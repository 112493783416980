import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const userImprintPurposesAdapter = createEntityAdapter({})

const initialState = userImprintPurposesAdapter.getInitialState()

export const userImprintPurposesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserImprintPurposes: builder.query({
            query: () => ({
                url: '/userImprintPurposes/getUserImprintPurposes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserImprintPurposes = responseData.map(userImprintPurpose => {
                    userImprintPurpose.id = userImprintPurpose._id
                    return userImprintPurpose
                })
                return userImprintPurposesAdapter.setAll(initialState, loadedUserImprintPurposes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserImprintPurpose', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserImprintPurpose', id}))
                    ]
                } else {
                    return [ { type: 'UserImprintPurpose', id: 'LIST'}]
                }
            },
        }),
        createNewUserImprintPurpose: builder.mutation({
            query: initialUserImprintPurpose => ({
                url: '/userImprintPurposes/createNewUserImprintPurpose',
                method: 'POST',
                body: {...initialUserImprintPurpose,}
            }),
            invalidatesTags: [
                { type: 'UserImprintPurpose', id: "LIST" }
            ] 
        }),
        updateUserImprintPurpose: builder.mutation({
            query: initialUserImprintPurpose => ({
                url: '/userImprintPurposes/updateUserImprintPurpose',
                method: 'PATCH',
                body: {...initialUserImprintPurpose,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserImprintPurpose', id: arg.id },
                { type: 'UserImprintPurpose', id: 'LIST' }
            ],
        }),
        deleteUserImprintPurpose: builder.mutation({
            query: ({id}) => ({
                url: '/userImprintPurposes/deleteUserImprintPurpose',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserImprintPurpose', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserImprintPurposesQuery,
    useCreateNewUserImprintPurposeMutation,
    useUpdateUserImprintPurposeMutation,
    useDeleteUserImprintPurposeMutation,
} = userImprintPurposesApiSlice

// returns the query result object
export const selectUserImprintPurposeResult = userImprintPurposesApiSlice.endpoints.getUserImprintPurposes.select()

// create memoized selector
const selectUserImprintPurposesData = createSelector(
    selectUserImprintPurposeResult,
    userImprintPurposeResult => userImprintPurposeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserImprintPurposes,
    selectById: selectUserImprintPurposeById,
    selectIds: selectUserImprintPurposeIds,
} = userImprintPurposesAdapter.getSelectors(state => selectUserImprintPurposesData(state) ?? initialState)