import React, { useState } from 'react'
import { useNavigate } from "react-router"
import { addIcon, userProfileIcon2 } from "@/assets/img/imgAssets"
import EtUserInduction from '@/pages/home/web/EtUserInduction'
import EtUserImprint from '@/pages/home/web/EtUserImprint'
import EtUserIllumination from '@/pages/home/web/EtUserIllumination'
import EtIlluminationIcon1SVGComp from '@/assets/img/icons/svgComp/EtIlluminationIcon1SVGComp'
import EtInductionIcon1SVGComp from '@/assets/img/icons/svgComp/EtInductionIcon1SVGComp'
import EtImprintIcon2SVGComp from '@/assets/img/icons/svgComp/EtImprintIcon2SVGComp'

// 25 Feb 2025
import EtAdminIcon1SVGComp from '@/assets/img/icons/svgComp/EtAdminIcon1SVGComp'

// 26 Feb 2025
import EtTrinityWebAdmin from '@/pages/home/web/EtTrinityWebAdmin'

// 07 Mar 2025
import EtTrinityWebLanding from '@/pages/home/web/EtTrinityWebLanding'

import { useGetUserPicQuery, useGetUsersQuery } from "@/features/users/usersApiSlice"
import useAuth from '@/hooks/useAuth';
import { useSendLogoutMutation } from "@/features/auth/authApiSlice"

import EtModalUserPicUpload from "@/components/common/modal/EtModalUserPicUpload"

import TrinityIcon from '@/assets/img/icons/base64Svgs/trinityRoundIcon1.svg'
import EtLogoutIcon1SVGComp from "@/assets/img/icons/svgComp/EtLogoutIcon1SVGComp" 

// 07 Mar 2025
import EtEtTrinityIcon6SVGComp from "@/assets/img/icons/svgComp/EtEtTrinityIcon6SVGComp";


const EtTrinityWebHome = () => {
  // First Created - 22 Feb 2024
  // Author - Atul Pandey
  // Inherited from EtUserMagic
  // Updated - 25 Feb 2025 - Added isAdmin
  // Updated - 14 Mar 2025 - username prop to EtTrinityWebLanding - for iCal

 // 0 - Extract username and name
 const { username, name, isAdmin } = useAuth() // Extract username & name
 // console.log('username', username)
 // console.log('name', name)
 console.log('isAdmin', isAdmin)
  
// 1a - Set navigation
 const navigate = useNavigate()
 
// 1b - Set active Tab
const [activeTab, setActiveTab] = useState("induction")
const [isModalOpen, setIsModalOpen] = useState(false)
  
// 1c - Modal Management
const handleOpenModal = () => setIsModalOpen(true)
const handleCloseModal = () => setIsModalOpen(false)

// 2a - User Info
const { data: usersData } = useGetUsersQuery();

const [sendLogout, {
isLoading,
isError,
error
}] = useSendLogoutMutation()

const extractEntitiesFromAdapter = (dataAdapter) => {
if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
return dataAdapter.ids.map((id) => dataAdapter.entities[id])
};

const extractFilteredEntitiesFromAdapter = (dataAdapter) => {
if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
return dataAdapter.ids.map((id) => dataAdapter.entities[id])
?.filter(
    (item) => item?.username === username && item.name === name
    );
};

// const usersAll = extractEntitiesFromAdapter(usersData)
// console.log('usersAll', usersAll)
const userInfo = extractFilteredEntitiesFromAdapter(usersData)

console.log('userInfo', userInfo)   

// 2b - extract userId and userOrgId from userInfo
const userId = userInfo[0]?._id || null
const userOrgId = userInfo[0]?.userOrgId?._id || null

// console.log('userId', userId)
// console.log('userOrgId', userOrgId)

// 3 - Handle Logout
const handleLogout = async () => {
  try {
    await sendLogout().unwrap();
    navigate('/login')
  } catch (err) {
    console.error('Logout failed:', err);
  }
};


  return (
    <div className='bg-gray-200 m-1 p-1 rounded xl:w-[99vw] mx-auto shadow shadow-black'>   
      {/* Tab Navigation */}
      <div className='bg-gray-50 rounded m-1 p-1'>
        <div className='flex justify-between items-center'>
            
            
            <div className="flex justify-start items-center m-1 p-1 font-poppins rounded">
                
                <div 
                  onClick={() => setActiveTab("home")}
                  className=" cursor-pointer bg-black hover:bg-gradient-to-r from-orange-600 to-orange-600 via-black text-white p-1 rounded-full shadow-lg shadow-black"
                >
                      <EtEtTrinityIcon6SVGComp className="h-10 w-10 text-orange-600 hover:text-white" />

                     
                       {/* <img src={TrinityIcon} alt="" className='h-10 w-10' /> */}
           
                </div>
                
                <div
                onClick={() => setActiveTab("induction")}
                className={`flex items-center text-gray-400 font-bold mx-2 ml-10 cursor-pointer ${
                    activeTab === "induction" ? "text-orange-600 underline" : ""
                }`}
                >
                <div><EtInductionIcon1SVGComp className='h-6 w-6'/></div>  
                <div><p className="mx-1 px-1">Induce</p></div>
                </div>
                
                <div
                onClick={() => setActiveTab("imprint")}
                className={`flex items-center text-gray-400 font-bold mx-2 cursor-pointer ${
                    activeTab === "imprint" ? "text-orange-600 underline" : ""
                }`}
                >
                <div><EtImprintIcon2SVGComp className='h-6 w-6'/></div>  
                <div><p className="mx-1 px-1">Imprint</p></div>
                </div>

                <div
                onClick={() => setActiveTab("illumination")}
                className={`flex items-center text-gray-400 font-bold mx-2 cursor-pointer ${
                    activeTab === "illumination" ? "text-orange-600 underline" : ""
                }`}
                >
                    <div><EtIlluminationIcon1SVGComp className='h-6 w-6'/></div>  
                    <div><p className="mx-1 px-1">Illuminate</p></div>
                </div>

                {isAdmin && (
                  <div
                  onClick={() => setActiveTab("admin")}
                  className={`flex items-center text-gray-400 font-bold mx-2 cursor-pointer ${
                      activeTab === "admin" ? "text-orange-600 underline" : ""
                  }`}
                  >
                      <div><EtAdminIcon1SVGComp className='h-6 w-6'/></div>  
                      <div><p className="mx-1 px-1">Admin</p></div>
                  </div>
                )}
            </div>
            
            <div className='flex justify-end cursor-pointer m-1 p-1'>  
                {/* User Pic */}
                <div className="m-1 p-1 flex justify-start items-center">
                    <p className="px-5 text-xs text-gray-600">
                    Hello{" "}
                    <span className="text-black font-bold">
                        {name.split(' ')[0]}!
                    </span>
                    </p>
                    <div className='cursor-pointer' onClick={handleOpenModal}>
                        <User username={username} defaultUserPic={userProfileIcon2}/> 
                    </div>
                    
                     {/* Logout */}
                    <div className='relative group justify-center items-center cursor-pointer px-5'  onClick={handleLogout}> 
                        <div className='flex justify-center items-center rounded-full w-4 h-4 bg-gradient-to-r from-orange-800 to-orange-800 via-orange-600 shadow-xl shadow-gray-600'>
                        <EtLogoutIcon1SVGComp className='h-3 w-3 text-white'/>
                        </div>
                        {/* Tooltip */}
                        <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-xs rounded py-1 px-2 z-10">
                            Logout
                        </div>
                        
                    </div>  
                </div>   
            </div>
            
        </div>
        

        {/* Tab Content */}
        <div className='border-t border-gray-400 my-2 py-2'>
            {/* {activeTab === "home" && <EtTrinityWebLanding />} */}
            {activeTab === "home" && <EtTrinityWebLanding username={username}/>} {/* 14 Mar 2025*/}
            {activeTab === "induction" && <EtUserInduction userId={userId} userOrgId={userOrgId}/>}
            {activeTab === "imprint" && <EtUserImprint userId={userId}/>}
            {activeTab === "illumination" && <EtUserIllumination userId={userId}/>}
            {activeTab === "admin" && <EtTrinityWebAdmin userOrgId={userOrgId} userId={userId}/>}
        </div>
      </div>
      <EtModalUserPicUpload isOpen={isModalOpen} onClose={handleCloseModal}/>
    </div>
  )
}

const User = ({ username, defaultUserPic }) => {
    const { data: userPic, isFetching: isFetchingUserPic } =
      useGetUserPicQuery(username)
  
    return (
      <div className="flex justify-center items-center h-12 w-12 rounded-full border border-white bg-gradient-to-r from-orange-600 to-orange-400 shadow-lg shadow-black cursor-pointer">
        {/* Display user picture or loading indicator*/}
        {isFetchingUserPic ? (
          <div>Loading...</div>
        ) : userPic ? (
          <img src={userPic} alt="User" className="h-11 w-11  rounded-full" />
        ) : (
          <img src={defaultUserPic} alt="User" className="h-10 w-10 p-1" />
        )}
      </div>
    )
}

export default EtTrinityWebHome
