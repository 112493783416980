import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import useTitle from "../../hooks/useTitle";
import EtBtnFormBasic from "../../components/common/buttons/EtBtnFormBasic";
import PulseLoader from "react-spinners/PulseLoader";
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';
import { useGetChallengesQuery } from "../challenges/challengesApiSlice";
import { useCreateNewUserChallengeCoachEvalMutation } from "./userChallengeCoachEvalsApiSlice";

const EtAddOrUpdateUserChallengeCoachEvalComp = ({mode = 'create', existingEvalData = null, challengeId, coachId, coachOrgId, userId, userOrgId, trailId, sessionId, triggeringUrl, onClose}) => {
  // First Created - 27 Aug 2024
  // Author - Atul Pandey
  // Inherited from EtCreateUserChallengeCoachEvalComp
  // Purpose - to create new as well as update coach user challenge evals

  // 0 - Set Page Title
  useTitle('Create New or Update Coach Challenge Evaluation');
  
  // 1a- Set Page Navigation
  const navigate = useNavigate();

 // 1b - Extract incoming data:
//   console.log('Mode:', mode)
//   console.log('ExistingEvalData:', existingEvalData)
//   console.log('ChallengeId:', challengeId)
//   console.log('CoachId:', coachId)
//   console.log('CoachOrgId:', coachOrgId)
//   console.log('UserId:', userId)
//   console.log('UserOrgId:', userOrgId)
//   console.log('TrailId:', trailId)
//   console.log('SessionId:', sessionId)
//   console.log('TriggeringUrl:', triggeringUrl)

  // 2a - Set the mutation to create the new challenge
  const [createNewUserChallengeCoachEval, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewUserChallengeCoachEvalMutation();

  // 2b - Get ChallengesData
  const {data: challengesData} = useGetChallengesQuery()
  const challenges = challengesData?.ids?.map((id) => {
    const challenge = challengesData?.entities[id]
    return challenge
  })
console.log('challenges', challenges)

  // 2c - Filter the challengesData for the given challengdId
  const filteredChallenge = challenges?.find((challenge) => challenge._id === challengeId)
 console.log('Filtered Challenge', filteredChallenge)

  // 2d - Extract all the challenge dimensions for the filtered challenge
  const challengeDimensions = filteredChallenge?.challengeDimensions
 //console.log('challenge dimensions:', challengeDimensions)

  // 2e - Extract all the challenge dim id data
  const challengeDimIds = challengeDimensions?.map((dimension) => {
    const challengeDimId = dimension?.challengeDimId
    return challengeDimId
  })
// console.log('Challenge Dim Ids', challengeDimIds)
console.log('Challenge Dim Ids', challengeDimIds?.map(dim => dim.challengeDimensionName))


// 3a - State to keep track of selected challenge dimensions
const [selectedChallengeDims, setSelectedChallengeDims] = useState([]);
// console.log('Selected Challenge Dims', selectedChallengeDims)

// 3b - Create the form data object
const [formData, setFormData] = useState({
    challengeId: challengeId,
    coachId:coachId,
    coachOrgId: coachOrgId, 
    userId: userId,
    userOrgId: userOrgId,
    trailId: trailId,
    sessionId: sessionId,  
    userChallengeCoachEvals: [{ userChallengeDimId: "", userChallengeDimCoachScore: "", userChallengeDimCoachEvalText: "",}],  
  });

// 3c - Use Existing Evals data for existing eval records
useEffect(() => {
    if (existingEvalData) {
        setFormData({
            challengeId: existingEvalData.challengeId,
            coachId: existingEvalData.coachId,
            coachOrgId: existingEvalData.coachOrgId,
            userId: existingEvalData.userId,
            userOrgId: existingEvalData.userOrgId,
            trailId: existingEvalData.trailId,
            sessionId: existingEvalData.sessionId,
            userChallengeCoachEvals: existingEvalData.userChallengeCoachEvals,
        });

        // Update read-only status based on existing evaluations
        const evaluatedDims = existingEvalData.userChallengeCoachEvals.reduce((acc, evalItem) => {
            acc[evalItem.userChallengeDimId] = true; // Mark evaluated dimensions as read-only
            return acc;
        }, {});

        setSelectedChallengeDims(existingEvalData.userChallengeCoachEvals?.map(evalItem => evalItem.userChallengeDimId) || []);
    }
}, [existingEvalData,challengeId, coachId, coachOrgId, userId, userOrgId, trailId, sessionId]);


// 3d - Filter out already selected challengeDimIds
const availableChallengeDims = challengeDimIds?.filter(dim => !selectedChallengeDims.includes(dim._id));
// console.log('available challenge Dims', availableChallengeDims)

// 3e - - Create options for select box from available challenge dimensions
const challengeDimOptions = availableChallengeDims?.map((dim) => (
        <option key={dim._id} value={dim._id}>
            {dim.challengeDimensionName}
        </option>
  ));

// 4 - Reset form upon successful submission
useEffect(() => {
if (isSuccess) {
    setFormData({
    challengeId: "", 
    coachId: "",
    coachOrgId: "",
    userId: "",
    userOrgId: "",
    trailId: "",
    sessionId: "",  
    challengeDimId: "",  
    userChallengeEvals:[{}],
    });
    if (onClose) {
      onClose(); // Close the modal
    } else {
      navigate(triggeringUrl || '/userChallengeCoachEvals'); // Navigate to triggering URL or fallback
      
    }
}
}, [isSuccess, navigate, onClose, triggeringUrl]);


// 5a - Form field updates / change handling
const handleChange = (e) => {
    const { name, value } = e.target;
    const [fieldName, index] = name.split('-');

    setFormData(prevState => {
        const updatedEvals = [...prevState.userChallengeCoachEvals];
        updatedEvals[index][fieldName] = value; // Update the form data for the specific field

        return { ...prevState, userChallengeCoachEvals: updatedEvals };
    });

    // If a dimension is selected, update the selected dimensions state
    if (fieldName === 'userChallengeDimId') {
        setSelectedChallengeDims(prevState => {
            const updatedDims = [...prevState];
            updatedDims[index] = value; // Store the selected dimension ID at the corresponding index
            return updatedDims;
        });
    }
};

// 5b - Add new challenge dimension evaluation field
const handleAddEval = () => {
    setFormData(prevState => ({
        ...prevState,
        userChallengeCoachEvals: [
            ...prevState.userChallengeCoachEvals,
            { userChallengeDimId: "", userChallengeDimCoachScore: "", userChallengeDimCoachEvalText: "" }
        ]
    }));
    setSelectedChallengeDims(prevState => [...prevState, ""]); // Add an empty string to track the new dimension selection
};
  
// 5c - Remove challenge dimension evaluation field
const handleRemoveEval = (index) => {
    setFormData(prevState => ({
        ...prevState,
        userChallengeCoachEvals: prevState.userChallengeCoachEvals.filter((_, i) => i !== index) // Corrected key
    }));

    setSelectedChallengeDims(prevState => prevState.filter((_, i) => i !== index));
};


// 6 - Form Submit Logic
const handleSubmit = async (e) => {
    e.preventDefault();

    // Clone userChallengeCoachEvals immutably if you are modifying it
    const userChallengeCoachEvals = formData.userChallengeCoachEvals?.map(evalItem => ({
        ...evalItem
    }));
    

    // Initialize an object to collect the form data
    const userChallengeCoachEvalData = {
        challengeId: formData.challengeId,
        coachId: formData.coachId,
        coachOrgId: formData.coachOrgId,
        userId: formData.userId,
        userOrgId: formData.userOrgId,
        trailId: formData.trailId,
        sessionId: formData.sessionId,
        userChallengeCoachEvals: userChallengeCoachEvals,
    };

    // console.log('UserChallenge Coach Eval Data', userChallengeCoachEvalData)
    
    if (formData.pic) {
        const reader = new FileReader();
        reader.onloadend = async () => {
        userChallengeCoachEvalData.pic = reader.result;
        try {
            await createNewUserChallengeCoachEval(userChallengeCoachEvalData).unwrap();
        } catch (error) {
            console.error(error);
        }
        };
        reader.readAsDataURL(formData.pic);
    } else {
        try {
        await createNewUserChallengeCoachEval(userChallengeCoachEvalData).unwrap();
        } catch (error) {
        console.error(error);
        }
    }
    };

// 7- Handle Cancel
const handleCancel = () => {
  if (onClose) {
    onClose(); // Close the modal
  } else {
    navigate(triggeringUrl || "/userChallengeCoachEvals"); // Navigate to triggering URL or fallback
  }
};

// 8 - Handle Clear
const handleClear = () => {
    setFormData({
        challengeId: "",
        coachId: "",
        coachOrgId: "", 
        userId: "",
        userOrgId: "",
        trailId: "",
        sessionId: "",  
        challengeDimId: "",  
        userChallengeEvals:[{}],
    });
};

return (
    <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black scrollbar overflow-auto max-h-[60vh]">
            {isLoading && <PulseLoader color={"#ea580c"} />}
            {isError && (
                <p className="text-orange-700 font-semi-bold py-2">
                    {error?.data?.message}
                </p>
            )}
            <div className="bg-white p-1 m-1 rounded">
                <p className="p-1 m-1 font-bold font-poppins text-xl">Create or Update Coach Evaluation</p>
  
                <div className="bg-gray-200 rounded m-1 p-1">
                    <form
                        onSubmit={handleSubmit}
                        className="m-1 p-1 text-xs font-poppins"
                    >
                        {/* Challenge Coach-Evaluation Scores */}
                        
                    {formData.userChallengeCoachEvals?.map((evalItem, index) => (
                            <div key={index} className="mb-4">
                                {/* Select for Challenge Dimension */}
                                <div className="flex justify-start items-center mb-2">
                                    <select
                                        name={`userChallengeDimId-${index}`}
                                        value={evalItem.userChallengeDimId}
                                        onChange={handleChange}
                                        className="w-full rounded p-2 m-1"
                                    >
                                        <option value="" disabled>Select Challenge Dimension</option>
                                        {/* {challengeDimOptions} */}
                                        {availableChallengeDims?.map(dim => (
                                        <option key={dim._id} value={dim._id}>
                                            {dim.challengeDimensionName}
                                        </option>
                                        ))}
                                        {/* Include the currently selected dimension in the options */}
                                        {evalItem?.userChallengeDimId && !availableChallengeDims?.some(dim => dim._id === evalItem?.userChallengeDimId) && (
                                            <option value={evalItem?.userChallengeDimId} selected>
                                                {challengeDimIds?.find(dim => dim._id === evalItem?.userChallengeDimId)?.challengeDimensionName || "Selected Dimension"}
                                            </option>
                                        )}
                                    </select>
                                </div>
                                {/* Input for Coach-Evaluation Score */}
                                <div className="flex justify-start items-center mb-2">
                                    <input
                                        type="number"
                                        name={`userChallengeDimCoachScore-${index}`}
                                        placeholder="Enter Coach-Evaluation Score"
                                        value={evalItem.userChallengeDimCoachScore}
                                        onChange={handleChange}
                                        className="w-full rounded p-2 m-1"
                                        // readOnly={mode === 'update' && evalItem.userChallengeDimCoachScore !== undefined} // 27 Aug 2024
                                        readOnly={mode === 'update' && existingEvalData?.userChallengeCoachEvals?.some(existingEval => existingEval.userChallengeDimId === evalItem.userChallengeDimId && existingEval.userChallengeDimCoachScore !== undefined)}  // 27 Aug 2024
                                    />
                                </div>
                                {/* Textarea for Coach-Evaluation Text */}
                                <div className="flex justify-start items-center mb-2">
                                    <textarea
                                        name={`userChallengeDimCoachEvalText-${index}`}
                                        placeholder="Enter Coach-Evaluation Text"
                                        value={evalItem.userChallengeDimCoachEvalText}
                                        onChange={handleChange}
                                        className="w-full rounded p-2 m-1"
                                    />
                                </div>
                                {/* Remove Button */}
                                <div className="flex justify-end">
                                    <button
                                        type="button"
                                        onClick={() => handleRemoveEval(index)}
                                        className="flex justify-center items-center bg-red-500 text-white rounded p-1 m-1"
                                    >
                                        <MinusIcon className="h-5 w-5" />
                                    </button>
                                </div>
                            </div>
                        ))}
                        {availableChallengeDims?.length > 0 && (
                            <div className="flex justify-start items-center m-1">
                                <button
                                    type="button"
                                    onClick={handleAddEval}
                                    className="flex justify-center items-center bg-green-500 text-white rounded p-1 m-1"
                                >
                                    <PlusIcon className="h-5 w-5" />
                                </button>
                            </div>
                        )}
  
                        <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
                            <EtBtnFormBasic
                                name={mode === 'create' ? "Create" : "Update"}
                                type={'create'}
                                handleClick={handleSubmit}
                            />
                            <EtBtnFormBasic
                                name={"Clear"}
                                type={"clear"}
                                handleClick={handleClear}
                            />
                            <EtBtnFormBasic
                                name={"Cancel"}
                                type={"cancel"}
                                handleClick={handleCancel}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
  
  )
}

export default EtAddOrUpdateUserChallengeCoachEvalComp