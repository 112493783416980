import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import useTitle from "@/hooks/useTitle"
import {
  PlusIcon,
  MinusIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline"
import { ClockIcon, BuildingOfficeIcon } from "@heroicons/react/24/solid"
import { userProfileIcon2 } from '@/assets/img/imgAssets'
import EtContentEditorLight2 from "@/utils/EtContentEditorLight2" 

import { useGetUserPicQuery } from "@/features/users/usersApiSlice" 
import { useGetUsersQuery } from '@/features/users/usersApiSlice'

// 19 Jan 2025
import { useGetUserAccomplishmentWeeklysQuery } from '@/features/userAccomplishmentWeekly/userAccomplishmentWeeklysApiSlice'

import EtIlluminateWeekIcon1SVGComp from '@/assets/img/icons/svgComp/EtIlluminateWeekIcon1SVGComp'
import EtIlluminateYearIcon1SVGComp from '@/assets/img/icons/svgComp/EtIlluminateYearIcon1SVGComp'
import EtIlluminateLifeIcon1SVGComp from '@/assets/img/icons/svgComp/EtIlluminateLifeIcon1SVGComp'

import EtIlluminateWeek from '@/pages/home/web/userIlluminate/illuminateWeek/EtIlluminateWeek'
import EtIlluminateYear from '@/pages/home/web/userIlluminate/illuminateYear/EtIlluminateYear'
import EtIlluminateLife from '@/pages/home/web/userIlluminate/illuminateLife/EtIlluminateLife'

// 19 Jan 2025
import { processAccomplishments } from "@/utils/processAccomplishments"

// 22 Jan 2025
import EtUserAccomplishmentWeeklyPadComp from '@/features/userAccomplishmentWeekly/EtUserAccomplishmentWeeklyPadComp'
import EtUserAccomplishmentWeeklyListComp from '@/features/userAccomplishmentWeekly/EtUserAccomplishmentWeeklyListComp'

const EtUserIllumination = ({userId}) => {
    // First Created - 19 Oct 2024
    // Author - Atul Pandey
    // Updated - 18 Jan 2025 - Started laying out the illumination boards
    // Updated - 19 Jan 2025 - User Accomplishment Data processing and filtering into weeks, years
    // Updated - 22 Jan 2025 - Added tabs for Week
    // Updated - 22 Jan 2025 - Added UserAccomplishmentWeeklyPadComp to display weekly accomplishment pad for the week
    // Updated - 22 Jan 2025 - Added UserAccomplishmentWeeklyListComp to display weekly accomplishment pad for all weeks (by year)
    // Updated - 27 Jan 2025 - Completed first version of Illumination!
    // Updated - 05 Mar 2025 - Changed the import reference from @/features/userMagic to @/pages/home/web


    // 1a - Set Page Title
    useTitle('User Illuminate')

    // 1b - State variables
    const [ activeTab, setActiveTab ] = useState("week")
    const [ activeWeekTab, setActiveWeekTab ] = useState("summaryWeek")
    const [ activeYearTab, setActiveYearTab ] = useState("summaryYear")

    // 2a - Get Users Data
    const {data: usersData } = useGetUsersQuery()
    const userInfo = usersData?.ids.map(id => usersData?.entities[id])?.filter(user => user._id === userId)
    // console.log('userInfo', userInfo)

    // 2b - Get accomplishment data from the backend
    const { data: userAccomplishmentWeeklysData } = useGetUserAccomplishmentWeeklysQuery()

    // 2c - Extract needed data from the incoming props
    const userOrgId = userInfo[0]?.userOrgId._id || null
    // console.log('userOrgId', userOrgId)

    // 2d - Exreact data from entities adapter
    const extractFilteredUserEntitiesFromAdapter = (dataAdapter) => {
        if (!dataAdapter || !dataAdapter?.ids || !dataAdapter?.entities) return [];
        return dataAdapter?.ids.map((id) => dataAdapter?.entities[id])
        ?.filter(
            (item) => item?.userId?._id === userId && item?.userOrgId?._id === userOrgId
            );
      };

    const extractFilteredUserOrgEntitiesFromAdapter = (dataAdapter) => {
        if (!dataAdapter || !dataAdapter?.ids || !dataAdapter?.entities) return [];
        return dataAdapter?.ids.map((id) => dataAdapter?.entities[id])
        ?.filter(
            (item) => item?.userOrgId?._id === userOrgId
            );
      };
  
    const userAccomplishments = extractFilteredUserEntitiesFromAdapter(userAccomplishmentWeeklysData)
    const orgAccomplishments = extractFilteredUserOrgEntitiesFromAdapter(userAccomplishmentWeeklysData)
    console.log('userAccomplishments', userAccomplishments)
    console.log('orgAccomplishments', orgAccomplishments)

    // 2e - Process accomplishments
    const {
      accomplishmentsThisWeek,
      accomplishmentsThisYear,
      accomplishments3Yrs,
      accomplishments5Yrs,
      accomplishmentsAllYrs,
    } = processAccomplishments(userAccomplishments, userId, userOrgId);
    
    const {
      accomplishmentsOrgThisWeek,
      accomplishmentsOrgThisYear,
      accomplishmentsOrg3Yrs,
      accomplishmentsOrg5Yrs,
      accomplishmentsOrgAllYrs,
    } = processAccomplishments(orgAccomplishments, userId, userOrgId);

    const accomplishmentsLife = [
      {last1Yr: accomplishmentsThisYear},
      {last3Yrs: accomplishments3Yrs},
      {last5Yrs: accomplishments5Yrs},
      {lifeTime:accomplishmentsAllYrs},]
    
      const accomplishmentsOrgLife = [
      {last1Yr: accomplishmentsOrgThisYear},
      {last3Yrs: accomplishmentsOrg3Yrs},
      {last5Yrs: accomplishmentsOrg5Yrs},
      {lifeTime:accomplishmentsOrgAllYrs},]

    // Log results for debugging
    console.log('This Week:', accomplishmentsThisWeek);
    // console.log('This Year:', accomplishmentsThisYear);
    // console.log('Last 3 Years:', accomplishments3Yrs);
    // console.log('Last 5 Years:', accomplishments5Yrs);
    // console.log('All Years:', accomplishmentsAllYrs);
    
    // console.log('Org This Week:', accomplishmentsOrgThisWeek);
    // console.log('Org This Year:', accomplishmentsOrgThisYear);
    // console.log('Org Last 3 Years:', accomplishmentsOrg3Yrs);
    // console.log('Org Last 5 Years:', accomplishmentsOrg5Yrs);
    // console.log('Org All Years:', accomplishmentsOrgAllYrs);



    return (
      <div>
        {/* Tabbed sections -for User Self View and 360 Imprint view */}
        <div className='bg-gradient-to-r from-black to-black rounded my-2 py-2'>
            <div className="flex justify-start items-center m-1 p-1 font-poppins rounded">
              <div
                onClick={() => setActiveTab("week")}
                className={`flex items-center text-gray-500 font-bold mx-2 cursor-pointer ${
                  activeTab === "week" ? "text-orange-600" : ""
                }`}
              >
                <div><EtIlluminateWeekIcon1SVGComp className='h-8 w-8' /></div>
                <div>
                    <p className="mx-1 px-1">Week</p>
                    <p className="mx-1 px-1 font-normal text-[10px] italic">how much did I <strong>illuminate</strong> this week?</p>
                </div>
              </div>
              
              <div
                onClick={() => setActiveTab("year")}
                className={`flex items-center text-gray-500 font-bold mx-2 cursor-pointer ${
                  activeTab === "year" ? "text-orange-600" : ""
                }`}
              >
                <div><EtIlluminateYearIcon1SVGComp className='h-8 w-8' /></div>
                <div>
                    <p className="mx-1 px-1">Year</p>
                    <p className="mx-1 px-1 font-normal text-[10px] italic">how much did I illuminate this year?</p>
                </div>
              </div>
              
              <div
                onClick={() => setActiveTab("life")}
                className={`flex items-center text-gray-500 font-bold mx-2 cursor-pointer ${
                  activeTab === "life" ? "text-orange-600" : ""
                }`}
              >
                <div><EtIlluminateLifeIcon1SVGComp className='h-8 w-8' /></div>
                <div>
                    <p className="mx-1 px-1">Lifetime</p>
                    <p className="mx-1 px-1 font-normal text-[10px] italic">how much did I illuminate this life?</p>
                </div>
              </div>

            </div>
        </div>

        <div className='border-t my-2 py-2'>
          {activeTab === 'week' && (
            <div>    
                <div className="flex justify-start items-center m-1 p-1 font-poppins rounded">
                  <div
                    onClick={() => setActiveWeekTab("summaryWeek")}
                    className={`flex items-center text-gray-700 font-bold mx-2 cursor-pointer ${
                      activeWeekTab === "summaryWeek" ? "text-orange-600 border-b-2 border-orange-600" : ""
                    }`}
                  >
                    {/* <div><EtIlluminateWeekIcon1SVGComp className='h-8 w-8' /></div> */}
                    <div>
                        <p className="mx-1 px-1">Summary</p>
                        {/* <p className="mx-1 px-1 font-normal text-[10px] italic">how much did I illuminate this week?</p> */}
                    </div>
                  </div>
                  
                  <div
                    onClick={() => setActiveWeekTab("detailWeek")}
                    className={`flex items-center text-gray-700 font-bold mx-2 cursor-pointer ${
                      activeWeekTab === "detailWeek" ? "text-orange-600 border-b-2 border-orange-600" : ""
                    }`}
                  >
                    {/* <div><EtIlluminateYearIcon1SVGComp className='h-8 w-8' /></div> */}
                    <div>
                        <p className="mx-1 px-1">Detail</p>
                        {/* <p className="mx-1 px-1 font-normal text-[10px] italic">how much did I illuminate this year?</p> */}
                    </div>
                  </div>

                </div>
            
                {activeWeekTab === "summaryWeek" && (
                  <div>
                    <EtIlluminateWeek userId={userId} userInfo={userInfo} dataSelf={accomplishmentsThisWeek} dataOrg={accomplishmentsOrgThisWeek}/>
                  </div>
                )}
                {activeWeekTab === "detailWeek" && (
                  <div>
                    <EtUserAccomplishmentWeeklyPadComp userId={userId} userOrgId={userOrgId} />
                  </div>
                )}
            
            </div>
          )}

          {activeTab === 'year' && (
            <div>
              <div className="flex justify-start items-center m-1 p-1 font-poppins rounded">
                  <div
                    onClick={() => setActiveYearTab("summaryYear")}
                    className={`flex items-center text-gray-700 font-bold mx-2 cursor-pointer ${
                      activeYearTab === "summaryYear" ? "text-orange-600 border-b-2 border-orange-600" : ""
                    }`}
                  >
                    {/* <div><EtIlluminateWeekIcon1SVGComp className='h-8 w-8' /></div> */}
                    <div>
                        <p className="mx-1 px-1">Summary</p>
                        {/* <p className="mx-1 px-1 font-normal text-[10px] italic">how much did I illuminate this week?</p> */}
                    </div>
                  </div>
                  
                  <div
                    onClick={() => setActiveYearTab("detailYear")}
                    className={`flex items-center text-gray-700 font-bold mx-2 cursor-pointer ${
                      activeYearTab === "detailYear" ? "border-b-2 border-orange-600" : ""
                    }`}
                  >
                    {/* <div><EtIlluminateYearIcon1SVGComp className='h-8 w-8' /></div> */}
                    <div>
                        <p className="mx-1 px-1">Detail</p>
                        {/* <p className="mx-1 px-1 font-normal text-[10px] italic">how much did I illuminate this year?</p> */}
                    </div>
                  </div>

                </div>

                {activeYearTab === "summaryYear" && (
                  <div>
                    <EtIlluminateYear userId={userId} userInfo={userInfo} dataSelf={accomplishmentsThisYear} dataOrg={accomplishmentsOrgThisYear}/>
                  </div>
                )}

                {activeYearTab === "detailYear" && (
                  <div>
                    <EtUserAccomplishmentWeeklyListComp userId={userId} userOrgId={userOrgId} />
                  </div>
                )}

            </div>
          )}
          {activeTab === 'life' && (
            <div>
              <EtIlluminateLife userId={userId} userInfo={userInfo} dataSelf={accomplishmentsAllYrs} dataOrg={accomplishmentsOrgAllYrs}/>
            </div>
          )}
        </div>

      </div>
    )
}

export default EtUserIllumination