import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router"
import PulseLoader from "react-spinners/PulseLoader"; 

import { PlusIcon, MinusIcon, PaperAirplaneIcon, MicrophoneIcon, StopIcon, EyeIcon, CursorArrowRippleIcon, CalendarDaysIcon } from "@heroicons/react/24/solid";

import { useGetActionTypesQuery } from "@/features/actionTypes/actionTypesApiSlice"
import { useCreateNewUserActionMutation, useUpdateUserActionMutation, } from "@/features/userActions/userActionsApiSlice"

import { useGetUserTrailSessionExecutionsQuery } from '@/features/userTrailSessions/userTrailSessionExecutionsApiSlice'

import { useGetSessionsQuery } from '@/features/sessions/sessionsApiSlice'
import { useGetUserActionsQuery } from '@/features/userActions/userActionsApiSlice'

// 19 Feb 2025
import { useGetUserPicQuery, useGetUsersQuery } from "@/features/users/usersApiSlice"
import useAuth from '@/hooks/useAuth';
import { useSendLogoutMutation } from "@/features/auth/authApiSlice"

import TrinityIcon from '@/assets/img/icons/base64Svgs/trinityRoundIcon1.svg'
import EtWabActionIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabActionIcon1SVGComp" 
import { getTimeSince } from "@/utils/EtGetTimeSince"
import { formatDate, formatDateTime } from "@/utils/formatDate"
import { produce } from 'immer';

import { CircularProgressbarWithChildren } from 'react-circular-progressbar'

// 19 Feb 2025
import EtLogoutIcon1SVGComp from "@/assets/img/icons/svgComp/EtLogoutIcon1SVGComp" 

const EtTrinityUserActionMobile = () => {
  // First Created - 15 Feb 2025
  // Author - Atul Pandey
  // Purpose - To capture Mobile gratitudes and summarize with Trinity's LLM powers
  // Inherited from EtTrinityUserConversationMobile 
  // Updated - 19 Feb 2025 - copied from @/features to @/pages/mobile and added useAuth
  // Updated - 20 Feb 2025 - refined voiceInput for Android
  // Updated - 21 Feb 2025 - further refined voiceInput for Android - continuous recording

  // 0 - User Variables
  const { username, name } = useAuth() // Extract username & name - 19 Feb 2025
  // console.log('username', username)
  // console.log('name', name)

  // 1a - Set navigation
  const navigate = useNavigate()
  
  // 1b1 - State variables
  
  const [localDraft, setLocalDraft] = useState('');
  const [ activeTab, setActiveTab] = useState('add')
  const [isRecording, setIsRecording] = useState(false);
  const [ showNoteDetails, setShowNoteDetails ] = useState({}) 

  // 16 Feb 2025
  const [selectedTrailId, setSelectedTrailId] = useState('');
  const [selectedSessionId, setSelectedSessionId] = useState('');
  const [actionName, setActionName] = useState('');
  const [actionDesc, setActionDesc] = useState('');
  const [selectedActionTypeId, setSelectedActionTypeId] = useState('');
  const [completionDate, setCompletionDate] = useState('');


  // 17 Feb 2025 - State for managing progress update form visibility and inputs
  const [showProgressForm, setShowProgressForm] = useState({}); // Track form visibility per action
  const [progressNoteText, setProgressNoteText] = useState(""); // Input for action note text
  const [progressValue, setProgressValue] = useState(0); // Slider and input box sync

  const [loading, setLoading] = useState(false); // 11 Feb 2025 - For Gen AI

  // 1b2 - State to control the modal and store the AI-generated response - 11 Feb 2025
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [aiGeneratedResponse, setAiGeneratedResponse] = useState('');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);


  // 1c - Ref for speech recognition instance
  const recognitionRef = useRef(null);
  const textareaRef = useRef(null); // Ref for the textarea to control its size

  // 2a1 - User Info - 19 Feb 2025
  const { data: usersData } = useGetUsersQuery();
  const [sendLogout, {
    isLoading,
    isError,
    error
  }] = useSendLogoutMutation()

  const extractFilteredUserEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id])
    ?.filter(
        (item) => item?.username === username && item.name === name
        );
  };
  const userInfo = extractFilteredUserEntitiesFromAdapter(usersData)
  
  // 2a2 - extract userId and userOrgId from userInfo
  const userId = userInfo[0]?._id || null
  const userOrgId = userInfo[0]?.userOrgId?._id || null
    
  // 2a3 - Data Queries & Mutations
  const { data: actionTypesData } = useGetActionTypesQuery(); 
  const { data: userTrailSessionsData } = useGetUserTrailSessionExecutionsQuery(); 
  const { data: sessionsData } = useGetSessionsQuery(); 
  const { data: userActionsData, refetch } = useGetUserActionsQuery(); 
  
  const [createNewUserAction] = useCreateNewUserActionMutation();
  const [updateUserAction] = useUpdateUserActionMutation();
  
  const extractEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id])
  };

  const extractFilteredUserOrgEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id])
    ?.filter(
      (item) => item.userId?._id === userId && item?.userOrgId === userOrgId
      );
  };

  const extractFilteredEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id])
    ?.filter(
        // (item) => item.userId?._id === userId && item?.userOrgId === userOrgId
        (item) => item.userId?._id === userId
        );
  };

  const actionTypes = extractEntitiesFromAdapter(actionTypesData);
  const sessions = extractEntitiesFromAdapter(sessionsData);
  const userTrailSessions = extractFilteredEntitiesFromAdapter(userTrailSessionsData)
  // const userActions = extractEntitiesFromAdapter(userActionsData)
  const userFilteredActions = extractFilteredEntitiesFromAdapter(userActionsData)

  // console.log('userActions', userActions)
  // console.log('userFilteredActions', userFilteredActions)
  // console.log('userTrailSessions', userTrailSessions)
  // console.log('sessions', sessions)

  // 2b1 - Extract trails from userTrailSessions and group sessions by trail
  const trails = userTrailSessions?.reduce((acc, session) => {
    const trailId = session.trailId?._id;
    if (!acc[trailId]) {
      acc[trailId] = {
        trailId,
        trailName: session.trailId?.trailName || "Unnamed Trail",
        sessions: [],
      };
    }
    acc[trailId].sessions.push({
      sessionId: session?.sessionId?._id,
      sessionName: session.sessionId?.sessionName || "Unnamed Session",
    });
    return acc;
  }, {});

  // 2b2 - Convert trails object to array
  const trailOptions = Object.values(trails || {});
  // console.log('trailOptions', trailOptions)

  // 2c - Load drafts from localStorage (if available)
  useEffect(() => {
    const savedDraft = localStorage.getItem('actionDraft');
    if (savedDraft) setLocalDraft(savedDraft);
  }, []);

  // 2d - Save draft to localStorage
  useEffect(() => {
    localStorage.setItem('actionDraft', actionName);
  }, [actionName]);

  // 3 - Handle Toggle Progress Form - 17 Feb 2025
  const handleToggleProgressForm = (actionId, currentProgress) => {
    setShowProgressForm((prev) => ({
      ...prev,
      [actionId]: !prev[actionId], // Toggle visibility per action
    }));
    setProgressNoteText(""); // Reset the note text input
    setProgressValue(currentProgress); // Set slider to current progress
  };
  

  // 4 - Handle Toggle showNoteDetails - 10 Feb 2025
  const handleToggleShowNoteDetails = (noteId) => {
    setShowNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
      }))
  }

// 5 - Handle submission (RTK Mutation) - New Action Creation
const handleSubmit = async (finalText) => {
  if (!selectedActionTypeId || !selectedTrailId || !selectedSessionId) {
    alert("Please select an action type, trail, and session.");
    return;
  }

  if (!finalText.trim() || !completionDate) {
    alert("Please enter action details and a completion date.");
    return;
  }

  let newAction = {
    userId,
    userOrgId,
    trailId: selectedTrailId,
    sessionId: selectedSessionId,
    actionTypeId: selectedActionTypeId,
    userActionName: actionName,
    userActionDesc: finalText,
    completeBy: completionDate,
  };

  try {
    await createNewUserAction(newAction).unwrap();
    refetch();
    setActionName('');
    setActionDesc('');
    setSelectedActionTypeId('');
    setSelectedTrailId('');
    setSelectedSessionId('');
    setCompletionDate('');
    localStorage.removeItem('actionDraft');
  } catch (error) {
    console.error('Failed to create a new action:', error);
    alert('Failed to create a new action. Please try again.');
  }
};

  // 6 - Create an object for setting inner HTML
  const createMarkup = (htmlContent) => {
    const formattedContent = htmlContent.replace(/\n/g, '<br />'); // Convert \n to <br />
    // return { __html: htmlContent }
    return { __html: formattedContent };
    }

  // 7a - Audio Recording  
  const handleVoiceInputOriginal = (fieldType) => {
    if (!("webkitSpeechRecognition" in window)) {
        alert("Your browser does not support voice input.");
        return;
    }
    
    if (isRecording) {
        // Stop recording if already active
        if (recognitionRef.current) {
        recognitionRef.current.stop();
        setIsRecording(false);
        }
        return;
    }
    
    const recognition = new window.webkitSpeechRecognition();
    recognitionRef.current = recognition;

    // Detect if the device is Android
    const isAndroid = /Android/i.test(navigator.userAgent);


    recognition.continuous = true; // Continuous recording
    recognition.interimResults = !isAndroid; // ✅ Enable interim results except on Android
    // recognition.interimResults = true; // Show live transcription
    // recognition.interimResults = false; // Show live transcription
    recognition.lang = "en-US";

    let lastRecognizedText = ""; // ✅ Track last final transcript to prevent duplicates
    
    recognition.onstart = () => {
        setIsRecording(true);
    };
    
    recognition.onresult = (event) => {
        // let interimText = "";
        let finalText = "";
    
        // for (let i = 0; i < event.results.length; i++) {
        for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript.trim();
        
        if (event.results[i].isFinal && transcript !== lastRecognizedText) {
          finalText = transcript;
          lastRecognizedText = finalText; // ✅ Store last processed result
          }
        }

        // ✅ Update the appropriate field
        if (finalText) {
          if (fieldType === "action") {
              setActionDesc((prev) => prev.includes(finalText) ? prev : prev + " " + finalText);
          } else if (fieldType === "progress") {
              setProgressNoteText((prev) => prev.includes(finalText) ? prev : prev + " " + finalText);
          }
      }

    
        // setActionDesc(finalText + interimText); // Update the text area with live transcription

        // Determine which field to update - 17 Feb 2025
        // if (fieldType === "action") {
        //   setActionDesc(finalText + interimText);
        // } else if (fieldType === "progress") {
        //   setProgressNoteText(finalText + interimText);
        // }
    };
    
    recognition.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
    };
    
    recognition.onend = () => {
        setIsRecording(false);
        recognitionRef.current = null;

        // ✅ Fix for Android auto-stopping issue
        if (isRecording) {
          // console.log("Restarting speech recognition...");
          handleVoiceInput(fieldType); // ✅ Restart recognition on Android
      }
    };
    
    recognition.start();
  };

  const handleVoiceInput = (fieldType) => {
    if (!("webkitSpeechRecognition" in window)) {
        alert("Your browser does not support voice input.");
        return;
    }
    
    if (isRecording) {
        if (recognitionRef.current) {
            recognitionRef.current.stop();
            setIsRecording(false);
        }
        return;
    }
    
    const recognition = new window.webkitSpeechRecognition();
    recognitionRef.current = recognition;

    // Detect if the device is Android
    const isAndroid = /Android/i.test(navigator.userAgent);

    recognition.continuous = true;
    recognition.interimResults = !isAndroid; // Disable interim results on Android
    recognition.lang = "en-US";

    let lastRecognizedText = "";
    
    recognition.onstart = () => {
        setIsRecording(true);
        lastRecognizedText = "";
    };
    
    recognition.onresult = (event) => {
        let finalText = "";
    
        for (let i = event.resultIndex; i < event.results.length; i++) {
            const transcript = event.results[i][0].transcript.trim();
            
            if (event.results[i].isFinal) {
                // Only process if this is new text
                if (transcript !== lastRecognizedText) {
                    finalText = transcript;
                    lastRecognizedText = finalText;

                    // Update the appropriate field
                    if (fieldType === "action") {
                        setActionDesc(prev => {
                            // More robust duplicate check
                            if (!prev) return transcript;
                            const prevLower = prev.toLowerCase();
                            const transcriptLower = transcript.toLowerCase();
                            return prevLower.includes(transcriptLower) ? prev : `${prev} ${transcript}`;
                        });
                    } else if (fieldType === "progress") {
                        setProgressNoteText(prev => {
                            if (!prev) return transcript;
                            const prevLower = prev.toLowerCase();
                            const transcriptLower = transcript.toLowerCase();
                            return prevLower.includes(transcriptLower) ? prev : `${prev} ${transcript}`;
                        });
                    }
                }
            }
        }
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      setIsRecording(false);
      recognitionRef.current = null;
  };
    
  recognition.onend = () => {
    if (isRecording) {
        // Immediate restart if we're still supposed to be recording
        try {
            recognition.start();
        } catch (error) {
            console.error("Failed to restart recognition:", error);
            // Only set recording to false if restart failed
            setIsRecording(false);
            recognitionRef.current = null;
        }
    } else {
        // Only update states when we actually want to stop
        setIsRecording(false);
        recognitionRef.current = null;
    }
  };  
    try {
        recognition.start();
    } catch (error) {
        console.error("Failed to start recognition:", error);
        setIsRecording(false);
        recognitionRef.current = null;
    }
  };

  // 7b - Add cleanup effect
  useEffect(() => {
      return () => {
          if (recognitionRef.current) {
              recognitionRef.current.stop();
              setIsRecording(false);
          }
      };
  }, []);

  // 8a - Gen AI addition - aiPrompt
//   const aiPrompt = `
// Summarize the follogratitudeg text into a clear and structured note. 
// - Avoid adding "Subject", greetings, or closing statements. 
// - Use short paragraphs and lists where appropriate. 
// - Focus only on summarizing the content clearly and concisely. 

// Text to summarize:
// "${gratitudeText}"
// `;
  // const aiPrompt = `
  // Summarize the follogratitudeg text into a concise and structured format using HTML. Use <p> tags for paragraphs and <ul><li> for bullet points. Ensure readability and avoid including a subject line or closing remarks:
  // "${gratitudeText}"
  // `;
  const aiPrompt = `
  Summarize the follogratitudeg text into a concise, well-structured note in **HTML format**. Organize key points into a numbered list, with the first word of each item wrapped in <strong> tags for emphasis. Ensure the output is clean and readable, with paragraphs and spacing between sections. Do not include a subject line or closing statement. Do not include any salutations like "Dear Team" etc. Only return the HTML without additional comments:
  
  "${actionDesc}"
  `; 

  // 8b - Function to process response chunks
  const handleResponseChunk = (chunk) => {
    // setGratitudeText((prev) => prev + chunk); // Stream content into the existing text
    setAiGeneratedResponse((prev) => prev + chunk); // Stream content into aiGeneratedResponse instead of gratitudeText
    
  };

  // 8c - Function to process final response once ai stream is complete
  const handleComplete = (finalResponse) => {
    setIsModalOpen(true);  // Open modal first
    setAiGeneratedResponse(""); // Ensure it starts empty for typing effect
    setTimeout(() => simulateTypingEffect(finalResponse), 500);  // Delay typing effect slightly for better UX
    setLoading(false); // Stop the spinner or loading indicator
  };

  // 8d - Handle errors during the AI generation process
  const handleError = (error) => {
    console.error("Error generating AI response:", error);
    alert("Something went wrong. Please try again.");
    setLoading(false); // Ensure loading state is cleared
  };

  // 8e - Generate AI reponse function
  const generateAIResponse = (aiPrompt, onResponseChunk, onComplete, onError) => {
    try {
      const eventSource = new EventSource(
        `${process.env.REACT_APP_SERVER_URL}/trinity/generateResponse?` +
        `messages=${encodeURIComponent(JSON.stringify([
          { role: "system", content: "Generate a refined professional note." },
          { role: "user", content: aiPrompt }
        ]))}&maxTokens=5000&model=${encodeURIComponent("gpt-4")}`
      );
  
      let response = "";
  
      eventSource.onmessage = (event) => {
        const chunk = event.data;
        response += chunk;
        onResponseChunk(chunk);
      };
  
      eventSource.addEventListener("end", () => {
        eventSource.close();
        onComplete(response); // Final response
      });
  
      eventSource.onerror = (error) => {
        eventSource.close();
        onError(error);
      };
    } catch (error) {
      onError(error);
    }
  };

  // 8f - Function to generate response
  const handleGenerateAIResponse = () => {
    if (!actionDesc.trim()) return;
    
    setIsModalOpen(true);   // Open the modal first
    setLoading(true); // Show loading state
  
    generateAIResponse(aiPrompt, handleResponseChunk, handleComplete, handleError);
  };

  // 8g - Function to update action progress
  const handleUpdate = async (actionId) => {
    if (!progressNoteText.trim()) {
      alert("Please enter a progress note.");
      return;
    }
  
    const newNote = {
      actionNoteText: progressNoteText,
      actionNoteDate: new Date().toISOString(),
      actionNoteProgress: progressValue,
    };
  
    const actionToUpdate = userFilteredActions?.find((a) => a._id === actionId);
    if (!actionToUpdate) return;
  
  
    const updatedAction = {
      id: actionToUpdate._id, // Ensure we pass only the ID
      userId: actionToUpdate.userId._id, // Send only the ID
      userOrgId: actionToUpdate.userOrgId, 
      trailId: actionToUpdate.trailId._id, // Send only the ID
      sessionId: actionToUpdate.sessionId._id, // Send only the ID
      actionTypeId: actionToUpdate.actionTypeId._id, // Send only the ID
      userActionName: actionToUpdate.userActionName,
      userActionDesc: actionToUpdate.userActionDesc,
      completeBy: actionToUpdate.completeBy,
      userActionProgress: progressValue,
      userActionNotes: [newNote], // ✅ Send only the new note (backend handles appending)
    };
    
  
    try {
      await updateUserAction(updatedAction).unwrap();
      refetch(); // Refresh data
      setShowProgressForm((prev) => ({ ...prev, [actionId]: false })); // Close form
      setProgressNoteText(""); // Reset input
      setProgressValue(0); // Reset progress
    } catch (error) {
      console.error("Failed to update action:", error);
      alert("Failed to update action. Please try again.");
    }
  };
  

  // 9 - Simulate typing effect - 11 Feb 2025
  const simulateTypingEffect = (text) => {
    let index = 0;
    const words = text.split(" ");
    let displayedText = "";
  
    const interval = setInterval(() => {
      if (index < words.length) {
        displayedText += words[index] + " ";
        setAiGeneratedResponse(displayedText); // Set the response progressively
        index++;
      } else {
        clearInterval(interval);
      }
    }, 100); // Adjust the interval time as needed
  };

  // 10 - Handle Logout
  const handleLogout = async () => {
    try {
      await sendLogout().unwrap();
      navigate('/login')
    } catch (err) {
      console.error('Logout failed:', err);
    }
  };
  

    return (
        <div className="flex flex-col h-screen bg-gray-200 p-4 rounded m-1 overflow-auto max-w-md mx-auto ml-1 mr-1 font-poppins">
            {/* Logout */}
            <div className='flex justify-end cursor-pointer m-1 p-1' onClick={handleLogout}>  
              <div className='flex justify-center items-center rounded-full w-6 h-6 bg-gradient-to-r from-orange-800 to-orange-800 via-orange-600 shadow-xl shadow-gray-600'>
                <EtLogoutIcon1SVGComp className='h-4 w-4 text-white'/>
              </div>
            </div>
            {/* Trinity Bar */}
            <div className='flex justify-center items-center m-1 p-1'>
                <div className='flex justify-center items-center rounded-full shadow-lg shadow-gray-600 cursor-pointer'
                    onClick={() => navigate('/etapp/mobile')}
                >
                    <img src={TrinityIcon} alt="" className='h-24 w-24' />
                </div>
            </div>
            <div className='my-2 py-2 text-center border-t border-white'>
                <div className='flex justify-center items-center m-1 p-1'>
                    <div className="flex justify-center items-center rounded-full h-16 w-16 " ><EtWabActionIcon1SVGComp  className='h-16 w-16 text-orange-600'/>
                    </div>
                
                    <div>
                        <p className='font-bold text-2xl mx-1 px-1'>Actions</p>
                        <p className='text-xs text-gray-500 mx-1 px-1 italic'>My actions this week!</p>
                    </div>
                </div>
            </div>
            
          {/* Tab Navigation */}
          <div className="flex justify-around bg-white shadow-md p-2 rounded-lg mb-2">
            <div className={`flex justify-start items-center p-3 text-center ${activeTab === 'view' ? 'border-b-4 border-orange-500 text-orange-600' : 'text-gray-600'}`}
              onClick={() => setActiveTab('view')}>
                <div className='mx-1 px-1'><CursorArrowRippleIcon className='h-8 w-8 '/> </div>
                <button>
                    List 
                </button>
                <div className={`rounded-full flex justify-center items-center h-7 w-7 ${activeTab === 'view' ? 'border-orange-300': 'border-gray-600'} border  bg-gray-50 m-1`}><p className={`text-xs ${activeTab === 'view' ? 'text-orange-600' : 'text-gray-600'}`}>{userFilteredActions?.length || 0}</p></div>
            </div>
            <div className={`flex justify-center items-center p-3 text-center ${activeTab === 'add' ? 'border-b-4 border-orange-500 text-orange-600' : 'text-gray-600'}`}
              onClick={() => setActiveTab('add')}>
                <div className={`m-1 p-1 rounded ${activeTab === 'view' ? 'bg-gray-600': 'bg-lime-600'}`}><PlusIcon className='h-4 w-4 text-white'/> </div>
                <button>
                    Add 
                </button>
            </div>
          </div>
      
          {/* Tab Content */}
          <div className="flex-1 h-full py-2">
            {activeTab === 'view' ? (
              // Creations History
              <div className="space-y-1 overflow-y-auto">
                {userFilteredActions
                ?.slice()
                .sort(
                    (a, b) =>
                        new Date(b.updatedAt) - new Date(a.updatedAt)
                ) 
                ?.map((action, actionIndex) => {

                  const actionName = action?.userActionName || ""
                  const actionDesc = action?.userActionDesc || ""
                  const actionProgress = action?.userActionProgress || 0
                  const completeBy = action?.completeBy || ""
                  const lastUpdated = action?.updatedAt || ""
                  const actionNotes = action?.userActionNotes || []

                  return(
                    <div key={action._id+actionIndex} className="bg-gray-100 p-4 rounded-lg shadow-lg">
                      
                      <div className='grid grid-cols-4 sm:grid-cols-6 justify-center items-center cursor-pointer pb-3'>
                        <div className='col-span-1 flex justify-start items-center'>
                              <div
                                  onClick={() => handleToggleShowNoteDetails(action._id)}
                                  className="mx-2  rounded-full h-6 w-6 flex justify-start items-center hover:bg-white"
                                  >
                                  {showNoteDetails[action._id] ? (
                                      <MinusIcon className="h-5 w-5 text-orange-600" />
                                  ) : (
                                      <PlusIcon className="h-5 w-5 text-orange-600" />
                                  )}
                              </div>
                              <div className=''>
                                  <p className="text-lg sm:text-2xl font-bold text-gray-600">{ actionIndex +1}.</p>
                              </div>
                          </div>
                          <div className='col-span-3 sm:col-span-5 m-1 p-1'>
                            <p className='text-xs mx-2 px-2'>{actionName}</p>
                          </div>
                      </div>
                      {showNoteDetails[action._id] && actionDesc && (
                            <div className='bg-gray-50 m-1 p-1 rounded w-full'>
                              <p dangerouslySetInnerHTML={createMarkup(actionDesc)} className='text-xs m-1 p-1 leading-4'></p>
                            </div>
                      )}
                      <div className='border-t border-white grid grid-cols-5 justify-center items-center pt-2'>

                       <div className='col-span-1 flex justify-center items-center mx-1 px-1'>
                            <div className='h-10 w-10'>
                                <CircularProgressbarWithChildren value={actionProgress} circleRatio={0.75} styles={{
                                path: {
                                    stroke: '#552a9a',
                                    // stroke: pattern2,
                                    transform: 'rotate(-135deg)',
                                    transformOrigin: 'center center',
                                },
                                trail: {
                                    stroke: '#d6d6d6',
                                    // stroke: pattern2,
                                    transform: 'rotate(-135deg)',
                                    transformOrigin: 'center center',
                                },
                              }}>

                                  <div><p className='m-2 font-bold text-xs text-orange-600'>{actionProgress}%</p></div>

                              </CircularProgressbarWithChildren>
                            </div>     
                        </div>
                        <div className="col-span-2 flex flex-col justify-center items-center">
                              <p className="text-[10px] mx-1 rounded-full px-2 py-[1px] bg-lime-200">
                              {getTimeSince(lastUpdated)}
                              </p>
                              <p className="text-[8px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                          </div>
                          <div className="col-span-2 ">
                              <div className='flex justify-start items-center'>
                                  <CalendarDaysIcon className="m-2 h-5 w-5 text-orange-600" />
                                  <p className="text-gray-600 text-[9px]">Complete by:</p>
                              </div>
                              <p className="flex justify-center items-center text-[9px] rounded-full px-2 py-[1px] bg-white border text-gray-600 border-orange-400">
                                {formatDate(completeBy)}
                              </p>
                          </div>
                      </div>
                      
                      {showNoteDetails[action._id] &&  (
                        <div className='bg-white rounded mt-3 py-1'>

                          <div className='flex justify-between items-center'>
                            <p className='font-bold m-1 p-1 text-xs'>Action Progress Trail</p>
                            <div className='flex justify-start items-center p-1'>
                                {/* <PlusIcon className='h-4 w-4 bg-lime-600 rounded text-white'/> */}
                                <button 
                                  onClick={() => handleToggleProgressForm(action?._id, action?.userActionProgress)}
                                  className='flex justify-center items-center bg-lime-600 p-1 rounded text-white'
                                >
                                  <PlusIcon className='h-4 w-4'/>
                                </button>
                            </div>
                          </div>
                          {showProgressForm[action._id] && (
                              <div className="bg-white p-3 rounded my-2 shadow">

                                {/* Progress Slider & Number Input */}
                                <div className="flex items-center my-2">
                                  <p className='text-xs text-gray-400 mx-1 px-1 text-nowrap'>Progress (%) : </p>
                                  <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    step="1"
                                    value={progressValue}
                                    onChange={(e) => setProgressValue(Number(e.target.value))}
                                    className="w-full"
                                  />
                                  <input
                                    type="number"
                                    min="0"
                                    max="100"
                                    value={progressValue}
                                    onChange={(e) => setProgressValue(Number(e.target.value))}
                                    className="ml-2 border border-gray-300 rounded p-1 text-center"
                                  />
                                </div>
                                
                                {/* Progress Note Textarea */}
                                  <div className='relative'>
                                      <textarea
                                         id="actionProgress-textarea"
                                        value={progressNoteText}
                                        onChange={(e) => setProgressNoteText(e.target.value)}
                                        placeholder="Enter progress update..."
                                        rows={1}
                                        className="w-full border border-gray-300 rounded p-7 pr-20 pl-4 resize-y overflow-auto focus:ring-1 focus:ring-orange-500 text-xs"
                                      />
                                      {/* Voice Input & Submit Button */}
                                      <div className="absolute right-4 top-2/3 transform -translate-y-2/3 flex space-x-2">
                                        <button
                                          type="button"
                                          className={`p-2 rounded-full ${isRecording ? 'bg-red-600' : 'bg-lime-500'} text-white`}
                                          onClick={() => handleVoiceInput("progress")}
                                        >
                                          {isRecording ? <StopIcon className="h-5 w-5" /> : <MicrophoneIcon className="h-5 w-5" />}
                                        </button>

                                        <button
                                          onClick={() => handleUpdate(action._id)}
                                          className="p-2 bg-orange-500 text-white rounded-full hover:bg-orange-600"
                                        >
                                          <PaperAirplaneIcon className="h-5 w-5" />
                                        </button>
                                      </div>
                                    </div>
                                </div>
                            )}

                          {actionNotes?.length === 0 && (
                            <div>
                              <p className='text-orange-600 mx-1 px-1 text-xs'>No progress update has been recorded yet. Click the Add button to record one!</p>
                            </div>
                          )}
                          {actionNotes?.length >0 && actionNotes
                          ?.slice()
                          .sort(
                              (a, b) =>
                                  new Date(b.actionNoteDate) - new Date(a.actionNoteDate)
                          ) 
                          ?.map((note, noteIndex) => {
                            const noteText = note?.actionNoteText || ""
                            const progress = note?.actionNoteProgress || 0
                            const noteDate = note?.actionNoteDate || ""

                            return(
                              <div key={note._id + noteIndex} className='m-1 p-1 bg-gray-50 border rounded'>

                                <div className='grid grid-cols-6 justify-start items-center py-1'>  
                                  <div className='col-span-1 flex justify-center items-center'>
                                        <div className='h-8 w-8'>
                                            <CircularProgressbarWithChildren value={progress} circleRatio={0.75} styles={{
                                            path: {
                                                stroke: '#552a9a',
                                                // stroke: pattern2,
                                                transform: 'rotate(-135deg)',
                                                transformOrigin: 'center center',
                                            },
                                            trail: {
                                                stroke: '#d6d6d6',
                                                // stroke: pattern2,
                                                transform: 'rotate(-135deg)',
                                                transformOrigin: 'center center',
                                            },
                                          }}>

                                              <div><p className='text-[10px] font-bold'>{progress}%</p></div>

                                          </CircularProgressbarWithChildren>
                                      </div>    
                                  </div>
                                  <div className='col-span-5'>
                                      <p className='mx-1 px-1 text-xs'>{noteText}</p>
                                  </div>
                                </div>
                                <div className='flex justify-between items-center'>
                                  <div className='flex justify-start items-center mb-[2px]'>
                                    <div>
                                      <p className='text-[10px] text-gray-400 italic mx-1 px-1'>Last Updated: </p>
                                    </div>
                                    <div className='rounded-full bg-orange-400 inline-block'>
                                        <p className="text-[9px] mx-1 py-[2px] px-1 text-white ">
                                        {formatDate(noteDate)}
                                        </p>
                                    </div>
                                  </div>
                                  <div className='flex justify-end items-center'>
                                          <p className="text-[9px] mx-1 text-gray-400 italic">
                                            {getTimeSince(noteDate)}
                                          </p>
                                  </div>
                                </div>

                              </div>
                            )
                          })}

                        </div>
                      )}

                    </div>
                  )
                })}
                
              </div>
            ) : (
              // Add New Action - Centered Form
              <div>
                <div className="flex flex-col items-center justify-center h-full">
                    <div className="bg-gray-100 p-4 rounded-xl shadow-lg w-full max-w-md">
                      
                        {/* Trail Selection */}
                        <select
                          value={selectedTrailId}
                          onChange={(e) => {
                            setSelectedTrailId(e.target.value);
                            setSelectedSessionId(""); // Reset session selection on trail change
                          }}
                          className="w-full border border-gray-300 rounded-lg p-3 mb-3 focus:outline-none focus:ring-1 focus:ring-orange-500"
                        >
                          <option value="">Select Trail</option>
                          {trailOptions.map((trail) => (
                            <option key={trail.trailId} value={trail.trailId}>
                              {trail.trailName}
                            </option>
                          ))}
                        </select>

                        {/* Session Selection */}
                        <select
                          value={selectedSessionId}
                          onChange={(e) => setSelectedSessionId(e.target.value)}
                          className="w-full border border-gray-300 rounded-lg p-3 mb-3 focus:outline-none focus:ring-1 focus:ring-orange-500"
                          disabled={!selectedTrailId}
                        >
                          <option value="">Select Session</option>
                          {selectedTrailId &&
                            trails[selectedTrailId]?.sessions.map((session) => (
                              <option key={session.sessionId} value={session.sessionId}>
                                {session.sessionName}
                              </option>
                            ))}
                        </select>


                        {/* Action Type Selection */}
                        <select
                          value={selectedActionTypeId}
                          onChange={(e) => setSelectedActionTypeId(e.target.value)}
                          className="w-full border border-gray-300 rounded-lg p-3 mb-3 focus:outline-none focus:ring-1 focus:ring-orange-500"
                        >
                          <option value="">Select Action Type</option>
                          {actionTypes?.map((type) => (
                            <option key={type._id} value={type._id}>
                              {type.actionTypeName}
                            </option>
                          ))}
                        </select>

                        {/* Action Name Input */}
                        <input
                          type="text"
                          value={actionName}
                          onChange={(e) => setActionName(e.target.value)}
                          placeholder="Enter action name..."
                          className="w-full border border-gray-300 rounded-lg p-3 mb-3 focus:outline-none focus:ring-1 focus:ring-orange-500"
                        />

                        {/* Completion Date */}
                        <label className="text-gray-800 text-sm font-bold m-1">Complete By</label>
                        <input
                          type="date"
                          value={completionDate}
                          onChange={(e) => setCompletionDate(e.target.value)}
                          className="w-full border border-gray-300 rounded-lg p-3 mb-3 focus:outline-none focus:ring-1 focus:ring-orange-500"
                        />

                        
                        {/* Creation Desc Textarea */}
                        <div className='relative'>
                          <textarea
                              id="action-textarea"
                              ref={textareaRef}  // Attach the ref here
                              value={actionDesc}
                              onChange={(e) => setActionDesc(e.target.value)}
                              placeholder="Enter action details here..."
                              rows={1}
                              className="w-full border border-gray-300 rounded-lg p-7 pr-20 pl-4 focus:outline-none focus:ring-1 focus:ring-orange-500 md:text-lg resize-y overflow-auto"
                              onInput={(e) => {
                                  e.target.style.height = "auto"; 
                                  e.target.style.height = `${e.target.scrollHeight}px`;
                              }}
                          />
                          <div className="absolute right-4 top-2/3 transform -translate-y-2/3 flex space-x-2">
                              {/* Voice Input Button */}
                              <button
                                  type="button"
                                  className={`p-2 rounded-full ${isRecording ? 'bg-red-600' : 'bg-lime-500'} text-white`}
                                  onClick={() => handleVoiceInput("action")}
                              >
                                  {isRecording ? <StopIcon className="h-5 w-5" /> : <MicrophoneIcon className="h-5 w-5" />}
                              </button>
                              
                              {/* Send Button */}
                              <button
                                  onClick={() => {
                                    setIsConfirmModalOpen(true);
                                  }}
                                  className="p-2 bg-orange-500 text-white rounded-full hover:bg-orange-600"
                              >
                                  <PaperAirplaneIcon className="h-5 w-5" />
                              </button>
                          </div>
                        </div>
                    </div>
                </div>
              </div>
            )}
          </div>

          {isModalOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                <h2 className="text-lg font-semibold mb-4">Review and Edit</h2>

                {loading ? (
                  <div className="flex justify-start items-center">
                      <p className='text-sm mx-1 px-1'>Generating response....</p>
                      <div className="bg-orange-600 p-2 rounded">
                        <PulseLoader color={"#fff"} />
                      </div>
                  </div>
                ) : (
                  <>

                    <div className="mb-4">
                      <h3 className="text-sm font-bold text-gray-600">Original Note</h3>
                      <textarea
                        value={actionDesc}
                        readOnly
                        rows={4}
                        className="w-full border border-gray-300 rounded-lg p-3 bg-gray-100 focus:outline-none overflow-y-auto"
                      />
                    </div>

                    
                    <div className="mb-4">
                      <h3 className="text-sm font-bold text-gray-600">Trinity Refined Note</h3>
                        {(()=>{
                           const plainTextResponse = aiGeneratedResponse.replace(/<[^>]+>/g, ''); // Strip all HTML tags
                          return(
                            <textarea
                              // value={aiGeneratedResponse}
                              value={plainTextResponse}
                              onChange={(e) => setAiGeneratedResponse(e.target.value)}
                              rows={6}
                              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none resize-y"
                            />
                          )
                      })()}
                    </div>
                 
                <div className="flex justify-end space-x-4 mt-4">
                  <button
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                    onClick={() => setIsModalOpen(false)}
                  >
                    Cancel
                  </button>
                  
                  <button
                    className="px-4 py-2 bg-orange-600 text-white rounded hover:bg-orange-700"
                    onClick={() => {
                      const combinedText = `<strong>My Note:</strong>\n\n${actionDesc}\n\n<strong>Trinity Refined:</strong>\n\n${aiGeneratedResponse}`;
                      setActionDesc(combinedText); // Set combined text 
                      setIsModalOpen(false);
                      handleSubmit(combinedText); // Trigger the final submission
                    }}
                    disabled={loading} // Disable the button while loading
                  >
                    Confirm
                  </button>
                </div>
                </>)}
              </div>
            </div>
          )}

          {isConfirmModalOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                <h2 className="text-lg font-semibold mb-4">Need Trinity's touch?</h2>
                <p className="text-sm text-gray-600 mb-4">
                  Would you like to refine your note with Trinity, or submit it directly?
                </p>
                <div className="flex justify-end space-x-4">
                  <button
                    className="p-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                    onClick={() => {
                      setIsConfirmModalOpen(false); // Close the modal
                      handleSubmit(actionDesc); // Submit without AI
                    }}
                  >
                   Submit Directly
                  </button>
                  <button
                    className="p-2 bg-orange-600 text-white rounded hover:bg-orange-700"
                    onClick={() => {
                      setIsConfirmModalOpen(false); // Close the modal
                      handleGenerateAIResponse(); // Trigger AI summarization
                    }}
                  >
                    Refine with Trinity
                  </button>
                </div>
              </div>
            </div>
          )}


        </div>
      );
      
      
};

export default EtTrinityUserActionMobile;