import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const legacyTypesAdapter = createEntityAdapter({})

const initialState = legacyTypesAdapter.getInitialState()

export const legacyTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getLegacyTypes: builder.query({
            query: () => ({
                url: '/legacyTypes/getLegacyTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedLegacyTypes = responseData.map(legacyType => {
                    legacyType.id = legacyType._id
                    return legacyType
                })
                return legacyTypesAdapter.setAll(initialState, loadedLegacyTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'LegacyType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'LegacyType', id}))
                    ]
                } else {
                    return [ { type: 'LegacyType', id: 'LIST'}]
                }
            },
        }),
        createNewLegacyType: builder.mutation({
            query: initialLegacyType => ({
                url: '/legacyTypes/createNewLegacyType',
                method: 'POST',
                body: {...initialLegacyType,}
            }),
            invalidatesTags: [
                { type: 'LegacyType', id: "LIST" }
            ] 
        }),
        updateLegacyType: builder.mutation({
            query: initialLegacyType => ({
                url: '/legacyTypes/updateLegacyType',
                method: 'PATCH',
                body: {...initialLegacyType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'LegacyType', id: arg.id },
                { type: 'LegacyType', id: 'LIST' }
            ],
        }),
        deleteLegacyType: builder.mutation({
            query: ({id}) => ({
                url: '/legacyTypes/deleteLegacyType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'LegacyType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetLegacyTypesQuery,
    useCreateNewLegacyTypeMutation,
    useUpdateLegacyTypeMutation,
    useDeleteLegacyTypeMutation,
} = legacyTypesApiSlice

// returns the query result object
export const selectLegacyTypeResult = legacyTypesApiSlice.endpoints.getLegacyTypes.select()

// create memoized selector
const selectLegacyTypesData = createSelector(
    selectLegacyTypeResult,
    legacyTypeResult => legacyTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllLegacyTypes,
    selectById: selectLegacyTypeById,
    selectIds: selectLegacyTypeIds,
} = legacyTypesAdapter.getSelectors(state => selectLegacyTypesData(state) ?? initialState)