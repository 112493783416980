import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userOrgSubscriptionsAdapter = createEntityAdapter({})

const initialState = userOrgSubscriptionsAdapter.getInitialState()

export const userOrgSubscriptionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserOrgSubscriptions: builder.query({
            query: () => ({
                url: '/userOrgSubscriptions/getUserOrgSubscriptions',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserOrgSubscriptions = responseData.map(userOrgSubscription => {
                    userOrgSubscription.id = userOrgSubscription._id
                    return userOrgSubscription
                })
                return userOrgSubscriptionsAdapter.setAll(initialState, loadedUserOrgSubscriptions)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserOrgSubscription', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserOrgSubscription', id}))
                    ]
                } else {
                    return [ { type: 'UserOrgSubscription', id: 'LIST'}]
                }
            },
        }),
        createNewUserOrgSubscription: builder.mutation({
            query: initialUserOrgSubscription => ({
                url: '/userOrgSubscriptions/createNewUserOrgSubscription',
                method: 'POST',
                body: {...initialUserOrgSubscription,}
            }),
            invalidatesTags: [
                { type: 'UserOrgSubscription', id: "LIST" }
            ] 
        }),
        updateUserOrgSubscription: builder.mutation({
            query: initialUserOrgSubscription => ({
                url: '/userOrgSubscriptions/updateUserOrgSubscription',
                method: 'PATCH',
                body: {...initialUserOrgSubscription,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserOrgSubscription', id: arg.id },
                { type: 'UserOrgSubscription', id: 'LIST' }
            ],
        }),
        deleteUserOrgSubscription: builder.mutation({
            query: ({id}) => ({
                url: '/userOrgSubscriptions/deleteUserOrgSubscription',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserOrgSubscription', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserOrgSubscriptionsQuery,
    useCreateNewUserOrgSubscriptionMutation,
    useUpdateUserOrgSubscriptionMutation,
    useDeleteUserOrgSubscriptionMutation,
} = userOrgSubscriptionsApiSlice

// returns the query result object
export const selectUserOrgSubscriptionResult = userOrgSubscriptionsApiSlice.endpoints.getUserOrgSubscriptions.select()

// create memoized selector
const selectUserOrgSubscriptionsData = createSelector(
    selectUserOrgSubscriptionResult,
    userOrgSubscriptionResult => userOrgSubscriptionResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserOrgSubscriptions,
    selectById: selectUserOrgSubscriptionById,
    selectIds: selectUserOrgSubscriptionIds,
} = userOrgSubscriptionsAdapter.getSelectors(state => selectUserOrgSubscriptionsData(state) ?? initialState)