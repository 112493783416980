import React, { useState, useEffect } from 'react'
import useTitle from "../../../hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
    PencilSquareIcon,
  } from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "../../../utils/EtContentEditorLight2"
import EtBtnFormBasic from "../../../components/common/buttons/EtBtnFormBasic"
import { useNavigate } from "react-router"

import { useUpdateUserGratitudeImprintMutation, useGetUserGratitudeImprintsQuery } from '@/features/userGratitudeImprints/userGratitudeImprintsApiSlice'
import { useGetContactTypesQuery } from '@/features/contactTypes/contactTypesApiSlice'
import { useGetGratitudeTypesQuery } from '@/features/gratitudeTypes/gratitudeTypesApiSlice'
import { useGetContactsQuery } from '@/features/contacts/contactsApiSlice'

const EtUpdateUserImprintGratitudeComp = ({
    id,
    userId, 
    userOrgId, 
    triggeringUrl, 
    onClose
  }) => {

  // First Created - 18 Dec 2024
  // Author - Atul Pandey
  // Inherited from the EtCreateUserImprintCreationComp component
  // Updated - 20 Dec 2024 - Added the main logic

  // 0 - Set Page title & variables
  useTitle('User Imprint Gratitude - Update')

  // console.log('id is:', id)
  // console.log('userId is:', userId)
  // console.log('userOrgId is:', userOrgId)
  
  // 1a - set page navigations
  const navigate = useNavigate()

  // 1b - set form states
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState({}); // 05 Dec 2024
  const [editorKey, setEditorKey] = useState('gratitudeDesc-' + Date.now());
  const [editorValue, setEditorValue] = useState('');

  // 2a - set the mutation to create the new user imprint gratitude data
    const [updateUserGratitudeImprint, {
      isLoading,
      isSuccess,
      isError,
      error,
    }] = useUpdateUserGratitudeImprintMutation()
  
  // 2b - Get existing user creations Data from the backend
  const { 
    data: userGratitudeImprintData,
    isLoading: isFetching,
    isError: isFetchError,
    error: fetchError,
  } = useGetUserGratitudeImprintsQuery()
  console.log('userGratitudeImprintData', userGratitudeImprintData)
  const userGratitudeImprintRecords = userGratitudeImprintData?.ids
  ?.map(id => userGratitudeImprintData?.entities[id])
  ?.filter(item=> item._id === id && item.userId._id === userId && item.userOrgId._id === userOrgId) || []
  console.log('userGratitudeImprints', userGratitudeImprintRecords)

  // 2c - Get Data for building User Gratitude Imprints
  const { data: contactTypesData} = useGetContactTypesQuery()
  const { data: gratitudeTypesData} = useGetGratitudeTypesQuery()
  const { data: contactsData} = useGetContactsQuery()

  const extractEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id]);
  };

  const contactTypes = extractEntitiesFromAdapter(contactTypesData);
  const gratitudeTypes = extractEntitiesFromAdapter(gratitudeTypesData);
  const contacts = extractEntitiesFromAdapter(contactsData);

  // 2d: Filter contacts for the given userId
  const filteredContacts = contacts?.filter((contact) => {
    const isOwner = contact.contactOwnerId._id === userId; // Check if the contactOwnerId matches the userId
    const isSharedWithUser = contact.sharedWith.some(
      (shared) => shared.userId._id === userId // Check if userId is in the sharedWith array
    );
    return isOwner || isSharedWithUser; // Return true if either condition is met
  });
  console.log('filteredContacts', filteredContacts);

  // 3 - Initialize Form Data State
  const [formData, setFormData] = useState({
      userGratitudeTypeId: null,
      userGratitudeTargetId: null,
      userGratitudeFor: null,
      userGratitudeNoteText: "",
      userGratitudeNoteDate: "",
      userGratitudeNoteUpdateDate:"",
      pic: "",
      active: true, // Default active state
  });

  // 4 - Set the FormData based on the incoming data
  useEffect(() => {
    if (userGratitudeImprintRecords?.length > 0 && !isFormInitialized) {
      const selectedRecord = userGratitudeImprintRecords[0];

      setFormData({
        userGratitudeId: selectedRecord.userGratitudeId || null,
        userGratitudeTypeId: selectedRecord.userGratitudeTypeId?._id || null,
        userGratitudeTargetId: selectedRecord.userGratitudeTargetId?._id || null,
        userGratitudeFor: selectedRecord.userGratitudeFor?._id || null,
        userGratitudeNoteText: selectedRecord.userGratitudeNoteText || '',
        userGratitudeNoteDate: selectedRecord.userGratitudeNoteDate || '',
        userGratitudeNoteUpdateDate: selectedRecord.userGratitudeNoteUpdateDate || '',
        aboutMe: selectedRecord.aboutMe || '',
        pic: selectedRecord.pic || '',
        active: selectedRecord.active ?? true,
      });

      setIsFormInitialized(true); // Prevent future overwrites
    }
  }, [userGratitudeImprintRecords, isFormInitialized]);
  
  // 5a - Convert Image to Base64
  const convertPicToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  // 5b - Handle Form Changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "pic") {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  // 5c - Toggle show / collapse each section 
  const toggleSection = (key) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // 6 - Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
  
    // Trim the gratitude note text
    const trimmedNoteText = formData.userGratitudeNoteText?.trim();
  
    // Validate that the trimmed note is not empty
    if (!trimmedNoteText) {
      console.error("Gratitude note cannot be empty.");
      return;
    }
  
    try {
      // Prepare the payload for the API call
      const payload = {
        imprintId: id, // Unique identifier for the record being updated
        userId,
        userOrgId,
        userGratitudeId: formData.userGratitudeId || null, // Optional
        userGratitudeTypeId: formData.userGratitudeTypeId,
        userGratitudeTargetId: formData.userGratitudeTargetId,
        userGratitudeFor: formData.userGratitudeFor || null, // Optional
        userGratitudeNoteText: trimmedNoteText,
        userGratitudeNoteUpdateDate: new Date(), // Update date
        aboutMe: formData.aboutMe || null, // Optional
        pic: formData.pic || null, // Optional
      };
  
      // Invoke the mutation
      await updateUserGratitudeImprint(payload).unwrap();
  
      // Log success
      console.log("Gratitude Imprint updated successfully!");
  
      // Reset the form upon success
      setFormData({
        userGratitudeTypeId: null,
        userGratitudeTargetId: null,
        userGratitudeFor: null,
        userGratitudeNoteText: "",
        userGratitudeNoteDate: "",
        userGratitudeNoteUpdateDate: "",
        pic: "",
        active: true, // Default active state
      });
  
      // Handle navigation or closure
      if (onClose) {
        onClose();
      } else {
        navigate(triggeringUrl || "/userMagicHome");
      }
    } catch (err) {
      console.error("Failed to update Gratitude Imprint:", err);
    }
  };
  

  // 7 - Reset Form on Successful Submission
        useEffect(() => {
          if (isSuccess) {
            setFormData({
              userGratitudeTypeId: null,
              userGratitudeTargetId: null,
              userGratitudeFor: null,
              userGratitudeNoteText: "",
              userGratitudeNoteDate: "",
              userGratitudeNoteUpdateDate:"",
              pic: "",
              active: true, // Default active state
            });
      
            if (onClose) {
              onClose();
            } else {
              navigate(triggeringUrl || "/userMagicHome");
            }
          }
        }, [isSuccess, navigate]);
      
      // 8 - Handle Clear
      const handleClear = () => {
      setFormData({
          userGratitudeTypeId: null,
          userGratitudeTargetId: null,
          userGratitudeFor: null,
          userGratitudeNoteText: "",
          userGratitudeNoteDate: "",
          userGratitudeNoteUpdateDate:"",
          pic: "",
          active: true, // Default active state
      });
      };
  
      // 9 - Handle Cancel
      const handleCancel = () => {
      if (onClose) {
          onClose();
      } else {
          navigate(triggeringUrl || "/userMagicHome");
      }
      };



  return (
    <div className="mx-auto w-full m-1 p-1 bg-white rounded shadow-md shadow-black">
      {isLoading && <PulseLoader color={'#ea580c'} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">{error?.data?.message}</p>
      )}

      <p className="p-1 m-1 font-bold font-poppins text-xl">
        Update User Imprint Gratitude
      </p>

      <form onSubmit={handleSubmit} className="m-1 p-1 text-xs font-poppins">
        {/* 1. Gratitude Type - i.e. Gratitude Type - i.e. Enablement, Opportunities */}
        <div id="gratitudeType" className="bg-gray-100 rounded p-1 m-1">
            <label className="block text-xs font-bold mb-1" htmlFor="gratitudeType">
                Gratitude Type
            </label>
            <select
                id="gratitudeType"
                name="userGratitudeTypeId"
                value={formData.userGratitudeTypeId || ''}
                onChange={handleChange}
                className="w-full p-2 border rounded text-xs"
                required
            >
                <option value="">Select Gratitude Type</option>
                {gratitudeTypes?.map((type) => (
                <option key={type._id} value={type._id}>
                    {type.gratitudeTypeName}
                </option>
                ))}
            </select>
        </div>

        {/* 2. Gratitude Target - i.e. Contact Type - i.e. Clients, Partners */}
        <div id="gratitudeTarget" className="bg-gray-100 rounded p-1 m-1">
            <label className="block text-xs font-bold mb-1" htmlFor="gratitudeTarget">
                Gratitude Target
            </label>
            <select
                id="gratitudeTarget"
                name="userGratitudeTargetId"
                value={formData.userGratitudeTargetId || ''}
                onChange={handleChange}
                className="w-full p-2 border rounded text-xs"
                required
            >
                <option value="">Select Contact Type</option>
                {contactTypes?.map((type) => (
                <option key={type._id} value={type._id}>
                    {type.contactTypeName}
                </option>
                ))}
            </select>
        </div>       
        
        {/* 3. Gratitude For - i.e. specific contact from the filtered contacts list (optional)*/}
        <div id="select-specific-contact" className="bg-gray-100 rounded p-1 m-1">
            <label className="block text-xs font-bold mb-1" htmlFor="specificContact">
                Specific Contact (Optional)
            </label>
            <select
                id="specificContact"
                name="userGratitudeFor"
                value={formData.userGratitudeFor || ''}
                onChange={handleChange}
                className="w-full p-2 border rounded text-xs"
            >
                <option value="">Select Specific Contact</option>
                {filteredContacts
                ?.filter(
                    (contact) =>
                    formData.userGratitudeTargetId &&
                    contact.contactTypeId?._id === formData.userGratitudeTargetId
                )
                ?.map((contact) => (
                    <option key={contact._id} value={contact._id}>
                    {contact.contactName || 'Unnamed Contact'}
                    </option>
                ))}
            </select>
        </div>

        {/* 4. Gratitude Note - using the editor */}
        <div id="gratitudeNote" className="bg-gray-100 rounded p-1 m-1">
            <label className="block text-xs font-bold mb-1" htmlFor="gratitudeNote">
                Gratitude Note
            </label>
            <EtContentEditorLight2
                value={formData.userGratitudeNoteText || ""}
                handleChange={(content) => {
                    setFormData((prevState) => ({
                    ...prevState,
                    userGratitudeNoteText: content,
                    }));
                }}
                placeholderText="Write your gratitude note here..."
                editorKey={editorKey}
            />
        </div>
        
        {/* 5. Form Submissions Bar */}
        <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic 
              name={'Update'} 
              type={'create'} 
              handleClick={handleSubmit} 
              />
            <EtBtnFormBasic
              name={'Clear'}
              type={'clear'}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={'Cancel'}
              type={'cancel'}
              handleClick={handleCancel}
            />
        </div>



      </form>
      
    </div>
  )
}

export default EtUpdateUserImprintGratitudeComp