import React, {useState} from 'react'
import { useNavigate } from 'react-router'
import { addIcon } from '@/assets/img/imgAssets'
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import PulseLoader from "react-spinners/PulseLoader"
import { useGetActionTypesQuery } from './actionTypesApiSlice'

const EtActionTypeList = ({ setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }) => {

  // First Created - 07 Jun 2024
  // Author - Atul Pandey
  // Updated - 26 Feb 2025 - updated the function with props - { setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }
  // Updated - 26 Feb 2025 - switched from navigate to onAdd, onUpdate, onDelete, setSelectedEntityId
    
  // 1 - Set navigation
  const navigate = useNavigate()
  
  // 2 - Initialize showDetails state as an object where key is the actionType index
  const [showDetails, setShowDetails] = useState(null)
  const {data: actionTypesData, isError, error, isLoading} = useGetActionTypesQuery()
  // console.log('actionTypesData', actionTypesData)
  
  // 3 - Toggle the show/hide state for the specific actionType index
  const handleToggle = (index) => {
        setShowDetails(prev => prev === index ? null : index);
  }
    
  // 4 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
     }
  

  // 5 -  Optional: handle loading and error states
    if (isLoading) return <PulseLoader color={"#ea580c"} />
    if (isError) return <div>Error occurred: {error?.data?.message}</div>


  return (
    <div className='bg-gray-200 m-1 p-1 rounded'>
        {/* Header */}
        <div className='flex justify-start items-center m-1 p-1'>

            <div><p className='font-poppins text-2xl m-1 p-1 font-bold'>Et Action Types</p></div>
            <div>
          <img
            src={addIcon}
            alt=""
            className="h-10 w-10 cursor-pointer"
            // onClick={() => navigate("/actiontypes/create")}
            onClick={onAdd} // 26 Feb 2025
          />
        </div>

        </div>

        {/* ActionType Data */}
        <div id='actionType-data' className='bg-white rounded m-1 p-1 font-poppins'>
            {actionTypesData && actionTypesData?.ids.map((id, index) => {
                const {actionTypeName, actionTypeDesc, aboutMe} = actionTypesData.entities[id]
                return(
                <div key={index} className=' bg-gray-200 rounded m-1 p-1'>
                    <div className='flex justify-between items-center'>

                        <div className='flex justify-start items-center mx-2'>

                          <div
                                      onClick={() => handleToggle(index)}
                                      className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white"
                                  >
                                      {showDetails === index ? (
                                      <MinusIcon className="h-3 w-3 text-orange-600 " />
                                      ) : (
                                      <PlusIcon className="h-3 w-3 text-orange-600 " />
                                      )}
                                  </div> 
                        <div className='m-1 p-1' ><p className='text-sm font-bold '>{index+1} - {actionTypeName}</p></div>
                        </div>

                        <div className='flex justify-start items-center'>

                                <div 
                                // onClick={() => navigate(`/actionTypes/update/${id}`)} 
                                onClick={() => onUpdate(id)} // 26 Feb 2025
                                
                                className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'>
                                    <PencilSquareIcon className="h-3 w-3 text-orange-600 " />
                                </div>

                                <div 
                                // onClick={() => navigate(`/actiontypes/delete/${id}`)} 
                                onClick={() => onDelete(id)} // 26 Feb 2025 
                                
                                className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'>
                                    <TrashIcon className="h-3 w-3 text-orange-600 " />
                                </div>
                                    
                                
                            </div>
                      </div>

                      {showDetails === index && (
                        <div  className=' bg-white rounded text-xs py-1'>
                            <div className='m-1 p-1'><p className='font-bold mx-2 px-2'><span className='text-black mx-1 py-1 border-b-2 border-orange-600'>Action Type:</span><span className='mx-2 font-normal'>{actionTypeName}</span></p> </div>
                            <div className='m-2 p-2 rounded border border-gray-200' > <p className='px-2 leading-4' dangerouslySetInnerHTML={createMarkup(actionTypeDesc)}></p></div>
                            {aboutMe && (
                            <div key={actionTypeName+index} className='m-1 p-1'><p className='px-2 leading-4' dangerouslySetInnerHTML={createMarkup(aboutMe)}></p></div>
                                )}

                        </div>
                      )
                        
                        
                      }
                </div>
            )})}

        </div>

    </div>
  )
}

export default EtActionTypeList