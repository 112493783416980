
    import React from 'react';
    
    const EtCapabilitiesYearsIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" fill="currentColor">
          <g transform="translate(-744 -99)"><path d="M814.176 179.82 836.463 192.69C839.258 194.31 840.209 197.888 838.595 200.683 836.975 203.479 833.397 204.429 830.602 202.809L805.413 188.252C803.669 187.24 802.499 185.355 802.499 183.195L802.499 144.22C802.499 140.997 805.115 138.381 808.338 138.381 811.561 138.381 814.177 140.997 814.177 144.22ZM759.32 178.65C759.32 151.576 781.263 129.634 808.336 129.634L864.728 129.634 859.502 135.04C857.264 137.368 857.331 141.07 859.666 143.308 861.994 145.547 865.696 145.474 867.934 143.151L882.441 128.143C883.644 127.069 884.398 125.511 884.398 123.772 884.398 122.034 883.644 120.476 882.441 119.402L867.934 104.394C865.696 102.065 861.994 101.998 859.666 104.237 857.337 106.475 857.264 110.177 859.502 112.505L864.728 117.911 808.336 117.911C774.794 117.905 747.596 145.103 747.596 178.651 747.596 212.199 774.793 239.403 808.336 239.403 841.884 239.403 869.076 212.2 869.076 178.651 869.076 175.417 866.449 172.79 863.215 172.79 859.975 172.79 857.354 175.417 857.354 178.651 857.354 205.725 835.411 227.671 808.342 227.671 781.268 227.671 759.326 205.723 759.326 178.651Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtCapabilitiesYearsIcon1SVGComp;
    