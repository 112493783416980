
    import React from 'react';
    
    const EtUserIconSVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192" fill="currentColor">
          <g transform="translate(-1976 -238)"><path d="M2072 328C2091.88 328 2108 311.882 2108 292 2108 272.118 2091.88 256 2072 256 2052.12 256 2036 272.118 2036 292 2036 311.882 2052.12 328 2072 328ZM2072 340C2037.67 340 2009.47 373.626 2006.3 406.011 2005.98 409.309 2008.69 412.001 2012 412.001L2132 412.001C2135.32 412.001 2138.03 409.309 2137.71 406.011 2134.53 373.626 2106.33 340 2072 340ZM2102 388C2102 391.314 2099.31 394 2096 394L2084 394C2080.69 394 2078 391.314 2078 388 2078 384.686 2080.69 382 2084 382L2096 382C2099.31 382 2102 384.686 2102 388Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtUserIconSVGComp;
    