import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const capabilitiesTechStackTypesAdapter = createEntityAdapter({})

const initialState = capabilitiesTechStackTypesAdapter.getInitialState()

export const capabilitiesTechStackTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCapabilitiesTechStackTypes: builder.query({
            query: () => ({
                url: '/capabilitiesTechStackTypes/getCapabilitiesTechStackTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedCapabilitiesTechStackTypes = responseData.map(capabilitiesTechStackType => {
                    capabilitiesTechStackType.id = capabilitiesTechStackType._id
                    return capabilitiesTechStackType
                })
                return capabilitiesTechStackTypesAdapter.setAll(initialState, loadedCapabilitiesTechStackTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'CapabilitiesTechStackType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'CapabilitiesTechStackType', id}))
                    ]
                } else {
                    return [ { type: 'CapabilitiesTechStackType', id: 'LIST'}]
                }
            },
        }),
        createNewCapabilitiesTechStackType: builder.mutation({
            query: initialCapabilitiesTechStackType => ({
                url: '/capabilitiesTechStackTypes/createNewCapabilitiesTechStackType',
                method: 'POST',
                body: {...initialCapabilitiesTechStackType,}
            }),
            invalidatesTags: [
                { type: 'CapabilitiesTechStackType', id: "LIST" }
            ] 
        }),
        updateCapabilitiesTechStackType: builder.mutation({
            query: initialCapabilitiesTechStackType => ({
                url: '/capabilitiesTechStackTypes/updateCapabilitiesTechStackType',
                method: 'PATCH',
                body: {...initialCapabilitiesTechStackType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'CapabilitiesTechStackType', id: arg.id },
                { type: 'CapabilitiesTechStackType', id: 'LIST' }
            ],
        }),
        deleteCapabilitiesTechStackType: builder.mutation({
            query: ({id}) => ({
                url: '/capabilitiesTechStackTypes/deleteCapabilitiesTechStackType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'CapabilitiesTechStackType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetCapabilitiesTechStackTypesQuery,
    useCreateNewCapabilitiesTechStackTypeMutation,
    useUpdateCapabilitiesTechStackTypeMutation,
    useDeleteCapabilitiesTechStackTypeMutation,
} = capabilitiesTechStackTypesApiSlice

// returns the query result object
export const selectCapabilitiesTechStackTypeResult = capabilitiesTechStackTypesApiSlice.endpoints.getCapabilitiesTechStackTypes.select()

// create memoized selector
const selectCapabilitiesTechStackTypesData = createSelector(
    selectCapabilitiesTechStackTypeResult,
    capabilitiesTechStackTypeResult => capabilitiesTechStackTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCapabilitiesTechStackTypes,
    selectById: selectCapabilitiesTechStackTypeById,
    selectIds: selectCapabilitiesTechStackTypeIds,
} = capabilitiesTechStackTypesAdapter.getSelectors(state => selectCapabilitiesTechStackTypesData(state) ?? initialState)