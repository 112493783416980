
    import React from 'react';
    
    const EtDocSelfNoteAllUsersIconSVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m2250 562.5h-1237.5c-59.672 0-116.9 23.707-159.1 65.902-42.195 42.195-65.902 99.426-65.902 159.1v2025c0 39.496 10.395 78.297 30.145 112.5 19.746 34.203 48.152 62.609 82.355 82.355 34.203 19.75 73.004 30.145 112.5 30.145h1575c39.496 0 78.297-10.395 112.5-30.145 34.203-19.746 62.609-48.152 82.355-82.355 19.75-34.203 30.145-73.004 30.145-112.5v-1687.5zm-562.5 2025h-675v-225h675zm225-450h-900v-225h900zm337.5-900c-29.836 0-58.453-11.852-79.551-32.949-21.098-21.098-32.949-49.715-32.949-79.551v-337.5l450 450z" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtDocSelfNoteAllUsersIconSVGComp;
    