import React, { useEffect, useRef } from 'react';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

// ✅ Register the required scales & elements
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const EtChartComponent = ({ data }) => {
    // First Created - 28 Jan 2025
    // Author - Atul Pandey
    // Purpose to render charts by Trinity AI

    const options = {
        responsive: true,
        maintainAspectRatio: false, // Changed to false
        animation: {
            duration: 0
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Trinity Chart'
            }
        }
    };

    
    const chartData = {
        labels: data.map(d => d.label),
        datasets: [
            {
                label: "Trinity Chart!",
                data: data.map(d => d.value),
                backgroundColor: "rgba(255, 99, 132, 0.4)"
            }
        ]
    };

    return (
        <div className="h-[300px] w-full">
            <Bar data={chartData} options={options} />
        </div>
    );
}

export default EtChartComponent