import React, { useEffect, useState, useCallback } from "react"
import { useParams, useNavigate } from "react-router-dom"
import useTitle from "../../hooks/useTitle"
import { addIcon, userProfileIcon2 } from "../../assets/img/imgAssets"
import {
  PlusIcon,
  MinusIcon,
  TrashIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline"
import {
  UserGroupIcon,
  BuildingOffice2Icon,
  ArrowTrendingUpIcon,
  CalendarDaysIcon,
  Square3Stack3DIcon,
  ListBulletIcon,
  SwatchIcon,
  InformationCircleIcon,
  ClockIcon,
  ArrowLeftCircleIcon,
  WrenchIcon,
  RocketLaunchIcon,
  DocumentTextIcon,
  CursorArrowRippleIcon,
  SunIcon,
} from "@heroicons/react/24/solid"
import EtContentEditorLight2 from "../../utils/EtContentEditorLight2"
// import EtBtnFormBasic from "../../components/common/buttons/EtBtnFormBasic"
import { getTimeSince } from "../../utils/EtGetTimeSince"
import { formatDate, formatDateTime } from "../../utils/formatDate"
import PulseLoader from "react-spinners/PulseLoader"
import {
  useGetCoachOrgTrailSessionPadsQuery,
  useUpdateCoachOrgTrailSessionPadMutation,
} from "./coachOrgTrailSessionPadsApiSlice"
import { useGetCoachUserOrgTrailSessionActionsQuery } from "../coachUserOrgTrailSessionActions/coachUserOrgTrailSessionActionsApiSlice" // 01 Sep 2024
import { useGetUserPicQuery } from "../users/usersApiSlice"
import EtDocSelfNoteAllUsersIconSVGComp from "@/assets/img/icons/svgComp/EtDocSelfNoteAllUsersIconSVGComp"
import EtDocSelfUserNoteIconSVGComp from "@/assets/img/icons/svgComp/EtDocSelfUserNoteIconSVGComp"
import EtDocSharedNoteAllUsersIconSVGComp from "@/assets/img/icons/svgComp/EtDocSharedNoteAllUsersIconSVGComp"
import EtDocSharedUserNoteIconSVGComp from "@/assets/img/icons/svgComp/EtDocSharedUserNoteIconSVGComp"
// 26 Aug 2024
import EtCoachEvalIcon2SVGComp from "@/assets/img/icons/svgComp/EtCoachEvalIcon2SVGComp"
// import EtModalCreateUserChallengeCoachEval from "@/components/common/modal/EtModalCreateUserChallengeCoachEval"
import EtModalAddOrUpdateUserChallengeCoachEval from "@/components/common/modal/EtModalAddOrUpdateUserChallengeCoachEval"
// 10 Sep 2024
import EtModalAddOrUpdateCoachUserOrgTrailSessionAction from "@/components/common/modal/EtModalAddOrUpdateCoachUserOrgTrailSessionAction"
import EtCoachUserActionIcon1SVGComp from "@/assets/img/icons/svgComp/EtCoachUserActionIcon1SVGComp"
import EtUserActionListByIdComp from "../userActions/EtUserActionListByIdComp" // 02 Sep 2024 - to show user action progress

const EtCoachOrgTrailSessionPadCompProd = () => {
  // First Created - 22 Oct 2024
  // Author - Atul Pandey
  // Inherited from EtCoachOrgTrailSessionPad
  // Updated - 22 Oct 2024 - moved coachId to useParams
  // Updated - 23 Feb 2025 - Copied and Renamed for prod deployment
  
  // 0 - Set Page Title
  useTitle("Coach Org Trail Session Pad")

  // 1 - Extract params from the URL
  const { coachId, orgId, trailId, sessionId } = useParams()
  // console.log('orgId', orgId)
  // console.log('trailId', trailId)
  // console.log('sessionId', sessionId)

  // 2a - RTK queries - extract and update data mutation
  const {
    data: coachOrgTrailSessionPadsData,
    isLoading,
    isError,
    error,
    refetch, // 27 Aug 2024 -Add refetch to trigger a data refresh
  } = useGetCoachOrgTrailSessionPadsQuery()
  // console.log('coach org trail session data:', coachOrgTrailSessionPadsData)

  // 2b - Extract data from entity adapters
  const coachOrgTrailSessionPads = coachOrgTrailSessionPadsData?.ids.map(
    (id) => {
      const coachOrgTrailSessionPad = coachOrgTrailSessionPadsData?.entities[id]
      return coachOrgTrailSessionPad
    }
  )
  // console.log('coachOrgTrailSessionPads:', coachOrgTrailSessionPads)

  // 2c - Filter Org trail sessions for the selected org trail session
  const filteredCoachOrgTrailSessionPad = coachOrgTrailSessionPads?.find(
    (pad) =>
      pad.coachId._id === coachId &&
      pad.orgId._id === orgId &&
      pad.trailId._id === trailId &&
      pad.sessionId._id === sessionId
  )

  // console.log( "Filtered Coach Org Trail Session Pad:", filteredCoachOrgTrailSessionPad)

  // 3 - Local state to hold form data
  const [formData, setFormData] = useState({
    coachSessionSelfNotes: "",
    coachSessionAllUsersNotes: "",
    sessionUsers: [],
    // Add other fields as needed
  })

  // 4 - Handler for form inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  if (isLoading) return <PulseLoader color={"#ea580c"} />
  if (isError) return <div>Error occurred: {error?.data?.message}</div>

  return (
    <div className="m-1 p-1 bg-gray-200 rounded">
      <EtCoachOrgTrailSessionPadForm
        padData={filteredCoachOrgTrailSessionPad}
        refetch={refetch}
      />
    </div>
  )
}

const EtCoachOrgTrailSessionPadForm = ({ padData, refetch }) => {
  // First Created - 09 Aug 2024
  // Author - Atul Pandey
  // Updated - 10 Aug 2024 - Added Coach Session Note mutation and form components
  // Updated - 25 Aug 2024 - Added Coach Session Challenge Evaluation for a specific org trail session
  // Updated - 26 Aug 2024 - Added Coach evaluation section along with modal to create new evaluations
  // Updated - 27 Aug 2024 - Refcactored display to include Challenge Instructions and Dimension Headers
  // Updated - 01 Sep 2024 - Added Coach Session Planned Actions for all users in a session
  // Updated - 02 Sep 2024 - Added the coach session planned action creation modal
  // Updated - 02 Sep 2024 - Added the coach session
  // Updated - 10 Sep 2024 - Added filter to the session user to exclude coach who is from the same org as that of users
  // Updated - 13 Sep 2024 - Refined display of Coach's notes based on the note length
  // Updated - 13 Sep 2024 - Added state variables to toggle show / hide coach all users note details
  // Updated - 13 Sep 2024 - Added state variables to toggle show / hide coach self note (all users) details
  // Updated - 13 Sep 2024 - Added state variables to toggle show / hide coach shared note details for specific user
  // Updated - 13 Sep 2024 - Added state variables to toggle show / hide coach self note details for specific user
  // Updated - 07 Mar 2025 - fixed the sessionId (added to filter out non matching sessions)

  // 1a - state variables for show / hide
  const [showDetails, setShowDetails] = useState(true)
  const [showSessionInfo, setShowSessionInfo] = useState(false)
  const [showSessionUsers, setShowSessionUsers] = useState(false)
  const [showSelfNotes, setShowSelfNotes] = useState(false) // Coach's self notes - for all users
  const [showAllUsersNotes, setShowAllUsersNotes] = useState(false) // Coach's shared notes - for all users
  const [showSelfUserNotes, setShowSelfUserNotes] = useState({}) // Coach's self notes - for specific users - 11 Aug 2024 changed to object from boolean
  const [showSharedUserNotes, setShowSharedUserNotes] = useState({}) // Coach's shared notes - for specific users - 11 Aug 2024 changed to object from boolean
  const [selfEditorKey, setSelfEditorKey] = useState("selfNote-" + Date.now()) // to address multiple editors issue
  const [allUsersEditorKey, setAllUsersEditorKey] = useState(
    "allUsersNote-" + Date.now()
  ) // to address multiple editors issue
  const [selfUserEditorKey, setSelfUserEditorKey] = useState(
    "selfUserNote-" + Date.now()
  ) // 11 Aug 2024 - to address multiple editors issue
  const [sharedUserEditorKey, setSharedUserEditorKey] = useState(
    "sharedUserNote-" + Date.now()
  ) // 11 Aug 2024 - to address multiple editors issue
  const [showUserSections, setShowUserSections] = useState(false) // 11 Aug 2024 - For Section 5 - Specific User sections
  const [showUserSectionDetails, setShowUserSectionDetails] = useState({}) // 11 Aug 2024 - For Section 5 - Specific User sections
  const [showSharedUserSections, setShowSharedUserSections] = useState(false) // 11 Aug 2024 - For Section 5 - Specific SharedUser sections
  const [showSharedUserSectionDetails, setShowSharedUserSectionDetails] =
    useState({}) // 11 Aug 2024 - For Section 5 - Specific SharedUser sections
  const [showCoachEvalsSection, setShowCoachEvalsSection] = useState(false) // 26 Aug 2024 - For Coach's Evaluation
  const [showChallengeSections, setShowChallengeSections] = useState({}) // 26 Aug 2024
  const [showChallengeHeadDetails, setShowChallengeHeadDetails] = useState({}) // 26 Aug 2024
  const [showChallengeHeadSectionDetails, setShowChallengeHeadSectionDetails] =
    useState({}) // 27 Aug 2024
  const [showChallengeDimDetails, setShowChallengeDimDetails] = useState({}) // 26 Aug 2024
  const [selectedUserId, setSelectedUserId] = useState(null) // 26 Aug 2024
  const [selectedUserOrgId, setSelectedUserOrgId] = useState(null) // 26 Aug 2024
  const [selectedChallengeId, setSelectedChallengeId] = useState(null) // 26 Aug 2024
  const [existingEvalData, setExistingEvalData] = useState(null) // 27 Aug 2024 - to allow create / update of coach user challenge evals
  const [mode, setMode] = useState("create") // 27 Aug 2024 - to allow create / update of coach user challenge evals
  const [actionMode, setActionMode] = useState("create") // 10 Sep 2024 - to allow create / update of coach user session planned actions
  const [showInstructionDetails, setShowInstructionDetails] = useState({}) // 19 Aug 2024
  const [existingActionData, setExistingActionData] = useState(null) // 10 Sep 2024 - to pass existing data to the modal

  const [showCoachAllUsersActionsSection, setShowCoachAllUsersActionsSection ] = useState(false) // 01 Sep 2024 - For Section 8
  const [showCoachAllUsersCreatedActions, setShowCoachAllUsersCreatedActions] = useState(false) // 02 Sep 2024 - Section 8
  const [showCoachUserActionUpdate, setShowCoachUserActionUpdate] = useState({}) // 02 Sep 2024 - for showing user action updates for coach planned user actions

  const [showCoachSelfAllUsersNoteDetail, setShowCoachSelfAllUsersNoteDetail] = useState({}) // 13 Sep 2024 - for showing coach self note (for all users) detail when it is >100 chars
  const [showCoachAllUsersNoteDetail, setShowCoachAllUsersNoteDetail] = useState({}) // 13 Sep 2024 - for showing coach note detail when it is >100 chars
  const [showCoachSelfUserNoteDetail, setShowCoachSelfUserNoteDetail] = useState({}) // 13 Sep 2024 - for showing coach self note detail for a specific user when it is >100 chars
  const [showCoachSharedUserNoteDetail, setShowCoachSharedUserNoteDetail] = useState({}) // 13 Sep 2024 - for showing coach shared note detail for a specific user when it is >100 chars
  
  // 1b - State for Modal -- 26 Aug 2024
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [triggeringUrl, setTriggeringUrl] = useState(null) // 26 Aug 2024

  const [isActionModalOpen, setIsActionModalOpen] = useState(false) // 02 Sep 2024 for creating Coach Planned Session Action Modal
  const [triggeringActionUrl, setTriggeringActionUrl] = useState(null) // 02 Sep 2024 for creating Coach Planned Session Action Modal

  

  // 1c - Get coach's user planned actions data - 01 Sep 2024
  const {data: coachUserOrgTrailSessionActionsData, refetch: refetchActions} = useGetCoachUserOrgTrailSessionActionsQuery()
  const coachSessionPlannedActions = coachUserOrgTrailSessionActionsData?.ids?.map((id) => {
    const coachSessionPlannedAction = coachUserOrgTrailSessionActionsData?.entities[id]
    return coachSessionPlannedAction
  })
  // console.log('Coach Session Planned Actions:', coachSessionPlannedActions)

  // 1d - Load state from localStorage -- 11 Aug 2024 - repurposed from EtUserTrailSessionsList - 12 Jun 2024
  // useEffect(() => {
  //     const storedShowDetails = localStorage.getItem('showDetails');
  //     const storedShowSelfNotes = localStorage.getItem('showSelfNotes');
  //     const storedShowAllUsersNotes = localStorage.getItem('showAllUsersNotes');
  //     const storedShowSelfUserNotes = localStorage.getItem('showSelfUserNotes');

  //     if (storedShowDetails && !showDetails) {
  //         setShowDetails(JSON.parse(storedShowDetails));
  //     }
  //     if (storedShowSelfNotes && !showSelfNotes) {
  //         setShowSelfNotes(JSON.parse(storedShowSelfNotes));
  //     }
  //     if (storedShowAllUsersNotes && !showAllUsersNotes) {
  //         setShowAllUsersNotes(JSON.parse(storedShowAllUsersNotes));
  //     }
  //     if (storedShowSelfUserNotes) {
  //         setShowSelfUserNotes(JSON.parse(storedShowSelfUserNotes));
  //     } else {
  //         setShowSelfUserNotes({}); // Initialize as an empty object if not present
  //     }
  // }, [ ]);

  // 1c-  Save state to localStorage before page reload -- 11 Aug 2024 - repurposed from EtUserTrailSessionsList - 12 Jun 2024
  // useEffect(() => {
  //     const handleBeforeUnload = () => {
  //         localStorage.setItem('showDetails', JSON.stringify(showDetails));
  //         localStorage.setItem('showSelfNotes', JSON.stringify(showSelfNotes));
  //         localStorage.setItem('showAllUsersNotes', JSON.stringify(showAllUsersNotes));
  //         localStorage.setItem('showSelfUserNotes', JSON.stringify(showSelfUserNotes));
  //     };

  //     window.addEventListener('beforeunload', handleBeforeUnload);
  //     return () => {
  //         window.removeEventListener('beforeunload', handleBeforeUnload);
  //     };
  // }, [showDetails,showSelfNotes, showAllUsersNotes, showSelfUserNotes]);
  // useEffect(() => {
  //     const handleBeforeUnload = () => {
  //         localStorage.setItem('showDetails', JSON.stringify(showDetails));
  //         localStorage.setItem('showSelfNotes', JSON.stringify(showSelfNotes));
  //         localStorage.setItem('showAllUsersNotes', JSON.stringify(showAllUsersNotes));
  //         localStorage.setItem('showSelfUserNotes', JSON.stringify(showSelfUserNotes));
  //     };

  //     window.addEventListener('beforeunload', handleBeforeUnload);
  //     return () => {
  //         window.removeEventListener('beforeunload', handleBeforeUnload);
  //     };
  // }, [showDetails, showSelfNotes, showAllUsersNotes, showSelfUserNotes]);

  // 2a - Basic Pad Data
  const coachId = padData?.coachId?._id
  const coachOrgId = padData?.coachId?.userOrgId
  const orgId = padData?.orgId?._id
  const trailId = padData?.trailId?._id
  const sessionId = padData?.sessionId?._id
  const username = padData?.coachId?.username
  const name = padData?.coachId?.name
  const orgName = padData?.orgId?.organizationName
  const trailName = padData?.trailId?.trailName
  const trailPic = padData?.trailId?.pic
  const sessionName = padData?.sessionId?.sessionName
  const sessionPic = padData?.sessionId?.pic
  const sessionUsers = padData?.sessionUsers
  const filteredSessionUsers = sessionUsers?.filter(user => user.userId._id !== coachId) // 10 Sep 2024
  // const userCount = padData?.sessionUsers?.length
  const userCount = filteredSessionUsers?.length // 10 Sep 2024 - changed to filteredSessionUsers from sessionUsers
  const sessionDetails = padData?.sessionId
  const coachSessionAllUsersNotes = padData?.coachSessionAllUsersNotes // 10 Aug 2024
  const coachSessionSelfNotes = padData?.coachSessionSelfNotes // 10 Aug 2024
  const coachSessionSelfUserNotes = padData?.coachSessionSelfUserNotes // 11 Aug 2024
  const coachSessionSharedUserNotes = padData?.coachSessionSharedUserNotes // 11 Aug 2024
  const coachSessionEvals = padData?.sessionChallengeCoachEvals // 26 Aug 2024
  const sessionChallenges = padData?.sessionId?.sessionChallenges // 26 Aug 2024

  // console.log('Session Users:', sessionUsers)
  // console.log('Filtered Session Users:', filteredSessionUsers)

  // 2b - Filter Coach Planned SessionActions - 01 Sep 2024
  // const filteredCoachUserPlannedActions = coachSessionPlannedActions?.filter(action => action.coachId._id === coachId && action.coachOrgId._id === coachOrgId && action.userOrgId._id === orgId)
  const filteredCoachUserPlannedActions = coachSessionPlannedActions?.filter(action => action.coachId._id === coachId && action.coachOrgId._id === coachOrgId && action.userOrgId._id === orgId && action?.sessionId?._id === sessionId) // 07 Mar 2025 - fixed the sessionId (added to filter out non matching sessions)

  // console.log('Filtered Coach Planned Actions:', filteredCoachUserPlannedActions)

  // 2c - Define and declare Navigation and state variables for Coach notes - 10 Aug 2024
  const navigate = useNavigate()
  const [coachSelfNotes, setCoachSelfNotes] = useState(coachSessionSelfNotes)
  const [coachAllUsersNotes, setCoachAllUsersNotes] = useState(
    coachSessionAllUsersNotes
  )
  const [coachSelfUserNotes, setCoachSelfUserNotes] = useState(
    coachSessionSelfUserNotes
  ) // 11 Aug 2024
  const [coachSharedUserNotes, setCoachSharedUserNotes] = useState(
    coachSessionSharedUserNotes
  ) // 11 Aug 2024
  const [coachEvals, setCoachEvals] = useState(coachSessionEvals) // 26 Aug 2024

  // const [isEditing, setIsEditing] = useState(false)
  const [isSelfNoteEditing, setIsSelfNoteEditing] = useState(false)
  const [isAllUsersNoteEditing, setIsAllUsersNoteEditing] = useState(false)
  const [isSelfUserNoteEditing, setIsSelfUserNoteEditing] = useState({}) // 11 Aug 2024 - updated the state to object from boolean
  const [isSharedUserNoteEditing, setIsSharedUserNoteEditing] = useState({}) // 11 Aug 2024 - updated the state to object from boolean

  // 3a - Update mutation for adding, editing coach's notes and other activities - 10 Aug 2024
  const [updateCoachOrgTrailSessionPad] =
    useUpdateCoachOrgTrailSessionPadMutation()

  // 4a - Editor Value setting based on incoming data -- 10 Aug 2024 - 13 Jun 2024 (repurposed EtUserTrailSessionImplementation code)
  const [editorValue, setEditorValue] = useState(() => {
    const latestNote = coachSessionSelfNotes?.slice(-1)[0]
    return latestNote ? latestNote.coachSelfNoteText : ""
  })

  // 4b - Current Note Id of the note currently in editor -- 10 Aug 2024 - 14 Jun 2024 (repurposed EtUserTrailSessionImplementation code)
  const [currentSelfNoteId, setCurrentSelfNoteId] = useState(() => {
    const latestNote = coachSessionSelfNotes?.slice(-1)[0]
    return latestNote ? latestNote._id : null
  })

  // 4c - Handle Editor Value change
  const handleEditorChange = (value) => {
    setEditorValue(value)
  }

  // 4d - Handle add self note details - 10 Aug 2024
  const handleAddSelfNote = useCallback(() => {
    setEditorValue("")
    setCurrentSelfNoteId(null)
    setIsSelfNoteEditing(true)
    setSelfEditorKey("selfNote-" + Date.now())
  }, [])

  // 4e - Handle edit self note details - 10 Aug 2024
  const handleEditSelfNote = (note) => {
    setEditorValue(note.coachSelfNoteText)
    setCurrentSelfNoteId(note._id)
    setIsSelfNoteEditing(true)
  }

  // 4f - Handle edit self note details - 10 Aug 2024
  const handleDeleteSelfNote = async (noteId) => {
    try {
      const updatedNotes = coachSessionSelfNotes?.filter(
        (note) => note._id !== noteId
      )

      await updateCoachOrgTrailSessionPad({
        coachId,
        orgId,
        trailId,
        sessionId,
        coachSessionSelfNotes: updatedNotes,
      }).unwrap()

      setCoachSelfNotes(updatedNotes) // Update the state with the filtered notes
      alert("Note deleted successfully")
      window.location.reload()
    } catch (error) {
      console.error("Failed to delete the note:", error)
      // Add error handling logic here if needed
    }
  }

  // 4g - Function to handle saving new Note -- 10 Aug 2024 - 14 Jun 2024 -- (repurposed EtUserTrailSessionImplementation code)
  const handleSaveSelfNote = async () => {
    if (editorValue.trim() === "") {
      alert("The editor is blank. Please enter some text before saving.")
      return
    }

    try {
      let updatedNotes
      if (currentSelfNoteId) {
        // Update existing note
        updatedNotes = coachSessionSelfNotes?.map((note) =>
          note._id === currentSelfNoteId
            ? {
                ...note,
                coachSelfNoteText: editorValue,
                implementationNoteUpdateDate: new Date(),
              }
            : note
        )
      } else {
        // Add new note
        const newNote = {
          coachSelfNoteText: editorValue,
          coachSelfNoteDate: new Date(),
          coachSelfNoteUpdateDate: new Date(),
        }
        updatedNotes = [...coachSessionSelfNotes, newNote]
      }

      await updateCoachOrgTrailSessionPad({
        coachId,
        orgId,
        trailId,
        sessionId,
        coachSessionSelfNotes: updatedNotes,
      }).unwrap()

      setEditorValue("")
      setIsSelfNoteEditing(false)
      alert("Notes updated successfully")
      window.location.reload()
    } catch (error) {
      console.error("Failed to update the user session implementation:", error)
      // You can add an error message or any other logic here in case of a failure
    }
  }

  // 4h - Function to handle editing a note -- 14 Jun 2024 --
  const handleCancelEditSelfNote = (note) => {
    setEditorValue("")
    setCurrentSelfNoteId(null)
    setIsSelfNoteEditing(false)
  }

  // 5a - Editor Value setting for All Users Notes based on incoming data
  const [allUsersEditorValue, setAllUsersEditorValue] = useState(() => {
    const latestNote = coachAllUsersNotes?.slice(-1)[0]
    return latestNote ? latestNote.coachAllUsersNoteText : ""
  })

  // 5b - Current Note Id of the note currently in editor for All Users Notes
  const [currentAllUsersNoteId, setCurrentAllUsersNoteId] = useState(() => {
    const latestNote = coachAllUsersNotes?.slice(-1)[0]
    return latestNote ? latestNote._id : null
  })

  // 5c - Handle Editor Value change for All Users Notes
  const handleAllUsersEditorChange = (value) => {
    setAllUsersEditorValue(value)
  }

  // 5d - Handle add All Users note details
  const handleAddAllUsersNote = useCallback(() => {
    setAllUsersEditorValue("")
    setCurrentAllUsersNoteId(null)
    setIsAllUsersNoteEditing(true)
    setAllUsersEditorKey("allUsersNote-" + Date.now())
  }, [])

  // 5e - Handle edit All Users note details
  const handleEditAllUsersNote = (note) => {
    setAllUsersEditorValue(note.coachAllUsersNoteText)
    setCurrentAllUsersNoteId(note._id)
    setIsAllUsersNoteEditing(true)
  }

  // 5f - Handle save All Users note details
  const handleDeleteAllUsersNote = async (noteId) => {
    try {
      const updatedNotes = coachAllUsersNotes?.filter(
        (note) => note._id !== noteId
      )

      await updateCoachOrgTrailSessionPad({
        coachId,
        orgId,
        trailId,
        sessionId,
        coachAllUsersNotes: updatedNotes,
      }).unwrap()

      setCoachAllUsersNotes(updatedNotes) // Update the state with the filtered notes
      alert("Note deleted successfully")
      window.location.reload()
    } catch (error) {
      console.error("Failed to delete the note:", error)
      // Add error handling logic here if needed
    }
  }

  // 5g - Handle save All Users note details
  const handleSaveAllUsersNote = async () => {
    if (allUsersEditorValue.trim() === "") {
      alert("The editor is blank. Please enter some text before saving.")
      return
    }

    try {
      let updatedNotes
      if (currentAllUsersNoteId) {
        // Update existing note
        updatedNotes = coachAllUsersNotes?.map((note) =>
          note._id === currentAllUsersNoteId
            ? {
                ...note,
                coachAllUsersNoteText: allUsersEditorValue,
                coachAllUsersNoteUpdateDate: new Date(),
              }
            : note
        )
      } else {
        // Add new note
        const newNote = {
          coachAllUsersNoteText: allUsersEditorValue,
          coachAllUsersNoteDate: new Date(),
          coachAllUsersNoteUpdateDate: new Date(),
        }
        updatedNotes = [...coachAllUsersNotes, newNote]
      }

      await updateCoachOrgTrailSessionPad({
        coachId,
        orgId,
        trailId,
        sessionId,
        coachSessionAllUsersNotes: updatedNotes,
      }).unwrap()

      setAllUsersEditorValue("")
      setIsAllUsersNoteEditing(false)
      alert("All Users Notes updated successfully")
      window.location.reload()
    } catch (error) {
      console.error("Failed to update the All Users notes:", error)
    }
  }

  // 5h - Function to handle editing a All Users note
  const handleCancelEditAllUsersNote = (note) => {
    setAllUsersEditorValue("")
    setCurrentAllUsersNoteId(null)
    setIsAllUsersNoteEditing(false)
  }

  // 6a - Editor Value setting for Self User Notes based on incoming data -- 11 Aug 2024
  const [selfUserEditorValue, setSelfUserEditorValue] = useState(() => {
    const initialEditorValues = {}
    if (coachSelfUserNotes) {
      coachSelfUserNotes?.forEach((note) => {
        initialEditorValues[note.userId] = note?.coachSelfUserNoteText || ""
      })
    }
    return initialEditorValues
  })

  // 6b - Current Note Id of the note currently in editor for Self User Notes -- 11 Aug 2024
  const [currentSelfUserNoteId, setCurrentSelfUserNoteId] = useState({})

  // 6c - Handle Editor Value change for Self User Notes -- 11 Aug 2024
  const handleSelfUserEditorChange = (value, userId) => {
    setSelfUserEditorValue((prev) => ({
      ...prev,
      [userId]: value,
    }))
  }

  // 6d - Handle add Self User note details -- 11 Aug 2024
  const handleAddSelfUserNote = useCallback((userId) => {
    setSelfUserEditorValue((prevState) => ({
      ...prevState,
      [userId]: "", // Clear the editor value for this specific userId
    }))
    setCurrentSelfUserNoteId((prevState) => ({
      ...prevState,
      [userId]: null, // Clear the current note ID for this specific userId
    }))
    setIsSelfUserNoteEditing((prevState) => ({
      ...prevState,
      [userId]: true, // Set editing state to true for this specific userId
    }))
    setSelfUserEditorKey("selfUserNote-" + Date.now())
  }, [])

  // 6e - Handle edit Self User note details -- 11 Aug 2024
  const handleEditSelfUserNote = (userId, note) => {
    setSelfUserEditorValue((prevState) => ({
      ...prevState,
      [userId]: note.coachSelfUserNoteText, // Set the editor value to the note's content for this userId
    }))
    setCurrentSelfUserNoteId((prevState) => ({
      ...prevState,
      [userId]: note._id, // Set the current note ID for this specific userId
    }))
    setIsSelfUserNoteEditing((prevState) => ({
      ...prevState,
      [userId]: true, // Set editing state to true for this specific userId
    }))
  }

  // 6f - Handle save Self User note details -- 11 Aug 2024
  const handleDeleteSelfUserNote = async (noteId) => {
    try {
      const updatedNotes = coachSelfUserNotes?.filter(
        (note) => note._id !== noteId
      )
      // console.log('updated notes:', updatedNotes)

      await updateCoachOrgTrailSessionPad({
        coachId,
        orgId,
        trailId,
        sessionId,
        coachSessionSelfUserNotes: updatedNotes,
      }).unwrap()

      setCoachSelfUserNotes(updatedNotes) // Update the state with the filtered notes
      alert("Note deleted successfully")
      window.location.reload()
    } catch (error) {
      console.error("Failed to delete the note:", error)
      // Add error handling logic here if needed
    }
  }

  // 6g - Handle save Self User note details -- 11 Aug 2024
  const handleSaveSelfUserNote = async (userId) => {
    // Access the specific note content for the given userId
    const editorContent = selfUserEditorValue[userId]?.trim()
    if (!editorContent || editorContent === "") {
      alert("The editor is blank. Please enter some text before saving.")
      return
    }

    try {
      let updatedNotes
      if (currentSelfUserNoteId[userId]) {
        // Update existing note
        updatedNotes = coachSelfUserNotes?.map((note) =>
          note._id === currentSelfUserNoteId[userId]
            ? {
                ...note,
                coachSelfUserNoteText: editorContent,
                coachSelfUserNoteUpdateDate: new Date(),
              }
            : note
        )
      } else {
        // Add new note
        const newNote = {
          userId: userId,
          coachSelfUserNoteText: editorContent,
          coachSelfUserNoteDate: new Date(),
          coachSelfUserNoteUpdateDate: new Date(),
        }
        updatedNotes = [...coachSelfUserNotes, newNote]
      }

      await updateCoachOrgTrailSessionPad({
        coachId,
        orgId,
        trailId,
        sessionId,
        coachSessionSelfUserNotes: updatedNotes,
      }).unwrap()

      // setSelfUserEditorValue('');
      setSelfUserEditorValue((prevState) => ({
        ...prevState,
        [userId]: "", // Clear the editor value for this userId
      }))
      setIsSelfUserNoteEditing((prevState) => ({
        ...prevState,
        [userId]: false, // Set editing state to false for this userId
      }))
      alert("Self User Note updated successfully")
      window.location.reload()
    } catch (error) {
      console.error("Failed to update the All Users notes:", error)
    }
  }

  // 6h - Function to handle editing a Self User note
  // const handleCancelEditSelfUserNote = (userId) => {
  //     setSelfUserEditorValue('');
  //     setCurrentSelfUserNoteId(null);
  //     // setIsSelfUserNoteEditing(false);
  //     setIsSelfUserNoteEditing((prevState) => ({
  //         ...prevState,
  //         [userId]: false, // Set editing state to false for this userId
  //     }));
  // };
  const handleCancelEditSelfUserNote = (userId) => {
    setSelfUserEditorValue((prevState) => ({
      ...prevState,
      [userId]: "", // Clear the editor value for this userId
    }))
    setCurrentSelfUserNoteId((prevState) => ({
      ...prevState,
      [userId]: null, // Clear the current note ID for this userId
    }))
    setIsSelfUserNoteEditing((prevState) => ({
      ...prevState,
      [userId]: false, // Set editing state to false for this userId
    }))
  }

  // 6i - Handle toggle show user details -- 11 Aug 2024 -- repurposed from EtUserTrailSessionList -- 23 May 2024 --
  const handleToggleUserDetails = (userId) => {
    setShowUserSectionDetails((prev) => ({
      ...prev,
      [userId]: !prev[userId], // Toggle the visibility of the user section
    }))

    setShowSelfUserNotes((prevNotes) => ({
      ...prevNotes,
      [userId]: !prevNotes[userId], // Toggle visibility for the specific user's notes section
    }))
  }

  // 7a - Editor Value setting for Shared User Notes based on incoming data -- 11 Aug 2024
  const [sharedUserEditorValue, setSharedUserEditorValue] = useState(() => {
    const initialEditorValues = {}
    if (coachSharedUserNotes) {
      coachSharedUserNotes?.forEach((note) => {
        initialEditorValues[note.userId] = note?.coachSharedUserNoteText || ""
      })
    }
    return initialEditorValues
  })

  // 7b - Current Note Id of the note currently in editor for Shared User Notes -- 11 Aug 2024
  const [currentSharedUserNoteId, setCurrentSharedUserNoteId] = useState({})

  // 7c - Handle Editor Value change for Shared User Notes -- 11 Aug 2024
  const handleSharedUserEditorChange = (value, userId) => {
    setSharedUserEditorValue((prev) => ({
      ...prev,
      [userId]: value,
    }))
  }

  // 7d - Handle add Shared User note details -- 11 Aug 2024
  const handleAddSharedUserNote = useCallback((userId) => {
    setSharedUserEditorValue((prevState) => ({
      ...prevState,
      [userId]: "", // Clear the editor value for this specific userId
    }))
    setCurrentSharedUserNoteId((prevState) => ({
      ...prevState,
      [userId]: null, // Clear the current note ID for this specific userId
    }))
    setIsSharedUserNoteEditing((prevState) => ({
      ...prevState,
      [userId]: true, // Set editing state to true for this specific userId
    }))
    setSharedUserEditorKey("sharedUserNote-" + Date.now())
  }, [])

  // 7e - Handle edit Shared User note details -- 11 Aug 2024
  const handleEditSharedUserNote = (userId, note) => {
    setSharedUserEditorValue((prevState) => ({
      ...prevState,
      [userId]: note.coachSharedUserNoteText, // Set the editor value to the note's content for this userId
    }))
    setCurrentSharedUserNoteId((prevState) => ({
      ...prevState,
      [userId]: note._id, // Set the current note ID for this specific userId
    }))
    setIsSharedUserNoteEditing((prevState) => ({
      ...prevState,
      [userId]: true, // Set editing state to true for this specific userId
    }))
  }

  // 7f - Handle save Shared User note details -- 11 Aug 2024
  const handleDeleteSharedUserNote = async (noteId) => {
    try {
      const updatedNotes = coachSharedUserNotes?.filter(
        (note) => note._id !== noteId
      )
      // console.log('updated notes:', updatedNotes)

      await updateCoachOrgTrailSessionPad({
        coachId,
        orgId,
        trailId,
        sessionId,
        coachSessionSharedUserNotes: updatedNotes,
      }).unwrap()

      setCoachSharedUserNotes(updatedNotes) // Update the state with the filtered notes
      alert("Note deleted successfully")
      window.location.reload()
    } catch (error) {
      console.error("Failed to delete the note:", error)
      // Add error handling logic here if needed
    }
  }

  // 7g - Handle save Shared User note details -- 11 Aug 2024
  const handleSaveSharedUserNote = async (userId) => {
    // Access the specific note content for the given userId
    const editorContent = sharedUserEditorValue[userId]?.trim()
    if (!editorContent || editorContent === "") {
      alert("The editor is blank. Please enter some text before saving.")
      return
    }

    try {
      let updatedNotes
      if (currentSharedUserNoteId[userId]) {
        // Update existing note
        updatedNotes = coachSharedUserNotes?.map((note) =>
          note._id === currentSharedUserNoteId[userId]
            ? {
                ...note,
                coachSharedUserNoteText: editorContent,
                coachSharedUserNoteUpdateDate: new Date(),
              }
            : note
        )
      } else {
        // Add new note
        const newNote = {
          userId: userId,
          coachSharedUserNoteText: editorContent,
          coachSharedUserNoteDate: new Date(),
          coachSharedUserNoteUpdateDate: new Date(),
        }
        updatedNotes = [...coachSharedUserNotes, newNote]
      }

      await updateCoachOrgTrailSessionPad({
        coachId,
        orgId,
        trailId,
        sessionId,
        coachSessionSharedUserNotes: updatedNotes,
      }).unwrap()

      setSharedUserEditorValue((prevState) => ({
        ...prevState,
        [userId]: "", // Clear the editor value for this userId
      }))
      setIsSharedUserNoteEditing((prevState) => ({
        ...prevState,
        [userId]: false, // Set editing state to false for this userId
      }))
      alert("Shared User Note updated successfully")
      window.location.reload()
    } catch (error) {
      console.error("Failed to update the All Users notes:", error)
    }
  }

  // 7h - Function to handle editing a Shared User note
  const handleCancelEditSharedUserNote = (userId) => {
    setSharedUserEditorValue((prevState) => ({
      ...prevState,
      [userId]: "", // Clear the editor value for this userId
    }))
    setCurrentSharedUserNoteId((prevState) => ({
      ...prevState,
      [userId]: null, // Clear the current note ID for this userId
    }))
    setIsSharedUserNoteEditing((prevState) => ({
      ...prevState,
      [userId]: false, // Set editing state to false for this userId
    }))
  }

  // 7i - Handle toggle show user details -- 11 Aug 2024 -- repurposed from EtUserTrailSessionList -- 23 May 2024 --
  const handleToggleSharedUserDetails = (userId) => {
    setShowSharedUserSectionDetails((prev) => ({
      ...prev,
      [userId]: !prev[userId], // Toggle the visibility of the user section
    }))

    setShowSharedUserNotes((prevNotes) => ({
      ...prevNotes,
      [userId]: !prevNotes[userId], // Toggle visibility for the specific user's notes section
    }))
  }

  // 8a - Toggle function to show/hide challenge details - 26 Aug 2024
  const handleToggleChallengeSections = (challengeId) => {
    setShowChallengeSections((prevState) => ({
      ...prevState,
      [challengeId]: !prevState[challengeId],
    }))
  }

  // 8a - Toggle function to show/hide challenge details - 27 Aug 2024
  const handleToggleChallengeHeadSectionDetails = (challengeId) => {
    setShowChallengeHeadSectionDetails((prevState) => ({
      ...prevState,
      [challengeId]: !prevState[challengeId],
    }))
  }

  // 8b - Toggle function to show/hide challenge head details - 26 Aug 2024
  const handleToggleChallengeHeadDetails = (challengeId, headName) => {
    setShowChallengeHeadDetails((prevState) => ({
      ...prevState,
      [`${challengeId}-${headName}`]: !prevState[`${challengeId}-${headName}`],
    }))
  }

  // 8c - Toggle function to show/hide challenge dimension details - 26 Aug 2024
  const handleToggleChallengeDimDetails = (
    challengeId,
    headName,
    dimensionId
  ) => {
    setShowChallengeDimDetails((prevState) => ({
      ...prevState,
      [`${challengeId}-${headName}-${dimensionId}`]:
        !prevState[`${challengeId}-${headName}-${dimensionId}`],
    }))
  }

  // 8d - Toggle Show function for challenge instruction details - 27 Aug 2024
  const handleToggleInstructionDetails = (challengeId) => {
    setShowInstructionDetails((prev) => ({
      ...prev,
      [challengeId]: !prev[challengeId],
    }))
  }

  // 8e - Toggle Show function for challenge instruction details - 03 Sep 2024
  const handleToggleCoachUserActionUpdate = (actionId) => {
    setShowCoachUserActionUpdate((prev) => ({
      ...prev,
      [actionId]: !prev[actionId],
    }))
  }

  // 8f - Toggle Show function for coach's self note details for all users - 13 Sep 2024
  const handleToggleCoachSelfAllUsersNoteDetail = (noteId) => {
    setShowCoachSelfAllUsersNoteDetail((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
  }

  // 8g - Toggle Show function coach's shared note details for all users - 13 Sep 2024
  const handleToggleCoachAllUsersNoteDetail = (noteId) => {
    setShowCoachAllUsersNoteDetail((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
  }
  
  // 8h - Toggle Show function for coach's self note details for a specific user - 13 Sep 2024
  const handleToggleCoachSelfUserNoteDetail = (noteId) => {
    setShowCoachSelfUserNoteDetail((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
  }

  // 8i - Toggle Show function for coach's shared note details for a specific user - 13 Sep 2024
  const handleToggleCoachSharedUserNoteDetail = (noteId) => {
    setShowCoachSharedUserNoteDetail((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
  }

  // 9a - Handle Evaluate Action to activate modal - 26 Aug 2024 -- updated 27 Aug 2024 - with setMode and setExistingEvalData
  const handleEvaluate = (challengeId, userId, userOrgId) => {
    const currentUrl = window.location.pathname // 26 Aug 2024 - Capture the current URL
    setTriggeringUrl(currentUrl) // 26 Aug 2024 - Set the triggering URL

    // 27 Aug 2024 - Check if there's an existing evaluation for the selected challenge and user
    const existingEval = coachSessionEvals?.find(
      (evalItem) =>
        evalItem.sessionChallengeCoachEvalId.challengeId === challengeId &&
        evalItem.sessionChallengeCoachEvalId.userId === userId
    )

    setIsModalOpen(true)
    // Pass the userId and userOrgId to the modal component
    setSelectedChallengeId(challengeId)
    setSelectedUserId(userId)
    setSelectedUserOrgId(userOrgId)

    // Determine the mode and set existing evaluation data if it exists
    const mode = existingEval ? "update" : "create"
    setMode(mode)
    setExistingEvalData(
      existingEval ? existingEval.sessionChallengeCoachEvalId : null
    )
  }
  // console.log("Existing Eval Data", existingEvalData)

  const handleCloseChallengeEvalModal = () => {
    setIsModalOpen(false)
    setTriggeringUrl(null) // 26 Aug 2024 Reset the triggering URL
    setSelectedChallengeId(null)
    setSelectedUserId(null)
    setSelectedUserOrgId(null)
    // window.location.reload(); // Force the page to reload
    // Refetch the data to get the latest evaluations
    refetch()
  }

  // 9b - Handle Create Coach Planned Action modal - 02 Sep 2024
  const handleCreatePlannedAction = () => {
    const currentUrl = window.location.pathname // 02 Sep 2024 - Capture the current URL
    setTriggeringActionUrl(currentUrl) // 02 Sep 2024 - Set the triggering URL
    setIsActionModalOpen(true)
  }

  // 10 Sep 2024 - changed the modal to handle create / update
  const handleAddOrUpdatePlannedAction = (action) => {
    const currentUrl = window.location.pathname // 02 Sep 2024 - Capture the current URL
    setTriggeringActionUrl(currentUrl) // 02 Sep 2024 - Set the triggering URL
    setIsActionModalOpen(true)
    
    // Determine the mode based on existing coach planned actions data
    const mode = filteredCoachUserPlannedActions.length>0 ? "update" : "create"
    setActionMode(mode)
    setExistingActionData(action)
  }

  const handleCloseCreateActionModal = () => {
    setIsActionModalOpen(false)
    setTriggeringActionUrl(null) // 26 Aug 2024 Reset the triggering URL
    setExistingActionData(null) // 10 Sep 2024
    refetchActions()
  }

  // 10 - Create an object for setting inner HTML
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
  }

  // 11 - Function to get the appropriate color class based on the eval score - 26 Aug 2024
  const getColorForScore = (score) => {
    if (score >= 0 && score < 0.9) return "bg-etpx-0"
    if (score >= 0.9 && score < 1.9) return "bg-etpx-1"
    if (score >= 1.9 && score < 2.9) return "bg-etpx-2"
    if (score >= 2.9 && score < 3.9) return "bg-etpx-3"
    if (score >= 3.9 && score < 4.9) return "bg-etpx-4"
    if (score >= 4.9) return "bg-etpx-5" // For score >= 4.9
    return "bg-black" // default
  }

  return (
    <div className="m-1 p-1 bg-white rounded font-poppins">
      <div className="m-1 p-1 bg-gray-300 rounded">
          {/* <div className="m-1 p-1" onClick={() => navigate("/userMagicHome")}> */}
         <div className="m-1 p-1" onClick={() => navigate("/etapp")}> {/* 23 Feb 2025*/}
          <ArrowLeftCircleIcon className="h-5 w-5 text-orange-600 cursor-pointer" />
        </div>
        {/* Coach Header */}
        <div className="grid grid-cols-12 justify-between items-center m-1 p-1">
          <div className="col-span-3 flex justify-start items-center m-1 p-1">
            <div
              onClick={() => setShowDetails((prev) => !prev)}
              className="mx-2 rounded-full h-7 w-7 hover:bg-white flex justify-center items-center cursor-pointer"
            >
              {showDetails ? (
                <MinusIcon className="h-5 w-5 text-orange-600" />
              ) : (
                <PlusIcon className="h-5 w-5 text-orange-600" />
              )}
            </div>
            <div>
              <User username={username} defaultUserPic={userProfileIcon2} />
            </div>
            <div>
              <p className="px-2 mx-2 text-gray-600 font-bold">{name}</p>
              <p className="mx-2 text-[10px] text-gray-400 rounded-full inline-block px-2 border bg-white shadow shadow-gray-400">
                Founder,{" "}
                <span className="text-orange-600">Eternity Solutions</span>
              </p>
            </div>
          </div>
          <div className="col-span-2 flex justify-start items-center">
            <div>
              <p className="text-[10px] text-gray-500 mx-1 px-1">Org:</p>
            </div>
            <div>
              <BuildingOffice2Icon className="h-5 w-5 text-orange-600 m-1" />
            </div>
            <div>
              <p className=" text-gray-600 font-bold">{orgName}</p>
            </div>
          </div>
          <div className="col-span-2 flex justify-start items-center">
            <div>
              <p className="text-[10px] text-gray-500 mx-1 px-1">Trail:</p>
            </div>
            <div>
              <ArrowTrendingUpIcon className="h-5 w-5 text-orange-600 m-1" />
            </div>
            <div className="flex justify-start items-center">
              <div className="flex justify-center item-center rounded-full h-6 w-6 bg-gray-200 hover:bg-gray-50 mx-1">
                <img src={trailPic} alt="" className="h-5 w-5 " />
              </div>
              <div>
                <p className="text-gray-600 font-bold">{trailName}</p>
              </div>
            </div>
          </div>
          <div className="col-span-4 flex justify-start items-center">
            <div>
              <p className="text-[10px] text-gray-500 mx-1 px-1">Session:</p>
            </div>
            <div>
              <CalendarDaysIcon className="h-5 w-5 text-orange-600 m-1" />
            </div>
            <div className="flex justify-start items-center">
              <div className="flex justify-center item-center rounded-full h-6 w-6 bg-gray-200 hover:bg-gray-50 mx-1">
                <img src={sessionPic} alt="" className="h-5 w-5 " />
              </div>
              <div>
                <p className="px-2 mx-2 text-gray-600 font-bold">
                  {sessionName}
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-1 flex justify-start items-center">
            <div>
              <p className="text-[10px] text-gray-500 mx-1 px-1">Users :</p>
            </div>
            <div>
              <UserGroupIcon className="h-5 w-5 text-orange-600 m-1" />
            </div>
            <div className="rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50">
              <p className="text-orange-600 text-[10px]">{userCount}</p>
            </div>
          </div>
        </div>
        {showDetails && (
          <div className="m-1 p-1 bg-white rounded">
            {/* Section 1 - Session Info */}
            <div className="m-1 p-1 bg-gray-100 rounded">
              <div className="flex justify-between items-center m-1 p-1">
                <div className="flex justify-start items-center m-1 p-1">
                  <div
                    onClick={() => setShowSessionInfo((prev) => !prev)}
                    className="mx-2 rounded-full h-7 w-7 hover:bg-white flex justify-center items-center cursor-pointer"
                  >
                    {showSessionInfo ? (
                      <MinusIcon className="h-5 w-5 text-orange-600" />
                    ) : (
                      <PlusIcon className="h-5 w-5 text-orange-600" />
                    )}
                  </div>
                  <div className="mx-1 px-1">
                    <InformationCircleIcon className="h-6 w-6 text-orange-600" />
                  </div>
                  <div>
                    <p className="text-md font-bold text-gray-600 mx-2 px-2">
                      Session Info
                    </p>
                  </div>
                </div>
              </div>
              {showSessionInfo && sessionDetails?.sessionIntro && (
                <div className="bg-white m-1 p-1 rounded">
                  <div className="p-2 grid grid-cols-3 gap-2 justify-center">
                    <div className="mx-2 border-r  border-orange-200 col-span-1 h-full">
                      <div className="flex flex-col justify-start">
                        <div className="flex justify-start items-center  m-1 p-1">
                          <div className="">
                            <Square3Stack3DIcon className="h-5 w-5 text-orange-600" />
                          </div>
                          <p className="m-1 p-1 text-xs text-gray-400 font-bold border-b-2 border-orange-600">
                            Session{" "}
                            <span className="text-orange-600">Detail</span>
                          </p>
                        </div>
                        <p
                          dangerouslySetInnerHTML={createMarkup(
                            sessionDetails?.sessionIntro
                          )}
                          className="text-[10px] text-gray-600"
                        ></p>
                      </div>
                    </div>

                    <div className="mx-2 col-span-1 border-r border-orange-200 h-full">
                      <div className="flex flex-col justify-start">
                        <div className="flex justify-start items-center  m-1 p-1">
                          <div className="">
                            <ListBulletIcon className="h-5 w-5 text-orange-600" />
                          </div>
                          <p className="m-1 p-1 text-xs text-gray-400 font-bold border-b-2 border-orange-600">
                            Session{" "}
                            <span className="text-orange-600">Prework</span>
                          </p>
                        </div>

                        {sessionDetails?.sessionPreWork &&
                          sessionDetails?.sessionPreWork?.map(
                            (item, preWorkIndex) => (
                              <div
                                key={preWorkIndex}
                                className="rounded m-1 p-1 bg-gray-100"
                              >
                                <p className="text-[10px] mx-1">
                                  <span className="font-bold"></span>
                                  {item.prework}
                                </p>
                              </div>
                            )
                          )}
                      </div>
                    </div>

                    <div className="mx-2 col-span-1">
                      <div className="flex flex-col justify-start">
                        <div className="flex justify-start items-center m-1 p-1">
                          <div className="">
                            <SwatchIcon className="h-5 w-5 text-orange-600" />
                          </div>
                          <p className="m-1 p-1 text-xs text-gray-400 font-bold border-b-2 border-orange-600">
                            Session{" "}
                            <span className="text-orange-600">Outcomes</span>
                          </p>
                        </div>

                        {sessionDetails?.sessionOutcomes &&
                          sessionDetails?.sessionOutcomes?.map(
                            (item, outIndex) => (
                              <div
                                key={outIndex}
                                className="rounded m-1 p-1 bg-gray-100"
                              >
                                <p className="text-[10px] mx-1">
                                  {item?.outcome}
                                </p>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Section 2 - Session Users */}
            <div className="m-1 p-1 bg-gray-100 rounded">
              <div className="flex justify-between items-center m-1 p-1">
                <div className="flex justify-start items-center m-1 p-1">
                  <div
                    onClick={() => setShowSessionUsers((prev) => !prev)}
                    className="mx-2 rounded-full h-7 w-7 hover:bg-white flex justify-center items-center cursor-pointer"
                  >
                    {showSessionUsers ? (
                      <MinusIcon className="h-5 w-5 text-orange-600" />
                    ) : (
                      <PlusIcon className="h-5 w-5 text-orange-600" />
                    )}
                  </div>
                  <div className="mx-1 px-1">
                    <UserGroupIcon className="h-6 w-6 text-orange-600" />
                  </div>
                  <div>
                    <p className="text-md font-bold text-gray-600 mx-2 px-2">
                      Session Users
                    </p>
                  </div>
                  <div className="rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50">
                    <p className="text-orange-600 text-[10px]">{userCount}</p>
                  </div>
                </div>
              </div>

              {showSessionUsers &&            
                filteredSessionUsers?.map((user, userIndex) => { // 10 Sep 2024 - changed from sessionUsers to filteredSessionUsers
                  return (
                    <div key={userIndex} className="m-1 p-1 bg-gray-50 rounded">
                      <div className="flex justify-between items-center m-1 p-1">
                        <div className=" flex justify-start items-center m-1 p-1">
                          <div className="mx-1 px-1">
                            <User
                              username={user.userId?.username}
                              defaultUserPic={userProfileIcon2}
                            />
                          </div>
                          <div className="mx-1 px-1">
                            <p className="text-xs text-gray-600 font-bold">
                              {user.userId?.name}
                            </p>
                            <p className="text-[10px] text-gray-400">
                              {user.userId?.email}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>

            {/* Section 3 - Coach's Session Self Notes */}
            <div className="m-1 p-1 bg-gray-100 rounded">
              <div className="flex justify-between items-center m-1 p-1">
                <div className="flex justify-start items-center m-1 p-1">
                  <div
                    onClick={() => setShowSelfNotes((prev) => !prev)}
                    className="mx-2 rounded-full h-7 w-7 hover:bg-white flex justify-center items-center cursor-pointer"
                  >
                    {showSelfNotes ? (
                      <MinusIcon className="h-5 w-5 text-orange-600" />
                    ) : (
                      <PlusIcon className="h-5 w-5 text-orange-600" />
                    )}
                  </div>
                  <div>
                    <EtDocSelfNoteAllUsersIconSVGComp className="h-7 w-7 text-orange-600" />
                  </div>
                  <div>
                    <p className="text-md font-bold text-gray-600 mx-2 px-2">
                      Coach's Session Notes - Self
                    </p>
                    <p className="text-[10px] italic text-gray-600 mx-2 px-2">
                      Capture your session experiences, thoughts and
                      observations about the participants.{" "}
                    </p>
                  </div>
                </div>
              </div>

              {showSelfNotes && (
                <div className="m-2 p-2 bg-gray-300 rounded">
                  <div className="m-1 p-1 border border-white rounded">
                    {coachSelfNotes?.length === 0 && (
                      <div className=" ">
                        <p className="text-[11px] text-gray-500 italic mx-2 px21">
                          No self notes available. Add your first note below.
                        </p>
                      </div>
                    )}

                    {/* Add Note Form */}
                    {isSelfNoteEditing ? (
                      <div className="bg-gray-50 p-1 m-1 rounded">
                        <EtContentEditorLight2
                          value={editorValue}
                          handleChange={handleEditorChange}
                          placeholderText="Write your self note here..."
                          editorKey={selfEditorKey}
                        />
                        <div className="flex justify-start items-center m-1 p-1">
                          <button
                            type="button"
                            onClick={handleSaveSelfNote}
                            className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                          >
                            <p className="text-[10px] px-2 py-1">Save</p>
                          </button>
                          <button
                            type="button"
                            onClick={handleCancelEditSelfNote}
                            className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                          >
                            <p className="text-[10px] px-2 py-1">Cancel</p>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-start items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Add</p>
                        <button type="button" onClick={handleAddSelfNote}>
                          <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                        </button>
                      </div>
                    )}

                    {/* Session Self Note Trail */}

                    <div className="m-1 p-1">
                      <div>
                        <p className="m-1 p-1 text-xs font-bold">
                          Session Notes Trail:
                        </p>
                      </div>
                      <div>
                        {coachSelfNotes
                          ?.slice()
                          .sort(
                            (a, b) =>
                              new Date(b.coachSelfNoteDate) -
                              new Date(a.coachSelfNoteDate)
                          ) // Sort in descending order
                          .map((note, noteIndex, arr) => (
                            <div
                              key={note._id}
                              className="bg-white p-1 m-1 rounded"
                            >
                               {/* 13 Sep 2024 - refined display */}
                               <RenderNotes 
                                  note={note}
                                  noteTextKey="coachSelfNoteText"
                                  updateDateKey="coachSelfNoteUpdateDate"
                                  createdDateKey="coachSelfNoteDate"
                                  showDetail={showCoachSelfAllUsersNoteDetail}
                                  toggleShowDetail={handleToggleCoachSelfAllUsersNoteDetail}
                                  handleEditNote={handleEditSelfNote}
                                  handleDeleteNote={handleDeleteSelfNote}
                                  noteIndex={noteIndex}
                                  arr={arr}
                                  createMarkup={createMarkup}
                                />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Section 4 - Coach's Session All Users Notes */}
            <div className="m-1 p-1 bg-gray-100 rounded">
              <div className="flex justify-between items-center m-1 p-1">
                <div className="flex justify-start items-center m-1 p-1">
                  <div
                    onClick={() => setShowAllUsersNotes((prev) => !prev)}
                    className="mx-2 rounded-full h-7 w-7 hover:bg-white flex justify-center items-center cursor-pointer"
                  >
                    {showAllUsersNotes ? (
                      <MinusIcon className="h-5 w-5 text-orange-600" />
                    ) : (
                      <PlusIcon className="h-5 w-5 text-orange-600" />
                    )}
                  </div>
                  <div>
                    <EtDocSharedNoteAllUsersIconSVGComp className="h-7 w-7 text-orange-600" />
                  </div>
                  <div>
                    <p className="text-md font-bold text-gray-600 mx-2 px-2">
                      Coach's Session Notes - All Users
                    </p>
                    <p className="text-[10px] italic text-gray-600 mx-2 px-2">
                      Capture your session experiences, thoughts and
                      observations about the participants which you can share
                      with them.{" "}
                    </p>
                  </div>
                </div>
              </div>

              {showAllUsersNotes && (
                <div className="m-2 p-2 bg-gray-300 rounded">
                  <div className="m-1 p-1 border border-white rounded">
                    {coachAllUsersNotes?.length === 0 && (
                      <div className=" ">
                        <p className="text-[11px] text-gray-500 italic mx-2 px21">
                          No all users notes available. Add your first note
                          below.
                        </p>
                      </div>
                    )}

                    {/* Add Note Form */}
                    {isAllUsersNoteEditing ? (
                      <div className="bg-gray-50 p-1 m-1 rounded">
                        <EtContentEditorLight2
                          value={allUsersEditorValue}
                          handleChange={handleAllUsersEditorChange}
                          placeholderText="Write your all users note here..."
                          editorKey={allUsersEditorKey}
                        />
                        <div className="flex justify-start items-center m-1 p-1">
                          <button
                            type="button"
                            onClick={handleSaveAllUsersNote}
                            className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                          >
                            <p className="text-[10px] px-2 py-1">Save</p>
                          </button>
                          <button
                            type="button"
                            onClick={handleCancelEditAllUsersNote}
                            className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                          >
                            <p className="text-[10px] px-2 py-1">Cancel</p>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-start items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Add</p>
                        <button type="button" onClick={handleAddAllUsersNote}>
                          <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                        </button>
                      </div>
                    )}

                    {/* Session AllUsers Note Trail */}

                    <div className="m-1 p-1">
                      <div>
                        <p className="m-1 p-1 text-xs font-bold">
                          Session Notes Trail:
                        </p>
                      </div>
                      <div>
                        {coachAllUsersNotes
                          ?.slice()
                          .sort(
                            (a, b) =>
                              new Date(b.coachAllUsersNoteDate) -
                              new Date(a.coachAllUsersNoteDate)
                          ) // Sort in descending order
                          .map((note, noteIndex, arr) => (
                            <div
                              key={note._id}
                              className="bg-white p-1 m-1 rounded"
                            >
                              <RenderNotes 
                                  note={note}
                                  noteTextKey="coachAllUsersNoteText"
                                  updateDateKey="coachAllUsersNoteUpdateDate"
                                  createdDateKey="coachAllUsersNoteDate"
                                  showDetail={showCoachAllUsersNoteDetail}
                                  toggleShowDetail={handleToggleCoachAllUsersNoteDetail}
                                  handleEditNote={handleEditAllUsersNote}
                                  handleDeleteNote={handleDeleteAllUsersNote}
                                  noteIndex={noteIndex}
                                  arr={arr}
                                  createMarkup={createMarkup}
                                />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Section 5 - Coach's Session Self User Specific Notes */}
            <div className="m-1 p-1 bg-gray-100 rounded">
              <div className="flex justify-between items-center m-1 p-1">
                <div className="flex justify-start items-center m-1 p-1">
                  <div
                    onClick={() => setShowUserSections((prev) => !prev)}
                    className="mx-2 rounded-full h-7 w-7 hover:bg-white flex justify-center items-center cursor-pointer"
                  >
                    {showUserSections ? (
                      <MinusIcon className="h-5 w-5 text-orange-600" />
                    ) : (
                      <PlusIcon className="h-5 w-5 text-orange-600" />
                    )}
                  </div>
                  <div className="">
                    <EtDocSelfUserNoteIconSVGComp className="h-6 w-6 text-orange-600" />
                  </div>
                  <div>
                    <p className="text-md font-bold text-gray-600 mx-2 px-2">
                      Coach's Session Notes - Self - Specific User
                    </p>
                    <p className="text-[10px] italic text-gray-600 mx-2 px-2">
                      Capture your session experiences, thoughts and
                      observations about specific participants.{" "}
                    </p>
                  </div>
                </div>
              </div>
              {showUserSections &&
                filteredSessionUsers &&         // 10 Sep 2024 - changed from sessionUsers to filteredSessionUsers
                filteredSessionUsers?.map((user, userIndex) => {
                  const userId = user.userId?._id
                  // console.log('user id:', userId)

                  return (
                    <div key={userIndex} className="m-1 p-1 bg-gray-50 rounded">
                      <div className="flex justify-between items-center m-1 p-1">
                        <div className=" flex justify-start items-center m-1 p-1">
                          <div
                            onClick={() => handleToggleUserDetails(userId)}
                            className="mx-2 rounded-full h-7 w-7 hover:bg-white flex justify-center items-center cursor-pointer"
                          >
                            {showUserSectionDetails[userId] ? (
                              <MinusIcon className="h-5 w-5 text-orange-600" />
                            ) : (
                              <PlusIcon className="h-5 w-5 text-orange-600" />
                            )}
                          </div>

                          <div className="mx-1 px-1">
                            <User
                              username={user.userId?.username}
                              defaultUserPic={userProfileIcon2}
                            />
                          </div>
                          <div className="mx-1 px-1">
                            <p className="text-xs text-gray-600 font-bold">
                              {user.userId?.name}
                            </p>
                            <p className="text-[10px] text-gray-400">
                              {user.userId?.email}
                            </p>
                          </div>
                        </div>
                      </div>

                      {showUserSectionDetails[userId] &&
                        showSelfUserNotes[userId] && (
                          <div className="m-2 p-2 bg-gray-300 rounded">
                            <div className="m-1 p-1 border border-white rounded">
                              {coachSelfUserNotes?.filter(
                                (note) => note.userId === userId
                              )?.length === 0 && (
                                <div className=" ">
                                  <p className="text-[11px] text-gray-500 italic mx-2 px21">
                                    No users notes available. Add your first
                                    note below by selecting a user from the list
                                    below.
                                  </p>
                                </div>
                              )}

                              {/* Add Note Form */}
                              {isSelfUserNoteEditing[userId] ? (
                                <div className="bg-gray-50 p-1 m-1 rounded">
                                  <EtContentEditorLight2
                                    value={selfUserEditorValue[userId]}
                                    handleChange={(value) =>
                                      handleSelfUserEditorChange(value, userId)
                                    }
                                    placeholderText="Write your all users note here..."
                                    editorKey={selfUserEditorKey + userId}
                                  />
                                  <div className="flex justify-start items-center m-1 p-1">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleSaveSelfUserNote(userId)
                                      }
                                      className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                                    >
                                      <p className="text-[10px] px-2 py-1">
                                        Save
                                      </p>
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleCancelEditSelfUserNote(userId)
                                      }
                                      className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                                    >
                                      <p className="text-[10px] px-2 py-1">
                                        Cancel
                                      </p>
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className="flex justify-start items-center m-1 p-1">
                                  <p className="font-bold text-xs m-1 p-1">
                                    Add
                                  </p>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleAddSelfUserNote(userId)
                                    }
                                  >
                                    <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                                  </button>
                                </div>
                              )}

                              {/* Session SelfUser Note Trail */}

                              <div className="m-1 p-1">
                                <div>
                                  <p className="m-1 p-1 text-xs font-bold">
                                    Session Notes Trail:
                                  </p>
                                </div>
                                <div>
                                  {coachSelfUserNotes
                                    ?.filter((note) => note.userId === userId)
                                    ?.slice()
                                    .sort(
                                      (a, b) =>
                                        new Date(b.coachSelfUserNoteDate) -
                                        new Date(a.coachSelfUserNoteDate)
                                    ) // Sort in descending order
                                    .map((note, noteIndex, arr) => (
                                      <div
                                        key={note._id}
                                        className="bg-white p-1 m-1 rounded"
                                      >
                                        <RenderNotes 
                                          note={note}
                                          noteTextKey="coachSelfUserNoteText"
                                          updateDateKey="coachSelfUserNoteUpdateDate"
                                          createdDateKey="coachSelfUserNoteDate"
                                          showDetail={showCoachSelfUserNoteDetail}
                                          toggleShowDetail={handleToggleCoachSelfUserNoteDetail}
                                          handleEditNote={handleEditSelfUserNote}
                                          handleDeleteNote={handleDeleteSelfUserNote}
                                          noteIndex={noteIndex}
                                          userId={userId}
                                          arr={arr}
                                          createMarkup={createMarkup}
                                        />
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  )
                })}
            </div>

            {/* Section 6 - Coach's Session Shared User Specific Notes */}
            <div className="m-1 p-1 bg-gray-100 rounded">
              <div className="flex justify-between items-center m-1 p-1">
                <div className="flex justify-start items-center m-1 p-1">
                  <div
                    onClick={() => setShowSharedUserSections((prev) => !prev)}
                    className="mx-2 rounded-full h-7 w-7 hover:bg-white flex justify-center items-center cursor-pointer"
                  >
                    {showSharedUserSections ? (
                      <MinusIcon className="h-5 w-5 text-orange-600" />
                    ) : (
                      <PlusIcon className="h-5 w-5 text-orange-600" />
                    )}
                  </div>
                  <div>
                    <EtDocSharedUserNoteIconSVGComp className="h-6 w-6 text-orange-600" />
                  </div>
                  <div>
                    <p className="text-md font-bold text-gray-600 mx-2 px-2">
                      Coach's Session Notes - Shared - User Specific
                    </p>
                    <p className="text-[10px] italic text-gray-600 mx-2 px-2">
                      Capture your session experiences, thoughts and
                      observations about specific participants which you would
                      share with them.{" "}
                    </p>
                  </div>
                </div>
              </div>
              {showSharedUserSections &&
                filteredSessionUsers &&             // 10 Sep 2024 - changed from sessionUsers to filteredSessionUsers
                filteredSessionUsers?.map((user, userIndex) => {
                  const userId = user.userId?._id
                  // console.log('user id:', userId)

                  return (
                    <div key={userIndex} className="m-1 p-1 bg-gray-50 rounded">
                      <div className="flex justify-between items-center m-1 p-1">
                        <div className=" flex justify-start items-center m-1 p-1">
                          <div
                            onClick={() =>
                              handleToggleSharedUserDetails(userId)
                            }
                            className="mx-2 rounded-full h-7 w-7 hover:bg-white flex justify-center items-center cursor-pointer"
                          >
                            {showSharedUserSectionDetails[userId] ? (
                              <MinusIcon className="h-5 w-5 text-orange-600" />
                            ) : (
                              <PlusIcon className="h-5 w-5 text-orange-600" />
                            )}
                          </div>

                          <div className="mx-1 px-1">
                            <User
                              username={user.userId?.username}
                              defaultUserPic={userProfileIcon2}
                            />
                          </div>
                          <div className="mx-1 px-1">
                            <p className="text-xs text-gray-600 font-bold">
                              {user.userId?.name}
                            </p>
                            <p className="text-[10px] text-gray-400">
                              {user.userId?.email}
                            </p>
                          </div>
                        </div>
                      </div>

                      {showSharedUserSectionDetails[userId] &&
                        showSharedUserNotes[userId] && (
                          <div className="m-2 p-2 bg-gray-300 rounded">
                            <div className="m-1 p-1 border border-white rounded">
                              {coachSharedUserNotes?.filter(
                                (note) => note.userId === userId
                              )?.length === 0 && (
                                <div className=" ">
                                  <p className="text-[11px] text-gray-500 italic mx-2 px21">
                                    No users notes available. Add your first
                                    note below by selecting a user from the list
                                    below.
                                  </p>
                                </div>
                              )}

                              {/* Add Note Form */}
                              {isSharedUserNoteEditing[userId] ? (
                                <div className="bg-gray-50 p-1 m-1 rounded">
                                  <EtContentEditorLight2
                                    value={sharedUserEditorValue[userId]}
                                    handleChange={(value) =>
                                      handleSharedUserEditorChange(
                                        value,
                                        userId
                                      )
                                    }
                                    placeholderText="Write your all users note here..."
                                    editorKey={sharedUserEditorKey + userId}
                                  />
                                  <div className="flex justify-start items-center m-1 p-1">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleSaveSharedUserNote(userId)
                                      }
                                      className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                                    >
                                      <p className="text-[10px] px-2 py-1">
                                        Save
                                      </p>
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleCancelEditSharedUserNote(userId)
                                      }
                                      className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                                    >
                                      <p className="text-[10px] px-2 py-1">
                                        Cancel
                                      </p>
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className="flex justify-start items-center m-1 p-1">
                                  <p className="font-bold text-xs m-1 p-1">
                                    Add
                                  </p>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleAddSharedUserNote(userId)
                                    }
                                  >
                                    <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                                  </button>
                                </div>
                              )}

                              {/* Session SharedUser Note Trail */}

                              <div className="m-1 p-1">
                                <div>
                                  <p className="m-1 p-1 text-xs font-bold">
                                    Session Notes Trail:
                                  </p>
                                </div>
                                <div>
                                  {coachSharedUserNotes
                                    ?.filter((note) => note.userId === userId)
                                    ?.slice()
                                    .sort(
                                      (a, b) =>
                                        new Date(b.coachSharedUserNoteDate) -
                                        new Date(a.coachSharedUserNoteDate)
                                    ) // Sort in descending order
                                    .map((note, noteIndex, arr) => (
                                      <div
                                        key={note._id}
                                        className="bg-white p-1 m-1 rounded"
                                      >
                                        <RenderNotes 
                                          note={note}
                                          noteTextKey="coachSharedUserNoteText"
                                          updateDateKey="coachSharedUserNoteUpdateDate"
                                          createdDateKey="coachSharedUserNoteDate"
                                          showDetail={showCoachSharedUserNoteDetail}
                                          toggleShowDetail={handleToggleCoachSharedUserNoteDetail}
                                          handleEditNote={handleEditSharedUserNote}
                                          handleDeleteNote={handleDeleteSharedUserNote}
                                          noteIndex={noteIndex}
                                          userId={userId}
                                          arr={arr}
                                          createMarkup={createMarkup}
                                        />
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  )
                })}
            </div>

            {/* Section 7 - Coach's Session Challenge Evaluation - 25 Aug 2024*/}
            <div className="m-1 p-1 bg-gray-100 rounded">
              <div className="flex justify-between items-center m-1 p-1">
                <div className="flex justify-start items-center m-1 p-1">
                  <div
                    onClick={() => setShowCoachEvalsSection((prev) => !prev)}
                    className="mx-2 rounded-full h-7 w-7 hover:bg-white flex justify-center items-center cursor-pointer"
                  >
                    {showCoachEvalsSection ? (
                      <MinusIcon className="h-5 w-5 text-orange-600" />
                    ) : (
                      <PlusIcon className="h-5 w-5 text-orange-600" />
                    )}
                  </div>
                  <div>
                    <EtCoachEvalIcon2SVGComp className="h-7 w-7 text-orange-600" />
                  </div>
                  <div>
                    <p className="text-md font-bold text-gray-600 mx-2 px-2">
                      Coach's Session Evaluations
                    </p>
                    <p className="text-[10px] italic text-gray-600 mx-2 px-2">
                      Evaluate users on the session challenges
                    </p>
                  </div>
                </div>
              </div>

              {showCoachEvalsSection && (
                <div className="m-1 p-1">
                  {/* Display Session Challenges */}
                  <div className="m-1 p-1">
                    <p className="text-gray-600 font-bold mx-1 px-1">
                      Session Challenges
                    </p>
                  </div>
                  {sessionChallenges &&
                    sessionChallenges?.length > 0 &&
                    sessionChallenges?.map((challenge, index) => (
                      <div
                        key={challenge.challengeId._id + index}
                        className="m-1 p-1 bg-gray-200 rounded"
                      >
                        <div className="flex justify-between items-center mx-1 px-1">
                          <div className="flex justify-start items-center">
                            <div
                              onClick={() =>
                                handleToggleChallengeSections(
                                  challenge.challengeId._id
                                )
                              }
                              className="cursor-pointer m-1 p-1"
                            >
                              {showChallengeSections[
                                challenge.challengeId._id
                              ] ? (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                              ) : (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                              )}
                            </div>
                            <div className="m-1 p-1">
                              <p className="font-bold">
                                {challenge.challengeId?.challengeName}
                              </p>
                              <p className="text-[10px] italic">
                                {challenge.challengeId?.challengeDesc}
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* Challenge Details Section */}
                        {showChallengeSections[challenge.challengeId._id] && (
                          <div>
                            {/* Section - Challenge Instructions */}
                            <div className="mx-1 px-1">
                              <div className="bg-gray-50 rounded">
                                <div className="flex justify-between items-center m-1 p-1">
                                  <div className="flex justify-start items-center m-1 p-1">
                                    <div
                                      className="m-1 p-1 cursor-pointer rounded-full h-6 w-6 flex justify-center items-center hover:bg-white"
                                      onClick={() =>
                                        handleToggleInstructionDetails(
                                          challenge.challengeId._id
                                        )
                                      }
                                    >
                                      {showInstructionDetails[
                                        challenge.challengeId._id
                                      ] ? (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                      ) : (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                      )}
                                    </div>
                                    <div className="rounded-full h-7 w-7 flex justify-center items-center hover:bg-white">
                                      <WrenchIcon className="h-6 w-6 text-orange-600" />
                                    </div>
                                    <div>
                                      <p className="mx-1 px-1 text-gray-500 font-bold">
                                        Instructions
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                {showInstructionDetails[
                                  challenge.challengeId._id
                                ] &&
                                  challenge.challengeId?.challengeInstructions
                                    ?.length > 0 && (
                                    <div className="m-1 p-1 rounded">
                                      <div className="m-1 p-1 rounded bg-gray-300">
                                        {challenge.challengeId?.challengeInstructions?.map(
                                          (instruction, insIndex) => {
                                            return (
                                              <div
                                                key={instruction._id + insIndex}
                                                className="bg-gray-100 m-1 p-1 rounded"
                                              >
                                                <div className="flex justify-start items-center m-1 p-1">
                                                  <div>
                                                    <p className="text-gray-400 font-bold mx-1 px-1">
                                                      {insIndex + 1}.
                                                    </p>
                                                  </div>
                                                  <div>
                                                    <div>
                                                      <p className="text-[10px] mx-1 px-1">
                                                        <span className="">
                                                          {
                                                            instruction.instruction
                                                          }
                                                        </span>{" "}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          }
                                        )}
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>

                            {/* Section - Challenge Dimensions & Ealuations */}
                            <div className="mx-1 px-1">
                              <div className="bg-gray-50 rounded">
                                <div className="flex justify-between items-center m-1 p-1">
                                  <div className="flex justify-start items-center m-1 p-1">
                                    <div
                                      className="m-1 p-1 cursor-pointer rounded-full h-6 w-6 flex justify-center items-center hover:bg-white"
                                      onClick={() =>
                                        handleToggleChallengeHeadSectionDetails(
                                          challenge.challengeId?._id
                                        )
                                      }
                                    >
                                      {showChallengeHeadSectionDetails[
                                        challenge.challengeId?._id
                                      ] ? (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                      ) : (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                      )}
                                    </div>
                                    <div className="rounded-full h-7 w-7 flex justify-center items-center hover:bg-white">
                                      <SunIcon className="h-6 w-6 text-orange-600" />
                                    </div>
                                    <div>
                                      <p className="mx-1 px-1 text-gray-500 font-bold">
                                        Dimensions
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {/* Challenge Heads Section */}
                                {showChallengeHeadSectionDetails[
                                  challenge.challengeId?._id
                                ] &&
                                  challenge.challengeId?.challengeDimensions &&
                                  challenge.challengeId.challengeDimensions
                                    .length > 0 && (
                                    <div className="">
                                      <div className="m-1 p-1 rounded">
                                        {Object.entries(
                                          challenge.challengeId.challengeDimensions.reduce(
                                            (acc, dimension) => {
                                              const headName =
                                                dimension.challengeDimId
                                                  ?.challengeHeadId
                                                  ?.challengeHeadName ||
                                                "Unknown Head"
                                              if (!acc[headName])
                                                acc[headName] = []
                                              acc[headName].push(dimension)
                                              return acc
                                            },
                                            {}
                                          )
                                        ).map(
                                          (
                                            [headName, dimensions],
                                            headIndex
                                          ) => (
                                            <div
                                              key={headName + headIndex}
                                              className="bg-gray-100 m-1 p-1 rounded"
                                            >
                                              <div className="">
                                                <div className="grid grid-cols-12 justify-between items-center m-1 p-1">
                                                  {/* Left Side Challenge Head Bar */}
                                                  <div className="col-span-4 flex justify-start items-center m-1 p-1">
                                                    <div
                                                      onClick={() =>
                                                        handleToggleChallengeHeadDetails(
                                                          challenge.challengeId
                                                            ._id,
                                                          headName
                                                        )
                                                      }
                                                      className="cursor-pointer"
                                                    >
                                                      {showChallengeHeadDetails[
                                                        `${challenge.challengeId._id}-${headName}`
                                                      ] ? (
                                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                                      ) : (
                                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                                      )}
                                                    </div>
                                                    <div className="mx-1 px-1">
                                                      <div>
                                                        <p className="mx-1 px-1 font-bold">
                                                          {headName}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  {/* User Header Bar - to display each user who is part of the session */}
                                                  {showChallengeHeadDetails[
                                                    `${challenge.challengeId._id}-${headName}`
                                                  ] && (
                                                    <div className="col-span-8 gap-2 flex justify-end items-center">
                                                      {/* Display each user here */}
                                                      {sessionUsers?.map(
                                                        (user, index) => (
                                                          <div
                                                            key={
                                                              user.userId._id +
                                                              index
                                                            }
                                                            className="flex flex-col justify-center items-center m-1 p-1"
                                                          >
                                                            <div className="w-16">
                                                              <p className="text-orange-600 text-[9px] text-center">
                                                                User
                                                              </p>
                                                            </div>
                                                            <div className="m-1 p-1">
                                                              <User
                                                                username={
                                                                  user.userId
                                                                    ?.username
                                                                }
                                                                defaultUserPic={
                                                                  userProfileIcon2
                                                                }
                                                              />
                                                            </div>
                                                            <div className="w-16 my-1">
                                                              <p className="text-gray-600 text-[10px] text-center">
                                                                {
                                                                  user.userId?.name.split(
                                                                    " "
                                                                  )[0]
                                                                }
                                                              </p>
                                                            </div>
                                                            {/* Add Evaluate button under each User */}
                                                            <div
                                                              onClick={() =>
                                                                handleEvaluate(
                                                                  challenge
                                                                    .challengeId
                                                                    ._id,
                                                                  user.userId
                                                                    ._id,
                                                                  user.userId
                                                                    .userOrgId
                                                                )
                                                              }
                                                              className="cursor-pointer w-16"
                                                            >
                                                              <p className="mx-1 px-1 text-[10px] text-orange-600">
                                                                Evaluate
                                                              </p>
                                                            </div>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                              </div>

                                              {/* Challenge Dimensions Section */}
                                              {showChallengeHeadDetails[
                                                `${challenge.challengeId._id}-${headName}`
                                              ] && (
                                                <div className="">
                                                  {/* Challenge Dimensions Section */}
                                                  {dimensions?.map(
                                                    (dimension, dimIndex) => (
                                                      <div
                                                        key={
                                                          dimension
                                                            .challengeDimId
                                                            ._id + dimIndex
                                                        }
                                                        className="bg-gray-200 m-1 p-1 rounded"
                                                      >
                                                        <div className="grid grid-cols-12 justify-between items-center">
                                                          <div className="col-span-4 flex justify-start items-center m-1 p-1">
                                                            <div
                                                              onClick={() =>
                                                                handleToggleChallengeDimDetails(
                                                                  challenge
                                                                    .challengeId
                                                                    ._id,
                                                                  headName,
                                                                  dimension
                                                                    .challengeDimId
                                                                    ._id
                                                                )
                                                              }
                                                              className="cursor-pointer m-1 p-1"
                                                            >
                                                              {showChallengeDimDetails[
                                                                `${challenge.challengeId._id}-${headName}-${dimension.challengeDimId._id}`
                                                              ] ? (
                                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                                              ) : (
                                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                                              )}
                                                            </div>
                                                            
                                                            <div>
                                                              <p className="text-4xl text-gray-400 font-bold mx-1 px-1">
                                                                {dimIndex + 1}.
                                                              </p>
                                                            </div>

                                                            <div>
                                                              <div>
                                                                <p className="text-[10px] mx-1 px-1">
                                                                  <span className="font-bold">
                                                                    {
                                                                      dimension
                                                                        .challengeDimId
                                                                        ?.challengeDimensionName
                                                                    }
                                                                  </span>
                                                                </p>
                                                              </div>
                                                              <div>
                                                                <p className="text-[10px] mx-1 px-1 italic">
                                                                  {
                                                                    dimension
                                                                      .challengeDimId
                                                                      ?.challengeDimensionDesc
                                                                  }
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          {/* Display Eval Scores */}
                                                          <div className="col-span-8 gap-2 flex justify-end items-center">
                                                            {/* Iterate over each session user */}
                                                            {sessionUsers?.map(
                                                              (
                                                                user,
                                                                userIndex
                                                              ) => {
                                                                // Filter the evaluations for the current user and dimension
                                                                const userEvals =
                                                                  coachSessionEvals?.filter(
                                                                    (
                                                                      evalItem
                                                                    ) =>
                                                                      evalItem
                                                                        .sessionChallengeCoachEvalId
                                                                        .userId ===
                                                                        user
                                                                          .userId
                                                                          ._id &&
                                                                      evalItem.sessionChallengeCoachEvalId.userChallengeCoachEvals.some(
                                                                        (
                                                                          coachEval
                                                                        ) =>
                                                                          coachEval.userChallengeDimId ===
                                                                          dimension
                                                                            .challengeDimId
                                                                            ._id
                                                                      )
                                                                  )
                                                                // console.log('userEvals:', userEvals)

                                                                return (
                                                                  <div
                                                                    key={
                                                                      user.userId +
                                                                      userIndex
                                                                    }
                                                                    className="flex flex-col justify-center items-center m-1 p-1"
                                                                  >
                                                                    {/* Check if the user has evaluations for this dimension */}
                                                                    {userEvals?.length >
                                                                    0 ? (
                                                                      userEvals.map(
                                                                        (
                                                                          userEval,
                                                                          evalIndex
                                                                        ) => {
                                                                          const coachEvalScore =
                                                                            userEval.sessionChallengeCoachEvalId.userChallengeCoachEvals.find(
                                                                              (
                                                                                evaluation
                                                                              ) =>
                                                                                evaluation.userChallengeDimId ===
                                                                                dimension
                                                                                  .challengeDimId
                                                                                  ._id
                                                                            )?.userChallengeDimCoachScore

                                                                          const displayCoachEvalScore =
                                                                            coachEvalScore !==
                                                                              undefined &&
                                                                            coachEvalScore !==
                                                                              null
                                                                              ? coachEvalScore
                                                                              : "N/A"

                                                                          return (
                                                                            <div
                                                                              key={
                                                                                userEval
                                                                                  .sessionChallengeCoachEvalId
                                                                                  ._id +
                                                                                evalIndex
                                                                              }
                                                                              className="flex flex-col justify-center items-center"
                                                                            >
                                                                              <div
                                                                                className={`flex justify-center items-center ${getColorForScore(
                                                                                  displayCoachEvalScore
                                                                                )} rounded h-16 w-16 border border-gray-400 my-1 py-1`}
                                                                              >
                                                                                <p className="text-white">
                                                                                  {
                                                                                    displayCoachEvalScore
                                                                                  }
                                                                                </p>
                                                                              </div>
                                                                            </div>
                                                                          )
                                                                        }
                                                                      )
                                                                    ) : (
                                                                      <div className="flex flex-col justify-center items-center">
                                                                        <div className="h-16 w-16 flex justify-center items-center border border-gray-400 rounded my-1 py-1 bg-gradient-to-r from-gray-800 to-gray-800 via-black">
                                                                          <p className="text-gray-500 text-[9px]">
                                                                            No
                                                                            Eval
                                                                          </p>
                                                                        </div>
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                )
                                                              }
                                                            )}
                                                          </div>
                                                        </div>

                                                        {/* Dimension Evaluation Section */}
                                                        {showChallengeDimDetails[
                                                          `${challenge.challengeId._id}-${headName}-${dimension.challengeDimId._id}`
                                                        ] && (
                                                          <div className="grid grid-cols-9 xl:grid-cols-10 gap-y-2 justify-start items-center bg-gray-50 my-2 px-1 py-2 rounded">
                                                            {sessionUsers?.map(
                                                              (
                                                                user,
                                                                userIndex
                                                              ) => {
                                                                // Extract evaluations for the current user and dimension
                                                                const userEvals =
                                                                  coachSessionEvals?.filter(
                                                                    (
                                                                      evalItem
                                                                    ) =>
                                                                      evalItem
                                                                        .sessionChallengeCoachEvalId
                                                                        .userId ===
                                                                        user
                                                                          .userId
                                                                          ._id &&
                                                                      evalItem.sessionChallengeCoachEvalId.userChallengeCoachEvals.some(
                                                                        (
                                                                          coachEval
                                                                        ) =>
                                                                          coachEval.userChallengeDimId ===
                                                                          dimension
                                                                            .challengeDimId
                                                                            ._id
                                                                      )
                                                                  )

                                                                return (
                                                                  <div
                                                                    key={`${user.userId._id}-${userIndex}`}
                                                                    className="col-span-3 xl:col-span-2 m-1 p-1 bg-gray-100 h-full"
                                                                  >
                                                                    {/* Check if the user has evaluations for this dimension */}
                                                                    {userEvals?.length >
                                                                    0 ? (
                                                                      userEvals.map(
                                                                        (
                                                                          userEval,
                                                                          evalIndex
                                                                        ) => {
                                                                          const coachEvaluation =
                                                                            userEval.sessionChallengeCoachEvalId.userChallengeCoachEvals.find(
                                                                              (
                                                                                evaluation
                                                                              ) =>
                                                                                evaluation.userChallengeDimId ===
                                                                                dimension
                                                                                  .challengeDimId
                                                                                  ._id
                                                                            )

                                                                          const coachEvalText =
                                                                            coachEvaluation?.userChallengeDimCoachEvalText ||
                                                                            "No comment"
                                                                          const evalUpdateDate =
                                                                            coachEvaluation?.userChallengeDimCoachEvalTextUpdateDate
                                                                              ? new Date(
                                                                                  coachEvaluation.userChallengeDimCoachEvalTextUpdateDate
                                                                                ).toLocaleDateString()
                                                                              : "No update date"

                                                                          return renderUserEvalInfo(user, coachEvalText, evalUpdateDate)
                                                                        }
                                                                      )
                                                                    ) : (
                                                                      renderUserEvalInfo(user, "Coach has not evaluated this dimension for the user yet.", "No update date", "text-orange-600")
                                                                    )}
                                                                  </div>
                                                                )
                                                              }
                                                            )}
                                                          </div>
                                                        )}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              )}
              <EtModalAddOrUpdateUserChallengeCoachEval
                isOpen={isModalOpen}
                onClose={handleCloseChallengeEvalModal}
                challengeId={selectedChallengeId}
                coachId={coachId}
                coachOrgId={coachOrgId}
                userId={selectedUserId}
                userOrgId={selectedUserOrgId}
                trailId={trailId}
                sessionId={sessionId}
                triggeringUrl={triggeringUrl}
                mode={mode} // 27 Aug 2024
                existingEvalData={existingEvalData} // 27 Aug 2024
              />
            </div>
            
             {/* Section 8 - Coach's Session Planned User Actions - 01 Sep 2024*/}
             <div className="m-1 p-1 bg-gray-100 rounded">
                <div className="flex justify-between items-center m-1 p-1">
                  <div className="flex justify-start items-center m-1 p-1">
                    <div
                      onClick={() => setShowCoachAllUsersActionsSection((prev) => !prev)}
                      className="mx-2 rounded-full h-7 w-7 hover:bg-white flex justify-center items-center cursor-pointer"
                    >
                      {showCoachAllUsersActionsSection ? (
                        <MinusIcon className="h-5 w-5 text-orange-600" />
                      ) : (
                        <PlusIcon className="h-5 w-5 text-orange-600" />
                      )}
                    </div>
                    <div>
                      <EtCoachUserActionIcon1SVGComp className="h-7 w-7 text-orange-600" />
                    </div>
                    <div>
                      <p className="text-md font-bold text-gray-600 mx-2 px-2">
                        Coach's Session Planned Actions
                      </p>
                      <p className="text-[10px] italic text-gray-600 mx-2 px-2">
                        Plan and share actions for all session users
                      </p>
                    </div>
                  </div>
                </div>

                {showCoachAllUsersActionsSection && (
                  <div className="m-1 p-1">
                    {/* Add Planned Actions Button */}

                    <div className="flex justify-start items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Add</p>
                        <button type="button" onClick={handleCreatePlannedAction}>
                          <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                        </button>
                      </div>

                      <div className="m-1 p-1 bg-gray-200 rounded">
                          {/* Planned Actions Header */}
                          <div className="flex justify-start items-center m-1 p-1">
                            <div
                              onClick={() => setShowCoachAllUsersCreatedActions((prev) => !prev)}
                              className="mx-2 rounded-full h-7 w-7 hover:bg-white flex justify-center items-center cursor-pointer"
                            >
                              {showCoachAllUsersCreatedActions ? (
                                <MinusIcon className="h-5 w-5 text-orange-600" />
                              ) : (
                                <PlusIcon className="h-5 w-5 text-orange-600" />
                              )}
                            </div>
                            <div>
                                <p className="text-gray-600 font-bold mx-1 px-1">Coach's Planned Actions</p>
                            </div>
                          </div>

                          {showCoachAllUsersCreatedActions && filteredCoachUserPlannedActions && filteredCoachUserPlannedActions?.length>0 &&  filteredCoachUserPlannedActions?.map((action, actionIndex) => {
                            // console.log('planned action:', action)
                            
                            return(
                              <div key={action._id+actionIndex} className="bg-white rounded m-1 p-1">
                                <div className="">  {/* original - bg-gray-50 rounded */}
                                  <div className="grid grid-cols-8 justify-between items-center">
                                    <div className="col-span-5 flex justify-start items-center">

                                          <div
                                            onClick={() => handleToggleCoachUserActionUpdate(action._id)}
                                            className="mx-2 rounded-full h-7 w-7 hover:bg-white flex justify-center items-center cursor-pointer"
                                          >
                                            {showCoachUserActionUpdate[action._id] ? (
                                              <MinusIcon className="h-5 w-5 text-orange-600" />
                                            ) : (
                                              <PlusIcon className="h-5 w-5 text-orange-600" />
                                            )}
                                          </div>

                                          <div><p className="text-[11px] font-bold text-orange-600 mx-1 px-1">{action.coachActionTypeId.actionTypeName}</p></div>
                                          <div><p className="text-[11px] font-bold mx-1">Action {actionIndex+1}.</p></div>
                                          <div><p className="text-[11px] text-gray-600 mx-1">{action.coachSessionActionName}</p></div>
                                    </div>

                                    <div className="col-span-3 flex justify-between items-center ">
                                      <div className="flex justify-start items-center">
                                        <p className="text-gray-600 text-[9px]">Complete by:</p>
                                      <CalendarDaysIcon className="m-2 h-5 w-5 text-orange-600" />
                                      <p className="flex justify-center items-center text-[9px] rounded-full px-2 py-[1px] bg-white border text-gray-600 border-orange-400">
                                        {formatDate(action?.coachSessionActionCompleteBy)}
                                      </p>
                                      </div>
                                      
                                      <div className="flex justify-center items-center">
                                        <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                          <PencilSquareIcon
                                            // 10 Sep 2024 - Add an Onclick function here
                                            onClick={() => handleAddOrUpdatePlannedAction(action)}
                                            className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                          />
                                        </div>
                                        {/* <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                          <TrashIcon
                                            // Add an Onclick function here
                                            className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                          />
                                        </div> */}
                                      </div>
                                    </div>

                                  </div>
                                  {/* Show User Action Update for each planned action here*/}
                                  {/* {showCoachUserActionUpdate[action._id] && userCount>0 && sessionUsers?.map((user, userIndex)=> {

                                  })} */}

                                  {/* Show User Action Update for each planned action here */}
                                  {showCoachUserActionUpdate[action._id] && action.sessionUsersActions?.length > 0 && action.sessionUsersActions.map((userAction, userActionIndex) => {
                                    // Find the corresponding user for the userAction
                                    const user = sessionUsers?.find(u => u.userId._id === userAction.userActionId.userId._id);
                                    if (!user) return null;

                                    // Create userInfo object
                                    const userInfo = {
                                      _id: user.userId._id,
                                      username: user.userId.username,
                                      name: user.userId.name,
                                    };

                                    return (
                                      <div key={userAction.userActionId._id + userActionIndex} className="">
                                        {/* Pass userInfo and userActionId to EtUserActionListByIdComp */}
                                        <EtUserActionListByIdComp
                                          userInfo={userInfo}
                                          userActionId={userAction.userActionId._id}
                                          userId={userInfo._id}
                                          trailId={trailId}
                                          sessionId={sessionId}
                                        />
                                      </div>
                                    );
                                  })}

                                  
                                </div>

                              </div>
                            )
                          })}
                  
                    </div>  
                  </div>
                )}
                <EtModalAddOrUpdateCoachUserOrgTrailSessionAction 
                isOpen={isActionModalOpen}
                onClose={handleCloseCreateActionModal}
                coachId={coachId}
                coachOrgId={coachOrgId}
                userOrgId={orgId}
                trailId={trailId}
                sessionId={sessionId}
                triggeringUrl={triggeringActionUrl}
                sessionUsers={filteredSessionUsers}         // 02 Sep 2024 - added to pass sessionUsers , 10 Sep 2024 - updated to filteredSessionUsers
                mode={actionMode} // 10 Sep 2024
                existingActionData={existingActionData} // 10 Sep 2024
                />
             </div>
          </div>
        )}
      </div>
    </div>
  )
}

const User = ({ username, defaultUserPic }) => {
  const { data: userPic, isFetching: isFetchingUserPic } =
    useGetUserPicQuery(username)

  return (
    <div className="flex justify-center items-center h-12 w-12 rounded-full border border-white bg-gradient-to-r from-orange-600 to-orange-400 shadow-lg shadow-black cursor-pointer">
      {/* Display user picture or loading indicator*/}
      {isFetchingUserPic ? (
        <div>Loading...</div>
      ) : userPic ? (
        <img src={userPic} alt="User" className="h-11 w-11  rounded-full" />
      ) : (
        <img src={defaultUserPic} alt="User" className="h-10 w-10 p-1" />
      )}
    </div>
  )
}

// 30 Aug 2024 - Helper function to render user info and evaluation comments
const renderUserEvalInfo = (user, coachEvalText, evalUpdateDate, textColor = "text-gray-800") => (
  <div className="flex flex-col items-start justify-start mx-1 px-1">
      <div className="flex justify-start items-center w-full">
          <div className="flex justify-start items-center m-1 p-1 w-16">
              {/* User Info */}
              <div className="flex flex-col justify-center items-center">
                  <div className="w-16">
                      <p className="text-orange-600 text-[9px] text-center">
                          User
                      </p>
                  </div>
                  <div className="mx-1 px-1">
                      <User
                          username={user.userId?.username}
                          defaultUserPic={userProfileIcon2}
                      />
                  </div>
                  <div className="w-16 my-1 py-1">
                      <p className="text-gray-600 text-[10px] text-center">
                          {user.userId?.name.split(" ")[0]}
                      </p>
                  </div>
              </div>
          </div>
          <div className="m-1 p-1 border-l-2 border-orange-600">
              <div>
                  <p className="mx-1 text-gray-600 font-bold text-[10px]">
                      Comments
                  </p>
              </div>
              <div>
                  <p className={`mx-1 ${textColor} text-[10px]`}>
                      {coachEvalText}
                  </p>
              </div>
          </div>
      </div>

      <div className="m-1 p-1">
          <div className="flex justify-start items-center">
              <ClockIcon className="h-5 w-5 text-lime-600" />
              <p className="mx-1 px-1 text-gray-500 text-[10px] font-bold">
                  Last Updated
              </p>
              <p className="text-[9px] mx-1 italic px-2 py-1 text-gray-400">
                  {getTimeSince(evalUpdateDate)}
              </p>
          </div>
      </div>
  </div>
);

// 13 Sep 2024 - Component for rendering coach's notes
const RenderNotes = ({
  note,
  noteTextKey,           // e.g., "coachSharedUserNoteText", "coachAllUsersNoteText"
  updateDateKey,         // e.g., "coachSharedUserNoteUpdateDate", "coachAllUsersNoteUpdateDate"
  createdDateKey,        // e.g., "coachSharedUserNoteDate", "coachAllUsersNoteDate"
  showDetail,            // State variable controlling visibility of full note
  toggleShowDetail,      // Function to toggle visibility
  handleEditNote,        // Function to handle editing of the note
  handleDeleteNote,      // Function to handle deletion of the note
  noteIndex,
  userId,
  arr,
  createMarkup,
}) => {

  const noteText = note[noteTextKey];
  const updateDate = note[updateDateKey];
  const createdDate = note[createdDateKey];
  const noteId = note._id;

  return (
    noteText.length > 0 && (
      <div className="bg-gray-100 m-1 p-1 rounded">
        <div className="grid grid-cols-8 justify-between items-center m-1 p-1">
          <div className="col-span-1 flex justify-start items-center">
            <div
              onClick={() => toggleShowDetail(noteId)}
              className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
            >
              {showDetail[noteId] ? (
                <MinusIcon className="h-4 w-4 text-orange-600" />
              ) : (
                <PlusIcon className="h-4 w-4 text-orange-600" />
              )}
            </div>
            <div>
              {/* <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{arr.length - noteIndex}.</p> */}
              <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ noteIndex +1}.</p>
            </div>
            <div>
              <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-18">
                {getTimeSince(updateDate)}
              </p>
              <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
            </div>
          </div>

          <div className="col-span-4 flex justify-start items-center">
            <div
              dangerouslySetInnerHTML={createMarkup(noteText)}
              className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
            ></div>
          </div>

          <div className="col-span-2 flex justify-start items-center mx-2">
            <ClockIcon className="h-5 w-5 text-lime-600" />
            <p className="text-[9px] mx-1">First Created:</p>
            <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdDate)}</p>
          </div>

          <div className="col-span-1 flex justify-start items-center mx-2">
            <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
              <PencilSquareIcon
                className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                // onClick={() => handleEditNote(userId, note)}
                onClick={() => (userId ? handleEditNote(userId, note) : handleEditNote(note))}
              />
            </div>
            <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
              <TrashIcon
                className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                onClick={() => handleDeleteNote(noteId)}
              />
            </div>
          </div>
        </div>

        {showDetail[noteId] && (
          <div className="m-1 p-1 rounded bg-gray-50">
            <div
              dangerouslySetInnerHTML={createMarkup(noteText)}
              className="text-[11px] text-gray-700 m-1 p-1"
            ></div>
          </div>
        )}
      </div>
    )
  );
}

export default EtCoachOrgTrailSessionPadCompProd