import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userSession8848O2DsAdapter = createEntityAdapter({})

const initialState = userSession8848O2DsAdapter.getInitialState()

export const userSession8848O2DsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserSession8848O2Ds: builder.query({
            query: () => ({
                url: '/userSession8848O2Ds/getUserSession8848O2Ds',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserSession8848O2Ds = responseData.map(userSession8848O2D => {
                    userSession8848O2D.id = userSession8848O2D._id
                    return userSession8848O2D
                })
                return userSession8848O2DsAdapter.setAll(initialState, loadedUserSession8848O2Ds)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserSession8848O2D', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserSession8848O2D', id}))
                    ]
                } else {
                    return [ { type: 'UserSession8848O2D', id: 'LIST'}]
                }
            },
        }),
        createNewUserSession8848O2D: builder.mutation({
            query: initialUserSession8848O2D => ({
                url: '/userSession8848O2Ds/createNewUserSession8848O2D',
                method: 'POST',
                body: {...initialUserSession8848O2D,}
            }),
            invalidatesTags: [
                { type: 'UserSession8848O2D', id: "LIST" }
            ] 
        }),
        updateUserSession8848O2D: builder.mutation({
            query: initialUserSession8848O2D => ({
                url: '/userSession8848O2Ds/updateUserSession8848O2D',
                method: 'PATCH',
                body: {...initialUserSession8848O2D,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserSession8848O2D', id: arg.id },
                { type: 'UserSession8848O2D', id: 'LIST' }
            ],
        }),
        deleteUserSession8848O2D: builder.mutation({
            query: ({id}) => ({
                url: '/userSession8848O2Ds/deleteUserSession8848O2D',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserSession8848O2D', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserSession8848O2DsQuery,
    useCreateNewUserSession8848O2DMutation,
    useUpdateUserSession8848O2DMutation,
    useDeleteUserSession8848O2DMutation,
} = userSession8848O2DsApiSlice

// returns the query result object
export const selectUserSession8848O2DResult = userSession8848O2DsApiSlice.endpoints.getUserSession8848O2Ds.select()

// create memoized selector
const selectUserSession8848O2DsData = createSelector(
    selectUserSession8848O2DResult,
    userSession8848O2DResult => userSession8848O2DResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserSession8848O2Ds,
    selectById: selectUserSession8848O2DById,
    selectIds: selectUserSession8848O2DIds,
} = userSession8848O2DsAdapter.getSelectors(state => selectUserSession8848O2DsData(state) ?? initialState)