
    import React from 'react';
    
    const EtShowcaseResultLongevityIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" fill="currentColor">
          <g transform="translate(-676 -141)"><path d="M766 222C758.839 222 751.972 224.845 746.908 229.908 741.844 234.971 739 241.839 739 249 739 256.161 741.845 263.028 746.908 268.092 751.971 273.156 758.839 276 766 276 773.161 276 780.028 273.155 785.092 268.092 790.156 263.029 793 256.161 793 249 793 241.839 790.155 234.972 785.092 229.908 780.029 224.844 773.161 222 766 222ZM779.005 255.525C778.631 256.267 778.059 256.891 777.352 257.328 776.645 257.765 775.831 257.997 775 258 774.294 258.008 773.597 257.838 772.975 257.505L763.975 253.005C763.233 252.631 762.609 252.059 762.172 251.352 761.735 250.645 761.502 249.831 761.5 249L761.5 240C761.5 238.392 762.357 236.907 763.75 236.103 765.142 235.299 766.857 235.299 768.25 236.103 769.642 236.907 770.5 238.392 770.5 240L770.5 246.21 777.025 249.495C778.084 250.034 778.888 250.971 779.259 252.101 779.63 253.231 779.538 254.462 779.005 255.525Z" fillRule="evenodd"/><path d="M748 154.5C748 153.307 747.526 152.162 746.682 151.318 745.838 150.474 744.693 150 743.5 150L725.5 150C724.307 150 723.162 150.474 722.318 151.318 721.474 152.162 721 153.307 721 154.5L721 159 748 159Z" fillRule="evenodd"/><path d="M730 249C730 239.452 733.793 230.296 740.544 223.544 747.296 216.793 756.452 213 766 213L766 177C766 175.42 765.584 173.868 764.794 172.5 764.004 171.132 762.868 169.996 761.5 169.206 760.132 168.416 758.58 168 757 168L712 168C709.613 168 707.324 168.948 705.636 170.636 703.948 172.324 703 174.613 703 177L703 267C703 268.58 703.416 270.132 704.206 271.5 704.996 272.868 706.132 274.004 707.5 274.794 708.868 275.584 710.42 276 712 276L742.285 276C738.436 272.631 735.35 268.48 733.232 263.824 731.114 259.168 730.012 254.115 730 249Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtShowcaseResultLongevityIcon1SVGComp;
    