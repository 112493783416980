
    import React from 'react';
    
    const EtWabActionIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m468 2070-352.8 446.4c-39.602 50.398-32.398 126 18 165.6 21.602 18 46.801 25.199 72 25.199 36 0 68.398-14.398 93.602-43.199l342-432-165.6-154.8-7.1992-7.1992z" fillRule="evenodd"/>
 <path d="m1137.6 1526.4-158.4-122.4c-28.801-25.199-68.398-32.398-108-25.199-36 7.1992-68.398 28.801-90 61.199l-255.6 414c-32.398 46.801-25.199 111.6 18 151.2l248.4 234-140.4 306c-25.199 57.602 0 129.6 57.602 154.8 14.398 7.1992 32.398 10.801 50.398 10.801 43.199 0 86.398-25.199 108-68.398l176.4-385.2c21.602-46.801 10.801-100.8-25.199-133.2l-158.4-147.6 208.8-262.8 252 72c10.801 3.6016 21.602 3.6016 32.398 3.6016l237.6-18c3.6016-68.398 10.801-133.2 25.199-198l-259.2 18z" fillRule="evenodd"/>
 <path d="m1134 1432.8c104.4 50.398 226.8 3.6016 273.6-97.199 50.398-104.4 3.6016-226.8-97.199-273.6-104.4-50.398-226.8-3.6016-273.6 97.199-50.402 100.8-7.1992 223.2 97.199 273.6z" fillRule="evenodd"/>
 <path d="m3510 1800c0 324.11-172.91 623.6-453.6 785.66-280.69 162.05-626.51 162.05-907.2 0-280.69-162.06-453.6-461.55-453.6-785.66s172.91-623.6 453.6-785.66c280.69-162.05 626.51-162.05 907.2 0 280.69 162.06 453.6 461.55 453.6 785.66" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtWabActionIcon1SVGComp;
    