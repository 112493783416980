import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const problemMetricUoMTypesAdapter = createEntityAdapter({})

const initialState = problemMetricUoMTypesAdapter.getInitialState()

export const problemMetricUoMTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProblemMetricUoMTypes: builder.query({
            query: () => ({
                url: '/problemMetricUoMTypes/getProblemMetricUoMTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedProblemMetricUoMTypes = responseData.map(problemMetricUoMType => {
                    problemMetricUoMType.id = problemMetricUoMType._id
                    return problemMetricUoMType
                })
                return problemMetricUoMTypesAdapter.setAll(initialState, loadedProblemMetricUoMTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'ProblemMetricUoMType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'ProblemMetricUoMType', id}))
                    ]
                } else {
                    return [ { type: 'ProblemMetricUoMType', id: 'LIST'}]
                }
            },
        }),
        createNewProblemMetricUoMType: builder.mutation({
            query: initialProblemMetricUoMType => ({
                url: '/problemMetricUoMTypes/createNewProblemMetricUoMType',
                method: 'POST',
                body: {...initialProblemMetricUoMType,}
            }),
            invalidatesTags: [
                { type: 'ProblemMetricUoMType', id: "LIST" }
            ] 
        }),
        updateProblemMetricUoMType: builder.mutation({
            query: initialProblemMetricUoMType => ({
                url: '/problemMetricUoMTypes/updateProblemMetricUoMType',
                method: 'PATCH',
                body: {...initialProblemMetricUoMType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'ProblemMetricUoMType', id: arg.id },
                { type: 'ProblemMetricUoMType', id: 'LIST' }
            ],
        }),
        deleteProblemMetricUoMType: builder.mutation({
            query: ({id}) => ({
                url: '/problemMetricUoMTypes/deleteProblemMetricUoMType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'ProblemMetricUoMType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetProblemMetricUoMTypesQuery,
    useCreateNewProblemMetricUoMTypeMutation,
    useUpdateProblemMetricUoMTypeMutation,
    useDeleteProblemMetricUoMTypeMutation,
} = problemMetricUoMTypesApiSlice

// returns the query result object
export const selectProblemMetricUoMTypeResult = problemMetricUoMTypesApiSlice.endpoints.getProblemMetricUoMTypes.select()

// create memoized selector
const selectProblemMetricUoMTypesData = createSelector(
    selectProblemMetricUoMTypeResult,
    problemMetricUoMTypeResult => problemMetricUoMTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllProblemMetricUoMTypes,
    selectById: selectProblemMetricUoMTypeById,
    selectIds: selectProblemMetricUoMTypeIds,
} = problemMetricUoMTypesAdapter.getSelectors(state => selectProblemMetricUoMTypesData(state) ?? initialState)