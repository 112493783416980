import React, { useState } from 'react'
import useTitle from '@/hooks/useTitle'
import { useNavigate } from 'react-router'
import { addIcon } from '@/assets/img/imgAssets'
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon,  } from '@heroicons/react/24/outline'
import { UserIcon, PhoneArrowUpRightIcon, AtSymbolIcon } from '@heroicons/react/24/solid'
import PulseLoader from "react-spinners/PulseLoader"
import { useGetContactsQuery } from './contactsApiSlice'

const EtContactList = ({ setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }) => {
  // First Created - 29 Sep 2024
    // Author - Atul Pandey
    // Inherited from organization types
    // Updated - added org role and title
    // Updated - 07 Mar 2025 - updated the comp with props - { setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }
    // Updated - 07 Mar 2025 - switched from navigate to onAdd, onUpdate, onDelete, setSelectedEntityId

    // 0 - Set Page Title
    useTitle('Contacts List')

    // 1 - set page navigations
    const navigate = useNavigate()

    // 2 - Initialize showDetails state as an object where key is the contact index
    const [showContactTypeDetails, setShowContactTypeDetails] = useState({})
    const [showContactOrgDetails, setShowContactOrgDetails] = useState({})
    const [activeTab, setActiveTab] = useState('view1') // For Tabbed view, default to 'view1'

    // 3 - Extract the contacts data
    const { data: contactsData, isError, error, isLoading } = useGetContactsQuery()
    // console.log('contactsData', contactsData)

    // 4a - Toggle Show function for contact contactTypedetails
    const handleToggleContactTypeDetails = (contactId) => {
        setShowContactTypeDetails(prev => ({ ...prev, [contactId]: !prev[contactId] }));
    }

    // 4b - Toggle Show function for contact contactOrg details
    const handleToggleContactOrgDetails = (contactId) => {
        setShowContactOrgDetails(prev => ({ ...prev, [contactId]: !prev[contactId] }));
    }

    // 5 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
        return { __html: htmlContent }
    }

    // 6a - Refined contacts data
    const contacts =  contactsData?.ids.map(id => {
        const contact = contactsData?.entities[id];
        const { 
            _id,
            contactName,
            contactNickName,
            contactEmail, 
            contactTypeId,
            contactOrgId, 
            contactPhone,
            contactOrgRole,  // 30 Sep 2024
            contactOrgTitle, // 30 Sep 2024
            } = contact;
        
            return {
            _id,
            contactName,
            contactNickName,
            contactEmail, 
            contactType: contactTypeId?.contactTypeName,
            contactOrg: contactOrgId?.contactOrganizationName,
            contactRole: contactOrgRole?.orgRoleName || "None",            // 30 Sep 2024
            contactTitle: contactOrgTitle?.orgTitleName || "None",         // 30 Sep 2024
            contactPhone, 
            }
    });

    console.log('contacts', contacts)

    // 6b - New grouping by contactType and contactOrg
    const groupedContacts = contacts?.reduce((acc, contact) => {
      const { contactType, contactOrg } = contact;

      if (!acc[contactType]) {
        acc[contactType] = {};
      }

      if (!acc[contactType][contactOrg]) {
        acc[contactType][contactOrg] = [];
      }

      acc[contactType][contactOrg].push(contact);

      return acc;
      }, {});

    // console.log('groupedContacts', groupedContacts);

    // Ensure groupedContacts is defined and valid before processing
    const groupedContactsArray = groupedContacts && Object.entries(groupedContacts).length > 0
      ? Object.entries(groupedContacts).map(([contactType, orgs]) => ({
          contactType,
          organizations: Object.entries(orgs).map(([contactOrg, contacts]) => ({
            contactOrg,
            contacts
          }))
        }))
      : [];

    console.log('groupedContactsArray', groupedContactsArray);

    // 7 - Optional: handle loading and error states
    if (isLoading) return <PulseLoader color={"#ea580c"} />
    if (isError) return <div>Error occurred: {error?.data?.message}</div>

    return (
        <div className='bg-gray-200 m-1 p-1 rounded font-poppins mx-auto ml-1 mr-1'>
            {/* Header */}
            <div className='flex justify-start items-center m-1 p-1'>
                <div><p className='text-2xl m-1 p-1 font-bold'>Et Contacts</p></div>
                <div>
                    <img
                        src={addIcon}
                        alt=""
                        className="h-10 w-10 cursor-pointer"
                        // onClick={() => navigate("/contacts/create")}
                        onClick={onAdd} // 07 Mar 2025
                    />
                </div>
            </div>

            {/* JSX for tabs */}
            <div className='flex justify-start items-center m-1 p-1 bg-gray-50 rounded'>
                <div>
                    <button
                        className={`m-1 p-2 text-sm font-bold rounded-t-md ${activeTab === 'view1' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                        onClick={() => setActiveTab('view1')}
                    >
                        View All Contacts
                    </button>
                    <button
                        className={`m-1 p-2 text-sm font-bold rounded-t-md ${activeTab === 'view2' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                        onClick={() => setActiveTab('view2')}
                    >
                        View by Type & Org
                    </button>
                </div>
            </div>

            {/* View 1 - All contact details */}
            {
                activeTab === 'view1' && (
                    <div className='bg-white m-1 p-1 rounded'>
                        {/* Render View 1 - Show all contacts */}
                        {/* <EtContactCardAll contactList={contacts} /> */}
                         <EtContactCardAll contactList={contacts} onUpdate={onUpdate} onDelete={onDelete}/> {/* 07 Mar 2025*/}
                    </div>
                )
            }

            {/* View 2 - Contact Details by contact type and org */}
            {activeTab === 'view2' && (
              <div className='bg-white m-1 p-1 rounded'>
                {groupedContactsArray && groupedContactsArray.length === 0 && (
                  <div className='bg-gray-50 m-1 p-1 rounded'>
                    <p className='text-orange-600 mx-1 px-1'>There are no contacts created yet. Click the + button to create one!</p>
                  </div>
                )}
                {groupedContactsArray && groupedContactsArray.length >0 &&  groupedContactsArray?.map((contactType, contactTypeIndex) => {
                  return(
                    <div key={contactType+contactTypeIndex} className='bg-gray-50 m-1 p-1 rounded'>
                      <div className='flex justify-start items-center m-1 p-1'>
                        <div
                          onClick={() => handleToggleContactTypeDetails(contactType.contactType)}
                          className="mx-2 rounded-full h-7 w-7 hover:bg-white flex justify-center items-center cursor-pointer"
                        >
                          {showContactTypeDetails[contactType.contactType] ? (
                            <MinusIcon className="h-4 w-4 text-orange-600" />
                          ) : (
                            <PlusIcon className="h-5 w-5 text-orange-600" />
                          )}
                        </div>
                        <div><p className='font-bold text-2xl text-gray-600 mx-1 px-1'>{contactTypeIndex+1}.</p></div>
                        <div><p className='font-bold mx-1 px-1'>{contactType.contactType}</p></div>

                      </div>

                      {showContactTypeDetails[contactType.contactType] && (
                        <div>
                          {/* Show the organization details here */}
                          {contactType.organizations.length>0 && contactType.organizations?.map((org, orgIndex)=> {
                            return(
                              <div key={org.contactOrg+orgIndex} className='bg-gray-100 m-1 p-1 rounded'>
                                <div className='flex justify-start items-center m-1 p-1'>
                                  <div
                                    onClick={() => handleToggleContactOrgDetails(org.contactOrg)}
                                    className="mx-2 rounded-full h-5 w-5 hover:bg-white flex justify-center items-center cursor-pointer"
                                  >
                                    {showContactOrgDetails[org.contactOrg] ? (
                                      <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                      <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                  </div>
                                  <div><p className='font-bold text-xl text-gray-400 mx-1 px-1'>{orgIndex+1}.</p></div>
                                  <div><p className='font-bold text-sm mx-1 px-1 text-gray-600'>{org.contactOrg}</p></div>
                                </div>

                                {showContactOrgDetails[org.contactOrg] && (
                                  <div>
                                    {/* Show the contact details here! */}
                                    {/* <EtContactCardBasic contactList={org.contacts}/> */}
                                     <EtContactCardBasic contactList={org.contacts} onUpdate={onUpdate} onDelete={onDelete}/> {/* 07 Mar 2025 */}
                                  </div>
                                )}
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            )}
        </div>
    )
}

const EtContactCardBasic = ({contactList, onUpdate, onDelete}) => {

  // Updated - 07 Mar 2025 - added onUpdate, onDelete props

  // 1 - set page navigations
  const navigate = useNavigate()

  return(
    <div>
      {contactList.length>0 && contactList?.map((contact, contactIndex) =>(
        <div key={contact._id + contactIndex} className='grid grid-cols-12 justify-start items-center bg-white m-1 p-1 rounded'>
        <div className='col-span-4 flex justify-start items-center m-1 p-1'>
            <div>
                <p className='font-bold text-gray-400 mx-1 px-1'>{contactIndex + 1}.</p>
            </div>
            <div>
                <UserIcon className='text-orange-600 h-6 w-6' />
            </div>
            <div>
              <p className='text-xs text-gray-800 mx-1 px-1 font-bold'>{contact.contactName}</p>
              {contact.contactRole !== 'None' && contact.contactTitle !== 'None' && (
              <p className='text-[10px] text-gray-400 mx-1 px-1 italic'>{contact.contactTitle} & {contact.contactRole}</p>)}
              <p className='text-[10px] text-gray-800 mx-1 px-1'>{contact.contactOrg}</p>
            </div>
        </div>
        <div className='col-span-1 flex justify-start items-center'>
          <div><p className='text-xs text-gray-400'>{contact.contactType}</p></div>
        </div>
        <div className='col-span-3 flex justify-start items-center m-1 p-1'>
            <div><p className='text-xs text-gray-400 mx-1 px-1'>Nickname :</p></div>
            <div><p className='text-xs text-gray-600 mx-1 px-1'>{contact.contactNickName}</p></div>
        </div>
        <div className='col-span-3  m-1 p-1'>
            <div className='flex justify-start items-center'>
              <div>
                <AtSymbolIcon className='text-orange-600 h-4 w-4' />
              </div>
              <div><p className='text-[10px] text-gray-600 mx-1 px-1'>{contact.contactEmail}</p></div>  
            </div>
            <div className=' py-1 flex justify-start items-center'>
            <div>
                <PhoneArrowUpRightIcon className='text-orange-600 h-4 w-4' />
            </div>
            <div><p className='text-[10px] text-gray-600 mx-1 px-1'>{contact.contactPhone}</p></div>
            </div> 
        </div>
        <div className='col-span-1 flex justify-end items-center m-1 p-1'>
            <div 
            // onClick={() => navigate(`/contacts/update/${contact._id}`)} 
            onClick={() => onUpdate(contact?._id)} // 07 Mar 2025 
            
            className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'><PencilSquareIcon className='text-orange-600 h-3 w-3' /></div>
            <div 
            // onClick={() => navigate(`/contacts/delete/${contact._id}`)} 
            onClick={() => onDelete(contact?._id)} // 07 Mar 2025 
            
            className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'><TrashIcon className='text-orange-600 h-3 w-3' /></div>
        
        </div>
        
    </div>
      ))}
      
    </div>
  )

}

const EtContactCardAll = ({ contactList, onUpdate, onDelete }) => {

  // 1 - set page navigations
  const navigate = useNavigate()


  return (
      <div className='m-1 p-1 bg-gray-50 rounded'>
          {contactList.length > 0 && contactList?.map((contact, contactIndex) => (
              <div key={contact._id + contactIndex} className='grid grid-cols-12 justify-start items-center bg-white m-1 p-1 rounded'>
                  <div className='col-span-4 flex justify-start items-center m-1 p-1'>
                      <div>
                          <p className='font-bold text-gray-400 mx-1 px-1'>{contactIndex + 1}.</p>
                      </div>
                      <div>
                          <UserIcon className='text-orange-600 h-6 w-6' />
                      </div>
                      <div>
                        <p className='text-xs text-gray-800 mx-1 px-1 font-bold'>{contact.contactName}</p>
                        {contact.contactRole !== 'None' && contact.contactTitle !== 'None' && (
                        <p className='text-[10px] text-gray-400 mx-1 px-1 italic'>{contact.contactTitle} & {contact.contactRole}</p>)}
                        <p className='text-[10px] text-gray-800 mx-1 px-1'>{contact.contactOrg}</p>
                      </div>
                  </div>
                  <div className='col-span-1 flex justify-start items-center'>
                    <div><p className='text-xs text-gray-400'>{contact.contactType}</p></div>
                  </div>
                  <div className='col-span-3 flex justify-start items-center m-1 p-1'>
                      <div><p className='text-xs text-gray-400 mx-1 px-1'>Nickname :</p></div>
                      <div><p className='text-xs text-gray-600 mx-1 px-1'>{contact.contactNickName}</p></div>
                  </div>
                  <div className='col-span-3  m-1 p-1'>
                      <div className='flex justify-start items-center'>
                        <div>
                          <AtSymbolIcon className='text-orange-600 h-4 w-4' />
                        </div>
                        <div><p className='text-[10px] text-gray-600 mx-1 px-1'>{contact.contactEmail}</p></div>  
                      </div>
                      <div className=' py-1 flex justify-start items-center'>
                      <div>
                          <PhoneArrowUpRightIcon className='text-orange-600 h-4 w-4' />
                      </div>
                      <div><p className='text-[10px] text-gray-600 mx-1 px-1'>{contact.contactPhone}</p></div>
                      </div> 
                  </div>
                  <div className='col-span-1 flex justify-end items-center m-1 p-1'>
                      <div 
                      // onClick={() => navigate(`/contacts/update/${contact._id}`)} 
                      onClick={() => onUpdate(contact?._id)} // 07 Mar 2025 
                      
                      className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'><PencilSquareIcon className='text-orange-600 h-3 w-3' /></div>
                      <div 
                      // onClick={() => navigate(`/contacts/delete/${contact._id}`)} 
                      onClick={() => onDelete(contact?._id)} // 07 Mar 2025 
                      
                      className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'><TrashIcon className='text-orange-600 h-3 w-3' /></div>
                  
                  </div>
                  
              </div>
          ))}
      </div>
  );
}


export default EtContactList