import React, {useState} from 'react'
import { useNavigate } from 'react-router'
import useTitle from '@/hooks/useTitle'
import { useGetSubsPacksQuery } from './subscriptionPacksApiSlice'
import { addIcon, editIcon, deleteIcon, viewIcon } from '@/assets/img/imgAssets'

const EtSubsPackList = ({setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }) => {

    // First Created - 04 Apr 2024
    // Author - Atul Pandey
    // Updated - 01 Mar 2025 - updated the softSkillFunctionArea with props - { setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }
    // Updated - 01 Mar 2025 - switched from navigate to onAdd, onUpdate, onDelete, setSelectedEntityId


  // 0 - Set Title
  useTitle('Et Subscription Packs')
  
  // 1 - Set Navigation
  const navigate = useNavigate()

  // 2 - Set Show Details and Subscription Pack
  const [showDetails, setShowDetails] = useState(false)
  const [subscriptionPackName, setSubscriptionPackName ] = useState('')
  
  // 3 - Use the RTK Query hook to get Subscription Packs
  const {data: subscriptionPacks, error, isLoading } = useGetSubsPacksQuery()

  // 3a - Optional: handle loading and error states
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error occurred: {error.error && error.error.message ? error.error.message : 'Unknown error'}</div>;

  const displaySubscriptionPackFeatures = (subscriptionPack) => {
    setShowDetails(true)
    setSubscriptionPackName(subscriptionPack)
  }

  // Create an object for setting inner HTML
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
  }

  return (
    <div className="bg-gray-200 p-1 mx-auto my-5 rounded-lg border border-white shadow-none shadow-black">
        <div className='flex justify-start items-center p-1 m-1'>
            <div><p className="p-1 m-2 font-bold font-poppins text-xl">Subscription Packages</p></div>
            <div>
              <img 
                src={addIcon} 
                alt="" 
                className='h-10 w-10 cursor-pointer' 
                // onClick={() => navigate('/subscriptionPacks/create') } 
                onClick={onAdd} // 01 Mar 2025
              />
            </div>
        </div>
        
        
        <div className='flex justify-center border-t border-white m-1 p-1 pt-4'>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {subscriptionPacks && subscriptionPacks.ids.map((id) => {
                    const {subscriptionPackName, subscriptionPackDesc, pic} = subscriptionPacks.entities[id]
                return (
                <div key={id} className="bg-white focus:bg-orange-400 rounded-lg overflow-hidden shadow-md shadow-slate-600">
                    <div className='flex justify-start items-center p-1 my-1 border-b'>
                        {/* <div className='flex justify-center items-center p-1 rounded-full h-6 w-6 hover:bg-gray-100 cursor-pointer'><img src={viewIcon} alt="" className='h-6 w-6' onClick={() => displaySubscriptionPackFeatures(subscriptionPackName)}/></div> */}
                        <div className='flex justify-center items-center p-1 rounded-full h-6 w-6 hover:bg-gray-100 cursor-pointer'>
                          <img 
                            src={editIcon} 
                            alt="" className='h-6 w-6' 
                            // onClick={() => navigate(`/subscriptionPacks/update/${id}`)}
                            onClick={() => onUpdate(id)} // 01 Mar 2025 
                          />
                        </div>
                        <div className='flex justify-center items-center p-1 rounded-full h-6 w-6 hover:bg-gray-100 cursor-pointer'>
                          <img 
                            src={deleteIcon} 
                            alt="" className='h-6 w-6' 
                            // onClick={() => navigate(`/subscriptionPacks/delete/${id}`)} 
                            onClick={() => onDelete(id)} // 01 Mar 2025 
                          />
                        </div>
                        
                    </div>
                    <div className="flex flex-col items-center justify-center p-4">
                    <div className='rounded-full h-16 w-16 hover:bg-gray-50 p-2'><img src={pic} alt="SubscriptionPack" className="rounded mx-auto h-14 w-14"/></div>
                    <h2 className="mt-2 text-center font-bold text-xl">{subscriptionPackName}</h2>
                    </div>
                    <div className="border-t p-4">
                    <p className='text-xs text-gray-500 font-poppins' dangerouslySetInnerHTML={createMarkup(
                      ` ${subscriptionPackDesc}`
                    )}></p>
                    </div>
                </div>
                )

                })}
            </div>
      </div>
      {/* {showDetails && (
      <div className='m-5 border-t border-white'>
      <EtQuestionListBySet setname={qsetName}/>
      </div>
      )} */}
    
    </div>
  )
}

export default EtSubsPackList