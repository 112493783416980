import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userRecognitionsAcademicsAdapter = createEntityAdapter({})

const initialState = userRecognitionsAcademicsAdapter.getInitialState()

export const userRecognitionsAcademicsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserRecognitionsAcademics: builder.query({
            query: () => ({
                url: '/userRecognitionsAcademics/getUserRecognitionsAcademics',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserRecognitionsAcademics = responseData.map(userRecognitionsAcademic => {
                    userRecognitionsAcademic.id = userRecognitionsAcademic._id
                    return userRecognitionsAcademic
                })
                return userRecognitionsAcademicsAdapter.setAll(initialState, loadedUserRecognitionsAcademics)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserRecognitionsAcademic', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserRecognitionsAcademic', id}))
                    ]
                } else {
                    return [ { type: 'UserRecognitionsAcademic', id: 'LIST'}]
                }
            },
        }),
        createNewUserRecognitionsAcademic: builder.mutation({
            query: initialUserRecognitionsAcademic => ({
                url: '/userRecognitionsAcademics/createNewUserRecognitionsAcademic',
                method: 'POST',
                body: {...initialUserRecognitionsAcademic,}
            }),
            invalidatesTags: [
                { type: 'UserRecognitionsAcademic', id: "LIST" }
            ] 
        }),
        updateUserRecognitionsAcademic: builder.mutation({
            query: initialUserRecognitionsAcademic => ({
                url: '/userRecognitionsAcademics/updateUserRecognitionsAcademic',
                method: 'PATCH',
                body: {...initialUserRecognitionsAcademic,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserRecognitionsAcademic', id: arg.id },
                { type: 'UserRecognitionsAcademic', id: 'LIST' }
            ],
        }),
        deleteUserRecognitionsAcademic: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userRecognitionsAcademics/deleteUserRecognitionsAcademic',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userRecognitionsAcademics/deleteUserRecognitionsAcademic',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserRecognitionsAcademic', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserRecognitionsAcademicsQuery,
    useCreateNewUserRecognitionsAcademicMutation,
    useUpdateUserRecognitionsAcademicMutation,
    useDeleteUserRecognitionsAcademicMutation,
} = userRecognitionsAcademicsApiSlice

// returns the query result object
export const selectUserRecognitionsAcademicResult = userRecognitionsAcademicsApiSlice.endpoints.getUserRecognitionsAcademics.select()

// create memoized selector
const selectUserRecognitionsAcademicsData = createSelector(
    selectUserRecognitionsAcademicResult,
    userRecognitionsAcademicResult => userRecognitionsAcademicResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserRecognitionsAcademics,
    selectById: selectUserRecognitionsAcademicById,
    selectIds: selectUserRecognitionsAcademicIds,
} = userRecognitionsAcademicsAdapter.getSelectors(state => selectUserRecognitionsAcademicsData(state) ?? initialState)