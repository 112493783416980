import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userMissionPartnersAdapter = createEntityAdapter({})

const initialState = userMissionPartnersAdapter.getInitialState()

export const userMissionPartnersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserMissionPartners: builder.query({
            query: () => ({
                url: '/userMissionPartners/getUserMissionPartners',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserMissionPartners = responseData.map(userMissionPartner => {
                    userMissionPartner.id = userMissionPartner._id
                    return userMissionPartner
                })
                return userMissionPartnersAdapter.setAll(initialState, loadedUserMissionPartners)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserMissionPartner', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserMissionPartner', id}))
                    ]
                } else {
                    return [ { type: 'UserMissionPartner', id: 'LIST'}]
                }
            },
        }),
        createNewUserMissionPartner: builder.mutation({
            query: initialUserMissionPartner => ({
                url: '/userMissionPartners/createNewUserMissionPartner',
                method: 'POST',
                body: {...initialUserMissionPartner,}
            }),
            invalidatesTags: [
                { type: 'UserMissionPartner', id: "LIST" }
            ] 
        }),
        updateUserMissionPartner: builder.mutation({
            query: initialUserMissionPartner => ({
                url: '/userMissionPartners/updateUserMissionPartner',
                method: 'PATCH',
                body: {...initialUserMissionPartner,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserMissionPartner', id: arg.id },
                { type: 'UserMissionPartner', id: 'LIST' }
            ],
        }),
        deleteUserMissionPartner: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userMissionPartners/deleteUserMissionPartner',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userMissionPartners/deleteUserMissionPartner',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserMissionPartner', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserMissionPartnersQuery,
    useCreateNewUserMissionPartnerMutation,
    useUpdateUserMissionPartnerMutation,
    useDeleteUserMissionPartnerMutation,
} = userMissionPartnersApiSlice

// returns the query result object
export const selectUserMissionPartnerResult = userMissionPartnersApiSlice.endpoints.getUserMissionPartners.select()

// create memoized selector
const selectUserMissionPartnersData = createSelector(
    selectUserMissionPartnerResult,
    userMissionPartnerResult => userMissionPartnerResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserMissionPartners,
    selectById: selectUserMissionPartnerById,
    selectIds: selectUserMissionPartnerIds,
} = userMissionPartnersAdapter.getSelectors(state => selectUserMissionPartnersData(state) ?? initialState)