import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import Modal from "@/components/common/modal/Modal"
import { useGetCreationAreaTypesQuery, useDeleteCreationAreaTypeMutation } from "./creationAreaTypesApiSlice"

const EtDeleteCreationAreaType = ({id, setActiveAdminView}) => {
  // Created - 09 Dec 2024
  // Author - Atul Pandey
  // Updated - 27 Feb 2025 - updated the function with props - { id, setActiveAdminView}
  // Updated - 27 Feb 2025 - switched from navigate to setActiveAdminView

  // 1 - Set Title
  useTitle("Update CreationArea Type")

  // 2 - extract the creationAreaType Id from the url
  // const { id } = useParams()
  // //console.log("id:", id)

  // 3 - Extract the creationAreaType from the query to load the creationAreaType data
  const { creationAreaType } = useGetCreationAreaTypesQuery("CreationAreaType", {
    selectFromResult: ({ data }) => ({
      creationAreaType: data?.entities[id],
    }),
  })

  //console.log('creationArea type:', creationAreaType)

  // 4 - Check for returned data
  if (!creationAreaType) {
    return <PulseLoader color={"#ea580c"} />
  }

  // 5. Display data
  let content
  content = <EtDeleteCreationAreaTypeForm creationAreaType={creationAreaType} id={id} setActiveAdminView={setActiveAdminView}/> // 26 Feb 2025 - added setActiveAdminView={setActiveAdminView}
  return content
}

const EtDeleteCreationAreaTypeForm = ({creationAreaType, id,setActiveAdminView }) => {

  // 1 - set the variable for navigate
  const navigate = useNavigate()

 // 1a - Delete section modal 
 const [isModalOpen, setIsModalOpen] = useState(false)


  // 2 - set the mutation to create the new section data
  const[deleteCreationAreaType, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useDeleteCreationAreaTypeMutation()

// 3 - set the form data object
const [formData, setFormData] = useState({
  creationAreaTypeName: creationAreaType.creationAreaTypeName,
})

//console.log('formData for update:', formData)

// 4 - Reset form upon successful submission
useEffect(() => {
  if(isSuccess) {
    setFormData({
      creationAreaTypeName: "",
    })

    // navigate('/creationAreatypes')
    setActiveAdminView('creationAreaTypes'); // 26 Feb 2025
  }

},[isSuccess]) // ✅ 26 Feb 2025 - Removed `navigate` since it's no longer used.

// 5 - Form field updates / change handling

const handleChange = (e) => {
  const { name, value, files } = e.target
  if (name === "pic") {
    setFormData(prevState => ({ ...prevState, pic: files[0] }))
  } else {
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }
}

// Modal management functions
const handleOpenModal = () => setIsModalOpen(true)
const handleCloseModal = () => setIsModalOpen(false)

// 7 - Form Submit Logic
const handleConfirm = async (e) => {
  e.preventDefault()
  // handleCloseModal()

  // Initialize an object to collect the form data
  const creationAreaTypeData = {
    creationAreaTypeName: formData.creationAreaTypeName,
  }
    // No image, send other data as is
    try {
      creationAreaTypeData.id = id
      console.log("Form submitted.")
      await deleteCreationAreaType(creationAreaTypeData).unwrap() // Dispatch the RTK Query mutation
      setActiveAdminView("creationAreaTypes");
    } catch (error) {
      console.error(error)
    } finally {
      handleCloseModal(); // ✅ Ensure modal closes even if there's an error.
    }
}

// 8 - Handle Cancel

const handleCancel = () => {
  // navigate("/creationAreatypes")
  setActiveAdminView('creationAreaTypes'); // 26 Feb 2025
}


return (
  <div className="bg-gray-200 p-1 w-3/4 mx-auto mt-5 rounded-lg shadow-md shadow-black">
    {isLoading && <PulseLoader color={"#ea580c"} />}
    {isError && (
      <p className="text-orange-700 font-semi-bold py-2">
        {error?.data?.message}
      </p>
    )}
    <div className="bg-white p-1 m-1 rounded">
      <p className="p-1 m-1 font-bold font-poppins text-xl">Update a CreationArea Type</p>

      <div className="bg-gray-200 rounded m-1 p-1">
        <form
          onSubmit={handleOpenModal}
          className="m-1 p-1 text-xs font-poppins"
        >
          
          <div id="creationAreaTypeName">
            <input
              type="text"
              name="creationAreaTypeName"
              placeholder="Enter the creationArea type name here"
              value={formData.creationAreaTypeName}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="creationAreas" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic
              name={"Delete"}
              type={"create"}
              handleClick={handleOpenModal}
            />
            <EtBtnFormBasic
              name={"Cancel"}
              type={"cancel"}
              handleClick={handleCancel}
            />
          </div>
        </form>
      </div>
    </div>
    <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleConfirm} />
  </div>
)

}

export default EtDeleteCreationAreaType