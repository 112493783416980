import React, { useState, useEffect } from "react"
import { useNavigate, useParams  } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLightWithPlace from "@/utils/EtContentEditorLightWithPlace"
import EtContentEditorLight3 from "@/utils/EtContentEditorLight3"
import { PlusIcon, MinusIcon} from '@heroicons/react/24/outline'
import { useGetOrganizationsQuery, useUpdateOrganizationMutation } from "./organizationsApiSlice"
import { useGetOrganizationTypesQuery } from "../organizationTypes/organizationTypesApiSlice"
import { useGetUsersQuery } from "../users/usersApiSlice" // 06 Sep 2024

const EtUpdateOrganization = ({id, setActiveAdminView}) => {
  // First Created - 09 May 2024
  // Author - Atul Pandey
  // Updated - 06 Sep 2024 - added user org role and titles
  // Updated - 01 Mar 2025 - updated the comp with props - { id, setActiveAdminView}
  // Updated - 01 Mar 2025 - switched from navigate to setActiveAdminView

  // 1 - Set Page Title
  useTitle('Update A Organization')

  // 2 - Extract the Id to be updated
  // const { id } = useParams()
  //console.log("id:", id)

  // 3 - Extract the organization to be updated
  const { organization } = useGetOrganizationsQuery("Organization", {
    selectFromResult: ({ data }) => ({
      organization: data?.entities[id],
    }),
  })
  //console.log('organization', organization)
  //console.log('organization trailId id', organization?.trailId._id)
  //console.log('organization trailId name', organization?.trailId.trailName)

  // 4 - Check for returned data
  if (!organization) {
    return <PulseLoader color={"#ea580c"} />
  }

  // 5. Display data
  let content
  content = <EtUpdateOrganizationForm organization={organization} id={id} setActiveAdminView={setActiveAdminView}/> // 01 Mar 2025 - added setActiveAdminView={setActiveAdminView} 

  return content

}

const EtUpdateOrganizationForm = ({organization, id, setActiveAdminView}) => {
  // First Created - 09 May 2024
  // Author - Atul Pandey
  // Updated - 06 Sep 2024 - added user org role and titles

  // 1 - set the variable for navigate
  const navigate = useNavigate()

  // 2 - Define the mutation to update the organization
  const [updateOrganization, { isLoading, isSuccess, isError, error }] = useUpdateOrganizationMutation()

  // 3a - Fill the form data with extracted organization info
  const [formData, setFormData] = useState({
    organizationTypeId: organization.organizationTypeId._id,
    organizationName: organization.organizationName,
    organizationDesc: organization.organizationDesc,
    organizationPurpose: organization.organizationPurpose || "",
    organizationIntro: organization.organizationIntro || "",
    organizationOutro: organization.organizationOutro || "",
    organizationTargetAudience: organization.organizationTargetAudience || [{ audience: "" }],
    organizationLocations: organization.organizationLocations || [{ location: "" }],
    organizationTeamSize: organization.organizationTeamSize || "",
    organizationRevenueSize: organization.organizationRevenueSize || "",
    organizationExistenceDuration: organization.organizationExistenceDuration || "", 
    organizationStartedDate: organization.organizationStartedDate || "",
    organizationRecognitions: organization.organizationRecognitions || [{ recognition: "" }],
    organizationContributions: organization.organizationContributions || [{ contribution: "" }],
    aboutMe: organization.aboutMe || "",
    organizationSponsors: organization.organizationSponsors || [{ sponsorId: "" }], // 06 Sep 2024 - Added for sponsors
  })
    //console.log('Loaded formData for organization', formData)
   // console.log('formData.organizationTypeId', formData.organizationTypeId)

   // 3b - Filter users who belong to the same org and have 'OrgSponsor' role - 06 Sep 2024
  const { data: usersData } = useGetUsersQuery("User");
  const eligibleSponsors = usersData?.ids
    .map(id => usersData.entities[id])
    .filter(user => 
      user.userOrgId?._id === organization._id && 
      user.roles.includes('OrgSponsor')
    ) || [];
  console.log('Eligible Sponsors:', eligibleSponsors)


    // 4 - Get organization types data for options
    const { organizationTypes } = useGetOrganizationTypesQuery('organizationTypesList', {
      selectFromResult: ( { data }) => ({
        organizationTypes: data?.ids.map(id => data?.entities[id])
      })
    })

  //console.log('organization types:', organizationTypes)

  
// 5 - Create options to select a organization type from list of organization types
const organizationTypeOptions = organizationTypes ? [
    <option key="placeholder" value="" disabled>
        Select an Organization Type
    </option>,
    ...organizationTypes?.map(organizationType => (
        <option 
        key={organizationType.id} 
        value={organizationType.id}
        >
            {organizationType.organizationTypeName}
        </option>
    ))
] : []

   // 6 - Reset form upon successful submission
  useEffect(() => {
    if(isSuccess) {
      setFormData({
        organizationTypeId: "", 
        organizationName: "",
        organizationDesc: "",
        organizationPurpose: "", 
        organizationIntro: "", 
        organizationOutro: "",
        organizationTargetAudience: [{ audience: "" }],
        organizationLocations: [{ location: "" }],
        organizationTeamSize: "",
        organizationRevenueSize: "",
        organizationExistenceDuration: "", 
        organizationStartedDate: "",
        organizationRecognitions: [{ recognition: "" }],
        organizationContributions: [{ contribution: "" }],
        aboutMe: "", 
        pic: "", 
        organizationSponsors: [{ sponsorId: "" }], // 06 Sep 2024 - Added for sponsors
      })
  
     // navigate('/organizations')
     setActiveAdminView('organizations'); // 01 Mar 2025
    }
    
    },[isSuccess])// ✅ 01 Mar 2025 - Removed `navigate` since it's no longer used.

    // 7 - Form field updates / change handling
  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData(prevState => ({ ...prevState, pic: files[0] }))
    }  else {
      setFormData(prevState => ({ ...prevState, [name]: value }))
    }
  }

  // 7a - Generic Handle Add Field Function
  const handleAdd = (fieldName, newItem) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: [...prevState[fieldName], newItem]
    }))
  }

  // 7b - Generic Handle Remove Field Function
  const handleRemove = (fieldName, index) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: prevState[fieldName].filter((_, i) => i !== index)
    }))
  }

  // 7c - Generic Handle Field Change Function
  const handleFieldChange = (fieldName, index, value) => {
    const updatedItems = formData[fieldName].map((item, i) => i === index ? value : item)
    setFormData({ ...formData, [fieldName]: updatedItems })
  }

  // 7d - Wrapper functions for specific fields
  const handleAddAudience = () => handleAdd('organizationTargetAudience', { audience: "" })
  const handleRemoveAudience = (index) => handleRemove('organizationTargetAudience', index)
  const handleAudienceChange = (index, value) => handleFieldChange('organizationTargetAudience', index, { audience: value })

  const handleAddLocations = () => handleAdd('organizationLocations', { location: "" })
  const handleRemoveLocations = (index) => handleRemove('organizationLocations', index)
  const handleLocationsChange = (index, value) => handleFieldChange('organizationLocations', index, { location: value })
 
  const handleAddRecognitions = () => handleAdd('organizationRecognitions', { recognition: "" })
  const handleRemoveRecognitions = (index) => handleRemove('organizationRecognitions', index)
  const handleRecognitionsChange = (index, value) => handleFieldChange('organizationRecognitions', index, { recognition: value })

  // 06 Sep 2024 - Add, Remove, Update a sponsor
  const handleAddSponsor = () => handleAdd('organizationSponsors', { sponsorId: "" });
  const handleRemoveSponsor = (index) => handleRemove('organizationSponsors', index);
  const handleSponsorChange = (index, value) => handleFieldChange('organizationSponsors', index, { sponsorId: value });


  // 8 - Form Submit Logic
const handleSubmit = async (e) => {
  e.preventDefault()

  // Initialize an object to collect the form data
  const organizationData = {
    trailId: formData.trailId,
    organizationTypeId: formData.organizationTypeId,
    organizationName: formData.organizationName,
    organizationDesc: formData.organizationDesc,
    organizationIntro: formData.organizationIntro,
    organizationOutro: formData.organizationOutro,
    organizationTargetAudience: formData.organizationTargetAudience,
    organizationLocations: formData.organizationLocations,
    organizationRecognitions: formData.organizationRecognitions,
    organizationStartedDate: formData.organizationStartedDate,
    aboutMe: formData.aboutMe,
    organizationSponsors: formData.organizationSponsors, // 06 Sep 2024 - Added for sponsors
  }

  //console.log('organization Name:',organizationData.organizationName)
  //console.log('organization Planned Date Slot:',organizationData.organizationStartedDate)

  if (formData.pic) {
    // Convert the image to a base64 string
    const reader = new FileReader()
    reader.onloadend = async () => {
      // Once conversion is done, add the base64 string to your data object
      organizationData.pic = reader.result

      // Now, send the data object to the server
      try {
        organizationData.id = id
        console.log("Form submitted.")
        await updateOrganization(organizationData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    }
    reader.readAsDataURL(formData.pic)
  } else {
    // No image, send other data as is
    try {
      organizationData.id = id
      console.log("Form submitted.")
      await updateOrganization(organizationData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
  }
}

  // 9 - Handle Cancel
  const handleCancel = () => {
     // navigate('/organizations')
     setActiveAdminView('organizations'); // 01 Mar 2025
  }

// 10 - Handle Clear
const handleClear = () => {
  setFormData({
      organizationTypeId: "", 
      organizationName: "",
      organizationDesc: "",
      organizationPurpose: "", 
      organizationIntro: "", 
      organizationOutro: "",
      organizationTargetAudience: [{ audience: "" }],
      organizationLocations: [{ location: "" }],
      organizationTeamSize: "",
      organizationRevenueSize: "",
      organizationExistenceDuration: "", 
      organizationStartedDate: "",
      organizationRecognitions: [{ recognition: "" }],
      organizationContributions: [{ contribution: "" }],
      aboutMe: "", 
      pic: "", 
      organizationSponsors: [{ sponsorId: "" }], // 06 Sep 2024 - Added for sponsors
  })
}


return (
  <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
    {isLoading && <PulseLoader color={"#ea580c"} />}
    {isError && (
      <p className="text-orange-700 font-semi-bold py-2">
        {error?.data?.message}
      </p>
    )}
    <div className="bg-white p-1 m-1 rounded">
      <p className="p-1 m-1 font-bold font-poppins text-xl">Update an Organization</p>

      <div className="bg-gray-200 rounded m-1 p-1">
        <form
          onSubmit={handleSubmit}
          className="m-1 p-1 text-xs font-poppins"
        >
          <div id="organizationTypeSelector">
            <select
              name="organizationTypeId"
              id="organizationTypeId"
              value={formData.organizationTypeId}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            >
              {organizationTypeOptions}
            </select>
          </div>

          <div id="organizationName">
            <input
              type="text"
              name="organizationName"
              placeholder="Enter the organization name here"
              value={formData.organizationName}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="organizationDesc">
            <input
              type="text"
              name="organizationDesc"
              placeholder="Enter the organization description here"
              value={formData.organizationDesc}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="organizationStartedDate">
            <input
              type="text"
              name="organizationStartedDate"
              placeholder="Enter the date when the organization started"
              value={formData.organizationStartedDate}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div className="bg-white w-full rounded p-2 m-1">
            <EtContentEditorLightWithPlace
              value={formData.organizationIntro}
              handleChange={(content) =>
                setFormData({ ...formData, organizationIntro: content })
              }
              placeholderText={
                "Write what people would see when this organization starts!"
              }
              // toolbarId={'organizationIntro'}
            />
          </div>

          <div className="bg-white w-full rounded p-2 m-1">
            <EtContentEditorLight3
              value={formData.organizationOutro}
              handleChange={(content) =>
                setFormData({ ...formData, organizationOutro: content })
              }
              placeholderText={
                "Write what people would see when this organization ends!"
              }
              toolbarId={"organizationOurtro"}
            />
          </div>

          <div id="aboutMe">
            <input
              type="text"
              name="aboutMe"
              placeholder="Add something about the personality of this organization"
              value={formData.aboutMe}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="pic">
            <input
              type="file"
              name="pic"
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          {/* Organization Sponsors - Added - 06 Sep 2024*/}
          <div id="sponsors">
            <div className="m-1 p-1 border border-white rounded">
              <div>
                <p className="font-bold mx-2 px-2 mt-2">Organization Sponsors</p>
                <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">
                  Add sponsors for this organization
                </p>
              </div>

              {formData.organizationSponsors.map((item, index) => (
                <div key={index} className="flex justify-start items-center mx-1">
                  <select
                    value={item.sponsorId}
                    onChange={(e) => handleSponsorChange(index, e.target.value)}
                    className="w-full rounded p-2 m-1 text-xs"
                  >
                    <option value="">Select a sponsor</option>
                    {eligibleSponsors.map(sponsor => (
                      <option key={sponsor._id} value={sponsor._id}>
                        {sponsor.name} ({sponsor.userOrgRole?.orgRoleName} & {sponsor.userOrgTitle?.orgTitleName})
                      </option>
                    ))}
                  </select>
                  <div className="rounded bg-red-500 m-1 p-1">
                    <button type="button" onClick={() => handleRemoveSponsor(index)}>
                      <MinusIcon className="h-5 w-5 text-white" />
                    </button>
                  </div>
                </div>
              ))}
              <div className="flex justify-start items-center m-1">
                <p className="font-bold text-xs m-1 p-1">Add Sponsor</p>
                <button type="button" onClick={handleAddSponsor}>
                  <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                </button>
              </div>
            </div>
          </div>


          {/* Target Audience List */}
          <div id="target-audience">

              <div className="m-1 p-1 border border-white rounded">
                  <div>
                    <p className="font-bold mx-2 px-2 mt-2">Target Audience</p>
                    <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">Who this organization is for?</p>
                  </div>

                    {formData.organizationTargetAudience.map((item, index) => (
                      <div key={index} className="flex justify-start items-center mx-1">
                        <input
                          type="text"
                          value={item.audience}
                          onChange={(e) => handleAudienceChange(index, e.target.value)}
                          placeholder="Enter a target audience"
                          className="w-full rounded p-2 m-1 text-xs"
                        />
                        <div className="rounded bg-red-500 m-1 p-1">
                          <button type="button" onClick={() => handleRemoveAudience(index)}>
                            <MinusIcon className="h-5 w-5 text-white" />
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="flex justify-start items-center m-1">

                        <p className="font-bold text-xs m-1 p-1">Add</p>
                        <button type="button" onClick={handleAddAudience}>
                          <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                        </button>
                    
                    </div>
              </div>
         
          </div>

          {/* Organization Locations */}
          <div id="pre-work">

              <div className="m-1 p-1 border border-white rounded">
                  <div>
                    <p className="font-bold mx-2 px-2 mt-2">Organization Locations</p>
                    <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">What are the locations for this organization?</p>
                  </div>

                    {formData.organizationLocations.map((item, index) => (
                      <div key={index} className="flex justify-start items-center mx-1">
                        <input
                          type="text"
                          value={item.location}
                          onChange={(e) => handleLocationsChange(index, e.target.value)}
                          placeholder="Enter a pre work for the organization"
                          className="w-full rounded p-2 m-1 text-xs"
                        />
                        <div className="rounded bg-red-500 m-1 p-1">
                          <button type="button" onClick={() => handleRemoveLocations(index)}>
                            <MinusIcon className="h-5 w-5 text-white" />
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="flex justify-start items-center m-1">

                        <p className="font-bold text-xs m-1 p-1">Add</p>
                        <button type="button" onClick={handleAddLocations}>
                          <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                        </button>
                    
                    </div>
              </div>
         
          </div>
          
          {/* Organization Recognitions */}
          <div id="recognitions">

              <div className="m-1 p-1 border border-white rounded">
                  <div>
                    <p className="font-bold mx-2 px-2 mt-2">Organization Recognitions</p>
                    <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">What one can expect to achieve out of the organization?</p>
                  </div>

                    {formData.organizationRecognitions.map((item, index) => (
                      <div key={index} className="flex justify-start items-center mx-1">
                        <input
                          type="text"
                          value={item.recognition}
                          onChange={(e) => handleRecognitionsChange(index, e.target.value)}
                          placeholder="Enter an recognition for the organization"
                          className="w-full rounded p-2 m-1 text-xs"
                        />
                        <div className="rounded bg-red-500 m-1 p-1">
                          <button type="button" onClick={() => handleRemoveRecognitions(index)}>
                            <MinusIcon className="h-5 w-5 text-white" />
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="flex justify-start items-center m-1">

                        <p className="font-bold text-xs m-1 p-1">Add</p>
                        <button type="button" onClick={handleAddRecognitions}>
                          <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                        </button>
                    
                    </div>
              </div>
         
          </div>

          <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic
              name={"Update"}
              type={"create"}
              handleClick={handleSubmit}
            />
            <EtBtnFormBasic
              name={"Clear"}
              type={"clear"}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={"Cancel"}
              type={"cancel"}
              handleClick={handleCancel}
            />
          </div>
        </form>
      </div>
    </div>
  </div>
)

}

export default EtUpdateOrganization