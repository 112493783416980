
    import React from 'react';
    
    const EtVarianceIconSVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m2808 1368h-936c-51.445 0-98.984 27.445-124.71 72-25.723 44.555-25.723 99.445 0 144 25.723 44.555 73.262 72 124.71 72h936c51.445 0 98.984-27.445 124.71-72 25.723-44.555 25.723-99.445 0-144-25.723-44.555-73.262-72-124.71-72z" fillRule="evenodd"/>
 <path d="m2808 756h-684c-51.445 0-98.984 27.445-124.71 72-25.723 44.555-25.723 99.445 0 144 25.723 44.555 73.262 72 124.71 72h684c51.445 0 98.984-27.445 124.71-72 25.723-44.555 25.723-99.445 0-144-25.723-44.555-73.262-72-124.71-72z" fillRule="evenodd"/>
 <path d="m792 2268h900c51.445 0 98.984-27.445 124.71-72 25.723-44.555 25.723-99.445 0-144-25.723-44.555-73.262-72-124.71-72h-900c-51.445 0-98.984 27.445-124.71 72-25.723 44.555-25.723 99.445 0 144 25.723 44.555 73.262 72 124.71 72z" fillRule="evenodd"/>
 <path d="m792 2880h900c51.445 0 98.984-27.445 124.71-72 25.723-44.555 25.723-99.445 0-144-25.723-44.555-73.262-72-124.71-72h-900c-51.445 0-98.984 27.445-124.71 72-25.723 44.555-25.723 99.445 0 144 25.723 44.555 73.262 72 124.71 72z" fillRule="evenodd"/>
 <path d="m2929.3 2045.5c-23.652-36.523-62.555-60.395-105.83-64.938-43.277-4.543-86.289 10.723-117.01 41.539l-186.48 186.48-186.48-186.48c-30.188-30.238-72.242-45.508-114.8-41.684-42.551 3.8242-81.211 26.348-105.52 61.484-19 29.348-27.137 64.418-22.992 99.137 4.1406 34.715 20.301 66.887 45.672 90.941l180 180-183.6 183.6h0.003906c-24.031 23.379-39.293 54.312-43.223 87.609-3.9336 33.297 3.6992 66.934 21.621 95.27 23.652 36.523 62.555 60.395 105.83 64.938 43.277 4.543 86.289-10.723 117.01-41.539l186.48-186.48 186.48 186.48c30.188 30.238 72.242 45.508 114.8 41.684 42.551-3.8242 81.211-26.348 105.52-61.484 19.047-29.305 27.242-64.355 23.164-99.07-4.0781-34.715-20.168-66.91-45.484-91.008l-180-180 183.6-183.6h-0.003906c23.977-23.426 39.168-54.379 43.035-87.676 3.8672-33.293-3.8281-66.906-21.793-95.203z" fillRule="evenodd"/>
 <path d="m690.12 1343.9 190.44 190.44h-0.003906c43.332 43.242 102.05 67.527 163.26 67.527 61.215 0 119.93-24.285 163.26-67.527l442.8-442.44c36.398-36.398 50.613-89.449 37.293-139.17-13.324-49.723-52.16-88.559-101.88-101.88-49.719-13.32-102.77 0.89453-139.17 37.293l-402.12 402.12-150.12-150.12v0.003906c-36.398-36.398-89.449-50.613-139.17-37.293-49.723 13.324-88.559 52.16-101.88 101.88-13.32 49.719 0.89453 102.77 37.293 139.17z" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtVarianceIconSVGComp;
    