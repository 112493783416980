import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const sponsorUserOrgTrailSessionActionsAdapter = createEntityAdapter({})

const initialState = sponsorUserOrgTrailSessionActionsAdapter.getInitialState()

export const sponsorUserOrgTrailSessionActionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSponsorUserOrgTrailSessionActions: builder.query({
            query: () => ({
                url: '/sponsorUserOrgTrailSessionActions/getSponsorUserOrgTrailSessionActions',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedSponsorUserOrgTrailSessionActions = responseData.map(sponsorUserOrgTrailSessionAction => {
                    sponsorUserOrgTrailSessionAction.id = sponsorUserOrgTrailSessionAction._id
                    return sponsorUserOrgTrailSessionAction
                })
                return sponsorUserOrgTrailSessionActionsAdapter.setAll(initialState, loadedSponsorUserOrgTrailSessionActions)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'SponsorUserOrgTrailSessionAction', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'SponsorUserOrgTrailSessionAction', id}))
                    ]
                } else {
                    return [ { type: 'SponsorUserOrgTrailSessionAction', id: 'LIST'}]
                }
            },
        }),
        createNewSponsorUserOrgTrailSessionAction: builder.mutation({
            query: initialSponsorUserOrgTrailSessionAction => ({
                url: '/sponsorUserOrgTrailSessionActions/createNewSponsorUserOrgTrailSessionAction',
                method: 'POST',
                body: {...initialSponsorUserOrgTrailSessionAction,}
            }),
            invalidatesTags: [
                { type: 'SponsorUserOrgTrailSessionAction', id: "LIST" }
            ] 
        }),
        updateSponsorUserOrgTrailSessionAction: builder.mutation({
            query: initialSponsorUserOrgTrailSessionAction => ({
                url: '/sponsorUserOrgTrailSessionActions/updateSponsorUserOrgTrailSessionAction',
                method: 'PATCH',
                body: {...initialSponsorUserOrgTrailSessionAction,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'SponsorUserOrgTrailSessionAction', id: arg.id },
                { type: 'SponsorUserOrgTrailSessionAction', id: 'LIST' }
            ],
        }),
        deleteSponsorUserOrgTrailSessionAction: builder.mutation({
            query: ({id}) => ({
                url: '/sponsorUserOrgTrailSessionActions/deleteSponsorUserOrgTrailSessionAction',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'SponsorUserOrgTrailSessionAction', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetSponsorUserOrgTrailSessionActionsQuery,
    useCreateNewSponsorUserOrgTrailSessionActionMutation,
    useUpdateSponsorUserOrgTrailSessionActionMutation,
    useDeleteSponsorUserOrgTrailSessionActionMutation,
} = sponsorUserOrgTrailSessionActionsApiSlice

// returns the query result object
export const selectSponsorUserOrgTrailSessionActionResult = sponsorUserOrgTrailSessionActionsApiSlice.endpoints.getSponsorUserOrgTrailSessionActions.select()

// create memoized selector
const selectSponsorUserOrgTrailSessionActionsData = createSelector(
    selectSponsorUserOrgTrailSessionActionResult,
    sponsorUserOrgTrailSessionActionResult => sponsorUserOrgTrailSessionActionResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllSponsorUserOrgTrailSessionActions,
    selectById: selectSponsorUserOrgTrailSessionActionById,
    selectIds: selectSponsorUserOrgTrailSessionActionIds,
} = sponsorUserOrgTrailSessionActionsAdapter.getSelectors(state => selectSponsorUserOrgTrailSessionActionsData(state) ?? initialState)