import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../../app/api/apiSlice'

const userImprintOverviewAIsAdapter = createEntityAdapter({})

const initialState = userImprintOverviewAIsAdapter.getInitialState()

export const userImprintOverviewAIsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserImprintOverviewAIs: builder.query({
            query: () => ({
                url: '/userImprintOverviewAIs/getUserImprintOverviewAIs',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserImprintOverviewAIs = responseData.map(userImprintOverviewAI => {
                    userImprintOverviewAI.id = userImprintOverviewAI._id
                    return userImprintOverviewAI
                })
                return userImprintOverviewAIsAdapter.setAll(initialState, loadedUserImprintOverviewAIs)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserImprintOverviewAI', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserImprintOverviewAI', id}))
                    ]
                } else {
                    return [ { type: 'UserImprintOverviewAI', id: 'LIST'}]
                }
            },
        }),
        createNewUserImprintOverviewAI: builder.mutation({
            query: initialUserImprintOverviewAI => ({
                url: '/userImprintOverviewAIs/createNewUserImprintOverviewAI',
                method: 'POST',
                body: {...initialUserImprintOverviewAI,}
            }),
            invalidatesTags: [
                { type: 'UserImprintOverviewAI', id: "LIST" }
            ] 
        }),
        updateUserImprintOverviewAI: builder.mutation({
            query: initialUserImprintOverviewAI => ({
                url: '/userImprintOverviewAIs/updateUserImprintOverviewAI',
                method: 'PATCH',
                body: {...initialUserImprintOverviewAI,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserImprintOverviewAI', id: arg.id },
                { type: 'UserImprintOverviewAI', id: 'LIST' }
            ],
        }),
        deleteUserImprintOverviewAI: builder.mutation({
            query: ({id}) => ({
                url: '/userImprintOverviewAIs/deleteUserImprintOverviewAI',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserImprintOverviewAI', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserImprintOverviewAIsQuery,
    useCreateNewUserImprintOverviewAIMutation,
    useUpdateUserImprintOverviewAIMutation,
    useDeleteUserImprintOverviewAIMutation,
} = userImprintOverviewAIsApiSlice

// returns the query result object
export const selectUserImprintOverviewAIResult = userImprintOverviewAIsApiSlice.endpoints.getUserImprintOverviewAIs.select()

// create memoized selector
const selectUserImprintOverviewAIsData = createSelector(
    selectUserImprintOverviewAIResult,
    userImprintOverviewAIResult => userImprintOverviewAIResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserImprintOverviewAIs,
    selectById: selectUserImprintOverviewAIById,
    selectIds: selectUserImprintOverviewAIIds,
} = userImprintOverviewAIsAdapter.getSelectors(state => selectUserImprintOverviewAIsData(state) ?? initialState)