import React, { useState, useEffect } from 'react'
import useTitle from "../../../hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
    PencilSquareIcon,
  } from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "../../../utils/EtContentEditorLight2"
import EtBtnFormBasic from "../../../components/common/buttons/EtBtnFormBasic"
import { useNavigate } from "react-router"
import { useCreateNewUserImprintShowcaseMutation } from './userImprintShowcasesApiSlice'
import { useGetCapabilitiesIndustryTypesQuery } from '@/features/capabilitiesIndustryTypes/capabilitiesIndustryTypesApiSlice'
import { useGetCapabilitiesDomainTypesQuery } from '@/features/capabilitiesDomainTypes/capabilitiesDomainTypesApiSlice'
import { useGetCapabilitiesFunctionTypesQuery } from '@/features/capabilitiesFunctionTypes/capabilitiesFunctionTypesApiSlice'
import { useGetCapabilitiesTechAreaTypesQuery } from '@/features/capabilitiesTechAreaTypes/capabilitiesTechAreaTypesApiSlice'
import { useGetCapabilitiesTechStackTypesQuery } from '@/features/capabilitiesTechStackTypes/capabilitiesTechStackTypesApiSlice'
import { useGetCapabilitiesSalesTypesQuery } from '@/features/capabilitiesSalesTypes/capabilitiesSalesTypesApiSlice'
import { useGetCapabilitiesServiceTypesQuery } from '@/features/capabilitiesServiceTypes/capabilitiesServiceTypesApiSlice'
import { useGetCapabilitiesAdvisoryTypesQuery } from '@/features/capabilitiesAdvisoryTypes/capabilitiesAdvisoryTypesApiSlice'
import { useGetShowcaseTypesQuery } from '@/features/showcaseTypes/showcaseTypesApiSlice'

//04 Dec 2024
import { useGetProblemAreaTypesQuery } from '@/features/problemAreaTypes/problemAreaTypesApiSlice.js'
import { useGetProblemMetricTypesQuery } from '@/features/problemMetricTypes/problemMetricTypesApiSlice.js'
import { useGetProblemMetricGradationTypesQuery } from '@/features/problemMetricGradationTypes/problemMetricGradationTypesApiSlice.js'
import { useGetProblemMetricUoMTypesQuery } from '@/features/problemMetricUoMTypes/problemMetricUoMTypesApiSlice.js'

const EtCreateUserImprintShowcaseComp = ({
  userId, 
  userOrgId, 
  triggeringUrl, 
  onClose
}) => {

  // First Created - 03 Dec 2024
  // Author - Atul Pandey
  // Inherited from the EtCreateUserImprintRole component
  // Updated - 04 Dec 2024 - added problem area, metric, gradation, uom types

  // 0 - Set Page title & variables
  useTitle('User Imprint Showcasess')

  // 1 - set page navigations
  const navigate = useNavigate()
  
  // 2a - set the mutation to create the new user imprint Role data
  const [createNewUserImprintShowcase, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewUserImprintShowcaseMutation()

  // 2b - Get Data for building Showcase Areas
  const { data: industryTypesData} = useGetCapabilitiesIndustryTypesQuery()
  const { data: domainTypesData} = useGetCapabilitiesDomainTypesQuery()
  const { data: functionTypesData} = useGetCapabilitiesFunctionTypesQuery()
  const { data: techAreaTypesData} = useGetCapabilitiesTechAreaTypesQuery()
  const { data: techStackTypesData} = useGetCapabilitiesTechStackTypesQuery()
  const { data: salesTypesData} = useGetCapabilitiesSalesTypesQuery()
  const { data: serviceTypesData} = useGetCapabilitiesServiceTypesQuery()
  const { data: advisoryTypesData} = useGetCapabilitiesAdvisoryTypesQuery()
  const { data: showcaseTypesData} = useGetShowcaseTypesQuery()
  const { data: problemAreaTypesData} = useGetProblemAreaTypesQuery() // 04 Dec 2024
  const { data: problemMetricTypesData} = useGetProblemMetricTypesQuery() // 04 Dec 2024
  const { data: problemMetricGradationTypesData} = useGetProblemMetricGradationTypesQuery() // 04 Dec 2024
  const { data: problemMetricUoMTypesData} = useGetProblemMetricUoMTypesQuery() // 04 Dec 2024

  const extractEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id]);
  };

  const industryTypes = extractEntitiesFromAdapter(industryTypesData);
  const domainTypes = extractEntitiesFromAdapter(domainTypesData);
  const functionTypes = extractEntitiesFromAdapter(functionTypesData);
  const techAreaTypes = extractEntitiesFromAdapter(techAreaTypesData);
  const techStackTypes = extractEntitiesFromAdapter(techStackTypesData);
  const salesTypes = extractEntitiesFromAdapter(salesTypesData);
  const serviceTypes = extractEntitiesFromAdapter(serviceTypesData);
  const advisoryTypes = extractEntitiesFromAdapter(advisoryTypesData);
  const showCaseTypes = extractEntitiesFromAdapter(showcaseTypesData)
  const problemAreaTypes = extractEntitiesFromAdapter(problemAreaTypesData) // 04 Dec 2024
  const problemMetricTypes = extractEntitiesFromAdapter(problemMetricTypesData) // 04 Dec 2024
  const problemMetricGradationTypes = extractEntitiesFromAdapter(problemMetricGradationTypesData) // 04 Dec 2024
  const problemMetricUoMTypes = extractEntitiesFromAdapter(problemMetricUoMTypesData) // 04 Dec 2024

  // 3 - Initialize Form Data State
  const [formData, setFormData] = useState({
    userImprintShowcaseHeadlineName: "",
    userImprintShowcaseHeadlineDesc: "",
    userImprintShowcases: [], // Array for showcases
    userImprintShowcaseSelfNotes: [], // Array for self-notes
    aboutMe: "",
    pic: "",
    active: true, // Default active state
  });

  // 4 - State variables for the editor
  const [editorKey, setEditorKey] = useState('showcaseDesc-' + Date.now());
  const [editorValue, setEditorValue] = useState('');
  const [collapsedSections, setCollapsedSections] = useState({}); // 29 Nov 2024
 
  // 5a - Convert Image to Base64
  const convertPicToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  // 5b - Handle Form Changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "pic") {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  // 5c - Toggle show / collapse each section 
  const toggleSection = (key) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };  

  // 6 - Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Step 1: Convert the `pic` field to Base64 if it exists
      const picBase64 = formData.pic ? await convertPicToBase64(formData.pic) : undefined;

      // Step 2: Construct the payload
      const payload = {
        userId,
        userOrgId,
        userImprintShowcaseHeadlineName: formData.userImprintShowcaseHeadlineName.trim() || undefined,
        userImprintShowcaseHeadlineDesc: formData.userImprintShowcaseHeadlineDesc.trim() || undefined,
        userImprintShowcases: formData.userImprintShowcases, 
        userImprintShowcaseSelfNotes: formData.userImprintShowcaseSelfNotes, 
        aboutMe: formData.aboutMe.trim() || undefined,
        pic: picBase64,
        active: formData.active,
      };

      // Step 3: Submit the payload
      await createNewUserImprintShowcase(payload).unwrap();

      // Step 4: Reset the form on success
      setFormData({
        userImprintShowcaseHeadlineName: "",
        userImprintShowcaseHeadlineDesc: "",
        userImprintShowcases: [],
        userImprintShowcaseSelfNotes: [],
        aboutMe: "",
        pic: "",
        active: true,
      });

      // Step 5: Redirect or close the form
      if (onClose) {
        onClose(); // Close the modal or component
      } else {
        navigate(triggeringUrl || "/userMagicHome");
      }
    } catch (error) {
      // Step 6: Handle Errors
      console.error("Error submitting showcase form:", error);
    }
  };

  // 7 - Reset Form on Successful Submission
  useEffect(() => {
    if (isSuccess) {
      setFormData({
        userImprintShowcaseHeadlineName: "",
        userImprintShowcaseHeadlineDesc: "",
        userImprintShowcases: [],
        userImprintShowcaseSelfNotes: [],
        aboutMe: "",
        pic: "",
        active: true,
      });

      if (onClose) {
        onClose();
      } else {
        navigate(triggeringUrl || "/userMagicHome");
      }
    }
  }, [isSuccess, navigate]);

  // 8 - Handle Clear
  const handleClear = () => {
    setFormData({
      userImprintShowcaseHeadlineName: "",
      userImprintShowcaseHeadlineDesc: "",
      userImprintShowcases: [],
      userImprintShowcaseSelfNotes: [],
      aboutMe: "",
      pic: "",
      active: true,
    });
  };

  // 9 - Handle Cancel
  const handleCancel = () => {
    if (onClose) {
      onClose();
    } else {
      navigate(triggeringUrl || "/userMagicHome");
    }
  };

  // 10 - create Markup
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
    }

return (
  <div className="mx-auto w-full m-1 p-1 bg-white rounded shadow-md shadow-black">
    {isLoading && <PulseLoader color={'#ea580c'} />}
    {isError && (
      <p className="text-orange-700 font-semi-bold py-2">{error?.data?.message}</p>
    )}

    <p className="p-1 m-1 font-bold font-poppins text-xl">
      Create User Imprint Showcase
    </p>

    <form onSubmit={handleSubmit} className="m-1 p-1 text-xs font-poppins">
      {/* 1. Showcase Headline Name */}
      <div id="headlineName" className="bg-gray-100 rounded p-1 m-1">
        <label
          htmlFor="userImprintShowcaseHeadlineName"
          className="block text-gray-600 text-sm font-bold mb-1"
        >
          Showcase Headline Name (Optional)
        </label>
        <input
          type="text"
          name="userImprintShowcaseHeadlineName"
          placeholder="Enter a headline or title"
          value={formData.userImprintShowcaseHeadlineName}
          onChange={handleChange}
          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
        />
      </div>

      {/* 2. Showcase Headline Description */}
      <div id="headlineDesc" className="bg-gray-100 rounded p-1 m-1">
        <div className='flex justify-start items-center m-1 p-1'>
            <button
              type="button"
              className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
              onClick={() => toggleSection(`headlineDesc`)} // Toggle function
            >
              {collapsedSections[`headlineDesc`] ? (
                <PlusIcon className="h-4 w-4 text-orange-600" />
              ) : (
                <MinusIcon className="h-4 w-4 text-orange-600" />
              )}
            </button>
            <label
              htmlFor="userImprintShowcaseHeadlineDesc"
              className="block text-gray-600 text-sm font-bold mb-1"
            >
              Showcase Headline Description
            </label>
            {collapsedSections[`headlineDesc`] && (
                    <div dangerouslySetInnerHTML={createMarkup(formData?.userImprintShowcaseHeadlineDesc) || ""} className='text-[10px] truncate line-clamp-1 mx-1 px-1 max-w-sm'/> )}
        </div>
        {!collapsedSections[`headlineDesc`] && (
        <div className='m-1 p-1'>
            <EtContentEditorLight2
              value={editorValue}
              handleChange={(content) => {
                setEditorValue(content);
                setFormData((prevState) => ({
                  ...prevState,
                  userImprintShowcaseHeadlineDesc: content,
                }));
              }}
              placeholderText="Write your showcase description here!"
              editorKey={editorKey}
            />
          </div>)}
      </div>

      {/* 3. Showcases Section */}
      <div id="showcases" className="border rounded p-2 m-2 bg-gray-50">
        <div className="flex justify-between items-center mb-2">
          <div className='flex justify-start items-center m-1 p-1'>
            <button
              type="button"
              className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
              onClick={() => toggleSection(`showCases`)} // Toggle function
            >
              {collapsedSections[`showCases`] ? (
                <PlusIcon className="h-4 w-4 text-orange-600" />
              ) : (
                <MinusIcon className="h-4 w-4 text-orange-600" />
              )}
            </button>
            <p className="font-bold text-sm">Showcases</p>   
          </div>
               
          <div className="flex justify-start items-center">
            <p className="font-bold text-xs mx-1 px-1">Add</p>
            <button
              type="button"
              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
              onClick={() => {
                setFormData((prevState) => ({
                  ...prevState,
                  userImprintShowcases: [
                    ...(prevState.userImprintShowcases || []),
                    {
                      userShowcaseTypeId: null,
                      userShowcaseName: "",
                      userShowcaseDesc: "",
                      userShowcaseTargetType: null,
                      userShowcaseTargetName: "",
                      userShowcaseTargetDesc: "",
                      userShowcaseStartDate: "",
                      userShowcaseEndDate: "",
                      userShowcaseIndustry: null,
                      userShowcaseDomain: null,
                      userShowcaseFunction: null,
                      userShowcaseTechArea: null,
                      userShowcaseTechStack: null,
                      userShowcaseAdvisory: null,
                      userShowcaseSales: null,
                      userShowcaseService: null,
                      userShowcaseProblems: [],
                      userShowcaseHeadwinds: [],
                      userShowcaseApproachLevers: [],
                      userShowcaseActionsAndResults: [],
                    },
                  ],
                }));
              }}
            >
              <PlusIcon className="h-4 w-4 inline" />
            </button>
          </div>
        </div>
        
        {/* Render Each Showcase */}
        {!collapsedSections[`showCases`] && formData.userImprintShowcases?.map((showcase, showcaseIndex) => (
          <div key={showcaseIndex} className="border rounded p-2 m-2 bg-white">
            <div className="flex justify-between items-center mb-2">

              <div className='flex justify-start items-center m-1 p-1'>
                <button
                type="button"
                className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                onClick={() => toggleSection(`showcase-${showcaseIndex}`)} // Toggle function
              >
                {collapsedSections[`showcase-${showcaseIndex}`] ? (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                )}
              </button>
              <p className="font-bold text-sm mx-1 px-1">Showcase {showcaseIndex + 1}</p>

              </div>

             
              <div className="flex justify-start items-center">
                <p className="font-bold text-xs mx-1 px-1">Remove</p>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) => ({
                      ...prevState,
                      userImprintShowcases: prevState.userImprintShowcases.filter(
                        (_, i) => i !== showcaseIndex
                      ),
                    }));
                  }}
                >
                  <MinusIcon className="h-4 w-4 inline" />
                </button>
              </div>
            </div>

            {!collapsedSections[`showcase-${showcaseIndex}`] && (
              <div>
                
                {/* Showcase Type Dropdown */}
                <div id={`showcaseType-${showcaseIndex}`} className="bg-gray-100 rounded p-1 m-1">
                  <label
                    htmlFor={`userShowcaseTypeId-${showcaseIndex}`}
                    className="block text-gray-600 text-sm font-bold mb-1"
                  >
                    Showcase Type
                  </label>
                  <select
                    id={`userShowcaseTypeId-${showcaseIndex}`}
                    value={showcase.userShowcaseTypeId || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      setFormData((prevState) => {
                        const updatedShowcases = [...prevState.userImprintShowcases];
                        updatedShowcases[showcaseIndex].userShowcaseTypeId = value || null;
                        return { ...prevState, userImprintShowcases: updatedShowcases };
                      });
                    }}
                    className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                  >
                    <option value="" disabled>
                      Select Showcase Type
                    </option>
                    {showCaseTypes?.map((type) => (
                      <option key={type._id} value={type._id}>
                        {type.showcaseTypeName}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Showcase Name */}
                <div id={`showcaseName-${showcaseIndex}`} className="bg-gray-100 rounded p-1 m-1">
                  <label
                    htmlFor={`userShowcaseName-${showcaseIndex}`}
                    className="block text-gray-600 text-sm font-bold mb-1"
                  >
                    Showcase Name
                  </label>
                  <input
                    type="text"
                    id={`userShowcaseName-${showcaseIndex}`}
                    name="userShowcaseName"
                    placeholder="Enter showcase name"
                    value={showcase.userShowcaseName || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      setFormData((prevState) => {
                        const updatedShowcases = [...prevState.userImprintShowcases];
                        updatedShowcases[showcaseIndex].userShowcaseName = value;
                        return { ...prevState, userImprintShowcases: updatedShowcases };
                      });
                    }}
                    className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                  />
                </div>

                {/* Showcase Description */}
                <div id={`showcaseDesc-${showcaseIndex}`} className="bg-gray-100 rounded p-1 m-1">
                  <div className='flex justify-start items-center m-1 p-1'>
                    <button
                      type="button"
                      className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                      onClick={() => toggleSection(`showcaseDesc-${showcaseIndex}`)} // Toggle function
                    >
                      {collapsedSections[`showcaseDesc-${showcaseIndex}`] ? (
                        <PlusIcon className="h-4 w-4 text-orange-600" />
                      ) : (
                        <MinusIcon className="h-4 w-4 text-orange-600" />
                      )}
                    </button>
                    <label
                      htmlFor={`userShowcaseDesc-${showcaseIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Showcase Description
                    </label>
                    {collapsedSections[`showcaseDesc-${showcaseIndex}`] && (
                      <div
                        dangerouslySetInnerHTML={createMarkup(showcase.userShowcaseDesc) || ""}
                        className='text-[10px] truncate line-clamp-1 mx-1 px-1 max-w-sm'
                      />
                    )}
                  </div>
                  {!collapsedSections[`showcaseDesc-${showcaseIndex}`] && (
                    <div className='m-1 p-1'>
                      <EtContentEditorLight2
                        value={showcase.userShowcaseDesc || ""}
                        handleChange={(content) => {
                          setFormData((prevState) => {
                            const updatedShowcases = [...prevState.userImprintShowcases];
                            updatedShowcases[showcaseIndex].userShowcaseDesc = content;
                            return { ...prevState, userImprintShowcases: updatedShowcases };
                          });
                        }}
                        placeholderText="Write your showcase description here!"
                        editorKey={`showcaseDesc-${showcaseIndex}`}
                      />
                    </div>
                  )}
                </div>

                {/* Showcase Target Details */}
                <div id={`showcaseTarget-${showcaseIndex}`} className="bg-gray-100 rounded p-1 m-1">
                  <div className='flex justify-start items-center m-1 p-1'>
                    <button
                      type="button"
                      className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                      onClick={() => toggleSection(`showcaseTarget-${showcaseIndex}`)} // Toggle function
                    >
                      {collapsedSections[`showcaseTarget-${showcaseIndex}`] ? (
                        <PlusIcon className="h-4 w-4 text-orange-600" />
                      ) : (
                        <MinusIcon className="h-4 w-4 text-orange-600" />
                      )}
                    </button>
                    <label className="block text-gray-600 text-sm font-bold mb-1">
                      Showcase Target Details
                    </label>
                    {collapsedSections[`showcaseTarget-${showcaseIndex}`] && (
                      <div
                        className='text-[10px] truncate line-clamp-1 mx-1 px-1 max-w-sm'
                      >
                        {showcase.userShowcaseTargetName}
                      </div>
                    )}
                  </div>
                  {!collapsedSections[`showcaseTarget-${showcaseIndex}`] && (
                    <div className='m-1 p-1'>
                      {/* Target Type */}
                      <div className="bg-gray-100 rounded p-1 m-1">
                        <label
                          htmlFor={`userShowcaseTargetType-${showcaseIndex}`}
                          className="block text-gray-600 text-sm font-bold mb-1"
                        >
                          Target Type
                        </label>
                        <select
                          id={`userShowcaseTargetType-${showcaseIndex}`}
                          value={showcase.userShowcaseTargetType || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedShowcases = [...prevState.userImprintShowcases];
                              updatedShowcases[showcaseIndex].userShowcaseTargetType = value;
                              return { ...prevState, userImprintShowcases: updatedShowcases };
                            });
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                        >
                          <option value="" disabled>
                            Select Target Type
                          </option>
                          <option value="Clients">Clients</option>
                          <option value="Org">Org</option>
                          <option value="Org Team">Org Team</option>
                          <option value="Partners">Partners</option>
                          {/* Add more options as needed */}
                        </select>
                      </div>

                      {/* Target Name */}
                      <div className="bg-gray-100 rounded p-1 m-1">
                        <label
                          htmlFor={`userShowcaseTargetName-${showcaseIndex}`}
                          className="block text-gray-600 text-sm font-bold mb-1"
                        >
                          Target Name
                        </label>
                        <input
                          type="text"
                          id={`userShowcaseTargetName-${showcaseIndex}`}
                          placeholder="Enter target name"
                          value={showcase.userShowcaseTargetName || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedShowcases = [...prevState.userImprintShowcases];
                              updatedShowcases[showcaseIndex].userShowcaseTargetName = value;
                              return { ...prevState, userImprintShowcases: updatedShowcases };
                            });
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                        />
                      </div>

                      {/* Target Description */}
                      <div className="bg-gray-100 rounded p-1 m-1">
                        <label
                          htmlFor={`userShowcaseTargetDesc-${showcaseIndex}`}
                          className="block text-gray-600 text-sm font-bold mb-1"
                        >
                          Target Description
                        </label>
                        <EtContentEditorLight2
                          value={showcase.userShowcaseTargetDesc || ""}
                          handleChange={(content) => {
                            setFormData((prevState) => {
                              const updatedShowcases = [...prevState.userImprintShowcases];
                              updatedShowcases[showcaseIndex].userShowcaseTargetDesc = content;
                              return { ...prevState, userImprintShowcases: updatedShowcases };
                            });
                          }}
                          placeholderText="Describe the target..."
                          editorKey={`showcaseTargetDesc-${showcaseIndex}`}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/* Showcase Dates */}
                <div id={`showcaseDates-${showcaseIndex}`} className="bg-gray-100 rounded p-1 m-1">
                  <div className='flex justify-start items-center m-1 p-1'>
                    <button
                      type="button"
                      className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                      onClick={() => toggleSection(`showcaseDates-${showcaseIndex}`)} // Toggle function
                    >
                      {collapsedSections[`showcaseDates-${showcaseIndex}`] ? (
                        <PlusIcon className="h-4 w-4 text-orange-600" />
                      ) : (
                        <MinusIcon className="h-4 w-4 text-orange-600" />
                      )}
                    </button>
                    <label className="block text-gray-600 text-sm font-bold mb-1">
                      Showcase Dates
                    </label>
                  </div>
                  {!collapsedSections[`showcaseDates-${showcaseIndex}`] && (
                    <div className='m-1 p-1'>
                      {/* Start Date */}
                      <div className="bg-gray-100 rounded p-1 m-1">
                        <label
                          htmlFor={`userShowcaseStartDate-${showcaseIndex}`}
                          className="block text-gray-600 text-sm font-bold mb-1"
                        >
                          Start Date
                        </label>
                        <input
                          type="date"
                          id={`userShowcaseStartDate-${showcaseIndex}`}
                          value={showcase.userShowcaseStartDate || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedShowcases = [...prevState.userImprintShowcases];
                              updatedShowcases[showcaseIndex].userShowcaseStartDate = value;
                              return { ...prevState, userImprintShowcases: updatedShowcases };
                            });
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                        />
                      </div>

                      {/* End Date */}
                      <div className="bg-gray-100 rounded p-1 m-1">
                        <label
                          htmlFor={`userShowcaseEndDate-${showcaseIndex}`}
                          className="block text-gray-600 text-sm font-bold mb-1"
                        >
                          End Date
                        </label>
                        <input
                          type="date"
                          id={`userShowcaseEndDate-${showcaseIndex}`}
                          value={showcase.userShowcaseEndDate || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedShowcases = [...prevState.userImprintShowcases];
                              updatedShowcases[showcaseIndex].userShowcaseEndDate = value;
                              return { ...prevState, userImprintShowcases: updatedShowcases };
                            });
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/* Showcase Capabilities */}
                <div id={`showcaseCapabilities-${showcaseIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                  <div className="flex justify-between items-center mb-2">
                    <div className='flex justify-start items-center m-1 p-1'>
                      <button
                        type="button"
                        className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                        onClick={() => toggleSection(`showcaseCapabilities-${showcaseIndex}`)} // Toggle function
                      >
                        {collapsedSections[`showcaseCapabilities-${showcaseIndex}`] ? (
                          <PlusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                          <MinusIcon className="h-4 w-4 text-orange-600" />
                        )}
                      </button>
                      <p className="font-bold text-sm">Showcase Capabilities</p>
                    </div>
                  </div>
                  {!collapsedSections[`showcaseCapabilities-${showcaseIndex}`] && (
                    <div>
                      {/* Industry Type */}
                      <div className="bg-gray-100 rounded p-1 m-1">
                        <label
                          htmlFor={`userShowcaseIndustry-${showcaseIndex}`}
                          className="block text-gray-600 text-sm font-bold mb-1"
                        >
                          Industry
                        </label>
                        <select
                          id={`userShowcaseIndustry-${showcaseIndex}`}
                          value={showcase.userShowcaseIndustry || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedShowcases = [...prevState.userImprintShowcases];
                              updatedShowcases[showcaseIndex].userShowcaseIndustry = value;
                              return { ...prevState, userImprintShowcases: updatedShowcases };
                            });
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                        >
                          <option value="" disabled>
                            Select Industry
                          </option>
                          {industryTypes?.map((type) => (
                            <option key={type._id} value={type._id}>
                              {type.capabilitiesIndustryTypeName}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* Domain Type */}
                      <div className="bg-gray-100 rounded p-1 m-1">
                        <label
                          htmlFor={`userShowcaseDomain-${showcaseIndex}`}
                          className="block text-gray-600 text-sm font-bold mb-1"
                        >
                          Domain
                        </label>
                        <select
                          id={`userShowcaseDomain-${showcaseIndex}`}
                          value={showcase.userShowcaseDomain || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedShowcases = [...prevState.userImprintShowcases];
                              updatedShowcases[showcaseIndex].userShowcaseDomain = value;
                              return { ...prevState, userImprintShowcases: updatedShowcases };
                            });
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                        >
                          <option value="" disabled>
                            Select Domain
                          </option>
                          {domainTypes?.map((type) => (
                            <option key={type._id} value={type._id}>
                              {type.capabilitiesDomainTypeName}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* Function Type */}
                      <div className="bg-gray-100 rounded p-1 m-1">
                        <label
                          htmlFor={`userShowcaseFunction-${showcaseIndex}`}
                          className="block text-gray-600 text-sm font-bold mb-1"
                        >
                          Function
                        </label>
                        <select
                          id={`userShowcaseFunction-${showcaseIndex}`}
                          value={showcase.userShowcaseFunction || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedShowcases = [...prevState.userImprintShowcases];
                              updatedShowcases[showcaseIndex].userShowcaseFunction = value;
                              return { ...prevState, userImprintShowcases: updatedShowcases };
                            });
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                        >
                          <option value="" disabled>
                            Select Function
                          </option>
                          {functionTypes?.map((type) => (
                            <option key={type._id} value={type._id}>
                              {type.capabilitiesFunctionTypeName}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* TechArea Type */}
                      <div className="bg-gray-100 rounded p-1 m-1">
                        <label
                          htmlFor={`userShowcaseTechArea-${showcaseIndex}`}
                          className="block text-gray-600 text-sm font-bold mb-1"
                        >
                          TechArea
                        </label>
                        <select
                          id={`userShowcaseTechArea-${showcaseIndex}`}
                          value={showcase.userShowcaseTechArea || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedShowcases = [...prevState.userImprintShowcases];
                              updatedShowcases[showcaseIndex].userShowcaseTechArea = value;
                              return { ...prevState, userImprintShowcases: updatedShowcases };
                            });
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                        >
                          <option value="" disabled>
                            Select TechArea
                          </option>
                          {techAreaTypes?.map((type) => (
                            <option key={type._id} value={type._id}>
                              {type.capabilitiesTechAreaTypeName}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* TechStack Type */}
                      <div className="bg-gray-100 rounded p-1 m-1">
                        <label
                          htmlFor={`userShowcaseTechStack-${showcaseIndex}`}
                          className="block text-gray-600 text-sm font-bold mb-1"
                        >
                          TechStack
                        </label>
                        <select
                          id={`userShowcaseTechStack-${showcaseIndex}`}
                          value={showcase.userShowcaseTechStack || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedShowcases = [...prevState.userImprintShowcases];
                              updatedShowcases[showcaseIndex].userShowcaseTechStack = value;
                              return { ...prevState, userImprintShowcases: updatedShowcases };
                            });
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                        >
                          <option value="" disabled>
                            Select TechStack
                          </option>
                          {techStackTypes?.map((type) => (
                            <option key={type._id} value={type._id}>
                              {type.capabilitiesTechStackTypeName}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* Sales Type */}
                      <div className="bg-gray-100 rounded p-1 m-1">
                        <label
                          htmlFor={`userShowcaseSales-${showcaseIndex}`}
                          className="block text-gray-600 text-sm font-bold mb-1"
                        >
                          Sales
                        </label>
                        <select
                          id={`userShowcaseSales-${showcaseIndex}`}
                          value={showcase.userShowcaseSales || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedShowcases = [...prevState.userImprintShowcases];
                              updatedShowcases[showcaseIndex].userShowcaseSales = value;
                              return { ...prevState, userImprintShowcases: updatedShowcases };
                            });
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                        >
                          <option value="" disabled>
                            Select Sales
                          </option>
                          {salesTypes?.map((type) => (
                            <option key={type._id} value={type._id}>
                              {type.capabilitiesSalesTypeName}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* Service Type */}
                      <div className="bg-gray-100 rounded p-1 m-1">
                        <label
                          htmlFor={`userShowcaseService-${showcaseIndex}`}
                          className="block text-gray-600 text-sm font-bold mb-1"
                        >
                          Service
                        </label>
                        <select
                          id={`userShowcaseService-${showcaseIndex}`}
                          value={showcase.userShowcaseService || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedShowcases = [...prevState.userImprintShowcases];
                              updatedShowcases[showcaseIndex].userShowcaseService = value;
                              return { ...prevState, userImprintShowcases: updatedShowcases };
                            });
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                        >
                          <option value="" disabled>
                            Select Service
                          </option>
                          {serviceTypes?.map((type) => (
                            <option key={type._id} value={type._id}>
                              {type.capabilitiesServiceTypeName}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* Advisory Type */}
                      <div className="bg-gray-100 rounded p-1 m-1">
                        <label
                          htmlFor={`userShowcaseAdvisory-${showcaseIndex}`}
                          className="block text-gray-600 text-sm font-bold mb-1"
                        >
                          Advisory
                        </label>
                        <select
                          id={`userShowcaseAdvisory-${showcaseIndex}`}
                          value={showcase.userShowcaseAdvisory || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedShowcases = [...prevState.userImprintShowcases];
                              updatedShowcases[showcaseIndex].userShowcaseAdvisory = value;
                              return { ...prevState, userImprintShowcases: updatedShowcases };
                            });
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                        >
                          <option value="" disabled>
                            Select Advisory
                          </option>
                          {advisoryTypes?.map((type) => (
                            <option key={type._id} value={type._id}>
                              {type.capabilitiesAdvisoryTypeName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                </div>

                {/* Showcase Problems Section */}
                <div id={`showcaseProblems-${showcaseIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                  <div className="flex justify-between items-center mb-2">
                    <div className='flex justify-start items-center m-1 p-1'>
                      <button
                        type="button"
                        className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                        onClick={() => toggleSection(`showcaseProblems-${showcaseIndex}`)} // Toggle function
                      >
                        {collapsedSections[`showcaseProblems-${showcaseIndex}`] ? (
                          <PlusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                          <MinusIcon className="h-4 w-4 text-orange-600" />
                        )}
                      </button>
                      <p className="font-bold text-sm">Showcase Problems</p>
                    </div>
                    <div className="flex justify-start items-center">
                      <p className="text-xs font-bold mx-1 px-1">Add</p>
                      <button
                        type="button"
                        className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                        onClick={() => {
                          setFormData((prevState) => {
                            const updatedShowcases = [...prevState.userImprintShowcases];
                            return {
                              ...prevState,
                              userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                if (index === showcaseIndex) {
                                  return {
                                    ...showcase,
                                    userShowcaseProblems: [
                                      ...(showcase.userShowcaseProblems || []),
                                      {
                                        problemName: "The Problem is...",
                                        problemDesc: "",
                                        problemAreaType: null,
                                        problemMetricType: null,
                                        problemMetricUoMType: null,
                                        problemMetricGradationType: null,
                                        problemCurrentMeasureLevel: 0,
                                      },
                                    ],
                                  };
                                }
                                return showcase;
                              }),
                            };
                          });
                        }}
                      >
                        <PlusIcon className="h-4 w-4 inline" />
                      </button>
                    </div>
                  </div>

                  {/* Render Problems */}
                  {!collapsedSections[`showcaseProblems-${showcaseIndex}`] &&
                    showcase.userShowcaseProblems?.map((problem, problemIndex) => (
                      <div key={problemIndex} className="p-2 border rounded mb-2 bg-white">
                        <div className="flex justify-between items-center">
                          <div className='flex justify-start items-center m-1 p-1'>
                            <button
                              type="button"
                              className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                              onClick={() => toggleSection(`problem-${showcaseIndex}-${problemIndex}`)} // Toggle function
                            >
                              {collapsedSections[`problem-${showcaseIndex}-${problemIndex}`] ? (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                              ) : (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                              )}
                            </button>
                            <p className="font-bold text-sm mx-1 px-1">Problem {problemIndex + 1}</p>
                          </div>
                          <div className="flex justify-start items-center">
                            <p className="font-bold text-xs mx-1 px-1">Remove</p>
                            <button
                              type="button"
                              className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                              onClick={() => {
                                setFormData((prevState) => {
                                  const updatedShowcases = [...prevState.userImprintShowcases];
                                  return {
                                    ...prevState,
                                    userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                      if (index === showcaseIndex) {
                                        return {
                                          ...showcase,
                                          userShowcaseProblems: showcase.userShowcaseProblems.filter(
                                            (_, i) => i !== problemIndex
                                          ),
                                        };
                                      }
                                      return showcase;
                                    }),
                                  };
                                });
                              }}
                            >
                              <MinusIcon className="h-4 w-4 inline" />
                            </button>
                          </div>
                        </div>

                        {!collapsedSections[`problem-${showcaseIndex}-${problemIndex}`] && (
                          <div>
                            {/* Problem Name */}
                            <div id={`problemName-${showcaseIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                              <label
                                htmlFor={`problemName-${showcaseIndex}-${problemIndex}`}
                                className="block text-gray-600 text-sm font-bold mb-1"
                              >
                                Problem Name
                              </label>
                              <input
                                type="text"
                                id={`problemName-${showcaseIndex}-${problemIndex}`}
                                value={problem.problemName || ""}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormData((prevState) => {
                                    const updatedShowcases = [...prevState.userImprintShowcases];
                                    return {
                                      ...prevState,
                                      userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                        if (index === showcaseIndex) {
                                          return {
                                            ...showcase,
                                            userShowcaseProblems: showcase.userShowcaseProblems.map((p, i) => {
                                              if (i === problemIndex) {
                                                return { ...p, problemName: value };
                                              }
                                              return p;
                                            }),
                                          };
                                        }
                                        return showcase;
                                      }),
                                    };
                                  });
                                }}
                                placeholder="Enter the problem name"
                                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                              />
                            </div>

                            {/* Problem Description */}
                            <div id={`problemDesc-${showcaseIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                              <label
                                htmlFor={`problemDesc-${showcaseIndex}-${problemIndex}`}
                                className="block text-gray-600 text-sm font-bold mb-1"
                              >
                                Problem Description
                              </label>
                              <EtContentEditorLight2
                                value={problem.problemDesc || ""}
                                handleChange={(content) => {
                                  setFormData((prevState) => {
                                    const updatedShowcases = [...prevState.userImprintShowcases];
                                    return {
                                      ...prevState,
                                      userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                        if (index === showcaseIndex) {
                                          return {
                                            ...showcase,
                                            userShowcaseProblems: showcase.userShowcaseProblems.map((p, i) => {
                                              if (i === problemIndex) {
                                                return { ...p, problemDesc: content };
                                              }
                                              return p;
                                            }),
                                          };
                                        }
                                        return showcase;
                                      }),
                                    };
                                  });
                                }}
                                placeholderText="Describe the problem here..."
                                editorKey={`problemDesc-${showcaseIndex}-${problemIndex}`}
                              />
                            </div>

                            {/* Problem Area Type */}
                            <div id={`problemAreaType-${showcaseIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                              <label
                                htmlFor={`problemAreaType-${showcaseIndex}-${problemIndex}`}
                                className="block text-gray-600 text-sm font-bold mb-1"
                              >
                                Problem Area Type
                              </label>
                              <select
                                id={`problemAreaType-${showcaseIndex}-${problemIndex}`}
                                value={problem.problemAreaType || ""}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormData((prevState) => {
                                    const updatedShowcases = [...prevState.userImprintShowcases];
                                    return {
                                      ...prevState,
                                      userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                        if (index === showcaseIndex) {
                                          return {
                                            ...showcase,
                                            userShowcaseProblems: showcase.userShowcaseProblems.map((p, i) => {
                                              if (i === problemIndex) {
                                                return { ...p, problemAreaType: value || null };
                                              }
                                              return p;
                                            }),
                                          };
                                        }
                                        return showcase;
                                      }),
                                    };
                                  });
                                }}
                                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                              >
                                <option value="" disabled>
                                  Select Problem Area
                                </option>
                                {problemAreaTypes?.map((type) => (
                                  <option key={type._id} value={type._id}>
                                    {type.problemAreaTypeName}
                                  </option>
                                ))}
                              </select>
                            </div>

                            {/* Problem Metric Type */}
                            <div id={`problemMetricType-${showcaseIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                              <label
                                htmlFor={`problemMetricType-${showcaseIndex}-${problemIndex}`}
                                className="block text-gray-600 text-sm font-bold mb-1"
                              >
                                Problem Metric Type
                              </label>
                              <select
                                id={`problemMetricType-${showcaseIndex}-${problemIndex}`}
                                value={problem.problemMetricType || ""}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormData((prevState) => {
                                    const updatedShowcases = [...prevState.userImprintShowcases];
                                    return {
                                      ...prevState,
                                      userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                        if (index === showcaseIndex) {
                                          return {
                                            ...showcase,
                                            userShowcaseProblems: showcase.userShowcaseProblems.map((p, i) => {
                                              if (i === problemIndex) {
                                                return { ...p, problemMetricType: value || null };
                                              }
                                              return p;
                                            }),
                                          };
                                        }
                                        return showcase;
                                      }),
                                    };
                                  });
                                }}
                                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                              >
                                <option value="" disabled>
                                  Select Problem Metric Type
                                </option>
                                {problemMetricTypes?.map((type) => (
                                  <option key={type._id} value={type._id}>
                                    {type.problemMetricTypeName}
                                  </option>
                                ))}
                              </select>
                            </div>

                            {/* Problem Metric Gradation Type */}
                            <div id={`problemMetricGradationType-${showcaseIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                              <label
                                htmlFor={`problemMetricGradationType-${showcaseIndex}-${problemIndex}`}
                                className="block text-gray-600 text-sm font-bold mb-1"
                              >
                                Problem Metric Gradation Type
                              </label>
                              <select
                                id={`problemMetricGradationType-${showcaseIndex}-${problemIndex}`}
                                value={problem.problemMetricGradationType || ""}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormData((prevState) => {
                                    const updatedShowcases = [...prevState.userImprintShowcases];
                                    return {
                                      ...prevState,
                                      userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                        if (index === showcaseIndex) {
                                          return {
                                            ...showcase,
                                            userShowcaseProblems: showcase.userShowcaseProblems.map((p, i) => {
                                              if (i === problemIndex) {
                                                return { ...p, problemMetricGradationType: value || null };
                                              }
                                              return p;
                                            }),
                                          };
                                        }
                                        return showcase;
                                      }),
                                    };
                                  });
                                }}
                                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                              >
                                <option value="" disabled>
                                  Select Problem Metric Gradation Type
                                </option>
                                {problemMetricGradationTypes?.map((type) => (
                                  <option key={type._id} value={type._id}>
                                    {type.problemMetricGradationTypeName}
                                  </option>
                                ))}
                              </select>
                            </div>

                            {/* Problem Metric UOM Type */}
                            <div id={`problemMetricUoMType-${showcaseIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                              <label
                                htmlFor={`problemMetricUoMType-${showcaseIndex}-${problemIndex}`}
                                className="block text-gray-600 text-sm font-bold mb-1"
                              >
                                Problem Metric UoM Type
                              </label>
                              <select
                                id={`problemMetricUoMType-${showcaseIndex}-${problemIndex}`}
                                value={problem.problemMetricUoMType || ""}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormData((prevState) => {
                                    const updatedShowcases = [...prevState.userImprintShowcases];
                                    return {
                                      ...prevState,
                                      userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                        if (index === showcaseIndex) {
                                          return {
                                            ...showcase,
                                            userShowcaseProblems: showcase.userShowcaseProblems.map((p, i) => {
                                              if (i === problemIndex) {
                                                return { ...p, problemMetricUoMType: value || null };
                                              }
                                              return p;
                                            }),
                                          };
                                        }
                                        return showcase;
                                      }),
                                    };
                                  });
                                }}
                                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                              >
                                <option value="" disabled>
                                  Select Problem Metric UoM Type
                                </option>
                                {problemMetricUoMTypes?.map((type) => (
                                  <option key={type._id} value={type._id}>
                                    {type.problemMetricUoMTypeName}
                                  </option>
                                ))}
                              </select>
                            </div>

                            {/* Problem Current Measure Level */}
                            <div id={`problemCurrentMeasureLevel-${showcaseIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                              <label
                                htmlFor={`problemCurrentMeasureLevel-${showcaseIndex}-${problemIndex}`}
                                className="block text-gray-600 text-sm font-bold mb-1"
                              >
                                Problem Current Measure Level
                              </label>
                              <input
                                type="number"
                                id={`problemCurrentMeasureLevel-${showcaseIndex}-${problemIndex}`}
                                value={problem.problemCurrentMeasureLevel || 0}
                                onChange={(e) => {
                                  const value = parseFloat(e.target.value);
                                  setFormData((prevState) => {
                                    const updatedShowcases = [...prevState.userImprintShowcases];
                                    return {
                                      ...prevState,
                                      userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                        if (index === showcaseIndex) {
                                          return {
                                            ...showcase,
                                            userShowcaseProblems: showcase.userShowcaseProblems.map((p, i) => {
                                              if (i === problemIndex) {
                                                return { ...p, problemCurrentMeasureLevel: value || 0 };
                                              }
                                              return p;
                                            }),
                                          };
                                        }
                                        return showcase;
                                      }),
                                    };
                                  });
                                }}
                                placeholder="Enter the current measure level"
                                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                              />
                            </div>

                          </div>
                        )}
                      </div>
                    ))}
                </div>

                {/* Showcase Headwinds Section */}
                <div id={`showcaseHeadwinds-${showcaseIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                  <div className="flex justify-between items-center mb-2">
                    <div className='flex justify-start items-center m-1 p-1'>
                      <button
                        type="button"
                        className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                        onClick={() => toggleSection(`showcaseHeadwinds-${showcaseIndex}`)} // Toggle function
                      >
                        {collapsedSections[`showcaseHeadwinds-${showcaseIndex}`] ? (
                          <PlusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                          <MinusIcon className="h-4 w-4 text-orange-600" />
                        )}
                      </button>
                      <p className="font-bold text-sm">Showcase Headwinds</p>
                    </div>
                    <div className="flex justify-start items-center">
                      <p className="text-xs font-bold mx-1 px-1">Add</p>
                      <button
                        type="button"
                        className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                        onClick={() => {
                          setFormData((prevState) => {
                            const updatedShowcases = [...prevState.userImprintShowcases];
                            return {
                              ...prevState,
                              userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                if (index === showcaseIndex) {
                                  return {
                                    ...showcase,
                                    userShowcaseHeadwinds: [
                                      ...(showcase.userShowcaseHeadwinds || []),
                                      { userShowcaseHeadwindText: "" }, // Add a new headwind
                                    ],
                                  };
                                }
                                return showcase;
                              }),
                            };
                          });
                        }}
                      >
                        <PlusIcon className="h-4 w-4 inline" />
                      </button>
                    </div>
                  </div>

                  {/* Render Headwinds */}
                  {!collapsedSections[`showcaseHeadwinds-${showcaseIndex}`] &&
                    showcase.userShowcaseHeadwinds?.map((headwind, headwindIndex) => (
                      <div key={headwindIndex} className="p-2 border rounded mb-2 bg-white">
                        <div className="flex justify-between items-center">
                          <div className='flex justify-start items-center m-1 p-1'>
                            <button
                              type="button"
                              className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                              onClick={() => toggleSection(`headwind-${showcaseIndex}-${headwindIndex}`)} // Toggle function
                            >
                              {collapsedSections[`headwind-${showcaseIndex}-${headwindIndex}`] ? (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                              ) : (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                              )}
                            </button>
                            <p className="font-bold text-sm mx-1 px-1">Headwind {headwindIndex + 1}</p>
                          </div>
                          <div className="flex justify-start items-center">
                            <p className="font-bold text-xs mx-1 px-1">Remove</p>
                            <button
                              type="button"
                              className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                              onClick={() => {
                                setFormData((prevState) => {
                                  const updatedShowcases = [...prevState.userImprintShowcases];
                                  return {
                                    ...prevState,
                                    userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                      if (index === showcaseIndex) {
                                        return {
                                          ...showcase,
                                          userShowcaseHeadwinds: showcase.userShowcaseHeadwinds.filter(
                                            (_, i) => i !== headwindIndex
                                          ),
                                        };
                                      }
                                      return showcase;
                                    }),
                                  };
                                });
                              }}
                            >
                              <MinusIcon className="h-4 w-4 inline" />
                            </button>
                          </div>
                        </div>

                        {!collapsedSections[`headwind-${showcaseIndex}-${headwindIndex}`] && (
                          <div>
                            {/* Headwind Name */}
                            <div id={`headwindName-${showcaseIndex}-${headwindIndex}`} className="bg-gray-100 rounded p-1 m-1">
                              <label
                                htmlFor={`headwindName-${showcaseIndex}-${headwindIndex}`}
                                className="block text-gray-600 text-sm font-bold mb-1"
                              >
                                Headwind Name
                              </label>
                              <input
                                type="text"
                                id={`headwindName-${showcaseIndex}-${headwindIndex}`}
                                value={headwind.headwindName || ""}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormData((prevState) => {
                                    const updatedShowcases = [...prevState.userImprintShowcases];
                                    return {
                                      ...prevState,
                                      userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                        if (index === showcaseIndex) {
                                          return {
                                            ...showcase,
                                            userShowcaseHeadwinds: showcase.userShowcaseHeadwinds.map((h, i) => {
                                              if (i === headwindIndex) {
                                                return { ...h, headwindName: value };
                                              }
                                              return h;
                                            }),
                                          };
                                        }
                                        return showcase;
                                      }),
                                    };
                                  });
                                }}
                                placeholder="Enter headwind name"
                                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                              />
                            </div>
  
                            {/* Headwind Desc */}    
                            <div className="bg-gray-100 rounded p-1 m-1">
                              <label
                                htmlFor={`userShowcaseHeadwindText-${showcaseIndex}-${headwindIndex}`}
                                className="block text-gray-600 text-sm font-bold mb-1"
                              >
                                Headwind Description
                              </label>
                              <EtContentEditorLight2
                                value={headwind.userShowcaseHeadwindText || ""}
                                handleChange={(content) => {
                                  setFormData((prevState) => {
                                    const updatedShowcases = [...prevState.userImprintShowcases];
                                    return {
                                      ...prevState,
                                      userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                        if (index === showcaseIndex) {
                                          return {
                                            ...showcase,
                                            userShowcaseHeadwinds: showcase.userShowcaseHeadwinds.map((h, i) => {
                                              if (i === headwindIndex) {
                                                return { ...h, userShowcaseHeadwindText: content };
                                              }
                                              return h;
                                            }),
                                          };
                                        }
                                        return showcase;
                                      }),
                                    };
                                  });
                                }}
                                placeholderText="Describe the headwind here..."
                                editorKey={`headwind-${showcaseIndex}-${headwindIndex}`}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>

                {/* Showcase Approach Levers Section */}
                <div id={`showcaseApproachLevers-${showcaseIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                  <div className="flex justify-between items-center mb-2">
                    <div className='flex justify-start items-center m-1 p-1'>
                      <button
                        type="button"
                        className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                        onClick={() => toggleSection(`showcaseApproachLevers-${showcaseIndex}`)} // Toggle function
                      >
                        {collapsedSections[`showcaseApproachLevers-${showcaseIndex}`] ? (
                          <PlusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                          <MinusIcon className="h-4 w-4 text-orange-600" />
                        )}
                      </button>
                      <p className="font-bold text-sm">Showcase Approach Levers</p>
                    </div>
                    <div className="flex justify-start items-center">
                      <p className="text-xs font-bold mx-1 px-1">Add</p>
                      <button
                        type="button"
                        className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                        onClick={() => {
                          setFormData((prevState) => {
                            const updatedShowcases = [...prevState.userImprintShowcases];
                            return {
                              ...prevState,
                              userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                if (index === showcaseIndex) {
                                  return {
                                    ...showcase,
                                    userShowcaseApproachLevers: [
                                      ...(showcase.userShowcaseApproachLevers || []),
                                      { leverName: "", leverDescription: "" }, // New approach lever
                                    ],
                                  };
                                }
                                return showcase;
                              }),
                            };
                          });
                        }}
                      >
                        <PlusIcon className="h-4 w-4 inline" />
                      </button>
                    </div>
                  </div>

                  {/* Render Approach Levers */}
                  {!collapsedSections[`showcaseApproachLevers-${showcaseIndex}`] &&
                    showcase.userShowcaseApproachLevers?.map((lever, leverIndex) => (
                      <div key={leverIndex} className="p-2 border rounded mb-2 bg-white">
                        <div className="flex justify-between items-center">
                          <div className='flex justify-start items-center m-1 p-1'>
                            <button
                              type="button"
                              className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                              onClick={() => toggleSection(`lever-${showcaseIndex}-${leverIndex}`)} // Toggle function
                            >
                              {collapsedSections[`lever-${showcaseIndex}-${leverIndex}`] ? (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                              ) : (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                              )}
                            </button>
                            <p className="font-bold text-sm mx-1 px-1">Approach Lever {leverIndex + 1}</p>
                          </div>
                          <div className="flex justify-start items-center">
                            <p className="font-bold text-xs mx-1 px-1">Remove</p>
                            <button
                              type="button"
                              className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                              onClick={() => {
                                setFormData((prevState) => {
                                  const updatedShowcases = [...prevState.userImprintShowcases];
                                  return {
                                    ...prevState,
                                    userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                      if (index === showcaseIndex) {
                                        return {
                                          ...showcase,
                                          userShowcaseApproachLevers: showcase.userShowcaseApproachLevers.filter(
                                            (_, i) => i !== leverIndex
                                          ),
                                        };
                                      }
                                      return showcase;
                                    }),
                                  };
                                });
                              }}
                            >
                              <MinusIcon className="h-4 w-4 inline" />
                            </button>
                          </div>
                        </div>

                        {!collapsedSections[`lever-${showcaseIndex}-${leverIndex}`] && (
                          <div>
                            {/* Lever Name */}
                            <div id={`leverName-${showcaseIndex}-${leverIndex}`} className="bg-gray-100 rounded p-1 m-1">
                              <label
                                htmlFor={`leverName-${showcaseIndex}-${leverIndex}`}
                                className="block text-gray-600 text-sm font-bold mb-1"
                              >
                                Lever Name
                              </label>
                              <input
                                type="text"
                                id={`leverName-${showcaseIndex}-${leverIndex}`}
                                value={lever.leverName || ""}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormData((prevState) => {
                                    const updatedShowcases = [...prevState.userImprintShowcases];
                                    return {
                                      ...prevState,
                                      userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                        if (index === showcaseIndex) {
                                          return {
                                            ...showcase,
                                            userShowcaseApproachLevers: showcase.userShowcaseApproachLevers.map((l, i) => {
                                              if (i === leverIndex) {
                                                return { ...l, leverName: value };
                                              }
                                              return l;
                                            }),
                                          };
                                        }
                                        return showcase;
                                      }),
                                    };
                                  });
                                }}
                                placeholder="Enter lever name"
                                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                              />
                            </div>

                            {/* Lever Description */}
                            <div id={`leverDesc-${showcaseIndex}-${leverIndex}`} className="bg-gray-100 rounded p-1 m-1">
                              <label
                                htmlFor={`leverDesc-${showcaseIndex}-${leverIndex}`}
                                className="block text-gray-600 text-sm font-bold mb-1"
                              >
                                Lever Description
                              </label>
                              <EtContentEditorLight2
                                value={lever.leverDescription || ""}
                                handleChange={(content) => {
                                  setFormData((prevState) => {
                                    const updatedShowcases = [...prevState.userImprintShowcases];
                                    return {
                                      ...prevState,
                                      userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                        if (index === showcaseIndex) {
                                          return {
                                            ...showcase,
                                            userShowcaseApproachLevers: showcase.userShowcaseApproachLevers.map((l, i) => {
                                              if (i === leverIndex) {
                                                return { ...l, leverDescription: content };
                                              }
                                              return l;
                                            }),
                                          };
                                        }
                                        return showcase;
                                      }),
                                    };
                                  });
                                }}
                                placeholderText="Describe the lever here..."
                                editorKey={`lever-${showcaseIndex}-${leverIndex}`}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>

                {/* Showcase Actions and Results Section */}
                <div id={`showcaseActionsResults-${showcaseIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                  <div className="flex justify-between items-center mb-2">
                    <div className='flex justify-start items-center m-1 p-1'>
                      <button
                        type="button"
                        className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                        onClick={() => toggleSection(`showcaseActionsResults-${showcaseIndex}`)} // Toggle function
                      >
                        {collapsedSections[`showcaseActionsResults-${showcaseIndex}`] ? (
                          <PlusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                          <MinusIcon className="h-4 w-4 text-orange-600" />
                        )}
                      </button>
                      <p className="font-bold text-sm">Showcase Actions & Results</p>
                    </div>
                    <div className="flex justify-start items-center">
                      <p className="text-xs font-bold mx-1 px-1">Add</p>
                      <button
                        type="button"
                        className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                        onClick={() => {
                          setFormData((prevState) => {
                            const updatedShowcases = [...prevState.userImprintShowcases];
                            return {
                              ...prevState,
                              userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                if (index === showcaseIndex) {
                                  return {
                                    ...showcase,
                                    userShowcaseActionsAndResults: [
                                      ...(showcase.userShowcaseActionsAndResults || []),
                                      { actionName: "", actionDescription: "", result: "" }, // New action/result pair
                                    ],
                                  };
                                }
                                return showcase;
                              }),
                            };
                          });
                        }}
                      >
                        <PlusIcon className="h-4 w-4 inline" />
                      </button>
                    </div>
                  </div>

                  {/* Render Actions and Results */}
                  {!collapsedSections[`showcaseActionsResults-${showcaseIndex}`] &&
                    showcase.userShowcaseActionsAndResults?.map((actionResult, actionResultIndex) => (
                      <div key={actionResultIndex} className="p-2 border rounded mb-2 bg-white">
                        <div className="flex justify-between items-center">
                          <div className='flex justify-start items-center m-1 p-1'>
                            <button
                              type="button"
                              className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                              onClick={() => toggleSection(`actionResult-${showcaseIndex}-${actionResultIndex}`)} // Toggle function
                            >
                              {collapsedSections[`actionResult-${showcaseIndex}-${actionResultIndex}`] ? (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                              ) : (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                              )}
                            </button>
                            <p className="font-bold text-sm mx-1 px-1">Action {actionResultIndex + 1} and Results </p>
                          </div>
                          <div className="flex justify-start items-center">
                            <p className="font-bold text-xs mx-1 px-1">Remove</p>
                            <button
                              type="button"
                              className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                              onClick={() => {
                                setFormData((prevState) => {
                                  const updatedShowcases = [...prevState.userImprintShowcases];
                                  return {
                                    ...prevState,
                                    userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                      if (index === showcaseIndex) {
                                        return {
                                          ...showcase,
                                          userShowcaseActionsAndResults: showcase.userShowcaseActionsAndResults.filter(
                                            (_, i) => i !== actionResultIndex
                                          ),
                                        };
                                      }
                                      return showcase;
                                    }),
                                  };
                                });
                              }}
                            >
                              <MinusIcon className="h-4 w-4 inline" />
                            </button>
                          </div>
                        </div>

                        {!collapsedSections[`actionResult-${showcaseIndex}-${actionResultIndex}`] && (
                          <div>
                            {/* Action Name */}
                            <div id={`actionName-${showcaseIndex}-${actionResultIndex}`} className="bg-gray-100 rounded p-1 m-1">
                              <label
                                htmlFor={`actionName-${showcaseIndex}-${actionResultIndex}`}
                                className="block text-gray-600 text-sm font-bold mb-1"
                              >
                                Action Name
                              </label>
                              <input
                                type="text"
                                id={`actionName-${showcaseIndex}-${actionResultIndex}`}
                                value={actionResult.actionName || ""}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormData((prevState) => {
                                    const updatedShowcases = [...prevState.userImprintShowcases];
                                    return {
                                      ...prevState,
                                      userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                        if (index === showcaseIndex) {
                                          return {
                                            ...showcase,
                                            userShowcaseActionsAndResults: showcase.userShowcaseActionsAndResults.map((ar, i) => {
                                              if (i === actionResultIndex) {
                                                return { ...ar, actionName: value };
                                              }
                                              return ar;
                                            }),
                                          };
                                        }
                                        return showcase;
                                      }),
                                    };
                                  });
                                }}
                                placeholder="Enter action name"
                                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                              />
                            </div>

                            {/* Action Description */}
                            <div id={`actionDescription-${showcaseIndex}-${actionResultIndex}`} className="bg-gray-100 rounded p-1 m-1">
                              <label
                                htmlFor={`actionDescription-${showcaseIndex}-${actionResultIndex}`}
                                className="block text-gray-600 text-sm font-bold mb-1"
                              >
                                Action Description
                              </label>
                              <EtContentEditorLight2
                                value={actionResult.actionDescription || ""}
                                handleChange={(content) => {
                                  setFormData((prevState) => {
                                    const updatedShowcases = [...prevState.userImprintShowcases];
                                    return {
                                      ...prevState,
                                      userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                        if (index === showcaseIndex) {
                                          return {
                                            ...showcase,
                                            userShowcaseActionsAndResults: showcase.userShowcaseActionsAndResults.map((ar, i) => {
                                              if (i === actionResultIndex) {
                                                return { ...ar, actionDescription: content };
                                              }
                                              return ar;
                                            }),
                                          };
                                        }
                                        return showcase;
                                      }),
                                    };
                                  });
                                }}
                                placeholderText="Describe the action here..."
                                editorKey={`actionDescription-${showcaseIndex}-${actionResultIndex}`}
                              />
                            </div>

                            {/* Action Results Section */}
                            <div id={`actionResults-${showcaseIndex}-${actionResultIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                              <div className="flex justify-between items-center mb-2">
                                <div className="flex justify-start items-center m-1 p-1">
                                  <button
                                    type="button"
                                    className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                                    onClick={() => toggleSection(`actionResults-${showcaseIndex}-${actionResultIndex}`)}
                                  >
                                    {collapsedSections[`actionResults-${showcaseIndex}-${actionResultIndex}`] ? (
                                      <PlusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                      <MinusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                  </button>
                                  <p className="font-bold text-sm mx-1 px-1">Results</p>
                                </div>
                                <div className="flex justify-start items-center">
                                  <p className="font-bold text-xs mx-1 px-1">Add</p>
                                  <button
                                    type="button"
                                    className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                                    onClick={() => {
                                      setFormData((prevState) => {
                                        const updatedShowcases = [...prevState.userImprintShowcases];
                                        return {
                                          ...prevState,
                                          userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                            if (index === showcaseIndex) {
                                              return {
                                                ...showcase,
                                                userShowcaseActionsAndResults: showcase.userShowcaseActionsAndResults.map(
                                                  (actionResult, actionResultIdx) => {
                                                    if (actionResultIdx === actionResultIndex) {
                                                      return {
                                                        ...actionResult,
                                                        actionResults: [
                                                          ...(actionResult.actionResults || []),
                                                          {
                                                            problems: [], // Initial empty problems array
                                                            resultMagnitude: "yet to register",
                                                            resultDirection: "turnaround",
                                                            resultLongevity: "one time",
                                                            resultNotes: [],
                                                          },
                                                        ],
                                                      };
                                                    }
                                                    return actionResult;
                                                  }
                                                ),
                                              };
                                            }
                                            return showcase;
                                          }),
                                        };
                                      });
                                    }}
                                  >
                                    <PlusIcon className="h-4 w-4 inline" />
                                  </button>
                                </div>
                              </div>

                              {/* Render Action Results */}
                              {!collapsedSections[`actionResults-${showcaseIndex}-${actionResultIndex}`] &&
                                actionResult.actionResults?.map((result, resultIndex) => (
                                  <div key={resultIndex} className="p-2 border rounded mb-2 bg-white">
                                    <div className="flex justify-between items-center">
                                      <div className="flex justify-start items-center m-1 p-1">
                                        <button
                                          type="button"
                                          className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                                          onClick={() =>
                                            toggleSection(
                                              `actionResult-${showcaseIndex}-${actionResultIndex}-${resultIndex}`
                                            )
                                          }
                                        >
                                          {collapsedSections[
                                            `actionResult-${showcaseIndex}-${actionResultIndex}-${resultIndex}`
                                          ] ? (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                          ) : (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                          )}
                                        </button>
                                        <p className="font-bold text-sm mx-1 px-1">Result {resultIndex + 1}</p>
                                      </div>
                                      <div className="flex justify-start items-center">
                                        <p className="font-bold text-xs mx-1 px-1">Remove</p>
                                        <button
                                          type="button"
                                          className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                          onClick={() => {
                                            setFormData((prevState) => {
                                              const updatedShowcases = [...prevState.userImprintShowcases];
                                              return {
                                                ...prevState,
                                                userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                                  if (index === showcaseIndex) {
                                                    return {
                                                      ...showcase,
                                                      userShowcaseActionsAndResults: showcase.userShowcaseActionsAndResults.map(
                                                        (actionResult, actionResultIdx) => {
                                                          if (actionResultIdx === actionResultIndex) {
                                                            return {
                                                              ...actionResult,
                                                              actionResults: actionResult.actionResults.filter(
                                                                (_, resIdx) => resIdx !== resultIndex
                                                              ),
                                                            };
                                                          }
                                                          return actionResult;
                                                        }
                                                      ),
                                                    };
                                                  }
                                                  return showcase;
                                                }),
                                              };
                                            });
                                          }}
                                        >
                                          <MinusIcon className="h-4 w-4 inline" />
                                        </button>
                                      </div>
                                    </div>

                                  {/* Render Problems Section */}
                                  {!collapsedSections[
                                      `actionResult-${showcaseIndex}-${actionResultIndex}-${resultIndex}`
                                    ] && (
                                    <div>
                                      <div
                                        id={`problems-${showcaseIndex}-${actionResultIndex}-${resultIndex}`}
                                        className="border rounded p-2 m-2 bg-gray-50"
                                      >
                                        <div className="flex justify-between items-center mb-2">

                                          <div className='flex justify-start items-center'>
                                              <button
                                                    type="button"
                                                    className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                                                    onClick={() =>
                                                      toggleSection(
                                                        `problems-${showcaseIndex}-${actionResultIndex}-${resultIndex}`
                                                      )
                                                    }
                                                >
                                              {collapsedSections[
                                                `problems-${showcaseIndex}-${actionResultIndex}-${resultIndex}`
                                              ] ? (
                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                              ) : (
                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                              )}
                                            </button>
                                            <p className="font-bold text-sm mx-1 px-1">Problems</p>

                                          </div>

                                          
                                          <div className="flex justify-start items-center">
                                            <p className="font-bold text-xs mx-1 px-1">Add</p>
                                            <button
                                              type="button"
                                              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                                              onClick={() => {
                                                setFormData((prevState) => {
                                                  const updatedShowcases = [...prevState.userImprintShowcases];
                                                  return {
                                                    ...prevState,
                                                    userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                                      if (index === showcaseIndex) {
                                                        return {
                                                          ...showcase,
                                                          userShowcaseActionsAndResults: showcase.userShowcaseActionsAndResults.map(
                                                            (actionResult, actionResultIdx) => {
                                                              if (actionResultIdx === actionResultIndex) {
                                                                return {
                                                                  ...actionResult,
                                                                  actionResults: actionResult.actionResults.map(
                                                                    (result, resIdx) => {
                                                                      if (resIdx === resultIndex) {
                                                                        return {
                                                                          ...result,
                                                                          problems: [
                                                                            ...(result.problems || []),
                                                                            {
                                                                              problemName: "",
                                                                              problemPreActionLevel: 0,
                                                                              problemPostActionLevel: 0,
                                                                              problemMetricUoMType: "",
                                                                              problemMetricGradationType: "",
                                                                            },
                                                                          ],
                                                                        };
                                                                      }
                                                                      return result;
                                                                    }
                                                                  ),
                                                                };
                                                              }
                                                              return actionResult;
                                                            }
                                                          ),
                                                        };
                                                      }
                                                      return showcase;
                                                    }),
                                                  };
                                                });
                                              }}
                                            >
                                              <PlusIcon className="h-4 w-4 inline" />
                                            </button>
                                          </div>
                                        </div>

                                        {/* Problems Array */}
                                        {!collapsedSections[
                                                `problems-${showcaseIndex}-${actionResultIndex}-${resultIndex}`
                                              ] && result.problems?.map((problem, problemIndex) => (
                                          <div
                                            key={problemIndex}
                                            className="p-2 border rounded mb-2 bg-white"
                                          >
                                            <div className="flex justify-between items-center">
                                              <div className='flex justify-start items-center'>

                                                  <button
                                                        type="button"
                                                        className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                                                        onClick={() =>
                                                          toggleSection(
                                                            `problemName-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`
                                                          )
                                                        }
                                                    >
                                                  {collapsedSections[
                                                    `problemName-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`
                                                  ] ? (
                                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                                  ) : (
                                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                                  )}
                                                </button>

                                                <p className="font-bold text-sm">Problem {problemIndex + 1}</p>

                                              </div>
                                              
                                              <button
                                                type="button"
                                                className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                                onClick={() => {
                                                  setFormData((prevState) => {
                                                    const updatedShowcases = [...prevState.userImprintShowcases];
                                                    return {
                                                      ...prevState,
                                                      userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                                        if (index === showcaseIndex) {
                                                          return {
                                                            ...showcase,
                                                            userShowcaseActionsAndResults: showcase.userShowcaseActionsAndResults.map(
                                                              (actionResult, actionResultIdx) => {
                                                                if (actionResultIdx === actionResultIndex) {
                                                                  return {
                                                                    ...actionResult,
                                                                    actionResults: actionResult.actionResults.map(
                                                                      (result, resIdx) => {
                                                                        if (resIdx === resultIndex) {
                                                                          return {
                                                                            ...result,
                                                                            problems: result.problems.filter(
                                                                              (_, pIdx) => pIdx !== problemIndex
                                                                            ),
                                                                          };
                                                                        }
                                                                        return result;
                                                                      }
                                                                    ),
                                                                  };
                                                                }
                                                                return actionResult;
                                                              }
                                                            ),
                                                          };
                                                        }
                                                        return showcase;
                                                      }),
                                                    };
                                                  });
                                                }}
                                              >
                                                <MinusIcon className="h-4 w-4 inline" />
                                              </button>
                                            </div>

                                            
                                            {/* Each Problem in the problems array */}
                                            {!collapsedSections[
                                                    `problemName-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`
                                                  ] && ( <div>
                                            {/* Problem Name */}        
                                            <div
                                              id={`problemName-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                              className="bg-gray-100 rounded p-1 m-1"
                                            >
                                              <label
                                                htmlFor={`problemName-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                className="block text-gray-600 text-sm font-bold mb-1"
                                              >
                                                Problem Name
                                              </label>
                                              <select
                                                id={`problemName-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                value={problem.problemName || ""}
                                                onChange={(e) => {
                                                  const selectedProblemName = e.target.value;
                                                  const selectedProblem = showcase.userShowcaseProblems.find(
                                                    (p) => p.problemName === selectedProblemName
                                                  );
                                                  if (selectedProblem) {
                                                    setFormData((prevState) => {
                                                      const updatedShowcases = [...prevState.userImprintShowcases];
                                                      return {
                                                        ...prevState,
                                                        userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                                          if (index === showcaseIndex) {
                                                            return {
                                                              ...showcase,
                                                              userShowcaseActionsAndResults: showcase.userShowcaseActionsAndResults.map(
                                                                (actionResult, actionResultIdx) => {
                                                                  if (actionResultIdx === actionResultIndex) {
                                                                    return {
                                                                      ...actionResult,
                                                                      actionResults: actionResult.actionResults.map(
                                                                        (result, resIdx) => {
                                                                          if (resIdx === resultIndex) {
                                                                            return {
                                                                              ...result,
                                                                              problems: result.problems.map(
                                                                                (p, pIdx) => {
                                                                                  if (pIdx === problemIndex) {
                                                                                    return {
                                                                                      ...p,
                                                                                      problemName: selectedProblemName,
                                                                                      problemPreActionLevel:
                                                                                        selectedProblem.problemCurrentMeasureLevel,
                                                                                    };
                                                                                  }
                                                                                  return p;
                                                                                }
                                                                              ),
                                                                            };
                                                                          }
                                                                          return result;
                                                                        }
                                                                      ),
                                                                    };
                                                                  }
                                                                  return actionResult;
                                                                }
                                                              ),
                                                            };
                                                          }
                                                          return showcase;
                                                        }),
                                                      };
                                                    });
                                                  }
                                                }}
                                                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                              >
                                                <option value="" disabled>
                                                  Select a problem
                                                </option>
                                                {[
                                                  problem.problemName, // Include the currently selected problem
                                                  ...showcase.userShowcaseProblems
                                                    ?.filter(
                                                      (p) =>
                                                        !result.problems.some(
                                                          (rp, rpIdx) => rp.problemName === p.problemName && rpIdx !== problemIndex
                                                        )
                                                    )
                                                    ?.map((p) => p.problemName),
                                                ]
                                                  .filter(Boolean) // Remove any undefined or null values
                                                  .map((problemOption) => (
                                                    <option key={problemOption} value={problemOption}>
                                                      {problemOption}
                                                    </option>
                                                  ))}
                                              </select>
                                            </div>

                                            {/* Problem Pre-Action Level */}
                                            <div
                                              id={`problemPreActionLevel-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                              className="bg-gray-100 rounded p-1 m-1"
                                            >
                                              <label
                                                htmlFor={`problemPreActionLevel-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                className="block text-gray-600 text-sm font-bold mb-1"
                                              >
                                                Problem Pre-Action Level
                                              </label>
                                              <input
                                                type="number"
                                                id={`problemPreActionLevel-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                value={problem.problemPreActionLevel || 0}
                                                readOnly
                                                className="w-full rounded p-2 border border-gray-300 bg-gray-200 focus:ring-orange-500 focus:border-orange-500"
                                              />
                                            </div>

                                            {/* Problem Post-Action Level */}
                                            <div
                                              id={`problemPostActionLevel-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                              className="bg-gray-100 rounded p-1 m-1"
                                            >
                                              <label
                                                htmlFor={`problemPostActionLevel-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                className="block text-gray-600 text-sm font-bold mb-1"
                                              >
                                                Problem Post-Action Level
                                              </label>
                                              <input
                                                type="number"
                                                id={`problemPostActionLevel-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                value={problem.problemPostActionLevel || 0}
                                                onChange={(e) => {
                                                  const value = e.target.value;
                                                  setFormData((prevState) => {
                                                    const updatedShowcases = [...prevState.userImprintShowcases];
                                                    return {
                                                      ...prevState,
                                                      userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                                        if (index === showcaseIndex) {
                                                          return {
                                                            ...showcase,
                                                            userShowcaseActionsAndResults: showcase.userShowcaseActionsAndResults.map(
                                                              (actionResult, actionResultIdx) => {
                                                                if (actionResultIdx === actionResultIndex) {
                                                                  return {
                                                                    ...actionResult,
                                                                    actionResults: actionResult.actionResults.map((result, resIdx) => {
                                                                      if (resIdx === resultIndex) {
                                                                        return {
                                                                          ...result,
                                                                          problems: result.problems.map((p, probIdx) => {
                                                                            if (probIdx === problemIndex) {
                                                                              return { ...p, problemPostActionLevel: Number(value) };
                                                                            }
                                                                            return p;
                                                                          }),
                                                                        };
                                                                      }
                                                                      return result;
                                                                    }),
                                                                  };
                                                                }
                                                                return actionResult;
                                                              }
                                                            ),
                                                          };
                                                        }
                                                        return showcase;
                                                      }),
                                                    };
                                                  });
                                                }}
                                                placeholder="Enter post-action level"
                                                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                              />
                                            </div>

                                            {/* Problem Metric UoM Type */}
                                            <div
                                              id={`problemMetricUoMType-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                              className="bg-gray-100 rounded p-1 m-1"
                                            >
                                              <label
                                                htmlFor={`problemMetricUoMType-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                className="block text-gray-600 text-sm font-bold mb-1"
                                              >
                                                Problem Metric UoM Type
                                              </label>
                                              <select
                                                id={`problemMetricUoMType-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                value={problem.problemMetricUoMType || ""}
                                                onChange={(e) => {
                                                  const value = e.target.value;
                                                  setFormData((prevState) => {
                                                    const updatedShowcases = [...prevState.userImprintShowcases];
                                                    return {
                                                      ...prevState,
                                                      userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                                        if (index === showcaseIndex) {
                                                          return {
                                                            ...showcase,
                                                            userShowcaseActionsAndResults: showcase.userShowcaseActionsAndResults.map(
                                                              (actionResult, actionResultIdx) => {
                                                                if (actionResultIdx === actionResultIndex) {
                                                                  return {
                                                                    ...actionResult,
                                                                    actionResults: actionResult.actionResults.map((result, resIdx) => {
                                                                      if (resIdx === resultIndex) {
                                                                        return {
                                                                          ...result,
                                                                          problems: result.problems.map((p, probIdx) => {
                                                                            if (probIdx === problemIndex) {
                                                                              return { ...p, problemMetricUoMType: value || null };
                                                                            }
                                                                            return p;
                                                                          }),
                                                                        };
                                                                      }
                                                                      return result;
                                                                    }),
                                                                  };
                                                                }
                                                                return actionResult;
                                                              }
                                                            ),
                                                          };
                                                        }
                                                        return showcase;
                                                      }),
                                                    };
                                                  });
                                                }}
                                                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                              >
                                                <option value="" disabled>
                                                  Select Metric UoM Type
                                                </option>
                                                {problemMetricUoMTypes?.map((uom) => (
                                                  <option key={uom._id} value={uom._id}>
                                                    {uom.problemMetricUoMTypeName}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>

                                           
                                            {/* Problem Metric Gradation Type */}
                                            <div
                                              id={`problemMetricGradationType-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                              className="bg-gray-100 rounded p-1 m-1"
                                            >
                                              <label
                                                htmlFor={`problemMetricGradationType-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                className="block text-gray-600 text-sm font-bold mb-1"
                                              >
                                                Problem Metric Gradation Type
                                              </label>
                                              <select
                                                id={`problemMetricGradationType-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                value={problem.problemMetricGradationType || ""}
                                                onChange={(e) => {
                                                  const value = e.target.value;
                                                  setFormData((prevState) => {
                                                    const updatedShowcases = [...prevState.userImprintShowcases];
                                                    return {
                                                      ...prevState,
                                                      userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                                        if (index === showcaseIndex) {
                                                          return {
                                                            ...showcase,
                                                            userShowcaseActionsAndResults: showcase.userShowcaseActionsAndResults.map(
                                                              (actionResult, actionResultIdx) => {
                                                                if (actionResultIdx === actionResultIndex) {
                                                                  return {
                                                                    ...actionResult,
                                                                    actionResults: actionResult.actionResults.map((result, resIdx) => {
                                                                      if (resIdx === resultIndex) {
                                                                        return {
                                                                          ...result,
                                                                          problems: result.problems.map((p, probIdx) => {
                                                                            if (probIdx === problemIndex) {
                                                                              return { ...p, problemMetricGradationType: value || null };
                                                                            }
                                                                            return p;
                                                                          }),
                                                                        };
                                                                      }
                                                                      return result;
                                                                    }),
                                                                  };
                                                                }
                                                                return actionResult;
                                                              }
                                                            ),
                                                          };
                                                        }
                                                        return showcase;
                                                      }),
                                                    };
                                                  });
                                                }}
                                                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                              >
                                                <option value="" disabled>
                                                  Select Metric Gradation Type
                                                </option>
                                                {problemMetricGradationTypes?.map((gradation) => (
                                                  <option key={gradation._id} value={gradation._id}>
                                                    {gradation.problemMetricGradationTypeName}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                            </div> )}
                                            {/* End of each problem in the problems array */}
                                          
                                          </div>
                                        ))}
                                      </div>
                                        {/* Results - Magnitude, Direction, Longevity */}
                                  <div className='flex justify-between items-center m-1 p-1 border rounded'>
                                    {/* Result Magnitude */}
                                    <div
                                      id={`resultMagnitude-${showcaseIndex}-${actionResultIndex}-${resultIndex}`}
                                      className="bg-gray-100 rounded p-1 m-1 w-full"
                                    >
                                      <label
                                        htmlFor={`resultMagnitude-${showcaseIndex}-${actionResultIndex}-${resultIndex}`}
                                        className="block text-gray-600 text-sm font-bold mb-1"
                                      >
                                        Result Magnitude
                                      </label>
                                      <select
                                        id={`resultMagnitude-${showcaseIndex}-${actionResultIndex}-${resultIndex}`}
                                        value={result.resultMagnitude || ""}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormData((prevState) => {
                                            const updatedShowcases = [...prevState.userImprintShowcases];
                                            return {
                                              ...prevState,
                                              userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                                if (index === showcaseIndex) {
                                                  return {
                                                    ...showcase,
                                                    userShowcaseActionsAndResults: showcase.userShowcaseActionsAndResults.map(
                                                      (actionResult, actionResultIdx) => {
                                                        if (actionResultIdx === actionResultIndex) {
                                                          return {
                                                            ...actionResult,
                                                            actionResults: actionResult.actionResults.map((res, resIdx) => {
                                                              if (resIdx === resultIndex) {
                                                                return { ...res, resultMagnitude: value };
                                                              }
                                                              return res;
                                                            }),
                                                          };
                                                        }
                                                        return actionResult;
                                                      }
                                                    ),
                                                  };
                                                }
                                                return showcase;
                                              }),
                                            };
                                          });
                                        }}
                                        className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                      >
                                        <option value="" disabled>
                                          Select Magnitude
                                        </option>
                                        <option value="yet to register">Yet to Register</option>
                                        <option value="incremental">Incremental</option>
                                        <option value="significant">Significant</option>
                                        <option value="order of magnitude">Order of Magnitude</option>
                                        <option value="benchmark">Benchmark</option>
                                      </select>
                                    </div>

                                    {/* Result Direction */}
                                    <div
                                      id={`resultDirection-${showcaseIndex}-${actionResultIndex}-${resultIndex}`}
                                      className="bg-gray-100 rounded p-1 m-1 w-full"
                                    >
                                      <label
                                        htmlFor={`resultDirection-${showcaseIndex}-${actionResultIndex}-${resultIndex}`}
                                        className="block text-gray-600 text-sm font-bold mb-1"
                                      >
                                        Result Direction
                                      </label>
                                      <select
                                        id={`resultDirection-${showcaseIndex}-${actionResultIndex}-${resultIndex}`}
                                        value={result.resultDirection || ""}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormData((prevState) => {
                                            const updatedShowcases = [...prevState.userImprintShowcases];
                                            return {
                                              ...prevState,
                                              userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                                if (index === showcaseIndex) {
                                                  return {
                                                    ...showcase,
                                                    userShowcaseActionsAndResults: showcase.userShowcaseActionsAndResults.map(
                                                      (actionResult, actionResultIdx) => {
                                                        if (actionResultIdx === actionResultIndex) {
                                                          return {
                                                            ...actionResult,
                                                            actionResults: actionResult.actionResults.map((res, resIdx) => {
                                                              if (resIdx === resultIndex) {
                                                                return { ...res, resultDirection: value };
                                                              }
                                                              return res;
                                                            }),
                                                          };
                                                        }
                                                        return actionResult;
                                                      }
                                                    ),
                                                  };
                                                }
                                                return showcase;
                                              }),
                                            };
                                          });
                                        }}
                                        className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                      >
                                        <option value="" disabled>
                                          Select Direction
                                        </option>
                                        <option value="turnaround">Turnaround</option>
                                        <option value="stabilized">Stabilized</option>
                                        <option value="kept it steady">Kept It Steady</option>
                                        <option value="accelerated">Accelerated</option>
                                      </select>
                                    </div>

                                    {/* Result Longevity */}
                                    <div
                                      id={`resultLongevity-${showcaseIndex}-${actionResultIndex}-${resultIndex}`}
                                      className="bg-gray-100 rounded p-1 m-1 w-full"
                                    >
                                      <label
                                        htmlFor={`resultLongevity-${showcaseIndex}-${actionResultIndex}-${resultIndex}`}
                                        className="block text-gray-600 text-sm font-bold mb-1"
                                      >
                                        Result Longevity
                                      </label>
                                      <select
                                        id={`resultLongevity-${showcaseIndex}-${actionResultIndex}-${resultIndex}`}
                                        value={result.resultLongevity || ""}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormData((prevState) => {
                                            const updatedShowcases = [...prevState.userImprintShowcases];
                                            return {
                                              ...prevState,
                                              userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                                if (index === showcaseIndex) {
                                                  return {
                                                    ...showcase,
                                                    userShowcaseActionsAndResults: showcase.userShowcaseActionsAndResults.map(
                                                      (actionResult, actionResultIdx) => {
                                                        if (actionResultIdx === actionResultIndex) {
                                                          return {
                                                            ...actionResult,
                                                            actionResults: actionResult.actionResults.map((res, resIdx) => {
                                                              if (resIdx === resultIndex) {
                                                                return { ...res, resultLongevity: value };
                                                              }
                                                              return res;
                                                            }),
                                                          };
                                                        }
                                                        return actionResult;
                                                      }
                                                    ),
                                                  };
                                                }
                                                return showcase;
                                              }),
                                            };
                                          });
                                        }}
                                        className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                      >
                                        <option value="" disabled>
                                          Select Longevity
                                        </option>
                                        <option value="one time">One Time</option>
                                        <option value="limited time">Limited Time</option>
                                        <option value="ongoing">Ongoing</option>
                                      </select>
                                    </div>

                                  </div> {/* End of Results - Magnitude, Direction, Longevity */}

                                  {/* Result Notes Section */}
                                      <div
                                        id={`resultNotes-${showcaseIndex}-${actionResultIndex}-${resultIndex}`}
                                        className="border rounded p-2 m-2 bg-gray-50"
                                      >
                                        <div className="flex justify-between items-center mb-2">
                                          <p className="font-bold text-sm">Result Notes</p>
                                          <div className="flex justify-start items-center">
                                            <p className="font-bold text-xs mx-1 px-1">Add</p>
                                            <button
                                              type="button"
                                              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                                              onClick={() => {
                                                setFormData((prevState) => {
                                                  const updatedShowcases = [...prevState.userImprintShowcases];
                                                  return {
                                                    ...prevState,
                                                    userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                                      if (index === showcaseIndex) {
                                                        return {
                                                          ...showcase,
                                                          userShowcaseActionsAndResults: showcase.userShowcaseActionsAndResults.map(
                                                            (actionResult, actionResultIdx) => {
                                                              if (actionResultIdx === actionResultIndex) {
                                                                return {
                                                                  ...actionResult,
                                                                  actionResults: actionResult.actionResults.map((result, resIdx) => {
                                                                    if (resIdx === resultIndex) {
                                                                      return {
                                                                        ...result,
                                                                        resultNotes: [
                                                                          ...(result.resultNotes || []),
                                                                          {
                                                                            resultNoteText: "",
                                                                            resultNoteDate: new Date().toISOString(),
                                                                            resultNoteUpdateDate: null,
                                                                          },
                                                                        ],
                                                                      };
                                                                    }
                                                                    return result;
                                                                  }),
                                                                };
                                                              }
                                                              return actionResult;
                                                            }
                                                          ),
                                                        };
                                                      }
                                                      return showcase;
                                                    }),
                                                  };
                                                });
                                              }}
                                            >
                                              <PlusIcon className="h-4 w-4 inline" />
                                            </button>
                                          </div>
                                        </div>

                                        {/* Render Result Notes */}
                                        {result.resultNotes?.map((note, noteIndex) => (
                                          <div key={noteIndex} className="p-2 border rounded mb-2 bg-white">
                                            <div className="flex justify-between items-center">

                                              <div className='flex justify-start items-center'>
                                                  <button
                                                            type="button"
                                                            className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                                                            onClick={() =>
                                                              toggleSection(
                                                                `resultNote-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${noteIndex}`
                                                              )
                                                            }
                                                        >
                                                      {collapsedSections[
                                                        `resultNote-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${noteIndex}`
                                                      ] ? (
                                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                                      ) : (
                                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                                      )}
                                                    </button>
                                                    <p className="font-bold text-sm">Note {noteIndex + 1}</p>
                                              </div>
                                              
                                              <div className="flex justify-start items-center">
                                                <p className="font-bold text-xs mx-1 px-1">Remove</p>
                                                <button
                                                  type="button"
                                                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                                  onClick={() => {
                                                    setFormData((prevState) => {
                                                      const updatedShowcases = [...prevState.userImprintShowcases];
                                                      return {
                                                        ...prevState,
                                                        userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                                          if (index === showcaseIndex) {
                                                            return {
                                                              ...showcase,
                                                              userShowcaseActionsAndResults: showcase.userShowcaseActionsAndResults.map(
                                                                (actionResult, actionResultIdx) => {
                                                                  if (actionResultIdx === actionResultIndex) {
                                                                    return {
                                                                      ...actionResult,
                                                                      actionResults: actionResult.actionResults.map((result, resIdx) => {
                                                                        if (resIdx === resultIndex) {
                                                                          return {
                                                                            ...result,
                                                                            resultNotes: result.resultNotes.filter(
                                                                              (_, noteIdx) => noteIdx !== noteIndex
                                                                            ),
                                                                          };
                                                                        }
                                                                        return result;
                                                                      }),
                                                                    };
                                                                  }
                                                                  return actionResult;
                                                                }
                                                              ),
                                                            };
                                                          }
                                                          return showcase;
                                                        }),
                                                      };
                                                    });
                                                  }}
                                                >
                                                  <MinusIcon className="h-4 w-4 inline" />
                                                </button>
                                              </div>
                                            </div>

                                            {/* Note Text */}
                                            {!collapsedSections[
                                                        `resultNote-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${noteIndex}`
                                                      ] && (
                                            <div>
                                            <div
                                              id={`resultNoteText-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${noteIndex}`}
                                              className="bg-gray-100 rounded p-1 m-1"
                                            >
                                              <label
                                                htmlFor={`resultNoteText-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${noteIndex}`}
                                                className="block text-gray-600 text-sm font-bold mb-1"
                                              >
                                                Note Text
                                              </label>
                                              <EtContentEditorLight2
                                                value={note.resultNoteText || ""}
                                                handleChange={(content) => {
                                                  setFormData((prevState) => {
                                                    const updatedShowcases = [...prevState.userImprintShowcases];
                                                    return {
                                                      ...prevState,
                                                      userImprintShowcases: updatedShowcases.map((showcase, index) => {
                                                        if (index === showcaseIndex) {
                                                          return {
                                                            ...showcase,
                                                            userShowcaseActionsAndResults: showcase.userShowcaseActionsAndResults.map(
                                                              (actionResult, actionResultIdx) => {
                                                                if (actionResultIdx === actionResultIndex) {
                                                                  return {
                                                                    ...actionResult,
                                                                    actionResults: actionResult.actionResults.map((result, resIdx) => {
                                                                      if (resIdx === resultIndex) {
                                                                        return {
                                                                          ...result,
                                                                          resultNotes: result.resultNotes.map((noteObj, noteIdx) => {
                                                                            if (noteIdx === noteIndex) {
                                                                              return {
                                                                                ...noteObj,
                                                                                resultNoteText: content,
                                                                                resultNoteUpdateDate: new Date().toISOString(),
                                                                              };
                                                                            }
                                                                            return noteObj;
                                                                          }),
                                                                        };
                                                                      }
                                                                      return result;
                                                                    }),
                                                                  };
                                                                }
                                                                return actionResult;
                                                              }
                                                            ),
                                                          };
                                                        }
                                                        return showcase;
                                                      }),
                                                    };
                                                  });
                                                }}
                                                placeholderText="Enter result note here..."
                                                editorKey={`resultNoteText-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${noteIndex}`}
                                              />
                                            </div>


                                            {/* Note Metadata */}
                                            <p className="text-xs text-gray-500 mt-2">
                                              Created on: {new Date(note.resultNoteDate).toLocaleDateString()}
                                              {note.resultNoteUpdateDate && (
                                                <>
                                                  , Updated on: {new Date(note.resultNoteUpdateDate).toLocaleDateString()}
                                                </>
                                              )}
                                            </p>
                                            </div> )} {/* End of a specific Result Note */}
                                          </div>
                                        ))}
                                      </div> {/* End of Result Notes */}


                                    </div>
                                  )}
                                 
                                  


                                  </div>
                                ))}
                            </div>


                          </div>
                        )}
                      </div>
                    ))}
                </div>


              </div>
            )}
          </div>
        ))}
      </div>

      {/* 4. Self Notes Section */}
      <div id="selfNotes" className="border rounded p-2 m-2 bg-gray-50">
        <div className="flex justify-between items-center mb-2">

        <div className='flex justify-start items-center m-1 p-1'>
            <button
              type="button"
              className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
              onClick={() => toggleSection(`selfNotes`)} // Toggle function
            >
              {collapsedSections[`selfNotes`] ? (
                <PlusIcon className="h-4 w-4 text-orange-600" />
              ) : (
                <MinusIcon className="h-4 w-4 text-orange-600" />
              )}
            </button>
            <p className="font-bold text-sm">Showcase Self Notes</p>
          </div>

          
          <div className="flex justify-start items-center">
            <p className="text-xs font-bold mx-1 px-1">Add</p>
            <button
              type="button"
              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
              onClick={() => {
                setFormData((prevState) => ({
                  ...prevState,
                  userImprintShowcaseSelfNotes: [
                    ...(Array.isArray(prevState.userImprintShowcaseSelfNotes)
                      ? prevState.userImprintShowcaseSelfNotes
                      : []),
                    {
                      userImprintShowcaseSelfNoteText: "",
                      userImprintShowcaseSelfNoteDate: new Date().toISOString(),
                      userImprintShowcaseSelfNoteUpdateDate: null,
                    },
                  ],
                }));
                // setCollapsedSections(`selfNotes`)
              }}
            >
              <PlusIcon className="h-4 w-4 inline" />
            </button>
          </div>
        </div>

        {/* Render Notes */}
        {!collapsedSections[`selfNotes`] && formData.userImprintShowcaseSelfNotes?.map((note, noteIndex) => (
          <div key={noteIndex} className="p-2 border rounded mb-2 bg-white">
            <div className="flex justify-between items-center">

            <div className='flex justify-start items-center m-1 p-1'>
              <button
                type="button"
                className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                onClick={() => toggleSection(`note-${noteIndex}`)} // Toggle function
              >
                {collapsedSections[`note-${noteIndex}`] ? (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                )}
              </button>
              <p className="font-bold text-sm mx-1 px-1">Self Note {noteIndex + 1}</p>
            </div>


              
              <div className="flex justify-start items-center">
                <p className="font-bold text-xs mx-1 px-1">Remove</p>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) => ({
                      ...prevState,
                      userImprintShowcaseSelfNotes: prevState.userImprintShowcaseSelfNotes.filter(
                        (_, i) => i !== noteIndex
                      ),
                    }));
                  }}
                >
                  <MinusIcon className="h-4 w-4 inline" />
                </button>
              </div>
            </div>

            {!collapsedSections[`note-${noteIndex}`] && (
              <div>      
              {/* Note Text */}
                  <EtContentEditorLight2
                    value={note.userImprintShowcaseSelfNoteText}
                    handleChange={(content) => {
                      setFormData((prevState) => {
                        const updatedNotes = [...prevState.userImprintShowcaseSelfNotes];
                        updatedNotes[noteIndex].userImprintShowcaseSelfNoteText = content;
                        updatedNotes[noteIndex].userImprintShowcaseSelfNoteUpdateDate = new Date().toISOString();
                        return { ...prevState, userImprintShowcaseSelfNotes: updatedNotes };
                      });
                    }}
                    placeholderText="Write your note here..."
                    editorKey={`selfNote-${noteIndex}`}
                  />

                  {/* Note Dates */}
                  <p className="text-xs text-gray-500 mt-2">
                    Created on: {new Date(note.userImprintShowcaseSelfNoteDate).toLocaleDateString()}
                    {note.userImprintShowcaseSelfNoteUpdateDate && (
                      <span>
                        , Updated on:{" "}
                        {new Date(note.userImprintShowcaseSelfNoteUpdateDate).toLocaleDateString()}
                      </span>
                    )}
                  </p>
              </div>
            )}

          </div>
        ))}
      </div>

      {/* 5. About Me Section */}
      {/* <div id="aboutMe" className="bg-gray-100 rounded p-1 m-1">
          <label
            htmlFor="aboutMe"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
            About Me
          </label>
          <EtContentEditorLight2
            value={formData.aboutMe}
            handleChange={(content) => {
              setFormData((prevState) => ({
                ...prevState,
                aboutMe: content,
              }));
            }}
            placeholderText="Write about yourself here..."
            editorKey="aboutMe-editor"
          />
        </div> */}

      {/* 6. Picture Upload Section */}
      {/* <div id="pic" className="bg-gray-100 rounded p-1 m-1">
        <label
          htmlFor="pic"
          className="block text-gray-600 text-sm font-bold mb-1"
        >
          Role Profile Picture
        </label>
        <input
          type="file"
          name="pic"
          accept="image/*"
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              convertPicToBase64(file)
                .then((base64) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    pic: base64,
                  }));
                })
                .catch((error) => {
                  console.error("Error converting image to Base64:", error);
                });
            }
          }}
          className="w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-orange-300"
        />
        {formData.pic && (
          <img
            src={formData.pic}
            alt="Preview"
            className="mt-2 h-20 w-20 rounded-full border border-gray-300"
          />
        )}
      </div> */}

      {/* 7. Active Toggle Section */}
      {/* <div id="active" className="bg-gray-100 rounded p-1 m-1">
        <label
          htmlFor="active"
          className="block text-gray-600 text-sm font-bold mb-1"
        >
          Is Active?
        </label>
        <input
          type="checkbox"
          name="active"
          id="active"
          checked={formData.active}
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              active: e.target.checked,
            }));
          }}
          className="w-5 h-5 text-orange-500 rounded border-gray-300 focus:ring-orange-500 focus:border-orange-500"
        />
      </div> */}

      {/* 8. Submission Actions Bar */}
      <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
        <EtBtnFormBasic 
          name={'Create'} 
          type={'create'} 
          handleClick={handleSubmit} 
          />
        <EtBtnFormBasic
          name={'Clear'}
          type={'clear'}
          handleClick={handleClear}
        />
        <EtBtnFormBasic
          name={'Cancel'}
          type={'cancel'}
          handleClick={handleCancel}
        />
      </div>

    </form>
  </div>
);

}

export default EtCreateUserImprintShowcaseComp