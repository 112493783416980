import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import { PlusIcon, MinusIcon} from '@heroicons/react/24/outline'
import { useGetTrailsQuery, useUpdateTrailMutation } from "./trailsApiSlice"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight from "@/utils/EtContentEditorLight"

const EtUpdateTrail = ({id, setActiveAdminView}) => {

    // First Created - 02 Apr 2024
    // Updated 1 - 07 May 2024 - see update notes at the bottom
    // Author - Atul Pandey
    // Updated - 01 Mar 2025 - updated the comp with props - { id, setActiveAdminView}
    // Updated - 01 Mar 2025 - switched from navigate to setActiveAdminView

    // 0 - Set the page title
    useTitle("Trail Update")
    
    // 1 - extract the set id to be edited from the url
    // const {id} = useParams()
    //console.log('id:',id)

    // 2 - Extract the Question Set data through the useGet query
    const { trail } = useGetTrailsQuery('TrailList', {
        selectFromResult: ({ data }) => ({
            trail: data?.entities[id]
        }),
    })
    //console.log('trail:',trail)

    // 3. Check for returned data
    if(!trail) {
        return <PulseLoader color={"#ea580c"} />
    }

    // 4. Display data
  let content
  content = <EditTrailForm trail={trail} id={id} setActiveAdminView={setActiveAdminView}/> // 01 Mar 2025 - added setActiveAdminView={setActiveAdminView} 

  return content

}

const EditTrailForm = ({trail, id, setActiveAdminView}) => {
    // 1 - set the navigate hook
    const navigate = useNavigate()

    // 2 - The mutation to update the Question Set Data
    const [ updateTrail, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateTrailMutation()

    // 3 - Set the data received in the component props to the local variables
    const [formData, setFormData] = useState({
        trailName: trail.trailName,
        trailDesc: trail.trailDesc,
        trailTargetAudience: trail.trailTargetAudience,
        estimatedDuration: trail.estimatedDuration,
      })
    
      // 4. Reset the input field and navigation based on the success state 
    useEffect(() => {
        if(isSuccess) {
            setFormData({
                trailName: "",
                trailDesc: "",
                pic: null,
                trailTargetAudience: [{audience: ""}],
                estimatedDuration: "",
              })
           // navigate("/trails")
      setActiveAdminView('trails'); // 01 Mar 2025
    }
    
    },[isSuccess])// ✅ 01 Mar 2025 - Removed `navigate` since it's no longer used.

    // 5a. Form field updates / change handling

  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }))
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }))
    }
  }

  // 5b. handle the content created inside the rich editor
  const handleContentChange = (newContent) => {
    setFormData(prevFormData => ({ ...prevFormData, trailDesc: newContent }));
  }

  // 6a - Handle Add Audience
  const handleAddAudience = () => {
    setFormData({
      ...formData,
      trailTargetAudience: [...formData.trailTargetAudience, { audience: "" }]
    });
  };

  // 6b - Handle Remove Audience
  const handleRemoveAudience = (index) => {
    setFormData({
      ...formData,
      trailTargetAudience: formData.trailTargetAudience.filter((_, i) => i !== index)
    });
  };

  // 6c - Handle Audience Change
  const handleAudienceChange = (index, value) => {
    const updatedAudiences = formData.trailTargetAudience.map((item, i) =>
      i === index ? { audience: value } : item
    );
    setFormData({ ...formData, trailTargetAudience: updatedAudiences });
  };

   // 7. Form Submit logic:
   const handleSubmit = async (e) => {
    e.preventDefault()

    // Initialize an object to collect the form data
    const trailData = {
      trailName: formData.trailName,
      trailDesc: formData.trailDesc,
      trailTargetAudience: formData.trailTargetAudience,
      estimatedDuration: formData.estimatedDuration,
    }

    if (formData.pic) {
      // Convert the image to a base64 string
      const reader = new FileReader()
      reader.onloadend = async () => {
        // Once conversion is done, add the base64 string to your data object
        trailData.pic = reader.result

        // Now, send the data object to the server
        try {
            trailData.id = id
          //console.log("Form submitted.")
          await updateTrail(trailData).unwrap() // Dispatch the RTK Query mutation
        } catch (error) {
          console.error(error)
        }
      }
      reader.readAsDataURL(formData.pic)
    } else {
      // No image, send other data as is
      try {
        trailData.id = id
        //console.log("Form submitted.")
        //console.log('trail data submitted', trailData)
        await updateTrail(trailData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleCancel = () => {
    // navigate("/trails")
    setActiveAdminView('trails'); // 01 Mar 2025
  }

const handleClear = () => {
    setFormData({
        trailName: "",
        trailDesc: "",
        pic: null,
        trailTargetAudience: [{audience: ""}],
      })
}


return (
    <div className="bg-gray-200 p-1 mx-auto my-5 rounded-lg shadow-md shadow-black">
      {isLoading && <PulseLoader color={"#ea580c"} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">
          {error?.data?.message}
        </p>
      )}
      <div className="bg-white p-1 m-1 rounded">
        <p className="p-1 m-1 font-bold font-poppins text-xl">
          Update a Trail
        </p>

        <div className="bg-gray-200 rounded m-1 p-1">
          <form
            onSubmit={handleSubmit}
            className="m-1 p-1 text-sm font-poppins"
          >
            <div id="trailName">
              <input
                type="text"
                name="trailName"
                placeholder="Set Name"
                value={formData.trailName}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="estimatedDuration">
              <input
                type="text"
                name="estimatedDuration"
                placeholder="Trail Duration"
                value={formData.estimatedDuration}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div className="h-32 bg-white rounded m-1 p-2 w-full">
            <EtContentEditorLight
                value={formData.trailDesc}
                handleChange={handleContentChange}
              />
            </div>


            <div id="pic">
              <input
                type="file"
                name="pic"
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            {/* Target Audience List */}
            <div id="target-audience">

                <div className="m-1 p-1 border border-white rounded">
                    <div>
                      <p className="font-bold mx-2 px-2 mt-2">Target Audience</p>
                      <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">Who this trail is for?</p>
                    </div>

                      {formData.trailTargetAudience.map((item, index) => (
                        <div key={index} className="flex justify-start items-center mx-1">
                          <input
                            type="text"
                            value={item.audience}
                            onChange={(e) => handleAudienceChange(index, e.target.value)}
                            placeholder="Enter a target audience"
                            className="w-full rounded p-2 m-1 text-xs"
                          />
                          <div className="rounded bg-red-500 m-1 p-1">
                            <button type="button" onClick={() => handleRemoveAudience(index)}>
                              <MinusIcon className="h-5 w-5 text-white" />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-start items-center m-1">

                          <p className="font-bold text-xs m-1 p-1">Add</p>
                          <button type="button" onClick={handleAddAudience}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                          </button>
                      
                      </div>
                </div>
           
            </div>

            <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Update"}
                type={"create"}
                handleClick={handleSubmit}
              />
              <EtBtnFormBasic
                name={"Clear"}
                type={"clear"}
                handleClick={handleClear}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )

}

export default EtUpdateTrail

// Update Notes
// -------------------
// Note 1 - 07 May 2024
// Added trailTargetAudience feature - form field, add / remove, change functions, form submit function
// functions added - 6a, 6b, 6c
// functions updated - 7
// comments and step numbers added for each part of the component
// --------------------