import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userVisionOrgsAdapter = createEntityAdapter({})

const initialState = userVisionOrgsAdapter.getInitialState()

export const userVisionOrgsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserVisionOrgs: builder.query({
            query: () => ({
                url: '/userVisionOrgs/getUserVisionOrgs',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserVisionOrgs = responseData.map(userVisionOrg => {
                    userVisionOrg.id = userVisionOrg._id
                    return userVisionOrg
                })
                return userVisionOrgsAdapter.setAll(initialState, loadedUserVisionOrgs)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserVisionOrg', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserVisionOrg', id}))
                    ]
                } else {
                    return [ { type: 'UserVisionOrg', id: 'LIST'}]
                }
            },
        }),
        createNewUserVisionOrg: builder.mutation({
            query: initialUserVisionOrg => ({
                url: '/userVisionOrgs/createNewUserVisionOrg',
                method: 'POST',
                body: {...initialUserVisionOrg,}
            }),
            invalidatesTags: [
                { type: 'UserVisionOrg', id: "LIST" }
            ] 
        }),
        updateUserVisionOrg: builder.mutation({
            query: initialUserVisionOrg => ({
                url: '/userVisionOrgs/updateUserVisionOrg',
                method: 'PATCH',
                body: {...initialUserVisionOrg,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserVisionOrg', id: arg.id },
                { type: 'UserVisionOrg', id: 'LIST' }
            ],
        }),
        deleteUserVisionOrg: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userVisionOrgs/deleteUserVisionOrg',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userVisionOrgs/deleteUserVisionOrg',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserVisionOrg', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserVisionOrgsQuery,
    useCreateNewUserVisionOrgMutation,
    useUpdateUserVisionOrgMutation,
    useDeleteUserVisionOrgMutation,
} = userVisionOrgsApiSlice

// returns the query result object
export const selectUserVisionOrgResult = userVisionOrgsApiSlice.endpoints.getUserVisionOrgs.select()

// create memoized selector
const selectUserVisionOrgsData = createSelector(
    selectUserVisionOrgResult,
    userVisionOrgResult => userVisionOrgResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserVisionOrgs,
    selectById: selectUserVisionOrgById,
    selectIds: selectUserVisionOrgIds,
} = userVisionOrgsAdapter.getSelectors(state => selectUserVisionOrgsData(state) ?? initialState)