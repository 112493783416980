import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userMissionClientsAdapter = createEntityAdapter({})

const initialState = userMissionClientsAdapter.getInitialState()

export const userMissionClientsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserMissionClients: builder.query({
            query: () => ({
                url: '/userMissionClients/getUserMissionClients',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserMissionClients = responseData.map(userMissionClient => {
                    userMissionClient.id = userMissionClient._id
                    return userMissionClient
                })
                return userMissionClientsAdapter.setAll(initialState, loadedUserMissionClients)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserMissionClient', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserMissionClient', id}))
                    ]
                } else {
                    return [ { type: 'UserMissionClient', id: 'LIST'}]
                }
            },
        }),
        createNewUserMissionClient: builder.mutation({
            query: initialUserMissionClient => ({
                url: '/userMissionClients/createNewUserMissionClient',
                method: 'POST',
                body: {...initialUserMissionClient,}
            }),
            invalidatesTags: [
                { type: 'UserMissionClient', id: "LIST" }
            ] 
        }),
        updateUserMissionClient: builder.mutation({
            query: initialUserMissionClient => ({
                url: '/userMissionClients/updateUserMissionClient',
                method: 'PATCH',
                body: {...initialUserMissionClient,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserMissionClient', id: arg.id },
                { type: 'UserMissionClient', id: 'LIST' }
            ],
        }),
        deleteUserMissionClient: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userMissionClients/deleteUserMissionClient',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userMissionClients/deleteUserMissionClient',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserMissionClient', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserMissionClientsQuery,
    useCreateNewUserMissionClientMutation,
    useUpdateUserMissionClientMutation,
    useDeleteUserMissionClientMutation,
} = userMissionClientsApiSlice

// returns the query result object
export const selectUserMissionClientResult = userMissionClientsApiSlice.endpoints.getUserMissionClients.select()

// create memoized selector
const selectUserMissionClientsData = createSelector(
    selectUserMissionClientResult,
    userMissionClientResult => userMissionClientResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserMissionClients,
    selectById: selectUserMissionClientById,
    selectIds: selectUserMissionClientIds,
} = userMissionClientsAdapter.getSelectors(state => selectUserMissionClientsData(state) ?? initialState)