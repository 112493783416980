import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const investigationTypesAdapter = createEntityAdapter({})

const initialState = investigationTypesAdapter.getInitialState()

export const investigationTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getInvestigationTypes: builder.query({
            query: () => ({
                url: '/investigationTypes/getInvestigationTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedInvestigationTypes = responseData.map(investigationType => {
                    investigationType.id = investigationType._id
                    return investigationType
                })
                return investigationTypesAdapter.setAll(initialState, loadedInvestigationTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'InvestigationType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'InvestigationType', id}))
                    ]
                } else {
                    return [ { type: 'InvestigationType', id: 'LIST'}]
                }
            },
        }),
        createNewInvestigationType: builder.mutation({
            query: initialInvestigationType => ({
                url: '/investigationTypes/createNewInvestigationType',
                method: 'POST',
                body: {...initialInvestigationType,}
            }),
            invalidatesTags: [
                { type: 'InvestigationType', id: "LIST" }
            ] 
        }),
        updateInvestigationType: builder.mutation({
            query: initialInvestigationType => ({
                url: '/investigationTypes/updateInvestigationType',
                method: 'PATCH',
                body: {...initialInvestigationType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'InvestigationType', id: arg.id },
                { type: 'InvestigationType', id: 'LIST' }
            ],
        }),
        deleteInvestigationType: builder.mutation({
            query: ({id}) => ({
                url: '/investigationTypes/deleteInvestigationType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'InvestigationType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetInvestigationTypesQuery,
    useCreateNewInvestigationTypeMutation,
    useUpdateInvestigationTypeMutation,
    useDeleteInvestigationTypeMutation,
} = investigationTypesApiSlice

// returns the query result object
export const selectInvestigationTypeResult = investigationTypesApiSlice.endpoints.getInvestigationTypes.select()

// create memoized selector
const selectInvestigationTypesData = createSelector(
    selectInvestigationTypeResult,
    investigationTypeResult => investigationTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllInvestigationTypes,
    selectById: selectInvestigationTypeById,
    selectIds: selectInvestigationTypeIds,
} = investigationTypesAdapter.getSelectors(state => selectInvestigationTypesData(state) ?? initialState)