import React, { useState, useEffect } from 'react'
import useTitle from "../../../hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
    PencilSquareIcon,
  } from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "../../../utils/EtContentEditorLight2"
import EtBtnFormBasic from "../../../components/common/buttons/EtBtnFormBasic"
import { useNavigate } from "react-router"

import { useCreateNewUserCreationMutation } from '@/features/userCreations/userCreationsApiSlice'
import { useGetCreationTypesQuery } from '@/features/creationTypes/creationTypesApiSlice'
import { useGetCreationAreaTypesQuery } from '@/features/creationAreaTypes/creationAreaTypesApiSlice'

// 10 Dec 2024
import { useGetCapabilitiesIndustryTypesQuery } from '@/features/capabilitiesIndustryTypes/capabilitiesIndustryTypesApiSlice'
import { useGetCapabilitiesDomainTypesQuery } from '@/features/capabilitiesDomainTypes/capabilitiesDomainTypesApiSlice'
import { useGetCapabilitiesFunctionTypesQuery } from '@/features/capabilitiesFunctionTypes/capabilitiesFunctionTypesApiSlice'
import { useGetCapabilitiesTechAreaTypesQuery } from '@/features/capabilitiesTechAreaTypes/capabilitiesTechAreaTypesApiSlice'
import { useGetCapabilitiesTechStackTypesQuery } from '@/features/capabilitiesTechStackTypes/capabilitiesTechStackTypesApiSlice'
import { useGetCapabilitiesSalesTypesQuery } from '@/features/capabilitiesSalesTypes/capabilitiesSalesTypesApiSlice'
import { useGetCapabilitiesServiceTypesQuery } from '@/features/capabilitiesServiceTypes/capabilitiesServiceTypesApiSlice'
import { useGetCapabilitiesAdvisoryTypesQuery } from '@/features/capabilitiesAdvisoryTypes/capabilitiesAdvisoryTypesApiSlice'

const EtCreateUserImprintCreationComp = ({
    userId, 
    userOrgId, 
    triggeringUrl, 
    onClose
  }) => {

  // First Created - 09 Dec 2024
  // Author - Atul Pandey
  // Inherited from the EtCreateUserImprintCapabilitiesIndustry component
  // Updated - 10 Dec 2024 - added capabilities<Focus>Types - to build userCreationFocusAreas array elements

  // 0 - Set Page title & variables
  useTitle('User Imprint Creations')

  // 1 - set page navigations
  const navigate = useNavigate()
  
  // 2a - set the mutation to create the new user imprint Role data
  const [createNewUserCreation, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewUserCreationMutation()

  // 2b - Get Data for building Creations
  const { data: creationTypesData} = useGetCreationTypesQuery()
  const { data: creationAreaTypesData} = useGetCreationAreaTypesQuery()
  // 10 Dec 2024
  const { data: industryTypesData} = useGetCapabilitiesIndustryTypesQuery()
  const { data: domainTypesData} = useGetCapabilitiesDomainTypesQuery()
  const { data: functionTypesData} = useGetCapabilitiesFunctionTypesQuery()
  const { data: techAreaTypesData} = useGetCapabilitiesTechAreaTypesQuery()
  const { data: techStackTypesData} = useGetCapabilitiesTechStackTypesQuery()
  const { data: salesTypesData} = useGetCapabilitiesSalesTypesQuery()
  const { data: serviceTypesData} = useGetCapabilitiesServiceTypesQuery()
  const { data: advisoryTypesData} = useGetCapabilitiesAdvisoryTypesQuery()

  const extractEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id]);
  };

  const creationTypes = extractEntitiesFromAdapter(creationTypesData);
  const creationAreaTypes = extractEntitiesFromAdapter(creationAreaTypesData);
  // 10 Dec 2024
  const industryTypes = extractEntitiesFromAdapter(industryTypesData);
  const domainTypes = extractEntitiesFromAdapter(domainTypesData);
  const functionTypes = extractEntitiesFromAdapter(functionTypesData);
  const techAreaTypes = extractEntitiesFromAdapter(techAreaTypesData);
  const techStackTypes = extractEntitiesFromAdapter(techStackTypesData);
  const salesTypes = extractEntitiesFromAdapter(salesTypesData);
  const serviceTypes = extractEntitiesFromAdapter(serviceTypesData);
  const advisoryTypes = extractEntitiesFromAdapter(advisoryTypesData);

  console.log('Creation Area Types:', creationAreaTypes)
  
  // 3 - Initialize Form Data State
  const [formData, setFormData] = useState({
    userCreationName: "",
    userCreationDesc: "",
    userCreationType: null,
    userCreationFocusAreas: [], // 10 Dec 2024
    userCreationNotes: [], // Array for creation notes
    aboutMe: "",
    pic: "",
    active: true, // Default active state
  });

  // 4 - State variables for the editor
  const [editorKey, setEditorKey] = useState('creationDesc-' + Date.now());
  const [editorValue, setEditorValue] = useState('');
  const [collapsedSections, setCollapsedSections] = useState({});

  // 5a - Convert Image to Base64
  const convertPicToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  // 5b - Handle Form Changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "pic") {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  // 5c - Toggle show / collapse each section 
  const toggleSection = (key) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };  

  // 6 - Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // For debugging
      console.log('Starting form submission...');
      console.log('Raw focus areas:', formData.userCreationFocusAreas);
  
      // Step 1: Convert the pic field to Base64 if it exists
      const picBase64 = formData.pic ? await convertPicToBase64(formData.pic) : undefined;
  
      // Step 2: Filter out incomplete focus areas
      const validFocusAreas = formData.userCreationFocusAreas.filter((focusArea) => {
        if (!focusArea.creationAreaType) return false;
        
        // Get the area type name from creationAreaTypes
        const areaType = creationAreaTypes.find(
          type => type._id === focusArea.creationAreaType
        );
        
        if (!areaType) return false;
        
        const areaTypeName = areaType.creationAreaTypeName;
        
        // For debugging
        console.log('Processing focus area:', {
          areaType,
          areaTypeName,
          hasSpecificType: focusArea[`creation${areaTypeName}Type`]
        });
        
        return areaTypeName === "General" || 
               focusArea[`creation${areaTypeName}Type`];
      });
  
      // For debugging
      console.log('Valid focus areas after filtering:', validFocusAreas);
  
      // Step 3: Process notes if they exist
      const processedNotes = formData.userCreationNotes
        .filter(note => note.userCreationNoteText.trim()) // Remove empty notes
        .map(note => ({
          userCreationNoteText: note.userCreationNoteText.trim(),
          userCreationNoteDate: note.userCreationNoteDate
        }));
  
      // Step 4: Construct the payload
      const payload = {
        userId,
        userOrgId,
        userCreationName: formData.userCreationName.trim() || undefined,
        userCreationDesc: formData.userCreationDesc.trim() || undefined,
        userCreationType: formData.userCreationType || undefined,
        userCreationFocusAreas: validFocusAreas.map((focusArea) => {
          const areaType = creationAreaTypes.find(
            type => type._id === focusArea.creationAreaType
          );
          const areaTypeName = areaType.creationAreaTypeName;
          
          return {
            creationAreaType: focusArea.creationAreaType,
            [`creation${areaTypeName}Type`]: 
              areaTypeName === "General" 
                ? undefined 
                : focusArea[`creation${areaTypeName}Type`],
            creationGeneralType:
              areaTypeName === "General" ? "All Purpose" : undefined,
          };
        }),
        userCreationNotes: processedNotes.length > 0 ? processedNotes : undefined,
        pic: picBase64 || undefined,
        active: true
      };
  
      // Remove any undefined values from payload
      Object.keys(payload).forEach(key => 
        payload[key] === undefined && delete payload[key]
      );
  
      // For debugging
      // console.log('Final payload:', payload);
  
      // Step 5: Submit the payload
      const response = await createNewUserCreation(payload).unwrap();
      console.log('Submission successful:', response);
  
      // Step 6: Reset the form upon success
      setFormData({
        userCreationName: "",
        userCreationDesc: "",
        userCreationType: null,
        userCreationFocusAreas: [],
        userCreationNotes: [],
        aboutMe: "",
        pic: "",
        active: true,
      });
  
      // Step 7: Handle navigation/closure
      if (onClose) {
        onClose();
      } else {
        navigate(triggeringUrl || "/userMagicHome");
      }
  
    } catch (error) {
      // Step 8: Handle errors
      console.error("Error submitting form:", error);
      // You might want to set an error state here or show a notification
    }
  };
  
  // 7 - Reset Form on Successful Submission
  useEffect(() => {
    if (isSuccess) {
      setFormData({
        userCreationName: "",
        userCreationDesc: "",
        userCreationType: null,
        userCreationFocusAreas: [], // 10 Dec 2024
        userCreationNotes: [], // Array for creation notes
        aboutMe: "",
        pic: "",
        active: true, // Default active state
      });

      if (onClose) {
        onClose();
      } else {
        navigate(triggeringUrl || "/userMagicHome");
      }
    }
  }, [isSuccess, navigate]);

  // 8 - Handle Clear
  const handleClear = () => {
    setFormData({
      userCreationName: "",
      userCreationDesc: "",
      userCreationType: null,
      userCreationFocusAreas: [], // 10 Dec 2024
      userCreationNotes: [], // Array for creation notes
      aboutMe: "",
      pic: "",
      active: true, // Default active state
    });
  };

  // 9 - Handle Cancel
  const handleCancel = () => {
    if (onClose) {
      onClose();
    } else {
      navigate(triggeringUrl || "/userMagicHome");
    }
  };

  // 10 - create Markup
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
    }
  
  
  return (
    <div className="mx-auto w-full m-1 p-1 bg-white rounded shadow-md shadow-black">
      {isLoading && <PulseLoader color={'#ea580c'} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">{error?.data?.message}</p>
      )}

      <p className="p-1 m-1 font-bold font-poppins text-xl">
        Create User Imprint Creation
      </p>

      <form onSubmit={handleSubmit} className="m-1 p-1 text-xs font-poppins">
         {/* 1. Creation Name */}
          <div id="userCreationName" className="bg-gray-100 rounded p-1 m-1">
              <label
                htmlFor="userCreationName"
                className="block text-gray-600 text-sm font-bold mb-1"
              >
                Creation Name
              </label>
              <input
                type="text"
                name="userCreationName"
                placeholder="Enter a creation name or title"
                value={formData.userCreationName}
                onChange={handleChange}
                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
              />
            </div>
            
         {/* 2. Creation Type */}
          <div id="creationType" className="bg-gray-100 rounded p-1 m-1">
            <label
              htmlFor="creationType"
              className="block text-gray-600 text-sm font-bold mb-1"
            >
              Creation Type
            </label>
            <select
              name="userCreationType"
              value={formData.userCreationType || ''}
              onChange={handleChange}
              className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
            >
              <option value="" disabled>
                Select a Creation Type
              </option>
              {creationTypes.map((type) => (
                <option key={type._id} value={type._id}>
                  {type.creationTypeName}
                </option>
              ))}
            </select>
          </div>

         {/* 3. Creation Focus Areas Section */}
          <div id="creationFocusAreas" className="border rounded p-2 m-2 bg-gray-50">
            <div className="flex justify-between items-center mb-2">
                <div className="flex justify-start items-center">
                  <button
                    type="button"
                    onClick={() => toggleSection("focusAreasSection")}
                    className="text-orange-600 font-bold m-1 p-1 rounded"
                  >
                    {collapsedSections["focusAreasSection"] ? (
                      <PlusIcon className="h-4 w-4 text-orange-600" />
                    ) : (
                      <MinusIcon className="h-4 w-4 text-orange-600" />
                    )}
                  </button>
                  <p className="font-bold text-sm mx-1 px-1"> Creation Focus Areas</p>
                </div>
                <div className="flex justify-start items-center">
                  <p className="font-bold text-xs mx-1 px-1">Add</p>
                  <button
                    type="button"
                    className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                    onClick={() => {
                      setFormData((prevState) => ({
                        ...prevState,
                        userCreationFocusAreas: [
                          {
                            creationAreaType: null,
                            creationIndustryType: null,
                            creationDomainType: null,
                            creationFunctionType: null,
                            creationTechAreaType: null,
                            creationTechStackType: null,
                            creationSalesType: null,
                            creationServiceType: null,
                            creationAdvisoryType: null,
                          },
                          ...(prevState.userCreationFocusAreas || []),
                        ],
                      }));
                      setCollapsedSections((prevState) => ({
                        ...Object.keys(prevState).reduce(
                          (acc, key) => ({ ...acc, [key]: true }), // Collapse all other sections
                          {}
                        ),
                        focusAreasSection: false, // Ensure the entire section is expanded
                        "focusArea-0": false, // Expand the new focus area
                      }));
                    }}
                  >
                    <PlusIcon className="h-4 w-4 inline" />
                  </button>
                </div>
            </div>

            {/* Map through userCreationFocusAreas */}
            <div className='grid grid-cols-3 justify-between items-start gap-1'>
              {!collapsedSections["focusAreasSection"] && formData.userCreationFocusAreas?.map((focusArea, focusIndex) => (
                <div key={focusIndex} className="w-full p-2 border rounded  bg-white">
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex justify-start items-center">
                      <button
                        type="button"
                        onClick={() => toggleSection(`focusArea-${focusIndex}`)}
                        className="text-orange-600 font-bold m-1 p-1 rounded"
                      >
                        {collapsedSections[`focusArea-${focusIndex}`] ? (
                          <PlusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                          <MinusIcon className="h-4 w-4 text-orange-600" />
                        )}
                      </button>
                      <p className="font-bold text-xs mx-1 px-1">Focus Area {focusIndex + 1}</p>
                    </div>

                    <div className="flex justify-start items-center">
                      <p className="font-bold text-xs mx-1 px-1">Remove</p>
                      <button
                        type="button"
                        className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                        onClick={() => {
                          setFormData((prevState) => ({
                            ...prevState,
                            userCreationFocusAreas: prevState.userCreationFocusAreas.filter((_, i) => i !== focusIndex),
                          }));
                        }}
                      >
                        <MinusIcon className="h-4 w-4 inline" />
                      </button>
                    </div>
                  </div>
                  
                  {!collapsedSections[`focusArea-${focusIndex}`] && (
                  <div>
                    {/* Dropdown for Creation Area Type */}
                    <div className="flex justify-between items-center bg-gray-100 rounded p-1 m-1">
                      <label htmlFor={`creationAreaType-${focusIndex}`} className="text-gray-600 text-[10px] font-bold w-1/3">
                        Area Type
                      </label>
                      <select
                        id={`creationAreaType-${focusIndex}`}
                        value={focusArea.creationAreaType || ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFormData((prevState) => ({
                            ...prevState,
                            userCreationFocusAreas: prevState.userCreationFocusAreas.map((area, index) => {
                              if (index === focusIndex) {
                                return {
                                  ...area,
                                  creationAreaType: value,
                                  // Reset other specific types when area type changes
                                  creationIndustryType: null,
                                  creationDomainType: null,
                                  creationFunctionType: null,
                                  creationTechAreaType: null,
                                  creationTechStackType: null,
                                  creationSalesType: null,
                                  creationServiceType: null,
                                  creationAdvisoryType: null,
                                };
                              }
                              return area;
                            }),
                          }));
                        }}
                        className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
                      >
                        <option value="" disabled>Select Area Type</option>
                        {creationAreaTypes.map((type) => (
                          <option key={type._id} value={type._id}>
                            {type.creationAreaTypeName}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Conditional Dropdown for Specific Type */}
                    {focusArea.creationAreaType && (() => {
                      const selectedCreationAreaTypeName = creationAreaTypes.find(
                        (type) => type._id === focusArea.creationAreaType
                      )?.creationAreaTypeName || "";

                      const isGeneral = selectedCreationAreaTypeName === "General";

                      return (
                        <div className="flex justify-between items-center bg-gray-100 rounded p-1 m-1">
                          <label htmlFor={`specificType-${focusIndex}`} className="text-gray-600 text-[10px] font-bold w-1/3">
                            Specific {selectedCreationAreaTypeName}
                          </label>
                          <select
                            id={`specificType-${focusIndex}`}
                            value={isGeneral ? focusArea.creationGeneralType || ""
                              : focusArea[`creation${selectedCreationAreaTypeName}Type`] || ""}
                            onChange={(e) => {
                              const value = e.target.value; // ObjectId of the selected specific type
                            
                              setFormData((prevState) => ({
                                ...prevState,
                                userCreationFocusAreas: prevState.userCreationFocusAreas.map((area, index) => {
                                  if (index === focusIndex) {
                                    return {
                                      ...area,
                                      [`creation${selectedCreationAreaTypeName}Type`]: value, // Correctly updating the specific type
                                    };
                                  }
                                  return area;
                                }),
                              }));
                            }}
                            
                                                  
                            className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
                          >
                            {/* Options dynamically populated */}

                                <option value="" disabled>
                                Select {selectedCreationAreaTypeName || "Type"}
                                </option>
                                {isGeneral ? (
                                  <option value="All Purpose">All Purpose</option>
                                ) : (
                                  (() => {
                                    const optionsMap = {
                                      Industry: industryTypes,
                                      Domain: domainTypes,
                                      Function: functionTypes,
                                      TechArea: techAreaTypes,
                                      TechStack: techStackTypes,
                                      Sales: salesTypes,
                                      Service: serviceTypes,
                                      Advisory: advisoryTypes,
                                    };
                                    return optionsMap[selectedCreationAreaTypeName]?.map((type) => (
                                      <option key={type._id} value={type._id}>
                                        {type[`capabilities${selectedCreationAreaTypeName}TypeName`]}
                                      </option>
                                    ));
                                  })()
                                )}
                              {/* {selectedCreationAreaTypeName === "Industry" &&
                                industryTypes?.map((type) => (
                                  <option key={type._id} value={type._id}>
                                    {type.capabilitiesIndustryTypeName}
                                  </option>
                                ))}
                              {selectedCreationAreaTypeName === "Domain" &&
                                domainTypes?.map((type) => (
                                  <option key={type._id} value={type._id}>
                                    {type.capabilitiesDomainTypeName}
                                  </option>
                                ))}
                              {selectedCreationAreaTypeName === "Function" &&
                                functionTypes?.map((type) => (
                                  <option key={type._id} value={type._id}>
                                    {type.capabilitiesFunctionTypeName}
                                  </option>
                                ))}
                              {selectedCreationAreaTypeName === "TechArea" &&
                                techAreaTypes?.map((type) => (
                                  <option key={type._id} value={type._id}>
                                    {type.capabilitiesTechAreaTypeName}
                                  </option>
                                ))}
                              {selectedCreationAreaTypeName === "TechStack" &&
                                techStackTypes?.map((type) => (
                                  <option key={type._id} value={type._id}>
                                    {type.capabilitiesTechStackTypeName}
                                  </option>
                                ))}
                              {selectedCreationAreaTypeName === "Sales" &&
                                salesTypes?.map((type) => (
                                  <option key={type._id} value={type._id}>
                                    {type.capabilitiesSalesTypeName}
                                  </option>
                                ))}
                              {selectedCreationAreaTypeName === "Service" &&
                                serviceTypes?.map((type) => (
                                  <option key={type._id} value={type._id}>
                                    {type.capabilitiesServiceTypeName}
                                  </option>
                                ))}
                              {selectedCreationAreaTypeName === "Advisory" &&
                                advisoryTypes?.map((type) => (
                                  <option key={type._id} value={type._id}>
                                    {type.capabilitiesAdvisoryTypeName}
                                  </option>
                                ))} */}

                          </select>
                        </div>
                      );
                    })()}
                  </div>
                  )}


                </div>
              ))}
            </div>
          </div>

         
         {/* 4. Creation Description */}
          <div id="creationDesc" className="bg-gray-100 rounded p-1 m-1">
            <label
              htmlFor="creationDesc"
              className="block text-gray-600 text-sm font-bold mb-1"
            >
              Creation Description
            </label>
            <EtContentEditorLight2
              value={formData.userCreationDesc || ""}
              handleChange={(content) => {
                setFormData((prevState) => ({
                  ...prevState,
                  userCreationDesc: content,
                }));
              }}
              placeholderText="Write your creation description here..."
              editorKey={editorKey}
            />
          </div>

         {/* 5. Creation Notes */}
          <div id="creationNotes" className="border rounded p-2 m-2 bg-gray-50">
            <div className="flex justify-between items-center mb-2">
              <p className="font-bold text-sm">Creation Notes</p>
              <div className="flex justify-start items-center">
                <button
                  type="button"
                  className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) => ({
                      ...prevState,
                      userCreationNotes: [
                        ...prevState.userCreationNotes,
                        { userCreationNoteText: "", userCreationNoteDate: new Date().toISOString() },
                      ],
                    }));
                  }}
                >
                  <PlusIcon className="h-4 w-4 inline" />
                </button>
              </div>
            </div>

            {formData.userCreationNotes.map((note, noteIndex) => (
              <div key={noteIndex} className="p-2 border rounded mb-2 bg-white">
                <div className="flex justify-between items-center">
                  <div className="flex justify-start items-center">
                    <button
                      type="button"
                      onClick={() => toggleSection(`creationNote-${noteIndex}`)}
                      className="text-orange-600 font-bold m-1 p-1 rounded"
                    >
                      {collapsedSections[`creationNote-${noteIndex}`] ? (
                        <PlusIcon className="h-4 w-4 text-orange-600" />
                      ) : (
                        <MinusIcon className="h-4 w-4 text-orange-600" />
                      )}
                    </button>
                    <p className="font-bold text-sm mx-1 px-1">Note {noteIndex + 1}</p>
                  </div>
                  <div className="flex justify-start items-center">
                    <button
                      type="button"
                      className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                      onClick={() => {
                        setFormData((prevState) => ({
                          ...prevState,
                          userCreationNotes: prevState.userCreationNotes.filter((_, i) => i !== noteIndex),
                        }));
                      }}
                    >
                      <MinusIcon className="h-4 w-4 inline" />
                    </button>
                  </div>
                </div>

                {!collapsedSections[`creationNote-${noteIndex}`] && (
                  <div>
                    {/* Note Text */}
                    <div className="bg-gray-100 rounded p-1 m-1">
                      <label
                        htmlFor={`noteText-${noteIndex}`}
                        className="block text-gray-600 text-sm font-bold mb-1"
                      >
                        Note Text
                      </label>
                      <EtContentEditorLight2
                        value={note.userCreationNoteText || ""}
                        handleChange={(content) => {
                          setFormData((prevState) => {
                            const updatedNotes = [...prevState.userCreationNotes];
                            updatedNotes[noteIndex].userCreationNoteText = content;
                            return { ...prevState, userCreationNotes: updatedNotes };
                          });
                        }}
                        placeholderText="Write your note here..."
                        editorKey={`creationNote-${noteIndex}`}
                      />
                    </div>

                    {/* Note Date (Read-only) */}
                    <p className="text-xs text-gray-500 mt-2">
                      Created on: {new Date(note.userCreationNoteDate).toLocaleDateString()}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>

         {/* 6. Form Submissions Bar */}
         <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic 
              name={'Create'} 
              type={'create'} 
              handleClick={handleSubmit} 
              />
            <EtBtnFormBasic
              name={'Clear'}
              type={'clear'}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={'Cancel'}
              type={'cancel'}
              handleClick={handleCancel}
            />
        </div>
      </form>

    </div>
  )
}

export default EtCreateUserImprintCreationComp