import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const capabilitiesFunctionTypesAdapter = createEntityAdapter({})

const initialState = capabilitiesFunctionTypesAdapter.getInitialState()

export const capabilitiesFunctionTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCapabilitiesFunctionTypes: builder.query({
            query: () => ({
                url: '/capabilitiesFunctionTypes/getCapabilitiesFunctionTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedCapabilitiesFunctionTypes = responseData.map(capabilitiesFunctionType => {
                    capabilitiesFunctionType.id = capabilitiesFunctionType._id
                    return capabilitiesFunctionType
                })
                return capabilitiesFunctionTypesAdapter.setAll(initialState, loadedCapabilitiesFunctionTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'CapabilitiesFunctionType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'CapabilitiesFunctionType', id}))
                    ]
                } else {
                    return [ { type: 'CapabilitiesFunctionType', id: 'LIST'}]
                }
            },
        }),
        createNewCapabilitiesFunctionType: builder.mutation({
            query: initialCapabilitiesFunctionType => ({
                url: '/capabilitiesFunctionTypes/createNewCapabilitiesFunctionType',
                method: 'POST',
                body: {...initialCapabilitiesFunctionType,}
            }),
            invalidatesTags: [
                { type: 'CapabilitiesFunctionType', id: "LIST" }
            ] 
        }),
        updateCapabilitiesFunctionType: builder.mutation({
            query: initialCapabilitiesFunctionType => ({
                url: '/capabilitiesFunctionTypes/updateCapabilitiesFunctionType',
                method: 'PATCH',
                body: {...initialCapabilitiesFunctionType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'CapabilitiesFunctionType', id: arg.id },
                { type: 'CapabilitiesFunctionType', id: 'LIST' }
            ],
        }),
        deleteCapabilitiesFunctionType: builder.mutation({
            query: ({id}) => ({
                url: '/capabilitiesFunctionTypes/deleteCapabilitiesFunctionType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'CapabilitiesFunctionType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetCapabilitiesFunctionTypesQuery,
    useCreateNewCapabilitiesFunctionTypeMutation,
    useUpdateCapabilitiesFunctionTypeMutation,
    useDeleteCapabilitiesFunctionTypeMutation,
} = capabilitiesFunctionTypesApiSlice

// returns the query result object
export const selectCapabilitiesFunctionTypeResult = capabilitiesFunctionTypesApiSlice.endpoints.getCapabilitiesFunctionTypes.select()

// create memoized selector
const selectCapabilitiesFunctionTypesData = createSelector(
    selectCapabilitiesFunctionTypeResult,
    capabilitiesFunctionTypeResult => capabilitiesFunctionTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCapabilitiesFunctionTypes,
    selectById: selectCapabilitiesFunctionTypeById,
    selectIds: selectCapabilitiesFunctionTypeIds,
} = capabilitiesFunctionTypesAdapter.getSelectors(state => selectCapabilitiesFunctionTypesData(state) ?? initialState)