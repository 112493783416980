import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userSessionInvestigationsAdapter = createEntityAdapter({})

const initialState = userSessionInvestigationsAdapter.getInitialState()

export const userSessionInvestigationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserSessionInvestigations: builder.query({
            query: () => ({
                url: '/userSessionInvestigations/getUserSessionInvestigations',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserSessionInvestigations = responseData.map(userSessionInvestigation => {
                    userSessionInvestigation.id = userSessionInvestigation._id
                    return userSessionInvestigation
                })
                return userSessionInvestigationsAdapter.setAll(initialState, loadedUserSessionInvestigations)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserSessionInvestigation', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserSessionInvestigation', id}))
                    ]
                } else {
                    return [ { type: 'UserSessionInvestigation', id: 'LIST'}]
                }
            },
        }),
        createNewUserSessionInvestigation: builder.mutation({
            query: initialUserSessionInvestigation => ({
                url: '/userSessionInvestigations/createNewUserSessionInvestigation',
                method: 'POST',
                body: {...initialUserSessionInvestigation,}
            }),
            invalidatesTags: [
                { type: 'UserSessionInvestigation', id: "LIST" }
            ] 
        }),
        updateUserSessionInvestigation: builder.mutation({
            query: initialUserSessionInvestigation => ({
                url: '/userSessionInvestigations/updateUserSessionInvestigation',
                method: 'PATCH',
                body: {...initialUserSessionInvestigation,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserSessionInvestigation', id: arg.id },
                { type: 'UserSessionInvestigation', id: 'LIST' }
            ],
        }),
        deleteUserSessionInvestigation: builder.mutation({
            query: ({id}) => ({
                url: '/userSessionInvestigations/deleteUserSessionInvestigation',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserSessionInvestigation', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserSessionInvestigationsQuery,
    useCreateNewUserSessionInvestigationMutation,
    useUpdateUserSessionInvestigationMutation,
    useDeleteUserSessionInvestigationMutation,
} = userSessionInvestigationsApiSlice

// returns the query result object
export const selectUserSessionInvestigationResult = userSessionInvestigationsApiSlice.endpoints.getUserSessionInvestigations.select()

// create memoized selector
const selectUserSessionInvestigationsData = createSelector(
    selectUserSessionInvestigationResult,
    userSessionInvestigationResult => userSessionInvestigationResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserSessionInvestigations,
    selectById: selectUserSessionInvestigationById,
    selectIds: selectUserSessionInvestigationIds,
} = userSessionInvestigationsAdapter.getSelectors(state => selectUserSessionInvestigationsData(state) ?? initialState)