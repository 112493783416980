// Author - Atul Pandey
// First Created - 26 Feb 2025
// Purpose - to feed entities for admin setup

// ActionType
import EtActionTypeList from '@/pages/admin/actionTypes/EtActionTypeList';
import EtCreateActionType from '@/pages/admin/actionTypes/EtCreateActionType';
import EtUpdateActionType from '@/pages/admin/actionTypes/EtUpdateActionType';
import EtDeleteActionType from '@/pages/admin/actionTypes/EtDeleteActionType';

// ContactType
import EtContactTypeList from '@/pages/admin/contactTypes/EtContactTypeList';
import EtCreateContactType from '@/pages/admin/contactTypes/EtCreateContactType';
import EtUpdateContactType from '@/pages/admin/contactTypes/EtUpdateContactType';
import EtDeleteContactType from '@/pages/admin/contactTypes/EtDeleteContactType';

// ChallengeType
import EtChallengeTypeList from '@/pages/admin/challengeTypes/EtChallengeTypeList';
import EtCreateChallengeType from '@/pages/admin/challengeTypes/EtCreateChallengeType';
import EtUpdateChallengeType from '@/pages/admin/challengeTypes/EtUpdateChallengeType';
import EtDeleteChallengeType from '@/pages/admin/challengeTypes/EtDeleteChallengeType';

// ContributionType
import EtContributionTypeList from '@/pages/admin/contributionTypes/EtContributionTypeList';
import EtCreateContributionType from '@/pages/admin/contributionTypes/EtCreateContributionType';
import EtUpdateContributionType from '@/pages/admin/contributionTypes/EtUpdateContributionType';
import EtDeleteContributionType from '@/pages/admin/contributionTypes/EtDeleteContributionType';

// CreationAreaType - 27 Feb 2025
import EtCreationAreaTypeList from '@/pages/admin/creationAreaTypes/EtCreationAreaTypeList';
import EtCreateCreationAreaType from '@/pages/admin/creationAreaTypes/EtCreateCreationAreaType';
import EtUpdateCreationAreaType from '@/pages/admin/creationAreaTypes/EtUpdateCreationAreaType';
import EtDeleteCreationAreaType from '@/pages/admin/creationAreaTypes/EtDeleteCreationAreaType';

// CreationType - 27 Feb 2025
import EtCreationTypeList from '@/pages/admin/creationTypes/EtCreationTypeList';
import EtCreateCreationType from '@/pages/admin/creationTypes/EtCreateCreationType';
import EtUpdateCreationType from '@/pages/admin/creationTypes/EtUpdateCreationType';
import EtDeleteCreationType from '@/pages/admin/creationTypes/EtDeleteCreationType';

// GratitudeType - 27 Feb 2025
import EtGratitudeTypeList from '@/pages/admin/gratitudeTypes/EtGratitudeTypeList';
import EtCreateGratitudeType from '@/pages/admin/gratitudeTypes/EtCreateGratitudeType';
import EtUpdateGratitudeType from '@/pages/admin/gratitudeTypes/EtUpdateGratitudeType';
import EtDeleteGratitudeType from '@/pages/admin/gratitudeTypes/EtDeleteGratitudeType';

// ImpactType - 27 Feb 2025
import EtImpactTypeList from '@/pages/admin/impactTypes/EtImpactTypeList';
import EtCreateImpactType from '@/pages/admin/impactTypes/EtCreateImpactType';
import EtUpdateImpactType from '@/pages/admin/impactTypes/EtUpdateImpactType';
import EtDeleteImpactType from '@/pages/admin/impactTypes/EtDeleteImpactType';

// InspirationBeaconType - 27 Feb 2025
import EtInspirationBeaconTypeList from '@/pages/admin/inspirationBeaconTypes/EtInspirationBeaconTypeList';
import EtCreateInspirationBeaconType from '@/pages/admin/inspirationBeaconTypes/EtCreateInspirationBeaconType';
import EtUpdateInspirationBeaconType from '@/pages/admin/inspirationBeaconTypes/EtUpdateInspirationBeaconType';
import EtDeleteInspirationBeaconType from '@/pages/admin/inspirationBeaconTypes/EtDeleteInspirationBeaconType';

// IntentionType - 27 Feb 2025
import EtIntentionTypeList from '@/pages/admin/intentionTypes/EtIntentionTypeList';
import EtCreateIntentionType from '@/pages/admin/intentionTypes/EtCreateIntentionType';
import EtUpdateIntentionType from '@/pages/admin/intentionTypes/EtUpdateIntentionType';
import EtDeleteIntentionType from '@/pages/admin/intentionTypes/EtDeleteIntentionType';

// InvestigationType - 27 Feb 2025
import EtInvestigationTypeList from '@/pages/admin/investigationTypes/EtInvestigationTypeList';
import EtCreateInvestigationType from '@/pages/admin/investigationTypes/EtCreateInvestigationType';
import EtUpdateInvestigationType from '@/pages/admin/investigationTypes/EtUpdateInvestigationType';
import EtDeleteInvestigationType from '@/pages/admin/investigationTypes/EtDeleteInvestigationType';

// LegacyType - 27 Feb 2025
import EtLegacyTypeList from '@/pages/admin/legacyTypes/EtLegacyTypeList';
import EtCreateLegacyType from '@/pages/admin/legacyTypes/EtCreateLegacyType';
import EtUpdateLegacyType from '@/pages/admin/legacyTypes/EtUpdateLegacyType';
import EtDeleteLegacyType from '@/pages/admin/legacyTypes/EtDeleteLegacyType';

// MissionMetricGradationType - 27 Feb 2025
import EtMissionMetricGradationTypeList from '@/pages/admin/missionMetricGradationTypes/EtMissionMetricGradationTypeList';
import EtCreateMissionMetricGradationType from '@/pages/admin/missionMetricGradationTypes/EtCreateMissionMetricGradationType';
import EtUpdateMissionMetricGradationType from '@/pages/admin/missionMetricGradationTypes/EtUpdateMissionMetricGradationType';
import EtDeleteMissionMetricGradationType from '@/pages/admin/missionMetricGradationTypes/EtDeleteMissionMetricGradationType';

// MissionMetricUoMType - 27 Feb 2025
import EtMissionMetricUoMTypeList from '@/pages/admin/missionMetricUoMTypes/EtMissionMetricUoMTypeList';
import EtCreateMissionMetricUoMType from '@/pages/admin/missionMetricUoMTypes/EtCreateMissionMetricUoMType';
import EtUpdateMissionMetricUoMType from '@/pages/admin/missionMetricUoMTypes/EtUpdateMissionMetricUoMType';
import EtDeleteMissionMetricUoMType from '@/pages/admin/missionMetricUoMTypes/EtDeleteMissionMetricUoMType';

// OrganizationType - 27 Feb 2025
import EtOrganizationTypeList from '@/pages/admin/organizationTypes/EtOrganizationTypeList';
import EtCreateOrganizationType from '@/pages/admin/organizationTypes/EtCreateOrganizationType';
import EtUpdateOrganizationType from '@/pages/admin/organizationTypes/EtUpdateOrganizationType';
import EtDeleteOrganizationType from '@/pages/admin/organizationTypes/EtDeleteOrganizationType';

// ProblemAreaType - 27 Feb 2025
import EtProblemAreaTypeList from '@/pages/admin/problemAreaTypes/EtProblemAreaTypeList';
import EtCreateProblemAreaType from '@/pages/admin/problemAreaTypes/EtCreateProblemAreaType';
import EtUpdateProblemAreaType from '@/pages/admin/problemAreaTypes/EtUpdateProblemAreaType';
import EtDeleteProblemAreaType from '@/pages/admin/problemAreaTypes/EtDeleteProblemAreaType';

// ProblemMetricGradationType - 27 Feb 2025
import EtProblemMetricGradationTypeList from '@/pages/admin/problemMetricGradationTypes/EtProblemMetricGradationTypeList';
import EtCreateProblemMetricGradationType from '@/pages/admin/problemMetricGradationTypes/EtCreateProblemMetricGradationType';
import EtUpdateProblemMetricGradationType from '@/pages/admin/problemMetricGradationTypes/EtUpdateProblemMetricGradationType';
import EtDeleteProblemMetricGradationType from '@/pages/admin/problemMetricGradationTypes/EtDeleteProblemMetricGradationType';

// ProblemMetricType - 27 Feb 2025
import EtProblemMetricTypeList from '@/pages/admin/problemMetricTypes/EtProblemMetricTypeList';
import EtCreateProblemMetricType from '@/pages/admin/problemMetricTypes/EtCreateProblemMetricType';
import EtUpdateProblemMetricType from '@/pages/admin/problemMetricTypes/EtUpdateProblemMetricType';
import EtDeleteProblemMetricType from '@/pages/admin/problemMetricTypes/EtDeleteProblemMetricType';

// ProblemMetricUoMType - 27 Feb 2025
import EtProblemMetricUoMTypeList from '@/pages/admin/problemMetricUoMTypes/EtProblemMetricUoMTypeList';
import EtCreateProblemMetricUoMType from '@/pages/admin/problemMetricUoMTypes/EtCreateProblemMetricUoMType';
import EtUpdateProblemMetricUoMType from '@/pages/admin/problemMetricUoMTypes/EtUpdateProblemMetricUoMType';
import EtDeleteProblemMetricUoMType from '@/pages/admin/problemMetricUoMTypes/EtDeleteProblemMetricUoMType';

// SessionType - 27 Feb 2025
import EtSessionTypeList from '@/pages/admin/sessionTypes/EtSessionTypeList';
import EtCreateSessionType from '@/pages/admin/sessionTypes/EtCreateSessionType';
import EtUpdateSessionType from '@/pages/admin/sessionTypes/EtUpdateSessionType';
import EtDeleteSessionType from '@/pages/admin/sessionTypes/EtDeleteSessionType';

// ShowcaseType - 27 Feb 2025
import EtShowcaseTypeList from '@/pages/admin/showcaseTypes/EtShowcaseTypeList';
import EtCreateShowcaseType from '@/pages/admin/showcaseTypes/EtCreateShowcaseType';
import EtUpdateShowcaseType from '@/pages/admin/showcaseTypes/EtUpdateShowcaseType';
import EtDeleteShowcaseType from '@/pages/admin/showcaseTypes/EtDeleteShowcaseType';

// UserChallengeType - 27 Feb 2025
import EtUserChallengeTypeList from '@/pages/admin/userChallengeTypes/EtUserChallengeTypeList';
import EtCreateUserChallengeType from '@/pages/admin/userChallengeTypes/EtCreateUserChallengeType';
import EtUpdateUserChallengeType from '@/pages/admin/userChallengeTypes/EtUpdateUserChallengeType';
import EtDeleteUserChallengeType from '@/pages/admin/userChallengeTypes/EtDeleteUserChallengeType';

// WinType - 27 Feb 2025
import EtWinTypeList from '@/pages/admin/winTypes/EtWinTypeList';
import EtCreateWinType from '@/pages/admin/winTypes/EtCreateWinType';
import EtUpdateWinType from '@/pages/admin/winTypes/EtUpdateWinType';
import EtDeleteWinType from '@/pages/admin/winTypes/EtDeleteWinType';

// CapabilitiesAdvisoryType - 27 Feb 2025
import EtCapabilitiesAdvisoryTypeList from '@/pages/admin/capabilitiesAdvisoryTypes/EtCapabilitiesAdvisoryTypeList';
import EtCreateCapabilitiesAdvisoryType from '@/pages/admin/capabilitiesAdvisoryTypes/EtCreateCapabilitiesAdvisoryType';
import EtUpdateCapabilitiesAdvisoryType from '@/pages/admin/capabilitiesAdvisoryTypes/EtUpdateCapabilitiesAdvisoryType';
import EtDeleteCapabilitiesAdvisoryType from '@/pages/admin/capabilitiesAdvisoryTypes/EtDeleteCapabilitiesAdvisoryType';

// CapabilitiesDomainType - 27 Feb 2025
import EtCapabilitiesDomainTypeList from '@/pages/admin/capabilitiesDomainTypes/EtCapabilitiesDomainTypeList';
import EtCreateCapabilitiesDomainType from '@/pages/admin/capabilitiesDomainTypes/EtCreateCapabilitiesDomainType';
import EtUpdateCapabilitiesDomainType from '@/pages/admin/capabilitiesDomainTypes/EtUpdateCapabilitiesDomainType';
import EtDeleteCapabilitiesDomainType from '@/pages/admin/capabilitiesDomainTypes/EtDeleteCapabilitiesDomainType';

// CapabilitiesFunctionType - 27 Feb 2025
import EtCapabilitiesFunctionTypeList from '@/pages/admin/capabilitiesFunctionTypes/EtCapabilitiesFunctionTypeList';
import EtCreateCapabilitiesFunctionType from '@/pages/admin/capabilitiesFunctionTypes/EtCreateCapabilitiesFunctionType';
import EtUpdateCapabilitiesFunctionType from '@/pages/admin/capabilitiesFunctionTypes/EtUpdateCapabilitiesFunctionType';
import EtDeleteCapabilitiesFunctionType from '@/pages/admin/capabilitiesFunctionTypes/EtDeleteCapabilitiesFunctionType';

// CapabilitiesIndustryType - 27 Feb 2025
import EtCapabilitiesIndustryTypeList from '@/pages/admin/capabilitiesIndustryTypes/EtCapabilitiesIndustryTypeList';
import EtCreateCapabilitiesIndustryType from '@/pages/admin/capabilitiesIndustryTypes/EtCreateCapabilitiesIndustryType';
import EtUpdateCapabilitiesIndustryType from '@/pages/admin/capabilitiesIndustryTypes/EtUpdateCapabilitiesIndustryType';
import EtDeleteCapabilitiesIndustryType from '@/pages/admin/capabilitiesIndustryTypes/EtDeleteCapabilitiesIndustryType';

// CapabilitiesSalesFunctionAreaType - 27 Feb 2025
import EtCapabilitiesSalesFunctionAreaTypeList from '@/pages/admin/capabilitiesSalesFunctionAreaTypes/EtCapabilitiesSalesFunctionAreaTypeList';
import EtCreateCapabilitiesSalesFunctionAreaType from '@/pages/admin/capabilitiesSalesFunctionAreaTypes/EtCreateCapabilitiesSalesFunctionAreaType';
import EtUpdateCapabilitiesSalesFunctionAreaType from '@/pages/admin/capabilitiesSalesFunctionAreaTypes/EtUpdateCapabilitiesSalesFunctionAreaType';
import EtDeleteCapabilitiesSalesFunctionAreaType from '@/pages/admin/capabilitiesSalesFunctionAreaTypes/EtDeleteCapabilitiesSalesFunctionAreaType';

// CapabilitiesSalesType - 27 Feb 2025
import EtCapabilitiesSalesTypeList from '@/pages/admin/capabilitiesSalesTypes/EtCapabilitiesSalesTypeList';
import EtCreateCapabilitiesSalesType from '@/pages/admin/capabilitiesSalesTypes/EtCreateCapabilitiesSalesType';
import EtUpdateCapabilitiesSalesType from '@/pages/admin/capabilitiesSalesTypes/EtUpdateCapabilitiesSalesType';
import EtDeleteCapabilitiesSalesType from '@/pages/admin/capabilitiesSalesTypes/EtDeleteCapabilitiesSalesType';

// CapabilitiesServiceFunctionAreaType - 27 Feb 2025
import EtCapabilitiesServiceFunctionAreaTypeList from '@/pages/admin/capabilitiesServiceFunctionAreaTypes/EtCapabilitiesServiceFunctionAreaTypeList';
import EtCreateCapabilitiesServiceFunctionAreaType from '@/pages/admin/capabilitiesServiceFunctionAreaTypes/EtCreateCapabilitiesServiceFunctionAreaType';
import EtUpdateCapabilitiesServiceFunctionAreaType from '@/pages/admin/capabilitiesServiceFunctionAreaTypes/EtUpdateCapabilitiesServiceFunctionAreaType';
import EtDeleteCapabilitiesServiceFunctionAreaType from '@/pages/admin/capabilitiesServiceFunctionAreaTypes/EtDeleteCapabilitiesServiceFunctionAreaType';

// CapabilitiesServiceType - 27 Feb 2025
import EtCapabilitiesServiceTypeList from '@/pages/admin/capabilitiesServiceTypes/EtCapabilitiesServiceTypeList';
import EtCreateCapabilitiesServiceType from '@/pages/admin/capabilitiesServiceTypes/EtCreateCapabilitiesServiceType';
import EtUpdateCapabilitiesServiceType from '@/pages/admin/capabilitiesServiceTypes/EtUpdateCapabilitiesServiceType';
import EtDeleteCapabilitiesServiceType from '@/pages/admin/capabilitiesServiceTypes/EtDeleteCapabilitiesServiceType';

// CapabilitiesSoftSkillFunctionAreaType - 27 Feb 2025
import EtCapabilitiesSoftSkillFunctionAreaTypeList from '@/pages/admin/capabilitiesSoftSkillFunctionAreaTypes/EtCapabilitiesSoftSkillFunctionAreaTypeList';
import EtCreateCapabilitiesSoftSkillFunctionAreaType from '@/pages/admin/capabilitiesSoftSkillFunctionAreaTypes/EtCreateCapabilitiesSoftSkillFunctionAreaType';
import EtUpdateCapabilitiesSoftSkillFunctionAreaType from '@/pages/admin/capabilitiesSoftSkillFunctionAreaTypes/EtUpdateCapabilitiesSoftSkillFunctionAreaType';
import EtDeleteCapabilitiesSoftSkillFunctionAreaType from '@/pages/admin/capabilitiesSoftSkillFunctionAreaTypes/EtDeleteCapabilitiesSoftSkillFunctionAreaType';

// CapabilitiesSoftSkillType - 27 Feb 2025
import EtCapabilitiesSoftSkillTypeList from '@/pages/admin/capabilitiesSoftSkillTypes/EtCapabilitiesSoftSkillTypeList';
import EtCreateCapabilitiesSoftSkillType from '@/pages/admin/capabilitiesSoftSkillTypes/EtCreateCapabilitiesSoftSkillType';
import EtUpdateCapabilitiesSoftSkillType from '@/pages/admin/capabilitiesSoftSkillTypes/EtUpdateCapabilitiesSoftSkillType';
import EtDeleteCapabilitiesSoftSkillType from '@/pages/admin/capabilitiesSoftSkillTypes/EtDeleteCapabilitiesSoftSkillType';

// CapabilitiesTechAreaType - 28 Feb 2025
import EtCapabilitiesTechAreaTypeList from '@/pages/admin/capabilitiesTechAreaTypes/EtCapabilitiesTechAreaTypeList';
import EtCreateCapabilitiesTechAreaType from '@/pages/admin/capabilitiesTechAreaTypes/EtCreateCapabilitiesTechAreaType';
import EtUpdateCapabilitiesTechAreaType from '@/pages/admin/capabilitiesTechAreaTypes/EtUpdateCapabilitiesTechAreaType';
import EtDeleteCapabilitiesTechAreaType from '@/pages/admin/capabilitiesTechAreaTypes/EtDeleteCapabilitiesTechAreaType';

// CapabilitiesTechStackType - 28 Feb 2025
import EtCapabilitiesTechStackTypeList from '@/pages/admin/capabilitiesTechStackTypes/EtCapabilitiesTechStackTypeList';
import EtCreateCapabilitiesTechStackType from '@/pages/admin/capabilitiesTechStackTypes/EtCreateCapabilitiesTechStackType';
import EtUpdateCapabilitiesTechStackType from '@/pages/admin/capabilitiesTechStackTypes/EtUpdateCapabilitiesTechStackType';
import EtDeleteCapabilitiesTechStackType from '@/pages/admin/capabilitiesTechStackTypes/EtDeleteCapabilitiesTechStackType';

// ChallengeHead - 28 Feb 2025
import EtChallengeHeadList from '@/pages/admin/challengeHeads/EtChallengeHeadList';
import EtCreateChallengeHead from '@/pages/admin/challengeHeads/EtCreateChallengeHead';
import EtUpdateChallengeHead from '@/pages/admin/challengeHeads/EtUpdateChallengeHead';
import EtDeleteChallengeHead from '@/pages/admin/challengeHeads/EtDeleteChallengeHead';

// Challenge - 28 Feb 2025
import EtChallengeList from '@/pages/admin/challenges/EtChallengeList';
import EtCreateChallenge from '@/pages/admin/challenges/EtCreateChallenge';
import EtUpdateChallenge from '@/pages/admin/challenges/EtUpdateChallenge';
import EtDeleteChallenge from '@/pages/admin/challenges/EtDeleteChallenge';

// Challenge - 28 Feb 2025
import EtContactOrganizationTypeList from '@/pages/admin/contactOrganizationTypes/EtContactOrganizationTypeList';
import EtCreateContactOrganizationType from '@/pages/admin/contactOrganizationTypes/EtCreateContactOrganizationType';
import EtUpdateContactOrganizationType from '@/pages/admin/contactOrganizationTypes/EtUpdateContactOrganizationType';
import EtDeleteContactOrganizationType from '@/pages/admin/contactOrganizationTypes/EtDeleteContactOrganizationType';

// Question - 28 Feb 2025
import EtQuestionList from '@/pages/admin/questions/EtQuestionList';
import EtCreateQuestion from '@/pages/admin/questions/EtCreateQuestion';
import EtUpdateQuestion from '@/pages/admin/questions/EtUpdateQuestion';
import EtDeleteQuestion from '@/pages/admin/questions/EtDeleteQuestion';

// QuestionSet - 28 Feb 2025
import EtQuestionSetList from '@/pages/admin/questionSets/EtQuestionSetList';
import EtCreateQuestionSet from '@/pages/admin/questionSets/EtCreateQuestionSet';
import EtUpdateQuestionSet from '@/pages/admin/questionSets/EtUpdateQuestionSet';
import EtDeleteQuestionSet from '@/pages/admin/questionSets/EtDeleteQuestionSet';

// QuestionWithScores - 01 Mar 2025
import EtQuestionWithScoresList from '@/pages/admin/questionwithscores/EtQuestionWithScoresList';
import EtCreateQuestionWithScores from '@/pages/admin/questionwithscores/EtCreateQuestionWithScores';
import EtUpdateQuestionWithScores from '@/pages/admin/questionwithscores/EtUpdateQuestionWithScores';
import EtDeleteQuestionWithScores from '@/pages/admin/questionwithscores/EtDeleteQuestionWithScores';

// Section - 01 Mar 2025
import EtSectionList from '@/pages/admin/sections/EtSectionList';
import EtCreateSection from '@/pages/admin/sections/EtCreateSection';
import EtUpdateSection from '@/pages/admin/sections/EtUpdateSection';
import EtDeleteSection from '@/pages/admin/sections/EtDeleteSection';

// Session - 01 Mar 2025
import EtSessionList from '@/pages/admin/sessions/EtSessionList';
import EtCreateSession from '@/pages/admin/sessions/EtCreateSession';
import EtUpdateSession from '@/pages/admin/sessions/EtUpdateSession';
import EtDeleteSession from '@/pages/admin/sessions/EtDeleteSession';

// SubsPack - 01 Mar 2025
import EtSubsPackList from '@/pages/admin/subsPacks/EtSubsPackList';
import EtCreateSubsPack from '@/pages/admin/subsPacks/EtCreateSubsPack';
import EtUpdateSubsPack from '@/pages/admin/subsPacks/EtUpdateSubsPack';
import EtDeleteSubsPack from '@/pages/admin/subsPacks/EtDeleteSubsPack';

// OrgSubscription - 01 Mar 2025
import EtOrgSubscriptionList from '@/pages/admin/orgSubscriptions/EtOrgSubscriptionList';
import EtCreateOrgSubscription from '@/pages/admin/orgSubscriptions/EtCreateOrgSubscription';
import EtUpdateOrgSubscription from '@/pages/admin/orgSubscriptions/EtUpdateOrgSubscription';
import EtDeleteOrgSubscription from '@/pages/admin/orgSubscriptions/EtDeleteOrgSubscription';

// Trail - 01 Mar 2025
import EtTrailList from '@/pages/admin/trails/EtTrailList';
import EtCreateTrail from '@/pages/admin/trails/EtCreateTrail';
import EtUpdateTrail from '@/pages/admin/trails/EtUpdateTrail';
import EtDeleteTrail from '@/pages/admin/trails/EtDeleteTrail';

// Organization - 01 Mar 2025
import EtOrganizationList from '@/pages/admin/organizations/EtOrganizationList';
import EtCreateOrganization from '@/pages/admin/organizations/EtCreateOrganization';
import EtUpdateOrganization from '@/pages/admin/organizations/EtUpdateOrganization';
import EtDeleteOrganization from '@/pages/admin/organizations/EtDeleteOrganization';

// User - 02 Mar 2025
import EtUserList from '@/pages/admin/users/EtUserList';
import EtCreateUser from '@/pages/admin/users/EtCreateUser';
import EtUpdateUser from '@/pages/admin/users/EtUpdateUser';
import EtDeleteUser from '@/pages/admin/users/EtDeleteUser';

// OrgRole - 03 Mar 2025
import EtOrgRoleList from '@/pages/admin/orgRoles/EtOrgRoleList';
import EtCreateOrgRole from '@/pages/admin/orgRoles/EtCreateOrgRole';
import EtUpdateOrgRole from '@/pages/admin/orgRoles/EtUpdateOrgRole';
import EtDeleteOrgRole from '@/pages/admin/orgRoles/EtDeleteOrgRole';

// OrgTitle - 03 Mar 2025
import EtOrgTitleList from '@/pages/admin/orgTitles/EtOrgTitleList';
import EtCreateOrgTitle from '@/pages/admin/orgTitles/EtCreateOrgTitle';
import EtUpdateOrgTitle from '@/pages/admin/orgTitles/EtUpdateOrgTitle';
import EtDeleteOrgTitle from '@/pages/admin/orgTitles/EtDeleteOrgTitle';

// CoachUserPad - 04 Mar 2025
import EtCoachUserPadList from '@/pages/admin/coachUserPads/EtCoachUserPadList';
import EtCreateCoachUserPad from '@/pages/admin/coachUserPads/EtCreateCoachUserPad';
import EtUpdateCoachUserPad from '@/pages/admin/coachUserPads/EtUpdateCoachUserPad';
import EtDeleteCoachUserPad from '@/pages/admin/coachUserPads/EtDeleteCoachUserPad';

// SponsorUserPad - 04 Mar 2025
import EtSponsorUserPadList from '@/pages/admin/sponsorUserPads/EtSponsorUserPadList';
import EtCreateSponsorUserPad from '@/pages/admin/sponsorUserPads/EtCreateSponsorUserPad';
import EtUpdateSponsorUserPad from '@/pages/admin/sponsorUserPads/EtUpdateSponsorUserPad';
import EtDeleteSponsorUserPad from '@/pages/admin/sponsorUserPads/EtDeleteSponsorUserPad';

// ContactOrganization - 07 Mar 2025
import EtContactOrganizationList from '@/pages/admin/contactOrganizations/EtContactOrganizationList';
import EtCreateContactOrganization from '@/pages/admin/contactOrganizations/EtCreateContactOrganization';
import EtUpdateContactOrganization from '@/pages/admin/contactOrganizations/EtUpdateContactOrganization';
import EtDeleteContactOrganization from '@/pages/admin/contactOrganizations/EtDeleteContactOrganization';

// Contact - 07 Mar 2025
import EtContactList from '@/pages/admin/contacts/EtContactList';
import EtCreateContact from '@/pages/admin/contacts/EtCreateContact';
import EtUpdateContact from '@/pages/admin/contacts/EtUpdateContact';
import EtDeleteContact from '@/pages/admin/contacts/EtDeleteContact';


const entityMap = {
    
    coachUserPads: {
        list: EtCoachUserPadList,
        create: EtCreateCoachUserPad,
        update: EtUpdateCoachUserPad,
        delete: EtDeleteCoachUserPad,
    },
    sponsorUserPads: {
        list: EtSponsorUserPadList,
        create: EtCreateSponsorUserPad,
        update: EtUpdateSponsorUserPad,
        delete: EtDeleteSponsorUserPad,
    },
    actionTypes: {
        list: EtActionTypeList,
        create: EtCreateActionType,
        update: EtUpdateActionType,
        delete: EtDeleteActionType,
    },
    capabilitiesAdvisoryTypes: {
        list: EtCapabilitiesAdvisoryTypeList,
        create: EtCreateCapabilitiesAdvisoryType,
        update: EtUpdateCapabilitiesAdvisoryType,
        delete: EtDeleteCapabilitiesAdvisoryType,
    },
    capabilitiesDomainTypes: {
        list: EtCapabilitiesDomainTypeList,
        create: EtCreateCapabilitiesDomainType,
        update: EtUpdateCapabilitiesDomainType,
        delete: EtDeleteCapabilitiesDomainType,
    },
    capabilitiesFunctionTypes: {
        list: EtCapabilitiesFunctionTypeList,
        create: EtCreateCapabilitiesFunctionType,
        update: EtUpdateCapabilitiesFunctionType,
        delete: EtDeleteCapabilitiesFunctionType,
    },
    capabilitiesIndustryTypes: {
        list: EtCapabilitiesIndustryTypeList,
        create: EtCreateCapabilitiesIndustryType,
        update: EtUpdateCapabilitiesIndustryType,
        delete: EtDeleteCapabilitiesIndustryType,
    },
    capabilitiesSalesFunctionAreaTypes: {
        list: EtCapabilitiesSalesFunctionAreaTypeList,
        create: EtCreateCapabilitiesSalesFunctionAreaType,
        update: EtUpdateCapabilitiesSalesFunctionAreaType,
        delete: EtDeleteCapabilitiesSalesFunctionAreaType,
    },
    capabilitiesSalesTypes: {
        list: EtCapabilitiesSalesTypeList,
        create: EtCreateCapabilitiesSalesType,
        update: EtUpdateCapabilitiesSalesType,
        delete: EtDeleteCapabilitiesSalesType,
    },
    capabilitiesServiceFunctionAreaTypes: {
        list: EtCapabilitiesServiceFunctionAreaTypeList,
        create: EtCreateCapabilitiesServiceFunctionAreaType,
        update: EtUpdateCapabilitiesServiceFunctionAreaType,
        delete: EtDeleteCapabilitiesServiceFunctionAreaType,
    },
    capabilitiesServiceTypes: {
        list: EtCapabilitiesServiceTypeList,
        create: EtCreateCapabilitiesServiceType,
        update: EtUpdateCapabilitiesServiceType,
        delete: EtDeleteCapabilitiesServiceType,
    },
    capabilitiesSoftSkillFunctionAreaTypes: {
        list: EtCapabilitiesSoftSkillFunctionAreaTypeList,
        create: EtCreateCapabilitiesSoftSkillFunctionAreaType,
        update: EtUpdateCapabilitiesSoftSkillFunctionAreaType,
        delete: EtDeleteCapabilitiesSoftSkillFunctionAreaType,
    },
    capabilitiesSoftSkillTypes: {
        list: EtCapabilitiesSoftSkillTypeList,
        create: EtCreateCapabilitiesSoftSkillType,
        update: EtUpdateCapabilitiesSoftSkillType,
        delete: EtDeleteCapabilitiesSoftSkillType,
    },
    capabilitiesTechAreaTypes: {
        list: EtCapabilitiesTechAreaTypeList,
        create: EtCreateCapabilitiesTechAreaType,
        update: EtUpdateCapabilitiesTechAreaType,
        delete: EtDeleteCapabilitiesTechAreaType,
    },
    capabilitiesTechStackTypes: {
        list: EtCapabilitiesTechStackTypeList,
        create: EtCreateCapabilitiesTechStackType,
        update: EtUpdateCapabilitiesTechStackType,
        delete: EtDeleteCapabilitiesTechStackType,
    },
    challengeHeads: {
        list: EtChallengeHeadList,
        create: EtCreateChallengeHead,
        update: EtUpdateChallengeHead,
        delete: EtDeleteChallengeHead,
    },
    challenges: {
        list: EtChallengeList,
        create: EtCreateChallenge,
        update: EtUpdateChallenge,
        delete: EtDeleteChallenge,
    },
    contacts: {
        list: EtContactList,
        create: EtCreateContact,
        update: EtUpdateContact,
        delete: EtDeleteContact,
    },
    contactOrganizations: {
        list: EtContactOrganizationList,
        create: EtCreateContactOrganization,
        update: EtUpdateContactOrganization,
        delete: EtDeleteContactOrganization,
    },
    contactOrganizationTypes: {
        list: EtContactOrganizationTypeList,
        create: EtCreateContactOrganizationType,
        update: EtUpdateContactOrganizationType,
        delete: EtDeleteContactOrganizationType,
    },
    contactTypes: {
        list: EtContactTypeList,
        create: EtCreateContactType,
        update: EtUpdateContactType,
        delete: EtDeleteContactType,
    },
    challengeTypes: {
        list: EtChallengeTypeList,
        create: EtCreateChallengeType,
        update: EtUpdateChallengeType,
        delete: EtDeleteChallengeType,
    },
    contributionTypes: {
        list: EtContributionTypeList,
        create: EtCreateContributionType,
        update: EtUpdateContributionType,
        delete: EtDeleteContributionType,
    },
    creationAreaTypes: {
        list: EtCreationAreaTypeList,
        create: EtCreateCreationAreaType,
        update: EtUpdateCreationAreaType,
        delete: EtDeleteCreationAreaType,
    },
    creationTypes: {
        list: EtCreationTypeList,
        create: EtCreateCreationType,
        update: EtUpdateCreationType,
        delete: EtDeleteCreationType,
    },
    gratitudeTypes: {
        list: EtGratitudeTypeList,
        create: EtCreateGratitudeType,
        update: EtUpdateGratitudeType,
        delete: EtDeleteGratitudeType,
    },
    impactTypes: {
        list: EtImpactTypeList,
        create: EtCreateImpactType,
        update: EtUpdateImpactType,
        delete: EtDeleteImpactType,
    },
    inspirationBeaconTypes: {
        list: EtInspirationBeaconTypeList,
        create: EtCreateInspirationBeaconType,
        update: EtUpdateInspirationBeaconType,
        delete: EtDeleteInspirationBeaconType,
    },
    intentionTypes: {
        list: EtIntentionTypeList,
        create: EtCreateIntentionType,
        update: EtUpdateIntentionType,
        delete: EtDeleteIntentionType,
    },
    investigationTypes: {
        list: EtInvestigationTypeList,
        create: EtCreateInvestigationType,
        update: EtUpdateInvestigationType,
        delete: EtDeleteInvestigationType,
    },
    legacyTypes: {
        list: EtLegacyTypeList,
        create: EtCreateLegacyType,
        update: EtUpdateLegacyType,
        delete: EtDeleteLegacyType,
    },
    missionMetricGradationTypes: {
        list: EtMissionMetricGradationTypeList,
        create: EtCreateMissionMetricGradationType,
        update: EtUpdateMissionMetricGradationType,
        delete: EtDeleteMissionMetricGradationType,
    },
    missionMetricUoMTypes: {
        list: EtMissionMetricUoMTypeList,
        create: EtCreateMissionMetricUoMType,
        update: EtUpdateMissionMetricUoMType,
        delete: EtDeleteMissionMetricUoMType,
    },
    organizations: {
        list: EtOrganizationList,
        create: EtCreateOrganization,
        update: EtUpdateOrganization,
        delete: EtDeleteOrganization,
    },
    organizationTypes: {
        list: EtOrganizationTypeList,
        create: EtCreateOrganizationType,
        update: EtUpdateOrganizationType,
        delete: EtDeleteOrganizationType,
    },
    orgRoles: {
        list: EtOrgRoleList,
        create: EtCreateOrgRole,
        update: EtUpdateOrgRole,
        delete: EtDeleteOrgRole,
    },
    orgTitles: {
        list: EtOrgTitleList,
        create: EtCreateOrgTitle,
        update: EtUpdateOrgTitle,
        delete: EtDeleteOrgTitle,
    },
    problemAreaTypes: {
        list: EtProblemAreaTypeList,
        create: EtCreateProblemAreaType,
        update: EtUpdateProblemAreaType,
        delete: EtDeleteProblemAreaType,
    },
    problemMetricGradationTypes: {
        list: EtProblemMetricGradationTypeList,
        create: EtCreateProblemMetricGradationType,
        update: EtUpdateProblemMetricGradationType,
        delete: EtDeleteProblemMetricGradationType,
    },
    problemMetricTypes: {
        list: EtProblemMetricTypeList,
        create: EtCreateProblemMetricType,
        update: EtUpdateProblemMetricType,
        delete: EtDeleteProblemMetricType,
    },
    problemMetricUoMTypes: {
        list: EtProblemMetricUoMTypeList,
        create: EtCreateProblemMetricUoMType,
        update: EtUpdateProblemMetricUoMType,
        delete: EtDeleteProblemMetricUoMType,
    },
    questions: {
        list: EtQuestionList,
        create: EtCreateQuestion,
        update: EtUpdateQuestion,
        delete: EtDeleteQuestion,
    },
    questionSets:{
        list: EtQuestionSetList,
        create: EtCreateQuestionSet,
        update: EtUpdateQuestionSet,
        delete: EtDeleteQuestionSet,
    },
    questionWithScores:{
        list: EtQuestionWithScoresList,
        create: EtCreateQuestionWithScores,
        update: EtUpdateQuestionWithScores,
        delete: EtDeleteQuestionWithScores,
    },
    sections:{
        list: EtSectionList,
        create: EtCreateSection,
        update: EtUpdateSection,
        delete: EtDeleteSection,
    },
    sessions:{
        list: EtSessionList,
        create: EtCreateSession,
        update: EtUpdateSession,
        delete: EtDeleteSession,
    },
    subsPacks:{
        list: EtSubsPackList,
        create: EtCreateSubsPack,
        update: EtUpdateSubsPack,
        delete: EtDeleteSubsPack,
    },
    orgSubscriptions:{
        list: EtOrgSubscriptionList,
        create: EtCreateOrgSubscription,
        update: EtUpdateOrgSubscription,
        delete: EtDeleteOrgSubscription,
    },
    trails:{
        list: EtTrailList,
        create: EtCreateTrail,
        update: EtUpdateTrail,
        delete: EtDeleteTrail,
    },
    users:{
        list: EtUserList,
        create: EtCreateUser,
        update: EtUpdateUser,
        delete: EtDeleteUser,
    },
    sessionTypes: {
        list: EtSessionTypeList,
        create: EtCreateSessionType,
        update: EtUpdateSessionType,
        delete: EtDeleteSessionType,
    },
    showcaseTypes: {
        list: EtShowcaseTypeList,
        create: EtCreateShowcaseType,
        update: EtUpdateShowcaseType,
        delete: EtDeleteShowcaseType,
    },
    userChallengeTypes: {
        list: EtUserChallengeTypeList,
        create: EtCreateUserChallengeType,
        update: EtUpdateUserChallengeType,
        delete: EtDeleteUserChallengeType,
    },
    winTypes: {
        list: EtWinTypeList,
        create: EtCreateWinType,
        update: EtUpdateWinType,
        delete: EtDeleteWinType,
    },
};

export default entityMap;