import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import Modal from "@/components/common/modal/Modal"
import { useGetContactsQuery, useDeleteContactMutation } from "./contactsApiSlice"

const EtDeleteContact = ({id, setActiveAdminView, userId}) => {
  // Created - 01 Oct 2024
  // Author - Atul Pandey
  // Inherited from EtDeleteContactOrganizationType
  // Updated - 07 Mar 2025 - updated the comp with props - { id, setActiveAdminView, userId}
  // Updated - 07 Mar 2025 - switched from navigate to setActiveAdminView

  // 1 - Set Title
  useTitle("Delete Contact")

  // 2 - extract the contact Id from the url
  // const { id } = useParams()
  // const userId = userId
  //console.log("id:", id)

  // 3 - Extract the contact from the query to load the contact data
  const { contact } = useGetContactsQuery("Contact", {
    selectFromResult: ({ data }) => ({
      contact: data?.entities[id],
    }),
  })

  //console.log('contactOrganization type:', contact)

  // 4 - Check for returned data
  if (!contact) {
    return <PulseLoader color={"#ea580c"} />
  }

  // 5. Display data
  let content
  content = <EtDeleteContactForm contact={contact} id={id} userId={userId} setActiveAdminView={setActiveAdminView}/> // 07 Mar 2025 - added setActiveAdminView={setActiveAdminView}
  return content
}

const EtDeleteContactForm = ({contact, id, userId, setActiveAdminView}) => {

  // 1 - set the variable for navigate
  const navigate = useNavigate()

 // 1a - Delete section modal 
 const [isModalOpen, setIsModalOpen] = useState(false)


  // 2 - set the mutation to create the new section data
  const[deleteContact, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useDeleteContactMutation()

// 3 - set the form data object
const [formData, setFormData] = useState({
  contactName: contact.contactName,
})

//console.log('formData for update:', formData)

// 4 - Reset form upon successful submission
useEffect(() => {
  if(isSuccess) {
    setFormData({
      contactName: "",
    })

    // navigate('/contacts');
    setActiveAdminView('contacts'); // 07 Mar 2025
  }
  
  },[isSuccess])// ✅ 07 Mar 2025 - Removed `navigate` since it's no longer used.

// 5 - Form field updates / change handling

const handleChange = (e) => {
  const { name, value, files } = e.target
  if (name === "pic") {
    setFormData(prevState => ({ ...prevState, pic: files[0] }))
  } else {
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }
}

// Modal management functions
const handleOpenModal = () => setIsModalOpen(true)
const handleCloseModal = () => setIsModalOpen(false)

// 7 - Form Submit Logic
const handleConfirm = async (e) => {
  e.preventDefault()
  handleCloseModal()

  // Initialize an object to collect the form data
  const contactData = {
    // contactId: id,
    // userId: userId,
    contactId: id,
    userId: userId,
  }
    // No image, send other data as is
    try {
      // contactData.contactId = id
      // contactData.userId= userId
      console.log('contactData', contactData)
      console.log("Form submitted.")
      await deleteContact(contactData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
}

// 8 - Handle Cancel

const handleCancel = () => {
  // navigate('/contacts');
  setActiveAdminView('contacts'); // 07 Mar 2025
}


return (
  <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
    {isLoading && <PulseLoader color={"#ea580c"} />}
    {isError && (
      <p className="text-orange-700 font-semi-bold py-2">
        {error?.data?.message}
      </p>
    )}
    <div className="bg-white p-1 m-1 rounded">
      <p className="p-1 m-1 font-bold font-poppins text-xl">Delete a Contact</p>

      <div className="bg-gray-200 rounded m-1 p-1">
        <form
          onSubmit={handleOpenModal}
          className="m-1 p-1 text-xs font-poppins"
        >
          
          <div id="contactName">
            <input
              type="text"
              name="contactName"
              placeholder="Enter the contactOrganization type name here"
              value={formData.contactName}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="contactOrganizations" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic
              name={"Delete"}
              type={"create"}
              handleClick={handleOpenModal}
            />
            <EtBtnFormBasic
              name={"Cancel"}
              type={"cancel"}
              handleClick={handleCancel}
            />
          </div>
        </form>
      </div>
    </div>
    <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleConfirm} />
  </div>
)

}

export default EtDeleteContact