
    import React from 'react';
    
    const EtWabImpactIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m1558.8 2314.8 241.2 417.85 241.2-418.07 241.2-417.85-263.59-0.003906c-9.6914 0.019531-19.055-3.5195-26.312-9.9414-7.2578-6.4219-11.91-15.285-13.07-24.906l-184-1499.3-183.86 1498.1c-0.89844 9.8672-5.4648 19.039-12.797 25.703-7.332 6.6641-16.895 10.34-26.801 10.297h-254.38l241.2 417.82zm-43.199 83.41 88.559 153.39v0.003906c-127.13 9.0273-252.43 35.383-372.42 78.336-116.64 45.648-186.66 108.72-186.66 181.87 0 82.477 90.613 152.75 237.1 199.69 168.24 48.527 342.75 71.797 517.82 69.047 175.08 2.7266 349.59-20.566 517.82-69.117 146.48-46.801 237.1-117.21 237.1-199.69 0-73.188-70.055-136.26-186.8-181.95-119.93-42.91-245.15-69.242-372.21-78.266l88.488-153.39c272.81 23.617 510.12 80.281 678.93 157.79 159.27 73.152 254.59 162.36 254.59 255.82 0 110.12-130.82 213.59-342.32 291.85-223.45 82.801-533.02 133.81-875.7 133.81-342.68 0-652.25-51.121-875.7-133.81-211.5-78.266-342.32-181.73-342.32-291.85 0-93.602 95.293-182.63 254.48-255.6 168.84-77.543 406.22-134.24 679.14-157.86zm248.94 431.21-0.003907 0.003906c4.2695 8.5742 11.504 15.309 20.359 18.961 8.8555 3.6484 18.734 3.9648 27.809 0.89063 9.0703-3.0781 16.719-9.3359 21.527-17.621l117.86-204.09h0.003906c132.06 6.5703 262.48 32.18 387.21 76.035 85.141 33.336 136.26 71.281 136.26 108 0 44.062-69.551 88.379-181.91 124.38h-0.003906c-160.43 46.012-326.79 67.996-493.67 65.23-166.87 2.8125-333.23-19.125-493.67-65.086-112.36-36-181.91-80.316-181.91-124.38 0-36.863 51.047-74.809 136.15-108l-0.003906-0.003906c124.8-43.918 255.29-69.578 387.43-76.176z" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtWabImpactIcon1SVGComp;
    