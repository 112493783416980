import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const contributionTypesAdapter = createEntityAdapter({})

const initialState = contributionTypesAdapter.getInitialState()

export const contributionTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getContributionTypes: builder.query({
            query: () => ({
                url: '/contributionTypes/getContributionTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedContributionTypes = responseData.map(contributionType => {
                    contributionType.id = contributionType._id
                    return contributionType
                })
                return contributionTypesAdapter.setAll(initialState, loadedContributionTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'ContributionType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'ContributionType', id}))
                    ]
                } else {
                    return [ { type: 'ContributionType', id: 'LIST'}]
                }
            },
        }),
        createNewContributionType: builder.mutation({
            query: initialContributionType => ({
                url: '/contributionTypes/createNewContributionType',
                method: 'POST',
                body: {...initialContributionType,}
            }),
            invalidatesTags: [
                { type: 'ContributionType', id: "LIST" }
            ] 
        }),
        updateContributionType: builder.mutation({
            query: initialContributionType => ({
                url: '/contributionTypes/updateContributionType',
                method: 'PATCH',
                body: {...initialContributionType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'ContributionType', id: arg.id },
                { type: 'ContributionType', id: 'LIST' }
            ],
        }),
        deleteContributionType: builder.mutation({
            query: ({id}) => ({
                url: '/contributionTypes/deleteContributionType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'ContributionType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetContributionTypesQuery,
    useCreateNewContributionTypeMutation,
    useUpdateContributionTypeMutation,
    useDeleteContributionTypeMutation,
} = contributionTypesApiSlice

// returns the query result object
export const selectContributionTypeResult = contributionTypesApiSlice.endpoints.getContributionTypes.select()

// create memoized selector
const selectContributionTypesData = createSelector(
    selectContributionTypeResult,
    contributionTypeResult => contributionTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllContributionTypes,
    selectById: selectContributionTypeById,
    selectIds: selectContributionTypeIds,
} = contributionTypesAdapter.getSelectors(state => selectContributionTypesData(state) ?? initialState)