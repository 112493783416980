import React from "react"
import ReactQuill from "react-quill"
import EtEditorToolbarLight, { modules, formats } from "./EtEditorToolbarLight2"
import "react-quill/dist/quill.snow.css"


export const EtContentEditorLight3 = ({value, handleChange, placeholderText, toolbarId}) => {
  const dynamicModules = {
    ...modules,
    toolbar: {
      container: `#${toolbarId}`,
    },
  };
  
  return (
    <div className="custom-quill-editor3" >
      <div id={toolbarId}>
        <EtEditorToolbarLight />
      </div>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleChange}
        placeholder={placeholderText}
        modules={dynamicModules}
        formats={formats}
        className='w-full h-full'
      />
    </div>
  )
}

export default EtContentEditorLight3
