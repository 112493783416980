import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userSession8848BBDBsAdapter = createEntityAdapter({})

const initialState = userSession8848BBDBsAdapter.getInitialState()

export const userSession8848BBDBsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserSession8848BBDBs: builder.query({
            query: () => ({
                url: '/userSession8848BBDBs/getUserSession8848BBDBs',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserSession8848BBDBs = responseData.map(userSession8848BBDB => {
                    userSession8848BBDB.id = userSession8848BBDB._id
                    return userSession8848BBDB
                })
                return userSession8848BBDBsAdapter.setAll(initialState, loadedUserSession8848BBDBs)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserSession8848BBDB', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserSession8848BBDB', id}))
                    ]
                } else {
                    return [ { type: 'UserSession8848BBDB', id: 'LIST'}]
                }
            },
        }),
        createNewUserSession8848BBDB: builder.mutation({
            query: initialUserSession8848BBDB => ({
                url: '/userSession8848BBDBs/createNewUserSession8848BBDB',
                method: 'POST',
                body: {...initialUserSession8848BBDB,}
            }),
            invalidatesTags: [
                { type: 'UserSession8848BBDB', id: "LIST" }
            ] 
        }),
        updateUserSession8848BBDB: builder.mutation({
            query: initialUserSession8848BBDB => ({
                url: '/userSession8848BBDBs/updateUserSession8848BBDB',
                method: 'PATCH',
                body: {...initialUserSession8848BBDB,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserSession8848BBDB', id: arg.id },
                { type: 'UserSession8848BBDB', id: 'LIST' }
            ],
        }),
        deleteUserSession8848BBDB: builder.mutation({
            query: ({id}) => ({
                url: '/userSession8848BBDBs/deleteUserSession8848BBDB',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserSession8848BBDB', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserSession8848BBDBsQuery,
    useCreateNewUserSession8848BBDBMutation,
    useUpdateUserSession8848BBDBMutation,
    useDeleteUserSession8848BBDBMutation,
} = userSession8848BBDBsApiSlice

// returns the query result object
export const selectUserSession8848BBDBResult = userSession8848BBDBsApiSlice.endpoints.getUserSession8848BBDBs.select()

// create memoized selector
const selectUserSession8848BBDBsData = createSelector(
    selectUserSession8848BBDBResult,
    userSession8848BBDBResult => userSession8848BBDBResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserSession8848BBDBs,
    selectById: selectUserSession8848BBDBById,
    selectIds: selectUserSession8848BBDBIds,
} = userSession8848BBDBsAdapter.getSelectors(state => selectUserSession8848BBDBsData(state) ?? initialState)