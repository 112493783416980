// Author - Atul Pandey
// First Created - 27 Feb 2025
// Purpose - to feed entity name singulars and plurals for admin setup

const entityNameMap = {
    coachUserPads: ["coachUserPad", "coachUserPads"],
    sponsorUserPads: ["sponsorUserPad", "sponsorUserPads"],
    actionTypes: ["actionType", "actionTypes"],
    capabilitiesAdvisoryTypes: ["capabilitiesAdvisoryType", "capabilitiesAdvisoryTypes"],
    capabilitiesDomainTypes: ["capabilitiesDomainType", "capabilitiesDomainTypes"],
    capabilitiesFunctionTypes: ["capabilitiesFunctionType", "capabilitiesFunctionTypes"],
    capabilitiesIndustryTypes: ["capabilitiesIndustryType", "capabilitiesIndustryTypes"],
    capabilitiesSalesFunctionAreaTypes: ["capabilitiesSalesFunctionAreaType", "capabilitiesSalesFunctionAreaTypes"],
    capabilitiesSalesTypes: ["capabilitiesSalesType", "capabilitiesSalesTypes"],
    capabilitiesServiceFunctionAreaTypes: ["capabilitiesServiceFunctionAreaType", "capabilitiesServiceFunctionAreaTypes"],
    capabilitiesServiceTypes: ["capabilitiesServiceType", "capabilitiesServiceTypes"],
    capabilitiesSoftSkillFunctionAreaTypes: ["capabilitiesSoftSkillFunctionAreaType", "capabilitiesSoftSkillFunctionAreaTypes"],
    capabilitiesSoftSkillTypes: ["capabilitiesSoftSkillType", "capabilitiesSoftSkillTypes"],
    capabilitiesTechAreaTypes: ["capabilitiesTechAreaType", "capabilitiesTechAreaTypes"],
    capabilitiesTechStackTypes: ["capabilitiesTechStackType", "capabilitiesTechStackTypes"],
    challenges: ["challenge", "challenges"],
    contacts: ["contact", "contacts"],
    challengeHeads: ["challengeHead", "challengeHeads"],
    challengeTypes: ["challengeType", "challengeTypes"],
    contactOrganizations: ["contactOrganization", "contactOrganizations"],
    contactOrganizationTypes: ["contactOrganizationType", "contactOrganizationTypes"],
    contactTypes: ["contactType", "contactTypes"],
    contributionTypes: ["contributionType", "contributionTypes"],
    creationAreaTypes: ["creationAreaType", "creationAreaTypes"],
    creationTypes: ["creationType", "creationTypes"],
    gratitudeTypes: ["gratitudeType", "gratitudeTypes"],
    impactTypes: ["impactType", "impactTypes"],
    inspirationBeaconTypes: ["inspirationBeaconType", "inspirationBeaconTypes"],
    intentionTypes: ["intentionType", "intentionTypes"],
    investigationTypes: ["investigationType", "investigationTypes"],
    legacyTypes: ["legacyType", "legacyTypes"],
    missionMetricGradationTypes: ["missionMetricGradationType", "missionMetricGradationTypes"],
    missionMetricUoMTypes: ["missionMetricUoMType", "missionMetricUoMTypes"],
    organizations: ["organization", "organizations"],
    organizationTypes: ["organizationType", "organizationTypes"],
    orgRoles: ["orgRole", "orgRoles"],
    orgTitles: ["orgTitle", "orgTitles"],
    problemAreaTypes: ["problemAreaType", "problemAreaTypes"],
    problemMetricGradationTypes: ["problemMetricGradationType", "problemMetricGradationTypes"],
    problemMetricTypes: ["problemMetricType", "problemMetricTypes"],
    problemMetricUoMTypes: ["problemMetricUoMType", "problemMetricUoMTypes"],
    questions: ["question", "questions"],
    questionSets: ["questionSet", "questionSets"],
    questionWithScores: ["questionWithScore", "questionsWithScores"],
    sections: ["section", "sections"],
    sessions: ["session", "sessions"],
    sessionTypes: ["sessionType", "sessionTypes"],
    showcaseTypes: ["showcaseType", "showcaseTypes"],
    orgSubscriptions: ["orgSubscription", "orgSubscriptions"],
    subsPacks: ["subsPack", "subsPacks"],
    trails: ["trail", "trails"],
    users: ["user", "users"],
    userChallengeTypes: ["userChallengeType", "userChallengeTypes"],
    winTypes: ["winType", "winTypes"],
};

export default entityNameMap;