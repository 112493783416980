import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const actionTypesAdapter = createEntityAdapter({})

const initialState = actionTypesAdapter.getInitialState()

export const actionTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getActionTypes: builder.query({
            query: () => ({
                url: '/actionTypes/getActionTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedActionTypes = responseData.map(actionType => {
                    actionType.id = actionType._id
                    return actionType
                })
                return actionTypesAdapter.setAll(initialState, loadedActionTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'ActionType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'ActionType', id}))
                    ]
                } else {
                    return [ { type: 'ActionType', id: 'LIST'}]
                }
            },
        }),
        createNewActionType: builder.mutation({
            query: initialActionType => ({
                url: '/actionTypes/createNewActionType',
                method: 'POST',
                body: {...initialActionType,}
            }),
            invalidatesTags: [
                { type: 'ActionType', id: "LIST" }
            ] 
        }),
        updateActionType: builder.mutation({
            query: initialActionType => ({
                url: '/actionTypes/updateActionType',
                method: 'PATCH',
                body: {...initialActionType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'ActionType', id: arg.id },
                { type: 'ActionType', id: 'LIST' }
            ],
        }),
        deleteActionType: builder.mutation({
            query: ({id}) => ({
                url: '/actionTypes/deleteActionType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'ActionType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetActionTypesQuery,
    useCreateNewActionTypeMutation,
    useUpdateActionTypeMutation,
    useDeleteActionTypeMutation,
} = actionTypesApiSlice

// returns the query result object
export const selectActionTypeResult = actionTypesApiSlice.endpoints.getActionTypes.select()

// create memoized selector
const selectActionTypesData = createSelector(
    selectActionTypeResult,
    actionTypeResult => actionTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllActionTypes,
    selectById: selectActionTypeById,
    selectIds: selectActionTypeIds,
} = actionTypesAdapter.getSelectors(state => selectActionTypesData(state) ?? initialState)