import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const capabilitiesServiceTypesAdapter = createEntityAdapter({})

const initialState = capabilitiesServiceTypesAdapter.getInitialState()

export const capabilitiesServiceTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCapabilitiesServiceTypes: builder.query({
            query: () => ({
                url: '/capabilitiesServiceTypes/getCapabilitiesServiceTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedCapabilitiesServiceTypes = responseData.map(capabilitiesServiceType => {
                    capabilitiesServiceType.id = capabilitiesServiceType._id
                    return capabilitiesServiceType
                })
                return capabilitiesServiceTypesAdapter.setAll(initialState, loadedCapabilitiesServiceTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'CapabilitiesServiceType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'CapabilitiesServiceType', id}))
                    ]
                } else {
                    return [ { type: 'CapabilitiesServiceType', id: 'LIST'}]
                }
            },
        }),
        createNewCapabilitiesServiceType: builder.mutation({
            query: initialCapabilitiesServiceType => ({
                url: '/capabilitiesServiceTypes/createNewCapabilitiesServiceType',
                method: 'POST',
                body: {...initialCapabilitiesServiceType,}
            }),
            invalidatesTags: [
                { type: 'CapabilitiesServiceType', id: "LIST" }
            ] 
        }),
        updateCapabilitiesServiceType: builder.mutation({
            query: initialCapabilitiesServiceType => ({
                url: '/capabilitiesServiceTypes/updateCapabilitiesServiceType',
                method: 'PATCH',
                body: {...initialCapabilitiesServiceType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'CapabilitiesServiceType', id: arg.id },
                { type: 'CapabilitiesServiceType', id: 'LIST' }
            ],
        }),
        deleteCapabilitiesServiceType: builder.mutation({
            query: ({id}) => ({
                url: '/capabilitiesServiceTypes/deleteCapabilitiesServiceType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'CapabilitiesServiceType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetCapabilitiesServiceTypesQuery,
    useCreateNewCapabilitiesServiceTypeMutation,
    useUpdateCapabilitiesServiceTypeMutation,
    useDeleteCapabilitiesServiceTypeMutation,
} = capabilitiesServiceTypesApiSlice

// returns the query result object
export const selectCapabilitiesServiceTypeResult = capabilitiesServiceTypesApiSlice.endpoints.getCapabilitiesServiceTypes.select()

// create memoized selector
const selectCapabilitiesServiceTypesData = createSelector(
    selectCapabilitiesServiceTypeResult,
    capabilitiesServiceTypeResult => capabilitiesServiceTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCapabilitiesServiceTypes,
    selectById: selectCapabilitiesServiceTypeById,
    selectIds: selectCapabilitiesServiceTypeIds,
} = capabilitiesServiceTypesAdapter.getSelectors(state => selectCapabilitiesServiceTypesData(state) ?? initialState)