import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import { useGetSectionsQuery , useDeleteSectionMutation } from "./sectionsApiSlice"
import PulseLoader from "react-spinners/PulseLoader"
import Modal from "@/components/common/modal/Modal"

const EtDeleteSection = ({id, setActiveAdminView}) => {
  // Created - 14 Apr 2024
  // Author - Atul Pandey
  // Updated - 01 Mar 2025 - updated the softSkillFunctionArea with props - { id, setActiveAdminView}
  // Updated - 01 Mar 2025 - switched from navigate to setActiveAdminView

  // 1 - Set Title
  useTitle("Delete a Section")

  // 2 - extract the question Id from the url
  // const { id } = useParams()
  // console.log("id:", id)

  // 3 - Extract the section from the query to load the section data
  const { section } = useGetSectionsQuery("Section", {
    selectFromResult: ({ data }) => ({
      section: data?.entities[id],
    }),
  })

  // 4 - Check for returned data
  if (!section) {
    return <PulseLoader color={"#ea580c"} />
  }

  // 5. Display data
  let content
  content = <EtDeleteSectionForm section={section} id={id} setActiveAdminView={setActiveAdminView}/> // 01 Mar 2025 - added setActiveAdminView={setActiveAdminView}

  return content
}

const EtDeleteSectionForm = ({section, id, setActiveAdminView}) => {

  // 1 - set the navigate hook
  const navigate = useNavigate()

  // 1a - Delete section modal 
  const [isModalOpen, setIsModalOpen] = useState(false)

  // 2 - The mutation to delete the Question Set Data
  const [ deleteSection, {
    isLoading,
    isSuccess,
    isError,
    error
  }] = useDeleteSectionMutation()

// 3 - Set the data received in the component props to the local variables
const [formData, setFormData] = useState({
    sectionName: section.sectionName,
  })

  // 4. Reset the input field and navigation based on the success state 
useEffect(() => {
    if(isSuccess) {
        setFormData({
            sectionName: "",
          })
    // navigate('/sections')
    setActiveAdminView('sections'); // 01 Mar 2025
  }
  
  },[isSuccess])// ✅ 01 Mar 2025 - Removed `navigate` since it's no longer used.

// 5. Form field updates / change handling

    // Modal management functions
    const handleOpenModal = () => setIsModalOpen(true)
    const handleCloseModal = () => setIsModalOpen(false)

  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }))
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }))
    }
  }

 // 6. Form Submit logic:
 const handleConfirm = async (e) => {
  e.preventDefault()
  handleCloseModal()

  // Initialize an object to collect the form data
  const sectionData = {
    sectionName: formData.sectionName,
  }
    // No image, send other data as is
    try {
      sectionData.id = id
      console.log("Form submitted.")
      await deleteSection(sectionData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
  
}

const handleCancel = () => {
 // navigate('/sections')
 setActiveAdminView('sections'); // 01 Mar 2025
}

return (
  <div className="bg-gray-200 p-1 w-3/4 mx-auto my-5 rounded-lg shadow-md shadow-black">
    {isLoading && <PulseLoader color={"#ea580c"} />}
    {isError && (
      <p className="text-orange-700 font-semi-bold py-2">
        {error?.data?.message}
      </p>
    )}
    <div className="bg-white p-1 m-1 rounded">
      <p className="p-1 m-1 font-bold font-poppins text-xl">
        Delete a Section
      </p>

      <div className="bg-gray-200 rounded m-1 p-1">
        <form
          onSubmit={handleOpenModal}
          className="m-1 p-1 text-sm font-poppins"
        >
          <div id="sectionName">
            <input
              type="text"
              name="sectionName"
              placeholder="Section Name"
              readOnly={true}
              value={formData.sectionName}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic
              name={"Delete"}
              type={"create"}
              handleClick={handleOpenModal}
            />
            <EtBtnFormBasic
              name={"Cancel"}
              type={"cancel"}
              handleClick={handleCancel}
            />
          </div>
        </form>
      </div>
    </div>
    <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleConfirm} />
  </div>
)


}

export default EtDeleteSection