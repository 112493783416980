import React, { useState, useEffect } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";

import EtEtTrinityIcon6SVGComp from "@/assets/img/icons/svgComp/EtEtTrinityIcon6SVGComp";
import { useGenerateTrinityResponseMutation } from "@/features/ai/trinityApiSlice";

// 01 Feb 2025 - images for Task menu
import EtImprintCrystallBallIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintCrystallBallIcon1SVGComp'
import EtIlluminatePurposeIcon1SVGComp from '@/assets/img/icons/svgComp/EtIlluminatePurposeIcon1SVGComp'
import EtImprintCreationsIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintCreationsIcon1SVGComp'
import EtIlluminateContributionsIcon1SVGComp from "@/assets/img/icons/svgComp/EtIlluminateContributionsIcon1SVGComp"
import EtIlluminateWinsIcon1SVGComp from "@/assets/img/icons/svgComp/EtIlluminateWinsIcon1SVGComp"
import EtIlluminateGratitudesIcon1SVGComp from "@/assets/img/icons/svgComp/EtIlluminateGratitudesIcon1SVGComp"
import EtImprintVisionIcon2SVGComp from '@/assets/img/icons/svgComp/EtImprintVisionIcon2SVGComp'
import EtImprintMissionsIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintMissionsIcon1SVGComp'
import EtImprintPlansIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintPlansIcon1SVGComp'

// 01 Feb 2025 - components connected to the Task menu items
import EtTrinityUserImprintOverview from '@/features/userMagic/userImprint/EtTrinityUserImprintOverview'

const EtTrinityGuidedServiceBarImprint = ({ context }) => {
  // First Created - 31 Jan 2025
  // Author - Atul Pandey
  // Inherited from EtTrinityGuidedServiceBar

  // 1a - Ensure context exists and is an array before accessing [0] -- 31 Jan 2025
  const userInfo = Array.isArray(context) && context?.length > 0 ? context[0] : {};
  console.log('userInfo', userInfo)

  // 1b - Extract user details with safe optional chaining -- 31 Jan 2025
  const userId = userInfo?._id ?? null;
  const username = userInfo?.username ?? "";
  const userName = userInfo?.name ?? "";
  const userOrg = userInfo?.userOrgId?.organizationName ?? "";
  const userRole = userInfo?.userOrgRole?.orgRoleName ?? "";
  const userTitle = userInfo?.userOrgTitle?.orgTitleName ?? "";
  const firstName = userName?.split(" ")[0] ?? "";

  // 1c State Management
  const [isOpen, setIsOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);

  // 1d Task Options - 01 Feb 2025
  const taskOptions = [
    { label: "Refine Imprint Overview", value: "refine_imprint", pic: EtImprintCrystallBallIcon1SVGComp },
    { label: "Refine a Purpose", value: "refine_purpose", pic: EtIlluminatePurposeIcon1SVGComp },
    { label: "Capture a New Creation", value: "capture_creation", pic: EtImprintCreationsIcon1SVGComp },
    { label: "Capture a Contribution", value: "capture_contribution", pic: EtIlluminateContributionsIcon1SVGComp },
    { label: "Capture a Win", value: "capture_win", pic: EtIlluminateWinsIcon1SVGComp },
    { label: "Extend a Gratitude", value: "extend_gratitude", pic: EtIlluminateGratitudesIcon1SVGComp },
    { label: "Ideate on a Vision", value: "ideate_vision", pic: EtImprintVisionIcon2SVGComp },
    { label: "Define a Mission", value: "define_mission", pic: EtImprintMissionsIcon1SVGComp },
    { label: "Define an Action Plan", value: "define_action", pic: EtImprintPlansIcon1SVGComp },
  ];

  // 2 Manage opening/closing of the Trinity Chat
  const toggleChat = () => {
    setIsOpen(!isOpen);   
  };


  // 3a - Function to Handle Task Selection
  const handleTaskSelection = (selectedTask) => {
    if (selectedTask === "refine_imprint") {
      setActiveComponent("refine_imprint");
    } else {
      setSelectedTask(selectedTask); // Handle other tasks
    }
  };

  // 3b - Function to Reset and Show Menu Again
  const handleReset = () => {
    setActiveComponent(null);
  };

  
  return (
    <div className="fixed top-5 right-1 z-50">
      {/* Trinity Icon */}
      <button
        className="bg-black hover:bg-orange-600 text-white p-1 rounded-full shadow-lg shadow-black"
        onClick={ () => {
          toggleChat(); // Open or close the chat
          setActiveComponent(null); // Reset active task when reopening
        }}
      >
        <EtEtTrinityIcon6SVGComp className="h-6 w-6 text-orange-600 hover:text-white" />
      </button>

      {/* Chat Interface */}
      {isOpen && (
        <div className="fixed bottom-20 right-5 bg-white shadow-lg shadow-black rounded-lg w-[60vw] h-[75vh] p-4 font-poppins overflow-y-auto">
          {/* Section 1 - Header */}
          <div className="p-4 border-b border-gray-200">
            <p className="text-lg font-semibold">Hello <span className="text-orange-600">{firstName}</span>, I am Trinity!</p>
            <p className="text-[10px] text-gray-400">How can I power up your imprint today?</p>
          </div>

          {/* Task Selection Menu */}
          {!activeComponent ? (
            <div className="p-4">
              <h3 className="text-md font-semibold mb-2">Would you like to...</h3>
              <div className="grid grid-cols-3 gap-1 aspect-[3/1]">
                {taskOptions?.map((task) => (
                  <button
                    key={task.value}
                    className="bg-orange-600 text-white px-4 py-2 rounded-lg hover:bg-orange-700 text-xs flex items-center space-x-2 hover:shadow-lg hover:shadow-black"
                    onClick={() => handleTaskSelection(task.value)}
                  >
                    <task.pic className="h-8 w-8 text-white" />
                    <span>{task.label}</span>
                  </button>
                  
                ))}
              </div>
            </div>
          ):(
            activeComponent === "refine_imprint" && (
              <EtTrinityUserImprintOverview userId={userId} userInfo={userInfo} setIsOpen={setIsOpen}/>
            )
          )}


        </div>
      )}
    </div>
  );
};

export default EtTrinityGuidedServiceBarImprint;