import React, { useState, useEffect } from "react";
import { useCreateNewUserImprintOverviewAIMutation } from "@/features/userMagic/userImprint/userImprintOverviewAIsApiSlice";
import EtTrinityGuidedServiceBar from "@/components/ai/EtTrinityGuidedServiceBar";
import EtTrinityGuidedFlow from "@/components/ai/EtTrinityGuidedFlow";

const userImprintOverviewPromptFlow = [
        { step: "openingStatement", prompt: "Let's begin! Tell me a few key statements about your journey so far." },
        { step: "industryExperiences", prompt: "Fantastic start! Now, can you share some insights about your industry or domain expertise?" },
        { step: "capabilities", prompt: "Great insights! What are some of your key capabilities or skills that define you?" },
        { step: "roles", prompt: "Nice! Let's now talk about the roles you've played and your key contributions." },
        { step: "achievements", prompt: "Impressive! Tell me about some of your most outstanding achievements." },
        { step: "aspirations", prompt: "Love that! Now, what drives you? What are your aspirations moving forward?" },
        { step: "factoids", prompt: "You're doing great! Finally, what makes your story unique or truly memorable?" },
        { step: "finalize", prompt: "Super! Based on our conversation, I'll generate an overview for you. Ready?" }
];

const EtTrinityUserImprintOverview = ({userId, userInfo, setIsOpen}) => {
  // First Created - 29 Jan 2025
  // Author - Atul Pandey
  // Updated - 31 Jan 2025 - updated the prop list - replaced userOrgId with userInfo
  // Updated - 31 Jan 2025 - updated the context prop in EtTrinityGuidedServiceBar component - changed to userInfo
  // Updated - 01 Feb 2025 - updated the Trinity AI handler to EtTrinityGuidedFlow
  // Updated - 02 Feb 2025 - added the typewriter simulation
  
  
  // Step 0 - confirm when inside this component
  //   console.log('inside EtTrinityUserImprintOverview ')

  // Step 1 - state variables
  const [typedText, setTypedText] = useState(""); // ✅ Store typed text
  const openingMsg = "Super! We will get cracking on refining your imprint..";

  useEffect(() => {
    simulateTypingEffect(openingMsg, setTypedText, 50);
  }, []); // ✅ Runs once when the component mounts
  
  // Step 2a - extract userOrgId from userInfo prop -- 31 Jan 2025
  const userOrgId = userInfo?.userOrgId?._id
  // console.log('userOrgId', userOrgId)

  // Step 2b - RTK Mutation Hook for Saving AI-Guided Input
  const [createNewUserImprintOverviewAI] = useCreateNewUserImprintOverviewAIMutation();

  // Step 3: Handle Final AI Response
  const handleFinalAIResponse = async (finalResponse) => {
    try {
      const payload = {
        userId: userId, 
        userOrgId: userOrgId, 
        ...finalResponse,
      };
      await createNewUserImprintOverviewAI(payload).unwrap();
      console.log("Successfully saved AI-generated imprint overview.");
    } catch (error) {
      console.error("Error saving user imprint overview:", error);
    }
  };

  // Step 4: Typing Effect for AI Responses
  const simulateTypingEffect = (text, setText, speed = 10) => {
    let index = 0;
    let displayedText = "";
    // const characters = text.split("");
    const words = text.split(" "); // ✅ Splitting by words now
  
    return new Promise((resolve) => {
      const typingInterval = setInterval(() => {
        if (index < words.length) {
          // displayedText += characters[index];
          displayedText += words[index] + " "; // ✅ Add words one by one
          index++;
          setText(displayedText); // ✅ Updates UI progressively
        } else {
          clearInterval(typingInterval);
          resolve(displayedText);
        }
      }, speed);
    });
  };
  
  
  return (
    <div>
      {/* <p className="text-xs text-gray-600 m-1 p-1">
        {typedText}
      </p> */}
      <EtTrinityGuidedFlow 
        context={userInfo}
        promptFlow={userImprintOverviewPromptFlow}
        onComplete={handleFinalAIResponse}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

export default EtTrinityUserImprintOverview;