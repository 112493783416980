import React, { useState, useEffect } from 'react'
import useTitle from "@/hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
  } from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "@/utils/EtContentEditorLight2"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import { useNavigate } from "react-router"

import { 
  useGetUserMeetingPrepsQuery, 
  useUpdateUserMeetingPrepMutation 
} from '@/features/userMeetingPreps/userMeetingPrepsApiSlice'

const EtUpdateUserMeetingPrep = ({
  id, 
  noteId = null, // ✅ 22 Mar 2025 - new prop
  isDelete = false, // ✅ 22 Mar 2025 - new prop
  userId, 
  userOrgId, 
  triggeringUrl = '/etapp', 
  onClose
  }) => {

    // First Created - 21 Mar 2025
    // Author - Atul Pandey
    // Inherited from the EtUpdateUserImprintGratitudeComp component
    // Updated - 22 Mar 2025 - Added noteId for editing an existing note
    // Updated - 22 Mar 2025 - Added isDelete for deleting an existing note

    // 0 - Set Page title & variables
        useTitle('User Imprint Gratitude - Update')
  
    // console.log('id is:', id)
    // console.log('userId is:', userId)
    // console.log('userOrgId is:', userOrgId)
    
    // 1a - set page navigations
    const navigate = useNavigate()

    // 1b - set form states
    const editorKey = `meetingPrep`
    const [editorValue, setEditorValue] = useState('')
    const [currentPrep, setCurrentPrep] = useState(null)
    const [isFormInitialized, setIsFormInitialized] = useState(false)

    // 2 - Fetch all user meeting preps
    const { data: userMeetingPrepsData } = useGetUserMeetingPrepsQuery()
    const [updateUserMeetingPrep, { isLoading, isSuccess, isError, error }] = useUpdateUserMeetingPrepMutation()

    // 3 - Extract the specific prep by id and user match
    useEffect(() => {
      if (userMeetingPrepsData?.ids?.length && !isFormInitialized) {
        const record = userMeetingPrepsData.entities[id]
        if (record?.userId === userId && record?.userOrgId === userOrgId) {
          setCurrentPrep(record)
          
          // 22 Mar 2025
          if (noteId && record.meetingPrepNotes?.length > 0) {
            const targetNote = record.meetingPrepNotes.find(note => note._id === noteId);
            if (targetNote) {
              setEditorValue(targetNote.meetingPrepNoteText || '');
            }
          } else if (!isDelete) {
            // Inject guidance content only for new note and not delete mode
            setEditorValue(`
              <strong>What do you want to get out of this meeting?</strong><br />
              (Your thoughts here...)<br /><br /><br />
              <strong>How would you open the conversation?</strong><br />
              (Your opening lines or tone...)<br /><br /><br />
              <strong>What are your key talking points?</strong><br />
              (List 2–3 core things you'd like to cover...)<br /><br /><br />
              <strong>What will you do if the discussion goes off track?</strong><br />
              (Backup plan or redirect ideas...)<br /><br /><br />
              <strong>How would you wrap up and propose next steps?</strong><br />
              (Summary and actionables...)<br /><br /><br />
            `.trim())
          }

          setIsFormInitialized(true)
        }
      }
    }, [userMeetingPrepsData, id, userId, userOrgId, isFormInitialized])

    // 4 - Handle Submit
    const handleSubmit = async (e) => {
      e.preventDefault()
      const trimmedNote = editorValue?.trim()
      if (!trimmedNote) return

      const payload = {
        id,
        userId,
        userOrgId,
        calendarMeetingId: currentPrep?.calendarMeetingId?._id,
        meetingPrepNotes: [
          noteId
            ? {
                _id: noteId,
                ...(isDelete ? { delete: true } : { meetingPrepNoteText: trimmedNote }),
              }
            : {
                meetingPrepNoteText: trimmedNote,
              },
        ],
      }

      try {
        await updateUserMeetingPrep(payload).unwrap()
        if (onClose) onClose()
      } catch (err) {
        console.error('Update failed:', err)
      }
    }

    // 5 - Handle Cancel
    const handleCancel = () => {
      if (onClose) onClose()
    }

    // 6 - stripHtml
    const stripHtmlTags = (html) => {
      if (!html) return ''
      const div = document.createElement('div')
      div.innerHTML = html
      return div.textContent || div.innerText || ''
    }

    // 7 - create Markup
    const createMarkup = (htmlContent) => {
      return { __html: htmlContent }
    }

  return (
    <div className="bg-white p-4 rounded shadow-md text-xs font-poppins">
      <p className="text-lg font-bold mb-2">Add / Update Meeting Prep</p>

      {isLoading && <PulseLoader color={'#ea580c'} />}
      {isError && <p className="text-red-600">{error?.data?.message}</p>}

      {isDelete && (
        <p className="text-xs text-red-600 font-semibold mb-2">
          You are about to delete this prep note. This action is permanent and cannot be undone. Are you sure?
        </p>
      )}
      <form onSubmit={handleSubmit}>
        <div className={`rounded p-2 mb-4 ${isDelete ? 'bg-gray-100 text-gray-300' : 'bg-gray-100'}`}>
          <label className="block font-bold mb-1">Meeting Prep Note</label>
          {isDelete ? (
              <p dangerouslySetInnerHTML={createMarkup(editorValue)} className="text-sm leading-relaxed p-2"></p>
            ) : (<div>
              <p className="text-[11px] text-gray-600 leading-5 mb-2">
                Use this space to shape your thoughts before the meeting. Reflect on:
                <br />
                • <strong>What do you want to get out of this meeting?</strong>  
                <br />
                • <strong>How would you open the conversation?</strong>  
                <br />
                • <strong>What are your key talking points?</strong>  
                <br />
                • <strong>What will you do if the discussion goes off track?</strong>  
                <br />
                • <strong>How would you wrap up and propose next steps?</strong>  
              </p>
              <EtContentEditorLight2
                editorKey={editorKey}
                value={editorValue}
                handleChange={(val) => setEditorValue(val)}
                placeholderText="Write your prep note here..."
              />
              </div>
            )}
        </div>

        <div className="flex gap-2">
          <EtBtnFormBasic name={isDelete ? "Delete" : "Update"} type="create" handleClick={handleSubmit} />
          <EtBtnFormBasic name="Cancel" type="cancel" handleClick={handleCancel} />
        </div>
      </form>
    </div>
  )
}

export default EtUpdateUserMeetingPrep