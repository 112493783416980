import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import { useGetQuestionsQuery, useUpdateQuestionMutation } from "./questionsApiSlice"
import { useGetQuestionSetsQuery } from "../questionSets/questionSetsApiSlice"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight from "@/utils/EtContentEditorLight"

const EtUpdateQuestion = ({id, setActiveAdminView}) => {
    // Created - 04 Apr 2024
    // Author - Atul Pandey
    // Updated - 28 Feb 2025 - updated the softSkillFunctionArea with props - { id, setActiveAdminView}
    // Updated - 28 Feb 2025 - switched from navigate to setActiveAdminView

    // 1 - Set Title
    useTitle('Update a Question')

    // 2 - extract the question Id from the url
    // const { id } = useParams()

  // 3 - Extract the question from the query to load the question data
  const { question } = useGetQuestionsQuery('Question', {
    selectFromResult: ({ data }) => ({
      question: data?.entities[id]
    })
  }) 

  // 4 - Check for returned data
  if(!question) {
      return <PulseLoader color={"#ea580c"} />
  }

  // 5. Display data
  let content
  content = <EditQuestionForm quest={question} id={id} setActiveAdminView={setActiveAdminView}/> // 28 Feb 2025 - added setActiveAdminView={setActiveAdminView}

  return content
  
}

const EditQuestionForm = ({quest, id, setActiveAdminView}) => {
    
    // 1 - set the variable for navigate
    const navigate = useNavigate()

    // 2 - define the mutation to update
    const [updateQuestion, {
        isLoading,
        isSuccess,
        isError,
        error,
    }] = useUpdateQuestionMutation()

    // 3 - Fill the form data with extracted question info

  const[formData,setFormData ] = useState({
        setname: quest.setname,
        questionName: quest.questionName,
        questionSubText: quest.questionSubText,
        questionHintText: quest.questionHintText,
        responseOptions: quest.questionResponseOptions || [], // 05 Apr 2024
        })
   
  // 4 - Reset the input field and navigation based on the success state 
  useEffect(() => {
    if(isSuccess) {
        setFormData({
            setname: "",
            questionName: "",
            questionSubText:"",
            questionHintText:"",
            pic: null,
            responseOptions: [],// 05 Apr 2024
          })
         // navigate('/admin/questions')
         setActiveAdminView('questions'); // 28 Feb 2025
        }
        
        },[isSuccess])// ✅ 28 Feb 2025 - Removed `navigate` since it's no longer used.

 // 5 - get all question sets

 const { qSets } = useGetQuestionSetsQuery('questionSetList', {
    selectFromResult: ( { data }) => ({
      qSets: data?.ids.map(id => data?.entities[id])
    })
  })

  const options = qSets ? [
    <option key="placeholder" value="" disabled selected>
      Select a question set
    </option>,
    ...qSets.map(set => (
      <option key={set.id} value={set.id}>
        {set.setname}
      </option>
    ))
  ] : []

// 6 - Form field updates / change handling

  // const handleChange = (e) => {
  //   const { name, value, files } = e.target
  //   if (name === "pic") {
  //     setFormData((prevState) => ({ ...prevState, pic: files[0] }))
  //   } else {
  //     setFormData((prevState) => ({ ...prevState, [name]: value }))
  //   }
  // }

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "pic") {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }));
    } else if (name.startsWith("responseOption")) {
      const index = parseInt(name.split("-")[1], 10);
      const updatedOptions = [...formData.responseOptions];
      updatedOptions[index] = value;
      setFormData((prevState) => ({ ...prevState, responseOptions: updatedOptions }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  }

  // 7 - Form Submit logic:
  const handleSubmit = async (e) => {
    e.preventDefault()

    // Initialize an object to collect the form data
    const questionData = {
      setname: formData.setname,
      questionName: formData.questionName,
      questionSubText: formData.questionSubText,
      questionHintText: formData.questionHintText,
      questionResponseOptions: formData.responseOptions,
    }

    console.log('setname:',questionData.setname)

    if (formData.pic) {
      // Convert the image to a base64 string
      const reader = new FileReader()
      reader.onloadend = async () => {
        // Once conversion is done, add the base64 string to your data object
        questionData.pic = reader.result

        // Now, send the data object to the server
        try {
            questionData.id = id
          console.log("Form submitted.")
          await updateQuestion(questionData).unwrap() // Dispatch the RTK Query mutation
        } catch (error) {
          console.error(error)
        }
      }
      reader.readAsDataURL(formData.pic)
    } else {
      // No image, send other data as is
      try {
        questionData.id = id
        console.log("Form submitted.")
        await updateQuestion(questionData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleCancel = () => {
    // navigate('/admin/questions')
    setActiveAdminView('questions'); // 28 Feb 2025
  }

  const handleClear = () => {
    setFormData({
      setname: "",
      questionName: "",
      questionSubText: "",
      questionHintText: "",
      pic: null,
      responseOptions: [],// 05 Apr 2024
    })
  }

  const handleContentChange = (newContent) => {
    setFormData(prevFormData => ({ ...prevFormData, questionName: newContent }));
}

// 05 Apr 2024 - Button to remove a response option
const handleRemoveOption = (indexToRemove) => {
  setFormData(prevState => ({
    ...prevState,
    responseOptions: prevState.responseOptions.filter((_, index) => index !== indexToRemove)
  }));
}

return (
    <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
    {isLoading && <PulseLoader color={"#ea580c"} />}
    {isError && (
      <p className="text-orange-700 font-semi-bold py-2">
        {error?.data?.message}
      </p>
    )}
    <div className="bg-white p-1 m-1 rounded">
      <p className="p-1 m-1 font-bold font-poppins text-xl">
        Update a Question
      </p>

      <div className="bg-gray-200 rounded m-1 p-1">
        <form
          onSubmit={handleSubmit}
          className="m-1 p-1 text-xs font-poppins"
        >

          <div id="">
              <select 
              name="setname"
              id='setname'
              value={formData.setname}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
              >
              {options}
              </select>
          </div>

          <div className="bg-white w-full rounded p-2 m-1">
            
            <EtContentEditorLight 
            value={formData.questionName}
            handleChange={handleContentChange}
            />
    
          </div>

          <div id="questionSubText">
            <input
              type="text"
              name="questionSubText"
              placeholder="What you would like the users to think about?"
              value={formData.questionSubText}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="questionHintText">
            <input
              type="text"
              name="questionHintText"
              placeholder="Give them some example text to guide their responses"
              value={formData.questionHintText}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>


          <div id="pic">
            <input
              type="file"
              name="pic"
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          {/* 05 Apr 2024 - Question Response Options */}
          <div id="response-options" className="w-full rounded p-2 mx-1 my-2 border border-white">
            
            <div className="flex justify-start items-center m-1 p-1">
              <div className="mx-1 px-1"><p className="text-xs font-poppins text-gray-600">Response Options:</p></div>
              <div className="mx-1 px-1">{(formData.responseOptions?.length ?? 0) < 6 && ( // Only show if there are less than 6 options
                <button
                  onClick={() =>
                    setFormData((prevState) => ({
                      ...prevState,
                      responseOptions: [...prevState.responseOptions, ""], // Add an empty string as a new option
                    }))
                  }
                  className="border border-orange-600 hover:bg-orange-600 hover:text-white text-orange-600 text-md font-poppins px-2 py-1 rounded"
                  type="button" // Ensure it doesn't submit the form
                >
                  +
                </button>
              )}</div>
            </div>
            
              {formData.responseOptions.map((option, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <input
                    type="text"
                    name={`responseOption-${index}`}
                    value={option}
                    onChange={handleChange}
                    placeholder={`Option ${index + 1}`}
                    className="w-full rounded p-2 m-1"
                  />
                  <button
                    onClick={() => handleRemoveOption(index)}
                    className="bg-red-500 hover:bg-red-600 text-white text-xs p-2 rounded"
                    type="button"
                  >
                    -
                  </button>
                </div>
              ))}

              
            </div>

          <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic
              name={"Update"}
              type={"create"}
              handleClick={handleSubmit}
            />
            <EtBtnFormBasic
              name={"Clear"}
              type={"clear"}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={"Cancel"}
              type={"cancel"}
              handleClick={handleCancel}
            />
          </div>
        </form>
      </div>
    </div>
  </div>
)
}

export default EtUpdateQuestion