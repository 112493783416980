import {
    autoformatArrow,
    autoformatLegal,
    autoformatLegalHtml,
    autoformatMath,
    autoformatPunctuation,
    autoformatSmartQuotes,
  } from '@udecode/plate-autoformat';
  
  import { autoformatBlocks } from './autoformatBlocks';
  import { autoformatMarks } from './autoformatMarks';
  
  export const autoformatRules = [
    ...autoformatBlocks,
    ...autoformatMarks,
    ...autoformatSmartQuotes,
    ...autoformatPunctuation,
    ...autoformatLegal,
    ...autoformatLegalHtml,
    ...autoformatArrow,
    ...autoformatMath,
  ];