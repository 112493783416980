import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const investigationsEntityAdapter = createEntityAdapter()

const initialState = investigationsEntityAdapter.getInitialState()

export const investigationsApiSlice = apiSlice.injectEndpoints({

    endpoints: builder => ({
        getInvestigations: builder.query({
            query: () => '/investigations',
            transformResponse: responseData => {
                const loadedInvestigations = responseData.map(investigation => {
                    // Assuming your Investigation model uses _id as well
                    investigation.id = investigation._id
                    return investigation
                })
                return investigationsEntityAdapter.setAll(initialState, loadedInvestigations)
            },
            providesTags: (result, error, arg) => result ? 
                [...result.ids.map(id => ({ type: 'Investigation', id })), { type: 'Investigation', id: 'LIST' }] : 
                [{ type: 'Investigation', id: 'LIST' }],
        }),
        createNewInvestigation: builder.mutation({
            query: newInvestigation => ({
                url: '/investigations',
                method: 'POST',
                body: {...newInvestigation,}
            }),
            invalidatesTags: (result, error, arg) =>
            
            [{ type: 'Investigation', id: arg.id }],
        }),
        updateInvestigation: builder.mutation({
            query: ({ id, ...updateData }) => ({
                url: `/investigations/${id}`,
                method: 'PATCH',
                body: updateData,
            }),
            invalidatesTags: (result, error, arg) =>
            
            [{ type: 'Investigation', id: arg.id }],

        }),
        deleteInvestigation: builder.mutation({
            query: ({id}) => ({
                url: `/investigations/${id}`,
                method: 'DELETE',
                
            }),
            invalidatesTags: (result, error, arg) =>
            
            [{ type: 'Investigation', id: arg.id }],

        }),
    })

})

export const {

    useGetInvestigationsQuery,
    useCreateNewInvestigationMutation,
    useUpdateInvestigationMutation,
    useDeleteInvestigationMutation,

} = investigationsApiSlice

export const selectInvestigationResult = investigationsApiSlice.endpoints.getInvestigations.select()

// create memoized selector
const selectInvestigationData = createSelector(
    selectInvestigationResult,
    investigationResult => investigationResult.data
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllInvestigations,
    selectById: selectInvestigationById,
    selectIds: selectInvestigationIds
} = investigationsEntityAdapter.getSelectors(state => selectInvestigationData(state) ?? initialState)