import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userMissionOrgsAdapter = createEntityAdapter({})

const initialState = userMissionOrgsAdapter.getInitialState()

export const userMissionOrgsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserMissionOrgs: builder.query({
            query: () => ({
                url: '/userMissionOrgs/getUserMissionOrgs',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserMissionOrgs = responseData.map(userMissionOrg => {
                    userMissionOrg.id = userMissionOrg._id
                    return userMissionOrg
                })
                return userMissionOrgsAdapter.setAll(initialState, loadedUserMissionOrgs)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserMissionOrg', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserMissionOrg', id}))
                    ]
                } else {
                    return [ { type: 'UserMissionOrg', id: 'LIST'}]
                }
            },
        }),
        createNewUserMissionOrg: builder.mutation({
            query: initialUserMissionOrg => ({
                url: '/userMissionOrgs/createNewUserMissionOrg',
                method: 'POST',
                body: {...initialUserMissionOrg,}
            }),
            invalidatesTags: [
                { type: 'UserMissionOrg', id: "LIST" }
            ] 
        }),
        updateUserMissionOrg: builder.mutation({
            query: initialUserMissionOrg => ({
                url: '/userMissionOrgs/updateUserMissionOrg',
                method: 'PATCH',
                body: {...initialUserMissionOrg,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserMissionOrg', id: arg.id },
                { type: 'UserMissionOrg', id: 'LIST' }
            ],
        }),
        deleteUserMissionOrg: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userMissionOrgs/deleteUserMissionOrg',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userMissionOrgs/deleteUserMissionOrg',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserMissionOrg', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserMissionOrgsQuery,
    useCreateNewUserMissionOrgMutation,
    useUpdateUserMissionOrgMutation,
    useDeleteUserMissionOrgMutation,
} = userMissionOrgsApiSlice

// returns the query result object
export const selectUserMissionOrgResult = userMissionOrgsApiSlice.endpoints.getUserMissionOrgs.select()

// create memoized selector
const selectUserMissionOrgsData = createSelector(
    selectUserMissionOrgResult,
    userMissionOrgResult => userMissionOrgResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserMissionOrgs,
    selectById: selectUserMissionOrgById,
    selectIds: selectUserMissionOrgIds,
} = userMissionOrgsAdapter.getSelectors(state => selectUserMissionOrgsData(state) ?? initialState)