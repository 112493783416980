import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userSessionIlluminationsAdapter = createEntityAdapter({})

const initialState = userSessionIlluminationsAdapter.getInitialState()

export const userSessionIlluminationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserSessionIlluminations: builder.query({
            query: () => ({
                url: '/userSessionIlluminations/getUserSessionIlluminations',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserSessionIlluminations = responseData.map(userSessionIllumination => {
                    userSessionIllumination.id = userSessionIllumination._id
                    return userSessionIllumination
                })
                return userSessionIlluminationsAdapter.setAll(initialState, loadedUserSessionIlluminations)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserSessionIllumination', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserSessionIllumination', id}))
                    ]
                } else {
                    return [ { type: 'UserSessionIllumination', id: 'LIST'}]
                }
            },
        }),
        createNewUserSessionIllumination: builder.mutation({
            query: initialUserSessionIllumination => ({
                url: '/userSessionIlluminations/createNewUserSessionIllumination',
                method: 'POST',
                body: {...initialUserSessionIllumination,}
            }),
            invalidatesTags: [
                { type: 'UserSessionIllumination', id: "LIST" }
            ] 
        }),
        updateUserSessionIllumination: builder.mutation({
            query: initialUserSessionIllumination => ({
                url: '/userSessionIlluminations/updateUserSessionIllumination',
                method: 'PATCH',
                body: {...initialUserSessionIllumination,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserSessionIllumination', id: arg.id },
                { type: 'UserSessionIllumination', id: 'LIST' }
            ],
        }),
        deleteUserSessionIllumination: builder.mutation({
            query: ({id}) => ({
                url: '/userSessionIlluminations/deleteUserSessionIllumination',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserSessionIllumination', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserSessionIlluminationsQuery,
    useCreateNewUserSessionIlluminationMutation,
    useUpdateUserSessionIlluminationMutation,
    useDeleteUserSessionIlluminationMutation,
} = userSessionIlluminationsApiSlice

// returns the query result object
export const selectUserSessionIlluminationResult = userSessionIlluminationsApiSlice.endpoints.getUserSessionIlluminations.select()

// create memoized selector
const selectUserSessionIlluminationsData = createSelector(
    selectUserSessionIlluminationResult,
    userSessionIlluminationResult => userSessionIlluminationResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserSessionIlluminations,
    selectById: selectUserSessionIlluminationById,
    selectIds: selectUserSessionIlluminationIds,
} = userSessionIlluminationsAdapter.getSelectors(state => selectUserSessionIlluminationsData(state) ?? initialState)