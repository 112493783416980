import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const contactOrganizationTypesAdapter = createEntityAdapter({})

const initialState = contactOrganizationTypesAdapter.getInitialState()

export const contactOrganizationTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getContactOrganizationTypes: builder.query({
            query: () => ({
                url: '/contactOrganizationTypes/getContactOrganizationTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedContactOrganizationTypes = responseData.map(contactOrganizationType => {
                    contactOrganizationType.id = contactOrganizationType._id
                    return contactOrganizationType
                })
                return contactOrganizationTypesAdapter.setAll(initialState, loadedContactOrganizationTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'ContactOrganizationType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'ContactOrganizationType', id}))
                    ]
                } else {
                    return [ { type: 'ContactOrganizationType', id: 'LIST'}]
                }
            },
        }),
        createNewContactOrganizationType: builder.mutation({
            query: initialContactOrganizationType => ({
                url: '/contactOrganizationTypes/createNewContactOrganizationType',
                method: 'POST',
                body: {...initialContactOrganizationType,}
            }),
            invalidatesTags: [
                { type: 'ContactOrganizationType', id: "LIST" }
            ] 
        }),
        updateContactOrganizationType: builder.mutation({
            query: initialContactOrganizationType => ({
                url: '/contactOrganizationTypes/updateContactOrganizationType',
                method: 'PATCH',
                body: {...initialContactOrganizationType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'ContactOrganizationType', id: arg.id },
                { type: 'ContactOrganizationType', id: 'LIST' }
            ],
        }),
        deleteContactOrganizationType: builder.mutation({
            query: ({id}) => ({
                url: '/contactOrganizationTypes/deleteContactOrganizationType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'ContactOrganizationType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetContactOrganizationTypesQuery,
    useCreateNewContactOrganizationTypeMutation,
    useUpdateContactOrganizationTypeMutation,
    useDeleteContactOrganizationTypeMutation,
} = contactOrganizationTypesApiSlice

// returns the query result object
export const selectContactOrganizationTypeResult = contactOrganizationTypesApiSlice.endpoints.getContactOrganizationTypes.select()

// create memoized selector
const selectContactOrganizationTypesData = createSelector(
    selectContactOrganizationTypeResult,
    contactOrganizationTypeResult => contactOrganizationTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllContactOrganizationTypes,
    selectById: selectContactOrganizationTypeById,
    selectIds: selectContactOrganizationTypeIds,
} = contactOrganizationTypesAdapter.getSelectors(state => selectContactOrganizationTypesData(state) ?? initialState)