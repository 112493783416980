import React, { useState, useEffect, useMemo } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLightWithPlace from "@/utils/EtContentEditorLightWithPlace"
import { useGetCapabilitiesSalesFunctionAreaTypesQuery, useUpdateCapabilitiesSalesFunctionAreaTypeMutation } from "./capabilitiesSalesFunctionAreaTypesApiSlice"
import { useGetCapabilitiesSalesTypesQuery } from '../capabilitiesSalesTypes/capabilitiesSalesTypesApiSlice' // 14 Nov 2024

const EtUpdateCapabilitiesSalesFunctionAreaType = ({id, setActiveAdminView}) => {
  // Created - 18 Nov 2024
  // Author - Atul Pandey
  // Inherited from functionTypes
  // Updated - 28 Feb 2025 - updated the salesFunctionArea with props - { id, setActiveAdminView}
  // Updated - 28 Feb 2025 - switched from navigate to setActiveAdminView

  // 1 - Set Title
  useTitle("Update CapabilitiesSalesFunctionArea Type")

  // 2 - extract the capabilitiesSalesFunctionAreaType Id from the url
  // const { id } = useParams()
  // //console.log("id:", id)

  // 3 - Extract the capabilitiesSalesFunctionAreaType from the query to load the capabilitiesSalesFunctionAreaType data
  const { capabilitiesSalesFunctionAreaType } = useGetCapabilitiesSalesFunctionAreaTypesQuery("CapabilitiesSalesFunctionAreaType", {
    selectFromResult: ({ data }) => ({
      capabilitiesSalesFunctionAreaType: data?.entities[id],
    }),
  })

  //console.log('capabilitiesSalesFunctionArea type:', capabilitiesSalesFunctionAreaType)

  // 4 - Check for returned data
  if (!capabilitiesSalesFunctionAreaType) {
    return <PulseLoader color={"#ea580c"} />
  }

  // 5. Display data
  let content
  content = <EtUpdateCapabilitiesSalesFunctionAreaTypeForm capabilitiesSalesFunctionAreaType={capabilitiesSalesFunctionAreaType} id={id} setActiveAdminView={setActiveAdminView}/> // 28 Feb 2025 - added setActiveAdminView={setActiveAdminView}
  return content
}

const EtUpdateCapabilitiesSalesFunctionAreaTypeForm = ({ capabilitiesSalesFunctionAreaType, id,setActiveAdminView}) =>{
  // 1a - set the variable for navigate
  const navigate = useNavigate()
  //console.log('update form invoked!')
  console.log('capabilitiesSalesFunctionAreaType', capabilitiesSalesFunctionAreaType)

  // 1b - state variables for sales types
  // const [selectedFocus, setSelectedFocus] = useState("");
  // const [filteredSalesTypes, setFilteredSalesTypes] = useState([]);
  
  // 2 - set the mutation to create the new section data
  const[updateCapabilitiesSalesFunctionAreaType, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useUpdateCapabilitiesSalesFunctionAreaTypeMutation()

  // 2b - Get SalesTypes Data
  const { data: salesTypesData } = useGetCapabilitiesSalesTypesQuery();
  const salesTypes = useMemo(() => {
    return salesTypesData?.ids?.map(id => salesTypesData?.entities[id]) || [];
  }, [salesTypesData]);
  console.log('salesTypes', salesTypes)

  // 2c - Set initial values for selectedFocus and filteredSalesTypes when component mounts
  // useEffect(() => {
  //   if (capabilitiesSalesFunctionAreaType?.capabilitiesSalesType) {
  //     const initialFocus = capabilitiesSalesFunctionAreaType?.capabilitiesSalesType?.capabilitiesSalesFocus;
  //     setSelectedFocus(initialFocus);
      
  //     // Set filtered sales types based on the initial focus
  //     setFilteredSalesTypes(
  //       salesTypes.filter((sales) => sales?.capabilitiesSalesFocus === initialFocus)
  //     );

  //     // Set the initial value for capabilitiesSalesType in formData
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       capabilitiesSalesType: capabilitiesSalesFunctionAreaType?.capabilitiesSalesType._id,
  //     }));
  //   }
  // }, [capabilitiesSalesFunctionAreaType, salesTypes]);
  


// 3 - set the form data object
const [formData, setFormData] = useState({
  capabilitiesSalesFunctionAreaTypeName: capabilitiesSalesFunctionAreaType.capabilitiesSalesFunctionAreaTypeName,
  capabilitiesSalesFunctionAreaTypeDesc: capabilitiesSalesFunctionAreaType.capabilitiesSalesFunctionAreaTypeDesc,
  aboutMe: capabilitiesSalesFunctionAreaType.aboutMe,
})

//console.log('formData for update:', formData)
useEffect(() => {
  if (capabilitiesSalesFunctionAreaType?.capabilitiesSalesType) {
    setFormData((prevData) => ({
      ...prevData,
      capabilitiesSalesType: capabilitiesSalesFunctionAreaType.capabilitiesSalesType._id,
    }));
  }
}, [capabilitiesSalesFunctionAreaType]);

// 4 - Reset form upon successful submission
useEffect(() => {
  if(isSuccess) {
    setFormData({
      capabilitiesSalesFunctionAreaTypeName: "",
      capabilitiesSalesFunctionAreaTypeDesc: "",
      capabilitiesSalesType: null,
      aboutMe: "",
      pic: "",
    })

    // navigate('/capabilitiesSalesFunctionAreatypes')
    setActiveAdminView('capabilitiesSalesFunctionAreaTypes'); // 28 Feb 2025
  }

},[isSuccess]) // ✅ 28 Feb 2025 - Removed `navigate` since it's no longer used.

// 5 - Form field updates / change handling

const handleChange = (e) => {
  const { name, value, files } = e.target
  if (name === "pic") {
    setFormData(prevState => ({ ...prevState, pic: files[0] }))
  } else {
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }
}

// 6 - salesFunctionArea to strip html tags from sections
const stripHtml = (html) => {
  return html.replace(/<\/?[^>]+(>|$)/g, "");
}

// 7 - Form Submit Logic
const handleSubmit = async (e) => {
e.preventDefault()

// Validate that capabilitiesSalesType is selected
if (!formData.capabilitiesSalesType) {
  alert("Please select a Sales Type before submitting.");
  return;
}

// Initialize an object to collect the form data
const capabilitiesSalesFunctionAreaTypeData = {
  capabilitiesSalesFunctionAreaTypeName: formData.capabilitiesSalesFunctionAreaTypeName,
  capabilitiesSalesFunctionAreaTypeDesc: formData.capabilitiesSalesFunctionAreaTypeDesc,
  capabilitiesSalesType: formData.capabilitiesSalesType,
  aboutMe: formData.aboutMe,
}

//console.log('capabilitiesSalesFunctionAreaType Name:',capabilitiesSalesFunctionAreaTypeData.capabilitiesSalesFunctionAreaTypeName)

if (formData.pic) {
  // Convert the image to a base64 string
  const reader = new FileReader()
  reader.onloadend = async () => {
    // Once conversion is done, add the base64 string to your data object
    capabilitiesSalesFunctionAreaTypeData.pic = reader.result

    // Now, send the data object to the server
    try {
      capabilitiesSalesFunctionAreaTypeData.id = id
      console.log("Form submitted.")
      await updateCapabilitiesSalesFunctionAreaType(capabilitiesSalesFunctionAreaTypeData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
  }
  reader.readAsDataURL(formData.pic)
} else {
  // No image, send other data as is
  try {
    capabilitiesSalesFunctionAreaTypeData.id = id
    console.log("Form submitted.")
    await updateCapabilitiesSalesFunctionAreaType(capabilitiesSalesFunctionAreaTypeData).unwrap() // Dispatch the RTK Query mutation
  } catch (error) {
    console.error(error)
  }
}
}

// 8 - Handle Cancel

const handleCancel = () => {
  // navigate("/capabilitiesSalesFunctionAreatypes")
  setActiveAdminView('capabilitiesSalesFunctionAreaTypes'); // 28 Feb 2025
}

// 9 - Handle Clear
const handleClear = () => {
  setFormData({
    capabilitiesSalesFunctionAreaTypeName: "",
    capabilitiesSalesFunctionAreaTypeDesc: "",
    capabilitiesSalesType: null,
    aboutMe: "",
    pic: "",
    })
}

return (
  <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
    {isLoading && <PulseLoader color={"#ea580c"} />}
    {isError && (
      <p className="text-orange-700 font-semi-bold py-2">
        {error?.data?.message}
      </p>
    )}
    <div className="bg-white p-1 m-1 rounded">
      <p className="p-1 m-1 font-bold font-poppins text-xl">Update a CapabilitiesSalesFunctionArea Type</p>

      <div className="bg-gray-200 rounded m-1 p-1">
        <form
          onSubmit={handleSubmit}
          className="m-1 p-1 text-xs font-poppins"
        >

          {/* <div id="focus">
            <select
              name="focus"
              value={selectedFocus}
              onChange={(e) => {
                const newFocus = e.target.value;
                setSelectedFocus(newFocus);
                setFilteredSalesTypes(
                  salesTypes?.filter((sales) => sales?.capabilitiesSalesFocus === newFocus)
                );
                // Reset the selected sales type if the focus changes
                setFormData((prevData) => ({ ...prevData, capabilitiesSalesType: "" }));
              }}
              className="w-full rounded p-2 m-1"
            >
              <option value="">Select Sales Focus</option>
              <option value="Business">Business</option>
              <option value="Technology">Technology</option>
            </select>
          </div> */}

          <div id="salesType">
            <select
              name="capabilitiesSalesType"
              value={formData.capabilitiesSalesType || ""}
              onChange={(e) => setFormData({ ...formData, capabilitiesSalesType: e.target.value })}
              className="w-full rounded p-2 m-1"
              // disabled={!selectedFocus} // Disable if no focus is selected
            >
              <option value="">Select Sales Type</option>
              {salesTypes?.map((sales) => (
                <option key={sales._id} value={sales._id}>
                  {sales?.capabilitiesSalesTypeName}
                </option>
              ))}
              {/* {filteredSalesTypes?.map((sales) => (
                <option key={sales._id} value={sales._id}>
                  {sales?.capabilitiesSalesTypeName}
                </option>
              ))} */}
            </select>
          </div>


          
          <div id="capabilitiesSalesFunctionAreaTypeName">
            <input
              type="text"
              name="capabilitiesSalesFunctionAreaTypeName"
              placeholder="Enter the capabilitiesSalesFunctionArea type name here"
              value={formData.capabilitiesSalesFunctionAreaTypeName}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div className="bg-white w-full rounded p-2 m-1">
            <EtContentEditorLightWithPlace
              value={formData.capabilitiesSalesFunctionAreaTypeDesc}
              handleChange={(content) =>
                setFormData({ ...formData, capabilitiesSalesFunctionAreaTypeDesc: content })
              }
              placeholderText={
                "Write this capabilitiesSalesFunctionArea type is about or will be used for"
              }
              // toolbarId={'sectionDesc'}
            />
          </div>

          <div id="aboutMe">
            <input
              type="text"
              name="aboutMe"
              placeholder="Add something about the personality about this capabilitiesSalesFunctionArea type"
              value={formData.aboutMe}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="pic">
            <input
              type="file"
              name="pic"
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

         <div id="capabilitiesSalesFunctionAreas" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic
              name={"Update"}
              type={"create"}
              handleClick={handleSubmit}
            />
            <EtBtnFormBasic
              name={"Clear"}
              type={"clear"}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={"Cancel"}
              type={"cancel"}
              handleClick={handleCancel}
            />
          </div>
        </form>
      </div>
    </div>
  </div>
)

}

export default EtUpdateCapabilitiesSalesFunctionAreaType