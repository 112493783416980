import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const organizationsAdapter = createEntityAdapter({})

const initialState = organizationsAdapter.getInitialState()

export const organizationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOrganizations: builder.query({
            query: () => ({
                url: '/organizations/getOrganizations',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedOrganizations = responseData.map(organization => {
                    organization.id = organization._id
                    return organization
                })
                return organizationsAdapter.setAll(initialState, loadedOrganizations)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'Organization', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'Organization', id}))
                    ]
                } else {
                    return [ { type: 'Organization', id: 'LIST'}]
                }
            },
        }),
        createNewOrganization: builder.mutation({
            query: initialOrganization => ({
                url: '/organizations/createNewOrganization',
                method: 'POST',
                body: {...initialOrganization,}
            }),
            invalidatesTags: [
                { type: 'Organization', id: "LIST" }
            ] 
        }),
        updateOrganization: builder.mutation({
            query: initialOrganization => ({
                url: '/organizations/updateOrganization',
                method: 'PATCH',
                body: {...initialOrganization,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Organization', id: arg.id },
                { type: 'Organization', id: 'LIST' }
            ],
        }),
        deleteOrganization: builder.mutation({
            query: ({id}) => ({
                url: '/organizations/deleteOrganization',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Organization', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetOrganizationsQuery,
    useCreateNewOrganizationMutation,
    useUpdateOrganizationMutation,
    useDeleteOrganizationMutation,
} = organizationsApiSlice

// returns the query result object
export const selectOrganizationResult = organizationsApiSlice.endpoints.getOrganizations.select()

// create memoized selector
const selectOrganizationsData = createSelector(
    selectOrganizationResult,
    organizationResult => organizationResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllOrganizations,
    selectById: selectOrganizationById,
    selectIds: selectOrganizationIds,
} = organizationsAdapter.getSelectors(state => selectOrganizationsData(state) ?? initialState)