import React, {useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router'
import useTitle from '@/hooks/useTitle'
import EtBtnFormBasic from '@/components/common/buttons/EtBtnFormBasic'
import { useGetSubsPacksQuery, useDeleteSubsPackMutation } from './subscriptionPacksApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import Modal from '@/components/common/modal/Modal'


const EtDeleteSubsPack = ({id, setActiveAdminView}) => {
  // First Created - 04 Apr 2024
  // Author - Atul Pandey
  // Updated - 01 Mar 2025 - updated the softSkillFunctionArea with props - { id, setActiveAdminView}
  // Updated - 01 Mar 2025 - switched from navigate to setActiveAdminView

   // 0 - Set the page title
   useTitle('Delete a Subscription Package')

   // 1 - extract the trail id to be edited from the url
  //  const {id} = useParams()
  //  console.log('Subscription id:',id)
 
   // 2 - Extract the Subscription pack data through the useGet query
   const { subsPack } = useGetSubsPacksQuery('SubsPacksList', {
    selectFromResult: ({ data }) => ({
        subsPack: data?.entities[id]
    }),
    })
    console.log('subsPack:',subsPack)

    // 3 - Check for returned data
    if(!subsPack) {
    return <PulseLoader color={"#ea580c"} />
    }
 
   //4 - Send the data for deletion
    let content 
    content = <EtDeleteSubsPackForm subsPack={subsPack} id={id} setActiveAdminView={setActiveAdminView}/> // 01 Mar 2025 - added setActiveAdminView={setActiveAdminView}
    return content
}

const EtDeleteSubsPackForm = ({subsPack, id, setActiveAdminView}) => {
    // 1 - set the navigate hook
    const navigate = useNavigate()

    // 2 - Delete Section modal
    const [isModalOpen, setIsModalOpen] = useState(false)

    // 2a - The mutation to delete the Subscription Pack Data
    const [deleteSubsPack, {
        isLoading,
        isSuccess,
        isError,
        error,
    }] = useDeleteSubsPackMutation()

    // 3 - Set the data received in the component props to the local variables
    const [formData, setFormData] = useState({
        subscriptionPackName: subsPack.subscriptionPackName,
      })

    // 4. Reset the input field and navigation based on the success state 
    useEffect(() => {
        if(isSuccess) {
            setFormData({
                subscriptionPackName: "",
              })
            // navigate('/subscriptionPacks')
         setActiveAdminView('subsPacks'); // 01 Mar 2025
        }
        
        },[isSuccess])// ✅ 01 Mar 2025 - Removed `navigate` since it's no longer used.

    // 5 - Form field updates / change handling

    // Modal management functions
    const handleOpenModal = () => setIsModalOpen(true)
    const handleCloseModal = () => setIsModalOpen(false)

  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }))
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }))
    }
  }

  // 6. Form Submit logic:
  const handleConfirm = async (e) => {
    e.preventDefault()
    handleCloseModal()

    // Initialize an object to collect the form data
    const subscriptionPackData = {
      subscriptionPackName: formData.subscriptionPackName,
    }

    if (formData.pic) {
      // Convert the image to a base64 string
      const reader = new FileReader()
      reader.onloadend = async () => {
        // Once conversion is done, add the base64 string to your data object
        subscriptionPackData.pic = reader.result

        // Now, send the data object to the server
        try {
            subscriptionPackData.id = id
          console.log("Form submitted.")
          await deleteSubsPack(subscriptionPackData).unwrap() // Dispatch the RTK Query mutation
        } catch (error) {
          console.error(error)
        }
      }
      reader.readAsDataURL(formData.pic)
    } else {
      // No image, send other data as is
      try {
        subscriptionPackData.id = id
        console.log("Form submitted.")
        await deleteSubsPack(subscriptionPackData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleCancel = () => {
    // navigate('/subscriptionPacks')
    setActiveAdminView('subsPacks'); // 01 Mar 2025
  }

return (
    <div className="bg-gray-200 p-1 mx-auto my-5 rounded-lg shadow-md shadow-black">
      {isLoading && <PulseLoader color={"#ea580c"} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">
          {error?.data?.message}
        </p>
      )}
      <div className="bg-white p-1 m-1 rounded">
        <p className="p-1 m-1 font-bold font-poppins text-xl">
          Delete a Subscription Pack
        </p>

        <div className="bg-gray-200 rounded m-1 p-1">
          <form
            onSubmit={handleOpenModal}
            className="m-1 p-1 text-sm font-poppins"
          >
            <div id="subscriptionPackName">
              <input
                type="text"
                name="subscriptionPackName"
                placeholder="Subscription Pack Name"
                readOnly={true}
                value={formData.subscriptionPackName}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Delete"}
                type={"create"}
                handleClick={handleOpenModal}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>
          </form>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleConfirm} />
    </div>
  )


}

export default EtDeleteSubsPack