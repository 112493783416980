import React, { useState, useEffect } from 'react'
import useTitle from "../../../hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
    PencilSquareIcon,
  } from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "../../../utils/EtContentEditorLight2"
import EtBtnFormBasic from "../../../components/common/buttons/EtBtnFormBasic"
import { useNavigate } from "react-router"

import { useCreateNewUserImprintShowcaseMutation, useGetUserImprintShowcasesQuery } from './userImprintShowcasesApiSlice'
import { useGetCapabilitiesIndustryTypesQuery } from '@/features/capabilitiesIndustryTypes/capabilitiesIndustryTypesApiSlice'
import { useGetCapabilitiesDomainTypesQuery } from '@/features/capabilitiesDomainTypes/capabilitiesDomainTypesApiSlice'
import { useGetCapabilitiesFunctionTypesQuery } from '@/features/capabilitiesFunctionTypes/capabilitiesFunctionTypesApiSlice'
import { useGetCapabilitiesTechAreaTypesQuery } from '@/features/capabilitiesTechAreaTypes/capabilitiesTechAreaTypesApiSlice'
import { useGetCapabilitiesTechStackTypesQuery } from '@/features/capabilitiesTechStackTypes/capabilitiesTechStackTypesApiSlice'
import { useGetCapabilitiesSalesTypesQuery } from '@/features/capabilitiesSalesTypes/capabilitiesSalesTypesApiSlice'
import { useGetCapabilitiesServiceTypesQuery } from '@/features/capabilitiesServiceTypes/capabilitiesServiceTypesApiSlice'
import { useGetCapabilitiesAdvisoryTypesQuery } from '@/features/capabilitiesAdvisoryTypes/capabilitiesAdvisoryTypesApiSlice'
import { useGetShowcaseTypesQuery } from '@/features/showcaseTypes/showcaseTypesApiSlice'

import { useGetProblemAreaTypesQuery } from '@/features/problemAreaTypes/problemAreaTypesApiSlice.js'
import { useGetProblemMetricTypesQuery } from '@/features/problemMetricTypes/problemMetricTypesApiSlice.js'
import { useGetProblemMetricGradationTypesQuery } from '@/features/problemMetricGradationTypes/problemMetricGradationTypesApiSlice.js'
import { useGetProblemMetricUoMTypesQuery } from '@/features/problemMetricUoMTypes/problemMetricUoMTypesApiSlice.js'

import { produce } from 'immer';

const EtUpdateUserImprintShowcaseComp = ({
    userId, 
    userOrgId, 
    triggeringUrl, 
    onClose
  }) => {
  
  // First Created - 03 Dec 2024
  // Author - Atul Pandey
  // Inherited from the EtUpdateUserImprintRole component
  // Updated - 05 Dec 2024 - Added the core logic

  // 0 - Set Page title & variables
  useTitle('User Imprint Showcases')

  // 1a - set page navigations
  const navigate = useNavigate()

  // 1b - set form states
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState({}); // 05 Dec 2024
  const [editorKey, setEditorKey] = useState('showcaseDesc-' + Date.now());
  const [editorValue, setEditorValue] = useState('');
  
  // 2a - set the mutation to create the new user imprint Role data
  const [createNewUserImprintShowcase, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewUserImprintShowcaseMutation()

  // 2b - Get existing user imprint roles Data from the backend
  const { 
    data: userImprintShowcaseData,
    isLoading: isFetching,
    isError: isFetchError,
    error: fetchError,
  } = useGetUserImprintShowcasesQuery()
  const userImprintShowcaseRecords = userImprintShowcaseData?.ids
  ?.map(id => userImprintShowcaseData?.entities[id])
  ?.filter(item=> item.userId._id === userId && item.userOrgId._id === userOrgId) || []
  console.log('userImprintShowcases', userImprintShowcaseRecords)
  
  // 2c - Get Data for building Showcase Areas
  const { data: industryTypesData} = useGetCapabilitiesIndustryTypesQuery()
  const { data: domainTypesData} = useGetCapabilitiesDomainTypesQuery()
  const { data: functionTypesData} = useGetCapabilitiesFunctionTypesQuery()
  const { data: techAreaTypesData} = useGetCapabilitiesTechAreaTypesQuery()
  const { data: techStackTypesData} = useGetCapabilitiesTechStackTypesQuery()
  const { data: salesTypesData} = useGetCapabilitiesSalesTypesQuery()
  const { data: serviceTypesData} = useGetCapabilitiesServiceTypesQuery()
  const { data: advisoryTypesData} = useGetCapabilitiesAdvisoryTypesQuery()
  const { data: showcaseTypesData} = useGetShowcaseTypesQuery()
  const { data: problemAreaTypesData} = useGetProblemAreaTypesQuery() 
  const { data: problemMetricTypesData} = useGetProblemMetricTypesQuery() 
  const { data: problemMetricGradationTypesData} = useGetProblemMetricGradationTypesQuery() 
  const { data: problemMetricUoMTypesData} = useGetProblemMetricUoMTypesQuery() 

  const extractEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id]);
  };

  const industryTypes = extractEntitiesFromAdapter(industryTypesData);
  const domainTypes = extractEntitiesFromAdapter(domainTypesData);
  const functionTypes = extractEntitiesFromAdapter(functionTypesData);
  const techAreaTypes = extractEntitiesFromAdapter(techAreaTypesData);
  const techStackTypes = extractEntitiesFromAdapter(techStackTypesData);
  const salesTypes = extractEntitiesFromAdapter(salesTypesData);
  const serviceTypes = extractEntitiesFromAdapter(serviceTypesData);
  const advisoryTypes = extractEntitiesFromAdapter(advisoryTypesData);
  const showCaseTypes = extractEntitiesFromAdapter(showcaseTypesData)
  const problemAreaTypes = extractEntitiesFromAdapter(problemAreaTypesData) 
  const problemMetricTypes = extractEntitiesFromAdapter(problemMetricTypesData) 
  const problemMetricGradationTypes = extractEntitiesFromAdapter(problemMetricGradationTypesData) 
  const problemMetricUoMTypes = extractEntitiesFromAdapter(problemMetricUoMTypesData) 

  // 3 - Initialize Form Data State
  const [formData, setFormData] = useState({
    userImprintShowcaseHeadlineName: "",
    userImprintShowcaseHeadlineDesc: "",
    userImprintShowcases: [], // Array for showcases
    userImprintShowcaseSelfNotes: [], // Array for self-notes
    aboutMe: "",
    pic: "",
    active: true, // Default active state
  });

  // 4 - Set the FormData based on the incoming data
  useEffect(() => {
    if (userImprintShowcaseRecords.length > 0 && !isFormInitialized) {
      const selectedRecord = userImprintShowcaseRecords[0];
  
      setFormData({
        userImprintShowcaseHeadlineName: selectedRecord.userImprintShowcaseHeadlineName || '',
        userImprintShowcaseHeadlineDesc: selectedRecord.userImprintShowcaseHeadlineDesc || '',
        userImprintShowcases: selectedRecord.userImprintShowcases || [],
        userImprintShowcaseSelfNotes: selectedRecord.userImprintShowcaseSelfNotes || [],
        aboutMe: selectedRecord.aboutMe || '',
        pic: selectedRecord.pic || '',
        active: selectedRecord.active ?? true,
      });
      setIsFormInitialized(true); // Prevent future overwrites
    }
  }, [userImprintShowcaseRecords, isFormInitialized]);

  // 5a - Convert Image to Base64
  const convertPicToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  // 5b - Handle Form Changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "pic") {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  // 5c - Toggle show / collapse each section 
  const toggleSection = (key) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // 6 - Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Step 1: Convert the pic field to Base64 if it exists
      const picBase64 = formData.pic ? await convertPicToBase64(formData.pic) : undefined;
  
      // Step 2: Construct the payload
      const payload = {
        userId,
        userOrgId,
        userImprintShowcaseHeadlineName: formData.userImprintShowcaseHeadlineName.trim() || undefined,
        userImprintShowcaseHeadlineDesc: formData.userImprintShowcaseHeadlineDesc.trim() || undefined,
        userImprintShowcases: [
          ...(formData.userImprintShowcases?.map((showcase) => ({
            _id: showcase._id || undefined,
            userShowcaseTypeId: showcase.userShowcaseTypeId || undefined,
            userShowcaseName: showcase.userShowcaseName || undefined,
            userShowcaseDesc: showcase.userShowcaseDesc || undefined,
            userShowcaseTargetType: showcase.userShowcaseTargetType || undefined,
            userShowcaseTargetName: showcase.userShowcaseTargetName || undefined,
            userShowcaseTargetDesc: showcase.userShowcaseTargetDesc || undefined,
            userShowcaseStartDate: showcase.userShowcaseStartDate || undefined,
            userShowcaseEndDate: showcase.userShowcaseEndDate || undefined,
            userShowcaseIndustry: showcase.userShowcaseIndustry?._id || showcase.userShowcaseIndustry || undefined,
            userShowcaseDomain: showcase.userShowcaseDomain?._id || showcase.userShowcaseDomain || undefined,
            userShowcaseFunction: showcase.userShowcaseFunction?._id || showcase.userShowcaseFunction || undefined,
            userShowcaseTechArea: showcase.userShowcaseTechArea?._id || showcase.userShowcaseTechArea || undefined,
            userShowcaseTechStack: showcase.userShowcaseTechStack?._id || showcase.userShowcaseTechStack || undefined,
            userShowcaseAdvisory: showcase.userShowcaseAdvisory?._id || showcase.userShowcaseAdvisory || undefined,
            userShowcaseSales: showcase.userShowcaseSales?._id || showcase.userShowcaseSales || undefined,
            userShowcaseService: showcase.userShowcaseService?._id || showcase.userShowcaseService || undefined,
            userShowcaseProblems: [
              ...(showcase.userShowcaseProblems?.map((problem) => ({
                _id: problem._id || undefined,
                problemName: problem.problemName || undefined,
                problemDesc: problem.problemDesc || undefined,
                problemAreaType: problem.problemAreaType?._id || problem.problemAreaType || undefined,
                problemMetricType: problem.problemMetricType?._id || problem.problemMetricType || undefined,
                problemMetricUoMType: problem.problemMetricUoMType?._id || problem.problemMetricUoMType || undefined,
                problemMetricGradationType: problem.problemMetricGradationType?._id || problem.problemMetricGradationType || undefined,
                problemCurrentMeasureLevel: problem.problemCurrentMeasureLevel || 0,
                delete: problem.delete || false,
              })) || []),
              ...(formData.deletedProblems || []),
            ],
            userShowcaseHeadwinds: [
              ...(showcase.userShowcaseHeadwinds?.map((headwind) => ({
                _id: headwind._id || undefined,
                headwindName: headwind.headwindName || undefined,
                headwindDesc: headwind.headwindDesc || undefined,
                delete: headwind.delete || false,
              })) || []),
              ...(formData.deletedHeadwinds || []),
            ],
            userShowcaseApproachLevers: [
              ...(showcase.userShowcaseApproachLevers?.map((lever) => ({
                _id: lever._id || undefined,
                approachLeverName: lever.approachLeverName || undefined,
                approachLeverDesc: lever.approachLeverDesc || undefined,
                delete: lever.delete || false,
              })) || []),
              ...(formData.deletedApproachLevers || []),
            ],
            userShowcaseActionsAndResults: [
              ...(showcase.userShowcaseActionsAndResults?.map((action) => ({
                _id: action._id || undefined,
                actionName: action.actionName || undefined,
                actionDesc: action.actionDesc || undefined,
                actionResults: [
                  ...(action.actionResults?.map((result) => ({
                    _id: result._id || undefined,
                    problems: [
                      ...(result.problems?.map((problem) => ({
                        _id: problem._id || undefined,
                        problemName: problem.problemName || undefined,
                        problemPreActionLevel: problem.problemPreActionLevel || 0,
                        problemPostActionLevel: problem.problemPostActionLevel || 0,
                        problemMetricUoMType: problem.problemMetricUoMType?._id || problem.problemMetricUoMType || undefined,
                        problemMetricGradationType: problem.problemMetricGradationType?._id || problem.problemMetricGradationType || undefined,
                        delete: problem.delete || false,
                      })) || []),
                      ...(formData.deletedActionProblems || []),
                    ],
                    resultMagnitude: result.resultMagnitude || undefined,
                    resultDirection: result.resultDirection || undefined,
                    resultLongevity: result.resultLongevity || undefined,
                    resultNotes: [
                      ...(result.resultNotes?.map((note) => ({
                        _id: note._id || undefined,
                        resultNoteText: note.resultNoteText || undefined,
                        resultNoteDate: note.resultNoteDate || undefined,
                        resultNoteUpdateDate: note.resultNoteUpdateDate || undefined,
                        delete: note.delete || false,
                      })) || []),
                      ...(formData.deletedResultNotes || []),
                    ],
                    delete: result.delete || false,
                  })) || []),
                  ...(formData.deletedActionResults || []),
                ],
                delete: action.delete || false,
              })) || []),
              ...(formData.deletedActions || []),
            ],
            delete: showcase.delete || false,
          })) || []),
          ...(formData.deletedShowcases || []),
        ],
        userImprintShowcaseSelfNotes: [
          ...(formData.userImprintShowcaseSelfNotes?.map((note) => ({
            _id: note._id || undefined,
            userImprintShowcaseSelfNoteText: note.userImprintShowcaseSelfNoteText || undefined,
            delete: note.delete || false,
          })) || []),
          ...(formData.deletedSelfNotes || []),
        ],
        aboutMe: formData.aboutMe.trim() || undefined,
        pic: picBase64,
        active: formData.active,
      };
  
      // Step 3: Submit the payload
      await createNewUserImprintShowcase(payload).unwrap();
  
      // Step 4: Reset the form on success
      setFormData({
        userImprintShowcaseHeadlineName: "",
        userImprintShowcaseHeadlineDesc: "",
        userImprintShowcases: [],
        userImprintShowcaseSelfNotes: [],
        aboutMe: "",
        pic: "",
        active: true,
      });
  
      // Step 5: Redirect or close the form
      if (onClose) {
        onClose(); // Close the modal or component
      } else {
        navigate(triggeringUrl || "/userMagicHome");
      }
    } catch (error) {
      // Step 6: Handle Errors
      console.error("Error submitting showcase form:", error);
    }
  };
  
  // 7 - Reset Form on Successful Submission
  useEffect(() => {
    if (isSuccess) {
      setFormData({
        userImprintShowcaseHeadlineName: "",
        userImprintShowcaseHeadlineDesc: "",
        userImprintShowcases: [],
        userImprintShowcaseSelfNotes: [],
        aboutMe: "",
        pic: "",
        active: true,
      });

      if (onClose) {
        onClose();
      } else {
        navigate(triggeringUrl || "/userMagicHome");
      }
    }
  }, [isSuccess, navigate]);

  // 8 - Handle Clear
  const handleClear = () => {
    setFormData({
      userImprintShowcaseHeadlineName: "",
      userImprintShowcaseHeadlineDesc: "",
      userImprintShowcases: [],
      userImprintShowcaseSelfNotes: [],
      aboutMe: "",
      pic: "",
      active: true,
    });
  };

  // 9 - Handle Cancel
  const handleCancel = () => {
    if (onClose) {
      onClose();
    } else {
      navigate(triggeringUrl || "/userMagicHome");
    }
  };

  // 10 - create Markup
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
    }


  return (
    <div className="mx-auto w-full p-2 bg-white rounded shadow-md shadow-black">
      
      {isLoading || isFetching ? <PulseLoader color={'#ea580c'} /> : null}
  
      {isError || isFetchError ? (
        <p className="text-orange-700 font-bold py-2">
          {fetchError?.data?.message || "Failed to fetch user imprint role data. Please try again."}
        </p>
      ) : null}

      <p className="p-1 m-1 font-bold font-poppins text-xl">Update User Imprint Showcase</p>
  
      
      {userImprintShowcaseRecords?.length === 0  ? (
        <p className="text-red-500 font-bold text-sm">
        No record found for the selected User and Organization. Please create a new one.
      </p>
      ) : (
        <form onSubmit={handleSubmit} className="m-1 p-1 text-xs font-poppins">
          
          {/* 1, 2. Headline Fields Section */}
          <div className="border rounded p-2 m-2 bg-gray-50">
            <p className="font-bold text-sm mb-2">Headline Fields</p>

            {/* 1. Showcase Headline Name */}
            <div id="headlineName" className="bg-gray-100 rounded p-1 m-1">
              <label
                htmlFor="userImprintShowcaseHeadlineName"
                className="block text-gray-600 text-sm font-bold mb-1"
              >
                Showcase Headline Name (Optional)
              </label>
              <input
                type="text"
                name="userImprintShowcaseHeadlineName"
                placeholder="Enter a headline or title"
                value={formData.userImprintShowcaseHeadlineName || ""}
                onChange={handleChange}
                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
              />
            </div>

            {/* 2. Showcase Headline Description */}
            <div id="headlineDesc" className="bg-gray-100 rounded p-1 m-1">
              <div className="flex justify-start items-center m-1 p-1">
                <button
                  type="button"
                  className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                  onClick={() => toggleSection("headlineDesc")} // Toggle function
                >
                  {collapsedSections["headlineDesc"] ? (
                    <PlusIcon className="h-4 w-4 text-orange-600" />
                  ) : (
                    <MinusIcon className="h-4 w-4 text-orange-600" />
                  )}
                </button>
                <label
                  htmlFor="userImprintShowcaseHeadlineDesc"
                  className="block text-gray-600 text-sm font-bold mb-1"
                >
                  Showcase Headline Description
                </label>
                {collapsedSections["headlineDesc"] && (
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      formData?.userImprintShowcaseHeadlineDesc || ""
                    )}
                    className="text-[10px] truncate line-clamp-1 mx-1 px-1 max-w-sm"
                  />
                )}
              </div>

              {!collapsedSections["headlineDesc"] && (
                <div className="m-1 p-1">
                  <EtContentEditorLight2
                    value={editorValue}
                    handleChange={(content) => {
                      setEditorValue(content);
                      setFormData((prevState) => ({
                        ...prevState,
                        userImprintShowcaseHeadlineDesc: content,
                      }));
                    }}
                    placeholderText="Write your showcase description here!"
                    editorKey={editorKey}
                  />
                </div>
              )}
            </div>
          </div>

          {/* 3. Showcases Section */}
          <div id="showcases" className="border rounded p-2 m-2 bg-gray-50">
            <div className="flex justify-between items-center mb-2">
              <div className="flex justify-start items-center m-1 p-1">
                <button
                  type="button"
                  className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                  onClick={() => toggleSection(`showcases`)}
                >
                  {collapsedSections[`showcases`] ? (
                    <PlusIcon className="h-4 w-4 text-orange-600" />
                  ) : (
                    <MinusIcon className="h-4 w-4 text-orange-600" />
                  )}
                </button>
                <p className="font-bold text-sm">Showcases</p>
              </div>
              <div className="flex justify-start items-center">
                <p className="font-bold text-xs mx-1 px-1">Add</p>
                <button
                  type="button"
                  className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() =>
                    setFormData((prevState) =>
                      produce(prevState, (draft) => {
                        draft.userImprintShowcases.unshift({
                          userShowcaseTypeId: null,
                          userShowcaseName: "",
                          userShowcaseDesc: "",
                          userShowcaseTargetType: null,
                          userShowcaseTargetName: "",
                          userShowcaseTargetDesc: "",
                          userShowcaseStartDate: "",
                          userShowcaseEndDate: "",
                          userShowcaseIndustry: null,
                          userShowcaseDomain: null,
                          userShowcaseFunction: null,
                          userShowcaseTechArea: null,
                          userShowcaseTechStack: null,
                          userShowcaseAdvisory: null,
                          userShowcaseSales: null,
                          userShowcaseService: null,
                          userShowcaseProblems: [],
                          userShowcaseHeadwinds: [],
                          userShowcaseApproachLevers: [],
                          userShowcaseActionsAndResults: [],
                        });
                      })
                    )
                  }
                >
                  <PlusIcon className="h-4 w-4 inline" />
                </button>
              </div>
            </div>

            {/* Render Individual Showcases */}
            {!collapsedSections[`showcases`] &&
              formData.userImprintShowcases?.map((showcase, showcaseIndex) => (
                <div key={showcaseIndex} className="border rounded p-2 m-2 bg-white">
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex justify-start items-center m-1 p-1">
                      <button
                        type="button"
                        className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                        onClick={() => toggleSection(`showcase-${showcaseIndex}`)}
                      >
                        {collapsedSections[`showcase-${showcaseIndex}`] ? (
                          <PlusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                          <MinusIcon className="h-4 w-4 text-orange-600" />
                        )}
                      </button>
                      <p className="font-bold text-sm mx-1 px-1">
                        Showcase {showcaseIndex + 1}
                      </p>
                    </div>
                    <div className="flex justify-start items-center">
                      <p className="font-bold text-xs mx-1 px-1">Remove</p>
                      <button
                        type="button"
                        className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                        onClick={() =>
                          setFormData((prevState) =>
                            produce(prevState, (draft) => {
                              // Mark the showcase for deletion
                              draft.userImprintShowcases[showcaseIndex].delete = true;
                            
                              // Push to the `deletedShowcases` array for backend submission
                              if (!draft.deletedShowcases) {
                                draft.deletedShowcases = [];
                              }
                              draft.deletedShowcases.push(draft.userImprintShowcases[showcaseIndex]);
                            
                              // Remove it visually from the form
                              draft.userImprintShowcases.splice(showcaseIndex, 1);
                            })
                          )
                        }
                      >
                        <MinusIcon className="h-4 w-4 inline" />
                      </button>
                    </div>
                  </div>

                  {/* Showcase Fields */}
                  {!collapsedSections[`showcase-${showcaseIndex}`] && (
                    <div>
                      {/* Showcase Type */}
                      <div
                        id={`showcaseType-${showcaseIndex}`}
                        className="bg-gray-100 rounded p-1 m-1"
                      >
                        <label
                          htmlFor={`showcaseType-${showcaseIndex}`}
                          className="block text-gray-600 text-sm font-bold mb-1"
                        >
                          Showcase Type
                        </label>
                        <select
                          id={`showcaseType-${showcaseIndex}`}
                          value={showcase.userShowcaseTypeId?._id || ""}
                          onChange={(e) =>
                            setFormData((prevState) =>
                              produce(prevState, (draft) => {
                                draft.userImprintShowcases[showcaseIndex].userShowcaseTypeId =
                                  e.target.value;
                              })
                            )
                          }
                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                        >
                          <option value="" disabled>
                            Select Showcase Type
                          </option>
                          {showCaseTypes.map((type) => (
                            <option key={type._id} value={type._id}>
                              {type.showcaseTypeName}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* Showcase Name */}
                      <div
                        id={`showcaseName-${showcaseIndex}`}
                        className="bg-gray-100 rounded p-1 m-1"
                      >
                        <label
                          htmlFor={`showcaseName-${showcaseIndex}`}
                          className="block text-gray-600 text-sm font-bold mb-1"
                        >
                          Showcase Name
                        </label>
                        <input
                          type="text"
                          id={`showcaseName-${showcaseIndex}`}
                          value={showcase.userShowcaseName || ""}
                          onChange={(e) =>
                            setFormData((prevState) =>
                              produce(prevState, (draft) => {
                                draft.userImprintShowcases[showcaseIndex].userShowcaseName =
                                  e.target.value;
                              })
                            )
                          }
                          placeholder="Enter showcase name"
                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                        />
                      </div>

                      {/* Additional Fields (Description, Dates, etc.) */}
                      <div id={`additionalFields-${showcaseIndex}`} className="bg-gray-100 rounded p-2 m-2">
                        {/* Showcase Description */}
                        <div id={`showcaseDesc-${showcaseIndex}`} className="bg-gray-100 rounded p-1 m-1">
                          <label
                            htmlFor={`userShowcaseDesc-${showcaseIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            Showcase Description
                          </label>
                          <EtContentEditorLight2
                            value={showcase.userShowcaseDesc || ""}
                            handleChange={(content) =>
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  draft.userImprintShowcases[showcaseIndex].userShowcaseDesc = content;
                                })
                              )
                            }
                            placeholderText="Write your showcase description here..."
                            editorKey={`showcaseDesc-${showcaseIndex}`}
                          />
                        </div>

                        {/* Target Type */}
                        <div id={`targetType-${showcaseIndex}`} className="bg-gray-100 rounded p-1 m-1">
                          <label
                            htmlFor={`userShowcaseTargetType-${showcaseIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            Target Type
                          </label>
                          <select
                            id={`userShowcaseTargetType-${showcaseIndex}`}
                            value={showcase.userShowcaseTargetType || ""}
                            onChange={(e) =>
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  draft.userImprintShowcases[showcaseIndex].userShowcaseTargetType = e.target.value;
                                })
                              )
                            }
                            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                          >
                            <option value="" disabled>
                              Select Target Type
                            </option>
                            <option value="Clients">Clients</option>
                            <option value="Org">Org</option>
                            <option value="Org Team">Org Team</option>
                            <option value="Partners">Partners</option>
                          </select>
                        </div>

                        {/* Target Name */}
                        <div id={`targetName-${showcaseIndex}`} className="bg-gray-100 rounded p-1 m-1">
                          <label
                            htmlFor={`userShowcaseTargetName-${showcaseIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            Target Name
                          </label>
                          <input
                            type="text"
                            id={`userShowcaseTargetName-${showcaseIndex}`}
                            value={showcase.userShowcaseTargetName || ""}
                            onChange={(e) =>
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  draft.userImprintShowcases[showcaseIndex].userShowcaseTargetName = e.target.value;
                                })
                              )
                            }
                            placeholder="Enter target name"
                            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                          />
                        </div>

                        {/* Target Description */}
                        <div id={`targetDesc-${showcaseIndex}`} className="bg-gray-100 rounded p-1 m-1">
                          <label
                            htmlFor={`userShowcaseTargetDesc-${showcaseIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            Target Description
                          </label>
                          <EtContentEditorLight2
                            value={showcase.userShowcaseTargetDesc || ""}
                            handleChange={(content) =>
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  draft.userImprintShowcases[showcaseIndex].userShowcaseTargetDesc = content;
                                })
                              )
                            }
                            placeholderText="Write target description here..."
                            editorKey={`targetDesc-${showcaseIndex}`}
                          />
                        </div>

                        {/* Dates  */}
                        <div className='flex justify-between items-center'>    
                          {/* Start Date */}
                          <div id={`startDate-${showcaseIndex}`} className="bg-gray-100 rounded p-1 m-1 w-full">
                            <label
                              htmlFor={`userShowcaseStartDate-${showcaseIndex}`}
                              className="block text-gray-600 text-sm font-bold mb-1"
                            >
                              Start Date
                            </label>
                            <input
                              type="date"
                              id={`userShowcaseStartDate-${showcaseIndex}`}
                              value={showcase.userShowcaseStartDate?.split("T")[0] || ""}
                              onChange={(e) =>
                                setFormData((prevState) =>
                                  produce(prevState, (draft) => {
                                    draft.userImprintShowcases[showcaseIndex].userShowcaseStartDate = e.target.value;
                                  })
                                )
                              }
                              className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                            />
                          </div>

                          {/* End Date */}
                          <div id={`endDate-${showcaseIndex}`} className="bg-gray-100 rounded p-1 m-1 w-full">
                            <label
                              htmlFor={`userShowcaseEndDate-${showcaseIndex}`}
                              className="block text-gray-600 text-sm font-bold mb-1"
                            >
                              End Date
                            </label>
                            <input
                              type="date"
                              id={`userShowcaseEndDate-${showcaseIndex}`}
                              value={showcase.userShowcaseEndDate?.split("T")[0] || ""}
                              onChange={(e) =>
                                setFormData((prevState) =>
                                  produce(prevState, (draft) => {
                                    draft.userImprintShowcases[showcaseIndex].userShowcaseEndDate = e.target.value;
                                  })
                                )
                              }
                              className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                            />
                          </div>
                        </div>

                        {/* Industry, Domain, Function, etc. */}
                        <div className='grid grid-cols-4 justify-between items-center gap-1'>
                          {[
                            { id: "industry", label: "Industry", options: industryTypes, valueKey: "userShowcaseIndustry", typeNameField: "capabilitiesIndustryTypeName" },
                            { id: "domain", label: "Domain", options: domainTypes, valueKey: "userShowcaseDomain", typeNameField: "capabilitiesDomainTypeName" },
                            { id: "function", label: "Function", options: functionTypes, valueKey: "userShowcaseFunction", typeNameField: "capabilitiesFunctionTypeName" },
                            { id: "techArea", label: "Tech Area", options: techAreaTypes, valueKey: "userShowcaseTechArea", typeNameField: "capabilitiesTechAreaTypeName" },
                            { id: "techStack", label: "Tech Stack", options: techStackTypes, valueKey: "userShowcaseTechStack", typeNameField: "capabilitiesTechStackTypeName" },
                            { id: "advisory", label: "Advisory", options: advisoryTypes, valueKey: "userShowcaseAdvisory", typeNameField: "capabilitiesAdvisoryTypeName" },
                            { id: "sales", label: "Sales", options: salesTypes, valueKey: "userShowcaseSales", typeNameField: "capabilitiesSalesTypeName" },
                            { id: "service", label: "Service", options: serviceTypes, valueKey: "userShowcaseService", typeNameField: "capabilitiesServiceTypeName" },
                            ].map(({ id, label, options, valueKey, typeNameField }) => (
                              <div key={id} className="bg-gray-100 rounded p-1 m-1">
                                <label
                                  htmlFor={`${id}-${showcaseIndex}`}
                                  className="block text-gray-600 text-sm font-bold mb-1"
                                >
                                  {label}
                                </label>
                                <select
                                  id={`${id}-${showcaseIndex}`}
                                  value={showcase[valueKey]?._id || ""} // Extract `_id` for the selected value
                                  onChange={(e) => {
                                    const selectedId = e.target.value;
                                    const selectedOption = options.find((option) => option._id === selectedId);
                                    setFormData((prevState) =>
                                      produce(prevState, (draft) => {
                                        draft.userImprintShowcases[showcaseIndex][valueKey] = selectedOption || null; // Store the full object
                                      })
                                    );
                                  }}
                                  className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                >
                                  <option value="" disabled>
                                    Select {label}
                                  </option>
                                  {options.map((option) => (
                                    <option key={option._id} value={option._id}>
                                      {option[typeNameField]} {/* Correct type name for display */}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            ))}
                          </div>

                        
                        {/* User Showcase Problems Section */}
                        <div
                          id={`problems-${showcaseIndex}`}
                          className="border rounded p-2 m-2 bg-gray-50"
                        >
                          <div className="flex justify-between items-center mb-2">
                            <div className="flex justify-start items-center m-1 p-1">
                              <button
                                type="button"
                                className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                                onClick={() => toggleSection(`problems-${showcaseIndex}`)} // Toggle function
                              >
                                {collapsedSections[`problems-${showcaseIndex}`] ? (
                                  <PlusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                  <MinusIcon className="h-4 w-4 text-orange-600" />
                                )}
                              </button>
                              <p className="font-bold text-sm">Problems</p>
                            </div>
                            <div className="flex justify-start items-center">
                              <p className="font-bold text-xs mx-1 px-1">Add</p>
                              <button
                                type="button"
                                className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                                onClick={() =>
                                  setFormData((prevState) =>
                                    produce(prevState, (draft) => {
                                      draft.userImprintShowcases[showcaseIndex].userShowcaseProblems =
                                        draft.userImprintShowcases[showcaseIndex].userShowcaseProblems || [];
                                      draft.userImprintShowcases[showcaseIndex].userShowcaseProblems.push({
                                        problemName: "",
                                        problemDesc: "",
                                        problemAreaType: "",
                                        problemMetricType: "",
                                        problemMetricUoMType: "",
                                        problemMetricGradationType: "",
                                        problemCurrentMeasureLevel: 0,
                                      });
                                    })
                                  )
                                }
                              >
                                <PlusIcon className="h-4 w-4 inline" />
                              </button>
                            </div>
                          </div>

                          {/* Render Problems */}
                          {!collapsedSections[`problems-${showcaseIndex}`] &&
                            showcase.userShowcaseProblems?.map((problem, problemIndex) => (
                              <div key={problemIndex} className="p-2 border rounded mb-2 bg-white">
                                <div className="flex justify-between items-center">
                                  <div className="flex justify-start items-center m-1 p-1">
                                    <button
                                      type="button"
                                      className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                                      onClick={() =>
                                        toggleSection(`problem-${showcaseIndex}-${problemIndex}`)
                                      } // Toggle function
                                    >
                                      {collapsedSections[`problem-${showcaseIndex}-${problemIndex}`] ? (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                      ) : (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                      )}
                                    </button>
                                    <p className="font-bold text-sm mx-1 px-1">Problem {problemIndex + 1}</p>
                                  </div>
                                  <div className="flex justify-start items-center">
                                    <p className="font-bold text-xs mx-1 px-1">Remove</p>
                                    <button
                                      type="button"
                                      className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                      onClick={() =>
                                        setFormData((prevState) =>
                                          produce(prevState, (draft) => {
                                            // Mark the problem for deletion
                                            draft.userImprintShowcases[showcaseIndex].userShowcaseProblems[problemIndex].delete = true;
                                          
                                            // Push to the `deletedProblems` array for backend submission
                                            if (!draft.deletedProblems) {
                                              draft.deletedProblems = [];
                                            }
                                            draft.deletedProblems.push(draft.userImprintShowcases[showcaseIndex].userShowcaseProblems[problemIndex]);
                                          
                                            // Remove it visually from the form
                                            draft.userImprintShowcases[showcaseIndex].userShowcaseProblems.splice(problemIndex, 1);
                                          })
                                        )
                                      }
                                    >
                                      <MinusIcon className="h-4 w-4 inline" />
                                    </button>
                                  </div>
                                </div>

                                {/* Problem Fields will go here */}
                                {!collapsedSections[`problem-${showcaseIndex}-${problemIndex}`] && (
                                <div>
                                    {/* Problem Name */}
                                    <div id={`problemName-${showcaseIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                      <label
                                        htmlFor={`problemName-${showcaseIndex}-${problemIndex}`}
                                        className="block text-gray-600 text-sm font-bold mb-1"
                                      >
                                        Problem Name
                                      </label>
                                      <input
                                        type="text"
                                        id={`problemName-${showcaseIndex}-${problemIndex}`}
                                        value={problem.problemName || ""}
                                        onChange={(e) =>
                                          setFormData((prevState) =>
                                            produce(prevState, (draft) => {
                                              draft.userImprintShowcases[showcaseIndex].userShowcaseProblems[
                                                problemIndex
                                              ].problemName = e.target.value;
                                            })
                                          )
                                        }
                                        placeholder="Enter problem name"
                                        className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                      />
                                    </div>

                                    {/* Problem Description */}
                                    <div id={`problemDesc-${showcaseIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                      <div className="flex justify-start items-center m-1 p-1">
                                        <button
                                          type="button"
                                          className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                                          onClick={() =>
                                            toggleSection(`problemDesc-${showcaseIndex}-${problemIndex}`)
                                          }
                                        >
                                          {collapsedSections[`problemDesc-${showcaseIndex}-${problemIndex}`] ? (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                          ) : (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                          )}
                                        </button>
                                        <label
                                          htmlFor={`problemDesc-${showcaseIndex}-${problemIndex}`}
                                          className="block text-gray-600 text-sm font-bold mb-1"
                                        >
                                          Problem Description
                                        </label>
                                        {collapsedSections[`problemDesc-${showcaseIndex}-${problemIndex}`] && (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: problem.problemDesc || "",
                                            }}
                                            className="text-[10px] truncate line-clamp-1 mx-1 px-1 max-w-sm"
                                          />
                                        )}
                                      </div>
                                      {!collapsedSections[`problemDesc-${showcaseIndex}-${problemIndex}`] && (
                                        <div className="m-1 p-1">
                                          <EtContentEditorLight2
                                            value={problem.problemDesc || ""}
                                            handleChange={(content) =>
                                              setFormData((prevState) =>
                                                produce(prevState, (draft) => {
                                                  draft.userImprintShowcases[showcaseIndex].userShowcaseProblems[
                                                    problemIndex
                                                  ].problemDesc = content;
                                                })
                                              )
                                            }
                                            placeholderText="Write your problem description here..."
                                            editorKey={`problemDesc-editor-${showcaseIndex}-${problemIndex}`}
                                          />
                                        </div>
                                      )}
                                    </div>

                                    {/* Problem Area Type */}
                                    <div id={`problemAreaType-${showcaseIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                      <label
                                        htmlFor={`problemAreaType-${showcaseIndex}-${problemIndex}`}
                                        className="block text-gray-600 text-sm font-bold mb-1"
                                      >
                                        Problem Area Type
                                      </label>
                                      <select
                                        id={`problemAreaType-${showcaseIndex}-${problemIndex}`}
                                        value={problem.problemAreaType?._id || ""}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormData((prevState) =>
                                            produce(prevState, (draft) => {
                                              draft.userImprintShowcases[showcaseIndex].userShowcaseProblems[
                                                problemIndex
                                              ].problemAreaType = value ? { _id: value } : null;
                                            })
                                          );
                                        }}
                                        className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                      >
                                        <option value="" disabled>
                                          Select Problem Area Type
                                        </option>
                                        {problemAreaTypes?.map((type) => (
                                          <option key={type._id} value={type._id}>
                                            {type.problemAreaTypeName}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    {/* Problem Metric Type */}
                                    <div id={`problemMetricType-${showcaseIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                      <label
                                        htmlFor={`problemMetricType-${showcaseIndex}-${problemIndex}`}
                                        className="block text-gray-600 text-sm font-bold mb-1"
                                      >
                                        Problem Metric Type
                                      </label>
                                      <select
                                        id={`problemMetricType-${showcaseIndex}-${problemIndex}`}
                                        value={problem.problemMetricType?._id || ""}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormData((prevState) =>
                                            produce(prevState, (draft) => {
                                              draft.userImprintShowcases[showcaseIndex].userShowcaseProblems[
                                                problemIndex
                                              ].problemMetricType = value ? { _id: value } : null;
                                            })
                                          );
                                        }}
                                        className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                      >
                                        <option value="" disabled>
                                          Select Problem Metric Type
                                        </option>
                                        {problemMetricTypes?.map((type) => (
                                          <option key={type._id} value={type._id}>
                                            {type.problemMetricTypeName}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    {/* Problem Metric UoM Type */}
                                    <div id={`problemMetricUoMType-${showcaseIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                      <label
                                        htmlFor={`problemMetricUoMType-${showcaseIndex}-${problemIndex}`}
                                        className="block text-gray-600 text-sm font-bold mb-1"
                                      >
                                        Problem Metric UoM Type
                                      </label>
                                      <select
                                        id={`problemMetricUoMType-${showcaseIndex}-${problemIndex}`}
                                        value={problem.problemMetricUoMType?._id || ""}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormData((prevState) =>
                                            produce(prevState, (draft) => {
                                              draft.userImprintShowcases[showcaseIndex].userShowcaseProblems[
                                                problemIndex
                                              ].problemMetricUoMType = value ? { _id: value } : null;
                                            })
                                          );
                                        }}
                                        className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                      >
                                        <option value="" disabled>
                                          Select Problem Metric UoM Type
                                        </option>
                                        {problemMetricUoMTypes?.map((type) => (
                                          <option key={type._id} value={type._id}>
                                            {type.problemMetricUoMTypeName}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    {/* Problem Metric Gradation Type */}
                                    <div id={`problemMetricGradationType-${showcaseIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                      <label
                                        htmlFor={`problemMetricGradationType-${showcaseIndex}-${problemIndex}`}
                                        className="block text-gray-600 text-sm font-bold mb-1"
                                      >
                                        Problem Metric Gradation Type
                                      </label>
                                      <select
                                        id={`problemMetricGradationType-${showcaseIndex}-${problemIndex}`}
                                        value={problem.problemMetricGradationType?._id || ""}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormData((prevState) =>
                                            produce(prevState, (draft) => {
                                              draft.userImprintShowcases[showcaseIndex].userShowcaseProblems[
                                                problemIndex
                                              ].problemMetricGradationType = value ? { _id: value } : null;
                                            })
                                          );
                                        }}
                                        className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                      >
                                        <option value="" disabled>
                                          Select Problem Metric Gradation Type
                                        </option>
                                        {problemMetricGradationTypes?.map((type) => (
                                          <option key={type._id} value={type._id}>
                                            {type.problemMetricGradationTypeName}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    {/* Problem Current Measure Level */}
                                    <div id={`problemCurrentMeasureLevel-${showcaseIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                      <label
                                        htmlFor={`problemCurrentMeasureLevel-${showcaseIndex}-${problemIndex}`}
                                        className="block text-gray-600 text-sm font-bold mb-1"
                                      >
                                        Problem Current Measure Level
                                      </label>
                                      <input
                                        type="number"
                                        id={`problemCurrentMeasureLevel-${showcaseIndex}-${problemIndex}`}
                                        value={problem.problemCurrentMeasureLevel || 0}
                                        onChange={(e) => {
                                          const value = parseFloat(e.target.value) || 0;
                                          setFormData((prevState) =>
                                            produce(prevState, (draft) => {
                                              draft.userImprintShowcases[showcaseIndex].userShowcaseProblems[
                                                problemIndex
                                              ].problemCurrentMeasureLevel = value;
                                            })
                                          );
                                        }}
                                        className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                        placeholder="Enter the current measure level"
                                      />
                                    </div>
                                </div>
                                )}    {/* End of each showcase Problem */}
                              </div>
                            ))}
                        </div>

                        {/* Showcase Headwinds Section */}
                        <div id={`showcaseHeadwinds-${showcaseIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                          <div className="flex justify-between items-center mb-2">
                            <div className="flex justify-start items-center m-1 p-1">
                              <button
                                type="button"
                                className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                                onClick={() => toggleSection(`showcaseHeadwinds-${showcaseIndex}`)}
                              >
                                {collapsedSections[`showcaseHeadwinds-${showcaseIndex}`] ? (
                                  <PlusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                  <MinusIcon className="h-4 w-4 text-orange-600" />
                                )}
                              </button>
                              <p className="font-bold text-sm">Showcase Headwinds</p>
                            </div>
                            <div className="flex justify-start items-center">
                              <p className="text-xs font-bold mx-1 px-1">Add</p>
                              <button
                                type="button"
                                className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                                onClick={() => {
                                  setFormData((prevState) =>
                                    produce(prevState, (draft) => {
                                      draft.userImprintShowcases[showcaseIndex].userShowcaseHeadwinds.unshift({
                                        headwindName: "",
                                        headwindDesc: "",
                                      });
                                    })
                                  );
                                }}
                              >
                                <PlusIcon className="h-4 w-4 inline" />
                              </button>
                            </div>
                          </div>

                          {!collapsedSections[`showcaseHeadwinds-${showcaseIndex}`] &&
                            showcase.userShowcaseHeadwinds?.map((headwind, headwindIndex) => (
                              <div key={headwindIndex} className="p-2 border rounded mb-2 bg-white">
                                <div className="flex justify-between items-center">
                                  <div className="flex justify-start items-center m-1 p-1">
                                    <button
                                      type="button"
                                      className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                                      onClick={() =>
                                        toggleSection(`headwind-${showcaseIndex}-${headwindIndex}`)
                                      }
                                    >
                                      {collapsedSections[`headwind-${showcaseIndex}-${headwindIndex}`] ? (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                      ) : (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                      )}
                                    </button>
                                    <p className="font-bold text-sm mx-1 px-1">Headwind {headwindIndex + 1}</p>
                                  </div>
                                  <div className="flex justify-start items-center">
                                    <p className="font-bold text-xs mx-1 px-1">Remove</p>
                                    <button
                                      type="button"
                                      className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                      onClick={() =>
                                        setFormData((prevState) =>
                                          produce(prevState, (draft) => {
                                            draft.userImprintShowcases[showcaseIndex].userShowcaseHeadwinds[headwindIndex].delete = true;
                                      
                                            if (!draft.deletedHeadwinds) {
                                              draft.deletedHeadwinds = [];
                                            }
                                            draft.deletedHeadwinds.push(draft.userImprintShowcases[showcaseIndex].userShowcaseHeadwinds[headwindIndex]);
                                      
                                            draft.userImprintShowcases[showcaseIndex].userShowcaseHeadwinds.splice(headwindIndex, 1);
                                          })
                                        )
                                      }
                                    >
                                      <MinusIcon className="h-4 w-4 inline" />
                                    </button>
                                  </div>
                                </div>

                                {/* Expandable Section for Headwind Details */}
                                {!collapsedSections[`headwind-${showcaseIndex}-${headwindIndex}`] && (
                                  <div>
                                    {/* Headwind Name */}
                                    <div id={`headwindName-${showcaseIndex}-${headwindIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                      <label
                                        htmlFor={`headwindName-${showcaseIndex}-${headwindIndex}`}
                                        className="block text-gray-600 text-sm font-bold mb-1"
                                      >
                                        Headwind Name
                                      </label>
                                      <input
                                        type="text"
                                        id={`headwindName-${showcaseIndex}-${headwindIndex}`}
                                        value={headwind.headwindName || ""}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormData((prevState) =>
                                            produce(prevState, (draft) => {
                                              draft.userImprintShowcases[showcaseIndex].userShowcaseHeadwinds[
                                                headwindIndex
                                              ].headwindName = value;
                                            })
                                          );
                                        }}
                                        placeholder="Enter headwind name"
                                        className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                      />
                                    </div>

                                    {/* Headwind Description */}
                                    <div id={`headwindDesc-${showcaseIndex}-${headwindIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                      <label
                                        htmlFor={`userShowcaseHeadwindText-${showcaseIndex}-${headwindIndex}`}
                                        className="block text-gray-600 text-sm font-bold mb-1"
                                      >
                                        Headwind Description
                                      </label>
                                      <EtContentEditorLight2
                                        value={headwind.headwindDesc || ""}
                                        handleChange={(content) =>
                                          setFormData((prevState) =>
                                            produce(prevState, (draft) => {
                                              draft.userImprintShowcases[showcaseIndex].userShowcaseHeadwinds[
                                                headwindIndex
                                              ].headwindDesc = content;
                                            })
                                          )
                                        }
                                        placeholderText="Describe the headwind here..."
                                        editorKey={`headwind-${showcaseIndex}-${headwindIndex}`}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                        </div>

                        {/* Showcase Approach Levers Section */}
                        <div id={`showcaseApproachLevers-${showcaseIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                          <div className="flex justify-between items-center mb-2">
                            <div className="flex justify-start items-center m-1 p-1">
                              <button
                                type="button"
                                className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                                onClick={() => toggleSection(`showcaseApproachLevers-${showcaseIndex}`)}
                              >
                                {collapsedSections[`showcaseApproachLevers-${showcaseIndex}`] ? (
                                  <PlusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                  <MinusIcon className="h-4 w-4 text-orange-600" />
                                )}
                              </button>
                              <p className="font-bold text-sm">Showcase Approach Levers</p>
                            </div>
                            <div className="flex justify-start items-center">
                              <p className="text-xs font-bold mx-1 px-1">Add</p>
                              <button
                                type="button"
                                className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                                onClick={() => {
                                  setFormData((prevState) =>
                                    produce(prevState, (draft) => {
                                      draft.userImprintShowcases[showcaseIndex].userShowcaseApproachLevers.unshift({
                                        approachLeverName: "",
                                        approachLeverDesc: "",
                                      });
                                    })
                                  );
                                }}
                              >
                                <PlusIcon className="h-4 w-4 inline" />
                              </button>
                            </div>
                          </div>

                          {!collapsedSections[`showcaseApproachLevers-${showcaseIndex}`] &&
                            showcase.userShowcaseApproachLevers?.map((lever, leverIndex) => (
                              <div key={leverIndex} className="p-2 border rounded mb-2 bg-white">
                                <div className="flex justify-between items-center">
                                  <div className="flex justify-start items-center m-1 p-1">
                                    <button
                                      type="button"
                                      className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                                      onClick={() =>
                                        toggleSection(`approachLever-${showcaseIndex}-${leverIndex}`)
                                      }
                                    >
                                      {collapsedSections[`approachLever-${showcaseIndex}-${leverIndex}`] ? (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                      ) : (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                      )}
                                    </button>
                                    <p className="font-bold text-sm mx-1 px-1">
                                      Approach Lever {leverIndex + 1}
                                    </p>
                                  </div>
                                  <div className="flex justify-start items-center">
                                    <p className="font-bold text-xs mx-1 px-1">Remove</p>
                                    <button
                                      type="button"
                                      className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                      onClick={() =>
                                        setFormData((prevState) =>
                                          produce(prevState, (draft) => {
                                            draft.userImprintShowcases[showcaseIndex].userShowcaseApproachLevers[leverIndex].delete = true;
                                      
                                            if (!draft.deletedApproachLevers) {
                                              draft.deletedApproachLevers = [];
                                            }
                                            draft.deletedApproachLevers.push(
                                              draft.userImprintShowcases[showcaseIndex].userShowcaseApproachLevers[leverIndex]
                                            );
                                      
                                            draft.userImprintShowcases[showcaseIndex].userShowcaseApproachLevers.splice(leverIndex, 1);
                                          })
                                        )
                                      }
                                    >
                                      <MinusIcon className="h-4 w-4 inline" />
                                    </button>
                                  </div>
                                </div>

                                {/* Expandable Section for Approach Lever Details */}
                                {!collapsedSections[`approachLever-${showcaseIndex}-${leverIndex}`] && (
                                  <div>
                                    {/* Approach Lever Name */}
                                    <div id={`approachLeverName-${showcaseIndex}-${leverIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                      <label
                                        htmlFor={`approachLeverName-${showcaseIndex}-${leverIndex}`}
                                        className="block text-gray-600 text-sm font-bold mb-1"
                                      >
                                        Approach Lever Name
                                      </label>
                                      <input
                                        type="text"
                                        id={`approachLeverName-${showcaseIndex}-${leverIndex}`}
                                        value={lever.approachLeverName || ""}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormData((prevState) =>
                                            produce(prevState, (draft) => {
                                              draft.userImprintShowcases[showcaseIndex].userShowcaseApproachLevers[
                                                leverIndex
                                              ].approachLeverName = value;
                                            })
                                          );
                                        }}
                                        placeholder="Enter approach lever name"
                                        className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                      />
                                    </div>

                                    {/* Approach Lever Description */}
                                    <div id={`approachLeverDesc-${showcaseIndex}-${leverIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                      <label
                                        htmlFor={`approachLeverDesc-${showcaseIndex}-${leverIndex}`}
                                        className="block text-gray-600 text-sm font-bold mb-1"
                                      >
                                        Approach Lever Description
                                      </label>
                                      <EtContentEditorLight2
                                        value={lever.approachLeverDesc || ""}
                                        handleChange={(content) =>
                                          setFormData((prevState) =>
                                            produce(prevState, (draft) => {
                                              draft.userImprintShowcases[showcaseIndex].userShowcaseApproachLevers[
                                                leverIndex
                                              ].approachLeverDesc = content;
                                            })
                                          )
                                        }
                                        placeholderText="Describe the approach lever here..."
                                        editorKey={`approachLever-${showcaseIndex}-${leverIndex}`}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                        </div>

                        {/* Showcase Actions and Results Section */}
                        <div id={`showcaseActionsResults-${showcaseIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                          <div className="flex justify-between items-center mb-2">
                            <div className="flex justify-start items-center m-1 p-1">
                              <button
                                type="button"
                                className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                                onClick={() => toggleSection(`showcaseActionsResults-${showcaseIndex}`)}
                              >
                                {collapsedSections[`showcaseActionsResults-${showcaseIndex}`] ? (
                                  <PlusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                  <MinusIcon className="h-4 w-4 text-orange-600" />
                                )}
                              </button>
                              <p className="font-bold text-sm">Showcase Actions and Results</p>
                            </div>
                            <div className="flex justify-start items-center">
                              <p className="text-xs font-bold mx-1 px-1">Add</p>
                              <button
                                type="button"
                                className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                                onClick={() =>
                                  setFormData((prevState) =>
                                    produce(prevState, (draft) => {
                                      draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults.push({
                                        actionName: "",
                                        actionDesc: "",
                                        actionResults: [],
                                      });
                                    })
                                  )
                                }
                              >
                                <PlusIcon className="h-4 w-4 inline" />
                              </button>
                            </div>
                          </div>

                          {/* Render Each Action and Result */}
                          {!collapsedSections[`showcaseActionsResults-${showcaseIndex}`] &&
                            showcase.userShowcaseActionsAndResults?.map((actionResult, actionResultIndex) => (
                              <div key={actionResultIndex} className="p-2 border rounded mb-2 bg-white">
                                <div className="flex justify-between items-center">
                                  <div className="flex justify-start items-center m-1 p-1">
                                    <button
                                      type="button"
                                      className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                                      onClick={() =>
                                        toggleSection(`actionResult-${showcaseIndex}-${actionResultIndex}`)
                                      }
                                    >
                                      {collapsedSections[`actionResult-${showcaseIndex}-${actionResultIndex}`] ? (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                      ) : (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                      )}
                                    </button>
                                    <p className="font-bold text-sm mx-1 px-1">
                                      Action and Result {actionResultIndex + 1}
                                    </p>
                                  </div>
                                  <div className="flex justify-start items-center">
                                    <p className="font-bold text-xs mx-1 px-1">Remove</p>
                                    <button
                                      type="button"
                                      className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                      onClick={() =>
                                        setFormData((prevState) =>
                                          produce(prevState, (draft) => {
                                            draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[actionResultIndex].delete = true;
                                      
                                            if (!draft.deletedActionsAndResults) {
                                              draft.deletedActionsAndResults = [];
                                            }
                                            draft.deletedActionsAndResults.push(
                                              draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[actionResultIndex]
                                            );
                                      
                                            draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults.splice(actionResultIndex, 1);
                                          })
                                        )
                                      }
                                    >
                                      <MinusIcon className="h-4 w-4 inline" />
                                    </button>
                                  </div>
                                </div>

                                {/* Action Name */}
                                <div id={`actionName-${showcaseIndex}-${actionResultIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                  <label
                                    htmlFor={`actionName-${showcaseIndex}-${actionResultIndex}`}
                                    className="block text-gray-600 text-sm font-bold mb-1"
                                  >
                                    Action Name
                                  </label>
                                  <input
                                    type="text"
                                    id={`actionName-${showcaseIndex}-${actionResultIndex}`}
                                    value={actionResult.actionName || ""}
                                    onChange={(e) =>
                                      setFormData((prevState) =>
                                        produce(prevState, (draft) => {
                                          draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[
                                            actionResultIndex
                                          ].actionName = e.target.value;
                                        })
                                      )
                                    }
                                    placeholder="Enter action name"
                                    className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                  />
                                </div>

                                {/* Action Description */}
                                <div id={`actionDesc-${showcaseIndex}-${actionResultIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                  <label
                                    htmlFor={`actionDesc-${showcaseIndex}-${actionResultIndex}`}
                                    className="block text-gray-600 text-sm font-bold mb-1"
                                  >
                                    Action Description
                                  </label>
                                  <EtContentEditorLight2
                                    value={actionResult.actionDesc || ""}
                                    handleChange={(content) =>
                                      setFormData((prevState) =>
                                        produce(prevState, (draft) => {
                                          draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[
                                            actionResultIndex
                                          ].actionDesc = content;
                                        })
                                      )
                                    }
                                    placeholderText="Describe the action here..."
                                    editorKey={`actionDesc-${showcaseIndex}-${actionResultIndex}`}
                                  />
                                </div>

                                {/* Placeholder for Action Results (To be added next) */}
                                {/* Action Results */}
                                <div id={`actionResults-${showcaseIndex}-${actionResultIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                                  <div className="flex justify-between items-center mb-2">
                                    <p className="font-bold text-sm">Action Results</p>
                                    <div className="flex justify-start items-center">
                                      <p className="text-xs font-bold mx-1 px-1">Add</p>
                                      <button
                                        type="button"
                                        className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                                        onClick={() =>
                                          setFormData((prevState) =>
                                            produce(prevState, (draft) => {
                                              draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[
                                                actionResultIndex
                                              ].actionResults.push({
                                                problems: [],
                                                resultMagnitude: "",
                                                resultDirection: "",
                                                resultLongevity: "",
                                                resultNotes: [],
                                              });
                                            })
                                          )
                                        }
                                      >
                                        <PlusIcon className="h-4 w-4 inline" />
                                      </button>
                                    </div>
                                  </div>

                                  {/* Render Each Action Result */}
                                  {actionResult.actionResults?.map((result, resultIndex) => (
                                    <div key={resultIndex} className="p-2 border rounded mb-2 bg-white">
                                      <div className="flex justify-between items-center">
                                        <div className="flex justify-start items-center m-1 p-1">
                                          <button
                                            type="button"
                                            className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                                            onClick={() =>
                                              toggleSection(
                                                `actionResultDetails-${showcaseIndex}-${actionResultIndex}-${resultIndex}`
                                              )
                                            }
                                          >
                                            {collapsedSections[
                                              `actionResultDetails-${showcaseIndex}-${actionResultIndex}-${resultIndex}`
                                            ] ? (
                                              <PlusIcon className="h-4 w-4 text-orange-600" />
                                            ) : (
                                              <MinusIcon className="h-4 w-4 text-orange-600" />
                                            )}
                                          </button>
                                          <p className="font-bold text-sm mx-1 px-1">Result {resultIndex + 1}</p>
                                        </div>
                                        <div className="flex justify-start items-center">
                                          <p className="font-bold text-xs mx-1 px-1">Remove</p>
                                          <button
                                            type="button"
                                            className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                            onClick={() =>
                                              setFormData((prevState) =>
                                                produce(prevState, (draft) => {
                                                  draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[actionResultIndex].actionResults[
                                                    resultIndex
                                                  ].delete = true;
                                            
                                                  if (!draft.deletedActionResults) {
                                                    draft.deletedActionResults = [];
                                                  }
                                                  draft.deletedActionResults.push(
                                                    draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[actionResultIndex].actionResults[
                                                      resultIndex
                                                    ]
                                                  );
                                            
                                                  draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[actionResultIndex].actionResults.splice(
                                                    resultIndex,
                                                    1
                                                  );
                                                })
                                              )
                                            }
                                          >
                                            <MinusIcon className="h-4 w-4 inline" />
                                          </button>
                                        </div>
                                      </div>

                                      {!collapsedSections[
                                        `actionResultDetails-${showcaseIndex}-${actionResultIndex}-${resultIndex}`
                                      ] && (
                                        <div>
                                          
                                          {/* Problems Section */}
                                          <div id={`problems-${showcaseIndex}-${actionResultIndex}-${resultIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                                            <div className="flex justify-between items-center mb-2">
                                              <div className="flex justify-start items-center m-1 p-1">
                                                <button
                                                  type="button"
                                                  className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                                                  onClick={() =>
                                                    toggleSection(`problems-${showcaseIndex}-${actionResultIndex}-${resultIndex}`)
                                                  }
                                                >
                                                  {collapsedSections[`problems-${showcaseIndex}-${actionResultIndex}-${resultIndex}`] ? (
                                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                                  ) : (
                                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                                  )}
                                                </button>
                                                <p className="font-bold text-sm">Problems</p>
                                              </div>
                                              <div className="flex justify-start items-center">
                                                <p className="font-bold text-xs mx-1 px-1">Add</p>
                                                <button
                                                  type="button"
                                                  className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                                                  onClick={() => {
                                                    setFormData((prevState) =>
                                                      produce(prevState, (draft) => {
                                                        draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[
                                                          actionResultIndex
                                                        ].actionResults[resultIndex].problems.push({
                                                          problemName: "",
                                                          problemPreActionLevel: 0,
                                                          problemPostActionLevel: 0,
                                                          problemMetricUoMType: "",
                                                          problemMetricGradationType: "",
                                                        });
                                                      })
                                                    );
                                                  }}
                                                >
                                                  <PlusIcon className="h-4 w-4 inline" />
                                                </button>
                                              </div>
                                            </div>

                                            {/* Render Problems */}
                                            {!collapsedSections[`problems-${showcaseIndex}-${actionResultIndex}-${resultIndex}`] &&
                                              result.problems?.map((problem, problemIndex) => (
                                                <div
                                                  key={problemIndex}
                                                  className="p-2 border rounded mb-2 bg-white"
                                                >
                                                  <div className="flex justify-between items-center mb-2">
                                                    <div className="flex justify-start items-center m-1 p-1">
                                                      <button
                                                        type="button"
                                                        className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                                                        onClick={() =>
                                                          toggleSection(
                                                            `problem-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`
                                                          )
                                                        }
                                                      >
                                                        {collapsedSections[
                                                          `problem-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`
                                                        ] ? (
                                                          <PlusIcon className="h-4 w-4 text-orange-600" />
                                                        ) : (
                                                          <MinusIcon className="h-4 w-4 text-orange-600" />
                                                        )}
                                                      </button>
                                                      <p className="font-bold text-sm mx-1 px-1">
                                                        Problem {problemIndex + 1}
                                                      </p>
                                                    </div>
                                                    <div className="flex justify-start items-center">
                                                      <p className="font-bold text-xs mx-1 px-1">Remove</p>
                                                      <button
                                                        type="button"
                                                        className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                                        onClick={() => {
                                                          setFormData((prevState) =>
                                                            produce(prevState, (draft) => {
                                                              draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[actionResultIndex].actionResults[
                                                                resultIndex
                                                              ].problems[problemIndex].delete = true;
                                                        
                                                              if (!draft.deletedResultProblems) {
                                                                draft.deletedResultProblems = [];
                                                              }
                                                              draft.deletedResultProblems.push(
                                                                draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[actionResultIndex].actionResults[
                                                                  resultIndex
                                                                ].problems[problemIndex]
                                                              );
                                                        
                                                              draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[actionResultIndex].actionResults[
                                                                resultIndex
                                                              ].problems.splice(problemIndex, 1);
                                                            })
                                                          );
                                                        }}
                                                      >
                                                        <MinusIcon className="h-4 w-4 inline" />
                                                      </button>
                                                    </div>
                                                  </div>

                                                  
                                                  {!collapsedSections[
                                                    `problem-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`
                                                  ] && (
                                                    <div>
                                                      {/* Problem Name */}
                                                      <div id={`problemName-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                                        <label
                                                          htmlFor={`problemName-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                          className="block text-gray-600 text-sm font-bold mb-1"
                                                        >
                                                          Problem Name
                                                        </label>
                                                        <input
                                                          type="text"
                                                          id={`problemName-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                          value={problem.problemName || ""}
                                                          onChange={(e) => {
                                                            const value = e.target.value;
                                                            setFormData((prevState) =>
                                                              produce(prevState, (draft) => {
                                                                draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[
                                                                  actionResultIndex
                                                                ].actionResults[resultIndex].problems[problemIndex].problemName = value;
                                                              })
                                                            );
                                                          }}
                                                          placeholder="Enter problem name"
                                                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                                        />
                                                      </div>
                                                      
                                                      {/* Problem Pre-Action Level */}
                                                      <div id={`problemPreActionLevel-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                                        <label
                                                          htmlFor={`problemPreActionLevel-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                          className="block text-gray-600 text-sm font-bold mb-1"
                                                        >
                                                          Problem Pre-Action Level
                                                        </label>
                                                        <input
                                                          type="number"
                                                          id={`problemPreActionLevel-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                          value={problem.problemPreActionLevel || 0}
                                                          readOnly
                                                          className="w-full rounded p-2 border border-gray-300 bg-gray-200 focus:outline-none"
                                                        />
                                                      </div>

                                                      {/* Problem Post-Action Level */}
                                                      <div id={`problemPostActionLevel-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                                        <label
                                                          htmlFor={`problemPostActionLevel-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                          className="block text-gray-600 text-sm font-bold mb-1"
                                                        >
                                                          Problem Post-Action Level
                                                        </label>
                                                        <input
                                                          type="number"
                                                          id={`problemPostActionLevel-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                          value={problem.problemPostActionLevel || 0}
                                                          onChange={(e) =>
                                                            setFormData((prevState) =>
                                                              produce(prevState, (draft) => {
                                                                draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[
                                                                  actionResultIndex
                                                                ].actionResults[resultIndex].problems[problemIndex].problemPostActionLevel =
                                                                  Number(e.target.value);
                                                              })
                                                            )
                                                          }
                                                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                                        />
                                                      </div>

                                                      {/* Problem Metric UoM Type */}
                                                      <div id={`problemMetricUoMType-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                                        <label
                                                          htmlFor={`problemMetricUoMType-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                          className="block text-gray-600 text-sm font-bold mb-1"
                                                        >
                                                          Problem Metric UoM Type
                                                        </label>
                                                        <select
                                                          id={`problemMetricUoMType-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                          value={problem.problemMetricUoMType || ""}
                                                          onChange={(e) =>
                                                            setFormData((prevState) =>
                                                              produce(prevState, (draft) => {
                                                                draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[
                                                                  actionResultIndex
                                                                ].actionResults[resultIndex].problems[problemIndex].problemMetricUoMType =
                                                                  e.target.value;
                                                              })
                                                            )
                                                          }
                                                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                                        >
                                                          <option value="" disabled>
                                                            Select Unit of Measure
                                                          </option>
                                                          {problemMetricUoMTypes?.map((type) => (
                                                            <option key={type._id} value={type._id}>
                                                              {type.problemMetricUoMTypeName}
                                                            </option>
                                                          ))}
                                                        </select>
                                                      </div>

                                                      {/* Problem Metric Gradation Type */}
                                                      <div id={`problemMetricGradationType-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                                        <label
                                                          htmlFor={`problemMetricGradationType-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                          className="block text-gray-600 text-sm font-bold mb-1"
                                                        >
                                                          Problem Metric Gradation Type
                                                        </label>
                                                        <select
                                                          id={`problemMetricGradationType-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${problemIndex}`}
                                                          value={problem.problemMetricGradationType || ""}
                                                          onChange={(e) =>
                                                            setFormData((prevState) =>
                                                              produce(prevState, (draft) => {
                                                                draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[
                                                                  actionResultIndex
                                                                ].actionResults[resultIndex].problems[problemIndex].problemMetricGradationType =
                                                                  e.target.value;
                                                              })
                                                            )
                                                          }
                                                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                                        >
                                                          <option value="" disabled>
                                                            Select Gradation Type
                                                          </option>
                                                          {problemMetricGradationTypes?.map((type) => (
                                                            <option key={type._id} value={type._id}>
                                                              {type.problemMetricGradationTypeName}
                                                            </option>
                                                          ))}
                                                        </select>
                                                      </div>

                                                    </div>
                                                  )}

                                                </div>
                                              ))}
                                          </div> {/* End of Problems Section */}

                                          {/* Result Magnitude */}
                                          <div id={`resultMagnitude-${showcaseIndex}-${actionResultIndex}-${resultIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                            <label
                                              htmlFor={`resultMagnitude-${showcaseIndex}-${actionResultIndex}-${resultIndex}`}
                                              className="block text-gray-600 text-sm font-bold mb-1"
                                            >
                                              Result Magnitude
                                            </label>
                                            <select
                                              id={`resultMagnitude-${showcaseIndex}-${actionResultIndex}-${resultIndex}`}
                                              value={result.resultMagnitude || ""}
                                              onChange={(e) =>
                                                setFormData((prevState) =>
                                                  produce(prevState, (draft) => {
                                                    draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[
                                                      actionResultIndex
                                                    ].actionResults[resultIndex].resultMagnitude = e.target.value;
                                                  })
                                                )
                                              }
                                              className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                            >
                                              <option value="" disabled>
                                                Select Magnitude
                                              </option>
                                              {["yet to register", "incremental", "significant", "order of magnitude", "benchmark"].map(
                                                (magnitude) => (
                                                  <option key={magnitude} value={magnitude}>
                                                    {magnitude}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </div>

                                          {/* Result Direction */}
                                          <div id={`resultDirection-${showcaseIndex}-${actionResultIndex}-${resultIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                            <label
                                              htmlFor={`resultDirection-${showcaseIndex}-${actionResultIndex}-${resultIndex}`}
                                              className="block text-gray-600 text-sm font-bold mb-1"
                                            >
                                              Result Direction
                                            </label>
                                            <select
                                              id={`resultDirection-${showcaseIndex}-${actionResultIndex}-${resultIndex}`}
                                              value={result.resultDirection || ""}
                                              onChange={(e) =>
                                                setFormData((prevState) =>
                                                  produce(prevState, (draft) => {
                                                    draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[
                                                      actionResultIndex
                                                    ].actionResults[resultIndex].resultDirection = e.target.value;
                                                  })
                                                )
                                              }
                                              className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                            >
                                              <option value="" disabled>
                                                Select Direction
                                              </option>
                                              {["turnaround", "stabilized", "kept it steady", "accelerated"].map((direction) => (
                                                <option key={direction} value={direction}>
                                                  {direction}
                                                </option>
                                              ))}
                                            </select>
                                          </div>

                                          {/* Result Longevity */}
                                          <div id={`resultLongevity-${showcaseIndex}-${actionResultIndex}-${resultIndex}`} className="bg-gray-100 rounded p-1 m-1">
                                            <label
                                              htmlFor={`resultLongevity-${showcaseIndex}-${actionResultIndex}-${resultIndex}`}
                                              className="block text-gray-600 text-sm font-bold mb-1"
                                            >
                                              Result Longevity
                                            </label>
                                            <select
                                              id={`resultLongevity-${showcaseIndex}-${actionResultIndex}-${resultIndex}`}
                                              value={result.resultLongevity || ""}
                                              onChange={(e) =>
                                                setFormData((prevState) =>
                                                  produce(prevState, (draft) => {
                                                    draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[
                                                      actionResultIndex
                                                    ].actionResults[resultIndex].resultLongevity = e.target.value;
                                                  })
                                                )
                                              }
                                              className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                            >
                                              <option value="" disabled>
                                                Select Longevity
                                              </option>
                                              {["one time", "limited time", "ongoing"].map((longevity) => (
                                                <option key={longevity} value={longevity}>
                                                  {longevity}
                                                </option>
                                              ))}
                                            </select>
                                          </div>

                                          {/* Notes Section */}
                                          <div id={`resultNotes-${showcaseIndex}-${actionResultIndex}-${resultIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                                            <div className="flex justify-between items-center mb-2">
                                              <p className="font-bold text-sm">Result Notes</p>
                                              <div className="flex justify-start items-center">
                                                <p className="text-xs font-bold mx-1 px-1">Add</p>
                                                <button
                                                  type="button"
                                                  className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                                                  onClick={() =>
                                                    setFormData((prevState) =>
                                                      produce(prevState, (draft) => {
                                                        draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[
                                                          actionResultIndex
                                                        ].actionResults[resultIndex].resultNotes.push({
                                                          resultNoteText: "",
                                                          resultNoteDate: new Date().toISOString(),
                                                          resultNoteUpdateDate: null,
                                                        });
                                                      })
                                                    )
                                                  }
                                                >
                                                  <PlusIcon className="h-4 w-4 inline" />
                                                </button>
                                              </div>
                                            </div>

                                            {/* Render Notes */}
                                            {result.resultNotes?.map((note, noteIndex) => (
                                              <div key={noteIndex} className="p-2 border rounded mb-2 bg-white">
                                                <div className="flex justify-between items-center mb-2">
                                                  <div className="flex justify-start items-center m-1 p-1">
                                                    <button
                                                      type="button"
                                                      className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                                                      onClick={() =>
                                                        toggleSection(
                                                          `resultNote-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${noteIndex}`
                                                        )
                                                      }
                                                    >
                                                      {collapsedSections[
                                                        `resultNote-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${noteIndex}`
                                                      ] ? (
                                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                                      ) : (
                                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                                      )}
                                                    </button>
                                                    <p className="font-bold text-sm mx-1 px-1">Note {noteIndex + 1}</p>
                                                    {/* Collapsed Note Summary */}
                                                      {collapsedSections[
                                                        `resultNote-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${noteIndex}`
                                                      ] && (
                                                        <p dangerouslySetInnerHTML={createMarkup(note?.resultNoteText)} className="text-gray-500 text-xs truncate line-clamp-1 m-1"></p>
                                                      )}
                                                  </div>
                                                  <div className="flex justify-start items-center">
                                                    <p className="font-bold text-xs mx-1 px-1">Remove</p>
                                                    <button
                                                      type="button"
                                                      className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                                      onClick={() =>
                                                        setFormData((prevState) =>
                                                          produce(prevState, (draft) => {
                                                            draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[actionResultIndex].actionResults[
                                                              resultIndex
                                                            ].resultNotes[noteIndex].delete = true;
                                                      
                                                            if (!draft.deletedResultNotes) {
                                                              draft.deletedResultNotes = [];
                                                            }
                                                            draft.deletedResultNotes.push(
                                                              draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[actionResultIndex].actionResults[
                                                                resultIndex
                                                              ].resultNotes[noteIndex]
                                                            );
                                                      
                                                            draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[actionResultIndex].actionResults[
                                                              resultIndex
                                                            ].resultNotes.splice(noteIndex, 1);
                                                          })
                                                        )
                                                      }
                                                    >
                                                      <MinusIcon className="h-4 w-4 inline" />
                                                    </button>
                                                  </div>
                                                </div>

                                                {/* Note Text Editor */}
                                                {!collapsedSections[
                                                  `resultNote-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${noteIndex}`
                                                ] && (
                                                  <div>
                                                    <EtContentEditorLight2
                                                      value={note.resultNoteText || ""}
                                                      handleChange={(content) =>
                                                        setFormData((prevState) =>
                                                          produce(prevState, (draft) => {
                                                            draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[
                                                              actionResultIndex
                                                            ].actionResults[resultIndex].resultNotes[noteIndex].resultNoteText = content;
                                                            draft.userImprintShowcases[showcaseIndex].userShowcaseActionsAndResults[
                                                              actionResultIndex
                                                            ].actionResults[resultIndex].resultNotes[noteIndex].resultNoteUpdateDate = new Date().toISOString();
                                                          })
                                                        )
                                                      }
                                                      placeholderText="Write your note here..."
                                                      editorKey={`noteEditor-${showcaseIndex}-${actionResultIndex}-${resultIndex}-${noteIndex}`}
                                                    />
                                                    {/* Note Dates */}
                                                    <p className="text-xs text-gray-500 mt-2">
                                                      Created on: {new Date(note.resultNoteDate).toLocaleDateString()}
                                                      {note.resultNoteUpdateDate && (
                                                        <span>
                                                          , Updated on:{" "}
                                                          {new Date(note.resultNoteUpdateDate).toLocaleDateString()}
                                                        </span>
                                                      )}
                                                    </p>
                                                  </div>
                                                )}
                                                
                                              </div>
                                            ))}
                                          </div>

                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>


                              </div>
                            ))} {/* End of each Action & Result */}
                        </div>  {/* End of Showcase Actions and Results Section */}
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>

          {/* 4. Self Notes Section */}
          <div id="selfNotes" className="border rounded p-2 m-2 bg-gray-50">
            <div className="flex justify-between items-center mb-2">
              <div className="flex justify-start items-center m-1 p-1">
                <button
                  type="button"
                  className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                  onClick={() => toggleSection(`selfNotes`)} // Toggle function
                >
                  {collapsedSections[`selfNotes`] ? (
                    <PlusIcon className="h-4 w-4 text-orange-600" />
                  ) : (
                    <MinusIcon className="h-4 w-4 text-orange-600" />
                  )}
                </button>
                <p className="font-bold text-sm">Showcase Self Notes</p>
              </div>
              <div className="flex justify-start items-center">
                <p className="text-xs font-bold mx-1 px-1">Add</p>
                <button
                  type="button"
                  className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) =>
                      produce(prevState, (draft) => {
                        draft.userImprintShowcaseSelfNotes.push({
                          userImprintShowcaseSelfNoteText: "",
                          userImprintShowcaseSelfNoteDate: new Date().toISOString(),
                          userImprintShowcaseSelfNoteUpdateDate: null,
                        });
                      })
                    );
                  }}
                >
                  <PlusIcon className="h-4 w-4 inline" />
                </button>
              </div>
            </div>

            {/* Render Notes */}
            {!collapsedSections[`selfNotes`] &&
              formData.userImprintShowcaseSelfNotes?.map((note, noteIndex) => (
                <div key={noteIndex} className="p-2 border rounded mb-2 bg-white">
                  <div className="flex justify-between items-center">
                    <div className="flex justify-start items-center m-1 p-1">
                      <button
                        type="button"
                        className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                        onClick={() => toggleSection(`note-${noteIndex}`)} // Toggle function
                      >
                        {collapsedSections[`note-${noteIndex}`] ? (
                          <PlusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                          <MinusIcon className="h-4 w-4 text-orange-600" />
                        )}
                      </button>
                      <p className="font-bold text-sm mx-1 px-1">Self Note {noteIndex + 1}</p>
                    </div>
                    <div className="flex justify-start items-center">
                      <p className="font-bold text-xs mx-1 px-1">Remove</p>
                      <button
                        type="button"
                        className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                        onClick={() => {
                          setFormData((prevState) =>
                            produce(prevState, (draft) => {
                              draft.userImprintShowcaseSelfNotes[noteIndex].delete = true;
                        
                              if (!draft.deletedSelfNotes) {
                                draft.deletedSelfNotes = [];
                              }
                              draft.deletedSelfNotes.push(draft.userImprintShowcaseSelfNotes[noteIndex]);
                        
                              draft.userImprintShowcaseSelfNotes.splice(noteIndex, 1);
                            })
                          );
                        }}
                      >
                        <MinusIcon className="h-4 w-4 inline" />
                      </button>
                    </div>
                  </div>

                  {!collapsedSections[`note-${noteIndex}`] && (
                    <div>
                      {/* Note Text */}
                      <EtContentEditorLight2
                        value={note.userImprintShowcaseSelfNoteText}
                        handleChange={(content) => {
                          setFormData((prevState) =>
                            produce(prevState, (draft) => {
                              draft.userImprintShowcaseSelfNotes[noteIndex].userImprintShowcaseSelfNoteText =
                                content;
                              draft.userImprintShowcaseSelfNotes[noteIndex].userImprintShowcaseSelfNoteUpdateDate =
                                new Date().toISOString();
                            })
                          );
                        }}
                        placeholderText="Write your note here..."
                        editorKey={`selfNote-${noteIndex}`}
                      />

                      {/* Note Dates */}
                      <p className="text-xs text-gray-500 mt-2">
                        Created on:{" "}
                        {new Date(note.userImprintShowcaseSelfNoteDate).toLocaleDateString()}
                        {note.userImprintShowcaseSelfNoteUpdateDate && (
                          <span>
                            , Updated on:{" "}
                            {new Date(note.userImprintShowcaseSelfNoteUpdateDate).toLocaleDateString()}
                          </span>
                        )}
                      </p>
                    </div>
                  )}
                </div>
              ))}
          </div>

          {/* 5. About Me Section */}
          {/* <div id="aboutMe" className="bg-gray-100 rounded p-1 m-1">
              <label
                htmlFor="aboutMe"
                className="block text-gray-600 text-sm font-bold mb-1"
              >
                About Me
              </label>
              <EtContentEditorLight2
                value={formData.aboutMe}
                handleChange={(content) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    aboutMe: content,
                  }));
                }}
                placeholderText="Write about yourself here..."
                editorKey="aboutMe-editor"
              />
          </div> */}

          {/* 6. Picture Upload Section */}
          {/* <div id="pic" className="bg-gray-100 rounded p-1 m-1">
            <label
              htmlFor="pic"
              className="block text-gray-600 text-sm font-bold mb-1"
            >
              Role Profile Picture
            </label>
            <input
              type="file"
              name="pic"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  convertPicToBase64(file)
                    .then((base64) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        pic: base64,
                      }));
                    })
                    .catch((error) => {
                      console.error("Error converting image to Base64:", error);
                    });
                }
              }}
              className="w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-orange-300"
            />
            {formData.pic && (
              <img
                src={formData.pic}
                alt="Preview"
                className="mt-2 h-20 w-20 rounded-full border border-gray-300"
              />
            )}
          </div> */}

          {/* 7. Active Toggle Section */}
          {/* <div id="active" className="bg-gray-100 rounded p-1 m-1">
            <label
              htmlFor="active"
              className="block text-gray-600 text-sm font-bold mb-1"
            >
              Is Active?
            </label>
            <input
              type="checkbox"
              name="active"
              id="active"
              checked={formData.active}
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...prevState,
                  active: e.target.checked,
                }));
              }}
              className="w-5 h-5 text-orange-500 rounded border-gray-300 focus:ring-orange-500 focus:border-orange-500"
            />
          </div> */}

          {/* 8. Submission Actions Bar */}
          <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic 
              name={'Update'} 
              type={'create'} 
              handleClick={handleSubmit} 
              />
            <EtBtnFormBasic
              name={'Clear'}
              type={'clear'}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={'Cancel'}
              type={'cancel'}
              handleClick={handleCancel}
            />
          </div>

        </form>
      )}
    </div>
  );
}

export default EtUpdateUserImprintShowcaseComp