import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import {
  PlusIcon,
  MinusIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline"
import { ClockIcon, BuildingOffice2Icon } from "@heroicons/react/24/solid"
import { useGetUserImprintShowcasesQuery } from '@/features/userMagic/userImprint/userImprintShowcasesApiSlice'

import EtModalCreateUserImprintShowcase from "@/components/common/modal/EtModalCreateUserImprintShowcase"
import EtModalUpdateUserImprintShowcase from "@/components/common/modal/EtModalUpdateUserImprintShowcase"

//05 Dec 2024
import EtShowcaseSelfNotesIcon1SVGComp from "@/assets/img/icons/svgComp/EtShowcaseSelfNotesIcon1SVGComp"
import EtShowcaseTrailIcon1SVGComp from "@/assets/img/icons/svgComp/EtShowcaseTrailIcon1SVGComp"
import EtShowcaseIcon3SVGComp from "@/assets/img/icons/svgComp/EtShowcaseIcon3SVGComp"
import EtCapabilitiesAdvisoryIcon1SVGComp from "@/assets/img/icons/svgComp/EtCapabilitiesAdvisoryIcon1SVGComp"
import EtCapabilitiesDomainIcon1SVGComp from "@/assets/img/icons/svgComp/EtCapabilitiesDomainIcon1SVGComp"
import EtCapabilitiesFunctionIcon1SVGComp from "@/assets/img/icons/svgComp/EtCapabilitiesFunctionIcon1SVGComp"
import EtCapabilitiesIndustryIcon1SVGComp from "@/assets/img/icons/svgComp/EtCapabilitiesIndustryIcon1SVGComp"
import EtCapabilitiesSalesIcon1SVGComp from "@/assets/img/icons/svgComp/EtCapabilitiesSalesIcon1SVGComp"
import EtCapabilitiesServiceIcon1SVGComp from "@/assets/img/icons/svgComp/EtCapabilitiesServiceIcon1SVGComp"
import EtCapabilitiesTechAreaIcon1SVGComp from "@/assets/img/icons/svgComp/EtCapabilitiesTechAreaIcon1SVGComp"

// 08 Dec 2024
import EtShowcaseProblemsIcon1SVGComp from "@/assets/img/icons/svgComp/EtShowcaseProblemsIcon1SVGComp"
import EtShowcaseProblemIcon1SVGComp from "@/assets/img/icons/svgComp/EtShowcaseProblemIcon1SVGComp"
import EtShowcaseProblemHeadIcon1SVGComp from "@/assets/img/icons/svgComp/EtShowcaseProblemHeadIcon1SVGComp"
import EtShowcaseProblemLevelIcon1SVGComp from "@/assets/img/icons/svgComp/EtShowcaseProblemLevelIcon1SVGComp"
import EtShowcaseHeadwindsIcon1SVGComp from "@/assets/img/icons/svgComp/EtShowcaseHeadwindsIcon1SVGComp"
import EtShowcaseHeadwindIcon1SVGComp from "@/assets/img/icons/svgComp/EtShowcaseHeadwindIcon1SVGComp"
import EtShowcaseApproachesIcon2SVGComp from "@/assets/img/icons/svgComp/EtShowcaseApproachesIcon2SVGComp"
import EtShowcaseApproachIcon1SVGComp from "@/assets/img/icons/svgComp/EtShowcaseApproachIcon1SVGComp"
import EtShowcaseActionsAndResultsIcon2SVGComp from "@/assets/img/icons/svgComp/EtShowcaseActionsAndResultsIcon2SVGComp"
import EtShowcaseProblemActionIcon1SVGComp from "@/assets/img/icons/svgComp/EtShowcaseProblemActionIcon1SVGComp"
import EtShowcaseProblemActionResultIcon1SVGComp from "@/assets/img/icons/svgComp/EtShowcaseProblemActionResultIcon1SVGComp"
import EtShowcaseResultImpactsIcon1SVGComp from "@/assets/img/icons/svgComp/EtShowcaseResultImpactsIcon1SVGComp"
import EtShowcaseResultDirectionIcon1SVGComp from "@/assets/img/icons/svgComp/EtShowcaseResultDirectionIcon1SVGComp"
import EtShowcaseResultLongevityIcon1SVGComp from "@/assets/img/icons/svgComp/EtShowcaseResultLongevityIcon1SVGComp"
import EtShowcaseResultMagnitudeIcon1SVGComp from "@/assets/img/icons/svgComp/EtShowcaseResultMagnitudeIcon1SVGComp"

import {
  formatDateToMonthYear,
  formatDateToYear,
  formatDateTime,
  formatShortDate,
  calculateYearsAndMonths,
} from "@/utils/formatDate"

import { getTimeSince } from "@/utils/EtGetTimeSince"
import { useDeleteUserImprintShowcaseMutation } from "../userImprintShowcasesApiSlice"


const EtImprintShowcase = ({ userId, userInfo }) => {
  // First Created - 03 Dec 2024
  // Author - Atul Pandey
  // Updated - 05 Dec 2024 - Refinements for section displays

  // 1a - State for Modal -- 03 Dec 2024
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [createTriggeringUrl, setCreateTriggeringUrl] = useState(null)
  const [updateTriggeringUrl, setUpdateTriggeringUrl] = useState(null)

  // 1b - State for JSX Display - 05 Dec 2024
  const [showShowcaseTrail, setShowShowcaseTrail] = useState(false)
  const [showShowcaseDetails, setShowShowcaseDetails] = useState({})
  const [showShowcaseSelfNotes, setShowShowcaseSelfNotes] = useState(false)
  const [showShowcaseSelfNoteDetails, setShowShowcaseSelfNoteDetails] = useState({}) 
  const [ showProblems, setShowProblems ] = useState({}) // 07 Dec 2024
  const [ showProblemDesc, setShowProblemDesc ] = useState({}) // 07 Dec 2024
  const [ showHeadwinds, setShowHeadwinds ] = useState({}) // 07 Dec 2024
  const [ showHeadwindDesc, setShowHeadwindDesc ] = useState({}) // 08 Dec 2024
  const [ showApproaches, setShowApproaches ] = useState({}) // 07 Dec 2024
  const [ showApproachDesc, setShowApproachDesc ] = useState({}) // 08 Dec 2024
  const [ showActionsAndResults, setShowActionsAndResults ] = useState({}) // 07 Dec 2024
  const [ showActionDesc, setShowActionDesc ] = useState({}) // 08 Dec 2024
  const [ showActionResults, setShowActionResults ] = useState({}) // 08 Dec 2024
  const [ showActionResultProblems, setShowActionResultProblems ] = useState({}) // 08 Dec 2024
  const [showShowcaseResultNoteDetails, setShowShowcaseResultNoteDetails] = useState({}) // 08 Dec 2024

  // 2a - Extract needed data from the incoming props
  const userOrgId = userInfo[0]?.userOrgId._id || null
  // console.log('userOrgId', userOrgId)

  // 2b - Get UserImprintShowcase Data - 03 Dec 2024
  const { data: userImprintShowcaseData } = useGetUserImprintShowcasesQuery()
  const userImprintShowcases = userImprintShowcaseData?.ids
    ?.map((id) => userImprintShowcaseData?.entities[id])
    ?.filter(
      (item) => item.userId?._id === userId && item.userOrgId._id === userOrgId
    )
  console.log("userImprintShowcases", userImprintShowcases)

  // 3a - Handle Create User Imprint Showcase
  const handleCreateUserImprintShowcase = () => {
    const currentUrl = window.location.pathname
    setIsCreateModalOpen(true)
    setCreateTriggeringUrl(currentUrl)
  }

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false)
    setCreateTriggeringUrl(null)
    // window.location.reload();
  }

  // 3b - Handle Update User Imprint Showcase
  const handleUpdateUserImprintShowcase = () => {
    const currentUrl = window.location.pathname
    setIsUpdateModalOpen(true)
    setUpdateTriggeringUrl(currentUrl)
  }

  const handleCloseUpdateModal = () => {
    setIsUpdateModalOpen(false)
    setUpdateTriggeringUrl(null)
    // window.location.reload();
  }

  // 3c - Toggle Show function for show showcase self note details - 05 Dec 2024
  const handleToggleShowcaseSelfNoteDetails = (noteId) => {
    setShowShowcaseSelfNoteDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
  }

  // 3d - Toggle Show function for show showcase details - 05 Dec 2024
  const handleToggleShowcaseDetails = (showcaseId) => {
    setShowShowcaseDetails((prev) => ({
      ...prev,
      [showcaseId]: !prev[showcaseId],
    }))
  }
  
  // 3e - Toggle Show function for show problems details - 07 Dec 2024
  const handleToggleProblems = (problemsId) => {
    setShowProblems((prev) => ({
      ...prev,
      [problemsId]: !prev[problemsId],
    }))
  }
  
  // 3f - Toggle Show function for show problem desc - 07 Dec 2024
  const handleToggleProblemDesc = (showcaseId) => {
    setShowProblemDesc((prev) => ({
      ...prev,
      [showcaseId]: !prev[showcaseId],
    }))
  }

  // 3g - Toggle Show function for show headwinds details - 08 Dec 2024
  const handleToggleHeadwinds = (headwindsId) => {
    setShowHeadwinds((prev) => ({
      ...prev,
      [headwindsId]: !prev[headwindsId],
    }))
  }
  
  // 3h - Toggle Show function for show headwind desc - 08 Dec 2024
  const handleToggleHeadwindDesc = (showcaseId) => {
    setShowHeadwindDesc((prev) => ({
      ...prev,
      [showcaseId]: !prev[showcaseId],
    }))
  }

  // 3i - Toggle Show function for show approaches details - 08 Dec 2024
  const handleToggleApproaches = (approachsId) => {
    setShowApproaches((prev) => ({
      ...prev,
      [approachsId]: !prev[approachsId],
    }))
  }
  
  // 3j - Toggle Show function for show approach desc - 08 Dec 2024
  const handleToggleApproachDesc = (showcaseId) => {
    setShowApproachDesc((prev) => ({
      ...prev,
      [showcaseId]: !prev[showcaseId],
    }))
  }

  // 3k - Toggle Show function for show actionsAndResults details - 08 Dec 2024
  const handleToggleActionsAndResults = (approachsId) => {
    setShowActionsAndResults((prev) => ({
      ...prev,
      [approachsId]: !prev[approachsId],
    }))
  }

  // 3l - Toggle Show function for show action desc - 08 Dec 2024
  const handleToggleActionDesc = (showcaseId) => {
    setShowActionDesc((prev) => ({
      ...prev,
      [showcaseId]: !prev[showcaseId],
    }))
  }

  // 3m - Toggle Show function for show action results - 08 Dec 2024
  const handleToggleActionResults = (showcaseId) => {
    setShowActionResults((prev) => ({
      ...prev,
      [showcaseId]: !prev[showcaseId],
    }))
  }
  
  // 3n - Toggle Show function for show action result problems - 08 Dec 2024
  const handleToggleActionResultProblems = (showcaseId) => {
    setShowActionResultProblems((prev) => ({
      ...prev,
      [showcaseId]: !prev[showcaseId],
    }))
  }

  // 3o - Toggle Show function for show showcase result note details - 08 Dec 2024
  const handleToggleShowcaseResultNoteDetails = (noteId) => {
    setShowShowcaseResultNoteDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
  }

  // 5 - Function to get the appropriate color class based on section score
  const getColorForScore = (score) => {
    if (score >= 0 && score < 0.9) return "bg-etpx-0"
    if (score >= 0.9 && score < 1.9) return "bg-etpx-1"
    if (score >= 1.9 && score < 2.9) return "bg-etpx-2"
    if (score >= 2.9 && score < 3.9) return "bg-etpx-3"
    if (score >= 3.9 && score < 4.9) return "bg-etpx-4"
    return "bg-etpx-5" // For score >= 4.9
  }

  // 6 - create Markup
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
  }


  return (
    <div className="m-1 p-1">
      {userImprintShowcases?.length === 0 && (
        <div className="flex justify-between items-center">
          <div>
            <p className="m-1 p-1 text-xs">
              There is no showcase record you have created yet! Change that by
              clicking the Add button.
            </p>
          </div>
          <div className="flex justify-start items-center">
            <p className="text-xs font-bold mx-1 px-1">Add</p>
            <button
              type="button"
              onClick={handleCreateUserImprintShowcase}
              className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
            >
              <PlusIcon className="h-3 w-3" />
            </button>
          </div>
          <EtModalCreateUserImprintShowcase
            isOpen={isCreateModalOpen}
            userId={userId}
            userOrgId={userOrgId}
            triggeringUrl={createTriggeringUrl}
            onClose={handleCloseCreateModal}
          />
        </div>
      )}
      {userImprintShowcases?.length >0 && userImprintShowcases?.map((showcase, showcaseIndex) => {
        const headline = showcase?.userImprintShowcaseHeadlineName || ""
        const overview = showcase?.userImprintShowcaseHeadlineDesc || ""
        const imprintShowcases = showcase?.userImprintShowcases || []
        const selfNotes = showcase?.userImprintShowcaseSelfNotes || []

        return(
          <div key={showcase._id + showcaseIndex} className="m-1 p-1">
            {/* Header */}
            <div className="border-b">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-xs font-bold text-orange-600 m-1 p-1">
                      {headline}
                    </p>
                  </div>
                  <div>
                    <button type="button" onClick={handleUpdateUserImprintShowcase}>
                      <PencilSquareIcon className="h-5 w-5 text-orange-600 m-1 p-1" />
                    </button>
                  </div>
                  <EtModalUpdateUserImprintShowcase
                    isOpen={isUpdateModalOpen}
                    userId={userId}
                    userOrgId={userOrgId}
                    triggeringUrl={updateTriggeringUrl}
                    onClose={handleCloseUpdateModal}
                  />
                </div>
                <p
                  dangerouslySetInnerHTML={createMarkup(overview)}
                  className="text-xs leading-4 text-gray-600 m-1 p-1"
                ></p>
            </div>

             {/* Section 1 - Showcases Trail */}
             <div className=" m-1 p-1 bg-gray-200 rounded ">
             <div className="grid grid-cols-8 justify-start items-center">
                  <div
                    className={`col-span-3 flex justify-start items-center m-1 p-1`}
                  >
                    <div
                      onClick={() => setShowShowcaseTrail((prev) => !prev)}
                      className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                    >
                      {showShowcaseTrail ? (
                        <MinusIcon className="h-4 w-4 text-orange-600" />
                      ) : (
                        <PlusIcon className="h-4 w-4 text-orange-600" />
                      )}
                    </div>
                    <div
                      className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer"
                      onClick={() => setShowShowcaseTrail((prev) => !prev)}
                    >
                      <EtShowcaseTrailIcon1SVGComp className="h-6 w-6 text-orange-600" />
                    </div>
                    <div>
                      <p className="mx-1 px-1 font-bold text-gray-600">
                        Showcase Trail
                      </p>
                      <p className="mx-1 px-1 text-gray-400 text-xs italic">
                        what contributions am I super proud of?
                      </p>
                    </div>
                  </div>
                </div>

                {showShowcaseTrail && 
                imprintShowcases?.length>0 &&
                imprintShowcases
                ?.slice()
                 .sort(
                        (a, b) =>
                        new Date(b.userShowcaseStartDate) -
                        new Date(a.userShowcaseStartDate)
                    )
                ?.map((imprintShowcase, imprintShowcaseIndex) => {
                  const showcaseName = imprintShowcase?.userShowcaseName || ""
                  const showcaseDesc = imprintShowcase?.userShowcaseDesc || ""
                  const showcaseTypeName = imprintShowcase?.userShowcaseTypeId?.showcaseTypeName || ""
                  const showcaseTypePic = imprintShowcase?.userShowcaseTypeId?.pic || ""
                  const orgName = imprintShowcase?.userShowcaseTargetName || ""
                  const orgDesc = imprintShowcase?.userShowcaseTargetDesc || ""
                  const orgType = imprintShowcase?.userShowcaseTargetType || ""
                  const showcaseStartDate = imprintShowcase?.userShowcaseStartDate || null
                  const showcaseEndDate =   imprintShowcase?.userShowcaseEndDate || null
                  const showcaseAdvisoryName = imprintShowcase?.userShowcaseAdvisory?.capabilitiesAdvisoryTypeName || ""
                  const showcaseAdvisoryPic = imprintShowcase?.userShowcaseAdvisory?.pic || ""
                  const showcaseDomainName = imprintShowcase?.userShowcaseDomain?.capabilitiesDomainTypeName || ""
                  const showcaseDomainPic = imprintShowcase?.userShowcaseDomain?.pic || ""
                  const showcaseFunctionName = imprintShowcase?.userShowcaseFunction?.capabilitiesFunctionTypeName || ""
                  const showcaseFunctionPic = imprintShowcase?.userShowcaseFunction?.pic || ""
                  const showcaseIndustryName = imprintShowcase?.userShowcaseIndustry?.capabilitiesIndustryTypeName || ""
                  const showcaseIndustryPic = imprintShowcase?.userShowcaseIndustry?.pic || ""
                  const showcaseSalesName = imprintShowcase?.userShowcaseSales?.capabilitiesSalesTypeName || ""
                  const showcaseSalesPic = imprintShowcase?.userShowcaseSales?.pic || ""
                  const showcaseServiceName = imprintShowcase?.userShowcaseService?.capabilitiesServiceTypeName || ""
                  const showcaseServicePic = imprintShowcase?.userShowcaseService?.pic || ""
                  const showcaseTechAreaName = imprintShowcase?.userShowcaseTechArea?.capabilitiesTechAreaTypeName || ""
                  const showcaseTechAreaPic = imprintShowcase?.userShowcaseTechArea?.pic || ""
                  const showcaseTechStackName = imprintShowcase?.userShowcaseTechStack?.capabilitiesTechStackTypeName || ""
                  const showcaseTechStackPic = imprintShowcase?.userShowcaseTechStack?.pic || ""
                  const showcaseProblems = imprintShowcase?.userShowcaseProblems || []
                  const showcaseHeadwinds = imprintShowcase?.userShowcaseHeadwinds || []
                  const showcaseApproaches = imprintShowcase?.userShowcaseApproachLevers || []
                  const showcaseActionsAndResults = imprintShowcase?.userShowcaseActionsAndResults || []

                  console.log('showcase problems', showcaseProblems)

                  const capabilityAreas = [
                    {'area':'Advisory','areaName': showcaseAdvisoryName, 'areaPic': showcaseAdvisoryPic},
                    {'area':'Domain','areaName': showcaseDomainName, 'areaPic': showcaseDomainPic},
                    {'area':'Function','areaName': showcaseFunctionName, 'areaPic': showcaseFunctionPic},
                    {'area':'Industry','areaName': showcaseIndustryName, 'areaPic': showcaseIndustryPic},
                    {'area':'Sales','areaName': showcaseSalesName, 'areaPic': showcaseSalesPic},
                    {'area':'Service','areaName': showcaseServiceName, 'areaPic': showcaseServicePic},
                    {'area':'Tech Area','areaName': showcaseTechAreaName, 'areaPic': showcaseTechAreaPic},
                    {'area':'Tech Stack','areaName': showcaseTechStackName, 'areaPic': showcaseTechStackPic},
                  ]

                  return(
                    <div key={imprintShowcase._id + imprintShowcaseIndex} className="m-1 p-1 bg-white rounded">
                          <div className="grid grid-cols-8 justify-start items-center">
                            <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                              <div
                                onClick={() =>
                                  handleToggleShowcaseDetails(imprintShowcase._id)
                                }
                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                              >
                                {showShowcaseDetails[imprintShowcase._id] ? (
                                  <MinusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                  <PlusIcon className="h-4 w-4 text-orange-600" />
                                )}
                              </div>
                              <div>
                                <p className="text-xl font-bold mx-1 px-1 text-gray-600">
                                  {imprintShowcaseIndex + 1}.
                                </p>
                              </div>
                              <div
                                className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer"
                                onClick={() =>
                                  handleToggleShowcaseDetails(imprintShowcase._id)
                                }
                              >
                                <EtShowcaseIcon3SVGComp className="h-6 w-6 text-orange-600" />
                              </div>
                              <div>
                                <p className="text-sm font-bold mx-1 px-1 text-gray-600">
                                  {showcaseName}
                                </p>
                              </div>
                            </div>
                            <div className="col-span-2 flex justify-start items-center m-1 p-1">
                              <div className="flex justify-center items-center rounded-full h-7 w-7 bg-gray-100 hover:bg-gray-50">
                                <img src={showcaseTypePic} alt="" className="h-6 w-6"/>
                              </div>
                              <div>
                              <p className="text-[11px] font-bold text-gray-400 mx-1 px-1">Showcase Area:</p>
                              <p className="text-[9px] italic text-gray-800 mx-1 px-1">{showcaseTypeName}</p>
                              </div>
                              
                            </div>
                            <div className="col-span-2 flex justify-start items-center m-1 p-1">
                              <div>
                                <BuildingOffice2Icon className="h-4 w-4 text-orange-600" />
                              </div>
                              <div>
                                <p className="text-[10px] text-gray-600 mx-1 px-1">
                                  Org:
                                </p>
                              </div>
                              <div>
                                <p className="text-[10px] text-orange-600 mx-1 px-1">
                                  {orgName}
                                </p>
                              </div>
                            </div>
                            <div className="col-span-1 flex justify-start items-center m-1 p-1">
                              <ClockIcon className="h-4 w-4 text-lime-600" />
                              <p className="text-[10px] mx-1">
                                {showcaseStartDate ? formatDateToMonthYear(showcaseStartDate) : "Today"}{" "}
                                -{" "}
                              </p>
                              <p className="text-[10px] mx-1">
                                {showcaseEndDate ? formatDateToMonthYear(showcaseEndDate) : "Today"}
                              </p>
                            </div>
                          </div>
                          {showShowcaseDetails[imprintShowcase._id] && (
                            <div>
                              
                              {/* Context & Capability Areas */}
                              <div className="grid grid-cols-2 justify-between items-start gap-1 m-1 p-1 border rounded">
                                
                                {/* Showcase Context */}
                                <div className="bg-gray-50 m-1 p-1 rounded">
                                  <p className="font-bold mx-1 px-1 text-gray-600">Context</p>
                                  <p className="m-1 p-1 text-xs leading-4 text-gray-800" dangerouslySetInnerHTML={createMarkup(showcaseDesc)}></p>
                                </div>
                                
                                {/* Showcase Capabilities */}
                                <div className="bg-gray-50 m-1 p-1 rounded">
                                  <p className="font-bold mx-1 px-1 text-gray-600">Capability Areas</p>
                                  {capabilityAreas.length>0 && 
                                  capabilityAreas
                                  ?.filter((area) => area.areaName) // Exclude areas with empty names
                                  ?.map((area, areaIndex) => (
                                  
                                  <div key={area.areaName+areaIndex} className="bg-gray-100 flex justify-start items-center m-1 p-1 rounded">
                                        <div>
                                          <img src={area.areaPic} alt="" className="h-5 w-5"/>
                                        </div>
                                        <div className="flex justify-start items-center">
                                          <p className="text-[10px] mx-1 px-1 font-bold text-gray-600">{area.area}</p>
                                          <p className="text-[10px] mx-1 px-1">{area.areaName}</p>
                                        </div>
                                    </div>
                                  ))}

                                </div>
                              </div>
                              
                              {/* Problems */}
                              {(showcaseProblems && showcaseProblems.length > 0) &&(
                              <div className="m-1 p-1 border rounded">
                                <div className="flex justify-start items-center m-1 p-1">
                                        <div
                                            onClick={() => handleToggleProblems(imprintShowcase._id)}
                                            className="cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                          >
                                            {showProblems[imprintShowcase._id] ? (
                                              <MinusIcon className="h-3 w-3 text-orange-600" />
                                            ) : (
                                              <PlusIcon className="h-3 w-3 text-orange-600" />
                                            )}
                                        </div>
                                        <div><EtShowcaseProblemsIcon1SVGComp className="h-8 w-8 text-orange-600" /></div>
                                        <div><p className="font-bold mx-1 px-1 text-gray-600">Problems</p></div>

                                </div>
                                  { showProblems[imprintShowcase._id] && showcaseProblems?.map((problem, problemIndex)=> {
                                    const problemName = problem?.problemName || ""
                                    const problemDesc = problem?.problemDesc || ""
                                    const problemArea = problem?.problemAreaType?.problemAreaTypeName || ""
                                    const problemGradation = problem?.problemMetricGradationType?.problemMetricGradationTypeName || ""
                                    const problemMetricUoM = problem?.problemMetricUoMType?.problemMetricUoMTypeName || ""
                                    const problemCurrentLevel = problem?.problemCurrentMeasureLevel || 0                              
                                    // console.log('showcase problemname', problemName)
                                    return(
                                      <div key={problem._id+problemIndex} className=" bg-gray-100 rounded m-1 p-1">  
                                        <div className="grid grid-cols-8 justify-start items-center">
                                          <div className="col-span-3 flex justify-start items-center m-1 p-1 rounded">
                                              <div
                                                onClick={() => handleToggleProblemDesc(problem._id)}
                                                className="cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                              >
                                                {showProblemDesc[problem._id] ? (
                                                  <MinusIcon className="h-3 w-3 text-orange-600" />
                                                ) : (
                                                  <PlusIcon className="h-3 w-3 text-orange-600" />
                                                )}
                                              </div>
                                              <p className="text-lg font-bold text-gray-600 mx-1 px-1">{problemIndex+1}.</p>
                                              <div><EtShowcaseProblemIcon1SVGComp className="h-6 w-6 text-orange-600" /></div>
                                              <p className="mx-1 px-1 text-xs font-bold text-gray-600">Problem: </p>
                                              <p className="mx-1 px-1 text-xs">{problemName}</p>
                                          </div>
                                          <div className="col-span-2 flex justify-start items-center m-1 p-1 rounded">
                                          <div><EtShowcaseProblemHeadIcon1SVGComp className="h-5 w-5 text-orange-600" /></div>
                                              <p className="mx-1 px-1 text-xs font-bold text-gray-600">Problem Head: </p>
                                              <p className="mx-1 px-1 text-xs">{problemArea}</p>
                                          </div>
                                          <div className="col-span-3 flex justify-start items-center m-1 p-1 rounded">
                                              <div><EtShowcaseProblemLevelIcon1SVGComp className="h-5 w-5 text-orange-600" /></div>
                                              <p className="mx-1 px-1 text-xs font-bold text-gray-600">Current Level: </p>
                                              <p className="mx-1 px-1 text-xs">{problemGradation}</p>
                                              <p className="mx-1 font-bold text-xs text-orange-600">{problemCurrentLevel}</p>
                                              <p className="text-xs">{problemMetricUoM}</p>
                                          </div>
                                        </div>
                                        {showProblemDesc[problem._id] && (
                                          <div className="bg-white m-1 p-1 rounded">
                                            <p className="text-xs font-bold m-1 p-1 text-orange-600">Problem Description</p>
                                            <p className="mx-1 px-1 text-xs" dangerouslySetInnerHTML={createMarkup(problemDesc)}></p>
                                          </div>)}
                                        
                                      </div>
                                    )
                                  })}
                              </div>
                              )}

                              {/* Headwinds */}
                              {(showcaseHeadwinds && showcaseHeadwinds.length > 0) &&(
                              <div className="m-1 p-1 border rounded">
                                <div className="flex justify-start items-center m-1 p-1">
                                        <div
                                            onClick={() => handleToggleHeadwinds(imprintShowcase._id)}
                                            className="cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                          >
                                            {showHeadwinds[imprintShowcase._id] ? (
                                              <MinusIcon className="h-3 w-3 text-orange-600" />
                                            ) : (
                                              <PlusIcon className="h-3 w-3 text-orange-600" />
                                            )}
                                        </div>
                                        <div><EtShowcaseHeadwindsIcon1SVGComp className="h-8 w-8 text-orange-600" /></div>
                                        <div><p className="font-bold mx-1 px-1 text-gray-600">Headwinds</p></div>

                                </div>
                                  { showHeadwinds[imprintShowcase._id] && (
                                    <div className="grid grid-cols-3 justify-between items-start">
                                      {showcaseHeadwinds?.map((headwind, headwindIndex)=> {
                                        const headwindName = headwind?.headwindName || ""
                                        const headwindDesc = headwind?.headwindDesc || ""
                                        return(
                                          <div key={headwind._id+headwindIndex} className=" bg-gray-100 rounded m-1 p-1 ">  
                                            
                                              <div className="flex justify-start items-center m-1 p-1 rounded">
                                                  
                                                  <p className="text-lg font-bold text-gray-600 mx-1 px-1">{headwindIndex+1}.</p>
                                                  <div><EtShowcaseHeadwindIcon1SVGComp className="h-6 w-6 text-orange-600" /></div>
                                                  <p className="mx-1 px-1 text-xs font-bold text-gray-600">Headwind: </p>
                                                  <p className="mx-1 px-1 text-xs">{headwindName}</p>
                                              </div>
                                            
                                              <div className="bg-white m-1 p-1 rounded">
                                                <p className="text-xs font-bold m-1 p-1 text-orange-600">Headwind Description</p>
                                                <p className="mx-1 px-1 text-xs" dangerouslySetInnerHTML={createMarkup(headwindDesc)}></p>
                                              </div>
                                            
                                          </div>
                                          )
                                        })}
                                      </div>
                                    )}
                                  
                                  
                              </div>
                              )}

                              {/* Approach Levers */}
                              {(showcaseApproaches && showcaseApproaches.length > 0) &&(
                              <div className="m-1 p-1 border rounded">
                                <div className="flex justify-start items-center m-1 p-1">
                                        <div
                                            onClick={() => handleToggleApproaches(imprintShowcase._id)}
                                            className="cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                          >
                                            {showApproaches[imprintShowcase._id] ? (
                                              <MinusIcon className="h-3 w-3 text-orange-600" />
                                            ) : (
                                              <PlusIcon className="h-3 w-3 text-orange-600" />
                                            )}
                                        </div>
                                        <div><EtShowcaseApproachesIcon2SVGComp className="h-8 w-8 text-orange-600" /></div>
                                        <div><p className="font-bold mx-1 px-1 text-gray-600">Approaches</p></div>

                                </div>
                                  { showApproaches[imprintShowcase._id] && (
                                    <div className="grid grid-cols-3 justify-between items-center gap-1">
                                      {showcaseApproaches?.map((approach, approachIndex)=> {
                                        const approachName = approach?.approachName || ""
                                        const approachDesc = approach?.approachDesc || ""
                                        return(
                                          <div key={approach._id+approachIndex} className=" bg-gray-100 rounded m-1 p-1">  
                                            
                                              <div className="flex justify-start items-center m-1 p-1 rounded">
                                                  
                                                  <p className="text-lg font-bold text-gray-600 mx-1 px-1">{approachIndex+1}.</p>
                                                  <div><EtShowcaseApproachIcon1SVGComp className="h-6 w-6 text-orange-600" /></div>
                                                  <p className="mx-1 px-1 text-xs font-bold text-gray-600">Approach: </p>
                                                  <p className="mx-1 px-1 text-xs">{approachName}</p>
                                              </div>
                                            
                                              <div className="bg-white m-1 p-1 rounded">
                                                <p className="text-xs font-bold m-1 p-1 text-orange-600">Approach Description</p>
                                                <p className="mx-1 px-1 text-xs" dangerouslySetInnerHTML={createMarkup(approachDesc)}></p>
                                              </div>
                                            
                                          </div>
                                          )
                                        })}
                                      </div>
                                    )}
                                  
                                  
                              </div>
                              )}

                              {/* Actions And Results */}
                              {(showcaseActionsAndResults && showcaseActionsAndResults.length > 0) &&(
                              <div className="m-1 p-1 border rounded">
                                <div className="flex justify-start items-center m-1 p-1">
                                        <div
                                            onClick={() => handleToggleActionsAndResults(imprintShowcase._id)}
                                            className="cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                          >
                                            {showActionsAndResults[imprintShowcase._id] ? (
                                              <MinusIcon className="h-3 w-3 text-orange-600" />
                                            ) : (
                                              <PlusIcon className="h-3 w-3 text-orange-600" />
                                            )}
                                        </div>
                                        <div><EtShowcaseActionsAndResultsIcon2SVGComp className="h-8 w-8 text-orange-600" /></div>
                                        <div><p className="font-bold mx-1 px-1 text-gray-600">Actions & Results</p></div>

                                </div>
                                  { showActionsAndResults[imprintShowcase._id] && showcaseActionsAndResults?.map((action, actionIndex)=> {
                                    const actionName = action?.actionName || ""
                                    const actionDesc = action?.actionDesc || ""
                                    const actionResults = action?.actionResults || []
                                    
                                    return(
                                      <div key={action._id+actionIndex} className=" bg-gray-100 rounded m-1 p-1">  
                                        <div className="grid grid-cols-9 justify-start items-center">
                                          <div className="col-span-3 flex justify-start items-center m-1 p-1 rounded">
                                              <div
                                                onClick={() => handleToggleActionDesc(action._id)}
                                                className="cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                              >
                                                {showActionDesc[action._id] ? (
                                                  <MinusIcon className="h-3 w-3 text-orange-600" />
                                                ) : (
                                                  <PlusIcon className="h-3 w-3 text-orange-600" />
                                                )}
                                              </div>
                                              <p className="text-lg font-bold text-gray-600 mx-1 px-1">{actionIndex+1}.</p>
                                              <div><EtShowcaseProblemActionIcon1SVGComp className="h-7 w-7 text-orange-600" /></div>
                                              <div>
                                              <p className="mx-1 px-1 text-xs font-bold text-gray-400">Action: </p>
                                              <p className="mx-1 px-1 text-[10px]">{actionName}</p>
                                              </div>
                                              
                                          </div>
                                          <div className="col-span-6">
                                            {actionResults.length>0 && actionResults?.map((result, resultIndex) => {
                                              const problems = result?.problems || []
                                              const direction = result?.resultDirection || ""
                                              const longevity = result?.resultLongevity || ""
                                              const magnitude = result?.resultMagnitude || ""
                                              const notes = result?.resultNotes || []
                                              
                                              return(
                                                    <div key={result._id+resultIndex} className=" grid grid-cols-3 justify-between items-start">
                                                      
                                                      <div className="col-span-2 w-full">
                                                        <div className="flex justify-start items-center m-1 p-1">
                                                          <div><EtShowcaseProblemActionResultIcon1SVGComp className='h-7 w-7 text-orange-600'/></div>
                                                          <p className="m-1 p-1 text-xs font-bold text-gray-400">Results:</p>
                                                        </div>
                                                      
                                                      {problems?.length>0 && problems?.map((resProblem, resProblemIndex) =>{
                                                        const problemName = resProblem?.problemName || ""
                                                        const preLevel = resProblem?.problemPreActionLevel || 0
                                                        const postLevel = resProblem?.problemPostActionLevel || 0
                                                        const preLevelUoM = resProblem?.problemPreActionMetricUoMType?.problemMetricUoMTypeName
                                                        const postLevelUoM = resProblem?.problemMetricUoMType?.problemMetricUoMTypeName
                                                        const preLevelGradation = resProblem?.problemPreActionMetricGradationType?.problemMetricGradationTypeName
                                                        const postLevelGradation = resProblem?.problemMetricGradationType?.problemMetricGradationTypeName
                                                        || ""

                                                        return(
                                                          <div key={resProblem._id+resProblemIndex} className="bg-gray-200 m-1 p-1 rounded">
                                                            <div className="flex justify-start items-center">
                                                              <div
                                                                  onClick={() => handleToggleActionResultProblems(resProblem._id)}
                                                                  className="cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                                >
                                                                  {showActionResultProblems[resProblem._id] ? (
                                                                    <MinusIcon className="h-3 w-3 text-orange-600" />
                                                                  ) : (
                                                                    <PlusIcon className="h-3 w-3 text-orange-600" />
                                                                  )}
                                                                </div>
                                                                <p className="text-lg font-bold text-gray-600 mx-1 px-1">{resProblemIndex+1}.</p>
                                                                <div><EtShowcaseProblemIcon1SVGComp className="h-4 w-4 text-orange-600" /></div>
                                                                
                                                                <p className="text-[10px] mx-1 px-1 text-gray-600 font-bold">Problem :</p>
                                                                <p className="text-[10px] mx-1 px-1">{problemName}</p>
                                                            </div>

                                                            {showActionResultProblems[resProblem._id] && (
                                                              <div className="bg-white m-1 p-1">
                                                                <p className="text-orange-600 mx-1 px-1 text-[10px] font-bold">Results:</p>
                                                                
                                                                <div className="flex justify-start items-center bg-gray-50 m-1 p-1 border rounded">
                                                                  <p className="text-[9px] font-bold text-gray-600 mx-1 px-1">Before:</p>
                                                                  <p className="text-[9px] mx-1 px-1">{preLevelGradation}</p>
                                                                  <p className="text-[9px] mx-1 px-1 text-orange-600">{preLevel}</p>
                                                                  <p className="text-[9px]">{preLevelUoM}</p>
                                                                </div>

                                                                <div className="flex justify-start items-center bg-gray-50 m-1 p-1 border rounded">
                                                                  <p className="text-[9px] font-bold text-gray-600 mx-1 px-1">After:</p>
                                                                  <p className="text-[9px] mx-1 px-1">{postLevelGradation}</p>
                                                                  <p className="text-[9px] mx-1 px-1 text-lime-600 font-bold">{postLevel}</p>
                                                                  <p className="text-[9px]">{postLevelUoM}</p>
                                                                </div>
                                                              </div>
                                                            )}
                                                            

                                                          </div>
                                                        )
                                                      })}
                                                      </div>

                                                      <div className="col-span-1 w-full">
                                                      <div className="flex justify-start items-center m-1 p-1">
                                                          <div><EtShowcaseResultImpactsIcon1SVGComp className='h-7 w-7 text-orange-600'/></div>
                                                          <p className="m-1 p-1 text-xs font-bold text-gray-400">Impacts:</p>
                                                        </div>

                                                        <div className="bg-gray-200 m-1 p-1 rounded flex justify-start items-center">
                                                          <div className="mx-1 px-1"><EtShowcaseResultDirectionIcon1SVGComp className='h-4 w-4 text-orange-600'/></div>
                                                          <p className="text-[10px] mx-1 px-1 font-bold text-gray-600"> Direction:</p>
                                                          <p className="text-[10px] mx-1 px-1"> {direction}</p>
                                                        </div>

                                                        <div className="bg-gray-200 m-1 p-1 rounded flex justify-start items-center">
                                                          <div className="mx-1 px-1"><EtShowcaseResultLongevityIcon1SVGComp className='h-4 w-4 text-orange-600'/></div>
                                                          <p className="text-[10px] mx-1 px-1 font-bold text-gray-600"> Longevity:</p>
                                                          <p className="text-[10px] mx-1 px-1"> {longevity}</p>
                                                        </div>

                                                        <div className="bg-gray-200 m-1 p-1 rounded flex justify-start items-center">
                                                          <div className="mx-1 px-1"><EtShowcaseResultMagnitudeIcon1SVGComp className='h-4 w-4 text-orange-600'/></div>
                                                          <p className="text-[10px] mx-1 px-1 font-bold text-gray-600"> Magnitude:</p>
                                                          <p className="text-[10px] mx-1 px-1"> {magnitude}</p>
                                                        </div>
                                                        
                                                      </div>
                                                    </div>
                                               )
                                            })}
                                          </div>  
                                        </div>
                                        {showActionDesc[action._id] && (
                                          <div>
                                            {/* Result Action Description */}
                                            <div className="bg-white m-1 p-1 rounded">
                                              <p className="text-xs font-bold m-1 p-1 text-orange-600">Action Description</p>
                                              <p className="mx-1 px-1 text-xs" dangerouslySetInnerHTML={createMarkup(actionDesc)}></p>
                                            </div>
                                          
                                          {/* Result Notes */}
                                          {actionResults.length>0 && actionResults?.map((result, resultIndex) => {
                                            const notes = result?.resultNotes || []
                                            return(
                                              <div className="bg-white m-1 p-1 rounded">
                                              <p className="text-xs font-bold m-1 p-1 text-orange-600">Action Result Notes</p>

                                              {notes?.length>0 && notes?.map((resultNote, resultNoteIndex) => {
                                                const noteText = resultNote?.resultNoteText || ''
                                                const noteDate = resultNote?.resultNoteDate || ''
                                                const noteUpdateDate = resultNote?.resultNoteUpdateDate || ''
                                                                                                
                                                return(
                                                  <div key={resultNote._id + resultNoteIndex}
                                                  className="">
                                                      <div className="grid grid-cols-6 justify-between items-center m-1 p-1 bg-gray-100 border rounded">
                                                        <div className="col-span-1 flex justify-start items-center m-1 p-1">
                                                          <div
                                                            onClick={() =>
                                                              handleToggleShowcaseResultNoteDetails(resultNote._id)
                                                            }
                                                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                          >
                                                            {showShowcaseResultNoteDetails[resultNote._id] ? (
                                                              <MinusIcon className="h-4 w-4 text-orange-600" />
                                                            ) : (
                                                              <PlusIcon className="h-4 w-4 text-orange-600" />
                                                            )}
                                                          </div>
                                                          <div>
                                                            <p className="text-xl font-bold mx-1 px-1 text-gray-600">
                                                              {resultNoteIndex + 1}.
                                                            </p>
                                                          </div>
                                                          <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                            <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
                                                          </div>
                                                          <div>
                                                            <div className="flex flex-col justify-center items-center mx-1 px-1">
                                                              <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                                {getTimeSince(noteUpdateDate)}
                                                              </p>
                                                              <p className="text-[9px] text-gray-600 italic">
                                                                last updated
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="col-span-3">
                                                          <div
                                                            dangerouslySetInnerHTML={createMarkup(noteText)}
                                                            className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                          ></div>
                                                        </div>
                                                        <div className="col-span-2 flex justify-center items-center mx-2">
                                                          <ClockIcon className="h-5 w-5 text-lime-600" />
                                                          <p className="text-[9px] mx-1">First Created:</p>
                                                          <p className="text-gray-400 text-[9px] mx-2">
                                                            {formatDateTime(noteDate)}
                                                          </p>
                                                        </div>
                                                      </div>
                                                      {showShowcaseResultNoteDetails[resultNote._id] && (
                                                        <div
                                                          dangerouslySetInnerHTML={createMarkup(noteText)}
                                                          className="text-xs text-gray-600 m-1 p-1 border rounded leading-4 bg-gray-50"
                                                        ></div>
                                                      )}
                                                  </div>
                                                )
                                              })}
                                          </div>
                                            )
                                          })}
                                          </div>
                                        )}
                                      
                                      </div>
                                    )
                                  })}
                              </div>
                              )}
                            </div>

                          )}
                    </div>
                  )
                })}

             </div>
             
             {/* Section 2 - Showcases Self Notes Trail */}
             <div className=" m-1 p-1 bg-gray-200 rounded ">
                <div className="grid grid-cols-8 justify-start items-center">
                  <div
                    className={`col-span-3 flex justify-start items-center m-1 p-1`}
                  >
                    <div
                      onClick={() => setShowShowcaseSelfNotes((prev) => !prev)}
                      className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                    >
                      {showShowcaseSelfNotes ? (
                        <MinusIcon className="h-4 w-4 text-orange-600" />
                      ) : (
                        <PlusIcon className="h-4 w-4 text-orange-600" />
                      )}
                    </div>
                    <div
                      className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer"
                      onClick={() => setShowShowcaseSelfNotes((prev) => !prev)}
                    >
                      <EtShowcaseSelfNotesIcon1SVGComp className="h-6 w-6 text-orange-600" />
                    </div>
                    <div>
                      <p className="mx-1 px-1 font-bold text-gray-600">
                        Showcase Notes Trail
                      </p>
                      <p className="mx-1 px-1 text-gray-400 text-xs italic">
                        what perspectives have I gained from my showcases?
                      </p>
                    </div>
                  </div>
                </div>

                {showShowcaseSelfNotes &&
                  selfNotes?.length > 0 &&
                  selfNotes?.map((selfNote, selfNoteIndex) => {
                    const noteText = selfNote?.userImprintShowcaseSelfNoteText || ""
                    const noteDate = selfNote?.userImprintShowcaseSelfNoteDate || ""
                    const noteUpdateDate =
                      selfNote?.userImprintShowcaseSelfNoteUpdateDate || ""

                    return (
                      <div
                        key={selfNote._id + selfNoteIndex}
                        className="m-1 p-1 bg-white rounded"
                      >
                        <div className="grid grid-cols-6 justify-between items-center m-1 p-1 bg-gray-100 border rounded">
                          <div className="col-span-1 flex justify-start items-center m-1 p-1">
                            <div
                              onClick={() =>
                                handleToggleShowcaseSelfNoteDetails(selfNote._id)
                              }
                              className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                            >
                              {showShowcaseSelfNoteDetails[selfNote._id] ? (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                              ) : (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                              )}
                            </div>
                            <div>
                              <p className="text-xl font-bold mx-1 px-1 text-gray-600">
                                {selfNoteIndex + 1}.
                              </p>
                            </div>
                            <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                              <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
                            </div>
                            <div>
                              <div className="flex flex-col justify-center items-center mx-1 px-1">
                                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                  {getTimeSince(noteUpdateDate)}
                                </p>
                                <p className="text-[9px] text-gray-600 italic">
                                  last updated
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-3">
                            <div
                              dangerouslySetInnerHTML={createMarkup(noteText)}
                              className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                            ></div>
                          </div>
                          <div className="col-span-2 flex justify-center items-center mx-2">
                            <ClockIcon className="h-5 w-5 text-lime-600" />
                            <p className="text-[9px] mx-1">First Created:</p>
                            <p className="text-gray-400 text-[9px] mx-2">
                              {formatDateTime(noteDate)}
                            </p>
                          </div>
                        </div>
                        {showShowcaseSelfNoteDetails[selfNote._id] && (
                          <div
                            dangerouslySetInnerHTML={createMarkup(noteText)}
                            className="text-xs text-gray-600 m-1 p-1 border rounded leading-4 bg-white"
                          ></div>
                        )}
                      </div>
                    )
                  })}
              </div>
          </div>
        )
      })}

    </div>
  )
}

export default EtImprintShowcase