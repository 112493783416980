import React, { useState, useEffect } from "react"
import useTitle from "../../hooks/useTitle"
import { useNavigate } from "react-router"
import {
  PlusIcon,
  MinusIcon,
  TrashIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline"
import {
  PlusCircleIcon,
  CalendarDaysIcon,
  ArrowUpCircleIcon,
  ClockIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/solid"
import { userProfileIcon2 } from "../../assets/img/imgAssets"
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined"
import PulseLoader from "react-spinners/PulseLoader"
import { formatDate, formatDateTime } from "../../utils/formatDate"
import { getTimeSince } from "../../utils/EtGetTimeSince"
import { differenceInDays } from 'date-fns' // Added 29 Jun 2024
import {
  useGetUserActionsQuery,
  useUpdateUserActionMutation,
} from "./userActionsApiSlice" // 09 Jun 2024 - update mutation
import { useGetUserPicQuery } from "../users/usersApiSlice"


const EtUserActionListByIdComp1 = ({ userInfo, userActionId, userId, trailId, sessionId }) => {
  // First Created - 03 Sep 2024
  // Author - Atul Pandey
  // Inherited from EtUserActionListComp
  
  // 0 - Set Page Title
  useTitle("User Actions List")

  // 1a - check incoming data
  // console.log('----incoming data---')
  // console.log('userInfo', userInfo)
  // console.log('userActionId', userActionId)
  // console.log('userId', userId)
  // console.log('trailId', trailId)
  // console.log('sessionId', sessionId)

  // 1 - set page navigations
  const navigate = useNavigate()

  // 2 - Initialize showDetails state as an object where key is the session index
  const [showDetails, setShowDetails] = useState({})
  const [actionNotes, setActionNotes] = useState({}) // 09 Jun 2024
  const [actionProgress, setActionProgress] = useState({}) // 09 Jun 2024

  // 3 - Extract the user actions data
  const {
    data: userActionsData,
    isError,
    error,
    isLoading,
  } = useGetUserActionsQuery()
  // console.log("userActionsData", userActionsData)

  // 3a - Mutation for updating user action -- 09 Jun 2024
  const [updateUserAction] = useUpdateUserActionMutation()

  // 3b - Load state from localStorage -- 11 Jun 2024
  useEffect(() => {
    const storedShowDetails = localStorage.getItem("showDetails")
    if (storedShowDetails) {
      setShowDetails(JSON.parse(storedShowDetails))
    }
  }, [])

  // 3c Save state to localStorage before page reload -- 11 Jun 2024
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("showDetails", JSON.stringify(showDetails))
    }

    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [showDetails])

  // 4 - Toggle Show function for userAction details -- updated -- 12 Jun 2024 --
  const handleToggleDetails = (actionId) => {
    setShowDetails((prev) => {
      const newShowDetails = { ...prev, [actionId]: !prev[actionId] }

      // Update the value attribute when details are shown
      if (!prev[actionId]) {
        const action = filteredUserActionsData?.find((a) => a.id === actionId)

        const slider = document.getElementById(`slider-${actionId}`)
        if (slider) {
          const initialValue =
            actionProgress[actionId] || action?.userActionProgress || 0
          slider.value = initialValue // Set the value attribute
        }
      }
      return newShowDetails
    })
  }

  // 5 - Create an object for setting inner HTML
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
  }

  // 6 - Filter userActionsData based on props
  const filteredUserActionsData = userActionsData?.ids
    .map((id) => userActionsData?.entities[id])
    .filter(
      (action) =>
        action.userId._id === userId &&
        action.trailId._id === trailId &&
        action.sessionId._id === sessionId &&
        action._id === userActionId
    )

  console.log("filtered user action data", filteredUserActionsData)

  // 7 - Handle Note Change -- 09 Jun 2024
  const handleNoteChange = (e, actionId) => {
    const { value } = e.target
    setActionNotes((prevNotes) => ({ ...prevNotes, [actionId]: value }))
  }

  // 8 - Handle Progress Change -- 09 Jun 2024
  const handleProgressChange = (e, actionId) => {
    const { value } = e.target
    setActionProgress((prevProgress) => ({
      ...prevProgress,
      [actionId]: value,
    }))
    e.target.style.setProperty("--value", value)
  }

  // 8a - UseEffect for slider -- 10 Jun 2024, updated -- 12 Jun 2024 --
  useEffect(() => {
    // Iterate through all actions to set initial styles
    filteredUserActionsData?.forEach((action) => {
      const slider = document.getElementById(`slider-${action.id}`)
      if (slider) {
        const initialValue =
          actionProgress[action.id] || action.userActionProgress || 0
        slider.style.setProperty("--value", initialValue)
        slider.style.setProperty("--min", 0)
        slider.style.setProperty("--max", 100)
      }
    })
  }, [filteredUserActionsData, actionProgress]) // Dependencies updated

  // 9 - Save function to update user action
  const handleSave = async (
    userId,
    trailId,
    sessionId,
    actionTypeId,
    actionId,
    userActionName,
    userActionDesc,
    completeBy
  ) => {
    const actionNoteText = actionNotes[actionId]
    const actionNoteDate = new Date()
    const userActionProgress = actionProgress[actionId]

    // Check if the action note text is not empty
    if (!actionNoteText) {
      alert("Action note cannot be empty")
      return
    }

    try {
      const updatedAction = {
        id: actionId,
        userId: userId,
        trailId: trailId,
        sessionId: sessionId,
        actionTypeId: actionTypeId,
        userActionName: userActionName,
        userActionDesc: userActionDesc,
        completeBy: completeBy,
        userActionNotes: [
          {
            actionNoteText,
            actionNoteDate,
            actionNoteProgress: userActionProgress,
          },
        ],
        userActionProgress,
      }

      await updateUserAction(updatedAction).unwrap()
      alert("Action updated successfully")
      window.location.reload()
    } catch (error) {
      console.error("Failed to update action:", error)
      alert("Failed to update action")
    }
  }

  // 10a - bgColor for action progress status -- 29 Jun 2024
  const getBgColor = (action) => {
    const today = new Date() // Current date
    const completeByDate = new Date(action?.completeBy); // Assuming it's already a Date object
    
    const daysDifference = differenceInDays(completeByDate, today);

    let bgColor = 'bg-lime-400'; // default color

    if (daysDifference < 0) {
      bgColor = 'bg-red-600 text-white';
    } else if (daysDifference < 1) {
      bgColor = 'bg-orange-400 text-white';
    } else if (daysDifference < 3) {
      bgColor = 'bg-yellow-400';
    } // default bgColor 'bg-lime-400' will be used if daysDifference > 3
    return bgColor
  }

  // 10b - Icon color for action progress icon  -- 29 Jun 2024
  const getIconClr = (action) => {
    const today = new Date(); // Current date
    const completeByDate = new Date(action?.completeBy); // Convert the string to a Date object
  
    if (isNaN(completeByDate)) {
      console.error('Invalid date:', action?.completeBy);
      return '#84cc16'; // Return the default color if the date is invalid
    }
  
    const daysDifference = differenceInDays(completeByDate, today);
    //console.log('Difference in days', daysDifference);
  
    let color = '#84cc16'; // default color
  
    if (daysDifference < 0) {
      color = '#dc2626';
    } else if (daysDifference < 1) {
      color = '#f97316';
    } else if (daysDifference < 3) {
      color = '#facc15';
    } // default color '#84cc16' will be used if daysDifference > 3
  
    return color;
  };

  // 10c - get the difference in days -- 29 Jun 2024
  const getDaysDifference = (action) => {
    const today = new Date(); // Current date
    const completeByDate = new Date(action?.completeBy); // Convert the string to a Date object
    const daysDifference = differenceInDays(completeByDate, today);
    return daysDifference
  }
  

  // 11 - Optional: handle loading and error states
  if (isLoading) return <PulseLoader color={"#ea580c"} />
  if (isError) return <div>Error occurred: {error?.data?.message}</div>

  return (
    <div className="rounded font-poppins xl:mx-auto">
      <div className="bg-white rounded m-1 p-1">
        {/* 11 Jun 2024 - Added this section when there are no user actions in the trail*/}
        {filteredUserActionsData?.length === 0 ? (
          <p className="text-center text-gray-500 text-xs">
            There are no user actions added to this session yet
          </p>
        ) : (
          filteredUserActionsData?.map((action, index) => (
            <div
              key={action.id + index}
              className=""
            >
              <div className="grid grid-cols-11 justify-between items-center">
                <div className="col-span-4 gap-2 flex justify-start items-center mx-2">
                  <div
                    onClick={() => handleToggleDetails(action.id)}
                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                  >
                    {showDetails[action.id] ? (
                      <MinusIcon className="h-4 w-4 text-orange-600" />
                    ) : (
                      <PlusIcon className="h-4 w-4 text-orange-600" />
                    )}
                  </div>

                  <div className="m-1 p-1 flex justify-start items-center ">
                    {/* <div className="flex justify-start items-center">
                    <User username={userInfo.username} defaultUserPic={userProfileIcon2} />
                    <div>
                      <p className="text-[11px] text-gray-400 font-bold mx-1 px-1">{userInfo.name.split(" ")[0]}</p>
                      <p className="text-[10px] text-gray-500 mx-1 px-1">{userInfo.username}</p>
                    </div>
              
                    </div> */}
                    {/* <p className="text-lg font-bold text-gray-600 mx-1 px-1">{index+1}.</p> */}
                    <p className="text-[10px] m-1">{action?.userActionName}</p>
                  </div>
                </div>

                <div className="col-span-1">
                    <p className="text-[10px] font-bold text-gray-600">Progress:</p>
                </div>

                <div className="col-span-2">
                  {/* 11 Jun 2024 - added code to display progress data based on threshold for better visibility */}
                  {action?.userActionProgress < 10 ? (
                    <div className="rounded-full bg-gray-300 relative">
                      <div
                        className="rounded-full bg-[#552a9a]"
                        style={{
                          width: `${action?.userActionProgress}%`,
                          height: "18%",
                        }}
                      ></div>
                      <p className="text-[8px] text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        {action?.userActionProgress}%
                      </p>
                    </div>
                  ) : (
                    <div className="rounded-full bg-gray-300">
                      <div className="flex justify-between items-center">
                        <div
                          className="rounded-full bg-[#552a9a] flex justify-center items-center"
                          style={{ width: `${action?.userActionProgress}%` }}
                        >
                          <p className="text-[8px] text-white">
                            {action?.userActionProgress}%
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-span-2 flex justify-center items-center mx-2">
                  {action?.userActionStatus === "Completed" ? (
                    <CheckCircleIcon className="h-4 w-4 text-lime-600" />
                  ) : (
                    <ExploreOutlinedIcon
                      fontSize="small"
                      sx={{
                        color:
                          action?.userActionStatus !== "Planned"
                            ? `${getIconClr(action)}`
                            : (action?.userActionStatus ===
                              "Planned" && getDaysDifference(action)<3 )? `${getIconClr(action)}` :"#d1d5db",
                      }}
                    />
                  )}
                  <p
                    className={`text-[9px] mx-1 ${
                      action?.userActionStatus === "Completed"
                        ? "bg-lime-600 text-white rounded-full px-2"
                        : action?.userActionStatus !== "Planned"
                        ? `${getBgColor(action)} rounded-full px-2`
                        : (action?.userActionStatus === "Planned" && getDaysDifference(action)<3 )? `${getBgColor(action)} rounded-full px-2` :"text-gray-600"
                    }`}
                  >
                    {action?.userActionStatus}
                  </p>
                </div>

                <div className="col-span-2 flex justify-center items-center ">
                  <div className="flex justify-start items-center">
                    <p className="text-gray-600 text-[9px]">Complete by:</p>
                  <CalendarDaysIcon className="m-2 h-5 w-5 text-orange-600" />
                  <p className="flex justify-center items-center text-[9px] rounded-full px-2 py-[1px] bg-white border text-gray-600 border-orange-400">
                    {formatDate(action?.completeBy)}
                  </p>
                  </div>
                  
                </div>

              </div>
              {/* User Action Notes Section */}

              {showDetails[action.id] && (
                <div>
                  {/* Show existing action notes */}
                  <div className="bg-gray-300 m-1 p-1 rounded">
                    {action?.userActionNotes?.length > 0 && (
                    <p className="font-bold m-1 p-1 text-xs">
                      Action Progress Trail:
                    </p>)}
                    {action?.userActionNotes?.length === 0 && (
                    <div className="bg-white rounded m-1 p-1"><p className="m-1 p-1 text-xs text-orange-600">
                      No progress update has been made on this action by the user yet.
                    </p></div>)}
                    {action?.userActionNotes &&
                      action?.userActionNotes
                      ?.slice()
                      .sort(
                        (a, b) =>
                          new Date(b.actionNoteDate) -
                          new Date(a.actionNoteDate)
                      ) // Sort in descending order
                      ?.map((userNote, noteIndex) => (
                        <div
                          key={userNote._id + noteIndex}
                          className="bg-gray-50 rounded m-1 p-1"
                        >
                          <div className="grid grid-cols-10 justify-between items-center m-1 p-1 w-full gap-2">
                            <div className="col-span-5 flex justify-start items-center">
                              <div>
                                <p className="font-bold text-lg mx-1 py-[1px] text-gray-400">
                                  {noteIndex + 1}.
                                </p>
                              </div>
                              <div>
                                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-18">
                                  {getTimeSince(userNote?.actionNoteDate)}
                                </p>
                              </div>
                              <div>
                                <p className="text-[9px] mx-1 py-[1px]">
                                  {userNote?.actionNoteText}
                                </p>
                              </div>
                            </div>

                            <div className="col-span-1">
                              <p className="text-[9px] text-gray-600">
                                Action Progress:
                              </p>
                            </div>

                            {/* 11 Jun 2024 - action progress bar for each note */}

                            <div className="col-span-2">
                              {userNote?.actionNoteProgress < 10 ? (
                                <div className="rounded-full bg-gray-300 relative">
                                  <div
                                    className="rounded-full bg-[#552a9a]"
                                    style={{
                                      width: `${userNote?.actionNoteProgress}%`,
                                      height: "70%",
                                    }}
                                  ></div>
                                  <p className="text-[8px] text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                    {userNote?.actionNoteProgress}%
                                  </p>
                                </div>
                              ) : (
                                <div className="rounded-full bg-gray-300">
                                  <div className="flex justify-between items-center">
                                    <div
                                      className="rounded-full bg-[#552a9a] flex justify-center items-center"
                                      style={{
                                        width: `${userNote?.actionNoteProgress}%`,
                                      }}
                                    >
                                      <p className="text-[8px] text-white">
                                        {userNote?.actionNoteProgress}%
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="col-span-2 flex justify-center items-center">
                              <div className="mx-2"><ClockIcon className="w-6 h-6 text-lime-600" /></div>
                              <div className="w-full">
                                <p className="text-[9px] text-gray-400 rounded-full">
                                  {formatDateTime(userNote?.actionNoteDate)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    {/* Area for new note */}
                    {/* 11 June - added logic for hiding action note addition for completed actions  - 03 Sep 2024 - Muted */}
                    {/* {action?.userActionProgress < 100 && (
                      <div className="bg-white m-1 py-1 px-2 rounded">
                        <div className="m-1 p-1 bg-gray-100 rounded">
                          <div className="grid grid-cols-10 justify-between items-start">
                            <div className="col-span-6 m-1 p-1">
                              <div className="flex justify-start items-center">
                                <PlusCircleIcon className="m-2 h-5 w-5 text-orange-600" />
                                <p className="font-bold text-xs">
                                  Add Action Note
                                </p>
                              </div>
                              <textarea
                                className="w-full rounded p-2 m-1 text-[10px] border border-gray-200"
                                placeholder="Add your action notes here"
                                value={actionNotes[action.id] || ""}
                                onChange={(e) => handleNoteChange(e, action.id)}
                              />
                            </div>

                            <div className="col-span-4 m-1 p-1">
                              <div className="flex justify-start items-center">
                                <ArrowUpCircleIcon className="m-2 h-5 w-5 text-orange-600" />
                                <p className="font-bold text-xs">
                                  Update Action Progress
                                </p>
                              </div>
                              <div className="flex justify-start items-center">
                                <input
                                  type="range"
                                  id={`slider-${action.id}`}
                                  min="0"
                                  max="100"
                                  className="w-full rounded m-1 h-1"
                                  value={
                                    actionProgress[action.id] ||
                                    action?.userActionProgress
                                  }
                                  onChange={(e) =>
                                    handleProgressChange(e, action.id)
                                  }
                                />
                                <input
                                  type="number"
                                  className="w-12 rounded p-2 m-1 text-center text-[10px] border border-gray-200"
                                  placeholder="Action Progress (%)"
                                  value={
                                    actionProgress[action.id] ||
                                    action?.userActionProgress
                                  }
                                  onChange={(e) =>
                                    handleProgressChange(e, action.id)
                                  }
                                />
                                <p className="text-xs">%</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <button
                          className="bg-orange-600 text-white rounded-full px-4 py-[1px] m-1 text-[10px]"
                          onClick={() =>
                            handleSave(
                              userId,
                              trailId,
                              sessionId,
                              action.actionTypeId,
                              action.id,
                              action.userActionName,
                              action.userActionDesc,
                              action.completeBy
                            )
                          }
                        >
                          Save
                        </button>
                      </div>
                    )} */}
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  )
}

const User = ({ username, defaultUserPic }) => {
  const { data: userPic, isFetching: isFetchingUserPic } =
    useGetUserPicQuery(username)

  return (
    <div className="flex justify-center items-center h-12 w-12 rounded-full border border-white bg-gradient-to-r from-orange-600 to-orange-400 shadow-lg shadow-black cursor-pointer">
      {/* Display user picture or loading indicator*/}
      {isFetchingUserPic ? (
        <div>Loading...</div>
      ) : userPic ? (
        <img src={userPic} alt="User" className="h-11 w-11  rounded-full" />
      ) : (
        <img src={defaultUserPic} alt="User" className="h-10 w-10 p-1" />
      )}
    </div>
  )
}

export default EtUserActionListByIdComp1
