import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import useTitle from "@/hooks/useTitle"
import {
  PlusIcon,
  MinusIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline"
import { ClockIcon, BuildingOfficeIcon } from "@heroicons/react/24/solid"
import { userProfileIcon2 } from '@/assets/img/imgAssets'
import EtContentEditorLight2 from "@/utils/EtContentEditorLight2" // 10 Nov 2024

import { useGetUserPicQuery } from "@/features/users/usersApiSlice" // 21 Oct 2024
import { useGetUsersQuery } from '@/features/users/usersApiSlice'
import { useGetUserImprintOverviewsQuery } from './userImprint/userImprintOverviewsApiSlice'  // 27 Oct 2024
import { useGetUserImprintEtsQuery } from './userImprint/userImprintEtsApiSlice' // 04 Nov 2024
import { useGetUserImprintPurposesQuery } from './userImprint/userImprintPurposesApiSlice'  // 09 Nov 2024
import { useCreateNewUserImprintPurposeMutation } from './userImprint/userImprintPurposesApiSlice'  // 09 Nov 2024

import EtCreateUserImprintOverview from '@/pages/home/web/userImprint/EtCreateUserImprintOverview'
import EtUpdateUserImprintOverview from '@/pages/home/web/userImprint/EtUpdateUserImprintOverview' // 29 Oct 2024

import EtImprintCrystallBallIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintCrystallBallIcon1SVGComp'
import EtImprintPurposeIcon1SVGComp from '@/assets/img/icons/svgComp/EtIlluminatePurposeIcon1SVGComp'
import EtImprintCapabilitiesIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintCapabilitiesIcon1SVGComp'
import EtImprintRolePCIIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintRolePCIIcon1SVGComp'
import EtImprintCreationsIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintCreationsIcon1SVGComp'
import EtImprintTestimonialsIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintTestimonialsIcon1SVGComp'
import EtImprintLegacyIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintLegacyIcon1SVGComp' // 09 Dec 2024
import EtImprintHiddenMagicIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintHiddenMagicIcon1SVGComp'

//29 Oct 2024
import EtImprintOverviewEducationIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintOverviewEducationIcon1SVGComp'
import EtImprintOverviewExperienceIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintOverviewExperienceIcon1SVGComp'
import EtImprintOverviewSpanIcon2SVGComp from '@/assets/img/icons/svgComp/EtImprintOverviewSpanIcon2SVGComp'

// 02 Nov 2024
import EtImprintSelfIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintSelfIcon1SVGComp'
import EtImprint360Icon1SVGComp from '@/assets/img/icons/svgComp/EtImprint360Icon1SVGComp'
import EtImprintEtIcon2SVGComp from '@/assets/img/icons/svgComp/EtImprintEtIcon2SVGComp' 
import EtImprintCustomersIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintCustomersIcon1SVGComp'
import EtImprintPartnersIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintPartnersIcon1SVGComp'
import EtImprintTeamIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintTeamIcon1SVGComp'
import EtImprintLeadersIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintLeadersIcon1SVGComp'

// 06 Nov 2024
import EtInspirationBeaconIcon2SVGComp from '@/assets/img/icons/svgComp/EtInspirationBeaconIcon2SVGComp'
import EtGratitudeTypeIcon1SVGComp from '@/assets/img/icons/svgComp/EtGratitudeTypeIcon1SVGComp'

// 08 Nov 2024
import EtImprintFutureIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintFutureIcon1SVGComp'
import EtImprintVisionIcon2SVGComp from '@/assets/img/icons/svgComp/EtImprintVisionIcon2SVGComp'
import EtImprintMissionsIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintMissionsIcon1SVGComp'
import EtImprintPlansIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintPlansIcon1SVGComp'

// 09 Nov 2024
import EtImprintVisionSelfIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintVisionSelfIcon1SVGComp'
import EtImprintVisionClientsIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintVisionClientsIcon1SVGComp'
import EtImprintMissionsSelfIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintMissionsSelfIcon1SVGComp'
import EtImprintMissionsClientsIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintMissionsClientsIcon1SVGComp'
import EtImprintPlansSelfIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintPlansSelfIcon1SVGComp'
import EtImprintPlansClientsIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintPlansClientsIcon1SVGComp'

// 06 Jan 2025
import EtImprintVisionPartnerIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintVisionPartnerIcon1SVGComp'
import EtImprintVisionOrgIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintVisionOrgIcon1SVGComp'
import EtImprintVisionTeamIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintVisionTeamIcon1SVGComp'
import EtImprintMissionPartnerIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintMissionPartnerIcon1SVGComp'
import EtImprintMissionOrgIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintMissionOrgIcon1SVGComp'
import EtImprintMissionTeamIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintMissionTeamIcon1SVGComp'


// 11 Nov 2024
import EtImprintPurposeNameIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintPurposeNameIcon1SVGComp'

// 18 Nov 2024
import EtCapabilitiesAdvisoryIcon1SVGComp from '@/assets/img/icons/svgComp/EtCapabilitiesAdvisoryIcon1SVGComp'
import EtCapabilitiesDomainIcon1SVGComp from '@/assets/img/icons/svgComp/EtCapabilitiesDomainIcon1SVGComp'
import EtCapabilitiesFunctionIcon1SVGComp from '@/assets/img/icons/svgComp/EtCapabilitiesFunctionIcon1SVGComp'
import EtCapabilitiesIndustryIcon1SVGComp from '@/assets/img/icons/svgComp/EtCapabilitiesIndustryIcon1SVGComp'
import EtCapabilitiesSalesIcon1SVGComp from '@/assets/img/icons/svgComp/EtCapabilitiesSalesIcon1SVGComp'
import EtCapabilitiesServiceIcon1SVGComp from '@/assets/img/icons/svgComp/EtCapabilitiesServiceIcon1SVGComp'
import EtCapabilitiesSoftSkillIcon1SVGComp from '@/assets/img/icons/svgComp/EtCapabilitiesSoftSkillIcon1SVGComp'
import EtCapabilitiesTechAreaIcon1SVGComp from '@/assets/img/icons/svgComp/EtCapabilitiesTechAreaIcon1SVGComp'
import EtCapabilitiesTechStackIcon1SVGComp from '@/assets/img/icons/svgComp/EtCapabilitiesTechStackIcon1SVGComp'

// 01 Dec 2024
import EtImprintShowCaseIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintShowCaseIcon1SVGComp'
import EtImprintGratitudeIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintGratitudeIcon1SVGComp'

// 04 Jan 2025
import EtFutureStrategyIcon1SVGComp from '@/assets/img/icons/svgComp/EtFutureStrategyIcon1SVGComp'
import EtFutureStrategyClientIcon1SVGComp from '@/assets/img/icons/svgComp/EtFutureStrategyClientIcon1SVGComp'
import EtFutureStrategySelfIcon1SVGComp from '@/assets/img/icons/svgComp/EtFutureStrategySelfIcon1SVGComp'

// 22 Nov 2024
import EtImprintCapabilitiesIndustry from './userImprint/ImprintCapabilities/EtImprintCapabilitiesIndustry'
import EtImprintCapabilitiesDomain from './userImprint/ImprintCapabilities/EtImprintCapabilitiesDomain'
import EtImprintCapabilitiesFunction from './userImprint/ImprintCapabilities/EtImprintCapabilitiesFunction'
import EtImprintCapabilitiesTechArea from './userImprint/ImprintCapabilities/EtImprintCapabilitiesTechArea'
import EtImprintCapabilitiesTechStack from './userImprint/ImprintCapabilities/EtImprintCapabilitiesTechStack'
import EtImprintCapabilitiesAdvisory from './userImprint/ImprintCapabilities/EtImprintCapabilitiesAdvisory'
import EtImprintCapabilitiesSales from './userImprint/ImprintCapabilities/EtImprintCapabilitiesSales'
import EtImprintCapabilitiesService from './userImprint/ImprintCapabilities/EtImprintCapabilitiesService'
import EtImprintCapabilitiesSoftSkill from './userImprint/ImprintCapabilities/EtImprintCapabilitiesSoftSkill'

// 23 Nov 2024
import EtImprintRole from '@/pages/home/web/userImprint/ImprintRoles/EtImprintRole'

// 02 Dec 2024
import EtImprintShowcase from '@/pages/home/web/userImprint/ImprintShowcases/EtImprintShowcase'

// 09 Dec 2024
import EtImprintCreation from '@/pages/home/web/userImprint/ImprintCreations/EtImprintCreation'

// 18 Dec 2024
import EtImprintGratitude from '@/pages/home/web/userImprint/ImprintGratitudes/EtImprintGratitude'

// 22 Dec 2024
import EtImprintHiddenMagic from '@/pages/home/web/userImprint/ImprintHiddenMagics/EtImprintHiddenMagic'

// 28 Dec 2024
import EtImprintLegacy from '@/pages/home/web/userImprint/ImprintLegacys/EtImprintLegacy'

// 06 Jan 2025
import EtImprintVision from '@/pages/home/web/userImprint/ImprintVisions/EtImprintVision'

// 09 Jan 2025
import EtImprintMission from '@/pages/home/web/userImprint/ImprintMissions/EtImprintMission'

// 14 Jan 2025
import EtImprintPlan from '@/pages/home/web/userImprint/ImprintPlans/EtImprintPlan'

// 28 Jan 2025
import EtTrinityServiceBar from '@/components/ai/EtTrinityServiceBar'

// 29 Jan 2025
import EtTrinityUserImprintOverview from '@/pages/home/web/userImprint/EtTrinityUserImprintOverview'

// 31 Jan 2025
import EtTrinityGuidedServiceBarImprint from '@/components/ai/EtTrinityGuidedServiceBarImprint'

import { formatDateToMonthYear, formatDateToYear, formatDateTime, formatShortDate } from '@/utils/formatDate'
import { getTimeSince } from "@/utils/EtGetTimeSince"

const EtUserImprint = ({userId}) => {
  // First Created - 19 Oct 2024
  // Author - Atul Pandey
  // Updated - 22 Oct 2024 - added user imprint sections
  // Updated - 27 Oct 2024 - State variable to toggle the create component
  // Updated - 02 Nov 2024 - added tabs for self and 360 degree view
  // Updated - 07 Nov 2024 - refined the userId and guest userId connections
  // Updated - 09 Nov 2024 - added user imprint purpose section / component (ImprintPurpose)
  // Updated - 11 Nov 2024 - refined the logic to push all the main display and processing of Purpose to ImprintPurpose
  // Updated - 11 Nov 2024 - isEditingPurpose flag
  // Updated - 18 NOv 2024 - started adding the capabilities section details
  // Updated - 21 Nov 2024 - completed capabilities industry and domain sections
  // Updated - 22 Nov 2024 - componentized imprint capabilities industry session
  // Updated - 22 Nov 2024 - completed all capability sections 
  // Updated - 23 Nov 2024 - started the roles section details
  // Updated - 01 Dec 2024 - completed the roles section, started the showcase section
  // Updated - 09 Dec 2024 - started the creations section, added placeholder for Legacy
  // Updated - 18 Dec 2024 - started the gratitudes section
  // Updated - 22 Dec 2024 - started the hidden magic section
  // Updated - 28 Dec 2024 - started the legacy section
  // Updated - 04 Jan 2025 - inserted the strategies section in imagine section
  // Updated - 06 Jan 2025 - started the build out of the vision section
  // Updated - 09 Jan 2025 - started the build out of the missions section
  // Updated - 14 Jan 2025 - started the build out of the plans section
  // Updated - 16 Jan 2025 - completed the Imprint !
  // Updated - 28 Jan 2025 - added the Trinty Service Bar!
  // Updated - 31 Jan 2025 - added the Trinty Service Bar Imprint!
  // Updated - 13 Mar 2025 - Fixed the navigation of imprint components from @/features/userMagic to @/pages/home/web

  // 1a - Set Page Title
  useTitle('User Imprint')

  // 1b1 - State variables to show / hide sections
  const [ showOverview, setShowOverview ] = useState(false)
  const [ showPurpose, setShowPurpose ] = useState(false)
  const [ showCapabilities, setShowCapabilities ] = useState(false)
  const [ showRolesPCI, setShowRolesPCI ] = useState(false)
  const [ showDomain, setShowDomain ] = useState(false)
  const [ showIndustry, setShowIndustry ] = useState(false)
  const [ showShowcase, setShowShowcase ] = useState(false) // 01 Dec 2024
  const [ showCreations, setShowCreations ] = useState(false)
  const [ showTestimonials, setShowTestimonials ] = useState(false)
  const [ showLegacy, setShowLegacy ] = useState(false) // 09 Dec 2024
  const [ showGratitudes, setShowGratitudes ] = useState(false) // 01 Dec 2024
  const [ showHiddenMagic, setShowHiddenMagic ] = useState(false)
  const [ showCreateUserImprintOverview, setShowCreateUserImprintOverview ] = useState(false) // 27 Oct 2024
  const [ showUpdateUserImprintOverview, setShowUpdateUserImprintOverview ] = useState(false) // 29 Oct 2024
  const [ activeTab, setActiveTab ] = useState("self") // 02 Nov 2024
  const [ showImprint360Et, setShowImprint360Et] = useState(false) // 04 Nov 2024
  const [ showImprint360Clients, setShowImprint360Clients] = useState(false) // 08 Nov 2024
  const [ showImprint360Partners, setShowImprint360Partners] = useState(false) // 08 Nov 2024
  const [ showImprint360Team, setShowImprint360Team] = useState(false) // 08 Nov 2024
  const [ showImprint360Leadership, setShowImprint360Leadership] = useState(false) // 08 Nov 2024
  const [ showImprintFutureVision, setShowImprintFutureVision] = useState(false) // 08 Nov 2024
  const [ showImprintFutureMissions, setShowImprintFutureMissions] = useState(false) // 08 Nov 2024
  const [ showImprintFuturePlans, setShowImprintFuturePlans] = useState(false) // 08 Nov 2024
  const [ isEditingPurpose, setIsEditingPurpose ] = useState(false) // 11 Nov 2024
  const [ isAddingPurpose, setIsAddingPurpose ] = useState(false) // 11 Nov 2024

  const [ showImprintFutureStrategies, setShowImprintFutureStrategies] = useState(false) // 05 Jan 2025
 
  
  // 1c - Define handleCreateUserImprintOverview function - 27 Oct 2024
  const handleCreateUserImprintOverview = () => {
    setShowOverview(true)
    setShowCreateUserImprintOverview(true)
  }

  // 1d - Define handleCreateUserImprintOverview function - 27 Oct 2024
  const handleUpdateUserImprintOverview = () => {
    setShowOverview(true)
    setShowUpdateUserImprintOverview(true)
  }

  // 1e - Handle Show Purpose Click function - 11 Nov 2024
  const togglePurposeSection = () => setShowPurpose((prev) => !prev);

  // 1f - Add handlers in EtUserImprint
  const handleAddPurposeClick = () => {
    setShowPurpose(true);
    setIsAddingPurpose(true); // Set add mode
  };
  
  // 1g - Edit handlers in EtUserImprint
  const handleEditPurposeClick = () => {
    setShowPurpose(true);
    setIsEditingPurpose(true);
  };
  
  // 2a - Get Data
  const {data: usersData } = useGetUsersQuery()
  const userInfo = usersData?.ids.map(id => usersData?.entities[id])?.filter(user => user._id === userId)
  
// console.log('userInfo', userInfo)


  // 2b - Get UserImprintOverview Data
  const { data: userImprintOverviewData } = useGetUserImprintOverviewsQuery()
  const userImprintOverviews = userImprintOverviewData?.ids?.map(id => userImprintOverviewData?.entities[id])?.filter(item => item.userId?._id === userId)
  
  // 2c - Get UserImprintEt Data
  const { data: userImprintEtData } = useGetUserImprintEtsQuery()
  const userImprintEts = userImprintEtData?.ids?.map(id => userImprintEtData?.entities[id])?.filter(item => item.guestUserId?._id === userId) // 07 Nov - select the guest user id for the given userId
  
//  console.log('userImprintEt', userImprintEts)

  // 2d - Extract commenter info from userImprintEts - 07 Nov 2024
  const commenterId = userImprintEts?.[0]?.userId?._id || null
//   console.log('commenter', commenterId)
  const guestUserInfo = commenterId ? usersData?.ids?.map(id => usersData?.entities[id])?.filter(user => user?._id === commenterId) : []
//   const overview = userImprintOverviews[0] || {}
//   console.log('overiew', overview)
  
// 2e - Get UserImprintPurpose Data - 09 Nov 2024
const { data: userImprintPurposeData } = useGetUserImprintPurposesQuery()
const userImprintPurposes = userImprintPurposeData?.ids?.map(id => userImprintPurposeData?.entities[id])?.filter(item => item.userId?._id === userId)
// console.log('userImprintPurposes', userImprintPurposes)



// 3a - create Markup
const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
}

// // 3️b Function to trigger the right component (for component-driven tasks)
// const handleComponentTrigger = (task) => {
//     console.log("Triggering component for task:", task);
//     // Logic to activate the correct component (e.g., ImprintPurpose)
//     // This can be used to control visibility of peer components
// };

  return (
    <div className=''>
    
     {/* Tabbed sections -for User Self View and 360 Imprint view */}
     <div className='bg-gradient-to-r from-black to-black rounded my-2 py-2'>
        <div className="flex justify-start items-center m-1 p-1 font-poppins rounded">
          <div
            onClick={() => setActiveTab("self")}
            className={`flex items-center text-gray-700 font-bold mx-2 cursor-pointer ${
              activeTab === "self" ? "text-orange-600" : ""
            }`}
          >
            <div><EtImprintSelfIcon1SVGComp className='h-8 w-8' /></div>
            <div>
                <p className="mx-1 px-1">Self View</p>
                <p className="mx-1 px-1 font-normal text-[10px] italic">how do I see my imprint?</p>
            </div>
          </div>
          
          <div
            onClick={() => setActiveTab("360")}
            className={`flex items-center text-gray-700 font-bold mx-2 cursor-pointer ${
              activeTab === "360" ? "text-orange-600" : ""
            }`}
          >
            <div><EtImprint360Icon1SVGComp className='h-8 w-8' /></div>
            <div>
                <p className="mx-1 px-1">360 View</p>
                <p className="mx-1 px-1 font-normal text-[10px] italic">how do others see my imprint?</p>
            </div>
          </div>
          
          <div
            onClick={() => setActiveTab("imagine")}
            className={`flex items-center text-gray-700 font-bold mx-2 cursor-pointer ${
              activeTab === "imagine" ? "text-orange-600" : ""
            }`}
          >
            <div><EtImprintFutureIcon1SVGComp className='h-10 w-10' /></div>
            <div>
                <p className="mx-1 px-1">Imagine</p>
                <p className="mx-1 px-1 font-normal text-[10px] italic">what will be my future imprint?</p>
            </div>
          </div>

        </div>
     </div>

     {/* <EtTrinityServiceBar /> */}
     {/* <EtTrinityUserImprintOverview userId={userId} userInfo={userInfo} />  */}
     <EtTrinityGuidedServiceBarImprint 
     context={userInfo}     
     />

        {/* Tabbed Section - Self View - 02 Nov 2024 */}

        <div className='border-t my-2 py-2'>
            {activeTab === 'self' && (
            <div>

                {/* Section 1 - Overview - at a glance */}
                <div className=' m-1 p-1 bg-gray-200 rounded '>
                        <div className='grid grid-cols-4 justify-start items-center'>
                            <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                <div
                                    onClick={() => setShowOverview(prev => !prev)}
                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                    >
                                    {showOverview ? (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                </div>
                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowOverview(prev => !prev)} ><EtImprintCrystallBallIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                <div>
                                    <p className='mx-1 px-1 font-bold text-gray-600'>Crystal Ball</p>
                                    <p className='mx-1 px-1 text-gray-400 text-xs italic'>who am I?</p>
                                </div>     
                            </div>
                            <div className="col-span-1 ">
                                {userImprintOverviews && userImprintOverviews.length===0 && (
                                    <div className='flex justify-end items-center m-1 p-1'>
                                        <p className="font-bold text-xs m-1 p-1">Add</p>
                                        <button type="button" onClick={handleCreateUserImprintOverview}>
                                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                                        </button>
                                    </div>
                                )}
                                {userImprintOverviews && userImprintOverviews.length>0 && (
                                    <div className='flex justify-end items-center m-1 p-1 cursor-pointer' onClick={handleUpdateUserImprintOverview}>
                                        <p className="font-bold text-xs m-1 p-1">Edit</p>
                                        <button type="button" onClick={handleUpdateUserImprintOverview}>
                                            <PencilSquareIcon className="h-5 w-5 text-orange-600 m-1 p-1" />
                                        </button>
                                    </div>
                                )}

                            </div>
                            
                        </div>
                        
                        {showCreateUserImprintOverview && (
                                <EtCreateUserImprintOverview userId={userId} userOrgId={userInfo[0]?.userOrgId?._id} showOverview={showOverview} setShowOverview={setShowOverview} showCreateUserImprintOverview={showCreateUserImprintOverview}
                                setShowCreateUserImprintOverview={setShowCreateUserImprintOverview}
                                />
                        )}

                        {showUpdateUserImprintOverview && (
                                <EtUpdateUserImprintOverview userId={userId} userOrgId={userInfo[0]?.userOrgId._id} showOverview={showOverview} setShowOverview={setShowOverview} showUpdateUserImprintOverview={showUpdateUserImprintOverview}
                                setShowUpdateUserImprintOverview={setShowUpdateUserImprintOverview}
                                />
                        )}

                        {userImprintOverviews && userImprintOverviews.length>0 && showOverview && (
                            <div>
                            <ImprintOverview userInfo={userInfo} overview={userImprintOverviews} createMarkup={createMarkup}/>
                            </div>
                        )}

                        {userImprintOverviews && userImprintOverviews.length === 0 && showOverview && (
                            <div className='bg-white rounded m-1 p-1'>
                                <p className='text-orange-600 m-1 p-1 text-xs'>
                                    Your magical imprint is a touch away! Click on the add button above to create one.
                                </p>
                            </div>
                        )}
                </div>

                {/* Section 2 - Purpose */}  
                <div className='m-1 p-1 bg-gray-200 rounded'>
                    <div className='grid grid-cols-4 justify-start items-center'>
                        <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                        <div
                            onClick={togglePurposeSection}
                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                        >
                            {showPurpose ? (
                            <MinusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                            <PlusIcon className="h-4 w-4 text-orange-600" />
                            )}
                        </div>
                        <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={togglePurposeSection}>
                            <EtImprintPurposeIcon1SVGComp className='h-6 w-6 text-orange-600' />
                        </div>
                        <div>
                            <p className='mx-1 px-1 font-bold text-gray-600'>Purpose</p>
                            <p className='mx-1 px-1 text-gray-400 text-xs italic'>what drives me?</p>
                        </div>
                        </div>
                        <div className="col-span-1">
                        {userImprintPurposes && userImprintPurposes?.length === 0 ? (
                            <div className='flex justify-end items-center m-1 p-1 cursor-pointer' onClick={handleAddPurposeClick}>
                            <p className="font-bold text-xs m-1 p-1">Add</p>
                            <button type="button">
                                <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                            </button>
                            </div>
                        ) : (
                            <div className='flex justify-end items-center m-1 p-1 cursor-pointer' onClick={handleEditPurposeClick}>
                            <p className="font-bold text-xs m-1 p-1">Edit</p>
                            <button type="button">
                                <PencilSquareIcon className="h-5 w-5 text-orange-600 m-1 p-1" />
                            </button>
                            </div>
                        )}
                        </div>
                    </div>
    
                    {showPurpose && (
                        <div className='bg-white rounded m-1 p-1'>
                            <ImprintPurpose 
                                userId={userId} 
                                userInfo={userInfo} 
                                purpose={userImprintPurposes} 
                                createMarkup={createMarkup} 
                                isEditingPurpose={isEditingPurpose}
                                setIsEditingPurpose={setIsEditingPurpose}
                                isAddingPurpose={isAddingPurpose}
                                setIsAddingPurpose={setIsAddingPurpose}
                            />
                        </div>
                    )}
                </div>

                {/* Section 3 - Capabilities */}
                <div className=' m-1 p-1 bg-gray-200 rounded '>
                        <div className='grid grid-cols-8 justify-start items-center'>
                            <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                <div
                                    onClick={() => setShowCapabilities(prev => !prev)}
                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                    >
                                    {showCapabilities ? (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                </div>
                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowCapabilities(prev => !prev)} ><EtImprintCapabilitiesIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                <div>
                                    <p className='mx-1 px-1 font-bold text-gray-600'>Capabilities</p>
                                    <p className='mx-1 px-1 text-gray-400 text-xs italic'>what am I good at?</p>
                                </div>     
                            </div>
                        </div>
                        
                        {showCapabilities && (
                            <div className='bg-white rounded m-1 p-1'>
                                <ImprintCapabilities userId={userId} userInfo={userInfo}/>
                            </div>
                        )}
                </div>
                
                {/* Section 4 - Roles, Contributions & Impact*/}
                <div className=' m-1 p-1 bg-gray-200 rounded '>
                        <div className='grid grid-cols-8 justify-start items-center'>
                            <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                <div
                                    onClick={() => setShowRolesPCI(prev => !prev)}
                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                    >
                                    {showRolesPCI ? (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                </div>
                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowRolesPCI(prev => !prev)} ><EtImprintRolePCIIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                <div>
                                    <p className='mx-1 px-1 font-bold text-gray-600'>Roles</p>
                                    <p className='mx-1 px-1 text-gray-400 text-xs italic'>what have I been responsible for?</p>
                                </div>     
                            </div>
                        </div>
                        
                        {showRolesPCI && (
                            <div className='bg-white rounded m-1 p-1'>
                                <EtImprintRole userId={userId} userInfo={userInfo}/>
                            </div>
                        )}
                </div>
                
                {/* Section 5 - Domain Expertise & Credentials */}
                {/* <div className=' m-1 p-1 bg-gray-200 rounded '>
                        <div className='grid grid-cols-8 justify-start items-center'>
                            <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                <div
                                    onClick={() => setShowDomain(prev => !prev)}
                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                    >
                                    {showDomain ? (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                </div>
                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowDomain(prev => !prev)} ><EtImprintDomainIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                <div>
                                    <p className='mx-1 px-1 font-bold text-gray-600'>Domain</p>
                                    <p className='mx-1 px-1 text-gray-400 text-xs italic'>what are my expertise areas?</p>
                                </div>     
                            </div>
                        </div>
                        
                        {showDomain && (
                            <div>
                                Domain
                            </div>
                        )}
                </div> */}
                
                {/* Section 6 - Industry Expertise & Credentials */}
                {/* <div className=' m-1 p-1 bg-gray-200 rounded '>
                        <div className='grid grid-cols-8 justify-start items-center'>
                            <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                <div
                                    onClick={() => setShowIndustry(prev => !prev)}
                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                    >
                                    {showIndustry ? (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                </div>
                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowIndustry(prev => !prev)} ><EtImprintIndustryIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                <div>
                                    <p className='mx-1 px-1 font-bold text-gray-600'>Industry</p>
                                    <p className='mx-1 px-1 text-gray-400 text-xs italic'>where have I made an impact?</p>
                                </div>     
                            </div>
                        </div>
                        
                        {showIndustry && (
                            <div>
                                Industry
                            </div>
                        )}
                </div> */}
                
                {/* Section 6 - Showcase Imprint */}
                <div className=' m-1 p-1 bg-gray-200 rounded '>
                        <div className='grid grid-cols-8 justify-start items-center'>
                            <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                <div
                                    onClick={() => setShowShowcase(prev => !prev)}
                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                    >
                                    {showShowcase ? (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                </div>
                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowShowcase(prev => !prev)} ><EtImprintShowCaseIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                <div>
                                    <p className='mx-1 px-1 font-bold text-gray-600'>Showcases</p>
                                    <p className='mx-1 px-1 text-gray-400 text-xs italic'>what are my marquee accomplishments?</p>
                                </div>     
                            </div>
                        </div>
                        
                        {showShowcase && (
                            <div className='bg-white rounded m-1 p-1'>
                                <EtImprintShowcase userId={userId} userInfo={userInfo}/>
                            </div>
                        )}
                </div>

                {/* Section 7 - Creations Imprint */}
                <div className=' m-1 p-1 bg-gray-200 rounded '>
                        <div className='grid grid-cols-8 justify-start items-center'>
                            <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                <div
                                    onClick={() => setShowCreations(prev => !prev)}
                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                    >
                                    {showCreations ? (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                </div>
                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowCreations(prev => !prev)} ><EtImprintCreationsIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                <div>
                                    <p className='mx-1 px-1 font-bold text-gray-600'>Creations</p>
                                    <p className='mx-1 px-1 text-gray-400 text-xs italic'>what can speak about me?</p>
                                </div>     
                            </div>
                        </div>
                        
                        {showCreations && (
                            <div className='bg-white rounded m-1 p-1'>
                                <EtImprintCreation userId={userId} userInfo={userInfo}/>
                            </div>
                        )}
                </div>
                
                {/* Section 8 - Gratitude */}
                <div className=' m-1 p-1 bg-gray-200 rounded '>
                        <div className='grid grid-cols-8 justify-start items-center'>
                            <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                <div
                                    onClick={() => setShowGratitudes(prev => !prev)}
                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                    >
                                    {showGratitudes ? (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                </div>
                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowGratitudes(prev => !prev)} ><EtImprintGratitudeIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                <div>
                                    <p className='mx-1 px-1 font-bold text-gray-600'>Gratitudes</p>
                                    <p className='mx-1 px-1 text-gray-400 text-xs italic'>who have helped shape me up?</p>
                                </div>     
                            </div>
                        </div>
                        
                        {showGratitudes && (
                            <div className='bg-white rounded m-1 p-1'>
                                <EtImprintGratitude userId={userId} userInfo={userInfo}/>
                        </div>
                        )}
                </div>
                {/* Section 8 - Testimonials */}
                {/* <div className=' m-1 p-1 bg-gray-200 rounded '>
                        <div className='grid grid-cols-8 justify-start items-center'>
                            <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                <div
                                    onClick={() => setShowTestimonials(prev => !prev)}
                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                    >
                                    {showTestimonials ? (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                </div>
                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowTestimonials(prev => !prev)} ><EtImprintTestimonialsIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                <div>
                                    <p className='mx-1 px-1 font-bold text-gray-600'>Testimonials</p>
                                    <p className='mx-1 px-1 text-gray-400 text-xs italic'>what people say about me?</p>
                                </div>     
                            </div>
                        </div>
                        
                        {showTestimonials && (
                            <div>
                                Testimonials
                            </div>
                        )}
                </div> */}
                
                {/* Section 9 - Hidden Magic */}
                <div className=' m-1 p-1 bg-gray-200 rounded '>
                        <div className='grid grid-cols-8 justify-start items-center'>
                            <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                <div
                                    onClick={() => setShowHiddenMagic(prev => !prev)}
                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                    >
                                    {showHiddenMagic ? (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                </div>
                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowHiddenMagic(prev => !prev)} ><EtImprintHiddenMagicIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                <div>
                                    <p className='mx-1 px-1 font-bold text-gray-600'>Hidden Magic</p>
                                    <p className='mx-1 px-1 text-gray-400 text-xs italic'>what are my untapped superpowers?</p>
                                </div>     
                            </div>
                        </div>
                        
                        {showHiddenMagic && (
                            <div className='bg-white rounded m-1 p-1'>
                                <EtImprintHiddenMagic userId={userId} userInfo={userInfo}/>
                            </div>
                        )}
                </div>

                {/* Section 10 - Legacy */}
                <div className=' m-1 p-1 bg-gray-200 rounded '>
                        <div className='grid grid-cols-8 justify-start items-center'>
                            <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                <div
                                    onClick={() => setShowLegacy(prev => !prev)}
                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                    >
                                    {showLegacy ? (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                </div>
                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowLegacy(prev => !prev)} ><EtImprintLegacyIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                <div>
                                    <p className='mx-1 px-1 font-bold text-gray-600'>My Legacy</p>
                                    <p className='mx-1 px-1 text-gray-400 text-xs italic'>what will I leave behind?</p>
                                </div>     
                            </div>
                        </div>
                        
                        {showLegacy && (
                            <div className='bg-white rounded m-1 p-1'>
                               <EtImprintLegacy userId={userId} userInfo={userInfo}/>
                            </div>
                        )}
                </div>

            </div>
            )}

            {activeTab === '360' && (
                <div>
                    {/* Section 1 - Eternity's View of User's Imprint */}
                    <div className=' m-1 p-1 bg-gray-200 rounded '>
                        <div className='grid grid-cols-4 justify-start items-center'>
                            <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                <div
                                    onClick={() => setShowImprint360Et(prev => !prev)}
                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                    >
                                    {showImprint360Et ? (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                </div>
                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowOverview(prev => !prev)} ><EtImprintEtIcon2SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                <div>
                                    <p className='mx-1 px-1 font-bold text-gray-600'>Eternity</p>
                                    <p className='mx-1 px-1 text-gray-400 text-xs italic'>how does Et registers my imprint ?</p>
                                </div>     
                            </div>
                        </div>
                        
                        {userImprintEts && userImprintEts.length>0 && showImprint360Et && (
                            <div>
                            <Imprint360Et commenterInfo={guestUserInfo} userInfo={userInfo} overview={userImprintEts} createMarkup={createMarkup}/>
                            </div>
                        )}

                        {userImprintOverviews && userImprintOverviews.length === 0 && showOverview && (
                            <div className='bg-white rounded m-1 p-1'>
                            <p className='text-orange-600 m-1 p-1 text-xs'>
                                    Your magical imprint is a touch away! Click on the add button above to create one.
                            </p>
                            </div>
                        )}
                    </div>

                    {/* Section 2 - Clients' View of User's Imprint  */}
                    <div className=' m-1 p-1 bg-gray-200 rounded '>
                            <div className='grid grid-cols-8 justify-start items-center'>
                                <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                    <div
                                        onClick={() => setShowImprint360Clients(prev => !prev)}
                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                        >
                                        {showImprint360Clients ? (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                        )}
                                    </div>
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowImprint360Clients(prev => !prev)} ><EtImprintCustomersIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                    <div>
                                        <p className='mx-1 px-1 font-bold text-gray-600'>Clients</p>
                                        <p className='mx-1 px-1 text-gray-400 text-xs italic'>how do my clients register my imprint?</p>
                                    </div>     
                                </div>
                            </div>
                            
                            {showImprint360Clients && (
                                <div className='bg-white rounded m-1 p-1'>
                                    <p className='mx-1 px-1 text-sm'>Upcoming!</p>
                                </div>
                            )}
                    </div>
                    
                    {/* Section 3 - Partners' View of User's Imprint */}
                    <div className=' m-1 p-1 bg-gray-200 rounded '>
                            <div className='grid grid-cols-8 justify-start items-center'>
                                <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                    <div
                                        onClick={() => setShowImprint360Partners(prev => !prev)}
                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                        >
                                        {showImprint360Partners ? (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                        )}
                                    </div>
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowImprint360Partners(prev => !prev)} ><EtImprintPartnersIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                    <div>
                                        <p className='mx-1 px-1 font-bold text-gray-600'>Partners</p>
                                        <p className='mx-1 px-1 text-gray-400 text-xs italic'>how do my partners register my imprint?</p>
                                    </div>     
                                </div>
                            </div>
                            
                            {showImprint360Partners && (
                                <div className='bg-white rounded m-1 p-1'>
                                    <p className='mx-1 px-1 text-sm'>Upcoming!</p>
                                </div>
                            )}
                    </div>

                    {/* Section 4 - Team's View of User's Imprint  */}
                    <div className=' m-1 p-1 bg-gray-200 rounded '>
                            <div className='grid grid-cols-8 justify-start items-center'>
                                <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                    <div
                                        onClick={() => setShowImprint360Team(prev => !prev)}
                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                        >
                                        {showImprint360Team ? (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                        )}
                                    </div>
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowImprint360Team(prev => !prev)} ><EtImprintTeamIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                    <div>
                                        <p className='mx-1 px-1 font-bold text-gray-600'>Team</p>
                                        <p className='mx-1 px-1 text-gray-400 text-xs italic'>how do my teammates register my imprint?</p>
                                    </div>     
                                </div>
                            </div>
                            
                            {showImprint360Team && (
                                <div className='bg-white rounded m-1 p-1'>
                                    <p className='mx-1 px-1 text-sm'>Upcoming!</p>
                                </div>
                            )}
                    </div>
                    
                    {/* Section 5 - Org Leadership's View of User's Imprint */}
                    <div className=' m-1 p-1 bg-gray-200 rounded '>
                            <div className='grid grid-cols-8 justify-start items-center'>
                                <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                    <div
                                        onClick={() => setShowImprint360Leadership(prev => !prev)}
                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                        >
                                        {showImprint360Leadership ? (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                        )}
                                    </div>
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowImprint360Leadership(prev => !prev)} ><EtImprintLeadersIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                    <div>
                                        <p className='mx-1 px-1 font-bold text-gray-600'>Leadership</p>
                                        <p className='mx-1 px-1 text-gray-400 text-xs italic'>how do my leaders register my imprint?</p>
                                    </div>     
                                </div>
                            </div>
                            
                            {showImprint360Leadership && (
                                <div className='bg-white rounded m-1 p-1'>
                                    <p className='mx-1 px-1 text-sm'>Upcoming!</p>
                                </div>
                            )}
                    </div>

                </div>
            )}

            {activeTab === 'imagine' && (
                <div>
                    {/* Section 1 - Your Vision */}
                    <div className=' m-1 p-1 bg-gray-200 rounded '>
                            <div className='grid grid-cols-8 justify-start items-center'>
                                <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                    <div
                                        onClick={() => setShowImprintFutureVision(prev => !prev)}
                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                        >
                                        {showImprintFutureVision ? (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                        )}
                                    </div>
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowImprintFutureVision(prev => !prev)} ><EtImprintVisionIcon2SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                    <div>
                                        <p className='mx-1 px-1 font-bold text-gray-600'>Vision</p>
                                        <p className='mx-1 px-1 text-gray-400 text-xs italic'>what will guide my future imprint?</p>
                                    </div>     
                                </div>
                            </div>
                            
                            {showImprintFutureVision && (
                                <div>
                                    {/* <ImprintFutureVision /> */}
                                    <EtImprintVision userId={userId} userInfo={userInfo}/>
                                </div>
                            )}
                    </div>
                    
                    {/* Section 2 - Your Missions */}
                    <div className=' m-1 p-1 bg-gray-200 rounded '>
                            <div className='grid grid-cols-8 justify-start items-center'>
                                <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                    <div
                                        onClick={() => setShowImprintFutureMissions(prev => !prev)}
                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                        >
                                        {showImprintFutureMissions ? (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                        )}
                                    </div>
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowImprintFutureMissions(prev => !prev)} ><EtImprintMissionsIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                    <div>
                                        <p className='mx-1 px-1 font-bold text-gray-600'>Missions</p>
                                        <p className='mx-1 px-1 text-gray-400 text-xs italic'>what will shape my future imprint?</p>
                                    </div>     
                                </div>
                            </div>
                            
                            {showImprintFutureMissions && (
                                <div>
                                    {/* <ImprintFutureMissions /> */}
                                    <EtImprintMission userId={userId} userInfo={userInfo}/>
                                </div>
                            )}
                    </div>

                    {/* Section 3 - Your Strategies */}
                    {/* <div className=' m-1 p-1 bg-gray-200 rounded '>
                            <div className='grid grid-cols-8 justify-start items-center'>
                                <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                    <div
                                        onClick={() => setShowImprintFutureStrategies(prev => !prev)}
                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                        >
                                        {showImprintFutureStrategies ? (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                        )}
                                    </div>
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowImprintFutureStrategies(prev => !prev)} ><EtFutureStrategyIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                    <div>
                                        <p className='mx-1 px-1 font-bold text-gray-600'>Strategies</p>
                                        <p className='mx-1 px-1 text-gray-400 text-xs italic'>how should I guide my future imprint?</p>
                                    </div>     
                                </div>
                            </div>
                            
                            {showImprintFutureStrategies && (
                                <div>
                                    <ImprintFutureStrategies />
                                </div>
                            )}
                    </div> */}

                    {/* Section 4 - Your Plans */}
                    <div className=' m-1 p-1 bg-gray-200 rounded '>
                            <div className='grid grid-cols-8 justify-start items-center'>
                                <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                    <div
                                        onClick={() => setShowImprintFuturePlans(prev => !prev)}
                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                        >
                                        {showImprintFuturePlans ? (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                        )}
                                    </div>
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowImprintFuturePlans(prev => !prev)} ><EtImprintPlansIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                    <div>
                                        <p className='mx-1 px-1 font-bold text-gray-600'>Plans</p>
                                        <p className='mx-1 px-1 text-gray-400 text-xs italic'>how will I change my future imprint?</p>
                                    </div>     
                                </div>
                            </div>
                            
                            {showImprintFuturePlans && (
                                <div>
                                    {/* <ImprintFuturePlans /> */}
                                    <EtImprintPlan userId={userId} userInfo={userInfo}/>
                                </div>
                            )}
                    </div>
                </div>
            )}
        </div>
      
    </div>
  )
}

const ImprintOverview = ({userInfo, overview=[], createMarkup}) => {
  // First Created - 27 Oct 2024
  // Author - Atul Pandey
  // Updated - 31 Jan 2025 - added userTitle for Individuals

  const username = userInfo[0]?.username
  const userName = userInfo[0]?.name
  const userOrg = userInfo[0]?.userOrgId.organizationName
  const userRole = userInfo[0]?.userOrgRole.orgRoleName
  const userTitle = userInfo[0]?.userOrgTitle.orgTitleName // 31 Jan 2025
  const headline = overview[0]?.userImprintOverviewName
  const intro = overview[0]?.userImprintOverviewDesc
  const stats = overview[0]?.userImprintOverviewStats

  return(
    <div className='bg-white rounded m-1 p-1'>

      <div className='grid grid-cols-3 justify-start items-start m-1 p-1 border rounded'>
        <div className='col-span-1'>
          <div className='m-1 p-1'>
            <UserFrame username={username} defaultUserPic={userProfileIcon2} />
            <div className='flex justify-center items-center bg-black shadow'>
              <p className='m-1 p-1 font-bold text-orange-600'>{userName} <span className='text-gray-200'>|</span></p>
              <p className='mx-1 text-gray-400'>{userRole},</p>
              {userOrg === "Movers" ? (                                 // 31 Jan 2025
                <p className="text-gray-600">{userTitle}</p>
                ) : (
                <p className="text-gray-600">{userOrg}</p>
              )}
          </div>
          </div>
        </div>
        <div className='col-span-2 my-2 h-full font-poppins m-1 p-1'>
            <div>
              <p className='m-1 p-1 text-orange-600 font-bold'>{headline}</p>
            </div>
            <div className='text-[10px] m-1 p-1' dangerouslySetInnerHTML={createMarkup(intro)}>
            </div>
            {/* Stats Section */}
            <div className='flex justify-center items-center'>
                {stats?.length>0 && stats?.map((stat,statIndex) => {
                    return (
                        <div key={stat._id+statIndex} className='border-t'>

                            <div className='grid grid-cols-7 justify-start items-top m-1 p-1 gap-2'>

                                <div className='col-span-1'>
                                    <div className='flex justify-center items-center m-1 p-1'>
                                        <EtImprintOverviewSpanIcon2SVGComp className='h-7 w-7 text-orange-600'/>
                                        <p className='m-1 p-1 font-bold text-center'>Span</p>
                                    </div>
                                    <div className='aspect-square w-full flex flex-col justify-center items-center border rounded bg-gradient-to-r from-black to-black via-gray-700'>
                                        <p className='font-bold text-orange-600 text-4xl'>{stat?.yearsOfExperience}</p>
                                        <p className='text-xs text-gray-400'>Years</p>
                                    </div>
                                </div>

                                <div className='col-span-3'>
                                    <div className='flex justify-center items-center m-1 p-1'>
                                        <EtImprintOverviewExperienceIcon1SVGComp className='h-7 w-7 text-orange-600'/>
                                        <p className='m-1 p-1 font-bold text-center'>Experince</p>
                                    </div>
                                    
                                    {stat?.experienceSnapshot?.map((experience, experienceIndex) => (
                                        <div key={experience._id+experienceIndex} className='grid grid-cols-2 justify-start items-center bg-gray-200 m-1 p-1 rounded text-[10px]'>
                                            <div className='col-span-1'>
                                                <p className='mx-1 px-1'>{formatDateToMonthYear(experience?.startDate)} - {formatDateToMonthYear(experience?.endDate)}</p>
                                            </div>
                                            <div className='col-span-1'>
                                                <p className='font-bold'>{experience?.orgName}</p>
                                            </div>                        
                                        </div>
                                    ))}
                                </div>

                                <div className='col-span-3'>
                                    <div className='flex justify-center items-center m-1 p-1'>
                                        <EtImprintOverviewEducationIcon1SVGComp className='h-7 w-7 text-orange-600'/>
                                        <p className='m-1 p-1 font-bold text-center'>Education</p>
                                    </div>
                                    {stat?.educationSnapshot?.map((education, educationIndex) => (
                                        <div key={education._id+educationIndex} className='grid grid-cols-4 justify-center items-center bg-gray-200 m-1 p-1 rounded text-[10px]'>
                                            <div className='col-span-1'>
                                                <p className='mx-1 px-1'>{formatDateToYear(education?.startDate)} - {formatDateToYear(education.endDate)}</p>
                                            </div>
                                            <div className='col-span-1'>
                                                <p>{education?.degreeName} </p>
                                            </div>
                                            <div className='col-span-2'>
                                                <p>{education?.instituteName}</p>
                                            </div> 
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
      </div>
    </div>
  )
}

const ImprintCapabilities = ({userId, userInfo}) => {
    // First Created - 18 Nov 2024
    // Author - Atul Pandey
  
    // 1a - State to manage active tab
    const [activeTab, setActiveTab] = useState("overview");

    // 1b - Capability Dimensions
    const capabilityDimensions = [
        {
          name: "Industry",
          description: "Expertise in specific industries",
          pic: <EtCapabilitiesIndustryIcon1SVGComp className="h-8 w-8 text-orange-600" />,
          tabPic: <EtCapabilitiesIndustryIcon1SVGComp className="h-4 w-4 text-orange-600" />,
          component: EtImprintCapabilitiesIndustry,
          props:{userId: userId, userInfo: userInfo}
        },
        {
          name: "Domains",
          description: "Focused knowledge in business domains",
          pic: <EtCapabilitiesDomainIcon1SVGComp className="h-8 w-8 text-orange-600" />,
          tabPic: <EtCapabilitiesDomainIcon1SVGComp className="h-4 w-4 text-orange-600" />,
          component: EtImprintCapabilitiesDomain,
          props:{userId: userId, userInfo: userInfo}
        },
        {
          name: "Functions",
          description: "Operational roles and responsibilities",
          pic: <EtCapabilitiesFunctionIcon1SVGComp className="h-8 w-8 text-orange-600" />,
          tabPic: <EtCapabilitiesFunctionIcon1SVGComp className="h-4 w-4 text-orange-600" />,
          component: EtImprintCapabilitiesFunction,
          props:{userId: userId, userInfo: userInfo}
        },
        {
          name: "Tech Areas",
          description: "Technology specialization areas",
          pic: <EtCapabilitiesTechAreaIcon1SVGComp className="h-8 w-8 text-orange-600" />,
          tabPic: <EtCapabilitiesTechAreaIcon1SVGComp className="h-4 w-4 text-orange-600" />,
          component: EtImprintCapabilitiesTechArea,
          props:{userId: userId, userInfo: userInfo}
        },
        {
          name: "Tech Stacks",
          description: "Software frameworks and stacks",
          pic: <EtCapabilitiesTechStackIcon1SVGComp className="h-8 w-8 text-orange-600" />,
          tabPic: <EtCapabilitiesTechStackIcon1SVGComp className="h-4 w-4 text-orange-600" />,
          component: EtImprintCapabilitiesTechStack,
          props:{userId: userId, userInfo: userInfo}
        },
        {
          name: "Services",
          description: "Service offerings and expertise",
          pic: <EtCapabilitiesServiceIcon1SVGComp className="h-8 w-8 text-orange-600" />,
          tabPic: <EtCapabilitiesServiceIcon1SVGComp className="h-4 w-4 text-orange-600" />,
          component: EtImprintCapabilitiesService,
          props:{userId: userId, userInfo: userInfo}
        },
        {
          name: "Sales",
          description: "Proficiency in sales and growth",
          pic: <EtCapabilitiesSalesIcon1SVGComp className="h-8 w-8 text-orange-600" />,
          tabPic: <EtCapabilitiesSalesIcon1SVGComp className="h-4 w-4 text-orange-600" />,
          component: EtImprintCapabilitiesSales,
          props:{userId: userId, userInfo: userInfo}
        },
        {
          name: "Advisory",
          description: "Strategic consulting and advisory skills",
          pic: <EtCapabilitiesAdvisoryIcon1SVGComp className="h-8 w-8 text-orange-600" />,
          tabPic: <EtCapabilitiesAdvisoryIcon1SVGComp className="h-4 w-4 text-orange-600" />,
          component: EtImprintCapabilitiesAdvisory,
          props:{userId: userId, userInfo: userInfo}
        },
        {
          name: "SoftSkills",
          description: "Interpersonal and communication skills",
          pic: <EtCapabilitiesSoftSkillIcon1SVGComp className="h-8 w-8 text-orange-600" />,
          tabPic: <EtCapabilitiesSoftSkillIcon1SVGComp className="h-4 w-4 text-orange-600" />,
          component: EtImprintCapabilitiesSoftSkill,
          props:{userId: userId, userInfo: userInfo}
        },
      ];
      
    return (
      <div className="bg-white rounded m-1 p-1 font-poppins">
        {/* Tab Navigation */}
        <div className='flex justify-between items-center'>
            <div className="flex justify-start items-center border-b mb-2">
            <div
                onClick={() => setActiveTab("overview")}
                className={`text-xs flex items-center px-4 py-2 cursor-pointer ${
                activeTab === "overview" ? "text-orange-600 font-bold border-b-2 border-orange-600" : "text-gray-400"
                }`}
            >
                <p className='mx-[2px] px-[2px]'>Overview</p>
            </div>
            {capabilityDimensions.map((dimension, index) => (
                <div
                key={index}
                onClick={() => setActiveTab(dimension.name.toLowerCase())}
                className={`text-xs flex items-center px-4 py-2 cursor-pointer ${
                    activeTab === dimension.name.toLowerCase()
                    ? "text-orange-600 font-bold border-b-2 border-orange-600"
                    : "text-gray-400"
                }`}
                >
                {/* Dynamically update the tabPic class */}
                <div className="h-6 w-6 flex justify-center items-center">
                    {React.cloneElement(dimension.tabPic, {
                    className: `h-4 w-4 ${
                        activeTab === dimension.name.toLowerCase() ? "text-orange-600" : "text-gray-400"
                    }`,
                    })}
                </div>
                <p className='mx-[2px] px-[2px]'>{dimension.name}</p>
                </div>
            ))}
            </div>
            {/* <div className='flex justify-start items-center'>
                <p className='text-xs font-bold mx-1 px-1'>Add</p>
                <button
                    className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                >
                    <PlusIcon className="h-3 w-3" />
                </button>
            </div> */}
        </div>
  
        {/* Tab Content */}
        <div className="py-2">
          {activeTab === "overview" && (
            <div className="grid grid-cols-3 gap-3">
            {capabilityDimensions?.map((dimension, index) => (
              <div
                key={index}
                className="bg-gray-100 border aspect-[5/2] border-gray-200 rounded shadow hover:shadow-lg p-4 flex flex-col items-center"
              >
                {/* Card Picture */}
                <div className="mb-2">{dimension?.pic}</div>
                {/* Card Header */}
                <div className="font-bold text-orange-600 text-lg mb-2">{dimension?.name}</div>
                {/* Card Details */}
                <div className="text-xs text-gray-600 text-center">
                  {dimension?.description}
                </div>
              </div>
            ))}
          </div>
          )}
          {/* Individual Capability Dimension Content */}
            {capabilityDimensions.map((dimension, index) => (
                activeTab === dimension.name.toLowerCase() && (
                <div key={index} className="bg-gray-100 border rounded p-4">
                    {/* <div className="flex flex-col items-center">
                    {dimension.pic}
                    <h3 className="text-lg font-bold text-orange-600 mt-2">{dimension.name}</h3>
                    <p className="text-gray-600 text-center mt-1">{dimension.description}</p>
                    </div> */}

                    <div className="mt-2">
                    {/* Render the capability's component */}
                    {React.createElement(dimension.component, { ...dimension.props })}
                </div>
                </div>
                )
            ))}
        </div>
      </div>
    );
  };

const ImprintPurpose = ({ userId, userInfo, purpose=[],createMarkup, isEditingPurpose, setIsEditingPurpose, isAddingPurpose, setIsAddingPurpose}) => {
  // First Created - 09 Nov 2024
  // Author - Atul Pandey

  // 0 - Validate incoming data
//   console.log('purpose', purpose)
  
  // 1a - Set Data variables
  const [showForm, setShowForm] = useState(false); 
  const [showMessage, setShowMessage] = useState(false);
  const [showDetails, setShowDetails] = useState(false); 
  const [showPurposeDetails, setShowPurposeDetails] = useState(false); 

  // 1b - Set the incoming purpose data
  useEffect(() => {
    if (isAddingPurpose) {
        // Show an empty form for adding a new purpose
        setShowForm(true);
        setShowDetails(false);
        setShowMessage(false);
        setFormData({
          userImprintPurposeName: "",
          userImprintPurposeDesc: "",
          userImprintPurposeSelfNotes: [{ userImprintPurposeSelfNoteText: "" }],
          newPurpose: false,
        });
      } else if (purpose && purpose.length > 0) {
      setShowDetails(true);
      setShowMessage(false);
      setShowForm(isEditingPurpose)
      const latestSelfNote = purpose[0].userImprintPurposeSelfNotes?.slice(-1)[0] || { userImprintPurposeSelfNoteText: "" };
      setFormData({
        userImprintPurposeName: purpose[0].userImprintPurposeName || "",
        userImprintPurposeDesc: purpose[0].userImprintPurposeDesc || "",
        userImprintPurposeSelfNotes: [latestSelfNote],
        newPurpose: false,
      });
    } else {
      setShowMessage(true);
    
    }
  }, [purpose, isEditingPurpose, isAddingPurpose]);  
  
  // 2a - RTK Query & Mutations
  const [createNewUserImprintPurpose, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewUserImprintPurposeMutation()

   
  // 3 - Initialize formData states
  const [formData, setFormData] = useState({
        userImprintPurposeName: "",        // Purpose name
        userImprintPurposeDesc: "",        // Purpose description
        userImprintPurposeSelfNotes: [{ userImprintPurposeSelfNoteText: "" }], // Self-note array
        newPurpose: false,                 // New purpose flag
    });

  // 4a - Generic handler for input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // 4b - Handler for purpose description editor
  const handlePurposeDescChange = (content) => {
    setFormData((prevData) => ({
      ...prevData,
      userImprintPurposeDesc: content,
    }));
  };
  
  // 4c - Handler for self-note editor
  const handleSelfNoteChange = (content) => {
    setFormData((prevData) => ({
        ...prevData,
        userImprintPurposeSelfNotes: [
          {
            ...prevData.userImprintPurposeSelfNotes[0],
            userImprintPurposeSelfNoteText: content,
          },
        ],
      }));
  };

  // 4d - Handle Toggle show forms
  const toggleForm = () => {
    setShowForm(prev => !prev);
    setShowMessage(false);
    setShowDetails(false);
  };                                        
  
  // 6 - Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      userId: userId,
      userOrgId: userInfo[0]?.userOrgId._id,
      userImprintPurposeName: formData.userImprintPurposeName,
      userImprintPurposeDesc: formData.userImprintPurposeDesc,
      userImprintPurposeSelfNotes: formData.userImprintPurposeSelfNotes,
      newPurpose: formData.newPurpose,
    };
  
    try {
      await createNewUserImprintPurpose(payload).unwrap();
      alert("Imprint Purpose saved successfully");
      setShowForm(false);
      setShowDetails(true);
      setShowMessage(false);
      setIsEditingPurpose(false);
      setIsAddingPurpose(false);
    } catch (error) {
      console.error("Error submitting purpose:", error);
    }
  };
  
  // 7 - Handle Cancel
  const handleCancel = () => {
    setShowForm(false);
    setShowDetails(true);
    setIsEditingPurpose(false);
    setIsAddingPurpose(false);
    setFormData({
        userImprintPurposeName: "",
        userImprintPurposeDesc: "",
        userImprintPurposeSelfNotes: [{ userImprintPurposeSelfNoteText: "" }],
        newPurpose: false,
    });
  }

  return(
        <div>
            
            {showForm && (
                <div className="bg-white rounded m-1 p-1 font-poppins">
                    <form onSubmit={handleSubmit}>
                    {/* Purpose Name Input */}
                    <div className="mb-2">
                        <label className="block font-semibold mb-1">Purpose Name:</label>
                        <input
                        type="text"
                        name="userImprintPurposeName"
                        value={formData.userImprintPurposeName}
                        onChange={handleInputChange}
                        className="w-full border rounded p-2 text-sm"
                        placeholder="Enter your purpose headline..."
                        />
                    </div>

                    {/* Purpose Description Editor */}
                    <div className="mb-2">
                        <label className="block font-semibold mb-1">Purpose Description:</label>
                        <EtContentEditorLight2
                        value={formData.userImprintPurposeDesc}
                        handleChange={handlePurposeDescChange}
                        placeholderText="Describe your purpose in detail..."
                        editorKey={"purposeDescEditor"}
                        />
                    </div>

                    {/* Self Note Editor */}
                    <div className="mb-2">
                        <label className="block font-semibold mb-1">Self Note:</label>
                        <EtContentEditorLight2
                        value={formData.userImprintPurposeSelfNotes[0]?.userImprintPurposeSelfNoteText || ""}
                        handleChange={handleSelfNoteChange}
                        placeholderText="Add a personal note..."
                        editorKey={"selfNoteEditor"}
                        />
                    </div>

                    {/* New Purpose Checkbox */}
                    {purpose?.length > 0 && (
                        <div className="mb-2">
                        <label className="inline-flex items-center">
                            <input
                            type="checkbox"
                            name="newPurpose"
                            checked={formData.newPurpose}
                            onChange={handleInputChange}
                            className="form-checkbox"
                            />
                            <span className="ml-2 text-xs">This is a significant change to my purpose</span>
                        </label>
                        </div>
                    )}

                    {/* Action Buttons */}
                    <div className="flex space-x-2">
                        <button
                        type="submit"
                        className="bg-lime-500 hover:bg-lime-600 text-white text-xs rounded px-2 py-1"
                        >
                        Save
                        </button>
                        <button
                        type="button"
                        onClick={handleCancel}
                        className="bg-orange-600 hover:bg-red-600 text-white text-xs rounded px-2 py-1"
                        >
                        Cancel
                        </button>
                    </div>
                    </form>
                </div>
            )}

            {showMessage && !showForm && (
                <div className="bg-white rounded m-2 p-2">
                    <p className="text-orange-600 m-1 p-1 text-xs">
                    Your purpose is waiting for you to make it your own. Go ahead, click the add button above!.
                    </p>
                </div>
            )}

            {showDetails && !showForm && (
                <div className="bg-gray-200 rounded m-1 p-1">
                    {/* Purpose Details */}
                    <div className='grid grid-cols-6 justify-start items-center m-1 p-1'>
                        <div className='col-span-1 flex justify-start items-center m-1 p-1'>
                            <div
                                onClick={() => setShowPurposeDetails(prev => !prev)}
                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-gray-100"
                            >
                                {showPurposeDetails ? (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                )}
                            </div>
                            <div>
                                <EtImprintPurposeNameIcon1SVGComp className='h-7 w-7 text-orange-600'/>
                            </div>
                            <div>
                                <div className='flex flex-col justify-center items-center mx-1 px-1'>
                                    <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">{getTimeSince(purpose[0]?.updatedAt)}</p>
                                    <p className='text-[9px] text-gray-600 italic'>last updated</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-span-3'>
                            <p className='text-sm mx-1 px-1 text-gray-600 '>{purpose[0]?.userImprintPurposeName}</p>
                        </div>
                        <div className="col-span-2 flex justify-center items-center mx-2">
                            <ClockIcon className="h-5 w-5 text-lime-600" />
                            <p className="text-[9px] mx-1">First Created:</p>
                            <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(purpose[0]?.createdAt)}</p>
                        </div>

                    </div>
                    
                    {showPurposeDetails && (
                        <div className='bg-gray-50 rounded m-1 p-1'>
                            <p dangerouslySetInnerHTML={createMarkup(purpose[0]?.userImprintPurposeDesc)}  className='text-xs m-1 p-1'/>
                        </div>
                        
                    )}
                </div>
            )}

        </div>
  )
}

const Imprint360Et = ({commenterInfo, userInfo, overview=[], createMarkup}) => {

  // First Created - 02 Nov 2024
  // Author - Atul Pandey

  const username = userInfo[0]?.username
  const userName = userInfo[0]?.name
  const userFirstName = userName ? userName.split(' ')[0] : '';
  const userOrg = userInfo[0]?.userOrgId.organizationName
  const userRole = userInfo[0]?.userOrgRole.orgRoleName
  const headline = overview[0]?.userImprintEtName
  const intro = overview[0]?.userImprintEtDesc
  const beacons = overview[0]?.userInspirationBeacons
  const gratitudes = overview[0]?.userImprintEtGratitudeNotes
  const commenter = commenterInfo[0].username
  const commenterName = commenterInfo[0].name
  const commenterFirstName = commenterName ? commenterName.split(' ')[0] : '';

  const [showGratitudes, setShowGratitudes] = useState(false)
  const [showGratitudeNote, setShowGratitudeNote] = useState({})

  const handleToggleShowGratitudeNote = (noteId) => {
    setShowGratitudeNote((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
      }))
  }


  return(
    <div className='bg-white rounded m-1 p-1'>

      <div className='grid grid-cols-3 justify-start items-start m-1 p-1 border rounded'>
        <div className='col-span-1'>
          <div className='m-1 p-1'>
            <UserFrame username={username} defaultUserPic={userProfileIcon2} />
            <div className='flex justify-center items-center bg-black shadow'>
              <p className='m-1 p-1 font-bold text-orange-600'>{userName} <span className='text-gray-200'>|</span></p>
              <p className='mx-1 text-gray-400'>{userRole},</p>
              <p className='text-gray-600'>{userOrg}</p>
          </div>
          </div>
        </div>
        <div className='col-span-2 my-2 h-full font-poppins rounded  m-1 p-1'>
            <div>
              <p className='m-1 p-1 text-orange-600 font-bold'>{headline}</p>
            </div>
            <div className='text-[10px] m-1 p-1' dangerouslySetInnerHTML={createMarkup(intro)}>
            </div>
            {/* Beacons Section */}
            <div className='m-1 p-1 border-t'>
                <div className='flex justify-start items-center m-1 p-1 w-full rounded'>
                    <div className=''>
                        <EtInspirationBeaconIcon2SVGComp className='h-8 w-8 text-orange-600'/>
                    </div>
                    <div>
                        <p className='font-bold mx-1 px-1'>Inspiration Beacons</p>
                        <p className='text-[10px] mx-1 px-1 italic text-gray-400'>How <span className='text-orange-600'>{userFirstName}</span> lights up the world?</p>
                        <div className='mx-2 px-1 h-[2px] w-3 bg-orange-600 rounded'/>
                    </div>
                </div>  
            </div>
            <div className='grid grid-cols-3 justify-start items-start m-1 p-1 gap-2'>
                        {beacons?.length>0 && beacons?.map((beacon,beaconIndex) => {
                            return (
                                <div key={beacon._id+beaconIndex} className='col-span-1 flex justify-start items-center m-1 p-1'>
            
                                    <div className='rounded border border-orange-600 h-9 w-9 flex justify-center items-center bg-white'><img src={beacon?.userInspirationBeaconId?.pic} className='h-7 w-7' /></div>
                                    {/* <div className='rounded h-9 w-9 flex justify-center items-center bg-gradient-to-r from-black to-black via-gray-800'><img src={beacon?.userInspirationBeaconId?.pic} className='h-7 w-7' /></div> */}
                                    <div><p className='text-[10px] mx-1 px-1 text-gray-600'>{beacon?.userInspirationBeaconId?.inspirationBeaconName}</p></div>
                        
                                </div>
                            )
                        })}
                        
            </div>
            {beacons.length === 0 && (
                <div className='w-full m-1 p-1'>
                    <p className='text-orange-600 mx-1 px-1 text-xs'>No beacons have been added yet!</p>
                </div>
            )}
        </div>
      </div>
      
      {/* Et Gratitude Notes */}
      <div className='m-1 p-1 border rounded'>

        <div className='flex justify-start items-center m-1 p-1 w-full rounded'>
            <div
                onClick={() => setShowGratitudes(prev => !prev)}
                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                >
                {showGratitudes ? (
                    <MinusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                    <PlusIcon className="h-4 w-4 text-orange-600" />
                )}
            </div>

            <div className=''>
                <EtGratitudeTypeIcon1SVGComp className='h-8 w-8 text-orange-600'/>
            </div>
            <div>
                <p className='font-bold mx-1 px-1'>Gratitude Notes</p>
                <p className='text-[10px] mx-1 px-1 italic text-gray-400'>how is Et grateful to <span className='text-orange-600'>{userFirstName}</span>?</p>
                
            </div>
        </div>  

        {showGratitudes && gratitudes.length >0 && gratitudes?.map((gratitude, gratitudeIndex) => {
            return(
                <div key={gratitude._id+gratitudeIndex} className='bg-gray-100 rounded m-1 p-1'>

                    <div className='grid grid-cols-8 justify-start items-center'>
                        <div className='col-span-2 flex justify-start items-center m-1 p-1'>
                            <div>
                                <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ gratitudeIndex +1}.</p>
                            </div>
                            <div
                                onClick={() => handleToggleShowGratitudeNote(gratitude._id)}
                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                >
                                {showGratitudeNote[gratitude._id] ? (
                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                )}
                            </div>
                            
                            <div className='flex flex-col justify-center items-center'>
                                <User username={commenter} defaultUserPic={userProfileIcon2} />
                            </div>
                            <div className='flex flex-col justify-center items-center mx-1 px-1'>
                                <p className='text-[10px] my-1 text-gray-600'>{commenterFirstName}</p>
                                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">{getTimeSince(gratitude?.userImprintEtGratitudeNoteUpdateDate)}</p>
                            </div>
                        </div>
                        <div className='col-span-3'>
                            <p dangerouslySetInnerHTML={createMarkup(gratitude?.userImprintEtGratitudeNoteText)} className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"></p>
                        </div>
                        <div className="col-span-3 flex justify-center items-center mx-2">
                            <ClockIcon className="h-5 w-5 text-lime-600" />
                            <p className="text-[9px] mx-1">First Created:</p>
                            <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(gratitude?.userImprintEtGratitudeNoteDate)}</p>
                        </div>
                    </div>
                    {showGratitudeNote[gratitude._id] && (
                        <div className="m-1 p-1 rounded bg-white">
                        <div
                            dangerouslySetInnerHTML={createMarkup(gratitude?.userImprintEtGratitudeNoteText)}
                            className="text-[11px] text-gray-700 m-1 p-1"
                        ></div>
                        </div>
                    )}     
                </div>
            )
        })}
      </div>
    </div>
  )
}

const ImprintFutureVision = () => {

    // First Created - 08 Nov 2024
    // Author - Atul Pandey
    // Updated - 09 Nov 2024 - added tabs - self and client

    const [activeTab, setActiveTab] = useState("self") // 09 Nov 2024
    
    return(
        <div className='bg-white rounded m-1 p-1'> 
            <div className="flex justify-start items-center m-1 p-1 font-poppins rounded">
                <div
                    onClick={() => setActiveTab("self")}
                    className={`flex items-center text-gray-700 font-bold mx-2 cursor-pointer ${
                    activeTab === "self" ? "text-orange-600" : ""
                    }`}
                >
                    <div><EtImprintVisionSelfIcon1SVGComp className='h-8 w-8' /></div>
                    <div>
                        <p className="mx-1 px-1 text-sm">Self</p>
                        <p className="mx-1 px-1 font-normal text-[9px] italic">what legacy am I inspired to create?</p>
                    </div>
                </div>
                <div
                    onClick={() => setActiveTab("clients")}
                    className={`flex items-center text-gray-700 font-bold mx-2 cursor-pointer ${
                    activeTab === "clients" ? "text-orange-600" : ""
                    }`}
                >
                    <div><EtImprintVisionClientsIcon1SVGComp className='h-8 w-8' /></div>
                    <div className=''>
                        <p className="mx-1 px-1 text-sm">Clients</p>
                        {/* <p className="mx-1 px-1 font-normal text-[9px] italic">what impact am I inspired to make for my clients?</p> */}
                        <p className="mx-1 px-1 font-normal text-[9px] italic">what am I inspired to do for my clients?</p>
                    </div>
                </div>
                <div
                    onClick={() => setActiveTab("org")}
                    className={`flex items-center text-gray-700 font-bold mx-2 cursor-pointer ${
                    activeTab === "org" ? "text-orange-600" : ""
                    }`}
                >
                    <div><EtImprintVisionOrgIcon1SVGComp className='h-8 w-8' /></div>
                    <div>
                        <p className="mx-1 px-1 text-sm">Org</p>
                        <p className="mx-1 px-1 font-normal text-[9px] italic">what am I inspired to do for my org?</p>
                        {/* <p className="mx-1 px-1 font-normal text-[9px] italic">what drives me?</p> */}
                    </div>
                </div>
                <div
                    onClick={() => setActiveTab("team")}
                    className={`flex items-center text-gray-700 font-bold mx-2 cursor-pointer ${
                    activeTab === "team" ? "text-orange-600" : ""
                    }`}
                >
                    <div><EtImprintVisionTeamIcon1SVGComp className='h-8 w-8' /></div>
                    <div>
                        <p className="mx-1 px-1 text-sm">Team</p>
                        {/* <p className="mx-1 px-1 font-normal text-[9px] italic">what impact am I inspired to make for my clients?</p> */}
                        <p className="mx-1 px-1 font-normal text-[9px] italic">what am I inspired to do for my team?</p>
                    </div>
                </div>
                <div
                    onClick={() => setActiveTab("partners")}
                    className={`flex items-center text-gray-700 font-bold mx-2 cursor-pointer ${
                    activeTab === "partners" ? "text-orange-600" : ""
                    }`}
                >
                    <div><EtImprintVisionPartnerIcon1SVGComp className='h-8 w-8' /></div>
                    <div>
                        <p className="mx-1 px-1 text-sm">Partners</p>
                        {/* <p className="mx-1 px-1 font-normal text-[9px] italic">what impact am I inspired to make for my clients?</p> */}
                        <p className="mx-1 px-1 font-normal text-[9px] italic">what am I inspired to do for my partners?</p>
                    </div>
                </div>
            </div>

            <div className='border-t my-2 py-2'>
                {activeTab === 'self' && (
                    <div>
                        <p>Self</p>
                    </div>
                )}

                {activeTab === 'clients' && (
                    <div>
                        <p>Clients</p>
                    </div>
                )}
                {activeTab === 'org' && (
                    <div>
                        <p>Org</p>
                    </div>
                )}

                {activeTab === 'partners' && (
                    <div>
                        <p>Partners</p>
                    </div>
                )}
                {activeTab === 'team' && (
                    <div>
                        <p>Team</p>
                    </div>
                )}
            </div>

        </div>
    )
}

const ImprintFutureStrategies = () => {

    // First Created - 05 Jan 2025
    // Author - Atul Pandey

    const [activeTab, setActiveTab] = useState("self") // 09 Nov 2024
    
    return(
        <div className='bg-white rounded m-1 p-1'> 
            <div className="flex justify-start items-center m-1 p-1 font-poppins rounded">
                <div
                    onClick={() => setActiveTab("self")}
                    className={`flex items-center text-gray-700 font-bold mx-2 cursor-pointer ${
                    activeTab === "self" ? "text-orange-600" : ""
                    }`}
                >
                    <div><EtFutureStrategySelfIcon1SVGComp className='h-8 w-8' /></div>
                    <div>
                        <p className="mx-1 px-1 text-sm">Self</p>
                        <p className="mx-1 px-1 font-normal text-[9px] italic">how will I guide my self missions?</p>
                    </div>
                </div>
                <div
                    onClick={() => setActiveTab("clients")}
                    className={`flex items-center text-gray-700 font-bold mx-2 cursor-pointer ${
                    activeTab === "clients" ? "text-orange-600" : ""
                    }`}
                >
                    <div><EtFutureStrategyClientIcon1SVGComp className='h-8 w-8' /></div>
                    <div>
                        <p className="mx-1 px-1 text-sm">Clients</p>
                        <p className="mx-1 px-1 font-normal text-[9px] italic">how will I guide my client missions?</p>
                    </div>
                </div>
            </div>

            <div className='border-t my-2 py-2'>
                {activeTab === 'self' && (
                    <div>
                        <p>Self</p>
                    </div>
                )}

                {activeTab === 'clients' && (
                    <div>
                        <p>Clients</p>
                    </div>
                )}
            </div>

        </div>
    )
}

const ImprintFutureMissions = () => {

    // First Created - 09 Nov 2024
    // Author - Atul Pandey

    const [activeTab, setActiveTab] = useState("self") // 09 Nov 2024
    
    return(
        <div className='bg-white rounded m-1 p-1'> 
            <div className="flex justify-start items-center m-1 p-1 font-poppins rounded">
                <div
                    onClick={() => setActiveTab("self")}
                    className={`flex items-center text-gray-700 font-bold mx-2 cursor-pointer ${
                    activeTab === "self" ? "text-orange-600" : ""
                    }`}
                >
                    <div><EtImprintMissionsSelfIcon1SVGComp className='h-8 w-8' /></div>
                    <div>
                        <p className="mx-1 px-1 text-sm">Self</p>
                        <p className="mx-1 px-1 font-normal text-[9px] italic">what I aspire to achieve for myself?</p>
                    </div>
                </div>
                <div
                    onClick={() => setActiveTab("clients")}
                    className={`flex items-center text-gray-700 font-bold mx-2 cursor-pointer ${
                    activeTab === "clients" ? "text-orange-600" : ""
                    }`}
                >
                    <div><EtImprintMissionsClientsIcon1SVGComp className='h-8 w-8' /></div>
                    <div>
                        <p className="mx-1 px-1 text-sm">Clients</p>
                        <p className="mx-1 px-1 font-normal text-[9px] italic">what I aspire to achieve for my clients?</p>
                    </div>
                </div>
            </div>

            <div className='border-t my-2 py-2'>
                {activeTab === 'self' && (
                    <div>
                        <p>Self</p>
                    </div>
                )}

                {activeTab === 'clients' && (
                    <div>
                        <p>Clients</p>
                    </div>
                )}
            </div>

        </div>
    )
}

const ImprintFuturePlans = () => {
    // First Created - 09 Nov 2024
    // Author - Atul Pandey

    const [activeTab, setActiveTab] = useState("self") // 09 Nov 2024
    
    return(
        <div className='bg-white rounded m-1 p-1'> 
            <div className="flex justify-start items-center m-1 p-1 font-poppins rounded">
                <div
                    onClick={() => setActiveTab("self")}
                    className={`flex items-center text-gray-700 font-bold mx-2 cursor-pointer ${
                    activeTab === "self" ? "text-orange-600" : ""
                    }`}
                >
                    <div><EtImprintPlansSelfIcon1SVGComp className='h-8 w-8' /></div>
                    <div>
                        <p className="mx-1 px-1 text-sm">Self</p>
                        <p className="mx-1 px-1 font-normal text-[9px] italic">how will I achieve it for myself?</p>
                    </div>
                </div>
                <div
                    onClick={() => setActiveTab("clients")}
                    className={`flex items-center text-gray-700 font-bold mx-2 cursor-pointer ${
                    activeTab === "clients" ? "text-orange-600" : ""
                    }`}
                >
                    <div><EtImprintPlansClientsIcon1SVGComp className='h-8 w-8' /></div>
                    <div>
                        <p className="mx-1 px-1 text-sm">Clients</p>
                        <p className="mx-1 px-1 font-normal text-[9px] italic">how will I achieve it for my clients?</p>
                    </div>
                </div>
            </div>

            <div className='border-t my-2 py-2'>
                {activeTab === 'self' && (
                    <div>
                        <p>Self</p>
                    </div>
                )}

                {activeTab === 'clients' && (
                    <div>
                        <p>Clients</p>
                    </div>
                )}
            </div>

        </div>
    )
}

const User = ({ username, defaultUserPic }) => {
  const { data: userPic, isFetching: isFetchingUserPic } =
    useGetUserPicQuery(username)

  return (
    <div className="flex justify-center items-center h-12 w-12 rounded-full border border-white bg-gradient-to-r from-orange-600 to-orange-400 shadow-lg shadow-black cursor-pointer">
      {/* Display user picture or loading indicator*/}
      {isFetchingUserPic ? (
        <div>Loading...</div>
      ) : userPic ? (
        <img src={userPic} alt="User" className="h-11 w-11  rounded-full" />
      ) : (
        <img src={defaultUserPic} alt="User" className="h-10 w-10 p-1" />
      )}
    </div>
  )
}

const UserFrame = ({ username, defaultUserPic }) => {
  const { data: userPic, isFetching: isFetchingUserPic } =
    useGetUserPicQuery(username)

  return (
    <div className="flex justify-center items-center rounded cursor-pointer border-white bg-gradient-to-r from-orange-600 to-orange-400 shadow-lg shadow-black">
      {/* Display user picture or loading indicator*/}
      {isFetchingUserPic ? (
        <div>Loading...</div>
      ) : userPic ? (
        <img src={userPic} alt="User" className="" />
      ) : (
        <img src={defaultUserPic} alt="User" className="" />
      )}
    </div>
  )
}

export default EtUserImprint