import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../../app/api/apiSlice'

const userImprintShowcasesAdapter = createEntityAdapter({})

const initialState = userImprintShowcasesAdapter.getInitialState()

export const userImprintShowcasesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserImprintShowcases: builder.query({
            query: () => ({
                url: '/userImprintShowcases/getUserImprintShowcases',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserImprintShowcases = responseData.map(userImprintShowcase => {
                    userImprintShowcase.id = userImprintShowcase._id
                    return userImprintShowcase
                })
                return userImprintShowcasesAdapter.setAll(initialState, loadedUserImprintShowcases)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserImprintShowcase', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserImprintShowcase', id}))
                    ]
                } else {
                    return [ { type: 'UserImprintShowcase', id: 'LIST'}]
                }
            },
        }),
        createNewUserImprintShowcase: builder.mutation({
            query: initialUserImprintShowcase => ({
                url: '/userImprintShowcases/createNewUserImprintShowcase',
                method: 'POST',
                body: {...initialUserImprintShowcase,}
            }),
            invalidatesTags: [
                { type: 'UserImprintShowcase', id: "LIST" }
            ] 
        }),
        updateUserImprintShowcase: builder.mutation({
            query: initialUserImprintShowcase => ({
                url: '/userImprintShowcases/updateUserImprintShowcase',
                method: 'PATCH',
                body: {...initialUserImprintShowcase,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserImprintShowcase', id: arg.id },
                { type: 'UserImprintShowcase', id: 'LIST' }
            ],
        }),
        deleteUserImprintShowcase: builder.mutation({
            query: ({id}) => ({
                url: '/userImprintShowcases/deleteUserImprintShowcase',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserImprintShowcase', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserImprintShowcasesQuery,
    useCreateNewUserImprintShowcaseMutation,
    useUpdateUserImprintShowcaseMutation,
    useDeleteUserImprintShowcaseMutation,
} = userImprintShowcasesApiSlice

// returns the query result object
export const selectUserImprintShowcaseResult = userImprintShowcasesApiSlice.endpoints.getUserImprintShowcases.select()

// create memoized selector
const selectUserImprintShowcasesData = createSelector(
    selectUserImprintShowcaseResult,
    userImprintShowcaseResult => userImprintShowcaseResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserImprintShowcases,
    selectById: selectUserImprintShowcaseById,
    selectIds: selectUserImprintShowcaseIds,
} = userImprintShowcasesAdapter.getSelectors(state => selectUserImprintShowcasesData(state) ?? initialState)