import React, {useState} from 'react'
import { useNavigate } from 'react-router'
import { addIcon } from '@/assets/img/imgAssets'
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { useGetSectionsQuery } from './sectionsApiSlice'
import PulseLoader from "react-spinners/PulseLoader"


const EtSectionList = ({setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }) => {
    // Created - 10 Apr 2024
    // Author - Atul Pandey
    // Updated - 01 Mar 2025 - updated the softSkillFunctionArea with props - { setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }
    // Updated - 01 Mar 2025 - switched from navigate to onAdd, onUpdate, onDelete, setSelectedEntityId


    const navigate = useNavigate()
    // Initialize showDetails state as an object where key is the section index
    const [showDetails, setShowDetails] = useState(null)
    const {data: sectionsData, isError, error, isLoading} = useGetSectionsQuery()
    //console.log('sectionsData', sectionsData)

    const handleToggle = (index) => {
        // Toggle the show/hide state for the specific section index
        setShowDetails(prev => prev === index ? null : index);
      }
    
    // Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
     }
  

    // Optional: handle loading and error states
    if (isLoading) return <PulseLoader color={"#ea580c"} />
    if (isError) return <div>Error occurred: {error?.data?.message}</div>


  return (
    <div className='bg-gray-200 m-1 p-1 rounded'>
        {/* Header */}
        <div className='flex justify-start items-center m-1 p-1'>

            <div><p className='font-poppins text-2xl m-1 p-1 font-bold'>Et Assessment Sections</p></div>
            <div>
          <img
            src={addIcon}
            alt=""
            className="h-10 w-10 cursor-pointer"
            // onClick={() => navigate("/sections/create")}
            onClick={onAdd} // 01 Mar 2025
          />
        </div>

        </div>

        {/* Section Data */}
        <div id='section-data' className='bg-white rounded m-1 p-1 font-poppins'>
            {sectionsData && sectionsData.ids.map((id, index) => {
                const {sectionName, qsetName, sectionIntro, questions} = sectionsData.entities[id]
                return(
                <div key={index} className=' bg-gray-200 rounded m-1 p-1'>
                    <div className='flex justify-between items-center'>

                        <div className='flex justify-start items-center mx-2'>

                          <div
                                      onClick={() => handleToggle(index)}
                                      className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white"
                                  >
                                      {showDetails === index ? (
                                      <MinusIcon className="h-3 w-3 text-orange-600 " />
                                      ) : (
                                      <PlusIcon className="h-3 w-3 text-orange-600 " />
                                      )}
                                  </div> 
                        <div className='m-1 p-1' ><p className='text-sm font-bold '>{index+1} - {sectionName}</p></div>
                        </div>

                        <div className='flex justify-start items-center'>

                                <div 
                                
                                // onClick={() => navigate(`/sections/update/${id}`)} 
                                onClick={() => onUpdate(id)} // 01 Mar 2025 
                                
                                className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'>
                                    <PencilSquareIcon className="h-3 w-3 text-orange-600 " />
                                </div>

                                <div 
                                
                                // onClick={() => navigate(`/sections/delete/${id}`)} 
                                onClick={() => onDelete(id)} // 01 Mar 2025 
                                
                                className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'>
                                    <TrashIcon className="h-3 w-3 text-orange-600 " />
                                </div>
                                    
                                
                            </div>
                      </div>

                      {showDetails === index && (
                        <div  className=' bg-white rounded text-xs py-1'>
                            <div className='m-1 p-1'><p className='font-bold mx-2 px-2'><span className='text-black mx-1 py-1 border-b-2 border-orange-600'>Question Set:</span><span className='mx-2 font-normal'>{qsetName}</span></p> </div>
                            <div className='m-2 p-2 rounded border border-gray-200' > <p className='px-2 leading-4' dangerouslySetInnerHTML={createMarkup(sectionIntro)}></p></div>
                            <div className='mx-1 px-1'><p className='font-bold px-2 mx-1 text-xl'>Section Questions:</p> </div>
                            {questions.map((question, detailIndex) => (
                            <div key={detailIndex} className='flex justify-start items-center bg-gray-200 rounded m-1 p-1'>
                                <div className='m-1 p-1'><p className='font-bold text-gray-400'>Q {detailIndex+1} :</p></div>
                                <div className='m-1 p-1' dangerouslySetInnerHTML={createMarkup(question.questionName)}></div>
                            </div>))}

                        </div>
                      )
                        
                        
                      }
                </div>
            )})}

        </div>

    </div>
  )
}

export default EtSectionList