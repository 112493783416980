
    import React from 'react';
    
    const EtSponsorGroupIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m3405.6 745.2h-1994.4c-57.602 0-104.4 46.801-104.4 104.4v777.6l86.398-43.199c39.602-21.602 79.199-32.398 122.4-36l0.003906-594h1785.6v1717.2h-1785.6v-381.6l-208.8 108v381.6c0 57.602 46.801 104.4 104.4 104.4h1994.4c57.602 0 104.4-46.801 104.4-104.4l0.003906-1929.6c0-57.602-46.801-104.4-104.4-104.4z" fillRule="evenodd"/>
 <path d="m1702.8 1810.8c-43.199-86.398-147.6-118.8-234-75.602l-324 165.6s-180-126-237.6-165.6c-36-25.199-144-111.6-248.4-147.6-115.2-39.598-414-64.797-496.8 129.6-86.398 205.2-72 734.4-68.398 1116 0 32.398 21.602 57.602 54 57.602h626.4c39.602 0 50.398-21.602 57.602-50.398 3.6016-18 39.602-450 36-637.2v-64.801l165.6 111.6c28.801 21.602 64.801 28.801 97.199 28.801 28.801 0 54-7.1992 79.199-18l417.6-212.4c82.797-46.805 118.8-151.21 75.598-237.61z" fillRule="evenodd"/>
 <path d="m907.2 1090.8c0 136.33-72.73 262.31-190.8 330.47-118.07 68.168-263.53 68.168-381.6 0-118.07-68.164-190.8-194.14-190.8-330.47s72.734-262.31 190.8-330.48c118.07-68.164 263.53-68.164 381.6 0 118.07 68.168 190.8 194.14 190.8 330.48" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtSponsorGroupIcon1SVGComp;
    