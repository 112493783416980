import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const creationAreaTypesAdapter = createEntityAdapter({})

const initialState = creationAreaTypesAdapter.getInitialState()

export const creationAreaTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCreationAreaTypes: builder.query({
            query: () => ({
                url: '/creationAreaTypes/getCreationAreaTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedCreationAreaTypes = responseData.map(creationAreaType => {
                    creationAreaType.id = creationAreaType._id
                    return creationAreaType
                })
                return creationAreaTypesAdapter.setAll(initialState, loadedCreationAreaTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'CreationAreaType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'CreationAreaType', id}))
                    ]
                } else {
                    return [ { type: 'CreationAreaType', id: 'LIST'}]
                }
            },
        }),
        createNewCreationAreaType: builder.mutation({
            query: initialCreationAreaType => ({
                url: '/creationAreaTypes/createNewCreationAreaType',
                method: 'POST',
                body: {...initialCreationAreaType,}
            }),
            invalidatesTags: [
                { type: 'CreationAreaType', id: "LIST" }
            ] 
        }),
        updateCreationAreaType: builder.mutation({
            query: initialCreationAreaType => ({
                url: '/creationAreaTypes/updateCreationAreaType',
                method: 'PATCH',
                body: {...initialCreationAreaType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'CreationAreaType', id: arg.id },
                { type: 'CreationAreaType', id: 'LIST' }
            ],
        }),
        deleteCreationAreaType: builder.mutation({
            query: ({id}) => ({
                url: '/creationAreaTypes/deleteCreationAreaType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'CreationAreaType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetCreationAreaTypesQuery,
    useCreateNewCreationAreaTypeMutation,
    useUpdateCreationAreaTypeMutation,
    useDeleteCreationAreaTypeMutation,
} = creationAreaTypesApiSlice

// returns the query result object
export const selectCreationAreaTypeResult = creationAreaTypesApiSlice.endpoints.getCreationAreaTypes.select()

// create memoized selector
const selectCreationAreaTypesData = createSelector(
    selectCreationAreaTypeResult,
    creationAreaTypeResult => creationAreaTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCreationAreaTypes,
    selectById: selectCreationAreaTypeById,
    selectIds: selectCreationAreaTypeIds,
} = creationAreaTypesAdapter.getSelectors(state => selectCreationAreaTypesData(state) ?? initialState)