import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userVisionTeamsAdapter = createEntityAdapter({})

const initialState = userVisionTeamsAdapter.getInitialState()

export const userVisionTeamsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserVisionTeams: builder.query({
            query: () => ({
                url: '/userVisionTeams/getUserVisionTeams',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserVisionTeams = responseData.map(userVisionTeam => {
                    userVisionTeam.id = userVisionTeam._id
                    return userVisionTeam
                })
                return userVisionTeamsAdapter.setAll(initialState, loadedUserVisionTeams)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserVisionTeam', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserVisionTeam', id}))
                    ]
                } else {
                    return [ { type: 'UserVisionTeam', id: 'LIST'}]
                }
            },
        }),
        createNewUserVisionTeam: builder.mutation({
            query: initialUserVisionTeam => ({
                url: '/userVisionTeams/createNewUserVisionTeam',
                method: 'POST',
                body: {...initialUserVisionTeam,}
            }),
            invalidatesTags: [
                { type: 'UserVisionTeam', id: "LIST" }
            ] 
        }),
        updateUserVisionTeam: builder.mutation({
            query: initialUserVisionTeam => ({
                url: '/userVisionTeams/updateUserVisionTeam',
                method: 'PATCH',
                body: {...initialUserVisionTeam,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserVisionTeam', id: arg.id },
                { type: 'UserVisionTeam', id: 'LIST' }
            ],
        }),
        deleteUserVisionTeam: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userVisionTeams/deleteUserVisionTeam',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userVisionTeams/deleteUserVisionTeam',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserVisionTeam', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserVisionTeamsQuery,
    useCreateNewUserVisionTeamMutation,
    useUpdateUserVisionTeamMutation,
    useDeleteUserVisionTeamMutation,
} = userVisionTeamsApiSlice

// returns the query result object
export const selectUserVisionTeamResult = userVisionTeamsApiSlice.endpoints.getUserVisionTeams.select()

// create memoized selector
const selectUserVisionTeamsData = createSelector(
    selectUserVisionTeamResult,
    userVisionTeamResult => userVisionTeamResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserVisionTeams,
    selectById: selectUserVisionTeamById,
    selectIds: selectUserVisionTeamIds,
} = userVisionTeamsAdapter.getSelectors(state => selectUserVisionTeamsData(state) ?? initialState)