import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const capabilitiesSalesTypesAdapter = createEntityAdapter({})

const initialState = capabilitiesSalesTypesAdapter.getInitialState()

export const capabilitiesSalesTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCapabilitiesSalesTypes: builder.query({
            query: () => ({
                url: '/capabilitiesSalesTypes/getCapabilitiesSalesTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedCapabilitiesSalesTypes = responseData.map(capabilitiesSalesType => {
                    capabilitiesSalesType.id = capabilitiesSalesType._id
                    return capabilitiesSalesType
                })
                return capabilitiesSalesTypesAdapter.setAll(initialState, loadedCapabilitiesSalesTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'CapabilitiesSalesType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'CapabilitiesSalesType', id}))
                    ]
                } else {
                    return [ { type: 'CapabilitiesSalesType', id: 'LIST'}]
                }
            },
        }),
        createNewCapabilitiesSalesType: builder.mutation({
            query: initialCapabilitiesSalesType => ({
                url: '/capabilitiesSalesTypes/createNewCapabilitiesSalesType',
                method: 'POST',
                body: {...initialCapabilitiesSalesType,}
            }),
            invalidatesTags: [
                { type: 'CapabilitiesSalesType', id: "LIST" }
            ] 
        }),
        updateCapabilitiesSalesType: builder.mutation({
            query: initialCapabilitiesSalesType => ({
                url: '/capabilitiesSalesTypes/updateCapabilitiesSalesType',
                method: 'PATCH',
                body: {...initialCapabilitiesSalesType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'CapabilitiesSalesType', id: arg.id },
                { type: 'CapabilitiesSalesType', id: 'LIST' }
            ],
        }),
        deleteCapabilitiesSalesType: builder.mutation({
            query: ({id}) => ({
                url: '/capabilitiesSalesTypes/deleteCapabilitiesSalesType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'CapabilitiesSalesType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetCapabilitiesSalesTypesQuery,
    useCreateNewCapabilitiesSalesTypeMutation,
    useUpdateCapabilitiesSalesTypeMutation,
    useDeleteCapabilitiesSalesTypeMutation,
} = capabilitiesSalesTypesApiSlice

// returns the query result object
export const selectCapabilitiesSalesTypeResult = capabilitiesSalesTypesApiSlice.endpoints.getCapabilitiesSalesTypes.select()

// create memoized selector
const selectCapabilitiesSalesTypesData = createSelector(
    selectCapabilitiesSalesTypeResult,
    capabilitiesSalesTypeResult => capabilitiesSalesTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCapabilitiesSalesTypes,
    selectById: selectCapabilitiesSalesTypeById,
    selectIds: selectCapabilitiesSalesTypeIds,
} = capabilitiesSalesTypesAdapter.getSelectors(state => selectCapabilitiesSalesTypesData(state) ?? initialState)