import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const capabilitiesServiceFunctionAreaTypesAdapter = createEntityAdapter({})

const initialState = capabilitiesServiceFunctionAreaTypesAdapter.getInitialState()

export const capabilitiesServiceFunctionAreaTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCapabilitiesServiceFunctionAreaTypes: builder.query({
            query: () => ({
                url: '/capabilitiesServiceFunctionAreaTypes/getCapabilitiesServiceFunctionAreaTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedCapabilitiesServiceFunctionAreaTypes = responseData.map(capabilitiesServiceFunctionAreaType => {
                    capabilitiesServiceFunctionAreaType.id = capabilitiesServiceFunctionAreaType._id
                    return capabilitiesServiceFunctionAreaType
                })
                return capabilitiesServiceFunctionAreaTypesAdapter.setAll(initialState, loadedCapabilitiesServiceFunctionAreaTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'CapabilitiesServiceFunctionAreaType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'CapabilitiesServiceFunctionAreaType', id}))
                    ]
                } else {
                    return [ { type: 'CapabilitiesServiceFunctionAreaType', id: 'LIST'}]
                }
            },
        }),
        createNewCapabilitiesServiceFunctionAreaType: builder.mutation({
            query: initialCapabilitiesServiceFunctionAreaType => ({
                url: '/capabilitiesServiceFunctionAreaTypes/createNewCapabilitiesServiceFunctionAreaType',
                method: 'POST',
                body: {...initialCapabilitiesServiceFunctionAreaType,}
            }),
            invalidatesTags: [
                { type: 'CapabilitiesServiceFunctionAreaType', id: "LIST" }
            ] 
        }),
        updateCapabilitiesServiceFunctionAreaType: builder.mutation({
            query: initialCapabilitiesServiceFunctionAreaType => ({
                url: '/capabilitiesServiceFunctionAreaTypes/updateCapabilitiesServiceFunctionAreaType',
                method: 'PATCH',
                body: {...initialCapabilitiesServiceFunctionAreaType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'CapabilitiesServiceFunctionAreaType', id: arg.id },
                { type: 'CapabilitiesServiceFunctionAreaType', id: 'LIST' }
            ],
        }),
        deleteCapabilitiesServiceFunctionAreaType: builder.mutation({
            query: ({id}) => ({
                url: '/capabilitiesServiceFunctionAreaTypes/deleteCapabilitiesServiceFunctionAreaType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'CapabilitiesServiceFunctionAreaType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetCapabilitiesServiceFunctionAreaTypesQuery,
    useCreateNewCapabilitiesServiceFunctionAreaTypeMutation,
    useUpdateCapabilitiesServiceFunctionAreaTypeMutation,
    useDeleteCapabilitiesServiceFunctionAreaTypeMutation,
} = capabilitiesServiceFunctionAreaTypesApiSlice

// returns the query result object
export const selectCapabilitiesServiceFunctionAreaTypeResult = capabilitiesServiceFunctionAreaTypesApiSlice.endpoints.getCapabilitiesServiceFunctionAreaTypes.select()

// create memoized selector
const selectCapabilitiesServiceFunctionAreaTypesData = createSelector(
    selectCapabilitiesServiceFunctionAreaTypeResult,
    capabilitiesServiceFunctionAreaTypeResult => capabilitiesServiceFunctionAreaTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCapabilitiesServiceFunctionAreaTypes,
    selectById: selectCapabilitiesServiceFunctionAreaTypeById,
    selectIds: selectCapabilitiesServiceFunctionAreaTypeIds,
} = capabilitiesServiceFunctionAreaTypesAdapter.getSelectors(state => selectCapabilitiesServiceFunctionAreaTypesData(state) ?? initialState)