import React, { useState } from 'react'
import useTitle from '@/hooks/useTitle'
import { useNavigate } from 'react-router'
import { addIcon } from '@/assets/img/imgAssets'
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon,  } from '@heroicons/react/24/outline'
import { BuildingLibraryIcon ,CalendarDaysIcon} from '@heroicons/react/24/solid'
import PulseLoader from "react-spinners/PulseLoader"
import { useGetContactOrganizationsQuery } from './contactOrganizationsApiSlice'

const EtContactOrganizationList = ({ setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }) => {
    // First Created - 28 Sep 2024
    // Author - Atul Pandey
    // Updated - 07 Mar 2025 - updated the comp with props - { setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }
    // Updated - 07 Mar 2025 - switched from navigate to onAdd, onUpdate, onDelete, setSelectedEntityId

    // 0 - Set Page Title
    useTitle('ContactOrganizations List')

    // 1 - set page navigations
    const navigate = useNavigate()

    // 2 - Initialize showDetails state as an object where key is the contactOrganization index
    const [showDetails, setShowDetails] = useState({})

    // 3 - Extract the contactOrganizations data
    const { data: contactOrganizationsData, isError, error, isLoading } = useGetContactOrganizationsQuery()
    console.log('contactOrganizationsData', contactOrganizationsData)

    // 4 - Toggle Show function for contactOrganization details
    const handleToggleDetails = (contactOrganizationId) => {
        setShowDetails(prev => ({ ...prev, [contactOrganizationId]: !prev[contactOrganizationId] }));
    }

    // 5 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
        return { __html: htmlContent }
    }

    // 6 - Optional: handle loading and error states
    if (isLoading) return <PulseLoader color={"#ea580c"} />
    if (isError) return <div>Error occurred: {error?.data?.message}</div>

    // 7 - Grouping contactOrganizations by trail
    const contactOrganizations =  contactOrganizationsData?.ids.map(id => {
        const contactOrganization = contactOrganizationsData?.entities[id];
        const { 
            _id,
            contactOrganizationName,
            contactOrganizationIntro, 
            contactOrganizationTypeId, 
            contactOrganizationDesc, 
            contactOrganizationTargetAudience, 
            pic, 
            contactOrganizationStartedDate, 
            contactOrganizationLocations, 
            contactOrganizationOutcomes} = contactOrganization;
        
            return {
            _id,
            contactOrganizationName,
            contactOrganizationIntro, 
            contactOrganizationTypeId, 
            contactOrganizationDesc, 
            contactOrganizationTargetAudience, 
            pic, 
            contactOrganizationStartedDate, 
            contactOrganizationLocations, 
            contactOrganizationOutcomes
            }
    });

    console.log('contactOrganizations', contactOrganizations)

    return (
        <div className='bg-gray-200 m-1 p-1 rounded font-poppins mx-auto'>
            {/* Header */}
            <div className='flex justify-start items-center m-1 p-1'>
                <div><p className='text-2xl m-1 p-1 font-bold'>Et ContactOrganizations</p></div>
                <div>
                    <img
                        src={addIcon}
                        alt=""
                        className="h-10 w-10 cursor-pointer"
                        // onClick={() => navigate("/contactOrganizations/create")}
                        onClick={onAdd} // 07 Mar 2025
                    />
                </div>
            </div>

            {contactOrganizations && contactOrganizations?.map((item, index) => (
                <div key={item+index} className='m-1 p-1 bg-white rounded'>

                <div className='m-1 p-1 bg-gray-100 rounded '>
                    
                    <div className='flex justify-between items-center'>
                    <div className='flex justify-start items-center mx-2'>

                            <div
                                onClick={() => handleToggleDetails(item._id)}
                                className="m-1 p-1 cursor-pointer rounded-full h-7 w-7 flex justify-center items-center hover:bg-white"
                            >
                                {showDetails[item._id] ? <MinusIcon className="h-6 w-6 text-orange-600" /> : <PlusIcon className="h-4 w-4 text-orange-600" />}
                            </div>

                            <div className='m-1 p-1'>
                                <p className='font-bold'>{index+1} - {item.contactOrganizationName}</p>
                                <p className='text-xs'>{item.contactOrganizationDesc}</p>
                            </div>
                    </div>
                    <div className='flex justify-start items-center mx-2'>
                        <div className='rounded-full flex justify-center items-center h-6 w-6 hover:bg-white'>
                            <PencilSquareIcon 
                            // onClick={() => navigate(`/contactOrganizations/update/${item?._id}`)} 
                            onClick={() => onUpdate(item?._id)} // 07 Mar 2025 
                            
                            className="h-4 w-4 text-orange-600 cursor-pointer" />
                        </div>
                        <div className='rounded-full flex justify-center items-center h-6 w-6 hover:bg-white'>
                            <TrashIcon 
                            // onClick={() => navigate(`/contactOrganizations/delete/${item?._id}`)} 
                            onClick={() => onDelete(item?._id)} // 07 Mar 2025 
                            
                            className="h-4 w-4 text-orange-600 cursor-pointer" />
                        </div>
                    </div>
                    </div>
                
                    {showDetails[item._id] && (
                        <div className='m-1 p-1 bg-gray-200 rounded'>
                            {/* ContactOrganization Details */}
                            <div className='m-1 p-1 rounded bg-white'>

                                {item?.contactOrganizationIntro && (
                                <div className='bg-gray-50 rounded text-xs mx-2 px-2 my-1 py-1'>
                                <p className='m-1 p-1' dangerouslySetInnerHTML={createMarkup(item?.contactOrganizationIntro)}></p>
                                </div>)}

                                <div className='flex justify-start items-center my-2'>
                                <BuildingLibraryIcon className="h-4 w-4 text-orange-600 mx-2" />
                                <p className='text-xs font-bold'>Org Type: <span className='font-normal text-gray-400'>{item?.contactOrganizationTypeId.contactOrganizationTypeName}</span> </p>
                                </div>

                                <div className='flex justify-start items-center my-2'>
                                <CalendarDaysIcon className="h-4 w-4 text-orange-600 mx-2" />
                                <p className='text-xs font-bold'>Established: <span className='font-normal text-gray-400'>{item?.contactOrganizationStartedDate ? formatDate(item?.contactOrganizationStartedDate) : 'A long time ago....'}</span></p>
                                </div>

                                {item?.contactOrganizationLocations && item?.contactOrganizationLocations.map((location, locIndex) => (
                                    <div key={location+locIndex} className='rounded m-1 p-1 bg-gray-200 inline-block'>
                                        <p className='text-[10px] mx-1 px-1'>{location.location}</p>
                                    </div>
                                ))}
                                
                            </div>
                        </div>
                    )}
                
                </div>

                

            </div>
            ))}
            

        </div>
    )
}

// Function to format the date
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'long', year: 'numeric' };
    const day = date.getDate();
  
    // Determine the correct ordinal indicator
    const ordinal = (n) => {
        let s = ["th", "st", "nd", "rd"],
            v = n % 100;
        return s[(v - 20) % 10] || s[v] || s[0]; // Only returns the suffix
    }
    // const ordinal = (n) => {
    //   let s = ["th", "st", "nd", "rd"],
    //       v = n % 100;
    //   return n + (s[(v-20)%10] || s[v] || s[0]);
    // };
  
   // return `${ordinal(day)} ${new Intl.DateTimeFormat('en-GB', options).format(date)}`;

   return (
    <>
      {day}<sup>{ordinal(day)}</sup> {new Intl.DateTimeFormat('en-GB', options).format(date)}
    </>
  )

//     const formattedDate = `${day}<sup>${ordinal(day)}</sup> ${new Intl.DateTimeFormat('en-GB', options).format(date)}`;
//   return formattedDate;
  }

export default EtContactOrganizationList