import React, { useState, useEffect } from 'react'
import useTitle from '@/hooks/useTitle'
import { useNavigate } from 'react-router'
import { addIcon, userProfileIcon2 } from '@/assets/img/imgAssets'
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon,  } from '@heroicons/react/24/outline'
import { UserGroupIcon, BuildingOffice2Icon, ArrowTrendingUpIcon, CalendarDaysIcon, ArrowRightCircleIcon } from '@heroicons/react/24/solid'
import PulseLoader from "react-spinners/PulseLoader"
import { useGetSponsorUserPadsQuery } from './sponsorUserPadsApiSlice'
import { useGetUserPicQuery } from "../users/usersApiSlice"


const EtSponsorUserPadListCompProd = ({sponsorId, sponsorOrgId}) => {
    // First Created - 21 Oct 2024
    // Author - Atul Pandey
    // Inherited from EtSponsorUserPadList
    // Purpose - to filter and show sponsor user pads for a specific user
    // Updated - 03 Mar 2025 - copied from EtSponsorUserPadListComp and refined it for prod (userMagicHome --> etapp)
    // Updated - 08 Mar 2025 - sessionSequence fix - showing sessions in ascending order of sessionSequence
    

    // 0 - Set Page Title EtSponsorUserPadList
    useTitle('Sponsor User Pad List')

    // 1 - set page navigations
    const navigate = useNavigate()

    // 2 - Initialize showDetails state as an object where key is the session index
    const [showSponsorDetails, setShowSponsorDetails] = useState(true)
    const [showOrgs, setShowOrgs] = useState({})
    const [showTrails, setShowTrails] = useState({})
    const [showDetails, setShowDetails] = useState({})

    // 3 - Extract the sponsor user pads data
    const { data: sponsorUserPadsData, isError, error, isLoading, refetch } = useGetSponsorUserPadsQuery()
    const sponsorUserPads = sponsorUserPadsData?.ids.map((id) => {
        const sponsorUserPad = sponsorUserPadsData?.entities[id]
        return sponsorUserPad
    })?.filter(pad => pad.orgId?._id === sponsorOrgId && pad.sponsorId?._id === sponsorId )
    // console.log('Sponsor User Pads Data', sponsorUserPadsData)
    // console.log('Sponsor User Pads:', sponsorUserPads)

    // const filteredSponsorPads = sponsorUserPads?.filter(pad => pad.orgId?._id === sponsorOrgId && pad.sponsorId?._id === sponsorId )
    // console.log('filteredSponsorPads', filteredSponsorPads)

    // 3a - Refetch data when the component is mounted or navigated to
    useEffect(() => {
        refetch();
    }, [navigate, refetch]);


     // 4 - Group the data by sponsorId, organization, trails, sessions, and users - added role and title - 07 Sep 2024 - 08 Sep 2024 - added sponsorName to capture sponsor name and refactored sponsorId to capture id instead of name
    const groupedData = sponsorUserPads?.reduce((acc, pad) => {
        const sponsorId = pad.sponsorId?._id || 'No Sponsor Id'; //  08 Sep 2024 Get the sponsor ID
        const sponsorName = pad.sponsorId?.name || 'No Sponsor'; // 08 Sep 2024 Get the sponsor name
        const sponsorUsername = pad.sponsorId?.username || 'No Username';
        const sponsorOrgRole = pad.sponsorId?.userOrgRole?.orgRoleName || 'No Role';    // 07 Sep 2024
        const sponsorOrgTitle = pad.sponsorId?.userOrgTitle?.orgTitleName || 'No Role'; // 07 Sep 2024
        const orgId = pad.orgId?._id || 'No org id';
        const orgName = pad.userId.userOrgId?.organizationName || 'No Organization';
        const trailId=pad.trailId?._id || 'No Trail Id';
        const trailName = pad.trailId?.trailName || 'No Trail';
        const trailPic = pad.trailId?.pic || 'No Trail';
        const sessionId = pad.sessionId?._id || 'No Session';

        const session = {
            sessionId: sessionId,
            sessionName: pad.sessionId?.sessionName || 'No Session',
            sessionPic: pad.sessionId?.pic || '',
            sessionDesc: pad.sessionId?.sessionDesc || '',
            sessionSequence: pad.sessionId?.sessionSequence || 0, // ✅ 08 Mar 2025 - Added sessionSequence
            users: []
        };

        const user = {
            userId: pad.userId?._id,
            userName: pad.userId?.name,
            username: pad.userId?.username,
            userEmail: pad.userId?.email,
            ...pad // Other properties from pad if necessary
        };

        if (!acc[sponsorId]) acc[sponsorId] = { name: sponsorName, username: sponsorUsername, role:sponsorOrgRole, title:sponsorOrgTitle, orgs: {} };  // added role and title - 07 Sep 2024                                                                                   
        if (!acc[sponsorId].orgs[orgId]) acc[sponsorId].orgs[orgId] = { name: orgName, trails: {} };
        if (!acc[sponsorId].orgs[orgId].trails[trailName]) acc[sponsorId].orgs[orgId].trails[trailName] = { id:trailId,pic: trailPic, sessions: {} }; // Updated
        if (!acc[sponsorId].orgs[orgId].trails[trailName].sessions[sessionId]) acc[sponsorId].orgs[orgId].trails[trailName].sessions[sessionId] = { ...session }; // Updated
        
        // 21 Oct 2024 - added the if logic to filter out the sponsor from the users list
        if (user.userId._id !== sponsorId) {
            acc[sponsorId].orgs[orgId].trails[trailName].sessions[sessionId].users.push(user);
        }
        // console.log('users', user)
        
        
        return acc;
    }, {});

    // console.log('Grouped Data:', groupedData);

    // 5 - Toggle Show function for sponsor user pad details
    const handleToggleDetails = (trailIndex, sessionIndex) => {
        const key = `${trailIndex}-${sessionIndex}`;
        setShowDetails(prev => ({ ...prev, [key]: !prev[key] }));
    };

    // 5a - Toggle Show function for trails
    const handleToggleSponsorDetails = (sponsorId) => {
        setShowSponsorDetails(prev => ({ ...prev, [sponsorId]: !prev[sponsorId] }));
    }
    
    // 5b - Toggle Show function for trails
    const handleToggleTrails = (orgIndex, trailIndex) => {
        const key = `${orgIndex}-${trailIndex}`;
        setShowTrails(prev => ({ ...prev, [key]: !prev[key] }));
    };

    // 5b - Toggle Show function for trails
    const handleToggleOrgs = (sponsorIndex, orgIndex) => {
        const key = `${sponsorIndex}-${orgIndex}`;
        setShowOrgs(prev => ({ ...prev, [key]: !prev[key] }));
    };
    
    if (isLoading) return <PulseLoader color={"#ea580c"} />
    if (isError) return <p className="text-red-500">{error?.data?.message}</p>

    return (
        <div className='bg-gray-200 m-1 p-1 rounded font-poppins mx-auto'>
            {/* Header */}
            {/* <div className='flex justify-start items-center m-1 p-1'>
                <div><p className='text-2xl m-1 p-1 font-bold'>Et Sponsor User Pads</p></div>
                <div>
                    <img
                        src={addIcon}
                        alt=""
                        className="h-10 w-10 cursor-pointer"
                        onClick={() => navigate('/sponsorUserPad/create')}
                    />
                </div>
            </div> */}

           
            {Object.keys(groupedData)?.length === 0 ? (
                <div className="m-1 p-1 bg-white rounded">
                    <p className='text-orange-600 text-xs m-1 p-1'>No sponsor user pads available. Please create one by clicking the "+" button above.</p>
                </div>
            ) : (
            Object.entries(groupedData)?.map(([sponsorId, sponsorData], sponsorIndex) => {
                const { name: sponsorName, username: sponsorUsername, role: sponsorOrgRole, title: sponsorOrgTitle, orgs: organizations } = sponsorData; // 07 Sep 2024 - added role and title
                const orgCount = Object.entries(organizations)?.length
                const trailCount = Object.values(organizations).reduce((acc, org) => acc + Object.keys(org.trails).length, 0);
                const sessionCount = Object.values(organizations).reduce((acc, org) => {
                    return acc + Object.values(org.trails).reduce((trailAcc, trail) => trailAcc + (trail?.sessions ? Object.keys(trail.sessions).length : 0), 0); // Updated
                }, 0);
                
                const uniqueUserIds = new Set(); // 05 Aug 2024
                Object.values(organizations).forEach(org => {
                    Object.values(org.trails).forEach(trail => {
                        Object.values(trail.sessions || {}).forEach(session => {
                            session.users.forEach(user => uniqueUserIds.add(user.userId._id));
                        });
                    });
                });
                
                const userCount = uniqueUserIds.size; // 05 Aug 2024
                // console.log('sponsorName:', sponsorName)
                // console.log('sponsorId:', sponsorId)
                return(
                <div key={sponsorIndex} className='m-1 p-1 bg-white rounded'>
                    <div className=' '>
                        {/* Sponsor Header */}
                        {/* <div className='grid grid-cols-11 justify-between items-center m-1 p-1'>
                            <div className='col-span-3 flex justify-start items-center m-1 p-1'>
                                <div onClick={() => handleToggleSponsorDetails(sponsorIndex)} className="mx-2 rounded-full h-7 w-7 hover:bg-white flex justify-center items-center cursor-pointer">{showSponsorDetails[sponsorIndex] ? <MinusIcon className="h-5 w-5 text-orange-600" /> : <PlusIcon className="h-5 w-5 text-orange-600" />}</div>
                                <div>
                                    <User
                                        username={sponsorUsername}
                                        defaultUserPic={userProfileIcon2}
                                    />
                                </div>
                                <div>
                                    <p className="px-2 mx-2 text-gray-600 font-bold">{sponsorName}</p>
                                    <p className='mx-2 text-[10px] text-gray-400 rounded-full inline-block px-2 border bg-white shadow shadow-gray-400'>{sponsorOrgRole} & {sponsorOrgTitle}, <span className='text-orange-600'>{Object.values(organizations)[0]?.name|| 'No Organization'}</span></p>
                                </div>
                            </div>
                            <div className='col-span-2 flex justify-start items-center'>
                                <div><p className='text-[10px] text-gray-500 mx-2 px-2'>Organizations :</p></div>
                                <div>
                                            <BuildingOffice2Icon className='h-5 w-5 text-orange-600 m-1'/>
                                </div>
                                <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50'><p className='text-orange-600 text-[10px]'>{orgCount}</p></div>
                            </div>
                            <div className='col-span-2 flex justify-start items-center'>
                                <div><p className='text-[10px] text-gray-500 mx-2 px-2'>Trails :</p></div>
                                <div>
                                            <ArrowTrendingUpIcon className='h-5 w-5 text-orange-600 m-1'/>
                                </div>
                                <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50'><p className='text-orange-600 text-[10px]'>{trailCount}</p></div>
                            </div>
                            <div className='col-span-2 flex justify-start items-center'>
                                <div><p className='text-[10px] text-gray-500 mx-2 px-2'>Sessions :</p></div>
                                <div>
                                            <CalendarDaysIcon className='h-5 w-5 text-orange-600 m-1'/>
                                </div>
                                <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50'><p className='text-orange-600 text-[10px]'>{sessionCount}</p></div>
                            </div>
                            <div className='col-span-2 flex justify-start items-center'>
                                <div><p className='text-[10px] text-gray-500 mx-2 px-2'>Users :</p></div>
                                <div>
                                            <UserGroupIcon className='h-5 w-5 text-orange-600 m-1'/>
                                </div>
                                <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50'><p className='text-orange-600 text-[10px]'>{userCount}</p></div>
                            </div>
                        </div> */}
                    

                    {/* Sponsor Details */}
                    {showSponsorDetails && Object.entries(organizations)?.map(([orgId, orgData], orgIndex) => {
                        const { name: orgName, trails } = orgData; // Extract orgName and trails
                        const trailCount = Object.keys(trails)?.length;
                        const sessionCount = Object.values(trails).reduce((acc, trail) => acc + (trail?.sessions ? Object.keys(trail.sessions).length : 0), 0); // Ensure sessions is defined
                        const uniqueUserIds = new Set(); // 05 Aug 2024
                        Object.values(trails).forEach(trail => {
                            Object.values(trail?.sessions).forEach(session => {
                                session.users.forEach(user => uniqueUserIds.add(user.userId._id));
                            });
                        });
                        const userCount = uniqueUserIds.size;// 05 Aug 2024
                        return(
                        <div key={orgIndex} className="">
                            {/* Org Header */}
                            <div className=''>
                                <div className='grid grid-cols-10 gap-1 justify-start items-center m-1 p-1'>
                                    <div className='col-span-3 flex justify-start items-center m-1 p-1'>
                                        <div onClick={() => handleToggleOrgs(sponsorIndex, orgIndex)} className="mx-2 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer">{showOrgs[`${sponsorIndex}-${orgIndex}`] ? <MinusIcon className="h-4 w-4 text-orange-600" /> : <PlusIcon className="h-4 w-4 text-orange-600" />}</div>
                                        <div><p className='text-[10px] text-gray-500 mx-1 px-1'>Org:</p></div>
                                        <div>
                                            <BuildingOffice2Icon className='h-5 w-5 text-orange-600 m-1'/>
                                        </div>
                                        <div><p className="font-semibold mx-1 px-1">{orgName}</p></div>
                                    </div>
                                    <div className='col-span-2 flex justify-start items-center m-1 p-1'>
                                        <div><p className='text-[10px] text-gray-500 mx-2 px-2'>Trails :</p></div>
                                        <div>
                                            <ArrowTrendingUpIcon className='h-5 w-5 text-orange-600 m-1'/>
                                        </div>
                                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50'><p className='text-orange-600 text-[10px]'>{trailCount}</p></div>
                                    </div>
                                    <div className='col-span-2 flex justify-start items-center m-1 p-1'>
                                        <div><p className='text-[10px] text-gray-500 mx-2 px-2'>Sessions :</p></div>
                                        <div>
                                            <CalendarDaysIcon className='h-5 w-5 text-orange-600 m-1'/>
                                        </div>
                                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50'><p className='text-orange-600 text-[10px]'>{sessionCount}</p></div>
                                    </div>
                                    <div className='col-span-2 flex justify-start items-center m-1 p-1'>
                                        <div><p className='text-[10px] text-gray-500 mx-2 px-2'>Users :</p></div>
                                        <div>
                                            <UserGroupIcon className='h-5 w-5 text-orange-600 m-1'/>
                                        </div>
                                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50'><p className='text-orange-600 text-[10px]'>{userCount}</p></div>
                                    </div>
                                </div>
                                
                                {/* Org Details */}
                                {showOrgs[`${sponsorIndex}-${orgIndex}`] && Object.entries(trails)?.map(([trailName, trailData], trailIndex) => {
                                    const sessionCount = trailData.sessions ? Object.keys(trailData?.sessions).length : 0; // Ensure sessions is defined
                                    const uniqueUserIds = new Set(); // 05 Aug 2024
                                    Object.values(trailData.sessions || {}).forEach(session => {
                                        session?.users.forEach(user => uniqueUserIds.add(user.userId._id));
                                    });
                                    const userCount = uniqueUserIds.size; // 05 Aug 2024
                                    
                                    return(
                                    <div key={trailIndex} className='bg-gray-100 rounded m-1 p-1'>
                                        {/* Trail Header */}
                                        <div className='grid grid-cols-9 justify-between items-center m-1 p-1'>

                                            <div className='col-span-3 flex justify-start items-center m-1 p-1'>
                                                <div onClick={() => handleToggleTrails(orgIndex, trailIndex)} className="mx-2 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer">{showTrails[`${orgIndex}-${trailIndex}`] ? <MinusIcon className="h-4 w-4 text-orange-600" /> : <PlusIcon className="h-4 w-4 text-orange-600" />}</div>
                                                <div className='mx-2 flex justify-center items-center rounded-full bg-gray-100 hover:bg-white h-8 w-8'>
                                                    <img src={trailData.pic} alt="" className='h-6 w-6' />
                                                </div>
                                                <div><p className="font-semibold">{trailName}</p></div>
                                            </div>
                                            <div className='col-span-2 flex justify-start items-center m-1 p-1'>
                                                <div><p className='text-[10px] text-gray-500 mx-2 px-2'>Sessions :</p></div>
                                                <div>
                                                    <CalendarDaysIcon className='h-5 w-5 text-orange-600 m-1'/>
                                                </div>
                                                <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50'><p className='text-orange-600 text-[10px]'>{sessionCount}</p></div>
                                            </div>
                                            <div className='col-span-2 flex justify-start items-center m-1 p-1'>
                                                <div><p className='text-[10px] text-gray-500 mx-2 px-2'>Users :</p></div>
                                                <div>
                                                    <UserGroupIcon className='h-5 w-5 text-orange-600 m-1'/>
                                                </div>
                                                <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50'><p className='text-orange-600 text-[10px]'>{userCount}</p></div>
                                            </div>
                                        </div>

                                        {showTrails[`${orgIndex}-${trailIndex}`] && Object.entries(trailData.sessions || {})
                                        .sort(([, a], [, b]) => a.sessionSequence - b.sessionSequence) // ✅ 08 Mar 2025 Sorting by sessionSequence
                                        ?.map(([sessionId, sessionData], sessionIndex) => {                                       
                                            const userCount = sessionData.users ? sessionData.users.length : 0; //08 Aug 2024
                                            const trailId = trailData.id
                                            // console.log('sponsorId:', sponsorId)
                                            
                                            return(
                                            <div key={sessionId+sessionIndex} className="m-1 p-1 bg-gray-200 rounded">

                                                <div className='grid grid-cols-12 justify-between items-center m-1 p-1'>
                                                    <div className='col-span-6 flex justify-start items-center'>
                                                        <div onClick={() => handleToggleDetails(trailIndex, sessionIndex)} className="mx-2 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer">{showDetails[`${trailIndex}-${sessionIndex}`] ? <MinusIcon className="h-4 w-4 text-orange-600" /> : <PlusIcon className="h-4 w-4 text-orange-600" />}</div>
                                                        <div className='mx-2 flex justify-center items-center rounded-full bg-gray-100 hover:bg-white h-8 w-8'>
                                                            <img src={sessionData?.sessionPic} alt="" className='h-6 w-6' />
                                                        </div>
                                                        {/* <div><p className="text-xs font-semibold">Session {sessionIndex +1} - {sessionData?.sessionName}</p></div> */}
                                                        <div><p className="text-xs font-semibold">Session {sessionData?.sessionSequence} - {sessionData?.sessionName}</p></div>{/* 08 Mar 2025  */}
                                                    </div>
                                                    <div className='col-span-2 flex justify-start items-center m-1 p-1'>
                                                        <div><p className='text-[10px] text-gray-500 mx-2 px-2'>Users :</p></div>
                                                        <div>
                                                            <UserGroupIcon className='h-5 w-5 text-orange-600 m-1'/>
                                                        </div>
                                                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50'><p className='text-orange-600 text-[10px]'>{userCount}</p></div>
                                                    </div>
                                                    <div className='col-span-4 flex justify-end items-center mx-2'>
                                                        <div className='flex justify-start items-center border border-white cursor-pointer shadow rounded p-1'
                                                        // onClick={() => navigate(`/userMagicHome/sponsor/update/${sponsorId}/${orgId}/${trailId}/${sessionId}`)}
                                                        onClick={() => navigate(`/etapp/sponsor/update/${sponsorId}/${orgId}/${trailId}/${sessionId}`)}
                                                        
                                                        
                                                        >
                                                            <div><p className='mx-1 px-1 text-[10px] text-gray-400'>Go to session</p></div>
                                                            <div className='rounded-full flex justify-center items-center h-6 w-6 hover:bg-transparent'>
                                                                
                                                                <ArrowRightCircleIcon  className="h-6 w-6 text-lime-600 cursor-pointer" />
                                                            </div>
                                                        </div>

                                                        {/* <div className='rounded-full flex justify-center items-center h-6 w-6 hover:bg-white'>
                                                            <TrashIcon onClick={() => navigate(`/sponsorUserPad/delete/${sponsorId}/${orgId}/${trailId}/${sessionId}`)} className="h-4 w-4 text-orange-600 cursor-pointer" />
                                                        </div> */}
                                                    </div>
                                                </div>

                                                <div className="m-1 p-1">
                                                    {showDetails[`${trailIndex}-${sessionIndex}`] && sessionData.users?.map((user, userIndex) => {
                                                        
                                                        return(
                                                        <div key={userIndex} className='m-1 p-1 bg-gray-50 rounded'>
                                                            <div className='flex justify-between items-center m-1 p-1'>
                                                                <div className=' flex justify-start items-center m-1 p-1'>
                                                                    <div className='mx-1 px-1'>
                                                                        <User
                                                                        username={user.username}
                                                                        defaultUserPic={userProfileIcon2}
                                                                    />
                                                                    </div>
                                                                    <div className='mx-1 px-1'>
                                                                        <p className='text-xs text-gray-600 font-bold'>{user.userName}</p>
                                                                        <p className='text-[10px] text-gray-400'>{user.userEmail}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )})}
                                                </div>
                                            </div>
                                        )})}
                                    </div>
                                )})}
                            </div>
                        </div>
                    )})}
                    
                    </div>

                </div>
            )}))}
        </div>
    )
}

const User = ({ username, defaultUserPic }) => {
    const { data: userPic, isFetching: isFetchingUserPic } =
      useGetUserPicQuery(username)
  
    return (
      <div className="flex justify-center items-center h-12 w-12 rounded-full border border-white bg-gradient-to-r from-orange-600 to-orange-400 shadow-lg shadow-black cursor-pointer">
        {/* Display user picture or loading indicator*/}
        {isFetchingUserPic ? (
          <div>Loading...</div>
        ) : userPic ? (
          <img src={userPic} alt="User" className="h-11 w-11  rounded-full" />
        ) : (
          <img src={defaultUserPic} alt="User" className="h-10 w-10 p-1" />
        )}
      </div>
    )
  }


export default EtSponsorUserPadListCompProd