import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const coachUserOrgTrailSessionActionsAdapter = createEntityAdapter({})

const initialState = coachUserOrgTrailSessionActionsAdapter.getInitialState()

export const coachUserOrgTrailSessionActionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCoachUserOrgTrailSessionActions: builder.query({
            query: () => ({
                url: '/coachUserOrgTrailSessionActions/getCoachUserOrgTrailSessionActions',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedCoachUserOrgTrailSessionActions = responseData.map(coachUserOrgTrailSessionAction => {
                    coachUserOrgTrailSessionAction.id = coachUserOrgTrailSessionAction._id
                    return coachUserOrgTrailSessionAction
                })
                return coachUserOrgTrailSessionActionsAdapter.setAll(initialState, loadedCoachUserOrgTrailSessionActions)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'CoachUserOrgTrailSessionAction', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'CoachUserOrgTrailSessionAction', id}))
                    ]
                } else {
                    return [ { type: 'CoachUserOrgTrailSessionAction', id: 'LIST'}]
                }
            },
        }),
        createNewCoachUserOrgTrailSessionAction: builder.mutation({
            query: initialCoachUserOrgTrailSessionAction => ({
                url: '/coachUserOrgTrailSessionActions/createNewCoachUserOrgTrailSessionAction',
                method: 'POST',
                body: {...initialCoachUserOrgTrailSessionAction,}
            }),
            invalidatesTags: [
                { type: 'CoachUserOrgTrailSessionAction', id: "LIST" }
            ] 
        }),
        updateCoachUserOrgTrailSessionAction: builder.mutation({
            query: initialCoachUserOrgTrailSessionAction => ({
                url: '/coachUserOrgTrailSessionActions/updateCoachUserOrgTrailSessionAction',
                method: 'PATCH',
                body: {...initialCoachUserOrgTrailSessionAction,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'CoachUserOrgTrailSessionAction', id: arg.id },
                { type: 'CoachUserOrgTrailSessionAction', id: 'LIST' }
            ],
        }),
        deleteCoachUserOrgTrailSessionAction: builder.mutation({
            query: ({id}) => ({
                url: '/coachUserOrgTrailSessionActions/deleteCoachUserOrgTrailSessionAction',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'CoachUserOrgTrailSessionAction', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetCoachUserOrgTrailSessionActionsQuery,
    useCreateNewCoachUserOrgTrailSessionActionMutation,
    useUpdateCoachUserOrgTrailSessionActionMutation,
    useDeleteCoachUserOrgTrailSessionActionMutation,
} = coachUserOrgTrailSessionActionsApiSlice

// returns the query result object
export const selectCoachUserOrgTrailSessionActionResult = coachUserOrgTrailSessionActionsApiSlice.endpoints.getCoachUserOrgTrailSessionActions.select()

// create memoized selector
const selectCoachUserOrgTrailSessionActionsData = createSelector(
    selectCoachUserOrgTrailSessionActionResult,
    coachUserOrgTrailSessionActionResult => coachUserOrgTrailSessionActionResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCoachUserOrgTrailSessionActions,
    selectById: selectCoachUserOrgTrailSessionActionById,
    selectIds: selectCoachUserOrgTrailSessionActionIds,
} = coachUserOrgTrailSessionActionsAdapter.getSelectors(state => selectCoachUserOrgTrailSessionActionsData(state) ?? initialState)