import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit"
import { apiSlice } from "@/app/api/apiSlice"

const questionSetsAdapter = createEntityAdapter({})

const initialState = questionSetsAdapter.getInitialState()

export const questionSetsApiSlice = apiSlice.injectEndpoints({

    endpoints: builder => ({
        getQuestionSets: builder.query({
            query: () => ({
                url: '/questionsets',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {

                const loadedQuestionSets = responseData.map(questionSet =>{
                    questionSet.id = questionSet._id
                    return questionSet
                })
                return questionSetsAdapter.setAll(initialState, loadedQuestionSets)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'QuestionSet', id: 'LIST'},
                        ...result.ids.map(id => ({type: 'QuestionSet', id}))
                    ]
                } else {
                    return [{type: 'QuestionSet', id: 'LIST'}]
                }
            },
        }),
        addNewQuestionSet: builder.mutation({
         query: initialQuestionSet => ({
            url: '/questionsets',
            method: 'POST',
            body: {...initialQuestionSet,}
         }),
         invalidatesTags: [
            { type: 'QuestionSet', id: "LIST" }
        ]  
        }),
        updateQuestionSet: builder.mutation({
            query: initialQuestionSet => ({
                url: '/questionsets',
                method: 'PATCH',
                body: {...initialQuestionSet,}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'QuestionSet', id: arg.id}
            ]
        }),
        deleteQuestionSet: builder.mutation({
            query: ({id}) => ({
                url: '/questionsets',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg ) => [
                { type: 'QuestionSet', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetQuestionSetsQuery,
    useAddNewQuestionSetMutation,
    useUpdateQuestionSetMutation,
    useDeleteQuestionSetMutation,
} = questionSetsApiSlice

// returns the query result object
export const selectQuestionSetsResult = questionSetsApiSlice.endpoints.getQuestionSets.select()

// create memoized selector
const selectQuestionSetsData = createSelector(
    selectQuestionSetsResult,
    questionSetsResult => questionSetsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllQuestionSets,
    selectById: selectQuestionSetById,
    selectIds: selectQuestionSetIds,
} = questionSetsAdapter.getSelectors(state => selectQuestionSetsData(state) ?? initialState)