import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const userImprintCapabilitiesFunctionsAdapter = createEntityAdapter({})

const initialState = userImprintCapabilitiesFunctionsAdapter.getInitialState()

export const userImprintCapabilitiesFunctionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserImprintCapabilitiesFunctions: builder.query({
            query: () => ({
                url: '/userImprintCapabilitiesFunctions/getUserImprintCapabilitiesFunctions',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserImprintCapabilitiesFunctions = responseData.map(userImprintCapabilitiesFunction => {
                    userImprintCapabilitiesFunction.id = userImprintCapabilitiesFunction._id
                    return userImprintCapabilitiesFunction
                })
                return userImprintCapabilitiesFunctionsAdapter.setAll(initialState, loadedUserImprintCapabilitiesFunctions)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserImprintCapabilitiesFunction', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserImprintCapabilitiesFunction', id}))
                    ]
                } else {
                    return [ { type: 'UserImprintCapabilitiesFunction', id: 'LIST'}]
                }
            },
        }),
        createNewUserImprintCapabilitiesFunction: builder.mutation({
            query: initialUserImprintCapabilitiesFunction => ({
                url: '/userImprintCapabilitiesFunctions/createNewUserImprintCapabilitiesFunction',
                method: 'POST',
                body: {...initialUserImprintCapabilitiesFunction,}
            }),
            invalidatesTags: [
                { type: 'UserImprintCapabilitiesFunction', id: "LIST" }
            ] 
        }),
        updateUserImprintCapabilitiesFunction: builder.mutation({
            query: initialUserImprintCapabilitiesFunction => ({
                url: '/userImprintCapabilitiesFunctions/updateUserImprintCapabilitiesFunction',
                method: 'PATCH',
                body: {...initialUserImprintCapabilitiesFunction,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserImprintCapabilitiesFunction', id: arg.id },
                { type: 'UserImprintCapabilitiesFunction', id: 'LIST' }
            ],
        }),
        deleteUserImprintCapabilitiesFunction: builder.mutation({
            query: ({id}) => ({
                url: '/userImprintCapabilitiesFunctions/deleteUserImprintCapabilitiesFunction',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserImprintCapabilitiesFunction', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserImprintCapabilitiesFunctionsQuery,
    useCreateNewUserImprintCapabilitiesFunctionMutation,
    useUpdateUserImprintCapabilitiesFunctionMutation,
    useDeleteUserImprintCapabilitiesFunctionMutation,
} = userImprintCapabilitiesFunctionsApiSlice

// returns the query result object
export const selectUserImprintCapabilitiesFunctionResult = userImprintCapabilitiesFunctionsApiSlice.endpoints.getUserImprintCapabilitiesFunctions.select()

// create memoized selector
const selectUserImprintCapabilitiesFunctionsData = createSelector(
    selectUserImprintCapabilitiesFunctionResult,
    userImprintCapabilitiesFunctionResult => userImprintCapabilitiesFunctionResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserImprintCapabilitiesFunctions,
    selectById: selectUserImprintCapabilitiesFunctionById,
    selectIds: selectUserImprintCapabilitiesFunctionIds,
} = userImprintCapabilitiesFunctionsAdapter.getSelectors(state => selectUserImprintCapabilitiesFunctionsData(state) ?? initialState)