import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userGratitudeImprintsAdapter = createEntityAdapter({})

const initialState = userGratitudeImprintsAdapter.getInitialState()

export const userGratitudeImprintsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserGratitudeImprints: builder.query({
            query: () => ({
                url: '/userGratitudeImprints/getUserGratitudeImprints',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserGratitudeImprints = responseData.map(userGratitudeImprint => {
                    userGratitudeImprint.id = userGratitudeImprint._id
                    return userGratitudeImprint
                })
                return userGratitudeImprintsAdapter.setAll(initialState, loadedUserGratitudeImprints)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserGratitudeImprint', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserGratitudeImprint', id}))
                    ]
                } else {
                    return [ { type: 'UserGratitudeImprint', id: 'LIST'}]
                }
            },
        }),
        createNewUserGratitudeImprint: builder.mutation({
            query: initialUserGratitudeImprint => ({
                url: '/userGratitudeImprints/createNewUserGratitudeImprint',
                method: 'POST',
                body: {...initialUserGratitudeImprint,}
            }),
            invalidatesTags: [
                { type: 'UserGratitudeImprint', id: "LIST" }
            ] 
        }),
        updateUserGratitudeImprint: builder.mutation({
            query: initialUserGratitudeImprint => ({
                url: '/userGratitudeImprints/updateUserGratitudeImprint',
                method: 'PATCH',
                body: {...initialUserGratitudeImprint,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserGratitudeImprint', id: arg.id },
                { type: 'UserGratitudeImprint', id: 'LIST' }
            ],
        }),
        deleteUserGratitudeImprint: builder.mutation({
            query: ({id}) => ({
                url: '/userGratitudeImprints/deleteUserGratitudeImprint',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserGratitudeImprint', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserGratitudeImprintsQuery,
    useCreateNewUserGratitudeImprintMutation,
    useUpdateUserGratitudeImprintMutation,
    useDeleteUserGratitudeImprintMutation,
} = userGratitudeImprintsApiSlice

// returns the query result object
export const selectUserGratitudeImprintResult = userGratitudeImprintsApiSlice.endpoints.getUserGratitudeImprints.select()

// create memoized selector
const selectUserGratitudeImprintsData = createSelector(
    selectUserGratitudeImprintResult,
    userGratitudeImprintResult => userGratitudeImprintResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserGratitudeImprints,
    selectById: selectUserGratitudeImprintById,
    selectIds: selectUserGratitudeImprintIds,
} = userGratitudeImprintsAdapter.getSelectors(state => selectUserGratitudeImprintsData(state) ?? initialState)