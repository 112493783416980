import React, { useState, useEffect, useMemo } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLightWithPlace from "@/utils/EtContentEditorLightWithPlace"
import { useGetCapabilitiesSoftSkillFunctionAreaTypesQuery, useUpdateCapabilitiesSoftSkillFunctionAreaTypeMutation } from "./capabilitiesSoftSkillFunctionAreaTypesApiSlice"
import { useGetCapabilitiesSoftSkillTypesQuery } from '../capabilitiesSoftSkillTypes/capabilitiesSoftSkillTypesApiSlice' // 14 Nov 2024

const EtUpdateCapabilitiesSoftSkillFunctionAreaType = ({id, setActiveAdminView}) => {
  // Created - 18 Nov 2024
  // Author - Atul Pandey
  // Inherited from functionTypes
  // Updated - 27 Feb 2025 - updated the softSkillFunctionArea with props - { id, setActiveAdminView}
  // Updated - 27 Feb 2025 - switched from navigate to setActiveAdminView

  // 1 - Set Title
  useTitle("Update CapabilitiesSoftSkillFunctionArea Type")

  // 2 - extract the capabilitiesSoftSkillFunctionAreaType Id from the url
  // const { id } = useParams()
  // //console.log("id:", id)

  // 3 - Extract the capabilitiesSoftSkillFunctionAreaType from the query to load the capabilitiesSoftSkillFunctionAreaType data
  const { capabilitiesSoftSkillFunctionAreaType } = useGetCapabilitiesSoftSkillFunctionAreaTypesQuery("CapabilitiesSoftSkillFunctionAreaType", {
    selectFromResult: ({ data }) => ({
      capabilitiesSoftSkillFunctionAreaType: data?.entities[id],
    }),
  })

  //console.log('capabilitiesSoftSkillFunctionArea type:', capabilitiesSoftSkillFunctionAreaType)

  // 4 - Check for returned data
  if (!capabilitiesSoftSkillFunctionAreaType) {
    return <PulseLoader color={"#ea580c"} />
  }

  // 5. Display data
  let content
  content = <EtUpdateCapabilitiesSoftSkillFunctionAreaTypeForm capabilitiesSoftSkillFunctionAreaType={capabilitiesSoftSkillFunctionAreaType} id={id} setActiveAdminView={setActiveAdminView}/> // 27 Feb 2025 - added setActiveAdminView={setActiveAdminView}
  return content
}

const EtUpdateCapabilitiesSoftSkillFunctionAreaTypeForm = ({ capabilitiesSoftSkillFunctionAreaType, id,setActiveAdminView}) =>{
  // 1a - set the variable for navigate
  const navigate = useNavigate()
  //console.log('update form invoked!')
  console.log('capabilitiesSoftSkillFunctionAreaType', capabilitiesSoftSkillFunctionAreaType)

  // 1b - state variables for softSkill types
  // const [selectedFocus, setSelectedFocus] = useState("");
  // const [filteredSoftSkillTypes, setFilteredSoftSkillTypes] = useState([]);
  
  // 2 - set the mutation to create the new section data
  const[updateCapabilitiesSoftSkillFunctionAreaType, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useUpdateCapabilitiesSoftSkillFunctionAreaTypeMutation()

  // 2b - Get SoftSkillTypes Data
  const { data: softSkillTypesData } = useGetCapabilitiesSoftSkillTypesQuery();
  const softSkillTypes = useMemo(() => {
    return softSkillTypesData?.ids?.map(id => softSkillTypesData?.entities[id]) || [];
  }, [softSkillTypesData]);
  console.log('softSkillTypes', softSkillTypes)

  // 2c - Set initial values for selectedFocus and filteredSoftSkillTypes when component mounts
  // useEffect(() => {
  //   if (capabilitiesSoftSkillFunctionAreaType?.capabilitiesSoftSkillType) {
  //     const initialFocus = capabilitiesSoftSkillFunctionAreaType?.capabilitiesSoftSkillType?.capabilitiesSoftSkillFocus;
  //     setSelectedFocus(initialFocus);
      
  //     // Set filtered softSkill types based on the initial focus
  //     setFilteredSoftSkillTypes(
  //       softSkillTypes.filter((softSkill) => softSkill?.capabilitiesSoftSkillFocus === initialFocus)
  //     );

  //     // Set the initial value for capabilitiesSoftSkillType in formData
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       capabilitiesSoftSkillType: capabilitiesSoftSkillFunctionAreaType?.capabilitiesSoftSkillType._id,
  //     }));
  //   }
  // }, [capabilitiesSoftSkillFunctionAreaType, softSkillTypes]);
  


// 3 - set the form data object
const [formData, setFormData] = useState({
  capabilitiesSoftSkillFunctionAreaTypeName: capabilitiesSoftSkillFunctionAreaType.capabilitiesSoftSkillFunctionAreaTypeName,
  capabilitiesSoftSkillFunctionAreaTypeDesc: capabilitiesSoftSkillFunctionAreaType.capabilitiesSoftSkillFunctionAreaTypeDesc,
  aboutMe: capabilitiesSoftSkillFunctionAreaType.aboutMe,
})

//console.log('formData for update:', formData)
useEffect(() => {
  if (capabilitiesSoftSkillFunctionAreaType?.capabilitiesSoftSkillType) {
    setFormData((prevData) => ({
      ...prevData,
      capabilitiesSoftSkillType: capabilitiesSoftSkillFunctionAreaType.capabilitiesSoftSkillType._id,
    }));
  }
}, [capabilitiesSoftSkillFunctionAreaType]);

// 4 - Reset form upon successful submission
useEffect(() => {
  if(isSuccess) {
    setFormData({
      capabilitiesSoftSkillFunctionAreaTypeName: "",
      capabilitiesSoftSkillFunctionAreaTypeDesc: "",
      capabilitiesSoftSkillType: null,
      aboutMe: "",
      pic: "",
    })

   // navigate('/capabilitiesSoftSkillFunctionAreatypes')
   setActiveAdminView('capabilitiesSoftSkillFunctionAreaTypes'); // 27 Feb 2025
  }

},[isSuccess]) // ✅ 27 Feb 2025 - Removed `navigate` since it's no longer used.

// 5 - Form field updates / change handling

const handleChange = (e) => {
  const { name, value, files } = e.target
  if (name === "pic") {
    setFormData(prevState => ({ ...prevState, pic: files[0] }))
  } else {
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }
}

// 6 - softSkillFunctionArea to strip html tags from sections
const stripHtml = (html) => {
  return html.replace(/<\/?[^>]+(>|$)/g, "");
}

// 7 - Form Submit Logic
const handleSubmit = async (e) => {
e.preventDefault()

// Validate that capabilitiesSoftSkillType is selected
if (!formData.capabilitiesSoftSkillType) {
  alert("Please select a SoftSkill Type before submitting.");
  return;
}

// Initialize an object to collect the form data
const capabilitiesSoftSkillFunctionAreaTypeData = {
  capabilitiesSoftSkillFunctionAreaTypeName: formData.capabilitiesSoftSkillFunctionAreaTypeName,
  capabilitiesSoftSkillFunctionAreaTypeDesc: formData.capabilitiesSoftSkillFunctionAreaTypeDesc,
  capabilitiesSoftSkillType: formData.capabilitiesSoftSkillType,
  aboutMe: formData.aboutMe,
}

//console.log('capabilitiesSoftSkillFunctionAreaType Name:',capabilitiesSoftSkillFunctionAreaTypeData.capabilitiesSoftSkillFunctionAreaTypeName)

if (formData.pic) {
  // Convert the image to a base64 string
  const reader = new FileReader()
  reader.onloadend = async () => {
    // Once conversion is done, add the base64 string to your data object
    capabilitiesSoftSkillFunctionAreaTypeData.pic = reader.result

    // Now, send the data object to the server
    try {
      capabilitiesSoftSkillFunctionAreaTypeData.id = id
      console.log("Form submitted.")
      await updateCapabilitiesSoftSkillFunctionAreaType(capabilitiesSoftSkillFunctionAreaTypeData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
  }
  reader.readAsDataURL(formData.pic)
} else {
  // No image, send other data as is
  try {
    capabilitiesSoftSkillFunctionAreaTypeData.id = id
    console.log("Form submitted.")
    await updateCapabilitiesSoftSkillFunctionAreaType(capabilitiesSoftSkillFunctionAreaTypeData).unwrap() // Dispatch the RTK Query mutation
  } catch (error) {
    console.error(error)
  }
}
}

// 8 - Handle Cancel

const handleCancel = () => {
  // navigate("/capabilitiesSoftSkillFunctionAreatypes")
  setActiveAdminView('capabilitiesSoftSkillFunctionAreaTypes'); // 27 Feb 2025
}

// 9 - Handle Clear
const handleClear = () => {
  setFormData({
    capabilitiesSoftSkillFunctionAreaTypeName: "",
    capabilitiesSoftSkillFunctionAreaTypeDesc: "",
    capabilitiesSoftSkillType: null,
    aboutMe: "",
    pic: "",
    })
}

return (
  <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
    {isLoading && <PulseLoader color={"#ea580c"} />}
    {isError && (
      <p className="text-orange-700 font-semi-bold py-2">
        {error?.data?.message}
      </p>
    )}
    <div className="bg-white p-1 m-1 rounded">
      <p className="p-1 m-1 font-bold font-poppins text-xl">Update a CapabilitiesSoftSkillFunctionArea Type</p>

      <div className="bg-gray-200 rounded m-1 p-1">
        <form
          onSubmit={handleSubmit}
          className="m-1 p-1 text-xs font-poppins"
        >

          {/* <div id="focus">
            <select
              name="focus"
              value={selectedFocus}
              onChange={(e) => {
                const newFocus = e.target.value;
                setSelectedFocus(newFocus);
                setFilteredSoftSkillTypes(
                  softSkillTypes?.filter((softSkill) => softSkill?.capabilitiesSoftSkillFocus === newFocus)
                );
                // Reset the selected softSkill type if the focus changes
                setFormData((prevData) => ({ ...prevData, capabilitiesSoftSkillType: "" }));
              }}
              className="w-full rounded p-2 m-1"
            >
              <option value="">Select SoftSkill Focus</option>
              <option value="Business">Business</option>
              <option value="Technology">Technology</option>
            </select>
          </div> */}

          <div id="softSkillType">
            <select
              name="capabilitiesSoftSkillType"
              value={formData.capabilitiesSoftSkillType || ""}
              onChange={(e) => setFormData({ ...formData, capabilitiesSoftSkillType: e.target.value })}
              className="w-full rounded p-2 m-1"
              // disabled={!selectedFocus} // Disable if no focus is selected
            >
              <option value="">Select SoftSkill Type</option>
              {softSkillTypes?.map((softSkill) => (
                <option key={softSkill._id} value={softSkill._id}>
                  {softSkill?.capabilitiesSoftSkillTypeName}
                </option>
              ))}
              {/* {filteredSoftSkillTypes?.map((softSkill) => (
                <option key={softSkill._id} value={softSkill._id}>
                  {softSkill?.capabilitiesSoftSkillTypeName}
                </option>
              ))} */}
            </select>
          </div>


          
          <div id="capabilitiesSoftSkillFunctionAreaTypeName">
            <input
              type="text"
              name="capabilitiesSoftSkillFunctionAreaTypeName"
              placeholder="Enter the capabilitiesSoftSkillFunctionArea type name here"
              value={formData.capabilitiesSoftSkillFunctionAreaTypeName}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div className="bg-white w-full rounded p-2 m-1">
            <EtContentEditorLightWithPlace
              value={formData.capabilitiesSoftSkillFunctionAreaTypeDesc}
              handleChange={(content) =>
                setFormData({ ...formData, capabilitiesSoftSkillFunctionAreaTypeDesc: content })
              }
              placeholderText={
                "Write this capabilitiesSoftSkillFunctionArea type is about or will be used for"
              }
              // toolbarId={'sectionDesc'}
            />
          </div>

          <div id="aboutMe">
            <input
              type="text"
              name="aboutMe"
              placeholder="Add something about the personality about this capabilitiesSoftSkillFunctionArea type"
              value={formData.aboutMe}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="pic">
            <input
              type="file"
              name="pic"
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

         <div id="capabilitiesSoftSkillFunctionAreas" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic
              name={"Update"}
              type={"create"}
              handleClick={handleSubmit}
            />
            <EtBtnFormBasic
              name={"Clear"}
              type={"clear"}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={"Cancel"}
              type={"cancel"}
              handleClick={handleCancel}
            />
          </div>
        </form>
      </div>
    </div>
  </div>
)

}

export default EtUpdateCapabilitiesSoftSkillFunctionAreaType