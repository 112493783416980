import React, {useState, useMemo} from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import useTitle from '../../hooks/useTitle'
import PulseLoader from "react-spinners/PulseLoader"
import { getTimeSince } from '../../utils/EtGetTimeSince' 
import { formatDateTime } from '../../utils/formatDate'
import EtContentEditorLight2 from "../../utils/EtContentEditorLight2"
import EtBtnFormBasic from "../../components/common/buttons/EtBtnFormBasic"
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon,  } from '@heroicons/react/24/outline'
import { userProfileIcon2 } from '../../assets/img/imgAssets'
import {
  RocketLaunchIcon,
  DocumentTextIcon,
  CursorArrowRippleIcon,
  Square3Stack3DIcon , ListBulletIcon, SwatchIcon, 
  ClockIcon, WrenchIcon,
  SunIcon
} from "@heroicons/react/24/solid"
import { useGetUserSessionImaginationsQuery, useUpdateUserSessionImaginationMutation } from './userSessionImaginationsApiSlice'

import { useGetUserChallengeSelfEvalsQuery } from '../userChallengeSelfEvals/userChallengeSelfEvalsApiSlice' // 22 Oct 2024
import { useGetUserChallengeCoachEvalsQuery } from '../userChallengeCoachEvals/userChallengeCoachEvalsApiSlice' // 22 Oct 2024
import { useGetUserChallengeSponsorEvalsQuery } from '../userChallengeSponsorEvals/userChallengeSponsorEvalsApiSlice' // 22 Oct 2024

import EtUserActionListByGroupWithTabsComp from '../userActions/EtUserActionListByGroupWithTabsComp' // 23 Sep 2024
import EtModalCreateUserAction from '../../components/common/modal/EtModalCreateUserAction'
import { useGetUserPicQuery } from "../users/usersApiSlice" // 22 Oct 2024
import { useGetUserActionsQuery } from '../userActions/userActionsApiSlice' // 22 Oct 2024 - to filter and count user actions

// 13 Aug 2024 - Icons for Coach's Inputs
import EtCoachGroupIcon1SVGComp from '@/assets/img/icons/svgComp/EtCoachGroupIcon1SVGComp'
import EtCoachGroupIcon2SVGComp from '@/assets/img/icons/svgComp/EtCoachGroupIcon2SVGComp'
import EtCoachOneOnOneIcon2SVGComp from '@/assets/img/icons/svgComp/EtCoachOneOnOneIcon2SVGComp'

// 22 Oct 2024 - Icons for Sponsor Inputs
import EtSponsorGroupIcon1SVGComp from '@/assets/img/icons/svgComp/EtSponsorGroupIcon1SVGComp'
import EtSponsorOneOnOneIcon1SVGComp from '@/assets/img/icons/svgComp/EtSponsorOneOnOneIcon1SVGComp'
import EtChallengeIcon1SVGComp from '@/assets/img/icons/svgComp/EtChallengeIcon1SVGComp'
import EtModalAddOrUpdateUserChallengeSelfEval from '@/components/common/modal/EtModalAddOrUpdateUserChallengeSelfEval'


const EtUserTrailSessionImaginationCompProd = () => {

  // First Created - 17 Jul 2024
  // Author - Atul Pandey
  // Inherited and refined from EtUserTrailSessionIntrospection
  // Update - 13 Aug 2024 - Added Coach's Notes
  // Update - 15 Aug 2024 - Added the Coach notes directly as ref to user session.
  // Updated - 23 Sep 2024 - Updated Actions Display Comp - ByGroupWithTabs
  // Updated - 22 Oct 2024 - Added all the refinements done to 8848KYC component
  // Updated - 23 Feb 2025 - Copied and Renamed for prod deployment
  

  // 0 - Set page Title
  useTitle('User Session - Imagination')

  // 1 - Extrat userId, TrailId and SessionId from the page url
  const {userId, trailId, sessionId} = useParams()

  // 1a - Initialize showDetails state as an object where key is the organization index
  const [showDetails, setShowDetails] = useState(false)
  
  // 2 - Extract user imagination session data from the DB using RTK query
  const {data: userImaginationSessionData, isLoading, isError, error} = useGetUserSessionImaginationsQuery()
  // console.log('user imagination session data:', userImaginationSessionData)
  // console.log('coach inputs:', coachOrgTrailSessionPadsData)

  // 3a - Filter data for the matching user, trail and session -- 03 Jun 2024 --
  const userSessions = userImaginationSessionData?.entities ? Object.values(userImaginationSessionData?.entities) : [];
  const filteredUserSessionData = userSessions?.find(session => 
    session.userId._id === userId && session.trailId._id === trailId && session.sessionId._id === sessionId
  );
  // console.log('filtered user sessions data', filteredUserSessionData)

  // 3b - Extract userOrgId from filtered user session data - 22 Oct 2024
  const userOrgId = filteredUserSessionData?.userId.userOrgId
  // console.log('User Org Id:', userOrgId)

  // 3c - Extract Coach's Shared All Users Notes from the data - 15 Aug 2024
  const coachAllUsersNotes = filteredUserSessionData?.coachSessionAllUsersNotes || []
  // console.log('coach notes:', coachAllUsersNotes)
  
  // 3d - Extract Coach's Shared User Specific Notes from the data - 15 Aug 2024
  const coachSharedUserNotes = filteredUserSessionData?.coachSessionSharedUserNotes || []
  // console.log('coach user specific notes:', coachSharedUserNotes)

  // 3e - Extract Sponsor's Shared All Users Notes from the data - 22 Oct 2024
  const sponsorAllUsersNotes = filteredUserSessionData?.sponsorSessionAllUsersNotes || []
  // console.log('sponsor notes:', sponsorAllUsersNotes)
  
  // 3f - Extract Sponsor's Shared User Specific Notes from the data - 22 Oct 2024
  const sponsorSharedUserNotes = filteredUserSessionData?.sponsorSessionSharedUserNotes || []
  // console.log('sponsor user specific notes:', sponsorSharedUserNotes)

  // 3g - Extract Challenges planned for the session - 22 Oct 2024
  const sessionChallenges = filteredUserSessionData?.sessionId?.sessionChallenges?.map((item) =>{
    return item.challengeId
  })
  // console.log('Session Challenges', sessionChallenges)

  // 4 - State for Modal -- 11 Jun 2024
  const [isModalOpen, setIsModalOpen] = useState(false)

  // 5 - Function to handle Add Action -- 11 Jun 2024
  const handleAddAction = () => {
      handleOpenModal()
  }

  // 5a. Modal management functions
  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => setIsModalOpen(false)

  // 6 - Create an object for setting inner HTML
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
}

  if (isLoading) return <PulseLoader color={"#ea580c"} />
  if (isError) return <div>Error occurred: {error?.data?.message}</div>

  return (
    <div className="bg-gray-200 p-1 xl:w-5/6 mx-auto my-2 rounded-lg shadow-md shadow-black font-poppins">
      <div className='m-1 p-1 bg-white rounded'>
        {/* Header Bar */}
        <div className='m-1 p-1 bg-gray-300 rounded'>
          <div className='flex justify-between items-center m-1 p-1'>
            {/* Left side */}
            <div className='m-1 p-1 flex justify-start items-center'>
              <div
                className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white m-1 p-1"
                onClick={() => setShowDetails((prev) => !prev)}
              >
                {showDetails ? (
                  <MinusIcon className="h-5 w-5 text-orange-600" />
                ) : (
                  <PlusIcon className="h-5 w-5 text-orange-600" />
                )}
              </div>
              <div className='flex justify-center items-center rounded-full bg-gray-200 hover:bg-white h-7 w-7 m-1 p-1'>
                <img src={filteredUserSessionData?.sessionId.pic} alt="" className='h-5 w-5' />
              </div>
              <div className='m-1 p-1'>
                <p className='text-sm font-bold mx-1'>Session {filteredUserSessionData.userSessionNumber} - {filteredUserSessionData.sessionId.sessionName}</p>
                <p className='text-[10px] m-1'>{filteredUserSessionData.sessionId.sessionDesc}</p>
              </div>
            </div>

            {/* Mid Section - Status */}
            <div className='flex justify-start items-center m-1 p-1'>
              
                {/* <div className='mx-1'><ClockIcon className="h-5 w-5 text-orange-600" /></div> */}
                <div>
                  <p className='text-xs font-bold mx-1'>Duration:</p>
                </div>
              
                <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{filteredUserSessionData?.sessionId?.sessionEstimatedDuration}</p></div>
                <div><p className='text-[8px] text-orange-600 italic'>min</p></div>
            </div>

            {/* Right Side */}
            <div className='flex justify-start items-center m-1 p-1'>
              <div>
                    <RocketLaunchIcon className="h-5 w-5 text-orange-600" />
              </div>
              <div className='m-1 p-1'>
                <p className='text-xs font-bold mx-1'>Started</p>
                <p className='text-[8px] mx-1 italic text-gray-600'>{getTimeSince(filteredUserSessionData?.startedAt)}</p> {/* Display duration from the start of the session */}
              </div>
            </div>

          </div>
          {/* Session Detail when the plus icon is clicked */}
          {showDetails && (
          <div className='bg-white rounded mx-2  my-1'>
          {filteredUserSessionData?.sessionId.sessionIntro && (
         <div className='p-2 grid grid-cols-3 gap-2 justify-center'> 
             <div className='mx-2 border-r  border-orange-200 col-span-1 h-full'>
                 <div className='flex flex-col justify-start'>
                     <div className='flex justify-start items-center  m-1 p-1'>
                         <div className=''><Square3Stack3DIcon className='h-5 w-5 text-orange-600' /></div>
                         <p className='m-1 p-1 text-xs text-gray-400 font-bold border-b-2 border-orange-600'>Session <span className='text-orange-600'>Detail</span></p>
                     </div>
                     <p dangerouslySetInnerHTML={createMarkup(filteredUserSessionData?.sessionId?.sessionIntro)} className='text-[10px] text-gray-600'></p>
                 </div>
             </div>

             <div className='mx-2 col-span-1 border-r border-orange-200 h-full'>
                 <div className='flex flex-col justify-start'>
                     <div className='flex justify-start items-center  m-1 p-1'>
                         <div className=''><ListBulletIcon className='h-5 w-5 text-orange-600' /></div>
                         <p className='m-1 p-1 text-xs text-gray-400 font-bold border-b-2 border-orange-600'>Session <span className='text-orange-600'>Prework</span></p>
                     </div>

                     {filteredUserSessionData?.sessionId.sessionPreWork && filteredUserSessionData?.sessionId.sessionPreWork?.map((item,preWorkIndex) => (
                     <div key={preWorkIndex} className='rounded m-1 p-1 bg-gray-100'>
                         <p className='text-[10px] mx-1'><span className='font-bold'></span>{item.prework}</p>
                     </div>
                     ))}
                 </div>
             </div>

             <div className='mx-2 col-span-1'>
                 <div className='flex flex-col justify-start'>
                     <div className='flex justify-start items-center m-1 p-1'>
                     <div className=''><SwatchIcon className='h-5 w-5 text-orange-600' /></div>
                         <p className='m-1 p-1 text-xs text-gray-400 font-bold border-b-2 border-orange-600'>Session <span className='text-orange-600'>Outcomes</span></p>
                     </div>

                     {filteredUserSessionData?.sessionId?.sessionOutcomes && filteredUserSessionData?.sessionId.sessionOutcomes?.map((item,outIndex) => (
                     <div key={outIndex} className='rounded m-1 p-1 bg-gray-100'>
                         <p className='text-[10px] mx-1'>{item?.outcome}</p>
                     </div>
                     ))}
                 </div>
             </div>
         </div>)}
     </div>
          )}
        </div> {/* End of Header Bar */}

        {/* Coach's inputs - TBD */}

        {/* Input Form  */}
        <EtUpdateUserSessionForm userId={userId} trailId={trailId} sessionId={sessionId} userOrgId={userOrgId} filteredUserSessionData={filteredUserSessionData} handleAddAction={handleAddAction} coachAllUsersNotes={coachAllUsersNotes} coachSharedUserNotes={coachSharedUserNotes} sponsorAllUsersNotes={sponsorAllUsersNotes} sponsorSharedUserNotes={sponsorSharedUserNotes} sessionChallenges={sessionChallenges}/>

      </div>
      <EtModalCreateUserAction isOpen={isModalOpen} onClose={handleCloseModal} userId={userId} trailId={trailId} sessionId={sessionId} sessionTypeName={filteredUserSessionData?.sessionId.sessionTypeId?.sessionTypeName}/>
    </div>
  )
}

const EtUpdateUserSessionForm = ({userId, trailId, sessionId, userOrgId, filteredUserSessionData, handleAddAction, coachAllUsersNotes, coachSharedUserNotes, sponsorAllUsersNotes,sponsorSharedUserNotes,sessionChallenges}) => {

  // First Created - 04 Jun 2024
  // Author - Atul Pandey
  // Updated - 11 Jun 2024 - handleAddAction Modal
  // Updated - 13 Aug 2024 - Coach's Inputs and show details
  // Updated - 15 Aug 2024 - Refined data structure for JSX based on incoming data from user session query
  // Updated - 22 Oct 2024 - Refined to be in line with 8848KYC (reference)

  // 0 - Define and declare Navigation and state variables
  const navigate = useNavigate()
  const [imaginationSessionNotes, setImaginationSessionNotes] = useState(filteredUserSessionData?.imaginationSessionNotes)
  const [isEditing, setIsEditing] = useState(false) // 14 Jun 2024 - for show / edit editor
  const [showCoachSectionDetails, setShowCoachSectionDetails] = useState(false) // 13 Aug 2024
  const [showCoachAllUsersNotes, setShowCoachAllUsersNotes] = useState(false) // 13 Aug 2024
  const [showCoachSelfUserNotes, setShowCoachSelfUserNotes] = useState(false) // 13 Aug 2024
  const [showSponsorSectionDetails, setShowSponsorSectionDetails] = useState(false) // 22 Oct 2024
  const [showSponsorAllUsersNotes, setShowSponsorAllUsersNotes] = useState(false) // 22 Oct 2024
  const [showSponsorSelfUserNotes, setShowSponsorSelfUserNotes] = useState(false) // 22 Oct 2024
  const [showSessionNotes, setShowSessionNotes] = useState(false) // 22 Oct 2024
  const [showSessionActions, setShowSessionActions] = useState(false) // 22 Oct 2024

  const [showCoachAllUsersNotesDetail,setShowCoachAllUsersNotesDetail ] = useState({}) // 22 Oct 2024 - for toggling coach all users note detail
  const [showCoachSelfUserNotesDetail,setShowCoachSelfUserNotesDetail] = useState({}) // 22 Oct 2024 - for toggling coach specific user note detail
  
  const [showSponsorAllUsersNotesDetail,setShowSponsorAllUsersNotesDetail ] = useState({}) // 22 Oct 2024 - for toggling coach all users note detail
  const [showSponsorSelfUserNotesDetail,setShowSponsorSelfUserNotesDetail] = useState({}) // 22 Oct 2024 - for toggling coach specific user note detail
  const [showSessionNotesDetail, setShowSessionNotesDetail] = useState({}) // 22 Oct 2024 - To toggle show / hide note detail
  // console.log('imaginationSessionNotes', imaginationSessionNotes)
  
  // 1a - Define the RTK mutation to update backend data
  const [updateUserSessionImagination] = useUpdateUserSessionImaginationMutation()

  // 1b - Extract User Actions Data using RTK query to filter and count the user actions for the session - 22 Oct 2024
  const {data: userActionsData} = useGetUserActionsQuery() // Extract data in entities adapter
  const userActions = userActionsData?.ids?.map((id) => {  // Extract and separate the data inside entities adapter
    const userAction = userActionsData?.entities[id]
    return userAction
  })
  const filteredUserActions = userActions?.filter((action) => 
    action.userId._id === userId &&
    action.trailId._id === trailId &&
    action.sessionId._id === sessionId
  )
 
  const userActionsCount = filteredUserActions?.length
  // console.log('userActions count:', userActionsCount)

  // 1c - Editor Value setting based on incoming data -- 13 Jun 2024
  const [editorValue, setEditorValue] = useState(() => {
  const latestNote = filteredUserSessionData?.imaginationSessionNotes?.slice(-1)[0];
  return latestNote ? latestNote.imaginationNoteText : '';
  });

  // 1d - Current Note Id of the note currently in editor -- 14 Jun 2024
  const [currentNoteId, setCurrentNoteId] = useState(() => {
    const latestNote = filteredUserSessionData?.imaginationSessionNotes?.slice(-1)[0];
    return latestNote ? latestNote._id : null;
  });

  // 1e - Extract Self User Data - to be passed to User component to get picture - 22 Oct 2024
  const selfUserData = filteredUserSessionData?.userId
  // console.log('filtered user data', selfUserData)

  // 1f - Handle Editor Value change
  const handleEditorChange = (value) => {
    setEditorValue(value);
  };
 
  // 2 - Form submit
  const handleSave = async () => {
    try {
        let updatedNotes;
        if (currentNoteId) {
          // Update existing note
          updatedNotes = imaginationSessionNotes.map(note =>
            note._id === currentNoteId ? { ...note, imaginationNoteText: editorValue, imaginationNoteUpdateDate: new Date() } : note
          );
        } else {
          // Add new note
          const newNote = {
            imaginationNoteText: editorValue,
            imaginationNoteDate: new Date(),
            imaginationNoteUpdateDate: new Date(),
          };
          updatedNotes = [...imaginationSessionNotes, newNote];
        }
  
      await updateUserSessionImagination({
        userId,
        trailId,
        sessionId,
        imaginationSessionNotes: updatedNotes,
      }).unwrap();
  
      // navigate('/userMagicHome')
      navigate('/etapp') // 23 Feb 2025
    } catch (error) {
      console.error('Failed to update the user session imagination:', error);
      // You can add an error message or any other logic here in case of a failure
    }
  }; 

  // 2a - Function to handle saving new Note -- 14 Jun 2024 --
  const handleSaveNote = async () => {
    if (editorValue.trim() === '') {
      alert('The editor is blank. Please enter some text before saving.');
      return;
    }
  
    try {
        let updatedNotes;
        if (currentNoteId) {
          // Update existing note
          updatedNotes = imaginationSessionNotes.map(note =>
            note._id === currentNoteId ? { ...note, imaginationNoteText: editorValue, imaginationNoteUpdateDate: new Date() } : note
          );
        } else {
          // Add new note
          const newNote = {
            imaginationNoteText: editorValue,
            imaginationNoteDate: new Date(),
            imaginationNoteUpdateDate: new Date(),
          };
          updatedNotes = [...imaginationSessionNotes, newNote];
        }
  
      await updateUserSessionImagination({
        userId,
        trailId,
        sessionId,
        imaginationSessionNotes: updatedNotes,
      }).unwrap();
  
      setEditorValue('');
      setIsEditing(false);
      alert('Notes updated successfully');
      window.location.reload();
    } catch (error) {
      console.error('Failed to update the user session imagination:', error);
      // You can add an error message or any other logic here in case of a failure
    }
  };
  
  // 3 - Handle Clear
  const handleClear = () => {
    setImaginationSessionNotes(filteredUserSessionData?.imaginationSessionNotes)
  }

  // 4 - Handle Cancel
  const handleCancel = () => {
    // navigate('/userMagicHome')
    navigate('/etapp') // 23 Feb 2025
  }

  // 5 - Create an object for setting inner HTML
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
  }

  // 6 - Function to handle Add New Note -- 14 Jun 2024 --
  const handleAddNote = () => {
    // Clear editor and switch to edit mode
    setEditorValue('');
    setCurrentNoteId(null); // Clear the currentNoteId to treat it as a new entry
    setIsEditing(true);
   };

  // 7 - Function to handle editing a note -- 14 Jun 2024 --
  const handleEditNote = (note) => {
    setEditorValue(note.imaginationNoteText);
    setCurrentNoteId(note._id);
    setIsEditing(true);
  };

  // 7a - Function to handle editing a note -- 14 Jun 2024 --
  const handleCancelEditNote = (note) => {
    setEditorValue('');
    setCurrentNoteId(null);
    setIsEditing(false);
  };
 
  // 8 - Function to handle deleting a note -- 14 Jun 2024 --
  const handleDeleteNote = async (noteId) => {
    try {
      const updatedNotes = imaginationSessionNotes?.filter(note => note._id !== noteId);
      
      await updateUserSessionImagination({
        userId,
        trailId,
        sessionId,
        imaginationSessionNotes: updatedNotes,
      }).unwrap();
      
      setImaginationSessionNotes(updatedNotes); // Update the state with the filtered notes
      alert('Note deleted successfully');
    } catch (error) {
      console.error('Failed to delete the note:', error);
      // Add error handling logic here if needed
    }
  };
  
  // 9 - Calculate the number of coaches notes - 13 Aug 2024
  const allUsersNoteCount = coachAllUsersNotes?.length
  const sharedUserNoteCount = coachSharedUserNotes?.length
  // console.log('allUsersNoteCount :', allUsersNoteCount)
  // console.log('sharedUserNoteCount :', sharedUserNoteCount)

  // 10 - Calculate the number of sponsors' notes - 22 Oct 2024
  const allUsersSponsorNoteCount = sponsorAllUsersNotes?.length
  const sharedUserSponsorNoteCount = sponsorSharedUserNotes?.length
  // console.log('allUsersSponsorNoteCount :', allUsersSponsorNoteCount)
  // console.log('sharedUserSponsorNoteCount :', sharedUserSponsorNoteCount)

  // 11a - Handle Toggle showCoachAllUsersNotesDetail - 22 Oct 2024
  const handleToggleShowCoachAllUsersNotesDetail = (noteId) => {
    setShowCoachAllUsersNotesDetail((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
  }
  
  // 11b - Handle Toggle showCoachSelfUserNotesDetail - 22 Oct 2024
  const handleToggleShowCoachSelfUserNotesDetail = (noteId) => {
    setShowCoachSelfUserNotesDetail((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
  }

  // 11c - Handle Toggle showSponsorAllUsersNotesDetail - 22 Oct 2024
  const handleToggleShowSponsorAllUsersNotesDetail = (noteId) => {
    setShowSponsorAllUsersNotesDetail((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
  }
  
  // 11d - Handle Toggle showSponsorSelfUserNotesDetail - 22 Oct 2024
  const handleToggleShowSponsorSelfUserNotesDetail = (noteId) => {
    setShowSponsorSelfUserNotesDetail((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
  }

  // 11e - Handle Toggle show session UserNotesDetail - 22 Oct 2024
  const handleToggleShowSessionNotesDetail = (noteId) => {
    setShowSessionNotesDetail((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
  }

  return (
    <div className="bg-gray-300 p-1 m-1 rounded font-poppins">
      
      {/* 19 Aug 2024 - Session Challenges -- 22 Oct 2024*/}
      <EtSessionChallengesSection sessionChallenges={sessionChallenges} userId={userId} trailId={trailId} sessionId={sessionId} userOrgId={userOrgId} selfUserData={selfUserData}/>

      {/* 15 Aug 2024 - Coach's notes - 22 Oct 2024 */}
      <div className='m-1 p-1 border border-white rounded'>

          {/* Coach Inputs Header Block */}
          <div className='m-1 p-1 grid grid-cols-5 justify-start items-center'>
            <div className='col-span-2 flex justify-start items-center'>
              <div
                className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white m-1 p-1"
                onClick={() => setShowCoachSectionDetails((prev) => !prev)}
              >
                {showCoachSectionDetails ? (
                  <MinusIcon className="h-5 w-5 text-orange-600" />
                ) : (
                  <PlusIcon className="h-5 w-5 text-orange-600" />
                )}
              </div>
                <div className='rounded-full w-7 h-7 flex justify-center items-center bg-gray-200 hover:bg-gray-50  m-1 p-1'><EtCoachGroupIcon1SVGComp className='w-5 h-5 text-orange-600'/>
                </div>
                <div>
                  <p className='font-bold mx-1 px-1'>Coach's Notes </p>
                  <p className='text-[10px] italic text-gray-600 mx-1'>See what your coaches have to say.. </p>
                </div>
              </div>
            
            <div className='col-span-1'>
              <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'>
                <p className='text-orange-600 text-[10px]'>{allUsersNoteCount + sharedUserNoteCount}</p>
              </div>
            </div>
          </div>

          {showCoachSectionDetails && (
            <div>
                {/* All Users Notes */}
              <div className='m-1 p-1 bg-gray-100 rounded'>
                  
                  <div className='m-1 p-1 flex justify-start items-center'>
                  <div
                      className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white m-1 p-1"
                      onClick={() => setShowCoachAllUsersNotes((prev) => !prev)}
                    >
                      {showCoachAllUsersNotes ? (
                        <MinusIcon className="h-5 w-5 text-orange-600" />
                      ) : (
                        <PlusIcon className="h-5 w-5 text-orange-600" />
                      )}
                    </div>
                    <div className='rounded-full w-7 h-7 flex justify-center items-center bg-gray-200 hover:bg-gray-50'><EtCoachGroupIcon2SVGComp className='w-7 h-7 text-orange-600'/></div>
                    <div><p className='text-sm text-gray-600 font-bold mx-1 px-1'>For Everyone:</p></div>
                    <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{allUsersNoteCount}</p></div>
                  </div>

                  {allUsersNoteCount !== 0 && showCoachAllUsersNotes && (
                      <div className='m-1 p-1 bg-gray-300 rounded'>
                        {coachAllUsersNotes && coachAllUsersNotes?.slice()
                            .sort((a, b) => new Date(b.coachAllUsersNotesId.coachAllUsersNoteDate) - new Date(a.coachAllUsersNotesId.coachAllUsersNoteDate)).map((note, noteIndex, arr) =>(
                          
                          <div key={note._id+ noteIndex} className='m-1 p-1 bg-white rounded'>

                              <div className='grid grid-cols-6 justify-between items-center m-1 p-1 bg-gray-100 rounded'>
                                
                                <div className='col-span-1 flex justify-start items-center'>
                                  <div
                                      onClick={() => handleToggleShowCoachAllUsersNotesDetail(note._id)}
                                      className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
                                    >
                                      {showCoachAllUsersNotesDetail[note._id] ? (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                      ) : (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                      )}
                                    </div>
                                    <div>
                                      {/* <p className='text-2xl font-bold mx-1 px-1 text-gray-600'>{arr.length - noteIndex}.</p> */}
                                      <p className='text-2xl font-bold mx-1 px-1 text-gray-600'>{noteIndex +1}.</p>
                                    </div> 
                                    <div>
                                      <p className='text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-18'>{getTimeSince(note?.coachAllUsersNotesId.coachAllUsersNoteUpdateDate)}</p>
                                      <p className='text-[7px] mx-1 italic px-2 py-1 text-gray-400'>last updated</p>
                                  </div>  
                                </div>
                                <div className='col-span-3 flex justify-start items-center'>
                                  <div dangerouslySetInnerHTML={createMarkup(note?.coachAllUsersNotesId.coachAllUsersNoteText)} className='text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1'></div>
                                </div>
                                <div className='col-span-2 flex justify-start items-center mx-2'>
                                    <ClockIcon className='h-5 w-5 text-lime-600'/>
                                    <p className='text-[9px] mx-1'>First Created:</p>
                                    <p className='text-gray-400 text-[9px] mx-2'>{formatDateTime(note?.coachAllUsersNotesId.coachAllUsersNoteDate)}</p>
                                </div>
                              </div>
                              {showCoachAllUsersNotesDetail[note._id] && (
                                <div className="m-1 p-1 rounded bg-gray-50">
                                <div
                                  dangerouslySetInnerHTML={createMarkup(note?.coachAllUsersNotesId.coachAllUsersNoteText)}
                                  className="text-[11px] text-gray-700 m-1 p-1"
                                ></div>
                                </div>

                              )}

                          </div>
                        ))}
                      </div>
                  )}
                  {/* When no coach notes are available */}
                  {allUsersNoteCount === 0 && showCoachAllUsersNotes && (
                    <div className="m-1 p-1 bg-white rounded">
                    <p className='text-orange-600 text-xs m-1 p-1'>No coach notes are available yet.</p>
                  </div>
                  )}
              </div>
              {/* Self User Notes */}
              <div className='m-1 p-1 bg-gray-100 rounded'>
                  
                  <div className='m-1 p-1 flex justify-start items-center'>
                  <div
                      className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white m-1 p-1"
                      onClick={() => setShowCoachSelfUserNotes((prev) => !prev)}
                    >
                      {showCoachSelfUserNotes ? (
                        <MinusIcon className="h-5 w-5 text-orange-600" />
                      ) : (
                        <PlusIcon className="h-5 w-5 text-orange-600" />
                      )}
                    </div>
                    <div className='rounded-full w-7 h-7 flex justify-center items-center bg-gray-200 hover:bg-gray-50'><EtCoachOneOnOneIcon2SVGComp className='w-7 h-7 text-orange-600'/></div>
                    <div><p className='text-sm text-gray-600 font-bold mx-1 px-1'>For Me:</p></div>
                    <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{sharedUserNoteCount}</p></div>
                  </div>

                  {sharedUserNoteCount !== 0 && showCoachSelfUserNotes && (
                      <div className='m-1 p-1 bg-gray-300 rounded'>
                        {coachSharedUserNotes && coachSharedUserNotes?.slice()
                            .sort((a, b) => new Date(b.coachSharedUserNotesId.coachSharedUserNoteDate) - new Date(a.coachSharedUserNotesId.coachSharedUserNoteDate)).map((note, noteIndex, arr) =>(
                          
                          <div key={note._id+ noteIndex} className='m-1 p-1 bg-white rounded'>
                              <div className='grid grid-cols-6 justify-between items-center m-1 p-1 bg-gray-100 rounded'>
                                
                                <div className='col-span-1 flex justify-start items-center'>
                                  <div
                                    onClick={() => handleToggleShowCoachSelfUserNotesDetail(note._id)}
                                    className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
                                  >
                                    {showCoachSelfUserNotesDetail[note._id] ? (
                                      <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                      <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                    </div>
                                    <div>
                                      {/* <p className='text-2xl font-bold mx-1 px-1 text-gray-600'>{arr.length - noteIndex}.</p> */}
                                      <p className='text-2xl font-bold mx-1 px-1 text-gray-600'>{noteIndex +1}.</p>
                                      </div> 
                                    <div>
                                      <p className='text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-18'>{getTimeSince(note?.coachSharedUserNotesId.coachSharedUserNoteUpdateDate)}</p>
                                      <p className='text-[7px] mx-1 italic px-2 py-1 text-gray-400'>last updated</p>
                                  </div>  
                                </div>
                                <div className='col-span-3 flex justify-start items-center'>
                                  <div dangerouslySetInnerHTML={createMarkup(note?.coachSharedUserNotesId.coachSharedUserNoteText)} className='text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1'></div>
                                </div>
                                <div className='col-span-2 flex justify-start items-center mx-2'>
                                    <ClockIcon className='h-5 w-5 text-lime-600'/>
                                    <p className='text-[9px] mx-1'>First Created:</p>
                                    <p className='text-gray-400 text-[9px] mx-2'>{formatDateTime(note?.coachSharedUserNotesId.coachSharedUserNoteDate)}</p>
                                </div>
                              </div>
                              {showCoachSelfUserNotesDetail[note._id] && (
                                <div className="m-1 p-1 rounded bg-gray-50">
                                <div
                                  dangerouslySetInnerHTML={createMarkup(note?.coachSharedUserNotesId.coachSharedUserNoteText)}
                                  className="text-[11px] text-gray-700 m-1 p-1"
                                ></div>
                                </div>

                              )}
                          </div>
                        ))}
                      </div>
                  )}
                  {/* When no coach notes are available */}
                  {sharedUserNoteCount === 0 && showCoachSelfUserNotes && (
                    <div className="m-1 p-1 bg-white rounded">
                    <p className='text-orange-600 text-xs m-1 p-1'>No coach notes are available yet.</p>
                  </div>
                  )}
              </div>
            </div>
          )}  

      </div>

      {/* 12 Sep 2024 - Sponsor's notes - 22 Oct 2024*/}
      <div className='m-1 p-1 border border-white rounded'>

        {/* Sponsor Inputs Header Block 14 Sep 2024 - changed flex to grid*/}
        <div className='m-1 p-1 grid grid-cols-5 justify-start items-center'>
          <div className='col-span-2 flex justify-start items-center'>
            <div
              className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white m-1 p-1"
              onClick={() => setShowSponsorSectionDetails((prev) => !prev)}
            >
              {showSponsorSectionDetails ? (
                <MinusIcon className="h-5 w-5 text-orange-600" />
              ) : (
                <PlusIcon className="h-5 w-5 text-orange-600" />
              )}
            </div>
            <div className='rounded-full w-7 h-7 flex justify-center items-center bg-gray-200 hover:bg-gray-50  m-1 p-1'><EtSponsorGroupIcon1SVGComp className='w-5 h-5 text-orange-600'/>
            </div>
            <div>
              <p className='font-bold mx-1 px-1'>Sponsor's Notes </p>
              <p className='text-[10px] italic text-gray-600 mx-1'>See what your sponsors have to say.. </p>
            </div>
          </div>
          
          <div className='col-span-1'>
            <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'>
              <p className='text-orange-600 text-[10px]'>{allUsersSponsorNoteCount + sharedUserSponsorNoteCount}</p>
            </div>
          </div>
            </div>

            {showSponsorSectionDetails && (
          <div>
              {/* All Users Sponsor Notes */}
            <div className='m-1 p-1 bg-gray-100 rounded'>
                
                <div className='m-1 p-1 flex justify-start items-center'>
                <div
                    className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white m-1 p-1"
                    onClick={() => setShowSponsorAllUsersNotes((prev) => !prev)}
                  >
                    {showSponsorAllUsersNotes ? (
                      <MinusIcon className="h-5 w-5 text-orange-600" />
                    ) : (
                      <PlusIcon className="h-5 w-5 text-orange-600" />
                    )}
                  </div>
                  <div className='rounded-full w-7 h-7 flex justify-center items-center bg-gray-200 hover:bg-gray-50'><EtCoachGroupIcon2SVGComp className='w-7 h-7 text-orange-600'/></div>
                  <div><p className='text-sm text-gray-600 font-bold mx-1 px-1'>For Everyone:</p></div>
                  <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{allUsersSponsorNoteCount}</p></div>
                </div>

                {allUsersSponsorNoteCount !== 0 && showSponsorAllUsersNotes && (
                    <div className='m-1 p-1 bg-gray-300 rounded'>
                      {sponsorAllUsersNotes && sponsorAllUsersNotes?.slice()
                          .sort((a, b) => new Date(b.sponsorAllUsersNotesId.sponsorAllUsersNoteDate) - new Date(a.sponsorAllUsersNotesId.sponsorAllUsersNoteDate)).map((note, noteIndex, arr) =>(
                        
                        <div key={note._id+ noteIndex} className='m-1 p-1 bg-white rounded'>
                            <div className='grid grid-cols-6 justify-between items-center m-1 p-1 bg-gray-100 rounded'>
                              
                              <div className='col-span-1 flex justify-start items-center'>
                                  <div
                                    onClick={() => handleToggleShowSponsorAllUsersNotesDetail(note._id)}
                                    className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
                                  >
                                    {showSponsorAllUsersNotesDetail[note._id] ? (
                                      <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                      <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                  </div>
                                  <div>
                                    {/* <p className='text-2xl font-bold mx-1 px-1 text-gray-600'>{arr.length - noteIndex}.</p> */}
                                    <p className='text-2xl font-bold mx-1 px-1 text-gray-600'>{noteIndex +1}.</p>
                                  </div>  
                                <div>
                                    <p className='text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-18'>{getTimeSince(note?.sponsorAllUsersNotesId.sponsorAllUsersNoteUpdateDate)}</p>
                                    <p className='text-[7px] mx-1 italic px-2 py-1 text-gray-400'>last updated</p>
                                </div>  
                              </div>
                              <div className='col-span-3 flex justify-start items-center'>
                                <div dangerouslySetInnerHTML={createMarkup(note?.sponsorAllUsersNotesId.sponsorAllUsersNoteText)} className='text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1'></div>
                              </div>
                              <div className='col-span-2 flex justify-start items-center mx-2'>
                                  <ClockIcon className='h-5 w-5 text-lime-600'/>
                                  <p className='text-[9px] mx-1'>First Created:</p>
                                  <p className='text-gray-400 text-[9px] mx-2'>{formatDateTime(note?.sponsorAllUsersNotesId.sponsorAllUsersNoteDate)}</p>
                              </div>
                            </div>
                            {showSponsorAllUsersNotesDetail[note._id] && (
                              <div className="m-1 p-1 rounded bg-gray-50">
                              <div
                                dangerouslySetInnerHTML={createMarkup(note?.sponsorAllUsersNotesId.sponsorAllUsersNoteText)}
                                className="text-[11px] text-gray-700 m-1 p-1"
                              ></div>
                              </div>
                            )}
                        </div>
                      ))}
                    </div>
                )}
                {/* When no sponsor notes are available */}
                {allUsersSponsorNoteCount === 0 && showSponsorAllUsersNotes && (
                  <div className="m-1 p-1 bg-white rounded">
                  <p className='text-orange-600 text-xs m-1 p-1'>No sponsor notes are available yet.</p>
                </div>
                )}
            </div>
            {/* Self User Sponsor Notes */}
            <div className='m-1 p-1 bg-gray-100 rounded'>
                
                <div className='m-1 p-1 flex justify-start items-center'>
                <div
                    className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white m-1 p-1"
                    onClick={() => setShowSponsorSelfUserNotes((prev) => !prev)}
                  >
                    {showSponsorSelfUserNotes ? (
                      <MinusIcon className="h-5 w-5 text-orange-600" />
                    ) : (
                      <PlusIcon className="h-5 w-5 text-orange-600" />
                    )}
                  </div>
                  <div className='rounded-full w-7 h-7 flex justify-center items-center bg-gray-200 hover:bg-gray-50'><EtSponsorOneOnOneIcon1SVGComp className='w-7 h-7 text-orange-600'/></div>
                  <div><p className='text-sm text-gray-600 font-bold mx-1 px-1'>For Me:</p></div>
                  <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{sharedUserSponsorNoteCount}</p></div>
                </div>

                {sharedUserSponsorNoteCount !== 0 && showSponsorSelfUserNotes && (
                    <div className='m-1 p-1 bg-gray-300 rounded'>
                      {sponsorSharedUserNotes && sponsorSharedUserNotes?.slice()
                          .sort((a, b) => new Date(b.sponsorSharedUserNotesId.sponsorSharedUserNoteDate) - new Date(a.sponsorSharedUserNotesId.sponsorSharedUserNoteDate)).map((note, noteIndex, arr) =>(
                        
                        <div key={note._id+ noteIndex} className='m-1 p-1 bg-white rounded'>
                            <div className='grid grid-cols-6 justify-between items-center m-1 p-1 bg-gray-100 rounded'>
                              
                              <div className='col-span-1 flex justify-start items-center'>
                                  <div
                                  onClick={() => handleToggleShowSponsorSelfUserNotesDetail(note._id)}
                                  className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
                                  >
                                  {showSponsorSelfUserNotesDetail[note._id] ? (
                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                  ) : (
                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                  )}
                                  </div>
                                  <div>
                                    {/* <p className='text-2xl font-bold mx-1 px-1 text-gray-600'>{arr.length - noteIndex}.</p> */}
                                    <p className='text-2xl font-bold mx-1 px-1 text-gray-600'>{noteIndex +1}.</p>
                                    </div> 
                                <div>
                                    <p className='text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-18'>{getTimeSince(note?.sponsorSharedUserNotesId.sponsorSharedUserNoteUpdateDate)}</p>
                                    <p className='text-[7px] mx-1 italic px-2 py-1 text-gray-400'>last updated</p>
                                </div>  
                              </div>
                                <div className='col-span-3 flex justify-start items-center'>
                                <div dangerouslySetInnerHTML={createMarkup(note?.sponsorSharedUserNotesId.sponsorSharedUserNoteText)} className='text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1'></div>
                                </div>
                                <div className='col-span-2 flex justify-start items-center mx-2'>
                                  <ClockIcon className='h-5 w-5 text-lime-600'/>
                                  <p className='text-[9px] mx-1'>First Created:</p>
                                  <p className='text-gray-400 text-[9px] mx-2'>{formatDateTime(note?.sponsorSharedUserNotesId.sponsorSharedUserNoteDate)}</p>
                                </div>
                            </div>
                            {showSponsorSelfUserNotesDetail[note._id] && (
                              <div className="m-1 p-1 rounded bg-gray-50">
                              <div
                                dangerouslySetInnerHTML={createMarkup(note?.sponsorSharedUserNotesId.sponsorSharedUserNoteText)}
                                className="text-[11px] text-gray-700 m-1 p-1"
                              ></div>
                              </div>

                            )}
                        </div>
                      ))}
                    </div>
                )}
                {/* When no sponsor notes are available */}
                {sharedUserSponsorNoteCount === 0 && showSponsorSelfUserNotes && (
                  <div className="m-1 p-1 bg-white rounded">
                  <p className='text-orange-600 text-xs m-1 p-1'>No sponsor notes are available yet.</p>
                </div>
                )}
            </div>
          </div>
            )}    

      </div>

      {/* 14 Jun 2024 Session Notes Updates - 22 Oct 2024*/}
      <div className='m-1 p-1 border border-white rounded'>

        <div className='m-1 p-1 grid grid-cols-5 justify-start items-center'>
          <div className='col-span-2 flex justify-start items-center'>
              <div
                className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white m-1 p-1"
                onClick={() => setShowSessionNotes((prev) => !prev)}
              >
                {showSessionNotes ? (
                  <MinusIcon className="h-5 w-5 text-orange-600" />
                ) : (
                  <PlusIcon className="h-5 w-5 text-orange-600" />
                )}
              </div>
            <div className='flex justify-center items-center cursor-pointer rounded-full h-7 w-7 bg-gray-200 hover:bg-gray-50 m-1 p-1'>
              <DocumentTextIcon className="h-5 w-5 text-orange-600 hover:text-orange-600" />
            </div>
            <div className=''>
              <p className='font-bold mx-1'>Session Notes</p>
              <p className='text-[10px] italic text-gray-600 mx-1'>Capture your session experiences, thoughts and observations. </p>
            </div>
          </div>

          <div className='col-span-1'>
            <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'>
                <p className='text-orange-600 text-[10px]'>{imaginationSessionNotes?.length}</p>
            </div>
          </div>
        </div>

        {showSessionNotes && (
          <div>

          {/* Add Button */}
          <div className="flex justify-start items-center m-1 p-1">
              <p className="font-bold text-xs m-1 p-1">Add</p>
              <button type="button" onClick={handleAddNote}>
              <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
              </button>
          </div>
          <div><p></p></div>

          {/* Session Notes Editor */}
          <div className='m-1 p-1'>
              {isEditing && (
                  <div>
                      <div className="bg-gray-50 p-1 m-1 rounded">
                          
                              <EtContentEditorLight2
                              value={editorValue}
                              handleChange={handleEditorChange}
                              placeholderText={editorValue}
                              // toolbarId={"sessionOurtro"}
                              /> 
                          
                      </div>
                      
                      {/* Save and Cancel Button */}
                      <div className="flex justify-start items-center m-1 p-1"> 
                                  <button type="button" onClick={handleSaveNote} className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700">
                                      <p className="text-[10px] px-2 py-1">Save</p>
                                  </button>
                                  <button type="button" onClick={handleCancelEditNote} className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1">
                                      <p className="text-[10px] px-2 py-1">Cancel</p>
                                  </button>
                      </div>
                  </div>
              )}
          </div>

            {imaginationSessionNotes && imaginationSessionNotes.length >0 && (
            <div className='m-1 p-1'>
                <div><p className='m-1 p-1 text-xs font-bold'>Session Notes Trail:</p></div>
                <div>
                {imaginationSessionNotes
                ?.slice()
                .sort((a, b) => new Date(b.imaginationNoteDate) - new Date(a.imaginationNoteDate)) // Sort in descending order
                .map((note, noteIndex, arr) => (
                    <div key={note._id} className="bg-white p-1 m-1 rounded">
                        <div className='bg-gray-100 m-1 p-1 rounded '>   
                            <div className='grid grid-cols-7 justify-between items-center m-1 p-1'>
                                <div className='col-span-1 flex justify-start items-center'>
                                <div
                                      onClick={() => handleToggleShowSessionNotesDetail(note._id)}
                                      className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
                                      >
                                    {showSessionNotesDetail[note._id] ? (
                                      <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                      <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                    </div>
                                    <div>
                                      {/* <p className='text-2xl font-bold mx-1 px-1 text-gray-600'>{arr.length - noteIndex}.</p> */}
                                      <p className='text-2xl font-bold mx-1 px-1 text-gray-600'>{noteIndex +1}.</p>
                                      </div>
                                    <div>
                                        <p className='text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-18'>{getTimeSince(note?.imaginationNoteUpdateDate)}</p>
                                        <p className='text-[7px] mx-1 italic px-2 py-1 text-gray-400'>last updated</p>
                                    </div>  
                                </div>

                                <div className='col-span-3 flex justify-start items-center'>
                                    <div dangerouslySetInnerHTML={createMarkup(note?.imaginationNoteText)} className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"></div>
                                </div>

                                <div className='col-span-2 flex justify-start items-center mx-2'>
                                    <ClockIcon className='h-5 w-5 text-lime-600'/>
                                    <p className='text-[9px] mx-1'>First Created:</p>
                                    <p className='text-gray-400 text-[9px] mx-2'>{formatDateTime(note.imaginationNoteDate)}</p>

                                </div>
                                
                                <div className='col-span-1 flex justify-end items-center mx-2'>
                                    <div className='rounded-full flex justify-center items-center h-6 w-6 hover:bg-white'>
                                        <PencilSquareIcon 
                                            className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer" 
                                            onClick={() => handleEditNote(note)}
                                        />
                                    </div>
                                    <div className='rounded-full flex justify-center items-center h-6 w-6 hover:bg-white'>
                                        <TrashIcon 
                                            className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                            onClick={() => handleDeleteNote(note._id)} 
                                        />
                                    </div>
                                </div>
                            </div> 
                        </div>
                        {/* 14 Sep 2024 - Refined the details display - shifted here */}
                        {showSessionNotesDetail[note._id] && (
                                <div className="m-1 p-1 rounded bg-gray-50">
                                <div
                                  dangerouslySetInnerHTML={createMarkup(note?.imaginationNoteText)}
                                  className="text-[11px] text-gray-700 m-1 p-1"
                                ></div>
                                </div>
                              )}
                    </div>
                ))}
                </div>
            </div>
            )}
            </div>
            )}   
      </div>

      {/* Session Actions Section - 22 Oct 2024 */}
      <div className='m-1 p-1 border border-white rounded'>
      <div className='m-1 p-1'>
        <div className=''>
          <div className='grid grid-cols-5 justify-start items-center'>
                <div className='col-span-2 flex justify-start items-center'>
                    <div
                    className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white m-1 p-1"
                    onClick={() => setShowSessionActions((prev) => !prev)}
                  >
                    {showSessionActions ? (
                      <MinusIcon className="h-5 w-5 text-orange-600" />
                    ) : (
                      <PlusIcon className="h-5 w-5 text-orange-600" />
                    )}
                  </div>
                  <div className='rounded-full flex justify-center items-center h-7 w-7 bg-gray-200 hover:bg-white m-1 p-1'>
                    <CursorArrowRippleIcon className="h-5 w-5 text-orange-600" />
                    </div>
                  <div>
                      <p className="font-bold mx-1 px-1">Actions</p>
                      <p className="text-[10px] mx-1 px-1 italic text-gray-600">What would you like to light up next?</p>
                  </div>
                </div>

                <div className='col-span-1'>
                    <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'>
                      <p className='text-orange-600 text-[10px]'>{userActionsCount}</p>
                    </div>
                </div>
          </div>
        </div> 

        {showSessionActions && ( 
            <div>         
              <div>
              {/* Component to display the trail session actions for the user */}
              {/* <EtUserActionListComp userId={userId} trailId={trailId} sessionId={sessionId}/> */}
              {/* <EtUserActionListByGroupComp userId={userId} trailId={trailId} sessionId={sessionId}/> */}
              <EtUserActionListByGroupWithTabsComp userId={userId} trailId={trailId} sessionId={sessionId}/>
              </div>
              <div className="flex justify-start items-center m-1 p-1">
                  <p className="font-bold text-xs m-1 p-1">Add</p>
                  <button type="button" onClick={handleAddAction}>
                    <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                  </button>
              </div>
            </div>
          )}   

      </div>
      </div>

      {/* Session Action Bar */}
      <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Save"}
                type={"create"}
                handleClick={handleSave}
              />
              <EtBtnFormBasic
                name={"Clear"}
                type={"clear"}
                handleClick={handleClear}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>
    </div>
  )

}

const EtSessionChallengesSection = ({sessionChallenges, userId, trailId, sessionId, userOrgId, selfUserData}) => {
  // First Created - 21 Aug 2024 
  // Author - Atul Pandey
  // Purpose - to simplify and decouple the different section JSXs from the main display component
  // Updated - 23 Aug 2024 - triggerUrl to capture and pass the url triggering the modal for it to navigate back to that page upon completion
  // Updated - 23 Aug 2024 - user challenge self eval scores 
  // Updated - 23 Aug 2024 - get color scores to depict scores as Et Pixels - PCI Luminance
  // Updated - 24 Aug 2024 - Evaluation bar header - Dimension, Self Eval, Sponsor Eval
  // Updated - 24 Aug 2024 - Sponsor Evaluation
  // Updated - 25 Aug 2024 - Added Sponsor User Challenge Evaluations
  // Updated - 28 Aug 2024 - Refined display of coach, sponsor, self eval data
  // Updated - 29 Aug 2024 - Refined display of coach, sponsor evals with memoized data
  // Updated - 30 Aug 2024 - Cleaned up code. Added section for user, coach and sponsor eval comments (challangeDimEvalDetails)
  // Updated - 31 Aug 2024 - Added user, coach and sponsor evaluation comments
  // Updated - 31 Aug 2024 - Added the Modal to Add or Update User Challenge Self Eval
  // Updated - 14 Sep 2024 - Refined the header JSX - from flex to grid
  
  // 1a - State variables for display navigation
  const [showChallengeDetails, setShowChallengeDetails] = useState(false) // 19 Aug 2024
  const [showChallengeSections, setShowChallengeSections] = useState({}) // 19 Aug 2024
  const [showInstructionDetails, setShowInstructionDetails] = useState({}) // 19 Aug 2024
  const [showChallengeDimDetails, setShowChallengeDimDetails] = useState({}) // 19 Aug 2024
  const [showChallengeDimEvalDetails, setShowChallengeDimEvalDetails] = useState({}) // 30 Aug 2024
  const [showChallengeHeadDetails, setShowChallengeHeadDetails] = useState({}) // 21 Aug 2024
  const [existingEvalData, setExistingEvalData] = useState(null) // 31 Aug 2024 - to allow create / update of self user challenge evals
  const [mode, setMode] = useState("create") // 31 Aug 2024 - to allow create / update of self user challenge evals


  // 1b - Extract user challenge self eval data using RTK query - 23 Aug 2024
  const { data: userChallengeSelfEvalData, refetch} = useGetUserChallengeSelfEvalsQuery()
  const userChallengeSelfEvals = userChallengeSelfEvalData?.ids?.map((id) => {
    const userChallengeSelfEval = userChallengeSelfEvalData?.entities[id]
    return userChallengeSelfEval
  })
  // console.log('User Challenge Self Evals', userChallengeSelfEvals)
  // console.log('Session Challenges', sessionChallenges)
  // console.log('SessionHeads:', sessionChallenges?.map(challenge => challenge.challengeDimensions.map(dimension => dimension.challengeDimId.challengeHeadId.challengeHeadName
  // )))
  // console.log('SelfUserData:', selfUserData)

  // 1c - Filter the challenge eval data for the given user org, trail and session - 23 Aug 2024
  const filteredUserChallengeSelfEvals = userChallengeSelfEvals?.filter(evalItem =>
    evalItem.userId._id === userId &&
    evalItem.userId.userOrgId === userOrgId &&
    evalItem.trailId._id === trailId &&
    evalItem.sessionId._id === sessionId &&
    sessionChallenges.some(challenge => challenge._id === evalItem.challengeId)
  )
  // console.log('Filtered User Challenge Self Evals:', filteredUserChallengeSelfEvals)

  // 1d - Extract coach user challenge evaluation data - 24 Aug 2024
  const {data: userChallengeCoachEvalData} = useGetUserChallengeCoachEvalsQuery()
  const userChallengeCoachEvals = userChallengeCoachEvalData?.ids?.map((id) => {
    const userChallengeCoachEval = userChallengeCoachEvalData?.entities[id]
    return userChallengeCoachEval
  })
  // console.log('User Challenge Coach Evals:', userChallengeCoachEvals)

  // 1e - Filter the coach challenge eval data for the given user org, trail and session - 24 Aug 2024
  const filteredUserChallengeCoachEvals = userChallengeCoachEvals?.filter(evalItem =>
    evalItem.userId._id === userId &&
    evalItem.userId.userOrgId === userOrgId &&
    evalItem.trailId._id === trailId &&
    evalItem.sessionId._id === sessionId &&
    sessionChallenges.some(challenge => challenge._id === evalItem.challengeId)
  )
  // console.log('Filtered User Challenge Coach Evals:', filteredUserChallengeCoachEvals)

  // 1f - Extract coach user challenge evaluation data - 25 Aug 2024
  const {data: userChallengeSponsorEvalData} = useGetUserChallengeSponsorEvalsQuery()
  const userChallengeSponsorEvals = userChallengeSponsorEvalData?.ids?.map((id) => {
    const userChallengeSponsorEval = userChallengeSponsorEvalData?.entities[id]
    return userChallengeSponsorEval
  })
  // console.log('User Challenge Sponsor Evals:', userChallengeSponsorEvals)

  // 1g - Filter the sponsor challenge eval data for the given user org, trail and session - 24 Aug 2024
  const filteredUserChallengeSponsorEvals = userChallengeSponsorEvals?.filter(evalItem =>
    evalItem.userId._id === userId &&
    evalItem.userId.userOrgId === userOrgId &&
    evalItem.trailId._id === trailId &&
    evalItem.sessionId._id === sessionId &&
    sessionChallenges.some(challenge => challenge._id === evalItem.challengeId)
  )
  // console.log('Filtered User Challenge Sponsor Evals:', filteredUserChallengeSponsorEvals)

  // 1h - State for Modal -- 11 Jun 2024
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [triggeringUrl, setTriggeringUrl] = useState(null); // 23 Aug 2024

  // 1i - Handle Evaluate Action to activate modal - 23 Aug 2024
  const handleEvaluate = (challengeId) => {
    // handleOpenChallengeEvalModal()
    const currentUrl = window.location.pathname; // 23 Aug 2024 - Capture the current URL
    setTriggeringUrl(currentUrl); // 23 Aug 2024 - Set the triggering URL

     // 31 Aug 2024 - Check if there's an existing evaluation for the selected challenge and user
    //  const existingEval = filteredUserChallengeSelfEvals?.find(
    //   (evalItem) =>
    //     evalItem.sessionChallengeCoachEvalId.challengeId === challengeId &&
    //     evalItem.sessionChallengeCoachEvalId.userId === userId
    // )
    const existingEval = filteredUserChallengeSelfEvals?.find(evalItem =>
      evalItem.challengeId === challengeId )
  
    setIsModalOpen(true)
    // Determine the mode and set existing evaluation data if it exists
    const mode = existingEval ? "update" : "create"
    setMode(mode)
    setExistingEvalData(
      existingEval ? existingEval : null
    )
  }
  
  // // 1j -  Modal management functions for Challenge Evaluations - 22 Aug 2024
  // const handleOpenChallengeEvalModal = () => {
  //   const currentUrl = window.location.pathname; // 23 Aug 2024 - Capture the current URL
  //   setTriggeringUrl(currentUrl); // 23 Aug 2024 - Set the triggering URL
  //   setIsModalOpen(true)
  // }

  const handleCloseChallengeEvalModal = () => {
    setIsModalOpen(false)
    setTriggeringUrl(null); // 23 Aug 2024 Reset the triggering URL
    refetch()
  }
  
  // 2a - Toggle Show function for challenge instruction details
  const handleToggleChallengeSections = (challengeId) => {
    setShowChallengeSections(prev => ({ ...prev, [challengeId]: !prev[challengeId] }));
  };

  // 2b - Toggle Show function for challenge instruction details
  const handleToggleInstructionDetails = (challengeId) => {
    setShowInstructionDetails(prev => ({ ...prev, [challengeId]: !prev[challengeId] }));
  };

  // 2c - Toggle Show function for challenge dimension details
  const handleToggleChallengeHeadDetails = (challengeId) => {
    setShowChallengeHeadDetails(prev => ({ ...prev, [challengeId]: !prev[challengeId] }));
  };

   // 2d - Toggle Show function for challenge dimension details
   const handleToggleChallengeDimDetails = (challengeId) => {
    setShowChallengeDimDetails(prev => ({ ...prev, [challengeId]: !prev[challengeId] }));
  };

   // 2e - Toggle Show function for challenge dimension eval details - 30 Aug 2024
   const handleToggleChallengeDimEvalDetails = (challengeId) => {
    setShowChallengeDimEvalDetails(prev => ({ ...prev, [challengeId]: !prev[challengeId] }));
  };

  // 3 - Calculate the number of session challenges - 19 Aug 2024
  const challengeCount = sessionChallenges?.length || 0
  // console.log('challengeCount :', challengeCount)

  // 4 - Function to get the appropriate color class based on the eval score - 23 Aug 2024
  const getColorForScore = (score) => {
    if (score >= 0 && score < 0.9) return "bg-etpx-0"
    if (score >= 0.9 && score < 1.9) return "bg-etpx-1"
    if (score >= 1.9 && score < 2.9) return "bg-etpx-2"
    if (score >= 2.9 && score < 3.9) return "bg-etpx-3"
    if (score >= 3.9 && score < 4.9) return "bg-etpx-4"
    if (score >= 4.9) return "bg-etpx-5" // For score >= 4.9
    return "bg-black" // default
  }

  // 5 -  Extract Unique Coaches from filteredUserChallengeCoachEvals - 28 Aug 2024 
  const sessionCoaches = filteredUserChallengeCoachEvals?.reduce((acc, evalItem) => {
    const coachId = evalItem.coachId?._id;
    // Check if this coach is already added to the list
    if (coachId && !acc.some(coach => coach._id === coachId)) {
      acc.push(evalItem.coachId); // Add coach to the list if not already present
    }
    return acc;
  }, []);
  // console.log('Session Coaches', sessionCoaches)

  // 6 -  Extract Unique Coaches from filteredUserChallengeCoachEvals - 28 Aug 2024 
  const sessionSponsors = filteredUserChallengeSponsorEvals?.reduce((acc, evalItem) => {
    const sponsorId = evalItem.sponsorId?._id;
    // Check if this sponsor is already added to the list
    if (sponsorId && !acc.some(sponsor => sponsor._id === sponsorId)) {
      acc.push(evalItem.sponsorId); // Add sponsor to the list if not already present
    }
    return acc;
  }, []);
  // console.log('Session Sponsors', sessionSponsors)

  // 7 - Helper function to determine if a dimension should be skipped - 28 Aug 2024, -- 29 Aug 2024 - modified - added  evaluationType, sessionUsers
  const shouldSkipDimension = (dimension, matchingEval, dimensions, currentUser, evaluationType) => {
    if (!matchingEval) {
      // console.log(`No matching evaluation found for ${currentUser.name}`);
      return true; // Skip if no matching evaluation
    }
  
    const challengeHeadId = dimension.challengeDimId.challengeHeadId._id;
    const dimensionsInHead = dimensions.filter(dim => 
      dim.challengeDimId.challengeHeadId._id === challengeHeadId
    );
  
    const evalField = evaluationType === 'coach' ? 'userChallengeCoachEvals' : 'userChallengeSponsorEvals';
  
    const hasEvaluationsForHead = matchingEval[evalField]?.some(evaluation =>
      dimensionsInHead.some(dim => evaluation.userChallengeDimId === dim.challengeDimId._id)
    );
  
    // console.log(`Should skip for ${currentUser.name}: ${!hasEvaluationsForHead}`);
    return !hasEvaluationsForHead;
  };
  
  // 8 - Memoized Evaluations - 29 Aug 2024
  const memoizedEvaluations = useMemo(() => {
    return sessionChallenges?.reduce((acc, challenge) => {
      challenge.challengeDimensions?.forEach(dimension => {
        const headName = dimension.challengeDimId?.challengeHeadId?.challengeHeadName || "Unknown Head";
        if (!acc[challenge._id]) acc[challenge._id] = {};
        if (!acc[challenge._id][headName]) {
          acc[challenge._id][headName] = {
            coaches: sessionCoaches?.map((coach) => {
              const matchingCoachEval = filteredUserChallengeCoachEvals?.find(evalItem => 
                evalItem.coachId._id === coach._id && 
                evalItem.challengeId === challenge._id
              );
              return { coach, matchingCoachEval };
            }),
            sponsors: sessionSponsors?.map((sponsor) => {
              const matchingSponsorEval = filteredUserChallengeSponsorEvals?.find(evalItem => 
                evalItem.sponsorId._id === sponsor._id && 
                evalItem.challengeId === challenge._id
              );
              return { sponsor, matchingSponsorEval };
            })
          };
        }
      });
      return acc;
    }, {});
  }, [sessionChallenges, sessionCoaches, sessionSponsors, filteredUserChallengeCoachEvals, filteredUserChallengeSponsorEvals]);
  
  // Console logs for debugging 
  // console.log('filteredUserChallengeCoachEvals:', filteredUserChallengeCoachEvals);
  // console.log('sessionCoaches:', sessionCoaches);
  // console.log('sessionSponsors:', sessionSponsors);
  // console.log('memoizedEvaluations:', memoizedEvaluations);
  
  
  return(
    <div>
      {challengeCount > 0 && (
      <div className='m-1 p-1 border border-white rounded'>
        {/* Challenge Header Block - 14 Sep 2024 - Changed from flex to grid*/}
        <div className='m-1 p-1 grid grid-cols-5 justify-start items-center'>
          <div className='col-span-2 flex justify-start items-center'>
            <div
              className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white m-1 p-1"
              onClick={() => setShowChallengeDetails((prev) => !prev)}
            >
              {showChallengeDetails ? (
                <MinusIcon className="h-5 w-5 text-orange-600" />
              ) : (
                <PlusIcon className="h-5 w-5 text-orange-600" />
              )}
            </div>
            <div className='rounded-full w-7 h-7 flex justify-center items-center bg-gray-200 hover:bg-gray-50  m-1 p-1'><EtChallengeIcon1SVGComp className='w-5 h-5 text-orange-600'/></div>
            <div>
              <p className='font-bold mx-1 px-1'>Session Challenges </p>
              <p className='text-[10px] italic text-gray-600 mx-1'>Test your client understanding muscle.. </p>
            </div>
          </div>

          <div className='col-span-1'>
            <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'>
              <p className='text-orange-600 text-[10px]'>{challengeCount}</p>
            </div>
          </div>

        </div>
        {showChallengeDetails && (
          <div>
           {sessionChallenges && sessionChallenges?.length > 0 && sessionChallenges?.map((challenge, challengeIndex) =>{
            return(
              <div key={challenge._id+challengeIndex} className='m-1 p-1 bg-gray-100 rounded'>
                <div className='flex justify-between items-center m-1 p-1'>
                  <div className='flex justify-start items-center'>
                      <div className='m-1 p-1 cursor-pointer rounded-full h-6 w-6 flex justify-center items-center hover:bg-white' onClick={() => handleToggleChallengeSections(challenge._id)}>
                          {showChallengeSections[challenge._id] ? (
                              <MinusIcon className="h-4 w-4 text-orange-600" />
                          ) : (
                              <PlusIcon className="h-4 w-4 text-orange-600" />
                          )}
                      </div>
                    <div>
                      <p className='font-bold'>{challenge.challengeName}</p>
                      <p className='text-[10px] italic'>{challenge.challengeDesc}</p>
                    </div>
                  </div>
                </div>

                {showChallengeSections[challenge._id] && (
                  <div>
                      {/* Section - Challenge Instructions */}
                      <div className='mx-1 px-1'>
                        <div className='bg-gray-200 rounded'>
                          <div className='flex justify-between items-center m-1 p-1'>
                              <div className='flex justify-start items-center m-1 p-1'>
                                <div className='m-1 p-1 cursor-pointer rounded-full h-6 w-6 flex justify-center items-center hover:bg-white' onClick={() => handleToggleInstructionDetails(challenge._id)}>
                                      {showInstructionDetails[challenge._id] ? (
                                          <MinusIcon className="h-4 w-4 text-orange-600" />
                                      ) : (
                                          <PlusIcon className="h-4 w-4 text-orange-600" />
                                      )}
                                  </div>
                                  <div className='rounded-full h-7 w-7 flex justify-center items-center hover:bg-white'><WrenchIcon className='h-6 w-6 text-orange-600'/></div>
                                  <div><p className='mx-1 px-1 text-gray-500 font-bold'>Instructions</p></div>
                              </div>
                          </div>
                          {showInstructionDetails[challenge._id] && challenge.challengeInstructions?.length >0 && (
                              <div className='m-1 p-1 rounded'>
                                <div className='m-1 p-1 rounded bg-gray-300'>
                                  {challenge.challengeInstructions?.map((instruction, insIndex) =>{
                                    return(
                                      <div key={instruction._id + insIndex} className='bg-gray-100 m-1 p-1 rounded'>
                                        <div className='flex justify-start items-center m-1 p-1'>
                                            <div><p className='text-gray-400 font-bold mx-1 px-1'>{insIndex +1}.</p></div>
                                            <div>
                                                <div><p className='text-[10px] mx-1 px-1'><span className=''>{instruction.instruction}</span> </p></div>
                                            </div>
                                        </div>   
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            )}  
                        
                        </div>
                      </div>

                      {/* Section - Challenge Dimensions */}
                      <div className='mx-1 px-1'>
                        <div className='bg-gray-200 rounded'>
                          <div className='flex justify-between items-center m-1 p-1'>
                              <div className='flex justify-start items-center m-1 p-1'>
                                <div className='m-1 p-1 cursor-pointer rounded-full h-6 w-6 flex justify-center items-center hover:bg-white' onClick={() => handleToggleChallengeHeadDetails(challenge._id)}>
                                      {showChallengeHeadDetails[challenge._id] ? (
                                          <MinusIcon className="h-4 w-4 text-orange-600" />
                                      ) : (
                                          <PlusIcon className="h-4 w-4 text-orange-600" />
                                      )}
                                  </div>
                                  <div className='rounded-full h-7 w-7 flex justify-center items-center hover:bg-white'><SunIcon className='h-6 w-6 text-orange-600'/></div>
                                  <div><p className='mx-1 px-1 text-gray-500 font-bold'>Dimensions</p></div>
                              </div>
                          </div>
                        {showChallengeHeadDetails[challenge._id] && challenge.challengeDimensions?.length > 0 && (
                          <div className=''>
                            <div className='m-1 p-1 rounded bg-gray-200'>
                              {/* Step 1: Group the dimensions by challengeHeadName */}
                              {Object.entries(
                                challenge.challengeDimensions?.reduce((acc, dimension) => {
                                  const headName = dimension.challengeDimId?.challengeHeadId?.challengeHeadName || "Unknown Head";
                                  if (!acc[headName]) acc[headName] = [];
                                  acc[headName].push(dimension);
                                  return acc;
                                }, {})
                              ).map(([headName, dimensions], headIndex) => {

                                // console.log('headname: ',headName, ' dimensions length:',dimensions.length)
                                // console.log('dimensions', dimensions)
                                
                                // 28 Aug 2024
                                const hasUserEvals = filteredUserChallengeSelfEvals?.some(evalItem =>
                                  evalItem.userChallengeEvals?.some(evaluation =>
                                    dimensions.some(dimension =>
                                      evaluation.userChallengeDimId === dimension.challengeDimId._id
                                    )
                                  )
                                );

                                return(
                                <div key={headName + headIndex+ challenge._id} className='bg-white m-1 p-1 rounded'>
                                  {/* Step 2: Display the challengeHeadName and the evaluators when details are clicked*/}
                                      <div className=''>
                                        <div className='grid grid-cols-12 justify-between items-center m-1 p-1'>
                                          <div className='col-span-4 flex justify-start items-center m-1 p-1'>
                                            <div className='m-1 p-1 cursor-pointer rounded-full h-6 w-6 flex justify-center items-center hover:bg-white' onClick={() => handleToggleChallengeDimDetails(headName)}>
                                                {showChallengeDimDetails[headName] ? (
                                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                                ) : (
                                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                                )}
                                            </div>
                                              <div>
                                                <div><p className='mx-1 px-1 font-bold'>{headName}</p></div>
                                                <div onClick={() =>handleEvaluate(challenge._id)} className='cursor-pointer'><p className='mx-1 px-1 text-[10px] text-orange-600'>Evaluate</p></div>
                                              </div>                                                  
                                          </div>

                                
                                          {/* User Header Bar - to display each user who has done evaluation */}
                                          {showChallengeDimDetails[headName] && (
                                            <div className='col-span-8 gap-2 flex justify-end items-center'>

                                              {/* Display Self if there are any evaluations */}
                                               {/* 28 Aug 2024 Check if there are any self evaluations */}
                                                {hasUserEvals && (
                                                  <div className='flex flex-col justify-center items-center m-1 p-1'>
                                                    <div className='w-16'>
                                                      <p className='text-orange-600 text-[9px] text-center'>You</p>
                                                    </div>
                                                    <div className='m-1 p-1'>
                                                      <User username={selfUserData.username} defaultUserPic={userProfileIcon2} />
                                                    </div>
                                                    <div className='w-16 my-1'>
                                                      <p className='text-gray-600 text-[10px] text-center'>{selfUserData.name.split(" ")[0]}</p>
                                                    </div>
                                                  </div>
                                                )}
                                              
                                                  {/* Display each coach user if they have any evaluations */}
                                                  {sessionCoaches?.map((coach, coachIndex) => {

                                                  const matchingCoachEvals = filteredUserChallengeCoachEvals?.filter(evalItem =>
                                                    evalItem.userChallengeCoachEvals?.some(evaluation => 
                                                      dimensions.some(dimension =>
                                                        evaluation.userChallengeDimId === dimension.challengeDimId._id
                                                      )
                                                    )
                                                  );

                                                    // console.log('maching coach evals --indise display session coaches', matchingCoachEvals)
                                                    const coachEvals = matchingCoachEvals?.filter(evalItem => evalItem?.coachId._id === coach._id);
                                                    const hasCoachEval = coachEvals?.length > 0;

                                                    if (!hasCoachEval) return null; // Skip displaying if there are no evaluations for the coach

                                                    return (
                                                      <div key={coach._id + coachIndex} className='flex flex-col justify-center items-center m-1 p-1'>
                                                        <div className='w-16'>
                                                          <p className='text-orange-600 text-[9px] text-center'>Coach</p>
                                                        </div>
                                                        <div className='m-1 p-1'>
                                                          <User username={coach.username} defaultUserPic={userProfileIcon2} />
                                                        </div>
                                                        <div className='w-16 my-1'>
                                                          <p className='text-gray-600 text-[10px] text-center'>{coach.name.split(" ")[0]}</p>
                                                        </div>
                                                      </div>
                                                    );
                                                  })}

                                                      {/* Display each sponsor user */}
                                                      {sessionSponsors?.map((sponsor, sponsorIndex) => {

                                                      const matchingSponsorEvals = filteredUserChallengeSponsorEvals?.filter(evalItem =>
                                                        evalItem.userChallengeSponsorEvals?.some(evaluation => 
                                                          dimensions.some(dimension =>
                                                            evaluation.userChallengeDimId === dimension.challengeDimId._id
                                                          )
                                                        )
                                                      );

                                                        // console.log('maching sponsor evals --indise display session sponsores', matchingSponsorEvals)
                                                        const sponsorEvals = matchingSponsorEvals?.filter(evalItem => evalItem?.sponsorId._id === sponsor._id);
                                                        const hasSponsorEval = sponsorEvals?.length > 0;

                                                        if (!hasSponsorEval) return null; // Skip displaying if there are no evaluations for the sponsor

                                                        return (
                                                          <div key={sponsor._id + sponsorIndex} className='flex flex-col justify-center items-center m-1 p-1'>
                                                            <div className='w-16'>
                                                              <p className='text-orange-600 text-[9px] text-center'>Sponsor</p>
                                                            </div>
                                                            <div className='m-1 p-1'>
                                                              <User username={sponsor.username} defaultUserPic={userProfileIcon2} />
                                                            </div>
                                                            <div className='w-16 my-1'>
                                                              <p className='text-gray-600 text-[10px] text-center'>{sponsor.name.split(" ")[0]}</p>
                                                            </div>
                                                          </div>
                                                        );
                                                      })}
                                                </div>
                                            )}
                                        </div>                                        
                                      </div>

                                  {/* Step 3: Display each dimension under the challengeHeadName */}
                                  {showChallengeDimDetails[headName] && dimensions.map((dimension, dimIndex) => {

                                    // 28 Aug 2024 Find the matching evaluation for the current dimension
                                        const matchingEval = filteredUserChallengeSelfEvals?.find(evalItem =>
                                          evalItem.userChallengeEvals?.some(evaluation => evaluation.userChallengeDimId === dimension.challengeDimId._id)
                                      );
                                      // console.log('matchingEval', matchingEval)

                                      // 31 Aug 2024 - Extract the exact matching dim evaluation
                                      const matchingDimEval = filteredUserChallengeSelfEvals?.find(evalItem =>
                                        evalItem.userChallengeEvals?.find(evaluation => evaluation.userChallengeDimId === dimension.challengeDimId._id)
                                    );
                                    // console.log('matchingDimEval', matchingDimEval)
                                      

                                      // 28 Aug 2024 - Extract the userChallengeDimSelfScore if a matching evaluation is found
                                      const userEvalScore = matchingEval?.userChallengeEvals?.find(evaluation => evaluation.userChallengeDimId === dimension.challengeDimId._id)?.userChallengeDimSelfScore || "N/A";

                                      const displayUserEvalScore = (userEvalScore !== undefined && userEvalScore !== null) ? userEvalScore : "N/A";

                                      // 31 Aug 2024 - Extract the userChallengeDimSelfEvalText if a matching evaluation is found
                                      const userEvalText = matchingEval?.userChallengeEvals?.find(evaluation => evaluation.userChallengeDimId === dimension.challengeDimId._id)?.userChallengeDimSelfEvalText
                                      || "N/A";

                                      // 31 Aug 2024 - Extract the userChallengeDimSelfEvalText if a matching evaluation is found
                                      const userEvalTextUpdateDate = matchingEval?.userChallengeEvals?.find(evaluation => evaluation.userChallengeDimId === dimension.challengeDimId._id)?.userChallengeDimSelfEvalTextUpdateDate
                                      || "N/A";

                                      // 31 Aug 2024 - Extract the matchingUser info
                                      // const userInfo = matchingEval?.userId
                                      // console.log('Matching User', userInfo)
                                      // console.log('SelfUserData', selfUserData)

                                      // 24 Aug 2024 - Filter the coach evaluations for the current dimension
                                        const matchingCoachEvals = filteredUserChallengeCoachEvals?.filter(evalItem =>
                                          evalItem.userChallengeCoachEvals?.some(evaluation => evaluation.userChallengeDimId === dimension.challengeDimId._id)
                                      );
                                      // console.log('matching coach evals:', matchingCoachEvals)

                                      // 25 Aug 2024 - Filter the sponsor evaluations for the current dimension
                                        const matchingSponsorEvals = filteredUserChallengeSponsorEvals?.filter(evalItem =>
                                          evalItem.userChallengeSponsorEvals?.some(evaluation => evaluation.userChallengeDimId === dimension.challengeDimId._id)
                                      );
                                      // console.log('matching sponsor evals:', matchingSponsorEvals)

                                      // 28 Aug 2024 Calculate if there are any self evaluations
                                      const hasUserEvals = filteredUserChallengeSelfEvals?.some(evalItem =>
                                        evalItem.userChallengeEvals?.some(evaluation =>
                                          dimensions.some(dimension =>
                                            evaluation.userChallengeDimId === dimension.challengeDimId._id
                                          )
                                        )
                                      );
                                      // console.log('hasUserEvals:', hasUserEvals)

                                    return(
                                          <div>
                                          {/* 24 Aug 2024 - Header Bar */}
                              
                                          <div key={dimension._id + dimIndex} className='bg-gray-100 m-1 p-1 rounded'>
                                              <div className='grid grid-cols-12 justify-between items-center'>
                                                  <div className='col-span-4 flex justify-start items-center m-1 p-1'>
                                                  <div
                                                      onClick={() =>
                                                        handleToggleChallengeDimEvalDetails(
                                                          dimension._id
                                                        )
                                                      }
                                                      className="cursor-pointer m-1 p-1"
                                                      >
                                                        {showChallengeDimEvalDetails[
                                                          dimension._id
                                                        ] ? (
                                                          <MinusIcon className="h-4 w-4 text-orange-600" />
                                                        ) : (
                                                          <PlusIcon className="h-4 w-4 text-orange-600" />
                                                        )}
                                                      </div>
                                                    <div>
                                                      <p className='text-4xl text-gray-400 font-bold mx-1 px-1'>{dimIndex + 1}.</p>
                                                    </div>
                                                    <div>
                                                      <div><p className='text-[10px] mx-1 px-1'><span className='font-bold'>
                                                        {dimension.challengeDimId?.challengeDimensionName}</span></p></div>
                                                      <div><p className='text-[10px] mx-1 px-1 italic'>{dimension.challengeDimId?.challengeDimensionDesc}</p></div>
                                                    </div>
                                                  </div>
                                                  {/* Dimension Evaluations */}
                                                 
                                                  <div className='col-span-8 gap-2 flex justify-end items-center'>
                                                  {/* 28 Aug 2024 */}
                                                  {hasUserEvals && displayUserEvalScore === "N/A" ? (
                                                      <div className="h-16 w-16 flex justify-center items-center border border-gray-400 rounded my-1 py-1 bg-gradient-to-r from-gray-800 to-gray-800 via-black">
                                                        <p className="text-gray-500 text-[9px]">No Eval</p>
                                                      </div>
                                                    ) : (
                                                      <div className={`flex justify-center items-center ${getColorForScore(displayUserEvalScore)} rounded h-16 w-16 border border-gray-400 my-1 py-1`}>
                                                        <p className='text-white'>{displayUserEvalScore}</p>
                                                      </div>
                                                    )}
                                                   
                                                    {/* Iterate over each coach evaluation or show "No Eval" if missing */}
                                                    {memoizedEvaluations[challenge._id]?.[headName]?.coaches.map(({ coach, matchingCoachEval }, coachIndex) => {
                                                    const coachEvalScore = matchingCoachEval?.userChallengeCoachEvals?.find(
                                                      evaluation => evaluation.userChallengeDimId === dimension.challengeDimId._id
                                                    )?.userChallengeDimCoachScore;

                                                    const displayCoachEvalScore = (coachEvalScore !== undefined && coachEvalScore !== null) ? coachEvalScore : "N/A";

                                                    if (shouldSkipDimension(dimension, matchingCoachEval, dimensions, coach, 'coach')) {
                                                      return null;
                                                    }

                                                  return (
                                                    <div key={coach._id + coachIndex} className='flex flex-col justify-center items-center'>
                                                      <div key={dimension.challengeDimId._id + dimIndex} className='flex flex-col justify-center items-center m-1 p-1'>
                                                        {displayCoachEvalScore === "N/A" ? (
                                                          <div className="h-16 w-16 flex justify-center items-center border border-gray-400 rounded my-1 py-1 bg-gradient-to-r from-gray-800 to-gray-800 via-black">
                                                            <p className="text-gray-500 text-[9px]">No Eval</p>
                                                          </div>
                                                        ) : (
                                                          <div className={`flex justify-center items-center ${getColorForScore(displayCoachEvalScore)} rounded h-16 w-16 border border-gray-400 my-1 py-1`}>
                                                            <p className='text-white'>{displayCoachEvalScore}</p>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  );
                                                })}

                                                {memoizedEvaluations[challenge._id]?.[headName]?.sponsors.map(({ sponsor, matchingSponsorEval }, sponsorIndex) => {
                                                  const sponsorEvalScore = matchingSponsorEval?.userChallengeSponsorEvals?.find(
                                                    evaluation => evaluation.userChallengeDimId === dimension.challengeDimId._id
                                                  )?.userChallengeDimSponsorScore;

                                                  const displaySponsorEvalScore = (sponsorEvalScore !== undefined && sponsorEvalScore !== null) ? sponsorEvalScore : "N/A";

                                                  if (shouldSkipDimension(dimension, matchingSponsorEval, dimensions, sponsor, 'sponsor')) {
                                                    return null;
                                                  }

                                                  return (
                                                    <div key={sponsor._id + sponsorIndex} className='flex flex-col justify-center items-center'>
                                                      <div key={dimension.challengeDimId._id + dimIndex} className='flex flex-col justify-center items-center m-1 p-1'>
                                                        {displaySponsorEvalScore === "N/A" ? (
                                                          <div className="h-16 w-16 flex justify-center items-center border border-gray-400 rounded my-1 py-1 bg-gradient-to-r from-gray-800 to-gray-800 via-black">
                                                            <p className="text-gray-500 text-[9px]">No Eval</p>
                                                          </div>
                                                        ) : (
                                                          <div className={`flex justify-center items-center ${getColorForScore(displaySponsorEvalScore)} rounded h-16 w-16 border border-gray-400 my-1 py-1`}>
                                                            <p className='text-white'>{displaySponsorEvalScore}</p>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                          </div>
                                          {/* 30 Aug 2024 - Challenge Dim Eval Section */}
                                          {showChallengeDimEvalDetails[dimension._id] && (
                                            <div className='bg-white m-1 p-1'>
                                              <div><p className='m-1 p-1 font-bold text-gray-800'>Eval Comments</p></div>
                                              {/* 31 Aug 2024 - Grid for displaying each user, coach and sponsor eval comment for this dimension */}

                                              <div className='grid grid-cols-9 xl:grid-cols-10 gap-y-2 justify-start items-center bg-gray-50 my-2 px-1 py-2 rounded'>

                                                {/* 31 Aug 2024 - Put the user comments here for each eval dimension */}
                                                <div className='col-span-3 xl:col-span-2 m-1 p-1 bg-white h-full'>
                                                  {hasUserEvals && userEvalScore !== 'N/A' ?(
                                                    renderUserEvalInfo(selfUserData, "You", userEvalText, userEvalTextUpdateDate )
                                                  ):(
                                                    renderUserEvalInfo(selfUserData, "You", "You have not evaluated this dimension yet.", "No update date", "text-orange-600")
                                                  )}
                                                </div>

                                                {/* 31 Aug 2024 - Put each coach comment here */}
                                                
                                                {memoizedEvaluations[challenge._id]?.[headName]?.coaches?.map(({ coach, matchingCoachEval }, coachIndex) => {

                                                  const coachEvalScore = matchingCoachEval?.userChallengeCoachEvals?.find(
                                                    evaluation => evaluation.userChallengeDimId === dimension.challengeDimId._id
                                                  )?.userChallengeDimCoachScore;

                                                  const displayCoachEvalScore = (coachEvalScore !== undefined && coachEvalScore !== null) ? coachEvalScore : "N/A";

                                                  const coachEvalText = matchingCoachEval?.userChallengeCoachEvals?.find(
                                                    evaluation => evaluation.userChallengeDimId === dimension.challengeDimId._id
                                                  )?.userChallengeDimCoachEvalText;

                                                  const coachEvalTextUpdateDate = matchingCoachEval?.userChallengeCoachEvals?.find(
                                                    evaluation => evaluation.userChallengeDimId === dimension.challengeDimId._id
                                                  )?.userChallengeDimCoachEvalTextUpdateDate || 'Not updated';

                                                  return(
                                                    <div key={challenge._id+headName+coach._id+dimension._id+coachIndex} className='col-span-3 xl:col-span-2 m-1 p-1 bg-white h-full'>
                                                      {displayCoachEvalScore && displayCoachEvalScore !== 'N/A' ? (renderUserEvalInfo(coach, "Coach", coachEvalText, coachEvalTextUpdateDate))
                                                      : (
                                                        renderUserEvalInfo(coach, "Coach", "Coach has not evaluated this dimension yet.", "No update date", "text-orange-600")
                                                      )}
                                                    </div>
                                                  )
                                                })}
                                                
                                                {/* 31 Aug 2024 - Put each sponsor comment here */}

                                                {memoizedEvaluations[challenge._id]?.[headName]?.sponsors?.map(({ sponsor,     matchingSponsorEval }, sponsorIndex) => {

                                                  const sponsorEvalScore = matchingSponsorEval?.userChallengeSponsorEvals?.find(
                                                    evaluation => evaluation.userChallengeDimId === dimension.challengeDimId._id
                                                  )?.userChallengeDimSponsorScore;

                                                  const displaySponsorEvalScore = (sponsorEvalScore !== undefined && sponsorEvalScore !== null) ? sponsorEvalScore : "N/A";

                                                  const sponsorEvalText = matchingSponsorEval?.userChallengeSponsorEvals?.find(
                                                    evaluation => evaluation.userChallengeDimId === dimension.challengeDimId._id
                                                  )?.userChallengeDimSponsorEvalText;

                                                  const sponsorEvalTextUpdateDate = matchingSponsorEval?.userChallengeSponsorEvals?.find(
                                                    evaluation => evaluation.userChallengeDimId === dimension.challengeDimId._id
                                                  )?.userChallengeDimSponsorEvalTextUpdateDate || 'Not updated';

                                                  return(
                                                    <div key={challenge._id+headName+sponsor._id+dimension._id+sponsorIndex} className='col-span-3 xl:col-span-2 m-1 p-1 bg-white h-full'>
                                                      {displaySponsorEvalScore && displaySponsorEvalScore !== 'N/A' ? (renderUserEvalInfo(sponsor, "Sponsor", sponsorEvalText, sponsorEvalTextUpdateDate))
                                                      : (
                                                        renderUserEvalInfo(sponsor, "Sponsor", "Sponsor has not evaluated this dimension yet.", "No update date", "text-orange-600")
                                                      )}
                                                    </div>
                                                  )
                                                })}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                      </div>
                                  )})}
                              </div>
                            )})}
                        </div>  
                      </div>
                    )}  
                </div>
                    <EtModalAddOrUpdateUserChallengeSelfEval 
                      isOpen={isModalOpen} 
                      onClose={handleCloseChallengeEvalModal}
                      challengeId={challenge._id} 
                      userId={userId} 
                      userOrgId={userOrgId} 
                      trailId={trailId} 
                      sessionId={sessionId} 
                      triggeringUrl={triggeringUrl} 
                      mode={mode} 
                      existingEvalData={existingEvalData}
                    />
                      {/* <EtModalCreateUserChallengeSelfEval isOpen={isModalOpen} onClose={handleCloseChallengeEvalModal} challengeId={challenge._id} userId={userId} userOrgId={userOrgId} trailId={trailId} sessionId={sessionId} triggeringUrl={triggeringUrl}/> */}
                    </div>
                </div>
              )}
          </div>
        )
           })}
          </div>
        )}
      </div>)}
    </div>
  )
}

const User = ({ username, defaultUserPic }) => {
  const { data: userPic, isFetching: isFetchingUserPic } =
    useGetUserPicQuery(username)

  return (
    <div className="flex justify-center items-center h-12 w-12 rounded-full border border-white bg-gradient-to-r from-orange-600 to-orange-400 shadow-lg shadow-black cursor-pointer">
      {/* Display user picture or loading indicator*/}
      {isFetchingUserPic ? (
        <div>Loading...</div>
      ) : userPic ? (
        <img src={userPic} alt="User" className="h-11 w-11  rounded-full" />
      ) : (
        <img src={defaultUserPic} alt="User" className="h-10 w-10 p-1" />
      )}
    </div>
  )
}

// 30 Aug 2024 - Helper function to render user info and evaluation comments - updated - 31 Aug 2024 - added userRelation
const renderUserEvalInfo = (user, userRelation, userEvalText, evalUpdateDate, textColor = "text-gray-800") => (
  <div className="flex flex-col items-start justify-start mx-1 px-1">
      <div className="flex justify-start items-center w-full">
          <div className="flex justify-start items-center m-1 p-1 w-16">
              {/* User Info */}
              <div className="flex flex-col justify-center items-center">
                  <div className="w-16">
                      <p className="text-orange-600 text-[9px] text-center">
                          {userRelation}
                      </p>
                  </div>
                  <div className="mx-1 px-1">
                      <User
                          username={user.username || user.userId?.username}
                          defaultUserPic={userProfileIcon2}
                      />
                  </div>
                  <div className="w-16 my-1 py-1">
                      <p className="text-gray-600 text-[10px] text-center">
                          {user?.name.split(" ")[0] || user.userId?.name.split(" ")[0]}
                      </p>
                  </div>
              </div>
          </div>
          <div className="m-1 p-1 border-l-2 border-orange-600">
              <div>
                  <p className="mx-1 text-gray-600 font-bold text-[10px]">
                      Comments
                  </p>
              </div>
              <div>
                  <p className={`mx-1 ${textColor} text-[10px]`}>
                      {userEvalText}
                  </p>
              </div>
          </div>
      </div>

      <div className="m-1 p-1">
          <div className="flex justify-start items-center">
              <ClockIcon className="h-5 w-5 text-lime-600" />
              <p className="mx-1 px-1 text-gray-500 text-[10px] font-bold">
                  Last Updated
              </p>
              <p className="text-[9px] mx-1 italic px-2 py-1 text-gray-400">
                  {getTimeSince(evalUpdateDate)}
              </p>
          </div>
      </div>
  </div>
);

export default EtUserTrailSessionImaginationCompProd