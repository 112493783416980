
    import React from 'react';
    
    const EtWabCreationNotesIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m550.84 2595.7c0 255.66 208.83 464.33 464.33 464.33h342c28.828 0 50.344-21.656 50.344-50.344 0-28.828-21.656-50.344-50.344-50.344h-342c-201.66 0-363.67-162-363.67-363.67l0.14062-1591.3c0-201.66 162-363.67 363.67-363.67h842.33v388.84c0 108 90 198 198 198l392.33 0.14062v320.34c0 28.828 21.656 50.344 50.344 50.344 28.828 0 50.344-21.656 50.344-50.344l0.14062-367.16c0-14.344-7.1719-25.172-14.344-36l-586.84-590.47c-10.828-10.828-21.656-14.344-36-14.344h-896.47c-255.66 0-464.33 208.83-464.33 464.33z" fillRule="evenodd"/>
 <path d="m2415.7 1893.7-774 770.33c-10.828 10.828-14.344 21.656-14.344 36l-3.6562 288c0 14.344 7.1719 28.828 14.344 36 10.828 10.828 21.656 14.344 36 14.344l284.34-3.6562c14.344 0 25.172-7.1719 36-14.344l777.67-774-280.83-280.83z" fillRule="evenodd"/>
 <path d="m2808 2134.8 36 36 133.17-133.17c97.172-97.172 97.172-255.66 0-352.83-97.172-97.172-255.66-97.172-356.34 0l-133.17 133.17 280.83 280.83z" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtWabCreationNotesIcon1SVGComp;
    