import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import {
  PlusIcon,
  MinusIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline"
import { ClockIcon, BuildingOfficeIcon } from "@heroicons/react/24/solid"
import { useGetUserImprintCapabilitiesSoftSkillsQuery } from '../userImprintCapabilitiesSoftSkillsApiSlice' // 19 Nov 2024

import EtModalCreateUserImprintCapabilitiesSoftSkill from '@/components/common/modal/EtModalCreateUserImprintCapabilitiesSoftSkill'
import EtModalUpdateUserImprintCapabilitiesSoftSkill from '@/components/common/modal/EtModalUpdateUserImprintCapabilitiesSoftSkill'

import EtCapabilitiesEngagementsIcon1SVGComp from '@/assets/img/icons/svgComp/EtCapabilitiesEngagementsIcon1SVGComp'
import EtCapabilitiesYearsIcon1SVGComp from '@/assets/img/icons/svgComp/EtCapabilitiesYearsIcon1SVGComp'
import EtCapabilitiesProficiencyIcon1SVGComp from '@/assets/img/icons/svgComp/EtCapabilitiesProficiencyIcon1SVGComp'
import EtCapabilitiesCertificationIcon1SVGComp from '@/assets/img/icons/svgComp/EtCapabilitiesCertificationIcon1SVGComp'
import EtCapabilitiesCertificationIcon2SVGComp from '@/assets/img/icons/svgComp/EtCapabilitiesCertificationIcon2SVGComp'
import EtCapabilitiesRecognitionIcon1SVGComp from '@/assets/img/icons/svgComp/EtCapabilitiesRecognitionIcon1SVGComp'
import EtCapabilitiesRecognitionIcon3SVGComp from '@/assets/img/icons/svgComp/EtCapabilitiesRecognitionIcon3SVGComp'
import EtCapabilitiesSelfNotesIcon1SVGComp from '@/assets/img/icons/svgComp/EtCapabilitiesSelfNotesIcon1SVGComp'

import { formatDateToMonthYear, formatDateToYear, formatDateTime, formatShortDate } from '@/utils/formatDate'
import { getTimeSince } from "@/utils/EtGetTimeSince"

const EtImprintCapabilitiesSoftSkill = ({userId, userInfo}) => {
    // First Created - 22 Nov 2024
    // Author - Atul Pandey
    // Inherited and refined the main code from ImprintCapabilitiesDomain

    //console.log('userInfo', userInfo)
    
    // 1a - set navigate:
    const navigate = useNavigate();
    const [showCertifications, setShowCertifications] = useState({}) // 20 Nov 2024
    const [showRecognitions, setShowRecognitions] = useState({}) // 20 Nov 2024
    const [showNotes, setShowNotes] = useState(false) // 20 Nov 2024
    const [showNoteDetails, setShowNoteDetails] = useState({}) // 20 Nov 2024
    const [showCertificationDetails, setShowCertificationDetails] = useState({}) // 21 Nov 2024
    const [showRecognitionDetails, setShowRecognitionDetails] = useState({}) // 21 Nov 2024
    
    // 1b - State for Modal -- 21 Nov 2024
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
    const [createTriggeringUrl, setCreateTriggeringUrl] = useState(null)
    const [updateTriggeringUrl, setUpdateTriggeringUrl] = useState(null)
    
    // 2a - Extract needed data from the incoming props
    const userOrgId = userInfo[0]?.userOrgId._id || null
    // console.log('userOrgId', userOrgId)

    // 2b - Get Data from the backend
    const { data: userImprintCapabilitiesSoftSkillData} = useGetUserImprintCapabilitiesSoftSkillsQuery()
    const userImprintCapabilitiesSoftSkills = userImprintCapabilitiesSoftSkillData?.ids
    ?.map(id => userImprintCapabilitiesSoftSkillData?.entities[id])
    ?.filter(item=> item.userId._id === userId && item.userOrgId._id === userOrgId) || []
    console.log('userImprintCapabilties SoftSkill', userImprintCapabilitiesSoftSkills)

    // 3a - Handle Create User Imprint Capability SoftSkill
    const handleCreateUserImprintCapabilitySoftSkill = () => {
        const currentUrl = window.location.pathname
        setIsCreateModalOpen(true) 
        setCreateTriggeringUrl(currentUrl) 
    }

    const handleCloseCreateModal = () => {
        setIsCreateModalOpen(false)
        setCreateTriggeringUrl(null) 
        // window.location.reload(); 
      }

    // 3b - Handle Update User Imprint Capability SoftSkill
    const handleUpdateUserImprintCapabilitySoftSkill = () => {
        const currentUrl = window.location.pathname
        setIsUpdateModalOpen(true) 
        setUpdateTriggeringUrl(currentUrl) 
    }

    const handleCloseUpdateModal = () => {
        setIsUpdateModalOpen(false)
        setUpdateTriggeringUrl(null) 
        // window.location.reload(); 
      }
    
    // 3c - Handle Toggle ShowRecognitions
    const handleToggleShowRecognitions = (recId) => {
        setShowRecognitions((prev) => ({
            ...prev,
            [recId]: !prev[recId],
          }))
      }

    // 3d - Handle Toggle ShowCertifications
    const handleToggleShowCertifications = (certId) => {
        setShowCertifications((prev) => ({
            ...prev,
            [certId]: !prev[certId],
          }))
      }

    // 3e - Handle Toggle ShowNoteDetails
    const handleToggleShowNoteDetails = (noteId) => {
        setShowNoteDetails((prev) => ({
            ...prev,
            [noteId]: !prev[noteId],
          }))
      }

    // 3f - Handle Toggle ShowCertificationDetails
    const handleToggleShowCertificationDetails = (certId) => {
        setShowCertificationDetails((prev) => ({
            ...prev,
            [certId]: !prev[certId],
          }))
      }

    // 3g - Handle Toggle ShowRecognitionDetails
    const handleToggleShowRecognitionDetails = (recId) => {
        setShowRecognitionDetails((prev) => ({
            ...prev,
            [recId]: !prev[recId],
          }))
      }
   
    // 4 - create Markup
    const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
    }

    return(
        <div className='bg-white rounded m-1 p-1 font-poppins'>
            {userImprintCapabilitiesSoftSkills?.length === 0 && (
                <div className='flex justify-between items-center'>
                <div>
                <p className='m-1 p-1 text-xs'>There is no softSkill record you have created yet! Change that by clicking the Add button.</p>
                </div>
                <div className='flex justify-start items-center'>
                    <p className='text-xs font-bold mx-1 px-1'>Add</p>
                    <button
                        type="button" 
                        // onClick={() => navigate("/capabilitiesUserImprint/softSkill/create")}
                        onClick={handleCreateUserImprintCapabilitySoftSkill}
                        className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                    >
                        <PlusIcon className="h-3 w-3" />
                    </button>
                </div>
                <EtModalCreateUserImprintCapabilitiesSoftSkill 
                    isOpen={isCreateModalOpen}
                    userId={userId}
                    userOrgId={userOrgId}
                    triggeringUrl={createTriggeringUrl}
                    onClose={handleCloseCreateModal}
                />

            </div>
            )}
            {userImprintCapabilitiesSoftSkills?.length>0 && userImprintCapabilitiesSoftSkills?.map((capability, capIndex) => {
                // Capability Items
                const headline = capability?.userImprintCapabilitiesSoftSkillName || ""
                const overview = capability?.userImprintCapabilitiesSoftSkillDesc || ""

                
                return(
                    <div key={capability._id} className='m-1 p-1'>
                        <div className='border-b'>
                            <div className='flex justify-between items-center'>
                                <div>
                                    <p className='text-xs font-bold text-orange-600 m-1 p-1'>{headline}</p>
                                </div>
                                <div>
                                    <button 
                                        type="button" 
                                        onClick={handleUpdateUserImprintCapabilitySoftSkill}
                                        // onClick={() => navigate("/capabilitiesUserImprint/softSkill/update")}
                                    >
                                        <PencilSquareIcon className="h-5 w-5 text-orange-600 m-1 p-1" />
                                    </button>
                                </div> 
                                    <EtModalUpdateUserImprintCapabilitiesSoftSkill 
                                        isOpen={isUpdateModalOpen}
                                        userId={userId}
                                        userOrgId={userOrgId}
                                        triggeringUrl={updateTriggeringUrl}
                                        onClose={handleCloseUpdateModal}
                                    />
                            </div>
                            
                            <p dangerouslySetInnerHTML={createMarkup(overview)} className='text-[10px] leading-4 text-gray-600 m-1 p-1'></p>
                        </div>
                        
                        {/* SoftSkill Stats */}
                        {capability?.userImprintCapabilitiesSoftSkillStats?.length>0 && capability?.userImprintCapabilitiesSoftSkillStats?.map((stat, statIndex) => {
                            //Stat items
                            const softSkillName = stat?.softSkillType?.capabilitiesSoftSkillTypeName || ""
                            const softSkillPic = stat?.softSkillType?.pic || ""
                            const numberOfEngagements = stat?.numberOfEngagements || 0
                            const yearsOfExperience = stat?.yearsOfExperience || 0
                            const proficiencyLevel = stat?.proficiencyLevel || ""
                            const certifications = stat?.certifications || []
                            const recognitions = stat?.recognitions || []

                            return(
                            <div key={stat._id+statIndex} >
                                <div className='grid grid-cols-4 justify-between items-center m-1 p-1 gap-1'>
                                    <div className='col-span-1 aspect-[3/2] flex flex-col justify-center items-center border rounded bg-gradient-to-r from-black to-black via-gray-900'>
                                        <p className='text-xs text-gray-700 my-2 py-2'>SoftSkill</p>
                                        <img src={softSkillPic} alt="" className='h-12 w-12' />
                                        <p className='text-sm text-gray-600 font-bold my-2 py-2'>{softSkillName}</p>
                                    </div>
                                    <div className='col-span-1 aspect-[3/2] flex flex-col justify-center items-center border rounded bg-gradient-to-r from-black to-black via-gray-900'>
                                        <p className='text-xs text-gray-700 my-2 py-2'>Engagements</p>
                                        <EtCapabilitiesEngagementsIcon1SVGComp className='w-12 h-12 text-orange-600'/>
                                        <p className='text-4xl text-gray-600 font-bold my-2 py-2'>{numberOfEngagements}</p>
                                    </div>
                                    <div className='col-span-1 aspect-[3/2] flex flex-col justify-center items-center border rounded bg-gradient-to-r from-black to-black via-gray-900'>
                                        <p className='text-xs text-gray-700 my-2 py-2'>Experience(Yrs)</p>
                                        <EtCapabilitiesYearsIcon1SVGComp className='w-12 h-12 text-orange-600'/>
                                        <p className='text-4xl text-gray-600 font-bold my-2 py-2'>{yearsOfExperience}</p>
                                    </div>
                                    <div className='col-span-1 aspect-[3/2] flex flex-col justify-center items-center border rounded bg-gradient-to-r from-black to-black via-gray-900'>
                                        <p className='text-xs text-gray-700 my-2 py-2'>Proficiency</p>
                                            <EtCapabilitiesProficiencyIcon1SVGComp className='w-12 h-12 text-orange-600'/>
                                        <p className='text-2xl text-gray-600 font-bold my-2 py-2'>{proficiencyLevel}</p>
                                    </div>
                                </div>
                                {/* Certifications Section */}
                                <div className=' m-1 p-1 bg-gray-200 rounded '>
                                        <div className='grid grid-cols-8 justify-start items-center'>
                                            <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                                <div
                                                    onClick={() => handleToggleShowCertifications(stat._id)}
                                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                    >
                                                    {showCertifications[stat._id] ? (
                                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                                    ) : (
                                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                                    )}
                                                </div>
                                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={()=>handleToggleShowCertifications(stat._id)} ><EtCapabilitiesCertificationIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                                <div>
                                                    <p className='mx-1 px-1 font-bold text-gray-600'>Certifications</p>
                                                    <p className='mx-1 px-1 text-gray-400 text-[10px] italic'>what are my earned softSkill credentials?</p>
                                                </div>     
                                            </div>
                                        </div>
                                        
                                        {showCertifications[stat._id] && certifications?.length === 0 && (
                                            <div className='bg-white rounded m-1 p-1'>
                                                <p className='text-xs m-1 p-1 text-orange-600'>No certifications have been added yet</p>
                                            </div>
                                        )}
                                        {showCertifications[stat._id] && certifications?.length>0 && 
                                        certifications
                                        ?.slice()
                                        .sort(
                                            (a, b) =>
                                                new Date(b.certificationDate) - new Date(a.certificationDate)
                                        ) // Sort in descending order
                                        ?.map((cert, certIndex) => (
                                            <div key={cert._id+certIndex} className='bg-white rounded m-1 p-1'>
                                                <div className='grid grid-cols-9 justify-between items-center m-1 p-1'>
                                                    <div className='col-span-3 flex justify-start items-center'>
                                                        <div
                                                            onClick={() => handleToggleShowCertificationDetails(cert._id)}
                                                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                            >
                                                            {showCertificationDetails[cert._id] ? (
                                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                                            ) : (
                                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                                            )}
                                                        </div>
                                                        <p className='text-lg font-bold text-gray-600 mx-1 px-1'>{certIndex+1}.</p>
                                                        <div className="flex justify-center items-center rounded-full h-6 w-6 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => handleToggleShowCertificationDetails(cert._id)}><EtCapabilitiesCertificationIcon2SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                                        <p className='text-xs m-1 p-1'>{cert.certificationName}</p>
                                                    </div>
                                                    
                                                    <div className='col-span-2 flex justify-start items-center'>
                                                        <BuildingOfficeIcon className="h-5 w-5 text-orange-600" />
                                                        <p className="text-[9px] mx-1">Certifying Org:</p>
                                                        <p className="text-gray-800 text-[9px] mx-2">{cert.certificationOrg}</p>
                                                    </div>
                                                    <div className="col-span-2 flex justify-center items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">Received:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">{formatShortDate(cert.certificationDate)}</p>
                                                    </div>
                                                    <div className="col-span-2 flex justify-center items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">Valid Till:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">{formatShortDate(cert.validTill)}</p>
                                                    </div>
                                                </div>
                                                {showCertificationDetails[cert._id] && (
                                                    <div className='border rounded m-1 p-1'>
                                                        <p dangerouslySetInnerHTML={createMarkup(cert.certificationDesc)} className='text-[10px] leading-4 text-gray-600 m-1 p-1'></p>
                                                    </div>
                                                )}                                              
                                            </div>
                                        ))}
                                </div>

                                {/* Recognitions Section */}
                                <div className=' m-1 p-1 bg-gray-200 rounded '>
                                        <div className='grid grid-cols-8 justify-start items-center'>
                                            <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                                <div
                                                    onClick={()=> handleToggleShowRecognitions(stat._id)}
                                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                    >
                                                    {showRecognitions[stat._id] ? (
                                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                                    ) : (
                                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                                    )}
                                                </div>
                                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() =>handleToggleShowRecognitions(stat._id)} ><EtCapabilitiesRecognitionIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                                <div>
                                                    <p className='mx-1 px-1 font-bold text-gray-600'>Recognitions</p>
                                                    <p className='mx-1 px-1 text-gray-400 text-[10px] italic'>what are my earned softSkill honors?</p>
                                                </div>     
                                            </div>
                                        </div>
                                        
                                        {showRecognitions[stat._id] && recognitions?.length === 0 && (
                                            <div className='bg-white rounded m-1 p-1'>
                                                <p className='text-xs m-1 p-1 text-orange-600'>No recognitions have been added yet</p>
                                            </div>
                                        )}
                                        {showRecognitions[stat._id] && recognitions?.length>0 && 
                                        recognitions
                                        ?.slice()
                                        .sort(
                                            (a, b) =>
                                                new Date(b.recognitionDate) - new Date(a.recognitionDate)
                                        ) // Sort in descending order
                                        ?.map((rec, recIndex) => (
                                            <div key={rec._id+recIndex} className='bg-white rounded m-1 p-1'>
                                                
                                                <div className='grid grid-cols-7 justify-between items-center m-1 p-1'>
                                                    <div className='col-span-3 flex justify-start items-center'>
                                                        <div
                                                            onClick={() => handleToggleShowRecognitionDetails(rec._id)}
                                                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                            >
                                                            {showRecognitionDetails[rec._id] ? (
                                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                                            ) : (
                                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                                            )}
                                                        </div>
                                                        <p className='text-lg font-bold text-gray-600 mx-1 px-1'>{recIndex+1}.</p>
                                                        <div className="flex justify-center items-center rounded-full h-6 w-6 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => handleToggleShowRecognitionDetails(rec._id)}><EtCapabilitiesRecognitionIcon3SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                                        <p className='text-xs m-1 p-1'>{rec.recognitionName}</p>
                                                    </div>
                                                    
                                                    <div className='col-span-2 flex justify-start items-center'>
                                                        <BuildingOfficeIcon className="h-5 w-5 text-orange-600" />
                                                        <p className="text-[9px] mx-1">Recognized By:</p>
                                                        <p className="text-gray-800 text-[9px] mx-2">{rec.recognitionOrg}</p>
                                                    </div>
                                                    <div className="col-span-2 flex justify-center items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">Received:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">{formatDateToMonthYear(rec.recognitionDate)}</p>
                                                    </div>
                                                    
                                                </div>
                                                
                                                {showRecognitionDetails[rec._id] && (
                                                    <div className='border rounded m-1 p-1'>
                                                        <p dangerouslySetInnerHTML={createMarkup(rec.recognitionDesc)} className='text-[10px] leading-4 text-gray-600 m-1 p-1'></p>
                                                    </div>
                                                )} 
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )
                        })}

                        {/* Self Notes */}

                        <div className=' m-1 p-1 bg-gray-200 rounded '>
                                <div className='grid grid-cols-8 justify-start items-center'>
                                    <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                                        <div
                                            onClick={() => setShowNotes(prev => !prev)}
                                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                            >
                                            {showNotes ? (
                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                            ) : (
                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                            )}
                                        </div>
                                        <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowNotes(prev => !prev)} ><EtCapabilitiesSelfNotesIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                                        <div>
                                            <p className='mx-1 px-1 font-bold text-gray-600'>Notes</p>
                                            <p className='mx-1 px-1 text-gray-400 text-[10px] italic'>what are my softSkill thoughts?</p>
                                        </div>     
                                    </div>
                                </div>
                                {showNotes && capability?.userImprintCapabilitiesSoftSkillSelfNotes?.length>0 && capability?.userImprintCapabilitiesSoftSkillSelfNotes
                                ?.slice()
                                .sort(
                                    (a, b) =>
                                        new Date(b.userImprintCapabilitiesSoftSkillSelfNoteDate) - new Date(a.userImprintCapabilitiesSoftSkillSelfNoteDate)
                                ) // Sort in descending order
                                ?.map((note, noteIndex) => {
                                    return(
                                        <div key={note._id+noteIndex} className='bg-white m-1 p-1 rounded'>
                                            
                                            <div className='grid grid-cols-12 justify-between items-center m-1 p-1'>
                                                    <div className='col-span-2 flex justify-start items-center'>
                                                        <div
                                                            onClick={() => handleToggleShowNoteDetails(note._id)}
                                                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                            >
                                                            {showNoteDetails[note._id] ? (
                                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                                            ) : (
                                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                                            )}
                                                        </div>
                                                        <div>
                                                            <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ noteIndex +1}.</p>
                                                        </div>
                                                        <div className="">
                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(note.userImprintCapabilitiesSoftSkillSelfNoteUpdateDate)}
                                                            </p>
                                                            <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                        </div>
                                                    </div>     
                                                    <div className='col-span-7 flex justify-start items-center'>
                                                        <div
                                                            dangerouslySetInnerHTML={createMarkup(note.userImprintCapabilitiesSoftSkillSelfNoteText)}
                                                            className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                        ></div>
                                                    </div>                                             
                                                    <div className='col-span-3 flex justify-start items-center mx-2'>
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(note.userImprintCapabilitiesSoftSkillSelfNoteDate)}</p>
                                                    </div>
                                                    
                                                </div>
                                                
                                                {showNoteDetails[note._id] && (
                                                    <div className='border rounded m-1 p-1'>
                                                        <p dangerouslySetInnerHTML={createMarkup(note.userImprintCapabilitiesSoftSkillSelfNoteText)} className='text-[11px] leading-4 m-1 p-1'></p>
                                                    </div>
                                                )} 
                                        </div>
                                    )
                                })}
                        </div>
                        
                    </div>
                )
            })}
        </div>
    )

}

export default EtImprintCapabilitiesSoftSkill