import React, { useState, useEffect } from 'react'
import useTitle from "@/hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
    PencilSquareIcon,
    TrashIcon,
  } from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "@/utils/EtContentEditorLight2"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import { useNavigate } from "react-router"
import { useCreateNewUserImprintOverviewMutation } from './userImprintOverviewsApiSlice'
import { useGetUserImprintOverviewsQuery } from './userImprintOverviewsApiSlice'
import {produce} from 'immer'; // 29 Oct 2024

const EtUpdateUserImprintOverview = ({userId, userOrgId, showOverview, setShowOverview, showUpdateUserImprintOverview, setShowUpdateUserImprintOverview}) => {
  // First Created - 29 Oct 2024
  // Author - Atul Pandey
  // Updated - 30 Oct 2024 - refined for state errors, multiple update mutations
  // Updated - 06 Feb 2025 - refined for empty incoming stats data (if initial data is created by Trinity AI)

  // 0 - Set Page title & variables
  useTitle('Update User Imprint')

  // 1 - set page navigations
  const navigate = useNavigate()

  // 2a - set the mutation to create the new user imprint overview data
  const [createNewUserImprintOverview, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewUserImprintOverviewMutation()

  // 2b - Get existing data from the backend
  const {data: userImprintOverviewData} = useGetUserImprintOverviewsQuery()
  const userImprintOverview = userImprintOverviewData?.ids?.map(id => userImprintOverviewData?.entities[id])?.filter(item => item.userId._id === userId && item.userOrgId._id === userOrgId)
  console.log('userImprintOverview', userImprintOverview)

  

  // 3 - Initialize state variables for the form data - updated 30 Oct 2024
  const [formData, setFormData] = useState({
    userImprintOverviewName: userImprintOverview[0]?.userImprintOverviewName || "",
    userImprintOverviewDesc: userImprintOverview[0]?.userImprintOverviewDesc || "",
    userImprintOverviewStats: userImprintOverview[0]?.userImprintOverviewStats?.length > 0 // 06 Feb 2025
    ? userImprintOverview[0].userImprintOverviewStats
    : [
        {
          yearsOfExperience: 0,
          experienceSnapshot: [{ orgName: "", startDate: "", endDate: "", location: "" }],
          educationSnapshot: [{ instituteName: "", startDate: "", endDate: "", degreeName: "", degreeLevel: "", location: "" }],
        }
      ],
    // userImprintOverviewStats: userImprintOverview[0]?.userImprintOverviewStats || [
    //   {
    //     yearsOfExperience: 0,
    //     experienceSnapshot: [{ orgName: "", startDate: "", endDate: "", location: "" }],
    //     educationSnapshot: [{ instituteName: "", startDate: "", endDate: "", degreeName: "", degreeLevel: "", location: "" }],
    //   }
    // ],
    aboutMe:userImprintOverview[0]?.aboutMe || "",
    pic: "",
  })
  // const [formData, setFormData] = useState({
  //   userImprintOverviewName: "",
  //   userImprintOverviewDesc: "",
  //   userImprintOverviewStats: [
  //     {
  //       yearsOfExperience: 0,
  //       experienceSnapshot: [{ orgName: "", startDate: "", endDate: "", location: "" }],
  //       educationSnapshot: [{ instituteName: "", startDate: "", endDate: "", degreeName: "", degreeLevel: "", location: "" }],
  //     }
  //   ],
  //   aboutMe: "",
  //   pic: "",
  // });
  
  // useEffect(() => {
  //   if (userImprintOverview && userImprintOverview[0]) {
  //     setFormData({
  //       userImprintOverviewName: userImprintOverview[0]?.userImprintOverviewName,
  //       userImprintOverviewDesc: userImprintOverview[0]?.userImprintOverviewDesc,
  //       userImprintOverviewStats: userImprintOverview[0]?.userImprintOverviewStats || formData.userImprintOverviewStats,
  //       aboutMe: userImprintOverview[0]?.aboutMe,
  //       pic: "",
  //     });
  //   }
  // }, [userImprintOverview]);
  

  // 4 - Initialize state variables for the editor key and value
  const [editorKey, setEditorKey] = useState("overviewDesc-" + Date.now())
  const [editorValue, setEditorValue] = useState(formData.userImprintOverviewDesc)
  
  // 5 - Handle form field changes
  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData(prevState => ({ ...prevState, pic: files[0] }))
    } else {
      setFormData(prevState => ({ ...prevState, [name]: value }))
    }
  }

  // 5a - Handle editor value change
  const handleEditorChange = (content) => {
    setEditorValue(content)
    setFormData(prevState => ({ ...prevState, userImprintOverviewDesc: content }))
  }

  // 5b - Generic Handle Add Field Function
  const handleAdd = (fieldName, newItem) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: [...prevState[fieldName], newItem]
    }))
  }

  // 5c - Generic Handle Remove Field Function
  const handleRemove = (fieldName, index) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: prevState[fieldName]?.filter((_, i) => i !== index)
    }))
  }

  // 5d - Handle adding experience snapshot
  const handleAddExperienceSnapshot = () => {
    // setFormData((prevState) => {
    //   // Deep clone the userImprintOverviewStats to avoid accidental double updates
    //   const updatedStats = JSON.parse(JSON.stringify(prevState.userImprintOverviewStats));
    //   updatedStats[0].experienceSnapshot.push({
    //     orgName: "", startDate: "", endDate: "", location: ""
    //   });
    //   return { ...prevState, userImprintOverviewStats: updatedStats };
    // });
    setFormData((prevState) =>
      produce(prevState, (draft) => {
        draft.userImprintOverviewStats[0]?.experienceSnapshot.push({
          orgName: "", startDate: "", endDate: "", location: ""
        });
      })
    );
    
  };

  // 5e - Handle removing experience snapshot
  // const handleRemoveExperienceSnapshot = (index) => {
  //   handleRemove("userImprintOverviewStats[0].experienceSnapshot", index)
  // }
  const handleRemoveExperienceSnapshot = (index) => {
    setFormData((prevState) =>
      produce(prevState, (draft) => {
        draft.userImprintOverviewStats[0].experienceSnapshot[index].delete = true;
        // draft.userImprintOverviewStats[0].experienceSnapshot.splice(index, 1);
      })
    );
  };
  

  // 5f - Handle adding education snapshot
  const handleAddEducationSnapshot = () => {
    // setFormData((prevState) => {
    //     // Deep clone the userImprintOverviewStats to avoid accidental double updates
    //     const updatedStats = JSON.parse(JSON.stringify(prevState.userImprintOverviewStats));
    //     updatedStats[0].educationSnapshot.push({
    //       instituteName: "", startDate: "", endDate: "", degreeName: "", degreeLevel: "", location: ""
    //     });
    //     return { ...prevState, userImprintOverviewStats: updatedStats };
    //   });
    setFormData((prevState) =>
      produce(prevState, (draft) => {
        draft.userImprintOverviewStats[0]?.educationSnapshot?.push({
          instituteName: "", startDate: "", endDate: "", degreeName: "", degreeLevel: "", location: ""
        });
      })
    );
  }

  

  // 5g - Handle removing education snapshot
  const handleRemoveEducationSnapshot = (index) => {
    // setFormData((prevState) => {
    //   // Deep clone the userImprintOverviewStats to avoid reference issues
    //   const updatedStats = JSON.parse(JSON.stringify(prevState.userImprintOverviewStats));
    //   updatedStats[0].educationSnapshot = updatedStats[0].educationSnapshot.filter((_, i) => i !== index);
    //   return { ...prevState, userImprintOverviewStats: updatedStats };
    // });
    setFormData((prevState) =>
      produce(prevState, (draft) => {
        draft.userImprintOverviewStats[0].educationSnapshot[index].delete = true;
        // draft.userImprintOverviewStats[0].educationSnapshot.splice(index, 1);
      })
    );
  };

  // 5h - Generic Handle Field Change Function
  const handleFieldChange = (section, index, updatedItem) => {
    // setFormData((prevState) => {
    //   const updatedStats = [...prevState.userImprintOverviewStats];
    //   updatedStats[0][section] = updatedStats[0][section]?.map((item, i) =>
    //     i === index ? updatedItem : item
    //   );
    //   return { ...prevState, userImprintOverviewStats: updatedStats };
    // });
    setFormData((prevState) =>
      produce(prevState, (draft) => {
        draft.userImprintOverviewStats[0][section][index] = updatedItem;
      })
    );
    
  };
  

  // 6 - Form submit logic
  const handleSubmit = async (e) => {
    e.preventDefault()

    const imprintData = {
        userId, 
        userOrgId,
        userImprintOverviewName: formData.userImprintOverviewName,
        userImprintOverviewDesc: formData.userImprintOverviewDesc,
        userImprintOverviewStats: formData.userImprintOverviewStats,
        aboutMe: formData.aboutMe,
    }

    if (formData.pic) {
      const reader = new FileReader()
      reader.onloadend = async () => {
        imprintData.pic = reader.result

        try {
          await createNewUserImprintOverview(imprintData).unwrap()
        } catch (error) {
          console.error(error)
        }
      }
      reader.readAsDataURL(formData.pic)
    } else {
      try {
        await createNewUserImprintOverview(imprintData).unwrap()
      } catch (error) {
        console.error(error)
      }
    }
  }

  // 7 - Reset form upon successful submission
  useEffect(() => {
    if(isSuccess) {
      setFormData({
        userImprintOverviewName: "",
        userImprintOverviewDesc: "",
        userImprintOverviewStats: [
          {
            yearsOfExperience: 0,
            experienceSnapshot: [{ orgName: "", startDate: "", endDate: "", location: "" }],
            educationSnapshot: [{ instituteName: "", startDate: "", endDate: "", degreeName: "", degreeLevel: "", location: "" }],
          }
        ],
        aboutMe: "",
        pic: "",
      })
      setShowUpdateUserImprintOverview(false)
      navigate('/etapp')
    }
  
    },[isSuccess,navigate])

  // 8 - Handle Cancel
  const handleCancel = () => {
    setShowOverview(false)
    setShowUpdateUserImprintOverview(false)
    navigate("/etapp")
  }

  // 9 - Handle Clear
  const handleClear = () => {
    setFormData({
        userImprintOverviewName: "",
        userImprintOverviewDesc: "",
        userImprintOverviewStats: [
          {
            yearsOfExperience: 0,
            experienceSnapshot: [{ orgName: "", startDate: "", endDate: "", location: "" }],
            educationSnapshot: [{ instituteName: "", startDate: "", endDate: "", degreeName: "", degreeLevel: "", location: "" }],
          }
        ],
        aboutMe: "",
        pic: "",
    })
  }

  return (
    <div className="">
      {isLoading && <PulseLoader color={"#ea580c"} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">
          {error?.data?.message}
        </p>
      )}
      {showOverview && (
      <div className="bg-white p-1 m-1 rounded shadow-md shadow-black">
        <p className="p-1 m-1 font-bold font-poppins text-xl">Update User Imprint Overview</p>

        <form onSubmit={handleSubmit} className="m-1 p-1 text-xs font-poppins">

          <div id="userImprintOverviewName" className="bg-gray-100 rounded p-1 m-1">
            <input
              type="text"
              name="userImprintOverviewName"
              placeholder="Enter your greeting or headline here!"
              value={formData.userImprintOverviewName}
              onChange={handleChange}
              className="w-1/2 rounded p-2 m-2 inline-block"
            />
          </div>

          <div className="bg-white w-full rounded p-1 m-1">
            <EtContentEditorLight2
              value={editorValue}
              handleChange={handleEditorChange}
              placeholderText="Write your magical imprint overview here!"
              editorKey={editorKey}
            />
          </div>

          {/* Years of Experience Field */}
            <div className="flex flex-col m-1 p-1">
                <input
                    type="number"
                    name="yearsOfExperience"
                    placeholder="Years of Experience"
                    value={formData.userImprintOverviewStats[0]?.yearsOfExperience || 0}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10) || 0;
                      setFormData((prevState) =>
                        produce(prevState, (draft) => {
                          draft.userImprintOverviewStats[0].yearsOfExperience = value;
                        })
                      );
                    }}
                    className="w-full rounded p-1 m-1 text-xs border"
                />
            </div>

          <div id="experienceSnapshot" className='border rounded m-1 p-1 bg-gray-200'>

          <div className='flex justify-between items-center m-1 p-1 '>
                <p className="font-bold mx-1 px-1">Experience Snapshot</p>
                <div className='flex justify-start items-center m-1 p-1'>
                    <div><p className='text-gray-800 font-bold text-[11px] mx-1 px-1'>Add</p></div>
                    <div className='rounded bg-lime-600 hover:bg-green-600'>
                        <button type="button" onClick={handleAddExperienceSnapshot}>
                            <PlusIcon className="h-4 w-4 text-white" />
                        </button>
                    </div>
                </div>
            </div>

            
            {formData.userImprintOverviewStats[0]?.experienceSnapshot
            ?.filter(item => !item.delete)
            ?.map((item, index) => (
              <div key={index} className="flex items-center m-1 p-1">
                <input
                  type="text"
                  name="orgName"
                  placeholder="Organization Name"
                  value={item.orgName}
                  onChange={(e) =>
                    handleFieldChange("experienceSnapshot", index, {
                      ...item,
                      orgName: e.target.value,
                    })
                  }
                  className="w-full rounded p-1 m-1 text-xs"
                />
                {/* Start Date Field */}
                <input
                type="date"
                name="startDate"
                placeholder="Start Date"
                value={item.startDate ? item.startDate.split('T')[0] : ''}
                onChange={(e) =>
                    handleFieldChange("experienceSnapshot", index, {
                    ...item,
                    startDate: e.target.value,
                    })
                }
                className="w-full rounded p-1 m-1 text-xs"
                />
                
                {/* End Date Field */}
                <input
                type="date"
                name="endDate"
                placeholder="End Date"
                value={item.endDate ? item.endDate.split('T')[0] : ''}
                onChange={(e) =>
                    handleFieldChange("experienceSnapshot", index, {
                    ...item,
                    endDate: e.target.value,
                    })
                }
                className="w-full rounded p-1 m-1 text-xs"
                />

                {/* Location Field */}
                <input
                type="text"
                name="location"
                placeholder="Location"
                value={item.location}
                onChange={(e) =>
                    handleFieldChange("experienceSnapshot", index, {
                    ...item,
                    location: e.target.value,
                    })
                }
                className="w-full rounded p-1 m-1 text-xs"
                />
                    <div className='rounded bg-red-500 m-1 p-1'>
                    <button type="button" onClick={() => handleRemoveExperienceSnapshot(index)}>
                            <MinusIcon className="h-4 w-4 text-white" />
                    </button>
                    </div>
              </div>
            ))}
            
          </div>

          <div id="educationSnapshot" className='border rounded m-1 p-1 bg-gray-200'>

            <div className='flex justify-between items-center m-1 p-1 '>
                <p className="font-bold mx-1 px-1">Education Snapshot</p>
                <div className='flex justify-start items-center m-1 p-1'>
                    <div><p className='text-gray-800 font-bold text-[11px] mx-1 px-1'>Add</p></div>
                    <div className='rounded bg-lime-600 hover:bg-green-600'>
                        <button type="button" onClick={handleAddEducationSnapshot}>
                            <PlusIcon className="h-4 w-4 text-white" />
                        </button>
                    </div>
                </div>
            </div>

            {formData.userImprintOverviewStats[0]?.educationSnapshot
            ?.filter(item => !item.delete) 
            ?.map((item, index) => (
              <div key={index} className="flex items-center m-1 p-1">
                <input
                  type="text"
                  name="instituteName"
                  placeholder="Institute Name"
                  value={item.instituteName}
                  onChange={(e) =>
                    handleFieldChange("educationSnapshot", index, {
                      ...item,
                      instituteName: e.target.value,
                    })
                  }
                  className="w-full rounded p-1 m-1 text-xs"
                />
                {/* Start Date Field */}
                <input
                type="date"
                name="startDate"
                placeholder="Start Date"
                value={item.startDate ? item.startDate.split('T')[0] : ''}
                onChange={(e) =>
                    handleFieldChange("educationSnapshot", index, {
                    ...item,
                    startDate: e.target.value,
                    })
                }
                className="w-full rounded p-1 m-1 text-xs"
                />
                
                {/* End Date Field */}
                <input
                type="date"
                name="endDate"
                placeholder="End Date"
                value={item.endDate ? item.endDate.split('T')[0] : ''}
                onChange={(e) =>
                    handleFieldChange("educationSnapshot", index, {
                    ...item,
                    endDate: e.target.value,
                    })
                }
                className="w-full rounded p-1 m-1 text-xs"
                />

                {/* DegreeName Field */}
                <input
                type="text"
                name="degreeName"
                placeholder="DegreeName"
                value={item.degreeName}
                onChange={(e) =>
                    handleFieldChange("educationSnapshot", index, {
                    ...item,
                    degreeName: e.target.value,
                    })
                }
                className="w-full rounded p-1 m-1 text-xs"
                />

                {/* DegreeLevel Field */}
                <input
                type="text"
                name="degreeLevel"
                placeholder="DegreeLevel"
                value={item.degreeLevel}
                onChange={(e) =>
                    handleFieldChange("educationSnapshot", index, {
                    ...item,
                    degreeLevel: e.target.value,
                    })
                }
                className="w-full rounded p-1 m-1 text-xs"
                />

                {/* Location Field */}
                <input
                type="text"
                name="location"
                placeholder="Location"
                value={item.location}
                onChange={(e) =>
                    handleFieldChange("educationSnapshot", index, {
                    ...item,
                    location: e.target.value,
                    })
                }
                className="w-full rounded p-1 m-1 text-xs"
                />

                <div className='rounded bg-red-500 m-1 p-1'>
                    <button type="button" onClick={() => handleRemoveEducationSnapshot(index)}>
                            <MinusIcon className="h-4 w-4 text-white" />
                    </button>
                    </div>
              </div>
            ))}
           
          </div>

          <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Update"}
                type={"create"}
                handleClick={handleSubmit}
              />
              <EtBtnFormBasic
                name={"Clear"}
                type={"clear"}
                handleClick={handleClear}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>
        </form>
      </div>)}
    </div>
  )
}



export default EtUpdateUserImprintOverview