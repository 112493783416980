const SectionData1 = [
    {sectionScore: 1},
    {sectionScore: 2},
    {sectionScore: 1.2},
    {sectionScore: 0.1},
    {sectionScore: 0},
    {sectionScore: 0},
    {sectionScore: 0},
    {sectionScore: 1},
    {sectionScore: 0},
    {sectionScore: 1},
    {sectionScore: 1},
    {sectionScore: 3},
    {sectionScore: 1},
    {sectionScore: 0},
    {sectionScore: 4},
    {sectionScore: 1},
]
const SectionData2 = [
    {sectionScore: 5},
    {sectionScore: 5},
    {sectionScore: 5},
    {sectionScore: 5},
    {sectionScore: 5},
    {sectionScore: 5},
    {sectionScore: 5},
    {sectionScore: 5},
    {sectionScore: 5},
    {sectionScore: 5},
    {sectionScore: 5},
    {sectionScore: 5},
    {sectionScore: 5},
    {sectionScore: 5},
    {sectionScore: 5},
    {sectionScore: 5},
]
const SectionData3 = [
    {sectionScore: 2},
    {sectionScore: 3},
    {sectionScore: 3},
    {sectionScore: 1},
    {sectionScore: 2},
    {sectionScore: 3},
    {sectionScore: 4},
    {sectionScore: 4},
    {sectionScore: 3},
    {sectionScore: 2},
    {sectionScore: 2},
    {sectionScore: 1},
    {sectionScore: 3},
    {sectionScore: 3},
    {sectionScore: 3},
    {sectionScore: 4},
]
const SectionData4 = [
    {sectionScore: 1},
    {sectionScore: 2},
    {sectionScore: 1.2},
    {sectionScore: 0.1},
    {sectionScore: 0},
    {sectionScore: 0},
    {sectionScore: 0},
    {sectionScore: 1},
    {sectionScore: 0},
    {sectionScore: 1},
    {sectionScore: 1},
    {sectionScore: 3},
    {sectionScore: 1},
    {sectionScore: 0},
    {sectionScore: 4},
    {sectionScore: 1},
]
const SectionData5 = [
    {sectionScore: 0},
    {sectionScore: 1},
    {sectionScore: 0.2},
    {sectionScore: 0.1},
    {sectionScore: 0},
    {sectionScore: 0},
    {sectionScore: 1},
    {sectionScore: 1},
    {sectionScore: 2},
    {sectionScore: 1},
    {sectionScore: 0},
    {sectionScore: 0},
    {sectionScore: 0.2},
    {sectionScore: 0},
    {sectionScore: 2},
    {sectionScore: 0.1},
]
const SectionData6 = [
    {sectionScore: 1},
    {sectionScore: 2},
    {sectionScore: 1},
    {sectionScore: 1},
    {sectionScore: 2},
    {sectionScore: 2},
    {sectionScore: 3},
    {sectionScore: 1},
    {sectionScore: 0.1},
    {sectionScore: 2},
    {sectionScore: 3},
    {sectionScore: 1},
    {sectionScore: 1},
    {sectionScore: 2},
    {sectionScore: 1},
    {sectionScore: 0},
]

export {
    SectionData1, 
    SectionData2,
    SectionData3,
    SectionData4,
    SectionData5,
    SectionData6,
}