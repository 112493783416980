import React, { useState, useEffect } from 'react'
import useTitle from '@/hooks/useTitle'
import { useNavigate } from 'react-router'
import { userProfileIcon2 } from '@/assets/img/imgAssets'
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon,  } from '@heroicons/react/24/outline'
import {
  UserGroupIcon,
  EnvelopeIcon,
  PowerIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/solid"
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import PulseLoader from "react-spinners/PulseLoader"
import { useGetUsersQuery, useGetUserPicQuery } from '../users/usersApiSlice'
import { useGetUserOrgSubscriptionsQuery } from '../userSubscriptions/userOrgSubscriptionsApiSlice'
import { useGetUserTrailSessionPlansQuery } from './userTrailSessionPlansApiSlice'
import { useCreateNewUserTrailSessionExecutionMutation, useGetUserTrailSessionExecutionsQuery } from './userTrailSessionExecutionsApiSlice' // 02 Jun 2024 , 05 Jun 2024 - useGetQuery
import { useGetUserSessionIntroductionsQuery } from './userSessionIntroductionsApiSlice'
import { useGetUserSessionIntrospectionsQuery } from './userSessionIntrospectionsApiSlice' // 13 Jun 2024
import { useGetUserSessionInvestigationsQuery } from './userSessionInvestigationApiSlice' //  02 Jul 2024
import { useGetUserSessionImaginationsQuery } from './userSessionImaginationsApiSlice' // 17 Jul 2024
import { useGetUserSessionImplementationsQuery } from './userSessionImplementationsApiSlice' // 17 Jul 2024
import { useGetUserSessionIlluminationsQuery } from './userSessionIlluminationsApiSlice' // 18 Jul 2024
import { useGetUserSession8848BBDBsQuery } from './userSession8848BBDBsApiSlice' // 27 Jul 2024
import { useGetUserSession8848KYCsQuery } from './userSession8848KYCsApiSlice' // 29 Jul 2024
import { useGetUserSession8848O2DsQuery } from './userSession8848O2DsApiSlice' // 29 Jul 2024
import { useGetUserSession8848NLGsQuery } from './userSession8848NLGsApiSlice' // 29 Jul 2024
import { useGetUserSession8848SSCsQuery } from './userSession8848SSCsApiSlice' // 29 Jul 2024
import EtUserTrailSession8848KYCComp from './EtUserTrailSession8848KYCComp' // 20 Oct 2024


const EtUserTrailSessionsListProdComp = ({userId,onTotalSessionsUpdate}) => {
    // First Created - 23 Feb 2025
    // Author - Atul Pandey
    // Inherited from EtUserTrailSessionsListComp
    // Updated - 20 Oct 2024 - Added Map of sessionTypeName to components

    // 0 - Set Page Title
    useTitle('User Trail Sessions List')

    // 1 - set page navigations
    const navigate = useNavigate()

   // 2 - Initialize showDetails state as an object where key is the organization index
   const [showDetails, setShowDetails] = useState(false)
   const [showUserDetails, setShowUserDetails] = useState({}) // 25 May 2024
   const [showTrailSessions, setShowTrailSessions] = useState({}) // 27 May 2024 -- updated 15 Jun 2024 change the state to {} from false
   const [showTrailDetails, setShowTrailDetails] = useState({}) // 27 May 2024
  

   // 3 - Extract all the data from the DB
   const {data: usersData, isLoading, isError, error } = useGetUsersQuery()
   const {data: userOrgSubscriptionsData} = useGetUserOrgSubscriptionsQuery()
   const {data: userTrailSessionPlanData} = useGetUserTrailSessionPlansQuery()
   const {data: userTrailSessionExecutionData , isLoading: isExecutionDataLoading } = useGetUserTrailSessionExecutionsQuery() // 05 Jun 2024
   const {data: userSessionIntroductionsData} = useGetUserSessionIntroductionsQuery() // 05 Jun 2024
   const {data: userSessionIntrospectionsData} = useGetUserSessionIntrospectionsQuery() // 13 Jun 2024
   const {data: userSessionInvestigationsData} = useGetUserSessionInvestigationsQuery() // 03 Jul 2024
   const {data: userSessionImaginationsData} = useGetUserSessionImaginationsQuery() // 17 Jul 2024
   const {data: userSessionImplementationsData} = useGetUserSessionImplementationsQuery() // 17 Jul 2024
   const {data: userSessionIlluminationsData} = useGetUserSessionIlluminationsQuery() // 18 Jul 2024
   const {data: userSession8848BBDBsData} = useGetUserSession8848BBDBsQuery() // 27 Jul 2024
   const {data: userSession8848KYCsData} = useGetUserSession8848KYCsQuery() // 29 Jul 2024
   const {data: userSession8848O2DsData} = useGetUserSession8848O2DsQuery() // 29 Jul 2024
   const {data: userSession8848NLGsData} = useGetUserSession8848NLGsQuery() // 29 Jul 2024
   const {data: userSession8848SSCsData} = useGetUserSession8848SSCsQuery() // 29 Jul 2024
   const [createNewUserTrailSessionExecution] = useCreateNewUserTrailSessionExecutionMutation() // 02 Jun 2024
   //console.log('users', usersData)
   //console.log('user org subscriptions', userOrgSubscriptionsData)
   //console.log('user trail session plan', userTrailSessionPlanData)
   //console.log('user trail session execution', userTrailSessionExecutionData)

   // 3a - Build users list from extracted user entities data
   const usersList = usersData?.ids?.map((id) => {
    const user = usersData?.entities[id]
    return user
   })?.filter((user) => user?._id === userId)
  //console.log('users list:', usersList)

  // 3b - Handle toggle show user details -- 23 May 2024 --
  const handleToggleUserDetails = (userId) => {
    setShowUserDetails((prev) => ({ ...prev, [userId]: !prev[userId] }));
  }
  
  // 3c - Handle toggle show user details -- 15 Jun 2024 --
  const handleToggleTrailSessions = (userId) => {
    setShowTrailSessions((prev) => ({ ...prev, [userId]: !prev[userId] }));
  }

  // 3d - Handle toggle show trail details -- 15 Jun 2024 --
  const handleToggleTrailDetails = (userId, trailId) => {
    setShowTrailDetails((prev) => ({
        ...prev,
        [userId]: {
            ...prev[userId],
            [trailId]: !prev[userId]?.[trailId],
        },
    }));
};

  // 3e - Load state from localStorage -- 12 Jun 2024
  useEffect(() => {
    const storedShowDetails = localStorage.getItem('showDetails');
    const storedShowUserDetails = localStorage.getItem('showUserDetails');
    const storedShowTrailDetails = localStorage.getItem('showTrailDetails');
    const storedShowTrailSessions = localStorage.getItem('showTrailSessions');

    if (storedShowDetails) {
        setShowDetails(JSON.parse(storedShowDetails));
    }
    if (storedShowUserDetails) {
        setShowUserDetails(JSON.parse(storedShowUserDetails));
    }
    if (storedShowTrailDetails) {
        setShowTrailDetails(JSON.parse(storedShowTrailDetails));
    }
    if (storedShowTrailSessions) {
        setShowTrailSessions(JSON.parse(storedShowTrailSessions));
    }
}, [
  usersData, 
  userOrgSubscriptionsData, 
  userTrailSessionPlanData, 
  userTrailSessionExecutionData, 
  userSessionIntroductionsData, 
  userSessionIntrospectionsData, 
  userSessionInvestigationsData,
  userSessionImaginationsData,  // 17 Jul 2024
  userSessionImplementationsData, // 17 Jul 2024
  userSessionIlluminationsData, // 18 Jul 2024
  userSession8848BBDBsData, // 27 Jul 2024
  userSession8848KYCsData, // 29 Jul 2024
  userSession8848O2DsData, // 29 Jul 2024
  userSession8848NLGsData, // 29 Jul 2024
  userSession8848SSCsData, // 29 Jul 2024
]);

  // 3f Save state to localStorage before page reload -- 12 Jun 2024
  useEffect(() => {
    const handleBeforeUnload = () => {
        localStorage.setItem('showDetails', JSON.stringify(showDetails));
        localStorage.setItem('showUserDetails', JSON.stringify(showUserDetails));
        localStorage.setItem('showTrailDetails', JSON.stringify(showTrailDetails));
        localStorage.setItem('showTrailSessions', JSON.stringify(showTrailSessions));
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [showDetails, showUserDetails, showTrailDetails ,showTrailSessions]);

  // 3g - Function to get trail sessions for a user -- 26 May 2024 -- and group them by trail -- 27 May 2024 -- updated -- 05 Jun 2024 (user execution status)
  const getUserTrailSessionsByTrail = () => {
    if (!userTrailSessionPlanData || !userTrailSessionPlanData?.entities) return { groupedSessions: {}, totalSessions: 0 }

    const userSessions = Object.values(userTrailSessionPlanData?.entities).filter(
      (plan) => plan?.userId?._id.toString() === userId.toString()
    )

    const userSessionExecutions = userTrailSessionExecutionData?.entities ? 
      Object.values(userTrailSessionExecutionData?.entities).filter(
        (execution) => execution.userId._id === userId
      ) : []

    // Group sessions by trailId and count sessions
    const { groupedSessions, totalSessions } = userSessions?.reduce((acc, session) => {
      const trailId = session?.trailId?._id
      if (!acc.groupedSessions[trailId]) {
        acc.groupedSessions[trailId] = {
          trailInfo: session.trailId,
          sessions: [],
          sessionCount: 0, // Initialize session count per trail
        }
      }

      const sessionExecution = userSessionExecutions?.find(
        (execution) => execution.trailId._id === trailId && execution.sessionId._id === session.sessionId._id
      )

      const sessionData = {
        ...session,
        userTrailSessionStatus: sessionExecution ? sessionExecution?.userTrailSessionExecutionStatus : session.userTrailSessionStatus
      }

      acc.groupedSessions[trailId].sessions.push(sessionData)
      acc.groupedSessions[trailId].sessionCount += 1 // Increment session count for the trail
      acc.totalSessions += 1 // Increment total session count

      return acc
    }, { groupedSessions: {}, totalSessions: 0 })

    return { groupedSessions, totalSessions }
  }

 const { groupedSessions, totalSessions } = getUserTrailSessionsByTrail()

 // 3h - Call the callback to update total sessions in the parent
 useEffect(() => {
  if (onTotalSessionsUpdate) {
    onTotalSessionsUpdate(totalSessions) // Update the parent with total sessions
  }
}, [totalSessions, onTotalSessionsUpdate])

  // 4 - Function to create new user trail session execution records -- 02 Jun 2024 --
  const handleStartSession = async (userId, trailId, sessionId, sessionTypeId) => {
    try {
        const response = await createNewUserTrailSessionExecution({
            userId,
            trailId,
            sessionId
        }).unwrap()
        alert(response?.message)
        window.location.reload();
        // Redirect to the session engagement page
        if (sessionTypeId?.sessionTypeName === 'Introduction') {
            navigate(`/userTrailSessions/introduction/${userId}/${trailId}/${sessionId}`)
        }
        // 13 Jun 2024 
        if (sessionTypeId?.sessionTypeName === 'Introspection') {
            navigate(`/userTrailSessions/introspection/${userId}/${trailId}/${sessionId}`)
        }
        // 02 Jul 2024 
        if (sessionTypeId?.sessionTypeName === 'Assessment' ) {
            navigate(`/userTrailSessions/assessment/${userId}/${trailId}/${sessionId}`)
        }
        if (sessionTypeId?.sessionTypeName === 'Investigation' ) {
            navigate(`/userTrailSessions/investigation/${userId}/${trailId}/${sessionId}`)
        }
        // 17 Jul 2024
        if (sessionTypeId?.sessionTypeName === 'Imagination' ) {
          navigate(`/userTrailSessions/imagination/${userId}/${trailId}/${sessionId}`)
        }
        // 17 Jul 2024
        if (sessionTypeId?.sessionTypeName === 'Implementation' ) {
          navigate(`/userTrailSessions/implementation/${userId}/${trailId}/${sessionId}`)
        }
        // 18 Jul 2024
        if (sessionTypeId?.sessionTypeName === 'Illumination' ) {
          navigate(`/userTrailSessions/illumination/${userId}/${trailId}/${sessionId}`)
        }
        // 27 Jul 2024
        if (sessionTypeId?.sessionTypeName === '8848BBDB' ) {
          navigate(`/userTrailSessions/8848bbdb/${userId}/${trailId}/${sessionId}`)
        }

        // 29 Jul 2024
        if (sessionTypeId?.sessionTypeName === '8848KYC' ) {
          navigate(`/userTrailSessions/8848kyc/${userId}/${trailId}/${sessionId}`)
        }

        // 29 Jul 2024
        if (sessionTypeId?.sessionTypeName === '8848O2D' ) {
          navigate(`/userTrailSessions/8848o2d/${userId}/${trailId}/${sessionId}`)
        }

        // 29 Jul 2024
        if (sessionTypeId?.sessionTypeName === '8848NLG' ) {
          navigate(`/userTrailSessions/8848nlg/${userId}/${trailId}/${sessionId}`)
        }

        // 29 Jul 2024
        if (sessionTypeId?.sessionTypeName === '8848SSC' ) {
          navigate(`/userTrailSessions/8848ssc/${userId}/${trailId}/${sessionId}`)
        }

    } catch (error) {
        console.error(error)
        alert('Failed to start the session')
    }
  }

  // 5 - Check if session has been started and get session execution status -- 05 Jun 2024
  const isSessionStarted = (userId, trailId, sessionId) => {
    const userSessionExecution = userTrailSessionExecutionData?.entities ? Object.values(userTrailSessionExecutionData?.entities).find(
      (execution) => execution.userId._id === userId && execution.trailId._id === trailId && execution.sessionId._id === sessionId
    ) : null

    const userSessionIntroduction = userSessionIntroductionsData?.entities ? Object.values(userSessionIntroductionsData?.entities).find(
      (intro) => intro.userId._id === userId && intro.trailId._id === trailId && intro.sessionId._id === sessionId
    ) : null

    // 13 Jun 2024
    const userSessionIntrospection = userSessionIntrospectionsData?.entities ? Object.values(userSessionIntrospectionsData?.entities).find(
      (intro) => intro.userId._id === userId && intro.trailId._id === trailId && intro.sessionId._id === sessionId
    ) : null
    
    // 02 Jul 2024
    const userSessionInvestigation = userSessionInvestigationsData?.entities ? Object.values(userSessionInvestigationsData?.entities).find(
      (intro) => intro.userId._id === userId && intro.trailId._id === trailId && intro.sessionId._id === sessionId
    ) : null
    
    // 17 Jul 2024
    const userSessionImagination = userSessionImaginationsData?.entities ? Object.values(userSessionImaginationsData?.entities).find(
      (intro) => intro.userId._id === userId && intro.trailId._id === trailId && intro.sessionId._id === sessionId
    ) : null

    // 17 Jul 2024
    const userSessionImplementation = userSessionImplementationsData?.entities ? Object.values(userSessionImplementationsData?.entities).find(
      (intro) => intro.userId._id === userId && intro.trailId._id === trailId && intro.sessionId._id === sessionId
    ) : null

    // 18 Jul 2024
    const userSessionIllumination = userSessionIlluminationsData?.entities ? Object.values(userSessionIlluminationsData?.entities).find(
      (intro) => intro.userId._id === userId && intro.trailId._id === trailId && intro.sessionId._id === sessionId
    ) : null

    // 27 Jul 2024
    const userSession8848BBDB = userSession8848BBDBsData?.entities ? Object.values(userSession8848BBDBsData?.entities).find(
      (intro) => intro.userId._id === userId && intro.trailId._id === trailId && intro.sessionId._id === sessionId
    ) : null

    // 29 Jul 2024
    const userSession8848KYC = userSession8848KYCsData?.entities ? Object.values(userSession8848KYCsData?.entities).find(
      (intro) => intro.userId._id === userId && intro.trailId._id === trailId && intro.sessionId._id === sessionId
    ) : null

    // 29 Jul 2024
    const userSession8848O2D = userSession8848O2DsData?.entities ? Object.values(userSession8848O2DsData?.entities).find(
      (intro) => intro.userId._id === userId && intro.trailId._id === trailId && intro.sessionId._id === sessionId
    ) : null

    // 29 Jul 2024
    const userSession8848NLG = userSession8848NLGsData?.entities ? Object.values(userSession8848NLGsData?.entities).find(
      (intro) => intro.userId._id === userId && intro.trailId._id === trailId && intro.sessionId._id === sessionId
    ) : null

    // 29 Jul 2024
    const userSession8848SSC = userSession8848SSCsData?.entities ? Object.values(userSession8848SSCsData?.entities).find(
      (intro) => intro.userId._id === userId && intro.trailId._id === trailId && intro.sessionId._id === sessionId
    ) : null


    return (userSessionExecution && userSessionIntroduction) || (userSessionExecution && userSessionIntrospection) || (userSessionExecution && userSessionInvestigation) || (userSessionExecution && userSessionImagination) || (userSessionExecution && userSessionImplementation) || (userSessionExecution && userSessionIllumination) || (userSessionExecution && userSession8848BBDB) || (userSessionExecution && userSession8848KYC) || (userSessionExecution && userSession8848O2D) || (userSessionExecution && userSession8848NLG) || (userSessionExecution && userSession8848SSC) // 13 Jun 2024 - added || condition, 02 Jul 2024 - added investigation , 17 Jul 2024 - added imagination and implementation, 18 Jul 2024 - added illumination, 27 Jul 2024 - added 8848 BBDB, 29 Jul 2024 - added 8848 KYC, O2D, NLG and SSC
  }

  

  if (isLoading || isExecutionDataLoading) return <PulseLoader color={"#ea580c"} />
  if (isError) return <div>Error occurred: {error?.data?.message}</div>

  return (
        <div>
          {usersList?.map((user, userIndex) => (
            <div
              key={user._id + userIndex}
            >                
                <div>
                  <div className="m-1 p-1 rounded bg-gray-200">
                    {Object.entries(groupedSessions)?.length > 0 ? (
                      Object.entries(groupedSessions)?.map(([trailId, { trailInfo, sessions, sessionCount }], trailIndex) => {
                        return (
                          // User Trail Info bar section
                          <div key={trailId + user._id + trailIndex} className="m-1 p-1 bg-white rounded">
                            <div className='grid grid-cols-8 justify-start items-center'>
                              <div className='col-span-2 m-1 p-1 flex justify-start items-center'>
                                <div
                                  className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white"
                                  onClick={() => handleToggleTrailDetails(user._id, trailId)}
                                >
                                  {showTrailDetails[user._id]?.[trailId] ? (
                                    <MinusIcon className="h-5 w-5 text-orange-600" />
                                  ) : (
                                    <PlusIcon className="h-5 w-5 text-orange-600" />
                                  )}
                                </div>         
                                <div className='flex justify-start items-center'>
                                  <div className='mx-2 flex justify-center items-center rounded-full bg-gray-50 hover:bg-gray-100 h-8 w-8'>
                                    <img src={trailInfo?.pic} alt="" className='h-6 w-6' />
                                  </div>
                                  <p className="text-sm font-bold">{trailInfo?.trailName}</p>
                                </div>
                              </div>
                              <div className='col-span-1 flex justify-start items-center'>
                                  <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{sessionCount}</p></div>
                              </div>
                            </div>
                            
                            {/* User Sessions section */}
                            {showTrailDetails[user._id]?.[trailId] && sessions?.map((session, sessionIndex) => {
                              return (
                                <div key={session._id + sessionIndex} className="m-1 p-1 bg-gray-100 rounded">
                                  <div className='m-1 p-1 grid grid-cols-10 justify-between items-center'>
                                    <div className='col-span-5 flex justify-start items-center'>
                                      <div className='flex justify-center items-center rounded-full bg-gray-50 hover:bg-white h-7 w-7'>
                                        <img src={session?.sessionId?.pic} alt="" className='h-5 w-5' />
                                      </div>
                                    
                                      <div className='m-1 p-1'>
                                        <p className='text-sm font-bold mx-1'>Session {session?.sessionId?.sessionSequence} - {session?.sessionId?.sessionName}</p>
                                        <p className='text-[10px] m-1'>{session?.sessionId?.sessionDesc}</p>
                                      </div>
                                    </div>
                                    
                                    <div className='col-span-3 m-1 p-1 flex justify-start items-center'>
                                      <ExploreOutlinedIcon sx={{ color: session?.userTrailSessionStatus === 'Started' ? '#84cc16' : '#d4d4d4' }} />
                                      <p className={`text-[10px] mx-1 ${isSessionStarted(user?._id, trailInfo?._id, session?.sessionId._id) ? 'text-lime-600' : 'text-gray-400'}`}>
                                        {session?.userTrailSessionStatus}
                                      </p>
                                    </div>

                                    <div className='col-span-2 flex m-1 p-1 justify-between items-center'>
                                      {isSessionStarted(user?._id, trailInfo?._id, session?.sessionId._id) ? (
                                        <div 
                                          className='flex justify-center items-center border border-white cursor-pointer rounded shadow p-1'
                                          // onClick={() => { navigate(`/userMagicHome/${session?.sessionId?.sessionTypeId?.sessionTypeName.toLowerCase()}/${user?._id}/${trailInfo?._id}/${session?.sessionId._id}`) }}
                                           onClick={() => { navigate(`/etapp/${session?.sessionId?.sessionTypeId?.sessionTypeName.toLowerCase()}/${user?._id}/${trailInfo?._id}/${session?.sessionId._id}`) }} // 23 Feb 2025
                                        >
                                          
                                          <div><p className='text-[10px] mx-1 px-1 text-gray-400'>Go to session</p></div>
                                          <div>
                                            <ArrowRightCircleIcon className='h-6 w-6 text-lime-600'/>
                                          </div>
                                  
                                        </div>
                                      ) : (
                                        <div 
                                          className='flex justify-center items-center border border-orange-600 rounded p-1 shadow cursor-pointer'
                                          onClick={() => handleStartSession(user?._id, trailInfo?._id, session?.sessionId?._id, session?.sessionId.sessionTypeId)}
                                        >
                                          <div><p className='text-[10px] mx-1 px-1 text-gray-400'>Start the session</p></div>
                                          <div>
                                            <PowerIcon className='h-5 w-5 text-orange-600'/>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        )
                      })
                    ) : (
                      <p className="text-xs">No sessions planned</p>
                    )}
                  </div>

                  {/* <div className="m-1 p-1 rounded bg-gray-200">
                    {Object.entries(getUserTrailSessionsByTrail())?.length > 0 ? (
                      Object.entries(getUserTrailSessionsByTrail())?.map(([trailId, { trailInfo, sessions }], trailIndex) => {
                        // console.log('trailId inside object.entries', trailId)
                        // console.log('{trailInfo, sessions}', {trailInfo, sessions})
                        return (
                        // User Trail Info bar section
                        <div key={trailId+user._id+trailIndex} className="m-1 p-1 bg-white rounded">
                          <div className='flex justify-between items-center'>
                            <div className='m-1 p-1 flex justify-start items-center'>
                              <div
                                  className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white"
                                  onClick={() => handleToggleTrailDetails(user._id, trailId)}
                                >
                                  {showTrailDetails[user._id]?.[trailId] ? (
                                    <MinusIcon className="h-5 w-5 text-orange-600" />
                                  ) : (
                                    <PlusIcon className="h-5 w-5 text-orange-600" />
                                  )}
                                </div>         
                                <div className='flex justify-start items-center'>
                                    <div className='mx-2 flex justify-center items-center rounded-full bg-gray-50 hover:bg-gray-100 h-8 w-8'>
                                        <img src={trailInfo?.pic} alt="" className='h-6 w-6' />
                                    </div>
                                    <p className="text-sm font-bold">{trailInfo?.trailName}</p>

                                </div>
                            </div>
                          </div>
                          
                          
                          {showTrailDetails[user._id]?.[trailId] && sessions?.map((session, sessionIndex) => {
                            // console.log('Session', session)
                            
                            return (
                          <div key={session._id + sessionIndex} className="m-1 p-1 bg-gray-100 rounded">
                            <div className='m-1 p-1 grid grid-cols-10 justify-between items-center'>

                              <div className='col-span-5 flex justify-start items-center'>
                                <div className='flex justify-center items-center rounded-full bg-gray-50 hover:bg-white h-7 w-7'>
                                  <img src={session?.sessionId?.pic} alt="" className='h-5 w-5' />
                                </div>
                              
                                <div className='m-1 p-1'>
                                    <p className='text-sm font-bold mx-1'>Session {session?.sessionId?.sessionSequence} - {session?.sessionId?.sessionName}</p>
                                    <p className='text-[10px] m-1'>{session?.sessionId?.sessionDesc}</p>
                                </div>

                              </div>
                              
                              <div className='col-span-3 m-1 p-1 flex justify-start items-center'>
                                <ExploreOutlinedIcon sx={{color: session?.userTrailSessionStatus === 'Started' ? '#84cc16' : '#d4d4d4'}}/>
                               <p className={`text-[10px] mx-1 ${isSessionStarted(user?._id, trailInfo?._id, session?.sessionId._id) ? 'text-lime-600': 'text-gray-400'}`}>{session?.userTrailSessionStatus}</p>
                              </div>

                              <div className='col-span-2 flex m-1 p-1 justify-between items-center '>
                                      {isSessionStarted(user?._id, trailInfo?._id, session?.sessionId._id) ? (
                                        <div 
                                          className='flex justify-start items-center border border-lime-600 rounded-full bg-lime-300 hover:bg-lime-600 hover:text-white cursor-pointer'
                                          onClick={() => {navigate(`/userMagicHome/${session?.sessionId?.sessionTypeId?.sessionTypeName.toLowerCase()}/${user?._id}/${trailInfo?._id}/${session?.sessionId._id}`
                                            )
                                          }}
                                        >
                                          <div><p className='text-[11px] px-2 py-1'>Resume</p></div>
                                        </div>
                                      ) : (
                                        <div 
                                          className='flex justify-start items-center border border-orange-600 rounded-full hover:bg-orange-600 hover:text-white cursor-pointer'
                                          onClick={() => handleStartSession(user?._id, trailInfo?._id, session?.sessionId?._id, session?.sessionId.sessionTypeId)}
                                        >
                                          <div><p className='text-[11px] px-2 py-1'>Start Now</p></div>
                                        </div>
                                      )}
                                    </div>
                            </div>
                          </div>
                        )
                          })}
                        </div>
                      )})
                    ) : (
                      <p className="text-xs">No sessions planned</p>
                    )}
                  </div> */}
                </div>
            </div>
          ))}
        </div> 
  )
}

const User = ({ username, defaultUserPic }) => {
  const { data: userPic, isFetching: isFetchingUserPic } =
    useGetUserPicQuery(username)

  return (
    <div className="flex justify-center items-center h-12 w-12 rounded-full border border-white bg-gradient-to-r from-orange-600 to-orange-400 shadow-lg shadow-black cursor-pointer">
      {/* Display user picture or loading indicator*/}
      {isFetchingUserPic ? (
        <div>Loading...</div>
      ) : userPic ? (
        <img src={userPic} alt="User" className="h-11 w-11  rounded-full" />
      ) : (
        <img src={defaultUserPic} alt="User" className="h-10 w-10 p-1" />
      )}
    </div>
  )
}

export default EtUserTrailSessionsListProdComp