import React, { useState } from 'react'
import useTitle from '@/hooks/useTitle'
import { useNavigate } from 'react-router'
import { addIcon } from '@/assets/img/imgAssets'
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon,  } from '@heroicons/react/24/outline'
import { Square3Stack3DIcon ,CalendarDaysIcon, UserGroupIcon, ListBulletIcon, SwatchIcon, ClockIcon } from '@heroicons/react/24/solid'
import PulseLoader from "react-spinners/PulseLoader"
import { useGetSessionsQuery } from './sessionsApiSlice'

const EtSessionList = ({setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }) => {
    // First Created - 03 May 2024
    // Author - Atul Pandey
    // Updated - 01 Mar 2025 - updated the softSkillFunctionArea with props - { setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }
    // Updated - 01 Mar 2025 - switched from navigate to onAdd, onUpdate, onDelete, setSelectedEntityId

    // 0 - Set Page Title
    useTitle('Sessions List')

    // 1 - set page navigations
    const navigate = useNavigate()

    // 2 - Initialize showDetails state as an object where key is the session index
    const [showDetails, setShowDetails] = useState({})
    const [showTrails, setShowTrails] = useState({})

    // 3 - Extract the sessions data
    const { data: sessionsData, isError, error, isLoading } = useGetSessionsQuery()
    console.log('sessionsData', sessionsData)

    // 4 - Toggle Show function for session details
    const handleToggleDetails = (sessionId) => {
        setShowDetails(prev => ({ ...prev, [sessionId]: !prev[sessionId] }));
    }

    // 4a - Toggle Show function for trails
    const handleToggleTrails = (trailId) => {
        setShowTrails(prev => ({ ...prev, [trailId]: !prev[trailId] }));
    }

    // 5 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
        return { __html: htmlContent }
    }

    // 6 - Optional: handle loading and error states
    if (isLoading) return <PulseLoader color={"#ea580c"} />
    if (isError) return <div>Error occurred: {error?.data?.message}</div>

    // 7 - Grouping sessions by trail
    const trails = {};
    sessionsData?.ids.forEach(id => {
        const session = sessionsData?.entities[id];
        const { trailId, sessionIntro, sessionSequence, sessionName, sessionTypeId, sessionDesc, sessionTargetAudience, pic, sessionPlannedDateSlot, sessionPreWork, sessionOutcomes} = session;
        if (!trails[trailId._id]) {
            trails[trailId._id] = {
                trailName: trailId.trailName,
                trailPic: trailId.pic,
                trailDesc: trailId.trailDesc,
                trailTargetAudience: trailId.trailTargetAudience,
                trailEstimatedDuration: trailId.estimatedDuration,
                sessions: []
            };
        }
        trails[trailId._id].sessions.push({
            id,
            sessionIntro,
            sessionSequence,
            sessionName,
            sessionTypeName: sessionTypeId.sessionTypeName,
            sessionDesc,
            sessionTargetAudience,
            pic,
            sessionPlannedDateSlot,
            sessionPreWork,
            sessionOutcomes,
        });
    });

    console.log('trails', trails)

    return (
        <div className='bg-gray-200 m-1 p-1 rounded font-poppins mx-auto'>
            {/* Header */}
            <div className='flex justify-start items-center m-1 p-1'>
                <div><p className='text-2xl m-1 p-1 font-bold'>Et Trail Sessions</p></div>
                <div>
                    <img
                        src={addIcon}
                        alt=""
                        className="h-10 w-10 cursor-pointer"
                        // onClick={() => navigate("/sessions/create")}
                        onClick={onAdd} // 01 Mar 2025
                    />
                </div>
            </div>

            {Object.values(trails).map((trail, trailIndex) => (
                <div key={trailIndex} className='m-1 p-1 bg-white rounded'>

                    <div className='bg-gray-300 m-1 p-1 rounded'>
                        {/* Trail Header */}
                        <div className=''>
                            {/* Left Side */}
                            <div className='grid grid-cols-12 gap-1 justify-start mx-5 items-center'>
                                <div onClick={() => handleToggleTrails(trailIndex)} className="col-span-4 cursor-pointer m-1 p-1 flex justify-start items-center">
                                    <div className="mx-2 rounded-full h-7 w-7 hover:bg-white flex justify-center items-center ">{showTrails[trailIndex] ? <MinusIcon className="h-5 w-5 text-orange-600" /> : <PlusIcon className="h-5 w-5 text-orange-600" />}</div>
                                    <div className='mx-2 flex justify-center items-center rounded-full bg-gray-100 hover:bg-white h-8 w-8'>
                                        <img src={trail?.trailPic} alt="" className='h-6 w-6' />
                                    </div>
                                    <div className='w-48'>
                                        <p className='font-bold text-lg'>{trail?.trailName}</p>
                                        <p className='text-[9px]'dangerouslySetInnerHTML={createMarkup(trail?.trailDesc)}></p>
                                    </div>  
                                </div>

                                <div className='col-span-2 mx-2 flex justify-start items-center'>
                                        <div><p className='text-[10px] text-gray-400 mx-2 px-2'>Sessions</p></div>
                                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50'><p className='text-orange-600 text-[10px]'>{trail?.sessions.length}</p></div>
                                </div>

                                <div className='col-span-3 mx-2 flex justify-start items-center'>
                                    <div>
                                        <p className='text-[10px] text-gray-400 mx-2 px-2'>Audience:</p>
                                    </div>
                                    <div>
                                        <UserGroupIcon className='h-5 w-5 text-orange-600 m-1'/>
                                    </div>
                                    <div>
                                        {trail?.trailTargetAudience?.map((item, targetIndex) => (
                                            <div key={item+targetIndex} className='bg-gray-200 rounded inline-block m-1'>
                                                    <p className='text-[8px] mx-2 text-gray-600'>{item.audience}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className='col-span-3 mx-2 flex justify-start items-center'>
                                    <div>
                                        <p className='text-[10px] text-gray-400 mx-2 px-2'>Duration:</p>
                                    </div>
                                    <div>
                                        <ClockIcon className='h-5 w-5 text-orange-600 m-1'/>
                                    </div>
                                    <div>
                                            <div  className='bg-gray-200 rounded inline-block m-1'>
                                                    <p className='text-[8px] mx-2 text-gray-600'>{trail?.trailEstimatedDuration}</p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                        {showTrails[trailIndex] && (
                            <div className='bg-white m-1 p-1 rounded'>
                                {trail.sessions.map((session, index) => (
                                    <div key={session.id} className='bg-gray-100 rounded m-1 p-1'>
                                        <div className='flex justify-between items-center'>
                                            <div className='flex w-1/2 justify-start items-center mx-2'>
                                                <div onClick={() => handleToggleDetails(session.id)} className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white">
                                                    {showDetails[session.id] ? <MinusIcon className="h-4 w-4 text-orange-600" /> : <PlusIcon className="h-4 w-4 text-orange-600" />}
                                                </div>
                                                <div className='flex justify-center items-center rounded-full bg-gray-50 hover:bg-white h-7 w-7'>
                                                    <img src={session.pic} alt="" className='h-5 w-5' />
                                                </div>
                                                <div className='m-1 p-1'>
                                                    <p className='text-sm font-bold mx-1'>Session {session?.sessionSequence} - {session?.sessionName}</p>
                                                    <p className='text-[10px] m-1'>{session?.sessionDesc}</p>
                                                </div>
                                            </div>

                                            <div className='flex justify-center items-center mx-2'>
                                                            <CalendarDaysIcon className='m-2 h-5 w-5 text-orange-600' />
                                                            <p className='flex justify-center items-center text-xs font-bold'>{session.sessionPlannedDateSlot}</p>
                                            </div>



                                            <div className='flex justify-start items-center mx-2'>
                                                <div className='rounded-full flex justify-center items-center h-6 w-6 hover:bg-white'>
                                                    <PencilSquareIcon 
                                                    
                                                    // onClick={() => navigate(`/sessions/update/${session?.id}`)} 
                                                    onClick={() => onUpdate(session?.id)} // 01 Mar 2025 
                                                    
                                                    
                                                    className="h-4 w-4 text-orange-600 cursor-pointer" />
                                                </div>
                                                <div className='rounded-full flex justify-center items-center h-6 w-6 hover:bg-white'>
                                                    <TrashIcon 
                                                    
                                                    // onClick={() => navigate(`/sessions/delete/${session?.id}`)} 
                                                    onClick={() => onDelete(session?.id)} // 01 Mar 2025 
                                                    
                                                    className="h-4 w-4 text-orange-600 cursor-pointer" />
                                                </div>
                                            </div>
                                        </div>
                                        {showDetails[session.id] && (
                                            <div className='bg-white rounded mx-2  my-1'>
                                                 {session?.sessionIntro && (
                                                <div className='p-2 grid grid-cols-3 gap-2 justify-center'>
                                                   
                                                    <div className='mx-2 border-r  border-orange-200 col-span-1 h-full'>
                                                        
                                                        <div className='flex flex-col justify-start'>
                                                            
                                                            <div className='flex justify-start items-center  m-1 p-1'>

                                                                <div className=''><Square3Stack3DIcon className='h-5 w-5 text-orange-600' /></div>
                                                                <p className='m-1 p-1 text-xs text-gray-400 font-bold border-b-2 border-orange-600'>Session <span className='text-orange-600'>Detail</span></p>
                                                            </div>

                                                            <p dangerouslySetInnerHTML={createMarkup(session?.sessionIntro)} className='text-[10px] text-gray-600'></p>
                                                        </div>
                                                        
                                                    
                                                    </div>

                                                    <div className='mx-2 col-span-1 border-r border-orange-200 h-full'>
                                                        
                                                        <div className='flex flex-col justify-start'>
                                                            
                                                            <div className='flex justify-start items-center  m-1 p-1'>

                                                                <div className=''><ListBulletIcon className='h-5 w-5 text-orange-600' /></div>
                                                                <p className='m-1 p-1 text-xs text-gray-400 font-bold border-b-2 border-orange-600'>Session <span className='text-orange-600'>Prework</span></p>
                                                            </div>

                                                            {session?.sessionPreWork && session?.sessionPreWork?.map((item,preWorkIndex) => (
                                                            <div key={preWorkIndex} className='rounded m-1 p-1 bg-gray-100'>
                                                                <p className='text-[10px] mx-1'><span className='font-bold'></span>{item.prework}</p>
                                                            </div>
                                                            ))}

                                                        </div>


                                                    </div>

                                                    <div className='mx-2 col-span-1'>
                                                        <div className='flex flex-col justify-start'>

                                                            <div className='flex justify-start items-center m-1 p-1'>

                                                            <div className=''><SwatchIcon className='h-5 w-5 text-orange-600' /></div>
                                                                <p className='m-1 p-1 text-xs text-gray-400 font-bold border-b-2 border-orange-600'>Session <span className='text-orange-600'>Outcomes</span></p>

                                                            </div>
                                    
                                                            {session?.sessionOutcomes && session?.sessionOutcomes?.map((item,outIndex) => (
                                                            <div key={outIndex} className='rounded m-1 p-1 bg-gray-100'>
                                                                <p className='text-[10px] mx-1'>{item.outcome}</p>
                                                            </div>
                                                            ))}

                                                        </div>

                                                   
                                                   
                                                    </div>
                                                    
                                                </div>)}
                                                
                                            
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>


                </div>
            ))}
        </div>
    )
}


export default EtSessionList