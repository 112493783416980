
    import React from 'react';
    
    const EtEncourageIconSVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <g>
  <path d="m3093.8 768.77-225 299.98h112.5v1131c69.523 70.988 112.5 168.07 112.5 275.29 0 107.21-42.977 204.3-112.5 275.29v62.211h225v-1743.8h112.5z" fillRule="evenodd"/>
  <path d="m393.75 2868.8h225v-118.46c-69.523-70.988-112.5-168.08-112.5-275.29s42.977-204.3 112.5-275.29v-1074.7h112.5l-225-299.98-225 299.98h112.5z" fillRule="evenodd"/>
  <path d="m2081.2 450c0 155.33-125.92 281.25-281.25 281.25s-281.25-125.92-281.25-281.25 125.92-281.25 281.25-281.25 281.25 125.92 281.25 281.25" fillRule="evenodd"/>
  <path d="m1781.2 842.79-35.211 373.61 54 54 54-54-35.211-373.61c-6.2461 0.28125-12.434 0.95703-18.789 0.95703s-12.543-0.67578-18.789-0.95703z" fillRule="evenodd"/>
  <path d="m2081.2 2475c0 155.33-125.92 281.25-281.25 281.25s-281.25-125.92-281.25-281.25 125.92-281.25 281.25-281.25 281.25 125.92 281.25 281.25" fillRule="evenodd"/>
  <path d="m2981.2 2475c0 155.33-125.92 281.25-281.25 281.25s-281.25-125.92-281.25-281.25 125.92-281.25 281.25-281.25 281.25 125.92 281.25 281.25" fillRule="evenodd"/>
  <path d="m1176.5 903.82 286.03 63.562v551.36h675v-551.36l286.03-63.562c129.66-28.801 220.22-141.69 220.22-274.55v-404.27c0-30.992-25.258-56.25-56.25-56.25s-56.25 25.258-56.25 56.25v354.71c0 82.855-59.289 152.83-141.02 166.45l-460.35 76.727 41.062 435.71-170.94 170.95-171-171 41.062-435.71-460.35-76.727c-81.672-13.559-140.96-83.59-140.96-166.39v-354.71c0-30.992-25.258-56.25-56.25-56.25s-56.25 25.258-56.25 56.25v404.27c0 132.86 90.562 245.75 220.22 274.55z" fillRule="evenodd"/>
  <path d="m2700 2868.8c-147.21 0-283.11 63-379.41 173.14 61.82 96.301 98.156 210.43 98.156 333.11v56.25h506.25v-337.5h112.5v337.5h168.75v-56.25c0-279.17-227.14-506.25-506.25-506.25z" fillRule="evenodd"/>
  <path d="m1800 2868.8c-279.11 0-506.25 227.08-506.25 506.25v56.25h168.75v-337.5h112.5v337.5h450v-337.5h112.5v337.5h168.75v-56.25c0-279.17-227.14-506.25-506.25-506.25z" fillRule="evenodd"/>
  <path d="m956.25 1631.2h1687.5v450h-1687.5z" fillRule="evenodd"/>
  <path d="m900 2868.8c-279.11 0-506.25 227.08-506.25 506.25v56.25h168.75v-337.5h112.5v337.5h506.25v-56.25c0-122.68 36.336-236.81 98.156-333.11-96.301-110.14-232.2-173.14-379.41-173.14z" fillRule="evenodd"/>
  <path d="m1181.2 2475c0 155.33-125.92 281.25-281.25 281.25s-281.25-125.92-281.25-281.25 125.92-281.25 281.25-281.25 281.25 125.92 281.25 281.25" fillRule="evenodd"/>
 </g>

        </svg>
    );
    
    export default EtEncourageIconSVGComp;
    