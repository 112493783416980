import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userVisionSelfsAdapter = createEntityAdapter({})

const initialState = userVisionSelfsAdapter.getInitialState()

export const userVisionSelfsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserVisionSelfs: builder.query({
            query: () => ({
                url: '/userVisionSelfs/getUserVisionSelfs',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserVisionSelfs = responseData.map(userVisionSelf => {
                    userVisionSelf.id = userVisionSelf._id
                    return userVisionSelf
                })
                return userVisionSelfsAdapter.setAll(initialState, loadedUserVisionSelfs)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserVisionSelf', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserVisionSelf', id}))
                    ]
                } else {
                    return [ { type: 'UserVisionSelf', id: 'LIST'}]
                }
            },
        }),
        createNewUserVisionSelf: builder.mutation({
            query: initialUserVisionSelf => ({
                url: '/userVisionSelfs/createNewUserVisionSelf',
                method: 'POST',
                body: {...initialUserVisionSelf,}
            }),
            invalidatesTags: [
                { type: 'UserVisionSelf', id: "LIST" }
            ] 
        }),
        updateUserVisionSelf: builder.mutation({
            query: initialUserVisionSelf => ({
                url: '/userVisionSelfs/updateUserVisionSelf',
                method: 'PATCH',
                body: {...initialUserVisionSelf,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserVisionSelf', id: arg.id },
                { type: 'UserVisionSelf', id: 'LIST' }
            ],
        }),
        deleteUserVisionSelf: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userVisionSelfs/deleteUserVisionSelf',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userVisionSelfs/deleteUserVisionSelf',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserVisionSelf', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserVisionSelfsQuery,
    useCreateNewUserVisionSelfMutation,
    useUpdateUserVisionSelfMutation,
    useDeleteUserVisionSelfMutation,
} = userVisionSelfsApiSlice

// returns the query result object
export const selectUserVisionSelfResult = userVisionSelfsApiSlice.endpoints.getUserVisionSelfs.select()

// create memoized selector
const selectUserVisionSelfsData = createSelector(
    selectUserVisionSelfResult,
    userVisionSelfResult => userVisionSelfResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserVisionSelfs,
    selectById: selectUserVisionSelfById,
    selectIds: selectUserVisionSelfIds,
} = userVisionSelfsAdapter.getSelectors(state => selectUserVisionSelfsData(state) ?? initialState)