import React, {useState} from 'react'
import { useNavigate } from 'react-router'
import { addIcon } from '@/assets/img/imgAssets'
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import PulseLoader from "react-spinners/PulseLoader"
import { useGetCapabilitiesTechAreaTypesQuery } from './capabilitiesTechAreaTypesApiSlice'

const EtCapabilitiesTechAreaTypeList = ({ setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }) => {

  // First Created - 12 Nov 2024
  // Author - Atul Pandey
  // Updated - 14 Nov 2024 - refined to show domains
  // Updated - 28 Feb 2025 - updated the softSkillFunctionArea with props - { setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }
  // Updated - 28 Feb 2025 - switched from navigate to onAdd, onUpdate, onDelete, setSelectedEntityId
    
  // 1 - Set navigation
  const navigate = useNavigate()
  
  // 2a - Initialize showDetails state as an object where key is the capabilitiesTechAreaType index
  const [showBizDetails, setShowBizDetails] = useState({}); // For business details toggle
  const [showTechDetails, setShowTechDetails] = useState({}); // For technology details toggle
  const [showBizSection, setShowBizSection] = useState(false); // Toggle business section
  const [showTechSection, setShowTechSection] = useState(false); // Toggle technology section
  const [showDomainTypeNameSection, setShowDomainTypeNameSection] = useState({}) // Toggle specific domainType Name section detail
  const [showFunctionTypeNameSection, setShowFunctionTypeNameSection] = useState({}) // Toggle specific functionType Name section detail
  const {data: capabilitiesTechAreaTypesData, isError, error, isLoading} = useGetCapabilitiesTechAreaTypesQuery()
  // console.log('capabilitiesTechAreaTypesData', capabilitiesTechAreaTypesData)

//   // 2b - Business Capabilities
  const businessCapabilities = capabilitiesTechAreaTypesData?.ids
  ?.filter(id => capabilitiesTechAreaTypesData.entities[id]?.capabilitiesFunctionType?.capabilitiesDomainType?.capabilitiesDomainFocus === "Business")
  ?.map(id => capabilitiesTechAreaTypesData.entities[id]);
  // console.log('business capabilities', businessCapabilities)

// // 2c - Technology Capabilities
const technologyCapabilities = capabilitiesTechAreaTypesData?.ids
?.filter(id => capabilitiesTechAreaTypesData.entities[id]?.capabilitiesFunctionType?.capabilitiesDomainType.capabilitiesDomainFocus === "Technology")
?.map(id => capabilitiesTechAreaTypesData.entities[id]);
// console.log('technology capabilities', technologyCapabilities)

// 2d - Group business capabilities by capabilitiesDomainTypeName
const groupedBusinessCapabilities = businessCapabilities?.reduce((acc, capability) => {
  const { capabilitiesDomainType, capabilitiesFunctionTypeName } = capability.capabilitiesFunctionType;
  const domainTypeName = capabilitiesDomainType.capabilitiesDomainTypeName;
  const domainTypePic = capabilitiesDomainType.pic;

  if (!acc[domainTypeName]) {
    acc[domainTypeName] = { domainTypePic, functions: {} };
  }
  
  if (!acc[domainTypeName].functions[capabilitiesFunctionTypeName]) {
    acc[domainTypeName].functions[capabilitiesFunctionTypeName] = [];
  }
  
  acc[domainTypeName].functions[capabilitiesFunctionTypeName].push(capability);
  
  return acc;
}, {});
// console.log('groupedBusinessCapabilities', groupedBusinessCapabilities)

// // 2e - Group technology capabilities by capabilitiesDomainTypeName
const groupedTechnologyCapabilities = technologyCapabilities?.reduce((acc, capability) => {
  const { capabilitiesDomainType, capabilitiesFunctionTypeName } = capability.capabilitiesFunctionType;
  const domainTypeName = capabilitiesDomainType?.capabilitiesDomainTypeName;
  const domainTypePic = capabilitiesDomainType?.pic;
  if (!acc[domainTypeName]) {
    acc[domainTypeName] = { domainTypePic, functions: {} };
  }
  if (!acc[domainTypeName].functions[capabilitiesFunctionTypeName]) {
    acc[domainTypeName].functions[capabilitiesFunctionTypeName] = [];
  }
  
  acc[domainTypeName].functions[capabilitiesFunctionTypeName].push(capability);
  return acc;
}, {});
console.log('groupedTechnologyCapabilities', groupedTechnologyCapabilities)

  
 // 3a - Toggle Show DomainType Name Section -- 14 Nov 2024
  const handleToggleDomainTypeNameSection = (sectionId) => {
    setShowDomainTypeNameSection((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }))
}

 // 3b - Toggle Show FunctionType Name Section -- 14 Nov 2024
  const handleToggleFunctionTypeNameSection = (sectionId) => {
    setShowFunctionTypeNameSection((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }))
}

// 3c - Toggle handler for business details
const handleToggleBizDetails = (functionTypeName, techAreaIndex) => {
  setShowBizDetails(prev => ({
    ...prev,
    [functionTypeName]: prev[functionTypeName] === techAreaIndex ? null : techAreaIndex,
  }));
};

// 3d - Toggle handler for technology details
const handleToggleTechDetails = (functionTypeName, techAreaIndex) => {
  setShowTechDetails(prev => ({
    ...prev,
    [functionTypeName]: prev[functionTypeName] === techAreaIndex ? null : techAreaIndex,
  }));
};
    
  // 4 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
     }
  

  // 5 -  Optional: handle loading and error states
    if (isLoading) return <PulseLoader color={"#ea580c"} />
    if (isError) return <div>Error occurred: {error?.data?.message}</div>


  return (
    <div className='bg-gray-200 m-1 p-1 rounded font-poppins mx-auto'>
        {/* Header */}
        <div className='flex justify-start items-center m-1 p-1'>

            <div><p className='font-poppins text-2xl m-1 p-1 font-bold'>Et Capabilities TechArea Types</p></div>
            <div>
          <img
            src={addIcon}
            alt=""
            className="h-10 w-10 cursor-pointer"
            // onClick={() => navigate("/capabilitiesTechAreatypes/create")}
            onClick={onAdd} // 28 Feb 2025
          />
        </div>

        </div>

        {/* CapabilitiesTechAreaType Data */}
        <div id='capabilitiesTechAreaType-data' className='bg-white rounded m-1 p-1 font-poppins'>
            {capabilitiesTechAreaTypesData?.ids.length === 0 && (
                <div><p>No capabilities TechArea type data exists. Click above to create one</p></div>
            )}
            
              {/* Business Capabilities Grouped by Domain Type Name and then Function Type Name */}
                <div className='bg-gray-50 rounded m-1 p-1 border'>
                  <div className='m-1 p-1 flex justify-start items-center'>
                    <div onClick={() => setShowBizSection(prev => !prev)}
                      className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                      {showBizSection ? (
                        <MinusIcon className="h-3 w-3 text-orange-600 " />
                      ) : (
                        <PlusIcon className="h-3 w-3 text-orange-600 " />
                      )}
                    </div>
                    <p className='font-bold m-1 p-1'>Business Capabilities</p>
                  </div>

                  {showBizSection && (
                    <div>
                      {Object.entries(groupedBusinessCapabilities)?.map(([domainTypeName, { domainTypePic, functions }], index) => (
                        <div key={domainTypeName + index} className='bg-gray-100 rounded m-1 p-1'>
                          {/* Domain Type Header */}
                          <div className='flex justify-start items-center m-1 p-1'>
                              <div onClick={() => handleToggleDomainTypeNameSection(domainTypeName)}
                                  className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                                  {showDomainTypeNameSection[domainTypeName] ? (
                                    <MinusIcon className="h-3 w-3 text-orange-600 " />
                                  ) : (
                                    <PlusIcon className="h-3 w-3 text-orange-600 " />
                                  )}
                              </div>
                              <div>
                                <p className='text-sm font-bold mx-1 px-1'>{index + 1} - </p>
                              </div>
                            {domainTypePic && (
                              <div className='flex justify-center items-center h-7 w-7 bg-gray-100 hover:bg-white rounded-full cursor-pointer mr-2'>
                                <img src={domainTypePic} alt="" className='h-5 w-5' />
                              </div>
                            )}
                            <p className='text-sm font-bold mx-2'>{domainTypeName}</p>
                          </div>

                          {showDomainTypeNameSection[domainTypeName] && (
                            <div>
                              {Object.entries(functions)?.map(([functionTypeName, techAreas], funcIdx) => (
                                <div key={functionTypeName + funcIdx} className='bg-gray-50 rounded m-1 p-1'>
                                  {/* Function Type Header */}
                                  <div className='flex justify-start items-center m-1 p-1'>
                                    <div onClick={() => handleToggleFunctionTypeNameSection(functionTypeName)}
                                      className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                                      {showFunctionTypeNameSection[functionTypeName] ? (
                                        <MinusIcon className="h-3 w-3 text-orange-600 " />
                                      ) : (
                                        <PlusIcon className="h-3 w-3 text-orange-600 " />
                                      )}
                                    </div>
                                    <div>
                                      <p className='text-sm font-bold mx-1 px-1'>{funcIdx + 1} - </p>
                                    </div>
                                      <p className='text-xs font-bold mx-2'>{functionTypeName}</p>
                                  </div>
                                  
                                  {showFunctionTypeNameSection[functionTypeName] && (
                                    <div>
                                        {/* Tech Area Items */}
                                        {techAreas.map((capability, idx) => (
                                          <div key={capability._id} className='bg-gray-300 rounded m-1 p-1'>
                                            <div className='flex justify-between items-center m-1 p-1'>
                                              <div className='flex justify-start items-center mx-2'>
                                                <div onClick={() => handleToggleBizDetails(functionTypeName, idx)}
                                                  className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                                                  {showBizDetails[functionTypeName] === idx ? (
                                                    <MinusIcon className="h-3 w-3 text-orange-600 " />
                                                  ) : (
                                                    <PlusIcon className="h-3 w-3 text-orange-600 " />
                                                  )}
                                                </div>
                                                <p className='text-xs mx-1 px-1'>{idx + 1} - {capability.capabilitiesTechAreaTypeName}</p>
                                              </div>
                                              <div className='flex justify-start items-center'>

                                                    <div 
                                                    
                                                    // onClick={() => navigate(`/capabilitiesTechAreaTypes/update/${capability._id}`)} 
                                                    onClick={() => onUpdate(capability._id)} // 28 Feb 2025
                                                    
                                                    className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'>
                                                        <PencilSquareIcon className="h-3 w-3 text-orange-600 " />
                                                    </div>

                                                    <div 
                                                    
                                                    // onClick={() => navigate(`/capabilitiesTechAreatypes/delete/${capability._id}`)} 
                                                    onClick={() => onDelete(capability._id)} // 28 Feb 2025
                                                    
                                                    
                                                    className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'>
                                                        <TrashIcon className="h-3 w-3 text-orange-600 " />
                                                    </div> 
                                              </div>
                                            </div>

                                            {/* Show Details for Each TechArea */}
                                            {showBizDetails[functionTypeName] === idx && (
                                              <div className='bg-white rounded text-xs py-1'>
                                                <p className='font-bold m-1 p-1 border-b-2 border-orange-600'>Description:</p>
                                                <div dangerouslySetInnerHTML={createMarkup(capability.capabilitiesTechAreaTypeDesc)} className='m-1 p-1'></div>
                                              </div>
                                            )}
                                          </div>
                                        ))}
                                    </div>
                                  )}
                                  
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>

              {/* Technology Capabilities Grouped by Domain Type Name and then Function Type Name */}
                <div className='bg-gray-50 rounded m-1 p-1 border'>
                  <div className='m-1 p-1 flex justify-start items-center'>
                    <div onClick={() => setShowTechSection(prev => !prev)}
                      className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                      {showTechSection ? (
                        <MinusIcon className="h-3 w-3 text-orange-600 " />
                      ) : (
                        <PlusIcon className="h-3 w-3 text-orange-600 " />
                      )}
                    </div>
                    <p className='font-bold m-1 p-1'>Technology Capabilities</p>
                  </div>

                  {showTechSection && (
                    <div>
                      {Object.entries(groupedTechnologyCapabilities)?.map(([domainTypeName, { domainTypePic, functions }], techIndex) => (
                        <div key={domainTypeName + techIndex} className='bg-gray-100 rounded m-1 p-1 border'>
                          {/* Domain Type Header */}
                          <div className='flex justify-start items-center m-1 p-1'>
                            <div onClick={() => handleToggleDomainTypeNameSection(domainTypeName)}
                              className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                              {showDomainTypeNameSection[domainTypeName] ? (
                                <MinusIcon className="h-3 w-3 text-orange-600 " />
                              ) : (
                                <PlusIcon className="h-3 w-3 text-orange-600 " />
                              )}
                            </div>
                            <div>
                                <p className='text-sm font-bold mx-1 px-1'>{techIndex + 1} - </p>
                              </div>
                            {domainTypePic && (
                              <div className='flex justify-center items-center h-7 w-7 bg-gray-100 hover:bg-white rounded-full cursor-pointer mr-2'>
                                <img src={domainTypePic} alt="" className='h-5 w-5' />
                              </div>
                            )}
                            <p className='text-sm font-bold mx-2'>{domainTypeName}</p>
                          </div>

                          {showDomainTypeNameSection[domainTypeName] && (
                            <div>
                              {Object.entries(functions)?.map(([functionTypeName, techAreas], funcIdx) => (
                                <div key={functionTypeName + funcIdx} className='bg-gray-50 rounded m-1 p-1 border '>
                                  {/* Function Type Header */}
                                  <div className='flex justify-start items-center m-1 p-1'>
                                      <div onClick={() => handleToggleFunctionTypeNameSection(functionTypeName)}
                                        className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                                        {showFunctionTypeNameSection[functionTypeName] ? (
                                          <MinusIcon className="h-3 w-3 text-orange-600 " />
                                        ) : (
                                          <PlusIcon className="h-3 w-3 text-orange-600 " />
                                        )}
                                      </div>
                                      <div>
                                        <p className='text-sm font-bold mx-1 px-1'>{funcIdx + 1} - </p>
                                      </div>
                                        <p className='text-sm font-bold mx-2'>{functionTypeName}</p>
                                  </div>
                                  
                                  
                                  {showFunctionTypeNameSection[functionTypeName] && (
                                    <div>
                                        {/* Tech Area Items */}
                                        {techAreas.map((capability, idx) => (
                                          <div key={capability._id} className='bg-gray-300 rounded m-1 p-1'>
                                            <div className='flex justify-between items-center m-1 p-1'>
                                              <div className='flex justify-start items-center mx-2'>
                                                <div onClick={() => handleToggleTechDetails(functionTypeName, idx)}
                                                  className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                                                  {showTechDetails[functionTypeName] === idx ? (
                                                    <MinusIcon className="h-3 w-3 text-orange-600 " />
                                                  ) : (
                                                    <PlusIcon className="h-3 w-3 text-orange-600 " />
                                                  )}
                                                </div>
                                                <p className='text-xs mx-1 px-1'>{idx + 1} - {capability.capabilitiesTechAreaTypeName}</p>
                                              </div>
                                              <div className='flex justify-start items-center'>

                                                    <div 
                                                    
                                                    // onClick={() => navigate(`/capabilitiesTechAreaTypes/update/${capability._id}`)} 
                                                    onClick={() => onUpdate(capability._id)} // 28 Feb 2025
                                                    
                                                    
                                                    className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'>
                                                        <PencilSquareIcon className="h-3 w-3 text-orange-600 " />
                                                    </div>

                                                    <div 
                                                    
                                                    // onClick={() => navigate(`/capabilitiesTechAreatypes/delete/${capability._id}`)} 
                                                    onClick={() => onDelete(capability._id)} // 28 Feb 2025
                                                    
                                                    className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'>
                                                        <TrashIcon className="h-3 w-3 text-orange-600 " />
                                                    </div> 
                                              </div>
                                            </div>

                                            {/* Show Details for Each TechArea */}
                                            {showTechDetails[functionTypeName] === idx && (
                                              <div className='bg-white rounded text-xs py-1'>
                                                <p className='font-bold m-1 p-1 border-b-2 border-orange-600'>Description:</p>
                                                <div dangerouslySetInnerHTML={createMarkup(capability.capabilitiesTechAreaTypeDesc)} className='m-1 p-1'></div>
                                              </div>
                                            )}
                                          </div>
                                        ))}
                                    </div>
                                  )}
                                  
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>


        </div>

    </div>
  )
}

export default EtCapabilitiesTechAreaTypeList