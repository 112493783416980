import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const capabilitiesAdvisoryTypesAdapter = createEntityAdapter({})

const initialState = capabilitiesAdvisoryTypesAdapter.getInitialState()

export const capabilitiesAdvisoryTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCapabilitiesAdvisoryTypes: builder.query({
            query: () => ({
                url: '/capabilitiesAdvisoryTypes/getCapabilitiesAdvisoryTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedCapabilitiesAdvisoryTypes = responseData.map(capabilitiesAdvisoryType => {
                    capabilitiesAdvisoryType.id = capabilitiesAdvisoryType._id
                    return capabilitiesAdvisoryType
                })
                return capabilitiesAdvisoryTypesAdapter.setAll(initialState, loadedCapabilitiesAdvisoryTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'CapabilitiesAdvisoryType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'CapabilitiesAdvisoryType', id}))
                    ]
                } else {
                    return [ { type: 'CapabilitiesAdvisoryType', id: 'LIST'}]
                }
            },
        }),
        createNewCapabilitiesAdvisoryType: builder.mutation({
            query: initialCapabilitiesAdvisoryType => ({
                url: '/capabilitiesAdvisoryTypes/createNewCapabilitiesAdvisoryType',
                method: 'POST',
                body: {...initialCapabilitiesAdvisoryType,}
            }),
            invalidatesTags: [
                { type: 'CapabilitiesAdvisoryType', id: "LIST" }
            ] 
        }),
        updateCapabilitiesAdvisoryType: builder.mutation({
            query: initialCapabilitiesAdvisoryType => ({
                url: '/capabilitiesAdvisoryTypes/updateCapabilitiesAdvisoryType',
                method: 'PATCH',
                body: {...initialCapabilitiesAdvisoryType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'CapabilitiesAdvisoryType', id: arg.id },
                { type: 'CapabilitiesAdvisoryType', id: 'LIST' }
            ],
        }),
        deleteCapabilitiesAdvisoryType: builder.mutation({
            query: ({id}) => ({
                url: '/capabilitiesAdvisoryTypes/deleteCapabilitiesAdvisoryType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'CapabilitiesAdvisoryType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetCapabilitiesAdvisoryTypesQuery,
    useCreateNewCapabilitiesAdvisoryTypeMutation,
    useUpdateCapabilitiesAdvisoryTypeMutation,
    useDeleteCapabilitiesAdvisoryTypeMutation,
} = capabilitiesAdvisoryTypesApiSlice

// returns the query result object
export const selectCapabilitiesAdvisoryTypeResult = capabilitiesAdvisoryTypesApiSlice.endpoints.getCapabilitiesAdvisoryTypes.select()

// create memoized selector
const selectCapabilitiesAdvisoryTypesData = createSelector(
    selectCapabilitiesAdvisoryTypeResult,
    capabilitiesAdvisoryTypeResult => capabilitiesAdvisoryTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCapabilitiesAdvisoryTypes,
    selectById: selectCapabilitiesAdvisoryTypeById,
    selectIds: selectCapabilitiesAdvisoryTypeIds,
} = capabilitiesAdvisoryTypesAdapter.getSelectors(state => selectCapabilitiesAdvisoryTypesData(state) ?? initialState)