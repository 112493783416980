import React, { useState, useEffect } from 'react'
import useTitle from "../../../hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
    PencilSquareIcon,
  } from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "../../../utils/EtContentEditorLight2"
import EtBtnFormBasic from "../../../components/common/buttons/EtBtnFormBasic"
import { useNavigate } from "react-router"

import { useUpdateUserCreationMutation, useGetUserCreationsQuery } from '@/features/userCreations/userCreationsApiSlice'
import { useGetCreationTypesQuery } from '@/features/creationTypes/creationTypesApiSlice'
import { useGetCreationAreaTypesQuery } from '@/features/creationAreaTypes/creationAreaTypesApiSlice'

import { useGetCapabilitiesIndustryTypesQuery } from '@/features/capabilitiesIndustryTypes/capabilitiesIndustryTypesApiSlice'
import { useGetCapabilitiesDomainTypesQuery } from '@/features/capabilitiesDomainTypes/capabilitiesDomainTypesApiSlice'
import { useGetCapabilitiesFunctionTypesQuery } from '@/features/capabilitiesFunctionTypes/capabilitiesFunctionTypesApiSlice'
import { useGetCapabilitiesTechAreaTypesQuery } from '@/features/capabilitiesTechAreaTypes/capabilitiesTechAreaTypesApiSlice'
import { useGetCapabilitiesTechStackTypesQuery } from '@/features/capabilitiesTechStackTypes/capabilitiesTechStackTypesApiSlice'
import { useGetCapabilitiesSalesTypesQuery } from '@/features/capabilitiesSalesTypes/capabilitiesSalesTypesApiSlice'
import { useGetCapabilitiesServiceTypesQuery } from '@/features/capabilitiesServiceTypes/capabilitiesServiceTypesApiSlice'
import { useGetCapabilitiesAdvisoryTypesQuery } from '@/features/capabilitiesAdvisoryTypes/capabilitiesAdvisoryTypesApiSlice'

import { produce } from "immer";

const EtUpdateUserImprintCreationComp = ({
    id,
    userId, 
    userOrgId, 
    triggeringUrl, 
    onClose
  }) => {

  // First Created - 09 Dec 2024
  // Author - Atul Pandey
  // Inherited from the EtCreateUserImprintCapabilitiesIndustry component
  // Updated - 12 Dec 2024 - added id to the props list
  // Updated - 14 Dec 2024 - refinements for creationGeneralType in handleSubmit

  // 0 - Set Page title & variables
  useTitle('User Imprint Creations')

  // console.log('id is:', id)

  // 1a - set page navigations
  const navigate = useNavigate()

  // 1b - set form states
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState({}); // 05 Dec 2024
  // const [editorKey, setEditorKey] = useState('creationDesc-' + Date.now()); // 01 Jan 2025 - muted to address quill editor unique key issue
  const [editorValue, setEditorValue] = useState('');

  // 2a - set the mutation to create the new user imprint Role data
  const [updateUserCreation, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useUpdateUserCreationMutation()

  // 2b - Get existing user creations Data from the backend
  const { 
    data: userCreationData,
    isLoading: isFetching,
    isError: isFetchError,
    error: fetchError,
  } = useGetUserCreationsQuery()
  const userCreationRecords = userCreationData?.ids
  ?.map(id => userCreationData?.entities[id])
  ?.filter(item=> item._id === id && item.userId._id === userId && item.userOrgId._id === userOrgId) || []
  console.log('userCreations', userCreationRecords)

  // 2c - Get Data for building Creations
  const { data: creationTypesData} = useGetCreationTypesQuery()
  const { data: creationAreaTypesData} = useGetCreationAreaTypesQuery()
  const { data: industryTypesData} = useGetCapabilitiesIndustryTypesQuery()
  const { data: domainTypesData} = useGetCapabilitiesDomainTypesQuery()
  const { data: functionTypesData} = useGetCapabilitiesFunctionTypesQuery()
  const { data: techAreaTypesData} = useGetCapabilitiesTechAreaTypesQuery()
  const { data: techStackTypesData} = useGetCapabilitiesTechStackTypesQuery()
  const { data: salesTypesData} = useGetCapabilitiesSalesTypesQuery()
  const { data: serviceTypesData} = useGetCapabilitiesServiceTypesQuery()
  const { data: advisoryTypesData} = useGetCapabilitiesAdvisoryTypesQuery()

  const extractEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id]);
  };

  const creationTypes = extractEntitiesFromAdapter(creationTypesData);
  const creationAreaTypes = extractEntitiesFromAdapter(creationAreaTypesData);
  const industryTypes = extractEntitiesFromAdapter(industryTypesData);
  const domainTypes = extractEntitiesFromAdapter(domainTypesData);
  const functionTypes = extractEntitiesFromAdapter(functionTypesData);
  const techAreaTypes = extractEntitiesFromAdapter(techAreaTypesData);
  const techStackTypes = extractEntitiesFromAdapter(techStackTypesData);
  const salesTypes = extractEntitiesFromAdapter(salesTypesData);
  const serviceTypes = extractEntitiesFromAdapter(serviceTypesData);
  const advisoryTypes = extractEntitiesFromAdapter(advisoryTypesData);

   // 3 - Initialize Form Data State
   const [formData, setFormData] = useState({
    userCreationName: "",
    userCreationDesc: "",
    userCreationType: null,
    userCreationFocusAreas: [], // 10 Dec 2024
    userCreationNotes: [], // Array for creation notes
    aboutMe: "",
    pic: "",
    active: true, // Default active state
  });

  // 4 - Set the FormData based on the incoming data
  useEffect(() => {
    if (userCreationRecords.length > 0 && !isFormInitialized) {
      const selectedRecord = userCreationRecords[0];

      // Process incoming focus areas to handle both existing and new scenarios
      const processedFocusAreas = selectedRecord.userCreationFocusAreas?.map((focusArea) => ({
        _id: focusArea._id || undefined, // Use _id if it exists
        creationAreaType: focusArea.creationAreaType?._id || focusArea.creationAreaType || null, // Handle nested objects or direct IDs
        [`creation${focusArea.creationAreaType?.creationAreaTypeName || ''}Type`]:
          focusArea[`creation${focusArea.creationAreaType?.creationAreaTypeName || ''}Type`]?._id ||
          focusArea[`creation${focusArea.creationAreaType?.creationAreaTypeName || ''}Type`] || null, // Handle nested objects or direct IDs
      })) || [];

      setFormData({
        userCreationName: selectedRecord.userCreationName || '',
        userCreationDesc: selectedRecord.userCreationDesc || '',
        userCreationType: selectedRecord.userCreationType || null,
        userCreationFocusAreas: processedFocusAreas, // Initialize focus areas
        userCreationNotes: selectedRecord.userCreationNotes || [],
        aboutMe: selectedRecord.aboutMe || '',
        pic: selectedRecord.pic || '',
        active: selectedRecord.active ?? true,
      });

      setIsFormInitialized(true); // Prevent future overwrites
    }
  }, [userCreationRecords, isFormInitialized]);

  // console.log('Form.userCreationFocusAreas', formData.userCreationFocusAreas)

  useEffect(() => {
    if (formData.userCreationFocusAreas.length > 0) {
      setCollapsedSections((prev) => ({
        ...prev,
        focusAreasSection: false, // Expand the entire focus areas section
      }));
    }
  }, [formData.userCreationFocusAreas]);

   // 5a - Convert Image to Base64
   const convertPicToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  // 5b - Handle Form Changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
  
    if (name === "pic") {
      setFormData((prevState) =>
        produce(prevState, (draft) => {
          draft.pic = files[0];
        })
      );
    } else {
      setFormData((prevState) =>
        produce(prevState, (draft) => {
          draft[name] = value;
        })
      );
    }
  };

  // 5c - Toggle show / collapse each section 
  const toggleSection = (key) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };  

  // 6 - Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Step 1: Convert the pic field to Base64 if it exists
      const picBase64 = formData.pic ? await convertPicToBase64(formData.pic) : undefined;

      // Step 2: Filter out incomplete focus areas
      // const validFocusAreas = formData.userCreationFocusAreas.filter(
      //   (focusArea) =>
      //     focusArea.creationAreaType &&
      //     focusArea[`creation${creationAreaTypes.find((type) => type._id === focusArea.creationAreaType)?.creationAreaTypeName}Type`]
      // );
      // Step 2: Filter out incomplete focus areas and validate
      const validFocusAreas = formData.userCreationFocusAreas.filter((focusArea) => {
        const areaType = creationAreaTypes.find(
          (type) => type._id === focusArea.creationAreaType
        );
        
        // Basic validation for required fields
        const hasRequiredFields = focusArea.creationAreaType && 
          focusArea[`creation${areaType?.creationAreaTypeName}Type`];
          
        // Validate creationGeneralType is only present for General type
        const isGeneralTypeValid = areaType?.creationAreaTypeName === "General" ? 
          !!focusArea.creationGeneralType : true;
          
        return hasRequiredFields && isGeneralTypeValid;
      });

      // Step 3: Construct the payload
      const payload = {
        id, // Include the creation record's ID
        userId,
        userOrgId,
        userCreationName: formData.userCreationName.trim() || undefined,
        userCreationDesc: formData.userCreationDesc.trim() || undefined,
        userCreationType: formData.userCreationType || undefined,
        userCreationFocusAreas: [
          ...(validFocusAreas.map((focusArea) => {
            // 14 Dec 2024
            const areaType = creationAreaTypes.find(
              (type) => type._id === focusArea.creationAreaType
            );
            
            return {
            _id: focusArea._id || undefined,
            creationAreaType: focusArea.creationAreaType || undefined,
            ...(areaType?.creationAreaTypeName === "General" && {                     // 14 Dec 2024
              creationGeneralType: focusArea.creationGeneralType || "All Purpose"
            }),
            creationIndustryType: focusArea.creationIndustryType?._id || focusArea.creationIndustryType || undefined,
            creationDomainType: focusArea.creationDomainType?._id || focusArea.creationDomainType || undefined,
            creationFunctionType: focusArea.creationFunctionType?._id || focusArea.creationFunctionType || undefined,
            creationTechAreaType: focusArea.creationTechAreaType?._id || focusArea.creationTechAreaType || undefined,
            creationTechStackType: focusArea.creationTechStackType?._id || focusArea.creationTechStackType || undefined,
            creationSalesType: focusArea.creationSalesType?._id || focusArea.creationSalesType || undefined,
            creationServiceType: focusArea.creationServiceType?._id || focusArea.creationServiceType || undefined,
            creationAdvisoryType: focusArea.creationAdvisoryType?._id || focusArea.creationAdvisoryType || undefined,
            delete: focusArea.delete || false, // Include delete flag
          }
        }) || []),
          ...(formData.deletedFocusAreas || []), // Include deleted focus areas
        ],
        userCreationNotes: formData.userCreationNotes?.map((note) => ({
          _id: note._id || undefined,
          userCreationNoteText: note.userCreationNoteText || undefined,
          userCreationNoteDate: note.userCreationNoteDate || undefined,
          delete: note.delete || false, // Include delete flag if applicable
        })) || [],
        aboutMe: formData.aboutMe.trim() || undefined,
        pic: picBase64,
        active: formData.active,
      };

      // Step 4: Submit the payload
      await updateUserCreation(payload).unwrap();

      // Step 5: Reset the form upon success
      setFormData({
        userCreationName: "",
        userCreationDesc: "",
        userCreationType: null,
        userCreationFocusAreas: [],
        userCreationNotes: [],
        aboutMe: "",
        pic: "",
        active: true,
      });

      // Step 6: Redirect or close the form
      if (onClose) {
        onClose();
      } else {
        navigate(triggeringUrl || "/userMagicHome");
      }
    } catch (error) {
      // Step 7: Handle errors
      console.error("Error submitting creation form:", error);
    }
  };

  // 7 - Reset Form on Successful Submission
  useEffect(() => {
    if (isSuccess) {
      setFormData({
        userCreationName: "",
        userCreationDesc: "",
        userCreationType: null,
        userCreationFocusAreas: [], // 10 Dec 2024
        userCreationNotes: [], // Array for creation notes
        aboutMe: "",
        pic: "",
        active: true, // Default active state
      });

      if (onClose) {
        onClose();
      } else {
        navigate(triggeringUrl || "/userMagicHome");
      }
    }
  }, [isSuccess, navigate]);

  // 8 - Handle Clear
  const handleClear = () => {
    setFormData({
      userCreationName: "",
      userCreationDesc: "",
      userCreationType: null,
      userCreationFocusAreas: [], // 10 Dec 2024
      userCreationNotes: [], // Array for creation notes
      aboutMe: "",
      pic: "",
      active: true, // Default active state
    });
  };

  // 9 - Handle Cancel
  const handleCancel = () => {
    if (onClose) {
      onClose();
    } else {
      navigate(triggeringUrl || "/userMagicHome");
    }
  };

  // 10 - create Markup
  const createMarkup = (htmlContent) => {
  return { __html: htmlContent }
  }

  return (
    <div className="mx-auto w-full m-1 p-1 bg-white rounded shadow-md shadow-black">
      {isLoading && <PulseLoader color={'#ea580c'} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">{error?.data?.message}</p>
      )}

      <p className="p-1 m-1 font-bold font-poppins text-xl">
        Update User Imprint Creation
      </p>

      <form onSubmit={handleSubmit} className="m-1 p-1 text-xs font-poppins">

        {/* 1. Creation Name */}
        <div id="userCreationName" className="bg-gray-100 rounded p-1 m-1">
          <label
            htmlFor="userCreationName"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
            Creation Name
          </label>
          <input
            type="text"
            name="userCreationName"
            placeholder="Enter a creation name or title"
            value={formData.userCreationName}
            onChange={handleChange}
            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
          />
        </div>

        {/* 2. Creation Type */}
        <div id="creationType" className="bg-gray-100 rounded p-1 m-1">
          <label
            htmlFor="creationType"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
            Creation Type
          </label>
          <select
            name="userCreationType"
            value={formData.userCreationType || ""}
            onChange={(e) => {
              const value = e.target.value;
              setFormData((prevState) =>
                produce(prevState, (draft) => {
                  draft.userCreationType = value;
                })
              );
            }}
            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
          >
            <option value="" disabled>
              Select a Creation Type
            </option>
            {creationTypes.map((type) => (
              <option key={type._id} value={type._id}>
                {type.creationTypeName}
              </option>
            ))}
          </select>
        </div>

        {/* 3. Creation Focus Areas Section */}
        <div id="creationFocusAreas" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <div className="flex justify-start items-center">
              <button
                type="button"
                onClick={() => toggleSection("focusAreasSection")}
                className="text-orange-600 font-bold m-1 p-1 rounded"
              >
                {collapsedSections["focusAreasSection"] ? (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                )}
              </button>
              <p className="font-bold text-sm mx-1 px-1"> Creation Focus Areas</p>
            </div>
            <div className="flex justify-start items-center">
              <p className="font-bold text-xs mx-1 px-1">Add</p>
              <button
                type="button"
                className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                onClick={() => {
                  setFormData((prevState) =>
                    produce(prevState, (draft) => {
                      // Initialize the array if it doesn't exist
                      if (!draft.userCreationFocusAreas) {
                        draft.userCreationFocusAreas = [];
                      }
                      // Add a new focus area
                      draft.userCreationFocusAreas.unshift({
                        creationAreaType: null,
                        creationIndustryType: null,
                        creationDomainType: null,
                        creationFunctionType: null,
                        creationTechAreaType: null,
                        creationTechStackType: null,
                        creationSalesType: null,
                        creationServiceType: null,
                        creationAdvisoryType: null,
                      });
                    })
                  );
                  setCollapsedSections((prevState) =>
                    produce(prevState, (draft) => {
                      // Collapse all existing sections
                      Object.keys(draft).forEach((key) => (draft[key] = true));

                      // Expand the new focus area
                      draft.focusAreasSection = false;
                      draft[`focusArea-0`] = false;
                    })
                  );
                }}
              >
                <PlusIcon className="h-4 w-4 inline" />
              </button>
            </div>
          </div>

          {/* Focus Areas */}
          <div className="grid grid-cols-3 justify-between items-start gap-1">
            {!collapsedSections["focusAreasSection"] &&
              formData.userCreationFocusAreas?.map((focusArea, focusIndex) => (
                <div key={focusIndex} className="w-full p-2 border rounded bg-white">
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex justify-start items-center">
                      <button
                        type="button"
                        onClick={() =>
                          setCollapsedSections((prevState) =>
                            produce(prevState, (draft) => {
                              draft[`focusArea-${focusIndex}`] =
                                !draft[`focusArea-${focusIndex}`];
                            })
                          )
                        }
                        className="text-orange-600 font-bold m-1 p-1 rounded"
                      >
                        {collapsedSections[`focusArea-${focusIndex}`] ? (
                          <PlusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                          <MinusIcon className="h-4 w-4 text-orange-600" />
                        )}
                      </button>
                      <p className="font-bold text-xs mx-1 px-1">
                        Focus Area {focusIndex + 1}
                      </p>
                    </div>

                    <div className="flex justify-start items-center">
                      <p className="font-bold text-xs mx-1 px-1">Remove</p>
                      <button
                        type="button"
                        className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                        onClick={() =>
                          setFormData((prevState) =>
                            produce(prevState, (draft) => {
                              const removedFocusArea = draft.userCreationFocusAreas[focusIndex];
                              if (removedFocusArea._id) {
                                // Mark existing focus area as deleted
                                removedFocusArea.delete = true;
                                if (!draft.deletedFocusAreas) {
                                  draft.deletedFocusAreas = [];
                                }
                                draft.deletedFocusAreas.push(removedFocusArea);
                              }
                              // Remove it from the visible list
                              draft.userCreationFocusAreas.splice(focusIndex, 1);
                            })
                          )
                        }
                      >
                        <MinusIcon className="h-4 w-4 inline" />
                      </button>
                    </div>
                  </div>

                  {/* Dropdown for Creation Area Type */}
                  {!collapsedSections[`focusArea-${focusIndex}`] && (
                    <>
                      <div className="flex justify-between items-center bg-gray-100 rounded p-1 m-1">
                        <label
                          htmlFor={`creationAreaType-${focusIndex}`}
                          className="text-gray-600 text-[10px] font-bold w-1/3"
                        >
                          Area Type
                        </label>
                        <select
                          id={`creationAreaType-${focusIndex}`}
                          value={focusArea.creationAreaType || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) =>
                              produce(prevState, (draft) => {
                                const area = draft.userCreationFocusAreas[focusIndex];
                                area.creationAreaType = value;

                                // Reset other specific types when area type changes
                                area.creationIndustryType = null;
                                area.creationDomainType = null;
                                area.creationFunctionType = null;
                                area.creationTechAreaType = null;
                                area.creationTechStackType = null;
                                area.creationSalesType = null;
                                area.creationServiceType = null;
                                area.creationAdvisoryType = null;
                              })
                            );
                          }}
                          className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
                        >
                          <option value="" disabled>
                            Select Area Type
                          </option>
                          {creationAreaTypes.map((type) => (
                            <option key={type._id} value={type._id}>
                              {type.creationAreaTypeName}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* Conditional Dropdown for Specific Type */}
                      {focusArea.creationAreaType &&
                        (() => {
                          const selectedCreationAreaTypeName = creationAreaTypes.find(
                            (type) => type._id === focusArea.creationAreaType
                          )?.creationAreaTypeName;

                          // 13 Dec 2024 - added for General type
                          const isGeneral = selectedCreationAreaTypeName === "General";

                          return (
                            <div className="flex justify-between items-center bg-gray-100 rounded p-1 m-1">
                              <label
                                htmlFor={`specificType-${focusIndex}`}
                                className="text-gray-600 text-[10px] font-bold w-1/3"
                              >
                                Specific {selectedCreationAreaTypeName}
                              </label>
                              <select
                                id={`specificType-${focusIndex}`}
                                value={
                                  isGeneral ? focusArea.creationGeneralType || ""
                                  : focusArea[
                                    `creation${selectedCreationAreaTypeName}Type`
                                  ]?._id || focusArea[`creation${selectedCreationAreaTypeName}Type`] || ""
                                }
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormData((prevState) =>
                                    produce(prevState, (draft) => {
                                      draft.userCreationFocusAreas[focusIndex][
                                        `creation${selectedCreationAreaTypeName}Type`
                                      ] = value;
                                    })
                                  );
                                }}
                                className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
                              >
                                <option value="" disabled>
                                  Select {selectedCreationAreaTypeName || "Type"}
                                </option>
                                {isGeneral ? (
                                    <option value="All Purpose">All Purpose</option>
                                  ) : (                               
                                  (() => {
                                    const optionsMap = {
                                      Industry: industryTypes,
                                      Domain: domainTypes,
                                      Function: functionTypes,
                                      TechArea: techAreaTypes,
                                      TechStack: techStackTypes,
                                      Sales: salesTypes,
                                      Service: serviceTypes,
                                      Advisory: advisoryTypes,
                                    };

                                    return (
                                      optionsMap[selectedCreationAreaTypeName]?.map(
                                        (type) => (
                                          <option key={type._id} value={type._id}>
                                            {type[`capabilities${selectedCreationAreaTypeName}TypeName`]}
                                          </option>
                                        )
                                      ) || []
                                    );
                                  })()
                                  )}
                              </select>
                            </div>
                          );
                        })()}
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>  

        {/* 4. Creation Description */}
        <div id="creationDesc" className="bg-gray-100 rounded p-1 m-1">
          <label
            htmlFor="creationDesc"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
            Creation Description
          </label>
          <EtContentEditorLight2
            value={formData.userCreationDesc || ""}
            handleChange={(content) => {
              setFormData((prevState) =>
                produce(prevState, (draft) => {
                  draft.userCreationDesc = content;
                })
              );
            }}
            placeholderText="Write your creation description here..."
            // editorKey={`creationDesc-main-${id}-${Date.now()}`}
            editorKey={`creationDesc-main-${id}`} // 14 Feb 2025
          />
        </div>

        {/* 5. Creation Notes */}
        <div id="creationNotes" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <p className="font-bold text-sm">Creation Notes</p>
            <div className="flex justify-start items-center">
              <button
                type="button"
                className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                onClick={() => {
                  setFormData((prevState) =>
                    produce(prevState, (draft) => {
                      draft.userCreationNotes.push({
                        userCreationNoteText: "",
                        userCreationNoteDate: new Date().toISOString(),
                      });
                    })
                  );
                }}
              >
                <PlusIcon className="h-4 w-4 inline" />
              </button>
            </div>
          </div>

          {formData.userCreationNotes.map((note, noteIndex) => (
            <div key={noteIndex} className="p-2 border rounded mb-2 bg-white">
              <div className="flex justify-between items-center">
                <div className="flex justify-start items-center">
                  <button
                    type="button"
                    onClick={() => toggleSection(`creationNote-${noteIndex}`)}
                    className="text-orange-600 font-bold m-1 p-1 rounded"
                  >
                    {collapsedSections[`creationNote-${noteIndex}`] ? (
                      <PlusIcon className="h-4 w-4 text-orange-600" />
                    ) : (
                      <MinusIcon className="h-4 w-4 text-orange-600" />
                    )}
                  </button>
                  <p className="font-bold text-sm mx-1 px-1">Note {noteIndex + 1}</p>
                </div>
                <div className="flex justify-start items-center">
                  <button
                    type="button"
                    className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                    onClick={() => {
                      setFormData((prevState) =>
                        produce(prevState, (draft) => {
                          draft.userCreationNotes.splice(noteIndex, 1);
                        })
                      );
                    }}
                  >
                    <MinusIcon className="h-4 w-4 inline" />
                  </button>
                </div>
              </div>

              {!collapsedSections[`creationNote-${noteIndex}`] && (
                <div>
                  {/* Note Text */}
                  <div className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`noteText-${noteIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Note Text
                    </label>
                    <EtContentEditorLight2
                      value={note.userCreationNoteText || ""}
                      handleChange={(content) => {
                        setFormData((prevState) =>
                          produce(prevState, (draft) => {
                            draft.userCreationNotes[noteIndex].userCreationNoteText = content;
                          })
                        );
                      }}
                      placeholderText="Write your note here..."
                      // editorKey={`creationNote-${noteIndex}`}
                      editorKey={`creationNote-${noteIndex}-${note._id}`} // 02 Jan 2025
                      // editorKey={`creationNote-${noteIndex}-${note._id + Date.now()}`} // 01 Jan 2025
                    />
                  </div>

                  {/* Note Date (Read-only) */}
                  <p className="text-xs text-gray-500 mt-2">
                    Created on: {new Date(note.userCreationNoteDate).toLocaleDateString()}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
        
        {/* 6. Form Submissions Bar */}
        <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic 
              name={'Update'} 
              type={'create'} 
              handleClick={handleSubmit} 
              />
            <EtBtnFormBasic
              name={'Clear'}
              type={'clear'}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={'Cancel'}
              type={'cancel'}
              handleClick={handleCancel}
            />
        </div>
      </form>

    </div>
  )
}

export default EtUpdateUserImprintCreationComp