import { useState, useEffect } from "react";
import { useGetUsersQuery, useAddNewUserMutation } from "./usersApiSlice";
import { useGetOrganizationsQuery } from "../organizations/organizationsApiSlice";
import { useGetOrgRolesQuery } from "../orgRoles/orgRolesApiSlice";
import { useGetOrgTitlesQuery } from "../orgTitles/orgTitlesApiSlice";
import useTitle from "@/hooks/useTitle";
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic";
import PulseLoader from "react-spinners/PulseLoader";
import { PlusIcon, MinusIcon } from "@heroicons/react/24/outline";

const EtCreateUser = ({ setActiveAdminView }) => {

  // First Created - 02 Mar 2025
  // Author - Atul Pandey

  // 1️⃣ Set Page Title
  useTitle("Create New User");

  // 2️⃣ Initialize RTK Query Hooks
  const { data: organizationsData } = useGetOrganizationsQuery();
  const { data: orgRolesData } = useGetOrgRolesQuery();
  const { data: orgTitlesData } = useGetOrgTitlesQuery();
  const [addNewUser, { isLoading, isSuccess, isError, error }] = useAddNewUserMutation();

  const extractEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id]);
  };

  // 2a - Extract the roles enum list to be used for updating roles
  const { rolesEnum } = useGetUsersQuery("User", {
    selectFromResult: ({ data }) => ({
      rolesEnum: data?.rolesEnum,
    }),
  })
  
  // 2b - Extract data from Entities adapters
  const organizations = extractEntitiesFromAdapter(organizationsData)
  const orgRoles = extractEntitiesFromAdapter(orgRolesData)
  const orgTitles = extractEntitiesFromAdapter(orgTitlesData)

  // console.log('organizations:', organizations)
  // console.log('orgRoles:', orgRoles)
  // console.log('orgTitles:', orgTitles)
  // console.log('roles Enum:', rolesEnum)

  // 3️⃣ State Variables
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    username: "",
    password: "",
    userOrgId: "",
    userOrgRole: "",
    userOrgTitle: "",
    roles: [], // ✅ Stores assigned roles
    tempPwd: false, // ✅ Flag to enforce password reset on first login
  });

  const [availableRoles, setAvailableRoles] = useState(rolesEnum || []); // ✅ Available roles list

  // 4️⃣ Reset Form on Success
  useEffect(() => {
    if (isSuccess) {
      setFormData({
        name: "",
        email: "",
        username: "",
        password: "",
        userOrgId: "",
        userOrgRole: "",
        userOrgTitle: "",
        roles: [],
        tempPwd: false,
      });
      setActiveAdminView("users"); // ✅ Return to User List on success
    }
  }, [isSuccess, setActiveAdminView]);

  // 5️⃣ Handle Input Changes (Including Checkbox for tempPwd)
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // 6️⃣ Handle Role Add
  const handleAddRole = (role) => {
    setFormData((prevState) => ({
      ...prevState,
      roles: [...prevState.roles, role],
    }));
    setAvailableRoles((prevRoles) => prevRoles.filter((r) => r !== role));
  };

  // 7️⃣ Handle Role Remove
  const handleRemoveRole = (index) => {
    setFormData((prevState) => {
      const updatedRoles = prevState.roles.filter((_, i) => i !== index);
      return { ...prevState, roles: updatedRoles };
    });
    setAvailableRoles((prevRoles) => [...prevRoles, formData.roles[index]]);
  };

  // 8️⃣ Handle "Select All" Roles
  const handleSelectAllRoles = () => {
    setFormData((prevState) => ({
      ...prevState,
      roles: rolesEnum,
    }));
    setAvailableRoles([]);
  };

  // 9️⃣ Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Ensure tempPwd is always set to true for new users
    const userData = { ...formData, tempPwd: true };
    // await addNewUser(formData);
    await addNewUser(userData);
  };

  // 🔟 Handle Cancel
  const handleCancel = () => setActiveAdminView("users");

  // 1️⃣1️⃣ Handle Clear
  const handleClear = () => setFormData({
    name: "",
    email: "",
    username: "",
    password: "",
    userOrgId: "",
    userOrgRole: "",
    userOrgTitle: "",
    roles: [],
    tempPwd: false,
  });

  return (
    <div className="bg-gray-200 p-4 rounded-lg shadow-md text-sm">
      {isLoading && <PulseLoader color={"#ea580c"} />}
      {isError && <p className="text-red-600">{error?.data?.message}</p>}

      <div className="bg-white p-4 rounded-lg">
        <h2 className="text-xl font-bold mb-4">Create New User</h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* 1️⃣2️⃣ Name */}
          <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} className="w-full p-2 border rounded" required />

          {/* 1️⃣3️⃣ Email */}
          <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} className="w-full p-2 border rounded" required />

          {/* 1️⃣4️⃣ Username */}
          <input type="text" name="username" placeholder="Username" value={formData.username} onChange={handleChange} className="w-full p-2 border rounded" required />

          {/* 1️⃣5️⃣ Password */}
          <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} className="w-full p-2 border rounded" required />

          {/* 1️⃣6️⃣ Organization Selection */}
          <select name="userOrgId" value={formData.userOrgId} onChange={handleChange} className="w-full p-2 border rounded">
            <option value="">Select Organization</option>
            {
              // organizationsData?.ids?.map((id) => {
              organizations?.map((org) => {
              // const org = organizationsData.entities[id];
              return <option key={org._id} value={org._id}>{org.organizationName}</option>;
            })}
          </select>

          {/* 1️⃣7️⃣ Organization Role */}
          <select name="userOrgRole" value={formData.userOrgRole} onChange={handleChange} className="w-full p-2 border rounded">
            <option value="">Select Org Role</option>
            {
              // orgRolesData?.ids?.map((id) => {
              orgRoles?.map((role) => {
              // const role = orgRolesData.entities[id];
              return <option key={role._id} value={role._id}>{role.orgRoleName}</option>;
            })}
          </select>

          {/* 1️⃣8️⃣ Organization Title */}
          <select name="userOrgTitle" value={formData.userOrgTitle} onChange={handleChange} className="w-full p-2 border rounded">
            <option value="">Select Org Title</option>
            {
              // orgTitlesData?.ids?.map((id) => {
              orgTitles?.map((title) => {
              // const title = orgTitlesData.entities[id];
              return <option key={title?._id} value={title?._id}>{title?.orgTitleName}</option>;
            })}
          </select>

          {/* 1️⃣9️⃣ Role Selection */}
          <div className="bg-gray-100 p-4 rounded-lg">
            <p className="font-bold">Roles:</p>
            {formData.roles.map((role, index) => (
              <div key={index} className="flex items-center m-1 p-1">
                <button type="button" onClick={() => handleRemoveRole(index)} className="bg-red-500 p-1 rounded"><MinusIcon className="h-4 w-4 text-white" /></button>
                <span className="mx-2">{role}</span>
              </div>
            ))}

            <select onChange={(e) => handleAddRole(e.target.value)} className="mt-2 m-1 p-1 border rounded">
              <option value="">Select Role</option>
              {availableRoles.map((role) => <option key={role} value={role}>{role}</option>)}
            </select>
            <button type="button" onClick={handleSelectAllRoles} className="mt-2 m-1 bg-lime-600 text-white p-1 px-2 rounded">Select All</button>
          </div>

          {/* Buttons */}
          <div className="flex space-x-2">
            <EtBtnFormBasic name="Create" type="create" handleClick={handleSubmit} />
            <EtBtnFormBasic name="Clear" type="clear" handleClick={handleClear} />
            <EtBtnFormBasic name="Cancel" type="cancel" handleClick={handleCancel} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EtCreateUser;