import React from 'react'
import EtAddOrUpdateUserChallengeCoachEvalComp from '@/features/userChallengeCoachEvals/EtAddOrUpdateUserChallengeCoachEvalComp'
import {
    XCircleIcon
  } from "@heroicons/react/24/solid"

const EtModalAddOrUpdateUserChallengeCoachEval = ({ mode, existingEvalData,isOpen, onClose, challengeId, coachId, coachOrgId, userId, userOrgId, trailId, sessionId, triggeringUrl,}) => {
  // First Created - 26 Aug 2024
  // Author - Atul Pandey
  // Inherited from EtModalCreateUserChallengeCoachEval
  console.log('triggering url', triggeringUrl)

  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 z-70 flex justify-center items-center font-poppins">
      <div className="bg-black p-5 rounded-lg shadow-lg shadow-black relative">
        <div
          className="absolute top-0 right-0 m-2 font-bold rounded-full cursor-pointer h-7 w-7 flex justify-center items-center"
          onClick={onClose}
        >
          <XCircleIcon className='text-orange-600 rounded-full hover:text-red-600 h-7 w-7'/>
        </div>
        <EtAddOrUpdateUserChallengeCoachEvalComp mode={mode} existingEvalData={existingEvalData} challengeId={challengeId} coachId={coachId} coachOrgId={coachOrgId} userId={userId} userOrgId={userOrgId} trailId={trailId} sessionId={sessionId} triggeringUrl={triggeringUrl} onClose={onClose}/>
      </div>
    </div>
  )
}

export default EtModalAddOrUpdateUserChallengeCoachEval