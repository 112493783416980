
    import React from 'react';
    
    const EtMlNationalOrgIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" fill="currentColor">
          <g transform="translate(-769 -316)"><path d="M853.288 375.712 819.592 352.96C819.496 352.96 819.496 352.96 819.4 352.864L819.4 340.48C820.072 340.672 820.84 340.672 821.512 340.672 823.912 340.672 826.12 339.808 828.136 339.136 830.536 338.272 832.84 337.408 835.336 337.888 836.008 337.984 836.584 337.792 837.064 337.408 837.544 337.024 837.832 336.352 837.832 335.776L837.832 322.144C837.832 321.088 837.064 320.224 836.104 320.032 832.456 319.36 829.384 320.512 826.6 321.472 823.912 322.528 821.704 323.296 819.304 322.336L819.304 320.896C819.304 319.648 818.344 318.496 817.096 318.4 816.424 318.304 815.752 318.592 815.176 318.976 814.696 319.456 814.408 320.128 814.408 320.8L814.408 352.864C814.312 352.864 814.312 352.864 814.216 352.96L801.064 361.888 801.064 359.008C801.064 357.76 800.104 356.8 798.856 356.8L794.152 356.8C792.904 356.8 791.944 357.76 791.944 359.008L791.944 368.032 780.52 375.808C778.504 377.152 777.64 379.84 778.792 381.952 780.136 384.352 783.208 385.12 785.416 383.584L789.352 380.896 789.352 406.336C789.352 408.16 790.792 409.6 792.616 409.6L809.224 409.6 809.224 386.944C809.224 386.08 809.896 385.312 810.856 385.312L822.76 385.312C823.624 385.312 824.392 385.984 824.392 386.944L824.392 409.6 841 409.6C842.824 409.6 844.264 408.16 844.264 406.336L844.264 380.896 848.2 383.584C848.968 384.16 849.928 384.352 850.792 384.352 852.424 384.352 854.056 383.488 854.92 381.856 856.072 379.744 855.208 377.056 853.288 375.712Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtMlNationalOrgIcon1SVGComp;
    