import React from 'react'
import { XCircleIcon } from '@heroicons/react/24/solid'
import EtUpdateUserMeetingPrep from '@/features/userMeetingPreps/EtUpdateUserMeetingPrep'

const EtModalUpdateUserMeetingPrep = ({ isOpen, id, noteId = null, isDelete = false, userId, userOrgId, triggeringUrl, onClose }) => {
  // First Created - 21 Mar 2025
  // Author - Atul Pandey
  // Inherited from EtModalCreateUserImprintRole
  // Updated - 22 Mar 2025 - added noteId
  
  // console.log('triggering url', triggeringUrl)
  
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 z-70 flex justify-center items-start font-poppins overflow-y-auto p-4">
      <div className="bg-black p-5 rounded-lg shadow-lg shadow-black relative w-full max-w-4xl">
        <div
          className="absolute top-2 right-2 m-2 font-bold rounded-full cursor-pointer h-7 w-7 flex justify-center items-center"
          onClick={onClose}
        >
          <XCircleIcon className="text-orange-600 rounded-full hover:text-red-600 h-7 w-7" />
        </div>
        <EtUpdateUserMeetingPrep
          id={id}
          noteId={noteId} // 22 Mar 2025
          isDelete={isDelete} // 22 Mar 2025
          userId={userId}
          userOrgId={userOrgId}
          triggeringUrl={triggeringUrl}
          onClose={onClose}
        />
      </div>
    </div>
  )
}

export default EtModalUpdateUserMeetingPrep
