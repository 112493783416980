import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../../app/api/apiSlice'

const userImprintCapabilitiesServicesAdapter = createEntityAdapter({})

const initialState = userImprintCapabilitiesServicesAdapter.getInitialState()

export const userImprintCapabilitiesServicesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserImprintCapabilitiesServices: builder.query({
            query: () => ({
                url: '/userImprintCapabilitiesServices/getUserImprintCapabilitiesServices',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserImprintCapabilitiesServices = responseData.map(userImprintCapabilitiesService => {
                    userImprintCapabilitiesService.id = userImprintCapabilitiesService._id
                    return userImprintCapabilitiesService
                })
                return userImprintCapabilitiesServicesAdapter.setAll(initialState, loadedUserImprintCapabilitiesServices)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserImprintCapabilitiesService', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserImprintCapabilitiesService', id}))
                    ]
                } else {
                    return [ { type: 'UserImprintCapabilitiesService', id: 'LIST'}]
                }
            },
        }),
        createNewUserImprintCapabilitiesService: builder.mutation({
            query: initialUserImprintCapabilitiesService => ({
                url: '/userImprintCapabilitiesServices/createNewUserImprintCapabilitiesService',
                method: 'POST',
                body: {...initialUserImprintCapabilitiesService,}
            }),
            invalidatesTags: [
                { type: 'UserImprintCapabilitiesService', id: "LIST" }
            ] 
        }),
        updateUserImprintCapabilitiesService: builder.mutation({
            query: initialUserImprintCapabilitiesService => ({
                url: '/userImprintCapabilitiesServices/updateUserImprintCapabilitiesService',
                method: 'PATCH',
                body: {...initialUserImprintCapabilitiesService,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserImprintCapabilitiesService', id: arg.id },
                { type: 'UserImprintCapabilitiesService', id: 'LIST' }
            ],
        }),
        deleteUserImprintCapabilitiesService: builder.mutation({
            query: ({id}) => ({
                url: '/userImprintCapabilitiesServices/deleteUserImprintCapabilitiesService',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserImprintCapabilitiesService', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserImprintCapabilitiesServicesQuery,
    useCreateNewUserImprintCapabilitiesServiceMutation,
    useUpdateUserImprintCapabilitiesServiceMutation,
    useDeleteUserImprintCapabilitiesServiceMutation,
} = userImprintCapabilitiesServicesApiSlice

// returns the query result object
export const selectUserImprintCapabilitiesServiceResult = userImprintCapabilitiesServicesApiSlice.endpoints.getUserImprintCapabilitiesServices.select()

// create memoized selector
const selectUserImprintCapabilitiesServicesData = createSelector(
    selectUserImprintCapabilitiesServiceResult,
    userImprintCapabilitiesServiceResult => userImprintCapabilitiesServiceResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserImprintCapabilitiesServices,
    selectById: selectUserImprintCapabilitiesServiceById,
    selectIds: selectUserImprintCapabilitiesServiceIds,
} = userImprintCapabilitiesServicesAdapter.getSelectors(state => selectUserImprintCapabilitiesServicesData(state) ?? initialState)