
    import React from 'react';
    
    const EtIlluminateImpactsIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" fill="currentColor">
          <g transform="translate(-690 -299)"><path d="M752.352 391.592 762 408.306 771.648 391.583 781.296 374.869 770.752 374.869C770.365 374.87 769.99 374.728 769.7 374.471 769.41 374.214 769.224 373.86 769.177 373.475L761.817 313.503 754.463 373.427C754.427 373.822 754.244 374.189 753.951 374.455 753.658 374.722 753.275 374.869 752.879 374.867L742.704 374.867 752.352 391.58ZM750.624 394.928 754.166 401.064 754.166 401.064C749.081 401.425 744.069 402.48 739.27 404.198 734.604 406.024 731.803 408.546 731.803 411.472 731.803 414.772 735.428 417.582 741.287 419.46 748.017 421.401 754.997 422.332 762 422.222 769.003 422.331 775.984 421.399 782.713 419.457 788.572 417.585 792.197 414.769 792.197 411.47 792.197 408.542 789.394 406.019 784.725 404.192 779.928 402.475 774.919 401.422 769.836 401.061L773.376 394.925C784.288 395.87 793.781 398.136 800.533 401.237 806.904 404.163 810.717 407.731 810.717 411.47 810.717 415.874 805.484 420.013 797.024 423.144 788.086 426.456 775.703 428.496 761.996 428.496 748.289 428.496 735.906 426.451 726.968 423.144 718.508 420.013 713.275 415.874 713.275 411.47 713.275 407.726 717.087 404.164 723.454 401.246 730.208 398.144 739.703 395.876 750.62 394.931ZM760.582 412.177 760.582 412.177C760.752 412.52 761.042 412.789 761.396 412.936 761.75 413.081 762.145 413.094 762.508 412.971 762.871 412.848 763.177 412.598 763.369 412.266L768.084 404.102 768.084 404.102C773.366 404.365 778.583 405.39 783.572 407.144 786.978 408.478 789.023 409.995 789.023 411.464 789.023 413.226 786.24 414.999 781.746 416.439L781.746 416.439C775.329 418.28 768.674 419.159 761.999 419.048 755.324 419.161 748.67 418.283 742.252 416.445 737.758 415.005 734.976 413.232 734.976 411.47 734.976 409.995 737.018 408.477 740.422 407.15L740.422 407.15C745.414 405.393 750.634 404.366 755.919 404.102Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtIlluminateImpactsIcon1SVGComp;
    