import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userSession8848SSCsAdapter = createEntityAdapter({})

const initialState = userSession8848SSCsAdapter.getInitialState()

export const userSession8848SSCsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserSession8848SSCs: builder.query({
            query: () => ({
                url: '/userSession8848SSCs/getUserSession8848SSCs',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserSession8848SSCs = responseData.map(userSession8848SSC => {
                    userSession8848SSC.id = userSession8848SSC._id
                    return userSession8848SSC
                })
                return userSession8848SSCsAdapter.setAll(initialState, loadedUserSession8848SSCs)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserSession8848SSC', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserSession8848SSC', id}))
                    ]
                } else {
                    return [ { type: 'UserSession8848SSC', id: 'LIST'}]
                }
            },
        }),
        createNewUserSession8848SSC: builder.mutation({
            query: initialUserSession8848SSC => ({
                url: '/userSession8848SSCs/createNewUserSession8848SSC',
                method: 'POST',
                body: {...initialUserSession8848SSC,}
            }),
            invalidatesTags: [
                { type: 'UserSession8848SSC', id: "LIST" }
            ] 
        }),
        updateUserSession8848SSC: builder.mutation({
            query: initialUserSession8848SSC => ({
                url: '/userSession8848SSCs/updateUserSession8848SSC',
                method: 'PATCH',
                body: {...initialUserSession8848SSC,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserSession8848SSC', id: arg.id },
                { type: 'UserSession8848SSC', id: 'LIST' }
            ],
        }),
        deleteUserSession8848SSC: builder.mutation({
            query: ({id}) => ({
                url: '/userSession8848SSCs/deleteUserSession8848SSC',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserSession8848SSC', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserSession8848SSCsQuery,
    useCreateNewUserSession8848SSCMutation,
    useUpdateUserSession8848SSCMutation,
    useDeleteUserSession8848SSCMutation,
} = userSession8848SSCsApiSlice

// returns the query result object
export const selectUserSession8848SSCResult = userSession8848SSCsApiSlice.endpoints.getUserSession8848SSCs.select()

// create memoized selector
const selectUserSession8848SSCsData = createSelector(
    selectUserSession8848SSCResult,
    userSession8848SSCResult => userSession8848SSCResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserSession8848SSCs,
    selectById: selectUserSession8848SSCById,
    selectIds: selectUserSession8848SSCIds,
} = userSession8848SSCsAdapter.getSelectors(state => selectUserSession8848SSCsData(state) ?? initialState)