import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userIntentionsAdapter = createEntityAdapter({})

const initialState = userIntentionsAdapter.getInitialState()

export const userIntentionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserIntentions: builder.query({
            query: () => ({
                url: '/userIntentions/getUserIntentions',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserIntentions = responseData.map(userIntention => {
                    userIntention.id = userIntention._id
                    return userIntention
                })
                return userIntentionsAdapter.setAll(initialState, loadedUserIntentions)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserIntention', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserIntention', id}))
                    ]
                } else {
                    return [ { type: 'UserIntention', id: 'LIST'}]
                }
            },
        }),
        createNewUserIntention: builder.mutation({
            query: initialUserIntention => ({
                url: '/userIntentions/createNewUserIntention',
                method: 'POST',
                body: {...initialUserIntention,}
            }),
            invalidatesTags: [
                { type: 'UserIntention', id: "LIST" }
            ] 
        }),
        updateUserIntention: builder.mutation({
            query: initialUserIntention => ({
                url: '/userIntentions/updateUserIntention',
                method: 'PATCH',
                body: {...initialUserIntention,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserIntention', id: arg.id },
                { type: 'UserIntention', id: 'LIST' }
            ],
        }),
        deleteUserIntention: builder.mutation({
            query: ({id}) => ({
                url: '/userIntentions/deleteUserIntention',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserIntention', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserIntentionsQuery,
    useCreateNewUserIntentionMutation,
    useUpdateUserIntentionMutation,
    useDeleteUserIntentionMutation,
} = userIntentionsApiSlice

// returns the query result object
export const selectUserIntentionResult = userIntentionsApiSlice.endpoints.getUserIntentions.select()

// create memoized selector
const selectUserIntentionsData = createSelector(
    selectUserIntentionResult,
    userIntentionResult => userIntentionResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserIntentions,
    selectById: selectUserIntentionById,
    selectIds: selectUserIntentionIds,
} = userIntentionsAdapter.getSelectors(state => selectUserIntentionsData(state) ?? initialState)