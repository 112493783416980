import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import {
  PlusIcon,
  MinusIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline"
import { ClockIcon, BuildingOffice2Icon,ArrowPathIcon, CalendarDaysIcon, Squares2X2Icon, ArrowTrendingUpIcon, InboxArrowDownIcon, UserIcon, ArrowDownCircleIcon, ArrowUpCircleIcon  } from "@heroicons/react/24/solid"

import { useGetUserGratitudeImprintsQuery } from '@/features/userGratitudeImprints/userGratitudeImprintsApiSlice'
import { useGetContactTypesQuery } from '@/features/contactTypes/contactTypesApiSlice'
import { useGetGratitudeTypesQuery } from '@/features/gratitudeTypes/gratitudeTypesApiSlice'

import EtModalCreateUserImprintGratitude from "@/components/common/modal/EtModalCreateUserImprintGratitude"
import EtModalUpdateUserImprintGratitude from "@/components/common/modal/EtModalUpdateUserImprintGratitude"
import EtModalCreateContact from '@/components/common/modal/EtModalCreateContact' // 19 Dec 2024

import EtGratitudeTargetIcon1SVGComp from '@/assets/img/icons/svgComp/EtGratitudeTargetIcon1SVGComp'
import EtGratitudeTypeIcon2SVGComp from '@/assets/img/icons/svgComp/EtGratitudeTypeIcon2SVGComp'

// 19 Dec 2024
import EtGratitudeAllIcon1SVGComp from '@/assets/img/icons/svgComp/EtGratitudeAllIcon1SVGComp'
import EtGratitudeContactIcon1SVGComp from '@/assets/img/icons/svgComp/EtGratitudeContactIcon1SVGComp'


import { createSvgComponentFromBase64 } from '@/utils/createSvgComponentFromBase64'

import {
  formatDateToMonthYear,
  formatDateToYear,
  formatDateTime,
  formatShortDate,
  calculateYearsAndMonths,
} from "@/utils/formatDate"

import { getTimeSince } from "@/utils/EtGetTimeSince"

const EtImprintGratitude = ({ userId, userInfo }) => {
  // First Created - 18 Dec 2024
  // Author - Atul Pandey
  // Updated - 19 Dec 2024 - Refined the tabs and added contact create modal

  // 1a - State for Modal -- 18 Dec 2024
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [createTriggeringUrl, setCreateTriggeringUrl] = useState(null)
  const [updateTriggeringUrl, setUpdateTriggeringUrl] = useState(null)

  // 1b - Create Contact Modal - 19 Dec 2024
  const [isCreateContactModalOpen, setIsCreateContactModalOpen] = useState(false)
  const [createContactTriggeringUrl, setCreateContactTriggeringUrl] = useState(null)

  // 1c - State for JSX Display - 18 Dec 2024
  const [showGratitudeDetails, setShowGratitudeDetails] = useState({})
  const [activeTab, setActiveTab] = useState('Summary') 
  const [selectedGratitudeId, setSelectedGratitudeId] = useState(null);
  const [showGratitudeAllNotes, setShowGratitudeAllNotes] = useState(false) // 19 Dec 2024
  const [showGratitudeContactNotes, setShowGratitudeContactNotes] = useState(false) // 19 Dec 2024

  // 2a - Extract needed data from the incoming props
  const userOrgId = userInfo[0]?.userOrgId._id || null
  // console.log('userOrgId', userOrgId)

  // 2b - Get UserGratitudeImprint Data - 18 Dec 2024
  const { data: contactTypesData} = useGetContactTypesQuery()
  const { data: gratitudeTypesData} = useGetGratitudeTypesQuery()
  const { data: userGratitudeImprintData } = useGetUserGratitudeImprintsQuery()

  const userGratitudeImprints = userGratitudeImprintData?.ids
    ?.map((id) => userGratitudeImprintData?.entities[id])
    ?.filter(
      (item) => item.userId?._id === userId && item.userOrgId._id === userOrgId
    )
  // console.log("userImprintGratitudes", userGratitudeImprints)

  const gratitudeAllNotes = userGratitudeImprints?.filter(item => item.userGratitudeFor === null || !item.userGratitudeFor ) || []
  // console.log('gratitudeAllNotes', gratitudeAllNotes)
  
  const gratitudeContactNotes = userGratitudeImprints?.filter(item => item.userGratitudeFor !== null && item.userGratitudeFor ) || []

  // console.log('gratitudeContactNotes', gratitudeContactNotes)

  const extractEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id]);
  };

  const contactTypes = extractEntitiesFromAdapter(contactTypesData);
  const gratitudeTypes = extractEntitiesFromAdapter(gratitudeTypesData);

  // 3a - Handle Create User Imprint Gratitude
  const handleCreateUserImprintGratitude = () => {
    const currentUrl = window.location.pathname
    setIsCreateModalOpen(true)
    setCreateTriggeringUrl(currentUrl)
  }

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false)
    setCreateTriggeringUrl(null)
    // window.location.reload();
  }

  // 3b - Handle Update User Imprint Gratitude
  const handleUpdateUserImprintGratitude = (id) => {
    const currentUrl = window.location.pathname
    setIsUpdateModalOpen(true)
    setUpdateTriggeringUrl(currentUrl)
    setSelectedGratitudeId(id)
    // console.log('clicked gratitude id', id)
    // console.log('selectedGratitudeId', selectedGratitudeId)
  }

  const handleCloseUpdateModal = () => {
    setIsUpdateModalOpen(false)
    setUpdateTriggeringUrl(null)
    // window.location.reload();
  }

  // 3c - Handle Create Contact
  const handleCreateContact = () => {
    const currentUrl = window.location.pathname
    setIsCreateContactModalOpen(true)
    setCreateContactTriggeringUrl(currentUrl)
    // setSelectedGratitudeId(id)
  }

  const handleCloseCreateContactModal = () => {
    setIsCreateContactModalOpen(false)
    setCreateContactTriggeringUrl(null)
    // window.location.reload();
  }

  // 3d - Toggle Show function for gratitude note details - 20 Dec 2024
  const handleToggleGratitudeDetails = (noteId) => {
    setShowGratitudeDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
    }))
    }

  // 4 - Toggle Tabs -- 10 Dec 2024
  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
  };

 // 5 - Function to get the appropriate color class based on section score
 const getColorForScore = (score) => {
  if (score >= 0 && score < 0.9) return "bg-etpx-0"
  if (score >= 0.9 && score < 1.9) return "bg-etpx-1"
  if (score >= 1.9 && score < 2.9) return "bg-etpx-2"
  if (score >= 2.9 && score < 3.9) return "bg-etpx-3"
  if (score >= 3.9 && score < 4.9) return "bg-etpx-4"
  return "bg-etpx-5" // For score >= 4.9
}

// 6 - create Markup
const createMarkup = (htmlContent) => {
  return { __html: htmlContent }
}

  return (
    <div className="m-1 p-1 border rounded">
        {userGratitudeImprints?.length === 0 && (
                <div className="flex justify-between items-center">
                  <div>
                    <p className="m-1 p-1 text-xs">
                      There is no gratitude record for you yet! Change that by
                      clicking the Add button.
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-xs font-bold mx-1 px-1">Add</p>
                    <button
                      type="button"
                      onClick={handleCreateUserImprintGratitude}
                      className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                    >
                      <PlusIcon className="h-3 w-3" />
                    </button>
                  </div>
                  <EtModalCreateUserImprintGratitude
                    isOpen={isCreateModalOpen}
                    userId={userId}
                    userOrgId={userOrgId}
                    triggeringUrl={createTriggeringUrl}
                    onClose={handleCloseCreateModal}
                  />
                </div>
        )}
        {userGratitudeImprints?.length >0 && (
          <div>
            {/* Tab Navigation */}
            <div className="grid grid-cols-10 justify-between items-center m-1 p-1">
                <div className="col-span-3 flex space-x-4 mb-4 m-1 p-1">
                    {/* Tab 1: Summary */}
                    <button
                        className={`m-1 p-1 text-sm font-bold ${activeTab === 'Summary' ? 'border-b-2 border-orange-600' : 'text-gray-400'}`}
                        onClick={() => handleTabSwitch('Summary')}
                    >
                        Summary
                    </button>

                    {/* Tab 2: Details */}
                    <button
                        className={`m-1 p-1 text-sm font-bold ${activeTab === 'Details' ? 'border-b-2 border-orange-600' : 'text-gray-400'}`}
                        onClick={() => handleTabSwitch('Details')}
                    >
                        Details
                    </button>
                </div>
                <div className="col-span-3 flex justify-center items-center m-1 p-1">
                    <p className="font-bold text-xs m-1 p-1">Gratitudes:</p>
                    <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{userGratitudeImprints?.length}</p></div>
                </div>
                <div className="col-span-4 flex justify-end items-center m-1 p-1">
                    <p className="font-bold text-xs m-1 p-1">Add a New Contact</p>
                    <button
                        type="button"
                        onClick={handleCreateContact}
                        className="flex justify-center items-center h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600"
                    >
                        <PlusIcon className="h-5 w-5" />
                    </button>
                    <p className="font-bold text-xs m-1 p-1">Add a New Gratitude</p>
                    <button
                        type="button"
                        onClick={handleCreateUserImprintGratitude}
                        className="flex justify-center items-center h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600"
                    >
                        <PlusIcon className="h-5 w-5" />
                    </button>
                </div>

                <EtModalCreateUserImprintGratitude
                  isOpen={isCreateModalOpen}
                  userId={userId}
                  userOrgId={userOrgId}
                  triggeringUrl={createTriggeringUrl}
                  onClose={handleCloseCreateModal}
                />  
            </div>

            {/* TAB1 - Summary */}
            {activeTab === 'Summary' && (
              <div className="bg-gray-100 rounded m-1 p-1">
                {userGratitudeImprints?.length > 0 && (
                <p className="text-xs m-1 p-1 leading-4">My journey of accomplishments is not one I traveled alone. It is deeply intertwined with the generosity, support, and wisdom of countless individuals who have left an enduring imprint on my life. Their <strong>guidance, encouragement, critique, and inspiration </strong> have been instrumental in shaping who I am and where I stand today. My gratitude imprint is a humble attempt to honor and acknowledge their <strong>profound contributions</strong> — an integral part of my own story. I carry their imprints forward with immense appreciation and respect.</p>      
                )}

                <div className='grid grid-cols-6 justify-between items-center w-5/6 mx-auto'>
                    <div className='col-span-3 m-1 rounded'>
                        <div className='flex justify-start items-center'>
                        <div><EtGratitudeTargetIcon1SVGComp className='h-7 w-7 text-etpx-5'/></div>
                        <div><p className='p-1 m-1 text-gray-600 font-bold'>Gratitude To</p></div>
                        </div>
                        <div><p className='m-1 p-1 text-[10px] lg:text-xs italic'>who I am grateful to..</p></div>
                        {/* Left side - Put the CreationTypes grid based on all creation types with zero here */}
                        <EtGratitudeTargetsScorecard contactTypes={contactTypes} gratitudes={userGratitudeImprints} />
                    </div>
                    <div className='col-span-3 m-1 rounded '>                       
                        <div className="flex justify-between items-center">
                            <div className='flex justify-start items-center'>
                            <div><EtGratitudeTypeIcon2SVGComp className='h-7 w-7 text-etpx-5'/></div>
                                <div><p className='p-1 m-1 text-gray-400 font-bold'>Gratitude For</p></div>
                            </div>
                        </div>
                        <div><p className='m-1 p-1 text-[10px] lg:text-xs italic'>what I am grateful for...</p></div>
                        {/* Right Side grid with actual weekly creations data*/}
                        <EtGratitudeTypesScorecard gratitudeTypes={gratitudeTypes} gratitudes={userGratitudeImprints} />
                    </div>
                </div>    


              </div>
            )}

            {/* TAB2 - Details */}
            {activeTab === 'Details' && (
              <div>
                {/* All Gratitude */}
                <div className="m-1 p-1 bg-gray-100 rounded">        
                    <div className="grid grid-cols-10 justify-between items-center">
                        <div className="col-span-3 flex justify-start items-center m-1 p-1">
                            <div
                                onClick={() => setShowGratitudeAllNotes(prev => !prev)}
                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                >
                                {showGratitudeAllNotes ? (
                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                )}
                            </div>
                            <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtGratitudeAllIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                            <div><p className="font-bold mx-1 px-1">All</p></div>  
                        </div>
                        <div className="col-span-3 flex justify-center items-center m-1 p-1">
                            <p className="font-bold text-xs m-1 p-1">Gratitudes:</p>
                            <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{gratitudeAllNotes?.length}</p></div>
                        </div>
                    </div>
                    {showGratitudeAllNotes && (
                      <div className="bg-gray-50 m-1 p-1 rounded">
                          {gratitudeAllNotes?.length === 0 && (
                              <p className="text-[11px] text-gray-500 italic mx-2 px21">
                                  No gratitudes have been added yet. Add your first one now!
                              </p>
                          )}

                          <div className="m-1 p-1">
                            <div>
                              <p className="m-1 p-1 text-xs font-bold">Gratitude Trail:</p>
                            </div>
                            {gratitudeAllNotes?.length > 0 && gratitudeAllNotes
                              ?.slice()
                              .sort(
                                (a, b) =>
                                    new Date(b.createdAt) - new Date(a.createdAt)
                            ) // Sort in descending order
                            ?.map((gratitude, gratitudeIndex) => {

                              const gratitudeTypeName = gratitude?.userGratitudeTypeId?.gratitudeTypeName || ""
                              const gratitudeTypePic = gratitude?.userGratitudeTypeId?.pic || ""
                              const gratitudeTargetName = gratitude?.userGratitudeTargetId?.contactTypeName || ""
                              const gratitudeTargetPic = gratitude?.userGratitudeTargetId?.pic || ""
                              const gratitudeNote = gratitude?.userGratitudeNoteText || ""
                              const gratitudeNoteDate = gratitude?.userGratitudeNoteDate || ""
                              const gratitudeNoteUpdateDate = gratitude?.userGratitudeNoteUpdateDate || ""
                              
                              
                              return(
                                <div key={gratitude._id+gratitudeIndex} className="">
                                  
                                  <div className="bg-gray-200 m-1 p-1 rounded">
                                    <div className="grid grid-cols-12 justify-start items-center m-1 p-1 gap-2">
                                      <div className="col-span-2 flex justify-start items-center">
                                        <div
                                          onClick={() => handleToggleGratitudeDetails(gratitude._id)}
                                          className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
                                        >
                                          {showGratitudeDetails[gratitude._id] ? (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                          ) : (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                          )}
                                        </div>
                                        <div>
                                          <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ gratitudeIndex +1}.</p>
                                        </div>
                                        <div className="">
                                          <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                            {getTimeSince(gratitudeNoteUpdateDate)}
                                          </p>
                                          <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                        </div>
                                      </div>
                                      <div className="col-span-2 flex justify-start items-center">
                                          
                                          <div className="rounded-full flex justify-center items-center h-7 w-7 bg-white hover:bg-gray-200">
                                              <img src={gratitudeTargetPic} alt="" className="h-6 w-6" />
                                          </div>
                                          <div>
                                              <p className="text-[10px] mx-1 px-1 text-gray-400 font-bold">Gratitude To:</p>
                                              <p className="text-[10px] mx-1 px-1 text-gray-600">{gratitudeTargetName}</p>
                                          </div>

                                      </div>
                                      <div className="col-span-2 flex justify-start items-center">
                                          
                                          <div className="rounded-full flex justify-center items-center h-7 w-7 bg-white hover:bg-gray-200">
                                              <img src={gratitudeTypePic} alt="" className="h-6 w-6" />
                                          </div>
                                          <div>
                                              <p className="text-[10px] mx-1 px-1 text-gray-400 font-bold">Gratitude For:</p>
                                              <p className="text-[10px] mx-1 px-1 text-gray-600">{gratitudeTypeName}</p>
                                          </div>

                                      </div>
                                      <div className="col-span-3 flex justify-start items-center">
                                        <div
                                          dangerouslySetInnerHTML={createMarkup(gratitudeNote)}
                                          className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                        ></div>
                                      </div>
                                      <div className="col-span-3 flex justify-start items-center mx-2">
                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                        <p className="text-[9px] mx-1">First Created:</p>
                                        <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(gratitudeNoteDate)}</p>
                                        <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                          <PencilSquareIcon
                                            className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                            onClick={() => handleUpdateUserImprintGratitude(gratitude._id)}
                                          />
                                        </div>
                                      </div>
                                    </div>
                            
                                    {showGratitudeDetails[gratitude._id] && (
                                      <div className="m-1 p-1 rounded bg-gray-50">
                                        <div
                                          dangerouslySetInnerHTML={createMarkup(gratitudeNote)}
                                          className="text-[11px] text-gray-700 m-1 p-1"
                                        ></div>
                                      </div>
                                    )}
                                  </div>                             
                                </div>
                              )
                            })}
                          </div>
                      </div>
                    )}
                </div>

                {/* By Contact Gratitude */}
                <div className="m-1 p-1 bg-gray-100 rounded">
                  <div className="grid grid-cols-10 justify-between items-center">
                      <div className="col-span-3 flex justify-start items-center m-1 p-1">
                          <div
                              onClick={() => setShowGratitudeContactNotes(prev => !prev)}
                              className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                              >
                              {showGratitudeContactNotes ? (
                                  <MinusIcon className="h-4 w-4 text-orange-600" />
                              ) : (
                                  <PlusIcon className="h-4 w-4 text-orange-600" />
                              )}
                          </div>
                          <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtGratitudeContactIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                          <div><p className="font-bold mx-1 px-1">By Contact</p></div>
                      </div>
                      <div className="col-span-3 flex justify-center items-center m-1 p-1">
                          <p className="font-bold text-xs m-1 p-1">Gratitudes:</p>
                          <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{gratitudeContactNotes?.length}</p></div>
                      </div>
                      
                  </div>
                  {showGratitudeContactNotes && (
                      <div className="bg-gray-50 m-1 p-1 rounded">
                          {gratitudeContactNotes?.length === 0 && (
                              <p className="text-[11px] text-gray-500 italic mx-2 px21">
                                  No gratitudes have been added yet. Add your first one now!
                              </p>
                          )}

                          <div className="m-1 p-1">
                            <div>
                              <p className="m-1 p-1 text-xs font-bold">Gratitude Trail:</p>
                            </div>
                            {gratitudeContactNotes?.length > 0 && gratitudeContactNotes
                              ?.slice()
                              .sort(
                                (a, b) =>
                                    new Date(b.createdAt) - new Date(a.createdAt)
                            ) // Sort in descending order
                            ?.map((gratitude, gratitudeIndex) => {

                              const gratitudeTypeName = gratitude?.userGratitudeTypeId?.gratitudeTypeName || ""
                              const gratitudeTypePic = gratitude?.userGratitudeTypeId?.pic || ""
                              const gratitudeTargetName = gratitude?.userGratitudeTargetId?.contactTypeName || ""
                              const gratitudeTargetPic = gratitude?.userGratitudeTargetId?.pic || ""
                              const gratitudeForName = gratitude?.userGratitudeFor?.contactName || ""
                              const gratitudeNote = gratitude?.userGratitudeNoteText || ""
                              const gratitudeNoteDate = gratitude?.userGratitudeNoteDate || ""
                              const gratitudeNoteUpdateDate = gratitude?.userGratitudeNoteUpdateDate || ""
                              
                              
                              return(
                                <div key={gratitude._id+gratitudeIndex} className="">
                                  
                                  <div className="bg-gray-200 m-1 p-1 rounded">
                                  <div className="grid grid-cols-12 justify-start items-center m-1 p-1 gap-2">
                                      <div className="col-span-2 flex justify-start items-center">
                                        <div
                                          onClick={() => handleToggleGratitudeDetails(gratitude._id)}
                                          className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
                                        >
                                          {showGratitudeDetails[gratitude._id] ? (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                          ) : (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                          )}
                                        </div>
                                        <div>
                                          <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ gratitudeIndex +1}.</p>
                                        </div>
                                        <div className="">
                                          <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                            {getTimeSince(gratitudeNoteUpdateDate)}
                                          </p>
                                          <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                        </div>
                                      </div>
                                      <div className="col-span-2 flex justify-start items-center">
                                          
                                          <div className="rounded-full flex justify-center items-center h-7 w-7 bg-white hover:bg-gray-200">
                                              <img src={gratitudeTargetPic} alt="" className="h-6 w-6" />
                                          </div>
                                          <div>
                                              <p className="text-[10px] mx-1 px-1 text-gray-400 font-bold">Gratitude To:</p>
                                              <p className="text-[10px] mx-1 px-1 text-orange-600 font-bold">{gratitudeForName}</p>
                                              <p className="text-[10px] mx-1 px-1 text-gray-600">{gratitudeTargetName ? gratitudeTargetName.slice(0, -1) : ""}</p>
                                          </div>

                                      </div>
                                      <div className="col-span-2 flex justify-start items-center">
                                          
                                          <div className="rounded-full flex justify-center items-center h-7 w-7 bg-white hover:bg-gray-200">
                                              <img src={gratitudeTypePic} alt="" className="h-6 w-6" />
                                          </div>
                                          <div>
                                              <p className="text-[10px] mx-1 px-1 text-gray-400 font-bold">Gratitude For:</p>
                                              <p className="text-[10px] mx-1 px-1 text-gray-600">{gratitudeTypeName}</p>
                                          </div>

                                      </div>
                                      <div className="col-span-3 flex justify-start items-center">
                                        <div
                                          dangerouslySetInnerHTML={createMarkup(gratitudeNote)}
                                          className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                        ></div>
                                      </div>
                            
                                      <div className="col-span-3 flex justify-start items-center mx-2">
                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                        <p className="text-[9px] mx-1">First Created:</p>
                                        <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(gratitudeNoteDate)}</p>
                                        <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                          <PencilSquareIcon
                                            className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                            onClick={() => handleUpdateUserImprintGratitude(gratitude._id)}
                                          />
                                        </div>
                                      </div>
                                  </div>
                            
                                    {showGratitudeDetails[gratitude._id] && (
                                      <div className="m-1 p-1 rounded bg-gray-50">
                                        <div
                                          dangerouslySetInnerHTML={createMarkup(gratitudeNote)}
                                          className="text-[11px] text-gray-700 m-1 p-1"
                                        ></div>
                                      </div>
                                    )}
                                  </div>                             
                                </div>
                              )
                            })}
                          </div>
                      </div>
                    )}
                </div>
              </div>
            )}
            <EtModalUpdateUserImprintGratitude 
              isOpen={isUpdateModalOpen}
              id={selectedGratitudeId}
              userId={userId}
              userOrgId={userOrgId}
              triggeringUrl={updateTriggeringUrl}
              onClose={handleCloseUpdateModal} 
            />
            <EtModalCreateContact 
            isOpen={isCreateContactModalOpen}
            userId={userId}
            userOrgId={userOrgId}
            triggeringUrl={createContactTriggeringUrl}
            onClose={handleCloseCreateContactModal}
        />   
          </div>
        )}

        
    </div>
  )
}

const EtGratitudeTargetsScorecard = ({ contactTypes=[], gratitudes = [] }) => {

  // 0. Get the weeklyCreationData
  const gratitudeTargetsData = extractGratitudeTargetsData(contactTypes, gratitudes)
  // console.log('incoming gratiudes', gratitudes)
  // console.log('gratiude target data', gratitudeTargetsData)
  
  // 1. Calculate the grid size based on the number of creation types
  const calculateGridSize = (numberOfCreationTypes) => {
      if (numberOfCreationTypes <= 1) return 1;
      if (numberOfCreationTypes <= 4) return 2;
      if (numberOfCreationTypes <= 9) return 3;
      if (numberOfCreationTypes <= 16) return 4;
      return 4; // Default to 4 if more than 16 creation types
  };
  
  const gridSize = calculateGridSize(contactTypes.length);
  
  // 2. Function to get the appropriate color class based on creation count
  const getColorForCreationCount = (count) => {
      return count > 0 ? "bg-etpx-5" : "bg-black"; // Light up the pixel if count > 0, else keep it black
  };
  
  const getTextColorForScore = (count) => {
      return count >0 ? 'text-white' : 'text-gray-900'; // Default color if none of the conditions match
      };
  
  const getTextColorForTitle = (count) => {
      return count >0 ? 'text-black' : 'text-gray-700'; // Default color if none of the conditions match
      };
  
  // 3. Generate square divs for each creation type
  const squares = contactTypes?.map((type, index) => {
      const gratitudeData = gratitudeTargetsData?.find(data => data.contactTypeName === type?.contactTypeName); // Find matching creation data
      const pic = type?.pic; // Icon for the creation type
      const MyIcon = createSvgComponentFromBase64(pic); // 07 Jul 2024
      const contactTypeName = type?.contactTypeName;
      const gratitudeCount = gratitudeData ? gratitudeData.targetCount : 0; // Get creation count or default to 0
      const colorClass = getColorForCreationCount(gratitudeCount);
      const textColorClass = getTextColorForScore(gratitudeCount)
      const textTitleColorClass = getTextColorForTitle(gratitudeCount)
  
      return (
      <div key={index} className={`aspect-square rounded border m-[1px] border-black ${colorClass}`}>
          <div className="flex flex-col justify-between items-center h-full p-2">
          
          <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 md:h-8 md:w-8 ${textColorClass}`}/>
          
          <div className={`rounded-full h-6 w-6 border flex justify-center items-center ${gratitudeCount >0 ? 'border-white' : 'border-none'}`}><p className={`${textColorClass} text-center font-bold`}>{gratitudeCount}</p></div>
          <p className={`text-[9px]  ${textTitleColorClass} text-center`}>{contactTypeName}</p>
          
          
          </div>
      </div>
      );
  });
  
  // 4. Return the grid of squares
  return (
      <div className={`grid grid-cols-${gridSize} aspect-square rounded bg-gradient-to-r from-black to-black via-etpx-bg mx-auto p-[2px]`}>
      {squares}
      </div>
  );
};

const extractGratitudeTargetsData = (contactTypes=[], gratitudes) => {
  // Create a map to store the count of creations per creation type
  const targetCountMap = {};
  // console.log('incoming gratiudes in extract data', gratitudes)
  // console.log('incoming contact types in extract data', contactTypes)

  // Populate the map with creation counts from the creations array
  gratitudes?.forEach(gratitude => {
    const gratitudeTargetId = gratitude.userGratitudeTargetId?._id;
    // console.log('gratitudeTargetId', gratitudeTargetId)
    if (gratitudeTargetId) {
      targetCountMap[gratitudeTargetId] = (targetCountMap[gratitudeTargetId] || 0) + 1;
    }
  });
  // console.log('targetCountMap', targetCountMap)


  // Build the gratitudeTargetData array
  const gratitudeTargetData = contactTypes?.map(type => ({
    contactTypeName: type.contactTypeName,
    pic: type.pic, // Assuming pic holds the icon data for each creation type
    targetCount: targetCountMap[type._id] || 0 // If no creations, set to 0
  }));
  // console.log('gratitudeTargetData', gratitudeTargetData)

  return gratitudeTargetData;
};

const EtGratitudeTypesScorecard = ({ gratitudeTypes=[], gratitudes = [] }) => {

  // 0. Get the weeklyCreationData
  const gratitudeTargetsData = extractGratitudeTypesData(gratitudeTypes, gratitudes)
  // console.log('gratiude target data', gratitudeTargetsData)
  
  // 1. Calculate the grid size based on the number of creation types
  const calculateGridSize = (numberOfCreationTypes) => {
      if (numberOfCreationTypes <= 1) return 1;
      if (numberOfCreationTypes <= 4) return 2;
      if (numberOfCreationTypes <= 9) return 3;
      if (numberOfCreationTypes <= 16) return 4;
      return 4; // Default to 4 if more than 16 creation types
  };
  
  const gridSize = calculateGridSize(gratitudeTypes.length);
  
  // 2. Function to get the appropriate color class based on creation count
  const getColorForCreationCount = (count) => {
      return count > 0 ? "bg-etpx-5" : "bg-black"; // Light up the pixel if count > 0, else keep it black
  };
  
  const getTextColorForScore = (count) => {
      return count >0 ? 'text-white' : 'text-gray-900'; // Default color if none of the conditions match
      };
  
  const getTextColorForTitle = (count) => {
      return count >0 ? 'text-black' : 'text-gray-700'; // Default color if none of the conditions match
      };
  
  // 3. Generate square divs for each creation type
  const squares = gratitudeTypes?.map((type, index) => {
      const gratitudeData = gratitudeTargetsData?.find(data => data.gratitudeTypeName === type?.gratitudeTypeName); // Find matching creation data
      const pic = type?.pic; // Icon for the creation type
      const MyIcon = createSvgComponentFromBase64(pic); // 07 Jul 2024
      const gratitudeTypeName = type?.gratitudeTypeName;
      const gratitudeCount = gratitudeData ? gratitudeData.targetCount : 0; // Get creation count or default to 0
      const colorClass = getColorForCreationCount(gratitudeCount);
      const textColorClass = getTextColorForScore(gratitudeCount)
      const textTitleColorClass = getTextColorForTitle(gratitudeCount)
  
      return (
      <div key={index} className={`aspect-square rounded border m-[1px] border-black ${colorClass}`}>
          <div className="flex flex-col justify-between items-center h-full p-2">
          
          <MyIcon className={`h-12 w-12 ${textColorClass}`}/>
          
          <div className={`rounded-full h-6 w-6 border flex justify-center items-center ${gratitudeCount >0 ? 'border-white' : 'border-none'}`}><p className={`${textColorClass} text-center font-bold`}>{gratitudeCount}</p></div>
          <p className={`text-[9px]  ${textTitleColorClass} text-center`}>{gratitudeTypeName}</p>
          
          
          </div>
      </div>
      );
  });
  
  // 4. Return the grid of squares
  return (
      <div className={`grid grid-cols-${gridSize} aspect-square rounded bg-gradient-to-r from-black to-black via-etpx-bg mx-auto p-[2px]`}>
      {squares}
      </div>
  );
};

const extractGratitudeTypesData = (gratitudeTypes=[], gratitudes) => {
  // Create a map to store the count of gratitudes per gratitude type
  const targetCountMap = {};
  // console.log('gratitudes', gratitudes)

  // Populate the map with gratitude counts from the gratitudes array
  gratitudes?.forEach(gratitude => {
    const gratitudeTypeId = gratitude.userGratitudeTypeId?._id;
    // console.log('gratitudeTypeId', gratitudeTypeId)
    if (gratitudeTypeId) {
      targetCountMap[gratitudeTypeId] = (targetCountMap[gratitudeTypeId] || 0) + 1;
    }
  });

  // Build the gratitudeTargetData array
  const gratitudeTargetData = gratitudeTypes?.map(type => ({
    gratitudeTypeName: type.gratitudeTypeName,
    pic: type.pic, // Assuming pic holds the icon data for each creation type
    targetCount: targetCountMap[type._id] || 0 // If no creations, set to 0
  }));

  return gratitudeTargetData;
};

export default EtImprintGratitude