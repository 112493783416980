
    import React from 'react';
    
    const EtMlAcademicExellenceIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" fill="currentColor">
          <g transform="translate(-473 -315)"><path d="M545 414.316C534.578 414.316 518.896 412.376 502.576 403.41L502.576 406.384C502.576 408.607 503.811 410.648 505.799 411.709 520.99 419.801 535.501 421.498 544.999 421.498 562.216 421.498 575.853 416.155 584.262 411.674 586.212 410.635 587.422 408.607 587.422 406.385L587.422 403.426C578.002 408.595 563.42 414.316 544.998 414.316Z" fillRule="evenodd"/><path d="M550.192 396.204C548.533 396.862 546.786 397.197 545.002 397.197 543.218 397.197 541.472 396.862 539.814 396.206L502.579 381.422 502.579 399.989C518.857 409.349 534.628 411.32 545.003 411.32 563.767 411.32 578.41 405.174 587.427 399.99L587.427 381.422 550.194 396.204Z" fillRule="evenodd"/><path d="M608.636 362.536 549.084 338.892C546.452 337.849 543.55 337.849 540.916 338.892L481.364 362.536C479.872 363.128 478.909 364.548 478.909 366.152 478.909 367.758 479.873 369.178 481.364 369.769L540.916 393.414C542.232 393.936 543.616 394.196 544.999 394.196 546.382 394.196 547.767 393.934 549.084 393.414L597.12 374.343 546.26 369.288C545.438 369.205 544.838 368.473 544.916 367.652L598.424 372.973 598.424 405.172C595.832 405.844 593.904 408.181 593.904 410.98 593.904 412.831 594.76 414.469 596.079 415.573 595.551 416.191 595.182 416.954 595.05 417.816L592.441 434.771C592.376 435.205 592.501 435.644 592.785 435.977 593.068 436.31 593.485 436.501 593.923 436.501L605.923 436.501C606.361 436.501 606.777 436.31 607.062 435.977 607.347 435.644 607.473 435.205 607.405 434.771L604.797 417.816C604.665 416.954 604.294 416.191 603.768 415.573 605.086 414.468 605.944 412.83 605.944 410.978 605.944 408.18 604.015 405.841 601.423 405.17L601.423 372.632 608.634 369.77C610.126 369.178 611.089 367.759 611.089 366.154 611.089 364.549 610.125 363.128 608.634 362.537Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtMlAcademicExellenceIcon1SVGComp;
    