
    import React from 'react';
    
    const EtGratitudeAllIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" fill="currentColor">
          <g transform="translate(-309 -495)"><path d="M380.136 582.552C384.168 574.632 392.232 569.736 400.872 569.736 404.472 569.736 408.072 570.6 411.24 572.184 413.688 553.896 417.576 522.792 417.576 522.792 418.008 519.048 415.416 515.736 411.672 515.304 407.928 514.872 404.616 517.464 404.184 521.208L400.584 551.448C400.44 552.6 399.576 553.32 398.424 553.32L398.28 553.32C397.128 553.32 396.264 552.312 396.264 551.16L397.992 511.848C398.28 508.104 395.4 504.936 391.656 504.792 387.912 504.504 384.744 507.528 384.6 511.272L382.872 550.584C382.872 551.736 381.864 552.6 380.712 552.6 379.56 552.6 378.84 551.736 378.697 550.584L375.816 504.936C375.529 501.192 372.361 498.456 368.616 498.6 364.872 498.888 361.992 502.056 362.28 505.8L365.16 549.432C365.304 550.728 364.296 551.736 363 551.592L362.28 551.592C361.272 551.592 360.552 550.872 360.264 549.864L354.072 514.008C353.496 510.264 349.896 507.816 346.296 508.536 342.552 509.256 340.248 512.712 340.824 516.312 340.824 516.312 346.152 545.4 347.016 551.736 347.88 557.928 348.168 561.528 348.456 567.144 348.6 570.312 346.008 572.328 342.84 569.16 342.84 569.16 337.08 562.104 333.768 558.792 330.456 555.336 326.856 554.184 323.4 556.344 319.656 558.648 318.792 563.544 321.24 567 321.24 567 335.784 590.904 344.856 600.84 347.736 604.008 351.192 607.176 355.08 608.76L356.808 630.36C357.096 633.24 359.4 635.4 362.28 635.4L391.944 635.4C394.824 635.4 397.272 633.24 397.416 630.36L397.992 622.728 384.888 609.624C377.688 602.28 375.672 591.192 380.136 582.552Z" fillRule="evenodd"/><path d="M434.136 579.816C428.52 576.936 421.752 578.664 417.288 582.984L414.408 585.864 411.096 582.552C404.328 575.784 392.664 577.08 387.912 586.44 385.032 592.056 386.76 598.824 391.08 603.288L412.536 624.744C413.544 625.752 415.272 625.752 416.28 624.744L438.024 603C444.648 596.376 443.352 584.568 434.136 579.816Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtGratitudeAllIcon1SVGComp;
    