import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import useTitle from "../../hooks/useTitle"
import EtBtnFormBasic from "../../components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLightWithPlace from "../../utils/EtContentEditorLightWithPlace"
import { useGetActionTypesQuery } from "../actionTypes/actionTypesApiSlice"
import { useCreateNewSponsorUserOrgTrailSessionActionMutation } from "./sponsorUserOrgTrailSessionActionsApiSlice"

const EtAddOrUpdateSponsorUserOrgTrailSessionActionComp = ({mode = 'create', existingActionData = null, sponsorId, sponsorOrgId, userOrgId, trailId, sessionId, triggeringUrl, onClose, sessionUsers}) => {
  // First Created - 11 Sep 2024
  // Author - Atul Pandey
  // Inherited from EtCreateCoachUserOrgTrailSessionAction component

  // 0 - Set Page Title
  useTitle('Create or Update New Sponsor Session Planned Action')
  
  // 1a - set page navigations
  const navigate = useNavigate()

  // 1b - Extract incoming data:
  console.log('Mode:', mode)
  console.log('ExistingActionData:', existingActionData)
  console.log('SponsorId:', sponsorId)
  console.log('SponsorOrgId:', sponsorOrgId)
  console.log('UserOrgId:', userOrgId)
  console.log('TrailId:', trailId)
  console.log('SessionId:', sessionId)
  console.log('TriggeringUrl:', triggeringUrl)
  console.log('Session Users:', sessionUsers)

  // 2 - set the mutation to create the new action data
  const[createNewSponsorUserOrgTrailSessionAction, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewSponsorUserOrgTrailSessionActionMutation()

  // 3 - Get action types data for options
  const { actionTypes } = useGetActionTypesQuery('actionTypesList', {
    selectFromResult: ( { data }) => ({
      actionTypes: data?.ids.map(id => data?.entities[id])
    })
  })
//   console.log('action types', actionTypes)

  // 4 - Create options to select a session type from list of session types
  const actionTypeOptions = actionTypes ? [
  <option key="placeholder" value="" disabled>
        Select a Action Type
    </option>,
    ...actionTypes?.map(actionType => (
        <option key={actionType._id} value={actionType._id}>
        {actionType.actionTypeName}
        </option>
    ))
   ] : []

  // 5a - create the form data object - if no existingActionData is there
  const [formData, setFormData] = useState({
    sponsorId: sponsorId,
    sponsorOrgId: sponsorOrgId,
    userOrgId: userOrgId,
    trailId: trailId, 
    sessionId: sessionId,
    sponsorActionTypeId:"",
    sponsorSessionActionName:"",
    sponsorSessionActionDesc:"",
    sponsorSessionActionCompleteBy:"",
    sessionUsers:sessionUsers,  // 02 Sep 2024 - added from the list of incoming props 
    aboutMe:"",
    pic: "", 
  })
  
  // 5b - Use Existing Action data for existing action records
  useEffect(() => {

    if (existingActionData) {
      setFormData({
        id: existingActionData._id,
        sponsorId: existingActionData.sponsorId._id,
        sponsorOrgId: existingActionData.sponsorOrgId._id,
        userOrgId: existingActionData.userOrgId._id,
        trailId: existingActionData.trailId._id, 
        sessionId: existingActionData.sessionId._id,
        sponsorActionTypeId:existingActionData.sponsorActionTypeId._id,
        sponsorSessionActionName:existingActionData.sponsorSessionActionName,
        sponsorSessionActionDesc:existingActionData.sponsorSessionActionDesc,
        sponsorSessionActionCompleteBy:existingActionData.sponsorSessionActionCompleteBy ? existingActionData.sponsorSessionActionCompleteBy.split('T')[0] : "",
        sessionUsers:sessionUsers,  // 02 Sep 2024 - added from the list of incoming props 
      })
    }

  },[existingActionData, sponsorId, sponsorOrgId, userOrgId, trailId, sessionId ])

  // 6 - Reset form upon successful submission
  useEffect(() => {
    if(isSuccess) {
      setFormData({
        sponsorId: sponsorId,
        sponsorOrgId: sponsorOrgId,
        userOrgId: userOrgId,
        trailId: trailId, 
        sessionId: sessionId,
        sponsorActionTypeId:"",
        sponsorSessionActionName:"",
        sponsorSessionActionDesc:"",
        sponsorSessionActionCompleteBy:"", 
        sessionUsers:sessionUsers,  // 02 Sep 2024 - added from the list of incoming props 
        aboutMe:"",
        pic: "", 
      })
      if (onClose) {
        onClose(); // Close the modal
      } else {
        navigate(triggeringUrl || '/sponsorUserOrgTrailSessionActions'); // Navigate to triggering URL or fallback
      }
    }
  
    },[isSuccess,navigate, onClose, triggeringUrl])

// 7 - Form field updates / change handling
const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData(prevState => ({ ...prevState, pic: files[0] }))
    }  else {
      setFormData(prevState => ({ ...prevState, [name]: value }))
    }
  }

// 8 - Form Submit Logic
const handleSubmit = async (e) => {
    e.preventDefault()
  
    // Initialize an object to collect the form data
    const actionData = {
        sponsorId: formData.sponsorId,
        sponsorOrgId: formData.sponsorOrgId,
        userOrgId: formData.userOrgId,
        trailId: formData.trailId, 
        sessionId: formData.sessionId,
        sponsorActionTypeId:formData.sponsorActionTypeId,
        sponsorSessionActionName:formData.sponsorSessionActionName,
        sponsorSessionActionDesc:formData.sponsorSessionActionDesc,
        sponsorSessionActionCompleteBy:formData.sponsorSessionActionCompleteBy, 
        sessionUsers:formData.sessionUsers,  // 02 Sep 2024 - added from the list of incoming props 
        aboutMe:formData.aboutMe,   
    }
  
    //console.log('action Name:',actionData.sponsorSessionActionName)
    //console.log('action CompleteBy Date:',actionData.sponsorSessionActionCompleteBy)
    if(existingActionData) {actionData.id = existingActionData._id}
  
    if (formData.pic) {
      // Convert the image to a base64 string
      const reader = new FileReader()
      reader.onloadend = async () => {
        // Once conversion is done, add the base64 string to your data object
        actionData.pic = reader.result
  
        // Now, send the data object to the server
        try {
          console.log("Form submitted.")
          await createNewSponsorUserOrgTrailSessionAction(actionData).unwrap() // Dispatch the RTK Query mutation
        } catch (error) {
          console.error(error)
        }
      }
      reader.readAsDataURL(formData.pic)
    } else {
      // No image, send other data as is
      try {
        console.log("Form submitted.")
        await createNewSponsorUserOrgTrailSessionAction(actionData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    }
  }
  
  // 9 - Handle Cancel
  const handleCancel = () => {
    if (onClose) {
    onClose(); // Close the modal
  } else {
    navigate(triggeringUrl || '/sponsorUserOrgTrailSessionActions'); // Navigate to triggering URL or fallback
  }
  }
  
  
  // 10 - Handle Clear
    const handleClear = () => {
      setFormData({
        sponsorId: sponsorId,
        sponsorOrgId: sponsorOrgId,
        userOrgId: userOrgId,
        trailId: trailId, 
        sessionId: sessionId,
        sponsorActionTypeId:"",
        sponsorSessionActionName:"",
        sponsorSessionActionDesc:"",
        sponsorSessionActionCompleteBy:"", 
        aboutMe:"",
        pic: "", 
      })
    }

    return (
        <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
          {isLoading && <PulseLoader color={"#ea580c"} />}
          {isError && (
            <p className="text-orange-700 font-semi-bold py-2">
              {error?.data?.message}
            </p>
          )}
          <div className="bg-white p-1 m-1 rounded">
            <p className="p-1 m-1 font-bold font-poppins text-xl">Add or Update a Sponsor Planned Session Action</p>
    
            <div className="bg-gray-200 rounded m-1 p-1">
              <form
                onSubmit={handleSubmit}
                className="m-1 p-1 text-xs font-poppins"
              >
                   
                <div id="actionTypeSelector">
                  <select
                    name="sponsorActionTypeId"
                    id="sponsorActionTypeId"
                    value={formData.sponsorActionTypeId}
                    onChange={handleChange}
                    className="w-full rounded p-2 m-1"
                  >
                    {actionTypeOptions}
                  </select>
                </div>
    
                <div id="sponsorSessionActionName">
                  <input
                    type="text"
                    name="sponsorSessionActionName"
                    placeholder="Enter the userAction name here"
                    value={formData.sponsorSessionActionName}
                    onChange={handleChange}
                    className="w-full rounded p-2 m-1"
                  />
                </div>
       
                <div className="bg-white w-full rounded p-2 m-1">
                  <EtContentEditorLightWithPlace
                    value={formData.sponsorSessionActionDesc}
                    handleChange={(content) =>
                      setFormData({ ...formData, sponsorSessionActionDesc: content })
                    }
                    placeholderText={
                      "Write something more about this action.."
                    }
                  />
                </div>

                <div id="sponsorSessionActionCompleteBy" className="flex justify-between items-center">
                    <div className="p-2 m-1 bg-white rounded w-full"><p className="font-bold">Complete By:</p></div>
                    <input
                        type="date"
                        name="sponsorSessionActionCompleteBy"
                        value={formData.sponsorSessionActionCompleteBy}
                        onChange={handleChange}
                        className="w-full rounded p-2 m-1"
                    />
                </div>
    
                <div id="aboutMe">
                  <input
                    type="text"
                    name="aboutMe"
                    placeholder="Add something about the personality of this action"
                    value={formData.aboutMe}
                    onChange={handleChange}
                    className="w-full rounded p-2 m-1"
                  />
                </div>
    
                <div id="pic">
                  <input
                    type="file"
                    name="pic"
                    onChange={handleChange}
                    className="w-full rounded p-2 m-1"
                  />
                </div>
    
    
                <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
                  <EtBtnFormBasic
                    name={mode === 'create'? "Create": "Update"}
                    type={"create"}
                    handleClick={handleSubmit}
                  />
                  <EtBtnFormBasic
                    name={"Clear"}
                    type={"clear"}
                    handleClick={handleClear}
                  />
                  <EtBtnFormBasic
                    name={"Cancel"}
                    type={"cancel"}
                    handleClick={handleCancel}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )
}

export default EtAddOrUpdateSponsorUserOrgTrailSessionActionComp