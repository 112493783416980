import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const challengesAdapter = createEntityAdapter({})

const initialState = challengesAdapter.getInitialState()

export const challengesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getChallenges: builder.query({
            query: () => ({
                url: '/challenges/getChallenges',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedChallenges = responseData.map(challenge => {
                    challenge.id = challenge._id
                    return challenge
                })
                return challengesAdapter.setAll(initialState, loadedChallenges)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'Challenge', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'Challenge', id}))
                    ]
                } else {
                    return [ { type: 'Challenge', id: 'LIST'}]
                }
            },
        }),
        createNewChallenge: builder.mutation({
            query: initialChallenge => ({
                url: '/challenges/createNewChallenge',
                method: 'POST',
                body: {...initialChallenge,}
            }),
            invalidatesTags: [
                { type: 'Challenge', id: "LIST" }
            ] 
        }),
        updateChallenge: builder.mutation({
            query: initialChallenge => ({
                url: '/challenges/updateChallenge',
                method: 'PATCH',
                body: {...initialChallenge,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Challenge', id: arg.id },
                { type: 'Challenge', id: 'LIST' }
            ],
        }),
        deleteChallenge: builder.mutation({
            query: ({id}) => ({
                url: '/challenges/deleteChallenge',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Challenge', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetChallengesQuery,
    useCreateNewChallengeMutation,
    useUpdateChallengeMutation,
    useDeleteChallengeMutation,
} = challengesApiSlice

// returns the query result object
export const selectChallengeResult = challengesApiSlice.endpoints.getChallenges.select()

// create memoized selector
const selectChallengesData = createSelector(
    selectChallengeResult,
    challengeResult => challengeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllChallenges,
    selectById: selectChallengeById,
    selectIds: selectChallengeIds,
} = challengesAdapter.getSelectors(state => selectChallengesData(state) ?? initialState)