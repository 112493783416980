import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userChallengeSelfEvalsAdapter = createEntityAdapter({})

const initialState = userChallengeSelfEvalsAdapter.getInitialState()

export const userChallengeSelfEvalsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserChallengeSelfEvals: builder.query({
            query: () => ({
                url: '/userChallengeSelfEvals/getUserChallengeSelfEvals',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserChallengeSelfEvals = responseData.map(userChallengeSelfEval => {
                    userChallengeSelfEval.id = userChallengeSelfEval._id
                    return userChallengeSelfEval
                })
                return userChallengeSelfEvalsAdapter.setAll(initialState, loadedUserChallengeSelfEvals)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserChallengeSelfEval', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserChallengeSelfEval', id}))
                    ]
                } else {
                    return [ { type: 'UserChallengeSelfEval', id: 'LIST'}]
                }
            },
        }),
        createNewUserChallengeSelfEval: builder.mutation({
            query: initialUserChallengeSelfEval => ({
                url: '/userChallengeSelfEvals/createNewUserChallengeSelfEval',
                method: 'POST',
                body: {...initialUserChallengeSelfEval,}
            }),
            invalidatesTags: [
                { type: 'UserChallengeSelfEval', id: "LIST" }
            ] 
        }),
        updateUserChallengeSelfEval: builder.mutation({
            query: initialUserChallengeSelfEval => ({
                url: '/userChallengeSelfEvals/updateUserChallengeSelfEval',
                method: 'PATCH',
                body: {...initialUserChallengeSelfEval,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserChallengeSelfEval', id: arg.id },
                { type: 'UserChallengeSelfEval', id: 'LIST' }
            ],
        }),
        deleteUserChallengeSelfEval: builder.mutation({
            query: ({id}) => ({
                url: '/userChallengeSelfEvals/deleteUserChallengeSelfEval',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserChallengeSelfEval', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserChallengeSelfEvalsQuery,
    useCreateNewUserChallengeSelfEvalMutation,
    useUpdateUserChallengeSelfEvalMutation,
    useDeleteUserChallengeSelfEvalMutation,
} = userChallengeSelfEvalsApiSlice

// returns the query result object
export const selectUserChallengeSelfEvalResult = userChallengeSelfEvalsApiSlice.endpoints.getUserChallengeSelfEvals.select()

// create memoized selector
const selectUserChallengeSelfEvalsData = createSelector(
    selectUserChallengeSelfEvalResult,
    userChallengeSelfEvalResult => userChallengeSelfEvalResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserChallengeSelfEvals,
    selectById: selectUserChallengeSelfEvalById,
    selectIds: selectUserChallengeSelfEvalIds,
} = userChallengeSelfEvalsAdapter.getSelectors(state => selectUserChallengeSelfEvalsData(state) ?? initialState)