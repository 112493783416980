import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userMissionTeamsAdapter = createEntityAdapter({})

const initialState = userMissionTeamsAdapter.getInitialState()

export const userMissionTeamsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserMissionTeams: builder.query({
            query: () => ({
                url: '/userMissionTeams/getUserMissionTeams',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserMissionTeams = responseData.map(userMissionTeam => {
                    userMissionTeam.id = userMissionTeam._id
                    return userMissionTeam
                })
                return userMissionTeamsAdapter.setAll(initialState, loadedUserMissionTeams)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserMissionTeam', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserMissionTeam', id}))
                    ]
                } else {
                    return [ { type: 'UserMissionTeam', id: 'LIST'}]
                }
            },
        }),
        createNewUserMissionTeam: builder.mutation({
            query: initialUserMissionTeam => ({
                url: '/userMissionTeams/createNewUserMissionTeam',
                method: 'POST',
                body: {...initialUserMissionTeam,}
            }),
            invalidatesTags: [
                { type: 'UserMissionTeam', id: "LIST" }
            ] 
        }),
        updateUserMissionTeam: builder.mutation({
            query: initialUserMissionTeam => ({
                url: '/userMissionTeams/updateUserMissionTeam',
                method: 'PATCH',
                body: {...initialUserMissionTeam,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserMissionTeam', id: arg.id },
                { type: 'UserMissionTeam', id: 'LIST' }
            ],
        }),
        deleteUserMissionTeam: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userMissionTeams/deleteUserMissionTeam',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userMissionTeams/deleteUserMissionTeam',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserMissionTeam', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserMissionTeamsQuery,
    useCreateNewUserMissionTeamMutation,
    useUpdateUserMissionTeamMutation,
    useDeleteUserMissionTeamMutation,
} = userMissionTeamsApiSlice

// returns the query result object
export const selectUserMissionTeamResult = userMissionTeamsApiSlice.endpoints.getUserMissionTeams.select()

// create memoized selector
const selectUserMissionTeamsData = createSelector(
    selectUserMissionTeamResult,
    userMissionTeamResult => userMissionTeamResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserMissionTeams,
    selectById: selectUserMissionTeamById,
    selectIds: selectUserMissionTeamIds,
} = userMissionTeamsAdapter.getSelectors(state => selectUserMissionTeamsData(state) ?? initialState)