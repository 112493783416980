import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import { PlusIcon, MinusIcon} from '@heroicons/react/24/outline'
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid"
import PulseLoader from "react-spinners/PulseLoader"
import { useGetUsersQuery, useUpdateUserMutation } from "./usersApiSlice"
import { useGetOrganizationsQuery } from "../organizations/organizationsApiSlice"
import { useGetOrgRolesQuery } from "../orgRoles/orgRolesApiSlice" // 06 Sep 2024
import { useGetOrgTitlesQuery } from "../orgTitles/orgTitlesApiSlice" // 06 Sep 2024


const EtUpdateUser = ({id, setActiveAdminView}) => {
  // First Created - 31 Jul 2024
  // Author - Atul Pandey
  // Updated - 06 Sep 2024 - for user org role and org title
  // Updated - 02 Mar 2025 - updated the comp with props - { id, setActiveAdminView}
  // Updated - 02 Mar 2025 - switched from navigate to setActiveAdminView


  // 1 - Set Page Title
  useTitle('Update A User')

  // 2 - Extract the Id to be updated
  // const { id } = useParams()
  // console.log("id:", id)

  // 3 - Extract the user to be updated
  const { user } = useGetUsersQuery("User", {
    selectFromResult: ({ data }) => ({
      user: data?.entities[id],
    }),
  })
  console.log('user', user)

  // 3a - Extract the roles enum list to be used for updating roles -- 01 Aug 2024
  const { rolesEnum } = useGetUsersQuery("User", {
    selectFromResult: ({ data }) => ({
      rolesEnum: data?.rolesEnum,
    }),
  })
  // console.log('roles Enum:', rolesEnum)
   
  // 4 - Check for returned data
  if (!user) {
    return <PulseLoader color={"#ea580c"} />
  }

  // 5. Display data
  let content
  content = <EtUpdateUserForm user={user} id={id} roles={rolesEnum} setActiveAdminView={setActiveAdminView}/> // 02 Mar 2025 - added setActiveAdminView={setActiveAdminView}  

  return content
}

const EtUpdateUserForm = ({user, id, roles, setActiveAdminView}) => {

  // Author - Atul Pandey
  // Updated - 01 Aug 2024 - added roles from rolesEnum - add , remove and select all features
  // Updated - 01 Aug 2024 - added organization assignment
  // Updated - 06 Sep 2024 - for user org role and org title
  // Updated - 13 Mar 2025 - added googleIcalUrl
  // Updated - 14 Mar 2025 - show / hide googleIcalUrl visibility
  

  // 1 - set the variable for navigate
  const navigate = useNavigate()

  // 2a - Extract the organizations data
  const {data:organizationsData} = useGetOrganizationsQuery()
  // console.log('organizations data', organizationsData)

  // 2b - Extract the organization Names from the org data
  const orgInfo = organizationsData?.ids.map((id) => {
    const organization = organizationsData?.entities[id]
    return {orgName:organization.organizationName,orgId:organization._id }
  })
  // console.log('Organization:', orgInfo)

  // 2c - Extract the org roles data -- 06 Sep 2024
  const {data: orgRolesData} = useGetOrgRolesQuery()
  const orgRoles = orgRolesData?.ids?.map((id) => {
    const orgRole = orgRolesData?.entities[id]
    return orgRole
  })
  // console.log('OrgRoles:', orgRoles)

  // 2d - Extract the org titles data -- 06 Sep 2024
  const {data: orgTitlesData} = useGetOrgTitlesQuery()
  const orgTitles = orgTitlesData?.ids?.map((id) => {
    const orgTitle = orgTitlesData?.entities[id]
    return orgTitle
  })
  // console.log('OrgTitles:', orgTitles)

  // 2e - Define the mutation to update the user
  const [updateUser, { isLoading, isSuccess, isError, error }] = useUpdateUserMutation()

  // 2f - Cal url visibility - 14 Mar 2025
  const [isIcalVisible, setIsIcalVisible] = useState(false); // ✅ Toggle visibility

  // 3 - Fill the form data with extracted user info
  const [formData, setFormData] = useState({
    name: user.name,
    email: user.email,
    username: user.username,
    roles: user.roles || [], // Initialize assigned roles // 01 Aug
    userOrgId: user?.userOrgId?._id || null, // 01 Aug
    userOrgRole: user?.userOrgRole?._id || null, // 06 Sep 2024
    userOrgTitle: user?.userOrgTitle?._id || null, // 06 Sep 2024
    googleIcalUrl: user.googleIcalUrl || "", // 13 Mar 2025 - Added Google iCal URL
  });
  
  const [availableRoles, setAvailableRoles] = useState(
    roles.filter((role) => !formData.roles.includes(role))
  );
  // console.log('Available Roles', availableRoles)

  // 4 - Reset form upon successful submission
  useEffect(() => {
    if(isSuccess) {
      setFormData({
        name:"",
        email:"",
        username:"",
        roles:[],
        userOrgId:"",
        googleIcalUrl:""
      })
      // navigate('/users')
      setActiveAdminView('users'); // 02 Mar 2025
    }
    
    },[isSuccess])// ✅ 02 Mar 2025 - Removed `navigate` since it's no longer used.

    // 5a - Form field updates / change handling
  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData(prevState => ({ ...prevState, pic: files[0] }))
    }  else {
      setFormData(prevState => ({ ...prevState, [name]: value }))
    }
  }

  // 5b - Handle Add Role
  const handleAddRole = (role) => {
    setFormData(prevState => ({
      ...prevState,
      roles: [...prevState.roles, role],
    }));
    setAvailableRoles(prevRoles => prevRoles.filter(r => r !== role));
  };

  // 5c - Handle Remove Roles
  const handleRemoveRole = (index) => {
    setFormData(prevState => {
      const updatedRoles = prevState.roles.filter((_, i) => i !== index);
      return { ...prevState, roles: updatedRoles };
    });
    setAvailableRoles(prevRoles => [...prevRoles, formData.roles[index]]);
  };

  // 5d - Organization change
  const handleOrgChange = (e) => {
    setFormData(prevState => ({
      ...prevState,
      userOrgId: e.target.value
    }));
  };

  // 5e - Handle Select All
  const handleSelectAllRoles = () => {
    setFormData(prevState => ({
      ...prevState,
      roles: roles, // Assign all roles
    }));
    setAvailableRoles([]);
  };
  
  // 6 - Form submit handling
  const handleSubmit = async (e) => {
    e.preventDefault()
  
    // Initialize an object to collect the form data
    const userData = {
      name:formData.name,
      email:formData.email,
      username:formData.username,
      roles: formData.roles,
      userOrgId: formData.userOrgId,
      userOrgRole: formData.userOrgRole, // 06 Sep 2024
      userOrgTitle: formData.userOrgTitle, // 06 Sep 2024
      googleIcalUrl: formData.googleIcalUrl, // 13 Mar 2025 - Added Google iCal URL
    }
  
    //console.log('user Name:',userData.userName)
  
    if (formData?.pic) {
      // Convert the image to a base64 string
      const reader = new FileReader()
      reader.onloadend = async () => {
        // Once conversion is done, add the base64 string to your data object
        userData.pic = reader.result
  
        // Now, send the data object to the server
        try {
          userData.id = id
          console.log("Form submitted.")
          await updateUser(userData).unwrap() // Dispatch the RTK Query mutation
        } catch (error) {
          console.error(error)
        }
      }
      reader.readAsDataURL(formData.pic)
    } else {
      // No image, send other data as is
      try {
        userData.id = id
        console.log("Form submitted.")
        await updateUser(userData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    }
  }
    // 7 - Handle Cancel
    const handleCancel = () => {
      // navigate('/users')
      setActiveAdminView('users'); // 02 Mar 2025
    }
  
  // 8 - Handle Clear
    const handleClear = () => {
      setFormData({
        name:"",
        email:"",
        username:"",
        roles:[],
        userOrgId:"",
        googleIcalUrl:""

      })
    }
  
    return (
      <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
        {isLoading && <PulseLoader color={"#ea580c"} />}
        {isError && (
          <p className="text-orange-700 font-semi-bold py-2">
            {error?.data?.message}
          </p>
        )}
        <div className="bg-white p-1 m-1 rounded">
          <p className="p-1 m-1 font-bold font-poppins text-xl">Update a User</p>
    
          <div className="bg-gray-200 rounded m-1 p-1">
            <form
              onSubmit={handleSubmit}
              className="m-1 p-1 text-xs font-poppins"
            >
              
              <div id="name">
                <input
                  type="text"
                  name="name"
                  placeholder="Enter the user's name here"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full rounded p-2 m-1"
                />
              </div>

              <div id="email">
                <input
                  type="text"
                  name="email"
                  placeholder="Enter the user's email here"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full rounded p-2 m-1"
                />
              </div>

              <div id="username">
                <input
                  type="text"
                  name="username"
                  placeholder="Enter the user's username here"
                  value={formData.username}
                  onChange={handleChange}
                  className="w-full rounded p-2 m-1"
                />
              </div>

              {/* 13 Mar 2025 - Add Google iCal URL Field */}
              <div id="googleIcalUrl" className="relative">
                <input
                  // type={isIcalVisible ? "text" : "password"}
                  type={"text"}
                  name="googleIcalUrl"
                  placeholder="Enter Google Calendar iCal URL"
                  // value={formData.googleIcalUrl}
                  value={isIcalVisible ? formData.googleIcalUrl : "•••••••••"}
                  onChange={handleChange}
                  className="w-full rounded p-2 m-1 pr-10"
                  // className={`w-full rounded p-2 m-1 pr-10 ${isIcalVisible ? "break-words pr-4" : "tracking-widest text-gray-400"}`} 
                  // className={`w-full rounded p-2 m-1 pr-10 break-words overflow-hidden text-ellipsis whitespace-pre-wrap ${isIcalVisible ? "pr-4" : "tracking-widest text-gray-400"}`} 
                  readOnly={!isIcalVisible} // ✅ Prevent user from editing when masked
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-3 flex items-center"
                  onClick={() => setIsIcalVisible(prev => !prev)}
                >
                  {isIcalVisible ? (
                    <EyeSlashIcon className="h-4 w-4 text-orange-600" /> // ✅ Hide
                  ) : (
                    <EyeIcon className="h-4 w-4 text-orange-600" /> // ✅ Show
                  )}
                </button>
              </div>

              {/* Roles Section */}
              <div id="roles" className="bg-white rounded p-2 m-1 font-poppins">
                <p className="font-semibold m-1 p-1">Roles:</p>
                {formData.roles?.map((role, index) => (
                  <div key={index} className="flex items-center justify-start mx-1 px-1">
                    <button type="button" onClick={() => handleRemoveRole(index)} className="bg-red-400 hover:bg-red-600 rounded p-1">
                      <MinusIcon className="h-5 w-5 text-white" />
                    </button>
                    <div className="m-1 p-1 border rounded w-full"><span className="px-1">{role}</span></div>
                  </div>
                ))}
                {availableRoles.length > 0 && (
                  <div className="flex justify-start items-center">
                    {/* Added - Select All Button */}
                    <div className="">
                      <button
                        type="button"
                        onClick={handleSelectAllRoles}
                        className="bg-lime-500 hover:bg-lime-700 text-white rounded p-2"
                      >
                        Select All
                      </button>
                    </div>
                    <div className="">
                      <select onChange={(e) => handleAddRole(e.target.value)} className="rounded p-2 m-1 border">
                        <option value="">Select a role to add</option>
                        {availableRoles.map(role => (
                          <option key={role} value={role}>{role}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>

              {/* Organization Section */}
              <div id="organization" className="bg-white rounded p-2 m-1 font-poppins">
                <p className="font-semibold m-1 p-1">Organization:</p>
                <select
                  name="organization"
                  value={formData.userOrgId}
                  onChange={handleOrgChange}
                  className="rounded p-2 m-1 border"
                >
                  <option value="">Select an organization</option>
                  {orgInfo?.map(org => (
                    <option key={org?.orgId} value={org.orgId}>{org.orgName}</option>
                  ))}
                </select>
              </div>

              {/* Org Role Section */}
              <div id="orgRole" className="bg-white rounded p-2 m-1 font-poppins">
                <p className="font-semibold m-1 p-1">Organization Role:</p>
                <select
                  name="orgRole"
                  value={formData?.userOrgRole}
                  onChange={(e) => setFormData(prevState => ({ ...prevState, userOrgRole: e.target.value }))}
                  className="rounded p-2 m-1 border"
                >
                  <option value="">Select an organization role</option>
                  {orgRoles?.map(role => (
                    <option key={role?._id} value={role._id}>{role.orgRoleName}</option>
                  ))}
                </select>
              </div>

              {/* Org Title Section */}
              <div id="orgTitle" className="bg-white rounded p-2 m-1 font-poppins">
                <p className="font-semibold m-1 p-1">Organization Title:</p>
                <select
                  name="orgTitle"
                  value={formData?.userOrgTitle}
                  onChange={(e) => setFormData(prevState => ({ ...prevState, userOrgTitle: e.target.value }))}
                  className="rounded p-2 m-1 border"
                >
                  <option value="">Select an organization title</option>
                  {orgTitles?.map(title => (
                    <option key={title?._id} value={title._id}>{title.orgTitleName}</option>
                  ))}
                </select>
              </div>

             <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
                <EtBtnFormBasic
                  name={"Update"}
                  type={"create"}
                  handleClick={handleSubmit}
                />
                <EtBtnFormBasic
                  name={"Clear"}
                  type={"clear"}
                  handleClick={handleClear}
                />
                <EtBtnFormBasic
                  name={"Cancel"}
                  type={"cancel"}
                  handleClick={handleCancel}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    )


}

export default EtUpdateUser