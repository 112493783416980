import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit"
import { apiSlice } from "@/app/api/apiSlice"

const trailsAdapter = createEntityAdapter({})

const initialState = trailsAdapter.getInitialState()

export const trailsApiSlice = apiSlice.injectEndpoints({

    endpoints: builder => ({
        getTrails: builder.query({
            query: () => ({
                url: '/trails/getTrails',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {

                const loadedTrails = responseData.map(questionSet =>{
                    questionSet.id = questionSet._id
                    return questionSet
                })
                return trailsAdapter.setAll(initialState, loadedTrails)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'Trail', id: 'LIST'},
                        ...result.ids.map(id => ({type: 'Trail', id}))
                    ]
                } else {
                    return [{type: 'Trail', id: 'LIST'}]
                }
            },
        }),
        createNewTrail: builder.mutation({
         query: initialTrail => ({
            url: '/trails/createNewTrail',
            method: 'POST',
            body: {...initialTrail,}
         }),
         invalidatesTags: [
            { type: 'Trail', id: "LIST" }
        ]  
        }),
        updateTrail: builder.mutation({
            query: initialTrail => ({
                url: '/trails/updateTrail',
                method: 'PATCH',
                body: {...initialTrail,}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Trail', id: arg.id}
            ]
        }),
        deleteTrail: builder.mutation({
            query: ({id}) => ({
                url: '/trails/deleteTrail',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg ) => [
                { type: 'Trail', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetTrailsQuery,
    useCreateNewTrailMutation,
    useUpdateTrailMutation,
    useDeleteTrailMutation,
} = trailsApiSlice

// returns the query result object
export const selectTrailsResult = trailsApiSlice.endpoints.getTrails.select()

// create memoized selector
const selectTrailsData = createSelector(
    selectTrailsResult,
    trailsResult => trailsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllTrails,
    selectById: selectTrailById,
    selectIds: selectTrailIds,
} = trailsAdapter.getSelectors(state => selectTrailsData(state) ?? initialState)