import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userTrailSessionPlansAdapter = createEntityAdapter({})

const initialState = userTrailSessionPlansAdapter.getInitialState()

export const userTrailSessionPlansApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserTrailSessionPlans: builder.query({
            query: () => ({
                url: '/userTrailSessionPlans/getUserTrailSessionPlans',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserTrailSessionPlans = responseData.map(userTrailSessionPlan => {
                    userTrailSessionPlan.id = userTrailSessionPlan._id
                    return userTrailSessionPlan
                })
                return userTrailSessionPlansAdapter.setAll(initialState, loadedUserTrailSessionPlans)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserTrailSessionPlan', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserTrailSessionPlan', id}))
                    ]
                } else {
                    return [ { type: 'UserTrailSessionPlan', id: 'LIST'}]
                }
            },
        }),
        createNewUserTrailSessionPlan: builder.mutation({
            query: initialUserTrailSessionPlan => ({
                url: '/userTrailSessionPlans/createNewUserTrailSessionPlan',
                method: 'POST',
                body: {...initialUserTrailSessionPlan,}
            }),
            invalidatesTags: [
                { type: 'UserTrailSessionPlan', id: "LIST" }
            ] 
        }),
        updateUserTrailSessionPlan: builder.mutation({
            query: initialUserTrailSessionPlan => ({
                url: '/userTrailSessionPlans/updateUserTrailSessionPlan',
                method: 'PATCH',
                body: {...initialUserTrailSessionPlan,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserTrailSessionPlan', id: arg.id },
                { type: 'UserTrailSessionPlan', id: 'LIST' }
            ],
        }),
        deleteUserTrailSessionPlan: builder.mutation({
            query: ({id}) => ({
                url: '/userTrailSessionPlans/deleteUserTrailSessionPlan',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserTrailSessionPlan', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserTrailSessionPlansQuery,
    useCreateNewUserTrailSessionPlanMutation,
    useUpdateUserTrailSessionPlanMutation,
    useDeleteUserTrailSessionPlanMutation,
} = userTrailSessionPlansApiSlice

// returns the query result object
export const selectUserTrailSessionPlanResult = userTrailSessionPlansApiSlice.endpoints.getUserTrailSessionPlans.select()

// create memoized selector
const selectUserTrailSessionPlansData = createSelector(
    selectUserTrailSessionPlanResult,
    userTrailSessionPlanResult => userTrailSessionPlanResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserTrailSessionPlans,
    selectById: selectUserTrailSessionPlanById,
    selectIds: selectUserTrailSessionPlanIds,
} = userTrailSessionPlansAdapter.getSelectors(state => selectUserTrailSessionPlansData(state) ?? initialState)