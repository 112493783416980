
    import React from 'react';
    
    const EtLogoutIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" fill="currentColor">
          <g transform="translate(-216 -130)"><path d="M290.88 168.725 277.277 155.122C275.779 153.625 273.341 153.625 271.843 155.122 270.346 156.62 270.346 159.058 271.843 160.556L285.446 174.159 254.401 174.159C252.279 174.159 250.561 175.877 250.561 177.999 250.561 180.121 252.279 181.839 254.401 181.839L285.446 181.839 271.843 195.442C270.346 196.94 270.346 199.369 271.843 200.876 272.592 201.624 273.571 201.999 274.56 201.999 275.549 201.999 276.528 201.625 277.277 200.876L290.88 187.273C293.357 184.796 294.72 181.503 294.72 177.999 294.72 174.495 293.357 171.202 290.88 168.725Z" fillRule="evenodd"/><path d="M233.28 204.88C233.28 207.002 234.998 208.72 237.12 208.72 239.242 208.72 240.96 207.002 240.96 204.88L240.96 151.12C240.96 148.998 239.242 147.28 237.12 147.28 234.998 147.28 233.28 148.998 233.28 151.12Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtLogoutIcon1SVGComp;
    