import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userAssessmentResponseWithScoresAdapter = createEntityAdapter({})

const initialState = userAssessmentResponseWithScoresAdapter.getInitialState()

export const userAssessmentResponseWithScoresApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserAssessmentResponseWithScores: builder.query({
            query: () => ({
                url: '/userAssessmentResponseWithScores',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserAssessmentResponseWithScores = responseData.map(userAssessmentResponseWithScore => {
                    userAssessmentResponseWithScore.id = userAssessmentResponseWithScore._id
                    return userAssessmentResponseWithScore
                })
                return userAssessmentResponseWithScoresAdapter.setAll(initialState, loadedUserAssessmentResponseWithScores)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserAssessmentResponseWithScore', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserAssessmentResponseWithScore', id}))
                    ]
                } else {
                    return [ { type: 'UserAssessmentResponseWithScore', id: 'LIST'}]
                }
            },
        }),
        createNewUserAssessmentResponseWithScore: builder.mutation({
            query: initialUserAssessmentResponseWithScore => ({
                url: '/userAssessmentResponseWithScores',
                method: 'POST',
                body: {...initialUserAssessmentResponseWithScore,}
            }),
            invalidatesTags: [
                { type: 'UserAssessmentResponseWithScore', id: "LIST" }
            ] 
        }),
        updateUserAssessmentResponseWithScore: builder.mutation({
            query: initialUserAssessmentResponseWithScore => ({
                url: '/userAssessmentResponseWithScores',
                method: 'PATCH',
                body: {...initialUserAssessmentResponseWithScore,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserAssessmentResponseWithScore', id: arg.id },
                { type: 'UserAssessmentResponseWithScore', id: 'LIST' }
            ],
        }),
        deleteUserAssessmentResponseWithScore: builder.mutation({
            query: ({id}) => ({
                url: '/userAssessmentResponseWithScores',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserAssessmentResponseWithScore', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserAssessmentResponseWithScoresQuery,
    useCreateNewUserAssessmentResponseWithScoreMutation,
    useUpdateUserAssessmentResponseWithScoreMutation,
    useDeleteUserAssessmentResponseWithScoreMutation,
} = userAssessmentResponseWithScoresApiSlice

// returns the query result object
export const selectUserAssessmentResponseWithScoreResult = userAssessmentResponseWithScoresApiSlice.endpoints.getUserAssessmentResponseWithScores.select()

// create memoized selector
const selectUserAssessmentResponseWithScoresData = createSelector(
    selectUserAssessmentResponseWithScoreResult,
    userAssessmentResponseWithScoreResult => userAssessmentResponseWithScoreResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserAssessmentResponseWithScores,
    selectById: selectUserAssessmentResponseWithScoreById,
    selectIds: selectUserAssessmentResponseWithScoreIds,
} = userAssessmentResponseWithScoresAdapter.getSelectors(state => selectUserAssessmentResponseWithScoresData(state) ?? initialState)