import React from 'react'
import { 
  basicCredentials,
  domainPresence,
  earnedTestimonialsAndLegacy,
  industryPresence,
  influencingPresence,
  partnerPresence,
  personalityPresence,
  rolePCI,
  socialPresence
 } from '../../assets/img/sessionImgAssets'

const pixelColors = [
  {'color':'etpx-0','pxlevel':'Dark'},
  {'color':'etpx-1','pxlevel':'Dim'},
  {'color':'etpx-2','pxlevel':'Low'},
  {'color':'etpx-3','pxlevel':'Warm'},
  {'color':'etpx-4','pxlevel':'Bright'},
  {'color':'orange-600','pxlevel':'Radient'},
]

const presenceAreas = [
  {"name": "Basic Credentials", "image": basicCredentials},
  {"name": "Social Presence", "image": socialPresence},
  {"name": "Industry Presence", "image":industryPresence },
  {"name": "Domain Presence", "image":domainPresence },
  {"name": "Partner Presence", "image":partnerPresence },
  {"name": "Roles, Contributions & Impact", "image":rolePCI },
  {"name": "Personality Presence", "image":personalityPresence },
  {"name": "Larger Influencing Presence", "image":influencingPresence },
  {"name": "Earned Testimonials & Legacy", "image":earnedTestimonialsAndLegacy },
]

const EtPixelTest = () => {
  return (
    <div className="mx-auto p-1 my-2 font-oswald">
        <div className='flex justify-center bg-gradient-to-r from-black to-black via-etpx-bg w-[40vw] rounded-lg p-2'>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-[2px]">
        {presenceAreas.map((item,index) => (
          <div key={index} className="p-[3px] rounded bg-transparent border border-double border-slate-800">
            <div
              className={`rounded border bg-transparent border-black h-full flex flex-col justify-center items-center text-white cursor-pointer`}
            >
              <div className="py-1"><img src={item.image} alt="" className='h-8 w-8' /></div>
              <div className=""><p className='text-6xl my-1 py-1  font-bold'>{index+1}</p></div>
              <div className="px-7"><p className='text-[11px] font-extralight'>{item.name}</p></div>
              <div className='h-10'>
                {/* pixel colors */}
                {/* <div className='flex justify-center items-center m-1 p-1'>
                  {pixelColors.map((item, index) => (
                    <div className={`rounded-full h-3 w-3 bg-${item.color} m-1`}>
                    </div>
                  ))}
                </div> */}
              </div>

            </div>
          </div>
        ))}
      </div>


      {/* <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-[2px]">
        {pixelColors.map((item,index) => (
          <div key={index} className="p-[3px] rounded bg-transparent border border-slate-600 h-48 w-48">
            <div
              className={`rounded border bg-${item.color} border-black h-full flex flex-col justify-center items-center`}
            >
              <div className="py-2"></div>
              <div className=""><p className='text-white text-8xl my-1 py-1  font-bold'>{index}</p></div>
              <div className=""><p className='text-gray-200 text-xs'>{item.pxlevel}</p></div>

            </div>
          </div>
        ))}
      </div> */}

      
      </div>
    </div>
  )
}

export default EtPixelTest