
    import React from 'react';
    
    const EtCreationTypeIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" fill="currentColor">
          <g transform="translate(-606 -79)"><path d="M723.9 197.372C723.068 197.372 722.258 197.439 721.47 197.574 720.795 197.687 720.098 197.484 719.58 197.012 719.062 196.54 718.792 195.865 718.838 195.167 718.883 194.717 718.927 194.222 718.927 193.749 718.927 184.412 711.255 176.807 701.828 176.807 700.365 176.807 698.903 177.009 697.463 177.392 696.855 177.549 696.225 177.459 695.708 177.144 695.168 176.829 694.808 176.312 694.673 175.704 693.008 167.987 686.01 162.407 678 162.407 669.99 162.407 662.993 167.987 661.328 175.704 661.193 176.312 660.833 176.829 660.293 177.144 659.775 177.459 659.123 177.549 658.538 177.392 657.098 177.009 655.635 176.807 654.173 176.807 644.746 176.807 637.073 184.411 637.073 193.749 637.073 194.222 637.118 194.717 637.163 195.167 637.208 195.865 636.938 196.539 636.421 197.012 635.903 197.485 635.206 197.687 634.531 197.574 633.743 197.439 632.933 197.372 632.101 197.372 623.438 197.372 616.261 203.762 615.158 212.043L740.842 212.043C739.739 203.763 732.562 197.372 723.899 197.372Z" fillRule="evenodd"/><path d="M630.108 137.444C645.96 147.878 661.766 140.279 667.126 137.073 672.003 142.872 675.637 149.909 675.727 158.048 675.727 158.048 675.75 158.025 675.772 158.048 676.492 157.958 677.235 157.913 678 157.913 678.765 157.913 679.507 157.958 680.227 158.048 680.25 158.025 680.272 158.048 680.272 158.048 680.34 151.236 683.417 145.324 687.529 140.441 692.349 143.259 705.495 149.267 718.697 140.576 734.869 129.928 729.818 98.2881 729.818 98.2881 719.249 106.889 701.467 103.187 690.539 114.831 683.446 122.385 684.082 131.881 684.937 136.464L684.931 136.47C682.209 139.53 679.824 142.995 678.181 146.865 676.291 141.696 673.259 137.083 669.727 133.106 670.773 127.92 671.82 116.321 663.225 107.163 650.371 93.461 629.464 97.809 617.033 87.6946 617.033 87.6946 611.087 124.916 630.111 137.435Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtCreationTypeIcon1SVGComp;
    