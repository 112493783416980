import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const contactTypesAdapter = createEntityAdapter({})

const initialState = contactTypesAdapter.getInitialState()

export const contactTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getContactTypes: builder.query({
            query: () => ({
                url: '/contactTypes/getContactTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedContactTypes = responseData.map(contactType => {
                    contactType.id = contactType._id
                    return contactType
                })
                return contactTypesAdapter.setAll(initialState, loadedContactTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'ContactType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'ContactType', id}))
                    ]
                } else {
                    return [ { type: 'ContactType', id: 'LIST'}]
                }
            },
        }),
        createNewContactType: builder.mutation({
            query: initialContactType => ({
                url: '/contactTypes/createNewContactType',
                method: 'POST',
                body: {...initialContactType,}
            }),
            invalidatesTags: [
                { type: 'ContactType', id: "LIST" }
            ] 
        }),
        updateContactType: builder.mutation({
            query: initialContactType => ({
                url: '/contactTypes/updateContactType',
                method: 'PATCH',
                body: {...initialContactType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'ContactType', id: arg.id },
                { type: 'ContactType', id: 'LIST' }
            ],
        }),
        deleteContactType: builder.mutation({
            query: ({id}) => ({
                url: '/contactTypes/deleteContactType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'ContactType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetContactTypesQuery,
    useCreateNewContactTypeMutation,
    useUpdateContactTypeMutation,
    useDeleteContactTypeMutation,
} = contactTypesApiSlice

// returns the query result object
export const selectContactTypeResult = contactTypesApiSlice.endpoints.getContactTypes.select()

// create memoized selector
const selectContactTypesData = createSelector(
    selectContactTypeResult,
    contactTypeResult => contactTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllContactTypes,
    selectById: selectContactTypeById,
    selectIds: selectContactTypeIds,
} = contactTypesAdapter.getSelectors(state => selectContactTypesData(state) ?? initialState)