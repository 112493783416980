// Author - Atul Pandey
// Updated - 18 Feb 2025 - refined useRef to useEffect

import { Outlet, Link } from "react-router-dom"
import { useEffect, useRef, useState } from 'react'
import { useRefreshMutation } from "./authApiSlice"
import usePersist from "../../hooks/usePersist"
import { useSelector } from 'react-redux'
import { selectCurrentToken } from "./authSlice"

const PersistLogin = () => {

    const [persist] = usePersist()
    const token = useSelector(selectCurrentToken)
    const effectRan = useRef(false) // 18 Feb 2025 - We are replacing this with a useState flag (hasRefreshed) for better control.

    const [trueSuccess, setTrueSuccess] = useState(false)
    const [hasRefreshed, setHasRefreshed] = useState(false); // 18 Feb 2025

    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRefreshMutation()


    // 18 Feb 2025 - Modify useEffect to Use useState Instead of useRef
    // useEffect(() => {                   
    //     let isMounted = true; // Flag to track component mount
    
    //     const verifyRefreshToken = async () => {
    //         try {
    //             await refresh();
    //             if (isMounted) {
    //                 setTrueSuccess(true);
    //                 setHasRefreshed(true);
    //             }
    //         } catch (err) {
    //             console.error(err);
    //         }
    //     };
    
    //     if (!token && persist && !hasRefreshed) {
    //         verifyRefreshToken();
    //     }
    
    //     return () => {
    //         isMounted = false; // Cleanup function
    //     };
    // }, [token, persist, refresh, hasRefreshed]);
    
    useEffect(() => {

        if (effectRan.current === true || process.env.NODE_ENV !== 'development') { // React 18 Strict Mode

            const verifyRefreshToken = async () => {
                //console.log('verifying refresh token')
                try {
                    //const response = 
                    await refresh()
                    //const { accessToken } = response.data
                    setTrueSuccess(true)
                }
                catch (err) {
                    console.error(err)
                }
            }

            if (!token && persist) verifyRefreshToken()
        }

        return () => effectRan.current = true

        // eslint-disable-next-line
    }, [])

    let content
    if (!persist) { // persist: no
        //console.log('no persist')
        content = <Outlet />
    } else if (isLoading) { //persist: yes, token: no
        //console.log('loading')
        content = <p>Loading...</p>
    } else if (isError) { //persist: yes, token: no
        //console.log('error')
        content = (
            <div className="m-1 p-2 bg-gray-200 rounded">
            <p className='text-orange-800 font-bold'>
                {`${error?.data?.message} - `}
                <Link to="/login" className="text-gray-800">Please <span className="text-orange-600 underline font-bold">click here</span> to login</Link>.
            </p>
            </div>
        )
    } else if (isSuccess && trueSuccess) { //persist: yes, token: yes
        //console.log('success')
        content = <Outlet />
    } else if (token && isUninitialized) { //persist: yes, token: yes
        //console.log('token and uninit')
        //console.log(isUninitialized)
        content = <Outlet />
    }

    return content
}
export default PersistLogin
