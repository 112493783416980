
    import React from 'react';
    
    const EtInterpretIconSVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m3416.4 1000.8h-306c-39.602 0-72 21.602-86.398 57.602l-97.199 230.4-280.8-666c-28.801-68.398-144-68.398-172.8 0l-158.4 378h-244.8c-50.398 0-93.602 43.199-93.602 93.602 0 50.398 43.199 93.602 93.602 93.602h309.6c39.602 0 72-21.602 86.398-57.602l90-230.4 280.8 666c14.398 36 50.398 57.602 86.398 57.602 39.602 0 72-21.602 86.398-57.602l162-378h244.8c50.398 0 93.602-43.199 93.602-93.602-0.003906-50.398-43.203-93.598-93.605-93.598z" fillRule="evenodd"/>
 <path d="m1438.5 704.2c152.65 71.305 255.66 218.63 270.23 386.48 14.574 167.85-61.504 330.72-199.58 427.27-138.07 96.547-317.17 112.1-469.82 40.793-152.65-71.305-255.66-218.63-270.23-386.48-14.574-167.85 61.504-330.72 199.58-427.27 138.07-96.547 317.17-112.1 469.82-40.793" fillRule="evenodd"/>
 <path d="m2232 1450.8c-205.2 0-374.4 165.6-374.4 374.4 0 118.8 57.602 226.8 147.6 295.2l-115.2 262.8-324 10.801s-187.2-316.8-291.6-489.6c-144-234-518.4-489.6-799.2-244.8-273.6 241.2-360 842.4-381.6 1270.8-3.6016 57.602 43.199 104.4 97.199 104.4h846c54 0 97.199-43.199 97.199-97.199v-421.2l126 212.4c21.602 36 54 68.398 93.602 86.398 32.398 14.398 64.801 21.602 97.199 21.602l583.2-28.797c118.8-3.6016 212.4-104.4 208.8-223.2-3.6016-97.199-72-176.4-162-201.6l86.398-194.4c21.602 3.6016 43.199 7.1992 64.801 7.1992 205.2 0 374.4-165.6 374.4-374.4-3.5977-205.2-169.2-370.8-374.4-370.8zm0 568.8c-108 0-198-90-198-198s90-198 198-198 198 90 198 198c0 111.6-90 198-198 198z" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtInterpretIconSVGComp;
    