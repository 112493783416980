
    import React from 'react';
    
    const EtMlLifeLongIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" fill="currentColor">
          <g transform="translate(-213 -119)"><path d="M258.964 201.496 243.444 201.496C239.902 201.496 237.026 204.372 237.026 207.918L237.026 251.958 265.383 251.958 265.383 207.918C265.383 204.372 262.506 201.496 258.964 201.496ZM256.7 240.084 251.21 236.096 245.712 240.084 247.811 233.626 242.321 229.637 249.107 229.637 251.21 223.182 253.305 229.637 260.098 229.637 254.601 233.626Z" fillRule="evenodd"/><path d="M297.852 182.644 282.336 182.644C278.794 182.644 275.917 185.52 275.917 189.063L275.917 251.959 304.274 251.959 304.274 189.063C304.274 185.52 301.398 182.644 297.852 182.644ZM295.588 240.08 290.098 236.091 284.608 240.08 286.703 233.622 281.213 229.633 287.999 229.633 290.101 223.178 292.196 229.633 298.99 229.633 293.5 233.622ZM293.492 208.234 295.587 214.693 290.097 210.704 284.607 214.693 286.703 208.234 281.213 204.249 287.999 204.249 290.101 197.791 292.196 204.249 298.989 204.249Z" fillRule="evenodd"/><path d="M336.744 160.528 321.232 160.528C317.682 160.528 314.806 163.401 314.806 166.947L314.806 251.955 343.163 251.955 343.163 166.947C343.163 163.401 340.286 160.528 336.744 160.528ZM334.48 240.08 328.99 236.091 323.5 240.08 325.595 233.622 320.105 229.633 326.898 229.633 328.993 223.178 331.088 229.633 337.882 229.633 332.392 233.622ZM332.384 208.234 334.479 214.693 328.989 210.704 323.499 214.693 325.595 208.234 320.105 204.249 326.898 204.249 328.993 197.791 331.088 204.249 337.881 204.249ZM332.384 182.851 334.479 189.306 328.989 185.317 323.499 189.306 325.595 182.851 320.105 178.858 326.898 178.858 328.993 172.404 331.088 178.858 337.881 178.858Z" fillRule="evenodd"/><path d="M330.744 148.696 341.515 130.041 319.98 130.041 324.008 137.032 226.836 193.136 229.536 197.813 326.708 141.709Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtMlLifeLongIcon1SVGComp;
    