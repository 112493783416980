import React from 'react'
import { XCircleIcon } from '@heroicons/react/24/solid'
import EtTrinityUserMeetingConversation from '@/features/userConversations/EtTrinityUserMeetingConversation'

const EtModalTrinityUserMeetingConversation = ({
  isOpen,
  userId,
  userOrgId,
  noteId = null, // ✅ 25 Mar 2025 - new prop
  isDelete = false,// ✅ 25 Mar 2025 - new prop
  refetchUserConversations = () => {},// ✅ 25 Mar 2025 - new prop
  userMeetingPrepId,
  calendarMeetingId,
  onClose
}) => {

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 z-70 flex justify-center items-start font-poppins overflow-y-auto p-4">
      <div className="bg-black p-3 rounded-lg shadow-lg shadow-black relative w-full max-w-6xl">
        
        {/* Close Button */}
        <div
          className="absolute top-2 right-2 m-2 font-bold rounded-full cursor-pointer h-7 w-7 flex justify-center items-center"
          onClick={onClose}
        >
          <XCircleIcon className='text-orange-600 rounded-full hover:text-red-600 h-7 w-7' />
        </div>

        {/* Main Conversation Component */}
        <EtTrinityUserMeetingConversation
          userId={userId}
          userOrgId={userOrgId}
          noteId={noteId}           // ✅ 25 Mar 2025 - new prop
          isDelete={isDelete}       // ✅ 25 Mar 2025 - new prop
          refetchUserConversations={refetchUserConversations}       // ✅ 25 Mar 2025 - new prop
          userMeetingPrepId={userMeetingPrepId}
          calendarMeetingId={calendarMeetingId}
          onClose={onClose}
        />
      </div>
    </div>
  )
}

export default EtModalTrinityUserMeetingConversation