import React, { useState, useEffect } from "react"
import { useNavigate, useParams  } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLightWithPlace from "@/utils/EtContentEditorLightWithPlace"
import EtContentEditorLight3 from "@/utils/EtContentEditorLight3"
import { PlusIcon, MinusIcon} from '@heroicons/react/24/outline'
import { useGetContactOrganizationsQuery, useUpdateContactOrganizationMutation } from "./contactOrganizationsApiSlice"
import { useGetContactOrganizationTypesQuery } from "../contactOrganizationTypes/contactOrganizationTypesApiSlice"
import { useGetUsersQuery } from "../users/usersApiSlice" // 06 Sep 2024

const EtUpdateContactOrganization = ({id, setActiveAdminView, userOrgId }) => {
  // First Created - 09 May 2024
  // Author - Atul Pandey
  // Updated - 06 Sep 2024 - added user org role and titles
  // Updated - 07 Mar 2025 - updated the comp with props - { id, setActiveAdminView}
  // Updated - 07 Mar 2025 - switched from navigate to setActiveAdminView

  // 1 - Set Page Title
  useTitle('Update A ContactOrganization')

  // 2 - Extract the Id to be updated
  // const { id } = useParams()
  const passedUserOrgId = userOrgId
  //console.log("id:", id)

  // 3 - Extract the contactOrganization to be updated
  const { contactOrganization } = useGetContactOrganizationsQuery("ContactOrganization", {
    selectFromResult: ({ data }) => ({
      contactOrganization: data?.entities[id],
    }),
  })
  //console.log('contactOrganization', contactOrganization)
  //console.log('contactOrganization trailId id', contactOrganization?.trailId._id)
  //console.log('contactOrganization trailId name', contactOrganization?.trailId.trailName)

  // 4 - Check for returned data
  if (!contactOrganization) {
    return <PulseLoader color={"#ea580c"} />
  }

  // 5. Display data
  let content
  content = <EtUpdateContactOrganizationForm contactOrganization={contactOrganization} id={id} passedUserOrgId={passedUserOrgId} setActiveAdminView={setActiveAdminView}/> // 07 Mar 2025 - added setActiveAdminView={setActiveAdminView}

  return content

}

const EtUpdateContactOrganizationForm = ({contactOrganization, id, passedUserOrgId, setActiveAdminView}) => {
  // First Created - 09 May 2024
  // Author - Atul Pandey
  // Updated - 06 Sep 2024 - added user org role and titles

  // 1 - set the variable for navigate
  const navigate = useNavigate()

  // 2 - Define the mutation to update the contactOrganization
  const [updateContactOrganization, { isLoading, isSuccess, isError, error }] = useUpdateContactOrganizationMutation()

  // 3a - Fill the form data with extracted contactOrganization info
  const [formData, setFormData] = useState({
    contactOrganizationTypeId: contactOrganization.contactOrganizationTypeId._id,
    contactOrganizationName: contactOrganization.contactOrganizationName,
    contactOrganizationDesc: contactOrganization.contactOrganizationDesc,
    contactOrganizationEmail: contactOrganization.contactOrganizationEmail,     // Added
    contactOrganizationPhone: contactOrganization.contactOrganizationPhone,     // Added
    contactOrganizationWebsite: contactOrganization.contactOrganizationWebsite, // Added
    contactOrganizationPurpose: contactOrganization.contactOrganizationPurpose || "",
    contactOrganizationIntro: contactOrganization.contactOrganizationIntro || "",
    contactOrganizationOutro: contactOrganization.contactOrganizationOutro || "",
    contactOrganizationTargetAudience: contactOrganization.contactOrganizationTargetAudience || [{ audience: "" }],
    contactOrganizationLocations: contactOrganization.contactOrganizationLocations || [{ location: "" }],
    contactOrganizationTeamSize: contactOrganization.contactOrganizationTeamSize || "",
    contactOrganizationRevenueSize: contactOrganization.contactOrganizationRevenueSize || "",
    contactOrganizationExistenceDuration: contactOrganization.contactOrganizationExistenceDuration || "", 
    contactOrganizationStartedDate: contactOrganization.contactOrganizationStartedDate || "",
    contactOrganizationRecognitions: contactOrganization.contactOrganizationRecognitions || [{ recognition: "" }],
    contactOrganizationContributions: contactOrganization.contactOrganizationContributions || [{ contribution: "" }],
    aboutMe: contactOrganization.aboutMe || "",
    contactOrganizationSponsors: contactOrganization.contactOrganizationSponsors || [{ sponsorId: "" }], // 06 Sep 2024 - Added for sponsors
  })
    //console.log('Loaded formData for contactOrganization', formData)
   // console.log('formData.contactOrganizationTypeId', formData.contactOrganizationTypeId)

   // 3b - Filter users who belong to the same org and have 'OrgSponsor' role - 06 Sep 2024
  const { data: usersData } = useGetUsersQuery("User");
  const eligibleSponsors = usersData?.ids
    .map(id => usersData.entities[id])
    .filter(user => 
      user.userOrgId?._id === contactOrganization._id && 
      user.roles.includes('OrgSponsor')
    ) || [];
  console.log('Eligible Sponsors:', eligibleSponsors)


    // 4 - Get contactOrganization types data for options
    const { contactOrganizationTypes } = useGetContactOrganizationTypesQuery('contactOrganizationTypesList', {
      selectFromResult: ( { data }) => ({
        contactOrganizationTypes: data?.ids.map(id => data?.entities[id])
      })
    })

  //console.log('contactOrganization types:', contactOrganizationTypes)

  
// 5 - Create options to select a contactOrganization type from list of contactOrganization types
const contactOrganizationTypeOptions = contactOrganizationTypes ? [
    <option key="placeholder" value="" disabled>
        Select a Contact Organization Type
    </option>,
    ...contactOrganizationTypes?.map(contactOrganizationType => (
        <option 
        key={contactOrganizationType.id} 
        value={contactOrganizationType.id}
        >
            {contactOrganizationType.contactOrganizationTypeName}
        </option>
    ))
] : []

   // 6 - Reset form upon successful submission
  useEffect(() => {
    if(isSuccess) {
      setFormData({
        contactOrganizationTypeId: "", 
        contactOrganizationName: "",
        contactOrganizationDesc: "",
        contactOrganizationEmail: "",        // Added
        contactOrganizationPhone: "",        // Added
        contactOrganizationWebsite: "",      // Added
        contactOrganizationPurpose: "", 
        contactOrganizationIntro: "", 
        contactOrganizationOutro: "",
        contactOrganizationTargetAudience: [{ audience: "" }],
        contactOrganizationLocations: [{ location: "" }],
        contactOrganizationTeamSize: "",
        contactOrganizationRevenueSize: "",
        contactOrganizationExistenceDuration: "", 
        contactOrganizationStartedDate: "",
        contactOrganizationRecognitions: [{ recognition: "" }],
        contactOrganizationContributions: [{ contribution: "" }],
        aboutMe: "", 
        pic: "", 
        contactOrganizationSponsors: [{ sponsorId: "" }], // 06 Sep 2024 - Added for sponsors
      })
  
       // navigate('/contactOrganizations')
    setActiveAdminView('contactOrganizations'); // 07 Mar 2025
  }
  
  },[isSuccess])// ✅ 07 Mar 2025 - Removed `navigate` since it's no longer used.

    // 7 - Form field updates / change handling
  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData(prevState => ({ ...prevState, pic: files[0] }))
    }  else {
      setFormData(prevState => ({ ...prevState, [name]: value }))
    }
  }

  // 7a - Generic Handle Add Field Function
  const handleAdd = (fieldName, newItem) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: [...prevState[fieldName], newItem]
    }))
  }

  // 7b - Generic Handle Remove Field Function
  const handleRemove = (fieldName, index) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: prevState[fieldName].filter((_, i) => i !== index)
    }))
  }

  // 7c - Generic Handle Field Change Function
  const handleFieldChange = (fieldName, index, value) => {
    const updatedItems = formData[fieldName].map((item, i) => i === index ? value : item)
    setFormData({ ...formData, [fieldName]: updatedItems })
  }

  // 7d - Wrapper functions for specific fields
  const handleAddAudience = () => handleAdd('contactOrganizationTargetAudience', { audience: "" })
  const handleRemoveAudience = (index) => handleRemove('contactOrganizationTargetAudience', index)
  const handleAudienceChange = (index, value) => handleFieldChange('contactOrganizationTargetAudience', index, { audience: value })

  const handleAddLocations = () => handleAdd('contactOrganizationLocations', { location: "" })
  const handleRemoveLocations = (index) => handleRemove('contactOrganizationLocations', index)
  const handleLocationsChange = (index, value) => handleFieldChange('contactOrganizationLocations', index, { location: value })
 
  const handleAddRecognitions = () => handleAdd('contactOrganizationRecognitions', { recognition: "" })
  const handleRemoveRecognitions = (index) => handleRemove('contactOrganizationRecognitions', index)
  const handleRecognitionsChange = (index, value) => handleFieldChange('contactOrganizationRecognitions', index, { recognition: value })

  // 06 Sep 2024 - Add, Remove, Update a sponsor
  const handleAddSponsor = () => handleAdd('contactOrganizationSponsors', { sponsorId: "" });
  const handleRemoveSponsor = (index) => handleRemove('contactOrganizationSponsors', index);
  const handleSponsorChange = (index, value) => handleFieldChange('contactOrganizationSponsors', index, { sponsorId: value });


  // 8 - Form Submit Logic
const handleSubmit = async (e) => {
  e.preventDefault()

  // Initialize an object to collect the form data
  const contactOrganizationData = {
    trailId: formData.trailId,
    contactOrganizationTypeId: formData.contactOrganizationTypeId,
    contactOrganizationName: formData.contactOrganizationName,
    contactOrganizationDesc: formData.contactOrganizationDesc,
    contactOrganizationEmail: formData.contactOrganizationEmail,        // Added
    contactOrganizationPhone: formData.contactOrganizationPhone,        // Added
    contactOrganizationWebsite: formData.contactOrganizationWebsite,    // Added
    contactOrganizationIntro: formData.contactOrganizationIntro,
    contactOrganizationOutro: formData.contactOrganizationOutro,
    contactOrganizationTargetAudience: formData.contactOrganizationTargetAudience,
    contactOrganizationLocations: formData.contactOrganizationLocations,
    contactOrganizationRecognitions: formData.contactOrganizationRecognitions,
    contactOrganizationStartedDate: formData.contactOrganizationStartedDate,
    availableToUserOrgs: [{ userOrgId: passedUserOrgId }],
    aboutMe: formData.aboutMe,
    contactOrganizationSponsors: formData.contactOrganizationSponsors, // 06 Sep 2024 - Added for sponsors
  }

  //console.log('contactOrganization Name:',contactOrganizationData.contactOrganizationName)
  //console.log('contactOrganization Planned Date Slot:',contactOrganizationData.contactOrganizationStartedDate)

  if (formData.pic) {
    // Convert the image to a base64 string
    const reader = new FileReader()
    reader.onloadend = async () => {
      // Once conversion is done, add the base64 string to your data object
      contactOrganizationData.pic = reader.result

      // Now, send the data object to the server
      try {
        contactOrganizationData.id = id
        console.log("Form submitted.")
        await updateContactOrganization(contactOrganizationData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    }
    reader.readAsDataURL(formData.pic)
  } else {
    // No image, send other data as is
    try {
      contactOrganizationData.id = id
      console.log("Form submitted.")
      await updateContactOrganization(contactOrganizationData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
  }
}

  // 9 - Handle Cancel
  const handleCancel = () => {
    // navigate('/contactOrganizations')
    setActiveAdminView('contactOrganizations'); // 07 Mar 2025
  }

// 10 - Handle Clear
const handleClear = () => {
  setFormData({
      contactOrganizationTypeId: "", 
      contactOrganizationName: "",
      contactOrganizationDesc: "",
      contactOrganizationPurpose: "", 
      contactOrganizationIntro: "", 
      contactOrganizationOutro: "",
      contactOrganizationTargetAudience: [{ audience: "" }],
      contactOrganizationLocations: [{ location: "" }],
      contactOrganizationTeamSize: "",
      contactOrganizationRevenueSize: "",
      contactOrganizationExistenceDuration: "", 
      contactOrganizationStartedDate: "",
      contactOrganizationRecognitions: [{ recognition: "" }],
      contactOrganizationContributions: [{ contribution: "" }],
      aboutMe: "", 
      pic: "", 
      contactOrganizationSponsors: [{ sponsorId: "" }], // 06 Sep 2024 - Added for sponsors
  })
}


return (
  <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
    {isLoading && <PulseLoader color={"#ea580c"} />}
    {isError && (
      <p className="text-orange-700 font-semi-bold py-2">
        {error?.data?.message}
      </p>
    )}
    <div className="bg-white p-1 m-1 rounded">
      <p className="p-1 m-1 font-bold font-poppins text-xl">Update a Contact Organization</p>

      <div className="bg-gray-200 rounded m-1 p-1">
        <form
          onSubmit={handleSubmit}
          className="m-1 p-1 text-xs font-poppins"
        >
          <div id="contactOrganizationTypeSelector">
            <select
              name="contactOrganizationTypeId"
              id="contactOrganizationTypeId"
              value={formData.contactOrganizationTypeId}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            >
              {contactOrganizationTypeOptions}
            </select>
          </div>

          <div id="contactOrganizationName">
            <input
              type="text"
              name="contactOrganizationName"
              placeholder="Enter the contact Organization name here"
              value={formData.contactOrganizationName}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="contactOrganizationDesc">
            <input
              type="text"
              name="contactOrganizationDesc"
              placeholder="Enter the contact Organization description here"
              value={formData.contactOrganizationDesc}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          {/* 28 Sep 2024 Add input fields for email, phone, website, and available orgs */}

          <div id="contactOrganizationEmail">
              <input
                type="email"
                name="contactOrganizationEmail"
                placeholder="Enter the contact organization email here"
                value={formData.contactOrganizationEmail}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="contactOrganizationPhone">
              <input
                type="tel"
                name="contactOrganizationPhone"
                placeholder="Enter the contact organization phone number here"
                value={formData.contactOrganizationPhone}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="contactOrganizationWebsite">
              <input
                type="url"
                name="contactOrganizationWebsite"
                placeholder="Enter the contact organization website here"
                value={formData.contactOrganizationWebsite}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

          {/* <div id="contactOrganizationStartedDate">
            <input
              type="text"
              name="contactOrganizationStartedDate"
              placeholder="Enter the date when the contactOrganization started"
              value={formData.contactOrganizationStartedDate}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div className="bg-white w-full rounded p-2 m-1">
            <EtContentEditorLightWithPlace
              value={formData.contactOrganizationIntro}
              handleChange={(content) =>
                setFormData({ ...formData, contactOrganizationIntro: content })
              }
              placeholderText={
                "Write what people would see when this contactOrganization starts!"
              }
              // toolbarId={'contactOrganizationIntro'}
            />
          </div>

          <div className="bg-white w-full rounded p-2 m-1">
            <EtContentEditorLight3
              value={formData.contactOrganizationOutro}
              handleChange={(content) =>
                setFormData({ ...formData, contactOrganizationOutro: content })
              }
              placeholderText={
                "Write what people would see when this contactOrganization ends!"
              }
              toolbarId={"contactOrganizationOurtro"}
            />
          </div>

          <div id="aboutMe">
            <input
              type="text"
              name="aboutMe"
              placeholder="Add something about the personality of this contactOrganization"
              value={formData.aboutMe}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="pic">
            <input
              type="file"
              name="pic"
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div> */}

          {/* ContactOrganization Sponsors - Added - 06 Sep 2024*/}
          {/* <div id="sponsors">
            <div className="m-1 p-1 border border-white rounded">
              <div>
                <p className="font-bold mx-2 px-2 mt-2">ContactOrganization Sponsors</p>
                <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">
                  Add sponsors for this contactOrganization
                </p>
              </div>

              {formData.contactOrganizationSponsors.map((item, index) => (
                <div key={index} className="flex justify-start items-center mx-1">
                  <select
                    value={item.sponsorId}
                    onChange={(e) => handleSponsorChange(index, e.target.value)}
                    className="w-full rounded p-2 m-1 text-xs"
                  >
                    <option value="">Select a sponsor</option>
                    {eligibleSponsors.map(sponsor => (
                      <option key={sponsor._id} value={sponsor._id}>
                        {sponsor.name} ({sponsor.userOrgRole?.orgRoleName} & {sponsor.userOrgTitle?.orgTitleName})
                      </option>
                    ))}
                  </select>
                  <div className="rounded bg-red-500 m-1 p-1">
                    <button type="button" onClick={() => handleRemoveSponsor(index)}>
                      <MinusIcon className="h-5 w-5 text-white" />
                    </button>
                  </div>
                </div>
              ))}
              <div className="flex justify-start items-center m-1">
                <p className="font-bold text-xs m-1 p-1">Add Sponsor</p>
                <button type="button" onClick={handleAddSponsor}>
                  <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                </button>
              </div>
            </div>
          </div> */}


          {/* Target Audience List */}
          {/* <div id="target-audience">

              <div className="m-1 p-1 border border-white rounded">
                  <div>
                    <p className="font-bold mx-2 px-2 mt-2">Target Audience</p>
                    <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">Who this contactOrganization is for?</p>
                  </div>

                    {formData.contactOrganizationTargetAudience.map((item, index) => (
                      <div key={index} className="flex justify-start items-center mx-1">
                        <input
                          type="text"
                          value={item.audience}
                          onChange={(e) => handleAudienceChange(index, e.target.value)}
                          placeholder="Enter a target audience"
                          className="w-full rounded p-2 m-1 text-xs"
                        />
                        <div className="rounded bg-red-500 m-1 p-1">
                          <button type="button" onClick={() => handleRemoveAudience(index)}>
                            <MinusIcon className="h-5 w-5 text-white" />
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="flex justify-start items-center m-1">

                        <p className="font-bold text-xs m-1 p-1">Add</p>
                        <button type="button" onClick={handleAddAudience}>
                          <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                        </button>
                    
                    </div>
              </div>
         
          </div> */}

          {/* ContactOrganization Locations */}
          {/* <div id="pre-work">

              <div className="m-1 p-1 border border-white rounded">
                  <div>
                    <p className="font-bold mx-2 px-2 mt-2">ContactOrganization Locations</p>
                    <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">What are the locations for this contactOrganization?</p>
                  </div>

                    {formData.contactOrganizationLocations.map((item, index) => (
                      <div key={index} className="flex justify-start items-center mx-1">
                        <input
                          type="text"
                          value={item.location}
                          onChange={(e) => handleLocationsChange(index, e.target.value)}
                          placeholder="Enter a pre work for the contactOrganization"
                          className="w-full rounded p-2 m-1 text-xs"
                        />
                        <div className="rounded bg-red-500 m-1 p-1">
                          <button type="button" onClick={() => handleRemoveLocations(index)}>
                            <MinusIcon className="h-5 w-5 text-white" />
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="flex justify-start items-center m-1">

                        <p className="font-bold text-xs m-1 p-1">Add</p>
                        <button type="button" onClick={handleAddLocations}>
                          <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                        </button>
                    
                    </div>
              </div>
         
          </div> */}
          
          {/* ContactOrganization Recognitions */}
          {/* <div id="recognitions">

              <div className="m-1 p-1 border border-white rounded">
                  <div>
                    <p className="font-bold mx-2 px-2 mt-2">ContactOrganization Recognitions</p>
                    <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">What one can expect to achieve out of the contactOrganization?</p>
                  </div>

                    {formData.contactOrganizationRecognitions.map((item, index) => (
                      <div key={index} className="flex justify-start items-center mx-1">
                        <input
                          type="text"
                          value={item.recognition}
                          onChange={(e) => handleRecognitionsChange(index, e.target.value)}
                          placeholder="Enter an recognition for the contactOrganization"
                          className="w-full rounded p-2 m-1 text-xs"
                        />
                        <div className="rounded bg-red-500 m-1 p-1">
                          <button type="button" onClick={() => handleRemoveRecognitions(index)}>
                            <MinusIcon className="h-5 w-5 text-white" />
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="flex justify-start items-center m-1">

                        <p className="font-bold text-xs m-1 p-1">Add</p>
                        <button type="button" onClick={handleAddRecognitions}>
                          <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                        </button>
                    
                    </div>
              </div>
         
          </div> */}

          <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic
              name={"Update"}
              type={"create"}
              handleClick={handleSubmit}
            />
            <EtBtnFormBasic
              name={"Clear"}
              type={"clear"}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={"Cancel"}
              type={"cancel"}
              handleClick={handleCancel}
            />
          </div>
        </form>
      </div>
    </div>
  </div>
)

}

export default EtUpdateContactOrganization