import React, { useState, useEffect } from "react"
import useTitle from "../../hooks/useTitle"
import { useNavigate } from "react-router"
import {
  PlusIcon,
  MinusIcon,
  TrashIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline"
import {
  PlusCircleIcon,
  CalendarDaysIcon,
  ArrowUpCircleIcon,
  ClockIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/solid"
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined"
import PulseLoader from "react-spinners/PulseLoader"
import { formatDate, formatShortDate, formatDateTime } from "../../utils/formatDate"
import { getTimeSince } from "../../utils/EtGetTimeSince"
import { differenceInDays } from 'date-fns' // Added 29 Jun 2024
import {
  useGetUserActionsQuery,
  useUpdateUserActionMutation,
} from "./userActionsApiSlice" // 09 Jun 2024 - update mutation

const EtUserActionListByUserComp = ({userId}) => {
  // First Created - 23 Sep 2024
  // Author - Atul Pandey
  // Inherited from EtUserActionList
  // Updated - 22 Jan 2025 - set the progress trail to be in descending order

  // 0 - Set Page Title
  useTitle("User Actions List By User")

  // 1 - set page navigations
  const navigate = useNavigate()

  // 2 - Initialize showDetails state as an object where key is the session index
  const [showDetails, setShowDetails] = useState({})
  const [showTrails, setShowTrails] = useState({})
  const [actionNotes, setActionNotes] = useState({}) // 09 Jun 2024
  const [actionProgress, setActionProgress] = useState({}) // 09 Jun 2024

  // 3 - Extract the user actions data - refined to fetch data based on the userId
  const {
    data: userActionsData,
    isError,
    error,
    isLoading,
  } = useGetUserActionsQuery(undefined, {
    selectFromResult: ({ data, ...otherProps }) => ({
      data: {
        ...data,
        ids: data?.ids.filter((id) => data?.entities[id]?.userId?._id === userId),
      },
      ...otherProps,
    }),
  }) 
//   console.log("userActionsData", userActionsData)

  // 3a - Mutation for updating user action -- 09 Jun 2024
  const [updateUserAction] = useUpdateUserActionMutation()

  // 3b - Load state from localStorage -- 11 Jun 2024 -- updated -- 12 Jun 2024 -- showUserDetails
  useEffect(() => {
    const storedShowDetails = localStorage.getItem("showDetails")
    const storedShowTrails = localStorage.getItem("showTrails")
    if (storedShowDetails) {
      setShowDetails(JSON.parse(storedShowDetails))
    }
    if (storedShowTrails) {
      setShowTrails(JSON.parse(storedShowTrails))
    }
  }, [])

  // 3c Save state to localStorage before page reload -- 11 Jun 2024 -- updated 12 Jun 2024 -- showUserDetails
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("showDetails", JSON.stringify(showDetails))
      localStorage.setItem("showTrails", JSON.stringify(showTrails))
    }

    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [showDetails, showTrails])

  // 4 - Toggle Show function for userAction details
  const handleToggleDetails = (actionId) => {
    setShowDetails((prev) => {
      const newShowDetails = { ...prev, [actionId]: !prev[actionId] }

      // Update the value attribute when details are shown
      if (!prev[actionId]) {
        let action = null

        for (const trail of Object.values(trails)) {
          for (const session of Object.values(trail.sessions)) {
              action = session.userActions.find((a) => a.id === actionId)
              if (action) break
          }
          if (action) break
        }

        const slider = document.getElementById(`slider-${actionId}`)
        if (slider) {
          const initialValue =
            actionProgress[actionId] || action?.userActionProgress || 0
          slider.value = initialValue // Set the value attribute
        }
      }
      return newShowDetails
    })
  }

  // 4a - Toggle Show function for trails
  const handleToggleTrails = (trailId) => {
    setShowTrails((prev) => ({ ...prev, [trailId]: !prev[trailId] }))
  }

  // 5 - Create an object for setting inner HTML
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
  }

  // 6a - Calculate total number of actions for each trail -- 12 Jun 2024
  const calculateTotalActions = (trail) => {
    return Object.values(trail.sessions).reduce((total, session) => {
      return total + session.userActions.length
    }, 0)
  }

  // 6b - Grouping sessions by trail -- updated 12 Jun 2024 to sub group by user id and sessions
  const trails = {}
  userActionsData?.ids?.forEach((id) => {
    const userAction = userActionsData?.entities[id]
    const {
      trailId,
      sessionId,
      actionTypeId,
      userActionName,
      userActionDesc,
      userActionNumber,
      userActionNotes,
      userActionStatus,
      userActionProgress,
      pic,
      completeBy,
      actionOwner,
    } = userAction

    if (!trails[trailId._id]) {
      trails[trailId._id] = {
        trailId: trailId._id,
        trailName: trailId.trailName,
        trailPic: trailId.pic,
        trailDesc: trailId.trailDesc,
        // users: {},
        sessions: {},
      }
    }

    if (!trails[trailId._id].sessions[sessionId._id]) {
      trails[trailId._id].sessions[sessionId._id] = {
        sessionId: sessionId._id,
        sessionName: sessionId.sessionName,
        sessionPic: sessionId.pic,
        userActions: [],
      }
    }
    
    trails[trailId._id].sessions[
      sessionId._id
    ].userActions.push({
      id,
      userActionName,
      userActionDesc,
      userActionNumber,
      userActionNotes,
      userActionTypeId: actionTypeId._id,
      userActionTypeName: actionTypeId.actionTypeName,
      userActionSession: sessionId.sessionName,
      userActionOwner: actionOwner,
      pic,
      userActionStatus,
      userActionProgress,
      completeBy,
    })
    trails[trailId._id].totalActions = calculateTotalActions(trails[trailId._id]) // Added
  })

  //console.log("trails", trails)

  // 7 - Handle Note Change -- 09 Jun 2024
  const handleNoteChange = (e, actionId) => {
    const { value } = e.target
    setActionNotes((prevNotes) => ({ ...prevNotes, [actionId]: value }))

    // 18 Sep 2024 - Set the actionProgress to the current userActionProgress if not already set - 06 Oct 2024 - refined
    setActionProgress((prevProgress) => {
      if (prevProgress[actionId] === undefined) {

          // const action = userActionsData?.find((a) => a.id === actionId);
          // return { ...prevProgress, [actionId]: action?.userActionProgress || 0 };

          let action = null;

        // Use the correct logic to find the action within the nested structure
        Object.values(trails).forEach((trail) => {
          Object.values(trail.sessions).forEach((session) => {
            session.userActions.forEach((userAction) => {
              if (userAction.id === actionId) {
                action = userAction;
              }
            });
          });
        });

        return { ...prevProgress, [actionId]: action?.userActionProgress || 0 };
      }

      return prevProgress;
    });
  }

  // 8 - Handle Progress Change -- 09 Jun 2024
  const handleProgressChange = (e, actionId) => {
    const { value } = e.target
    setActionProgress((prevProgress) => ({
      ...prevProgress,
      [actionId]: value,
    }))
    e.target.style.setProperty("--value", value)
  }

  // 8a - UseEffect for slider -- 10 Jun 2024 -- updated -- 12 Jun 2024 -- for user and session groups
  useEffect(() => {
    // Iterate through all actions to set initial styles
    Object.values(trails).forEach((trail) => {
      Object.values(trail.sessions).forEach((session) => {
          session.userActions.forEach((action) => {
            const slider = document.getElementById(`slider-${action.id}`)
            if (slider) {
              const initialValue =
                actionProgress[action.id] || action.userActionProgress || 0
              slider.style.setProperty("--value", initialValue)
              slider.style.setProperty("--min", 0)
              slider.style.setProperty("--max", 100)
            }
          })
      })
    })
  }, [trails, actionProgress]) // Dependencies updated

  // 9 - Save function to update user action
  const handleSave = async (
    userId,
    trailId,
    sessionId,
    actionTypeId,
    actionId,
    userActionName,
    userActionDesc,
    completeBy
  ) => {
    const actionNoteText = actionNotes[actionId]
    const actionNoteDate = new Date()
    const userActionProgress = actionProgress[actionId]

    // Check if the action note text is not empty
    if (!actionNoteText) {
      alert("Action note cannot be empty")
      return
    }

    try {
      const updatedAction = {
        id: actionId,
        userId: userId,
        trailId: trailId,
        sessionId: sessionId,
        actionTypeId: actionTypeId,
        userActionName: userActionName,
        userActionDesc: userActionDesc,
        completeBy: completeBy,
        userActionNotes: [
          {
            actionNoteText,
            actionNoteDate,
            actionNoteProgress: userActionProgress,
          },
        ],
        userActionProgress,
      }
      console.log("updatedActionNote:", updatedAction)

      await updateUserAction(updatedAction).unwrap()
      alert("Action updated successfully")
      window.location.reload()
    } catch (error) {
      console.error("Failed to update action:", error)
      alert("Failed to update action")
    }
  }

  // 10a - bgColor for action progress status -- 29 Jun 2024
  const getBgColor = (action) => {
    const today = new Date() // 29 Jun 2024
    const completeByDate = new Date(action?.completeBy); // Assuming it's already a Date object
    
    const daysDifference = differenceInDays(completeByDate, today);

    let bgColor = 'bg-lime-400'; // default color

    if (daysDifference < 0) {
      bgColor = 'bg-red-600 text-white';
    } else if (daysDifference < 1) {
      bgColor = 'bg-orange-400 text-white';
    } else if (daysDifference < 3) {
      bgColor = 'bg-yellow-400';
    } // default bgColor 'bg-lime-400' will be used if daysDifference > 3
    return bgColor
  }

  // 10b - Icon color for action progress icon  -- 29 Jun 2024
  const getIconClr = (action) => {
    const today = new Date(); // Current date
    const completeByDate = new Date(action?.completeBy); // Convert the string to a Date object
  
    if (isNaN(completeByDate)) {
      console.error('Invalid date:', action?.completeBy);
      return '#84cc16'; // Return the default color if the date is invalid
    }
  
    const daysDifference = differenceInDays(completeByDate, today);
    //console.log('Difference in days', daysDifference);
  
    let color = '#84cc16'; // default color
  
    if (daysDifference < 0) {
      color = '#dc2626';
    } else if (daysDifference < 1) {
      color = '#f97316';
    } else if (daysDifference < 3) {
      color = '#facc15';
    } // default color '#84cc16' will be used if daysDifference > 3
  
    return color;
  };

  // 10c - get the difference in days -- 29 Jun 2024
  const getDaysDifference = (action) => {
    const today = new Date(); // Current date
    const completeByDate = new Date(action?.completeBy); // Convert the string to a Date object
    const daysDifference = differenceInDays(completeByDate, today);
    return daysDifference
  }

  // 11 - Optional: handle loading and error states
  if (isLoading) return <PulseLoader color={"#ea580c"} />
  if (isError) return <div>Error occurred: {error?.data?.message}</div>

  return (
    <div className="">
      
      {Object.values(trails).map(
        (
          trail,
          trailIndex // Modified
        ) => (
          <div key={trailIndex} className="">
            <div className="bg-gray-300 m-1 p-1 rounded">
              {/* Trail Header */}
              <div className="">
                {/* Left Side */}
                <div className="grid grid-cols-12 gap-1 justify-start mx-5 items-center">
                  <div
                    onClick={() => handleToggleTrails(trailIndex)}
                    className="col-span-4 cursor-pointer m-1 p-1 flex justify-start items-center"
                  >
                    <div className="mx-2 rounded-full h-7 w-7 hover:bg-white flex justify-center items-center ">
                      {showTrails[trailIndex] ? (
                        <MinusIcon className="h-5 w-5 text-orange-600" />
                      ) : (
                        <PlusIcon className="h-5 w-5 text-orange-600" />
                      )}
                    </div>
                    <div className="mx-2 flex justify-center items-center rounded-full bg-gray-100 hover:bg-white h-8 w-8">
                      <img src={trail?.trailPic} alt="" className="h-6 w-6" />
                    </div>
                    <div className="w-48">
                      <p className="font-bold text-lg">{trail?.trailName}</p>
                      <p
                        className="text-[9px]"
                        dangerouslySetInnerHTML={createMarkup(trail?.trailDesc)}
                      ></p>
                    </div>
                  </div>

                  <div className="col-span-2 mx-2 flex justify-start items-center">
                    <div>
                      <p className="text-[10px] text-gray-400 mx-2 px-2">
                        Actions
                      </p>
                    </div>
                    <div className="rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50">
                      <p className="text-orange-600 text-[10px]">
                        {trail?.totalActions}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
              {showTrails[trailIndex] && ( // Modified
                <div className="bg-white m-1 p-1 rounded">
                  {Object.values(trail?.sessions)?.map((session, sessionIndex) => (                       
                    <div key={`${session.sessionId}-${sessionIndex}`} className="m-1 p-1 bg-gray-200 rounded">
                    <div className="flex justify-start items-center m-1 p-1">
                        <div className="flex justify-center items-center rounded-full bg-gray-300 hover:bg-white h-7 w-7 m-1 p-1">
                        <img
                            src={session.sessionPic}
                            alt=""
                            className="h-5 w-5"
                        />
                        </div>
                        <p className="text-md font-bold text-gray-600 mx-2">
                        Session -{" "}
                        </p>
                        <p className="text-md font-bold mx-2">
                        {session.sessionName}
                        </p>
                    </div>
                    {session?.userActions?.length === 0 ? ( // Modified
                        <p className="text-center text-gray-500 text-sm">
                        There are no user actions added to this
                        session yet
                        </p>
                    ) : (
                        session?.userActions?.map(
                        (
                            action,
                            index // Modified
                        ) => (
                            <div
                            key={action.id + index}
                            className="bg-gray-100 rounded m-1 p-1"
                            >
                            <div className="grid grid-cols-12 justify-between items-center gap-2">
                                <div className="col-span-5 flex justify-start items-center mx-2">
                                <div
                                    onClick={() =>
                                    handleToggleDetails(action.id)
                                    }
                                    className="mx-1 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                >
                                    {showDetails[action.id] ? (
                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                </div>

                                <div className="m-1 p-1 ">
                                    <div className="flex justify-start items-center">
                                    <p className="text-2xl font-bold mx-1 text-gray-400">
                                        {index + 1}.
                                    </p>
                                    <p className="text-[10px] mx-1">
                                        {action?.userActionName}
                                    </p>
                                    </div>

                                    <p className="text-[10px] m-1">
                                    {action?.userActionDesc}
                                    </p>
                                </div>
                                </div>

                                <div className="col-span-2">
                                {/* 11 Jun 2024 - added code to display progress data based on threshold for better visibility */}
                                {action?.userActionProgress < 10 ? (
                                    <div className="rounded-full bg-gray-300 relative">
                                    <div
                                        className="rounded-full bg-[#552a9a]"
                                        style={{
                                        width: `${action?.userActionProgress}%`,
                                        height: "26%",
                                        }}
                                    ></div>
                                    <p className="text-[8px] text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                        {action?.userActionProgress}%
                                    </p>
                                    </div>
                                ) : (
                                    <div className="rounded-full bg-gray-300">
                                    <div className="flex justify-between items-center">
                                        <div
                                        className="rounded-full bg-[#552a9a] flex justify-center items-center"
                                        style={{
                                            width: `${action?.userActionProgress}%`,
                                        }}
                                        >
                                        <p className="text-[8px] text-white">
                                            {
                                            action?.userActionProgress
                                            }
                                            %
                                        </p>
                                        </div>
                                    </div>
                                    </div>
                                )}
                                </div>

                                <div className="col-span-2 flex justify-center items-center mx-2">
                                {action?.userActionStatus ===
                                "Completed" ? (
                                    <CheckCircleIcon className="h-4 w-4 text-lime-600" />
                                ) : (
                                    <ExploreOutlinedIcon
                                    fontSize="small"
                                    sx={{
                                        color:
                                        action?.userActionStatus !==
                                        "Planned"
                                            ? `${getIconClr(action)}`
                                            : (action?.userActionStatus ===
                                        "Planned" && getDaysDifference(action)<3 )? `${getIconClr(action)}` :"#d1d5db",
                                    }}
                                    />
                                )}
                                <p
                                    className={`text-[9px] mx-1 ${
                                    action?.userActionStatus ===
                                    "Completed"
                                        ? "bg-lime-600 text-white rounded-full px-2"
                                        : action?.userActionStatus !==
                                        "Planned"
                                        ? `${getBgColor(action)} rounded-full px-2`
                                        : (action?.userActionStatus ===
                                        "Planned" && getDaysDifference(action)<3 )? `${getBgColor(action)} rounded-full px-2` :"text-gray-600"
                                    }`}
                                >
                                    {action?.userActionStatus}
                                </p>
                                </div>

                                <div className="col-span-3 flex justify-end items-center mx-1">
                                <p className="text-gray-600 text-[9px]">
                                    Complete by:
                                </p>
                                <CalendarDaysIcon className="m-2 h-5 w-5 text-orange-600" />
                                <p className="flex justify-center items-center text-[9px] rounded-full px-2 py-[1px] bg-white border text-gray-600 border-orange-400">
                                    {formatShortDate(action?.completeBy)}
                                </p>
                                <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white mx-2">
                                    <PencilSquareIcon
                                    onClick={() =>
                                        navigate(
                                        `/userActions/update/${action?.id}`
                                        )
                                    }
                                    className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                    />
                                </div>
                                </div>
                            </div>
                            {/* User Action Notes Section */}

                            {showDetails[action.id] && (
                                <div>
                                {/* Show existing action notes */}
                                <div className="bg-gray-300 m-1 p-1 rounded">
                                    <p className="font-bold m-1 p-1 text-xs">
                                    Action Progress Trail:
                                    </p>
                                    {action?.userActionNotes &&
                                    action?.userActionNotes
                                    ?.slice() // 22 Jan 2025
                                    ?.sort(
                                        (a, b) =>
                                            new Date(b.actionNoteDate) -
                                            new Date(a.actionNoteDate)
                                    )
                                    ?.map(
                                        (userNote, noteIndex) => (
                                        <div
                                            key={
                                            userNote._id + noteIndex
                                            }
                                            className="bg-gray-50 rounded m-1 p-1"
                                        >
                                            <div className="grid grid-cols-10 justify-between items-center m-1 p-1 w-full">
                                            <div className="col-span-5 flex justify-start items-center">
                                                <div>
                                                <p className="font-bold text-[9px] mx-1 py-[1px]">
                                                    {noteIndex + 1}.
                                                </p>
                                                </div>
                                                <div>
                                                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-18">
                                                    {getTimeSince(
                                                    userNote.actionNoteDate
                                                    )}
                                                </p>
                                                </div>
                                                <div>
                                                <p className="text-[9px] mx-1 py-[1px]">
                                                    {
                                                    userNote?.actionNoteText
                                                    }
                                                </p>
                                                </div>
                                            </div>

                                            <div className="col-span-1">
                                                <p className="text-[9px] text-gray-600">
                                                Action Progress:
                                                </p>
                                            </div>

                                            {/* 11 Jun 2024 - action progress bar for each note */}

                                            <div className="col-span-2 gap-4">
                                                {userNote?.actionNoteProgress <
                                                10 ? (
                                                <div className="rounded-full bg-gray-300 relative">
                                                    <div
                                                    className="rounded-full bg-[#552a9a]"
                                                    style={{
                                                        width: `${userNote?.actionNoteProgress}%`,
                                                        height: "70%",
                                                    }}
                                                    ></div>
                                                    <p className="text-[8px] text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                    {
                                                        userNote?.actionNoteProgress
                                                    }
                                                    %
                                                    </p>
                                                </div>
                                                ) : (
                                                <div className="rounded-full bg-gray-300">
                                                    <div className="flex justify-between items-center">
                                                    <div
                                                        className="rounded-full bg-[#552a9a] flex justify-center items-center"
                                                        style={{
                                                        width: `${userNote?.actionNoteProgress}%`,
                                                        }}
                                                    >
                                                        <p className="text-[8px] text-white">
                                                        {
                                                            userNote?.actionNoteProgress
                                                        }
                                                        %
                                                        </p>
                                                    </div>
                                                    </div>
                                                </div>
                                                )}
                                            </div>

                                            <div className="col-span-2 flex justify-start items-center">
                                                <div className="mx-2 px-2 w-full">
                                                <p className="text-[9px] text-gray-400 mx-1 rounded-full px-2">
                                                    {formatDateTime(
                                                    userNote.actionNoteDate
                                                    )}
                                                </p>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        )
                                    )}

                                    {/* Area for new note */}
                                    {/* 11 June - added logic for hiding action note addition for completed actions */}
                                    {action?.userActionProgress <
                                    100 && (
                                    <div className="bg-white m-1 py-1 px-2 rounded">
                                        <div className="m-1 p-1 bg-gray-100 rounded">
                                        <div className="grid grid-cols-10 justify-between items-start">
                                            <div className="col-span-6 m-1 p-1">
                                            <div className="flex justify-start items-center">
                                                <PlusCircleIcon className="m-2 h-5 w-5 text-orange-600" />
                                                <p className="font-bold text-xs">
                                                Add Action Note
                                                </p>
                                            </div>
                                            <textarea
                                                className="w-full rounded p-2 m-1 text-[10px] border border-gray-200"
                                                placeholder="Add your action notes here"
                                                value={
                                                actionNotes[
                                                    action.id
                                                ] || ""
                                                }
                                                onChange={(e) =>
                                                handleNoteChange(
                                                    e,
                                                    action.id
                                                )
                                                }
                                            />
                                            </div>

                                            <div className="col-span-4 m-1 p-1">
                                            <div className="flex justify-start items-center">
                                                <ArrowUpCircleIcon className="m-2 h-5 w-5 text-orange-600" />
                                                <p className="font-bold text-xs">
                                                Update Action
                                                Progress
                                                </p>
                                            </div>
                                            <div className="flex justify-start items-center">
                                                <input
                                                type="range"
                                                id={`slider-${action.id}`}
                                                min="0"
                                                max="100"
                                                className="w-full rounded m-1 h-1"
                                                value={
                                                    actionProgress[
                                                    action.id
                                                    ] ||
                                                    action?.userActionProgress
                                                }
                                                onChange={(e) =>
                                                    handleProgressChange(
                                                    e,
                                                    action.id
                                                    )
                                                }
                                                />
                                                <input
                                                type="number"
                                                className="w-12 rounded p-2 m-1 text-center text-[10px] border border-gray-200"
                                                placeholder="Action Progress (%)"
                                                value={
                                                    actionProgress[
                                                    action.id
                                                    ] ||
                                                    action?.userActionProgress
                                                }
                                                onChange={(e) =>
                                                    handleProgressChange(
                                                    e,
                                                    action.id
                                                    )
                                                }
                                                />
                                                <p className="text-xs">
                                                %
                                                </p>
                                            </div>
                                            </div>
                                        </div>
                                        </div>

                                        <button
                                        className="bg-orange-600 text-white rounded-full px-4 py-[1px] m-1 text-[10px]"
                                        onClick={() =>
                                            handleSave(
                                            userId,
                                            trail.trailId,
                                            session.sessionId,
                                            action.userActionTypeId,
                                            action.id,
                                            action.userActionName,
                                            action.userActionDesc,
                                            action.completeBy
                                            )
                                        }
                                        >
                                        Save
                                        </button>
                                    </div>
                                    )}
                                </div>
                                </div>
                            )}
                            </div>
                        )
                        )
                    )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )
      )}
    </div>
  )
}

export default EtUserActionListByUserComp
