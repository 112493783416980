import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const orgRolesAdapter = createEntityAdapter({})

const initialState = orgRolesAdapter.getInitialState()

export const orgRolesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOrgRoles: builder.query({
            query: () => ({
                url: '/orgRoles/getOrgRoles',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedOrgRoles = responseData.map(orgRole => {
                    orgRole.id = orgRole._id
                    return orgRole
                })
                return orgRolesAdapter.setAll(initialState, loadedOrgRoles)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'OrgRole', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'OrgRole', id}))
                    ]
                } else {
                    return [ { type: 'OrgRole', id: 'LIST'}]
                }
            },
        }),
        createNewOrgRole: builder.mutation({
            query: initialOrgRole => ({
                url: '/orgRoles/createNewOrgRole',
                method: 'POST',
                body: {...initialOrgRole,}
            }),
            invalidatesTags: [
                { type: 'OrgRole', id: "LIST" }
            ] 
        }),
        updateOrgRole: builder.mutation({
            query: initialOrgRole => ({
                url: '/orgRoles/updateOrgRole',
                method: 'PATCH',
                body: {...initialOrgRole,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'OrgRole', id: arg.id },
                { type: 'OrgRole', id: 'LIST' }
            ],
        }),
        deleteOrgRole: builder.mutation({
            query: ({id}) => ({
                url: '/orgRoles/deleteOrgRole',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'OrgRole', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetOrgRolesQuery,
    useCreateNewOrgRoleMutation,
    useUpdateOrgRoleMutation,
    useDeleteOrgRoleMutation,
} = orgRolesApiSlice

// returns the query result object
export const selectOrgRoleResult = orgRolesApiSlice.endpoints.getOrgRoles.select()

// create memoized selector
const selectOrgRolesData = createSelector(
    selectOrgRoleResult,
    orgRoleResult => orgRoleResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllOrgRoles,
    selectById: selectOrgRoleById,
    selectIds: selectOrgRoleIds,
} = orgRolesAdapter.getSelectors(state => selectOrgRolesData(state) ?? initialState)