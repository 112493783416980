import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userPlanTeamsAdapter = createEntityAdapter({})

const initialState = userPlanTeamsAdapter.getInitialState()

export const userPlanTeamsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserPlanTeams: builder.query({
            query: () => ({
                url: '/userPlanTeams/getUserPlanTeams',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserPlanTeams = responseData.map(userPlanTeam => {
                    userPlanTeam.id = userPlanTeam._id
                    return userPlanTeam
                })
                return userPlanTeamsAdapter.setAll(initialState, loadedUserPlanTeams)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserPlanTeam', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserPlanTeam', id}))
                    ]
                } else {
                    return [ { type: 'UserPlanTeam', id: 'LIST'}]
                }
            },
        }),
        createNewUserPlanTeam: builder.mutation({
            query: initialUserPlanTeam => ({
                url: '/userPlanTeams/createNewUserPlanTeam',
                method: 'POST',
                body: {...initialUserPlanTeam,}
            }),
            invalidatesTags: [
                { type: 'UserPlanTeam', id: "LIST" }
            ] 
        }),
        updateUserPlanTeam: builder.mutation({
            query: initialUserPlanTeam => ({
                url: '/userPlanTeams/updateUserPlanTeam',
                method: 'PATCH',
                body: {...initialUserPlanTeam,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserPlanTeam', id: arg.id },
                { type: 'UserPlanTeam', id: 'LIST' }
            ],
        }),
        deleteUserPlanTeam: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userPlanTeams/deleteUserPlanTeam',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userPlanTeams/deleteUserPlanTeam',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserPlanTeam', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserPlanTeamsQuery,
    useCreateNewUserPlanTeamMutation,
    useUpdateUserPlanTeamMutation,
    useDeleteUserPlanTeamMutation,
} = userPlanTeamsApiSlice

// returns the query result object
export const selectUserPlanTeamResult = userPlanTeamsApiSlice.endpoints.getUserPlanTeams.select()

// create memoized selector
const selectUserPlanTeamsData = createSelector(
    selectUserPlanTeamResult,
    userPlanTeamResult => userPlanTeamResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserPlanTeams,
    selectById: selectUserPlanTeamById,
    selectIds: selectUserPlanTeamIds,
} = userPlanTeamsAdapter.getSelectors(state => selectUserPlanTeamsData(state) ?? initialState)