import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLightWithPlace from "@/utils/EtContentEditorLightWithPlace"
import EtContentEditorLight3 from "@/utils/EtContentEditorLight3"
import { PlusIcon, MinusIcon} from '@heroicons/react/24/outline'
import { useGetContactOrganizationTypesQuery } from "../contactOrganizationTypes/contactOrganizationTypesApiSlice"
import { useCreateNewContactOrganizationMutation } from "./contactOrganizationsApiSlice"

const EtCreateContactOrganization = ({setActiveAdminView, userOrgId}) => {
   // First Created - 28 Sep 2024
  // Author - Atul Pandey
  // Updated - 07 Mar 2025 - updated the comp with props - { setActiveAdminView, userOrgId }
  // Updated - 07 Mar 2025 - switched from navigate to setActiveAdminView

  // 0 - Set Page Title
  useTitle('Create New ContactOrganization')
  const passedUserOrgId = userOrgId // 07 Mar 2025
  
  // 1 - set page navigations
  const navigate = useNavigate()

  // 2 - set the mutation to create the new section data
  const[createNewContactOrganization, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewContactOrganizationMutation()

 // 3 - Get contactOrganization types data for options
  const { contactOrganizationTypes } = useGetContactOrganizationTypesQuery('contactOrganizationTypesList', {
    selectFromResult: ( { data }) => ({
      contactOrganizationTypes: data?.ids.map(id => data?.entities[id])
    })
  })

  console.log('contactOrganization types:', contactOrganizationTypes)

  // 4 - Create options to select a contactOrganization type from list of contactOrganization types
  const contactOrganizationTypeOptions = contactOrganizationTypes ? [
  <option key="placeholder" value="" disabled selected>
    Select a Contact Organization Type
  </option>,
  ...contactOrganizationTypes?.map(contactOrganizationType => (
    <option key={contactOrganizationType.id} value={contactOrganizationType.id}>
      {contactOrganizationType.contactOrganizationTypeName}
    </option>
  ))
  ] : []

  // 7 - create the form data object
  const [formData, setFormData] = useState({
    contactOrganizationTypeId: "", 
    contactOrganizationName: "",
    contactOrganizationDesc: "",
    contactOrganizationEmail: "",        // Added
    contactOrganizationPhone: "",        // Added
    contactOrganizationWebsite: "",      // Added
    contactOrganizationPurpose: "", 
    contactOrganizationIntro: "", 
    contactOrganizationOutro: "",
    contactOrganizationTargetAudience: [{ audience: "" }],
    contactOrganizationLocations: [{ location: "" }],
    contactOrganizationTeamSize: "",
    contactOrganizationRevenueSize: "",
    contactOrganizationExistenceDuration: "", 
    contactOrganizationStartedDate: "",
    contactOrganizationRecognitions: [{ recognition: "" }],
    contactOrganizationContributions: [{ contribution: "" }],
    aboutMe: "", 
    pic: "", 
  })

  // 8 - Reset form upon successful submission
  useEffect(() => {
  if(isSuccess) {
    setFormData({
        contactOrganizationTypeId: "", 
        contactOrganizationName: "",
        contactOrganizationDesc: "",
        contactOrganizationEmail: "",        // Added
        contactOrganizationPhone: "",        // Added
        contactOrganizationWebsite: "",      // Added
        contactOrganizationPurpose: "", 
        contactOrganizationIntro: "", 
        contactOrganizationOutro: "",
        contactOrganizationTargetAudience: [{ audience: "" }],
        contactOrganizationLocations: [{ location: "" }],
        contactOrganizationTeamSize: "",
        contactOrganizationRevenueSize: "",
        contactOrganizationExistenceDuration: "", 
        contactOrganizationStartedDate: "",
        contactOrganizationRecognitions: [{ recognition: "" }],
        contactOrganizationContributions: [{ contribution: "" }],
        aboutMe: "", 
        pic: "", 
    })

    // navigate('/contactOrganizations')
    setActiveAdminView('contactOrganizations'); // 07 Mar 2025
  }
  
  },[isSuccess])// ✅ 07 Mar 2025 - Removed `navigate` since it's no longer used.

  // 9 - Form field updates / change handling
  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData(prevState => ({ ...prevState, pic: files[0] }))
    }  else {
      setFormData(prevState => ({ ...prevState, [name]: value }))
    }
  }

  // 9a - Generic Handle Add Field Function
  const handleAdd = (fieldName, newItem) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: [...prevState[fieldName], newItem]
    }))
  }

  // 9b - Generic Handle Remove Field Function
  const handleRemove = (fieldName, index) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: prevState[fieldName].filter((_, i) => i !== index)
    }))
  }

  // 9c - Generic Handle Field Change Function
  const handleFieldChange = (fieldName, index, value) => {
    const updatedItems = formData[fieldName].map((item, i) => i === index ? value : item)
    setFormData({ ...formData, [fieldName]: updatedItems })
  }

  // 9d - Wrapper functions for specific fields
  const handleAddAudience = () => handleAdd('contactOrganizationTargetAudience', { audience: "" })
  const handleRemoveAudience = (index) => handleRemove('contactOrganizationTargetAudience', index)
  const handleAudienceChange = (index, value) => handleFieldChange('contactOrganizationTargetAudience', index, { audience: value })

  const handleAddLocations = () => handleAdd('contactOrganizationLocations', { location: "" })
  const handleRemoveLocations = (index) => handleRemove('contactOrganizationLocations', index)
  const handleLocationsChange = (index, value) => handleFieldChange('contactOrganizationLocations', index, { location: value })
 
  const handleAddRecognitions = () => handleAdd('contactOrganizationRecognitions', { recognition: "" })
  const handleRemoveRecognitions = (index) => handleRemove('contactOrganizationRecognitions', index)
  const handleRecognitionsChange = (index, value) => handleFieldChange('contactOrganizationRecognitions', index, { recognition: value })

  // 10 - Form Submit Logic
const handleSubmit = async (e) => {
  e.preventDefault()

  // Initialize an object to collect the form data
  const contactOrganizationData = {
    trailId: formData.trailId,
    contactOrganizationTypeId: formData.contactOrganizationTypeId,
    contactOrganizationName: formData.contactOrganizationName,
    contactOrganizationDesc: formData.contactOrganizationDesc,
    contactOrganizationEmail: formData.contactOrganizationEmail,         // Added
    contactOrganizationPhone: formData.contactOrganizationPhone,         // Added
    contactOrganizationWebsite: formData.contactOrganizationWebsite,     // Added
    contactOrganizationIntro: formData.contactOrganizationIntro,
    contactOrganizationOutro: formData.contactOrganizationOutro,
    contactOrganizationTargetAudience: formData.contactOrganizationTargetAudience,
    contactOrganizationLocations: formData.contactOrganizationLocations,
    contactOrganizationRecognitions: formData.contactOrganizationRecognitions,
    contactOrganizationStartedDate: formData.contactOrganizationStartedDate,
    availableToUserOrgs: [{ userOrgId: passedUserOrgId }],
    aboutMe: formData.aboutMe,
  }

  //console.log('contactOrganization Name:',contactOrganizationData.contactOrganizationName)
  //console.log('contactOrganization Planned Date Slot:',contactOrganizationData.contactOrganizationStartedDate)

  if (formData.pic) {
    // Convert the image to a base64 string
    const reader = new FileReader()
    reader.onloadend = async () => {
      // Once conversion is done, add the base64 string to your data object
      contactOrganizationData.pic = reader.result

      // Now, send the data object to the server
      try {
        console.log("Form submitted.")
        await createNewContactOrganization(contactOrganizationData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    }
    reader.readAsDataURL(formData.pic)
  } else {
    // No image, send other data as is
    try {
      console.log("Form submitted.")
      await createNewContactOrganization(contactOrganizationData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
  }
}

  // 11 - Handle Cancel
  const handleCancel = () => {
    // navigate('/contactOrganizations')
    setActiveAdminView('contactOrganizations'); // 07 Mar 2025
  }

// 12 - Handle Clear
  const handleClear = () => {
    setFormData({
        contactOrganizationTypeId: "", 
        contactOrganizationName: "",
        contactOrganizationDesc: "",
        contactOrganizationEmail: "",        // Added
        contactOrganizationPhone: "",        // Added
        contactOrganizationWebsite: "",      // Added
        contactOrganizationPurpose: "", 
        contactOrganizationIntro: "", 
        contactOrganizationOutro: "",
        contactOrganizationTargetAudience: [{ audience: "" }],
        contactOrganizationLocations: [{ location: "" }],
        contactOrganizationTeamSize: "",
        contactOrganizationRevenueSize: "",
        contactOrganizationExistenceDuration: "", 
        contactOrganizationStartedDate: "",
        contactOrganizationRecognitions: [{ recognition: "" }],
        contactOrganizationContributions: [{ contribution: "" }],
        aboutMe: "", 
        pic: "", 
    })
  }


  return (
    <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
      {isLoading && <PulseLoader color={"#ea580c"} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">
          {error?.data?.message}
        </p>
      )}
      <div className="bg-white p-1 m-1 rounded">
        <p className="p-1 m-1 font-bold font-poppins text-xl">Add a Contact Organization</p>

        <div className="bg-gray-200 rounded m-1 p-1">
          <form
            onSubmit={handleSubmit}
            className="m-1 p-1 text-xs font-poppins"
          >
            <div id="contactOrganizationTypeSelector">
              <select
                name="contactOrganizationTypeId"
                id="contactOrganizationTypeId"
                value={formData.contactOrganizationTypeId}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              >
                {contactOrganizationTypeOptions}
              </select>
            </div>

            <div id="contactOrganizationName">
              <input
                type="text"
                name="contactOrganizationName"
                placeholder="Enter the contact Organization name here"
                value={formData.contactOrganizationName}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="contactOrganizationDesc">
              <input
                type="text"
                name="contactOrganizationDesc"
                placeholder="Enter the contact Organization description here"
                value={formData.contactOrganizationDesc}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            {/* 28 Sep 2024 Add input fields for email, phone, website, and available orgs */}

            <div id="contactOrganizationEmail">
              <input
                type="email"
                name="contactOrganizationEmail"
                placeholder="Enter the contact organization email here"
                value={formData.contactOrganizationEmail}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="contactOrganizationPhone">
              <input
                type="tel"
                name="contactOrganizationPhone"
                placeholder="Enter the contact organization phone number here"
                value={formData.contactOrganizationPhone}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="contactOrganizationWebsite">
              <input
                type="url"
                name="contactOrganizationWebsite"
                placeholder="Enter the contact organization website here"
                value={formData.contactOrganizationWebsite}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            {/* <div id="contactOrganizationStartedDate">
              <input
                type="text"
                name="contactOrganizationStartedDate"
                placeholder="Enter the contactOrganization planned Date Slot (i.e Week 2, Month 2) here"
                value={formData.contactOrganizationStartedDate}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div className="bg-white w-full rounded p-2 m-1">
              <EtContentEditorLightWithPlace
                value={formData.contactOrganizationIntro}
                handleChange={(content) =>
                  setFormData({ ...formData, contactOrganizationIntro: content })
                }
                placeholderText={
                  "Write what people would see when this contactOrganization starts!"
                }
                // toolbarId={'contactOrganizationIntro'}
              />
            </div>

            <div className="bg-white w-full rounded p-2 m-1">
              <EtContentEditorLight3
                value={formData.contactOrganizationOutro}
                handleChange={(content) =>
                  setFormData({ ...formData, contactOrganizationOutro: content })
                }
                placeholderText={
                  "Write what people would see when this contactOrganization ends!"
                }
                toolbarId={"contactOrganizationOurtro"}
              />
            </div>

            <div id="aboutMe">
              <input
                type="text"
                name="aboutMe"
                placeholder="Add something about the personality of this contactOrganization"
                value={formData.aboutMe}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="pic">
              <input
                type="file"
                name="pic"
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div> */}

            {/* Target Audience List */}
            {/* <div id="target-audience">

                <div className="m-1 p-1 border border-white rounded">
                    <div>
                      <p className="font-bold mx-2 px-2 mt-2">Target Audience</p>
                      <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">Who this contactOrganization is for?</p>
                    </div>

                      {formData.contactOrganizationTargetAudience.map((item, index) => (
                        <div key={index} className="flex justify-start items-center mx-1">
                          <input
                            type="text"
                            value={item.audience}
                            onChange={(e) => handleAudienceChange(index, e.target.value)}
                            placeholder="Enter a target audience"
                            className="w-full rounded p-2 m-1 text-xs"
                          />
                          <div className="rounded bg-red-500 m-1 p-1">
                            <button type="button" onClick={() => handleRemoveAudience(index)}>
                              <MinusIcon className="h-5 w-5 text-white" />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-start items-center m-1">

                          <p className="font-bold text-xs m-1 p-1">Add</p>
                          <button type="button" onClick={handleAddAudience}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                          </button>
                      
                      </div>
                </div>
           
            </div> */}

            {/* ContactOrganization Pre Work */}
            {/* <div id="pre-work">

                <div className="m-1 p-1 border border-white rounded">
                    <div>
                      <p className="font-bold mx-2 px-2 mt-2">ContactOrganization Locations</p>
                      <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">What are the locations for this contactOrganization?</p>
                    </div>

                      {formData.contactOrganizationLocations.map((item, index) => (
                        <div key={index} className="flex justify-start items-center mx-1">
                          <input
                            type="text"
                            value={item.location}
                            onChange={(e) => handleLocationsChange(index, e.target.value)}
                            placeholder="Enter a pre work for the contactOrganization"
                            className="w-full rounded p-2 m-1 text-xs"
                          />
                          <div className="rounded bg-red-500 m-1 p-1">
                            <button type="button" onClick={() => handleRemoveLocations(index)}>
                              <MinusIcon className="h-5 w-5 text-white" />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-start items-center m-1">

                          <p className="font-bold text-xs m-1 p-1">Add</p>
                          <button type="button" onClick={handleAddLocations}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                          </button>
                      
                      </div>
                </div>
           
            </div> */}
            
            {/* ContactOrganization Recognitions */}
            {/* <div id="recognitions">

                <div className="m-1 p-1 border border-white rounded">
                    <div>
                      <p className="font-bold mx-2 px-2 mt-2">ContactOrganization Recognitions</p>
                      <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">What one can expect to achieve out of the contactOrganization?</p>
                    </div>

                      {formData.contactOrganizationRecognitions.map((item, index) => (
                        <div key={index} className="flex justify-start items-center mx-1">
                          <input
                            type="text"
                            value={item.recognition}
                            onChange={(e) => handleRecognitionsChange(index, e.target.value)}
                            placeholder="Enter an recognition for the contactOrganization"
                            className="w-full rounded p-2 m-1 text-xs"
                          />
                          <div className="rounded bg-red-500 m-1 p-1">
                            <button type="button" onClick={() => handleRemoveRecognitions(index)}>
                              <MinusIcon className="h-5 w-5 text-white" />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-start items-center m-1">

                          <p className="font-bold text-xs m-1 p-1">Add</p>
                          <button type="button" onClick={handleAddRecognitions}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                          </button>
                      
                      </div>
                </div>
           
            </div> */}

            <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Create"}
                type={"create"}
                handleClick={handleSubmit}
              />
              <EtBtnFormBasic
                name={"Clear"}
                type={"clear"}
                handleClick={handleClear}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EtCreateContactOrganization