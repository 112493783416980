
    import React from 'react';
    
    const EtSponsorUserActionIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m1999.7 4.2578c-299.15-21.516-598.69 36.34-868.31 167.71-269.62 131.38-499.78 331.62-667.19 580.48-167.41 248.86-266.14 537.51-286.21 836.76-1.7617 28.121 8.1641 55.723 27.43 76.277 19.27 20.559 46.168 32.25 74.344 32.312 28.344-0.15625 55.57-11.055 76.195-30.496 20.629-19.438 33.113-45.977 34.945-74.262 21.098-284.55 123.44-557.08 294.86-785.18 171.42-228.11 404.72-402.21 672.18-501.64 267.46-99.418 557.83-119.97 836.63-59.223s534.29 200.25 736.14 401.94c201.84 201.69 341.55 457.07 402.53 735.82 60.977 278.75 40.652 569.14-58.555 836.68-99.207 267.54-273.12 500.98-501.1 672.58-227.97 171.6-500.41 274.16-784.95 295.48-29.078 2.0352-56.316 14.957-76.285 36.191-19.969 21.23-31.199 49.211-31.449 78.359 0.066406 27.418 11.43 53.598 31.414 72.371 19.984 18.777 46.82 28.488 74.191 26.848 343.45-21.699 672.27-146.92 943.15-359.17 270.88-212.25 471.12-501.57 574.35-829.85 103.22-328.29 104.59-680.14 3.918-1009.2-100.67-329.08-298.66-619.95-567.88-834.29-269.23-214.35-597.06-342.12-940.33-366.48z" fillRule="evenodd"/>
 <path d="m2422.1 538.68c-904.47-378.57-1772.3 246.98-1820 1091.4-1.3008 17.434 4.7031 34.629 16.574 47.469 11.867 12.836 28.539 20.168 46.023 20.238h78.355c18.219-0.14453 35.73-7.0742 49.117-19.434 13.387-12.363 21.684-29.27 23.273-47.422 48.121-715.4 802.27-1234.9 1571.3-873.39 191.2 90.355 345.38 243.93 436.48 434.78 369.62 783.96-176.72 1551.3-911.28 1575.6-8.2891 0.32422-16.133 3.8203-21.918 9.7656-5.7852 5.9414-9.0664 13.879-9.168 22.172v104.33c-2.6445 19.75 3.5469 39.66 16.918 54.43 13.375 14.77 32.578 22.898 52.492 22.219 846.56-47.695 1473-919.38 1088.9-1826v0.003906c-58.148-137.71-142.45-262.84-248.22-368.47-105.77-105.63-231.02-189.75-368.81-247.71z" fillRule="evenodd"/>
 <path d="m1690.6 1700.4-1037.8 258.48c-38.953 10.367-73.062 34.02-96.426 66.867-23.363 32.848-34.516 72.824-31.523 113.02 2.9883 40.199 19.926 78.09 47.891 107.12l239.32 235.91-728.6 739.25c-41.562 42.297-64.621 99.367-64.102 158.66s24.574 115.96 66.867 157.52c42.297 41.559 99.371 64.617 158.66 64.098 59.297-0.51953 115.96-24.57 157.52-66.867l728.6-742.23 239.32 236.34-0.003907 0.003906c29.023 29.336 67.707 47.137 108.87 50.098 41.16 2.9609 81.992-9.1211 114.91-34s55.695-60.863 64.086-101.27l239.75-1039h-0.003906c9.0234-38.129 4.6289-78.199-12.449-113.46-17.074-35.266-45.789-63.566-81.297-80.129-35.508-16.566-75.641-20.379-113.63-10.805z" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtSponsorUserActionIcon1SVGComp;
    