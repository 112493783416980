import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userSession8848NLGsAdapter = createEntityAdapter({})

const initialState = userSession8848NLGsAdapter.getInitialState()

export const userSession8848NLGsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserSession8848NLGs: builder.query({
            query: () => ({
                url: '/userSession8848NLGs/getUserSession8848NLGs',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserSession8848NLGs = responseData.map(userSession8848NLG => {
                    userSession8848NLG.id = userSession8848NLG._id
                    return userSession8848NLG
                })
                return userSession8848NLGsAdapter.setAll(initialState, loadedUserSession8848NLGs)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserSession8848NLG', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserSession8848NLG', id}))
                    ]
                } else {
                    return [ { type: 'UserSession8848NLG', id: 'LIST'}]
                }
            },
        }),
        createNewUserSession8848NLG: builder.mutation({
            query: initialUserSession8848NLG => ({
                url: '/userSession8848NLGs/createNewUserSession8848NLG',
                method: 'POST',
                body: {...initialUserSession8848NLG,}
            }),
            invalidatesTags: [
                { type: 'UserSession8848NLG', id: "LIST" }
            ] 
        }),
        updateUserSession8848NLG: builder.mutation({
            query: initialUserSession8848NLG => ({
                url: '/userSession8848NLGs/updateUserSession8848NLG',
                method: 'PATCH',
                body: {...initialUserSession8848NLG,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserSession8848NLG', id: arg.id },
                { type: 'UserSession8848NLG', id: 'LIST' }
            ],
        }),
        deleteUserSession8848NLG: builder.mutation({
            query: ({id}) => ({
                url: '/userSession8848NLGs/deleteUserSession8848NLG',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserSession8848NLG', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserSession8848NLGsQuery,
    useCreateNewUserSession8848NLGMutation,
    useUpdateUserSession8848NLGMutation,
    useDeleteUserSession8848NLGMutation,
} = userSession8848NLGsApiSlice

// returns the query result object
export const selectUserSession8848NLGResult = userSession8848NLGsApiSlice.endpoints.getUserSession8848NLGs.select()

// create memoized selector
const selectUserSession8848NLGsData = createSelector(
    selectUserSession8848NLGResult,
    userSession8848NLGResult => userSession8848NLGResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserSession8848NLGs,
    selectById: selectUserSession8848NLGById,
    selectIds: selectUserSession8848NLGIds,
} = userSession8848NLGsAdapter.getSelectors(state => selectUserSession8848NLGsData(state) ?? initialState)