// First Created - 07 Jul 2024
// Author - Atul Pandey
// Purpose - to convert a Base64 svg stream into a svg component which could be dynamically styled using Tailwind CSS

import React from 'react';

// Function to decode base64 SVG string
const decodeBase64Svg = (base64) => {
  // Decode the base64 string
  const svgString = atob(base64.replace(/^data:image\/svg\+xml;base64,/, ''));

  // Create a DOM parser
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
  const svgElement = svgDoc.documentElement;

  // Replace width and height with viewBox
  const width = svgElement.getAttribute('width') || 96;
  const height = svgElement.getAttribute('height') || 96;
  svgElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
  svgElement.removeAttribute('width');
  svgElement.removeAttribute('height');

  // Remove unnecessary attributes
  svgElement.removeAttribute('xmlns:xlink');
  svgElement.removeAttribute('xml:space');
  svgElement.removeAttribute('overflow');

  // Set fill to currentColor at the SVG level
  svgElement.setAttribute('fill', 'currentColor');

  // Modify path elements
  const paths = svgElement.querySelectorAll('path');
  paths.forEach((path) => {
    path.removeAttribute('fill');
    path.setAttribute('fillRule', path.getAttribute('fill-rule') || '');
    path.removeAttribute('fill-rule');
  });

  // Convert the modified SVG element back to string
  const serializer = new XMLSerializer();
  const modifiedSvgString = serializer.serializeToString(svgElement);

  return modifiedSvgString;
};

// Function to create a React component from an SVG string
const createSvgComponentFromString = (svgString) => {
    return (props) => (
        <div
          {...props}
          dangerouslySetInnerHTML={{ __html: svgString }}
          className={`fill-current ${props.className}`} // Ensure the fill color can be set via className
        />
      );
  };
  
// Main function to create a React component from a base64 SVG string
const createSvgComponentFromBase64 = (base64) => {
  const svgString = decodeBase64Svg(base64);
// console.log('svgString:', svgString)
// console.log('componentSVG:', createSvgComponentFromString(svgString))
  return createSvgComponentFromString(svgString);
};

export { createSvgComponentFromBase64, decodeBase64Svg };

