import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const gratitudeTypesAdapter = createEntityAdapter({})

const initialState = gratitudeTypesAdapter.getInitialState()

export const gratitudeTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getGratitudeTypes: builder.query({
            query: () => ({
                url: '/gratitudeTypes/getGratitudeTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedGratitudeTypes = responseData.map(gratitudeType => {
                    gratitudeType.id = gratitudeType._id
                    return gratitudeType
                })
                return gratitudeTypesAdapter.setAll(initialState, loadedGratitudeTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'GratitudeType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'GratitudeType', id}))
                    ]
                } else {
                    return [ { type: 'GratitudeType', id: 'LIST'}]
                }
            },
        }),
        createNewGratitudeType: builder.mutation({
            query: initialGratitudeType => ({
                url: '/gratitudeTypes/createNewGratitudeType',
                method: 'POST',
                body: {...initialGratitudeType,}
            }),
            invalidatesTags: [
                { type: 'GratitudeType', id: "LIST" }
            ] 
        }),
        updateGratitudeType: builder.mutation({
            query: initialGratitudeType => ({
                url: '/gratitudeTypes/updateGratitudeType',
                method: 'PATCH',
                body: {...initialGratitudeType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'GratitudeType', id: arg.id },
                { type: 'GratitudeType', id: 'LIST' }
            ],
        }),
        deleteGratitudeType: builder.mutation({
            query: ({id}) => ({
                url: '/gratitudeTypes/deleteGratitudeType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'GratitudeType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetGratitudeTypesQuery,
    useCreateNewGratitudeTypeMutation,
    useUpdateGratitudeTypeMutation,
    useDeleteGratitudeTypeMutation,
} = gratitudeTypesApiSlice

// returns the query result object
export const selectGratitudeTypeResult = gratitudeTypesApiSlice.endpoints.getGratitudeTypes.select()

// create memoized selector
const selectGratitudeTypesData = createSelector(
    selectGratitudeTypeResult,
    gratitudeTypeResult => gratitudeTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllGratitudeTypes,
    selectById: selectGratitudeTypeById,
    selectIds: selectGratitudeTypeIds,
} = gratitudeTypesAdapter.getSelectors(state => selectGratitudeTypesData(state) ?? initialState)