import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userSessionImaginationsAdapter = createEntityAdapter({})

const initialState = userSessionImaginationsAdapter.getInitialState()

export const userSessionImaginationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserSessionImaginations: builder.query({
            query: () => ({
                url: '/userSessionImaginations/getUserSessionImaginations',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserSessionImaginations = responseData.map(userSessionImagination => {
                    userSessionImagination.id = userSessionImagination._id
                    return userSessionImagination
                })
                return userSessionImaginationsAdapter.setAll(initialState, loadedUserSessionImaginations)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserSessionImagination', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserSessionImagination', id}))
                    ]
                } else {
                    return [ { type: 'UserSessionImagination', id: 'LIST'}]
                }
            },
        }),
        createNewUserSessionImagination: builder.mutation({
            query: initialUserSessionImagination => ({
                url: '/userSessionImaginations/createNewUserSessionImagination',
                method: 'POST',
                body: {...initialUserSessionImagination,}
            }),
            invalidatesTags: [
                { type: 'UserSessionImagination', id: "LIST" }
            ] 
        }),
        updateUserSessionImagination: builder.mutation({
            query: initialUserSessionImagination => ({
                url: '/userSessionImaginations/updateUserSessionImagination',
                method: 'PATCH',
                body: {...initialUserSessionImagination,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserSessionImagination', id: arg.id },
                { type: 'UserSessionImagination', id: 'LIST' }
            ],
        }),
        deleteUserSessionImagination: builder.mutation({
            query: ({id}) => ({
                url: '/userSessionImaginations/deleteUserSessionImagination',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserSessionImagination', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserSessionImaginationsQuery,
    useCreateNewUserSessionImaginationMutation,
    useUpdateUserSessionImaginationMutation,
    useDeleteUserSessionImaginationMutation,
} = userSessionImaginationsApiSlice

// returns the query result object
export const selectUserSessionImaginationResult = userSessionImaginationsApiSlice.endpoints.getUserSessionImaginations.select()

// create memoized selector
const selectUserSessionImaginationsData = createSelector(
    selectUserSessionImaginationResult,
    userSessionImaginationResult => userSessionImaginationResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserSessionImaginations,
    selectById: selectUserSessionImaginationById,
    selectIds: selectUserSessionImaginationIds,
} = userSessionImaginationsAdapter.getSelectors(state => selectUserSessionImaginationsData(state) ?? initialState)