import React, { useEffect, useRef, useCallback } from "react";
import ReactQuill from "react-quill";
import EtEditorToolbarLight, { modules, formats } from "./EtEditorToolbarLight2";
import "react-quill/dist/quill.snow.css";

export const EtContentEditorLight2 = ({ value, handleChange, placeholderText, editorKey }) => {
  const quillRef = useRef(null);

  const memoizedHandleChange = useCallback((content, delta, source, editor) => {
    handleChange(content);
  }, [handleChange]);

  const customModules = {
    ...modules,
    toolbar: {
      ...modules.toolbar,
      container: `#toolbar-${editorKey}`,
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (quillRef.current) {
        const editor = quillRef.current.getEditor();
        if (editor && editor.root) {
          editor.root.setAttribute('spellcheck', false);
        }
      }
    }, 100);

    return () => {
      clearTimeout(timer);
      if (quillRef.current) {
        const editor = quillRef.current.getEditor();
        if (editor) {
          editor.off('text-change', memoizedHandleChange);
          
        }
      }
    };
  }, [memoizedHandleChange, editorKey]);

  return (
    <div className="custom-quill-editor2">
      <EtEditorToolbarLight id={`toolbar-${editorKey}`} />
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={value}
        onChange={memoizedHandleChange}
        placeholder={placeholderText}
        modules={customModules}
        formats={formats}
        className='w-full h-full'
      />
    </div>
  );
};

export default EtContentEditorLight2;