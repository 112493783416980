
    import React from 'react';
    
    const EtDocSharedNoteAllUsersIconSVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m2812.5 1951.9v-826.88l-562.5-562.5h-1237.5c-59.672 0-116.9 23.707-159.1 65.902-42.195 42.195-65.902 99.426-65.902 159.1v2025c0 39.496 10.395 78.297 30.145 112.5 19.746 34.203 48.152 62.609 82.355 82.355 34.203 19.75 73.004 30.145 112.5 30.145h1073.2c91.617 103.18 212.65 175.8 346.81 208.08 134.15 32.285 274.98 22.68 403.5-27.52 128.53-50.203 238.58-138.59 315.34-253.25 76.762-114.66 116.54-250.09 113.97-388.05-2.5703-137.96-47.355-271.82-128.32-383.55-80.973-111.73-194.24-195.96-324.55-241.34zm-675-1164.4 450 450h-337.5c-29.836 0-58.453-11.852-79.551-32.949-21.098-21.098-32.949-49.715-32.949-79.551zm-450 1800h-675v-225h675zm225-450h-900v-225h900zm675 1012.5c-149.18 0-292.26-59.262-397.75-164.75-105.49-105.49-164.75-248.56-164.75-397.75s59.262-292.26 164.75-397.75c105.49-105.49 248.56-164.75 397.75-164.75s292.26 59.262 397.75 164.75c105.49 105.49 164.75 248.56 164.75 397.75 0 98.738-25.992 195.74-75.359 281.25-49.371 85.512-120.38 156.52-205.89 205.89-85.512 49.367-182.51 75.359-281.25 75.359z" fillRule="evenodd"/>
 <path d="m2522.2 2475c0 62.133-50.367 112.5-112.5 112.5s-112.5-50.367-112.5-112.5 50.367-112.5 112.5-112.5 112.5 50.367 112.5 112.5" fillRule="evenodd"/>
 <path d="m2409.8 2587.5c-59.672 0-116.9 23.707-159.1 65.902-42.195 42.195-65.902 99.426-65.902 159.1h450c0-39.496-10.395-78.297-30.145-112.5-19.746-34.203-48.152-62.609-82.355-82.355-34.203-19.75-73.004-30.145-112.5-30.145z" fillRule="evenodd"/>
 <path d="m2859.8 2475c0 62.133-50.367 112.5-112.5 112.5s-112.5-50.367-112.5-112.5 50.367-112.5 112.5-112.5 112.5 50.367 112.5 112.5" fillRule="evenodd"/>
 <path d="m2747.2 2587.5c-45.375 0.007812-89.691 13.73-127.12 39.375 45.512 51.137 70.727 117.17 70.875 185.62h281.25c0-39.496-10.395-78.297-30.145-112.5-19.746-34.203-48.152-62.609-82.355-82.355-34.203-19.75-73.004-30.145-112.5-30.145z" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtDocSharedNoteAllUsersIconSVGComp;
    