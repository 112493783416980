import React, { useState, useEffect, useMemo } from "react"
import { useNavigate } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLightWithPlace from "@/utils/EtContentEditorLightWithPlace"
import { useCreateNewCapabilitiesTechAreaTypeMutation } from "./capabilitiesTechAreaTypesApiSlice"
import { useGetCapabilitiesDomainTypesQuery } from '../capabilitiesDomainTypes/capabilitiesDomainTypesApiSlice' // 14 Nov 2024
import { useGetCapabilitiesFunctionTypesQuery } from '../capabilitiesFunctionTypes/capabilitiesFunctionTypesApiSlice' // 14 Nov 2024

const EtCreateCapabilitiesTechAreaType = ({setActiveAdminView}) => {
  // First Created - 12 Nov 2024
  // Author - Atul Pandey
  // Updated - 14 Nov 2024 - added capabilitiesDomainType
  // Updated - 28 Feb 2025 - updated the softSkillFunctionArea with props - { setActiveAdminView }
  // Updated - 28 Feb 2025 - switched from navigate to setActiveAdminView

  // 0 - Set Page Title
  useTitle('Create CapabilitiesTechArea Types')
  
  // 1 - set page navigations
  const navigate = useNavigate()

  // 1b - state variables for domain types
  const [selectedFocus, setSelectedFocus] = useState("");
  const [filteredDomainTypes, setFilteredDomainTypes] = useState([]);
  const [filteredFunctionTypes, setFilteredFunctionTypes] = useState([]);

  // 2a - set the mutation to create the new section data
  const[createNewCapabilitiesTechAreaType, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewCapabilitiesTechAreaTypeMutation()

  // 2b - Get DomainTypes Data
  const { data: domainTypesData } = useGetCapabilitiesDomainTypesQuery();
  const domainTypes = useMemo(() => {
    return domainTypesData?.ids?.map(id => domainTypesData?.entities[id]) || [];
  }, [domainTypesData]);
  // console.log('domainTypes', domainTypes)

  // 2c - populate the filteredDomainTypes based on selected focus
  useEffect(() => {
    if (selectedFocus) {
      setFilteredDomainTypes(
        domainTypes.filter((domain) => domain.capabilitiesDomainFocus === selectedFocus)
      );
    } else {
      setFilteredDomainTypes([]); // Reset if no focus is selected
    }
  }, [selectedFocus, domainTypes]);

  // 2d - Get FunctionTypes Data
  const { data: functionTypesData } = useGetCapabilitiesFunctionTypesQuery();
  const functionTypes = useMemo(() => {
    return functionTypesData?.ids?.map(id => functionTypesData?.entities[id]) || [];
  }, [functionTypesData]);
  // console.log('functionTypes', functionTypes)

// 3 - create the form data object
const [formData, setFormData] = useState({
  capabilitiesTechAreaTypeName: "",
  capabilitiesTechAreaTypeDesc: "",
  capabilitiesDomainType:"",
  capabilitiesFunctionType: "",
  aboutMe: "",
  pic: "",
})

// 3b - Populate the filteredFunctionTypes based on selected capabilitiesDomainType
useEffect(() => {
  if (formData.capabilitiesDomainType) {
    setFilteredFunctionTypes(
      functionTypes.filter((func) => func.capabilitiesDomainType._id === formData.capabilitiesDomainType)
    );
  } else {
    setFilteredFunctionTypes([]); // Reset if no domain type is selected
  }
}, [formData.capabilitiesDomainType, functionTypes]);

// 4 - Reset form upon successful submission
useEffect(() => {
  if(isSuccess) {
    setFormData({
      capabilitiesTechAreaTypeName: "",
      capabilitiesTechAreaTypeDesc: "",
      capabilitiesDomainType: "",
      capabilitiesFunctionType: "",
      aboutMe: "",
      pic: "",
    })
 // navigate('/capabilitiesTechAreatypes')
 setActiveAdminView('capabilitiesTechAreatypes'); // 28 Feb 2025
}

},[isSuccess])// ✅ 28 Feb 2025 - Removed `navigate` since it's no longer used.

// 5 - Form field updates / change handling

const handleChange = (e) => {
  const { name, value, files } = e.target
  if (name === "pic") {
    setFormData(prevState => ({ ...prevState, pic: files[0] }))
  } else {
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }
}

// 6 - techArea to strip html tags from sections
const stripHtml = (html) => {
  return html.replace(/<\/?[^>]+(>|$)/g, "");
}

// 7 - Form Submit Logic
const handleSubmit = async (e) => {
e.preventDefault()

// Initialize an object to collect the form data
const capabilitiesTechAreaTypeData = {
  capabilitiesTechAreaTypeName: formData.capabilitiesTechAreaTypeName,
  capabilitiesTechAreaTypeDesc: formData.capabilitiesTechAreaTypeDesc,
  capabilitiesFunctionType: formData.capabilitiesFunctionType || null, // New field for domain type selection
  aboutMe: formData.aboutMe,
}

// console.log('capabilitiesTechAreaType Name:',capabilitiesTechAreaTypeData.capabilitiesTechAreaTypeName)
// console.log('capabilitiesFunctionType:',capabilitiesTechAreaTypeData.capabilitiesFunctionType)

if (formData.pic) {
  // Convert the image to a base64 string
  const reader = new FileReader()
  reader.onloadend = async () => {
    // Once conversion is done, add the base64 string to your data object
    capabilitiesTechAreaTypeData.pic = reader.result

    // Now, send the data object to the server
    try {
      console.log("Form submitted.")
      await createNewCapabilitiesTechAreaType(capabilitiesTechAreaTypeData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
  }
  reader.readAsDataURL(formData.pic)
} else {
  // No image, send other data as is
  try {
    console.log("Form submitted.")
    await createNewCapabilitiesTechAreaType(capabilitiesTechAreaTypeData).unwrap() // Dispatch the RTK Query mutation
  } catch (error) {
    console.error(error)
  }
}
}

// 8 - Handle Cancel

const handleCancel = () => {
 // navigate('/capabilitiesTechAreatypes')
 setActiveAdminView('capabilitiesTechAreatypes'); // 28 Feb 2025
}

// 9 - Handle Clear
const handleClear = () => {
  setFormData({
    capabilitiesTechAreaTypeName: "",
    capabilitiesTechAreaTypeDesc: "",
    capabilitiesDomainType: "", // New field for domain type selection
    capabilitiesFunctionType: "",
    aboutMe: "",
    pic: "",
    })
}
return (
  <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
    {isLoading && <PulseLoader color={"#ea580c"} />}
    {isError && (
      <p className="text-orange-700 font-semi-bold py-2">
        {error?.data?.message}
      </p>
    )}
    <div className="bg-white p-1 m-1 rounded">
      <p className="p-1 m-1 font-bold font-poppins text-xl">Add an CapabilitiesTechArea Type</p>

      <div className="bg-gray-200 rounded m-1 p-1">
        <form
          onSubmit={handleSubmit}
          className="m-1 p-1 text-xs font-poppins"
        >

          <div id="focus">
            <select
              name="focus"
              value={selectedFocus}
              onChange={(e) => {
                setSelectedFocus(e.target.value);
                setFilteredDomainTypes(
                  domainTypes?.filter(
                    (domain) => domain.capabilitiesDomainFocus === e.target.value
                  ) || []
                );
              }}
              className="w-full rounded p-2 m-1"
            >
              <option value="">Select Domain Focus</option>
              <option value="Business">Business</option>
              <option value="Technology">Technology</option>
            </select>
          </div>

          <div id="domainType">
            <select
              name="capabilitiesDomainType"
              value={formData.capabilitiesDomainType || ""}
              onChange={(e) => setFormData({ ...formData, capabilitiesDomainType: e.target.value })}
              className="w-full rounded p-2 m-1"
              disabled={!selectedFocus} // Disable if no focus is selected
            >
              <option value="">Select Domain Type</option>
              {filteredDomainTypes?.map((domain) => (
                <option key={domain._id} value={domain._id}>
                  {domain.capabilitiesDomainTypeName}
                </option>
              ))}
            </select>
          </div>

          <div id="functionType">
            <select
              name="capabilitiesFunctionType"
              value={formData.capabilitiesFunctionType || ""}
              onChange={(e) => setFormData({ ...formData, capabilitiesFunctionType: e.target.value })}
              className="w-full rounded p-2 m-1"
              disabled={!formData.capabilitiesDomainType} // Disable if no domain type is selected
            >
              <option value="">Select Function Type</option>
              {filteredFunctionTypes?.map((func) => (
                <option key={func._id} value={func._id}>
                  {func.capabilitiesFunctionTypeName}
                </option>
              ))}
            </select>
          </div>


          <div id="capabilitiesTechAreaTypeName">
            <input
              type="text"
              name="capabilitiesTechAreaTypeName"
              placeholder="Enter the capabilitiesTechArea type name here"
              value={formData.capabilitiesTechAreaTypeName}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div className="bg-white w-full rounded p-2 m-1">
            <EtContentEditorLightWithPlace
              value={formData.capabilitiesTechAreaTypeDesc}
              handleChange={(content) =>
                setFormData({ ...formData, capabilitiesTechAreaTypeDesc: content })
              }
              placeholderText={
                "Write this capabilitiesTechArea type is about or will be used for"
              }
              // toolbarId={'sectionDesc'}
            />
          </div>

          <div id="aboutMe">
            <input
              type="text"
              name="aboutMe"
              placeholder="Add something about the personality about this capabilitiesTechArea type"
              value={formData.aboutMe}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="pic">
            <input
              type="file"
              name="pic"
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

         <div id="capabilitiesTechAreas" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic
              name={"Create"}
              type={"create"}
              handleClick={handleSubmit}
            />
            <EtBtnFormBasic
              name={"Clear"}
              type={"clear"}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={"Cancel"}
              type={"cancel"}
              handleClick={handleCancel}
            />
          </div>
        </form>
      </div>
    </div>
  </div>
)
}

export default EtCreateCapabilitiesTechAreaType