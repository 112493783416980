import { useRef, useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { setCredentials } from './authSlice'
import { useLoginMutation } from './authApiSlice'

import usePersist from '@/hooks/usePersist'
import useTitle from '@/hooks/useTitle'
import { userProfileIcon, pwdIcon } from '@/assets/img/imgAssets'

import { useUpdateUserPasswordMutation } from '@/pages/admin/users/usersApiSlice'


const Login = () => {
  // Author - Atul Pandey
  // Updated - 19 Feb 2025 - changed navigation to home page based on screen size
  // Updated - 19 Feb 2025 - changed JSX for small screens
  // Updated - 02 Mar 2025 - temp password check

  // 0 - Use Title
  useTitle('Et - Login')

  // 1a - State Variables
  const userRef = useRef()
  const errRef = useRef()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [persist, setPersist] = usePersist()

  // 1b - State variables for password reset if current password is temp - 02 Mar 2025 
  const [isTempPassword, setIsTempPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetError, setResetError] = useState("");

  // 2 - Navigation & Data 
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [login, { isLoading }] = useLoginMutation()
  const [updateUserPassword] = useUpdateUserPasswordMutation() // 02 Mar 2025

  useEffect(() => {
    userRef.current?.focus()
    }, [])

  useEffect(() => {
    setErrMsg('');
  }, [username, password])

  // 3 - HandleSubmit
  const handleSubmit = async (e, overridePassword=null ) => {
    e.preventDefault()
    setErrMsg(""); // 02 Mar 2025

    try {
        // const { accessToken } = await login({ username, password }).unwrap() // Old code - muted 02 Mar 2025
        
        // const response = await login({ username, password }).unwrap() // 02 Mar 2025
        const response = await login({ username, password: overridePassword || password }).unwrap(); // ✅ Ensure the correct field name

        // Step 1: Check if the response indicates a temp password
        if (response.requiresPasswordReset) {
          setIsTempPassword(true);
          return;
        }
        
        // Step 2: Proceed with normal login
        // dispatch(setCredentials({ accessToken }))
        dispatch(setCredentials({ accessToken: response.accessToken }));
        setUsername('')
        setPassword('')

        // Detect screen size -- 19 Feb 2025
        const screenWidth = window.innerWidth;
        
        if (screenWidth < 768) { 
            // For small screens (sm/xs)
            navigate('/etapp/mobile');
        } else {
            // For medium screens and larger (md+)
            navigate('/etapp');
        }
        // navigate('/etapp')
    } catch (err) {
      const errorMessage = err?.data?.message || 'An unexpected error occurred';
        setErrMsg(errorMessage); // Update errMsg based on server response or a fallback error message
    }
  }

  useEffect(() => {
    if (errMsg) {
        errRef.current?.focus();
    }
  }, [errMsg])

  // 4 - Functions
  const handleUserInput = (e) => setUsername(e.target.value)
  const handlePwdInput = (e) => setPassword(e.target.value)
  const handleToggle = (e) => setPersist(prev => !prev)

  // 5 - Handle Password Reset for temp - 02 Mar 2025
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setResetError("");

    // Step 1: Validate that the new password and confirmation match
    if (newPassword !== confirmPassword) {
        setResetError("Passwords do not match.");
        return;
    }

    try {
        // Step 2: Call the API to update the password
        await updateUserPassword({ username, newPassword }).unwrap();

        // Step 3: Reset temp password flag and log in again
        setIsTempPassword(false);
        setPassword(newPassword);
        // handleSubmit(e);
        await handleSubmit(e, newPassword);
    } catch (err) {
        setResetError(err?.data?.message || "Error updating password");
    }
 };


  if (isLoading) return <p className='text-orange-800 text-xl font-oswald font-bold'>Loading...</p>

  const content =  (
    <div className="flex flex-col bg-scroll bg-cover lg:flex-row items-center justify-center h-screen bg-gradient-to-r from-gray-400 to-gray-400 via-black">

        {/* <div className='mx-auto rounded-2xl w-1/3 bg-gray-200 px-5 py-5 z-40 shadow-2xl shadow-slate-600'> */}
        <div className='mx-auto rounded-2xl ml-2 mr-2 w-[90vw] sm:w-3/4 md:w-1/2 lg:w-1/3 bg-gray-200 px-5 py-5 z-40 shadow-2xl shadow-slate-600'>
          <p ref={errRef} className='text-orange-800 text-xl font-oswald font-bold' aria-live="assertive">{errMsg}</p>

          <div className='flex flex-col items-center'>
            <h3 className="lg:my-2 py-5 text-black  text-xl lg:text-3xl font-bold">Sign In</h3>
          </div>
          
          {isTempPassword ? (
            <form className="space-y-1 font-poppins text-xs" onSubmit={handlePasswordReset}>
                <p className="text-orange-800 font-bold m-1 p-1">You must change your password before continuing.</p>       
                <div className=" mt-4 pt-4 grid grid-cols-7 justify-start items-center">
                    
                    <div className='col-span-2'>
                      <p className="font-semibold text-nowrap">New Password</p>
                    </div>
                    <div className='col-span-5'>
                      <input 
                          type="password" 
                          value={newPassword} 
                          onChange={(e) => setNewPassword(e.target.value)} 
                          required 
                          className="m-1 p-1 border rounded-md w-full"
                      />
                    </div>
                </div>

                <div className="grid grid-cols-7 items-center">
                  <div className='col-span-2'>
                        <p className="font-semibold text-nowrap">Confirm Password</p>
                      </div>
                      <div className='col-span-5'>
                          <input 
                            type="password" 
                            value={confirmPassword} 
                            onChange={(e) => setConfirmPassword(e.target.value)} 
                            required 
                            className="m-1 p-1 border rounded-md w-full"
                        />
                      </div>
                </div>

                {resetError && <p className="text-red-600">{resetError}</p>}
        
                <div className='m-1 p-1 pt-5 flex justify-center items-center'>
                  <button disabled={newPassword !== confirmPassword} className={newPassword === confirmPassword ? "bg-orange-600 p-1 rounded text-white" : "bg-gray-400 p-1 rounded"}> Reset Password</button>
                </div>
                
            
            </form>

          ):(
              <form className="space-y-1 font-poppins" onSubmit={handleSubmit}>
                <div className='relative mx-2 justify-evenly items-center'>   
                    <img 
                        src={userProfileIcon}
                        className='-px-1 -mx-1 absolute left-2 top-1/2 transform -translate-y-1/2 h-8 w-8'
                        alt=''
                      />
                      <input
                        id="username"
                        type="text"
                        ref={userRef}
                        value={username}
                        placeholder='Enter your username here'
                        // className="pl-12 pr-2 w-full rounded-md border-0 py-3 text-md text-gray-600 shadow-lg z-30 shadow-slate-600 placeholder:text-gray-400 placeholder:text-xs leading-6 focus:outline-none"
                        className="pl-10 pr-2 w-full rounded-md border-0 py-2 sm:py-3 text-sm sm:text-md text-gray-600 shadow-lg z-30 shadow-slate-600 placeholder:text-gray-400 placeholder:text-xs leading-6 focus:outline-none"
                        onChange={handleUserInput}
                        autoComplete="off"
                        required
                      />
                </div>  

                <div className='relative mx-2 justify-evenly items-center'>
                    <img 
                        src={pwdIcon}
                        className='-px-1 -mx-1 absolute left-2 top-1/2 transform -translate-y-1/2 h-8 w-8'
                        alt=''
                      />
                  <input
                        id="password"
                        type="password"
                        value={password}
                        placeholder='Enter your password here'
                        // className="pl-12 pr-2 w-full rounded-md border-0 py-3 text-xs text-gray-600 shadow-lg z-30 shadow-slate-600 placeholder:text-gray-400 text-md leading-6 focus:outline-none"
                        className="pl-10 pr-2 w-full rounded-md border-0 py-2 sm:py-3 text-xs text-gray-600 shadow-lg z-30 shadow-slate-600 placeholder:text-gray-400 text-md leading-6 focus:outline-none"
                        onChange={handlePwdInput}
                        required
                      />
                </div>

                <div className="py-5 flex flex-col items-center">
                    <button 
                      className="block rounded-full bg-gradient-to-r from-orange-800 to-orange-400 w-20 px-3 py-2 text-center text-white text-md shadow-lg hover:bg-gradient-to-r hover:from-orange-400 hover:to-orange-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 shadow-black"
                      >
                        Sign In
                    </button>
                  </div>

                  <div className='pb-10'>
                      <label className="block text-sm font-oswaldtext-xs leading-6 text-gray-800" htmlFor="persist">
                                
                      <input
                          id="persist"
                          type="checkbox"
                          checked={persist}
                          onChange={handleToggle}
                          className="h-4 w-4 mr-5 rounded border-gray-300 text-orange-800 focus:ring-orange-600"
                        />   
                        Trust this device 
                      </label>
                  </div>
                  
              </form>
          )}
          
            
            <div className='py-30 flex flex-wrap justify-center'>
              <Link to="/forgot-password" className='text-black text-sm font-bold mx-1 px-1'>Forgot Password?</Link>
              {/* <Link to="/signup" className='text-orange-600 text-sm font-bold'>Sign up here</Link> */}
              <Link to="/" className='text-black text-sm font-bold mx-1 px-1'>Back to Home</Link>

            </div>
        </div>
    </div>
  )

  return content
}

export default Login