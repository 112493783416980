import React, { useState, useEffect, useCallback } from "react"
import useTitle from "../../hooks/useTitle"
import { useNavigate } from "react-router"
import { addIcon, userProfileIcon2 } from "../../assets/img/imgAssets"
import {
  PlusIcon,
  MinusIcon,
  TrashIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline"
import { ArrowPathIcon, ClockIcon, CalendarDaysIcon, Squares2X2Icon, ArrowTrendingUpIcon, InboxArrowDownIcon, UserIcon,ArrowDownCircleIcon, ArrowUpCircleIcon  } from "@heroicons/react/24/solid"
import { formatDate, formatDateTime, formatWeekRange } from "../../utils/formatDate" // 18 Sep 2024 - added formatWeekRange
import { getTimeSince } from "../../utils/EtGetTimeSince"
import { differenceInDays } from 'date-fns' // Added 29 Jun 2024
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "../../utils/EtContentEditorLight2"
import { useGetUserAccomplishmentWeeklysQuery, useCreateNewUserAccomplishmentWeeklyMutation } from "./userAccomplishmentWeeklysApiSlice"
import { useCreateNewUserReflectionMutation } from "../userReflections/userReflectionsApiSlice" // 25 Sep 2024
import { useCreateNewUserConversationMutation } from '../userConversations/userConversationsApiSlice' // 01 Oct 2024
import { useGetContactsQuery } from "../contacts/contactsApiSlice" // 02 Oct 2024
import EtModalCreateContact from '@/components/common/modal/EtModalCreateContact' // 03 Oct 2024
import { useGetUserPicQuery, useGetUsersQuery } from "../users/usersApiSlice"
import { useGetCreationTypesQuery } from '@/features/creationTypes/creationTypesApiSlice' // 05 Oct 2024
import { useCreateNewUserCreationMutation, useUpdateUserCreationMutation, useDeleteUserCreationMutation } from "../userCreations/userCreationsApiSlice" // 05 Oct 2024

import { useCreateNewUserContributionMutation } from '@/features/userContributions/userContributionsApiSlice' // 10 Oct 2024
import { useGetContributionTypesQuery } from '@/features/contributionTypes/contributionTypesApiSlice' // 10 Oct 2024

import { useCreateNewUserImpactMutation } from '@/features/userImpacts/userImpactsApiSlice' // 13 Oct 2024
import { useGetImpactTypesQuery } from '@/features/impactTypes/impactTypesApiSlice' // 13 Oct 2024

import { useCreateNewUserWinMutation } from '@/features/userWins/userWinsApiSlice' // 14 Oct 2024
import { useGetWinTypesQuery } from '@/features/winTypes/winTypesApiSlice' // 14 Oct 2024

import { useCreateNewUserChallengeWABMutation } from '@/features/userChallengeWABs/userChallengeWABsApiSlice' // 14 Oct 2024
import { useGetUserChallengeTypesQuery } from '@/features/userChallengeTypes/userChallengeTypesApiSlice' // 14 Oct 2024

import { useCreateNewUserIntentionMutation } from '@/features/userIntentions/userIntentionsApiSlice' // 15 Oct 2024
import { useGetIntentionTypesQuery } from '@/features/intentionTypes/intentionTypesApiSlice' // 15 Oct 2024

import { useCreateNewUserGratitudeMutation } from '@/features/userGratitudes/userGratitudesApiSlice' // 16 Oct 2024
import { useGetGratitudeTypesQuery } from '@/features/gratitudeTypes/gratitudeTypesApiSlice' // 16 Oct 2024

import EtWabActionIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabActionIcon1SVGComp" // 19 Sep 2024
import EtWabReflectionIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabReflectionIcon1SVGComp" // 19 Sep 2024
import EtWabConversationIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabConversationIcon1SVGComp" // 20 Sep 2024
import EtWabCreationIcon1SVGComp  from "@/assets/img/icons/svgComp/EtWabCreationIcon1SVGComp" // 20 Sep 2024
import EtWabContributionIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabContributionIcon1SVGComp" // 20 Sep 2024
import EtWabImpactIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabImpactIcon1SVGComp" // 20 Sep 2024
import EtWabWinIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabWinIcon1SVGComp" // 20 Sep 2024
import EtWabChallengeIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabChallengeIcon1SVGComp" // 20 Sep 2024
import EtWabIntentionIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabIntentionIcon1SVGComp" // 20 Sep 2024
import EtWabGratitudeIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabGratitudeIcon1SVGComp" // 20 Sep 2024
import EtConversationAllIcon1SVGComp from '@/assets/img/icons/svgComp/EtConversationAllIcon1SVGComp' // 02 Oct 2024
import EtConversationContactIcon1SVGComp from '@/assets/img/icons/svgComp/EtConversationContactIcon1SVGComp' // 02 Oct 2024
import EtWabCreationNotesIcon1SVGComp from '@/assets/img/icons/svgComp/EtWabCreationNotesIcon1SVGComp' // 07 Oct 2024

import EtContributionAllIcon1SVGComp from '@/assets/img/icons/svgComp/EtContributionAllIcon1SVGComp' // 10 Oct 2024
import EtContributionContactIcon1SVGComp from '@/assets/img/icons/svgComp/EtContributionContactIcon1SVGComp' // 10 Oct 2024
import EtContributionTypeIcon1SVGComp from '@/assets/img/icons/svgComp/EtContributionTypeIcon1SVGComp' // 11 Oct 2024

import EtImpactAllIcon1SVGComp from '@/assets/img/icons/svgComp/EtImpactAllIcon1SVGComp' // 13 Oct 2024
import EtImpactContactIcon1SVGComp from '@/assets/img/icons/svgComp/EtImpactContactIcon1SVGComp' // 13 Oct 2024
import EtImpactTypeIcon1SVGComp from '@/assets/img/icons/svgComp/EtImpactTypeIcon1SVGComp' // 13 Oct 2024

import EtWinAllIcon1SVGComp from '@/assets/img/icons/svgComp/EtWinAllIcon1SVGComp' // 14 Oct 2024
import EtWinContactIcon1SVGComp from '@/assets/img/icons/svgComp/EtWinContactIcon1SVGComp' // 14 Oct 2024
import EtWinTypeIcon1SVGComp from '@/assets/img/icons/svgComp/EtWinTypeIcon1SVGComp' // 14 Oct 2024

import EtUserChallengeAllIcon1SVGComp from '@/assets/img/icons/svgComp/EtUserChallengeAllIcon1SVGComp' // 14 Oct 2024
import EtUserChallengeContactIcon1SVGComp from '@/assets/img/icons/svgComp/EtUserChallengeContactIcon1SVGComp' // 14 Oct 2024
import EtUserChallengeTypeIcon1SVGComp from '@/assets/img/icons/svgComp/EtUserChallengeTypeIcon1SVGComp' // 14 Oct 2024

import EtIntentionAllIcon1SVGComp from '@/assets/img/icons/svgComp/EtIntentionAllIcon1SVGComp' // 15 Oct 2024
import EtIntentionContactIcon1SVGComp from '@/assets/img/icons/svgComp/EtIntentionContactIcon1SVGComp' // 15 Oct 2024
import EtIntentionTypeIcon1SVGComp from '@/assets/img/icons/svgComp/EtIntentionTypeIcon1SVGComp' // 15 Oct 2024

import EtGratitudeAllIcon1SVGComp from '@/assets/img/icons/svgComp/EtGratitudeAllIcon1SVGComp' // 16 Oct 2024
import EtGratitudeContactIcon1SVGComp from '@/assets/img/icons/svgComp/EtGratitudeContactIcon1SVGComp' // 16 Oct 2024
import EtGratitudeTypeIcon1SVGComp from '@/assets/img/icons/svgComp/EtGratitudeTypeIcon1SVGComp' // 16 Oct 2024


import { createSvgComponentFromBase64 } from '@/utils/createSvgComponentFromBase64' // 09 Oct 2024

const EtUserAccomplishmentWeeklyList = ({userId, userOrgId}) => {
  // First Created - 16 Sep 2024
  // Author - Atul Pandey
  // Updated - 17 Sep 2024 - refined grouping of data - separated each weekly accomplishment report
  // Updated - 18 Sep 2024 - formatWeekRange, showWABActionSection
  // Updated - 18 Sep 2024 - added refresh and create mutation
  // Updated - 19 Sep 2024 - refined the badge bg color based on the badge value
  // Updated - 19 Sep 2024 - refined the maxProgressAction Logic to report progress for lagging action items
  // Updated - 20 Sep 2024 - added WAB Sections - conversations, creations, contributions, impacst, wins, challenges, intentions
  // Updated - 30 Sep 2024 - added all accomplishment actions for showing in all actions tab
  // Updated - 03 Oct 2024 - added the WABReflections, WABConversations
  // Updated - 10 Oct 2024 - added the WABCreations
  // Updated - 16 Oct 2024 - added the WABContributions
  // Updated - 16 Oct 2024 - added the WABImpacts
  // Updated - 16 Oct 2024 - added the WABWins
  // Updated - 16 Oct 2024 - added the WABChallenges
  // Updated - 16 Oct 2024 - added the WABIntentions
  // Updated - 17 Oct 2024 - added the WABGratitude
  // Updated - 22 Jan 2025 - refined the year display - separated by year tabs
  // Updated - 22 Jan 2024 - refined the actions display to filter "lagged!" and converted into a comp

  // 1a - Set Page Title EtSponsorUserPadList
  useTitle('User Weekly Accomplishment List')

  // 1b - set page navigations
  const navigate = useNavigate()

  // 1c - State variables
  const [showWABActionSection,setShowWABActionSection ] = useState({}) // 18 Sep 2024
  const [showWAB, setShowWAB] = useState({}) // 17 Sep 2024 - added top level to be a unique WAB report
  const [showWABReflections, setShowWABReflections] = useState({}) // 19 Sep 2024 - Reflections
  const [showWABConversations, setShowWABConversations] = useState({}) // 20 Sep 2024 - Conversations
  const [showWABCreations, setShowWABCreations] = useState({}) // 20 Sep 2024 - Creations
  const [showWABContributions, setShowWABContributions] = useState({}) // 20 Sep 2024 - Contributions
  const [showWABImpacts, setShowWABImpacts] = useState({}) // 20 Sep 2024 - Impacts
  const [showWABWins, setShowWABWins] = useState({}) // 20 Sep 2024 - Wins
  const [showWABChallenges, setShowWABChallenges] = useState({}) // 20 Sep 2024 - Challenges
  const [showWABIntentions, setShowWABIntentions] = useState({}) // 20 Sep 2024 - Intentions
  const [showWABGratitude, setShowWABGratitude] = useState({}) // 11 Oct 2024 - Gratitude
  const [selectedAccomplishmentId, setSelectedAccomplishmentId] = useState(null); // 30 Sep 2024 - for filtering all actions for a given accomplishment Id
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // 22 Jan 2025 to separate reports by year

  // 2a - Create RTK mutation - connected to refresh - 18 Sep 2024
  const [createNewUserAccomplishmentWeekly, {
    isLoading: isCreating, // Renaming for clarity
    isSuccess: isCreationSuccess,
    isError: isCreationError,
    error: creationError,
  }] = useCreateNewUserAccomplishmentWeeklyMutation();

  // 2b - Extract user accomplishment data
  const { data: userWeeklyAccomplishmentData, isError, error, isLoading, refetch} = useGetUserAccomplishmentWeeklysQuery()
  const userWeeklyAccomplishments = userWeeklyAccomplishmentData?.ids?.map((id) => {
    const userWeeklyAccomplishment = userWeeklyAccomplishmentData?.entities[id]
    return userWeeklyAccomplishment
  })
const filteredUserWeeklyAccomplishments = userWeeklyAccomplishments?.filter(item => item.userId._id === userId)
//console.log('user weekly accomplishments entities data:', userWeeklyAccomplishmentData)
//   console.log('user weekly accomplishments data:', userWeeklyAccomplishments)
// console.log('filtered user weekly accomplishments', filteredUserWeeklyAccomplishments)

// 2c - Extract the userInfo for the userId
const {data: usersData} = useGetUsersQuery()
const users = usersData?.ids?.map((id) => {
  return usersData?.entities[id]
})
const userInfo = users?.find(user => user._id === userId)
// const users = filteredUserWeeklyAccomplishments?.map((item => item.userId)) || {}
// const userInfo = users[0]
// console.log('userInfo', userInfo)

// 2d - Extract the contact data for the user - 02 Oct 2024
const { data: contactsData} = useGetContactsQuery()
const contacts = contactsData?.ids?.map((id) => contactsData?.entities[id])
//   console.log('contacts', contacts)

// 2d1: Filter contacts for the given userId
const filteredContacts = contacts?.filter((contact) => {
  const isOwner = contact.contactOwnerId._id === userId; // Check if the contactOwnerId matches the userId
  const isSharedWithUser = contact.sharedWith.some(
    (shared) => shared.userId._id === userId // Check if userId is in the sharedWith array
  );
  return isOwner || isSharedWithUser; // Return true if either condition is met
});

//   console.log('filteredContacts', filteredContacts);

// 2e - Extract the creation types - 05 Oct 2024
const { data: creationTypesData} = useGetCreationTypesQuery()
const creationTypes = creationTypesData?.ids?.map((id) => creationTypesData?.entities[id])
// console.log('creationTypes', creationTypes)

// 2f - Extract the contribution types - 10 Oct 2024
const { data: contributionTypesData} = useGetContributionTypesQuery()
const contributionTypes = contributionTypesData?.ids?.map((id) => contributionTypesData?.entities[id])
// console.log('contributionTypes', contributionTypes)

// 2g - Extract the impact types - 13 Oct 2024
const { data: impactTypesData} = useGetImpactTypesQuery()
const impactTypes = impactTypesData?.ids?.map((id) => impactTypesData?.entities[id])
// console.log('impactTypes', impactTypes)

// 2h - Extract the win types - 14 Oct 2024
const { data: winTypesData} = useGetWinTypesQuery()
const winTypes = winTypesData?.ids?.map((id) => winTypesData?.entities[id])
// console.log('winTypes', winTypes)

// 2i - Extract the user challenge types - 14 Oct 2024
const { data: userChallengeTypesData} = useGetUserChallengeTypesQuery()
const userChallengeTypes = userChallengeTypesData?.ids?.map((id) => userChallengeTypesData?.entities[id])
// console.log('userChallengeTypes', userChallengeTypes)

// 2j - Extract the intention types - 15 Oct 2024
const { data: intentionTypesData} = useGetIntentionTypesQuery()
const intentionTypes = intentionTypesData?.ids?.map((id) => intentionTypesData?.entities[id])
// console.log('intentionTypes', intentionTypes)

// 2k - Extract the gratitude types - 16 Oct 2024
const { data: gratitudeTypesData} = useGetGratitudeTypesQuery()
const gratitudeTypes = gratitudeTypesData?.ids?.map((id) => gratitudeTypesData?.entities[id])
// console.log('gratitudeTypes', gratitudeTypes)

// 3a - Group accomplishment action data by trail and session
const groupedAccomplishments = {};
filteredUserWeeklyAccomplishments?.forEach(accomplishment => {
    // 17 Sep 2024
    const accomplishmentId = accomplishment._id;
    if (!groupedAccomplishments[accomplishmentId]) {
        groupedAccomplishments[accomplishmentId] = {
            accomplishmentId,
            weekStarting: accomplishment.weekStarting,
            weekEnding: accomplishment.weekEnding,
            userAccomplishmentWeeklyName: accomplishment.userAccomplishmentWeeklyName,
            userId: accomplishment.userId,
            userReflectionNotes: accomplishment.userReflectionNotes || [] , // 03 Oct 2024
            userConversationNotes: accomplishment.userConversationNotes || [], // 03 Oct 2024
            userCreationWABNotes: accomplishment.userCreationWABNotes || [], // 10 Oct 2024
            userContributionNotes: accomplishment.userContributionNotes || [], // 10 Oct 2024 - for userContributions
            userImpactNotes: accomplishment.userImpactNotes || [], // 13 Oct 2024 - for userImpacts
            userWinNotes: accomplishment.userWinNotes || [], // 14 Oct 2024 - for userWins
            userChallengeWABNotes: accomplishment.userChallengeWABNotes || [], // 14 Oct 2024 - for userChallengeWABs
            userIntentionNotes: accomplishment.userIntentionNotes || [], // 15 Oct 2024 - for userIntentions
            userGratitudeNotes: accomplishment.userGratitudeNotes || [], // 16 Oct 2024 - for userGratitudes
            trails: {}
        };
    }

    // Group by trail and session within each accomplishment._id
    accomplishment.userAccomplishmentActions.forEach(action => {
        const trailId = action.trailId._id;
        if (!groupedAccomplishments[accomplishmentId].trails[trailId]) {
            groupedAccomplishments[accomplishmentId].trails[trailId] = {
                trailName: action.trailId.trailName,
                trailDesc: action.trailId.trailDesc,
                pic: action.trailId.pic,
                sessions: {}
            };
        }

        const sessionId = action.sessionId._id;
        if (!groupedAccomplishments[accomplishmentId].trails[trailId].sessions[sessionId]) {
            groupedAccomplishments[accomplishmentId].trails[trailId].sessions[sessionId] = {
                sessionName: action.sessionId.sessionName,
                sessionDesc: action.sessionId.sessionDesc,
                sessionSequence: action.sessionId.sessionSequence,
                sessionIntro: action.sessionId.sessionIntro,
                sessionOutro: action.sessionId.sessionOutro,
                pic: action.sessionId.pic,
                actions: []
            };
        }

        groupedAccomplishments[accomplishmentId].trails[trailId].sessions[sessionId].actions.push({
            userActionId: action.userActionId,
            userActionName: action.userActionName,
            userActionProgress: action.userActionProgress,
            userActionProgressTrail: action.userActionProgressTrail, // 17 Sep 2024 - the prior userActionProgress is now userActionProgressTrail
            userActionProgressBadge: action.userActionProgressBadge,
        });
    });
});

// 3b1 - Convert the grouped data object into an array
const groupedAccomplishmentsArray = Object.keys(groupedAccomplishments)?.map(accomplishmentId => {
    const accomplishment = groupedAccomplishments[accomplishmentId]
    return {
        accomplishmentId,
        weekStarting: accomplishment.weekStarting,
        weekEnding: accomplishment.weekEnding,
        userAccomplishmentWeeklyName: accomplishment.userAccomplishmentWeeklyName,
        userReflectionNotes: accomplishment.userReflectionNotes || [] , // 03 Oct 2024
        userConversationNotes: accomplishment.userConversationNotes || [], // 03 Oct 2024
        userCreationWABNotes: accomplishment.userCreationWABNotes || [], // 10 Oct 2024
        userContributionNotes: accomplishment.userContributionNotes || [], // 10 Oct 2024 - for userContributions
        userImpactNotes: accomplishment.userImpactNotes || [], // 13 Oct 2024 - for userImpacts
        userWinNotes: accomplishment.userWinNotes || [], // 14 Oct 2024 - for userWins
        userChallengeWABNotes: accomplishment.userChallengeWABNotes || [], // 14 Oct 2024 - for userChallengeWABs
        userIntentionNotes: accomplishment.userIntentionNotes || [], // 15 Oct 2024 - for userIntentions
        userGratitudeNotes: accomplishment.userGratitudeNotes || [], // 16 Oct 2024 - for userGratitudes
        userId:accomplishment.userId,
        trails: Object.keys(accomplishment.trails).map(trailId => {
            const trail = accomplishment.trails[trailId];
            return {
                trailId,
                trailName: trail.trailName,
                trailDesc: trail.trailDesc,
                pic: trail.pic,
                sessions: Object.keys(trail.sessions).map(sessionId => {
                    const session = trail.sessions[sessionId];
                    return {
                        sessionId,
                        sessionName: session.sessionName,
                        sessionDesc: session.sessionDesc,
                        sessionSequence: session.sessionSequence,
                        sessionIntro: session.sessionIntro,
                        sessionOutro: session.sessionOutro,
                        pic: session.pic,
                        actions: session.actions
                    };
                })
            }
        }),
    };
});
// console.log('Grouped Accomplishments Array:', groupedAccomplishmentsArray)

// 3b2 - Extract unique years from weekStarting -- 22 Jan 2025
const uniqueYears = Array.from(
    new Set(
        groupedAccomplishmentsArray.map(item => new Date(item.weekStarting).getFullYear())
    )
).sort((a, b) => b - a); // Sort years in descending order
// console.log('unique years', uniqueYears)

// 3b3 - Filter reports for the selected year -- 22 Jan 2025
const filteredAccomplishments = groupedAccomplishmentsArray.filter(
    item => new Date(item.weekStarting).getFullYear() === selectedYear
);

// console.log('filteredAccomplishments', filteredAccomplishments)

// 3c - Aggregate all actions into one array - 30 Sep 2024
const allAccomplishmentActions = [];
groupedAccomplishmentsArray.forEach((accomplishment) => {
  accomplishment?.trails?.forEach((trail) => {
    trail?.sessions?.forEach((session) => {
      session?.actions?.forEach((action) => {
        allAccomplishmentActions.push({
          accomplishmentId:accomplishment?.accomplishmentId,
          userActionId: action.userActionId,
          userActionName: action.userActionName,
          userActionProgress: action.userActionProgress,
          userActionProgressBadge: action.userActionProgressBadge,
          userActionProgressTrail: action.userActionProgressTrail, // Action progress trail for further details
        });
      });
    });
  });
});
// console.log("All Accomplishment Actions:", allAccomplishmentActions);

// 4a - Handle Toggle showWAB - 17 Sep 2024
    const handleToggleShowWAB = (accomplishmentId) => {
        setShowWAB((prev) => ({
            ...prev,
            [accomplishmentId]: !prev[accomplishmentId],
          }))
    }

// 4b - Handle Toggle showWABActionSection - 18 Sep 2024
    const handleToggleShowWABActionSection = (accomplishmentId) => {
        setShowWABActionSection((prev) => ({
            ...prev,
            [accomplishmentId]: !prev[accomplishmentId],
          }))
    }

// 4c - Handle Toggle showWABReflections - 19 Sep 2024
    const handleToggleShowWABReflections = (accomplishmentId) => {
        setShowWABReflections((prev) => ({
            ...prev,
            [accomplishmentId]: !prev[accomplishmentId],
          }))
    }

// 4d - Handle Toggle showWABConversations - 20 Sep 2024
const handleToggleShowWABConversations = (accomplishmentId) => {
        setShowWABConversations((prev) => ({
            ...prev,
            [accomplishmentId]: !prev[accomplishmentId],
          }))
    }

// 4e - Handle Toggle showWABCreations - 20 Sep 2024
const handleToggleShowWABCreations = (accomplishmentId) => {
        setShowWABCreations((prev) => ({
            ...prev,
            [accomplishmentId]: !prev[accomplishmentId],
          }))
    }

// 4f - Handle Toggle showWABContributions - 20 Sep 2024
const handleToggleShowWABContributions = (accomplishmentId) => {
        setShowWABContributions((prev) => ({
            ...prev,
            [accomplishmentId]: !prev[accomplishmentId],
          }))
    }

// 4g - Handle Toggle showWABImpacts - 20 Sep 2024
const handleToggleShowWABImpacts = (accomplishmentId) => {
        setShowWABImpacts((prev) => ({
            ...prev,
            [accomplishmentId]: !prev[accomplishmentId],
          }))
    }

// 4h - Handle Toggle showWABWins - 20 Sep 2024
const handleToggleShowWABWins = (accomplishmentId) => {
        setShowWABWins((prev) => ({
            ...prev,
            [accomplishmentId]: !prev[accomplishmentId],
          }))
    }

// 4i - Handle Toggle showWABChallenges - 20 Sep 2024
const handleToggleShowWABChallenges = (accomplishmentId) => {
        setShowWABChallenges((prev) => ({
            ...prev,
            [accomplishmentId]: !prev[accomplishmentId],
          }))
    }

// 4j - Handle Toggle showWABIntentions - 20 Sep 2024
const handleToggleShowWABIntentions = (accomplishmentId) => {
        setShowWABIntentions((prev) => ({
            ...prev,
            [accomplishmentId]: !prev[accomplishmentId],
          }))
    }

// 4k - Handle Toggle showWABGratitude - 11 Oct 2024
const handleToggleShowWABGratitude = (accomplishmentId) => {
    setShowWABGratitude((prev) => ({
        ...prev,
        [accomplishmentId]: !prev[accomplishmentId],
      }))
}

// 4l - Handle select accomplishment ID
const handleSelectAccomplishment = (accomplishmentId) => {
    setSelectedAccomplishmentId(accomplishmentId);
  };

// 5 - Define the accomplishmentWeeklyData
const accomplishmentWeeklyData = {
    userId,
    userOrgId,
  };

// 6 - onClick handler function
const handleCreateNewAccomplishment = async () => {
    try {
      // Trigger the mutation and await its result
      const result = await createNewUserAccomplishmentWeekly(accomplishmentWeeklyData).unwrap();
      
      if (isCreationSuccess) {
        // Refetch the accomplishment data on success
        refetch();
        // alert("New User Accomplishment Weekly created successfully!");
      }
    } catch (error) {
      console.error("Error creating accomplishment:", creationError);
      alert("Failed to create new accomplishment");
    }
  };

  return (
    // <div className='bg-gray-200 m-1 p-1 rounded font-poppins mx-auto xl:mx-auto xl:w-5/6'>
    <div className='bg-gray-200 m-1 p-1 rounded font-poppins mx-auto'>
        {/* Header */}
        {isCreating && <PulseLoader color={"#ea580c"} />}
          {isCreationError && <p className="text-red-500">{creationError?.message}</p>}
        <div className="flex justify-between items-center">
            <div className='flex justify-start items-center m-1 p-1'>
                    <div className="m-1 p-1 flex flex-col justify-center items-center">
                        <User username={userInfo?.username} defaultUserPic={userProfileIcon2}/>
                        <p className="m-1 p-1 font-bold text-xs text-gray-600">{userInfo?.name.split(' ')[0]}</p>
                    </div>
                    <div>
                        <div className="flex justify-start items-center mx-1 px-1 border-b border-white">  
                                <CalendarDaysIcon className="text-orange-600 h-7 w-7" />
                                {uniqueYears?.map((year)=>(
                                    <div key={year}
                                    onClick={() => setSelectedYear(year)}
                                    className={`flex items-center  font-bold mx-2 cursor-pointer ${
                                      selectedYear === year ? "text-orange-600" : "text-gray-400"
                                    }`}
                                    >
                                        <p className="font-bold text-2xl">{year}</p> 
                                    </div>
                                ))}
                                  
                        </div>
                        <p className='text-2xl mx-1 px-1 font-bold'>Weekly Accomplishments Wall</p>
                        <p className="text-xs text-gray-500 italic mx-1 px-1"> See how you have been unlocking & unleashing your magic!</p>
                    </div>       
            </div>
            <div className="relative group mx-2 px-2" onClick={handleCreateNewAccomplishment}>
                <div className="h-7 w-7 flex justify-center items-center rounded-full bg-gradient-to-r from-orange-600 to-orange-600 via-orange-800 hover:bg-gradient-to-r hover:from-orange-400 hover:via-orange-600 hover:to-black cursor-pointer shadow-md"
                
                >
                    <ArrowPathIcon className="h-5 w-5 text-white" />
                </div>
                {/* Tooltip */}
                <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-xs rounded py-1 px-2 z-10">
                    Refresh
                </div>
            </div>    
        </div>
        {/* Main */}
        {/* {groupedAccomplishmentsArray?.length>0 && groupedAccomplishmentsArray */}
        {filteredAccomplishments?.length>0 && filteredAccomplishments
        ?.slice()
        .sort((a, b) => new Date(b.weekStarting) - new Date(a.weekStarting)) // Sort by weekStarting in descending order
        .map((item, itemIndex) =>{

            const {accomplishmentId, userReflectionNotes} = item
            // console.log('accomplishmentId',accomplishmentId)
            // console.log('userReflectionNote Id',userReflectionNotes?.filter((note) => note.userReflectionNoteId?.weekStarting === item.weekStarting))

            return(
                <div key={item.accomplishmentId+itemIndex} className="m-1 p-1 font-poppins bg-white rounded"
                onClick={() => handleSelectAccomplishment(item.accomplishmentId)} >
                    {/* WAB Header */}
                    <div className="m-1 p-1 flex justify-start items-center  ">
                        <div className="flex justify-start items-center m-1 p-1">
                            <div
                                onClick={() => handleToggleShowWABActionSection(item.accomplishmentId)}
                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                >
                                {showWABActionSection[item.accomplishmentId] ? (
                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                )}
                            </div>
                            
                        </div>
                        <div className="flex justify-start items-center">
                            <div>
                                <p className="text-2xl font-bold text-gray-600 mx-1 px-1">{itemIndex+1}.</p>
                            </div>
                            <div>
                                <p className="mx-1 px-1 font-bold text-gray-600" >Week of:</p>
                            </div>
                            <div>
                                <WeekRangeComponent weekStarting={item.weekStarting} weekEnding={item.weekEnding}/>
                            </div>
                        </div>
                    </div>
                    
                    {showWABActionSection[item.accomplishmentId] && (                    
                    <div className="">
                        {/* Section 1 - Actions */}
                        <div className="m-1 p-1 bg-gray-100 rounded">
                            <div className="flex justify-start items-center">
                                <div
                                    onClick={() => handleToggleShowWAB(item.accomplishmentId)}
                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                    >
                                    {showWAB[item.accomplishmentId] ? (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                </div>
                                <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => handleToggleShowWAB(item.accomplishmentId)}><EtWabActionIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                                <div className="m-1 p-1">
                                    <p className="mx-1 px-1 font-bold text-gray-600 text-sm">Actions:</p>
                                    <p className="mx-1 px-1  text-gray-500 text-[10px] italic">Did I toil enough? My week in action!</p>
                                </div>
                            </div>
                            {showWAB[item.accomplishmentId] && (
                            <>
                            {/* Section 1 - Action Component */}
                            <WABActions 
                                allAccomplishmentActions={allAccomplishmentActions} // 30 Sep 2024 - added for all actions
                                accomplishmentActions={item.trails} 
                                startOfWeek={item.weekStarting} 
                                endOfWeek={item.weekEnding} filteredUserWeeklyAccomplishments={filteredUserWeeklyAccomplishments}
                                selectedAccomplishmentId={selectedAccomplishmentId}
                            />
                            </>
                            )} 
                        </div>

                         {/* Section 2 - Weekly Reflections - 19 Sep 2024*/}   
                        <div className="m-1 p-1 bg-gray-100 rounded">   
                        <div className="flex justify-start items-center">
                            <div
                                onClick={() => handleToggleShowWABReflections(item.accomplishmentId)}
                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                >
                                {showWABReflections[item.accomplishmentId] ? (
                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                )}
                            </div>
                            <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => handleToggleShowWABReflections(item.accomplishmentId)}><EtWabReflectionIcon1SVGComp className='h-8 w-8 text-orange-600'/></div>
                            <div className="m-1 p-1">
                                <p className="mx-1 px-1 font-bold text-gray-600 text-sm">Reflections:</p>
                                <p className="mx-1 px-1  text-gray-500 text-[10px] italic">What was this week for me? My learning this week!</p>
                            </div>
                        </div>
                        {showWABReflections[item.accomplishmentId] && (
                            <>
                            {/* Section 2 - Reflections Component */}
                            <WABReflections 
                                userReflectionNotes={item?.userReflectionNotes|| []}
                                startOfWeek={item.weekStarting} 
                                endOfWeek={item.weekEnding} 
                                userId={userId}
                                userOrgId={userOrgId}
                            />
                            </>
                        )}        
                        </div>

                         {/* Section 3 - Weekly Conversations - 20 Sep 2024*/}   
                        <div className="m-1 p-1 bg-gray-100 rounded">   
                        <div className="flex justify-start items-center">
                            <div
                                onClick={() => handleToggleShowWABConversations(item.accomplishmentId)}
                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                >
                                {showWABConversations[item.accomplishmentId] ? (
                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                )}
                            </div>
                            <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => handleToggleShowWABConversations(item.accomplishmentId)}><EtWabConversationIcon1SVGComp className='h-8 w-8 text-orange-600'/></div>
                            <div className="m-1 p-1">
                                <p className="mx-1 px-1 font-bold text-gray-600 text-sm">Conversations:</p>
                                <p className="mx-1 px-1  text-gray-500 text-[10px] italic">Who did I engage with? My discussions this week!</p>
                            </div>
                        </div>
                        {showWABConversations[item.accomplishmentId] && (
                            <>
                            {/* Section 3 - Conversations Component */}
                            <WABConversations 
                                userConversationNotes={item?.userConversationNotes || [] }
                                startOfWeek={item?.weekStarting} 
                                endOfWeek={item?.weekEnding} 
                                userId={userId}
                                userOrgId={userOrgId}
                                conversationContacts={filteredContacts}
                            />
                            </>
                        )}        
                        </div>

                         {/* Section 4 - Weekly Creations - 20 Sep 2024*/}   
                        <div className="m-1 p-1 bg-gray-100 rounded">   
                        <div className="flex justify-start items-center">
                            <div
                                onClick={() => handleToggleShowWABCreations(item.accomplishmentId)}
                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                >
                                {showWABCreations[item.accomplishmentId] ? (
                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                )}
                            </div>
                            <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => handleToggleShowWABCreations(item.accomplishmentId)}><EtWabCreationIcon1SVGComp className='h-8 w-8 text-orange-600'/></div>
                            <div className="m-1 p-1">
                                <p className="mx-1 px-1 font-bold text-gray-600 text-sm">Creations:</p>
                                <p className="mx-1 px-1  text-gray-500 text-[10px] italic">What emerged from my experiments? My creative imprint this week!
                                </p>
                            </div>
                        </div>
                        {showWABCreations[item.accomplishmentId] && (
                            <>
                            {/* Section 4 - Creations Component */}
                            <WABCreations 
                                userCreationWABNotes={item?.userCreationWABNotes}
                                startOfWeek={item?.weekStarting} 
                                endOfWeek={item?.weekEnding} 
                                userId={userId}
                                userOrgId={userOrgId}
                                creationTypes={creationTypes}
                            />
                            </>
                        )}        
                        </div>

                         {/* Section 5 - Weekly Contributions - 20 Sep 2024*/}   
                        <div className="m-1 p-1 bg-gray-100 rounded">   
                        <div className="flex justify-start items-center">
                            <div
                                onClick={() => handleToggleShowWABContributions(item.accomplishmentId)}
                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                >
                                {showWABContributions[item.accomplishmentId] ? (
                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                )}
                            </div>
                            <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => handleToggleShowWABContributions(item.accomplishmentId)}><EtWabContributionIcon1SVGComp className='h-8 w-8 text-orange-600'/></div>
                            <div className="m-1 p-1">
                                <p className="mx-1 px-1 font-bold text-gray-600 text-sm">Contributions:</p>
                                <p className="mx-1 px-1  text-gray-500 text-[10px] italic">What did I do for my stakeholders? My contributions this week!</p>
                            </div>
                        </div>
                        {showWABContributions[item.accomplishmentId] && (
                            <>
                            {/* Section 5 - Contributions Component */}
                            <WABContributions 
                                userContributionNotes={item?.userContributionNotes || [] }
                                startOfWeek={item?.weekStarting} 
                                endOfWeek={item?.weekEnding} 
                                userId={userId}
                                userOrgId={userOrgId}
                                contributionContacts={filteredContacts}
                                contributionTypes={contributionTypes}
                            />
                            </>
                        )}        
                        </div> 

                        {/* Section 6 - Weekly Impacts - 20 Sep 2024*/}   
                        <div className="m-1 p-1 bg-gray-100 rounded">   
                        <div className="flex justify-start items-center">
                            <div
                                onClick={() => handleToggleShowWABImpacts(item.accomplishmentId)}
                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                >
                                {showWABImpacts[item.accomplishmentId] ? (
                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                )}
                            </div>
                            <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => handleToggleShowWABImpacts(item.accomplishmentId)}><EtWabImpactIcon1SVGComp className='h-8 w-8 text-orange-600'/></div>
                            <div className="m-1 p-1">
                                <p className="mx-1 px-1 font-bold text-gray-600 text-sm">Impacts:</p>
                                <p className="mx-1 px-1  text-gray-500 text-[10px] italic">What difference did I make? My impacts this week!</p>
                            </div>
                        </div>
                        {showWABImpacts[item.accomplishmentId] && (
                            <>
                            {/* Section 6 - Impacts Component */}
                            <WABImpacts 
                                userImpactNotes={item?.userImpactNotes || [] }
                                startOfWeek={item?.weekStarting} 
                                endOfWeek={item?.weekEnding} 
                                userId={userId}
                                userOrgId={userOrgId}
                                impactContacts={filteredContacts}
                                impactTypes={impactTypes}
                            />
                            </>
                        )}        
                        </div> 
                        
                        {/* Section 7 - Weekly Wins - 20 Sep 2024*/}   
                        <div className="m-1 p-1 bg-gray-100 rounded">   
                        <div className="flex justify-start items-center">
                            <div
                                onClick={() => handleToggleShowWABWins(item.accomplishmentId)}
                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                >
                                {showWABWins[item.accomplishmentId] ? (
                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                )}
                            </div>
                            <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => handleToggleShowWABWins(item.accomplishmentId)}><EtWabWinIcon1SVGComp className='h-8 w-8 text-orange-600'/></div>
                            <div className="m-1 p-1">
                                <p className="mx-1 px-1 font-bold text-gray-600 text-sm">Wins:</p>
                                <p className="mx-1 px-1  text-gray-500 text-[10px] italic">What made this week special? My wins & accolades this week!</p>
                            </div>
                        </div>
                        {showWABWins[item.accomplishmentId] && (
                            <>
                            {/* Section 7 - Wins Component */}
                            <WABWins 
                                userWinNotes={item?.userWinNotes || [] }
                                startOfWeek={item?.weekStarting} 
                                endOfWeek={item?.weekEnding} 
                                userId={userId}
                                userOrgId={userOrgId}
                                winContacts={filteredContacts}
                                winTypes={winTypes}
                            />
                            </>
                        )}        
                        </div> 

                        {/* Section 8 - Weekly Challenges - 20 Sep 2024*/}   
                        <div className="m-1 p-1 bg-gray-100 rounded">   
                        <div className="flex justify-start items-center">
                            <div
                                onClick={() => handleToggleShowWABChallenges(item.accomplishmentId)}
                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                >
                                {showWABChallenges[item.accomplishmentId] ? (
                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                )}
                            </div>
                            <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => handleToggleShowWABChallenges(item.accomplishmentId)}><EtWabChallengeIcon1SVGComp className='h-8 w-8 text-orange-600'/></div>
                            <div className="m-1 p-1">
                                <p className="mx-1 px-1 font-bold text-gray-600 text-sm">Challenges:</p>
                                <p className="mx-1 px-1  text-gray-500 text-[10px] italic">What tested my persistence? My challenges this week!</p>
                            </div>
                        </div>
                        {showWABChallenges[item.accomplishmentId] && (
                            <>
                            {/* Section 8 - Challenges Component */}
                            <WABChallenges 
                                userChallengeWABNotes={item?.userChallengeWABNotes || [] }
                                startOfWeek={item?.weekStarting} 
                                endOfWeek={item?.weekEnding} 
                                userId={userId}
                                userOrgId={userOrgId}
                                userChallengeContacts={filteredContacts}
                                userChallengeTypes={userChallengeTypes}
                            />
                            </>
                        )}        
                        </div> 

                        {/* Section 9 - Weekly Intentions - 20 Sep 2024*/}   
                        <div className="m-1 p-1 bg-gray-100 rounded">   
                        <div className="flex justify-start items-center">
                            <div
                                onClick={() => handleToggleShowWABIntentions(item.accomplishmentId)}
                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                >
                                {showWABIntentions[item.accomplishmentId] ? (
                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                )}
                            </div>
                            <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => handleToggleShowWABIntentions(item.accomplishmentId)}><EtWabIntentionIcon1SVGComp className='h-8 w-8 text-orange-600'/></div>
                            <div className="m-1 p-1">
                                <p className="mx-1 px-1 font-bold text-gray-600 text-sm">Intentions:</p>
                                <p className="mx-1 px-1  text-gray-500 text-[10px] italic">What frontiers I have to win ahead? My missions next week!</p>
                            </div>
                        </div>
                        {showWABIntentions[item.accomplishmentId] && (
                            <>
                            {/* Section 9 - Intentions Component */}
                            <WABIntentions 
                                userIntentionNotes={item?.userIntentionNotes || [] }
                                startOfWeek={item?.weekStarting} 
                                endOfWeek={item?.weekEnding} 
                                userId={userId}
                                userOrgId={userOrgId}
                                intentionContacts={filteredContacts}
                                intentionTypes={intentionTypes}
                            />
                            </>
                        )}        
                        </div> 
                        
                        {/* Section 10 - Weekly Gratitudes - 20 Sep 2024*/}   
                        <div className="m-1 p-1 bg-gray-100 rounded">   
                        <div className="flex justify-start items-center">
                            <div
                                onClick={() => handleToggleShowWABGratitude(item.accomplishmentId)}
                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                >
                                {showWABGratitude[item.accomplishmentId] ? (
                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                )}
                            </div>
                            <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => handleToggleShowWABGratitude(item.accomplishmentId)}><EtWabGratitudeIcon1SVGComp className='h-8 w-8 text-orange-600'/></div>
                            <div className="m-1 p-1">
                                <p className="mx-1 px-1 font-bold text-gray-600 text-sm">Gratitudes:</p>
                                <p className="mx-1 px-1  text-gray-500 text-[10px] italic">Who and what I am thankful for? My gratitudes this week!</p>
                            </div>
                        </div>
                        {showWABGratitude[item.accomplishmentId] && (
                            <>
                            {/* Section 10 - Gratitudes Component */}
                            <WABGratitudes 
                                userGratitudeNotes={item?.userGratitudeNotes || [] }
                                startOfWeek={item?.weekStarting} 
                                endOfWeek={item?.weekEnding} 
                                userId={userId}
                                userOrgId={userOrgId}
                                gratitudeContacts={filteredContacts}
                                gratitudeTypes={gratitudeTypes}
                            />
                            </>
                        )}        
                        </div> 

                    </div>)}  
                </div>
            )
        })}

    </div>
  )
}

const WABActions = ({allAccomplishmentActions, accomplishmentActions, startOfWeek, endOfWeek, filteredUserWeeklyAccomplishments, selectedAccomplishmentId}) => {
    // First Created - 16 Sep 2024
    // Author - Atul Pandey
    // Updated - 17 Sep 2024 - refined group structure - WAB
    // Updated - 30 Sep 2024 - added active tabs for showing all actions, by trail actions
    // 

    // console.log('Accomplishment Actions:', accomplishmentActions)

    // 1 - Set the state variables
    
    const [showWABTrail, setShowWABTrail] = useState({})
    const [showWABTrailSession, setShowWABTrailSession] = useState({})
    const [showWABTrailSessionAction, setShowWABTrailSessionAction] = useState({})
    const [activeTab, setActiveTab] = useState("allActions") // 30 Sep 2024
    const [showActionProgress,setShowActionProgress] = useState({}) // 30 Sep 2024

    // 2a - Handle Toggle showWABTrail
    const handleToggleShowWABTrail = (trailId) => {
        setShowWABTrail((prev) => ({
            ...prev,
            [trailId]: !prev[trailId],
          }))
    }
    // 2b - Handle Toggle showWABTrailSession
    const handleToggleShowWABTrailSession = (trailId) => {
        setShowWABTrailSession((prev) => ({
            ...prev,
            [trailId]: !prev[trailId],
          }))
    }
    // 2c - Handle Toggle showWABTrailSessionAction
    const handleToggleShowWABTrailSessionAction = (trailId) => {
        setShowWABTrailSessionAction((prev) => ({
            ...prev,
            [trailId]: !prev[trailId],
          }))
    }

     // 2d - Handle Toggle showActionProgress - 30 Sep 2024
     const handleToggleShowActionProgress = (actionId) => {
        setShowActionProgress((prev) => ({
          ...prev,
          [actionId]: !prev[actionId],
        }));
      };
  
      const getBadgeClass = (badge) => {
          switch (badge) {
            case "Accomplished!":
              return "bg-lime-600 text-white";
            case "Moved!":
              return "bg-lime-400";
            case "Persisted!":
              return "bg-yellow-300";
            case "Lagged!":
              return "bg-red-600 text-white";
            default:
              return "bg-gray-200"; // Default case if no badge matches
          }
        };
    // 3 - Filter the actions based on the current accomplishment week (accomplishmentId)
    const filteredActionsByAccomplishment = allAccomplishmentActions?.filter(action => 
    action?.accomplishmentId === selectedAccomplishmentId
    );

    
    return(
        <>
        {/* Tabs to navigate between All Actions and Actions by Trail */}
        <div className="mx-1 px-1 my-2 py-2 rounded bg-gray-50 shadow font-poppins">
            <div className="flex justify-start items-center">         
                <div
                    onClick={() => setActiveTab("allActions")}
                    className={`flex justify-start items-center text-gray-400 font-bold mx-2 cursor-pointer ${
                    activeTab === "allActions" ? "text-orange-600 underline" : ""
                    }`}
                >
                    <Squares2X2Icon className="h-6 w-6" />
                    <p className="mx-1 px-1">Acted &...</p>
                </div>
                <div
                    onClick={() => setActiveTab("byTrail")}
                    className={`flex justify-start items-center text-gray-400 mx-2 font-bold cursor-pointer ${
                    activeTab === "byTrail" ? "text-orange-600 underline" : ""
                    }`}
                >
                    <ArrowTrendingUpIcon className="h-6 w-6" />
                    <p className="mx-1 px-1">By Trail</p>
                </div>
            </div>      
        </div>

        {activeTab === "allActions" && (
            <div>
            {/* Display all actions with progress */}
            {filteredActionsByAccomplishment
            ?.filter(item => item.userActionProgressBadge !== 'Lagged!') // 22 Jan 2025
            ?.map((action, actionIndex) => (
                <div key={action.userActionId + actionIndex} className="m-1 p-1 bg-white rounded">

                    <div className="grid grid-cols-12 justify-start items-center gap-2">
                        {/* Action Name */}
                        <div className="col-span-7 flex justify-start items-center">
                            <div
                            onClick={() => handleToggleShowActionProgress(action.userActionId)}
                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                            >
                                {showActionProgress[action.userActionId] ? (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                )}
                            </div>
                            <div>
                            <p className="mx-1 px-1 text-2xl font-bold text-gray-600">{actionIndex + 1}.</p>
                            </div>
                            <div>
                            <p className="text-[10px] mx-1 px-1">{action.userActionName}</p>
                            </div>
                        </div>
                        {/* Progress Text */}
                        <div className="col-span-1">
                            <p className="mx-1 px-1 text-[9px] text-gray-500">Progress:</p>
                        </div>
                        {/* Progress Bar */}
                        <div className="col-span-2">
                        <div className="w-full">
                                {action.userActionProgress < 10 ? (
                                    <div className="rounded-full bg-gray-300 relative">
                                        <div
                                        className="rounded-full bg-[#552a9a]"
                                        style={{
                                            width: `${action.userActionProgress}%`,
                                            height: "40%",
                                        }}
                                        ></div>
                                        <p className="text-[8px] text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                        {action.userActionProgress}%
                                        </p>
                                    </div>
                                    ) : (
                                    <div className="rounded-full bg-gray-300">
                                        <div className="flex justify-between items-center">
                                        <div
                                            className="rounded-full bg-[#552a9a] flex justify-center items-center"
                                            style={{ width: `${action.userActionProgress}%` }}
                                        >
                                            <p className="text-[8px] text-white">
                                            {action.userActionProgress}%
                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                    )}
                                </div>
                        </div>
                        {/* Progress Badge */}
                        <div className="col-span-2 text-center mx-1 px-1">
                            <div className={`text-[10px] px-2 py-[1px] rounded-full inline-block ${getBadgeClass(action.userActionProgressBadge)}`}>
                                {action.userActionProgressBadge}
                            </div>
                        </div>
                    </div>
                    {showActionProgress[action.userActionId] && (
                        <div>
                        <div className="m-1 p-1 bg-gray-200 rounded">
                            <div><p className="m-1 p-1 font-bold text-xs text-gray-600">Progress this week:</p></div>
                        </div>
                        {action
                        ?.userActionProgressTrail
                        .slice()
                        .sort(
                            (a, b) =>
                                new Date(b.actionNoteDate) -
                                new Date(a.actionNoteDate)
                        )
                        .map((progress, progressIndex)=> {
                            return(
                                <div key={progress._id+progressIndex} className="bg-gray-100 m-1 p-1">
                                    <div className="grid grid-cols-10 justify-between items-center m-1 p-1 w-full">
                                    <div className="col-span-5 flex justify-start items-center">
                                        <div>
                                        <p className="font-bold text-lg mx-1 px-1 text-gray-400">
                                            {progressIndex + 1}.
                                        </p>
                                        </div>
                                        <div>
                                        <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-18">
                                            {getTimeSince(progress?.actionNoteDate)}
                                        </p>
                                        </div>
                                        <div>
                                        <p className="text-[9px] mx-1 py-[1px]">
                                            {progress?.actionNoteText}
                                        </p>
                                        </div>
                                    </div>

                                    <div className="col-span-1">
                                        <p className="text-[9px] text-gray-600">
                                        Action Progress:
                                        </p>
                                    </div>

                                    {/* 11 Jun 2024 - action progress bar for each note */}

                                    <div className="col-span-2 gap-4">
                                        {progress?.actionNoteProgress < 10 ? (
                                        <div className="rounded-full bg-gray-300 relative">
                                            <div
                                            className="rounded-full bg-[#552a9a]"
                                            style={{
                                                width: `${progress?.actionNoteProgress}%`,
                                                height: "45%",
                                            }}
                                            ></div>
                                            <p className="text-[8px] text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                            {progress?.actionNoteProgress}%
                                            </p>
                                        </div>
                                        ) : (
                                        <div className="rounded-full bg-gray-300">
                                            <div className="flex justify-between items-center">
                                            <div
                                                className="rounded-full bg-[#552a9a] flex justify-center items-center"
                                                style={{
                                                width: `${progress?.actionNoteProgress}%`,
                                                }}
                                            >
                                                <p className="text-[8px] text-white">
                                                {progress?.actionNoteProgress}%
                                                </p>
                                            </div>
                                            </div>
                                        </div>
                                        )}
                                    </div>

                                    <div className="col-span-2 flex justify-start items-center">
                                    <div className="ml-2 pl-2 pr-1"><ClockIcon className="h-5 w-5 text-lime-500" /></div>
                                        <div className=" w-full">
                                        <p className="text-[9px] text-gray-400 rounded-full">
                                            {formatDateTime(progress?.actionNoteDate)}
                                        </p>
                                        </div>
                                    </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>

                    )}

                </div>
            ))}
            </div>
        )}
        
        {activeTab === "byTrail" && (
            <div>
                {accomplishmentActions?.map((action, actionIndex) => {
            return(
                <div key={action._id+actionIndex} className="bg-white rounded m-1 p-1">
                    {/* Show Trail */}
                    <div className="flex justify-start items-center m-1 p-1">
                        <div
                            onClick={() => handleToggleShowWABTrail(action.trailId)}
                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                            >
                            {showWABTrail[action.trailId] ? (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                            )}
                        </div>
                        <div className="flex justify-center items-center h-7 w-7 bg-gray-200 hover:bg-white rounded-full"><img src={action.pic} className="h-7 w-7 p-1" /></div>
                        <div><p className="text-xs m-1 p-1"><span className="font-bold text-gray-600">Trail</span> {action.trailName}</p></div>
                    </div>
                    {showWABTrail[action.trailId] && action.sessions?.length >0 && action.sessions?.map((session, sessionIndex) => {
                        return(
                            <div key={session.sessionId + sessionIndex} className="m-1 p-1 bg-gray-200 rounded">
                                {/* Show Trail Session */}
                                <div className="flex justify-start items-center m-1 p-1">
                                    <div
                                        onClick={() => handleToggleShowWABTrailSession(session.sessionId)}
                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                        >
                                        {showWABTrailSession[session.sessionId] ? (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                        )}
                                    </div>
                                    <div className="flex justify-center items-center h-7 w-7 bg-gray-100 hover:bg-white rounded-full"><img src={session.pic} className="h-7 w-7 p-1" /></div>
                                    <div><p className="text-xs m-1 p-1 font-bold"><span className="text-[10px] text-gray-400 font-normal">Session: </span> {session.sessionName}</p></div>
                                </div>
                                {showWABTrailSession[session.sessionId] && session.actions?.length>0 && session.actions?.map((action, actionIndex)=> {
                                    // 17 Sep 2024 - Calculate the maximum actionNoteProgress
                                    // const maxActionProgress = Math.max(
                                    //     ...action?.userActionProgressTrail?.map(progress => progress?.actionNoteProgress),
                                    //     0 // In case the array is empty, ensure there's a default value
                                    // );
                                    // console.log('max action progress', action.userActionName, ':', maxActionProgress)
                                    
                                    // 19 Sep 2024 - Step to Calculate the maximum actionNoteProgress
                                        let maxActionProgress;
                                        const notesFromCurrentWeek = action?.userActionProgressTrail?.filter(note => 
                                            note?.actionNoteDate >= startOfWeek && note?.actionNoteDate <= endOfWeek
                                        );
                                        // console.log('Notes from Current Week:', notesFromCurrentWeek, 'Count:', notesFromCurrentWeek?.length);

                                        // Helper function to find the last note's progress from previous accomplishments
                                        const findLastNoteProgressFromPreviousWeeks = (actionId, currentWeekStart) => {
                                            let lastProgress = 0;
                                            // Traverse back through each previous week's accomplishment
                                            for (let i = filteredUserWeeklyAccomplishments.length - 1; i >= 0; i--) {
                                                const prevAccomplishment = filteredUserWeeklyAccomplishments[i];
                                                if (prevAccomplishment.weekStarting < currentWeekStart) {
                                                    const actionInPrevWeek = prevAccomplishment?.userAccomplishmentActions?.find(
                                                        a => a.userActionId === actionId
                                                    );
                                                    if (actionInPrevWeek) {
                                                        const lastNote = actionInPrevWeek?.userActionProgressTrail
                                                            ?.filter(note => note?.actionNoteDate < currentWeekStart)
                                                            ?.sort((a, b) => new Date(b.actionNoteDate) - new Date(a.actionNoteDate))[0];
                                                        if (lastNote) {
                                                            lastProgress = lastNote?.actionNoteProgress;
                                                            break; // Exit loop once we find the last progress
                                                        }
                                                    }
                                                }
                                            }
                                            return lastProgress;
                                        };

                                        // If there are no notes in the current week, look back at prior weeks for progress
                                        if (notesFromCurrentWeek?.length === 0) {
                                            const lastNoteProgress = findLastNoteProgressFromPreviousWeeks(action.userActionId, startOfWeek);
                                            // If no previous progress found, default to 0
                                            maxActionProgress = lastNoteProgress || 0;
                                        } else {
                                            // If there are notes in the current week, calculate the maximum progress
                                            maxActionProgress = Math.max(
                                                ...notesFromCurrentWeek?.map(progress => progress?.actionNoteProgress),
                                                0 // In case the array is empty, ensure there's a default value
                                            );
                                        }

                                    // console.log('maxActionProgress', maxActionProgress)

                                    return(
                                        <div key={action.userActionId+actionIndex} className="bg-gray-50 m-1 p-1 rounded">
                                            {/* Show Trail Session Action & Progress */}
                                            <div className="grid grid-cols-12 justify-start items-center gap-2">
                                                <div className="col-span-7 flex justify-start items-center">
                                                    <div
                                                        onClick={() => handleToggleShowWABTrailSessionAction(action.userActionId)}
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                        >
                                                        {showWABTrailSessionAction[action.userActionId] ? (
                                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                                        )}
                                                    </div>
                                                    <div><p className="mx-1 px-1 text-2xl font-bold text-gray-600">{actionIndex+1}.</p></div>
                                                    <div><p className="text-[10px] mx-1 px-1">{action.userActionName}</p></div>
                                                </div>
                                                <div className="col-span-1"><p className="mx-1 px-1 text-[9px] text-gray-500">Progress:</p></div>
                                                <div className="col-span-2">
                                                    
                                                    <div className="w-full">
                                                    {maxActionProgress < 10 ? (
                                                        <div className="rounded-full bg-gray-300 relative">
                                                            <div
                                                            className="rounded-full bg-[#552a9a]"
                                                            style={{
                                                                width: `${maxActionProgress}%`,
                                                                height: "40%",
                                                            }}
                                                            ></div>
                                                            <p className="text-[8px] text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                            {maxActionProgress}%
                                                            </p>
                                                        </div>
                                                        ) : (
                                                        <div className="rounded-full bg-gray-300">
                                                            <div className="flex justify-between items-center">
                                                            <div
                                                                className="rounded-full bg-[#552a9a] flex justify-center items-center"
                                                                style={{ width: `${maxActionProgress}%` }}
                                                            >
                                                                <p className="text-[8px] text-white">
                                                                {maxActionProgress}%
                                                                </p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        )}
                                                    </div>

                                                </div>
                                                <div className="col-span-2 text-center mx-1 px-1">
                                                    <div>
                                                        {/* <p className="text-[10px] bg-lime-400 inline-block px-2 rounded-full text-gray-600">{action.userActionProgressBadge}</p> */}
                                                        {/* 19 Sep 2024 - added different colors for badges */}
                                                        <p className={`text-[10px] inline-block px-2 rounded-full text-gray-600 
                                                        ${action.userActionProgressBadge === "Accomplished!" ? "bg-lime-600 text-white" : ""}
                                                        ${action.userActionProgressBadge === "Moved!" ? "bg-lime-400" : ""}
                                                        ${action.userActionProgressBadge === "Persisted!" ? "bg-yellow-300" : ""}
                                                        ${action.userActionProgressBadge === "Lagged!" ? "bg-red-600 text-white" : ""}`}>
                                                        {action.userActionProgressBadge}
                                                    </p>
                                                    </div>
                                                </div>
                                            </div>
                                            {showWABTrailSessionAction[action.userActionId] && (
                                                <div>
                                                    <div className="m-1 p-1 bg-gray-200 rounded">
                                                        <div><p className="m-1 p-1 font-bold text-xs text-gray-600">Progress this week:</p></div>
                                                    </div>
                                                    {action
                                                    ?.userActionProgressTrail
                                                    .slice()
                                                    .sort(
                                                        (a, b) =>
                                                            new Date(b.actionNoteDate) -
                                                            new Date(a.actionNoteDate)
                                                    )
                                                    .map((progress, progressIndex)=> {
                                                        return(
                                                            <div key={progress._id+progressIndex} className="bg-gray-100 m-1 p-1">
                                                                <div className="grid grid-cols-10 justify-between items-center m-1 p-1 w-full">
                                                                <div className="col-span-5 flex justify-start items-center">
                                                                    <div>
                                                                    <p className="font-bold text-lg mx-1 px-1 text-gray-400">
                                                                        {progressIndex + 1}.
                                                                    </p>
                                                                    </div>
                                                                    <div>
                                                                    <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-18">
                                                                        {getTimeSince(progress?.actionNoteDate)}
                                                                    </p>
                                                                    </div>
                                                                    <div>
                                                                    <p className="text-[9px] mx-1 py-[1px]">
                                                                        {progress?.actionNoteText}
                                                                    </p>
                                                                    </div>
                                                                </div>

                                                                <div className="col-span-1">
                                                                    <p className="text-[9px] text-gray-600">
                                                                    Action Progress:
                                                                    </p>
                                                                </div>

                                                                {/* 11 Jun 2024 - action progress bar for each note */}

                                                                <div className="col-span-2 gap-4">
                                                                    {progress?.actionNoteProgress < 10 ? (
                                                                    <div className="rounded-full bg-gray-300 relative">
                                                                        <div
                                                                        className="rounded-full bg-[#552a9a]"
                                                                        style={{
                                                                            width: `${progress?.actionNoteProgress}%`,
                                                                            height: "45%",
                                                                        }}
                                                                        ></div>
                                                                        <p className="text-[8px] text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                                        {progress?.actionNoteProgress}%
                                                                        </p>
                                                                    </div>
                                                                    ) : (
                                                                    <div className="rounded-full bg-gray-300">
                                                                        <div className="flex justify-between items-center">
                                                                        <div
                                                                            className="rounded-full bg-[#552a9a] flex justify-center items-center"
                                                                            style={{
                                                                            width: `${progress?.actionNoteProgress}%`,
                                                                            }}
                                                                        >
                                                                            <p className="text-[8px] text-white">
                                                                            {progress?.actionNoteProgress}%
                                                                            </p>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    )}
                                                                </div>

                                                                <div className="col-span-2 flex justify-start items-center">
                                                                <div className="ml-2 pl-2 pr-1"><ClockIcon className="h-5 w-5 text-lime-500" /></div>
                                                                    <div className=" w-full">
                                                                    <p className="text-[9px] text-gray-400 rounded-full">
                                                                        {formatDateTime(progress?.actionNoteDate)}
                                                                    </p>
                                                                    </div>
                                                                </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )}

                                        </div>
                                    )
                                })}


                            </div>
                        )
                    })}

                </div>
            )
                })}
            </div>
        )} 

        </>
    )

}

const WABReflections = ({userReflectionNotes = [],startOfWeek, endOfWeek, userId, userOrgId}) => {
    // First Created - 22 Sep 2024
    // Author - Atul Pandey
    // Updated - 25 Sep 2024 - refined for creating / updating reflections via form
    // Updated - 27 Sep 2024 - refinements for showing / toggling reflection details

     // 0 - Validate incoming data:
    // console.log('Current userReflectionNotes', userReflectionNotes)

    const filteredUserReflectionNotes = userReflectionNotes[0]?.userReflectionNoteId?.userReflectionNotes || []

    // 1a - State to manage reflections list (using the provided props)
    const [showReflectionNoteDetails, setShowReflectionNoteDetails] = useState({}) // 27 Sep 2024
    // const [reflections, setReflections] = useState(userReflectionNotes);
    const [reflections, setReflections] = useState(filteredUserReflectionNotes);
    console.log('reflections:', reflections)

    // 1b - State variables for reflections
    const [editorValue, setEditorValue] = useState(() => {
        const latestNote = userReflectionNotes?.slice(-1)[0];
        return latestNote ? latestNote.userReflectionNoteText : "";
    });

    const [currentReflectionId, setCurrentReflectionId] = useState(() => {
        const latestNote = userReflectionNotes?.slice(-1)[0];
        return latestNote ? latestNote._id : null;
    });

    // console.log('currentReflectionId', currentReflectionId)

    const [isEditing, setIsEditing] = useState(false);
    const [reflectionEditorKey, setReflectionEditorKey] = useState("reflectionNote-" + Date.now());

    // 2 - Initialize the mutation
    const [createNewUserReflection] = useCreateNewUserReflectionMutation();

    // 3 - Handle Editor Value change
    const handleEditorChange = (value) => {
    setEditorValue(value);
    };

    // 4a - Handle adding a new reflection
    const handleAddReflection = useCallback(() => {
        setEditorValue(""); // Clear editor
        setCurrentReflectionId(null); // Reset reflection ID
        setIsEditing(true); // Enable edit mode
        setReflectionEditorKey("reflectionNote-" + Date.now()); // Reset editor key
    }, []);

    // 4b - Handle editing an existing reflection
    const handleEditReflection = (note) => {
    setEditorValue(note.userReflectionNoteText); // Load note into editor
    setCurrentReflectionId(note._id); // Track the reflection being edited
    setIsEditing(true); // Enable edit mode
    };


    // 4c - Function to handle editing a note 
    const handleCancelReflection = (note) => {
    setEditorValue("")
    setCurrentReflectionId(null)
    setIsEditing(false)
    }

    // 4d - Toggle Show function for reflection note details 
    const handleToggleReflectionNoteDetails = (noteId) => {
    setShowReflectionNoteDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
    }

    // 5 - Function to handle saving a reflection (create or update)
    const handleSaveReflection = async () => {
    if (editorValue.trim() === "") {
        alert("The editor is blank. Please enter some text before saving.");
        return;
    }
    try {

        let updatedReflectionNotes;
        if (currentReflectionId) {
            // Update existing reflection note
            updatedReflectionNotes = userReflectionNotes?.map((note) =>
                note._id === currentReflectionId
                    ? {
                          ...note,
                          userReflectionNoteText: editorValue,
                          userReflectionNoteUpdateDate: new Date(),
                      }
                    : note
            );
            // console.log('updated notes:', updatedReflectionNotes)
        } else {
            // Add new reflection note
            updatedReflectionNotes = [
                ...userReflectionNotes,
                {
                    userReflectionNoteText: editorValue,
                    userReflectionNoteDate: new Date(),
                    userReflectionNoteUpdateDate: new Date(),
                },
            ];
            // console.log('updated notes (new):', updatedReflectionNotes)
        }


        const reflectionPayload = {
            userId,
            userOrgId,
            weekStarting: startOfWeek, // Pass the start of the week
            userReflectionNotes: updatedReflectionNotes,
        };

        // console.log('notes sent to backend (reflectionPayload):', reflectionPayload)
        // Call the mutation
        const newReflection = await createNewUserReflection(reflectionPayload).unwrap();
        // Reload the page after a successful save
        window.location.reload(); // This line reloads the page to fetch and display the saved data

        
        if (!currentReflectionId) {
            // Update local state with the newly added reflection only if it's a new one
            setReflections((prev) => [...prev, newReflection.data]);
        }

        setEditorValue(""); // Clear editor after saving
        setIsEditing(false); // Disable edit mode
        setCurrentReflectionId(null); // Reset reflection ID after saving
        alert("Reflection saved successfully");
    } catch (error) {
        console.error("Failed to save the reflection:", error);
        alert("Failed to save the reflection");
    }
    };

    // 6 - Handle delete reflection note details - 27 Sep 2024
    const handleDeleteReflection = async (noteId) => {
        try {
            // console.log('noteId for deletion', noteId);
            // console.log('current noteId', currentReflectionId);
    
            // Mark the note for deletion by adding a delete: true flag
            const updatedNotes = userReflectionNotes?.map((note) => 
                note._id === noteId ? { ...note, delete: true } : note
            );
            
            console.log('updatedNotes with delete flag:', updatedNotes);
    
            await createNewUserReflection({
                userId,
                userOrgId,
                weekStarting: startOfWeek, // Pass the start of the week
                userReflectionNotes: updatedNotes, // Send the updated notes array with delete flag
            }).unwrap();
    
            // Update state after successful deletion
            setReflections((prevReflections) =>
                prevReflections.filter((note) => note._id !== noteId)
            ); // Optionally remove from frontend state
    
            alert("Note deleted successfully");
            window.location.reload(); // Reload the page to fetch updated data
        } catch (error) {
            console.error("Failed to delete the note:", error);
            // Add error handling logic here if needed
        }
    };
    
  // 7 - Create an object for setting inner HTML
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
  }
 
    return (
        <div className="bg-gray-50 rounded m-1 p-1 font-poppins">
            {/* Reflections Header */}
            {reflections?.length === 0 && (
                    <p className="text-[11px] text-orange-600 italic m-1 p-1">
                        No reflections were added this week.
                    </p>
            )}
                 
            {/* Reflection Editor & Add Note Form */} 
            {/* {isEditing ? (
                <div className="bg-gray-50 p-1 m-1 rounded">
                <EtContentEditorLight2
                    value={editorValue}
                    handleChange={handleEditorChange}
                    placeholderText="Write your self note here..."
                    editorKey={reflectionEditorKey}
                />
                <div className="flex justify-start items-center m-1 p-1">
                    <button
                    type="button"
                    onClick={handleSaveReflection}
                    className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                    >
                    <p className="text-[10px] px-2 py-1">Save</p>
                    </button>
                    <button
                    type="button"
                    onClick={handleCancelReflection}
                    className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                    >
                    <p className="text-[10px] px-2 py-1">Cancel</p>
                    </button>
                </div>
                </div>
                    ) : (
                      <div className="flex justify-start items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Add</p>
                        <button type="button" onClick={handleAddReflection}>
                          <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                        </button>
                      </div>
                    )} */}

            {/* Display Reflection Notes in Descending Order */}
            {reflections?.length >0 && (
            <div className="m-1 p-1">
                <div>
                    <p className="m-1 p-1 text-xs font-bold">Reflection Notes Trail:</p>
                </div>
                <div>
                    {reflections
                        ?.slice()
                        .sort(
                            (a, b) =>
                                new Date(b.userReflectionNoteUpdateDate) - new Date(a.userReflectionNoteUpdateDate)
                        ) // Sort in descending order
                        .map((note, noteIndex, arr) => (
                            <div key={note._id} className="bg-white rounded">
                                <RenderNotes
                                    note={note}
                                    noteTextKey="userReflectionNoteText"
                                    updateDateKey="userReflectionNoteUpdateDate"
                                    createdDateKey="userReflectionNoteDate"
                                    showDetail={showReflectionNoteDetails}
                                    toggleShowDetail={handleToggleReflectionNoteDetails}
                                    handleEditNote={handleEditReflection}
                                    handleDeleteNote={handleDeleteReflection}
                                    noteIndex={noteIndex}
                                    arr={arr}
                                    createMarkup={createMarkup}
                                />
                            </div>
                        ))}
                </div>
            </div>)}
        </div>
    );
    
}

const WABConversations = ({userConversationNotes =[], startOfWeek, endOfWeek, userId, userOrgId, conversationContacts}) => {
    // First Created - 28 Sep 2024
    // Author - Atul Pandey
    // Updated - 01 Oct 2024 - refined for creating / updating conversations via form
    // Updated - 02 Oct 2024 - added functionality to get and create new user contacts for conversations
    // Updated - 03 Oct 2024 - added modal to add a new contact for conversations 

    // 0 - check for incoming data:
    // console.log('conversationContacts', conversationContacts)
    console.log('userConversationNotes', userConversationNotes)
    const userConversationContactNotes = userConversationNotes[0]?.userConversationNoteId?.userConversationContactNotes || []
    const userConversationAllNotes = userConversationNotes[0]?.userConversationNoteId?.userConversationAllNotes || []


    // 1a - State for Modal -- 03 Oct 2024
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [triggeringUrl, setTriggeringUrl] = useState(null)
    
    // 1b - Add state management for conversation notes
    const [showConverastionAllNotes, setShowConverastionAllNotes] = useState(false)
    const [showConverastionContactNotes, setShowConverastionContactNotes] = useState(false)

    const [showConversationContactNoteDetails, setShowConversationContactNoteDetails] = useState({}) 
    const [showConversationAllNoteDetails, setShowConversationAllNoteDetails] = useState({}) 
    const [conversationContactNotes, setConversationContactNotes] = useState(userConversationContactNotes);
    const [conversationAllNotes, setConversationAllNotes] = useState(userConversationAllNotes);

    // console.log('incoming user conversation contact notes', userConversationContactNotes)
    // console.log('conversation contact notes', conversationContactNotes)

    // 1c - State variables for the conversation editor (similar to reflections)
    const [allEditorValue, setAllEditorValue] = useState(() => {
        const latestAllNote = userConversationAllNotes?.slice(-1)[0];
        return latestAllNote ? latestAllNote.userConversationAllNoteText : "";
      });
      
      const [currentAllNoteId, setCurrentAllNoteId] = useState(() => {
        const latestAllNote = userConversationAllNotes?.slice(-1)[0];
        return latestAllNote ? latestAllNote._id : null;
      });

    const [isEditingAll, setIsEditingAll] = useState(false);
    const [allEditorKey, setAllEditorKey] = useState("allNote-" + Date.now());
    
    // 1d - State variables for the conversation editor (similar to reflections)
    const [selectedContactId, setSelectedContactId] = useState(""); // Holds the selected contact ID
    const [contactEditorValue, setContactEditorValue] = useState(() => {
        const latestContactNote = userConversationContactNotes?.slice(-1)[0];
        return latestContactNote ? latestContactNote.userConversationContactNoteText : "";
      });
      
    const [currentContactNoteId, setCurrentContactNoteId] = useState(() => {
        const latestContactNote = userConversationContactNotes?.slice(-1)[0];
        return latestContactNote ? latestContactNote._id : null;
      });
      
    const [isEditingContact, setIsEditingContact] = useState(false);
    const [contactEditorKey, setContactEditorKey] = useState("contactNote-" + Date.now());

    // 2 - Initialize the mutation
    const [createNewUserConversation] = useCreateNewUserConversationMutation();

    // 3a - Handle Editor Value change
    const handleAllEditorChange = (value) => {
        setAllEditorValue(value);
        };

    // 3a - Handle Editor Value change
    const handleContactEditorChange = (value) => {
        setContactEditorValue(value);
        };
    
    // 4a - Handle adding a new conversation
    const handleAddAllConversation = useCallback(() => {
        setAllEditorValue(""); // Clear editor
        setCurrentAllNoteId(null); // Reset conversation ID
        setIsEditingAll(true); // Enable edit mode
        setAllEditorKey("allNote-" + Date.now()); // Reset editor key
    }, []);

    // 4b - Handle editing an existing reflection
    const handleEditAllConversation = (note) => {
    setAllEditorValue(note.userConversationAllNoteText); // Load note into editor
    setCurrentAllNoteId(note._id); // Track the reflection being edited
    setIsEditingAll(true); // Enable edit mode
    };

    // 4c - Function to handle editing a note 
    const handleCancelAllConversation = (note) => {
    setAllEditorValue("")
    setCurrentAllNoteId(null)
    setIsEditingAll(false)
    }
    
    // 4d - Toggle Show function for reflection note details 
    const handleToggleConversationAllNoteDetails = (noteId) => {
    setShowConversationAllNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
    }))
    }

    // 4e - Handle adding a new conversation
    const handleAddContactConversation = useCallback(() => {
        setContactEditorValue(""); // Clear editor
        setCurrentContactNoteId(null); // Reset conversation ID
        setIsEditingContact(true); // Enable edit mode
        setContactEditorKey("contactNote-" + Date.now()); // Reset editor key
    }, []);

    // 4f - Handle editing an existing reflection
    const handleEditContactConversation = (note) => {
        // console.log('note', note)
    setContactEditorValue(note.userConversationContactNoteText); // Load note into editor
    setCurrentContactNoteId(note._id); // Track the reflection being edited
    setSelectedContactId(note.userContactId._id); // Set the contact ID for pre-selecting the contact
    // console.log('currentContactNoteId in handleEdit function:', currentContactNoteId)
    setIsEditingContact(true); // Enable edit mode
    };

    // 4g - Function to handle editing a note 
    const handleCancelContactConversation = (note) => {
    setContactEditorValue("")
    setCurrentContactNoteId(null)
    setIsEditingContact(false)
    }
    
    // 4h - Toggle Show function for reflection note details 
    const handleToggleConversationContactNoteDetails = (noteId) => {
    setShowConversationContactNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
    }))
    }

    // 4i - Create options to select a contact from the list of conversation contacts - 02 Oct 2024
    const contactOptions = conversationContacts ? [
    <option key="placeholder" value="" disabled selected>
      Select a Contact
    </option>,
    ...conversationContacts?.map(contact => (
      <option key={contact._id} value={contact._id}>
        {contact.contactName}
      </option>
    )),
    ] : [];
    // console.log('contactOptions',contactOptions )

    // 4j - Function to handle adding a new contact for the userId, userOrgId - 03 Oct 2024
    const handleAddContact = (userId, userOrgId) => {
        const currentUrl = window.location.pathname // 26 Aug 2024 - Capture the current URL
        setTriggeringUrl(currentUrl) // 26 Aug 2024 - Set the triggering URL
        setIsModalOpen(true)
    }

    const handleCloseCreateContactModal = () => {
        setIsModalOpen(false)
        setTriggeringUrl(null) // 26 Aug 2024 Reset the triggering URL
        window.location.reload(); // Force the page to reload
        // Refetch the data to get the latest evaluations
        // refetch()
      }
  

    // 5a - Function to handle saving a reflection (create or update)
    const handleSaveAllConversation = async () => {
    if (allEditorValue.trim() === "") {
        alert("The editor is blank. Please enter some text before saving.");
        return;
    }
    try {

        let updatedConversationAllNotes;
        if (currentAllNoteId) {
            // Update existing reflection note
            updatedConversationAllNotes = userConversationAllNotes?.map((note) =>
                note._id === currentAllNoteId
                    ? {
                            ...note,
                            userConversationAllNoteText: allEditorValue,
                            userConversationAllNoteUpdateDate: new Date(),
                        }
                    : note
            );
            // console.log('updated notes:', updatedConversationAllNotes)
        } else {
            // Add new reflection note
            updatedConversationAllNotes = [
                ...userConversationAllNotes,
                {
                    userConversationAllNoteText: allEditorValue,
                    userConversationAllNoteDate: new Date(),
                    userConversationAllNoteUpdateDate: new Date(),
                },
            ];
            // console.log('updated notes (new):', updatedConversationAllNotes)
        }


        const conversationPayload = {
            userId,
            userOrgId,
            weekStarting: startOfWeek, // Pass the start of the week
            userConversationAllNotes: updatedConversationAllNotes,
        };

        // console.log('notes sent to backend (conversationPayload):', conversationPayload)
        // Call the mutation
        const newConversation = await createNewUserConversation(conversationPayload).unwrap();
        // Reload the page after a successful save
        window.location.reload(); // This line reloads the page to fetch and display the saved data

        
        if (!currentAllNoteId) {
            // Update local state with the newly added reflection only if it's a new one
            setConversationAllNotes((prev) => [...prev, newConversation.data]);
        }

        setAllEditorValue(""); // Clear editor after saving
        setIsEditingAll(false); // Disable edit mode
        setCurrentAllNoteId(null); // Reset reflection ID after saving
        alert("Conversation all note saved successfully");
    } catch (error) {
        console.error("Failed to save the conversation all note:", error);
        alert("Failed to save the conversation all note");
    }
    };

    // 5b - Function to handle saving a reflection (create or update)
    const handleSaveContactConversation = async () => {
        if (!selectedContactId || contactEditorValue.trim() === "") { 
            alert("Please select a contact and enter some text in the editor before saving.");
            return;
        }
        try {
            // console.log('selected Contact Id:', selectedContactId);
            // console.log('current Contact note Id:', currentContactNoteId);
    
            let updatedConversationContactNotes;
    
            if (currentContactNoteId) {
                // Update existing contact note
                updatedConversationContactNotes = userConversationContactNotes?.map((note) =>
                    note._id === currentContactNoteId
                        ? {
                              ...note,
                              userConversationContactNoteText: contactEditorValue,
                          }
                        : note
                );
                // console.log('updated notes:', updatedConversationContactNotes);
            } else {
                // Add new contact note
                updatedConversationContactNotes = [
                    ...userConversationContactNotes,
                    {
                        userContactId: selectedContactId, 
                        userConversationContactNoteText: contactEditorValue,
                    },
                ];
                // console.log('updated notes (new):', updatedConversationContactNotes);
            }
    
            const conversationPayload = {
                userId,
                userOrgId,
                weekStarting: startOfWeek,
                userConversationContactNotes: updatedConversationContactNotes,
            };
    
            // console.log('notes sent to backend (conversationPayload):', conversationPayload);
    
            // Call the mutation
            const newConversation = await createNewUserConversation(conversationPayload).unwrap();
            // Reload the page after a successful save
            window.location.reload(); // This line reloads the page to fetch and display the saved data
    
            // Update local state with the updated/new contact notes
            setConversationContactNotes(newConversation.userConversationContactNotes || []);
    
            // Clear editor and reset states after saving
            setContactEditorValue(""); 
            setIsEditingContact(false); 
            setCurrentContactNoteId(null); 
    
            alert("Conversation contact note saved successfully");
        } catch (error) {
            console.error("Failed to save the conversation contact note:", error);
            alert("Failed to save the conversation contact note");
        }
    };
    
    // 6a - Handle delete reflection note details - 02 Oct 2024    
    const handleDeleteAllConversation = async (noteId) => {
        try {
            // console.log('noteId for deletion', noteId);
            // console.log('current noteId', currentReflectionId);
    
            // Mark the note for deletion by adding a delete: true flag
            const updatedNotes = userConversationAllNotes?.map((note) => 
                note._id === noteId ? { ...note, delete: true } : note
            );
            
            console.log('updatedNotes with delete flag:', updatedNotes);
    
            await createNewUserConversation({
                userId,
                userOrgId,
                weekStarting: startOfWeek, // Pass the start of the week
                userConversationAllNotes: updatedNotes, // Send the updated notes array with delete flag
            }).unwrap();
    
            // Update state after successful deletion
            setConversationAllNotes((prevConversations) =>
                prevConversations.filter((note) => note._id !== noteId)
            ); // Optionally remove from frontend state
    
            alert("Note deleted successfully");
            window.location.reload(); // Reload the page to fetch updated data
        } catch (error) {
            console.error("Failed to delete the note:", error);
            // Add error handling logic here if needed
        }
    };
    
    // 6b - Handle delete reflection note details - 02 Oct 2024
    const handleDeleteContactConversation = async (noteId) => {
        try {
            // console.log('noteId for deletion', noteId);
            // console.log('current noteId', currentReflectionId);
    
            // Mark the note for deletion by adding a delete: true flag
            const updatedNotes = userConversationContactNotes?.map((note) => 
                note._id === noteId ? { ...note, delete: true } : note
            );
            
            console.log('updatedNotes with delete flag:', updatedNotes);
    
            await createNewUserConversation({
                userId,
                userOrgId,
                weekStarting: startOfWeek, // Pass the start of the week
                userConversationContactNotes: updatedNotes, // Send the updated notes array with delete flag
            }).unwrap();
    
            // Update state after successful deletion
            setConversationContactNotes((prevConversations) =>
                prevConversations.filter((note) => note._id !== noteId)
            ); // Optionally remove from frontend state
    
            alert("Note deleted successfully");
            window.location.reload(); // Reload the page to fetch updated data
        } catch (error) {
            console.error("Failed to delete the note:", error);
            // Add error handling logic here if needed
        }
    };
    
    // 7 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
    }


    return (
        <div className="bg-gray-50 rounded m-1 p-1 font-poppins">

            {/* Sections for Conversation Headers */}

            {/* All Conversation */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <div className="flex justify-start items-center m-1 p-1 rounded">
                    <div
                        onClick={() => setShowConverastionAllNotes(prev => !prev)}
                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                        >
                        {showConverastionAllNotes ? (
                            <MinusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                            <PlusIcon className="h-4 w-4 text-orange-600" />
                        )}
                    </div>
                    <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtConversationAllIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                    <div><p className="font-bold mx-1 px-1">All</p></div>
                </div>
                {showConverastionAllNotes && (
                    <div className="bg-gray-50 m-1 p-1 rounded">
                        {conversationAllNotes?.length === 0 && (
                            <p className="text-[11px] text-gray-500 italic mx-2 px21">
                                No conversations were added for this week.
                            </p>
                        )}

                        {/* {isEditingAll ? (
                            <div className="bg-gray-50 p-1 m-1 rounded">
                            <EtContentEditorLight2
                                value={allEditorValue}
                                handleChange={handleAllEditorChange}
                                placeholderText="Write your self note here..."
                                editorKey={allEditorKey}
                            />
                            <div className="flex justify-start items-center m-1 p-1">
                                <button
                                type="button"
                                onClick={handleSaveAllConversation}
                                className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                                >
                                <p className="text-[10px] px-2 py-1">Save</p>
                                </button>
                                <button
                                type="button"
                                onClick={handleCancelAllConversation}
                                className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                                >
                                <p className="text-[10px] px-2 py-1">Cancel</p>
                                </button>
                            </div>
                            </div>
                                ) : (
                                <div className="flex justify-start items-center m-1 p-1">
                                    <p className="font-bold text-xs m-1 p-1">Add</p>
                                    <button type="button" onClick={handleAddAllConversation}>
                                    <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                                    </button>
                                </div>
                            )} */}

                        {/* Display Reflection Notes in Descending Order */}
                        {conversationAllNotes?.length > 0 && (
                        <div className="m-1 p-1">
                            <div>
                                <p className="m-1 p-1 text-xs font-bold">Conversation Trail:</p>
                            </div>
                            <div>
                                {conversationAllNotes
                                    ?.slice()
                                    .sort(
                                        (a, b) =>
                                            new Date(b.userConversationAllNoteUpdateDate) - new Date(a.userConversationAllNoteUpdateDate)
                                    ) // Sort in descending order
                                    .map((note, noteIndex, arr) => (
                                        <div key={note._id} className="bg-white rounded">
                                            <RenderNotes
                                                note={note}
                                                noteTextKey="userConversationAllNoteText"
                                                updateDateKey="userConversationAllNoteUpdateDate"
                                                createdDateKey="userConversationAllNoteDate"
                                                showDetail={showConversationAllNoteDetails}
                                                toggleShowDetail={handleToggleConversationAllNoteDetails}
                                                handleEditNote={handleEditAllConversation}
                                                handleDeleteNote={handleDeleteAllConversation}
                                                noteIndex={noteIndex}
                                                arr={arr}
                                                createMarkup={createMarkup}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>)}
                    </div>
                )}
            </div>
           
           {/* By Contact Section */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <div className="flex justify-start items-center m-1 p-1 rounded">
                    <div
                        onClick={() => setShowConverastionContactNotes(prev => !prev)}
                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                        >
                        {showConverastionContactNotes ? (
                            <MinusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                            <PlusIcon className="h-4 w-4 text-orange-600" />
                        )}
                    </div>
                    <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtConversationContactIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                    <div><p className="font-bold mx-1 px-1">By Contact</p></div>
                </div>
                {showConverastionContactNotes && (
                    <div className="bg-gray-50 m-1 p-1 rounded">
                        {conversationContactNotes?.length === 0 && (
                            <p className="text-[11px] text-gray-500 italic mx-2 px21">
                                No conversations were added for this week.
                            </p>
                        )}

                        {/* {isEditingContact ? (
                            <div className="bg-gray-50 rounded">
                                
                                <div className="flex justify-start items-center my-1 py-1 rounded bg-gray-100">
                                    <div className="flex justify-center items-center h-8 w-8 bg-white hover:bg-gray-200 rounded-full m-1">
                                        <UserIcon className='text-orange-600 h-6 w-6' />
                                    </div>
                                    <div><p className="text-xs text-gray-600 m-1 p-1 font-bold">Contact:</p></div>
                                    <select
                                    className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none "
                                    onChange={(e) => setSelectedContactId(e.target.value)}
                                    value={selectedContactId || ""} 
                                    disabled={!!currentContactNoteId}
                                    >
                                    {contactOptions}
                                    </select>
                                </div>
                                
                                <EtContentEditorLight2
                                    value={contactEditorValue}
                                    handleChange={handleContactEditorChange}
                                    placeholderText="Write your self note here..."
                                    editorKey={contactEditorKey}
                                />
                                <div className="flex justify-start items-center m-1 p-1">
                                    <button
                                    type="button"
                                    onClick={handleSaveContactConversation}
                                    className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                                    >
                                    <p className="text-[10px] px-2 py-1">Save</p>
                                    </button>
                                    <button
                                    type="button"
                                    onClick={handleCancelContactConversation}
                                    className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                                    >
                                    <p className="text-[10px] px-2 py-1">Cancel</p>
                                    </button>
                                </div>
                            </div>
                                ) : (
                                <div className="flex justify-start items-center m-1 p-1">

                                    <div className="flex justify-start items-center m-1 p-1">
                                        <p className="font-bold text-xs m-1 p-1">Add a Conversation</p>
                                        <button type="button" onClick={handleAddContactConversation}>
                                        <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                                        </button>
                                    </div>

                                    <div className="flex justify-start items-center m-1 p-1">
                                        <p className="font-bold text-xs m-1 p-1">Add a Contact</p>
                                        <button type="button" onClick={()=>handleAddContact(userId, userOrgId)}>
                                        <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                                        </button>
                                    </div>
                                </div>
                            )} */}

                        {/* Display Reflection Notes in Descending Order */}
                        {conversationContactNotes?.length > 0 && (
                        <div className="m-1 p-1">
                            <div>
                                <p className="m-1 p-1 text-xs font-bold">Contact Conversation Trail:</p>
                            </div>
                            <div>
                                {conversationContactNotes
                                    ?.slice()
                                    .sort(
                                        (a, b) =>
                                            new Date(b.userConversationContactNoteUpdateDate) - new Date(a.userConversationContactNoteUpdateDate)
                                    ) // Sort in descending order
                                    .map((note, noteIndex, arr) => (
                                        <div key={note._id} className="bg-white rounded">
                                            <RenderContactNotes
                                                note={note}
                                                noteTextKey="userConversationContactNoteText"
                                                updateDateKey="userConversationContactNoteUpdateDate"
                                                createdDateKey="userConversationContactNoteDate"
                                                showDetail={showConversationContactNoteDetails}
                                                toggleShowDetail={handleToggleConversationContactNoteDetails}
                                                handleEditNote={handleEditContactConversation}
                                                handleDeleteNote={handleDeleteContactConversation}
                                                noteIndex={noteIndex}
                                                arr={arr}
                                                createMarkup={createMarkup}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>)}
                    </div>
                )}
                {/* <EtModalCreateContact 
                    isOpen={isModalOpen}
                    userId={userId}
                    userOrgId={userOrgId}
                    triggeringUrl={triggeringUrl}
                    onClose={handleCloseCreateContactModal}
                /> */}
            </div>
            
        
        </div>
    )

}

const WABCreations = ({userCreationWABNotes = [], startOfWeek, endOfWeek, userId, userOrgId, creationTypes}) => {
    // First Created - 05 Oct 2024
    // Author - Atul Pandey
    // Updated - 06 Oct 2024 - added showCreationDetails
    // Updated - 07 Oct 2024 - added showCreationNotes
    // Updated - 08 Oct 2024 - added delete creation mutation
    // Updated - 09 Oct 2024 - switched the notes display via RenderNotes and isEditing via NoteEditor
    // Updated - 09 Oct 2024 - added tabs for summary and details


    // 1a - Extract the creation records
    const creationRecords = userCreationWABNotes[0]?.userCreationWABNoteId?.userCreationRecords || []
    const userCreationWABNoteId = userCreationWABNotes[0]?.userCreationWABNoteId?._id

    // 1b - State to handle user creation data and form fields
    const [creations, setCreations] = useState(creationRecords);
    // console.log('creations:', creations)
    // console.log('creationTypes', creationTypes)

    // 1c - State variables for editor and creation fields
    const [isEditingCreation, setIsEditingCreation] = useState(false);  // Track edit mode for Creation
    const [isEditingNote, setIsEditingNote] = useState(false);  // Track edit mode for Note
    const [showCreationDetails, setShowCreationDetails] = useState({}) // 06 Oct 2024
    const [showCreationNotes, setShowCreationNotes] = useState({}) // 07 Oct 2024
    const [showCreationNoteDetails, setShowCreationNoteDetails] = useState({}) // 07 Oct 2024
    const [activeTab, setActiveTab] = useState('Summary') // 09 Oct 2024


    // Capture the latest creation note text (if exists)
    const [editorValue, setEditorValue] = useState(() => {
        const latestCreation = creations?.slice(-1)[0];
        const latestNote = latestCreation?.userCreationNotes?.slice(-1)[0];
        return latestNote ? latestNote.userCreationNoteText : "";
    });

    // Track the current creation and the latest note within it
    const [currentCreationId, setCurrentCreationId] = useState(() => {
        const latestCreation = creations?.slice(-1)[0];
        return latestCreation ? latestCreation?.userCreationId?._id : null;
    });

    const [currentCreationNoteId, setCurrentCreationNoteId] = useState(() => {
        const latestNote = creations?.slice(-1)[0]?.userCreationNotes?.slice(-1)[0];
        return latestNote ? latestNote._id : null;
    });

    // Handle creation fields (name, description, type)
    const [userCreationName, setUserCreationName] = useState(() => {
        const latestCreation = creations?.slice(-1)[0];
        return latestCreation ? latestCreation.userCreationName : "";
    });

    const [userCreationDesc, setUserCreationDesc] = useState(() => {
        const latestCreation = creations?.slice(-1)[0];
        return latestCreation ? latestCreation.userCreationDesc : "";
    });

    const [selectedCreationTypeId, setSelectedCreationTypeId] = useState(() => {
        const latestCreation = creations?.slice(-1)[0];
        return latestCreation ? latestCreation.userCreationType : "";
    });

    // 1d - State for resetting editor key when switching between edit and create modes
    const [creationEditorKey, setCreationEditorKey] = useState("creationNote-" + Date.now());

    // 2a - Mutation for creating, updating and deleting a user creation
    const [createNewUserCreation] = useCreateNewUserCreationMutation();
    const [updateUserCreation] = useUpdateUserCreationMutation();
    const [deleteUserCreation] = useDeleteUserCreationMutation(); // 08 Oct 2024


    // 2b - Fetch creation types for the select options (if not passed in as props)
    const creationTypeOptions = creationTypes?.map(type => (
        <option key={type._id} value={type._id}>{type?.creationTypeName}</option>
    ));

    // 3a - Handle the editor value change for creation notes
    const handleEditorChange = (value) => {
        setEditorValue(value);
    };

    // 3b - Handle adding a new creation (reset fields and enter edit mode)
    const handleAddCreation = useCallback(() => {
        setActiveTab("Details") // 10 Oct 2024 - set the active tab to detail
        setUserCreationName(""); // Clear creation name
        setUserCreationDesc(""); // Clear description
        setSelectedCreationTypeId(""); // Clear selected type
        setEditorValue(""); // Clear editor for note
        setCurrentCreationId(null); // Reset the creation ID
        setIsEditingCreation(true); // Switch to edit mode
        setIsEditingNote(true) // Switch to note edit mode (since we need the editor for the note)
        setCreationEditorKey("creationNote-" + Date.now()); // Reset editor key
    }, []);

    // 3c - Handle Add creation note
    const handleAddCreationNote = (creationId) => {
        // Reset the editor and prepare to add a new note
        setEditorValue(""); // Clear the note editor for a new note
        setCurrentCreationId(creationId); // Set the current creation ID for which we are adding a note
        setCurrentCreationNoteId(null); // Reset the note ID since this is a new note
        setIsEditingCreation(false)
        setIsEditingNote(true); // Switch to edit mode for adding a note
        setCreationEditorKey("creationNote-" + Date.now()); // Reset the editor key to ensure a fresh editor
    };
    
    // 3d - Handle editing an existing creation
    const handleEditCreation = (creation) => {
        setUserCreationName(creation.userCreationId?.userCreationName); // Set name
        setUserCreationDesc(creation.userCreationId?.userCreationDesc); // Set description
        setSelectedCreationTypeId(creation.userCreationId?.userCreationType._id); // Set creation type
        // const latestNote = creation?.userCreationNotes?.slice(-1)[0];
        // setEditorValue(latestNote?.userCreationNoteText || ""); // Set note text
        setCurrentCreationId(creation.userCreationId?._id); // Set the creation ID for updating
        setIsEditingCreation(true); // Switch to edit mode
        setIsEditingNote(false)
    };

    // 3e - Handle edit creation note - 07 Sep 2024
    const handleEditCreationNote = (noteId) => {
        // console.log('noteId', noteId)
        // Find the creation that contains the note
        const selectedCreation = creations?.find(creation =>
            creation.userCreationId?.userCreationNotes?.some(note => note._id === noteId)
        );
        console.log('selectedCreation', selectedCreation)
    
        if (selectedCreation) {
            // Find the specific note to edit
            const selectedNote = selectedCreation.userCreationId?.userCreationNotes.find(note => note._id === noteId);
            console.log('selectedNote', selectedNote)
    
            if (selectedNote) {
                setEditorValue(selectedNote.userCreationNoteText); // Set the note text for editing
                setCurrentCreationId(selectedCreation.userCreationId._id); // Set the current creation ID
                setCurrentCreationNoteId(noteId); // Set the current note ID
                setIsEditingCreation(false)
                setIsEditingNote(true); // Switch to edit mode for the note
                setIsEditingCreation(false)
            }
        }
    };

    // 3f - Handle cancelling the creation process
    const handleCancelCreation = () => {
        setUserCreationName("");
        setUserCreationDesc("");
        setSelectedCreationTypeId("");
        setEditorValue("");
        setCurrentCreationId(null);
        setIsEditingCreation(false);
        setIsEditingNote(false)
    };


    // 3g - Function to handle saving a creation (create or update) - updated - 07 Oct 2024
    const handleSaveCreation = async () => {
        try {
            let response;
    
            if (isEditingCreation && !isEditingNote) {
                // Case: Editing an existing creation (without notes)
                if (!userCreationName.trim()) {
                    alert("Please provide a creation name before saving.");
                    return;
                }
    
                const creationPayload = {
                    id: currentCreationId,
                    userCreationName: userCreationName.trim(),
                    userCreationDesc: userCreationDesc?.trim(),
                    userCreationType: selectedCreationTypeId,
                };
                // console.log('creationPayload', creationPayload)
                response = await updateUserCreation(creationPayload).unwrap();
                window.location.reload()
            } 
            else if (isEditingNote && !isEditingCreation) {
                // Case: Editing or adding a note for an existing creation
                if (!editorValue.trim()) {
                    alert("Please provide a note before saving.");
                    return;
                }
                
                // console.log('currentCreationId', currentCreationId)
                const updatedNotes = creations?.map(creation =>
                        
                        creation.userCreationId._id === currentCreationId
                            ? {
                                ...creation,
                                userCreationNotes: currentCreationNoteId
                                    ? creation.userCreationId?.userCreationNotes?.map(note =>
                                        note._id === currentCreationNoteId
                                            ? { ...note, userCreationNoteText: editorValue }
                                            : note
                                    )
                                    : [...creation.userCreationId?.userCreationNotes, {  // Adding new note
                                        userCreationNoteText: editorValue,
                                        userCreationNoteDate: new Date(),
                                        userCreationNoteUpdateDate: new Date()
                                    }],
                            }
                            : creation
                );
                // console.log('updatedNotes', updatedNotes)

                const creationIndex = updatedNotes?.findIndex(
                    creation => creation.userCreationId._id === currentCreationId
                );

                // console.log('creationIndex', creationIndex)

                const newOrUpdatedNote = currentCreationNoteId
                    ? updatedNotes[creationIndex]?.userCreationNotes.find(note => note._id === currentCreationNoteId)  // Find the updated note
                    : {
                        userCreationNoteText: editorValue,
                        userCreationNoteDate: new Date(), // New note date for the new note
                        userCreationNoteUpdateDate: new Date(), // Set update date
                    };

                // console.log('newOrUpdatedNote', newOrUpdatedNote);
    
                const creationPayload = {
                    id: currentCreationId,
                    // userCreationNotes: updatedNotes[creationIndex]?.userCreationNotes || [],
                    userCreationNotes: [newOrUpdatedNote], // Send only the new or updated note
                };
                response = await updateUserCreation(creationPayload).unwrap();
                window.location.reload()
            } 
            else if (isEditingCreation && isEditingNote){
                // Case: Adding a new creation (with a note)
                if (!userCreationName.trim() || !editorValue.trim()) {
                    alert("Please provide a creation name and note before saving.");
                    return;
                }
    
                const creationPayload = {
                    userId,
                    userOrgId,
                    userCreationName: userCreationName.trim(),
                    userCreationDesc: userCreationDesc?.trim(),
                    userCreationType: selectedCreationTypeId,
                    userCreationNotes: [
                        {
                            userCreationNoteText: editorValue,
                            userCreationNoteDate: new Date(),
                            userCreationNoteUpdateDate: new Date(),
                        }
                    ]
                };
                // console.log('creation Payload', creationPayload)
                response = await createNewUserCreation(creationPayload).unwrap();
                window.location.reload()
            }
    
            // Reset states after success
            setCreations((prevCreations) => {
                if (currentCreationId) {
                    return prevCreations.map(creation =>
                        creation._id === currentCreationId ? response.data : creation
                    );
                } else {
                    return [...prevCreations, response.data];
                }
            });
            resetCreationForm(); // Reset form fields and state
    
        } catch (error) {
            console.error("Failed to save the creation or note:", error);
            alert("Failed to save.");
        }
    };
    
    // Reset form function
    const resetCreationForm = () => {
        setUserCreationName("");
        setUserCreationDesc("");
        setEditorValue("");
        setCurrentCreationId(null);
        setCurrentCreationNoteId(null);
        setIsEditingCreation(false);
        setIsEditingNote(false);
    };

    // 3h - Handle delete creation note
    const handleDeleteCreationNote = async (noteId, currentCreationId) => {
        try {
            // console.log('noteId', noteId)
            
            // Step 1: Identify the correct creation ID
            const selectedCreation = creations?.find(
                (creation) => creation?.userCreationId?._id === currentCreationId
            );
            // console.log('selectedCreation', selectedCreation)
    
            if (!selectedCreation) {
                console.error("Failed to find the creation.");
                return;
            }
    
            // Step 2: Identify the correct note ID
            const selectedNote = selectedCreation.userCreationId.userCreationNotes.find(
                (note) => note._id === noteId
            );
            // console.log('selectedNote', selectedNote)
    
            if (!selectedNote) {
                console.error("Failed to find the note.");
                return;
            }
    
            // Step 3: Mark the note for deletion
            const updatedNotes = selectedCreation.userCreationId.userCreationNotes.map(
                (note) => 
                    note._id === noteId 
                        ? { ...note, delete: true } 
                        : note
            );
            // console.log('updatedNotes', updatedNotes)
    
            // Step 4: Prepare the payload
            const creationPayload = {
                id: currentCreationId, // creation ID
                // userCreationNotes: updatedNotes // updated notes with the delete flag
                userCreationNotes: updatedNotes?.filter(note => note._id === noteId && note.delete)
            };
    
            // Step 5: Send the payload to the backend and update frontend state
            await updateUserCreation(creationPayload).unwrap();
            window.location.reload()
    
            setCreations((prevCreations) =>
                prevCreations.map((creation) =>
                    creation.userCreationId._id === currentCreationId
                        ? { ...creation, userCreationNotes: updatedNotes }
                        : creation
                )
            );
    
            alert("Note deleted successfully.");
            resetCreationForm(); // Reset form fields and state
        } catch (error) {
            console.error("Failed to delete the note:", error);
            alert("Failed to delete the note.");
        }
    };
    
    // 3i - Handle delete creation - updated 08 Oct 2024
    const handleDeleteCreation = async (creationId) => {
        try {
            const confirmation = window.confirm("Are you sure you want to delete this creation and all associated notes?");
            if (!confirmation) return;
            console.log('creationId for deletion', creationId)

            if (!userCreationWABNoteId || !creationId) {
                console.error("Missing required data to delete creation");
                return;
            }   

            const deletionPayload = {
                creationId: creationId,
                userCreationWABNoteId: userCreationWABNoteId
            };

            console.log('deletionPayload', deletionPayload)

            // Call the delete mutation (deleteUserCreation)
            await deleteUserCreation(deletionPayload).unwrap();
            window.location.reload()

            // Update the frontend state to remove the deleted creation
            setCreations((prevCreations) =>
                prevCreations.filter((creation) => creation.userCreationId?._id !== creationId)
            );

            alert("Creation deleted successfully.");
            resetCreationForm(); // Reset form fields and state
        } catch (error) {
            console.error("Failed to delete the creation:", error);
            alert("Failed to delete the creation.");
        }
    };

    // 4a - Toggle Show function for show creation details -- 06 Oct 2024
    const handleToggleCreationDetails = (noteId) => {
        setShowCreationDetails((prev) => ({
          ...prev,
          [noteId]: !prev[noteId],
        }))
        }

    // 4b - Toggle Show function for show creation notes -- 07 Oct 2024
    const handleToggleCreationNotes = (noteId) => {
        setShowCreationNotes((prev) => ({
          ...prev,
          [noteId]: !prev[noteId],
        }))
    }

    // 4c - Toggle Show function for show creation note details -- 07 Oct 2024
    const handleToggleCreationNoteDetails = (noteId) => {
        setShowCreationNoteDetails((prev) => ({
          ...prev,
          [noteId]: !prev[noteId],
        }))
    }

    // 4d - Toggle Tabs -- 09 Oct 2024
    const handleTabSwitch = (tab) => {
        setActiveTab(tab);
    };

    // 5 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
    }

    return (
        <div className="bg-gray-50 rounded m-1 p-1 font-poppins">

            {/* Tab Navigation */}
            <div className="grid grid-cols-6 justify-start items-center m-1 p-1">
                <div className="col-span-2 flex space-x-4 mb-4">
                    {/* Tab 1: Summary */}
                    <button
                        className={`m-1 p-1 text-sm font-bold ${activeTab === 'Summary' ? 'border-b-2 border-orange-600' : 'text-gray-400'}`}
                        onClick={() => handleTabSwitch('Summary')}
                    >
                        Summary
                    </button>

                    {/* Tab 2: Details */}
                    <button
                        className={`m-1 p-1 text-sm font-bold ${activeTab === 'Details' ? 'border-b-2 border-orange-600' : 'text-gray-400'}`}
                        onClick={() => handleTabSwitch('Details')}
                    >
                        Details
                    </button>
                </div>
                <div className="col-span-2 flex justify-start items-center mb-4">
                    <p className="font-bold text-xs m-1 p-1">Creations this week:</p>
                    <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{creations?.length}</p></div>
                </div>
                {/* <div className="flex justify-start items-center m-1 p-1">
                    <p className="font-bold text-xs m-1 p-1">Add a New Creation</p>
                    <button
                        type="button"
                        onClick={handleAddCreation}
                        className="flex justify-center items-center h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600"
                    >
                        <PlusIcon className="h-5 w-5" />
                    </button>
                </div>   */}
            </div>

             {/* TAB 1 - Summary */}
             {activeTab === 'Summary' && (
                <div className="bg-gray-100 rounded m-1 p-1">

                    <p className="text-xs m-1 p-1 leading-4">As you slog through every week, you are creating! Whether it is a template to write a good code or a sales proposal,  checklist for a requirements review or best practices for solution design, your creative instincts are constantly at play. A creation lights up your imprint like nothing else. It reflects your thought leadership. It builds your legacy!</p>
                    {creations?.length >0 && (
                    <p className="text-xs m-1 p-1 leading-4">Here's a snapshot of the creations you have added to your creation vault thus far.Take a moment to soak in before you light up some more!
                    </p>)}
                    {creations?.length === 0 && (
                    <p className="text-xs m-1 p-1 leading-4">You are just getting started.Add a creation and see how it lights up your magic!
                    </p>)}
                    <div className='grid grid-cols-6 justify-between items-center w-5/6 mx-auto'>
                        <div className='col-span-3 m-1 rounded'>
                            <div className='flex justify-start items-center'>
                            <div><ArrowDownCircleIcon className='h-5 w-5'/></div>
                            <div><p className='p-1 m-1 text-gray-400 font-bold'>Start of the week:</p></div>
                            </div>
                            <div><p className='mx-1 px-1 text-[10px] lg:text-xs italic'>a blank slate..</p></div>
                           {/* Left side - Put the CreationTypes grid based on all creation types with zero here */}
                           <EtCreationScorecardWeekly creationTypes={creationTypes} creations={[]} />
                        </div>
                        <div className='col-span-3 m-1 rounded '>                       
                            <div className="flex justify-between items-center">
                                <div className='flex justify-start items-center'>
                                    <div><ArrowUpCircleIcon className='h-5 w-5 text-etpx-5'/></div>
                                    <div><p className='p-1 m-1 text-gray-400 font-bold'>Your Magic!</p></div>
                                </div>
                            </div>
                            <div><p className='mx-1 px-1 text-[10px] lg:text-xs italic'>as you keep turning up your luminance...</p></div>
                            {/* Right Side grid with actual weekly creations data*/}
                            <EtCreationScorecardWeekly creationTypes={creationTypes} creations={creations} />
                        </div>
                    </div>
                </div>
             )}
             
             {/* TAB 2 - Details */}
             {activeTab === 'Details' && (
                <div>
            
                    {/* Creation Form */}
                    {/* {isEditingCreation && isEditingNote ? (
                        <div className="bg-gray-50 p-1 m-1 rounded text-xs">
                            
                            <p className="m-1 p-1 font-bold">New Creation Details</p>
                            <div className="flex flex-col ">
                            
                                <input
                                    type="text"
                                    value={userCreationName}
                                    onChange={(e) => setUserCreationName(e.target.value)}
                                    placeholder="Enter creation name"
                                    className="w-full rounded p-2 m-1 border border-gray-300"
                                />
                            </div>
            
                        
                            <div className="flex flex-col ">
                            
                                <textarea
                                    value={userCreationDesc}
                                    onChange={(e) => setUserCreationDesc(e.target.value)}
                                    placeholder="Enter creation description"
                                    className="w-full rounded p-2 m-1 border border-gray-300"
                                />
                            </div>
            
                        
                            <div className="flex flex-col ">
                            
                                <select
                                    value={selectedCreationTypeId}
                                    onChange={(e) => setSelectedCreationTypeId(e.target.value)}
                                    className="w-full rounded p-2 m-1 border border-gray-300"
                                >
                                    <option value="" disabled>
                                        Select Creation Type
                                    </option>
                                    {creationTypeOptions}
                                </select>
                            </div>
            
                        
                            <div className="flex flex-col m-1 w-full">
                                
                                <EtContentEditorLight2
                                    value={editorValue}
                                    handleChange={handleEditorChange}
                                    placeholderText="Write your creation note here..."
                                    editorKey={creationEditorKey}
                                />
                            </div>
            
                        
                            <div className="flex justify-start items-center m-2 p-2">
                                <button
                                    type="button"
                                    onClick={handleSaveCreation}
                                    className="w-24 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                                >
                                    <p className="text-sm px-3 py-2">Save</p>
                                </button>
                                <button
                                    type="button"
                                    onClick={handleCancelCreation}
                                    className="w-24 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-2"
                                >
                                    <p className="text-sm px-3 py-2">Cancel</p>
                                </button>
                            </div>
                        </div>
                    ) : isEditingCreation ? (
                        <div className="bg-gray-50 p-1 m-1 rounded text-xs">
                            <p className="m-1 p-1 font-bold">Edit Creation Details</p>
                        
                            <div className="flex flex-col">
                            
                                <input
                                    type="text"
                                    value={userCreationName}
                                    onChange={(e) => setUserCreationName(e.target.value)}
                                    placeholder="Enter creation name"
                                    className="w-full rounded p-2 m-1 border border-gray-300"
                                />
                            </div>
            
                            
                            <div className="flex flex-col">
                            
                                <textarea
                                    value={userCreationDesc}
                                    onChange={(e) => setUserCreationDesc(e.target.value)}
                                    placeholder="Enter creation description"
                                    className="w-full rounded p-2 m-1 border border-gray-300"
                                />
                            </div>
            
                        
                            <div className="flex flex-col">
                            
                                <select
                                    value={selectedCreationTypeId}
                                    onChange={(e) => setSelectedCreationTypeId(e.target.value)}
                                    className="w-full rounded p-2 m-1 border border-gray-300"
                                >
                                    <option value="" disabled>
                                        Select Creation Type
                                    </option>
                                    {creationTypeOptions}
                                </select>
                            </div>
            
                        
                            <div className="flex justify-start items-center m-2 p-2">
                                <button
                                    type="button"
                                    onClick={handleSaveCreation}
                                    className="w-24 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                                >
                                    <p className="text-sm px-3 py-2">Save</p>
                                </button>
                                <button
                                    type="button"
                                    onClick={handleCancelCreation}
                                    className="w-24 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-2"
                                >
                                    <p className="text-sm px-3 py-2">Cancel</p>
                                </button>
                            </div>

                        </div>
                    ): isEditingNote ? (
                        <NoteEditor
                            editorValue={editorValue}
                            handleEditorChange={handleEditorChange}
                            editorKey={creationEditorKey}
                            currentCreationNoteId={currentCreationNoteId}
                            handleSaveCreation={handleSaveCreation}
                            handleCancelCreation={handleCancelCreation}
                        />
                    ):null} */}
                    
                    {/* Creations Display Section */}
                    <div className="bg-gray-50 border rounded m-1 p-1">
                        {creations?.length === 0 && (
                            <div className="m-1 p-1 bg-white rounded">
                                <p className="text-orange-600 mx-1 px-1 text-xs"> No creations were recorded.</p>
                            </div>
                        )}

                        {creations?.length >0 && creations
                        ?.slice()
                        .sort(
                            (a, b) =>
                                new Date(b.userCreationId?.createdAt) - new Date(a.userCreationId?.createdAt)
                        ) // Sort in descending order
                        .map((creation, creationIndex) => {
                            const creationId = creation.userCreationId?._id
                            const creationName = creation?.userCreationId?.userCreationName
                            const creationType = creation?.userCreationId?.userCreationType?.creationTypeName
                            const creationTypePic = creation?.userCreationId?.userCreationType?.pic
                            const creationNotes = creation?.userCreationId?.userCreationNotes
                            const creationNotesLength = creation?.userCreationId?.userCreationNotes?.length
                            const createdAt = creation?.userCreationId?.createdAt
 
                            return(
                                <div key={creationId+creationIndex} className="bg-gray-100 m-1 p-1 rounded cursor-pointer hover:bg-gray-200">
                                    <div className="grid grid-cols-10 justify-start gap-2 items-center">
                                    <div className="col-span-4 flex justify-start items-center m-1 p-1">
                                            <div
                                                onClick={() => handleToggleCreationDetails(creationId)}
                                                className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
                                            >
                                                {showCreationDetails[creationId] ? (
                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                                ) : (
                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                                )}
                                            </div>
                                            <div><p className="text-gray-600 font-bold text-xl mx-1 px-1">{creationIndex+1}.</p></div>
                                            <div><p className="text-gray-800 text-xs mx-1 px-1">{creationName}</p></div>
                                        </div> 

                                        <div className="col-span-2 flex justify-start items-center m-1 p-1">
                                            {/* <div><p className="text-[10px] text-gray-400">Type:</p></div> */}
                                            <div className="flex justify-center items-center h-7 w-7 rounded-full bg-white hover:bg-gray-50"><img src={creationTypePic} alt="" className="h-5 w-5" /></div>
                                            <div><p className="mx-1 px-1 text-[10px]">{creationType}</p></div>

                                        </div>

                                        <div className="col-span-3 flex justify-start items-center mx-2">
                                            <ClockIcon className="h-5 w-5 text-lime-600" />
                                            <p className="text-[9px] mx-1">First Created:</p>
                                            <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdAt)}</p>
                                        </div>

                                        {/* <div className="col-span-1 flex justify-end items-center mx-2">
                                            <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                            <PencilSquareIcon
                                                className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                                onClick={() => handleEditCreation(creation) }
                                            />
                                            </div>
                                            <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                            <TrashIcon
                                                className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                                onClick={() => handleDeleteCreation(creationId)}
                                            />
                                            </div>
                                        </div> */}
                                    
                                    </div>
                                    
                                    {showCreationDetails[creationId] && (
                                        <div className="bg-white rounded m-1 p-1">
                                            
                                            <div className="grid grid-cols-8 gap-1 justify-start items-center m-1 p-1">   
                                                <div className="col-span-3 flex justify-start items-center">
                                                    <div
                                                        onClick={() => handleToggleCreationNotes(creationId)}
                                                        className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
                                                    >
                                                        {showCreationNotes[creationId] ? (
                                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                                        ) : (
                                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                                        )}
                                                    </div>
                                                    <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtWabCreationNotesIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                                                    <div>
                                                        <p className="mx-1 px-1 text-gray-600 font-bold text-sm">Creation Notes</p>
                                                        <p className="mx-1 px-1 text-gray-400 italic text-[10px]">Your thoughts about this creation!</p>
                                                    </div>
                                                </div>
                                                <div className="col-span-2 flex justify-start items-center">
                                                    <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{creationNotesLength}</p></div>
                                                    <div><p className='text-[8px] text-orange-600 italic'>{creationNotesLength === 1 ? 'note': 'notes'}</p></div>
                                                </div>
                                                {/* <div className="col-span-3 flex justify-end items-center m-1 p-1">
                                                    <p className="font-bold text-xs m-1 p-1">Add a New Note</p>
                                                    <button
                                                        type="button"
                                                        onClick={() =>handleAddCreationNote(creationId)}
                                                        className="flex justify-center items-center h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600"
                                                    >
                                                        <PlusIcon className="h-5 w-5" />
                                                    </button>
                                                </div> */}
                                            </div>

                                            {showCreationNotes[creationId] && creationNotes.length > 0 && creationNotes
                                            ?.slice()
                                            .sort(
                                                (a, b) =>
                                                new Date(b.userCreationNoteDate) - new Date(a.userCreationNoteDate)
                                            )
                                            .map((note, noteIndex) => (
                                                <RenderNotes
                                                key={note._id + noteIndex}
                                                note={note}
                                                noteTextKey="userCreationNoteText"
                                                updateDateKey="userCreationNoteUpdateDate"
                                                createdDateKey="userCreationNoteDate"
                                                showDetail={showCreationNoteDetails}
                                                toggleShowDetail={handleToggleCreationNoteDetails}
                                                handleEditNote={handleEditCreationNote}
                                                handleDeleteNote={handleDeleteCreationNote}
                                                noteIndex={noteIndex}
                                                createMarkup={createMarkup}
                                                noteId={note._id} // Pass noteId
                                                currentCreationId={creationId}
                                                creations={creations}
                                                />
                                            ))}


                                            {/* {showCreationNotes[creationId] && creationNotes.length>0 && creationNotes
                                            ?.slice()
                                            .sort(
                                                    (a, b) =>
                                                        new Date(b.userCreationNoteDate) - new Date(a.userCreationNoteDate)
                                                ) // Sort in descending order
                                            .map((note, noteIndex) => {
                                                const noteText = note?.userCreationNoteText
                                                const noteDate = note?.userCreationNoteDate
                                                const noteUpdateDate = note?.userCreationNoteUpdateDate
                                                

                                                return(
                                                    <div key={note._id+noteIndex} className="bg-gray-100 m-1 p-1 rounded">
                                                        <div className="grid grid-cols-12 justify-between items-center m-1 p-1">
                                                            <div className="col-span-2 flex justify-start items-center">
                                                                <div
                                                                    onClick={() => handleToggleCreationNoteDetails(note._id)}
                                                                    className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
                                                                    >
                                                                {showCreationNoteDetails[note._id] ? (
                                                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                                                ) : (
                                                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                                                )}
                                                                </div>
                                                                <div>
                                                                    <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ noteIndex +1}.</p>
                                                                </div>
                                                                <div>
                                                                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-18">
                                                                    {getTimeSince(noteUpdateDate)}
                                                                </p>
                                                                <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                                </div>
                                                            </div>

                                                            <div className="col-span-6 flex justify-start items-center">
                                                                <div
                                                                dangerouslySetInnerHTML={createMarkup(noteText)}
                                                                className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                                ></div>
                                                            </div>

                                                            <div className="col-span-3 flex justify-start items-center mx-2">
                                                                <ClockIcon className="h-5 w-5 text-lime-600" />
                                                                <p className="text-[9px] mx-1">First Created:</p>
                                                                <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(noteDate)}</p>
                                                            </div>

                                                            <div className="col-span-1 flex justify-end items-center mx-2">
                                                                <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                                                <PencilSquareIcon
                                                                    className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                                                    onClick={() => handleEditCreationNote(note._id) }
                                                                />
                                                                </div>
                                                                <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                                                <TrashIcon
                                                                    className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                                                    onClick={() => handleDeleteCreationNote(note._id)}
                                                                />
                                                                </div>
                                                            </div>


                                                        </div>

                                                        {noteText && showCreationNoteDetails[note._id] && (
                                                            <div
                                                            dangerouslySetInnerHTML={createMarkup(noteText                                                    )}
                                                            className="text-[11px] text-gray-700 m-1 p-1 bg-gray-50 rounded"
                                                            ></div>
                                                        )}
                                                    </div>
                                                )
                                            })}                             */}
                                        </div>
                                    )}
                                
                                </div>
                            )
                        })}
                    </div>
                </div>
             )}{/* End of TAB2 */}
            
        </div>
    );
    

}

const WABContributions = ({userContributionNotes = [], startOfWeek, endOfWeek, userId, userOrgId, contributionContacts, contributionTypes}) => {
    // First Created - 10 Oct 2024
    // Author - Atul Pandey
    // Inherited from WABConversations
    // Updated - 11 Oct 2024 - added the code for contribution types
    
    // 0 - check for incoming data:
    // console.log('contributionContacts', contributionContacts)
    // console.log('contributionTypes', contributionTypes)

    // console.log('userContributionNotes', userContributionNotes)
    const userContributionContactNotes = userContributionNotes[0]?.userContributionNoteId?.userContributionContactNotes || []
    const userContributionAllNotes = userContributionNotes[0]?.userContributionNoteId?.userContributionAllNotes || []

    // 1a - State for Modal -- 03 Oct 2024
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [triggeringUrl, setTriggeringUrl] = useState(null)
    
    // 1b - Add state management for contribution notes
    const [showContributionAllNotes, setShowContributionAllNotes] = useState(false)
    const [showContributionContactNotes, setShowContributionContactNotes] = useState(false)

    const [showContributionContactNoteDetails, setShowContributionContactNoteDetails] = useState({}) 
    const [showContributionAllNoteDetails, setShowContributionAllNoteDetails] = useState({}) 
    const [contributionContactNotes, setContributionContactNotes] = useState(userContributionContactNotes);
    const [contributionAllNotes, setContributionAllNotes] = useState(userContributionAllNotes);

    // console.log('contribution all notes', contributionAllNotes)
    // console.log('contribution contact notes', contributionContactNotes)

    // 1c - State variables for the contribution editor (similar to reflections)
    const [allEditorValue, setAllEditorValue] = useState(() => {
        const latestAllNote = userContributionAllNotes?.slice(-1)[0];
        return latestAllNote ? latestAllNote.userContributionAllNoteText : "";
      });
      
      const [currentAllNoteId, setCurrentAllNoteId] = useState(() => {
        const latestAllNote = userContributionAllNotes?.slice(-1)[0];
        return latestAllNote ? latestAllNote._id : null;
      });

    const [isEditingAll, setIsEditingAll] = useState(false);
    const [allEditorKey, setAllEditorKey] = useState("allContributionNote-" + Date.now());
    
    // 1d - State variables for the contribution editor (similar to reflections)
    const [selectedContactId, setSelectedContactId] = useState(""); // Holds the selected contact ID
    const [selectedContactType, setSelectedContactType] = useState("") // 11 Oct 2024 - for filtering contact Type

    const [contactEditorValue, setContactEditorValue] = useState(() => {
        const latestContactNote = userContributionContactNotes?.slice(-1)[0];
        return latestContactNote ? latestContactNote.userContributionContactNoteText : "";
      });
      
    const [currentContactNoteId, setCurrentContactNoteId] = useState(() => {
        const latestContactNote = userContributionContactNotes?.slice(-1)[0];
        return latestContactNote ? latestContactNote._id : null;
      });
      
    const [isEditingContact, setIsEditingContact] = useState(false);
    const [contactEditorKey, setContactEditorKey] = useState("contactContributionNote-" + Date.now());

    // 1e - State variable for selected contribution type - 11 Oct 2024
    const [selectedContributionTypeId, setSelectedContributionTypeId] = useState("")
    // console.log('selectedContributionTypeId', selectedContributionTypeId)

    // 2 - Initialize the mutation
    const [createNewUserContribution] = useCreateNewUserContributionMutation();

    // 3a - Handle Editor Value change
    const handleAllEditorChange = (value) => {
        setAllEditorValue(value);
        };

    // 3a - Handle Editor Value change
    const handleContactEditorChange = (value) => {
        setContactEditorValue(value);
        };
    
    // 4a - Handle adding a new contribution
    const handleAddAllContribution = useCallback(() => {
        setAllEditorValue(""); // Clear editor
        setCurrentAllNoteId(null); // Reset contribution ID
        setIsEditingAll(true); // Enable edit mode
        setSelectedContributionTypeId(""); // 11 Oct 2024 Reset the contribution type ID
        setAllEditorKey("allContributionNote-" + Date.now()); // Reset editor key
        setShowContributionAllNotes(true)
    }, []);

    // 4b - Handle editing an existing reflection
    const handleEditAllContribution = (note) => {
        // console.log('note.id', note._id)
    setAllEditorValue(note.userContributionAllNoteText); // Load note into editor
    setCurrentAllNoteId(note._id); // Track the reflection being edited
    setIsEditingAll(true); // Enable edit mode
    setSelectedContributionTypeId(note?.userContributionTypeId._id) // 11 Oct 2024
    // console.log('selectedContributionTypeId', selectedContributionTypeId)
    // console.log('selectedContributionTypeId', note.userContributionTypeId)
    };

    // 4c - Function to handle editing a note 
    const handleCancelAllContribution = (note) => {
    setAllEditorValue("")
    setCurrentAllNoteId(null)
    setIsEditingAll(false)
    }
    
    // 4d - Toggle Show function for reflection note details 
    const handleToggleContributionAllNoteDetails = (noteId) => {
    setShowContributionAllNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
    }))
    }

    // 4e - Handle adding a new contribution
    const handleAddContactContribution = useCallback(() => {
        setContactEditorValue(""); // Clear editor
        setCurrentContactNoteId(null); // Reset contribution ID
        setIsEditingContact(true); // Enable edit mode
        setContactEditorKey("contactContributionNote-" + Date.now()); // Reset editor key
        setSelectedContributionTypeId(""); // 11 Oct 2024 Reset the contribution type ID
        setSelectedContactId(""); // 11 Oct 2024 Reset contact selection
        setSelectedContactType(""); // 11 Oct 2024 Reset the contact type to ensure no leftover filtering
        setShowContributionContactNotes(true)
    }, []);

    // 4f - Handle editing an existing reflection
    const handleEditContactContribution = (note) => {
        // console.log('note', note)
    setContactEditorValue(note.userContributionContactNoteText); // Load note into editor
    setCurrentContactNoteId(note._id); // Track the reflection being edited
    setSelectedContactId(note.userContactId._id); // Set the contact ID for pre-selecting the contact
    // console.log('currentContactNoteId in handleEdit function:', currentContactNoteId)
    setIsEditingContact(true); // Enable edit mode
    setSelectedContributionTypeId(note.userContributionTypeId._id) // 11 Oct 2024
    // setSelectedContactId(""); // 11 Oct 2024 Reset contact selection
    setSelectedContactType(note.userContactId?.contactTypeId?.contactTypeName) // 11 Oct 2024
    };

    // 4g - Function to handle editing a note 
    const handleCancelContactContribution = (note) => {
    setContactEditorValue("")
    setCurrentContactNoteId(null)
    setIsEditingContact(false)
    setSelectedContributionTypeId(""); // Reset contribution type
    setSelectedContactType(""); // Reset contact type to clear filtering
    }
    
    // 4h - Toggle Show function for reflection note details 
    const handleToggleContributionContactNoteDetails = (noteId) => {
    setShowContributionContactNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
    }))
    }

    // 4i - Create options to select a contact from the list of contribution contacts - 02 Oct 2024
    const contactOptions = contributionContacts ? [
    <option key="placeholder" value="" disabled >
      Select a Contact
    </option>,
    ...contributionContacts?.map(contact => (
      <option key={contact._id} value={contact._id}>
        {contact.contactName}
      </option>
    )),
    ] : [];
    // console.log('contactOptions',contactOptions )

    // 4j - Create options to select a contribution type from the list of contribution types - 10 Oct 2024
    const filteredContributionTypes = selectedContactType?.toLowerCase().includes("client")
    ? contributionTypes.filter(type => 
        type.contributionTypeName.toLowerCase().includes("client") || 
        type.contributionTypeName.toLowerCase() === "mentoring" // Explicitly include "Mentoring"
        )
    : selectedContactType?.toLowerCase().includes("partner")
    ? contributionTypes.filter(type => type.contributionTypeName.toLowerCase().includes("partner"))
    : contributionTypes;


    const contributionTypeOptions = filteredContributionTypes ? [
    <option key="placeholder" value="" disabled>
      Select a Contribution Type
    </option>,
    ...filteredContributionTypes?.map(type => (
      <option key={type._id} value={type._id}>
        {type.contributionTypeName}
      </option>
    )),
    ] : [];
    
    // 4k - Function to handle adding a new contact for the userId, userOrgId - 03 Oct 2024
    const handleAddContact = (userId, userOrgId) => {
        const currentUrl = window.location.pathname // 26 Aug 2024 - Capture the current URL
        setTriggeringUrl(currentUrl) // 26 Aug 2024 - Set the triggering URL
        setIsModalOpen(true)
    }

    const handleCloseCreateContactModal = () => {
        setIsModalOpen(false)
        setTriggeringUrl(null) // 26 Aug 2024 Reset the triggering URL
        window.location.reload(); // Force the page to reload
        // Refetch the data to get the latest evaluations
        // refetch()
      }

    // 5a - Function to handle saving a reflection (create or update) - 11 Oct 2024 - updated for contribution type
    const handleSaveAllContribution = async () => {

        // 11 Oct 2024 - updated with contribution type

    if (allEditorValue.trim() === "") {
        alert("The editor is blank. Please enter some text before saving.");
        return;
    }
    try {

        let contributionPayload;
            if (currentAllNoteId) {
                // Update existing contact note - send only the updated note
                const updatedNote = {
                    _id: currentAllNoteId,
                    userContributionAllNoteText: allEditorValue,
                    userContributionTypeId: selectedContributionTypeId,
                    userContributionAllNoteDate: userContributionAllNotes?.find(note => note._id === currentAllNoteId)?.userContributionAllNoteDate, // Preserve original creation date
                    userContributionAllNoteUpdateDate: new Date(), // Update the timestamp
                };

                contributionPayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userContributionAllNotes: [updatedNote], // Send only the updated note
                };
            } else {
                // Add new contact note - send only the new note
                const newNote = {
                    userContributionAllNoteText: allEditorValue,
                    userContributionTypeId: selectedContributionTypeId,
                    userContributionAllNoteDate: new Date(), // Creation date
                    userContributionAllNoteUpdateDate: new Date(), // Update date
                };
                console.log('newNote', newNote)

                contributionPayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userContributionAllNotes: [newNote], // Send only the new note
                };
            }

        console.log('notes sent to backend (contributionPayload):', contributionPayload)
        // Call the mutation
        const newContribution = await createNewUserContribution(contributionPayload).unwrap();
        // Reload the page after a successful save
        window.location.reload(); // This line reloads the page to fetch and display the saved data

        
        if (!currentAllNoteId) {
            // Update local state with the newly added reflection only if it's a new one
            setContributionAllNotes((prev) => [...prev, newContribution.data]);
        }

        setAllEditorValue(""); // Clear editor after saving
        setIsEditingAll(false); // Disable edit mode
        setCurrentAllNoteId(null); // Reset reflection ID after saving
        alert("Contribution all note saved successfully");
    } catch (error) {
        console.error("Failed to save the contribution all note:", error);
        alert("Failed to save the contribution all note");
    }
    };

    // 5b - Function to handle saving a contribution (create or update) - 11 Oct 2024 - updated for contribution type - 12 Oct 2024 - refined for update logic - changed note only
    const handleSaveContactContribution = async () => {
        if (!selectedContactId || contactEditorValue.trim() === "") { 
            alert("Please select a contact and enter some text in the editor before saving.");
            return;
        }
        try {
            // console.log('selected Contact Id:', selectedContactId);
            // console.log('current Contact note Id:', currentContactNoteId);
    
            let contributionPayload;
            if (currentContactNoteId) {
                // Update existing contact note - send only the updated note
                const updatedNote = {
                    _id: currentContactNoteId,
                    userContactId: selectedContactId,
                    userContributionContactNoteText: contactEditorValue,
                    userContributionTypeId: selectedContributionTypeId,
                    userContributionContactNoteDate: userContributionContactNotes?.find(note => note._id === currentContactNoteId)?.userContributionContactNoteDate, // Preserve original creation date
                    userContributionContactNoteUpdateDate: new Date(), // Update the timestamp
                };

                contributionPayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userContributionContactNotes: [updatedNote], // Send only the updated note
                };
            } else {
                // Add new contact note - send only the new note
                const newNote = {
                    userContactId: selectedContactId,
                    userContributionContactNoteText: contactEditorValue,
                    userContributionTypeId: selectedContributionTypeId,
                    userContributionContactNoteDate: new Date(), // Creation date
                    userContributionContactNoteUpdateDate: new Date(), // Update date
                };

                contributionPayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userContributionContactNotes: [newNote], // Send only the new note
                };
            }
        
            const newContribution = await createNewUserContribution(contributionPayload).unwrap();
            // Reload the page after a successful save
            window.location.reload(); // This line reloads the page to fetch and display the saved data
    
            // Update local state with the updated/new contact notes
            setContributionContactNotes(newContribution.userContributionContactNotes || []);
    
            // Clear editor and reset states after saving
            setContactEditorValue(""); 
            setIsEditingContact(false); 
            setCurrentContactNoteId(null); 
    
            alert("Contribution contact note saved successfully");
        } catch (error) {
            console.error("Failed to save the contribution contact note:", error);
            alert("Failed to save the contribution contact note");
        }
    };
    
    // 6a - Handle delete contribution note details - 02 Oct 2024    - 11 Oct 2024 - reviewed but not updated for contribution type
    const handleDeleteAllContribution = async (noteId) => {
        try {
            // console.log('noteId for deletion', noteId);
            // console.log('current noteId', currentReflectionId);
    
            // Mark the note for deletion by adding a delete: true flag
            const updatedNotes = userContributionAllNotes?.map((note) => 
                note._id === noteId ? { ...note, delete: true } : note
            );
            
            console.log('updatedNotes with delete flag:', updatedNotes);
    
            await createNewUserContribution({
                userId,
                userOrgId,
                weekStarting: startOfWeek, // Pass the start of the week
                userContributionAllNotes: updatedNotes, // Send the updated notes array with delete flag
            }).unwrap();
    
            // Update state after successful deletion
            setContributionAllNotes((prevContributions) =>
                prevContributions.filter((note) => note._id !== noteId)
            ); // Optionally remove from frontend state
    
            alert("Note deleted successfully");
            window.location.reload(); // Reload the page to fetch updated data
        } catch (error) {
            console.error("Failed to delete the note:", error);
            // Add error handling logic here if needed
        }
    };
    
    // 6b - Handle delete contribution note details - 02 Oct 2024    - 11 Oct 2024 - reviewed but not updated for contribution type
    const handleDeleteContactContribution = async (noteId) => {
        try {
            // console.log('noteId for deletion', noteId);
            // console.log('current noteId', currentContributionId);
    
            // Mark the note for deletion by adding a delete: true flag
            const updatedNotes = userContributionContactNotes?.map((note) => 
                note._id === noteId ? { ...note, delete: true } : note
            );
            
            console.log('updatedNotes with delete flag:', updatedNotes);
    
            await createNewUserContribution({
                userId,
                userOrgId,
                weekStarting: startOfWeek, // Pass the start of the week
                userContributionContactNotes: updatedNotes, // Send the updated notes array with delete flag
            }).unwrap();
    
            // Update state after successful deletion
            setContributionContactNotes((prevContributions) =>
                prevContributions.filter((note) => note._id !== noteId)
            ); // Optionally remove from frontend state
    
            alert("Note deleted successfully");
            window.location.reload(); // Reload the page to fetch updated data
        } catch (error) {
            console.error("Failed to delete the note:", error);
            // Add error handling logic here if needed
        }
    };
    
    // 7 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
    }


    return (
        <div className="bg-gray-50 rounded m-1 p-1 font-poppins">

            {/* Sections for Contribution Headers */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <p className="text-xs m-1 p-1 leading-4">You work hard and accomplish many things throughout the week, but the impact of your work goes beyond just you. Your efforts are making a difference for your clients, your partners, your team members, and others you collaborate with. You are contributing! Take a moment to reflect and record the contributions you've made this week.</p>
            </div>

            {/* All Contribution */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <div className="grid grid-cols-10 justify-between items-center">
                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                        <div
                            onClick={() => setShowContributionAllNotes(prev => !prev)}
                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                            >
                            {showContributionAllNotes ? (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                            )}
                        </div>
                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtContributionAllIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                        <div><p className="font-bold mx-1 px-1">All</p></div>  
                    </div>
                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Contributions this week:</p>
                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{contributionAllNotes?.length}</p></div>
                    </div>
                    {/* <div className="col-span-4 flex justify-end items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Add a Contribution</p>
                        <button type="button" onClick={handleAddAllContribution}>
                        <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                        </button>
                    </div> */}
                </div>
                {showContributionAllNotes && (
                    <div className="bg-gray-50 m-1 p-1 rounded">
                        {contributionAllNotes?.length === 0 && (
                            <p className="text-[11px] text-gray-500 italic mx-2 px21">
                                No contributions have been added yet. Add your first one now!
                            </p>
                        )}

                        {/* {isEditingAll ? (
                            <div className="bg-gray-50 p-1 m-1 rounded">
                                <div className="flex justify-start items-center my-1 py-1 rounded bg-gray-100">
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-white hover:bg-gray-200 cursor-pointer ml-2 pl-2" ><EtContributionTypeIcon1SVGComp  className='h-7 w-7 text-orange-600'/>
                                    </div>
                                    <p className="text-xs text-gray-600 m-1 p-1 font-bold">Contribution Type:</p>
                                    <select
                                    className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none"
                                    onChange={(e) => setSelectedContributionTypeId(e.target.value)}
                                    value={selectedContributionTypeId || ""} 
                                    >
                                    {contributionTypeOptions}
                                    </select>
                                </div>
                            <EtContentEditorLight2
                                value={allEditorValue}
                                handleChange={handleAllEditorChange}
                                placeholderText="Write your contribution note here..."
                                editorKey={allEditorKey}
                            />
                            <div className="flex justify-start items-center m-1 p-1">
                                <button
                                type="button"
                                onClick={handleSaveAllContribution}
                                className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                                >
                                <p className="text-[10px] px-2 py-1">Save</p>
                                </button>
                                <button
                                type="button"
                                onClick={handleCancelAllContribution}
                                className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                                >
                                <p className="text-[10px] px-2 py-1">Cancel</p>
                                </button>
                            </div>
                            </div>
                                ) : null} */}

                        {/* Display Reflection Notes in Descending Order */}
                        <div className="m-1 p-1">
                            <div>
                                <p className="m-1 p-1 text-xs font-bold">Contribution Trail:</p>
                            </div>
                            <div>
                                {contributionAllNotes
                                    ?.slice()
                                    .sort(
                                        (a, b) =>
                                            new Date(b.userContributionAllNoteUpdateDate) - new Date(a.userContributionAllNoteUpdateDate)
                                    ) // Sort in descending order
                                    .map((note, noteIndex, arr) => (
                                        <div key={note._id} className="bg-white rounded">
                                            <RenderContributionNotes
                                                note={note}
                                                noteTextKey="userContributionAllNoteText"
                                                updateDateKey="userContributionAllNoteUpdateDate"
                                                createdDateKey="userContributionAllNoteDate"
                                                showDetail={showContributionAllNoteDetails}
                                                toggleShowDetail={handleToggleContributionAllNoteDetails}
                                                handleEditNote={handleEditAllContribution}
                                                handleDeleteNote={handleDeleteAllContribution}
                                                noteIndex={noteIndex}
                                                arr={arr}
                                                createMarkup={createMarkup}
                                                contributionTypeInfo={note.userContributionTypeId}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
           
           {/* By Contact Section */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <div className="grid grid-cols-10 justify-between items-center">
                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                        <div
                            onClick={() => setShowContributionContactNotes(prev => !prev)}
                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                            >
                            {showContributionContactNotes ? (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                            )}
                        </div>
                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtContributionContactIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                        <div><p className="font-bold mx-1 px-1">By Contact</p></div>
                    </div>
                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Contributions this week:</p>
                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{contributionContactNotes?.length}</p></div>
                    </div>
                    {/* <div className="col-span-4 flex justify-end items-center m-1 p-1">
                        <div className="flex justify-start items-center m-1 p-1">
                            <p className="font-bold text-xs m-1 p-1">Add a Contact</p>
                            <button type="button" onClick={()=>handleAddContact(userId, userOrgId)}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                            </button>
                        </div>
                        <div className="flex justify-start items-center m-1 p-1">
                            <p className="font-bold text-xs m-1 p-1">Add a Contribution</p>
                            <button type="button" onClick={handleAddContactContribution}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                            </button>
                        </div>
                    </div> */}
                </div>
                {showContributionContactNotes && (
                    <div className="bg-gray-50 m-1 p-1 rounded">
                        {contributionContactNotes?.length === 0 && (
                            <p className="text-[11px] text-gray-500 italic mx-2 px21">
                                No contributions have been added yet. Add your first one now!
                            </p>
                        )}

                        {/* {isEditingContact ? (
                            <div className="bg-gray-50 rounded">
                                
                                <div className="flex justify-start items-center my-1 py-1 rounded bg-gray-100">
                                    <div className="flex justify-center items-center h-8 w-8 bg-white hover:bg-gray-200 rounded-full m-1">
                                        <UserIcon className='text-orange-600 h-6 w-6' />
                                    </div>
                                    <div><p className="text-xs text-gray-600 m-1 p-1 font-bold">Contact:</p></div>
                                    <select
                                    className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none "
                                    // onChange={(e) => setSelectedContactId(e.target.value)}
                                    onChange={(e) => {
                                        const selectedContact = contributionContacts.find(contact => contact._id === e.target.value);
                                        setSelectedContactId(e.target.value);
                                        setSelectedContactType(selectedContact?.contactTypeId?.contactTypeName || ""); // Set the contact type for filtering
                                    }}
                                    
                                    value={selectedContactId || ""} 
                                    disabled={!!currentContactNoteId}
                                    >
                                    {contactOptions}
                                    </select>

                                    <div className="flex justify-start items-center mx-2 px-2">
                                        <div className="flex justify-center items-center rounded-full h-8 w-8 bg-white hover:bg-gray-200 cursor-pointer ml-2 pl-2" ><EtContributionTypeIcon1SVGComp  className='h-7 w-7 text-orange-600'/>
                                        </div>
                                        <p className="text-xs text-gray-600 m-1 p-1 font-bold">Contribution Type:</p>
                                        <select
                                        className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none"
                                        onChange={(e) => setSelectedContributionTypeId(e.target.value)}
                                        value={selectedContributionTypeId || ""} 
                                        >
                                        {contributionTypeOptions}
                                        </select>
                                    </div>

                                </div>
                                
                                <EtContentEditorLight2
                                    value={contactEditorValue}
                                    handleChange={handleContactEditorChange}
                                    placeholderText="Write your contribution note here..."
                                    editorKey={contactEditorKey}
                                />
                                <div className="flex justify-start items-center m-1 p-1">
                                    <button
                                    type="button"
                                    onClick={handleSaveContactContribution}
                                    className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                                    >
                                    <p className="text-[10px] px-2 py-1">Save</p>
                                    </button>
                                    <button
                                    type="button"
                                    onClick={handleCancelContactContribution}
                                    className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                                    >
                                    <p className="text-[10px] px-2 py-1">Cancel</p>
                                    </button>
                                </div>
                            </div>
                                ) : null} */}

                        {/* Display Reflection Notes in Descending Order */}
                        <div className="m-1 p-1">
                            <div>
                                <p className="m-1 p-1 text-xs font-bold">Contact Contribution Trail:</p>
                            </div>
                            <div>
                                {contributionContactNotes
                                    ?.slice()
                                    .sort(
                                        (a, b) =>
                                            new Date(b.userContributionContactNoteUpdateDate) - new Date(a.userContributionContactNoteUpdateDate)
                                    ) // Sort in descending order
                                    .map((note, noteIndex, arr) => (
                                        <div key={note._id} className="bg-white rounded">
                                            <RenderContactContributionNotes
                                                note={note}
                                                noteTextKey="userContributionContactNoteText"
                                                updateDateKey="userContributionContactNoteUpdateDate"
                                                createdDateKey="userContributionContactNoteDate"
                                                showDetail={showContributionContactNoteDetails}
                                                toggleShowDetail={handleToggleContributionContactNoteDetails}
                                                handleEditNote={handleEditContactContribution}
                                                handleDeleteNote={handleDeleteContactContribution}
                                                noteIndex={noteIndex}
                                                arr={arr}
                                                createMarkup={createMarkup}
                                                contributionTypeInfo={note.userContributionTypeId}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
                <EtModalCreateContact 
                    isOpen={isModalOpen}
                    userId={userId}
                    userOrgId={userOrgId}
                    triggeringUrl={triggeringUrl}
                    onClose={handleCloseCreateContactModal}
                />
            </div>
        </div>
    )

}

const WABImpacts = ({userImpactNotes = [], startOfWeek, endOfWeek, userId, userOrgId, impactContacts, impactTypes}) => {
    // First Created - 13 Oct 2024
    // Author - Atul Pandey
    // Inherited from WABImpacts
   
    // 0 - check for incoming data:
    // console.log('impactContacts', impactContacts)
    // console.log('impactTypes', impactTypes)
    const userImpactContactNotes = userImpactNotes[0]?.userImpactNoteId?.userImpactContactNotes || []
    const userImpactAllNotes = userImpactNotes[0]?.userImpactNoteId?.userImpactAllNotes || []

    // 1a - State for Modal -- 03 Oct 2024
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [triggeringUrl, setTriggeringUrl] = useState(null)
    
    // 1b - Add state management for impact notes
    const [showImpactAllNotes, setShowImpactAllNotes] = useState(false)
    const [showImpactContactNotes, setShowImpactContactNotes] = useState(false)

    const [showImpactContactNoteDetails, setShowImpactContactNoteDetails] = useState({}) 
    const [showImpactAllNoteDetails, setShowImpactAllNoteDetails] = useState({}) 
    const [impactContactNotes, setImpactContactNotes] = useState(userImpactContactNotes);
    const [impactAllNotes, setImpactAllNotes] = useState(userImpactAllNotes);

    // console.log('impact all notes', impactAllNotes)
    // console.log('impact contact notes', impactContactNotes)

    // 1c - State variables for the impact editor (similar to reflections)
    const [allEditorValue, setAllEditorValue] = useState(() => {
        const latestAllNote = userImpactAllNotes?.slice(-1)[0];
        return latestAllNote ? latestAllNote.userImpactAllNoteText : "";
      });
      
      const [currentAllNoteId, setCurrentAllNoteId] = useState(() => {
        const latestAllNote = userImpactAllNotes?.slice(-1)[0];
        return latestAllNote ? latestAllNote._id : null;
      });

    const [isEditingAll, setIsEditingAll] = useState(false);
    const [allEditorKey, setAllEditorKey] = useState("allImpactNote-" + Date.now());
    
    // 1d - State variables for the impact editor (similar to reflections)
    const [selectedContactId, setSelectedContactId] = useState(""); // Holds the selected contact ID
    const [selectedContactType, setSelectedContactType] = useState("") // 11 Oct 2024 - for filtering contact Type

    const [contactEditorValue, setContactEditorValue] = useState(() => {
        const latestContactNote = userImpactContactNotes?.slice(-1)[0];
        return latestContactNote ? latestContactNote.userImpactContactNoteText : "";
      });
      
    const [currentContactNoteId, setCurrentContactNoteId] = useState(() => {
        const latestContactNote = userImpactContactNotes?.slice(-1)[0];
        return latestContactNote ? latestContactNote._id : null;
      });
      
    const [isEditingContact, setIsEditingContact] = useState(false);
    const [contactEditorKey, setContactEditorKey] = useState("contactImpactNote-" + Date.now());

    // 1e - State variable for selected impact type - 11 Oct 2024
    const [selectedImpactTypeId, setSelectedImpactTypeId] = useState("")
    // console.log('selectedImpactTypeId', selectedImpactTypeId)

    // 2 - Initialize the mutation
    const [createNewUserImpact] = useCreateNewUserImpactMutation();

    // 3a - Handle Editor Value change
    const handleAllEditorChange = (value) => {
        setAllEditorValue(value);
        };

    // 3a - Handle Editor Value change
    const handleContactEditorChange = (value) => {
        setContactEditorValue(value);
        };
    
    // 4a - Handle adding a new impact
    const handleAddAllImpact = useCallback(() => {
        setAllEditorValue(""); // Clear editor
        setCurrentAllNoteId(null); // Reset impact ID
        setIsEditingAll(true); // Enable edit mode
        setAllEditorKey("allImpactNote-" + Date.now()); // Reset editor key
        setShowImpactAllNotes(true)
    }, []);

    // 4b - Handle editing an existing reflection
    const handleEditAllImpact = (note) => {
    setAllEditorValue(note.userImpactAllNoteText); // Load note into editor
    setCurrentAllNoteId(note._id); // Track the reflection being edited
    setIsEditingAll(true); // Enable edit mode
    setSelectedImpactTypeId(note.userImpactTypeId._id) // 11 Oct 2024
    };

    // 4c - Function to handle editing a note 
    const handleCancelAllImpact = (note) => {
    setAllEditorValue("")
    setCurrentAllNoteId(null)
    setIsEditingAll(false)
    }
    
    // 4d - Toggle Show function for reflection note details 
    const handleToggleImpactAllNoteDetails = (noteId) => {
    setShowImpactAllNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
    }))
    }

    // 4e - Handle adding a new impact
    const handleAddContactImpact = useCallback(() => {
        setContactEditorValue(""); // Clear editor
        setCurrentContactNoteId(null); // Reset impact ID
        setIsEditingContact(true); // Enable edit mode
        setContactEditorKey("contactImpactNote-" + Date.now()); // Reset editor key
        setSelectedImpactTypeId(""); // 11 Oct 2024 Reset the impact type ID
        setSelectedContactId(""); // 11 Oct 2024 Reset contact selection
        setSelectedContactType(""); // 11 Oct 2024 Reset the contact type to ensure no leftover filtering
        setShowImpactContactNotes(true)
    }, []);

    // 4f - Handle editing an existing reflection
    const handleEditContactImpact = (note) => {
        // console.log('note', note)
    setContactEditorValue(note.userImpactContactNoteText); // Load note into editor
    setCurrentContactNoteId(note._id); // Track the reflection being edited
    setSelectedContactId(note.userContactId._id); // Set the contact ID for pre-selecting the contact
    // console.log('currentContactNoteId in handleEdit function:', currentContactNoteId)
    setIsEditingContact(true); // Enable edit mode
    setSelectedImpactTypeId(note.userImpactTypeId._id) // 11 Oct 2024
    // setSelectedContactId(""); // 11 Oct 2024 Reset contact selection
    setSelectedContactType(note.userContactId?.contactTypeId?.contactTypeName) // 11 Oct 2024
    };

    // 4g - Function to handle editing a note 
    const handleCancelContactImpact = (note) => {
    setContactEditorValue("")
    setCurrentContactNoteId(null)
    setIsEditingContact(false)
    setSelectedImpactTypeId(""); // Reset impact type
    setSelectedContactType(""); // Reset contact type to clear filtering
    }
    
    // 4h - Toggle Show function for reflection note details 
    const handleToggleImpactContactNoteDetails = (noteId) => {
    setShowImpactContactNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
    }))
    }

    // 4i - Create options to select a contact from the list of impact contacts - 02 Oct 2024
    const contactOptions = impactContacts ? [
    <option key="placeholder" value="" disabled >
      Select a Contact
    </option>,
    ...impactContacts?.map(contact => (
      <option key={contact._id} value={contact._id}>
        {contact.contactName}
      </option>
    )),
    ] : [];
    // console.log('contactOptions',contactOptions )

    // 4j - Create options to select a impact type from the list of impact types - 10 Oct 2024
    const impactTypeOptions = impactTypes ? [
    <option key="placeholder" value="" disabled selected>
      Select a Impact Type
    </option>,
    ...impactTypes?.map(type => (
      <option key={type._id} value={type._id}>
        {type.impactTypeName}
      </option>
    )),
    ] : [];
    
    // 4k - Function to handle adding a new contact for the userId, userOrgId - 03 Oct 2024
    const handleAddContact = (userId, userOrgId) => {
        const currentUrl = window.location.pathname // 26 Aug 2024 - Capture the current URL
        setTriggeringUrl(currentUrl) // 26 Aug 2024 - Set the triggering URL
        setIsModalOpen(true)
    }

    const handleCloseCreateContactModal = () => {
        setIsModalOpen(false)
        setTriggeringUrl(null) // 26 Aug 2024 Reset the triggering URL
        window.location.reload(); // Force the page to reload
        // Refetch the data to get the latest evaluations
        // refetch()
      }

    // 5a - Function to handle saving a reflection (create or update) - 11 Oct 2024 - updated for impact type
    const handleSaveAllImpact = async () => {

        // 11 Oct 2024 - updated with impact type

    if (allEditorValue.trim() === "") {
        alert("The editor is blank. Please enter some text before saving.");
        return;
    }
    try {

        let impactPayload;
            if (currentAllNoteId) {
                // Update existing contact note - send only the updated note
                const updatedNote = {
                    _id: currentAllNoteId,
                    userImpactAllNoteText: allEditorValue,
                    userImpactTypeId: selectedImpactTypeId,
                    userImpactAllNoteDate: userImpactAllNotes?.find(note => note._id === currentAllNoteId)?.userImpactAllNoteDate, // Preserve original creation date
                    userImpactAllNoteUpdateDate: new Date(), // Update the timestamp
                };

                impactPayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userImpactAllNotes: [updatedNote], // Send only the updated note
                };
            } else {
                // Add new contact note - send only the new note
                const newNote = {
                    userImpactAllNoteText: allEditorValue,
                    userImpactTypeId: selectedImpactTypeId,
                    userImpactAllNoteDate: new Date(), // Creation date
                    userImpactAllNoteUpdateDate: new Date(), // Update date
                };

                impactPayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userImpactAllNotes: [newNote], // Send only the new note
                };
            }

        // console.log('notes sent to backend (impactPayload):', impactPayload)
        // Call the mutation
        const newImpact = await createNewUserImpact(impactPayload).unwrap();
        // Reload the page after a successful save
        window.location.reload(); // This line reloads the page to fetch and display the saved data

        
        if (!currentAllNoteId) {
            // Update local state with the newly added reflection only if it's a new one
            setImpactAllNotes((prev) => [...prev, newImpact.data]);
        }

        setAllEditorValue(""); // Clear editor after saving
        setIsEditingAll(false); // Disable edit mode
        setCurrentAllNoteId(null); // Reset reflection ID after saving
        alert("Impact all note saved successfully");
    } catch (error) {
        console.error("Failed to save the impact all note:", error);
        alert("Failed to save the impact all note");
    }
    };

    // 5b - Function to handle saving a impact (create or update) - 11 Oct 2024 - updated for impact type - 12 Oct 2024 - refined for update logic - changed note only
    const handleSaveContactImpact = async () => {
        if (!selectedContactId || contactEditorValue.trim() === "") { 
            alert("Please select a contact and enter some text in the editor before saving.");
            return;
        }
        try {
            // console.log('selected Contact Id:', selectedContactId);
            // console.log('current Contact note Id:', currentContactNoteId);
    
            let impactPayload;
            if (currentContactNoteId) {
                // Update existing contact note - send only the updated note
                const updatedNote = {
                    _id: currentContactNoteId,
                    userContactId: selectedContactId,
                    userImpactContactNoteText: contactEditorValue,
                    userImpactTypeId: selectedImpactTypeId,
                    userImpactContactNoteDate: userImpactContactNotes?.find(note => note._id === currentContactNoteId)?.userImpactContactNoteDate, // Preserve original creation date
                    userImpactContactNoteUpdateDate: new Date(), // Update the timestamp
                };

                impactPayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userImpactContactNotes: [updatedNote], // Send only the updated note
                };
            } else {
                // Add new contact note - send only the new note
                const newNote = {
                    userContactId: selectedContactId,
                    userImpactContactNoteText: contactEditorValue,
                    userImpactTypeId: selectedImpactTypeId,
                    userImpactContactNoteDate: new Date(), // Creation date
                    userImpactContactNoteUpdateDate: new Date(), // Update date
                };

                impactPayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userImpactContactNotes: [newNote], // Send only the new note
                };
            }
        
            const newImpact = await createNewUserImpact(impactPayload).unwrap();
            // Reload the page after a successful save
            window.location.reload(); // This line reloads the page to fetch and display the saved data
    
            // Update local state with the updated/new contact notes
            setImpactContactNotes(newImpact.userImpactContactNotes || []);
    
            // Clear editor and reset states after saving
            setContactEditorValue(""); 
            setIsEditingContact(false); 
            setCurrentContactNoteId(null); 
    
            alert("Impact contact note saved successfully");
        } catch (error) {
            console.error("Failed to save the impact contact note:", error);
            alert("Failed to save the impact contact note");
        }
    };
    
    // 6a - Handle delete impact note details - 02 Oct 2024    - 11 Oct 2024 - reviewed but not updated for impact type
    const handleDeleteAllImpact = async (noteId) => {
        try {
            // console.log('noteId for deletion', noteId);
            // console.log('current noteId', currentReflectionId);
    
            // Mark the note for deletion by adding a delete: true flag
            const updatedNotes = userImpactAllNotes?.map((note) => 
                note._id === noteId ? { ...note, delete: true } : note
            );
            
            // console.log('updatedNotes with delete flag:', updatedNotes);
    
            await createNewUserImpact({
                userId,
                userOrgId,
                weekStarting: startOfWeek, // Pass the start of the week
                userImpactAllNotes: updatedNotes, // Send the updated notes array with delete flag
            }).unwrap();
    
            // Update state after successful deletion
            setImpactAllNotes((prevImpacts) =>
                prevImpacts.filter((note) => note._id !== noteId)
            ); // Optionally remove from frontend state
    
            alert("Note deleted successfully");
            window.location.reload(); // Reload the page to fetch updated data
        } catch (error) {
            console.error("Failed to delete the note:", error);
            // Add error handling logic here if needed
        }
    };
    
    // 6b - Handle delete impact note details - 02 Oct 2024    - 11 Oct 2024 - reviewed but not updated for impact type
    const handleDeleteContactImpact = async (noteId) => {
        try {
            // console.log('noteId for deletion', noteId);
            // console.log('current noteId', currentImpactId);
    
            // Mark the note for deletion by adding a delete: true flag
            const updatedNotes = userImpactContactNotes?.map((note) => 
                note._id === noteId ? { ...note, delete: true } : note
            );
            
            // console.log('updatedNotes with delete flag:', updatedNotes);
    
            await createNewUserImpact({
                userId,
                userOrgId,
                weekStarting: startOfWeek, // Pass the start of the week
                userImpactContactNotes: updatedNotes, // Send the updated notes array with delete flag
            }).unwrap();
    
            // Update state after successful deletion
            setImpactContactNotes((prevImpacts) =>
                prevImpacts.filter((note) => note._id !== noteId)
            ); // Optionally remove from frontend state
    
            alert("Note deleted successfully");
            window.location.reload(); // Reload the page to fetch updated data
        } catch (error) {
            console.error("Failed to delete the note:", error);
            // Add error handling logic here if needed
        }
    };
    
    // 7 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
    }

    return (
        <div className="bg-gray-50 rounded m-1 p-1 font-poppins">

            {/* Sections for Impact Headers */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <p className="text-xs m-1 p-1 leading-4">As you continue to make contributions, your presence and influence grow through the positive impacts you create for others. Take a moment to register and reflect on how your efforts resonate—did your contributions make a difference for those who received them? The purpose of tracking these impacts is not to limit your contributions but to help you refine, guide, and enhance them. By doing so, you can increase the value of your efforts, ensuring that others benefit even more from the meaningful work you put in.</p>
            </div>

            {/* All Impact */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <div className="grid grid-cols-10 justify-between items-center">
                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                        <div
                            onClick={() => setShowImpactAllNotes(prev => !prev)}
                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                            >
                            {showImpactAllNotes ? (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                            )}
                        </div>
                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtImpactAllIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                        <div><p className="font-bold mx-1 px-1">All</p></div>  
                    </div>
                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Impacts this week:</p>
                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{impactAllNotes?.length}</p></div>
                    </div>
                    {/* <div className="col-span-4 flex justify-end items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Add an Impact</p>
                        <button type="button" onClick={handleAddAllImpact}>
                        <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                        </button>
                    </div> */}
                </div>
                {showImpactAllNotes && (
                    <div className="bg-gray-50 m-1 p-1 rounded">
                        {impactAllNotes?.length === 0 && (
                            <p className="text-[11px] text-gray-500 italic mx-2 px21">
                                No impacts were added.
                            </p>
                        )}

                        {/* {isEditingAll ? (
                            <div className="bg-gray-50 p-1 m-1 rounded">
                                <div className="flex justify-start items-center my-1 py-1 rounded bg-gray-100">
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-white hover:bg-gray-200 cursor-pointer ml-2 pl-2" ><EtImpactTypeIcon1SVGComp  className='h-7 w-7 text-orange-600'/>
                                    </div>
                                    <p className="text-xs text-gray-600 m-1 p-1 font-bold">Impact Type:</p>
                                    <select
                                    className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none"
                                    onChange={(e) => setSelectedImpactTypeId(e.target.value)}
                                    value={selectedImpactTypeId || ""} 
                                    >
                                    {impactTypeOptions}
                                    </select>
                                </div>
                            <EtContentEditorLight2
                                value={allEditorValue}
                                handleChange={handleAllEditorChange}
                                placeholderText="Write your impact note here..."
                                editorKey={allEditorKey}
                            />
                            <div className="flex justify-start items-center m-1 p-1">
                                <button
                                type="button"
                                onClick={handleSaveAllImpact}
                                className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                                >
                                <p className="text-[10px] px-2 py-1">Save</p>
                                </button>
                                <button
                                type="button"
                                onClick={handleCancelAllImpact}
                                className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                                >
                                <p className="text-[10px] px-2 py-1">Cancel</p>
                                </button>
                            </div>
                            </div>
                                ) : null} */}

                        {/* Display Reflection Notes in Descending Order */}
                        <div className="m-1 p-1">
                            <div>
                                <p className="m-1 p-1 text-xs font-bold">Impact Trail:</p>
                            </div>
                            <div>
                                {impactAllNotes
                                    ?.slice()
                                    .sort(
                                        (a, b) =>
                                            new Date(b.userImpactAllNoteUpdateDate) - new Date(a.userImpactAllNoteUpdateDate)
                                    ) // Sort in descending order
                                    .map((note, noteIndex, arr) => (
                                        <div key={note._id} className="bg-white rounded">
                                            <RenderImpactNotes
                                                note={note}
                                                noteTextKey="userImpactAllNoteText"
                                                updateDateKey="userImpactAllNoteUpdateDate"
                                                createdDateKey="userImpactAllNoteDate"
                                                showDetail={showImpactAllNoteDetails}
                                                toggleShowDetail={handleToggleImpactAllNoteDetails}
                                                handleEditNote={handleEditAllImpact}
                                                handleDeleteNote={handleDeleteAllImpact}
                                                noteIndex={noteIndex}
                                                arr={arr}
                                                createMarkup={createMarkup}
                                                impactTypeInfo={note.userImpactTypeId}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
           
           {/* By Contact Section */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <div className="grid grid-cols-10 justify-between items-center">
                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                        <div
                            onClick={() => setShowImpactContactNotes(prev => !prev)}
                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                            >
                            {showImpactContactNotes ? (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                            )}
                        </div>
                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtImpactContactIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                        <div><p className="font-bold mx-1 px-1">By Contact</p></div>
                    </div>
                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Impacts this week:</p>
                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{impactContactNotes?.length}</p></div>
                    </div>
                    {/* <div className="col-span-4 flex justify-end items-center m-1 p-1">
                        <div className="flex justify-start items-center m-1 p-1">
                            <p className="font-bold text-xs m-1 p-1">Add a Contact</p>
                            <button type="button" onClick={()=>handleAddContact(userId, userOrgId)}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                            </button>
                        </div>
                        <div className="flex justify-start items-center m-1 p-1">
                            <p className="font-bold text-xs m-1 p-1">Add an Impact</p>
                            <button type="button" onClick={handleAddContactImpact}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                            </button>
                        </div>
                    </div> */}
                </div>
                {showImpactContactNotes && (
                    <div className="bg-gray-50 m-1 p-1 rounded">
                        {impactContactNotes?.length === 0 && (
                            <p className="text-[11px] text-gray-500 italic mx-2 px21">
                                No impacts were added.
                            </p>
                        )}

                        {/* {isEditingContact ? (
                            <div className="bg-gray-50 rounded">
                               
                                <div className="flex justify-start items-center my-1 py-1 rounded bg-gray-100">
                                    <div className="flex justify-center items-center h-8 w-8 bg-white hover:bg-gray-200 rounded-full m-1">
                                        <UserIcon className='text-orange-600 h-6 w-6' />
                                    </div>
                                    <div><p className="text-xs text-gray-600 m-1 p-1 font-bold">Contact:</p></div>
                                    <select
                                    className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none "
                                    // onChange={(e) => setSelectedContactId(e.target.value)}
                                    onChange={(e) => {
                                        const selectedContact = impactContacts.find(contact => contact._id === e.target.value);
                                        setSelectedContactId(e.target.value);
                                        setSelectedContactType(selectedContact?.contactTypeId?.contactTypeName || ""); // Set the contact type for filtering
                                    }}
                                    
                                    value={selectedContactId || ""} 
                                    disabled={!!currentContactNoteId}
                                    >
                                    {contactOptions}
                                    </select>

                                    <div className="flex justify-start items-center mx-2 px-2">
                                        <div className="flex justify-center items-center rounded-full h-8 w-8 bg-white hover:bg-gray-200 cursor-pointer ml-2 pl-2" ><EtImpactTypeIcon1SVGComp  className='h-7 w-7 text-orange-600'/>
                                        </div>
                                        <p className="text-xs text-gray-600 m-1 p-1 font-bold">Impact Type:</p>
                                        <select
                                        className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none"
                                        onChange={(e) => setSelectedImpactTypeId(e.target.value)}
                                        value={selectedImpactTypeId || ""} 
                                        >
                                        {impactTypeOptions}
                                        </select>
                                    </div>

                                </div>
                                
                                <EtContentEditorLight2
                                    value={contactEditorValue}
                                    handleChange={handleContactEditorChange}
                                    placeholderText="Write your impact note here..."
                                    editorKey={contactEditorKey}
                                />
                                <div className="flex justify-start items-center m-1 p-1">
                                    <button
                                    type="button"
                                    onClick={handleSaveContactImpact}
                                    className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                                    >
                                    <p className="text-[10px] px-2 py-1">Save</p>
                                    </button>
                                    <button
                                    type="button"
                                    onClick={handleCancelContactImpact}
                                    className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                                    >
                                    <p className="text-[10px] px-2 py-1">Cancel</p>
                                    </button>
                                </div>
                            </div>
                                ) : null} */}

                        {/* Display Reflection Notes in Descending Order */}
                        <div className="m-1 p-1">
                            <div>
                                <p className="m-1 p-1 text-xs font-bold">Contact Impact Trail:</p>
                            </div>
                            <div>
                                {impactContactNotes
                                    ?.slice()
                                    .sort(
                                        (a, b) =>
                                            new Date(b.userImpactContactNoteUpdateDate) - new Date(a.userImpactContactNoteUpdateDate)
                                    ) // Sort in descending order
                                    .map((note, noteIndex, arr) => (
                                        <div key={note._id} className="bg-white rounded">
                                            <RenderContactImpactNotes
                                                note={note}
                                                noteTextKey="userImpactContactNoteText"
                                                updateDateKey="userImpactContactNoteUpdateDate"
                                                createdDateKey="userImpactContactNoteDate"
                                                showDetail={showImpactContactNoteDetails}
                                                toggleShowDetail={handleToggleImpactContactNoteDetails}
                                                handleEditNote={handleEditContactImpact}
                                                handleDeleteNote={handleDeleteContactImpact}
                                                noteIndex={noteIndex}
                                                arr={arr}
                                                createMarkup={createMarkup}
                                                impactTypeInfo={note.userImpactTypeId}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
                <EtModalCreateContact 
                    isOpen={isModalOpen}
                    userId={userId}
                    userOrgId={userOrgId}
                    triggeringUrl={triggeringUrl}
                    onClose={handleCloseCreateContactModal}
                />
            </div>
        </div>
    )

}

const WABWins = ({userWinNotes = [], startOfWeek, endOfWeek, userId, userOrgId, winContacts, winTypes}) => {
    // First Created - 13 Oct 2024
    // Author - Atul Pandey
    // Inherited from WABWins
   
    // 0 - check for incoming data:
    // console.log('winContacts', winContacts)
    // console.log('winTypes', winTypes)

    const userWinContactNotes = userWinNotes[0]?.userWinNoteId?.userWinContactNotes || []
    const userWinAllNotes = userWinNotes[0]?.userWinNoteId?.userWinAllNotes || []

    // 1a - State for Modal -- 03 Oct 2024
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [triggeringUrl, setTriggeringUrl] = useState(null)
    
    // 1b - Add state management for win notes
    const [showWinAllNotes, setShowWinAllNotes] = useState(false)
    const [showWinContactNotes, setShowWinContactNotes] = useState(false)

    const [showWinContactNoteDetails, setShowWinContactNoteDetails] = useState({}) 
    const [showWinAllNoteDetails, setShowWinAllNoteDetails] = useState({}) 
    const [winContactNotes, setWinContactNotes] = useState(userWinContactNotes);
    const [winAllNotes, setWinAllNotes] = useState(userWinAllNotes);

    // console.log('win all notes', winAllNotes)
    // console.log('win contact notes', winContactNotes)

    // 1c - State variables for the win editor (similar to reflections)
    const [allEditorValue, setAllEditorValue] = useState(() => {
        const latestAllNote = userWinAllNotes?.slice(-1)[0];
        return latestAllNote ? latestAllNote.userWinAllNoteText : "";
      });
      
      const [currentAllNoteId, setCurrentAllNoteId] = useState(() => {
        const latestAllNote = userWinAllNotes?.slice(-1)[0];
        return latestAllNote ? latestAllNote._id : null;
      });

    const [isEditingAll, setIsEditingAll] = useState(false);
    const [allEditorKey, setAllEditorKey] = useState("allWinNote-" + Date.now());
    
    // 1d - State variables for the win editor (similar to reflections)
    const [selectedContactId, setSelectedContactId] = useState(""); // Holds the selected contact ID
    const [selectedContactType, setSelectedContactType] = useState("") // 11 Oct 2024 - for filtering contact Type

    const [contactEditorValue, setContactEditorValue] = useState(() => {
        const latestContactNote = userWinContactNotes?.slice(-1)[0];
        return latestContactNote ? latestContactNote.userWinContactNoteText : "";
      });
      
    const [currentContactNoteId, setCurrentContactNoteId] = useState(() => {
        const latestContactNote = userWinContactNotes?.slice(-1)[0];
        return latestContactNote ? latestContactNote._id : null;
      });
      
    const [isEditingContact, setIsEditingContact] = useState(false);
    const [contactEditorKey, setContactEditorKey] = useState("contactWinNote-" + Date.now());

    // 1e - State variable for selected win type - 11 Oct 2024
    const [selectedWinTypeId, setSelectedWinTypeId] = useState("")
    // console.log('selectedWinTypeId', selectedWinTypeId)

    // 2 - Initialize the mutation
    const [createNewUserWin] = useCreateNewUserWinMutation();

    // 3a - Handle Editor Value change
    const handleAllEditorChange = (value) => {
        setAllEditorValue(value);
        };

    // 3a - Handle Editor Value change
    const handleContactEditorChange = (value) => {
        setContactEditorValue(value);
        };
    
    // 4a - Handle adding a new win
    const handleAddAllWin = useCallback(() => {
        setAllEditorValue(""); // Clear editor
        setCurrentAllNoteId(null); // Reset win ID
        setIsEditingAll(true); // Enable edit mode
        setAllEditorKey("allWinNote-" + Date.now()); // Reset editor key
        setShowWinAllNotes(true)
    }, []);

    // 4b - Handle editing an existing reflection
    const handleEditAllWin = (note) => {
    setAllEditorValue(note.userWinAllNoteText); // Load note into editor
    setCurrentAllNoteId(note._id); // Track the reflection being edited
    setIsEditingAll(true); // Enable edit mode
    setSelectedWinTypeId(note.userWinTypeId._id) // 11 Oct 2024
    };

    // 4c - Function to handle editing a note 
    const handleCancelAllWin = (note) => {
    setAllEditorValue("")
    setCurrentAllNoteId(null)
    setIsEditingAll(false)
    }
    
    // 4d - Toggle Show function for reflection note details 
    const handleToggleWinAllNoteDetails = (noteId) => {
    setShowWinAllNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
    }))
    }

    // 4e - Handle adding a new win
    const handleAddContactWin = useCallback(() => {
        setContactEditorValue(""); // Clear editor
        setCurrentContactNoteId(null); // Reset win ID
        setIsEditingContact(true); // Enable edit mode
        setContactEditorKey("contactWinNote-" + Date.now()); // Reset editor key
        setSelectedWinTypeId(""); // 11 Oct 2024 Reset the win type ID
        setSelectedContactId(""); // 11 Oct 2024 Reset contact selection
        setSelectedContactType(""); // 11 Oct 2024 Reset the contact type to ensure no leftover filtering
        setShowWinContactNotes(true)
    }, []);

    // 4f - Handle editing an existing reflection
    const handleEditContactWin = (note) => {
        // console.log('note', note)
    setContactEditorValue(note.userWinContactNoteText); // Load note into editor
    setCurrentContactNoteId(note._id); // Track the reflection being edited
    setSelectedContactId(note.userContactId._id); // Set the contact ID for pre-selecting the contact
    // console.log('currentContactNoteId in handleEdit function:', currentContactNoteId)
    setIsEditingContact(true); // Enable edit mode
    setSelectedWinTypeId(note.userWinTypeId._id) // 11 Oct 2024
    // setSelectedContactId(""); // 11 Oct 2024 Reset contact selection
    setSelectedContactType(note.userContactId?.contactTypeId?.contactTypeName) // 11 Oct 2024
    };

    // 4g - Function to handle editing a note 
    const handleCancelContactWin = (note) => {
    setContactEditorValue("")
    setCurrentContactNoteId(null)
    setIsEditingContact(false)
    setSelectedWinTypeId(""); // Reset win type
    setSelectedContactType(""); // Reset contact type to clear filtering
    }
    
    // 4h - Toggle Show function for reflection note details 
    const handleToggleWinContactNoteDetails = (noteId) => {
    setShowWinContactNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
    }))
    }

    // 4i - Create options to select a contact from the list of win contacts - 02 Oct 2024
    const contactOptions = winContacts ? [
    <option key="placeholder" value="" disabled >
      Select a Contact
    </option>,
    ...winContacts?.map(contact => (
      <option key={contact._id} value={contact._id}>
        {contact.contactName}
      </option>
    )),
    ] : [];
    // console.log('contactOptions',contactOptions )

    // 4j - Create options to select a win type from the list of win types - 10 Oct 2024
    const winTypeOptions = winTypes ? [
    <option key="placeholder" value="" disabled selected>
      Select a Win Type
    </option>,
    ...winTypes?.map(type => (
      <option key={type._id} value={type._id}>
        {type.winTypeName}
      </option>
    )),
    ] : [];
    
    // 4k - Function to handle adding a new contact for the userId, userOrgId - 03 Oct 2024
    const handleAddContact = (userId, userOrgId) => {
        const currentUrl = window.location.pathname // 26 Aug 2024 - Capture the current URL
        setTriggeringUrl(currentUrl) // 26 Aug 2024 - Set the triggering URL
        setIsModalOpen(true)
    }

    const handleCloseCreateContactModal = () => {
        setIsModalOpen(false)
        setTriggeringUrl(null) // 26 Aug 2024 Reset the triggering URL
        window.location.reload(); // Force the page to reload
        // Refetch the data to get the latest evaluations
        // refetch()
      }

    // 5a - Function to handle saving a reflection (create or update) - 11 Oct 2024 - updated for win type
    const handleSaveAllWin = async () => {

        // 11 Oct 2024 - updated with win type

    if (allEditorValue.trim() === "") {
        alert("The editor is blank. Please enter some text before saving.");
        return;
    }
    try {

        let winPayload;
            if (currentAllNoteId) {
                // Update existing contact note - send only the updated note
                const updatedNote = {
                    _id: currentAllNoteId,
                    userWinAllNoteText: allEditorValue,
                    userWinTypeId: selectedWinTypeId,
                    userWinAllNoteDate: userWinAllNotes?.find(note => note._id === currentAllNoteId)?.userWinAllNoteDate, // Preserve original creation date
                    userWinAllNoteUpdateDate: new Date(), // Update the timestamp
                };

                winPayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userWinAllNotes: [updatedNote], // Send only the updated note
                };
            } else {
                // Add new contact note - send only the new note
                const newNote = {
                    userWinAllNoteText: allEditorValue,
                    userWinTypeId: selectedWinTypeId,
                    userWinAllNoteDate: new Date(), // Creation date
                    userWinAllNoteUpdateDate: new Date(), // Update date
                };

                winPayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userWinAllNotes: [newNote], // Send only the new note
                };
            }

        // console.log('notes sent to backend (winPayload):', winPayload)
        // Call the mutation
        const newWin = await createNewUserWin(winPayload).unwrap();
        // Reload the page after a successful save
        window.location.reload(); // This line reloads the page to fetch and display the saved data

        
        if (!currentAllNoteId) {
            // Update local state with the newly added reflection only if it's a new one
            setWinAllNotes((prev) => [...prev, newWin.data]);
        }

        setAllEditorValue(""); // Clear editor after saving
        setIsEditingAll(false); // Disable edit mode
        setCurrentAllNoteId(null); // Reset reflection ID after saving
        alert("Win all note saved successfully");
    } catch (error) {
        console.error("Failed to save the win all note:", error);
        alert("Failed to save the win all note");
    }
    };

    // 5b - Function to handle saving a win (create or update) - 11 Oct 2024 - updated for win type - 12 Oct 2024 - refined for update logic - changed note only
    const handleSaveContactWin = async () => {
        if (!selectedContactId || contactEditorValue.trim() === "") { 
            alert("Please select a contact and enter some text in the editor before saving.");
            return;
        }
        try {
            // console.log('selected Contact Id:', selectedContactId);
            // console.log('current Contact note Id:', currentContactNoteId);
    
            let winPayload;
            if (currentContactNoteId) {
                // Update existing contact note - send only the updated note
                const updatedNote = {
                    _id: currentContactNoteId,
                    userContactId: selectedContactId,
                    userWinContactNoteText: contactEditorValue,
                    userWinTypeId: selectedWinTypeId,
                    userWinContactNoteDate: userWinContactNotes?.find(note => note._id === currentContactNoteId)?.userWinContactNoteDate, // Preserve original creation date
                    userWinContactNoteUpdateDate: new Date(), // Update the timestamp
                };

                winPayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userWinContactNotes: [updatedNote], // Send only the updated note
                };
            } else {
                // Add new contact note - send only the new note
                const newNote = {
                    userContactId: selectedContactId,
                    userWinContactNoteText: contactEditorValue,
                    userWinTypeId: selectedWinTypeId,
                    userWinContactNoteDate: new Date(), // Creation date
                    userWinContactNoteUpdateDate: new Date(), // Update date
                };

                winPayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userWinContactNotes: [newNote], // Send only the new note
                };
            }
        
            const newWin = await createNewUserWin(winPayload).unwrap();
            // Reload the page after a successful save
            window.location.reload(); // This line reloads the page to fetch and display the saved data
    
            // Update local state with the updated/new contact notes
            setWinContactNotes(newWin.userWinContactNotes || []);
    
            // Clear editor and reset states after saving
            setContactEditorValue(""); 
            setIsEditingContact(false); 
            setCurrentContactNoteId(null); 
    
            alert("Win contact note saved successfully");
        } catch (error) {
            console.error("Failed to save the win contact note:", error);
            alert("Failed to save the win contact note");
        }
    };
    
    // 6a - Handle delete win note details - 02 Oct 2024    - 11 Oct 2024 - reviewed but not updated for win type
    const handleDeleteAllWin = async (noteId) => {
        try {
            // console.log('noteId for deletion', noteId);
            // console.log('current noteId', currentReflectionId);
    
            // Mark the note for deletion by adding a delete: true flag
            const updatedNotes = userWinAllNotes?.map((note) => 
                note._id === noteId ? { ...note, delete: true } : note
            );
            
            // console.log('updatedNotes with delete flag:', updatedNotes);
    
            await createNewUserWin({
                userId,
                userOrgId,
                weekStarting: startOfWeek, // Pass the start of the week
                userWinAllNotes: updatedNotes, // Send the updated notes array with delete flag
            }).unwrap();
    
            // Update state after successful deletion
            setWinAllNotes((prevWins) =>
                prevWins.filter((note) => note._id !== noteId)
            ); // Optionally remove from frontend state
    
            alert("Note deleted successfully");
            window.location.reload(); // Reload the page to fetch updated data
        } catch (error) {
            console.error("Failed to delete the note:", error);
            // Add error handling logic here if needed
        }
    };
    
    // 6b - Handle delete win note details - 02 Oct 2024  - 11 Oct 2024 - reviewed but not updated for win type
    const handleDeleteContactWin = async (noteId) => {
        try {
            // console.log('noteId for deletion', noteId);
            // console.log('current noteId', currentWinId);
    
            // Mark the note for deletion by adding a delete: true flag
            const updatedNotes = userWinContactNotes?.map((note) => 
                note._id === noteId ? { ...note, delete: true } : note
            );
            
            // console.log('updatedNotes with delete flag:', updatedNotes);
    
            await createNewUserWin({
                userId,
                userOrgId,
                weekStarting: startOfWeek, // Pass the start of the week
                userWinContactNotes: updatedNotes, // Send the updated notes array with delete flag
            }).unwrap();
    
            // Update state after successful deletion
            setWinContactNotes((prevWins) =>
                prevWins.filter((note) => note._id !== noteId)
            ); // Optionally remove from frontend state
    
            alert("Note deleted successfully");
            window.location.reload(); // Reload the page to fetch updated data
        } catch (error) {
            console.error("Failed to delete the note:", error);
            // Add error handling logic here if needed
        }
    };
    
    // 7 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
    }

    return (
        <div className="bg-gray-50 rounded m-1 p-1 font-poppins">

            {/* Sections for Win Headers */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <p className="text-xs m-1 p-1 leading-4">There’s nothing sweeter than seeing your efforts transform into the outcomes you envisioned. A win is more than just a reward for your hard work—it’s a powerful reminder of your untapped potential. Let each accolade inspire you to keep striving for greater contributions and impact, while guarding against distractions or complacency. Winning is a never-ending journey, and to continue achieving, we must always stay prepared, focused, and ready for the next challenge.</p>
            </div>

            {/* All Win */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <div className="grid grid-cols-10 justify-between items-center">
                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                        <div
                            onClick={() => setShowWinAllNotes(prev => !prev)}
                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                            >
                            {showWinAllNotes ? (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                            )}
                        </div>
                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtWinAllIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                        <div><p className="font-bold mx-1 px-1">All</p></div>  
                    </div>
                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Wins this week:</p>
                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{winAllNotes?.length}</p></div>
                    </div>
                    {/* <div className="col-span-4 flex justify-end items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Add a Win</p>
                        <button type="button" onClick={handleAddAllWin}>
                        <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                        </button>
                    </div> */}
                </div>
                {showWinAllNotes && (
                    <div className="bg-gray-50 m-1 p-1 rounded">
                        {winAllNotes?.length === 0 && (
                            <p className="text-[11px] text-gray-500 italic mx-2 px21">
                                No wins were added.
                            </p>
                        )}

                        {/* {isEditingAll ? (
                            <div className="bg-gray-50 p-1 m-1 rounded">
                                <div className="flex justify-start items-center my-1 py-1 rounded bg-gray-100">
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-white hover:bg-gray-200 cursor-pointer ml-2 pl-2" ><EtWinTypeIcon1SVGComp  className='h-7 w-7 text-orange-600'/>
                                    </div>
                                    <p className="text-xs text-gray-600 m-1 p-1 font-bold">Win Type:</p>
                                    <select
                                    className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none"
                                    onChange={(e) => setSelectedWinTypeId(e.target.value)}
                                    value={selectedWinTypeId || ""} 
                                    >
                                    {winTypeOptions}
                                    </select>
                                </div>
                            <EtContentEditorLight2
                                value={allEditorValue}
                                handleChange={handleAllEditorChange}
                                placeholderText="Write your win note here..."
                                editorKey={allEditorKey}
                            />
                            <div className="flex justify-start items-center m-1 p-1">
                                <button
                                type="button"
                                onClick={handleSaveAllWin}
                                className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                                >
                                <p className="text-[10px] px-2 py-1">Save</p>
                                </button>
                                <button
                                type="button"
                                onClick={handleCancelAllWin}
                                className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                                >
                                <p className="text-[10px] px-2 py-1">Cancel</p>
                                </button>
                            </div>
                            </div>
                                ) : null} */}

                        {/* Display Reflection Notes in Descending Order */}
                        <div className="m-1 p-1">
                            <div>
                                <p className="m-1 p-1 text-xs font-bold">Win Trail:</p>
                            </div>
                            <div>
                                {winAllNotes
                                    ?.slice()
                                    .sort(
                                        (a, b) =>
                                            new Date(b.userWinAllNoteUpdateDate) - new Date(a.userWinAllNoteUpdateDate)
                                    ) // Sort in descending order
                                    .map((note, noteIndex, arr) => (
                                        <div key={note._id} className="bg-white rounded">
                                            <RenderWinNotes
                                                note={note}
                                                noteTextKey="userWinAllNoteText"
                                                updateDateKey="userWinAllNoteUpdateDate"
                                                createdDateKey="userWinAllNoteDate"
                                                showDetail={showWinAllNoteDetails}
                                                toggleShowDetail={handleToggleWinAllNoteDetails}
                                                handleEditNote={handleEditAllWin}
                                                handleDeleteNote={handleDeleteAllWin}
                                                noteIndex={noteIndex}
                                                arr={arr}
                                                createMarkup={createMarkup}
                                                winTypeInfo={note.userWinTypeId}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
           
           {/* By Contact Section */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <div className="grid grid-cols-10 justify-between items-center">
                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                        <div
                            onClick={() => setShowWinContactNotes(prev => !prev)}
                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                            >
                            {showWinContactNotes ? (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                            )}
                        </div>
                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtWinContactIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                        <div><p className="font-bold mx-1 px-1">By Contact</p></div>
                    </div>
                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Wins this week:</p>
                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{winContactNotes?.length}</p></div>
                    </div>
                    {/* <div className="col-span-4 flex justify-end items-center m-1 p-1">
                        <div className="flex justify-start items-center m-1 p-1">
                            <p className="font-bold text-xs m-1 p-1">Add a Contact</p>
                            <button type="button" onClick={()=>handleAddContact(userId, userOrgId)}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                            </button>
                        </div>
                        <div className="flex justify-start items-center m-1 p-1">
                            <p className="font-bold text-xs m-1 p-1">Add a Win</p>
                            <button type="button" onClick={handleAddContactWin}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                            </button>
                        </div>
                    </div> */}
                </div>
                {showWinContactNotes && (
                    <div className="bg-gray-50 m-1 p-1 rounded">
                        {winContactNotes?.length === 0 && (
                            <p className="text-[11px] text-gray-500 italic mx-2 px21">
                                No wins were added.
                            </p>
                        )}

                        {/* {isEditingContact ? (
                            <div className="bg-gray-50 rounded">
                                
                                <div className="flex justify-start items-center my-1 py-1 rounded bg-gray-100">
                                    <div className="flex justify-center items-center h-8 w-8 bg-white hover:bg-gray-200 rounded-full m-1">
                                        <UserIcon className='text-orange-600 h-6 w-6' />
                                    </div>
                                    <div><p className="text-xs text-gray-600 m-1 p-1 font-bold">Contact:</p></div>
                                    <select
                                    className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none "
                                    // onChange={(e) => setSelectedContactId(e.target.value)}
                                    onChange={(e) => {
                                        const selectedContact = winContacts.find(contact => contact._id === e.target.value);
                                        setSelectedContactId(e.target.value);
                                        setSelectedContactType(selectedContact?.contactTypeId?.contactTypeName || ""); // Set the contact type for filtering
                                    }}
                                    
                                    value={selectedContactId || ""} 
                                    disabled={!!currentContactNoteId}
                                    >
                                    {contactOptions}
                                    </select>

                                    <div className="flex justify-start items-center mx-2 px-2">
                                        <div className="flex justify-center items-center rounded-full h-8 w-8 bg-white hover:bg-gray-200 cursor-pointer ml-2 pl-2" ><EtWinTypeIcon1SVGComp  className='h-7 w-7 text-orange-600'/>
                                        </div>
                                        <p className="text-xs text-gray-600 m-1 p-1 font-bold">Win Type:</p>
                                        <select
                                        className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none"
                                        onChange={(e) => setSelectedWinTypeId(e.target.value)}
                                        value={selectedWinTypeId || ""} 
                                        >
                                        {winTypeOptions}
                                        </select>
                                    </div>

                                </div>
                                
                                <EtContentEditorLight2
                                    value={contactEditorValue}
                                    handleChange={handleContactEditorChange}
                                    placeholderText="Write your win note here..."
                                    editorKey={contactEditorKey}
                                />
                                <div className="flex justify-start items-center m-1 p-1">
                                    <button
                                    type="button"
                                    onClick={handleSaveContactWin}
                                    className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                                    >
                                    <p className="text-[10px] px-2 py-1">Save</p>
                                    </button>
                                    <button
                                    type="button"
                                    onClick={handleCancelContactWin}
                                    className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                                    >
                                    <p className="text-[10px] px-2 py-1">Cancel</p>
                                    </button>
                                </div>
                            </div>
                                ) : null} */}

                        {/* Display Reflection Notes in Descending Order */}
                        <div className="m-1 p-1">
                            <div>
                                <p className="m-1 p-1 text-xs font-bold">Contact Win Trail:</p>
                            </div>
                            <div>
                                {winContactNotes
                                    ?.slice()
                                    .sort(
                                        (a, b) =>
                                            new Date(b.userWinContactNoteUpdateDate) - new Date(a.userWinContactNoteUpdateDate)
                                    ) // Sort in descending order
                                    .map((note, noteIndex, arr) => (
                                        <div key={note._id} className="bg-white rounded">
                                            <RenderContactWinNotes
                                                note={note}
                                                noteTextKey="userWinContactNoteText"
                                                updateDateKey="userWinContactNoteUpdateDate"
                                                createdDateKey="userWinContactNoteDate"
                                                showDetail={showWinContactNoteDetails}
                                                toggleShowDetail={handleToggleWinContactNoteDetails}
                                                handleEditNote={handleEditContactWin}
                                                handleDeleteNote={handleDeleteContactWin}
                                                noteIndex={noteIndex}
                                                arr={arr}
                                                createMarkup={createMarkup}
                                                winTypeInfo={note.userWinTypeId}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
                <EtModalCreateContact 
                    isOpen={isModalOpen}
                    userId={userId}
                    userOrgId={userOrgId}
                    triggeringUrl={triggeringUrl}
                    onClose={handleCloseCreateContactModal}
                />
            </div>
        </div>
    )

}

const WABChallenges = ({userChallengeWABNotes = [], startOfWeek, endOfWeek, userId, userOrgId, userChallengeContacts, userChallengeTypes}) => {
    // First Created - 13 Oct 2024
    // Author - Atul Pandey
    // Inherited from WABUserChallenges
   
    // 0 - check for incoming data:
    // console.log('userChallengeContacts', userChallengeContacts)
    // console.log('userChallengeTypes', userChallengeTypes)
    const userChallengeContactNotesData = userChallengeWABNotes[0]?.userChallengeWABNoteId?.userChallengeWABContactNotes || []
    const userChallengeAllNotesData = userChallengeWABNotes[0]?.userChallengeWABNoteId?.userChallengeWABAllNotes || []

    // 1a - State for Modal -- 03 Oct 2024
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [triggeringUrl, setTriggeringUrl] = useState(null)
    
    // 1b - Add state management for userChallenge notes
    const [showUserChallengeAllNotes, setShowUserChallengeAllNotes] = useState(false)
    const [showUserChallengeContactNotes, setShowUserChallengeContactNotes] = useState(false)

    const [showUserChallengeContactNoteDetails, setShowUserChallengeContactNoteDetails] = useState({}) 
    const [showUserChallengeAllNoteDetails, setShowUserChallengeAllNoteDetails] = useState({}) 
    const [userChallengeContactNotes, setUserChallengeContactNotes] = useState(userChallengeContactNotesData);
    const [userChallengeAllNotes, setUserChallengeAllNotes] = useState(userChallengeAllNotesData);

    // console.log('userChallenge all incoming notes', userChallengeAllNotesData)
    // console.log('userChallenge contact notes', userChallengeContactNotes)

    // 1c - State variables for the win editor (similar to reflections)
    const [allEditorValue, setAllEditorValue] = useState(() => {
        const latestAllNote = userChallengeAllNotes?.slice(-1)[0];
        return latestAllNote ? latestAllNote.userChallengeAllNoteText : "";
      });
      
      const [currentAllNoteId, setCurrentAllNoteId] = useState(() => {
        const latestAllNote = userChallengeAllNotes?.slice(-1)[0];
        return latestAllNote ? latestAllNote._id : null;
      });

    const [isEditingAll, setIsEditingAll] = useState(false);
    const [allEditorKey, setAllEditorKey] = useState("allUserChallengeNote-" + Date.now());
    
    // 1d - State variables for the userChallenge editor (similar to reflections)
    const [selectedContactId, setSelectedContactId] = useState(""); // Holds the selected contact ID
    const [selectedContactType, setSelectedContactType] = useState("") // 11 Oct 2024 - for filtering contact Type

    const [contactEditorValue, setContactEditorValue] = useState(() => {
        const latestContactNote = userChallengeContactNotes?.slice(-1)[0];
        return latestContactNote ? latestContactNote.userChallengeContactNoteText : "";
      });
      
    const [currentContactNoteId, setCurrentContactNoteId] = useState(() => {
        const latestContactNote = userChallengeContactNotes?.slice(-1)[0];
        return latestContactNote ? latestContactNote._id : null;
      });
      
    const [isEditingContact, setIsEditingContact] = useState(false);
    const [contactEditorKey, setContactEditorKey] = useState("contactUserChallengeNote-" + Date.now());

    // 1e - State variable for selected userChallenge type - 11 Oct 2024
    const [selectedUserChallengeTypeId, setSelectedUserChallengeTypeId] = useState("")
    // console.log('selectedUserChallengeTypeId', selectedUserChallengeTypeId)

    // 2 - Initialize the mutation
    const [createNewUserChallengeWAB] = useCreateNewUserChallengeWABMutation();

    // 3a - Handle Editor Value change
    const handleAllEditorChange = (value) => {
        setAllEditorValue(value);
        };

    // 3a - Handle Editor Value change
    const handleContactEditorChange = (value) => {
        setContactEditorValue(value);
        };
    
    // 4a - Handle adding a new userChallenge
    const handleAddAllUserChallenge = useCallback(() => {
        setAllEditorValue(""); // Clear editor
        setCurrentAllNoteId(null); // Reset userChallenge ID
        setIsEditingAll(true); // Enable edit mode
        setAllEditorKey("allUserChallengeNote-" + Date.now()); // Reset editor key
        setShowUserChallengeAllNotes(true)
    }, []);

    // 4b - Handle editing an existing reflection
    const handleEditAllUserChallenge = (note) => {
    setAllEditorValue(note.userChallengeWABAllNoteText); // Load note into editor
    setCurrentAllNoteId(note._id); // Track the reflection being edited
    setIsEditingAll(true); // Enable edit mode
    setSelectedUserChallengeTypeId(note.userChallengeWABTypeId._id) // 11 Oct 2024
    };

    // 4c - Function to handle editing a note 
    const handleCancelAllUserChallenge = (note) => {
    setAllEditorValue("")
    setCurrentAllNoteId(null)
    setIsEditingAll(false)
    }
    
    // 4d - Toggle Show function for reflection note details 
    const handleToggleUserChallengeAllNoteDetails = (noteId) => {
    setShowUserChallengeAllNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
    }))
    }

    // 4e - Handle adding a new user challenge
    const handleAddContactUserChallenge = useCallback(() => {
        setContactEditorValue(""); // Clear editor
        setCurrentContactNoteId(null); // Reset userChallenge ID
        setIsEditingContact(true); // Enable edit mode
        setContactEditorKey("contactUserChallengeNote-" + Date.now()); // Reset editor key
        setSelectedUserChallengeTypeId(""); // 11 Oct 2024 Reset the userChallenge type ID
        setSelectedContactId(""); // 11 Oct 2024 Reset contact selection
        setSelectedContactType(""); // 11 Oct 2024 Reset the contact type to ensure no leftover filtering
        setShowUserChallengeContactNotes(true)
    }, []);

    // 4f - Handle editing an existing user challenge
    const handleEditContactUserChallenge = (note) => {
        // console.log('note', note)
    setContactEditorValue(note.userChallengeWABContactNoteText); // Load note into editor
    setCurrentContactNoteId(note._id); // Track the reflection being edited
    setSelectedContactId(note.userContactId._id); // Set the contact ID for pre-selecting the contact
    // console.log('currentContactNoteId in handleEdit function:', currentContactNoteId)
    setIsEditingContact(true); // Enable edit mode
    setSelectedUserChallengeTypeId(note.userChallengeWABTypeId._id) // 11 Oct 2024
    // setSelectedContactId(""); // 11 Oct 2024 Reset contact selection
    setSelectedContactType(note.userContactId?.contactTypeId?.contactTypeName) // 11 Oct 2024
    };

    // 4g - Function to handle editing a note 
    const handleCancelContactUserChallenge = (note) => {
    setContactEditorValue("")
    setCurrentContactNoteId(null)
    setIsEditingContact(false)
    setSelectedUserChallengeTypeId(""); // Reset win type
    setSelectedContactType(""); // Reset contact type to clear filtering
    }
    
    // 4h - Toggle Show function for reflection note details 
    const handleToggleUserChallengeContactNoteDetails = (noteId) => {
    setShowUserChallengeContactNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
    }))
    }

    // 4i - Create options to select a contact from the list of user challenge contacts - 02 Oct 2024
    const contactOptions = userChallengeContacts ? [
    <option key="placeholder" value="" disabled >
      Select a Contact
    </option>,
    ...userChallengeContacts?.map(contact => (
      <option key={contact._id} value={contact._id}>
        {contact.contactName}
      </option>
    )),
    ] : [];
    // console.log('contactOptions',contactOptions )

    // 4j - Create options to select a userChallenge type from the list of userChallenge types - 10 Oct 2024
    const userChallengeTypeOptions = userChallengeTypes ? [
    <option key="placeholder" value="" disabled selected>
      Select a UserChallenge Type
    </option>,
    ...userChallengeTypes?.map(type => (
      <option key={type._id} value={type._id}>
        {type.userChallengeTypeName}
      </option>
    )),
    ] : [];
    
    // 4k - Function to handle adding a new contact for the userId, userOrgId - 03 Oct 2024
    const handleAddContact = (userId, userOrgId) => {
        const currentUrl = window.location.pathname // 26 Aug 2024 - Capture the current URL
        setTriggeringUrl(currentUrl) // 26 Aug 2024 - Set the triggering URL
        setIsModalOpen(true)
    }

    const handleCloseCreateContactModal = () => {
        setIsModalOpen(false)
        setTriggeringUrl(null) // 26 Aug 2024 Reset the triggering URL
        window.location.reload(); // Force the page to reload
        // Refetch the data to get the latest evaluations
        // refetch()
      }

    // 5a - Function to handle saving a user challenge (create or update) - 11 Oct 2024 - updated for win type
    const handleSaveAllUserChallenge = async () => {

        // 11 Oct 2024 - updated with userChallenge type

    if (allEditorValue.trim() === "") {
        alert("The editor is blank. Please enter some text before saving.");
        return;
    }
    try {

        let userChallengePayload;
            if (currentAllNoteId) {
                // Update existing contact note - send only the updated note
                const updatedNote = {
                    _id: currentAllNoteId,
                    userChallengeWABAllNoteText: allEditorValue,
                    userChallengeWABTypeId: selectedUserChallengeTypeId,
                    userChallengeWABAllNoteDate: userChallengeAllNotes?.find(note => note._id === currentAllNoteId)?.userChallengeWABAllNoteDate, // Preserve original creation date
                    userChallengeWABAllNoteUpdateDate: new Date(), // Update the timestamp
                };

                userChallengePayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userChallengeWABAllNotes: [updatedNote], // Send only the updated note
                };
            } else {
                // Add new contact note - send only the new note
                const newNote = {
                    userChallengeWABAllNoteText: allEditorValue,
                    userChallengeWABTypeId: selectedUserChallengeTypeId,
                    userChallengeWABAllNoteDate: new Date(), // Creation date
                    userChallengeWABAllNoteUpdateDate: new Date(), // Update date
                };

                userChallengePayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userChallengeWABAllNotes: [newNote], // Send only the new note
                };
            }

        // console.log('notes sent to backend (userChallengePayload):', userChallengePayload)
        // Call the mutation
        const newUserChallenge = await createNewUserChallengeWAB(userChallengePayload).unwrap();
        // Reload the page after a successful save
        window.location.reload(); // This line reloads the page to fetch and display the saved data

        
        if (!currentAllNoteId) {
            // Update local state with the newly added reflection only if it's a new one
            setUserChallengeAllNotes((prev) => [...prev, newUserChallenge.data]);
        }

        setAllEditorValue(""); // Clear editor after saving
        setIsEditingAll(false); // Disable edit mode
        setCurrentAllNoteId(null); // Reset reflection ID after saving
        alert("User Challenge all note saved successfully");
    } catch (error) {
        console.error("Failed to save the user Challenge all note:", error);
        alert("Failed to save the user Challenge all note");
    }
    };

    // 5b - Function to handle saving a user challenge (create or update) - 11 Oct 2024 - updated for win type - 12 Oct 2024 - refined for update logic - changed note only
    const handleSaveContactUserChallenge = async () => {
        if (!selectedContactId || contactEditorValue.trim() === "") { 
            alert("Please select a contact and enter some text in the editor before saving.");
            return;
        }
        try {
            // console.log('selected Contact Id:', selectedContactId);
            // console.log('current Contact note Id:', currentContactNoteId);
    
            let userChallengePayload;
            if (currentContactNoteId) {
                // Update existing contact note - send only the updated note
                const updatedNote = {
                    _id: currentContactNoteId,
                    userContactId: selectedContactId,
                    userChallengeWABContactNoteText: contactEditorValue,
                    userChallengeWABTypeId: selectedUserChallengeTypeId,
                    userChallengeWABContactNoteDate: userChallengeContactNotes?.find(note => note._id === currentContactNoteId)?.userChallengeWABContactNoteDate, // Preserve original creation date
                    userChallengeWABContactNoteUpdateDate: new Date(), // Update the timestamp
                };

                userChallengePayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userChallengeWABContactNotes: [updatedNote], // Send only the updated note
                };
            } else {
                // Add new contact note - send only the new note
                const newNote = {
                    userContactId: selectedContactId,
                    userChallengeWABContactNoteText: contactEditorValue,
                    userChallengeWABTypeId: selectedUserChallengeTypeId,
                    userChallengeWABContactNoteDate: new Date(), // Creation date
                    userChallengeWABContactNoteUpdateDate: new Date(), // Update date
                };

                userChallengePayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userChallengeWABContactNotes: [newNote], // Send only the new note
                };
            }
        
            const newUserChallenge = await createNewUserChallengeWAB(userChallengePayload).unwrap();
            // Reload the page after a successful save
            window.location.reload(); // This line reloads the page to fetch and display the saved data
    
            // Update local state with the updated/new contact notes
            setUserChallengeContactNotes(newUserChallenge.userChallengeWABContactNotes || []);
    
            // Clear editor and reset states after saving
            setContactEditorValue(""); 
            setIsEditingContact(false); 
            setCurrentContactNoteId(null); 
    
            alert("User challenge contact note saved successfully");
        } catch (error) {
            console.error("Failed to save the user challenge contact note:", error);
            alert("Failed to save the user challenge contact note");
        }
    };
    
    // 6a - Handle delete user challenge note details - 02 Oct 2024    - 11 Oct 2024 - reviewed but not updated for user challenge type
    const handleDeleteAllUserChallenge = async (noteId) => {
        try {
            // console.log('noteId for deletion', noteId);
            // console.log('current noteId', currentReflectionId);
    
            // Mark the note for deletion by adding a delete: true flag
            const updatedNotes = userChallengeAllNotes?.map((note) => 
                note._id === noteId ? { ...note, delete: true } : note
            );
            
            // console.log('updatedNotes with delete flag:', updatedNotes);
    
            await createNewUserChallengeWAB({
                userId,
                userOrgId,
                weekStarting: startOfWeek, // Pass the start of the week
                userChallengeWABAllNotes: updatedNotes, // Send the updated notes array with delete flag
            }).unwrap();
    
            // Update state after successful deletion
            setUserChallengeAllNotes((prevWins) =>
                prevWins.filter((note) => note._id !== noteId)
            ); // Optionally remove from frontend state
    
            alert("Note deleted successfully");
            window.location.reload(); // Reload the page to fetch updated data
        } catch (error) {
            console.error("Failed to delete the note:", error);
            // Add error handling logic here if needed
        }
    };
    
    // 6b - Handle delete user challenge note details - 02 Oct 2024  - 11 Oct 2024 - reviewed but not updated for win type
    const handleDeleteContactUserChallenge = async (noteId) => {
        try {
            // console.log('noteId for deletion', noteId);
            // console.log('current noteId', currentWinId);
    
            // Mark the note for deletion by adding a delete: true flag
            const updatedNotes = userChallengeContactNotes?.map((note) => 
                note._id === noteId ? { ...note, delete: true } : note
            );
            
            // console.log('updatedNotes with delete flag:', updatedNotes);
    
            await createNewUserChallengeWAB({
                userId,
                userOrgId,
                weekStarting: startOfWeek, // Pass the start of the week
                userChallengeWABContactNotes: updatedNotes, // Send the updated notes array with delete flag
            }).unwrap();
    
            // Update state after successful deletion
            setUserChallengeContactNotes((prevWins) =>
                prevWins.filter((note) => note._id !== noteId)
            ); // Optionally remove from frontend state
    
            alert("Note deleted successfully");
            window.location.reload(); // Reload the page to fetch updated data
        } catch (error) {
            console.error("Failed to delete the note:", error);
            // Add error handling logic here if needed
        }
    };
    
    // 7 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
    }

    return (
        <div className="bg-gray-50 rounded m-1 p-1 font-poppins">

            {/* Sections for User Challenge Headers */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <p className="text-xs m-1 p-1 leading-4">Your magic isn’t defined solely by your creations or wins. A significant part of maintaining that magic lies in recognizing the importance of the challenges you face and the persistence you show in overcoming them. These challenges build your resilience and strengthen your willpower as you pursue a greater mission. Wins may be sporadic, but the lessons from your challenges are constant. Take a moment to record and reflect on the challenges you encountered this week—what did you learn, how did you overcome them, and where might you need support to keep pushing forward?</p>
            </div>

            {/* All UserChallenge */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <div className="grid grid-cols-10 justify-between items-center">
                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                        <div
                            onClick={() => setShowUserChallengeAllNotes(prev => !prev)}
                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                            >
                            {showUserChallengeAllNotes ? (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                            )}
                        </div>
                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtUserChallengeAllIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                        <div><p className="font-bold mx-1 px-1">All</p></div>  
                    </div>
                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Challenges this week:</p>
                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{userChallengeAllNotes?.length}</p></div>
                    </div>
                    {/* <div className="col-span-4 flex justify-end items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Add a Challenge</p>
                        <button type="button" onClick={handleAddAllUserChallenge}>
                        <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                        </button>
                    </div> */}
                </div>
                {showUserChallengeAllNotes && (
                    <div className="bg-gray-50 m-1 p-1 rounded">
                        {userChallengeAllNotes?.length === 0 && (
                            <p className="text-[11px] text-gray-500 italic mx-2 px21">
                                No challenges were added.
                            </p>
                        )}

                        {/* {isEditingAll ? (
                            <div className="bg-gray-50 p-1 m-1 rounded">
                                <div className="flex justify-start items-center my-1 py-1 rounded bg-gray-100">
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-white hover:bg-gray-200 cursor-pointer ml-2 pl-2" ><EtUserChallengeTypeIcon1SVGComp  className='h-7 w-7 text-orange-600'/>
                                    </div>
                                    <p className="text-xs text-gray-600 m-1 p-1 font-bold">Challenge Type:</p>
                                    <select
                                    className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none"
                                    onChange={(e) => setSelectedUserChallengeTypeId(e.target.value)}
                                    value={selectedUserChallengeTypeId || ""} 
                                    >
                                    {userChallengeTypeOptions}
                                    </select>
                                </div>
                            <EtContentEditorLight2
                                value={allEditorValue}
                                handleChange={handleAllEditorChange}
                                placeholderText="Write your challenge note here..."
                                editorKey={allEditorKey}
                            />
                            <div className="flex justify-start items-center m-1 p-1">
                                <button
                                type="button"
                                onClick={handleSaveAllUserChallenge}
                                className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                                >
                                <p className="text-[10px] px-2 py-1">Save</p>
                                </button>
                                <button
                                type="button"
                                onClick={handleCancelAllUserChallenge}
                                className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                                >
                                <p className="text-[10px] px-2 py-1">Cancel</p>
                                </button>
                            </div>
                            </div>
                                ) : null} */}

                        {/* Display Reflection Notes in Descending Order */}
                        <div className="m-1 p-1">
                            <div>
                                <p className="m-1 p-1 text-xs font-bold">Challenge Trail:</p>
                            </div>
                            <div>
                                {userChallengeAllNotes
                                    ?.slice()
                                    .sort(
                                        (a, b) =>
                                            new Date(b.userChallengeWABAllNoteUpdateDate) - new Date(a.userChallengeWABAllNoteUpdateDate)
                                    ) // Sort in descending order
                                    .map((note, noteIndex, arr) => (
                                        <div key={note._id} className="bg-white rounded">
                                            <RenderUserChallengeNotes
                                                note={note}
                                                noteTextKey="userChallengeWABAllNoteText"
                                                updateDateKey="userChallengeWABAllNoteUpdateDate"
                                                createdDateKey="userChallengeWABAllNoteDate"
                                                showDetail={showUserChallengeAllNoteDetails}
                                                toggleShowDetail={handleToggleUserChallengeAllNoteDetails}
                                                handleEditNote={handleEditAllUserChallenge}
                                                handleDeleteNote={handleDeleteAllUserChallenge}
                                                noteIndex={noteIndex}
                                                arr={arr}
                                                createMarkup={createMarkup}
                                                userChallengeTypeInfo={note.userChallengeWABTypeId}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
           
           {/* By Contact Section */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <div className="grid grid-cols-10 justify-between items-center">
                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                        <div
                            onClick={() => setShowUserChallengeContactNotes(prev => !prev)}
                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                            >
                            {showUserChallengeContactNotes ? (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                            )}
                        </div>
                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtUserChallengeContactIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                        <div><p className="font-bold mx-1 px-1">By Contact</p></div>
                    </div>
                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Challenges this week:</p>
                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{userChallengeContactNotes?.length}</p></div>
                    </div>
                    {/* <div className="col-span-4 flex justify-end items-center m-1 p-1">
                        <div className="flex justify-start items-center m-1 p-1">
                            <p className="font-bold text-xs m-1 p-1">Add a Contact</p>
                            <button type="button" onClick={()=>handleAddContact(userId, userOrgId)}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                            </button>
                        </div>
                        <div className="flex justify-start items-center m-1 p-1">
                            <p className="font-bold text-xs m-1 p-1">Add a Challenge</p>
                            <button type="button" onClick={handleAddContactUserChallenge}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                            </button>
                        </div>
                    </div> */}
                </div>
                {showUserChallengeContactNotes && (
                    <div className="bg-gray-50 m-1 p-1 rounded">
                        {userChallengeContactNotes?.length === 0 && (
                            <p className="text-[11px] text-gray-500 italic mx-2 px21">
                                No challenges were added.
                            </p>
                        )}

                        {/* {isEditingContact ? (
                            <div className="bg-gray-50 rounded">
                                
                                <div className="flex justify-start items-center my-1 py-1 rounded bg-gray-100">
                                    <div className="flex justify-center items-center h-8 w-8 bg-white hover:bg-gray-200 rounded-full m-1">
                                        <UserIcon className='text-orange-600 h-6 w-6' />
                                    </div>
                                    <div><p className="text-xs text-gray-600 m-1 p-1 font-bold">Contact:</p></div>
                                    <select
                                    className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none "
                                    // onChange={(e) => setSelectedContactId(e.target.value)}
                                    onChange={(e) => {
                                        const selectedContact = userChallengeContacts?.find(contact => contact._id === e.target.value);
                                        setSelectedContactId(e.target.value);
                                        setSelectedContactType(selectedContact?.contactTypeId?.contactTypeName || ""); // Set the contact type for filtering
                                    }}
                                    
                                    value={selectedContactId || ""} 
                                    disabled={!!currentContactNoteId}
                                    >
                                    {contactOptions}
                                    </select>

                                    <div className="flex justify-start items-center mx-2 px-2">
                                        <div className="flex justify-center items-center rounded-full h-8 w-8 bg-white hover:bg-gray-200 cursor-pointer ml-2 pl-2" ><EtUserChallengeTypeIcon1SVGComp  className='h-7 w-7 text-orange-600'/>
                                        </div>
                                        <p className="text-xs text-gray-600 m-1 p-1 font-bold">Challenge Type:</p>
                                        <select
                                        className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none"
                                        onChange={(e) => setSelectedUserChallengeTypeId(e.target.value)}
                                        value={selectedUserChallengeTypeId || ""} 
                                        >
                                        {userChallengeTypeOptions}
                                        </select>
                                    </div>

                                </div>
                                
                                <EtContentEditorLight2
                                    value={contactEditorValue}
                                    handleChange={handleContactEditorChange}
                                    placeholderText="Write your challenge note here..."
                                    editorKey={contactEditorKey}
                                />
                                <div className="flex justify-start items-center m-1 p-1">
                                    <button
                                    type="button"
                                    onClick={handleSaveContactUserChallenge}
                                    className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                                    >
                                    <p className="text-[10px] px-2 py-1">Save</p>
                                    </button>
                                    <button
                                    type="button"
                                    onClick={handleCancelContactUserChallenge}
                                    className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                                    >
                                    <p className="text-[10px] px-2 py-1">Cancel</p>
                                    </button>
                                </div>
                            </div>
                                ) : null} */}

                        {/* Display Reflection Notes in Descending Order */}
                        <div className="m-1 p-1">
                            <div>
                                <p className="m-1 p-1 text-xs font-bold">Contact Challenge Trail:</p>
                            </div>
                            <div>
                                {userChallengeContactNotes
                                    ?.slice()
                                    .sort(
                                        (a, b) =>
                                            new Date(b.userChallengeWABContactNoteUpdateDate) - new Date(a.userChallengeWABContactNoteUpdateDate)
                                    ) // Sort in descending order
                                    .map((note, noteIndex, arr) => (
                                        <div key={note._id} className="bg-white rounded">
                                            <RenderContactUserChallengeNotes
                                                note={note}
                                                noteTextKey="userChallengeWABContactNoteText"
                                                updateDateKey="userChallengeWABContactNoteUpdateDate"
                                                createdDateKey="userChallengeWABContactNoteDate"
                                                showDetail={showUserChallengeContactNoteDetails}
                                                toggleShowDetail={handleToggleUserChallengeContactNoteDetails}
                                                handleEditNote={handleEditContactUserChallenge}
                                                handleDeleteNote={handleDeleteContactUserChallenge}
                                                noteIndex={noteIndex}
                                                arr={arr}
                                                createMarkup={createMarkup}
                                                userChallengeTypeInfo={note.userChallengeWABTypeId}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
                <EtModalCreateContact 
                    isOpen={isModalOpen}
                    userId={userId}
                    userOrgId={userOrgId}
                    triggeringUrl={triggeringUrl}
                    onClose={handleCloseCreateContactModal}
                />
            </div>
        </div>
    )

}

const WABIntentions = ({userIntentionNotes = [], startOfWeek, endOfWeek, userId, userOrgId, intentionContacts, intentionTypes}) => {
    // First Created - 15 Oct 2024
    // Author - Atul Pandey
    // Inherited from WABImpacts
   
    // 0 - check for incoming data:
    // console.log('intentionContacts', intentionContacts)
    // console.log('intentionTypes', intentionTypes)
    const userIntentionContactNotes = userIntentionNotes[0]?.userIntentionNoteId?.userIntentionContactNotes || []
    const userIntentionAllNotes = userIntentionNotes[0]?.userIntentionNoteId?.userIntentionAllNotes || []

    // 1a - State for Modal -- 03 Oct 2024
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [triggeringUrl, setTriggeringUrl] = useState(null)
    
    // 1b - Add state management for intention notes
    const [showIntentionAllNotes, setShowIntentionAllNotes] = useState(false)
    const [showIntentionContactNotes, setShowIntentionContactNotes] = useState(false)

    const [showIntentionContactNoteDetails, setShowIntentionContactNoteDetails] = useState({}) 
    const [showIntentionAllNoteDetails, setShowIntentionAllNoteDetails] = useState({}) 
    const [intentionContactNotes, setIntentionContactNotes] = useState(userIntentionContactNotes);
    const [intentionAllNotes, setIntentionAllNotes] = useState(userIntentionAllNotes);

    // console.log('intention all notes', intentionAllNotes)
    // console.log('intention contact notes', intentionContactNotes)

    // 1c - State variables for the intention editor (similar to reflections)
    const [allEditorValue, setAllEditorValue] = useState(() => {
        const latestAllNote = userIntentionAllNotes?.slice(-1)[0];
        return latestAllNote ? latestAllNote.userIntentionAllNoteText : "";
      });
      
      const [currentAllNoteId, setCurrentAllNoteId] = useState(() => {
        const latestAllNote = userIntentionAllNotes?.slice(-1)[0];
        return latestAllNote ? latestAllNote._id : null;
      });

    const [isEditingAll, setIsEditingAll] = useState(false);
    const [allEditorKey, setAllEditorKey] = useState("allIntentionNote-" + Date.now());
    
    // 1d - State variables for the intention editor (similar to reflections)
    const [selectedContactId, setSelectedContactId] = useState(""); // Holds the selected contact ID
    const [selectedContactType, setSelectedContactType] = useState("") // 11 Oct 2024 - for filtering contact Type

    const [contactEditorValue, setContactEditorValue] = useState(() => {
        const latestContactNote = userIntentionContactNotes?.slice(-1)[0];
        return latestContactNote ? latestContactNote.userIntentionContactNoteText : "";
      });
      
    const [currentContactNoteId, setCurrentContactNoteId] = useState(() => {
        const latestContactNote = userIntentionContactNotes?.slice(-1)[0];
        return latestContactNote ? latestContactNote._id : null;
      });
      
    const [isEditingContact, setIsEditingContact] = useState(false);
    const [contactEditorKey, setContactEditorKey] = useState("contactIntentionNote-" + Date.now());

    // 1e - State variable for selected intention type - 11 Oct 2024
    const [selectedIntentionTypeId, setSelectedIntentionTypeId] = useState("")
    // console.log('selectedIntentionTypeId', selectedIntentionTypeId)

    // 2 - Initialize the mutation
    const [createNewUserIntention] = useCreateNewUserIntentionMutation();

    // 3a - Handle Editor Value change
    const handleAllEditorChange = (value) => {
        setAllEditorValue(value);
        };

    // 3a - Handle Editor Value change
    const handleContactEditorChange = (value) => {
        setContactEditorValue(value);
        };
    
    // 4a - Handle adding a new intention
    const handleAddAllIntention = useCallback(() => {
        setAllEditorValue(""); // Clear editor
        setCurrentAllNoteId(null); // Reset intention ID
        setIsEditingAll(true); // Enable edit mode
        setAllEditorKey("allIntentionNote-" + Date.now()); // Reset editor key
    }, []);

    // 4b - Handle editing an existing reflection
    const handleEditAllIntention = (note) => {
    setAllEditorValue(note.userIntentionAllNoteText); // Load note into editor
    setCurrentAllNoteId(note._id); // Track the reflection being edited
    setIsEditingAll(true); // Enable edit mode
    setSelectedIntentionTypeId(note.userIntentionTypeId._id) // 11 Oct 2024
    };

    // 4c - Function to handle editing a note 
    const handleCancelAllIntention = (note) => {
    setAllEditorValue("")
    setCurrentAllNoteId(null)
    setIsEditingAll(false)
    }
    
    // 4d - Toggle Show function for reflection note details 
    const handleToggleIntentionAllNoteDetails = (noteId) => {
    setShowIntentionAllNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
    }))
    }

    // 4e - Handle adding a new intention
    const handleAddContactIntention = useCallback(() => {
        setContactEditorValue(""); // Clear editor
        setCurrentContactNoteId(null); // Reset intention ID
        setIsEditingContact(true); // Enable edit mode
        setContactEditorKey("contactIntentionNote-" + Date.now()); // Reset editor key
        setSelectedIntentionTypeId(""); // 11 Oct 2024 Reset the intention type ID
        setSelectedContactId(""); // 11 Oct 2024 Reset contact selection
        setSelectedContactType(""); // 11 Oct 2024 Reset the contact type to ensure no leftover filtering
    }, []);

    // 4f - Handle editing an existing reflection
    const handleEditContactIntention = (note) => {
        // console.log('note', note)
    setContactEditorValue(note.userIntentionContactNoteText); // Load note into editor
    setCurrentContactNoteId(note._id); // Track the reflection being edited
    setSelectedContactId(note.userContactId._id); // Set the contact ID for pre-selecting the contact
    // console.log('currentContactNoteId in handleEdit function:', currentContactNoteId)
    setIsEditingContact(true); // Enable edit mode
    setSelectedIntentionTypeId(note.userIntentionTypeId._id) // 11 Oct 2024
    // setSelectedContactId(""); // 11 Oct 2024 Reset contact selection
    setSelectedContactType(note.userContactId?.contactTypeId?.contactTypeName) // 11 Oct 2024
    };

    // 4g - Function to handle editing a note 
    const handleCancelContactIntention = (note) => {
    setContactEditorValue("")
    setCurrentContactNoteId(null)
    setIsEditingContact(false)
    setSelectedIntentionTypeId(""); // Reset intention type
    setSelectedContactType(""); // Reset contact type to clear filtering
    }
    
    // 4h - Toggle Show function for reflection note details 
    const handleToggleIntentionContactNoteDetails = (noteId) => {
    setShowIntentionContactNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
    }))
    }

    // 4i - Create options to select a contact from the list of intention contacts - 02 Oct 2024
    const contactOptions = intentionContacts ? [
    <option key="placeholder" value="" disabled >
      Select a Contact
    </option>,
    ...intentionContacts?.map(contact => (
      <option key={contact._id} value={contact._id}>
        {contact.contactName}
      </option>
    )),
    ] : [];
    // console.log('contactOptions',contactOptions )

    // 4j - Create options to select a intention type from the list of intention types - 10 Oct 2024
    const intentionTypeOptions = intentionTypes ? [
    <option key="placeholder" value="" disabled selected>
      Select a Intention Type
    </option>,
    ...intentionTypes?.map(type => (
      <option key={type._id} value={type._id}>
        {type.intentionTypeName}
      </option>
    )),
    ] : [];
    
    // 4k - Function to handle adding a new contact for the userId, userOrgId - 03 Oct 2024
    const handleAddContact = (userId, userOrgId) => {
        const currentUrl = window.location.pathname // 26 Aug 2024 - Capture the current URL
        setTriggeringUrl(currentUrl) // 26 Aug 2024 - Set the triggering URL
        setIsModalOpen(true)
    }

    const handleCloseCreateContactModal = () => {
        setIsModalOpen(false)
        setTriggeringUrl(null) // 26 Aug 2024 Reset the triggering URL
        window.location.reload(); // Force the page to reload
        // Refetch the data to get the latest evaluations
        // refetch()
      }

    // 5a - Function to handle saving a reflection (create or update) - 11 Oct 2024 - updated for intention type
    const handleSaveAllIntention = async () => {

        // 11 Oct 2024 - updated with intention type

    if (allEditorValue.trim() === "") {
        alert("The editor is blank. Please enter some text before saving.");
        return;
    }
    try {

        let intentionPayload;
            if (currentAllNoteId) {
                // Update existing contact note - send only the updated note
                const updatedNote = {
                    _id: currentAllNoteId,
                    userIntentionAllNoteText: allEditorValue,
                    userIntentionTypeId: selectedIntentionTypeId,
                    userIntentionAllNoteDate: userIntentionAllNotes?.find(note => note._id === currentAllNoteId)?.userIntentionAllNoteDate, // Preserve original creation date
                    userIntentionAllNoteUpdateDate: new Date(), // Update the timestamp
                };

                intentionPayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userIntentionAllNotes: [updatedNote], // Send only the updated note
                };
            } else {
                // Add new contact note - send only the new note
                const newNote = {
                    userIntentionAllNoteText: allEditorValue,
                    userIntentionTypeId: selectedIntentionTypeId,
                    userIntentionAllNoteDate: new Date(), // Creation date
                    userIntentionAllNoteUpdateDate: new Date(), // Update date
                };

                intentionPayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userIntentionAllNotes: [newNote], // Send only the new note
                };
            }

        // console.log('notes sent to backend (intentionPayload):', intentionPayload)
        // Call the mutation
        const newIntention = await createNewUserIntention(intentionPayload).unwrap();
        // Reload the page after a successful save
        window.location.reload(); // This line reloads the page to fetch and display the saved data

        
        if (!currentAllNoteId) {
            // Update local state with the newly added reflection only if it's a new one
            setIntentionAllNotes((prev) => [...prev, newIntention.data]);
        }

        setAllEditorValue(""); // Clear editor after saving
        setIsEditingAll(false); // Disable edit mode
        setCurrentAllNoteId(null); // Reset reflection ID after saving
        alert("Intention all note saved successfully");
    } catch (error) {
        console.error("Failed to save the intention all note:", error);
        alert("Failed to save the intention all note");
    }
    };

    // 5b - Function to handle saving a intention (create or update) - 11 Oct 2024 - updated for intention type - 12 Oct 2024 - refined for update logic - changed note only
    const handleSaveContactIntention = async () => {
        if (!selectedContactId || contactEditorValue.trim() === "") { 
            alert("Please select a contact and enter some text in the editor before saving.");
            return;
        }
        try {
            // console.log('selected Contact Id:', selectedContactId);
            // console.log('current Contact note Id:', currentContactNoteId);
    
            let intentionPayload;
            if (currentContactNoteId) {
                // Update existing contact note - send only the updated note
                const updatedNote = {
                    _id: currentContactNoteId,
                    userContactId: selectedContactId,
                    userIntentionContactNoteText: contactEditorValue,
                    userIntentionTypeId: selectedIntentionTypeId,
                    userIntentionContactNoteDate: userIntentionContactNotes?.find(note => note._id === currentContactNoteId)?.userIntentionContactNoteDate, // Preserve original creation date
                    userIntentionContactNoteUpdateDate: new Date(), // Update the timestamp
                };

                intentionPayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userIntentionContactNotes: [updatedNote], // Send only the updated note
                };
            } else {
                // Add new contact note - send only the new note
                const newNote = {
                    userContactId: selectedContactId,
                    userIntentionContactNoteText: contactEditorValue,
                    userIntentionTypeId: selectedIntentionTypeId,
                    userIntentionContactNoteDate: new Date(), // Creation date
                    userIntentionContactNoteUpdateDate: new Date(), // Update date
                };

                intentionPayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userIntentionContactNotes: [newNote], // Send only the new note
                };
            }
        
            const newIntention = await createNewUserIntention(intentionPayload).unwrap();
            // Reload the page after a successful save
            window.location.reload(); // This line reloads the page to fetch and display the saved data
    
            // Update local state with the updated/new contact notes
            setIntentionContactNotes(newIntention.userIntentionContactNotes || []);
    
            // Clear editor and reset states after saving
            setContactEditorValue(""); 
            setIsEditingContact(false); 
            setCurrentContactNoteId(null); 
    
            alert("Intention contact note saved successfully");
        } catch (error) {
            console.error("Failed to save the intention contact note:", error);
            alert("Failed to save the intention contact note");
        }
    };
    
    // 6a - Handle delete intention note details - 02 Oct 2024    - 11 Oct 2024 - reviewed but not updated for intention type
    const handleDeleteAllIntention = async (noteId) => {
        try {
            // console.log('noteId for deletion', noteId);
            // console.log('current noteId', currentReflectionId);
    
            // Mark the note for deletion by adding a delete: true flag
            const updatedNotes = userIntentionAllNotes?.map((note) => 
                note._id === noteId ? { ...note, delete: true } : note
            );
            
            // console.log('updatedNotes with delete flag:', updatedNotes);
    
            await createNewUserIntention({
                userId,
                userOrgId,
                weekStarting: startOfWeek, // Pass the start of the week
                userIntentionAllNotes: updatedNotes, // Send the updated notes array with delete flag
            }).unwrap();
    
            // Update state after successful deletion
            setIntentionAllNotes((prevIntentions) =>
                prevIntentions.filter((note) => note._id !== noteId)
            ); // Optionally remove from frontend state
    
            alert("Note deleted successfully");
            window.location.reload(); // Reload the page to fetch updated data
        } catch (error) {
            console.error("Failed to delete the note:", error);
            // Add error handling logic here if needed
        }
    };
    
    // 6b - Handle delete intention note details - 02 Oct 2024    - 11 Oct 2024 - reviewed but not updated for intention type
    const handleDeleteContactIntention = async (noteId) => {
        try {
            // console.log('noteId for deletion', noteId);
            // console.log('current noteId', currentIntentionId);
    
            // Mark the note for deletion by adding a delete: true flag
            const updatedNotes = userIntentionContactNotes?.map((note) => 
                note._id === noteId ? { ...note, delete: true } : note
            );
            
            // console.log('updatedNotes with delete flag:', updatedNotes);
    
            await createNewUserIntention({
                userId,
                userOrgId,
                weekStarting: startOfWeek, // Pass the start of the week
                userIntentionContactNotes: updatedNotes, // Send the updated notes array with delete flag
            }).unwrap();
    
            // Update state after successful deletion
            setIntentionContactNotes((prevIntentions) =>
                prevIntentions.filter((note) => note._id !== noteId)
            ); // Optionally remove from frontend state
    
            alert("Note deleted successfully");
            window.location.reload(); // Reload the page to fetch updated data
        } catch (error) {
            console.error("Failed to delete the note:", error);
            // Add error handling logic here if needed
        }
    };
    
    // 7 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
    }

    return (
        <div className="bg-gray-50 rounded m-1 p-1 font-poppins">

            {/* Sections for Intention Headers */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <p className="text-xs m-1 p-1 leading-4">You’ve worked hard this week, lighting up your magic through your accomplishments, listening, learning, contributing, and navigating both wins and challenges. But keeping that magic alive is an ongoing journey, and next week is your opportunity to take it to the next level. It’s time to re-energize, reflect, and set new intentions—what new actions will you take, what fresh conversations will you spark, how will your contributions grow, and how will you overcome challenges? Invest in yourself by prioritizing your health, learning, and creative energy, and let your thoughts inspire others. Go ahead and set your intentions, because you're just getting started!</p>
            </div>

            {/* All Intention */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <div className="grid grid-cols-10 justify-between items-center">
                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                        <div
                            onClick={() => setShowIntentionAllNotes(prev => !prev)}
                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                            >
                            {showIntentionAllNotes ? (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                            )}
                        </div>
                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtIntentionAllIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                        <div><p className="font-bold mx-1 px-1">All</p></div>  
                    </div>
                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Intentions for next week:</p>
                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{intentionAllNotes?.length}</p></div>
                    </div>
                    {/* <div className="col-span-4 flex justify-end items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Add an Intention</p>
                        <button type="button" onClick={handleAddAllIntention}>
                        <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                        </button>
                    </div> */}
                </div>
                {showIntentionAllNotes && (
                    <div className="bg-gray-50 m-1 p-1 rounded">
                        {intentionAllNotes?.length === 0 && (
                            <p className="text-[11px] text-gray-500 italic mx-2 px21">
                                No intentions were added.
                            </p>
                        )}

                        {/* {isEditingAll ? (
                            <div className="bg-gray-50 p-1 m-1 rounded">
                                <div className="flex justify-start items-center my-1 py-1 rounded bg-gray-100">
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-white hover:bg-gray-200 cursor-pointer ml-2 pl-2" ><EtIntentionTypeIcon1SVGComp  className='h-7 w-7 text-orange-600'/>
                                    </div>
                                    <p className="text-xs text-gray-600 m-1 p-1 font-bold">Intention Type:</p>
                                    <select
                                    className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none"
                                    onChange={(e) => setSelectedIntentionTypeId(e.target.value)}
                                    value={selectedIntentionTypeId || ""} 
                                    >
                                    {intentionTypeOptions}
                                    </select>
                                </div>
                            <EtContentEditorLight2
                                value={allEditorValue}
                                handleChange={handleAllEditorChange}
                                placeholderText="Write your intention note here..."
                                editorKey={allEditorKey}
                            />
                            <div className="flex justify-start items-center m-1 p-1">
                                <button
                                type="button"
                                onClick={handleSaveAllIntention}
                                className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                                >
                                <p className="text-[10px] px-2 py-1">Save</p>
                                </button>
                                <button
                                type="button"
                                onClick={handleCancelAllIntention}
                                className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                                >
                                <p className="text-[10px] px-2 py-1">Cancel</p>
                                </button>
                            </div>
                            </div>
                                ) : null} */}

                        {/* Display Reflection Notes in Descending Order */}
                        <div className="m-1 p-1">
                            <div>
                                <p className="m-1 p-1 text-xs font-bold">Intention Trail:</p>
                            </div>
                            <div>
                                {intentionAllNotes
                                    ?.slice()
                                    .sort(
                                        (a, b) =>
                                            new Date(b.userIntentionAllNoteUpdateDate) - new Date(a.userIntentionAllNoteUpdateDate)
                                    ) // Sort in descending order
                                    .map((note, noteIndex, arr) => (
                                        <div key={note._id} className="bg-white rounded">
                                            <RenderIntentionNotes
                                                note={note}
                                                noteTextKey="userIntentionAllNoteText"
                                                updateDateKey="userIntentionAllNoteUpdateDate"
                                                createdDateKey="userIntentionAllNoteDate"
                                                showDetail={showIntentionAllNoteDetails}
                                                toggleShowDetail={handleToggleIntentionAllNoteDetails}
                                                handleEditNote={handleEditAllIntention}
                                                handleDeleteNote={handleDeleteAllIntention}
                                                noteIndex={noteIndex}
                                                arr={arr}
                                                createMarkup={createMarkup}
                                                intentionTypeInfo={note.userIntentionTypeId}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
           
           {/* By Contact Section */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <div className="grid grid-cols-10 justify-between items-center">
                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                        <div
                            onClick={() => setShowIntentionContactNotes(prev => !prev)}
                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                            >
                            {showIntentionContactNotes ? (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                            )}
                        </div>
                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtIntentionContactIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                        <div><p className="font-bold mx-1 px-1">By Contact</p></div>
                    </div>
                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Intentions for next week:</p>
                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{intentionContactNotes?.length}</p></div>
                    </div>
                    {/* <div className="col-span-4 flex justify-end items-center m-1 p-1">
                        <div className="flex justify-start items-center m-1 p-1">
                            <p className="font-bold text-xs m-1 p-1">Add a Contact</p>
                            <button type="button" onClick={()=>handleAddContact(userId, userOrgId)}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                            </button>
                        </div>
                        <div className="flex justify-start items-center m-1 p-1">
                            <p className="font-bold text-xs m-1 p-1">Add an Intention</p>
                            <button type="button" onClick={handleAddContactIntention}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                            </button>
                        </div>
                    </div> */}
                </div>
                {showIntentionContactNotes && (
                    <div className="bg-gray-50 m-1 p-1 rounded">
                        {intentionContactNotes?.length === 0 && (
                            <p className="text-[11px] text-gray-500 italic mx-2 px21">
                                No intentions were added.
                            </p>
                        )}

                        {/* {isEditingContact ? (
                            <div className="bg-gray-50 rounded">
                                
                                <div className="flex justify-start items-center my-1 py-1 rounded bg-gray-100">
                                    <div className="flex justify-center items-center h-8 w-8 bg-white hover:bg-gray-200 rounded-full m-1">
                                        <UserIcon className='text-orange-600 h-6 w-6' />
                                    </div>
                                    <div><p className="text-xs text-gray-600 m-1 p-1 font-bold">Contact:</p></div>
                                    <select
                                    className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none "
                                    // onChange={(e) => setSelectedContactId(e.target.value)}
                                    onChange={(e) => {
                                        const selectedContact = intentionContacts.find(contact => contact._id === e.target.value);
                                        setSelectedContactId(e.target.value);
                                        setSelectedContactType(selectedContact?.contactTypeId?.contactTypeName || ""); // Set the contact type for filtering
                                    }}
                                    
                                    value={selectedContactId || ""} 
                                    disabled={!!currentContactNoteId}
                                    >
                                    {contactOptions}
                                    </select>

                                    <div className="flex justify-start items-center mx-2 px-2">
                                        <div className="flex justify-center items-center rounded-full h-8 w-8 bg-white hover:bg-gray-200 cursor-pointer ml-2 pl-2" ><EtIntentionTypeIcon1SVGComp  className='h-7 w-7 text-orange-600'/>
                                        </div>
                                        <p className="text-xs text-gray-600 m-1 p-1 font-bold">Intention Type:</p>
                                        <select
                                        className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none"
                                        onChange={(e) => setSelectedIntentionTypeId(e.target.value)}
                                        value={selectedIntentionTypeId || ""} 
                                        >
                                        {intentionTypeOptions}
                                        </select>
                                    </div>

                                </div>
                                
                                <EtContentEditorLight2
                                    value={contactEditorValue}
                                    handleChange={handleContactEditorChange}
                                    placeholderText="Write your intention note here..."
                                    editorKey={contactEditorKey}
                                />
                                <div className="flex justify-start items-center m-1 p-1">
                                    <button
                                    type="button"
                                    onClick={handleSaveContactIntention}
                                    className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                                    >
                                    <p className="text-[10px] px-2 py-1">Save</p>
                                    </button>
                                    <button
                                    type="button"
                                    onClick={handleCancelContactIntention}
                                    className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                                    >
                                    <p className="text-[10px] px-2 py-1">Cancel</p>
                                    </button>
                                </div>
                            </div>
                                ) : null} */}

                        {/* Display Reflection Notes in Descending Order */}
                        <div className="m-1 p-1">
                            <div>
                                <p className="m-1 p-1 text-xs font-bold">Contact Intention Trail:</p>
                            </div>
                            <div>
                                {intentionContactNotes
                                    ?.slice()
                                    .sort(
                                        (a, b) =>
                                            new Date(b.userIntentionContactNoteUpdateDate) - new Date(a.userIntentionContactNoteUpdateDate)
                                    ) // Sort in descending order
                                    .map((note, noteIndex, arr) => (
                                        <div key={note._id} className="bg-white rounded">
                                            <RenderContactIntentionNotes
                                                note={note}
                                                noteTextKey="userIntentionContactNoteText"
                                                updateDateKey="userIntentionContactNoteUpdateDate"
                                                createdDateKey="userIntentionContactNoteDate"
                                                showDetail={showIntentionContactNoteDetails}
                                                toggleShowDetail={handleToggleIntentionContactNoteDetails}
                                                handleEditNote={handleEditContactIntention}
                                                handleDeleteNote={handleDeleteContactIntention}
                                                noteIndex={noteIndex}
                                                arr={arr}
                                                createMarkup={createMarkup}
                                                intentionTypeInfo={note.userIntentionTypeId}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
                <EtModalCreateContact 
                    isOpen={isModalOpen}
                    userId={userId}
                    userOrgId={userOrgId}
                    triggeringUrl={triggeringUrl}
                    onClose={handleCloseCreateContactModal}
                />
            </div>
        </div>
    )

}

const WABGratitudes = ({userGratitudeNotes = [], startOfWeek, endOfWeek, userId, userOrgId, gratitudeContacts, gratitudeTypes}) => {
    // First Created - 16 Oct 2024
    // Author - Atul Pandey
    // Inherited from WABImpacts
   
    // 0 - check for incoming data:
    // console.log('gratitudeContacts', gratitudeContacts)
    // console.log('gratitudeTypes', gratitudeTypes)
    const userGratitudeContactNotes = userGratitudeNotes[0]?.userGratitudeNoteId?.userGratitudeContactNotes || []
    const userGratitudeAllNotes = userGratitudeNotes[0]?.userGratitudeNoteId?.userGratitudeAllNotes || []

    // 1a - State for Modal -- 03 Oct 2024
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [triggeringUrl, setTriggeringUrl] = useState(null)
    
    // 1b - Add state management for gratitude notes
    const [showGratitudeAllNotes, setShowGratitudeAllNotes] = useState(false)
    const [showGratitudeContactNotes, setShowGratitudeContactNotes] = useState(false)

    const [showGratitudeContactNoteDetails, setShowGratitudeContactNoteDetails] = useState({}) 
    const [showGratitudeAllNoteDetails, setShowGratitudeAllNoteDetails] = useState({}) 
    const [gratitudeContactNotes, setGratitudeContactNotes] = useState(userGratitudeContactNotes);
    const [gratitudeAllNotes, setGratitudeAllNotes] = useState(userGratitudeAllNotes);

    // console.log('gratitude all notes', gratitudeAllNotes)
    // console.log('gratitude contact notes', gratitudeContactNotes)

    // 1c - State variables for the gratitude editor (similar to reflections)
    const [allEditorValue, setAllEditorValue] = useState(() => {
        const latestAllNote = userGratitudeAllNotes?.slice(-1)[0];
        return latestAllNote ? latestAllNote.userGratitudeAllNoteText : "";
      });
      
      const [currentAllNoteId, setCurrentAllNoteId] = useState(() => {
        const latestAllNote = userGratitudeAllNotes?.slice(-1)[0];
        return latestAllNote ? latestAllNote._id : null;
      });

    const [isEditingAll, setIsEditingAll] = useState(false);
    const [allEditorKey, setAllEditorKey] = useState("allGratitudeNote-" + Date.now());
    
    // 1d - State variables for the gratitude editor (similar to reflections)
    const [selectedContactId, setSelectedContactId] = useState(""); // Holds the selected contact ID
    const [selectedContactType, setSelectedContactType] = useState("") // 11 Oct 2024 - for filtering contact Type

    const [contactEditorValue, setContactEditorValue] = useState(() => {
        const latestContactNote = userGratitudeContactNotes?.slice(-1)[0];
        return latestContactNote ? latestContactNote.userGratitudeContactNoteText : "";
      });
      
    const [currentContactNoteId, setCurrentContactNoteId] = useState(() => {
        const latestContactNote = userGratitudeContactNotes?.slice(-1)[0];
        return latestContactNote ? latestContactNote._id : null;
      });
      
    const [isEditingContact, setIsEditingContact] = useState(false);
    const [contactEditorKey, setContactEditorKey] = useState("contactGratitudeNote-" + Date.now());

    // 1e - State variable for selected gratitude type - 11 Oct 2024
    const [selectedGratitudeTypeId, setSelectedGratitudeTypeId] = useState("")
    // console.log('selectedGratitudeTypeId', selectedGratitudeTypeId)

    // 2 - Initialize the mutation
    const [createNewUserGratitude] = useCreateNewUserGratitudeMutation();

    // 3a - Handle Editor Value change
    const handleAllEditorChange = (value) => {
        setAllEditorValue(value);
        };

    // 3a - Handle Editor Value change
    const handleContactEditorChange = (value) => {
        setContactEditorValue(value);
        };
    
    // 4a - Handle adding a new gratitude
    const handleAddAllGratitude = useCallback(() => {
        setAllEditorValue(""); // Clear editor
        setCurrentAllNoteId(null); // Reset gratitude ID
        setIsEditingAll(true); // Enable edit mode
        setAllEditorKey("allGratitudeNote-" + Date.now()); // Reset editor key
        setShowGratitudeAllNotes(true)
    }, []);

    // 4b - Handle editing an existing reflection
    const handleEditAllGratitude = (note) => {
    setAllEditorValue(note.userGratitudeAllNoteText); // Load note into editor
    setCurrentAllNoteId(note._id); // Track the reflection being edited
    setIsEditingAll(true); // Enable edit mode
    setSelectedGratitudeTypeId(note.userGratitudeTypeId._id) // 11 Oct 2024
    };

    // 4c - Function to handle editing a note 
    const handleCancelAllGratitude = (note) => {
    setAllEditorValue("")
    setCurrentAllNoteId(null)
    setIsEditingAll(false)
    }
    
    // 4d - Toggle Show function for reflection note details 
    const handleToggleGratitudeAllNoteDetails = (noteId) => {
    setShowGratitudeAllNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
    }))
    }

    // 4e - Handle adding a new gratitude
    const handleAddContactGratitude = useCallback(() => {
        setContactEditorValue(""); // Clear editor
        setCurrentContactNoteId(null); // Reset gratitude ID
        setIsEditingContact(true); // Enable edit mode
        setContactEditorKey("contactGratitudeNote-" + Date.now()); // Reset editor key
        setSelectedGratitudeTypeId(""); // 11 Oct 2024 Reset the gratitude type ID
        setSelectedContactId(""); // 11 Oct 2024 Reset contact selection
        setSelectedContactType(""); // 11 Oct 2024 Reset the contact type to ensure no leftover filtering
        setShowGratitudeContactNotes(true)
    }, []);

    // 4f - Handle editing an existing reflection
    const handleEditContactGratitude = (note) => {
        // console.log('note', note)
    setContactEditorValue(note.userGratitudeContactNoteText); // Load note into editor
    setCurrentContactNoteId(note._id); // Track the reflection being edited
    setSelectedContactId(note.userContactId._id); // Set the contact ID for pre-selecting the contact
    // console.log('currentContactNoteId in handleEdit function:', currentContactNoteId)
    setIsEditingContact(true); // Enable edit mode
    setSelectedGratitudeTypeId(note.userGratitudeTypeId._id) // 11 Oct 2024
    // setSelectedContactId(""); // 11 Oct 2024 Reset contact selection
    setSelectedContactType(note.userContactId?.contactTypeId?.contactTypeName) // 11 Oct 2024
    };

    // 4g - Function to handle editing a note 
    const handleCancelContactGratitude = (note) => {
    setContactEditorValue("")
    setCurrentContactNoteId(null)
    setIsEditingContact(false)
    setSelectedGratitudeTypeId(""); // Reset gratitude type
    setSelectedContactType(""); // Reset contact type to clear filtering
    }
    
    // 4h - Toggle Show function for reflection note details 
    const handleToggleGratitudeContactNoteDetails = (noteId) => {
    setShowGratitudeContactNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
    }))
    }

    // 4i - Create options to select a contact from the list of gratitude contacts - 02 Oct 2024
    const contactOptions = gratitudeContacts ? [
    <option key="placeholder" value="" disabled >
      Select a Contact
    </option>,
    ...gratitudeContacts?.map(contact => (
      <option key={contact._id} value={contact._id}>
        {contact.contactName}
      </option>
    )),
    ] : [];
    // console.log('contactOptions',contactOptions )

    // 4j - Create options to select a gratitude type from the list of gratitude types - 10 Oct 2024
    const gratitudeTypeOptions = gratitudeTypes ? [
    <option key="placeholder" value="" disabled>
      Select a Gratitude Type
    </option>,
    ...gratitudeTypes?.map(type => (
      <option key={type._id} value={type._id}>
        {type.gratitudeTypeName}
      </option>
    )),
    ] : [];
    
    // 4k - Function to handle adding a new contact for the userId, userOrgId - 03 Oct 2024
    const handleAddContact = (userId, userOrgId) => {
        const currentUrl = window.location.pathname // 26 Aug 2024 - Capture the current URL
        setTriggeringUrl(currentUrl) // 26 Aug 2024 - Set the triggering URL
        setIsModalOpen(true)
    }

    const handleCloseCreateContactModal = () => {
        setIsModalOpen(false)
        setTriggeringUrl(null) // 26 Aug 2024 Reset the triggering URL
        window.location.reload(); // Force the page to reload
        // Refetch the data to get the latest evaluations
        // refetch()
      }

    // 5a - Function to handle saving a reflection (create or update) - 11 Oct 2024 - updated for gratitude type
    const handleSaveAllGratitude = async () => {

        // 11 Oct 2024 - updated with gratitude type

    if (allEditorValue.trim() === "") {
        alert("The editor is blank. Please enter some text before saving.");
        return;
    }
    try {

        let gratitudePayload;
            if (currentAllNoteId) {
                // Update existing contact note - send only the updated note
                const updatedNote = {
                    _id: currentAllNoteId,
                    userGratitudeAllNoteText: allEditorValue,
                    userGratitudeTypeId: selectedGratitudeTypeId,
                    userGratitudeAllNoteDate: userGratitudeAllNotes?.find(note => note._id === currentAllNoteId)?.userGratitudeAllNoteDate, // Preserve original creation date
                    userGratitudeAllNoteUpdateDate: new Date(), // Update the timestamp
                };

                gratitudePayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userGratitudeAllNotes: [updatedNote], // Send only the updated note
                };
            } else {
                // Add new contact note - send only the new note
                const newNote = {
                    userGratitudeAllNoteText: allEditorValue,
                    userGratitudeTypeId: selectedGratitudeTypeId,
                    userGratitudeAllNoteDate: new Date(), // Creation date
                    userGratitudeAllNoteUpdateDate: new Date(), // Update date
                };
                console.log('new Note', newNote)

                gratitudePayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userGratitudeAllNotes: [newNote], // Send only the new note
                };
            }

        console.log('notes sent to backend (gratitudePayload):', gratitudePayload)
        // Call the mutation
        const newGratitude = await createNewUserGratitude(gratitudePayload).unwrap();
        // Reload the page after a successful save
        window.location.reload(); // This line reloads the page to fetch and display the saved data

        
        if (!currentAllNoteId) {
            // Update local state with the newly added reflection only if it's a new one
            setGratitudeAllNotes((prev) => [...prev, newGratitude.data]);
        }

        setAllEditorValue(""); // Clear editor after saving
        setIsEditingAll(false); // Disable edit mode
        setCurrentAllNoteId(null); // Reset reflection ID after saving
        alert("Gratitude all note saved successfully");
    } catch (error) {
        console.error("Failed to save the gratitude all note:", error);
        alert("Failed to save the gratitude all note");
    }
    };

    // 5b - Function to handle saving a gratitude (create or update) - 11 Oct 2024 - updated for gratitude type - 12 Oct 2024 - refined for update logic - changed note only
    const handleSaveContactGratitude = async () => {
        if (!selectedContactId || contactEditorValue.trim() === "") { 
            alert("Please select a contact and enter some text in the editor before saving.");
            return;
        }
        try {
            // console.log('selected Contact Id:', selectedContactId);
            // console.log('current Contact note Id:', currentContactNoteId);
    
            let gratitudePayload;
            if (currentContactNoteId) {
                // Update existing contact note - send only the updated note
                const updatedNote = {
                    _id: currentContactNoteId,
                    userContactId: selectedContactId,
                    userGratitudeContactNoteText: contactEditorValue,
                    userGratitudeTypeId: selectedGratitudeTypeId,
                    userGratitudeContactNoteDate: userGratitudeContactNotes?.find(note => note._id === currentContactNoteId)?.userGratitudeContactNoteDate, // Preserve original creation date
                    userGratitudeContactNoteUpdateDate: new Date(), // Update the timestamp
                };

                gratitudePayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userGratitudeContactNotes: [updatedNote], // Send only the updated note
                };
            } else {
                // Add new contact note - send only the new note
                const newNote = {
                    userContactId: selectedContactId,
                    userGratitudeContactNoteText: contactEditorValue,
                    userGratitudeTypeId: selectedGratitudeTypeId,
                    userGratitudeContactNoteDate: new Date(), // Creation date
                    userGratitudeContactNoteUpdateDate: new Date(), // Update date
                };

                gratitudePayload = {
                    userId,
                    userOrgId,
                    weekStarting: startOfWeek,
                    userGratitudeContactNotes: [newNote], // Send only the new note
                };
            }
        
            const newGratitude = await createNewUserGratitude(gratitudePayload).unwrap();
            // Reload the page after a successful save
            window.location.reload(); // This line reloads the page to fetch and display the saved data
    
            // Update local state with the updated/new contact notes
            setGratitudeContactNotes(newGratitude.userGratitudeContactNotes || []);
    
            // Clear editor and reset states after saving
            setContactEditorValue(""); 
            setIsEditingContact(false); 
            setCurrentContactNoteId(null); 
    
            alert("Gratitude contact note saved successfully");
        } catch (error) {
            console.error("Failed to save the gratitude contact note:", error);
            alert("Failed to save the gratitude contact note");
        }
    };
    
    // 6a - Handle delete gratitude note details - 02 Oct 2024    - 11 Oct 2024 - reviewed but not updated for gratitude type
    const handleDeleteAllGratitude = async (noteId) => {
        try {
            // console.log('noteId for deletion', noteId);
            // console.log('current noteId', currentReflectionId);
    
            // Mark the note for deletion by adding a delete: true flag
            const updatedNotes = userGratitudeAllNotes?.map((note) => 
                note._id === noteId ? { ...note, delete: true } : note
            );
            
            // console.log('updatedNotes with delete flag:', updatedNotes);
    
            await createNewUserGratitude({
                userId,
                userOrgId,
                weekStarting: startOfWeek, // Pass the start of the week
                userGratitudeAllNotes: updatedNotes, // Send the updated notes array with delete flag
            }).unwrap();
    
            // Update state after successful deletion
            setGratitudeAllNotes((prevGratitudes) =>
                prevGratitudes.filter((note) => note._id !== noteId)
            ); // Optionally remove from frontend state
    
            alert("Note deleted successfully");
            window.location.reload(); // Reload the page to fetch updated data
        } catch (error) {
            console.error("Failed to delete the note:", error);
            // Add error handling logic here if needed
        }
    };
    
    // 6b - Handle delete gratitude note details - 02 Oct 2024    - 11 Oct 2024 - reviewed but not updated for gratitude type
    const handleDeleteContactGratitude = async (noteId) => {
        try {
            // console.log('noteId for deletion', noteId);
            // console.log('current noteId', currentGratitudeId);
    
            // Mark the note for deletion by adding a delete: true flag
            const updatedNotes = userGratitudeContactNotes?.map((note) => 
                note._id === noteId ? { ...note, delete: true } : note
            );
            
            // console.log('updatedNotes with delete flag:', updatedNotes);
    
            await createNewUserGratitude({
                userId,
                userOrgId,
                weekStarting: startOfWeek, // Pass the start of the week
                userGratitudeContactNotes: updatedNotes, // Send the updated notes array with delete flag
            }).unwrap();
    
            // Update state after successful deletion
            setGratitudeContactNotes((prevGratitudes) =>
                prevGratitudes.filter((note) => note._id !== noteId)
            ); // Optionally remove from frontend state
    
            alert("Note deleted successfully");
            window.location.reload(); // Reload the page to fetch updated data
        } catch (error) {
            console.error("Failed to delete the note:", error);
            // Add error handling logic here if needed
        }
    };
    
    // 7 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
    }

    return (
        <div className="bg-gray-50 rounded m-1 p-1 font-poppins">

            {/* Sections for Gratitude Headers */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <p className="text-xs m-1 p-1 leading-4">Before you conclude this week and prepare for the next, take a moment to extend some gratitude and fill yourself with calm, refreshing, and inspiring energy. Recognize and count your blessings—these are what keep you moving forward, even when you face challenges, losses, or lack of recognition. In fact, count these challenges as blessings too, for they reveal your untapped strengths and pave the way for greater wins—the tougher the challenge, the sweeter the victory! Remember, your power to overcome is always greater than the obstacles you face. Start by recognizing the people and environment around you, for gratitude is the key to unlocking your inner strength.</p>
            </div>

            {/* All Gratitude */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <div className="grid grid-cols-10 justify-between items-center">
                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                        <div
                            onClick={() => setShowGratitudeAllNotes(prev => !prev)}
                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                            >
                            {showGratitudeAllNotes ? (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                            )}
                        </div>
                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtGratitudeAllIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                        <div><p className="font-bold mx-1 px-1">All</p></div>  
                    </div>
                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Gratitudes for this week:</p>
                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{gratitudeAllNotes?.length}</p></div>
                    </div>
                    {/* <div className="col-span-4 flex justify-end items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Add a Gratitude</p>
                        <button type="button" onClick={handleAddAllGratitude}>
                        <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                        </button>
                    </div> */}
                </div>
                {showGratitudeAllNotes && (
                    <div className="bg-gray-50 m-1 p-1 rounded">
                        {gratitudeAllNotes?.length === 0 && (
                            <p className="text-[11px] text-gray-500 italic mx-2 px21">
                                No gratitudes were added.
                            </p>
                        )}

                        {/* {isEditingAll ? (
                            <div className="bg-gray-50 p-1 m-1 rounded">
                                <div className="flex justify-start items-center my-1 py-1 rounded bg-gray-100">
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-white hover:bg-gray-200 cursor-pointer ml-2 pl-2" ><EtGratitudeTypeIcon1SVGComp  className='h-7 w-7 text-orange-600'/>
                                    </div>
                                    <p className="text-xs text-gray-600 m-1 p-1 font-bold">Gratitude Type:</p>
                                    <select
                                    className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none"
                                    onChange={(e) => setSelectedGratitudeTypeId(e.target.value)}
                                    value={selectedGratitudeTypeId || ""} 
                                    >
                                    {gratitudeTypeOptions}
                                    </select>
                                </div>
                            <EtContentEditorLight2
                                value={allEditorValue}
                                handleChange={handleAllEditorChange}
                                placeholderText="Write your gratitude note here..."
                                editorKey={allEditorKey}
                            />
                            <div className="flex justify-start items-center m-1 p-1">
                                <button
                                type="button"
                                onClick={handleSaveAllGratitude}
                                className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                                >
                                <p className="text-[10px] px-2 py-1">Save</p>
                                </button>
                                <button
                                type="button"
                                onClick={handleCancelAllGratitude}
                                className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                                >
                                <p className="text-[10px] px-2 py-1">Cancel</p>
                                </button>
                            </div>
                            </div>
                                ) : null} */}

                        {/* Display Reflection Notes in Descending Order */}
                        <div className="m-1 p-1">
                            <div>
                                <p className="m-1 p-1 text-xs font-bold">Gratitude Trail:</p>
                            </div>
                            <div>
                                {gratitudeAllNotes
                                    ?.slice()
                                    .sort(
                                        (a, b) =>
                                            new Date(b.userGratitudeAllNoteUpdateDate) - new Date(a.userGratitudeAllNoteUpdateDate)
                                    ) // Sort in descending order
                                    .map((note, noteIndex, arr) => (
                                        <div key={note._id} className="bg-white rounded">
                                            <RenderGratitudeNotes
                                                note={note}
                                                noteTextKey="userGratitudeAllNoteText"
                                                updateDateKey="userGratitudeAllNoteUpdateDate"
                                                createdDateKey="userGratitudeAllNoteDate"
                                                showDetail={showGratitudeAllNoteDetails}
                                                toggleShowDetail={handleToggleGratitudeAllNoteDetails}
                                                handleEditNote={handleEditAllGratitude}
                                                handleDeleteNote={handleDeleteAllGratitude}
                                                noteIndex={noteIndex}
                                                arr={arr}
                                                createMarkup={createMarkup}
                                                gratitudeTypeInfo={note.userGratitudeTypeId}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
           
           {/* By Contact Section */}
            <div className="m-1 p-1 bg-gray-100 rounded">
                <div className="grid grid-cols-10 justify-between items-center">
                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                        <div
                            onClick={() => setShowGratitudeContactNotes(prev => !prev)}
                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                            >
                            {showGratitudeContactNotes ? (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                            )}
                        </div>
                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtGratitudeContactIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                        <div><p className="font-bold mx-1 px-1">By Contact</p></div>
                    </div>
                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                        <p className="font-bold text-xs m-1 p-1">Gratitudes for this week:</p>
                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{gratitudeContactNotes?.length}</p></div>
                    </div>
                    {/* <div className="col-span-4 flex justify-end items-center m-1 p-1">
                        <div className="flex justify-start items-center m-1 p-1">
                            <p className="font-bold text-xs m-1 p-1">Add a Contact</p>
                            <button type="button" onClick={()=>handleAddContact(userId, userOrgId)}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                            </button>
                        </div>
                        <div className="flex justify-start items-center m-1 p-1">
                            <p className="font-bold text-xs m-1 p-1">Add a Gratitude</p>
                            <button type="button" onClick={handleAddContactGratitude}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                            </button>
                        </div>
                    </div> */}
                </div>
                {showGratitudeContactNotes && (
                    <div className="bg-gray-50 m-1 p-1 rounded">
                        {gratitudeContactNotes?.length === 0 && (
                            <p className="text-[11px] text-gray-500 italic mx-2 px21">
                                No gratitudes were added.
                            </p>
                        )}

                        {/* {isEditingContact ? (
                            <div className="bg-gray-50 rounded">
                               
                                <div className="flex justify-start items-center my-1 py-1 rounded bg-gray-100">
                                    <div className="flex justify-center items-center h-8 w-8 bg-white hover:bg-gray-200 rounded-full m-1">
                                        <UserIcon className='text-orange-600 h-6 w-6' />
                                    </div>
                                    <div><p className="text-xs text-gray-600 m-1 p-1 font-bold">Contact:</p></div>
                                    <select
                                    className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none "
                                    // onChange={(e) => setSelectedContactId(e.target.value)}
                                    onChange={(e) => {
                                        const selectedContact = gratitudeContacts.find(contact => contact._id === e.target.value);
                                        setSelectedContactId(e.target.value);
                                        setSelectedContactType(selectedContact?.contactTypeId?.contactTypeName || ""); // Set the contact type for filtering
                                    }}
                                    
                                    value={selectedContactId || ""} 
                                    disabled={!!currentContactNoteId}
                                    >
                                    {contactOptions}
                                    </select>

                                    <div className="flex justify-start items-center mx-2 px-2">
                                        <div className="flex justify-center items-center rounded-full h-8 w-8 bg-white hover:bg-gray-200 cursor-pointer ml-2 pl-2" ><EtGratitudeTypeIcon1SVGComp  className='h-7 w-7 text-orange-600'/>
                                        </div>
                                        <p className="text-xs text-gray-600 m-1 p-1 font-bold">Gratitude Type:</p>
                                        <select
                                        className="inline-block m-1 p-1 border rounded font-poppins text-xs ring-none"
                                        onChange={(e) => setSelectedGratitudeTypeId(e.target.value)}
                                        value={selectedGratitudeTypeId || ""} 
                                        >
                                        {gratitudeTypeOptions}
                                        </select>
                                    </div>

                                </div>
                                
                                <EtContentEditorLight2
                                    value={contactEditorValue}
                                    handleChange={handleContactEditorChange}
                                    placeholderText="Write your gratitude note here..."
                                    editorKey={contactEditorKey}
                                />
                                <div className="flex justify-start items-center m-1 p-1">
                                    <button
                                    type="button"
                                    onClick={handleSaveContactGratitude}
                                    className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700"
                                    >
                                    <p className="text-[10px] px-2 py-1">Save</p>
                                    </button>
                                    <button
                                    type="button"
                                    onClick={handleCancelContactGratitude}
                                    className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1"
                                    >
                                    <p className="text-[10px] px-2 py-1">Cancel</p>
                                    </button>
                                </div>
                            </div>
                                ) : null} */}

                        {/* Display Reflection Notes in Descending Order */}
                        <div className="m-1 p-1">
                            <div>
                                <p className="m-1 p-1 text-xs font-bold">Contact Gratitude Trail:</p>
                            </div>
                            <div>
                                {gratitudeContactNotes
                                    ?.slice()
                                    .sort(
                                        (a, b) =>
                                            new Date(b.userGratitudeContactNoteUpdateDate) - new Date(a.userGratitudeContactNoteUpdateDate)
                                    ) // Sort in descending order
                                    .map((note, noteIndex, arr) => (
                                        <div key={note._id} className="bg-white rounded">
                                            <RenderContactGratitudeNotes
                                                note={note}
                                                noteTextKey="userGratitudeContactNoteText"
                                                updateDateKey="userGratitudeContactNoteUpdateDate"
                                                createdDateKey="userGratitudeContactNoteDate"
                                                showDetail={showGratitudeContactNoteDetails}
                                                toggleShowDetail={handleToggleGratitudeContactNoteDetails}
                                                handleEditNote={handleEditContactGratitude}
                                                handleDeleteNote={handleDeleteContactGratitude}
                                                noteIndex={noteIndex}
                                                arr={arr}
                                                createMarkup={createMarkup}
                                                gratitudeTypeInfo={note.userGratitudeTypeId}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
                <EtModalCreateContact 
                    isOpen={isModalOpen}
                    userId={userId}
                    userOrgId={userOrgId}
                    triggeringUrl={triggeringUrl}
                    onClose={handleCloseCreateContactModal}
                />
            </div>
        </div>
    )

}

const WeekRangeComponent = ({ weekStarting, weekEnding }) => {
    const formattedWeekRange = formatWeekRange(weekStarting, weekEnding);

    return (
        <div>
            <p dangerouslySetInnerHTML={{ __html: formattedWeekRange }} className="mx-1 px-1 text-xs font-gray-400"></p>
        </div>
    );
};

const RenderNotes = ({
    note,
    noteTextKey,           // e.g., "coachSharedUserNoteText", "coachAllUsersNoteText"
    updateDateKey,         // e.g., "coachSharedUserNoteUpdateDate", "coachAllUsersNoteUpdateDate"
    createdDateKey,        // e.g., "coachSharedUserNoteDate", "coachAllUsersNoteDate"
    showDetail,            // State variable controlling visibility of full note
    toggleShowDetail,      // Function to toggle visibility
    handleEditNote,        // Function to handle editing of the note
    handleDeleteNote,      // Function to handle deletion of the note
    noteIndex,
    userId,
    arr,
    createMarkup,
  }) => {
  
    const noteText = note[noteTextKey];
    const updateDate = note[updateDateKey];
    const createdDate = note[createdDateKey];
    const noteId = note._id;
  
    return (
      noteText.length > 0 && (
        <div className="bg-gray-100 m-1 p-1 rounded">
          <div className="grid grid-cols-11 justify-start items-center m-1 p-1 gap-2">
            <div className="col-span-2 flex justify-start items-center">
              <div
                onClick={() => toggleShowDetail(noteId)}
                className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
              >
                {showDetail[noteId] ? (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                )}
              </div>
              <div>
                <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ noteIndex +1}.</p>
              </div>
              <div className="">
                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                  {getTimeSince(updateDate)}
                </p>
                <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
              </div>
            </div>
  
            <div className="col-span-6 flex justify-start items-center">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
              ></div>
            </div>
  
            <div className="col-span-3 flex justify-start items-center mx-2">
              <ClockIcon className="h-5 w-5 text-lime-600" />
              <p className="text-[9px] mx-1">First Created:</p>
              <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdDate)}</p>
            </div>
  
            {/* <div className="col-span-1 flex justify-end items-center mx-2">
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <PencilSquareIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  // onClick={() => handleEditNote(userId, note)}
                  onClick={() => (userId ? handleEditNote(userId, note) : handleEditNote(note))}
                />
              </div>
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <TrashIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  onClick={() => handleDeleteNote(noteId)}
                />
              </div>
            </div> */}
          </div>
  
          {showDetail[noteId] && (
            <div className="m-1 p-1 rounded bg-gray-50">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1"
              ></div>
            </div>
          )}
        </div>
      )
    );
  }

const RenderContactNotes = ({
    note,
    noteTextKey,           // e.g., "coachSharedUserNoteText", "coachAllUsersNoteText"
    updateDateKey,         // e.g., "coachSharedUserNoteUpdateDate", "coachAllUsersNoteUpdateDate"
    createdDateKey,        // e.g., "coachSharedUserNoteDate", "coachAllUsersNoteDate"
    showDetail,            // State variable controlling visibility of full note
    toggleShowDetail,      // Function to toggle visibility
    handleEditNote,        // Function to handle editing of the note
    handleDeleteNote,      // Function to handle deletion of the note
    noteIndex,
    userId,
    arr,
    createMarkup,
  }) => {

    // console.log('Contact note', note)
  
    const noteText = note[noteTextKey];
    const updateDate = note[updateDateKey];
    const createdDate = note[createdDateKey];
    const noteId = note._id;

    // Contact Data
    const contactType = note.userContactId?.contactTypeId?.contactTypeName
    const contactName = note.userContactId?.contactName
    const contactNickName = note.userContactId?.contactNickName
    const contactEmail = note.userContactId?.contactEmail
    const contactPhone = note.userContactId?.contactPhone
    const contactOrg = note.userContactId?.contactOrgId?.contactOrganizationName
    const contactRole = note.userContactId?.contactOrgRole?.orgRoleName
    const contactTitle = note.userContactId?.contactOrgTitle?.orgTitleName

  
    return (
      noteText.length > 0 && (
        <div className="bg-gray-100 m-1 p-1 rounded">
          <div className="grid grid-cols-12 justify-start items-center m-1 p-1">
            <div className="col-span-2 flex justify-start items-center">
              <div
                onClick={() => toggleShowDetail(noteId)}
                className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
              >
                {showDetail[noteId] ? (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                )}
              </div>
              <div>
                <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ noteIndex +1}.</p>
              </div>
              <div className="">
                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                  {getTimeSince(updateDate)}
                </p>
                <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
              </div>
            </div>

            <div className="col-span-3 flex justify-start items-center">
                <div className="mx-1 px-1"><UserIcon className="h-6 w-6 text-orange-600" /></div>
                <div>
                    <p className='text-xs text-gray-800 mx-1 px-1 font-bold'>{contactName}</p>
                    {contactRole !== 'None' && contactTitle !== 'None' && (
                    <p className='text-[10px] text-gray-400 mx-1 px-1 italic'>{contactTitle} & {contactRole}</p>)}
                    <p className='text-[10px] text-gray-800 mx-1 px-1'>{contactOrg}</p>
                </div>
            </div>
  
            <div className="col-span-4 flex justify-start items-center">
              
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
              ></div>
            </div>
  
            <div className="col-span-3 flex justify-start items-center mx-2">
              <ClockIcon className="h-5 w-5 text-lime-600" />
              <p className="text-[9px] mx-1">First Created:</p>
              <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdDate)}</p>
            {/* </div> */}
  
            {/* <div className="col-span-1 flex justify-end items-center mx-2"> */}
              {/* <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <PencilSquareIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  // onClick={() => handleEditNote(userId, note)}
                  onClick={() => (userId ? handleEditNote(userId, note) : handleEditNote(note))}
                />
              </div>
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <TrashIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  onClick={() => handleDeleteNote(noteId)}
                />
              </div> */}
            </div>
          </div>
  
          {showDetail[noteId] && (
            <div className="m-1 p-1 rounded bg-gray-50">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1"
              ></div>
            </div>
          )}
        </div>
      )
    );
  }

const RenderContributionNotes = ({
    note,
    noteTextKey,           // e.g., "coachSharedUserNoteText", "coachAllUsersNoteText"
    updateDateKey,         // e.g., "coachSharedUserNoteUpdateDate", "coachAllUsersNoteUpdateDate"
    createdDateKey,        // e.g., "coachSharedUserNoteDate", "coachAllUsersNoteDate"
    showDetail,            // State variable controlling visibility of full note
    toggleShowDetail,      // Function to toggle visibility
    handleEditNote,        // Function to handle editing of the note
    handleDeleteNote,      // Function to handle deletion of the note
    noteIndex,
    userId,
    arr,
    createMarkup,
    currentCreationId,
    creations,
    contributionTypeInfo
  }) => {
  
    const noteText = note[noteTextKey];
    const updateDate = note[updateDateKey];
    const createdDate = note[createdDateKey];
    const noteId = note._id;

    const contributionTypeName = contributionTypeInfo?.contributionTypeName
    const contributionTypePic = contributionTypeInfo?.pic

    // console.log('currentCreationId inside RenderNotes:', currentCreationId)
    // console.log('contributionTypeInfo', contributionTypeInfo)
  
    return (
      noteText.length >= 0 && (
        <div className="bg-gray-100 m-1 p-1 rounded">
          <div className="grid grid-cols-12 justify-start items-center m-1 p-1 gap-2">
            <div className="col-span-2 flex justify-start items-center">
              <div
                onClick={() => toggleShowDetail(noteId)}
                className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
              >
                {showDetail[noteId] ? (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                )}
              </div>
              <div>
                <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ noteIndex +1}.</p>
              </div>
              <div className="">
                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                  {getTimeSince(updateDate)}
                </p>
                <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
              </div>
            </div>
            <div className="col-span-2 flex justify-start items-center">
                 
                 <div className="rounded-full flex justify-center items-center h-7 w-7 bg-white hover:bg-gray-200">
                    <img src={contributionTypePic} alt="" className="h-6 w-6" />
                 </div>
                 <div>
                     <p className="text-[10px] mx-1 px-1 text-gray-600">{contributionTypeName}</p>
                </div>

            </div>
            <div className="col-span-4 flex justify-start items-center">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
              ></div>
            </div>
  
            <div className="col-span-3 flex justify-start items-center mx-2">
              <ClockIcon className="h-5 w-5 text-lime-600" />
              <p className="text-[9px] mx-1">First Created:</p>
              <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdDate)}</p>
            </div>
  
            <div className="col-span-1 flex justify-end items-center mx-2">
              {/* <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <PencilSquareIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  // onClick={() => handleEditNote(userId, note)}
                  onClick={() => 
                    handleEditNote(note)}
                />
              </div>
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <TrashIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  onClick={() => noteId ? handleDeleteNote(noteId, currentCreationId) : handleDeleteNote(note)}
                //   onClick={() => handleDeleteNote(note._id)}
                />
              </div> */}
            </div>
          </div>
  
          {showDetail[noteId] && (
            <div className="m-1 p-1 rounded bg-gray-50">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1"
              ></div>
            </div>
          )}
        </div>
      )
    );
  }

const RenderContactContributionNotes = ({
    note,
    noteTextKey,           // e.g., "coachSharedUserNoteText", "coachAllUsersNoteText"
    updateDateKey,         // e.g., "coachSharedUserNoteUpdateDate", "coachAllUsersNoteUpdateDate"
    createdDateKey,        // e.g., "coachSharedUserNoteDate", "coachAllUsersNoteDate"
    showDetail,            // State variable controlling visibility of full note
    toggleShowDetail,      // Function to toggle visibility
    handleEditNote,        // Function to handle editing of the note
    handleDeleteNote,      // Function to handle deletion of the note
    noteIndex,
    userId,
    arr,
    createMarkup,
    contributionTypeInfo,
  }) => {

    // console.log('Contact note', note)
  
    const noteText = note[noteTextKey];
    const updateDate = note[updateDateKey];
    const createdDate = note[createdDateKey];
    const noteId = note._id;

    const contributionTypeName = contributionTypeInfo?.contributionTypeName
    const contributionTypePic = contributionTypeInfo?.pic

    // Contact Data
    const contactType = note.userContactId?.contactTypeId?.contactTypeName
    const contactName = note.userContactId?.contactName
    const contactNickName = note.userContactId?.contactNickName
    const contactEmail = note.userContactId?.contactEmail
    const contactPhone = note.userContactId?.contactPhone
    const contactOrg = note.userContactId?.contactOrgId?.contactOrganizationName
    const contactRole = note.userContactId?.contactOrgRole?.orgRoleName
    const contactTitle = note.userContactId?.contactOrgTitle?.orgTitleName

  
    return (
      noteText.length > 0 && (
        <div className="bg-gray-100 m-1 p-1 rounded">
          <div className="grid grid-cols-12 justify-start items-center m-1 p-1">
            <div className="col-span-2 flex justify-start items-center">
              <div
                onClick={() => toggleShowDetail(noteId)}
                className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
              >
                {showDetail[noteId] ? (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                )}
              </div>
              <div>
                <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ noteIndex +1}.</p>
              </div>
              <div className="">
                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                  {getTimeSince(updateDate)}
                </p>
                <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
              </div>
            </div>
            
            <div className="col-span-2 flex justify-start items-center">
                <div className="mx-1 px-1"><UserIcon className="h-6 w-6 text-orange-600" /></div>
                <div>
                    <p className='text-xs text-gray-800 mx-1 px-1 font-bold'>{contactName}</p>
                    {contactRole !== 'None' && contactTitle !== 'None' && (
                    <p className='text-[10px] text-gray-400 mx-1 px-1 italic'>{contactTitle} & {contactRole}</p>)}
                    <p className='text-[10px] text-gray-800 mx-1 px-1'>{contactOrg}</p>
                </div>
            </div>

            <div className="col-span-2 flex justify-center items-center">
                 
                 <div className="rounded-full flex justify-center items-center h-7 w-7 bg-white hover:bg-gray-200">
                    <img src={contributionTypePic} alt="" className="h-6 w-6" />
                 </div>
                 <div>
                     <p className="text-[10px] mx-1 px-1 text-gray-600">{contributionTypeName}</p>
                </div>

            </div>
  
            <div className="col-span-3 flex justify-start items-center">
              
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
              ></div>
            </div>
  
            <div className="col-span-3 flex justify-start items-center mx-2">
              <ClockIcon className="h-5 w-5 text-lime-600" />
              <p className="text-[9px] mx-1">First Created:</p>
              <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdDate)}</p>
           
              {/* <div className="rounded-full flex justify-end items-center h-6 w-6 hover:bg-white">
                <PencilSquareIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  // onClick={() => handleEditNote(userId, note)}
                  onClick={() => (userId ? handleEditNote(userId, note) : handleEditNote(note))}
                />
              </div>
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <TrashIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  onClick={() => handleDeleteNote(noteId)}
                />
              </div> */}
            </div>
          </div>
  
          {showDetail[noteId] && (
            <div className="m-1 p-1 rounded bg-gray-50">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1"
              ></div>
            </div>
          )}
        </div>
      )
    );
}

const RenderImpactNotes = ({
    note,
    noteTextKey,           // e.g., "coachSharedUserNoteText", "coachAllUsersNoteText"
    updateDateKey,         // e.g., "coachSharedUserNoteUpdateDate", "coachAllUsersNoteUpdateDate"
    createdDateKey,        // e.g., "coachSharedUserNoteDate", "coachAllUsersNoteDate"
    showDetail,            // State variable controlling visibility of full note
    toggleShowDetail,      // Function to toggle visibility
    handleEditNote,        // Function to handle editing of the note
    handleDeleteNote,      // Function to handle deletion of the note
    noteIndex,
    userId,
    arr,
    createMarkup,
    currentCreationId,
    creations,
    impactTypeInfo
  }) => {
  
    const noteText = note[noteTextKey];
    const updateDate = note[updateDateKey];
    const createdDate = note[createdDateKey];
    const noteId = note._id;

    const impactTypeName = impactTypeInfo?.impactTypeName
    const impactTypePic = impactTypeInfo?.pic

    // console.log('impactTypeInfo', impactTypeInfo)
    // console.log('impactTypeName', impactTypeName)
    // console.log('impactTypePic', impactTypePic)
    // console.log('all impact note', note)
  
    return (
      noteText.length >= 0 && (
        <div className="bg-gray-100 m-1 p-1 rounded">
          <div className="grid grid-cols-12 justify-start items-center m-1 p-1 gap-2">
            <div className="col-span-2 flex justify-start items-center">
              <div
                onClick={() => toggleShowDetail(noteId)}
                className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
              >
                {showDetail[noteId] ? (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                )}
              </div>
              <div>
                <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ noteIndex +1}.</p>
              </div>
              <div className="">
                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                  {getTimeSince(updateDate)}
                </p>
                <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
              </div>
            </div>
            <div className="col-span-2 flex justify-start items-center">
                 
                 <div className="rounded-full flex justify-center items-center h-7 w-7 bg-white hover:bg-gray-200">
                    <img src={impactTypePic} alt="" className="h-6 w-6" />
                 </div>
                 <div>
                     <p className="text-[10px] mx-1 px-1 text-gray-600">{impactTypeName}</p>
                </div>

            </div>
            <div className="col-span-4 flex justify-start items-center">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
              ></div>
            </div>
  
            <div className="col-span-3 flex justify-start items-center mx-2">
              <ClockIcon className="h-5 w-5 text-lime-600" />
              <p className="text-[9px] mx-1">First Created:</p>
              <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdDate)}</p>
            </div>
  
            {/* <div className="col-span-1 flex justify-end items-center mx-2">
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <PencilSquareIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  // onClick={() => handleEditNote(userId, note)}
                  onClick={() => 
                    handleEditNote(note)}
                />
              </div>
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <TrashIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  onClick={() => handleDeleteNote(note)}
                //   onClick={() => handleDeleteNote(note._id)}
                />
              </div>
            </div> */}
          </div>
  
          {showDetail[noteId] && (
            <div className="m-1 p-1 rounded bg-gray-50">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1"
              ></div>
            </div>
          )}
        </div>
      )
    );
  }

const RenderContactImpactNotes = ({
    note,
    noteTextKey,           // e.g., "coachSharedUserNoteText", "coachAllUsersNoteText"
    updateDateKey,         // e.g., "coachSharedUserNoteUpdateDate", "coachAllUsersNoteUpdateDate"
    createdDateKey,        // e.g., "coachSharedUserNoteDate", "coachAllUsersNoteDate"
    showDetail,            // State variable controlling visibility of full note
    toggleShowDetail,      // Function to toggle visibility
    handleEditNote,        // Function to handle editing of the note
    handleDeleteNote,      // Function to handle deletion of the note
    noteIndex,
    userId,
    arr,
    createMarkup,
    impactTypeInfo,
  }) => {

    // console.log('Contact note', note)
  
    const noteText = note[noteTextKey];
    const updateDate = note[updateDateKey];
    const createdDate = note[createdDateKey];
    const noteId = note._id;

    const impactTypeName = impactTypeInfo?.impactTypeName
    const impactTypePic = impactTypeInfo?.pic
    // console.log('noteText', noteText)
    // console.log('impactTypeName', impactTypeName)

    // Contact Data
    const contactType = note.userContactId?.contactTypeId?.contactTypeName
    const contactName = note.userContactId?.contactName
    const contactNickName = note.userContactId?.contactNickName
    const contactEmail = note.userContactId?.contactEmail
    const contactPhone = note.userContactId?.contactPhone
    const contactOrg = note.userContactId?.contactOrgId?.contactOrganizationName
    const contactRole = note.userContactId?.contactOrgRole?.orgRoleName
    const contactTitle = note.userContactId?.contactOrgTitle?.orgTitleName

  
    return (
      noteText.length > 0 && (
        <div className="bg-gray-100 m-1 p-1 rounded">
          <div className="grid grid-cols-12 justify-start items-center m-1 p-1">
            <div className="col-span-2 flex justify-start items-center">
              <div
                onClick={() => toggleShowDetail(noteId)}
                className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
              >
                {showDetail[noteId] ? (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                )}
              </div>
              <div>
                <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ noteIndex +1}.</p>
              </div>
              <div className="">
                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                  {getTimeSince(updateDate)}
                </p>
                <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
              </div>
            </div>
            
            <div className="col-span-2 flex justify-start items-center">
                <div className="mx-1 px-1"><UserIcon className="h-6 w-6 text-orange-600" /></div>
                <div>
                    <p className='text-xs text-gray-800 mx-1 px-1 font-bold'>{contactName}</p>
                    {contactRole !== 'None' && contactTitle !== 'None' && (
                    <p className='text-[10px] text-gray-400 mx-1 px-1 italic'>{contactTitle} & {contactRole}</p>)}
                    <p className='text-[10px] text-gray-800 mx-1 px-1'>{contactOrg}</p>
                </div>
            </div>

            <div className="col-span-2 flex justify-start items-center">
                 
                 <div className="rounded-full flex justify-center items-center h-7 w-7 bg-white hover:bg-gray-200">
                    <img src={impactTypePic} alt="" className="h-6 w-6" />
                 </div>
                 <div>
                     <p className="text-[10px] mx-1 px-1 text-gray-600">{impactTypeName}</p>
                </div>

            </div>
  
            <div className="col-span-3 flex justify-start items-center">
              
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
              ></div>
            </div>
  
            <div className="col-span-3 flex justify-start items-center mx-2">
              <ClockIcon className="h-5 w-5 text-lime-600" />
              <p className="text-[9px] mx-1">First Created:</p>
              <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdDate)}</p>
           
              {/* <div className="rounded-full flex justify-end items-center h-6 w-6 hover:bg-white">
                <PencilSquareIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  // onClick={() => handleEditNote(userId, note)}
                  onClick={() => handleEditNote(note)}
                />
              </div>
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <TrashIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  onClick={() => handleDeleteNote(noteId)}
                />
              </div> */}
            </div>
          </div>
  
          {showDetail[noteId] && (
            <div className="m-1 p-1 rounded bg-gray-50">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1"
              ></div>
            </div>
          )}
        </div>
      )
    );
  }

const RenderWinNotes = ({
    note,
    noteTextKey,           // e.g., "coachSharedUserNoteText", "coachAllUsersNoteText"
    updateDateKey,         // e.g., "coachSharedUserNoteUpdateDate", "coachAllUsersNoteUpdateDate"
    createdDateKey,        // e.g., "coachSharedUserNoteDate", "coachAllUsersNoteDate"
    showDetail,            // State variable controlling visibility of full note
    toggleShowDetail,      // Function to toggle visibility
    handleEditNote,        // Function to handle editing of the note
    handleDeleteNote,      // Function to handle deletion of the note
    noteIndex,
    userId,
    arr,
    createMarkup,
    currentCreationId,
    creations,
    winTypeInfo
  }) => {
  
    const noteText = note[noteTextKey];
    const updateDate = note[updateDateKey];
    const createdDate = note[createdDateKey];
    const noteId = note._id;

    const winTypeName = winTypeInfo?.winTypeName
    const winTypePic = winTypeInfo?.pic

    // console.log('winTypeInfo', winTypeInfo)
    // console.log('winTypeName', winTypeName)
    // console.log('winTypePic', winTypePic)
  
    return (
      noteText.length >= 0 && (
        <div className="bg-gray-100 m-1 p-1 rounded">
          <div className="grid grid-cols-12 justify-start items-center m-1 p-1 gap-2">
            <div className="col-span-2 flex justify-start items-center">
              <div
                onClick={() => toggleShowDetail(noteId)}
                className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
              >
                {showDetail[noteId] ? (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                )}
              </div>
              <div>
                <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ noteIndex +1}.</p>
              </div>
              <div className="">
                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                  {getTimeSince(updateDate)}
                </p>
                <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
              </div>
            </div>
            <div className="col-span-2 flex justify-start items-center">
                 
                 <div className="rounded-full flex justify-center items-center h-7 w-7 bg-white hover:bg-gray-200">
                    <img src={winTypePic} alt="" className="h-6 w-6" />
                 </div>
                 <div>
                     <p className="text-[10px] mx-1 px-1 text-gray-600">{winTypeName}</p>
                </div>

            </div>
            <div className="col-span-4 flex justify-start items-center">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
              ></div>
            </div>
  
            <div className="col-span-3 flex justify-start items-center mx-2">
              <ClockIcon className="h-5 w-5 text-lime-600" />
              <p className="text-[9px] mx-1">First Created:</p>
              <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdDate)}</p>
            </div>
  
            {/* <div className="col-span-1 flex justify-end items-center mx-2">
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <PencilSquareIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  // onClick={() => handleEditNote(userId, note)}
                  onClick={() => 
                    handleEditNote(note)}
                />
              </div>
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <TrashIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  onClick={() => handleDeleteNote(note)}
                //   onClick={() => handleDeleteNote(note._id)}
                />
              </div>
            </div> */}
          </div>
  
          {showDetail[noteId] && (
            <div className="m-1 p-1 rounded bg-gray-50">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1"
              ></div>
            </div>
          )}
        </div>
      )
    );
  }

const RenderContactWinNotes = ({
    note,
    noteTextKey,           // e.g., "coachSharedUserNoteText", "coachAllUsersNoteText"
    updateDateKey,         // e.g., "coachSharedUserNoteUpdateDate", "coachAllUsersNoteUpdateDate"
    createdDateKey,        // e.g., "coachSharedUserNoteDate", "coachAllUsersNoteDate"
    showDetail,            // State variable controlling visibility of full note
    toggleShowDetail,      // Function to toggle visibility
    handleEditNote,        // Function to handle editing of the note
    handleDeleteNote,      // Function to handle deletion of the note
    noteIndex,
    userId,
    arr,
    createMarkup,
    winTypeInfo,
  }) => {

    // console.log('Contact note', note)
  
    const noteText = note[noteTextKey];
    const updateDate = note[updateDateKey];
    const createdDate = note[createdDateKey];
    const noteId = note._id;

    const winTypeName = winTypeInfo?.winTypeName
    const winTypePic = winTypeInfo?.pic
    // console.log('noteText', noteText)
    // console.log('winTypeName', winTypeName)

    // Contact Data
    const contactType = note.userContactId?.contactTypeId?.contactTypeName
    const contactName = note.userContactId?.contactName
    const contactNickName = note.userContactId?.contactNickName
    const contactEmail = note.userContactId?.contactEmail
    const contactPhone = note.userContactId?.contactPhone
    const contactOrg = note.userContactId?.contactOrgId?.contactOrganizationName
    const contactRole = note.userContactId?.contactOrgRole?.orgRoleName
    const contactTitle = note.userContactId?.contactOrgTitle?.orgTitleName

  
    return (
      noteText.length > 0 && (
        <div className="bg-gray-100 m-1 p-1 rounded">
          <div className="grid grid-cols-12 justify-start items-center m-1 p-1">
            <div className="col-span-2 flex justify-start items-center">
              <div
                onClick={() => toggleShowDetail(noteId)}
                className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
              >
                {showDetail[noteId] ? (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                )}
              </div>
              <div>
                <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ noteIndex +1}.</p>
              </div>
              <div className="">
                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                  {getTimeSince(updateDate)}
                </p>
                <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
              </div>
            </div>
            
            <div className="col-span-2 flex justify-start items-center">
                <div className="mx-1 px-1"><UserIcon className="h-6 w-6 text-orange-600" /></div>
                <div>
                    <p className='text-xs text-gray-800 mx-1 px-1 font-bold'>{contactName}</p>
                    {contactRole !== 'None' && contactTitle !== 'None' && (
                    <p className='text-[10px] text-gray-400 mx-1 px-1 italic'>{contactTitle} & {contactRole}</p>)}
                    <p className='text-[10px] text-gray-800 mx-1 px-1'>{contactOrg}</p>
                </div>
            </div>

            <div className="col-span-2 flex justify-start items-center">
                 
                 <div className="rounded-full flex justify-center items-center h-7 w-7 bg-white hover:bg-gray-200">
                    <img src={winTypePic} alt="" className="h-6 w-6" />
                 </div>
                 <div>
                     <p className="text-[10px] mx-1 px-1 text-gray-600">{winTypeName}</p>
                </div>

            </div>
  
            <div className="col-span-3 flex justify-start items-center">
              
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
              ></div>
            </div>
  
            <div className="col-span-3 flex justify-start items-center mx-2">
              <ClockIcon className="h-5 w-5 text-lime-600" />
              <p className="text-[9px] mx-1">First Created:</p>
              <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdDate)}</p>
           
              {/* <div className="rounded-full flex justify-end items-center h-6 w-6 hover:bg-white">
                <PencilSquareIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  // onClick={() => handleEditNote(userId, note)}
                  onClick={() => (userId ? handleEditNote(userId, note) : handleEditNote(note))}
                />
              </div>
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <TrashIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  onClick={() => handleDeleteNote(noteId)}
                />
              </div> */}
            </div>
          </div>
  
          {showDetail[noteId] && (
            <div className="m-1 p-1 rounded bg-gray-50">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1"
              ></div>
            </div>
          )}
        </div>
      )
    );
  }

const RenderUserChallengeNotes = ({
    note,
    noteTextKey,           // e.g., "coachSharedUserNoteText", "coachAllUsersNoteText"
    updateDateKey,         // e.g., "coachSharedUserNoteUpdateDate", "coachAllUsersNoteUpdateDate"
    createdDateKey,        // e.g., "coachSharedUserNoteDate", "coachAllUsersNoteDate"
    showDetail,            // State variable controlling visibility of full note
    toggleShowDetail,      // Function to toggle visibility
    handleEditNote,        // Function to handle editing of the note
    handleDeleteNote,      // Function to handle deletion of the note
    noteIndex,
    userId,
    arr,
    createMarkup,
    currentCreationId,
    creations,
    userChallengeTypeInfo
  }) => {
  
    const noteText = note[noteTextKey];
    const updateDate = note[updateDateKey];
    const createdDate = note[createdDateKey];
    const noteId = note._id;

    const userChallengeTypeName = userChallengeTypeInfo?.userChallengeTypeName
    const userChallengeTypePic = userChallengeTypeInfo?.pic

    // console.log('userChallengeTypeInfo', userChallengeTypeInfo)
    // console.log('userChallengeTypeName', userChallengeTypeName)
    // console.log('userChallengeTypePic', userChallengeTypePic)
  
    return (
      noteText?.length > 0 && (
        <div className="bg-gray-100 m-1 p-1 rounded">
          <div className="grid grid-cols-12 justify-start items-center m-1 p-1 gap-2">
            <div className="col-span-2 flex justify-start items-center">
              <div
                onClick={() => toggleShowDetail(noteId)}
                className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
              >
                {showDetail[noteId] ? (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                )}
              </div>
              <div>
                <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ noteIndex +1}.</p>
              </div>
              <div className="">
                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                  {getTimeSince(updateDate)}
                </p>
                <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
              </div>
            </div>
            <div className="col-span-2 flex justify-start items-center">
                 
                 <div className="rounded-full flex justify-center items-center h-7 w-7 bg-white hover:bg-gray-200">
                    <img src={userChallengeTypePic} alt="" className="h-6 w-6" />
                 </div>
                 <div>
                     <p className="text-[10px] mx-1 px-1 text-gray-600">{userChallengeTypeName}</p>
                </div>

            </div>
            <div className="col-span-4 flex justify-start items-center">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
              ></div>
            </div>
  
            <div className="col-span-3 flex justify-start items-center mx-2">
              <ClockIcon className="h-5 w-5 text-lime-600" />
              <p className="text-[9px] mx-1">First Created:</p>
              <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdDate)}</p>
            </div>
  
            {/* <div className="col-span-1 flex justify-end items-center mx-2">
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <PencilSquareIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  // onClick={() => handleEditNote(userId, note)}
                  onClick={() => 
                    handleEditNote(note)}
                />
              </div>
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <TrashIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  onClick={() => handleDeleteNote(noteId)}
                //   onClick={() => handleDeleteNote(note._id)}
                />
              </div>
            </div> */}
          </div>
  
          {showDetail[noteId] && (
            <div className="m-1 p-1 rounded bg-gray-50">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1"
              ></div>
            </div>
          )}
        </div>
      )
    );
  }

const RenderContactUserChallengeNotes = ({
    note,
    noteTextKey,           // e.g., "coachSharedUserNoteText", "coachAllUsersNoteText"
    updateDateKey,         // e.g., "coachSharedUserNoteUpdateDate", "coachAllUsersNoteUpdateDate"
    createdDateKey,        // e.g., "coachSharedUserNoteDate", "coachAllUsersNoteDate"
    showDetail,            // State variable controlling visibility of full note
    toggleShowDetail,      // Function to toggle visibility
    handleEditNote,        // Function to handle editing of the note
    handleDeleteNote,      // Function to handle deletion of the note
    noteIndex,
    userId,
    arr,
    createMarkup,
    userChallengeTypeInfo,
  }) => {

    // console.log('Contact note', note)
  
    const noteText = note[noteTextKey];
    const updateDate = note[updateDateKey];
    const createdDate = note[createdDateKey];
    const noteId = note._id;

    const userChallengeTypeName = userChallengeTypeInfo?.userChallengeTypeName
    const userChallengeTypePic = userChallengeTypeInfo?.pic
 
    // console.log('noteText', noteText)
    // console.log('userChallengeTypeName', userChallengeTypeName)
    // console.log('userChallengeTypePic', userChallengeTypePic)

    // Contact Data
    const contactType = note.userContactId?.contactTypeId?.contactTypeName
    const contactName = note.userContactId?.contactName
    const contactNickName = note.userContactId?.contactNickName
    const contactEmail = note.userContactId?.contactEmail
    const contactPhone = note.userContactId?.contactPhone
    const contactOrg = note.userContactId?.contactOrgId?.contactOrganizationName
    const contactRole = note.userContactId?.contactOrgRole?.orgRoleName
    const contactTitle = note.userContactId?.contactOrgTitle?.orgTitleName

  
    return (
      noteText.length > 0 && (
        <div className="bg-gray-100 m-1 p-1 rounded">
          <div className="grid grid-cols-12 justify-start items-center m-1 p-1">
            <div className="col-span-2 flex justify-start items-center">
              <div
                onClick={() => toggleShowDetail(noteId)}
                className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
              >
                {showDetail[noteId] ? (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                )}
              </div>
              <div>
                <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ noteIndex +1}.</p>
              </div>
              <div className="">
                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                  {getTimeSince(updateDate)}
                </p>
                <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
              </div>
            </div>
            
            <div className="col-span-2 flex justify-start items-center">
                <div className="mx-1 px-1"><UserIcon className="h-6 w-6 text-orange-600" /></div>
                <div>
                    <p className='text-xs text-gray-800 mx-1 px-1 font-bold'>{contactName}</p>
                    {contactRole !== 'None' && contactTitle !== 'None' && (
                    <p className='text-[10px] text-gray-400 mx-1 px-1 italic'>{contactTitle} & {contactRole}</p>)}
                    <p className='text-[10px] text-gray-800 mx-1 px-1'>{contactOrg}</p>
                </div>
            </div>

            <div className="col-span-2 flex justify-start items-center">
                 
                 <div className="rounded-full flex justify-center items-center h-7 w-7 bg-white hover:bg-gray-200">
                    <img src={userChallengeTypePic} alt="" className="h-6 w-6" />
                 </div>
                 <div>
                     <p className="text-[10px] mx-1 px-1 text-gray-600">{userChallengeTypeName}</p>
                </div>

            </div>
  
            <div className="col-span-3 flex justify-start items-center">
              
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
              ></div>
            </div>
  
            <div className="col-span-3 flex justify-start items-center mx-2">
              <ClockIcon className="h-5 w-5 text-lime-600" />
              <p className="text-[9px] mx-1">First Created:</p>
              <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdDate)}</p>
           
              {/* <div className="rounded-full flex justify-end items-center h-6 w-6 hover:bg-white">
                <PencilSquareIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  // onClick={() => handleEditNote(userId, note)}
                  onClick={() => (userId ? handleEditNote(userId, note) : handleEditNote(note))}
                />
              </div>
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <TrashIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  onClick={() => handleDeleteNote(noteId)}
                />
              </div> */}
            </div>
          </div>
  
          {showDetail[noteId] && (
            <div className="m-1 p-1 rounded bg-gray-50">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1"
              ></div>
            </div>
          )}
        </div>
      )
    );
  }

const RenderIntentionNotes = ({
    note,
    noteTextKey,           // e.g., "coachSharedUserNoteText", "coachAllUsersNoteText"
    updateDateKey,         // e.g., "coachSharedUserNoteUpdateDate", "coachAllUsersNoteUpdateDate"
    createdDateKey,        // e.g., "coachSharedUserNoteDate", "coachAllUsersNoteDate"
    showDetail,            // State variable controlling visibility of full note
    toggleShowDetail,      // Function to toggle visibility
    handleEditNote,        // Function to handle editing of the note
    handleDeleteNote,      // Function to handle deletion of the note
    noteIndex,
    userId,
    arr,
    createMarkup,
    currentCreationId,
    creations,
    intentionTypeInfo
  }) => {
  
    const noteText = note[noteTextKey];
    const updateDate = note[updateDateKey];
    const createdDate = note[createdDateKey];
    const noteId = note._id;

    const intentionTypeName = intentionTypeInfo?.intentionTypeName
    const intentionTypePic = intentionTypeInfo?.pic

    // console.log('intentionTypeInfo', intentionTypeInfo)
    // console.log('intentionTypeName', intentionTypeName)
    // console.log('intentionTypePic', intentionTypePic)
    // console.log('all intention note', note)
  
    return (
      noteText.length >= 0 && (
        <div className="bg-gray-100 m-1 p-1 rounded">
          <div className="grid grid-cols-12 justify-start items-center m-1 p-1 gap-2">
            <div className="col-span-2 flex justify-start items-center">
              <div
                onClick={() => toggleShowDetail(noteId)}
                className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
              >
                {showDetail[noteId] ? (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                )}
              </div>
              <div>
                <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ noteIndex +1}.</p>
              </div>
              <div className="">
                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                  {getTimeSince(updateDate)}
                </p>
                <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
              </div>
            </div>
            <div className="col-span-2 flex justify-start items-center">
                 
                 <div className="rounded-full flex justify-center items-center h-7 w-7 bg-white hover:bg-gray-200">
                    <img src={intentionTypePic} alt="" className="h-6 w-6" />
                 </div>
                 <div>
                     <p className="text-[10px] mx-1 px-1 text-gray-600">{intentionTypeName}</p>
                </div>

            </div>
            <div className="col-span-4 flex justify-start items-center">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
              ></div>
            </div>
  
            <div className="col-span-3 flex justify-start items-center mx-2">
              <ClockIcon className="h-5 w-5 text-lime-600" />
              <p className="text-[9px] mx-1">First Created:</p>
              <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdDate)}</p>
            </div>
  
            {/* <div className="col-span-1 flex justify-end items-center mx-2">
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <PencilSquareIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  // onClick={() => handleEditNote(userId, note)}
                  onClick={() => 
                    handleEditNote(note)}
                />
              </div>
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <TrashIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  onClick={() => handleDeleteNote(noteId)}
                //   onClick={() => handleDeleteNote(note._id)}
                />
              </div>
            </div> */}
          </div>
  
          {showDetail[noteId] && (
            <div className="m-1 p-1 rounded bg-gray-50">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1"
              ></div>
            </div>
          )}
        </div>
      )
    );
  }

const RenderContactIntentionNotes = ({
    note,
    noteTextKey,           // e.g., "coachSharedUserNoteText", "coachAllUsersNoteText"
    updateDateKey,         // e.g., "coachSharedUserNoteUpdateDate", "coachAllUsersNoteUpdateDate"
    createdDateKey,        // e.g., "coachSharedUserNoteDate", "coachAllUsersNoteDate"
    showDetail,            // State variable controlling visibility of full note
    toggleShowDetail,      // Function to toggle visibility
    handleEditNote,        // Function to handle editing of the note
    handleDeleteNote,      // Function to handle deletion of the note
    noteIndex,
    userId,
    arr,
    createMarkup,
    intentionTypeInfo,
  }) => {

    // console.log('Contact note', note)
  
    const noteText = note[noteTextKey];
    const updateDate = note[updateDateKey];
    const createdDate = note[createdDateKey];
    const noteId = note._id;

    const intentionTypeName = intentionTypeInfo?.intentionTypeName
    const intentionTypePic = intentionTypeInfo?.pic
    // console.log('noteText', noteText)
    // console.log('intentionTypeName', intentionTypeName)

    // Contact Data
    const contactType = note.userContactId?.contactTypeId?.contactTypeName
    const contactName = note.userContactId?.contactName
    const contactNickName = note.userContactId?.contactNickName
    const contactEmail = note.userContactId?.contactEmail
    const contactPhone = note.userContactId?.contactPhone
    const contactOrg = note.userContactId?.contactOrgId?.contactOrganizationName
    const contactRole = note.userContactId?.contactOrgRole?.orgRoleName
    const contactTitle = note.userContactId?.contactOrgTitle?.orgTitleName

  
    return (
      noteText.length > 0 && (
        <div className="bg-gray-100 m-1 p-1 rounded">
          <div className="grid grid-cols-12 justify-start items-center m-1 p-1">
            <div className="col-span-2 flex justify-start items-center">
              <div
                onClick={() => toggleShowDetail(noteId)}
                className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
              >
                {showDetail[noteId] ? (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                )}
              </div>
              <div>
                <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ noteIndex +1}.</p>
              </div>
              <div className="">
                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                  {getTimeSince(updateDate)}
                </p>
                <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
              </div>
            </div>
            
            <div className="col-span-2 flex justify-start items-center">
                <div className="mx-1 px-1"><UserIcon className="h-6 w-6 text-orange-600" /></div>
                <div>
                    <p className='text-xs text-gray-800 mx-1 px-1 font-bold'>{contactName}</p>
                    {contactRole !== 'None' && contactTitle !== 'None' && (
                    <p className='text-[10px] text-gray-400 mx-1 px-1 italic'>{contactTitle} & {contactRole}</p>)}
                    <p className='text-[10px] text-gray-800 mx-1 px-1'>{contactOrg}</p>
                </div>
            </div>

            <div className="col-span-2 flex justify-start items-center">
                 
                 <div className="rounded-full flex justify-center items-center h-7 w-7 bg-white hover:bg-gray-200">
                    <img src={intentionTypePic} alt="" className="h-6 w-6" />
                 </div>
                 <div>
                     <p className="text-[10px] mx-1 px-1 text-gray-600">{intentionTypeName}</p>
                </div>

            </div>
  
            <div className="col-span-3 flex justify-start items-center">
              
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
              ></div>
            </div>
  
            <div className="col-span-3 flex justify-start items-center mx-2">
              <ClockIcon className="h-5 w-5 text-lime-600" />
              <p className="text-[9px] mx-1">First Created:</p>
              <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdDate)}</p>
           
              {/* <div className="rounded-full flex justify-end items-center h-6 w-6 hover:bg-white">
                <PencilSquareIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  // onClick={() => handleEditNote(userId, note)}
                  onClick={() => (userId ? handleEditNote(userId, note) : handleEditNote(note))}
                />
              </div>
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <TrashIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  onClick={() => handleDeleteNote(noteId)}
                />
              </div> */}
            </div>
          </div>
  
          {showDetail[noteId] && (
            <div className="m-1 p-1 rounded bg-gray-50">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1"
              ></div>
            </div>
          )}
        </div>
      )
    );
  }

const RenderGratitudeNotes = ({
    note,
    noteTextKey,           // e.g., "coachSharedUserNoteText", "coachAllUsersNoteText"
    updateDateKey,         // e.g., "coachSharedUserNoteUpdateDate", "coachAllUsersNoteUpdateDate"
    createdDateKey,        // e.g., "coachSharedUserNoteDate", "coachAllUsersNoteDate"
    showDetail,            // State variable controlling visibility of full note
    toggleShowDetail,      // Function to toggle visibility
    handleEditNote,        // Function to handle editing of the note
    handleDeleteNote,      // Function to handle deletion of the note
    noteIndex,
    userId,
    arr,
    createMarkup,
    currentCreationId,
    creations,
    gratitudeTypeInfo
  }) => {
  
    const noteText = note[noteTextKey];
    const updateDate = note[updateDateKey];
    const createdDate = note[createdDateKey];
    const noteId = note._id;

    const gratitudeTypeName = gratitudeTypeInfo?.gratitudeTypeName
    const gratitudeTypePic = gratitudeTypeInfo?.pic

    // console.log('gratitudeTypeInfo', gratitudeTypeInfo)
    // console.log('gratitudeTypeName', gratitudeTypeName)
    // console.log('gratitudeTypePic', gratitudeTypePic)
    // console.log('all gratitude note', note)
  
    return (
      noteText.length >= 0 && (
        <div className="bg-gray-100 m-1 p-1 rounded">
          <div className="grid grid-cols-12 justify-start items-center m-1 p-1 gap-2">
            <div className="col-span-2 flex justify-start items-center">
              <div
                onClick={() => toggleShowDetail(noteId)}
                className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
              >
                {showDetail[noteId] ? (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                )}
              </div>
              <div>
                <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ noteIndex +1}.</p>
              </div>
              <div className="">
                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                  {getTimeSince(updateDate)}
                </p>
                <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
              </div>
            </div>
            <div className="col-span-2 flex justify-start items-center">
                 
                 <div className="rounded-full flex justify-center items-center h-7 w-7 bg-white hover:bg-gray-200">
                    <img src={gratitudeTypePic} alt="" className="h-6 w-6" />
                 </div>
                 <div>
                     <p className="text-[10px] mx-1 px-1 text-gray-600">{gratitudeTypeName}</p>
                </div>

            </div>
            <div className="col-span-4 flex justify-start items-center">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
              ></div>
            </div>
  
            <div className="col-span-3 flex justify-start items-center mx-2">
              <ClockIcon className="h-5 w-5 text-lime-600" />
              <p className="text-[9px] mx-1">First Created:</p>
              <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdDate)}</p>
            </div>
  
            {/* <div className="col-span-1 flex justify-end items-center mx-2">
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <PencilSquareIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  // onClick={() => handleEditNote(userId, note)}
                  onClick={() => 
                    handleEditNote(note)}
                />
              </div>
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <TrashIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  onClick={() => handleDeleteNote(noteId)}
                //   onClick={() => handleDeleteNote(note._id)}
                />
              </div>
            </div> */}
          </div>
  
          {showDetail[noteId] && (
            <div className="m-1 p-1 rounded bg-gray-50">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1"
              ></div>
            </div>
          )}
        </div>
      )
    );
  }

const RenderContactGratitudeNotes = ({
    note,
    noteTextKey,           // e.g., "coachSharedUserNoteText", "coachAllUsersNoteText"
    updateDateKey,         // e.g., "coachSharedUserNoteUpdateDate", "coachAllUsersNoteUpdateDate"
    createdDateKey,        // e.g., "coachSharedUserNoteDate", "coachAllUsersNoteDate"
    showDetail,            // State variable controlling visibility of full note
    toggleShowDetail,      // Function to toggle visibility
    handleEditNote,        // Function to handle editing of the note
    handleDeleteNote,      // Function to handle deletion of the note
    noteIndex,
    userId,
    arr,
    createMarkup,
    gratitudeTypeInfo,
  }) => {

    // console.log('Contact note', note)
  
    const noteText = note[noteTextKey];
    const updateDate = note[updateDateKey];
    const createdDate = note[createdDateKey];
    const noteId = note._id;

    const gratitudeTypeName = gratitudeTypeInfo?.gratitudeTypeName
    const gratitudeTypePic = gratitudeTypeInfo?.pic
    // console.log('noteText', noteText)
    // console.log('gratitudeTypeName', gratitudeTypeName)

    // Contact Data
    const contactType = note.userContactId?.contactTypeId?.contactTypeName
    const contactName = note.userContactId?.contactName
    const contactNickName = note.userContactId?.contactNickName
    const contactEmail = note.userContactId?.contactEmail
    const contactPhone = note.userContactId?.contactPhone
    const contactOrg = note.userContactId?.contactOrgId?.contactOrganizationName
    const contactRole = note.userContactId?.contactOrgRole?.orgRoleName
    const contactTitle = note.userContactId?.contactOrgTitle?.orgTitleName

  
    return (
      noteText.length > 0 && (
        <div className="bg-gray-100 m-1 p-1 rounded">
          <div className="grid grid-cols-12 justify-start items-center m-1 p-1">
            <div className="col-span-2 flex justify-start items-center">
              <div
                onClick={() => toggleShowDetail(noteId)}
                className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
              >
                {showDetail[noteId] ? (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                )}
              </div>
              <div>
                <p className="text-2xl font-bold mx-1 px-1 text-gray-600">{ noteIndex +1}.</p>
              </div>
              <div className="">
                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                  {getTimeSince(updateDate)}
                </p>
                <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
              </div>
            </div>
            
            <div className="col-span-2 flex justify-start items-center">
                <div className="mx-1 px-1"><UserIcon className="h-6 w-6 text-orange-600" /></div>
                <div>
                    <p className='text-xs text-gray-800 mx-1 px-1 font-bold'>{contactName}</p>
                    {contactRole !== 'None' && contactTitle !== 'None' && (
                    <p className='text-[10px] text-gray-400 mx-1 px-1 italic'>{contactTitle} & {contactRole}</p>)}
                    <p className='text-[10px] text-gray-800 mx-1 px-1'>{contactOrg}</p>
                </div>
            </div>

            <div className="col-span-2 flex justify-start items-center">
                 
                 <div className="rounded-full flex justify-center items-center h-7 w-7 bg-white hover:bg-gray-200">
                    <img src={gratitudeTypePic} alt="" className="h-6 w-6" />
                 </div>
                 <div>
                     <p className="text-[10px] mx-1 px-1 text-gray-600">{gratitudeTypeName}</p>
                </div>

            </div>
  
            <div className="col-span-3 flex justify-start items-center">
              
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
              ></div>
            </div>
  
            <div className="col-span-3 flex justify-start items-center mx-2">
              <ClockIcon className="h-5 w-5 text-lime-600" />
              <p className="text-[9px] mx-1">First Created:</p>
              <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdDate)}</p>
           
              {/* <div className="rounded-full flex justify-end items-center h-6 w-6 hover:bg-white">
                <PencilSquareIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  // onClick={() => handleEditNote(userId, note)}
                  onClick={() => (userId ? handleEditNote(userId, note) : handleEditNote(note))}
                />
              </div>
              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                <TrashIcon
                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                  onClick={() => handleDeleteNote(noteId)}
                />
              </div> */}
            </div>
          </div>
  
          {showDetail[noteId] && (
            <div className="m-1 p-1 rounded bg-gray-50">
              <div
                dangerouslySetInnerHTML={createMarkup(noteText)}
                className="text-[11px] text-gray-700 m-1 p-1"
              ></div>
            </div>
          )}
        </div>
      )
    );
  }
  
const User = ({ username, defaultUserPic }) => {
    const { data: userPic, isFetching: isFetchingUserPic } =
      useGetUserPicQuery(username)
  
    return (
      <div className="flex justify-center items-center h-12 w-12 rounded-full border border-white bg-gradient-to-r from-orange-600 to-orange-400 shadow-lg shadow-black cursor-pointer">
        {/* Display user picture or loading indicator*/}
        {isFetchingUserPic ? (
          <div>Loading...</div>
        ) : userPic ? (
          <img src={userPic} alt="User" className="h-11 w-11  rounded-full" />
        ) : (
          <img src={defaultUserPic} alt="User" className="h-10 w-10 p-1" />
        )}
      </div>
    )
  }

  const NoteEditor = ({
    editorValue,
    handleEditorChange,
    editorKey,
    currentCreationNoteId,
    handleSaveCreation,
    handleCancelCreation,
  }) => {

    // First Created - 09 Oct 2024
    // Author - Atul Pandey

    return (
      <div className="bg-gray-50 p-1 m-1 rounded text-xs">
        <p className="m-1 p-1 font-bold">
          {currentCreationNoteId === null ? "Add a Creation Note" : "Edit a Creation Note"}
        </p>
        {/* Editor for Creation Notes */}
        <div className="flex flex-col">
          <EtContentEditorLight2
            value={editorValue}
            handleChange={handleEditorChange}
            placeholderText="Write your creation note here..."
            editorKey={editorKey}
          />
        </div>
        {/* Save and Cancel Buttons */}
        <div className="flex justify-start items-center m-2 p-2">
          <button
            type="button"
            onClick={handleSaveCreation}
            className="w-24 text-white rounded-full bg-lime-600 hover:bg-lime-700"
          >
            <p className="text-sm px-3 py-2">Save</p>
          </button>
          <button
            type="button"
            onClick={handleCancelCreation}
            className="w-24 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-2"
          >
            <p className="text-sm px-3 py-2">Cancel</p>
          </button>
        </div>
      </div>
    );
  };

  const EtCreationScorecardWeekly = ({ creationTypes, creations = [] }) => {

    // 0. Get the weeklyCreationData
    const weeklyCreationData = extractCreationData(creationTypes, creations)
    // console.log('Weekly CreationData', weeklyCreationData)

    // 1. Calculate the grid size based on the number of creation types
    const calculateGridSize = (numberOfCreationTypes) => {
      if (numberOfCreationTypes <= 1) return 1;
      if (numberOfCreationTypes <= 4) return 2;
      if (numberOfCreationTypes <= 9) return 3;
      if (numberOfCreationTypes <= 16) return 4;
      return 4; // Default to 4 if more than 16 creation types
    };
  
    const gridSize = calculateGridSize(creationTypes.length);
  
    // 2. Function to get the appropriate color class based on creation count
    const getColorForCreationCount = (count) => {
      return count > 0 ? "bg-etpx-5" : "bg-black"; // Light up the pixel if count > 0, else keep it black
    };

    const getTextColorForScore = (count) => {
        return count >0 ? 'text-white' : 'text-gray-900'; // Default color if none of the conditions match
      };

    const getTextColorForTitle = (count) => {
        return count >0 ? 'text-black' : 'text-gray-700'; // Default color if none of the conditions match
      };
  
    // 3. Generate square divs for each creation type
    const squares = creationTypes?.map((type, index) => {
      const creationData = weeklyCreationData?.find(data => data.creationTypeName === type?.creationTypeName); // Find matching creation data
      const pic = type?.pic; // Icon for the creation type
      const MyIcon = createSvgComponentFromBase64(pic); // 07 Jul 2024
      const creationTypeName = type?.creationTypeName;
      const creationCount = creationData ? creationData.creationCount : 0; // Get creation count or default to 0
      const colorClass = getColorForCreationCount(creationCount);
      const textColorClass = getTextColorForScore(creationCount)
      const textTitleColorClass = getTextColorForTitle(creationCount)
  
      return (
        <div key={index} className={`aspect-square rounded border m-[1px] border-black ${colorClass}`}>
          <div className="flex flex-col justify-between items-center h-full p-2">
           
            <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 md:h-8 md:w-8 ${textColorClass}`}/>
            
            <div className={`rounded-full h-6 w-6 border flex justify-center items-center ${creationCount >0 ? 'border-white' : 'border-none'}`}><p className={`${textColorClass} text-center font-bold`}>{creationCount}</p></div>
            <p className={`text-[9px]  ${textTitleColorClass} text-center`}>{creationTypeName}</p>
           
            
          </div>
        </div>
      );
    });
  
    // 4. Return the grid of squares
    return (
      <div className={`grid grid-cols-${gridSize} aspect-square rounded bg-gradient-to-r from-black to-black via-etpx-bg mx-auto p-[2px]`}>
        {squares}
      </div>
    );
  };
  
// Helper function to build the weeklyCreationData
const extractCreationData = (creationTypes, creations) => {
    // Create a map to store the count of creations per creation type
    const creationCountMap = {};
  
    // Populate the map with creation counts from the creations array
    creations?.forEach(creation => {
      const creationTypeId = creation.userCreationId?.userCreationType?._id;
      if (creationTypeId) {
        creationCountMap[creationTypeId] = (creationCountMap[creationTypeId] || 0) + 1;
      }
    });
  
    // Build the weeklyCreationData array
    const weeklyCreationData = creationTypes?.map(type => ({
      creationTypeName: type.creationTypeName,
      pic: type.pic, // Assuming pic holds the icon data for each creation type
      creationCount: creationCountMap[type._id] || 0 // If no creations, set to 0
    }));
  
    return weeklyCreationData;
  };

export default EtUserAccomplishmentWeeklyList