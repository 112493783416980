import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const capabilitiesSalesFunctionAreaTypesAdapter = createEntityAdapter({})

const initialState = capabilitiesSalesFunctionAreaTypesAdapter.getInitialState()

export const capabilitiesSalesFunctionAreaTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCapabilitiesSalesFunctionAreaTypes: builder.query({
            query: () => ({
                url: '/capabilitiesSalesFunctionAreaTypes/getCapabilitiesSalesFunctionAreaTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedCapabilitiesSalesFunctionAreaTypes = responseData.map(capabilitiesSalesFunctionAreaType => {
                    capabilitiesSalesFunctionAreaType.id = capabilitiesSalesFunctionAreaType._id
                    return capabilitiesSalesFunctionAreaType
                })
                return capabilitiesSalesFunctionAreaTypesAdapter.setAll(initialState, loadedCapabilitiesSalesFunctionAreaTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'CapabilitiesSalesFunctionAreaType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'CapabilitiesSalesFunctionAreaType', id}))
                    ]
                } else {
                    return [ { type: 'CapabilitiesSalesFunctionAreaType', id: 'LIST'}]
                }
            },
        }),
        createNewCapabilitiesSalesFunctionAreaType: builder.mutation({
            query: initialCapabilitiesSalesFunctionAreaType => ({
                url: '/capabilitiesSalesFunctionAreaTypes/createNewCapabilitiesSalesFunctionAreaType',
                method: 'POST',
                body: {...initialCapabilitiesSalesFunctionAreaType,}
            }),
            invalidatesTags: [
                { type: 'CapabilitiesSalesFunctionAreaType', id: "LIST" }
            ] 
        }),
        updateCapabilitiesSalesFunctionAreaType: builder.mutation({
            query: initialCapabilitiesSalesFunctionAreaType => ({
                url: '/capabilitiesSalesFunctionAreaTypes/updateCapabilitiesSalesFunctionAreaType',
                method: 'PATCH',
                body: {...initialCapabilitiesSalesFunctionAreaType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'CapabilitiesSalesFunctionAreaType', id: arg.id },
                { type: 'CapabilitiesSalesFunctionAreaType', id: 'LIST' }
            ],
        }),
        deleteCapabilitiesSalesFunctionAreaType: builder.mutation({
            query: ({id}) => ({
                url: '/capabilitiesSalesFunctionAreaTypes/deleteCapabilitiesSalesFunctionAreaType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'CapabilitiesSalesFunctionAreaType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetCapabilitiesSalesFunctionAreaTypesQuery,
    useCreateNewCapabilitiesSalesFunctionAreaTypeMutation,
    useUpdateCapabilitiesSalesFunctionAreaTypeMutation,
    useDeleteCapabilitiesSalesFunctionAreaTypeMutation,
} = capabilitiesSalesFunctionAreaTypesApiSlice

// returns the query result object
export const selectCapabilitiesSalesFunctionAreaTypeResult = capabilitiesSalesFunctionAreaTypesApiSlice.endpoints.getCapabilitiesSalesFunctionAreaTypes.select()

// create memoized selector
const selectCapabilitiesSalesFunctionAreaTypesData = createSelector(
    selectCapabilitiesSalesFunctionAreaTypeResult,
    capabilitiesSalesFunctionAreaTypeResult => capabilitiesSalesFunctionAreaTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCapabilitiesSalesFunctionAreaTypes,
    selectById: selectCapabilitiesSalesFunctionAreaTypeById,
    selectIds: selectCapabilitiesSalesFunctionAreaTypeIds,
} = capabilitiesSalesFunctionAreaTypesAdapter.getSelectors(state => selectCapabilitiesSalesFunctionAreaTypesData(state) ?? initialState)