import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import {
  PlusIcon,
  MinusIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline"
import { ClockIcon, BuildingOffice2Icon,ArrowPathIcon, CalendarDaysIcon, Squares2X2Icon, ArrowTrendingUpIcon, InboxArrowDownIcon, UserIcon, ArrowDownCircleIcon, ArrowUpCircleIcon  } from "@heroicons/react/24/solid"

import { useGetUserImprintLegacysQuery } from "../userImprintLegacysApiSlice"
import { useGetLegacyTypesQuery } from '@/features/legacyTypes/legacyTypesApiSlice'

import EtModalCreateUserImprintCreation from "@/components/common/modal/EtModalCreateUserImprintCreation"
import EtModalUpdateUserImprintCreation from "@/components/common/modal/EtModalUpdateUserImprintCreation"
import EtModalCreateUserImprintLegacyVenture from "@/components/common/modal/EtModalCreateUserImprintLegacyVenture"
import EtModalUpdateUserImprintLegacyVenture from "@/components/common/modal/EtModalUpdateUserImprintLegacyVenture"
// 02 Jan 2025
import EtModalCreateUserImprintLegacySuccessor from "@/components/common/modal/EtModalCreateUserImprintLegacySuccessor" 
import EtModalUpdateUserImprintLegacySuccessor from "@/components/common/modal/EtModalUpdateUserImprintLegacySuccessor"
import EtModalCreateUserImprintLegacyRecognitionsAcademic from "@/components/common/modal/EtModalCreateUserImprintLegacyRecognitionsAcademic" 
import EtModalUpdateUserImprintLegacyRecognitionsAcademic from "@/components/common/modal/EtModalUpdateUserImprintLegacyRecognitionsAcademic"
import EtModalCreateUserImprintLegacyRecognitionsIndustry from "@/components/common/modal/EtModalCreateUserImprintLegacyRecognitionsIndustry" 
import EtModalUpdateUserImprintLegacyRecognitionsIndustry from "@/components/common/modal/EtModalUpdateUserImprintLegacyRecognitionsIndustry"

// 03 Jan 2025
import EtModalCreateUserImprintLegacyRecognitionsDomain from "@/components/common/modal/EtModalCreateUserImprintLegacyRecognitionsDomain" 
import EtModalUpdateUserImprintLegacyRecognitionsDomain from "@/components/common/modal/EtModalUpdateUserImprintLegacyRecognitionsDomain"
import EtModalCreateUserImprintLegacyRecognitionsCommunity from "@/components/common/modal/EtModalCreateUserImprintLegacyRecognitionsCommunity" 
import EtModalUpdateUserImprintLegacyRecognitionsCommunity from "@/components/common/modal/EtModalUpdateUserImprintLegacyRecognitionsCommunity"
import EtModalCreateUserImprintLegacyRecognitionsNational from "@/components/common/modal/EtModalCreateUserImprintLegacyRecognitionsNational" 
import EtModalUpdateUserImprintLegacyRecognitionsNational from "@/components/common/modal/EtModalUpdateUserImprintLegacyRecognitionsNational"
import EtModalCreateUserImprintLegacyRecognitionsGlobal from "@/components/common/modal/EtModalCreateUserImprintLegacyRecognitionsGlobal" 
import EtModalUpdateUserImprintLegacyRecognitionsGlobal from "@/components/common/modal/EtModalUpdateUserImprintLegacyRecognitionsGlobal"


// 30 Dec 2024
import EtMlLifeLongIcon1SVGComp from '@/assets/img/icons/svgComp/EtMlLifeLongIcon1SVGComp'
import EtMlThisYrIcon1SVGComp from '@/assets/img/icons/svgComp/EtMlThisYrIcon1SVGComp'
import EtMlCreationsIcon1SVGComp from '@/assets/img/icons/svgComp/EtMlCreationsIcon1SVGComp'
import EtMlVenturesIcon1SVGComp from '@/assets/img/icons/svgComp/EtMlVenturesIcon1SVGComp'
import EtMlSuccessorsIcon1SVGComp from '@/assets/img/icons/svgComp/EtMlSuccessorsIcon1SVGComp'
import EtMlAcademicExellenceIcon1SVGComp from '@/assets/img/icons/svgComp/EtMlAcademicExellenceIcon1SVGComp'
import EtMlIndustryExellenceIcon1SVGComp from '@/assets/img/icons/svgComp/EtMlIndustryExellenceIcon1SVGComp'
import EtMlDomainExellenceIcon1SVGComp from '@/assets/img/icons/svgComp/EtMlDomainExellenceIcon1SVGComp'
import EtMlCommunityExellenceIcon1SVGComp from '@/assets/img/icons/svgComp/EtMlCommunityExellenceIcon1SVGComp'
import EtMlNationalExellenceIcon1SVGComp from '@/assets/img/icons/svgComp/EtMlNationalExellenceIcon1SVGComp'
import EtMlGlobalExellenceIcon1SVGComp from '@/assets/img/icons/svgComp/EtMlGlobalExellenceIcon1SVGComp'

// 02 Jan 2025
import EtMlAcademicOrgIcon1SVGComp from '@/assets/img/icons/svgComp/EtMlAcademicOrgIcon1SVGComp'
import EtMlIndustryOrgIcon1SVGComp from '@/assets/img/icons/svgComp/EtMlIndustryOrgIcon1SVGComp'

// 03 Jan 2025
import EtMlDomainOrgIcon1SVGComp from '@/assets/img/icons/svgComp/EtMlDomainOrgIcon1SVGComp'
import EtMlCommunityOrgIcon1SVGComp from '@/assets/img/icons/svgComp/EtMlCommunityOrgIcon1SVGComp'
import EtMlNationalOrgIcon1SVGComp from '@/assets/img/icons/svgComp/EtMlNationalOrgIcon1SVGComp'
import EtMlGlobalOrgIcon1SVGComp from '@/assets/img/icons/svgComp/EtMlGlobalOrgIcon1SVGComp'

import { createSvgComponentFromBase64 } from '@/utils/createSvgComponentFromBase64'

import {
    formatDateToMonthYear,
    formatDateToYear,
    formatDateTime,
    formatShortDate,
    calculateYearsAndMonths,
  } from "@/utils/formatDate"
  
  import { getTimeSince } from "@/utils/EtGetTimeSince"

const EtImprintLegacy = ({ userId, userInfo }) => {
    // First Created - 28 Dec 2024
    // Author - Atul Pandey
    // Updated - 29 Dec 2024 - added modals and codes for legacy components
    // Updated - 30 Dec 2024 - refined modal functions
    // Updated - 01 Jan 2025 - refined and completed Ventures component
    // Updated - 02 Jan 2025 - added Successors component
    // Updated - 02 Jan 2025 - added Recognitions Academic component
    // Updated - 02 Jan 2025 - added Recognitions Industry component
    // Updated - 03 Jan 2025 - added Recognitions Domain component
    // Updated - 03 Jan 2025 - added Recognitions Community component
    // Updated - 03 Jan 2025 - added Recognitions National component
    // Updated - 03 Jan 2025 - added Recognitions Global component


    // 1a1 - State for Legacy Creation Modal -- 29 Dec 2024
    const [isCreateLegacyCreationModalOpen, setIsCreateLegacyCreationModalOpen] = useState(false)
    const [isUpdateLegacyCreationModalOpen, setIsUpdateLegacyCreationModalOpen] = useState(false)
    const [createLegacyCreationTriggeringUrl, setCreateLegacyCreationTriggeringUrl] = useState(null)
    const [updateLegacyCreationTriggeringUrl, setUpdateLegacyCreationTriggeringUrl] = useState(null)
    
    // 1a2 - State for Legacy Venture Modal -- 29 Dec 2024
    const [isCreateLegacyVentureModalOpen, setIsCreateLegacyVentureModalOpen] = useState(false)
    const [isUpdateLegacyVentureModalOpen, setIsUpdateLegacyVentureModalOpen] = useState(false)
    const [createLegacyVentureTriggeringUrl, setCreateLegacyVentureTriggeringUrl] = useState(null)
    const [updateLegacyVentureTriggeringUrl, setUpdateLegacyVentureTriggeringUrl] = useState(null)
    
    // 1a3 - State for Legacy Successor Modal -- 29 Dec 2024
    const [isCreateLegacySuccessorModalOpen, setIsCreateLegacySuccessorModalOpen] = useState(false)
    const [isUpdateLegacySuccessorModalOpen, setIsUpdateLegacySuccessorModalOpen] = useState(false)
    const [createLegacySuccessorTriggeringUrl, setCreateLegacySuccessorTriggeringUrl] = useState(null)
    const [updateLegacySuccessorTriggeringUrl, setUpdateLegacySuccessorTriggeringUrl] = useState(null)
    
    // 1a4 - State for Legacy Academic Modal -- 29 Dec 2024
    const [isCreateLegacyAcademicModalOpen, setIsCreateLegacyAcademicModalOpen] = useState(false)
    const [isUpdateLegacyAcademicModalOpen, setIsUpdateLegacyAcademicModalOpen] = useState(false)
    const [createLegacyAcademicTriggeringUrl, setCreateLegacyAcademicTriggeringUrl] = useState(null)
    const [updateLegacyAcademicTriggeringUrl, setUpdateLegacyAcademicTriggeringUrl] = useState(null)

    // 1a5 - State for Legacy Industry Modal -- 29 Dec 2024
    const [isCreateLegacyIndustryModalOpen, setIsCreateLegacyIndustryModalOpen] = useState(false)
    const [isUpdateLegacyIndustryModalOpen, setIsUpdateLegacyIndustryModalOpen] = useState(false)
    const [createLegacyIndustryTriggeringUrl, setCreateLegacyIndustryTriggeringUrl] = useState(null)
    const [updateLegacyIndustryTriggeringUrl, setUpdateLegacyIndustryTriggeringUrl] = useState(null)

    // 1a6 - State for Legacy Domain Modal -- 29 Dec 2024
    const [isCreateLegacyDomainModalOpen, setIsCreateLegacyDomainModalOpen] = useState(false)
    const [isUpdateLegacyDomainModalOpen, setIsUpdateLegacyDomainModalOpen] = useState(false)
    const [createLegacyDomainTriggeringUrl, setCreateLegacyDomainTriggeringUrl] = useState(null)
    const [updateLegacyDomainTriggeringUrl, setUpdateLegacyDomainTriggeringUrl] = useState(null)

    // 1a7 - State for Legacy Community Modal -- 29 Dec 2024
    const [isCreateLegacyCommunityModalOpen, setIsCreateLegacyCommunityModalOpen] = useState(false)
    const [isUpdateLegacyCommunityModalOpen, setIsUpdateLegacyCommunityModalOpen] = useState(false)
    const [createLegacyCommunityTriggeringUrl, setCreateLegacyCommunityTriggeringUrl] = useState(null)
    const [updateLegacyCommunityTriggeringUrl, setUpdateLegacyCommunityTriggeringUrl] = useState(null)

    // 1a8 - State for Legacy National Modal -- 29 Dec 2024
    const [isCreateLegacyNationalModalOpen, setIsCreateLegacyNationalModalOpen] = useState(false)
    const [isUpdateLegacyNationalModalOpen, setIsUpdateLegacyNationalModalOpen] = useState(false)
    const [createLegacyNationalTriggeringUrl, setCreateLegacyNationalTriggeringUrl] = useState(null)
    const [updateLegacyNationalTriggeringUrl, setUpdateLegacyNationalTriggeringUrl] = useState(null)

    // 1a9 - State for Legacy Global Modal -- 29 Dec 2024
    const [isCreateLegacyGlobalModalOpen, setIsCreateLegacyGlobalModalOpen] = useState(false)
    const [isUpdateLegacyGlobalModalOpen, setIsUpdateLegacyGlobalModalOpen] = useState(false)
    const [createLegacyGlobalTriggeringUrl, setCreateLegacyGlobalTriggeringUrl] = useState(null)
    const [updateLegacyGlobalTriggeringUrl, setUpdateLegacyGlobalTriggeringUrl] = useState(null)
    
    
    // 1b - State for JSX Display - 29 Dec 2024
    const [activeTab, setActiveTab] = useState('Summary') 
    const [selectedLegacyType, setSelectedLegacyType] = useState('');

    const [ showCreationSection, setShowCreationSection ] = useState(false) // 30 Dec 2024
    const [ showCreationDetails, setShowCreationDetails ] = useState({}) // 30 Dec 2024
    const [ selectedCreationId, setSelectedCreationId ] = useState(null); // 30 Dec 2024
    const [ showCreationNoteDetails, setShowCreationNoteDetails ] = useState({}) // 01 Jan 2025

    const [ showVentureSection, setShowVentureSection ] = useState(false) // 30 Dec 2024
    const [ showVentureDetails, setShowVentureDetails ] = useState({}) // 30 Dec 2024
    const [ selectedVentureId, setSelectedVentureId ] = useState(null); // 30 Dec 2024
    const [ showVentureNoteDetails, setShowVentureNoteDetails ] = useState({}) // 02 Jan 2025
    
    const [ showSuccessorSection, setShowSuccessorSection ] = useState(false) // 02 Jan 2025
    const [ showSuccessorDetails, setShowSuccessorDetails ] = useState({}) // 02 Jan 2025
    const [ selectedSuccessorId, setSelectedSuccessorId ] = useState(null); // 02 Jan 2025
    const [ showSuccessorNoteDetails, setShowSuccessorNoteDetails ] = useState({}) // 02 Jan 2025
    
    const [ showAcademicSection, setShowAcademicSection ] = useState(false) // 02 Jan 2025
    const [ showAcademicDetails, setShowAcademicDetails ] = useState({}) // 02 Jan 2025
    const [ selectedAcademicId, setSelectedAcademicId ] = useState(null); // 02 Jan 2025
    const [ showAcademicNoteDetails, setShowAcademicNoteDetails ] = useState({}) // 02 Jan 2025
    
    const [ showIndustrySection, setShowIndustrySection ] = useState(false) // 02 Jan 2025
    const [ showIndustryDetails, setShowIndustryDetails ] = useState({}) // 02 Jan 2025
    const [ selectedIndustryId, setSelectedIndustryId ] = useState(null); // 02 Jan 2025
    const [ showIndustryNoteDetails, setShowIndustryNoteDetails ] = useState({}) // 02 Jan 2025
    
    const [ showDomainSection, setShowDomainSection ] = useState(false) // 03 Jan 2025
    const [ showDomainDetails, setShowDomainDetails ] = useState({}) // 03 Jan 2025
    const [ selectedDomainId, setSelectedDomainId ] = useState(null); // 03 Jan 2025
    const [ showDomainNoteDetails, setShowDomainNoteDetails ] = useState({}) // 03 Jan 2025
    
    const [ showCommunitySection, setShowCommunitySection ] = useState(false) // 03 Jan 2025
    const [ showCommunityDetails, setShowCommunityDetails ] = useState({}) // 03 Jan 2025
    const [ selectedCommunityId, setSelectedCommunityId ] = useState(null); // 03 Jan 2025
    const [ showCommunityNoteDetails, setShowCommunityNoteDetails ] = useState({}) // 03 Jan 2025
    
    const [ showNationalSection, setShowNationalSection ] = useState(false) // 03 Jan 2025
    const [ showNationalDetails, setShowNationalDetails ] = useState({}) // 03 Jan 2025
    const [ selectedNationalId, setSelectedNationalId ] = useState(null); // 03 Jan 2025
    const [ showNationalNoteDetails, setShowNationalNoteDetails ] = useState({}) // 03 Jan 2025
    
    const [ showGlobalSection, setShowGlobalSection ] = useState(false) // 03 Jan 2025
    const [ showGlobalDetails, setShowGlobalDetails ] = useState({}) // 03 Jan 2025
    const [ selectedGlobalId, setSelectedGlobalId ] = useState(null); // 03 Jan 2025
    const [ showGlobalNoteDetails, setShowGlobalNoteDetails ] = useState({}) // 03 Jan 2025
    
    // 2a - Extract needed data from the incoming props
    const userOrgId = userInfo[0]?.userOrgId._id || null
    // console.log('userOrgId', userOrgId)

    // 2b - Get UserImprintLegacy Data - 28 Dec 2024
    const { data: legacyTypesData} = useGetLegacyTypesQuery()
    const { data: userImprintLegacyData } = useGetUserImprintLegacysQuery()
    const userImprintLegacys = userImprintLegacyData?.ids
        ?.map((id) => userImprintLegacyData?.entities[id])
        ?.filter(
        (item) => item.userId?._id === userId && item.userOrgId._id === userOrgId
        )
      console.log("userImprintLegacys", userImprintLegacys)

    const extractEntitiesFromAdapter = (dataAdapter) => {
        if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
        return dataAdapter.ids.map((id) => dataAdapter.entities[id]);
    };

    const legacyTypes = extractEntitiesFromAdapter(legacyTypesData);

    // console.log('legacyTypes: ', legacyTypes)

    // 3a - Handle Create User Imprint Legacy - 30 Dec 2024
    const handleCreateUserImprintLegacy = (selectedLegacyType) => {
        const currentUrl = window.location.pathname;
    
        // Close all other modals first
        handleCloseAllModals();
    
        if (selectedLegacyType === 'Creations') {
            setIsCreateLegacyCreationModalOpen(true);
            setCreateLegacyCreationTriggeringUrl(currentUrl);
        }
        // Add cases for other legacy types in subsequent iterations
        if (selectedLegacyType === 'Ventures') {
            setIsCreateLegacyVentureModalOpen(true);
            setCreateLegacyVentureTriggeringUrl(currentUrl);
        }
        // 02 Jan 2025
        if (selectedLegacyType === 'Successors') {
            setIsCreateLegacySuccessorModalOpen(true);
            setCreateLegacySuccessorTriggeringUrl(currentUrl);
        }
        if (selectedLegacyType === 'Academic') {
            setIsCreateLegacyAcademicModalOpen(true);
            setCreateLegacyAcademicTriggeringUrl(currentUrl);
        }
        if (selectedLegacyType === 'Industry') {
            setIsCreateLegacyIndustryModalOpen(true);
            setCreateLegacyIndustryTriggeringUrl(currentUrl);
        }
        // 03 Jan 2025
        if (selectedLegacyType === 'Domain') {
            setIsCreateLegacyDomainModalOpen(true);
            setCreateLegacyDomainTriggeringUrl(currentUrl);
        }
        if (selectedLegacyType === 'Community') {
            setIsCreateLegacyCommunityModalOpen(true);
            setCreateLegacyCommunityTriggeringUrl(currentUrl);
        }
        if (selectedLegacyType === 'National') {
            setIsCreateLegacyNationalModalOpen(true);
            setCreateLegacyNationalTriggeringUrl(currentUrl);
        }
        if (selectedLegacyType === 'Global') {
            setIsCreateLegacyGlobalModalOpen(true);
            setCreateLegacyGlobalTriggeringUrl(currentUrl);
        }

    };
    
    const handleCloseAllModals = () => {
        setIsCreateLegacyCreationModalOpen(false);
        setIsUpdateLegacyCreationModalOpen(false);
        setCreateLegacyCreationTriggeringUrl(null);
        setUpdateLegacyCreationTriggeringUrl(null);
    
        setIsCreateLegacyVentureModalOpen(false);
        setIsUpdateLegacyVentureModalOpen(false);
        setCreateLegacyVentureTriggeringUrl(null);
        setUpdateLegacyVentureTriggeringUrl(null);
    
        setIsCreateLegacySuccessorModalOpen(false);
        setIsUpdateLegacySuccessorModalOpen(false);
        setCreateLegacySuccessorTriggeringUrl(null);
        setUpdateLegacySuccessorTriggeringUrl(null);
    
        setIsCreateLegacyAcademicModalOpen(false);
        setIsUpdateLegacyAcademicModalOpen(false);
        setCreateLegacyAcademicTriggeringUrl(null);
        setUpdateLegacyAcademicTriggeringUrl(null);
    
        setIsCreateLegacyIndustryModalOpen(false);
        setIsUpdateLegacyIndustryModalOpen(false);
        setCreateLegacyIndustryTriggeringUrl(null);
        setUpdateLegacyIndustryTriggeringUrl(null);
    
        setIsCreateLegacyDomainModalOpen(false);
        setIsUpdateLegacyDomainModalOpen(false);
        setCreateLegacyDomainTriggeringUrl(null);
        setUpdateLegacyDomainTriggeringUrl(null);
    
        setIsCreateLegacyCommunityModalOpen(false);
        setIsUpdateLegacyCommunityModalOpen(false);
        setCreateLegacyCommunityTriggeringUrl(null);
        setUpdateLegacyCommunityTriggeringUrl(null);
    
        setIsCreateLegacyNationalModalOpen(false);
        setIsUpdateLegacyNationalModalOpen(false);
        setCreateLegacyNationalTriggeringUrl(null);
        setUpdateLegacyNationalTriggeringUrl(null);
    
        setIsCreateLegacyGlobalModalOpen(false);
        setIsUpdateLegacyGlobalModalOpen(false);
        setCreateLegacyGlobalTriggeringUrl(null);
        setUpdateLegacyGlobalTriggeringUrl(null);
    };  

    const handleCloseCreateLegacyCreationModal = () => {
        setIsCreateLegacyCreationModalOpen(false)
        setCreateLegacyCreationTriggeringUrl(null)
        // window.location.reload();
    }
    
    // 3b - Handle Update User Imprint Creation
    const handleUpdateUserImprintCreation = (id) => {
        const currentUrl = window.location.pathname
        setSelectedCreationId(id)
        setIsUpdateLegacyCreationModalOpen(true)
        setUpdateLegacyCreationTriggeringUrl(currentUrl)
        
    }

    const handleCloseUpdateLegacyCreationModal = () => {
        setIsUpdateLegacyCreationModalOpen(false)
        setUpdateLegacyCreationTriggeringUrl(null)
        window.location.reload();
    }

    // 3c - Handle Create User Imprint Venture
    const handleCloseCreateLegacyVentureModal = () => {
        setIsCreateLegacyVentureModalOpen(false)
        setCreateLegacyVentureTriggeringUrl(null)
        window.location.reload();
    }

    // 3d - Handle Update User Imprint Venture
    const handleUpdateUserImprintVenture = (id) => {
        const currentUrl = window.location.pathname
        setSelectedVentureId(id)
        setIsUpdateLegacyVentureModalOpen(true)
        setUpdateLegacyVentureTriggeringUrl(currentUrl)       
    }

    const handleCloseUpdateLegacyVentureModal = () => {
        setIsUpdateLegacyVentureModalOpen(false)
        setUpdateLegacyVentureTriggeringUrl(null)
        window.location.reload();
    }

    // 3e - Handle Create User Imprint Successor -- 02 Jan 2025
    const handleCloseCreateLegacySuccessorModal = () => {
        setIsCreateLegacySuccessorModalOpen(false)
        setCreateLegacySuccessorTriggeringUrl(null)
        window.location.reload();
    }

    // 3f - Handle Update User Imprint Successor -- 02 Jan 2025
    const handleUpdateUserImprintSuccessor = (id) => {
        const currentUrl = window.location.pathname
        setSelectedSuccessorId(id)
        setIsUpdateLegacySuccessorModalOpen(true)
        setUpdateLegacySuccessorTriggeringUrl(currentUrl)       
    }

    const handleCloseUpdateLegacySuccessorModal = () => {
        setIsUpdateLegacySuccessorModalOpen(false)
        setUpdateLegacySuccessorTriggeringUrl(null)
        window.location.reload();
    }

    // 3g - Handle Create User Imprint Academic -- 02 Jan 2025
    const handleCloseCreateLegacyAcademicModal = () => {
        setIsCreateLegacyAcademicModalOpen(false)
        setCreateLegacyAcademicTriggeringUrl(null)
        window.location.reload();
    }

    // 3h - Handle Update User Imprint Academic -- 02 Jan 2025
    const handleUpdateUserImprintAcademic = (id) => {
        const currentUrl = window.location.pathname
        setSelectedAcademicId(id)
        setIsUpdateLegacyAcademicModalOpen(true)
        setUpdateLegacyAcademicTriggeringUrl(currentUrl)       
    }

    const handleCloseUpdateLegacyAcademicModal = () => {
        setIsUpdateLegacyAcademicModalOpen(false)
        setUpdateLegacyAcademicTriggeringUrl(null)
        window.location.reload();
    }

    // 3i - Handle Create User Imprint Industry -- 02 Jan 2025
    const handleCloseCreateLegacyIndustryModal = () => {
        setIsCreateLegacyIndustryModalOpen(false)
        setCreateLegacyIndustryTriggeringUrl(null)
        window.location.reload();
    }

    // 3j - Handle Update User Imprint Industry -- 02 Jan 2025
    const handleUpdateUserImprintIndustry = (id) => {
        const currentUrl = window.location.pathname
        setSelectedIndustryId(id)
        setIsUpdateLegacyIndustryModalOpen(true)
        setUpdateLegacyIndustryTriggeringUrl(currentUrl)       
    }

    const handleCloseUpdateLegacyIndustryModal = () => {
        setIsUpdateLegacyIndustryModalOpen(false)
        setUpdateLegacyIndustryTriggeringUrl(null)
        window.location.reload();
    }

    // 3k - Handle Create User Imprint Domain -- 03 Jan 2025
    const handleCloseCreateLegacyDomainModal = () => {
        setIsCreateLegacyDomainModalOpen(false)
        setCreateLegacyDomainTriggeringUrl(null)
        window.location.reload();
    }

    // 3l - Handle Update User Imprint Domain -- 03 Jan 2025
    const handleUpdateUserImprintDomain = (id) => {
        const currentUrl = window.location.pathname
        setSelectedDomainId(id)
        setIsUpdateLegacyDomainModalOpen(true)
        setUpdateLegacyDomainTriggeringUrl(currentUrl)       
    }

    const handleCloseUpdateLegacyDomainModal = () => {
        setIsUpdateLegacyDomainModalOpen(false)
        setUpdateLegacyDomainTriggeringUrl(null)
        window.location.reload();
    }

    // 3m - Handle Create User Imprint Community -- 03 Jan 2025
    const handleCloseCreateLegacyCommunityModal = () => {
        setIsCreateLegacyCommunityModalOpen(false)
        setCreateLegacyCommunityTriggeringUrl(null)
        window.location.reload();
    }

    // 3n - Handle Update User Imprint Community -- 03 Jan 2025
    const handleUpdateUserImprintCommunity = (id) => {
        const currentUrl = window.location.pathname
        setSelectedCommunityId(id)
        setIsUpdateLegacyCommunityModalOpen(true)
        setUpdateLegacyCommunityTriggeringUrl(currentUrl)       
    }

    const handleCloseUpdateLegacyCommunityModal = () => {
        setIsUpdateLegacyCommunityModalOpen(false)
        setUpdateLegacyCommunityTriggeringUrl(null)
        window.location.reload();
    }

    // 3o - Handle Create User Imprint National -- 03 Jan 2025
    const handleCloseCreateLegacyNationalModal = () => {
        setIsCreateLegacyNationalModalOpen(false)
        setCreateLegacyNationalTriggeringUrl(null)
        window.location.reload();
    }

    // 3p - Handle Update User Imprint National -- 03 Jan 2025
    const handleUpdateUserImprintNational = (id) => {
        const currentUrl = window.location.pathname
        setSelectedNationalId(id)
        setIsUpdateLegacyNationalModalOpen(true)
        setUpdateLegacyNationalTriggeringUrl(currentUrl)       
    }

    const handleCloseUpdateLegacyNationalModal = () => {
        setIsUpdateLegacyNationalModalOpen(false)
        setUpdateLegacyNationalTriggeringUrl(null)
        window.location.reload();
    }

    // 3q - Handle Create User Imprint Global -- 03 Jan 2025
    const handleCloseCreateLegacyGlobalModal = () => {
        setIsCreateLegacyGlobalModalOpen(false)
        setCreateLegacyGlobalTriggeringUrl(null)
        window.location.reload();
    }

    // 3r - Handle Update User Imprint Global -- 03 Jan 2025
    const handleUpdateUserImprintGlobal = (id) => {
        const currentUrl = window.location.pathname
        setSelectedGlobalId(id)
        setIsUpdateLegacyGlobalModalOpen(true)
        setUpdateLegacyGlobalTriggeringUrl(currentUrl)       
    }

    const handleCloseUpdateLegacyGlobalModal = () => {
        setIsUpdateLegacyGlobalModalOpen(false)
        setUpdateLegacyGlobalTriggeringUrl(null)
        window.location.reload();
    }

    // 4a - Toggle Tabs -- 29 Dec 2024
    const handleTabSwitch = (tab) => {
        setActiveTab(tab);
    };

    // 4b - Toggle Show function for creation details - 30 Dec 2024
    const handleToggleCreationDetails = (creationId) => {
        setShowCreationDetails((prev) => ({
            ...prev,
            [creationId]: !prev[creationId],
        }))
        }
    
    // 4c1 - Toggle Show function for venture details - 30 Dec 2024
    const handleToggleVentureDetails = (ventureId) => {
        setShowVentureDetails((prev) => ({
            ...prev,
            [ventureId]: !prev[ventureId],
        }))
    }

    // 4c2 - Toggle Show function for successor details - 02 Jan 2025
    const handleToggleSuccessorDetails = (successorId) => {
        setShowSuccessorDetails((prev) => ({
            ...prev,
            [successorId]: !prev[successorId],
        }))
    }

    // 4c3 - Toggle Show function for academic details - 02 Jan 2025
    const handleToggleAcademicDetails = (academicId) => {
        setShowAcademicDetails((prev) => ({
            ...prev,
            [academicId]: !prev[academicId],
        }))
    }

    // 4c4 - Toggle Show function for industry details - 02 Jan 2025
    const handleToggleIndustryDetails = (industryId) => {
        setShowIndustryDetails((prev) => ({
            ...prev,
            [industryId]: !prev[industryId],
        }))
    }

    // 4c5 - Toggle Show function for domain details - 03 Jan 2025
    const handleToggleDomainDetails = (domainId) => {
        setShowDomainDetails((prev) => ({
            ...prev,
            [domainId]: !prev[domainId],
        }))
    }

    // 4c6 - Toggle Show function for community details - 03 Jan 2025
    const handleToggleCommunityDetails = (communityId) => {
        setShowCommunityDetails((prev) => ({
            ...prev,
            [communityId]: !prev[communityId],
        }))
    }

    // 4c7 - Toggle Show function for national details - 03 Jan 2025
    const handleToggleNationalDetails = (nationalId) => {
        setShowNationalDetails((prev) => ({
            ...prev,
            [nationalId]: !prev[nationalId],
        }))
    }

    // 4c8 - Toggle Show function for global details - 03 Jan 2025
    const handleToggleGlobalDetails = (globalId) => {
        setShowGlobalDetails((prev) => ({
            ...prev,
            [globalId]: !prev[globalId],
        }))
    }

    // 4d1 - Toggle Show function for show creation note details -- 01 Jan 2025
    const handleToggleCreationNoteDetails = (noteId) => {
        setShowCreationNoteDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
    }
    
    // 4d2 - Toggle Show function for show venture note details -- 02 Jan 2025
    const handleToggleVentureNoteDetails = (noteId) => {
        setShowVentureNoteDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
    }
    
    // 4d3 - Toggle Show function for show successor note details -- 02 Jan 2025
    const handleToggleSuccessorNoteDetails = (noteId) => {
        setShowSuccessorNoteDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
    }

    // 4d4 - Toggle Show function for show academic note details -- 02 Jan 2025
    const handleToggleAcademicNoteDetails = (noteId) => {
        setShowAcademicNoteDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
    }

    // 4d5 - Toggle Show function for show industry note details -- 02 Jan 2025
    const handleToggleIndustryNoteDetails = (noteId) => {
        setShowIndustryNoteDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
    }

    // 4d6 - Toggle Show function for show domain note details -- 03 Jan 2025
    const handleToggleDomainNoteDetails = (noteId) => {
        setShowDomainNoteDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
    }

    // 4d7 - Toggle Show function for show community note details -- 03 Jan 2025
    const handleToggleCommunityNoteDetails = (noteId) => {
        setShowCommunityNoteDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
    }

    // 4d8 - Toggle Show function for show national note details -- 03 Jan 2025
    const handleToggleNationalNoteDetails = (noteId) => {
        setShowNationalNoteDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
    }

    // 4d9 - Toggle Show function for show global note details -- 03 Jan 2025
    const handleToggleGlobalNoteDetails = (noteId) => {
        setShowGlobalNoteDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
    }

    // 5 - Count items in an array created this year - 30 Dec 2024
    const countRecordsCreatedThisYear = (array) => {
        if (!Array.isArray(array)) return 0;
      
        const currentYear = new Date().getFullYear();
      
        return array.filter(item => {
          const createdYear = new Date(item.createdAt).getFullYear();
          return createdYear === currentYear;
        }).length;
      };
    
    // 6 - create Markup
    const createMarkup = (htmlContent) => {
        return { __html: htmlContent }
    }

  return (
    <div className="m-1 p-1 border rounded">
        {userImprintLegacys && userImprintLegacys?.map((legacy, legacyIndex) => {
            const creations = legacy?.userLegacyRecords[0]?.userCreations || []
            const ventures = legacy?.userLegacyRecords[0]?.userVentures || []
            const successors = legacy?.userLegacyRecords[0]?.userSuccessors || []
            const academics = legacy?.userLegacyRecords[0]?.userRecognitionsAcademics || []
            const industrys = legacy?.userLegacyRecords[0]?.userRecognitionsIndustrys || []
            const domains = legacy?.userLegacyRecords[0]?.userRecognitionsDomains || []
            const communitys = legacy?.userLegacyRecords[0]?.userRecognitionsCommunitys || []
            const nationals = legacy?.userLegacyRecords[0]?.userRecognitionsNationals || []
            const globals = legacy?.userLegacyRecords[0]?.userRecognitionsGlobals || []
            const creationsLength = creations?.length || 0
            const venturesLength = ventures?.length || 0
            const successorsLength = successors?.length || 0
            const academicsLength = academics?.length || 0
            const industrysLength = industrys?.length || 0
            const domainsLength = domains?.length || 0
            const communitysLength = communitys?.length || 0
            const nationalsLength = nationals?.length || 0
            const globalsLength = globals?.length || 0
            const legacyCounts = creationsLength + venturesLength + successorsLength + academicsLength + industrysLength + domainsLength + communitysLength + nationalsLength + globalsLength || 0
            
            const creationsThisYr = countRecordsCreatedThisYear(creations);
            const venturesThisYr = countRecordsCreatedThisYear(ventures);
            const successorsThisYr = countRecordsCreatedThisYear(successors);
            const academicsThisYr = countRecordsCreatedThisYear(academics);
            const industrysThisYr = countRecordsCreatedThisYear(industrys);
            const domainsThisYr = countRecordsCreatedThisYear(domains);
            const communitysThisYr = countRecordsCreatedThisYear(communitys);
            const nationalsThisYr = countRecordsCreatedThisYear(nationals);
            const globalsThisYr = countRecordsCreatedThisYear(globals);

            const legaciesForLife = [
                {legacyTypeName:"Creations", count: creationsLength},
                {legacyTypeName:"Ventures", count: venturesLength},
                {legacyTypeName:"Successors", count: successorsLength},
                {legacyTypeName:"Academic", count: academicsLength},
                {legacyTypeName:"Industry", count: industrysLength},
                {legacyTypeName:"Domain", count: domainsLength},
                {legacyTypeName:"Community", count: communitysLength},
                {legacyTypeName:"National", count: nationalsLength},
                {legacyTypeName:"Global", count: globalsLength}, 
            ]
            const legaciesThisYr = [
                {legacyTypeName:"Creations", countThisYr: creationsThisYr},
                {legacyTypeName:"Ventures", countThisYr: venturesThisYr},
                {legacyTypeName:"Successors", countThisYr: successorsThisYr},
                {legacyTypeName:"Academic", countThisYr: academicsThisYr},
                {legacyTypeName:"Industry", countThisYr: industrysThisYr},
                {legacyTypeName:"Domain", countThisYr: domainsThisYr},
                {legacyTypeName:"Community", countThisYr: communitysThisYr},
                {legacyTypeName:"National", countThisYr: nationalsThisYr},
                {legacyTypeName:"Global", countThisYr: globalsThisYr},
            ]

            return(
                <div key={legacy._id+legacyIndex}>
                    {/* Tab Navigation */}
                    <div className="grid grid-cols-10 justify-between items-center m-1 p-1">
                        <div className="col-span-3 flex space-x-4 mb-4 m-1 p-1">
                            {/* Tab 1: Summary */}
                            <button
                                className={`m-1 p-1 text-sm font-bold ${activeTab === 'Summary' ? 'border-b-2 border-orange-600' : 'text-gray-400'}`}
                                onClick={() => handleTabSwitch('Summary')}
                            >
                                Summary
                            </button>

                            {/* Tab 2: Details */}
                            <button
                                className={`m-1 p-1 text-sm font-bold ${activeTab === 'Details' ? 'border-b-2 border-orange-600' : 'text-gray-400'}`}
                                onClick={() => handleTabSwitch('Details')}
                            >
                                Details
                            </button>
                        </div>
                        <div className="col-span-3 flex justify-center items-center m-1 p-1">
                            <p className="font-bold text-xs m-1 p-1">Legacies:</p>
                            <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{legacyCounts}</p></div>
                        </div>
                        <div className="col-span-4 flex justify-end items-center m-1 p-1">
                            <p className="font-bold text-xs m-1 p-1">Add a New Legacy</p>
                            <select
                                className="text-xs border border-gray-300 rounded m-1 p-1 bg-white focus:ring focus:ring-orange-300 focus:outline-none"
                                onChange={(e) => setSelectedLegacyType(e.target.value)} // Update selectedLegacyType state
                                value={selectedLegacyType} // Bind to state
                            >
                                <option value="" disabled>Select Legacy Type</option>
                                {legacyTypes?.map((legacyType) => (
                                    <option key={legacyType?._id} value={legacyType?.name}>
                                        {legacyType?.legacyTypeName}
                                    </option>
                                ))}
                            </select>
                            <button
                                type="button"
                                onClick={() => handleCreateUserImprintLegacy(selectedLegacyType)} // Pass the selected legacy type
                                className="flex justify-center items-center h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600"
                            >
                                <PlusIcon className="h-5 w-5" />
                            </button>
                        </div>

                        <EtModalCreateUserImprintCreation
                            isOpen={isCreateLegacyCreationModalOpen}
                            userId={userId}
                            userOrgId={userOrgId}
                            triggeringUrl={createLegacyCreationTriggeringUrl}
                            onClose={handleCloseCreateLegacyCreationModal}
                        />  
                        <EtModalCreateUserImprintLegacyVenture
                            isOpen={isCreateLegacyVentureModalOpen}
                            userId={userId}
                            userOrgId={userOrgId}
                            triggeringUrl={createLegacyVentureTriggeringUrl}
                            onClose={handleCloseCreateLegacyVentureModal}
                        />  
                        <EtModalCreateUserImprintLegacySuccessor                             // 02 Jan 2025
                            isOpen={isCreateLegacySuccessorModalOpen}
                            userId={userId}
                            userOrgId={userOrgId}
                            triggeringUrl={createLegacySuccessorTriggeringUrl}
                            onClose={handleCloseCreateLegacySuccessorModal}
                        />  
                        <EtModalCreateUserImprintLegacyRecognitionsAcademic                  // 02 Jan 2025
                            isOpen={isCreateLegacyAcademicModalOpen}
                            userId={userId}
                            userOrgId={userOrgId}
                            triggeringUrl={createLegacyAcademicTriggeringUrl}
                            onClose={handleCloseCreateLegacyAcademicModal}
                        />  

                        <EtModalCreateUserImprintLegacyRecognitionsIndustry                  // 02 Jan 2025
                            isOpen={isCreateLegacyIndustryModalOpen}
                            userId={userId}
                            userOrgId={userOrgId}
                            triggeringUrl={createLegacyIndustryTriggeringUrl}
                            onClose={handleCloseCreateLegacyIndustryModal}
                        />  

                        <EtModalCreateUserImprintLegacyRecognitionsDomain                  // 03 Jan 2025
                            isOpen={isCreateLegacyDomainModalOpen}
                            userId={userId}
                            userOrgId={userOrgId}
                            triggeringUrl={createLegacyDomainTriggeringUrl}
                            onClose={handleCloseCreateLegacyDomainModal}
                        />  
                        <EtModalCreateUserImprintLegacyRecognitionsCommunity                  // 03 Jan 2025
                            isOpen={isCreateLegacyCommunityModalOpen}
                            userId={userId}
                            userOrgId={userOrgId}
                            triggeringUrl={createLegacyCommunityTriggeringUrl}
                            onClose={handleCloseCreateLegacyCommunityModal}
                        />  
                        <EtModalCreateUserImprintLegacyRecognitionsNational                  // 03 Jan 2025
                            isOpen={isCreateLegacyNationalModalOpen}
                            userId={userId}
                            userOrgId={userOrgId}
                            triggeringUrl={createLegacyNationalTriggeringUrl}
                            onClose={handleCloseCreateLegacyNationalModal}
                        />  
                        <EtModalCreateUserImprintLegacyRecognitionsGlobal                  // 03 Jan 2025
                            isOpen={isCreateLegacyGlobalModalOpen}
                            userId={userId}
                            userOrgId={userOrgId}
                            triggeringUrl={createLegacyGlobalTriggeringUrl}
                            onClose={handleCloseCreateLegacyGlobalModal}
                        />  
                    </div>

                    {/* TAB1 - Summary */}
                    {activeTab === 'Summary' && (
                        <div className="bg-gray-100 rounded m-1 p-1">

                            <div className='grid grid-cols-6 justify-between items-center w-5/6 mx-auto'>
                            <div className='col-span-3 m-1 rounded'>
                                <div className='flex justify-start items-center'>
                                <div><EtMlLifeLongIcon1SVGComp className='h-7 w-7 text-etpx-5'/></div>
                                <div><p className='p-1 m-1 text-gray-600 font-bold'>Legacy Thus Far</p></div>
                                </div>
                                <div><p className='m-1 p-1 text-[10px] lg:text-xs italic'>what legacy did I create thus far..</p></div>
                                {/* Left side - Put the CreationTypes grid based on all creation types with zero here */}
                                <EtLegacyScorecard legacyTypes={legacyTypes} legaciesForLife ={legaciesForLife} legaciesThisYr={[]} />
                            </div>
                            <div className='col-span-3 m-1 rounded'>
                                <div className='flex justify-start items-center'>
                                <div><EtMlThisYrIcon1SVGComp className='h-7 w-7 text-etpx-5'/></div>
                                <div><p className='p-1 m-1 text-gray-600 font-bold'>Legacy This Yr</p></div>
                                </div>
                                <div><p className='m-1 p-1 text-[10px] lg:text-xs italic'>what legacy did I create this year..</p></div>
                                {/* Left side - Put the CreationTypes grid based on all creation types with zero here */}
                                <EtLegacyScorecard legacyTypes={legacyTypes} legaciesForLife ={[]}legaciesThisYr={legaciesThisYr} />
                            </div>
                            </div>
                        </div>
                    )}

                    {/* TAB2 - Details */}
                    {activeTab === 'Details' && (
                        <div>
                            {/* 1. Creations */}
                            <div className="m-1 p-1 bg-gray-100 rounded">
                                <div className="grid grid-cols-10 justify-between items-center">
                                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                                        <div
                                            onClick={() => setShowCreationSection(prev => !prev)}
                                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                            >
                                            {showCreationSection ? (
                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                            ) : (
                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                            )}
                                        </div>
                                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtMlCreationsIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                                        <div><p className="font-bold mx-1 px-1">Creations</p></div>  
                                    </div>
                                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                                        <p className="font-bold text-xs m-1 p-1">Count:</p>
                                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{creationsLength}</p></div>
                                    </div>
                                </div>
                                {showCreationSection && creationsLength>0 && creations?.map((creation, creationIndex) => {
                                    
                                    const creationId = creation?.creationId?._id || null
                                    const creationName = creation?.creationId?.userCreationName || ""
                                    const creationDesc = creation?.creationId?.userCreationDesc || ""
                                    const creationNotes = creation?.creationId?.userCreationNotes || []
                                    const createdAt = creation?.createdAt || ""
                                    const updatedAt = creation?.updatedAt || ""

                                    return(
                                        <div key={creation._id+creationIndex} className="bg-gray-50 m-1 p-1 rounded">
                                            <div className="grid grid-cols-12 justify-start items-center">

                                                <div className="col-span-2 flex justify-start items-center">
                                                    <div
                                                        onClick={() => handleToggleCreationDetails(creation._id)}
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                    >
                                                        {showCreationDetails[creation._id] ? (
                                                            <MinusIcon className="h-3 w-3 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-3 w-3 text-orange-600" />
                                                        )}
                                                    </div>
                                                    <p className="text-lg mx-1 px-1 font-bold text-gray-400">{creationIndex+1}.</p>
                                                    <div className="flex justify-center items-center rounded-full h-7 w-7 bg-gray-50 hover:bg-white cursor-pointer" ><EtMlCreationsIcon1SVGComp  className='h-5 w-5 text-orange-600'/></div>
                                                    
                                                    <div className="m-1 p-1">
                                                        <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(updatedAt)}
                                                        </p>
                                                        <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                    </div>
                                                </div>
                                                <div className="col-span-6 flex justify-start items-center">
                                                    <div>
                                                        <p className="text-xs mx-1 px-1 font-bold">{creationName}</p>
                                                        <p dangerouslySetInnerHTML={createMarkup(creationDesc)} className="text-[9px] italic mx-1 px-1 leading-4 text-gray-400"></p>
                                                    </div>

                                                </div>
                                                <div className="col-span-4 flex justify-end items-center mx-2">
                                                    <ClockIcon className="h-5 w-5 text-lime-600" />
                                                    <p className="text-[9px] mx-1">First Created:</p>
                                                    <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdAt)}</p>
                                                    <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                                    <PencilSquareIcon
                                                        className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                                        onClick={() => handleUpdateUserImprintCreation(creationId)}
                                                    />
                                                    </div>
                                                </div>

                                            </div>
                                            {showCreationDetails[creation._id] && creationNotes.length>0 && creationNotes?.map((note, noteIndex) => {                            
                                                const noteText = note?.userCreationNoteText || ""
                                                const noteDate = note?.userCreationNoteDate || ""
                                                const noteUpdateDate = note?.userCreationNoteUpdateDate || ""
                
                                                return(
                                                <div key={note._id+noteIndex} className="">
                                                    <div className="grid grid-cols-6 justify-between items-center m-1 p-1 bg-gray-100 border rounded">
                                                    <div className="col-span-1 flex justify-start items-center m-1 p-1">
                                                        <div
                                                        onClick={() =>
                                                            handleToggleCreationNoteDetails(note?._id)
                                                        }
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                        >
                                                        {showCreationNoteDetails[note?._id] ? (
                                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                                        )}
                                                        </div>
                                                        <div>
                                                        <p className="font-bold mx-1 px-1 text-gray-600">
                                                            {noteIndex + 1}.
                                                        </p>
                                                        </div>
                                                        {/* <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                        <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
                                                        </div> */}
                                                        <div>
                                                        <div className="flex flex-col justify-center items-center mx-1 px-1">
                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(noteUpdateDate)}
                                                            </p>
                                                            <p className="text-[9px] text-gray-600 italic">
                                                            last updated
                                                            </p>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-3">
                                                        <div
                                                        dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                        ></div>
                                                    </div>
                                                    <div className="col-span-2 flex justify-center items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">
                                                        {formatDateTime(noteDate)}
                                                        </p>
                                                    </div>
                                                    </div>
                                                    {showCreationNoteDetails[note?._id] && (
                                                    <div className="bg-white m-1 p-1 rounded">
                
                                                        <div dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="m-1 p-1 text-xs leading-4">
                                                        </div>
                
                                                    </div>
                                                    )}
                
                
                                                </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                                <EtModalUpdateUserImprintCreation
                                    isOpen={isUpdateLegacyCreationModalOpen}
                                    id={selectedCreationId}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={updateLegacyCreationTriggeringUrl}
                                    onClose={handleCloseUpdateLegacyCreationModal}
                                /> 
                            </div>
                            {/* 2. Ventures */}
                            <div className="m-1 p-1 bg-gray-100 rounded">
                                <div className="grid grid-cols-10 justify-between items-center">
                                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                                        <div
                                            onClick={() => setShowVentureSection(prev => !prev)}
                                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                            >
                                            {showVentureSection ? (
                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                            ) : (
                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                            )}
                                        </div>
                                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtMlVenturesIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                                        <div><p className="font-bold mx-1 px-1">Ventures</p></div>  
                                    </div>
                                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                                        <p className="font-bold text-xs m-1 p-1">Count:</p>
                                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{venturesLength}</p></div>
                                    </div>
                                </div>
                                {showVentureSection && venturesLength>0 && ventures?.map((venture, ventureIndex) => {
                                    
                                    const ventureId = venture?.ventureId?._id || null
                                    const ventureName = venture?.ventureId?.userVentureName || ""
                                    const ventureDesc = venture?.ventureId?.userVentureDesc || ""
                                    const ventureNotes = venture?.ventureId?.userVentureNotes || []
                                    const createdAt = venture?.createdAt || ""
                                    const updatedAt = venture?.updatedAt || ""

                                    return(
                                        <div key={venture._id+ventureIndex} className="bg-gray-50 m-1 p-1 rounded">
                                            <div className="grid grid-cols-12 justify-start items-center">

                                                <div className="col-span-2 flex justify-start items-center">
                                                    <div
                                                        onClick={() => handleToggleVentureDetails(venture._id)}
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                    >
                                                        {showVentureDetails[venture._id] ? (
                                                            <MinusIcon className="h-3 w-3 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-3 w-3 text-orange-600" />
                                                        )}
                                                    </div>
                                                    <p className="text-lg mx-1 px-1 font-bold text-gray-400">{ventureIndex+1}.</p>
                                                    <div className="flex justify-center items-center rounded-full h-7 w-7 bg-gray-50 hover:bg-white cursor-pointer" ><EtMlVenturesIcon1SVGComp  className='h-5 w-5 text-orange-600'/></div>
                                                    
                                                    <div className="m-1 p-1">
                                                        <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(updatedAt)}
                                                        </p>
                                                        <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                    </div>
                                                </div>
                                                <div className="col-span-6 flex justify-start items-center">
                                                    <div>
                                                        <p className="text-xs mx-1 px-1 font-bold">{ventureName}</p>
                                                        <p dangerouslySetInnerHTML={createMarkup(ventureDesc)} className="text-[9px] italic mx-1 px-1 leading-4 text-gray-400"></p>
                                                    </div>

                                                </div>
                                                <div className="col-span-4 flex justify-end items-center mx-2">
                                                    <ClockIcon className="h-5 w-5 text-lime-600" />
                                                    <p className="text-[9px] mx-1">First Created:</p>
                                                    <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdAt)}</p>
                                                    <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                                    <PencilSquareIcon
                                                        className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                                        onClick={() => handleUpdateUserImprintVenture(ventureId)}
                                                    />
                                                    </div>
                                                </div>

                                            </div>
                                            {showVentureDetails[venture._id] && ventureNotes.length>0 && ventureNotes?.map((note, noteIndex) => {                            
                                                const noteText = note?.userVentureNoteText || ""
                                                const noteDate = note?.userVentureNoteDate || ""
                                                const noteUpdateDate = note?.userVentureNoteUpdateDate || ""
                
                                                return(
                                                <div key={note._id+noteIndex} className="">
                                                    <div className="grid grid-cols-6 justify-between items-center m-1 p-1 bg-gray-100 border rounded">
                                                    <div className="col-span-1 flex justify-start items-center m-1 p-1">
                                                        <div
                                                        onClick={() =>
                                                            handleToggleVentureNoteDetails(note?._id)
                                                        }
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                        >
                                                        {showVentureNoteDetails[note?._id] ? (
                                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                                        )}
                                                        </div>
                                                        <div>
                                                        <p className="font-bold mx-1 px-1 text-gray-600">
                                                            {noteIndex + 1}.
                                                        </p>
                                                        </div>
                                                        {/* <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                        <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
                                                        </div> */}
                                                        <div>
                                                        <div className="flex flex-col justify-center items-center mx-1 px-1">
                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(noteUpdateDate)}
                                                            </p>
                                                            <p className="text-[9px] text-gray-600 italic">
                                                            last updated
                                                            </p>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-3">
                                                        <div
                                                        dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                        ></div>
                                                    </div>
                                                    <div className="col-span-2 flex justify-center items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">
                                                        {formatDateTime(noteDate)}
                                                        </p>
                                                    </div>
                                                    </div>
                                                    {showVentureNoteDetails[note?._id] && (
                                                    <div className="bg-white m-1 p-1 rounded">
                
                                                        <div dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="m-1 p-1 text-xs leading-4">
                                                        </div>
                
                                                    </div>
                                                    )}
                
                
                                                </div>
                                                )
                                            })}

                                        </div>
                                    )
                                })}

                                <EtModalUpdateUserImprintLegacyVenture
                                    isOpen={isUpdateLegacyVentureModalOpen}
                                    id={selectedVentureId}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={updateLegacyVentureTriggeringUrl}
                                    onClose={handleCloseUpdateLegacyVentureModal}
                                /> 
                            </div>
                            {/* 3. Successors */}
                            <div className="m-1 p-1 bg-gray-100 rounded">
                                <div className="grid grid-cols-10 justify-between items-center">
                                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                                        <div
                                            onClick={() => setShowSuccessorSection(prev => !prev)}
                                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                            >
                                            {showSuccessorSection ? (
                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                            ) : (
                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                            )}
                                        </div>
                                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtMlSuccessorsIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                                        <div><p className="font-bold mx-1 px-1">Successors</p></div>  
                                    </div>
                                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                                        <p className="font-bold text-xs m-1 p-1">Count:</p>
                                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{successorsLength}</p></div>
                                    </div>
                                </div>
                                {showSuccessorSection && successorsLength>0 && successors?.map((successor, successorIndex) => {
                                    
                                    const successorId = successor?.successorId?._id || null
                                    const successorName = successor?.successorId?.userSuccessorName || ""
                                    const successorDesc = successor?.successorId?.userSuccessorDesc || ""
                                    const successorNotes = successor?.successorId?.userSuccessorNotes || []
                                    const createdAt = successor?.createdAt || ""
                                    const updatedAt = successor?.updatedAt || ""

                                    return(
                                        <div key={successor._id+successorIndex} className="bg-gray-50 m-1 p-1 rounded">
                                            <div className="grid grid-cols-12 justify-start items-center">

                                                <div className="col-span-2 flex justify-start items-center">
                                                    <div
                                                        onClick={() => handleToggleSuccessorDetails(successor._id)}
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                    >
                                                        {showSuccessorDetails[successor._id] ? (
                                                            <MinusIcon className="h-3 w-3 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-3 w-3 text-orange-600" />
                                                        )}
                                                    </div>
                                                    <p className="text-lg mx-1 px-1 font-bold text-gray-400">{successorIndex+1}.</p>
                                                    <div className="flex justify-center items-center rounded-full h-7 w-7 bg-gray-50 hover:bg-white cursor-pointer" ><EtMlSuccessorsIcon1SVGComp  className='h-5 w-5 text-orange-600'/></div>
                                                    
                                                    <div className="m-1 p-1">
                                                        <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(updatedAt)}
                                                        </p>
                                                        <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                    </div>
                                                </div>
                                                <div className="col-span-6 flex justify-start items-center">
                                                    <div>
                                                        <p className="text-xs mx-1 px-1 font-bold">{successorName}</p>
                                                        <p dangerouslySetInnerHTML={createMarkup(successorDesc)} className="text-[9px] italic mx-1 px-1 leading-4 text-gray-400"></p>
                                                    </div>

                                                </div>
                                                <div className="col-span-4 flex justify-end items-center mx-2">
                                                    <ClockIcon className="h-5 w-5 text-lime-600" />
                                                    <p className="text-[9px] mx-1">First Created:</p>
                                                    <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdAt)}</p>
                                                    <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                                    <PencilSquareIcon
                                                        className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                                        onClick={() => handleUpdateUserImprintSuccessor(successorId)}
                                                    />
                                                    </div>
                                                </div>

                                            </div>
                                            {showSuccessorDetails[successor._id] && successorNotes.length>0 && successorNotes?.map((note, noteIndex) => {                            
                                                const noteText = note?.userSuccessorNoteText || ""
                                                const noteDate = note?.userSuccessorNoteDate || ""
                                                const noteUpdateDate = note?.userSuccessorNoteUpdateDate || ""
                
                                                return(
                                                <div key={note._id+noteIndex} className="">
                                                    <div className="grid grid-cols-6 justify-between items-center m-1 p-1 bg-gray-100 border rounded">
                                                    <div className="col-span-1 flex justify-start items-center m-1 p-1">
                                                        <div
                                                        onClick={() =>
                                                            handleToggleSuccessorNoteDetails(note?._id)
                                                        }
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                        >
                                                        {showSuccessorNoteDetails[note?._id] ? (
                                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                                        )}
                                                        </div>
                                                        <div>
                                                        <p className="font-bold mx-1 px-1 text-gray-600">
                                                            {noteIndex + 1}.
                                                        </p>
                                                        </div>
                                                        {/* <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                        <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
                                                        </div> */}
                                                        <div>
                                                        <div className="flex flex-col justify-center items-center mx-1 px-1">
                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(noteUpdateDate)}
                                                            </p>
                                                            <p className="text-[9px] text-gray-600 italic">
                                                            last updated
                                                            </p>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-3">
                                                        <div
                                                        dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                        ></div>
                                                    </div>
                                                    <div className="col-span-2 flex justify-center items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">
                                                        {formatDateTime(noteDate)}
                                                        </p>
                                                    </div>
                                                    </div>
                                                    {showSuccessorNoteDetails[note?._id] && (
                                                    <div className="bg-white m-1 p-1 rounded">
                
                                                        <div dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="m-1 p-1 text-xs leading-4">
                                                        </div>
                
                                                    </div>
                                                    )}
                                                </div>
                                                )
                                            })}

                                        </div>
                                    )
                                })}

                                <EtModalUpdateUserImprintLegacySuccessor
                                    isOpen={isUpdateLegacySuccessorModalOpen}
                                    id={selectedSuccessorId}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={updateLegacySuccessorTriggeringUrl}
                                    onClose={handleCloseUpdateLegacySuccessorModal}
                                /> 
                            </div>
                            {/* 4. Academics */}
                            <div className="m-1 p-1 bg-gray-100 rounded">
                                <div className="grid grid-cols-10 justify-between items-center">
                                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                                        <div
                                            onClick={() => setShowAcademicSection(prev => !prev)}
                                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                            >
                                            {showAcademicSection ? (
                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                            ) : (
                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                            )}
                                        </div>
                                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtMlAcademicExellenceIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                                        <div><p className="font-bold mx-1 px-1">Academics</p></div>  
                                    </div>
                                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                                        <p className="font-bold text-xs m-1 p-1">Count:</p>
                                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{academicsLength}</p></div>
                                    </div>
                                </div>
                                {showAcademicSection && academicsLength>0 && academics?.map((academic, academicIndex) => {
                                    
                                    const academicId = academic?.recognitionsAcademicId?._id || null
                                    const recognitionName = academic?.recognitionsAcademicId?.recognitionName || ""
                                    const recognitionDesc = academic?.recognitionsAcademicId?.recognitionDesc || ""
                                    const recognitionDate = academic?.recognitionsAcademicId?.recognitionDate || ""
                                    const recognitionOrgName = academic?.recognitionsAcademicId?.recognitionOrgName || ""
                                    const academicNotes = academic?.recognitionsAcademicId?.recognitionNotes || []
                                    const createdAt = academic?.createdAt || ""
                                    const updatedAt = academic?.updatedAt || ""

                                    return(
                                        <div key={academic._id+academicIndex} className="bg-gray-50 m-1 p-1 rounded">
                                            <div className="grid grid-cols-12 justify-start items-center">

                                                <div className="col-span-2 flex justify-start items-center">
                                                    <div
                                                        onClick={() => handleToggleAcademicDetails(academic._id)}
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                    >
                                                        {showAcademicDetails[academic._id] ? (
                                                            <MinusIcon className="h-3 w-3 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-3 w-3 text-orange-600" />
                                                        )}
                                                    </div>
                                                    <p className="text-lg mx-1 px-1 font-bold text-gray-400">{academicIndex+1}.</p>
                                                    <div className="flex justify-center items-center rounded-full h-7 w-7 bg-gray-50 hover:bg-white cursor-pointer" ><EtMlAcademicExellenceIcon1SVGComp  className='h-5 w-5 text-orange-600'/></div>
                                                    
                                                    <div className="m-1 p-1">
                                                        <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(updatedAt)}
                                                        </p>
                                                        <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                    </div>
                                                </div>
                                                <div className="col-span-3 flex justify-start items-center">
                                                    <div>
                                                        <p className="text-xs mx-1 px-1 font-bold">{recognitionName}</p>
                                                        <p dangerouslySetInnerHTML={createMarkup(recognitionDesc)} className="text-[9px] italic mx-1 px-1 leading-4 text-gray-400"></p>
                                                    </div>
                                                </div>
                                                <div className="col-span-3 flex justify-start items-center">
                                                    <EtMlAcademicOrgIcon1SVGComp className="h-6 w-6 text-orange-600"/>
                                                    <div>
                                                        <p className="text-xs mx-1 px-1 font-bold">{recognitionOrgName}</p>
                                                        <p className="text-[9px] italic mx-1 px-1 text-gray-400">{formatDateToMonthYear(recognitionDate)}</p>
                                                    </div>
                                                </div>

                                                <div className="col-span-4 flex justify-end items-center mx-2">
                                                    <ClockIcon className="h-5 w-5 text-lime-600" />
                                                    <p className="text-[9px] mx-1">First Created:</p>
                                                    <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdAt)}</p>
                                                    <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                                    <PencilSquareIcon
                                                        className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                                        onClick={() => handleUpdateUserImprintAcademic(academicId)}
                                                    />
                                                    </div>
                                                </div>

                                            </div>
                                            {showAcademicDetails[academic._id] && academicNotes.length>0 && academicNotes?.map((note, noteIndex) => {                            
                                                const noteText = note?.recognitionNoteText || ""
                                                const noteDate = note?.recognitionNoteDate || ""
                                                const noteUpdateDate = note?.recognitionNoteUpdateDate || ""
                
                                                return(
                                                <div key={note._id+noteIndex} className="">
                                                    <div className="grid grid-cols-6 justify-between items-center m-1 p-1 bg-gray-100 border rounded">
                                                    <div className="col-span-1 flex justify-start items-center m-1 p-1">
                                                        <div
                                                        onClick={() =>
                                                            handleToggleAcademicNoteDetails(note?._id)
                                                        }
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                        >
                                                        {showAcademicNoteDetails[note?._id] ? (
                                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                                        )}
                                                        </div>
                                                        <div>
                                                        <p className="font-bold mx-1 px-1 text-gray-600">
                                                            {noteIndex + 1}.
                                                        </p>
                                                        </div>
                                                        {/* <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                        <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
                                                        </div> */}
                                                        <div>
                                                        <div className="flex flex-col justify-center items-center mx-1 px-1">
                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(noteUpdateDate)}
                                                            </p>
                                                            <p className="text-[9px] text-gray-600 italic">
                                                            last updated
                                                            </p>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-3">
                                                        <div
                                                        dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                        ></div>
                                                    </div>
                                                    <div className="col-span-2 flex justify-center items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">
                                                        {formatDateTime(noteDate)}
                                                        </p>
                                                    </div>
                                                    </div>
                                                    {showAcademicNoteDetails[note?._id] && (
                                                    <div className="bg-white m-1 p-1 rounded">
                
                                                        <div dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="m-1 p-1 text-xs leading-4">
                                                        </div>
                
                                                    </div>
                                                    )}
                                                </div>
                                                )
                                            })}

                                        </div>
                                    )
                                })}

                                <EtModalUpdateUserImprintLegacyRecognitionsAcademic
                                    isOpen={isUpdateLegacyAcademicModalOpen}
                                    id={selectedAcademicId}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={updateLegacyAcademicTriggeringUrl}
                                    onClose={handleCloseUpdateLegacyAcademicModal}
                                /> 
                            </div>
                            {/* 5. Industries */}
                            <div className="m-1 p-1 bg-gray-100 rounded">
                                <div className="grid grid-cols-10 justify-between items-center">
                                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                                        <div
                                            onClick={() => setShowIndustrySection(prev => !prev)}
                                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                            >
                                            {showIndustrySection ? (
                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                            ) : (
                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                            )}
                                        </div>
                                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtMlIndustryExellenceIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                                        <div><p className="font-bold mx-1 px-1">Industries</p></div>  
                                    </div>
                                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                                        <p className="font-bold text-xs m-1 p-1">Count:</p>
                                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{industrysLength}</p></div>
                                    </div>
                                </div>
                                {showIndustrySection && industrysLength>0 && industrys?.map((industry, industryIndex) => {
                                    
                                    const industryId = industry?.recognitionsIndustryId?._id || null
                                    const recognitionName = industry?.recognitionsIndustryId?.recognitionName || ""
                                    const recognitionDesc = industry?.recognitionsIndustryId?.recognitionDesc || ""
                                    const recognitionDate = industry?.recognitionsIndustryId?.recognitionDate || null
                                    const recognitionOrgName = industry?.recognitionsIndustryId?.recognitionOrgName || ""
                                    const industryNotes = industry?.recognitionsIndustryId?.recognitionNotes || []
                                    const createdAt = industry?.createdAt || null
                                    const updatedAt = industry?.updatedAt || null

                                    return(
                                        <div key={industry._id+industryIndex} className="bg-gray-50 m-1 p-1 rounded">
                                            <div className="grid grid-cols-12 justify-start items-center">

                                                <div className="col-span-2 flex justify-start items-center">
                                                    <div
                                                        onClick={() => handleToggleIndustryDetails(industry._id)}
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                    >
                                                        {showIndustryDetails[industry._id] ? (
                                                            <MinusIcon className="h-3 w-3 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-3 w-3 text-orange-600" />
                                                        )}
                                                    </div>
                                                    <p className="text-lg mx-1 px-1 font-bold text-gray-400">{industryIndex+1}.</p>
                                                    <div className="flex justify-center items-center rounded-full h-7 w-7 bg-gray-50 hover:bg-white cursor-pointer" ><EtMlIndustryExellenceIcon1SVGComp  className='h-5 w-5 text-orange-600'/></div>
                                                    
                                                    <div className="m-1 p-1">
                                                        <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(updatedAt)}
                                                        </p>
                                                        <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                    </div>
                                                </div>
                                                <div className="col-span-3 flex justify-start items-center">
                                                    <div>
                                                        <p className="text-xs mx-1 px-1 font-bold">{recognitionName}</p>
                                                        <p dangerouslySetInnerHTML={createMarkup(recognitionDesc)} className="text-[9px] italic mx-1 px-1 leading-4 text-gray-400"></p>
                                                    </div>
                                                </div>
                                                <div className="col-span-3 flex justify-start items-center">
                                                    <EtMlIndustryOrgIcon1SVGComp className="h-6 w-6 text-orange-600"/>
                                                    <div>
                                                        <p className="text-xs mx-1 px-1 font-bold">{recognitionOrgName}</p>
                                                        <p className="text-[9px] italic mx-1 px-1 text-gray-400">{formatDateToMonthYear(recognitionDate)}</p>
                                                    </div>
                                                </div>

                                                <div className="col-span-4 flex justify-end items-center mx-2">
                                                    <ClockIcon className="h-5 w-5 text-lime-600" />
                                                    <p className="text-[9px] mx-1">First Created:</p>
                                                    <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdAt)}</p>
                                                    <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                                    <PencilSquareIcon
                                                        className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                                        onClick={() => handleUpdateUserImprintIndustry(industryId)}
                                                    />
                                                    </div>
                                                </div>

                                            </div>
                                            {showIndustryDetails[industry._id] && industryNotes.length>0 && industryNotes?.map((note, noteIndex) => {                            
                                                const noteText = note?.recognitionNoteText || ""
                                                const noteDate = note?.recognitionNoteDate || ""
                                                const noteUpdateDate = note?.recognitionNoteUpdateDate || ""
                
                                                return(
                                                <div key={note._id+noteIndex} className="">
                                                    <div className="grid grid-cols-6 justify-between items-center m-1 p-1 bg-gray-100 border rounded">
                                                    <div className="col-span-1 flex justify-start items-center m-1 p-1">
                                                        <div
                                                        onClick={() =>
                                                            handleToggleIndustryNoteDetails(note?._id)
                                                        }
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                        >
                                                        {showIndustryNoteDetails[note?._id] ? (
                                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                                        )}
                                                        </div>
                                                        <div>
                                                        <p className="font-bold mx-1 px-1 text-gray-600">
                                                            {noteIndex + 1}.
                                                        </p>
                                                        </div>
                                                        {/* <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                        <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
                                                        </div> */}
                                                        <div>
                                                        <div className="flex flex-col justify-center items-center mx-1 px-1">
                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(noteUpdateDate)}
                                                            </p>
                                                            <p className="text-[9px] text-gray-600 italic">
                                                            last updated
                                                            </p>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-3">
                                                        <div
                                                        dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                        ></div>
                                                    </div>
                                                    <div className="col-span-2 flex justify-center items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">
                                                        {formatDateTime(noteDate)}
                                                        </p>
                                                    </div>
                                                    </div>
                                                    {showIndustryNoteDetails[note?._id] && (
                                                    <div className="bg-white m-1 p-1 rounded">
                
                                                        <div dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="m-1 p-1 text-xs leading-4">
                                                        </div>
                
                                                    </div>
                                                    )}
                                                </div>
                                                )
                                            })}

                                        </div>
                                    )
                                })}

                                <EtModalUpdateUserImprintLegacyRecognitionsIndustry
                                    isOpen={isUpdateLegacyIndustryModalOpen}
                                    id={selectedIndustryId}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={updateLegacyIndustryTriggeringUrl}
                                    onClose={handleCloseUpdateLegacyIndustryModal}
                                /> 
                            </div>
                            {/* 6. Domains */}
                            <div className="m-1 p-1 bg-gray-100 rounded">
                                <div className="grid grid-cols-10 justify-between items-center">
                                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                                        <div
                                            onClick={() => setShowDomainSection(prev => !prev)}
                                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                            >
                                            {showDomainSection ? (
                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                            ) : (
                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                            )}
                                        </div>
                                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtMlDomainExellenceIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                                        <div><p className="font-bold mx-1 px-1">Domains</p></div>  
                                    </div>
                                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                                        <p className="font-bold text-xs m-1 p-1">Count:</p>
                                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{domainsLength}</p></div>
                                    </div>
                                </div>
                                {showDomainSection && domainsLength>0 && domains?.map((domain, domainIndex) => {
                                    
                                    const domainId = domain?.recognitionsDomainId?._id || null
                                    const recognitionName = domain?.recognitionsDomainId?.recognitionName || ""
                                    const recognitionDesc = domain?.recognitionsDomainId?.recognitionDesc || ""
                                    const recognitionDate = domain?.recognitionsDomainId?.recognitionDate || null
                                    const recognitionOrgName = domain?.recognitionsDomainId?.recognitionOrgName || ""
                                    const domainNotes = domain?.recognitionsDomainId?.recognitionNotes || []
                                    const createdAt = domain?.createdAt || null
                                    const updatedAt = domain?.updatedAt || null

                                    return(
                                        <div key={domain._id+domainIndex} className="bg-gray-50 m-1 p-1 rounded">
                                            <div className="grid grid-cols-12 justify-start items-center">

                                                <div className="col-span-2 flex justify-start items-center">
                                                    <div
                                                        onClick={() => handleToggleDomainDetails(domain._id)}
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                    >
                                                        {showDomainDetails[domain._id] ? (
                                                            <MinusIcon className="h-3 w-3 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-3 w-3 text-orange-600" />
                                                        )}
                                                    </div>
                                                    <p className="text-lg mx-1 px-1 font-bold text-gray-400">{domainIndex+1}.</p>
                                                    <div className="flex justify-center items-center rounded-full h-7 w-7 bg-gray-50 hover:bg-white cursor-pointer" ><EtMlDomainExellenceIcon1SVGComp  className='h-5 w-5 text-orange-600'/></div>
                                                    
                                                    <div className="m-1 p-1">
                                                        <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(updatedAt)}
                                                        </p>
                                                        <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                    </div>
                                                </div>
                                                <div className="col-span-3 flex justify-start items-center">
                                                    <div>
                                                        <p className="text-xs mx-1 px-1 font-bold">{recognitionName}</p>
                                                        <p dangerouslySetInnerHTML={createMarkup(recognitionDesc)} className="text-[9px] italic mx-1 px-1 leading-4 text-gray-400"></p>
                                                    </div>
                                                </div>
                                                <div className="col-span-3 flex justify-start items-center">
                                                    <EtMlDomainOrgIcon1SVGComp className="h-6 w-6 text-orange-600"/>
                                                    <div>
                                                        <p className="text-xs mx-1 px-1 font-bold">{recognitionOrgName}</p>
                                                        <p className="text-[9px] italic mx-1 px-1 text-gray-400">{formatDateToMonthYear(recognitionDate)}</p>
                                                    </div>
                                                </div>

                                                <div className="col-span-4 flex justify-end items-center mx-2">
                                                    <ClockIcon className="h-5 w-5 text-lime-600" />
                                                    <p className="text-[9px] mx-1">First Created:</p>
                                                    <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdAt)}</p>
                                                    <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                                    <PencilSquareIcon
                                                        className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                                        onClick={() => handleUpdateUserImprintDomain(domainId)}
                                                    />
                                                    </div>
                                                </div>

                                            </div>
                                            {showDomainDetails[domain._id] && domainNotes.length>0 && domainNotes?.map((note, noteIndex) => {                            
                                                const noteText = note?.recognitionNoteText || ""
                                                const noteDate = note?.recognitionNoteDate || ""
                                                const noteUpdateDate = note?.recognitionNoteUpdateDate || ""
                
                                                return(
                                                <div key={note._id+noteIndex} className="">
                                                    <div className="grid grid-cols-6 justify-between items-center m-1 p-1 bg-gray-100 border rounded">
                                                    <div className="col-span-1 flex justify-start items-center m-1 p-1">
                                                        <div
                                                        onClick={() =>
                                                            handleToggleDomainNoteDetails(note?._id)
                                                        }
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                        >
                                                        {showDomainNoteDetails[note?._id] ? (
                                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                                        )}
                                                        </div>
                                                        <div>
                                                        <p className="font-bold mx-1 px-1 text-gray-600">
                                                            {noteIndex + 1}.
                                                        </p>
                                                        </div>
                                                        {/* <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                        <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
                                                        </div> */}
                                                        <div>
                                                        <div className="flex flex-col justify-center items-center mx-1 px-1">
                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(noteUpdateDate)}
                                                            </p>
                                                            <p className="text-[9px] text-gray-600 italic">
                                                            last updated
                                                            </p>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-3">
                                                        <div
                                                        dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                        ></div>
                                                    </div>
                                                    <div className="col-span-2 flex justify-center items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">
                                                        {formatDateTime(noteDate)}
                                                        </p>
                                                    </div>
                                                    </div>
                                                    {showDomainNoteDetails[note?._id] && (
                                                    <div className="bg-white m-1 p-1 rounded">
                
                                                        <div dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="m-1 p-1 text-xs leading-4">
                                                        </div>
                
                                                    </div>
                                                    )}
                                                </div>
                                                )
                                            })}

                                        </div>
                                    )
                                })}

                                <EtModalUpdateUserImprintLegacyRecognitionsDomain
                                    isOpen={isUpdateLegacyDomainModalOpen}
                                    id={selectedDomainId}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={updateLegacyDomainTriggeringUrl}
                                    onClose={handleCloseUpdateLegacyDomainModal}
                                /> 
                            </div>
                            {/* 7. Communities */}
                            <div className="m-1 p-1 bg-gray-100 rounded">
                                <div className="grid grid-cols-10 justify-between items-center">
                                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                                        <div
                                            onClick={() => setShowCommunitySection(prev => !prev)}
                                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                            >
                                            {showCommunitySection ? (
                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                            ) : (
                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                            )}
                                        </div>
                                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtMlCommunityExellenceIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                                        <div><p className="font-bold mx-1 px-1">Communities</p></div>  
                                    </div>
                                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                                        <p className="font-bold text-xs m-1 p-1">Count:</p>
                                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{communitysLength}</p></div>
                                    </div>
                                </div>
                                {showCommunitySection && communitysLength>0 && communitys?.map((community, communityIndex) => {
                                    
                                    const communityId = community?.recognitionsCommunityId?._id || null
                                    const recognitionName = community?.recognitionsCommunityId?.recognitionName || ""
                                    const recognitionDesc = community?.recognitionsCommunityId?.recognitionDesc || ""
                                    const recognitionDate = community?.recognitionsCommunityId?.recognitionDate || null
                                    const recognitionOrgName = community?.recognitionsCommunityId?.recognitionOrgName || ""
                                    const communityNotes = community?.recognitionsCommunityId?.recognitionNotes || []
                                    const createdAt = community?.createdAt || null
                                    const updatedAt = community?.updatedAt || null

                                    return(
                                        <div key={community._id+communityIndex} className="bg-gray-50 m-1 p-1 rounded">
                                            <div className="grid grid-cols-12 justify-start items-center">

                                                <div className="col-span-2 flex justify-start items-center">
                                                    <div
                                                        onClick={() => handleToggleCommunityDetails(community._id)}
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                    >
                                                        {showCommunityDetails[community._id] ? (
                                                            <MinusIcon className="h-3 w-3 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-3 w-3 text-orange-600" />
                                                        )}
                                                    </div>
                                                    <p className="text-lg mx-1 px-1 font-bold text-gray-400">{communityIndex+1}.</p>
                                                    <div className="flex justify-center items-center rounded-full h-7 w-7 bg-gray-50 hover:bg-white cursor-pointer" ><EtMlCommunityExellenceIcon1SVGComp  className='h-5 w-5 text-orange-600'/></div>
                                                    
                                                    <div className="m-1 p-1">
                                                        <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(updatedAt)}
                                                        </p>
                                                        <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                    </div>
                                                </div>
                                                <div className="col-span-3 flex justify-start items-center">
                                                    <div>
                                                        <p className="text-xs mx-1 px-1 font-bold">{recognitionName}</p>
                                                        <p dangerouslySetInnerHTML={createMarkup(recognitionDesc)} className="text-[9px] italic mx-1 px-1 leading-4 text-gray-400"></p>
                                                    </div>
                                                </div>
                                                <div className="col-span-3 flex justify-start items-center">
                                                    <EtMlCommunityOrgIcon1SVGComp className="h-6 w-6 text-orange-600"/>
                                                    <div>
                                                        <p className="text-xs mx-1 px-1 font-bold">{recognitionOrgName}</p>
                                                        <p className="text-[9px] italic mx-1 px-1 text-gray-400">{formatDateToMonthYear(recognitionDate)}</p>
                                                    </div>
                                                </div>

                                                <div className="col-span-4 flex justify-end items-center mx-2">
                                                    <ClockIcon className="h-5 w-5 text-lime-600" />
                                                    <p className="text-[9px] mx-1">First Created:</p>
                                                    <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdAt)}</p>
                                                    <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                                    <PencilSquareIcon
                                                        className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                                        onClick={() => handleUpdateUserImprintCommunity(communityId)}
                                                    />
                                                    </div>
                                                </div>

                                            </div>
                                            {showCommunityDetails[community._id] && communityNotes.length>0 && communityNotes?.map((note, noteIndex) => {                            
                                                const noteText = note?.recognitionNoteText || ""
                                                const noteDate = note?.recognitionNoteDate || ""
                                                const noteUpdateDate = note?.recognitionNoteUpdateDate || ""
                
                                                return(
                                                <div key={note._id+noteIndex} className="">
                                                    <div className="grid grid-cols-6 justify-between items-center m-1 p-1 bg-gray-100 border rounded">
                                                    <div className="col-span-1 flex justify-start items-center m-1 p-1">
                                                        <div
                                                        onClick={() =>
                                                            handleToggleCommunityNoteDetails(note?._id)
                                                        }
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                        >
                                                        {showCommunityNoteDetails[note?._id] ? (
                                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                                        )}
                                                        </div>
                                                        <div>
                                                        <p className="font-bold mx-1 px-1 text-gray-600">
                                                            {noteIndex + 1}.
                                                        </p>
                                                        </div>
                                                        {/* <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                        <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
                                                        </div> */}
                                                        <div>
                                                        <div className="flex flex-col justify-center items-center mx-1 px-1">
                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(noteUpdateDate)}
                                                            </p>
                                                            <p className="text-[9px] text-gray-600 italic">
                                                            last updated
                                                            </p>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-3">
                                                        <div
                                                        dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                        ></div>
                                                    </div>
                                                    <div className="col-span-2 flex justify-center items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">
                                                        {formatDateTime(noteDate)}
                                                        </p>
                                                    </div>
                                                    </div>
                                                    {showCommunityNoteDetails[note?._id] && (
                                                    <div className="bg-white m-1 p-1 rounded">
                
                                                        <div dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="m-1 p-1 text-xs leading-4">
                                                        </div>
                
                                                    </div>
                                                    )}
                                                </div>
                                                )
                                            })}

                                        </div>
                                    )
                                })}

                                <EtModalUpdateUserImprintLegacyRecognitionsCommunity
                                    isOpen={isUpdateLegacyCommunityModalOpen}
                                    id={selectedCommunityId}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={updateLegacyCommunityTriggeringUrl}
                                    onClose={handleCloseUpdateLegacyCommunityModal}
                                /> 
                            </div>
                            {/* 8. National */}
                            <div className="m-1 p-1 bg-gray-100 rounded">
                                <div className="grid grid-cols-10 justify-between items-center">
                                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                                        <div
                                            onClick={() => setShowNationalSection(prev => !prev)}
                                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                            >
                                            {showNationalSection ? (
                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                            ) : (
                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                            )}
                                        </div>
                                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtMlNationalExellenceIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                                        <div><p className="font-bold mx-1 px-1">National</p></div>  
                                    </div>
                                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                                        <p className="font-bold text-xs m-1 p-1">Count:</p>
                                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{nationalsLength}</p></div>
                                    </div>
                                </div>
                                {showNationalSection && nationalsLength>0 && nationals?.map((national, nationalIndex) => {
                                    
                                    const nationalId = national?.recognitionsNationalId?._id || null
                                    const recognitionName = national?.recognitionsNationalId?.recognitionName || ""
                                    const recognitionDesc = national?.recognitionsNationalId?.recognitionDesc || ""
                                    const recognitionDate = national?.recognitionsNationalId?.recognitionDate || null
                                    const recognitionOrgName = national?.recognitionsNationalId?.recognitionOrgName || ""
                                    const nationalNotes = national?.recognitionsNationalId?.recognitionNotes || []
                                    const createdAt = national?.createdAt || null
                                    const updatedAt = national?.updatedAt || null

                                    return(
                                        <div key={national._id+nationalIndex} className="bg-gray-50 m-1 p-1 rounded">
                                            <div className="grid grid-cols-12 justify-start items-center">

                                                <div className="col-span-2 flex justify-start items-center">
                                                    <div
                                                        onClick={() => handleToggleNationalDetails(national._id)}
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                    >
                                                        {showNationalDetails[national._id] ? (
                                                            <MinusIcon className="h-3 w-3 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-3 w-3 text-orange-600" />
                                                        )}
                                                    </div>
                                                    <p className="text-lg mx-1 px-1 font-bold text-gray-400">{nationalIndex+1}.</p>
                                                    <div className="flex justify-center items-center rounded-full h-7 w-7 bg-gray-50 hover:bg-white cursor-pointer" ><EtMlNationalExellenceIcon1SVGComp  className='h-5 w-5 text-orange-600'/></div>
                                                    
                                                    <div className="m-1 p-1">
                                                        <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(updatedAt)}
                                                        </p>
                                                        <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                    </div>
                                                </div>
                                                <div className="col-span-3 flex justify-start items-center">
                                                    <div>
                                                        <p className="text-xs mx-1 px-1 font-bold">{recognitionName}</p>
                                                        <p dangerouslySetInnerHTML={createMarkup(recognitionDesc)} className="text-[9px] italic mx-1 px-1 leading-4 text-gray-400"></p>
                                                    </div>
                                                </div>
                                                <div className="col-span-3 flex justify-start items-center">
                                                    <EtMlNationalOrgIcon1SVGComp className="h-6 w-6 text-orange-600"/>
                                                    <div>
                                                        <p className="text-xs mx-1 px-1 font-bold">{recognitionOrgName}</p>
                                                        <p className="text-[9px] italic mx-1 px-1 text-gray-400">{formatDateToMonthYear(recognitionDate)}</p>
                                                    </div>
                                                </div>

                                                <div className="col-span-4 flex justify-end items-center mx-2">
                                                    <ClockIcon className="h-5 w-5 text-lime-600" />
                                                    <p className="text-[9px] mx-1">First Created:</p>
                                                    <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdAt)}</p>
                                                    <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                                    <PencilSquareIcon
                                                        className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                                        onClick={() => handleUpdateUserImprintNational(nationalId)}
                                                    />
                                                    </div>
                                                </div>

                                            </div>
                                            {showNationalDetails[national._id] && nationalNotes.length>0 && nationalNotes?.map((note, noteIndex) => {                            
                                                const noteText = note?.recognitionNoteText || ""
                                                const noteDate = note?.recognitionNoteDate || ""
                                                const noteUpdateDate = note?.recognitionNoteUpdateDate || ""
                
                                                return(
                                                <div key={note._id+noteIndex} className="">
                                                    <div className="grid grid-cols-6 justify-between items-center m-1 p-1 bg-gray-100 border rounded">
                                                    <div className="col-span-1 flex justify-start items-center m-1 p-1">
                                                        <div
                                                        onClick={() =>
                                                            handleToggleNationalNoteDetails(note?._id)
                                                        }
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                        >
                                                        {showNationalNoteDetails[note?._id] ? (
                                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                                        )}
                                                        </div>
                                                        <div>
                                                        <p className="font-bold mx-1 px-1 text-gray-600">
                                                            {noteIndex + 1}.
                                                        </p>
                                                        </div>
                                                        {/* <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                        <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
                                                        </div> */}
                                                        <div>
                                                        <div className="flex flex-col justify-center items-center mx-1 px-1">
                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(noteUpdateDate)}
                                                            </p>
                                                            <p className="text-[9px] text-gray-600 italic">
                                                            last updated
                                                            </p>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-3">
                                                        <div
                                                        dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                        ></div>
                                                    </div>
                                                    <div className="col-span-2 flex justify-center items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">
                                                        {formatDateTime(noteDate)}
                                                        </p>
                                                    </div>
                                                    </div>
                                                    {showNationalNoteDetails[note?._id] && (
                                                    <div className="bg-white m-1 p-1 rounded">
                
                                                        <div dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="m-1 p-1 text-xs leading-4">
                                                        </div>
                
                                                    </div>
                                                    )}
                                                </div>
                                                )
                                            })}

                                        </div>
                                    )
                                })}

                                <EtModalUpdateUserImprintLegacyRecognitionsNational
                                    isOpen={isUpdateLegacyNationalModalOpen}
                                    id={selectedNationalId}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={updateLegacyNationalTriggeringUrl}
                                    onClose={handleCloseUpdateLegacyNationalModal}
                                /> 
                            </div>
                            {/* 9. Global */}
                            <div className="m-1 p-1 bg-gray-100 rounded">
                                <div className="grid grid-cols-10 justify-between items-center">
                                    <div className="col-span-3 flex justify-start items-center m-1 p-1">
                                        <div
                                            onClick={() => setShowGlobalSection(prev => !prev)}
                                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                            >
                                            {showGlobalSection ? (
                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                            ) : (
                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                            )}
                                        </div>
                                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtMlGlobalExellenceIcon1SVGComp  className='h-8 w-8 text-orange-600'/></div>
                                        <div><p className="font-bold mx-1 px-1">Global</p></div>  
                                    </div>
                                    <div className="col-span-3 flex justify-center items-center m-1 p-1">
                                        <p className="font-bold text-xs m-1 p-1">Count:</p>
                                        <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{globalsLength}</p></div>
                                    </div>
                                </div>
                                {showGlobalSection && globalsLength>0 && globals?.map((global, globalIndex) => {
                                    
                                    const globalId = global?.recognitionsGlobalId?._id || null
                                    const recognitionName = global?.recognitionsGlobalId?.recognitionName || ""
                                    const recognitionDesc = global?.recognitionsGlobalId?.recognitionDesc || ""
                                    const recognitionDate = global?.recognitionsGlobalId?.recognitionDate || null
                                    const recognitionOrgName = global?.recognitionsGlobalId?.recognitionOrgName || ""
                                    const globalNotes = global?.recognitionsGlobalId?.recognitionNotes || []
                                    const createdAt = global?.createdAt || null
                                    const updatedAt = global?.updatedAt || null

                                    return(
                                        <div key={global._id+globalIndex} className="bg-gray-50 m-1 p-1 rounded">
                                            <div className="grid grid-cols-12 justify-start items-center">

                                                <div className="col-span-2 flex justify-start items-center">
                                                    <div
                                                        onClick={() => handleToggleGlobalDetails(global._id)}
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                    >
                                                        {showGlobalDetails[global._id] ? (
                                                            <MinusIcon className="h-3 w-3 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-3 w-3 text-orange-600" />
                                                        )}
                                                    </div>
                                                    <p className="text-lg mx-1 px-1 font-bold text-gray-400">{globalIndex+1}.</p>
                                                    <div className="flex justify-center items-center rounded-full h-7 w-7 bg-gray-50 hover:bg-white cursor-pointer" ><EtMlGlobalExellenceIcon1SVGComp  className='h-5 w-5 text-orange-600'/></div>
                                                    
                                                    <div className="m-1 p-1">
                                                        <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(updatedAt)}
                                                        </p>
                                                        <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                    </div>
                                                </div>
                                                <div className="col-span-3 flex justify-start items-center">
                                                    <div>
                                                        <p className="text-xs mx-1 px-1 font-bold">{recognitionName}</p>
                                                        <p dangerouslySetInnerHTML={createMarkup(recognitionDesc)} className="text-[9px] italic mx-1 px-1 leading-4 text-gray-400"></p>
                                                    </div>
                                                </div>
                                                <div className="col-span-3 flex justify-start items-center">
                                                    <EtMlGlobalOrgIcon1SVGComp className="h-6 w-6 text-orange-600"/>
                                                    <div>
                                                        <p className="text-xs mx-1 px-1 font-bold">{recognitionOrgName}</p>
                                                        <p className="text-[9px] italic mx-1 px-1 text-gray-400">{formatDateToMonthYear(recognitionDate)}</p>
                                                    </div>
                                                </div>

                                                <div className="col-span-4 flex justify-end items-center mx-2">
                                                    <ClockIcon className="h-5 w-5 text-lime-600" />
                                                    <p className="text-[9px] mx-1">First Created:</p>
                                                    <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(createdAt)}</p>
                                                    <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                                    <PencilSquareIcon
                                                        className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                                        onClick={() => handleUpdateUserImprintGlobal(globalId)}
                                                    />
                                                    </div>
                                                </div>

                                            </div>
                                            {showGlobalDetails[global._id] && globalNotes.length>0 && globalNotes?.map((note, noteIndex) => {                            
                                                const noteText = note?.recognitionNoteText || ""
                                                const noteDate = note?.recognitionNoteDate || ""
                                                const noteUpdateDate = note?.recognitionNoteUpdateDate || ""
                
                                                return(
                                                <div key={note._id+noteIndex} className="">
                                                    <div className="grid grid-cols-6 justify-between items-center m-1 p-1 bg-gray-100 border rounded">
                                                    <div className="col-span-1 flex justify-start items-center m-1 p-1">
                                                        <div
                                                        onClick={() =>
                                                            handleToggleGlobalNoteDetails(note?._id)
                                                        }
                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                        >
                                                        {showGlobalNoteDetails[note?._id] ? (
                                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                                        )}
                                                        </div>
                                                        <div>
                                                        <p className="font-bold mx-1 px-1 text-gray-600">
                                                            {noteIndex + 1}.
                                                        </p>
                                                        </div>
                                                        {/* <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                        <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
                                                        </div> */}
                                                        <div>
                                                        <div className="flex flex-col justify-center items-center mx-1 px-1">
                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                            {getTimeSince(noteUpdateDate)}
                                                            </p>
                                                            <p className="text-[9px] text-gray-600 italic">
                                                            last updated
                                                            </p>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-3">
                                                        <div
                                                        dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                        ></div>
                                                    </div>
                                                    <div className="col-span-2 flex justify-center items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">
                                                        {formatDateTime(noteDate)}
                                                        </p>
                                                    </div>
                                                    </div>
                                                    {showGlobalNoteDetails[note?._id] && (
                                                    <div className="bg-white m-1 p-1 rounded">
                
                                                        <div dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="m-1 p-1 text-xs leading-4">
                                                        </div>
                
                                                    </div>
                                                    )}
                                                </div>
                                                )
                                            })}

                                        </div>
                                    )
                                })}

                                <EtModalUpdateUserImprintLegacyRecognitionsGlobal
                                    isOpen={isUpdateLegacyGlobalModalOpen}
                                    id={selectedGlobalId}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={updateLegacyGlobalTriggeringUrl}
                                    onClose={handleCloseUpdateLegacyGlobalModal}
                                /> 
                            </div>
                        </div>
                    )}
                </div>
            )
        })}
        

    </div>
  )
}

const EtLegacyScorecard = ({ legacyTypes=[], legaciesForLife = [], legaciesThisYr=[] }) => {

    // 0. Determine the data source based on the incoming arrays
    const legacyTargetsData = legaciesForLife.length > 0 ? legaciesForLife : legaciesThisYr;
    
    // 1. Calculate the grid size based on the number of legacy types
    const calculateGridSize = (numberOfLegacyTypes) => {
        if (numberOfLegacyTypes <= 1) return 1;
        if (numberOfLegacyTypes <= 4) return 2;
        if (numberOfLegacyTypes <= 9) return 3;
        if (numberOfLegacyTypes <= 16) return 4;
        return 4; // Default to 4 if more than 16 creation types
    };
    
    const gridSize = calculateGridSize(legacyTypes.length);
    
    // 2. Function to get the appropriate color class based on legacy count
    const getColorForLegacyCount = (count) => {
        return count > 0 ? "bg-etpx-5" : "bg-black"; // Light up the pixel if count > 0, else keep it black
    };
    
    const getTextColorForScore = (count) => {
        return count >0 ? 'text-white' : 'text-gray-900'; // Default color if none of the conditions match
        };
    
    const getTextColorForTitle = (count) => {
        return count >0 ? 'text-white text-sm font-bold' : 'text-gray-700'; // Default color if none of the conditions match
        };
    
    // 3. Generate square divs for each legacy type
    const squares = legacyTypes?.map((type, index) => {
        const legacyData = legacyTargetsData?.find(data => data.legacyTypeName === type?.legacyTypeName); // Find matching legacy data
        const pic = type?.pic; // Icon for the legacy type
        const MyIcon = createSvgComponentFromBase64(pic); // 07 Jul 2024
        const legacyTypeName = type?.legacyTypeName;
        const legacyCount = legacyData ? legacyData.count || legacyData.countThisYr : 0; // Get legacy count or default to 0
        const colorClass = getColorForLegacyCount(legacyCount);
        const textColorClass = getTextColorForScore(legacyCount)
        const textTitleColorClass = getTextColorForTitle(legacyCount)
    
        return (
        <div key={index} className={`aspect-square rounded border m-[1px] border-black ${colorClass}`}>
            <div className="flex flex-col justify-between items-center h-full p-2">
            
            <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 md:h-8 md:w-8 ${textColorClass}`}/>
            
            <div className={`rounded-full h-6 w-6 border flex justify-center items-center ${legacyCount >0 ? 'border-white' : 'border-none'}`}><p className={`${textColorClass} text-center font-bold`}>{legacyCount}</p></div>
            <p className={`text-[9px]  ${textTitleColorClass} text-center`}>{legacyTypeName}</p>
            
            </div>
        </div>
        );
    });
    
    // 4. Return the grid of squares
    return (
        <div className={`grid grid-cols-${gridSize} aspect-square rounded bg-gradient-to-r from-black to-black via-etpx-bg mx-auto p-[2px]`}>
        {squares}
        </div>
    );
  };

export default EtImprintLegacy