import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const capabilitiesIndustryTypesAdapter = createEntityAdapter({})

const initialState = capabilitiesIndustryTypesAdapter.getInitialState()

export const capabilitiesIndustryTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCapabilitiesIndustryTypes: builder.query({
            query: () => ({
                url: '/capabilitiesIndustryTypes/getCapabilitiesIndustryTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedCapabilitiesIndustryTypes = responseData.map(capabilitiesIndustryType => {
                    capabilitiesIndustryType.id = capabilitiesIndustryType._id
                    return capabilitiesIndustryType
                })
                return capabilitiesIndustryTypesAdapter.setAll(initialState, loadedCapabilitiesIndustryTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'CapabilitiesIndustryType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'CapabilitiesIndustryType', id}))
                    ]
                } else {
                    return [ { type: 'CapabilitiesIndustryType', id: 'LIST'}]
                }
            },
        }),
        createNewCapabilitiesIndustryType: builder.mutation({
            query: initialCapabilitiesIndustryType => ({
                url: '/capabilitiesIndustryTypes/createNewCapabilitiesIndustryType',
                method: 'POST',
                body: {...initialCapabilitiesIndustryType,}
            }),
            invalidatesTags: [
                { type: 'CapabilitiesIndustryType', id: "LIST" }
            ] 
        }),
        updateCapabilitiesIndustryType: builder.mutation({
            query: initialCapabilitiesIndustryType => ({
                url: '/capabilitiesIndustryTypes/updateCapabilitiesIndustryType',
                method: 'PATCH',
                body: {...initialCapabilitiesIndustryType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'CapabilitiesIndustryType', id: arg.id },
                { type: 'CapabilitiesIndustryType', id: 'LIST' }
            ],
        }),
        deleteCapabilitiesIndustryType: builder.mutation({
            query: ({id}) => ({
                url: '/capabilitiesIndustryTypes/deleteCapabilitiesIndustryType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'CapabilitiesIndustryType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetCapabilitiesIndustryTypesQuery,
    useCreateNewCapabilitiesIndustryTypeMutation,
    useUpdateCapabilitiesIndustryTypeMutation,
    useDeleteCapabilitiesIndustryTypeMutation,
} = capabilitiesIndustryTypesApiSlice

// returns the query result object
export const selectCapabilitiesIndustryTypeResult = capabilitiesIndustryTypesApiSlice.endpoints.getCapabilitiesIndustryTypes.select()

// create memoized selector
const selectCapabilitiesIndustryTypesData = createSelector(
    selectCapabilitiesIndustryTypeResult,
    capabilitiesIndustryTypeResult => capabilitiesIndustryTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCapabilitiesIndustryTypes,
    selectById: selectCapabilitiesIndustryTypeById,
    selectIds: selectCapabilitiesIndustryTypeIds,
} = capabilitiesIndustryTypesAdapter.getSelectors(state => selectCapabilitiesIndustryTypesData(state) ?? initialState)