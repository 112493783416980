import React, { useState } from 'react'
import useTitle from '@/hooks/useTitle'
import { useNavigate } from 'react-router'
import { addIcon } from '@/assets/img/imgAssets'
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon,  } from '@heroicons/react/24/outline'
import { BuildingLibraryIcon ,CalendarDaysIcon} from '@heroicons/react/24/solid'
import PulseLoader from "react-spinners/PulseLoader"
import { useGetOrganizationsQuery } from './organizationsApiSlice'

const EtOrganizationList = ({setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }) => {
  // First Created - 08 May 2024
    // Author - Atul Pandey
    // Updated - 01 Mar 2025 - updated the comp with props - { setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }
    // Updated - 01 Mar 2025 - switched from navigate to onAdd, onUpdate, onDelete, setSelectedEntityId

    // 0 - Set Page Title
    useTitle('Organizations List')

    // 1 - set page navigations
    const navigate = useNavigate()

    // 2 - Initialize showDetails state as an object where key is the organization index
    const [showDetails, setShowDetails] = useState({})

    // 3 - Extract the organizations data
    const { data: organizationsData, isError, error, isLoading } = useGetOrganizationsQuery()
    console.log('organizationsData', organizationsData)

    // 4 - Toggle Show function for organization details
    const handleToggleDetails = (organizationId) => {
        setShowDetails(prev => ({ ...prev, [organizationId]: !prev[organizationId] }));
    }

    // 5 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
        return { __html: htmlContent }
    }

    // 6 - Optional: handle loading and error states
    if (isLoading) return <PulseLoader color={"#ea580c"} />
    if (isError) return <div>Error occurred: {error?.data?.message}</div>

    // 7 - Grouping organizations by trail
    const organizations =  organizationsData?.ids.map(id => {
        const organization = organizationsData?.entities[id];
        const { 
            _id,
            organizationName,
            organizationIntro, 
            organizationTypeId, 
            organizationDesc, 
            organizationTargetAudience, 
            pic, 
            organizationStartedDate, 
            organizationLocations, 
            organizationOutcomes} = organization;
        
            return {
            _id,
            organizationName,
            organizationIntro, 
            organizationTypeId, 
            organizationDesc, 
            organizationTargetAudience, 
            pic, 
            organizationStartedDate, 
            organizationLocations, 
            organizationOutcomes
            }
    });

    console.log('organizations', organizations)

    return (
        <div className='bg-gray-200 m-1 p-1 rounded font-poppins mx-auto'>
            {/* Header */}
            <div className='flex justify-start items-center m-1 p-1'>
                <div><p className='text-2xl m-1 p-1 font-bold'>Et Organizations</p></div>
                <div>
                    <img
                        src={addIcon}
                        alt=""
                        className="h-10 w-10 cursor-pointer"
                        // onClick={() => navigate("/organizations/create")}
                        onClick={onAdd} // 01 Mar 2025
                    />
                </div>
            </div>

            {organizations && organizations?.map((item, index) => (
                <div key={item+index} className='m-1 p-1 bg-white rounded'>

                <div className='m-1 p-1 bg-gray-100 rounded '>
                    
                    <div className='flex justify-between items-center'>
                    <div className='flex justify-start items-center mx-2'>

                            <div
                                onClick={() => handleToggleDetails(item._id)}
                                className="m-1 p-1 cursor-pointer rounded-full h-7 w-7 flex justify-center items-center hover:bg-white"
                            >
                                {showDetails[item._id] ? <MinusIcon className="h-6 w-6 text-orange-600" /> : <PlusIcon className="h-4 w-4 text-orange-600" />}
                            </div>

                            <div className='m-1 p-1'>
                                <p className='font-bold'>{index+1} - {item.organizationName}</p>
                                <p className='text-xs'>{item.organizationDesc}</p>
                            </div>
                    </div>
                    <div className='flex justify-start items-center mx-2'>
                        <div className='rounded-full flex justify-center items-center h-6 w-6 hover:bg-white'>
                            <PencilSquareIcon 
                            // onClick={() => navigate(`/organizations/update/${item?._id}`)} 
                            onClick={() => onUpdate(item?._id)} // 01 Mar 2025
                            
                            className="h-4 w-4 text-orange-600 cursor-pointer" />
                        </div>
                        <div className='rounded-full flex justify-center items-center h-6 w-6 hover:bg-white'>
                            <TrashIcon 
                            // onClick={() => navigate(`/organizations/delete/${item?._id}`)} 
                            onClick={() => onDelete(item?._id)} // 01 Mar 2025
                            
                            className="h-4 w-4 text-orange-600 cursor-pointer" />
                        </div>
                    </div>
                    </div>
                
                    {showDetails[item._id] && (
                        <div className='m-1 p-1 bg-gray-200 rounded'>
                            {/* Organization Details */}
                            <div className='m-1 p-1 rounded bg-white'>

                                {item?.organizationIntro && (
                                <div className='bg-gray-50 rounded text-xs mx-2 px-2 my-1 py-1'>
                                <p className='m-1 p-1' dangerouslySetInnerHTML={createMarkup(item?.organizationIntro)}></p>
                                </div>)}

                                <div className='flex justify-start items-center my-2'>
                                <BuildingLibraryIcon className="h-4 w-4 text-orange-600 mx-2" />
                                <p className='text-xs font-bold'>Org Type: <span className='font-normal text-gray-400'>{item?.organizationTypeId.organizationTypeName}</span> </p>
                                </div>

                                <div className='flex justify-start items-center my-2'>
                                <CalendarDaysIcon className="h-4 w-4 text-orange-600 mx-2" />
                                <p className='text-xs font-bold'>Established: <span className='font-normal text-gray-400'>{item?.organizationStartedDate ? formatDate(item?.organizationStartedDate) : 'A long time ago....'}</span></p>
                                </div>

                                {item?.organizationLocations && item?.organizationLocations.map((location, locIndex) => (
                                    <div key={location+locIndex} className='rounded m-1 p-1 bg-gray-200 inline-block'>
                                        <p className='text-[10px] mx-1 px-1'>{location.location}</p>
                                    </div>
                                ))}
                                
                            </div>
                        </div>
                    )}
                
                </div>

                

            </div>
            ))}
            

        </div>
    )
}

// Function to format the date
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'long', year: 'numeric' };
    const day = date.getDate();
  
    // Determine the correct ordinal indicator
    const ordinal = (n) => {
        let s = ["th", "st", "nd", "rd"],
            v = n % 100;
        return s[(v - 20) % 10] || s[v] || s[0]; // Only returns the suffix
    }
    // const ordinal = (n) => {
    //   let s = ["th", "st", "nd", "rd"],
    //       v = n % 100;
    //   return n + (s[(v-20)%10] || s[v] || s[0]);
    // };
  
   // return `${ordinal(day)} ${new Intl.DateTimeFormat('en-GB', options).format(date)}`;

   return (
    <>
      {day}<sup>{ordinal(day)}</sup> {new Intl.DateTimeFormat('en-GB', options).format(date)}
    </>
  )

//     const formattedDate = `${day}<sup>${ordinal(day)}</sup> ${new Intl.DateTimeFormat('en-GB', options).format(date)}`;
//   return formattedDate;
  }

export default EtOrganizationList