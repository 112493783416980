import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const inspirationBeaconTypesAdapter = createEntityAdapter({})

const initialState = inspirationBeaconTypesAdapter.getInitialState()

export const inspirationBeaconTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getInspirationBeaconTypes: builder.query({
            query: () => ({
                url: '/inspirationBeaconTypes/getInspirationBeaconTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedInspirationBeaconTypes = responseData.map(inspirationBeaconType => {
                    inspirationBeaconType.id = inspirationBeaconType._id
                    return inspirationBeaconType
                })
                return inspirationBeaconTypesAdapter.setAll(initialState, loadedInspirationBeaconTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'InspirationBeaconType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'InspirationBeaconType', id}))
                    ]
                } else {
                    return [ { type: 'InspirationBeaconType', id: 'LIST'}]
                }
            },
        }),
        createNewInspirationBeaconType: builder.mutation({
            query: initialInspirationBeaconType => ({
                url: '/inspirationBeaconTypes/createNewInspirationBeaconType',
                method: 'POST',
                body: {...initialInspirationBeaconType,}
            }),
            invalidatesTags: [
                { type: 'InspirationBeaconType', id: "LIST" }
            ] 
        }),
        updateInspirationBeaconType: builder.mutation({
            query: initialInspirationBeaconType => ({
                url: '/inspirationBeaconTypes/updateInspirationBeaconType',
                method: 'PATCH',
                body: {...initialInspirationBeaconType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'InspirationBeaconType', id: arg.id },
                { type: 'InspirationBeaconType', id: 'LIST' }
            ],
        }),
        deleteInspirationBeaconType: builder.mutation({
            query: ({id}) => ({
                url: '/inspirationBeaconTypes/deleteInspirationBeaconType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'InspirationBeaconType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetInspirationBeaconTypesQuery,
    useCreateNewInspirationBeaconTypeMutation,
    useUpdateInspirationBeaconTypeMutation,
    useDeleteInspirationBeaconTypeMutation,
} = inspirationBeaconTypesApiSlice

// returns the query result object
export const selectInspirationBeaconTypeResult = inspirationBeaconTypesApiSlice.endpoints.getInspirationBeaconTypes.select()

// create memoized selector
const selectInspirationBeaconTypesData = createSelector(
    selectInspirationBeaconTypeResult,
    inspirationBeaconTypeResult => inspirationBeaconTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllInspirationBeaconTypes,
    selectById: selectInspirationBeaconTypeById,
    selectIds: selectInspirationBeaconTypeIds,
} = inspirationBeaconTypesAdapter.getSelectors(state => selectInspirationBeaconTypesData(state) ?? initialState)