import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userReflectionsAdapter = createEntityAdapter({})

const initialState = userReflectionsAdapter.getInitialState()

export const userReflectionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserReflections: builder.query({
            query: () => ({
                url: '/userReflections/getUserReflections',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserReflections = responseData.map(userReflection => {
                    userReflection.id = userReflection._id
                    return userReflection
                })
                return userReflectionsAdapter.setAll(initialState, loadedUserReflections)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserReflection', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserReflection', id}))
                    ]
                } else {
                    return [ { type: 'UserReflection', id: 'LIST'}]
                }
            },
        }),
        createNewUserReflection: builder.mutation({
            query: initialUserReflection => ({
                url: '/userReflections/createNewUserReflection',
                method: 'POST',
                body: {...initialUserReflection,}
            }),
            invalidatesTags: [
                { type: 'UserReflection', id: "LIST" }
            ] 
        }),
        updateUserReflection: builder.mutation({
            query: initialUserReflection => ({
                url: '/userReflections/updateUserReflection',
                method: 'PATCH',
                body: {...initialUserReflection,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserReflection', id: arg.id },
                { type: 'UserReflection', id: 'LIST' }
            ],
        }),
        deleteUserReflection: builder.mutation({
            query: ({id}) => ({
                url: '/userReflections/deleteUserReflection',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserReflection', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserReflectionsQuery,
    useCreateNewUserReflectionMutation,
    useUpdateUserReflectionMutation,
    useDeleteUserReflectionMutation,
} = userReflectionsApiSlice

// returns the query result object
export const selectUserReflectionResult = userReflectionsApiSlice.endpoints.getUserReflections.select()

// create memoized selector
const selectUserReflectionsData = createSelector(
    selectUserReflectionResult,
    userReflectionResult => userReflectionResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserReflections,
    selectById: selectUserReflectionById,
    selectIds: selectUserReflectionIds,
} = userReflectionsAdapter.getSelectors(state => selectUserReflectionsData(state) ?? initialState)