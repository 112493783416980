import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userPlanOrgsAdapter = createEntityAdapter({})

const initialState = userPlanOrgsAdapter.getInitialState()

export const userPlanOrgsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserPlanOrgs: builder.query({
            query: () => ({
                url: '/userPlanOrgs/getUserPlanOrgs',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserPlanOrgs = responseData.map(userPlanOrg => {
                    userPlanOrg.id = userPlanOrg._id
                    return userPlanOrg
                })
                return userPlanOrgsAdapter.setAll(initialState, loadedUserPlanOrgs)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserPlanOrg', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserPlanOrg', id}))
                    ]
                } else {
                    return [ { type: 'UserPlanOrg', id: 'LIST'}]
                }
            },
        }),
        createNewUserPlanOrg: builder.mutation({
            query: initialUserPlanOrg => ({
                url: '/userPlanOrgs/createNewUserPlanOrg',
                method: 'POST',
                body: {...initialUserPlanOrg,}
            }),
            invalidatesTags: [
                { type: 'UserPlanOrg', id: "LIST" }
            ] 
        }),
        updateUserPlanOrg: builder.mutation({
            query: initialUserPlanOrg => ({
                url: '/userPlanOrgs/updateUserPlanOrg',
                method: 'PATCH',
                body: {...initialUserPlanOrg,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserPlanOrg', id: arg.id },
                { type: 'UserPlanOrg', id: 'LIST' }
            ],
        }),
        deleteUserPlanOrg: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userPlanOrgs/deleteUserPlanOrg',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userPlanOrgs/deleteUserPlanOrg',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserPlanOrg', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserPlanOrgsQuery,
    useCreateNewUserPlanOrgMutation,
    useUpdateUserPlanOrgMutation,
    useDeleteUserPlanOrgMutation,
} = userPlanOrgsApiSlice

// returns the query result object
export const selectUserPlanOrgResult = userPlanOrgsApiSlice.endpoints.getUserPlanOrgs.select()

// create memoized selector
const selectUserPlanOrgsData = createSelector(
    selectUserPlanOrgResult,
    userPlanOrgResult => userPlanOrgResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserPlanOrgs,
    selectById: selectUserPlanOrgById,
    selectIds: selectUserPlanOrgIds,
} = userPlanOrgsAdapter.getSelectors(state => selectUserPlanOrgsData(state) ?? initialState)