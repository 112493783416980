
    import React from 'react';
    
    const EtYourLeaderIconSVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <g fill-rule="evenodd">
  <path d="m3099.5 1215.4v-711.94h-2447.6v718.99c214.35 45 375.49 235.27 375.49 462.94 0 54.148-9.1133 106.24-25.914 154.73h1597.1c-16.801-48.488-25.914-100.57-25.914-154.73 0-261.07 211.95-473.02 472.99-473.02 18.227 0 36.227 1.0508 53.926 3.0391zm-896.4 338.44v-378.19c0-31.051-25.199-56.25-56.25-56.25s-56.25 25.199-56.25 56.25v378.19c0 31.051 25.199 56.25 56.25 56.25s56.25-25.199 56.25-56.25zm-535.2 0v-205.8c0-31.051-25.199-56.25-56.25-56.25s-56.25 25.199-56.25 56.25v205.8c0 31.051 25.199 56.25 56.25 56.25s56.25-25.199 56.25-56.25zm267.6 0v-255.98c0-31.012-25.199-56.25-56.25-56.25-31.051 0-56.25 25.238-56.25 56.25v255.98c0 31.051 25.199 56.25 56.25 56.25 31.051 0 56.25-25.199 56.25-56.25zm231.6-659.62v40.988c0 31.051 25.199 56.25 56.25 56.25 31.051 0 56.25-25.199 56.25-56.25v-189.82c0-31.086-25.199-56.25-56.25-56.25h-189.86c-31.051 0-56.25 25.199-56.25 56.25 0 31.012 25.199 56.25 56.25 56.25h70.836c-103.91 91.273-273.26 152.21-411.45 155.77-232.39 5.9609-317.32 184.46-317.32 184.46-13.688 27.863-2.1758 61.574 25.688 75.262s61.613 2.1758 75.301-25.688c0 0 60.488-117.52 219.23-121.57 157.31-4.0508 346.57-73.648 471.34-175.65z" fillRule="evenodd"/>
  <path d="m495.79 75h2759.9v315.98h-2759.9z" fillRule="evenodd"/>
  <path d="m914.93 1685.4c0 199.11-161.41 360.53-360.53 360.53-199.11 0-360.52-161.41-360.52-360.53 0-199.11 161.41-360.52 360.52-360.52 199.11 0 360.53 161.41 360.53 360.52" fillRule="evenodd"/>
  <path d="m3406.1 1685.4c0 199.11-161.41 360.53-360.52 360.53-199.11 0-360.53-161.41-360.53-360.53 0-199.11 161.41-360.52 360.53-360.52 199.11 0 360.52 161.41 360.52 360.52" fillRule="evenodd"/>
  <path d="m481.2 2631.1v893.89h546.68v-499.16c-44.625-11.176-86.887-31.727-123.75-60.824l-413.96-326.55c-3.0742-2.3984-6.0391-4.8359-8.9609-7.3516z" fillRule="evenodd"/>
  <path d="m3111.8 2334.9c96.711 33.602 166.09 125.48 166.09 233.59v956.48h-546.68v-292.05c65.062-30.488 118.8-81.676 152.36-146.25l242.93-467.96c48.148-92.812 39.676-201.15-14.699-283.8z" fillRule="evenodd"/>
  <path d="m1120.8 2456.3s195.3-201.71 308.93-319.05c43.086-44.477 110.51-53.363 163.27-22.914 3.3359 1.9141 18.75 12.863 22.762 16.426 53.137 46.949 63.977 127.2 22.801 186.94-91.801 133.24-243.64 353.59-352.69 511.88-33.938 49.238-86.699 82.238-145.84 91.164-59.102 8.9258-119.25-7.0508-166.2-44.062-129.86-102.45-301.09-237.49-413.96-326.55-65.211-51.449-82.801-142.39-42.676-214.12 1.4609-2.625 11.586-18.188 15.414-22.949 48.227-60.414 132.82-80.324 203.66-45.523 153.75 75.488 384.52 188.77 384.52 188.77z" fillRule="evenodd"/>
  <path d="m2476.8 2712.2s-261.04-103.46-412.88-163.69c-57.562-22.801-122.66-3.1875-158.21 46.273-2.2891 3.1484-11.812 19.461-14.023 24.375-29.102 64.648-5.9258 142.24 56.176 179.77 138.49 83.625 367.54 222 532.05 321.38 51.188 30.938 112.88 39.262 170.4 23.102 57.562-16.199 105.86-55.5 133.39-108.56 76.199-146.77 176.7-340.35 242.93-467.96 38.289-73.727 16.875-163.84-49.199-212.7-2.3984-1.7617-18.074-11.812-23.512-14.586-68.812-35.211-154.09-18.562-204.34 42.301-109.09 132.07-272.77 330.3-272.77 330.3z" fillRule="evenodd"/>
 </g>

        </svg>
    );
    
    export default EtYourLeaderIconSVGComp;
    