// Created - 21 Jul 2024
// Author - Atul Pandey
// Updated - 14 Mar 2025 - fetch iCal data 

import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const calendarEventsAdapter = createEntityAdapter({})

const initialState = calendarEventsAdapter.getInitialState()

export const calendarEventsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCalendarEvents: builder.query({
            query: () => ({
                url: '/calendarEvents/getCalendarEvents',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedCalendarEvents = responseData.map(calendarEvent => {
                    calendarEvent.id = calendarEvent._id
                    return calendarEvent
                })
                return calendarEventsAdapter.setAll(initialState, loadedCalendarEvents)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'CalendarEvent', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'CalendarEvent', id}))
                    ]
                } else {
                    return [ { type: 'CalendarEvent', id: 'LIST'}]
                }
            },
        }),
        createNewCalendarEvent: builder.mutation({
            query: initialCalendarEvent => ({
                url: '/calendarEvents/createNewCalendarEvent',
                method: 'POST',
                body: {...initialCalendarEvent,}
            }),
            invalidatesTags: [
                { type: 'CalendarEvent', id: "LIST" }
            ] 
        }),
        updateCalendarEvent: builder.mutation({
            query: initialCalendarEvent => ({
                url: '/calendarEvents/updateCalendarEvent',
                method: 'PATCH',
                body: {...initialCalendarEvent,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'CalendarEvent', id: arg.id },
                { type: 'CalendarEvent', id: 'LIST' }
            ],
        }),
        deleteCalendarEvent: builder.mutation({
            query: ({id}) => ({
                url: '/calendarEvents/deleteCalendarEvent',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'CalendarEvent', id: arg.id}
            ]
        }),
       // 14 Mar 2025 - fetch iCal data
        fetchGoogleCalendarEvents: builder.query({
            query: () => ({
                url: '/calendarEvents/fetchGoogleCalendarEvents',
                method: 'GET',
            }),
            // transformResponse: responseData => responseData.events, // Commented - 19 Mar 2025 Extract events array
            transformResponse: responseData => responseData, // ✅ 19 Mar 2025 Return full response object (success & message)
            providesTags: [{ type: 'CalendarEvent', id: 'ICAL' }],
        }),
        // 19 Mar 2025 - get UserCalendarEvent DB data
        getUserCalendarEvents: builder.query({
            query: () => ({
                url: '/calendarEvents/getUserCalendarEvents',
                method: 'GET',
            }),
            transformResponse: responseData => responseData.events, // Extract events array
            providesTags: [{ type: 'CalendarEvent', id: 'USER_CALENDAR' }],
        }),          
    }),
})

export const {
    useGetCalendarEventsQuery,
    useFetchGoogleCalendarEventsQuery,  // ✅ 14 Mar 2025 - Added new hook
    useLazyFetchGoogleCalendarEventsQuery, // ✅ 24 Mar 2025 🔥 Added this line for lazy fetching
    useGetUserCalendarEventsQuery,  // ✅ 19 Mar 2025 - Added new hook - to get UserCalendarEvent DB data
    useCreateNewCalendarEventMutation,
    useUpdateCalendarEventMutation,
    useDeleteCalendarEventMutation,
} = calendarEventsApiSlice

// returns the query result object
export const selectCalendarEventResult = calendarEventsApiSlice.endpoints.getCalendarEvents.select()

// create memoized selector
const selectCalendarEventsData = createSelector(
    selectCalendarEventResult,
    calendarEventResult => calendarEventResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCalendarEvents,
    selectById: selectCalendarEventById,
    selectIds: selectCalendarEventIds,
} = calendarEventsAdapter.getSelectors(state => selectCalendarEventsData(state) ?? initialState)