// First Created - 21 Mar 2025
// Author - Atul Pandey
// Purpose - To interact with backend controller endpoints for  user meeting prep contents for a User Calendar Event

import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const userMeetingPrepsAdapter = createEntityAdapter({})

const initialState = userMeetingPrepsAdapter.getInitialState()

export const userMeetingPrepsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserMeetingPreps: builder.query({
            query: () => ({
                url: '/userMeetingPreps/getUserMeetingPreps',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserMeetingPreps = responseData.map(userMeetingPrep => {
                    userMeetingPrep.id = userMeetingPrep._id
                    return userMeetingPrep
                })
                return userMeetingPrepsAdapter.setAll(initialState, loadedUserMeetingPreps)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserMeetingPrep', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserMeetingPrep', id}))
                    ]
                } else {
                    return [ { type: 'UserMeetingPrep', id: 'LIST'}]
                }
            },
        }),
        createNewUserMeetingPrep: builder.mutation({
            query: initialUserMeetingPrep => ({
                url: '/userMeetingPreps/createNewUserMeetingPrep',
                method: 'POST',
                body: {...initialUserMeetingPrep,}
            }),
            invalidatesTags: [
                { type: 'UserMeetingPrep', id: "LIST" }
            ] 
        }),
        updateUserMeetingPrep: builder.mutation({
            query: initialUserMeetingPrep => ({
                url: '/userMeetingPreps/updateUserMeetingPrep',
                method: 'PATCH',
                body: {...initialUserMeetingPrep,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserMeetingPrep', id: arg.id },
                { type: 'UserMeetingPrep', id: 'LIST' }
            ],
        }),
        deleteUserMeetingPrep: builder.mutation({
            query: ({id}) => ({
                url: '/userMeetingPreps/deleteUserMeetingPrep',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserMeetingPrep', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserMeetingPrepsQuery,
    useCreateNewUserMeetingPrepMutation,
    useUpdateUserMeetingPrepMutation,
    useDeleteUserMeetingPrepMutation,
} = userMeetingPrepsApiSlice

// returns the query result object
export const selectUserMeetingPrepResult = userMeetingPrepsApiSlice.endpoints.getUserMeetingPreps.select()

// create memoized selector
const selectUserMeetingPrepsData = createSelector(
    selectUserMeetingPrepResult,
    userMeetingPrepResult => userMeetingPrepResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserMeetingPreps,
    selectById: selectUserMeetingPrepById,
    selectIds: selectUserMeetingPrepIds,
} = userMeetingPrepsAdapter.getSelectors(state => selectUserMeetingPrepsData(state) ?? initialState)