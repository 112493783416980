import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userMissionSelfsAdapter = createEntityAdapter({})

const initialState = userMissionSelfsAdapter.getInitialState()

export const userMissionSelfsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserMissionSelfs: builder.query({
            query: () => ({
                url: '/userMissionSelfs/getUserMissionSelfs',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserMissionSelfs = responseData.map(userMissionSelf => {
                    userMissionSelf.id = userMissionSelf._id
                    return userMissionSelf
                })
                return userMissionSelfsAdapter.setAll(initialState, loadedUserMissionSelfs)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserMissionSelf', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserMissionSelf', id}))
                    ]
                } else {
                    return [ { type: 'UserMissionSelf', id: 'LIST'}]
                }
            },
        }),
        createNewUserMissionSelf: builder.mutation({
            query: initialUserMissionSelf => ({
                url: '/userMissionSelfs/createNewUserMissionSelf',
                method: 'POST',
                body: {...initialUserMissionSelf,}
            }),
            invalidatesTags: [
                { type: 'UserMissionSelf', id: "LIST" }
            ] 
        }),
        updateUserMissionSelf: builder.mutation({
            query: initialUserMissionSelf => ({
                url: '/userMissionSelfs/updateUserMissionSelf',
                method: 'PATCH',
                body: {...initialUserMissionSelf,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserMissionSelf', id: arg.id },
                { type: 'UserMissionSelf', id: 'LIST' }
            ],
        }),
        deleteUserMissionSelf: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userMissionSelfs/deleteUserMissionSelf',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userMissionSelfs/deleteUserMissionSelf',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserMissionSelf', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserMissionSelfsQuery,
    useCreateNewUserMissionSelfMutation,
    useUpdateUserMissionSelfMutation,
    useDeleteUserMissionSelfMutation,
} = userMissionSelfsApiSlice

// returns the query result object
export const selectUserMissionSelfResult = userMissionSelfsApiSlice.endpoints.getUserMissionSelfs.select()

// create memoized selector
const selectUserMissionSelfsData = createSelector(
    selectUserMissionSelfResult,
    userMissionSelfResult => userMissionSelfResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserMissionSelfs,
    selectById: selectUserMissionSelfById,
    selectIds: selectUserMissionSelfIds,
} = userMissionSelfsAdapter.getSelectors(state => selectUserMissionSelfsData(state) ?? initialState)