import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import useTitle from "@/hooks/useTitle";
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic";
import EtContentEditorLight2 from "@/utils/EtContentEditorLight2"
import PulseLoader from "react-spinners/PulseLoader";
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';
import { useGetChallengesQuery, useUpdateChallengeMutation } from "./challengesApiSlice";
import { useGetChallengeTypesQuery } from "../challengeTypes/challengeTypesApiSlice";
import { useGetChallengeHeadsQuery } from "../challengeHeads/challengeHeadsApiSlice";

const EtUpdateChallenge = ({id, setActiveAdminView}) => {
  // First Created - 16 Aug 2024
  // Author - Atul Pandey
  // Updated - 18 Aug 2024 - added fields for Intro, Outro, targetAudience, Challenge Scenarios, Challenge Instructions
  // Updated - 18 Aug 2024 - added editor for Scenario building
  // Updated - 20 Aug 2024 - refined for changed data structure - challengeDimId to fetch challenge dimensions data
  // Updated - 28 Feb 2025 - updated the softSkillFunctionArea with props - { id, setActiveAdminView}
  // Updated - 28 Feb 2025 - switched from navigate to setActiveAdminView

  // 0 - Set Page Title
  useTitle('Update A Challenge');

  // 1 - Extract the Id to be updated
  // const { id } = useParams();

  // 2 - Extract the challenge to be updated
  const { challenge } = useGetChallengesQuery("Challenge", {
    selectFromResult: ({ data }) => ({
      challenge: data?.entities[id],
    }),
  });
  // console.log('challenge', challenge)

  // 3 - Check for returned data
  if (!challenge) {
    return <PulseLoader color={"#ea580c"} />;
  }

  // 4 - Display data
  let content;
  content = <EtUpdateChallengeForm challenge={challenge} id={id} setActiveAdminView={setActiveAdminView}/> // 28 Feb 2025 - added setActiveAdminView={setActiveAdminView}

  return content;
};

const EtUpdateChallengeForm = ({ challenge, id, setActiveAdminView }) => {
  // First Created - 16 Aug 2024
  // Author - Atul Pandey
  // Updated - 18 Aug 2024 - added fields for Intro, Outro, targetAudience, Challenge Scenarios, Challenge Instructions
  // Updated - 18 Aug 2024 - added editor for Scenario building
  // Updated - 20 Aug 2024 - refined for changed data structure - challengeDimId to fetch challenge dimensions data

  // 1a - Set the variable for navigate
  const navigate = useNavigate();

  // 1b - Set editor state variables - 18 Aug 2024
  const [scenarioEditorKey, setScenarioEditorKey] = useState('scenarioNote-' + Date.now()); // For editor key

  // 2a - Define the mutation to update the challenge
  const [updateChallenge, { isLoading, isSuccess, isError, error }] = useUpdateChallengeMutation();

  // 2b - Fill the form data with extracted challenge info
  const [formData, setFormData] = useState({
    challengeTypeId: challenge?.challengeTypeId?._id,
    challengeName: challenge?.challengeName,
    challengeDesc: challenge?.challengeDesc,
    challengeDimensions: challenge?.challengeDimensions || [{ challengeHeadId: "", challengeDimensionName: "", challengeDimensionDesc: "" }],
    challengeIntro: challenge.challengeIntro || "",  // 18 Aug 2024
    challengeOutro: challenge.challengeOutro || "",  // 18 Aug 2024
    targetAudience: challenge.targetAudience || [{ audience: "" }],  // 18 Aug 2024
    challengeScenarios: challenge.challengeScenarios || [{ scenario: "" }],  // 18 Aug 2024
    challengeInstructions: challenge.challengeInstructions || [{ instruction: "" }],  // 18 Aug 2024
    aboutMe: challenge?.aboutMe || "",
    pic: challenge.pic || "",
  });

  // 2c - Get challenge types data for options
  const { challengeTypes } = useGetChallengeTypesQuery('challengeTypesList', {
    selectFromResult: ({ data }) => ({
      challengeTypes: data?.ids.map(id => data?.entities[id])
    })
  });

  // 2d - Get challenge heads data for options
  const { challengeHeads } = useGetChallengeHeadsQuery('challengeHeadsList', {
    selectFromResult: ({ data }) => ({
      challengeHeads: data?.ids.map(id => data?.entities[id])
    })
  });

  // 2e - Create options to select a challenge type from the list of challenge types
  const challengeTypeOptions = challengeTypes ? [
    <option key="placeholder" value="" disabled>
      Select a Challenge Type
    </option>,
    ...challengeTypes?.map(challengeType => (
      <option key={challengeType._id} value={challengeType._id}>
        {challengeType?.challengeTypeName}
      </option>
    ))
  ] : [];

  // 2f - Create options to select a challenge head from the list of challenge heads
  const challengeHeadOptions = challengeHeads ? [
    <option key="placeholder" value="" disabled>
      Select a Challenge Head
    </option>,
    ...challengeHeads?.map(challengeHead => (
      <option key={challengeHead?._id} value={challengeHead?._id}>
        {challengeHead?.challengeHeadName}
      </option>
    ))
  ] : [];

  // 3 - Reset form upon successful submission
  useEffect(() => {
    if (isSuccess) {
      setFormData({
        challengeTypeId: "", 
        challengeName: "",
        challengeDesc: "",
        challengeDimensions: [{ challengeHeadId: "", challengeDimensionName: "", challengeDimensionDesc: "" }],
        challengeIntro: "",  // 18 Aug 2024
        challengeOutro: "",  // 18 Aug 2024
        targetAudience: [{ audience: "" }],  // 18 Aug 2024
        challengeScenarios: [{ scenario: "" }],  // 18 Aug 2024
        challengeInstructions: [{ instruction: "" }],  // 18 Aug 2024
        aboutMe: "", 
        pic: "", 
      });
  
      // navigate('/challenges');
      setActiveAdminView('challenges'); // 28 Feb 2025
    }
    
    },[isSuccess])// ✅ 28 Feb 2025 - Removed `navigate` since it's no longer used.

  // 4 - Form field updates / change handling
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "pic") {
      setFormData(prevState => ({ ...prevState, pic: files[0] }));
    } else {
      setFormData(prevState => ({ ...prevState, [name]: value }));
    }
  };

  // 5a - Generic Handle Add Field Function
  const handleAdd = (fieldName, newItem) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: [...prevState[fieldName], newItem]
    }));
  };

  // 5b- Generic Handle Remove Field Function
  const handleRemove = (fieldName, index) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: prevState[fieldName].filter((_, i) => i !== index)
    }));
  };

  // 5c - Generic Handle Field Change Function
  const handleFieldChange = (fieldName, index, updatedItem) => {
    const updatedItems = formData[fieldName].map((item, i) => i === index ? updatedItem : item);
    setFormData({ ...formData, [fieldName]: updatedItems });
  };

  // 5d- Wrapper functions for specific fields
  const handleAddDimension = () => handleAdd('challengeDimensions', { challengeHeadId: "", challengeDimensionName: "", challengeDimensionDesc: "" });
  const handleRemoveDimension = (index) => handleRemove('challengeDimensions', index);
  // const handleDimensionChange = (index, field, value) => {
  //   const updatedDimension = { ...formData.challengeDimensions[index], [field]: value };
  //   // const updatedDimension = {
  //   //   ...formData.challengeDimensions[index],
  //   //   challengeDimId: {
  //   //     ...formData.challengeDimensions[index].challengeDimId,
  //   //     [field]: value,
  //   //   },
  //   // };                                                                    // 20 Aug 2024
  //   handleFieldChange('challengeDimensions', index, updatedDimension);
  // };

  const handleDimensionChange = (index, field, value) => {
    const dimension = formData.challengeDimensions[index];
    // console.log('dimension', dimension)
    
    // If challengeDimId exists, update its fields
    if (dimension.challengeDimId) {
        const updatedDimension = {
            ...dimension,
            challengeDimId: {
                ...dimension.challengeDimId,
                [field]: value,
            },
        };
        // console.log('updatedDimension', updatedDimension)
        handleFieldChange('challengeDimensions', index, updatedDimension);
    } else {
        // If challengeDimId does not exist, update the top-level fields
        const updatedDimension = {
            ...dimension,
            [field]: value,
        };
        handleFieldChange('challengeDimensions', index, updatedDimension);
    }
};


  // 5f - Handle Editor Change for Scenarios - 18 Aug 2024
  const handleEditorChange = (index, content) => {
    const updatedScenarios = formData.challengeScenarios.map((item, i) => 
      i === index ? { ...item, scenario: content } : item
    );
    setFormData(prevState => ({
      ...prevState,
      challengeScenarios: updatedScenarios
    }));
  };

  // 6 - Form Submit Logic -- updated -- 21 Aug 2024 - for challengeDimId
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare challengeDimensions with correct format
    const formattedDimensions = formData.challengeDimensions?.map(dimension => {
      if (dimension?.challengeDimId && typeof dimension.challengeDimId === 'object') {
          return {
              challengeDimId: dimension?.challengeDimId._id,  // Extract the _id from challengeDimId object
              challengeHeadId: dimension?.challengeDimId.challengeHeadId,  // Keep other fields
              challengeDimensionName: dimension?.challengeDimId.challengeDimensionName,  // Keep other fields
              challengeDimensionDesc: dimension?.challengeDimId.challengeDimensionDesc,  // Keep other fields
              _id: dimension?._id // Keep the existing _id
              // ...dimension
          };
      }
      return dimension;
    });
    // console.log('formatted dimensions', formattedDimensions)


    // Initialize an object to collect the form data
    const challengeData = {
      challengeTypeId: formData.challengeTypeId,
      challengeName: formData.challengeName,
      challengeDesc: formData.challengeDesc,
      // challengeDimensions: formData.challengeDimensions,
      challengeDimensions: formattedDimensions,                       // 21 Aug 2024
      challengeIntro: formData.challengeIntro,                        // 18 Aug 2024
      challengeOutro: formData.challengeOutro,                        // 18 Aug 2024
      targetAudience: formData.targetAudience,                        // 18 Aug 2024
      challengeScenarios: formData.challengeScenarios,                // 18 Aug 2024
      challengeInstructions: formData.challengeInstructions,          // 18 Aug 2024
      aboutMe: formData.aboutMe,
      id: id,
    };
    // console.log('challengeData to be updated:', challengeData)
    // console.log('challenge Dimensions to be updated:', challengeData.challengeDimensions)

    if (formData.pic) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        challengeData.pic = reader.result;
        try {
          await updateChallenge(challengeData).unwrap();
        } catch (error) {
          console.error(error);
        }
      };
      reader.readAsDataURL(formData.pic);
    } else {
      try {
        await updateChallenge(challengeData).unwrap();
      } catch (error) {
        console.error(error);
      }
    }
  };

  // 7 - Handle Cancel
  const handleCancel = () => {
    // navigate('/challenges');
    setActiveAdminView('challenges'); // 28 Feb 2025
  };

  // 8 - Handle Clear
  const handleClear = () => {
    setFormData({
      challengeTypeId: "", 
      challengeName: "",
      challengeDesc: "",
      challengeDimensions: [{ challengeHeadId: "", challengeDimensionName: "", challengeDimensionDesc: "" }],
      challengeIntro: "",  // 18 Aug 2024
      challengeOutro: "",  // 18 Aug 2024
      targetAudience: [{ audience: "" }],  // 18 Aug 2024
      challengeScenarios: [{ scenario: "" }],  // 18 Aug 2024
      challengeInstructions: [{ instruction: "" }],  // 18 Aug 2024
      aboutMe: "", 
      pic: "", 
    });
  };



  return (
    <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
      {isLoading && <PulseLoader color={"#ea580c"} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">
          {error?.data?.message}
        </p>
      )}
      <div className="bg-white p-1 m-1 rounded">
        <p className="p-1 m-1 font-bold font-poppins text-xl">Update a Challenge</p>

        <div className="bg-gray-200 rounded m-1 p-1">
          <form
            onSubmit={handleSubmit}
            className="m-1 p-1 text-xs font-poppins"
          >
            <div id="challengeTypeSelector">
              <select
                name="challengeTypeId"
                id="challengeTypeId"
                value={formData.challengeTypeId}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              >
                {challengeTypeOptions}
              </select>
            </div>

            <div id="challengeName">
              <input
                type="text"
                name="challengeName"
                placeholder="Enter the challenge name here"
                value={formData.challengeName}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="challengeDesc">
              <input
                type="text"
                name="challengeDesc"
                placeholder="Enter the challenge description here"
                value={formData.challengeDesc}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            {/* Challenge Dimensions */}
            <div id="challenge-dimensions">

              <div className="m-1 p-1 border border-white rounded">
                <div>
                  <p className="font-bold mx-2 px-2 mt-2">Challenge Dimensions</p>
                  <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">Define the dimensions for this challenge</p>
                </div>

                {formData.challengeDimensions?.map((item, index) => (
                  <div key={index} className="flex flex-col mx-1">
                    <select
                      name="challengeHeadId"
                      value={item.challengeDimId?.challengeHeadId._id} // 20 Aug 2024 added challengeDimId?.
                      onChange={(e) => handleDimensionChange(index, 'challengeHeadId', e.target.value)}
                      className="w-full rounded p-2 m-1"
                    >
                      {challengeHeadOptions}
                    </select>
                    <input
                      type="text"
                      value={item.challengeDimId?.challengeDimensionName} // 20 Aug 2024 added challengeDimId?.
                      onChange={(e) => handleDimensionChange(index, 'challengeDimensionName', e.target.value)}
                      placeholder="Enter dimension name"
                      className="w-full rounded p-2 m-1 text-xs"
                    />
                    <input
                      type="text"
                      value={item.challengeDimId?.challengeDimensionDesc} // 20 Aug 2024 added challengeDimId?.
                      onChange={(e) => handleDimensionChange(index, 'challengeDimensionDesc', e.target.value)}
                      placeholder="Enter dimension description"
                      className="w-full rounded p-2 m-1 text-xs"
                    />
                    <div className="flex justify-end">
                      <button
                        type="button"
                        onClick={() => handleRemoveDimension(index)}
                        className="rounded bg-red-500 m-1 p-1"
                      >
                        <MinusIcon className="h-5 w-5 text-white" />
                      </button>
                    </div>
                  </div>
                ))}

                <div className="flex justify-start items-center m-1">
                  <p className="font-bold text-xs m-1 p-1">Add Dimension</p>
                    <button
                    type="button"
                    onClick={handleAddDimension}
                    >
                    <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                  </button>
                  
                </div>
              </div>
            </div>

                {/*  17 Aug 2024 - Target Audience List */}

                <div id="target-audience">
                {/* Similar to how you handle other arrays */}
                <div className="m-1 p-1 border border-white rounded">
                    <div>
                      <p className="font-bold mx-2 px-2 mt-2">Target Audience</p>
                      <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">Who is this challenge for?</p>
                    </div>

                    {formData.targetAudience?.map((item, index) => (
                          <div key={index} className="flex justify-start items-center mx-1">
                              <input
                                  type="text"
                                  value={item.audience}
                                  onChange={(e) => handleFieldChange('targetAudience', index, { audience: e.target.value })}
                                  placeholder="Enter a target audience"
                                  className="w-full rounded p-2 m-1 text-xs"
                              />
                              <div className="rounded bg-red-500 m-1 p-1">
                                  <button type="button" onClick={() => handleRemove('targetAudience', index)}>
                                      <MinusIcon className="h-5 w-5 text-white" />
                                  </button>
                              </div>
                          </div>
                      ))}
                      <div className="flex justify-start items-center m-1">
                          <p className="font-bold text-xs m-1 p-1">Add</p>
                          <button type="button" onClick={() => handleAdd('targetAudience', { audience: "" })}>
                              <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                          </button>
                      </div>
                  </div>
            </div>

            {/*  17 Aug 2024 - Challenge Scenarios */}
            <div id="challenge-scenarios">
              <div className="m-1 p-1 border border-white rounded">
                    <div>
                      <p className="font-bold mx-2 px-2 mt-2">Challenge Scenarios</p>
                      <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">Describe a situation or a context for this challenge.</p>
                    </div>

                    {formData.challengeScenarios.map((item, index) => (
                        <div key={index} className="flex justify-start items-center mx-1">
                            {/* <textarea
                                value={item.scenario}
                                onChange={(e) => handleFieldChange('challengeScenarios', index, { scenario: e.target.value })}
                                placeholder="Enter a scenario"
                                className="w-full rounded p-2 m-1 text-xs"
                            /> */}
                            <EtContentEditorLight2
                                value={item.scenario}
                                handleChange={(content) => handleEditorChange(index, content)}
                                placeholderText="Enter a scenario"
                                editorKey={`${scenarioEditorKey}-${index}`}  // Ensure unique key for each editor
                              />


                            <div className="rounded bg-red-500 m-1 p-1">
                                <button type="button" onClick={() => handleRemove('challengeScenarios', index)}>
                                    <MinusIcon className="h-5 w-5 text-white" />
                                </button>
                            </div>
                        </div>
                    ))}
                    <div className="flex justify-start items-center m-1">
                        <p className="font-bold text-xs m-1 p-1">Add</p>
                        <button type="button" onClick={() => handleAdd('challengeScenarios', { scenario: "" })}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                        </button>
                    </div>
                </div>
            </div>

            {/*  17 Aug 2024 - Instruction List */}

            <div id="instruction-list">
                {/* Similar to how you handle other arrays */}
                <div className="m-1 p-1 border border-white rounded">
                    <div>
                      <p className="font-bold mx-2 px-2 mt-2">Challenge Instructions</p>
                      <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">What the audience needs to do?</p>
                    </div>

                    {formData.challengeInstructions?.map((item, index) => (
                          <div key={index} className="flex justify-start items-center mx-1">
                              <input
                                  type="text"
                                  value={item.instruction}
                                  onChange={(e) => handleFieldChange('challengeInstructions', index, { instruction: e.target.value })}
                                  placeholder="Enter an instruction"
                                  className="w-full rounded p-2 m-1 text-xs"
                              />
                              <div className="rounded bg-red-500 m-1 p-1">
                                  <button type="button" onClick={() => handleRemove('challengeInstructions', index)}>
                                      <MinusIcon className="h-5 w-5 text-white" />
                                  </button>
                              </div>
                          </div>
                      ))}
                      <div className="flex justify-start items-center m-1">
                          <p className="font-bold text-xs m-1 p-1">Add</p>
                          <button type="button" onClick={() => handleAdd('challengeInstructions', { audience: "" })}>
                              <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                          </button>
                      </div>
                  </div>
            </div>

            <div id="challengeIntro">                            {/* 17 Aug 2024 */}
                <textarea
                    name="challengeIntro"
                    placeholder="Enter challenge introduction here"
                    value={formData.challengeIntro}
                    onChange={handleChange}
                    className="w-full rounded p-2 m-1"
                />
            </div>

            <div id="challengeOutro">                           {/* 17 Aug 2024 */}
                <textarea
                    name="challengeOutro"
                    placeholder="Enter challenge outro here"
                    value={formData.challengeOutro}
                    onChange={handleChange}
                    className="w-full rounded p-2 m-1"
                />
            </div>

            <div id="aboutMe">
              <input
                type="text"
                name="aboutMe"
                placeholder="Add something about this challenge"
                value={formData.aboutMe}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="pic">
              <input
                type="file"
                name="pic"
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Update"}
                type={"create"}
                handleClick={handleSubmit}
              />
              <EtBtnFormBasic
                name={"Clear"}
                type={"clear"}
                handleClick={handleClear}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EtUpdateChallenge;
