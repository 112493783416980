// Function to calculate and format the time difference -- 03 Jun 2024 --
// Author - Atul Pandey 

const getTimeSince = (date) => {
    const now = new Date()
    const diff = Math.floor((now - new Date(date)) / 1000) // difference in seconds

    const timeMapping = [
      { limit: 60, text: "Just now" },
      { limit: 3600, text: (d) => `${Math.floor(d / 60)} min${Math.floor(d / 60) > 1 ? 's' : ''} ago` },
      { limit: 86400, text: (d) => `${Math.floor(d / 3600)} hr${Math.floor(d / 3600) > 1 ? 's' : ''} ago` },
      { limit: 604800, text: (d) => `${Math.floor(d / 86400)} day${Math.floor(d / 86400) > 1 ? 's' : ''} ago` },
      { limit: 2419200, text: (d) => `${Math.floor(d / 604800)} week${Math.floor(d / 604800) > 1 ? 's' : ''} ago` },
      { limit: 29030400, text: (d) => `${Math.floor(d / 2419200)} month${Math.floor(d / 2419200) > 1 ? 's' : ''} ago` },
      { limit: Infinity, text: (d) => `${Math.floor(d / 29030400)} year${Math.floor(d / 29030400) > 1 ? 's' : ''} ago` }
    ]

    for (const { limit, text } of timeMapping) {
      if (diff < limit) return typeof text === 'function' ? text(diff) : text
    }
  }

  export {getTimeSince}