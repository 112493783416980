import React, {useState} from 'react'
import { useNavigate } from 'react-router'
import useTitle from '@/hooks/useTitle'
import { useGetTrailsQuery } from './trailsApiSlice'
import { addIcon, editIcon, deleteIcon, viewIcon } from '@/assets/img/imgAssets'

const EtTrailList = ({setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }) => {
  // First Created - 02 Apr 2024
  // Author - Atul Pandey
  // Updated - 01 Mar 2025 - updated the comp with props - { setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }
  // Updated - 01 Mar 2025 - switched from navigate to onAdd, onUpdate, onDelete, setSelectedEntityId

  const navigate = useNavigate()
  
  useTitle('Et Trails')
  
  const [showDetails, setShowDetails] = useState(false)
  const [trailName, setTrailName ] = useState('')
  
  // Use the RTK Query hook to get question sets
  const { data: trails, error, isLoading } = useGetTrailsQuery()
 
  // Optional: handle loading and error states
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error occurred: {error.error && error.error.message ? error.error.message : 'Unknown error'}</div>;

  const displayTrailSessions = (trailName) => {
    setShowDetails(true)
    setTrailName(trailName)
  }

  // Create an object for setting inner HTML
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
  }


  return (
    <div className="bg-gray-200 p-1 mx-auto my-5 rounded-lg border border-white shadow-none shadow-black">
        <div className='flex justify-start items-center p-1 m-1'>
            <div><p className="p-1 m-2 font-bold font-poppins text-xl">Trails</p></div>
            <div>
              <img 
                src={addIcon} 
                alt="" 
                className='h-10 w-10 cursor-pointer' 
                // onClick={() => navigate('/trails/create') } 
                onClick={onAdd} // 01 Mar 2025
              />
              </div>
        </div>
        
        
        <div className='flex justify-center border-t m-1 p-1 pt-4 border-white'>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
                {trails && trails.ids.map((id) => {
                    const {trailName, trailDesc, pic} = trails.entities[id]
                return (
                <div key={id} className="bg-white focus:bg-orange-400 rounded-lg overflow-hidden shadow-md shadow-slate-600">
                    <div className='flex justify-start items-center p-1 my-1 border-b'>
                        <div className='flex justify-center items-center p-1 rounded-full h-6 w-6 hover:bg-gray-100 cursor-pointer'><img src={viewIcon} alt="" className='h-6 w-6' onClick={() => displayTrailSessions(trailName)}/></div>
                        
                        <div className='flex justify-center items-center p-1 rounded-full h-6 w-6 hover:bg-gray-100 cursor-pointer'>
                            <img 
                              src={editIcon} 
                              alt="" 
                              className='h-6 w-6' 
                              // onClick={() => navigate(`/trails/update/${id}`)}
                              onClick={() => onUpdate(id)} // 01 Mar 2025 
                            />
                          </div>
                        
                        <div className='flex justify-center items-center p-1 rounded-full h-6 w-6 hover:bg-gray-100 cursor-pointer'>
                            <img 
                              src={deleteIcon} 
                              alt="" 
                              className='h-6 w-6' 
                              // onClick={() => navigate(`/trails/delete/${id}`)} 
                              onClick={() => onDelete(id)} // 01 Mar 2025
                            />
                        </div>
                        
                    </div>
                    <div className="flex flex-col items-center justify-center p-4">
                    <div className='rounded-full h-16 w-16 hover:bg-gray-50 p-2'><img src={pic} alt="Trail" className="rounded mx-auto h-14 w-14"/></div>
                    <h2 className="mt-2 text-center font-bold text-xl">{trailName}</h2>
                    </div>
                    <div className="border-t p-4">
                    <p className='text-xs text-gray-500 font-poppins' dangerouslySetInnerHTML={createMarkup(
                      ` ${trailDesc}`
                    )}></p>
                    </div>
                </div>
                )

                })}
            </div>
      </div>
      {/* {showDetails && (
      <div className='m-5 border-t border-white'>
      <EtQuestionListBySet setname={qsetName}/>
      </div>
      )} */}
    
    </div>
  )
}

export default EtTrailList