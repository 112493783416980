
    import React from 'react';
    
    const EtImprint360Icon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" fill="currentColor">
          <g transform="translate(-477 -100)"><path d="M561.828 145.092C561.828 152.136 556.116 157.848 549.074 157.848 542.025 157.848 536.317 152.136 536.317 145.092 536.317 138.045 542.026 132.336 549.074 132.336 556.116 132.336 561.828 138.044 561.828 145.092Z" fillRule="evenodd"/><path d="M549.076 111.22C529.118 111.22 520.512 119.798 516.807 126.994 510.522 139.202 512.99 156.452 524.352 179.73 532.014 195.424 541.018 208.096 541.397 208.628 543.168 211.108 546.026 212.579 549.073 212.579 552.119 212.579 554.979 211.108 556.748 208.628 557.127 208.097 566.133 195.424 573.794 179.729 585.155 156.45 587.623 139.201 581.336 126.993 577.636 119.799 569.03 111.222 549.076 111.222ZM549.076 120.65C607.964 120.65 549.075 203.15 549.075 203.15 549.075 203.15 490.179 120.65 549.076 120.65" fillRule="evenodd"/><path d="M549.076 225.716C511.757 225.716 490.796 219.275 490.028 215.874 490.548 213.541 500.298 209.846 518.074 207.693 516.442 208.805 515.456 210.158 515.456 211.813 515.456 220.3 541.188 220.958 549.077 220.958 556.964 220.958 582.696 220.3 582.696 211.813 582.696 210.157 581.709 208.802 580.077 207.69 597.854 209.838 607.605 213.524 608.125 215.822 607.351 219.275 586.395 225.716 549.077 225.716ZM565.302 200.777C564.732 201.694 564.18 202.568 563.666 203.372 562.163 205.719 560.927 207.559 560.112 208.75 568.448 209.424 573.929 210.766 576.17 211.813 573.172 213.208 564.403 215.139 550.732 215.286 550.185 215.361 549.632 215.408 549.075 215.408 548.515 215.408 547.964 215.361 547.417 215.286 533.746 215.138 524.976 213.208 521.978 211.813 524.22 210.766 529.701 209.424 538.037 208.75 537.223 207.559 535.985 205.719 534.484 203.372 533.969 202.568 533.42 201.694 532.847 200.777 510.196 202.025 484.371 206.082 484.371 215.848 484.371 228.105 525.043 231.374 549.075 231.374 573.106 231.374 613.779 228.104 613.779 215.848 613.778 206.082 587.955 202.026 565.303 200.777Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtImprint360Icon1SVGComp;
    