import React from 'react'
import EtCreateUserActionComp from '../../../features/userActions/EtCreateUserActionComp'
import {
  XCircleIcon
} from "@heroicons/react/24/solid"

const EtModalCreateUserAction = ({ isOpen, onClose, userId, trailId, sessionId, sessionTypeName}) => {

  // First Created - 11 Jun 2024
  // Author - Atul Pandey

  if (!isOpen) return null


  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 z-70 flex justify-center items-center font-poppins ">
      <div className="bg-black p-5 rounded-lg shadow-lg shadow-black relative">
        <div
          className="absolute top-0 right-0 m-2 font-bold rounded-full cursor-pointer h-7 w-7 flex justify-center items-center"
          onClick={onClose}
        >
          <XCircleIcon className='text-orange-600 rounded-full hover:text-red-600 h-7 w-7'/>
        </div>
        <EtCreateUserActionComp userId={userId} trailId={trailId} sessionId={sessionId} sessionTypeName={sessionTypeName}/>
      </div>
    </div>
  )
}

export default EtModalCreateUserAction