import {
etBlogImg1, 
etBlogImg10, 
etBlogImg11, 
etBlogImg12, 
etBlogImg13, 
etBlogImg2, 
etBlogImg3, 
etBlogImg4, 
etBlogImg5, 
etBlogImg6, 
etBlogImg7, 
etBlogImg8, 
etBlogImg9,
founderPic,
} from '@/assets/img/imageAssets'

export const videoList = [
    {
      name: "g_SkCJnU0jE",
      thumb: founderPic,
      title: "Introduction to Trinity",
      description: "A deep dive into the Trinity ecosystem and its core purpose.",
      category: "Technology",
      dateCreated: "2025-03-09",
      stats: { views: 1245, likes: 98, comments: 23 },
    },
    {
      name: "PLioFAEdnXE",
      title: "The Future of AI",
      thumb: founderPic,
      description: "Exploring the latest advancements in AI and its future impact.",
      category: "Innovation",
      dateCreated: "2025-02-25",
      stats: { views: 3456, likes: 210, comments: 45 },
    },
    {
      name: "V68vBaLpZ0Y",
      title: "Trinity Community Insights",
      thumb: founderPic,
      description: "A look at how the Trinity community is shaping the industry.",
      category: "Community",
      dateCreated: "2025-01-15",
      stats: { views: 874, likes: 67, comments: 12 },
    },
    {
      name: "8Ydg2ZOapws",
      title: "Next-Gen Technologies",
      thumb: founderPic,
      description: "Exploring next-gen innovations and their global impact.",
      category: "Technology",
      dateCreated: "2025-03-02",
      stats: { views: 5290, likes: 320, comments: 87 },
    },
    {
      name: "V68vBaLpZ0Y",
      title: "Trinity Community Insights",
      thumb: founderPic,
      description: "A look at how the Trinity community is shaping the industry.",
      category: "Community",
      dateCreated: "2025-01-15",
      stats: { views: 874, likes: 67, comments: 12 },
    },
    {
      name: "eXK4DaLt8MY",
      title: "Growth Trail With Eternity",
      thumb: founderPic,
      description: "Are you ready to unlock yourself?",
      category: "Self Transformation",
      dateCreated: "2025-03-02",
      stats: { views: 5290, likes: 320, comments: 87 },
    },
    {
      name: "g_SkCJnU0jE",
      thumb: etBlogImg1,
      title: "Introduction to Trinity",
      description: "A deep dive into the Trinity ecosystem and its core purpose.",
      category: "Technology",
      dateCreated: "2025-03-09",
      stats: { views: 1245, likes: 98, comments: 23 },
    },
    {
      name: "PLioFAEdnXE",
      title: "The Future of AI",
      thumb: etBlogImg7,
      description: "Exploring the latest advancements in AI and its future impact.",
      category: "Innovation",
      dateCreated: "2025-02-25",
      stats: { views: 3456, likes: 210, comments: 45 },
    },
    {
      name: "V68vBaLpZ0Y",
      title: "Trinity Community Insights",
      thumb: etBlogImg5,
      description: "A look at how the Trinity community is shaping the industry.",
      category: "Community",
      dateCreated: "2025-01-15",
      stats: { views: 874, likes: 67, comments: 12 },
    },
    {
      name: "8Ydg2ZOapws",
      title: "Next-Gen Technologies",
      thumb: etBlogImg9,
      description: "Exploring next-gen innovations and their global impact.",
      category: "Technology",
      dateCreated: "2025-03-02",
      stats: { views: 5290, likes: 320, comments: 87 },
    },
    {
      name: "V68vBaLpZ0Y",
      title: "Trinity Community Insights",
      thumb: etBlogImg5,
      description: "A look at how the Trinity community is shaping the industry.",
      category: "Community",
      dateCreated: "2025-01-15",
      stats: { views: 874, likes: 67, comments: 12 },
    },
    {
      name: "eXK4DaLt8MY",
      title: "Growth Trail With Eternity",
      thumb: etBlogImg13,
      description: "Are you ready to unlock yourself?",
      category: "Self Transformation",
      dateCreated: "2025-03-02",
      stats: { views: 5290, likes: 320, comments: 87 },
    },
  ];
  