import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import useTitle from "@/hooks/useTitle";
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic";
import PulseLoader from "react-spinners/PulseLoader";
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';
import { useGetContactTypesQuery } from "../contactTypes/contactTypesApiSlice";
import { useCreateNewContactMutation } from "./contactsApiSlice";
import { useGetContactOrganizationsQuery } from '@/features/contactOrganizations/contactOrganizationsApiSlice'
import { useGetOrgRolesQuery } from "../orgRoles/orgRolesApiSlice";
import { useGetOrgTitlesQuery } from "../orgTitles/orgTitlesApiSlice"

const EtCreateContact = ({setActiveAdminView, userId, userOrgId}) => {
  // First Created - 28 Sep 2024
  // Author - Atul Pandey
  // Updated - 01 Oct 2024 - Added org roles and title
  // Updated - 07 Mar 2025 - updated the comp with props - { setActiveAdminView, userId, userOrgId }
  // Updated - 07 Mar 2025 - switched from navigate to setActiveAdminView

  // 0 - Set Page Title
  useTitle('Create New Contact');

  // 1 - set page navigation
  const navigate = useNavigate();
  const contactOwnerId=userId
  // const userOrgId = userOrgId
 
  // 2 - set the mutation to create new contact data
  const [createNewContact, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewContactMutation();

  // 3a - Get contact types data for options
  const { contactTypes } = useGetContactTypesQuery('contactTypesList', {
    selectFromResult: ({ data }) => ({
      contactTypes: data?.ids.map(id => data?.entities[id]),
    }),
  });

  // 3b - Fetch Org Roles and Org Titles - 01 Oct 2024
  const { orgRoles } = useGetOrgRolesQuery("orgRolesList", {
  selectFromResult: ({ data }) => ({
    orgRoles: data?.ids.map(id => data?.entities[id]),
  }),
  });

  const { orgTitles } = useGetOrgTitlesQuery("orgTitlesList", {
  selectFromResult: ({ data }) => ({
    orgTitles: data?.ids.map(id => data?.entities[id]),
  }),
  });

  // 4 - Create options to select a contact type from list of contact types
  const contactTypeOptions = contactTypes ? [
    <option key="placeholder" value="" disabled selected>
      Select a Contact Type
    </option>,
    ...contactTypes?.map(contactType => (
      <option key={contactType.id} value={contactType.id}>
        {contactType.contactTypeName}
      </option>
    )),
  ] : [];

  // 5 - Get contact organizations data for options and filter by userOrgId
const { contactOrganizations } = useGetContactOrganizationsQuery("contactOrganizationsList", {
  selectFromResult: ({ data }) => ({
    contactOrganizations: data?.ids
      .map(id => data?.entities[id])
      .filter(contactOrg =>
        contactOrg.availableToUserOrgs.some(org => org.userOrgId._id === userOrgId)
      ),
  }),
});
// console.log('Contact Organizations', contactOrganizations)

// 6a - Create options for contact organizations
const contactOrganizationOptions = contactOrganizations ? [
  <option key="placeholder" value="" disabled selected>
    Select a Contact Organization
  </option>,
  ...contactOrganizations?.map(contactOrg => (
    <option key={contactOrg.id} value={contactOrg.id}>
      {contactOrg.contactOrganizationName}
    </option>
  )),
] : [];

// 6b - Create options for Org Roles - 01 Oct 2024
const orgRoleOptions = orgRoles ? [
  <option key="placeholder" value="" disabled>
    Select a Role
  </option>,
  ...orgRoles?.map(role => (
    <option key={role.id} value={role.id}>
      {role.orgRoleName}
    </option>
  )),
] : [];

// 6c - Create options for Org Titles - 01 Oct 2024
const orgTitleOptions = orgTitles ? [
  <option key="placeholder" value="" disabled>
    Select a Title
  </option>,
  ...orgTitles?.map(title => (
    <option key={title.id} value={title.id}>
      {title.orgTitleName}
    </option>
  )),
] : [];


  // 7 - create the form data object
  const [formData, setFormData] = useState({
    contactTypeId: "",
    contactName: "",
    contactNickName: "",
    contactEmail: "",
    contactPhone: "",
    contactOrgId: "",
    contactOrgRole: "",
    contactOrgTitle: "",
    contactOwnerId: "",
    sharedWith: [{ userId: "" }],
    pic: "",
  });

  // 8 - Reset form upon successful submission
  useEffect(() => {
    if (isSuccess) {
      setFormData({
        contactTypeId: "",
        contactName: "",
        contactNickName: "",
        contactEmail: "",
        contactPhone: "",
        contactOrgId: "",
        contactOrgRole: "",
        contactOrgTitle: "",
        contactOwnerId: "",
        sharedWith: [{ userId: "" }],
        pic: "",
      });

      // navigate('/contacts');
      setActiveAdminView('contacts'); // 07 Mar 2025
    }
    
    },[isSuccess])// ✅ 07 Mar 2025 - Removed `navigate` since it's no longer used.

  // 9 - Form field updates / change handling
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "pic") {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  // 9a - Generic Handle Add Field Function
  const handleAdd = (fieldName, newItem) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: [...prevState[fieldName], newItem],
    }));
  };

  // 9b - Generic Handle Remove Field Function
  const handleRemove = (fieldName, index) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: prevState[fieldName].filter((_, i) => i !== index),
    }));
  };

  // 9c - Generic Handle Field Change Function
  const handleFieldChange = (fieldName, index, value) => {
    const updatedItems = formData[fieldName].map((item, i) =>
      i === index ? value : item
    );
    setFormData({ ...formData, [fieldName]: updatedItems });
  };

  // 9d - Wrapper functions for sharedWith field
  const handleAddSharedWith = () => handleAdd('sharedWith', { userId: "" });
  const handleRemoveSharedWith = (index) => handleRemove('sharedWith', index);
  const handleSharedWithChange = (index, value) => handleFieldChange('sharedWith', index, { userId: value });

  // 10 - Form Submit Logic
  const handleSubmit = async (e) => {
    e.preventDefault();

    const contactData = {
      contactTypeId: formData.contactTypeId,
      contactName: formData.contactName,
      contactNickName: formData.contactNickName,
      contactEmail: formData.contactEmail,
      contactPhone: formData.contactPhone,
      contactOrgId: formData.contactOrgId,
      contactOrgRole: formData.contactOrgRole,
      contactOrgTitle: formData.contactOrgTitle,
      contactOwnerId: contactOwnerId,
      sharedWith: formData.sharedWith,
      pic: formData.pic ? await fileToBase64(formData.pic) : undefined,
    };

    try {
      await createNewContact(contactData).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  // Utility function to convert file to base64
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  // 11 - Handle Cancel
  const handleCancel = () => {
    // navigate('/contacts');
    setActiveAdminView('contacts'); // 07 Mar 2025
  };

  // 12 - Handle Clear
  const handleClear = () => {
    setFormData({
      contactTypeId: "",
      contactName: "",
      contactNickName: "",
      contactEmail: "",
      contactPhone: "",
      contactOrgId: "",
      contactOrgRole: "",
      contactOrgTitle: "",
      contactOwnerId: "",
      sharedWith: [{ userId: "" }],
      pic: "",
    });
  };

  return (
    <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
      {isLoading && <PulseLoader color={"#ea580c"} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">
          {error?.data?.message}
        </p>
      )}
      <div className="bg-white p-1 m-1 rounded">
        <p className="p-1 m-1 font-bold font-poppins text-xl">Add a Contact</p>

        <div className="bg-gray-200 rounded m-1 p-1">
          <form onSubmit={handleSubmit} className="m-1 p-1 text-xs font-poppins">

            <div id="contactTypeSelector">
              <select
                name="contactTypeId"
                id="contactTypeId"
                value={formData.contactTypeId}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              >
                {contactTypeOptions}
              </select>
            </div>

            <div id="contactName">
              <input
                type="text"
                name="contactName"
                placeholder="Enter the contact name here"
                value={formData.contactName}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="contactNickName">
              <input
                type="text"
                name="contactNickName"
                placeholder="Enter the contact nickname here"
                value={formData.contactNickName}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="contactOrgId">
              <select
                name="contactOrgId"
                id="contactOrgId"
                value={formData.contactOrgId}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              >
                {contactOrganizationOptions}
              </select>
            </div>

            <div id="contactOrgRole">
              <select
                name="contactOrgRole"
                id="contactOrgRole"
                value={formData.contactOrgRole}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              >
                {orgRoleOptions}
              </select>
            </div>

            <div id="contactOrgTitle">
              <select
                name="contactOrgTitle"
                id="contactOrgTitle"
                value={formData.contactOrgTitle}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              >
                {orgTitleOptions}
              </select>
            </div>

            <div id="contactEmail">
              <input
                type="email"
                name="contactEmail"
                placeholder="Enter the contact email here"
                value={formData.contactEmail}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="contactPhone">
              <input
                type="text"
                name="contactPhone"
                placeholder="Enter the contact phone here"
                value={formData.contactPhone}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            {/* Shared With Users */}
            {/* <div id="sharedWith">
              <div className="m-1 p-1 border border-white rounded">
                <div>
                  <p className="font-bold mx-2 px-2 mt-2">Shared With</p>
                  <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">
                    Who can view this contact?
                  </p>
                </div>
                {formData.sharedWith.map((item, index) => (
                  <div key={index} className="flex justify-start items-center mx-1">
                    <input
                      type="text"
                      value={item.userId}
                      onChange={(e) => handleSharedWithChange(index, e.target.value)}
                      placeholder="Enter user id"
                      className="w-full rounded p-2 m-1 text-xs"
                    />
                    <div className="rounded bg-red-500 m-1 p-1">
                      <button type="button" onClick={() => handleRemoveSharedWith(index)}>
                        <MinusIcon className="h-5 w-5 text-white" />
                      </button>
                    </div>
                  </div>
                ))}
                <div className="flex justify-start items-center m-1">
                  <p className="font-bold text-xs m-1 p-1">Add</p>
                  <button type="button" onClick={handleAddSharedWith}>
                    <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                  </button>
                </div>
              </div>
            </div> */}

            {/* <div id="pic">
              <input
                type="file"
                name="pic"
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div> */}

            <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Create"}
                type={"create"}
                handleClick={handleSubmit}
              />
              <EtBtnFormBasic
                name={"Clear"}
                type={"clear"}
                handleClick={handleClear}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EtCreateContact;