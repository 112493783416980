import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import { useCreateNewOrgSubscriptionMutation } from "./orgSubscriptionsApiSlice"
import { useGetOrganizationsQuery } from "../organizations/organizationsApiSlice"
import { useGetSubsPacksQuery } from "../subsPacks/subscriptionPacksApiSlice"

const EtCreateOrgSubscription = ({setActiveAdminView}) => {

   // First Created - 13 May 2024
  // Author - Atul Pandey
  // Updated - 01 Mar 2025 - updated the softSkillFunctionArea with props - { id, setActiveAdminView}
  // Updated - 01 Mar 2025 - switched from navigate to setActiveAdminView

  // 0 - Set Page Title
  useTitle('Create New Org Subscription')
  
  // 1 - set page navigations
  const navigate = useNavigate()

  // 2 - set the mutation to create the new section data
  const[createNewOrgSubscription, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewOrgSubscriptionMutation()

  // 3 - Get organization data for options
  const { organizations } = useGetOrganizationsQuery('organizationsList', {
    selectFromResult: ( { data }) => ({
      organizations: data?.ids.map(id => data?.entities[id])
    })
  })

  // 4 - Get orgSubscription types data for options
  const { subsPacks } = useGetSubsPacksQuery('subsPacksList', {
    selectFromResult: ( { data }) => ({
      subsPacks: data?.ids.map(id => data?.entities[id])
    })
  })

  // console.log('organizations:', organizations)
  // console.log('session types:', subsPacks)

  // 5 - Create options to select a organization from list of organizations
  const organizationOptions = organizations ? [
  <option key="placeholder" value="" disabled selected>
    Select an organization
  </option>,
  ...organizations?.map(organization => (
    <option key={organization.id} value={organization.id}>
      {organization.organizationName}
    </option>
  ))
  ] : []

  // 6 - Create options to select a session type from list of session types
  const subsPackOptions = subsPacks ? [
  <option key="placeholder" value="" disabled selected>
    Select a Subscription Pack
  </option>,
  ...subsPacks?.map(subsPack => (
    <option key={subsPack.id} value={subsPack.id}>
      {subsPack.subscriptionPackName}
    </option>
  ))
  ] : []

  // 7 - create the form data object
  const [formData, setFormData] = useState({
    orgId: "", 
    subsPackId: "", 
    orgSubscriptionName: "",
    aboutMe: "", 
    pic: "", 
  })

  // 8 - Reset form upon successful submission
  useEffect(() => {
  if(isSuccess) {
    setFormData({
        orgId: "", 
        subsPackId: "", 
        orgSubscriptionName: "",
        aboutMe: "", 
        pic: "", 
    })

    // navigate('/orgSubscriptions')
    setActiveAdminView('orgSubscriptions'); // 01 Mar 2025
  }
  
  },[isSuccess])// ✅ 01 Mar 2025 - Removed `navigate` since it's no longer used.

  // 9 - Form field updates / change handling
  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData(prevState => ({ ...prevState, pic: files[0] }))
    }  else {
      setFormData(prevState => ({ ...prevState, [name]: value }))
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
  
    // Initialize an object to collect the form data
    const orgSubscriptionData = {
      orgId: formData.orgId,
      subsPackId: formData.subsPackId,
      orgSubscriptionName: formData.orgSubscriptionName,
      aboutMe: formData.aboutMe,
    }
  
    //console.log('session Name:',orgSubscriptionData.sessionName)
    //console.log('session Planned Date Slot:',orgSubscriptionData.sessionPlannedDateSlot)
  
    if (formData.pic) {
      // Convert the image to a base64 string
      const reader = new FileReader()
      reader.onloadend = async () => {
        // Once conversion is done, add the base64 string to your data object
        orgSubscriptionData.pic = reader.result
  
        // Now, send the data object to the server
        try {
          console.log("Form submitted.")
          await createNewOrgSubscription(orgSubscriptionData).unwrap() // Dispatch the RTK Query mutation
        } catch (error) {
          console.error(error)
        }
      }
      reader.readAsDataURL(formData.pic)
    } else {
      // No image, send other data as is
      try {
        console.log("Form submitted.")
        await createNewOrgSubscription(orgSubscriptionData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    }
  }
  
    // 11 - Handle Cancel
    const handleCancel = () => {
     // navigate('/orgSubscriptions')
    setActiveAdminView('orgSubscriptions'); // 01 Mar 2025
    }
  
  // 12 - Handle Clear
    const handleClear = () => {
      setFormData({
        orgId: "", 
        subsPackId: "", 
        orgSubscriptionName: "",
        aboutMe: "", 
        pic: "", 
      })
    }

    return (
        <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
          {isLoading && <PulseLoader color={"#ea580c"} />}
          {isError && (
            <p className="text-orange-700 font-semi-bold py-2">
              {error?.data?.message}
            </p>
          )}
          <div className="bg-white p-1 m-1 rounded">
            <p className="p-1 m-1 font-bold font-poppins text-xl">Add an Org Subscription</p>
    
            <div className="bg-gray-200 rounded m-1 p-1">
              <form
                onSubmit={handleSubmit}
                className="m-1 p-1 text-xs font-poppins"
              >
                <div id="trailSelector">
                  <select
                    name="orgId"
                    id="orgId"
                    value={formData.orgId}
                    onChange={handleChange}
                    className="w-full rounded p-2 m-1"
                  >
                    {organizationOptions}
                  </select>
                </div>
    
                <div id="subsPackSelector">
                  <select
                    name="subsPackId"
                    id="subsPackId"
                    value={formData.subsPackId}
                    onChange={handleChange}
                    className="w-full rounded p-2 m-1"
                  >
                    {subsPackOptions}
                  </select>
                </div>
    
                <div id="orgSubscriptionName">
                  <input
                    type="text"
                    name="orgSubscriptionName"
                    placeholder="Enter the org subscription name here"
                    value={formData.orgSubscriptionName}
                    onChange={handleChange}
                    className="w-full rounded p-2 m-1"
                  />
                </div>
    
                <div id="aboutMe">
                  <input
                    type="text"
                    name="aboutMe"
                    placeholder="Add something about the personality of this subscription"
                    value={formData.aboutMe}
                    onChange={handleChange}
                    className="w-full rounded p-2 m-1"
                  />
                </div>
    
                <div id="pic">
                  <input
                    type="file"
                    name="pic"
                    onChange={handleChange}
                    className="w-full rounded p-2 m-1"
                  />
                </div>
    
    
                <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
                  <EtBtnFormBasic
                    name={"Create"}
                    type={"create"}
                    handleClick={handleSubmit}
                  />
                  <EtBtnFormBasic
                    name={"Clear"}
                    type={"clear"}
                    handleClick={handleClear}
                  />
                  <EtBtnFormBasic
                    name={"Cancel"}
                    type={"cancel"}
                    handleClick={handleCancel}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )
}

export default EtCreateOrgSubscription