import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const intentionTypesAdapter = createEntityAdapter({})

const initialState = intentionTypesAdapter.getInitialState()

export const intentionTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getIntentionTypes: builder.query({
            query: () => ({
                url: '/intentionTypes/getIntentionTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedIntentionTypes = responseData.map(intentionType => {
                    intentionType.id = intentionType._id
                    return intentionType
                })
                return intentionTypesAdapter.setAll(initialState, loadedIntentionTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'IntentionType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'IntentionType', id}))
                    ]
                } else {
                    return [ { type: 'IntentionType', id: 'LIST'}]
                }
            },
        }),
        createNewIntentionType: builder.mutation({
            query: initialIntentionType => ({
                url: '/intentionTypes/createNewIntentionType',
                method: 'POST',
                body: {...initialIntentionType,}
            }),
            invalidatesTags: [
                { type: 'IntentionType', id: "LIST" }
            ] 
        }),
        updateIntentionType: builder.mutation({
            query: initialIntentionType => ({
                url: '/intentionTypes/updateIntentionType',
                method: 'PATCH',
                body: {...initialIntentionType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'IntentionType', id: arg.id },
                { type: 'IntentionType', id: 'LIST' }
            ],
        }),
        deleteIntentionType: builder.mutation({
            query: ({id}) => ({
                url: '/intentionTypes/deleteIntentionType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'IntentionType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetIntentionTypesQuery,
    useCreateNewIntentionTypeMutation,
    useUpdateIntentionTypeMutation,
    useDeleteIntentionTypeMutation,
} = intentionTypesApiSlice

// returns the query result object
export const selectIntentionTypeResult = intentionTypesApiSlice.endpoints.getIntentionTypes.select()

// create memoized selector
const selectIntentionTypesData = createSelector(
    selectIntentionTypeResult,
    intentionTypeResult => intentionTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllIntentionTypes,
    selectById: selectIntentionTypeById,
    selectIds: selectIntentionTypeIds,
} = intentionTypesAdapter.getSelectors(state => selectIntentionTypesData(state) ?? initialState)