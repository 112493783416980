import React, { useState } from 'react';
import entityMap from '@/data/admin/entityMap';
import adminMenuItems from '@/data/admin/adminMenuItems';
import entityNameMap from "@/data/admin/entityNameMap";

const EtTrinityWebAdmin = ({userOrgId="", userId=""}) => {
  // Author - Atul Pandey
  // First Created - 26 Feb 2025
  // Purpose to act as admin board for all setup activities for Et and Org Admins
  // Updated - 27 Feb 2025 - added entityNameMap to include entities which do not have type in the end
  // Updated - 07 Mar 2025 - added userOrgId for create components which need userOrgId
  // Updated - 07 Mar 2025 - added userOrgId for update components which need userOrgId

  // 1 - Manage selected admin section
  const [activeAdminView, setActiveAdminView] = useState('actionTypes');
  const [selectedEntityId, setSelectedEntityId] = useState(null);

  // 2 - Get entity type - 27 Feb 2025
  const getEntityType = (activeAdminView) => {
    return Object.keys(entityNameMap)
    .sort((a, b) => b.length - a.length) // Sort by length DESCENDING
    .find(type => 
        entityNameMap[type].some(name => 
            activeAdminView.toLowerCase().includes(name.toLowerCase())
        )
    ) || null;
  };

  // 3 - Render the corresponding component dynamically
  const renderAdminContent = () => {
    // const entityType = Object.keys(entityMap).find(type => activeAdminView.includes(type));
    const entityType = getEntityType(activeAdminView);

    if (entityType) {
        const action = activeAdminView.replace(new RegExp(entityType, "i"), "").toLowerCase().trim();
        const Component = entityMap[entityType][action] || entityMap[entityType].list;

        // Define props based on action type
        const componentProps = {
            setActiveAdminView,
            // ...(action === "update" || action === "delete" ? { id: selectedEntityId } : {}),
            // 07 Mar 2025 ✅ Pass userId and userOrgId if available
            ...(action === "update" || action === "delete"
              ? {
                  id: selectedEntityId,
                  ...(userOrgId ? { userOrgId } : {}),
                  ...(userId ? { userId } : {}),
                }
              : {}),
            ...(action === "create"
              ? {
                  ...(userOrgId ? { userOrgId } : {}),
                  ...(userId ? { userId } : {}),
                }
              : {}),
            ...(action === "" ? {  // ✅ Defaulting to list when action is empty
                onAdd: () => setActiveAdminView(`create${entityType}`),
                onUpdate: (id) => { setSelectedEntityId(id); setActiveAdminView(`update${entityType}`); },
                onDelete: (id) => { setSelectedEntityId(id); setActiveAdminView(`delete${entityType}`); },
                setSelectedEntityId
            } : {})
        };

        return <Component {...componentProps} />;
    }

    return <div>Select an admin section</div>;
};

  return (
    <div className="flex h-full bg-gray-100 p-4 rounded-lg">
      {/* Left Panel - Admin Navigation */}
      <div className="w-1/4 bg-white p-2 rounded-lg shadow-md overflow-y-auto" >
        <h2 className="text-lg font-bold mb-4 mx-3 px-3">Admin Panel</h2>
        <ul>
            {adminMenuItems.map((item, index) => (
                <div
                    key={item.key+index}
                    className={`m-1 p-1 bg-gray-100 cursor-pointer rounded-md font-semibold ${
                    (activeAdminView === item.key || item.subKeys?.includes(activeAdminView))
                        ? 'bg-orange-600 text-white' 
                        : 'text-gray-700 hover:bg-gray-200'
                    }`}
                    onClick={() => setActiveAdminView(item.key)}
                >
                    <div className='flex justify-start items-center'>
                        <p className='text-sm font-bold mx-1 px-1'>{index+1}.</p>
                        <p className='text-xs mx-1 px-1'>{item.label}</p>
                    </div>
                    
                </div>
            ))}
        </ul>
      </div>

      {/* Right Panel - Admin Content */}
      <div className="w-3/4 bg-white p-2 ml-4 rounded-lg shadow-md overflow-y-auto">
        {renderAdminContent()}
      </div>
    </div>
  );
};

export default EtTrinityWebAdmin;