import { Routes, Route } from "react-router-dom"
import EtTrailsUbuntuMain from "./pages/trails/ubuntu/EtTrailsUbuntuMain"
import EtHomeLanding from "./pages/EtHomeLanding"
import EtLearnMain from "./pages/learn/EtLearnMain"
// import EtCreateSession from "./pages/admin/sessions/EtCreateSession"
// import EtEditSession from "./pages/admin/sessions/EtEditSession"
// import EtViewSessions from "./pages/admin/sessions/EtViewSessions"
import EtTrailsSalesMain from "./pages/trails/sales/EtTrailsSalesMain"
import MyTrailInitiationTest from "./components/common/MyTrailInitiationTest"
import useTitle from "./hooks/useTitle"
import Login from "./features/auth/Login"
import SignUpNewUserForm from "./features/users/SignUpNewUserForm"
import PersistLogin from "./features/auth/PersistLogin"
import RequireAuth from "./features/auth/RequireAuth"
import { ROLES } from "./config/role" 
import Prefetch from "./features/auth/Prefetch"
import ResetPassword from "./features/auth/ResetPassword"
import ForgotPassword from './features/auth/ForgotPassword'
import EtUserPicUpload from "./features/users/EtUserPicUpload"
// import EtQuestionSetMain from "./pages/admin/qsets/EtQuestionSetMain"
// import EtQuestionSetCreate from "./pages/admin/qsets/EtQuestionSetCreate"
// import EtQuestionSetUpdate from "./pages/admin/qsets/EtQuestionSetUpdate"
// import EtQuestionSetDelete from "./pages/admin/qsets/EtQuestionSetDelete"
// import EtQuestionsMain from "./pages/admin/questions/EtQuestionsMain"
// import EtQuestionCreate from "./pages/admin/questions/EtQuestionCreate"
// import EtQuestionUpdate from "./pages/admin/questions/EtQuestionUpdate"
// import EtQuestionDelete from "./pages/admin/questions/EtQuestionDelete"
import EtSessionAboutYou from "./features/investigation/EtSessionAboutYou"
import EtSessionAboutYouList from "./features/investigation/EtSessionAboutYouList"
import EtTrailList from "./features/trails/EtTrailList"
import EtCreateTrail from "./features/trails/EtCreateTrail"
import EtUpdateTrail from "./features/trails/EtUpdateTrail"
import EtFloatUp from "./components/animation/EtFloatUp"
import EtTrail8848Banner from "./components/common/EtTrail8848Banner"
import EtDeleteTrail from "./features/trails/EtDeleteTrail"
import EtSubsPacksList from "./features/subspacks/EtSubsPacksList"
import EtCreateSubsPack from "./features/subspacks/EtCreateSubsPack"
import EtUpdateSubsPack from "./features/subspacks/EtUpdateSubsPack"
import EtDeleteSubsPack from "./features/subspacks/EtDeleteSubsPack"
import EtSessionPCI from "./features/investigation/EtSessionPCI"
import EtTabs from "./components/common/EtTabs"
import EtUserScoreTest from "./components/common/EtUserScoreTest"
import EtPixelTest from "./components/common/EtPixelTest"
import EtSvgWrapper from "./components/common/EtSvgWrapper"
import EtPixelScorecardTest from "./components/common/EtPixelScorecardTest"
import EtSectionList from "./features/sections/EtSectionList"
import EtCreateSection from "./features/sections/EtCreateSection"
import EtUpdateSection from "./features/sections/EtUpdateSection"
import EtDeleteSection from "./features/sections/EtDeleteSection"
import EtAssessmentList from "./features/assessments/EtAssessmentList"
import EtCreateAssessment from "./features/assessments/EtCreateAssessment"
import EtUpdateAssessment  from "./features/assessments/EtUpdateAssessment"
import EtDeleteAssessment from "./features/assessments/EtDeleteAssessment"
import EtSessionTypeList from "./features/sessiontypes/EtSessionTypeList"
import EtCreateSessionType from "./features/sessiontypes/EtCreateSessionType"
import EtUpdateSessionType from "./features/sessiontypes/EtUpdateSessionType"
import EtDeleteSessionType from "./features/sessiontypes/EtDeleteSessionType"
import EtQuestionWithScoresList from "./features/questionwithscores/EtQuestionWithScoresList"
import EtCreateQuestionWithScores from "./features/questionwithscores/EtCreateQuestionWithScores"
import EtUpdateQuestionWithScores from "./features/questionwithscores/EtUpdateQuestionWithScores"
import EtDeleteQuestionWithScores from "./features/questionwithscores/EtDeleteQuestionWithScores"
import EtSessionQuesAns from "./features/investigation/EtSessionQuesAns"
import EtUserQuestionScore from "./features/userQuestionResponseWithScores/EtUserQuestionScore"
import EtUserSectionResponseWithScoresList from "./features/userSectionResponseWithScores/EtUserSectionResponseWithScoresList"
import EtCreateUserSectionResponseWithScores from "./features/userSectionResponseWithScores/EtCreateUserSectionResponseWithScores"
import EtUserAssessmentResponseWithScoresList from "./features/userAssessmentResponseWithScores/EtUserAssessmentResponseWithScoresList"
import EtCreateUserAssessmentResponseWithScores from "./features/userAssessmentResponseWithScores/EtCreateUserAssessmentResponseWithScores"
import EtUserSessionsList from "./features/userSessions/EtUserSessionsList"
import EtCreateUserSession from "./features/userSessions/EtCreateUserSession"
import EtUpdateUserSession from "./features/userSessions/EtUpdateUserSession"
import EtSessionList from "./features/sessions/EtSessionList"
import EtCreateNewSession from "./features/sessions/EtCreateNewSession"
import EtUpdateSession from "./features/sessions/EtUpdateSession"
import EtDeleteSession from "./features/sessions/EtDeleteSession"
import EtOrganizationTypeList from "./features/organizationtypes/EtOrganizationTypeList"
import EtCreateOrganizationType from "./features/organizationtypes/EtCreateOrganizationType"
import EtUpdateOrganizationType from "./features/organizationtypes/EtUpdateOrganizationType"
import EtDeleteOrganizationType from "./features/organizationtypes/EtDeleteOrganizationType"
import EtOrganizationList from "./features/organizations/EtOrganizationList"
import EtCreateOrganization from "./features/organizations/EtCreateOrganization"
import EtUpdateOrganization from "./features/organizations/EtUpdateOrganization"
import EtDeleteOrganization from "./features/organizations/EtDeleteOrganization"
import EtUsersList from "./features/users/EtUsersList"
import EtCreateUser from "./features/users/EtCreateUser"
import EtUpdateUser from "./features/users/EtUpdateUser"
import EtDeleteUser from "./features/users/EtDeleteUser"
import EtAssignOrgToUser from "./features/users/EtAssignOrgToUser"
import EtOrgSubscriptionList from "./features/orgSubscriptions/EtOrgSubscriptionList"
import EtCreateOrgSubscription from "./features/orgSubscriptions/EtCreateOrgSubscription"
import EtUpdateOrgSubscription from "./features/orgSubscriptions/EtUpdateOrgSubscription"
import EtDeleteOrgSubscription from "./features/orgSubscriptions/EtDeleteOrgSubscription"
import EtUserOrgSubscriptionsList from "./features/userSubscriptions/EtUserOrgSubscriptionsList"
import EtUsersMainList from "./pages/users/EtUsersMainList"
import EtUserTrailSessionsList from "./features/userTrailSessions/EtUserTrailSessionsList"
import EtCreateUserTrailSession from "./features/userTrailSessions/EtCreateUserTrailSession"
import EtUpdateUserTrailSession from "./features/userTrailSessions/EtUpdateUserTrailSession"
import EtDeleteUserTrailSession from "./features/userTrailSessions/EtDeleteUserTrailSession"
import EtUserTrailSessionIntroduction from "./features/userTrailSessions/EtUserTrailSessionIntroduction"
import EtUserTrailSessionIntroductionComp from "./features/userTrailSessions/EtUserTrailSessionIntroductionComp"
import EtActionTypeList from "./features/actionTypes/EtActionTypeList"
import EtCreateActionType from "./features/actionTypes/EtCreateActionType"
import EtUpdateActionType from "./features/actionTypes/EtUpdateActionType"
import EtDeleteActionType from "./features/actionTypes/EtDeleteActionType"
import EtUserActionList from "./features/userActions/EtUserActionList"
import EtCreateUserAction from "./features/userActions/EtCreateUserAction"
import EtUpdateUserAction from "./features/userActions/EtUpdateUserAction"
import EtDeleteUserAction from "./features/userActions/EtDeleteUserAction"
import EtUserActionListCompTest from "./features/userActions/EtUserActionListCompTest"
import EtUserTrailSessionIntrospection from "./features/userTrailSessions/EtUserTrailSessionIntrospection"
import EtUserTrailSessionIntrospectionComp from "./features/userTrailSessions/EtUserTrailSessionIntrospectionComp"
import EtSlateTest from "./components/editors/EtSlateTest"
import EtPlateTestEditor from "./components/editors/EtPlateTestEditor"
import EtCreateTestEditorContent from "./features/testEditor/EtCreateTestEditorContent"
import EtTestEditorContentsList from "./features/testEditor/EtTestEditorContentsList"
import EtCreateTestEditorContentBasic from "./features/testEditor/EtCreateTestEditorContentBasic"
import EtTestEditorContentsListBasic from "./features/testEditor/EtTestEditorContentsListBasic"
import EtInvestigationTypeList from "./features/investigationTypes/EtInvestigationTypeList"
import EtCreateInvestigationType from "./features/investigationTypes/EtCreateInvestigationType"
import EtUpdateInvestigationType from "./features/investigationTypes/EtUpdateInvestigationType"
import EtDeleteInvestigationType from "./features/investigationTypes/EtDeleteInvestigationType"
import EtUserTrailSessionInvestigation from "./features/userTrailSessions/EtUserTrailSessionInvestigation"
import EtUserTrailSessionInvestigationComp from "./features/userTrailSessions/EtUserTrailSessionInvestigationComp"
import EtSvgTest from "./components/svgs/EtSvgTest"
import EtUserTrailSessionImagination from "./features/userTrailSessions/EtUserTrailSessionImagination"
import EtUserTrailSessionImaginationComp from "./features/userTrailSessions/EtUserTrailSessionImaginationComp"
import EtUserTrailSessionImplementation from "./features/userTrailSessions/EtUserTrailSessionImplementation"
import EtUserTrailSessionImplementationComp from "./features/userTrailSessions/EtUserTrailSessionImplementationComp"
import EtUserTrailSessionIllumination from "./features/userTrailSessions/EtUserTrailSessionIllumination"
import EtUserTrailSessionIlluminationComp from "./features/userTrailSessions/EtUserTrailSessionIlluminationComp"
import EtCalendarEventList from "./features/calendarEvents/EtCalendarEventList"
import EtCreateCalendarEvent from "./features/calendarEvents/EtCreateCalendarEvent"
import EtUpdateCalendarEvent from "./features/calendarEvents/EtUpdateCalendarEvent"
import EtDeleteCalendarEvent from "./features/calendarEvents/EtDeleteCalendarEvent"
import EtUserTrailSession8848BBDB from "./features/userTrailSessions/EtUserTrailSession8848BBDB"
import EtUserTrailSession8848BBDBComp from "./features/userTrailSessions/EtUserTrailSession8848BBDBComp"
import EtUserTrailSession8848KYC from "./features/userTrailSessions/EtUserTrailSession8848KYC"
import EtUserTrailSession8848KYCComp from "./features/userTrailSessions/EtUserTrailSession8848KYCComp"
import EtUserTrailSession8848O2D from "./features/userTrailSessions/EtUserTrailSession8848O2D"
import EtUserTrailSession8848O2DComp from "./features/userTrailSessions/EtUserTrailSession8848O2DComp"
import EtUserTrailSession8848NLG from "./features/userTrailSessions/EtUserTrailSession8848NLG"
import EtUserTrailSession8848NLGComp from "./features/userTrailSessions/EtUserTrailSession8848NLGComp"
import EtUserTrailSession8848SSC from "./features/userTrailSessions/EtUserTrailSession8848SSC"
import EtUserTrailSession8848SSCComp from "./features/userTrailSessions/EtUserTrailSession8848SSCComp"
import EtCoachUserPadList from "./features/coachUserPads/EtCoachUserPadList"
import EtCreateCoachUserPad from "./features/coachUserPads/EtCreateCoachUserPad"
import EtUpdateCoachUserPad from "./features/coachUserPads/EtUpdateCoachUserPad"
import EtDeleteCoachUserPad from "./features/coachUserPads/EtDeleteCoachUserPad"
import EtCoachUserPad from "./features/coachUserPads/EtCoachUserPad"
import EtCoachOrgTrailSessionPad from "./features/coachOrgTrailSessionPads/EtCoachOrgTrailSessionPad"
import EtCoachOrgTrailSessionPadComp from "./features/coachOrgTrailSessionPads/EtCoachOrgTrailSessionPadComp"
import EtChallangeTypeList from "./features/challengeTypes/EtChallangeTypeList"
import EtCreateChallengeType from "./features/challengeTypes/EtCreateChallengeType"
import EtUpdateChallengeType from "./features/challengeTypes/EtUpdateChallengeType"
import EtDeleteChallengeType from "./features/challengeTypes/EtDeleteChallengeType"
import EtChallengeHeadList from "./features/challengeHeads/EtChallengeHeadList"
import EtCreateChallengeHead from "./features/challengeHeads/EtCreateChallengeHead"
import EtUpdateChallengeHead from "./features/challengeHeads/EtUpdateChallengeHead"
import EtDeleteChallengeHead from "./features/challengeHeads/EtDeleteChallengeHead"
import EtChallengeList from "./features/challenges/EtChallengeList"
import EtCreateChallenge from "./features/challenges/EtCreateChallenge"
import EtUpdateChallenge from "./features/challenges/EtUpdateChallenge"
import EtDeleteChallenge from "./features/challenges/EtDeleteChallenge"
import EtUserChallengeSelfEvalList from "./features/userChallengeSelfEvals/EtUserChallengeSelfEvalList"
import EtCreateUserChallengeSelfEval from "./features/userChallengeSelfEvals/EtCreateUserChallengeSelfEval"
import EtUpdateUserChallengeSelfEval from "./features/userChallengeSelfEvals/EtUpdateUserChallengeSelfEval"
import EtDeleteUserChallengeSelfEval from "./features/userChallengeSelfEvals/EtDeleteUserChallengeSelfEval"
import EtUserChallengeCoachEvalList from "./features/userChallengeCoachEvals/EtUserChallengeCoachEvalList"
import EtCreateUserChallengeCoachEval from "./features/userChallengeCoachEvals/EtCreateUserChallengeCoachEval"
import EtUpdateUserChallengeCoachEval from "./features/userChallengeCoachEvals/EtUpdateUserChallengeCoachEval"
import EtDeleteUserChallengeCoachEval from "./features/userChallengeCoachEvals/EtDeleteUserChallengeCoachEval"
import EtUserChallengeSponsorEvalList from "./features/userChallengeSponsorEvals/EtUserChallengeSponsorEvalList"
import EtCreateUserChallengeSponsorEval from "./features/userChallengeSponsorEvals/EtCreateUserChallengeSponsorEval"
import EtUpdateUserChallengeSponsorEval from "./features/userChallengeSponsorEvals/EtUpdateUserChallengeSponsorEval"
import EtDeleteUserChallengeSponsorEval from "./features/userChallengeSponsorEvals/EtDeleteUserChallengeSponsorEval"
import EtCoachUserOrgTrailSessionActionList from "./features/coachUserOrgTrailSessionActions/EtCoachUserOrgTrailSessionActionList"
import EtCreateCoachUserOrgTrailSessionAction from "./features/coachUserOrgTrailSessionActions/EtCreateCoachUserOrgTrailSessionAction"
import EtUpdateCoachUserOrgTrailSessionAction from "./features/coachUserOrgTrailSessionActions/EtUpdateCoachUserOrgTrailSessionAction"
import EtDeleteCoachUserOrgTrailSessionAction from "./features/coachUserOrgTrailSessionActions/EtDeleteCoachUserOrgTrailSessionAction"
import EtOrgRoleList from "./features/orgRoles/EtOrgRoleList"
import EtCreateOrgRole from "./features/orgRoles/EtCreateOrgRole"
import EtUpdateOrgRole from "./features/orgRoles/EtUpdateOrgRole"
import EtDeleteOrgRole from "./features/orgRoles/EtDeleteOrgRole"
import EtOrgTitleList from "./features/orgTitles/EtOrgTitle.List"
import EtCreateOrgTitle from "./features/orgTitles/EtCreateOrgTitle"
import EtUpdateOrgTitle from "./features/orgTitles/EtUpdateOrgTitle"
import EtDeleteOrgTitle from "./features/orgTitles/EtDeleteOrgTitle"
import EtSponsorUserPad from "./features/sponsorUserPads/EtSponsorUserPad"
import EtSponsorUserPadList from "./features/sponsorUserPads/EtSponsorUserPadList"
import EtCreateSponsorUserPad from "./features/sponsorUserPads/EtCreateSponsorUserPad"
import EtSponsorOrgTrailSessionPad from "./features/sponsorOrgTrailSessionPads/EtSponsorOrgTrailSessionPad"
import EtSponsorOrgTrailSessionPadComp from "./features/sponsorOrgTrailSessionPads/EtSponsorOrgTrailSessionPadComp"
import EtDeleteSponsorUserPad from "./features/sponsorUserPads/EtDeleteSponsorUserPad"
import EtCreateSponsorUserPadByOrg from "./features/sponsorUserPads/EtCreateSponsorUserPadByOrg"
import EtSponsorUserOrgTrailSessionActionList from "./features/sponsorUserOrgTrailSessionActions/EtSponsorUserOrgTrailSessionActionList"
import EtCreateSponsorUserOrgTrailSessionAction from "./features/sponsorUserOrgTrailSessionActions/EtCreateSponsorUserOrgTrailSessionAction"
import EtUpdateSponsorUserOrgTrailSessionAction from "./features/sponsorUserOrgTrailSessionActions/EtUpdateSponsorUserOrgTrailSessionAction"
import EtDeleteSponsorUserOrgTrailSessionAction from "./features/sponsorUserOrgTrailSessionActions/EtDeleteSponsorUserOrgTrailSessionAction"
import EtUserAccomplishmentWeeklyList from "./features/userAccomplishmentWeekly/EtUserAccomplishmentWeeklyList"
import EtUserAccomplishmentWeeklyPad from '@/features/userAccomplishmentWeekly/EtUserAccomplishmentWeeklyPad'
import EtCreateUserAccomplishmentWeekly from "./features/userAccomplishmentWeekly/EtCreateUserAccomplishmentWeekly"
import EtUpdateUserAccomplishmentWeekly from "./features/userAccomplishmentWeekly/EtUpdateUserAccomplishmentWeekly"
import EtDeleteUserAccomplishmentWeekly from "./features/userAccomplishmentWeekly/EtDeleteUserAccomplishmentWeekly"
import EtUserReflectionList from './features/userReflections/EtUserReflectionList'
import EtCreateUserReflection from '@/features/userReflections/EtCreateUserReflection'
import EtUpdateUserReflection from '@/features/userReflections/EtUpdateUserReflection'
import EtDeleteUserReflection from '@/features/userReflections/EtDeleteUserReflection'
import EtUserActionListByUserComp from "./features/userActions/EtUserActionListByUserComp"
import EtContactTypeList from '@/features/contactTypes/EtContactTypeList'
import EtCreateContactType from '@/features/contactTypes/EtCreateContactType'
import EtUpdateContactType from '@/features/contactTypes/EtUpdateContactType'
import EtDeleteContactType from '@/features/contactTypes/EtDeleteContactType'
import EtContactList from '@/features/contacts/EtContactList'
import EtCreateContact from '@/features/contacts/EtCreateContact'
import EtUpdateContact from '@/features/contacts/EtUpdateContact'
import EtDeleteContact from '@/features/contacts/EtDeleteContact'
import EtContactOrganizationTypeList from '@/features/contactOrganizationTypes/EtContactOrganizationTypeList'
import EtCreateContactOrganizationType from '@/features/contactOrganizationTypes/EtCreateContactOrganizationType'
import EtUpdateContactOrganizationType from '@/features/contactOrganizationTypes/EtUpdateContactOrganizationType'
import EtDeleteContactOrganizationType from '@/features/contactOrganizationTypes/EtDeleteContactOrganizationType'
import EtContactOrganizationList from '@/features/contactOrganizations/EtContactOrganizationList'
import EtCreateContactOrganization from '@/features/contactOrganizations/EtCreateContactOrganization'
import EtUpdateContactOrganization from '@/features/contactOrganizations/EtUpdateContactOrganization'
import EtDeleteContactOrganization from '@/features/contactOrganizations/EtDeleteContactOrganization'
import EtCreationTypeList from '@/features/creationTypes/EtCreationTypeList'
import EtCreateCreationType from '@/features/creationTypes/EtCreateCreationType'
import EtUpdateCreationType from '@/features/creationTypes/EtUpdateCreationType'
import EtDeleteCreationType from '@/features/creationTypes/EtDeleteCreationType'
import EtContributionTypeList from '@/features/contributionTypes/EtContributionTypeList'
import EtCreateContributionType from '@/features/contributionTypes/EtCreateContributionType'
import EtUpdateContributionType from '@/features/contributionTypes/EtUpdateContributionType'
import EtDeleteContributionType from '@/features/contributionTypes/EtDeleteContributionType'
import EtImpactTypeList from '@/features/impactTypes/EtImpactTypeList'
import EtCreateImpactType from '@/features/impactTypes/EtCreateImpactType'
import EtUpdateImpactType from '@/features/impactTypes/EtUpdateImpactType'
import EtDeleteImpactType from '@/features/impactTypes/EtDeleteImpactType'
import EtWinTypeList from '@/features/winTypes/EtWinTypeList'
import EtCreateWinType from '@/features/winTypes/EtCreateWinType'
import EtUpdateWinType from '@/features/winTypes/EtUpdateWinType'
import EtDeleteWinType from '@/features/winTypes/EtDeleteWinType'
import EtUserChallengeTypeList from '@/features/userChallengeTypes/EtUserChallengeTypeList'
import EtCreateUserChallengeType from '@/features/userChallengeTypes/EtCreateUserChallengeType'
import EtUpdateUserChallengeType from '@/features/userChallengeTypes/EtUpdateUserChallengeType'
import EtDeleteUserChallengeType from '@/features/userChallengeTypes/EtDeleteUserChallengeType'
import EtIntentionTypeList from '@/features/intentionTypes/EtIntentionTypeList'
import EtCreateIntentionType from '@/features/intentionTypes/EtCreateIntentionType'
import EtUpdateIntentionType from '@/features/intentionTypes/EtUpdateIntentionType'
import EtDeleteIntentionType from '@/features/intentionTypes/EtDeleteIntentionType'
import EtGratitudeTypeList from '@/features/gratitudeTypes/EtGratitudeTypeList'
import EtCreateGratitudeType from '@/features/gratitudeTypes/EtCreateGratitudeType'
import EtUpdateGratitudeType from '@/features/gratitudeTypes/EtUpdateGratitudeType'
import EtDeleteGratitudeType from '@/features/gratitudeTypes/EtDeleteGratitudeType'
import EtUserMagic from '@/features/userMagic/EtUserMagic'
import EtInspirationBeaconTypeList from '@/features/inspirationBeaconTypes/EtInspirationBeaconTypeList'
import EtCreateInspirationBeaconType from '@/features/inspirationBeaconTypes/EtCreateInspirationBeaconType'
import EtUpdateInspirationBeaconType from '@/features/inspirationBeaconTypes/EtUpdateInspirationBeaconType'
import EtDeleteInspirationBeaconType from '@/features/inspirationBeaconTypes/EtDeleteInspirationBeaconType'
import EtInspirationBeaconList from '@/features/inspirationBeacons/EtInspirationBeaconList'
import EtCreateInspirationBeacon from '@/features/inspirationBeacons/EtCreateInspirationBeacon'
import EtUpdateInspirationBeacon from '@/features/inspirationBeacons/EtUpdateInspirationBeacon'
import EtDeleteInspirationBeacon from '@/features/inspirationBeacons/EtDeleteInspirationBeacon'
import EtUserImprintEtList from '@/features/userMagic/userImprint/EtUserImprintEtList'
import EtCreateUserImprintEt from '@/features/userMagic/userImprint/EtCreateUserImprintEt'
import EtUpdateUserImprintEt from '@/features/userMagic/userImprint/EtUpdateUserImprintEt'
import EtDeleteUserImprintEt from '@/features/userMagic/userImprint/EtDeleteUserImprintEt'
import EtCapabilitiesAdvisoryTypeList from '@/features/capabilitiesAdvisoryTypes/EtCapabilitiesAdvisoryTypeList'
import EtCreateCapabilitiesAdvisoryType from '@/features/capabilitiesAdvisoryTypes/EtCreateCapabilitiesAdvisoryType'
import EtUpdateCapabilitiesAdvisoryType from '@/features/capabilitiesAdvisoryTypes/EtUpdateCapabilitiesAdvisoryType'
import EtDeleteCapabilitiesAdvisoryType from '@/features/capabilitiesAdvisoryTypes/EtDeleteCapabilitiesAdvisoryType'
import EtCapabilitiesIndustryTypeList from '@/features/capabilitiesIndustryTypes/EtCapabilitiesIndustryTypeList'
import EtCreateCapabilitiesIndustryType from '@/features/capabilitiesIndustryTypes/EtCreateCapabilitiesIndustryType'
import EtUpdateCapabilitiesIndustryType from '@/features/capabilitiesIndustryTypes/EtUpdateCapabilitiesIndustryType'
import EtDeleteCapabilitiesIndustryType from '@/features/capabilitiesIndustryTypes/EtDeleteCapabilitiesIndustryType'
import EtCapabilitiesDomainTypeList from '@/features/capabilitiesDomainTypes/EtCapabilitiesDomainTypeList'
import EtCreateCapabilitiesDomainType from '@/features/capabilitiesDomainTypes/EtCreateCapabilitiesDomainType'
import EtUpdateCapabilitiesDomainType from '@/features/capabilitiesDomainTypes/EtUpdateCapabilitiesDomainType'
import EtDeleteCapabilitiesDomainType from '@/features/capabilitiesDomainTypes/EtDeleteCapabilitiesDomainType'
import EtCapabilitiesFunctionTypeList from '@/features/capabilitiesFunctionTypes/EtCapabilitiesFunctionTypeList'
import EtCreateCapabilitiesFunctionType from '@/features/capabilitiesFunctionTypes/EtCreateCapabilitiesFunctionType'
import EtUpdateCapabilitiesFunctionType from '@/features/capabilitiesFunctionTypes/EtUpdateCapabilitiesFunctionType'
import EtDeleteCapabilitiesFunctionType from '@/features/capabilitiesFunctionTypes/EtDeleteCapabilitiesFunctionType'
import EtCapabilitiesTechAreaTypeList from '@/features/capabilitiesTechAreaTypes/EtCapabilitiesTechAreaTypeList'
import EtCreateCapabilitiesTechAreaType from '@/features/capabilitiesTechAreaTypes/EtCreateCapabilitiesTechAreaType'
import EtUpdateCapabilitiesTechAreaType from '@/features/capabilitiesTechAreaTypes/EtUpdateCapabilitiesTechAreaType'
import EtDeleteCapabilitiesTechAreaType from '@/features/capabilitiesTechAreaTypes/EtDeleteCapabilitiesTechAreaType'
import EtCapabilitiesTechStackTypeList from '@/features/capabilitiesTechStackTypes/EtCapabilitiesTechStackTypeList'
import EtCreateCapabilitiesTechStackType from '@/features/capabilitiesTechStackTypes/EtCreateCapabilitiesTechStackType'
import EtUpdateCapabilitiesTechStackType from '@/features/capabilitiesTechStackTypes/EtUpdateCapabilitiesTechStackType'
import EtDeleteCapabilitiesTechStackType from '@/features/capabilitiesTechStackTypes/EtDeleteCapabilitiesTechStackType'
import EtCapabilitiesServiceTypeList from '@/features/capabilitiesServiceTypes/EtCapabilitiesServiceTypeList'
import EtCreateCapabilitiesServiceType from '@/features/capabilitiesServiceTypes/EtCreateCapabilitiesServiceType'
import EtUpdateCapabilitiesServiceType from '@/features/capabilitiesServiceTypes/EtUpdateCapabilitiesServiceType'
import EtDeleteCapabilitiesServiceType from '@/features/capabilitiesServiceTypes/EtDeleteCapabilitiesServiceType'
import EtCapabilitiesServiceFunctionAreaTypeList from '@/features/capabilitiesServiceFunctionAreaTypes/EtCapabilitiesServiceFunctionAreaTypeList'
import EtCreateCapabilitiesServiceFunctionAreaType from '@/features/capabilitiesServiceFunctionAreaTypes/EtCreateCapabilitiesServiceFunctionAreaType'
import EtUpdateCapabilitiesServiceFunctionAreaType from '@/features/capabilitiesServiceFunctionAreaTypes/EtUpdateCapabilitiesServiceFunctionAreaType'
import EtDeleteCapabilitiesServiceFunctionAreaType from '@/features/capabilitiesServiceFunctionAreaTypes/EtDeleteCapabilitiesServiceFunctionAreaType'
import EtCapabilitiesSalesTypeList from '@/features/capabilitiesSalesTypes/EtCapabilitiesSalesTypeList'
import EtCreateCapabilitiesSalesType from '@/features/capabilitiesSalesTypes/EtCreateCapabilitiesSalesType'
import EtUpdateCapabilitiesSalesType from '@/features/capabilitiesSalesTypes/EtUpdateCapabilitiesSalesType'
import EtDeleteCapabilitiesSalesType from '@/features/capabilitiesSalesTypes/EtDeleteCapabilitiesSalesType'
import EtCapabilitiesSalesFunctionAreaTypeList from '@/features/capabilitiesSalesFunctionAreaTypes/EtCapabilitiesSalesFunctionAreaTypeList'
import EtCreateCapabilitiesSalesFunctionAreaType from '@/features/capabilitiesSalesFunctionAreaTypes/EtCreateCapabilitiesSalesFunctionAreaType'
import EtUpdateCapabilitiesSalesFunctionAreaType from '@/features/capabilitiesSalesFunctionAreaTypes/EtUpdateCapabilitiesSalesFunctionAreaType'
import EtDeleteCapabilitiesSalesFunctionAreaType from '@/features/capabilitiesSalesFunctionAreaTypes/EtDeleteCapabilitiesSalesFunctionAreaType'
import EtCapabilitiesSoftSkillTypeList from '@/features/capabilitiesSoftSkillTypes/EtCapabilitiesSoftSkillTypeList'
import EtCreateCapabilitiesSoftSkillType from '@/features/capabilitiesSoftSkillTypes/EtCreateCapabilitiesSoftSkillType'
import EtUpdateCapabilitiesSoftSkillType from '@/features/capabilitiesSoftSkillTypes/EtUpdateCapabilitiesSoftSkillType'
import EtDeleteCapabilitiesSoftSkillType from '@/features/capabilitiesSoftSkillTypes/EtDeleteCapabilitiesSoftSkillType'
import EtCapabilitiesSoftSkillFunctionAreaTypeList from '@/features/capabilitiesSoftSkillFunctionAreaTypes/EtCapabilitiesSoftSkillFunctionAreaTypeList'
import EtCreateCapabilitiesSoftSkillFunctionAreaType from '@/features/capabilitiesSoftSkillFunctionAreaTypes/EtCreateCapabilitiesSoftSkillFunctionAreaType'
import EtUpdateCapabilitiesSoftSkillFunctionAreaType from '@/features/capabilitiesSoftSkillFunctionAreaTypes/EtUpdateCapabilitiesSoftSkillFunctionAreaType'
import EtDeleteCapabilitiesSoftSkillFunctionAreaType from '@/features/capabilitiesSoftSkillFunctionAreaTypes/EtDeleteCapabilitiesSoftSkillFunctionAreaType'
import EtCreateUserImprintCapabilitiesIndustry from '@/features/userMagic/userImprint/EtCreateUserImprintCapabilitiesIndustry'
import EtUpdateUserImprintCapabilitiesIndustry from '@/features/userMagic/userImprint/EtUpdateUserImprintCapabilitiesIndustry'
import EtShowcaseTypeList from '@/features/showcaseTypes/EtShowcaseTypeList'
import EtCreateShowcaseType from '@/features/showcaseTypes/EtCreateShowcaseType'
import EtUpdateShowcaseType from '@/features/showcaseTypes/EtUpdateShowcaseType'
import EtDeleteShowcaseType from '@/features/showcaseTypes/EtDeleteShowcaseType'
import EtProblemAreaTypeList from '@/features/problemAreaTypes/EtProblemAreaTypeList'
import EtCreateProblemAreaType from '@/features/problemAreaTypes/EtCreateProblemAreaType'
import EtUpdateProblemAreaType from '@/features/problemAreaTypes/EtUpdateProblemAreaType'
import EtDeleteProblemAreaType from '@/features/problemAreaTypes/EtDeleteProblemAreaType'
import EtProblemMetricTypeList from '@/features/problemMetricTypes/EtProblemMetricTypeList'
import EtCreateProblemMetricType from '@/features/problemMetricTypes/EtCreateProblemMetricType'
import EtUpdateProblemMetricType from '@/features/problemMetricTypes/EtUpdateProblemMetricType'
import EtDeleteProblemMetricType from '@/features/problemMetricTypes/EtDeleteProblemMetricType'
import EtProblemMetricUoMTypeList from '@/features/problemMetricUoMTypes/EtProblemMetricUoMTypeList'
import EtCreateProblemMetricUoMType from '@/features/problemMetricUoMTypes/EtCreateProblemMetricUoMType'
import EtUpdateProblemMetricUoMType from '@/features/problemMetricUoMTypes/EtUpdateProblemMetricUoMType'
import EtDeleteProblemMetricUoMType from '@/features/problemMetricUoMTypes/EtDeleteProblemMetricUoMType'
import EtProblemMetricGradationTypeList from '@/features/problemMetricGradationTypes/EtProblemMetricGradationTypeList'
import EtCreateProblemMetricGradationType from '@/features/problemMetricGradationTypes/EtCreateProblemMetricGradationType'
import EtUpdateProblemMetricGradationType from '@/features/problemMetricGradationTypes/EtUpdateProblemMetricGradationType'
import EtDeleteProblemMetricGradationType from '@/features/problemMetricGradationTypes/EtDeleteProblemMetricGradationType'
import EtCreationAreaTypeList from '@/features/creationAreaTypes/EtCreationAreaTypeList'
import EtCreateCreationAreaType from '@/features/creationAreaTypes/EtCreateCreationAreaType'
import EtUpdateCreationAreaType from '@/features/creationAreaTypes/EtUpdateCreationAreaType'
import EtDeleteCreationAreaType from '@/features/creationAreaTypes/EtDeleteCreationAreaType'
import EtLegacyTypeList from '@/features/legacyTypes/EtLegacyTypeList'
import EtCreateLegacyType from '@/features/legacyTypes/EtCreateLegacyType'
import EtUpdateLegacyType from '@/features/legacyTypes/EtUpdateLegacyType'
import EtDeleteLegacyType from '@/features/legacyTypes/EtDeleteLegacyType'
//09 Jan 2025
import EtMissionMetricUoMTypeList from '@/features/missionMetricUoMTypes/EtMissionMetricUoMTypeList'
import EtCreateMissionMetricUoMType from '@/features/missionMetricUoMTypes/EtCreateMissionMetricUoMType'
import EtUpdateMissionMetricUoMType from '@/features/missionMetricUoMTypes/EtUpdateMissionMetricUoMType'
import EtDeleteMissionMetricUoMType from '@/features/missionMetricUoMTypes/EtDeleteMissionMetricUoMType'
import EtMissionMetricGradationTypeList from '@/features/missionMetricGradationTypes/EtMissionMetricGradationTypeList'
import EtCreateMissionMetricGradationType from '@/features/missionMetricGradationTypes/EtCreateMissionMetricGradationType'
import EtUpdateMissionMetricGradationType from '@/features/missionMetricGradationTypes/EtUpdateMissionMetricGradationType'
import EtDeleteMissionMetricGradationType from '@/features/missionMetricGradationTypes/EtDeleteMissionMetricGradationType'

// 07 Feb 2025
import EtUserConversationMobile from '@/features/userConversations/EtUserConversationMobile'
import EtTrinityUserConversationMobile1 from '@/features/userConversations/EtTrinityUserConversationMobile' //11 Feb 2025

// 08 Feb 2025
import EtTrinityMobileHome1 from '@/features/trinityMobile/EtTrinityMobileHome'
import EtTrinityMobileWABHome1 from '@/features/trinityMobile/EtTrinityMobileWABHome'

// 12 Feb 2025
import EtTrinityUserContributionMobile1 from '@/features/userContributions/EtTrinityUserContributionMobile'
import EtTrinityUserImpactMobile1 from '@/features/userImpacts/EtTrinityUserImpactMobile'
import EtTrinityUserWinMobile1 from '@/features/userWins/EtTrinityUserWinMobile'
import EtTrinityUserIntentionMobile1 from '@/features/userIntentions/EtTrinityUserIntentionMobile'
import EtTrinityUserGratitudeMobile1 from '@/features/userGratitudes/EtTrinityUserGratitudeMobile'

// 14 Feb 2025
import EtTrinityUserChallengeWABMobile1 from '@/features/userChallengeWABs/EtTrinityUserChallengeWABMobile'
import EtTrinityUserCreationMobile1 from '@/features/userCreations/EtTrinityUserCreationMobile'
// 15 Feb 2025
import EtTrinityUserActionMobile1 from '@/features/userActions/EtTrinityUserActionMobile'

// 18 Feb 2025
import EtTrinityMobileHome from '@/pages/home/EtTrinityMobileHome'

// 19 Feb 2025
import EtTrinityUserActionMobile from '@/pages/home/mobile/EtTrinityUserActionMobile'
import EtTrinityUserConversationMobile from '@/pages/home/mobile/EtTrinityUserConversationMobile'
import EtTrinityUserContributionMobile from '@/pages/home/mobile/EtTrinityUserContributionMobile'
import EtTrinityUserChallengeWABMobile from '@/pages/home/mobile/EtTrinityUserChallengeWABMobile'
import EtTrinityUserCreationMobile from '@/pages/home/mobile/EtTrinityUserCreationMobile'
import EtTrinityUserGratitudeMobile from '@/pages/home/mobile/EtTrinityUserGratitudeMobile'
import EtTrinityUserImpactMobile from '@/pages/home/mobile/EtTrinityUserImpactMobile'
import EtTrinityUserIntentionMobile from '@/pages/home/mobile/EtTrinityUserIntentionMobile'
import EtTrinityUserWinMobile from '@/pages/home/mobile/EtTrinityUserWinMobile'

// 22 Feb 2025
import EtTrinityWebHome from '@/pages/home/EtTrinityWebHome'

// 23 Feb 2025
import EtUserTrailSession8848BBDBCompProd from "@/features/userTrailSessions/EtUserTrailSession8848BBDBCompProd"
import EtUserTrailSession8848KYCCompProd from "@/features/userTrailSessions/EtUserTrailSession8848KYCCompProd"
import EtUserTrailSession8848O2DCompProd from "@/features/userTrailSessions/EtUserTrailSession8848O2DCompProd"
import EtUserTrailSession8848NLGCompProd from "@/features/userTrailSessions/EtUserTrailSession8848NLGCompProd"
import EtUserTrailSession8848SSCCompProd from "@/features/userTrailSessions/EtUserTrailSession8848SSCCompProd"

import EtUserTrailSessionIntroductionCompProd from "@/features/userTrailSessions/EtUserTrailSessionIntroductionCompProd"
import EtUserTrailSessionIntrospectionCompProd from "@/features/userTrailSessions/EtUserTrailSessionIntrospectionCompProd"
import EtUserTrailSessionInvestigationCompProd from "@/features/userTrailSessions/EtUserTrailSessionInvestigationCompProd"
import EtUserTrailSessionImaginationCompProd from "@/features/userTrailSessions/EtUserTrailSessionImaginationCompProd"
import EtUserTrailSessionImplementationCompProd from "@/features/userTrailSessions/EtUserTrailSessionImplementationCompProd"
import EtUserTrailSessionIlluminationCompProd from "@/features/userTrailSessions/EtUserTrailSessionIlluminationCompProd"


import EtSponsorOrgTrailSessionPadCompProd from "@/features/sponsorOrgTrailSessionPads/EtSponsorOrgTrailSessionPadCompProd"
import EtCoachOrgTrailSessionPadCompProd from "@/features/coachOrgTrailSessionPads/EtCoachOrgTrailSessionPadCompProd"

// 28 Feb 2025
import EtQuestionSetList from '@/features/questionsets/EtQuestionSetList'
import EtCreateQuestionSet from '@/features/questionsets/EtCreateQuestionSet'
import EtUpdateQuestionSet from '@/features/questionsets/EtUpdateQuestionSet'
import EtDeleteQuestionSet from '@/features/questionsets/EtDeleteQuestionSet'


function App() {
  useTitle('Et Home')
  return (
    <Routes>                                                                                                                                    
      <Route path="/">

        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          <Route
            element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}
          >
            <Route element={<Prefetch />}>
            <Route path="etapp">
              {/* <Route index element={<EtHomeLanding />} /> */}
              <Route index element={<EtTrinityWebHome />} />
                <Route path='8848bbdb/:userId/:trailId/:sessionId' element={<EtUserTrailSession8848BBDBCompProd />} />  {/* 23 Feb 2025 */}
                <Route path='8848kyc/:userId/:trailId/:sessionId' element={<EtUserTrailSession8848KYCCompProd />} />    {/* 23 Feb 2025 */}  
                <Route path='8848o2d/:userId/:trailId/:sessionId' element={<EtUserTrailSession8848O2DCompProd />} />    {/* 23 Feb 2025 */}
                <Route path='8848nlg/:userId/:trailId/:sessionId' element={<EtUserTrailSession8848NLGCompProd />} />    {/* 23 Feb 2025 */}
                <Route path='8848ssc/:userId/:trailId/:sessionId' element={<EtUserTrailSession8848SSCCompProd />} />    {/* 23 Feb 2025 */}
                <Route path='introduction/:userId/:trailId/:sessionId' element={<EtUserTrailSessionIntroductionCompProd />} />      {/* 23 Feb 2025 */}
                <Route path='introspection/:userId/:trailId/:sessionId' element={<EtUserTrailSessionIntrospectionCompProd />} />    {/* 23 Feb 2025 */}
                <Route path='assessment/:userId/:trailId/:sessionId' element={<EtUserTrailSessionInvestigationCompProd />} />    {/* 23 Feb 2025 */}
                <Route path='imagination/:userId/:trailId/:sessionId' element={<EtUserTrailSessionImaginationCompProd />} />        {/* 23 Feb 2025 */}
                <Route path='implementation/:userId/:trailId/:sessionId' element={<EtUserTrailSessionImplementationCompProd />} />  {/* 23 Feb 2025 */} 
                <Route path='illumination/:userId/:trailId/:sessionId' element={<EtUserTrailSessionIlluminationCompProd />} />      {/* 23 Feb 2025 */}
                <Route path="sponsor/update/:sponsorId/:orgId/:trailId/:sessionId" element={<EtSponsorOrgTrailSessionPadCompProd/>} />    
                <Route path="coach/update/:coachId/:orgId/:trailId/:sessionId" element={<EtCoachOrgTrailSessionPadCompProd/>} />
                <Route path='mobile'>
                     <Route index element={<EtTrinityMobileHome />} />
                     <Route path="action" element={<EtTrinityUserActionMobile />} />
                     <Route path="conversation" element={<EtTrinityUserConversationMobile />} />
                     <Route path="contribution" element={<EtTrinityUserContributionMobile />} />
                     <Route path="creation" element={<EtTrinityUserCreationMobile />} />
                     <Route path="challenge" element={<EtTrinityUserChallengeWABMobile />} />
                     <Route path="gratitude" element={<EtTrinityUserGratitudeMobile />} />
                     <Route path="impact" element={<EtTrinityUserImpactMobile />} />
                     <Route path="intention" element={<EtTrinityUserIntentionMobile />} />
                     <Route path="win" element={<EtTrinityUserWinMobile />} />
                </Route> 
            </Route>
           
            {/* Learn Routes */}
            <Route path="learn">
              <Route index element={<EtLearnMain />} />
              <Route path="users" element={<EtUsersMainList />} />
            </Route>{" "}
            {/* End of Learn Routes */}
            
            {/* Trails Routes */}
            <Route path="trails">
              <Route path="ubuntu" element={<EtTrailsUbuntuMain />} />
              <Route path="sales" element={<EtTrailsSalesMain />} />
            </Route>{" "}
            {/* End of Trails Routes */}
           
            {/* Admin Routes */}
            <Route path="admin">
              {/* <Route path="sessions">
                <Route path="create" element={<EtCreateSession />} />
                <Route path="edit/:id" element={<EtEditSession />} />
                <Route path="view" element={<EtViewSessions />} />
              </Route> */}
              
              {/* <Route path="qsets">
                <Route index element={<EtQuestionSetMain />} />
                <Route path="create" element={<EtQuestionSetCreate />} />
                <Route path="update/:id" element={<EtQuestionSetUpdate />}/>
                <Route path="delete/:id" element={<EtQuestionSetDelete />}/>
              </Route> */}

              {/* <Route path="questions">
                <Route index element={<EtQuestionsMain />} />
                <Route path="create" element={<EtQuestionCreate />} />
                <Route path="update/:id" element={<EtQuestionUpdate />} />
                <Route path="delete/:id" element={<EtQuestionDelete />} />
              </Route> */}

            </Route>           
           
             </Route>{/* End of Protected routes  - prefetch */}
          </Route>{" "}
          {/* End of Protected routes  - require auth*/}
        </Route>{" "}
        {/* End of Protected routes - persist login*/}


        {/* Public Routes */}
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        {/* <Route path="signup" element={<SignUpNewUserForm />} /> */}
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />

        {/* 28 Feb 2025 */}
        {/* <Route path="questionSets">
          <Route index element={<EtQuestionSetList />} />
          <Route path="create" element={<EtCreateQuestionSet />} />
          <Route path="update/:id" element={<EtUpdateQuestionSet />} />
          <Route path="delete/:id" element={<EtDeleteQuestionSet />} />
        </Route> */}
        
        {/* <Route path="test" element={<MyTrailInitiationTest />} />
        <Route path="test2" element={<EtSessionAboutYou />} />
        <Route path="test3" element={<EtSessionPCI />} />
        <Route path="test4" element={<EtTabs />} />
        <Route path="test5" element={<EtUserScoreTest />} />
        <Route path="test6" element={<EtPixelTest />} />
        <Route path="test7" element={<EtSvgWrapper />} />
        <Route path="test8" element={<EtPixelScorecardTest />} />
        <Route path="test9" element={<EtUserActionListCompTest />} />
        <Route path="test10" element={<EtSlateTest />} />
        <Route path="test11" element={<EtCreateTestEditorContent />} />
        <Route path="test11/show" element={<EtTestEditorContentsList />} />
        <Route path="test11/showBasic" element={<EtTestEditorContentsListBasic />} />
        <Route path="test11/create" element={<EtCreateTestEditorContentBasic />} />
        <Route path="test12" element={<EtSvgTest />} /> */}

        {/* <Route path="pic-upload" element={<EtUserPicUpload />} />
        <Route path="investigations" element={<EtSessionAboutYouList />}/>
        <Route path='trails' element={<EtTrailList />} />
        <Route path="trails/create" element={<EtCreateTrail />} />
        <Route path="trails/update/:id" element={<EtUpdateTrail />}/>
        <Route path='trails/delete/:id' element={<EtDeleteTrail />} />
        <Route path="animation/float" element={<EtFloatUp />} />
        <Route path='test/8848' element={<EtTrail8848Banner />} />
        <Route path='subscriptionPacks' element={<EtSubsPacksList />} />
        <Route path='subscriptionPacks/create' element={<EtCreateSubsPack />} />
        <Route path='subscriptionPacks/update/:id' element={<EtUpdateSubsPack />} />
        <Route path='subscriptionPacks/delete/:id' element={<EtDeleteSubsPack />} /> */}
        
        {/* <Route path="sections">
          <Route index element={<EtSectionList />} />
          <Route path="create" element={<EtCreateSection />} />
          <Route path="update/:id" element={<EtUpdateSection />} />
          <Route path="delete/:id" element={<EtDeleteSection />} />
        </Route>
        
        <Route path="assessments">
          <Route index element={<EtAssessmentList/>} />
          <Route path="create" element={<EtCreateAssessment />} />
          <Route path="update/:id" element={<EtUpdateAssessment />} />
          <Route path="delete/:id" element={<EtDeleteAssessment />} />
        </Route> */}

        {/* <Route path="sessiontypes">
          <Route index element={<EtSessionTypeList/>} />
          <Route path="create" element={<EtCreateSessionType />} />
          <Route path="update/:id" element={<EtUpdateSessionType />} />
          <Route path="delete/:id" element={<EtDeleteSessionType />} />
        </Route>

        <Route path="questionswithscores">
          <Route index element={<EtQuestionWithScoresList/>} />
          <Route path="create" element={<EtCreateQuestionWithScores />} />
          <Route path="update/:id" element={<EtUpdateQuestionWithScores />} />
          <Route path="delete/:id" element={<EtDeleteQuestionWithScores />} />
        </Route>

        <Route path="userQuestionResponses">
          <Route index element={<EtSessionQuesAns/>} />
          <Route path="create" element={<EtCreateQuestionWithScores />} />
          <Route path="update/:id" element={<EtUpdateQuestionWithScores />} />
          <Route path="delete/:id" element={<EtDeleteQuestionWithScores />} />
        </Route> */}

        {/* <Route path="userscores">
          <Route index element={<EtUserQuestionScore/>} />
          <Route path='questions'>
                <Route path="create" element={<EtCreateQuestionWithScores />} />
                <Route path="update/:id" element={<EtUpdateQuestionWithScores />} />
                <Route path="delete/:id" element={<EtDeleteQuestionWithScores />} />
          </Route>
          <Route path='sections'>
                <Route index element={<EtUserSectionResponseWithScoresList />} />
                <Route path="create" element={<EtCreateUserSectionResponseWithScores />} />

          </Route>
          <Route path='assessments'>
                <Route index element={<EtUserAssessmentResponseWithScoresList />} />
                <Route path="create" element={<EtCreateUserAssessmentResponseWithScores />} />
          </Route>
        </Route> */}

        {/* <Route path="userSessions">
          <Route index element={<EtUserSessionsList />} />
          <Route path="create" element={<EtCreateUserSession />} />
          <Route path="update/:id" element={<EtUpdateUserSession />} />
        </Route>

        <Route path="sessions">
          <Route index element={<EtSessionList />} />
          <Route path="create" element={<EtCreateNewSession />} />
          <Route path="update/:id" element={<EtUpdateSession />} />
          <Route path="delete/:id" element={<EtDeleteSession />} />
        </Route> */}

        {/* <Route path="organizations">
          <Route index element={<EtOrganizationList />} />
          <Route path="create" element={<EtCreateOrganization />} />
          <Route path="update/:id" element={<EtUpdateOrganization />} />
          <Route path="delete/:id" element={<EtDeleteOrganization />} />
        </Route> */}

        {/* <Route path="organizationtypes">
          <Route index element={<EtOrganizationTypeList />} />
          <Route path="create" element={<EtCreateOrganizationType />} />
          <Route path="update/:id" element={<EtUpdateOrganizationType />} />
          <Route path="delete/:id" element={<EtDeleteOrganizationType />} />
        </Route> */}

         {/* <Route path="investigationTypes">  02 Jul 2024 */}
          {/* <Route index element={<EtInvestigationTypeList />} />
          <Route path="create" element={<EtCreateInvestigationType />} />
          <Route path="update/:id" element={<EtUpdateInvestigationType />} />
          <Route path="delete/:id" element={<EtDeleteInvestigationType />} />
        </Route> */}
       
        {/* <Route path="orgSubscriptions">
          <Route index element={<EtOrgSubscriptionList />} />
          <Route path="create" element={<EtCreateOrgSubscription />} />
          <Route path="update/:id" element={<EtUpdateOrgSubscription />} />
          <Route path="delete/:id" element={<EtDeleteOrgSubscription />} />
        </Route> */}

        {/* <Route path="users">
          <Route index element={<EtUsersList />} />
          <Route path="create" element={<EtCreateUser />} />
          <Route path="update/:id" element={<EtUpdateUser />} />
          <Route path="delete/:id" element={<EtDeleteUser />} />
          <Route path="assignOrg" element={<EtAssignOrgToUser />} />
        </Route> */}

        {/* <Route path="userTrailSessions">
          <Route index element={<EtUserTrailSessionsList />} />
          <Route path="create" element={<EtCreateUserTrailSession />} />
          <Route path="update/:id" element={<EtUpdateUserTrailSession/>} />
          <Route path="delete/:id" element={<EtDeleteUserTrailSession/>} />
          <Route path='introduction/:userId/:trailId/:sessionId' element={<EtUserTrailSessionIntroduction />} />
          <Route path='introspection/:userId/:trailId/:sessionId' element={<EtUserTrailSessionIntrospection />} />
          <Route path='investigation/:userId/:trailId/:sessionId' element={<EtUserTrailSessionInvestigation />} />
          <Route path='assessment/:userId/:trailId/:sessionId' element={<EtUserTrailSessionInvestigation />} /> */}
          {/* <Route path='imagination/:userId/:trailId/:sessionId' element={<EtUserTrailSessionImagination />} />  17 Jul 2024 */}
          {/* <Route path='implementation/:userId/:trailId/:sessionId' element={<EtUserTrailSessionImplementation />} />  17 Jul 2024 */}
          {/* <Route path='illumination/:userId/:trailId/:sessionId' element={<EtUserTrailSessionIllumination />} />  18 Jul 2024 */}
          {/* <Route path='8848bbdb/:userId/:trailId/:sessionId' element={<EtUserTrailSession8848BBDB />} />  27 Jul 2024 */}
          {/* <Route path='8848kyc/:userId/:trailId/:sessionId' element={<EtUserTrailSession8848KYC />} />  29 Jul 2024 */}
          {/* <Route path='8848o2d/:userId/:trailId/:sessionId' element={<EtUserTrailSession8848O2D />} />  29 Jul 2024 */}
          {/* <Route path='8848nlg/:userId/:trailId/:sessionId' element={<EtUserTrailSession8848NLG />} />  29 Jul 2024 */}
          {/* <Route path='8848ssc/:userId/:trailId/:sessionId' element={<EtUserTrailSession8848SSC />} />  29 Jul 2024 */}

        {/* </Route> */}

        {/* <Route path="calendar"> 22 Jul 2024 */}
          {/* <Route index element={<EtCalendarEventList/>} />
          <Route path="create" element={<EtCreateCalendarEvent />} />
          <Route path="update/:id" element={<EtUpdateCalendarEvent/>} />
          <Route path="delete/:id" element={<EtDeleteCalendarEvent/>} />
        </Route> */}

        {/* <Route path="coachUserPad"> 02 Aug 2024 */}
          {/* <Route index element={<EtCoachUserPadList/>} />
          <Route path=":id" element={<EtCoachUserPad/>} />
          <Route path="create" element={< EtCreateCoachUserPad/>} />
          <Route path="update/:orgId/:trailId/:sessionId" element={<EtCoachOrgTrailSessionPad/>} />
          <Route path="delete/:orgId/:trailId/:sessionId" element={<EtDeleteCoachUserPad/>} />
        </Route> */}


        {/* <Route path="userOrgSubscriptions"> */}
          {/* <Route index element={<EtUserOrgSubscriptionsList />} /> */}
          {/* <Route path="create" element={<EtCreateOrgSubscription />} />
          <Route path="update/:id" element={<EtUpdateOrgSubscription />} />
          <Route path="delete/:id" element={<EtDeleteOrgSubscription />} /> */}
        {/* </Route> */}

          {/* 07 Jun 2024 */}
        {/* <Route path="actiontypes">
          <Route index element={<EtActionTypeList/>} />
          <Route path="create" element={<EtCreateActionType/>} />
          <Route path="update/:id" element={<EtUpdateActionType/>} />
          <Route path="delete/:id" element={<EtDeleteActionType />} />
        </Route> */}

        {/* 07 Jun 2024 */}
        {/* <Route path="userActions">
          <Route index element={<EtUserActionList/>} />
          <Route path="create" element={<EtCreateUserAction/>} />
          <Route path="user" element={<EtUserActionListByUserComp/>} />
          <Route path="update/:id" element={<EtUpdateUserAction/>} />
          <Route path="delete/:id" element={<EtDeleteUserAction/>} />
        </Route> */}

         {/* 16 Aug 2024 */}
         {/* <Route path="challengetypes">
          <Route index element={<EtChallangeTypeList/>} />
          <Route path="create" element={<EtCreateChallengeType/>} />
          <Route path="update/:id" element={<EtUpdateChallengeType/>} />
          <Route path="delete/:id" element={<EtDeleteChallengeType />} />
        </Route> */}

         {/* 16 Aug 2024 */}
         {/* <Route path="challengeheads">
          <Route index element={<EtChallengeHeadList/>} />
          <Route path="create" element={<EtCreateChallengeHead/>} />
          <Route path="update/:id" element={<EtUpdateChallengeHead/>} />
          <Route path="delete/:id" element={<EtDeleteChallengeHead />} />
        </Route> */}

        {/* 16 Aug 2024 */}
        {/* <Route path="challenges">
          <Route index element={<EtChallengeList/>} />
          <Route path="create" element={<EtCreateChallenge/>} />
          <Route path="update/:id" element={<EtUpdateChallenge/>} />
          <Route path="delete/:id" element={<EtDeleteChallenge />} />
        </Route> */}

        {/* 21 Aug 2024 */}
        {/* <Route path="userChallengeSelfEvals">
          <Route index element={<EtUserChallengeSelfEvalList/>} />
          <Route path="create" element={<EtCreateUserChallengeSelfEval/>} />
          <Route path="update/:id" element={<EtUpdateUserChallengeSelfEval/>} />
          <Route path="delete/:id" element={<EtDeleteUserChallengeSelfEval />} />
        </Route> */}

        {/* 24 Aug 2024 */}
        {/* <Route path="userChallengeCoachEvals">
          <Route index element={<EtUserChallengeCoachEvalList/>} />
          <Route path="create" element={<EtCreateUserChallengeCoachEval/>} />
          <Route path="update/:id" element={<EtUpdateUserChallengeCoachEval/>} />
          <Route path="delete/:id" element={<EtDeleteUserChallengeCoachEval />} />
        </Route> */}

        {/* 25 Aug 2024 */}
        {/* <Route path="userChallengeSponsorEvals">
          <Route index element={<EtUserChallengeSponsorEvalList/>} />
          <Route path="create" element={<EtCreateUserChallengeSponsorEval/>} />
          <Route path="update/:id" element={<EtUpdateUserChallengeSponsorEval/>} />
          <Route path="delete/:id" element={<EtDeleteUserChallengeSponsorEval />} />
        </Route> */}

        {/* 01 Sep 2024 */}
        {/* <Route path="coachUserOrgTrailSessionActions">
          <Route index element={<EtCoachUserOrgTrailSessionActionList/>} />
          <Route path="create" element={<EtCreateCoachUserOrgTrailSessionAction/>} />
          <Route path="update/:id" element={<EtUpdateCoachUserOrgTrailSessionAction/>} />
          <Route path="delete/:id" element={<EtDeleteCoachUserOrgTrailSessionAction />} />
        </Route> */}

        {/* 05 Sep 2024 */}
        {/* <Route path="orgRoles">
          <Route index element={<EtOrgRoleList/>} />
          <Route path="create" element={<EtCreateOrgRole/>} />
          <Route path="update/:id" element={<EtUpdateOrgRole/>} />
          <Route path="delete/:id" element={<EtDeleteOrgRole />} />
        </Route> */}

        {/* 05 Sep 2024 */}
        {/* <Route path="orgTitles">
          <Route index element={<EtOrgTitleList/>} />
          <Route path="create" element={<EtCreateOrgTitle/>} />
          <Route path="update/:id" element={<EtUpdateOrgTitle/>} />
          <Route path="delete/:id" element={<EtDeleteOrgTitle />} />
        </Route> */}

        {/* 07 Sep 2024 */}
        {/* <Route path="sponsorUserPad"> 
          <Route index element={<EtSponsorUserPadList/>} />
          <Route path=":id" element={<EtSponsorUserPad/>} />
          <Route path="create" element={<EtCreateSponsorUserPadByOrg/>} /> */}
          {/* <Route path="update/:sponsorId/:orgId/:trailId/:sessionId" element={<EtSponsorOrgTrailSessionPad/>} />08 Sep 2024 - added sponsorId to the route */}
          {/* <Route path="delete/:sponsorId/:orgId/:trailId/:sessionId" element={<EtDeleteSponsorUserPad/>} />
        </Route> */}

        {/* 01 Sep 2024 */}
        {/* <Route path="sponsorUserOrgTrailSessionActions">
          <Route index element={<EtSponsorUserOrgTrailSessionActionList/>} />
          <Route path="create" element={<EtCreateSponsorUserOrgTrailSessionAction/>} />
          <Route path="update/:id" element={<EtUpdateSponsorUserOrgTrailSessionAction/>} />
          <Route path="delete/:id" element={<EtDeleteSponsorUserOrgTrailSessionAction />} />
        </Route> */}

        {/* 15 Sep 2024 */}
        {/* <Route path="userWABs">
          <Route index element={<EtUserAccomplishmentWeeklyList/>} />
          <Route path="pad" element={<EtUserAccomplishmentWeeklyPad/>} />
          <Route path="create" element={<EtCreateUserAccomplishmentWeekly/>} />
          <Route path="update/:id" element={<EtUpdateUserAccomplishmentWeekly/>} />
          <Route path="delete/:id" element={<EtDeleteUserAccomplishmentWeekly />} />
        </Route> */}

        {/* 21 Sep 2024 */}
        {/* <Route path="userReflections">
          <Route index element={<EtUserReflectionList/>} />
          <Route path="create" element={<EtCreateUserReflection/>} />
          <Route path="update/:id" element={<EtUpdateUserReflection/>} />
          <Route path="delete/:id" element={<EtDeleteUserReflection />} />
        </Route> */}

        {/* 27 Sep 2024 */}
        {/* <Route path="contacttypes">
          <Route index element={<EtContactTypeList/>} />
          <Route path="create" element={<EtCreateContactType/>} />
          <Route path="update/:id" element={<EtUpdateContactType/>} />
          <Route path="delete/:id" element={<EtDeleteContactType />} />
        </Route> */}

        {/* 28 Sep 2024 */}
        {/* <Route path="contacts">
          <Route index element={<EtContactList/>} />
          <Route path="create" element={<EtCreateContact/>} />
          <Route path="update/:id" element={<EtUpdateContact/>} />
          <Route path="delete/:id" element={<EtDeleteContact />} />
        </Route> */}

        {/* 28 Sep 2024 */}
        {/* <Route path="contactOrganizationTypes">
          <Route index element={<EtContactOrganizationTypeList />} />
          <Route path="create" element={<EtCreateContactOrganizationType />} />
          <Route path="update/:id" element={<EtUpdateContactOrganizationType />} />
          <Route path="delete/:id" element={<EtDeleteContactOrganizationType />} />
        </Route> */}

        {/* 28 Sep 2024 */}
        {/* <Route path="contactOrganizations">
          <Route index element={<EtContactOrganizationList />} />
          <Route path="create" element={<EtCreateContactOrganization />} />
          <Route path="update/:id" element={<EtUpdateContactOrganization />} />
          <Route path="delete/:id" element={<EtDeleteContactOrganization />} />
        </Route> */}

         {/* 04 Oct 2024 */}
         {/* <Route path="creationtypes">
          <Route index element={<EtCreationTypeList/>} />
          <Route path="create" element={<EtCreateCreationType/>} />
          <Route path="update/:id" element={<EtUpdateCreationType/>} />
          <Route path="delete/:id" element={<EtDeleteCreationType />} />
        </Route> */}
         
         {/* 10 Oct 2024 */}
         {/* <Route path="contributiontypes">
          <Route index element={<EtContributionTypeList/>} />
          <Route path="create" element={<EtCreateContributionType/>} />
          <Route path="update/:id" element={<EtUpdateContributionType/>} />
          <Route path="delete/:id" element={<EtDeleteContributionType />} />
        </Route> */}

         {/* 13 Oct 2024 */}
         {/* <Route path="impacttypes">
          <Route index element={<EtImpactTypeList/>} />
          <Route path="create" element={<EtCreateImpactType/>} />
          <Route path="update/:id" element={<EtUpdateImpactType/>} />
          <Route path="delete/:id" element={<EtDeleteImpactType />} />
        </Route> */}

         {/* 13 Oct 2024 */}
         {/* <Route path="wintypes">
          <Route index element={<EtWinTypeList/>} />
          <Route path="create" element={<EtCreateWinType/>} />
          <Route path="update/:id" element={<EtUpdateWinType/>} />
          <Route path="delete/:id" element={<EtDeleteWinType />} />
        </Route> */}

         {/* 14 Oct 2024 */}
         {/* <Route path="userChallengeTypes">
          <Route index element={<EtUserChallengeTypeList/>} />
          <Route path="create" element={<EtCreateUserChallengeType/>} />
          <Route path="update/:id" element={<EtUpdateUserChallengeType/>} />
          <Route path="delete/:id" element={<EtDeleteUserChallengeType />} />
        </Route> */}

        {/* 15 Oct 2024 */}
        {/* <Route path="intentionTypes">
          <Route index element={<EtIntentionTypeList/>} />
          <Route path="create" element={<EtCreateIntentionType/>} />
          <Route path="update/:id" element={<EtUpdateIntentionType/>} />
          <Route path="delete/:id" element={<EtDeleteIntentionType />} />
        </Route> */}

        {/* 16 Oct 2024 */}
        {/* <Route path="gratitudeTypes">
          <Route index element={<EtGratitudeTypeList/>} />
          <Route path="create" element={<EtCreateGratitudeType/>} />
          <Route path="update/:id" element={<EtUpdateGratitudeType/>} />
          <Route path="delete/:id" element={<EtDeleteGratitudeType />} />
        </Route> */}

        {/* 19 Oct 2024 */}
        {/* <Route path="userMagicHome">
          <Route index element={<EtUserMagic/>} />
          <Route path='8848bbdb/:userId/:trailId/:sessionId' element={<EtUserTrailSession8848BBDBComp />} />  22 Oct 2024
          <Route path='8848kyc/:userId/:trailId/:sessionId' element={<EtUserTrailSession8848KYCComp />} />    20 Oct 2024  
          <Route path='8848o2d/:userId/:trailId/:sessionId' element={<EtUserTrailSession8848O2DComp />} />    22 Oct 2024
          <Route path='8848nlg/:userId/:trailId/:sessionId' element={<EtUserTrailSession8848NLGComp />} />    22 Oct 2024
          <Route path='8848ssc/:userId/:trailId/:sessionId' element={<EtUserTrailSession8848SSCComp />} />    22 Oct 2024
          <Route path='introduction/:userId/:trailId/:sessionId' element={<EtUserTrailSessionIntroductionComp />} />      22 Oct 2024
          <Route path='introspection/:userId/:trailId/:sessionId' element={<EtUserTrailSessionIntrospectionComp />} />    22 Oct 2024
          <Route path='assessment/:userId/:trailId/:sessionId' element={<EtUserTrailSessionInvestigationComp />} />    22 Oct 2024
          <Route path='imagination/:userId/:trailId/:sessionId' element={<EtUserTrailSessionImaginationComp />} />        22 Oct 2024
          <Route path='implementation/:userId/:trailId/:sessionId' element={<EtUserTrailSessionImplementationComp />} />  22 Oct 2024 
          <Route path='illumination/:userId/:trailId/:sessionId' element={<EtUserTrailSessionIlluminationComp />} />      22 Oct 2024
          <Route path="sponsor/update/:sponsorId/:orgId/:trailId/:sessionId" element={<EtSponsorOrgTrailSessionPadComp/>} />    
          <Route path="coach/update/:coachId/:orgId/:trailId/:sessionId" element={<EtCoachOrgTrailSessionPadComp/>} />
        </Route> */}

        {/* 03 Nov 2024 */}
        {/* <Route path="inspirationBeaconTypes">
          <Route index element={<EtInspirationBeaconTypeList/>} />
          <Route path="create" element={<EtCreateInspirationBeaconType/>} />
          <Route path="update/:id" element={<EtUpdateInspirationBeaconType/>} />
          <Route path="delete/:id" element={<EtDeleteInspirationBeaconType />} />
        </Route> */}

        {/* 03 Nov 2024 */}
        {/* <Route path="inspirationBeacons">
          <Route index element={<EtInspirationBeaconList/>} />
          <Route path="create" element={<EtCreateInspirationBeacon/>} />
          <Route path="update/:id" element={<EtUpdateInspirationBeacon/>} />
          <Route path="delete/:id" element={<EtDeleteInspirationBeacon />} />
        </Route> */}

        {/* 04 Nov 2024 */}
        {/* <Route path="userImprintEts">
          <Route index element={<EtUserImprintEtList/>} />
          <Route path="create" element={<EtCreateUserImprintEt/>} />
          <Route path="update/:userId/:userOrgId/:guestUserId/:guestUserOrgId" element={<EtUpdateUserImprintEt/>} />
          <Route path="delete/:id" element={<EtDeleteUserImprintEt />} />
        </Route> */}

        {/* 12 Nov 2024 */}
        {/* <Route path="capabilitiesAdvisoryTypes">
          <Route index element={<EtCapabilitiesAdvisoryTypeList/>} />
          <Route path="create" element={<EtCreateCapabilitiesAdvisoryType/>} />
          <Route path="update/:id" element={<EtUpdateCapabilitiesAdvisoryType/>} />
          <Route path="delete/:id" element={<EtDeleteCapabilitiesAdvisoryType />} />
        </Route> */}

        {/* 12 Nov 2024 */}
        {/* <Route path="capabilitiesIndustryTypes">
          <Route index element={<EtCapabilitiesIndustryTypeList/>} />
          <Route path="create" element={<EtCreateCapabilitiesIndustryType/>} />
          <Route path="update/:id" element={<EtUpdateCapabilitiesIndustryType/>} />
          <Route path="delete/:id" element={<EtDeleteCapabilitiesIndustryType />} />
        </Route> */}

        {/* 12 Nov 2024 */}
        {/* <Route path="capabilitiesDomainTypes">
          <Route index element={<EtCapabilitiesDomainTypeList/>} />
          <Route path="create" element={<EtCreateCapabilitiesDomainType/>} />
          <Route path="update/:id" element={<EtUpdateCapabilitiesDomainType/>} />
          <Route path="delete/:id" element={<EtDeleteCapabilitiesDomainType />} />
        </Route> */}

        {/* 12 Nov 2024 */}
        {/* <Route path="capabilitiesFunctionTypes">
          <Route index element={<EtCapabilitiesFunctionTypeList/>} />
          <Route path="create" element={<EtCreateCapabilitiesFunctionType/>} />
          <Route path="update/:id" element={<EtUpdateCapabilitiesFunctionType/>} />
          <Route path="delete/:id" element={<EtDeleteCapabilitiesFunctionType />} />
        </Route> */}

        {/* 14 Nov 2024 */}
        {/* <Route path="capabilitiesTechAreaTypes">
          <Route index element={<EtCapabilitiesTechAreaTypeList/>} />
          <Route path="create" element={<EtCreateCapabilitiesTechAreaType/>} />
          <Route path="update/:id" element={<EtUpdateCapabilitiesTechAreaType/>} />
          <Route path="delete/:id" element={<EtDeleteCapabilitiesTechAreaType />} />
        </Route> */}

        {/* 15 Nov 2024 */}
        {/* <Route path="capabilitiesTechStackTypes">
          <Route index element={<EtCapabilitiesTechStackTypeList/>} />
          <Route path="create" element={<EtCreateCapabilitiesTechStackType/>} />
          <Route path="update/:id" element={<EtUpdateCapabilitiesTechStackType/>} />
          <Route path="delete/:id" element={<EtDeleteCapabilitiesTechStackType />} />
        </Route> */}

        {/* 17 Nov 2024 */}
        {/* <Route path="capabilitiesServiceTypes">
          <Route index element={<EtCapabilitiesServiceTypeList/>} />
          <Route path="create" element={<EtCreateCapabilitiesServiceType/>} />
          <Route path="update/:id" element={<EtUpdateCapabilitiesServiceType/>} />
          <Route path="delete/:id" element={<EtDeleteCapabilitiesServiceType />} />
        </Route> */}
        
        {/* 17 Nov 2024 */}
        {/* <Route path="capabilitiesServiceFunctionAreaTypes">
          <Route index element={<EtCapabilitiesServiceFunctionAreaTypeList/>} />
          <Route path="create" element={<EtCreateCapabilitiesServiceFunctionAreaType/>} />
          <Route path="update/:id" element={<EtUpdateCapabilitiesServiceFunctionAreaType/>} />
          <Route path="delete/:id" element={<EtDeleteCapabilitiesServiceFunctionAreaType />} />
        </Route> */}

        {/* 18 Nov 2024 */}
        {/* <Route path="capabilitiesSalesTypes">
          <Route index element={<EtCapabilitiesSalesTypeList/>} />
          <Route path="create" element={<EtCreateCapabilitiesSalesType/>} />
          <Route path="update/:id" element={<EtUpdateCapabilitiesSalesType/>} />
          <Route path="delete/:id" element={<EtDeleteCapabilitiesSalesType />} />
        </Route> */}

        {/* 18 Nov 2024 */}
        {/* <Route path="capabilitiesSalesFunctionAreaTypes">
          <Route index element={<EtCapabilitiesSalesFunctionAreaTypeList/>} />
          <Route path="create" element={<EtCreateCapabilitiesSalesFunctionAreaType/>} />
          <Route path="update/:id" element={<EtUpdateCapabilitiesSalesFunctionAreaType/>} />
          <Route path="delete/:id" element={<EtDeleteCapabilitiesSalesFunctionAreaType />} />
        </Route> */}

        {/* 18 Nov 2024 */}
        {/* <Route path="capabilitiesSoftSkillTypes">
          <Route index element={<EtCapabilitiesSoftSkillTypeList/>} />
          <Route path="create" element={<EtCreateCapabilitiesSoftSkillType/>} />
          <Route path="update/:id" element={<EtUpdateCapabilitiesSoftSkillType/>} />
          <Route path="delete/:id" element={<EtDeleteCapabilitiesSoftSkillType />} />
        </Route> */}

        {/* 18 Nov 2024 */}
        {/* <Route path="capabilitiesSoftSkillFunctionAreaTypes">
          <Route index element={<EtCapabilitiesSoftSkillFunctionAreaTypeList/>} />
          <Route path="create" element={<EtCreateCapabilitiesSoftSkillFunctionAreaType/>} />
          <Route path="update/:id" element={<EtUpdateCapabilitiesSoftSkillFunctionAreaType/>} />
          <Route path="delete/:id" element={<EtDeleteCapabilitiesSoftSkillFunctionAreaType />} />
        </Route> */}

        {/* 19 Nov 2024 */}
        {/* <Route path="capabilitiesUserImprint">
          <Route index element={<EtUserMagic/>} />
          <Route path="industry/create" element={<EtCreateUserImprintCapabilitiesIndustry/>} />
          <Route path="industry/update" element={<EtUpdateUserImprintCapabilitiesIndustry/>} />
          <Route path="update/:id" element={<EtUpdateCapabilitiesSoftSkillFunctionAreaType/>} />
          <Route path="delete/:id" element={<EtDeleteCapabilitiesSoftSkillFunctionAreaType />} />
        </Route> */}

        {/* 02 Dec 2024 */}
        {/* <Route path="showcaseTypes">
          <Route index element={<EtShowcaseTypeList/>} />
          <Route path="create" element={<EtCreateShowcaseType/>} />
          <Route path="update/:id" element={<EtUpdateShowcaseType/>} />
          <Route path="delete/:id" element={<EtDeleteShowcaseType />} />
        </Route> */}
        
        {/* 02 Dec 2024 */}
        {/* <Route path="problemAreaTypes">
          <Route index element={<EtProblemAreaTypeList/>} />
          <Route path="create" element={<EtCreateProblemAreaType/>} />
          <Route path="update/:id" element={<EtUpdateProblemAreaType/>} />
          <Route path="delete/:id" element={<EtDeleteProblemAreaType />} />
        </Route> */}

        {/* 02 Dec 2024 */}
        {/* <Route path="problemMetricTypes">
          <Route index element={<EtProblemMetricTypeList/>} />
          <Route path="create" element={<EtCreateProblemMetricType/>} />
          <Route path="update/:id" element={<EtUpdateProblemMetricType/>} />
          <Route path="delete/:id" element={<EtDeleteProblemMetricType />} />
        </Route> */}
        
        {/* 02 Dec 2024 */}
        {/* <Route path="problemMetricUoMTypes">
          <Route index element={<EtProblemMetricUoMTypeList/>} />
          <Route path="create" element={<EtCreateProblemMetricUoMType/>} />
          <Route path="update/:id" element={<EtUpdateProblemMetricUoMType/>} />
          <Route path="delete/:id" element={<EtDeleteProblemMetricUoMType />} />
        </Route> */}
        
        
        {/* 02 Dec 2024 */}
        {/* <Route path="problemMetricGradationTypes">
          <Route index element={<EtProblemMetricGradationTypeList/>} />
          <Route path="create" element={<EtCreateProblemMetricGradationType/>} />
          <Route path="update/:id" element={<EtUpdateProblemMetricGradationType/>} />
          <Route path="delete/:id" element={<EtDeleteProblemMetricGradationType />} />
        </Route> */}

        {/* 09 Dec 2024 */}
        {/* <Route path="creationAreaTypes">
          <Route index element={<EtCreationAreaTypeList/>} />
          <Route path="create" element={<EtCreateCreationAreaType/>} />
          <Route path="update/:id" element={<EtUpdateCreationAreaType/>} />
          <Route path="delete/:id" element={<EtDeleteCreationAreaType />} />
        </Route> */}

        {/* 26 Dec 2024 */}
        {/* <Route path="legacyTypes">
          <Route index element={<EtLegacyTypeList/>} />
          <Route path="create" element={<EtCreateLegacyType/>} />
          <Route path="update/:id" element={<EtUpdateLegacyType/>} />
          <Route path="delete/:id" element={<EtDeleteLegacyType />} />
        </Route> */}

        {/* 09 Jan 2025 */}
        {/* <Route path="missionMetricUoMTypes">
          <Route index element={<EtMissionMetricUoMTypeList/>} />
          <Route path="create" element={<EtCreateMissionMetricUoMType/>} />
          <Route path="update/:id" element={<EtUpdateMissionMetricUoMType/>} />
          <Route path="delete/:id" element={<EtDeleteMissionMetricUoMType />} />
        </Route> */}
        
        
        {/* 09 Jan 2025 */}
        {/* <Route path="missionMetricGradationTypes">
          <Route index element={<EtMissionMetricGradationTypeList/>} />
          <Route path="create" element={<EtCreateMissionMetricGradationType/>} />
          <Route path="update/:id" element={<EtUpdateMissionMetricGradationType/>} />
          <Route path="delete/:id" element={<EtDeleteMissionMetricGradationType />} />
        </Route> */}

        {/* 07 Feb 2025 */}
        {/* <Route path="trinityMobile">
          <Route index element={<EtTrinityMobileHome1/>} />
          <Route path="conversation1" element={<EtUserConversationMobile/>} />
          <Route path="conversation2" element={<EtTrinityUserConversationMobile1/>} /> */}
          {/*<Route path="contribution1" element={<EtTrinityUserContributionMobile1/>} />  12 Feb 2025  */}
          {/* <Route path="impact1" element={<EtTrinityUserImpactMobile1/>} />             12 Feb 2025  */}
          {/* <Route path="win1" element={<EtTrinityUserWinMobile1/>} />                   12 Feb 2025   */}
          {/* <Route path="intention1" element={<EtTrinityUserIntentionMobile1/>} />       12 Feb 2025  */}
          {/* <Route path="gratitude1" element={<EtTrinityUserGratitudeMobile1/>} />       12 Feb 2025  */}
          {/* <Route path="challenge1" element={<EtTrinityUserChallengeWABMobile1/>} />    14 Feb 2025  */}
          {/* <Route path="creation1" element={<EtTrinityUserCreationMobile1/>} />         14 Feb 2025  */}
          {/* <Route path="action1" element={<EtTrinityUserActionMobile1/>} />             15 Feb 2025  */}
        {/* </Route> */}


        
      </Route>{" "}
      {/* End of all routes */}
    </Routes>
  )
}

export default App
