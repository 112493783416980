import React, {useState, useEffect} from 'react'
import useTitle from "@/hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
  } from "@heroicons/react/24/outline"
  import {
    Squares2X2Icon,
    ArrowTrendingUpIcon,
    PresentationChartLineIcon,
  } from "@heroicons/react/24/solid"
import { useGetOrgSubscriptionsQuery } from "@/features/orgSubscriptions/orgSubscriptionsApiSlice"
import { useGetUserTrailSessionPlansQuery } from '@/features/userTrailSessions/userTrailSessionPlansApiSlice'
import { useGetUserTrailSessionExecutionsQuery } from '@/features/userTrailSessions/userTrailSessionExecutionsApiSlice'
import { useGetSponsorUserPadsQuery } from '@/features/sponsorUserPads/sponsorUserPadsApiSlice'
import { useGetCoachUserPadsQuery } from '@/features/coachUserPads/coachUserPadsApiSlice'
import { useGetUsersQuery } from '@/features/users/usersApiSlice'

import EtUserTrailSessionsListComp from '@/features/userTrailSessions/EtUserTrailSessionsListComp'// 20 Oct 2024
import EtUserTrailSessionsListProdComp from '@/features/userTrailSessions/EtUserTrailSessionsListProdComp'// 23 Feb 2025
import EtSponsorUserPadListComp from '@/features/sponsorUserPads/EtSponsorUserPadListComp' // 21 Oct 2024
import EtCoachUserPadListComp from '@/features/coachUserPads/EtCoachUserPadListComp' // 21 Oct 2024
import EtSponsorUserPadListCompProd from '@/features/sponsorUserPads/EtSponsorUserPadListCompProd' // 03 Mar 2025
import EtCoachUserPadListCompProd from '@/features/coachUserPads/EtCoachUserPadListCompProd' // 03 Mar 2025

import EtSponsorCoachIcon1SVGComp from '@/assets/img/icons/svgComp/EtSponsorCoachIcon1SVGComp'
import EtCoachInductionIcon1SVGComp from '@/assets/img/icons/svgComp/EtCoachInductionIcon1SVGComp'


const EtUserInduction = ({userId, userOrgId}) => {
    // First Created - 19 Oct 2024
    // Author - Atul Pandey
    // Updated - 20 Oct 2024 - added user trail session list comp
    // Updated - 21 Oct 2024 - added sponsor user pad list comp
    // Updated - 23 Feb 2025 - changed the sesion listing comp from EtUserTrailSessionsListComp to EtUserTrailSessionsListProdComp
    // Updated - 03 Mar 2025 - changed the sponsor and coach user pad comp (prod)

    // 0 - Set Page Title
    useTitle('User Induction')
    

    // 1b - State variables to show / hide sections
    const [showSubscriptions, setShowSubscriptions ] = useState(false)
    const [showTrails, setShowTrails ] = useState(false)
    const [showUserSessions, setShowUserSessions ] = useState(false)
    const [showSponsorSessions, setShowSponsorSessions ] = useState(false)
    const [showCoachSessions, setShowCoachSessions ] = useState(false)
    const [totalSessionCounts, setTotalSessionCounts] = useState(0)

    // 2a - Get Data
    const {data: usersData } = useGetUsersQuery()
    const userInfo = usersData?.ids.map(id => usersData?.entities[id])?.filter(user => user._id === userId)
    const { data: orgSubscriptionsData } = useGetOrgSubscriptionsQuery()
    const orgSubscriptions = orgSubscriptionsData?.ids?.map(id => orgSubscriptionsData?.entities[id])
    const {data: userTrailSessionPlanData} = useGetUserTrailSessionPlansQuery()
    const {data: userTrailSessionExecutionData , isLoading: isExecutionDataLoading } = useGetUserTrailSessionExecutionsQuery()

    const { data: sponsorUserPadsData, isError, error, isLoading, refetch } = useGetSponsorUserPadsQuery()
    const sponsorUserPads = sponsorUserPadsData?.ids.map((id) => {
        const sponsorUserPad = sponsorUserPadsData?.entities[id]
        return sponsorUserPad
    })?.filter(pad => pad.orgId?._id === userOrgId && pad.sponsorId?._id === userId )

    const { data: coachUserPadsData, isError: isCoachPadError, error: coachPadError, isLoading: isCoachPadLoading, refetch: coachUserPadRefetch } = useGetCoachUserPadsQuery()
    const coachUserPads = coachUserPadsData?.ids.map((id) => {
        const coachUserPad = coachUserPadsData?.entities[id]
        return coachUserPad
    })?.filter(pad => pad.coachId?._id === userId )

    // console.log('Sponsor User Pads:', sponsorUserPads)
    // console.log('Coach User Pads:', coachUserPads)
    const sponsorSessionsCount = sponsorUserPads?.length || 0
    const coachSessionsCount = coachUserPads?.length || 0
    
    // console.log('OrgSubscriptions', orgSubscriptions)
    const userRoles = userInfo?.map(user => user.roles)
    // console.log('userInfo', userInfo)
    // console.log('userRoles', userRoles)

    // 2b - Filter user org subscriptions
    const userSubscriptions = orgSubscriptions?.filter(item => item.orgId._id === userOrgId )
    // console.log('User Subscriptions', userSubscriptions)

    // 2c - Get total trail count across all user subscriptions
    const totalTrailCount = userSubscriptions?.reduce((acc, subscription) => {
    // Loop through subsPackId array and add the length of subscriptionPackTrails
    subscription?.subsPackId?.forEach(subsPack => {
      acc += subsPack?.subscriptionPackTrails?.length || 0
    })
    return acc
    }, 0)

    // 2d - Get trail count by subsPack
    const trailCountBySubsPack = userSubscriptions?.reduce((acc, subscription) => {
        // Loop through subsPackId array
        subscription?.subsPackId?.forEach(subsPack => {
        const packName = subsPack?.subscriptionPackName || "Unnamed Pack"
        const trailCount = subsPack?.subscriptionPackTrails?.length || 0
    
        // Accumulate trail count by pack name
        acc[packName] = (acc[packName] || 0) + trailCount
        })
        return acc
    }, {})
    // console.log('Trail count by pack', trailCountBySubsPack)

    // 2e - Callback to handle total sessions from child component
    const handleTotalSessionsUpdate = (total) => {
        setTotalSessionCounts(total)
    }

    // 2f - get User trail sessions by Trail
    const getUserTrailSessionsByTrail = () => {
        if (!userTrailSessionPlanData || !userTrailSessionPlanData?.entities) return { groupedSessions: {}, totalSessions: 0 }
    
        const userSessions = Object.values(userTrailSessionPlanData?.entities).filter(
          (plan) => plan?.userId?._id?.toString() === userId?.toString()
        )
    
        const userSessionExecutions = userTrailSessionExecutionData?.entities ? 
          Object.values(userTrailSessionExecutionData?.entities).filter(
            (execution) => execution.userId._id === userId
          ) : []
    
        // Group sessions by trailId and count sessions
        const { groupedSessions, totalSessions } = userSessions?.reduce((acc, session) => {
          const trailId = session?.trailId?._id
          if (!acc.groupedSessions[trailId]) {
            acc.groupedSessions[trailId] = {
              trailInfo: session.trailId,
              sessions: [],
              sessionCount: 0, // Initialize session count per trail
            }
          }
    
          const sessionExecution = userSessionExecutions?.find(
            (execution) => execution.trailId._id === trailId && execution.sessionId._id === session.sessionId._id
          )
    
          const sessionData = {
            ...session,
            userTrailSessionStatus: sessionExecution ? sessionExecution?.userTrailSessionExecutionStatus : session.userTrailSessionStatus
          }
    
          acc.groupedSessions[trailId].sessions.push(sessionData)
          acc.groupedSessions[trailId].sessionCount += 1 // Increment session count for the trail
          acc.totalSessions += 1 // Increment total session count
    
          return acc
        }, { groupedSessions: {}, totalSessions: 0 })
    
        return { groupedSessions, totalSessions }
      }
    const { groupedSessions, totalSessions } = getUserTrailSessionsByTrail()
      
    //   console.log('totalSessions', totalSessions )

    // 3a - Group the data by coachId, organization, trails, sessions, and users
    const groupedData = coachUserPads?.reduce((acc, pad) => {
        const coachId = pad.coachId?.name || 'No Coach';
        const coachUsername = pad.coachId?.username || 'No Username';
        const orgId = pad.orgId?._id || 'No org id';
        const orgName = pad.userId.userOrgId?.organizationName || 'No Organization';
        const trailId=pad.trailId?._id || 'No Trail Id';
        const trailName = pad.trailId?.trailName || 'No Trail';
        const trailPic = pad.trailId?.pic || 'No Trail';
        const sessionId = pad.sessionId?._id || 'No Session';

        const session = {
            sessionId: sessionId,
            sessionName: pad.sessionId?.sessionName || 'No Session',
            sessionPic: pad.sessionId?.pic || '',
            sessionDesc: pad.sessionId?.sessionDesc || '',
            users: []
        };

        const user = {
            userId: pad.userId?._id,
            userName: pad.userId?.name,
            username: pad.userId?.username,
            userEmail: pad.userId?.email,
            ...pad // Other properties from pad if necessary
        };
        // console.log('user', user)

        if (!acc[coachId]) acc[coachId] = { username: coachUsername, orgs: {} };                                                                                                             
        if (!acc[coachId].orgs[orgId]) acc[coachId].orgs[orgId] = { name: orgName, trails: {} };
        if (!acc[coachId].orgs[orgId].trails[trailName]) {
            acc[coachId].orgs[orgId].trails[trailName] = { 
                id:trailId,pic: trailPic, 
                sessions: {},
                sessionCount: 0  // 21 Oct 2024 - added session count
            }
          }; 
        if (!acc[coachId].orgs[orgId].trails[trailName].sessions[sessionId]) {
            acc[coachId].orgs[orgId].trails[trailName].sessions[sessionId] = { ...session }
            acc[coachId].orgs[orgId].trails[trailName].sessionCount += 1;    
        }; 
        
        // 21 Oct 2024 - added the if logic to filter out the sponsor from the users list
        if (user.userId._id!== userId) {
            // console.log('user.userId._id', user.userId._id)
            // console.log('coachId', userId)
            // console.log('user.userId._id !== userId ?', user.userId._id !== userId)

        acc[coachId].orgs[orgId].trails[trailName].sessions[sessionId].users.push(user);
        }
        
        return acc;
    }, {});

    // console.log('Grouped Data:', groupedData);
   
    // 3b - Initialize an object to store trail session counts
    const trailCoachSessionCounts = {};

    // 3c - Iterate over groupedData to get session counts for each trail
    if (groupedData && Object.keys(groupedData).length > 0) {
        Object.values(groupedData)?.forEach(coach => {
        Object.values(coach?.orgs)?.forEach(org => {
            Object.values(org?.trails)?.forEach(trail => {
            const trailName = trail?.trailName || 'No Trail';
            const sessionCount = trail?.sessionCount || 0;

            // Update the session count for each trail
            if (!trailCoachSessionCounts[trailName]) {
                trailCoachSessionCounts[trailName] = 0;
            }
            trailCoachSessionCounts[trailName] += sessionCount;
            });
        });
        });
    }

    // 3d - Calculate total number of sessions across all trails
    const totalCoachSessions = Object.values(trailCoachSessionCounts)?.reduce((acc, count) => acc + count, 0);

    // a - Group the data by sponsorId, organization, trails, sessions, and users
    const groupedSponsorData = sponsorUserPads?.reduce((acc, pad) => {
        const sponsorId = pad.sponsorId?._id || 'No Sponsor Id'; //  08 Sep 2024 Get the sponsor ID
        const sponsorName = pad.sponsorId?.name || 'No Sponsor'; // 08 Sep 2024 Get the sponsor name
        const sponsorUsername = pad.sponsorId?.username || 'No Username';
        const sponsorOrgRole = pad.sponsorId?.userOrgRole?.orgRoleName || 'No Role';    // 07 Sep 2024
        const sponsorOrgTitle = pad.sponsorId?.userOrgTitle?.orgTitleName || 'No Role'; // 07 Sep 2024
        const orgId = pad.orgId?._id || 'No org id';
        const orgName = pad.userId.userOrgId?.organizationName || 'No Organization';
        const trailId=pad.trailId?._id || 'No Trail Id';
        const trailName = pad.trailId?.trailName || 'No Trail';
        const trailPic = pad.trailId?.pic || 'No Trail';
        const sessionId = pad.sessionId?._id || 'No Session';

        const session = {
            sessionId: sessionId,
            sessionName: pad.sessionId?.sessionName || 'No Session',
            sessionPic: pad.sessionId?.pic || '',
            sessionDesc: pad.sessionId?.sessionDesc || '',
            users: []
        };

        const user = {
            userId: pad.userId?._id,
            userName: pad.userId?.name,
            username: pad.userId?.username,
            userEmail: pad.userId?.email,
            ...pad // Other properties from pad if necessary
        };

        if (!acc[sponsorId]) acc[sponsorId] = { name: sponsorName, username: sponsorUsername, role:sponsorOrgRole, title:sponsorOrgTitle, orgs: {} };  // added role and title - 07 Sep 2024                                                                                   
        if (!acc[sponsorId].orgs[orgId]) acc[sponsorId].orgs[orgId] = { name: orgName, trails: {} };
        if (!acc[sponsorId].orgs[orgId].trails[trailName]) {
            acc[sponsorId].orgs[orgId].trails[trailName] = { 
                id:trailId,
                pic: trailPic, 
                sessions: {},
                sessionCount: 0  // 21 Oct 2024 - added session count
            }
        }; 
        if (!acc[sponsorId].orgs[orgId].trails[trailName].sessions[sessionId]) {
            acc[sponsorId].orgs[orgId].trails[trailName].sessions[sessionId] = { ...session }
            acc[sponsorId].orgs[orgId].trails[trailName].sessionCount += 1
        }; 
        
        // 21 Oct 2024 - added the if logic to filter out the sponsor from the users list
        if (user.userId._id !== userId) {
            acc[sponsorId].orgs[orgId].trails[trailName].sessions[sessionId].users.push(user);
        }
        // console.log('users', user)
        return acc;
    }, {});

    // console.log('Grouped Sponsor Data:', groupedSponsorData);

    // 4b - Initialize an object to store trail session counts
    const trailSponsorSessionCounts = {};

    // 4c - Iterate over groupedData to get session counts for each trail
    if (groupedSponsorData && Object.keys(groupedSponsorData).length > 0) {
        Object.values(groupedSponsorData)?.forEach(sponsor => {
        Object.values(sponsor?.orgs)?.forEach(org => {
            Object.values(org?.trails)?.forEach(trail => {
            const trailId = trail?.id || 'No Id';
            const sessionCount = trail?.sessionCount || 0;

            // Update the session count for each trail
            if (!trailSponsorSessionCounts[trailId]) {
                trailSponsorSessionCounts[trailId] = 0;
            }
            trailSponsorSessionCounts[trailId] += sessionCount;
            });
        });
        });
    }

    // 4d - Calculate total number of sessions across all trails
    const totalSponsorSessions = Object.values(trailSponsorSessionCounts)?.reduce((acc, count) => acc + count, 0);
    
    // 4 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
    }

  return (
    <div>
        {/* Section 1 - Your Subscriptions */}
        <div className=' m-1 p-1 bg-gray-200 rounded '>
            <div className='grid grid-cols-8 justify-start items-center'>
                <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                    <div
                        onClick={() => setShowSubscriptions(prev => !prev)}
                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                        >
                        {showSubscriptions ? (
                            <MinusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                            <PlusIcon className="h-4 w-4 text-orange-600" />
                        )}
                    </div>
                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowSubscriptions(prev => !prev)} ><Squares2X2Icon  className='h-6 w-6 text-orange-600'/></div>
                    <div>
                        <p className='mx-1 px-1 font-bold text-gray-600'>Packs</p>
                        <p className='mx-1 px-1 text-gray-700 text-xs italic'>what induction powers can I avail?</p>
                    </div>     
                </div>
                <div className='col-span-1 flex justify-start items-center'>
                    {/* <div><p className='text-[10px] text-gray-400 mx-1 px-1 '>Packs :</p></div> */}
                    <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{userSubscriptions?.length}</p></div>
                </div>
            </div>
            
            {showSubscriptions && (
                <div>
                    <UserSubscriptions subscriptions={userSubscriptions} createMarkup={createMarkup}/>
                </div>
            )}
        </div>

        {/* Section 2 - Trails */}
        <div className='m-1 p-1 bg-gray-200 rounded '>
            <div className='grid grid-cols-8 justify-start items-center'>
                <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                    <div
                        onClick={() => setShowTrails(prev => !prev)}
                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                        >
                        {showTrails ? (
                            <MinusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                            <PlusIcon className="h-4 w-4 text-orange-600" />
                        )}
                    </div>
                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowTrails(prev => !prev)} ><ArrowTrendingUpIcon className='h-6 w-6 text-orange-600'/></div>
                    <div>
                        <p className='mx-1 px-1 font-bold text-gray-600'>Trails</p>
                        <p className='mx-1 px-1 text-gray-700 text-xs italic'>what are my induction pathways?</p>
                    </div>
                </div>
                <div className='col-span-1 flex justify-start items-center'>
                    {/* <div><p className='text-[10px] text-gray-400 mx-1 px-1 '>Trails :</p></div> */}
                    <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{totalTrailCount}</p></div>
                </div>
            </div>
            
            {showTrails && (
                <div>
                    <UserTrails subscriptions={userSubscriptions} createMarkup={createMarkup} trailCountBySubsPack={trailCountBySubsPack}/>
                </div>
            )}
        </div>
        
        {/* Section 3 - User Sessions */}
        <div className='m-1 p-1 bg-gray-200 rounded'>
            <div className='grid grid-cols-8 justify-start items-center'>
                <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                    <div
                        onClick={() => setShowUserSessions(prev => !prev)}
                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                        >
                        {showUserSessions ? (
                            <MinusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                            <PlusIcon className="h-4 w-4 text-orange-600" />
                        )}
                    </div>
                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowUserSessions(prev => !prev)} ><PresentationChartLineIcon  className='h-6 w-6 text-orange-600'/></div>
                    <div>
                        <p className='mx-1 px-1 font-bold text-gray-600'>User Sessions</p>
                        <p className='mx-1 px-1 text-gray-700 text-xs italic'>what will be my induction journey?</p>
                    </div>
                </div>
                <div className='col-span-1 flex justify-start items-center'>
                    {/* <div ><p className='text-[10px] text-gray-700 mx-1 px-1 '>Sessions :</p></div> */}
                    <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{totalSessions}</p></div>
                </div>
            </div>

            {showUserSessions && (
                <div>
                    {/* <EtUserTrailSessionsListComp userId={userId}/> */}
                    <EtUserTrailSessionsListProdComp userId={userId}/> {/* 23 Feb 2025 */}
                </div>
            )}
        </div>
        
        {/* Section 4 - Sponsor Sessions */}
        {userRoles && userRoles[0]?.includes("OrgSponsor") && (
        <div className='m-1 p-1 bg-gray-200 rounded'>
            <div className='grid grid-cols-8 justify-start items-center'>
                <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                    <div
                        onClick={() => setShowSponsorSessions(prev => !prev)}
                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                        >
                        {showSponsorSessions ? (
                            <MinusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                            <PlusIcon className="h-4 w-4 text-orange-600" />
                        )}
                    </div>
                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowSponsorSessions(prev => !prev)} ><EtSponsorCoachIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                    <div>
                        <p className='mx-1 px-1 font-bold text-gray-600'>Sponsor Sessions</p>
                        <p className='mx-1 px-1 text-gray-700 text-xs italic'>how, as a sponsor, will I induce my team?</p>
                    </div>
                </div>
                <div className='col-span-1 flex justify-start items-center'>
                    {/* <div ><p className='text-[10px] text-gray-700 mx-1 px-1 '>Sessions :</p></div> */}
                    <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{totalSponsorSessions}</p></div>
                </div>
            </div>

            {showSponsorSessions && (
                <div>
                    {/* <EtSponsorUserPadListComp sponsorId={userId} sponsorOrgId={userOrgId}/> */}
                     <EtSponsorUserPadListCompProd sponsorId={userId} sponsorOrgId={userOrgId}/>{/* 03 Mar 2025 */}
                </div>
            )}
        
        </div>)}

        {/* Section 5 - Coach Sessions */}
        {userRoles && userRoles[0]?.includes("EtCoach") && (
        <div className='m-1 p-1 bg-gray-200 rounded'>
            <div className='grid grid-cols-8 justify-start items-center'>
                <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                    <div
                        onClick={() => setShowCoachSessions(prev => !prev)}
                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                        >
                        {showCoachSessions ? (
                            <MinusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                            <PlusIcon className="h-4 w-4 text-orange-600" />
                        )}
                    </div>
                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" onClick={() => setShowCoachSessions(prev => !prev)} ><EtCoachInductionIcon1SVGComp  className='h-6 w-6 text-orange-600'/></div>
                    <div>
                        <p className='mx-1 px-1 font-bold text-gray-600'>Coach Sessions</p>
                        <p className='mx-1 px-1 text-gray-700 text-xs italic'>how,as a coach, will I induce my cohorts?</p>
                    </div>
                </div>
                <div className='col-span-1 flex justify-start items-center'>
                    {/* <div ><p className='text-[10px] text-gray-700 mx-1 px-1 '>Sessions :</p></div> */}
                    <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{totalCoachSessions}</p></div>
                </div>
            </div>

            {showCoachSessions && (
                <div>
                    {/* <EtCoachUserPadListComp coachUserId={userId} coachOrgId={userOrgId}/> */}
                     <EtCoachUserPadListCompProd coachUserId={userId} coachOrgId={userOrgId}/> {/* 03 Mar 2025 */}
                </div>
            )}

        </div>)}

    </div>
  )
}

const UserSubscriptions = ({subscriptions = [], createMarkup}) => {
    // First Created - 19 Oct 2024
    // Author - Atul Pandey

    console.log('subscriptions', subscriptions)

    return(
        <div className='bg-gray-50 m-1 p-1 rounded'>
            {subscriptions.length === 0 && (
                <div className='m-1 p-1'>
                    <p className='mx-1 px-1 text-orange-600 text-sm'> No subscriptions have been assigned yet for your org. This could change anytime!</p>
                </div>
            )}

           {subscriptions.length>0 && subscriptions?.map((item, itemIndex) => {

            const subsPackName = item.subsPackId[0].subscriptionPackName
            const subsPackDesc = item.subsPackId[0].subscriptionPackDesc
            const subsPackPic = item.subsPackId[0].pic
            
            return (
                <div key={item._id+ itemIndex} className='m-1 p-1 flex justify-start items-center bg-gray-100 font-poppins'>
                    <div className='m-1 p-1'><p className='text-4xl mx-1 px-1 text-gray-700 font-bold'>{itemIndex+1}.</p></div>
                    <div className='rounded-full flex justify-center items-center m-1 p-1'>
                        <img src={subsPackPic} alt="" className='h-10 w-10'/>
                    </div>
                    <div className='m-1 p-1'>
                        <p className='text-gray-600 mx-1 px-1 font-bold'>{subsPackName}</p>
                        <p dangerouslySetInnerHTML={createMarkup(subsPackDesc)} className='text-xs text-gray-600 mx-1 px-1 max-w-xl'></p>
                    </div>
                </div>
            )
           })}
        </div>
    )


}

const UserTrails = ({subscriptions = [], createMarkup, trailCountBySubsPack}) => {
    // First Created - 19 Oct 2024
    // Author - Atul Pandey

    // 0 - Validate incoming data
    console.log('subscriptions', subscriptions)

    // 1 - State variables for toggle / show trails
    const [ showTrails, setShowTrails] = useState({})

    // 2 - Toggle show trail
    const handleToggleShowTrails = (trailId) => {
        setShowTrails((prev) => ({
            ...prev,
            [trailId]: !prev[trailId],
          }))
    }

    return(
        <div className='bg-gray-50 m-1 p-1 rounded'>
            {subscriptions.length === 0 && (
                <div className='m-1 p-1'>
                    <p className='mx-1 px-1 text-orange-600 text-sm'> No trails are available for you as no subscriptions have been assigned yet for your org. This could change anytime!</p>
                </div>
            )}

           {subscriptions.length>0 && subscriptions?.map((item, itemIndex) => {

            const subsPackName = item.subsPackId[0].subscriptionPackName
            const subsPackPic = item.subsPackId[0].pic
            const subsPackTrails = item.subsPackId[0].subscriptionPackTrails
            const subsPackId = item.subsPackId[0]._id

            
            return (
                <div key={item._id+ itemIndex} className='m-1 p-1  bg-gray-100 font-poppins'>
                    <div className='grid grid-cols-8 justify-start items-center'>
                        <div className='col-span-2 flex justify-start items-center'>
                            <div
                                onClick={() => handleToggleShowTrails(subsPackId)}
                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                >
                                {showTrails[subsPackId] ? (
                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                )}
                            </div>
                        
                        <div className='rounded-full flex justify-center items-center m-1 p-1'>
                            <img src={subsPackPic} alt="" className='h-6 w-6'/>
                        </div>
                        <div className='m-1 p-1'>
                            <p className='text-gray-600 mx-1 px-1 font-bold text-xs'>{subsPackName}</p>
                        </div>
                        
                        </div>
                        <div className='col-span-1 flex justify-start items-center'>
                            <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{trailCountBySubsPack[subsPackName]}</p></div>
                        </div>
                    </div>
                    
                    {showTrails[subsPackId] && subsPackTrails.length>0 && subsPackTrails?.map((trail, trailIndex) =>{

                        const trailId = trail.packTrailId._id
                        const trailName = trail.packTrailId.trailName
                        const trailDesc = trail.packTrailId.trailDesc
                        const trailPic = trail.packTrailId.pic

                        return(
                            <div key={trailId+trailIndex} className='m-1 p-1 bg-gray-50 rounded'>
                                <div className='flex justify-start items-center'>
                                    <div className='m-1 p-1'><p className='mx-1 px-1 text-gray-700 font-bold'>{trailIndex+1}.</p></div>
                                    <div className='rounded-full flex justify-center items-center m-1 p-1'>
                                        <img src={trailPic} alt="" className='h-10 w-10'/>
                                    </div>
                                    <div className='m-1 p-1'>
                                        <p className='text-gray-600 mx-1 px-1 font-bold'>{trailName}</p>
                                        <p dangerouslySetInnerHTML={createMarkup(trailDesc)} className='text-[10px] text-gray-600 mx-1 px-1'></p>
                                    </div>
                                </div>

                            </div>
                        )
                    }) }

                </div>
            )
           })}
        </div>
    )

}

export default EtUserInduction