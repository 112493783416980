
    import React from 'react';
    
    const EtIlluminateActionsIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" fill="currentColor">
          <g transform="translate(-399 -101)"><path d="M417.72 183.8 403.608 201.656C402.024 203.672 402.312 206.696 404.328 208.28 405.192 209 406.2 209.288 407.208 209.288 408.648 209.288 409.944 208.712 410.952 207.56L424.632 190.28 418.008 184.088 417.72 183.8Z" fillRule="evenodd"/><path d="M444.504 162.056 438.168 157.16C437.016 156.152 435.432 155.864 433.848 156.152 432.408 156.44 431.112 157.304 430.248 158.6L420.024 175.16C418.728 177.032 419.016 179.624 420.744 181.208L430.68 190.568 425.064 202.808C424.056 205.112 425.064 207.992 427.368 209 427.944 209.288 428.664 209.432 429.384 209.432 431.112 209.432 432.84 208.424 433.704 206.696L440.76 191.288C441.624 189.416 441.192 187.256 439.752 185.96L433.416 180.056 441.768 169.544 451.848 172.424C452.28 172.568 452.712 172.568 453.144 172.568L462.648 171.848C462.792 169.112 463.08 166.52 463.656 163.928L453.288 164.648Z" fillRule="evenodd"/><path d="M444.36 158.312C448.536 160.328 453.432 158.456 455.304 154.424 457.32 150.248 455.448 145.352 451.416 143.48 447.24 141.464 442.344 143.336 440.472 147.368 438.456 151.4 440.184 156.296 444.36 158.312Z" fillRule="evenodd"/><path d="M539.4 173C539.4 185.964 532.484 197.944 521.256 204.426 510.028 210.908 496.196 210.908 484.968 204.426 473.74 197.944 466.824 185.964 466.824 173 466.824 160.036 473.74 148.056 484.968 141.574 496.196 135.092 510.028 135.092 521.256 141.574 532.484 148.056 539.4 160.036 539.4 173" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtIlluminateActionsIcon1SVGComp;
    