import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userConversationsAdapter = createEntityAdapter({})

const initialState = userConversationsAdapter.getInitialState()

export const userConversationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserConversations: builder.query({
            query: () => ({
                url: '/userConversations/getUserConversations',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserConversations = responseData.map(userConversation => {
                    userConversation.id = userConversation._id
                    return userConversation
                })
                return userConversationsAdapter.setAll(initialState, loadedUserConversations)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserConversation', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserConversation', id}))
                    ]
                } else {
                    return [ { type: 'UserConversation', id: 'LIST'}]
                }
            },
        }),
        createNewUserConversation: builder.mutation({
            query: initialUserConversation => ({
                url: '/userConversations/createNewUserConversation',
                method: 'POST',
                body: {...initialUserConversation,}
            }),
            invalidatesTags: [
                { type: 'UserConversation', id: "LIST" }
            ] 
        }),
        updateUserConversation: builder.mutation({
            query: initialUserConversation => ({
                url: '/userConversations/updateUserConversation',
                method: 'PATCH',
                body: {...initialUserConversation,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserConversation', id: arg.id },
                { type: 'UserConversation', id: 'LIST' }
            ],
        }),
        deleteUserConversation: builder.mutation({
            query: ({id}) => ({
                url: '/userConversations/deleteUserConversation',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserConversation', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserConversationsQuery,
    useCreateNewUserConversationMutation,
    useUpdateUserConversationMutation,
    useDeleteUserConversationMutation,
} = userConversationsApiSlice

// returns the query result object
export const selectUserConversationResult = userConversationsApiSlice.endpoints.getUserConversations.select()

// create memoized selector
const selectUserConversationsData = createSelector(
    selectUserConversationResult,
    userConversationResult => userConversationResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserConversations,
    selectById: selectUserConversationById,
    selectIds: selectUserConversationIds,
} = userConversationsAdapter.getSelectors(state => selectUserConversationsData(state) ?? initialState)