// Functions  to format the date

import { format } from 'date-fns'; // 18 Sep 2024

// 1 - Date with superscript like 2nd, 25th etc
export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'long', year: 'numeric' };
    const day = date.getDate();
  
    // Determine the correct ordinal indicator
    const ordinal = (n) => {
        let s = ["th", "st", "nd", "rd"],
            v = n % 100;
        return s[(v - 20) % 10] || s[v] || s[0]; // Only returns the suffix
    }
    
   return (
    <>
      {day}
      <sup>{ordinal(day)}</sup> 
      {' '} 
      {new Intl.DateTimeFormat('en-GB', options).format(date)}
    </>
  )
  }
// 1a - Date with superscript like 2nd, 25th etc
export const formatShortDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'short', year: 'numeric' };
    const day = date.getDate();
  
    // Determine the correct ordinal indicator
    const ordinal = (n) => {
        let s = ["th ", "st ", "nd ", "rd "],
            v = n % 100;
        return s[(v - 20) % 10] || s[v] || s[0]; // Only returns the suffix
    }
    
   return (
    <>
      {day}
      <sup>{ordinal(day)}</sup>{' '} {/* Adds explicit space after ordinal */}
      {new Intl.DateTimeFormat('en-GB', options).format(date).replace(/ /g, '  ')}{/* Replace to ensure spacing */}
    </>
  )
  }

  // 2 - Created 09 Jun 2024
  export const formatDateISO = (dateString) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  // 3 - Created 11 Jun 2024
  export const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    const day = date.getDate();
  
    // Determine the correct ordinal indicator
    const ordinal = (n) => {
      let s = ["th", "st", "nd", "rd"],
        v = n % 100;
      return s[(v - 20) % 10] || s[v] || s[0]; // Only returns the suffix
    };
  
   // const timeString = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
  
    return (
      <>
        {day}<sup>{ordinal(day)}</sup> {new Intl.DateTimeFormat('en-GB', options).format(date)}
      </>
    );
  }

  // 4 - Created 22 Jul 2024
  export const formatEventDateTime = (date, time) => {
    return new Date(`${date.split('T')[0]}T${time}:00`);
  };

  // 5 - Created 18 Sep 2024

  export const formatWeekRange = (weekStarting, weekEnding) => {
    const startDate = new Date(weekStarting);
    const endDate = new Date(weekEnding);

    // Modify formatDay to wrap the suffix in <sup> tags
    const formatDay = (date) => {
        const dayWithSuffix = format(date, 'do'); // Get day with suffix, e.g., "1st", "2nd"
        return dayWithSuffix.replace(/(\d+)(\w+)/, (match, day, suffix) => {
            return `${day}<sup>${suffix}</sup>`; // Wrap the suffix in <sup>
        });
    };

    const formatMonth = (date) => format(date, 'MMM'); // Short month name
    const formatYear = (date) => format(date, 'yyyy'); // Full year

    const startDay = formatDay(startDate);
    const endDay = formatDay(endDate);
    const startMonth = formatMonth(startDate);
    const endMonth = formatMonth(endDate);
    const startYear = formatYear(startDate);
    const endYear = formatYear(endDate);

    // Case 1: Same month and year
    if (startMonth === endMonth && startYear === endYear) {
        return `${startDay} - ${endDay} ${startMonth} ${startYear}`;
    }
    // Case 2: Same year, different months
    else if (startYear === endYear) {
        return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
    }
    // Case 3: Different year (fallback case)
    else {
        return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
    }
};

// 6 - Helper function to get the nearest Monday - created 15 Sep 2024
export const getNearestMonday = (date) => {
  const dayOfWeek = date.getDay(); // 0 for Sunday, 1 for Monday, etc.
  const daysSinceMonday = (dayOfWeek === 0) ? 6 : dayOfWeek - 1; // If Sunday, move 6 days back; otherwise, move back to Monday
  const nearestMonday = new Date(date);
  nearestMonday.setDate(date.getDate() - daysSinceMonday);
  return nearestMonday;
};

// 7 - Format date as dd-Mmm-yyyy - created 23 Sep 2024
export const formatShortPlainDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };

  return new Intl.DateTimeFormat('en-GB', options).format(date).replace(/ /g, '-');
};

// 8 - Format Date in Mmm YYYY format - 29 Oct 2024
export function formatDateToMonthYear(dateString) {
  // Check if dateString is null or undefined
  if (!dateString || isNaN(new Date(dateString))) return "Today";
  
  // Convert the dateString to a Date object
  const date = new Date(dateString);
  
  // Define an array of month names
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
  // Extract the month and year from the date object
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  
  // Return formatted string
  return `${month} ${year}`;
}

// 9 - Format date to YYYY - 29 Oct 2024
export function formatDateToYear(dateString) {
  // Check if dateString is null or undefined
  if (!dateString) return "Today";
  
  // Convert the dateString to a Date object
  const date = new Date(dateString);
  
  // Extract the year from the date object
  const year = date.getFullYear();
  
  // Return formatted year
  return year.toString();
}

// 10 - Calculate the difference in years and months between two dates - 27 Nov 2024
export function calculateYearsAndMonths(startDate, endDate= new Date()) {

  // Ensure both dates are valid
  if (!startDate) {
    throw new Error("Start date is required");
  }
  
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isNaN(start) || isNaN(end)) {
    throw new Error("Invalid dates provided");
  }

  let years = end.getFullYear() - start.getFullYear();
  let months = end.getMonth() - start.getMonth();

  // Adjust if months are negative
  if (months < 0) {
    years -= 1;
    months += 12;
  }

  // Round up for partial months
  const daysDifference = end.getDate() - start.getDate();
  if (daysDifference > 0) {
    months += 1;
  }

  // Handle case where months exceed 12 due to rounding
  if (months >= 12) {
    years += 1;
    months -= 12;
  }

  // Ensure at least 1 month if the difference is less than a month
  if (years === 0 && months === 0) {
    months = 1;
  }

  return { years, months };
}



  