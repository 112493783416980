
    import React from 'react';
    
    const EtShowcaseHeadwindIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" fill="currentColor">
          <g transform="translate(-339 -301)"><path d="M378.888 424.696 344.76 390.568C341.88 387.688 341.88 383.08 344.76 380.2 347.64 377.32 352.248 377.32 355.128 380.2L376.872 401.944 376.872 350.536C376.872 333.256 390.984 319.144 408.264 319.144L472.2 319.144C476.232 319.144 479.544 322.456 479.544 326.488 479.544 330.52 476.232 333.832 472.2 333.832L408.264 333.832C398.904 333.832 391.416 341.464 391.416 350.68L391.416 402.088 413.16 380.2C416.04 377.32 420.648 377.32 423.528 380.2 424.968 381.64 425.688 383.512 425.688 385.384 425.688 387.256 424.968 389.128 423.528 390.568L389.256 424.696C386.376 427.576 381.768 427.576 378.888 424.696Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtShowcaseHeadwindIcon1SVGComp;
    