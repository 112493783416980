import React from 'react'
//import { useNavigate } from 'react-router'
import useTitle from '@/hooks/useTitle'
import { useGetQuestionsQuery } from './questionsApiSlice'
//import { addIcon } from '../../assets/img/imgAssets'

const EtQuestionListBySet = ({setname}) => {
    //const navigate = useNavigate()
    useTitle('Question List by set')
    // Use the RTK Query hook to get question sets
  const { data: questions, isError, error, isLoading } = useGetQuestionsQuery()
//   console.log('questions:', questions)

// Create an object for setting inner HTML
const createMarkup = (htmlContent) => {
  return { __html: htmlContent }
}

  // Optional: handle loading and error states
  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error occurred: {error?.data?.message}</div>;

  return (
    <div className=" bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
      <div className="flex justify-start items-center p-1 m-1">
        <div>
          <p className="p-1 m-2 font-bold font-poppins text-lg">Questions for the question set <span className='rounded-full m-1 py-1 px-3 bg-gray-300 text-xl'>{setname}</span></p>
        </div>
        {/* <div>
          <img
            src={addIcon}
            alt=""
            className="h-10 w-10 cursor-pointer"
            onClick={() => navigate("/questions/create")}
          />
        </div> */}
      </div>
      {/* Header */}
      <div
        id="header"
        className="grid grid-cols-7 space-x-2 mx-3 my-1 px-1 py-3 text-black font-bold rounded bg-gradient-to-r from-orange-600 to-orange-600 via-orange-400 shadow-md shadow-black"
      >
        <div className="col-span-1">
          <p className="font-poppins text-xs text-left px-2">#</p>
        </div>
        <div className="col-span-2">
          <p className="font-poppins text-xs text-left px-2">Question</p>
        </div>
        <div className="col-span-2">
          <p className="font-poppins text-xs text-left px-2">Sub Text</p>
        </div>
        <div className="col-span-2">
          <p className="font-poppins text-xs text-left px-2">Hint Text</p>
        </div>
      </div>

      {/* Data */}

      {questions &&
        questions.ids
         .filter(id =>{
            // Only include questions whose set name matches the `setname` prop
            const question = questions.entities[id];
            return setname ? question.qsetName === setname : true;
         }).map((id, index) => {
          const { questionName, questionSubText, questionHintText } =
            questions.entities[id]
          return (
            <div
              id="data"
              className="grid grid-cols-7 space-x-2 mx-3 my-1 px-1 py-3 bg-white rounded items-center"
            >
              <div className="col-span-1">
                <p className="font-poppins text-xs text-left px-2">
                  {index+1}
                </p>
              </div>
              <div
                className="col-span-2 font-poppins text-xs text-left px-2"
                dangerouslySetInnerHTML={createMarkup(questionName)}
              ></div>
              <div className="col-span-2">
                <p className="font-poppins text-xs text-left px-2">
                  {questionSubText}
                </p>{" "}
              </div>
              <div className="col-span-2">
                <p className="font-poppins text-xs text-left px-2">
                  {questionHintText}
                </p>
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default EtQuestionListBySet