import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import useTitle from '../../hooks/useTitle'
import PulseLoader from "react-spinners/PulseLoader"
import { getTimeSince } from '../../utils/EtGetTimeSince' 
import { formatDateTime } from '../../utils/formatDate'
import EtContentEditorLight2 from "../../utils/EtContentEditorLight2"
import EtBtnFormBasic from "../../components/common/buttons/EtBtnFormBasic"
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon,  } from '@heroicons/react/24/outline'
import {
  RocketLaunchIcon,
  DocumentTextIcon,
  CursorArrowRippleIcon,
  Square3Stack3DIcon , ListBulletIcon, SwatchIcon, 
  ClockIcon,
  PresentationChartBarIcon,
  ClipboardDocumentListIcon,
  SparklesIcon,
  QuestionMarkCircleIcon,
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/solid"
import { useGetUserSessionInvestigationsQuery, useUpdateUserSessionInvestigationMutation } from './userSessionInvestigationApiSlice'
import { useGetUserQuestionResponseWithScoresQuery } from '../userQuestionResponseWithScores/userQuestionResponseWithScoresApiSlice' // 12 Jul 2024
import EtUserActionListComp from '../userActions/EtUserActionListComp'
import EtModalCreateUserAction from '../../components/common/modal/EtModalCreateUserAction'
import { createSvgComponentFromBase64 } from '@/utils/createSvgComponentFromBase64' // 07 Jul 2024
import { 
  SectionData1, 
  SectionData2,
  SectionData3,
  SectionData4,
  SectionData5,
  SectionData6, 
} from '@/data/pixelGridData' // 08 Jul 2024
import EtCommSkillsIconSVGComp from '@/assets/img/icons/svgComp/EtCommSkillsIconSVGComp'
import EtTeamworkIconSVGComp from '@/assets/img/icons/svgComp/EtTeamworkIconSVGComp'
import EtEncourageIconSVGComp from '@/assets/img/icons/svgComp/EtEncourageIconSVGComp'
import EtYouIconSVGComp from '@/assets/img/icons/svgComp/EtYouIconSVGComp'
import EtYourLeaderIconSVGComp from '@/assets/img/icons/svgComp/EtYourLeaderIconSVGComp'
// 16 Jul 2024
import EtMagnetIconSVGComp from '@/assets/img/icons/svgComp/EtMagnetIconSVGComp'
import EtMeasureLightIconSVGComp from '@/assets/img/icons/svgComp/EtMeasureLightIconSVGComp'
import EtScoresIconSVGComp from '@/assets/img/icons/svgComp/EtScoresIconSVGComp'
import EtInterpretIconSVGComp from '@/assets/img/icons/svgComp/EtInterpretIconSVGComp'
import EtVarianceIconSVGComp from '@/assets/img/icons/svgComp/EtVarianceIconSVGComp'
import EtUserIconSVGComp from '@/assets/img/icons/svgComp/EtUserIconSVGComp'
import EtCreateUserAssessmentResponseWithScoresComp from '../userAssessmentResponseWithScores/EtCreateUserAssessmentResponseWithScoresComp'
// 16 Jul 2024 - Dialog for displaying question responses when a score tile is clicked inside the Question Scorecard
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'; // Add MUI components
import { red } from '@mui/material/colors'


const EtUserTrailSessionInvestigationCompProd = () => {
  // First Created - 02 Jul 2024
  // Author - Atul Pandey
  // Copied and refined from EtUserTrailSessionInvestigation
  // Updated - 23 Feb 2025 - Copied and Renamed for prod deployment

  // 0 - Set page Title
  useTitle('User Session - Investigation')

  // 1 - Extrat userId, TrailId and SessionId from the page url
  const {userId, trailId, sessionId} = useParams()

  // 1a - Initialize showDetails state as an object where key is the organization index
  const [showDetails, setShowDetails] = useState(false)
  const [showAssessment, setShowAssessment] = useState(false); // 02 Jul 2024
  const [showPostAssessment, setShowPostAssessment] = useState(false); // 02 Jul 2024
  const [isAssessmentStarted, setIsAssessmentStarted] = useState(false) // 12 Jul 2024
  
  
  // 2 - Extract user investigation session data from the DB using RTK query
  const {data: userInvestigationSessionData, isLoading, isError, error} = useGetUserSessionInvestigationsQuery()
  const { data: userQuestionResponseData} = useGetUserQuestionResponseWithScoresQuery() // 12 Jul 2024
  //console.log('user investigation session data:', userInvestigationSessionData)
  //console.log('user question response data:', userQuestionResponseData)

  // 3 - Filter data for the matching user, trail and session -- 03 Jun 2024 --
  const userSessions = userInvestigationSessionData?.entities ? Object.values(userInvestigationSessionData?.entities) : [];
  const filteredUserSessionData = userSessions?.find(session => 
    session.userId._id === userId && session.trailId._id === trailId && session.sessionId._id === sessionId
  );

  // const userQuestionResponses = userQuestionResponseData?.entities ? Object.values(userQuestionResponseData?.entities) : [];
  const userQuestionResponses = userQuestionResponseData?.ids.map((id) => {
    const data = userQuestionResponseData?.entities[id]
    return data
  })
  const filteredUserQuestionResponseData = userQuestionResponses?.find(response => 
  response.userId._id === userId &&  response.trailId === trailId && response.sessionId === sessionId && response.userSessionInvestigationId
=== filteredUserSessionData?._id
  )
  // console.log('filtered user sessions data', filteredUserSessionData)
  // console.log('userQuestionResponses', userQuestionResponses)
  // console.log('filtered user question responses', filteredUserQuestionResponseData)

  // useEffect to automatically clear the alert after 2 seconds
  useEffect(() => {
    if (filteredUserQuestionResponseData) {

      setIsAssessmentStarted(true)

    }
  }, [filteredUserQuestionResponseData])

  // 3a - Check if there is any userAssessmentRecord in the userAssessments array -- 03 Jul 2024
  const hasUserAssessmentRecord = filteredUserSessionData?.userAssessments?.some(assessment => assessment.userAssessmentRecord);
  
  // 4 - State for Modal -- 11 Jun 2024
  const [isModalOpen, setIsModalOpen] = useState(false)

  // 5 - Function to handle Add Action -- 11 Jun 2024
  const handleAddAction = () => {
      handleOpenModal()
  }

  // 5a. Modal management functions
  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => setIsModalOpen(false)

  // 6 - Create an object for setting inner HTML
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
 }

 // 7 - On start assessment
 const handleStartAssessment = () => {
    setShowAssessment(true);
   
  };

  if (isLoading) return <PulseLoader color={"#ea580c"} />
  if (isError) return <div>Error occurred: {error?.data?.message}</div>

  return (
    <div className="bg-gray-200 p-1 xl:w-6/6 mx-auto my-2 rounded-lg shadow-md shadow-black font-poppins">
      <div className='m-1 p-1 bg-white rounded'>
        {/* Header Bar */}
        <div className='m-1 p-1 bg-gray-300 rounded'>
          <div className='flex justify-between items-center m-1 p-1'>
            {/* Left side */}
            <div className='m-1 p-1 flex justify-start items-center'>
              <div
                className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white m-1 p-1"
                onClick={() => setShowDetails((prev) => !prev)}
              >
                {showDetails ? (
                  <MinusIcon className="h-5 w-5 text-orange-600" />
                ) : (
                  <PlusIcon className="h-5 w-5 text-orange-600" />
                )}
              </div>
              <div className='flex justify-center items-center rounded-full bg-gray-200 hover:bg-white h-7 w-7 m-1 p-1'>
                <img src={filteredUserSessionData?.sessionId.pic} alt="" className='h-5 w-5' />
              </div>
              <div className='m-1 p-1'>
                <p className='text-sm font-bold mx-1'>Session {filteredUserSessionData.userSessionNumber} - {filteredUserSessionData.sessionId.sessionName}</p>
                <p className='text-[10px] m-1'>{filteredUserSessionData.sessionId.sessionDesc}</p>
              </div>
            </div>

            {/* Mid Section - Status */}
            <div className='flex justify-start items-center m-1 p-1'>
              
                {/* <div className='mx-1'><ClockIcon className="h-5 w-5 text-orange-600" /></div> */}
                <div>
                  <p className='text-xs font-bold mx-1'>Duration:</p>
                </div>
              
                <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{filteredUserSessionData?.sessionId?.sessionEstimatedDuration}</p></div>
                <div><p className='text-[8px] text-orange-600 italic'>min</p></div>
            </div>

            {/* Right Side */}
            <div className='flex justify-start items-center m-1 p-1'>
              <div>
                    <RocketLaunchIcon className="h-5 w-5 text-orange-600" />
              </div>
              <div className='m-1 p-1'>
                <p className='text-xs font-bold mx-1'>Started</p>
                <p className='text-[8px] mx-1 italic text-gray-600'>{getTimeSince(filteredUserSessionData?.startedAt)}</p> {/* Display duration from the start of the session */}
              </div>
            </div>

          </div>
          {/* Session Detail when the plus icon is clicked */}
          {showDetails && (
          <div className='bg-white rounded mx-2  my-1'>
          {filteredUserSessionData?.sessionId.sessionIntro && (
         <div className='p-2 grid grid-cols-3 gap-2 justify-center'> 
             <div className='mx-2 border-r  border-orange-200 col-span-1 h-full'>
                 <div className='flex flex-col justify-start'>
                     <div className='flex justify-start items-center  m-1 p-1'>
                         <div className=''><Square3Stack3DIcon className='h-5 w-5 text-orange-600' /></div>
                         <p className='m-1 p-1 text-xs text-gray-400 font-bold border-b-2 border-orange-600'>Session <span className='text-orange-600'>Detail</span></p>
                     </div>
                     <p dangerouslySetInnerHTML={createMarkup(filteredUserSessionData?.sessionId?.sessionIntro)} className='text-[10px] text-gray-600'></p>
                 </div>
             </div>

             <div className='mx-2 col-span-1 border-r border-orange-200 h-full'>
                 <div className='flex flex-col justify-start'>
                     <div className='flex justify-start items-center  m-1 p-1'>
                         <div className=''><ListBulletIcon className='h-5 w-5 text-orange-600' /></div>
                         <p className='m-1 p-1 text-xs text-gray-400 font-bold border-b-2 border-orange-600'>Session <span className='text-orange-600'>Prework</span></p>
                     </div>

                     {filteredUserSessionData?.sessionId.sessionPreWork && filteredUserSessionData?.sessionId.sessionPreWork?.map((item,preWorkIndex) => (
                     <div key={preWorkIndex} className='rounded m-1 p-1 bg-gray-100'>
                         <p className='text-[10px] mx-1'><span className='font-bold'></span>{item.prework}</p>
                     </div>
                     ))}
                 </div>
             </div>

             <div className='mx-2 col-span-1'>
                 <div className='flex flex-col justify-start'>
                     <div className='flex justify-start items-center m-1 p-1'>
                     <div className=''><SwatchIcon className='h-5 w-5 text-orange-600' /></div>
                         <p className='m-1 p-1 text-xs text-gray-400 font-bold border-b-2 border-orange-600'>Session <span className='text-orange-600'>Outcomes</span></p>
                     </div>

                     {filteredUserSessionData?.sessionId?.sessionOutcomes && filteredUserSessionData?.sessionId.sessionOutcomes?.map((item,outIndex) => (
                     <div key={outIndex} className='rounded m-1 p-1 bg-gray-100'>
                         <p className='text-[10px] mx-1'>{item?.outcome}</p>
                     </div>
                     ))}
                 </div>
             </div>
         </div>)}
     </div>
          )}
        </div> {/* End of Header Bar */}

        {/* Coach's inputs - TBD */}
        {!hasUserAssessmentRecord && !showAssessment && !isAssessmentStarted && !showPostAssessment && (
          <AssessmentKickoffSection onStartAssessment={handleStartAssessment} />
        )}

        {hasUserAssessmentRecord && (
          <div>
            <PostAssessmentView filteredUserSessionData={filteredUserSessionData} createMarkup={createMarkup}/>  
            <EtUpdateUserSessionForm userId={userId} trailId={trailId} sessionId={sessionId} filteredUserSessionData={filteredUserSessionData} handleAddAction={handleAddAction} />
          </div>
        )}

        {!hasUserAssessmentRecord && (showAssessment || isAssessmentStarted) && !showPostAssessment && (
          <AssessmentSection userId={userId} sessionId={sessionId} trailId={trailId} userSessionInvestigationId={filteredUserSessionData._id} setShowPostAssessment={setShowPostAssessment}/>
        )}
       
      </div>
      <EtModalCreateUserAction isOpen={isModalOpen} onClose={handleCloseModal} userId={userId} trailId={trailId} sessionId={sessionId} sessionTypeName={filteredUserSessionData?.sessionId.sessionTypeId?.sessionTypeName}/>
    </div>
  )
}

const EtUpdateUserSessionForm = ({userId, trailId, sessionId, filteredUserSessionData, handleAddAction}) => {

    // First Created - 03 Jul 2024
    // Author - Atul Pandey
    // Copied from EtUserTrailSessionIntrospection
  
    // 0 - Define and declare Navigation and state variables
    const navigate = useNavigate()
    const [investigationSessionNotes, setInvestigationSessionNotes] = useState(filteredUserSessionData?.investigationSessionNotes)
    const [isEditing, setIsEditing] = useState(false) // 14 Jun 2024 - for show / edit editor
    // console.log('investigationSessionNotes', investigationSessionNotes)
    
    // 1 - Define the RTK mutation to update backend data
    const [updateUserSessionInvestigation] = useUpdateUserSessionInvestigationMutation()
  
    // 1a - Editor Value setting based on incoming data -- 13 Jun 2024
    const [editorValue, setEditorValue] = useState(() => {
    const latestNote = filteredUserSessionData?.investigationSessionNotes?.slice(-1)[0];
    return latestNote ? latestNote.investigationNoteText : '';
    });
  
    // 1b - Current Note Id of the note currently in editor -- 14 Jun 2024
    const [currentNoteId, setCurrentNoteId] = useState(() => {
      const latestNote = filteredUserSessionData?.investigationSessionNotes?.slice(-1)[0];
      return latestNote ? latestNote._id : null;
    });
  
  
    // 1b - Handle Editor Value change
    const handleEditorChange = (value) => {
      setEditorValue(value);
    };
  
    // 2 - Form submit
    const handleSave = async () => {
      try {
          let updatedNotes;
          if (currentNoteId) {
            // Update existing note
            updatedNotes = investigationSessionNotes.map(note =>
              note._id === currentNoteId ? { ...note, investigationNoteText: editorValue, investigationNoteUpdateDate: new Date() } : note
            );
          } else {
            // Add new note
            const newNote = {
              investigationNoteText: editorValue,
              investigationNoteDate: new Date(),
              investigationNoteUpdateDate: new Date(),
            };
            updatedNotes = [...investigationSessionNotes, newNote];
          }
    
        await updateUserSessionInvestigation({
          userId,
          trailId,
          sessionId,
          investigationSessionNotes: updatedNotes,
        }).unwrap();
    
        // navigate('/userMagicHome')
       navigate('/etapp') // 23 Feb 2025
      } catch (error) {
        console.error('Failed to update the user session investigation:', error);
        // You can add an error message or any other logic here in case of a failure
      }
    };
    
  
    // 2a - Function to handle saving new Note -- 14 Jun 2024 --
    const handleSaveNote = async () => {
      if (editorValue.trim() === '') {
        alert('The editor is blank. Please enter some text before saving.');
        return;
      }
    
      try {
          let updatedNotes;
          if (currentNoteId) {
            // Update existing note
            updatedNotes = investigationSessionNotes.map(note =>
              note._id === currentNoteId ? { ...note, investigationNoteText: editorValue, investigationNoteUpdateDate: new Date() } : note
            );
          } else {
            // Add new note
            const newNote = {
              investigationNoteText: editorValue,
              investigationNoteDate: new Date(),
              investigationNoteUpdateDate: new Date(),
            };
            updatedNotes = [...investigationSessionNotes, newNote];
          }
    
        await updateUserSessionInvestigation({
          userId,
          trailId,
          sessionId,
          investigationSessionNotes: updatedNotes,
        }).unwrap();
    
        setEditorValue('');
        setIsEditing(false);
        alert('Notes updated successfully');
        window.location.reload();
      } catch (error) {
        console.error('Failed to update the user session investigation:', error);
        // You can add an error message or any other logic here in case of a failure
      }
    };
    
    // 3 - Handle Clear
    const handleClear = () => {
      setInvestigationSessionNotes(filteredUserSessionData?.investigationSessionNotes)
    }
  
    // 4 - Handle Cancel
    const handleCancel = () => {
      // navigate('/userMagicHome')
       navigate('/etapp') // 23 Feb 2025
    }
  
    // 5 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
      return { __html: htmlContent }
    }
  
    // 6 - Function to handle Add New Note -- 14 Jun 2024 --
    const handleAddNote = () => {
      // Clear editor and switch to edit mode
      setEditorValue('');
      setCurrentNoteId(null); // Clear the currentNoteId to treat it as a new entry
      setIsEditing(true);
     };
  
    // 7 - Function to handle editing a note -- 14 Jun 2024 --
    const handleEditNote = (note) => {
      setEditorValue(note.investigationNoteText);
      setCurrentNoteId(note._id);
      setIsEditing(true);
    };
  
    // 7a - Function to handle editing a note -- 14 Jun 2024 --
    const handleCancelEditNote = (note) => {
      setEditorValue('');
      setCurrentNoteId(null);
      setIsEditing(false);
    };
  
    
    // 8 - Function to handle deleting a note -- 14 Jun 2024 --
    const handleDeleteNote = async (noteId) => {
      try {
        const updatedNotes = investigationSessionNotes?.filter(note => note._id !== noteId);
        
        await updateUserSessionInvestigation({
          userId,
          trailId,
          sessionId,
          investigationSessionNotes: updatedNotes,
        }).unwrap();
        
        setInvestigationSessionNotes(updatedNotes); // Update the state with the filtered notes
        alert('Note deleted successfully');
      } catch (error) {
        console.error('Failed to delete the note:', error);
        // Add error handling logic here if needed
      }
    };

    return (
      <div className="bg-gray-300 p-1 m-1 rounded font-poppins">
        {/* Assessment Kick Off Section */}
        

        {/* Assessment Section */}
        
        
        {/* Post Assessment View */}
        

        {/* Assessment Scorecard */}
        

        {/* Session Notes Header */}
        <div className='m-1 p-1 flex justify-start items-center'>
          <div className='flex justify-center items-center cursor-pointer rounded-full h-8 w-8 bg-gray-200 hover:bg-white m-1 p-1'>
            <DocumentTextIcon className="h-5 w-5 text-orange-500 hover:text-orange-600" />
          </div>
          <div className='m-1 p-1'>
            <p className='font-bold mx-1'>Session Notes:</p>
            <p className='text-[10px] italic text-gray-600 mx-1'>Capture your session experiences, thoughts and observation. </p>
          </div>
        </div>
  
       {/* 14 Jun 2024 Updates */}
       <div className='m-1 p-1 border border-white rounded'>
  
          {/* Add Button */}
          <div className="flex justify-start items-center m-1 p-1">
                      <p className="font-bold text-xs m-1 p-1">Add</p>
                      <button type="button" onClick={handleAddNote}>
                      <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                      </button>
          </div>
          <div><p></p></div>
  
          {/* Session Notes Editor */}
          <div className='m-1 p-1'>
              {isEditing && (
                  <div>
                      <div className="bg-gray-50 p-1 m-1 rounded">
                          
                              <EtContentEditorLight2
                              value={editorValue}
                              handleChange={handleEditorChange}
                              placeholderText={editorValue}
                              // toolbarId={"sessionOurtro"}
                              /> 
                          
                      </div>
                      
                      {/* Save and Cancel Button */}
                      <div className="flex justify-start items-center m-1 p-1"> 
                                  <button type="button" onClick={handleSaveNote} className="w-16 text-white rounded-full bg-lime-600 hover:bg-lime-700">
                                      <p className="text-[10px] px-2 py-1">Save</p>
                                  </button>
                                  <button type="button" onClick={handleCancelEditNote} className="w-16 text-white rounded-full bg-orange-600 hover:bg-orange-700 mx-1">
                                      <p className="text-[10px] px-2 py-1">Cancel</p>
                                  </button>
                      </div>
                  </div>
              )}
          </div>
  
          <div className='m-1 p-1'>
              <div><p className='m-1 p-1 text-xs font-bold'>Session Notes Trail:</p></div>
              <div>
              {investigationSessionNotes
              ?.slice()
              .sort((a, b) => new Date(b.investigationNoteDate) - new Date(a.investigationNoteDate)) // Sort in descending order
              .map((note, noteIndex, arr) => (
                  <div key={note._id} className="bg-white p-1 m-1 rounded">
                      <div className='bg-gray-100 m-1 p-1 rounded '>
                          
                          <div className='grid grid-cols-12 justify-between items-center m-1 p-1'>
                              <div className='col-span-1 flex justify-start items-center'>
                                  <div><p className='text-[11px] font-bold'>{arr.length - noteIndex}.</p></div> 
                                  <div>
                                      <p className='text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-18'>{getTimeSince(note?.investigationNoteUpdateDate)}</p>
                                      <p className='text-[7px] mx-1 italic px-2 py-1 text-gray-400'>last updated</p>
                                  </div>  
                              </div>
  
                              <div className='col-span-7 flex justify-start items-center'>
                                  <div dangerouslySetInnerHTML={createMarkup(note.investigationNoteText)} className="text-[11px] text-gray-700 m-1 p-1"></div>
                              </div>
  
                              <div className='col-span-3 flex justify-start items-center mx-2'>
                                  <ClockIcon className='h-5 w-5 text-lime-600'/>
                                  <p className='text-[9px] mx-1'>First Created:</p>
                                  <p className='text-gray-400 text-[9px] mx-2'>{formatDateTime(note.investigationNoteDate)}</p>
  
                              </div>
                              
                              <div className='col-span-1 flex justify-start items-center mx-2'>
                                  <div className='rounded-full flex justify-center items-center h-6 w-6 hover:bg-white'>
                                      <PencilSquareIcon 
                                          className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer" 
                                          onClick={() => handleEditNote(note)}
                                      />
                                  </div>
                                  <div className='rounded-full flex justify-center items-center h-6 w-6 hover:bg-white'>
                                      <TrashIcon 
                                          className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                          onClick={() => handleDeleteNote(note._id)} 
                                      />
                                  </div>
                              </div>
                          </div> 
                      </div>
                  </div>
              ))}
              </div>
          </div>  
          
         </div>
  
        {/* Session Actions Section */}
        <div className='m-1 p-1 border border-white rounded'>
          <div className='m-1 p-1'>
            <div className='flex justify-between items-center'>
              <div className='flex justify-start items-center m-1 p-1'>
                  <div className='rounded-full flex justify-center items-center h-7 w-7 bg-gray-200 hover:bg-white'>
                    <CursorArrowRippleIcon className="h-5 w-5 text-orange-600" />
                    </div>
                  <div>
                      <p className="font-bold mx-1 px-1">Actions</p>
                      <p className="text-[8px] mx-1 px-1 italic text-gray-600">What would you like to do next?</p>
                  </div>
              </div>
            </div> 
          <div>
           {/* Component to display the trail session actions for the user */}
           <EtUserActionListComp userId={userId} trailId={trailId} sessionId={sessionId}/>
          </div>
  
          <div className="flex justify-start items-center m-1 p-1">
                  <p className="font-bold text-xs m-1 p-1">Add</p>
                  <button type="button" onClick={handleAddAction}>
                    <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                  </button>
              </div>
          </div>
        </div>
  
        {/* Session Action Bar */}
        <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
                <EtBtnFormBasic
                  name={"Save"}
                  type={"create"}
                  handleClick={handleSave}
                />
                <EtBtnFormBasic
                  name={"Clear"}
                  type={"clear"}
                  handleClick={handleClear}
                />
                <EtBtnFormBasic
                  name={"Cancel"}
                  type={"cancel"}
                  handleClick={handleCancel}
                />
              </div>
      </div>
    )
  
  }

const AssessmentKickoffSection = ({onStartAssessment}) => {
    return(
    <div className="bg-gray-300 border-white m-1 p-1 rounded shadow-md">
            <div className='bg-gray-50 p-4 rounded'>
                <h2 className="text-lg font-bold mb-2">About:</h2>
                <p className='mb-2 text-sm text-gray-700'>Based on Eternity Solutions <strong>(Et)</strong> Research, the <strong>PCI Assessment</strong> evaluates your readiness to create impactful outcomes, focusing on <strong>Presence, Contributions, and Impact</strong> — the third lever in unlocking human potential. This assessment gauges your appeal to clients as a valuable partner, examining <strong>nine</strong> dimensions: overall credentials, social presence, industry and domain presence, partner presence, roles and impact, spheres of influence, personality, and earned testimonials.By establishing your current capabilities, especially in <strong>client-facing</strong> roles, you can better understand how your clients perceive your value and ability to help them achieve their objectives. </p>
                <p className='mb-2 text-sm text-gray-700'>The PCI Assessment serves as a crucial <strong>guiding compass</strong> for self-evaluation before embarking on a journey to enhance your professional impact and results.</p> 
                <p className='mb-2 text-sm text-gray-700'>Are you <strong>ready</strong>?</p>
                <button 
                    onClick={onStartAssessment} 
                    className="mt-2 px-4 py-2 bg-orange-600 text-white rounded text-xs hover:bg-orange-700"
                >
                    Yes, start my assessment !
                </button>
            </div>
        </div>
    )
}


const AssessmentSection = ({
  userId,
  sessionId,
  trailId,
  userSessionInvestigationId,
  setShowPostAssessment
}) => {
    {/* Assessment Section */}
    return(
        <>
          <EtCreateUserAssessmentResponseWithScoresComp userId={userId} sessionId={sessionId} trailId={trailId} userSessionInvestigationId={userSessionInvestigationId} assessmentName={"PCI - General"} setShowPostAssessment={setShowPostAssessment}/>
        </>
)
}
const PostAssessmentView = ({filteredUserSessionData, createMarkup}) => {

  // 1 - State variable for showing a section
  const [showSectionDetails, setShowSectionDetails] = useState({})
  // const [showTranscriptBar, setShowTranscriptBar] = useState(false) // 15 Jul 2024
  const [showTranscriptDetails, setShowTranscriptDetails] = useState(false) // 15 Jul 2024
  const [activeTab, setActiveTab] = useState('interpret'); // 05 Jul 2024

  // 2 - Function to get the appropriate color class based on the question score
  const getColorForScore = (score) => {
    if (score >= 0 && score < 0.9) return "bg-etpx-0"
    if (score >= 0.9 && score < 1.9) return "bg-etpx-1"
    if (score >= 1.9 && score < 2.9) return "bg-etpx-2"
    if (score >= 2.9 && score < 3.9) return "bg-etpx-3"
    if (score >= 3.9 && score < 4.9) return "bg-etpx-4"
    return "bg-etpx-5" // For score >= 4.9
  }

  // 3 - Handle toggle show user details -- 04 Jul 2024 --
  const handleToggleSections = (sectionId) => {
    setShowSectionDetails((prev) => ({ ...prev, [sectionId]: !prev[sectionId] }));
  }

  // 3b - Handle Tab change -- 05 Jul 2024
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

    {/* Post Assessment View */}
    return (
      <div className="bg-gray-300 m-1 p-1 rounded">
       
        {filteredUserSessionData?.userAssessments?.map(
          (userAssessment, assessIndex) => (
            <div
              key={userAssessment._id + assessIndex}
              className="m-1 p-1 bg-gray-50 rounded "
            >
              <div>
                <div className='flex justify-start items-center m-1 p-1'>
                    <div className='rounded-full m-1 p-1 flex justify-center items-center w-9 h-9 bg-gray-200 hover:bg-white'><DocumentTextIcon className="h-7 w-7 text-orange-600 p-1" /></div>
                    <div><p className="p-4 text-lg font-bold text-gray-700"><strong>Assessment Transcript:</strong></p></div>
                </div>
                {/* Added - 14 Jul 2024 */}
                <div className='m-1 p-1 bg-gray-100 rounded'>
                    <p className='text-xs leading-4 text-gray-600 m-1 p-1'>You have successfully completed the <strong className='text-black'>assessment!</strong></p>
                    <p className='text-xs leading-4 text-gray-600 m-1 p-1'>
                      Before diving into your scores or responses, start with the <strong className='text-black'>"How to interpret"</strong> section. Remember, the PCI test isn't a measure of your abilities, but a means to heighten your awareness of the opportunities to become a better version of yourself—an untapped promise of you.
                    </p>
                    <p className='text-xs leading-4 text-gray-600 m-1 p-1'>
                      Once you've reviewed the interpretation guide, take a look at your scores. These scores don't define who you are; they simply reflect your current state of manifested <strong className='text-black'>magnetism</strong>. As you absorb the pixels and their <strong className='text-black'>luminance</strong>, pay attention to your <strong className='text-black'>feelings</strong>. If you're feeling positive, embrace it—it's well-earned! Look for ways to maintain or even boost this luminance. If you're not feeling great, approach your feelings with <strong className='text-black'>dispassion</strong>. View these scores as opportunities to brighten your <strong className='text-black'>PCI</strong>, opening new dimensions to <strong className='text-black'>explore</strong>. New challenges and missions await you—greet them with a smile and warmth.
                    </p>
                    <p className='text-xs leading-4 text-gray-600 m-1 p-1'>
                      Examine each section and question score, guiding your feelings as you go. Review your responses with an open mind and positive energy. Create <strong className='text-black'>session notes</strong>, reflecting on your thoughts and feelings about each response. Finally, take a moment to distill your reflections into a focused list of <strong className='text-black'>actions</strong>. It's okay to start with a broad list, but aim to refine it into sharp, few, and focused actions. A concise list will help you stay focused and avoid <strong className='text-black'>distractions</strong>, enabling you to achieve more.
                    </p>
                    <p className='text-xs leading-4 text-gray-600 m-1 p-1'><strong className='text-black'>Ready?</strong></p>
                  
                    <button 
                    onClick={() => setShowTranscriptDetails(prev => !prev)} 
                    className="mt-2 px-4 py-2 bg-orange-600 text-white rounded text-xs hover:bg-orange-700"
                >
                    {showTranscriptDetails ? 'Hide results':'Yes, show my results !'}
                </button>
                  {/* <p>Overall Assessment Score: {userAssessment?.userAssessmentRecord.assessmentScore}</p> */}
                </div>
              </div>

              {/* 15 Jul 2024 - Transcript Details bar */}

              {showTranscriptDetails && (
                <div className='m-1 p-1 bg-gray-100 rounded'>
                    <div className=''>
                          <div className='flex justify-start items-center p-4'>
                              
                            <div onClick={() => handleTabChange('interpret')} className='flex justify-start items-center cursor-pointer mx-4'>
                              <div className='rounded-full  flex justify-center items-center w-6 h-6'><QuestionMarkCircleIcon className="h-5 w-5 text-orange-600" /></div>
                              <p className={`py-1 text-md ${activeTab === 'interpret' ? 'border-b-2 border-orange-600 font-bold' : ''}`}><strong>How to Interpret?</strong></p>
                            </div>
                            <div onClick={() => handleTabChange('scores')} className='flex justify-start items-center cursor-pointer mx-4'>
                              <div className='rounded-full  flex justify-center items-center w-6 h-6'><SparklesIcon className="h-5 w-5 text-orange-600" /></div>
                              <p className={`py-1 text-md ${activeTab === 'scores' ? 'border-b-2 border-orange-600 font-bold' : ''}`}><strong>Scores</strong></p>
                            </div>
                            <div onClick={() => handleTabChange('responses')} className='flex justify-start items-center cursor-pointer mx-4'>
                              <div className='rounded-full  flex justify-center items-center w-6 h-6'><ClipboardDocumentListIcon className="h-5 w-5 text-orange-600" /></div>
                              <p className={`py-1 text-md ${activeTab === 'responses' ? 'border-b-2 border-orange-600 font-bold' : ''}`}><strong>Responses</strong></p>
                            </div>
                          </div>
                      </div>        
                      
                      {activeTab === 'responses' && userAssessment.userAssessmentRecord.sections?.map(
                        (section, sectionIndex) => {
                          const sectionName = section?.sectionId?.sectionName;
                          const prefixToRemove = "PCI - General - ";
                          const formattedSectionName = sectionName.replace(prefixToRemove, '');
                          const MyIcon = createSvgComponentFromBase64(section?.sectionId?.pic); // 07 Jul 2024
                          return(
                          <div key={section._id + sectionIndex} className='bg-gray-200 m-1 p-1 rounded'>
                            
                            {/* header */}
                            <div className='grid grid-cols-11 justify-between items-center'>

                                {/* Left Side */}
                              <div className='col-span-7 flex justify-start items-center'>
                                    <div
                                      className="flex justify-center items-center cursor-pointer rounded-full h-6 w-6 hover:bg-white m-1 p-1"
                                      onClick={() => handleToggleSections(section._id)}
                                    >
                                      {showSectionDetails[section._id] ? (
                                        <MinusIcon className="h-5 w-5 text-orange-600" />
                                      ) : (
                                        <PlusIcon className="h-5 w-5 text-orange-600" />
                                      )}
                                    </div>

                                      <div className='m-1 p-1 flex justify-center items-center h-7 w-7 rounded-full bg-gray-100 hover:bg-orange-600'>
                                        {/* <img src={section?.sectionId?.pic} alt="" className='h-5 w-5'/> */}
                                        <MyIcon className='h-5 w-5 text-orange-600 hover:text-white'/>                               
                                        </div>
                                      <div className='mx-1'><p className="px-1 py-4 text-xs"><strong>{formattedSectionName}</strong></p></div>
                              </div>

                              {/* Middle bar  */}
                              <div className='col-span-2 flex justify-start items-center'>
                                <div>
                                  <PresentationChartBarIcon className="h-5 w-5 text-orange-600" />
                                </div>
                                <div><p className='mx-1 px-1 text-[11px] text-gray-400'>Score:</p></div>
                                <div className='flex justify-center items-center bg-white rounded-full h-6 w-6 border border-orange-600'><p className='text-[9px]'>{section.sectionScore}</p></div>
                                <div><p className='mx-1 px-1 text-[11px] text-gray-400'>/5</p></div>
                              </div>

                              {/* End bar  */}
                              <div className='col-span-2 flex justify-start items-center'>
                                <div><p className='mx-1 px-1 text-[11px] text-gray-400'>PCI Luminance</p></div>
                                {/* <div className={`flex justify-center items-center ${getColorForScore(section.sectionScore)} rounded h-6 w-6 border border-gray-400`}><p className='text-[9px] text-gray-200'>{section.sectionScore}</p></div> */}
                                <div className={`flex justify-center items-center ${getColorForScore(section.sectionScore)} rounded h-10 w-10 border border-gray-400`}></div>
                                {/* <div><p className='mx-1 px-1 text-sm text-gray-400'>&rarr;</p></div> */}
                                {/* <div><p className='mx-1 px-1 text-sm text-gray-400'>/</p></div> */}
                                {/* <div className={`flex justify-center items-center ${getColorForScore(5)} rounded h-6 w-6 border border-orange-600`}></div>   */}
                              </div>     
                            </div>

                            {/* Section Intro */}
                            {showSectionDetails[section._id] && (
                              <div className='bg-white m-1 p-1 rounded'>
                                <div>
                                  <p dangerouslySetInnerHTML={createMarkup(section?.sectionId.sectionIntro)} className='text-xs leading-6'></p>
                                </div>

                              </div>
                            )}

                            {/* section responses */}
                            {showSectionDetails[section._id] && section?.responses?.map((response, responseIndex) => {

                              return(
                                <div key={response._id+responseIndex} className=''>

                                  <div className='grid grid-cols-6 justify-between items-center'>

                                    <div className='col-span-5 flex justify-start items-center bg-white rounded m-1 p-1'>
                                      <div><p className='text-4xl font-bold mx-1'>{responseIndex +1}.</p></div>
                                      <div className='px-1 py-4'>
                                        <p dangerouslySetInnerHTML={createMarkup(response?.questionId?.questionName)} className='text-xs px-1 font-bold text-gray-600'></p>
                                        <p className='p-1 text-[10px] text-gray-600 italic'><span className='text-orange-600 normal'>Your response:</span> {response?.questionResponse}</p>
                                      </div>
                                    </div>
                                    <div className='col-span-1 flex justify-between items-center bg-white rounded m-1 p-1'>
                                      {/* Score */}
                                      <div className='flex flex-col justify-center items-center px-2 py-3'>
                                        <p className='text-2xl text-orange-600'>{response?.questionScore}</p>
                                        <p className='text-[10px] text-gray-400'>Score</p>
                                      </div>
                                      {/* PCI Luminance */}
                                      <div className='flex flex-col justify-center items-center p-1'>
                                        <div className={`flex justify-center items-center ${getColorForScore(response?.questionScore)} rounded h-16 w-16 border border-gray-400`}></div>
                                        {/* <p className='text-[10px] text-gray-400'>PCI Luminance</p> */}
                                      </div>
                                    </div>
                                  </div>  
                                </div>
                              )
                            })}  
                          </div>
                        )}
                      )}
                      
                      {/* Scorecard Section - 06 Jul 2024 */}
                      {activeTab === 'scores' && (
                        <div>
                          <AssessmentScorecard assessmentData={userAssessment.userAssessmentRecord}/>
                        </div>
                      )}
                      {/* How to interpret section - 06 Jul 2024 */}
                      {activeTab === 'interpret' && (
                        <div>
                          <EtInterpretScores />
                        </div>
                      )}
                </div>
              )}
            </div>
          )
        )}
      </div>
    )
}
const AssessmentScorecard = ({assessmentData}) => {
    {/* Assessment Scorecard */}
    return (
      <div className="bg-white my-1 p-4 rounded shadow-md">

        <p className='text-xs italic text-gray-400 p-1'>Click on the tiles below to see the PCI Luminosity level</p>

        <div className='grid grid-cols-10 justify-between items-center'>
          
          <div className='col-span-5 m-[1px]'>
            <p className='font-bold m-1 p-1 border-b-4 border-orange-600 text-gray-600 inline-block'>All Sections</p>
            <EtSectionScoreCard scoreData={assessmentData}/>
          </div>
          
          <div className='col-span-5 m-[1px]'>
            <p className='font-bold m-1 p-1 border-b-4 border-orange-600 text-gray-600 inline-block'>All Questions</p>
            <EtQuestionScoreCard scoreData={assessmentData}/>
          </div>
        </div>
      </div>
    )
}

const EtSectionScoreCard = ({
  scoreData
}) => {

  // 1 - state for toggling score display -- 07 Jul 2024
  const [visibleScores, setVisibleScores] = useState(null);

  // console.log('score Data:', scoreData)

  // 2 - Build sections data
  const { sectionsData } = extractAssessmentData(scoreData);
  // console.log('Section Data:', sectionsData);
  
  // 3 - Calculate display grid blocks
  const calculateGridSize = (numberOfSections) => {
    if (numberOfSections <= 1) return 1
    if (numberOfSections <= 4) return 2
    if (numberOfSections <= 9) return 3
    if (numberOfSections <= 16) return 4
    return 4 // Default to 4 if more than 16 questions
  }

  const gridSize = calculateGridSize(sectionsData.length) // Calculate grid size based on the number of sections

  // 4a - Function to get the appropriate color class based on section score
  const getColorForScore = (score) => {
    if (score >= 0 && score < 0.9) return "bg-etpx-0"
    if (score >= 0.9 && score < 1.9) return "bg-etpx-1"
    if (score >= 1.9 && score < 2.9) return "bg-etpx-2"
    if (score >= 2.9 && score < 3.9) return "bg-etpx-3"
    if (score >= 3.9 && score < 4.9) return "bg-etpx-4"
    return "bg-etpx-5" // For score >= 4.9
  }

  // 4b - Function to get the appropriate color class based on section score -- 07 Jul 2024
  const getTextColorForScore = (score) => {
    if (score >= 0 && score < 0.9) return 'text-[#525252]';
    if (score >= 0.9 && score < 2.9) return 'text-[#A8A8A8]';
    if (score >= 2.9) return 'text-white';
    return 'text-white'; // Default color if none of the conditions match
  };

  // 5 - Toggle the visibility of section scores
  const toggleSectionScoreVisibility = (index) => {
    setVisibleScores((prevIndex) => (prevIndex === index ? null : index));
  };

  // 6 - Function to extract the final part of the section name -- 07 Jul 2024
  const extractSectionName = (sectionName) => {
    const parts = sectionName.split('-').map(part => part.trim());
    return parts.pop();
  };

  // 6 - Generate square divs based on the grid size
  const squares = Array.from({ length: gridSize * gridSize }, (_, index) => {
    const section = sectionsData[index]
    const MyIcon = createSvgComponentFromBase64(section?.pic); // 07 Jul 2024
    const sectionName = section? extractSectionName(section?.sectionName) : ''; // 07 Jul 2024
    const textColorClass = section ? getTextColorForScore(section.sectionScore) : '';
    const colorClass = section
      ? getColorForScore(section.sectionScore)
      : "bg-black" // Default color if section is missing

    return (
      <div
        key={index}
        className={`aspect-square rounded border m-[1px] border-black ${colorClass} cursor-pointer`}
        onClick={() => toggleSectionScoreVisibility(index)} // Toggle visibility when clicked
      >
        {section &&  (
          <div className="flex flex-col p-2 justify-between items-center h-full">
            <MyIcon className={`h-6 w-6 md:h-8 md:w-8 lg:h-10 lg:w-10 ${textColorClass}`}/>
            
            {visibleScores === index && (
            <p className={`text-lg lg:text-4xl font-bold ${textColorClass}`}>
              {section.sectionScore.toFixed(1)}
            </p>
            )}
            <p className={`text-[6px] md:text-[8px] lg:text-[10px] font-light ${textColorClass}`}>{sectionName}</p>
          </div>
        )}

      </div>
    )
  })

  return (
    <div
      className={`grid grid-cols-${gridSize} aspect-square rounded bg-gradient-to-r from-black to-black via-etpx-bg mx-auto p-[2px]`}
    >
      {squares}
    </div>
  )

}

const EtQuestionScoreCard = ({scoreData}) => {

  // 1 - state for toggling score display -- 07 Jul 2024
  const [visibleQuestScores, setVisibleQuestScores] = useState(null);
  const [openDialog, setOpenDialog] = useState(false); // 16 Jul 2024 State to handle dialog visibility
  const [selectedQuestion, setSelectedQuestion] = useState({}); // 16 Jul 2024 State to store the selected question details

// 2 - Extract questions data
const {allQuestionsData} = extractAssessmentData(scoreData)
console.log('Questions Data:', allQuestionsData)

  const calculateGridSize = (numberOfQuestions) => {
    if (numberOfQuestions <= 1) return 1
    if (numberOfQuestions <= 4) return 2
    if (numberOfQuestions <= 9) return 3
    if (numberOfQuestions <= 16) return 4
    if (numberOfQuestions <= 25) return 5
    if (numberOfQuestions <= 36) return 6
    if (numberOfQuestions <= 49) return 7
    if (numberOfQuestions <= 64) return 8
    if (numberOfQuestions <= 81) return 9
    return 9 // Default to 4 if more than 16 questions
  }

  const gridSize = calculateGridSize(allQuestionsData?.length) // Reuse the same grid logic

  // 3 - Function to get the appropriate color class based on section score
  const getColorForScore = (score) => {
    if (score >= 0 && score < 0.9) return "bg-etpx-0"
    if (score >= 0.9 && score < 1.9) return "bg-etpx-1"
    if (score >= 1.9 && score < 2.9) return "bg-etpx-2"
    if (score >= 2.9 && score < 3.9) return "bg-etpx-3"
    if (score >= 3.9 && score < 4.9) return "bg-etpx-4"
    return "bg-etpx-5" // For score >= 4.9
  }

  // // 4a - Toggle the visibility of section scores
  // const toggleQuestionScoreVisibility = (index) => {
  //   setVisibleQuestScores((prevIndex) => (prevIndex === index ? null : index));
  // };

  // 4b - Handle Question score click - 16 Jul 2024
  const handleQuestionClick = (index) => {
    const question = allQuestionsData[index];
    if (question) {
      setSelectedQuestion(question);
      setOpenDialog(true);
    }
  };

  // 4c - Handle Close Dialog - 16 Jul 2024
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedQuestion({});
  };

  // 5 - Create an object for setting inner HTML
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
 }

  // 6 - Generate square divs based on the grid size
  const squares = Array.from({ length: gridSize * gridSize }, (_, index) => {
    const question = allQuestionsData[index]
    const colorClass = question
      ? `cursor-pointer ${getColorForScore(question.questionScore)}`
      : "bg-black" // Default if question is missing
  
    return (
      <div
        key={index}
        className={`aspect-square rounded border m-[1px] border-black ${colorClass}`}
        onClick={() => {handleQuestionClick(index) }} // 16 Jul 2024 Open dialog on click
        onMouseEnter={() => setVisibleQuestScores(index)} // Show score on hover
        onMouseLeave={() => setVisibleQuestScores(null)} // Hide score on hover out
      >
        {/* {question && visibleQuestScores === index && ( */}
        {question && visibleQuestScores === index && (
          <div className="flex flex-col justify-center p-4 items-center h-full">
            <p className="text-white text-sm font-bold">
              {question.questionScore.toFixed(1)}
            </p>
          </div>
        )}
      </div>
    )
  })

  return (
    <div>
      <div
        className={`grid grid-cols-${gridSize} aspect-square rounded bg-gradient-to-r from-black to-black via-etpx-bg mx-auto p-[2px]`}
      >
        {squares}
      </div>
    
    {/* Dialog section - 16 Jul 2024 */}

    <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle><div className='font-bold'>Question Details</div></DialogTitle>
        <DialogContent>
          <div className='flex justify-start items-center'>
            <p className='m-1 p-1 text-sm text-orange-600'><strong>Question:</strong></p>
            <p dangerouslySetInnerHTML={createMarkup(selectedQuestion?.questionId?.questionName)} className='text-xs m-1 p-1'></p>
          </div>
          <div className='flex justify-start items-center'>
            <p className='m-1 p-1 text-sm text-orange-600'><strong>Response:</strong></p>
            <p dangerouslySetInnerHTML={createMarkup(selectedQuestion?.questionResponse)} className='text-xs m-1 p-1'></p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>


    </div>
  )

  
}

// Extract Asssessment Data for Px Scoregrids
const extractAssessmentData = (data) => {
  // 1 - Extract assessment data
  const assessmentData = {
    assessmentId: data.assessmentId,
    assessmentScore: data.assessmentScore,
    sections: data.sections.map(section => ({
      sectionId: section.sectionId._id,
      sectionName: section.sectionId.sectionName,
      sectionScore: section.sectionScore,
    }))
  };

  // 2 - Extract section data
  const sectionsData = data.sections.map(section => ({
    sectionId: section.sectionId._id,
    sectionName: section.sectionId.sectionName,
    sectionIntro: section.sectionId.sectionIntro,
    pic: section.sectionId.pic,
    responses: section.responses,
    sectionScore: section.sectionScore,
    _id: section._id
  }));

  // 3 - Extract Questions Data
const questionsData = sectionsData?.map((section) => {
  const questionsAndResponses = section.responses
  return questionsAndResponses
})
// console.log('questionsData', questionsData)

// 4 - Flat all questions
const allQuestionsData = questionsData.flat()
//console.log('All Questions Data', allQuestionsData)

  return { assessmentData, sectionsData, questionsData, allQuestionsData };
};

// PCI Score Interpretation
const EtInterpretScores = ({}) => {

  // 16 Jul 2024 - State variables for hide / show a section
  const [showPremise, setShowPremise] = useState(false)
  const [showMeasures, setShowMeasures] = useState(false)
  const [showScores, setShowScores] = useState(false)
  const [showInterpretation, setShowInterpretation] = useState(false)
  const [showVariances, setShowVariances] = useState(false)

  const EtPixelShades = [
    {"level": 0, "luminance": "Dark",     "color": "etpx-0"},
    {"level": 1, "luminance": "Dim",      "color": "etpx-1"},
    {"level": 2, "luminance": "Low",      "color": "etpx-2"},
    {"level": 3, "luminance": "Warm",     "color": "etpx-3"},
    {"level": 4, "luminance": "Bright",   "color": "etpx-4"},
    {"level": 5, "luminance": "Radiant",  "color": "etpx-5"},
  ]

  return(
    <div className='bg-white rounded m-1 p-1'>
      {/* Et Approach - The Human Potential - A test of luminance */}
      {/* Et Pixels - Intro*/}
      {/* Spectrums - The Origin, Now, The Possible */}
      {/* Your takeaways */}

      {/* Section 1 - The Et Approach */}

      <div className='bg-gray-100 rounded m-1 p-1'>
        <p className='font-bold mx-1 px-1 text-lg'>Measuring Human Potential - the Et Way</p>
        <p className='mx-1 px-1 text-[10px] text-gray-600 italic'>You are not a number. You are a limitless <strong className='text-orange-600'>magnet!</strong> </p>

      </div>

      {/* Section 2 - The Et Approach - The Premise */}

      <div className='bg-gray-100 rounded m-1 p-1'>
            
            <div className='flex justify-start items-center m-1 p-1'>
                <div
                  className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white m-1 p-1"
                  onClick={() => setShowPremise((prev) => !prev)}
                >
                  {showPremise ? (
                    <MinusIcon className="h-5 w-5 text-orange-600" />
                  ) : (
                    <PlusIcon className="h-5 w-5 text-orange-600" />
                  )}
                </div>
                <div className='flex justify-center items-center w-9 h-9 rounded-full bg-gray-50 hover:bg-orange-600'><EtMagnetIconSVGComp className='h-10 w-10 text-orange-600 hover:text-white'/></div>    
                <div><p className='font-bold m-1 p-1 text-xs sm:text-sm md:text-md xl:text-lg'>The Premise </p></div>
            </div>
          {showPremise && (
          <div>
            <p className='m-1 p-1 text-xs text-gray-600 leading-5'>Eternity Solutions (Et) views human potential as a vast, untapped magnetic field. This potential can be constrained by external factors like challenging environments or lack of opportunities, as well as internal factors such as lack of initiative or confidence. Et sees every person as a potential human magnet, with their magnetism reflected in the outcomes they create. While outcomes are important, they're considered a lagging indicator of potential and insufficient for fully understanding or changing one's potential realization. Et's approach focuses on exploring the untapped potential by examining both the sources and processes leading to outcomes, rather than just the results themselves.</p>
          </div>
          )}

      </div>
      
      {/* Section 3 - The Measures */}

      <div className='bg-gray-100 rounded m-1 p-1'>
          <div className='flex justify-start items-center m-1 p-1'>
          <div
            className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white m-1 p-1"
            onClick={() => setShowMeasures((prev) => !prev)}
          >
            {showMeasures ? (
              <MinusIcon className="h-5 w-5 text-orange-600" />
            ) : (
              <PlusIcon className="h-5 w-5 text-orange-600" />
            )}
          </div>
            <div className='flex justify-center items-center w-9 h-9 rounded-full bg-gray-50 hover:bg-orange-600'><EtMeasureLightIconSVGComp className='h-6 w-6 text-orange-600 hover:text-white'/></div>
            <div><p className='font-bold m-1 p-1 text-xs sm:text-sm md:text-md xl:text-lg'>The Measures </p></div>
          </div>
          
          {showMeasures && (
          <div>
            <p className='m-1 p-1 text-xs text-gray-600 leading-5'>Et conceptualizes human potential as a continuous spectrum of <strong className='text-orange-600'>magnetism</strong>, connecting inner sources to outward manifestations. To measure this, Et uses the metaphor of <strong>light</strong> intensity to represent the power of human magnetism. Each aspect of potential is represented as an <strong>"Et Pixel,"</strong> with its brightness indicating the strength of that potential aspect. This approach allows for a nuanced, bias-free assessment of individuals, recognizing personal uniqueness while still acknowledging different capability levels. The PCI test measures three key factors: Presence, Contributions, and Impact, using a spectrum from <strong>Dark (0)</strong> to <strong>Radiant (5)</strong> to represent different levels of potential realization.</p>
            
            {/* Et Pixel Shades */}
            <div className='m-1 p-4 rounded  bg-gradient-to-r from-black to-black via-etpx-bg'>
              <div className='flex w-full justify-center items-center p-4'><p className='text-orange-600 font-bold text-xs sm:text-lg md:text-2xl xl:text-4xl'> <span className='border-b text-white'>Et Pixels</span> -  Your Presence Luminosity</p></div>
              <div className='grid grid-cols-6 gap-2 m-1 p-4'>
              {EtPixelShades.map((shade, shadeIndex) => (
                <div key={shade.level+shadeIndex} className={`col-span-1`}>
                    
                    <div>
                        <div className='w-full flex justify-center items-center p-1'><EtUserIconSVGComp className={`h-32 w-32 text-${shade.color}`}/></div>
                        <div className={`aspect-square rounded bg-${shade.color} border border-white`}>
                          <div className='flex flex-col justify-center items-center p-1 h-full'>
                            <p className='text-2xl md:text-4xl xl:text-8xl text-white font-bold font-oswald'>{shade.level}</p>
                          </div>
                        </div>
                        <div><p className='py-2 text-center font-oswald text-white text-xs sm:text-sm md:text-lg xl:text-xl font-extralight'>{shade.luminance}</p></div>
                    </div>
                </div>
              ))}
              </div>
            </div>
          </div>
          )}
          
      
      </div>
      
      {/* Section 4 - The Scores */}

      <div className='bg-gray-100 rounded m-1 p-1'>
          <div className='flex justify-start items-center m-1 p-1'>
            <div
              className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white m-1 p-1"
              onClick={() => setShowScores((prev) => !prev)}
            >
              {showScores ? (
                <MinusIcon className="h-5 w-5 text-orange-600" />
              ) : (
                <PlusIcon className="h-5 w-5 text-orange-600" />
              )}
            </div>
            <div className='flex justify-center items-center w-9 h-9 rounded-full bg-gray-50 hover:bg-orange-600'><EtScoresIconSVGComp className='h-6 w-6 text-orange-600 hover:text-white'/></div>
            <div><p className='font-bold m-1 p-1 text-xs sm:text-sm md:text-md xl:text-lg'>The Scores </p></div>
          </div>
          {showScores && (
          <div>
            <p className='m-1 p-1 text-xs text-gray-600 leading-5'>Scores in the <strong className='text-orange-600'>Et Pixels</strong> represent current <strong>PCI</strong> luminance levels based on self-assessment across nine PCI dimensions. Each section's luminance is determined by the underlying sub-factors, measured through specific questions. The scores correspond to the previously described luminance levels and indicators, providing a comprehensive view of an individual's current potential realization across various aspects.</p>
          </div>
          )}
      </div>
      
      {/* Section 5 - The Interpretation -- 08 Jul 2024*/}

     <div className='bg-gray-100 rounded m-1 p-1'>
          <div className='flex justify-start items-center m-1 p-1'>
            <div
                className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white m-1 p-1"
                onClick={() => setShowInterpretation((prev) => !prev)}
              >
                {showInterpretation ? (
                  <MinusIcon className="h-5 w-5 text-orange-600" />
                ) : (
                  <PlusIcon className="h-5 w-5 text-orange-600" />
                )}
              </div>
              <div className='flex justify-center items-center w-9 h-9 rounded-full bg-gray-50 hover:bg-orange-600'><EtInterpretIconSVGComp className='h-7 w-7 text-orange-600 hover:text-white'/></div>
            <div><p className='font-bold m-1 p-1 text-xs sm:text-sm md:text-md xl:text-lg'>The Interpretation </p></div>
          </div>

          {showInterpretation && (
            <div>  
            <div>
              <p className='m-1 p-1 text-xs text-gray-600 leading-5'>Et emphasizes interpreting scores as indicators of possibilities rather than judgments. The scores reflect one's readiness to tackle challenges and the intensity of their inner light. They're not meant to categorize individuals as winners or losers, but to help understand where one can progress independently versus where they might need support. Et encourages viewing the scores as stages in a journey towards greater potential realization. </p>
            </div>

            <div className='grid grid-cols-9 justify-between items-center'>
              <div className='col-span-3 m-1 rounded'>
                <div className='flex justify-start items-center'>
                  <div><ArrowDownCircleIcon className='h-5 w-5'/></div>
                  <div><p className='p-1 m-1 text-gray-400 font-bold'>At start</p></div>
                </div>
                <div><p className='mx-1 px-1 text-[10px] lg:text-xs italic'>a blank slate..</p></div>
                <div className='bg-gradient-to-r from-black to-black via-etpx-bg rounded'><EtPixelGrid sectionNum={16} /></div>
              </div>
              <div className='col-span-3 m-1 rounded'>
                <div className='flex justify-start items-center'>
                    <div><ArrowRightCircleIcon className='h-5 w-5 text-etpx-2'/></div>
                    <div><p className='p-1 m-1 text-gray-400 font-bold'>Now</p></div>
                </div>
                <div><p className='mx-1 px-1 text-[10px] lg:text-xs italic'>we dare to change the game..</p></div>
                <div className='bg-gradient-to-r from-black to-black via-etpx-bg rounded'><EtPixelGrid sectionNum={16} sectionData={SectionData1}/></div>
              </div>
              <div className='col-span-3 m-1 rounded '>
                <div className='flex justify-start items-center'>
                  <div><ArrowUpCircleIcon className='h-5 w-5 text-etpx-5'/></div>
                  <div><p className='p-1 m-1 text-gray-400 font-bold'>The Possible!</p></div>
                </div>
                <div><p className='mx-1 px-1 text-[10px] lg:text-xs italic'>we keep turning up our luminance...</p></div>
                <div className='bg-gradient-to-r from-black to-black via-etpx-bg rounded'><EtPixelGrid sectionNum={16} sectionData={SectionData2}/></div>
              </div>
            </div>
          </div>
          )}
      </div>

      {/* Section 6 - The Score Scatters - You may see yourself as a king, others may not -- 08 Jul 2024*/}

      <div className='bg-gray-100 rounded m-1 p-1'>
        <div className='flex justify-start items-center m-1 p-1'>
          <div
            className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white m-1 p-1"
            onClick={() => setShowVariances((prev) => !prev)}
          >
            {showVariances ? (
              <MinusIcon className="h-5 w-5 text-orange-600" />
            ) : (
              <PlusIcon className="h-5 w-5 text-orange-600" />
            )}
          </div>
          <div className='flex justify-center items-center w-9 h-9 rounded-full bg-gray-50 hover:bg-orange-600'><EtVarianceIconSVGComp className='h-6 w-6 text-orange-600 hover:text-white'/></div>
          <div><p className='font-bold m-1 p-1 text-xs sm:text-sm md:text-md xl:text-lg'>The Variances </p></div>
        </div>
        
        {showVariances && (
          <div>
              <div>
                <p className='m-1 p-1 text-xs text-gray-600 leading-5'>Recognize that score variances from others reflect differing perceptions of your potential. As you increase your light, your capabilities will become clearer to everyone. Like objects under varying light conditions, your potential is seen differently based on its brightness. Take these scores as indicators to enhance your light, not as reflections of your strengths or weaknesses. Your strengths are already there; you just need to illuminate those pixels!</p>
              </div>

              <div className='grid grid-cols-10 justify-between items-center'>
                <div className='col-span-2 m-1 rounded'>
                  <div className='flex justify-start items-center'>
                    <div><EtYouIconSVGComp className='h-6 w-6 text-orange-600'/></div>
                    <div><p className='p-1 m-1 text-gray-400 font-bold'>You</p></div>
                  </div>
                  <div><p className='mx-1 px-1 text-[10px] xl:text-xs italic'>may see yourself as a king..</p></div>
                  <div className='bg-gradient-to-r from-black to-black via-etpx-bg rounded'><EtPixelGrid sectionNum={16} sectionData={SectionData3} /></div>
                </div>
                <div className='col-span-2 m-1 rounded'>
                  <div className='flex justify-start items-center'>
                      <div><EtTeamworkIconSVGComp className='h-6 w-6 text-orange-600'/></div>
                      <div><p className='p-1 m-1 text-gray-400 font-bold'>Your Team</p></div>
                  </div>
                  <div><p className='mx-1 px-1 text-[10px] xl:text-xs italic'>may not share the same view..</p></div>
                  <div className='bg-gradient-to-r from-black to-black via-etpx-bg rounded'><EtPixelGrid sectionNum={16} sectionData={SectionData4}/></div>
                </div>
                <div className='col-span-2 m-1 rounded '>
                  <div className='flex justify-start items-center'>
                    <div><EtEncourageIconSVGComp className='h-6 w-6 text-orange-600'/></div>
                    <div><p className='p-1 m-1 text-gray-400 font-bold'>Your Clients</p></div>
                  </div>
                  <div><p className='mx-1 px-1 text-[10px] xl:text-xs italic'>may have their own view...</p></div>
                  <div className='bg-gradient-to-r from-black to-black via-etpx-bg rounded'><EtPixelGrid sectionNum={16} sectionData={SectionData5}/></div>
                </div>
                <div className='col-span-2 m-1 rounded '>
                  <div className='flex justify-start items-center'>
                    <div><EtYourLeaderIconSVGComp className='h-6 w-6 text-orange-600'/></div>
                    <div><p className='p-1 m-1 text-gray-400 font-bold'>Your Leaders</p></div>
                  </div>
                  <div><p className='mx-1 px-1 text-[10px] xl:text-xs italic'>may see your promise,yet...</p></div>
                  <div className='bg-gradient-to-r from-black to-black via-etpx-bg rounded'><EtPixelGrid sectionNum={16} sectionData={SectionData6}/></div>
                </div>
                <div className='col-span-2 m-1 rounded '>
                  <div className='flex justify-start items-center'>
                    <div><EtCommSkillsIconSVGComp className='h-6 w-6 text-orange-600'/></div>
                    <div><p className='p-1 m-1 text-gray-400 font-bold'>Your Coach</p></div>
                  </div>
                  <div><p className='mx-1 px-1 text-[10px] xl:text-xs italic'>may help you realize...</p></div>
                  <div className='bg-gradient-to-r from-black to-black via-etpx-bg rounded'><EtPixelGrid sectionNum={16} sectionData={SectionData6}/></div>
                </div>
              </div>
          </div>
        )}

      </div>

    </div>
  )
}

// Et Pixel Grid
const EtPixelGrid = ({sectionNum, sectionData= []}) => {
  // 1 - state for toggling score display -- 07 Jul 2024
  
  // 3 - Calculate display grid blocks
  const calculateGridSize = (numberOfSections) => {
    if (numberOfSections <= 1) return 1
    if (numberOfSections <= 4) return 2
    if (numberOfSections <= 9) return 3
    if (numberOfSections <= 16) return 4
    if (numberOfSections <= 25) return 5
    if (numberOfSections <= 36) return 6
    if (numberOfSections <= 49) return 7
    if (numberOfSections <= 64) return 8
    if (numberOfSections <= 81) return 9
    if (numberOfSections <= 100) return 10
    return 10 // Default to 10 if more than 100 sections
  }

  const gridSize = calculateGridSize(sectionNum) // Calculate grid size based on the number of sections
  // console.log('gridSize', gridSize)

  // 4 - Function to get the appropriate color class based on section score
  const getColorForScore = (score) => {
    if (score >= 0 && score < 0.9) return "bg-etpx-0"
    if (score >= 0.9 && score < 1.9) return "bg-etpx-1"
    if (score >= 1.9 && score < 2.9) return "bg-etpx-2"
    if (score >= 2.9 && score < 3.9) return "bg-etpx-3"
    if (score >= 3.9 && score < 4.9) return "bg-etpx-4"
    return "bg-etpx-5" // For score >= 4.9
  }

  // 6 - Generate square divs based on the grid size
  const squares = Array.from({ length: gridSize * gridSize }, (_, index) => {
    const section = sectionData[index];
    const colorClass = section
      ? getColorForScore(section?.sectionScore)
      : "bg-black" // Default color if section is missing

    return (
      <div
        key={index}
        className={`aspect-square rounded border m-[1px] border-black ${colorClass} cursor-pointer`}
      >
        {/* <p className='text-white'>1</p> */}
      </div>
    )
  })

 
  return (
    <div
      className={`grid grid-cols-${gridSize} aspect-square rounded bg-gradient-to-r from-black to-black via-etpx-bg mx-auto p-[2px]`}
    >
      {squares}
    </div>
  )

}

export default EtUserTrailSessionInvestigationCompProd