import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import { useGetQuestionSetsQuery, useUpdateQuestionSetMutation } from "./questionSetsApiSlice"
import PulseLoader from "react-spinners/PulseLoader"


const EtUpdateQuestionSet = ({id, setActiveAdminView}) => {
    // Created - 04 Apr 2024
    // Author - Atul Pandey
    // Updated - 28 Feb 2025 - updated the softSkillFunctionArea with props - { id, setActiveAdminView}
    // Updated - 28 Feb 2025 - switched from navigate to setActiveAdminView
    
    // 0 - Set the page title
    useTitle("Question Set - Update")
    
    // 1 - extract the set id to be edited from the url
    // const {id} = useParams()
    // console.log('id:',id)

    // 2 - Extract the Question Set data through the useGet query
    const { questionSet } = useGetQuestionSetsQuery('questionSetList', {
        selectFromResult: ({ data }) => ({
            questionSet: data?.entities[id]
        }),
    })
    // console.log('question set:',questionSet)

    // 3. Check for returned data
    if(!questionSet) {
        return <PulseLoader color={"#ea580c"} />
    }

    // 4. Display data
  let content
  content = <EditQsetForm qSet={questionSet} id={id} setActiveAdminView={setActiveAdminView}/> // 28 Feb 2025 - added setActiveAdminView={setActiveAdminView}

  return content

}

const EditQsetForm = ({qSet, id, setActiveAdminView}) => {
    // 1 - set the navigate hook
    const navigate = useNavigate()

    // 2 - The mutation to update the Question Set Data
    const [ updateQuestionSet, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateQuestionSetMutation()

    // 3 - Set the data received in the component props to the local variables
    const [formData, setFormData] = useState({
        setname: qSet.setname,
        setdesc: qSet.setdesc,
      })
    // 4. Reset the input field and navigation based on the success state 
    useEffect(() => {
        if(isSuccess) {
            setFormData({
                setname: "",
                setdesc: "",
                pic: null,
              })
            // navigate('/admin/qsets')
            setActiveAdminView('questionSets'); // 28 Feb 2025
          }
          
          },[isSuccess])// ✅ 28 Feb 2025 - Removed `navigate` since it's no longer used.


    // 5. Form field updates / change handling

  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }))
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }))
    }
  }

   // 6. Form Submit logic:
   const handleSubmit = async (e) => {
    e.preventDefault()

    // Initialize an object to collect the form data
    const questionSetData = {
      setname: formData.setname,
      setdesc: formData.setdesc,
    }

    if (formData.pic) {
      // Convert the image to a base64 string
      const reader = new FileReader()
      reader.onloadend = async () => {
        // Once conversion is done, add the base64 string to your data object
        questionSetData.pic = reader.result

        // Now, send the data object to the server
        try {
            questionSetData.id = id
          console.log("Form submitted.")
          await updateQuestionSet(questionSetData).unwrap() // Dispatch the RTK Query mutation
        } catch (error) {
          console.error(error)
        }
      }
      reader.readAsDataURL(formData.pic)
    } else {
      // No image, send other data as is
      try {
        questionSetData.id = id
        console.log("Form submitted.")
        await updateQuestionSet(questionSetData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleCancel = () => {
     // navigate('/admin/qsets')
    setActiveAdminView('questionSets'); // 28 Feb 2025
  }

const handleClear = () => {
    setFormData({
        setname: "",
        setdesc: "",
        pic: null,
      })
}


return (
    <div className="bg-gray-200 p-1 mx-auto my-5 rounded-lg shadow-md shadow-black">
      {isLoading && <PulseLoader color={"#ea580c"} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">
          {error?.data?.message}
        </p>
      )}
      <div className="bg-white p-1 m-1 rounded">
        <p className="p-1 m-1 font-bold font-poppins text-xl">
          Update a Question Set
        </p>

        <div className="bg-gray-200 rounded m-1 p-1">
          <form
            onSubmit={handleSubmit}
            className="m-1 p-1 text-sm font-poppins"
          >
            <div id="setname">
              <input
                type="text"
                name="setname"
                placeholder="Set Name"
                value={formData.setname}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="setdesc">
              <textarea
                name="setdesc"
                placeholder="Set Description"
                value={formData.setdesc}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              ></textarea>
            </div>

            <div id="pic">
              <input
                type="file"
                name="pic"
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Update"}
                type={"create"}
                handleClick={handleSubmit}
              />
              <EtBtnFormBasic
                name={"Clear"}
                type={"clear"}
                handleClick={handleClear}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )

}

export default EtUpdateQuestionSet