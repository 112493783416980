import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const orgSubscriptionsAdapter = createEntityAdapter({})

const initialState = orgSubscriptionsAdapter.getInitialState()

export const orgSubscriptionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOrgSubscriptions: builder.query({
            query: () => ({
                url: '/orgSubscriptions/getOrgSubscriptions',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedOrgSubscriptions = responseData.map(orgSubscription => {
                    orgSubscription.id = orgSubscription._id
                    return orgSubscription
                })
                return orgSubscriptionsAdapter.setAll(initialState, loadedOrgSubscriptions)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'OrgSubscription', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'OrgSubscription', id}))
                    ]
                } else {
                    return [ { type: 'OrgSubscription', id: 'LIST'}]
                }
            },
        }),
        createNewOrgSubscription: builder.mutation({
            query: initialOrgSubscription => ({
                url: '/orgSubscriptions/createNewOrgSubscription',
                method: 'POST',
                body: {...initialOrgSubscription,}
            }),
            invalidatesTags: [
                { type: 'OrgSubscription', id: "LIST" }
            ] 
        }),
        updateOrgSubscription: builder.mutation({
            query: initialOrgSubscription => ({
                url: '/orgSubscriptions/updateOrgSubscription',
                method: 'PATCH',
                body: {...initialOrgSubscription,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'OrgSubscription', id: arg.id },
                { type: 'OrgSubscription', id: 'LIST' }
            ],
        }),
        deleteOrgSubscription: builder.mutation({
            query: ({id}) => ({
                url: '/orgSubscriptions/deleteOrgSubscription',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'OrgSubscription', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetOrgSubscriptionsQuery,
    useCreateNewOrgSubscriptionMutation,
    useUpdateOrgSubscriptionMutation,
    useDeleteOrgSubscriptionMutation,
} = orgSubscriptionsApiSlice

// returns the query result object
export const selectOrgSubscriptionResult = orgSubscriptionsApiSlice.endpoints.getOrgSubscriptions.select()

// create memoized selector
const selectOrgSubscriptionsData = createSelector(
    selectOrgSubscriptionResult,
    orgSubscriptionResult => orgSubscriptionResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllOrgSubscriptions,
    selectById: selectOrgSubscriptionById,
    selectIds: selectOrgSubscriptionIds,
} = orgSubscriptionsAdapter.getSelectors(state => selectOrgSubscriptionsData(state) ?? initialState)