import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const challengeTypesAdapter = createEntityAdapter({})

const initialState = challengeTypesAdapter.getInitialState()

export const challengeTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getChallengeTypes: builder.query({
            query: () => ({
                url: '/challengeTypes/getChallengeTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedChallengeTypes = responseData.map(challengeType => {
                    challengeType.id = challengeType._id
                    return challengeType
                })
                return challengeTypesAdapter.setAll(initialState, loadedChallengeTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'ChallengeType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'ChallengeType', id}))
                    ]
                } else {
                    return [ { type: 'ChallengeType', id: 'LIST'}]
                }
            },
        }),
        createNewChallengeType: builder.mutation({
            query: initialChallengeType => ({
                url: '/challengeTypes/createNewChallengeType',
                method: 'POST',
                body: {...initialChallengeType,}
            }),
            invalidatesTags: [
                { type: 'ChallengeType', id: "LIST" }
            ] 
        }),
        updateChallengeType: builder.mutation({
            query: initialChallengeType => ({
                url: '/challengeTypes/updateChallengeType',
                method: 'PATCH',
                body: {...initialChallengeType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'ChallengeType', id: arg.id },
                { type: 'ChallengeType', id: 'LIST' }
            ],
        }),
        deleteChallengeType: builder.mutation({
            query: ({id}) => ({
                url: '/challengeTypes/deleteChallengeType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'ChallengeType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetChallengeTypesQuery,
    useCreateNewChallengeTypeMutation,
    useUpdateChallengeTypeMutation,
    useDeleteChallengeTypeMutation,
} = challengeTypesApiSlice

// returns the query result object
export const selectChallengeTypeResult = challengeTypesApiSlice.endpoints.getChallengeTypes.select()

// create memoized selector
const selectChallengeTypesData = createSelector(
    selectChallengeTypeResult,
    challengeTypeResult => challengeTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllChallengeTypes,
    selectById: selectChallengeTypeById,
    selectIds: selectChallengeTypeIds,
} = challengeTypesAdapter.getSelectors(state => selectChallengeTypesData(state) ?? initialState)