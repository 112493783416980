import React, { useState, useEffect } from 'react'
import useTitle from "../../../hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
    PencilSquareIcon,
  } from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "../../../utils/EtContentEditorLight2"
import EtBtnFormBasic from "../../../components/common/buttons/EtBtnFormBasic"
import { useNavigate } from "react-router"

import { useUpdateUserVentureMutation, useGetUserVenturesQuery } from '@/features/userVentures/userVenturesApiSlice'


import { produce } from "immer";

const EtUpdateUserImprintLegacyVentureComp = ({
    id,
    userId, 
    userOrgId, 
    triggeringUrl, 
    onClose
  }) => {

  // First Created - 29 Dec 2024
  // Author - Atul Pandey
  // Inherited from the EtCreateUserImprintGratitudeComp component
  

  // 0 - Set Page title & variables
  useTitle('User Imprint Legacy - Venture - Update')

  // console.log('id is:', id)
  // console.log('userId is:', userId)
  // console.log('userOrgId is:', userOrgId)
  
  // 1a - set page navigations
  const navigate = useNavigate()

  // 1b - set form states
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState({});
  
  // 2a - set the mutation to create the new user imprint gratitude data
    const [updateUserVenture, {
      isLoading,
      isSuccess,
      isError,
      error,
    }] = useUpdateUserVentureMutation()
  
  // 2b - Get existing user creations Data from the backend
  const { 
    data: userVentureData,
    isLoading: isFetching,
    isError: isFetchError,
    error: fetchError,
  } = useGetUserVenturesQuery()
  // console.log('userVentureData', userVentureData)
  const userVentureRecords = userVentureData?.ids
  ?.map(id => userVentureData?.entities[id])
  ?.filter(item=> item._id === id && item.userId._id === userId && item.userOrgId._id === userOrgId) || []
  // console.log('userVentures', userVentureRecords)

  // 3 - Initialize Form Data State
  const [formData, setFormData] = useState({
    userVentureName: "",
    userVentureDesc: "",
    userVentureStartDate: "",
    userVentureEndDate: "",
    userVentureNotes:[],
    pic: "",
    active: true, // Default active state
  });

  // 4a - Set the FormData based on the incoming data
  useEffect(() => {
    if (userVentureRecords?.length > 0 && !isFormInitialized) {
      const selectedRecord = userVentureRecords[0];
    
      setFormData({
        userVentureName: selectedRecord?.userVentureName || "",
        userVentureDesc: selectedRecord?.userVentureDesc || "",
        userVentureDesc: selectedRecord?.userVentureDesc || "",
        userVentureStartDate: selectedRecord?.userVentureStartDate || "",
        userVentureEndDate: selectedRecord?.userVentureEndDate || "",
        userVentureNotes: selectedRecord?.userVentureNotes || [],
        aboutMe: selectedRecord?.aboutMe || '',
        pic: selectedRecord?.pic || '',
        active: selectedRecord?.active ?? true,
      });

      setIsFormInitialized(true); // Prevent future overwrites
    }
  }, [userVentureRecords, isFormInitialized]);
 
  // 5a - Convert Image to Base64
  const convertPicToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  // 5b - Handle Form Changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "pic") {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  // 5c - Toggle show / collapse each section 
  const toggleSection = (key) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // 6 - Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Convert image to Base64 if it exists
      const picBase64 = formData.pic ? await convertPicToBase64(formData.pic) : undefined;
  
      // Filter out valid notes
      const validNotes = formData.userVentureNotes?.filter(
        (note) => note?.userVentureNoteText.trim()
      );
  
      // Construct the payload
      const payload = {
        ventureId: id, // Assuming `id` is the identifier for the record to update
        userId,
        userOrgId,
        userVentureName: formData.userVentureName.trim() || undefined,
        userVentureDesc: formData.userVentureDesc.trim() || undefined,
        userVentureStartDate: formData.userVentureStartDate || undefined,
        userVentureEndDate: formData.userVentureEndDate || undefined,
        userVentureNotes: validNotes?.map((note) => ({
          ...note,
          userVentureNoteText: note?.userVentureNoteText.trim(),
        })),
        pic: picBase64 || undefined,
        active: formData.active,
      };
  
      // Update the venture using the mutation
      await updateUserVenture(payload).unwrap();
  
      if (onClose) {
        onClose(); // Close the modal if `onClose` is provided
      } else {
        navigate(triggeringUrl || "/userMagicHome"); // Redirect on success
      }
    } catch (error) {
      console.error("Error updating venture:", error);
    }
  };
  
  
  // 7 - Reset Form on Successful Submission
        useEffect(() => {
          if (isSuccess) {
            setFormData({
              userVentureName: "",
              userVentureDesc: "",
              userVentureStartDate: "",
              userVentureEndDate: "",
              userVentureNotes:[],
              pic: "",
              active: true, // Default active state
            });
      
            if (onClose) {
              onClose();
            } else {
              navigate(triggeringUrl || "/userMagicHome");
            }
          }
        }, [isSuccess, navigate]);
      
      // 8 - Handle Clear
      const handleClear = () => {
      setFormData({
        userVentureName: "",
        userVentureDesc: "",
        userVentureStartDate: "",
        userVentureEndDate: "",
        userVentureNotes:[],
        pic: "",
        active: true, // Default active state
      });
      };
  
      // 9 - Handle Cancel
      const handleCancel = () => {
      if (onClose) {
          onClose();
      } else {
          navigate(triggeringUrl || "/userMagicHome");
      }
      };

      // console.log('FormData:', formData)

  return (
    <div className="mx-auto w-full m-1 p-1 bg-white rounded shadow-md shadow-black">
      {isLoading && <PulseLoader color={'#ea580c'} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">{error?.data?.message}</p>
      )}

      <p className="p-1 m-1 font-bold font-poppins text-xl">
        Update User Hidden Magic
      </p>

      <form onSubmit={handleSubmit} className="m-1 p-1 text-xs font-poppins">
        
        {/* 1. Legacy - Venture - Name */}
        <div id="ventureName" className="bg-gray-100 rounded p-1 m-1">
          <label htmlFor="userVentureName" className="block text-gray-600 text-sm font-bold mb-1">
            Venture Name
          </label>
          <input
            type="text"
            name="userVentureName"
            value={formData.userVentureName}
            onChange={(e) =>
              setFormData((prev) =>
                produce(prev, (draft) => {
                  draft.userVentureName = e.target.value;
                })
              )
            }
            placeholder="Enter venture name"
            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
          />
        </div>

        {/* 2. Legacy - Venture - Description */}
        <div id="ventureDesc" className="bg-gray-100 rounded p-1 m-1">
          <label
            htmlFor="userVentureDesc"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
            Venture Description
          </label>
          {isFormInitialized && ( 
          <EtContentEditorLight2
            value={formData.userVentureDesc}
            handleChange={(content) => {
              setFormData((prev) =>
                produce(prev, (draft) => {
                  draft.userVentureDesc = content;
                })
              );
            }}
            placeholderText="Write your venture description here..."
            editorKey={`ventureDesc-main-${id}`} // Ensure unique editor key
          />)}
        </div>
        
        <div className="flex justify-between items-center">
          {/* 3. Legacy - Venture - Start Date */}
          <div id="ventureStartDate" className="bg-gray-100 rounded p-1 m-1 w-full">
            <label
              htmlFor="userVentureStartDate"
              className="block text-gray-600 text-sm font-bold mb-1"
            >
              Start Date
            </label>
            <input
              type="date"
              name="userVentureStartDate"
              value={formData.userVentureStartDate ? formData.userVentureStartDate.split('T')[0] : ''}
              onChange={(e) =>
                setFormData((prev) =>
                  produce(prev, (draft) => {
                    draft.userVentureStartDate = e.target.value;
                  })
                )
              }
              className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
            />
          </div>

          {/* 4. Legacy - Venture - End Date */}
          <div id="ventureEndDate" className="bg-gray-100 rounded p-1 m-1 w-full">
            <label
              htmlFor="userVentureEndDate"
              className="block text-gray-600 text-sm font-bold mb-1"
            >
              End Date
            </label>
            <input
              type="date"
              name="userVentureEndDate"
              value={formData.userVentureEndDate ? formData.userVentureEndDate.split('T')[0] : ''}
              onChange={(e) =>
                setFormData((prev) =>
                  produce(prev, (draft) => {
                    draft.userVentureEndDate = e.target.value;
                  })
                )
              }
              className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
            />
          </div>
        </div>

        {/* 5. Legacy - Venture - Notes */}
        <div id="ventureNotes" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <p className="font-bold text-sm">Venture Notes</p>
            <button
              type="button"
              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
              onClick={() =>
                setFormData((prev) =>
                  produce(prev, (draft) => {
                    draft.userVentureNotes.push({
                      userVentureNoteText: "",
                      userVentureNoteDate: new Date().toISOString(),
                    });
                  })
                )
              }
            >
              <PlusIcon className="h-4 w-4 inline" />
            </button>
          </div>

          {formData.userVentureNotes?.map((note, index) => (
            <div key={index} className="p-2 border rounded mb-2 bg-white">
              <div className="flex justify-between items-center mb-2">
                <p className="font-bold text-xs">Note {index + 1}</p>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() =>
                    setFormData((prev) =>
                      produce(prev, (draft) => {
                        draft.userVentureNotes.splice(index, 1); // Remove note
                      })
                    )
                  }
                >
                  <MinusIcon className="h-4 w-4 inline" />
                </button>
              </div>

              {/* Note Text */}
              <div className="bg-gray-100 rounded p-1 m-1">
                <label
                  htmlFor={`ventureNoteText-${index}`}
                  className="block text-gray-600 text-sm font-bold mb-1"
                >
                  Note Text
                </label>
                {isFormInitialized && ( 
                <EtContentEditorLight2
                  value={note.userVentureNoteText}
                  handleChange={(content) => {
                    setFormData((prev) =>
                      produce(prev, (draft) => {
                        draft.userVentureNotes[index].userVentureNoteText = content;
                      })
                    );
                  }}
                  placeholderText="Write your note here..."
                  editorKey={`ventureNote-${index}-${note?._id || `new-${index}`}`} // Ensure unique editor key
                />)}
              </div>
            </div>
          ))}
        </div>


        
        {/* 6. Form Submissions Bar */}
        <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic 
              name={'Update'} 
              type={'create'} 
              handleClick={handleSubmit} 
              />
            <EtBtnFormBasic
              name={'Clear'}
              type={'clear'}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={'Cancel'}
              type={'cancel'}
              handleClick={handleCancel}
            />
        </div>

      </form>
      
    </div>
  )
}

export default EtUpdateUserImprintLegacyVentureComp