import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const sessionsAdapter = createEntityAdapter({})

const initialState = sessionsAdapter.getInitialState()

export const sessionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSessions: builder.query({
            query: () => ({
                url: '/sessions/getSessions',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedSessions = responseData.map(session => {
                    session.id = session._id
                    return session
                })
                return sessionsAdapter.setAll(initialState, loadedSessions)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'Session', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'Session', id}))
                    ]
                } else {
                    return [ { type: 'Session', id: 'LIST'}]
                }
            },
        }),
        createNewSession: builder.mutation({
            query: initialSession => ({
                url: '/sessions/createNewSession',
                method: 'POST',
                body: {...initialSession,}
            }),
            invalidatesTags: [
                { type: 'Session', id: "LIST" }
            ] 
        }),
        updateSession: builder.mutation({
            query: initialSession => ({
                url: '/sessions/updateSession',
                method: 'PATCH',
                body: {...initialSession,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Session', id: arg.id },
                { type: 'Session', id: 'LIST' }
            ],
        }),
        deleteSession: builder.mutation({
            query: ({id}) => ({
                url: '/sessions/deleteSession',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Session', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetSessionsQuery,
    useCreateNewSessionMutation,
    useUpdateSessionMutation,
    useDeleteSessionMutation,
} = sessionsApiSlice

// returns the query result object
export const selectSessionResult = sessionsApiSlice.endpoints.getSessions.select()

// create memoized selector
const selectSessionsData = createSelector(
    selectSessionResult,
    sessionResult => sessionResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllSessions,
    selectById: selectSessionById,
    selectIds: selectSessionIds,
} = sessionsAdapter.getSelectors(state => selectSessionsData(state) ?? initialState)