import React, { useState, useEffect, useMemo } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLightWithPlace from "@/utils/EtContentEditorLightWithPlace"
import { useGetCapabilitiesServiceFunctionAreaTypesQuery, useUpdateCapabilitiesServiceFunctionAreaTypeMutation } from "./capabilitiesServiceFunctionAreaTypesApiSlice"
import { useGetCapabilitiesServiceTypesQuery } from '../capabilitiesServiceTypes/capabilitiesServiceTypesApiSlice' // 14 Nov 2024

const EtUpdateCapabilitiesServiceFunctionAreaType = ({id, setActiveAdminView}) => {
  // Created - 17 Nov 2024
  // Author - Atul Pandey
  // Inherited from functionTypes
  // Updated - 27 Feb 2025 - updated the serviceFunctionArea with props - { id, setActiveAdminView}
  // Updated - 27 Feb 2025 - switched from navigate to setActiveAdminView

  // 1 - Set Title
  useTitle("Update CapabilitiesServiceFunctionArea Type")

  // 2 - extract the capabilitiesServiceFunctionAreaType Id from the url
  // const { id } = useParams()
  // //console.log("id:", id)

  // 3 - Extract the capabilitiesServiceFunctionAreaType from the query to load the capabilitiesServiceFunctionAreaType data
  const { capabilitiesServiceFunctionAreaType } = useGetCapabilitiesServiceFunctionAreaTypesQuery("CapabilitiesServiceFunctionAreaType", {
    selectFromResult: ({ data }) => ({
      capabilitiesServiceFunctionAreaType: data?.entities[id],
    }),
  })

  //console.log('capabilitiesServiceFunctionArea type:', capabilitiesServiceFunctionAreaType)

  // 4 - Check for returned data
  if (!capabilitiesServiceFunctionAreaType) {
    return <PulseLoader color={"#ea580c"} />
  }

  // 5. Display data
  let content
  content = <EtUpdateCapabilitiesServiceFunctionAreaTypeForm capabilitiesServiceFunctionAreaType={capabilitiesServiceFunctionAreaType} id={id} setActiveAdminView={setActiveAdminView}/> // 27 Feb 2025 - added setActiveAdminView={setActiveAdminView}
  return content
}

const EtUpdateCapabilitiesServiceFunctionAreaTypeForm = ({ capabilitiesServiceFunctionAreaType, id,setActiveAdminView}) =>{
  // 1a - set the variable for navigate
  const navigate = useNavigate()
  //console.log('update form invoked!')
  console.log('capabilitiesServiceFunctionAreaType', capabilitiesServiceFunctionAreaType)

  // 1b - state variables for service types
  // const [selectedFocus, setSelectedFocus] = useState("");
  // const [filteredServiceTypes, setFilteredServiceTypes] = useState([]);
  
  // 2 - set the mutation to create the new section data
  const[updateCapabilitiesServiceFunctionAreaType, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useUpdateCapabilitiesServiceFunctionAreaTypeMutation()

  // 2b - Get ServiceTypes Data
  const { data: serviceTypesData } = useGetCapabilitiesServiceTypesQuery();
  const serviceTypes = useMemo(() => {
    return serviceTypesData?.ids?.map(id => serviceTypesData?.entities[id]) || [];
  }, [serviceTypesData]);
  console.log('serviceTypes', serviceTypes)

  // 2c - Set initial values for selectedFocus and filteredServiceTypes when component mounts
  // useEffect(() => {
  //   if (capabilitiesServiceFunctionAreaType?.capabilitiesServiceType) {
  //     const initialFocus = capabilitiesServiceFunctionAreaType?.capabilitiesServiceType?.capabilitiesServiceFocus;
  //     setSelectedFocus(initialFocus);
      
  //     // Set filtered service types based on the initial focus
  //     setFilteredServiceTypes(
  //       serviceTypes.filter((service) => service?.capabilitiesServiceFocus === initialFocus)
  //     );

  //     // Set the initial value for capabilitiesServiceType in formData
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       capabilitiesServiceType: capabilitiesServiceFunctionAreaType?.capabilitiesServiceType._id,
  //     }));
  //   }
  // }, [capabilitiesServiceFunctionAreaType, serviceTypes]);
  


// 3 - set the form data object
const [formData, setFormData] = useState({
  capabilitiesServiceFunctionAreaTypeName: capabilitiesServiceFunctionAreaType.capabilitiesServiceFunctionAreaTypeName,
  capabilitiesServiceFunctionAreaTypeDesc: capabilitiesServiceFunctionAreaType.capabilitiesServiceFunctionAreaTypeDesc,
  aboutMe: capabilitiesServiceFunctionAreaType.aboutMe,
})

//console.log('formData for update:', formData)
useEffect(() => {
  if (capabilitiesServiceFunctionAreaType?.capabilitiesServiceType) {
    setFormData((prevData) => ({
      ...prevData,
      capabilitiesServiceType: capabilitiesServiceFunctionAreaType.capabilitiesServiceType._id,
    }));
  }
}, [capabilitiesServiceFunctionAreaType]);

// 4 - Reset form upon successful submission
useEffect(() => {
  if(isSuccess) {
    setFormData({
      capabilitiesServiceFunctionAreaTypeName: "",
      capabilitiesServiceFunctionAreaTypeDesc: "",
      capabilitiesServiceType: null,
      aboutMe: "",
      pic: "",
    })

    // navigate('/capabilitiesServiceFunctionAreatypes')
    setActiveAdminView('capabilitiesServiceFunctionAreaTypes'); // 27 Feb 2025
  }

},[isSuccess]) // ✅ 27 Feb 2025 - Removed `navigate` since it's no longer used.

// 5 - Form field updates / change handling

const handleChange = (e) => {
  const { name, value, files } = e.target
  if (name === "pic") {
    setFormData(prevState => ({ ...prevState, pic: files[0] }))
  } else {
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }
}

// 6 - serviceFunctionArea to strip html tags from sections
const stripHtml = (html) => {
  return html.replace(/<\/?[^>]+(>|$)/g, "");
}

// 7 - Form Submit Logic
const handleSubmit = async (e) => {
e.preventDefault()

// Validate that capabilitiesServiceType is selected
if (!formData.capabilitiesServiceType) {
  alert("Please select a Service Type before submitting.");
  return;
}

// Initialize an object to collect the form data
const capabilitiesServiceFunctionAreaTypeData = {
  capabilitiesServiceFunctionAreaTypeName: formData.capabilitiesServiceFunctionAreaTypeName,
  capabilitiesServiceFunctionAreaTypeDesc: formData.capabilitiesServiceFunctionAreaTypeDesc,
  capabilitiesServiceType: formData.capabilitiesServiceType,
  aboutMe: formData.aboutMe,
}

//console.log('capabilitiesServiceFunctionAreaType Name:',capabilitiesServiceFunctionAreaTypeData.capabilitiesServiceFunctionAreaTypeName)

if (formData.pic) {
  // Convert the image to a base64 string
  const reader = new FileReader()
  reader.onloadend = async () => {
    // Once conversion is done, add the base64 string to your data object
    capabilitiesServiceFunctionAreaTypeData.pic = reader.result

    // Now, send the data object to the server
    try {
      capabilitiesServiceFunctionAreaTypeData.id = id
      console.log("Form submitted.")
      await updateCapabilitiesServiceFunctionAreaType(capabilitiesServiceFunctionAreaTypeData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
  }
  reader.readAsDataURL(formData.pic)
} else {
  // No image, send other data as is
  try {
    capabilitiesServiceFunctionAreaTypeData.id = id
    console.log("Form submitted.")
    await updateCapabilitiesServiceFunctionAreaType(capabilitiesServiceFunctionAreaTypeData).unwrap() // Dispatch the RTK Query mutation
  } catch (error) {
    console.error(error)
  }
}
}

// 8 - Handle Cancel

const handleCancel = () => {
  // navigate('/capabilitiesServiceFunctionAreatypes')
  setActiveAdminView('capabilitiesServiceFunctionAreaTypes'); // 27 Feb 2025
}

// 9 - Handle Clear
const handleClear = () => {
  setFormData({
    capabilitiesServiceFunctionAreaTypeName: "",
    capabilitiesServiceFunctionAreaTypeDesc: "",
    capabilitiesServiceType: null,
    aboutMe: "",
    pic: "",
    })
}

return (
  <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
    {isLoading && <PulseLoader color={"#ea580c"} />}
    {isError && (
      <p className="text-orange-700 font-semi-bold py-2">
        {error?.data?.message}
      </p>
    )}
    <div className="bg-white p-1 m-1 rounded">
      <p className="p-1 m-1 font-bold font-poppins text-xl">Update a CapabilitiesServiceFunctionArea Type</p>

      <div className="bg-gray-200 rounded m-1 p-1">
        <form
          onSubmit={handleSubmit}
          className="m-1 p-1 text-xs font-poppins"
        >

          {/* <div id="focus">
            <select
              name="focus"
              value={selectedFocus}
              onChange={(e) => {
                const newFocus = e.target.value;
                setSelectedFocus(newFocus);
                setFilteredServiceTypes(
                  serviceTypes?.filter((service) => service?.capabilitiesServiceFocus === newFocus)
                );
                // Reset the selected service type if the focus changes
                setFormData((prevData) => ({ ...prevData, capabilitiesServiceType: "" }));
              }}
              className="w-full rounded p-2 m-1"
            >
              <option value="">Select Service Focus</option>
              <option value="Business">Business</option>
              <option value="Technology">Technology</option>
            </select>
          </div> */}

          <div id="serviceType">
            <select
              name="capabilitiesServiceType"
              value={formData.capabilitiesServiceType || ""}
              onChange={(e) => setFormData({ ...formData, capabilitiesServiceType: e.target.value })}
              className="w-full rounded p-2 m-1"
              // disabled={!selectedFocus} // Disable if no focus is selected
            >
              <option value="">Select Service Type</option>
              {serviceTypes?.map((service) => (
                <option key={service._id} value={service._id}>
                  {service?.capabilitiesServiceTypeName}
                </option>
              ))}
              {/* {filteredServiceTypes?.map((service) => (
                <option key={service._id} value={service._id}>
                  {service?.capabilitiesServiceTypeName}
                </option>
              ))} */}
            </select>
          </div>


          
          <div id="capabilitiesServiceFunctionAreaTypeName">
            <input
              type="text"
              name="capabilitiesServiceFunctionAreaTypeName"
              placeholder="Enter the capabilitiesServiceFunctionArea type name here"
              value={formData.capabilitiesServiceFunctionAreaTypeName}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div className="bg-white w-full rounded p-2 m-1">
            <EtContentEditorLightWithPlace
              value={formData.capabilitiesServiceFunctionAreaTypeDesc}
              handleChange={(content) =>
                setFormData({ ...formData, capabilitiesServiceFunctionAreaTypeDesc: content })
              }
              placeholderText={
                "Write this capabilitiesServiceFunctionArea type is about or will be used for"
              }
              // toolbarId={'sectionDesc'}
            />
          </div>

          <div id="aboutMe">
            <input
              type="text"
              name="aboutMe"
              placeholder="Add something about the personality about this capabilitiesServiceFunctionArea type"
              value={formData.aboutMe}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="pic">
            <input
              type="file"
              name="pic"
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

         <div id="capabilitiesServiceFunctionAreas" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic
              name={"Update"}
              type={"create"}
              handleClick={handleSubmit}
            />
            <EtBtnFormBasic
              name={"Clear"}
              type={"clear"}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={"Cancel"}
              type={"cancel"}
              handleClick={handleCancel}
            />
          </div>
        </form>
      </div>
    </div>
  </div>
)

}

export default EtUpdateCapabilitiesServiceFunctionAreaType