import React from "react"
import ReactQuill from "react-quill"
import EtEditorToolbarLight, { modules, formats } from "./EtEditorToolbarLight"
import "react-quill/dist/quill.snow.css"


export const EtContentEditorLightWithPlace = ({value, handleChange, placeholderText}) => {
  
  return (
    <div className="custom-quill-editor" >
      <EtEditorToolbarLight />
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleChange}
        placeholder={placeholderText}
        modules={modules}
        formats={formats}
        className='w-full h-full'
      />
    </div>
  )
}

export default EtContentEditorLightWithPlace
