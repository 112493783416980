import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userVenturesAdapter = createEntityAdapter({})

const initialState = userVenturesAdapter.getInitialState()

export const userVenturesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserVentures: builder.query({
            query: () => ({
                url: '/userVentures/getUserVentures',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserVentures = responseData.map(userVenture => {
                    userVenture.id = userVenture._id
                    return userVenture
                })
                return userVenturesAdapter.setAll(initialState, loadedUserVentures)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserVenture', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserVenture', id}))
                    ]
                } else {
                    return [ { type: 'UserVenture', id: 'LIST'}]
                }
            },
        }),
        createNewUserVenture: builder.mutation({
            query: initialUserVenture => ({
                url: '/userVentures/createNewUserVenture',
                method: 'POST',
                body: {...initialUserVenture,}
            }),
            invalidatesTags: [
                { type: 'UserVenture', id: "LIST" }
            ] 
        }),
        updateUserVenture: builder.mutation({
            query: initialUserVenture => ({
                url: '/userVentures/updateUserVenture',
                method: 'PATCH',
                body: {...initialUserVenture,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserVenture', id: arg.id },
                { type: 'UserVenture', id: 'LIST' }
            ],
        }),
        deleteUserVenture: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userVentures/deleteUserVenture',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userVentures/deleteUserVenture',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserVenture', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserVenturesQuery,
    useCreateNewUserVentureMutation,
    useUpdateUserVentureMutation,
    useDeleteUserVentureMutation,
} = userVenturesApiSlice

// returns the query result object
export const selectUserVentureResult = userVenturesApiSlice.endpoints.getUserVentures.select()

// create memoized selector
const selectUserVenturesData = createSelector(
    selectUserVentureResult,
    userVentureResult => userVentureResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserVentures,
    selectById: selectUserVentureById,
    selectIds: selectUserVentureIds,
} = userVenturesAdapter.getSelectors(state => selectUserVenturesData(state) ?? initialState)