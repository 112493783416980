import {getNearestMonday } from "@/utils/formatDate"

export const processAccomplishments = (data, userId, userOrgId) => {
    // First Created - 19 Jan 2025
    // Author - Atul Pandey

    // Get today's date
  const today = new Date();

  // Calculate date ranges
  const currentWeekStarting = getNearestMonday(today);
  currentWeekStarting.setHours(0, 0, 0, 0);
  const currentWeekEnding = new Date(currentWeekStarting);
  currentWeekEnding.setDate(currentWeekStarting.getDate() + 6);
  currentWeekEnding.setHours(23, 59, 59, 999);
  // console.log('currentWeekStarting', currentWeekStarting) // 05 Mar 2025
  // console.log('currentWeekEnding', currentWeekEnding)     // 05 Mar 2025

  const currentYearStart = new Date(today.getFullYear(), 0, 1, 0, 0, 0, 0);
  const currentYearEnd = new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999);

  const threeYearsStart = new Date(today.getFullYear() - 3, 0, 1, 0, 0, 0, 0);
  const fiveYearsStart = new Date(today.getFullYear() - 5, 0, 1, 0, 0, 0, 0);

  // Helper function to filter by date range
  const filterByDateRange = (item, startDate, endDate) => {
    const weekStart = new Date(item.weekStarting);
    const weekEnd = new Date(item.weekEnding);
    return weekStart >= startDate && weekEnd <= endDate;
  };

  // Process accomplishments - Self
  const accomplishmentsThisWeek = data.filter(
    (item) =>
      item.userId._id === userId &&
      item.userOrgId._id === userOrgId &&
      filterByDateRange(item, currentWeekStarting, currentWeekEnding)
  );

  const accomplishmentsThisYear = data.filter(
    (item) =>
      item.userId._id === userId &&
      item.userOrgId._id === userOrgId &&
      filterByDateRange(item, currentYearStart, currentYearEnd)
  );

  const accomplishments3Yrs = data.filter(
    (item) =>
      item.userId._id === userId &&
      item.userOrgId._id === userOrgId &&
      filterByDateRange(item, threeYearsStart, today)
  );

  const accomplishments5Yrs = data.filter(
    (item) =>
      item.userId._id === userId &&
      item.userOrgId._id === userOrgId &&
      filterByDateRange(item, fiveYearsStart, today)
  );

  const accomplishmentsAllYrs = data.filter(
    (item) =>
      item.userId._id === userId &&
      item.userOrgId._id === userOrgId
  );


  // Process accomplishments - Org
  const accomplishmentsOrgThisWeek = data.filter(
    (item) =>
      item.userOrgId._id === userOrgId &&
      filterByDateRange(item, currentWeekStarting, currentWeekEnding)
  );

  const accomplishmentsOrgThisYear = data.filter(
    (item) =>
      item.userOrgId._id === userOrgId &&
      filterByDateRange(item, currentYearStart, currentYearEnd)
  );

  const accomplishmentsOrg3Yrs = data.filter(
    (item) =>
      item.userOrgId._id === userOrgId &&
      filterByDateRange(item, threeYearsStart, today)
  );

  const accomplishmentsOrg5Yrs = data.filter(
    (item) =>
      item.userOrgId._id === userOrgId &&
      filterByDateRange(item, fiveYearsStart, today)
  );

  const accomplishmentsOrgAllYrs = data.filter(
    (item) =>
      item.userOrgId._id === userOrgId
  );

  return {
    accomplishmentsThisWeek,
    accomplishmentsThisYear,
    accomplishments3Yrs,
    accomplishments5Yrs,
    accomplishmentsAllYrs,
    accomplishmentsOrgThisWeek,
    accomplishmentsOrgThisYear,
    accomplishmentsOrg3Yrs,
    accomplishmentsOrg5Yrs,
    accomplishmentsOrgAllYrs,
  };
}