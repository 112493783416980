import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const contactsAdapter = createEntityAdapter({})

const initialState = contactsAdapter.getInitialState()

export const contactsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getContacts: builder.query({
            query: () => ({
                url: '/contacts/getContacts',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedContacts = responseData.map(contact => {
                    contact.id = contact._id
                    return contact
                })
                return contactsAdapter.setAll(initialState, loadedContacts)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'Contact', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'Contact', id}))
                    ]
                } else {
                    return [ { type: 'Contact', id: 'LIST'}]
                }
            },
        }),
        createNewContact: builder.mutation({
            query: initialContact => ({
                url: '/contacts/createNewContact',
                method: 'POST',
                body: {...initialContact,}
            }),
            invalidatesTags: [
                { type: 'Contact', id: "LIST" }
            ] 
        }),
        updateContact: builder.mutation({
            query: initialContact => ({
                url: '/contacts/updateContact',
                method: 'PATCH',
                body: {...initialContact,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Contact', id: arg.id },
                { type: 'Contact', id: 'LIST' }
            ],
        }),
        deleteContact: builder.mutation({
            query: ({ contactId, userId }) => ({
              url: '/contacts/deleteContact',
              method: 'DELETE',
              body: { contactId, userId },  // Send both `contactId` and `userId`
            }),
            invalidatesTags: (result, error, arg) => [
              { type: 'Contact', id: arg.contactId }
            ],
          }),          
        // deleteContact: builder.mutation({
        //     query: ({id}) => ({
        //         url: '/contacts/deleteContact',
        //         method: 'DELETE',
        //         body: {id}
        //     }),
        //     invalidatesTags: (result, error, arg) => [
        //         { type: 'Contact', id: arg.id}
        //     ]
        // }),
    }),
})

export const {
    useGetContactsQuery,
    useCreateNewContactMutation,
    useUpdateContactMutation,
    useDeleteContactMutation,
} = contactsApiSlice

// returns the query result object
export const selectContactResult = contactsApiSlice.endpoints.getContacts.select()

// create memoized selector
const selectContactsData = createSelector(
    selectContactResult,
    contactResult => contactResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllContacts,
    selectById: selectContactById,
    selectIds: selectContactIds,
} = contactsAdapter.getSelectors(state => selectContactsData(state) ?? initialState)