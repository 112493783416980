import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const userImprintRolesAdapter = createEntityAdapter({})

const initialState = userImprintRolesAdapter.getInitialState()

export const userImprintRolesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserImprintRoles: builder.query({
            query: () => ({
                url: '/userImprintRoles/getUserImprintRoles',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserImprintRoles = responseData.map(userImprintRole => {
                    userImprintRole.id = userImprintRole._id
                    return userImprintRole
                })
                return userImprintRolesAdapter.setAll(initialState, loadedUserImprintRoles)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserImprintRole', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserImprintRole', id}))
                    ]
                } else {
                    return [ { type: 'UserImprintRole', id: 'LIST'}]
                }
            },
        }),
        createNewUserImprintRole: builder.mutation({
            query: initialUserImprintRole => ({
                url: '/userImprintRoles/createNewUserImprintRole',
                method: 'POST',
                body: {...initialUserImprintRole,}
            }),
            invalidatesTags: [
                { type: 'UserImprintRole', id: "LIST" }
            ] 
        }),
        updateUserImprintRole: builder.mutation({
            query: initialUserImprintRole => ({
                url: '/userImprintRoles/updateUserImprintRole',
                method: 'PATCH',
                body: {...initialUserImprintRole,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserImprintRole', id: arg.id },
                { type: 'UserImprintRole', id: 'LIST' }
            ],
        }),
        deleteUserImprintRole: builder.mutation({
            query: ({id}) => ({
                url: '/userImprintRoles/deleteUserImprintRole',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserImprintRole', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserImprintRolesQuery,
    useCreateNewUserImprintRoleMutation,
    useUpdateUserImprintRoleMutation,
    useDeleteUserImprintRoleMutation,
} = userImprintRolesApiSlice

// returns the query result object
export const selectUserImprintRoleResult = userImprintRolesApiSlice.endpoints.getUserImprintRoles.select()

// create memoized selector
const selectUserImprintRolesData = createSelector(
    selectUserImprintRoleResult,
    userImprintRoleResult => userImprintRoleResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserImprintRoles,
    selectById: selectUserImprintRoleById,
    selectIds: selectUserImprintRoleIds,
} = userImprintRolesAdapter.getSelectors(state => selectUserImprintRolesData(state) ?? initialState)