import React, { useState, useEffect } from "react"
import { useNavigate, useParams  } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import { useGetOrgSubscriptionsQuery, useUpdateOrgSubscriptionMutation } from "./orgSubscriptionsApiSlice"
import { useGetOrganizationsQuery } from "../organizations/organizationsApiSlice"
import { useGetSubsPacksQuery } from "../subsPacks/subscriptionPacksApiSlice"


const EtUpdateOrgSubscription = ({id, setActiveAdminView}) => {
  // First Created - 15 May 2024
  // Author - Atul Pandey
  // Updated - 01 Mar 2025 - updated the softSkillFunctionArea with props - { id, setActiveAdminView}
  // Updated - 01 Mar 2025 - switched from navigate to setActiveAdminView

  // 1 - Set Page Title
  useTitle('Update An Org Subscription')

  // 2 - Extract the Id to be updated
  // const { id } = useParams()
  // //console.log("id:", id)

  // 3 - Extract the organization to be updated
  const { orgSubscription } = useGetOrgSubscriptionsQuery("OrgSubscription", {
    selectFromResult: ({ data }) => ({
      orgSubscription: data?.entities[id],
    }),
  })
  console.log('orgSubscription', orgSubscription)
  
  // 4 - Check for returned data
  if (!orgSubscription) {
    return <PulseLoader color={"#ea580c"} />
  }

  // 5. Display data
  let content
  content = <EtUpdateOrgSubscriptionForm orgSubscription={orgSubscription} id={id} setActiveAdminView={setActiveAdminView}/> // 01 Mar 2025 - added setActiveAdminView={setActiveAdminView} 

  return content

}

const EtUpdateOrgSubscriptionForm = ({orgSubscription, id, setActiveAdminView}) => {
  // 1 - set the variable for navigate
  const navigate = useNavigate()

  // 2 - Define the mutation to update the organization
  const [updateOrgSubscription, { isLoading, isSuccess, isError, error }] = useUpdateOrgSubscriptionMutation()

  // 3 - Get organization data for options
  const { organizations } = useGetOrganizationsQuery('organizationsList', {
    selectFromResult: ( { data }) => ({
      organizations: data?.ids.map(id => data?.entities[id])
    })
  })

  // 4 - Get orgSubscription types data for options
  const { subsPacks } = useGetSubsPacksQuery('subsPacksList', {
    selectFromResult: ( { data }) => ({
      subsPacks: data?.ids.map(id => data?.entities[id])
    })
  })

  // console.log('organizations:', organizations)
  // console.log('session types:', subsPacks)

  // 5 - Create options to select a organization from list of organizations
  const organizationOptions = organizations ? [
  <option key="placeholder" value="" disabled selected>
    Select an organization
  </option>,
  ...organizations?.map(organization => (
    <option key={organization._id} value={organization._id}>
      {organization.organizationName}
    </option>
  ))
  ] : []

  // 6 - Create options to select a session type from list of session types
  const subsPackOptions = subsPacks ? [
  <option key="placeholder" value="" disabled selected>
    Select a Subscription Pack
  </option>,
  ...subsPacks?.map(subsPack => (
    <option key={subsPack._id} value={subsPack._id}>
      {subsPack.subscriptionPackName}
    </option>
  ))
  ] : []

  // 7 - Fill the form data with extracted org subscription info
  const [formData, setFormData] = useState({
    orgId: orgSubscription.orgId._id, 
    subsPackId: orgSubscription.subsPackId[0]._id, 
    orgSubscriptionName: orgSubscription.orgSubscriptionName,
    aboutMe: orgSubscription.aboutMe || "", 
  })

  // console.log('formData', formData)

  // 8 - Reset form upon successful submission
  useEffect(() => {
    if(isSuccess) {
      setFormData({
          orgId: "", 
          subsPackId: "", 
          orgSubscriptionName: "",
          aboutMe: "", 
          pic: "", 
      })
  
      // navigate('/orgSubscriptions')
      setActiveAdminView('orgSubscriptions'); // 01 Mar 2025
    }
    
    },[isSuccess])// ✅ 01 Mar 2025 - Removed `navigate` since it's no longer used.

  // 9 - Form field updates / change handling
  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData(prevState => ({ ...prevState, pic: files[0] }))
    }  else {
      setFormData(prevState => ({ ...prevState, [name]: value }))
    }
  }

  // 10 - Form Submit
  const handleSubmit = async (e) => {
    e.preventDefault()
  
    // Initialize an object to collect the form data
    const orgSubscriptionData = {
      orgId: formData.orgId,
      subsPackId: formData.subsPackId,
      orgSubscriptionName: formData.orgSubscriptionName,
      aboutMe: formData.aboutMe,
    }
  
    //console.log('session Name:',orgSubscriptionData.sessionName)
    //console.log('session Planned Date Slot:',orgSubscriptionData.sessionPlannedDateSlot)
  
    if (formData.pic) {
      // Convert the image to a base64 string
      const reader = new FileReader()
      reader.onloadend = async () => {
        // Once conversion is done, add the base64 string to your data object
        orgSubscriptionData.pic = reader.result
  
        // Now, send the data object to the server
        try {
          orgSubscriptionData.id = id
          console.log("Form submitted.")
          await updateOrgSubscription(orgSubscriptionData).unwrap() // Dispatch the RTK Query mutation
        } catch (error) {
          console.error(error)
        }
      }
      reader.readAsDataURL(formData.pic)
    } else {
      // No image, send other data as is
      try {
        orgSubscriptionData.id = id
        console.log("Form submitted.")
        await updateOrgSubscription(orgSubscriptionData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    }
  }
  
    // 11 - Handle Cancel
    const handleCancel = () => {
      // navigate('/orgSubscriptions')
      setActiveAdminView('orgSubscriptions'); // 01 Mar 2025
    }
  
  // 12 - Handle Clear
    const handleClear = () => {
      setFormData({
        orgId: "", 
        subsPackId: "", 
        orgSubscriptionName: "",
        aboutMe: "", 
        pic: "", 
      })
    }

    return (
      <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
        {isLoading && <PulseLoader color={"#ea580c"} />}
        {isError && (
          <p className="text-orange-700 font-semi-bold py-2">
            {error?.data?.message}
          </p>
        )}
        <div className="bg-white p-1 m-1 rounded">
          <p className="p-1 m-1 font-bold font-poppins text-xl">Update Org Subscription</p>
  
          <div className="bg-gray-200 rounded m-1 p-1">
            <form
              onSubmit={handleSubmit}
              className="m-1 p-1 text-xs font-poppins"
            >
              <div id="trailSelector">
                <select
                  name="orgId"
                  id="orgId"
                  value={formData.orgId}
                  onChange={handleChange}
                  className="w-full rounded p-2 m-1"
                >
                  {organizationOptions}
                </select>
              </div>
  
              <div id="subsPackSelector">
                <select
                  name="subsPackId"
                  id="subsPackId"
                  value={formData.subsPackId}
                  onChange={handleChange}
                  className="w-full rounded p-2 m-1"
                >
                  {subsPackOptions}
                </select>
              </div>
  
              <div id="orgSubscriptionName">
                <input
                  type="text"
                  name="orgSubscriptionName"
                  placeholder="Enter the org subscription name here"
                  value={formData.orgSubscriptionName}
                  onChange={handleChange}
                  className="w-full rounded p-2 m-1"
                />
              </div>
  
              <div id="aboutMe">
                <input
                  type="text"
                  name="aboutMe"
                  placeholder="Add something about the personality of this subscription"
                  value={formData.aboutMe}
                  onChange={handleChange}
                  className="w-full rounded p-2 m-1"
                />
              </div>
  
              <div id="pic">
                <input
                  type="file"
                  name="pic"
                  onChange={handleChange}
                  className="w-full rounded p-2 m-1"
                />
              </div>
  
  
              <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
                <EtBtnFormBasic
                  name={"Update"}
                  type={"create"}
                  handleClick={handleSubmit}
                />
                <EtBtnFormBasic
                  name={"Clear"}
                  type={"clear"}
                  handleClick={handleClear}
                />
                <EtBtnFormBasic
                  name={"Cancel"}
                  type={"cancel"}
                  handleClick={handleCancel}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  

}

export default EtUpdateOrgSubscription