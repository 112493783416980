import React from 'react';

import { withRef } from '@udecode/cn';
import {
   useMediaToolbarButton,
} from '@udecode/plate-media';
import {
  ELEMENT_IMAGE,
} from '@udecode/plate-media';

import { Icons } from '@/components/plate-ui/Icons';

import { ToolbarButton } from './Toolbar';

export const MediaToolbarButton = withRef(({ nodeType, ...rest }, ref) => {
  const { props } = useMediaToolbarButton({ nodeType });

  return (
    <ToolbarButton ref={ref} {...props} {...rest}>
      {nodeType === ELEMENT_IMAGE ? <Icons.image />: <Icons.media />} {/* 21 Jun 2024 - added the conditional  */}
    </ToolbarButton>
  );
});