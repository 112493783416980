import React, {useState} from 'react'
import { useNavigate } from 'react-router'
import { addIcon } from '@/assets/img/imgAssets'
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import PulseLoader from "react-spinners/PulseLoader"
import { useGetCapabilitiesFunctionTypesQuery } from './capabilitiesFunctionTypesApiSlice'

const EtCapabilitiesFunctionTypeList = ({ setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }) => {

  // First Created - 12 Nov 2024
  // Author - Atul Pandey
  // Updated - 14 Nov 2024 - refined to show domains
  // Updated - 28 Feb 2025 - updated the function with props - { setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }
  // Updated - 28 Feb 2025 - switched from navigate to onAdd, onUpdate, onDelete, setSelectedEntityId
    
  // 1 - Set navigation
  const navigate = useNavigate()
  
  // 2a - Initialize showDetails state as an object where key is the capabilitiesFunctionType index
  const [showDetails, setShowDetails] = useState(null)
  const [showBizDetails, setShowBizDetails] = useState(null); // For business details toggle
  const [showTechDetails, setShowTechDetails] = useState(null); // For technology details toggle
  const [showBizSection, setShowBizSection] = useState(false); // Toggle business section
  const [showTechSection, setShowTechSection] = useState(false); // Toggle technology section
  const [showDomainTypeNameSection, setShowDomainTypeNameSection] = useState({}) // Toggle specific domainType Name section detail
  const {data: capabilitiesFunctionTypesData, isError, error, isLoading} = useGetCapabilitiesFunctionTypesQuery()
  // console.log('capabilitiesFunctionTypesData', capabilitiesFunctionTypesData)

  // 2b - Business Capabilities
  const businessCapabilities = capabilitiesFunctionTypesData?.ids
  ?.filter(id => capabilitiesFunctionTypesData.entities[id]?.capabilitiesDomainType.capabilitiesDomainFocus === "Business")
  ?.map(id => capabilitiesFunctionTypesData.entities[id]);
  // console.log('business capabilities', businessCapabilities)

// 2c - Technology Capabilities
const technologyCapabilities = capabilitiesFunctionTypesData?.ids
?.filter(id => capabilitiesFunctionTypesData.entities[id]?.capabilitiesDomainType.capabilitiesDomainFocus === "Technology")
?.map(id => capabilitiesFunctionTypesData.entities[id]);
// console.log('technology capabilities', technologyCapabilities)

// 2d - Group business capabilities by capabilitiesDomainTypeName
const groupedBusinessCapabilities = businessCapabilities?.reduce((acc, capability) => {
  const domainTypeName = capability.capabilitiesDomainType.capabilitiesDomainTypeName;
  const domainTypePic = capability.capabilitiesDomainType.pic;
  if (!acc[domainTypeName]) {
    acc[domainTypeName] = { domainTypePic, functions: [] };
  }
  acc[domainTypeName].functions.push(capability);
  return acc;
}, {});

// 2e - Group technology capabilities by capabilitiesDomainTypeName
const groupedTechnologyCapabilities = technologyCapabilities?.reduce((acc, capability) => {
  const domainTypeName = capability.capabilitiesDomainType.capabilitiesDomainTypeName;
  const domainTypePic = capability.capabilitiesDomainType.pic;
  if (!acc[domainTypeName]) {
    acc[domainTypeName] = { domainTypePic, functions: [] };
  }
  acc[domainTypeName].functions.push(capability);
  return acc;
}, {});

  
  // 3a - Toggle the show/hide state for the specific capabilitiesFunctionType index
  const handleToggle = (index) => {
        setShowDetails(prev => prev === index ? null : index);
  }

  // 3b - Toggle Show function for show creation notes -- 07 Oct 2024
  const handleToggleDomainTypeNameSection = (sectionId) => {
    setShowDomainTypeNameSection((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }))
}
    
  // 4 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
     }
  

  // 5 -  Optional: handle loading and error states
    if (isLoading) return <PulseLoader color={"#ea580c"} />
    if (isError) return <div>Error occurred: {error?.data?.message}</div>


  return (
    <div className='bg-gray-200 m-1 p-1 rounded mx-auto'>
        {/* Header */}
        <div className='flex justify-start items-center m-1 p-1'>

            <div><p className='font-poppins text-2xl m-1 p-1 font-bold'>Et Capabilities Function Types</p></div>
            <div>
          <img
            src={addIcon}
            alt=""
            className="h-10 w-10 cursor-pointer"
            // onClick={() => navigate("/capabilitiesFunctiontypes/create")}
            onClick={onAdd} // 28 Feb 2025
          />
        </div>

        </div>

        {/* CapabilitiesFunctionType Data */}
        <div id='capabilitiesFunctionType-data' className='bg-white rounded m-1 p-1 font-poppins'>
            {capabilitiesFunctionTypesData?.ids.length === 0 && (
                <div><p>No capabilities Function type data exists. Click above to create one</p></div>
            )}

            {/* Business Capabilities Grouped by Domain Type Name */}
              <div className='bg-gray-50 rounded m-1 p-1 border'>
                <div className='m-1 p-1 flex justify-start items-center'>
                  <div onClick={() => setShowBizSection(prev => !prev)}
                    className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                    {showBizSection ? (
                      <MinusIcon className="h-3 w-3 text-orange-600 " />
                    ) : (
                      <PlusIcon className="h-3 w-3 text-orange-600 " />
                    )}
                  </div>
                  <p className='font-bold m-1 p-1'>Business Capabilities</p>
                </div>

                {showBizSection && (
                  <div>
                    {Object.entries(groupedBusinessCapabilities)?.map(([domainTypeName, {domainTypePic, functions}], index) => (
                      <div key={domainTypeName+index} className='bg-gray-100 rounded m-1 p-1 border'>
                        {/* Domain Type Name Header */}
                        <div className='flex justify-start items-center m-1 p-1'>
                              <div onClick={() => handleToggleDomainTypeNameSection(domainTypeName)}
                                className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                                {showDomainTypeNameSection[domainTypeName] ? (
                                  <MinusIcon className="h-3 w-3 text-orange-600 " />
                                ) : (
                                  <PlusIcon className="h-3 w-3 text-orange-600 " />
                                )}
                              </div>
                              <div>
                                <p className='text-sm font-bold mx-1 px-1'>{index + 1} - </p>
                              </div>
                              {domainTypePic && (
                                <div className='flex justify-center items-center h-7 w-7 bg-gray-100 hover:bg-white rounded-full cursor-pointer mr-2'>
                                  <img src={domainTypePic} alt="" className='h-5 w-5' />
                                </div>
                              )}
                            <p className='text-sm font-bold mx-2'>{domainTypeName}</p>
                        </div>
                        
                        {showDomainTypeNameSection[domainTypeName] && (
                          <div>
                            {/* Function Names under each Domain Type */}
                            {functions.map((capability, idx) => (
                              <div key={capability._id} className='bg-gray-200 rounded m-1 p-1 border'>
                                <div className='flex justify-between items-center m-1 p-1'>
                                  <div className='flex justify-start items-center mx-2'>
                                    <div onClick={() => setShowBizDetails(prev => prev === idx ? null : idx)}
                                      className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                                      {showBizDetails === idx ? (
                                        <MinusIcon className="h-3 w-3 text-orange-600 " />
                                      ) : (
                                        <PlusIcon className="h-3 w-3 text-orange-600 " />
                                      )}
                                    </div>
                                    <p className='text-xs font-bold mx-1 px-1'>{idx + 1} - {capability.capabilitiesFunctionTypeName}</p>
                                  </div>
                                  <div className='flex justify-start items-center'>

                                          <div 
                                          // onClick={() => navigate(`/capabilitiesFunctionTypes/update/${capability._id}`)} 
                                          onClick={() => onUpdate(capability._id)} // 28 Feb 2025
                                          
                                          className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'>
                                              <PencilSquareIcon className="h-3 w-3 text-orange-600 " />
                                          </div>

                                          <div 
                                          
                                          // onClick={() => navigate(`/capabilitiesFunctiontypes/delete/${capability._id}`)} 
                                          onClick={() => onDelete(capability._id)} // 28 Feb 2025
                                          
                                          className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'>
                                              <TrashIcon className="h-3 w-3 text-orange-600 " />
                                          </div> 
                                    </div>
                                </div>

                                {/* Show Details for Each Function */}
                                {showBizDetails === idx && (
                                  <div className='bg-white rounded text-xs py-1'>
                                    <p className='font-bold m-1 p-1 border-b-2 border-orange-600'>Description:</p>
                                    <div dangerouslySetInnerHTML={createMarkup(capability.capabilitiesFunctionTypeDesc)} className='m-1 p-1'></div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                        
                      </div>
                    ))}
                  </div>
                )}
              </div>

            {/* Repeat similar structure for Technology Capabilities */}
              <div className='bg-gray-50 rounded m-1 p-1 border'>
                <div className='m-1 p-1 flex justify-start items-center'>
                  <div onClick={() => setShowTechSection(prev => !prev)}
                    className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                    {showTechSection ? (
                      <MinusIcon className="h-3 w-3 text-orange-600 " />
                    ) : (
                      <PlusIcon className="h-3 w-3 text-orange-600 " />
                    )}
                  </div>
                  <p className='font-bold m-1 p-1'>Technology Capabilities</p>
                </div>

                {showTechSection && (
                  <div>
                    {Object.entries(groupedTechnologyCapabilities)?.map(([domainTypeName, {domainTypePic, functions}], techIndex) => (
                      <div key={domainTypeName + techIndex} className='bg-gray-100 rounded m-1 p-1 border'>
                        {/* Domain Type Name Header */}
                        <div className='flex justify-start items-center m-1 p-1'>
                              <div onClick={() => handleToggleDomainTypeNameSection(domainTypeName)}
                                className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                                {showDomainTypeNameSection[domainTypeName] ? (
                                  <MinusIcon className="h-3 w-3 text-orange-600 " />
                                ) : (
                                  <PlusIcon className="h-3 w-3 text-orange-600 " />
                                )}
                              </div>
                              <div>
                                <p className='text-sm font-bold mx-1 px-1'>{techIndex + 1} - </p>
                              </div>
                              {domainTypePic && (
                                <div className='flex justify-center items-center h-7 w-7 bg-gray-100 hover:bg-white rounded-full cursor-pointer mr-2'>
                                  <img src={domainTypePic} alt="" className='h-5 w-5' />
                                </div>
                              )}
                              <p className='text-sm font-bold mx-2'>{domainTypeName}</p>
                        </div>


                        {showDomainTypeNameSection[domainTypeName] && (
                          <div>
                             {/* Function Names under each Domain Type */}
                              {functions.map((capability, idx) => (
                                <div key={capability._id} className='bg-gray-200 rounded m-1 p-1 border'>
                                  <div className='flex justify-between items-center m-1 p-1'>
                                    <div className='flex justify-start items-center mx-2'>
                                      <div onClick={() => setShowTechDetails(prev => prev === idx ? null : idx)}
                                        className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                                        {showTechDetails === idx ? (
                                          <MinusIcon className="h-3 w-3 text-orange-600 " />
                                        ) : (
                                          <PlusIcon className="h-3 w-3 text-orange-600 " />
                                        )}
                                      </div>
                                      <p className='text-xs font-bold mx-1 px-1'>{idx + 1} - {capability.capabilitiesFunctionTypeName}</p>
                                    </div>
                                    <div className='flex justify-start items-center'>

                                          <div onClick={() => navigate(`/capabilitiesFunctionTypes/update/${capability._id}`)} className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'>
                                              <PencilSquareIcon className="h-3 w-3 text-orange-600 " />
                                          </div>

                                          <div onClick={() => navigate(`/capabilitiesFunctiontypes/delete/${capability._id}`)} className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'>
                                              <TrashIcon className="h-3 w-3 text-orange-600 " />
                                          </div> 
                                    </div>
                                  </div>

                                  {/* Show Details for Each Function */}
                                  {showTechDetails === idx && (
                                    <div className='bg-white rounded text-xs py-1'>
                                      <p className='font-bold m-1 p-1 border-b-2 border-orange-600'>Description:</p>
                                      <div dangerouslySetInnerHTML={createMarkup(capability.capabilitiesFunctionTypeDesc)} className='m-1 p-1'></div>
                                    </div>
                                  )}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>

        </div>

    </div>
  )
}

export default EtCapabilitiesFunctionTypeList