import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLightWithPlace from "@/utils/EtContentEditorLightWithPlace"
import EtContentEditorLight3 from "@/utils/EtContentEditorLight3"
import { PlusIcon, MinusIcon} from '@heroicons/react/24/outline'
import { useGetOrganizationTypesQuery } from "../organizationTypes/organizationTypesApiSlice"
import { useCreateNewOrganizationMutation } from "./organizationsApiSlice"

const EtCreateOrganization = ({setActiveAdminView}) => {
   // First Created - 08 May 2024
  // Author - Atul Pandey
  // Updated - 01 Mar 2025 - updated the comp with props - { id, setActiveAdminView}
  // Updated - 01 Mar 2025 - switched from navigate to setActiveAdminView

  // 0 - Set Page Title
  useTitle('Create New Organization')
  
  // 1 - set page navigations
  const navigate = useNavigate()

  // 2 - set the mutation to create the new section data
  const[createNewOrganization, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewOrganizationMutation()

 // 3 - Get organization types data for options
  const { organizationTypes } = useGetOrganizationTypesQuery('organizationTypesList', {
    selectFromResult: ( { data }) => ({
      organizationTypes: data?.ids.map(id => data?.entities[id])
    })
  })

  console.log('organization types:', organizationTypes)

  // 4 - Create options to select a organization type from list of organization types
  const organizationTypeOptions = organizationTypes ? [
  <option key="placeholder" value="" disabled selected>
    Select an Organization Type
  </option>,
  ...organizationTypes?.map(organizationType => (
    <option key={organizationType.id} value={organizationType.id}>
      {organizationType.organizationTypeName}
    </option>
  ))
  ] : []

  // 7 - create the form data object
  const [formData, setFormData] = useState({
    organizationTypeId: "", 
    organizationName: "",
    organizationDesc: "",
    organizationPurpose: "", 
    organizationIntro: "", 
    organizationOutro: "",
    organizationTargetAudience: [{ audience: "" }],
    organizationLocations: [{ location: "" }],
    organizationTeamSize: "",
    organizationRevenueSize: "",
    organizationExistenceDuration: "", 
    organizationStartedDate: "",
    organizationRecognitions: [{ recognition: "" }],
    organizationContributions: [{ contribution: "" }],
    aboutMe: "", 
    pic: "", 
  })

  // 8 - Reset form upon successful submission
  useEffect(() => {
  if(isSuccess) {
    setFormData({
        organizationTypeId: "", 
        organizationName: "",
        organizationDesc: "",
        organizationPurpose: "", 
        organizationIntro: "", 
        organizationOutro: "",
        organizationTargetAudience: [{ audience: "" }],
        organizationLocations: [{ location: "" }],
        organizationTeamSize: "",
        organizationRevenueSize: "",
        organizationExistenceDuration: "", 
        organizationStartedDate: "",
        organizationRecognitions: [{ recognition: "" }],
        organizationContributions: [{ contribution: "" }],
        aboutMe: "", 
        pic: "", 
    })

    // navigate('/organizations')
    setActiveAdminView('organizations'); // 01 Mar 2025
  }
  
  },[isSuccess])// ✅ 01 Mar 2025 - Removed `navigate` since it's no longer used.

  // 9 - Form field updates / change handling
  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData(prevState => ({ ...prevState, pic: files[0] }))
    }  else {
      setFormData(prevState => ({ ...prevState, [name]: value }))
    }
  }

  // 9a - Generic Handle Add Field Function
  const handleAdd = (fieldName, newItem) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: [...prevState[fieldName], newItem]
    }))
  }

  // 9b - Generic Handle Remove Field Function
  const handleRemove = (fieldName, index) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: prevState[fieldName].filter((_, i) => i !== index)
    }))
  }

  // 9c - Generic Handle Field Change Function
  const handleFieldChange = (fieldName, index, value) => {
    const updatedItems = formData[fieldName].map((item, i) => i === index ? value : item)
    setFormData({ ...formData, [fieldName]: updatedItems })
  }

  // 9d - Wrapper functions for specific fields
  const handleAddAudience = () => handleAdd('organizationTargetAudience', { audience: "" })
  const handleRemoveAudience = (index) => handleRemove('organizationTargetAudience', index)
  const handleAudienceChange = (index, value) => handleFieldChange('organizationTargetAudience', index, { audience: value })

  const handleAddLocations = () => handleAdd('organizationLocations', { location: "" })
  const handleRemoveLocations = (index) => handleRemove('organizationLocations', index)
  const handleLocationsChange = (index, value) => handleFieldChange('organizationLocations', index, { location: value })
 
  const handleAddRecognitions = () => handleAdd('organizationRecognitions', { recognition: "" })
  const handleRemoveRecognitions = (index) => handleRemove('organizationRecognitions', index)
  const handleRecognitionsChange = (index, value) => handleFieldChange('organizationRecognitions', index, { recognition: value })

  // 10 - Form Submit Logic
const handleSubmit = async (e) => {
  e.preventDefault()

  // Initialize an object to collect the form data
  const organizationData = {
    trailId: formData.trailId,
    organizationTypeId: formData.organizationTypeId,
    organizationName: formData.organizationName,
    organizationDesc: formData.organizationDesc,
    organizationIntro: formData.organizationIntro,
    organizationOutro: formData.organizationOutro,
    organizationTargetAudience: formData.organizationTargetAudience,
    organizationLocations: formData.organizationLocations,
    organizationRecognitions: formData.organizationRecognitions,
    organizationStartedDate: formData.organizationStartedDate,
    aboutMe: formData.aboutMe,
  }

  //console.log('organization Name:',organizationData.organizationName)
  //console.log('organization Planned Date Slot:',organizationData.organizationStartedDate)

  if (formData.pic) {
    // Convert the image to a base64 string
    const reader = new FileReader()
    reader.onloadend = async () => {
      // Once conversion is done, add the base64 string to your data object
      organizationData.pic = reader.result

      // Now, send the data object to the server
      try {
        console.log("Form submitted.")
        await createNewOrganization(organizationData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    }
    reader.readAsDataURL(formData.pic)
  } else {
    // No image, send other data as is
    try {
      console.log("Form submitted.")
      await createNewOrganization(organizationData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
  }
}

  // 11 - Handle Cancel
  const handleCancel = () => {
    // navigate('/organizations')
    setActiveAdminView('organizations'); // 01 Mar 2025
  }

// 12 - Handle Clear
  const handleClear = () => {
    setFormData({
        organizationTypeId: "", 
        organizationName: "",
        organizationDesc: "",
        organizationPurpose: "", 
        organizationIntro: "", 
        organizationOutro: "",
        organizationTargetAudience: [{ audience: "" }],
        organizationLocations: [{ location: "" }],
        organizationTeamSize: "",
        organizationRevenueSize: "",
        organizationExistenceDuration: "", 
        organizationStartedDate: "",
        organizationRecognitions: [{ recognition: "" }],
        organizationContributions: [{ contribution: "" }],
        aboutMe: "", 
        pic: "", 
    })
  }


  return (
    <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
      {isLoading && <PulseLoader color={"#ea580c"} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">
          {error?.data?.message}
        </p>
      )}
      <div className="bg-white p-1 m-1 rounded">
        <p className="p-1 m-1 font-bold font-poppins text-xl">Add an Organization</p>

        <div className="bg-gray-200 rounded m-1 p-1">
          <form
            onSubmit={handleSubmit}
            className="m-1 p-1 text-xs font-poppins"
          >
            <div id="organizationTypeSelector">
              <select
                name="organizationTypeId"
                id="organizationTypeId"
                value={formData.organizationTypeId}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              >
                {organizationTypeOptions}
              </select>
            </div>

            <div id="organizationName">
              <input
                type="text"
                name="organizationName"
                placeholder="Enter the organization name here"
                value={formData.organizationName}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="organizationDesc">
              <input
                type="text"
                name="organizationDesc"
                placeholder="Enter the organization description here"
                value={formData.organizationDesc}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="organizationStartedDate">
              <input
                type="text"
                name="organizationStartedDate"
                placeholder="Enter the organization planned Date Slot (i.e Week 2, Month 2) here"
                value={formData.organizationStartedDate}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div className="bg-white w-full rounded p-2 m-1">
              <EtContentEditorLightWithPlace
                value={formData.organizationIntro}
                handleChange={(content) =>
                  setFormData({ ...formData, organizationIntro: content })
                }
                placeholderText={
                  "Write what people would see when this organization starts!"
                }
                // toolbarId={'organizationIntro'}
              />
            </div>

            <div className="bg-white w-full rounded p-2 m-1">
              <EtContentEditorLight3
                value={formData.organizationOutro}
                handleChange={(content) =>
                  setFormData({ ...formData, organizationOutro: content })
                }
                placeholderText={
                  "Write what people would see when this organization ends!"
                }
                toolbarId={"organizationOurtro"}
              />
            </div>

            <div id="aboutMe">
              <input
                type="text"
                name="aboutMe"
                placeholder="Add something about the personality of this organization"
                value={formData.aboutMe}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="pic">
              <input
                type="file"
                name="pic"
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            {/* Target Audience List */}
            <div id="target-audience">

                <div className="m-1 p-1 border border-white rounded">
                    <div>
                      <p className="font-bold mx-2 px-2 mt-2">Target Audience</p>
                      <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">Who this organization is for?</p>
                    </div>

                      {formData.organizationTargetAudience.map((item, index) => (
                        <div key={index} className="flex justify-start items-center mx-1">
                          <input
                            type="text"
                            value={item.audience}
                            onChange={(e) => handleAudienceChange(index, e.target.value)}
                            placeholder="Enter a target audience"
                            className="w-full rounded p-2 m-1 text-xs"
                          />
                          <div className="rounded bg-red-500 m-1 p-1">
                            <button type="button" onClick={() => handleRemoveAudience(index)}>
                              <MinusIcon className="h-5 w-5 text-white" />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-start items-center m-1">

                          <p className="font-bold text-xs m-1 p-1">Add</p>
                          <button type="button" onClick={handleAddAudience}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                          </button>
                      
                      </div>
                </div>
           
            </div>

            {/* Organization Pre Work */}
            <div id="pre-work">

                <div className="m-1 p-1 border border-white rounded">
                    <div>
                      <p className="font-bold mx-2 px-2 mt-2">Organization Locations</p>
                      <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">What are the locations for this organization?</p>
                    </div>

                      {formData.organizationLocations.map((item, index) => (
                        <div key={index} className="flex justify-start items-center mx-1">
                          <input
                            type="text"
                            value={item.location}
                            onChange={(e) => handleLocationsChange(index, e.target.value)}
                            placeholder="Enter a pre work for the organization"
                            className="w-full rounded p-2 m-1 text-xs"
                          />
                          <div className="rounded bg-red-500 m-1 p-1">
                            <button type="button" onClick={() => handleRemoveLocations(index)}>
                              <MinusIcon className="h-5 w-5 text-white" />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-start items-center m-1">

                          <p className="font-bold text-xs m-1 p-1">Add</p>
                          <button type="button" onClick={handleAddLocations}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                          </button>
                      
                      </div>
                </div>
           
            </div>
            
            {/* Organization Recognitions */}
            <div id="recognitions">

                <div className="m-1 p-1 border border-white rounded">
                    <div>
                      <p className="font-bold mx-2 px-2 mt-2">Organization Recognitions</p>
                      <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">What one can expect to achieve out of the organization?</p>
                    </div>

                      {formData.organizationRecognitions?.map((item, index) => (
                        <div key={index} className="flex justify-start items-center mx-1">
                          <input
                            type="text"
                            value={item.recognition}
                            onChange={(e) => handleRecognitionsChange(index, e.target.value)}
                            placeholder="Enter an recognition for the organization"
                            className="w-full rounded p-2 m-1 text-xs"
                          />
                          <div className="rounded bg-red-500 m-1 p-1">
                            <button type="button" onClick={() => handleRemoveRecognitions(index)}>
                              <MinusIcon className="h-5 w-5 text-white" />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-start items-center m-1">

                          <p className="font-bold text-xs m-1 p-1">Add</p>
                          <button type="button" onClick={handleAddRecognitions}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                          </button>
                      
                      </div>
                </div>
           
            </div>

            <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Create"}
                type={"create"}
                handleClick={handleSubmit}
              />
              <EtBtnFormBasic
                name={"Clear"}
                type={"clear"}
                handleClick={handleClear}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EtCreateOrganization