
    import React from 'react';
    
    const EtRunMarkIcon3SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" fill="currentColor">
          <g transform="translate(-119 -557)"><path d="M171.992 590.792C171.512 589.928 171.512 588.872 171.896 588.008 172.472 587.048 172.856 585.896 173.24 584.84 175.16 577.736 174.008 570.152 169.976 563.912L168.152 561.128C167.192 559.688 165.272 558.92 163.736 559.688 161.72 560.552 161.048 563.048 162.2 564.872L164.12 567.752C167 572.264 167.864 577.736 166.424 582.92 165.752 585.512 163.736 587.528 161.432 588.392 160.952 588.488 157.112 589.64 155.768 590.12 151.832 591.656 145.88 597.992 144.728 599.24 143.48 600.584 143.48 602.696 144.728 604.04L152.696 612.776C153.368 613.544 154.328 613.928 155.288 613.928 156.152 613.928 157.016 613.64 157.688 612.968 157.88 612.776 158.072 612.488 158.264 612.296L158.264 613.064 155.096 645.992C154.904 648.008 156.056 649.928 157.976 650.408 160.376 651.08 162.68 649.352 162.968 646.952L165.848 617.768 169.88 617.768 172.76 646.952C172.952 648.968 174.68 650.504 176.696 650.504L177.08 650.504C179.288 650.312 180.824 648.392 180.632 646.184L176.984 609.032C176.408 604.232 175.064 596.552 171.992 590.792ZM157.88 607.976 152.12 601.736C154.04 599.912 155.96 598.184 157.304 597.32L157.976 608.168C157.976 608.072 157.88 608.072 157.88 607.976Z" fillRule="evenodd"/><path d="M163.928 578.696C163.928 583.574 159.974 587.528 155.096 587.528 150.218 587.528 146.264 583.574 146.264 578.696 146.264 573.818 150.218 569.864 155.096 569.864 159.974 569.864 163.928 573.818 163.928 578.696" fillRule="evenodd"/><path d="M190.04 589.16 185.72 582.92C185.24 582.248 184.28 582.248 183.8 582.92L179.48 589.16C179 589.928 179.48 590.984 180.44 590.984L182.936 590.984 182.936 602.696C182.936 603.752 183.8 604.52 184.76 604.52 185.816 604.52 186.584 603.656 186.584 602.696L186.584 590.888 189.08 590.888C190.04 590.888 190.52 589.928 190.04 589.16Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtRunMarkIcon3SVGComp;
    