import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userPlanClientsAdapter = createEntityAdapter({})

const initialState = userPlanClientsAdapter.getInitialState()

export const userPlanClientsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserPlanClients: builder.query({
            query: () => ({
                url: '/userPlanClients/getUserPlanClients',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserPlanClients = responseData.map(userPlanClient => {
                    userPlanClient.id = userPlanClient._id
                    return userPlanClient
                })
                return userPlanClientsAdapter.setAll(initialState, loadedUserPlanClients)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserPlanClient', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserPlanClient', id}))
                    ]
                } else {
                    return [ { type: 'UserPlanClient', id: 'LIST'}]
                }
            },
        }),
        createNewUserPlanClient: builder.mutation({
            query: initialUserPlanClient => ({
                url: '/userPlanClients/createNewUserPlanClient',
                method: 'POST',
                body: {...initialUserPlanClient,}
            }),
            invalidatesTags: [
                { type: 'UserPlanClient', id: "LIST" }
            ] 
        }),
        updateUserPlanClient: builder.mutation({
            query: initialUserPlanClient => ({
                url: '/userPlanClients/updateUserPlanClient',
                method: 'PATCH',
                body: {...initialUserPlanClient,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserPlanClient', id: arg.id },
                { type: 'UserPlanClient', id: 'LIST' }
            ],
        }),
        deleteUserPlanClient: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userPlanClients/deleteUserPlanClient',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userPlanClients/deleteUserPlanClient',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserPlanClient', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserPlanClientsQuery,
    useCreateNewUserPlanClientMutation,
    useUpdateUserPlanClientMutation,
    useDeleteUserPlanClientMutation,
} = userPlanClientsApiSlice

// returns the query result object
export const selectUserPlanClientResult = userPlanClientsApiSlice.endpoints.getUserPlanClients.select()

// create memoized selector
const selectUserPlanClientsData = createSelector(
    selectUserPlanClientResult,
    userPlanClientResult => userPlanClientResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserPlanClients,
    selectById: selectUserPlanClientById,
    selectIds: selectUserPlanClientIds,
} = userPlanClientsAdapter.getSelectors(state => selectUserPlanClientsData(state) ?? initialState)