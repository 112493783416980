import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import {
  PlusIcon,
  MinusIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline"
import { ClockIcon, BuildingOffice2Icon,ArrowPathIcon, CalendarDaysIcon, Squares2X2Icon, ArrowTrendingUpIcon, InboxArrowDownIcon, UserIcon, ArrowDownCircleIcon, ArrowUpCircleIcon  } from "@heroicons/react/24/solid"

import { useGetUserMissionSelfsQuery } from '@/features/userMissions/userMissionSelfsApiSlice'
import { useGetUserMissionClientsQuery } from '@/features/userMissions/userMissionClientsApiSlice'
import { useGetUserMissionOrgsQuery } from '@/features/userMissions/userMissionOrgsApiSlice'
import { useGetUserMissionPartnersQuery } from '@/features/userMissions/userMissionPartnersApiSlice'
import { useGetUserMissionTeamsQuery } from '@/features/userMissions/userMissionTeamsApiSlice'


import EtModalCreateUserImprintMissionSelf from "@/components/common/modal/EtModalCreateUserImprintMissionSelf"
import EtModalUpdateUserImprintMissionSelf from "@/components/common/modal/EtModalUpdateUserImprintMissionSelf"
import EtModalCreateUserImprintMissionClient from "@/components/common/modal/EtModalCreateUserImprintMissionClient"
import EtModalUpdateUserImprintMissionClient from "@/components/common/modal/EtModalUpdateUserImprintMissionClient"
import EtModalCreateUserImprintMissionOrg from "@/components/common/modal/EtModalCreateUserImprintMissionOrg"
import EtModalUpdateUserImprintMissionOrg from "@/components/common/modal/EtModalUpdateUserImprintMissionOrg"
import EtModalCreateUserImprintMissionPartner from "@/components/common/modal/EtModalCreateUserImprintMissionPartner"
import EtModalUpdateUserImprintMissionPartner from "@/components/common/modal/EtModalUpdateUserImprintMissionPartner"
import EtModalCreateUserImprintMissionTeam from "@/components/common/modal/EtModalCreateUserImprintMissionTeam"
import EtModalUpdateUserImprintMissionTeam from "@/components/common/modal/EtModalUpdateUserImprintMissionTeam"

import EtImprintMissionsSelfIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintMissionsSelfIcon1SVGComp'
import EtImprintMissionsClientsIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintMissionsClientsIcon1SVGComp'
import EtImprintMissionOrgIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintMissionOrgIcon1SVGComp'
import EtImprintMissionPartnerIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintMissionPartnerIcon1SVGComp'
import EtImprintMissionTeamIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintMissionTeamIcon1SVGComp'

import EtShowcaseSelfNotesIcon1SVGComp from '@/assets/img/icons/svgComp/EtShowcaseSelfNotesIcon1SVGComp'
import EtImprintMissionEvolutionIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintMissionEvolutionIcon1SVGComp'
import EtImprintVisionNotesIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintVisionNotesIcon1SVGComp'

// 11 Jan 2025
import EtImprintMissionsIcon2SVGComp from '@/assets/img/icons/svgComp/EtImprintMissionsIcon2SVGComp'

import { createSvgComponentFromBase64 } from '@/utils/createSvgComponentFromBase64'

import {
    formatDateToMonthYear,
    formatDateToYear,
    formatDateTime,
    formatShortDate,
    calculateYearsAndMonths,
  } from "@/utils/formatDate"
  
  import { getTimeSince } from "@/utils/EtGetTimeSince"


const EtImprintMission = ({ userId, userInfo }) => {
    // First Created - 09 Jan 2025
    // Author - Atul Pandey
    // Updated - 10 Jan 2025 - added the main logic
    // Updated - 10 Jan 2025 - section details for each mission

    // 1a1 - States for Self Mission Modal -- 10 Jan 2025
    const [isCreateMissionSelfModalOpen, setIsCreateMissionSelfModalOpen] = useState(false)
    const [isUpdateMissionSelfModalOpen, setIsUpdateMissionSelfModalOpen] = useState(false)
    const [createMissionSelfTriggeringUrl, setCreateMissionSelfTriggeringUrl] = useState(null)
    const [updateMissionSelfTriggeringUrl, setUpdateMissionSelfTriggeringUrl] = useState(null)
    
    // 1a2 - States for Clients Mission Modal -- 10 Jan 2025
    const [isCreateMissionClientsModalOpen, setIsCreateMissionClientsModalOpen] = useState(false)
    const [isUpdateMissionClientsModalOpen, setIsUpdateMissionClientsModalOpen] = useState(false)
    const [createMissionClientsTriggeringUrl, setCreateMissionClientsTriggeringUrl] = useState(null)
    const [updateMissionClientsTriggeringUrl, setUpdateMissionClientsTriggeringUrl] = useState(null)
    
    // 1a3 - States for Org Mission Modal -- 10 Jan 2025
    const [isCreateMissionOrgModalOpen, setIsCreateMissionOrgModalOpen] = useState(false)
    const [isUpdateMissionOrgModalOpen, setIsUpdateMissionOrgModalOpen] = useState(false)
    const [createMissionOrgTriggeringUrl, setCreateMissionOrgTriggeringUrl] = useState(null)
    const [updateMissionOrgTriggeringUrl, setUpdateMissionOrgTriggeringUrl] = useState(null)
    
    // 1a4 - States for Team Mission Modal -- 10 Jan 2025
    const [isCreateMissionTeamModalOpen, setIsCreateMissionTeamModalOpen] = useState(false)
    const [isUpdateMissionTeamModalOpen, setIsUpdateMissionTeamModalOpen] = useState(false)
    const [createMissionTeamTriggeringUrl, setCreateMissionTeamTriggeringUrl] = useState(null)
    const [updateMissionTeamTriggeringUrl, setUpdateMissionTeamTriggeringUrl] = useState(null)
    
    // 1a5 - States for Partners Mission Modal -- 10 Jan 2025
    const [isCreateMissionPartnersModalOpen, setIsCreateMissionPartnersModalOpen] = useState(false)
    const [isUpdateMissionPartnersModalOpen, setIsUpdateMissionPartnersModalOpen] = useState(false)
    const [createMissionPartnersTriggeringUrl, setCreateMissionPartnersTriggeringUrl] = useState(null)
    const [updateMissionPartnersTriggeringUrl, setUpdateMissionPartnersTriggeringUrl] = useState(null)
    
    // 1b - State for mission tabs
    const [activeTab, setActiveTab] = useState("self")

    // 1c - State for selected vision id - 07 Jan 2025
    const [ selectedMissionId, setSelectedMissionId ] = useState(null)

    // 2a - Extract needed data from the incoming props
    const userOrgId = userInfo[0]?.userOrgId._id || null
    // console.log('userOrgId', userOrgId)

    // 2b - Get User Mission Data - 10 Jan 2025
    const { data: userMissionSelfData } = useGetUserMissionSelfsQuery()
    const { data: userMissionClientData } = useGetUserMissionClientsQuery()
    const { data: userMissionOrgData } = useGetUserMissionOrgsQuery()
    const { data: userMissionPartnerData } = useGetUserMissionPartnersQuery()
    const { data: userMissionTeamData } = useGetUserMissionTeamsQuery()

    const extractFilteredEntitiesFromAdapter = (dataAdapter) => {
        if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
        return dataAdapter.ids.map((id) => dataAdapter.entities[id])
        ?.filter(
            (item) => item.userId?._id === userId && item.userOrgId._id === userOrgId
            );
    };

    const userMissionSelfs = extractFilteredEntitiesFromAdapter(userMissionSelfData)
    const userMissionClients = extractFilteredEntitiesFromAdapter(userMissionClientData)
    const userMissionOrgs = extractFilteredEntitiesFromAdapter(userMissionOrgData)
    const userMissionPartners = extractFilteredEntitiesFromAdapter(userMissionPartnerData)
    const userMissionTeams = extractFilteredEntitiesFromAdapter(userMissionTeamData)
    console.log("userMissionSelfs", userMissionSelfs)
    console.log("userMissionClients", userMissionClients)
    console.log("userMissionOrgs", userMissionOrgs)
    console.log("userMissionPartners", userMissionPartners)
    console.log("userMissionTeams", userMissionTeams)

    // 3a1 - Handle Create User Self Mission - -- 10 Jan 2025
    const handleCreateUserMissionSelf = () => {
        const currentUrl = window.location.pathname
        setIsCreateMissionSelfModalOpen(true)
        setCreateMissionSelfTriggeringUrl(currentUrl)
    }

    const handleCloseCreateMissionSelfModal = () => {
        setIsCreateMissionSelfModalOpen(false)
        setCreateMissionSelfTriggeringUrl(null)
        // window.location.reload();
    }
    
    // 3a2 - Handle Create User Clients Mission - -- 10 Jan 2025
    const handleCreateUserMissionClients = () => {
        const currentUrl = window.location.pathname
        setIsCreateMissionClientsModalOpen(true)
        setCreateMissionClientsTriggeringUrl(currentUrl)
    }

    const handleCloseCreateMissionClientsModal = () => {
        setIsCreateMissionClientsModalOpen(false)
        setCreateMissionClientsTriggeringUrl(null)
        // window.location.reload();
    }
    
    // 3a3 - Handle Create User Org Mission - -- 10 Jan 2025
    const handleCreateUserMissionOrg = () => {
        const currentUrl = window.location.pathname
        setIsCreateMissionOrgModalOpen(true)
        setCreateMissionOrgTriggeringUrl(currentUrl)
    }

    const handleCloseCreateMissionOrgModal = () => {
        setIsCreateMissionOrgModalOpen(false)
        setCreateMissionOrgTriggeringUrl(null)
        // window.location.reload();
    }
    
    // 3a4 - Handle Create User Team Mission - -- 10 Jan 2025
    const handleCreateUserMissionTeam = () => {
        const currentUrl = window.location.pathname
        setIsCreateMissionTeamModalOpen(true)
        setCreateMissionTeamTriggeringUrl(currentUrl)
    }

    const handleCloseCreateMissionTeamModal = () => {
        setIsCreateMissionTeamModalOpen(false)
        setCreateMissionTeamTriggeringUrl(null)
        // window.location.reload();
    }

    // 3a5 - Handle Create User Partners Mission - -- 10 Jan 2025
    const handleCreateUserMissionPartners = () => {
        const currentUrl = window.location.pathname
        setIsCreateMissionPartnersModalOpen(true)
        setCreateMissionPartnersTriggeringUrl(currentUrl)
    }

    const handleCloseCreateMissionPartnersModal = () => {
        setIsCreateMissionPartnersModalOpen(false)
        setCreateMissionPartnersTriggeringUrl(null)
        // window.location.reload();
    }

    // 3b1 - Handle Update User Self Mission -- 10 Jan 2025
    const handleUpdateUserMissionSelf = (id) => {

        const currentUrl = window.location.pathname
        setSelectedMissionId(id)
        console.log('selectedMissionId', selectedMissionId)
        setIsUpdateMissionSelfModalOpen(true)
        setUpdateMissionSelfTriggeringUrl(currentUrl)
    }

    const handleCloseUpdateMissionSelfModal = () => {
        setSelectedMissionId(null)
        setIsUpdateMissionSelfModalOpen(false)
        setUpdateMissionSelfTriggeringUrl(null)
        // window.location.reload();
    }
    
    // 3b2 - Handle Update User Clients Mission -- 10 Jan 2025
    const handleUpdateUserMissionClients = (id) => {

        const currentUrl = window.location.pathname
        setSelectedMissionId(id)
        console.log('selectedMissionId', selectedMissionId)
        setIsUpdateMissionClientsModalOpen(true)
        setUpdateMissionClientsTriggeringUrl(currentUrl)
    }

    const handleCloseUpdateMissionClientsModal = () => {
        setSelectedMissionId(null)
        setIsUpdateMissionClientsModalOpen(false)
        setUpdateMissionClientsTriggeringUrl(null)
        // window.location.reload();
    }
    
    // 3b3 - Handle Update User Org Mission -- 10 Jan 2025
    const handleUpdateUserMissionOrg = (id) => {

        const currentUrl = window.location.pathname
        setSelectedMissionId(id)
        console.log('selectedMissionId', selectedMissionId)
        setIsUpdateMissionOrgModalOpen(true)
        setUpdateMissionOrgTriggeringUrl(currentUrl)
    }

    const handleCloseUpdateMissionOrgModal = () => {
        setSelectedMissionId(null)
        setIsUpdateMissionOrgModalOpen(false)
        setUpdateMissionOrgTriggeringUrl(null)
        // window.location.reload();
    }
    
    // 3b4 - Handle Update User Team Mission -- 10 Jan 2025
    const handleUpdateUserMissionTeam = (id) => {

        const currentUrl = window.location.pathname
        setSelectedMissionId(id)
        console.log('selectedMissionId', selectedMissionId)
        setIsUpdateMissionTeamModalOpen(true)
        setUpdateMissionTeamTriggeringUrl(currentUrl)
    }

    const handleCloseUpdateMissionTeamModal = () => {
        setSelectedMissionId(null)
        setIsUpdateMissionTeamModalOpen(false)
        setUpdateMissionTeamTriggeringUrl(null)
        // window.location.reload();
    }
    
    // 3b1 - Handle Update User Partners Mission -- 10 Jan 2025
    const handleUpdateUserMissionPartners = (id) => {

        const currentUrl = window.location.pathname
        setSelectedMissionId(id)
        console.log('selectedMissionId', selectedMissionId)
        setIsUpdateMissionPartnersModalOpen(true)
        setUpdateMissionPartnersTriggeringUrl(currentUrl)
    }

    const handleCloseUpdateMissionPartnersModal = () => {
        setSelectedMissionId(null)
        setIsUpdateMissionPartnersModalOpen(false)
        setUpdateMissionPartnersTriggeringUrl(null)
        // window.location.reload();
    }
  
    return (
        <div className="bg-white rounded m-1 p-1">
            <div className="flex justify-start items-center m-1 p-1 font-poppins rounded">
                <div
                    onClick={() => setActiveTab("self")}
                    className={`flex items-center text-gray-600 font-bold mx-2 cursor-pointer ${
                    activeTab === "self" ? "text-orange-600" : ""
                    }`}
                >
                    <div><EtImprintMissionsSelfIcon1SVGComp className='h-8 w-8' /></div>
                    <div>
                        <p className="mx-1 px-1 text-sm">Self</p>
                        <p className="mx-1 px-1 font-normal text-[9px] italic">what I aspire to achieve for myself?</p>
                    </div>
                </div>
                <div
                    onClick={() => setActiveTab("clients")}
                    className={`flex items-center text-gray-600 font-bold mx-2 cursor-pointer ${
                    activeTab === "clients" ? "text-orange-600" : ""
                    }`}
                >
                    <div><EtImprintMissionsClientsIcon1SVGComp className='h-8 w-8' /></div>
                    <div className=''>
                        <p className="mx-1 px-1 text-sm">Clients</p>
                        {/* <p className="mx-1 px-1 font-normal text-[9px] italic">what impact am I inspired to make for my clients?</p> */}
                        <p className="mx-1 px-1 font-normal text-[9px] italic">what I aspire to achieve for my clients?</p>
                    </div>
                </div>
                <div
                    onClick={() => setActiveTab("org")}
                    className={`flex items-center text-gray-600 font-bold mx-2 cursor-pointer ${
                    activeTab === "org" ? "text-orange-600" : ""
                    }`}
                >
                    <div><EtImprintMissionOrgIcon1SVGComp className='h-8 w-8' /></div>
                    <div>
                        <p className="mx-1 px-1 text-sm">Org</p>
                        <p className="mx-1 px-1 font-normal text-[9px] italic">what I aspire to achieve for my org?</p>
                        {/* <p className="mx-1 px-1 font-normal text-[9px] italic">what drives me?</p> */}
                    </div>
                </div>
                <div
                    onClick={() => setActiveTab("team")}
                    className={`flex items-center text-gray-600 font-bold mx-2 cursor-pointer ${
                    activeTab === "team" ? "text-orange-600" : ""
                    }`}
                >
                    <div><EtImprintMissionTeamIcon1SVGComp className='h-8 w-8' /></div>
                    <div>
                        <p className="mx-1 px-1 text-sm">Team</p>
                        {/* <p className="mx-1 px-1 font-normal text-[9px] italic">what impact am I inspired to make for my clients?</p> */}
                        <p className="mx-1 px-1 font-normal text-[9px] italic">what I aspire to achieve for my team?</p>
                    </div>
                </div>
                <div
                    onClick={() => setActiveTab("partners")}
                    className={`flex items-center text-gray-600 font-bold mx-2 cursor-pointer ${
                    activeTab === "partners" ? "text-orange-600" : ""
                    }`}
                >
                    <div><EtImprintMissionPartnerIcon1SVGComp className='h-8 w-8' /></div>
                    <div>
                        <p className="mx-1 px-1 text-sm">Partners</p>
                        {/* <p className="mx-1 px-1 font-normal text-[9px] italic">what impact am I inspired to make for my clients?</p> */}
                        <p className="mx-1 px-1 font-normal text-[9px] italic">what I aspire to achieve for my partners?</p>
                    </div>
                </div>
            </div>
            
            <div className='border-t my-2 py-2'>
                {activeTab === 'self' && (
                    <div className="m-1 p-1 rounded">
                        {userMissionSelfs?.length === 0 && (
                            <div className="flex justify-between items-center">
                                <div>
                                <p className="m-1 p-1 text-xs">
                                    You have not set a self mission yet! Change that by
                                    clicking the Add button.
                                </p>
                                </div>
                                <div className="flex justify-start items-center">
                                <p className="text-xs font-bold mx-1 px-1">Add</p>
                                <button
                                    type="button"
                                    onClick={handleCreateUserMissionSelf}
                                    className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                                >
                                    <PlusIcon className="h-3 w-3" />
                                </button>
                                </div>
                                <EtModalCreateUserImprintMissionSelf
                                    isOpen={isCreateMissionSelfModalOpen}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={createMissionSelfTriggeringUrl}
                                    onClose={handleCloseCreateMissionSelfModal}
                                />
                            </div>
                        )}
                        {userMissionSelfs?.length >0 && (
                            <div>
                                
                                <div className="flex justify-start items-center m-1 p-1">
                                    <p className="text-xs font-bold mx-1 px-1">Add a new Self Mission</p>
                                    <button
                                        type="button"
                                        onClick={handleCreateUserMissionSelf}
                                        className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                                    >
                                        <PlusIcon className="h-3 w-3" />
                                    </button>
                                </div>
                                <RenderMission 
                                    userMissions={userMissionSelfs}
                                    handleUpdate={handleUpdateUserMissionSelf}
                                    selectedMissionId={selectedMissionId}
                                    setSelectedMissionId={setSelectedMissionId}
                                    handleCloseModal={handleCloseUpdateMissionSelfModal}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={updateMissionSelfTriggeringUrl}
                                    isOpen={isUpdateMissionSelfModalOpen}
                                    ModalComponent={EtModalUpdateUserImprintMissionSelf}
                                />
                                <EtModalCreateUserImprintMissionSelf
                                    isOpen={isCreateMissionSelfModalOpen}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={createMissionSelfTriggeringUrl}
                                    onClose={handleCloseCreateMissionSelfModal}
                                />
                            </div>
                        )}
                    </div>
                )}

                {activeTab === 'clients' && (
                    <div className="m-1 p-1 rounded">
                        {userMissionClients?.length === 0 && (
                            <div className="flex justify-between items-center">
                                <div>
                                <p className="m-1 p-1 text-xs">
                                    You have not set a client mission yet! Change that by
                                    clicking the Add button.
                                </p>
                                </div>
                                <div className="flex justify-start items-center">
                                <p className="text-xs font-bold mx-1 px-1">Add</p>
                                <button
                                    type="button"
                                    onClick={handleCreateUserMissionClients}
                                    className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                                >
                                    <PlusIcon className="h-3 w-3" />
                                </button>
                                </div>
                                <EtModalCreateUserImprintMissionClient
                                    isOpen={isCreateMissionClientsModalOpen}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={createMissionClientsTriggeringUrl}
                                    onClose={handleCloseCreateMissionClientsModal}
                                />
                            </div>
                        )}
                        {userMissionClients?.length >0 && (
                            <div>
                                <div className="flex justify-start items-center m-1 p-1">
                                    <p className="text-xs font-bold mx-1 px-1">Add a new Client Mission</p>
                                    <button
                                        type="button"
                                        onClick={handleCreateUserMissionClients}
                                        className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                                    >
                                        <PlusIcon className="h-3 w-3" />
                                    </button>
                                </div>
                                
                                <RenderMission 
                                    userMissions={userMissionClients}
                                    handleUpdate={handleUpdateUserMissionClients}
                                    selectedMissionId={selectedMissionId}
                                    setSelectedMissionId={setSelectedMissionId}
                                    handleCloseModal={handleCloseUpdateMissionClientsModal}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={updateMissionClientsTriggeringUrl}
                                    isOpen={isUpdateMissionClientsModalOpen}
                                    ModalComponent={EtModalUpdateUserImprintMissionClient}
                                />
                                <EtModalCreateUserImprintMissionClient
                                    isOpen={isCreateMissionClientsModalOpen}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={createMissionClientsTriggeringUrl}
                                    onClose={handleCloseCreateMissionClientsModal}
                                />
                            </div>
                        )}
                    </div>
                )}
                
                {activeTab === 'org' && (
                    <div className="m-1 p-1 rounded">
                        {userMissionOrgs?.length === 0 && (
                            <div className="flex justify-between items-center">
                                <div>
                                <p className="m-1 p-1 text-xs">
                                    You have not set an org mission yet! Change that by
                                    clicking the Add button.
                                </p>
                                </div>
                                <div className="flex justify-start items-center">
                                <p className="text-xs font-bold mx-1 px-1">Add</p>
                                <button
                                    type="button"
                                    onClick={handleCreateUserMissionOrg}
                                    className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                                >
                                    <PlusIcon className="h-3 w-3" />
                                </button>
                                </div>
                                <EtModalCreateUserImprintMissionOrg
                                    isOpen={isCreateMissionOrgModalOpen}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={createMissionOrgTriggeringUrl}
                                    onClose={handleCloseCreateMissionOrgModal}
                                />
                            </div>
                        )}
                        {userMissionOrgs?.length >0 && (
                            <div>
                                <div className="flex justify-start items-center m-1 p-1">
                                    <p className="text-xs font-bold mx-1 px-1">Add a new Org Mission</p>
                                    <button
                                        type="button"
                                        onClick={handleCreateUserMissionOrg}
                                        className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                                    >
                                        <PlusIcon className="h-3 w-3" />
                                    </button>
                                </div>
                                <RenderMission 
                                    userMissions={userMissionOrgs}
                                    handleUpdate={handleUpdateUserMissionOrg}
                                    selectedMissionId={selectedMissionId}
                                    setSelectedMissionId={setSelectedMissionId}
                                    handleCloseModal={handleCloseUpdateMissionOrgModal}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={updateMissionOrgTriggeringUrl}
                                    isOpen={isUpdateMissionOrgModalOpen}
                                    ModalComponent={EtModalUpdateUserImprintMissionOrg}
                                />
                                <EtModalCreateUserImprintMissionOrg
                                    isOpen={isCreateMissionOrgModalOpen}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={createMissionOrgTriggeringUrl}
                                    onClose={handleCloseCreateMissionOrgModal}
                                />

                            </div>
                        )}
                    </div>
                )}
                
                {activeTab === 'team' && (
                    <div className="m-1 p-1 rounded">
                        {userMissionTeams?.length === 0 && (
                            <div className="flex justify-between items-center">
                                <div>
                                <p className="m-1 p-1 text-xs">
                                    You have not set a team mission yet! Change that by
                                    clicking the Add button.
                                </p>
                                </div>
                                <div className="flex justify-start items-center">
                                <p className="text-xs font-bold mx-1 px-1">Add</p>
                                <button
                                    type="button"
                                    onClick={handleCreateUserMissionTeam}
                                    className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                                >
                                    <PlusIcon className="h-3 w-3" />
                                </button>
                                </div>
                                <EtModalCreateUserImprintMissionTeam
                                    isOpen={isCreateMissionTeamModalOpen}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={createMissionTeamTriggeringUrl}
                                    onClose={handleCloseCreateMissionTeamModal}
                                />
                            </div>
                        )}
                        {userMissionTeams?.length >0 && (
                            <div>
                                <div className="flex justify-start items-center m-1 p-1">
                                    <p className="text-xs font-bold mx-1 px-1">Add a new Team Mission</p>
                                    <button
                                        type="button"
                                        onClick={handleCreateUserMissionTeam}
                                        className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                                    >
                                        <PlusIcon className="h-3 w-3" />
                                    </button>
                                </div>
                                <RenderMission 
                                    userMissions={userMissionTeams}
                                    handleUpdate={handleUpdateUserMissionTeam}
                                    selectedMissionId={selectedMissionId}
                                    setSelectedMissionId={setSelectedMissionId}
                                    handleCloseModal={handleCloseUpdateMissionTeamModal}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={updateMissionTeamTriggeringUrl}
                                    isOpen={isUpdateMissionTeamModalOpen}
                                    ModalComponent={EtModalUpdateUserImprintMissionTeam}
                                />
                                <EtModalCreateUserImprintMissionTeam
                                    isOpen={isCreateMissionTeamModalOpen}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={createMissionTeamTriggeringUrl}
                                    onClose={handleCloseCreateMissionTeamModal}
                                />
                            </div>
                        )}
                    </div>
                )}
                
                {activeTab === 'partners' && (
                    <div className="m-1 p-1 rounded">
                        {userMissionPartners?.length === 0 && (
                            <div className="flex justify-between items-center">
                                <div>
                                <p className="m-1 p-1 text-xs">
                                    You have not set a partner mission yet! Change that by
                                    clicking the Add button.
                                </p>
                                </div>
                                <div className="flex justify-start items-center">
                                <p className="text-xs font-bold mx-1 px-1">Add</p>
                                <button
                                    type="button"
                                    onClick={handleCreateUserMissionPartners}
                                    className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                                >
                                    <PlusIcon className="h-3 w-3" />
                                </button>
                                </div>
                                <EtModalCreateUserImprintMissionPartner
                                    isOpen={isCreateMissionPartnersModalOpen}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={createMissionPartnersTriggeringUrl}
                                    onClose={handleCloseCreateMissionPartnersModal}
                                />
                            </div>
                        )}
                        {userMissionPartners?.length >0 && (
                            <div>
                                <div className="flex justify-start items-center m-1 p-1">
                                    <p className="text-xs font-bold mx-1 px-1">Add a new Partner Mission</p>
                                    <button
                                        type="button"
                                        onClick={handleCreateUserMissionPartners}
                                        className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                                    >
                                        <PlusIcon className="h-3 w-3" />
                                    </button>
                                </div>
                                <RenderMission 
                                    userMissions={userMissionPartners}
                                    handleUpdate={handleUpdateUserMissionPartners}
                                    selectedMissionId={selectedMissionId}
                                    setSelectedMissionId={setSelectedMissionId}
                                    handleCloseModal={handleCloseUpdateMissionPartnersModal}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={updateMissionPartnersTriggeringUrl}
                                    isOpen={isUpdateMissionPartnersModalOpen}
                                    ModalComponent={EtModalUpdateUserImprintMissionPartner}
                                />
                                <EtModalCreateUserImprintMissionPartner
                                    isOpen={isCreateMissionPartnersModalOpen}
                                    userId={userId}
                                    userOrgId={userOrgId}
                                    triggeringUrl={createMissionPartnersTriggeringUrl}
                                    onClose={handleCloseCreateMissionPartnersModal}
                                />
                            </div>
                        )}
                    </div>
                )}

            </div>
        </div>
  )
}

const RenderMission = ({
    userMissions =[], 
    handleUpdate, 
    selectedMissionId, 
    setSelectedMissionId, 
    handleCloseModal, 
    userId, 
    userOrgId, 
    triggeringUrl, 
    isOpen,
    ModalComponent, 
}) => {
    // First Created - 11 Jan 2025
    // Author - Atul Pandey
    // Inherited from RenderVision and modified display for missions

    // 0 - confirm incoming data
    console.log('userMissions', userMissions)

    // 1 - Set state variables 
    
    const [ showMissionDetails, setShowMissionDetails] = useState({})
    const [ showMissionNoteSection, setShowMissionNoteSection] = useState(false)
    const [ showMissionNoteDetails, setShowMissionNoteDetails] = useState({})
    const [ showMissionEvolutionSection, setShowMissionEvolutionSection] = useState(false)
    const [ showMissionTrailDetails, setShowMissionTrailDetails] = useState({})

    // 2a - Handle Toggle for show mission note details
    const handleToggleMissionNoteDetails = (noteId) => {
        setShowMissionNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
        }))
    }

    // 2b - Handle Toggle for show mission trail details -- 08 Jan 2025
    const handleToggleMissionTrailDetails = (noteId) => {
        setShowMissionTrailDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
        }))
    }

    // 2b - Handle Toggle for show mission details -- 11 Jan 2025
    const handleToggleMissionDetails = (missionId) => {
        setShowMissionDetails((prev) => ({
        ...prev,
        [missionId]: !prev[missionId],
        }))
    }
    
    
    // 3 - create Markup
    const createMarkup = (htmlContent) => {
        return { __html: htmlContent }
    }

    return(
        <div>
           {userMissions?.length >0 && userMissions
        //    ?.slice()
        //    .sort(
        //        (a, b) =>
        //            new Date(b.missionDate) -
        //            new Date(a.missionDate)
        //    )
           ?.map((mission, missionIndex) => {
                        const missionName = mission?.missionName || ""
                        const missionDesc = mission?.missionDesc || ""
                        const missionDate = mission?.missionDate || ""
                        const missionUpdateDate = mission?.missionUpdateDate || ""
                        const uom = mission?.missionUoMType?.missionMetricUoMTypeName || ""
                        const gradation = mission?.missionGradationType?.missionMetricGradationTypeName || ""
                        const targetValue1Yr = mission?.mission1YrTargetValue || 0
                        const targetDate1Yr = mission?.mission1YrTargetDate || ""
                        const targetValue3Yr = mission?.mission3YrTargetValue || 0
                        const targetDate3Yr = mission?.mission3YrTargetDate || ""
                        const targetValue5Yr = mission?.mission5YrTargetValue || 0
                        const targetDate5Yr = mission?.mission5YrTargetDate || ""
                        const missionNotes = mission?.missionNotes || []
                        const missionTrail = mission?.missionTrail || []

                        
                        return(
                            <div key={mission._id+missionIndex} className="">
                                
                                <div className="bg-gray-200 rounded m-1 p-1">
                                    <div className="grid grid-cols-12 justify-start items-center gap-2">
                                        <div className='col-span-6 flex justify-start items-center m-1 p-1'>
                                            <div
                                                onClick={() => handleToggleMissionDetails(mission._id)}
                                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-gray-100"
                                            >
                                                {showMissionDetails[mission._id] ? (
                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                                ) : (
                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                                )}
                                            </div>
                                            <div>
                                                <EtImprintMissionsIcon2SVGComp className='h-6 w-6 text-orange-600'/>
                                            </div>
                                            <div>
                                                <p className="font-bold text-gray-600 text-lg mx-1 px-1">M {missionIndex+1}.</p>
                                            </div>
                                            <div>
                                                <div className="flex flex-col justify-center items-center mx-1 px-1">
                                                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                    {getTimeSince(missionUpdateDate)}
                                                </p>
                                                <p className="text-[9px] text-gray-600 italic">
                                                    last updated
                                                </p>
                                                </div>
                                            </div>
                                                <p className='font-bold text-sm mx-1 px-1 text-gray-600 '>{missionName}</p>
                                                <p className="text-[9px] italic">({uom})</p>
                                        </div> 
                                        
                                        <div className="col-span-3">
                                            <div className="grid grid-cols-3 justify-between items-center m-1 p-1">
                                                <div className="col-span-1 flex flex-col items-center">
                                                    <div className="border-b border-white">
                                                         <p className="m-1 p-1 text-lg font-bold">1 Yr</p>
                                                    </div>
                                                    <p className="mx-1 px-1 text-[9px] italic text-gray-600">by :</p>
                                                    <p className="m-1 px-2 text-[9px] italic rounded-full inline-block py-[1px] bg-white border border-orange-600">{targetDate1Yr?formatShortDate(targetDate1Yr) : "Today"}</p>
                                                    
                                                        <div className="aspect-square flex justify-center items-center w-10 h-10 bg-gradient-to-r from-black to-black via-gray-700 rounded">
                                                            <p className="mx-1 text-xl text-orange-600 font-bold">{targetValue1Yr}</p>
                                                        </div>
                                                        <p className="mx-1 text-[9px] italic">{gradation}</p>
                                                    
                                                    
                                                </div>
                                                <div className="col-span-1 flex flex-col items-center">
                                                    <div className="border-b border-white">
                                                         <p className="m-1 p-1 text-lg font-bold">3 Yrs</p>
                                                    </div>
                                                    <p className="mx-1 px-1 text-[9px] italic text-gray-600">by :</p>
                                                    <p className="m-1 px-2 text-[9px] italic rounded-full inline-block py-[1px] bg-white border border-orange-600">{targetDate3Yr?formatShortDate(targetDate3Yr) : "Today"}</p>
                                                    
                                                        <div className="aspect-square flex justify-center items-center w-10 h-10 bg-gradient-to-r from-black to-black via-gray-700 rounded">
                                                            <p className="mx-1 text-xl text-orange-600 font-bold">{targetValue3Yr}</p>
                                                        </div>
                                                        <p className="mx-1 text-[9px] italic">{gradation}</p>
                                                    
                                                    
                                                </div>
                                                <div className="col-span-1 flex flex-col items-center">
                                                    <div className="border-b border-white">
                                                         <p className="m-1 p-1 text-lg font-bold">5 Yrs</p>
                                                    </div>
                                                    <p className="mx-1 px-1 text-[9px] italic text-gray-600">by :</p>   
                                                    <p className="m-1 px-2 text-[9px] italic rounded-full inline-block py-[1px] bg-white border border-orange-600">{targetDate5Yr?formatShortDate(targetDate5Yr) : "Today"}</p>
                                                    
                                                        <div className="aspect-square flex justify-center items-center w-10 h-10 bg-gradient-to-r from-black to-black via-gray-700 rounded">
                                                            <p className="mx-1 text-xl text-orange-600 font-bold">{targetValue5Yr}</p>
                                                        </div>
                                                        <p className="mx-1 text-[9px] italic">{gradation}</p>  
                                                </div>
                                            </div>         
                                        </div>
                                        <div className="col-span-3 flex justify-end items-center">
                                            <ClockIcon className="h-5 w-5 text-lime-600" />
                                            <p className="text-[9px] mx-1">First Created:</p>
                                            <p className="text-gray-600 text-[9px] mx-2">{formatDateTime(missionDate)}</p>
                                            <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                            <PencilSquareIcon
                                                className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                                onClick={() => handleUpdate(mission._id)}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                    {showMissionDetails[mission._id] && (
                                        <div className="bg-white rounded m-1 p-1">
                                            <div className="grid grid-cols-1 justify-between items-center gap-2 border rounded m-1 p-1">
                                                <div className="col-span-1 flex justify-center items-center">
                                                    <p dangerouslySetInnerHTML={createMarkup(missionDesc)} className="text-gray-800 text-2xl mx-auto my-2 p-2 max-w-lg text-center"></p>
                                                </div>
                                            </div>
                                            {missionNotes?.length>0 && (
                                                <div className="bg-gray-200 rounded m-1 p-1">
                                                    <div className="grid grid-cols-8 justify-start items-center m-1 p-1">
                                                        <div className="col-span-3 flex justify-start items-center">
                                                            <div
                                                                onClick={() => setShowMissionNoteSection(prev => !prev)}
                                                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-gray-100"
                                                            >
                                                                {showMissionNoteSection ? (
                                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                                                ) : (
                                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                                                )}
                                                            </div>

                                                            <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                                <EtImprintVisionNotesIcon1SVGComp className="h-7 w-7 text-orange-600" />
                                                            </div>
                                                            <p className="mx-1 px-1 text-sm font-bold"> Mission Notes:</p>
                                                        </div>
                                                        <div className="col-span-1 flex justify-start items-center">
                                                            <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{missionNotes?.length}</p></div>
                                                        </div>
                                                    </div>
                                                    
                                                    {showMissionNoteSection && missionNotes
                                                    ?.slice()
                                                    .sort(
                                                        (a, b) =>
                                                            new Date(b.missionNoteDate) -
                                                            new Date(a.missionNoteDate)
                                                    )
                                                    .map((note, noteIndex)=>{
                                                        const noteText = note?.missionNoteText || ""
                                                        const noteDate = note?.missionNoteDate || ""
                                                        const noteUpdateDate = note?.missionNoteUpdateDate || ""

                                                        return (
                                                            <div key={note._id+noteIndex} className="bg-gray-100 border rounded m-1 p-1">
                                                                <div className="grid grid-cols-8 justify-between items-center m-1 p-1 ">
                                                                    <div className="col-span-2 flex justify-start items-center m-1 p-1">
                                                                    <div
                                                                        onClick={() =>
                                                                            handleToggleMissionNoteDetails(note._id)
                                                                        }
                                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                                    >
                                                                        {showMissionNoteDetails[note._id] ? (
                                                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                                                        ) : (
                                                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-xl font-bold mx-1 px-1 text-gray-600">
                                                                        {noteIndex + 1}.
                                                                        </p>
                                                                    </div>
                                                                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                                        <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
                                                                    </div>
                                                                    <div>
                                                                        <div className="flex flex-col justify-center items-center mx-1 px-1">
                                                                        <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                                            {getTimeSince(noteUpdateDate)}
                                                                        </p>
                                                                        <p className="text-[9px] text-gray-600 italic">
                                                                            last updated
                                                                        </p>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    <div className="col-span-4">
                                                                    <div
                                                                        dangerouslySetInnerHTML={createMarkup(noteText)}
                                                                        className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                                    ></div>
                                                                    </div>
                                                                    <div className="col-span-2 flex justify-center items-center mx-2">
                                                                    <ClockIcon className="h-5 w-5 text-lime-600" />
                                                                    <p className="text-[9px] mx-1">First Created:</p>
                                                                    <p className="text-gray-400 text-[9px] mx-2">
                                                                        {formatDateTime(noteDate)}
                                                                    </p>
                                                                    </div>
                                                                </div>
                                                                {showMissionNoteDetails[note._id] && (
                                                                <div
                                                                    dangerouslySetInnerHTML={createMarkup(noteText)}
                                                                    className="text-xs text-gray-600 m-1 p-1 rounded leading-4 bg-white"
                                                                ></div>
                                                                )}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )}

                                            {missionTrail?.length>0 && (
                                                <div className="bg-gray-200 rounded m-1 p-1">
                                                    <div className="grid grid-cols-8 justify-start items-center m-1 p-1">
                                                        <div className="col-span-3 flex justify-start items-center">
                                                            <div
                                                                onClick={() => setShowMissionEvolutionSection(prev => !prev)}
                                                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-gray-100"
                                                            >
                                                                {showMissionEvolutionSection ? (
                                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                                                ) : (
                                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                                                )}
                                                            </div>

                                                            <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                                <EtImprintMissionEvolutionIcon1SVGComp className="h-7 w-7 text-orange-600" />
                                                            </div>
                                                            <p className="mx-1 px-1 text-sm font-bold"> Mission Evolution:</p>
                                                        </div>
                                                        <div className="col-span-1 flex justify-start items-center">
                                                            <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{missionTrail?.length}</p></div>
                                                        </div>
                                                    </div>
                                                    
                                                    {showMissionEvolutionSection && missionTrail
                                                    ?.slice()
                                                    .sort(
                                                        (a, b) =>
                                                            new Date(b.missionUpdateDate) -
                                                            new Date(a.missionUpdateDate)
                                                    )
                                                    .map((item, itemIndex)=>{
                                                        const missionName = item?.missionName || ""
                                                        const missionDesc = item?.missionDesc || ""
                                                        const missionDate = item?.missionDate || ""
                                                        const missionUpdateDate = item?.missionUpdateDate || ""

                                                        return (
                                                            <div key={item._id+itemIndex} className="bg-gray-100 border rounded m-1 p-1">
                                                                <div className="grid grid-cols-8 justify-between items-center m-1 p-1 ">
                                                                    <div className="col-span-2 flex justify-start items-center m-1 p-1">
                                                                    <div
                                                                        onClick={() =>
                                                                            handleToggleMissionTrailDetails(item._id)
                                                                        }
                                                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                                    >
                                                                        {showMissionTrailDetails[item._id] ? (
                                                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                                                        ) : (
                                                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-xl font-bold mx-1 px-1 text-gray-600">
                                                                        {itemIndex + 1}.
                                                                        </p>
                                                                    </div>
                                                                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                                        <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
                                                                    </div>
                                                                    <div>
                                                                        <div className="flex flex-col justify-center items-center mx-1 px-1">
                                                                        <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                                            {getTimeSince(missionUpdateDate)}
                                                                        </p>
                                                                        <p className="text-[9px] text-gray-600 italic">
                                                                            last updated
                                                                        </p>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    <div className="col-span-4">
                                                                    <div><p className="text-sm mx-1 px-1">{missionName}</p></div>
                                                                    <div
                                                                        dangerouslySetInnerHTML={createMarkup(missionDesc)}
                                                                        className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                                    ></div>
                                                                    </div>
                                                                    <div className="col-span-2 flex justify-center items-center mx-2">
                                                                    <ClockIcon className="h-5 w-5 text-lime-600" />
                                                                    <p className="text-[9px] mx-1">First Created:</p>
                                                                    <p className="text-gray-400 text-[9px] mx-2">
                                                                        {formatDateTime(missionDate)}
                                                                    </p>
                                                                    </div>
                                                                </div>
                                                                {showMissionTrailDetails[item._id] && (
                                                                <div
                                                                    dangerouslySetInnerHTML={createMarkup(missionDesc)}
                                                                    className="text-xs text-gray-600 m-1 p-1 rounded leading-4 bg-white"
                                                                ></div>
                                                                )}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    )}

                                
                                </div>
                                
                                
                                
                                
                            </div>
                        )
            })}
            {ModalComponent && (
                <ModalComponent
                    isOpen={isOpen}
                    id={selectedMissionId}
                    userId={userId}
                    userOrgId={userOrgId}
                    triggeringUrl={triggeringUrl}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    )
}
// const RenderMission = ({
//     userMissions =[], 
//     handleUpdate, 
//     selectedMissionId, 
//     setSelectedMissionId, 
//     handleCloseModal, 
//     userId, 
//     userOrgId, 
//     triggeringUrl, 
//     isOpen,
//     ModalComponent, // 08 Jan 2025
// }) => {
//     // First Created - 07 Jan 2025
//     // Author - Atul Pandey
//     // Updated - 08 Jan 2025 - confirm incoming data
//     // Updated - 08 Jan 2025 - made the modal component as a prop

//     // 0 - confirm incoming data
//     console.log('userMissions', userMissions)

//     // 1 - Set state variables 
    
//     const [ showMissionDetails, setShowMissionDetails] = useState(false)
//     const [ showMissionNoteSection, setShowMissionNoteSection] = useState(false)
//     const [ showMissionNoteDetails, setShowMissionNoteDetails] = useState({})
//     const [ showMissionEvolutionSection, setShowMissionEvolutionSection] = useState(false)
//     const [ showMissionTrailDetails, setShowMissionTrailDetails] = useState({})

//     // 2a - Handle Toggle for show mission note details
//     const handleToggleMissionNoteDetails = (noteId) => {
//         setShowMissionNoteDetails((prev) => ({
//         ...prev,
//         [noteId]: !prev[noteId],
//         }))
//     }

//     // 2a - Handle Toggle for show mission trail details -- 08 Jan 2025
//     const handleToggleMissionTrailDetails = (noteId) => {
//         setShowMissionTrailDetails((prev) => ({
//         ...prev,
//         [noteId]: !prev[noteId],
//         }))
//     }
    
    
//     // 3 - create Markup
//     const createMarkup = (htmlContent) => {
//         return { __html: htmlContent }
//     }

//     return(
//         <div>
//            {userMissions?.length >0 && userMissions?.map((mission, missionIndex) => {
//                         const missionName = mission?.missionName || ""
//                         const missionDesc = mission?.missionDesc || ""
//                         const missionDate = mission?.missionDate || ""
//                         const missionUpdateDate = mission?.missionUpdateDate || ""
//                         const missionNotes = mission?.missionNotes || []
//                         const missionTrail = mission?.missionTrail || []
                        
//                         return(
//                             <div key={mission._id+missionIndex} className="">
                                
//                                 <div className="bg-gray-200 rounded m-1 p-1">
//                                     <div className="grid grid-cols-6 justify-start items-center">
//                                         <div className='col-span-1 flex justify-start items-center m-1 p-1'>
//                                             <div
//                                                 onClick={() => setShowMissionDetails(prev => !prev)}
//                                                 className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-gray-100"
//                                             >
//                                                 {showMissionDetails ? (
//                                                 <MinusIcon className="h-4 w-4 text-orange-600" />
//                                                 ) : (
//                                                 <PlusIcon className="h-4 w-4 text-orange-600" />
//                                                 )}
//                                             </div>
//                                             <div>
//                                                 <EtImprintMissionsSelfIcon1SVGComp className='h-5 w-5 text-orange-600'/>
//                                             </div>
//                                             <div>
//                                                 <div className='flex flex-col justify-center items-center mx-1 px-1'>
//                                                     <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">{getTimeSince(missionUpdateDate)}</p>
//                                                     <p className='text-[9px] text-gray-600 italic'>last updated</p>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="col-span-2">
//                                             <p className='font-bold text-sm mx-1 px-1 text-gray-600 '>{missionName}</p>
//                                         </div>
//                                         <div className="col-span-2 flex justify-center items-center mx-2">
//                                             <ClockIcon className="h-5 w-5 text-lime-600" />
//                                             <p className="text-[9px] mx-1">First Created:</p>
//                                             <p className="text-gray-600 text-[9px] mx-2">{formatDateTime(missionDate)}</p>
//                                         </div>
//                                         <div className="col-span-1 flex justify-end items-center">
//                                             <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
//                                             <PencilSquareIcon
//                                                 className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
//                                                 onClick={() => handleUpdate(mission._id)}
//                                             />
//                                             </div>
//                                         </div>
//                                     </div>
//                                     {showMissionDetails && (
//                                         <div className="bg-white rounded m-1 p-1">
//                                             <p dangerouslySetInnerHTML={createMarkup(missionDesc)} className="text-gray-800 text-2xl mx-auto my-2 p-2 max-w-lg text-center"></p>
//                                         </div>
//                                     )}
//                                 </div>
                                
//                                 {missionNotes?.length>0 && (
//                                     <div className="bg-gray-200 rounded m-1 p-1">
//                                         <div className="grid grid-cols-8 justify-start items-center m-1 p-1">
//                                             <div className="col-span-3 flex justify-start items-center">
//                                                 <div
//                                                     onClick={() => setShowMissionNoteSection(prev => !prev)}
//                                                     className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-gray-100"
//                                                 >
//                                                     {showMissionNoteSection ? (
//                                                     <MinusIcon className="h-4 w-4 text-orange-600" />
//                                                     ) : (
//                                                     <PlusIcon className="h-4 w-4 text-orange-600" />
//                                                     )}
//                                                 </div>

//                                                 <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
//                                                     <EtImprintVisionNotesIcon1SVGComp className="h-7 w-7 text-orange-600" />
//                                                 </div>
//                                                 <p className="mx-1 px-1 text-sm font-bold"> Mission Notes:</p>
//                                             </div>
//                                             <div className="col-span-1 flex justify-start items-center">
//                                                 <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{missionNotes?.length}</p></div>
//                                             </div>
//                                         </div>
                                        
//                                         {showMissionNoteSection && missionNotes
//                                         ?.slice()
//                                         .sort(
//                                             (a, b) =>
//                                                 new Date(b.missionNoteDate) -
//                                                 new Date(a.missionNoteDate)
//                                         )
//                                         .map((note, noteIndex)=>{
//                                             const noteText = note?.missionNoteText || ""
//                                             const noteDate = note?.missionNoteDate || ""
//                                             const noteUpdateDate = note?.missionNoteUpdateDate || ""

//                                             return (
//                                                 <div key={note._id+noteIndex} className="bg-gray-100 border rounded m-1 p-1">
//                                                     <div className="grid grid-cols-8 justify-between items-center m-1 p-1 ">
//                                                         <div className="col-span-2 flex justify-start items-center m-1 p-1">
//                                                         <div
//                                                             onClick={() =>
//                                                                 handleToggleMissionNoteDetails(note._id)
//                                                             }
//                                                             className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
//                                                         >
//                                                             {showMissionNoteDetails[note._id] ? (
//                                                             <MinusIcon className="h-4 w-4 text-orange-600" />
//                                                             ) : (
//                                                             <PlusIcon className="h-4 w-4 text-orange-600" />
//                                                             )}
//                                                         </div>
//                                                         <div>
//                                                             <p className="text-xl font-bold mx-1 px-1 text-gray-600">
//                                                             {noteIndex + 1}.
//                                                             </p>
//                                                         </div>
//                                                         <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
//                                                             <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
//                                                         </div>
//                                                         <div>
//                                                             <div className="flex flex-col justify-center items-center mx-1 px-1">
//                                                             <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
//                                                                 {getTimeSince(noteUpdateDate)}
//                                                             </p>
//                                                             <p className="text-[9px] text-gray-600 italic">
//                                                                 last updated
//                                                             </p>
//                                                             </div>
//                                                         </div>
//                                                         </div>
//                                                         <div className="col-span-4">
//                                                         <div
//                                                             dangerouslySetInnerHTML={createMarkup(noteText)}
//                                                             className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
//                                                         ></div>
//                                                         </div>
//                                                         <div className="col-span-2 flex justify-center items-center mx-2">
//                                                         <ClockIcon className="h-5 w-5 text-lime-600" />
//                                                         <p className="text-[9px] mx-1">First Created:</p>
//                                                         <p className="text-gray-400 text-[9px] mx-2">
//                                                             {formatDateTime(noteDate)}
//                                                         </p>
//                                                         </div>
//                                                     </div>
//                                                     {showMissionNoteDetails[note._id] && (
//                                                     <div
//                                                         dangerouslySetInnerHTML={createMarkup(noteText)}
//                                                         className="text-xs text-gray-600 m-1 p-1 rounded leading-4 bg-white"
//                                                     ></div>
//                                                     )}
//                                                 </div>
//                                             )
//                                         })}
//                                     </div>
//                                 )}

//                                 {missionTrail?.length>0 && (
//                                     <div className="bg-gray-200 rounded m-1 p-1">
//                                         <div className="grid grid-cols-8 justify-start items-center m-1 p-1">
//                                             <div className="col-span-3 flex justify-start items-center">
//                                                 <div
//                                                     onClick={() => setShowMissionEvolutionSection(prev => !prev)}
//                                                     className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-gray-100"
//                                                 >
//                                                     {showMissionEvolutionSection ? (
//                                                     <MinusIcon className="h-4 w-4 text-orange-600" />
//                                                     ) : (
//                                                     <PlusIcon className="h-4 w-4 text-orange-600" />
//                                                     )}
//                                                 </div>

//                                                 <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
//                                                     <EtImprintMissionEvolutionIcon1SVGComp className="h-7 w-7 text-orange-600" />
//                                                 </div>
//                                                 <p className="mx-1 px-1 text-sm font-bold"> Mission Evolution:</p>
//                                             </div>
//                                             <div className="col-span-1 flex justify-start items-center">
//                                                 <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{missionTrail?.length}</p></div>
//                                             </div>
//                                         </div>
                                        
//                                         {showMissionEvolutionSection && missionTrail
//                                         ?.slice()
//                                         .sort(
//                                             (a, b) =>
//                                                 new Date(b.missionUpdateDate) -
//                                                 new Date(a.missionUpdateDate)
//                                         )
//                                         .map((item, itemIndex)=>{
//                                             const missionName = item?.missionName || ""
//                                             const missionDesc = item?.missionDesc || ""
//                                             const missionDate = item?.missionDate || ""
//                                             const missionUpdateDate = item?.missionUpdateDate || ""

//                                             return (
//                                                 <div key={item._id+itemIndex} className="bg-gray-100 border rounded m-1 p-1">
//                                                     <div className="grid grid-cols-8 justify-between items-center m-1 p-1 ">
//                                                         <div className="col-span-2 flex justify-start items-center m-1 p-1">
//                                                         <div
//                                                             onClick={() =>
//                                                                 handleToggleMissionTrailDetails(item._id)
//                                                             }
//                                                             className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
//                                                         >
//                                                             {showMissionTrailDetails[item._id] ? (
//                                                             <MinusIcon className="h-4 w-4 text-orange-600" />
//                                                             ) : (
//                                                             <PlusIcon className="h-4 w-4 text-orange-600" />
//                                                             )}
//                                                         </div>
//                                                         <div>
//                                                             <p className="text-xl font-bold mx-1 px-1 text-gray-600">
//                                                             {itemIndex + 1}.
//                                                             </p>
//                                                         </div>
//                                                         <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
//                                                             <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
//                                                         </div>
//                                                         <div>
//                                                             <div className="flex flex-col justify-center items-center mx-1 px-1">
//                                                             <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
//                                                                 {getTimeSince(missionUpdateDate)}
//                                                             </p>
//                                                             <p className="text-[9px] text-gray-600 italic">
//                                                                 last updated
//                                                             </p>
//                                                             </div>
//                                                         </div>
//                                                         </div>
//                                                         <div className="col-span-4">
//                                                         <div><p className="text-sm mx-1 px-1">{missionName}</p></div>
//                                                         <div
//                                                             dangerouslySetInnerHTML={createMarkup(missionDesc)}
//                                                             className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
//                                                         ></div>
//                                                         </div>
//                                                         <div className="col-span-2 flex justify-center items-center mx-2">
//                                                         <ClockIcon className="h-5 w-5 text-lime-600" />
//                                                         <p className="text-[9px] mx-1">First Created:</p>
//                                                         <p className="text-gray-400 text-[9px] mx-2">
//                                                             {formatDateTime(missionDate)}
//                                                         </p>
//                                                         </div>
//                                                     </div>
//                                                     {showMissionTrailDetails[item._id] && (
//                                                     <div
//                                                         dangerouslySetInnerHTML={createMarkup(missionDesc)}
//                                                         className="text-xs text-gray-600 m-1 p-1 rounded leading-4 bg-white"
//                                                     ></div>
//                                                     )}
//                                                 </div>
//                                             )
//                                         })}
//                                     </div>
//                                 )}
                                
                                
//                             </div>
//                         )
//             })}
//             {ModalComponent && (
//                 <ModalComponent
//                     isOpen={isOpen}
//                     id={selectedMissionId}
//                     userId={userId}
//                     userOrgId={userOrgId}
//                     triggeringUrl={triggeringUrl}
//                     onClose={handleCloseModal}
//                 />
//             )}
//         </div>
//     )
// }

export default EtImprintMission