import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const inspirationBeaconsAdapter = createEntityAdapter({})

const initialState = inspirationBeaconsAdapter.getInitialState()

export const inspirationBeaconsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getInspirationBeacons: builder.query({
            query: () => ({
                url: '/inspirationBeacons/getInspirationBeacons',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedInspirationBeacons = responseData.map(inspirationBeacon => {
                    inspirationBeacon.id = inspirationBeacon._id
                    return inspirationBeacon
                })
                return inspirationBeaconsAdapter.setAll(initialState, loadedInspirationBeacons)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'InspirationBeacon', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'InspirationBeacon', id}))
                    ]
                } else {
                    return [ { type: 'InspirationBeacon', id: 'LIST'}]
                }
            },
        }),
        createNewInspirationBeacon: builder.mutation({
            query: initialInspirationBeacon => ({
                url: '/inspirationBeacons/createNewInspirationBeacon',
                method: 'POST',
                body: {...initialInspirationBeacon,}
            }),
            invalidatesTags: [
                { type: 'InspirationBeacon', id: "LIST" }
            ] 
        }),
        updateInspirationBeacon: builder.mutation({
            query: initialInspirationBeacon => ({
                url: '/inspirationBeacons/updateInspirationBeacon',
                method: 'PATCH',
                body: {...initialInspirationBeacon,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'InspirationBeacon', id: arg.id },
                { type: 'InspirationBeacon', id: 'LIST' }
            ],
        }),
        deleteInspirationBeacon: builder.mutation({
            query: ({id}) => ({
                url: '/inspirationBeacons/deleteInspirationBeacon',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'InspirationBeacon', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetInspirationBeaconsQuery,
    useCreateNewInspirationBeaconMutation,
    useUpdateInspirationBeaconMutation,
    useDeleteInspirationBeaconMutation,
} = inspirationBeaconsApiSlice

// returns the query result object
export const selectInspirationBeaconResult = inspirationBeaconsApiSlice.endpoints.getInspirationBeacons.select()

// create memoized selector
const selectInspirationBeaconsData = createSelector(
    selectInspirationBeaconResult,
    inspirationBeaconResult => inspirationBeaconResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllInspirationBeacons,
    selectById: selectInspirationBeaconById,
    selectIds: selectInspirationBeaconIds,
} = inspirationBeaconsAdapter.getSelectors(state => selectInspirationBeaconsData(state) ?? initialState)