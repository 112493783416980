import React, {useState} from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import useTitle from '../../hooks/useTitle'
import PulseLoader from "react-spinners/PulseLoader"
import { getTimeSince } from '../../utils/EtGetTimeSince' 
import EtContentEditorLightWithPlace from "../../utils/EtContentEditorLightWithPlace"
import EtContentEditorLight3 from '@/utils/EtContentEditorLight3'
import EtBtnFormBasic from "../../components/common/buttons/EtBtnFormBasic"
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon,  } from '@heroicons/react/24/outline'
import {
  RocketLaunchIcon,
  DocumentTextIcon,
  CursorArrowRippleIcon,
  Square3Stack3DIcon , ListBulletIcon, SwatchIcon, 
} from "@heroicons/react/24/solid"
import { useGetUserSessionIntroductionsQuery, useUpdateUserSessionIntroductionMutation } from './userSessionIntroductionsApiSlice'
import EtUserActionListComp from '../userActions/EtUserActionListComp'
import EtUserActionListByGroupWithTabsComp from '../userActions/EtUserActionListByGroupWithTabsComp' // 23 Sep 2024
import EtModalCreateUserAction from '../../components/common/modal/EtModalCreateUserAction'


const EtUserTrailSessionIntroductionCompProd = () => {

  // First Created - 02 Jun 2024
  // Author - Atul Pandey
  // Update 1 - 13 Jun 2024 - added the session details
  // Updated - 23 Sep 2024 - Updated Actions Display Comp - ByGroupWithTabs
  // Updated - 23 Feb 2025 - Copied and Renamed for prod deployment

  // 0 - Set page Title
  useTitle('User Session - Introduction')

  // 1 - Extrat userId, TrailId and SessionId from the page url
  const {userId, trailId, sessionId} = useParams()

  // 1a - Initialize showDetails state as an object where key is the organization index
  const [showDetails, setShowDetails] = useState(false)
  
  // 2 - Extract user introduction session data from the DB using RTK query
  const {data: userIntroductionSessionData, isLoading, isError, error} = useGetUserSessionIntroductionsQuery()
  console.log('user introduction session data:', userIntroductionSessionData)

  // 3 - Filter data for the matching user, trail and session -- 03 Jun 2024 --
  const userSessions = userIntroductionSessionData?.entities ? Object.values(userIntroductionSessionData?.entities) : [];
  const filteredUserSessionData = userSessions.find(session => 
    session.userId._id === userId && session.trailId._id === trailId && session.sessionId._id === sessionId
  );
  console.log('filtered user sessions data', filteredUserSessionData)

  // 4 - State for Modal -- 11 Jun 2024
  const [isModalOpen, setIsModalOpen] = useState(false)

  // 5 - Function to handle Add Action -- 11 Jun 2024
  const handleAddAction = () => {
      handleOpenModal()
  }

  // 5a. Modal management functions
  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => setIsModalOpen(false)

  // 6 - Create an object for setting inner HTML
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
}

   
  if (isLoading) return <PulseLoader color={"#ea580c"} />
  if (isError) return <div>Error occurred: {error?.data?.message}</div>

  return (
    <div className="bg-gray-200 p-1 xl:w-5/6 mx-auto my-2 rounded-lg shadow-md shadow-black font-poppins">
      <div className='m-1 p-1 bg-white rounded'>
        {/* Header Bar */}
        <div className='m-1 p-1 bg-gray-300 rounded'>
          <div className='flex justify-between items-center m-1 p-1'>
            {/* Left side */}
            <div className='m-1 p-1 flex justify-start items-center'>
              <div
                className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white m-1 p-1"
                onClick={() => setShowDetails((prev) => !prev)}
              >
                {showDetails ? (
                  <MinusIcon className="h-5 w-5 text-orange-600" />
                ) : (
                  <PlusIcon className="h-5 w-5 text-orange-600" />
                )}
              </div>
              <div className='flex justify-center items-center rounded-full bg-gray-200 hover:bg-white h-7 w-7 m-1 p-1'>
                <img src={filteredUserSessionData?.sessionId.pic} alt="" className='h-5 w-5' />
              </div>
              <div className='m-1 p-1'>
                <p className='text-sm font-bold mx-1'>Session {filteredUserSessionData.userSessionNumber} - {filteredUserSessionData.sessionId.sessionName}</p>
                <p className='text-[10px] m-1'>{filteredUserSessionData.sessionId.sessionDesc}</p>
              </div>
            </div>

            {/* Mid Section - Status */}
            <div className='flex justify-start items-center m-1 p-1'>
              
                {/* <div className='mx-1'><ClockIcon className="h-5 w-5 text-orange-600" /></div> */}
                <div>
                  <p className='text-xs font-bold mx-1'>Duration:</p>
                </div>
              
                <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{filteredUserSessionData?.sessionId.sessionEstimatedDuration}</p></div>
                <div><p className='text-[8px] text-orange-600 italic'>min</p></div>
            </div>

            {/* Right Side */}
            <div className='flex justify-start items-center m-1 p-1'>
              <div>
                    <RocketLaunchIcon className="h-5 w-5 text-orange-600" />
              </div>
              <div className='m-1 p-1'>
                <p className='text-xs font-bold mx-1'>Started</p>
                <p className='text-[8px] mx-1 italic text-gray-600'>{getTimeSince(filteredUserSessionData.startedAt)}</p> {/* Display duration from the start of the session */}
              </div>
            </div>

          </div>
          {/* Session Detail when the plus icon is clicked */}
          {showDetails && (
          <div className='bg-white rounded mx-2  my-1'>
          {filteredUserSessionData?.sessionId.sessionIntro && (
         <div className='p-2 grid grid-cols-3 gap-2 justify-center'>
            
             <div className='mx-2 border-r  border-orange-200 col-span-1 h-full'>
                 
                 <div className='flex flex-col justify-start'>
                     
                     <div className='flex justify-start items-center  m-1 p-1'>

                         <div className=''><Square3Stack3DIcon className='h-5 w-5 text-orange-600' /></div>
                         <p className='m-1 p-1 text-xs text-gray-400 font-bold border-b-2 border-orange-600'>Session <span className='text-orange-600'>Detail</span></p>
                     </div>

                     <p dangerouslySetInnerHTML={createMarkup(filteredUserSessionData?.sessionId.sessionIntro)} className='text-[10px] text-gray-600'></p>
                 </div>
                 
             
             </div>

             <div className='mx-2 col-span-1 border-r border-orange-200 h-full'>
                 
                 <div className='flex flex-col justify-start'>
                     
                     <div className='flex justify-start items-center  m-1 p-1'>

                         <div className=''><ListBulletIcon className='h-5 w-5 text-orange-600' /></div>
                         <p className='m-1 p-1 text-xs text-gray-400 font-bold border-b-2 border-orange-600'>Session <span className='text-orange-600'>Prework</span></p>
                     </div>

                     {filteredUserSessionData?.sessionId.sessionPreWork && filteredUserSessionData?.sessionId.sessionPreWork?.map((item,preWorkIndex) => (
                     <div key={preWorkIndex} className='rounded m-1 p-1 bg-gray-100'>
                         <p className='text-[10px] mx-1'><span className='font-bold'></span>{item.prework}</p>
                     </div>
                     ))}

                 </div>


             </div>

             <div className='mx-2 col-span-1'>
                 <div className='flex flex-col justify-start'>

                     <div className='flex justify-start items-center m-1 p-1'>

                     <div className=''><SwatchIcon className='h-5 w-5 text-orange-600' /></div>
                         <p className='m-1 p-1 text-xs text-gray-400 font-bold border-b-2 border-orange-600'>Session <span className='text-orange-600'>Outcomes</span></p>

                     </div>

                     {filteredUserSessionData?.sessionId.sessionOutcomes && filteredUserSessionData?.sessionId.sessionOutcomes?.map((item,outIndex) => (
                     <div key={outIndex} className='rounded m-1 p-1 bg-gray-100'>
                         <p className='text-[10px] mx-1'>{item.outcome}</p>
                     </div>
                     ))}

                 </div>

            
            
             </div>
             
         </div>)}
         
     
     </div>
          )}
        </div> {/* End of Header Bar */}

        {/* Coach's inputs - TBD */}

        {/* Input Form  */}
        <EtUpdateUserSessionIntroForm userId={userId} trailId={trailId} sessionId={sessionId} filteredUserSessionData={filteredUserSessionData} handleAddAction={handleAddAction} />

      </div>
      <EtModalCreateUserAction isOpen={isModalOpen} onClose={handleCloseModal} userId={userId} trailId={trailId} sessionId={sessionId}/>
    </div>
  )
}

const EtUpdateUserSessionIntroForm = ({userId, trailId, sessionId, filteredUserSessionData, handleAddAction}) => {

  // First Created - 04 Jun 2024
  // Author - Atul Pandey
  // Updated - 11 Jun 2024 - handleAddAction Modal

  // 0 - Define and declare Navigation and state variables
  const navigate = useNavigate()
  const [introductionSessionNotes, setIntroductionSessionNotes] = useState(filteredUserSessionData?.introductionSessionNotes)
  const [introductionSessionPrivateNotes, setIntroductionSessionPrivateNotes] = useState(filteredUserSessionData?.introductionSessionPrivateNotes || '') // 27 Jun 2024
  
  // 1 - Define the RTK mutation to update backend data
  const [updateUserSessionIntroduction] = useUpdateUserSessionIntroductionMutation()

  // 2 - Form submit
  const handleSave = async () => {
    try {
      await updateUserSessionIntroduction({ userId, trailId, sessionId, introductionSessionNotes,introductionSessionPrivateNotes }).unwrap()
      // You can add a success message or any other logic here after a successful update
      // navigate('/userMagicHome')
      navigate('/etapp') // 23 Feb 2025
    } catch (error) {
      console.error('Failed to update the user session introduction:', error)
      // You can add an error message or any other logic here in case of a failure
    }
  }

  // 3 - Handle Clear
  const handleClear = () => {
    setIntroductionSessionNotes(filteredUserSessionData?.introductionSessionNotes)
  }

  // 4 - Handle Cancel
  const handleCancel = () => {
    // navigate('/userMagicHome')
    navigate('/etapp') // 23 Feb 2025
  }

  // 5 - Create an object for setting inner HTML
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
  }

  // // 6 - Add Action Function -- 07 Jun 2024
  // const handleAddAction = () => {

  // }

  return (
    <div className="bg-gray-300 p-1 m-1 rounded font-poppins">
      {/* Session Notes Header */}
      <div className='m-1 p-1 flex justify-start items-center'>
        <div className='flex justify-center items-center cursor-pointer rounded-full h-8 w-8 bg-gray-200 hover:bg-white m-1 p-1'>
          <DocumentTextIcon className="h-5 w-5 text-orange-500 hover:text-orange-600" />
        </div>
        <div className='m-1 p-1'>
          <p className='font-bold mx-1'>Session Notes:</p>
          <p className='text-[10px] italic text-gray-600 mx-1'>Capture your session experiences, thoughts and observation. </p>
        </div>
      </div>
      {/* Session Notes Editor */}
      <div className="bg-gray-50 p-1 m-1 rounded">
        <EtContentEditorLightWithPlace
          value={introductionSessionNotes}
          handleChange={(content) => setIntroductionSessionNotes(content)}
          placeholderText={introductionSessionNotes}
        /> 
      </div>
      {/* 27 Jun 2024 - Private Note */}
      {/* <div className="bg-gray-50 p-1 m-1 rounded">
        <p className='text-sm m-1 p-1 font-bold text-gray-400'>Private Notes</p>
        <EtContentEditorLight3
            value={introductionSessionPrivateNotes}
            handleChange={(content) =>
              setIntroductionSessionPrivateNotes(content)
            }
            placeholderText={
              "Only for your eyes..."
            }
            toolbarId={"privateNote"}
        />
      </div> */}

      {/* Session Actions Section */}
      <div className='m-1 p-1 border border-white rounded'>
        <div className='m-1 p-1'>
          <div className='flex justify-between items-center'>
            <div className='flex justify-start items-center m-1 p-1'>
                <div className='rounded-full flex justify-center items-center h-7 w-7 bg-gray-200 hover:bg-white'>
                  <CursorArrowRippleIcon className="h-5 w-5 text-orange-600" />
                  </div>
                <div>
                    <p className="font-bold mx-1 px-1">Actions</p>
                    <p className="text-[8px] mx-1 px-1 italic text-gray-600">What would you like to do next?</p>
                </div>
            </div>
          </div> 
        <div>
         {/* Component to display the trail session actions for the user */}
         {/* <EtUserActionListComp userId={userId} trailId={trailId} sessionId={sessionId}/> */}
         <EtUserActionListByGroupWithTabsComp userId={userId} trailId={trailId} sessionId={sessionId}/>
        </div>

        <div className="flex justify-start items-center m-1 p-1">
                <p className="font-bold text-xs m-1 p-1">Add</p>
                <button type="button" onClick={handleAddAction}>
                  <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600" />
                </button>
            </div>
        </div>
      </div>

      {/* Session Action Bar */}
      <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Save"}
                type={"create"}
                handleClick={handleSave}
              />
              <EtBtnFormBasic
                name={"Clear"}
                type={"clear"}
                handleClick={handleClear}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>
    </div>
  )

}

export default EtUserTrailSessionIntroductionCompProd