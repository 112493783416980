export const ROLES = {
    Guest: 'Guest',
    Coach: 'Coach',
    Admin: 'Admin',
    EtUser:'EtUser',
    EtCoach:'EtCoach', 
    EtAdmin:'EtAdmin', 
    OrgUser:'OrgUser', 
    OrgCoach:'OrgCoach', 
    OrgSponsor:'OrgSponsor', 
    OrgAdmin:'OrgAdmin',
}