import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userContributionsAdapter = createEntityAdapter({})

const initialState = userContributionsAdapter.getInitialState()

export const userContributionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserContributions: builder.query({
            query: () => ({
                url: '/userContributions/getUserContributions',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserContributions = responseData.map(userContribution => {
                    userContribution.id = userContribution._id
                    return userContribution
                })
                return userContributionsAdapter.setAll(initialState, loadedUserContributions)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserContribution', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserContribution', id}))
                    ]
                } else {
                    return [ { type: 'UserContribution', id: 'LIST'}]
                }
            },
        }),
        createNewUserContribution: builder.mutation({
            query: initialUserContribution => ({
                url: '/userContributions/createNewUserContribution',
                method: 'POST',
                body: {...initialUserContribution,}
            }),
            invalidatesTags: [
                { type: 'UserContribution', id: "LIST" }
            ] 
        }),
        updateUserContribution: builder.mutation({
            query: initialUserContribution => ({
                url: '/userContributions/updateUserContribution',
                method: 'PATCH',
                body: {...initialUserContribution,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserContribution', id: arg.id },
                { type: 'UserContribution', id: 'LIST' }
            ],
        }),
        deleteUserContribution: builder.mutation({
            query: ({id}) => ({
                url: '/userContributions/deleteUserContribution',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserContribution', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserContributionsQuery,
    useCreateNewUserContributionMutation,
    useUpdateUserContributionMutation,
    useDeleteUserContributionMutation,
} = userContributionsApiSlice

// returns the query result object
export const selectUserContributionResult = userContributionsApiSlice.endpoints.getUserContributions.select()

// create memoized selector
const selectUserContributionsData = createSelector(
    selectUserContributionResult,
    userContributionResult => userContributionResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserContributions,
    selectById: selectUserContributionById,
    selectIds: selectUserContributionIds,
} = userContributionsAdapter.getSelectors(state => selectUserContributionsData(state) ?? initialState)