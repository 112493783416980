import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight from "@/utils/EtContentEditorLight"
import { PlusIcon, MinusIcon} from '@heroicons/react/24/outline'
import { useCreateNewSubsPackMutation } from "./subscriptionPacksApiSlice"
import { useGetTrailsQuery } from "../trails/trailsApiSlice"


const EtCreateSubsPack = ({setActiveAdminView}) => {

  // First Created - 04 Apr 2024
  // Author - Atul Pandey
  // Update 1 - 14 May 2024 
  // Updated - 01 Mar 2025 - updated the softSkillFunctionArea with props - { id, setActiveAdminView}
  // Updated - 01 Mar 2025 - switched from navigate to setActiveAdminView

    // 0 - Use Title
    useTitle('Create - Subscription Pack')

    // 1 - Create new Subscription pack mutation
    const [createNewSubsPack, {
        isLoading,
        isSuccess,
        isError,
        error,
    }] = useCreateNewSubsPackMutation()

    // 2 - create navigation hook
    const navigate = useNavigate()

    // 2a - State to track selected trail IDs
  const [selectedTrailIds, setSelectedTrailIds] = useState([]);

    // 3 - Create the form data variable to enter data

    const [formData, setFormData] = useState({
        subscriptionPackName: "",
        subscriptionPackDesc: "",
        subscriptionPackTrails:[{ packTrailId: "" }],
        subscriptionPackFeatures:[{ featureDesc: "", pic: ""}],
        aboutMe:"",
        pic: null,
      })
    
      useEffect(() => {
        if (isSuccess) {
          setFormData({
            subscriptionPackName: "",
            subscriptionPackDesc: "",
            subscriptionPackTrails:[{ packTrailId: "" }],
            subscriptionPackFeatures:[{ featureDesc: "", pic: ""}],
            aboutMe:"",
            pic: null,
          })
    
         // navigate('/subscriptionPacks')
         setActiveAdminView('subsPacks'); // 01 Mar 2025
        }
        
        },[isSuccess])// ✅ 01 Mar 2025 - Removed `navigate` since it's no longer used.

// 3a - Get trails data for options
const { trails } = useGetTrailsQuery('trailsList', {
  selectFromResult: ( { data }) => ({
    trails: data?.ids.map(id => data?.entities[id])
  })
})

console.log('trails:', trails)

// 3b - Create options to select a trail from list of trails

const trailOptions = trails ? [
  <option key="placeholder" value="" disabled>
    Add a trail
  </option>,
  ...trails?.map(trail => (
    <option key={trail._id} value={trail._id} disabled={selectedTrailIds.includes(trail._id)}> 
      {trail.trailName}
    </option>
  ))
  ] : []
    
// 4 - Form field updates / change handling

const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }))
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }))
    }
  }

  // 5 - handle the content created inside the rich editor
  const handleContentChange = (newContent) => {
    setFormData(prevFormData => ({ ...prevFormData, subscriptionPackDesc: newContent }));
}

// 6a - Generic Handle Add Field Function
const handleAdd = (fieldName, newItem) => {
  setFormData(prevState => ({
    ...prevState,
    [fieldName]: [...prevState[fieldName], newItem]
  }))
}

// 6b - Generic Handle Remove Field Function
const handleRemove = (fieldName, index) => {
  setFormData(prevState => ({
    ...prevState,
    [fieldName]: prevState[fieldName].filter((_, i) => i !== index)
  }))
}

// 6c - Generic Handle Field Change Function
const handleFieldChange = (fieldName, index, value) => {
  const updatedItems = formData[fieldName].map((item, i) => i === index ? value : item)
  setFormData({ ...formData, [fieldName]: updatedItems })
}

// 6d - Wrapper functions for specific fields
const handleAddPackTrail = () => {
  
  if (selectedTrailIds?.length < trails?.length) { // Check if all trails are already selected
    handleAdd('subscriptionPackTrails', { packTrailId: "" });
  }
}
const handleRemovePackTrail = (index) => {
  handleRemove('subscriptionPackTrails', index);
  const removedTrailId = formData.subscriptionPackTrails[index].packTrailId;
  setSelectedTrailIds(prevIds => prevIds.filter(id => id !== removedTrailId)); // Update selected IDs
};

const handlePackTrailChange = (index, value) => {
  if (!selectedTrailIds.includes(value)) { // Check for duplicate
    handleFieldChange('subscriptionPackTrails', index, { packTrailId: value });
    setSelectedTrailIds(prevIds => [...prevIds, value]); // Update selected IDs
  }
};

//const handlePackTrailChange = (index, value) => handleFieldChange('subscriptionPackTrails', index, { packTrailId: value })

// 6e - show add button
const showAddButton =
  trails?.length !== formData.subscriptionPackTrails?.length;

// 7 - Form Submit logic:
const handleSubmit = async (e) => {
    e.preventDefault()

    // Initialize an object to collect the form data
    const subscriptionPackData = {
      subscriptionPackName: formData.subscriptionPackName,
      subscriptionPackDesc: formData.subscriptionPackDesc,
      subscriptionPackTrails: formData.subscriptionPackTrails,
      subscriptionPackFeatures:formData.subscriptionPackFeatures,
      aboutMe:formData.aboutMe,

    }

    if (formData.pic) {
      // Convert the image to a base64 string
      const reader = new FileReader()
      reader.onloadend = async () => {
        // Once conversion is done, add the base64 string to your data object
        subscriptionPackData.pic = reader.result
        console.log('Sub Pack Create Data to server:', subscriptionPackData)

        // Now, send the data object to the server
        try {
          console.log("Form submitted.")
          await createNewSubsPack(subscriptionPackData).unwrap() // Dispatch the RTK Query mutation
        } catch (error) {
          console.error(error)
        }
      }
      reader.readAsDataURL(formData.pic)
    } else {
      // No image, send other data as is
      try {
        console.log("Form submitted.")
        await createNewSubsPack(subscriptionPackData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    }
  }

  //
  const handleCancel = () => {
    // navigate('/subscriptionPacks')
    setActiveAdminView('subsPacks'); // 01 Mar 2025
  }
  
  //
  const handleClear = () => {
    setFormData({
        subscriptionPackName: "",
        subscriptionPackDesc: "",
        subscriptionPackTrails: formData.subscriptionPackTrails,
        subscriptionPackFeatures:formData.subscriptionPackFeatures,
        aboutMe:formData.aboutMe,
        pic: null,
      })
}

return (
    <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
      {isLoading && <PulseLoader color={"#ea580c"} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">
          {error?.data?.message}
        </p>
      )}
      <div className="bg-white p-1 m-1 rounded">
        <p className="p-1 m-1 font-bold font-poppins text-xl">
          Add a Subscription Pack
        </p>

        <div className="bg-gray-200 rounded m-1 p-1">
          <form
            onSubmit={handleSubmit}
            className="m-1 p-1 text-sm font-poppins"
          >
            <div id="subscriptionPackName">
              <input
                type="text"
                name="subscriptionPackName"
                placeholder="Enter a Subscription Pack Name"
                value={formData.subscriptionPackName}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div className="h-32 bg-white rounded m-1 p-2 w-full">
            <EtContentEditorLight
                value={formData.subscriptionPackDesc}
                handleChange={handleContentChange}
              />
            </div>

          
            <div id="pic">
              <input
                type="file"
                name="pic"
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>
            
            {/* Trail List */}
            <div id="target-audience">

                <div className="m-1 p-1 border border-white rounded">
                    <div>
                      <p className="font-bold mx-2 px-2 mt-2">Pack Trails</p>
                      <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">What trails are in this pack?</p>
                    </div>
                    
                    {formData.subscriptionPackTrails?.map((trail, index) => (
                        <div key={index} className="flex justify-between items-center m-1">
                          <select
                            value={trail?.packTrailId}
                            onChange={(e) => handlePackTrailChange(index, e.target.value)}
                            className="rounded p-2 m-1 flex-grow"
                          >
                            {trailOptions}
                          </select>
                          <button type="button" onClick={() => handleRemovePackTrail(index)} className="bg-red-500 text-white rounded p-1">
                            <MinusIcon className="h-5 w-5" />
                          </button>
                        </div>
                      ))}
                      {showAddButton && (
                      <div className="flex justify-start items-center m-1 p-1">
                          <p className="text-xs font-bold mx-2">Add</p>
                          <button type="button" onClick={() => handleAddPackTrail()} className="m-1 p-1 bg-green-500 text-white rounded">
                            <PlusIcon className="h-5 w-5" />
                          </button>
                      </div>
                      )}
        
                </div>
           
            </div>




            <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Create"}
                type={"create"}
                handleClick={handleSubmit}
              />
              <EtBtnFormBasic
                name={"Clear"}
                type={"clear"}
                handleClick={handleClear}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EtCreateSubsPack

// Update 1 - 14 May 2024
// ----------------------
// Added trails, features and about me data and functions to add / remove trails