import React, { useState, useEffect, useMemo } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLightWithPlace from "@/utils/EtContentEditorLightWithPlace"
import { useGetCapabilitiesTechStackTypesQuery, useUpdateCapabilitiesTechStackTypeMutation } from "./capabilitiesTechStackTypesApiSlice"
import { useGetCapabilitiesDomainTypesQuery } from '../capabilitiesDomainTypes/capabilitiesDomainTypesApiSlice' // 14 Nov 2024
import { useGetCapabilitiesFunctionTypesQuery } from '../capabilitiesFunctionTypes/capabilitiesFunctionTypesApiSlice' // 15 Nov 2024
import { useGetCapabilitiesTechAreaTypesQuery } from '../capabilitiesTechAreaTypes/capabilitiesTechAreaTypesApiSlice' // 15 Nov 2024

const EtUpdateCapabilitiesTechStackType = ({id, setActiveAdminView}) => {
  // Created - 15 Nov 2024
  // Author - Atul Pandey
  // Inherited from capabilitiesTechAreaType
  // Updated - 28 Feb 2025 - updated the softSkillFunctionArea with props - { id, setActiveAdminView}
  // Updated - 28 Feb 2025 - switched from navigate to setActiveAdminView

  // 1 - Set Title
  useTitle("Update CapabilitiesTechStack Type")

  // 2 - extract the capabilitiesTechStackType Id from the url
  // const { id } = useParams()
  //console.log("id:", id)

  // 3 - Extract the capabilitiesTechStackType from the query to load the capabilitiesTechStackType data
  const { capabilitiesTechStackType } = useGetCapabilitiesTechStackTypesQuery("CapabilitiesTechStackType", {
    selectFromResult: ({ data }) => ({
      capabilitiesTechStackType: data?.entities[id],
    }),
  })

  console.log('capabilitiesTechStack type:', capabilitiesTechStackType)

  // 4 - Check for returned data
  if (!capabilitiesTechStackType) {
    return <PulseLoader color={"#ea580c"} />
  }

  // 5. Display data
  let content
  content = <EtUpdateCapabilitiesTechStackTypeForm capabilitiesTechStackType={capabilitiesTechStackType} id={id} setActiveAdminView={setActiveAdminView}/> // 28 Feb 2025 - added setActiveAdminView={setActiveAdminView}
  return content
}

const EtUpdateCapabilitiesTechStackTypeForm = ({ capabilitiesTechStackType, id, setActiveAdminView}) =>{
  // 1a - set the variable for navigate
  const navigate = useNavigate()
  //console.log('update form invoked!')
  console.log('capabilitiesTechStackType', capabilitiesTechStackType)

  // 1b - state variables for domain types
  const [selectedFocus, setSelectedFocus] = useState("");
  const [filteredDomainTypes, setFilteredDomainTypes] = useState([]);
  const [filteredFunctionTypes, setFilteredFunctionTypes] = useState([]); // 15 Nov 2024
  const [filteredTechAreaTypes, setFilteredTechAreaTypes] = useState([]); // 15 Nov 2024

  
  // 2a - set the mutation to create the new section data
  const[updateCapabilitiesTechStackType, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useUpdateCapabilitiesTechStackTypeMutation()

  // 2b - Get DomainTypes Data
  const { data: domainTypesData } = useGetCapabilitiesDomainTypesQuery();
  const domainTypes = useMemo(() => {
    return domainTypesData?.ids?.map(id => domainTypesData?.entities[id]) || [];
  }, [domainTypesData]);
  // console.log('domainTypes', domainTypes)

  // 2c - Set initial values for selectedFocus and filteredDomainTypes when component mounts
  useEffect(() => {
    if (capabilitiesTechStackType?.capabilitiesTechAreaType?.capabilitiesFunctionType?.capabilitiesDomainType) {
      const initialFocus = capabilitiesTechStackType?.capabilitiesTechAreaType?.capabilitiesFunctionType?.capabilitiesDomainType?.capabilitiesDomainFocus;
      setSelectedFocus(initialFocus);
      
      // Set filtered domain types based on the initial focus
      setFilteredDomainTypes(
        domainTypes.filter((domain) => domain?.capabilitiesDomainFocus === initialFocus)
      );

      // Set the initial value for capabilitiesDomainType in formData
      setFormData((prevData) => ({
        ...prevData,
        capabilitiesDomainType: capabilitiesTechStackType?.capabilitiesTechAreaType?.capabilitiesFunctionType?.capabilitiesDomainType._id,
      }));
    }
  }, [capabilitiesTechStackType, domainTypes]);

  // 2d - Get FunctionTypes Data
  const { data: functionTypesData } = useGetCapabilitiesFunctionTypesQuery();
  const functionTypes = useMemo(() => {
    return functionTypesData?.ids?.map(id => functionTypesData?.entities[id]) || [];
  }, [functionTypesData]);
  // console.log('functionTypes', functionTypes)

  // 2e - Fetch TechAreaTypes Data
  const { data: techAreaTypesData } = useGetCapabilitiesTechAreaTypesQuery();
  const techAreaTypes = useMemo(() => {
    return techAreaTypesData?.ids?.map(id => techAreaTypesData?.entities[id]) || [];
  }, [techAreaTypesData]);


// 3a - set the form data object
const [formData, setFormData] = useState({
  capabilitiesTechStackTypeName: capabilitiesTechStackType.capabilitiesTechStackTypeName,
  capabilitiesTechStackTypeDesc: capabilitiesTechStackType.capabilitiesTechStackTypeDesc,
  capabilitiesDomainType: capabilitiesTechStackType?.capabilitiesTechAreaType?.capabilitiesFunctionType?.capabilitiesDomainType?._id || "",
  capabilitiesFunctionType: capabilitiesTechStackType?.capabilitiesTechAreaType?.capabilitiesFunctionType?._id || "",
  capabilitiesTechAreaType: capabilitiesTechStackType?.capabilitiesTechAreaType?._id || "",
  aboutMe: capabilitiesTechStackType.aboutMe || "",
});

//console.log('formData for update:', formData)

// 3b - Populate the filteredFunctionTypes based on selected capabilitiesDomainType
useEffect(() => {
  if (formData.capabilitiesDomainType) {
    setFilteredFunctionTypes(
      functionTypes.filter((func) => func.capabilitiesDomainType._id === formData.capabilitiesDomainType)
    );
  } else {
    setFilteredFunctionTypes([]); // Reset if no domain type is selected
  }
}, [formData.capabilitiesDomainType, functionTypes]);

// 3c - Populate the filteredTechAreaTypes based on selected capabilitiesFunctionType
useEffect(() => {
  if (formData.capabilitiesFunctionType) {
    setFilteredTechAreaTypes(
      techAreaTypes.filter(
        (techArea) => techArea.capabilitiesFunctionType._id === formData.capabilitiesFunctionType
      )
    );
  } else {
    setFilteredTechAreaTypes([]); // Reset if no function type is selected
  }
}, [formData.capabilitiesFunctionType, techAreaTypes]);

// 4 - Reset form upon successful submission
useEffect(() => {
  if(isSuccess) {
    setFormData({
      capabilitiesTechStackTypeName: "",
      capabilitiesTechStackTypeDesc: "",
      capabilitiesDomainType: "",
      capabilitiesFunctionType: "",
      capabilitiesTechAreaType:"",
      aboutMe: "",
      pic: "",
    })

  // navigate('/capabilitiesTechStacktypes')
  setActiveAdminView('capabilitiesTechStacktypes'); // 28 Feb 2025
}

},[isSuccess])// ✅ 28 Feb 2025 - Removed `navigate` since it's no longer used.

// 5 - Form field updates / change handling

const handleChange = (e) => {
  const { name, value, files } = e.target
  if (name === "pic") {
    setFormData(prevState => ({ ...prevState, pic: files[0] }))
  } else {
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }
}

// 6 - techStack to strip html tags from sections
const stripHtml = (html) => {
  return html.replace(/<\/?[^>]+(>|$)/g, "");
}

// 7 - Form Submit Logic
const handleSubmit = async (e) => {
e.preventDefault()

// Initialize an object to collect the form data
const capabilitiesTechStackTypeData = {
  capabilitiesTechStackTypeName: formData.capabilitiesTechStackTypeName,
  capabilitiesTechStackTypeDesc: formData.capabilitiesTechStackTypeDesc,
  capabilitiesTechAreaType: formData.capabilitiesTechAreaType || null, // New field for tech area type selection
  aboutMe: formData.aboutMe,
}

//console.log('capabilitiesTechStackType Name:',capabilitiesTechStackTypeData.capabilitiesTechStackTypeName)

if (formData.pic) {
  // Convert the image to a base64 string
  const reader = new FileReader()
  reader.onloadend = async () => {
    // Once conversion is done, add the base64 string to your data object
    capabilitiesTechStackTypeData.pic = reader.result

    // Now, send the data object to the server
    try {
      capabilitiesTechStackTypeData.id = id
      console.log("Form submitted.")
      await updateCapabilitiesTechStackType(capabilitiesTechStackTypeData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
  }
  reader.readAsDataURL(formData.pic)
} else {
  // No image, send other data as is
  try {
    capabilitiesTechStackTypeData.id = id
    console.log("Form submitted.")
    await updateCapabilitiesTechStackType(capabilitiesTechStackTypeData).unwrap() // Dispatch the RTK Query mutation
  } catch (error) {
    console.error(error)
  }
}
}

// 8 - Handle Cancel

const handleCancel = () => {
  // navigate('/capabilitiesTechStacktypes')
  setActiveAdminView('capabilitiesTechStacktypes'); // 28 Feb 2025
}

// 9 - Handle Clear
const handleClear = () => {
  setFormData({
    capabilitiesTechStackTypeName: "",
    capabilitiesTechStackTypeDesc: "",
    capabilitiesDomainType: "", // New field for domain type selection
    capabilitiesFunctionType: "",
    capabilitiesTechAreaType:"",
    aboutMe: "",
    pic: "",
    })
}

return (
  <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
    {isLoading && <PulseLoader color={"#ea580c"} />}
    {isError && (
      <p className="text-orange-700 font-semi-bold py-2">
        {error?.data?.message}
      </p>
    )}
    <div className="bg-white p-1 m-1 rounded">
      <p className="p-1 m-1 font-bold font-poppins text-xl">Update a Capabilities TechStack Type</p>

      <div className="bg-gray-200 rounded m-1 p-1">
        <form
          onSubmit={handleSubmit}
          className="m-1 p-1 text-xs font-poppins"
        >

          <div id="focus">
            <select
              name="focus"
              value={selectedFocus}
              onChange={(e) => {
                const newFocus = e.target.value;
                setSelectedFocus(newFocus);
                setFilteredDomainTypes(
                  domainTypes?.filter((domain) => domain?.capabilitiesDomainFocus === newFocus)
                );
                // Reset the selected domain type if the focus changes
                setFormData((prevData) => ({ ...prevData, capabilitiesDomainType: "" }));
              }}
              className="w-full rounded p-2 m-1"
            >
              <option value="">Select Domain Focus</option>
              <option value="Business">Business</option>
              <option value="Technology">Technology</option>
            </select>
          </div>

          <div id="domainType">
            <select
              name="capabilitiesDomainType"
              value={formData.capabilitiesDomainType || ""}
              onChange={(e) => setFormData({ ...formData, capabilitiesDomainType: e.target.value })}
              className="w-full rounded p-2 m-1"
              disabled={!selectedFocus} // Disable if no focus is selected
            >
              <option value="">Select Domain Type</option>
              {filteredDomainTypes?.map((domain) => (
                <option key={domain._id} value={domain._id}>
                  {domain?.capabilitiesDomainTypeName}
                </option>
              ))}
            </select>
          </div>

          <div id="functionType">
            <select
              name="capabilitiesFunctionType"
              value={formData.capabilitiesFunctionType || ""}
              onChange={(e) => setFormData({ ...formData, capabilitiesFunctionType: e.target.value })}
              className="w-full rounded p-2 m-1"
              disabled={!formData.capabilitiesDomainType} // Disable if no domain type is selected
            >
              <option value="">Select Function Type</option>
              {filteredFunctionTypes?.map((func) => (
                <option key={func._id} value={func._id}>
                  {func.capabilitiesFunctionTypeName}
                </option>
              ))}
            </select>
          </div>

          <div id="techAreaType">
            <select
              name="capabilitiesTechAreaType"
              value={formData.capabilitiesTechAreaType || ""}
              onChange={(e) =>
                setFormData({ ...formData, capabilitiesTechAreaType: e.target.value })
              }
              className="w-full rounded p-2 m-1"
              disabled={!formData.capabilitiesFunctionType} // Disable if no function type is selected
            >
              <option value="">Select Tech Area Type</option>
              {filteredTechAreaTypes?.map((techArea) => (
                <option key={techArea._id} value={techArea._id}>
                  {techArea.capabilitiesTechAreaTypeName}
                </option>
              ))}
            </select>
          </div>
          
          <div id="capabilitiesTechStackTypeName">
            <input
              type="text"
              name="capabilitiesTechStackTypeName"
              placeholder="Enter the capabilitiesTechStack type name here"
              value={formData.capabilitiesTechStackTypeName}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div className="bg-white w-full rounded p-2 m-1">
            <EtContentEditorLightWithPlace
              value={formData.capabilitiesTechStackTypeDesc}
              handleChange={(content) =>
                setFormData({ ...formData, capabilitiesTechStackTypeDesc: content })
              }
              placeholderText={
                "Write this capabilitiesTechStack type is about or will be used for"
              }
              // toolbarId={'sectionDesc'}
            />
          </div>

          <div id="aboutMe">
            <input
              type="text"
              name="aboutMe"
              placeholder="Add something about the personality about this capabilitiesTechStack type"
              value={formData.aboutMe}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="pic">
            <input
              type="file"
              name="pic"
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

         <div id="capabilitiesTechStacks" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic
              name={"Update"}
              type={"create"}
              handleClick={handleSubmit}
            />
            <EtBtnFormBasic
              name={"Clear"}
              type={"clear"}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={"Cancel"}
              type={"cancel"}
              handleClick={handleCancel}
            />
          </div>
        </form>
      </div>
    </div>
  </div>
)

}

export default EtUpdateCapabilitiesTechStackType