import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const orgTitlesAdapter = createEntityAdapter({})

const initialState = orgTitlesAdapter.getInitialState()

export const orgTitlesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOrgTitles: builder.query({
            query: () => ({
                url: '/orgTitles/getOrgTitles',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedOrgTitles = responseData.map(orgTitle => {
                    orgTitle.id = orgTitle._id
                    return orgTitle
                })
                return orgTitlesAdapter.setAll(initialState, loadedOrgTitles)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'OrgTitle', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'OrgTitle', id}))
                    ]
                } else {
                    return [ { type: 'OrgTitle', id: 'LIST'}]
                }
            },
        }),
        createNewOrgTitle: builder.mutation({
            query: initialOrgTitle => ({
                url: '/orgTitles/createNewOrgTitle',
                method: 'POST',
                body: {...initialOrgTitle,}
            }),
            invalidatesTags: [
                { type: 'OrgTitle', id: "LIST" }
            ] 
        }),
        updateOrgTitle: builder.mutation({
            query: initialOrgTitle => ({
                url: '/orgTitles/updateOrgTitle',
                method: 'PATCH',
                body: {...initialOrgTitle,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'OrgTitle', id: arg.id },
                { type: 'OrgTitle', id: 'LIST' }
            ],
        }),
        deleteOrgTitle: builder.mutation({
            query: ({id}) => ({
                url: '/orgTitles/deleteOrgTitle',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'OrgTitle', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetOrgTitlesQuery,
    useCreateNewOrgTitleMutation,
    useUpdateOrgTitleMutation,
    useDeleteOrgTitleMutation,
} = orgTitlesApiSlice

// returns the query result object
export const selectOrgTitleResult = orgTitlesApiSlice.endpoints.getOrgTitles.select()

// create memoized selector
const selectOrgTitlesData = createSelector(
    selectOrgTitleResult,
    orgTitleResult => orgTitleResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllOrgTitles,
    selectById: selectOrgTitleById,
    selectIds: selectOrgTitleIds,
} = orgTitlesAdapter.getSelectors(state => selectOrgTitlesData(state) ?? initialState)