import React, { useState, useEffect, useMemo } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLightWithPlace from "@/utils/EtContentEditorLightWithPlace"
import { useGetCapabilitiesFunctionTypesQuery, useUpdateCapabilitiesFunctionTypeMutation } from "./capabilitiesFunctionTypesApiSlice"
import { useGetCapabilitiesDomainTypesQuery } from '../capabilitiesDomainTypes/capabilitiesDomainTypesApiSlice' // 14 Nov 2024

const EtUpdateCapabilitiesFunctionType = ({id, setActiveAdminView}) => {
  // Created - 12 Nov 2024
  // Author - Atul Pandey
  // Updated - 14 Nov 2024 - added capabilitiesDomainType
  // Updated - 28 Feb 2025 - updated the function with props - { id, setActiveAdminView}
  // Updated - 28 Feb 2025 - switched from navigate to setActiveAdminView


  // 1 - Set Title
  useTitle("Update CapabilitiesFunction Type")

  // 2 - extract the capabilitiesFunctionType Id from the url
  // const { id } = useParams()
  // //console.log("id:", id)

  // 3 - Extract the capabilitiesFunctionType from the query to load the capabilitiesFunctionType data
  const { capabilitiesFunctionType } = useGetCapabilitiesFunctionTypesQuery("CapabilitiesFunctionType", {
    selectFromResult: ({ data }) => ({
      capabilitiesFunctionType: data?.entities[id],
    }),
  })

  //console.log('capabilitiesFunction type:', capabilitiesFunctionType)

  // 4 - Check for returned data
  if (!capabilitiesFunctionType) {
    return <PulseLoader color={"#ea580c"} />
  }

  // 5. Display data
  let content
  content = <EtUpdateCapabilitiesFunctionTypeForm capabilitiesFunctionType={capabilitiesFunctionType} id={id} setActiveAdminView={setActiveAdminView}/> // 28 Feb 2025 - added setActiveAdminView={setActiveAdminView}
  return content
}

const EtUpdateCapabilitiesFunctionTypeForm = ({ capabilitiesFunctionType, id,setActiveAdminView}) =>{
  // 1a - set the variable for navigate
  const navigate = useNavigate()
  //console.log('update form invoked!')
  console.log('capabilitiesFunctionType', capabilitiesFunctionType)

  // 1b - state variables for domain types
  const [selectedFocus, setSelectedFocus] = useState("");
  const [filteredDomainTypes, setFilteredDomainTypes] = useState([]);
  
  // 2 - set the mutation to create the new section data
  const[updateCapabilitiesFunctionType, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useUpdateCapabilitiesFunctionTypeMutation()

  // 2b - Get DomainTypes Data
  const { data: domainTypesData } = useGetCapabilitiesDomainTypesQuery();
  const domainTypes = useMemo(() => {
    return domainTypesData?.ids?.map(id => domainTypesData?.entities[id]) || [];
  }, [domainTypesData]);
  console.log('domainTypes', domainTypes)

  // 2c - Set initial values for selectedFocus and filteredDomainTypes when component mounts
  useEffect(() => {
    if (capabilitiesFunctionType?.capabilitiesDomainType) {
      const initialFocus = capabilitiesFunctionType?.capabilitiesDomainType?.capabilitiesDomainFocus;
      setSelectedFocus(initialFocus);
      
      // Set filtered domain types based on the initial focus
      setFilteredDomainTypes(
        domainTypes.filter((domain) => domain?.capabilitiesDomainFocus === initialFocus)
      );

      // Set the initial value for capabilitiesDomainType in formData
      setFormData((prevData) => ({
        ...prevData,
        capabilitiesDomainType: capabilitiesFunctionType?.capabilitiesDomainType._id,
      }));
    }
  }, [capabilitiesFunctionType, domainTypes]);


// 3 - set the form data object
const [formData, setFormData] = useState({
  capabilitiesFunctionTypeName: capabilitiesFunctionType.capabilitiesFunctionTypeName,
  capabilitiesFunctionTypeDesc: capabilitiesFunctionType.capabilitiesFunctionTypeDesc,
  aboutMe: capabilitiesFunctionType.aboutMe,
})

//console.log('formData for update:', formData)

// 4 - Reset form upon successful submission
useEffect(() => {
  if(isSuccess) {
    setFormData({
      capabilitiesFunctionTypeName: "",
      capabilitiesFunctionTypeDesc: "",
      aboutMe: "",
      pic: "",
    })

    // navigate('/capabilitiesFunctiontypes')
    setActiveAdminView('capabilitiesFunctionTypes'); // 28 Feb 2025
}

},[isSuccess]) // ✅ 28 Feb 2025 - Removed `navigate` since it's no longer used.

// 5 - Form field updates / change handling

const handleChange = (e) => {
  const { name, value, files } = e.target
  if (name === "pic") {
    setFormData(prevState => ({ ...prevState, pic: files[0] }))
  } else {
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }
}

// 6 - function to strip html tags from sections
function stripHtml(html) {
  return html.replace(/<\/?[^>]+(>|$)/g, "");
}

// 7 - Form Submit Logic
const handleSubmit = async (e) => {
e.preventDefault()

// Initialize an object to collect the form data
const capabilitiesFunctionTypeData = {
  capabilitiesFunctionTypeName: formData.capabilitiesFunctionTypeName,
  capabilitiesFunctionTypeDesc: formData.capabilitiesFunctionTypeDesc,
  aboutMe: formData.aboutMe,
}

//console.log('capabilitiesFunctionType Name:',capabilitiesFunctionTypeData.capabilitiesFunctionTypeName)

if (formData.pic) {
  // Convert the image to a base64 string
  const reader = new FileReader()
  reader.onloadend = async () => {
    // Once conversion is done, add the base64 string to your data object
    capabilitiesFunctionTypeData.pic = reader.result

    // Now, send the data object to the server
    try {
      capabilitiesFunctionTypeData.id = id
      console.log("Form submitted.")
      await updateCapabilitiesFunctionType(capabilitiesFunctionTypeData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
  }
  reader.readAsDataURL(formData.pic)
} else {
  // No image, send other data as is
  try {
    capabilitiesFunctionTypeData.id = id
    console.log("Form submitted.")
    await updateCapabilitiesFunctionType(capabilitiesFunctionTypeData).unwrap() // Dispatch the RTK Query mutation
  } catch (error) {
    console.error(error)
  }
}
}

// 8 - Handle Cancel
const handleCancel = () => {
  // navigate("/capabilitiesFunctiontypes")
  setActiveAdminView('capabilitiesFunctionTypes'); // 28 Feb 2025
}

// 9 - Handle Clear
const handleClear = () => {
  setFormData({
    capabilitiesFunctionTypeName: "",
    capabilitiesFunctionTypeDesc: "",
    aboutMe: "",
    pic: "",
    })
}

return (
  <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
    {isLoading && <PulseLoader color={"#ea580c"} />}
    {isError && (
      <p className="text-orange-700 font-semi-bold py-2">
        {error?.data?.message}
      </p>
    )}
    <div className="bg-white p-1 m-1 rounded">
      <p className="p-1 m-1 font-bold font-poppins text-xl">Update a CapabilitiesFunction Type</p>

      <div className="bg-gray-200 rounded m-1 p-1">
        <form
          onSubmit={handleSubmit}
          className="m-1 p-1 text-xs font-poppins"
        >

          <div id="focus">
            <select
              name="focus"
              value={selectedFocus}
              onChange={(e) => {
                const newFocus = e.target.value;
                setSelectedFocus(newFocus);
                setFilteredDomainTypes(
                  domainTypes?.filter((domain) => domain?.capabilitiesDomainFocus === newFocus)
                );
                // Reset the selected domain type if the focus changes
                setFormData((prevData) => ({ ...prevData, capabilitiesDomainType: "" }));
              }}
              className="w-full rounded p-2 m-1"
            >
              <option value="">Select Domain Focus</option>
              <option value="Business">Business</option>
              <option value="Technology">Technology</option>
            </select>
          </div>

          <div id="domainType">
            <select
              name="capabilitiesDomainType"
              value={formData.capabilitiesDomainType || ""}
              onChange={(e) => setFormData({ ...formData, capabilitiesDomainType: e.target.value })}
              className="w-full rounded p-2 m-1"
              disabled={!selectedFocus} // Disable if no focus is selected
            >
              <option value="">Select Domain Type</option>
              {filteredDomainTypes?.map((domain) => (
                <option key={domain._id} value={domain._id}>
                  {domain?.capabilitiesDomainTypeName}
                </option>
              ))}
            </select>
          </div>

          <div id="capabilitiesFunctionTypeName">
            <input
              type="text"
              name="capabilitiesFunctionTypeName"
              placeholder="Enter the capabilitiesFunction type name here"
              value={formData.capabilitiesFunctionTypeName}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div className="bg-white w-full rounded p-2 m-1">
            <EtContentEditorLightWithPlace
              value={formData.capabilitiesFunctionTypeDesc}
              handleChange={(content) =>
                setFormData({ ...formData, capabilitiesFunctionTypeDesc: content })
              }
              placeholderText={
                "Write this capabilitiesFunction type is about or will be used for"
              }
              // toolbarId={'sectionDesc'}
            />
          </div>

          <div id="aboutMe">
            <input
              type="text"
              name="aboutMe"
              placeholder="Add something about the personality about this capabilitiesFunction type"
              value={formData.aboutMe}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="pic">
            <input
              type="file"
              name="pic"
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

         <div id="capabilitiesFunctions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic
              name={"Update"}
              type={"create"}
              handleClick={handleSubmit}
            />
            <EtBtnFormBasic
              name={"Clear"}
              type={"clear"}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={"Cancel"}
              type={"cancel"}
              handleClick={handleCancel}
            />
          </div>
        </form>
      </div>
    </div>
  </div>
)

}

export default EtUpdateCapabilitiesFunctionType