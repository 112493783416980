import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit"
import { apiSlice } from "../../app/api/apiSlice"

const subscriptionPacksAdapter = createEntityAdapter({})

const initialState = subscriptionPacksAdapter.getInitialState()

export const subscriptionPacksApiSlice = apiSlice.injectEndpoints({

    endpoints: builder => ({
        getSubsPacks: builder.query({
            query: () => ({
                url: '/subscriptionPacks/getSubsPacks',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {

                const loadedsubscriptionPacks = responseData.map(questionSet =>{
                    questionSet.id = questionSet._id
                    return questionSet
                })
                return subscriptionPacksAdapter.setAll(initialState, loadedsubscriptionPacks)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'subscriptionPack', id: 'LIST'},
                        ...result.ids.map(id => ({type: 'subscriptionPack', id}))
                    ]
                } else {
                    return [{type: 'subscriptionPack', id: 'LIST'}]
                }
            },
        }),
        createNewSubsPack: builder.mutation({
         query: initialsubscriptionPack => ({
            url: '/subscriptionPacks/createNewSubsPack',
            method: 'POST',
            body: {...initialsubscriptionPack,}
         }),
         invalidatesTags: [
            { type: 'subscriptionPack', id: "LIST" }
        ]  
        }),
        updateSubsPack: builder.mutation({
            query: initialsubscriptionPack => ({
                url: '/subscriptionPacks/updateSubsPack',
                method: 'PATCH',
                body: {...initialsubscriptionPack,}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'subscriptionPack', id: arg.id}
            ]
        }),
        deleteSubsPack: builder.mutation({
            query: ({id}) => ({
                url: '/subscriptionPacks/deleteSubsPack',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg ) => [
                { type: 'subscriptionPack', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetSubsPacksQuery,
    useCreateNewSubsPackMutation,
    useUpdateSubsPackMutation,
    useDeleteSubsPackMutation,
} = subscriptionPacksApiSlice

// returns the query result object
export const selectsubscriptionPacksResult = subscriptionPacksApiSlice.endpoints.getSubsPacks.select()

// create memoized selector
const selectsubscriptionPacksData = createSelector(
    selectsubscriptionPacksResult,
    subscriptionPacksResult => subscriptionPacksResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllSubsPacks,
    selectById: selectSubsPackPackById,
    selectIds: selectSubsPackIds,
} = subscriptionPacksAdapter.getSelectors(state => selectsubscriptionPacksData(state) ?? initialState)