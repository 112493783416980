import React, { useState } from "react"
import { useNavigate } from "react-router"
import { addIcon } from "@/assets/img/imgAssets"
import {
  PlusIcon,
  MinusIcon,
  TrashIcon,
  PencilSquareIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import { useGetQuestionWithScoresQuery } from "./questionwithscoresApiSlice"
import { useGetQuestionSetsQuery } from "../questionSets/questionSetsApiSlice"

const EtQuestionWithScoresList = ({setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }) => {
  // Created - 17 Apr 2024
  //  Author - Atul Pandey
   // Updated - 01 Mar 2025 - updated the softSkillFunctionArea with props - { setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }
  // Updated - 01 Mar 2025 - switched from navigate to onAdd, onUpdate, onDelete, setSelectedEntityId


  // Step 1 - set page navigation
  const navigate = useNavigate()

  // Step 2 - Initialize showDetails state as an object where key is the section index
  const [showDetails, setShowDetails] = useState({})

  // Step 3 - Get questions data from the server
  const {
    data: questionsWithScores,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetQuestionWithScoresQuery()
  
  const {
    data: questionSetsData,
    isLoading: isQSetLoading,
    isSuccess: isQSetSuccess,
    isError: isQSetError,
    error: qSeterror,
  } = useGetQuestionSetsQuery()

 
  // Step 4 - Define Data interaction and presentation functions

  const handleToggle = (setId, questionId = null) => {
    // Toggle logic for both question sets and individual questions
    if (questionId === null) {
      setShowDetails((prev) => ({
        ...prev,
        [setId]: !prev[setId],
      }))
    } else {
      // Nested toggle for questions within a set
      setShowDetails((prev) => ({
        ...prev,
        [setId]: {
          ...prev[setId],
          [questionId]: !prev[setId]?.[questionId],
        },
      }))
    }
  }

  // Create an object for setting inner HTML
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
  }

  // Step 5 -  handle loading and error states
  if (isLoading) return <PulseLoader color={"#ea580c"} />
  if (isError) return <div>Error occurred: {error?.data?.message}</div>
  if (isQSetLoading) return <PulseLoader color={"#ea580c"} />
  if (isQSetError)
    return <div>QSet Error occurred: {qSeterror?.data?.message}</div>

  return (
    <div className="bg-gray-200 m-1 p-1 rounded">
      <div className="flex justify-start items-center m-1 p-1">
        <p className="font-poppins text-2xl m-1 p-1 font-bold">Et Questions</p>
        <div
          // onClick={() => navigate("/questionswithscores/create")}
          onClick={onAdd} // 01 Mar 2025
          className="h-10 w-10 cursor-pointer"
        >
          <img src={addIcon} alt="Add icon" className="h-full w-full" />
        </div>
      </div>
      <div
        id="questionset-data"
        className="bg-white rounded m-1 p-1 font-poppins"
      >
        <div className="m-1 p-1">
            <p className="m-1 p-1 text-xl font-bold border-b-2 border-orange-600 inline-block">Question Sets:</p>
        <p className="text-xs text-gray-500 italic m-1 p-1">Expand each set to see the questions in each set</p>
        </div>
        
        {questionSetsData?.ids.map((setId, index) => (
          <div key={setId} className="bg-gray-300 p-2 rounded m-1">
            <div className="flex justify-between items-center">
              <div className="flex justify-start items-center m-2 p-2">
                <div
                  onClick={() => handleToggle(setId)}
                  className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-6 w-6 hover:shadow-md hover:z-30  hover:bg-white"
                >
                  {showDetails[setId] ? (
                    <MinusIcon className="h-4 w-4 text-orange-600 " />
                  ) : (
                    <PlusIcon className="h-4 w-4 text-orange-600 " />
                  )}
                </div>
                <div className="h-8 w-8 rounded-full bg-gray-200 hover:bg-white flex justify-center items-center mx-2 p-1">
                    <img src={questionSetsData.entities[setId].pic} alt="" className="h-6 w-6 mx-2"/>
                </div>
                <div><p className="mx-2 px-2"><span className="font-bold mx-1 px-1">{index+1} - </span>{questionSetsData.entities[setId].setname}</p></div>
              </div>
            </div>

            {showDetails[setId] && (
              <QuestionsDataSection
                questionsWithScores={questionsWithScores}
                questionSetId={setId}
                handleToggle={handleToggle}
                showDetails={showDetails}
                createMarkup={createMarkup}
                navigate={navigate}
                onUpdate={onUpdate} // 01 Mar 2025
                onDelete={onDelete} // 01 Mar 2025
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

const QuestionsDataSection = ({
  questionsWithScores,
  questionSetId,
  handleToggle,
  showDetails,
  createMarkup,
  navigate,
  onUpdate, // 01 Mar 2025
  onDelete, // 01 Mar 2025

}) => {
  const filteredQuestions = questionsWithScores.ids.filter(
    (id) => questionsWithScores.entities[id].setname === questionSetId
  )

  return (
    <div id="assessment-data" className="bg-white rounded m-1 p-1 font-poppins">
    {filteredQuestions.length > 0 ? (
        <>
        <p className="font-bold m-1 p-1">Questions in this set</p>
      {filteredQuestions.map((id, index) => {
        if (questionsWithScores.entities[id].setname !== questionSetId)
          return null
        const { questionName, questionResponseOptions } =
          questionsWithScores.entities[id]
        return (
          <div key={id} className="bg-gray-200 rounded m-1 p-1">
            <div className="flex justify-between items-center">
              <div className="flex justify-start items-center mx-2">
                <div
                  onClick={() => handleToggle(questionSetId, id)}
                  className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:bg-white"
                >
                  {showDetails[questionSetId]?.[id] ? (
                    <MinusIcon className="h-3 w-3 text-orange-600" />
                  ) : (
                    <PlusIcon className="h-3 w-3 text-orange-600" />
                  )}
                </div>
                <div className="m-1 p-1 flex justify-start items-center">
                  <p className="text-sm font-bold mx-1 px-1">Q{index + 1} -</p>
                  <p
                    className="text-sm"
                    dangerouslySetInnerHTML={createMarkup(questionName)}
                  ></p>
                </div>
              </div>
              <div className="flex justify-start items-center">
                <div
                  // onClick={() => navigate(`/questionswithscores/update/${id}`)}
                  onClick={() => onUpdate(id)} // 01 Mar 2025 
                  

                  className="cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:bg-white"
                >
                  <PencilSquareIcon className="h-3 w-3 text-orange-600" />
                </div>
                <div
                  // onClick={() => navigate(`/questionswithscores/delete/${id}`)}
                  onClick={() => onDelete(id)} // 01 Mar 2025 
                  


                  className="cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:bg-white"
                >
                  <TrashIcon className="h-3 w-3 text-orange-600" />
                </div>
              </div>
            </div>
            {showDetails[questionSetId]?.[id] && (
              <div className="bg-white rounded text-xs py-1">
                {questionResponseOptions &&
                questionResponseOptions?.length > 0 ? (
                  <div className="mx-1 px-1">
                    <p className="font-bold p-2 mx-1 rounded bg-gray-100 inline-block text-center">
                      Response Options:
                    </p>
                  </div>
                ) : (
                  <div className="mx-1 px-1">
                    <p className="font-bold p-2 mx-1">
                      This is an open ended question
                    </p>
                  </div>
                )}
                {questionResponseOptions &&
                  questionResponseOptions.map((item, detailIndex) => (
                    <div
                      key={detailIndex}
                      className="grid grid-cols-8 mx-1 px-1 text-[10px] w-full"
                    >
                      <div className="flex justify-start items-center bg-gray-200 rounded m-1 p-1 col-span-6">
                        <div className="m-1 p-1">
                          <p className="font-bold text-gray-400">
                            Option {detailIndex + 1} :
                          </p>
                        </div>
                        <div
                          className="m-1 p-1"
                          dangerouslySetInnerHTML={createMarkup(
                            item.responseText
                          )}
                        ></div>
                      </div>
                      <div className="flex justify-start items-center bg-gray-200 rounded m-1 p-1 col-span-1">
                        <div className="m-1 p-1">
                          <p className="font-bold text-gray-400">Score :</p>
                        </div>
                        <div
                          className="m-1 p-1"
                          dangerouslySetInnerHTML={createMarkup(item.score)}
                        ></div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        )
      })}
      </>
    ) :(
        <p className="font-bold m-1 p-1">There are no questions in this set as of now.</p>
    )}
    </div>
  )
}

export default EtQuestionWithScoresList
