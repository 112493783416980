import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userSuccessorsAdapter = createEntityAdapter({})

const initialState = userSuccessorsAdapter.getInitialState()

export const userSuccessorsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserSuccessors: builder.query({
            query: () => ({
                url: '/userSuccessors/getUserSuccessors',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserSuccessors = responseData.map(userSuccessor => {
                    userSuccessor.id = userSuccessor._id
                    return userSuccessor
                })
                return userSuccessorsAdapter.setAll(initialState, loadedUserSuccessors)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserSuccessor', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserSuccessor', id}))
                    ]
                } else {
                    return [ { type: 'UserSuccessor', id: 'LIST'}]
                }
            },
        }),
        createNewUserSuccessor: builder.mutation({
            query: initialUserSuccessor => ({
                url: '/userSuccessors/createNewUserSuccessor',
                method: 'POST',
                body: {...initialUserSuccessor,}
            }),
            invalidatesTags: [
                { type: 'UserSuccessor', id: "LIST" }
            ] 
        }),
        updateUserSuccessor: builder.mutation({
            query: initialUserSuccessor => ({
                url: '/userSuccessors/updateUserSuccessor',
                method: 'PATCH',
                body: {...initialUserSuccessor,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserSuccessor', id: arg.id },
                { type: 'UserSuccessor', id: 'LIST' }
            ],
        }),
        deleteUserSuccessor: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userSuccessors/deleteUserSuccessor',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userSuccessors/deleteUserSuccessor',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserSuccessor', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserSuccessorsQuery,
    useCreateNewUserSuccessorMutation,
    useUpdateUserSuccessorMutation,
    useDeleteUserSuccessorMutation,
} = userSuccessorsApiSlice

// returns the query result object
export const selectUserSuccessorResult = userSuccessorsApiSlice.endpoints.getUserSuccessors.select()

// create memoized selector
const selectUserSuccessorsData = createSelector(
    selectUserSuccessorResult,
    userSuccessorResult => userSuccessorResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserSuccessors,
    selectById: selectUserSuccessorById,
    selectIds: selectUserSuccessorIds,
} = userSuccessorsAdapter.getSelectors(state => selectUserSuccessorsData(state) ?? initialState)