import React from 'react'
import EtUpdateUserImprintPlanOrgComp from '@/features/userMagic/userImprint/EtUpdateUserImprintPlanOrgComp'
import {
    XCircleIcon
  } from "@heroicons/react/24/solid"

const EtModalUpdateUserImprintPlanOrg = ({isOpen, id, userId, userOrgId, triggeringUrl, onClose}) => {

  // First Created - 14 Jan 2025
  // Author - Atul Pandey
  // Inherited from EtModalCreateUserImprintGratitude
  // console.log('triggering url', triggeringUrl)

  if (!isOpen) return null


  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 z-70 flex justify-center items-start font-poppins overflow-y-auto p-4">
      <div className="bg-black p-5 rounded-lg shadow-lg shadow-black relative w-full max-w-6xl">
        <div
          className="absolute top-2 right-2 m-2 font-bold rounded-full cursor-pointer h-7 w-7 flex justify-center items-center"
          onClick={onClose}
        >
          <XCircleIcon className='text-orange-600 rounded-full hover:text-red-600 h-7 w-7'/>
        </div>
        <EtUpdateUserImprintPlanOrgComp id={id} userId={userId} userOrgId={userOrgId} triggeringUrl={triggeringUrl} onClose={onClose}/>
      </div>
    </div>
  )
}

export default EtModalUpdateUserImprintPlanOrg