import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import Modal from "@/components/common/modal/Modal"
import { useGetChallengeHeadsQuery, useDeleteChallengeHeadMutation } from "./challengeHeadsApiSlice"

const EtDeleteChallengeHead = ({id, setActiveAdminView}) => {
  // Created - 16 Jun 2024
  // Author - Atul Pandey
   // Updated - 28 Feb 2025 - updated the softSkillFunctionArea with props - { id, setActiveAdminView}
  // Updated - 28 Feb 2025 - switched from navigate to setActiveAdminView


  // 1 - Set Title
  useTitle("Update Challenge Type")

  // 2 - extract the challengeHead Id from the url
  // const { id } = useParams()
  //console.log("id:", id)

  // 3 - Extract the challengeHead from the query to load the challengeHead data
  const { challengeHead } = useGetChallengeHeadsQuery("ChallengeHead", {
    selectFromResult: ({ data }) => ({
      challengeHead: data?.entities[id],
    }),
  })

  //console.log('challenge type:', challengeHead)

  // 4 - Check for returned data
  if (!challengeHead) {
    return <PulseLoader color={"#ea580c"} />
  }

  // 5. Display data
  let content
  content = <EtDeleteChallengeHeadForm challengeHead={challengeHead} id={id} setActiveAdminView={setActiveAdminView}/> // 28 Feb 2025 - added setActiveAdminView={setActiveAdminView}
  return content
}

const EtDeleteChallengeHeadForm = ({challengeHead, id, setActiveAdminView}) => {

  // 1 - set the variable for navigate
  const navigate = useNavigate()

 // 1a - Delete section modal 
 const [isModalOpen, setIsModalOpen] = useState(false)


  // 2 - set the mutation to create the new section data
  const[deleteChallengeHead, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useDeleteChallengeHeadMutation()

// 3 - set the form data object
const [formData, setFormData] = useState({
  challengeHeadName: challengeHead.challengeHeadName,
})

//console.log('formData for update:', formData)

// 4 - Reset form upon successful submission
useEffect(() => {
  if(isSuccess) {
    setFormData({
      challengeHeadName: "",
    })
 // navigate('/challengeheads')
 setActiveAdminView('challengeHeads'); // 28 Feb 2025
}

},[isSuccess])// ✅ 28 Feb 2025 - Removed `navigate` since it's no longer used.

// 5 - Form field updates / change handling

const handleChange = (e) => {
  const { name, value, files } = e.target
  if (name === "pic") {
    setFormData(prevState => ({ ...prevState, pic: files[0] }))
  } else {
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }
}

// Modal management functions
const handleOpenModal = () => setIsModalOpen(true)
const handleCloseModal = () => setIsModalOpen(false)

// 7 - Form Submit Logic
const handleConfirm = async (e) => {
  e.preventDefault()
  handleCloseModal()

  // Initialize an object to collect the form data
  const challengeHeadData = {
    challengeHeadName: formData.challengeHeadName,
  }
    // No image, send other data as is
    try {
      challengeHeadData.id = id
      console.log("Form submitted.")
      await deleteChallengeHead(challengeHeadData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
}

// 8 - Handle Cancel

const handleCancel = () => {
  // navigate('/challengeheads')
 setActiveAdminView('challengeHeads'); // 28 Feb 2025
}


return (
  <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
    {isLoading && <PulseLoader color={"#ea580c"} />}
    {isError && (
      <p className="text-orange-700 font-semi-bold py-2">
        {error?.data?.message}
      </p>
    )}
    <div className="bg-white p-1 m-1 rounded">
      <p className="p-1 m-1 font-bold font-poppins text-xl">Update a Challenge Type</p>

      <div className="bg-gray-200 rounded m-1 p-1">
        <form
          onSubmit={handleOpenModal}
          className="m-1 p-1 text-xs font-poppins"
        >
          
          <div id="challengeHeadName">
            <input
              type="text"
              name="challengeHeadName"
              placeholder="Enter the challenge type name here"
              value={formData.challengeHeadName}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="challenges" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic
              name={"Delete"}
              type={"create"}
              handleClick={handleOpenModal}
            />
            <EtBtnFormBasic
              name={"Cancel"}
              type={"cancel"}
              handleClick={handleCancel}
            />
          </div>
        </form>
      </div>
    </div>
    <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleConfirm} />
  </div>
)

}

export default EtDeleteChallengeHead