import React from 'react'

const EtBtnFormBasic = ({name, type, handleClick}) => {

  let createClass = 'btn hover:bg-gradient-to-r hover:from-orange-600 hover:to-orange-800 hover:border-transparent hover:shadow-lg hover:shadow-black border border-orange-600 bg-transparent w-32 p-2 hover:text-white text-orange-600 rounded-full mx-1'
  // let createClass = 'btn hover:bg-gradient-to-r hover:from-orange-600 hover:to-orange-800 hover:border-none hover:shadow-lg hover:shadow-black border border-orange-600 bg-transparent w-32 p-2 hover:text-white text-orange-600 rounded-full mx-1'
  let clearClass = 'btn hover:bg-gradient-to-r hover:from-black hover:via-slate-800 hover:to-black hover:border-none hover:shadow-lg hover:shadow-black border border-gray-600 bg-transparent w-32 p-2 hover:text-white text-gray-600 rounded-full mx-1'
  let cancelClass = 'btn hover:bg-gradient-to-r hover:from-red-800 hover:to-red-600 hover:border-none hover:shadow-lg hover:shadow-black border border-orange-600 bg-transparent w-32 p-2 hover:text-white text-orange-600 rounded-full mx-1'

  return (
    <button 
        type='button'
        className={type === 'create' ? createClass : type === 'clear' ? clearClass : type === 'cancel' ? cancelClass : ''}
        onClick={handleClick}>
          {name}
    </button>
  )
}

export default EtBtnFormBasic