
    import React from 'react';
    
    const EtCapabilitiesEngagementsIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" fill="currentColor">
          <g transform="translate(-371 -99)"><path d="M504.776 200.664 491.096 194.472 455.816 210.456C451.784 212.328 447.464 213.192 443 213.192 438.536 213.192 434.216 212.328 430.184 210.456L394.904 194.328 381.224 200.52C375.752 202.968 375.752 210.744 381.224 213.192L434.36 237.528C439.832 239.976 446.168 239.976 451.784 237.528L504.92 213.336C510.248 210.744 510.248 203.112 504.776 200.664Z" fillRule="evenodd"/><path d="M504.776 164.664 491.096 158.472 455.816 174.6C451.784 176.472 447.464 177.336 443 177.336 438.536 177.336 434.216 176.472 430.184 174.6L394.76 158.472 381.08 164.664C375.608 167.112 375.608 174.888 381.08 177.336L434.36 201.528C439.832 203.976 446.168 203.976 451.784 201.528L504.92 177.336C510.248 174.888 510.248 167.112 504.776 164.664Z" fillRule="evenodd"/><path d="M381.224 141.336 434.36 165.672C439.832 168.12 446.168 168.12 451.784 165.672L504.92 141.48C510.392 139.032 510.392 131.256 504.92 128.808L451.64 104.472C446.168 102.024 439.832 102.024 434.216 104.472L381.224 128.664C375.752 131.256 375.752 138.888 381.224 141.336Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtCapabilitiesEngagementsIcon1SVGComp;
    