import React, { useState, useEffect } from 'react'
import useTitle from "../../../hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
    PencilSquareIcon,
  } from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "../../../utils/EtContentEditorLight2"
import EtBtnFormBasic from "../../../components/common/buttons/EtBtnFormBasic"
import { useNavigate } from "react-router"
import { useCreateNewUserImprintCapabilitiesFunctionMutation, useGetUserImprintCapabilitiesFunctionsQuery } from './userImprintCapabilitiesFunctionsApiSlice'
import { useGetCapabilitiesFunctionTypesQuery } from '../../capabilitiesFunctionTypes/capabilitiesFunctionTypesApiSlice'
import { produce } from "immer";

const EtUpdateUserImprintCapabilitiesFunctionComp = ({
  userId, 
  userOrgId, 
  triggeringUrl, 
  onClose
}) => {
  // First Created - 22 Nov 2024
  // Author - Atul Pandey
  // Inherited from the EtCreateUserImprintCapabilitiesIndustry component

  // 0 - Set Page title & variables
  useTitle('User Imprint Cpabilities')


  // 1a - set page navigations and seed variables (these will be props)
  const navigate = useNavigate()
  
  // 1b - Initialize state variables for the editor key and value
  const [editorKey, setEditorKey] = useState("capabilitiesFunctionDesc-" + Date.now())
  const [editorValue, setEditorValue] = useState("")
  const [showCreateUserImprintCapabilitiesFunction, setShowCreateUserImprintCapabilitiesFunction] = useState(false)
  const [selectedFunction, setSelectedFunction] = useState(null); // 20 Nov 2024

  // 2a - set the mutation to create the new user imprint capabilitiesFunction data
  const [createNewUserImprintCapabilitiesFunction, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewUserImprintCapabilitiesFunctionMutation()

  // 2b - Get capability function types
  const { data: functionTypesData} = useGetCapabilitiesFunctionTypesQuery()
  const functionTypes = functionTypesData?.ids?.map(id => functionTypesData?.entities[id])
  console.log('Function Types', functionTypes)

  // 2c - Group Function Types by focus and domain
  const groupedFunctionTypes = functionTypes?.reduce((result, type) => {
    const focus = type.capabilitiesDomainType?.capabilitiesDomainFocus || "Other";
    const domainName = type.capabilitiesDomainType?.capabilitiesDomainTypeName || "Unknown Domain";
  
    if (!result[focus]) {
      result[focus] = {};
    }
  
    if (!result[focus][domainName]) {
      result[focus][domainName] = [];
    }
  
    result[focus][domainName].push(type);
  
    return result;
    }, {}) || {};
  
  
  // console.log('groupedFunctionTypes', groupedFunctionTypes)

  // 2c - Get existing capabilities function Data from the backend
  const { data: userImprintCapabilitiesFunctionData} = useGetUserImprintCapabilitiesFunctionsQuery()
  const userImprintCapabilitiesFunctions = userImprintCapabilitiesFunctionData?.ids
  ?.map(id => userImprintCapabilitiesFunctionData?.entities[id])
  ?.filter(item=> item.userId._id === userId && item.userOrgId._id === userOrgId) || []
  console.log('userImprintCapabilties Function', userImprintCapabilitiesFunctions)

  // 2d - Update the selectedFunction state value based on the incoming data
  useEffect(() => {
    if (userImprintCapabilitiesFunctions?.length > 0) {
      setSelectedFunction(userImprintCapabilitiesFunctions[0]);
    }
  }, [userImprintCapabilitiesFunctions]);
  
  // 3a - Initialize state variables for the form data
  const [formData, setFormData] = useState({
    userImprintCapabilitiesFunctionName: "",
    userImprintCapabilitiesFunctionDesc: "",
    userImprintCapabilitiesFunctionStats: [
      {
        functionType:null,
        yearsOfExperience: 0,
        proficiencyLevel: "Yet to Begin",
        numberOfEngagements:0,
        certifications: [
          {
            certificationName: "",
            certificationDesc: "",
            certificationDate: "",
            validTill: "",
          }
        ],
        recognitions: [
          {
            recognitionName: "",
            recognitionDesc: "",
            recognitionOrg: "",
            recognitionDate: "",
          }
        ],
      }
    ],
    userImprintCapabilitiesFunctionSelfNotes: [],
    aboutMe: "",
    pic: "",
  })

  // 3b - Use Effect to populate the formData based on the data from the backend
  useEffect(() => {
    if (selectedFunction) {
      setFormData(
        produce((draft) => {
          draft.userImprintCapabilitiesFunctionName = selectedFunction.userImprintCapabilitiesFunctionName || "";
          draft.userImprintCapabilitiesFunctionDesc = selectedFunction.userImprintCapabilitiesFunctionDesc || "";
          draft.userImprintCapabilitiesFunctionStats = selectedFunction.userImprintCapabilitiesFunctionStats?.map((stat) => ({
            ...stat,
            functionType: stat.functionType?._id || null,
          })) || [
            {
              functionType: null,
              yearsOfExperience: 0,
              proficiencyLevel: "Yet to Begin",
              numberOfEngagements: 0,
              certifications: [],
              recognitions: [],
            },
          ];
          draft.userImprintCapabilitiesFunctionSelfNotes = selectedFunction.userImprintCapabilitiesFunctionSelfNotes || [];
          draft.aboutMe = selectedFunction.aboutMe || "";
          draft.pic = "";
        })
      );
      setEditorValue(selectedFunction.userImprintCapabilitiesFunctionDesc || "");
    }
  }, [selectedFunction]);
 
  // 5 - Handle form field changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
  setFormData(
    produce((draft) => {
      if (name === "pic") {
        draft.pic = files[0];
      } else {
        draft[name] = value;
      }
    })
  );
  }

  // 5a - Handle editor value change
  const handleEditorChange = (content) => {
    setEditorValue(content)
    setFormData(prevState => ({ ...prevState, userImprintCapabilitiesFunctionDesc: content }))
  }

  // 5b - Generic Handle Add Field Function
  const handleAdd = (fieldName, newItem) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: [...prevState[fieldName], newItem]
    }))
  }

  // 5c - Generic Handle Remove Field Function
  const handleRemove = (fieldName, index) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: prevState[fieldName].filter((_, i) => i !== index)
    }))
  }

  // 5d - Function to convert pic to Base64
  const convertPicToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    }); 
 

  // 6 - Form submit logic
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Step 1: Handle the pic conversion separately
    const picBase64 = formData.pic ? await convertPicToBase64(formData.pic) : undefined;
  
    // Step 2: Use Immer to produce an immutable copy
    const payload = produce(formData, (draft) => {
      draft.userId = userId;
      draft.userOrgId = userOrgId;
      // Trim and clean up values within the draft object
      draft.userImprintCapabilitiesFunctionName =
        draft.userImprintCapabilitiesFunctionName?.trim() || undefined;
      draft.userImprintCapabilitiesFunctionDesc =
        draft.userImprintCapabilitiesFunctionDesc?.trim() || undefined;
  
      draft.userImprintCapabilitiesFunctionStats.forEach((stat) => {
        stat.certifications = stat.certifications.filter((cert) =>
          Object.values(cert).some((value) => value !== "")
        );
        stat.recognitions = stat.recognitions.filter((rec) =>
          Object.values(rec).some((value) => value !== "")
        );
      });
  
      draft.userImprintCapabilitiesFunctionSelfNotes =
        draft.userImprintCapabilitiesFunctionSelfNotes.filter(
          (note) => note.userImprintCapabilitiesFunctionSelfNoteText.trim() !== ""
        );
      draft.aboutMe = draft.aboutMe?.trim() || undefined;
      // Add the Base64 pic separately
      draft.pic = picBase64;
      
    });
  
    try {
      // Call the mutation
      await createNewUserImprintCapabilitiesFunction(payload).unwrap();
  
      // Reset form and navigate on success
      setFormData({
        userImprintCapabilitiesFunctionName: "",
        userImprintCapabilitiesFunctionDesc: "",
        userImprintCapabilitiesFunctionStats: [
          {
            functionType: null,
            yearsOfExperience: 0,
            proficiencyLevel: "Yet to Begin",
            numberOfEngagements: 0,
            certifications: [],
            recognitions: [],
          },
        ],
        userImprintCapabilitiesFunctionSelfNotes: [],
        aboutMe: "",
        pic: "",
      });
      if (onClose) {
        onClose(); // 21 Nov 2024
      } else{
        navigate(triggeringUrl || '/userMagicHome');
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  
  // 7 - Reset form upon successful submission
  useEffect(() => {
    if(isSuccess) {
      setFormData({
        userImprintCapabilitiesFunctionName: "",
      userImprintCapabilitiesFunctionDesc: "",
      userImprintCapabilitiesFunctionStats: [
        {
          functionType:null,
          yearsOfExperience: 0,
          proficiencyLevel: "Yet to Begin",
          numberOfEngagements:0,
          certifications: [
            {
              certificationName: "",
              certificationDesc: "",
              certificationOrg:"",
              certificationDate: "",
              validTill: "",
            }
          ],
          recognitions: [
            {
              recognitionName: "",
              recognitionDesc: "",
              recognitionOrg: "",
              recognitionDate: "",
            }
          ],
        }
      ],
      userImprintCapabilitiesFunctionSelfNotes: [],
      aboutMe: "",
      pic: "",
      })
      // setShowCreateUserImprintCapabilitiesFunction(false)
      if (onClose) {
        onClose(); // 21 Nov 2024
      } else{
        navigate(triggeringUrl || '/userMagicHome');
      }
    }
  
    },[isSuccess,navigate])

  // 8 - Handle Cancel
  const handleCancel = () => {
    // setShowCreateUserImprintCapabilitiesFunction(false)
    if (onClose) {
      onClose(); // 21 Nov 2024
    } else{
      navigate(triggeringUrl || '/userMagicHome');
    }
  }

  // 9 - Handle Clear
  const handleClear = () => {
    setFormData(
      produce((draft) => {
        draft.userImprintCapabilitiesFunctionName = "";
        draft.userImprintCapabilitiesFunctionDesc = "";
        draft.userImprintCapabilitiesFunctionStats = [
          {
            functionType: null,
            yearsOfExperience: 0,
            proficiencyLevel: "Yet to Begin",
            numberOfEngagements: 0,
            certifications: [],
            recognitions: [],
          },
        ];
        draft.userImprintCapabilitiesFunctionSelfNotes = [];
        draft.aboutMe = "";
        draft.pic = "";
      })
    );
  };
 
  return (
    <div className="mx-auto m-1 p-1 bg-white rounded shadow-md shadow-black">
      {isLoading && <PulseLoader color={"#ea580c"} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">
          {error?.data?.message}
        </p>
      )}

      <p className="p-1 m-1 font-bold font-poppins text-xl">Create User Imprint Capabilities - Function</p>

      
      <form onSubmit={handleSubmit} className="m-1 p-1 text-xs font-poppins">
      {/* 1. userImprintCapabilitiesFunctionName */}
      <div id="userImprintCapabilitiesFunctionName" className="bg-gray-100 rounded p-1 m-1">
        <label htmlFor="userImprintCapabilitiesFunctionName" className="block text-gray-600 text-sm font-bold mb-1">
          Capability Name (Optional)
        </label>
        <input
          type="text"
          name="userImprintCapabilitiesFunctionName"
          placeholder="Enter a headline or title"
          value={formData.userImprintCapabilitiesFunctionName}
          onChange={handleChange}
          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
        />
      </div>
      
      {/* 2. userImprintCapabilitiesFunctionDesc */}
      <div id="userImprintCapabilitiesFunctionDesc" className="bg-gray-100 rounded p-1 m-1">
        <label htmlFor="userImprintCapabilitiesFunctionDesc" className="block text-gray-600 text-sm font-bold mb-1">
          Capability Description
        </label>
        <EtContentEditorLight2
          value={editorValue}
          handleChange={handleEditorChange}
          placeholderText="Write your capability description here!"
          editorKey={editorKey}
        />
      </div>

      {/* Function Stats Section */}

      <div className="flex justify-between items-center m-1 p-1">
        <p className="font-bold text-sm mb-2">Function Stats</p>
        <div className='flex justify-start items-center'>
          <p className='text-xs font-bold mx-1 px-1'>Add</p>
          <button
            type="button"
            className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
            onClick={() => {
              setFormData(
                produce((draft) => {
                  draft.userImprintCapabilitiesFunctionStats.push({
                    functionType: null,
                    yearsOfExperience: 0,
                    proficiencyLevel: "Yet to Begin",
                    numberOfEngagements: 0,
                    certifications: [],
                    recognitions: [],
                  });
                })
              );
            }}
          >
            <PlusIcon className="h-4 w-4 inline" /> 
          </button>
        </div>
        
      </div>

      {formData.userImprintCapabilitiesFunctionStats.map((stat, statIndex) => (
          <div key={statIndex} className="border rounded p-2 m-2 bg-gray-50">
            <div className="flex justify-between items-center mb-2">
                <p className="font-bold text-sm mb-2">Function Stat {statIndex + 1}</p>
                <div className='flex justify-start items-center'>
                  <p className='text-xs font-bold mx-1 px-1'>Remove</p>
                  <button
                    type="button"
                    className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                    onClick={() => {
                      setFormData(
                        produce((draft) => {
                          draft.userImprintCapabilitiesFunctionStats.splice(statIndex, 1);
                        })
                      );
                    }}
                  >
                    <MinusIcon className="h-4 w-4 inline" /> 
                  </button>
                </div>
                
            </div>

            {/* Function Type Dropdown */}
            <div id={`functionType-${statIndex}`} className="flex justify-between items-center bg-gray-100 rounded p-1 m-1">
              <label htmlFor={`functionType-${statIndex}`} className="text-gray-600 text-sm font-bold w-1/3">
                Function Type
              </label>
                {/* <select
                  id={`functionType-${statIndex}`}
                  value={stat.functionType || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormData((prevState) => {
                      const updatedStats = [...prevState.userImprintCapabilitiesFunctionStats];
                      updatedStats[statIndex].functionType = value;
                      return { ...prevState, userImprintCapabilitiesFunctionStats: updatedStats };
                    });
                  }}
                  className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
                >
                  <option value="" disabled>
                    Select Function Type
                  </option>
                  <optgroup label="Business Functions">
                    {businessFunctionTypes.map((type) => (
                      <option key={type._id} value={type._id}>
                        {type.capabilitiesFunctionTypeName}
                      </option>
                    ))}
                  </optgroup>
                  <optgroup label="Technology Functions">
                    {technologyFunctionTypes.map((type) => (
                      <option key={type._id} value={type._id}>
                        {type.capabilitiesFunctionTypeName}
                      </option>
                    ))}
                  </optgroup>
                </select> */}
                {/* <select
                id={`functionType-${statIndex}`}
                value={stat.functionType || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  setFormData((prevState) => {
                    const updatedStats = [...prevState.userImprintCapabilitiesFunctionStats];
                    updatedStats[statIndex].functionType = value;
                    return { ...prevState, userImprintCapabilitiesFunctionStats: updatedStats };
                  });
                }}
                className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
              >
                <option value="" disabled>
                  Select Function Type
                </option>
                {functionTypes?.map((type) => (
                  <option key={type._id} value={type._id}>
                    {type.capabilitiesFunctionTypeName}
                  </option>
                ))}
              </select> */}
              <select
                  id={`functionType-${statIndex}`}
                  value={stat.functionType || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormData((prevState) => {
                      const updatedStats = [...prevState.userImprintCapabilitiesFunctionStats];
                      updatedStats[statIndex].functionType = value;
                      return { ...prevState, userImprintCapabilitiesFunctionStats: updatedStats };
                    });
                  }}
                  className="w-full rounded p-2 border border-gray-300 focus:outline-none"
                >
                  <option value="" disabled>
                    Select Function Type
                  </option>
                  {Object.entries(groupedFunctionTypes)?.map(([focus, domains]) =>
                    Object.entries(domains).map(([domainName, functions]) => (
                      <optgroup key={`${focus}-${domainName}`} label={`${focus} - ${domainName}`}>
                        {functions.map((func) => (
                          <option key={func._id} value={func._id}>
                            {func.capabilitiesFunctionTypeName}
                          </option>
                        ))}
                      </optgroup>
                    ))
                  )}
                </select>

            </div>

              {/* Years of Experience */}
              <div id={`yearsOfExperience-${statIndex}`} className="flex justify-between items-center bg-gray-100 rounded p-1 m-1">
                <label htmlFor={`yearsOfExperience-${statIndex}`} className="text-gray-600 text-sm font-bold w-1/3">
                  Years of Experience
                </label>
                <input
                  type="number"
                  id={`yearsOfExperience-${statIndex}`}
                  name="yearsOfExperience"
                  min="0"
                  value={stat.yearsOfExperience || 0}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10) || 0;
                    setFormData(
                      produce((draft) => {
                        draft.userImprintCapabilitiesFunctionStats[statIndex].yearsOfExperience = value;
                      })
                    );
                  }}
                  className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
                />
              </div>

              {/* Proficiency Level */}
              <div id={`proficiencyLevel-${statIndex}`} className="flex justify-between items-center bg-gray-100 rounded p-1 m-1">
                <label htmlFor={`proficiencyLevel-${statIndex}`} className="text-gray-600 text-sm font-bold w-1/3">
                  Proficiency Level
                </label>
                <select
                  id={`proficiencyLevel-${statIndex}`}
                  name="proficiencyLevel"
                  value={stat.proficiencyLevel || "Yet to Begin"}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormData(
                      produce((draft) => {
                        draft.userImprintCapabilitiesFunctionStats[statIndex].proficiencyLevel = value;
                      })
                    );
                  }}
                  className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
                >
                  <option value="Yet to Begin">Yet to Begin</option>
                  <option value="Beginner">Beginner</option>
                  <option value="Advanced">Advanced</option>
                  <option value="Pro">Pro</option>
                  <option value="Expert">Expert</option>
                  <option value="Leader">Leader</option>
                </select>
              </div>

              {/* Number of Engagements */}
              <div id={`numberOfEngagements-${statIndex}`} className="flex justify-between items-center bg-gray-100 rounded p-1 m-1">
                <label htmlFor={`numberOfEngagements-${statIndex}`} className="text-gray-600 text-sm font-bold w-1/3">
                  Number of Engagements
                </label>
                <input
                  type="number"
                  id={`numberOfEngagements-${statIndex}`}
                  name="numberOfEngagements"
                  min="0"
                  value={stat.numberOfEngagements || 0}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10) || 0;
                    setFormData(
                      produce((draft) => {
                        draft.userImprintCapabilitiesFunctionStats[statIndex].numberOfEngagements = value;
                      })
                    );
                  }}
                  className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
                />
              </div>

              {/* Certifications Section */}
              <div id={`certifications-${statIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                <div className="flex justify-between items-center mb-2">
                    <p className="font-bold text-sm">Certifications</p>
                    <div className='flex justify-start items-center'>
                      <p className='text-xs font-bold mx-1 px-1'>Add</p>
                      <button
                          type="button"
                          className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                          onClick={() => {
                            setFormData(
                              produce((draft) => {
                                draft.userImprintCapabilitiesFunctionStats[statIndex].certifications.push({
                                  certificationName: "",
                                  certificationDesc: "",
                                  certificationOrg:"",
                                  certificationDate: "",
                                  validTill: "",
                                });
                              })
                            );
                          }}
                        >
                        <PlusIcon className="h-4 w-4 inline" />
                      </button>

                    </div>
                  
                </div>

                {/* Certifications List */}
                {stat.certifications.map((cert, certIndex) => (
                  <div key={`${statIndex}-${certIndex}`} className="p-2 border rounded mb-2 bg-white">
                    <div className="flex justify-between items-center">
                        <p className="font-bold text-sm">Certification {certIndex + 1}</p>
                        <div className='flex justify-start items-center'>
                            <p className='text-xs font-bold mx-1 px-1'>Remove</p>
                            <button
                            type="button"
                            className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                            onClick={() => {
                              setFormData(
                                produce((draft) => {
                                  draft.userImprintCapabilitiesFunctionStats[statIndex].certifications.splice(certIndex, 1);
                                })
                              );
                            }}
                          >
                            <MinusIcon className="h-4 w-4 inline" />
                          </button>
                        </div>
                      
                    </div>

                    {/* Certification Fields */}
                    <input
                      type="text"
                      placeholder="Certification Name"
                      value={cert.certificationName}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData(
                          produce((draft) => {
                            draft.userImprintCapabilitiesFunctionStats[statIndex].certifications[certIndex].certificationName = value;
                          })
                        );
                      }}
                      className="w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-orange-300 mt-2"
                    />

                    <EtContentEditorLight2
                      value={cert.certificationDesc}
                      handleChange={(content) => {
                        setFormData(
                          produce((draft) => {
                            draft.userImprintCapabilitiesFunctionStats[statIndex].certifications[certIndex].certificationDesc = content;
                          })
                        );
                      }}
                      placeholderText="Write certification details here..."
                      editorKey={`certificationDesc-${statIndex}-${certIndex}`}
                    />

                    <div className="flex justify-between items-center">
                      
                      <div className="flex flex-col m-1 p-1 w-full">
                        <label
                          htmlFor={`certificationOrg-${statIndex}-${certIndex}`}
                          className="text-gray-600 text-sm font-bold"
                        >
                          Certifying Organization:
                        </label>
                        <input
                          type="text"
                          id={`certificationOrg-${statIndex}-${certIndex}`}
                          value={cert.certificationOrg || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData(
                              produce((draft) => {
                                draft.userImprintCapabilitiesFunctionStats[statIndex].certifications[certIndex].certificationOrg = value;
                              })
                            );
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-orange-300"
                        />
                      </div>

                      <div className="flex flex-col m-1 p-1 w-full">
                        <label
                          htmlFor={`certificationDate-${statIndex}-${certIndex}`}
                          className="text-gray-600 text-sm font-bold"
                        >
                          Certification Date
                        </label>
                        <input
                          type="date"
                          id={`certificationDate-${statIndex}-${certIndex}`}
                          value={cert.certificationDate ? cert.certificationDate.split('T')[0] : ''}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData(
                              produce((draft) => {
                                draft.userImprintCapabilitiesFunctionStats[statIndex].certifications[certIndex].certificationDate = value;
                              })
                            );
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-orange-300"
                        />
                      </div>

                      <div className="flex flex-col m-1 p-1 w-full">
                        <label
                          htmlFor={`validTill-${statIndex}-${certIndex}`}
                          className="text-gray-600 text-sm font-bold"
                        >
                          Valid Till
                        </label>
                        <input
                          type="date"
                          id={`validTill-${statIndex}-${certIndex}`}
                          value={cert.validTill ? cert.validTill.split('T')[0] : ''}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData(
                              produce((draft) => {
                                const cert = draft.userImprintCapabilitiesFunctionStats[statIndex].certifications[certIndex];
                                if (new Date(value) >= new Date(cert.certificationDate)) {
                                  cert.validTill = value;
                                }
                              })
                            );
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-orange-300"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Recognitions Section */}
              <div id={`recognitions-${statIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                <div className="flex justify-between items-center mb-2">
                    <p className="font-bold text-sm">Recognitions</p>
                    <div className='flex justify-start items-center'>
                        <p className='text-xs font-bold mx-1 px-1'>Add</p>
                        <button
                          type="button"
                          className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                          onClick={() => {
                            setFormData(
                              produce((draft) => {
                                draft.userImprintCapabilitiesFunctionStats[statIndex].recognitions.push({
                                  recognitionName: "",
                                  recognitionDesc: "",
                                  recognitionOrg: "",
                                  recognitionDate: "",
                                });
                              })
                            );
                          }}
                        >
                          <PlusIcon className="h-4 w-4 inline" />
                        </button>
                    </div>
                  
                </div>

                {/* Recognitions List */}
                {stat.recognitions.map((rec, recIndex) => (
                  <div key={`${statIndex}-${recIndex}`} className="p-2 border rounded mb-2 bg-white">
                    <div className="flex justify-between items-center">
                        <p className="font-bold text-sm">Recognition {recIndex + 1}</p>
                        <div className='flex justify-start items-center'>
                            <p className='text-xs font-bold mx-1 px-1'>Remove</p>
                            <button
                            type="button"
                            className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                            onClick={() => {
                              setFormData(
                                produce((draft) => {
                                  draft.userImprintCapabilitiesFunctionStats[statIndex].recognitions.splice(recIndex, 1);
                                })
                              );
                            }}
                          >
                            <MinusIcon className="h-4 w-4 inline" />
                          </button>
                        </div>

                    </div>

                    {/* Recognition Fields */}
                    <input
                      type="text"
                      placeholder="Recognition Name"
                      value={rec.recognitionName}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData(
                          produce((draft) => {
                            draft.userImprintCapabilitiesFunctionStats[statIndex].recognitions[recIndex].recognitionName = value;
                          })
                        );
                      }}
                      className="w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-orange-300 mt-2"
                    />

                    <EtContentEditorLight2
                      value={rec.recognitionDesc}
                      handleChange={(content) => {
                        setFormData(
                          produce((draft) => {
                            draft.userImprintCapabilitiesFunctionStats[statIndex].recognitions[recIndex].recognitionDesc = content;
                          })
                        );
                      }}
                      placeholderText="Write recognition details here..."
                      editorKey={`recognitionDesc-${statIndex}-${recIndex}`}
                    />

                    <div className="flex justify-between items-center">
                      <div className="flex flex-col m-1 p-1 w-full">
                        <label
                          htmlFor={`recognitionOrg-${statIndex}-${recIndex}`}
                          className="text-gray-600 text-sm font-bold"
                        >
                          Recognizing Org
                        </label>
                        <input
                          type="text"
                          id={`recognitionOrg-${statIndex}-${recIndex}`}
                          placeholder="Enter recognizing organization"
                          value={rec.recognitionOrg}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData(
                              produce((draft) => {
                                draft.userImprintCapabilitiesFunctionStats[statIndex].recognitions[recIndex].recognitionOrg = value;
                              })
                            );
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-orange-300"
                        />
                      </div>

                      <div className="flex flex-col m-1 p-1 w-full">
                        <label
                          htmlFor={`recognitionDate-${statIndex}-${recIndex}`}
                          className="text-gray-600 text-sm font-bold"
                        >
                          Recognition Date
                        </label>
                        <input
                          type="date"
                          id={`recognitionDate-${statIndex}-${recIndex}`}
                          value={rec.recognitionDate ? rec.recognitionDate.split('T')[0] : ''}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData(
                              produce((draft) => {
                                draft.userImprintCapabilitiesFunctionStats[statIndex].recognitions[recIndex].recognitionDate = value;
                              })
                            );
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-orange-300"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
          </div>
      ))}

      {/* Notes Section */}
      <div id="selfNotes" className="border rounded p-2 m-2 bg-gray-50">
        <div className="flex justify-between items-center mb-2">
            <p className="font-bold text-sm">Notes</p>
            <div className='flex justify-start items-center'>
                <p className='text-xs font-bold mx-1 px-1'>Add</p>
                <button
                type="button"
                className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                onClick={() => {
                  setFormData(
                    produce((draft) => {
                      draft.userImprintCapabilitiesFunctionSelfNotes.push({
                        userImprintCapabilitiesFunctionSelfNoteText: "",
                        userImprintCapabilitiesFunctionSelfNoteDate: new Date().toISOString(),
                        userImprintCapabilitiesFunctionSelfNoteUpdateDate: null,
                      });
                    })
                  );
                }}
              >
                <PlusIcon className="h-4 w-4 inline" />
              </button>
            </div>
          
        </div>

        {formData.userImprintCapabilitiesFunctionSelfNotes?.map((note, noteIndex) => (
          <div key={noteIndex} className="p-2 border rounded mb-2 bg-white">
            <div className="flex justify-between items-center">
                  <p className="font-bold text-sm">Self Note: {noteIndex + 1}</p>
              <div className='flex justify-start items-center'>
                  <p className='text-xs font-bold mx-1 px-1'>Remove</p>
                  <button
                    type="button"
                    className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                    onClick={() => {
                      setFormData(
                        produce((draft) => {
                          draft.userImprintCapabilitiesFunctionSelfNotes.splice(noteIndex, 1);
                        })
                      );
                    }}
                  >
                    <MinusIcon className="h-4 w-4 inline" />
                  </button>
              </div>
              
            </div>

            {/* Note Text */}
            <EtContentEditorLight2
              value={note.userImprintCapabilitiesFunctionSelfNoteText}
              handleChange={(content) => {
                setFormData(
                  produce((draft) => {
                    draft.userImprintCapabilitiesFunctionSelfNotes[noteIndex].userImprintCapabilitiesFunctionSelfNoteText = content;
                    draft.userImprintCapabilitiesFunctionSelfNotes[noteIndex].userImprintCapabilitiesFunctionSelfNoteUpdateDate = new Date().toISOString();
                  })
                );
              }}
              placeholderText="Write your note here..."
              editorKey={`note-${noteIndex}`}
            />

            {/* Note Date (Read-Only) */}
            <p className="text-xs text-gray-500 mt-2">
              Created on: {new Date(note.userImprintCapabilitiesFunctionSelfNoteDate).toLocaleDateString()}
              {note.userImprintCapabilitiesFunctionSelfNoteUpdateDate && (
                <span>
                  , Updated on: {new Date(note.userImprintCapabilitiesFunctionSelfNoteUpdateDate).toLocaleDateString()}
                </span>
              )}
            </p>
          </div>
        ))}
      </div>

      <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Update"}
                type={"create"}
                handleClick={handleSubmit}
              />
              <EtBtnFormBasic
                name={"Clear"}
                type={"clear"}
                handleClick={handleClear}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>

    </form>

    </div>
  )
}

export default EtUpdateUserImprintCapabilitiesFunctionComp