import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const userImprintCapabilitiesTechStacksAdapter = createEntityAdapter({})

const initialState = userImprintCapabilitiesTechStacksAdapter.getInitialState()

export const userImprintCapabilitiesTechStacksApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserImprintCapabilitiesTechStacks: builder.query({
            query: () => ({
                url: '/userImprintCapabilitiesTechStacks/getUserImprintCapabilitiesTechStacks',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserImprintCapabilitiesTechStacks = responseData.map(userImprintCapabilitiesTechStack => {
                    userImprintCapabilitiesTechStack.id = userImprintCapabilitiesTechStack._id
                    return userImprintCapabilitiesTechStack
                })
                return userImprintCapabilitiesTechStacksAdapter.setAll(initialState, loadedUserImprintCapabilitiesTechStacks)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserImprintCapabilitiesTechStack', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserImprintCapabilitiesTechStack', id}))
                    ]
                } else {
                    return [ { type: 'UserImprintCapabilitiesTechStack', id: 'LIST'}]
                }
            },
        }),
        createNewUserImprintCapabilitiesTechStack: builder.mutation({
            query: initialUserImprintCapabilitiesTechStack => ({
                url: '/userImprintCapabilitiesTechStacks/createNewUserImprintCapabilitiesTechStack',
                method: 'POST',
                body: {...initialUserImprintCapabilitiesTechStack,}
            }),
            invalidatesTags: [
                { type: 'UserImprintCapabilitiesTechStack', id: "LIST" }
            ] 
        }),
        updateUserImprintCapabilitiesTechStack: builder.mutation({
            query: initialUserImprintCapabilitiesTechStack => ({
                url: '/userImprintCapabilitiesTechStacks/updateUserImprintCapabilitiesTechStack',
                method: 'PATCH',
                body: {...initialUserImprintCapabilitiesTechStack,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserImprintCapabilitiesTechStack', id: arg.id },
                { type: 'UserImprintCapabilitiesTechStack', id: 'LIST' }
            ],
        }),
        deleteUserImprintCapabilitiesTechStack: builder.mutation({
            query: ({id}) => ({
                url: '/userImprintCapabilitiesTechStacks/deleteUserImprintCapabilitiesTechStack',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserImprintCapabilitiesTechStack', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserImprintCapabilitiesTechStacksQuery,
    useCreateNewUserImprintCapabilitiesTechStackMutation,
    useUpdateUserImprintCapabilitiesTechStackMutation,
    useDeleteUserImprintCapabilitiesTechStackMutation,
} = userImprintCapabilitiesTechStacksApiSlice

// returns the query result object
export const selectUserImprintCapabilitiesTechStackResult = userImprintCapabilitiesTechStacksApiSlice.endpoints.getUserImprintCapabilitiesTechStacks.select()

// create memoized selector
const selectUserImprintCapabilitiesTechStacksData = createSelector(
    selectUserImprintCapabilitiesTechStackResult,
    userImprintCapabilitiesTechStackResult => userImprintCapabilitiesTechStackResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserImprintCapabilitiesTechStacks,
    selectById: selectUserImprintCapabilitiesTechStackById,
    selectIds: selectUserImprintCapabilitiesTechStackIds,
} = userImprintCapabilitiesTechStacksAdapter.getSelectors(state => selectUserImprintCapabilitiesTechStacksData(state) ?? initialState)