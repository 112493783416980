import React, { useState } from 'react';
import useTitle from '@/hooks/useTitle';
import { useNavigate } from 'react-router';
import { addIcon } from '@/assets/img/imgAssets';
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import {
    ClockIcon,
    UserGroupIcon,
    EyeIcon,
    SwatchIcon,
    WrenchIcon,
    SunIcon,
  } from "@heroicons/react/24/solid"
import PulseLoader from "react-spinners/PulseLoader";
import { useGetChallengesQuery } from './challengesApiSlice';

const EtChallengeList = ({ setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }) => {
    // First Created - 16 Aug 2024
    // Author - Atul Pandey
    // Updated - 17 Aug 2024 - added challenge intro, outro, target audience, scenarios, instructions
    // Updated - 18 Aug 2024 - refined data display - added next level show / toggles
    // Updated - 20 Aug 2024 - refined for changed data structure - challengeDimId to fetch challenge dimensions data
    // Updated - 28 Feb 2025 - updated the softSkillFunctionArea with props - { setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }
  // Updated - 28 Feb 2025 - switched from navigate to onAdd, onUpdate, onDelete, setSelectedEntityId

    // 0 - Set Page Title
    useTitle('Challenges List');

    // 1 - Set Page Navigation
    const navigate = useNavigate();

    // 2 - Initialize showDetails state as an object where key is the challenge index
    const [showDetails, setShowDetails] = useState({});
    const [showIntroDetails, setShowIntroDetails] = useState({}) // 18 Aug 2024
    const [showAudienceDetails, setShowAudienceDetails] = useState({}) // 18 Aug 2024
    const [showScenarioDetails, setShowScenarioDetails] = useState({}) // 18 Aug 2024
    const [showInstructionDetails, setShowInstructionDetails] = useState({}) // 18 Aug 2024
    const [showChallengeDimDetails, setShowChallengeDimDetails] = useState({}) // 18 Aug 2024
    

    // 3 - Extract the challenges data
    const { data: challengesData, isError, error, isLoading } = useGetChallengesQuery();
    // console.log('challengesData', challengesData);
    
    // 4a - Toggle Show function for challenge details
    const handleToggleDetails = (challengeId) => {
        setShowDetails(prev => ({ ...prev, [challengeId]: !prev[challengeId] }));
    };

    // 4b - Toggle Show function for intro details
    const handleToggleIntroDetails = (challengeId) => {
        setShowIntroDetails(prev => ({ ...prev, [challengeId]: !prev[challengeId] }));
    };

    // 4c - Toggle Show function for audience details
    const handleToggleAudienceDetails = (challengeId) => {
        setShowAudienceDetails(prev => ({ ...prev, [challengeId]: !prev[challengeId] }));
    };

    // 4d - Toggle Show function for scenario details
    const handleToggleScenarioDetails = (challengeId) => {
        setShowScenarioDetails(prev => ({ ...prev, [challengeId]: !prev[challengeId] }));
    };

    // 4e - Toggle Show function for instruction details
    const handleToggleInstructionDetails = (challengeId) => {
        setShowInstructionDetails(prev => ({ ...prev, [challengeId]: !prev[challengeId] }));
    };

    // 4f - Toggle Show function for challenge dimension details
    const handleToggleChallengeDimDetails = (challengeId) => {
        setShowChallengeDimDetails(prev => ({ ...prev, [challengeId]: !prev[challengeId] }));
    };

    // 5 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };

    // 6 - Handle loading and error states
    if (isLoading) return <PulseLoader color={"#ea580c"} />;
    if (isError) return <div>Error occurred: {error?.data?.message}</div>;

    // 7 - Mapping challenges
    const challenges = challengesData?.ids.map(id => {
        const challenge = challengesData?.entities[id];
        const { 
            _id,
            challengeName,
            challengeDesc, 
            challengeTypeId, 
            challengeDimensions,
            challengeIntro,
            challengeOutro,
            targetAudience, 
            challengeScenarios,
            challengeInstructions,
            pic, 
            aboutMe,
            createdAt,
        } = challenge;

        return {
            _id,
            challengeName,
            challengeDesc, 
            challengeTypeId, 
            challengeDimensions, 
            challengeIntro,
            challengeOutro,
            targetAudience, 
            challengeScenarios,
            challengeInstructions,
            pic, 
            aboutMe,
            createdAt,
        };
    });
    // console.log('challenges', challenges);

    return (
        <div className='bg-gray-200 m-1 p-1 rounded font-poppins mx-auto'>
            {/* Header */}
            <div className='flex justify-start items-center m-1 p-1'>
                <div><p className='text-2xl m-1 p-1 font-bold'>Et Challenges</p></div>
                <div>
                    <img
                        src={addIcon}
                        alt=""
                        className="h-10 w-10 cursor-pointer"
                        // onClick={() => navigate("/challenges/create")}
                        onClick={onAdd} // 28 Feb 2025
                    />
                </div>
            </div>

            {challenges && challenges?.map((item, index) => (
                <div key={item+index} className='m-1 p-1 bg-white rounded'>

                    <div className='m-1 p-1 bg-gray-300 rounded '>
                        
                        <div className='grid grid-cols-12 justify-between items-center'>
                            <div className='col-span-4 flex justify-start items-center mx-2'>
                                <div
                                    onClick={() => handleToggleDetails(item._id)}
                                    className="m-1 p-1 cursor-pointer rounded-full h-7 w-7 flex justify-center items-center hover:bg-white"
                                >
                                    {showDetails[item._id] ? <MinusIcon className="h-6 w-6 text-orange-600" /> : <PlusIcon className="h-4 w-4 text-orange-600" />}
                                </div>

                                <div className='m-1 p-1'>
                                    <p className='font-bold'>{index+1} - {item.challengeName}</p>
                                    <p className='text-[10px] italic'>{item.challengeDesc}</p>
                                </div>
                            </div>

                            <div className='col-span-3 flex justify-center items-center mx-2'>
                                <div className=''><p className='text-xs font-bold text-gray-600 mx-1'>Mode:</p></div>
                                <div className=''><p className='text-[10px] text-gray-600 mx-1'>{item?.challengeTypeId?.challengeTypeName}</p></div>
                                
                            </div>

                            <div className='col-span-3 flex justify-center items-center mx-2'>
                                <div className=''><p className='text-xs font-bold text-gray-600 mx-1'>Created On:</p></div>
                                <div><ClockIcon className='h-5 w-5 text-lime-600'/></div>
                                <div className=''><p className='text-[10px] text-gray-600 mx-1'>{item?.createdAt ? formatDate(item?.createdAt) : 'N/A'}</p></div>
                            </div>

                            <div className='col-span-2 flex justify-end items-center mx-2'>
                                <div className='rounded-full flex justify-center items-center h-6 w-6 hover:bg-white'>
                                    <PencilSquareIcon 
                                    
                                    // onClick={() => navigate(`/challenges/update/${item?._id}`)}
                                    onClick={() => onUpdate(item?._id)} // 28 Feb 2025 
                                    
                                    
                                    className="h-4 w-4 text-orange-600 cursor-pointer" />
                                </div>
                                <div className='rounded-full flex justify-center items-center h-6 w-6 hover:bg-white'>
                                    <TrashIcon 
                                    
                                    // onClick={() => navigate(`/challenges/delete/${item?._id}`)} 
                                    onClick={() => onDelete(item?._id)} // 28 Feb 2025 
                                    
                                    
                                    className="h-4 w-4 text-orange-600 cursor-pointer" />
                                </div>
                            </div>
                        </div>
                    
                        {showDetails[item._id] && (
                            <div className=''>
                                {/* Challenge Details */}
                                <div className='m-1 p-1 rounded bg-white'>
                                    {/*Section 1 - Purpose  */}
                                    <div className='m-1 p-1 rounded bg-gray-100'>
                                        <div className='flex justify-between items-center'>
                                            <div className='flex justify-start items-center m-1 p-1'>
                                                <div className='m-1 p-1 cursor-pointer rounded-full h-6 w-6 flex justify-center items-center hover:bg-white' onClick={() => handleToggleIntroDetails(item._id)}>
                                                        {showIntroDetails[item._id] ? (
                                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                                        )}
                                                    </div>
                                                <div className='rounded-full h-7 w-7 flex justify-center items-center hover:bg-white'><EyeIcon className='h-6 w-6 text-orange-600'/></div>
                                                <div><p className='mx-1 px-1 text-gray-500 font-bold'>Purpose</p></div>
                                            </div> 
                                        </div>
                                        {item?.challengeIntro && showIntroDetails[item._id] && (
                                            <div className='bg-white rounded m-1 p-1'>
                                                    <div className=''>
                                                        <p className='m-1 p-1 text-xs'>{item?.challengeIntro}</p>
                                                    </div>
                                            </div>
                                        )}
                                    </div>
                                                                      
                                     {/*Section 2 - Target Audience  */}
                                     <div className='m-1 p-1 rounded bg-gray-100'>
                                        <div className='flex justify-between items-center'>
                                            <div className='flex justify-start items-center m-1 p-1'>
                                                <div className='m-1 p-1 cursor-pointer rounded-full h-6 w-6 flex justify-center items-center hover:bg-white' onClick={() => handleToggleAudienceDetails(item._id)}>
                                                        {showAudienceDetails[item._id] ? (
                                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                                        )}
                                                    </div>
                                                    <div className='rounded-full h-7 w-7 flex justify-center items-center hover:bg-white'><UserGroupIcon className='h-6 w-6 text-orange-600'/></div>
                                                    <div><p className='mx-1 px-1 text-gray-500 font-bold'>Audience</p></div>
                                            </div> 
                                        </div>
                                        {item?.targetAudience && showAudienceDetails[item._id] && item?.targetAudience.length >0 && (
                                            <div className='bg-white m-1 p-1 rounded'>
                                            {item?.targetAudience?.map((audience, index) => (
                                                <div key={index} className='rounded m-1 p-1 bg-gray-200 inline-block'>
                                                    <p className='text-[10px] mx-1 px-1'>{audience?.audience}</p>
                                                </div>
                                            ))}
                                            </div>
                                        )}
                                    </div>
                                   
                                    {/*Section 3 - Challenge Scenarios  */}
                                    <div className='m-1 p-1 rounded bg-gray-100'>
                                        <div className='flex justify-between items-center'>
                                            <div className='flex justify-start items-center m-1 p-1'>
                                                <div className='m-1 p-1 cursor-pointer rounded-full h-6 w-6 flex justify-center items-center hover:bg-white' onClick={() => handleToggleScenarioDetails(item._id)}>
                                                        {showScenarioDetails[item._id] ? (
                                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                                        )}
                                                    </div>
                                                    <div className='rounded-full h-7 w-7 flex justify-center items-center hover:bg-white'><SwatchIcon className='h-6 w-6 text-orange-600'/></div>
                                                    <div><p className='mx-1 px-1 text-gray-500 font-bold'>Scenarios</p></div>
                                            </div> 
                                        </div>
                                        {item?.challengeScenarios && showScenarioDetails[item._id] && item?.challengeScenarios.length>0 && (
                                            <div className='bg-white m-1 p-1 rounded'>
                                                {item?.challengeScenarios?.map((scenario, index) => (
                                                <div key={index} className='rounded bg-gray-200 inline-block m-1 p-1'>
                                                    <div className='mx-1 p-1'><p className='font-bold mx-1 px-1'>Scenario:</p></div>
                                                    <div className='m-1 p-1'><p className='text-[10px] mx-1 px-1' dangerouslySetInnerHTML={createMarkup(scenario?.scenario)}></p></div>
                                                </div>
                                                ))}
                                            </div>
                                        )}

                                    </div>

                                    {/*Section 4 - Challenge Instructions  */}
                                    <div className='m-1 p-1 rounded bg-gray-100'>
                                        <div className='flex justify-between items-center'>
                                            <div className='flex justify-start items-center m-1 p-1'>
                                                <div className='m-1 p-1 cursor-pointer rounded-full h-6 w-6 flex justify-center items-center hover:bg-white' onClick={() => handleToggleInstructionDetails(item._id)}>
                                                        {showInstructionDetails[item._id] ? (
                                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                                        )}
                                                    </div>
                                                    <div className='rounded-full h-7 w-7 flex justify-center items-center hover:bg-white'><WrenchIcon className='h-6 w-6 text-orange-600'/></div>
                                                    <div><p className='mx-1 px-1 text-gray-500 font-bold'>Instructions</p></div>
                                            </div> 
                                        </div>
                                        
                                        {item?.challengeInstructions && showInstructionDetails[item._id] && item?.challengeInstructions.length >0 && (
                                            <div className='bg-white m-1 p-1 rounded'>
                                                {item?.challengeInstructions?.map((instruction, index) => (
                                                    <div key={index} className='rounded m-1 p-1 bg-gray-200'>
                                                        <div className='m-1 p-1 flex justify-start items-center'>
                                                            <div><p className='text-xs mx-1 px-1 font-bold'>{index+1}.</p></div>
                                                            <div><p className='text-[11px] mx-1 px-1'>{instruction?.instruction}</p></div>
                                                        </div>
                                                        
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    
                                    {/*Section 5 - Challenge Dimensions  */}
                                    <div className='m-1 p-1 rounded bg-gray-100'>
                                        <div className='flex justify-between items-center'>
                                            <div className='flex justify-start items-center m-1 p-1'>
                                                <div className='m-1 p-1 cursor-pointer rounded-full h-6 w-6 flex justify-center items-center hover:bg-white' onClick={() => handleToggleChallengeDimDetails(item._id)}>
                                                        {showChallengeDimDetails[item._id] ? (
                                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                                        ) : (
                                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                                        )}
                                                    </div>
                                                    <div className='rounded-full h-7 w-7 flex justify-center items-center hover:bg-white'><SunIcon className='h-6 w-6 text-orange-600'/></div>
                                                    <div><p className='mx-1 px-1 text-gray-500 font-bold'>Challenge Dimensions</p></div>
                                            </div> 
                                        </div>
                                        {item?.challengeDimensions && showChallengeDimDetails[item._id] && item?.challengeDimensions.length >0 && (
                                            <div className='m-1 p-1 rounded bg-white'>
                                                {/* Group Challenge Dimensions by Challenge Head */}
                                                {Object.values(item?.challengeDimensions?.reduce((acc, dimension) => {
                                                    const headId = dimension.challengeDimId?.challengeHeadId?._id;
                                                    if (!acc[headId]) {
                                                        acc[headId] = {
                                                            headName: dimension.challengeDimId?.challengeHeadId?.challengeHeadName,
                                                            dimensions: [],
                                                            showDimensions: false,
                                                        };
                                                    }
                                                    acc[headId].dimensions.push(dimension);
                                                    return acc;
                                                }, {})).map((headData, headIndex) => (
                                                    <div key={headIndex} className='bg-gray-300 m-1 p-1 rounded'>
                                                        <div className='flex justify-start items-center cursor-pointer' onClick={() => setShowDetails(prev => ({
                                                            ...prev,
                                                            [item._id]: {
                                                                ...prev[item._id],
                                                                [headData.headName]: !prev[item._id]?.[headData.headName],
                                                            }
                                                        }))}>
                                                            <div className='m-1 p-1 cursor-pointer rounded-full h-7 w-7 flex justify-center items-center hover:bg-white'>
                                                                {showDetails[item._id]?.[headData.headName] ? (
                                                                    <MinusIcon className="h-5 w-5 text-orange-600" />
                                                                ) : (
                                                                    <PlusIcon className="h-5 w-5 text-orange-600" />
                                                                )}
                                                            </div>
                                                            <p className='text-xs font-bold mx-2'>{headIndex + 1}. {headData.headName}</p>
                                                            
                                                        </div>
                                                        {showDetails[item._id]?.[headData.headName] && (
                                                            <div className='ml-4'>
                                                                {headData.dimensions?.map((dimension, dimIndex) => (
                                                                    <div key={dimension._id+dimIndex} className='bg-gray-100 m-1 p-1 rounded'>
                                                                        <div className='flex justify-start items-center m-1 p-1'>
                                                                            <div><p className='text-4xl text-gray-400 font-bold mx-1 px-1'>{dimIndex +1}.</p></div>
                                                                            <div>
                                                                                <div><p className='text-[10px] mx-1 px-1'><span className='font-bold'>{dimension.challengeDimId?.challengeDimensionName}</span> </p></div>
                                                                                <div><p className='text-[10px] mx-1 px-1 italic'>{dimension.challengeDimId?.challengeDimensionDesc}</p></div>
                                                                            </div>
                                                                        </div>   
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>                                   
                                </div>
                            </div>
                        )}
                  </div>
                </div>
            ))}
        </div>
    );
};

// Function to format the date
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'long', year: 'numeric' };
    const day = date.getDate();
  
    // Determine the correct ordinal indicator
    const ordinal = (n) => {
        let s = ["th", "st", "nd", "rd"],
            v = n % 100;
        return s[(v - 20) % 10] || s[v] || s[0];
    };
  
    return (
        <>
            {day}<sup>{ordinal(day)}</sup> {new Intl.DateTimeFormat('en-GB', options).format(date)}
        </>
    );
};

export default EtChallengeList;