import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import Modal from "@/components/common/modal/Modal"
import EtContentEditorLight from "@/utils/EtContentEditorLight"
import { useGetQuestionWithScoresQuery, useDeleteQuestionWithScoresMutation } from "./questionwithscoresApiSlice"

const EtDeleteQuestionWithScores = ({id, setActiveAdminView}) => {

  // First Created - 18 Apr 2024
  // Author - Atul Pandey
  // Updated - 01 Mar 2025 - updated the softSkillFunctionArea with props - { id, setActiveAdminView}
  // Updated - 01 Mar 2025 - switched from navigate to setActiveAdminView


  // 1 - Set Page Title
useTitle('Delete a Question')

// 2 - extract the question Id from the url
// const { id } = useParams()

// 3 - Extract the question from the query to load the question data
const { question } = useGetQuestionWithScoresQuery('Question', {
  selectFromResult: ({ data }) => ({
    question: data?.entities[id]
  })
}) 

// 4 - Check for returned data
if(!question) {
    return <PulseLoader color={"#ea580c"} />
}

// 5. Display data
let content
content = <EtDeleteQuestionWithScoresForm quest={question} id={id} setActiveAdminView={setActiveAdminView}/> // 01 Mar 2025 - added setActiveAdminView={setActiveAdminView}

return content

}

const EtDeleteQuestionWithScoresForm = ({quest, id, setActiveAdminView}) => {
  
  // 1 - set the navigate hook
  const navigate = useNavigate()

  // 1a - Delete confirmation Modal
  const [isModalOpen, setIsModalOpen] = useState(false)

  // 2 - mutation to delete question
  const[deleteQuestionWithScores, {
      isLoading,
      isSuccess,
      isError,
      error,
  }] = useDeleteQuestionWithScoresMutation()

  // 3 - Set the data received in the component props to the local variables
  const [formData, setFormData] = useState({
    questionName: quest.questionName,
  })

// 4. Reset the input field and navigation based on the success state 
useEffect(() => {
    if(isSuccess) {
        setFormData({
            questionName: "",
          })
       // navigate("/questionswithscores")
    setActiveAdminView('questionWithScores'); // 01 Mar 2025
  }
  
  },[isSuccess])// ✅ 01 Mar 2025 - Removed `navigate` since it's no longer used.

// 5. Form field updates / change handling

// 5a - Modal management functions
const handleOpenModal = () => setIsModalOpen(true)
const handleCloseModal = () => setIsModalOpen(false)


// 6. Form Submit logic:
const handleConfirm = async (e) => {
e.preventDefault()
handleCloseModal()

// Initialize an object to collect the form data
const questionData = {
  questionName: formData.questionName,
}
  // No image, send other data as is
  try {
    questionData.id = id
    console.log("Form submitted.")
    await deleteQuestionWithScores(questionData).unwrap() // Dispatch the RTK Query mutation
  } catch (error) {
    console.error(error)
  }

}

// 7 - Handle Cancel
const handleCancel = () => {
 // navigate("/questionswithscores")
 setActiveAdminView('questionWithScores'); // 01 Mar 2025
}

// 8 - Handle Editor Content Change
const handleContentChange = (newContent) => {
setFormData(prevFormData => ({ ...prevFormData, questionName: newContent }));
}

return (
  <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
  {isLoading && <PulseLoader color={"#ea580c"} />}
  {isError && (
    <p className="text-orange-700 font-semi-bold py-2">
      {error?.data?.message}
    </p>
  )}
  <div className="bg-white p-1 m-1 rounded">
    <p className="p-1 m-1 font-bold font-poppins text-xl">
      Delete A Question
    </p>

    <div className="bg-gray-200 rounded m-1 p-1">
      <form
        onSubmit={handleOpenModal}
        className="m-1 p-1 text-xs font-poppins"
      >
        <div className="bg-white w-full rounded p-2 m-1">
          
          <EtContentEditorLight 
          value={formData.questionName}
          handleChange={handleContentChange}
          />
  
        </div>

        <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
          <EtBtnFormBasic
            name={"Delete"}
            type={"create"}
            handleClick={handleOpenModal}
          />
         
          <EtBtnFormBasic
            name={"Cancel"}
            type={"cancel"}
            handleClick={handleCancel}
          />
        </div>
      </form>
    </div>
  </div>
  <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleConfirm} />
</div>
)


}

export default EtDeleteQuestionWithScores