import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userVisionPartnersAdapter = createEntityAdapter({})

const initialState = userVisionPartnersAdapter.getInitialState()

export const userVisionPartnersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserVisionPartners: builder.query({
            query: () => ({
                url: '/userVisionPartners/getUserVisionPartners',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserVisionPartners = responseData.map(userVisionPartner => {
                    userVisionPartner.id = userVisionPartner._id
                    return userVisionPartner
                })
                return userVisionPartnersAdapter.setAll(initialState, loadedUserVisionPartners)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserVisionPartner', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserVisionPartner', id}))
                    ]
                } else {
                    return [ { type: 'UserVisionPartner', id: 'LIST'}]
                }
            },
        }),
        createNewUserVisionPartner: builder.mutation({
            query: initialUserVisionPartner => ({
                url: '/userVisionPartners/createNewUserVisionPartner',
                method: 'POST',
                body: {...initialUserVisionPartner,}
            }),
            invalidatesTags: [
                { type: 'UserVisionPartner', id: "LIST" }
            ] 
        }),
        updateUserVisionPartner: builder.mutation({
            query: initialUserVisionPartner => ({
                url: '/userVisionPartners/updateUserVisionPartner',
                method: 'PATCH',
                body: {...initialUserVisionPartner,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserVisionPartner', id: arg.id },
                { type: 'UserVisionPartner', id: 'LIST' }
            ],
        }),
        deleteUserVisionPartner: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userVisionPartners/deleteUserVisionPartner',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userVisionPartners/deleteUserVisionPartner',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserVisionPartner', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserVisionPartnersQuery,
    useCreateNewUserVisionPartnerMutation,
    useUpdateUserVisionPartnerMutation,
    useDeleteUserVisionPartnerMutation,
} = userVisionPartnersApiSlice

// returns the query result object
export const selectUserVisionPartnerResult = userVisionPartnersApiSlice.endpoints.getUserVisionPartners.select()

// create memoized selector
const selectUserVisionPartnersData = createSelector(
    selectUserVisionPartnerResult,
    userVisionPartnerResult => userVisionPartnerResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserVisionPartners,
    selectById: selectUserVisionPartnerById,
    selectIds: selectUserVisionPartnerIds,
} = userVisionPartnersAdapter.getSelectors(state => selectUserVisionPartnersData(state) ?? initialState)