import React, { useState, useEffect } from 'react'
import useTitle from "../../../hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
    PencilSquareIcon,
  } from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "../../../utils/EtContentEditorLight2"
import EtBtnFormBasic from "../../../components/common/buttons/EtBtnFormBasic"
import { useNavigate } from "react-router"
import { useCreateNewUserImprintCapabilitiesDomainMutation } from './userImprintCapabilitiesDomainsApiSlice'
import { useGetCapabilitiesDomainTypesQuery } from '../../capabilitiesDomainTypes/capabilitiesDomainTypesApiSlice'

const EtCreateUserImprintCapabilitiesDomainComp = ({
  userId, 
  userOrgId, 
  triggeringUrl, 
  onClose
}) => {
  // First Created - 21 Nov 2024
  // Author - Atul Pandey
  // Inherited from the EtCreateUserImprintCapabilitiesIndustry component

  // 0 - Set Page title & variables
  useTitle('User Imprint Cpabilities')

  // 1 - set page navigations
  const navigate = useNavigate()
  
  // 2a - set the mutation to create the new user imprint capabilitiesDomain data
  const [createNewUserImprintCapabilitiesDomain, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewUserImprintCapabilitiesDomainMutation()

  // 2b - Get capability domain types
  const { data: domainTypesData} = useGetCapabilitiesDomainTypesQuery()
  const domainTypes = domainTypesData?.ids?.map(id => domainTypesData?.entities[id])
  // console.log('Domain Types', domainTypes)

  // 2c - Domain Types based on focus:
  const businessDomainTypes = domainTypes?.filter(type => type.capabilitiesDomainFocus === "Business") || [];
  const technologyDomainTypes = domainTypes?.filter(type => type.capabilitiesDomainFocus === "Technology") || [];
  console.log('Biz Domain Types', businessDomainTypes)
  console.log('Tech Domain Types', technologyDomainTypes)


  // 3 - Initialize state variables for the form data
  const [formData, setFormData] = useState({
    userImprintCapabilitiesDomainName: "",
    userImprintCapabilitiesDomainDesc: "",
    userImprintCapabilitiesDomainStats: [
      {
        domainType:null,
        yearsOfExperience: 0,
        proficiencyLevel: "Yet to Begin",
        numberOfEngagements:0,
        certifications: [
          {
            certificationName: "",
            certificationDesc: "",
            certificationOrg:"",
            certificationDate: "",
            validTill: "",
          }
        ],
        recognitions: [
          {
            recognitionName: "",
            recognitionDesc: "",
            recognitionOrg: "",
            recognitionDate: "",
          }
        ],
      }
    ],
    userImprintCapabilitiesDomainSelfNotes: [],
    aboutMe: "",
    pic: "",
  })

  // 4 - Initialize state variables for the editor key and value
  const [editorKey, setEditorKey] = useState("capabilitiesDomainDesc-" + Date.now())
  const [editorValue, setEditorValue] = useState("")
  const [showCreateUserImprintCapabilitiesDomain, setShowCreateUserImprintCapabilitiesDomain] = useState(false)
  
  // 5 - Handle form field changes
  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData(prevState => ({ ...prevState, pic: files[0] }))
    } else {
      setFormData(prevState => ({ ...prevState, [name]: value }))
    }
  }

  // 5a - Handle editor value change
  const handleEditorChange = (content) => {
    setEditorValue(content)
    setFormData(prevState => ({ ...prevState, userImprintCapabilitiesDomainDesc: content }))
  }

  // 5b - Generic Handle Add Field Function
  const handleAdd = (fieldName, newItem) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: [...prevState[fieldName], newItem]
    }))
  }

  // 5c - Generic Handle Remove Field Function
  const handleRemove = (fieldName, index) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: prevState[fieldName].filter((_, i) => i !== index)
    }))
  }

  // 5d - Function to convert pic to Base64
  const convertPicToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    }); 
 

  // 6 - Form submit logic
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Step 1: Construct the payload and filter out empty values
    const payload = {
      userId,
      userOrgId,
      userImprintCapabilitiesDomainName: formData.userImprintCapabilitiesDomainName?.trim() || undefined,
      userImprintCapabilitiesDomainDesc: formData.userImprintCapabilitiesDomainDesc?.trim() || undefined,
      userImprintCapabilitiesDomainStats: formData.userImprintCapabilitiesDomainStats.map((stat) => ({
        ...stat,
        certifications: stat.certifications.filter((cert) =>
          Object.values(cert).some((value) => value !== "")
        ),
        recognitions: stat.recognitions.filter((rec) =>
          Object.values(rec).some((value) => value !== "")
        ),
      })),
      userImprintCapabilitiesDomainSelfNotes: formData.userImprintCapabilitiesDomainSelfNotes.filter(
        (note) => note.userImprintCapabilitiesDomainSelfNoteText.trim() !== ""
      ),
      aboutMe: formData.aboutMe?.trim() || undefined,
      pic: formData.pic ? await convertPicToBase64(formData.pic) : undefined, // Convert pic to Base64 if provided
    };
  
    try {
      // Step 2: Call the mutation
      await createNewUserImprintCapabilitiesDomain(payload).unwrap();
  
      // Step 3: Reset the form and handle success
      setFormData({
        userImprintCapabilitiesDomainName: "",
        userImprintCapabilitiesDomainDesc: "",
        userImprintCapabilitiesDomainStats: [
          {
            domainType: null,
            yearsOfExperience: 0,
            proficiencyLevel: "Yet to Begin",
            numberOfEngagements: 0,
            certifications: [],
            recognitions: [],
          },
        ],
        userImprintCapabilitiesDomainSelfNotes: [],
        aboutMe: "",
        pic: "",
      });
      if (onClose) {
        onClose(); // 21 Nov 2024
      } else{
        navigate(triggeringUrl || '/userMagicHome');
      }
      
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  
  

  // 7 - Reset form upon successful submission
  useEffect(() => {
    if(isSuccess) {
      setFormData({
        userImprintCapabilitiesDomainName: "",
      userImprintCapabilitiesDomainDesc: "",
      userImprintCapabilitiesDomainStats: [
        {
          domainType:null,
          yearsOfExperience: 0,
          proficiencyLevel: "Yet to Begin",
          numberOfEngagements:0,
          certifications: [
            {
              certificationName: "",
              certificationDesc: "",
              certificationOrg:"",
              certificationDate: "",
              validTill: "",
            }
          ],
          recognitions: [
            {
              recognitionName: "",
              recognitionDesc: "",
              recognitionOrg: "",
              recognitionDate: "",
            }
          ],
        }
      ],
      userImprintCapabilitiesDomainSelfNotes: [],
      aboutMe: "",
      pic: "",
      })
      // setShowCreateUserImprintCapabilitiesDomain(false)
      if (onClose) {
        onClose(); // 21 Nov 2024
      } else{
        navigate(triggeringUrl || '/userMagicHome');
      }
    }
  
    },[isSuccess,navigate])

  // 8 - Handle Cancel
  const handleCancel = () => {
    // setShowCreateUserImprintCapabilitiesDomain(false)
    if (onClose) {
      onClose(); // 21 Nov 2024
    } else{
      navigate(triggeringUrl || '/userMagicHome');
    }
  }

  // 9 - Handle Clear
  const handleClear = () => {
    setFormData({
      userImprintCapabilitiesDomainName: "",
      userImprintCapabilitiesDomainDesc: "",
      userImprintCapabilitiesDomainStats: [
        {
          domainType:null,
          yearsOfExperience: 0,
          proficiencyLevel: "Yet to Begin",
          numberOfEngagements:0,
          certifications: [
            {
              certificationName: "",
              certificationDesc: "",
              certificationOrg:"",
              certificationDate: "",
              validTill: "",
            }
          ],
          recognitions: [
            {
              recognitionName: "",
              recognitionDesc: "",
              recognitionOrg: "",
              recognitionDate: "",
            }
          ],
        }
      ],
      userImprintCapabilitiesDomainSelfNotes: [],
      aboutMe: "",
      pic: "",
    })
  }

  return (
    <div className="mx-auto w-full m-1 p-1 bg-white rounded shadow-md shadow-black">
      {isLoading && <PulseLoader color={"#ea580c"} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">
          {error?.data?.message}
        </p>
      )}

      <p className="p-1 m-1 font-bold font-poppins text-xl">Create User Imprint Capabilities - Domain</p>

      
      <form onSubmit={handleSubmit} className="m-1 p-1 text-xs font-poppins">
      {/* 1. userImprintCapabilitiesDomainName */}
      <div id="userImprintCapabilitiesDomainName" className="bg-gray-100 rounded p-1 m-1">
        <label htmlFor="userImprintCapabilitiesDomainName" className="block text-gray-600 text-sm font-bold mb-1">
          Capability Name (Optional)
        </label>
        <input
          type="text"
          name="userImprintCapabilitiesDomainName"
          placeholder="Enter a headline or title"
          value={formData.userImprintCapabilitiesDomainName}
          onChange={handleChange}
          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
        />
      </div>
      
      {/* 2. userImprintCapabilitiesDomainDesc */}
      <div id="userImprintCapabilitiesDomainDesc" className="bg-gray-100 rounded p-1 m-1">
        <label htmlFor="userImprintCapabilitiesDomainDesc" className="block text-gray-600 text-sm font-bold mb-1">
          Capability Description
        </label>
        <EtContentEditorLight2
          value={editorValue}
          handleChange={handleEditorChange}
          placeholderText="Write your capability description here!"
          editorKey={editorKey}
        />
      </div>

      {/* Domain Stats Section */}

      <div className="flex justify-between items-center m-1 p-1">
        <p className="font-bold text-sm mb-2">Domain Stats</p>
        <div className='flex justify-start items-center'>
          <p className='text-xs font-bold mx-1 px-1'>Add</p>
          <button
            type="button"
            className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
            onClick={() => {
              setFormData((prevState) => ({
                ...prevState,
                userImprintCapabilitiesDomainStats: [
                  ...prevState.userImprintCapabilitiesDomainStats,
                  {
                    domainType: null,
                    yearsOfExperience: 0,
                    proficiencyLevel: "Yet to Begin",
                    numberOfEngagements: 0,
                    certifications: [],
                    recognitions: [],
                  },
                ],
              }));
            }}
          >
            <PlusIcon className="h-4 w-4 inline" /> 
          </button>
        </div>
        
      </div>

      {formData.userImprintCapabilitiesDomainStats.map((stat, statIndex) => (
          <div key={statIndex} className="border rounded p-2 m-2 bg-gray-50">
            <div className="flex justify-between items-center mb-2">
                <p className="font-bold text-sm mb-2">Domain Stat {statIndex + 1}</p>
                <div className='flex justify-start items-center'>
                  <p className='text-xs font-bold mx-1 px-1'>Remove</p>
                  <button
                    type="button"
                    className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                    onClick={() => {
                      setFormData((prevState) => {
                        const updatedStats = [...prevState.userImprintCapabilitiesDomainStats];
                        updatedStats.splice(statIndex, 1); // Remove the selected stats
                        return { ...prevState, userImprintCapabilitiesDomainStats: updatedStats };
                      });
                    }}
                  >
                    <MinusIcon className="h-4 w-4 inline" /> 
                  </button>
                </div>
                
            </div>

           

            {/* Domain Type Dropdown */}
            <div id={`domainType-${statIndex}`} className="flex justify-between items-center bg-gray-100 rounded p-1 m-1">
              <label htmlFor={`domainType-${statIndex}`} className="text-gray-600 text-sm font-bold w-1/3">
                Domain Type
              </label>
                <select
                  id={`domainType-${statIndex}`}
                  value={stat.domainType || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormData((prevState) => {
                      const updatedStats = [...prevState.userImprintCapabilitiesDomainStats];
                      updatedStats[statIndex].domainType = value;
                      return { ...prevState, userImprintCapabilitiesDomainStats: updatedStats };
                    });
                  }}
                  className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
                >
                  <option value="" disabled>
                    Select Domain Type
                  </option>
                  <optgroup label="Business Domains">
                    {businessDomainTypes.map((type) => (
                      <option key={type._id} value={type._id}>
                        {type.capabilitiesDomainTypeName}
                      </option>
                    ))}
                  </optgroup>
                  <optgroup label="Technology Domains">
                    {technologyDomainTypes.map((type) => (
                      <option key={type._id} value={type._id}>
                        {type.capabilitiesDomainTypeName}
                      </option>
                    ))}
                  </optgroup>
                </select>

            </div>

              {/* Years of Experience */}
              <div id={`yearsOfExperience-${statIndex}`} className="flex justify-between items-center bg-gray-100 rounded p-1 m-1">
                <label htmlFor={`yearsOfExperience-${statIndex}`} className="text-gray-600 text-sm font-bold w-1/3">
                  Years of Experience
                </label>
                <input
                  type="number"
                  id={`yearsOfExperience-${statIndex}`}
                  name="yearsOfExperience"
                  min="0"
                  value={stat.yearsOfExperience || 0}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10) || 0;
                    setFormData((prevState) => {
                      const updatedStats = [...prevState.userImprintCapabilitiesDomainStats];
                      updatedStats[statIndex].yearsOfExperience = value;
                      return { ...prevState, userImprintCapabilitiesDomainStats: updatedStats };
                    });
                  }}
                  className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
                />
              </div>

              {/* Proficiency Level */}
              <div id={`proficiencyLevel-${statIndex}`} className="flex justify-between items-center bg-gray-100 rounded p-1 m-1">
                <label htmlFor={`proficiencyLevel-${statIndex}`} className="text-gray-600 text-sm font-bold w-1/3">
                  Proficiency Level
                </label>
                <select
                  id={`proficiencyLevel-${statIndex}`}
                  name="proficiencyLevel"
                  value={stat.proficiencyLevel || "Yet to Begin"}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormData((prevState) => {
                      const updatedStats = [...prevState.userImprintCapabilitiesDomainStats];
                      updatedStats[statIndex].proficiencyLevel = value;
                      return { ...prevState, userImprintCapabilitiesDomainStats: updatedStats };
                    });
                  }}
                  className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
                >
                  <option value="Yet to Begin">Yet to Begin</option>
                  <option value="Beginner">Beginner</option>
                  <option value="Advanced">Advanced</option>
                  <option value="Pro">Pro</option>
                  <option value="Expert">Expert</option>
                  <option value="Leader">Leader</option>
                </select>
              </div>

              {/* Number of Engagements */}
              <div id={`numberOfEngagements-${statIndex}`} className="flex justify-between items-center bg-gray-100 rounded p-1 m-1">
                <label htmlFor={`numberOfEngagements-${statIndex}`} className="text-gray-600 text-sm font-bold w-1/3">
                  Number of Engagements
                </label>
                <input
                  type="number"
                  id={`numberOfEngagements-${statIndex}`}
                  name="numberOfEngagements"
                  min="0"
                  value={stat.numberOfEngagements || 0}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10) || 0;
                    setFormData((prevState) => {
                      const updatedStats = [...prevState.userImprintCapabilitiesDomainStats];
                      updatedStats[statIndex].numberOfEngagements = value;
                      return { ...prevState, userImprintCapabilitiesDomainStats: updatedStats };
                    });
                  }}
                  className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
                />
              </div>

              {/* Certifications Section */}
              <div id={`certifications-${statIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                <div className="flex justify-between items-center mb-2">
                    <p className="font-bold text-sm">Certifications</p>
                    <div className='flex justify-start items-center'>
                      <p className='text-xs font-bold mx-1 px-1'>Add</p>
                      <button
                          type="button"
                          className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                          onClick={() => {
                            setFormData((prevState) => {
                              const updatedStats = [...prevState.userImprintCapabilitiesDomainStats];
                              updatedStats[statIndex].certifications.push({
                                certificationName: "",
                                certificationDesc: "",
                                certificationOrg:"",
                                certificationDate: "",
                                validTill: "",
                              });
                              return { ...prevState, userImprintCapabilitiesDomainStats: updatedStats };
                            });
                          }}
                        >
                        <PlusIcon className="h-4 w-4 inline" />
                      </button>

                    </div>
                  
                </div>

                {/* Certifications List */}
                {stat.certifications.map((cert, certIndex) => (
                  <div key={`${statIndex}-${certIndex}`} className="p-2 border rounded mb-2 bg-white">
                    <div className="flex justify-between items-center">
                        <p className="font-bold text-sm">Certification {certIndex + 1}</p>
                        <div className='flex justify-start items-center'>
                            <p className='text-xs font-bold mx-1 px-1'>Remove</p>
                            <button
                            type="button"
                            className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                            onClick={() => {
                              setFormData((prevState) => {
                                const updatedStats = [...prevState.userImprintCapabilitiesDomainStats];
                                updatedStats[statIndex].certifications.splice(certIndex, 1);
                                return { ...prevState, userImprintCapabilitiesDomainStats: updatedStats };
                              });
                            }}
                          >
                            <MinusIcon className="h-4 w-4 inline" />
                          </button>
                        </div>
                      
                    </div>

                    {/* Certification Fields */}
                    <input
                      type="text"
                      placeholder="Certification Name"
                      value={cert.certificationName}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData((prevState) => {
                          const updatedStats = [...prevState.userImprintCapabilitiesDomainStats];
                          updatedStats[statIndex].certifications[certIndex].certificationName = value;
                          return { ...prevState, userImprintCapabilitiesDomainStats: updatedStats };
                        });
                      }}
                      className="w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-orange-300 mt-2"
                    />

                    <EtContentEditorLight2
                      value={cert.certificationDesc}
                      handleChange={(content) => {
                        setFormData((prevState) => {
                          const updatedStats = [...prevState.userImprintCapabilitiesDomainStats];
                          updatedStats[statIndex].certifications[certIndex].certificationDesc = content;
                          return { ...prevState, userImprintCapabilitiesDomainStats: updatedStats };
                        });
                      }}
                      placeholderText="Write certification details here..."
                      editorKey={`certificationDesc-${statIndex}-${certIndex}`}
                    />

                    <div className="flex justify-between items-center">
                      <div className="flex flex-col m-1 p-1 w-full">
                        <label
                          htmlFor={`certificationOrg-${statIndex}-${certIndex}`}
                          className="text-gray-600 text-sm font-bold"
                        >
                          Certifying Organization:
                        </label>
                        <input
                          type="text"
                          id={`certificationOrg-${statIndex}-${certIndex}`}
                          value={cert.certificationOrg}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedStats = [...prevState.userImprintCapabilitiesDomainStats];
                              updatedStats[statIndex].certifications[certIndex].certificationOrg = value;
                              return { ...prevState, userImprintCapabilitiesDomainStats: updatedStats };
                            });
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-orange-300"
                        />
                      </div>

                      <div className="flex flex-col m-1 p-1 w-full">
                        <label
                          htmlFor={`certificationDate-${statIndex}-${certIndex}`}
                          className="text-gray-600 text-sm font-bold"
                        >
                          Certification Date
                        </label>
                        <input
                          type="date"
                          id={`certificationDate-${statIndex}-${certIndex}`}
                          value={cert.certificationDate}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedStats = [...prevState.userImprintCapabilitiesDomainStats];
                              updatedStats[statIndex].certifications[certIndex].certificationDate = value;
                              return { ...prevState, userImprintCapabilitiesDomainStats: updatedStats };
                            });
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-orange-300"
                        />
                      </div>

                      <div className="flex flex-col m-1 p-1 w-full">
                        <label
                          htmlFor={`validTill-${statIndex}-${certIndex}`}
                          className="text-gray-600 text-sm font-bold"
                        >
                          Valid Till
                        </label>
                        <input
                          type="date"
                          id={`validTill-${statIndex}-${certIndex}`}
                          value={cert.validTill}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedStats = [...prevState.userImprintCapabilitiesDomainStats];
                              const selectedCert = updatedStats[statIndex].certifications[certIndex];
                              if (new Date(value) >= new Date(selectedCert.certificationDate)) {
                                selectedCert.validTill = value;
                              }
                              return { ...prevState, userImprintCapabilitiesDomainStats: updatedStats };
                            });
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-orange-300"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Recognitions Section */}
              <div id={`recognitions-${statIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                <div className="flex justify-between items-center mb-2">
                    <p className="font-bold text-sm">Recognitions</p>
                    <div className='flex justify-start items-center'>
                        <p className='text-xs font-bold mx-1 px-1'>Add</p>
                        <button
                          type="button"
                          className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                          onClick={() => {
                            setFormData((prevState) => {
                              const updatedStats = [...prevState.userImprintCapabilitiesDomainStats];
                              updatedStats[statIndex].recognitions.push({
                                recognitionName: "",
                                recognitionDesc: "",
                                recognitionOrg: "",
                                recognitionDate: "",
                              });
                              return { ...prevState, userImprintCapabilitiesDomainStats: updatedStats };
                            });
                          }}
                        >
                          <PlusIcon className="h-4 w-4 inline" />
                        </button>
                    </div>
                  
                </div>

                {/* Recognitions List */}
                {stat.recognitions.map((rec, recIndex) => (
                  <div key={`${statIndex}-${recIndex}`} className="p-2 border rounded mb-2 bg-white">
                    <div className="flex justify-between items-center">
                        <p className="font-bold text-sm">Recognition {recIndex + 1}</p>
                        <div className='flex justify-start items-center'>
                            <p className='text-xs font-bold mx-1 px-1'>Remove</p>
                            <button
                            type="button"
                            className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                            onClick={() => {
                              setFormData((prevState) => {
                                const updatedStats = [...prevState.userImprintCapabilitiesDomainStats];
                                updatedStats[statIndex].recognitions.splice(recIndex, 1);
                                return { ...prevState, userImprintCapabilitiesDomainStats: updatedStats };
                              });
                            }}
                          >
                            <MinusIcon className="h-4 w-4 inline" />
                          </button>
                        </div>

                      
                    </div>

                    {/* Recognition Fields */}
                    <input
                      type="text"
                      placeholder="Recognition Name"
                      value={rec.recognitionName}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData((prevState) => {
                          const updatedStats = [...prevState.userImprintCapabilitiesDomainStats];
                          updatedStats[statIndex].recognitions[recIndex].recognitionName = value;
                          return { ...prevState, userImprintCapabilitiesDomainStats: updatedStats };
                        });
                      }}
                      className="w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-orange-300 mt-2"
                    />

                    <EtContentEditorLight2
                      value={rec.recognitionDesc}
                      handleChange={(content) => {
                        setFormData((prevState) => {
                          const updatedStats = [...prevState.userImprintCapabilitiesDomainStats];
                          updatedStats[statIndex].recognitions[recIndex].recognitionDesc = content;
                          return { ...prevState, userImprintCapabilitiesDomainStats: updatedStats };
                        });
                      }}
                      placeholderText="Write recognition details here..."
                      editorKey={`recognitionDesc-${statIndex}-${recIndex}`}
                    />

                    <div className="flex justify-between items-center">
                      <div className="flex flex-col m-1 p-1 w-full">
                        <label
                          htmlFor={`recognitionOrg-${statIndex}-${recIndex}`}
                          className="text-gray-600 text-sm font-bold"
                        >
                          Recognizing Org
                        </label>
                        <input
                          type="text"
                          id={`recognitionOrg-${statIndex}-${recIndex}`}
                          placeholder="Enter recognizing organization"
                          value={rec.recognitionOrg}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedStats = [...prevState.userImprintCapabilitiesDomainStats];
                              updatedStats[statIndex].recognitions[recIndex].recognitionOrg = value;
                              return { ...prevState, userImprintCapabilitiesDomainStats: updatedStats };
                            });
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-orange-300"
                        />
                      </div>

                      <div className="flex flex-col m-1 p-1 w-full">
                        <label
                          htmlFor={`recognitionDate-${statIndex}-${recIndex}`}
                          className="text-gray-600 text-sm font-bold"
                        >
                          Recognition Date
                        </label>
                        <input
                          type="date"
                          id={`recognitionDate-${statIndex}-${recIndex}`}
                          value={rec.recognitionDate}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedStats = [...prevState.userImprintCapabilitiesDomainStats];
                              updatedStats[statIndex].recognitions[recIndex].recognitionDate = value;
                              return { ...prevState, userImprintCapabilitiesDomainStats: updatedStats };
                            });
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-orange-300"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
          </div>
      ))}
   
      {/* Notes Section */}
      <div id="selfNotes" className="border rounded p-2 m-2 bg-gray-50">
        <div className="flex justify-between items-center mb-2">
            <p className="font-bold text-sm">Notes</p>
            <div className='flex justify-start items-center'>
                <p className='text-xs font-bold mx-1 px-1'>Add</p>
                <button
                type="button"
                className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                onClick={() => {
                  setFormData((prevState) => {
                    // console.log("prevState:", prevState);
                    return {
                      ...prevState,
                      userImprintCapabilitiesDomainSelfNotes: [
                        ...(Array.isArray(prevState.userImprintCapabilitiesDomainSelfNotes)
                          ? prevState.userImprintCapabilitiesDomainSelfNotes
                          : []),
                        {
                          userImprintCapabilitiesDomainSelfNoteText: "",
                          userImprintCapabilitiesDomainSelfNoteDate: new Date().toISOString(),
                          userImprintCapabilitiesDomainSelfNoteUpdateDate: null,
                        },
                      ],
                    };
                  });
                }}
              >
                <PlusIcon className="h-4 w-4 inline" />
              </button>
            </div>
          
        </div>

        {formData.userImprintCapabilitiesDomainSelfNotes?.map((note, noteIndex) => (
          <div key={noteIndex} className="p-2 border rounded mb-2 bg-white">
            <div className="flex justify-between items-center">
              <p className="font-bold text-sm">Self Note: {noteIndex + 1}</p>
              <button
                type="button"
                className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold py-1 px-2 rounded"
                onClick={() => {
                  setFormData((prevState) => ({
                    ...prevState,
                    userImprintCapabilitiesDomainSelfNotes: prevState.userImprintCapabilitiesDomainSelfNotes.filter(
                      (_, i) => i !== noteIndex
                    ),
                  }));
                }}
              >
                <MinusIcon className="h-4 w-4 inline" /> Remove
              </button>
            </div>

            {/* Note Text */}
            <EtContentEditorLight2
              value={note.userImprintCapabilitiesDomainSelfNoteText}
              handleChange={(content) => {
                setFormData((prevState) => {
                  const updatedNotes = [...prevState.userImprintCapabilitiesDomainSelfNotes];
                  updatedNotes[noteIndex].userImprintCapabilitiesDomainSelfNoteText = content;
                  updatedNotes[noteIndex].userImprintCapabilitiesDomainSelfNoteUpdateDate = new Date().toISOString();
                  return { ...prevState, userImprintCapabilitiesDomainSelfNotes: updatedNotes };
                });
              }}
              placeholderText="Write your note here..."
              editorKey={`note-${noteIndex}`}
            />

            {/* Note Date (Read-Only) */}
            <p className="text-xs text-gray-500 mt-2">
              Created on: {new Date(note.userImprintCapabilitiesDomainSelfNoteDate).toLocaleDateString()}
              {note.userImprintCapabilitiesDomainSelfNoteUpdateDate && (
                <span>
                  , Updated on: {new Date(note.userImprintCapabilitiesDomainSelfNoteUpdateDate).toLocaleDateString()}
                </span>
              )}
            </p>
          </div>
        ))}
      </div>

      <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Create"}
                type={"create"}
                handleClick={handleSubmit}
              />
              <EtBtnFormBasic
                name={"Clear"}
                type={"clear"}
                handleClick={handleClear}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>

    </form>

    </div>
  )
}

export default EtCreateUserImprintCapabilitiesDomainComp