
    import React from 'react';
    
    const EtTeamworkIconSVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <g>
  <path d="m1051.6 687.52c-72.238 0-131.04 58.801-131.04 131.04 0 72.281 58.801 131.04 131.04 131.04 72.238 0 131.04-58.801 131.04-131.04 0.003906-72.238-58.797-131.04-131.04-131.04z" fillRule="evenodd"/>
  <path d="m1357.4 1517.5c0-168.6-137.16-305.8-305.8-305.8s-305.8 137.16-305.8 305.8v113.84c76.801 29.922 160.28 46.359 247.52 46.359h364.04l0.003906-160.2z" fillRule="evenodd"/>
  <path d="m993.32 308.96c-377.36 0-684.36 307-684.36 684.36 0 255.76 141.08 479.2 349.48 596.64v-72.441c0-216.8 176.36-393.16 393.16-393.16 216.8 0 393.16 176.36 393.16 393.16v160.16h189.28c24.121 0 43.68-19.559 43.68-43.68l-0.003906-640.68c-0.039062-377.36-307.04-684.36-684.4-684.36zm58.238 728.04c-120.44 0-218.4-97.961-218.4-218.4 0-120.44 97.961-218.4 218.4-218.4 120.44 0 218.4 98 218.4 218.4 0.003907 120.4-97.957 218.4-218.4 218.4z" fillRule="evenodd"/>
  <path d="m2548.4 687.52c-72.238 0-131.04 58.801-131.04 131.04 0 72.281 58.801 131.04 131.04 131.04 72.281 0 131.04-58.801 131.04-131.04 0-72.238-58.762-131.04-131.04-131.04z" fillRule="evenodd"/>
  <path d="m2854.2 1517.5c0-168.6-137.16-305.8-305.8-305.8-168.6 0-305.8 137.16-305.8 305.8v160.16h364.04c87.238 0 170.72-16.441 247.52-46.359l0.003907-113.8z" fillRule="evenodd"/>
  <path d="m2606.7 308.96c-377.36 0-684.36 307-684.36 684.36v640.68c0 24.121 19.559 43.68 43.68 43.68h189.28v-160.16c0-216.8 176.36-393.16 393.16-393.16 216.8 0 393.16 176.36 393.16 393.16v72.441c208.4-117.44 349.48-340.84 349.48-596.64-0.042969-377.36-307.04-684.36-684.4-684.36zm-58.238 728.04c-120.44 0-218.4-97.961-218.4-218.4 0-120.44 97.961-218.4 218.4-218.4 120.44 0 218.4 98 218.4 218.4 0 120.4-97.961 218.4-218.4 218.4z" fillRule="evenodd"/>
  <path d="m2635.8 2432c0 72.371-58.668 131.04-131.04 131.04-72.375 0-131.04-58.668-131.04-131.04 0-72.371 58.668-131.04 131.04-131.04 72.371 0 131.04 58.668 131.04 131.04" fillRule="evenodd"/>
  <path d="m2811.2 3130.9c0-168.6-137.16-305.8-305.8-305.8-168.6 0-305.8 137.16-305.8 305.8v55.48c105.36 66.281 229.96 104.72 363.4 104.72 87.48 0 171.2-16.52 248.2-46.602z" fillRule="evenodd"/>
  <path d="m2563 1922.3h-640.68c-24.121 0-43.68 19.559-43.68 43.68v640.68c0 205 90.602 389.2 233.88 514.72 5.0781-212.4 179.32-383.68 392.92-383.68 216.8 0 393.16 176.36 393.16 393.16v72.078c208.04-117.52 348.84-340.76 348.84-596.28-0.082031-377.36-307.08-684.36-684.44-684.36zm-58.238 728.04c-120.44 0-218.4-97.961-218.4-218.4 0-120.44 97.961-218.4 218.4-218.4 120.44 0 218.4 98 218.4 218.4 0 120.44-97.961 218.4-218.4 218.4z" fillRule="evenodd"/>
  <path d="m1182.6 2432c0 72.371-58.672 131.04-131.04 131.04s-131.04-58.668-131.04-131.04c0-72.371 58.668-131.04 131.04-131.04s131.04 58.668 131.04 131.04" fillRule="evenodd"/>
  <path d="m1356.7 3130.9c0-168.6-137.16-305.8-305.8-305.8-168.6 0-305.8 137.16-305.8 305.8v113.6c77 30.039 160.68 46.602 248.2 46.602 133.4 0 258-38.398 363.4-104.72z" fillRule="evenodd"/>
  <path d="m1634 1922.3h-640.68c-377.36 0-684.36 307-684.36 684.36 0 255.52 140.76 478.72 348.84 596.28v-72.078c0-216.8 176.36-393.16 393.16-393.16 213.6 0 387.84 171.28 392.92 383.68 143.24-125.52 233.88-309.72 233.88-514.72v-640.68c-0.082031-24.121-19.641-43.68-43.762-43.68zm-582.44 728.04c-120.44 0-218.4-97.961-218.4-218.4 0-120.44 97.961-218.4 218.4-218.4 120.44 0 218.4 98 218.4 218.4 0.003907 120.44-97.957 218.4-218.4 218.4z" fillRule="evenodd"/>
 </g>

        </svg>
    );
    
    export default EtTeamworkIconSVGComp;
    