import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userRecognitionsCommunitysAdapter = createEntityAdapter({})

const initialState = userRecognitionsCommunitysAdapter.getInitialState()

export const userRecognitionsCommunitysApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserRecognitionsCommunitys: builder.query({
            query: () => ({
                url: '/userRecognitionsCommunitys/getUserRecognitionsCommunitys',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserRecognitionsCommunitys = responseData.map(userRecognitionsCommunity => {
                    userRecognitionsCommunity.id = userRecognitionsCommunity._id
                    return userRecognitionsCommunity
                })
                return userRecognitionsCommunitysAdapter.setAll(initialState, loadedUserRecognitionsCommunitys)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserRecognitionsCommunity', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserRecognitionsCommunity', id}))
                    ]
                } else {
                    return [ { type: 'UserRecognitionsCommunity', id: 'LIST'}]
                }
            },
        }),
        createNewUserRecognitionsCommunity: builder.mutation({
            query: initialUserRecognitionsCommunity => ({
                url: '/userRecognitionsCommunitys/createNewUserRecognitionsCommunity',
                method: 'POST',
                body: {...initialUserRecognitionsCommunity,}
            }),
            invalidatesTags: [
                { type: 'UserRecognitionsCommunity', id: "LIST" }
            ] 
        }),
        updateUserRecognitionsCommunity: builder.mutation({
            query: initialUserRecognitionsCommunity => ({
                url: '/userRecognitionsCommunitys/updateUserRecognitionsCommunity',
                method: 'PATCH',
                body: {...initialUserRecognitionsCommunity,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserRecognitionsCommunity', id: arg.id },
                { type: 'UserRecognitionsCommunity', id: 'LIST' }
            ],
        }),
        deleteUserRecognitionsCommunity: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userRecognitionsCommunitys/deleteUserRecognitionsCommunity',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userRecognitionsCommunitys/deleteUserRecognitionsCommunity',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserRecognitionsCommunity', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserRecognitionsCommunitysQuery,
    useCreateNewUserRecognitionsCommunityMutation,
    useUpdateUserRecognitionsCommunityMutation,
    useDeleteUserRecognitionsCommunityMutation,
} = userRecognitionsCommunitysApiSlice

// returns the query result object
export const selectUserRecognitionsCommunityResult = userRecognitionsCommunitysApiSlice.endpoints.getUserRecognitionsCommunitys.select()

// create memoized selector
const selectUserRecognitionsCommunitysData = createSelector(
    selectUserRecognitionsCommunityResult,
    userRecognitionsCommunityResult => userRecognitionsCommunityResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserRecognitionsCommunitys,
    selectById: selectUserRecognitionsCommunityById,
    selectIds: selectUserRecognitionsCommunityIds,
} = userRecognitionsCommunitysAdapter.getSelectors(state => selectUserRecognitionsCommunitysData(state) ?? initialState)