import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userChallengeWABsAdapter = createEntityAdapter({})

const initialState = userChallengeWABsAdapter.getInitialState()

export const userChallengeWABsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserChallengeWABs: builder.query({
            query: () => ({
                url: '/userChallengeWABs/getUserChallengeWABs',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserChallengeWABs = responseData.map(userChallengeWAB => {
                    userChallengeWAB.id = userChallengeWAB._id
                    return userChallengeWAB
                })
                return userChallengeWABsAdapter.setAll(initialState, loadedUserChallengeWABs)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserChallengeWAB', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserChallengeWAB', id}))
                    ]
                } else {
                    return [ { type: 'UserChallengeWAB', id: 'LIST'}]
                }
            },
        }),
        createNewUserChallengeWAB: builder.mutation({
            query: initialUserChallengeWAB => ({
                url: '/userChallengeWABs/createNewUserChallengeWAB',
                method: 'POST',
                body: {...initialUserChallengeWAB,}
            }),
            invalidatesTags: [
                { type: 'UserChallengeWAB', id: "LIST" }
            ] 
        }),
        updateUserChallengeWAB: builder.mutation({
            query: initialUserChallengeWAB => ({
                url: '/userChallengeWABs/updateUserChallengeWAB',
                method: 'PATCH',
                body: {...initialUserChallengeWAB,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserChallengeWAB', id: arg.id },
                { type: 'UserChallengeWAB', id: 'LIST' }
            ],
        }),
        deleteUserChallengeWAB: builder.mutation({
            query: ({id}) => ({
                url: '/userChallengeWABs/deleteUserChallengeWAB',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserChallengeWAB', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserChallengeWABsQuery,
    useCreateNewUserChallengeWABMutation,
    useUpdateUserChallengeWABMutation,
    useDeleteUserChallengeWABMutation,
} = userChallengeWABsApiSlice

// returns the query result object
export const selectUserChallengeWABResult = userChallengeWABsApiSlice.endpoints.getUserChallengeWABs.select()

// create memoized selector
const selectUserChallengeWABsData = createSelector(
    selectUserChallengeWABResult,
    userChallengeWABResult => userChallengeWABResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserChallengeWABs,
    selectById: selectUserChallengeWABById,
    selectIds: selectUserChallengeWABIds,
} = userChallengeWABsAdapter.getSelectors(state => selectUserChallengeWABsData(state) ?? initialState)