import React, { useState, useEffect } from 'react'
import useTitle from "../../../hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
    PencilSquareIcon,
  } from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "../../../utils/EtContentEditorLight2"
import EtBtnFormBasic from "../../../components/common/buttons/EtBtnFormBasic"
import { useNavigate } from "react-router"

import { useCreateNewUserGratitudeImprintMutation } from '@/features/userGratitudeImprints/userGratitudeImprintsApiSlice'
import { useGetContactTypesQuery } from '@/features/contactTypes/contactTypesApiSlice'
import { useGetGratitudeTypesQuery } from '@/features/gratitudeTypes/gratitudeTypesApiSlice'
import { useGetContactsQuery } from '@/features/contacts/contactsApiSlice'


const EtCreateUserImprintGratitudeComp = ({
    userId, 
    userOrgId, 
    triggeringUrl, 
    onClose
  }) => {

  // First Created - 22 Dec 2024
  // Author - Atul Pandey
  // Inherited from the EtCreateUserImprintGratitudeComp component
 
  // 0 - Set Page title & variables
  useTitle('User Imprint Gratitude')

  // 1 - set page navigations
  const navigate = useNavigate()
  
  // 2a - set the mutation to create the new user imprint gratitude data
  const [createNewUserGratitudeImprint, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewUserGratitudeImprintMutation()

  // 2b - Get Data for building User Gratitude Imprints
  const { data: contactTypesData} = useGetContactTypesQuery()
  const { data: gratitudeTypesData} = useGetGratitudeTypesQuery()
  const { data: contactsData} = useGetContactsQuery()

  const extractEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id]);
  };

  const contactTypes = extractEntitiesFromAdapter(contactTypesData);
  const gratitudeTypes = extractEntitiesFromAdapter(gratitudeTypesData);
  const contacts = extractEntitiesFromAdapter(contactsData);

  // 2c: Filter contacts for the given userId
  const filteredContacts = contacts?.filter((contact) => {
    const isOwner = contact.contactOwnerId._id === userId; // Check if the contactOwnerId matches the userId
    const isSharedWithUser = contact.sharedWith.some(
      (shared) => shared.userId._id === userId // Check if userId is in the sharedWith array
    );
    return isOwner || isSharedWithUser; // Return true if either condition is met
  });
  
  console.log('filteredContacts', filteredContacts);

  // 3 - Initialize Form Data State
    const [formData, setFormData] = useState({
        userGratitudeTypeId: null,
        userGratitudeTargetId: null,
        userGratitudeFor: null,
        userGratitudeNoteText: "",
        userGratitudeNoteDate: "",
        userGratitudeNoteUpdateDate:"",
        pic: "",
        active: true, // Default active state
    });
  
    // 4 - State variables for the editor
    const [editorKey, setEditorKey] = useState('gratitudeDesc-' + Date.now());
    const [editorValue, setEditorValue] = useState('');
    const [collapsedSections, setCollapsedSections] = useState({});
  
    // 5a - Convert Image to Base64
    const convertPicToBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
  
    // 5b - Handle Form Changes
    const handleChange = (e) => {
      const { name, value, files } = e.target;
      if (name === "pic") {
        setFormData((prevState) => ({ ...prevState, pic: files[0] }));
      } else {
        setFormData((prevState) => ({ ...prevState, [name]: value }));
      }
    };
  
    // 5c - Toggle show / collapse each section 
    const toggleSection = (key) => {
      setCollapsedSections((prev) => ({
        ...prev,
        [key]: !prev[key],
      }));
    };  

    // 6 - Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
      
        // Trim the gratitude note text
        const trimmedNoteText = formData.userGratitudeNoteText?.trim();
      
        // Validate that the trimmed note is not empty
        if (!trimmedNoteText) {
          console.error("Gratitude note cannot be empty.");
          return;
        }
      
        try {
          // Prepare the data for the API call
          const payload = {
            userId,
            userOrgId,
            userGratitudeTypeId: formData.userGratitudeTypeId,
            userGratitudeTargetId: formData.userGratitudeTargetId,
            userGratitudeFor: formData.userGratitudeFor || null, // Optional field
            userGratitudeNoteText: trimmedNoteText,
            userGratitudeNoteDate: formData.userGratitudeNoteDate || new Date(),
            userGratitudeNoteUpdateDate: formData.userGratitudeNoteUpdateDate || new Date(),
          };
      
          // Invoke the mutation
          await createNewUserGratitudeImprint(payload).unwrap();
      
          // Log success
          console.log("Gratitude Imprint created successfully!");
          
          // Step 6: Reset the form upon success
            setFormData({
                userGratitudeTypeId: null,
                userGratitudeTargetId: null,
                userGratitudeFor: null,
                userGratitudeNoteText: "",
                userGratitudeNoteDate: "",
                userGratitudeNoteUpdateDate:"",
                pic: "",
                active: true, // Default active state
            });
        
            // Step 7: Handle navigation/closure
            if (onClose) {
                onClose();
            } else {
                navigate(triggeringUrl || "/userMagicHome");
            }
  
        } catch (err) {
          console.error("Failed to create Gratitude Imprint:", err);
        }
    };  

    // 7 - Reset Form on Successful Submission
      useEffect(() => {
        if (isSuccess) {
          setFormData({
            userGratitudeTypeId: null,
            userGratitudeTargetId: null,
            userGratitudeFor: null,
            userGratitudeNoteText: "",
            userGratitudeNoteDate: "",
            userGratitudeNoteUpdateDate:"",
            pic: "",
            active: true, // Default active state
          });
    
          if (onClose) {
            onClose();
          } else {
            navigate(triggeringUrl || "/userMagicHome");
          }
        }
      }, [isSuccess, navigate]);
    
    // 8 - Handle Clear
    const handleClear = () => {
    setFormData({
        userGratitudeTypeId: null,
        userGratitudeTargetId: null,
        userGratitudeFor: null,
        userGratitudeNoteText: "",
        userGratitudeNoteDate: "",
        userGratitudeNoteUpdateDate:"",
        pic: "",
        active: true, // Default active state
    });
    };

    // 9 - Handle Cancel
    const handleCancel = () => {
    if (onClose) {
        onClose();
    } else {
        navigate(triggeringUrl || "/userMagicHome");
    }
    };


  return (
    <div className="mx-auto w-full m-1 p-1 bg-white rounded shadow-md shadow-black">
      {isLoading && <PulseLoader color={'#ea580c'} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">{error?.data?.message}</p>
      )}

      <p className="p-1 m-1 font-bold font-poppins text-xl">
        Create User Imprint Gratitude
      </p>

      <form onSubmit={handleSubmit} className="m-1 p-1 text-xs font-poppins">
        {/* 1. Gratitude Type - i.e. Gratitude Type - i.e. Enablement, Opportunities */}
        <div id="gratitudeType" className="bg-gray-100 rounded p-1 m-1">
            <label className="block text-xs font-bold mb-1" htmlFor="gratitudeType">
                Gratitude Type
            </label>
            <select
                id="gratitudeType"
                name="userGratitudeTypeId"
                value={formData.userGratitudeTypeId || ''}
                onChange={handleChange}
                className="w-full p-2 border rounded text-xs"
                required
            >
                <option value="">Select Gratitude Type</option>
                {gratitudeTypes?.map((type) => (
                <option key={type._id} value={type._id}>
                    {type.gratitudeTypeName}
                </option>
                ))}
            </select>
        </div>

        {/* 2. Gratitude Target - i.e. Contact Type - i.e. Clients, Partners */}
        <div id="gratitudeTarget" className="bg-gray-100 rounded p-1 m-1">
            <label className="block text-xs font-bold mb-1" htmlFor="gratitudeTarget">
                Gratitude Target
            </label>
            <select
                id="gratitudeTarget"
                name="userGratitudeTargetId"
                value={formData.userGratitudeTargetId || ''}
                onChange={handleChange}
                className="w-full p-2 border rounded text-xs"
                required
            >
                <option value="">Select Contact Type</option>
                {contactTypes?.map((type) => (
                <option key={type._id} value={type._id}>
                    {type.contactTypeName}
                </option>
                ))}
            </select>
        </div>       
        
        {/* 3. Gratitude For - i.e. specific contact from the filtered contacts list (optional)*/}
        <div id="select-specific-contact" className="bg-gray-100 rounded p-1 m-1">
            <label className="block text-xs font-bold mb-1" htmlFor="specificContact">
                Specific Contact (Optional)
            </label>
            <select
                id="specificContact"
                name="userGratitudeFor"
                value={formData.userGratitudeFor || ''}
                onChange={handleChange}
                className="w-full p-2 border rounded text-xs"
            >
                <option value="">Select Specific Contact</option>
                {filteredContacts
                ?.filter(
                    (contact) =>
                    formData.userGratitudeTargetId &&
                    contact.contactTypeId?._id === formData.userGratitudeTargetId
                )
                ?.map((contact) => (
                    <option key={contact._id} value={contact._id}>
                    {contact.contactName || 'Unnamed Contact'}
                    </option>
                ))}
            </select>
        </div>

        {/* 4. Gratitude Note - using the editor */}
        <div id="gratitudeNote" className="bg-gray-100 rounded p-1 m-1">
            <label className="block text-xs font-bold mb-1" htmlFor="gratitudeNote">
                Gratitude Note
            </label>
            <EtContentEditorLight2
                value={formData.userGratitudeNoteText || ""}
                handleChange={(content) => {
                    setFormData((prevState) => ({
                    ...prevState,
                    userGratitudeNoteText: content,
                    }));
                }}
                placeholderText="Write your gratitude note here..."
                editorKey={editorKey}
            />
        </div>
        
        {/* 5. Form Submissions Bar */}
        <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic 
              name={'Create'} 
              type={'create'} 
              handleClick={handleSubmit} 
              />
            <EtBtnFormBasic
              name={'Clear'}
              type={'clear'}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={'Cancel'}
              type={'cancel'}
              handleClick={handleCancel}
            />
        </div>

      </form>


    </div>
  )
}

export default EtCreateUserImprintGratitudeComp