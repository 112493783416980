
    import React from 'react';
    
    const EtScoresIconSVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m1527.8 326.25h-1030.5c-94.5 0-171 76.5-171 171v1030.5c0 94.5 76.5 171 171 171h1030.5c94.5 0 171-76.5 171-171v-1030.5c0-94.5-76.5-171-171-171zm-263.53 794.81c39.656 39.656 39.656 103.78 0 143.16-19.828 19.828-45.703 29.672-71.719 29.672s-51.75-9.8438-71.578-29.672l-108.42-108.56-108.7 108.7c-19.828 19.828-45.703 29.672-71.578 29.672s-51.891-9.8438-71.719-29.672c-39.516-39.656-39.516-103.78 0-143.16l108.84-108.7-108.56-108.56c-39.656-39.656-39.656-103.78 0-143.16 39.656-39.656 103.64-39.656 143.16 0l108.56 108.56 108.7-108.7c39.656-39.656 103.64-39.656 143.16 0s39.516 103.78 0 143.16l-108.7 108.7z" fillRule="evenodd"/>
 <path d="m3102.8 326.25h-1030.5c-94.5 0-171 76.5-171 171v1030.5c0 94.5 76.5 171 171 171h1030.5c94.5 0 171-76.5 171-171v-1030.5c0-94.5-76.5-171-171-171zm-183.94 610.16-384.48 309.8c-18.141 14.484-40.5 22.359-63.562 22.359-3.7969 0-7.5938-0.28125-11.531-0.70312-26.859-3.0938-51.469-16.734-68.203-38.109l-150.89-192.52c-34.594-44.156-26.719-107.72 17.156-142.17 44.016-34.734 107.72-26.578 142.17 17.156l87.75 111.94 304.59-245.53c43.594-35.016 107.3-28.125 142.45 15.469 34.875 43.453 28.125 107.3-15.469 142.31z" fillRule="evenodd"/>
 <path d="m1527.8 1901.2h-1030.5c-94.5 0-171 76.5-171 171v1030.5c0 94.5 76.5 171 171 171h1030.5c94.5 0 171-76.5 171-171v-1030.5c0-94.5-76.5-171-171-171zm-263.53 794.81c39.656 39.656 39.656 103.78 0 143.16-19.828 19.828-45.703 29.672-71.719 29.672s-51.75-9.8438-71.578-29.672l-108.42-108.56-108.7 108.7c-19.828 19.828-45.703 29.672-71.578 29.672s-51.891-9.8438-71.719-29.672c-39.516-39.656-39.516-103.78 0-143.16l108.84-108.7-108.56-108.56c-39.656-39.656-39.656-103.78 0-143.16 39.656-39.656 103.64-39.656 143.16 0l108.56 108.56 108.7-108.7c39.656-39.656 103.64-39.656 143.16 0s39.516 103.78 0 143.16l-108.7 108.7z" fillRule="evenodd"/>
 <path d="m3102.8 1901.2h-1030.5c-94.5 0-171 76.5-171 171v1030.5c0 94.5 76.5 171 171 171h1030.5c94.5 0 171-76.5 171-171v-1030.5c0-94.5-76.5-171-171-171zm-263.53 794.81c39.656 39.656 39.656 103.78 0 143.16-19.828 19.828-45.703 29.672-71.719 29.672s-51.75-9.8438-71.578-29.672l-108.42-108.56-108.7 108.7c-19.828 19.828-45.703 29.672-71.578 29.672s-51.891-9.8438-71.719-29.672c-39.516-39.656-39.516-103.78 0-143.16l108.84-108.7-108.56-108.56c-39.656-39.656-39.656-103.78 0-143.16 39.656-39.656 103.64-39.656 143.16 0l108.56 108.56 108.7-108.7c39.656-39.656 103.64-39.656 143.16 0s39.516 103.78 0 143.16l-108.7 108.7z" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtScoresIconSVGComp;
    