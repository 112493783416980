import React, { useState, useEffect } from "react"
import useTitle from "@/hooks/useTitle"
import { useNavigate } from "react-router"
import { addIcon, userProfileIcon2 } from "@/assets/img/imgAssets"
import {
  PlusIcon,
  MinusIcon,
  TrashIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline"
import {
  PlusCircleIcon,
  CalendarDaysIcon,
  ArrowUpCircleIcon,
  ClockIcon,
  CheckCircleIcon,
  SunIcon,
  ClipboardDocumentIcon,
  CommandLineIcon,
} from "@heroicons/react/24/solid"
import PulseLoader from "react-spinners/PulseLoader"
import { useGetCalendarEventsQuery } from "./calendarEventsApiSlice"
import { useGetUserPicQuery } from "../users/usersApiSlice"
import jstz from 'jstimezonedetect'; // 25 Jul 2024 timezone
import moment from 'moment'// 25 Jul 2024 timezone
import 'moment-timezone';// 25 Jul 2024 timezone

const EtCalendarEventList = () => {
    // First Created - 22 Jul 2024
    // Author - Atul Pandey

    // 0 - Set Page Title
    useTitle('Organizations List')

    // 1 - set page navigations
    const navigate = useNavigate()

    // 1a - Detect and set Timezone - 24 Jul 2024
  function detectTimezone() {
    const timezone = jstz.determine(); 
    return timezone ? timezone.name() : 'UTC'; // Default to UTC if detection fails
  }
  
  const [timezone, setTimezone] = useState(detectTimezone());
  
  useEffect(() => {
    const newTimezone = detectTimezone();
    if (newTimezone !== timezone) {
      setTimezone(newTimezone);
    }
  }, []); // Run only once on component mount

//   console.log('timezone', timezone)

     // 2 - Initialize showDetails state as an object where key is the event index
     const [showDetails, setShowDetails] = useState({})

     // 3 - Extract the calendar events data
     const { data: calEventsData, isError, error, isLoading } = useGetCalendarEventsQuery()
     //console.log('organizationsData', calEventsData)

     // 3a - Extract the events data from the entity adapter
     const eventsData = calEventsData?.ids.map((id) => {
        const event = calEventsData?.entities[id]
        return event
     })

    //  console.log('Events Data :', eventsData)
 
    //  // 4 - Toggle Show function for event details
     const handleToggleDetails = (eventId) => {
         setShowDetails(prev => ({ ...prev, [eventId]: !prev[eventId] }));
     }

     // 5 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
        return { __html: htmlContent }
    }

    // 6 - Optional: handle loading and error states
    if (isLoading) return <PulseLoader color={"#ea580c"} />
    if (isError) return <div>Error occurred: {error?.data?.message}</div>

   return (
    <div className='bg-gray-200 m-1 p-1 rounded font-poppins mx-auto'>
         {/* Header bar */}
         <div className='flex justify-start items-center m-1 p-1'>
                <div><p className='text-2xl m-1 p-1 font-bold'>Et Events</p></div>
                <div>
                    <img
                        src={addIcon}
                        alt=""
                        className="h-10 w-10 cursor-pointer"
                        onClick={() => navigate("/calendar/create")}
                    />
                </div>
            </div>
        
        {/* Main Section */}
        <div className="m-1 p-1">
            <p className="m-1 p-1 font-bold">Events Data</p>
            {/* Event Headers */}
            <div className="hidden xl:grid xl:grid-cols-12 justify-between items-center bg-gray-100 rounded m-1 p-1 text-xs font-bold ">
                <div className="xl:col-span-3 flex justify-start items-center m-1 p-1">
                    <SunIcon className="w-5 h-5 text-orange-600" />
                    <p className="px-1 text-gray-600">Event Name</p>
                </div>
                <div className="xl:col-span-4 flex justify-start items-center m-1 p-1">
                    <ClipboardDocumentIcon className="w-5 h-5 text-orange-600" />
                    <p className="px-1 text-gray-600">Agenda</p>
                </div>
                <div className="xl:col-span-1 xl:flex xl:justify-center xl:items-center xl:m-1 xl:p-1">
                    <CalendarDaysIcon className="w-5 h-5 text-orange-600" />
                    <p className="px-1 text-gray-600 ">Start Date</p>
                </div>
               
                <div className="col-span-1 flex xl:justify-center justify-start items-center m-1 p-1">
                    <ClockIcon className="w-5 h-5 text-orange-600" />
                    <p className="px-1 text-gray-600 ">Start Time</p>
                </div>
                <div className="col-span-1 flex xl:justify-center justify-start items-center m-1 p-1">
                    <ClockIcon className="w-5 h-5 text-orange-600" />
                    <p className="px-1 text-gray-600 ">End Time</p>
                </div>
                
                <div className="col-span-1 flex xl:justify-center justify-start items-center m-1 p-1">
                    <CommandLineIcon className="w-5 h-5 text-orange-600" />
                    <p className="px-1 text-gray-600 ">Next</p>
                </div>
            </div>
            {eventsData && eventsData?.length >0 ? (
                
                eventsData
                ?.slice()
                 .sort((a, b) => new Date(b.eventStartDate) - new Date(a.eventStartDate)) // Sort in descending order
                ?.map((event, eventIndex) => {
                // Convert UTC dates to local timezone based on user's timezone
                const eventStartDateLocal = moment.tz(event?.eventStartDate, timezone).format("YYYY-MM-DD");
                const eventEndDateLocal = moment.tz(event?.eventEndDate, timezone).format("YYYY-MM-DD");

                // Extract time from the Date objects, since it's already stored in UTC
                const eventStartTimeLocal = moment.tz(event?.eventStartDate, timezone).format("h:mm A"); // h:mm A for 12-hour with AM/PM 
                const eventEndTimeLocal = moment.tz(event?.eventEndDate, timezone).format("h:mm A");
                // const eventEndTimeLocal = moment.tz(event?.eventEndDate, timezone).format("HH:mm");
                return(
                    <div className="bg-gray-100 rounded m-1 p-1 text-xs">
                        <div key={event._id+eventIndex} className="xl:grid xl:grid-cols-12  xl:justify-between grid grid-cols-2 justify-start items-center ">
                            
                        <div className="col-span-2 xl:col-span-3 flex justify-start items-center m-1 p-1 border-b xl:border-none">

                            <div className="xl:hidden flex justify-start items-center">
                                <div className="flex justify-start items-center w-32">
                                    <SunIcon className="w-5 h-5 text-orange-600" />
                                    <p className="px-1 text-gray-600 font-bold">Event Name</p>
                                </div>
                                <p className="px-1 text-gray-600 font-bold">:</p>
                            </div>

                            <div className="flex justify-start items-center">
                                <div
                                    className="hidden xl:flex xl:justify-center xl:items-center xl:cursor-pointer xl:rounded-full h-7 w-7 hover:bg-white"
                                    onClick={() => handleToggleDetails(event._id)}
                                >
                                    {showDetails[event._id] ? (
                                    <MinusIcon className="h-5 w-5 text-orange-600" />
                                    ) : (
                                    <PlusIcon className="h-5 w-5 text-orange-600" />
                                    )}
                                </div>
                                <div><p className="px-1 text-gray-600">{event?.eventName}</p></div>
                            </div>
                            
                        </div>

                        <div className="col-span-2 xl:col-span-4 flex justify-start items-center m-1 p-1 border-b xl:border-none">

                            <div className="xl:hidden flex justify-start items-center">
                                <div className="flex justify-start items-center w-32">
                                    <ClipboardDocumentIcon className="w-5 h-5 text-orange-600" />
                                    <p className="px-1 text-gray-600 font-bold">Agenda</p>
                                </div>
                                <p className="px-1 text-gray-600 font-bold">:</p>
                            </div>
                            <p className="px-1 text-gray-600">{event?.eventDesc}</p>
                        </div>

                        <div className="col-span-2 xl:col-span-1 flex justify-start xl:justify-center items-center m-1 p-1 border-b xl:border-none">
                            <div className="xl:hidden flex justify-start items-center">
                                <div className="flex justify-start items-center w-32">
                                    <CalendarDaysIcon className="w-5 h-5 text-orange-600" />
                                    <p className="px-1 text-gray-600 font-bold">Start Date</p>
                                </div>
                                <p className="px-1 text-gray-600 font-bold">:</p>
                            </div>  
                            <p className="px-1 text-gray-600 ">{eventStartDateLocal}</p>
                        </div>
                        
                        <div className="col-span-2 xl:col-span-1 flex xl:justify-center items-center m-1 p-1 border-b xl:border-none">
                            <div className="xl:hidden flex justify-start items-center">
                                <div className="flex justify-start items-center w-32">
                                    <ClockIcon className="w-5 h-5 text-orange-600" />
                                    <p className="px-1 text-gray-600 font-bold">Start Time</p>
                                </div>
                                <p className="px-1 text-gray-600 font-bold">:</p>
                            </div>  
                            <p className="px-1 text-gray-600 ">{eventStartTimeLocal}</p>
                        </div>

                        <div className="col-span-2 xl:col-span-1 flex xl:justify-center items-center m-1 p-1 border-b xl:border-none">
                            <div className="xl:hidden flex justify-start items-center">
                                <div className="flex justify-start items-center w-32">
                                    <ClockIcon className="w-5 h-5 text-orange-600" />
                                    <p className="px-1 text-gray-600 font-bold">End Time</p>
                                </div>
                                <p className="px-1 text-gray-600 font-bold">:</p>
                            </div>  
                            <p className="px-1 text-gray-600 ">{eventEndTimeLocal}</p>
                        </div>

                        <div className="col-span-2 xl:col-span-1 flex xl:justify-center items-center m-1 p-1 border-b xl:border-none">
                            <div className="xl:hidden flex justify-start items-center">
                                <div className="flex justify-start items-center w-32">
                                    <CommandLineIcon className="w-5 h-5 text-orange-600" />
                                    <p className="px-1 text-gray-600 font-bold">Next</p>
                                </div>
                                <p className="px-1 text-gray-600 font-bold">:</p>
                            </div>

                            <div className='flex justify-start items-center mx-2'>
                                <div className='rounded-full flex justify-center items-center h-6 w-6 hover:bg-white'>
                                    <PencilSquareIcon onClick={() => navigate(`/calendar/update/${event?._id}`)} className="h-4 w-4 text-orange-600 cursor-pointer" />
                                </div>
                                <div className='rounded-full flex justify-center items-center h-6 w-6 hover:bg-white'>
                                    <TrashIcon onClick={() => navigate(`/calendar/delete/${event?._id}`)} className="h-4 w-4 text-orange-600 cursor-pointer" />
                                </div>
                            </div>
                            
                            {/* <p className="px-1 text-gray-600 ">Next</p> */}
                        </div>

                        
                        </div>
                        {showDetails[event._id] && (
                            <div className="m-1 p-1 bg-gray-50 rounded">
                                <p className="m-1 p-1 font-bold border-b-2 border-orange-600 inline-block">Attendees:</p>
                                <div className="grid grid-cols-4">
                                {event?.eventAttendees?.map((attendee, attendeeIndex) => (
                                    <div key={attendee._id} className="flex justify-start items-center m-1 p-1 bg-gray-100 rounded">
                                        <p className="text-xs font-bold m-1 p-1 inline-block">{attendeeIndex+1}</p>
                                        <User
                                            username={attendee?.attendeeId?.username}
                                            defaultUserPic={userProfileIcon2}
                                        />
                                        <div>
                                            <p className="text-xs mx-1 px-1 text-gray-600 font-bold ">{attendee.attendeeId.name}</p>
                                            <p className="text-[10px] mx-1 px-1 ">{attendee.attendeeId.email}</p>
                                        </div>
                                        
                                        
                                    </div>
                                ))}
                                </div>
                            </div>
                        )}
                        <div className="xl:hidden m-1 p-1 bg-gray-50 rounded">
                                <p className="m-1 p-1 font-bold border-b-2 border-orange-600 inline-block">Attendees:</p>
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                                {event?.eventAttendees?.map((attendee, attendeeIndex) => (
                                    <div key={attendee._id} className="flex justify-start items-center m-1 p-1 bg-gray-100 rounded">
                                        <p className="text-xs font-bold m-1 p-1 inline-block">{attendeeIndex+1}</p>
                                        <User
                                            username={attendee?.attendeeId?.username}
                                            defaultUserPic={userProfileIcon2}
                                        />
                                        <div>
                                            <p className="text-xs mx-1 px-1 text-gray-600 font-bold ">{attendee.attendeeId.name}</p>
                                            <p className="text-[10px] mx-1 px-1 ">{attendee.attendeeId.email}</p>
                                        </div>
                                        
                                        
                                    </div>
                                ))}
                                </div>
                            </div>
                    </div>
                )
                }
            )): (
           
                <div className="m-1 p-1 bg-white rounded">
            <p className="text-orange-600 text-xs m-1 p-1">There are currently no events setup.Please create one by clicking the "+" button above.</p>
          </div>      
        )}
        </div>
    </div>
  )
}

const User = ({ username, defaultUserPic }) => {
    const { data: userPic, isFetching: isFetchingUserPic } =
      useGetUserPicQuery(username)
  
    return (
      <div className="flex justify-center items-center h-12 w-12 rounded-full border border-white bg-gradient-to-r from-orange-600 to-orange-400 shadow-lg shadow-black cursor-pointer">
        {/* Display user picture or loading indicator*/}
        {isFetchingUserPic ? (
          <div>Loading...</div>
        ) : userPic ? (
          <img src={userPic} alt="User" className="h-11 w-11  rounded-full" />
        ) : (
          <img src={defaultUserPic} alt="User" className="h-10 w-10 p-1" />
        )}
      </div>
    )
  }

export default EtCalendarEventList