import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLightWithPlace from "@/utils/EtContentEditorLightWithPlace"
import { useCreateNewMissionMetricUoMTypeMutation } from "./missionMetricUoMTypesApiSlice"

const EtCreateMissionMetricUoMType = ({setActiveAdminView}) => {
  // First Created - 09 Jan 2025
  // Author - Atul Pandey
  // Updated - 27 Feb 2025 - updated the function with props - { setActiveAdminView }
  // Updated - 27 Feb 2025 - switched from navigate to setActiveAdminView

  // 0 - Set Page Title
  useTitle('Create MissionMetricUoM Types')
  
  // 1 - set page navigations
  const navigate = useNavigate()

  // 2 - set the mutation to create the new section data
  const[createNewMissionMetricUoMType, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewMissionMetricUoMTypeMutation()

// 3 - create the form data object
const [formData, setFormData] = useState({
  missionMetricUoMTypeName: "",
  missionMetricUoMTypeDesc: "",
  aboutMe: "",
  pic: "",
})

// 4 - Reset form upon successful submission
useEffect(() => {
  if(isSuccess) {
    setFormData({
      missionMetricUoMTypeName: "",
      missionMetricUoMTypeDesc: "",
      aboutMe: "",
      pic: "",
    })

    // navigate('/missionMetricUoMtypes')
    setActiveAdminView('missionMetricUoMTypes'); // 27 Feb 2025
  }

},[isSuccess])

// 5 - Form field updates / change handling

const handleChange = (e) => {
  const { name, value, files } = e.target
  if (name === "pic") {
    setFormData(prevState => ({ ...prevState, pic: files[0] }))
  } else {
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }
}

// 6 - function to strip html tags from sections
function stripHtml(html) {
  return html.replace(/<\/?[^>]+(>|$)/g, "");
}

// 7 - Form Submit Logic
const handleSubmit = async (e) => {
e.preventDefault()

// Initialize an object to collect the form data
const missionMetricUoMTypeData = {
  missionMetricUoMTypeName: formData.missionMetricUoMTypeName,
  missionMetricUoMTypeDesc: formData.missionMetricUoMTypeDesc,
  aboutMe: formData.aboutMe,
}

console.log('missionMetricUoMType Name:',missionMetricUoMTypeData.missionMetricUoMTypeName)

if (formData.pic) {
  // Convert the image to a base64 string
  const reader = new FileReader()
  reader.onloadend = async () => {
    // Once conversion is done, add the base64 string to your data object
    missionMetricUoMTypeData.pic = reader.result

    // Now, send the data object to the server
    try {
      console.log("Form submitted.")
      await createNewMissionMetricUoMType(missionMetricUoMTypeData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
  }
  reader.readAsDataURL(formData.pic)
} else {
  // No image, send other data as is
  try {
    console.log("Form submitted.")
    await createNewMissionMetricUoMType(missionMetricUoMTypeData).unwrap() // Dispatch the RTK Query mutation
  } catch (error) {
    console.error(error)
  }
}
}

// 8 - Handle Cancel

const handleCancel = () => {
  // navigate("/missionMetricUoMtypes")
  setActiveAdminView('missionMetricUoMTypes'); // 27 Feb 2025
}

// 9 - Handle Clear
const handleClear = () => {
  setFormData({
    missionMetricUoMTypeName: "",
    missionMetricUoMTypeDesc: "",
    aboutMe: "",
    pic: "",
    })
}
return (
  <div className="bg-gray-200 p-1 w-3/4 mx-auto mt-5 rounded-lg shadow-md shadow-black">
    {isLoading && <PulseLoader color={"#ea580c"} />}
    {isError && (
      <p className="text-orange-700 font-semi-bold py-2">
        {error?.data?.message}
      </p>
    )}
    <div className="bg-white p-1 m-1 rounded">
      <p className="p-1 m-1 font-bold font-poppins text-xl">Add an MissionMetricUoM Type</p>

      <div className="bg-gray-200 rounded m-1 p-1">
        <form
          onSubmit={handleSubmit}
          className="m-1 p-1 text-xs font-poppins"
        >
          
          <div id="missionMetricUoMTypeName">
            <input
              type="text"
              name="missionMetricUoMTypeName"
              placeholder="Enter the missionMetricUoM type name here"
              value={formData.missionMetricUoMTypeName}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div className="bg-white w-full rounded p-2 m-1">
            <EtContentEditorLightWithPlace
              value={formData.missionMetricUoMTypeDesc}
              handleChange={(content) =>
                setFormData({ ...formData, missionMetricUoMTypeDesc: content })
              }
              placeholderText={
                "Write this missionMetricUoM type is about or will be used for"
              }
              // toolbarId={'sectionDesc'}
            />
          </div>

          <div id="aboutMe">
            <input
              type="text"
              name="aboutMe"
              placeholder="Add something about the personality about this missionMetricUoM type"
              value={formData.aboutMe}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="pic">
            <input
              type="file"
              name="pic"
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

         <div id="missionMetricUoMs" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic
              name={"Create"}
              type={"create"}
              handleClick={handleSubmit}
            />
            <EtBtnFormBasic
              name={"Clear"}
              type={"clear"}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={"Cancel"}
              type={"cancel"}
              handleClick={handleCancel}
            />
          </div>
        </form>
      </div>
    </div>
  </div>
)
}

export default EtCreateMissionMetricUoMType