import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const showcaseTypesAdapter = createEntityAdapter({})

const initialState = showcaseTypesAdapter.getInitialState()

export const showcaseTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getShowcaseTypes: builder.query({
            query: () => ({
                url: '/showcaseTypes/getShowcaseTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedShowcaseTypes = responseData.map(showcaseType => {
                    showcaseType.id = showcaseType._id
                    return showcaseType
                })
                return showcaseTypesAdapter.setAll(initialState, loadedShowcaseTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'ShowcaseType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'ShowcaseType', id}))
                    ]
                } else {
                    return [ { type: 'ShowcaseType', id: 'LIST'}]
                }
            },
        }),
        createNewShowcaseType: builder.mutation({
            query: initialShowcaseType => ({
                url: '/showcaseTypes/createNewShowcaseType',
                method: 'POST',
                body: {...initialShowcaseType,}
            }),
            invalidatesTags: [
                { type: 'ShowcaseType', id: "LIST" }
            ] 
        }),
        updateShowcaseType: builder.mutation({
            query: initialShowcaseType => ({
                url: '/showcaseTypes/updateShowcaseType',
                method: 'PATCH',
                body: {...initialShowcaseType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'ShowcaseType', id: arg.id },
                { type: 'ShowcaseType', id: 'LIST' }
            ],
        }),
        deleteShowcaseType: builder.mutation({
            query: ({id}) => ({
                url: '/showcaseTypes/deleteShowcaseType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'ShowcaseType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetShowcaseTypesQuery,
    useCreateNewShowcaseTypeMutation,
    useUpdateShowcaseTypeMutation,
    useDeleteShowcaseTypeMutation,
} = showcaseTypesApiSlice

// returns the query result object
export const selectShowcaseTypeResult = showcaseTypesApiSlice.endpoints.getShowcaseTypes.select()

// create memoized selector
const selectShowcaseTypesData = createSelector(
    selectShowcaseTypeResult,
    showcaseTypeResult => showcaseTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllShowcaseTypes,
    selectById: selectShowcaseTypeById,
    selectIds: selectShowcaseTypeIds,
} = showcaseTypesAdapter.getSelectors(state => selectShowcaseTypesData(state) ?? initialState)