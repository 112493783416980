import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import { useGetUsersQuery } from '../users/usersApiSlice'
import { useGetTrailsQuery } from "../trails/trailsApiSlice"
import { useGetSessionsQuery } from "../sessions/sessionsApiSlice"
import { useGetOrganizationsQuery } from "../organizations/organizationsApiSlice"
import { useGetOrgSubscriptionsQuery } from "../orgSubscriptions/orgSubscriptionsApiSlice" // 06 Aug 2024
import { useCreateNewSponsorUserPadsByOrgMutation } from "./sponsorUserPadsApiSlice"
import { useGetSponsorUserPadsQuery } from "./sponsorUserPadsApiSlice"


const EtCreateSponsorUserPad = ({setActiveAdminView}) => {
  // First Created - 07 Sep 2024
  // Author - Atul Pandey
  // Inherited from EtCreateCoachUserPad
  // Updated - 04 Mar 2025 - This is the original EtCreateSponsorUserPadByOrg component - refined for admin panel
  // Updated - 04 Mar 2025 - updated the comp with props - { id, setActiveAdminView}
  // Updated - 04 Mar 2025 - switched from navigate to setActiveAdminView

  // 0 - Set Title
  useTitle('Create Sponsor User Pad')

  // 1b - set page navigations and initial page state
  const navigate = useNavigate()
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState(""); // 05 Aug 2024 - for adding sponsor pads by org 
  const [selectedSponsorId, setSelectedSponsorId] = useState(""); // 07 Sep 2024 - added sponsor for each org
  const [isCreating, setIsCreating] = useState(false); // 07 Sep 2024 - For pulse loader while waiting for the backend response

  // 1c - RTK Mutations to create / update data
  const [createNewSponsorUserPadsByOrg, {
    isSuccess,
    isError,
    error,
  }] = useCreateNewSponsorUserPadsByOrgMutation(); // 05 Aug 2024 - by organization
  
  // 2 - Get All Data from RTK entities adapters
  const {data: organizationsData} = useGetOrganizationsQuery()
  const {data: usersData, isLoading} = useGetUsersQuery()
  const {data: orgSubscriptionsData} = useGetOrgSubscriptionsQuery() // 06 Aug 2024
  const {data: trailsData} = useGetTrailsQuery()
  const {data: sessionsData} = useGetSessionsQuery()
  const {data: sponsorUserPadsData, refetch: refetchSponsorUserPads} = useGetSponsorUserPadsQuery()
  
  
//   console.log('Organizations Data', organizationsData)
  // console.log('Users Data', usersData)
  // console.log('OrgSubscriptions Data', orgSubscriptionsData)
  // console.log('Trails Data', trailsData)
  // console.log('Sessions Data', sessionsData)
  // console.log('SponsorUserPads Data', sponsorUserPadsData)
  

  // 2a - Extract data from entities adapter and store in data entities
  const convertEntitiesData = (adapterData) => {
  if (!adapterData) return [];
  return adapterData?.ids?.map((id) => adapterData?.entities[id]);
  }
  
  const orgs = convertEntitiesData(organizationsData)
  const users = convertEntitiesData(usersData)
  const orgSubscriptions = convertEntitiesData(orgSubscriptionsData)
  const trails = convertEntitiesData(trailsData)
  const sessions = convertEntitiesData(sessionsData)
  const sponsorUserPads = convertEntitiesData(sponsorUserPadsData)

  // console.log('orgs:', orgs)
  // console.log('users:', users)

  const orgSubscriptionTrails = orgSubscriptions
  ?.filter(sub => sub.orgId._id === selectedOrgId)
  ?.flatMap(sub => 
    sub.subsPackId?.flatMap(pack => 
      pack.subscriptionPackTrails?.map(trail => trail.packTrailId._id)
    )
  );

  // 4a - Filter organizations
  // const filteredOrgs = orgs
  const filteredOrgs = orgs?.filter(org => {
    // console.log('org:', org.organizationName)
    const orgUsers = users?.filter(user => user.userOrgId._id === org._id);
    if (!orgUsers.length) return false;  // Early exit if no users

    return !orgUsers?.every(user => {
      const userPads = sponsorUserPads?.filter(pad => pad.userId._id === user._id);
      // console.log('userPads:', userPads); 
      if (!userPads.length) return false;  // Check this explicitly
  
      // Get the trails associated with this user's organization via orgSubscriptions
      const userTrails = orgSubscriptions
        ?.filter(sub => sub.orgId._id === org._id)
        ?.flatMap(sub => 
          sub.subsPackId?.flatMap(pack => 
            pack.subscriptionPackTrails?.map(trail => trail.packTrailId._id)
          )
        ) || [];
        console.log('userTrails', userTrails)
        if (!userTrails.length) return false;  // Check for empty trails
  
      return userTrails?.every(trailId => {
        const trailSessions = sessions?.filter(session => session.trailId._id === trailId);
        if (!userTrails.length) return false;  // Check for empty trails
        return trailSessions?.every(session => userPads?.some(pad => pad.trailId?.toString() === trailId?.toString() && 
        pad.sessionId?.toString() === session._id?.toString()));
      });
    });
  });

  // console.log('filteredOrgs:', filteredOrgs)

  // 4b - Filter users who are sponsors for the selected organization - 07 Sep 2024
  const filteredSponsors = selectedOrgId ? users?.filter(user => 
    user.userOrgId._id === selectedOrgId && user.roles.includes('OrgSponsor')
  ) : [];

  // console.log('filtered sponsors:', filteredSponsors)

  // 5a - Select an Organization
  const orgOptions = filteredOrgs ? [
    <option key="placeholder" value="">
      Select an org
    </option>,
    ...filteredOrgs?.map(org => (
      <option key={org._id} value={org._id}>
        {org.organizationName}
      </option>
    ))
  ] : [];

  // 5b - Generate sponsor options for the dropdown - 07 Sep 2024
  const sponsorOptions = filteredSponsors ? [
  <option key="placeholder" value="">
    Select a Sponsor
  </option>,
  ...filteredSponsors?.map(user => (
    <option key={user._id} value={user._id}>
      {user.name}
    </option>
  ))
  ] : [];
     
  // 6 - create the form data object
  const [formData, setFormData] = useState({
    sponsorId:"", // 21 Oct 2024 - removed sponsorId and made it ""
    userId:"",
    trailId:"",
    sessionId:"",
    orgId: "",                // 07 Aug 2024
    sponsorSessionSelfNotes: [],
    sponsorSessionUserNotes:[],
  })

  // 8e - Handle organization selection
  const handleOrgChange = (e) => {
    setSelectedOrgId(e.target.value);
  }

  // 9 -  Determine if the Create All button should be disabled
  const isCreateAllDisabled = filteredOrgs?.length === 0;

  // 10 - Reset form upon successful submission
  useEffect(() => {
    if(isSuccess && isSubmitted) {
        setFormData({
          sponsorId:"", // 21 Oct 2024 - removed sponsorId and made it ""
          userId:"",
          trailId:"",
          sessionId:"",
          orgId: "",                // 07 Aug 2024
          sponsorSessionSelfNotes: [],
          sponsorSessionUserNotes:[],
        })
        // navigate('/sponsorUserPad')
        setActiveAdminView('sponsorUserPads'); // 04 Mar 2025
      }
      
      },[isSuccess, isSubmitted])// ✅ 04 Mar 2025 - Removed `navigate` since it's no longer used.

  
  // 11b - Handle creating sponsor user pads by organization - 05 Aug 2024 - modified - 07 Sep 2024 - isCreating
  const handleCreateByOrg = async () => {
    if (!selectedOrgId) return alert("Please select an organization");
    setIsCreating(true); // Set loading state to true
    try {
        await createNewSponsorUserPadsByOrg({ orgId: selectedOrgId, sponsorId: selectedSponsorId }).unwrap();
        alert("Sponsor User Pads created successfully");
        refetchSponsorUserPads(); // Trigger a refetch of the sponsor user pads data
        setSelectedOrgId(""); // Clear the selected organization
        // navigate('/sponsorUserPad')
        setActiveAdminView('sponsorUserPads'); // 04 Mar 2025
    } catch (error) {
        console.error("Failed to create sponsor user pads:", error);
        alert("Failed to create sponsor user pads");
    }finally {
      setIsCreating(false); //  07 Sep 2024 - Set loading state to false after the request completes
    }
  }
    
    return (
        <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
          {isLoading && <PulseLoader color={"#ea580c"} />}
          {isError && (
            <p className="text-orange-700 font-semi-bold py-2">
              {error?.data?.message}
            </p>
          )}
          <div className="bg-white p-1 m-1 rounded font-poppins">
            <p className="p-1 m-1 font-bold  text-xl">Add a Sponsor User Session Pad</p>
    
            <div className="bg-gray-200 rounded m-1 p-1">

              <div className="grid grid-cols-4 gap-1 justify-between items-top ">

                {/* Section 2 - Create By Org */}
                <div id="orgSelector" className="col-span-4 m-1 p-1 text-xs border rounded border-white"> 
                    <div className="m-1 p-1">
                      <p className="text-lg font-bold mx-1 px-1">Create By Org</p>
                    </div>
                    <div className="mx-1 px-1">
                      <select
                        name="orgId"
                        id="orgId"
                        value={selectedOrgId}
                        onChange={handleOrgChange}
                        className="w-full rounded p-2 m-1"
                        disabled={isCreating} // Disable the select when creating
                    >
                        {orgOptions}
                    </select>
                    </div>

                    {/* Sponsor Selection */}
                    <div id="sponsorSelector" className="mx-1 px-1">
                      <select
                        name="sponsorId"
                        id="sponsorId"
                        value={selectedSponsorId}
                        onChange={(e) => setSelectedSponsorId(e.target.value)}
                        className="w-full rounded p-2 m-1"
                        disabled={isCreating} // Disable the select when creating
                      >
                        {sponsorOptions}
                      </select>
                    </div>
                    
                    <div className="m-1 p-1">
                      <button 
                        type="button" 
                        onClick={handleCreateByOrg} 
                        className={`bg-lime-500 hover:bg-lime-700 text-white mx-1 py-2 px-4 rounded ${isCreateAllDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={isCreateAllDisabled || isCreating}
                        >
                        {isCreating ? <PulseLoader color={"#ffffff"} size={10} /> : "Create"}
                    </button>
                    </div> 
                </div>

              </div>

            </div>
          </div>
        </div>
      )
}

export default EtCreateSponsorUserPad
