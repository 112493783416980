
    import React from 'react';
    
    const EtCoachGroupIcon2SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m501.48 2037.2c-22.359 9.7031-46.828 15.891-72 18.703l220.36 532.12c19.828-15.891 41.766-28.406 64.125-37.125l-212.34-513.43z" fillRule="evenodd"/>
 <path d="m501.48 1562.8 212.34-513.43c-22.359-9-44.297-21.234-64.125-37.125l-220.36 532.12c25.172 2.8125 49.359 9.4219 72 18.703z" fillRule="evenodd"/>
 <path d="m1562.8 3098.5-513-212.34c-9.4219 22.641-21.938 44.578-37.406 64.125l532.12 220.36c2.8125-25.172 9.4219-49.359 18.703-72z" fillRule="evenodd"/>
 <path d="m2588.1 2950.2c-15.469-19.406-28.406-41.344-37.406-64.125l-513 212.34c9.7031 22.359 15.891 46.828 18.703 72l532.12-220.36z" fillRule="evenodd"/>
 <path d="m3098.5 2037.2-212.34 513.43c22.359 9 44.297 21.234 64.125 37.125l220.36-532.12c-25.172-2.8125-49.359-9.4219-72-18.703z" fillRule="evenodd"/>
 <path d="m1011.9 649.84c15.469 19.406 28.406 41.344 37.406 64.125l513-212.34c-9.7031-22.359-15.891-46.828-18.703-72l-532.12 220.36z" fillRule="evenodd"/>
 <path d="m2588.1 649.84-532.12-220.36c-2.8125 25.172-9.4219 49.359-18.703 72l513 212.34c9.4219-22.641 21.938-44.578 37.406-64.125z" fillRule="evenodd"/>
 <path d="m3098.5 1562.8c22.359-9.7031 46.828-15.891 72-18.703l-220.36-532.12c-19.828 15.891-41.766 28.406-64.125 37.125l212.34 513.43z" fillRule="evenodd"/>
 <path d="m1984.4 3199.6c0 245.81-368.71 245.81-368.71 0 0-245.67 368.71-245.67 368.71 0" fillRule="evenodd"/>
 <path d="m1984.4 400.36c0 245.67-368.71 245.67-368.71 0 0-245.81 368.71-245.81 368.71 0" fillRule="evenodd"/>
 <path d="m584.57 1800c0 245.81-368.57 245.81-368.57 0s368.57-245.81 368.57 0" fillRule="evenodd"/>
 <path d="m3384 1800c0 245.81-368.57 245.81-368.57 0s368.57-245.81 368.57 0" fillRule="evenodd"/>
 <path d="m2789.6 2605.6c-47.109 0-94.359 18-130.36 53.578-34.594 34.875-53.578 81-53.578 130.36s19.125 95.344 53.578 130.36c34.875 34.594 81 53.578 130.36 53.578s95.344-19.125 130.36-53.578c34.594-34.875 53.578-81 53.578-130.36s-19.125-95.344-53.578-130.36c-36-35.578-83.109-53.578-130.36-53.578z" fillRule="evenodd"/>
 <path d="m679.64 940.64c72 72 189 72 260.58 0 34.594-34.875 53.578-81 53.578-130.36s-19.125-95.344-53.578-130.36c-34.875-34.594-81-53.578-130.36-53.578s-95.344 19.125-130.36 53.578c-34.594 34.875-53.578 81-53.578 130.36s19.125 95.344 53.578 130.36z" fillRule="evenodd"/>
 <path d="m810.43 2973.9c49.359 0 95.344-19.125 130.36-53.578 34.594-34.875 53.578-81 53.578-130.36s-19.125-95.344-53.578-130.36c-36-35.578-83.109-53.578-130.36-53.578-47.109 0-94.359 18-130.36 53.578-34.594 34.875-53.578 81-53.578 130.36s19.125 95.344 53.578 130.36c34.875 34.594 81 53.578 130.36 53.578z" fillRule="evenodd"/>
 <path d="m2789.6 626.07c-49.359 0-95.344 19.125-130.36 53.578-34.594 34.875-53.578 81-53.578 130.36s19.125 95.344 53.578 130.36c72 72 189 72 260.58 0 34.594-34.875 53.578-81 53.578-130.36s-19.125-95.344-53.578-130.36c-34.875-34.594-81-53.578-130.36-53.578z" fillRule="evenodd"/>
 <path d="m2573.6 1800c0-426.67-347.06-773.57-773.57-773.57-426.67 0-773.57 347.06-773.57 773.57 0 426.67 347.06 773.57 773.57 773.57 426.67 0 773.57-347.06 773.57-773.57zm-294.89 326.81c0 63.703-51.469 115.17-115.17 115.17h-727.16c-63.703 0-115.17-51.469-115.17-115.17v-184.36c0-159.89 129.94-289.83 289.83-289.83h24.891c-34.875-38.812-56.812-90.703-56.812-147.23 0-122.06 99-221.06 221.06-221.06s221.06 99 221.06 221.06c0 56.812-21.656 108.42-56.812 147.23h24.891c159.89 0 289.83 129.94 289.83 289.83v184.36z" fillRule="evenodd"/>
 <path d="m1989 1726.2h-378c-119.11 0-216.42 96.891-216.42 216.42v184.36c0 23.062 18.422 41.766 41.766 41.766h91.125v-147.23c0-20.109 16.594-36.703 36.703-36.703s36.703 16.594 36.703 36.703v147.23h397.84v-147.23c0-20.109 16.594-36.703 36.703-36.703 20.109 0 36.703 16.594 36.703 36.703v147.23h91.125c23.062 0 41.766-18.422 41.766-41.766v-184.36c0-119.53-97.172-216.42-216.42-216.42z" fillRule="evenodd"/>
 <path d="m1947.2 1505.1c0 196.31-294.47 196.31-294.47 0s294.47-196.31 294.47 0" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtCoachGroupIcon2SVGComp;
    