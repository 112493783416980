import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const contactOrganizationsAdapter = createEntityAdapter({})

const initialState = contactOrganizationsAdapter.getInitialState()

export const contactOrganizationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getContactOrganizations: builder.query({
            query: () => ({
                url: '/contactOrganizations/getContactOrganizations',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedContactOrganizations = responseData.map(contactOrganization => {
                    contactOrganization.id = contactOrganization._id
                    return contactOrganization
                })
                return contactOrganizationsAdapter.setAll(initialState, loadedContactOrganizations)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'ContactOrganization', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'ContactOrganization', id}))
                    ]
                } else {
                    return [ { type: 'ContactOrganization', id: 'LIST'}]
                }
            },
        }),
        createNewContactOrganization: builder.mutation({
            query: initialContactOrganization => ({
                url: '/contactOrganizations/createNewContactOrganization',
                method: 'POST',
                body: {...initialContactOrganization,}
            }),
            invalidatesTags: [
                { type: 'ContactOrganization', id: "LIST" }
            ] 
        }),
        updateContactOrganization: builder.mutation({
            query: initialContactOrganization => ({
                url: '/contactOrganizations/updateContactOrganization',
                method: 'PATCH',
                body: {...initialContactOrganization,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'ContactOrganization', id: arg.id },
                { type: 'ContactOrganization', id: 'LIST' }
            ],
        }),
        deleteContactOrganization: builder.mutation({
            query: ({id}) => ({
                url: '/contactOrganizations/deleteContactOrganization',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'ContactOrganization', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetContactOrganizationsQuery,
    useCreateNewContactOrganizationMutation,
    useUpdateContactOrganizationMutation,
    useDeleteContactOrganizationMutation,
} = contactOrganizationsApiSlice

// returns the query result object
export const selectContactOrganizationResult = contactOrganizationsApiSlice.endpoints.getContactOrganizations.select()

// create memoized selector
const selectContactOrganizationsData = createSelector(
    selectContactOrganizationResult,
    contactOrganizationResult => contactOrganizationResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllContactOrganizations,
    selectById: selectContactOrganizationById,
    selectIds: selectContactOrganizationIds,
} = contactOrganizationsAdapter.getSelectors(state => selectContactOrganizationsData(state) ?? initialState)