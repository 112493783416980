
    import React from 'react';
    
    const EtShowcaseProblemIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" fill="currentColor">
          <g transform="translate(-325 -106)"><path d="M460.22 215.716 409.028 127.048C403.933 118.225 391.198 118.225 386.103 127.048L334.911 215.716C329.816 224.539 336.184 235.569 346.374 235.569L448.758 235.569C458.946 235.569 465.313 224.539 460.218 215.716ZM396.984 222.552C393.341 222.552 390.842 219.741 390.842 216.098 390.842 212.349 393.444 209.644 396.984 209.644 400.732 209.644 403.126 212.351 403.126 216.098 403.127 219.741 400.732 222.552 396.984 222.552ZM406.584 156.044 400.863 197.904C400.598 199.843 398.942 201.289 396.984 201.289 395.027 201.289 393.369 199.843 393.104 197.904L387.383 156.044C387.062 153.692 388.89 151.598 391.263 151.598L402.703 151.598C405.078 151.6 406.905 153.694 406.584 156.044Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtShowcaseProblemIcon1SVGComp;
    