import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate } from "react-router"
import useTitle from "@/hooks/useTitle"
import { EllipsisHorizontalCircleIcon, VideoCameraIcon, PlayCircleIcon, PauseCircleIcon, SpeakerWaveIcon, SpeakerXMarkIcon } from "@heroicons/react/24/solid"
// import EtVideoPlaylist from './EtVideoPlaylist'
import EtVideoPlaylist from '@/components/videos/EtVideoPlaylist'
import { videoList } from '@/data/videoPlaylist'

const EtTrinityWebLandingWelcome = () => {
  
  // Author - Atul Pandey
  // First Created - 09 Mar 2025
  // Purpose - to act as the welcome page for Trinity Landing - Founder's message, new announcements
  // Updated - 09 Mar 2025 - added a playlist

  // 1 - State variables for mute/unmute and play/pause
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [showPlaylist, setShowPlaylist] = useState(true)

  // 2a - Video Playlist
  // const videoList = [
  //   {name: 'g_SkCJnU0jE'},
  //   {name: 'eXK4DaLt8MY'},
  //   {name: 'PLioFAEdnXE'},
  //   {name: 'V68vBaLpZ0Y'},
  //   {name: '8Ydg2ZOapws'},
  //   ] 
    
  // 2a - Video Playlist
  const currentVideoId = videoList[currentVideoIndex].name;
//   const videoSrc = `https://www.youtube.com/embed/${currentVideoId}?autoplay=1&mute=0&controls=0&loop=1&playlist=${currentVideoId}&modestbranding=1&rel=0&iv_load_policy=3&disablekb=1&playsinline=1&cc_load_policy=0&enablejsapi=1`;

const playlistIds = videoList?.map(video => video.name).join(",");
const videoSrc = `https://www.youtube.com/embed/${currentVideoId}?autoplay=1&mute=0&controls=0&playlist=${playlistIds}&modestbranding=1&rel=0&iv_load_policy=3&disablekb=1&playsinline=1&cc_load_policy=0&enablejsapi=1`;

  
  // 2c - Autoplay on iOS
  useEffect(() => {
    // Try autoplay workaround on iPad (iOS)
    const iframe = document.getElementById("youtubeIframe");
    const playVideo = () => {
      iframe?.contentWindow?.postMessage(
        '{"event":"command","func":"playVideo","args":""}',
        "*"
      );
    };
    // Delay to allow autoplay on iOS
    setTimeout(playVideo, 1000);
  }, []);

  // 2d - Handle Next Video When One Ends
  const handleVideoStateChange = useCallback((event) => {
    if (event.data === 0) { // Video ended
      setCurrentVideoIndex(prevIndex => {
        const nextIndex = (prevIndex + 1) % videoList.length;
        return nextIndex;
      });
    }
  }, [videoList.length]);
  
//   const handleVideoStateChange = useCallback((event) => {
//     if (event.data === 0) { // Video ended
//       setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoList.length);
//     }
//   }, [videoList.length]);
  
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && typeof event.data === "string" && event.data.includes('"event":"onStateChange"')) {
        const parsedData = JSON.parse(event.data);
        handleVideoStateChange(parsedData);
      }
    };
  
    window.addEventListener("message", handleMessage);
  
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handleVideoStateChange]);
  

  // 3a - Handle Toggle Mute
  const toggleMute = () => {
    setIsMuted((prev) => !prev);
    document.getElementById("youtubeIframe")?.contentWindow?.postMessage(
      `{"event":"command","func":"${isMuted ? "unMute" : "mute"}","args":""}`,
      "*"
    );
  };

  // 3b - Handle Toggle Play / Pause
  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
    document.getElementById("youtubeIframe")?.contentWindow?.postMessage(
      `{"event":"command","func":"${isPlaying ? "pauseVideo" : "playVideo"}","args":""}`,
      "*"
    );
  };

  // 3c - Handle toggle playlist view
  const togglePlaylistView = () => {
    setShowPlaylist(prev => !prev);
    document.getElementById("youtubeIframe")?.contentWindow?.postMessage(
      '{"event":"command","func":"pauseVideo","args":""}',
      "*"
    );
  };
  
  return (
    <div className="relative w-full h-full overflow-hidden rounded">
      {/* Show Either Video Player or Playlist */}
      {showPlaylist ? (
        <EtVideoPlaylist 
          videoList={videoList} 
          setCurrentVideoIndex={setCurrentVideoIndex} 
          setShowPlaylist={setShowPlaylist} 
        />
      ) : (
        <div className="absolute top-0 left-0 w-full h-[80%] pointer-events-none">
          <iframe
            id="youtubeIframe"
            className="w-full h-full rounded"
            src={videoSrc}
            title="Welcome Video"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )}
  
      {/* Custom Controls - Only Show When Playlist is NOT Active */}
      {!showPlaylist && (
        <div className="absolute top-1 left-2 space-x-0">
          {/* Play/Pause Button */}
          <button
            onClick={togglePlayPause}
            className="bg-black text-orange-600 px-1 py-1 rounded"
          >
            {isPlaying ? <PauseCircleIcon className="h-4 w-4" /> : <PlayCircleIcon className="h-4 w-4" />}
          </button>
  
          {/* Mute/Unmute Button */}
          <button
            onClick={toggleMute}
            className="bg-black px-1 py-1 rounded text-orange-600"
          >
            {isMuted ? <SpeakerWaveIcon className="h-4 w-4" /> : <SpeakerXMarkIcon className="h-4 w-4" />}
          </button>
  
          {/* Show Playlist Button */}
          <button
            onClick={togglePlaylistView}
            className="bg-black px-1 py-1 rounded text-orange-600"
          >
            {showPlaylist ? <VideoCameraIcon className="h-4 w-4" /> : <EllipsisHorizontalCircleIcon className="h-4 w-4" />}
          </button>
        </div>
      )}
    </div>
  );
  
  // return (
    
  //   <div className="relative w-full h-full overflow-hidden rounded">
  //     <div className="absolute top-0 left-0 w-full h-[80%] pointer-events-none">
  //       <iframe
  //         id="youtubeIframe"
  //         className="w-full h-full rounded"
  //         src={videoSrc}
  //         title="Welcome Video"
  //         allow="autoplay; fullscreen; picture-in-picture"
  //         allowFullScreen
  //       ></iframe>
  //     </div>
      
  //     {/* Custom Controls */}
  //       <div className="absolute top-1 left-2 space-x-0">
  //        {/* Play/Pause Button */}
  //        <button
  //          onClick={togglePlayPause}
  //          className="bg-black text-orange-600 px-1 py-1 rounded"
  //        >
           
  //          {isPlaying ? <PauseCircleIcon className='h-4 w-4'/> : <PlayCircleIcon className='h-4 w-4'/>}
  //        </button>

  //        {/* Mute/Unmute Button */}
  //        <button
  //          onClick={toggleMute}
  //          className="bg-black px-1 py-1 rounded text-orange-600"
  //        >
          
  //          {isMuted ? <SpeakerWaveIcon className='h-4 w-4'/> : <SpeakerXMarkIcon className='h-4 w-4'/>}
  //        </button>
  //        {/* Show PlayList / Vid Button */}
  //        <button
  //         //  onClick={() => setShowPlaylist(prev => !prev)}
  //          onClick={togglePlaylistView}
  //          className="bg-black px-1 py-1 rounded text-orange-600"
  //        >
  //          {showPlaylist ? <VideoCameraIcon className='h-4 w-4'/> : <EllipsisHorizontalCircleIcon className='h-4 w-4'/>}
  //        </button>
  //      </div>
  //   </div>
  // );
}

export default EtTrinityWebLandingWelcome