import React from "react";
import { formatShortPlainDate } from "@/utils/formatDate"; // Import date formatting utility

const EtVideoPlaylist = ({ videoList, setCurrentVideoIndex, setShowPlaylist }) => {
  return (
    <div className="absolute top-0 left-0 w-full h-full rounded flex flex-col items-center p-3 overflow-y-auto bg-white">
      <h2 className=" text-2xl font-semibold mb-4 top-0 w-full py-3 text-center z-10">Select a Video</h2>
      
      {/* Grid Layout for Playlist */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 w-full pt-2">
        {videoList.map((video, index) => (
          <div
            key={index}
            className="bg-gray-900 rounded-lg overflow-hidden shadow shadow-white cursor-pointer hover:shadow-xl hover:bg-gray-800 transition-all duration-300 border border-gray-400"
            // className="hover:bg-orange-800 rounded-lg overflow-hidden shadow shadow-white cursor-pointer hover:shadow-xl bg-gradient-to-r from-orange-800 to-orange-600 transition-all duration-300 border border-gray-400"
            onClick={() => {
              setCurrentVideoIndex(index);
              setShowPlaylist(false);
            }}
          >
            {/* Video Thumbnail */}
            <img
            //   src={`https://img.youtube.com/vi/${video.name}/hqdefault.jpg`}
              src={video.thumb}
              alt={video.title}
              className="w-full h-48 object-contain"
            />

            {/* Video Details */}
            <div className="p-4">
              <h3 className="text-lg font-semibold text-white mb-2 line-clamp-1">{video.title}</h3>
              <p className="text-sm text-gray-400 mb-2 line-clamp-2">{video.description}</p>
              <div className="text-gray-500 text-xs py-1">
                {video.category} - {formatShortPlainDate(video.dateCreated)}
              </div>

              {/* Stats */}
              <div className="flex justify-between items-center text-gray-400 text-xs mt-2">
                <span>Views: {video.stats.views}</span>
                <span>Likes: {video.stats.likes}</span>
                <span>Comments: {video.stats.comments}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EtVideoPlaylist;