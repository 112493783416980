import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import {
  PlusIcon,
  MinusIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline"
import { ClockIcon, BuildingOffice2Icon,ArrowPathIcon, CalendarDaysIcon, Squares2X2Icon, ArrowTrendingUpIcon, InboxArrowDownIcon, UserIcon, ArrowDownCircleIcon, ArrowUpCircleIcon  } from "@heroicons/react/24/solid"

import { useGetUserVisionSelfsQuery } from '@/features/userVisions/userVisionSelfsApiSlice'
import { useGetUserVisionClientsQuery } from '@/features/userVisions/userVisionClientsApiSlice'
import { useGetUserVisionOrgsQuery } from '@/features/userVisions/userVisionOrgsApiSlice'
import { useGetUserVisionPartnersQuery } from '@/features/userVisions/userVisionPartnersApiSlice'
import { useGetUserVisionTeamsQuery } from '@/features/userVisions/userVisionTeamsApiSlice'

import EtModalCreateUserImprintVisionSelf from "@/components/common/modal/EtModalCreateUserImprintVisionSelf"
import EtModalUpdateUserImprintVisionSelf from "@/components/common/modal/EtModalUpdateUserImprintVisionSelf"
import EtModalCreateUserImprintVisionClient from "@/components/common/modal/EtModalCreateUserImprintVisionClient"
import EtModalUpdateUserImprintVisionClient from "@/components/common/modal/EtModalUpdateUserImprintVisionClient"
import EtModalCreateUserImprintVisionPartner from "@/components/common/modal/EtModalCreateUserImprintVisionPartner"
import EtModalUpdateUserImprintVisionPartner from "@/components/common/modal/EtModalUpdateUserImprintVisionPartner"
import EtModalCreateUserImprintVisionOrg from "@/components/common/modal/EtModalCreateUserImprintVisionOrg"
import EtModalUpdateUserImprintVisionOrg from "@/components/common/modal/EtModalUpdateUserImprintVisionOrg"
import EtModalCreateUserImprintVisionTeam from "@/components/common/modal/EtModalCreateUserImprintVisionTeam"
import EtModalUpdateUserImprintVisionTeam from "@/components/common/modal/EtModalUpdateUserImprintVisionTeam"

import EtImprintVisionSelfIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintVisionSelfIcon1SVGComp'
import EtImprintVisionClientsIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintVisionClientsIcon1SVGComp'
import EtImprintVisionPartnerIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintVisionPartnerIcon1SVGComp'
import EtImprintVisionOrgIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintVisionOrgIcon1SVGComp'
import EtImprintVisionTeamIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintVisionTeamIcon1SVGComp'

// 07 Jan 2025
import EtShowcaseSelfNotesIcon1SVGComp from '@/assets/img/icons/svgComp/EtShowcaseSelfNotesIcon1SVGComp'
import EtImprintVisionEvolutionIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintVisionEvolutionIcon1SVGComp'
import EtImprintVisionNotesIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintVisionNotesIcon1SVGComp'

import { createSvgComponentFromBase64 } from '@/utils/createSvgComponentFromBase64'

import {
    formatDateToMonthYear,
    formatDateToYear,
    formatDateTime,
    formatShortDate,
    calculateYearsAndMonths,
  } from "@/utils/formatDate"
  
  import { getTimeSince } from "@/utils/EtGetTimeSince"


const EtImprintVision = ({ userId, userInfo }) => {
    // First Created - 06 Jan 2025
    // Author - Atul Pandey
    // Updated - 07 Jan 2025 - section details for each vision
    // Updated - 08 Jan 2025 - elaborated client vision section
    // Updated - 08 Jan 2025 - elaborated org vision section
    // Updated - 08 Jan 2025 - elaborated team vision section

    // 1a1 - States for Self Vision Modal -- 06 Jan 2025
    const [isCreateVisionSelfModalOpen, setIsCreateVisionSelfModalOpen] = useState(false)
    const [isUpdateVisionSelfModalOpen, setIsUpdateVisionSelfModalOpen] = useState(false)
    const [createVisionSelfTriggeringUrl, setCreateVisionSelfTriggeringUrl] = useState(null)
    const [updateVisionSelfTriggeringUrl, setUpdateVisionSelfTriggeringUrl] = useState(null)
    
    // 1a2 - States for Clients Vision Modal -- 06 Jan 2025
    const [isCreateVisionClientsModalOpen, setIsCreateVisionClientsModalOpen] = useState(false)
    const [isUpdateVisionClientsModalOpen, setIsUpdateVisionClientsModalOpen] = useState(false)
    const [createVisionClientsTriggeringUrl, setCreateVisionClientsTriggeringUrl] = useState(null)
    const [updateVisionClientsTriggeringUrl, setUpdateVisionClientsTriggeringUrl] = useState(null)
    
    // 1a3 - States for Org Vision Modal -- 06 Jan 2025
    const [isCreateVisionOrgModalOpen, setIsCreateVisionOrgModalOpen] = useState(false)
    const [isUpdateVisionOrgModalOpen, setIsUpdateVisionOrgModalOpen] = useState(false)
    const [createVisionOrgTriggeringUrl, setCreateVisionOrgTriggeringUrl] = useState(null)
    const [updateVisionOrgTriggeringUrl, setUpdateVisionOrgTriggeringUrl] = useState(null)
    
    // 1a4 - States for Team Vision Modal -- 06 Jan 2025
    const [isCreateVisionTeamModalOpen, setIsCreateVisionTeamModalOpen] = useState(false)
    const [isUpdateVisionTeamModalOpen, setIsUpdateVisionTeamModalOpen] = useState(false)
    const [createVisionTeamTriggeringUrl, setCreateVisionTeamTriggeringUrl] = useState(null)
    const [updateVisionTeamTriggeringUrl, setUpdateVisionTeamTriggeringUrl] = useState(null)
    
    // 1a5 - States for Partners Vision Modal -- 06 Jan 2025
    const [isCreateVisionPartnersModalOpen, setIsCreateVisionPartnersModalOpen] = useState(false)
    const [isUpdateVisionPartnersModalOpen, setIsUpdateVisionPartnersModalOpen] = useState(false)
    const [createVisionPartnersTriggeringUrl, setCreateVisionPartnersTriggeringUrl] = useState(null)
    const [updateVisionPartnersTriggeringUrl, setUpdateVisionPartnersTriggeringUrl] = useState(null)

    // 1b - State for vision tabs
    const [activeTab, setActiveTab] = useState("self")

    // 1c - State for selected vision id - 07 Jan 2025
    const [ selectedVisionId, setSelectedVisionId ] = useState(null)

    // 2a - Extract needed data from the incoming props
    const userOrgId = userInfo[0]?.userOrgId._id || null
    // console.log('userOrgId', userOrgId)

    // 2b - Get User Vision Data - 06 Jan 2025
    const { data: userVisionSelfData } = useGetUserVisionSelfsQuery()
    const { data: userVisionClientData } = useGetUserVisionClientsQuery()
    const { data: userVisionPartnerData } = useGetUserVisionPartnersQuery()
    const { data: userVisionOrgData } = useGetUserVisionOrgsQuery()
    const { data: userVisionTeamData } = useGetUserVisionTeamsQuery()
    
    const extractFilteredEntitiesFromAdapter = (dataAdapter) => {
        if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
        return dataAdapter.ids.map((id) => dataAdapter.entities[id])
        ?.filter(
            (item) => item.userId?._id === userId && item.userOrgId._id === userOrgId
            );
    };

    const userVisionSelfs = extractFilteredEntitiesFromAdapter(userVisionSelfData)
    const userVisionClients = extractFilteredEntitiesFromAdapter(userVisionClientData)
    const userVisionPartners = extractFilteredEntitiesFromAdapter(userVisionPartnerData)
    const userVisionOrgs = extractFilteredEntitiesFromAdapter(userVisionOrgData)
    const userVisionTeams = extractFilteredEntitiesFromAdapter(userVisionTeamData)
    console.log("userVisionSelfs", userVisionSelfs)

    // 3a1 - Handle Create User Self Vision
    const handleCreateUserVisionSelf = () => {
        const currentUrl = window.location.pathname
        setIsCreateVisionSelfModalOpen(true)
        setCreateVisionSelfTriggeringUrl(currentUrl)
    }

    const handleCloseCreateVisionSelfModal = () => {
        setIsCreateVisionSelfModalOpen(false)
        setCreateVisionSelfTriggeringUrl(null)
        // window.location.reload();
    }

    // 3a2 - Handle Create User Clients Vision -- 08 Jan 2025
    const handleCreateUserVisionClients = () => {
        const currentUrl = window.location.pathname
        setIsCreateVisionClientsModalOpen(true)
        setCreateVisionClientsTriggeringUrl(currentUrl)
    }

    const handleCloseCreateVisionClientsModal = () => {
        setIsCreateVisionClientsModalOpen(false)
        setCreateVisionClientsTriggeringUrl(null)
        // window.location.reload();
    }

    // 3a3 - Handle Create User Org Vision -- 08 Jan 2025
    const handleCreateUserVisionOrg = () => {
        const currentUrl = window.location.pathname
        setIsCreateVisionOrgModalOpen(true)
        setCreateVisionOrgTriggeringUrl(currentUrl)
    }

    const handleCloseCreateVisionOrgModal = () => {
        setIsCreateVisionOrgModalOpen(false)
        setCreateVisionOrgTriggeringUrl(null)
        // window.location.reload();
    }

    // 3a4 - Handle Create User Team Vision -- 08 Jan 2025
    const handleCreateUserVisionTeam = () => {
        const currentUrl = window.location.pathname
        setIsCreateVisionTeamModalOpen(true)
        setCreateVisionTeamTriggeringUrl(currentUrl)
    }

    const handleCloseCreateVisionTeamModal = () => {
        setIsCreateVisionTeamModalOpen(false)
        setCreateVisionTeamTriggeringUrl(null)
        // window.location.reload();
    }

    // 3a5 - Handle Create User Partners Vision -- 08 Jan 2025
    const handleCreateUserVisionPartners = () => {
        const currentUrl = window.location.pathname
        setIsCreateVisionPartnersModalOpen(true)
        setCreateVisionPartnersTriggeringUrl(currentUrl)
    }

    const handleCloseCreateVisionPartnersModal = () => {
        setIsCreateVisionPartnersModalOpen(false)
        setCreateVisionPartnersTriggeringUrl(null)
        // window.location.reload();
    }

    // 3b1 - Handle Update User Self Vision -- 07 Jan 2025
    const handleUpdateUserVisionSelf = (id) => {

        const currentUrl = window.location.pathname
        setSelectedVisionId(id)
        console.log('selectedVisionId', selectedVisionId)
        setIsUpdateVisionSelfModalOpen(true)
        setUpdateVisionSelfTriggeringUrl(currentUrl)
    }

    const handleCloseUpdateVisionSelfModal = () => {
        setSelectedVisionId(null)
        setIsUpdateVisionSelfModalOpen(false)
        setUpdateVisionSelfTriggeringUrl(null)
        // window.location.reload();
    }

    // 3b2 - Handle Update User Clients Vision -- 08 Jan 2025
    const handleUpdateUserVisionClients = (id) => {

        const currentUrl = window.location.pathname
        setSelectedVisionId(id)
        console.log('selectedVisionId', selectedVisionId)
        setIsUpdateVisionClientsModalOpen(true)
        setUpdateVisionClientsTriggeringUrl(currentUrl)
    }

    const handleCloseUpdateVisionClientsModal = () => {
        setSelectedVisionId(null)
        setIsUpdateVisionClientsModalOpen(false)
        setUpdateVisionClientsTriggeringUrl(null)
        // window.location.reload();
    }

    // 3b3 - Handle Update User Org Vision -- 08 Jan 2025
    const handleUpdateUserVisionOrg = (id) => {

        const currentUrl = window.location.pathname
        setSelectedVisionId(id)
        console.log('selectedVisionId', selectedVisionId)
        setIsUpdateVisionOrgModalOpen(true)
        setUpdateVisionOrgTriggeringUrl(currentUrl)
    }

    const handleCloseUpdateVisionOrgModal = () => {
        setSelectedVisionId(null)
        setIsUpdateVisionOrgModalOpen(false)
        setUpdateVisionOrgTriggeringUrl(null)
        // window.location.reload();
    }

    // 3b4 - Handle Update User Team Vision -- 08 Jan 2025
    const handleUpdateUserVisionTeam = (id) => {

        const currentUrl = window.location.pathname
        setSelectedVisionId(id)
        console.log('selectedVisionId', selectedVisionId)
        setIsUpdateVisionTeamModalOpen(true)
        setUpdateVisionTeamTriggeringUrl(currentUrl)
    }

    const handleCloseUpdateVisionTeamModal = () => {
        setSelectedVisionId(null)
        setIsUpdateVisionTeamModalOpen(false)
        setUpdateVisionTeamTriggeringUrl(null)
        // window.location.reload();
    }

    // 3b5 - Handle Update User Partners Vision -- 08 Jan 2025
    const handleUpdateUserVisionPartners = (id) => {

        const currentUrl = window.location.pathname
        setSelectedVisionId(id)
        console.log('selectedVisionId', selectedVisionId)
        setIsUpdateVisionPartnersModalOpen(true)
        setUpdateVisionPartnersTriggeringUrl(currentUrl)
    }

    const handleCloseUpdateVisionPartnersModal = () => {
        setSelectedVisionId(null)
        setIsUpdateVisionPartnersModalOpen(false)
        setUpdateVisionPartnersTriggeringUrl(null)
        // window.location.reload();
    }

    return (
        <div className='bg-white rounded m-1 p-1'> 
        <div className="flex justify-start items-center m-1 p-1 font-poppins rounded">
            <div
                onClick={() => setActiveTab("self")}
                className={`flex items-center text-gray-600 font-bold mx-2 cursor-pointer ${
                activeTab === "self" ? "text-orange-600" : ""
                }`}
            >
                <div><EtImprintVisionSelfIcon1SVGComp className='h-8 w-8' /></div>
                <div>
                    <p className="mx-1 px-1 text-sm">Self</p>
                    <p className="mx-1 px-1 font-normal text-[9px] italic">what legacy am I inspired to create?</p>
                </div>
            </div>
            <div
                onClick={() => setActiveTab("clients")}
                className={`flex items-center text-gray-600 font-bold mx-2 cursor-pointer ${
                activeTab === "clients" ? "text-orange-600" : ""
                }`}
            >
                <div><EtImprintVisionClientsIcon1SVGComp className='h-8 w-8' /></div>
                <div className=''>
                    <p className="mx-1 px-1 text-sm">Clients</p>
                    {/* <p className="mx-1 px-1 font-normal text-[9px] italic">what impact am I inspired to make for my clients?</p> */}
                    <p className="mx-1 px-1 font-normal text-[9px] italic">what am I inspired to do for my clients?</p>
                </div>
            </div>
            <div
                onClick={() => setActiveTab("org")}
                className={`flex items-center text-gray-600 font-bold mx-2 cursor-pointer ${
                activeTab === "org" ? "text-orange-600" : ""
                }`}
            >
                <div><EtImprintVisionOrgIcon1SVGComp className='h-8 w-8' /></div>
                <div>
                    <p className="mx-1 px-1 text-sm">Org</p>
                    <p className="mx-1 px-1 font-normal text-[9px] italic">what am I inspired to do for my org?</p>
                    {/* <p className="mx-1 px-1 font-normal text-[9px] italic">what drives me?</p> */}
                </div>
            </div>
            <div
                onClick={() => setActiveTab("team")}
                className={`flex items-center text-gray-600 font-bold mx-2 cursor-pointer ${
                activeTab === "team" ? "text-orange-600" : ""
                }`}
            >
                <div><EtImprintVisionTeamIcon1SVGComp className='h-8 w-8' /></div>
                <div>
                    <p className="mx-1 px-1 text-sm">Team</p>
                    {/* <p className="mx-1 px-1 font-normal text-[9px] italic">what impact am I inspired to make for my clients?</p> */}
                    <p className="mx-1 px-1 font-normal text-[9px] italic">what am I inspired to do for my team?</p>
                </div>
            </div>
            <div
                onClick={() => setActiveTab("partners")}
                className={`flex items-center text-gray-600 font-bold mx-2 cursor-pointer ${
                activeTab === "partners" ? "text-orange-600" : ""
                }`}
            >
                <div><EtImprintVisionPartnerIcon1SVGComp className='h-8 w-8' /></div>
                <div>
                    <p className="mx-1 px-1 text-sm">Partners</p>
                    {/* <p className="mx-1 px-1 font-normal text-[9px] italic">what impact am I inspired to make for my clients?</p> */}
                    <p className="mx-1 px-1 font-normal text-[9px] italic">what am I inspired to do for my partners?</p>
                </div>
            </div>
        </div>

        <div className='border-t my-2 py-2'>
            {activeTab === 'self' && (
                <div className="m-1 p-1 rounded">
                    {userVisionSelfs?.length === 0 && (
                        <div className="flex justify-between items-center">
                            <div>
                            <p className="m-1 p-1 text-xs">
                                You have not set a self vision yet! Change that by
                                clicking the Add button.
                            </p>
                            </div>
                            <div className="flex justify-start items-center">
                            <p className="text-xs font-bold mx-1 px-1">Add</p>
                            <button
                                type="button"
                                onClick={handleCreateUserVisionSelf}
                                className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                            >
                                <PlusIcon className="h-3 w-3" />
                            </button>
                            </div>
                            <EtModalCreateUserImprintVisionSelf
                                isOpen={isCreateVisionSelfModalOpen}
                                userId={userId}
                                userOrgId={userOrgId}
                                triggeringUrl={createVisionSelfTriggeringUrl}
                                onClose={handleCloseCreateVisionSelfModal}
                            />
                        </div>
                    )}
                    {userVisionSelfs?.length >0 && (
                        <div>
                            <RenderVision 
                                userVisions={userVisionSelfs}
                                handleUpdate={handleUpdateUserVisionSelf}
                                selectedVisionId={selectedVisionId}
                                setSelectedVisionId={setSelectedVisionId}
                                handleCloseModal={handleCloseUpdateVisionSelfModal}
                                userId={userId}
                                userOrgId={userOrgId}
                                triggeringUrl={updateVisionSelfTriggeringUrl}
                                isOpen={isUpdateVisionSelfModalOpen}
                                ModalComponent={EtModalUpdateUserImprintVisionSelf}
                            />
                        </div>
                    )}
                </div>
            )}
            {activeTab === 'clients' && (
                <div className="m-1 p-1 rounded">
                    {userVisionClients?.length === 0 && (
                        <div className="flex justify-between items-center">
                            <div>
                            <p className="m-1 p-1 text-xs">
                                You have not set a client vision yet! Change that by
                                clicking the Add button.
                            </p>
                            </div>
                            <div className="flex justify-start items-center">
                            <p className="text-xs font-bold mx-1 px-1">Add</p>
                            <button
                                type="button"
                                onClick={handleCreateUserVisionClients}
                                className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                            >
                                <PlusIcon className="h-3 w-3" />
                            </button>
                            </div>
                            <EtModalCreateUserImprintVisionClient
                                isOpen={isCreateVisionClientsModalOpen}
                                userId={userId}
                                userOrgId={userOrgId}
                                triggeringUrl={createVisionClientsTriggeringUrl}
                                onClose={handleCloseCreateVisionClientsModal}
                            />
                        </div>
                    )}
                    {userVisionClients?.length >0 && (
                        <div>
                            <RenderVision 
                                userVisions={userVisionClients}
                                handleUpdate={handleUpdateUserVisionClients}
                                selectedVisionId={selectedVisionId}
                                setSelectedVisionId={setSelectedVisionId}
                                handleCloseModal={handleCloseUpdateVisionClientsModal}
                                userId={userId}
                                userOrgId={userOrgId}
                                triggeringUrl={updateVisionClientsTriggeringUrl}
                                isOpen={isUpdateVisionClientsModalOpen}
                                ModalComponent={EtModalUpdateUserImprintVisionClient} // 08 Jan 2025
                            />
                        </div>
                    )}
                </div>
            )}
            {activeTab === 'org' && (
                <div className="m-1 p-1 rounded">
                    {userVisionOrgs?.length === 0 && (
                        <div className="flex justify-between items-center">
                            <div>
                            <p className="m-1 p-1 text-xs">
                                You have not set a org vision yet! Change that by
                                clicking the Add button.
                            </p>
                            </div>
                            <div className="flex justify-start items-center">
                            <p className="text-xs font-bold mx-1 px-1">Add</p>
                            <button
                                type="button"
                                onClick={handleCreateUserVisionOrg}
                                className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                            >
                                <PlusIcon className="h-3 w-3" />
                            </button>
                            </div>
                            <EtModalCreateUserImprintVisionOrg
                                isOpen={isCreateVisionOrgModalOpen}
                                userId={userId}
                                userOrgId={userOrgId}
                                triggeringUrl={createVisionOrgTriggeringUrl}
                                onClose={handleCloseCreateVisionOrgModal}
                            />
                        </div>
                    )}
                    {userVisionOrgs?.length >0 && (
                        <div>
                            <RenderVision 
                                userVisions={userVisionOrgs}
                                handleUpdate={handleUpdateUserVisionOrg}
                                selectedVisionId={selectedVisionId}
                                setSelectedVisionId={setSelectedVisionId}
                                handleCloseModal={handleCloseUpdateVisionOrgModal}
                                userId={userId}
                                userOrgId={userOrgId}
                                triggeringUrl={updateVisionOrgTriggeringUrl}
                                isOpen={isUpdateVisionOrgModalOpen}
                                ModalComponent={EtModalUpdateUserImprintVisionOrg} // 08 Jan 2025
                            />
                        </div>
                    )}
                </div>
            )}
            {activeTab === 'partners' && (
                <div className="m-1 p-1 rounded">
                    {userVisionPartners?.length === 0 && (
                        <div className="flex justify-between items-center">
                            <div>
                            <p className="m-1 p-1 text-xs">
                                You have not set a partner vision yet! Change that by
                                clicking the Add button.
                            </p>
                            </div>
                            <div className="flex justify-start items-center">
                            <p className="text-xs font-bold mx-1 px-1">Add</p>
                            <button
                                type="button"
                                onClick={handleCreateUserVisionPartners}
                                className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                            >
                                <PlusIcon className="h-3 w-3" />
                            </button>
                            </div>
                            <EtModalCreateUserImprintVisionPartner
                                isOpen={isCreateVisionPartnersModalOpen}
                                userId={userId}
                                userOrgId={userOrgId}
                                triggeringUrl={createVisionPartnersTriggeringUrl}
                                onClose={handleCloseCreateVisionPartnersModal}
                            />
                        </div>
                    )}
                    {userVisionPartners?.length >0 && (
                        <div>
                            <RenderVision 
                                userVisions={userVisionPartners}
                                handleUpdate={handleUpdateUserVisionPartners}
                                selectedVisionId={selectedVisionId}
                                setSelectedVisionId={setSelectedVisionId}
                                handleCloseModal={handleCloseUpdateVisionPartnersModal}
                                userId={userId}
                                userOrgId={userOrgId}
                                triggeringUrl={updateVisionPartnersTriggeringUrl}
                                isOpen={isUpdateVisionPartnersModalOpen}
                                ModalComponent={EtModalUpdateUserImprintVisionPartner} // 08 Jan 2025
                            />
                        </div>
                    )}
                </div>
            )}
            {activeTab === 'team' && (
                <div className="m-1 p-1 rounded">
                    {userVisionTeams?.length === 0 && (
                        <div className="flex justify-between items-center">
                            <div>
                            <p className="m-1 p-1 text-xs">
                                You have not set a team vision yet! Change that by
                                clicking the Add button.
                            </p>
                            </div>
                            <div className="flex justify-start items-center">
                            <p className="text-xs font-bold mx-1 px-1">Add</p>
                            <button
                                type="button"
                                onClick={handleCreateUserVisionTeam}
                                className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                            >
                                <PlusIcon className="h-3 w-3" />
                            </button>
                            </div>
                            <EtModalCreateUserImprintVisionTeam
                                isOpen={isCreateVisionTeamModalOpen}
                                userId={userId}
                                userOrgId={userOrgId}
                                triggeringUrl={createVisionTeamTriggeringUrl}
                                onClose={handleCloseCreateVisionTeamModal}
                            />
                        </div>
                    )}
                    {userVisionTeams?.length >0 && (
                        <div>
                            <RenderVision 
                                userVisions={userVisionTeams}
                                handleUpdate={handleUpdateUserVisionTeam}
                                selectedVisionId={selectedVisionId}
                                setSelectedVisionId={setSelectedVisionId}
                                handleCloseModal={handleCloseUpdateVisionTeamModal}
                                userId={userId}
                                userOrgId={userOrgId}
                                triggeringUrl={updateVisionTeamTriggeringUrl}
                                isOpen={isUpdateVisionTeamModalOpen}
                                ModalComponent={EtModalUpdateUserImprintVisionTeam} // 08 Jan 2025
                            />
                        </div>
                    )}
                </div>
            )}
        </div>

    </div>
  )
}

const RenderVision = ({
    userVisions =[], 
    handleUpdate, 
    selectedVisionId, 
    setSelectedVisionId, 
    handleCloseModal, 
    userId, 
    userOrgId, 
    triggeringUrl, 
    isOpen,
    ModalComponent, // 08 Jan 2025
}) => {
    // First Created - 07 Jan 2025
    // Author - Atul Pandey
    // Updated - 08 Jan 2025 - confirm incoming data
    // Updated - 08 Jan 2025 - made the modal component as a prop

    // 0 - confirm incoming data
    console.log('userVisions', userVisions)

    // 1 - Set state variables 
    
    const [ showVisionDetails, setShowVisionDetails] = useState(false)
    const [ showVisionNoteSection, setShowVisionNoteSection] = useState(false)
    const [ showVisionNoteDetails, setShowVisionNoteDetails] = useState({})
    const [ showVisionEvolutionSection, setShowVisionEvolutionSection] = useState(false)
    const [ showVisionTrailDetails, setShowVisionTrailDetails] = useState({})

    // 2a - Handle Toggle for show vision note details
    const handleToggleVisionNoteDetails = (noteId) => {
        setShowVisionNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
        }))
    }

    // 2a - Handle Toggle for show vision trail details -- 08 Jan 2025
    const handleToggleVisionTrailDetails = (noteId) => {
        setShowVisionTrailDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
        }))
    }
    
    
    // 3 - create Markup
    const createMarkup = (htmlContent) => {
        return { __html: htmlContent }
    }

    return(
        <div>
           {userVisions?.length >0 && userVisions?.map((vision, visionIndex) => {
                        const visionName = vision?.visionName || ""
                        const visionDesc = vision?.visionDesc || ""
                        const visionDate = vision?.visionDate || ""
                        const visionUpdateDate = vision?.visionUpdateDate || ""
                        const visionNotes = vision?.visionNotes || []
                        const visionTrail = vision?.visionTrail || []
                        
                        return(
                            <div key={vision._id+visionIndex} className="">
                                
                                <div className="bg-gray-200 rounded m-1 p-1">
                                    <div className="grid grid-cols-6 justify-start items-center">
                                        <div className='col-span-1 flex justify-start items-center m-1 p-1'>
                                            <div
                                                onClick={() => setShowVisionDetails(prev => !prev)}
                                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-gray-100"
                                            >
                                                {showVisionDetails ? (
                                                <MinusIcon className="h-4 w-4 text-orange-600" />
                                                ) : (
                                                <PlusIcon className="h-4 w-4 text-orange-600" />
                                                )}
                                            </div>
                                            <div>
                                                <EtImprintVisionSelfIcon1SVGComp className='h-5 w-5 text-orange-600'/>
                                            </div>
                                            <div>
                                                <div className='flex flex-col justify-center items-center mx-1 px-1'>
                                                    <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">{getTimeSince(visionUpdateDate)}</p>
                                                    <p className='text-[9px] text-gray-600 italic'>last updated</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <p className='font-bold text-sm mx-1 px-1 text-gray-600 '>{visionName}</p>
                                        </div>
                                        <div className="col-span-2 flex justify-center items-center mx-2">
                                            <ClockIcon className="h-5 w-5 text-lime-600" />
                                            <p className="text-[9px] mx-1">First Created:</p>
                                            <p className="text-gray-600 text-[9px] mx-2">{formatDateTime(visionDate)}</p>
                                        </div>
                                        <div className="col-span-1 flex justify-end items-center">
                                            <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                            <PencilSquareIcon
                                                className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                                onClick={() => handleUpdate(vision._id)}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                    {showVisionDetails && (
                                        <div className="bg-white rounded m-1 p-1">
                                            <p dangerouslySetInnerHTML={createMarkup(visionDesc)} className="text-gray-800 text-2xl mx-auto my-2 p-2 max-w-lg text-center"></p>
                                        </div>
                                    )}
                                </div>
                                
                                {visionNotes?.length>0 && (
                                    <div className="bg-gray-200 rounded m-1 p-1">
                                        <div className="grid grid-cols-8 justify-start items-center m-1 p-1">
                                            <div className="col-span-3 flex justify-start items-center">
                                                <div
                                                    onClick={() => setShowVisionNoteSection(prev => !prev)}
                                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-gray-100"
                                                >
                                                    {showVisionNoteSection ? (
                                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                                    ) : (
                                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                                    )}
                                                </div>

                                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                    <EtImprintVisionNotesIcon1SVGComp className="h-7 w-7 text-orange-600" />
                                                </div>
                                                <p className="mx-1 px-1 text-sm font-bold"> Vision Notes:</p>
                                            </div>
                                            <div className="col-span-1 flex justify-start items-center">
                                                <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{visionNotes?.length}</p></div>
                                            </div>
                                        </div>
                                        
                                        {showVisionNoteSection && visionNotes
                                        ?.slice()
                                        .sort(
                                            (a, b) =>
                                                new Date(b.visionNoteDate) -
                                                new Date(a.visionNoteDate)
                                        )
                                        .map((note, noteIndex)=>{
                                            const noteText = note?.visionNoteText || ""
                                            const noteDate = note?.visionNoteDate || ""
                                            const noteUpdateDate = note?.visionNoteUpdateDate || ""

                                            return (
                                                <div key={note._id+noteIndex} className="bg-gray-100 border rounded m-1 p-1">
                                                    <div className="grid grid-cols-8 justify-between items-center m-1 p-1 ">
                                                        <div className="col-span-2 flex justify-start items-center m-1 p-1">
                                                        <div
                                                            onClick={() =>
                                                                handleToggleVisionNoteDetails(note._id)
                                                            }
                                                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                        >
                                                            {showVisionNoteDetails[note._id] ? (
                                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                                            ) : (
                                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                                            )}
                                                        </div>
                                                        <div>
                                                            <p className="text-xl font-bold mx-1 px-1 text-gray-600">
                                                            {noteIndex + 1}.
                                                            </p>
                                                        </div>
                                                        <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                            <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
                                                        </div>
                                                        <div>
                                                            <div className="flex flex-col justify-center items-center mx-1 px-1">
                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                                {getTimeSince(noteUpdateDate)}
                                                            </p>
                                                            <p className="text-[9px] text-gray-600 italic">
                                                                last updated
                                                            </p>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className="col-span-4">
                                                        <div
                                                            dangerouslySetInnerHTML={createMarkup(noteText)}
                                                            className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                        ></div>
                                                        </div>
                                                        <div className="col-span-2 flex justify-center items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">
                                                            {formatDateTime(noteDate)}
                                                        </p>
                                                        </div>
                                                    </div>
                                                    {showVisionNoteDetails[note._id] && (
                                                    <div
                                                        dangerouslySetInnerHTML={createMarkup(noteText)}
                                                        className="text-xs text-gray-600 m-1 p-1 rounded leading-4 bg-white"
                                                    ></div>
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}

                                {visionTrail?.length>0 && (
                                    <div className="bg-gray-200 rounded m-1 p-1">
                                        <div className="grid grid-cols-8 justify-start items-center m-1 p-1">
                                            <div className="col-span-3 flex justify-start items-center">
                                                <div
                                                    onClick={() => setShowVisionEvolutionSection(prev => !prev)}
                                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-gray-100"
                                                >
                                                    {showVisionEvolutionSection ? (
                                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                                    ) : (
                                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                                    )}
                                                </div>

                                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                    <EtImprintVisionEvolutionIcon1SVGComp className="h-7 w-7 text-orange-600" />
                                                </div>
                                                <p className="mx-1 px-1 text-sm font-bold"> Vision Evolution:</p>
                                            </div>
                                            <div className="col-span-1 flex justify-start items-center">
                                                <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{visionTrail?.length}</p></div>
                                            </div>
                                        </div>
                                        
                                        {showVisionEvolutionSection && visionTrail
                                        ?.slice()
                                        .sort(
                                            (a, b) =>
                                                new Date(b.visionUpdateDate) -
                                                new Date(a.visionUpdateDate)
                                        )
                                        .map((item, itemIndex)=>{
                                            const visionName = item?.visionName || ""
                                            const visionDesc = item?.visionDesc || ""
                                            const visionDate = item?.visionDate || ""
                                            const visionUpdateDate = item?.visionUpdateDate || ""

                                            return (
                                                <div key={item._id+itemIndex} className="bg-gray-100 border rounded m-1 p-1">
                                                    <div className="grid grid-cols-8 justify-between items-center m-1 p-1 ">
                                                        <div className="col-span-2 flex justify-start items-center m-1 p-1">
                                                        <div
                                                            onClick={() =>
                                                                handleToggleVisionTrailDetails(item._id)
                                                            }
                                                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                        >
                                                            {showVisionTrailDetails[item._id] ? (
                                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                                            ) : (
                                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                                            )}
                                                        </div>
                                                        <div>
                                                            <p className="text-xl font-bold mx-1 px-1 text-gray-600">
                                                            {itemIndex + 1}.
                                                            </p>
                                                        </div>
                                                        <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                            <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
                                                        </div>
                                                        <div>
                                                            <div className="flex flex-col justify-center items-center mx-1 px-1">
                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                                {getTimeSince(visionUpdateDate)}
                                                            </p>
                                                            <p className="text-[9px] text-gray-600 italic">
                                                                last updated
                                                            </p>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className="col-span-4">
                                                        <div><p className="text-sm mx-1 px-1">{visionName}</p></div>
                                                        <div
                                                            dangerouslySetInnerHTML={createMarkup(visionDesc)}
                                                            className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                        ></div>
                                                        </div>
                                                        <div className="col-span-2 flex justify-center items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">
                                                            {formatDateTime(visionDate)}
                                                        </p>
                                                        </div>
                                                    </div>
                                                    {showVisionTrailDetails[item._id] && (
                                                    <div
                                                        dangerouslySetInnerHTML={createMarkup(visionDesc)}
                                                        className="text-xs text-gray-600 m-1 p-1 rounded leading-4 bg-white"
                                                    ></div>
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                                
                                
                            </div>
                        )
            })}
            {/* <EtModalUpdateUserImprintVisionSelf 
                isOpen={isOpen}
                id={selectedVisionId}
                userId={userId}
                userOrgId={userOrgId}
                triggeringUrl={triggeringUrl}
                onClose={handleCloseModal}
            />  */}
            {ModalComponent && (
                <ModalComponent
                    isOpen={isOpen}
                    id={selectedVisionId}
                    userId={userId}
                    userOrgId={userOrgId}
                    triggeringUrl={triggeringUrl}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    )
}

export default EtImprintVision