import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../../app/api/apiSlice'

const userImprintLegacysAdapter = createEntityAdapter({})

const initialState = userImprintLegacysAdapter.getInitialState()

export const userImprintLegacysApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserImprintLegacys: builder.query({
            query: () => ({
                url: '/userImprintLegacys/getUserImprintLegacys',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserImprintLegacys = responseData.map(userImprintLegacy => {
                    userImprintLegacy.id = userImprintLegacy._id
                    return userImprintLegacy
                })
                return userImprintLegacysAdapter.setAll(initialState, loadedUserImprintLegacys)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserImprintLegacy', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserImprintLegacy', id}))
                    ]
                } else {
                    return [ { type: 'UserImprintLegacy', id: 'LIST'}]
                }
            },
        }),
        createNewUserImprintLegacy: builder.mutation({
            query: initialUserImprintLegacy => ({
                url: '/userImprintLegacys/createNewUserImprintLegacy',
                method: 'POST',
                body: {...initialUserImprintLegacy,}
            }),
            invalidatesTags: [
                { type: 'UserImprintLegacy', id: "LIST" }
            ] 
        }),
        updateUserImprintLegacy: builder.mutation({
            query: initialUserImprintLegacy => ({
                url: '/userImprintLegacys/updateUserImprintLegacy',
                method: 'PATCH',
                body: {...initialUserImprintLegacy,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserImprintLegacy', id: arg.id },
                { type: 'UserImprintLegacy', id: 'LIST' }
            ],
        }),
        deleteUserImprintLegacy: builder.mutation({
            query: ({id}) => ({
                url: '/userImprintLegacys/deleteUserImprintLegacy',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserImprintLegacy', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserImprintLegacysQuery,
    useCreateNewUserImprintLegacyMutation,
    useUpdateUserImprintLegacyMutation,
    useDeleteUserImprintLegacyMutation,
} = userImprintLegacysApiSlice

// returns the query result object
export const selectUserImprintLegacyResult = userImprintLegacysApiSlice.endpoints.getUserImprintLegacys.select()

// create memoized selector
const selectUserImprintLegacysData = createSelector(
    selectUserImprintLegacyResult,
    userImprintLegacyResult => userImprintLegacyResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserImprintLegacys,
    selectById: selectUserImprintLegacyById,
    selectIds: selectUserImprintLegacyIds,
} = userImprintLegacysAdapter.getSelectors(state => selectUserImprintLegacysData(state) ?? initialState)