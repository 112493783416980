import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const questionWithScoresAdapter = createEntityAdapter({})

const initialState = questionWithScoresAdapter.getInitialState()

export const questionWithScoresApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getQuestionWithScores: builder.query({
            query: () => ({
                url: '/questions/questionWithScores',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedQuestionWithScores = responseData.map(questionWithScores => {
                    questionWithScores.id = questionWithScores._id
                    return questionWithScores
                })
                return questionWithScoresAdapter.setAll(initialState, loadedQuestionWithScores)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'QuestionWithScores', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'QuestionWithScores', id}))
                    ]
                } else {
                    return [ { type: 'QuestionWithScores', id: 'LIST'}]
                }
            },
        }),
        addNewQuestionWithScores: builder.mutation({
            query: initialQuestionWithScores => ({
                url: '/questions/questionWithScores',
                method: 'POST',
                body: {...initialQuestionWithScores,}
            }),
            invalidatesTags: [
                { type: 'QuestionWithScores', id: "LIST" }
            ] 
        }),
        updateQuestionWithScores: builder.mutation({
            query: initialQuestionWithScores => ({
                url: '/questions/questionWithScores',
                method: 'PATCH',
                body: {...initialQuestionWithScores,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'QuestionWithScores', id: arg.id },
                { type: 'QuestionWithScores', id: 'LIST' }
            ],
        }),
        deleteQuestionWithScores: builder.mutation({
            query: ({id}) => ({
                url: '/questions/questionWithScores',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'QuestionWithScores', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetQuestionWithScoresQuery,
    useAddNewQuestionWithScoresMutation,
    useUpdateQuestionWithScoresMutation,
    useDeleteQuestionWithScoresMutation,
} = questionWithScoresApiSlice

// returns the query result object
export const selectQuestionWithScoresResult = questionWithScoresApiSlice.endpoints.getQuestionWithScores.select()

// create memoized selector
const selectQuestionWithScoresData = createSelector(
    selectQuestionWithScoresResult,
    questionWithScoresResult => questionWithScoresResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllQuestionWithScores,
    selectById: selectQuestionWithScoresById,
    selectIds: selectQuestionWithScoresIds,
} = questionWithScoresAdapter.getSelectors(state => selectQuestionWithScoresData(state) ?? initialState)