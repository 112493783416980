import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLightWithPlace from "@/utils/EtContentEditorLightWithPlace"
import EtContentEditorLight3 from "@/utils/EtContentEditorLight3"
import { useCreateNewSectionMutation } from "./sectionsApiSlice"
import { useGetQuestionSetsQuery } from "../questionSets/questionSetsApiSlice"
import { useGetQuestionsQuery } from "../questions/questionsApiSlice"

const EtCreateSection = ({setActiveAdminView}) => {
  // First Created - 10 Apr 2024
  // Author - Atul Pandey
  // Updated - 01 Mar 2025 - updated the softSkillFunctionArea with props - { setActiveAdminView }
  // Updated - 01 Mar 2025 - switched from navigate to setActiveAdminView

  // 0 - Set Page Title
  useTitle('Create Assessment Section')
  
  // 1 - set page navigations
  const navigate = useNavigate()

  // 2 - set the mutation to create the new section data
  const[createNewSection, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewSectionMutation()


  // 3 - get all question sets

const { qSets } = useGetQuestionSetsQuery('questionSetList', {
  selectFromResult: ( { data }) => ({
    qSets: data?.ids.map(id => data?.entities[id])
  })
})

// 4 - get all questions

const { questions } = useGetQuestionsQuery('questionList', {
  selectFromResult: ( { data }) => ({
    questions: data?.ids.map(id => data?.entities[id])
  })
})

// console.log('questions:', questions)

// 4a - New state to hold the currently selected question ID
const [selectedQuestion, setSelectedQuestion] = useState("")
const [allQuestionsAdded, setAllQuestionsAdded] = useState(false)

// 5 - Create options to select a question set from list of question sets

const qSetOptions = qSets ? [
  <option key="placeholder" value="" disabled selected>
    Select a question set
  </option>,
  ...qSets.map(set => (
    <option key={set.id} value={set.id}>
      {set.setname}
    </option>
  ))
] : []

// 6 - Create options to select a question from list of questions for the selected question set

// const questionOptions = questions.filter(question => question.setname === formData.questionSetId) // Adjust this condition based on your actual data structure
//   .map(question => (
//     <option key={question.id} value={question.id}>
//       {question.questionName}
//     </option>
//   ))

// 7 - create the form data object
const [formData, setFormData] = useState({
  sectionName: "",
  sectionIntro: "",
  sectionOutro: "",
  aboutMe: "",
  pic: "",
  questionSetId: "",
  questions: [],
})

// 8 - Reset form upon successful submission

useEffect(() => {
  if(isSuccess) {
    setFormData({
    sectionName: "",
    sectionIntro: "",
    sectionOutro: "",
    aboutMe: "",
    pic: "",
    questionSetId: "",
    questions: [],
    })
 // navigate('/sections')
 setActiveAdminView('sections'); // 01 Mar 2025
}

},[isSuccess])// ✅ 01 Mar 2025 - Removed `navigate` since it's no longer used.



// 9 - Form field updates / change handling

  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData(prevState => ({ ...prevState, pic: files[0] }))
    } else if (name === "questionSetId") {
      // Reset questions when changing the question set
      setFormData(prevState => ({ ...prevState, questionSetId: value, questions: [] }))
    } else {
      setFormData(prevState => ({ ...prevState, [name]: value }))
    }
  }

  // 9a - New function to handle adding selected question
  const handleAddQuestion = () => {
    if (selectedQuestion && !formData.questions.includes(selectedQuestion)) {
      setFormData(prevState => ({
        ...prevState,
        questions: [...prevState.questions, selectedQuestion]
      }));
      setSelectedQuestion('')
    }
  }

  // 9b - New function to handle removing a question
  const handleRemoveQuestion = (questionId) => {
    setFormData(prevState => ({
      ...prevState,
      questions: prevState.questions.filter(id => id !== questionId)
    }))
    
  }

  // 9c - function to strip html tags from questions
  function stripHtml(html) {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
  }

  // 9d - handle select change for question
  const handleSelectChange = (e) => {
    setSelectedQuestion(e.target.value);
  }

  let filteredQuestions = questions?.filter(question => question.setname === formData.questionSetId) || []
  console.log('filteredQuestions:', filteredQuestions)

  useEffect(() => {
    // Check if all questions from the set have been added
    setAllQuestionsAdded(filteredQuestions.length === formData.questions.length);
  }, [formData.questions, filteredQuestions.length]);

// 10 - Form Submit Logic
const handleSubmit = async (e) => {
  e.preventDefault()

  // Initialize an object to collect the form data
  const sectionData = {
    sectionName: formData.sectionName,
    sectionIntro: formData.sectionIntro,
    sectionOutro: formData.sectionOutro,
    aboutMe: formData.aboutMe,
    questionSetId: formData.questionSetId,
    questions: formData.questions,  
  }

  console.log('section Name:',sectionData.sectionName)

  if (formData.pic) {
    // Convert the image to a base64 string
    const reader = new FileReader()
    reader.onloadend = async () => {
      // Once conversion is done, add the base64 string to your data object
      sectionData.pic = reader.result

      // Now, send the data object to the server
      try {
        console.log("Form submitted.")
        await createNewSection(sectionData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    }
    reader.readAsDataURL(formData.pic)
  } else {
    // No image, send other data as is
    try {
      console.log("Form submitted.")
      await createNewSection(sectionData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
  }
}

// 11 - Handle Cancel
 
  const handleCancel = () => {
    // navigate('/sections')
 setActiveAdminView('sections'); // 01 Mar 2025
  }

// 12 - Handle Clear
  const handleClear = () => {
    setFormData({
    sectionName: "",
    sectionIntro: "",
    sectionOutro: "",
    aboutMe: "",
    pic: "",
    questionSetId: "",
    questions: [],
    })
  }


  return (
    <div className="bg-gray-200 p-1 w-3/4 mx-auto mt-5 rounded-lg shadow-md shadow-black">
      {isLoading && <PulseLoader color={"#ea580c"} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">
          {error?.data?.message}
        </p>
      )}
      <div className="bg-white p-1 m-1 rounded">
        <p className="p-1 m-1 font-bold font-poppins text-xl">Add a Section</p>

        <div className="bg-gray-200 rounded m-1 p-1">
          <form
            onSubmit={handleSubmit}
            className="m-1 p-1 text-xs font-poppins"
          >
            <div id="questionSetSelector">
              <select
                name="questionSetId"
                id="questionSetId"
                value={formData.questionSetId}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              >
                {qSetOptions}
              </select>
            </div>

            <div id="sectionName">
              <input
                type="text"
                name="sectionName"
                placeholder="Enter the section name here"
                value={formData.sectionName}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div className="bg-white w-full rounded p-2 m-1">
              <EtContentEditorLightWithPlace
                value={formData.sectionIntro}
                handleChange={(content) =>
                  setFormData({ ...formData, sectionIntro: content })
                }
                placeholderText={
                  "Write what people would see when this section starts!"
                }
                // toolbarId={'sectionIntro'}
              />
            </div>

            <div className="bg-white w-full rounded p-2 m-1">
              <EtContentEditorLight3
                value={formData.sectionOutro}
                handleChange={(content) =>
                  setFormData({ ...formData, sectionOutro: content })
                }
                placeholderText={
                  "Write what people would see when this section ends!"
                }
                toolbarId={"sectionOurtro"}
              />
            </div>

            <div id="aboutMe">
              <input
                type="text"
                name="aboutMe"
                placeholder="Add something about the personality about this section"
                value={formData.aboutMe}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="pic">
              <input
                type="file"
                name="pic"
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            {/* Question Selector and Add Button */}
            <div>
              <div className="flex justify-between items-center my-2">
                <select
                  onChange={handleSelectChange}
                  value={selectedQuestion}
                  className="w-full rounded p-2 mr-2"
                >
                  <option value="" disabled>
                    Select a question
                  </option>
                  {filteredQuestions.map((question) => (
                    <option key={question.id} value={question.id}>
                      {stripHtml(question.questionName)}
                    </option>
                  ))}
                </select>
                {!allQuestionsAdded && (
                <button
                  type="button"
                  onClick={handleAddQuestion}
                  className="py-2 px-4 rounded bg-orange-600 text-white"
                >
                  +
                </button>)}
              </div>

              {/* Display Selected Questions with Remove Option */}
              {formData.questions.map((questionId, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center bg-gray-100 p-2 rounded my-1"
                >
                  <span>

                    {stripHtml(filteredQuestions.find(
                      (question) => question.id === questionId
                    )?.questionName) || "Question not found"}
                  </span>
                  <button
                    type="button"
                    onClick={() => handleRemoveQuestion(questionId)}
                    className="py-1 px-3 rounded bg-red-500 text-white"
                  >
                    -
                  </button>
                </div>
              ))}
            </div>

            {/* 10 Apr 2024 - Question Options */}

            <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Create"}
                type={"create"}
                handleClick={handleSubmit}
              />
              <EtBtnFormBasic
                name={"Clear"}
                type={"clear"}
                handleClick={handleClear}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EtCreateSection