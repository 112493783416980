
    import React from 'react';
    
    const EtImprintEtIcon2SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2103 1057" fill="currentColor">
          <g transform="translate(-130 -152)"><path d="M2031.52 152C2097.16 152 2162.02 152.924 2225.94 154.728L2233 155.028 2051.26 162.737C1184.76 212.018 532.957 425.103 532.957 680.5 532.957 935.897 1184.76 1148.98 2051.26 1198.26L2233 1205.97 2225.94 1206.27C2162.02 1208.08 2097.16 1209 2031.52 1209 981.34 1209 130 972.382 130 680.5 130 388.617 981.34 152 2031.52 152Z" fillRule="evenodd"/><path d="M552 680.5C552 488.581 906.591 333 1344 333 1781.41 333 2136 488.581 2136 680.5 2136 872.419 1781.41 1028 1344 1028 906.591 1028 552 872.419 552 680.5Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtImprintEtIcon2SVGComp;
    