import { apiSlice } from '@/app/api/apiSlice';

export const trinityApiSlice = apiSlice.injectEndpoints({
    // First Created - 28 Jan 2025
    // Author - Atul Pandey
    // Purpose - to connect the Trinity AI front end to the backend
    // Updated - 28 Jan 2025 - changed the query params from prompt to messages

    endpoints: (builder) => ({
        generateTrinityResponse: builder.mutation({
            // query: ({ prompt, maxTokens, model }) => ({
            //     url: '/trinity/generateResponse',
            //     method: 'POST',
            //     body: { prompt, maxTokens, model },
            //     validateStatus: (response, result) => {
            //         return response.status === 200 && !result.isError;
            //     },
            // }),
            query: ({ messages, maxTokens, model }) => ({
                url: '/trinity/generateResponse',
                method: 'POST',
                body: { messages, maxTokens, model },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
        }),
    }),
});

export const {
    useGenerateTrinityResponseMutation,
} = trinityApiSlice;
