import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userRecognitionsNationalsAdapter = createEntityAdapter({})

const initialState = userRecognitionsNationalsAdapter.getInitialState()

export const userRecognitionsNationalsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserRecognitionsNationals: builder.query({
            query: () => ({
                url: '/userRecognitionsNationals/getUserRecognitionsNationals',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserRecognitionsNationals = responseData.map(userRecognitionsNational => {
                    userRecognitionsNational.id = userRecognitionsNational._id
                    return userRecognitionsNational
                })
                return userRecognitionsNationalsAdapter.setAll(initialState, loadedUserRecognitionsNationals)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserRecognitionsNational', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserRecognitionsNational', id}))
                    ]
                } else {
                    return [ { type: 'UserRecognitionsNational', id: 'LIST'}]
                }
            },
        }),
        createNewUserRecognitionsNational: builder.mutation({
            query: initialUserRecognitionsNational => ({
                url: '/userRecognitionsNationals/createNewUserRecognitionsNational',
                method: 'POST',
                body: {...initialUserRecognitionsNational,}
            }),
            invalidatesTags: [
                { type: 'UserRecognitionsNational', id: "LIST" }
            ] 
        }),
        updateUserRecognitionsNational: builder.mutation({
            query: initialUserRecognitionsNational => ({
                url: '/userRecognitionsNationals/updateUserRecognitionsNational',
                method: 'PATCH',
                body: {...initialUserRecognitionsNational,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserRecognitionsNational', id: arg.id },
                { type: 'UserRecognitionsNational', id: 'LIST' }
            ],
        }),
        deleteUserRecognitionsNational: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userRecognitionsNationals/deleteUserRecognitionsNational',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userRecognitionsNationals/deleteUserRecognitionsNational',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserRecognitionsNational', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserRecognitionsNationalsQuery,
    useCreateNewUserRecognitionsNationalMutation,
    useUpdateUserRecognitionsNationalMutation,
    useDeleteUserRecognitionsNationalMutation,
} = userRecognitionsNationalsApiSlice

// returns the query result object
export const selectUserRecognitionsNationalResult = userRecognitionsNationalsApiSlice.endpoints.getUserRecognitionsNationals.select()

// create memoized selector
const selectUserRecognitionsNationalsData = createSelector(
    selectUserRecognitionsNationalResult,
    userRecognitionsNationalResult => userRecognitionsNationalResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserRecognitionsNationals,
    selectById: selectUserRecognitionsNationalById,
    selectIds: selectUserRecognitionsNationalIds,
} = userRecognitionsNationalsAdapter.getSelectors(state => selectUserRecognitionsNationalsData(state) ?? initialState)