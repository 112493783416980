
    import React from 'react';
    
    const EtMagnetIconSVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <defs>
  <clipPath id="d">
   <path d="m733 1134h1405v1336h-1405z" fillRule="evenodd"/>
  </clipPath>
  <clipPath id="c">
   <path d="m196.89 1800 1603.1-1603.1 1603.1 1603.1-1603.1 1603.1z" fillRule="evenodd"/>
  </clipPath>
  <clipPath id="b">
   <path d="m2315 1646h738v293h-738z" fillRule="evenodd"/>
  </clipPath>
  <clipPath id="a">
   <path d="m196.89 1800 1603.1-1603.1 1603.1 1603.1-1603.1 1603.1z" fillRule="evenodd"/>
  </clipPath>
 </defs>
 <g clip-path="url(#d)">
  <g clip-path="url(#c)">
   <path d="m2096.8 2056.9c-5.0664 1.0898-508.27 108.68-676.56 123.91-89.117 8.0977-176.77-22.684-246.83-86.648-80.043-73.117-127.85-181.97-127.83-292.31 0-104.17 42.16-206.06 115.65-279.55 72.043-72.043 164.03-106.96 259.03-98.336 168.26 15.262 671.48 122.83 676.53 123.91 10.051 2.1641 20.535-0.33594 28.504-6.7969 8.1602-6.4609 12.648-16.176 12.633-26.453v-216.16c0.015625-15.855-10.949-29.609-26.402-33.137-21.562-4.9531-529.88-121.28-706.65-129.72-175.54-8.3984-342.82 57.602-471.06 185.83-125.36 125.36-198.16 298.68-200.18 476.43-0.03125 0.57812-0.03125 1.1523-0.03125 1.7305v5.6445c-0.015625 0.5625 0.015625 1.1055 0.03125 1.6367 1.9883 184.23 79.355 362.11 212.67 488.66 126.5 120.09 289.38 181.78 458.59 173.68 176.77-8.4492 685.09-124.77 706.65-129.72 6.3945-1.4922 12.023-4.6797 16.434-9.0898 6.2188-6.2188 9.9727-14.75 9.9727-24.047v-216.16c0-10.262-4.6484-20.008-12.633-26.453-7.9922-6.4414-18.457-8.9922-28.512-6.8281zm-1103.7 189.27c-120.97-114.85-190.79-276.65-191.51-443.64 0.70703-161.3 66.418-319.16 180.29-433.03 114.51-114.51 263.57-173.44 419.73-165.98 56.492 2.6914 151.12 17.523 252.6 36.184l-0.015624 151.03c-90.848-16.305-174.61-29.691-227.84-34.531-115.46-10.484-226.71 31.422-313.28 117.99-86.168 86.168-135.56 205.58-135.56 328.73 0 128.02 56.062 255.65 149.99 341.43 84.34 77.031 190.46 114.03 298.84 104.19 53.238-4.8242 137-18.211 227.85-34.516v151.05c-101.49 18.645-196.08 33.457-252.6 36.148-150.48 7.2266-295.57-47.824-408.49-155.04z" fillRule="evenodd"/>
  </g>
 </g>
 <g clip-path="url(#b)">
  <g clip-path="url(#a)">
   <path d="m3005 1743-272.29 113.48-31.836-182.18c-1.8906-10.805-8.8633-20.023-18.742-24.77-9.875-4.7461-21.434-4.4727-31.051 0.78516l-318.04 173.57c-16.449 9.043-22.523 29.672-13.547 46.152 8.9922 16.496 29.656 22.57 46.137 13.562l276.41-150.85 30.988 177.35c1.7812 10.18 8.0977 18.996 17.152 23.949 9.0586 4.9531 19.895 5.5625 29.434 1.5703l311.57-129.87c4.2305-1.7617 7.9023-4.2812 10.949-7.3281 9.457-9.457 12.809-24.031 7.3594-37.145-7.25-17.344-27.16-25.52-44.488-18.289z" fillRule="evenodd"/>
  </g>
 </g>

        </svg>
    );
    
    export default EtMagnetIconSVGComp;
    