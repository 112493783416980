import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userSessionIntrospectionsAdapter = createEntityAdapter({})

const initialState = userSessionIntrospectionsAdapter.getInitialState()

export const userSessionIntrospectionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserSessionIntrospections: builder.query({
            query: () => ({
                url: '/userSessionIntrospections/getUserSessionIntrospections',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserSessionIntrospections = responseData.map(userSessionIntrospection => {
                    userSessionIntrospection.id = userSessionIntrospection._id
                    return userSessionIntrospection
                })
                return userSessionIntrospectionsAdapter.setAll(initialState, loadedUserSessionIntrospections)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserSessionIntrospection', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserSessionIntrospection', id}))
                    ]
                } else {
                    return [ { type: 'UserSessionIntrospection', id: 'LIST'}]
                }
            },
        }),
        createNewUserSessionIntrospection: builder.mutation({
            query: initialUserSessionIntrospection => ({
                url: '/userSessionIntrospections/createNewUserSessionIntrospection',
                method: 'POST',
                body: {...initialUserSessionIntrospection,}
            }),
            invalidatesTags: [
                { type: 'UserSessionIntrospection', id: "LIST" }
            ] 
        }),
        updateUserSessionIntrospection: builder.mutation({
            query: initialUserSessionIntrospection => ({
                url: '/userSessionIntrospections/updateUserSessionIntrospection',
                method: 'PATCH',
                body: {...initialUserSessionIntrospection,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserSessionIntrospection', id: arg.id },
                { type: 'UserSessionIntrospection', id: 'LIST' }
            ],
        }),
        deleteUserSessionIntrospection: builder.mutation({
            query: ({id}) => ({
                url: '/userSessionIntrospections/deleteUserSessionIntrospection',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserSessionIntrospection', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserSessionIntrospectionsQuery,
    useCreateNewUserSessionIntrospectionMutation,
    useUpdateUserSessionIntrospectionMutation,
    useDeleteUserSessionIntrospectionMutation,
} = userSessionIntrospectionsApiSlice

// returns the query result object
export const selectUserSessionIntrospectionResult = userSessionIntrospectionsApiSlice.endpoints.getUserSessionIntrospections.select()

// create memoized selector
const selectUserSessionIntrospectionsData = createSelector(
    selectUserSessionIntrospectionResult,
    userSessionIntrospectionResult => userSessionIntrospectionResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserSessionIntrospections,
    selectById: selectUserSessionIntrospectionById,
    selectIds: selectUserSessionIntrospectionIds,
} = userSessionIntrospectionsAdapter.getSelectors(state => selectUserSessionIntrospectionsData(state) ?? initialState)