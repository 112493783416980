
    import React from 'react';
    
    const EtSponsorEvalIcon2SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m1954.1 246.28c207.41 96.883 347.39 297.07 367.19 525.14 19.801 228.07-83.574 449.38-271.19 580.56-187.61 131.18-430.96 152.31-638.38 55.43-207.42-96.887-347.39-297.07-367.19-525.14-19.805-228.07 83.57-449.38 271.18-580.57 187.61-131.18 430.96-152.31 638.38-55.426" fillRule="evenodd"/>
 <path d="m3031.2 2278.8c280.8 0 507.6-226.8 507.6-507.6s-226.8-507.6-507.6-507.6-507.6 226.8-507.6 507.6c0 162 79.199 309.6 198 399.6l-158.4 356.4-439.2 14.402s-255.6-432-399.6-669.6c-194.4-316.8-705.6-666-1087.2-331.2-374.4 331.2-489.6 1148.4-518.4 1731.6-3.6016 75.602 57.598 140.4 133.2 140.4h1152c72 0 133.2-61.199 133.2-133.2v-576l172.8 288c28.801 50.398 75.602 90 126 115.2 43.199 18 86.398 28.801 133.2 28.801l788.4-28.801c162-3.6016 288-140.4 280.8-302.4-3.6016-133.2-97.199-241.2-219.6-273.6l115.2-262.8c36.004 7.1992 64.805 10.801 97.203 10.801zm0-777.6c147.6 0 270 118.8 270 270s-118.8 270-270 270c-147.6 0-270-118.8-270-270s122.4-270 270-270z" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtSponsorEvalIcon2SVGComp;
    