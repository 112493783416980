import React, { useState, useEffect } from 'react'
import useTitle from "../../../hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
    PencilSquareIcon,
  } from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "../../../utils/EtContentEditorLight2"
import EtBtnFormBasic from "../../../components/common/buttons/EtBtnFormBasic"
import { useNavigate } from "react-router"
import { useCreateNewUserImprintRoleMutation, useGetUserImprintRolesQuery } from './userImprintRolesApiSlice'
import { useGetCapabilitiesIndustryTypesQuery } from '@/features/capabilitiesIndustryTypes/capabilitiesIndustryTypesApiSlice'
import { useGetCapabilitiesDomainTypesQuery } from '@/features/capabilitiesDomainTypes/capabilitiesDomainTypesApiSlice'
import { useGetCapabilitiesFunctionTypesQuery } from '@/features/capabilitiesFunctionTypes/capabilitiesFunctionTypesApiSlice'
import { useGetCapabilitiesTechAreaTypesQuery } from '@/features/capabilitiesTechAreaTypes/capabilitiesTechAreaTypesApiSlice'
import { useGetCapabilitiesSalesTypesQuery } from '@/features/capabilitiesSalesTypes/capabilitiesSalesTypesApiSlice'
import { useGetCapabilitiesServiceTypesQuery } from '@/features/capabilitiesServiceTypes/capabilitiesServiceTypesApiSlice'
import { useGetCapabilitiesAdvisoryTypesQuery } from '@/features/capabilitiesAdvisoryTypes/capabilitiesAdvisoryTypesApiSlice'
import { useGetContributionTypesQuery } from '@/features/contributionTypes/contributionTypesApiSlice'
import { useGetImpactTypesQuery } from '@/features/impactTypes/impactTypesApiSlice'

import { produce } from 'immer';

const EtUpdateUserImprintRoleComp = ({
    userId, 
    userOrgId, 
    triggeringUrl, 
    onClose
  }) => {

  // First Created - 23 Nov 2024
  // Author - Atul Pandey
  // Inherited from the EtCreateUserImprintCapabilitiesIndustry component
  // Updated - 29 Nov 2024 - refined the listing of the array elements (from push to unshift)
  // Updated - 29 Nov 2024 - added code to make each array element collapsible for compact display in form
  // Updated - 30 Nov - 01 Dec 2024 - refined all the section codes to remove data via delete flag and updated the handleSubmit form with these changes

  // 0 - Set Page title & variables
  useTitle('User Imprint Roles')

  // 1a - set page navigations
  const navigate = useNavigate()

  // 1b - set form states
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState({}); // 29 Nov 2024
  
  // 2a - set the mutation to create the new user imprint Role data
  const [createNewUserImprintRole, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewUserImprintRoleMutation()

  // 2b - Get existing user imprint roles Data from the backend
  const { 
    data: userImprintRoleData,
    isLoading: isFetching,
    isError: isFetchError,
    error: fetchError,
  } = useGetUserImprintRolesQuery()
  const userImprintRoleRecords = userImprintRoleData?.ids
  ?.map(id => userImprintRoleData?.entities[id])
  ?.filter(item=> item.userId._id === userId && item.userOrgId._id === userOrgId) || []
  console.log('userImprintRoles', userImprintRoleRecords)

  // 2c - Get Data for building Role Focus Areas
  const { data: industryTypesData} = useGetCapabilitiesIndustryTypesQuery()
  const { data: domainTypesData} = useGetCapabilitiesDomainTypesQuery()
  const { data: functionTypesData} = useGetCapabilitiesFunctionTypesQuery()
  const { data: techAreaTypesData} = useGetCapabilitiesTechAreaTypesQuery()
  const { data: salesTypesData} = useGetCapabilitiesSalesTypesQuery()
  const { data: serviceTypesData} = useGetCapabilitiesServiceTypesQuery()
  const { data: advisoryTypesData} = useGetCapabilitiesAdvisoryTypesQuery()
  const { data: contributionTypesData} = useGetContributionTypesQuery()
  const { data: impactTypesData} = useGetImpactTypesQuery()

  const extractEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id]);
  };

  const industryTypes = extractEntitiesFromAdapter(industryTypesData);
  const domainTypes = extractEntitiesFromAdapter(domainTypesData);
  const functionTypes = extractEntitiesFromAdapter(functionTypesData);
  const techAreaTypes = extractEntitiesFromAdapter(techAreaTypesData);
  const salesTypes = extractEntitiesFromAdapter(salesTypesData);
  const serviceTypes = extractEntitiesFromAdapter(serviceTypesData);
  const advisoryTypes = extractEntitiesFromAdapter(advisoryTypesData);
  const contributionTypes = extractEntitiesFromAdapter(contributionTypesData);
  const impactTypes = extractEntitiesFromAdapter(impactTypesData);

  // 3a - Initialize form data state
  const [formData, setFormData] = useState({
    userImprintRoleHeadlineName: '',
    userImprintRoleHeadlineDesc: '',
    userImprintRoles: [], // Array for roles
    userImprintRoleSelfNotes: [], // Array for self-notes
    userImprintRoleValidationNotes: [], // Array for validation notes
    aboutMe: '',
    pic: '',
    active: true,
  });
  
  // 3b - Set the FormData based on the incoming data
  useEffect(() => {
    if (userImprintRoleRecords.length > 0 && !isFormInitialized) {
      const selectedRecord = userImprintRoleRecords[0];
  
      setFormData({
        userImprintRoleHeadlineName: selectedRecord.userImprintRoleHeadlineName || '',
        userImprintRoleHeadlineDesc: selectedRecord.userImprintRoleHeadlineDesc || '',
        userImprintRoles: selectedRecord.userImprintRoles || [],
        userImprintRoleSelfNotes: selectedRecord.userImprintRoleSelfNotes || [],
        userImprintRoleValidationNotes: selectedRecord.userImprintRoleValidationNotes || [],
        aboutMe: selectedRecord.aboutMe || '',
        pic: selectedRecord.pic || '',
        active: selectedRecord.active ?? true,
      });
      setIsFormInitialized(true); // Prevent future overwrites
    }
  }, [userImprintRoleRecords, isFormInitialized]);

  // 4 - State variables for the editor
  const [editorKey, setEditorKey] = useState('roleDesc-' + Date.now());
  const [editorValue, setEditorValue] = useState('');
  
  // 5a - Handle form changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'pic') {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  // 5b - Toggle show / collapse each section - 29 Nov 2024
  const toggleSection = (key) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };  

  // 5c - Convert pic to Base64
  const convertPicToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });

  // 6 - Handle Submit 
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Step 1: Convert the pic field to Base64 if it exists
      const picBase64 = formData.pic ? await convertPicToBase64(formData.pic) : undefined;

      // Step 2: Construct the payload
      const payload = {
        userId,
        userOrgId,
        userImprintRoleHeadlineName: formData.userImprintRoleHeadlineName.trim() || undefined,
        userImprintRoleHeadlineDesc: formData.userImprintRoleHeadlineDesc.trim() || undefined,
        userImprintRoles: [
          ...(formData.userImprintRoles?.map((role) => ({
            _id: role._id || undefined,
            userRoleName: role.userRoleName || undefined,
            userRoleDesc: role.userRoleDesc || undefined,
            userRoleOrgName: role.userRoleOrgName || undefined,
            userRoleStartDate: role.userRoleStartDate || undefined,
            userRoleEndDate: role.userRoleEndDate || undefined,
            userRoleLevel: role.userRoleLevel || undefined,
            userRoleStakeholders: role.userRoleStakeholders?.map((stakeholder) => ({
              ...stakeholder,
              delete: stakeholder.delete || false,
            })) || [],
            userRoleFocusAreas: [
              ...(role.userRoleFocusAreas?.map((focus) => ({
                _id: focus._id || undefined,
                roleFocusArea: focus.roleFocusArea || undefined,
                roleIndustryType: focus.roleIndustryType?._id || focus.roleIndustryType || undefined,
                roleDomainType: focus.roleDomainType?._id || focus.roleDomainType || undefined,
                roleFunctionType: focus.roleFunctionType?._id || focus.roleFunctionType || undefined,
                roleTechAreaType: focus.roleTechAreaType?._id || focus.roleTechAreaType || undefined,
                roleSalesType: focus.roleSalesType?._id || focus.roleSalesType || undefined,
                roleServiceType: focus.roleServiceType?._id || focus.roleServiceType || undefined,
                roleAdvisoryType: focus.roleAdvisoryType?._id || focus.roleAdvisoryType || undefined,
                delete: focus.delete || false,
              })) || []),
              ...(formData.deletedFocusAreas || []), // Deleted focus areas
            ],
            userRoleContributionsAndImpacts: [
              ...(role.userRoleContributionsAndImpacts?.map((contribution) => ({
                ...contribution,
                roleContributionNotes: [
                  ...(contribution.roleContributionNotes?.map((note) => ({
                    _id: note._id || undefined,
                    roleContributionNoteText: note.roleContributionNoteText || undefined,
                    delete: note.delete || false,
                  })) || []),
                  ...(formData.deletedContributionNotes || []), // Deleted contribution notes
                ],
                roleImpactNotes: [
                  ...(contribution.roleImpactNotes?.map((note) => ({
                    _id: note._id || undefined,
                    roleImpactNoteText: note.roleImpactNoteText || undefined,
                    delete: note.delete || false,
                  })) || []),
                  ...(formData.deletedImpactNotes || []), // Deleted impact notes
                ],
                delete: contribution.delete || false,
              })) || []),
              ...(formData.deletedContributions || []), // Deleted contributions
            ],
            delete: role.delete || false,
          })) || []),
          ...(formData.deletedRoles || []), // Add deleted roles
        ],        
        userImprintRoleSelfNotes: [
          ...(formData.userImprintRoleSelfNotes?.map((note) => ({
            _id: note._id || undefined,
            userImprintRoleSelfNoteText: note.userImprintRoleSelfNoteText || undefined,
            delete: note.delete || false,
          })) || []),
          ...(formData.deletedSelfNotes || []), // Add deleted self-notes
        ],
        userImprintRoleValidationNotes: formData.userImprintRoleValidationNotes?.filter(
          (note) => note.userImprintRoleValidationNoteText.trim() !== ""
        ),
        aboutMe: formData.aboutMe.trim() || undefined,
        pic: picBase64, // Include the converted Base64 string for pic
        active: formData.active,
      };

      // Step 3: Submit the payload
      await createNewUserImprintRole(payload).unwrap();

      // Step 4: Reset the form upon success
      setFormData({
        userImprintRoleHeadlineName: "",
        userImprintRoleHeadlineDesc: "",
        userImprintRoles: [],
        userImprintRoleSelfNotes: [],
        userImprintRoleValidationNotes: [],
        aboutMe: "",
        pic: "",
        active: true,
      });

      // Step 5: Redirect or close the form
      if (onClose) {
        onClose();
      } else {
        navigate(triggeringUrl || "/userMagicHome");
      }
    } catch (error) {
      // Step 6: Handle errors
      console.error("Error submitting form:", error);
    }
  };

  // 7 - Reset form upon successful submission
  useEffect(() => {
    if(isSuccess) {
      setFormData({
        userImprintRoleHeadlineName: '',
        userImprintRoleHeadlineDesc: '',
        userImprintRoles: [],
        userImprintRoleSelfNotes: [],
        userImprintRoleValidationNotes: [],
        aboutMe: '',
        pic: '',
        active: true,
      })
      // setShowCreateUserImprintCapabilitiesSoftSkill(false)
      if (onClose) {
        onClose(); // 21 Nov 2024
      } else{
        navigate(triggeringUrl || '/userMagicHome');
      }
    }
  
    },[isSuccess,navigate])

  // 8 - Handle Cancel
  const handleCancel = () => {
    // setShowCreateUserImprintCapabilitiesSoftSkill(false)
    if (onClose) {
      onClose(); // 21 Nov 2024
    } else{
      navigate(triggeringUrl || '/userMagicHome');
    }
  }

  // 9 - Handle Clear
  const handleClear = () => {
    setFormData({
      userImprintRoleHeadlineName: '',
      userImprintRoleHeadlineDesc: '',
      userImprintRoles: [],
      userImprintRoleSelfNotes: [],
      userImprintRoleValidationNotes: [],
      aboutMe: '',
      pic: '',
      active: true,
    })
  }

  // 10 - create Markup
  const createMarkup = (htmlContent) => {
  return { __html: htmlContent }
  }
  
  
  
  return (
    <div className="mx-auto w-full m-1 p-1 bg-white rounded shadow-md shadow-black">
      {isLoading || isFetching ? <PulseLoader color={'#ea580c'} /> : null}
  
      {isError || isFetchError ? (
        <p className="text-orange-700 font-bold py-2">
          {fetchError?.data?.message || "Failed to fetch user imprint role data. Please try again."}
        </p>
      ) : null}
  
      <p className="p-1 m-1 font-bold font-poppins text-xl">Update User Imprint Role</p>
  
      {userImprintRoleRecords.length === 0 ? (
        <p className="text-red-500 font-bold text-sm">
          No record found for the selected User and Organization. Please create a new one.
        </p>
      ) : (
        <form onSubmit={handleSubmit} className="m-1 p-1 text-xs font-poppins">
          {/* Headline Name */}
          <div id="headlineName" className="bg-gray-100 rounded p-1 m-1">
            <label
              htmlFor="userImprintRoleHeadlineName"
              className="block text-gray-600 text-sm font-bold mb-1"
            >
              Role Headline Name (Optional)
            </label>
            <input
              type="text"
              name="userImprintRoleHeadlineName"
              placeholder="Enter a headline or title"
              value={formData.userImprintRoleHeadlineName}
              onChange={handleChange}
              className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
            />
          </div>
  
          {/* Headline Description */}
          <div id="headlineDesc" className="bg-gray-100 rounded p-1 m-1 border">
            <div className='flex justify-start items-center m-1 p-1'>
                 <button
                    type="button"
                    className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                    onClick={() => toggleSection(`headlineDesc`)} // Toggle function
                  >
                    {collapsedSections[`headlineDesc`] ? (
                      <PlusIcon className="h-4 w-4 text-orange-600" />
                    ) : (
                      <MinusIcon className="h-4 w-4 text-orange-600" />
                    )}
                  </button>
                  <label
                    htmlFor="userImprintRoleHeadlineDesc"
                    className="block text-gray-600 text-sm font-bold mb-1 text-nowrap"
                  >
                    Role Headline Description
                  </label>
                  {collapsedSections[`headlineDesc`] && (
                    <div dangerouslySetInnerHTML={createMarkup(formData?.userImprintRoleHeadlineDesc) || ""} className='text-[10px] truncate line-clamp-1 mx-1 px-1 max-w-sm'/> )}
            </div>
            
            {!collapsedSections[`headlineDesc`] && (
            <div className='m-1 p-1'>
            <EtContentEditorLight2
              value={formData.userImprintRoleHeadlineDesc || ""}
              handleChange={(content) => {
                setFormData((prevState) => ({
                  ...prevState,
                  userImprintRoleHeadlineDesc: content,
                }));
              }}
              placeholderText="Write your role description here!"
              editorKey="headlineDesc-editor"
            /></div>)}
          </div>
  
          {/* Roles Section */}
          <div id="roles" className="border rounded p-2 m-2 bg-gray-50">
            <div className="flex justify-between items-center mb-2">
              <div className='flex justify-start items-center'>
                    {/* Toggle Roles Section Button */}
                    <button
                      type="button"
                      className="text-orange-600 font-bold m-1 p-1 rounded"
                      onClick={() => toggleSection(`roles`)}
                    >
                      {collapsedSections[`roles`] ? (
                        <PlusIcon className="h-4 w-4 text-orange-600" />
                      ) : (
                        <MinusIcon className="h-4 w-4 text-orange-600" />
                      )}
                    </button>
                    <p className="font-bold text-sm">Roles</p>
             
                    {/* Global Toggle Buttons for Stakeholders */}
                      <div className="flex justify-end items-center mx-1 px-1">
                      {formData.userImprintRoles.every((_, roleIndex) =>
                        collapsedSections[`role-${roleIndex}`]
                        ) ? (
                        <button
                          type="button"
                          className="bg-lime-500 hover:bg-lime-600 text-white text-[10px] m-1 p-1 rounded"
                          onClick={() => {
                            setCollapsedSections((prev) => {
                              const updatedSections = { ...prev };
                              formData.userImprintRoles.forEach((_, roleIndex) => {
                                updatedSections[`role-${roleIndex}`] = false; // Expand all
                              });
                              return updatedSections;
                            });
                          }}
                        >
                          + All
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="bg-orange-500 hover:bg-orange-600 text-white text-[10px] m-1 p-1 rounded"
                          onClick={() => {
                            setCollapsedSections((prev) => {
                              const updatedSections = { ...prev };
                              formData.userImprintRoles.forEach((_, roleIndex) => {
                                updatedSections[`role-${roleIndex}`] = true; // Collapse all
                              });
                              return updatedSections;
                            });
                          }}
                        >
                          - All
                        </button>
                      )}
                      </div>
                </div>

              <div className="flex justify-start items-center">
                <p className="text-xs font-bold mx-1 px-1">Add</p>
                <button
                  type="button"
                  className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) =>
                      produce(prevState, (draft) => {
                        draft.userImprintRoles.unshift({
                          userRoleName: "",
                          userRoleDesc: "",
                          userRoleOrgName: "",
                          userRoleStartDate: "",
                          userRoleEndDate: "",
                          userRoleLevel: "Entry Level", // Default value
                          userRoleStakeholders: [],
                          userRoleFocusAreas: [],
                          userRoleContributionsAndImpacts: [],
                        });
                      })
                    );
                  }}
                >
                  <PlusIcon className="h-4 w-4 inline" />
                </button>
              </div>
            </div>

            {/* Map through roles and render each */}
            {!collapsedSections[`roles`] && formData.userImprintRoles.map((role, roleIndex) => (
              <div key={roleIndex} className="border rounded p-2 m-2 bg-white">
                {/* Role Header */}
                <div className="flex justify-between items-center mb-2">
                  <div className='flex justify-start items-center'>
                    {/* Expand/Collapse Button */}
                    <button
                      type="button"
                      className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                      onClick={() => toggleSection(`role-${roleIndex}`)} // Toggle function
                    >
                      {collapsedSections[`role-${roleIndex}`] ? (
                        <PlusIcon className="h-4 w-4 text-orange-600" />
                      ) : (
                        <MinusIcon className="h-4 w-4 text-orange-600" />
                      )}
                    </button>
                    <p className="font-bold text-sm">Role {roleIndex + 1}</p>
                  </div>
                 
                  <div className="flex justify-start items-center">
                    
                    {/* Remove Button */}
                    {/* <p className="text-xs font-bold mx-1 px-1">Remove</p> */}
                    <button
                      type="button"
                      className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                      onClick={() => {
                        setFormData((prevState) =>
                          produce(prevState, (draft) => {
                            // Mark role for deletion
                            draft.userImprintRoles[roleIndex].delete = true;
                    
                            // Push deleted role to a hidden array for submission
                            if (!draft.deletedRoles) {
                              draft.deletedRoles = []; // Add a separate array to hold deleted roles
                            }
                            draft.deletedRoles.push(draft.userImprintRoles[roleIndex]);
                    
                            // Remove it visually from the form
                            draft.userImprintRoles.splice(roleIndex, 1);
                          })
                        );
                      }}
                    >
                      <MinusIcon className="h-4 w-4 inline" />
                    </button>
                  </div>
                </div>
                
                {/* Role Content */}
                {!collapsedSections[`role-${roleIndex}`] && (
                  <div>
                    {/* Role Name */}
                    <div id={`userRoleName-${roleIndex}`} className="bg-gray-100 rounded p-1 m-1">
                      <label
                        htmlFor={`userRoleName-${roleIndex}`}
                        className="block text-gray-600 text-sm font-bold mb-1"
                      >
                        Role Name
                      </label>
                      <input
                        type="text"
                        id={`userRoleName-${roleIndex}`}
                        placeholder="Enter role name"
                        value={role.userRoleName || ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFormData((prevState) =>
                            produce(prevState, (draft) => {
                              draft.userImprintRoles[roleIndex].userRoleName = value;
                            })
                          );
                        }}
                        className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                      />
                    </div>

                    {/* Role Description */}
                    <div id={`userRoleDesc-${roleIndex}`} className="bg-gray-100 rounded p-1 m-1">
                      
                      <div className='flex justify-start items-center'>
                          <button
                            type="button"
                            className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                            onClick={() => toggleSection(`userRoleDesc-${roleIndex}`)} // Toggle function
                            >
                            {collapsedSections[`userRoleDesc-${roleIndex}`] ? (
                              <PlusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                              <MinusIcon className="h-4 w-4 text-orange-600" />
                            )}
                          </button>
                          <label
                            htmlFor={`userRoleDesc-${roleIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            Role Description
                          </label>
                          {collapsedSections[`userRoleDesc-${roleIndex}`] && (
                            <div dangerouslySetInnerHTML={createMarkup(role.userRoleDesc) || ""} className='text-[10px] truncate line-clamp-1 mx-1 px-1 max-w-sm'/> )}
                      </div>
                      
                      {!collapsedSections[`userRoleDesc-${roleIndex}`] && (
                      <EtContentEditorLight2
                        value={role.userRoleDesc || ""}
                        handleChange={(content) => {
                          setFormData((prevState) =>
                            produce(prevState, (draft) => {
                              draft.userImprintRoles[roleIndex].userRoleDesc = content;
                            })
                          );
                        }}
                        placeholderText="Write role description here..."
                        editorKey={`roleDesc-${roleIndex}`}
                      />)}
                    </div>
                    
                    {/* Role Basic Details - Org Name, Start Date, End Date, Level */}
                    <div className='flex justify-between items-center'>
                        {/* Organization Name */}
                        <div id={`userRoleOrgName-${roleIndex}`} className="bg-gray-100 rounded m-1 p-1 w-full">
                          <label
                            htmlFor={`userRoleOrgName-${roleIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            Organization Name
                          </label>
                          <input
                            type="text"
                            id={`userRoleOrgName-${roleIndex}`}
                            placeholder="Enter organization name"
                            value={role.userRoleOrgName || ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  draft.userImprintRoles[roleIndex].userRoleOrgName = value;
                                })
                              );
                            }}
                            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                          />
                        </div>

                        {/* Start Date */}
                        <div id={`userRoleStartDate-${roleIndex}`} className="bg-gray-100 rounded m-1 p-1 w-full">
                          <label
                            htmlFor={`userRoleStartDate-${roleIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            Start Date
                          </label>
                          <input
                            type="date"
                            id={`userRoleStartDate-${roleIndex}`}
                            value={role.userRoleStartDate ? role.userRoleStartDate.split('T')[0] : ''}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  draft.userImprintRoles[roleIndex].userRoleStartDate = value;
                                })
                              );
                            }}
                            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                          />
                        </div>

                        {/* End Date */}
                        <div id={`userRoleEndDate-${roleIndex}`} className="bg-gray-100 rounded m-1 p-1 w-full">
                          <label
                            htmlFor={`userRoleEndDate-${roleIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            End Date
                          </label>
                          <input
                            type="date"
                            id={`userRoleEndDate-${roleIndex}`}
                            value={role.userRoleEndDate ? role.userRoleEndDate.split('T')[0] : ''}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  draft.userImprintRoles[roleIndex].userRoleEndDate = value;
                                })
                              );
                            }}
                            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                          />
                        </div>

                        {/* Role Level */}
                        <div id={`userRoleLevel-${roleIndex}`} className="bg-gray-100 rounded m-1 p-1 w-full">
                          <label
                            htmlFor={`userRoleLevel-${roleIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            Role Level
                          </label>
                          <select
                            id={`userRoleLevel-${roleIndex}`}
                            value={role.userRoleLevel || "Entry Level"}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  draft.userImprintRoles[roleIndex].userRoleLevel = value;
                                })
                              );
                            }}
                            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                          >
                            <option value="Founder">Founder</option>
                            <option value="CoFounder">Co-Founder</option>
                            <option value="CxO">CxO</option>
                            <option value="Senior Level">Senior Level</option>
                            <option value="Mid Level">Mid Level</option>
                            <option value="Entry Level">Entry Level</option>
                            <option value="Intern">Intern</option>
                          </select>
                        </div>
                    </div>

                    {/* Stakeholders Section */}
                    <div id={`stakeholders-${roleIndex}`} className="border rounded p-2 m-2 bg-gray-50">

                      <div className="flex justify-between items-center mb-2">
                        <div className='flex justify-start items-center'>
                          {/* Toggle Stakeholders Section Button */}
                          <button
                            type="button"
                            className="text-orange-600 font-bold m-1 p-1 rounded"
                            onClick={() => toggleSection(`stakeholders-${roleIndex}`)}
                          >
                            {collapsedSections[`stakeholders-${roleIndex}`] ? (
                              <PlusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                              <MinusIcon className="h-4 w-4 text-orange-600" />
                            )}
                          </button>
                          <p className="font-bold text-sm">Stakeholders</p>
             
                          {/* Global Toggle Buttons for Stakeholders */}
                            <div className="flex justify-end items-center mx-1 px-1">
                            {role.userRoleStakeholders.every((_, stakeholderIndex) =>
                              collapsedSections[`stakeholder-${roleIndex}-${stakeholderIndex}`]
                              ) ? (
                              <button
                                type="button"
                                className="bg-lime-500 hover:bg-lime-600 text-white text-[10px] m-1 p-1 rounded"
                                onClick={() => {
                                  setCollapsedSections((prev) => {
                                    const updatedSections = { ...prev };
                                    role.userRoleStakeholders.forEach((_, stakeholderIndex) => {
                                      updatedSections[`stakeholder-${roleIndex}-${stakeholderIndex}`] = false; // Expand all
                                    });
                                    return updatedSections;
                                  });
                                }}
                              >
                                + All
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="bg-orange-500 hover:bg-orange-600 text-white text-[10px] m-1 p-1 rounded"
                                onClick={() => {
                                  setCollapsedSections((prev) => {
                                    const updatedSections = { ...prev };
                                    role.userRoleStakeholders.forEach((_, stakeholderIndex) => {
                                      updatedSections[`stakeholder-${roleIndex}-${stakeholderIndex}`] = true; // Collapse all
                                    });
                                    return updatedSections;
                                  });
                                }}
                              >
                                - All
                              </button>
                            )}
                            </div>
                        </div>
                             
                        <div className="flex justify-start items-center">          
                          <p className="font-bold text-xs mx-1 px-1">Add</p>
                          <button
                            type="button"
                            className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                            onClick={() => {
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  draft.userImprintRoles[roleIndex].userRoleStakeholders.unshift({
                                    stakeholderType: "",
                                  });
                                })
                              );
                              // Expand the newly added stakeholder
                              setCollapsedSections((prevState) => ({
                                ...prevState,
                                [`stakeholder-${roleIndex}-0`]: false, // Index 0 for the new stakeholder
                                [`stakeholders-${roleIndex}`]: false, // Ensure the entire section is expanded
                              }));
                            }}
                          >
                            <PlusIcon className="h-4 w-4 inline" />
                          </button>
                        </div>
                      </div>

                      {/* Map through each stakeholder */}
                      <div className='grid grid-cols-4 justify-between gap-1'>
                        {!collapsedSections[`stakeholders-${roleIndex}`] && 
                        role.userRoleStakeholders
                        ?.filter((stakeholder) => !stakeholder.delete)
                        ?.map((stakeholder, stakeholderIndex) => (
                          <div
                            key={stakeholderIndex}
                            className="p-2 border rounded mb-2 bg-white"
                          >
                            <div className="flex justify-between items-center">
                              <div className='flex justify-start items-center'>
                                {/* Expand/Collapse Button */}
                                <button
                                      type="button"
                                      onClick={() => toggleSection(`stakeholder-${roleIndex}-${stakeholderIndex}`)}
                                      className="text-orange-600 font-bold m-1 p-1 rounded"
                                    >
                                      {collapsedSections[`stakeholder-${roleIndex}-${stakeholderIndex}`] ? (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                      )}
                                  </button>
                                <p className="font-bold text-sm mx-1 px-1">Stakeholder {stakeholderIndex + 1}</p>
                              </div>
                              
                              <div className="flex justify-start items-center">
                                
                                  {/* Remove Button */}
                                {/* <p className="font-bold text-xs mx-1 px-1">Remove</p> */}
                                <button
                                  type="button"
                                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                  onClick={() => {
                                    setFormData((prevState) =>
                                      produce(prevState, (draft) => {
                                        const stakeholder = draft.userImprintRoles[roleIndex].userRoleStakeholders[stakeholderIndex];
                                        if (stakeholder._id) {
                                          // Mark as deleted for backend entries
                                          stakeholder.delete = true;
                                        } else {
                                          // Remove new entries
                                          draft.userImprintRoles[roleIndex].userRoleStakeholders.splice(stakeholderIndex, 1);
                                        }
                                      })
                                    );
                                  }}
                                >
                                  <MinusIcon className="h-4 w-4 inline" />
                                </button>
                              </div>
                            </div>

                            {/* Stakeholder Type */}
                            {!collapsedSections[`stakeholder-${roleIndex}-${stakeholderIndex}`] && (
                              <div
                                id={`stakeholderType-${roleIndex}-${stakeholderIndex}`}
                                className="flex justify-between items-center bg-gray-100 rounded p-1 m-1"
                              >
                                <label
                                  htmlFor={`stakeholderType-${roleIndex}-${stakeholderIndex}`}
                                  className="text-gray-600 text-[10px] font-bold text-nowrap mx-1 px-1"
                                >
                                  Stakeholder Type
                                </label>
                                <select
                                  id={`stakeholderType-${roleIndex}-${stakeholderIndex}`}
                                  value={stakeholder.stakeholderType || ""}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setFormData((prevState) =>
                                      produce(prevState, (draft) => {
                                        draft.userImprintRoles[roleIndex].userRoleStakeholders[
                                          stakeholderIndex
                                        ].stakeholderType = value;
                                      })
                                    );
                                  }}
                                  className="w-full rounded p-2 border border-gray-300 focus:outline-none"
                                >
                                  <option value="" disabled>
                                    Select Stakeholder Type
                                  </option>
                                  <option value="Clients">Clients</option>
                                  <option value="Org">Org</option>
                                  <option value="Org Team">Org Team</option>
                                  <option value="Partners">Partners</option>
                                </select>
                              </div>
                            )}
                          </div>
                        ))} 
                      </div>
                    </div>

                    {/* Role Focus Areas Section */}
                    <div id={`roleFocusAreas-${roleIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                      <div className="flex justify-between items-center mb-2">
                        <div className='flex justify-start items-center'>
                          {/* Toggle Stakeholders Section Button */}
                          <button
                            type="button"
                            className="text-orange-600 font-bold m-1 p-1 rounded"
                            onClick={() => toggleSection(`roleFocusAreas-${roleIndex}`)}
                          >
                            {collapsedSections[`roleFocusAreas-${roleIndex}`] ? (
                              <PlusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                              <MinusIcon className="h-4 w-4 text-orange-600" />
                            )}
                          </button>
                          <p className="font-bold text-sm">Focus Areas</p>
                          {/* Global Toggle Buttons for Role Focus Areas */}
                          <div className="flex justify-end items-center mx-1 px-1">
                            {role.userRoleFocusAreas.every((_, focusIndex) =>
                              collapsedSections[`focusArea-${roleIndex}-${focusIndex}`]
                              ) ? (
                              <button
                                type="button"
                                className="bg-lime-500 hover:bg-lime-600 text-white text-[10px] m-1 p-1 rounded"
                                onClick={() => {
                                  setCollapsedSections((prev) => {
                                    const updatedSections = { ...prev };
                                    role.userRoleFocusAreas.forEach((_, focusIndex) => {
                                      updatedSections[`focusArea-${roleIndex}-${focusIndex}`] = false; // Expand all
                                    });
                                    return updatedSections;
                                  });
                                }}
                              >
                                + All
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="bg-orange-500 hover:bg-orange-600 text-white text-[10px] m-1 p-1 rounded"
                                onClick={() => {
                                  setCollapsedSections((prev) => {
                                    const updatedSections = { ...prev };
                                    role.userRoleFocusAreas.forEach((_, focusIndex) => {
                                      updatedSections[`focusArea-${roleIndex}-${focusIndex}`] = true; // Collapse all
                                    });
                                    return updatedSections;
                                  });
                                }}
                              >
                                - All
                              </button>
                            )}
                            </div>

                        </div>
                        
                        <div className="flex justify-start items-center">
                          <p className="font-bold text-xs mx-1 px-1">Add</p>
                          <button
                            type="button"
                            className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                            onClick={() => {
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  draft.userImprintRoles[roleIndex].userRoleFocusAreas.unshift({
                                    roleFocusArea: "",
                                    roleIndustryType: null,
                                  });
                                })
                              );
                              // Expand the newly added stakeholder
                              setCollapsedSections((prevState) => ({
                                ...prevState,
                                [`focusArea-${roleIndex}-0`]: false, // Index 0 for the new stakeholder
                                [`roleFocusAreas-${roleIndex}`]: false, // Ensure the entire section is expanded
                              }));
                            }}
                          >
                            <PlusIcon className="h-4 w-4 inline" />
                          </button>
                        </div>
                      </div>

                      {/* Map through each focus area */}
                      <div className='grid grid-cols-3 justify-between gap-1'>
                        {!collapsedSections[`roleFocusAreas-${roleIndex}`] && role.userRoleFocusAreas?.map((focusArea, focusIndex) => (
                          <div key={focusIndex} className="p-2 border rounded mb-2 bg-white">
                            <div className="flex justify-between items-center">
                              <div className='flex justify-start items-center'>
                                {/* Expand/Collapse Button */}
                                <button
                                      type="button"
                                      onClick={() => toggleSection(`focusArea-${roleIndex}-${focusIndex}`)}
                                      className="text-orange-600 font-bold m-1 p-1 rounded"
                                    >
                                      {collapsedSections[`focusArea-${roleIndex}-${focusIndex}`] ? (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                      )}
                                  </button>

                                  <p className="font-bold text-sm mx-1 px-1">Focus Area {focusIndex + 1}</p>

                              </div>
   
                              <div className="flex justify-start items-center">
                                
                                {/* Remove Button */}
                                {/* <p className="font-bold text-xs mx-1 px-1">Remove</p> */}
                                <button
                                  type="button"
                                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                  onClick={() => {
                                    setFormData((prevState) =>
                                      produce(prevState, (draft) => {
                                        // Mark focus area as deleted
                                        draft.userImprintRoles[roleIndex].userRoleFocusAreas[focusIndex].delete = true;
                                    
                                        // Push deleted item to a hidden array for submission
                                        if (!draft.deletedFocusAreas) {
                                          draft.deletedFocusAreas = []; // Add a separate array to hold deleted focus areas
                                        }
                                        draft.deletedFocusAreas.push(draft.userImprintRoles[roleIndex].userRoleFocusAreas[focusIndex]);
                                    
                                        // Remove it visually from the form
                                        draft.userImprintRoles[roleIndex].userRoleFocusAreas.splice(focusIndex, 1);
                                      })
                                    );
                                  }}
                                >
                                  <MinusIcon className="h-4 w-4 inline" />
                                </button>
                              </div>
                            </div>
                            
                            {!collapsedSections[`focusArea-${roleIndex}-${focusIndex}`] && (
                              <div >
                                {/* Focus Area Dropdown */}
                                <div className="flex justify-between items-center bg-gray-100 rounded p-1 m-1">
                                  <label
                                    htmlFor={`roleFocusArea-${roleIndex}-${focusIndex}`}
                                    className="text-gray-600 text-[10px] font-bold w-1/3"
                                  >
                                    Focus Area
                                  </label>
                                  <select
                                    id={`roleFocusArea-${roleIndex}-${focusIndex}`}
                                    value={focusArea.roleFocusArea || ""}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      setFormData((prevState) =>
                                        produce(prevState, (draft) => {
                                          draft.userImprintRoles[roleIndex].userRoleFocusAreas[focusIndex] = {
                                            roleFocusArea: value,
                                            [`role${value}Type`]: null, // Dynamic reset for the selected focus area
                                          };
                                        })
                                      );
                                    }}
                                    className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
                                  >
                                    <option value="" disabled>
                                      Select Focus Area
                                    </option>
                                    <option value="Industry">Industry</option>
                                    <option value="Domain">Domain</option>
                                    <option value="Function">Function</option>
                                    <option value="TechArea">Tech Area</option>
                                    <option value="Sales">Sales</option>
                                    <option value="Service">Service</option>
                                    <option value="Advisory">Advisory</option>
                                  </select>
                                </div>

                                {/* Specific Type Dropdown */}
                                {focusArea.roleFocusArea && (
                                  <div className="flex justify-between items-center bg-gray-100 rounded p-1 m-1">
                                    <label
                                      htmlFor={`specificType-${roleIndex}-${focusIndex}`}
                                      className="text-gray-600 text-[10px] font-bold w-1/3"
                                    >
                                      Select {focusArea.roleFocusArea}
                                    </label>
                                    <select
                                      id={`specificType-${roleIndex}-${focusIndex}`}
                                      value={focusArea[`role${focusArea.roleFocusArea}Type`]?._id || focusArea[`role${focusArea.roleFocusArea}Type`] || ""}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        setFormData((prevState) =>
                                          produce(prevState, (draft) => {
                                            draft.userImprintRoles[roleIndex].userRoleFocusAreas[
                                              focusIndex
                                            ][`role${focusArea.roleFocusArea}Type`] = value;
                                          })
                                        );
                                      }}
                                      className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
                                    >
                                      <option value="" disabled>
                                      {`Select ${focusArea.roleFocusArea}`}
                                      </option>
                                      {focusArea.roleFocusArea === "Industry" &&
                                        industryTypes?.map((type) => (
                                          <option key={type._id} value={type._id} >
                                            {type.capabilitiesIndustryTypeName}
                                          </option>
                                        ))}
                                      {focusArea.roleFocusArea === "Domain" &&
                                        domainTypes?.map((type) => (
                                          <option key={type._id} value={type._id} >
                                            {type.capabilitiesDomainTypeName}
                                          </option>
                                        ))}
                                      {focusArea.roleFocusArea === "Function" &&
                                        functionTypes?.map((type) => (
                                          <option key={type._id} value={type._id} >
                                            {type.capabilitiesFunctionTypeName}
                                          </option>
                                        ))}
                                      {focusArea.roleFocusArea === "TechArea" &&
                                        techAreaTypes?.map((type) => (
                                          <option key={type._id} value={type._id}>
                                            {type.capabilitiesTechAreaTypeName}
                                          </option>
                                        ))}
                                      {focusArea.roleFocusArea === "Sales" &&
                                        salesTypes?.map((type) => (
                                          <option key={type._id} value={type._id}>
                                            {type.capabilitiesSalesTypeName}
                                          </option>
                                        ))}
                                      {focusArea.roleFocusArea === "Service" &&
                                        serviceTypes?.map((type) => (
                                          <option key={type._id} value={type._id}>
                                            {type.capabilitiesServiceTypeName}
                                          </option>
                                        ))}
                                      {focusArea.roleFocusArea === "Advisory" &&
                                        advisoryTypes?.map((type) => (
                                          <option key={type._id} value={type._id}>
                                            {type.capabilitiesAdvisoryTypeName}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* Contributions and Impacts Section */}
                    <div id={`contributionsAndImpacts-${roleIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                      <div className="flex justify-between items-center mb-2">
                        <div className='flex justify-start items-center'>
                          {/* Toggle Contribution & Impacts Section Button */}
                          <button
                            type="button"
                            className="text-orange-600 font-bold m-1 p-1 rounded"
                            onClick={() => toggleSection(`contributionsAndImpacts-${roleIndex}`)}
                          >
                            {collapsedSections[`contributionsAndImpacts-${roleIndex}`] ? (
                              <PlusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                              <MinusIcon className="h-4 w-4 text-orange-600" />
                            )}
                          </button>
                          <p className="font-bold text-sm">Contributions and Impacts</p>
                            {/* Global Toggle Buttons for Contributions & Impacts */}
                            <div className="flex justify-end items-center mx-1 px-1">
                            {role.userRoleContributionsAndImpacts.every((_, contributionIndex) =>
                              collapsedSections[`contribution-${roleIndex}-${contributionIndex}`]
                              ) ? (
                              <button
                                type="button"
                                className="bg-lime-500 hover:bg-lime-600 text-white text-[10px] m-1 p-1 rounded"
                                onClick={() => {
                                  setCollapsedSections((prev) => {
                                    const updatedSections = { ...prev };
                                    role.userRoleContributionsAndImpacts.forEach((_, contributionIndex) => {
                                      updatedSections[`contribution-${roleIndex}-${contributionIndex}`] = false; // Expand all
                                    });
                                    return updatedSections;
                                  });
                                }}
                              >
                                + All
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="bg-orange-500 hover:bg-orange-600 text-white text-[10px] m-1 p-1 rounded"
                                onClick={() => {
                                  setCollapsedSections((prev) => {
                                    const updatedSections = { ...prev };
                                    role.userRoleContributionsAndImpacts.forEach((_, contributionIndex) => {
                                      updatedSections[`contribution-${roleIndex}-${contributionIndex}`] = true; // Collapse all
                                    });
                                    return updatedSections;
                                  });
                                }}
                              >
                                - All
                              </button>
                            )}
                            </div>
                        </div>
                        
                        <div className="flex justify-start items-center">
                          <p className="font-bold text-xs mx-1 px-1">Add</p>
                          <button
                            type="button"
                            className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                            onClick={() => {
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts.unshift({
                                    roleContributionType: null,
                                    roleContributionNotes: [],
                                    roleContributionSelfEvalText: "",
                                    roleContributionSelfEvalScore: 0,
                                    roleImpactType: null,
                                    roleImpactNotes: [],
                                    roleImpactSelfEvalText: "",
                                    roleImpactSelfEvalScore: 0,
                                    roleContributionsAndImpactValidations: [],
                                  });
                                })
                              );
                            }}
                          >
                            <PlusIcon className="h-4 w-4 inline" />
                          </button>
                        </div>
                      </div>

                      {/* Map through Contributions and Impacts */}
                      {!collapsedSections[`contributionsAndImpacts-${roleIndex}`] && role.userRoleContributionsAndImpacts?.map((contribution, contributionIndex) => (
                        <div
                          key={`contributionImpact-${roleIndex}-${contributionIndex}`}
                          className="p-2 border rounded mb-2 bg-white"
                        >
                          <div className="flex justify-between items-center">
                            <div className='flex justify-start items-center'>
                              {/* Expand/Collapse Button */}
                              <button
                                    type="button"
                                    onClick={() => toggleSection(`contribution-${roleIndex}-${contributionIndex}`)}
                                    className="text-orange-600 font-bold m-1 p-1 rounded"
                                  >
                                    {collapsedSections[`contribution-${roleIndex}-${contributionIndex}`] ? (
                                      <PlusIcon className="h-4 w-4 text-orange-600" />
                                      ) : (
                                      <MinusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                </button>

                                <p className="font-bold text-sm mx-1 px-1">Contribution & Impact {contributionIndex + 1}</p>

                            </div>
                            
                            <div className="flex justify-start items-center">
                              
                                {/* Remove Button */}

                              {/* <p className="font-bold text-xs mx-1 px-1">Remove</p> */}
                              <button
                                type="button"
                                className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                onClick={() => {
                                  setFormData((prevState) =>
                                    produce(prevState, (draft) => {
                                      const contribution =
                                        draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                          contributionIndex
                                        ];
                                
                                      // Mark for deletion
                                      contribution.delete = true;
                                
                                      // Push deleted contribution to a hidden array for submission
                                      if (!draft.deletedContributions) {
                                        draft.deletedContributions = []; // Add a separate array for deleted contributions
                                      }
                                      draft.deletedContributions.push(contribution);
                                
                                      // Remove it visually from the form
                                      draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts.splice(
                                        contributionIndex,
                                        1
                                      );
                                    })
                                  );
                                }}
                              >
                                <MinusIcon className="h-4 w-4 inline" />
                              </button>
                            </div>
                          </div>
                          
                          {/* Expand / collapse contribution & impacts */}
                          {!collapsedSections[`contribution-${roleIndex}-${contributionIndex}`] && (
                          <div>

                            <div className='flex justify-between items-center'>
                                {/* 1. Contribution Type */}
                                <div id={`contributionType-${contributionIndex}`} className="bg-gray-100 rounded m-1 p-1 w-full">
                                  <label
                                    htmlFor={`contributionType-${roleIndex}-${contributionIndex}`}
                                    className="block text-gray-600 text-sm font-bold mb-1"
                                  >
                                    Contribution Type
                                  </label>
                                  <select
                                    id={`contributionType-${roleIndex}-${contributionIndex}`}
                                    value={contribution.roleContributionType || ""}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      setFormData((prevState) =>
                                        produce(prevState, (draft) => {
                                          draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                            contributionIndex
                                          ].roleContributionType = value;
                                        })
                                      );
                                    }}
                                    className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                  >
                                    <option value="" disabled>
                                      Select Contribution Type
                                    </option>
                                    {contributionTypes?.map((type) => (
                                      <option key={type._id} value={type._id}>
                                        {type.contributionTypeName}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                {/* 2. Contribution Self Evaluation */}
                                <div className="bg-gray-100 rounded m-1 p-1 w-full">
                                  <label
                                    htmlFor={`contributionSelfEvalText-${roleIndex}-${contributionIndex}`}
                                    className="block text-gray-600 text-sm font-bold mb-1"
                                  >
                                    Contribution Self Evaluation
                                  </label>
                                  <select
                                    id={`contributionSelfEvalText-${roleIndex}-${contributionIndex}`}
                                    value={contribution.roleContributionSelfEvalText || ""}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      const scoreMapping = {
                                        None: 0,
                                        "Below My Average": 1,
                                        "Average": 2,
                                        "Above Average": 3,
                                        "Over and Beyond!": 4,
                                        "Gave it All!": 5,
                                      };
                                      setFormData((prevState) =>
                                        produce(prevState, (draft) => {
                                          draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                            contributionIndex
                                          ].roleContributionSelfEvalText = value;
                                          draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                            contributionIndex
                                          ].roleContributionSelfEvalScore = scoreMapping[value] || 0;
                                        })
                                      );
                                    }}
                                    className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                  >
                                    <option value="None">None</option>
                                    <option value="Below My Average">Below My Average</option>
                                    <option value="Average">Average</option>
                                    <option value="Above Average">Above Average</option>
                                    <option value="Over and Beyond!">Over and Beyond!</option>
                                    <option value="Gave it All!">Gave it All!</option>
                                  </select>
                                </div>

                                {/* 3. Impact Type */}
                                <div id={`impactType-${contributionIndex}`} className="bg-gray-100 rounded m-1 p-1 w-full">
                                  <label
                                    htmlFor={`impactType-${roleIndex}-${contributionIndex}`}
                                    className="block text-gray-600 text-sm font-bold mb-1"
                                  >
                                    Impact Type
                                  </label>
                                  <select
                                    id={`impactType-${roleIndex}-${contributionIndex}`}
                                    value={contribution.roleImpactType || ""}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      setFormData((prevState) =>
                                        produce(prevState, (draft) => {
                                          draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                            contributionIndex
                                          ].roleImpactType = value;
                                        })
                                      );
                                    }}
                                    className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                  >
                                    <option value="" disabled>
                                      Select Impact Type
                                    </option>
                                    {impactTypes?.map((type) => (
                                      <option key={type._id} value={type._id}>
                                        {type.impactTypeName}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                {/* 4. Impact Self Evaluation */}
                                <div className="bg-gray-100 rounded m-1 p-1 w-full">
                                  <label
                                    htmlFor={`impactSelfEvalText-${roleIndex}-${contributionIndex}`}
                                    className="block text-gray-600 text-sm font-bold mb-1"
                                  >
                                    Impact Self Evaluation
                                  </label>
                                  <select
                                    id={`impactSelfEvalText-${roleIndex}-${contributionIndex}`}
                                    value={contribution.roleImpactSelfEvalText || ""}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      const scoreMapping = {
                                        None: 0,
                                        Low: 1,
                                        Moderate: 2,
                                        High: 3,
                                        Striking: 4,
                                        Lightning: 5,
                                      };
                                      setFormData((prevState) =>
                                        produce(prevState, (draft) => {
                                          draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                            contributionIndex
                                          ].roleImpactSelfEvalText = value;
                                          draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                            contributionIndex
                                          ].roleImpactSelfEvalScore = scoreMapping[value] || 0;
                                        })
                                      );
                                    }}
                                    className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                  >
                                    <option value="None">None</option>
                                    <option value="Low">Low</option>
                                    <option value="Moderate">Moderate</option>
                                    <option value="High">High</option>
                                    <option value="Striking">Striking</option>
                                    <option value="Lightning">Lightning</option>
                                  </select>
                                </div>
                              </div>

                              {/* 5. Contribution Notes Section */}
                              <div id={`contributionNotes-${contributionIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                                <div className="flex justify-between items-center mb-2">
                                  <div className='flex justify-start items-center'>
                                    <button
                                        type="button"
                                        className="text-orange-600 font-bold m-1 p-1 rounded"
                                        onClick={() => toggleSection(`contributionNotes-${contributionIndex}`)}
                                      >
                                        {collapsedSections[`contributionNotes-${contributionIndex}`] ? (
                                          <PlusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                          <MinusIcon className="h-4 w-4 text-orange-600" />
                                        )}
                                      </button>
                                      <p className="font-bold text-sm">Contribution Notes</p>
                                  </div>
                                  
                                  <div className="flex justify-start items-center">
                                    <p className="font-bold text-xs mx-1 px-1">Add</p>
                                    <button
                                      type="button"
                                      className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                                      onClick={() => {
                                        setFormData((prevState) =>
                                          produce(prevState, (draft) => {
                                            draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                              contributionIndex
                                            ].roleContributionNotes.unshift({
                                              roleContributionNoteText: "",
                                              roleContributionNoteDate: new Date().toISOString(),
                                              roleContributionNoteUpdateDate: null,
                                            });
                                          })
                                        );
                                        // Expand the newly added stakeholder
                                        setCollapsedSections((prevState) => ({
                                          ...prevState,
                                          [`contributionNote-${roleIndex}-${contributionIndex}-0`]: false, // Index 0 for the new note
                                          [`contributionNotes-${contributionIndex}`]: false, // Ensure the entire section is expanded
                                        }));
                                      }}
                                    >
                                      <PlusIcon className="h-4 w-4 inline" />
                                    </button>
                                  </div>
                                </div>

                                {!collapsedSections[`contributionNotes-${contributionIndex}`] && contribution.roleContributionNotes?.map((note, noteIndex) => (
                                  <div key={`contributionNote-${roleIndex}-${contributionIndex}-${noteIndex}`} className="p-2 border rounded mb-2 bg-white">
                                    <div className="flex justify-between items-center">

                                      <div className='flex justify-start items-center'>
                                        <button
                                            type="button"
                                            className="text-orange-600 font-bold m-1 p-1 rounded"
                                            onClick={() => toggleSection(`contributionNote-${roleIndex}-${contributionIndex}-${noteIndex}`)}
                                          >
                                            {collapsedSections[`contributionNote-${roleIndex}-${contributionIndex}-${noteIndex}`] ? (
                                              <PlusIcon className="h-4 w-4 text-orange-600" />
                                            ) : (
                                              <MinusIcon className="h-4 w-4 text-orange-600" />
                                            )}
                                          </button>
                                          <p className="font-bold text-sm mx-1 px-1">Contribution Note {noteIndex + 1}</p>
                                      </div>
                                      
                                      <div className="flex justify-start items-center">
                                        <p className="font-bold text-xs mx-1 px-1">Remove</p>
                                        <button
                                          type="button"
                                          className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                          onClick={() => {
                                            setFormData((prevState) =>
                                              produce(prevState, (draft) => {
                                                const note =
                                                  draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                                    contributionIndex
                                                  ].roleContributionNotes[noteIndex];
                                          
                                                // Mark for deletion
                                                note.delete = true;
                                          
                                                // Push deleted note to a hidden array for submission
                                                if (!draft.deletedContributionNotes) {
                                                  draft.deletedContributionNotes = []; // Add a separate array for deleted contribution notes
                                                }
                                                draft.deletedContributionNotes.push(note);
                                          
                                                // Remove it visually from the form
                                                draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                                  contributionIndex
                                                ].roleContributionNotes.splice(noteIndex, 1);
                                              })
                                            );
                                          }}
                                        >
                                          <MinusIcon className="h-4 w-4 inline" />
                                        </button>
                                      </div>
                                    </div>

                                    {/* Note Text */}
                                    {!collapsedSections[`contributionNote-${roleIndex}-${contributionIndex}-${noteIndex}`] && (
                                    <div>
                                        <EtContentEditorLight2
                                          value={note.roleContributionNoteText || ""}
                                          handleChange={(content) => {
                                            setFormData((prevState) =>
                                              produce(prevState, (draft) => {
                                                draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                                  contributionIndex
                                                ].roleContributionNotes[noteIndex].roleContributionNoteText = content;
                                                draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                                  contributionIndex
                                                ].roleContributionNotes[noteIndex].roleContributionNoteUpdateDate = new Date().toISOString();
                                              })
                                            );
                                          }}
                                          placeholderText="Write your contribution note here..."
                                          editorKey={`contributionNote-${roleIndex}-${contributionIndex}-${noteIndex}`}
                                        />

                                        {/* Note Date */}
                                        <p className="text-xs text-gray-500 mt-2">
                                          Created on: {new Date(note.roleContributionNoteDate).toLocaleDateString()}
                                          {note.roleContributionNoteUpdateDate && (
                                            <span>
                                              , Updated on: {new Date(note.roleContributionNoteUpdateDate).toLocaleDateString()}
                                            </span>
                                          )}
                                        </p>
                                    </div>)}
                                  </div>
                                ))}
                              </div>

                              {/* 6. Impact Notes Section */}
                              <div id={`impactNotes-${contributionIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                                <div className="flex justify-between items-center mb-2">
                                  <div className='flex justify-start items-center'>
                                      <button
                                          type="button"
                                          className="text-orange-600 font-bold m-1 p-1 rounded"
                                          onClick={() => toggleSection(`impactNotes-${contributionIndex}`)}
                                        >
                                          {collapsedSections[`impactNotes-${contributionIndex}`] ? (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                          ) : (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                          )}
                                        </button>
                                        <p className="font-bold text-sm">Impact Notes</p>
                                    </div>

                                 
                                  <div className="flex justify-start items-center">
                                    <p className="font-bold text-xs mx-1 px-1">Add</p>
                                    <button
                                      type="button"
                                      className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                                      onClick={() => {
                                        setFormData((prevState) =>
                                          produce(prevState, (draft) => {
                                            draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                              contributionIndex
                                            ].roleImpactNotes.unshift({
                                              roleImpactNoteText: "",
                                              roleImpactNoteDate: new Date().toISOString(),
                                              roleImpactNoteUpdateDate: null,
                                            });
                                          })
                                        );
                                        setCollapsedSections((prevState) => ({
                                          ...prevState,
                                          [`impactNote-${roleIndex}-${contributionIndex}-0`]: false, // Index 0 for the new note
                                          [`impactNotes-${contributionIndex}`]: false, // Ensure the entire section is expanded
                                        }));
                                      }}
                                    >
                                      <PlusIcon className="h-4 w-4 inline" />
                                    </button>
                                  </div>
                                </div>

                                {!collapsedSections[`impactNotes-${contributionIndex}`] && contribution.roleImpactNotes?.map((note, noteIndex) => (
                                  <div key={`impactNote-${roleIndex}-${contributionIndex}-${noteIndex}`} className="p-2 border rounded mb-2 bg-white">
                                    <div className="flex justify-between items-center">

                                        <div className='flex justify-start items-center'>
                                            <button
                                                type="button"
                                                className="text-orange-600 font-bold m-1 p-1 rounded"
                                                onClick={() => toggleSection(`impactNote-${roleIndex}-${contributionIndex}-${noteIndex}`)}
                                              >
                                                {collapsedSections[`impactNote-${roleIndex}-${contributionIndex}-${noteIndex}`] ? (
                                                  <PlusIcon className="h-4 w-4 text-orange-600" />
                                                ) : (
                                                  <MinusIcon className="h-4 w-4 text-orange-600" />
                                                )}
                                              </button>
                                              <p className="font-bold text-sm mx-1 px-1">Impact Note {noteIndex + 1}</p>
                                          </div>

                                      
                                      <div className="flex justify-start items-center">
                                        <p className="font-bold text-xs mx-1 px-1">Remove</p>
                                        <button
                                          type="button"
                                          className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                          onClick={() => {
                                            setFormData((prevState) =>
                                              produce(prevState, (draft) => {
                                                const note =
                                                  draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                                    contributionIndex
                                                  ].roleImpactNotes[noteIndex];
                                          
                                                // Mark for deletion
                                                note.delete = true;
                                          
                                                // Push deleted note to a hidden array for submission
                                                if (!draft.deletedImpactNotes) {
                                                  draft.deletedImpactNotes = []; // Add a separate array for deleted impact notes
                                                }
                                                draft.deletedImpactNotes.push(note);
                                          
                                                // Remove it visually from the form
                                                draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                                  contributionIndex
                                                ].roleImpactNotes.splice(noteIndex, 1);
                                              })
                                            );                                          
                                          }}
                                        >
                                          <MinusIcon className="h-4 w-4 inline" />
                                        </button>
                                      </div>
                                    </div>

                                    {/* Note Text */}
                                    {!collapsedSections[`impactNote-${roleIndex}-${contributionIndex}-${noteIndex}`] && (
                                    <div>
                                        <EtContentEditorLight2
                                          value={note.roleImpactNoteText || ""}
                                          handleChange={(content) => {
                                            setFormData((prevState) =>
                                              produce(prevState, (draft) => {
                                                draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                                  contributionIndex
                                                ].roleImpactNotes[noteIndex].roleImpactNoteText = content;
                                                draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                                  contributionIndex
                                                ].roleImpactNotes[noteIndex].roleImpactNoteUpdateDate = new Date().toISOString();
                                              })
                                            );
                                          }}
                                          placeholderText="Write your impact note here..."
                                          editorKey={`impactNote-${roleIndex}-${contributionIndex}-${noteIndex}`}
                                        />

                                        {/* Note Date */}
                                        <p className="text-xs text-gray-500 mt-2">
                                          Created on: {new Date(note.roleImpactNoteDate).toLocaleDateString()}
                                          {note.roleImpactNoteUpdateDate && (
                                            <span>
                                              , Updated on: {new Date(note.roleImpactNoteUpdateDate).toLocaleDateString()}
                                            </span>
                                          )}
                                        </p>
                                    </div>
                                  )}
                                  </div>
                                ))}
                              </div>

                              {/* 7. Validations */}
                              <div id={`validations-${roleIndex}-${contributionIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                                <div className="flex justify-between items-center">
                                  <p className="font-bold text-sm">Validations</p>
                                  <div className="flex justify-start items-center">
                                    <p className="font-bold text-xs mx-1 px-1">Add</p>
                                    <button
                                      type="button"
                                      className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                                      onClick={() => {
                                        setFormData((prevState) =>
                                          produce(prevState, (draft) => {
                                            draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                              contributionIndex
                                            ].roleContributionsAndImpactValidations.unshift({
                                              validatedBy: null,
                                              validatedOn: new Date().toISOString(),
                                              contributionEvalText: "Absent",
                                              contributionEvalScore: 0,
                                              impactEvalText: "Absent",
                                              impactEvalScore: 0,
                                              validationNotes: "",
                                            });
                                          })
                                        );
                                      }}
                                    >
                                      <PlusIcon className="h-4 w-4 inline" />
                                    </button>
                                  </div>
                                </div>

                                {/* Map through each Validation */}
                                {contribution.roleContributionsAndImpactValidations?.map((validation, validationIndex) => (
                                  <div key={validationIndex} className="p-2 border rounded mb-2 bg-white">
                                    <div className="flex justify-between items-center">
                                      <p className="font-bold text-sm">Validation {validationIndex + 1}</p>
                                      <div className="flex justify-start items-center">
                                        <p className="font-bold text-xs mx-1 px-1">Remove</p>
                                        <button
                                          type="button"
                                          className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                          onClick={() => {
                                            setFormData((prevState) =>
                                              produce(prevState, (draft) => {
                                                draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                                  contributionIndex
                                                ].roleContributionsAndImpactValidations.splice(validationIndex, 1);
                                              })
                                            );
                                          }}
                                        >
                                          <MinusIcon className="h-4 w-4 inline" />
                                        </button>
                                      </div>
                                    </div>

                                    {/* Validator */}
                                    <div className="bg-gray-100 rounded p-1 m-1">
                                      <label
                                        htmlFor={`validatedBy-${roleIndex}-${contributionIndex}-${validationIndex}`}
                                        className="block text-gray-600 text-sm font-bold mb-1"
                                      >
                                        Validator
                                      </label>
                                      <input
                                        type="text"
                                        id={`validatedBy-${roleIndex}-${contributionIndex}-${validationIndex}`}
                                        value={validation.validatedBy || ""}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFormData((prevState) =>
                                            produce(prevState, (draft) => {
                                              draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                                contributionIndex
                                              ].roleContributionsAndImpactValidations[validationIndex].validatedBy = value;
                                            })
                                          );
                                        }}
                                        className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                      />
                                    </div>

                                    {/* Contribution Evaluation */}
                                    <div className="bg-gray-100 rounded p-1 m-1">
                                      <label
                                        htmlFor={`contributionEvalText-${roleIndex}-${contributionIndex}-${validationIndex}`}
                                        className="block text-gray-600 text-sm font-bold mb-1"
                                      >
                                        Contribution Evaluation
                                      </label>
                                      <select
                                        id={`contributionEvalText-${roleIndex}-${contributionIndex}-${validationIndex}`}
                                        value={validation.contributionEvalText || "Absent"}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          const scoreMapping = {
                                            Absent: 0,
                                            Disappointing: 1,
                                            Unsatisfying: 2,
                                            Gratifying: 3,
                                            Fulfilling: 4,
                                            Indebting: 5,
                                          };

                                          setFormData((prevState) =>
                                            produce(prevState, (draft) => {
                                              draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                                contributionIndex
                                              ].roleContributionsAndImpactValidations[validationIndex].contributionEvalText = value;
                                              draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                                contributionIndex
                                              ].roleContributionsAndImpactValidations[validationIndex].contributionEvalScore =
                                                scoreMapping[value];
                                            })
                                          );
                                        }}
                                        className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                      >
                                        <option value="Absent">Absent</option>
                                        <option value="Disappointing">Disappointing</option>
                                        <option value="Unsatisfying">Unsatisfying</option>
                                        <option value="Gratifying">Gratifying</option>
                                        <option value="Fulfilling">Fulfilling</option>
                                        <option value="Indebting">Indebting</option>
                                      </select>
                                    </div>

                                    {/* Impact Evaluation */}
                                    <div className="bg-gray-100 rounded p-1 m-1">
                                      <label
                                        htmlFor={`impactEvalText-${roleIndex}-${contributionIndex}-${validationIndex}`}
                                        className="block text-gray-600 text-sm font-bold mb-1"
                                      >
                                        Impact Evaluation
                                      </label>
                                      <select
                                        id={`impactEvalText-${roleIndex}-${contributionIndex}-${validationIndex}`}
                                        value={validation.impactEvalText || "Absent"}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          const scoreMapping = {
                                            Absent: 0,
                                            Starting: 1,
                                            Promising: 2,
                                            Assuring: 3,
                                            Transforming: 4,
                                            "Game Changing": 5,
                                          };
                                          setFormData((prevState) =>
                                            produce(prevState, (draft) => {
                                              draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                                contributionIndex
                                              ].roleContributionsAndImpactValidations[validationIndex].impactEvalText = value;
                                              draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                                contributionIndex
                                              ].roleContributionsAndImpactValidations[validationIndex].impactEvalScore =
                                                scoreMapping[value];
                                            })
                                          );
                                        }}
                                        className="w-full rounded p-2 border border-gray-300 focus:outline-none"
                                      >
                                        <option value="Absent">Absent</option>
                                        <option value="Starting">Starting</option>
                                        <option value="Promising">Promising</option>
                                        <option value="Assuring">Assuring</option>
                                        <option value="Transforming">Transforming</option>
                                        <option value="Game Changing">Game Changing</option>
                                      </select>
                                    </div>

                                    {/* Validation Notes */}
                                    <div className="bg-gray-100 rounded p-1 m-1">
                                      <label
                                        htmlFor={`validationNotes-${roleIndex}-${contributionIndex}-${validationIndex}`}
                                        className="block text-gray-600 text-sm font-bold mb-1"
                                      >
                                        Validation Notes
                                      </label>
                                      <EtContentEditorLight2
                                        value={validation.validationNotes || ""}
                                        handleChange={(content) => {
                                          setFormData((prevState) =>
                                            produce(prevState, (draft) => {
                                              draft.userImprintRoles[roleIndex].userRoleContributionsAndImpacts[
                                                contributionIndex
                                              ].roleContributionsAndImpactValidations[validationIndex].validationNotes = content;
                                            })
                                          );
                                        }}
                                        placeholderText="Write validation notes here..."
                                        editorKey={`validationNotes-${roleIndex}-${contributionIndex}-${validationIndex}`}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>

                          </div>
                          )}

                        </div>
                      ))}
                    </div>

                  </div>
                )}

              </div>
            ))}

          </div>
  
          {/* User Imprint Role Self Notes Section */}
          <div id="userImprintRoleSelfNotes" className="border rounded p-2 m-2 bg-gray-50">
            <div className="flex justify-between items-center">

              <div className='flex justify-start items-center'>
                  <button
                      type="button"
                      className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                      onClick={() => toggleSection(`selfNotes`)} // Toggle function
                      >
                      {collapsedSections[`selfNotes`] ? (
                        <PlusIcon className="h-4 w-4 text-orange-600" />
                      ) : (
                        <MinusIcon className="h-4 w-4 text-orange-600" />
                      )}
                  </button>
                  <p className="font-bold text-sm">Role Self Notes</p>
              </div>

              
              <div className="flex justify-start items-center">
                <p className="font-bold text-xs mx-1 px-1">Add</p>
                <button
                  type="button"
                  className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) =>
                      produce(prevState, (draft) => {
                        draft.userImprintRoleSelfNotes.unshift({
                          userImprintRoleSelfNoteText: "",
                          userImprintRoleSelfNoteDate: new Date().toISOString(),
                          userImprintRoleSelfNoteUpdateDate: null,
                        });
                      })
                    );
                    // Expand the newly added stakeholder
                    setCollapsedSections((prevState) => ({
                      ...prevState,
                      [`selfNotes`]: false, // Ensure the entire section is expanded
                      [`selfNote-0`]: false,
                    }));
                  }}
                >
                  <PlusIcon className="h-4 w-4 inline" />
                </button>
              </div>
            </div>

            {!collapsedSections[`selfNotes`] && formData.userImprintRoleSelfNotes?.map((note, noteIndex) => (
              <div key={noteIndex} className="p-2 border rounded mb-2 bg-white">
                <div className="flex justify-between items-center">

                  <div className='flex justify-start items-center'>
                    <button
                      type="button"
                      className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                      onClick={() => toggleSection(`selfNote-${noteIndex}`)} // Toggle function
                      >
                      {collapsedSections[`selfNote-${noteIndex}`] ? (
                        <PlusIcon className="h-4 w-4 text-orange-600" />
                      ) : (
                        <MinusIcon className="h-4 w-4 text-orange-600" />
                      )}
                    </button>
                      <p className="font-bold text-sm mx-1 px-1">Self Note {noteIndex + 1}</p>
                      {collapsedSections[`selfNote-${noteIndex}`] && (
                      <div dangerouslySetInnerHTML={createMarkup(note.userImprintRoleSelfNoteText) || ""} className='text-[10px] truncate line-clamp-1 mx-1 px-1 max-w-sm font-normal'/> )}
                  </div>
                  
                  
                  <div className="flex justify-start items-center">
                    {/* <p className="font-bold text-xs mx-1 px-1">Remove</p> */}
                    <button
                      type="button"
                      className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                      onClick={() => {
                        setFormData((prevState) =>
                          produce(prevState, (draft) => {
                            const note =
                              draft.userImprintRoleSelfNotes[noteIndex];
                    
                            if (note._id) {
                              // Mark for deletion if note exists in DB
                              note.delete = true;
                    
                              // Push it to hidden array for submission
                              if (!draft.deletedSelfNotes) {
                                draft.deletedSelfNotes = [];
                              }
                              draft.deletedSelfNotes.push(note);
                            }
                    
                            // Remove it visually from the form
                            draft.userImprintRoleSelfNotes.splice(noteIndex, 1);
                          })
                        );                    
                      }}
                    >
                      <MinusIcon className="h-4 w-4 inline" />
                    </button>
                  </div>
                </div>

                {/* Note Text */}
                {!collapsedSections[`selfNote-${noteIndex}`] && (
                <div>
                  <EtContentEditorLight2
                    value={note.userImprintRoleSelfNoteText}
                    handleChange={(content) => {
                      setFormData((prevState) =>
                        produce(prevState, (draft) => {
                          draft.userImprintRoleSelfNotes[noteIndex].userImprintRoleSelfNoteText = content;
                          draft.userImprintRoleSelfNotes[noteIndex].userImprintRoleSelfNoteUpdateDate = new Date().toISOString();
                        })
                      );
                    }}
                    placeholderText="Write your note here..."
                    editorKey={`selfNote-${noteIndex}`}
                  />
                

                {/* Note Date (Read-Only) */}
                <p className="text-xs text-gray-500 mt-2">
                  Created on: {new Date(note.userImprintRoleSelfNoteDate).toLocaleDateString()}
                  {note.userImprintRoleSelfNoteUpdateDate && (
                    <span>
                      , Updated on: {new Date(note.userImprintRoleSelfNoteUpdateDate).toLocaleDateString()}
                    </span>
                  )}
                </p>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Validation Notes Section */}
          <div id="validationNotes" className="border rounded p-2 m-2 bg-gray-50">
            <div className="flex justify-between items-center mb-2">
              <div className='flex justify-start items-center'>
                    <button
                        type="button"
                        className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                        onClick={() => toggleSection(`validationNotes`)} // Toggle function
                        >
                        {collapsedSections[`validationNotes`] ? (
                          <PlusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                          <MinusIcon className="h-4 w-4 text-orange-600" />
                        )}
                    </button>
                    <p className="font-bold text-sm">Validation Notes</p>
                </div>
            
              <div className="flex justify-start items-center">
                <p className="font-bold text-xs mx-1 px-1">Add</p>
                <button
                  type="button"
                  className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) =>
                      produce(prevState, (draft) => {
                        draft.userImprintRoleValidationNotes.unshift({
                          userImprintRoleValidationNoteText: "",
                          userImprintRoleValidationNoteDate: new Date().toISOString(),
                          userImprintRoleValidationNoteUpdateDate: null,
                        });
                      })
                    );
                    // Expand the newly added stakeholder
                    setCollapsedSections((prevState) => ({
                      ...prevState,
                      [`validationNotes`]: false, // Ensure the entire section is expanded
                      [`validationNote-0`]: false,
                    }));
                  }}
                >
                  <PlusIcon className="h-4 w-4 inline" />
                </button>
              </div>
            </div>

            {formData.userImprintRoleValidationNotes?.map((note, noteIndex) => (
              <div key={noteIndex} className="p-2 border rounded mb-2 bg-white">
                <div className="flex justify-between items-center">

                  <div className='flex justify-start items-center'>
                    <button
                        type="button"
                        className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                        onClick={() => toggleSection(`validationNote-${noteIndex}`)} // Toggle function
                        >
                        {collapsedSections[`validationNote-${noteIndex}`] ? (
                          <PlusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                          <MinusIcon className="h-4 w-4 text-orange-600" />
                        )}
                      </button>
                      <p className="font-bold text-sm mx-1 px-1">Validation Note {noteIndex + 1}</p>
                      {collapsedSections[`validationNote-${noteIndex}`] && (
                      <div dangerouslySetInnerHTML={createMarkup(note.userImprintRoleValidationNoteText) || ""} className='text-[10px] truncate line-clamp-1 mx-1 px-1 max-w-sm font-normal'/> )}

                  </div>

                  
                  <div className="flex justify-start items-center">
                    {/* <p className="font-bold text-xs mx-1 px-1">Remove</p> */}
                    <button
                      type="button"
                      className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                      onClick={() => {
                        setFormData((prevState) =>
                          produce(prevState, (draft) => {
                            draft.userImprintRoleValidationNotes.splice(noteIndex, 1);
                          })
                        );
                      }}
                    >
                      <MinusIcon className="h-4 w-4 inline" />
                    </button>
                  </div>
                </div>

                {/* Note Text */}
                {!collapsedSections[`validationNote-${noteIndex}`] && (
                <div>
                  <EtContentEditorLight2
                    value={note.userImprintRoleValidationNoteText}
                    handleChange={(content) => {
                      setFormData((prevState) =>
                        produce(prevState, (draft) => {
                          draft.userImprintRoleValidationNotes[noteIndex].userImprintRoleValidationNoteText = content;
                          draft.userImprintRoleValidationNotes[noteIndex].userImprintRoleValidationNoteUpdateDate = new Date().toISOString();
                        })
                      );
                    }}
                    placeholderText="Write your validation note here..."
                    editorKey={`validationNote-${noteIndex}`}
                  />

                  {/* Note Date (Read-Only) */}
                  <p className="text-xs text-gray-500 mt-2">
                    Created on: {new Date(note.userImprintRoleValidationNoteDate).toLocaleDateString()}
                    {note.userImprintRoleValidationNoteUpdateDate && (
                      <span>
                        , Updated on: {new Date(note.userImprintRoleValidationNoteUpdateDate).toLocaleDateString()}
                      </span>
                    )}
                  </p>
                </div>
              )}
              </div>
            ))}
          </div>

          {/* About Me Section */}
          <div id="aboutMe" className="bg-gray-100 rounded p-1 m-1 border">
            
            <div className='flex justify-start items-center m-1 p-1'>

                <button
                    type="button"
                    className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                    onClick={() => toggleSection(`aboutMe`)} // Toggle function
                    >
                    {collapsedSections[`aboutMe`] ? (
                      <PlusIcon className="h-4 w-4 text-orange-600" />
                    ) : (
                      <MinusIcon className="h-4 w-4 text-orange-600" />
                    )}
                </button>
                <label
                  htmlFor="aboutMe"
                  className="block text-gray-600 text-sm font-bold mb-1"
                >
                  About Me
                </label>

            </div>
            {!collapsedSections[`aboutMe`] && (
              <div className='m-1 p-1'>
                <EtContentEditorLight2
                  value={formData.aboutMe}
                  handleChange={(content) => {
                    setFormData((prevState) =>
                      produce(prevState, (draft) => {
                        draft.aboutMe = content;
                      })
                    );
                  }}
                  placeholderText="Write about yourself here..."
                  editorKey="aboutMe-editor"
                />
                </div>)}
          
          </div>

          <div className='flex justify-between items-start'>
            {/* Picture Upload Section */}
            <div id="pic" className="bg-gray-100 rounded p-1 m-1 w-full border ">
              <label
                htmlFor="pic"
                className="block text-gray-600 text-sm font-bold mb-1"
              >
                Role Profile Picture
              </label>
              <input
                type="file"
                name="pic"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    convertPicToBase64(file)
                      .then((base64) => {
                        setFormData((prevState) =>
                          produce(prevState, (draft) => {
                            draft.pic = base64;
                          })
                        );
                      })
                      .catch((error) => {
                        console.error("Error converting image to Base64:", error);
                      });
                  }
                }}
                className="w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-orange-300"
              />
              {formData.pic && (
                <img
                  src={formData.pic}
                  alt="Preview"
                  className="mt-2 h-20 w-20 rounded-full border border-gray-300"
                />
              )}
            </div>

            {/* Active Toggle Section */}
            <div id="active" className="bg-gray-100 rounded p-1 m-1 w-full border">
              <label
                htmlFor="active"
                className="block text-gray-600 text-sm font-bold mb-1"
              >
                Is Active?
              </label>
              <input
                type="checkbox"
                name="active"
                id="active"
                checked={formData.active}
                onChange={(e) => {
                  setFormData((prevState) =>
                    produce(prevState, (draft) => {
                      draft.active = e.target.checked;
                    })
                  );
                }}
                className="w-5 h-5 text-orange-500 rounded border-gray-300 focus:ring-orange-500 focus:border-orange-500"
              />
            </div>
          </div>

          {/* Submission Actions Bar */}
          <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic
              name="Update"
              type="create"
              handleClick={handleSubmit}
            />
            
            <EtBtnFormBasic 
              name="Clear" 
              type="clear" 
              handleClick={handleClear} 
              />

            <EtBtnFormBasic 
              name="Cancel" 
              type="cancel" 
              handleClick={handleCancel} 
            />
          </div>
        </form>
      )}
    </div>
  );
  
}

export default EtUpdateUserImprintRoleComp