import React, { useState } from 'react'
import useTitle from '@/hooks/useTitle'
import { useNavigate } from 'react-router'
import { addIcon } from '@/assets/img/imgAssets'
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon,  } from '@heroicons/react/24/outline'
import PulseLoader from "react-spinners/PulseLoader"
import { useGetOrgSubscriptionsQuery } from './orgSubscriptionsApiSlice'

const EtOrgSubscriptionList = ({setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }) => {

    // First Created - 13 May 2024
    // Author - Atul Pandey
    // Updated - 01 Mar 2025 - updated the softSkillFunctionArea with props - { setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }
    // Updated - 01 Mar 2025 - switched from navigate to onAdd, onUpdate, onDelete, setSelectedEntityId

    // 0 - Set Page Title
    useTitle('Org Subscription List')

    // 1 - set page navigations
    const navigate = useNavigate()

    // 2 - Initialize showDetails state as an object where key is the session index
    const [showDetails, setShowDetails] = useState({})

    // 3 - Extract the sessions data
    const { data: orgSubscriptionsData, isError, error, isLoading } = useGetOrgSubscriptionsQuery()
    // console.log('orgSubscriptionsData', orgSubscriptionsData)
    
    // 4 - Transform subscriptions data into a grouped by organization structure
    const orgSubscriptions = orgSubscriptionsData?.ids.reduce((acc, id) => {
        const subscription = orgSubscriptionsData?.entities[id];
        const orgId = subscription.orgId._id;
        if (!acc[orgId]) {
            acc[orgId] = {
                organizationName: subscription.orgId.organizationName, // Adjust according to actual property names
                subscriptions: []
            };
        }
        acc[orgId].subscriptions.push(subscription);
        return acc;
    }, {});

    // console.log('orgSubscriptions', orgSubscriptions)

    if (isLoading) return <PulseLoader color={'#ea580c'} />;
    if (isError) return <div>Error occurred: {error?.data?.message}</div>;

    return (
        <div className='bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black font-poppins'>
            <div className='flex justify-start items-center m-1 p-1'>
                <div><h1 className='p-1 m-1 font-bold font-poppins text-xl'>Organization Subscriptions</h1></div>
                <div>
                    <img
                        src={addIcon}
                        alt=""
                        className="h-10 w-10 cursor-pointer"
                        // onClick={() => navigate("/orgSubscriptions/create")}
                        onClick={onAdd} // 01 Mar 2025
                    />
                </div>

            </div>
            
            {orgSubscriptions && Object.entries(orgSubscriptions).map(([orgId, { organizationName, subscriptions }]) => (
                <div key={orgId} className='bg-white m-1 p-1 rounded'>
                    <div className='bg-gray-300 m-1 p-1 rounded flex justify-start items-center'>
                       
                        <div onClick={() => setShowDetails(prev => ({ ...prev, [orgId]: !prev[orgId] }))} className='cursor-pointer m-1 p-1 rounded-full flex justify-center items-center hover:bg-white'>
                            {showDetails[orgId] ? <MinusIcon className="h-5 w-5 text-orange-600" /> : <PlusIcon className="h-5 w-5 text-orange-600" />}
                        </div> 
                        <h2 className='font-bold'>{organizationName}</h2>
                    </div>
                    {showDetails[orgId] && (
                        <div  className='bg-white m-1 p-1 rounded'>
                            <p className='m-1 p-1 font-bold text-xs'>Subscription Packages:</p>
                            {subscriptions.map(sub => (
                                <div key={sub._id} className='bg-gray-100 rounded m-1 p-1'>
                                    {/* <p>{sub.orgSubscriptionName}</p> */}
                                    {sub.subsPackId?.map((subpack, index) => (
                                        <div id='subscription-pack' key={subpack+index} className='m-1 p-1 bg-white rounded'>
                                            <div id='subsPack-outer' className='flex justify-between items-center m-1 p-1 bg-gray-100 rounded'>
                                                <div id='subsPack-left' className='flex flex-wrap justify-start items-center m-1 p-1 '>
                                                    <div className='m-1 p-1 rounded-full flex justify-center items-center hover:bg-white'>
                                                        <img src={subpack.pic} alt="" className='h-7 w-7' />
                                                    </div>
                                                    <div className='m-1 p-1'><p className='text-[10px]'>{subpack.subscriptionPackName}</p></div>
                                                    <div className='m-1 p-1'><p className='text-[10px] font-bold'>Trails: </p></div>

                                                    {subpack.subscriptionPackTrails?.map((packTrail, trailIndex) => (
                                                        <div key={packTrail._id+trailIndex} className='text-[10px] mx-1 px-1 flex justify-between items-center'>       

                                                            <div className='rounded-full hover:bg-white m-1 p-1 w-7 h-7 flex justify-center items-center'>
                                                                <img src={packTrail.packTrailId.pic} alt="" className='w-5 h-5 shrink-0' />
                                                            </div>
                                                            <p className=''>{packTrail.packTrailId.trailName}</p>


                                                        </div>
                                                    ))}
                                                </div>
                                                <div id='subsPack-right' className='flex justify-start items-center m-1 p-1'>
                                                    <div 
                                                    className='rounded-full h-5 w-5 hover:bg-white flex justify-center items-center mx-1 p-1 cursor-pointer'
                                                    
                                                    // onClick={() => navigate(`/orgSubscriptions/update/${sub._id}`)}
                                                    onClick={() => onUpdate(sub?._id)} // 01 Mar 2025 
                                                    
                                                    
                                                    >
                                                        <PencilSquareIcon className='h-4 w-4 text-orange-600'/>
                                                    </div>
                                                    <div 
                                                    className='rounded-full h-5 w-5 hover:bg-white flex justify-center items-center mx-1 p-1 cursor-pointer'
                                                    
                                                    // onClick={() => navigate(`/orgSubscriptions/delete/${sub._id}`)}
                                                    onClick={() => onDelete(sub?._id)} // 01 Mar 2025
                                                    
                                                    >
                                                        <TrashIcon className='h-4 w-4 text-orange-600'/>
                                                    </div>
                                                    

                                                </div>
                                            </div>
                                            
                                        </div>
                                    ))} 
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default EtOrgSubscriptionList