import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import { useGetUsersQuery } from '../users/usersApiSlice'
import { useGetTrailsQuery } from "../trails/trailsApiSlice"
import { useGetSessionsQuery } from "../sessions/sessionsApiSlice"
import { useGetOrganizationsQuery } from "../organizations/organizationsApiSlice"
import { useGetOrgSubscriptionsQuery } from "../orgSubscriptions/orgSubscriptionsApiSlice" // 06 Aug 2024
import { useCreateNewCoachUserPadMutation } from "./coachUserPadsApiSlice"
import { useCreateNewCoachUserPadsByOrgMutation } from "./coachUserPadsApiSlice"
import { useCreateNewCoachUserPadsAllOrgsMutation } from "./coachUserPadsApiSlice"
import { useGetCoachUserPadsQuery } from "./coachUserPadsApiSlice"


const EtCreateCoachUserPad = ({setActiveAdminView}) => {
  // First Created - 02 Aug 2024
  // Author - Atul Pandey
  // Updated - 05 Aug 2024 - added mutation to create user pads by organization
  // Updated - 05 Aug 2024 - added mutation to create user pads for all organizations
  // Updated - 05 Aug 2024 - refined the filtering of the selection options for existing data
  // Updated - 06 Aug 2024 - refined the organization of code and included org subscriptions to filter data
  // Updated - 03 Mar 2025 - refined to create pads for any user with EtCoach role
  // Updated - 04 Mar 2025 - refined the filters to check for the selected coachId and not just for a user
  // Updated - 04 Mar 2025 - updated the comp with props - { id, setActiveAdminView}
  // Updated - 04 Mar 2025 - switched from navigate to setActiveAdminView

  // 1a - Set Title
  useTitle('Create Coach User Pad')

  // 1b - set page navigations and initial page state
  const navigate = useNavigate()
  const [isSubmitted, setIsSubmitted] = useState(false);
  
  const [selectedOrgId, setSelectedOrgId] = useState(""); // 05 Aug 2024 - for adding coach pads by org
  const [selectedUserId, setSelectedUserId] = useState("") // 06 Aug 2024 - for filtering user trails for a user
  const [selectedTrailId, setSelectedTrailId] = useState("") // 06 Aug 2024 - for filtering user sessions for a trail
  const [selectedSessionId, setSelectedSessionId] = useState("") // 06 Aug 2024 - for selected sessionId 

  const [filteredSessions, setFilteredSessions] = useState([]);
  // const [filteredOrgs, setFilteredOrgs] = useState([]);
  // const [filteredUsers, setFilteredUsers] = useState([]);
  // const [filteredTrails, setFilteredTrails] = useState([]);
  // const [filteredSessionsOptions, setFilteredSessionsOptions] = useState([]);

  const [selectedCoachId, setSelectedCoachId] = useState(""); // 03 Mar 2025
  const [isCreating, setIsCreating] = useState(false); // 04 Mar 2025 - For pulse loader while waiting for the backend response

  // 1c - RTK Mutations to create / update data
  const [createNewCoachUserPad, isSuccess, isError, error] = useCreateNewCoachUserPadMutation()
  const [createNewCoachUserPadsByOrg] = useCreateNewCoachUserPadsByOrgMutation(); // 05 Aug 2024 - by organization
  const [createNewCoachUserPadsAllOrgs] = useCreateNewCoachUserPadsAllOrgsMutation(); // 05 Aug 2024 - all organizations 
  
  // 2 - Get All Data from RTK entities adapters
  const {data: organizationsData} = useGetOrganizationsQuery()
  const {data: usersData, isLoading} = useGetUsersQuery()
  const {data: orgSubscriptionsData} = useGetOrgSubscriptionsQuery() // 06 Aug 2024
  const {data: trailsData} = useGetTrailsQuery()
  const {data: sessionsData} = useGetSessionsQuery()
  const {data: coachUserPadsData, refetch: refetchCoachUserPads} = useGetCoachUserPadsQuery()
  
  
  // console.log('Organizations Data', organizationsData)
  // console.log('Users Data', usersData)
  // console.log('OrgSubscriptions Data', orgSubscriptionsData)
  // console.log('Trails Data', trailsData)
  // console.log('Sessions Data', sessionsData)
  // console.log('CoachUserPads Data', coachUserPadsData)
  

  // 2a - Extract data from entities adapter and store in data entities
  const convertEntitiesData = (adapterData) => {
  if (!adapterData) return [];
  return adapterData?.ids?.map((id) => adapterData?.entities[id]);
  }
  
  const orgs = convertEntitiesData(organizationsData)
  const users = convertEntitiesData(usersData)
  const orgSubscriptions = convertEntitiesData(orgSubscriptionsData)
  const trails = convertEntitiesData(trailsData)
  const sessions = convertEntitiesData(sessionsData)
  const coachUserPads = convertEntitiesData(coachUserPadsData)

  const orgSubscriptionTrails = orgSubscriptions
  ?.filter(sub => sub.orgId._id === selectedOrgId)
  ?.flatMap(sub => 
    sub.subsPackId?.flatMap(pack => 
      pack.subscriptionPackTrails?.map(trail => trail.packTrailId._id)
    )
  );
 
  // console.log('orgs:', orgs)
  // console.log('Users', users)
  // console.log('Org Subscriptions', orgSubscriptions)
  // console.log('Org Subscription Trails', orgSubscriptionTrails)
  // console.log('Trails', trails)
  // console.log('Sessions', sessions)
  // console.log('Existing Coach User Pads', coachUserPads)

  // 2b - Create a list of users who have EtCoach Role
  const coachUsers = users?.filter(user => user.roles?.includes("EtCoach")) || [];
  // console.log('coachUsers', coachUsers)
  

  // 3 - Filter data based on the existing data in the coach user pads

  // 3a - Filter 1 - filter all the sessions for a user in a trail
  // 3a - 1 - Check the existing data in coach user pads
  // 3a - 2 - If there is no existing data, then all organizations, users, trails and sessions need to be in. No data should filter out
  // 3a - 3 - 1 - if there are existing records (one or more), then first check the orgzniation id for the user and 
  // 3a - 3 - 2 - then filter the users data to build a list of all the users for that org
  // 3a - 3 - 3 - then check the org subscriptions for that org to find out the subscription packs
  // 3a - 3 - 4 - then check the trails in the subscription packs
  // 3a - 3 - 5 - then check the session in the coach user pad which has a matching trail in prior step (3a -3 -4)
  // 3a - 3 - 6 - the session found in the prior step (3a - 3 - 5) should be filtered out for the selected org, user and trail.
  // 3a - 3 - 7 - repeat the above process till all sessions for an org, user, trail are available in coach user pad records
  // 3a - Filter 1 - complete - for an org, a user, a trail, all sessions are now in coach user pads. Move to next trail for the same org and user

  // 3b - Filter 2 - filter all the trails for a user
  // 3b - 1 - If all sessions for a trail for a user inside an org are in coach users pad
  // 3b - 2 - 1 - then, filter out that trail from the trails select list for that user
  // 3b - 2 - 2 - user now selects the next trail from the list
  // 3b - 2 - 3 - if the next trail is available, then populate the sessions list for that trail and filter out based on the logic in Filter 1
  // 3b - 2 - 4 - if there are no more trails available for the user, then this filter is complete. Move to the next user for the same org and list all the trails for the next user
  
  // 3c - Filter 3 - filter all the users for an organization
  // 3c - 1 - If all trails and all the sessions for those trails for a user inside an org are in coach user pads
  // 3c - 2 - 1 - then, filter out that user from the users select list for that org
  // 3c - 2 - 2 - user now selects the next user from the list
  // 3c - 2 - 3 - if the next user is available for the org, then populate the trails for that user and filter out based on the logic in Filter 2
  // 3c - 2 - 4 - if there are no more users available for this org, then this filter is complete. Move to the next org and list all the users for the next org

  // 3d - Filter 4 - filter all the organizations
  // 3d - 1 - If all the users inside an org are in coach user pads
  // 3d - 2 - 1 - then, filter out that organization from the org select list
  // 3d - 2 - 2 - user now selects the next org from the list
  // 3d - 2 - 3 - if the next org is available, then populate the users for that org and filter out based on the logic in Filter 3
  // 3d - 2 - 4 - if there are no more orgs available, then we are done with all the filters and we can bring the filtering to an end


  // 4a - Filter organizations - refined 04 Mar 2025 - coachId / selectedCoachId
  const filteredOrgs = orgs?.filter(org => {
    const orgUsers = users?.filter(user => user.userOrgId._id === org._id);
    
    return !orgUsers?.every(user => {
      // const userPads = coachUserPads?.filter(pad => pad.userId._id === user._id);
       // 04 Mar 2025
      const userPads = coachUserPads?.filter(
        pad => pad.userId._id === user._id && pad.coachId._id === selectedCoachId
      );
  
      // Get the trails associated with this user's organization via orgSubscriptions
      const userTrails = orgSubscriptions
        ?.filter(sub => sub.orgId._id === org._id)
        ?.flatMap(sub => 
          sub.subsPackId?.flatMap(pack => 
            pack.subscriptionPackTrails?.map(trail => trail.packTrailId._id)
          )
        );
  
      return userTrails?.every(trailId => {
        const trailSessions = sessions?.filter(session => session.trailId._id === trailId);
        return trailSessions?.every(session => userPads?.some(pad => pad.trailId._id === trailId && pad.sessionId._id === session._id));
      });
    });
  });
    
// 4b - Filter users
const filteredUsers = selectedOrgId ? users?.filter(user => {
  if (user.userOrgId._id !== selectedOrgId) return false;

  // const userPads = coachUserPads?.filter(pad => pad.userId._id === user._id);
  // 04 Mar 2025
  const userPads = coachUserPads?.filter(
    pad => pad.userId._id === user._id && pad.coachId._id === selectedCoachId
  );
    
  return trails?.some(trail => {
    const trailSessions = sessions?.filter(session => session.trailId._id === trail._id);
    return trailSessions?.some(session => !userPads?.some(pad => pad.trailId._id === trail._id && pad.sessionId._id === session._id));
  });
}) : [];

// 4c - Filtered trails
const filteredTrails = selectedUserId ? trails?.filter(trail => {
  // const userPads = coachUserPads?.filter(pad => pad.userId._id === selectedUserId);
  // 04 Mar 2025
  const userPads = coachUserPads?.filter(
    pad => pad.userId._id === selectedUserId && pad.coachId._id === selectedCoachId
  );
  
  
  return orgSubscriptionTrails?.includes(trail._id) &&
    sessions?.some(session => session.trailId._id === trail._id && !userPads.some(pad => pad.trailId._id === trail._id && pad.sessionId._id === session._id));
}) : [];

// console.log('Filtered orgs:', filteredOrgs)
// console.log('Filtered users:', filteredUsers)
// console.log('Filtered trails:', filteredTrails)

  // 5a - Select an Organization
  const orgOptions = filteredOrgs ? [
    <option key="placeholder" value="">
      Select an org
    </option>,
    ...filteredOrgs?.map(org => (
      <option key={org._id} value={org._id}>
        {org.organizationName}
      </option>
    ))
  ] : [];
  
  // 5b - Select a User
  const userOptions = filteredUsers ? [
    <option key="placeholder" value="">
      Select a user
    </option>,
    ...filteredUsers?.map(user => (
      <option key={user._id} value={user._id}>
        {user.name}
      </option>
    ))
  ] : [];
  
  // 5c - Select a Trail
  const trailOptions = filteredTrails ? [
    <option key="placeholder" value="">
      Select a trail
    </option>,
    ...filteredTrails?.map(trail => (
      <option key={trail._id} value={trail._id}>
        {trail.trailName}
      </option>
    ))
  ] : [];

  // 5d - Select a Session
  const sessionOptions = selectedTrailId ? [
    <option key="placeholder" value="">
      Select a session
    </option>,
    ...filteredSessions?.filter(session => {

      // const userPads = coachUserPads?.filter(pad => pad.userId._id === selectedUserId);
      // 04 Mar 2025
      const userPads = coachUserPads?.filter(
        pad => pad.userId._id === selectedUserId && pad.coachId._id === selectedCoachId
      );
      
      
      return !userPads.some(pad => pad.trailId._id === selectedTrailId && pad.sessionId._id === session._id);
    }).map(session => (
      <option key={session._id} value={session._id}>
        {session.sessionName}
      </option>
    ))
  ] : [
    <option key="placeholder" value="">
      Select a session
    </option>
  ];
  
  // 6 - create the form data object
  const [formData, setFormData] = useState({
    // coachId:coachId,
    coachId:selectedCoachId, // 03 Mar 2025
    userId:"",
    trailId:"",
    sessionId:"",
    orgId: "",                // 07 Aug 2024
    coachSessionSelfNotes: [],
    coachSessionUserNotes:[],
  })

  // 7a - Generic Handle Add Field Function
  const handleAdd = (fieldName, newItem) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: [...prevState[fieldName], newItem]
    }))
  }

  // 7b - Generic Handle Remove Field Function
  const handleRemove = (fieldName, index) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: prevState[fieldName].filter((_, i) => i !== index)
    }))
  }

  // 7c - Generic Handle Field Change Function
  const handleFieldChange = (fieldName, index, value) => {
    const updatedItems = formData[fieldName].map((item, i) => i === index ? value : item)
    setFormData({ ...formData, [fieldName]: updatedItems })
  }

  // 7d - Form field updates / change handling
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "pic") {
      setFormData(prevState => ({ ...prevState, pic: files[0] }));
    } else {
      setFormData(prevState => ({ ...prevState, [name]: value }));
      if (name === "trailId") {
        const trailSessions = sessions?.filter(session => session.trailId._id === value);
        // console.log('trail sessions', trailSessions);
        setFilteredSessions(trailSessions);
        setSelectedTrailId(value); // Update selectedTrailId
        setFormData(prevState => ({ ...prevState, sessionId: "" })); // Reset session selection
      }
    }
  };

  // 7e - Handle organization selection
  const handleOrgChange = (e) => {
    setSelectedOrgId(e.target.value);
  }

  // 7f - Handle user selection
  const handleUserChange = (e) => {
    setSelectedUserId(e.target.value);
    setFormData(prevState => ({
      ...prevState,
      userId: e.target.value
    }));
  };

  // 7g - Handle session change
  const handleSessionChange = (e) => {
    setSelectedSessionId(e.target.value);
  }

  // 7h - Handle Coach Selection - 03 Mar 2025
  const handleCoachChange = (e) => setSelectedCoachId(e.target.value);

  // 8 - Wrapper functions for specific fields
  const handleAddCoachSelfNote = () => handleAdd('coachSessionSelfNotes', { coachSelfNoteText: "" })
  const handleRemoveCoachSelfNote = (index) => handleRemove('coachSessionSelfNotes', index)
  const handleCoachSelfNoteChange = (index, value) => handleFieldChange('coachSessionSelfNotes', index, { coachSelfNoteText: value })
  
  const handleAddCoachUserNote = () => handleAdd('coachSessionUserNotes', { coachUserNoteText: "" })
  const handleRemoveCoachUserNote = (index) => handleRemove('coachSessionUserNotes', index)
  const handleCoachUserNoteChange = (index, value) => handleFieldChange('coachSessionUserNotes', index, { coachUserNoteText: value })

  // 9 -  Determine if the Create All button should be disabled
  const isCreateAllDisabled = filteredOrgs.length === 0;

  // 10 - Reset form upon successful submission
  useEffect(() => {
    if(isSuccess && isSubmitted) {
        setFormData({
          // coachId:coachId,
          coachId:selectedCoachId, // 03 Mar 2025
          userId:"",
          trailId:"",
          sessionId:"",
          orgId: "",                // 07 Aug 2024
          coachSessionSelfNotes: [],
          coachSessionUserNotes:[],
        })
        // navigate('/coachUserPad')
        setActiveAdminView('coachUserPads'); // 04 Mar 2025
      }
      
      },[isSuccess, isSubmitted])// ✅ 04 Mar 2025 - Removed `navigate` since it's no longer used.

  // 11a - Form Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitted(true); // Set the submission state
    setIsCreating(true) // 04 Mar 2025 - to load the pulse loader
  
    // Initialize an object to collect the form data
    const coachUserPadData = {
    // coachId:coachId,
    coachId:selectedCoachId, // 03 Mar 2025
    userId: selectedUserId,
    trailId: selectedTrailId,
    sessionId: selectedSessionId,
    orgId:selectedOrgId,            // 07 Aug 2024
    coachSessionSelfNotes: formData.coachSessionSelfNotes,
    coachSessionUserNotes:formData.coachSessionUserNotes,
    }
    console.log('coachUserPadData Form', coachUserPadData)
    
    if (formData.pic) {
      // Convert the image to a base64 string
      const reader = new FileReader()
      reader.onloadend = async () => {
        // Once conversion is done, add the base64 string to your data object
        coachUserPadData.pic = reader.result
  
        // Now, send the data object to the server
        try {
          console.log("Form submitted.")
          await createNewCoachUserPad(coachUserPadData).unwrap() // Dispatch the RTK Query mutation
          
        } catch (error) {
          console.error(error)
        }finally {
          setIsCreating(false); //  04 Mar 2025 - Set loading state to false after the request completes
        }
      }
      reader.readAsDataURL(formData.pic)
    } else {
      // No image, send other data as is
      try {
        console.log("Form submitted.")
        await createNewCoachUserPad(coachUserPadData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }finally {
        setIsCreating(false); //  04 Mar 2025 - Set loading state to false after the request completes
      }
    }
  }


  // 11b - Handle creating coach user pads by organization - 05 Aug 2024
  const handleCreateByOrg = async () => {
    if (!selectedOrgId) return alert("Please select an organization");
    setIsCreating(true) // 04 Mar 2025 - to load the pulse loader
    try {
        // await createNewCoachUserPadsByOrg({ orgId: selectedOrgId, coachId }).unwrap();
        await createNewCoachUserPadsByOrg({ orgId: selectedOrgId, coachId: selectedCoachId }).unwrap(); // 03 Mar 2025
        alert("Coach User Pads created successfully");
        refetchCoachUserPads(); // Trigger a refetch of the coach user pads data
        setSelectedOrgId(""); // Clear the selected organization
        // navigate('/coachUserPad')
      setActiveAdminView('coachUserPads'); // 04 Mar 2025
    } catch (error) {
        console.error("Failed to create coach user pads:", error);
        alert("Failed to create coach user pads");
    }finally {
      setIsCreating(false); //  04 Mar 2025 - Set loading state to false after the request completes
    }
  }

  // 11c - Handle creating coach user pads for all organizations - 05 Aug 2024

  const handleCreateAllOrg = async () => {
    setIsCreating(true) // 04 Mar 2025 - to load the pulse loader
    try {
      // await createNewCoachUserPadsAllOrgs({ coachId }).unwrap();
      await createNewCoachUserPadsAllOrgs({ coachId: selectedCoachId }).unwrap(); // 03 Mar 2025
      alert("Coach User Pads created successfully for all organizations");
      // navigate('/coachUserPad')
      setActiveAdminView('coachUserPads'); // 04 Mar 2025
      
    } catch (error) {
        console.error("Failed to create coach user pads for all organizations:", error);
        alert("Failed to create coach user pads for all organizations");
    }finally {
      setIsCreating(false); //  04 Mar 2025 - Set loading state to false after the request completes
    }
  }
  
    // 12 - Handle Cancel
    const handleCancel = () => {
      // navigate('/coachUserPad')
      setActiveAdminView('coachUserPads'); // 04 Mar 2025
    }
  
  // 13 - Handle Clear
    const handleClear = () => {
      setFormData({
        coachId:"",
        userId:"",
        trailId:"",
        sessionId:"",
        orgId: "",                // 07 Aug 2024
        coachSessionSelfNotes: [],
        coachSessionUserNotes:[],
      })
    }
  
    return (
        <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
          {isLoading && <PulseLoader color={"#ea580c"} />}
          {isError && (
            <p className="text-orange-700 font-semi-bold py-2">
              {error?.data?.message}
            </p>
          )}
          <div className="bg-white p-1 m-1 rounded font-poppins">
            <p className="p-1 m-1 font-bold  text-xl">Add a Coach User Session Pad</p>

            {/* 03 Mar 2025 - Select a coach */}
            <div className="m-1 p-1 rounded border">
                <div className="flex justify-start items-center">
                    <p className="font-semibold text-nowrap">Coach : </p>
                      <select
                        name="coachId"
                        id="coachId"
                        value={selectedCoachId}
                        onChange={handleCoachChange}
                        className="rounded p-2 m-1 border"
                      >
                        <option value="">Select a coach</option>
                        {coachUsers.map(coach => (
                          <option key={coach._id} value={coach._id}>
                            {coach.name}
                          </option>
                        ))}
                      </select>
                </div>
              </div>

            <div className="bg-gray-200 rounded m-1 p-1">
              <div className="grid grid-cols-12 gap-1 justify-between items-top "> 
                {/* Section 1 - Create by user, trail, session */}
                <div className="col-span-4 m-1 p-1 border rounded border-white">
                  <div className="m-1 p-1">
                      <p className="text-lg font-bold mx-1 px-1">Option 1 - Create By User</p>
                  </div>
                  <form
                    onSubmit={handleSubmit}
                    className="text-xs font-poppins"
                  >
                    <div id="orgSelector" className="mx-1 px-1">
                      <select
                        name="selectedOrgId"
                        id="selectedOrgId"
                        value={selectedOrgId}
                        onChange={handleOrgChange}
                        className="w-full rounded p-2 m-1"
                        disabled={isCreateAllDisabled}
                      >
                        {orgOptions}
                      </select>
                    </div>
                    <div id="userSelector" className="mx-1 px-1">
                      <select
                        name="userId"
                        id="userId"
                        value={selectedUserId}
                        onChange={handleUserChange}
                        className="w-full rounded p-2 m-1"
                        disabled={isCreateAllDisabled}
                      >
                        {userOptions}
                      </select>
                    </div>

                    <div id="trailSelector" className="mx-1 px-1">
                      <select
                        name="trailId"
                        id="trailId"
                        value={selectedTrailId}
                        onChange={handleChange}
                        className="w-full rounded p-2 m-1"
                        disabled={isCreateAllDisabled}
                      >
                        {trailOptions}
                      </select>
                    </div>
        
                    <div id="sessionSelector" className="mx-1 px-1">
                      <select
                        name="sessionId"
                        id="sessionId"
                        value={selectedSessionId}
                        onChange={handleSessionChange}
                        className="w-full rounded p-2 m-1"
                        disabled={isCreateAllDisabled}
                      >
                        {sessionOptions}
                      </select>
                    </div>  
        
                    <div id="actions" className="bg-white flex justify-between items-center my-1 ml-3 mr-1 p-2 rounded">
                      <EtBtnFormBasic
                        name={"Create"}
                        type={"create"}
                        handleClick={handleSubmit}
                      />
                      <EtBtnFormBasic
                        name={"Clear"}
                        type={"clear"}
                        handleClick={handleClear}
                      />
                      <EtBtnFormBasic
                        name={"Cancel"}
                        type={"cancel"}
                        handleClick={handleCancel}
                      />
                    </div>
                  </form>
                </div>


                {/* Section 2 - Create By Org */}{/* 05 Aug 2024 */}
                <div id="orgSelector" className="col-span-4 m-1 p-1 text-xs border rounded border-white"> 
                    <div className="m-1 p-1">
                      <p className="text-lg font-bold mx-1 px-1">Option 2 - Create By Org</p>
                    </div>
                    <div className="mx-1 px-1">
                      <select
                        name="orgId"
                        id="orgId"
                        value={selectedOrgId}
                        onChange={handleOrgChange}
                        className="w-full rounded p-2 m-1"
                        disabled={isCreateAllDisabled}
                    >
                        {orgOptions}
                    </select>
                    </div>
                    
                    <div className="m-1 p-1">
                      <button 
                        type="button" 
                        onClick={handleCreateByOrg} 
                        className={`bg-lime-500 hover:bg-lime-700 text-white mx-1 py-2 px-4 rounded ${isCreateAllDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={isCreateAllDisabled}
                        >
                        {/* Create By Org */}
                        {isCreating ? <PulseLoader color={"#ffffff"} size={10} /> : "Create By Org"}
                    </button>
                    </div> 
                </div>

                {/* Section 3 - Create for all orgs */}
                <div className="col-span-4 m-1 p-1 text-xs border rounded border-white">
                  <div className="m-1 p-1">
                      <p className="text-lg font-bold mx-1 px-1">Option 3 - Create All</p>
                  </div>
                  <div className="m-1 p-1">
                      <button 
                        type="button" 
                        onClick={handleCreateAllOrg} 
                        className={`bg-lime-500 hover:bg-lime-700 text-white mx-1 py-2 px-4 rounded ${isCreateAllDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={isCreateAllDisabled}
                        >
                        {/* Create All */}
                        {isCreating ? <PulseLoader color={"#ffffff"} size={10} /> : "Create All"}
                    </button>
                    </div> 
                  
                </div>

              </div>

            </div>
          </div>
        </div>
      )
}

export default EtCreateCoachUserPad