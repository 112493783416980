import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userSessionImplementationsAdapter = createEntityAdapter({})

const initialState = userSessionImplementationsAdapter.getInitialState()

export const userSessionImplementationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserSessionImplementations: builder.query({
            query: () => ({
                url: '/userSessionImplementations/getUserSessionImplementations',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserSessionImplementations = responseData.map(userSessionImplementation => {
                    userSessionImplementation.id = userSessionImplementation._id
                    return userSessionImplementation
                })
                return userSessionImplementationsAdapter.setAll(initialState, loadedUserSessionImplementations)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserSessionImplementation', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserSessionImplementation', id}))
                    ]
                } else {
                    return [ { type: 'UserSessionImplementation', id: 'LIST'}]
                }
            },
        }),
        createNewUserSessionImplementation: builder.mutation({
            query: initialUserSessionImplementation => ({
                url: '/userSessionImplementations/createNewUserSessionImplementation',
                method: 'POST',
                body: {...initialUserSessionImplementation,}
            }),
            invalidatesTags: [
                { type: 'UserSessionImplementation', id: "LIST" }
            ] 
        }),
        updateUserSessionImplementation: builder.mutation({
            query: initialUserSessionImplementation => ({
                url: '/userSessionImplementations/updateUserSessionImplementation',
                method: 'PATCH',
                body: {...initialUserSessionImplementation,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserSessionImplementation', id: arg.id },
                { type: 'UserSessionImplementation', id: 'LIST' }
            ],
        }),
        deleteUserSessionImplementation: builder.mutation({
            query: ({id}) => ({
                url: '/userSessionImplementations/deleteUserSessionImplementation',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserSessionImplementation', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserSessionImplementationsQuery,
    useCreateNewUserSessionImplementationMutation,
    useUpdateUserSessionImplementationMutation,
    useDeleteUserSessionImplementationMutation,
} = userSessionImplementationsApiSlice

// returns the query result object
export const selectUserSessionImplementationResult = userSessionImplementationsApiSlice.endpoints.getUserSessionImplementations.select()

// create memoized selector
const selectUserSessionImplementationsData = createSelector(
    selectUserSessionImplementationResult,
    userSessionImplementationResult => userSessionImplementationResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserSessionImplementations,
    selectById: selectUserSessionImplementationById,
    selectIds: selectUserSessionImplementationIds,
} = userSessionImplementationsAdapter.getSelectors(state => selectUserSessionImplementationsData(state) ?? initialState)