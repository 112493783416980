
    import React from 'react';
    
    const EtWabChallengeIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m1954.8 3070.8c-36 100.8 18 208.8 115.2 244.8 21.602 7.1992 43.199 10.801 64.801 10.801 79.199 0 151.2-50.398 180-129.6l208.8-604.8-298.8-295.2z" fillRule="evenodd"/>
 <path d="m3423.6 1587.6-252-126-198-331.2c-21.602-36-54-61.199-93.602-72l-367.2-104.4c-43.199-10.801-90-7.1992-129.6 18l-399.6 248.4c-25.199 14.398-46.801 39.602-57.602 64.801l-165.6 342c-39.602 79.199-7.1992 172.8 72 212.4 79.199 39.602 172.8 7.1992 212.4-72l147.6-302.4 100.8-61.199-90 525.6c-10.801 61.199 10.801 126 54 169.2l432 424.8 126 648c18 90 97.199 154.8 187.2 154.8 10.801 0 25.199 0 36-3.6016 104.4-21.602 172.8-118.8 151.2-223.2l-136.8-705.6c-7.1992-39.602-25.199-72-54-100.8l-248.4-244.8 115.2-475.2 57.602 93.602c14.398 25.199 39.602 46.801 64.801 61.199l291.6 147.6c21.602 10.801 46.801 18 72 18 57.602 0 115.2-32.398 144-86.398 36.004-86.406 3.6016-180.01-71.996-219.61z" fillRule="evenodd"/>
 <path d="m3186 612c0 184.91-149.89 334.8-334.8 334.8-184.9 0-334.8-149.89-334.8-334.8s149.9-334.8 334.8-334.8c184.91 0 334.8 149.89 334.8 334.8" fillRule="evenodd"/>
 <path d="m1058.4 2905.2h-104.4c-43.199 0-79.199 36-79.199 79.199 0 43.199 36 79.199 79.199 79.199h104.4c43.199 0 79.199-36 79.199-79.199 0.003906-43.199-35.996-79.199-79.199-79.199z" fillRule="evenodd"/>
 <path d="m1792.8 2905.2h-104.4c-43.199 0-79.199 36-79.199 79.199 0 43.199 36 79.199 79.199 79.199h104.4c43.199 0 79.199-36 79.199-79.199 0-43.199-36-79.199-79.199-79.199z" fillRule="evenodd"/>
 <path d="m1425.6 2905.2h-104.4c-43.199 0-79.199 36-79.199 79.199 0 43.199 36 79.199 79.199 79.199h104.4c43.199 0 79.199-36 79.199-79.199 0-43.199-36-79.199-79.199-79.199z" fillRule="evenodd"/>
 <path d="m766.8 2984.4c0 186.89-151.51 338.4-338.4 338.4-186.89 0-338.4-151.51-338.4-338.4 0-186.89 151.51-338.4 338.4-338.4 186.89 0 338.4 151.51 338.4 338.4" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtWabChallengeIcon1SVGComp;
    