import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import {
  useGetSectionsQuery,
  useUpdateSectionMutation,
} from "./sectionsApiSlice"
import { useGetQuestionSetsQuery } from "../questionSets/questionSetsApiSlice"
import { useGetQuestionsQuery } from "../questions/questionsApiSlice"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLightWithPlace from "@/utils/EtContentEditorLightWithPlace"
import EtContentEditorLight3 from "@/utils/EtContentEditorLight3"

const EtUpdateSection = ({id, setActiveAdminView}) => {
  // Created - 11 Apr 2024
  // Author - Atul Pandey
  // Updated - 01 Mar 2025 - updated the softSkillFunctionArea with props - { id, setActiveAdminView}
  // Updated - 01 Mar 2025 - switched from navigate to setActiveAdminView

  // 1 - Set Title
  useTitle("Update a Section")

  // 2 - extract the question Id from the url
  // const { id } = useParams()
  // console.log("id:", id)

  // 3 - Extract the section from the query to load the section data
  const { section } = useGetSectionsQuery("Section", {
    selectFromResult: ({ data }) => ({
      section: data?.entities[id],
    }),
  })

  // 4 - Check for returned data
  if (!section) {
    return <PulseLoader color={"#ea580c"} />
  }

  // 5. Display data
  let content
  content = <EtUpdateSectionForm section={section} id={id} setActiveAdminView={setActiveAdminView}/> // 01 Mar 2025 - added setActiveAdminView={setActiveAdminView} 

  return content
}

const EtUpdateSectionForm = ({ section, id, setActiveAdminView }) => {
  
  // 1 - set the variable for navigate
  const navigate = useNavigate()

  // 2 - Define the mutation to update the section
  const [updateSection, { isLoading, isSuccess, isError, error }] =
    useUpdateSectionMutation()

  // 3 - Fill the form data with extracted section info
  const [formData, setFormData] = useState({
    sectionName: section.sectionName,
    sectionIntro: section.sectionIntro,
    sectionOutro: section.sectionOutro,
    aboutMe: section.aboutMe,
    questionSetId: section.questionSetId,
    questions: section.questions || [],
  })
 
  // 3a - Initialize selected Question and set selected question
  const [selectedQuestion, setSelectedQuestion] = useState("")
  const [allQuestionsAdded, setAllQuestionsAdded] = useState(false)
  
  // 3b -Initialize selectedQuestionSetId state right after formData
  const [selectedQuestionSetId, setSelectedQuestionSetId] = useState(
    section.questionSetId._id
  )

  // 3c - Sync selectedQuestionSetId with formData.questionSetId
  useEffect(() => {
    if (formData.questionSetId && formData.questionSetId._id) {
      setSelectedQuestionSetId(formData.questionSetId._id);
    }
  }, [formData.questionSetId])

  // 4 - create Question Set Options to populate the select box
  const { qSets } = useGetQuestionSetsQuery("questionSetList", {
    selectFromResult: ({ data }) => ({
      qSets: data?.ids.map((id) => data.entities[id]),
    }),
  })

  // 4a - Generate the options for the select dropdown
  const qSetOptions = qSets
    ? qSets.map((set) => (
        <option key={set._id} value={set._id}>
          {set.setname}
        </option>
      ))
    : []

  // 5 - Get all the questions and then filter them based on the selected Question Set
  const { questions } = useGetQuestionsQuery('questionList', {
    selectFromResult: ( { data }) => ({
      questions: data?.ids.map(id => data?.entities[id])
    })
  })
  
  const filteredQuestions = questions?.filter(question => question.setname === selectedQuestionSetId) || []

  // 6 - Reset the form on isSuccess event
  useEffect(() => {
    if(isSuccess) {
      setFormData({
        sectionName: "",
        sectionIntro: "",
        sectionOutro: "",
        aboutMe: "",
        pic: "",
        questionSetId: "",
        questions: [],
      })
      // navigate('/sections')
      setActiveAdminView('sections'); // 01 Mar 2025
    }
    
    },[isSuccess])// ✅ 01 Mar 2025 - Removed `navigate` since it's no longer used.


  // 7 - Form Handling functions

  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }))
    } else if (name === "questionSetId") {
      setSelectedQuestionSetId(value);
      setFormData(prev => ({
        ...prev,
        questionSetId: value,
        questions: [],  // Reset questions on question set change
      }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }))
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
  
    // Initialize an object to collect the form data
    const sectionData = {
      sectionName: formData.sectionName,
      sectionIntro: formData.sectionIntro,
      sectionOutro: formData.sectionOutro,
      aboutMe: formData.aboutMe,
      questionSetId: formData.questionSetId,
      questions: formData.questions
    }
  
    console.log('section Name:',sectionData.sectionName)
    console.log('questions in sectionData:', sectionData.questions)
  
    if (formData.pic) {
      // Convert the image to a base64 string
      const reader = new FileReader()
      reader.onloadend = async () => {
        // Once conversion is done, add the base64 string to your data object
        sectionData.pic = reader.result
  
        // Now, send the data object to the server
        try {
          sectionData.id = id
          console.log("Form submitted.")
          await updateSection(sectionData).unwrap() // Dispatch the RTK Query mutation
        } catch (error) {
          console.error(error)
        }
      }
      reader.readAsDataURL(formData.pic)
    } else {
      // No image, send other data as is
      try {
        sectionData.id = id
          console.log("Form submitted.")
          await updateSection(sectionData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleClear = () => {
    setFormData({
      sectionName: "",
      sectionIntro: "",
      sectionOutro: "",
      aboutMe: "",
      pic: "",
      questionSetId: "",
      questions: [],
      })
  }

  const handleCancel = () => {
    // navigate('/sections')
    setActiveAdminView('sections'); // 01 Mar 2025
  }

  // Function to strip html tags from questions
    function stripHtml(html) {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
    }

// Question Manipulation functions

const handleSelectChange = (e) => {
  setSelectedQuestion(e.target.value);
};

const handleAddQuestion = () => {
  if (!formData.questions.some(q => q._id === selectedQuestion) && selectedQuestion) {
    const questionToAdd = filteredQuestions.find(q => q._id === selectedQuestion);
    if (questionToAdd) {
      setFormData(prevState => ({
        ...prevState,
        questions: [...prevState.questions, questionToAdd]
      }));
      setSelectedQuestion(''); // Resetting after adding
    }
  }
}

useEffect(() => {
  // Check if all questions from the set have been added
  setAllQuestionsAdded(filteredQuestions?.length === formData.questions?.length);
}, [formData.questions, filteredQuestions.length]);


const handleRemoveQuestion = (questionId) => {
  console.log('handleRemoveQuestion function call made!')
  setFormData(prevState => ({
    ...prevState,
    questions: prevState.questions.filter(q => q._id !== questionId)
  })); 
  console.log('formData after remove', formData)
}

useEffect(() => {
  console.log('Updated questions list:', formData.questions);
}, [formData.questions]);


  return (
    <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
      {isLoading && <PulseLoader color={"#ea580c"} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">
          {error?.data?.message}
        </p>
      )}

      <div className="bg-white p-1 m-1 rounded">
        <p className="p-1 m-1 font-bold font-poppins text-xl">
          Update a Section
        </p>
        <div className="bg-gray-200 rounded m-1 p-1">
          <form
            onSubmit={handleSubmit}
            className="m-1 p-1 text-xs font-poppins"
          >
            <div id="questionSetSelector">
              <select
                name="questionSetId"
                id="questionSetId"
                value={selectedQuestionSetId}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              >
                {qSetOptions}
              </select>
            </div>

            <div id="sectionName">
              <input
                type="text"
                name="sectionName"
                placeholder="Enter the section name here"
                value={formData.sectionName}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            {/* Add other inputs for sectionIntro, sectionOutro, aboutMe, and pic here */}

            <div className="bg-white w-full rounded p-2 m-1">
              <EtContentEditorLightWithPlace
                value={formData.sectionIntro}
                handleChange={(content) =>
                  setFormData({ ...formData, sectionIntro: content })
                }
                placeholderText={
                  "Write what people would see when this section starts!"
                }
                // toolbarId={'sectionIntro'}
              />
            </div>

            <div className="bg-white w-full rounded p-2 m-1">
              <EtContentEditorLight3
                value={formData.sectionOutro}
                handleChange={(content) =>
                  setFormData({ ...formData, sectionOutro: content })
                }
                placeholderText={
                  "Write what people would see when this section ends!"
                }
                toolbarId={"sectionOurtro"}
              />
            </div>

            <div id="aboutMe">
              <input
                type="text"
                name="aboutMe"
                placeholder="Add something about the personality about this section"
                value={formData.aboutMe}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="pic">
              <input
                type="file"
                name="pic"
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            {/* Additional elements for editing questions will be added here */}

            <div className="rounded border border-white m-1 p-1">
              <h3 className="text-lg font-bold m-1 p-1">Section Questions</h3>
              {formData.questions?.map((question, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center bg-gray-100 p-2 rounded my-1"
                >
                  <span>{stripHtml(question.questionName)}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveQuestion(question._id)}
                    className="py-1 px-3 rounded bg-red-500 text-white"
                  >
                    -
                  </button>
                </div>
              ))}

              {/* Dropdown to select new questions */}
              <select
                value={selectedQuestion}
                onChange={handleSelectChange}
                className="w-full rounded p-2 m-1"
              >
                <option value="">Select a question</option>
                {filteredQuestions?.map((question) => (
                  <option key={question._id} value={question._id}>
                    {stripHtml(question.questionName)}
                  </option>
                ))}
              </select>
              {/* Conditionally display the Add button */}
              {filteredQuestions?.length !== formData.questions?.length && (
                <button
                  type="button"
                  onClick={handleAddQuestion}
                  className="my-2 py-2 px-4 rounded bg-green-500 text-white"
                >
                  Add Question
                </button>
              )}
            </div>

            <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Update"}
                type={"create"}
                handleClick={handleSubmit}
              />
              <EtBtnFormBasic
                name={"Clear"}
                type={"clear"}
                handleClick={handleClear}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
export default EtUpdateSection