import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const userImprintCapabilitiesAdvisorysAdapter = createEntityAdapter({})

const initialState = userImprintCapabilitiesAdvisorysAdapter.getInitialState()

export const userImprintCapabilitiesAdvisorysApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserImprintCapabilitiesAdvisorys: builder.query({
            query: () => ({
                url: '/userImprintCapabilitiesAdvisorys/getUserImprintCapabilitiesAdvisorys',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserImprintCapabilitiesAdvisorys = responseData.map(userImprintCapabilitiesAdvisory => {
                    userImprintCapabilitiesAdvisory.id = userImprintCapabilitiesAdvisory._id
                    return userImprintCapabilitiesAdvisory
                })
                return userImprintCapabilitiesAdvisorysAdapter.setAll(initialState, loadedUserImprintCapabilitiesAdvisorys)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserImprintCapabilitiesAdvisory', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserImprintCapabilitiesAdvisory', id}))
                    ]
                } else {
                    return [ { type: 'UserImprintCapabilitiesAdvisory', id: 'LIST'}]
                }
            },
        }),
        createNewUserImprintCapabilitiesAdvisory: builder.mutation({
            query: initialUserImprintCapabilitiesAdvisory => ({
                url: '/userImprintCapabilitiesAdvisorys/createNewUserImprintCapabilitiesAdvisory',
                method: 'POST',
                body: {...initialUserImprintCapabilitiesAdvisory,}
            }),
            invalidatesTags: [
                { type: 'UserImprintCapabilitiesAdvisory', id: "LIST" }
            ] 
        }),
        updateUserImprintCapabilitiesAdvisory: builder.mutation({
            query: initialUserImprintCapabilitiesAdvisory => ({
                url: '/userImprintCapabilitiesAdvisorys/updateUserImprintCapabilitiesAdvisory',
                method: 'PATCH',
                body: {...initialUserImprintCapabilitiesAdvisory,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserImprintCapabilitiesAdvisory', id: arg.id },
                { type: 'UserImprintCapabilitiesAdvisory', id: 'LIST' }
            ],
        }),
        deleteUserImprintCapabilitiesAdvisory: builder.mutation({
            query: ({id}) => ({
                url: '/userImprintCapabilitiesAdvisorys/deleteUserImprintCapabilitiesAdvisory',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserImprintCapabilitiesAdvisory', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserImprintCapabilitiesAdvisorysQuery,
    useCreateNewUserImprintCapabilitiesAdvisoryMutation,
    useUpdateUserImprintCapabilitiesAdvisoryMutation,
    useDeleteUserImprintCapabilitiesAdvisoryMutation,
} = userImprintCapabilitiesAdvisorysApiSlice

// returns the query result object
export const selectUserImprintCapabilitiesAdvisoryResult = userImprintCapabilitiesAdvisorysApiSlice.endpoints.getUserImprintCapabilitiesAdvisorys.select()

// create memoized selector
const selectUserImprintCapabilitiesAdvisorysData = createSelector(
    selectUserImprintCapabilitiesAdvisoryResult,
    userImprintCapabilitiesAdvisoryResult => userImprintCapabilitiesAdvisoryResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserImprintCapabilitiesAdvisorys,
    selectById: selectUserImprintCapabilitiesAdvisoryById,
    selectIds: selectUserImprintCapabilitiesAdvisoryIds,
} = userImprintCapabilitiesAdvisorysAdapter.getSelectors(state => selectUserImprintCapabilitiesAdvisorysData(state) ?? initialState)