
    import React from 'react';
    
    const EtWabCreationIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m2012.7 2874.5h-496.36c-32.727 0-54.547 21.816-54.547 54.547 0 32.727 21.816 54.547 54.547 54.547h496.36c32.727 0 54.547-21.816 54.547-54.547s-21.82-54.547-54.547-54.547z" fillRule="evenodd"/>
 <path d="m2012.7 3060h-496.36c-32.727 0-54.547 21.816-54.547 54.547 0 32.727 21.816 54.547 54.547 54.547h496.36c32.727 0 54.547-21.816 54.547-54.547 0-27.273-21.82-54.547-54.547-54.547z" fillRule="evenodd"/>
 <path d="m1947.3 3250.9h-360c-32.727 0-54.547 21.816-54.547 54.547 0 32.727 21.816 54.547 54.547 54.547h360c32.727 0 54.547-21.816 54.547-54.547-0.003906-32.73-27.273-54.547-54.547-54.547z" fillRule="evenodd"/>
 <path d="m1870.9 3436.4h-207.27c-32.727 0-54.547 21.816-54.547 54.547 0 32.727 21.816 54.547 54.547 54.547h207.27c32.727 0 54.547-21.816 54.547-54.547-0.003906-32.727-27.273-54.547-54.547-54.547z" fillRule="evenodd"/>
 <path d="m2094.5 2323.6c-27.273-16.363-60-10.91-76.363 16.363-87.273 136.36-120 234.55-130.91 398.18 0 32.727 21.816 54.547 49.09 60h5.4531c27.273 0 54.547-21.816 54.547-49.09 5.4531-141.82 32.727-223.64 114.55-343.64 16.363-32.73 5.4531-65.457-16.363-81.82z" fillRule="evenodd"/>
 <path d="m1587.3 2792.7s5.4531 0 0 0c32.727 0 54.547-27.273 54.547-60-10.91-163.64-43.637-261.82-130.91-398.18-16.363-27.273-49.09-32.727-76.363-16.363-27.273 16.363-32.727 49.09-16.363 76.363 76.363 120 103.64 201.82 114.55 343.64 5.4531 32.73 27.27 54.547 54.543 54.547z" fillRule="evenodd"/>
 <path d="m3087.3 1341.8h-180c-32.727 0-54.547 21.816-54.547 54.547 0 32.727 21.816 54.547 54.547 54.547h180c32.727 0 54.547-21.816 54.547-54.547-0.003906-32.727-21.82-54.547-54.547-54.547z" fillRule="evenodd"/>
 <path d="m687.27 1341.8h-174.55c-32.727 0-54.547 21.816-54.547 54.547 0 32.727 21.816 54.547 54.547 54.547h180c32.727 0 54.547-21.816 54.547-54.547 0-32.727-27.273-54.547-60-54.547z" fillRule="evenodd"/>
 <path d="m2623.6 2143.6c-21.816-21.816-54.547-21.816-76.363 0-21.816 21.816-21.816 54.547 0 76.363l125.45 125.45c21.816 21.816 54.547 21.816 76.363 0s21.816-54.547 0-76.363z" fillRule="evenodd"/>
 <path d="m976.36 649.09c21.816 21.816 54.547 21.816 76.363 0 21.816-21.816 21.816-54.547 0-76.363l-125.45-125.45c-21.816-21.816-54.547-21.816-76.363 0-21.816 21.816-21.816 54.547 0 76.363z" fillRule="evenodd"/>
 <path d="m1800 343.64c32.727 0 54.547-21.816 54.547-54.547v-180c0-32.727-21.816-54.547-54.547-54.547-32.727 0-54.547 21.816-54.547 54.547v180c0 27.273 21.82 54.547 54.547 54.547z" fillRule="evenodd"/>
 <path d="m976.36 2143.6-125.45 125.45c-21.816 21.816-21.816 54.547 0 76.363s54.547 21.816 76.363 0l125.45-125.45c21.816-21.816 21.816-54.547 0-76.363-21.816-21.82-54.543-21.82-76.363 0z" fillRule="evenodd"/>
 <path d="m2623.6 649.09 125.45-125.45c21.816-21.816 21.816-54.547 0-76.363-21.816-21.816-54.547-21.816-76.363 0l-125.45 125.45c-21.816 21.816-21.816 54.547 0 76.363 21.816 21.82 54.543 21.82 76.363 0z" fillRule="evenodd"/>
 <path d="m2700 1189.1c-65.453 65.453-147.27 114.55-234.55 141.82-27.273 10.91-60-10.91-65.453-38.184-10.91-27.273 10.91-60 38.184-65.453 81.816-21.816 152.73-70.91 207.27-130.91-5.4531-98.184-49.09-180-130.91-240-81.816 130.91-240 180-376.36 120-27.273-10.91-38.184-43.637-27.273-70.91 10.91-27.273 43.637-38.184 70.91-27.273 92.727 38.184 201.82 5.4531 245.45-92.727-27.273-147.27-152.73-234.55-294.55-234.55-147.27 0-300 103.64-300 300v1134.5c0 163.64 136.36 300 300 300 141.82 0 261.82-103.64 294.55-223.64-49.09-65.453-130.91-98.184-212.73-70.91-27.273 10.91-60-5.4531-70.91-32.727-10.91-27.273 5.4531-60 32.727-70.91 120-38.184 250.91 5.4531 327.27 98.184 81.816-49.09 125.45-125.45 136.36-223.64-38.184-54.547-87.273-98.184-141.82-130.91-27.273-16.363-38.184-49.09-21.816-76.363 16.363-27.273 49.09-38.184 76.363-21.816 54.547 32.727 103.64 70.91 141.82 120 147.27-125.45 141.82-349.09 5.4531-463.63z" fillRule="evenodd"/>
 <path d="m1767.3 1980v-1134.5c0-196.36-152.73-300-300-300-136.36 0-261.82 81.816-294.55 234.55 49.09 92.727 158.18 130.91 245.45 92.727 27.273-10.91 60 0 70.91 27.273s0 60-27.273 70.91c-136.36 60-294.55 10.91-376.36-120-87.273 54.547-130.91 141.82-130.91 240 54.547 60 125.45 109.09 207.27 130.91 27.273 10.91 43.637 38.184 38.184 65.453-10.91 27.273-38.184 43.637-65.453 38.184-87.273-27.273-169.09-76.363-234.55-141.82-136.36 114.55-136.36 343.64 10.91 458.18 38.184-49.09 87.273-87.273 141.82-120 27.273-16.363 60-5.4531 76.363 21.816 16.363 27.273 5.4531 60-21.816 76.363-60 32.727-109.09 76.363-141.82 130.91 10.91 98.184 60 174.55 136.36 223.64 76.363-92.727 207.27-136.36 327.27-98.184 27.273 10.91 43.637 38.184 32.727 70.91-10.91 27.273-38.184 43.637-70.91 32.727-81.816-27.273-169.09 5.4531-212.73 70.91 32.727 120 152.73 223.64 294.55 223.64 158.18 5.4492 294.54-130.91 294.54-294.55z" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtWabCreationIcon1SVGComp;
    