import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const sponsorOrgTrailSessionPadsAdapter = createEntityAdapter({})

const initialState = sponsorOrgTrailSessionPadsAdapter.getInitialState()

export const sponsorOrgTrailSessionPadsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSponsorOrgTrailSessionPads: builder.query({
            query: () => ({
                url: '/sponsorOrgTrailSessionPads/getSponsorOrgTrailSessionPads',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedSponsorOrgTrailSessionPads = responseData.map(sponsorOrgTrailSessionPad => {
                    sponsorOrgTrailSessionPad.id = sponsorOrgTrailSessionPad._id
                    return sponsorOrgTrailSessionPad
                })
                return sponsorOrgTrailSessionPadsAdapter.setAll(initialState, loadedSponsorOrgTrailSessionPads)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'SponsorOrgTrailSessionPad', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'SponsorOrgTrailSessionPad', id}))
                    ]
                } else {
                    return [ { type: 'SponsorOrgTrailSessionPad', id: 'LIST'}]
                }
            },
        }),
        createNewSponsorOrgTrailSessionPad: builder.mutation({
            query: initialSponsorOrgTrailSessionPad => ({
                url: '/sponsorOrgTrailSessionPads/createNewSponsorOrgTrailSessionPad',
                method: 'POST',
                body: {...initialSponsorOrgTrailSessionPad,}
            }),
            invalidatesTags: [
                { type: 'SponsorOrgTrailSessionPad', id: "LIST" }
            ] 
        }),
        updateSponsorOrgTrailSessionPad: builder.mutation({
            query: initialSponsorOrgTrailSessionPad => ({
                url: '/sponsorOrgTrailSessionPads/updateSponsorOrgTrailSessionPad',
                method: 'PATCH',
                body: {...initialSponsorOrgTrailSessionPad,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'SponsorOrgTrailSessionPad', id: arg.id },
                { type: 'SponsorOrgTrailSessionPad', id: 'LIST' }
            ],
        }),
        deleteSponsorOrgTrailSessionPad: builder.mutation({
            query: ({id}) => ({
                url: '/sponsorOrgTrailSessionPads/deleteSponsorOrgTrailSessionPad',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'SponsorOrgTrailSessionPad', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetSponsorOrgTrailSessionPadsQuery,
    useCreateNewSponsorOrgTrailSessionPadMutation,
    useUpdateSponsorOrgTrailSessionPadMutation,
    useDeleteSponsorOrgTrailSessionPadMutation,
} = sponsorOrgTrailSessionPadsApiSlice

// returns the query result object
export const selectSponsorOrgTrailSessionPadResult = sponsorOrgTrailSessionPadsApiSlice.endpoints.getSponsorOrgTrailSessionPads.select()

// create memoized selector
const selectSponsorOrgTrailSessionPadsData = createSelector(
    selectSponsorOrgTrailSessionPadResult,
    sponsorOrgTrailSessionPadResult => sponsorOrgTrailSessionPadResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllSponsorOrgTrailSessionPads,
    selectById: selectSponsorOrgTrailSessionPadById,
    selectIds: selectSponsorOrgTrailSessionPadIds,
} = sponsorOrgTrailSessionPadsAdapter.getSelectors(state => selectSponsorOrgTrailSessionPadsData(state) ?? initialState)