import React, { useState, useEffect } from 'react'
import useTitle from "../../../hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
    PencilSquareIcon,
  } from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "../../../utils/EtContentEditorLight2"
import EtBtnFormBasic from "../../../components/common/buttons/EtBtnFormBasic"
import { useNavigate } from "react-router"

import { useCreateNewUserPlanTeamMutation } from '@/features/userPlans/userPlanTeamsApiSlice'
import { useGetUserMissionTeamsQuery } from '@/features/userMissions/userMissionTeamsApiSlice'
import { useGetActionTypesQuery } from "@/features/actionTypes/actionTypesApiSlice"
import { useGetTrailsQuery } from "@/features/trails/trailsApiSlice"
import { useGetSessionsQuery } from "@/features/sessions/sessionsApiSlice"

const EtCreateUserImprintPlanTeamComp = ({
    userId, 
    userOrgId, 
    triggeringUrl, 
    onClose
  }) => {

  // First Created - 16 Jan 2025
  // Author - Atul Pandey
  // Inherited from the EtCreateUserImprintCreationComp component
  

  // 0 - Set Page title & variables
  useTitle('User Imprint Legacy - PlanTeam - Create')

  // 1 - set page navigations
  const navigate = useNavigate()
  
  // 2a - set the mutation to create the new user imprint hiddenMagic data
  const [createNewUserPlanTeam, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewUserPlanTeamMutation()

  // 2b - get the data for missions and action types
  const { data: missionsData } = useGetUserMissionTeamsQuery()
  const { data: trailsData } = useGetTrailsQuery()
  const { data: sessionsData } = useGetSessionsQuery()
  const { data: actionTypesData } = useGetActionTypesQuery()
  
  const extractEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id])
  };

  const extractFilteredEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id])
    ?.filter(
        (item) => item.userId?._id === userId && item.userOrgId._id === userOrgId
        );
  };

  const missions = extractFilteredEntitiesFromAdapter(missionsData)
  const trails = extractEntitiesFromAdapter(trailsData)
  const sessions = extractEntitiesFromAdapter(sessionsData)
  const actionTypes = extractEntitiesFromAdapter(actionTypesData)

  // 16 Jan 2025 - added to replace hard coded object ids
  const trailId = trails?.find(item => item.trailName === 'MyMissions')?._id;
  const sessionId = sessions?.find(item => item.sessionName === 'Plan a Mission')?._id;
  const actionTypeId = actionTypes?.find(item => item.actionTypeName === 'Mission')?._id;

  
  // console.log('missions', missions)
  // console.log('actionTypes', actionTypes)
  // console.log('actionType', actionTypeId)
  // console.log('trail', trailId)
  // console.log('session', sessionId)
  

  // 3 - Initialize Form Data State
    const [formData, setFormData] = useState({
      missionId: null,
      planName: "",
      planDesc: "",
      planDate: "",
      newActions: [], // Reset newActions
      planNotes: [],
      pic: "",
      active: true, // Default active state
    });
  
    // 4 - State variables for the editor
    const [editorKey, setEditorKey] = useState('planUserTeamDesc-' + Date.now());
    const [editorValue, setEditorValue] = useState('');
    const [collapsedSections, setCollapsedSections] = useState({});
  
    // 5a - Convert Image to Base64
    const convertPicToBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
  
    // 5b - Handle Form Changes
    const handleChange = (e) => {
      const { name, value, files } = e.target;
      if (name === "pic") {
        setFormData((prevState) => ({ ...prevState, pic: files[0] }));
      } else {
        setFormData((prevState) => ({ ...prevState, [name]: value }));
      }
    };
    
    // 5c - Toggle show / collapse each section 
    const toggleSection = (key) => {
      setCollapsedSections((prev) => ({
        ...prev,
        [key]: !prev[key],
      }));
    };  

    // 5d - Function to add a new action to `newActions`
    const handleAddAction = () => {
      setFormData((prevState) => ({
        ...prevState,
        newActions: [
          ...(prevState.newActions || []),
          {
            userActionName: "",
            userActionDesc: "",
            completeBy: "",
            actionTargetStakeholders: [{ stakeholderTypeName: "Team" }],
          },
        ],
      }));
    };
    
    // 5e - Function to remove a new action to `newActions`
    const handleRemoveAction = (index) => {
      setFormData((prevState) => ({
        ...prevState,
        newActions: prevState.newActions.filter((_, idx) => idx !== index),
      }));
      setCollapsedSections((prev) => {
        const updatedSections = { ...prev };
        delete updatedSections[`action-${index}`]; // Remove the collapsed section state for the action
        return updatedSections;
      });
    };

    // 5f - Function to handle action change
    const handleActionChange = (index, fieldName, value) => {
      setFormData((prevState) => ({
        ...prevState,
        newActions: prevState.newActions.map((action, idx) =>
          idx === index ? { ...action, [fieldName]: value } : action
        ),
      }));
    };
    
    // 6 - Handle form subplan
    const handleSubmit = async (e) => {
      e.preventDefault();
    
      try {
        const picBase64 = formData.pic
          ? await convertPicToBase64(formData.pic)
          : undefined;
    
        const validNotes = formData.planNotes.filter(
          (note) => note.planNoteText.trim()
        );
    
        // const validActions = (formData.newActions || []).filter(
        //   (action) => action.userActionName && action.completeBy
        // );
        const validActions = (formData.newActions || [])
        .filter((action) => action.userActionName && action.completeBy)
        .map((action) => ({
          ...action,
          actionTypeId: actionTypeId,
        }));

    
        const payload = {
          userId,
          userOrgId,
          missionId: formData.missionId || null,
          trailId: trailId,
          sessionId: sessionId,
          newActions: validActions, // Include newActions array
          planName: formData.planName.trim() || undefined,
          planDesc: formData.planDesc.trim() || undefined,
          planDate: formData.planDate || undefined,
          planNotes: validNotes.map((note) => ({
            ...note,
            planNoteText: note.planNoteText.trim(),
          })),
          pic: picBase64 || undefined,
          active: formData.active,
        };
    
        await createNewUserPlanTeam(payload).unwrap();
    
        if (onClose) {
          onClose();
        } else {
          navigate(triggeringUrl || "/userMagicHome");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    };
    

    // 7 - Reset Form on Successful Subplan
      useEffect(() => {
        if (isSuccess) {
          setFormData({
            missionId: null,
            planName: "",
            planDesc: "",
            planDate: "",
            newActions: [], // Reset newActions
            planNotes: [],
            pic: "",
            active: true, // Default active state
          });
    
          if (onClose) {
            onClose();
          } else {
            navigate(triggeringUrl || "/userMagicHome");
          }
        }
      }, [isSuccess, navigate]);
    
    // 8 - Handle Clear
    const handleClear = () => {
    setFormData({
      missionId: null,
      planName: "",
      planDesc: "",
      planDate: "",
      newActions: [], // Reset newActions
      planNotes: [],
      pic: "",
      active: true, // Default active state
    });
    };

    // 9 - Handle Cancel
    const handleCancel = () => {
    if (onClose) {
        onClose();
    } else {
        navigate(triggeringUrl || "/userMagicHome");
    }
    };


  return (
    <div className="mx-auto w-full m-1 p-1 bg-white rounded shadow-md shadow-black">
      {isLoading && <PulseLoader color={'#ea580c'} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">{error?.data?.message}</p>
      )}

      <p className="p-1 m-1 font-bold font-poppins text-xl">
        Create User Imprint Plan - Team
      </p>

      <form onSubmit={handleSubmit} className="m-1 p-1 text-xs font-poppins">
        
        {/* 1. Team Plan -  Name */}
        <div id="planName" className="bg-gray-100 rounded p-1 m-1">
          <label htmlFor="planName" className="block text-gray-600 text-sm font-bold mb-1">
            Team Plan - Name
          </label>
          <input
            type="text"
            name="planName"
            value={formData.planName}
            onChange={handleChange}
            placeholder="Enter planTeam name"
            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
          />
        </div>

        {/* 2. Team Plan -  Desc */}
        <div id="planDesc" className="bg-gray-100 rounded p-1 m-1">
          <label
            htmlFor="planDesc"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
            Team Plan -  Description
          </label>
          <EtContentEditorLight2
            value={formData.planDesc || ""}
            handleChange={(content) => {
              setFormData((prevState) => ({
                ...prevState,
                planDesc: content,
              }));
            }}
            placeholderText="Write your planTeam description here..."
            editorKey={editorKey}
          />
        </div>

        {/* 3. Team Plan Date */}
        <div id="planDate" className="bg-gray-100 rounded p-1 m-1 w-full">
          <label
            htmlFor="planDate"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
              Plan Date
          </label>
          <input
            type="date"
            name="planDate"
            value={formData.planDate}
            onChange={handleChange}
            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
          />
        </div>

        {/* 4. Mission Selection */}
        <div id="missionId" className="bg-gray-100 rounded p-1 m-1">
          <label htmlFor="missionId" className="block text-gray-600 text-sm font-bold mb-1">
            Select Mission
          </label>
          <select
            name="missionId"
            value={formData.missionId || ""}
            onChange={handleChange}
            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
          >
            <option value="">-- Select Mission --</option>
            {missions?.map((mission) => (
              <option key={mission._id} value={mission._id}>
                {mission.missionName}
              </option>
            ))}
          </select>
        </div>

        {/* 5. New Actions Section */}
        <div id="newActions" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <p className="font-bold text-sm">New Actions</p>
            <button
              type="button"
              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
              onClick={handleAddAction}
            >
              <PlusIcon className="h-4 w-4 inline" />
            </button>
          </div>

          {formData.newActions?.map((action, actionIndex) => (
            <div key={actionIndex} className="p-2 border rounded mb-2 bg-white">
              <div className="flex justify-between items-center mb-2">
                {/* <p className="font-bold text-xs">Action {actionIndex + 1}</p> */}
                <div className="flex justify-start items-center m-1 p-1">
                <button
                  type="button"
                  className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                  onClick={() => toggleSection(`action-${actionIndex}`)}
                >
                  {collapsedSections[`action-${actionIndex}`] ? (
                    <PlusIcon className="h-4 w-4 text-orange-600" />
                  ) : (
                    <MinusIcon className="h-4 w-4 text-orange-600" />
                  )}
                </button>
                <label className="block text-gray-600 text-sm font-bold mb-1">
                  Action {actionIndex + 1}
                </label>
              </div>

                <div className="flex items-center space-x-2">
                  <button
                    type="button"
                    className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                    onClick={() => handleRemoveAction(actionIndex)}
                  >
                    <MinusIcon className="h-4 w-4 inline" />
                  </button>
                </div>
              </div>

              {!collapsedSections[`action-${actionIndex}`] && (
                <>
                  {/* Action Name */}
                  <div id={`actionName-${actionIndex}`} className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`userActionName-${actionIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Action Name
                    </label>
                    <input
                      type="text"
                      name={`userActionName-${actionIndex}`}
                      value={action.userActionName || ""}
                      onChange={(e) => handleActionChange(actionIndex, "userActionName", e.target.value)}
                      placeholder="Enter action name"
                      className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                    />
                  </div>

                  {/* Action Description */}
                  <div id={`actionDesc-${actionIndex}`} className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`userActionDesc-${actionIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Action Description
                    </label>
                    <EtContentEditorLight2
                      value={action.userActionDesc || ""}
                      handleChange={(content) => handleActionChange(actionIndex, "userActionDesc", content)}
                      placeholderText="Write your action description here..."
                      editorKey={`userActionDesc-${actionIndex}`}
                    />
                  </div>

                  {/* Complete By */}
                  <div id={`completeBy-${actionIndex}`} className="bg-gray-100 rounded p-1 m-1 w-full">
                    <label
                      htmlFor={`completeBy-${actionIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Complete By
                    </label>
                    <input
                      type="date"
                      name={`completeBy-${actionIndex}`}
                      value={action.completeBy || ""}
                      onChange={(e) => handleActionChange(actionIndex, "completeBy", e.target.value)}
                      className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                    />
                  </div>
                </>
              )}
            </div>
          ))}
        </div>

        {/* 8. Team Plan -  Notes */}
        <div id="planNotes" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <p className="font-bold text-sm">Team Plan Notes</p>
            <button
              type="button"
              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
              onClick={() => {
                setFormData((prevState) => ({
                  ...prevState,
                  planNotes: [
                    ...prevState.planNotes,
                    {
                      planNoteText: "",
                      planNoteDate: new Date().toISOString(),
                    },
                  ],
                }));
              }}
            >
              <PlusIcon className="h-4 w-4 inline" />
            </button>
          </div>

          {formData.planNotes.map((note, index) => (
            <div key={index} className="p-2 border rounded mb-2 bg-white">
              <div className="flex justify-between items-center mb-2">
                <p className="font-bold text-xs">Note {index + 1}</p>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) => ({
                      ...prevState,
                      planNotes: prevState.planNotes.filter(
                        (_, idx) => idx !== index
                      ),
                    }));
                  }}
                >
                  <MinusIcon className="h-4 w-4 inline" />
                </button>
              </div>

              {/* Note Text */}
              <div className="bg-gray-100 rounded p-1 m-1">
                <label
                  htmlFor={`planTeamNoteText-${index}`}
                  className="block text-gray-600 text-sm font-bold mb-1"
                >
                  Note Text
                </label>
                <EtContentEditorLight2
                  value={note.planNoteText || ""}
                  handleChange={(content) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      planNotes: prevState.planNotes.map(
                        (n, idx) =>
                          idx === index
                            ? { ...n, planNoteText: content }
                            : n
                      ),
                    }));
                  }}
                  placeholderText="Write your note here..."
                  editorKey={`planTeamNoteText-${index}`}
                />
              </div>
            </div>
          ))}
        </div>
        
        {/* 9. Form Submissions Bar */}
        <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic 
              name={'Create'} 
              type={'create'} 
              handleClick={handleSubmit} 
              />
            <EtBtnFormBasic
              name={'Clear'}
              type={'clear'}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={'Cancel'}
              type={'cancel'}
              handleClick={handleCancel}
            />
        </div>

      </form>


    </div>
  )
}

export default EtCreateUserImprintPlanTeamComp