import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userAccomplishmentWeeklysAdapter = createEntityAdapter({})

const initialState = userAccomplishmentWeeklysAdapter.getInitialState()

export const userAccomplishmentWeeklysApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserAccomplishmentWeeklys: builder.query({
            query: () => ({
                url: '/userAccomplishmentWeeklys/getUserAccomplishmentWeeklys',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserAccomplishmentWeeklys = responseData.map(userAccomplishmentWeekly => {
                    userAccomplishmentWeekly.id = userAccomplishmentWeekly._id
                    return userAccomplishmentWeekly
                })
                return userAccomplishmentWeeklysAdapter.setAll(initialState, loadedUserAccomplishmentWeeklys)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserAccomplishmentWeekly', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserAccomplishmentWeekly', id}))
                    ]
                } else {
                    return [ { type: 'UserAccomplishmentWeekly', id: 'LIST'}]
                }
            },
        }),
        createNewUserAccomplishmentWeekly: builder.mutation({
            query: initialUserAccomplishmentWeekly => ({
                url: '/userAccomplishmentWeeklys/createNewUserAccomplishmentWeekly',
                method: 'POST',
                body: {...initialUserAccomplishmentWeekly,}
            }),
            invalidatesTags: [
                { type: 'UserAccomplishmentWeekly', id: "LIST" }
            ] 
        }),
        createThisWeekNewUserAccomplishmentWeekly: builder.mutation({       // 21 Sep 2024
            query: initialUserAccomplishmentWeekly => ({
                url: '/userAccomplishmentWeeklys/createThisWeekNewUserAccomplishmentWeekly',
                method: 'POST',
                body: {...initialUserAccomplishmentWeekly,}
            }),
            invalidatesTags: [
                { type: 'UserAccomplishmentWeekly', id: "LIST" }
            ] 
        }),
        updateUserAccomplishmentWeekly: builder.mutation({
            query: initialUserAccomplishmentWeekly => ({
                url: '/userAccomplishmentWeeklys/updateUserAccomplishmentWeekly',
                method: 'PATCH',
                body: {...initialUserAccomplishmentWeekly,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserAccomplishmentWeekly', id: arg.id },
                { type: 'UserAccomplishmentWeekly', id: 'LIST' }
            ],
        }),
        deleteUserAccomplishmentWeekly: builder.mutation({
            query: ({id}) => ({
                url: '/userAccomplishmentWeeklys/deleteUserAccomplishmentWeekly',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserAccomplishmentWeekly', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserAccomplishmentWeeklysQuery,
    useCreateNewUserAccomplishmentWeeklyMutation,
    useCreateThisWeekNewUserAccomplishmentWeeklyMutation, // 21 Sep 2024
    useUpdateUserAccomplishmentWeeklyMutation,
    useDeleteUserAccomplishmentWeeklyMutation,
} = userAccomplishmentWeeklysApiSlice

// returns the query result object
export const selectUserAccomplishmentWeeklyResult = userAccomplishmentWeeklysApiSlice.endpoints.getUserAccomplishmentWeeklys.select()

// create memoized selector
const selectUserAccomplishmentWeeklysData = createSelector(
    selectUserAccomplishmentWeeklyResult,
    userAccomplishmentWeeklyResult => userAccomplishmentWeeklyResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserAccomplishmentWeeklys,
    selectById: selectUserAccomplishmentWeeklyById,
    selectIds: selectUserAccomplishmentWeeklyIds,
} = userAccomplishmentWeeklysAdapter.getSelectors(state => selectUserAccomplishmentWeeklysData(state) ?? initialState)