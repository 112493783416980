import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import {
  PlusIcon,
  MinusIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline"
import { ClockIcon, BuildingOffice2Icon } from "@heroicons/react/24/solid"
import { useGetUserImprintRolesQuery } from "../userImprintRolesApiSlice" // 23 Nov 2024

import EtModalCreateUserImprintRole from "@/components/common/modal/EtModalCreateUserImprintRole"
import EtModalUpdateUserImprintRole from "@/components/common/modal/EtModalUpdateUserImprintRole"

// 27 Nov 2024
import EtRoleTrailIcon1SVGComp from "@/assets/img/icons/svgComp/EtRoleTrailIcon1SVGComp"
import EtRoleIcon2SVGComp from "@/assets/img/icons/svgComp/EtRoleIcon2SVGComp"
import EtCapabilitiesSelfNotesIcon1SVGComp from "@/assets/img/icons/svgComp/EtCapabilitiesSelfNotesIcon1SVGComp"
import EtRoleSelfNotesIcon1SVGComp from "@/assets/img/icons/svgComp/EtRoleSelfNotesIcon1SVGComp"
import EtCapabilitiesAdvisoryIcon1SVGComp from "@/assets/img/icons/svgComp/EtCapabilitiesAdvisoryIcon1SVGComp"
import EtCapabilitiesDomainIcon1SVGComp from "@/assets/img/icons/svgComp/EtCapabilitiesDomainIcon1SVGComp"
import EtCapabilitiesFunctionIcon1SVGComp from "@/assets/img/icons/svgComp/EtCapabilitiesFunctionIcon1SVGComp"
import EtCapabilitiesIndustryIcon1SVGComp from "@/assets/img/icons/svgComp/EtCapabilitiesIndustryIcon1SVGComp"
import EtCapabilitiesSalesIcon1SVGComp from "@/assets/img/icons/svgComp/EtCapabilitiesSalesIcon1SVGComp"
import EtCapabilitiesServiceIcon1SVGComp from "@/assets/img/icons/svgComp/EtCapabilitiesServiceIcon1SVGComp"
import EtCapabilitiesTechAreaIcon1SVGComp from "@/assets/img/icons/svgComp/EtCapabilitiesTechAreaIcon1SVGComp"
import EtRoleStakeholdersClientsIcon1SVGComp from "@/assets/img/icons/svgComp/EtRoleStakeholdersClientsIcon1SVGComp"
import EtRoleStakeholdersOrgIcon1SVGComp from "@/assets/img/icons/svgComp/EtRoleStakeholdersOrgIcon1SVGComp"
import EtRoleStakeholdersOrgTeamIcon1SVGComp from "@/assets/img/icons/svgComp/EtRoleStakeholdersOrgTeamIcon1SVGComp"
import EtRoleStakeholdersPartnersIcon1SVGComp from "@/assets/img/icons/svgComp/EtRoleStakeholdersPartnersIcon1SVGComp"
import EtRoleSynopsisIcon1SVGComp from "@/assets/img/icons/svgComp/EtRoleSynopsisIcon1SVGComp"
import EtRoleStakeholdersIcon1SVGComp from "@/assets/img/icons/svgComp/EtRoleStakeholdersIcon1SVGComp"
import EtRoleFocusIcon3SVGComp from "@/assets/img/icons/svgComp/EtRoleFocusIcon3SVGComp"
import EtRoleCIsIcon2SVGComp from "@/assets/img/icons/svgComp/EtRoleCIsIcon2SVGComp"
import EtRoleContributionsIcon3SVGComp from "@/assets/img/icons/svgComp/EtRoleContributionsIcon3SVGComp"
import EtRoleImpactsIcon2SVGComp from "@/assets/img/icons/svgComp/EtRoleImpactsIcon2SVGComp"

import {
  formatDateToMonthYear,
  formatDateToYear,
  formatDateTime,
  formatShortDate,
  calculateYearsAndMonths,
} from "@/utils/formatDate"
import { getTimeSince } from "@/utils/EtGetTimeSince"

const EtImprintRole = ({ userId, userInfo }) => {
  // First Created - 23 Nov 2024
  // Author - Atul Pandey
  // Inherited and refined the main code from ImprintCapabilitiesDomain
  // Updated - 24 Nov 2024 - put in the main display function and JSX after getting the created data
  // Updated - 26 Nov 2024 - further refinements to the logic
  // Updated - 27 Nov 2024 - refinements to the JSX display - roles - synopsis, focus areas, stakeholders, self notes
  // Updated - 28 Nov 2024 - refinements to the JSX display - roles - contributions & impacts
  // Updated - 29 Nov 2024 - componentized the contribution item

  // 1a - State for Modal -- 23 Nov 2024
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [createTriggeringUrl, setCreateTriggeringUrl] = useState(null)
  const [updateTriggeringUrl, setUpdateTriggeringUrl] = useState(null)

  // 1b - State for JSX Display - 27 Nov 2024
  const [showRoleTrail, setShowRoleTrail] = useState(false)
  const [showRoleDetails, setShowRoleDetails] = useState({})
  const [showRoleSelfNotes, setShowRoleSelfNotes] = useState(false)
  const [showRoleSelfNoteDetails, setShowRoleSelfNoteDetails] = useState({})
  const [showRoleSynopsis, setShowRoleSynopsis] = useState({})
  const [showRoleFocusAreas, setShowRoleFocusAreas] = useState({})
  const [showRoleStakeholders, setShowRoleStakeholders] = useState({})
  const [showRoleCIs, setShowRoleCIs] = useState({}) // 28 Nov 2024
  const [showRoleCIDetails, setShowRoleCIDetails] = useState({}) // 28 Nov 2024
  const [showRoleContributionNotes, setShowRoleContributionNotes] = useState({}) // 28 Nov 2024
  const [showRoleImpactNotes, setShowRoleImpactNotes] = useState({}) // 28 Nov 2024

  // 2a - Extract needed data from the incoming props
  const userOrgId = userInfo[0]?.userOrgId._id || null
  // console.log('userOrgId', userOrgId)

  // 2b - Get UserImprintRole Data - 23 Nov 2024
  const { data: userImprintRoleData } = useGetUserImprintRolesQuery()
  const userImprintRoles = userImprintRoleData?.ids
    ?.map((id) => userImprintRoleData?.entities[id])
    ?.filter(
      (item) => item.userId?._id === userId && item.userOrgId._id === userOrgId
    )
  console.log("userImprintRoles", userImprintRoles)

  // 3a - Handle Create User Imprint Role
  const handleCreateUserImprintRole = () => {
    const currentUrl = window.location.pathname
    setIsCreateModalOpen(true)
    setCreateTriggeringUrl(currentUrl)
  }

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false)
    setCreateTriggeringUrl(null)
    // window.location.reload();
  }

  // 3b - Handle Update User Imprint Role
  const handleUpdateUserImprintRole = () => {
    const currentUrl = window.location.pathname
    setIsUpdateModalOpen(true)
    setUpdateTriggeringUrl(currentUrl)
  }

  const handleCloseUpdateModal = () => {
    setIsUpdateModalOpen(false)
    setUpdateTriggeringUrl(null)
    // window.location.reload();
  }

  // 3c - Toggle Show function for show role self note details - 27 Nov 2024
  const handleToggleRoleSelfNoteDetails = (noteId) => {
    setShowRoleSelfNoteDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
  }

  // 3d - Toggle Show function for show role details - 27 Nov 2024
  const handleToggleRoleDetails = (roleId) => {
    setShowRoleDetails((prev) => ({
      ...prev,
      [roleId]: !prev[roleId],
    }))
  }

  // 3e - Toggle Show function for show role synopsis - 27 Nov 2024
  const handleToggleRoleSynopsis = (roleId) => {
    setShowRoleSynopsis((prev) => ({
      ...prev,
      [roleId]: !prev[roleId],
    }))
  }

  // 3f - Toggle Show function for show role focusAreas - 27 Nov 2024
  const handleToggleRoleFocusAreas = (roleId) => {
    setShowRoleFocusAreas((prev) => ({
      ...prev,
      [roleId]: !prev[roleId],
    }))
  }

  // 3g - Toggle Show function for show role stakeholders - 27 Nov 2024
  const handleToggleRoleStakeholders = (roleId) => {
    setShowRoleStakeholders((prev) => ({
      ...prev,
      [roleId]: !prev[roleId],
    }))
  }

  // 3h - Toggle Show function for show role CIs (Contributions & Impacts) - 28 Nov 2024
  const handleToggleRoleCIs = (roleId) => {
    setShowRoleCIs((prev) => ({
      ...prev,
      [roleId]: !prev[roleId],
    }))
  }

  // 3i - Toggle Show function for show role CIDetails (Contributions & Impacts) - 28 Nov 2024
  const handleToggleRoleCIDetails = (roleId) => {
    setShowRoleCIDetails((prev) => ({
      ...prev,
      [roleId]: !prev[roleId],
    }))
  }

  // 3j - Toggle Show function for show role Contribution Notes - 28 Nov 2024
  const handleToggleRoleContributionNotes = (roleId) => {
    setShowRoleContributionNotes((prev) => ({
      ...prev,
      [roleId]: !prev[roleId],
    }))
  }

  // 3k - Toggle Show function for show role Impact Notes - 28 Nov 2024
  const handleToggleRoleImpactNotes = (roleId) => {
    setShowRoleImpactNotes((prev) => ({
      ...prev,
      [roleId]: !prev[roleId],
    }))
  }

  // 4 - SVG Component mapping
  const svgComponentsMap = {
    roleIndustryType: EtCapabilitiesIndustryIcon1SVGComp,
    roleDomainType: EtCapabilitiesDomainIcon1SVGComp,
    roleFunctionType: EtCapabilitiesFunctionIcon1SVGComp,
    roleTechAreaType: EtCapabilitiesTechAreaIcon1SVGComp,
    roleSalesType: EtCapabilitiesSalesIcon1SVGComp,
    roleServiceType: EtCapabilitiesServiceIcon1SVGComp,
    roleAdvisoryType: EtCapabilitiesAdvisoryIcon1SVGComp,
  }

  // 5 - Function to get the appropriate color class based on section score
  const getColorForScore = (score) => {
    if (score >= 0 && score < 0.9) return "bg-etpx-0"
    if (score >= 0.9 && score < 1.9) return "bg-etpx-1"
    if (score >= 1.9 && score < 2.9) return "bg-etpx-2"
    if (score >= 2.9 && score < 3.9) return "bg-etpx-3"
    if (score >= 3.9 && score < 4.9) return "bg-etpx-4"
    return "bg-etpx-5" // For score >= 4.9
  }

  // 6 - create Markup
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
  }

  return (
    <div className="m-1 p-1">
      {userImprintRoles?.length === 0 && (
        <div className="flex justify-between items-center">
          <div>
            <p className="m-1 p-1 text-xs">
              There is no role record you have created yet! Change that by
              clicking the Add button.
            </p>
          </div>
          <div className="flex justify-start items-center">
            <p className="text-xs font-bold mx-1 px-1">Add</p>
            <button
              type="button"
              // onClick={() => navigate("/capabilitiesUserImprint/advisory/create")}
              onClick={handleCreateUserImprintRole}
              className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
            >
              <PlusIcon className="h-3 w-3" />
            </button>
          </div>
          <EtModalCreateUserImprintRole
            isOpen={isCreateModalOpen}
            userId={userId}
            userOrgId={userOrgId}
            triggeringUrl={createTriggeringUrl}
            onClose={handleCloseCreateModal}
          />
        </div>
      )}
      {userImprintRoles?.length > 0 &&
        userImprintRoles?.map((role, roleIndex) => {
          const headline = role?.userImprintRoleHeadlineName || ""
          const overview = role?.userImprintRoleHeadlineDesc || ""
          const imprintRoles = role?.userImprintRoles || []
          const selfNotes = role?.userImprintRoleSelfNotes || []

          return (
            <div key={role._id + roleIndex} className="m-1 p-1">
              <div className="border-b">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-xs font-bold text-orange-600 m-1 p-1">
                      {headline}
                    </p>
                  </div>
                  <div>
                    <button type="button" onClick={handleUpdateUserImprintRole}>
                      <PencilSquareIcon className="h-5 w-5 text-orange-600 m-1 p-1" />
                    </button>
                  </div>
                  <EtModalUpdateUserImprintRole
                    isOpen={isUpdateModalOpen}
                    userId={userId}
                    userOrgId={userOrgId}
                    triggeringUrl={updateTriggeringUrl}
                    onClose={handleCloseUpdateModal}
                  />
                </div>
                <p
                  dangerouslySetInnerHTML={createMarkup(overview)}
                  className="text-xs leading-4 text-gray-600 m-1 p-1"
                ></p>
              </div>
              {/* Section 1 - RoleTrail */}
              <div className=" m-1 p-1 bg-gray-200 rounded ">
                <div className="grid grid-cols-8 justify-start items-center">
                  <div
                    className={`col-span-3 flex justify-start items-center m-1 p-1`}
                  >
                    <div
                      onClick={() => setShowRoleTrail((prev) => !prev)}
                      className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                    >
                      {showRoleTrail ? (
                        <MinusIcon className="h-4 w-4 text-orange-600" />
                      ) : (
                        <PlusIcon className="h-4 w-4 text-orange-600" />
                      )}
                    </div>
                    <div
                      className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer"
                      onClick={() => setShowRoleTrail((prev) => !prev)}
                    >
                      <EtRoleTrailIcon1SVGComp className="h-6 w-6 text-orange-600" />
                    </div>
                    <div>
                      <p className="mx-1 px-1 font-bold text-gray-600">
                        Role Trail
                      </p>
                      <p className="mx-1 px-1 text-gray-400 text-xs italic">
                        what roles have I played thus far?
                      </p>
                    </div>
                  </div>
                </div>

                {showRoleTrail &&
                  imprintRoles?.length > 0 &&
                  imprintRoles
                    ?.slice()
                    .sort(
                      (a, b) =>
                        new Date(b.userRoleStartDate) -
                        new Date(a.userRoleStartDate)
                    )
                    ?.map((imprintRole, imprintRoleIndex) => {
                      const roleName = imprintRole?.userRoleName || ""
                      const roleDesc = imprintRole?.userRoleDesc || ""
                      const orgName = imprintRole?.userRoleOrgName || ""
                      const roleStartDate = imprintRole?.userRoleStartDate || null
                      const roleEndDate =
                        imprintRole?.userRoleEndDate || null
                      const userRoleFocusAreas =
                        imprintRole?.userRoleFocusAreas || []
                      const userRoleStakeholders =
                        imprintRole?.userRoleStakeholders || []
                      const userRoleContributionsAndImpacts =
                        imprintRole?.userRoleContributionsAndImpacts || []
                        const experienceDur = calculateYearsAndMonths(
                            new Date(roleStartDate),
                            new Date(roleEndDate || new Date())
                          );

                      return (
                        <div
                          key={imprintRole._id + imprintRoleIndex}
                          className="m-1 p-1 bg-white rounded"
                        >
                          <div className="grid grid-cols-8 justify-start items-center">
                            <div className={`col-span-3 flex justify-start items-center m-1 p-1`}>
                              <div
                                onClick={() =>
                                  handleToggleRoleDetails(imprintRole._id)
                                }
                                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                              >
                                {showRoleDetails[imprintRole._id] ? (
                                  <MinusIcon className="h-4 w-4 text-orange-600" />
                                ) : (
                                  <PlusIcon className="h-4 w-4 text-orange-600" />
                                )}
                              </div>
                              <div>
                                <p className="text-xl font-bold mx-1 px-1 text-gray-600">
                                  {imprintRoleIndex + 1}.
                                </p>
                              </div>
                              <div
                                className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer"
                                onClick={() =>
                                  handleToggleRoleDetails(imprintRole._id)
                                }
                              >
                                <EtRoleIcon2SVGComp className="h-6 w-6 text-orange-600" />
                              </div>
                              <div>
                                <p className="text-sm font-bold mx-1 px-1 text-gray-600">
                                  {roleName}
                                </p>
                              </div>
                            </div>
                            <div className="col-span-2 flex justify-start items-center m-1 p-1">
                              <div>
                                <BuildingOffice2Icon className="h-4 w-4 text-orange-600" />
                              </div>
                              <div>
                                <p className="text-[10px] text-gray-600 mx-1 px-1">
                                  Org:
                                </p>
                              </div>
                              <div>
                                <p className="text-[10px] text-orange-600 mx-1 px-1">
                                  {orgName}
                                </p>
                              </div>
                            </div>
                            <div className="col-span-2 flex justify-start items-center m-1 p-1">
                              <ClockIcon className="h-4 w-4 text-lime-600" />
                              <p className="text-[10px] mx-1">
                                {roleStartDate ? formatDateToMonthYear(roleStartDate) : "Today"}{" "}
                                -{" "}
                              </p>
                              <p className="text-[10px] mx-1">
                                {roleEndDate ? formatDateToMonthYear(roleEndDate) : "Today"}
                              </p>
                            </div>
                            <div className="col-span-1 flex justify-start items-center m-1 p-1">
                              <div className="flex justify-center items-center aspect-square h-10 rounded bg-gradient-to-r from-black to-black via-gray-800">
                                <p className="text-2xl text-orange-600 font-bold">
                                  {experienceDur.years}
                                </p>
                              </div>
                              <p className="text-gray-400 mx-1 px-1 text-[10px]">
                                Yrs
                              </p>
                            </div>
                          </div>

                          {showRoleDetails[imprintRole._id] && (
                            <div>
                              {/* Role - Synopsis */}
                              <div className="m-1 p-1 border rounded bg-gray-50">
                                <div className="flex justify-start items-center m-1 p-1">
                                  <div
                                    onClick={() =>
                                      handleToggleRoleSynopsis(imprintRole._id)
                                    }
                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                  >
                                    {showRoleSynopsis[imprintRole._id] ? (
                                      <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                      <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                  </div>
                                  <div
                                    className="flex justify-center items-center rounded-full h-6 w-6 bg-white hover:bg-gray-100 cursor-pointer"
                                    onClick={() =>
                                      handleToggleRoleSynopsis(imprintRole._id)
                                    }
                                  >
                                    <EtRoleSynopsisIcon1SVGComp className="h-4 w-4 text-orange-600" />
                                  </div>
                                  <div>
                                    <p className="font-bold m-1 p-1">
                                      Synopsis:
                                    </p>
                                  </div>
                                </div>
                                {showRoleSynopsis[imprintRole._id] && (
                                  <p
                                    dangerouslySetInnerHTML={createMarkup(
                                      roleDesc
                                    )}
                                    className="text-xs m-1 p-1"
                                  ></p>
                                )}
                              </div>

                              {/* Role - Focus Areas */}
                              <div className="m-1 p-1 border rounded bg-gray-50">
                                <div className="flex justify-start items-center m-1 p-1">
                                  <div
                                    onClick={() =>
                                      handleToggleRoleFocusAreas(
                                        imprintRole._id
                                      )
                                    }
                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                  >
                                    {showRoleFocusAreas[imprintRole._id] ? (
                                      <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                      <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                  </div>
                                  <div
                                    className="flex justify-center items-center rounded-full h-6 w-6 bg-white hover:bg-gray-100 cursor-pointer"
                                    onClick={() =>
                                      handleToggleRoleFocusAreas(
                                        imprintRole._id
                                      )
                                    }
                                  >
                                    <EtRoleFocusIcon3SVGComp className="h-4 w-4 text-orange-600" />
                                  </div>
                                  <div>
                                    <p className="font-bold m-1 p-1">
                                      Role Focus Areas
                                    </p>
                                  </div>
                                </div>

                                {showRoleFocusAreas[imprintRole._id] && (
                                  <div className="grid grid-cols-3 justify-between m-1 p-1 gap-1">
                                    {userRoleFocusAreas?.length > 0 &&
                                      (() => {
                                        // Group focus areas outside the map
                                        const groupedFocusAreas =
                                          userRoleFocusAreas.reduce(
                                            (acc, roleFocus) => {
                                              const roleFocusArea =
                                                roleFocus?.roleFocusArea ||
                                                "General" // Default group name
                                              const focusAreaName =
                                                roleFocus?.roleIndustryType
                                                  ?.capabilitiesIndustryTypeName ||
                                                roleFocus?.roleDomainType
                                                  ?.capabilitiesDomainTypeName ||
                                                roleFocus?.roleFunctionType
                                                  ?.capabilitiesFunctionTypeName ||
                                                roleFocus?.roleTechAreaType
                                                  ?.capabilitiesTechAreaTypeName ||
                                                roleFocus?.roleSalesType
                                                  ?.capabilitiesSalesTypeName ||
                                                roleFocus?.roleServiceType
                                                  ?.capabilitiesServiceTypeName ||
                                                roleFocus?.roleAdvisoryType
                                                  ?.capabilitiesAdvisoryTypeName ||
                                                "All" // Default focusAreaName if none exists

                                              const focusAreaPic =
                                                roleFocus?.roleIndustryType
                                                  ?.pic ||
                                                roleFocus?.roleDomainType
                                                  ?.pic ||
                                                roleFocus?.roleFunctionType
                                                  ?.pic ||
                                                roleFocus?.roleTechAreaType
                                                  ?.pic ||
                                                roleFocus?.roleSalesType?.pic ||
                                                roleFocus?.roleServiceType
                                                  ?.pic ||
                                                roleFocus?.roleAdvisoryType
                                                  ?.pic ||
                                                null // Default to null if no pic exists

                                              const roleFocusKey = Object.keys(
                                                svgComponentsMap
                                              )?.find((key) => roleFocus?.[key])
                                              const RoleFocusPicComp =
                                                roleFocusKey
                                                  ? svgComponentsMap[
                                                      roleFocusKey
                                                    ]
                                                  : null

                                              if (!acc[roleFocusArea]) {
                                                acc[roleFocusArea] = {
                                                  items: [],
                                                  Icon: RoleFocusPicComp,
                                                }
                                              }

                                              // Add the focus area details (name and pic) to the array if not already present
                                              if (
                                                !acc[roleFocusArea].items.some(
                                                  (item) =>
                                                    item.name === focusAreaName
                                                )
                                              ) {
                                                acc[roleFocusArea].items.push({
                                                  name: focusAreaName,
                                                  pic: focusAreaPic,
                                                })
                                              }

                                              return acc
                                            },
                                            {}
                                          )

                                        // Render grouped focus areas
                                        return Object.entries(
                                          groupedFocusAreas
                                        )?.map(
                                          ([focusArea, { items, Icon }]) => (
                                            <div
                                              key={focusArea}
                                              className="col-span-1 border rounded bg-gray-100"
                                            >
                                              {/* Focus Area Header */}
                                              <div className="flex justify-start items-center m-1 p-1">
                                                {Icon ? (
                                                  <Icon className="h-6 w-6 text-orange-600" />
                                                ) : (
                                                  <p>
                                                    No SVG component available
                                                  </p>
                                                )}
                                                <p className="font-bold text-gray-800 mx-1 px-1">
                                                  {focusArea}
                                                </p>
                                              </div>

                                              {/* List of Focus Area Names with Pictures */}
                                              <div className="m-1 p-1">
                                                {items?.map((item, idx) => (
                                                  <div
                                                    key={idx}
                                                    className="flex items-center bg-white border rounded m-1 p-1"
                                                  >
                                                    {item.pic ? (
                                                      <img
                                                        src={item.pic}
                                                        alt=""
                                                        className="h-4 w-4"
                                                      />
                                                    ) : (
                                                      <p className="h-4 w-4 mr-2 text-gray-400">
                                                        No Pic
                                                      </p>
                                                    )}
                                                    <p className="text-xs text-gray-600 mx-1 px-1">
                                                      {item.name}
                                                    </p>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          )
                                        )
                                      })()}
                                  </div>
                                )}
                              </div>

                              {/* Role - Stakeholders Served  */}
                              <div className="m-1 p-1 border rounded bg-gray-50">
                                <div className="flex justify-start items-center m-1 p-1">
                                  <div
                                    onClick={() =>
                                      handleToggleRoleStakeholders(
                                        imprintRole._id
                                      )
                                    }
                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                  >
                                    {showRoleStakeholders[imprintRole._id] ? (
                                      <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                      <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                  </div>
                                  <div
                                    className="flex justify-center items-center rounded-full h-6 w-6 bg-white hover:bg-gray-100 cursor-pointer"
                                    onClick={() =>
                                      handleToggleRoleStakeholders(
                                        imprintRole._id
                                      )
                                    }
                                  >
                                    <EtRoleStakeholdersIcon1SVGComp className="h-4 w-4 text-orange-600" />
                                  </div>
                                  <div>
                                    <p className="font-bold m-1 p-1">
                                      Stakeholders served:
                                    </p>
                                  </div>
                                </div>

                                {showRoleStakeholders[imprintRole._id] && (
                                  <div className="grid grid-cols-4 justify-between items-center gap-1 m-1 p-1">
                                    {userRoleStakeholders?.length > 0 &&
                                      (() => {
                                        // Stakeholder type-to-component mapping
                                        const stakeholderComponentsMap = {
                                          Clients:
                                            EtRoleStakeholdersClientsIcon1SVGComp,
                                          Org: EtRoleStakeholdersOrgIcon1SVGComp,
                                          "Org Team":
                                            EtRoleStakeholdersOrgTeamIcon1SVGComp,
                                          Partners:
                                            EtRoleStakeholdersPartnersIcon1SVGComp,
                                        }

                                        return userRoleStakeholders?.map(
                                          (stakeholder, stakeholderIndex) => {
                                            const stakeholderType =
                                              stakeholder?.stakeholderType ||
                                              "Unknown"
                                            const StakeholderIcon =
                                              stakeholderComponentsMap[
                                                stakeholderType
                                              ] || null

                                            return (
                                              <div
                                                key={
                                                  stakeholder._id +
                                                  stakeholderIndex
                                                }
                                                className="bg-gray-100 border rounded aspect-square flex flex-col justify-center items-center"
                                              >
                                                {StakeholderIcon ? (
                                                  <StakeholderIcon className="h-1/2 w-1/2 text-orange-600 mr-2" />
                                                ) : (
                                                  <p className="text-gray-400 mr-2">
                                                    No Icon
                                                  </p>
                                                )}
                                                <p className="text-xs m-1 p-1 ">
                                                  {stakeholderType}
                                                </p>
                                              </div>
                                            )
                                          }
                                        )
                                      })()}
                                  </div>
                                )}
                              </div>

                              {/* Role - Contributions & Impacts */}
                              <div className="m-1 p-1 border rounded bg-gray-50">
                                <div className="flex justify-start items-center m-1 p-1">
                                  <div
                                    onClick={() =>
                                      handleToggleRoleCIs(imprintRole._id)
                                    }
                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                  >
                                    {showRoleCIs[imprintRole._id] ? (
                                      <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                      <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                  </div>
                                  <div
                                    className="flex justify-center items-center rounded-full h-6 w-6 bg-white hover:bg-gray-100 cursor-pointer"
                                    onClick={() =>
                                      handleToggleRoleCIs(imprintRole._id)
                                    }
                                  >
                                    <EtRoleCIsIcon2SVGComp className="h-4 w-4 text-orange-600" />
                                  </div>
                                  <p className="font-bold m-1 p-1">
                                    Contributions & Impacts:
                                  </p>
                                </div>

                                {showRoleCIs[imprintRole._id] && (
                                    <div>
                                    {userRoleContributionsAndImpacts?.length > 0 &&
                                        userRoleContributionsAndImpacts?.map((contribution, contributionIndex) => (
                                        <ContributionItem
                                            key={contribution._id}
                                            contribution={contribution}
                                            contributionIndex={contributionIndex}
                                            handleToggleRoleCIDetails={handleToggleRoleCIDetails}
                                            showRoleCIDetails={showRoleCIDetails}
                                            handleToggleRoleContributionNotes={handleToggleRoleContributionNotes}
                                            handleToggleRoleImpactNotes={handleToggleRoleImpactNotes}
                                            showRoleContributionNotes={showRoleContributionNotes}
                                            showRoleImpactNotes={showRoleImpactNotes}
                                            getColorForScore={getColorForScore}
                                            createMarkup={createMarkup}
                                        />
                                        ))}
                                    </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    })}
              </div>

              {/* Section 2 - Role Self Notes Trail */}
              <div className=" m-1 p-1 bg-gray-200 rounded ">
                <div className="grid grid-cols-8 justify-start items-center">
                  <div
                    className={`col-span-3 flex justify-start items-center m-1 p-1`}
                  >
                    <div
                      onClick={() => setShowRoleSelfNotes((prev) => !prev)}
                      className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                    >
                      {showRoleSelfNotes ? (
                        <MinusIcon className="h-4 w-4 text-orange-600" />
                      ) : (
                        <PlusIcon className="h-4 w-4 text-orange-600" />
                      )}
                    </div>
                    <div
                      className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer"
                      onClick={() => setShowRoleSelfNotes((prev) => !prev)}
                    >
                      <EtRoleSelfNotesIcon1SVGComp className="h-6 w-6 text-orange-600" />
                    </div>
                    <div>
                      <p className="mx-1 px-1 font-bold text-gray-600">
                        Role Notes Trail
                      </p>
                      <p className="mx-1 px-1 text-gray-400 text-xs italic">
                        what perspectives have I gained from my roles?
                      </p>
                    </div>
                  </div>
                </div>

                {showRoleSelfNotes &&
                  selfNotes?.length > 0 &&
                  selfNotes?.map((selfNote, selfNoteIndex) => {
                    const noteText = selfNote?.userImprintRoleSelfNoteText || ""
                    const noteDate = selfNote?.userImprintRoleSelfNoteDate || ""
                    const noteUpdateDate =
                      selfNote?.userImprintRoleSelfNoteUpdateDate || ""

                    return (
                      <div
                        key={selfNote._id + selfNoteIndex}
                        className="m-1 p-1 bg-white rounded"
                      >
                        <div className="grid grid-cols-6 justify-between items-center m-1 p-1 bg-gray-100 border rounded">
                          <div className="col-span-1 flex justify-start items-center m-1 p-1">
                            <div
                              onClick={() =>
                                handleToggleRoleSelfNoteDetails(selfNote._id)
                              }
                              className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                            >
                              {showRoleSelfNoteDetails[selfNote._id] ? (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                              ) : (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                              )}
                            </div>
                            <div>
                              <p className="text-xl font-bold mx-1 px-1 text-gray-600">
                                {selfNoteIndex + 1}.
                              </p>
                            </div>
                            <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                              <EtCapabilitiesSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
                            </div>
                            <div>
                              <div className="flex flex-col justify-center items-center mx-1 px-1">
                                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                  {getTimeSince(noteUpdateDate)}
                                </p>
                                <p className="text-[9px] text-gray-600 italic">
                                  last updated
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-3">
                            <div
                              dangerouslySetInnerHTML={createMarkup(noteText)}
                              className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                            ></div>
                          </div>
                          <div className="col-span-2 flex justify-center items-center mx-2">
                            <ClockIcon className="h-5 w-5 text-lime-600" />
                            <p className="text-[9px] mx-1">First Created:</p>
                            <p className="text-gray-400 text-[9px] mx-2">
                              {formatDateTime(noteDate)}
                            </p>
                          </div>
                        </div>
                        {showRoleSelfNoteDetails[selfNote._id] && (
                          <div
                            dangerouslySetInnerHTML={createMarkup(noteText)}
                            className="text-xs text-gray-600 m-1 p-1 border rounded leading-4 bg-white"
                          ></div>
                        )}
                      </div>
                    )
                  })}
              </div>
            </div>
          )
        })}
    </div>
  )
}

const ContributionItem = ({ 
    contribution, 
    contributionIndex, 
    handleToggleRoleCIDetails, 
    showRoleCIDetails,
    handleToggleRoleContributionNotes,
    handleToggleRoleImpactNotes,
    showRoleContributionNotes,
    showRoleImpactNotes,
    getColorForScore,
    createMarkup, 
}) => {

    // First Created - 29 Nov 2024
    // Author - Atul Pandey

    // 1a - Extract values from contribution
    const {
      roleContributionType: { contributionTypeName = "", pic: contributionTypePic = "" },
      roleContributionSelfEvalText = "",
      roleContributionSelfEvalScore = 0,
      roleContributionNotes = [],
      roleImpactType: { impactTypeName = "", pic: impactTypePic = "" },
      roleImpactSelfEvalText = "",
      roleImpactSelfEvalScore = 0,
      roleImpactNotes = []
    } = contribution;

    // 1b - state variables for note details
    const [ showContributionNoteDetails, setShowContributionNoteDetails ] = useState({})
    const [ showImpactNoteDetails, setShowImpactNoteDetails ] = useState({})

    // 1c - Toggle Show function for show role Contribution Note details
    const handleToggleContributionNoteDetails = (noteId) => {
        setShowContributionNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
        }))
    }

    // 1c - Toggle Show function for show role Impact Note details
    const handleToggleImpactNoteDetails = (noteId) => {
        setShowImpactNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
        }))
    }

 
    // 2 - Render contribution section
    const renderContributionSection = () => (
      <div className="col-span-2 flex justify-start items-center m-1 p-1">
        <div>
          <img src={contributionTypePic} alt="" className="h-8 w-8" />
        </div>
        <div>
          <p className="text-[9px] font-bold mx-1 px-1">Contribution Area</p>
          <p className="text-[10px] text-gray-600 italic mx-1 px-1">
            {contributionTypeName}
          </p>
        </div>
      </div>
    );
  
    // 3 - Render impact section
    const renderImpactSection = () => (
      <div className="col-span-2 flex justify-start items-center m-1 p-1">
        <div>
          <img src={impactTypePic} alt="" className="h-8 w-8" />
        </div>
        <div>
          <p className="text-[9px] font-bold mx-1 px-1">Impact Area</p>
          <p className="text-[10px] text-gray-600 italic mx-1 px-1">
            {impactTypeName}
          </p>
        </div>
      </div>
    );
  
    // 4 - Render luminosity score section
    const renderLuminosityScore = (score, text, label) => (
      <div className="col-span-2 m-1 p-1">
        <div className="flex justify-start items-center">
          <div className="m-1 p-1">
            <p className="text-[9px] font-bold mx-1 px-1">{label}</p>
            <p className="text-[10px] text-gray-600 italic mx-1 px-1">{text}</p>
          </div>
          <div className={`h-12 w-12 aspect-square flex justify-center items-center border rounded ${getColorForScore(score)}`}>
            <p className="text-xl font-bold">{score}</p>
          </div>
        </div>
      </div>
    );
  
    return (
      <div key={contribution._id + contributionIndex} className="bg-gray-50 rounded">
        
        <div className="border rounded bg-white m-1 p-1">
        <div className="grid grid-cols-10 justify-between items-center  ">
          {/* Index and Toggle Section */}
          <div className="col-span-1 flex justify-start items-center m-1 p-1">
            <div
              onClick={() => handleToggleRoleCIDetails(contribution._id)}
              className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
            >
              {showRoleCIDetails[contribution._id] ? (
                <MinusIcon className="h-4 w-4 text-orange-600" />
              ) : (
                <PlusIcon className="h-4 w-4 text-orange-600" />
              )}
            </div>
            <div>
              <p className="text-xl font-bold mx-1 px-1 text-gray-600">
                {contributionIndex + 1}.
              </p>
            </div>
          </div>
  
          {/* Contribution Section */}
          {renderContributionSection()}
  
          {/* Contribution Luminosity Score */}
          {renderLuminosityScore(
            roleContributionSelfEvalScore,
            roleContributionSelfEvalText,
            "Contribution Luminosity"
          )}
  
          {/* Spacer */}
          <div className="col-span-1"></div>
  
          {/* Impact Section */}
          {renderImpactSection()}
  
          {/* Impact Luminosity Score */}
          {renderLuminosityScore(
            roleImpactSelfEvalScore,
            roleImpactSelfEvalText,
            "Impact Luminosity"
          )}

        </div>
       
        {showRoleCIDetails[contribution._id] && (
        <div className="mx-4">
            {/* Contribution Notes Section */}
            <div className="mb-1 border rounded bg-gray-50 m-1 p-1">
                <div className="flex justify-start items-center m-1 p-1">
                    <div
                        onClick={() => handleToggleRoleContributionNotes(contribution._id)}
                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                        >
                        {showRoleContributionNotes[contribution._id] ? (
                            <MinusIcon className="h-4 w-4 text-orange-600" />
                        ) : (
                            <PlusIcon className="h-4 w-4 text-orange-600" />
                        )}
                    </div>
                    <div
                      className="flex justify-center items-center rounded-full h-6 w-6 bg-gray-50 hover:bg-white cursor-pointer"
                      onClick={() => handleToggleRoleContributionNotes(contribution._id)}
                    >
                      <EtRoleContributionsIcon3SVGComp className="h-4 w-4 text-orange-600" />
                    </div>
                    <p className="font-bold text-sm">Contribution Notes</p>
                </div>
                {showRoleContributionNotes[contribution._id] && (
                    <div className="m-2">
                    {roleContributionNotes?.length > 0 ? (
                        roleContributionNotes.map((cNote, cNoteIndex) => (
                        <div key={cNote._id+ cNoteIndex} className="m-1 p-1 bg-gray-200 rounded border">
                            
                            <div className="grid grid-cols-8 justify-start items-center gap-1 ">

                                <div className={`col-span-2 flex justify-start items-center m-1 p-1`}>
                                    <div
                                        onClick={() =>
                                        handleToggleContributionNoteDetails(cNote._id)
                                        }
                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                    >
                                        {showContributionNoteDetails[cNote._id] ? (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                        )}
                                    </div>
                                    <div>
                                        <p className="text-xl font-bold mx-1 px-1 text-gray-600">
                                        {cNoteIndex + 1}.
                                        </p>
                                    </div>
                                    <div
                                        className="flex justify-center items-center rounded-full h-6 w-6 bg-gray-50 hover:bg-white cursor-pointer"
                                        onClick={() =>
                                        handleToggleContributionNoteDetails(cNote._id)
                                        }
                                    >
                                        <EtRoleContributionsIcon3SVGComp className="h-4 w-4 text-orange-600" />
                                    </div>
                                    <div>
                                        <div className='flex flex-col justify-center items-center mx-1 px-1'>
                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">{getTimeSince(cNote?.roleContributionNoteUpdateDate)}</p>
                                            <p className='text-[9px] text-gray-600 italic'>last updated</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className={`col-span-4 flex justify-start items-center m-1 p-1`}>
                                    <div>
                                        <p dangerouslySetInnerHTML={createMarkup(cNote.roleContributionNoteText)}   className="text-xs text-gray-600 leading-4 truncate max-w-full line-clamp-1 mx-1 px-1">
                                        </p>
                                    </div>
                                </div>

                                <div className={`col-span-2 flex justify-start items-center m-1 p-1`}>
                                    <ClockIcon className="h-5 w-5 text-lime-600" />
                                    <p className="text-[9px] mx-1">First Created:</p>
                                    <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(cNote?.roleContributionNoteDate)}</p>
                                </div>
                            </div>

                            {showContributionNoteDetails[cNote._id] && (
                                <div className="m-1 p-1 border rounded bg-white">
                                    <p dangerouslySetInnerHTML={createMarkup(cNote.roleContributionNoteText)} className="text-xs text-gray-600 leading-4 m-1 p-1">
                                    </p>
                                </div>
                            )}
                        </div>
                        ))
                    ) : (
                        <p className="text-xs text-gray-500 italic p-2">No contribution notes are available yet!</p>
                    )}
                    </div>
                )}
            </div>

            {/* Impact Notes Section */}
            <div className="mb-2 border rounded bg-gray-50 m-1 p-1">
            <div className="flex justify-start items-center m-1 p-1">
                <div
                onClick={() => handleToggleRoleImpactNotes(contribution._id)}
                className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                >
                {showRoleImpactNotes[contribution._id] ? (
                    <MinusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                    <PlusIcon className="h-4 w-4 text-orange-600" />
                )}
                </div>
                <div
                      className="flex justify-center items-center rounded-full h-6 w-6 bg-gray-50 hover:bg-white cursor-pointer"
                      onClick={() => handleToggleRoleImpactNotes(contribution._id)}
                    >
                      <EtRoleImpactsIcon2SVGComp className="h-4 w-4 text-orange-600" />
                    </div>
                <p className="font-bold text-sm">Impact Notes</p>
            </div>
                {showRoleImpactNotes[contribution._id] && (
                        <div className="m-2">
                        {roleImpactNotes?.length > 0 ? (
                            roleImpactNotes.map((iNote, iNoteIndex) => (
                            <div key={iNote._id+ iNoteIndex} className="m-1 p-1 bg-gray-200 rounded border">
                                
                                <div className="grid grid-cols-8 justify-start items-center gap-1 ">

                                    <div className={`col-span-2 flex justify-start items-center m-1 p-1`}>
                                        <div
                                            onClick={() =>
                                            handleToggleImpactNoteDetails(iNote._id)
                                            }
                                            className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                        >
                                            {showImpactNoteDetails[iNote._id] ? (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                            ) : (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                            )}
                                        </div>
                                        <div>
                                            <p className="text-xl font-bold mx-1 px-1 text-gray-600">
                                            {iNoteIndex + 1}.
                                            </p>
                                        </div>
                                        <div
                                            className="flex justify-center items-center rounded-full h-6 w-6 bg-gray-50 hover:bg-white cursor-pointer"
                                            onClick={() =>
                                            handleToggleImpactNoteDetails(iNote._id)
                                            }
                                        >
                                            <EtRoleImpactsIcon2SVGComp className="h-4 w-4 text-orange-600" />
                                        </div>
                                        <div>
                                            <div className='flex flex-col justify-center items-center mx-1 px-1'>
                                                <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">{getTimeSince(iNote?.roleImpactNoteUpdateDate)}</p>
                                                <p className='text-[9px] text-gray-600 italic'>last updated</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className={`col-span-4 flex justify-start items-center m-1 p-1`}>
                                        <div>
                                            <p dangerouslySetInnerHTML={createMarkup(iNote.roleImpactNoteText)}   className="text-xs text-gray-600 leading-4 truncate max-w-full line-clamp-1 mx-1 px-1">
                                            </p>
                                        </div>
                                    </div>

                                    <div className={`col-span-2 flex justify-start items-center m-1 p-1`}>
                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                        <p className="text-[9px] mx-1">First Created:</p>
                                        <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(iNote?.roleImpactNoteDate)}</p>
                                    </div>
                                </div>

                                {showImpactNoteDetails[iNote._id] && (
                                    <div className="m-1 p-1 border rounded bg-white">
                                        <p dangerouslySetInnerHTML={createMarkup(iNote.roleImpactNoteText)} className="text-xs text-gray-600 leading-4 m-1 p-1">
                                        </p>
                                    </div>
                                )}
                            </div>
                            ))
                        ) : (
                            <p className="text-xs text-gray-500 italic p-2">No impact notes are available yet!</p>
                        )}
                        </div>
                )}
            </div>
        </div>
        )}
        </div>

      
      </div>
    );
  };
  

export default EtImprintRole