import { useState, useEffect, useRef } from 'react'
import { useNavigate } from "react-router"

import { PlusIcon, PaperAirplaneIcon, MicrophoneIcon, StopIcon } from "@heroicons/react/24/solid";
import { userProfileIcon2 } from "@/assets/img/imgAssets"

import { useGetUserPicQuery, useGetUsersQuery } from "@/features/users/usersApiSlice"
import useAuth from '@/hooks/useAuth';
import { useSendLogoutMutation } from "@/features/auth/authApiSlice"

import TrinityIcon from '@/assets/img/icons/base64Svgs/trinityRoundIcon1.svg'
import EtWabActionIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabActionIcon1SVGComp"
import EtWabConversationIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabConversationIcon1SVGComp"
import EtWabCreationIcon1SVGComp  from "@/assets/img/icons/svgComp/EtWabCreationIcon1SVGComp" 
import EtWabContributionIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabContributionIcon1SVGComp" 
import EtWabImpactIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabImpactIcon1SVGComp" 
import EtWabWinIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabWinIcon1SVGComp" 
import EtWabChallengeIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabChallengeIcon1SVGComp" 
import EtWabIntentionIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabIntentionIcon1SVGComp" 
import EtWabGratitudeIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabGratitudeIcon1SVGComp" 
import EtConversationAllIcon1SVGComp from '@/assets/img/icons/svgComp/EtConversationAllIcon1SVGComp' 
import EtConversationContactIcon1SVGComp from '@/assets/img/icons/svgComp/EtConversationContactIcon1SVGComp' 

// 19 Feb 2025
import EtLogoutIcon1SVGComp from "@/assets/img/icons/svgComp/EtLogoutIcon1SVGComp" 


const EtTrinityMobileHome = () => {
  // First Created - 18 Feb 2025
  // Author - Atul Pandey
  // Updated - applied useAuth - to get the authenticated data from the backend.

  // 0 - Extract username and name
  const { username, name } = useAuth() // Extract username & name
  // console.log('username', username)
  // console.log('name', name)
  
  // 1a - Set navigation
  const navigate = useNavigate()

  // 1b - Sections Data
  const sections = [
    {name: "Actions", pic: EtWabActionIcon1SVGComp, desc:"record or progress an action",goTo: 'action'},
    {name: "Conversations", pic: EtWabConversationIcon1SVGComp, desc:"record a new conversation", goTo: 'conversation'},
    {name: "Contributions", pic: EtWabContributionIcon1SVGComp, desc:"record a new contribution", goTo: 'contribution'},
    {name: "Impacts", pic: EtWabImpactIcon1SVGComp, desc:"record a new impact",goTo: 'impact'},
    {name: "Creations", pic: EtWabCreationIcon1SVGComp, desc:"record a new creation", goTo: 'creation'},
    {name: "Wins", pic: EtWabWinIcon1SVGComp, desc:"record a new win",goTo: 'win'},
    {name: "Challenges", pic: EtWabChallengeIcon1SVGComp, desc:"record a new challenge",goTo: 'challenge'},
    {name: "Intentions", pic: EtWabIntentionIcon1SVGComp, desc:"record a new intention",goTo: 'intention'},
    {name: "Gratitude", pic: EtWabGratitudeIcon1SVGComp, desc:"record a new gratitude",goTo: 'gratitude'},
    ]
  
  // 2a - User Info
  const { data: usersData } = useGetUsersQuery();

  const [sendLogout, {
    isLoading,
    isError,
    error
  }] = useSendLogoutMutation()
  
  const extractEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id])
  };

  const extractFilteredEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id])
    ?.filter(
        (item) => item?.username === username && item.name === name
        );
  };

  // const usersAll = extractEntitiesFromAdapter(usersData)
  // console.log('usersAll', usersAll)
  const userInfo = extractFilteredEntitiesFromAdapter(usersData)

  // console.log('userInfo', userInfo)   

// 2b - extract userId and userOrgId from userInfo
const userId = userInfo[0]?._id || null
const userOrgId = userInfo[0]?.userOrgId?._id || null

// console.log('userId', userId)
// console.log('userOrgId', userOrgId)

// 3 - Handle Logout
const handleLogout = async () => {
  try {
    await sendLogout().unwrap();
    navigate('/login')
  } catch (err) {
    console.error('Logout failed:', err);
  }
};
  
  return (
    <div div className="flex flex-col h-screen bg-gray-200 p-4 rounded m-1 overflow-auto max-w-md mx-auto ml-1 mr-1 font-poppins">
        {/* Logout */}
        <div className='flex justify-end cursor-pointer m-1 p-1' onClick={handleLogout}>  
          <div className='flex justify-center items-center rounded-full w-6 h-6 bg-gradient-to-r from-orange-800 to-orange-800 via-orange-600 shadow-xl shadow-gray-600'>
            <EtLogoutIcon1SVGComp className='h-4 w-4 text-white'/>
          </div>
        </div>
        {/* Trinity Bar */}
        <div className='flex justify-center items-center m-1 p-1'>      
            <div className='flex justify-center items-center rounded-full shadow-lg shadow-gray-600 cursor-pointer'
                onClick={() => navigate('/etapp/mobile')}
            >
                <img src={TrinityIcon} alt="" className='h-24 w-24' />
            </div>
            {/* <p className="px-5 text-xs text-gray-600 cursor-pointer" onClick={handleLogout}>Logout</p> */}
        </div>
        {/* User Bar */}
        <div className='my-2 py-2 border-t border-white'>
            <div className='grid grid-cols-5 justify-start items-center m-1 p-1 border-b border-white mb-2 pb-4'>
                <div className='col-span-1'>
                    <User username={username} defaultUserPic={userProfileIcon2}/>
                </div>
                <div className='col-span-4'>
                    <p className='mx-1 px-1'>
                        Hello <span className='font-bold text-gray-600 '>{name?.split(' ')[0]}</span>, what would you like to accomplish today?
                    </p>
                </div>  
            </div>
            {/* Main Content Section */}
            {sections?.map((section, index) => (
                <div key={index} className='grid grid-cols-8 justify-start items-center mx-auto pl-5 m-1 p-1 bg-gray-50 rounded shadow-md shadow-gray-500'>
                    <div className="col-span-2 flex justify-center items-center rounded-full h-12 w-12 sm:h-16 sm:w-16">
                        <section.pic className='h-12 w-12 sm:h-16 sm:w-16 text-orange-600' />
                    </div>
                    <div className='col-span-5'>
                        <p className='font-bold text-lg sm:text-xl mx-2 px-2'>{section.name}</p>
                        <p className='text-xs sm:text-sm mx-2 px-2 text-gray-500 italic'>{section.desc}</p>
                    </div>
                    <div className='col-span-1 bg-lime-600 p-1 rounded h-6 w-6 sm:h-7 sm:w-7 cursor-pointer'
                    
                        onClick={() => navigate(`/etapp/mobile/${section?.goTo}`)}
                    >
                        <PlusIcon className='h-4 w-4 sm:h-5 sm:w-5 text-white'/>
                    </div>
                    
                </div>
            ))}
        </div>
    </div>
  )
}

const User = ({ username, defaultUserPic }) => {
    const { data: userPic, isFetching: isFetchingUserPic } =
      useGetUserPicQuery(username)
  
    return (
      <div className="flex justify-center items-center h-12 w-12 rounded-full border border-white bg-gradient-to-r from-orange-600 to-orange-400 shadow-lg shadow-black cursor-pointer">
        {/* Display user picture or loading indicator*/}
        {isFetchingUserPic ? (
          <div>Loading...</div>
        ) : userPic ? (
          <img src={userPic} alt="User" className="h-11 w-11  rounded-full" />
        ) : (
          <img src={defaultUserPic} alt="User" className="h-10 w-10 p-1" />
        )}
      </div>
    )
  }

export default EtTrinityMobileHome