import React, { useState, useEffect } from "react"
// import { useNavigate } from "react-router"
import useTitle from "../../hooks/useTitle"
import PulseLoader from "react-spinners/PulseLoader"
import { Alert } from '@mui/material'
import { learnIcon2 } from "../../assets/img/sessionImgAssets"
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { useGetQuestionWithScoresQuery } from "../questionwithscores/questionwithscoresApiSlice"
import { useGetSectionsQuery } from "../sections/sectionsApiSlice"
import { useGetAssessmentsQuery } from "../assessments/assessmentsApiSlice"
import { useCreateNewUserAssessmentResponseWithScoreMutation } from "./userAssessmentResponseWithScoresApiSlice"                   
import { useCreateNewUserSectionResponseWithScoreMutation, useGetUserSectionResponseWithScoresQuery } from "../userSectionResponseWithScores/userSectionResponseWithScoresApiSlice"
import { useCreateNewUserQuestionResponseWithScoreMutation, useGetUserQuestionResponseWithScoresQuery } from "../userQuestionResponseWithScores/userQuestionResponseWithScoresApiSlice"

const EtCreateUserAssessmentResponseWithScoresComp = ({userId, trailId, sessionId, userSessionInvestigationId, assessmentName, setShowPostAssessment }) => {
  // Created - 09 Jul 2024
  // Inherited from - EtCreateUserAssessmentResponseWithScores
  // Author - Atul Pandey
  // Updated - 11 Jul 2024 - added trailId, sessionId, userSessionInvestigationId to the submissions
  // Updated - 11 Jul 2024 - added the get queries to fetch already submitted questions and sections

  // 0 - Set variables
  // const navigate = useNavigate()
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0) // Separate Question Index to track questions
  const [responses, setResponses] = useState({})
  const [submittedQuestions, setSubmittedQuestions] = useState({})
  const [submittedSections, setSubmittedSections] = useState({})
  const [filteredQuestions, setFilteredQuestions] = useState([])
  const [filteredSections, setFilteredSections] = useState([])
  const [filteredAssessment, setFilteredAssessment] = useState(null)

  const [alert, setAlert] = useState("")

  // 1 - Set the page title
  useTitle("Assessment Responses")

  // 2 - Now let us get all the data we need
  const { data: assessmentsData, isLoading: loadingAssessments } =
    useGetAssessmentsQuery()
  const { data: sectionsData, isLoading: loadingSections } =
    useGetSectionsQuery()
  const { data: questionsData, isLoading: loadingQuestions } =
    useGetQuestionWithScoresQuery()
  // 11 Jul 2024 - added the fetch / get queries for submitted sections, questions and assessments
  // const { data:userAssessmentResponses, isLoading: loadingUserAssessments } = useGetUserAssessmentResponseWithScoresQuery()
  const { data:userSectionResponses, isLoading: loadingUserSections } = useGetUserSectionResponseWithScoresQuery()
  const { data:userQuestionResponses, isLoading: loadingUserQuestions } = useGetUserQuestionResponseWithScoresQuery()
  
  // console.log('assessmentsData', assessmentsData)
  // console.log('sectionsData', sectionsData)
  // console.log('questionsData', questionsData)

  // console.log('userAssessmentResponses', userAssessmentResponses)
  // console.log('userSectionResponses', userSectionResponses)
  // console.log('userQuestionResponses', userQuestionResponses)

  // 2b - Let us also define the mutations to create the new scores in the backend
  const [createNewUserQuestionResponseWithScore] =
    useCreateNewUserQuestionResponseWithScoreMutation()
  const [createNewUserSectionResponseWithScore] =
    useCreateNewUserSectionResponseWithScoreMutation()
  const [createNewUserAssessmentResponseWithScore] =
    useCreateNewUserAssessmentResponseWithScoreMutation()

  // 3a - Extract all the needed data -- updated - 11 Jul 2024 --
  useEffect(() => {
    if (
      assessmentsData && 
      sectionsData && 
      questionsData
    ) {
      // Step 1: Extract the given assessment
      const assessmentId = assessmentsData?.ids.find(
        (id) => assessmentsData?.entities[id].assessmentName === assessmentName
      )
  
      const filteredAssessment = assessmentsData?.entities[assessmentId]
  
      // Step 2: Extract sections for the given assessment
      const filteredSections = filteredAssessment?.sections.map(
        (section) => sectionsData?.entities[section._id]
      )
  
      // Step 3: Maintain connection between sections and questions
      const sectionWithQuestions = filteredSections?.map((section) => {
        // Find matching questions for this section
        const matchingQuestions = Object.values(
          questionsData?.entities
        )?.filter((question) => question.qsetName === section.qsetName)
  
        // Build a complete list of questions with detailed information
        const completeQuestions = matchingQuestions.map((question) => ({
          ...question, // This ensures all details from questionsData are included
          sectionName: section.sectionName, // Additional attribute for reference
        }))
  
        return {
          sectionName: section.sectionName,
          questions: completeQuestions,
        }
      })
  
      // Step 4: Populate submitted questions and sections from existing data
      const submittedQuestions = {}
      const responses = {}
  
      userQuestionResponses?.ids?.forEach((id) => {
        const response = userQuestionResponses?.entities[id]
        // console.log('question response:', response)
        if (
          response?.userId._id === userId &&
          response?.trailId === trailId &&
          response?.sessionId === sessionId &&
          response?.userSessionInvestigationId === userSessionInvestigationId
        ) {
          sectionWithQuestions?.forEach((section, sectionIndex) => {
            const questionIndex = section?.questions.findIndex((q) => q.id === response.questionId._id) // 12 Jul 2024 questionId._id
            // console.log('question Index', questionIndex)
            if (questionIndex !== -1) {
              submittedQuestions[`${sectionIndex}-${questionIndex}`] = true
              responses[response?.questionId._id] = {                                     // 12 Jul 2024 questionId._id
                responseText: response.questionResponse,
                score: response.questionScore,
              }
            }
          })
        }
      })
  
      const submittedSections = {}
      userSectionResponses?.ids?.forEach((id) => {
        const response = userSectionResponses?.entities[id]
        // console.log('section response:', response)
        if (
          response?.userId._id === userId &&
          response?.trailId === trailId &&
          response?.sessionId === sessionId &&
          response?.userSessionInvestigationId === userSessionInvestigationId
        ) {
          const sectionIndex = filteredSections?.findIndex(
            (section) => section?._id === response.sectionId._id          // 12 Jul 2024 sectionId._id
          )
          submittedSections[sectionIndex] = true
        }
      })

      // Determine initial section and question index based on unsubmitted status - 12 Jul 2024
      let initialSectionIndex = 0;
      let initialQuestionIndex = 0;

      for (let sectionIndex = 0; sectionIndex < sectionWithQuestions?.length; sectionIndex++) {
          if (!submittedSections[sectionIndex]) {
              initialSectionIndex = sectionIndex;
              for (let questionIndex = 0; questionIndex < sectionWithQuestions[sectionIndex]?.questions?.length; questionIndex++) {
                  if (!submittedQuestions[`${sectionIndex}-${questionIndex}`]) {
                      initialQuestionIndex = questionIndex;
                      break;
                  }
              }
              break;
          }
      }
  
      // Update state with the filtered data
      setSubmittedQuestions(submittedQuestions) // 11 Jul 2024
      setSubmittedSections(submittedSections) // 11 Jul 2024
      setResponses(responses) // 11 Jul 2024
      setFilteredAssessment(filteredAssessment)
      setFilteredSections(filteredSections)
      setFilteredQuestions(sectionWithQuestions)
      setCurrentSectionIndex(initialSectionIndex);
      setCurrentQuestionIndex(initialQuestionIndex);
    }
  }, [
    assessmentsData,
    sectionsData, 
    questionsData, 
    // userSectionResponses,       // 11 Jul 2024
    // userQuestionResponses,      // 11 Jul 2024 
  ])
  
  

  // console.log('filtered assessment: ', filteredAssessment)
  // console.log('filtered sections: ', filteredSections)
  //console.log('filtered questions: ', filteredQuestions)

  // Define the current section and current question in the section:
  const currentSection = filteredSections[currentSectionIndex]
  const currentQuestion =
    filteredQuestions[currentSectionIndex]?.questions[currentQuestionIndex]

  //console.log('current Section',currentSection)
  // console.log('current Question',currentQuestion)

  // useEffect to automatically clear the alert after 2 seconds
  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert("")
      }, 2000)
      return () => clearTimeout(timer) // Cleanup timer if alert changes or component unmounts
    }
  }, [alert])

  // 4 - Navigation between sections and questions
  // Set handle section circle index
  const handleCircleSectionClick = (index) => {
    handleSectionChange(index)
  }
  // Set handle question circle index
  const handleCircleQuestionClick = (index) => {
    setCurrentQuestionIndex(index)
  }
  // Handle Section Change
  const handleSectionChange = (index) => {
    setCurrentSectionIndex(index)
    setCurrentQuestionIndex(0) // Reset question index when changing sections
  }

  // 5 - Handle Question Submit
  const handleQuestionSubmit = async (event) => {
    event.preventDefault()
    const questionResponse = responses[currentQuestion.id]

    if (!questionResponse) {
      setAlert("Please select an option before submitting the question.")
      return
    }

    // Create a new question response
    await createNewUserQuestionResponseWithScore({
      userId: userId,
      trailId,                                  // 11 Jul 2024
      sessionId,                                // 11 Jul 2024
      userSessionInvestigationId,               // 11 Jul 2024
      questionId: currentQuestion.id,
      questionResponse: questionResponse.responseText,
      questionScore: questionResponse.score,
      dateStarted: new Date(),
    })

    // Mark the current question as submitted
    setSubmittedQuestions((prev) => ({
      ...prev,
      [`${currentSectionIndex}-${currentQuestionIndex}`]: true,
      // [`${currentQuestion.id}`]: true, // 11 Jul 2024 
    }))

    // Navigate to the next question within the section
    if (
      currentQuestionIndex <
      filteredQuestions[currentSectionIndex]?.questions.length - 1
    ) {
      setCurrentQuestionIndex((prev) => prev + 1)
    } else {
      // setCurrentQuestionIndex(filteredQuestions[currentSectionIndex]?.questions.length - 1) // Stay on the last question
      setAlert("All questions have been submitted. Please submit the section.")
    }
  }

  // 6 - Handle Section Submit
  const handleSectionSubmit = async (event) => {
    event.preventDefault()

    // Ensure all questions in the current section are submitted
    if (
      filteredQuestions[currentSectionIndex]?.questions.some(
        (_, index) => !submittedQuestions[`${currentSectionIndex}-${index}`]
      )
    ) {
      setAlert("Please submit all questions before submitting the section.")
      return
    }

    // Build section response with all question responses
    const sectionResponses = filteredQuestions[
      currentSectionIndex
    ]?.questions.map((question) => ({
      questionId: question.id,
      questionResponse: responses[question.id]?.responseText,
      questionScore: responses[question.id]?.score,
    }))

    // Calculate section score
    const totalScore = sectionResponses.reduce(
      (acc, response) => acc + (response.questionScore ?? 0),
      0
    )
    const sectionScore = (totalScore / sectionResponses.length).toFixed(2)

    await createNewUserSectionResponseWithScore({
      userId,
      trailId,                                  // 11 Jul 2024
      sessionId,                                // 11 Jul 2024
      userSessionInvestigationId,               // 11 Jul 2024
      sectionId: currentSection?.id,
      questionSetId: currentSection?.questionSetId,
      responses: sectionResponses,
      sectionScore,
      dateStarted: new Date(),
    })

    // Mark the current section as submitted
    setSubmittedSections((prev) => ({
      ...prev,
      [currentSectionIndex]: true, 
      // [currentSection?.id]: true,// Commented -- 12 Jul 2024
    }))
    // Navigate to the next section
    if (currentSectionIndex < filteredSections?.length - 1) {
      setCurrentSectionIndex(currentSectionIndex + 1) // Move to the next section
      setCurrentQuestionIndex(0)
    } else {
      setAlert(
        "All sections have been submitted. Please submit the assessment."
      ) // Alert when all sections are submitted
    }
  }

  // 7 - Handle Assessment Submit
  const handleAssessmentSubmit = async () => {
    // Ensure all sections are submitted
    if (filteredSections.some((_, index) => !submittedSections[index])) {
      setAlert("Please submit all sections before submitting the assessment.")
      return
    }

    // Build section data with all responses and scores
    const sectionsData = filteredSections.map((section, sectionIndex) => {
      const sectionResponses = filteredQuestions[sectionIndex]?.questions.map(
        (question) => ({
          questionId: question.id,
          questionResponse: responses[question.id]?.responseText,
          questionScore: responses[question.id]?.score,
        })
      )

      // Calculate section score
      const totalSectionScore = sectionResponses.reduce(
        (acc, response) => acc + (response.questionScore ?? 0),
        0
      )

      const sectionScore = (
        totalSectionScore / sectionResponses.length
      ).toFixed(2)

      return {
        sectionId: section.id,
        responses: sectionResponses,
        sectionScore: parseFloat(sectionScore), // Ensure it's a number
      }
    })

    // Calculate the overall assessment score
    const totalAllSectionsScore = sectionsData.reduce(
      (acc, section) => acc + section.sectionScore,
      0
    )
    const assessmentScore = (
      totalAllSectionsScore / sectionsData.length
    ).toFixed(2)

    // Create the assessment response
    await createNewUserAssessmentResponseWithScore({
      userId,
      trailId,                                  // 11 Jul 2024
      sessionId,                                // 11 Jul 2024
      userSessionInvestigationId,               // 11 Jul 2024
      assessmentId: filteredAssessment?.id,
      sections: sectionsData, // All sections with their responses and scores
      assessmentScore: parseFloat(assessmentScore), // Final assessment score
      dateStarted: new Date(),
    })

    // navigate("/userscores/assessments")
    setShowPostAssessment(true)
    // Reload the page after a short delay to ensure state is set
    setTimeout(() => {
    window.location.reload();
    }, 1000); // 1-second delay to ensure state change
  }

  // 8 - While data is loading
  if (
    loadingAssessments || 
    loadingSections || 
    loadingQuestions ||
    // loadingUserAssessments ||   // 11 Jul 2024
    loadingUserSections ||      // 11 Jul 2024
    loadingUserQuestions        // 11 Jul 2024
  ) {
    return <PulseLoader color="#ea580c" />
  }

  // 9 - Create an object for setting inner HTML
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
  }

  // 10 - Handle radio selection
  const handleRadioChange = (questionId, responseText, score) => {
    const questionKey = `${currentSectionIndex}-${currentQuestionIndex}`
    if (submittedQuestions[questionKey]) {
      // If the current question is submitted, do nothing
      return
    }

    // Update responses if not submitted
    setResponses((prev) => ({ ...prev, [questionId]: { responseText, score } }))
  }

  return (
    <div>
      {/* Section Header */}
      <div className="m-1 p-1 rounded bg-gray-200">
        <EtSectionNav
          sectionName={"Sections:"}
          filteredSections={filteredSections}
          currentSectionIndex={currentSectionIndex}
          handleCircleSectionClick={handleCircleSectionClick}
          submittedSections={submittedSections}
        />
        {/* Section Header */}
        <div className="flex justify-start items-center m-1 p-1">
          {/* <div><p className="font-bold text-gray-400 mx-1 px-1">Section: </p></div> */}
          <div>
            <p className="mx-1 px-1 font-bold">{currentSection?.sectionName}</p>
          </div>
        </div>
        {/* Section Intro */}
        <div className="m-1 p-1 rounded bg-white">
          <p
            className="text-[11px] m-1 p-1 leading-4 text-gray-600"
            dangerouslySetInnerHTML={createMarkup(
              filteredSections[currentSectionIndex]?.sectionIntro
            )}
          ></p>
        </div>

        {/* Questions in the section */}
        <div className="m-1 p-1 bg-white rounded">
          <div className="m-1 p-1 bg-gray-200 rounded">
            <EtQuestionNav
              qSetName={`Section ${currentSectionIndex + 1} Questions : `}
              filteredQuestions={
                filteredQuestions[currentSectionIndex]?.questions
              }
              currentQuestionIndex={currentQuestionIndex}
              currentSectionIndex={currentSectionIndex}
              handleCircleQuestionClick={handleCircleQuestionClick}
              submittedQuestions={submittedQuestions}
            />

            {alert && (
              <Alert
                severity="warning"
                onClick={() => setAlert("")}
                className="cursor-pointer"
              >
                {alert}
              </Alert>
            )}

            <form
              className="bg-gray-200 mx-auto m-1 p-1 rounded-lg shadow-lg"
              onSubmit={handleQuestionSubmit}
            >
              <EtQuestionResponseCard
                question={currentQuestion}
                responses={responses}
                handleRadioChange={handleRadioChange}
                createMarkup={createMarkup}
                currentQuestionIndex={currentQuestionIndex}
                currentSectionIndex={currentSectionIndex}
                submittedQuestions={submittedQuestions[currentQuestionIndex]}
              />

              <EtQuestionBottomNavControls
                currentQuestionIndex={currentQuestionIndex} // Current question index
                setCurrentQuestionIndex={setCurrentQuestionIndex} // Function to set question index
                currentSectionIndex={currentSectionIndex} // Current section index
                filteredQuestions={
                  filteredQuestions[currentSectionIndex]?.questions
                } // Questions in the current section
                filteredSections={filteredSections} // All sections in the assessment
                handleQuestionSubmit={handleQuestionSubmit} // Function to submit the current question
                handleSectionSubmit={handleSectionSubmit} // Function to submit the current section
                handleAssessmentSubmit={handleAssessmentSubmit} // Function to submit the assessment
                submittedQuestions={submittedQuestions} // Submitted questions tracking
                submittedSections={submittedSections} // Submitted sections tracking
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

const EtQuestionResponseCard = ({ 
    question, 
    responses, 
    handleRadioChange,
    createMarkup,
    currentQuestionIndex,
    currentSectionIndex,
    submittedQuestions, 
}) => {

    const questionKey = `${currentSectionIndex}-${currentQuestionIndex}`; // Unique key for the current question

    const isDisabled = submittedQuestions && submittedQuestions[questionKey]; // Check if current question is submitted
  
    return (
        <div className="m-1 p-1 bg-white rounded">
            <div className="flex justify-between items-center m-1 p-1">
                {/* Left side pic div */}
                <div className="m-1 p-1 rounded bg-gray-50">
                    <div className="flex flex-col justify-center items-center h-full">
                        <img
                            src={question?.pic ? question?.pic : learnIcon2}
                            alt=""
                            className="w-56 h-56"
                        />
                    </div>
                </div>

                {/* Right side question and response options div */}
                <div className="m-1 p-1 bg-gray-100 rounded grow">
                    {/* Display question name with safe HTML */}
                    <div className="flex justify-start items-center p-1 m-1">
                    <p className="font-bold text-sm">Q{currentQuestionIndex+1} -</p>
                    <p 
                        className='m-1 p-1 font-poppins text-sm' 
                        dangerouslySetInnerHTML={createMarkup(question?.questionName)}
                    ></p>
                    </div>
                    {question?.questionSubText && (
                      <div className="m-1 p-1 font-poppins max-w-2xl">
                        <p className="text-[11px] text-gray-400">{question?.questionSubText}</p>
                      </div>
                    )}
                    {question?.questionHintText && (
                      <div className="m-1 p-1 font-poppins max-w-2xl">
                        <p className="text-[11px] text-gray-400">{question?.questionHintText}</p>
                      </div>
                    )}

                    {/* Display response options with radio button selection */}
                    {question?.questionResponseOptions?.map((option, optionIndex) => (
                        <div 
                            key={option.responseText + optionIndex} 
                            className="bg-white rounded text-xs m-1 p-1 flex justify-start items-center"
                        >
                            <label className="flex align-center cursor-pointer">
                                <input
                                    type="radio"
                                    name={question.id}
                                    value={option.responseText}
                                    checked={responses[question.id]?.responseText === option.responseText}
                                    onChange={() => handleRadioChange(question.id, option.responseText, option.score)}
                                    className="mr-2"
                                    disabled={isDisabled}
                                />
                                <p>{option.responseText}</p>
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

const EtSectionNav = ({
    sectionName,
    filteredSections,
    currentSectionIndex,
    handleCircleSectionClick,
    submittedSections, // New prop for checking submitted status
}) => {

    if (!filteredSections || !Array.isArray(filteredSections)) {
        // Ensure the array is valid
        return <div>No sections available</div>;
      }

        return (     
            <div>
            <div className="flex w-full justify-start items-center p-5">        
              <div className="mx-1">
                <p className="text-xs">
                  {" "}
                  <span className="font-bold text-gray-400">{sectionName}</span>
                </p>
              </div>
              {filteredSections.map((item, index) => {
                const isSubmitted = submittedSections?.[index] // Safely check submission
                                
                return (
                <div
                  key={index}
                  className={`cursor-pointer flex justify-center items-center rounded-full w-4 h-4 border ${
                    currentSectionIndex === index && !isSubmitted
                      ? "bg-orange-600 border-orange-600 text-white"
                      : isSubmitted
                      ? "bg-green-400 border-green-400"
                      : "border-orange-600"
                  } mx-1`}
                  onClick={() => handleCircleSectionClick(index)}
                >
                  {isSubmitted ? (
                    <CheckCircleIcon className="w-4 h-4 text-white" /> // Checkbox for submitted
                        ) : (
                            <p className="text-[8px] text-center">{index + 1}</p> // Number for non-submitted
                    )}
                </div>
                )})}
                
            </div>
            {/* Show text if current section is submitted */}
                    {submittedSections?.[currentSectionIndex] && (
                        <div className="mx-1 px-1 text-red-600 text-[10px] text-center">
                        You have already submitted your response to this section.
                        </div>
                    )}
            </div>
          );
    
}

const EtQuestionNav = ({
    qSetName,
    filteredQuestions,
    currentQuestionIndex,
    currentSectionIndex,
    handleCircleQuestionClick,
    submittedQuestions, // New prop for checking submitted status
}) => {

    if (!filteredQuestions || !Array.isArray(filteredQuestions)) {
        // Ensure the array is valid
        return <div>No questions available</div>;
      }

        return (
            
            <div>
            <div className="flex w-full justify-start items-center p-5">
                
              <div className="mx-1">
                <p className="text-xs">
                  {" "}
                  <span className="font-bold text-gray-400">{qSetName}</span>
                </p>
              </div>
              {filteredQuestions?.map((item, index) => {
                const isSubmitted = submittedQuestions[`${currentSectionIndex}-${index}`] // Safely check submission
                                
                return (
                <div
                  key={index}
                  className={`cursor-pointer flex justify-center items-center rounded-full w-4 h-4 border ${
                    currentQuestionIndex === index && !isSubmitted
                      ? "bg-orange-600 border-orange-600 text-white"
                      : isSubmitted
                      ? "bg-green-400 border-green-400"
                      : "border-orange-600"
                  } mx-1`}
                  onClick={() => handleCircleQuestionClick(index)}
                >
                  {isSubmitted ? (
                    <CheckCircleIcon className="w-4 h-4 text-white" /> // Checkbox for submitted
                        ) : (
                            <p className="text-[8px] text-center">{index + 1}</p> // Number for non-submitted
                    )}
                </div>
                )})}
                
            </div>
            {/* Show text if current question is submitted */}
                    {submittedQuestions?.[currentQuestionIndex] && (
                        <div className="mx-1 px-1 text-red-600 text-[10px] text-center">
                        You have already submitted your response to this question.
                        </div>
                    )}
            </div>
          );
    
}

const EtQuestionBottomNavControls = ({
    currentQuestionIndex,
    setCurrentQuestionIndex,
    currentSectionIndex,
    filteredQuestions,
    filteredSections,
    handleQuestionSubmit, // Function to submit a single question
    handleSectionSubmit, // Function to submit the section
    handleAssessmentSubmit, // Function to submit the assessment
    submittedQuestions, // State to track submitted questions
    submittedSections, // State to track submitted sections
  }) => {

    // Check if filteredQuestions is valid before accessing its length
    if (!filteredQuestions || filteredQuestions.length === 0) {
    return <div>Questions not loaded or invalid.</div>; // Proper message if invalid
    }
  
    const canNavigatePrev = currentQuestionIndex > 0;
    const canNavigateNext = currentQuestionIndex < filteredQuestions?.length - 1
    const isLastQuestion = currentQuestionIndex === filteredQuestions?.length - 1;
    const isLastSection = currentSectionIndex === filteredSections?.length - 1;
    const isSectionSubmitted = submittedSections[currentSectionIndex]
    
      return (
        <div className="flex justify-between items-center mx-auto my-5">
          <div
            onClick={() => canNavigatePrev && setCurrentQuestionIndex(currentQuestionIndex - 1)}
            className={`flex justify-center items-center m-1 p-1 rounded-full h-5 w-5 ${
                canNavigatePrev ? "bg-orange-600 cursor-pointer" : "bg-gray-300"
              }`}
          >
            <ChevronDoubleLeftIcon className="h-3 w-3 text-white" />
          </div>
    
          {/* Submit button: conditional rendering */}
        {/* Submit Question Button */}
        <div className="flex justify-center items-center">
        <button
          type="button"
          className={`text-white text-xs px-4 py-2 rounded-full ${
            submittedQuestions[`${currentSectionIndex}-${currentQuestionIndex}`]
              ? "bg-gray-300"
              : "bg-orange-600"
          }`}
          onClick={handleQuestionSubmit} // Function to submit the current question
          disabled={submittedQuestions[`${currentSectionIndex}-${currentQuestionIndex}`]}
        >
          Submit Question
        </button>
  
        {/* Conditional Section Submit Button (only on the last question) */}
        {isLastQuestion && (
          <button
            type="button"
            className={` text-white text-xs mx-1 px-4 py-2 rounded-full ${
              submittedSections[`${currentSectionIndex}`]
                ? "bg-gray-300"
                : "bg-black"
            }`}
            onClick={handleSectionSubmit} // Function to submit the section
            disabled={submittedSections[currentSectionIndex]} 
          >
            Submit Section
          </button>
        )}

        {/* Conditional Assessment Submit Button */}
        {isLastSection && isSectionSubmitted && (
          <button
            type="button"
            className="bg-red-600 text-white text-xs mx-1 px-4 py-2 rounded-full"
            onClick={handleAssessmentSubmit} // Function to submit the assessment
          >
            Submit Assessment
          </button>
        )}
        </div>
  
    
        <div
        onClick={() => canNavigateNext && setCurrentQuestionIndex(currentQuestionIndex + 1)}
        className={`flex justify-center items-center m-1 p-1 rounded-full h-5 w-5 ${
          canNavigateNext ? "bg-orange-600 cursor-pointer" : "bg-gray-300"
        }`}
      >
        <ChevronDoubleRightIcon className="h-3 w-3 text-white" />
      </div>
        </div>
      );
  
  }

export default EtCreateUserAssessmentResponseWithScoresComp