
    import React from 'react';
    
    const EtImprintPlansSelfIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" fill="currentColor">
          <g transform="translate(-687 -496)"><path d="M758.724 582.54 751.052 574.868C750.157 574.024 748.762 574.024 747.868 574.868L713.983 608.752C713.106 609.635 713.106 611.059 713.983 611.936 714.861 612.814 716.289 612.814 717.167 611.936L749.46 579.643 755.541 585.723C756.419 586.601 757.847 586.601 758.725 585.723 759.602 584.846 759.602 583.417 758.725 582.54Z" fillRule="evenodd"/><path d="M800.832 560.064 777.815 583.081C776.937 583.959 776.937 585.387 777.815 586.265 778.692 587.142 780.121 587.142 780.999 586.265L804.016 563.248C804.893 562.365 804.893 560.942 804.016 560.064 803.133 559.186 801.71 559.186 800.832 560.064Z" fillRule="evenodd"/><path d="M714 622C714 626.972 709.972 631 705 631 700.028 631 696 626.972 696 622 696 617.028 700.028 613 705 613 709.972 613 714 617.028 714 622" fillRule="evenodd"/><path d="M822 550C822 554.972 817.972 559 813 559 808.028 559 804 554.972 804 550 804 545.028 808.028 541 813 541 817.972 541 822 545.028 822 550" fillRule="evenodd"/><path d="M777 595.496C777 600.468 772.972 604.496 768 604.496 763.028 604.496 759 600.468 759 595.496 759 590.524 763.028 586.496 768 586.496 772.972 586.496 777 590.524 777 595.496" fillRule="evenodd"/><path d="M797.248 543.248C798.491 543.242 799.492 542.241 799.498 540.998L799.498 526.373 819.747 526.373C820.529 526.379 821.255 525.974 821.665 525.304 822.076 524.641 822.11 523.808 821.75 523.111L818.667 516.923 821.75 510.735C822.11 510.038 822.076 509.205 821.665 508.542 821.255 507.872 820.529 507.467 819.747 507.473L799.498 507.473 799.498 507.248C799.498 506.005 798.491 504.998 797.248 504.998 796.005 504.998 794.998 506.005 794.998 507.248L794.998 540.999C795.004 542.242 796.005 543.243 797.248 543.249Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtImprintPlansSelfIcon1SVGComp;
    