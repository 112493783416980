
    import React from 'react';
    
    const EtWabWinIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m1800 1800c208.86 0 409.16-82.969 556.85-230.65 147.68-147.69 230.65-347.99 230.65-556.85s-82.969-409.16-230.65-556.85c-147.69-147.68-347.99-230.65-556.85-230.65s-409.16 82.969-556.85 230.65c-147.68 147.69-230.65 347.99-230.65 556.85 0.22266 208.79 83.262 408.96 230.9 556.6 147.64 147.64 347.81 230.68 556.6 230.9zm0-1462.5c179.02 0 350.71 71.117 477.3 197.7s197.7 298.28 197.7 477.3-71.117 350.71-197.7 477.3-298.28 197.7-477.3 197.7-350.71-71.117-477.3-197.7-197.7-298.28-197.7-477.3c0.19531-178.96 71.371-350.54 197.92-477.08 126.54-126.55 298.12-197.72 477.08-197.92z" fillRule="evenodd"/>
 <path d="m1690.8 1105-43.648 139.27 121.95-80.324c9.1836-6.0469 19.941-9.2695 30.938-9.2695s21.754 3.2227 30.938 9.2695l121.95 80.324-43.648-139.27c-3.5938-11.59-3.3477-24.027 0.71094-35.461 4.0547-11.438 11.695-21.254 21.789-27.988l106.09-71.551-139.22-8.9453c-10.969-0.69922-21.492-4.6016-30.27-11.219-8.7773-6.6172-15.426-15.664-19.117-26.016l-49.219-137.53-48.992 137.53c-3.6953 10.352-10.34 19.398-19.117 26.016-8.7773 6.6172-19.301 10.52-30.273 11.219l-139.22 8.9453 106.09 71.551h0.003906c10.051 6.7656 17.648 16.598 21.664 28.027 4.0117 11.434 4.2266 23.855 0.60938 35.422z" fillRule="evenodd"/>
 <path d="m1800 1575c149.18 0 292.26-59.262 397.75-164.75 105.49-105.49 164.75-248.56 164.75-397.75s-59.262-292.26-164.75-397.75c-105.49-105.49-248.56-164.75-397.75-164.75s-292.26 59.262-397.75 164.75c-105.49 105.49-164.75 248.56-164.75 397.75 0.16406 149.13 59.48 292.11 164.93 397.57s248.43 164.77 397.57 164.93zm-409.45-706.61 266.91-17.156 89.551-251.38v0.003906c5.2266-14.672 16.305-26.52 30.59-32.723 14.289-6.1992 30.508-6.1992 44.797 0 14.285 6.2031 25.363 18.051 30.59 32.723l89.551 251.38 266.91 17.156v-0.003906c15.73 1.0039 30.32 8.5664 40.211 20.84 9.8945 12.277 14.18 28.141 11.816 43.727-2.3594 15.586-11.156 29.469-24.242 38.258l-208.12 140.23 78.246 249.81c4.5781 14.812 2.8359 30.863-4.8086 44.352-7.6484 13.488-20.523 23.223-35.586 26.902-15.062 3.6797-30.977 0.97656-43.98-7.4648l-222.98-146.7-222.75 146.76c-13.004 8.4453-28.918 11.145-43.98 7.4648-15.062-3.6797-27.941-13.414-35.586-26.902-7.6484-13.488-9.3867-29.535-4.8086-44.352l78.242-249.86-208.12-140.23c-13.082-8.7891-21.879-22.672-24.242-38.258s1.9258-31.449 11.816-43.727c9.8906-12.273 24.48-19.836 40.215-20.84z" fillRule="evenodd"/>
 <path d="m1968.8 2362.5c8.1641-167.16 40.762-332.22 96.75-489.94-173 53.25-358 53.25-531 0 55.988 157.71 88.586 322.78 96.75 489.94 0 173.25-91.688 433.12-141.75 562.5h621c-50.062-129.38-141.75-389.25-141.75-562.5zm-112.5 281.25h-112.5v-112.5h112.5zm0-225h-112.5v-112.5h112.5zm0-225h-112.5v-112.5h112.5z" fillRule="evenodd"/>
 <path d="m2362.5 3173.1-135.56-135.56h-853.88l-135.56 135.56v201.94h1125z" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtWabWinIcon1SVGComp;
    