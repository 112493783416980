import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const userImprintCapabilitiesSalessAdapter = createEntityAdapter({})

const initialState = userImprintCapabilitiesSalessAdapter.getInitialState()

export const userImprintCapabilitiesSalessApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserImprintCapabilitiesSaless: builder.query({
            query: () => ({
                url: '/userImprintCapabilitiesSaless/getUserImprintCapabilitiesSaless',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserImprintCapabilitiesSaless = responseData.map(userImprintCapabilitiesSales => {
                    userImprintCapabilitiesSales.id = userImprintCapabilitiesSales._id
                    return userImprintCapabilitiesSales
                })
                return userImprintCapabilitiesSalessAdapter.setAll(initialState, loadedUserImprintCapabilitiesSaless)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserImprintCapabilitiesSales', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserImprintCapabilitiesSales', id}))
                    ]
                } else {
                    return [ { type: 'UserImprintCapabilitiesSales', id: 'LIST'}]
                }
            },
        }),
        createNewUserImprintCapabilitiesSales: builder.mutation({
            query: initialUserImprintCapabilitiesSales => ({
                url: '/userImprintCapabilitiesSaless/createNewUserImprintCapabilitiesSales',
                method: 'POST',
                body: {...initialUserImprintCapabilitiesSales,}
            }),
            invalidatesTags: [
                { type: 'UserImprintCapabilitiesSales', id: "LIST" }
            ] 
        }),
        updateUserImprintCapabilitiesSales: builder.mutation({
            query: initialUserImprintCapabilitiesSales => ({
                url: '/userImprintCapabilitiesSaless/updateUserImprintCapabilitiesSales',
                method: 'PATCH',
                body: {...initialUserImprintCapabilitiesSales,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserImprintCapabilitiesSales', id: arg.id },
                { type: 'UserImprintCapabilitiesSales', id: 'LIST' }
            ],
        }),
        deleteUserImprintCapabilitiesSales: builder.mutation({
            query: ({id}) => ({
                url: '/userImprintCapabilitiesSaless/deleteUserImprintCapabilitiesSales',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserImprintCapabilitiesSales', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserImprintCapabilitiesSalessQuery,
    useCreateNewUserImprintCapabilitiesSalesMutation,
    useUpdateUserImprintCapabilitiesSalesMutation,
    useDeleteUserImprintCapabilitiesSalesMutation,
} = userImprintCapabilitiesSalessApiSlice

// returns the query result object
export const selectUserImprintCapabilitiesSalesResult = userImprintCapabilitiesSalessApiSlice.endpoints.getUserImprintCapabilitiesSaless.select()

// create memoized selector
const selectUserImprintCapabilitiesSalessData = createSelector(
    selectUserImprintCapabilitiesSalesResult,
    userImprintCapabilitiesSalesResult => userImprintCapabilitiesSalesResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserImprintCapabilitiesSaless,
    selectById: selectUserImprintCapabilitiesSalesById,
    selectIds: selectUserImprintCapabilitiesSalesIds,
} = userImprintCapabilitiesSalessAdapter.getSelectors(state => selectUserImprintCapabilitiesSalessData(state) ?? initialState)