import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const missionMetricUoMTypesAdapter = createEntityAdapter({})

const initialState = missionMetricUoMTypesAdapter.getInitialState()

export const missionMetricUoMTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getMissionMetricUoMTypes: builder.query({
            query: () => ({
                url: '/missionMetricUoMTypes/getMissionMetricUoMTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedMissionMetricUoMTypes = responseData.map(missionMetricUoMType => {
                    missionMetricUoMType.id = missionMetricUoMType._id
                    return missionMetricUoMType
                })
                return missionMetricUoMTypesAdapter.setAll(initialState, loadedMissionMetricUoMTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'MissionMetricUoMType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'MissionMetricUoMType', id}))
                    ]
                } else {
                    return [ { type: 'MissionMetricUoMType', id: 'LIST'}]
                }
            },
        }),
        createNewMissionMetricUoMType: builder.mutation({
            query: initialMissionMetricUoMType => ({
                url: '/missionMetricUoMTypes/createNewMissionMetricUoMType',
                method: 'POST',
                body: {...initialMissionMetricUoMType,}
            }),
            invalidatesTags: [
                { type: 'MissionMetricUoMType', id: "LIST" }
            ] 
        }),
        updateMissionMetricUoMType: builder.mutation({
            query: initialMissionMetricUoMType => ({
                url: '/missionMetricUoMTypes/updateMissionMetricUoMType',
                method: 'PATCH',
                body: {...initialMissionMetricUoMType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'MissionMetricUoMType', id: arg.id },
                { type: 'MissionMetricUoMType', id: 'LIST' }
            ],
        }),
        deleteMissionMetricUoMType: builder.mutation({
            query: ({id}) => ({
                url: '/missionMetricUoMTypes/deleteMissionMetricUoMType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'MissionMetricUoMType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetMissionMetricUoMTypesQuery,
    useCreateNewMissionMetricUoMTypeMutation,
    useUpdateMissionMetricUoMTypeMutation,
    useDeleteMissionMetricUoMTypeMutation,
} = missionMetricUoMTypesApiSlice

// returns the query result object
export const selectMissionMetricUoMTypeResult = missionMetricUoMTypesApiSlice.endpoints.getMissionMetricUoMTypes.select()

// create memoized selector
const selectMissionMetricUoMTypesData = createSelector(
    selectMissionMetricUoMTypeResult,
    missionMetricUoMTypeResult => missionMetricUoMTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllMissionMetricUoMTypes,
    selectById: selectMissionMetricUoMTypeById,
    selectIds: selectMissionMetricUoMTypeIds,
} = missionMetricUoMTypesAdapter.getSelectors(state => selectMissionMetricUoMTypesData(state) ?? initialState)