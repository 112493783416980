import React from "react";

export const modules = {
  toolbar: {
    container: "#toolbar",
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};

export const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "align",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];

export const EtEditorToolbarLight = ({ id }) => (
  <div id={id}>
    <span className="ql-formats">
      <select className="ql-header" defaultValue="3">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
      <select className="ql-color"></select> 
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
      {/* <select className="ql-color" /> */}
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
      {/* <button className="ql-image" /> */}
    </span>
  </div>
);

export default EtEditorToolbarLight;