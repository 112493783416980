import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import {
  PlusIcon,
  MinusIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline"
import { ClockIcon, BuildingOffice2Icon,ArrowPathIcon, CalendarDaysIcon, Squares2X2Icon, ArrowTrendingUpIcon, InboxArrowDownIcon, UserIcon, ArrowDownCircleIcon, ArrowUpCircleIcon, ClipboardDocumentListIcon  } from "@heroicons/react/24/solid"

import { useGetUserImprintHiddenMagicsQuery } from "../userImprintHiddenMagicsApiSlice"

import EtModalCreateUserImprintHiddenMagic from "@/components/common/modal/EtModalCreateUserImprintHiddenMagic"
import EtModalUpdateUserImprintHiddenMagic from "@/components/common/modal/EtModalUpdateUserImprintHiddenMagic"

// 23 Dec 2024
import EtHmUserAspirationsIcon1SVGComp from '@/assets/img/icons/svgComp/EtHmUserAspirationsIcon1SVGComp'
import EtHmUserInspirationsIcon1SVGComp from '@/assets/img/icons/svgComp/EtHmUserInspirationsIcon1SVGComp'
import EtHmUserMagicMomentsIcon1SVGComp from '@/assets/img/icons/svgComp/EtHmUserMagicMomentsIcon1SVGComp'
import EtHmUserSuperPowersIcon1SVGComp from '@/assets/img/icons/svgComp/EtHmUserSuperPowersIcon1SVGComp'

// 24 Dec 2024
import EtHmUserAspirationsIcon2SVGComp from '@/assets/img/icons/svgComp/EtHmUserAspirationsIcon2SVGComp'
import EtHmUserInspirationsIcon3SVGComp from '@/assets/img/icons/svgComp/EtHmUserInspirationsIcon3SVGComp'
import EtHmUserMagicMomentsIcon2SVGComp from '@/assets/img/icons/svgComp/EtHmUserMagicMomentsIcon2SVGComp'
import EtHmUserSuperPowersIcon2SVGComp from '@/assets/img/icons/svgComp/EtHmUserSuperPowersIcon2SVGComp'

// 25 Dec 2024
import EtEtTrinityIconSVGComp from '@/assets/img/icons/svgComp/EtEtTrinityIcon5SVGComp'
import EtHmUserSelfNotesIconSVGComp from '@/assets/img/icons/svgComp/EtHmUserSelfNotesIcon1SVGComp'
import EtHmUserSelfNotesIcon3SVGComp from '@/assets/img/icons/svgComp/EtHmUserSelfNotesIcon3SVGComp'

import { createSvgComponentFromBase64 } from '@/utils/createSvgComponentFromBase64'

import {
    formatDateToMonthYear,
    formatDateToYear,
    formatDateTime,
    formatShortDate,
    calculateYearsAndMonths,
  } from "@/utils/formatDate"
  
  import { getTimeSince } from "@/utils/EtGetTimeSince"

const EtImprintHiddenMagic = ({ userId, userInfo }) => {
    // First Created - 22 Dec 2024
    // Author - Atul Pandey
    // Updated - 24 Dec 2024 - refined and detailed each sub section - aspirations, inspirations


    // 1a - State for Modal -- 22 Dec 2024
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
    const [createTriggeringUrl, setCreateTriggeringUrl] = useState(null)
    const [updateTriggeringUrl, setUpdateTriggeringUrl] = useState(null)

    // 1b - State for JSX Display - 22 Dec 2024
    const [ showAspirationSection, setShowAspirationSection ] = useState(false)
    const [ showInspirationSection, setShowInspirationSection ] = useState(false)
    const [ showMagicMomentsSection, setShowMagicMomentsSection ] = useState(false)
    const [ showSuperPowersSection, setShowSuperPowersSection ] = useState(false)
    const [ activeTab, setActiveTab] = useState('Summary') 
    const [ selectedHiddenMagicId, setSelectedHiddenMagicId] = useState(null);

    const [ showAspirationDetails,setShowAspirationDetails ] = useState({}) // 24 Dec 2024
    const [ showAspirationNoteSection,setShowAspirationNoteSection ] = useState({}) // 24 Dec 2024
    const [ showAspirationNoteDetails,setShowAspirationNoteDetails ] = useState({}) // 24 Dec 2024
    
    const [ showInspirationDetails,setShowInspirationDetails ] = useState({}) // 24 Dec 2024
    const [ showInspirationNoteSection,setShowInspirationNoteSection ] = useState({}) // 24 Dec 2024
    const [ showInspirationNoteDetails,setShowInspirationNoteDetails ] = useState({}) // 24 Dec 2024
    
    const [ showMagicMomentDetails,setShowMagicMomentDetails ] = useState({}) // 24 Dec 2024
    const [ showMagicMomentNoteSection,setShowMagicMomentNoteSection ] = useState({}) // 24 Dec 2024
    const [ showMagicMomentNoteDetails,setShowMagicMomentNoteDetails ] = useState({}) // 24 Dec 2024
    
    const [ showSuperPowerDetails,setShowSuperPowerDetails ] = useState({}) // 24 Dec 2024
    const [ showSuperPowerNoteSection,setShowSuperPowerNoteSection ] = useState({}) // 24 Dec 2024
    const [ showSuperPowerNoteDetails,setShowSuperPowerNoteDetails ] = useState({}) // 24 Dec 2024

    const [ showSelfNotesSection, setShowSelfNotesSection ] = useState(false)// 24 Dec 2024
    const [ showSelfNoteDetails,setShowSelfNoteDetails ] = useState({}) // 24 Dec 2024
    

    // 2a - Extract needed data from the incoming props
    const userOrgId = userInfo[0]?.userOrgId._id || null
    // console.log('userOrgId', userOrgId)

    // 2b - Get UserImprintHiddenMagic Data - 09 Dec 2024
    const { data: userImprintHiddenMagicData } = useGetUserImprintHiddenMagicsQuery()
      const userImprintHiddenMagics = userImprintHiddenMagicData?.ids
        ?.map((id) => userImprintHiddenMagicData?.entities[id])
        ?.filter(
          (item) => item.userId?._id === userId && item.userOrgId._id === userOrgId
        )
      console.log("userImprintHiddenMagics", userImprintHiddenMagics)

    // 3a - Handle Create User Imprint HiddenMagic
    const handleCreateUserImprintHiddenMagic = () => {
        const currentUrl = window.location.pathname
        setIsCreateModalOpen(true)
        setCreateTriggeringUrl(currentUrl)
    }

    const handleCloseCreateModal = () => {
        setIsCreateModalOpen(false)
        setCreateTriggeringUrl(null)
        // window.location.reload();
    }

    // 3b - Handle Update User Imprint HiddenMagic
    const handleUpdateUserImprintHiddenMagic = (id) => {
        const currentUrl = window.location.pathname
        setIsUpdateModalOpen(true)
        setUpdateTriggeringUrl(currentUrl)
        setSelectedHiddenMagicId(id)
        // console.log('clicked hiddenMagic id', id)
        // console.log('selectedHiddenMagicId', selectedHiddenMagicId)
    }

    const handleCloseUpdateModal = () => {
        setIsUpdateModalOpen(false)
        setUpdateTriggeringUrl(null)
        // window.location.reload();
    }

    // 4a - Toggle Tabs -- 10 Dec 2024
    const handleTabSwitch = (tab) => {
        setActiveTab(tab);
    };

    // 4b1 - Toggle Show Aspiration Details -- 24 Dec 2024
    const handleToggleShowAspirationDetails = (aspId) => {
        setShowAspirationDetails((prev) => ({
            ...prev,
            [aspId]: !prev[aspId],
          }))
    }

    // 4b2 - Toggle Show Aspiration Note Section -- 24 Dec 2024
    const handleToggleShowAspirationNoteSection = (noteId) => {
        setShowAspirationNoteSection((prev) => ({
            ...prev,
            [noteId]: !prev[noteId],
          }))
    }

    // 4b3 - Toggle Show Aspiration Note Details -- 24 Dec 2024
    const handleToggleShowAspirationNoteDetails = (noteId) => {
        setShowAspirationNoteDetails((prev) => ({
            ...prev,
            [noteId]: !prev[noteId],
          }))
    }

    // 4c1 - Toggle Show Inspiration Details -- 24 Dec 2024
    const handleToggleShowInspirationDetails = (inspId) => {
        setShowInspirationDetails((prev) => ({
            ...prev,
            [inspId]: !prev[inspId],
          }))
    }

    // 4c2 - Toggle Show Inspiration Note Section -- 24 Dec 2024
    const handleToggleShowInspirationNoteSection = (noteId) => {
        setShowInspirationNoteSection((prev) => ({
            ...prev,
            [noteId]: !prev[noteId],
          }))
    }

    // 4c3 - Toggle Show Inspiration Note Details -- 24 Dec 2024
    const handleToggleShowInspirationNoteDetails = (noteId) => {
        setShowInspirationNoteDetails((prev) => ({
            ...prev,
            [noteId]: !prev[noteId],
          }))
    }

    // 4d1 - Toggle Show MagicMoment Details -- 24 Dec 2024
    const handleToggleShowMagicMomentDetails = (inspId) => {
        setShowMagicMomentDetails((prev) => ({
            ...prev,
            [inspId]: !prev[inspId],
          }))
    }

    // 4d2 - Toggle Show MagicMoment Note Section -- 24 Dec 2024
    const handleToggleShowMagicMomentNoteSection = (noteId) => {
        setShowMagicMomentNoteSection((prev) => ({
            ...prev,
            [noteId]: !prev[noteId],
          }))
    }

    // 4d3 - Toggle Show MagicMoment Note Details -- 24 Dec 2024
    const handleToggleShowMagicMomentNoteDetails = (noteId) => {
        setShowMagicMomentNoteDetails((prev) => ({
            ...prev,
            [noteId]: !prev[noteId],
          }))
    }

    // 4e1 - Toggle Show SuperPower Details -- 24 Dec 2024
    const handleToggleShowSuperPowerDetails = (inspId) => {
        setShowSuperPowerDetails((prev) => ({
            ...prev,
            [inspId]: !prev[inspId],
          }))
    }

     // 4e2 - Toggle Show SuperPower Note Section -- 24 Dec 2024
     const handleToggleShowSuperPowerNoteSection = (noteId) => {
        setShowSuperPowerNoteSection((prev) => ({
            ...prev,
            [noteId]: !prev[noteId],
          }))
    }

    // 4e3 - Toggle Show SuperPower Note Details -- 24 Dec 2024
    const handleToggleShowSuperPowerNoteDetails = (noteId) => {
        setShowSuperPowerNoteDetails((prev) => ({
            ...prev,
            [noteId]: !prev[noteId],
          }))
    }

    // 4f - Toggle Show Self Note Details -- 24 Dec 2024
    const handleToggleShowSelfNoteDetails = (noteId) => {
        setShowSelfNoteDetails((prev) => ({
            ...prev,
            [noteId]: !prev[noteId],
          }))
    }

    // 5 - Function to get the appropriate color class based on section score
    const getColorForScore = (score) => {
    if (score >= 0 && score < 0.9) return "bg-etpx-0"
    if (score >= 0.9 && score < 1.9) return "bg-etpx-1"
    if (score >= 1.9 && score < 2.9) return "bg-etpx-2"
    if (score >= 2.9 && score < 3.9) return "bg-etpx-3"
    if (score >= 3.9 && score < 4.9) return "bg-etpx-4"
    return "bg-etpx-5" // For score >= 4.9
    }

    // 6 - create Markup
    const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
    }

    return (
        <div className="m-1 p-1 border rounded">
            {userImprintHiddenMagics?.length === 0 && (
                <div className="flex justify-between items-center">
                    <div>
                    <p className="m-1 p-1 text-xs">
                        There is no Hidden Magic record for you yet! Change that by
                        clicking the Add button.
                    </p>
                    </div>
                    <div className="flex justify-start items-center">
                    <p className="text-xs font-bold mx-1 px-1">Add</p>
                    <button
                        type="button"
                        onClick={handleCreateUserImprintHiddenMagic}
                        className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                    >
                        <PlusIcon className="h-3 w-3" />
                    </button>
                    </div>
                    <EtModalCreateUserImprintHiddenMagic
                        isOpen={isCreateModalOpen}
                        userId={userId}
                        userOrgId={userOrgId}
                        triggeringUrl={createTriggeringUrl}
                        onClose={handleCloseCreateModal}
                    />
                </div>
            )}
            {userImprintHiddenMagics?.length > 0 && userImprintHiddenMagics?.map((magic, magicIndex) => {
                
                const headline = magic?.userImprintHiddenMagicName || ""
                const overview = magic?.userImprintHiddenMagicDesc || ""
                const aspirations = magic?.userAspirations || []
                const inspirations = magic?.userInspirations || []
                const magicMoments = magic?.userMagicMoments || []
                const superPowers = magic?.userSuperPowers || []
                const selfNotes = magic?.userHiddenMagicSelfNotes || [] // 25 Dec 2024
                const createdAt = magic?.createdAt || ""
                
                return(
                    <div key={magic._id+magicIndex}>
                        <div className="m-1 p-1 border-b">
                            <div className="flex justify-between items-center">
                                <p className="m-1 p-1 text-xs text-orange-600 font-bold">{headline}</p>
                                <div
                                onClick={() => handleUpdateUserImprintHiddenMagic(magic._id)}
                                className="cursor-pointer"
                                >
                                    <PencilSquareIcon className="h-3 w-3 text-orange-600 "/>
                                </div>
                            </div>
                            <div><p dangerouslySetInnerHTML={createMarkup(overview)} className="text-xs leading-4 text-gray-600 m-1 p-1"></p></div>
                        </div>
                        <div className="m-1 p-1 bg-gray-100 rounded">
                           <div className="grid grid-cols-10 justify-between items-center">
                                <div className="col-span-3 flex justify-start items-center m-1 p-1">
                                    <div
                                        onClick={() => setShowAspirationSection(prev => !prev)}
                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                        >
                                        {showAspirationSection ? (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                        )}
                                    </div>
                                    <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtHmUserAspirationsIcon1SVGComp className='h-8 w-8 text-orange-600'/></div>
                                    <div>
                                        <p className="font-bold mx-1 px-1">My Aspirations</p>
                                        <p className="text-[10px] italic text-gray-600 mx-1 px-1">what I dream to achieve in my journey ahead...</p>
                                    </div>  
                                </div>
                                <div className="col-span-1 flex justify-center items-center m-1 p-1">
                                    <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{aspirations?.length}</p></div>
                                </div>
                                {/* <div className="col-span-6 flex justify-end items-center m-1 p-1">
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 border border-white bg-gradient-to-b from-orange-400 to-orange-700 via-orange-800 cursor-pointer shadow-lg shadow-black">
                                        <EtEtTrinityIconSVGComp className='h-6 w-6 text-white pb-1'/>
                                    </div>
                                </div>  */}
                                {/* <div className="col-span-6 flex justify-end items-center m-1 p-1">
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 bg-orange-600 border border-orange-600 cursor-pointer">
                                        <EtEtTrinityIconSVGComp className='h-6 w-6 text-white pb-1'/>
                                    </div>
                                </div>  */}
                            </div>
                            {aspirations?.length>0 && showAspirationSection && (
                                <div className="bg-white m-1 p-1 rounded">
                                    {aspirations?.map((asp, aspIndex)=>{
                                        const aspirationName = asp?.aspirationName || ""
                                        const aspirationDesc = asp?.aspirationDesc || ""
                                        const aspDate = asp?.aspirationDate || ""
                                        const aspUpdateDate = asp?.aspirationUpdateDate || ""
                                        const aspNotes = asp?.aspirationNotes || []

                                        return(
                                            <div key={asp._id+aspIndex} className="bg-gray-100 rounded border m-1 p-1">
                                                <div className="grid grid-cols-12 justify-start items-center m-1 p-1">
                                                    <div className="col-span-2 flex justify-start items-center">
                                                        <div
                                                            onClick={() => handleToggleShowAspirationDetails(asp._id)}
                                                            className="mx-2 cursor-pointer rounded-full h-4 w-4 flex justify-center items-center hover:bg-white"
                                                            >
                                                            {showAspirationDetails[asp._id] ? (
                                                                <MinusIcon className="h-3 w-3 text-orange-600" />
                                                            ) : (
                                                                <PlusIcon className="h-3 w-3 text-orange-600" />
                                                            )}
                                                        </div>
                                                        <div>
                                                            <p className="text-gray-600 font-bold mx-1 px-1">{aspIndex +1}.</p>
                                                        </div>
                                                        <div className="">
                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                                {getTimeSince(aspUpdateDate)}
                                                            </p>
                                                            <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-7 flex justify-start items-center">
                                                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtHmUserAspirationsIcon2SVGComp className='h-8 w-8 text-orange-600'/></div>
                                                        <div><p className="text-xs mx-1 px-1">{aspirationName}</p></div>
                                                    </div>
                                                    <div className="col-span-3 flex justify-start items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(aspDate)}</p>   
                                                    </div>
                                                </div>
                                                {showAspirationDetails[asp._id] && (
                                                    <div className="bg-white rounded m-1 p-1">
                                                        <div className="border-b">
                                                            <p className="text-xs text-orange-600 font-bold m-1 p-1">Description</p>
                                                            <p dangerouslySetInnerHTML={createMarkup(aspirationDesc)} className="m-1 p-1 leading-4 text-xs text-gray-600"></p>
                                                        </div>
                                                        <div className="bg-gray-200 m-1 p-1 rounded">
                                                            <div className="grid grid-cols-10 justify-start items-center">
                                                            <div className="col-span-3 flex justify-start items-center m-1 p-1">
                                                                <div
                                                                    onClick={() =>handleToggleShowAspirationNoteSection(asp._id)}
                                                                    className="mx-2 cursor-pointer rounded-full h-4 w-4 flex justify-center items-center hover:bg-white"
                                                                    >
                                                                    {showAspirationNoteSection[asp._id] ? (
                                                                        <MinusIcon className="h-3 w-3 text-orange-600" />
                                                                    ) : (
                                                                        <PlusIcon className="h-3 w-3 text-orange-600" />
                                                                    )}
                                                                </div>
                                                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" ><ClipboardDocumentListIcon className="h-6 w-6 text-orange-600" /></div>
                                                                <div>
                                                                    <p className="font-bold mx-1 px-1 text-xs">Notes</p>
                                                                    <p className="text-[10px] italic text-gray-600 mx-1 px-1">my musings about this aspiration...</p>
                                                                </div>  
                                                            </div>
                                                            <div className="col-span-1 flex justify-center items-center m-1 p-1">
                                                                <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{aspNotes?.length}</p></div>
                                                            </div>

                                                            </div>
                                                            {aspNotes?.length>0 && showAspirationNoteSection[asp._id] && (
                                                                <div className="bg-gray-50 rounded m-1 p-1">
                                                                    {aspNotes?.map((aspNote, aspNoteIndex) => {
                                                                        const note = aspNote?.aspirationNoteText || ""
                                                                        const noteDate = aspNote?.aspirationNoteDate || ""
                                                                        const noteUpdateDate = aspNote?.aspirationNoteUpdateDate || ""

                                                                        return(
                                                                            <div key={aspNote._id+aspNoteIndex} className="border rounded m-1 p-1">
                                                                                <div className="grid grid-cols-12 justify-start items-center m-1 p-1">
                                                                                    <div className="col-span-2 flex justify-start items-center">
                                                                                        <div
                                                                                            onClick={() =>handleToggleShowAspirationNoteDetails(aspNote._id)}
                                                                                            className="mx-2 cursor-pointer rounded-full h-4 w-4 flex justify-center items-center hover:bg-white"
                                                                                            >
                                                                                            {showAspirationNoteDetails[aspNote._id] ? (
                                                                                                <MinusIcon className="h-3 w-3 text-orange-600" />
                                                                                            ) : (
                                                                                                <PlusIcon className="h-3 w-3 text-orange-600" />
                                                                                            )}
                                                                                        </div>
                                                                                        <div>
                                                                                            <p className="text-gray-600 font-bold mx-1 px-1">{aspNoteIndex +1}.</p>
                                                                                        </div>
                                                                                        <div className="">
                                                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                                                                {getTimeSince(noteUpdateDate)}
                                                                                            </p>
                                                                                            <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-span-6 flex justify-start items-center">
                                                                                        <div
                                                                                        dangerouslySetInnerHTML={createMarkup(note)}
                                                                                        className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                                                        ></div>
                                                                                    </div>
                                                                                    <div className="col-span-3 flex justify-end items-center mx-2">
                                                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                                                        <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(noteDate)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                {showAspirationNoteDetails[aspNote._id] && (
                                                                                    <div className="m-1 p-1 rounded bg-gray-100">
                                                                                        <div
                                                                                        dangerouslySetInnerHTML={createMarkup(note)}
                                                                                        className="text-[11px] text-gray-700 m-1 p-1"
                                                                                        ></div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )
                                                                    })}


                                                                </div>
                                                            )}

                                                        </div>

                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}

                                </div>
                            )}
                        </div>   
                        <div className="m-1 p-1 bg-gray-100 rounded">
                            <div className="grid grid-cols-10 justify-between items-center">
                                <div className="col-span-3 flex justify-start items-center m-1 p-1">
                                    <div
                                        onClick={() => setShowInspirationSection(prev => !prev)}
                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                        >
                                        {showInspirationSection ? (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                        )}
                                    </div>
                                    <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtHmUserInspirationsIcon1SVGComp className='h-8 w-8 text-orange-600'/></div>
                                    <div>
                                        <p className="font-bold mx-1 px-1">My Inspirations</p>
                                        <p className="text-[10px] italic text-gray-600 mx-1 px-1">what keeps me motivated everyday...</p>
                                    </div>  
                                </div>
                                <div className="col-span-1 flex justify-center items-center m-1 p-1">
                                    <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{inspirations?.length}</p></div>
                                </div>
                                {/* <div className="col-span-6 flex justify-end items-center m-1 p-1">
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 border border-white bg-gradient-to-b from-orange-400 to-orange-700 via-orange-800 cursor-pointer shadow-lg shadow-black">
                                        <EtEtTrinityIconSVGComp className='h-6 w-6 text-white pb-1'/>
                                    </div>
                                </div>  */}
                            </div>
                            {inspirations?.length>0 && showInspirationSection && (
                                <div className="bg-white m-1 p-1 rounded">
                                    {inspirations?.map((insp, inspIndex)=>{
                                        const inspirationName = insp?.inspirationName || ""
                                        const inspirationDesc = insp?.inspirationDesc || ""
                                        const inspDate = insp?.inspirationDate || ""
                                        const inspUpdateDate = insp?.inspirationUpdateDate || ""
                                        const inspNotes = insp?.inspirationNotes || []

                                        return(
                                            <div key={insp._id+inspIndex} className="bg-gray-100 rounded border m-1 p-1">
                                                <div className="grid grid-cols-12 justify-start items-center m-1 p-1">
                                                    <div className="col-span-2 flex justify-start items-center">
                                                        <div
                                                            onClick={() => handleToggleShowInspirationDetails(insp._id)}
                                                            className="mx-2 cursor-pointer rounded-full h-4 w-4 flex justify-center items-center hover:bg-white"
                                                            >
                                                            {showInspirationDetails[insp._id] ? (
                                                                <MinusIcon className="h-3 w-3 text-orange-600" />
                                                            ) : (
                                                                <PlusIcon className="h-3 w-3 text-orange-600" />
                                                            )}
                                                        </div>
                                                        <div>
                                                            <p className="text-gray-600 font-bold mx-1 px-1">{inspIndex +1}.</p>
                                                        </div>
                                                        <div className="">
                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                                {getTimeSince(inspUpdateDate)}
                                                            </p>
                                                            <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-7 flex justify-start items-center">
                                                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtHmUserInspirationsIcon3SVGComp className='h-8 w-8 text-orange-600'/></div>
                                                        <div><p className="text-xs mx-1 px-1">{inspirationName}</p></div>
                                                    </div>
                                                    <div className="col-span-3 flex justify-start items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(inspDate)}</p>   
                                                    </div>
                                                </div>
                                                {showInspirationDetails[insp._id] && (
                                                    <div className="bg-white rounded m-1 p-1">
                                                        <div className="border-b">
                                                            <p className="text-xs text-orange-600 font-bold m-1 p-1">Description</p>
                                                            <p dangerouslySetInnerHTML={createMarkup(inspirationDesc)} className="m-1 p-1 leading-4 text-xs text-gray-600"></p>
                                                        </div>
                                                        <div className="bg-gray-200 m-1 p-1 rounded">
                                                            <div className="grid grid-cols-10 justify-start items-center">
                                                            <div className="col-span-3 flex justify-start items-center m-1 p-1">
                                                                <div
                                                                    onClick={() =>handleToggleShowInspirationNoteSection(insp._id)}
                                                                    className="mx-2 cursor-pointer rounded-full h-4 w-4 flex justify-center items-center hover:bg-white"
                                                                    >
                                                                    {showInspirationNoteSection[insp._id] ? (
                                                                        <MinusIcon className="h-3 w-3 text-orange-600" />
                                                                    ) : (
                                                                        <PlusIcon className="h-3 w-3 text-orange-600" />
                                                                    )}
                                                                </div>
                                                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" ><ClipboardDocumentListIcon className="h-6 w-6 text-orange-600" /></div>
                                                                <div>
                                                                    <p className="font-bold mx-1 px-1 text-xs">Notes</p>
                                                                    <p className="text-[10px] italic text-gray-600 mx-1 px-1">my musings about this inspiration...</p>
                                                                </div>  
                                                            </div>
                                                            <div className="col-span-1 flex justify-center items-center m-1 p-1">
                                                                <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{inspNotes?.length}</p></div>
                                                            </div>

                                                            </div>
                                                            {inspNotes?.length>0 && showInspirationNoteSection[insp._id] && (
                                                                <div className="bg-gray-50 rounded m-1 p-1">
                                                                    {inspNotes?.map((inspNote, inspNoteIndex) => {
                                                                        const note = inspNote?.inspirationNoteText || ""
                                                                        const noteDate = inspNote?.inspirationNoteDate || ""
                                                                        const noteUpdateDate = inspNote?.inspirationNoteUpdateDate || ""

                                                                        return(
                                                                            <div key={inspNote._id+inspNoteIndex} className="border rounded m-1 p-1">
                                                                                <div className="grid grid-cols-12 justify-start items-center m-1 p-1">
                                                                                    <div className="col-span-2 flex justify-start items-center">
                                                                                        <div
                                                                                            onClick={() =>handleToggleShowInspirationNoteDetails(inspNote._id)}
                                                                                            className="mx-2 cursor-pointer rounded-full h-4 w-4 flex justify-center items-center hover:bg-white"
                                                                                            >
                                                                                            {showInspirationNoteDetails[inspNote._id] ? (
                                                                                                <MinusIcon className="h-3 w-3 text-orange-600" />
                                                                                            ) : (
                                                                                                <PlusIcon className="h-3 w-3 text-orange-600" />
                                                                                            )}
                                                                                        </div>
                                                                                        <div>
                                                                                            <p className="text-gray-600 font-bold mx-1 px-1">{inspNoteIndex +1}.</p>
                                                                                        </div>
                                                                                        <div className="">
                                                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                                                                {getTimeSince(noteUpdateDate)}
                                                                                            </p>
                                                                                            <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-span-6 flex justify-start items-center">
                                                                                        <div
                                                                                        dangerouslySetInnerHTML={createMarkup(note)}
                                                                                        className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                                                        ></div>
                                                                                    </div>
                                                                                    <div className="col-span-3 flex justify-end items-center mx-2">
                                                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                                                        <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(noteDate)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                {showInspirationNoteDetails[inspNote._id] && (
                                                                                    <div className="m-1 p-1 rounded bg-gray-100">
                                                                                        <div
                                                                                        dangerouslySetInnerHTML={createMarkup(note)}
                                                                                        className="text-[11px] text-gray-700 m-1 p-1"
                                                                                        ></div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )
                                                                    })}


                                                                </div>
                                                            )}

                                                        </div>

                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}

                                </div>
                            )}
                        </div>   
                        <div className="m-1 p-1 bg-gray-100 rounded">
                            <div className="grid grid-cols-10 justify-between items-center">
                                <div className="col-span-3 flex justify-start items-center m-1 p-1">
                                    <div
                                        onClick={() => setShowMagicMomentsSection(prev => !prev)}
                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                        >
                                        {showMagicMomentsSection ? (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                        )}
                                    </div>
                                    <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtHmUserMagicMomentsIcon1SVGComp className='h-8 w-8 text-orange-600'/></div>
                                    <div>
                                        <p className="font-bold mx-1 px-1">My Magic Moments</p>
                                        <p className="text-[10px] italic text-gray-600 mx-1 px-1">what are some of my most empowering moments...</p>
                                    </div>  
                                </div>
                                <div className="col-span-1 flex justify-center items-center m-1 p-1">
                                    <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{magicMoments?.length}</p></div>
                                </div>
                                {/* <div className="col-span-6 flex justify-end items-center m-1 p-1">
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 border border-white bg-gradient-to-b from-orange-400 to-orange-700 via-orange-800 cursor-pointer shadow-lg shadow-black">
                                        <EtEtTrinityIconSVGComp className='h-6 w-6 text-white pb-1'/>
                                    </div>
                                </div>  */}
                            </div>
                            {magicMoments?.length>0 && showMagicMomentsSection && (
                                <div className="bg-white m-1 p-1 rounded">
                                    {magicMoments?.map((mm, mmIndex)=>{
                                        const magicMomentName = mm?.magicMomentName || ""
                                        const magicMomentDesc = mm?.magicMomentDesc || ""
                                        const mmDate = mm?.magicMomentDate || ""
                                        const mmUpdateDate = mm?.magicMomentUpdateDate || ""
                                        const mmNotes = mm?.magicMomentNotes || []

                                        return(
                                            <div key={mm._id+mmIndex} className="bg-gray-100 rounded border m-1 p-1">
                                                <div className="grid grid-cols-12 justify-start items-center m-1 p-1">
                                                    <div className="col-span-2 flex justify-start items-center">
                                                        <div
                                                            onClick={() => handleToggleShowMagicMomentDetails(mm._id)}
                                                            className="mx-2 cursor-pointer rounded-full h-4 w-4 flex justify-center items-center hover:bg-white"
                                                            >
                                                            {showMagicMomentDetails[mm._id] ? (
                                                                <MinusIcon className="h-3 w-3 text-orange-600" />
                                                            ) : (
                                                                <PlusIcon className="h-3 w-3 text-orange-600" />
                                                            )}
                                                        </div>
                                                        <div>
                                                            <p className="text-gray-600 font-bold mx-1 px-1">{mmIndex +1}.</p>
                                                        </div>
                                                        <div className="">
                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                                {getTimeSince(mmUpdateDate)}
                                                            </p>
                                                            <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-7 flex justify-start items-center">
                                                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtHmUserMagicMomentsIcon2SVGComp className='h-8 w-8 text-orange-600'/></div>
                                                        <div><p className="text-xs mx-1 px-1">{magicMomentName}</p></div>
                                                    </div>
                                                    <div className="col-span-3 flex justify-start items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(mmDate)}</p>   
                                                    </div>
                                                </div>
                                                {showMagicMomentDetails[mm._id] && (
                                                    <div className="bg-white rounded m-1 p-1">
                                                        <div className="border-b">
                                                            <p className="text-xs text-orange-600 font-bold m-1 p-1">Description</p>
                                                            <p dangerouslySetInnerHTML={createMarkup(magicMomentDesc)} className="m-1 p-1 leading-4 text-xs text-gray-600"></p>
                                                        </div>
                                                        <div className="bg-gray-200 m-1 p-1 rounded">
                                                            <div className="grid grid-cols-10 justify-start items-center">
                                                            <div className="col-span-3 flex justify-start items-center m-1 p-1">
                                                                <div
                                                                    onClick={() =>handleToggleShowMagicMomentNoteSection(mm._id)}
                                                                    className="mx-2 cursor-pointer rounded-full h-4 w-4 flex justify-center items-center hover:bg-white"
                                                                    >
                                                                    {showMagicMomentNoteSection[mm._id] ? (
                                                                        <MinusIcon className="h-3 w-3 text-orange-600" />
                                                                    ) : (
                                                                        <PlusIcon className="h-3 w-3 text-orange-600" />
                                                                    )}
                                                                </div>
                                                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" ><ClipboardDocumentListIcon className="h-6 w-6 text-orange-600" /></div>
                                                                <div>
                                                                    <p className="font-bold mx-1 px-1 text-xs">Notes</p>
                                                                    <p className="text-[10px] italic text-gray-600 mx-1 px-1">my musings about this magicMoment...</p>
                                                                </div>  
                                                            </div>
                                                            <div className="col-span-1 flex justify-center items-center m-1 p-1">
                                                                <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{mmNotes?.length}</p></div>
                                                            </div>

                                                            </div>
                                                            {mmNotes?.length>0 && showMagicMomentNoteSection[mm._id] && (
                                                                <div className="bg-gray-50 rounded m-1 p-1">
                                                                    {mmNotes?.map((mmNote, mmNoteIndex) => {
                                                                        const note = mmNote?.magicMomentNoteText || ""
                                                                        const noteDate = mmNote?.magicMomentNoteDate || ""
                                                                        const noteUpdateDate = mmNote?.magicMomentNoteUpdateDate || ""

                                                                        return(
                                                                            <div key={mmNote._id+mmNoteIndex} className="border rounded m-1 p-1">
                                                                                <div className="grid grid-cols-12 justify-start items-center m-1 p-1">
                                                                                    <div className="col-span-2 flex justify-start items-center">
                                                                                        <div
                                                                                            onClick={() =>handleToggleShowMagicMomentNoteDetails(mmNote._id)}
                                                                                            className="mx-2 cursor-pointer rounded-full h-4 w-4 flex justify-center items-center hover:bg-white"
                                                                                            >
                                                                                            {showMagicMomentNoteDetails[mmNote._id] ? (
                                                                                                <MinusIcon className="h-3 w-3 text-orange-600" />
                                                                                            ) : (
                                                                                                <PlusIcon className="h-3 w-3 text-orange-600" />
                                                                                            )}
                                                                                        </div>
                                                                                        <div>
                                                                                            <p className="text-gray-600 font-bold mx-1 px-1">{mmNoteIndex +1}.</p>
                                                                                        </div>
                                                                                        <div className="">
                                                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                                                                {getTimeSince(noteUpdateDate)}
                                                                                            </p>
                                                                                            <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-span-6 flex justify-start items-center">
                                                                                        <div
                                                                                        dangerouslySetInnerHTML={createMarkup(note)}
                                                                                        className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                                                        ></div>
                                                                                    </div>
                                                                                    <div className="col-span-3 flex justify-end items-center mx-2">
                                                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                                                        <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(noteDate)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                {showMagicMomentNoteDetails[mmNote._id] && (
                                                                                    <div className="m-1 p-1 rounded bg-gray-100">
                                                                                        <div
                                                                                        dangerouslySetInnerHTML={createMarkup(note)}
                                                                                        className="text-[11px] text-gray-700 m-1 p-1"
                                                                                        ></div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )
                                                                    })}


                                                                </div>
                                                            )}

                                                        </div>

                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}

                                </div>
                            )}
                        </div>   
                        <div className="m-1 p-1 bg-gray-100 rounded">
                            <div className="grid grid-cols-10 justify-between items-center">
                                <div className="col-span-3 flex justify-start items-center m-1 p-1">
                                    <div
                                        onClick={() => setShowSuperPowersSection(prev => !prev)}
                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                        >
                                        {showSuperPowersSection ? (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                        )}
                                    </div>
                                    <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtHmUserSuperPowersIcon1SVGComp className='h-8 w-8 text-orange-600'/></div>
                                    <div>
                                        <p className="font-bold mx-1 px-1">My Super Powers</p>
                                        <p className="text-[10px] italic text-gray-600 mx-1 px-1">what are my unique strengths...</p>
                                    </div>  
                                </div>
                                <div className="col-span-1 flex justify-center items-center m-1 p-1">
                                    <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{superPowers?.length}</p></div>
                                </div>
                                {/* <div className="col-span-6 flex justify-end items-center m-1 p-1">
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 border border-white bg-gradient-to-b from-orange-400 to-orange-700 via-orange-800 cursor-pointer shadow-lg shadow-black">
                                        <EtEtTrinityIconSVGComp className='h-6 w-6 text-white pb-1'/>
                                    </div>
                                </div>  */}
                            </div>
                            {superPowers?.length>0 && showSuperPowersSection && (
                                <div className="bg-white m-1 p-1 rounded">
                                    {superPowers?.map((sp, spIndex)=>{
                                        const superPowerName = sp?.superPowerName || ""
                                        const superPowerDesc = sp?.superPowerDesc || ""
                                        const spDate = sp?.superPowerDate || ""
                                        const spUpdateDate = sp?.superPowerUpdateDate || ""
                                        const spNotes = sp?.superPowerNotes || []

                                        return(
                                            <div key={sp._id+spIndex} className="bg-gray-100 rounded border m-1 p-1">
                                                <div className="grid grid-cols-12 justify-start items-center m-1 p-1">
                                                    <div className="col-span-2 flex justify-start items-center">
                                                        <div
                                                            onClick={() => handleToggleShowSuperPowerDetails(sp._id)}
                                                            className="mx-2 cursor-pointer rounded-full h-4 w-4 flex justify-center items-center hover:bg-white"
                                                            >
                                                            {showSuperPowerDetails[sp._id] ? (
                                                                <MinusIcon className="h-3 w-3 text-orange-600" />
                                                            ) : (
                                                                <PlusIcon className="h-3 w-3 text-orange-600" />
                                                            )}
                                                        </div>
                                                        <div>
                                                            <p className="text-gray-600 font-bold mx-1 px-1">{spIndex +1}.</p>
                                                        </div>
                                                        <div className="">
                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                                {getTimeSince(spUpdateDate)}
                                                            </p>
                                                            <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-7 flex justify-start items-center">
                                                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtHmUserSuperPowersIcon2SVGComp className='h-8 w-8 text-orange-600'/></div>
                                                        <div><p className="text-xs mx-1 px-1">{superPowerName}</p></div>
                                                    </div>
                                                    <div className="col-span-3 flex justify-start items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(spDate)}</p>   
                                                    </div>
                                                </div>
                                                {showSuperPowerDetails[sp._id] && (
                                                    <div className="bg-white rounded m-1 p-1">
                                                        <div className="border-b">
                                                            <p className="text-xs text-orange-600 font-bold m-1 p-1">Description</p>
                                                            <p dangerouslySetInnerHTML={createMarkup(superPowerDesc)} className="m-1 p-1 leading-4 text-xs text-gray-600"></p>
                                                        </div>
                                                        <div className="bg-gray-200 m-1 p-1 rounded">
                                                            <div className="grid grid-cols-10 justify-start items-center">
                                                            <div className="col-span-3 flex justify-start items-center m-1 p-1">
                                                                <div
                                                                    onClick={() =>handleToggleShowSuperPowerNoteSection(sp._id)}
                                                                    className="mx-2 cursor-pointer rounded-full h-4 w-4 flex justify-center items-center hover:bg-white"
                                                                    >
                                                                    {showSuperPowerNoteSection[sp._id] ? (
                                                                        <MinusIcon className="h-3 w-3 text-orange-600" />
                                                                    ) : (
                                                                        <PlusIcon className="h-3 w-3 text-orange-600" />
                                                                    )}
                                                                </div>
                                                                <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer" ><ClipboardDocumentListIcon className="h-6 w-6 text-orange-600" /></div>
                                                                <div>
                                                                    <p className="font-bold mx-1 px-1 text-xs">Notes</p>
                                                                    <p className="text-[10px] italic text-gray-600 mx-1 px-1">my musings about this super power...</p>
                                                                </div>  
                                                            </div>
                                                            <div className="col-span-1 flex justify-center items-center m-1 p-1">
                                                                <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{spNotes?.length}</p></div>
                                                            </div>

                                                            </div>
                                                            {spNotes?.length>0 && showSuperPowerNoteSection[sp._id] && (
                                                                <div className="bg-gray-50 rounded m-1 p-1">
                                                                    {spNotes?.map((spNote, spNoteIndex) => {
                                                                        const note = spNote?.superPowerNoteText || ""
                                                                        const noteDate = spNote?.superPowerNoteDate || ""
                                                                        const noteUpdateDate = spNote?.superPowerNoteUpdateDate || ""

                                                                        return(
                                                                            <div key={spNote._id+spNoteIndex} className="border rounded m-1 p-1">
                                                                                <div className="grid grid-cols-12 justify-start items-center m-1 p-1">
                                                                                    <div className="col-span-2 flex justify-start items-center">
                                                                                        <div
                                                                                            onClick={() =>handleToggleShowSuperPowerNoteDetails(spNote._id)}
                                                                                            className="mx-2 cursor-pointer rounded-full h-4 w-4 flex justify-center items-center hover:bg-white"
                                                                                            >
                                                                                            {showSuperPowerNoteDetails[spNote._id] ? (
                                                                                                <MinusIcon className="h-3 w-3 text-orange-600" />
                                                                                            ) : (
                                                                                                <PlusIcon className="h-3 w-3 text-orange-600" />
                                                                                            )}
                                                                                        </div>
                                                                                        <div>
                                                                                            <p className="text-gray-600 font-bold mx-1 px-1">{spNoteIndex +1}.</p>
                                                                                        </div>
                                                                                        <div className="">
                                                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                                                                {getTimeSince(noteUpdateDate)}
                                                                                            </p>
                                                                                            <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-span-6 flex justify-start items-center">
                                                                                        <div
                                                                                        dangerouslySetInnerHTML={createMarkup(note)}
                                                                                        className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                                                        ></div>
                                                                                    </div>
                                                                                    <div className="col-span-3 flex justify-end items-center mx-2">
                                                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                                                        <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(noteDate)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                {showSuperPowerNoteDetails[spNote._id] && (
                                                                                    <div className="m-1 p-1 rounded bg-gray-100">
                                                                                        <div
                                                                                        dangerouslySetInnerHTML={createMarkup(note)}
                                                                                        className="text-[11px] text-gray-700 m-1 p-1"
                                                                                        ></div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )
                                                                    })}


                                                                </div>
                                                            )}

                                                        </div>

                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                        <div className="m-1 p-1 bg-gray-100 rounded">
                            <div className="grid grid-cols-10 justify-between items-center">
                                <div className="col-span-3 flex justify-start items-center m-1 p-1">
                                    <div
                                        onClick={() => setShowSelfNotesSection(prev => !prev)}
                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                        >
                                        {showSelfNotesSection ? (
                                            <MinusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                            <PlusIcon className="h-4 w-4 text-orange-600" />
                                        )}
                                    </div>
                                    <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtHmUserSelfNotesIconSVGComp className='h-8 w-8 text-orange-600'/></div>
                                    <div>
                                        <p className="font-bold mx-1 px-1">My Kaleidoscope</p>
                                        <p className="text-[10px] italic text-gray-600 mx-1 px-1">what I keep thinking about...</p>
                                    </div>  
                                </div>
                                <div className="col-span-1 flex justify-center items-center m-1 p-1">
                                    <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{selfNotes?.length}</p></div>
                                </div>
                                {/* <div className="col-span-6 flex justify-end items-center m-1 p-1">
                                    <div className="flex justify-center items-center rounded-full h-8 w-8 border border-white bg-gradient-to-b from-orange-400 to-orange-700 via-orange-800 cursor-pointer shadow-lg shadow-black">
                                        <EtEtTrinityIconSVGComp className='h-6 w-6 text-white pb-1'/>
                                    </div>
                                </div>  */}
                            </div>
                            {selfNotes?.length>0 && showSelfNotesSection && (
                                <div className="bg-white m-1 p-1 rounded">
                                    {selfNotes?.map((selfNote, selfNoteIndex) =>{
                                        const note = selfNote?.hiddenMagicSelfNoteText || ""
                                        const noteDate = selfNote?.hiddenMagicSelfNoteDate || ""
                                        const noteUpdateDate = selfNote?.hiddenMagicSelfNoteUpdateDate || ""

                                        return(
                                            <div key={selfNote._id+selfNoteIndex} className="bg-gray-100 rounded border m-1 p-1">
                                                <div className="grid grid-cols-12 justify-start items-center m-1 p-1">
                                                    <div className="col-span-2 flex justify-start items-center">
                                                        <div
                                                            onClick={() => handleToggleShowSelfNoteDetails(selfNote._id)}
                                                            className="mx-2 cursor-pointer rounded-full h-4 w-4 flex justify-center items-center hover:bg-white"
                                                            >
                                                            {showSelfNoteDetails[selfNote._id] ? (
                                                                <MinusIcon className="h-3 w-3 text-orange-600" />
                                                            ) : (
                                                                <PlusIcon className="h-3 w-3 text-orange-600" />
                                                            )}
                                                        </div>
                                                        <div>
                                                            <p className="text-gray-600 font-bold mx-1 px-1">{selfNoteIndex +1}.</p>
                                                        </div>
                                                        <div className="">
                                                            <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                                {getTimeSince(noteUpdateDate)}
                                                            </p>
                                                            <p className="text-[7px] mx-1 italic px-2 py-1 text-gray-400">last updated</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-7 flex justify-start items-center">
                                                        <div className="flex justify-center items-center rounded-full h-10 w-10 bg-gray-50 hover:bg-white cursor-pointer" ><EtHmUserSelfNotesIcon3SVGComp className='h-8 w-8 text-orange-600'/></div>
                                                        <div
                                                        dangerouslySetInnerHTML={createMarkup(note)}
                                                        className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                        ></div>
                                                    </div>
                                                    <div className="col-span-3 flex justify-start items-center mx-2">
                                                        <ClockIcon className="h-5 w-5 text-lime-600" />
                                                        <p className="text-[9px] mx-1">First Created:</p>
                                                        <p className="text-gray-400 text-[9px] mx-2">{formatDateTime(noteDate)}</p>   
                                                    </div>
                                                </div>
                                                {showSelfNoteDetails[selfNote._id] && (
                                                    <div className="bg-white rounded m-1 p-1">
                                                        <div
                                                        dangerouslySetInnerHTML={createMarkup(note)}
                                                        className="text-xs text-gray-700 m-1 p-1 leading-4"
                                                        ></div>  
                                                    </div>
                                                )}

                                            </div>
                                        )
                                    })}
                                </div>
                            )}

                        </div>  
                        <EtModalUpdateUserImprintHiddenMagic
                            isOpen={isUpdateModalOpen}
                            id={selectedHiddenMagicId}
                            userId={userId}
                            userOrgId={userOrgId}
                            triggeringUrl={updateTriggeringUrl}
                            onClose={handleCloseUpdateModal}
                        /> 
                                        
                    
                    </div>
                )
            })}
        </div>
    )
}

export default EtImprintHiddenMagic