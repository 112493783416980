import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userRecognitionsDomainsAdapter = createEntityAdapter({})

const initialState = userRecognitionsDomainsAdapter.getInitialState()

export const userRecognitionsDomainsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserRecognitionsDomains: builder.query({
            query: () => ({
                url: '/userRecognitionsDomains/getUserRecognitionsDomains',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserRecognitionsDomains = responseData.map(userRecognitionsDomain => {
                    userRecognitionsDomain.id = userRecognitionsDomain._id
                    return userRecognitionsDomain
                })
                return userRecognitionsDomainsAdapter.setAll(initialState, loadedUserRecognitionsDomains)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserRecognitionsDomain', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserRecognitionsDomain', id}))
                    ]
                } else {
                    return [ { type: 'UserRecognitionsDomain', id: 'LIST'}]
                }
            },
        }),
        createNewUserRecognitionsDomain: builder.mutation({
            query: initialUserRecognitionsDomain => ({
                url: '/userRecognitionsDomains/createNewUserRecognitionsDomain',
                method: 'POST',
                body: {...initialUserRecognitionsDomain,}
            }),
            invalidatesTags: [
                { type: 'UserRecognitionsDomain', id: "LIST" }
            ] 
        }),
        updateUserRecognitionsDomain: builder.mutation({
            query: initialUserRecognitionsDomain => ({
                url: '/userRecognitionsDomains/updateUserRecognitionsDomain',
                method: 'PATCH',
                body: {...initialUserRecognitionsDomain,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserRecognitionsDomain', id: arg.id },
                { type: 'UserRecognitionsDomain', id: 'LIST' }
            ],
        }),
        deleteUserRecognitionsDomain: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userRecognitionsDomains/deleteUserRecognitionsDomain',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userRecognitionsDomains/deleteUserRecognitionsDomain',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserRecognitionsDomain', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserRecognitionsDomainsQuery,
    useCreateNewUserRecognitionsDomainMutation,
    useUpdateUserRecognitionsDomainMutation,
    useDeleteUserRecognitionsDomainMutation,
} = userRecognitionsDomainsApiSlice

// returns the query result object
export const selectUserRecognitionsDomainResult = userRecognitionsDomainsApiSlice.endpoints.getUserRecognitionsDomains.select()

// create memoized selector
const selectUserRecognitionsDomainsData = createSelector(
    selectUserRecognitionsDomainResult,
    userRecognitionsDomainResult => userRecognitionsDomainResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserRecognitionsDomains,
    selectById: selectUserRecognitionsDomainById,
    selectIds: selectUserRecognitionsDomainIds,
} = userRecognitionsDomainsAdapter.getSelectors(state => selectUserRecognitionsDomainsData(state) ?? initialState)