import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const userActionsAdapter = createEntityAdapter({})

const initialState = userActionsAdapter.getInitialState()

export const userActionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserActions: builder.query({
            query: () => ({
                url: '/userActions/getUserActions',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserActions = responseData.map(userAction => {
                    userAction.id = userAction._id
                    return userAction
                })
                return userActionsAdapter.setAll(initialState, loadedUserActions)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserAction', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserAction', id}))
                    ]
                } else {
                    return [ { type: 'UserAction', id: 'LIST'}]
                }
            },
        }),
        createNewUserAction: builder.mutation({
            query: initialUserAction => ({
                url: '/userActions/createNewUserAction',
                method: 'POST',
                body: {...initialUserAction,}
            }),
            invalidatesTags: [
                { type: 'UserAction', id: "LIST" }
            ] 
        }),
        updateUserAction: builder.mutation({
            query: initialUserAction => ({
                url: '/userActions/updateUserAction',
                method: 'PATCH',
                body: {...initialUserAction,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserAction', id: arg.id },
                { type: 'UserAction', id: 'LIST' }
            ],
        }),
        deleteUserAction: builder.mutation({
            query: ({id}) => ({
                url: '/userActions/deleteUserAction',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserAction', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserActionsQuery,
    useCreateNewUserActionMutation,
    useUpdateUserActionMutation,
    useDeleteUserActionMutation,
} = userActionsApiSlice

// returns the query result object
export const selectUserActionResult = userActionsApiSlice.endpoints.getUserActions.select()

// create memoized selector
const selectUserActionsData = createSelector(
    selectUserActionResult,
    userActionResult => userActionResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserActions,
    selectById: selectUserActionById,
    selectIds: selectUserActionIds,
} = userActionsAdapter.getSelectors(state => selectUserActionsData(state) ?? initialState)