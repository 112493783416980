import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userChallengeCoachEvalsAdapter = createEntityAdapter({})

const initialState = userChallengeCoachEvalsAdapter.getInitialState()

export const userChallengeCoachEvalsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserChallengeCoachEvals: builder.query({
            query: () => ({
                url: '/userChallengeCoachEvals/getUserChallengeCoachEvals',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserChallengeCoachEvals = responseData.map(userChallengeCoachEval => {
                    userChallengeCoachEval.id = userChallengeCoachEval._id
                    return userChallengeCoachEval
                })
                return userChallengeCoachEvalsAdapter.setAll(initialState, loadedUserChallengeCoachEvals)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserChallengeCoachEval', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserChallengeCoachEval', id}))
                    ]
                } else {
                    return [ { type: 'UserChallengeCoachEval', id: 'LIST'}]
                }
            },
        }),
        createNewUserChallengeCoachEval: builder.mutation({
            query: initialUserChallengeCoachEval => ({
                url: '/userChallengeCoachEvals/createNewUserChallengeCoachEval',
                method: 'POST',
                body: {...initialUserChallengeCoachEval,}
            }),
            invalidatesTags: [
                { type: 'UserChallengeCoachEval', id: "LIST" }
            ] 
        }),
        updateUserChallengeCoachEval: builder.mutation({
            query: initialUserChallengeCoachEval => ({
                url: '/userChallengeCoachEvals/updateUserChallengeCoachEval',
                method: 'PATCH',
                body: {...initialUserChallengeCoachEval,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserChallengeCoachEval', id: arg.id },
                { type: 'UserChallengeCoachEval', id: 'LIST' }
            ],
        }),
        deleteUserChallengeCoachEval: builder.mutation({
            query: ({id}) => ({
                url: '/userChallengeCoachEvals/deleteUserChallengeCoachEval',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserChallengeCoachEval', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserChallengeCoachEvalsQuery,
    useCreateNewUserChallengeCoachEvalMutation,
    useUpdateUserChallengeCoachEvalMutation,
    useDeleteUserChallengeCoachEvalMutation,
} = userChallengeCoachEvalsApiSlice

// returns the query result object
export const selectUserChallengeCoachEvalResult = userChallengeCoachEvalsApiSlice.endpoints.getUserChallengeCoachEvals.select()

// create memoized selector
const selectUserChallengeCoachEvalsData = createSelector(
    selectUserChallengeCoachEvalResult,
    userChallengeCoachEvalResult => userChallengeCoachEvalResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserChallengeCoachEvals,
    selectById: selectUserChallengeCoachEvalById,
    selectIds: selectUserChallengeCoachEvalIds,
} = userChallengeCoachEvalsAdapter.getSelectors(state => selectUserChallengeCoachEvalsData(state) ?? initialState)