import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const impactTypesAdapter = createEntityAdapter({})

const initialState = impactTypesAdapter.getInitialState()

export const impactTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getImpactTypes: builder.query({
            query: () => ({
                url: '/impactTypes/getImpactTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedImpactTypes = responseData.map(impactType => {
                    impactType.id = impactType._id
                    return impactType
                })
                return impactTypesAdapter.setAll(initialState, loadedImpactTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'ImpactType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'ImpactType', id}))
                    ]
                } else {
                    return [ { type: 'ImpactType', id: 'LIST'}]
                }
            },
        }),
        createNewImpactType: builder.mutation({
            query: initialImpactType => ({
                url: '/impactTypes/createNewImpactType',
                method: 'POST',
                body: {...initialImpactType,}
            }),
            invalidatesTags: [
                { type: 'ImpactType', id: "LIST" }
            ] 
        }),
        updateImpactType: builder.mutation({
            query: initialImpactType => ({
                url: '/impactTypes/updateImpactType',
                method: 'PATCH',
                body: {...initialImpactType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'ImpactType', id: arg.id },
                { type: 'ImpactType', id: 'LIST' }
            ],
        }),
        deleteImpactType: builder.mutation({
            query: ({id}) => ({
                url: '/impactTypes/deleteImpactType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'ImpactType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetImpactTypesQuery,
    useCreateNewImpactTypeMutation,
    useUpdateImpactTypeMutation,
    useDeleteImpactTypeMutation,
} = impactTypesApiSlice

// returns the query result object
export const selectImpactTypeResult = impactTypesApiSlice.endpoints.getImpactTypes.select()

// create memoized selector
const selectImpactTypesData = createSelector(
    selectImpactTypeResult,
    impactTypeResult => impactTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllImpactTypes,
    selectById: selectImpactTypeById,
    selectIds: selectImpactTypeIds,
} = impactTypesAdapter.getSelectors(state => selectImpactTypesData(state) ?? initialState)