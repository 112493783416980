// Author - Atul Pandey
// First Created - 26 Feb 2025
// Purpose - to define admin menu items

const adminMenuItems = [
    { key: 'coachUserPads', label: 'Coach Pads', subKeys: ['updateCoachUserPad', 'deleteCoachUserPad'] },
    { key: 'sponsorUserPads', label: 'Sponsor Pads', subKeys: ['updateSponsorUserPad', 'deleteSponsorUserPad'] },
    { key: 'actionTypes', label: 'Action Types', subKeys: ['updateActionType', 'deleteActionType'] },
    { key: 'capabilitiesAdvisoryTypes', label: 'CapabilitiesAdvisory Types', subKeys: ['updateCapabilitiesAdvisoryType', 'deleteCapabilitiesAdvisoryType'] },
    { key: 'capabilitiesDomainTypes', label: 'CapabilitiesDomain Types', subKeys: ['updateCapabilitiesDomainType', 'deleteCapabilitiesDomainType'] },
    { key: 'capabilitiesFunctionTypes', label: 'CapabilitiesFunction Types', subKeys: ['updateCapabilitiesFunctionType', 'deleteCapabilitiesFunctionType'] },
    { key: 'capabilitiesIndustryTypes', label: 'CapabilitiesIndustry Types', subKeys: ['updateCapabilitiesIndustryType', 'deleteCapabilitiesIndustryType'] },
    { key: 'capabilitiesSalesFunctionAreaTypes', label: 'CapabilitiesSalesFunctionArea Types', subKeys: ['updateCapabilitiesSalesFunctionAreaType', 'deleteCapabilitiesSalesFunctionAreaType'] },
    { key: 'capabilitiesSalesTypes', label: 'CapabilitiesSales Types', subKeys: ['updateCapabilitiesSalesType', 'deleteCapabilitiesSalesType'] },
    { key: 'capabilitiesServiceFunctionAreaTypes', label: 'CapabilitiesServiceFunctionArea Types', subKeys: ['updateCapabilitiesServiceFunctionAreaType', 'deleteCapabilitiesServiceFunctionAreaType'] },
    { key: 'capabilitiesServiceTypes', label: 'CapabilitiesService Types', subKeys: ['updateCapabilitiesServiceType', 'deleteCapabilitiesServiceType'] },
    { key: 'capabilitiesSoftSkillFunctionAreaTypes', label: 'CapabilitiesSoftSkillFunctionArea Types', subKeys: ['updateCapabilitiesSoftSkillFunctionAreaType', 'deleteCapabilitiesSoftSkillFunctionAreaType'] },
    { key: 'capabilitiesSoftSkillTypes', label: 'CapabilitiesSoftSkill Types', subKeys: ['updateCapabilitiesSoftSkillType', 'deleteCapabilitiesSoftSkillType'] },
    { key: 'capabilitiesTechAreaTypes', label: 'CapabilitiesTechArea Types', subKeys: ['updateCapabilitiesTechAreaType', 'deleteCapabilitiesTechAreaType'] },
    { key: 'capabilitiesTechStackTypes', label: 'CapabilitiesTechStack Types', subKeys: ['updateCapabilitiesTechStackType', 'deleteCapabilitiesTechStackType'] },
    { key: 'challengeHeads', label: 'Challenge Heads', subKeys: ['updateChallengeHead', 'deleteChallengeHead'] },
    { key: 'challenges', label: 'Challenges', subKeys: ['updateChallenge', 'deleteChallenge'] },
    { key: 'contacts', label: 'Contacts', subKeys: ['updateContact', 'deleteContact'] },
    { key: 'contactOrganizations', label: 'ContactOrganizations', subKeys: ['updateContactOrganization', 'deleteContactOrganization'] },
    { key: 'contactOrganizationTypes', label: 'ContactOrganizationTypes', subKeys: ['updateContactOrganizationType', 'deleteContactOrganizationType'] },
    { key: 'contactTypes', label: 'Contact Types', subKeys: ['updateContactType', 'deleteContactType'] },
    { key: 'challengeTypes', label: 'Challenge Types', subKeys: ['updateChallengeType', 'deleteChallengeType'] },
    { key: 'contributionTypes', label: 'Contribution Types', subKeys: ['updateContributionType', 'deleteContributionType'] },
    { key: 'creationAreaTypes', label: 'CreationArea Types', subKeys: ['updateCreationAreaType', 'deleteCreationAreaType'] },
    { key: 'creationTypes', label: 'Creation Types', subKeys: ['updateCreationType', 'deleteCreationType'] },
    { key: 'gratitudeTypes', label: 'Gratitude Types', subKeys: ['updateGratitudeType', 'deleteGratitudeType'] },
    { key: 'impactTypes', label: 'Impact Types', subKeys: ['updateImpactType', 'deleteImpactType'] },
    { key: 'inspirationBeaconTypes', label: 'InspirationBeacon Types', subKeys: ['updateInspirationBeaconType', 'deleteInspirationBeaconType'] },
    { key: 'intentionTypes', label: 'Intention Types', subKeys: ['updateIntentionType', 'deleteIntentionType'] },
    { key: 'investigationTypes', label: 'Investigation Types', subKeys: ['updateInvestigationType', 'deleteInvestigationType'] },
    { key: 'legacyTypes', label: 'Legacy Types', subKeys: ['updateLegacyType', 'deleteLegacyType'] },
    { key: 'missionMetricGradationTypes', label: 'MissionMetricGradation Types', subKeys: ['updateMissionMetricGradationType', 'deleteMissionMetricGradationType'] },
    { key: 'missionMetricUoMTypes', label: 'MissionMetricUoM Types', subKeys: ['updateMissionMetricUoMType', 'deleteMissionMetricUoMType'] },
    { key: 'organizations', label: 'Organizations', subKeys: ['updateOrganization', 'deleteOrganization'] },
    { key: 'organizationTypes', label: 'Organization Types', subKeys: ['updateOrganizationType', 'deleteOrganizationType'] },
    { key: 'orgRoles', label: 'Org Roles', subKeys: ['updateOrgRole', 'deleteOrgRole'] },
    { key: 'orgTitles', label: 'Org Titles', subKeys: ['updateOrgTitle', 'deleteOrgTitle'] },
    { key: 'problemAreaTypes', label: 'ProblemArea Types', subKeys: ['updateProblemAreaType', 'deleteProblemAreaType'] },
    { key: 'problemMetricGradationTypes', label: 'ProblemMetricGradation Types', subKeys: ['updateProblemMetricGradationType', 'deleteProblemMetricGradationType'] },
    { key: 'problemMetricTypes', label: 'ProblemMetric Types', subKeys: ['updateProblemMetricType', 'deleteProblemMetricType'] },
    { key: 'problemMetricUoMTypes', label: 'ProblemMetricUoM Types', subKeys: ['updateProblemMetricUoMType', 'deleteProblemMetricUoMType'] },
    { key: 'questionSets', label: 'Question Sets', subKeys: ['updateQuestionSet', 'deleteQuestionSet'] },
    { key: 'questionWithScores', label: 'Question With Scores', subKeys: ['updateQuestionWithScores', 'deleteQuestionWithScores'] },
    { key: 'sections', label: 'Sections', subKeys: ['updateSection', 'deleteSections'] },
    { key: 'sessions', label: 'Sessions', subKeys: ['updateSession', 'deleteSession'] },
    { key: 'subsPacks', label: 'Subscription Packs', subKeys: ['updateSubsPack', 'deleteSubsPack'] },
    { key: 'orgSubscriptions', label: 'Org Subscriptions', subKeys: ['updateOrgSubscription', 'deleteOrgSubscription'] },
    { key: 'sessionTypes', label: 'Session Types', subKeys: ['updateSessionType', 'deleteSessionType'] },
    { key: 'showcaseTypes', label: 'Showcase Types', subKeys: ['updateShowcaseType', 'deleteShowcaseType'] },
    { key: 'trails', label: 'Trails', subKeys: ['updateTrail', 'deleteTrail'] },
    { key: 'users', label: 'Users', subKeys: ['updateUser', 'deleteUser'] },
    { key: 'userChallengeTypes', label: 'UserChallenge Types', subKeys: ['updateUserChallengeType', 'deleteUserChallengeType'] },
    { key: 'winTypes', label: 'Win Types', subKeys: ['updateWinType', 'deleteWinType'] },
];

export default adminMenuItems