import { useState, useEffect, useRef } from 'react'
import {
  MicrophoneIcon,
  StopIcon,
  PaperAirplaneIcon
} from "@heroicons/react/24/outline"
import EtBtnFormBasic from '@/components/common/buttons/EtBtnFormBasic'
import EtContentEditorLight2 from "@/utils/EtContentEditorLight2"
import { 
    useGetUserConversationsQuery, // 25 Mar 2025
    useCreateNewUserConversationMutation 
} from '@/features/userConversations/userConversationsApiSlice'


const EtTrinityUserMeetingConversation = ({
  userId,
  userOrgId,
  noteId = null, // ✅ 25 Mar 2025 - new prop
  isDelete = false, // ✅ 25 Mar 2025 - new prop
  refetchUserConversations = () => {}, // ✅ 25 Mar 2025 - new prop
  calendarMeetingId,
  userMeetingPrepId,
  onClose,
}) => {

  // First Created - 23 Mar 2025
  // Author - Atul Pandey
  // Purpose - To capture Meeting conversations and summarize with Trinity's LLM powers
  // Inherited from EtUserConversationMobile 

  // -----------------------------------------
  // 0 - Initialize states and refs
  // -----------------------------------------
  const [noteText, setNoteText] = useState("")
  const [isRecording, setIsRecording] = useState(false)
  const recognitionRef = useRef(null)

  // 25 Mar 2025
//   const [editorValue, setEditorValue] = useState("")
  const [isFormInitialized, setIsFormInitialized] = useState(false)
  const [currentConversation, setCurrentConversation] = useState(null) // ✅ For local caching

//   console.log('userId', userId)
//   console.log('noteId', noteId)
//   console.log('userOrgId', userOrgId)
//   console.log('calendarMeetingId', calendarMeetingId)
//   console.log('userMeetingPrepId', userMeetingPrepId)

  const DEFAULT_CONVERSATION_TEMPLATE = `
    <strong>What was the core discussion about?</strong><br />
    (Key discussion points here...)<br /><br /><br />
    <strong>Who said what? Any memorable phrases?</strong><br />
    (Key statements / comments...)<br /><br /><br />
    <strong>What decisions were made?</strong><br />
    (List key decesions made...)<br /><br /><br />
    <strong>What ideas or concerns were raised?</strong><br />
    (Key moments /concerns...)<br /><br /><br />
    <strong>Any follow-ups or next steps agreed upon?</strong><br />
    (Summary and actionables...)<br /><br /><br />
`
useEffect(() => {
    if (!noteText.trim()) {
      setNoteText(DEFAULT_CONVERSATION_TEMPLATE);
    }
  }, []);

  // -----------------------------------------
  // 1 - Queries and Mutation to get and create a conversation
  // -----------------------------------------
  const [createNewUserConversation, {
    isLoading,
    isSuccess,
    isError,
    error
  }] = useCreateNewUserConversationMutation()

  const { data: userConversationsData } = useGetUserConversationsQuery() // 25 Mar 2025
//   console.log('conversationsData', userConversationsData?.ids.map(id => userConversationsData?.entities[id])
//   console.log('conversationsData', userConversationsData?.ids?.map((id)=> userConversationsData?.entities[id]))

  useEffect(() => {
    if (userConversationsData?.ids?.length && !isFormInitialized) {
      const match = userConversationsData.ids
        .map(id => userConversationsData.entities[id])
        .find(conv => 
          conv.userId?._id === userId &&
          conv.userOrgId?._id === userOrgId &&
          conv.userConversationAllNotes?.some(note => note._id === noteId)
        )
  
      if (match) {
        setCurrentConversation(match)
        // console.log("match", match)
  
        // ✅ Find and populate the target note if editing
        if (noteId && match.userConversationAllNotes?.length > 0) {
          const targetNote = match.userConversationAllNotes.find(note => note._id === noteId)
          if (targetNote) {
            setNoteText(targetNote.userConversationAllNoteText || '')
          }
        }
  
        // ✅ Only prefill default if not delete/edit mode
        if (!noteId && !isDelete) {
          setNoteText(DEFAULT_CONVERSATION_TEMPLATE)
        }
  
        setIsFormInitialized(true)
      }
    }
  }, [userConversationsData, noteId, userId, userOrgId, isFormInitialized])
  

  // -----------------------------------------
  // 2 - Handle voice input
  // -----------------------------------------
  const handleVoiceInput = () => {
    if (!("webkitSpeechRecognition" in window)) {
      alert("Your browser does not support voice input.")
      return
    }

    if (isRecording) {
      if (recognitionRef.current) recognitionRef.current.stop()
      setIsRecording(false)
      return
    }

    const recognition = new window.webkitSpeechRecognition()
    recognitionRef.current = recognition
    recognition.continuous = true
    recognition.interimResults = true
    recognition.lang = "en-US"

    recognition.onstart = () => setIsRecording(true)

    recognition.onresult = (event) => {
      let finalText = ""
      for (let i = event.resultIndex; i < event.results.length; i++) {
        if (event.results[i].isFinal) {
          finalText += event.results[i][0].transcript + " "
        }
      }
      setNoteText(prev => prev + finalText)
    }

    recognition.onerror = () => setIsRecording(false)
    recognition.onend = () => setIsRecording(false)

    recognition.start()
  }

  // -----------------------------------------
  // 3 - Handle form submit
  // -----------------------------------------
  const handleSubmit = async (e) => {
    e.preventDefault()
    const trimmedText = noteText.trim()
    if (!trimmedText) return

    const payload = {
      userId,
      userOrgId,
      userConversationAllNotes: [
        noteId
        ? {
            _id: noteId,
            ...(isDelete
              ? { delete: true }
              : {
                  userConversationAllNoteText: trimmedText,
                  userConversationAllNoteUpdateDate: new Date(),
                  calendarMeetingId,
                  userMeetingPrepId
                }),
          }
        :
        {
          userConversationAllNoteText: trimmedText,
          userConversationAllNoteDate: new Date(),
          userConversationAllNoteUpdateDate: new Date(),
          calendarMeetingId,
          userMeetingPrepId
        }
      ]
    }

    // const payload = {
    //   userId,
    //   userOrgId,
    //   userConversationAllNotes: [
    //     {
    //       userConversationAllNoteText: trimmedText,
    //       userConversationAllNoteDate: new Date(),
    //       userConversationAllNoteUpdateDate: new Date(),
    //       calendarMeetingId,
    //       userMeetingPrepId
    //     }
    //   ]
    // }

    try {
      await createNewUserConversation(payload).unwrap()
      
      if (refetchUserConversations) {
        await refetchUserConversations() // ✅ This will pull fresh data into parent view
      }

      if (onClose) onClose()
    } catch (err) {
      console.error("Error saving conversation:", err)
    }
  }

  // -----------------------------------------
  // 4 - Handle cancel / close
  // -----------------------------------------
  const handleCancel = () => {
    if (onClose) onClose()
  }

  // -----------------------------------------
  // 5 - Cleanup effect for stopping recognition
  // -----------------------------------------
  useEffect(() => {
    return () => {
      if (recognitionRef.current) recognitionRef.current.stop()
    }
  }, [])

   // 6 - create Markup
   const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
  }

  // -----------------------------------------
  // 7 - UI
  // -----------------------------------------
  return (
    <div className="bg-white p-4 rounded shadow-md text-xs font-poppins">
      <p className="text-base font-bold text-gray-700 mb-2">Add / Update a Meeting Conversation</p>

      {isError && <p className="text-red-600 mb-2">{error?.data?.message}</p>}

      {isDelete && (
        <p className="text-xs text-red-600 font-semibold mb-2">
          You are about to delete this conversation note. This action is permanent and cannot be undone. Are you sure?
        </p>
      )}

      <form onSubmit={handleSubmit} className={`rounded p-2 mb-4 ${isDelete ? 'bg-gray-100 text-gray-300' : 'bg-gray-100'}`}>
        {/* Note Entry */}

        {isDelete ? (
            <p dangerouslySetInnerHTML={createMarkup(noteText)} className="text-sm leading-relaxed p-2"></p>
        ) 
        :(
            <div>
                <p className="text-[11px] text-gray-600 leading-5 mb-2">
                    Use this space to capture your observations and key items covered during the meeting including: <br />
                <strong>What was the core discussion about?</strong><br />
                    <strong>Who said what? Any memorable phrases?</strong><br />
                    <strong>What decisions were made?</strong><br />
                    <strong>What ideas or concerns were raised?</strong><br />
                    <strong>Any follow-ups or next steps agreed upon?</strong><br />
                </p>
                <EtContentEditorLight2
                    editorKey="meetingConversationEditor"
                    value={noteText}
                    handleChange={setNoteText}
                    placeholderText="What did you discuss in the meeting?"
                />
            </div>
        )}

        {/* Voice + Send Buttons */}
        <div className="flex items-center gap-2 mb-3">
          <button
            type="button"
            onClick={handleVoiceInput}
            className={`my-5 p-2 rounded-full ${isRecording ? 'bg-red-600' : 'bg-lime-600'} text-white`}
            title="Voice Input"
          >
            {isRecording ? <StopIcon className="h-5 w-5" /> : <MicrophoneIcon className="h-5 w-5" />}
          </button>
        </div>

        {/* Action Buttons */}
        <div className="flex gap-2">
          <EtBtnFormBasic name={isDelete ? "Delete" : "Save"} type="create" handleClick={handleSubmit} />
          <EtBtnFormBasic name="Cancel" type="cancel" handleClick={handleCancel} />
        </div>
      </form>
    </div>
  )
}

export default EtTrinityUserMeetingConversation