import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userVisionClientsAdapter = createEntityAdapter({})

const initialState = userVisionClientsAdapter.getInitialState()

export const userVisionClientsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserVisionClients: builder.query({
            query: () => ({
                url: '/userVisionClients/getUserVisionClients',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserVisionClients = responseData.map(userVisionClient => {
                    userVisionClient.id = userVisionClient._id
                    return userVisionClient
                })
                return userVisionClientsAdapter.setAll(initialState, loadedUserVisionClients)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserVisionClient', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserVisionClient', id}))
                    ]
                } else {
                    return [ { type: 'UserVisionClient', id: 'LIST'}]
                }
            },
        }),
        createNewUserVisionClient: builder.mutation({
            query: initialUserVisionClient => ({
                url: '/userVisionClients/createNewUserVisionClient',
                method: 'POST',
                body: {...initialUserVisionClient,}
            }),
            invalidatesTags: [
                { type: 'UserVisionClient', id: "LIST" }
            ] 
        }),
        updateUserVisionClient: builder.mutation({
            query: initialUserVisionClient => ({
                url: '/userVisionClients/updateUserVisionClient',
                method: 'PATCH',
                body: {...initialUserVisionClient,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserVisionClient', id: arg.id },
                { type: 'UserVisionClient', id: 'LIST' }
            ],
        }),
        deleteUserVisionClient: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userVisionClients/deleteUserVisionClient',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userVisionClients/deleteUserVisionClient',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserVisionClient', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserVisionClientsQuery,
    useCreateNewUserVisionClientMutation,
    useUpdateUserVisionClientMutation,
    useDeleteUserVisionClientMutation,
} = userVisionClientsApiSlice

// returns the query result object
export const selectUserVisionClientResult = userVisionClientsApiSlice.endpoints.getUserVisionClients.select()

// create memoized selector
const selectUserVisionClientsData = createSelector(
    selectUserVisionClientResult,
    userVisionClientResult => userVisionClientResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserVisionClients,
    selectById: selectUserVisionClientById,
    selectIds: selectUserVisionClientIds,
} = userVisionClientsAdapter.getSelectors(state => selectUserVisionClientsData(state) ?? initialState)