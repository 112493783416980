import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userGratitudesAdapter = createEntityAdapter({})

const initialState = userGratitudesAdapter.getInitialState()

export const userGratitudesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserGratitudes: builder.query({
            query: () => ({
                url: '/userGratitudes/getUserGratitudes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserGratitudes = responseData.map(userGratitude => {
                    userGratitude.id = userGratitude._id
                    return userGratitude
                })
                return userGratitudesAdapter.setAll(initialState, loadedUserGratitudes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserGratitude', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserGratitude', id}))
                    ]
                } else {
                    return [ { type: 'UserGratitude', id: 'LIST'}]
                }
            },
        }),
        createNewUserGratitude: builder.mutation({
            query: initialUserGratitude => ({
                url: '/userGratitudes/createNewUserGratitude',
                method: 'POST',
                body: {...initialUserGratitude,}
            }),
            invalidatesTags: [
                { type: 'UserGratitude', id: "LIST" }
            ] 
        }),
        updateUserGratitude: builder.mutation({
            query: initialUserGratitude => ({
                url: '/userGratitudes/updateUserGratitude',
                method: 'PATCH',
                body: {...initialUserGratitude,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserGratitude', id: arg.id },
                { type: 'UserGratitude', id: 'LIST' }
            ],
        }),
        deleteUserGratitude: builder.mutation({
            query: ({id}) => ({
                url: '/userGratitudes/deleteUserGratitude',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserGratitude', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserGratitudesQuery,
    useCreateNewUserGratitudeMutation,
    useUpdateUserGratitudeMutation,
    useDeleteUserGratitudeMutation,
} = userGratitudesApiSlice

// returns the query result object
export const selectUserGratitudeResult = userGratitudesApiSlice.endpoints.getUserGratitudes.select()

// create memoized selector
const selectUserGratitudesData = createSelector(
    selectUserGratitudeResult,
    userGratitudeResult => userGratitudeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserGratitudes,
    selectById: selectUserGratitudeById,
    selectIds: selectUserGratitudeIds,
} = userGratitudesAdapter.getSelectors(state => selectUserGratitudesData(state) ?? initialState)