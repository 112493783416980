import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const organizationTypesAdapter = createEntityAdapter({})

const initialState = organizationTypesAdapter.getInitialState()

export const organizationTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOrganizationTypes: builder.query({
            query: () => ({
                url: '/organizationTypes/getOrganizationTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedOrganizationTypes = responseData.map(organizationType => {
                    organizationType.id = organizationType._id
                    return organizationType
                })
                return organizationTypesAdapter.setAll(initialState, loadedOrganizationTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'OrganizationType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'OrganizationType', id}))
                    ]
                } else {
                    return [ { type: 'OrganizationType', id: 'LIST'}]
                }
            },
        }),
        createNewOrganizationType: builder.mutation({
            query: initialOrganizationType => ({
                url: '/organizationTypes/createNewOrganizationType',
                method: 'POST',
                body: {...initialOrganizationType,}
            }),
            invalidatesTags: [
                { type: 'OrganizationType', id: "LIST" }
            ] 
        }),
        updateOrganizationType: builder.mutation({
            query: initialOrganizationType => ({
                url: '/organizationTypes/updateOrganizationType',
                method: 'PATCH',
                body: {...initialOrganizationType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'OrganizationType', id: arg.id },
                { type: 'OrganizationType', id: 'LIST' }
            ],
        }),
        deleteOrganizationType: builder.mutation({
            query: ({id}) => ({
                url: '/organizationTypes/deleteOrganizationType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'OrganizationType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetOrganizationTypesQuery,
    useCreateNewOrganizationTypeMutation,
    useUpdateOrganizationTypeMutation,
    useDeleteOrganizationTypeMutation,
} = organizationTypesApiSlice

// returns the query result object
export const selectOrganizationTypeResult = organizationTypesApiSlice.endpoints.getOrganizationTypes.select()

// create memoized selector
const selectOrganizationTypesData = createSelector(
    selectOrganizationTypeResult,
    organizationTypeResult => organizationTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllOrganizationTypes,
    selectById: selectOrganizationTypeById,
    selectIds: selectOrganizationTypeIds,
} = organizationTypesAdapter.getSelectors(state => selectOrganizationTypesData(state) ?? initialState)