import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userTrailSessionExecutionsAdapter = createEntityAdapter({})

const initialState = userTrailSessionExecutionsAdapter.getInitialState()

export const userTrailSessionExecutionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserTrailSessionExecutions: builder.query({
            query: () => ({
                url: '/userTrailSessionExecutions/getUserTrailSessionExecutions',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserTrailSessionExecutions = responseData.map(userTrailSessionExecution => {
                    userTrailSessionExecution.id = userTrailSessionExecution._id
                    return userTrailSessionExecution
                })
                return userTrailSessionExecutionsAdapter.setAll(initialState, loadedUserTrailSessionExecutions)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserTrailSessionExecution', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserTrailSessionExecution', id}))
                    ]
                } else {
                    return [ { type: 'UserTrailSessionExecution', id: 'LIST'}]
                }
            },
        }),
        createNewUserTrailSessionExecution: builder.mutation({
            query: initialUserTrailSessionExecution => ({
                url: '/userTrailSessionExecutions/createNewUserTrailSessionExecution',
                method: 'POST',
                body: {...initialUserTrailSessionExecution,}
            }),
            invalidatesTags: [
                { type: 'UserTrailSessionExecution', id: "LIST" }
            ] 
        }),
        updateUserTrailSessionExecution: builder.mutation({
            query: initialUserTrailSessionExecution => ({
                url: '/userTrailSessionExecutions/updateUserTrailSessionExecution',
                method: 'PATCH',
                body: {...initialUserTrailSessionExecution,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserTrailSessionExecution', id: arg.id },
                { type: 'UserTrailSessionExecution', id: 'LIST' }
            ],
        }),
        deleteUserTrailSessionExecution: builder.mutation({
            query: ({id}) => ({
                url: '/userTrailSessionExecutions/deleteUserTrailSessionExecution',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserTrailSessionExecution', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserTrailSessionExecutionsQuery,
    useCreateNewUserTrailSessionExecutionMutation,
    useUpdateUserTrailSessionExecutionMutation,
    useDeleteUserTrailSessionExecutionMutation,
} = userTrailSessionExecutionsApiSlice

// returns the query result object
export const selectUserTrailSessionExecutionResult = userTrailSessionExecutionsApiSlice.endpoints.getUserTrailSessionExecutions.select()

// create memoized selector
const selectUserTrailSessionExecutionsData = createSelector(
    selectUserTrailSessionExecutionResult,
    userTrailSessionExecutionResult => userTrailSessionExecutionResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserTrailSessionExecutions,
    selectById: selectUserTrailSessionExecutionById,
    selectIds: selectUserTrailSessionExecutionIds,
} = userTrailSessionExecutionsAdapter.getSelectors(state => selectUserTrailSessionExecutionsData(state) ?? initialState)