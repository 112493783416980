import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userSectionResponseWithScoresAdapter = createEntityAdapter({})

const initialState = userSectionResponseWithScoresAdapter.getInitialState()

export const userSectionResponseWithScoresApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserSectionResponseWithScores: builder.query({
            query: () => ({
                url: '/userSectionResponseWithScores',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserSectionResponseWithScores = responseData.map(userSectionResponseWithScore => {
                    userSectionResponseWithScore.id = userSectionResponseWithScore._id
                    return userSectionResponseWithScore
                })
                return userSectionResponseWithScoresAdapter.setAll(initialState, loadedUserSectionResponseWithScores)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserSectionResponseWithScore', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserSectionResponseWithScore', id}))
                    ]
                } else {
                    return [ { type: 'UserSectionResponseWithScore', id: 'LIST'}]
                }
            },
        }),
        createNewUserSectionResponseWithScore: builder.mutation({
            query: initialUserSectionResponseWithScore => ({
                url: '/userSectionResponseWithScores',
                method: 'POST',
                body: {...initialUserSectionResponseWithScore,}
            }),
            invalidatesTags: [
                { type: 'UserSectionResponseWithScore', id: "LIST" }
            ] 
        }),
        updateUserSectionResponseWithScore: builder.mutation({
            query: initialUserSectionResponseWithScore => ({
                url: '/userSectionResponseWithScores',
                method: 'PATCH',
                body: {...initialUserSectionResponseWithScore,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserSectionResponseWithScore', id: arg.id },
                { type: 'UserSectionResponseWithScore', id: 'LIST' }
            ],
        }),
        deleteUserSectionResponseWithScore: builder.mutation({
            query: ({id}) => ({
                url: '/userSectionResponseWithScores',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserSectionResponseWithScore', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserSectionResponseWithScoresQuery,
    useCreateNewUserSectionResponseWithScoreMutation,
    useUpdateUserSectionResponseWithScoreMutation,
    useDeleteUserSectionResponseWithScoreMutation,
} = userSectionResponseWithScoresApiSlice

// returns the query result object
export const selectUserSectionResponseWithScoreResult = userSectionResponseWithScoresApiSlice.endpoints.getUserSectionResponseWithScores.select()

// create memoized selector
const selectUserSectionResponseWithScoresData = createSelector(
    selectUserSectionResponseWithScoreResult,
    userSectionResponseWithScoreResult => userSectionResponseWithScoreResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserSectionResponseWithScores,
    selectById: selectUserSectionResponseWithScoreById,
    selectIds: selectUserSectionResponseWithScoreIds,
} = userSectionResponseWithScoresAdapter.getSelectors(state => selectUserSectionResponseWithScoresData(state) ?? initialState)