
    import React from 'react';
    
    const EtConversationContactIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m2808 1414.8c-147.6 0-266.4-118.8-266.4-266.4 0-147.6 118.8-266.4 266.4-266.4 147.6 0 266.4 118.8 266.4 266.4s-118.8 266.4-266.4 266.4z" fillRule="evenodd"/>
 <path d="m1972.8 1353.6c3.6016 39.602 18 252 147.6 363.6 57.602 50.398 129.6 72 208.8 61.199 79.199-7.1992 140.4-18 198-28.801l-10.801 1468.8c0 72 57.602 133.2 129.6 133.2 72 3.6016 133.2-57.602 133.2-129.6l7.1992-838.8h54l201.6 867.6c14.398 61.199 68.398 100.8 129.6 100.8 10.801 0 21.602 0 28.801-3.6016 72-18 115.2-86.398 97.199-158.4l-201.61-871.2v-72c3.6016 7.1992 10.801 10.801 18 14.398 21.602 14.398 46.801 21.602 68.398 21.602 36 0 75.602-18 97.199-50.398l208.8-291.6c28.801-39.602 28.801-93.602 3.6016-136.8-7.1992-14.398-219.6-320.4-680.4-320.4-126 0-208.8 14.398-306 28.801-57.602 10.801-118.8 21.602-201.6 28.801-14.398 0-21.602-3.6016-28.801-7.1992-39.602-32.398-61.199-133.2-64.801-194.4-3.6016-64.801-57.602-118.8-126-111.6-64.797 7.1992-115.2 61.199-111.6 126zm1119.6 428.4c64.801 28.801 115.2 64.801 147.6 97.199l-144 201.6z" fillRule="evenodd"/>
 <path d="m1584 2041.2c21.602-61.199-14.398-129.6-75.602-151.2l-212.4-68.398-172.8-216c-61.199-75.602-154.8-118.8-252-118.8h-230.4c-273.6 0-478.8 468-536.4 612-25.199 61.199 7.1992 129.6 68.398 154.8 14.398 7.1992 28.801 7.1992 43.199 7.1992 46.801 0 93.602-28.801 111.6-75.602 50.398-122.4 115.2-244.8 176.4-331.2l-18 464.4-194.4 874.8c-14.398 72 28.801 140.4 100.8 158.4 10.801 3.6016 18 3.6016 28.801 3.6016 61.199 0 115.2-43.199 129.6-104.4l187.2-864h50.398v835.2c0 72 57.602 133.2 133.2 133.2 72 0 133.2-57.602 133.2-133.2v-889.2l-14.398-446.4 93.602 115.2c14.398 18 32.398 32.398 54 39.602l244.8 79.199c61.195 17.996 129.59-18.004 151.2-79.203z" fillRule="evenodd"/>
 <path d="m1033.2 1148.4c0 147.13-119.27 266.4-266.4 266.4-147.13 0-266.4-119.27-266.4-266.4 0-147.13 119.27-266.4 266.4-266.4 147.13 0 266.4 119.27 266.4 266.4" fillRule="evenodd"/>
 <path d="m1731.6 244.8c-306 0-558 187.2-558 417.6 0 111.6 57.602 212.4 151.2 288l-61.199 187.2c-10.801 39.602 28.801 72 64.801 50.398l208.8-133.2c61.199 18 126 25.199 194.4 25.199 306 0 558-187.2 558-417.6 0-230.4-248.4-417.6-558-417.6zm-270 507.6c-46.801 0-86.398-39.602-86.398-86.398 0-46.801 39.602-86.398 86.398-86.398 46.801 0 86.398 39.602 86.398 86.398 0 46.801-39.602 86.398-86.398 86.398zm270 0c-46.801 0-86.398-39.602-86.398-86.398 0-46.801 39.602-86.398 86.398-86.398 46.801 0 86.398 39.602 86.398 86.398 3.6016 46.801-36 86.398-86.398 86.398zm273.6 0c-46.801 0-86.398-39.602-86.398-86.398 0-46.801 39.602-86.398 86.398-86.398 46.801 0 86.398 39.602 86.398 86.398 0.003906 46.801-35.996 86.398-86.398 86.398z" fillRule="evenodd"/>
 <path d="m1785.6 2365.2c-252 0-457.2 205.2-457.2 457.2s205.2 457.2 457.2 457.2 457.2-205.2 457.2-457.2-205.2-457.2-457.2-457.2zm219.6 399.6-237.6 237.6c-14.398 14.398-32.398 21.602-50.398 21.602s-36-7.1992-50.398-21.602l-118.8-118.8c-25.199-25.199-25.199-72 0-97.199 25.199-25.199 72-25.199 97.199 0l68.398 68.398 187.2-187.2c25.199-25.199 72-25.199 97.199 0 32.402 28.797 32.402 72 7.2031 97.199z" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtConversationContactIcon1SVGComp;
    