import {
    useIndentTodoToolBarButton,
    useIndentTodoToolBarButtonState,
  } from '@udecode/plate-indent-list';
  import { withRef } from '@udecode/react-utils';
  
  import { Icons } from '@/components/plate-ui/Icons';
  
  import { ToolbarButton } from './Toolbar';
  
  export const IndentTodoToolbarButton = withRef(
    (rest, ref) => {
      const state = useIndentTodoToolBarButtonState({ nodeType: 'todo' });
      const { props } = useIndentTodoToolBarButton(state);
  
      return (
        <ToolbarButton ref={ref} tooltip="Todo" {...props} {...rest}>
          <Icons.todo />
        </ToolbarButton>
      );
    }
  );