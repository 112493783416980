import React, { useState, useEffect } from 'react'
import useTitle from "../../../hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
    PencilSquareIcon,
  } from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "../../../utils/EtContentEditorLight2"
import EtBtnFormBasic from "../../../components/common/buttons/EtBtnFormBasic"
import { useNavigate } from "react-router"
import { useCreateNewUserImprintRoleMutation } from './userImprintRolesApiSlice'
import { useGetCapabilitiesIndustryTypesQuery } from '@/features/capabilitiesIndustryTypes/capabilitiesIndustryTypesApiSlice'
import { useGetCapabilitiesDomainTypesQuery } from '@/features/capabilitiesDomainTypes/capabilitiesDomainTypesApiSlice'
import { useGetCapabilitiesFunctionTypesQuery } from '@/features/capabilitiesFunctionTypes/capabilitiesFunctionTypesApiSlice'
import { useGetCapabilitiesTechAreaTypesQuery } from '@/features/capabilitiesTechAreaTypes/capabilitiesTechAreaTypesApiSlice'
import { useGetCapabilitiesSalesTypesQuery } from '@/features/capabilitiesSalesTypes/capabilitiesSalesTypesApiSlice'
import { useGetCapabilitiesServiceTypesQuery } from '@/features/capabilitiesServiceTypes/capabilitiesServiceTypesApiSlice'
import { useGetCapabilitiesAdvisoryTypesQuery } from '@/features/capabilitiesAdvisoryTypes/capabilitiesAdvisoryTypesApiSlice'
import { useGetContributionTypesQuery } from '@/features/contributionTypes/contributionTypesApiSlice'
import { useGetImpactTypesQuery } from '@/features/impactTypes/impactTypesApiSlice'

const EtCreateUserImprintRoleComp = ({
    userId, 
    userOrgId, 
    triggeringUrl, 
    onClose
  }) => {

  // First Created - 23 Nov 2024
  // Author - Atul Pandey
  // Inherited from the EtCreateUserImprintCapabilitiesIndustry component

  // 0 - Set Page title & variables
  useTitle('User Imprint Roles')

  // 1 - set page navigations
  const navigate = useNavigate()
  
  // 2a - set the mutation to create the new user imprint Role data
  const [createNewUserImprintRole, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewUserImprintRoleMutation()

  // 2b - Get Data for building Role Focus Areas
  const { data: industryTypesData} = useGetCapabilitiesIndustryTypesQuery()
  const { data: domainTypesData} = useGetCapabilitiesDomainTypesQuery()
  const { data: functionTypesData} = useGetCapabilitiesFunctionTypesQuery()
  const { data: techAreaTypesData} = useGetCapabilitiesTechAreaTypesQuery()
  const { data: salesTypesData} = useGetCapabilitiesSalesTypesQuery()
  const { data: serviceTypesData} = useGetCapabilitiesServiceTypesQuery()
  const { data: advisoryTypesData} = useGetCapabilitiesAdvisoryTypesQuery()
  const { data: contributionTypesData} = useGetContributionTypesQuery()
  const { data: impactTypesData} = useGetImpactTypesQuery()

  const extractEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id]);
  };

  const industryTypes = extractEntitiesFromAdapter(industryTypesData);
  const domainTypes = extractEntitiesFromAdapter(domainTypesData);
  const functionTypes = extractEntitiesFromAdapter(functionTypesData);
  const techAreaTypes = extractEntitiesFromAdapter(techAreaTypesData);
  const salesTypes = extractEntitiesFromAdapter(salesTypesData);
  const serviceTypes = extractEntitiesFromAdapter(serviceTypesData);
  const advisoryTypes = extractEntitiesFromAdapter(advisoryTypesData);
  const contributionTypes = extractEntitiesFromAdapter(contributionTypesData);
  const impactTypes = extractEntitiesFromAdapter(impactTypesData);

  // 3 - Initialize form data state
  const [formData, setFormData] = useState({
    userImprintRoleHeadlineName: '',
    userImprintRoleHeadlineDesc: '',
    userImprintRoles: [], // Array for roles
    userImprintRoleSelfNotes: [], // Array for self-notes
    userImprintRoleValidationNotes: [], // Array for validation notes
    aboutMe: '',
    pic: '',
    active: true,
  });

  // 4 - State variables for the editor
  const [editorKey, setEditorKey] = useState('roleDesc-' + Date.now());
  const [editorValue, setEditorValue] = useState('');

  // 5a - Handle form changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'pic') {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  // 5b - Convert pic to Base64
  const convertPicToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });

  // 6 - Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Step 1: Convert the pic field to Base64 if it exists
      const picBase64 = formData.pic ? await convertPicToBase64(formData.pic) : undefined;

      // Step 2: Construct the payload
      const payload = {
        userId,
        userOrgId,
        userImprintRoleHeadlineName: formData.userImprintRoleHeadlineName.trim() || undefined,
        userImprintRoleHeadlineDesc: formData.userImprintRoleHeadlineDesc.trim() || undefined,
        userImprintRoles: formData.userImprintRoles.map((role) => ({
          ...role,
          userRoleFocusAreas: role.userRoleFocusAreas?.map((focus) => ({
            roleFocusArea: focus.roleFocusArea,
            roleIndustryType: focus.roleIndustryType || undefined,
            roleDomainType: focus.roleDomainType || undefined,
            roleFunctionType: focus.roleFunctionType || undefined,
            roleTechAreaType: focus.roleTechAreaType || undefined,
            roleSalesType: focus.roleSalesType || undefined,
            roleServiceType: focus.roleServiceType || undefined,
            roleAdvisoryType: focus.roleAdvisoryType || undefined,
          })) || [],
          userRoleContributionsAndImpacts: role.userRoleContributionsAndImpacts?.map((contribution) => ({
            ...contribution,
            roleContributionNotes: contribution.roleContributionNotes?.filter(
              (note) => note.roleContributionNoteText.trim() !== ""
            ),
            roleImpactNotes: contribution.roleImpactNotes?.filter(
              (note) => note.roleImpactNoteText.trim() !== ""
            ),
            roleContributionsAndImpactValidations: contribution.roleContributionsAndImpactValidations?.map((validation) => ({
              ...validation,
              validationNotes: validation.validationNotes?.filter(
                (note) => note.validationNoteText.trim() !== ""
              ),
            })) || [],
          })) || [],
        })) || [],
        userImprintRoleSelfNotes: formData.userImprintRoleSelfNotes?.filter(
          (note) => note.userImprintRoleSelfNoteText.trim() !== ""
        ),
        userImprintRoleValidationNotes: formData.userImprintRoleValidationNotes?.filter(
          (note) => note.userImprintRoleValidationNoteText.trim() !== ""
        ),
        aboutMe: formData.aboutMe.trim() || undefined,
        pic: picBase64, // Include the converted Base64 string for pic
        active: formData.active,
      };

      // Step 3: Submit the payload
      await createNewUserImprintRole(payload).unwrap();

      // Step 4: Reset the form upon success
      setFormData({
        userImprintRoleHeadlineName: "",
        userImprintRoleHeadlineDesc: "",
        userImprintRoles: [],
        userImprintRoleSelfNotes: [],
        userImprintRoleValidationNotes: [],
        aboutMe: "",
        pic: "",
        active: true,
      });

      // Step 5: Redirect or close the form
      if (onClose) {
        onClose();
      } else {
        navigate(triggeringUrl || "/userMagicHome");
      }
    } catch (error) {
      // Step 6: Handle errors
      console.error("Error submitting form:", error);
    }
  };

  // 7 - Reset form upon successful submission
  useEffect(() => {
    if(isSuccess) {
      setFormData({
        userImprintRoleHeadlineName: '',
        userImprintRoleHeadlineDesc: '',
        userImprintRoles: [],
        userImprintRoleSelfNotes: [],
        userImprintRoleValidationNotes: [],
        aboutMe: '',
        pic: '',
        active: true,
      })
      // setShowCreateUserImprintCapabilitiesSoftSkill(false)
      if (onClose) {
        onClose(); // 21 Nov 2024
      } else{
        navigate(triggeringUrl || '/userMagicHome');
      }
    }
  
    },[isSuccess,navigate])

  // 8 - Handle Cancel
  const handleCancel = () => {
    // setShowCreateUserImprintCapabilitiesSoftSkill(false)
    if (onClose) {
      onClose(); // 21 Nov 2024
    } else{
      navigate(triggeringUrl || '/userMagicHome');
    }
  }

  // 9 - Handle Clear
  const handleClear = () => {
    setFormData({
      userImprintRoleHeadlineName: '',
      userImprintRoleHeadlineDesc: '',
      userImprintRoles: [],
      userImprintRoleSelfNotes: [],
      userImprintRoleValidationNotes: [],
      aboutMe: '',
      pic: '',
      active: true,
    })
  }

  return (
    <div className="mx-auto w-full m-1 p-1 bg-white rounded shadow-md shadow-black">
      {isLoading && <PulseLoader color={'#ea580c'} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">{error?.data?.message}</p>
      )}

      <p className="p-1 m-1 font-bold font-poppins text-xl">Create User Imprint Role</p>

      <form onSubmit={handleSubmit} className="m-1 p-1 text-xs font-poppins">
        {/* Headline Name */}
        <div id="headlineName" className="bg-gray-100 rounded p-1 m-1">
          <label
            htmlFor="userImprintRoleHeadlineName"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
            Role Headline Name (Optional)
          </label>
          <input
            type="text"
            name="userImprintRoleHeadlineName"
            placeholder="Enter a headline or title"
            value={formData.userImprintRoleHeadlineName}
            onChange={handleChange}
            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
          />
        </div>

        {/* Headline Description */}
        <div id="headlineDesc" className="bg-gray-100 rounded p-1 m-1">
          <label
            htmlFor="userImprintRoleHeadlineDesc"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
            Role Headline Description
          </label>
          <EtContentEditorLight2
            value={editorValue}
            handleChange={(content) => {
              setEditorValue(content);
              setFormData((prevState) => ({
                ...prevState,
                userImprintRoleHeadlineDesc: content,
              }));
            }}
            placeholderText="Write your role description here!"
            editorKey={editorKey}
          />
        </div>

        {/* Roles Section */}
        <div id="roles" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <p className="font-bold text-sm">Roles</p>
            <div className="flex justify-start items-center">
              <p className="text-xs font-bold mx-1 px-1">Add</p>
              <button
                type="button"
                className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                onClick={() => {
                  setFormData((prevState) => ({
                    ...prevState,
                    userImprintRoles: [
                      ...(prevState.userImprintRoles || []),
                      {
                        userRoleName: "",
                        userRoleDesc: "",
                        userRoleOrgName: "",
                        userRoleStartDate: "",
                        userRoleEndDate: "",
                        userRoleLevel: "Entry Level", // Default value
                      },
                    ],
                  }));
                }}
              >
                <PlusIcon className="h-4 w-4 inline" />
              </button>
            </div>
          </div>

          {/* Map through roles and render each */}
          {formData.userImprintRoles?.map((role, roleIndex) => (
            <div key={roleIndex} className="border rounded p-2 m-2 bg-white">
              <div className="flex justify-between items-center mb-2">
                    <p className="font-bold text-sm">Role {roleIndex + 1}</p>
                  <div className='flex justify-start items-center'>
                      <p className="font-bold text-xs mx-1 px-1">Remove</p>
                      <button
                          type="button"
                          className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                          onClick={() => {
                            setFormData((prevState) => ({
                              ...prevState,
                              userImprintRoles: prevState.userImprintRoles.filter((_, i) => i !== roleIndex),
                            }));
                          }}
                        >
                          <MinusIcon className="h-4 w-4 inline" />
                        </button>
                  </div>
                  
              </div>

              {/* Role Name */}
              <div id={`userRoleName-${roleIndex}`} className="bg-gray-100 rounded p-1 m-1">
                <label htmlFor={`userRoleName-${roleIndex}`} className="block text-gray-600 text-sm font-bold mb-1">
                  Role Name
                </label>
                <input
                  type="text"
                  id={`userRoleName-${roleIndex}`}
                  name="userRoleName"
                  placeholder="Enter role name"
                  value={role.userRoleName || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormData((prevState) => {
                      const updatedRoles = [...prevState.userImprintRoles];
                      updatedRoles[roleIndex].userRoleName = value;
                      return { ...prevState, userImprintRoles: updatedRoles };
                    });
                  }}
                  className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                />
              </div>

              {/* Role Description */}
              <div id={`userRoleDesc-${roleIndex}`} className="bg-gray-100 rounded p-1 m-1">
                <label htmlFor={`userRoleDesc-${roleIndex}`} className="block text-gray-600 text-sm font-bold mb-1">
                  Role Description
                </label>
                <EtContentEditorLight2
                  value={role.userRoleDesc || ""}
                  handleChange={(content) => {
                    setFormData((prevState) => {
                      const updatedRoles = [...prevState.userImprintRoles];
                      updatedRoles[roleIndex].userRoleDesc = content;
                      return { ...prevState, userImprintRoles: updatedRoles };
                    });
                  }}
                  placeholderText="Write your role description here..."
                  editorKey={`roleDesc-${roleIndex}`}
                />
              </div>

              {/* Organization Name */}
              <div id={`userRoleOrgName-${roleIndex}`} className="bg-gray-100 rounded p-1 m-1">
                <label htmlFor={`userRoleOrgName-${roleIndex}`} className="block text-gray-600 text-sm font-bold mb-1">
                  Organization Name
                </label>
                <input
                  type="text"
                  id={`userRoleOrgName-${roleIndex}`}
                  name="userRoleOrgName"
                  placeholder="Enter organization name"
                  value={role.userRoleOrgName || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormData((prevState) => {
                      const updatedRoles = [...prevState.userImprintRoles];
                      updatedRoles[roleIndex].userRoleOrgName = value;
                      return { ...prevState, userImprintRoles: updatedRoles };
                    });
                  }}
                  className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                />
              </div>

              {/* Start Date */}
              <div id={`userRoleStartDate-${roleIndex}`} className="bg-gray-100 rounded p-1 m-1">
                <label htmlFor={`userRoleStartDate-${roleIndex}`} className="block text-gray-600 text-sm font-bold mb-1">
                  Start Date
                </label>
                <input
                  type="date"
                  id={`userRoleStartDate-${roleIndex}`}
                  name="userRoleStartDate"
                  value={role.userRoleStartDate || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormData((prevState) => {
                      const updatedRoles = [...prevState.userImprintRoles];
                      updatedRoles[roleIndex].userRoleStartDate = value;
                      return { ...prevState, userImprintRoles: updatedRoles };
                    });
                  }}
                  className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                />
              </div>

              {/* End Date */}
              <div id={`userRoleEndDate-${roleIndex}`} className="bg-gray-100 rounded p-1 m-1">
                <label htmlFor={`userRoleEndDate-${roleIndex}`} className="block text-gray-600 text-sm font-bold mb-1">
                  End Date
                </label>
                <input
                  type="date"
                  id={`userRoleEndDate-${roleIndex}`}
                  name="userRoleEndDate"
                  value={role.userRoleEndDate || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormData((prevState) => {
                      const updatedRoles = [...prevState.userImprintRoles];
                      updatedRoles[roleIndex].userRoleEndDate = value;
                      return { ...prevState, userImprintRoles: updatedRoles };
                    });
                  }}
                  className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                />
              </div>

              {/* Role Level */}
              <div id={`userRoleLevel-${roleIndex}`} className="bg-gray-100 rounded p-1 m-1">
                <label htmlFor={`userRoleLevel-${roleIndex}`} className="block text-gray-600 text-sm font-bold mb-1">
                  Role Level
                </label>
                <select
                  id={`userRoleLevel-${roleIndex}`}
                  name="userRoleLevel"
                  value={role.userRoleLevel || "Entry Level"}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormData((prevState) => {
                      const updatedRoles = [...prevState.userImprintRoles];
                      updatedRoles[roleIndex].userRoleLevel = value;
                      return { ...prevState, userImprintRoles: updatedRoles };
                    });
                  }}
                  className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                >
                  <option value="Founder">Founder</option>
                  <option value="CoFounder">Co-Founder</option>
                  <option value="CxO">CxO</option>
                  <option value="Senior Level">Senior Level</option>
                  <option value="Mid Level">Mid Level</option>
                  <option value="Entry Level">Entry Level</option>
                  <option value="Intern">Intern</option>
                </select>
              </div>

              {/* Stakeholders Section */}
              <div id={`stakeholders-${roleIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                <div className="flex justify-between items-center mb-2">
                  <p className="font-bold text-sm">Stakeholders</p>
                  <div className="flex justify-start items-center">
                    <p className="font-bold text-xs mx-1 px-1">Add</p>
                    <button
                      type="button"
                      className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                      onClick={() => {
                        setFormData((prevState) => {
                          const updatedRoles = [...prevState.userImprintRoles];
                          return {
                            ...prevState,
                            userImprintRoles: [
                              ...updatedRoles.slice(0, roleIndex),
                              {
                                ...updatedRoles[roleIndex],
                                userRoleStakeholders: [
                                  ...(updatedRoles[roleIndex].userRoleStakeholders || []),
                                  { stakeholderType: "" }, // Add new stakeholder
                                ],
                              },
                              ...updatedRoles.slice(roleIndex + 1),
                            ],
                          };
                        });
                      }}
                    >
                      <PlusIcon className="h-4 w-4 inline" />
                    </button>
                  </div>
                </div>

                {/* Stakeholder List */}
                {Array.isArray(role.userRoleStakeholders) &&
                  role.userRoleStakeholders.map((stakeholder, stakeholderIndex) => (
                    <div
                      key={`${roleIndex}-${stakeholderIndex}`}
                      className="p-2 border rounded mb-2 bg-white"
                    >
                      <div className="flex justify-between items-center">
                        <p className="font-bold text-sm">Stakeholder {stakeholderIndex + 1}</p>
                        <div className="flex justify-start items-center">
                          <p className="font-bold text-xs mx-1 px-1">Remove</p>
                          <button
                            type="button"
                            className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                            onClick={() => {
                              setFormData((prevState) => {
                                const updatedRoles = [...prevState.userImprintRoles];
                                return {
                                  ...prevState,
                                  userImprintRoles: [
                                    ...updatedRoles.slice(0, roleIndex),
                                    {
                                      ...updatedRoles[roleIndex],
                                      userRoleStakeholders: updatedRoles[roleIndex].userRoleStakeholders.filter(
                                        (_, i) => i !== stakeholderIndex
                                      ),
                                    },
                                    ...updatedRoles.slice(roleIndex + 1),
                                  ],
                                };
                              });
                            }}
                          >
                            <MinusIcon className="h-4 w-4 inline" />
                          </button>
                        </div>
                      </div>

                      {/* Stakeholder Type */}
                      <div
                        id={`stakeholderType-${roleIndex}-${stakeholderIndex}`}
                        className="flex justify-between items-center bg-gray-100 rounded p-1 m-1"
                      >
                        <label
                          htmlFor={`stakeholderType-${roleIndex}-${stakeholderIndex}`}
                          className="text-gray-600 text-sm font-bold w-1/3"
                        >
                          Stakeholder Type
                        </label>
                        <select
                          id={`stakeholderType-${roleIndex}-${stakeholderIndex}`}
                          value={stakeholder.stakeholderType || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedRoles = [...prevState.userImprintRoles];
                              return {
                                ...prevState,
                                userImprintRoles: [
                                  ...updatedRoles.slice(0, roleIndex),
                                  {
                                    ...updatedRoles[roleIndex],
                                    userRoleStakeholders: [
                                      ...updatedRoles[roleIndex].userRoleStakeholders.slice(
                                        0,
                                        stakeholderIndex
                                      ),
                                      { ...stakeholder, stakeholderType: value },
                                      ...updatedRoles[roleIndex].userRoleStakeholders.slice(
                                        stakeholderIndex + 1
                                      ),
                                    ],
                                  },
                                  ...updatedRoles.slice(roleIndex + 1),
                                ],
                              };
                            });
                          }}
                          className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
                        >
                          <option value="" disabled>
                            Select Stakeholder Type
                          </option>
                          <option value="Clients">Clients</option>
                          <option value="Org">Org</option>
                          <option value="Org Team">Org Team</option>
                          <option value="Partners">Partners</option>
                        </select>
                      </div>
                    </div>
                  ))}
              </div>

              {/* Role Focus Areas Section */}
              <div id="roleFocusAreas" className="border rounded p-2 m-2 bg-gray-50">
                <div className="flex justify-between items-center mb-2">
                  <p className="font-bold text-sm">Role Focus Areas</p>
                  <div className="flex justify-start items-center">
                    <p className="font-bold text-xs mx-1 px-1">Add</p>
                    <button
                      type="button"
                      className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                      onClick={() => {
                        setFormData((prevState) => ({
                          ...prevState,
                          userImprintRoles: prevState.userImprintRoles.map((role, roleIndex) => {
                            if (roleIndex === roleIndex) {
                              return {
                                ...role,
                                userRoleFocusAreas: [
                                  ...(role.userRoleFocusAreas || []),
                                  { roleFocusArea: "", roleIndustryType: null },
                                ],
                              };
                            }
                            return role;
                          }),
                        }));
                      }}
                    >
                      <PlusIcon className="h-4 w-4 inline" />
                    </button>
                  </div>
                </div>

                {/* Loop through each roleFocusArea */}
                {role.userRoleFocusAreas?.map((focusArea, focusIndex) => (
                  <div key={focusIndex} className="p-2 border rounded mb-2 bg-white">
                    <div className="flex justify-between items-center mb-2">
                      <p className="font-bold text-sm">Focus Area {focusIndex + 1}</p>
                      <div className="flex justify-start items-center">
                        <p className="font-bold text-xs mx-1 px-1">Remove</p>
                        <button
                          type="button"
                          className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                          onClick={() => {
                            setFormData((prevState) => ({
                              ...prevState,
                              userImprintRoles: prevState.userImprintRoles.map((role, roleIndex) => {
                                if (roleIndex === roleIndex) {
                                  return {
                                    ...role,
                                    userRoleFocusAreas: role.userRoleFocusAreas.filter((_, i) => i !== focusIndex),
                                  };
                                }
                                return role;
                              }),
                            }));
                          }}
                        >
                          <MinusIcon className="h-4 w-4 inline" />
                        </button>
                      </div>
                    </div>

                    {/* Dropdown for Role Focus Area */}
                    <div className="flex justify-between items-center bg-gray-100 rounded p-1 m-1">
                      <label htmlFor={`roleFocusArea-${focusIndex}`} className="text-gray-600 text-sm font-bold w-1/3">
                        Focus Area
                      </label>
                      <select
                        id={`roleFocusArea-${focusIndex}`}
                        value={focusArea.roleFocusArea || ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFormData((prevState) => ({
                            ...prevState,
                            userImprintRoles: prevState.userImprintRoles.map((role, roleIndex) => {
                              if (roleIndex === roleIndex) {
                                return {
                                  ...role,
                                  userRoleFocusAreas: role.userRoleFocusAreas.map((area, index) => {
                                    if (index === focusIndex) {
                                      return { ...area, roleFocusArea: value, roleIndustryType: null };
                                    }
                                    return area;
                                  }),
                                };
                              }
                              return role;
                            }),
                          }));
                        }}
                        className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
                      >
                        <option value="" disabled>Select Focus Area</option>
                        <option value="Industry">Industry</option>
                        <option value="Domain">Domain</option>
                        <option value="Function">Function</option>
                        <option value="TechArea">Tech Area</option>
                        <option value="Sales">Sales</option>
                        <option value="Service">Service</option>
                        <option value="Advisory">Advisory</option>
                      </select>
                    </div>

                    {/* Conditional Dropdown for Specific Type */}
                    {focusArea.roleFocusArea && (
                      <div className="flex justify-between items-center bg-gray-100 rounded p-1 m-1">
                        <label
                          htmlFor={`specificType-${focusIndex}`}
                          className="text-gray-600 text-sm font-bold w-1/3"
                        >
                          Select Specific {focusArea.roleFocusArea}
                        </label>
                        <select
                          id={`specificType-${focusIndex}`}
                          value={focusArea[`role${focusArea.roleFocusArea}Type`] || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => ({
                              ...prevState,
                              userImprintRoles: prevState.userImprintRoles.map((role, roleIndex) => {
                                if (roleIndex === roleIndex) {
                                  return {
                                    ...role,
                                    userRoleFocusAreas: role.userRoleFocusAreas.map((area, index) => {
                                      if (index === focusIndex) {
                                        return { ...area, [`role${focusArea.roleFocusArea}Type`]: value };
                                      }
                                      return area;
                                    }),
                                  };
                                }
                                return role;
                              }),
                            }));
                          }}
                          className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
                        >
                          <option value="" disabled>
                            Select {focusArea.roleFocusArea}
                          </option>
                          {focusArea.roleFocusArea === "Industry" &&
                            industryTypes?.map((type) => (
                              <option key={type._id} value={type._id}>
                                {type.capabilitiesIndustryTypeName}
                              </option>
                            ))}
                          {focusArea.roleFocusArea === "Domain" &&
                            domainTypes?.map((type) => (
                              <option key={type._id} value={type._id}>
                                {type.capabilitiesDomainTypeName}
                              </option>
                            ))}
                          {focusArea.roleFocusArea === "Function" &&
                            functionTypes?.map((type) => (
                              <option key={type._id} value={type._id}>
                                {type.capabilitiesFunctionTypeName}
                              </option>
                            ))}
                          {focusArea.roleFocusArea === "TechArea" &&
                            techAreaTypes?.map((type) => (
                              <option key={type._id} value={type._id}>
                                {type.capabilitiesTechAreaTypeName}
                              </option>
                            ))}
                          {focusArea.roleFocusArea === "Sales" &&
                            salesTypes?.map((type) => (
                              <option key={type._id} value={type._id}>
                                {type.capabilitiesSalesTypeName}
                              </option>
                            ))}
                          {focusArea.roleFocusArea === "Service" &&
                            serviceTypes?.map((type) => (
                              <option key={type._id} value={type._id}>
                                {type.capabilitiesServiceTypeName}
                              </option>
                            ))}
                          {focusArea.roleFocusArea === "Advisory" &&
                            advisoryTypes?.map((type) => (
                              <option key={type._id} value={type._id}>
                                {type.capabilitiesAdvisoryTypeName}
                              </option>
                            ))}
                          
                        </select>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {/* Contributions and Impacts Section */}
              <div id={`contributionsAndImpacts-${roleIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                <div className="flex justify-between items-center mb-2">
                  <p className="font-bold text-sm">Contributions and Impacts</p>
                  <div className="flex justify-start items-center">
                    <p className="font-bold text-xs mx-1 px-1">Add</p>
                    <button
                      type="button"
                      className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                      onClick={() => {
                        setFormData((prevState) => {
                          const updatedRoles = [...prevState.userImprintRoles];
                          return {
                            ...prevState,
                            userImprintRoles: updatedRoles.map((role, idx) =>
                              idx === roleIndex
                                ? {
                                    ...role,
                                    userRoleContributionsAndImpacts: [
                                      ...(role.userRoleContributionsAndImpacts || []),
                                      {
                                        roleContributionType: null,
                                        roleContributionNotes: [],
                                        roleContributionSelfEvalText: "",
                                        roleContributionSelfEvalScore: 0,
                                        roleImpactType: null,
                                        roleImpactNotes: [],
                                        roleImpactSelfEvalText: "",
                                        roleImpactSelfEvalScore: 0,
                                        roleContributionsAndImpactValidations: [],
                                      },
                                    ],
                                  }
                                : role
                            ),
                          };
                        });
                      }}
                    >
                      <PlusIcon className="h-4 w-4 inline" />
                    </button>
                  </div>
                </div>

                {/* Map through Contributions and Impacts */}
                {formData.userImprintRoles?.[roleIndex]?.userRoleContributionsAndImpacts?.map(
                  (contribution, contributionIndex) => (
                    <div
                      key={`contributionImpact-${roleIndex}-${contributionIndex}`}
                      className="p-2 border rounded mb-2 bg-white"
                    >
                      <div className="flex justify-between items-center">
                        <p className="font-bold text-sm">
                          Contribution & Impact {contributionIndex + 1}
                        </p>
                        <div className='flex justify-start items-center'>
                            <p className="font-bold text-xs mx-1 px-1">Remove</p>
                            <button
                          type="button"
                          className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                          onClick={() => {
                            setFormData((prevState) => {
                              const updatedRoles = [...prevState.userImprintRoles];
                              return {
                                ...prevState,
                                userImprintRoles: updatedRoles.map((role, idx) =>
                                  idx === roleIndex
                                    ? {
                                        ...role,
                                        userRoleContributionsAndImpacts: role.userRoleContributionsAndImpacts.filter(
                                          (_, cIdx) => cIdx !== contributionIndex
                                        ),
                                      }
                                    : role
                                ),
                              };
                            });
                          }}
                        >
                          <MinusIcon className="h-4 w-4 inline" />
                        </button>

                        </div>
                        
                      </div>

                      {/* 1. Contribution Type */}
                      <div id={`contributionType-${contributionIndex}`} className="bg-gray-100 rounded p-1 m-1">
                        <label
                          htmlFor={`contributionType-${roleIndex}-${contributionIndex}`}
                          className="block text-gray-600 text-sm font-bold mb-1"
                        >
                          Contribution Type
                        </label>
                        <select
                          id={`contributionType-${roleIndex}-${contributionIndex}`}
                          value={contribution.roleContributionType || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData((prevState) => {
                              const updatedRoles = [...prevState.userImprintRoles];
                              return {
                                ...prevState,
                                userImprintRoles: updatedRoles.map((role, idx) =>
                                  idx === roleIndex
                                    ? {
                                        ...role,
                                        userRoleContributionsAndImpacts: role.userRoleContributionsAndImpacts.map(
                                          (contrib, cIdx) =>
                                            cIdx === contributionIndex
                                              ? { ...contrib, roleContributionType: value }
                                              : contrib
                                        ),
                                      }
                                    : role
                                ),
                              };
                            });
                          }}
                          className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                        >
                          <option value="" disabled>
                            Select Contribution Type
                          </option>
                          {contributionTypes?.map((type) => (
                            <option key={type._id} value={type._id}>
                              {type.contributionTypeName}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* 2. Contribution Notes */}
                      <div className="mt-4">
                        <div id={`contributionNotes-${contributionIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                          <div className="flex justify-between items-center mb-2">
                            <p className="font-bold text-sm">Contribution Notes</p>
                            <div className="flex justify-start items-center">
                              <p className="font-bold text-xs mx-1 px-1">Add</p>
                              <button
                                type="button"
                                className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                                onClick={() => {
                                  setFormData((prevState) => {
                                    const updatedRoles = [...prevState.userImprintRoles];
                                    const updatedContributions =
                                      updatedRoles[roleIndex].userRoleContributionsAndImpacts[contributionIndex];
                                    const updatedNotes = updatedContributions.roleContributionNotes || [];
                                    return {
                                      ...prevState,
                                      userImprintRoles: updatedRoles.map((role, idx) =>
                                        idx === roleIndex
                                          ? {
                                              ...role,
                                              userRoleContributionsAndImpacts: role.userRoleContributionsAndImpacts.map(
                                                (contribution, cIdx) =>
                                                  cIdx === contributionIndex
                                                    ? {
                                                        ...contribution,
                                                        roleContributionNotes: [
                                                          ...updatedNotes,
                                                          {
                                                            roleContributionNoteText: "",
                                                            roleContributionNoteDate: new Date().toISOString(),
                                                            roleContributionNoteUpdateDate: null,
                                                          },
                                                        ],
                                                      }
                                                    : contribution
                                              ),
                                            }
                                          : role
                                      ),
                                    };
                                  });
                                }}
                              >
                                <PlusIcon className="h-4 w-4 inline" />
                              </button>
                            </div>
                          </div>

                          {formData.userImprintRoles?.[roleIndex]?.userRoleContributionsAndImpacts?.[
                            contributionIndex
                          ]?.roleContributionNotes?.map((note, noteIndex) => (
                            <div key={noteIndex} className="p-2 border rounded mb-2 bg-white">
                              <div className="flex justify-between items-center">
                                <p className="font-bold text-sm">Contribution Note {noteIndex + 1}</p>
                                <div className='flex justify-start items-center'>
                                  <p className="font-bold text-xs">Remove</p>
                                  <button
                                  type="button"
                                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                  onClick={() => {
                                    setFormData((prevState) => {
                                      const updatedRoles = [...prevState.userImprintRoles];
                                      const updatedContributions =
                                        updatedRoles[roleIndex].userRoleContributionsAndImpacts[contributionIndex];
                                      const updatedNotes = updatedContributions.roleContributionNotes.filter(
                                        (_, i) => i !== noteIndex
                                      );
                                      return {
                                        ...prevState,
                                        userImprintRoles: updatedRoles.map((role, idx) =>
                                          idx === roleIndex
                                            ? {
                                                ...role,
                                                userRoleContributionsAndImpacts: role.userRoleContributionsAndImpacts.map(
                                                  (contribution, cIdx) =>
                                                    cIdx === contributionIndex
                                                      ? { ...contribution, roleContributionNotes: updatedNotes }
                                                      : contribution
                                                ),
                                              }
                                            : role
                                        ),
                                      };
                                    });
                                  }}
                                >
                                  <MinusIcon className="h-4 w-4 inline" />
                                </button>

                                </div>
                                
                              </div>

                              {/* Note Text */}
                              <EtContentEditorLight2
                                value={note.roleContributionNoteText}
                                handleChange={(content) => {
                                  setFormData((prevState) => {
                                    const updatedRoles = [...prevState.userImprintRoles];
                                    const updatedContributions =
                                      updatedRoles[roleIndex].userRoleContributionsAndImpacts[contributionIndex];
                                    const updatedNotes = [...updatedContributions.roleContributionNotes];
                                    updatedNotes[noteIndex].roleContributionNoteText = content;
                                    updatedNotes[noteIndex].roleContributionNoteUpdateDate =
                                      new Date().toISOString();
                                    return {
                                      ...prevState,
                                      userImprintRoles: updatedRoles.map((role, idx) =>
                                        idx === roleIndex
                                          ? {
                                              ...role,
                                              userRoleContributionsAndImpacts: role.userRoleContributionsAndImpacts.map(
                                                (contribution, cIdx) =>
                                                  cIdx === contributionIndex
                                                    ? { ...contribution, roleContributionNotes: updatedNotes }
                                                    : contribution
                                              ),
                                            }
                                          : role
                                      ),
                                    };
                                  });
                                }}
                                placeholderText="Write your contribution note here..."
                                editorKey={`contributionNote-${roleIndex}-${contributionIndex}-${noteIndex}`}
                              />

                              {/* Note Dates */}
                              <p className="text-xs text-gray-500 mt-2">
                                Created on:{" "}
                                {new Date(note.roleContributionNoteDate).toLocaleDateString()}
                                {note.roleContributionNoteUpdateDate && (
                                  <span>
                                    , Updated on:{" "}
                                    {new Date(note.roleContributionNoteUpdateDate).toLocaleDateString()}
                                  </span>
                                )}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* 3. Contribution Self Evaluation */}
                      <div className="mt-4">
                          {/* Contribution Self Evaluation */}
                          <div id={`contributionSelfEval-${contributionIndex}`} className="bg-gray-100 rounded p-1 m-1">
                            <label
                              htmlFor={`contributionSelfEvalText-${roleIndex}-${contributionIndex}`}
                              className="block text-gray-600 text-sm font-bold mb-1"
                            >
                              Contribution Self Evaluation
                            </label>
                            <select
                              id={`contributionSelfEvalText-${roleIndex}-${contributionIndex}`}
                              value={contribution.roleContributionSelfEvalText || ""}
                              onChange={(e) => {
                                const value = e.target.value;
                                const scoreMapping = {
                                  None: 0,
                                  "Below My Average": 1,
                                  Average: 2,
                                  "Above Average": 3,
                                  "Over and Beyond!": 4,
                                  "Gave it All!": 5,
                                };

                                setFormData((prevState) => {
                                  const updatedRoles = [...prevState.userImprintRoles];
                                  return {
                                    ...prevState,
                                    userImprintRoles: updatedRoles.map((role, idx) =>
                                      idx === roleIndex
                                        ? {
                                            ...role,
                                            userRoleContributionsAndImpacts: role.userRoleContributionsAndImpacts.map(
                                              (contrib, cIdx) =>
                                                cIdx === contributionIndex
                                                  ? {
                                                      ...contrib,
                                                      roleContributionSelfEvalText: value,
                                                      roleContributionSelfEvalScore: scoreMapping[value] || 0,
                                                    }
                                                  : contrib
                                            ),
                                          }
                                        : role
                                    ),
                                  };
                                });
                              }}
                              className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                            >
                              <option value="None">None</option>
                              <option value="Below My Average">Below My Average</option>
                              <option value="Average">Average</option>
                              <option value="Above Average">Above Average</option>
                              <option value="Over and Beyond!">Over and Beyond!</option>
                              <option value="Gave it All!">Gave it All!</option>
                            </select>
                          </div>
                      </div>

                      {/* 4. Impact Type */}
                      <div className="mt-4">
                        {/* Impact Type */}
                        <div id={`impactType-${contributionIndex}`} className="bg-gray-100 rounded p-1 m-1">
                          <label
                            htmlFor={`impactType-${roleIndex}-${contributionIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            Impact Type
                          </label>
                          <select
                            id={`impactType-${roleIndex}-${contributionIndex}`}
                            value={contribution.roleImpactType || ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFormData((prevState) => {
                                const updatedRoles = [...prevState.userImprintRoles];
                                return {
                                  ...prevState,
                                  userImprintRoles: updatedRoles.map((role, idx) =>
                                    idx === roleIndex
                                      ? {
                                          ...role,
                                          userRoleContributionsAndImpacts: role.userRoleContributionsAndImpacts.map(
                                            (contrib, cIdx) =>
                                              cIdx === contributionIndex
                                                ? { ...contrib, roleImpactType: value }
                                                : contrib
                                          ),
                                        }
                                      : role
                                  ),
                                };
                              });
                            }}
                            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                          >
                            <option value="" disabled>
                              Select Impact Type
                            </option>
                            {impactTypes?.map((type) => (
                              <option key={type._id} value={type._id}>
                                {type.impactTypeName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      {/* 5. Impact Notes */}
                      <div className="mt-4">
                          {/* Impact Notes Section */}
                            <div id={`impactNotes-${contributionIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                              <div className="flex justify-between items-center">
                                <p className="font-bold text-sm">Impact Notes</p>
                                <div className="flex justify-start items-center">
                                  <p className="font-bold text-xs mx-1 px-1">Add</p>
                                  <button
                                    type="button"
                                    className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                                    onClick={() => {
                                      setFormData((prevState) => {
                                        const updatedRoles = [...prevState.userImprintRoles];
                                        return {
                                          ...prevState,
                                          userImprintRoles: updatedRoles.map((role, idx) =>
                                            idx === roleIndex
                                              ? {
                                                  ...role,
                                                  userRoleContributionsAndImpacts: role.userRoleContributionsAndImpacts.map(
                                                    (contrib, cIdx) =>
                                                      cIdx === contributionIndex
                                                        ? {
                                                            ...contrib,
                                                            roleImpactNotes: [
                                                              ...(contrib.roleImpactNotes || []),
                                                              {
                                                                roleImpactNoteText: "",
                                                                roleImpactNoteDate: new Date().toISOString(),
                                                                roleImpactNoteUpdateDate: null,
                                                              },
                                                            ],
                                                          }
                                                        : contrib
                                                  ),
                                                }
                                              : role
                                          ),
                                        };
                                      });
                                    }}
                                  >
                                    <PlusIcon className="h-4 w-4 inline" />
                                  </button>
                                </div>
                              </div>

                              {contribution.roleImpactNotes?.map((note, noteIndex) => (
                                <div key={noteIndex} className="p-2 border rounded mb-2 bg-white">
                                  <div className="flex justify-between items-center">
                                    <p className="font-bold text-sm">Impact Note {noteIndex + 1}</p>
                                    <div className="flex justify-start items-center">
                                      <p className="font-bold text-xs mx-1 px-1">Remove</p>
                                      <button
                                        type="button"
                                        className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                        onClick={() => {
                                          setFormData((prevState) => {
                                            const updatedRoles = [...prevState.userImprintRoles];
                                            return {
                                              ...prevState,
                                              userImprintRoles: updatedRoles.map((role, idx) =>
                                                idx === roleIndex
                                                  ? {
                                                      ...role,
                                                      userRoleContributionsAndImpacts: role.userRoleContributionsAndImpacts.map(
                                                        (contrib, cIdx) =>
                                                          cIdx === contributionIndex
                                                            ? {
                                                                ...contrib,
                                                                roleImpactNotes: contrib.roleImpactNotes.filter(
                                                                  (_, i) => i !== noteIndex
                                                                ),
                                                              }
                                                            : contrib
                                                      ),
                                                    }
                                                  : role
                                              ),
                                            };
                                          });
                                        }}
                                      >
                                        <MinusIcon className="h-4 w-4 inline" />
                                      </button>
                                    </div>
                                  </div>

                                  {/* Note Text */}
                                  <EtContentEditorLight2
                                    value={note.roleImpactNoteText || ""}
                                    handleChange={(content) => {
                                      setFormData((prevState) => {
                                        const updatedRoles = [...prevState.userImprintRoles];
                                        return {
                                          ...prevState,
                                          userImprintRoles: updatedRoles.map((role, idx) =>
                                            idx === roleIndex
                                              ? {
                                                  ...role,
                                                  userRoleContributionsAndImpacts: role.userRoleContributionsAndImpacts.map(
                                                    (contrib, cIdx) =>
                                                      cIdx === contributionIndex
                                                        ? {
                                                            ...contrib,
                                                            roleImpactNotes: contrib.roleImpactNotes.map((n, nIdx) =>
                                                              nIdx === noteIndex
                                                                ? { ...n, roleImpactNoteText: content }
                                                                : n
                                                            ),
                                                          }
                                                        : contrib
                                                  ),
                                                }
                                              : role
                                          ),
                                        };
                                      });
                                    }}
                                    placeholderText="Write your impact note here..."
                                    editorKey={`impactNote-${roleIndex}-${contributionIndex}-${noteIndex}`}
                                  />

                                  {/* Note Date (Read-Only) */}
                                  <p className="text-xs text-gray-500 mt-2">
                                    Created on: {new Date(note.roleImpactNoteDate).toLocaleDateString()}
                                    {note.roleImpactNoteUpdateDate && (
                                      <span>
                                        , Updated on: {new Date(note.roleImpactNoteUpdateDate).toLocaleDateString()}
                                      </span>
                                    )}
                                  </p>
                                </div>
                              ))}
                            </div>
                      </div>

                      {/* 6. Impact Self Evaluation */}
                      <div className="mt-4">
                        {/* Impact Self Evaluation */}
                        <div id={`impactSelfEval-${contributionIndex}`} className="bg-gray-100 rounded p-1 m-1">
                          <label
                            htmlFor={`impactSelfEvalText-${roleIndex}-${contributionIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            Impact Self Evaluation
                          </label>
                          <select
                            id={`impactSelfEvalText-${roleIndex}-${contributionIndex}`}
                            value={contribution.roleImpactSelfEvalText || ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              const scoreMapping = {
                                None: 0,
                                Low: 1,
                                Moderate: 2,
                                High: 3,
                                Striking: 4,
                                Lightning: 5,
                              };

                              setFormData((prevState) => {
                                const updatedRoles = [...prevState.userImprintRoles];
                                return {
                                  ...prevState,
                                  userImprintRoles: updatedRoles.map((role, idx) =>
                                    idx === roleIndex
                                      ? {
                                          ...role,
                                          userRoleContributionsAndImpacts: role.userRoleContributionsAndImpacts.map(
                                            (contrib, cIdx) =>
                                              cIdx === contributionIndex
                                                ? {
                                                    ...contrib,
                                                    roleImpactSelfEvalText: value,
                                                    roleImpactSelfEvalScore: scoreMapping[value] || 0,
                                                  }
                                                : contrib
                                          ),
                                        }
                                      : role
                                  ),
                                };
                              });
                            }}
                            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                          >
                            <option value="None">None</option>
                            <option value="Low">Low</option>
                            <option value="Moderate">Moderate</option>
                            <option value="High">High</option>
                            <option value="Striking">Striking</option>
                            <option value="Lightning">Lightning</option>
                          </select>
                        </div>
                      </div>

                      {/* 7. Validations */}
                      <div className="mt-4">
                        {/* Contributions and Impact Validations Section */}
                        <div id={`contributionsAndImpactValidations-${contributionIndex}`} className="border rounded p-2 m-2 bg-gray-50">
                          <div className="flex justify-between items-center">
                            <p className="font-bold text-sm">Contributions and Impact Validations</p>
                            <div className="flex justify-start items-center">
                              <p className="font-bold text-xs mx-1 px-1">Add</p>
                              <button
                                type="button"
                                className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                                onClick={() => {
                                  setFormData((prevState) => {
                                    const updatedRoles = [...prevState.userImprintRoles];
                                    return {
                                      ...prevState,
                                      userImprintRoles: updatedRoles.map((role, idx) =>
                                        idx === roleIndex
                                          ? {
                                              ...role,
                                              userRoleContributionsAndImpacts: role.userRoleContributionsAndImpacts.map(
                                                (contrib, cIdx) =>
                                                  cIdx === contributionIndex
                                                    ? {
                                                        ...contrib,
                                                        roleContributionsAndImpactValidations: [
                                                          ...(contrib.roleContributionsAndImpactValidations || []),
                                                          {
                                                            validatedBy: null,
                                                            validatedOn: new Date().toISOString(),
                                                            contributionEvalText: "Absent",
                                                            contributionEvalScore: 0,
                                                            impactEvalText: "Absent",
                                                            impactEvalScore: 0,
                                                            validationNotes: [],
                                                          },
                                                        ],
                                                      }
                                                    : contrib
                                              ),
                                            }
                                          : role
                                      ),
                                    };
                                  });
                                }}
                              >
                                <PlusIcon className="h-4 w-4 inline" />
                              </button>
                            </div>
                          </div>

                          {contribution.roleContributionsAndImpactValidations?.map((validation, validationIndex) => (
                            <div key={validationIndex} className="p-2 border rounded mb-2 bg-white">
                              <div className="flex justify-between items-center">
                                <p className="font-bold text-sm">Validation {validationIndex + 1}</p>
                                <div className="flex justify-start items-center">
                                  <p className="font-bold text-xs mx-1 px-1">Remove</p>
                                  <button
                                    type="button"
                                    className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                                    onClick={() => {
                                      setFormData((prevState) => {
                                        const updatedRoles = [...prevState.userImprintRoles];
                                        return {
                                          ...prevState,
                                          userImprintRoles: updatedRoles.map((role, idx) =>
                                            idx === roleIndex
                                              ? {
                                                  ...role,
                                                  userRoleContributionsAndImpacts: role.userRoleContributionsAndImpacts.map(
                                                    (contrib, cIdx) =>
                                                      cIdx === contributionIndex
                                                        ? {
                                                            ...contrib,
                                                            roleContributionsAndImpactValidations:
                                                              contrib.roleContributionsAndImpactValidations.filter(
                                                                (_, vIdx) => vIdx !== validationIndex
                                                              ),
                                                          }
                                                        : contrib
                                                  ),
                                                }
                                              : role
                                          ),
                                        };
                                      });
                                    }}
                                  >
                                    <MinusIcon className="h-4 w-4 inline" />
                                  </button>
                                </div>
                              </div>

                              {/* Validator */}
                              <div className="bg-gray-100 rounded p-1 m-1">
                                <label
                                  htmlFor={`validatedBy-${roleIndex}-${contributionIndex}-${validationIndex}`}
                                  className="block text-gray-600 text-sm font-bold mb-1"
                                >
                                  Validator
                                </label>
                                <input
                                  type="text"
                                  id={`validatedBy-${roleIndex}-${contributionIndex}-${validationIndex}`}
                                  value={validation.validatedBy || ""}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setFormData((prevState) => {
                                      const updatedRoles = [...prevState.userImprintRoles];
                                      return {
                                        ...prevState,
                                        userImprintRoles: updatedRoles.map((role, idx) =>
                                          idx === roleIndex
                                            ? {
                                                ...role,
                                                userRoleContributionsAndImpacts: role.userRoleContributionsAndImpacts.map(
                                                  (contrib, cIdx) =>
                                                    cIdx === contributionIndex
                                                      ? {
                                                          ...contrib,
                                                          roleContributionsAndImpactValidations: contrib.roleContributionsAndImpactValidations.map(
                                                            (val, vIdx) =>
                                                              vIdx === validationIndex
                                                                ? { ...val, validatedBy: value }
                                                                : val
                                                          ),
                                                        }
                                                      : contrib
                                                ),
                                              }
                                            : role
                                        ),
                                      };
                                    });
                                  }}
                                  className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                />
                              </div>

                              {/* Contribution Evaluation */}
                              <div className="bg-gray-100 rounded p-1 m-1">
                                <label
                                  htmlFor={`contributionEvalText-${roleIndex}-${contributionIndex}-${validationIndex}`}
                                  className="block text-gray-600 text-sm font-bold mb-1"
                                >
                                  Contribution Evaluation
                                </label>
                                <select
                                  id={`contributionEvalText-${roleIndex}-${contributionIndex}-${validationIndex}`}
                                  value={validation.contributionEvalText || "Absent"}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const scoreMapping = {
                                      Absent: 0,
                                      Disappointing: 1,
                                      Unsatisfying: 2,
                                      Gratifying: 3,
                                      Fulfilling: 4,
                                      Indebting: 5,
                                    };

                                    setFormData((prevState) => {
                                      const updatedRoles = [...prevState.userImprintRoles];
                                      return {
                                        ...prevState,
                                        userImprintRoles: updatedRoles.map((role, idx) =>
                                          idx === roleIndex
                                            ? {
                                                ...role,
                                                userRoleContributionsAndImpacts: role.userRoleContributionsAndImpacts.map(
                                                  (contrib, cIdx) =>
                                                    cIdx === contributionIndex
                                                      ? {
                                                          ...contrib,
                                                          roleContributionsAndImpactValidations: contrib.roleContributionsAndImpactValidations.map(
                                                            (val, vIdx) =>
                                                              vIdx === validationIndex
                                                                ? {
                                                                    ...val,
                                                                    contributionEvalText: value,
                                                                    contributionEvalScore: scoreMapping[value] || 0,
                                                                  }
                                                                : val
                                                          ),
                                                        }
                                                      : contrib
                                                ),
                                              }
                                            : role
                                        ),
                                      };
                                    });
                                  }}
                                  className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                                >
                                  <option value="Absent">Absent</option>
                                  <option value="Disappointing">Disappointing</option>
                                  <option value="Unsatisfying">Unsatisfying</option>
                                  <option value="Gratifying">Gratifying</option>
                                  <option value="Fulfilling">Fulfilling</option>
                                  <option value="Indebting">Indebting</option>
                                </select>
                              </div>

                              
                              {/* Impact Evaluation */}
                              <div className="bg-gray-100 rounded p-1 m-1">
                                <label
                                  htmlFor={`impactEvalText-${roleIndex}-${contributionIndex}-${validationIndex}`}
                                  className="block text-gray-600 text-sm font-bold mb-1"
                                >
                                  Impact Evaluation
                                </label>
                                <select
                                  id={`impactEvalText-${roleIndex}-${contributionIndex}-${validationIndex}`}
                                  value={validation.impactEvalText || ""}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const scoreMapping = {
                                      "Absent": 0,
                                      "Starting": 1,
                                      "Promising": 2,
                                      "Assuring": 3,
                                      "Transforming": 4,
                                      "Game Changing": 5,
                                    };
                                    setFormData((prevState) => {
                                      const updatedRoles = [...prevState.userImprintRoles];
                                      return {
                                        ...prevState,
                                        userImprintRoles: updatedRoles.map((role, idx) =>
                                          idx === roleIndex
                                            ? {
                                                ...role,
                                                userRoleContributionsAndImpacts: role.userRoleContributionsAndImpacts.map(
                                                  (contribution, cIdx) =>
                                                    cIdx === contributionIndex
                                                      ? {
                                                          ...contribution,
                                                          roleContributionsAndImpactValidations: contribution.roleContributionsAndImpactValidations.map(
                                                            (val, vIdx) =>
                                                              vIdx === validationIndex
                                                                ? {
                                                                    ...val,
                                                                    impactEvalText: value,
                                                                    impactEvalScore: scoreMapping[value],
                                                                  }
                                                                : val
                                                          ),
                                                        }
                                                      : contribution
                                                ),
                                              }
                                            : role
                                        ),
                                      };
                                    });
                                  }}
                                  className="w-full rounded p-2 border border-gray-300 focus:outline-none"
                                >
                                  <option value="">Select Impact Evaluation</option>
                                  <option value="Absent">Absent</option>
                                  <option value="Starting">Starting</option>
                                  <option value="Promising">Promising</option>
                                  <option value="Assuring">Assuring</option>
                                  <option value="Transforming">Transforming</option>
                                  <option value="Game Changing">Game Changing</option>
                                </select>
                              </div>

                              {/* Validation Notes */}
                              <div className="bg-gray-100 rounded p-1 m-1">
                                <label
                                  htmlFor={`validationNotes-${roleIndex}-${contributionIndex}-${validationIndex}`}
                                  className="block text-gray-600 text-sm font-bold mb-1"
                                >
                                  Validation Notes
                                </label>
                                <EtContentEditorLight2
                                  value={validation.validationNotes || ""}
                                  handleChange={(content) => {
                                    setFormData((prevState) => {
                                      const updatedRoles = [...prevState.userImprintRoles];
                                      return {
                                        ...prevState,
                                        userImprintRoles: updatedRoles.map((role, idx) =>
                                          idx === roleIndex
                                            ? {
                                                ...role,
                                                userRoleContributionsAndImpacts: role.userRoleContributionsAndImpacts.map(
                                                  (contrib, cIdx) =>
                                                    cIdx === contributionIndex
                                                      ? {
                                                          ...contrib,
                                                          roleContributionsAndImpactValidations: contrib.roleContributionsAndImpactValidations.map(
                                                            (val, vIdx) =>
                                                              vIdx === validationIndex
                                                                ? { ...val, validationNotes: content }
                                                                : val
                                                          ),
                                                        }
                                                      : contrib
                                                ),
                                              }
                                            : role
                                        ),
                                      };
                                    });
                                  }}
                                  placeholderText="Write validation notes here..."
                                  editorKey={`validationNotes-${roleIndex}-${contributionIndex}-${validationIndex}`}
                                />
                              </div>

                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>

            </div>
          ))}
        </div>

        {/* User Imprint Role Self Notes Section */}
        <div id="userImprintRoleSelfNotes" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center">
            <p className="font-bold text-sm">User Imprint Role Self Notes</p>
            <div className="flex justify-start items-center">
              <p className="font-bold text-xs mx-1 px-1">Add</p>
              <button
                type="button"
                className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                onClick={() => {
                  setFormData((prevState) => ({
                    ...prevState,
                    userImprintRoleSelfNotes: [
                      ...(Array.isArray(prevState.userImprintRoleSelfNotes)
                        ? prevState.userImprintRoleSelfNotes
                        : []),
                      {
                        userImprintRoleSelfNoteText: "",
                        userImprintRoleSelfNoteDate: new Date().toISOString(),
                        userImprintRoleSelfNoteUpdateDate: null,
                      },
                    ],
                  }));
                }}
              >
                <PlusIcon className="h-4 w-4 inline" />
              </button>
            </div>
          </div>

          {formData.userImprintRoleSelfNotes?.map((note, noteIndex) => (
            <div key={noteIndex} className="p-2 border rounded mb-2 bg-white">
              <div className="flex justify-between items-center">
                <p className="font-bold text-sm">Self Note {noteIndex + 1}</p>
                <div className="flex justify-start items-center">
                  <p className="font-bold text-xs mx-1 px-1">Remove</p>
                  <button
                    type="button"
                    className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                    onClick={() => {
                      setFormData((prevState) => ({
                        ...prevState,
                        userImprintRoleSelfNotes: prevState.userImprintRoleSelfNotes.filter(
                          (_, i) => i !== noteIndex
                        ),
                      }));
                    }}
                  >
                    <MinusIcon className="h-4 w-4 inline" />
                  </button>
                </div>
              </div>

              {/* Note Text */}
              <EtContentEditorLight2
                value={note.userImprintRoleSelfNoteText}
                handleChange={(content) => {
                  setFormData((prevState) => {
                    const updatedNotes = [...prevState.userImprintRoleSelfNotes];
                    updatedNotes[noteIndex].userImprintRoleSelfNoteText = content;
                    updatedNotes[noteIndex].userImprintRoleSelfNoteUpdateDate = new Date().toISOString();
                    return { ...prevState, userImprintRoleSelfNotes: updatedNotes };
                  });
                }}
                placeholderText="Write your note here..."
                editorKey={`selfNote-${noteIndex}`}
              />

              {/* Note Date (Read-Only) */}
              <p className="text-xs text-gray-500 mt-2">
                Created on: {new Date(note.userImprintRoleSelfNoteDate).toLocaleDateString()}
                {note.userImprintRoleSelfNoteUpdateDate && (
                  <span>
                    , Updated on: {new Date(note.userImprintRoleSelfNoteUpdateDate).toLocaleDateString()}
                  </span>
                )}
              </p>
            </div>
          ))}
        </div>

        {/* Validation Notes Section */}
        <div id="validationNotes" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <p className="font-bold text-sm">Validation Notes</p>
            <div className="flex justify-start items-center">
              <p className="font-bold text-xs mx-1 px-1">Add</p>
              <button
                type="button"
                className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                onClick={() => {
                  setFormData((prevState) => ({
                    ...prevState,
                    userImprintRoleValidationNotes: [
                      ...(Array.isArray(prevState.userImprintRoleValidationNotes)
                        ? prevState.userImprintRoleValidationNotes
                        : []),
                      {
                        validatedBy: null,
                        userImprintRoleValidationNoteText: "",
                        userImprintRoleValidationNoteDate: new Date().toISOString(),
                        userImprintRoleValidationNoteUpdateDate: null,
                      },
                    ],
                  }));
                }}
              >
                <PlusIcon className="h-4 w-4 inline" />
              </button>
            </div>
          </div>

          {formData.userImprintRoleValidationNotes?.map((note, noteIndex) => (
            <div key={noteIndex} className="p-2 border rounded mb-2 bg-white">
              <div className="flex justify-between items-center">
                <p className="font-bold text-sm">Validation Note: {noteIndex + 1}</p>
                <div className="flex justify-start items-center">
                  <button
                    type="button"
                    className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                    onClick={() => {
                      setFormData((prevState) => ({
                        ...prevState,
                        userImprintRoleValidationNotes: prevState.userImprintRoleValidationNotes.filter(
                          (_, i) => i !== noteIndex
                        ),
                      }));
                    }}
                  >
                    <MinusIcon className="h-4 w-4 inline" />
                  </button>
                </div>
              </div>

              {/* Validated By */}
              {/* <div className="flex justify-between items-center bg-gray-100 rounded p-1 m-1">
                <label htmlFor={`validatedBy-${noteIndex}`} className="text-gray-600 text-sm font-bold w-1/3">
                  Validated By
                </label>
                <select
                  id={`validatedBy-${noteIndex}`}
                  value={note.validatedBy || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormData((prevState) => {
                      const updatedNotes = [...prevState.userImprintRoleValidationNotes];
                      updatedNotes[noteIndex].validatedBy = value;
                      return { ...prevState, userImprintRoleValidationNotes: updatedNotes };
                    });
                  }}
                  className="w-2/3 rounded p-2 border border-gray-300 focus:outline-none"
                >
                  <option value="" disabled>
                    Select Validator
                  </option> */}
                  {/* Replace with your list of users */}
                  {/* {users?.map((user) => (
                    <option key={user._id} value={user._id}>
                      {user.name}
                    </option>
                  ))} */}
                {/*</select>
              </div> */}

              {/* Note Text */}
              <EtContentEditorLight2
                value={note.userImprintRoleValidationNoteText}
                handleChange={(content) => {
                  setFormData((prevState) => {
                    const updatedNotes = [...prevState.userImprintRoleValidationNotes];
                    updatedNotes[noteIndex].userImprintRoleValidationNoteText = content;
                    updatedNotes[noteIndex].userImprintRoleValidationNoteUpdateDate = new Date().toISOString();
                    return { ...prevState, userImprintRoleValidationNotes: updatedNotes };
                  });
                }}
                placeholderText="Write your validation note here..."
                editorKey={`validationNote-${noteIndex}`}
              />

              {/* Note Date */}
              <p className="text-xs text-gray-500 mt-2">
                Created on: {new Date(note.userImprintRoleValidationNoteDate).toLocaleDateString()}
                {note.userImprintRoleValidationNoteUpdateDate && (
                  <span>
                    , Updated on: {new Date(note.userImprintRoleValidationNoteUpdateDate).toLocaleDateString()}
                  </span>
                )}
              </p>
            </div>
          ))}
        </div>

        {/* About Me Section */}
        <div id="aboutMe" className="bg-gray-100 rounded p-1 m-1">
          <label
            htmlFor="aboutMe"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
            About Me
          </label>
          <EtContentEditorLight2
            value={formData.aboutMe}
            handleChange={(content) => {
              setFormData((prevState) => ({
                ...prevState,
                aboutMe: content,
              }));
            }}
            placeholderText="Write about yourself here..."
            editorKey="aboutMe-editor"
          />
        </div>

        {/* Picture Upload Section */}
        <div id="pic" className="bg-gray-100 rounded p-1 m-1">
          <label
            htmlFor="pic"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
            Role Profile Picture
          </label>
          <input
            type="file"
            name="pic"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                convertPicToBase64(file)
                  .then((base64) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      pic: base64,
                    }));
                  })
                  .catch((error) => {
                    console.error("Error converting image to Base64:", error);
                  });
              }
            }}
            className="w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:ring-orange-300"
          />
          {formData.pic && (
            <img
              src={formData.pic}
              alt="Preview"
              className="mt-2 h-20 w-20 rounded-full border border-gray-300"
            />
          )}
        </div>

        {/* Active Toggle Section */}
        <div id="active" className="bg-gray-100 rounded p-1 m-1">
          <label
            htmlFor="active"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
            Is Active?
          </label>
          <input
            type="checkbox"
            name="active"
            id="active"
            checked={formData.active}
            onChange={(e) => {
              setFormData((prevState) => ({
                ...prevState,
                active: e.target.checked,
              }));
            }}
            className="w-5 h-5 text-orange-500 rounded border-gray-300 focus:ring-orange-500 focus:border-orange-500"
          />
        </div>

        {/* Submission Actions Bar */}
        <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
          <EtBtnFormBasic 
            name={'Create'} 
            type={'create'} 
            handleClick={handleSubmit} 
            />
          <EtBtnFormBasic
            name={'Clear'}
            type={'clear'}
            handleClick={handleClear}
          />
          <EtBtnFormBasic
            name={'Cancel'}
            type={'cancel'}
            handleClick={handleCancel}
          />
        </div>
      </form>
    </div>
  )
}

export default EtCreateUserImprintRoleComp