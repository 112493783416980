import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const userImprintCapabilitiesDomainsAdapter = createEntityAdapter({})

const initialState = userImprintCapabilitiesDomainsAdapter.getInitialState()

export const userImprintCapabilitiesDomainsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserImprintCapabilitiesDomains: builder.query({
            query: () => ({
                url: '/userImprintCapabilitiesDomains/getUserImprintCapabilitiesDomains',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserImprintCapabilitiesDomains = responseData.map(userImprintCapabilitiesDomain => {
                    userImprintCapabilitiesDomain.id = userImprintCapabilitiesDomain._id
                    return userImprintCapabilitiesDomain
                })
                return userImprintCapabilitiesDomainsAdapter.setAll(initialState, loadedUserImprintCapabilitiesDomains)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserImprintCapabilitiesDomain', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserImprintCapabilitiesDomain', id}))
                    ]
                } else {
                    return [ { type: 'UserImprintCapabilitiesDomain', id: 'LIST'}]
                }
            },
        }),
        createNewUserImprintCapabilitiesDomain: builder.mutation({
            query: initialUserImprintCapabilitiesDomain => ({
                url: '/userImprintCapabilitiesDomains/createNewUserImprintCapabilitiesDomain',
                method: 'POST',
                body: {...initialUserImprintCapabilitiesDomain,}
            }),
            invalidatesTags: [
                { type: 'UserImprintCapabilitiesDomain', id: "LIST" }
            ] 
        }),
        updateUserImprintCapabilitiesDomain: builder.mutation({
            query: initialUserImprintCapabilitiesDomain => ({
                url: '/userImprintCapabilitiesDomains/updateUserImprintCapabilitiesDomain',
                method: 'PATCH',
                body: {...initialUserImprintCapabilitiesDomain,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserImprintCapabilitiesDomain', id: arg.id },
                { type: 'UserImprintCapabilitiesDomain', id: 'LIST' }
            ],
        }),
        deleteUserImprintCapabilitiesDomain: builder.mutation({
            query: ({id}) => ({
                url: '/userImprintCapabilitiesDomains/deleteUserImprintCapabilitiesDomain',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserImprintCapabilitiesDomain', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserImprintCapabilitiesDomainsQuery,
    useCreateNewUserImprintCapabilitiesDomainMutation,
    useUpdateUserImprintCapabilitiesDomainMutation,
    useDeleteUserImprintCapabilitiesDomainMutation,
} = userImprintCapabilitiesDomainsApiSlice

// returns the query result object
export const selectUserImprintCapabilitiesDomainResult = userImprintCapabilitiesDomainsApiSlice.endpoints.getUserImprintCapabilitiesDomains.select()

// create memoized selector
const selectUserImprintCapabilitiesDomainsData = createSelector(
    selectUserImprintCapabilitiesDomainResult,
    userImprintCapabilitiesDomainResult => userImprintCapabilitiesDomainResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserImprintCapabilitiesDomains,
    selectById: selectUserImprintCapabilitiesDomainById,
    selectIds: selectUserImprintCapabilitiesDomainIds,
} = userImprintCapabilitiesDomainsAdapter.getSelectors(state => selectUserImprintCapabilitiesDomainsData(state) ?? initialState)