import React from 'react';

 import { Plate, createPlugins} from '@udecode/plate-common'
 import { Editor } from '@/components/plate-ui/Editor'
 import { FixedToolbar } from '@/components/plate-ui/FixedToolbar';
 import { TooltipProvider } from '@/components/plate-ui/tooltip'; 
//  import { FixedToolbarButtons } from '../plate-ui/fixed-toolbar-buttons';
import { FixedToolbarButtonsBasic } from '../plate-ui/fixed-toolbar-buttons-basic';
 import { editableProps } from '@/components/plate/editableProps'
 import {
    createBoldPlugin,
    // createCodePlugin,
    createItalicPlugin,
    createStrikethroughPlugin,
    createUnderlinePlugin,
    createSubscriptPlugin,
    createSuperscriptPlugin,
  } from '@udecode/plate-basic-marks';
import { createBlockquotePlugin } from '@udecode/plate-block-quote';
// import { createCodeBlockPlugin } from '@udecode/plate-code-block';
import { createHeadingPlugin } from '@udecode/plate-heading';
import { createParagraphPlugin } from '@udecode/plate-paragraph';
// import { createListPlugin } from '@udecode/plate-list' // 18 Jun 2024
// 19 Jun 2024
import { 
  createFontBackgroundColorPlugin,
  createFontColorPlugin,
  // createFontFamilyPlugin,
  // createFontSizePlugin,
  // createFontWeightPlugin,  
  } from '@udecode/plate-font' 
import { createAlignPlugin } from '@udecode/plate-alignment';
import { createAutoformatPlugin } from '@udecode/plate-autoformat';
import { createLinkPlugin } from '@udecode/plate-link';
// import { createCaptionPlugin } from '@udecode/plate-caption';
// import {
//   createImagePlugin,
//   createMediaEmbedPlugin,
//   ELEMENT_IMAGE,
//   ELEMENT_MEDIA_EMBED,
// } from '@udecode/plate-media';
// import { createSelectOnBackspacePlugin } from '@udecode/plate-select';
// 20 Jun 2024
// import { createLineHeightPlugin } from '@udecode/plate-line-height';
// import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { createIndentPlugin } from '@udecode/plate-indent';
import { createIndentListPlugin } from '@udecode/plate-indent-list';
// import { createTodoListPlugin } from '@udecode/plate-list';
//21 Jun 2024
import { createJuicePlugin } from '@udecode/plate-juice';
import { createDeserializeDocxPlugin } from '@udecode/plate-serializer-docx';


import { createPlateUI } from '@/lib/plate/createPlateUI';
import { autoformatRules } from '@/lib/plate/autoformatRules';
import { linkPlugin } from '@/lib/plate/linkPlugin';
// import { captionPlugin } from '@/lib/plate/captionPlugin';
import { indentPlugin } from '@/lib/plate/indentPlugin';
import { indentListPlugin } from '@/lib/plate/indentListPlugin';


const plugins = createPlugins(
     [
        createParagraphPlugin(),
        createBlockquotePlugin(),
        // createCodeBlockPlugin(),
        createHeadingPlugin(),
    
        createBoldPlugin(),
        createItalicPlugin(),
        createUnderlinePlugin(),
        createStrikethroughPlugin(),
        // createCodePlugin(),
        createSubscriptPlugin(),
        createSuperscriptPlugin(),

        // createListPlugin(), // 18 Jun 2024
        // 19 Jun 2024
        createFontBackgroundColorPlugin(),
        createFontColorPlugin(),
        // createFontSizePlugin(),
        // createFontFamilyPlugin(),
        // createFontWeightPlugin(),  

        createAlignPlugin(),
        createAutoformatPlugin({
          options: {
            rules: autoformatRules,
            enableUndoOnDelete: true,
          },
        }),
        createLinkPlugin(linkPlugin),
        // createCaptionPlugin(captionPlugin),
        // createImagePlugin(),
        // createMediaEmbedPlugin(),
        // createSelectOnBackspacePlugin({
        //   options: {
        //     query: {
        //       allow: [ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED],
        //     },
        //   },
        // }),
        // // 20 Jun 2024
        // createLineHeightPlugin({
        //   inject: {
        //     props: {
        //       defaultNodeValue: 1.5,
        //       validNodeValues: [1, 1.2, 1.5, 2, 3],
        //       validTypes: [
        //         ELEMENT_PARAGRAPH,
        //         // ELEMENT_H1, ELEMENT_H2, ELEMENT_H3
        //       ],
        //     },
        //   },
        // }),
        // createTodoListPlugin(),
        createIndentPlugin(indentPlugin),
        createIndentListPlugin(indentListPlugin),
        // 21 Jun 2024
        createDeserializeDocxPlugin(),
        createJuicePlugin(),  
      ],
      {
        components: createPlateUI(), // Register all Plate UI components
      }
);
  

 const EtPlateEditorBasic = ({ value, onChange }) => {

      return (
        <div className='font-poppins'>
            <Plate 
                initialValue={value} 
                onChange={(newValue) => {
                  // console.log('Editor content changed:', newValue);
                onChange(newValue)}}
                plugins={plugins}
            >
              <TooltipProvider>
                <FixedToolbar>
                <FixedToolbarButtonsBasic editableProps={editableProps}/>
                </FixedToolbar>
              </TooltipProvider>
            
                <Editor className='border-none' /> 
            
            </Plate>
            {/* <p className='text-orange-300'>{JSON.stringify(value)}</p> */}
      </div>
    );
  };
  
  export default EtPlateEditorBasic;
  export { plugins }