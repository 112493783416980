import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const winTypesAdapter = createEntityAdapter({})

const initialState = winTypesAdapter.getInitialState()

export const winTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getWinTypes: builder.query({
            query: () => ({
                url: '/winTypes/getWinTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedWinTypes = responseData.map(winType => {
                    winType.id = winType._id
                    return winType
                })
                return winTypesAdapter.setAll(initialState, loadedWinTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'WinType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'WinType', id}))
                    ]
                } else {
                    return [ { type: 'WinType', id: 'LIST'}]
                }
            },
        }),
        createNewWinType: builder.mutation({
            query: initialWinType => ({
                url: '/winTypes/createNewWinType',
                method: 'POST',
                body: {...initialWinType,}
            }),
            invalidatesTags: [
                { type: 'WinType', id: "LIST" }
            ] 
        }),
        updateWinType: builder.mutation({
            query: initialWinType => ({
                url: '/winTypes/updateWinType',
                method: 'PATCH',
                body: {...initialWinType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'WinType', id: arg.id },
                { type: 'WinType', id: 'LIST' }
            ],
        }),
        deleteWinType: builder.mutation({
            query: ({id}) => ({
                url: '/winTypes/deleteWinType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'WinType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetWinTypesQuery,
    useCreateNewWinTypeMutation,
    useUpdateWinTypeMutation,
    useDeleteWinTypeMutation,
} = winTypesApiSlice

// returns the query result object
export const selectWinTypeResult = winTypesApiSlice.endpoints.getWinTypes.select()

// create memoized selector
const selectWinTypesData = createSelector(
    selectWinTypeResult,
    winTypeResult => winTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllWinTypes,
    selectById: selectWinTypeById,
    selectIds: selectWinTypeIds,
} = winTypesAdapter.getSelectors(state => selectWinTypesData(state) ?? initialState)