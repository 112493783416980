// Author - Atul Pandey
// Updated - 25 Feb 2025 - refined the roles list for coach/ admin/ sponsor

import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../features/auth/authSlice"
import {jwtDecode} from 'jwt-decode'

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    // console.log('The token is',token)
    let isCoach = false
    let isAdmin = false
    let isSponsor = false
    let status = "Guest"

    if (token) {
        const decoded = jwtDecode(token)
        //console.log('The decoded token', decoded)
        const { name, username, roles } = decoded.UserInfo
        //console.log('UserName:',username, 'Roles:',roles)

        // isCoach = roles.includes('Coach')
        // isAdmin = roles.includes('Admin')

        isCoach = roles.some(role => ['Coach', 'EtCoach', 'OrgCoach'].includes(role));          // 25 Feb 2025
        isAdmin = roles.some(role => ['Admin', 'EtAdmin', 'OrgAdmin'].includes(role));          // 25 Feb 2025
        isSponsor = roles.some(role => ['OrgSponsor'].includes(role));                          // 25 Feb 2025

        if (isCoach) status = "Coach"
        if (isAdmin) status = "Admin"
        if (isSponsor) status = "Sponsor" // 25 Feb 2025

        return { name, username, roles, status, isCoach, isAdmin, isSponsor } // 25 Feb 2025 - isSponsor
    }

    return { name: '', username: '', roles: [], isCoach, isAdmin, isSponsor, status }    // 25 Feb 2025 - isSponsor
}
export default useAuth