import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userRecognitionsIndustrysAdapter = createEntityAdapter({})

const initialState = userRecognitionsIndustrysAdapter.getInitialState()

export const userRecognitionsIndustrysApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserRecognitionsIndustrys: builder.query({
            query: () => ({
                url: '/userRecognitionsIndustrys/getUserRecognitionsIndustrys',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserRecognitionsIndustrys = responseData.map(userRecognitionsIndustry => {
                    userRecognitionsIndustry.id = userRecognitionsIndustry._id
                    return userRecognitionsIndustry
                })
                return userRecognitionsIndustrysAdapter.setAll(initialState, loadedUserRecognitionsIndustrys)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserRecognitionsIndustry', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserRecognitionsIndustry', id}))
                    ]
                } else {
                    return [ { type: 'UserRecognitionsIndustry', id: 'LIST'}]
                }
            },
        }),
        createNewUserRecognitionsIndustry: builder.mutation({
            query: initialUserRecognitionsIndustry => ({
                url: '/userRecognitionsIndustrys/createNewUserRecognitionsIndustry',
                method: 'POST',
                body: {...initialUserRecognitionsIndustry,}
            }),
            invalidatesTags: [
                { type: 'UserRecognitionsIndustry', id: "LIST" }
            ] 
        }),
        updateUserRecognitionsIndustry: builder.mutation({
            query: initialUserRecognitionsIndustry => ({
                url: '/userRecognitionsIndustrys/updateUserRecognitionsIndustry',
                method: 'PATCH',
                body: {...initialUserRecognitionsIndustry,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserRecognitionsIndustry', id: arg.id },
                { type: 'UserRecognitionsIndustry', id: 'LIST' }
            ],
        }),
        deleteUserRecognitionsIndustry: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userRecognitionsIndustrys/deleteUserRecognitionsIndustry',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userRecognitionsIndustrys/deleteUserRecognitionsIndustry',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserRecognitionsIndustry', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserRecognitionsIndustrysQuery,
    useCreateNewUserRecognitionsIndustryMutation,
    useUpdateUserRecognitionsIndustryMutation,
    useDeleteUserRecognitionsIndustryMutation,
} = userRecognitionsIndustrysApiSlice

// returns the query result object
export const selectUserRecognitionsIndustryResult = userRecognitionsIndustrysApiSlice.endpoints.getUserRecognitionsIndustrys.select()

// create memoized selector
const selectUserRecognitionsIndustrysData = createSelector(
    selectUserRecognitionsIndustryResult,
    userRecognitionsIndustryResult => userRecognitionsIndustryResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserRecognitionsIndustrys,
    selectById: selectUserRecognitionsIndustryById,
    selectIds: selectUserRecognitionsIndustryIds,
} = userRecognitionsIndustrysAdapter.getSelectors(state => selectUserRecognitionsIndustrysData(state) ?? initialState)