import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userChallengeTypesAdapter = createEntityAdapter({})

const initialState = userChallengeTypesAdapter.getInitialState()

export const userChallengeTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserChallengeTypes: builder.query({
            query: () => ({
                url: '/userChallengeTypes/getUserChallengeTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserChallengeTypes = responseData.map(userChallengeType => {
                    userChallengeType.id = userChallengeType._id
                    return userChallengeType
                })
                return userChallengeTypesAdapter.setAll(initialState, loadedUserChallengeTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserChallengeType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserChallengeType', id}))
                    ]
                } else {
                    return [ { type: 'UserChallengeType', id: 'LIST'}]
                }
            },
        }),
        createNewUserChallengeType: builder.mutation({
            query: initialUserChallengeType => ({
                url: '/userChallengeTypes/createNewUserChallengeType',
                method: 'POST',
                body: {...initialUserChallengeType,}
            }),
            invalidatesTags: [
                { type: 'UserChallengeType', id: "LIST" }
            ] 
        }),
        updateUserChallengeType: builder.mutation({
            query: initialUserChallengeType => ({
                url: '/userChallengeTypes/updateUserChallengeType',
                method: 'PATCH',
                body: {...initialUserChallengeType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserChallengeType', id: arg.id },
                { type: 'UserChallengeType', id: 'LIST' }
            ],
        }),
        deleteUserChallengeType: builder.mutation({
            query: ({id}) => ({
                url: '/userChallengeTypes/deleteUserChallengeType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserChallengeType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserChallengeTypesQuery,
    useCreateNewUserChallengeTypeMutation,
    useUpdateUserChallengeTypeMutation,
    useDeleteUserChallengeTypeMutation,
} = userChallengeTypesApiSlice

// returns the query result object
export const selectUserChallengeTypeResult = userChallengeTypesApiSlice.endpoints.getUserChallengeTypes.select()

// create memoized selector
const selectUserChallengeTypesData = createSelector(
    selectUserChallengeTypeResult,
    userChallengeTypeResult => userChallengeTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserChallengeTypes,
    selectById: selectUserChallengeTypeById,
    selectIds: selectUserChallengeTypeIds,
} = userChallengeTypesAdapter.getSelectors(state => selectUserChallengeTypesData(state) ?? initialState)