import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router"
import PulseLoader from "react-spinners/PulseLoader"; 

import { PlusIcon, MinusIcon, PaperAirplaneIcon, MicrophoneIcon, StopIcon, EyeIcon } from "@heroicons/react/24/solid";
import { useCreateNewUserWinMutation, useGetUserWinsQuery } from '@/features/userWins/userWinsApiSlice';
import { useGetContactsQuery } from '@/features/contacts/contactsApiSlice';
import { useGetWinTypesQuery } from '@/features/winTypes/winTypesApiSlice'

// 19 Feb 2025
import { useGetUserPicQuery, useGetUsersQuery } from "@/features/users/usersApiSlice"
import useAuth from '@/hooks/useAuth';
import { useSendLogoutMutation } from "@/features/auth/authApiSlice"

import TrinityIcon from '@/assets/img/icons/base64Svgs/trinityRoundIcon1.svg'
import EtWabWinIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabWinIcon1SVGComp" 
import { getTimeSince } from "@/utils/EtGetTimeSince"
import { produce } from 'immer';

// 19 Feb 2025
import EtLogoutIcon1SVGComp from "@/assets/img/icons/svgComp/EtLogoutIcon1SVGComp" 

const EtTrinityUserWinMobile = () => {
  // First Created - 12 Feb 2025
  // Author - Atul Pandey
  // Purpose - To capture Mobile wins and summarize with Trinity's LLM powers
  // Inherited from EtTrinityUserConversationMobile 
  // Updated - 19 Feb 2025 - copied from @/features to @/pages/mobile and added useAuth
  // Updated - 21 Feb 2025 - further refined voiceInput for Android - continuous recording

  // 0 - User Variables - 19 Feb 2025
  const { username, name } = useAuth() // Extract username & name - 19 Feb 2025
  // console.log('username', username)
  // console.log('name', name)

  // 1a - Set navigation
  const navigate = useNavigate()
  
  // 1b1 - State variables
  const [winText, setWinText] = useState('');
  const [localDraft, setLocalDraft] = useState('');
  const [ activeTab, setActiveTab] = useState('add')
  const [isRecording, setIsRecording] = useState(false);
  const [ showNoteDetails, setShowNoteDetails ] = useState({}) // 11 Feb2025
  const [ selectedContactId, setSelectedContactId ] = useState("") // 11 Feb2025
  const [ selectedWinTypeId, setSelectedWinTypeId ] = useState("") // 11 Feb2025

  const [loading, setLoading] = useState(false); // 11 Feb 2025 - For Gen AI

  // 1b2 - State to control the modal and store the AI-generated response - 11 Feb 2025
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [aiGeneratedResponse, setAiGeneratedResponse] = useState('');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);


  // 1c - Ref for speech recognition instance
  const recognitionRef = useRef(null);
  const textareaRef = useRef(null); // Ref for the textarea to control its size

  // 2a1 - User Info - 19 Feb 2025
  const { data: usersData } = useGetUsersQuery();
  const [sendLogout, {
    isLoading,
    isError,
    error
  }] = useSendLogoutMutation()

  const extractFilteredUserEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id])
    ?.filter(
        (item) => item?.username === username && item.name === name
        );
  };
  const userInfo = extractFilteredUserEntitiesFromAdapter(usersData)
  
  // 2a2 - extract userId and userOrgId from userInfo
  const userId = userInfo[0]?._id || null
  const userOrgId = userInfo[0]?.userOrgId?._id || null
  
  // 2a3 - Data Queries & Mutations
  const { data: userWinsData, refetch } = useGetUserWinsQuery();
  const { data: contactsData } = useGetContactsQuery(); 
  const { data: winTypesData } = useGetWinTypesQuery(); 
  const [createNewUserWin] = useCreateNewUserWinMutation();

//   console.log('userWinsData', userWinsData)
  

  const extractEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id])
  };

  const extractFilteredEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id])
    ?.filter(
        (item) => item.userId?._id === userId && item.userOrgId._id === userOrgId
        );
  };

  const contacts = extractEntitiesFromAdapter(contactsData)
  const winTypes = extractEntitiesFromAdapter(winTypesData)
  const userWinsIncoming = extractFilteredEntitiesFromAdapter(userWinsData) // 11 Feb 2025

  // console.log('contacts', contacts)
  // console.log('winTypes', winTypes)
  // console.log('userWinsIncoming', userWinsIncoming)

  // 2b - Unified Wins Array -- 11 Feb 2025
  const buildUnifiedWinArray = (userWinsData) => {
    let unifiedArray = [];
    
    userWinsData?.forEach((win) => {
        // Extract userWinAllNotes and normalize
        win.userWinAllNotes?.forEach((note) => {
        unifiedArray.push({
            _id: note._id,  // Add the unique identifier
            userContactName: "Self",
            userContactTypeName: "Self",
            userWinTypeId: note.userWinTypeId || "",
            userWinNoteText: note.userWinAllNoteText,
            userWinNoteDate: note.userWinAllNoteDate,
            userWinNoteUpdateDate: note.userWinAllNoteUpdateDate,
        });
        });
    
        // Extract userWinContactNotes and normalize
        win.userWinContactNotes?.forEach((note) => {
        unifiedArray.push({
            _id: note._id,  // Add the unique identifier
            userContactName: note.userContactId?.contactName || "Unknown Contact",
            userContactTypeName: note.userContactId?.contactTypeId?.contactTypeName || "Unknown Type",
            userWinTypeId: note.userWinTypeId || "",
            userWinNoteText: note.userWinContactNoteText,
            userWinNoteDate: note.userWinContactNoteDate,
            userWinNoteUpdateDate: note.userWinContactNoteUpdateDate,
        });
        });
    });
    
    // Sort by userWinNoteUpdateDate in descending order
    unifiedArray.sort((a, b) => new Date(b.userWinNoteUpdateDate) - new Date(a.userWinNoteUpdateDate));
    
    return unifiedArray;
  };

  const userWins = buildUnifiedWinArray(userWinsIncoming); // 11 Feb 2025

//   console.log('userAllWins', userAllWins)
  // console.log('userWins', userWins)
//   console.log('contacts', contacts)

  // 2c - Load drafts from localStorage (if available)
  useEffect(() => {
    const savedDraft = localStorage.getItem('winDraft');
    if (savedDraft) setLocalDraft(savedDraft);
  }, []);

  // 2d - Save draft to localStorage
  useEffect(() => {
    localStorage.setItem('winDraft', winText);
  }, [winText]);

  // 3 - Handle input change
  const handleInputChange = (e) => {
    setWinText(e.target.value);
  };

  // 4a - Handle Toggle showNoteDetails - 10 Feb 2025
  const handleToggleShowNoteDetails = (noteId) => {
    setShowNoteDetails((prev) => ({
        ...prev,
        [noteId]: !prev[noteId],
      }))
  }

// 5 - Handle submission (RTK Mutation) - Refined - 11 Feb 2025 - added contact specific note
const handleSubmit = async (finalText) => {
    if (!selectedWinTypeId) {
      alert("Please select a win type.");
      return;
    }

    if (!finalText.trim()) return;
  
    let newNote;
    if (selectedContactId) {
      // Create a Contact-specific note
      newNote = {
        userWinContactNotes: [
          {
            userWinTypeId: selectedWinTypeId,
            userContactId: selectedContactId,
            userWinContactNoteText: finalText,
            userWinContactNoteDate: new Date().toISOString(),
            userWinContactNoteUpdateDate: new Date().toISOString(),
          },
        ],
      };
    } else {
      // Create a Self Note
      newNote = {
        userWinAllNotes: [
          {
            userWinTypeId: selectedWinTypeId,
            userWinAllNoteText: finalText,
            userWinAllNoteDate: new Date().toISOString(),
            userWinAllNoteUpdateDate: new Date().toISOString(),
          },
        ],
      };
    }
  
    try {
      await createNewUserWin({ userId, userOrgId, ...newNote }).unwrap();
      refetch();
      setWinText('');
      setSelectedContactId('');
      setSelectedWinTypeId('');  // Reset the dropdown
      localStorage.removeItem('winDraft');

      // Reset textarea size
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto'; // Reset to default height
      }
    } catch (error) {
      console.error('Failed to create a new win:', error);
      alert('Failed to create a new win. Please try again.');
    }
  };  

  // 6 - Create an object for setting inner HTML
  const createMarkup = (htmlContent) => {
    const formattedContent = htmlContent.replace(/\n/g, '<br />'); // Convert \n to <br />
    // return { __html: htmlContent }
    return { __html: formattedContent };
    }

  // 7 - Audio Recording
  const handleVoiceInput = () => {
    if (!("webkitSpeechRecognition" in window)) {
        alert("Your browser does not support voice input.");
        return;
    }
    
    if (isRecording) {
        if (recognitionRef.current) {
            recognitionRef.current.stop();
            setIsRecording(false);
        }
        return;
    }
    
    const recognition = new window.webkitSpeechRecognition();
    recognitionRef.current = recognition;

    const isAndroid = /Android/i.test(navigator.userAgent);

    recognition.continuous = true;
    recognition.interimResults = !isAndroid;
    recognition.lang = "en-US";

    let lastRecognizedText = "";
    
    recognition.onstart = () => {
        setIsRecording(true);
        lastRecognizedText = "";
    };
    
    recognition.onresult = (event) => {
        let finalText = "";
    
        for (let i = event.resultIndex; i < event.results.length; i++) {
            const transcript = event.results[i][0].transcript.trim();
            
            if (event.results[i].isFinal) {
                if (transcript !== lastRecognizedText) {
                    finalText = transcript;
                    lastRecognizedText = finalText;

                    setWinText(prev => 
                      !prev || !prev.toLowerCase().includes(finalText.toLowerCase()) 
                      ? `${prev} ${finalText}`.trim() 
                      : prev
                    );
                }
            }
        }
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      setIsRecording(false);
      recognitionRef.current = null;
  };
    
  recognition.onend = () => {
    if (isRecording) {
        try {
            recognition.start();
        } catch (error) {
            console.error("Failed to restart recognition:", error);
            setIsRecording(false);
        }
    }
  };  

    try {
        recognition.start();
    } catch (error) {
        console.error("Failed to start recognition:", error);
        setIsRecording(false);
        recognitionRef.current = null;
    }
  };

  // 7b - Add cleanup effect
  useEffect(() => {
    return () => {
        if (recognitionRef.current) {
            recognitionRef.current.stop();
            setIsRecording(false);
        }
    };
  }, []);


  const handleVoiceInputOriginal = () => {
    if (!("webkitSpeechRecognition" in window)) {
        alert("Your browser does not support voice input.");
        return;
    }
    
    if (isRecording) {
        // Stop recording if already active
        if (recognitionRef.current) {
        recognitionRef.current.stop();
        setIsRecording(false);
        }
        return;
    }
    
    const recognition = new window.webkitSpeechRecognition();
    recognitionRef.current = recognition;
    recognition.continuous = true; // Continuous recording
    recognition.interimResults = true; // Show live transcription
    recognition.lang = "en-US";
    
    recognition.onstart = () => {
        setIsRecording(true);
    };
    
    recognition.onresult = (event) => {
        let interimText = "";
        let finalText = "";
    
        for (let i = 0; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
            finalText += transcript + " ";
        } else {
            interimText += transcript + " ";
        }
        }
    
        setWinText(finalText + interimText); // Update the text area with live transcription
    };
    
    recognition.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
    };
    
    recognition.onend = () => {
        setIsRecording(false);
        recognitionRef.current = null;
    };
    
    recognition.start();
  };

  // 8a - Gen AI addition - aiPrompt
//   const aiPrompt = `
// Summarize the following text into a clear and structured note. 
// - Avoid adding "Subject", greetings, or closing statements. 
// - Use short paragraphs and lists where appropriate. 
// - Focus only on summarizing the content clearly and concisely. 

// Text to summarize:
// "${winText}"
// `;
  // const aiPrompt = `
  // Summarize the following text into a concise and structured format using HTML. Use <p> tags for paragraphs and <ul><li> for bullet points. Ensure readability and avoid including a subject line or closing remarks:
  // "${winText}"
  // `;
  const aiPrompt = `
  Summarize the following text into a concise, well-structured note in **HTML format**. Organize key points into a numbered list, with the first word of each item wrapped in <strong> tags for emphasis. Ensure the output is clean and readable, with paragraphs and spacing between sections. Do not include a subject line or closing statement. Do not include any salutations like "Dear Team" etc. Only return the HTML without additional comments:
  
  "${winText}"
  `; 

  // 8b - Function to process response chunks
  const handleResponseChunk = (chunk) => {
    // setWinText((prev) => prev + chunk); // Stream content into the existing text
    setAiGeneratedResponse((prev) => prev + chunk); // Stream content into aiGeneratedResponse instead of winText
    
  };

  // 8c - Function to process final response once ai stream is complete
  const handleComplete = (finalResponse) => {
    setIsModalOpen(true);  // Open modal first
    setAiGeneratedResponse(""); // Ensure it starts empty for typing effect
    setTimeout(() => simulateTypingEffect(finalResponse), 500);  // Delay typing effect slightly for better UX
    setLoading(false); // Stop the spinner or loading indicator
  };

  // 8d - Handle errors during the AI generation process
  const handleError = (error) => {
    console.error("Error generating AI response:", error);
    alert("Something went wrong. Please try again.");
    setLoading(false); // Ensure loading state is cleared
  };

  // 8e - Generate AI reponse function
  const generateAIResponse = (aiPrompt, onResponseChunk, onComplete, onError) => {
    try {
      const eventSource = new EventSource(
        `${process.env.REACT_APP_SERVER_URL}/trinity/generateResponse?` +
        `messages=${encodeURIComponent(JSON.stringify([
          { role: "system", content: "Generate a refined professional note." },
          { role: "user", content: aiPrompt }
        ]))}&maxTokens=5000&model=${encodeURIComponent("gpt-4")}`
      );
  
      let response = "";
  
      eventSource.onmessage = (event) => {
        const chunk = event.data;
        response += chunk;
        onResponseChunk(chunk);
      };
  
      eventSource.addEventListener("end", () => {
        eventSource.close();
        onComplete(response); // Final response
      });
  
      eventSource.onerror = (error) => {
        eventSource.close();
        onError(error);
      };
    } catch (error) {
      onError(error);
    }
  };

  // 8f - Function to generate response
  const handleGenerateAIResponse = () => {
    if (!winText.trim()) return;
    
    setIsModalOpen(true);   // Open the modal first
    setLoading(true); // Show loading state
  
    generateAIResponse(aiPrompt, handleResponseChunk, handleComplete, handleError);
  };

  // 9 - Simulate typing effect - 11 Feb 2025
  const simulateTypingEffect = (text) => {
    let index = 0;
    const words = text.split(" ");
    let displayedText = "";
  
    const interval = setInterval(() => {
      if (index < words.length) {
        displayedText += words[index] + " ";
        setAiGeneratedResponse(displayedText); // Set the response progressively
        index++;
      } else {
        clearInterval(interval);
      }
    }, 100); // Adjust the interval time as needed
  };

  // 10 - Handle Logout
  const handleLogout = async () => {
    try {
      await sendLogout().unwrap();
      navigate('/login')
    } catch (err) {
      console.error('Logout failed:', err);
    }
  };
  

    return (
        <div className="flex flex-col h-screen bg-gray-200 p-4 rounded m-1 overflow-auto max-w-md mx-auto ml-1 mr-1">
            {/* Logout */}
            <div className='flex justify-end cursor-pointer m-1 p-1' onClick={handleLogout}>  
              <div className='flex justify-center items-center rounded-full w-6 h-6 bg-gradient-to-r from-orange-800 to-orange-800 via-orange-600 shadow-xl shadow-gray-600'>
                <EtLogoutIcon1SVGComp className='h-4 w-4 text-white'/>
              </div>
            </div>
            {/* Trinity Bar */}
            <div className='flex justify-center items-center m-1 p-1'> 
                <div className='flex justify-center items-center rounded-full shadow-lg shadow-gray-600 cursor-pointer'
                    onClick={() => navigate('/etapp/mobile')}
                >
                    <img src={TrinityIcon} alt="" className='h-24 w-24' />
                </div>
            </div>
            <div className='my-2 py-2 text-center border-t border-white'>
                <div className='flex justify-center items-center m-1 p-1'>
                    <div className="flex justify-center items-center rounded-full h-16 w-16 " ><EtWabWinIcon1SVGComp  className='h-16 w-16 text-orange-600'/>
                    </div>
                
                    <div>
                        <p className='font-bold text-2xl mx-1 px-1'>Wins</p>
                        <p className='text-xs text-gray-500 mx-1 px-1 italic'>My wins this week!</p>
                    </div>
                </div>
            </div>
            
          {/* Tab Navigation */}
          <div className="flex justify-around bg-white shadow-md p-2 rounded-lg mb-2">
            <div className={`flex justify-start items-center p-3 text-center ${activeTab === 'view' ? 'border-b-4 border-orange-500 text-orange-600' : 'text-gray-600'}`}
              onClick={() => setActiveTab('view')}>
                <div className='mx-1 px-1'><EyeIcon className='h-8 w-8 '/> </div>
                <button>
                    Recorded 
                </button>
                <div className={`rounded-full flex justify-center items-center h-7 w-7 ${activeTab === 'view' ? 'border-orange-300': 'border-gray-600'} border  bg-gray-50 m-1`}><p className={`text-xs ${activeTab === 'view' ? 'text-orange-600' : 'text-gray-600'}`}>{userWins?.length || 0}</p></div>
            </div>
            <div className={`flex justify-center items-center p-3 text-center ${activeTab === 'add' ? 'border-b-4 border-orange-500 text-orange-600' : 'text-gray-600'}`}
              onClick={() => setActiveTab('add')}>
                <div className={`m-1 p-1 rounded ${activeTab === 'view' ? 'bg-gray-600': 'bg-lime-600'}`}><PlusIcon className='h-4 w-4 text-white'/> </div>
                <button>
                    Add 
                </button>
            </div>
          </div>
      
          {/* Tab Content */}
          <div className="flex-1 h-full py-2">
            {activeTab === 'view' ? (
              // Conversation History
              <div className="space-y-1 overflow-y-auto">
                {userWins?.map((note, noteIndex) => {
                    const noteText = note?.userWinNoteText || ""
                    const noteDate = note?.userWinNoteDate || ""
                    const noteUpdateDate = note?.userWinNoteUpdateDate || ""
                    const contact = note?.userContactName || ""
                    const winType = note?.userWinTypeId?.winTypeName || ""
                    const winTypePic = note?.userWinTypeId?.pic || ""
                    
                    return(
                    <div key={note._id+noteIndex} className="bg-gray-100 p-4 rounded-lg shadow-lg">
                    
                      <div className='grid grid-cols-5 sm:grid-cols-6 justify-start items-center cursor-pointer'
                      onClick={() => handleToggleShowNoteDetails(note._id)}>
                        <div className='col-span-1 flex justify-start items-center'>
                            <div
                                // onClick={() => handleToggleShowNoteDetails(note._id)}
                                className="sm:mx-2 rounded-full h-5 w-5 sm:h-6 sm:w-6 flex justify-center items-center hover:bg-white"
                                >
                                {showNoteDetails[note._id] ? (
                                    <MinusIcon className="h-4 w-4 sm:h-5 sm:w-5 text-orange-600" />
                                  ) : (
                                      <PlusIcon className="h-4 w-4 sm:h-5 sm:w-5 text-orange-600" />
                                )}
                            </div>
                            <div className=''>
                                <p className="text-lg sm:text-2xl font-bold text-gray-600">{ noteIndex +1}.</p>
                            </div>
                        </div>
                        <div className="col-span-1 sm:col-span-2 flex flex-col justify-center items-center">
                            <p className="text-center text-[9px] sm:text-[10px] sm:mx-1 rounded-full sm:px-2 py-[1px] bg-lime-200">
                            {getTimeSince(noteUpdateDate)}
                            </p>
                            <p className="text-[8px] sm:mx-1 italic sm:px-2 py-1 text-gray-400">last updated</p>
                        </div>
                        <div className="col-span-3 ml-2">
                            
                            <div className='flex justify-start items-center m-1 p-1'>
                              <div>
                                <img src={winTypePic} alt="" className='h-6 w-6' />
                              </div>
                              <div>
                                <p className='mx-1 px-1 text-gray-600 font-bold text-xs'>{winType}</p>
                              </div>
                            </div>

                            <p className='mx-1 px-1 font-bold text-gray-400 text-xs'>For: <span className='font-normal text-orange-600'>{contact}</span></p>
                            <p dangerouslySetInnerHTML={createMarkup(noteText)} className="text-xs text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"></p>
                        </div>
                      </div>
                      
                      {showNoteDetails[note._id] && (
                        <div className='bg-white rounded p-2'>
                            <p dangerouslySetInnerHTML={createMarkup(noteText)} className="text-base md:text-lg text-gray-800"></p>
                            <p className="text-sm md:text-md text-gray-500 mt-2">
                                {new Date(noteDate).toLocaleString()}
                            </p>
                        </div>
                      )}
                      
                    </div>
                  )})}
                
              </div>
            ) : (
              // Add New Conversation - Centered Form
              <div>
                <div className="flex flex-col items-center justify-center h-full">
                    <div className="bg-gray-100 p-4 rounded-xl shadow-lg w-full max-w-md">
                        {/* Contact Selection Dropdown */}
                        <select
                            value={selectedContactId}
                            onChange={(e) => setSelectedContactId(e.target.value)}
                            className="w-full border border-gray-300 rounded-lg p-3 mb-3 focus:outline-none focus:ring-1 focus:ring-orange-500"
                        >
                            <option value="">Self Note</option>
                            {contacts?.map((contact) => (
                            <option key={contact._id} value={contact._id}>
                                {contact.contactName} - {contact.contactTypeId?.contactTypeName?.slice(0, -1) || "No Type"}
                            </option>
                            ))}
                        </select>

                        {/* Win Type Selection Dropdown */}
                        <select
                          value={selectedWinTypeId}
                          onChange={(e) => setSelectedWinTypeId(e.target.value)}
                          className="w-full border border-gray-300 rounded-lg p-3 mb-3 focus:outline-none focus:ring-1 focus:ring-orange-500"
                        >
                          <option value="">Select Win Type</option>
                          {winTypes?.map((type) => (
                            <option key={type._id} value={type._id}>
                              {type.winTypeName}
                            </option>
                          ))}
                        </select>

                        
                        {/* Win Textarea */}
                        <div className='relative'>
                          <textarea
                              id="win-textarea"
                              ref={textareaRef}  // Attach the ref here
                              value={winText}
                              onChange={handleInputChange}
                              placeholder="Enter your win here..."
                              rows={1}
                              className="w-full border border-gray-300 rounded-lg p-7 pr-20 pl-4 focus:outline-none focus:ring-1 focus:ring-orange-500 md:text-lg resize-y overflow-auto"
                              onInput={(e) => {
                                  e.target.style.height = "auto"; 
                                  e.target.style.height = `${e.target.scrollHeight}px`;
                              }}
                          />
                          <div className="absolute right-4 top-2/3 transform -translate-y-2/3 flex space-x-2">
                              {/* Voice Input Button */}
                              <button
                                  type="button"
                                  className={`p-2 rounded-full ${isRecording ? 'bg-red-600' : 'bg-lime-500'} text-white`}
                                  onClick={handleVoiceInput}
                              >
                                  {isRecording ? <StopIcon className="h-5 w-5" /> : <MicrophoneIcon className="h-5 w-5" />}
                              </button>
                              
                              {/* Send Button */}
                              <button
                                  onClick={() => {
                                    setIsConfirmModalOpen(true);
                                  }}
                                  className="p-2 bg-orange-500 text-white rounded-full hover:bg-orange-600"
                              >
                                  <PaperAirplaneIcon className="h-5 w-5" />
                              </button>
                          </div>
                        </div>
                    </div>
                </div>
              </div>
            )}
          </div>

          {isModalOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                <h2 className="text-lg font-semibold mb-4">Review and Edit</h2>

                {loading ? (
                  <div className="flex justify-start items-center">
                      <p className='text-sm mx-1 px-1'>Generating response....</p>
                      <div className="bg-orange-600 p-2 rounded">
                        <PulseLoader color={"#fff"} />
                      </div>
                  </div>
                ) : (
                  <>

                    <div className="mb-4">
                      <h3 className="text-sm font-bold text-gray-600">Original Note</h3>
                      <textarea
                        value={winText}
                        readOnly
                        rows={4}
                        className="w-full border border-gray-300 rounded-lg p-3 bg-gray-100 focus:outline-none overflow-y-auto"
                      />
                    </div>

                    
                    <div className="mb-4">
                      <h3 className="text-sm font-bold text-gray-600">Trinity Refined Note</h3>
                        {(()=>{
                           const plainTextResponse = aiGeneratedResponse.replace(/<[^>]+>/g, ''); // Strip all HTML tags
                          return(
                            <textarea
                              // value={aiGeneratedResponse}
                              value={plainTextResponse}
                              onChange={(e) => setAiGeneratedResponse(e.target.value)}
                              rows={6}
                              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none resize-y"
                            />
                          )
                      })()}
                    </div>
                 
                <div className="flex justify-end space-x-4 mt-4">
                  <button
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                    onClick={() => setIsModalOpen(false)}
                  >
                    Cancel
                  </button>
                  
                  <button
                    className="px-4 py-2 bg-orange-600 text-white rounded hover:bg-orange-700"
                    onClick={() => {
                      const combinedText = `<strong>Trinity Refined:</strong>\n\n${aiGeneratedResponse}\n\n<strong>My Note:</strong>\n\n${winText}`;
                      setWinText(combinedText); // Set combined text // 12 Feb 2025
                      setIsModalOpen(false);
                      handleSubmit(combinedText); // Trigger the final submission
                    }}
                    disabled={loading} // Disable the button while loading
                  >
                    Confirm
                  </button>
                </div>
                </>)}
              </div>
            </div>
          )}

          {isConfirmModalOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                <h2 className="text-lg font-semibold mb-4">Need Trinity's touch?</h2>
                <p className="text-sm text-gray-600 mb-4">
                  Would you like to refine your note with Trinity, or submit it directly?
                </p>
                <div className="flex justify-end space-x-4">
                  <button
                    className="p-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                    onClick={() => {
                      setIsConfirmModalOpen(false); // Close the modal
                      handleSubmit(winText); // Submit without AI
                    }}
                  >
                   Submit Directly
                  </button>
                  <button
                    className="p-2 bg-orange-600 text-white rounded hover:bg-orange-700"
                    onClick={() => {
                      setIsConfirmModalOpen(false); // Close the modal
                      handleGenerateAIResponse(); // Trigger AI summarization
                    }}
                  >
                    Refine with Trinity
                  </button>
                </div>
              </div>
            </div>
          )}


        </div>
      );
      
      
};

export default EtTrinityUserWinMobile;