import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userRecognitionsGlobalsAdapter = createEntityAdapter({})

const initialState = userRecognitionsGlobalsAdapter.getInitialState()

export const userRecognitionsGlobalsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserRecognitionsGlobals: builder.query({
            query: () => ({
                url: '/userRecognitionsGlobals/getUserRecognitionsGlobals',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserRecognitionsGlobals = responseData.map(userRecognitionsGlobal => {
                    userRecognitionsGlobal.id = userRecognitionsGlobal._id
                    return userRecognitionsGlobal
                })
                return userRecognitionsGlobalsAdapter.setAll(initialState, loadedUserRecognitionsGlobals)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserRecognitionsGlobal', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserRecognitionsGlobal', id}))
                    ]
                } else {
                    return [ { type: 'UserRecognitionsGlobal', id: 'LIST'}]
                }
            },
        }),
        createNewUserRecognitionsGlobal: builder.mutation({
            query: initialUserRecognitionsGlobal => ({
                url: '/userRecognitionsGlobals/createNewUserRecognitionsGlobal',
                method: 'POST',
                body: {...initialUserRecognitionsGlobal,}
            }),
            invalidatesTags: [
                { type: 'UserRecognitionsGlobal', id: "LIST" }
            ] 
        }),
        updateUserRecognitionsGlobal: builder.mutation({
            query: initialUserRecognitionsGlobal => ({
                url: '/userRecognitionsGlobals/updateUserRecognitionsGlobal',
                method: 'PATCH',
                body: {...initialUserRecognitionsGlobal,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserRecognitionsGlobal', id: arg.id },
                { type: 'UserRecognitionsGlobal', id: 'LIST' }
            ],
        }),
        deleteUserRecognitionsGlobal: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userRecognitionsGlobals/deleteUserRecognitionsGlobal',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userRecognitionsGlobals/deleteUserRecognitionsGlobal',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserRecognitionsGlobal', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserRecognitionsGlobalsQuery,
    useCreateNewUserRecognitionsGlobalMutation,
    useUpdateUserRecognitionsGlobalMutation,
    useDeleteUserRecognitionsGlobalMutation,
} = userRecognitionsGlobalsApiSlice

// returns the query result object
export const selectUserRecognitionsGlobalResult = userRecognitionsGlobalsApiSlice.endpoints.getUserRecognitionsGlobals.select()

// create memoized selector
const selectUserRecognitionsGlobalsData = createSelector(
    selectUserRecognitionsGlobalResult,
    userRecognitionsGlobalResult => userRecognitionsGlobalResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserRecognitionsGlobals,
    selectById: selectUserRecognitionsGlobalById,
    selectIds: selectUserRecognitionsGlobalIds,
} = userRecognitionsGlobalsAdapter.getSelectors(state => selectUserRecognitionsGlobalsData(state) ?? initialState)