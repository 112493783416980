import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const assessmentsAdapter = createEntityAdapter({})

const initialState = assessmentsAdapter.getInitialState()

export const assessmentsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAssessments: builder.query({
            query: () => ({
                url: '/assessments/getAssessments',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedAssessments = responseData.map(assessment => {
                    assessment.id = assessment._id
                    return assessment
                })
                return assessmentsAdapter.setAll(initialState, loadedAssessments)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'Assessment', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'Assessment', id}))
                    ]
                } else {
                    return [ { type: 'Assessment', id: 'LIST'}]
                }
            },
        }),
        createNewAssessment: builder.mutation({
            query: initialAssessment => ({
                url: '/assessments/createNewAssessment',
                method: 'POST',
                body: {...initialAssessment,}
            }),
            invalidatesTags: [
                { type: 'Assessment', id: "LIST" }
            ] 
        }),
        updateAssessment: builder.mutation({
            query: initialAssessment => ({
                url: '/assessments/updateAssessment',
                method: 'PATCH',
                body: {...initialAssessment,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'Assessment', id: arg.id },
                { type: 'Assessment', id: 'LIST' }
            ],
        }),
        deleteAssessment: builder.mutation({
            query: ({id}) => ({
                url: '/assessments/deleteAssessment',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Assessment', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetAssessmentsQuery,
    useCreateNewAssessmentMutation,
    useUpdateAssessmentMutation,
    useDeleteAssessmentMutation,
} = assessmentsApiSlice

// returns the query result object
export const selectAssessmentResult = assessmentsApiSlice.endpoints.getAssessments.select()

// create memoized selector
const selectAssessmentsData = createSelector(
    selectAssessmentResult,
    assessmentResult => assessmentResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllAssessments,
    selectById: selectAssessmentById,
    selectIds: selectAssessmentIds,
} = assessmentsAdapter.getSelectors(state => selectAssessmentsData(state) ?? initialState)