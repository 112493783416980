import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const coachUserPadsAdapter = createEntityAdapter({})

const initialState = coachUserPadsAdapter.getInitialState()

export const coachUserPadsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCoachUserPads: builder.query({
            query: () => ({
                url: '/coachUserPads/getCoachUserPads',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedCoachUserPads = responseData.map(coachUserPad => {
                    coachUserPad.id = coachUserPad._id
                    return coachUserPad
                })
                return coachUserPadsAdapter.setAll(initialState, loadedCoachUserPads)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'CoachUserPad', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'CoachUserPad', id}))
                    ]
                } else {
                    return [ { type: 'CoachUserPad', id: 'LIST'}]
                }
            },
        }),
        createNewCoachUserPad: builder.mutation({
            query: initialCoachUserPad => ({
                url: '/coachUserPads/createNewCoachUserPad',
                method: 'POST',
                body: {...initialCoachUserPad,}
            }),
            invalidatesTags: [
                { type: 'CoachUserPad', id: "LIST" }
            ] 
        }),
        createNewCoachUserPadsByOrg: builder.mutation({                       // 05 Aug 2024
            query: initialCoachUserPadsByOrg => ({
                url: '/coachUserPads/createNewCoachUserPadsByOrg',
                method: 'POST',
                body: {...initialCoachUserPadsByOrg,}
            }),
            invalidatesTags: [
                { type: 'CoachUserPadsByOrg', id: "LIST" }
            ] 
        }),
        createNewCoachUserPadsAllOrgs: builder.mutation({                       // 05 Aug 2024
            query: initialCoachUserPadsAllOrgs => ({
                url: '/coachUserPads/createNewCoachUserPadsAllOrgs',
                method: 'POST',
                body: {...initialCoachUserPadsAllOrgs,}
            }),
            invalidatesTags: [
                { type: 'CoachUserPadsAllOrgs', id: "LIST" }
            ] 
        }),
        updateCoachUserPad: builder.mutation({
            query: initialCoachUserPad => ({
                url: '/coachUserPads/updateCoachUserPad',
                method: 'PATCH',
                body: {...initialCoachUserPad,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'CoachUserPad', id: arg.id },
                { type: 'CoachUserPad', id: 'LIST' }
            ],
        }),
        deleteCoachUserPad: builder.mutation({
            query: ({id}) => ({
                url: '/coachUserPads/deleteCoachUserPad',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'CoachUserPad', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetCoachUserPadsQuery,
    useCreateNewCoachUserPadMutation,
    useCreateNewCoachUserPadsByOrgMutation, // 05 Aug 2024
    useCreateNewCoachUserPadsAllOrgsMutation, // 05 Aug 2024
    useUpdateCoachUserPadMutation,
    useDeleteCoachUserPadMutation,
} = coachUserPadsApiSlice

// returns the query result object
export const selectCoachUserPadResult = coachUserPadsApiSlice.endpoints.getCoachUserPads.select()

// create memoized selector
const selectCoachUserPadsData = createSelector(
    selectCoachUserPadResult,
    coachUserPadResult => coachUserPadResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCoachUserPads,
    selectById: selectCoachUserPadById,
    selectIds: selectCoachUserPadIds,
} = coachUserPadsAdapter.getSelectors(state => selectCoachUserPadsData(state) ?? initialState)