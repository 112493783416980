import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userImpactsAdapter = createEntityAdapter({})

const initialState = userImpactsAdapter.getInitialState()

export const userImpactsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserImpacts: builder.query({
            query: () => ({
                url: '/userImpacts/getUserImpacts',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserImpacts = responseData.map(userImpact => {
                    userImpact.id = userImpact._id
                    return userImpact
                })
                return userImpactsAdapter.setAll(initialState, loadedUserImpacts)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserImpact', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserImpact', id}))
                    ]
                } else {
                    return [ { type: 'UserImpact', id: 'LIST'}]
                }
            },
        }),
        createNewUserImpact: builder.mutation({
            query: initialUserImpact => ({
                url: '/userImpacts/createNewUserImpact',
                method: 'POST',
                body: {...initialUserImpact,}
            }),
            invalidatesTags: [
                { type: 'UserImpact', id: "LIST" }
            ] 
        }),
        updateUserImpact: builder.mutation({
            query: initialUserImpact => ({
                url: '/userImpacts/updateUserImpact',
                method: 'PATCH',
                body: {...initialUserImpact,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserImpact', id: arg.id },
                { type: 'UserImpact', id: 'LIST' }
            ],
        }),
        deleteUserImpact: builder.mutation({
            query: ({id}) => ({
                url: '/userImpacts/deleteUserImpact',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserImpact', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserImpactsQuery,
    useCreateNewUserImpactMutation,
    useUpdateUserImpactMutation,
    useDeleteUserImpactMutation,
} = userImpactsApiSlice

// returns the query result object
export const selectUserImpactResult = userImpactsApiSlice.endpoints.getUserImpacts.select()

// create memoized selector
const selectUserImpactsData = createSelector(
    selectUserImpactResult,
    userImpactResult => userImpactResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserImpacts,
    selectById: selectUserImpactById,
    selectIds: selectUserImpactIds,
} = userImpactsAdapter.getSelectors(state => selectUserImpactsData(state) ?? initialState)