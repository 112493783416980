import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import useTitle from "../../hooks/useTitle"
import EtBtnFormBasic from "../../components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight3 from "../../utils/EtContentEditorLight3"
import { useGetActionTypesQuery } from "../actionTypes/actionTypesApiSlice"
import { useCreateNewUserActionMutation } from "./userActionsApiSlice"

const EtCreateUserActionComp = ({ userId, trailId, sessionId, sessionTypeName }) => {

  // First Created - 11 Jun 2024
  // Author - Atul Pandey
  // Updated - 13 Jun 2024 - added sessionTypeName function to dynamically navigate to appropriate session page
  
  // 0 - Set Page Title
  useTitle('Create New User Action')
  
  // 1 - set page navigations
  const navigate = useNavigate()

  // 2 - set the mutation to create the new section data
  const[createNewUserAction, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewUserActionMutation()

  // 3 - Get action types data for options
  const { actionTypes } = useGetActionTypesQuery('actionTypesList', {
    selectFromResult: ( { data }) => ({
      actionTypes: data?.ids.map(id => data?.entities[id])
    })
  })

  //console.log('action types', actionTypes)

  // 4 - Create options to select a session type from list of session types
  const actionTypeOptions = actionTypes ? [
    <option key="placeholder" value="" disabled selected>
      Select a Action Type
    </option>,
    ...actionTypes?.map(actionType => (
      <option key={actionType._id} value={actionType._id}>
        {actionType.actionTypeName}
      </option>
    ))
    ] : []
 
  // 5 - create the form data object
  const [formData, setFormData] = useState({
    userId: userId,
    trailId: trailId, 
    sessionId: sessionId,
    actionTypeId:"",
    userActionName:"",
    userActionDesc:"",
    completeBy:"", 
    aboutMe:"",
    pic: "", 
  })

  // 6 - Reset form upon successful submission
  useEffect(() => {
  if(isSuccess) {
    setFormData({
        userId: userId,
        trailId: trailId, 
        sessionId: sessionId,
        actionTypeId:"",
        userActionName:"",
        userActionDesc:"",
        completeBy:"", 
        aboutMe:"",
        pic: "", 
    })

    navigate(`/userTrailSessions/${sessionTypeName.toLowerCase()}/${userId}/${trailId}/${sessionId}`)
  }

  },[isSuccess,navigate])

  // 7 - Form field updates / change handling
  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData(prevState => ({ ...prevState, pic: files[0] }))
    }  else {
      setFormData(prevState => ({ ...prevState, [name]: value }))
    }
  }
  
  // 8 - Form Submit Logic
  const handleSubmit = async (e) => {
    e.preventDefault()
  
    // Initialize an object to collect the form data
    const actionData = {
        userId: formData.userId,
        trailId: formData.trailId, 
        sessionId: formData.sessionId,
        actionTypeId:formData.actionTypeId,
        userActionName:formData.userActionName,
        userActionDesc:formData.userActionDesc,
        completeBy:formData.completeBy, 
        aboutMe:formData.aboutMe,   
    }
  
    //console.log('action Name:',actionData.userActionName)
    //console.log('action CompleteBy Date:',actionData.completeBy)
  
    if (formData.pic) {
      // Convert the image to a base64 string
      const reader = new FileReader()
      reader.onloadend = async () => {
        // Once conversion is done, add the base64 string to your data object
        actionData.pic = reader.result
  
        // Now, send the data object to the server
        try {
          console.log("Form submitted.")
          await createNewUserAction(actionData).unwrap() // Dispatch the RTK Query mutation
        } catch (error) {
          console.error(error)
        }
      }
      reader.readAsDataURL(formData.pic)
    } else {
      // No image, send other data as is
      try {
        console.log("Form submitted.")
        await createNewUserAction(actionData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    }
  }
  
  // 9 - Handle Cancel
    const handleCancel = () => {
      navigate(`/userTrailSessions/${sessionTypeName.toLowerCase()}/${userId}/${trailId}/${sessionId}`)
    }
  
  // 10 - Handle Clear
    const handleClear = () => {
      setFormData({
        userId: userId,
        trailId: trailId, 
        sessionId: sessionId,
        actionTypeId:"",
        userActionName:"",
        userActionDesc:"",
        completeBy:"", 
        aboutMe:"",
        pic: "", 
      })
    }

    return (
        <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
          {isLoading && <PulseLoader color={"#ea580c"} />}
          {isError && (
            <p className="text-orange-700 font-semi-bold py-2">
              {error?.data?.message}
            </p>
          )}
          <div className="bg-white p-1 m-1 rounded">
            <p className="p-1 m-1 font-bold font-poppins text-xl">Add an Action</p>
    
            <div className="bg-gray-200 rounded m-1 p-1">
              <form
                onSubmit={handleSubmit}
                className="m-1 p-1 text-xs font-poppins"
              >
                   
                <div id="actionTypeSelector">
                  <select
                    name="actionTypeId"
                    id="actionTypeId"
                    value={formData.actionTypeId}
                    onChange={handleChange}
                    className="w-full rounded p-2 m-1"
                  >
                    {actionTypeOptions}
                  </select>
                </div>
    
                <div id="userActionName">
                  <input
                    type="text"
                    name="userActionName"
                    placeholder="Enter the Action name here"
                    value={formData.userActionName}
                    onChange={handleChange}
                    className="w-full rounded p-2 m-1"
                  />
                </div>
       
                <div className="bg-white w-full rounded p-2 m-1">
                  <EtContentEditorLight3
                    value={formData.userActionDesc}
                    handleChange={(content) =>
                      setFormData({ ...formData, userActionDesc: content })
                    }
                    placeholderText={
                      "Write something more about this action.."
                    }
                    toolbarId={"actionDesc"}
                  />
                </div>

                <div id="completeBy" className="flex justify-between items-center">
                    <div className="p-2 m-1 bg-white rounded w-full"><p className="font-bold">Complete By:</p></div>
                    <input
                        type="date"
                        name="completeBy"
                        value={formData.completeBy}
                        onChange={handleChange}
                        className="w-full rounded p-2 m-1"
                    />
                </div>
    
                <div id="aboutMe">
                  <input
                    type="text"
                    name="aboutMe"
                    placeholder="Add something about the personality of this action"
                    value={formData.aboutMe}
                    onChange={handleChange}
                    className="w-full rounded p-2 m-1"
                  />
                </div>
    
                <div id="pic">
                  <input
                    type="file"
                    name="pic"
                    onChange={handleChange}
                    className="w-full rounded p-2 m-1"
                  />
                </div>
    
    
                <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
                  <EtBtnFormBasic
                    name={"Create"}
                    type={"create"}
                    handleClick={handleSubmit}
                  />
                  <EtBtnFormBasic
                    name={"Clear"}
                    type={"clear"}
                    handleClick={handleClear}
                  />
                  <EtBtnFormBasic
                    name={"Cancel"}
                    type={"cancel"}
                    handleClick={handleCancel}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )
}

export default EtCreateUserActionComp