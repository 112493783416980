import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLightWithPlace from "@/utils/EtContentEditorLightWithPlace"
import EtContentEditorLight3 from "@/utils/EtContentEditorLight3"
import { PlusIcon, MinusIcon} from '@heroicons/react/24/outline'
import { useGetSessionsQuery } from "./sessionsApiSlice"
import { useUpdateSessionMutation } from "./sessionsApiSlice"
import { useGetSessionTypesQuery } from "../sessionTypes/sessionTypesApiSlice"
import { useGetTrailsQuery } from "../trails/trailsApiSlice"
import { useGetChallengesQuery } from "../challenges/challengesApiSlice" // 19 Aug 2024

const EtUpdateSession = ({id, setActiveAdminView}) => {

  // First Created - 05 May 2024
  // Author - Atul Pandey
  // Updated - 19 Aug 2024 - Added challenges
  // Updated - 01 Mar 2025 - updated the comp with props - { id, setActiveAdminView}
  // Updated - 01 Mar 2025 - switched from navigate to setActiveAdminView

  // 1 - Set Page Title
  useTitle('Update A Session')

  // 2 - Extract the Id to be updated
  // const { id } = useParams()
  //console.log("id:", id)

  // 3 - Extract the session to be updated
  const { session } = useGetSessionsQuery("Session", {
    selectFromResult: ({ data }) => ({
      session: data?.entities[id],
    }),
  })
  // console.log('session', session)
  //console.log('session trailId id', session?.trailId._id)
  //console.log('session trailId name', session?.trailId.trailName)

  // 4 - Check for returned data
  if (!session) {
    return <PulseLoader color={"#ea580c"} />
  }

  // 5. Display data
  let content
  content = <EtUpdateSessionForm session={session} id={id} setActiveAdminView={setActiveAdminView}/> // 01 Mar 2025 - added setActiveAdminView={setActiveAdminView} 

  return content

}

const EtUpdateSessionForm = ({session, id, setActiveAdminView}) => {

  // 1 - set the variable for navigate
  const navigate = useNavigate()

  // 1b - state variables for challenge - 19 Aug 2024
  const [availableChallenges, setAvailableChallenges] = useState([]); // Available challenges for selection
  const [selectedChallenges, setSelectedChallenges] = useState([]); // Selected challenges to be added to the session

  // 2 - Define the mutation to update the session
  const [updateSession, { isLoading, isSuccess, isError, error }] = useUpdateSessionMutation()

  // 3 - Fill the form data with extracted session info
  const [formData, setFormData] = useState({
    trailId: session.trailId._id,
    sessionTypeId: session.sessionTypeId._id,
    sessionName: session.sessionName,
    sessionDesc: session.sessionDesc,
    sessionIntro: session.sessionIntro || "",
    sessionOutro: session.sessionOutro || "",
    sessionTargetAudience: session.sessionTargetAudience || [{ audience: "" }],
    sessionPreRequisites: session.sessionPreRequisites || [],
    sessionPreWork: session.sessionPreWork || [{ prework: "" }],
    sessionEstimatedDuration: session.sessionEstimatedDuration || "", 
    sessionPlannedDateSlot: session.sessionPlannedDateSlot || "",
    sessionOutcomes: session.sessionOutcomes || [{ outcome: "" }],
    sessionRewards: session.sessionRewards || [],
    sessionSuccessMetrics: session.sessionSuccessMetrics || [],
    sessionChallenges: session.sessionChallenges || [], // 19 Aug 2024
    aboutMe: session.aboutMe || "",
  })
    //console.log('Loaded formData for session', formData)
    // console.log('formData.trailId', formData.trailId)
    // console.log('formData.sessionTypeId', formData.sessionTypeId)
    console.log('session challenges', formData.sessionChallenges)
  
  // 4a - Get trails data for options
  const { trails } = useGetTrailsQuery('trailsList', {
    selectFromResult: ( { data }) => ({
      trails: data?.ids.map(id => data?.entities[id])
    })
  })

  // 4b - Get challenges data for options - 19 Aug 2024
  const { challenges } = useGetChallengesQuery('challengesList', {
    selectFromResult: ({ data }) => ({
      challenges: data?.ids.map(id => data?.entities[id])
    })
  });
  
  
  // Initialize available challenges
  useEffect(() => {
    if (session?.sessionChallenges) {
      setSelectedChallenges(session?.sessionChallenges?.map(sc => sc.challengeId));
    }
  }, [session]);

  useEffect(() => {
    if (challenges && selectedChallenges) {
      // Filter the challenges
      const filteredChallenges = challenges.filter(
        c => !selectedChallenges.some(sc => sc._id === c._id)
      );
  
      // Only update if the filtered array is different from the current state
      if (JSON.stringify(availableChallenges) !== JSON.stringify(filteredChallenges)) {
        setAvailableChallenges(filteredChallenges);
      }
    }
  }, [challenges, selectedChallenges, availableChallenges]);
  // useEffect(() => {
  //   if (challenges && selectedChallenges) {
  //     setAvailableChallenges(
  //       challenges.filter(c => !selectedChallenges.some(sc => sc._id === c._id))
  //     );
  //   }
  // }, [challenges, selectedChallenges]);

  // 5 - Get session types data for options
  const { sessionTypes } = useGetSessionTypesQuery('sessionTypesList', {
    selectFromResult: ( { data }) => ({
      sessionTypes: data?.ids.map(id => data?.entities[id])
    })
  })

  //console.log('trails:', trails)
  //console.log('session types:', sessionTypes)

  // 6 - Create options to select a trail from list of trails
  const trailOptions = trails ? [
    <option key="placeholder" value="" disabled>
        Select a trail
    </option>,
    ...trails?.map(trail => (
        <option 
        key={trail._id} 
        value={trail._id}
        >
            {trail.trailName}
        </option>
    ))
] : []

// 7 - Create options to select a session type from list of session types
const sessionTypeOptions = sessionTypes ? [
    <option key="placeholder" value="" disabled>
        Select a Session Type
    </option>,
    ...sessionTypes?.map(sessionType => (
        <option 
        key={sessionType.id} 
        value={sessionType.id}
        >
            {sessionType.sessionTypeName}
        </option>
    ))
] : []

   // 8 - Reset form upon successful submission
  useEffect(() => {
    if(isSuccess) {
      setFormData({
        trailId: "", 
        sessionTypeId: "", 
        sessionName: "",
        sessionDesc: "",
        sessionSequence: "",
        sessionPurpose: "", 
        sessionIntro: "", 
        sessionOutro: "",
        sessionTargetAudience: [{ audience: "" }],
        sessionPreRequisites: [],
        sessionPreWork: [{ prework: "" }],
        sessionEstimatedDuration: "", 
        sessionPlannedDateSlot: "",
        sessionOutcomes: [{ outcome: "" }],
        sessionRewards: [],
        sessionSuccessMetrics: [],
        sessionChallenges: [],
        aboutMe: "", 
        pic: "", 
      })
  
      // navigate('/sessions')
      setActiveAdminView('sessions'); // 01 Mar 2025
    }
    
    },[isSuccess])// ✅ 01 Mar 2025 - Removed `navigate` since it's no longer used.

    // 9 - Form field updates / change handling
  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData(prevState => ({ ...prevState, pic: files[0] }))
    }  else {
      setFormData(prevState => ({ ...prevState, [name]: value }))
    }
  }

  // 9a - Generic Handle Add Field Function
  const handleAdd = (fieldName, newItem) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: [...prevState[fieldName], newItem]
    }))
  }

  // 9b - Generic Handle Remove Field Function
  const handleRemove = (fieldName, index) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: prevState[fieldName].filter((_, i) => i !== index)
    }))
  }

  // 9c - Generic Handle Field Change Function
  const handleFieldChange = (fieldName, index, value) => {
    const updatedItems = formData[fieldName].map((item, i) => i === index ? value : item)
    setFormData({ ...formData, [fieldName]: updatedItems })
  }

  // 9d - Wrapper functions for specific fields
  const handleAddAudience = () => handleAdd('sessionTargetAudience', { audience: "" })
  const handleRemoveAudience = (index) => handleRemove('sessionTargetAudience', index)
  const handleAudienceChange = (index, value) => handleFieldChange('sessionTargetAudience', index, { audience: value })

  const handleAddPreWork = () => handleAdd('sessionPreWork', { prework: "" })
  const handleRemovePreWork = (index) => handleRemove('sessionPreWork', index)
  const handlePreWorkChange = (index, value) => handleFieldChange('sessionPreWork', index, { prework: value })
  
  const handleAddOutcomes = () => handleAdd('sessionOutcomes', { outcome: "" })
  const handleRemoveOutcomes = (index) => handleRemove('sessionOutcomes', index)
  const handleOutcomesChange = (index, value) => handleFieldChange('sessionOutcomes', index, { outcome: value })

  // 9e - Handle adding a challenge to the selected list -- 19 Aug 2024
  const handleAddChallenge = (challenge) => {
    setSelectedChallenges(prevState => [...prevState, challenge]);
    setAvailableChallenges(prevChallenges => prevChallenges.filter(c => c._id !== challenge._id));
  };

  // 9f - Handle removing a challenge from the selected list -- 19 Aug 2024
  const handleRemoveChallenge = (index) => {
    const removedChallenge = selectedChallenges[index];
    setSelectedChallenges(prevState => prevState.filter((_, i) => i !== index));
    setAvailableChallenges(prevChallenges => [...prevChallenges, removedChallenge]);
  };

  // 9g - Handle selecting all available challenges -- 19 Aug 2024
  const handleSelectAllChallenges = () => {
    setSelectedChallenges(availableChallenges);
    setAvailableChallenges([]);
  };

  // 10 - Form Submit Logic
  const handleSubmit = async (e) => {
  e.preventDefault()

  // Initialize an object to collect the form data
  const sessionData = {
    trailId: formData.trailId,
    sessionTypeId: formData.sessionTypeId,
    sessionName: formData.sessionName,
    sessionDesc: formData.sessionDesc,
    sessionIntro: formData.sessionIntro,
    sessionOutro: formData.sessionOutro,
    sessionTargetAudience: formData.sessionTargetAudience,
    sessionPreWork: formData.sessionPreWork,
    sessionEstimatedDuration:formData.sessionEstimatedDuration,
    sessionOutcomes: formData.sessionOutcomes,
    sessionPlannedDateSlot: formData.sessionPlannedDateSlot,
    sessionChallenges: selectedChallenges.map(challenge => ({ challengeId: challenge._id })), // 19 Aug 2024
    aboutMe: formData.aboutMe,
  }

  //console.log('session Name:',sessionData.sessionName)

  if (formData?.pic) {
    // Convert the image to a base64 string
    const reader = new FileReader()
    reader.onloadend = async () => {
      // Once conversion is done, add the base64 string to your data object
      sessionData.pic = reader.result

      // Now, send the data object to the server
      try {
        sessionData.id = id
        console.log("Form submitted.")
        await updateSession(sessionData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    }
    reader.readAsDataURL(formData.pic)
  } else {
    // No image, send other data as is
    try {
      sessionData.id = id
      console.log("Form submitted.")
      await updateSession(sessionData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
  }
}

  // 11 - Handle Cancel
  const handleCancel = () => {
     // navigate('/sessions')
     setActiveAdminView('sessions'); // 01 Mar 2025
  }

// 12 - Handle Clear
  const handleClear = () => {
    setFormData({
      trailId: "", 
      sessionTypeId: "", 
      sessionName: "",
      sessionDesc: "",
      sessionSequence: "",
      sessionPurpose: "", 
      sessionIntro: "", 
      sessionOutro: "",
      sessionTargetAudience: [{ audience: "" }],
      sessionPreRequisites: [],
      sessionPreWork: [],
      sessionEstimatedDuration: "", 
      sessionPlannedDateSlot: "",
      sessionOutcomes: [],
      sessionRewards: [],
      sessionSuccessMetrics: [],
      sessionChallenges:[],
      aboutMe: "", 
      pic: "", 
    })
  }

  return (
    <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
      {isLoading && <PulseLoader color={"#ea580c"} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">
          {error?.data?.message}
        </p>
      )}
      <div className="bg-white p-1 m-1 rounded">
        <p className="p-1 m-1 font-bold font-poppins text-xl">Update a Session</p>

        <div className="bg-gray-200 rounded m-1 p-1">
          <form
            onSubmit={handleSubmit}
            className="m-1 p-1 text-xs font-poppins"
          >
            <div id="">
              <select
                name="trailId"
                id="trailId"
                value={formData.trailId}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              >
                {trailOptions}
              </select>
            </div>

            <div id="">
              <select
                name="sessionTypeId"
                id="sessionTypeId"
                value={formData.sessionTypeId}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              >
                {sessionTypeOptions}
              </select>
            </div>

            <div id="sessionName">
              <input
                type="text"
                name="sessionName"
                placeholder="Enter the session name here"
                value={formData?.sessionName}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="sessionDesc">
              <input
                type="text"
                name="sessionDesc"
                placeholder="Enter the session description here"
                value={formData?.sessionDesc}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="sessionPlannedDateSlot">
              <input
                type="text"
                name="sessionPlannedDateSlot"
                placeholder="Enter the session planned Date Slot (i.e Week 2, Month 2) here"
                value={formData.sessionPlannedDateSlot}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div className="bg-white w-full rounded p-2 m-1">
              <EtContentEditorLightWithPlace
                value={formData?.sessionIntro}
                handleChange={(content) =>
                  setFormData({ ...formData, sessionIntro: content })
                }
                placeholderText={
                  "Write what people would see when this session starts!"
                }
                // toolbarId={'sessionIntro'}
              />
            </div>

            <div className="bg-white w-full rounded p-2 m-1">
              <EtContentEditorLight3
                value={formData?.sessionOutro}
                handleChange={(content) =>
                  setFormData({ ...formData, sessionOutro: content })
                }
                placeholderText={
                  "Write what people would see when this session ends!"
                }
                toolbarId={"sessionOurtro"}
              />
            </div>

            <div id="sessionEstimatedDuration">
              <input
                type="number"
                name="sessionEstimatedDuration"
                placeholder="Enter the estimated session duration (min)"
                value={formData.sessionEstimatedDuration}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="aboutMe">
              <input
                type="text"
                name="aboutMe"
                placeholder="Add something about the personality of this session"
                value={formData.aboutMe}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="pic">
              <input
                type="file"
                name="pic"
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            {/* Target Audience List */}
            <div id="target-audience">

                <div className="m-1 p-1 border border-white rounded">
                    <div>
                      <p className="font-bold mx-2 px-2 mt-2">Target Audience</p>
                      <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">Who this session is for?</p>
                    </div>

                      {formData.sessionTargetAudience.map((item, index) => (
                        <div key={index} className="flex justify-start items-center mx-1">
                          <input
                            type="text"
                            value={item.audience}
                            onChange={(e) => handleAudienceChange(index, e.target.value)}
                            placeholder="Enter a target audience"
                            className="w-full rounded p-2 m-1 text-xs"
                          />
                          <div className="rounded bg-red-500 m-1 p-1">
                            <button type="button" onClick={() => handleRemoveAudience(index)}>
                              <MinusIcon className="h-5 w-5 text-white" />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-start items-center m-1">

                          <p className="font-bold text-xs m-1 p-1">Add</p>
                          <button type="button" onClick={handleAddAudience}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                          </button>
                      
                      </div>
                </div>
           
            </div>

            {/* Session Pre Work */}
            <div id="pre-work">

                <div className="m-1 p-1 border border-white rounded">
                    <div>
                      <p className="font-bold mx-2 px-2 mt-2">Session Prework</p>
                      <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">What needs to be done before the session?</p>
                    </div>

                      {formData.sessionPreWork.map((item, index) => (
                        <div key={index} className="flex justify-start items-center mx-1">
                          <input
                            type="text"
                            value={item.prework}
                            onChange={(e) => handlePreWorkChange(index, e.target.value)}
                            placeholder="Enter a pre work for the session"
                            className="w-full rounded p-2 m-1 text-xs"
                          />
                          <div className="rounded bg-red-500 m-1 p-1">
                            <button type="button" onClick={() => handleRemovePreWork(index)}>
                              <MinusIcon className="h-5 w-5 text-white" />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-start items-center m-1">

                          <p className="font-bold text-xs m-1 p-1">Add</p>
                          <button type="button" onClick={handleAddPreWork}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                          </button>
                      
                      </div>
                </div>
           
            </div>

            {/* 19 Aug 2024 - Session Challenges */}
            <div id="session-challenges">

            <div className="m-1 p-1 border border-white rounded">
              <div>
                <p className="font-bold mx-2 px-2 mt-2">Session Challenges</p>
                <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">Select the challenges for this session</p>
              </div>

              {selectedChallenges?.map((challenge, index) => (
                <div key={index} className="flex items-center justify-start mx-1 px-1">
                  <button type="button" onClick={() => handleRemoveChallenge(index)} className="bg-red-400 hover:bg-red-600 rounded p-1">
                    <MinusIcon className="h-5 w-5 text-white" />
                  </button>
                  <div className="m-1 p-1 border rounded w-full"><span className="px-1">{challenge.challengeName}</span></div>
                </div>
              ))}
              
              {availableChallenges?.length > 0 && (
                <div className="flex justify-start items-center">
                  <div>
                    <button
                      type="button"
                      onClick={handleSelectAllChallenges}
                      className="bg-lime-500 hover:bg-lime-700 text-white rounded p-2"
                    >
                      Select All
                    </button>
                  </div>
                  <div>
                    <select onChange={(e) => handleAddChallenge(challenges.find(c => c._id === e.target.value))} className="rounded p-2 m-1 border">
                      <option value="">Select a challenge to add</option>
                      {availableChallenges.map(challenge => (
                        <option key={challenge._id} value={challenge._id}>{challenge.challengeName}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>

            </div>

            {/* Session Outcomes */}
            <div id="outcomes">

                <div className="m-1 p-1 border border-white rounded">
                    <div>
                      <p className="font-bold mx-2 px-2 mt-2">Session Outcomes</p>
                      <p className="text-[8px] mx-2 px-2 mb-2 italic text-gray-600">What one can expect to achieve out of the session?</p>
                    </div>

                      {formData.sessionOutcomes.map((item, index) => (
                        <div key={index} className="flex justify-start items-center mx-1">
                          <input
                            type="text"
                            value={item.outcome}
                            onChange={(e) => handleOutcomesChange(index, e.target.value)}
                            placeholder="Enter an outcome for the session"
                            className="w-full rounded p-2 m-1 text-xs"
                          />
                          <div className="rounded bg-red-500 m-1 p-1">
                            <button type="button" onClick={() => handleRemoveOutcomes(index)}>
                              <MinusIcon className="h-5 w-5 text-white" />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-start items-center m-1">

                          <p className="font-bold text-xs m-1 p-1">Add</p>
                          <button type="button" onClick={handleAddOutcomes}>
                            <PlusIcon className="h-5 w-5 text-white m-1 p-1 rounded bg-green-500" />
                          </button>
                      
                      </div>
                </div>
           
            </div>

            <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Update"}
                type={"create"}
                handleClick={handleSubmit}
              />
              <EtBtnFormBasic
                name={"Clear"}
                type={"clear"}
                handleClick={handleClear}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )

}

export default EtUpdateSession