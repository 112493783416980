
    import React from 'react';
    
    const EtImprintSelfIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" fill="currentColor">
          <g transform="translate(-154 -100)"><path d="M220 171.4 220 202C220 204.144 221.144 206.124 223 207.196 224.856 208.268 227.144 208.268 229 207.196 230.856 206.124 232 204.144 232 202L232 171.4C239.284 169.913 245.757 165.774 250.163 159.786 254.57 153.798 256.595 146.388 255.848 138.991 255.101 131.594 251.634 124.738 246.119 119.752 240.604 114.767 233.434 112.006 226 112.006 218.566 112.006 211.396 114.766 205.881 119.752 200.366 124.738 196.899 131.594 196.152 138.991 195.405 146.388 197.431 153.798 201.837 159.786 206.243 165.774 212.716 169.913 220 171.4ZM226 124C230.774 124 235.352 125.896 238.728 129.272 242.104 132.648 244 137.226 244 142 244 146.774 242.104 151.352 238.728 154.728 235.352 158.104 230.774 160 226 160 221.226 160 216.648 158.104 213.272 154.728 209.896 151.352 208 146.774 208 142 208 137.226 209.896 132.648 213.272 129.272 216.648 125.896 221.226 124 226 124ZM251.26 186.52C249.116 186.07 246.896 186.798 245.434 188.429 243.972 190.06 243.492 192.347 244.174 194.429 244.856 196.51 246.596 198.07 248.74 198.52 262.36 201.22 268 206.08 268 208 268 211.48 253.3 220 226 220 198.7 220 184 211.48 184 208 184 206.08 189.64 201.22 203.26 198.28 205.404 197.83 207.144 196.27 207.826 194.189 208.508 192.107 208.028 189.82 206.566 188.189 205.104 186.558 202.884 185.83 200.74 186.28 182.5 190.48 172 198.34 172 208 172 223.78 199.18 232 226 232 252.82 232 280 223.78 280 208 280 198.34 269.5 190.48 251.26 186.52Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtImprintSelfIcon1SVGComp;
    