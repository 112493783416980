
    import React from 'react';
    
    const EtSponsorOneOnOneIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m1479.6 1868.4h-712.8c-28.801 0-50.398 21.602-50.398 50.398 0 28.801 21.602 50.398 50.398 50.398h396v612l-82.801 0.003907c-28.801 0-50.398 21.602-50.398 50.398 0 28.801 21.602 50.398 50.398 50.398h396c28.801 0 50.398-21.602 50.398-50.398v-712.8c3.6016-25.199-21.598-50.402-46.797-50.402z" fillRule="evenodd"/>
 <path d="m885.6 2098.8c-3.6016-39.602-36-68.398-75.602-68.398h-198l-18-273.6c54 32.398 118.8 50.398 183.6 50.398h133.2c36 0 64.801-28.801 64.801-64.801s-28.801-64.801-64.801-64.801h-133.2c-43.199 0-86.398-14.398-126-36l-129.6-82.801c-25.199-18-54-25.199-82.801-25.199h-25.199c-43.199 0-86.398 18-115.2 54-28.801 32.398-43.199 79.199-36 126l61.199 406.8c7.1992 36 39.602 64.801 75.602 64.801h338.4l46.801 421.2c3.6016 39.602 36 68.398 75.602 68.398h7.1992c43.199-3.6016 72-43.199 68.398-86.398z" fillRule="evenodd"/>
 <path d="m633.6 2246.4h-259.2c-57.602 0-108-43.199-115.2-100.8l-68.398-432c-3.6016-28.801-32.398-46.801-57.602-43.199-28.801 3.6016-46.801 32.398-43.199 57.602l68.398 432c18 108 108 187.2 216 187.2h259.2c28.801 0 50.398-21.602 50.398-50.398 3.6016-25.199-21.602-50.402-50.398-50.402z" fillRule="evenodd"/>
 <path d="m590.4 1317.6c0 93.445-75.75 169.2-169.2 169.2-93.445 0-169.2-75.754-169.2-169.2 0-93.449 75.754-169.2 169.2-169.2 93.449 0 169.2 75.754 169.2 169.2" fillRule="evenodd"/>
 <path d="m3373.2 1011.6h-370.8v-46.801c0-25.199-21.602-46.801-46.801-46.801-25.199 0-46.801 21.602-46.801 46.801v46.801h-378c-75.602 0-136.8 61.199-136.8 136.8v280.8c10.801 10.801 18 25.199 28.801 36 7.1992 7.1992 18 21.602 25.199 32.398 10.801 3.6016 25.199 7.1992 43.199 10.801v-356.4c0-21.602 18-39.602 39.602-39.602h842.4c21.602 0 39.602 18 39.602 39.602v691.2c0 21.602-18 39.602-39.602 39.602l-842.4-0.003907c-21.602 0-39.602-18-39.602-39.602v-25.199c-28.801-3.6016-61.199-10.801-97.199-18v43.199c0 75.602 61.199 136.8 136.8 136.8h374.4v460.8l-183.6 147.6c-21.602 18-25.199 46.801-7.1992 68.398 10.801 10.801 21.602 18 36 18 10.801 0 21.602-3.6016 28.801-10.801l172.8-144 172.8 140.4c7.1992 7.1992 18 10.801 28.801 10.801 14.398 0 28.801-7.1992 36-18 18-21.602 14.398-50.398-7.1992-68.398l-183.6-147.6v-464.4h374.4c75.602 0 136.8-61.199 136.8-136.8v-687.6c0-75.598-61.199-136.8-136.8-136.8z" fillRule="evenodd"/>
 <path d="m2242.8 1598.4c32.398 43.199 61.199 79.199 97.199 97.199 75.602 32.398 212.4 43.199 226.8 46.801 39.602 3.6016 75.602-25.199 79.199-64.801 3.6016-25.199-7.1992-46.801-25.199-61.199l140.4-259.2c7.1992-14.398 3.6016-32.398-10.801-39.602-14.398-7.1992-32.398-3.6016-39.602 10.801l-144 266.4c-50.398-3.6016-126-18-162-32.398-7.1992-7.1992-32.398-36-46.801-54-50.398-64.801-115.2-147.6-205.2-147.6h-194.4c-140.4 0-219.6 180-237.6 241.2-14.398 50.398-21.602 208.8-25.199 255.6-3.6016 39.602 28.801 72 68.398 75.602h3.6016c39.602 0 68.398-28.801 72-68.398 3.6016-90 14.398-205.2 21.602-226.8 3.6016-18 18-43.199 28.801-68.398l7.1992 349.2-93.602 666c-7.1992 46.801 25.199 90 72 93.602 46.801 7.1992 90-25.199 93.602-72l86.398-612 86.398 612c7.1992 43.199 43.199 72 82.801 72h10.801c46.801-7.1992 79.199-50.398 72-93.602l-93.602-666 7.1992-356.4c10.805 17.996 18.004 28.797 21.605 35.996z" fillRule="evenodd"/>
 <path d="m2224.8 1137.6c0 93.445-75.754 169.2-169.2 169.2-93.449 0-169.2-75.754-169.2-169.2 0-93.449 75.754-169.2 169.2-169.2 93.445 0 169.2 75.754 169.2 169.2" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtSponsorOneOnOneIcon1SVGComp;
    