import React, {useState} from 'react'
import { useNavigate } from 'react-router'
import useTitle from '@/hooks/useTitle'
import { useGetQuestionSetsQuery } from './questionSetsApiSlice'
import { useGetQuestionsQuery } from '../questions/questionsApiSlice'
import { addIcon, editIcon, deleteIcon, viewIcon } from '@/assets/img/imgAssets'
import EtQuestionListBySet from '../questions/EtQuestionListBySet'

const EtQuestionSetList = ({setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }) => {
  // First Created - 06 Apr 2024
  // Author - Atul Pandey
  // Updated - 28 Feb 2025 - updated the softSkillFunctionArea with props - { setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }
  // Updated - 28 Feb 2025 - switched from navigate to onAdd, onUpdate, onDelete, setSelectedEntityId

  // 1 - Use navigate
  const navigate = useNavigate()
  
  // 2 - Use Title
  useTitle('Question Sets')
  
  // 3 - State Variables
  const [showDetails, setShowDetails] = useState(false)
  const [qsetName, setQsetName ] = useState('')
  
  // 4 - Use the RTK Query hook to get question sets
  const { data: questionSets, error, isLoading } = useGetQuestionSetsQuery()
  const { data: questions, error: errorQuestions, isLoading: isLoadingQuestions } = useGetQuestionsQuery()

  console.log('questionSets', questionSets)
 
  // 5 - Optional: handle loading and error states
  if (isLoading || isLoadingQuestions) return <div>Loading...</div>;
  if (error || errorQuestions) return <div>Error occurred: {error.error && error.error.message ? error.error.message : 'Unknown error'}</div>;

  const displaySetQuestions = (setname) => {
    setShowDetails(true)
    setQsetName(setname)
  }

  // 06 Apr 2024 
  const countQuestionsBySet = (setname) => {
    return questions?.ids.filter((id) => questions.entities[id].qsetName === setname).length
}

  return (
    <div className="bg-gray-200 p-1 mx-auto my-5 rounded-lg border border-white shadow-none shadow-black">
        <div className='flex justify-start items-center p-1 m-1'>
            <div><p className="p-1 m-2 font-bold font-poppins text-xl">Question Sets</p></div>
            <div>
              <img 
                src={addIcon} alt="" 
                className='h-10 w-10 cursor-pointer' 
                // onClick={() => navigate('/admin/qsets/create') } 
                onClick={onAdd} // 28 Feb 2025
              />
              </div>
        </div>
        
        
        <div className='flex justify-center border-t border-white'>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 p-4">
                {questionSets && questionSets.ids.map((id) => {
                    const {setname, setdesc, pic} = questionSets.entities[id]
                    const questionCount = countQuestionsBySet(setname) // 06 Apr 2024
                return (
                <div key={id} className="bg-white focus:bg-orange-400 rounded-lg overflow-hidden shadow-md shadow-slate-600">
                    <div className='flex justify-between items-center border-b'>
                        <div className='flex justify-start items-center p-1 my-1 '>
                            <div className='flex justify-center items-center p-1 rounded-full h-6 w-6 hover:bg-gray-100 cursor-pointer'><img src={viewIcon} alt="" className='h-6 w-6' onClick={() => displaySetQuestions(setname)}/></div>
                            <div className='flex justify-center items-center p-1 rounded-full h-6 w-6 hover:bg-gray-100 cursor-pointer'>
                                <img 
                                  src={editIcon} 
                                  alt="" className='h-6 w-6' 
                                  // onClick={() => navigate(`/admin/qsets/update/${id}`)}
                                  onClick={() => onUpdate(id)} // 28 Feb 2025 
                                />
                              </div>
                            <div className='flex justify-center items-center p-1 rounded-full h-6 w-6 hover:bg-gray-100 cursor-pointer'>
                                <img 
                                  src={deleteIcon} 
                                  alt="" 
                                  className='h-6 w-6' 
                                  // onClick={() => navigate(`/admin/qsets/delete/${id}`)}
                                  onClick={() => onDelete(id)} // 28 Feb 2025 
                                />
                              </div>
                            
                        </div>
                        <div className='m-3 flex justify-start items-center'>
                          <p className='text-[10px] text-gray-400 mx-1 px-1'>Questions:</p>
                          <div className='flex justify-center items-center rounded-full w-5 h-5 bg-orange-50 border border-orange-600'>
                            <p className='text-[10px] text-orange-600'>{questionCount}</p>
                          </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center p-4">
                    <div className='rounded-full h-16 w-16 hover:bg-gray-50 p-2'><img src={pic} alt="Set" className="rounded mx-auto h-14 w-14"/></div>
                    <h2 className="mt-2 text-center font-bold text-xl">{setname}</h2>
                    </div>
                    <div className="border-t p-4">
                    <p className='text-xs text-gray-500 font-poppins'>{setdesc}</p>
                    </div>
                </div>
                )

                })}
            </div>
      </div>
      {showDetails && (
      <div className='m-5 border-t border-white'>
      <EtQuestionListBySet setname={qsetName}/>
      </div>
      )}
    
    </div>
  )
}

export default EtQuestionSetList