
    import React from 'react';
    
    const EtRoleFocusIcon3SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" fill="currentColor">
          <g transform="translate(-1058 -522)"><path d="M1061.43 565.424 1070.01 565.424C1071.58 565.424 1072.88 564.158 1072.88 562.555L1072.88 536.849 1098.58 536.849C1100.16 536.849 1101.45 535.583 1101.45 533.98L1101.45 525.43C1101.45 523.855 1100.19 522.561 1098.58 522.561L1065.71 522.561C1061.77 522.561 1058.56 525.768 1058.56 529.705L1058.56 562.555C1058.56 564.158 1059.83 565.424 1061.43 565.424Z" fillRule="evenodd"/><path d="M1098.56 651.152 1072.85 651.152 1072.85 625.446C1072.85 623.871 1071.58 622.577 1069.98 622.577L1061.43 622.577C1059.86 622.577 1058.56 623.842 1058.56 625.446L1058.56 658.296C1058.56 662.233 1061.77 665.439 1065.71 665.439L1098.56 665.439C1100.13 665.439 1101.42 664.174 1101.42 662.57L1101.42 653.992C1101.42 652.417 1100.13 651.152 1098.56 651.152Z" fillRule="evenodd"/><path d="M1194.29 522.562 1161.44 522.562C1159.87 522.562 1158.57 523.828 1158.57 525.431L1158.57 534.009C1158.57 535.584 1159.84 536.878 1161.44 536.878L1187.15 536.878 1187.15 562.584C1187.15 564.159 1188.41 565.453 1190.02 565.453L1198.6 565.453C1200.17 565.453 1201.46 564.188 1201.46 562.584L1201.46 529.706C1201.44 525.769 1198.23 522.563 1194.29 522.563Z" fillRule="evenodd"/><path d="M1198.57 622.576 1189.99 622.576C1188.42 622.576 1187.12 623.842 1187.12 625.445L1187.12 651.151 1161.41 651.151C1159.84 651.151 1158.55 652.417 1158.55 654.02L1158.55 662.598C1158.55 664.173 1159.81 665.467 1161.41 665.467L1194.26 665.467C1198.2 665.467 1201.41 662.26 1201.41 658.323L1201.41 625.445C1201.44 623.842 1200.17 622.576 1198.57 622.576Z" fillRule="evenodd"/><path d="M1165.72 594C1165.72 606.761 1158.91 618.553 1147.86 624.933 1136.81 631.314 1123.19 631.314 1112.14 624.933 1101.09 618.553 1094.28 606.761 1094.28 594 1094.28 581.239 1101.09 569.447 1112.14 563.067 1123.19 556.686 1136.81 556.686 1147.86 563.067 1158.91 569.447 1165.72 581.239 1165.72 594" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtRoleFocusIcon3SVGComp;
    