// Author - Atul Pandey
// Updated - 19 Mar 2024 - added updateUserPic mutation
// Updated - 10 May 2024 - added assignOrgToUser mutation
// Updated - 02 Mar 2025 - added /updateUserPassword route
import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "@/app/api/apiSlice"


const usersAdapter = createEntityAdapter({})

const initialState = usersAdapter.getInitialState({rolesEnum: [],}) // 30 Jul 2024 - added rolesEnum

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => ({
        url: "/users",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError
        },
      }),
      transformResponse: (responseData) => {
        const { users, rolesEnum } = responseData.data; // Adjust according to your backend response structure
        const loadedUsers = users.map((user) => {
          user.id = user._id;
          return user;
        });
        const adaptedUsers = usersAdapter.setAll(initialState, loadedUsers);
        return {
          ...adaptedUsers,
          rolesEnum,
        };
      },      // 30 Jul 2024

      // transformResponse: (responseData) => {
      //   const loadedUsers = responseData.map((user) => {
      //     user.id = user._id
      //     return user
      //   })
      //   return usersAdapter.setAll(initialState, loadedUsers)
      // },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "User", id: "LIST" },
            ...result.ids.map((id) => ({ type: "User", id })),
          ]
        } else return [{ type: "User", id: "LIST" }]
      },
    }),
    getUserPic: builder.query({
      query: (username) => `users/userpic/${username}`,
      providesTags: ['UserPic'],
      transformResponse: (response) => response.message,
    }),
    addNewUser: builder.mutation({
      query: (initialUserData) => ({
        url: "/users",
        method: "POST",
        body: {
          ...initialUserData,
        },
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    updateUser: builder.mutation({
      query: (initialUserData) => ({
        url: "/users",
        method: "PATCH",
        body: {
          ...initialUserData,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "User", id: arg.id }],
    }),
    updateUserPic: builder.mutation({
      query: (formData) => ({
        url: "/users/uploadPic",
        method: "PATCH",
        body: formData, // Directly pass FormData object
      }),
      invalidatesTags: ['UserPic'],
    }),
    deleteUser: builder.mutation({
      query: ({ id }) => ({
        url: `/users`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "User", id: arg.id }],
    }),
    assignOrgToUser: builder.mutation({
      query: (formData) => ({
        url: `/users/assignOrgToUser`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    //02 Mar 2025
    updateUserPassword: builder.mutation({
      query: (formData) => ({
        url: `/users/updateUserPassword`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
  }),
})

export const {
    useGetUsersQuery,
    useGetUserPicQuery,
    useAddNewUserMutation,
    useUpdateUserMutation,
    useUpdateUserPicMutation,
    useDeleteUserMutation,
    useAssignOrgToUserMutation,
    useUpdateUserPasswordMutation, // 02 Mar 2025
} = usersApiSlice

// returns the query result object
export const selectUsersResult = usersApiSlice.endpoints.getUsers.select()

// creates memoized selector
const selectUsersData = createSelector(
    selectUsersResult,
    usersResult => usersResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUsers,
    selectById: selectUserById,
    selectIds: selectUserIds
    // Pass in a selector that returns the users slice of state
} = usersAdapter.getSelectors(state => selectUsersData(state) ?? initialState)