import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userPlanSelfsAdapter = createEntityAdapter({})

const initialState = userPlanSelfsAdapter.getInitialState()

export const userPlanSelfsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserPlanSelfs: builder.query({
            query: () => ({
                url: '/userPlanSelfs/getUserPlanSelfs',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserPlanSelfs = responseData.map(userPlanSelf => {
                    userPlanSelf.id = userPlanSelf._id
                    return userPlanSelf
                })
                return userPlanSelfsAdapter.setAll(initialState, loadedUserPlanSelfs)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserPlanSelf', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserPlanSelf', id}))
                    ]
                } else {
                    return [ { type: 'UserPlanSelf', id: 'LIST'}]
                }
            },
        }),
        createNewUserPlanSelf: builder.mutation({
            query: initialUserPlanSelf => ({
                url: '/userPlanSelfs/createNewUserPlanSelf',
                method: 'POST',
                body: {...initialUserPlanSelf,}
            }),
            invalidatesTags: [
                { type: 'UserPlanSelf', id: "LIST" }
            ] 
        }),
        updateUserPlanSelf: builder.mutation({
            query: initialUserPlanSelf => ({
                url: '/userPlanSelfs/updateUserPlanSelf',
                method: 'PATCH',
                body: {...initialUserPlanSelf,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserPlanSelf', id: arg.id },
                { type: 'UserPlanSelf', id: 'LIST' }
            ],
        }),
        deleteUserPlanSelf: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userPlanSelfs/deleteUserPlanSelf',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userPlanSelfs/deleteUserPlanSelf',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserPlanSelf', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserPlanSelfsQuery,
    useCreateNewUserPlanSelfMutation,
    useUpdateUserPlanSelfMutation,
    useDeleteUserPlanSelfMutation,
} = userPlanSelfsApiSlice

// returns the query result object
export const selectUserPlanSelfResult = userPlanSelfsApiSlice.endpoints.getUserPlanSelfs.select()

// create memoized selector
const selectUserPlanSelfsData = createSelector(
    selectUserPlanSelfResult,
    userPlanSelfResult => userPlanSelfResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserPlanSelfs,
    selectById: selectUserPlanSelfById,
    selectIds: selectUserPlanSelfIds,
} = userPlanSelfsAdapter.getSelectors(state => selectUserPlanSelfsData(state) ?? initialState)