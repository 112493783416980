import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userPlanPartnersAdapter = createEntityAdapter({})

const initialState = userPlanPartnersAdapter.getInitialState()

export const userPlanPartnersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserPlanPartners: builder.query({
            query: () => ({
                url: '/userPlanPartners/getUserPlanPartners',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserPlanPartners = responseData.map(userPlanPartner => {
                    userPlanPartner.id = userPlanPartner._id
                    return userPlanPartner
                })
                return userPlanPartnersAdapter.setAll(initialState, loadedUserPlanPartners)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserPlanPartner', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserPlanPartner', id}))
                    ]
                } else {
                    return [ { type: 'UserPlanPartner', id: 'LIST'}]
                }
            },
        }),
        createNewUserPlanPartner: builder.mutation({
            query: initialUserPlanPartner => ({
                url: '/userPlanPartners/createNewUserPlanPartner',
                method: 'POST',
                body: {...initialUserPlanPartner,}
            }),
            invalidatesTags: [
                { type: 'UserPlanPartner', id: "LIST" }
            ] 
        }),
        updateUserPlanPartner: builder.mutation({
            query: initialUserPlanPartner => ({
                url: '/userPlanPartners/updateUserPlanPartner',
                method: 'PATCH',
                body: {...initialUserPlanPartner,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserPlanPartner', id: arg.id },
                { type: 'UserPlanPartner', id: 'LIST' }
            ],
        }),
        deleteUserPlanPartner: builder.mutation({
            query: (deletionPayload) => ({
                url: '/userPlanPartners/deleteUserPlanPartner',
                method: 'DELETE',
                body: deletionPayload
            }),
            // query: ({id}) => ({
            //     url: '/userPlanPartners/deleteUserPlanPartner',
            //     method: 'DELETE',
            //     body: {id}
            // }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserPlanPartner', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserPlanPartnersQuery,
    useCreateNewUserPlanPartnerMutation,
    useUpdateUserPlanPartnerMutation,
    useDeleteUserPlanPartnerMutation,
} = userPlanPartnersApiSlice

// returns the query result object
export const selectUserPlanPartnerResult = userPlanPartnersApiSlice.endpoints.getUserPlanPartners.select()

// create memoized selector
const selectUserPlanPartnersData = createSelector(
    selectUserPlanPartnerResult,
    userPlanPartnerResult => userPlanPartnerResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserPlanPartners,
    selectById: selectUserPlanPartnerById,
    selectIds: selectUserPlanPartnerIds,
} = userPlanPartnersAdapter.getSelectors(state => selectUserPlanPartnersData(state) ?? initialState)