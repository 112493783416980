import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userSessionsAdapter = createEntityAdapter({})

const initialState = userSessionsAdapter.getInitialState()

export const userSessionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserSessions: builder.query({
            query: () => ({
                url: '/userSessions',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserSessions = responseData.map(userSession => {
                    userSession.id = userSession._id
                    return userSession
                })
                return userSessionsAdapter.setAll(initialState, loadedUserSessions)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserSession', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserSession', id}))
                    ]
                } else {
                    return [ { type: 'UserSession', id: 'LIST'}]
                }
            },
        }),
        createNewUserSession: builder.mutation({
            query: initialUserSession => ({
                url: '/userSessions',
                method: 'POST',
                body: {...initialUserSession,}
            }),
            invalidatesTags: [
                { type: 'UserSession', id: "LIST" }
            ] 
        }),
        updateUserSession: builder.mutation({
            query: initialUserSession => ({
                url: '/userSessions',
                method: 'PATCH',
                body: {...initialUserSession,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserSession', id: arg.id },
                { type: 'UserSession', id: 'LIST' }
            ],
        }),
        deleteUserSession: builder.mutation({
            query: ({id}) => ({
                url: '/userSessions',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserSession', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserSessionsQuery,
    useCreateNewUserSessionMutation,
    useUpdateUserSessionMutation,
    useDeleteUserSessionMutation,
} = userSessionsApiSlice

// returns the query result object
export const selectUserSessionResult = userSessionsApiSlice.endpoints.getUserSessions.select()

// create memoized selector
const selectUserSessionsData = createSelector(
    selectUserSessionResult,
    userSessionResult => userSessionResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserSessions,
    selectById: selectUserSessionById,
    selectIds: selectUserSessionIds,
} = userSessionsAdapter.getSelectors(state => selectUserSessionsData(state) ?? initialState)