import React, { useState, useEffect } from 'react'
import useTitle from "../../../hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
    PencilSquareIcon,
  } from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "../../../utils/EtContentEditorLight2"
import EtBtnFormBasic from "../../../components/common/buttons/EtBtnFormBasic"
import { useNavigate } from "react-router"

import { useUpdateUserPlanOrgMutation, useGetUserPlanOrgsQuery } from '@/features/userPlans/userPlanOrgsApiSlice'
import { useGetUserMissionOrgsQuery } from '@/features/userMissions/userMissionOrgsApiSlice'
import { useGetActionTypesQuery } from "@/features/actionTypes/actionTypesApiSlice"
import { useGetTrailsQuery } from "@/features/trails/trailsApiSlice"
import { useGetSessionsQuery } from "@/features/sessions/sessionsApiSlice"

import { produce } from "immer";

const EtUpdateUserImprintPlanOrgComp = ({
    id,
    userId, 
    userOrgId, 
    triggeringUrl, 
    onClose
  }) => {

  // First Created - 16 Jan 2025
  // Author - Atul Pandey
  // Inherited from the EtCreateUserImprintGratitudeComp component

  // 0 - Set Page title & variables
  useTitle('User Imprint Org Plan - Update')

  // console.log('id is:', id)
  // console.log('userId is:', userId)
  // console.log('userOrgId is:', userOrgId)
  
  // 1a - set page navigations
  const navigate = useNavigate()

  // 1b - set form states
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState({});
  
  // 2a - set the mutation to create the new user imprint gratitude data
    const [updateUserPlanOrg, {
      isLoading,
      isSuccess,
      isError,
      error,
    }] = useUpdateUserPlanOrgMutation()
  
  // 2b - Get existing user creations Data from the backend
  const { 
    data: userPlanOrgData,
    isLoading: isFetching,
    isError: isFetchError,
    error: fetchError,
  } = useGetUserPlanOrgsQuery()
  // console.log('userPlanOrgData', userPlanOrgData)
  const userPlanOrgRecords = userPlanOrgData?.ids
  ?.map(id => userPlanOrgData?.entities[id])
  ?.filter(item=> item._id === id && item.userId._id === userId && item.userOrgId._id === userOrgId) || []
  console.log('userPlanOrgs', userPlanOrgRecords)

  // 2c - get the data for plan value uom and gradations
    const { data: missionsData } = useGetUserMissionOrgsQuery()
    const { data: trailsData } = useGetTrailsQuery()
    const { data: sessionsData } = useGetSessionsQuery()
    const { data: actionTypesData } = useGetActionTypesQuery()   
  
    const extractEntitiesFromAdapter = (dataAdapter) => {
      if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
      return dataAdapter.ids.map((id) => dataAdapter.entities[id])
    };
  
    const extractFilteredEntitiesFromAdapter = (dataAdapter) => {
      if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
      return dataAdapter.ids.map((id) => dataAdapter.entities[id])
      ?.filter(
          (item) => item.userId?._id === userId && item.userOrgId._id === userOrgId
          );
    };
  
    const missions = extractFilteredEntitiesFromAdapter(missionsData)
    const trails = extractEntitiesFromAdapter(trailsData)
    const sessions = extractEntitiesFromAdapter(sessionsData)
    const actionTypes = extractEntitiesFromAdapter(actionTypesData)
    
    const trailId = trails?.find(item => item.trailName === 'MyMissions')?._id;
    const sessionId = sessions?.find(item => item.sessionName === 'Plan a Mission')?._id;
    const actionTypeId = actionTypes?.find(item => item.actionTypeName === 'Mission')?._id;
    // console.log('missions', missions)
    // console.log('uoms', metricUoMs)
    // console.log('gradations', metricGradations)
  

  // 3 - Initialize Form Data State
  const [formData, setFormData] = useState({
      missionId: null,
      planName: "",
      planDesc: "",
      planDate: "",
      newActions: [], // Reset newActions
      planActions: [], // Reset PlanActions
      existingActions: [], // Reset Existing Actions
      planNotes: [],
      pic: "",
      active: true, // Default active state
  });

  // 4a - Set the FormData based on the incoming data
  // useEffect(() => {
  //   if (userPlanOrgRecords?.length > 0 && !isFormInitialized) {
  //     const selectedRecord = userPlanOrgRecords[0];
    
  //     setFormData({
  //       missionId: selectedRecord?.missionId || null,
  //       planName: selectedRecord?.planName || "",
  //       planDesc: selectedRecord?.planDesc || "",
  //       planDate: selectedRecord?.planDate || "",
  //       planActions: selectedRecord?.planActions || [],
  //       planNotes: selectedRecord?.planNotes || [],
  //       aboutMe: selectedRecord?.aboutMe || '',
  //       pic: selectedRecord?.pic || '',
  //       active: selectedRecord?.active ?? true,
  //     });

  //     setIsFormInitialized(true); // Prevent future overwrites
  //   }
  // }, [userPlanOrgRecords, isFormInitialized]);
  useEffect(() => {
    if (userPlanOrgRecords?.length > 0 && !isFormInitialized) {
      const selectedRecord = userPlanOrgRecords[0];
  
      setFormData({
        missionId: selectedRecord?.missionId._id || null,
        planName: selectedRecord?.planName || "",
        planDesc: selectedRecord?.planDesc || "",
        planDate: selectedRecord?.planDate || "",
        planActions: selectedRecord?.planActions || [],
        existingActions: selectedRecord?.planActions?.map((action) => ({
          actionId: action.planActionId?._id || null,
          userActionName: action.planActionId?.userActionName || "",
          userActionDesc: action.planActionId?.userActionDesc || "",
          completeBy: action.planActionId?.completeBy || "",
        })) || [],
        planNotes: selectedRecord?.planNotes || [],
        aboutMe: selectedRecord?.aboutMe || '',
        pic: selectedRecord?.pic || '',
        active: selectedRecord?.active ?? true,
      });
  
      setIsFormInitialized(true); // Prevent future overwrites
    }
  }, [userPlanOrgRecords, isFormInitialized]);
  
 
  // 5a - Convert Image to Base64
  const convertPicToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  // 5b - Handle Form Changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "pic") {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };
  
  // 5c - Toggle show / collapse each section 
  const toggleSection = (key) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // 5d - Function to add a new action to `newActions`
  const handleAddAction = () => {
    setFormData((prevState) =>
      produce(prevState, (draft) => {
        if (!draft.newActions) {
          draft.newActions = []; // Ensure newActions is always an array
        }
        draft.newActions.push({
          userActionName: "",
          userActionDesc: "",
          completeBy: "",
          actionTargetStakeholders: [{ stakeholderTypeName: "Org" }],
        });
      })
    );
  };
  
  
  // 5e - Function to remove a new action to `newActions`
  const handleRemoveAction = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      newActions: prevState.newActions.filter((_, idx) => idx !== index),
    }));
    setCollapsedSections((prev) => {
      const updatedSections = { ...prev };
      delete updatedSections[`action-${index}`]; // Remove the collapsed section state for the action
      return updatedSections;
    });
  };

  // 5f - Function to handle action change
  const handleActionChange = (index, fieldName, value) => {
    setFormData((prevState) => ({
      ...prevState,
      newActions: prevState.newActions.map((action, idx) =>
        idx === index ? { ...action, [fieldName]: value } : action
      ),
    }));
  };

  // 6 - Handle form subplan
//   const handleSubmit = async (e) => {
//     e.preventDefault();
  
//     try {
//       // Convert image to Base64 if it exists
//       const picBase64 = formData.pic ? await convertPicToBase64(formData.pic) : undefined;
  
//       // Filter out valid notes
//       // const validNotes = formData.planNotes?.filter(
//       //   (note) => note?.planNoteText.trim()
//       // );
//       const validNotes = formData.planNotes?.filter((note) => !note.delete); // Notes not marked for deletion
// const deletedNotes = formData.planNotes?.filter((note) => note.delete); // Notes marked for deletion
  
//       // Construct the payload
//       const payload = {
//           planOrgId: id, // Assuming `id` is the identifier for the record to update
//           userId,
//           userOrgId,
//           missionId: formData.missionId || null, // Use null for empty values
//           planName: formData.planName.trim() || undefined,
//           planDesc: formData.planDesc.trim() || undefined,
//           planDate: formData.planDate || undefined,
          
//           planNotes: validNotes.map((note) => ({
//             ...note,
//             planNoteText: note.planNoteText.trim(),
//           })),
//           pic: picBase64 || undefined,
//           active: formData.active,
//       };
  
//       // Update the planOrg using the mutation
//       await updateUserPlanOrg(payload).unwrap();
  
//       if (onClose) {
//         onClose(); // Close the modal if `onClose` is provided
//       } else {
//         navigate(triggeringUrl || "/userMagicHome"); // Redirect on success
//       }
//     } catch (error) {
//       console.error("Error updating planOrg:", error);
//     }
//   };
    const handleSubmit = async (e) => {
      e.preventDefault();

      try {
        // Convert image to Base64 if it exists
        const picBase64 = formData.pic ? await convertPicToBase64(formData.pic) : undefined;

        // Validate and filter notes
        const validNotes = formData.planNotes?.filter((note) => !note.delete && note.planNoteText.trim());
        const deletedNotes = formData.planNotes?.filter((note) => note.delete);

        // Validate required fields
        if (!formData.planName.trim()) {
          alert("Plan name is required.");
          return;
        }

        if (!formData.missionId) {
          alert("A mission must be selected.");
          return;
        }

        // Construct the payload
        const payload = {
          planOrgId: id, // Identifier for the record to update
          userId,
          userOrgId,
          missionId: formData.missionId,
          trailId: trailId,
          sessionId: sessionId,
          planName: formData.planName.trim(),
          planDesc: formData.planDesc?.trim() || undefined,
          planDate: formData.planDate || undefined,
          pic: picBase64 || undefined,
          active: formData.active,
          newActions: formData.newActions?.map((action) => ({
            ...action,
            userActionName: action.userActionName.trim(),
            completeBy: action.completeBy,
            actionTypeId, // Use the pre-determined action type ID
          })),
          existingActions: formData.existingActions?.map((action) => ({
            actionId: action.actionId,
            userActionName: action.userActionName?.trim(),
            userActionDesc: action.userActionDesc?.trim(),
            completeBy: action.completeBy,
          })),
          planNotes: [
            ...validNotes.map((note) => ({
              _id: note._id || undefined, // For existing notes
              planNoteText: note.planNoteText.trim(),
              planNoteDate: note.planNoteDate,
            })),
            ...deletedNotes.map((note) => ({
              _id: note._id, // For deleted notes, _id is required
              delete: true,
            })),
          ],
        };

        console.log("Payload:", payload); // Debugging purposes

        // Update the planOrg using the mutation
        await updateUserPlanOrg(payload).unwrap();

        // Handle success
        if (onClose) {
          onClose(); // Close modal if onClose is provided
        } else {
          navigate(triggeringUrl || "/userMagicHome"); // Redirect on success
        }
      } catch (error) {
        console.error("Error updating planOrg:", error);
        alert("Failed to update the plan. Please try again.");
      }
    };


  
  
  // 7 - Reset Form on Successful Subplan
        useEffect(() => {
          if (isSuccess) {
            setFormData({
              missionId: null,
              planName: "",
              planDesc: "",
              planDate: "",
              newActions: [], // Reset newActions
              existingActions: [], // Reset Existing Actions
              planNotes: [],
              pic: "",
              active: true, // Default active state
            });
      
            if (onClose) {
              onClose();
            } else {
              navigate(triggeringUrl || "/userMagicHome");
            }
          }
        }, [isSuccess, navigate]);
      
      // 8 - Handle Clear
      const handleClear = () => {
      setFormData({
        missionId: null,
        planName: "",
        planDesc: "",
        planDate: "",
        newActions: [], // Reset newActions
        existingActions: [], // Reset Existing Actions
        planNotes: [],
        pic: "",
        active: true, // Default active state
      });
      };
  
      // 9 - Handle Cancel
      const handleCancel = () => {
      if (onClose) {
          onClose();
      } else {
          navigate(triggeringUrl || "/userMagicHome");
      }
      };

      // console.log('FormData:', formData)

  return (
    <div className="mx-auto w-full m-1 p-1 bg-white rounded shadow-md shadow-black">
      {isLoading && <PulseLoader color={'#ea580c'} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">{error?.data?.message}</p>
      )}

      <p className="p-1 m-1 font-bold font-poppins text-xl">
        Update User Org Plan
      </p>

      <form onSubmit={handleSubmit} className="m-1 p-1 text-xs font-poppins">
        
        {/* 1. Org Plan - Name */}
        <div id="planOrgName" className="bg-gray-100 rounded p-1 m-1">
          <label htmlFor="planName" className="block text-gray-600 text-sm font-bold mb-1">
            Org Plan Name
          </label>
          <input
            type="text"
            name="planName"
            value={formData.planName}
            onChange={(e) =>
              setFormData((prev) =>
                produce(prev, (draft) => {
                  draft.planName = e.target.value;
                })
              )
            }
            placeholder="Enter planOrg name"
            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
          />
        </div>

        {/* 2. Org Plan - Description */}
        <div id="planOrgDesc" className="bg-gray-100 rounded p-1 m-1">
          <label
            htmlFor="planDesc"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
            Org Plan Description
          </label>
          {isFormInitialized && ( 
          <EtContentEditorLight2
            value={formData.planDesc}
            handleChange={(content) => {
              setFormData((prev) =>
                produce(prev, (draft) => {
                  draft.planDesc = content;
                })
              );
            }}
            placeholderText="Write your planOrg description here..."
            editorKey={`planOrgDesc-main-${id}`} // Ensure unique editor key
          />)}
        </div>
        
        {/* 3. Org Plan - Date */}
        <div id="planOrgStartDate" className="bg-gray-100 rounded p-1 m-1 w-full">
          <label
            htmlFor="planStartDate"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
            Org Plan Date
          </label>
          <input
            type="date"
            name="planStartDate"
            value={formData.planDate ? formData.planDate.split('T')[0] : ''}
            onChange={(e) =>
              setFormData((prev) =>
                produce(prev, (draft) => {
                  draft.planDate = e.target.value;
                })
              )
            }
            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
          />
        </div>

        {/* 4. Mission Selection */}
        <div id="missionId" className="bg-gray-100 rounded p-1 m-1">
          <label htmlFor="missionId" className="block text-gray-600 text-sm font-bold mb-1">
            Select Mission
          </label>
          <select
            name="missionId"
            value={formData.missionId || ""}
            onChange={handleChange}
            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
          >
            <option value="">-- Select Mission --</option>
            {missions?.map((mission) => (
              <option key={mission._id} value={mission._id}>
                {mission.missionName}
              </option>
            ))}
          </select>
        </div>

        {/* 5. Plan Actions */}
        <div id="planActions" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <p className="font-bold text-sm">Plan Actions</p>
            <button
              type="button"
              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
              onClick={handleAddAction} // Use the existing function to add a new action
            >
              <PlusIcon className="h-4 w-4 inline" />
            </button>
          </div>

          {/* Render existing actions */}
          {formData.existingActions?.map((action, actionIndex) => (
            <div key={actionIndex} className="p-2 border rounded mb-2 bg-white">
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center space-x-2">
                  <button
                    type="button"
                    className="text-orange-600 text-xs font-bold m-1 p-1 rounded"
                    onClick={() => toggleSection(`existingAction-${actionIndex}`)} // Toggle collapse/expand
                  >
                    {collapsedSections[`existingAction-${actionIndex}`] ? (
                      <PlusIcon className="h-4 w-4" />
                    ) : (
                      <MinusIcon className="h-4 w-4" />
                    )}
                  </button>
                  <p className="font-bold text-xs">
                    Action {actionIndex + 1}: {action?.userActionName || "Unnamed"}
                  </p>
                </div>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() =>
                    setFormData((prevState) =>
                      produce(prevState, (draft) => {
                        // Mark the action as deleted for backend processing
                        draft.planActions.push({ planActionId: draft.existingActions[actionIndex].actionId, delete: true });
                        // Remove from existingActions for UI
                        draft.existingActions.splice(actionIndex, 1);
                      })
                    )
                  }
                  
                >
                  <MinusIcon className="h-4 w-4 inline" />
                </button>
              </div>

              {/* Collapsible content for editing the action */}
              {!collapsedSections[`existingAction-${actionIndex}`] && (
                <>
                  {/* Action Name */}
                  <div id={`actionName-${actionIndex}`} className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`actionName-${actionIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Action Name
                    </label>
                    <input
                      type="text"
                      name={`actionName-${actionIndex}`}
                      value={action?.userActionName || ""}
                      onChange={(e) =>
                        setFormData((prev) =>
                          produce(prev, (draft) => {
                            if (!draft.existingActions[actionIndex]) {
                              draft.existingActions[actionIndex] = {};
                            }
                            draft.existingActions[actionIndex].userActionName = e.target.value;
                          })
                        )
                      }
                      placeholder="Enter action name"
                      className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                    />
                  </div>

                  {/* Action Description */}
                  <div id={`actionDesc-${actionIndex}`} className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`actionDesc-${actionIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Action Description
                    </label>
                    <EtContentEditorLight2
                      value={action?.userActionDesc || ""}
                      handleChange={(content) =>
                        setFormData((prev) =>
                          produce(prev, (draft) => {
                            if (!draft.existingActions[actionIndex]) {
                              draft.existingActions[actionIndex] = {};
                            }
                            draft.existingActions[actionIndex].userActionDesc = content;
                          })
                        )
                      }
                      placeholderText="Write your action description here..."
                      editorKey={`actionDesc-${actionIndex}-${action?.actionId}`} // Ensure unique editor key
                    />
                  </div>

                  {/* Complete By */}
                  <div id={`completeBy-${actionIndex}`} className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`completeBy-${actionIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Complete By
                    </label>
                    <input
                      type="date"
                      name={`completeBy-${actionIndex}`}
                      value={
                        action?.completeBy
                          ? action?.completeBy.split('T')[0]
                          : ""
                      }
                      onChange={(e) =>
                        setFormData((prev) =>
                          produce(prev, (draft) => {
                            if (!draft.existingActions[actionIndex]) {
                              draft.existingActions[actionIndex] = {};
                            }
                            draft.existingActions[actionIndex].completeBy = e.target.value;
                          })
                        )
                      }
                      className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                    />
                  </div>

                </>
              )}
            </div>
          ))}
        </div>

          {/* Render new actions */}
          {formData.newActions?.map((action, actionIndex) => (
            <div key={`new-${actionIndex}`} className="p-2 border rounded mb-2 bg-white">
              <div className="flex justify-between items-center mb-2">
                <p className="font-bold text-xs">
                  New Action {actionIndex + 1}: {action?.userActionName || "Unnamed"}
                </p>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() =>
                    setFormData((prevState) =>
                      produce(prevState, (draft) => {
                        draft.newActions.splice(actionIndex, 1); // Remove new action
                      })
                    )
                  }
                >
                  <MinusIcon className="h-4 w-4 inline" />
                </button>
              </div>

              {/* Editable fields for new actions */}
              <div id={`newActionName-${actionIndex}`} className="bg-gray-100 rounded p-1 m-1">
                <label
                  htmlFor={`newActionName-${actionIndex}`}
                  className="block text-gray-600 text-sm font-bold mb-1"
                >
                  Action Name
                </label>
                <input
                  type="text"
                  name={`newActionName-${actionIndex}`}
                  value={action?.userActionName || ""}
                  onChange={(e) =>
                    setFormData((prevState) =>
                      produce(prevState, (draft) => {
                        draft.newActions[actionIndex].userActionName = e.target.value;
                      })
                    )
                  }
                  placeholder="Enter action name"
                  className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                />
              </div>

              {/* Additional fields like Action Description and Complete By */}
              {/* Action Description */}
              <div id={`newActionDesc-${actionIndex}`} className="bg-gray-100 rounded p-1 m-1">
                <label
                  htmlFor={`newActionDesc-${actionIndex}`}
                  className="block text-gray-600 text-sm font-bold mb-1"
                >
                  Action Description
                </label>
                <EtContentEditorLight2
                  value={action?.userActionDesc || ""}
                  handleChange={(content) =>
                    setFormData((prevState) =>
                      produce(prevState, (draft) => {
                        draft.newActions[actionIndex].userActionDesc = content;
                      })
                    )
                  }
                  placeholderText="Write your action description here..."
                  editorKey={`newActionDesc-${actionIndex}`} // Ensure unique editor key
                />
              </div>

              <div id={`newActionCompleteBy-${actionIndex}`} className="bg-gray-100 rounded p-1 m-1">
                <label
                  htmlFor={`newActionCompleteBy-${actionIndex}`}
                  className="block text-gray-600 text-sm font-bold mb-1"
                >
                  Complete By
                </label>
                <input
                  type="date"
                  name={`newActionCompleteBy-${actionIndex}`}
                  value={action?.completeBy || ""}
                  onChange={(e) =>
                    setFormData((prevState) =>
                      produce(prevState, (draft) => {
                        draft.newActions[actionIndex].completeBy = e.target.value;
                      })
                    )
                  }
                  className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                />
              </div>
            </div>
          ))}

        {/* 6. Org Plan - Notes */}
        <div id="planOrgNotes" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <p className="font-bold text-sm">Org Plan Notes</p>
            <button
              type="button"
              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
              onClick={() =>
                setFormData((prev) =>
                  produce(prev, (draft) => {
                    draft.planNotes.push({
                      planNoteText: "",
                      planNoteDate: new Date().toISOString(),
                    });
                  })
                )
              }
            >
              <PlusIcon className="h-4 w-4 inline" />
            </button>
          </div>

          {formData.planNotes?.map((note, index) => (
            <div key={index} className="p-2 border rounded mb-2 bg-white">
              <div className="flex justify-between items-center mb-2">
                <p className="font-bold text-xs">Note {index + 1}</p>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() =>
                    setFormData((prev) =>
                      produce(prev, (draft) => {
                        draft.planNotes[index].delete = true; // Mark as deleted
                      })
                    )
                  }
                  
                >
                  <MinusIcon className="h-4 w-4 inline" />
                </button>
              </div>

              {/* Note Text */}
              <div className="bg-gray-100 rounded p-1 m-1">
                <label
                  htmlFor={`planOrgNoteText-${index}`}
                  className="block text-gray-600 text-sm font-bold mb-1"
                >
                  Note Text
                </label>
                {isFormInitialized && ( 
                <EtContentEditorLight2
                  value={note.planNoteText}
                  handleChange={(content) => {
                    setFormData((prev) =>
                      produce(prev, (draft) => {
                        draft.planNotes[index].planNoteText = content;
                      })
                    );
                  }}
                  placeholderText="Write your note here..."
                  editorKey={`planOrgNote-${index}-${note?._id || `new-${index}`}`} // Ensure unique editor key
                />)}
              </div>
            </div>
          ))}
        </div>

        {/* 9. Form Submissions Bar */}
        <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic 
              name={'Update'} 
              type={'create'} 
              handleClick={handleSubmit} 
              />
            <EtBtnFormBasic
              name={'Clear'}
              type={'clear'}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={'Cancel'}
              type={'cancel'}
              handleClick={handleCancel}
            />
        </div>

      </form>
      
    </div>
  )
}

export default EtUpdateUserImprintPlanOrgComp