import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const capabilitiesSoftSkillFunctionAreaTypesAdapter = createEntityAdapter({})

const initialState = capabilitiesSoftSkillFunctionAreaTypesAdapter.getInitialState()

export const capabilitiesSoftSkillFunctionAreaTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCapabilitiesSoftSkillFunctionAreaTypes: builder.query({
            query: () => ({
                url: '/capabilitiesSoftSkillFunctionAreaTypes/getCapabilitiesSoftSkillFunctionAreaTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedCapabilitiesSoftSkillFunctionAreaTypes = responseData.map(capabilitiesSoftSkillFunctionAreaType => {
                    capabilitiesSoftSkillFunctionAreaType.id = capabilitiesSoftSkillFunctionAreaType._id
                    return capabilitiesSoftSkillFunctionAreaType
                })
                return capabilitiesSoftSkillFunctionAreaTypesAdapter.setAll(initialState, loadedCapabilitiesSoftSkillFunctionAreaTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'CapabilitiesSoftSkillFunctionAreaType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'CapabilitiesSoftSkillFunctionAreaType', id}))
                    ]
                } else {
                    return [ { type: 'CapabilitiesSoftSkillFunctionAreaType', id: 'LIST'}]
                }
            },
        }),
        createNewCapabilitiesSoftSkillFunctionAreaType: builder.mutation({
            query: initialCapabilitiesSoftSkillFunctionAreaType => ({
                url: '/capabilitiesSoftSkillFunctionAreaTypes/createNewCapabilitiesSoftSkillFunctionAreaType',
                method: 'POST',
                body: {...initialCapabilitiesSoftSkillFunctionAreaType,}
            }),
            invalidatesTags: [
                { type: 'CapabilitiesSoftSkillFunctionAreaType', id: "LIST" }
            ] 
        }),
        updateCapabilitiesSoftSkillFunctionAreaType: builder.mutation({
            query: initialCapabilitiesSoftSkillFunctionAreaType => ({
                url: '/capabilitiesSoftSkillFunctionAreaTypes/updateCapabilitiesSoftSkillFunctionAreaType',
                method: 'PATCH',
                body: {...initialCapabilitiesSoftSkillFunctionAreaType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'CapabilitiesSoftSkillFunctionAreaType', id: arg.id },
                { type: 'CapabilitiesSoftSkillFunctionAreaType', id: 'LIST' }
            ],
        }),
        deleteCapabilitiesSoftSkillFunctionAreaType: builder.mutation({
            query: ({id}) => ({
                url: '/capabilitiesSoftSkillFunctionAreaTypes/deleteCapabilitiesSoftSkillFunctionAreaType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'CapabilitiesSoftSkillFunctionAreaType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetCapabilitiesSoftSkillFunctionAreaTypesQuery,
    useCreateNewCapabilitiesSoftSkillFunctionAreaTypeMutation,
    useUpdateCapabilitiesSoftSkillFunctionAreaTypeMutation,
    useDeleteCapabilitiesSoftSkillFunctionAreaTypeMutation,
} = capabilitiesSoftSkillFunctionAreaTypesApiSlice

// returns the query result object
export const selectCapabilitiesSoftSkillFunctionAreaTypeResult = capabilitiesSoftSkillFunctionAreaTypesApiSlice.endpoints.getCapabilitiesSoftSkillFunctionAreaTypes.select()

// create memoized selector
const selectCapabilitiesSoftSkillFunctionAreaTypesData = createSelector(
    selectCapabilitiesSoftSkillFunctionAreaTypeResult,
    capabilitiesSoftSkillFunctionAreaTypeResult => capabilitiesSoftSkillFunctionAreaTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCapabilitiesSoftSkillFunctionAreaTypes,
    selectById: selectCapabilitiesSoftSkillFunctionAreaTypeById,
    selectIds: selectCapabilitiesSoftSkillFunctionAreaTypeIds,
} = capabilitiesSoftSkillFunctionAreaTypesAdapter.getSelectors(state => selectCapabilitiesSoftSkillFunctionAreaTypesData(state) ?? initialState)