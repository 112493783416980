import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import useTitle from "@/hooks/useTitle"

import EtEtTrinityIcon6SVGComp from "@/assets/img/icons/svgComp/EtEtTrinityIcon6SVGComp";


// 09 Mar 2025
import EtTrinityWebLandingWelcome from './webLanding/EtTrinityWebLandingWelcome'
import EtTrinityWebLandingAction from './webLanding/EtTrinityWebLandingAction'

// 10 Mar 2025
import EtHomeActionsIconSVGComp from "@/assets/img/icons/svgComp/EtHomeActionsIcon1SVGComp";
import EtHomeWelcomeIconSVGComp from "@/assets/img/icons/svgComp/EtHomeWelcomeIcon1SVGComp";

// 12 Mar 2025
import EtRunMarkIconSVGComp from "@/assets/img/icons/svgComp/EtRunMarkIcon3SVGComp";
import EtRunSetIconSVGComp from "@/assets/img/icons/svgComp/EtRunSetIcon1SVGComp";
import EtRunGoIconSVGComp from "@/assets/img/icons/svgComp/EtRunGoIcon1SVGComp";


const EtTrinityWebLanding = ({username}) => {

  // Author - Atul Pandey
  // First Created - 07 Mar 2025
  // Purpose to act as the home page for Trinity Landing
  // Updated - 14 Mar 2025 - added username prop to the component and to pass it to other components called from here - i.e. EtTrinityWebLandingAction
  
  
  // 1a - Set Page Title
    useTitle('Trinity Home')
  
  // 1b1 - State variables to show / hide sections
  const [ activeTab, setActiveTab ] = useState("actions") // 08 Mar 2025


  return (
    <div className='mx-auto'>
        <div className='flex justify-start items-center m-1 p-1'>
          
          <div className='relative group justify-center items-center cursor-pointer'>
            <div 
              onClick={() => setActiveTab("welcome")}
              className={`flex items-center font-bold mx-2 cursor-pointer ${
                activeTab === "welcome" ? "text-orange-600" : ""
              }`}
            >
              {/* <EtHomeWelcomeIconSVGComp className='h-8 w-8'/> */}
              <EtRunMarkIconSVGComp className='h-8 w-8'/>
              {/* <p className='mx-1 px-1 text-xs italic'>Welcome!</p> */}
              {/* <p className='mx-1 px-1 text-xs italic'>On Mark!</p> */}
              <p className='mx-1 px-1 text-xs italic'>Power up!</p>
            </div>
            {/* Tooltip */}
              {/* <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-xs rounded py-1 px-2 z-10">
                  Welcome
              </div> */}
              <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-xs rounded py-1 px-2 z-10">
                  Welcome
              </div>
          </div>
          
          {/* <div className='relative group justify-center items-center cursor-pointer'>
            <div 
              onClick={() => setActiveTab("getset")}
              className={`flex items-center font-bold mx-2 cursor-pointer ${
                activeTab === "getset" ? "text-orange-600" : ""
              }`}
            >
               {/* <EtHomeActionsIconSVGComp className='h-8 w-8'/> 
              <EtRunSetIconSVGComp className='h-8 w-8'/>
              <p className='mx-1 px-1 text-xs italic'>Get Set!</p>
            </div>
             Tooltip
            <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-xs rounded py-1 px-2 z-10">
                  
                  Get Set
              </div>
          </div> */}

          <div className='relative group justify-center items-center cursor-pointer'>
            <div 
              onClick={() => setActiveTab("actions")}
              className={`flex items-center font-bold mx-2 cursor-pointer ${
                activeTab === "actions" ? "text-orange-600" : ""
              }`}
            >
              {/* <EtHomeActionsIconSVGComp className='h-8 w-8'/> */}
              <EtRunGoIconSVGComp className='h-8 w-8'/>
              {/* <p className='mx-1 px-1 text-xs italic'>Actions!</p> */}
              <p className='mx-1 px-1 text-xs italic'>Go!</p>
            </div>
            {/* Tooltip */}
            <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-xs rounded py-1 px-2 z-10">
                  Actions
              </div>
          </div>
        
        </div>

        <div className='border-t border-gray-400 my-2 py-2'>
          {activeTab === 'welcome' && (
            <div>
              <EtTrinityWebLandingWelcome />
            </div>
          )}

          {activeTab === 'actions' && (
            <div>
              {/* <EtTrinityWebLandingAction /> */}
              <EtTrinityWebLandingAction username={username}/> {/*  14 Mar 2025 */}
            </div>
          )}

        </div>
    </div>
  )
}

export default EtTrinityWebLanding