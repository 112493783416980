
    import React from 'react';
    
    const EtWabConversationIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m1564 2194.9c-224.02 44.719-414.14-115.59-449.57-147.94-22.078-42.609-99.562-183.66-202.92-275.91 93.094-45.844 157.22-141.75 157.22-252.28 0-155.11-126.14-281.25-281.25-281.25s-281.25 126.14-281.25 281.25c0 76.219 30.516 145.41 79.875 196.03-80.438 34.031-141.05 100.12-181.12 197.44-46.688 113.34-67.5 269.72-67.5 506.52v225c0 186.19 151.31 337.5 337.5 337.5h562.5c108.56 0 196.88-88.312 196.88-196.88v-140.62c0-93.094-75.656-168.75-168.75-168.75h-176.48c-20.25 0-40.219 3.6562-59.344 10.688l-430.88 161.58c-29.109 10.969-43.875 43.312-32.906 72.422s43.312 43.875 72.422 32.906l430.88-161.58c6.3281-2.3906 12.938-3.6562 19.688-3.6562h176.48c30.938 0 56.25 25.312 56.25 56.25v140.62c0 46.547-37.828 84.375-84.375 84.375h-562.5c-124.03 0-225-100.97-225-225v-225c0-445.21 78.75-618.77 281.25-618.77 100.97 0 237.09 206.44 287.16 306.42 2.6719 5.3438 6.1875 10.406 10.547 14.625 6.4688 6.4688 110.67 108.84 267.47 162.28-128.25 12.375-229.36-17.859-256.22-27l-273.94-219.09c-24.188-19.406-59.625-15.469-79.031 8.7188s-15.469 59.625 8.7188 79.031l35.156 28.125v321.75c0 31.078 25.172 56.25 56.25 56.25s56.25-25.172 56.25-56.25v-231.75l133.59 106.88c4.2188 3.375 9.1406 6.1875 14.203 8.2969 12.516 5.0625 310.64 120.8 612.29-60.188 23.484-14.062 33.469-43.031 23.625-68.625s-36.703-40.219-63.422-34.875zm-776.52-844.88c93.094 0 168.75 75.656 168.75 168.75 0 92.953-75.656 168.75-168.75 168.75s-168.75-75.656-168.75-168.75c0-92.953 75.656-168.75 168.75-168.75z" fillRule="evenodd"/>
 <path d="m3224.5 1944c-28.688-86.203-79.172-144-152.72-174.38 48.375-50.625 78.328-118.97 78.328-194.48 0-155.11-126.14-281.25-281.25-281.25s-281.25 126.14-281.25 281.25c0 106.88 59.906 199.97 147.94 247.5-80.156 64.969-162.42 170.16-245.81 315-79.594 138.09-138.94 274.5-165.09 337.5h-18.281c-93.094 0-168.75 75.656-168.75 168.75v140.62c0 108.56 88.312 196.88 196.88 196.88h590.62c186.19 0 337.5-151.31 337.5-337.5v-225c0-201.52 0-360.72-37.969-474.77zm-355.78-537.77c93.094 0 168.75 75.656 168.75 168.75 0 92.953-75.656 168.75-168.75 168.75s-168.75-75.656-168.75-168.75c0-92.953 75.656-168.75 168.75-168.75zm281.25 1237.5c0 124.03-100.97 225-225 225h-590.62c-46.547 0-84.375-37.828-84.375-84.375v-140.62c0-30.938 25.312-56.25 56.25-56.25h220.92l389.81 55.688c30.797 4.3594 59.344-17.016 63.703-47.812 4.3594-30.656-17.016-59.344-47.812-63.703l-243.7-34.875 222.75-267.19c19.969-23.906 16.594-59.344-7.1719-79.312-23.906-19.969-59.344-16.594-79.312 7.1719l-267.19 320.62-19.125-2.8125c-2.6719-0.28125-5.3438-0.5625-7.875-0.5625h-84.375c29.953-69.188 79.594-176.34 140.91-282.38 125.3-216.84 245.11-336.38 337.36-336.38 214.03 0 225 144.56 225 562.5v225z" fillRule="evenodd"/>
 <path d="m2418.8 1125c0-279.14-277.59-506.23-618.77-506.23-84.516 0-166.5 13.641-243.56 40.5-29.25 10.266-44.719 42.328-34.594 71.719 10.266 29.25 42.188 44.719 71.719 34.594 65.109-22.781 134.58-34.312 206.44-34.312 279.14 0 506.23 176.62 506.23 393.77 0 217.14-227.11 393.77-506.23 393.77-79.312 0-155.39-14.062-226.12-41.625-14.906-5.7656-31.5-5.0625-45.562 2.1094l-81.984 41.062 17.578-52.875c7.5938-22.781 0-47.812-19.125-62.438-97.453-74.812-151.03-174.23-151.03-279.84 0-105.75 52.312-202.64 147.23-277.03 24.469-19.125 28.688-54.562 9.5625-79.031s-54.562-28.688-79.031-9.5625c-122.62 96.188-190.27 226.12-190.27 365.62 0 129.23 57.797 249.75 163.69 343.41l-48.375 145.12c-7.1719 21.656-0.70312 45.281 16.594 60.188 10.406 9 23.625 13.781 36.844 13.781 8.5781 0 17.156-1.9688 25.172-5.9062l181.69-90.844c76.922 27 158.62 40.5 243.28 40.5 341.16 0 618.77-227.11 618.77-506.23z" fillRule="evenodd"/>
 <path d="m1856.2 1293.8v-56.25c0-31.078-25.172-56.25-56.25-56.25s-56.25 25.172-56.25 56.25v56.25c0 31.078 25.172 56.25 56.25 56.25s56.25-25.172 56.25-56.25z" fillRule="evenodd"/>
 <path d="m2025 1293.8v-56.25c0-31.078-25.172-56.25-56.25-56.25s-56.25 25.172-56.25 56.25v56.25c0 31.078 25.172 56.25 56.25 56.25s56.25-25.172 56.25-56.25z" fillRule="evenodd"/>
 <path d="m1687.5 1293.8v-56.25c0-31.078-25.172-56.25-56.25-56.25s-56.25 25.172-56.25 56.25v56.25c0 31.078 25.172 56.25 56.25 56.25s56.25-25.172 56.25-56.25z" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtWabConversationIcon1SVGComp;
    