import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import {
  PlusIcon,
  MinusIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline"
import { ClockIcon, BuildingOffice2Icon,ArrowPathIcon, CalendarDaysIcon, Squares2X2Icon, ArrowTrendingUpIcon, InboxArrowDownIcon, UserIcon, ArrowDownCircleIcon, ArrowUpCircleIcon  } from "@heroicons/react/24/solid"

import { useGetUserPlanSelfsQuery } from '@/features/userPlans/userPlanSelfsApiSlice'
// 16 Jan 2025
import { useGetUserPlanClientsQuery } from '@/features/userPlans/userPlanClientsApiSlice'
import { useGetUserPlanOrgsQuery } from '@/features/userPlans/userPlanOrgsApiSlice'
import { useGetUserPlanPartnersQuery } from '@/features/userPlans/userPlanPartnersApiSlice'
import { useGetUserPlanTeamsQuery } from '@/features/userPlans/userPlanTeamsApiSlice'

import EtImprintPlansSelfIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintPlansSelfIcon1SVGComp'
import EtImprintPlansClientsIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintPlansClientsIcon1SVGComp'
import EtImprintPlanOrgIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintPlansOrgIcon1SVGComp'
import EtImprintPlanPartnerIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintPlansPartnerIcon1SVGComp'
import EtImprintPlanTeamIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintPlansTeamIcon1SVGComp'

import EtModalCreateUserImprintPlanSelf from "@/components/common/modal/EtModalCreateUserImprintPlanSelf"
import EtModalUpdateUserImprintPlanSelf from "@/components/common/modal/EtModalUpdateUserImprintPlanSelf"

// 16 Jan 2025
import EtModalCreateUserImprintPlanClient from "@/components/common/modal/EtModalCreateUserImprintPlanClient"
import EtModalUpdateUserImprintPlanClient from "@/components/common/modal/EtModalUpdateUserImprintPlanClient"
import EtModalCreateUserImprintPlanOrg from "@/components/common/modal/EtModalCreateUserImprintPlanOrg"
import EtModalUpdateUserImprintPlanOrg from "@/components/common/modal/EtModalUpdateUserImprintPlanOrg"
import EtModalCreateUserImprintPlanPartner from "@/components/common/modal/EtModalCreateUserImprintPlanPartner"
import EtModalUpdateUserImprintPlanPartner from "@/components/common/modal/EtModalUpdateUserImprintPlanPartner"
import EtModalCreateUserImprintPlanTeam from "@/components/common/modal/EtModalCreateUserImprintPlanTeam"
import EtModalUpdateUserImprintPlanTeam from "@/components/common/modal/EtModalUpdateUserImprintPlanTeam"


import EtImprintPlanNotesIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintPlanNotesIcon1SVGComp'
import EtImprintMissionsIcon2SVGComp from '@/assets/img/icons/svgComp/EtImprintMissionsIcon2SVGComp'
import EtImprintPlansIcon2SVGComp from '@/assets/img/icons/svgComp/EtImprintPlansIcon2SVGComp'
import EtImprintPlanEvolutionIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintPlanEvolutionIcon1SVGComp'
import EtShowcaseSelfNotesIcon1SVGComp from '@/assets/img/icons/svgComp/EtShowcaseSelfNotesIcon1SVGComp'

import { createSvgComponentFromBase64 } from '@/utils/createSvgComponentFromBase64'

// 16 Jan 2025
import EtUserActionListByIdComp1 from "@/features/userActions/EtUserActionListByIdComp1"

import {
    formatDateToMonthYear,
    formatDateToYear,
    formatDateTime,
    formatShortDate,
    calculateYearsAndMonths,
  } from "@/utils/formatDate"
  
  import { getTimeSince } from "@/utils/EtGetTimeSince"

const EtImprintPlan = ({ userId, userInfo }) => {
    // First Created - 14 Jan 2025
    // Author - Atul Pandey
    

    // 1a1 - States for Self Plan Modal -- 14 Jan 2025
    const [isCreatePlanSelfModalOpen, setIsCreatePlanSelfModalOpen] = useState(false)
    const [isUpdatePlanSelfModalOpen, setIsUpdatePlanSelfModalOpen] = useState(false)
    const [createPlanSelfTriggeringUrl, setCreatePlanSelfTriggeringUrl] = useState(null)
    const [updatePlanSelfTriggeringUrl, setUpdatePlanSelfTriggeringUrl] = useState(null)
    
    // 1a2 - States for Clients Plan Modal -- 14 Jan 2025
    const [isCreatePlanClientsModalOpen, setIsCreatePlanClientsModalOpen] = useState(false)
    const [isUpdatePlanClientsModalOpen, setIsUpdatePlanClientsModalOpen] = useState(false)
    const [createPlanClientsTriggeringUrl, setCreatePlanClientsTriggeringUrl] = useState(null)
    const [updatePlanClientsTriggeringUrl, setUpdatePlanClientsTriggeringUrl] = useState(null)
    
    // 1a3 - States for Org Plan Modal -- 14 Jan 2025
    const [isCreatePlanOrgModalOpen, setIsCreatePlanOrgModalOpen] = useState(false)
    const [isUpdatePlanOrgModalOpen, setIsUpdatePlanOrgModalOpen] = useState(false)
    const [createPlanOrgTriggeringUrl, setCreatePlanOrgTriggeringUrl] = useState(null)
    const [updatePlanOrgTriggeringUrl, setUpdatePlanOrgTriggeringUrl] = useState(null)
    
    // 1a4 - States for Team Plan Modal -- 14 Jan 2025
    const [isCreatePlanTeamModalOpen, setIsCreatePlanTeamModalOpen] = useState(false)
    const [isUpdatePlanTeamModalOpen, setIsUpdatePlanTeamModalOpen] = useState(false)
    const [createPlanTeamTriggeringUrl, setCreatePlanTeamTriggeringUrl] = useState(null)
    const [updatePlanTeamTriggeringUrl, setUpdatePlanTeamTriggeringUrl] = useState(null)
    
    // 1a5 - States for Partners Plan Modal -- 14 Jan 2025
    const [isCreatePlanPartnersModalOpen, setIsCreatePlanPartnersModalOpen] = useState(false)
    const [isUpdatePlanPartnersModalOpen, setIsUpdatePlanPartnersModalOpen] = useState(false)
    const [createPlanPartnersTriggeringUrl, setCreatePlanPartnersTriggeringUrl] = useState(null)
    const [updatePlanPartnersTriggeringUrl, setUpdatePlanPartnersTriggeringUrl] = useState(null)
    
    // 1b - State for plan tabs
    const [activeTab, setActiveTab] = useState("self")

    // 1c - State for selected vision id - 14 Jan 2025
    const [ selectedPlanId, setSelectedPlanId ] = useState(null)

    // 2a - Extract needed data from the incoming props
    const userOrgId = userInfo[0]?.userOrgId._id || null
    // console.log('userOrgId', userOrgId)

    // 2b - Get User Plan Data - 14 Jan 2025
    const { data: userPlanSelfData } = useGetUserPlanSelfsQuery()
    const { data: userPlanClientData } = useGetUserPlanClientsQuery() // 16 Jan 2025
    const { data: userPlanOrgData } = useGetUserPlanOrgsQuery() // 16 Jan 2025
    const { data: userPlanPartnerData } = useGetUserPlanPartnersQuery() // 16 Jan 2025
    const { data: userPlanTeamData } = useGetUserPlanTeamsQuery() // 16 Jan 2025

    const extractFilteredEntitiesFromAdapter = (dataAdapter) => {
      if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
      return dataAdapter.ids.map((id) => dataAdapter.entities[id])
      ?.filter(
          (item) => item.userId?._id === userId && item.userOrgId._id === userOrgId
          );
    };

    const userPlanSelfs = extractFilteredEntitiesFromAdapter(userPlanSelfData)
    const userPlanClients = extractFilteredEntitiesFromAdapter(userPlanClientData)
    const userPlanOrgs = extractFilteredEntitiesFromAdapter(userPlanOrgData)
    const userPlanPartners = extractFilteredEntitiesFromAdapter(userPlanPartnerData)
    const userPlanTeams = extractFilteredEntitiesFromAdapter(userPlanTeamData)
    console.log('userPlanSelfs', userPlanSelfs)

    // 3a1 - Handle Create User Self Plan -- 14 Jan 2025
    const handleCreateUserPlanSelf = () => {
      const currentUrl = window.location.pathname
      setIsCreatePlanSelfModalOpen(true)
      setCreatePlanSelfTriggeringUrl(currentUrl)
    }

    const handleCloseCreatePlanSelfModal = () => {
        setIsCreatePlanSelfModalOpen(false)
        setCreatePlanSelfTriggeringUrl(null)
        // window.location.reload();
    }

    // 3a2 - Handle Create User Clients Plan -- 16 Jan 2025
    const handleCreateUserPlanClients = () => {
      const currentUrl = window.location.pathname
      setIsCreatePlanClientsModalOpen(true)
      setCreatePlanClientsTriggeringUrl(currentUrl)
    }

    const handleCloseCreatePlanClientsModal = () => {
        setIsCreatePlanClientsModalOpen(false)
        setCreatePlanClientsTriggeringUrl(null)
        // window.location.reload();
    }

    // 3a3 - Handle Create User Org Plan -- 16 Jan 2025
    const handleCreateUserPlanOrg = () => {
      const currentUrl = window.location.pathname
      setIsCreatePlanOrgModalOpen(true)
      setCreatePlanOrgTriggeringUrl(currentUrl)
    }

    const handleCloseCreatePlanOrgModal = () => {
        setIsCreatePlanOrgModalOpen(false)
        setCreatePlanOrgTriggeringUrl(null)
        // window.location.reload();
    }

    // 3a4 - Handle Create User Partners Plan -- 16 Jan 2025
    const handleCreateUserPlanPartners = () => {
      const currentUrl = window.location.pathname
      setIsCreatePlanPartnersModalOpen(true)
      setCreatePlanPartnersTriggeringUrl(currentUrl)
    }

    const handleCloseCreatePlanPartnersModal = () => {
        setIsCreatePlanPartnersModalOpen(false)
        setCreatePlanPartnersTriggeringUrl(null)
        // window.location.reload();
    }

    // 3a5 - Handle Create User Team Plan -- 16 Jan 2025
    const handleCreateUserPlanTeam = () => {
      const currentUrl = window.location.pathname
      setIsCreatePlanTeamModalOpen(true)
      setCreatePlanTeamTriggeringUrl(currentUrl)
    }

    const handleCloseCreatePlanTeamModal = () => {
        setIsCreatePlanTeamModalOpen(false)
        setCreatePlanTeamTriggeringUrl(null)
        // window.location.reload();
    }

    // 3b1 - Handle Update User Self Plan -- 16 Jan 2025
    const handleUpdateUserPlanSelf = (id) => {

      const currentUrl = window.location.pathname
      setSelectedPlanId(id)
      console.log('selectedPlanId', selectedPlanId)
      setIsUpdatePlanSelfModalOpen(true)
      setUpdatePlanSelfTriggeringUrl(currentUrl)
    }

    const handleCloseUpdatePlanSelfModal = () => {
        setSelectedPlanId(null)
        setIsUpdatePlanSelfModalOpen(false)
        setUpdatePlanSelfTriggeringUrl(null)
        // window.location.reload();
    }

    // 3b2 - Handle Update User Clients Plan -- 16 Jan 2025
    const handleUpdateUserPlanClients = (id) => {

      const currentUrl = window.location.pathname
      setSelectedPlanId(id)
      console.log('selectedPlanId', selectedPlanId)
      setIsUpdatePlanClientsModalOpen(true)
      setUpdatePlanClientsTriggeringUrl(currentUrl)
    }

    const handleCloseUpdatePlanClientsModal = () => {
        setSelectedPlanId(null)
        setIsUpdatePlanClientsModalOpen(false)
        setUpdatePlanClientsTriggeringUrl(null)
        // window.location.reload();
    }

    // 3b1 - Handle Update User Org Plan -- 16 Jan 2025
    const handleUpdateUserPlanOrg = (id) => {

      const currentUrl = window.location.pathname
      setSelectedPlanId(id)
      console.log('selectedPlanId', selectedPlanId)
      setIsUpdatePlanOrgModalOpen(true)
      setUpdatePlanOrgTriggeringUrl(currentUrl)
    }

    const handleCloseUpdatePlanOrgModal = () => {
        setSelectedPlanId(null)
        setIsUpdatePlanOrgModalOpen(false)
        setUpdatePlanOrgTriggeringUrl(null)
        // window.location.reload();
    }
    // 3b1 - Handle Update User Partners Plan -- 16 Jan 2025
    const handleUpdateUserPlanPartners = (id) => {

      const currentUrl = window.location.pathname
      setSelectedPlanId(id)
      console.log('selectedPlanId', selectedPlanId)
      setIsUpdatePlanPartnersModalOpen(true)
      setUpdatePlanPartnersTriggeringUrl(currentUrl)
    }

    const handleCloseUpdatePlanPartnersModal = () => {
        setSelectedPlanId(null)
        setIsUpdatePlanPartnersModalOpen(false)
        setUpdatePlanPartnersTriggeringUrl(null)
        // window.location.reload();
    }
    // 3b1 - Handle Update User Team Plan -- 16 Jan 2025
    const handleUpdateUserPlanTeam = (id) => {

      const currentUrl = window.location.pathname
      setSelectedPlanId(id)
      console.log('selectedPlanId', selectedPlanId)
      setIsUpdatePlanTeamModalOpen(true)
      setUpdatePlanTeamTriggeringUrl(currentUrl)
    }

    const handleCloseUpdatePlanTeamModal = () => {
        setSelectedPlanId(null)
        setIsUpdatePlanTeamModalOpen(false)
        setUpdatePlanTeamTriggeringUrl(null)
        // window.location.reload();
    }

    // 4 - UserInfo object for actions
    const userInfoDetails = {
      _id: userInfo[0]?._id,
      username: userInfo[0]?.username,
      name: userInfo[0]?.name,
    }
  
    return (
    <div className="bg-white rounded m-1 p-1">
      <div className="flex justify-start items-center m-1 p-1 font-poppins rounded">
          <div
              onClick={() => setActiveTab("self")}
              className={`flex items-center text-gray-600 font-bold mx-2 cursor-pointer ${
              activeTab === "self" ? "text-orange-600" : ""
              }`}
          >
              <div><EtImprintPlansSelfIcon1SVGComp className='h-8 w-8' /></div>
              <div>
                  <p className="mx-1 px-1 text-sm">Self</p>
                  <p className="mx-1 px-1 font-normal text-[9px] italic">how will I achieve it for myself?</p>
              </div>
          </div>
          <div
              onClick={() => setActiveTab("clients")}
              className={`flex items-center text-gray-600 font-bold mx-2 cursor-pointer ${
              activeTab === "clients" ? "text-orange-600" : ""
              }`}
          >
              <div><EtImprintPlansClientsIcon1SVGComp className='h-8 w-8' /></div>
              <div className=''>
                  <p className="mx-1 px-1 text-sm">Clients</p>
                  {/* <p className="mx-1 px-1 font-normal text-[9px] italic">what impact am I inspired to make for my clients?</p> */}
                  <p className="mx-1 px-1 font-normal text-[9px] italic">how will I achieve it for my clients?</p>
              </div>
          </div>
          <div
              onClick={() => setActiveTab("org")}
              className={`flex items-center text-gray-600 font-bold mx-2 cursor-pointer ${
              activeTab === "org" ? "text-orange-600" : ""
              }`}
          >
              <div><EtImprintPlanOrgIcon1SVGComp className='h-8 w-8' /></div>
              <div>
                  <p className="mx-1 px-1 text-sm">Org</p>
                  <p className="mx-1 px-1 font-normal text-[9px] italic">how will I achieve it for my org?</p>
                  {/* <p className="mx-1 px-1 font-normal text-[9px] italic">what drives me?</p> */}
              </div>
          </div>
          <div
              onClick={() => setActiveTab("team")}
              className={`flex items-center text-gray-600 font-bold mx-2 cursor-pointer ${
              activeTab === "team" ? "text-orange-600" : ""
              }`}
          >
              <div><EtImprintPlanTeamIcon1SVGComp className='h-8 w-8' /></div>
              <div>
                  <p className="mx-1 px-1 text-sm">Team</p>
                  {/* <p className="mx-1 px-1 font-normal text-[9px] italic">what impact am I inspired to make for my clients?</p> */}
                  <p className="mx-1 px-1 font-normal text-[9px] italic">how will I achieve it for my team?</p>
              </div>
          </div>
          <div
              onClick={() => setActiveTab("partners")}
              className={`flex items-center text-gray-600 font-bold mx-2 cursor-pointer ${
              activeTab === "partners" ? "text-orange-600" : ""
              }`}
          >
              <div><EtImprintPlanPartnerIcon1SVGComp className='h-8 w-8' /></div>
              <div>
                  <p className="mx-1 px-1 text-sm">Partners</p>
                  {/* <p className="mx-1 px-1 font-normal text-[9px] italic">what impact am I inspired to make for my clients?</p> */}
                  <p className="mx-1 px-1 font-normal text-[9px] italic">how will I achieve it for my partners?</p>
              </div>
          </div>
      </div>
      <div className='border-t my-2 py-2'>
        {activeTab === 'self' && (
            <div className="m-1 p-1 rounded">
              {userPlanSelfs?.length === 0 && (
                  <div className="flex justify-between items-center">
                      <div>
                      <p className="m-1 p-1 text-xs">
                          You have not set a self plan yet! Change that by
                          clicking the Add button.
                      </p>
                      </div>
                      <div className="flex justify-start items-center">
                      <p className="text-xs font-bold mx-1 px-1">Add</p>
                      <button
                          type="button"
                          onClick={handleCreateUserPlanSelf}
                          className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                      >
                          <PlusIcon className="h-3 w-3" />
                      </button>
                      </div>
                      <EtModalCreateUserImprintPlanSelf
                          isOpen={isCreatePlanSelfModalOpen}
                          userId={userId}
                          userOrgId={userOrgId}
                          triggeringUrl={createPlanSelfTriggeringUrl}
                          onClose={handleCloseCreatePlanSelfModal}
                      />
                  </div>
              )}
              {userPlanSelfs?.length >0 && (
                <div>
                  <div className="flex justify-start items-center m-1 p-1">
                      <p className="text-xs font-bold mx-1 px-1">Add a plan for another Self Mission</p>
                      <button
                          type="button"
                          onClick={handleCreateUserPlanSelf}
                          className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                      >
                          <PlusIcon className="h-3 w-3" />
                      </button>
                  </div>
                  <RenderPlan 
                      userPlans={userPlanSelfs}
                      handleUpdate={handleUpdateUserPlanSelf}
                      selectedPlanId={selectedPlanId}
                      setSelectedPlanId={setSelectedPlanId}
                      handleCloseModal={handleCloseUpdatePlanSelfModal}
                      userId={userId}
                      userOrgId={userOrgId}
                      userInfo={userInfoDetails}
                      triggeringUrl={updatePlanSelfTriggeringUrl}
                      isOpen={isUpdatePlanSelfModalOpen}
                      ModalComponent={EtModalUpdateUserImprintPlanSelf}
                  />
                  <EtModalCreateUserImprintPlanSelf
                          isOpen={isCreatePlanSelfModalOpen}
                          userId={userId}
                          userOrgId={userOrgId}
                          triggeringUrl={createPlanSelfTriggeringUrl}
                          onClose={handleCloseCreatePlanSelfModal}
                      />
                </div>
              )}
                
            </div>
        )}
        
        {activeTab === 'clients' && (
            <div className="m-1 p-1 rounded">
              {userPlanClients?.length === 0 && (
                  <div className="flex justify-between items-center">
                      <div>
                      <p className="m-1 p-1 text-xs">
                          You have not set a client plan yet! Change that by
                          clicking the Add button.
                      </p>
                      </div>
                      <div className="flex justify-start items-center">
                      <p className="text-xs font-bold mx-1 px-1">Add</p>
                      <button
                          type="button"
                          onClick={handleCreateUserPlanClients}
                          className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                      >
                          <PlusIcon className="h-3 w-3" />
                      </button>
                      </div>
                      <EtModalCreateUserImprintPlanClient
                          isOpen={isCreatePlanClientsModalOpen}
                          userId={userId}
                          userOrgId={userOrgId}
                          triggeringUrl={createPlanClientsTriggeringUrl}
                          onClose={handleCloseCreatePlanClientsModal}
                      />
                  </div>
              )}
              {userPlanClients?.length >0 && (
                <div>
                  <div className="flex justify-start items-center m-1 p-1">
                      <p className="text-xs font-bold mx-1 px-1">Add a plan for another Client Mission</p>
                      <button
                          type="button"
                          onClick={handleCreateUserPlanClients}
                          className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                      >
                          <PlusIcon className="h-3 w-3" />
                      </button>
                  </div>
                  <RenderPlan 
                      userPlans={userPlanClients}
                      handleUpdate={handleUpdateUserPlanClients}
                      selectedPlanId={selectedPlanId}
                      setSelectedPlanId={setSelectedPlanId}
                      handleCloseModal={handleCloseUpdatePlanClientsModal}
                      userId={userId}
                      userOrgId={userOrgId}
                      userInfo={userInfoDetails}
                      triggeringUrl={updatePlanClientsTriggeringUrl}
                      isOpen={isUpdatePlanClientsModalOpen}
                      ModalComponent={EtModalUpdateUserImprintPlanClient}
                  />
                  <EtModalCreateUserImprintPlanClient
                          isOpen={isCreatePlanClientsModalOpen}
                          userId={userId}
                          userOrgId={userOrgId}
                          triggeringUrl={createPlanClientsTriggeringUrl}
                          onClose={handleCloseCreatePlanClientsModal}
                      />
                </div>
              )} 
            </div>
        )}

        {activeTab === 'org' && (
            <div className="m-1 p-1 rounded">
              {userPlanOrgs?.length === 0 && (
                  <div className="flex justify-between items-center">
                      <div>
                      <p className="m-1 p-1 text-xs">
                          You have not set a org plan yet! Change that by
                          clicking the Add button.
                      </p>
                      </div>
                      <div className="flex justify-start items-center">
                      <p className="text-xs font-bold mx-1 px-1">Add</p>
                      <button
                          type="button"
                          onClick={handleCreateUserPlanOrg}
                          className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                      >
                          <PlusIcon className="h-3 w-3" />
                      </button>
                      </div>
                      <EtModalCreateUserImprintPlanOrg
                          isOpen={isCreatePlanOrgModalOpen}
                          userId={userId}
                          userOrgId={userOrgId}
                          triggeringUrl={createPlanOrgTriggeringUrl}
                          onClose={handleCloseCreatePlanOrgModal}
                      />
                  </div>
              )}
              {userPlanOrgs?.length >0 && (
                <div>
                  <div className="flex justify-start items-center m-1 p-1">
                      <p className="text-xs font-bold mx-1 px-1">Add a plan for another Org Mission</p>
                      <button
                          type="button"
                          onClick={handleCreateUserPlanOrg}
                          className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                      >
                          <PlusIcon className="h-3 w-3" />
                      </button>
                  </div>
                  <RenderPlan 
                      userPlans={userPlanOrgs}
                      handleUpdate={handleUpdateUserPlanOrg}
                      selectedPlanId={selectedPlanId}
                      setSelectedPlanId={setSelectedPlanId}
                      handleCloseModal={handleCloseUpdatePlanOrgModal}
                      userId={userId}
                      userOrgId={userOrgId}
                      userInfo={userInfoDetails}
                      triggeringUrl={updatePlanOrgTriggeringUrl}
                      isOpen={isUpdatePlanOrgModalOpen}
                      ModalComponent={EtModalUpdateUserImprintPlanOrg}
                  />
                  <EtModalCreateUserImprintPlanOrg
                          isOpen={isCreatePlanOrgModalOpen}
                          userId={userId}
                          userOrgId={userOrgId}
                          triggeringUrl={createPlanOrgTriggeringUrl}
                          onClose={handleCloseCreatePlanOrgModal}
                      />
                </div>
              )}
            </div>
        )}

        {activeTab === 'team' && (
            <div className="m-1 p-1 rounded">
              {userPlanTeams?.length === 0 && (
                  <div className="flex justify-between items-center">
                      <div>
                      <p className="m-1 p-1 text-xs">
                          You have not set a team plan yet! Change that by
                          clicking the Add button.
                      </p>
                      </div>
                      <div className="flex justify-start items-center">
                      <p className="text-xs font-bold mx-1 px-1">Add</p>
                      <button
                          type="button"
                          onClick={handleCreateUserPlanTeam}
                          className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                      >
                          <PlusIcon className="h-3 w-3" />
                      </button>
                      </div>
                      <EtModalCreateUserImprintPlanTeam
                          isOpen={isCreatePlanTeamModalOpen}
                          userId={userId}
                          userOrgId={userOrgId}
                          triggeringUrl={createPlanTeamTriggeringUrl}
                          onClose={handleCloseCreatePlanTeamModal}
                      />
                  </div>
              )}
              {userPlanTeams?.length >0 && (
                <div>
                  <div className="flex justify-start items-center m-1 p-1">
                      <p className="text-xs font-bold mx-1 px-1">Add a plan for another Team Mission</p>
                      <button
                          type="button"
                          onClick={handleCreateUserPlanTeam}
                          className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                      >
                          <PlusIcon className="h-3 w-3" />
                      </button>
                  </div>
                  <RenderPlan 
                      userPlans={userPlanTeams}
                      handleUpdate={handleUpdateUserPlanTeam}
                      selectedPlanId={selectedPlanId}
                      setSelectedPlanId={setSelectedPlanId}
                      handleCloseModal={handleCloseUpdatePlanTeamModal}
                      userId={userId}
                      userOrgId={userOrgId}
                      userInfo={userInfoDetails}
                      triggeringUrl={updatePlanTeamTriggeringUrl}
                      isOpen={isUpdatePlanTeamModalOpen}
                      ModalComponent={EtModalUpdateUserImprintPlanTeam}
                  />
                  <EtModalCreateUserImprintPlanTeam
                          isOpen={isCreatePlanTeamModalOpen}
                          userId={userId}
                          userOrgId={userOrgId}
                          triggeringUrl={createPlanTeamTriggeringUrl}
                          onClose={handleCloseCreatePlanTeamModal}
                      />
                </div>
              )}   
            </div>
        )}

        {activeTab === 'partners' && (
            <div className="m-1 p-1 rounded">
              {userPlanPartners?.length === 0 && (
                  <div className="flex justify-between items-center">
                      <div>
                      <p className="m-1 p-1 text-xs">
                          You have not set a partner plan yet! Change that by
                          clicking the Add button.
                      </p>
                      </div>
                      <div className="flex justify-start items-center">
                      <p className="text-xs font-bold mx-1 px-1">Add</p>
                      <button
                          type="button"
                          onClick={handleCreateUserPlanPartners}
                          className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                      >
                          <PlusIcon className="h-3 w-3" />
                      </button>
                      </div>
                      <EtModalCreateUserImprintPlanPartner
                          isOpen={isCreatePlanPartnersModalOpen}
                          userId={userId}
                          userOrgId={userOrgId}
                          triggeringUrl={createPlanPartnersTriggeringUrl}
                          onClose={handleCloseCreatePlanPartnersModal}
                      />
                  </div>
              )}
              {userPlanPartners?.length >0 && (
                <div>
                  <div className="flex justify-start items-center m-1 p-1">
                      <p className="text-xs font-bold mx-1 px-1">Add a plan for another Partner Mission</p>
                      <button
                          type="button"
                          onClick={handleCreateUserPlanPartners}
                          className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
                      >
                          <PlusIcon className="h-3 w-3" />
                      </button>
                  </div>
                  <RenderPlan 
                      userPlans={userPlanPartners}
                      handleUpdate={handleUpdateUserPlanPartners}
                      selectedPlanId={selectedPlanId}
                      setSelectedPlanId={setSelectedPlanId}
                      handleCloseModal={handleCloseUpdatePlanPartnersModal}
                      userId={userId}
                      userOrgId={userOrgId}
                      userInfo={userInfoDetails}
                      triggeringUrl={updatePlanPartnersTriggeringUrl}
                      isOpen={isUpdatePlanPartnersModalOpen}
                      ModalComponent={EtModalUpdateUserImprintPlanPartner}
                  />
                  <EtModalCreateUserImprintPlanPartner
                          isOpen={isCreatePlanPartnersModalOpen}
                          userId={userId}
                          userOrgId={userOrgId}
                          triggeringUrl={createPlanPartnersTriggeringUrl}
                          onClose={handleCloseCreatePlanPartnersModal}
                      />
                </div>
              )} 
            </div>
        )}
      </div>

    </div>
    )
}

const RenderPlan = ({
  userPlans =[], 
  handleUpdate, 
  selectedPlanId, 
  setSelectedPlanId, 
  handleCloseModal, 
  userId, 
  userInfo,
  userOrgId, 
  triggeringUrl, 
  isOpen,
  ModalComponent, 
}) => {
  // First Created - 16 Jan 2025
  // Author - Atul Pandey
  // Inherited from RenderVision and modified display for plans

  // 0 - confirm incoming data
  console.log('userPlans', userPlans)
  console.log('userInfo', userInfo)

  // 1 - Set state variables 
  
  const [ showPlanDetails, setShowPlanDetails] = useState({})
  const [ showPlanNoteSection, setShowPlanNoteSection] = useState(false)
  const [ showPlanNoteDetails, setShowPlanNoteDetails] = useState({})
  const [ showPlanActionSection, setShowPlanActionSection] = useState(false) // 16 Jan 2025
  const [ showPlanActionDetails, setShowPlanActionDetails] = useState({}) // 16 Jan 2025
  const [ showPlanEvolutionSection, setShowPlanEvolutionSection] = useState(false)
  const [ showPlanTrailDetails, setShowPlanTrailDetails] = useState({})

  // 2a - Handle Toggle for show plan note details
  const handleTogglePlanNoteDetails = (noteId) => {
      setShowPlanNoteDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
      }))
  }

  // 2b - Handle Toggle for show plan action details
  const handleTogglePlanActionDetails = (actionId) => {
      setShowPlanActionDetails((prev) => ({
      ...prev,
      [actionId]: !prev[actionId],
      }))
  }

  // 2c - Handle Toggle for show plan trail details -- 08 Jan 2025
  const handleTogglePlanTrailDetails = (noteId) => {
      setShowPlanTrailDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
      }))
  }

  // 2d - Handle Toggle for show plan details -- 11 Jan 2025
  const handleTogglePlanDetails = (planId) => {
      setShowPlanDetails((prev) => ({
      ...prev,
      [planId]: !prev[planId],
      }))
  }
  
  
  // 3 - create Markup
  const createMarkup = (htmlContent) => {
      return { __html: htmlContent }
  }

  return(
      <div>
         {userPlans?.length >0 && userPlans
      //    ?.slice()
      //    .sort(
      //        (a, b) =>
      //            new Date(b.planDate) -
      //            new Date(a.planDate)
      //    )
         ?.map((plan, planIndex) => {
                      const planName = plan?.planName || ""
                      const planDesc = plan?.planDesc || ""
                      const planDate = plan?.planDate || ""
                      const planUpdateDate = plan?.planUpdateDate || ""
                      const missionName = plan?.missionId?.missionName || ""
                      const missionDesc = plan?.missionId?.missionDesc || ""
                      const planActions = plan?.planActions || []
                      const trailId = plan?.trailId?._id || null
                      const sessionId = plan?.sessionId?._id || null
                      
                      const planNotes = plan?.planNotes || []
                      const planTrail = plan?.planTrail || []

                      // console.log('userId', userId)
                      // console.log('trailId', trailId)
                      // console.log('sessionId', sessionId)

                      
                      return(
                          <div key={plan._id+planIndex} className="">
                              
                              <div className="bg-gray-200 rounded m-1 p-1">
                                  <div className="grid grid-cols-9 justify-start items-center gap-2">
                                      <div className='col-span-6 flex justify-start items-center m-1 p-1'>
                                          <div
                                              onClick={() => handleTogglePlanDetails(plan._id)}
                                              className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-gray-100"
                                          >
                                              {showPlanDetails[plan._id] ? (
                                              <MinusIcon className="h-4 w-4 text-orange-600" />
                                              ) : (
                                              <PlusIcon className="h-4 w-4 text-orange-600" />
                                              )}
                                          </div>
                                          <div>
                                              <EtImprintMissionsIcon2SVGComp className='h-6 w-6 text-orange-600'/>
                                          </div>
                                          <div>
                                              <p className="font-bold text-gray-600 text-sm mx-1 px-1">Plan {planIndex+1}.</p>
                                          </div>
                                          <div>
                                            <p className='font-bold text-xs mx-1 px-1  '><span className="font-bold text-xs text-gray-400">Mission:</span> {missionName}</p>
                                            <p className='text-[10px] mx-1 px-1 italic text-gray-600' dangerouslySetInnerHTML={createMarkup(missionDesc)}></p>
                                          </div>        
                                      </div> 
                                      <div className="col-span-3 flex justify-end items-center">
                                          <ClockIcon className="h-5 w-5 text-lime-600" />
                                          <p className="text-[9px] mx-1">First Created:</p>
                                          <p className="text-gray-600 text-[9px] mx-2">{formatDateTime(planDate)}</p>
                                          <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                          <PencilSquareIcon
                                              className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                              onClick={() => handleUpdate(plan._id)}
                                          />
                                          </div>
                                      </div>
                                  </div>
                                  {showPlanDetails[plan._id] && (
                                      <div className="bg-white rounded m-1 p-1">
                                          
                                          {planActions?.length>0 && (
                                            <div className="bg-gray-100 rounded m-1 p-1">
                                              <div className="grid grid-cols-8 justify-start items-center m-1 p-1">
                                                      <div className="col-span-3 flex justify-start items-center">
                                                          <div
                                                              onClick={() => setShowPlanActionSection(prev => !prev)}
                                                              className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-gray-100"
                                                          >
                                                              {showPlanActionSection ? (
                                                              <MinusIcon className="h-4 w-4 text-orange-600" />
                                                              ) : (
                                                              <PlusIcon className="h-4 w-4 text-orange-600" />
                                                              )}
                                                          </div>

                                                          <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                              <EtImprintPlansIcon2SVGComp className="h-7 w-7 text-orange-600" />
                                                          </div>
                                                          <p className="mx-1 px-1 text-sm font-bold"> Plan Actions:</p>
                                                      </div>
                                                      <div className="col-span-1 flex justify-start items-center">
                                                          <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{planActions?.length}</p></div>
                                                      </div>
                                              </div>
                                              {showPlanActionSection && planActions?.map((action, actionIndex)=>{
                                                const actionId = action?.planActionId?._id || null
                                                console.log('actionId', actionId)
                                                return(
                                                  <div key={action._id+actionIndex}>
                                                    <EtUserActionListByIdComp1
                                                      userInfo={userInfo}
                                                      userActionId={actionId}
                                                      userId={userId}
                                                      trailId={trailId}
                                                      sessionId={sessionId}
                                                    />

                                                  </div>
                                                )
                                              })}

                                            </div>
                                          )}
                                          {planNotes?.length>0 && (
                                              <div className="bg-gray-100 rounded m-1 p-1">
                                                  <div className="grid grid-cols-8 justify-start items-center m-1 p-1">
                                                      <div className="col-span-3 flex justify-start items-center">
                                                          <div
                                                              onClick={() => setShowPlanNoteSection(prev => !prev)}
                                                              className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-gray-100"
                                                          >
                                                              {showPlanNoteSection ? (
                                                              <MinusIcon className="h-4 w-4 text-orange-600" />
                                                              ) : (
                                                              <PlusIcon className="h-4 w-4 text-orange-600" />
                                                              )}
                                                          </div>

                                                          <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                              <EtImprintPlanNotesIcon1SVGComp className="h-7 w-7 text-orange-600" />
                                                          </div>
                                                          <p className="mx-1 px-1 text-sm font-bold"> Plan Notes:</p>
                                                      </div>
                                                      <div className="col-span-1 flex justify-start items-center">
                                                          <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{planNotes?.length}</p></div>
                                                      </div>
                                                  </div>
                                                  
                                                  {showPlanNoteSection && planNotes
                                                  ?.slice()
                                                  .sort(
                                                      (a, b) =>
                                                          new Date(b.planNoteDate) -
                                                          new Date(a.planNoteDate)
                                                  )
                                                  .map((note, noteIndex)=>{
                                                      const noteText = note?.planNoteText || ""
                                                      const noteDate = note?.planNoteDate || ""
                                                      const noteUpdateDate = note?.planNoteUpdateDate || ""

                                                      return (
                                                          <div key={note._id+noteIndex} className="bg-gray-200 border rounded m-1 p-1">
                                                              <div className="grid grid-cols-8 justify-between items-center m-1 p-1 ">
                                                                  <div className="col-span-2 flex justify-start items-center m-1 p-1">
                                                                  <div
                                                                      onClick={() =>
                                                                          handleTogglePlanNoteDetails(note._id)
                                                                      }
                                                                      className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                                                  >
                                                                      {showPlanNoteDetails[note._id] ? (
                                                                      <MinusIcon className="h-4 w-4 text-orange-600" />
                                                                      ) : (
                                                                      <PlusIcon className="h-4 w-4 text-orange-600" />
                                                                      )}
                                                                  </div>
                                                                  <div>
                                                                      <p className="text-xl font-bold mx-1 px-1 text-gray-600">
                                                                      {noteIndex + 1}.
                                                                      </p>
                                                                  </div>
                                                                  <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                                                      <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
                                                                  </div>
                                                                  <div>
                                                                      <div className="flex flex-col justify-center items-center mx-1 px-1">
                                                                      <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                                                          {getTimeSince(noteUpdateDate)}
                                                                      </p>
                                                                      <p className="text-[9px] text-gray-600 italic">
                                                                          last updated
                                                                      </p>
                                                                      </div>
                                                                  </div>
                                                                  </div>
                                                                  <div className="col-span-4">
                                                                  <div
                                                                      dangerouslySetInnerHTML={createMarkup(noteText)}
                                                                      className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                                                  ></div>
                                                                  </div>
                                                                  <div className="col-span-2 flex justify-center items-center mx-2">
                                                                  <ClockIcon className="h-5 w-5 text-lime-600" />
                                                                  <p className="text-[9px] mx-1">First Created:</p>
                                                                  <p className="text-gray-400 text-[9px] mx-2">
                                                                      {formatDateTime(noteDate)}
                                                                  </p>
                                                                  </div>
                                                              </div>
                                                              {showPlanNoteDetails[note._id] && (
                                                              <div
                                                                  dangerouslySetInnerHTML={createMarkup(noteText)}
                                                                  className="text-xs text-gray-600 m-1 p-1 rounded leading-4 bg-white"
                                                              ></div>
                                                              )}
                                                          </div>
                                                      )
                                                  })}
                                              </div>
                                          )}

                                      </div>
                                  )}

                              
                              </div>
                              
                              
                              
                              
                          </div>
                      )
          })}
          {ModalComponent && (
              <ModalComponent
                  isOpen={isOpen}
                  id={selectedPlanId}
                  userId={userId}
                  userOrgId={userOrgId}
                  triggeringUrl={triggeringUrl}
                  onClose={handleCloseModal}
              />
          )}
      </div>
  )
}

export default EtImprintPlan