import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import useTitle from "@/hooks/useTitle"

import EtEtTrinityIcon6SVGComp from "@/assets/img/icons/svgComp/EtEtTrinityIcon6SVGComp";
// 08 Mar 2025
import EtPerformIconSVGComp from "@/assets/img/icons/svgComp/EtPerformIcon2SVGComp";
import EtPrepareIconSVGComp from "@/assets/img/icons/svgComp/EtPrepareIcon1SVGComp";
import EtPlanIconSVGComp from "@/assets/img/icons/svgComp/EtPlanIcon1SVGComp";

// 14 Mar 2025
import { useFetchGoogleCalendarEventsQuery, useLazyFetchGoogleCalendarEventsQuery } from "@/features/calendarEvents/calendarEventsApiSlice";

// 19 Mar 2025 - Switching to getting data from the persisted UserCalendarEvent DB
import { useGetUserCalendarEventsQuery } from "@/features/calendarEvents/calendarEventsApiSlice";

// 21 Mar 2025 - for UserMeetingPrep
import { useGetUserMeetingPrepsQuery } from "@/features/userMeetingPreps/userMeetingPrepsApiSlice";

// 23 Mar 2025 - for displaying conversations
import { useGetUserConversationsQuery } from '@/features/userConversations/userConversationsApiSlice'

// 21 Mar 2025 - Modal
import EtModalUpdateUserMeetingPrep from '@/components/common/modal/EtModalUpdateUserMeetingPrep'
// 23 Mar 2025
import EtModalTrinityUserMeetingConversation from '@/components/common/modal/EtModalTrinityUserMeetingConversation'

// 20 Mar 2025 - User Pic
import { useGetUserPicQuery } from "@/features/users/usersApiSlice"

// 14 Mar 2025
import moment from "moment";

// 19 Mar 2025
import { 
  ClockIcon, 
  CalendarDaysIcon, 
  UserGroupIcon,
  ArrowPathIcon,
  DocumentTextIcon,
  ShareIcon 
} from "@heroicons/react/24/solid"

import {
  PlusIcon,
  MinusIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline"

// 20 Mar 2025
import { userProfileIcon2 } from "@/assets/img/imgAssets"

// 21 Mar 2025
import PulseLoader from "react-spinners/PulseLoader"
import { getTimeSince } from "@/utils/EtGetTimeSince"

// 21 Mar 2025
import EtMeetingIcon1SVGComp from '@/assets/img/icons/svgComp/EtMeetingPrepIcon1SVGComp'
import EtMeetingIcon2SVGComp from '@/assets/img/icons/svgComp/EtMeetingIcon1SVGComp'

// 22 Mar 2025
import EtWabConversationIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabConversationIcon1SVGComp" 
import EtWabActionIcon1SVGComp from "@/assets/img/icons/svgComp/EtWabActionIcon1SVGComp" 


const EtTrinityWebLandingAction = ({username}) => {

  // Author - Atul Pandey
  // First Created - 07 Mar 2025
  // Purpose to act as the home page for Trinity Landing
  // Updated - 14 Mar 2025 - added username prop to the component and to pass it to other components called from here - i.e. Perform
  // Updated - 19 Mar 2025 - refinements for getting data from the persisted UserCalendarEvent DB
  // Updated - 21 Mar 2025 - refinements for getting data from the UserMeetingPrep DB
  // Updated - 24 Mar 2025 - refinements for fetch only upon user click and not on page load
  // Updated - 30 Mar 2025 - refinements for fetch only from the persisted DB and not triggering sync iCal
  // Updated - 31 Mar 2025 - refinements for data for Prepare and Plan if today is the last day of the month
  
  
  // 1a - Set Page Title
    useTitle('Trinity Home - Actions')
  
  // 1b1 - State variables to show / hide sections
  const [ activeTab, setActiveTab ] = useState("perform") // 08 Mar 2025
  const [syncMessage, setSyncMessage] = useState(null); // 19 Mar 2025 - for showing sync message 
  const [fadeOut, setFadeOut] = useState(false) // 21 Mar 2025 - for fading out syncMsg

  // 2a1 - Fetch Calendar Data - 19 Mar 2025  Updated - 30 Mar 2025
  const { data:userCalendarEventsData, isLoading: isSyncing, error, refetch } = useGetUserCalendarEventsQuery(); 
   // console.log('calendarData', userCalendarEventsData)
  // console.log('calendarData excluding all day', userCalendarEventsData?.filter(event => !event.isAllDay))

  // 2a2 - Fetch User Meeting Prep Data - 21 Mar 2025
  const { data: userMeetingPrepsData, isLoading: isLoadingMeetingPreps } = useGetUserMeetingPrepsQuery();
  
  const extractEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id]);
  };

  const userMeetingPrepsAll = extractEntitiesFromAdapter(userMeetingPrepsData);

  const userMeetingPreps = userMeetingPrepsAll?.filter(item => !item?.calendarMeetingId?.isAllDay)

  // console.log('meeting prep data', userMeetingPrepsData)
  // console.log('meeting preps', userMeetingPreps)

  // 2b1 - Today's calendar events
  const meetingsToday = userCalendarEventsData?.filter(event => 
    moment(event.startTime).isSame(moment(), 'day') && !event.isAllDay 
  ) || [];

  // 2b2 - Today's meeting preps
  const meetingPrepsToday = userMeetingPreps?.filter(event => 
    moment(event?.calendarMeetingId?.startTime).isSame(moment(), 'day')
  ) || [];

  
  // 2c - Determine if today is the last day of the month - 31 Mar 2025
  const isEndOfMonth = moment().isSame(moment().endOf('month'), 'day')
  
  
  // 2c1 - This month's remaining events (From tomorrow until end of the month) - 31 Mar 2025
  const prepareStart = isEndOfMonth ? moment().startOf('month').add(1, 'month') : moment().endOf('day')
  const prepareEnd = isEndOfMonth ? moment().endOf('month').add(1, 'month') : moment().endOf('month')

  const meetingsThisMonth = userCalendarEventsData?.filter(event => 
    moment(event.startTime).isAfter(prepareStart) && 
    moment(event.startTime).isBefore(prepareEnd) &&
    !event.isAllDay
  ) || []

  // const meetingsThisMonth = userCalendarEventsData?.filter(event => 
  //   moment(event.startTime).isAfter(moment().endOf('day')) && 
  //   moment(event.startTime).isBefore(moment().endOf('month')) &&
  //   !event.isAllDay 
  // ) || [];

  // 2c2 - This month's remaining meeting preps (From tomorrow until end of the month) - updated 31 Mar 2025
  const meetingPrepsThisMonth = userMeetingPreps?.filter(event => 
    moment(event?.calendarMeetingId?.startTime).isAfter(prepareStart) && 
    moment(event?.calendarMeetingId?.startTime).isBefore(prepareEnd)
  ) || []
  // const meetingPrepsThisMonth = userMeetingPreps?.filter(event => 
  //   moment(event?.calendarMeetingId?.startTime).isAfter(moment().endOf('day')) && 
  //   moment(event?.calendarMeetingId?.startTime).isBefore(moment().endOf('month')) 
  // ) || [];

  // 2d1 - Plan: Events from the month after next if today is end of month - 31 Mar 2025
  const planStart = isEndOfMonth 
  ? moment().startOf('month').add(2, 'month') 
  : moment().startOf('month').add(1, 'month')

  const futureMeetings = userCalendarEventsData?.filter(event => 
  moment(event.startTime).isSameOrAfter(planStart) && !event.isAllDay
  ) || []
  
  // 2d1 - Future events beyond this month (Starting next month)
  // const futureMeetings = userCalendarEventsData?.filter(event => 
  //   moment(event.startTime).isSameOrAfter(moment().startOf('month').add(1, 'month')) &&
  //   !event.isAllDay 
  // ) || [];

  // 2d2 - Future meeting preps beyond this month (Starting next month) - Updated 31 Mar 2025
  const futureMeetingPreps = userMeetingPreps?.filter(event => 
    moment(event?.calendarMeetingId?.startTime).isSameOrAfter(planStart)
  ) || []
  // const futureMeetingPreps = userMeetingPreps?.filter(event => 
  //   moment(event?.calendarMeetingId?.startTime).isSameOrAfter(moment().startOf('month').add(1, 'month'))
  // ) || [];

  useEffect(() => {
    if (syncMessage) {
      setFadeOut(false) // Reset fade on new message
      const timer1 = setTimeout(() => setFadeOut(true), 1500)  // Start fade after 1.5s
      const timer2 = setTimeout(() => setSyncMessage(null), 2000) // Remove message after 2s
      return () => {
        clearTimeout(timer1)
        clearTimeout(timer2)
      }
    }
  }, [syncMessage])

  return (
    <div>
     
      {isSyncing ? (
        <div className='bg-orange-600 p-2 flex justify-start items-center w-48 rounded'>
          <p className='text-white mx-1 px-1'>Syncing</p>
          <PulseLoader color={"#fff"} />
        </div>
      ):(    
        <div 
            className="relative group mx-2 px-2" 
            // 30 Mar 2025
            onClick={async () => {
              setSyncMessage(null);
              const result = await refetch(); // ✅ Just re-fetch DB-persisted data
          
              if (!result.error) {
                setSyncMessage({ type: "success", text: "Calendar refreshed from DB." });
              } else {
                setSyncMessage({ type: "error", text: "Failed to refresh calendar from DB." });
              }
            }}
          >
              <div className="h-7 w-7 flex justify-center items-center rounded-full bg-gradient-to-r from-orange-600 to-orange-600 via-orange-800 hover:bg-gradient-to-r hover:from-orange-400 hover:via-orange-600 hover:to-black cursor-pointer shadow-md">
                <ArrowPathIcon className="h-5 w-5 text-white" />
              </div>
              {/* Tooltip */}
              <div className="absolute -bottom-10 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-xs rounded py-1 px-2 z-10">
                  Refresh
              </div>
          </div>  
      )}

      {syncMessage && (
        <p
          className={`mt-2 transition-opacity duration-500 ease-in-out ${
            fadeOut ? "opacity-0" : "opacity-100"
          } ${syncMessage.type === "success" ? "text-lime-600" : "text-red-600"}`}
        >
          {syncMessage.text}
        </p>
      )}
     
      {isSyncing && <p>Loading...</p>}
      {error && <p className="text-red-500">Failed to fetch events</p>}

        <div className='grid grid-cols-3 justify-between items-start py-3 space-x-1'>
          {/* Panel 1 - Perform! */}
          <div 
            onClick={() => setActiveTab("perform")}
            className={`col-span-1 rounded`}
          >
            <div className={`text-gray-500 flex items-center bg-black rounded font-bold cursor-pointer py-5  ${
              activeTab === "perform" ? "text-orange-600" : "text-white"
            }`}>
              <div className='mx-1 px-1'><EtPerformIconSVGComp className='h-8 w-8'/></div>
              <div>
                <p className='mx-1 px-1'>Perform!</p>
                <p className='mx-1 px-1 text-xs italic font-normal'>do it like a rockstar!</p>
              </div>
            </div>
            {(
              <Perform activeTab={activeTab} meetings={meetingsToday} meetingPreps={meetingPrepsToday}/>
            )}
          </div>

          {/* Panel 2 - Prepare! */}
          <div 
            onClick={() => setActiveTab("prepare")}
            className={`col-span-1 rounded`}
          >
            <div className={`text-gray-500 flex items-center bg-black rounded font-bold cursor-pointer py-5 ${
              activeTab === "prepare" ? "text-orange-600" : "text-white"
            }`}>
              <div className='mx-1 px-1'><EtPrepareIconSVGComp className='h-8 w-8'/></div>
              <div>
                <p className='mx-1 px-1'>Prepare!</p>
                <p className='mx-1 px-1 text-xs italic font-normal'>steal your upcoming shows!</p>
              </div>
            </div>
            {(
              // <Prepare activeTab={activeTab} meetings={meetingsThisMonth} meetingPreps={meetingPrepsThisMonth}/>
              <Prepare activeTab={activeTab} meetings={meetingsThisMonth} meetingPreps={meetingPrepsThisMonth} isEndOfMonth={isEndOfMonth}/> // 31 Mar 2025
            )}
          </div>

          {/* Panel 3 - Plan! */}
          <div 
            onClick={() => setActiveTab("plan")}
            className={`col-span-1 rounded `}
          >
            <div className={`text-gray-500 flex items-center bg-black rounded font-bold cursor-pointer py-5 ${
              activeTab === "plan" ? "text-orange-600" : "text-white"
            }`}>
              <div className='mx-1 px-1'><EtPlanIconSVGComp className='h-8 w-8'/></div>
              <div>
                <p className='mx-1 px-1'>Plan!</p>
                <p className='mx-1 px-1 text-xs italic font-normal'>stay ahead like a pro!</p>
              </div>
            </div>
            {(
              <Plan activeTab={activeTab} meetings={futureMeetings} meetingPreps={futureMeetingPreps}/>
            )}
          </div>
        </div>
    </div>
  )
}

const Perform = ({activeTab, meetings = [] , meetingPreps=[]}) => {
  // Author - Atul Pandey
  // First Created - 12 Mar 2025
  // Purpose - To capture all the tasks related to a user's daily routine
  // Updated - 14 Mar 2025 - added meetings prop
  // Updated - 20 Mar 2025 - added show / hide - attendees

  // 0 - validate incoming data
  // console.log('meetings today', meetings)
  // console.log('meeting preps today', meetingPreps)

  // 1 - State variables to show / hide sections
  const [ showAttendees, setShowAttendees ] = useState({}) // 20 Mar 2025
  const [ showDetails, setShowDetails ] = useState({}) // 20 Mar 2025

  // 2a - Toggle Show function for event details
  const handleToggleAttendees = (eventId) => {
    setShowAttendees(prev => ({ ...prev, [eventId]: !prev[eventId] }));
  }

  // 2b - Toggle Show function for event details
  const handleToggleDetails = (eventId) => {
    setShowDetails(prev => ({ ...prev, [eventId]: !prev[eventId] }));
  }

  return(
    <div className={`rounded my-1 pb-1 border bg-gray-300 h-[350px] overflow-y-auto font-poppins ${activeTab === 'perform' ? 'border bg-orange-600': ''}`}>
      {/* Fetch and show all the meetings from user's calendar */}
      <div className="h-full">
        <div className='p-4'>
          <div className='flex justify-between items-center'>
            <p className='font-bold'>Your Meetings Today:</p>
            <div className={`flex justify-center items-center rounded-full w-8 h-8 border ${activeTab === 'perform' ? 'border border-white': 'border-black'}`}>
              <p className={`font-bold ${activeTab === 'perform' ? 'text-white': ''}`}>{meetings?.length}</p>
            </div>
            
          </div>
          
        </div>
        {meetings?.length > 0 ? (
            meetings
              ?.slice()
              ?.sort((a, b) => new Date(a.startTime) - new Date(b.startTime))
              ?.map((event, index) => {
                const matchingPrep = meetingPreps?.find(prep => prep?.calendarMeetingId?._id === event?._id);
                // console.log('matchingPrep Today',matchingPrep)
                return(
                <MeetingCard
                  key={`${event.eventId}-${index}`}
                  event={event}
                  index={index}
                  activeTab={activeTab}
                  matchingPrep={matchingPrep}
                />
              )})
          ) : (
            <div className='bg-white rounded m-1 p-1'>
              <p className='p-8 text-sm'>No events found</p>
            </div>
          )}
      </div>
    </div>
  )

}

const Prepare = ({activeTab, meetings = [], meetingPreps=[], isEndOfMonth=false}) => {

    // Author - Atul Pandey
    // First Created - 12 Mar 2025
    // Purpose - To capture all the tasks related to a user's upcoming meetings
    // Updated - 31 Mar 2025 - added isEndOfMonth prop

    // 0 - validate incoming data
    // console.log('meetings next week', meetings)
    // console.log('meeting preps next week', meetingPreps)


    // 1 - State variables to show / hide sections
    const [ showAttendees, setShowAttendees ] = useState({}) // 20 Mar 2025

    // 2 - Toggle Show function for event details
    const handleToggleAttendees = (eventId) => {
      setShowAttendees(prev => ({ ...prev, [eventId]: !prev[eventId] }));
  }

    return(
      <div className={`rounded my-1 pb-1 border bg-gray-300 h-[350px] overflow-y-auto font-poppins ${activeTab === 'prepare' ? 'border bg-orange-600': ''}`}>
        {/* Fetch and show all the meetings from user's calendar */}
        <div className="h-full">
          <div className='p-4'>
            <div className='flex justify-between items-center'>
              {/* <p className='font-bold'>Your Meetings This Month:</p> */}
              <p className='font-bold'>{isEndOfMonth ? 'Your Meetings for Upcoming Month:' : 'Your Meetings This Month:'}</p>
              <div className={`flex justify-center items-center rounded-full w-8 h-8 border ${activeTab === 'prepare' ? 'border border-white': 'border-black'}`}>
                <p className={`font-bold ${activeTab === 'prepare' ? 'text-white': ''}`}>{meetings?.length}</p>
              </div>
              
            </div>
            
          </div>
            {meetings?.length > 0 ? (
              meetings
                ?.slice()
                ?.sort((a, b) => new Date(a.startTime) - new Date(b.startTime))
                ?.map((event, index) => {
                  const matchingPrep = meetingPreps?.find(prep => prep?.calendarMeetingId?._id === event?._id);
                  return(
                  <MeetingCard
                    key={`${event.eventId}-${index}`}
                    event={event}
                    index={index}
                    activeTab={activeTab}
                    matchingPrep={matchingPrep}
                  />
                )})
            ) : (
              <div className='bg-white rounded m-1 p-1'>
                <p className='p-8 text-sm'>No events found</p>
              </div>
            )}
        </div>
      </div>
    )
}

const Plan = ({activeTab, meetings = [], meetingPreps=[]}) => {

    // Author - Atul Pandey
    // First Created - 12 Mar 2025
    // Purpose - To capture all the tasks related to a user's daily routine

    // 1 - validate incoming data
    // console.log('future meetings', meetings)
    // console.log('future meeting preps', meetingPreps)


    // 1 - State variables to show / hide sections
    const [ showAttendees, setShowAttendees ] = useState({}) // 20 Mar 2025

    // 2 - Toggle Show function for event details
    const handleToggleAttendees = (eventId) => {
      setShowAttendees(prev => ({ ...prev, [eventId]: !prev[eventId] }));
    }

    return(
      <div className={`rounded my-1 pb-1 border bg-gray-300 h-[350px] overflow-y-auto font-poppins ${activeTab === 'plan' ? 'border bg-orange-600': ''}`}>
        {/* Fetch and show all the meetings from user's calendar */}
        <div className="h-full">
          <div className='p-4'>
            <div className='flex justify-between items-center'>
              <p className='font-bold'>Your Future Meetings:</p>
              <div className={`flex justify-center items-center rounded-full w-8 h-8 border ${activeTab === 'plan' ? 'border border-white': 'border-black'}`}>
                <p className={`font-bold ${activeTab === 'plan' ? 'text-white': ''}`}>{meetings?.length}</p>
              </div>     
            </div>
            
          </div>
            {meetings?.length > 0 ? (
              meetings
                ?.slice()
                ?.sort((a, b) => new Date(a.startTime) - new Date(b.startTime))
                ?.map((event, index) => {
                  const matchingPrep = meetingPreps?.find(prep => prep?.calendarMeetingId?._id === event?._id);
                  return(
                  <MeetingCard
                    key={`${event.eventId}-${index}`}
                    event={event}
                    index={index}
                    activeTab={activeTab}
                    matchingPrep={matchingPrep}
                  />
                )})
            ) : (
              <div className='bg-white rounded m-1 p-1'>
                <p className='p-8 text-sm'>No events found</p>
              </div>
            )}
        </div>
      </div>
    )
}

const MeetingCard = ({ event, index, activeTab, matchingPrep=[] }) => {
  // Author - Atul Pandey
  // First Created - 21 Mar 2025
  // Purpose - To display each event details
  // Updated - 22 Mar 2025 - To edit or delete a specific note
  // Updated - 22 Mar 2025 - Post Meeting actions section
  // Updated - 23 Mar 2025 - Post Meeting Conversations capture and display
  // Updated - 25 Mar 2025 - Post Meeting Conversations - show conversation details
  
  // 0 - Validate incoming data
  // console.log('matchingPrep in MeetingCard', matchingPrep)
  // console.log('event id in MeetingCard', event?._id)
  // console.log('matchingPrep id in MeetingCare', matchingPrep?._id)

  // 1 - State variables
  const [showAttendees, setShowAttendees] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [showNoteDetails, setShowNoteDetails] = useState({})
  const [showModal, setShowModal] = useState(false);
  const [selectedNoteId, setSelectedNoteId] = useState(null);  // ✅ 22 Mar 2025 - new
  const [isDeletingNote, setIsDeletingNote] = useState(false); // ✅ 22 Mar 2025 - new
  const [activePostTab, setActivePostTab] = useState('conversations') // ✅ 22 Mar 2025 - new
  const [showConversationModal, setShowConversationModal] = useState(false); // ✅ 23 Mar 2025 - new
  
  const [showConversationDetails, setShowConversationDetails] = useState({}) // ✅ 25 Mar 2025 - new
  const [selectedConversationNoteId, setSelectedConversationNoteId] = useState(null);  // ✅ 25 Mar 2025 - new
  const [isDeletingConversationNote, setIsDeletingConversationNote] = useState(false); // ✅ 25 Mar 2025 - new

  // 2 - Get Data 
  const { 
    data: userConversationsData,
    isLoading: isLoadingUserConversations, // ✅ 25 Mar 2025
    refetch: refetchUserConversations // ✅ 25 Mar 2025 Extract refetch
  
  } = useGetUserConversationsQuery() //  ✅ 23 Mar 2025 - Fetch all user conversations
  // console.log('User Conversations Data', userConversationsData)
  
  // ✅ Filter conversations that belong to this meeting (by meetingPrepId or calendarMeetingId)
  const extractMeetingConversations = () => {
    if (!userConversationsData?.ids?.length || !userConversationsData?.entities) return [];
  
    const allNotes = [];
  
    userConversationsData?.ids.forEach((id) => {
      const conv = userConversationsData.entities[id];
  
      // 1️⃣ Process self notes
      (conv?.userConversationAllNotes || [])?.forEach((note) => {
        if (
          note.calendarMeetingId === event._id ||
          note.userMeetingPrepId === matchingPrep?._id
        ) {
          allNotes.push({
            ...note,
            noteType: 'self',
          });
        }
      });
  
      // 2️⃣ Process contact notes
      (conv?.userConversationContactNotes || [])?.forEach((note) => {
        if (
          note.calendarMeetingId === event._id ||
          note.userMeetingPrepId === matchingPrep?._id
        ) {
          allNotes.push({
            ...note,
            noteType: 'contact',
            contactName: note.userContactId?.contactName || '',
            contactType: note.userContactId?.contactTypeId?.contactTypeName || '',
          });
        }
      });
    });
  
    // 3️⃣ Sort by most recently updated
    return allNotes.sort(
      (a, b) =>
        new Date(b.userConversationNoteUpdateDate) - new Date(a.userConversationNoteUpdateDate)
    );
  };
  
  const meetingConversations = extractMeetingConversations();
  // console.log('meeting conversations', meetingConversations)
  
  // 3 - Functions
  const toggleDetails = () => setShowDetails(prev => !prev)
  const toggleAttendees = () => setShowAttendees(prev => !prev)

  // 23 Mar 2025
  const handleToggleNoteDetails = (noteId) => {
    setShowNoteDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
  }
  
  // 25 Mar 2025
  const handleToggleConversationDetails = (noteId) => {
    setShowConversationDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
  }
  

  // 4 - create Markup
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
  }

  return (
    <div className='bg-white rounded m-1 p-1 font-poppins'>
      {/* Header */}
      <div className='flex justify-start items-center'>
        <div
          className="flex justify-center items-center cursor-pointer rounded-full h-5 w-5 hover:bg-white"
          onClick={toggleDetails}
        >
          {showDetails ? (
            <MinusIcon className="h-4 w-4 text-orange-600" />
          ) : (
            <PlusIcon className="h-4 w-4 text-orange-600" />
          )}
        </div>
        <p className='text-lg m-1 p-1 font-bold text-gray-600'>{index + 1}.</p>
        <p className='text-xs m-1 p-1 font-bold text-gray-600'>{event.title}</p>
      </div>

      {/* Time */}
      <div className='flex justify-between items-center m-1 p-1 border-t'>
        <div className='flex justify-start items-center my-1 py-1'>
          <CalendarDaysIcon className='w-4 h-4 text-orange-600' />
          <p className='text-xs mx-1 px-1 text-orange-600'>
            {moment(event.startTime).isSame(moment(), 'day') ? "Today" : moment(event.startTime).format("DD MMM YYYY (ddd)")}
          </p>
        </div>
        <div className='flex justify-end items-center my-1 py-1'>
          <div className='flex items-center'>
            <ClockIcon className='text-lime-600 h-4 w-4' />
            <p className='text-xs mx-1 px-1'>Start: {moment(event.startTime).format("hh:mm A")}</p>
          </div>
          <div className='flex items-center'>
            <ClockIcon className='text-orange-600 h-4 w-4' />
            <p className='text-xs mx-1 px-1'>End: {moment(event.endTime).format("hh:mm A")}</p>
          </div>
        </div>
      </div>

      {/* Expandable Details */}
      {showDetails && (
          <div className='border-t m-1 p-1'>
            {/* Attendees */}
            <div className='p-2 flex justify-start items-center'>
              <div
                className="flex justify-center items-center cursor-pointer rounded-full h-5 w-5 hover:bg-white"
                onClick={toggleAttendees}
              >
                {showAttendees ? (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                )}
              </div>
              <UserGroupIcon className='h-5 w-5 text-orange-600' />
              <p className='text-sm font-bold mx-1 px-1'>Attendees:</p>
              <p className='text-xs text-gray-400 italic px-1'>
                {event?.attendees?.length} attendee{event?.attendees?.length !== 1 ? "s" : ""}
              </p>
            </div>

            {showAttendees && event?.attendees?.length > 0 && (
              event.attendees.map((attendee, i) => (
                <div key={`${attendee.email}-${i}`} className='bg-gray-300 rounded m-1 p-1'>
                  <div className='flex justify-start items-center'>
                    {/* <User
                      username={attendee?.username}
                      defaultUserPic={userProfileIcon2}
                    /> */}
                    <p className='text-[10px] mx-1 px-1 font-bold'>{i + 1}.</p>
                    <p className='text-[10px] mx-1 px-1'>{attendee?.email}</p>
                  </div>
                </div>
              ))
            )}

            {/* Show Prep */}
            {/* Meeting Prep Section */}
            <div className='border-t my-1 py-1'>
              <div className='flex justify-between items-center m-1 p-1'>
                <div className='flex justify-start items-center'>
                  <EtMeetingIcon1SVGComp className='h-6 w-6 text-orange-600' />
                  <p className='text-sm font-bold text-gray-600 mx-1 px-1'>Meeting Prep Trail</p>
                </div>
                <div className='flex justify-end items-center'>
                  <p className='text-xs font-bold mx-1 px-1'>Add</p>
                  <button
                    className="flex items-center bg-lime-500 text-white p-1 rounded text-xs hover:bg-lime-600"
                    onClick={() => setShowModal(true)}
                  >
                    <PlusIcon className="h-4 w-4" />
                  </button>
                </div>
                  
              </div>
              
              {/* Prep Notes Trail Section */}
              {matchingPrep?.meetingPrepNotes?.length > 0 ? (
                matchingPrep?.meetingPrepNotes
                ?.slice()
                  .sort(
                    (a, b) =>
                        new Date(b.meetingPrepNoteUpdateDate) - new Date(a.meetingPrepNoteUpdateDate)
                ) // Sort in descending order
                ?.map((note, i) => {

                    const noteText = note?.meetingPrepNoteText || ""
                    const noteDate = note?.meetingPrepNoteDate || ""
                    const noteUpdateDate = note?.meetingPrepNoteUpdateDate || ""
                    // console.log('noteText', noteText)
                  
                    return (
                  <div key={note._id+i} className="">

                    <div className='bg-gray-200 rounded grid grid-cols-11 justify-start items-center m-1 p-1'>
                      <div className="col-span-4 flex justify-start items-center">
                        <div
                          onClick={() => handleToggleNoteDetails(note._id)}
                          className="rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
                        >
                          {showNoteDetails[note._id] ? (
                            <MinusIcon className="h-4 w-4 text-orange-600" />
                          ) : (
                            <PlusIcon className="h-4 w-4 text-orange-600" />
                          )}
                        </div>
                        <div>
                          <p className="text-sm font-bold mx-1 px-1 text-gray-600">{ i +1}.</p>
                        </div>
                        <div className="">
                          <p className="text-[8px] mx-1 rounded-full px-1 my-[1px] py-[1px] bg-lime-200 w-full">
                            {getTimeSince(noteUpdateDate)}
                          </p>
                          <p className="text-[7px] mx-1 italic px-1 py-[1px] text-gray-400">last updated</p>
                        </div>
                      </div>

                      <div className="col-span-6 flex justify-start items-center">
                        <div
                          dangerouslySetInnerHTML={createMarkup(noteText)}
                          className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                        ></div>
                      </div>

                      <div className="col-span-1 flex justify-start items-center">
                        <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                          <PencilSquareIcon
                            className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                            onClick={() => {
                              setSelectedNoteId(note._id); // ✅ Set noteId for editing
                              setShowModal(true);
                            }}
                          />
                        </div>
                        <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                          <TrashIcon
                            className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                            onClick={() => {
                              setSelectedNoteId(note._id); // ✅ Set noteId for editing
                              setIsDeletingNote(true);     // ✅ Set flag for deleting
                              setShowModal(true);
                            }}
                          />
                        </div>
                      </div>
                      
                    </div>

                    {showNoteDetails[note._id] && (
                      <div className='bg-orange-50 rounded m-1 p-1'>
                        <p dangerouslySetInnerHTML={createMarkup(noteText)} className='text-xs leading-4 m-1 p-1'></p>
                        <div className='flex justify-between items-center m-1 p-1 border-t'>
                          <div className='flex justify-start items-center my-1'>
                            <ClockIcon className="h-5 w-5 text-lime-600" />
                            <p className="text-[10px] text-orange-600 font-bold mx-1 px-1">
                                Created:
                            </p>
                            <p className="text-[10px] text-gray-500">
                               {moment(noteDate).format('DD MMM, hh:mm A')}
                            </p>
                          </div>
                          <div className='flex justify-start items-center my-1'>
                            <ClockIcon className="h-5 w-5 text-lime-600" />
                            <p className="text-[10px] text-gray-500 font-bold mx-1 px-1">
                                Updated:
                            </p>
                            <p className="text-[10px] text-gray-500">
                                {moment(noteUpdateDate).format('DD MMM, hh:mm A')}
                            </p>
                          </div>
                          
                        </div>  
                      </div>
                    )}         
                  </div>
                )})
              ) : (
                <p className="text-xs italic text-gray-500">No prep notes yet.</p>
              )}
            </div>

            {/* Post Meeting Actions */}
            <div className='border-t m-1 p-1'>
              <div className=''>
                <p className='text-sm font-bold text-gray-600 m-1 p-1'>Post Meeting Actions</p>
                
                {/* Action Areas -  */}
                <div className='flex justify-between items-center my-1 py-1 bg-gray-100'>
                  <div 
                    className='flex justify-start items-center mx-1 px-1 cursor-pointer'
                    onClick={() => setActivePostTab('conversations')}  
                  >
                      <EtWabConversationIcon1SVGComp className='h-6 w-6 text-orange-600'/>
                      <p className={`text-[10px] font-bold mx-1 ${activePostTab === 'conversations' ? 'border-b-2 border-orange-600': ''}`}>Conversations</p>
                  </div>
                  <div 
                    className='flex justify-start items-center mx-1 px-1 cursor-pointer'
                    onClick={() => setActivePostTab('actions')}
                  
                  >
                      <EtWabActionIcon1SVGComp className='h-6 w-6 text-orange-600'/>
                      <p className={`text-[10px] font-bold mx-1 ${activePostTab === 'actions' ? 'border-b-2 border-orange-600': ''}`}>Actions</p>
                  </div>
                  <div 
                    className='flex justify-start items-center mx-1 px-1 cursor-pointer'
                    onClick={() => setActivePostTab('transcript')}
                  
                  >
                      <ShareIcon className='h-4 w-4 text-orange-600'/>
                      <p className={`text-[10px] font-bold mx-1 ${activePostTab === 'transcript' ? 'border-b-2 border-orange-600': ''}`}>Transcript</p>
                  </div>
                </div>

                {activePostTab === 'conversations' && (
                  <div className="bg-white rounded p-2 text-xs mt-2">
                    <div className='flex justify-between items-center m-1 p-1'>
                      <div className='flex justify-start items-center'>
                        <EtMeetingIcon2SVGComp className='h-6 w-6 text-orange-600' />
                        <p className='text-sm font-bold text-gray-600 mx-1 px-1'>Conversations Trail</p>
                      </div>
                      <div className='flex justify-end items-center'>
                        <p className='text-xs font-bold mx-1 px-1'>Add</p>
                        <button
                          className="flex items-center bg-lime-500 text-white p-1 rounded text-xs hover:bg-lime-600"
                          onClick={() => setShowConversationModal(true)}
                        >
                          <PlusIcon className="h-4 w-4" />
                        </button>
                      </div>
                    </div>

                    {/* You can later replace this placeholder with an editor or conversation log component */}
                    {meetingConversations?.length > 0 ? (
                          meetingConversations
                          ?.slice()
                            .sort(
                              (a, b) =>
                                  new Date(b.userConversationAllNoteUpdateDate) - new Date(a.userConversationAllNoteUpdateDate)
                          ) // Sort in descending order
                          ?.map((note, idx) => {
                            const noteType = note?.noteType || ""
                            const contactName = note?.contactName || ""
                            const contactType = note?.contactType || ""
                            const noteText = note?.userConversationAllNoteText || ""
                            const noteDate = note?.userConversationAllNoteDate || ""
                            const noteUpdateDate = note?.userConversationAllNoteUpdateDate || ""
                            
                            // console.log('note conversation', note)

                            return (
                            <div key={note._id + idx} className="">

                                <div className='bg-gray-200 rounded grid grid-cols-11 justify-start items-center m-1 p-1'>
                                  <div className="col-span-4 flex justify-start items-center">
                                    <div
                                      onClick={() => handleToggleConversationDetails(note._id)}
                                      className="rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
                                    >
                                      {showConversationDetails[note._id] ? (
                                        <MinusIcon className="h-4 w-4 text-orange-600" />
                                      ) : (
                                        <PlusIcon className="h-4 w-4 text-orange-600" />
                                      )}
                                    </div>
                                    <div>
                                      <p className="text-sm font-bold mx-1 px-1 text-gray-600">{ idx +1}.</p>
                                    </div>
                                    <div className="">
                                      <p className="text-[8px] mx-1 rounded-full px-1 my-[1px] py-[1px] bg-lime-200 w-full">
                                        {getTimeSince(noteUpdateDate)}
                                      </p>
                                      <p className="text-[7px] mx-1 italic px-1 py-[1px] text-gray-400">last updated</p>
                                    </div>
                                  </div>

                                  <div className="col-span-6 flex justify-start items-center">
                                    <div
                                      dangerouslySetInnerHTML={createMarkup(noteText)}
                                      className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                    ></div>
                                  </div>

                                  <div className="col-span-1 flex justify-start items-center">
                                    <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                      <PencilSquareIcon
                                        className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                        onClick={() => {
                                          setSelectedConversationNoteId(note._id); // ✅ Set noteId for editing
                                          setShowConversationModal(true);
                                        }}
                                      />
                                    </div>
                                    <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                      <TrashIcon
                                        className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                        onClick={() => {
                                          setSelectedConversationNoteId(note._id); // ✅ Set noteId for editing
                                          setIsDeletingConversationNote(true);     // ✅ Set flag for deleting
                                          setShowConversationModal(true);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                {showConversationDetails[note._id] && (
                                    <div className='bg-orange-50 rounded m-1 p-1'>
                                      <p dangerouslySetInnerHTML={createMarkup(noteText)} className='text-xs leading-4 m-1 p-1'></p>
                                      <div className='flex justify-between items-center m-1 p-1 border-t'>
                                        <div className='flex justify-start items-center my-1'>
                                          <ClockIcon className="h-5 w-5 text-lime-600" />
                                          <p className="text-[10px] text-orange-600 font-bold mx-1 px-1">
                                              Created:
                                          </p>
                                          <p className="text-[10px] text-gray-500">
                                            {moment(noteDate).format('DD MMM, hh:mm A')}
                                          </p>
                                        </div>
                                        <div className='flex justify-start items-center my-1'>
                                          <ClockIcon className="h-5 w-5 text-lime-600" />
                                          <p className="text-[10px] text-gray-500 font-bold mx-1 px-1">
                                              Updated:
                                          </p>
                                          <p className="text-[10px] text-gray-500">
                                              {moment(noteUpdateDate).format('DD MMM, hh:mm A')}
                                          </p>
                                        </div>   
                                      </div>  
                                    </div>
                                 )}  
                              </div>
                          )})
                        ) : (
                          <p className="text-xs italic text-gray-500 mt-2">No conversations added yet.</p>
                     )}
                  </div>
                )}

                {/* Placeholder for other tabs */}
                {activePostTab === "actions" && (
                  <div className="bg-gray-100 rounded mt-2 p-2">
                    <p className="text-xs italic text-gray-500">Actions coming soon...</p>
                  </div>
                )}
                {activePostTab === "transcript" && (
                  <div className="bg-gray-100 rounded mt-2 p-2">
                    <p className="text-xs italic text-gray-500">Transcript feature coming soon...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        
        {showModal && (
        <EtModalUpdateUserMeetingPrep
          isOpen={showModal}
          id={matchingPrep._id}
          noteId={selectedNoteId}   // ✅ 22 Mar 2025 - pass selected noteId
          isDelete={isDeletingNote} // ✅ 22 Mar 2025 - pass delete flag
          userId={matchingPrep.userId}
          userOrgId={matchingPrep.userOrgId}
          triggeringUrl={"/etapp"} // Or null, if staying on the same page
          onClose={() => {
            setShowModal(false);
            setSelectedNoteId(null); // ✅ 22 Mar 2025 - reset after modal close
            setIsDeletingNote(false) // ✅ 22 Mar 2025 - reset after modal close
          }}
        />)}
        {showConversationModal && (
          <EtModalTrinityUserMeetingConversation
            isOpen={showConversationModal}
            userId={matchingPrep?.userId}
            noteId={selectedConversationNoteId}
            isDelete={isDeletingConversationNote}
            userOrgId={matchingPrep?.userOrgId}
            calendarMeetingId={matchingPrep?.calendarMeetingId}
            userMeetingPrepId={matchingPrep?._id}
            onClose={() => {
              setShowConversationModal(false)
              setSelectedConversationNoteId(null); // ✅ 25 Mar 2025 - reset after modal close
              setIsDeletingConversationNote(false) // ✅ 25 Mar 2025 - reset after modal close
            }}
            refetchUserConversations={refetchUserConversations} // ✅ 25 Mar 2025
          />
        )}

    </div>
  )
}


const User = ({ username, defaultUserPic }) => {
    // const { data: userPic, isFetching: isFetchingUserPic } =
    //   useGetUserPicQuery(username)
    // 20 Mar 2025 - added skip
    const { data: userPic, isFetching: isFetchingUserPic } =
      useGetUserPicQuery(username, { skip: !username })
  
    return (
      <div className="flex justify-center items-center h-12 w-12 rounded-full border border-white bg-gradient-to-r from-orange-600 to-orange-400 shadow-lg shadow-black cursor-pointer">
        {/* Display user picture or loading indicator*/}
        {isFetchingUserPic ? (
          <div>Loading...</div>
        ) : userPic ? (
          <img src={userPic} alt="User" className="h-11 w-11  rounded-full" />
        ) : (
          <img src={defaultUserPic} alt="User" className="h-10 w-10 p-1" />
        )}
      </div>
    )
  }

export default EtTrinityWebLandingAction