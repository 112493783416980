import React, {useState} from 'react'
import { useNavigate } from 'react-router'
import { addIcon } from '@/assets/img/imgAssets'
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import PulseLoader from "react-spinners/PulseLoader"
import { useGetCapabilitiesDomainTypesQuery } from './capabilitiesDomainTypesApiSlice'

const EtCapabilitiesDomainTypeList = ({ setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }) => {

  // First Created - 12 Nov 2024
  // Author - Atul Pandey
  // Updated - 14 Nov 2024 - added show hide for domain types
  // Updated - 27 Feb 2025 - updated the function with props - { setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }
  // Updated - 27 Feb 2025 - switched from navigate to onAdd, onUpdate, onDelete, setSelectedEntityId
    
  // 1 - Set navigation
  const navigate = useNavigate()
  
  // 2a - Initialize showDetails state as an object where key is the capabilitiesDomainType index
  const [showTechDetails, setShowTechDetails] = useState(null)
  const [showBizDetails, setShowBizDetails] = useState(null)
  const [showBizSection, setShowBizSection] = useState(false) // 14 Nov 2024
  const [showTechSection, setShowTechSection] = useState(false) // 14 Nov 2024

  const {data: capabilitiesDomainTypesData, isError, error, isLoading} = useGetCapabilitiesDomainTypesQuery()
  // console.log('capabilitiesDomainTypesData', capabilitiesDomainTypesData)

  // 2b - Business Capabilities
  const businessCapabilities = capabilitiesDomainTypesData?.ids
  .filter(id => capabilitiesDomainTypesData.entities[id].capabilitiesDomainFocus === "Business")
  .map(id => capabilitiesDomainTypesData.entities[id]);
  // console.log('business capabilities', businessCapabilities)

  // 2c - Technology Capabilities
  const technologyCapabilities = capabilitiesDomainTypesData?.ids
  .filter(id => capabilitiesDomainTypesData.entities[id].capabilitiesDomainFocus === "Technology")
  .map(id => capabilitiesDomainTypesData.entities[id]);
  
  // 3 - Toggle the show/hide state for the specific capabilitiesDomainType index
  const handleBizToggle = (index) => {
        setShowBizDetails(prev => prev === index ? null : index);
  }

  const handleTechToggle = (index) => {
        setShowTechDetails(prev => prev === index ? null : index);
  }
    
  // 4 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
     }
  

  // 5 -  Optional: handle loading and error states
    if (isLoading) return <PulseLoader color={"#ea580c"} />
    if (isError) return <div>Error occurred: {error?.data?.message}</div>


  return (
    <div className='bg-gray-200 m-1 p-1 rounded mx-auto'>
        {/* Header */}
        <div className='flex justify-start items-center m-1 p-1'>

            <div><p className='font-poppins text-2xl m-1 p-1 font-bold'>Et Capabilities Domain Types</p></div>
            <div>
          <img
            src={addIcon}
            alt=""
            className="h-10 w-10 cursor-pointer"
            // onClick={() => navigate("/capabilitiesDomaintypes/create")}
            onClick={onAdd} // 27 Feb 2025
          />
        </div>

        </div>

        {/* CapabilitiesDomainType Data */}
        <div id='capabilitiesDomainType-data' className='bg-white rounded m-1 p-1 font-poppins'>
            {capabilitiesDomainTypesData?.ids.length === 0 && (
                <div><p>No capabilities Domain type data exists. Click above to create one</p></div>
            )}
            
            
            <div className='bg-gray-50 rounded m-1 p-1 border'>
              <div className='m-1 p-1 flex justify-start items-center'>
                  <div onClick={() => setShowBizSection(prev => !prev)}
                    className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                    {showBizSection ? (
                      <MinusIcon className="h-3 w-3 text-orange-600 " />
                    ) : (
                      <PlusIcon className="h-3 w-3 text-orange-600 " />
                    )}
                  </div>
                  <p className='font-bold m-1 p-1'>Business Capabilities</p>
              </div>
              
              {showBizSection && ( 
                <div>
                  {businessCapabilities?.map((capability, index) => (
                    <div key={index} className=' bg-gray-200 rounded m-1 p-1'>
                      <div className='flex justify-between items-center m-1 p-1'>
                        <div className='flex justify-start items-center mx-2'>
                          <div onClick={() => handleBizToggle(index)}
                            className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                            {showBizDetails === index ? (
                              <MinusIcon className="h-3 w-3 text-orange-600 " />
                            ) : (
                              <PlusIcon className="h-3 w-3 text-orange-600 " />
                            )}
                          </div>
                          <div><p className='text-sm font-bold mx-1 px-1'>{index+1} -</p></div>
                          {capability.pic && (
                                <div className='flex justify-center items-center h-7 w-7 bg-gray-100 hover:bg-white rounded-full cursor-pointer' onClick={() => handleBizToggle(index)}>
                                  <img src={capability.pic} alt="" className='h-5 w-5' />
                                </div>
                              )}
                          <p className='text-sm font-bold mx-1 px-1'>{capability.capabilitiesDomainTypeName}</p>
                        </div>
                        <div className='flex justify-start items-center'>

                              <div 
                              // onClick={() => navigate(`/capabilitiesDomainTypes/update/${capability._id}`)} 
                              onClick={() => onUpdate(capability._id)} // 27 Feb 2025
                              
                              className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'>
                                  <PencilSquareIcon className="h-3 w-3 text-orange-600 " />
                              </div>

                              <div 
                              // onClick={() => navigate(`/capabilitiesDomaintypes/delete/${capability._id}`)} 
                              onClick={() => onDelete(capability._id)} // 27 Feb 2025
                              
                              className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'>
                                  <TrashIcon className="h-3 w-3 text-orange-600 " />
                              </div> 
                        </div>
                      </div>
                      {showBizDetails === index && (
                        <div className=' bg-white rounded text-xs py-1'>
                          <p className='font-bold m-1 p-1 border-b-2 border-orange-600'>Description:</p>
                          <div dangerouslySetInnerHTML={createMarkup(capability.capabilitiesDomainTypeDesc)} className='m-1 p-1'></div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              
            </div>

            <div className='bg-gray-50 rounded m-1 p-1 border'>
            <div className='m-1 p-1 flex justify-start items-center'>
                  <div onClick={() => setShowTechSection(prev => !prev)}
                    className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                    {showTechSection ? (
                      <MinusIcon className="h-3 w-3 text-orange-600 " />
                    ) : (
                      <PlusIcon className="h-3 w-3 text-orange-600 " />
                    )}
                  </div>
                  <p className='font-bold m-1 p-1'>Technology Capabilities</p>
              </div>

              {showTechSection && (
                <div>
                  {technologyCapabilities?.map((capability, techIndex) => (
                    <div key={capability._id+techIndex} className=' bg-gray-200 rounded m-1 p-1'>
                      <div className='flex justify-between items-center m-1 p-1'>
                        <div className='flex justify-start items-center mx-2'>
                          <div onClick={() => handleTechToggle(techIndex)}
                            className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                            {showTechDetails === techIndex ? (
                              <MinusIcon className="h-3 w-3 text-orange-600 " />
                            ) : (
                              <PlusIcon className="h-3 w-3 text-orange-600 " />
                            )}
                          </div>
                          <div><p className='text-sm font-bold mx-1 px-1'>{techIndex+1} -</p></div>
                          {capability.pic && (
                                <div className='flex justify-center items-center h-7 w-7 bg-gray-100 hover:bg-white rounded-full cursor-pointer' onClick={() => handleTechToggle(techIndex)}>
                                  <img src={capability.pic} alt="" className='h-5 w-5' />
                                </div>
                              )}
                          <p className='text-sm font-bold mx-1 px-1'>{capability.capabilitiesDomainTypeName}</p>
                        </div>
                        <div className='flex justify-start items-center'>

                              <div onClick={() => navigate(`/capabilitiesDomainTypes/update/${capability._id}`)} className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'>
                                  <PencilSquareIcon className="h-3 w-3 text-orange-600 " />
                              </div>

                              <div onClick={() => navigate(`/capabilitiesDomaintypes/delete/${capability._id}`)} className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30  hover:bg-white'>
                                  <TrashIcon className="h-3 w-3 text-orange-600 " />
                              </div> 
                        </div>
                      </div>
                      {showTechDetails === techIndex && (
                        <div className=' bg-white rounded text-xs py-1'>
                          <p className='font-bold m-1 p-1 border-b-2 border-orange-600'>Description:</p>
                          <div dangerouslySetInnerHTML={createMarkup(capability.capabilitiesDomainTypeDesc)} className='m-1 p-1'></div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              
            </div>
        </div>

    </div>
  )
}

export default EtCapabilitiesDomainTypeList