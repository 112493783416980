import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userChallengeSponsorEvalsAdapter = createEntityAdapter({})

const initialState = userChallengeSponsorEvalsAdapter.getInitialState()

export const userChallengeSponsorEvalsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserChallengeSponsorEvals: builder.query({
            query: () => ({
                url: '/userChallengeSponsorEvals/getUserChallengeSponsorEvals',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserChallengeSponsorEvals = responseData.map(userChallengeSponsorEval => {
                    userChallengeSponsorEval.id = userChallengeSponsorEval._id
                    return userChallengeSponsorEval
                })
                return userChallengeSponsorEvalsAdapter.setAll(initialState, loadedUserChallengeSponsorEvals)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserChallengeSponsorEval', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserChallengeSponsorEval', id}))
                    ]
                } else {
                    return [ { type: 'UserChallengeSponsorEval', id: 'LIST'}]
                }
            },
        }),
        createNewUserChallengeSponsorEval: builder.mutation({
            query: initialUserChallengeSponsorEval => ({
                url: '/userChallengeSponsorEvals/createNewUserChallengeSponsorEval',
                method: 'POST',
                body: {...initialUserChallengeSponsorEval,}
            }),
            invalidatesTags: [
                { type: 'UserChallengeSponsorEval', id: "LIST" }
            ] 
        }),
        updateUserChallengeSponsorEval: builder.mutation({
            query: initialUserChallengeSponsorEval => ({
                url: '/userChallengeSponsorEvals/updateUserChallengeSponsorEval',
                method: 'PATCH',
                body: {...initialUserChallengeSponsorEval,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserChallengeSponsorEval', id: arg.id },
                { type: 'UserChallengeSponsorEval', id: 'LIST' }
            ],
        }),
        deleteUserChallengeSponsorEval: builder.mutation({
            query: ({id}) => ({
                url: '/userChallengeSponsorEvals/deleteUserChallengeSponsorEval',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserChallengeSponsorEval', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserChallengeSponsorEvalsQuery,
    useCreateNewUserChallengeSponsorEvalMutation,
    useUpdateUserChallengeSponsorEvalMutation,
    useDeleteUserChallengeSponsorEvalMutation,
} = userChallengeSponsorEvalsApiSlice

// returns the query result object
export const selectUserChallengeSponsorEvalResult = userChallengeSponsorEvalsApiSlice.endpoints.getUserChallengeSponsorEvals.select()

// create memoized selector
const selectUserChallengeSponsorEvalsData = createSelector(
    selectUserChallengeSponsorEvalResult,
    userChallengeSponsorEvalResult => userChallengeSponsorEvalResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserChallengeSponsorEvals,
    selectById: selectUserChallengeSponsorEvalById,
    selectIds: selectUserChallengeSponsorEvalIds,
} = userChallengeSponsorEvalsAdapter.getSelectors(state => selectUserChallengeSponsorEvalsData(state) ?? initialState)