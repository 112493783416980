import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userSession8848KYCsAdapter = createEntityAdapter({})

const initialState = userSession8848KYCsAdapter.getInitialState()

export const userSession8848KYCsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserSession8848KYCs: builder.query({
            query: () => ({
                url: '/userSession8848KYCs/getUserSession8848KYCs',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserSession8848KYCs = responseData.map(userSession8848KYC => {
                    userSession8848KYC.id = userSession8848KYC._id
                    return userSession8848KYC
                })
                return userSession8848KYCsAdapter.setAll(initialState, loadedUserSession8848KYCs)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserSession8848KYC', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserSession8848KYC', id}))
                    ]
                } else {
                    return [ { type: 'UserSession8848KYC', id: 'LIST'}]
                }
            },
        }),
        createNewUserSession8848KYC: builder.mutation({
            query: initialUserSession8848KYC => ({
                url: '/userSession8848KYCs/createNewUserSession8848KYC',
                method: 'POST',
                body: {...initialUserSession8848KYC,}
            }),
            invalidatesTags: [
                { type: 'UserSession8848KYC', id: "LIST" }
            ] 
        }),
        updateUserSession8848KYC: builder.mutation({
            query: initialUserSession8848KYC => ({
                url: '/userSession8848KYCs/updateUserSession8848KYC',
                method: 'PATCH',
                body: {...initialUserSession8848KYC,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserSession8848KYC', id: arg.id },
                { type: 'UserSession8848KYC', id: 'LIST' }
            ],
        }),
        deleteUserSession8848KYC: builder.mutation({
            query: ({id}) => ({
                url: '/userSession8848KYCs/deleteUserSession8848KYC',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserSession8848KYC', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserSession8848KYCsQuery,
    useCreateNewUserSession8848KYCMutation,
    useUpdateUserSession8848KYCMutation,
    useDeleteUserSession8848KYCMutation,
} = userSession8848KYCsApiSlice

// returns the query result object
export const selectUserSession8848KYCResult = userSession8848KYCsApiSlice.endpoints.getUserSession8848KYCs.select()

// create memoized selector
const selectUserSession8848KYCsData = createSelector(
    selectUserSession8848KYCResult,
    userSession8848KYCResult => userSession8848KYCResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserSession8848KYCs,
    selectById: selectUserSession8848KYCById,
    selectIds: selectUserSession8848KYCIds,
} = userSession8848KYCsAdapter.getSelectors(state => selectUserSession8848KYCsData(state) ?? initialState)