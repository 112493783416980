import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const problemMetricGradationTypesAdapter = createEntityAdapter({})

const initialState = problemMetricGradationTypesAdapter.getInitialState()

export const problemMetricGradationTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProblemMetricGradationTypes: builder.query({
            query: () => ({
                url: '/problemMetricGradationTypes/getProblemMetricGradationTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedProblemMetricGradationTypes = responseData.map(problemMetricGradationType => {
                    problemMetricGradationType.id = problemMetricGradationType._id
                    return problemMetricGradationType
                })
                return problemMetricGradationTypesAdapter.setAll(initialState, loadedProblemMetricGradationTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'ProblemMetricGradationType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'ProblemMetricGradationType', id}))
                    ]
                } else {
                    return [ { type: 'ProblemMetricGradationType', id: 'LIST'}]
                }
            },
        }),
        createNewProblemMetricGradationType: builder.mutation({
            query: initialProblemMetricGradationType => ({
                url: '/problemMetricGradationTypes/createNewProblemMetricGradationType',
                method: 'POST',
                body: {...initialProblemMetricGradationType,}
            }),
            invalidatesTags: [
                { type: 'ProblemMetricGradationType', id: "LIST" }
            ] 
        }),
        updateProblemMetricGradationType: builder.mutation({
            query: initialProblemMetricGradationType => ({
                url: '/problemMetricGradationTypes/updateProblemMetricGradationType',
                method: 'PATCH',
                body: {...initialProblemMetricGradationType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'ProblemMetricGradationType', id: arg.id },
                { type: 'ProblemMetricGradationType', id: 'LIST' }
            ],
        }),
        deleteProblemMetricGradationType: builder.mutation({
            query: ({id}) => ({
                url: '/problemMetricGradationTypes/deleteProblemMetricGradationType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'ProblemMetricGradationType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetProblemMetricGradationTypesQuery,
    useCreateNewProblemMetricGradationTypeMutation,
    useUpdateProblemMetricGradationTypeMutation,
    useDeleteProblemMetricGradationTypeMutation,
} = problemMetricGradationTypesApiSlice

// returns the query result object
export const selectProblemMetricGradationTypeResult = problemMetricGradationTypesApiSlice.endpoints.getProblemMetricGradationTypes.select()

// create memoized selector
const selectProblemMetricGradationTypesData = createSelector(
    selectProblemMetricGradationTypeResult,
    problemMetricGradationTypeResult => problemMetricGradationTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllProblemMetricGradationTypes,
    selectById: selectProblemMetricGradationTypeById,
    selectIds: selectProblemMetricGradationTypeIds,
} = problemMetricGradationTypesAdapter.getSelectors(state => selectProblemMetricGradationTypesData(state) ?? initialState)