import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import Modal from "@/components/common/modal/Modal"
import { useGetOrgSubscriptionsQuery, useDeleteOrgSubscriptionMutation } from "./orgSubscriptionsApiSlice"


const EtDeleteOrgSubscription = ({id, setActiveAdminView}) => {
 // First Created - 15 May 2024
  // Author - Atul Pandey
  // Updated - 01 Mar 2025 - updated the softSkillFunctionArea with props - { id, setActiveAdminView}
  // Updated - 01 Mar 2025 - switched from navigate to setActiveAdminView

  // 1 - Set Page Title
  useTitle('Delete An Org Subscription')

  // 2 - Extract the Id to be updated
  // const { id } = useParams()
  //console.log("id:", id)

  // 3 - Extract the organization to be updated
  const { orgSubscription } = useGetOrgSubscriptionsQuery("OrgSubscription", {
    selectFromResult: ({ data }) => ({
      orgSubscription: data?.entities[id],
    }),
  })
  console.log('orgSubscription', orgSubscription)
  
  // 4 - Check for returned data
  if (!orgSubscription) {
    return <PulseLoader color={"#ea580c"} />
  }

  // 5. Display data
  let content
  content = <EtDeleteOrgSubscriptionForm orgSubscription={orgSubscription} id={id} setActiveAdminView={setActiveAdminView}/> // 01 Mar 2025 - added setActiveAdminView={setActiveAdminView}

  return content
}

const EtDeleteOrgSubscriptionForm = ({orgSubscription, id, setActiveAdminView}) => {
  // 1 - set the navigate hook
  const navigate = useNavigate()

  // 1a - Delete confirmation Modal
  const [isModalOpen, setIsModalOpen] = useState(false)

  // 2 - mutation to delete question
  const[deleteOrgSubscription, {
      isLoading,
      isSuccess,
      isError,
      error,
  }] = useDeleteOrgSubscriptionMutation()

  // 3 - Set the data received in the component props to the local variables
  const [formData, setFormData] = useState({
    orgId: orgSubscription.orgId.organizationName, 
    subsPackId: orgSubscription.subsPackId[0].subscriptionPackName, 
    orgSubscriptionName: orgSubscription.orgSubscriptionName,
    })
  
  // 4. Reset the input field and navigation based on the success state 
  useEffect(() => {
      if(isSuccess) {
          setFormData({
            orgId: "", 
            subsPackId: "", 
            orgSubscriptionName: "",
            aboutMe: "", 
            pic: "",
            })
         // navigate('/orgSubscriptions')
      setActiveAdminView('orgSubscriptions'); // 01 Mar 2025
    }
    
    },[isSuccess])// ✅ 01 Mar 2025 - Removed `navigate` since it's no longer used.
    
  // 5. Form field updates / change handling

  // 6. Modal management functions
  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => setIsModalOpen(false)


// 7. Form Submit logic:
const handleConfirm = async (e) => {
  e.preventDefault()
  handleCloseModal()

  // Initialize an object to collect the form data
  const orgSubscriptionData = {
    orgSubscriptionName: orgSubscription.orgSubscriptionName
  }
    // No image, send other data as is
    try {
      orgSubscriptionData.id = id
      console.log("Form submitted.")
      await deleteOrgSubscription(orgSubscriptionData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
  
}

// 8. Handle Cancel
const handleCancel = () => {
   // navigate('/orgSubscriptions')
   setActiveAdminView('orgSubscriptions'); // 01 Mar 2025
}

return (
  <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
  {isLoading && <PulseLoader color={"#ea580c"} />}
  {isError && (
    <p className="text-orange-700 font-semi-bold py-2">
      {error?.data?.message}
    </p>
  )}
  <div className="bg-white p-1 m-1 rounded">
    <p className="p-1 m-1 font-bold font-poppins text-xl">
      Delete An Org Subscription
    </p>

    <div className="bg-gray-200 rounded m-1 p-1">
      <form
        onSubmit={handleOpenModal}
        className="m-1 p-1 text-xs font-poppins"
      >
        
          <div id="organizationName" >
            <input
              type="text"
              name="organizationName"
              placeholder="OrgSubscription"
              readOnly={true}
              value={formData.orgId}
              className="w-full rounded p-2 m-1"
            />
          </div>
          <div id="subscriptionPackName" >
            <input
              type="text"
              name="subscriptionPackName"
              placeholder="OrgSubscription"
              readOnly={true}
              value={formData.subsPackId}
              className="w-full rounded p-2 m-1"
            />
          </div>
          <div id="orgSubscriptionName" >
            <input
              type="text"
              name="orgSubscriptionName"
              placeholder="OrgSubscription"
              readOnly={true}
              value={formData.orgSubscriptionName}
              className="w-full rounded p-2 m-1"
            />
          </div>

       

        <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
          <EtBtnFormBasic
            name={"Delete"}
            type={"create"}
            handleClick={handleOpenModal}
          />
         
          <EtBtnFormBasic
            name={"Cancel"}
            type={"cancel"}
            handleClick={handleCancel}
          />
        </div>
      </form>
    </div>
  </div>
  <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleConfirm} />
</div>
)


}

export default EtDeleteOrgSubscription