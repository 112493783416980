import { cn } from '@udecode/cn';
import {
  useIndentTodoListElement,
  useIndentTodoListElementState,
} from '@udecode/plate-indent-list';

import { Checkbox } from './checkbox';

export const TodoMarker = ({ element }) => {
  const state = useIndentTodoListElementState({ element });
  const { checkboxProps } = useIndentTodoListElement(state);

  return (
    <div contentEditable={false}>
      <Checkbox
        style={{ left: -24, position: 'absolute', top: 4 }}
        {...checkboxProps}
      />
    </div>
  );
};

export const TodoLi = (props) => {
  const { children, element } = props;

  return (
    <span
      className={cn(
        (element.checked) && 'text-muted-foreground line-through'
      )}
    >
      {children}
    </span>
  );
};