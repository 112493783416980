import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLightWithPlace from "@/utils/EtContentEditorLightWithPlace"
import { useGetOrgRolesQuery, useUpdateOrgRoleMutation } from "./orgRolesApiSlice"

const EtUpdateOrgRole = ({id, setActiveAdminView}) => {
  // Created - 05 Sep 2024
  // Author - Atul Pandey
  // Updated - 03 Mar 2025 - updated the comp with props - { id, setActiveAdminView}
  // Updated - 03 Mar 2025 - switched from navigate to setActiveAdminView

  // 1 - Set Title
  useTitle("Update Org role")

  // 2 - extract the orgRole Id from the url
  // const { id } = useParams()
  //console.log("id:", id)

  // 3 - Extract the orgRole from the query to load the orgRole data
  const { orgRole } = useGetOrgRolesQuery("OrgRole", {
    selectFromResult: ({ data }) => ({
      orgRole: data?.entities[id],
    }),
  })

  //console.log('org role:', orgRole)

  // 4 - Check for returned data
  if (!orgRole) {
    return <PulseLoader color={"#ea580c"} />
  }

  // 5. Display data
  let content
  content = <EtUpdateOrgRoleForm orgRole={orgRole} id={id} setActiveAdminView={setActiveAdminView}/> // 03 Mar 2025 - added setActiveAdminView={setActiveAdminView} 
  return content
}

const EtUpdateOrgRoleForm = ({ orgRole, id, setActiveAdminView}) =>{
  // 1 - set the variable for navigate
  const navigate = useNavigate()

  //console.log('update form invoked!')

  // 2 - set the mutation to create the new section data
  const[updateOrgRole, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useUpdateOrgRoleMutation()

// 3 - set the form data object
const [formData, setFormData] = useState({
  orgRoleName: orgRole.orgRoleName,
  orgRoleDesc: orgRole.orgRoleDesc,
  aboutMe: orgRole.aboutMe,
})

//console.log('formData for update:', formData)

// 4 - Reset form upon successful submission
useEffect(() => {
  if(isSuccess) {
    setFormData({
      orgRoleName: "",
      orgRoleDesc: "",
      aboutMe: "",
      pic: "",
    })

    // navigate('/orgRoles')
    setActiveAdminView('orgRoles'); // 03 Mar 2025
  }
  
  },[isSuccess])// ✅ 03 Mar 2025 - Removed `navigate` since it's no longer used.

// 5 - Form field updates / change handling

const handleChange = (e) => {
  const { name, value, files } = e.target
  if (name === "pic") {
    setFormData(prevState => ({ ...prevState, pic: files[0] }))
  } else {
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }
}

// 6 - function to strip html tags from sections
function stripHtml(html) {
  return html.replace(/<\/?[^>]+(>|$)/g, "");
}

// 7 - Form Submit Logic
const handleSubmit = async (e) => {
e.preventDefault()

// Initialize an object to collect the form data
const orgRoleData = {
  orgRoleName: formData.orgRoleName,
  orgRoleDesc: formData.orgRoleDesc,
  aboutMe: formData.aboutMe,
}

//console.log('orgRole Name:',orgRoleData.orgRoleName)

if (formData.pic) {
  // Convert the image to a base64 string
  const reader = new FileReader()
  reader.onloadend = async () => {
    // Once conversion is done, add the base64 string to your data object
    orgRoleData.pic = reader.result

    // Now, send the data object to the server
    try {
      orgRoleData.id = id
      console.log("Form submitted.")
      await updateOrgRole(orgRoleData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
  }
  reader.readAsDataURL(formData.pic)
} else {
  // No image, send other data as is
  try {
    orgRoleData.id = id
    console.log("Form submitted.")
    await updateOrgRole(orgRoleData).unwrap() // Dispatch the RTK Query mutation
  } catch (error) {
    console.error(error)
  }
}
}

// 8 - Handle Cancel

const handleCancel = () => {
  // navigate('/orgRoles')
  setActiveAdminView('orgRoles'); // 03 Mar 2025
}

// 9 - Handle Clear
const handleClear = () => {
  setFormData({
    orgRoleName: "",
    orgRoleDesc: "",
    aboutMe: "",
    pic: "",
    })
}

return (
  <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
    {isLoading && <PulseLoader color={"#ea580c"} />}
    {isError && (
      <p className="text-orange-700 font-semi-bold py-2">
        {error?.data?.message}
      </p>
    )}
    <div className="bg-white p-1 m-1 rounded">
      <p className="p-1 m-1 font-bold font-poppins text-xl">Update a Org role</p>

      <div className="bg-gray-200 rounded m-1 p-1">
        <form
          onSubmit={handleSubmit}
          className="m-1 p-1 text-xs font-poppins"
        >
          
          <div id="orgRoleName">
            <input
              type="text"
              name="orgRoleName"
              placeholder="Enter the org role name here"
              value={formData.orgRoleName}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div className="bg-white w-full rounded p-2 m-1">
            <EtContentEditorLightWithPlace
              value={formData.orgRoleDesc}
              handleChange={(content) =>
                setFormData({ ...formData, orgRoleDesc: content })
              }
              placeholderText={
                "Write this org role is about or will be used for"
              }
              // toolbarId={'sectionDesc'}
            />
          </div>

          <div id="aboutMe">
            <input
              type="text"
              name="aboutMe"
              placeholder="Add something about the personality about this org role"
              value={formData.aboutMe}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="pic">
            <input
              type="file"
              name="pic"
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

         <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic
              name={"Update"}
              type={"create"}
              handleClick={handleSubmit}
            />
            <EtBtnFormBasic
              name={"Clear"}
              type={"clear"}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={"Cancel"}
              type={"cancel"}
              handleClick={handleCancel}
            />
          </div>
        </form>
      </div>
    </div>
  </div>
)

}

export default EtUpdateOrgRole