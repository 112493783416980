import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const testEditorContentsAdapter = createEntityAdapter({})

const initialState = testEditorContentsAdapter.getInitialState()

export const testEditorContentsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTestEditorContents: builder.query({
            query: () => ({
                url: '/testEditorContents/getTestEditorContents',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedTestEditorContents = responseData.map(testEditorContent => {
                    testEditorContent.id = testEditorContent._id
                    return testEditorContent
                })
                return testEditorContentsAdapter.setAll(initialState, loadedTestEditorContents)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'TestEditorContent', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'TestEditorContent', id}))
                    ]
                } else {
                    return [ { type: 'TestEditorContent', id: 'LIST'}]
                }
            },
        }),
        createNewTestEditorContent: builder.mutation({
            query: initialTestEditorContent => ({
                url: '/testEditorContents/createNewTestEditorContent',
                method: 'POST',
                body: {...initialTestEditorContent,}
            }),
            invalidatesTags: [
                { type: 'TestEditorContent', id: "LIST" }
            ] 
        }),
        updateTestEditorContent: builder.mutation({
            query: initialTestEditorContent => ({
                url: '/testEditorContents/updateTestEditorContent',
                method: 'PATCH',
                body: {...initialTestEditorContent,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'TestEditorContent', id: arg.id },
                { type: 'TestEditorContent', id: 'LIST' }
            ],
        }),
        deleteTestEditorContent: builder.mutation({
            query: ({id}) => ({
                url: '/testEditorContents/deleteTestEditorContent',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'TestEditorContent', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetTestEditorContentsQuery,
    useCreateNewTestEditorContentMutation,
    useUpdateTestEditorContentMutation,
    useDeleteTestEditorContentMutation,
} = testEditorContentsApiSlice

// returns the query result object
export const selectTestEditorContentResult = testEditorContentsApiSlice.endpoints.getTestEditorContents.select()

// create memoized selector
const selectTestEditorContentsData = createSelector(
    selectTestEditorContentResult,
    testEditorContentResult => testEditorContentResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllTestEditorContents,
    selectById: selectTestEditorContentById,
    selectIds: selectTestEditorContentIds,
} = testEditorContentsAdapter.getSelectors(state => selectTestEditorContentsData(state) ?? initialState)