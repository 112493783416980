import React, { useState, useEffect, useRef } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { PaperAirplaneIcon, MicrophoneIcon, StopIcon } from "@heroicons/react/24/solid";
import { useGenerateTrinityResponseMutation } from "@/features/ai/trinityApiSlice";

const EtTrinityGuidedFlow = ({ context, promptFlow, onComplete, setIsOpen }) => {
  // First Created - 01 Feb 2025
  // Author - Atul Pandey
  // Purpose - To act as a guided shell for Trinity AI Prompt flow for a given context
  // Updated - 02 Feb 2025 - added voice inputs for recording a user's inputs via sound
  // Updated - 02 Feb 2025 - added edit, review & submit option for generated message
  // Updated - 05 Feb 2025 - refined voice input - stop when send button is clicked
  // Updated - 05 Feb 2025 - added state variable to track final update
  // Updated - 06 Feb 2025 - fixed the title and summary display issue

  // 0️⃣ - Set ref for voice recognition
  const recognitionRef = useRef(null); // ✅ Store recognition instance
  
  // 1️⃣ Extract User Info from Context
  const userInfo = Array.isArray(context) && context.length > 0 ? context[0] : {};
  const userName = userInfo?.name ?? "";
  const firstName = userName?.split(" ")[0] ?? "";

  // 2️⃣ State Management
  const [messages, setMessages] = useState([{ role: "assistant", content: promptFlow[0]?.prompt }]);
  const [userResponses, setUserResponses] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [summaryGenerated, setSummaryGenerated] = useState(false);
  const [isRecording, setIsRecording] = useState(false); // 02 Feb 2025 ✅ Track recording status
  const [isEditing, setIsEditing] = useState(false); // 02 Feb 2025  - For editing generated response
  const [editedTitle, setEditedTitle] = useState(""); // 02 Feb 2025  - Edited response for submission
  const [editedSummary, setEditedSummary] = useState(""); // 02 Feb 2025  - Edited response for submission
  const [updateCompleted, setUpdateCompleted] = useState(false); // 05 Feb 2025 - ✅ Track when the update is completed

  // 3️⃣ RTK Query Mutation
  const [generateTrinityResponse] = useGenerateTrinityResponseMutation();

  // 4️⃣ Handle Input Change
  const handleInputChange = (e) => setInput(e.target.value);

  // 5️⃣ Typing Effect for AI Responses
  const simulateTypingEffect = (text) => {
    let index = 0;
    const words = text.split(" ");
    let displayedText = "";

    const interval = setInterval(() => {
      if (index < words.length) {
        displayedText += words[index] + " ";
        setMessages((prev) => {
          const lastMessage = prev[prev.length - 1];
          if (lastMessage?.role === "assistant") {
            return [...prev.slice(0, -1), { role: "assistant", content: displayedText }];
          }
          return [...prev, { role: "assistant", content: displayedText }];
        });
        index++;
      } else {
        clearInterval(interval);
      }
    }, 100);
  };

  // 6️⃣ Handle Form Submission
  const handleSend = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    // ✅ Stop speech recognition if active
    if (isRecording && recognitionRef.current) {
      recognitionRef.current.stop();
      setIsRecording(false);
    }

    const userMessage = input.trim();
    setInput("");
    setLoading(true);

    // Store User Response
    const updatedResponses = {
      ...userResponses,
      [promptFlow[currentStep].step]: userMessage,
    };
    setUserResponses(updatedResponses);

    // Determine next AI prompt or final response
    let nextAssistantMessage = "Thank you! Let me process your responses...";
    if (currentStep < promptFlow.length - 1) {
      nextAssistantMessage = promptFlow[currentStep + 1].prompt;
    }

    setMessages((prev) => [
      ...prev,
      { role: "user", content: userMessage },
      { role: "assistant", content: "" },
    ]);

    setTimeout(() => simulateTypingEffect(nextAssistantMessage), 500);

    if (currentStep < promptFlow.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      generateFinalAIResponse(updatedResponses);
    }

    setLoading(false);
  };

  // 7️⃣ Generate Final AI Response
  const generateFinalAIResponse = async (responses) => {
    setLoading(true);
    const aiPrompt = `
    Given the structured responses from the user, generate a **rich, engaging, and personalized** imprint overview.
    - **Rewrite content fluently** for a natural flow.
    - **Maintain a professional yet engaging tone.**
    - **Use first-person ("I", "my")**.
    - **Output format:**  
      **Title (inside <h2>)**  
      **Narrative (inside <p> tags, no section headings)**

    📌 **User Responses for Context**:
    ${JSON.stringify(responses, null, 2)}

    Now, generate the user’s imprint overview following the specified format:
    `;

    try {
      const eventSource = new EventSource(
        `${process.env.REACT_APP_SERVER_URL}/trinity/generateResponse?` +
        `messages=${encodeURIComponent(JSON.stringify([
          { role: "system", content: "Generate a user imprint overview." },
          { role: "user", content: aiPrompt }
        ]))}&maxTokens=5000&model=${encodeURIComponent("gpt-4")}`
      );

      let aiResponse = "";
      eventSource.onmessage = (event) => {
        const chunk = event.data;
        aiResponse += chunk;

        setMessages((prev) => {
          const lastMessage = prev[prev.length - 1];
          if (lastMessage?.role === "assistant") {
            return [...prev.slice(0, -1), { role: "assistant", content: aiResponse }];
          }
          return [...prev, { role: "assistant", content: aiResponse }];
        });
      };

      eventSource.addEventListener("end", () => {
        eventSource.close();
        setLoading(false);

        // ✅ Ensure AI response is formatted correctly
        let cleanResponse = aiResponse.trim();

        // After initial parsing
        // console.log("Initial cleanResponse:", cleanResponse);

        
        // Extract AI-generated Title & Narrative
        const parser = new DOMParser();
        const doc = parser.parseFromString(cleanResponse, "text/html");

        const titleElement = doc.querySelector("h2");
        const finalizedNarrativeTitle = titleElement ? titleElement.innerText.trim() : "My Journey";

        // ✅ Remove any leading <h2> or unexpected title presence before parsing
        cleanResponse = cleanResponse.replace(/<h2>.*?<\/h2>/g, "").trim();

        // After removing h2 tag
        // console.log("After h2 removal:", cleanResponse);
        
        // Extract Narrative
        const docClean = parser.parseFromString(cleanResponse, "text/html");   

        // After creating docClean
        // console.log("Document children:", Array.from(docClean.body.children).map(el => el.outerHTML));

        // const narrativeElements = docClean.querySelectorAll("p");
        const narrativeElements = Array.from(docClean.body.children);
        const titleLower = finalizedNarrativeTitle.toLowerCase();

        // First, filter out non-paragraph elements and create initial narrative
        // let finalizedNarrative = narrativeElements
        // .filter(el => el.tagName.toLowerCase() === "p")
        // .map(p => p.outerHTML)
        // .join("\n");

      // ✅ Extract only <p> tags and get plaintext (no HTML)
      const finalizedNarrative = Array.from(narrativeElements)
      .filter(el => el.tagName.toLowerCase() === "p") // ✅ Keep only <p> tags
      .map(p => p.innerText.trim()) // ✅ Get plaintext only
      .join("\n");

      const paragraphElements = narrativeElements.filter(el => el.tagName.toLowerCase() === "p");
      const firstParagraph = paragraphElements[0]?.innerText?.trim().toLowerCase();
      
      // ✅ Remove the first paragraph **textually** if it matches the title (avoid using .outerHTML)
      if (firstParagraph && firstParagraph.includes(titleLower)) {
        finalizedNarrative = finalizedNarrative.split("\n").slice(1).join("\n").trim();
      }

      // if (firstParagraph && firstParagraph.includes(titleLower)) {
      //     finalizedNarrative = finalizedNarrative.replace(narrativeElements[0].outerHTML, "").trim();
      // }

        // // Add this helper function to strip HTML tags
        // const stripHtmlTags = (html) => {
        //   const div = document.createElement('div');
        //   div.innerHTML = html;
        //   return div.textContent || div.innerText || '';
        // };

        // const finalizedNarrative = Array.from(narrativeElements)
        // .map((p) => p.innerText.trim()) // ✅ Extract plaintext only, no HTML
        // .join("\n");

        setEditedTitle(finalizedNarrativeTitle);  // ✅ Auto-fill title
        setEditedSummary(finalizedNarrative);    // ✅ Auto-fill summary
        // setEditedSummary(stripHtmlTags(finalizedNarrative));    // ✅ Auto-fill summary

        setSummaryGenerated(true);
        setIsEditing(false)
      });

      eventSource.onerror = (error) => {
        console.error("Error in EventSource:", error);
        eventSource.close();
        setLoading(false);
      };
    } catch (error) {
      console.error("Error streaming AI response:", error);
      setMessages((prev) => [
        ...prev,
        { role: "assistant", content: "Oops, something went wrong. Please try again!" },
      ]);
      setLoading(false);
    }
  };

  // 8️⃣ Handle Voice Input
  const handleVoiceInput = () => {
    if (!("webkitSpeechRecognition" in window)) {
        alert("Your browser does not support voice input.");
        return;
      }

      if (isRecording) {                  // 05 Feb 2025
        if (recognitionRef.current) {
            recognitionRef.current.stop(); // ✅ Stop recording manually
            setIsRecording(false); // ✅ Reset recording state & switch icon
          }
          return;
      }

      const recognition = new window.webkitSpeechRecognition();
      recognitionRef.current = recognition; // ✅ Store recognition instance
    //   recognition.continuous = false; // ✅ Stops after user finishes speaking
      recognition.continuous = true; // ✅ Stops after user finishes speaking
    //   recognition.interimResults = false; // ✅ Only final results
      recognition.interimResults = true; // ✅ Show live transcription
      recognition.lang = "en-US"; // ✅ Set language
    
      recognition.onstart = () => {
        setIsRecording(true); // ✅ Show recording state
      };
    
      recognition.onresult = (event) => {
        let interimText = "";
        let finalText = "";
    
        for (let i = 0; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalText += transcript + " "; // ✅ Store final results
          } else {
            interimText += transcript + " "; // ✅ Show interim results live
          }
        }
    
        setInput(finalText + interimText); // ✅ Update text area dynamically
      };
    
      recognition.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
      };
    
      recognition.onend = () => {
        setIsRecording(false); // ✅ Reset recording state
        recognitionRef.current = null; // ✅ Clear ref when done
      };
    
      recognition.start(); // ✅ Start recording
  }

  // 9️⃣ Function to resize textarea dynamically
  const resizeTextarea = () => {
        const textarea = document.getElementById("trinity-textarea");
        if (textarea) {
        textarea.style.height = "auto"; // ✅ Reset height first
        textarea.style.height = `${textarea.scrollHeight}px`; // ✅ Expand based on content
        }
  };

  // 🔟 Function to trigger onComplete post message edits
  const handleUpdateCompletion = (finalizedData) => {
    onComplete(finalizedData); // ✅ Trigger the update
    setIsEditing(false)
    setUserResponses({});
    // setSummaryGenerated(false);
    setUpdateCompleted(true); // 05 Feb 2025 ✅ Mark update as completed

    setMessages((prev) => [
        // ...prev,
        { role: "assistant", content: "✅ Your imprint has been updated successfully!" },
        { role: "assistant", content: "Would you like to start a new session or close the chat?" }
    ]);
  };

  // Trigger resize when input updates
  useEffect(() => {
    resizeTextarea();
  }, [input]);

  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
  }

  return (
    <div className="flex flex-col">
      {/* Chat Messages */}
      {/* ✅ Progress Bar */}
      <div className="flex justify-start w-full items-center">
      <div className="w-full bg-gray-200 rounded-full h-2 mt-2">
            <div
                className="bg-orange-600 h-2 rounded-full transition-all duration-300"
                style={{ width: `${(currentStep / (promptFlow.length - 1)) * 100}%` }}
                ></div>
            </div>
            <p className="text-[9px] text-gray-500 text-center mt-2 m-1 p-1 text-nowrap">
                {Math.round((currentStep / (promptFlow.length - 1)) * 100)}% Completed
            </p>
      </div>
      
      
      <div className="flex-grow overflow-y-auto p-4 space-y-4">
        {(!summaryGenerated || updateCompleted) && !isEditing && (messages?.map((msg, index) => (
          <div
            key={index}
            className={`p-2 text-xs rounded-lg ${
              msg.role === "user" ? "bg-orange-100 text-right self-end" : "bg-gray-100 text-left self-start"
            }`}
          >
            <div dangerouslySetInnerHTML={{ __html: msg.content }} />
          </div>
        )))}
      </div>

      {/* Input Field */}
      {currentStep < promptFlow.length - 1 ? (
            <form onSubmit={handleSend} className="p-4 relative border-t border-gray-200">
                <div className="relative w-full">
                  <textarea
                      id="trinity-textarea" // ✅ Give textarea an ID for direct access
                      value={input}
                      onChange={handleInputChange}
                      // placeholder={promptFlow[currentStep]?.prompt}
                      placeholder="Please provide your response here..."
                      rows={1}
                      // className="flex-grow border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-1 focus:ring-orange-500 text-xs"
                      className="w-full border border-gray-300 rounded-lg p-4 pr-20 pl-4 focus:outline-none focus:ring-1 focus:ring-orange-500 text-xs resize-none overflow-hidden"
                      disabled={loading}
                      onInput={(e) => {
                          e.target.style.height = "auto"; // ✅ Reset height first
                          e.target.style.height = `${e.target.scrollHeight}px`; // ✅ Expand based on content
                        }}
                  />
                    {/* Buttons Group (Side by Side on the Right) */}
                    <div className="absolute right-2 top-1/2 transform -translate-y-1/2 flex space-x-1">
                      {/* Microphone Button */}
                      <button
                          type="button"
                          className={`${
                          isRecording ? "bg-red-600" : "bg-lime-500"
                          } text-white p-2 rounded-full text-xs hover:bg-lime-600`}
                          onClick={handleVoiceInput}
                      >
                          {isRecording ? <StopIcon className="h-4 w-4" /> : <MicrophoneIcon className="h-4 w-4" />}
                      </button>

                      {/* Send Button */}
                      <button
                          type="submit"
                          className="bg-orange-600 text-white p-2 rounded-full text-xs hover:bg-orange-700"
                          disabled={loading}
                      >
                          <p className="text-xs">
                          {loading ? <PulseLoader color={"#fff"} /> : <PaperAirplaneIcon className="h-4 w-4" />}
                          </p>
                      </button>
                    </div>  
                </div>
            </form>
          ) :(
            !summaryGenerated && currentStep === promptFlow.length - 1 && !updateCompleted &&(
            <div className="p-4 flex justify-center">
            <button
              onClick={() => generateFinalAIResponse(userResponses)}
              className="bg-orange-600 text-white px-4 py-2 rounded-lg text-sm hover:bg-orange-700"
            >
              {loading ? <PulseLoader color={"#fff"} /> : "Yes, Please Generate My Imprint"}
            </button>
          </div>)
          )}

          {summaryGenerated && !updateCompleted &&(
            <div className="p-4">
                <h3 className="text-md font-semibold mb-2">Trinity Generated Summary</h3>
    
                {!isEditing ? (
                // 3️⃣ Show Read-Only Summary
                <>
                    {/* ✅ Show AI-Generated Title */}
                    <h2 className="text-lg font-bold mb-2">{editedTitle}</h2> 

                    {/* ✅ Show AI-Generated Summary */}
                    <div className="bg-gray-100 p-3 rounded-md text-xs">
                        {/* <div dangerouslySetInnerHTML={{ __html: messages[messages.length - 1]?.content }} /> */}
                        <div dangerouslySetInnerHTML={createMarkup(editedSummary)} />
                    </div>

                    {/* Buttons for Editing & Confirming */}
                    <div className="flex justify-center space-x-4 mt-3">
                        <button
                            onClick={() => {
                                setIsEditing(true);
                                setEditedTitle(editedTitle || ""); // ✅ Ensure title is editable
                                setEditedSummary(editedSummary || "");
                                // setEditedSummary(messages[messages.length - 1]?.content || "");
                            }}
                            className="bg-gray-600 text-white px-3 py-2 rounded text-[10px] hover:bg-gray-700"
                        >
                            ✏️ I would like to edit
                        </button>

                        <button
                            onClick={() => handleUpdateCompletion({ 
                                ...userResponses, 
                                finalizedNarrativeTitle: editedTitle, // ✅ Send title
                                finalizedNarrative: editedSummary 
                            })}
                            className="bg-orange-600 text-white px-3 py-2 rounded text-[10px] hover:bg-orange-700"
                        >
                            ✅ Looks good to me
                        </button>
                    </div>
                </>
                ) : (
                // 4️⃣ Show Editable Title & Summary
                <>
                    {/* ✅ Editable Title */}
                    <input
                        type="text"
                        value={editedTitle}
                        onChange={(e) => setEditedTitle(e.target.value)}
                        className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-1 focus:ring-orange-500 text-xs mb-2"
                        placeholder="Enter Title"
                    />

                    {/* ✅ Editable Summary */}
                    <textarea
                        value={editedSummary}
                        onChange={(e) => setEditedSummary(e.target.value)}
                        className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-1 focus:ring-orange-500 text-xs resize-y overflow-auto"
                        rows={5}
                    />

                    {/* ✅ Save & Update Button */}
                    <div className="flex justify-center space-x-4 mt-3">
                        <button
                            onClick={() => handleUpdateCompletion({ 
                                ...userResponses, 
                                finalizedNarrativeTitle: editedTitle, // ✅ Include title
                                finalizedNarrative: editedSummary 
                            })}
                            className="bg-green-600 text-white px-3 py-2 rounded text-[10px] hover:bg-green-700"
                        >
                            💾 Save & Update
                        </button>
                    </div>
                </>
                )}  
            </div>
          )}

          {/* 6️⃣ Buttons for Final Actions */}
          {summaryGenerated && updateCompleted && (
              <div className="p-4 flex justify-center space-x-4">
              <button
                  onClick={() => {
                  setMessages([]);
                  setUserResponses({});
                  setCurrentStep(0);
                  setSummaryGenerated(false);
                  setUpdateCompleted(false); // ✅ Reset update status when starting fresh
                  }}
                  className="bg-orange-600 text-white px-2 py-2 rounded text-[10px] hover:bg-orange-700"
              >
                  🔄 Start a New Session
              </button>

              <button
                  onClick={() => {
                    setIsOpen(false)
                    setMessages([]);
                    setUserResponses({});
                    setCurrentStep(0);
                    setSummaryGenerated(false);
                    setUpdateCompleted(false); // ✅ Reset update status when starting fresh
                  }}
                  className="bg-gray-600 text-white px-2 py-2 rounded text-[10px] hover:bg-gray-700"
              >
                  ❌ Close Chat
              </button>
              </div>
            )}

    </div>
  );
};

export default EtTrinityGuidedFlow;