import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import useTitle from "@/hooks/useTitle";
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic";
import PulseLoader from "react-spinners/PulseLoader";
import Modal from "@/components/common/modal/Modal";
import { useGetChallengesQuery, useDeleteChallengeMutation } from "./challengesApiSlice";

const EtDeleteChallenge = ({id, setActiveAdminView}) => {
  // First Created - 18 Aug 2024
  // Author - Atul Pandey
  // Updated - 28 Feb 2025 - updated the softSkillFunctionArea with props - { id, setActiveAdminView}
  // Updated - 28 Feb 2025 - switched from navigate to setActiveAdminView

  // Set Title
  useTitle("Delete Challenge");

  // Extract the challenge ID from the URL
  // const { id } = useParams();

  // Extract the challenge data to load the challenge details
  const { challenge } = useGetChallengesQuery("Challenge", {
    selectFromResult: ({ data }) => ({
      challenge: data?.entities[id],
    }),
  });

  // Check for returned data
  if (!challenge) {
    return <PulseLoader color={"#ea580c"} />;
  }

  // Display data
  let content;
  content = <EtDeleteChallengeForm challenge={challenge} id={id} setActiveAdminView={setActiveAdminView}/> // 28 Feb 2025 - added setActiveAdminView={setActiveAdminView}
  return content;
};

const EtDeleteChallengeForm = ({ challenge, id, setActiveAdminView }) => {
  // Set the variable for navigate
  const navigate = useNavigate();

  // Modal state for confirming deletion
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Define the mutation to delete the challenge
  const [deleteChallenge, { isLoading, isSuccess, isError, error }] = useDeleteChallengeMutation();

  // Set the form data object
  const [formData, setFormData] = useState({
    challengeName: challenge.challengeName,
  });

  // Reset form upon successful deletion
  useEffect(() => {
    if (isSuccess) {
      setFormData({
        challengeName: "",
      });

      // navigate('/challenges');
      setActiveAdminView('challenges'); // 28 Feb 2025
    }
    
    },[isSuccess])// ✅ 28 Feb 2025 - Removed `navigate` since it's no longer used.

  // Handle modal management functions
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  // Form Submit Logic
  const handleConfirm = async (e) => {
    e.preventDefault();
    handleCloseModal();

    // Initialize an object to collect the form data
    const challengeData = {
      challengeName: formData.challengeName,
      id: id,
    };

    try {
      console.log("Form submitted.");
      await deleteChallenge(challengeData).unwrap(); // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error);
    }
  };

  // Handle Cancel
  const handleCancel = () => {
    // navigate('/challenges');
    setActiveAdminView('challenges'); // 28 Feb 2025
  };

  return (
    <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
      {isLoading && <PulseLoader color={"#ea580c"} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">
          {error?.data?.message}
        </p>
      )}
      <div className="bg-white p-1 m-1 rounded">
        <p className="p-1 m-1 font-bold font-poppins text-xl">Delete Challenge</p>

        <div className="bg-gray-200 rounded m-1 p-1">
          <form
            onSubmit={handleOpenModal}
            className="m-1 p-1 text-xs font-poppins"
          >
            <div id="challengeName">
              <input
                type="text"
                name="challengeName"
                placeholder="Enter the challenge name here"
                value={formData.challengeName}
                onChange={(e) => setFormData({ ...formData, challengeName: e.target.value })}
                className="w-full rounded p-2 m-1"
                disabled
              />
            </div>

            <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Delete"}
                type={"create"}
                handleClick={handleOpenModal}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>
          </form>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleConfirm} />
    </div>
  );
};

export default EtDeleteChallenge;
