import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const userSessionIntroductionsAdapter = createEntityAdapter({})

const initialState = userSessionIntroductionsAdapter.getInitialState()

export const userSessionIntroductionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserSessionIntroductions: builder.query({
            query: () => ({
                url: '/userSessionIntroductions/getUserSessionIntroductions',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserSessionIntroductions = responseData.map(userSessionIntroduction => {
                    userSessionIntroduction.id = userSessionIntroduction._id
                    return userSessionIntroduction
                })
                return userSessionIntroductionsAdapter.setAll(initialState, loadedUserSessionIntroductions)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserSessionIntroduction', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserSessionIntroduction', id}))
                    ]
                } else {
                    return [ { type: 'UserSessionIntroduction', id: 'LIST'}]
                }
            },
        }),
        createNewUserSessionIntroduction: builder.mutation({
            query: initialUserSessionIntroduction => ({
                url: '/userSessionIntroductions/createNewUserSessionIntroduction',
                method: 'POST',
                body: {...initialUserSessionIntroduction,}
            }),
            invalidatesTags: [
                { type: 'UserSessionIntroduction', id: "LIST" }
            ] 
        }),
        updateUserSessionIntroduction: builder.mutation({
            query: initialUserSessionIntroduction => ({
                url: '/userSessionIntroductions/updateUserSessionIntroduction',
                method: 'PATCH',
                body: {...initialUserSessionIntroduction,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserSessionIntroduction', id: arg.id },
                { type: 'UserSessionIntroduction', id: 'LIST' }
            ],
        }),
        deleteUserSessionIntroduction: builder.mutation({
            query: ({id}) => ({
                url: '/userSessionIntroductions/deleteUserSessionIntroduction',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserSessionIntroduction', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserSessionIntroductionsQuery,
    useCreateNewUserSessionIntroductionMutation,
    useUpdateUserSessionIntroductionMutation,
    useDeleteUserSessionIntroductionMutation,
} = userSessionIntroductionsApiSlice

// returns the query result object
export const selectUserSessionIntroductionResult = userSessionIntroductionsApiSlice.endpoints.getUserSessionIntroductions.select()

// create memoized selector
const selectUserSessionIntroductionsData = createSelector(
    selectUserSessionIntroductionResult,
    userSessionIntroductionResult => userSessionIntroductionResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserSessionIntroductions,
    selectById: selectUserSessionIntroductionById,
    selectIds: selectUserSessionIntroductionIds,
} = userSessionIntroductionsAdapter.getSelectors(state => selectUserSessionIntroductionsData(state) ?? initialState)