import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const challengeHeadsAdapter = createEntityAdapter({})

const initialState = challengeHeadsAdapter.getInitialState()

export const challengeHeadsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getChallengeHeads: builder.query({
            query: () => ({
                url: '/challengeHeads/getChallengeHeads',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedChallengeHeads = responseData.map(challengeHead => {
                    challengeHead.id = challengeHead._id
                    return challengeHead
                })
                return challengeHeadsAdapter.setAll(initialState, loadedChallengeHeads)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'ChallengeHead', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'ChallengeHead', id}))
                    ]
                } else {
                    return [ { type: 'ChallengeHead', id: 'LIST'}]
                }
            },
        }),
        createNewChallengeHead: builder.mutation({
            query: initialChallengeHead => ({
                url: '/challengeHeads/createNewChallengeHead',
                method: 'POST',
                body: {...initialChallengeHead,}
            }),
            invalidatesTags: [
                { type: 'ChallengeHead', id: "LIST" }
            ] 
        }),
        updateChallengeHead: builder.mutation({
            query: initialChallengeHead => ({
                url: '/challengeHeads/updateChallengeHead',
                method: 'PATCH',
                body: {...initialChallengeHead,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'ChallengeHead', id: arg.id },
                { type: 'ChallengeHead', id: 'LIST' }
            ],
        }),
        deleteChallengeHead: builder.mutation({
            query: ({id}) => ({
                url: '/challengeHeads/deleteChallengeHead',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'ChallengeHead', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetChallengeHeadsQuery,
    useCreateNewChallengeHeadMutation,
    useUpdateChallengeHeadMutation,
    useDeleteChallengeHeadMutation,
} = challengeHeadsApiSlice

// returns the query result object
export const selectChallengeHeadResult = challengeHeadsApiSlice.endpoints.getChallengeHeads.select()

// create memoized selector
const selectChallengeHeadsData = createSelector(
    selectChallengeHeadResult,
    challengeHeadResult => challengeHeadResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllChallengeHeads,
    selectById: selectChallengeHeadById,
    selectIds: selectChallengeHeadIds,
} = challengeHeadsAdapter.getSelectors(state => selectChallengeHeadsData(state) ?? initialState)