import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const problemMetricTypesAdapter = createEntityAdapter({})

const initialState = problemMetricTypesAdapter.getInitialState()

export const problemMetricTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProblemMetricTypes: builder.query({
            query: () => ({
                url: '/problemMetricTypes/getProblemMetricTypes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedProblemMetricTypes = responseData.map(problemMetricType => {
                    problemMetricType.id = problemMetricType._id
                    return problemMetricType
                })
                return problemMetricTypesAdapter.setAll(initialState, loadedProblemMetricTypes)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'ProblemMetricType', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'ProblemMetricType', id}))
                    ]
                } else {
                    return [ { type: 'ProblemMetricType', id: 'LIST'}]
                }
            },
        }),
        createNewProblemMetricType: builder.mutation({
            query: initialProblemMetricType => ({
                url: '/problemMetricTypes/createNewProblemMetricType',
                method: 'POST',
                body: {...initialProblemMetricType,}
            }),
            invalidatesTags: [
                { type: 'ProblemMetricType', id: "LIST" }
            ] 
        }),
        updateProblemMetricType: builder.mutation({
            query: initialProblemMetricType => ({
                url: '/problemMetricTypes/updateProblemMetricType',
                method: 'PATCH',
                body: {...initialProblemMetricType,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'ProblemMetricType', id: arg.id },
                { type: 'ProblemMetricType', id: 'LIST' }
            ],
        }),
        deleteProblemMetricType: builder.mutation({
            query: ({id}) => ({
                url: '/problemMetricTypes/deleteProblemMetricType',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'ProblemMetricType', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetProblemMetricTypesQuery,
    useCreateNewProblemMetricTypeMutation,
    useUpdateProblemMetricTypeMutation,
    useDeleteProblemMetricTypeMutation,
} = problemMetricTypesApiSlice

// returns the query result object
export const selectProblemMetricTypeResult = problemMetricTypesApiSlice.endpoints.getProblemMetricTypes.select()

// create memoized selector
const selectProblemMetricTypesData = createSelector(
    selectProblemMetricTypeResult,
    problemMetricTypeResult => problemMetricTypeResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllProblemMetricTypes,
    selectById: selectProblemMetricTypeById,
    selectIds: selectProblemMetricTypeIds,
} = problemMetricTypesAdapter.getSelectors(state => selectProblemMetricTypesData(state) ?? initialState)