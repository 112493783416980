import React, {useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router'
import useTitle from '@/hooks/useTitle'
import EtBtnFormBasic from '@/components/common/buttons/EtBtnFormBasic'
import { useGetTrailsQuery, useDeleteTrailMutation } from './trailsApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import Modal from '@/components/common/modal/Modal'


const EtDeleteTrail = ({id, setActiveAdminView}) => {
  
    // First Created - 02 Apr 2024
    // Author - Atul Pandey
    // Updated - 01 Mar 2025 - updated the comp with props - { id, setActiveAdminView}
    // Updated - 01 Mar 2025 - switched from navigate to setActiveAdminView

    // 0 - Set the page title
    useTitle('Delete a Trail')

    // 1 - extract the trail id to be edited from the url
    // const {id} = useParams()
    //console.log('Trail id:',id)
  
    // 2 - Extract the Trail data through the useGet query
    const { trail } = useGetTrailsQuery('trailList', {
        selectFromResult: ({ data }) => ({
            trail: data?.entities[id]
        }),
    })
    //console.log('question set:',trail)

    // 3 -  Check for returned data
    if(!trail) {
        return <PulseLoader color={"#ea580c"} />
    }
  
    //4 - Send the data for deletion
  let content
  content = <EtDeleteTrailForm trail={trail} id={id} setActiveAdminView={setActiveAdminView}/> // 01 Mar 2025 - added setActiveAdminView={setActiveAdminView} 
    
  return content
}

const EtDeleteTrailForm = ({trail, id, setActiveAdminView}) => {

    // 1 - Set the navigation hook
    const navigate = useNavigate()

    // 2 - Delete Section modal
    const [isModalOpen, setIsModalOpen] = useState(false)

    // 2a - Delete trail mutation
    const [deleteTrail, {
        isLoading,
        isSuccess,
        isError,
        error,
    }] = useDeleteTrailMutation()

    // 3 - Set the data received in the component props to the local variables
    const [formData, setFormData] = useState({
        trailName: trail.trailName
    })

    // 4 -  Reset the input field and navigation based on the success state 
    useEffect(() => {
        if(isSuccess) {
            setFormData({
                trailName: ""
            })
          // navigate("/trails")
      setActiveAdminView('trails'); // 01 Mar 2025
    }
    
    },[isSuccess])// ✅ 01 Mar 2025 - Removed `navigate` since it's no longer used.

    // 5 - Form field updates / change handling

    // Modal management functions
    const handleOpenModal = () => setIsModalOpen(true)
    const handleCloseModal = () => setIsModalOpen(false)

  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }))
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }))
    }
  }

   // 6. Form Submit logic:
   const handleConfirm = async (e) => {
    e.preventDefault()
    handleCloseModal()

    // Initialize an object to collect the form data
    const trailData = {
      trailName: formData.trailName,
    }
      // No image, send other data as is
      try {
        trailData.id = id
        console.log("Form submitted.")
        await deleteTrail(trailData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      } 
  }

  const handleCancel = () => {
    // navigate("/trails")
    setActiveAdminView('trails'); // 01 Mar 2025
  }

  return (
    <div className="bg-gray-200 p-1 mx-auto my-5 rounded-lg shadow-md shadow-black">
      {isLoading && <PulseLoader color={"#ea580c"} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">
          {error?.data?.message}
        </p>
      )}
      <div className="bg-white p-1 m-1 rounded">
        <p className="p-1 m-1 font-bold font-poppins text-xl">
          Delete a Trail
        </p>

        <div className="bg-gray-200 rounded m-1 p-1">
          <form
            onSubmit={handleOpenModal}
            className="m-1 p-1 text-sm font-poppins"
          >
            <div id="trailName">
              <input
                type="text"
                name="trailName"
                placeholder="Trail Name"
                readOnly={true}
                value={formData.trailName}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Delete"}
                type={"create"}
                handleClick={handleOpenModal}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>
          </form>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleConfirm} />
    </div>
  )

}

export default EtDeleteTrail