
    import React from 'react';
    
    const EtConversationAllIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m1846.8 1904.4c0 47.719-38.684 86.402-86.402 86.402-47.715 0-86.398-38.684-86.398-86.402 0-47.715 38.684-86.398 86.398-86.398 47.719 0 86.402 38.684 86.402 86.398" fillRule="evenodd"/>
 <path d="m2181.6 1904.4c0 47.719-38.684 86.402-86.402 86.402-47.715 0-86.398-38.684-86.398-86.402 0-47.715 38.684-86.398 86.398-86.398 47.719 0 86.402 38.684 86.402 86.398" fillRule="evenodd"/>
 <path d="m1515.6 1904.4c0 47.719-38.684 86.402-86.402 86.402-47.715 0-86.398-38.684-86.398-86.402 0-47.715 38.684-86.398 86.398-86.398 47.719 0 86.402 38.684 86.402 86.398" fillRule="evenodd"/>
 <path d="m936 1882.8c0 306 244.8 558 550.8 565.2v313.2l421.2-309.6h144c313.2 0 565.2-252 565.2-565.2 0-313.2-252-565.2-565.2-565.2l-550.8-0.003906c-313.2-3.5977-565.2 252-565.2 561.6zm79.199 0c0-266.4 219.6-486 486-486h547.2c266.4 0 486 219.6 486 486 0 266.4-219.6 486-486 486h-169.2l-313.2 234v-234h-64.801c-266.4 0-486-216-486-486z" fillRule="evenodd"/>
 <path d="m954 3236.4c0-3.6016-21.602-273.6-363.6-273.6-345.6 0-363.6 273.6-363.6 273.6v79.199c0 21.602 18 39.602 39.602 39.602h644.4c21.602 0 39.602-18 39.602-39.602z" fillRule="evenodd"/>
 <path d="m579.6 2898c100.8 0 180-90 180-198s-79.199-198-180-198c-100.8 0-180 90-180 198s82.797 198 180 198z" fillRule="evenodd"/>
 <path d="m3355.2 3236.4c0-3.6016-21.602-273.6-363.6-273.6-345.6 0.003906-363.6 273.6-363.6 277.2v79.199c0 21.602 18 39.602 39.602 39.602h644.4c21.602 0 39.602-18 39.602-39.602z" fillRule="evenodd"/>
 <path d="m2984.4 2898c100.8 0 180-90 180-198s-79.199-198-180-198c-100.8 0-180 90-180 198s79.203 198 180 198z" fillRule="evenodd"/>
 <path d="m2138.4 770.4c0-3.6016-21.602-273.6-363.6-273.6-345.6 0-363.6 273.6-363.6 273.6v79.199c0 21.602 18 39.602 39.602 39.602h644.4c21.602 0 39.602-18 39.602-39.602z" fillRule="evenodd"/>
 <path d="m1767.6 432c100.8 0 180-90 180-198s-79.199-198-180-198c-100.8 0-180 90-180 198s79.199 198 180 198z" fillRule="evenodd"/>
 <path d="m1220.4 536.4c-7.1992-18-25.199-28.801-43.199-28.801-7.1992 0-14.398 0-18 3.6016-1335.6 586.8-914.4 1940.4-910.8 1954.8 7.1992 25.199 36 39.602 57.602 28.801 10.801-3.6016 21.602-10.801 28.801-21.602s7.1992-25.199 3.6016-36c0-3.6016-104.4-324-46.801-712.8 72-514.8 378-896.4 907.2-1126.8 21.598-10.797 32.398-35.996 21.598-61.199z" fillRule="evenodd"/>
 <path d="m2440.8 511.2c-7.1992-3.6016-10.801-3.6016-18-3.6016s-10.801 0-18 3.6016c-10.801 3.6016-21.602 14.398-25.199 25.199-3.6016 10.801-3.6016 25.199 0 36 3.6016 10.801 14.398 21.602 25.199 25.199 1256.4 550.8 878.4 1785.6 860.4 1836-7.1992 25.199 7.1992 50.398 28.801 57.602 25.199 7.1992 50.398-7.1992 57.602-28.801 7.1992-10.797 428.4-1364.4-910.8-1951.2z" fillRule="evenodd"/>
 <path d="m2408.4 3348c-655.2 277.2-1191.6 3.6016-1198.8 0-7.1992-3.6016-14.398-3.6016-21.602-3.6016-3.6016 0-10.801 0-14.398 3.6016-10.801 3.6016-21.602 10.801-28.801 21.602-7.1992 10.801-7.1992 25.199-3.6016 36 3.6016 10.801 10.801 21.602 21.602 28.801 10.801 7.1992 262.8 133.2 633.6 133.2 219.6 0 435.6-43.199 644.4-133.2 25.199-10.801 36-36 25.199-61.199-3.5977-25.203-32.395-36.004-57.598-25.203z" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtConversationAllIcon1SVGComp;
    