import React, { useEffect, useState } from 'react'
import { ClockIcon, BuildingOfficeIcon, HomeIcon, UserIcon } from "@heroicons/react/24/solid"
import { userProfileIcon2 } from '@/assets/img/imgAssets'
import { useGetUserPicQuery } from "@/features/users/usersApiSlice" 

// 24 Jan 2025
import { useGetCreationTypesQuery } from '@/features/creationTypes/creationTypesApiSlice'

// 26 Jan 2025
import { useGetContributionTypesQuery } from '@/features/contributionTypes/contributionTypesApiSlice'
import { useGetImpactTypesQuery } from '@/features/impactTypes/impactTypesApiSlice'
import { useGetContactTypesQuery } from '@/features/contactTypes/contactTypesApiSlice' // for conversations and gratitudes
import { useGetWinTypesQuery } from '@/features/winTypes/winTypesApiSlice'
import { useGetIntentionTypesQuery } from '@/features/intentionTypes/intentionTypesApiSlice'
import { useGetUserChallengeTypesQuery } from '@/features/userChallengeTypes/userChallengeTypesApiSlice'

import EtIlluminateActionsIcon1SVGComp from '@/assets/img/icons/svgComp/EtIlluminateActionsIcon1SVGComp'
import EtIlluminateConversationsIcon1SVGComp from '@/assets/img/icons/svgComp/EtIlluminateConversationsIcon1SVGComp'
import EtIlluminateChallengesIcon1SVGComp from '@/assets/img/icons/svgComp/EtIlluminateChallengesIcon1SVGComp'
import EtIlluminateContributionsIcon1SVGComp from '@/assets/img/icons/svgComp/EtIlluminateContributionsIcon1SVGComp'
import EtIlluminateImpactsIcon1SVGComp from '@/assets/img/icons/svgComp/EtIlluminateImpactsIcon1SVGComp'
import EtIlluminateWinsIcon1SVGComp from '@/assets/img/icons/svgComp/EtIlluminateWinsIcon1SVGComp'
import EtIlluminateCreationsIcon1SVGComp from '@/assets/img/icons/svgComp/EtIlluminateCreationsIcon1SVGComp'
import EtIlluminateIntentionsIcon1SVGComp from '@/assets/img/icons/svgComp/EtIlluminateIntentionsIcon1SVGComp'
import EtIlluminateGratitudesIcon1SVGComp from '@/assets/img/icons/svgComp/EtIlluminateGratitudesIcon1SVGComp'

// 23 Jan 2025
import EtIlluminateThisWeekIcon1SVGComp from '@/assets/img/icons/svgComp/EtIlluminateThisWeekIcon1SVGComp'
import EtIlluminateThisMonthIcon1SVGComp from '@/assets/img/icons/svgComp/EtIlluminateThisMonthIcon1SVGComp'

// 24 Jan 2025
import EtImprintPurposeNameIcon1SVGComp from '@/assets/img/icons/svgComp/EtImprintPurposeNameIcon1SVGComp'

import { createSvgComponentFromBase64 } from '@/utils/createSvgComponentFromBase64'


const EtIlluminateYear = ({ userId, userInfo, dataSelf, dataOrg }) => {
    // First Created - 18 Jan 2025
    // Author - Atul Pandey 
    // Updated - 19 Jan 2025 - Data from backend
    // Updated - 22 Jan 2025 - refined to count non "Lagged!" actions as accomplishments
    // Updated - 22 Jan 2025 - show / toggle views by week for org and self
    // Updated - 23 Jan 2025 - show / toggle views by month for org and self
    // Updated - 24 Jan 2025 - cleaned up multiple show / toggeles with a switch Tab approach
    // Updated - 24 Jan 2025 - added creations data - creation types
    // Updated - 26 Jan 2025 - added conversations data - contact types
    // Updated - 26 Jan 2025 - added contributions data - contribution types
    // Updated - 26 Jan 2025 - added impacts data - impact types
    // Updated - 26 Jan 2025 - added wins data - win types
    // Updated - 26 Jan 2025 - added challenges data - challenge types
    // Updated - 26 Jan 2025 - added gratitudes data - gratitude types
    // Updated - 26 Jan 2025 - added intentions data - intention types

    // 0 - Get data from the prop
    console.log('yearly accomplishment data', dataSelf)
    // console.log('yearly org accomplishment data', dataOrg)

    // 1 - State variables for magic board views -- 24 Jan 2025
    
    const [selfPanelTab, setSelfPanelTab] = useState('Me'); 
    const [leftPanelTab, setLeftPanelTab] = useState('home'); 
    const [rightPanelTab, setRightPanelTab] = useState('orgHome');
        
    // 2a1 - Get Types Data 
    const { data: creationTypesData} = useGetCreationTypesQuery()
    const { data: contributionTypesData} = useGetContributionTypesQuery() // 26 Jan 2025
    const { data: impactTypesData} = useGetImpactTypesQuery() // 26 Jan 2025
    const { data: contactTypesData} = useGetContactTypesQuery() // 26 Jan 2025
    const { data: winTypesData} = useGetWinTypesQuery() // 26 Jan 2025
    const { data: userChallengeTypesData} = useGetUserChallengeTypesQuery() // 26 Jan 2025
    const { data: intentionTypesData} = useGetIntentionTypesQuery() // 26 Jan 2025

    const extractEntitiesFromAdapter = (dataAdapter) => {
        if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
        return dataAdapter.ids.map((id) => dataAdapter.entities[id]);
      };
    
      const creationTypes = extractEntitiesFromAdapter(creationTypesData);
      const contributionTypes = extractEntitiesFromAdapter(contributionTypesData);
      const impactTypes = extractEntitiesFromAdapter(impactTypesData);
      const contactTypes = extractEntitiesFromAdapter(contactTypesData);
      const winTypes = extractEntitiesFromAdapter(winTypesData);
      const userChallengeTypes = extractEntitiesFromAdapter(userChallengeTypesData);
      const intentionTypes = extractEntitiesFromAdapter(intentionTypesData);
    //   console.log('creationTypes: ', creationTypes)
    //   console.log('contributionTypes: ', contributionTypes)
    //   console.log('impactTypes: ', impactTypes)
    //   console.log('contactTypes: ', contactTypes)
    //   console.log('winTypes: ', winTypes)
    //   console.log('userChallengeTypes: ', userChallengeTypes)
      console.log('intentionTypes: ', intentionTypes)

    
    // 2a2 - Calculate counts for each area by aggregating data from all elements in the array
    const calculateTotalCount = (data, key) => {
        return data?.reduce((total, item) => {
            const arrayData = item[key] || [];
            // 25 Jan 2025
            if (key === 'userCreationWABNotes') {
                // For creations, count the length of userCreationRecords inside each note
                return total + arrayData.reduce((sum, note) => {
                    const creationRecords = note?.userCreationWABNoteId?.userCreationRecords || [];
                    return sum + creationRecords.length;
                }, 0);
            }
            return total + arrayData.length;
        }, 0);
    };

    // 2a3 - Helper function to calculate counts for "Lagged!" actions -- 22 Jan 2025
    const calculateLaggedCount = (data, key) => {
        return data?.reduce((total, item) => {
            const arrayData = item[key] || [];
            const laggedCount = arrayData.filter(action => action.userActionProgressBadge === "Lagged!").length;
            return total + laggedCount;
        }, 0);
    };

    // 2b - Extract creations data as a separate array
    const extractCreations = (data) => {
        return data?.flatMap(item => {
            const arrayData = item['userCreationWABNotes'] || [];
            return arrayData.flatMap(note => note?.userCreationWABNoteId?.userCreationRecords || []);
        });
    };

    // 2c - Extract contributions data as a separate array -- 26 Jan 2025
    const extractConversations = (data) => {
        return data?.flatMap(item => {
            const conversationNotes = item?.userConversationNotes || [];
            return conversationNotes.flatMap(note => {
                const allNotes = note?.userConversationNoteId?.userConversationAllNotes || [];
                const contactNotes = note?.userConversationNoteId?.userConversationContactNotes || [];
                return [...allNotes, ...contactNotes]; // Combine both arrays
            });
        });
    };

    // 2d - Extract contributions data as a separate array -- 26 Jan 2025
    const extractContributions = (data) => {
        return data?.flatMap(item => {
            const contributionNotes = item?.userContributionNotes || [];
            return contributionNotes.flatMap(note => {
                const allNotes = note?.userContributionNoteId?.userContributionAllNotes || [];
                const contactNotes = note?.userContributionNoteId?.userContributionContactNotes || [];
                return [...allNotes, ...contactNotes]; // Combine both arrays
            });
        });
    };

    // 2e - Extract impacts data as a separate array -- 26 Jan 2025
    const extractImpacts = (data) => {
        return data?.flatMap(item => {
            const impactNotes = item?.userImpactNotes || [];
            return impactNotes.flatMap(note => {
                const allNotes = note?.userImpactNoteId?.userImpactAllNotes || [];
                const contactNotes = note?.userImpactNoteId?.userImpactContactNotes || [];
                return [...allNotes, ...contactNotes]; // Combine both arrays
            });
        });
    };

    // 2f - Extract wins data as a separate array -- 26 Jan 2025
    const extractWins = (data) => {
        return data?.flatMap(item => {
            const winNotes = item?.userWinNotes || [];
            return winNotes.flatMap(note => {
                const allNotes = note?.userWinNoteId?.userWinAllNotes || [];
                const contactNotes = note?.userWinNoteId?.userWinContactNotes || [];
                return [...allNotes, ...contactNotes]; // Combine both arrays
            });
        });
    };

    // 2g - Extract userChallenges data as a separate array -- 26 Jan 2025
    const extractUserChallenges = (data) => {
        return data?.flatMap(item => {
            const userChallengeNotes = item?.userChallengeWABNotes || [];
            return userChallengeNotes.flatMap(note => {
                const allNotes = note?.userChallengeWABNoteId?.userChallengeWABAllNotes || [];
                const contactNotes = note?.userChallengeWABNoteId?.userChallengeWABContactNotes || [];
                return [...allNotes, ...contactNotes]; // Combine both arrays
            });
        });
    };

    // 2h - Extract gratitudes data as a separate array -- 26 Jan 2025
    const extractGratitudes = (data) => {
        return data?.flatMap(item => {
            const gratitudeNotes = item?.userGratitudeNotes || [];
            return gratitudeNotes.flatMap(note => {
                const allNotes = note?.userGratitudeNoteId?.userGratitudeAllNotes || [];
                const contactNotes = note?.userGratitudeNoteId?.userGratitudeContactNotes || [];
                return [...allNotes, ...contactNotes]; // Combine both arrays
            });
        });
    };

    // 2i - Extract intentions data as a separate array -- 26 Jan 2025
    const extractIntentions = (data) => {
        return data?.flatMap(item => {
            const intentionNotes = item?.userIntentionNotes || [];
            return intentionNotes.flatMap(note => {
                const allNotes = note?.userIntentionNoteId?.userIntentionAllNotes || [];
                const contactNotes = note?.userIntentionNoteId?.userIntentionContactNotes || [];
                return [...allNotes, ...contactNotes]; // Combine both arrays
            });
        });
    };


    const creationsSelf = extractCreations(dataSelf);
    const contributionsSelf = extractContributions(dataSelf); // 26 Jan 2025
    const impactsSelf = extractImpacts(dataSelf); // 26 Jan 2025
    const conversationsSelf = extractConversations(dataSelf); // 26 Jan 2025
    const winsSelf = extractWins(dataSelf); // 26 Jan 2025
    const userChallengesSelf = extractUserChallenges(dataSelf); // 26 Jan 2025
    const gratitudesSelf = extractGratitudes(dataSelf); // 26 Jan 2025
    const intentionsSelf = extractIntentions(dataSelf); // 26 Jan 2025

    const creationsOrg = extractCreations(dataOrg);
    const contributionsOrg = extractContributions(dataOrg); // 26 Jan 2025
    const impactsOrg = extractImpacts(dataOrg); // 26 Jan 2025
    const conversationsOrg = extractConversations(dataOrg); // 26 Jan 2025
    const winsOrg = extractWins(dataOrg); // 26 Jan 2025
    const userChallengesOrg = extractUserChallenges(dataOrg); // 26 Jan 2025
    const gratitudesOrg = extractGratitudes(dataOrg); // 26 Jan 2025
    const intentionsOrg = extractIntentions(dataOrg); // 26 Jan 2025



    // console.log('creationsSelf Year', creationsSelf)
    // console.log('contributionsSelf Year', contributionsSelf)
    // console.log('impactsSelf Year', impactsSelf)
    // console.log('contactsSelf Year', contactsSelf)
    // console.log('conversationsSelf Year', conversationsSelf)
    // console.log('winsSelf Year', winsSelf)
    // console.log('userChallengesSelf Year', userChallengesSelf)
    // console.log('gratitudesSelf Year', gratitudesSelf)
    // console.log('intentionsSelf Year', intentionsSelf)

   // 2c1 - Calculate counts for self data
   const actionsCountSelf = calculateTotalCount(dataSelf, 'userAccomplishmentActions');
//    const challengesCountSelf = calculateTotalCount(dataSelf, 'userChallengeWABNotes');
   const challengesCountSelf = userChallengesSelf?.length || 0;
   const creationsCountSelf = calculateTotalCount(dataSelf, 'userCreationWABNotes');
//    const conversationsCountSelf = calculateTotalCount(dataSelf, 'userConversationNotes');
   const conversationsCountSelf = conversationsSelf?.length || 0;
//    const contributionsCountSelf = calculateTotalCount(dataSelf, 'userContributionNotes');
   const contributionsCountSelf = contributionsSelf?.length || 0
//    const gratitudesCountSelf = calculateTotalCount(dataSelf, 'userGratitudeNotes');
   const gratitudesCountSelf = gratitudesSelf?.length || 0;
//    const impactsCountSelf = calculateTotalCount(dataSelf, 'userImpactNotes');
   const impactsCountSelf = impactsSelf?.length || 0
//    const intentionsCountSelf = calculateTotalCount(dataSelf, 'userIntentionNotes');
   const intentionsCountSelf = intentionsSelf?.length || 0;
//    const winsCountSelf = calculateTotalCount(dataSelf, 'userWinNotes');
   const winsCountSelf = winsSelf?.length || 0;

   // 2c2 - Calculate counts for "Lagged!" actions in self data -- 22 Jan 2025
   const laggedActionsCountSelf = calculateLaggedCount(dataSelf, 'userAccomplishmentActions');
    
   // 2c3 - Calculate counts for Non "Lagged!" actions in self data -- 22 Jan 2025
   const accomplishedActionsCountSelf = actionsCountSelf - laggedActionsCountSelf || 0

   // 2d1 - Calculate counts for org data
   const actionsCountOrg = calculateTotalCount(dataOrg, 'userAccomplishmentActions');
   //    const challengesCountSelf = calculateTotalCount(dataSelf, 'userChallengeWABNotes');
   const challengesCountOrg = userChallengesOrg?.length || 0;
   const creationsCountOrg = calculateTotalCount(dataOrg, 'userCreationWABNotes');
//    const conversationsCountOrg = calculateTotalCount(dataOrg, 'userConversationNotes');
   const conversationsCountOrg = conversationsOrg?.length || 0;
//    const contributionsCountOrg = calculateTotalCount(dataOrg, 'userContributionNotes');
   const contributionsCountOrg = contributionsOrg?.length || 0
//    const gratitudesCountOrg = calculateTotalCount(dataOrg, 'userGratitudeNotes');
   const gratitudesCountOrg = gratitudesOrg?.length || 0;
//    const impactsCountOrg = calculateTotalCount(dataOrg, 'userImpactNotes');
   const impactsCountOrg = impactsOrg?.length || 0
//    const intentionsCountOrg = calculateTotalCount(dataOrg, 'userIntentionNotes');
   const intentionsCountOrg = intentionsOrg?.length || 0;
//    const winsCountOrg = calculateTotalCount(dataOrg, 'userWinNotes');
   const winsCountOrg = winsOrg?.length || 0;

   // 2d2 - Calculate counts for "Lagged!" actions in org data -- 22 Jan 2025
   const laggedActionsCountOrg = calculateLaggedCount(dataOrg, 'userAccomplishmentActions');

   // 2d3 - Calculate counts for Non "Lagged!" actions in self data -- 22 Jan 2025
   const accomplishedActionsCountOrg = actionsCountOrg - laggedActionsCountOrg || 0

   // 2e - Illumination data for self and org
   const illuminateYearDataSelf = [
       { area: "Actions", count: accomplishedActionsCountSelf, pic: EtIlluminateActionsIcon1SVGComp },
       { area: "Conversations", count: conversationsCountSelf, pic: EtIlluminateConversationsIcon1SVGComp },
       { area: "Creations", count: creationsCountSelf, pic: EtIlluminateCreationsIcon1SVGComp },
       { area: "Contributions", count: contributionsCountSelf, pic: EtIlluminateContributionsIcon1SVGComp },
       { area: "Impacts", count: impactsCountSelf, pic: EtIlluminateImpactsIcon1SVGComp },
       { area: "Wins", count: winsCountSelf, pic: EtIlluminateWinsIcon1SVGComp },
       { area: "Challenges", count: challengesCountSelf, pic: EtIlluminateChallengesIcon1SVGComp },
       { area: "Intentions", count: intentionsCountSelf, pic: EtIlluminateIntentionsIcon1SVGComp },
       { area: "Gratitudes", count: gratitudesCountSelf, pic: EtIlluminateGratitudesIcon1SVGComp },
   ];

   const illuminateYearDataOrg = [
       { area: "Actions", count: accomplishedActionsCountOrg, pic: EtIlluminateActionsIcon1SVGComp },
       { area: "Conversations", count: conversationsCountOrg, pic: EtIlluminateConversationsIcon1SVGComp },
       { area: "Creations", count: creationsCountOrg, pic: EtIlluminateCreationsIcon1SVGComp },
       { area: "Contributions", count: contributionsCountOrg, pic: EtIlluminateContributionsIcon1SVGComp },
       { area: "Impacts", count: impactsCountOrg, pic: EtIlluminateImpactsIcon1SVGComp },
       { area: "Wins", count: winsCountOrg, pic: EtIlluminateWinsIcon1SVGComp },
       { area: "Challenges", count: challengesCountOrg, pic: EtIlluminateChallengesIcon1SVGComp },
       { area: "Intentions", count: intentionsCountOrg, pic: EtIlluminateIntentionsIcon1SVGComp },
       { area: "Gratitudes", count: gratitudesCountOrg, pic: EtIlluminateGratitudesIcon1SVGComp },
   ];

    // 2f - User Info
    // console.log('UserInfo:', userInfo)
    const username = userInfo[0]?.username
    const userName = userInfo[0]?.name
    const userOrg = userInfo[0]?.userOrgId?.organizationName
    const userRole = userInfo[0]?.userOrgRole?.orgRoleName

    // 3a - Handle tab change - 24 Jan 2025
    
    const switchSelfPanelTab = (tab) => {
        setSelfPanelTab(tab);
    };
    const switchLeftPanelTab = (tab) => {
        setLeftPanelTab(tab);
    };
    const switchRightPanelTab = (tab) => {
        setRightPanelTab(tab);
    };
    
   
    // 3b - Render content based on the active tab for the self panel
    const renderSelfPanelContent = () => {
        switch (selfPanelTab) {
            case 'Me':
                return <SelfPanel username={username} userName={userName} userRole={userRole} userOrg={userOrg} />;
            case 'purpose':
                return <SelfPurpose />
            default:
                return <SelfPanel username={username} userName={userName} userRole={userRole} userOrg={userOrg} />;
        }
    };

    // 3c - Render content based on the active tab for the left panel
    const renderLeftPanelContent = () => {
        switch (leftPanelTab) {
            case 'week':
                return <YearlyBoardByWeek data={dataSelf} />;
            case 'month':
                return <YearlyBoardByMonth data={dataSelf} />;
            case 'creations':
                    return <EtCreationTypesScorecard creationTypes={creationTypes} creations={creationsSelf} />
            case 'conversations':
                    return <EtConversationTypesScorecard contactTypes={contactTypes} conversations={conversationsSelf} />
            case 'contributions':
                    return <EtContributionTypesScorecard contributionTypes={contributionTypes} contributions={contributionsSelf} />
            case 'impacts':
                    return <EtImpactTypesScorecard impactTypes={impactTypes} impacts={impactsSelf} />
            case 'wins':
                    return <EtWinTypesScorecard winTypes={winTypes} wins={winsSelf} />
            case 'userChallenges':
                    return <EtUserChallengeTypesScorecard userChallengeTypes={userChallengeTypes} userChallenges={userChallengesSelf} />
            case 'intentions':
                    return <EtIntentionTypesScorecard intentionTypes={intentionTypes} intentions={intentionsSelf} />
            case 'gratitudes':
                    return <EtGratitudeTypesScorecard contactTypes={contactTypes} gratitudes={gratitudesSelf} />
            case 'home':
            default:
                return <YearlyBoardAll data={illuminateYearDataSelf} />;
        }
    };

    // 3d - Render content based on the active tab for the right panel
    const renderRightPanelContent = () => {
        switch (rightPanelTab) {
            case 'orgWeek':
                return <YearlyBoardByWeek data={dataOrg} />;
            case 'orgMonth':
                return <YearlyBoardByMonth data={dataOrg} />;
            case 'orgCreations':
                return <EtCreationTypesScorecard creationTypes={creationTypes} creations={creationsOrg} />
            case 'orgConversations':
                 return <EtConversationTypesScorecard contactTypes={contactTypes} conversations={conversationsOrg} />
            case 'orgContributions':
                return <EtContributionTypesScorecard contributionTypes={contributionTypes} contributions={contributionsOrg} />
            case 'orgImpacts':
                return <EtImpactTypesScorecard impactTypes={impactTypes} impacts={impactsOrg} />
            case 'orgWins':
                return <EtWinTypesScorecard winTypes={winTypes} wins={winsOrg} />
            case 'orgUserChallenges':
                return <EtUserChallengeTypesScorecard userChallengeTypes={userChallengeTypes} userChallenges={userChallengesOrg} />
            case 'orgIntentions':
                return <EtIntentionTypesScorecard intentionTypes={intentionTypes} intentions={intentionsOrg} />
            case 'orgGratitudes':
                return <EtGratitudeTypesScorecard contactTypes={contactTypes} gratitudes={gratitudesOrg} />
            case 'orgHome':
            default:
                return <YearlyBoardAll data={illuminateYearDataOrg} />;      
        }
    };

    return (
        
        <div>
            <div className='grid grid-cols-3 justify-start items-start m-1 p-1 border rounded'>
                <div className='col-span-1'>
                
                    <div className='m-1 p-1'>
                        <div className='flex justify-center items-center'>
                            <p className='m-1 p-1 font-bold text-sm text-gray-400'>Me</p>
                        </div>
                    <div className='flex justify-start items-center my-1'>
                        
                        <div 
                            onClick={() => switchSelfPanelTab('Me')}
                            className={`flex flex-col justify-center items-center cursor-pointer mx-1 text-[8px] ${
                                selfPanelTab === 'Me' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <UserIcon className={`h-4 w-4 ${selfPanelTab === 'Me' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* <p>Me</p> */}
                        </div>

                        <div 
                            onClick={() => switchSelfPanelTab('purpose')}
                            className={`flex flex-col justify-center items-center cursor-pointer mx-1 text-[8px] ${
                                selfPanelTab === 'purpose' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtImprintPurposeNameIcon1SVGComp className={`h-4 w-4 ${selfPanelTab === 'purpose' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* <p>Purpose</p> */}
                        </div>
                    </div>
                    {renderSelfPanelContent()}
                    </div>
                </div>
                <div className='col-span-1 w-full font-poppins m-1 p-1'>
                    <div className='flex justify-center items-center'>
                        <p className='m-1 p-1 font-bold text-sm text-gray-400'>My Magic board</p>
                    </div>
                        
                    <div className="flex justify-start items-center my-1">
                        <div 
                            onClick={() => switchLeftPanelTab('home')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                leftPanelTab === 'home' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <HomeIcon className={`h-4 w-4 ${leftPanelTab === 'home' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Home
                            </div>
                            
                        </div>
                        <div 
                           onClick={() => switchLeftPanelTab('week')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                leftPanelTab === 'week' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateThisWeekIcon1SVGComp className={`h-4 w-4 ${leftPanelTab === 'week' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Week
                            </div>
                        </div>
                        <div 
                            onClick={() => switchLeftPanelTab('month')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                leftPanelTab === 'month' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateThisMonthIcon1SVGComp className={`h-4 w-4 ${leftPanelTab === 'month' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Month
                            </div>
                        </div>    
                        <div 
                            onClick={() => switchLeftPanelTab('conversations')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                leftPanelTab === 'conversations' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateConversationsIcon1SVGComp className={`h-5 w-5 ${leftPanelTab === 'conversations' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Conversations
                            </div>
                        </div>    
                        <div 
                            onClick={() => switchLeftPanelTab('creations')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                leftPanelTab === 'creations' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateCreationsIcon1SVGComp className={`h-4 w-4 ${leftPanelTab === 'creations' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Creations
                            </div>
                        </div>    
                        <div 
                            onClick={() => switchLeftPanelTab('contributions')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                leftPanelTab === 'contributions' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateContributionsIcon1SVGComp className={`h-4 w-4 ${leftPanelTab === 'contributions' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Contributions
                            </div>
                        </div>    
                        <div 
                            onClick={() => switchLeftPanelTab('impacts')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                leftPanelTab === 'impacts' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateImpactsIcon1SVGComp className={`h-4 w-4 ${leftPanelTab === 'impacts' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Impacts
                            </div>
                        </div>    
                        <div 
                            onClick={() => switchLeftPanelTab('wins')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                leftPanelTab === 'wins' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateWinsIcon1SVGComp className={`h-4 w-4 ${leftPanelTab === 'wins' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Wins
                            </div>
                        </div>    
                        <div 
                            onClick={() => switchLeftPanelTab('userChallenges')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                leftPanelTab === 'userChallenges' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateChallengesIcon1SVGComp className={`h-4 w-4 ${leftPanelTab === 'userChallenges' ? 'text-orange-600' : 'text-gray-400'}`}/>
                           {/* Tooltip */}
                           <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Challenges
                            </div>
                        </div>    
                        <div 
                            onClick={() => switchLeftPanelTab('intentions')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                leftPanelTab === 'intentions' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateIntentionsIcon1SVGComp className={`h-4 w-4 ${leftPanelTab === 'intentions' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Intentions
                            </div>
                        </div>    
                        <div 
                            onClick={() => switchLeftPanelTab('gratitudes')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                leftPanelTab === 'gratitudes' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateGratitudesIcon1SVGComp className={`h-4 w-4 ${leftPanelTab === 'gratitudes' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Gratitudes
                            </div>
                        </div>    
                           
                    </div>
                    {renderLeftPanelContent()}
                                              
                </div>
                <div className='col-span-1 w-full font-poppins m-1 p-1'>
                    <div className='flex justify-center items-center'>
                        <p className='m-1 p-1 font-bold text-sm text-gray-400'>My Org's Magic board</p>
                    </div>
                    <div className="flex justify-start items-center my-1">
                    <div 
                            onClick={() => switchRightPanelTab('orgHome')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                rightPanelTab === 'orgHome' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <HomeIcon className={`h-4 w-4 ${rightPanelTab === 'orgHome' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Home
                            </div>
                            
                        </div>
                        <div 
                           onClick={() => switchRightPanelTab('orgWeek')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                rightPanelTab === 'orgWeek' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateThisWeekIcon1SVGComp className={`h-4 w-4 ${rightPanelTab === 'orgWeek' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Week
                            </div>
                        </div>
                        <div 
                            onClick={() => switchRightPanelTab('orgMonth')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                rightPanelTab === 'orgMonth' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateThisMonthIcon1SVGComp className={`h-4 w-4 ${rightPanelTab === 'orgMonth' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Month
                            </div>
                        </div>    
                        <div 
                            onClick={() => switchRightPanelTab('orgConversations')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                rightPanelTab === 'orgConversations' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateConversationsIcon1SVGComp className={`h-5 w-5 ${rightPanelTab === 'orgConversations' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Conversations
                            </div>
                        </div>    
                        <div 
                            onClick={() => switchRightPanelTab('orgCreations')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                rightPanelTab === 'orgCreations' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateCreationsIcon1SVGComp className={`h-4 w-4 ${rightPanelTab === 'orgCreations' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Creations
                            </div>
                        </div>    
                        <div 
                            onClick={() => switchRightPanelTab('orgContributions')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                rightPanelTab === 'orgContributions' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateContributionsIcon1SVGComp className={`h-4 w-4 ${rightPanelTab === 'orgContributions' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Contributions
                            </div>
                        </div>    
                        <div 
                            onClick={() => switchRightPanelTab('orgImpacts')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                rightPanelTab === 'orgImpacts' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateImpactsIcon1SVGComp className={`h-4 w-4 ${rightPanelTab === 'orgImpacts' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Impacts
                            </div>
                        </div>    
                        <div 
                            onClick={() => switchRightPanelTab('orgWins')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                rightPanelTab === 'orgWins' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateWinsIcon1SVGComp className={`h-4 w-4 ${rightPanelTab === 'orgWins' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Wins
                            </div>
                        </div>    
                        <div 
                            onClick={() => switchRightPanelTab('orgUserChallenges')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                rightPanelTab === 'orgUserChallenges' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateChallengesIcon1SVGComp className={`h-4 w-4 ${rightPanelTab === 'orgUserChallenges' ? 'text-orange-600' : 'text-gray-400'}`}/>
                           {/* Tooltip */}
                           <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Challenges
                            </div>
                        </div>    
                        <div 
                            onClick={() => switchRightPanelTab('orgIntentions')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                rightPanelTab === 'orgIntentions' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateIntentionsIcon1SVGComp className={`h-4 w-4 ${rightPanelTab === 'orgIntentions' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Intentions
                            </div>
                        </div>    
                        <div 
                            onClick={() => switchRightPanelTab('orgGratitudes')}
                            className={`relative group cursor-pointer mx-1 text-[8px] ${
                                rightPanelTab === 'orgGratitudes' ? 'font-bold text-orange-600' : 'text-gray-400'
                            }`}
                        >
                            <EtIlluminateGratitudesIcon1SVGComp className={`h-4 w-4 ${rightPanelTab === 'orgGratitudes' ? 'text-orange-600' : 'text-gray-400'}`}/>
                            {/* Tooltip */}
                            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-[9px] rounded py-1 px-2 z-10">
                                Gratitudes
                            </div>
                        </div>   
                    </div>
                    {renderRightPanelContent()}
                </div>
            </div>
        </div>
    )
}

const SelfPanel = ({username, userName, userRole, userOrg}) => {
    // First Created - 24 Jan 2025
    // Author - Atul Pandey  
    
    return(
        <div className=''>
            <UserFrame username={username} defaultUserPic={userProfileIcon2} />
            <div className='flex justify-center items-center bg-black shadow'>
                <p className='m-1 p-1 font-bold text-orange-600'>{userName} <span className='text-gray-200'>|</span></p>
                <p className='mx-1 text-gray-400'>{userRole},</p>
                <p className='text-gray-600'>{userOrg}</p>
            </div>
        </div>
    )
}

const SelfPurpose = ()=>{
    // First Created - 24 Jan 2025
    // Author - Atul Pandey 

    return(
        <div className='flex justify-center items-center h-5/6 bg-black rounded'>
            <p className='text-orange-600 text-2xl text-center mx-1 px-1'>Keep solving client problems and driving greater value for them</p>
        </div>
    )

}

const YearlyBoardAll = ({data=[]}) => {
    // First Created - 18 Jan 2025
    // Author - Atul Pandey   

    
    // 0. Validate incoming data
    console.log('incoming yearly Self Data', data)
    
    // 1. Calculate the grid size based on the number of illuminationAreas
    const calculateGridSize = (numberOfIlluminationAreas) => {
        if (numberOfIlluminationAreas <= 1) return 1;
        if (numberOfIlluminationAreas <= 4) return 2;
        if (numberOfIlluminationAreas <= 9) return 3;
        if (numberOfIlluminationAreas <= 16) return 4;
        return 4; // Default to 4 if more than 16 illumination areas
    };
    
    const gridSize = calculateGridSize(data?.length);

    // 2. Function to get the appropriate color class based on area count
    const getColorForAreaCount = (count) => {
        return count > 0 ? "bg-etpx-5" : "bg-black"; // Light up the pixel if count > 0, else keep it black
    };

    const getTextColorForScore = (count) => {
        return count >0 ? 'text-white' : 'text-gray-900'; // Default color if none of the conditions match
    };

    const getTextColorForTitle = (count) => {
        return count >0 ? 'text-black' : 'text-gray-700'; // Default color if none of the conditions match
    };

    // 3 - Function to get the appropriate color class based on section score
    const getColorForScore = (score) => {
        if (score >= 0 && score < 0.9) return "bg-etpx-0"
        if (score >= 0.9 && score < 1.9) return "bg-etpx-1"
        if (score >= 1.9 && score < 2.9) return "bg-etpx-2"
        if (score >= 2.9 && score < 3.9) return "bg-etpx-3"
        if (score >= 3.9 && score < 4.9) return "bg-etpx-4"
        return "bg-etpx-5" // For score >= 4.9
    }

    // 4 - generate suqre tiles for each area
    const squares = data?.map((area, areaIndex) => {
    
        const AreaIcon = area?.pic; 
        const areaName = area?.area;
        const areaCount = area?.count; // Get area count or default to 0
        const colorClass = getColorForAreaCount(areaCount);
        const textColorClass = getTextColorForScore(areaCount)
        const textTitleColorClass = getTextColorForTitle(areaCount)

        return(
            <div key={areaIndex} className={`aspect-[98/100] rounded border m-[1px] border-black ${colorClass}`}>
                <div className="flex flex-col justify-between items-center h-full p-2">

                 <div>
                    <AreaIcon className={`h-10 w-10 ${textColorClass}`} />
                 </div>   
                
                <div className={`rounded-full h-6 w-6 border flex justify-center items-center ${areaCount >0 ? 'border-white' : 'border-none'}`}><p className={`${textColorClass} text-center font-bold`}>{areaCount}</p></div>
                <p className={`text-[9px]  ${textTitleColorClass} text-center`}>{areaName}</p>
                
                </div>
            </div>
        )
    })


    return(
        <div className={`grid grid-cols-${gridSize} aspect-square rounded bg-gradient-to-r from-black to-black via-etpx-bg mx-auto p-[2px]`}>
      {squares}
      </div>
    )
}

const YearlyBoardByWeek = ({data=[]}) => {
    // First Created - 22 Jan 2025
    // Author - Atul Pandey   
    // Purpose - Generate 56 tiles for the grid
    // Updated - 23 Jan 2025 - refined the tiles based on incoming data
    
    // 1 - Helper function to determine if a week should be lit up
    const shouldLightUp = (weekData) => {
        const keysToCheck = [
            "userAccomplishmentActions",
            "userChallengeWABNotes",
            "userCreationWABNotes",
            "userContributionWABNotes",
            "userConversationWABNotes",
            "userGratitudeNotes",
            "userImpactNotes",
            "userIntentionNotes",
            "userWinNotes",
        ];

        return keysToCheck.some((key) => (weekData[key]?.length || 0) > 0);
    };

    // 2 - Accumulate reports for the same weekStarting - 23 Jan 2025
    const accumulatedData = Object.values(
        data.reduce((acc, report) => {
            const week = report.weekStarting;
            if (!acc[week]) {
                acc[week] = { ...report };
            } else {
                const keysToCheck = [
                    "userAccomplishmentActions",
                    "userChallengeWABNotes",
                    "userCreationWABNotes",
                    "userContributionWABNotes",
                    "userConversationWABNotes",
                    "userGratitudeNotes",
                    "userImpactNotes",
                    "userIntentionNotes",
                    "userWinNotes",
                ];
                keysToCheck.forEach((key) => {
                    acc[week][key] = [
                        ...(acc[week][key] || []),
                        ...(report[key] || []),
                    ];
                });
            }
            return acc;
        }, {})
    );
    
    // 3 - Sort the data array by weekStarting date
    // const sortedData = [...data].sort((a, b) => new Date(a.weekStarting) - new Date(b.weekStarting));
    const sortedData = accumulatedData.sort((a, b) => new Date(a.weekStarting) - new Date(b.weekStarting));
    
    
    // 3 - Generate grid tiles with data
    const totalTiles = 56; // 7 days * 8 rows

    // 4 - Placeholder data for unused tiles
    const unusedTilesStart = 52; // Last 4 tiles will be unused
    const gridTiles = Array.from({ length: totalTiles }, (_, index) => {
        const isUnused = index >= unusedTilesStart;
        const weekData = sortedData[index] || null;
        const isLitUp = weekData ? shouldLightUp(weekData) : false;
        return {
            id: index,
            isUnused: isUnused,
            isLitUp: isLitUp,
            weekNumber: weekData ? `W${index + 1}` : null,
        };
    });

    return (
        <div className="grid grid-cols-8 aspect-square m-[1px] bg-gradient-to-r from-gray-900 to-gray-800 rounded p-1">
            {gridTiles?.map((tile) => (
                <div
                    key={tile.id}
                    className={`aspect-[6/7] border border-gray-900 rounded flex justify-center items-center 
                    ${tile.isUnused ? 'bg-black' : tile.isLitUp ? 'bg-etpx-5': 'bg-black'}`}
                >
                    {!tile.isUnused && tile.weekNumber && (
                        <p className="text-xs text-white font-bold">{tile.weekNumber}</p>
                    )}
                    {!tile.isUnused && !tile.weekNumber &&(
                        <p className="text-xs text-gray-800 font-bold">W{tile.id + 1}</p>
                    )}
                </div>
            ))}
        </div>
    );
};

const YearlyBoardByMonth = ({data=[]}) => {
    // First Created - 22 Jan 2025
    // Author - Atul Pandey   
    // Purpose - Generate 56 tiles for the grid
    // Updated - 23 Jan 2025 - refined the tiles based on incoming data
    
    // 3 - Generate grid tiles with data
    const totalTiles = 12; // 7 days * 8 rows

    // 4 - Placeholder data for unused tiles
    const unusedTilesStart = 12; // Last 4 tiles will be unused
    const gridTiles = Array.from({ length: totalTiles }, (_, index) => {
        const isUnused = index >= unusedTilesStart;
        return {
            id: index,
            isUnused: isUnused,
            
        };
    });

    return (
        <div className="grid grid-cols-4 aspect-square m-[1px] bg-gradient-to-r from-gray-900 to-gray-800 rounded p-1">
            {gridTiles?.map((tile) => (
                <div
                    key={tile.id}
                    className={`aspect-[132/180] border border-gray-900 rounded flex justify-center items-center 
                    ${tile.isUnused ? 'bg-black' : 'bg-black'}`}
                >
                    {!tile.isUnused && !tile.weekNumber &&(
                        <p className="text-xs text-gray-800 font-bold">M{tile.id + 1}</p>
                    )}
                </div>
            ))}
        </div>
    );
};

const UserFrame = ({ username, defaultUserPic }) => {
  const { data: userPic, isFetching: isFetchingUserPic } =
    useGetUserPicQuery(username)

  return (
    // <div className="flex justify-center items-center rounded-t cursor-pointer border-white bg-gradient-to-r from-orange-600 to-orange-400 shadow-lg shadow-black">
    <div className="flex justify-center items-center rounded-t cursor-pointer border-white bg-black shadow-lg shadow-black"> 
      {/* Display user picture or loading indicator*/}
      {isFetchingUserPic ? (
        <div>Loading...</div>
      ) : userPic ? (
        <img src={userPic} alt="User" className="h-1/2 w-3/4" />
      ) : (
        <img src={defaultUserPic} alt="User" className="" />
      )}
    </div>
  )
}

const EtCreationTypesScorecard = ({ creationTypes=[], creations = [] }) => {

    // 0. Get the weeklyCreationData
    const creationsData = extractCreationTypesData(creationTypes, creations)
    // console.log('Weekly CreationData', creationsData)
    
    // 1. Calculate the grid size based on the number of creation types
    const calculateGridSize = (numberOfCreationTypes) => {
        if (numberOfCreationTypes <= 1) return 1;
        if (numberOfCreationTypes <= 4) return 2;
        if (numberOfCreationTypes <= 9) return 3;
        if (numberOfCreationTypes <= 16) return 4;
        return 4; // Default to 4 if more than 16 creation types
    };
    
    const gridSize = calculateGridSize(creationTypes.length);
    
    // 2. Function to get the appropriate color class based on creation count
    const getColorForCreationCount = (count) => {
        return count > 0 ? "bg-etpx-5" : "bg-black"; // Light up the pixel if count > 0, else keep it black
    };
    
    const getTextColorForScore = (count) => {
        return count >0 ? 'text-white' : 'text-gray-900'; // Default color if none of the conditions match
        };
    
    const getTextColorForTitle = (count) => {
        return count >0 ? 'text-black' : 'text-gray-700'; // Default color if none of the conditions match
        };
    
    // 3. Generate square divs for each creation type
    const squares = creationTypes?.map((type, index) => {
        const creationData = creationsData?.find(data => data.creationTypeName === type?.creationTypeName); // Find matching creation data
        const pic = type?.pic; // Icon for the creation type
        const MyIcon = createSvgComponentFromBase64(pic); // 07 Jul 2024
        const creationTypeName = type?.creationTypeName;
        const creationCount = creationData ? creationData.creationCount : 0; // Get creation count or default to 0
        const colorClass = getColorForCreationCount(creationCount);
        const textColorClass = getTextColorForScore(creationCount)
        const textTitleColorClass = getTextColorForTitle(creationCount)
    
        return (
        <div key={index} className={`aspect-[98/100] rounded border m-[1px] border-black ${colorClass}`}>
            <div className="flex flex-col justify-between items-center h-full p-2">
            
            {/* <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 md:h-8 md:w-8 ${textColorClass}`}/> */}
            <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 ${textColorClass}`}/>
            
            <div className={`rounded-full h-6 w-6 border flex justify-center items-center ${creationCount >0 ? 'border-white' : 'border-none'}`}><p className={`${textColorClass} text-center font-bold`}>{creationCount}</p></div>
            <p className={`text-[7px]  ${textTitleColorClass} text-center`}>{creationTypeName}</p>
            
            
            </div>
        </div>
        );
    });
    
    // 4. Return the grid of squares
    return (
        <div className={`grid grid-cols-${gridSize} aspect-[98/100] rounded bg-gradient-to-r from-black to-black via-etpx-bg mx-auto p-[2px]`}>
        {squares}
        </div>
    );
};

const extractCreationTypesData = (creationTypes=[], creations) => {
// Create a map to store the count of creations per creation type
const creationCountMap = {};
// console.log('creations in extractCreationTypesData', creations)

// Populate the map with creation counts from the creations array
creations?.forEach(creation => {
    const creationTypeId = creation.userCreationId?.userCreationType?._id;
    if (creationTypeId) {
    creationCountMap[creationTypeId] = (creationCountMap[creationTypeId] || 0) + 1;
    }
});

// Build the weeklyCreationData array
const weeklyCreationData = creationTypes?.map(type => ({
    creationTypeName: type.creationTypeName,
    pic: type.pic, // Assuming pic holds the icon data for each creation type
    creationCount: creationCountMap[type._id] || 0 // If no creations, set to 0
}));

return weeklyCreationData;
};

const EtContributionTypesScorecard = ({ contributionTypes=[], contributions = [] }) => {

    // 0. Get the weeklyContributionData
    const contributionsData = extractContributionTypesData(contributionTypes, contributions)
    // console.log('Weekly ContributionData', contributionsData)
    
    // 1. Calculate the grid size based on the number of contribution types
    const calculateGridSize = (numberOfContributionTypes) => {
        if (numberOfContributionTypes <= 1) return 1;
        if (numberOfContributionTypes <= 4) return 2;
        if (numberOfContributionTypes <= 9) return 3;
        if (numberOfContributionTypes <= 16) return 4;
        return 4; // Default to 4 if more than 16 contribution types
    };
    
    const gridSize = calculateGridSize(contributionTypes.length);
    
    // 2. Function to get the appropriate color class based on contribution count
    const getColorForContributionCount = (count) => {
        return count > 0 ? "bg-etpx-5" : "bg-black"; // Light up the pixel if count > 0, else keep it black
    };
    
    const getTextColorForScore = (count) => {
        return count >0 ? 'text-white' : 'text-gray-900'; // Default color if none of the conditions match
        };
    
    const getTextColorForTitle = (count) => {
        return count >0 ? 'text-black' : 'text-gray-700'; // Default color if none of the conditions match
        };
    
    // 3. Generate square divs for each contribution type
    const squares = contributionTypes?.map((type, index) => {
        const contributionData = contributionsData?.find(data => data.contributionTypeName === type?.contributionTypeName); // Find matching contribution data
        const pic = type?.pic; // Icon for the contribution type
        const MyIcon = createSvgComponentFromBase64(pic); // 07 Jul 2024
        const contributionTypeName = type?.contributionTypeName;
        const contributionCount = contributionData ? contributionData.contributionCount : 0; // Get contribution count or default to 0
        const colorClass = getColorForContributionCount(contributionCount);
        const textColorClass = getTextColorForScore(contributionCount)
        const textTitleColorClass = getTextColorForTitle(contributionCount)
    
        return (
        <div key={index} className={`aspect-[98/100] rounded border m-[1px] border-black ${colorClass}`}>
            <div className="flex flex-col justify-between items-center h-full p-2">
            
            {/* <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 md:h-8 md:w-8 ${textColorClass}`}/> */}
            <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 ${textColorClass}`}/>
            
            <div className={`rounded-full h-6 w-6 border flex justify-center items-center ${contributionCount >0 ? 'border-white' : 'border-none'}`}><p className={`${textColorClass} text-center font-bold`}>{contributionCount}</p></div>
            <p className={`text-[7px]  ${textTitleColorClass} text-center`}>{contributionTypeName}</p>
            
            
            </div>
        </div>
        );
    });
    
    // 4. Return the grid of squares
    return (
        <div className={`grid grid-cols-${gridSize} aspect-[98/100] rounded bg-gradient-to-r from-black to-black via-etpx-bg mx-auto p-[2px]`}>
        {squares}
        </div>
    );
};

const extractContributionTypesData = (contributionTypes=[], contributions) => {
// Create a map to store the count of contributions per contribution type
const contributionCountMap = {};
// console.log('contributions in extractContributionTypesData', contributions)

// Populate the map with contribution counts from the contributions array
contributions?.forEach(contribution => {
    const contributionTypeId = contribution.userContributionTypeId?._id;
    if (contributionTypeId) {
    contributionCountMap[contributionTypeId] = (contributionCountMap[contributionTypeId] || 0) + 1;
    }
});

// Build the weeklyContributionData array
const weeklyContributionData = contributionTypes?.map(type => ({
    contributionTypeName: type.contributionTypeName,
    pic: type.pic, // Assuming pic holds the icon data for each contribution type
    contributionCount: contributionCountMap[type._id] || 0 // If no contributions, set to 0
}));

return weeklyContributionData;
};

const EtConversationTypesScorecard = ({ contactTypes=[], conversations = [] }) => {

    // 0. Get the weeklyConversationData
    const conversationsData = extractConversationTypesData(contactTypes, conversations)
    // console.log('Weekly ConversationData', conversationsData)
    
    // 1. Calculate the grid size based on the number of conversation types
    const calculateGridSize = (numberOfContactTypes) => {
        if (numberOfContactTypes <= 1) return 1;
        if (numberOfContactTypes <= 4) return 2;
        if (numberOfContactTypes <= 9) return 3;
        if (numberOfContactTypes <= 16) return 4;
        return 4; // Default to 4 if more than 16 conversation types
    };
    
    const gridSize = calculateGridSize(contactTypes.length);
    
    // 2. Function to get the appropriate color class based on conversation count
    const getColorForConversationCount = (count) => {
        return count > 0 ? "bg-etpx-5" : "bg-black"; // Light up the pixel if count > 0, else keep it black
    };
    
    const getTextColorForScore = (count) => {
        return count >0 ? 'text-white' : 'text-gray-900'; // Default color if none of the conditions match
        };
    
    const getTextColorForTitle = (count) => {
        return count >0 ? 'text-black' : 'text-gray-700'; // Default color if none of the conditions match
        };
    
    // 3. Generate square divs for each conversation type
    const squares = contactTypes?.map((type, index) => {
        const conversationData = conversationsData?.find(data => data.contactTypeName === type?.contactTypeName); // Find matching conversation data
        const pic = type?.pic; // Icon for the conversation type
        const MyIcon = createSvgComponentFromBase64(pic); // 07 Jul 2024
        const contactTypeName = type?.contactTypeName;
        const conversationCount = conversationData ? conversationData.conversationCount : 0; // Get conversation count or default to 0
        const colorClass = getColorForConversationCount(conversationCount);
        const textColorClass = getTextColorForScore(conversationCount)
        const textTitleColorClass = getTextColorForTitle(conversationCount)
    
        return (
        <div key={index} className={`aspect-[98/100] rounded border m-[1px] border-black ${colorClass}`}>
            <div className="flex flex-col justify-between items-center h-full p-2">
            
            {/* <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 md:h-8 md:w-8 ${textColorClass}`}/> */}
            <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 ${textColorClass}`}/>
            
            <div className={`rounded-full h-6 w-6 border flex justify-center items-center ${conversationCount >0 ? 'border-white' : 'border-none'}`}><p className={`${textColorClass} text-center font-bold`}>{conversationCount}</p></div>
            <p className={`text-[7px]  ${textTitleColorClass} text-center`}>{contactTypeName}</p>
            
            
            </div>
        </div>
        );
    });
    
    // 4. Return the grid of squares
    return (
        <div className={`grid grid-cols-${gridSize} aspect-[98/100] rounded bg-gradient-to-r from-black to-black via-etpx-bg mx-auto p-[2px]`}>
        {squares}
        </div>
    );
};

const extractConversationTypesData = (contactTypes=[], conversations) => {
// Create a map to store the count of conversations per conversation type
const conversationCountMap = {};
// console.log('conversations in extractContactTypesData', conversations)

// Populate the map with conversation counts from the conversations array
conversations?.forEach(conversation => {
    const contactTypeId = conversation.userContactId?.contactTypeId?._id;
    if (contactTypeId) {
    conversationCountMap[contactTypeId] = (conversationCountMap[contactTypeId] || 0) + 1;
    }
});

// Build the weeklyConversationData array
const weeklyConversationData = contactTypes?.map(type => ({
    contactTypeName: type.contactTypeName,
    pic: type.pic, // Assuming pic holds the icon data for each conversation type
    conversationCount: conversationCountMap[type._id] || 0 // If no conversations, set to 0
}));
// console.log('weeklyConversationData', weeklyConversationData)

return weeklyConversationData;
};

const EtImpactTypesScorecard = ({ impactTypes=[], impacts = [] }) => {

    // 0. Get the weeklyImpactData
    const impactsData = extractImpactTypesData(impactTypes, impacts)
    // console.log('Weekly ImpactData', impactsData)
    
    // 1. Calculate the grid size based on the number of impact types
    const calculateGridSize = (numberOfImpactTypes) => {
        if (numberOfImpactTypes <= 1) return 1;
        if (numberOfImpactTypes <= 4) return 2;
        if (numberOfImpactTypes <= 9) return 3;
        if (numberOfImpactTypes <= 16) return 4;
        return 4; // Default to 4 if more than 16 impact types
    };
    
    const gridSize = calculateGridSize(impactTypes.length);
    
    // 2. Function to get the appropriate color class based on impact count
    const getColorForImpactCount = (count) => {
        return count > 0 ? "bg-etpx-5" : "bg-black"; // Light up the pixel if count > 0, else keep it black
    };
    
    const getTextColorForScore = (count) => {
        return count >0 ? 'text-white' : 'text-gray-900'; // Default color if none of the conditions match
        };
    
    const getTextColorForTitle = (count) => {
        return count >0 ? 'text-black' : 'text-gray-700'; // Default color if none of the conditions match
        };
    
    // 3. Generate square divs for each impact type
    const squares = impactTypes?.map((type, index) => {
        const impactData = impactsData?.find(data => data.impactTypeName === type?.impactTypeName); // Find matching impact data
        const pic = type?.pic; // Icon for the impact type
        const MyIcon = createSvgComponentFromBase64(pic); // 07 Jul 2024
        const impactTypeName = type?.impactTypeName;
        const impactCount = impactData ? impactData.impactCount : 0; // Get impact count or default to 0
        const colorClass = getColorForImpactCount(impactCount);
        const textColorClass = getTextColorForScore(impactCount)
        const textTitleColorClass = getTextColorForTitle(impactCount)
    
        return (
        <div key={index} className={`aspect-[98/100] rounded border m-[1px] border-black ${colorClass}`}>
            <div className="flex flex-col justify-between items-center h-full p-2">
            
            {/* <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 md:h-8 md:w-8 ${textColorClass}`}/> */}
            <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 ${textColorClass}`}/>
            
            <div className={`rounded-full h-6 w-6 border flex justify-center items-center ${impactCount >0 ? 'border-white' : 'border-none'}`}><p className={`${textColorClass} text-center font-bold`}>{impactCount}</p></div>
            <p className={`text-[7px]  ${textTitleColorClass} text-center`}>{impactTypeName}</p>
            
            
            </div>
        </div>
        );
    });
    
    // 4. Return the grid of squares
    return (
        <div className={`grid grid-cols-${gridSize} aspect-[98/100] rounded bg-gradient-to-r from-black to-black via-etpx-bg mx-auto p-[2px]`}>
        {squares}
        </div>
    );
};

const extractImpactTypesData = (impactTypes=[], impacts) => {
// Create a map to store the count of impacts per impact type
const impactCountMap = {};
// console.log('impacts in extractImpactTypesData', impacts)

// Populate the map with impact counts from the impacts array
impacts?.forEach(impact => {
    const impactTypeId = impact.userImpactTypeId?._id;
    if (impactTypeId) {
    impactCountMap[impactTypeId] = (impactCountMap[impactTypeId] || 0) + 1;
    }
});

// Build the weeklyImpactData array
const weeklyImpactData = impactTypes?.map(type => ({
    impactTypeName: type.impactTypeName,
    pic: type.pic, // Assuming pic holds the icon data for each impact type
    impactCount: impactCountMap[type._id] || 0 // If no impacts, set to 0
}));

return weeklyImpactData;
};

const EtWinTypesScorecard = ({ winTypes=[], wins = [] }) => {

    // 0. Get the weeklyWinData
    const winsData = extractWinTypesData(winTypes, wins)
    // console.log('Weekly WinData', winsData)
    
    // 1. Calculate the grid size based on the number of win types
    const calculateGridSize = (numberOfWinTypes) => {
        if (numberOfWinTypes <= 1) return 1;
        if (numberOfWinTypes <= 4) return 2;
        if (numberOfWinTypes <= 9) return 3;
        if (numberOfWinTypes <= 16) return 4;
        return 4; // Default to 4 if more than 16 win types
    };
    
    const gridSize = calculateGridSize(winTypes.length);
    
    // 2. Function to get the appropriate color class based on win count
    const getColorForWinCount = (count) => {
        return count > 0 ? "bg-etpx-5" : "bg-black"; // Light up the pixel if count > 0, else keep it black
    };
    
    const getTextColorForScore = (count) => {
        return count >0 ? 'text-white' : 'text-gray-900'; // Default color if none of the conditions match
        };
    
    const getTextColorForTitle = (count) => {
        return count >0 ? 'text-black' : 'text-gray-700'; // Default color if none of the conditions match
        };
    
    // 3. Generate square divs for each win type
    const squares = winTypes?.map((type, index) => {
        const winData = winsData?.find(data => data.winTypeName === type?.winTypeName); // Find matching win data
        const pic = type?.pic; // Icon for the win type
        const MyIcon = createSvgComponentFromBase64(pic); // 07 Jul 2024
        const winTypeName = type?.winTypeName;
        const winCount = winData ? winData.winCount : 0; // Get win count or default to 0
        const colorClass = getColorForWinCount(winCount);
        const textColorClass = getTextColorForScore(winCount)
        const textTitleColorClass = getTextColorForTitle(winCount)
    
        return (
        <div key={index} className={`aspect-[98/100] rounded border m-[1px] border-black ${colorClass}`}>
            <div className="flex flex-col justify-between items-center h-full p-2">
            
            {/* <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 md:h-8 md:w-8 ${textColorClass}`}/> */}
            <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 ${textColorClass}`}/>
            
            <div className={`rounded-full h-6 w-6 border flex justify-center items-center ${winCount >0 ? 'border-white' : 'border-none'}`}><p className={`${textColorClass} text-center font-bold`}>{winCount}</p></div>
            <p className={`text-[7px]  ${textTitleColorClass} text-center`}>{winTypeName}</p>
            
            
            </div>
        </div>
        );
    });
    
    // 4. Return the grid of squares
    return (
        <div className={`grid grid-cols-${gridSize} aspect-[98/100] rounded bg-gradient-to-r from-black to-black via-etpx-bg mx-auto p-[2px]`}>
        {squares}
        </div>
    );
};

const extractWinTypesData = (winTypes=[], wins) => {
// Create a map to store the count of wins per win type
const winCountMap = {};
// console.log('wins in extractWinTypesData', wins)

// Populate the map with win counts from the wins array
wins?.forEach(win => {
    const winTypeId = win.userWinTypeId?._id;
    if (winTypeId) {
    winCountMap[winTypeId] = (winCountMap[winTypeId] || 0) + 1;
    }
});

// Build the weeklyWinData array
const weeklyWinData = winTypes?.map(type => ({
    winTypeName: type.winTypeName,
    pic: type.pic, // Assuming pic holds the icon data for each win type
    winCount: winCountMap[type._id] || 0 // If no wins, set to 0
}));

return weeklyWinData;
};

const EtUserChallengeTypesScorecard = ({ userChallengeTypes=[], userChallenges = [] }) => {

    // 0. Get the weeklyUserChallengeData
    const userChallengesData = extractUserChallengeTypesData(userChallengeTypes, userChallenges)
    // console.log('Weekly UserChallengeData', userChallengesData)
    
    // 1. Calculate the grid size based on the number of userChallenge types
    const calculateGridSize = (numberOfUserChallengeTypes) => {
        if (numberOfUserChallengeTypes <= 1) return 1;
        if (numberOfUserChallengeTypes <= 4) return 2;
        if (numberOfUserChallengeTypes <= 9) return 3;
        if (numberOfUserChallengeTypes <= 16) return 4;
        return 4; // Default to 4 if more than 16 userChallenge types
    };
    
    const gridSize = calculateGridSize(userChallengeTypes.length);
    
    // 2. Function to get the appropriate color class based on userChallenge count
    const getColorForUserChallengeCount = (count) => {
        return count > 0 ? "bg-etpx-5" : "bg-black"; // Light up the pixel if count > 0, else keep it black
    };
    
    const getTextColorForScore = (count) => {
        return count >0 ? 'text-white' : 'text-gray-900'; // Default color if none of the conditions match
        };
    
    const getTextColorForTitle = (count) => {
        return count >0 ? 'text-black' : 'text-gray-700'; // Default color if none of the conditions match
        };
    
    // 3. Generate square divs for each userChallenge type
    const squares = userChallengeTypes?.map((type, index) => {
        const userChallengeData = userChallengesData?.find(data => data.userChallengeTypeName === type?.userChallengeTypeName); // Find matching userChallenge data
        const pic = type?.pic; // Icon for the userChallenge type
        const MyIcon = createSvgComponentFromBase64(pic); // 07 Jul 2024
        const userChallengeTypeName = type?.userChallengeTypeName;
        const userChallengeCount = userChallengeData ? userChallengeData.userChallengeCount : 0; // Get userChallenge count or default to 0
        const colorClass = getColorForUserChallengeCount(userChallengeCount);
        const textColorClass = getTextColorForScore(userChallengeCount)
        const textTitleColorClass = getTextColorForTitle(userChallengeCount)
    
        return (
        <div key={index} className={`aspect-[98/100] rounded border m-[1px] border-black ${colorClass}`}>
            <div className="flex flex-col justify-between items-center h-full p-2">
            
            {/* <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 md:h-8 md:w-8 ${textColorClass}`}/> */}
            <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 ${textColorClass}`}/>
            
            <div className={`rounded-full h-6 w-6 border flex justify-center items-center ${userChallengeCount >0 ? 'border-white' : 'border-none'}`}><p className={`${textColorClass} text-center font-bold`}>{userChallengeCount}</p></div>
            <p className={`text-[7px]  ${textTitleColorClass} text-center`}>{userChallengeTypeName}</p>
            
            
            </div>
        </div>
        );
    });
    
    // 4. Return the grid of squares
    return (
        <div className={`grid grid-cols-${gridSize} aspect-[98/100] rounded bg-gradient-to-r from-black to-black via-etpx-bg mx-auto p-[2px]`}>
        {squares}
        </div>
    );
};

const extractUserChallengeTypesData = (userChallengeTypes=[], userChallenges) => {
// Create a map to store the count of userChallenges per userChallenge type
const userChallengeCountMap = {};
// console.log('userChallenges in extractUserChallengeTypesData', userChallenges)

// Populate the map with userChallenge counts from the userChallenges array
userChallenges?.forEach(userChallenge => {
    const userChallengeTypeId = userChallenge.userChallengeWABTypeId?._id;
    if (userChallengeTypeId) {
    userChallengeCountMap[userChallengeTypeId] = (userChallengeCountMap[userChallengeTypeId] || 0) + 1;
    }
});

// Build the weeklyUserChallengeData array
const weeklyUserChallengeData = userChallengeTypes?.map(type => ({
    userChallengeTypeName: type.userChallengeTypeName,
    pic: type.pic, // Assuming pic holds the icon data for each userChallenge type
    userChallengeCount: userChallengeCountMap[type._id] || 0 // If no userChallenges, set to 0
}));

return weeklyUserChallengeData;
};

const EtGratitudeTypesScorecard = ({ contactTypes=[], gratitudes = [] }) => {

    // 0. Get the weeklyGratitudeData
    const gratitudesData = extractGratitudeTypesData(contactTypes, gratitudes)
    // console.log('Weekly GratitudeData', gratitudesData)
    
    // 1. Calculate the grid size based on the number of gratitude types
    const calculateGridSize = (numberOfContactTypes) => {
        if (numberOfContactTypes <= 1) return 1;
        if (numberOfContactTypes <= 4) return 2;
        if (numberOfContactTypes <= 9) return 3;
        if (numberOfContactTypes <= 16) return 4;
        return 4; // Default to 4 if more than 16 gratitude types
    };
    
    const gridSize = calculateGridSize(contactTypes.length);
    
    // 2. Function to get the appropriate color class based on gratitude count
    const getColorForGratitudeCount = (count) => {
        return count > 0 ? "bg-etpx-5" : "bg-black"; // Light up the pixel if count > 0, else keep it black
    };
    
    const getTextColorForScore = (count) => {
        return count >0 ? 'text-white' : 'text-gray-900'; // Default color if none of the conditions match
        };
    
    const getTextColorForTitle = (count) => {
        return count >0 ? 'text-black' : 'text-gray-700'; // Default color if none of the conditions match
        };
    
    // 3. Generate square divs for each gratitude type
    const squares = contactTypes?.map((type, index) => {
        const gratitudeData = gratitudesData?.find(data => data.contactTypeName === type?.contactTypeName); // Find matching gratitude data
        const pic = type?.pic; // Icon for the gratitude type
        const MyIcon = createSvgComponentFromBase64(pic); // 07 Jul 2024
        const contactTypeName = type?.contactTypeName;
        const gratitudeCount = gratitudeData ? gratitudeData.gratitudeCount : 0; // Get gratitude count or default to 0
        const colorClass = getColorForGratitudeCount(gratitudeCount);
        const textColorClass = getTextColorForScore(gratitudeCount)
        const textTitleColorClass = getTextColorForTitle(gratitudeCount)
    
        return (
        <div key={index} className={`aspect-[98/100] rounded border m-[1px] border-black ${colorClass}`}>
            <div className="flex flex-col justify-between items-center h-full p-2">
            
            {/* <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 md:h-8 md:w-8 ${textColorClass}`}/> */}
            <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 ${textColorClass}`}/>
            
            <div className={`rounded-full h-6 w-6 border flex justify-center items-center ${gratitudeCount >0 ? 'border-white' : 'border-none'}`}><p className={`${textColorClass} text-center font-bold`}>{gratitudeCount}</p></div>
            <p className={`text-[7px]  ${textTitleColorClass} text-center`}>{contactTypeName}</p>
            
            
            </div>
        </div>
        );
    });
    
    // 4. Return the grid of squares
    return (
        <div className={`grid grid-cols-${gridSize} aspect-[98/100] rounded bg-gradient-to-r from-black to-black via-etpx-bg mx-auto p-[2px]`}>
        {squares}
        </div>
    );
};

const extractGratitudeTypesData = (contactTypes=[], gratitudes) => {
// Create a map to store the count of gratitudes per gratitude type
const gratitudeCountMap = {};
// console.log('gratitudes in extractContactTypesData', gratitudes)

// Populate the map with gratitude counts from the gratitudes array
gratitudes?.forEach(gratitude => {
    const contactTypeId = gratitude.userGratitudeTargetId?._id;
    if (contactTypeId) {
    gratitudeCountMap[contactTypeId] = (gratitudeCountMap[contactTypeId] || 0) + 1;
    }
});

// Build the weeklyGratitudeData array
const weeklyGratitudeData = contactTypes?.map(type => ({
    contactTypeName: type.contactTypeName,
    pic: type.pic, // Assuming pic holds the icon data for each gratitude type
    gratitudeCount: gratitudeCountMap[type._id] || 0 // If no gratitudes, set to 0
}));
// console.log('weeklyGratitudeData', weeklyGratitudeData)

return weeklyGratitudeData;
};

const EtIntentionTypesScorecard = ({ intentionTypes=[], intentions = [] }) => {

    // 0. Get the weeklyIntentionData
    const intentionsData = extractIntentionTypesData(intentionTypes, intentions)
    // console.log('Weekly IntentionData', intentionsData)
    
    // 1. Calculate the grid size based on the number of intention types
    const calculateGridSize = (numberOfIntentionTypes) => {
        if (numberOfIntentionTypes <= 1) return 1;
        if (numberOfIntentionTypes <= 4) return 2;
        if (numberOfIntentionTypes <= 9) return 3;
        if (numberOfIntentionTypes <= 16) return 4;
        return 4; // Default to 4 if more than 16 intention types
    };
    
    const gridSize = calculateGridSize(intentionTypes.length);
    
    // 2. Function to get the appropriate color class based on intention count
    const getColorForIntentionCount = (count) => {
        return count > 0 ? "bg-etpx-5" : "bg-black"; // Light up the pixel if count > 0, else keep it black
    };
    
    const getTextColorForScore = (count) => {
        return count >0 ? 'text-white' : 'text-gray-900'; // Default color if none of the conditions match
        };
    
    const getTextColorForTitle = (count) => {
        return count >0 ? 'text-black' : 'text-gray-700'; // Default color if none of the conditions match
        };
    
    // 3. Generate square divs for each intention type
    const squares = intentionTypes?.map((type, index) => {
        const intentionData = intentionsData?.find(data => data.intentionTypeName === type?.intentionTypeName); // Find matching intention data
        const pic = type?.pic; // Icon for the intention type
        const MyIcon = createSvgComponentFromBase64(pic); // 07 Jul 2024
        const intentionTypeName = type?.intentionTypeName;
        const intentionCount = intentionData ? intentionData.intentionCount : 0; // Get intention count or default to 0
        const colorClass = getColorForIntentionCount(intentionCount);
        const textColorClass = getTextColorForScore(intentionCount)
        const textTitleColorClass = getTextColorForTitle(intentionCount)
    
        return (
        <div key={index} className={`aspect-[98/100] rounded border m-[1px] border-black ${colorClass}`}>
            <div className="flex flex-col justify-between items-center h-full p-2">
            
            {/* <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 md:h-8 md:w-8 ${textColorClass}`}/> */}
            <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 ${textColorClass}`}/>
            
            <div className={`rounded-full h-6 w-6 border flex justify-center items-center ${intentionCount >0 ? 'border-white' : 'border-none'}`}><p className={`${textColorClass} text-center font-bold`}>{intentionCount}</p></div>
            <p className={`text-[7px]  ${textTitleColorClass} text-center`}>{intentionTypeName}</p>
            
            
            </div>
        </div>
        );
    });
    
    // 4. Return the grid of squares
    return (
        <div className={`grid grid-cols-${gridSize} aspect-[98/100] rounded bg-gradient-to-r from-black to-black via-etpx-bg mx-auto p-[2px]`}>
        {squares}
        </div>
    );
};

const extractIntentionTypesData = (intentionTypes=[], intentions) => {
// Create a map to store the count of intentions per intention type
const intentionCountMap = {};
// console.log('intentions in extractIntentionTypesData', intentions)

// Populate the map with intention counts from the intentions array
intentions?.forEach(intention => {
    const intentionTypeId = intention.userIntentionTypeId?._id;
    if (intentionTypeId) {
    intentionCountMap[intentionTypeId] = (intentionCountMap[intentionTypeId] || 0) + 1;
    }
});

// Build the weeklyIntentionData array
const weeklyIntentionData = intentionTypes?.map(type => ({
    intentionTypeName: type.intentionTypeName,
    pic: type.pic, // Assuming pic holds the icon data for each intention type
    intentionCount: intentionCountMap[type._id] || 0 // If no intentions, set to 0
}));

return weeklyIntentionData;
};

export default EtIlluminateYear