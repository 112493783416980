
    import React from 'react';
    
    const EtDocSelfUserNoteIconSVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 3600" fill="currentColor">
          
 <path d="m3085.8 632.43-627.32-531.11c-37.09-31.176-83.863-48.383-132.24-48.383h-1679.9c-112.89 0-204.81 91.922-204.81 204.81v3084.5c0 112.89 91.922 204.81 204.81 204.81h2307.2c112.89 0 204.81-91.922 204.81-204.81v-2553.4c0-60.199-26.34-117.18-72.574-156.43zm-161.32 2645.8c0 19.293-15.645 34.938-34.938 34.938h-2179.3c-19.293 0-34.938-15.645-34.938-34.938v-2956.6c0-19.293 15.645-34.938 34.938-34.938h1592.5c8.2695 0 16.273 2.9414 22.574 8.2695l586.77 496.93c7.8477 6.6406 12.363 16.406 12.363 26.668z" fillRule="evenodd"/>
 <path d="m1773.1 904.89c74.465 0 143.37 23.746 199.98 63.77v-105.24c0-53.387-20.789-103.55-58.531-141.27-37.719-37.953-87.957-58.797-141.5-58.797-110.29 0-200.04 89.742-200.04 200.06v105.32c56.641-40.07 125.57-63.852 200.09-63.852z" fillRule="evenodd"/>
 <path d="m1773.1 974.77c-153.11 0-277.65 124.55-277.65 277.65 0 153.11 124.55 277.68 277.65 277.68 153.11 0 277.68-124.57 277.68-277.68 0-153.11-124.57-277.65-277.68-277.65zm54.438 399.31h-108.61l17.508-109.71c-21.234-12.258-35.25-35.473-35.25-61.754 0-39.641 32.191-71.828 72.051-71.828 39.633 0 71.812 32.188 71.812 71.828 0 26.281-14.004 49.496-35.25 61.754z" fillRule="evenodd"/>
 <path d="m745.25 356.57v2886.7h2109.4l0.003906-2408.5-564.64-478.18zm757.86 506.85c0-148.85 121.08-269.93 269.91-269.93 72.285 0 140.11 28.164 190.98 79.293 50.867 50.844 78.926 118.59 78.926 190.64v170.42c48.508 59.762 77.695 135.79 77.695 218.57 0 191.64-155.91 347.55-347.55 347.55-191.64 0-347.52-155.91-347.52-347.55 0-82.707 29.148-158.68 77.562-218.41zm-465.61 956.05h1023.3c19.293 0 34.938 15.645 34.938 34.938 0 19.293-15.645 34.938-34.938 34.938l-1023.3-0.003906c-19.293 0-34.938-15.645-34.938-34.938 0-19.289 15.645-34.934 34.938-34.934zm0 326.68h358.57c19.293 0 34.938 15.645 34.938 34.938 0 19.293-15.645 34.938-34.938 34.938h-358.57c-19.293 0-34.938-15.645-34.938-34.938 0-19.293 15.645-34.938 34.938-34.938zm0 326.66h986.84c19.293 0 34.938 15.645 34.938 34.938 0 19.293-15.645 34.938-34.938 34.938l-986.84-0.003906c-19.293 0-34.938-15.645-34.938-34.938 0-19.289 15.645-34.934 34.938-34.934zm315.68 396.53h-315.68c-19.293 0-34.938-15.645-34.938-34.938 0-19.293 15.645-34.938 34.938-34.938h315.68c19.293 0 34.938 15.645 34.938 34.938-0.003906 19.293-15.648 34.938-34.938 34.938zm1155.5 0h-947.5c-19.293 0-34.938-15.645-34.938-34.938 0-19.293 15.645-34.938 34.938-34.938h947.5c19.293 0 34.938 15.645 34.938 34.938-0.003907 19.293-15.648 34.938-34.938 34.938zm0-326.66h-259.2c-19.293 0-34.938-15.645-34.938-34.938 0-19.293 15.645-34.938 34.938-34.938h259.2c19.293 0 34.938 15.645 34.938 34.938-0.003907 19.297-15.648 34.938-34.938 34.938zm0-326.65h-917.47c-19.293 0-34.938-15.645-34.938-34.938 0-19.293 15.645-34.938 34.938-34.938h917.47c19.293 0 34.938 15.645 34.938 34.938-0.003907 19.293-15.648 34.938-34.938 34.938zm34.934-361.62c0 19.293-15.645 34.938-34.938 34.938h-231.32c-19.293 0-34.938-15.645-34.938-34.938 0-19.293 15.645-34.938 34.938-34.938h231.32c19.293 0 34.938 15.645 34.938 34.938z" fillRule="evenodd"/>

        </svg>
    );
    
    export default EtDocSelfUserNoteIconSVGComp;
    