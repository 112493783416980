import React, { useState } from "react"
import useTitle from "../../hooks/useTitle"
import { useNavigate } from 'react-router' // 31 Jul 2024
import PulseLoader from "react-spinners/PulseLoader"
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon, } from "@heroicons/react/24/outline"
import {
  FlagIcon,
  EnvelopeIcon,
  CheckBadgeIcon,
  GlobeAltIcon,
  ArrowTrendingUpIcon,
  Squares2X2Icon,
  ArrowDownOnSquareIcon,
  ArrowUpOnSquareIcon,
  UserGroupIcon,
  BuildingOffice2Icon,
  ArrowUpRightIcon,
  ArrowDownRightIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
} from "@heroicons/react/24/solid"
import HikingRoundedIcon from "@mui/icons-material/HikingRounded"
import { userProfileIcon2 } from "../../assets/img/imgAssets"
import { useGetUsersQuery, useGetUserPicQuery } from "./usersApiSlice"
import {
  useGetUserOrgSubscriptionsQuery,
  useCreateNewUserOrgSubscriptionMutation,
  useUpdateUserOrgSubscriptionMutation,
} from "../userSubscriptions/userOrgSubscriptionsApiSlice"
import { useGetOrgSubscriptionsQuery } from "../orgSubscriptions/orgSubscriptionsApiSlice"
import { useGetUserTrailSessionPlansQuery, useCreateNewUserTrailSessionPlanMutation } from "../userTrailSessions/userTrailSessionPlansApiSlice"



const EtUsersList = () => {
  // First Created - 10 May 2024
  // Author - Atul Pandey
  // Updated - 16 May 2024 - added user subscriptions
  // Updated - 30 Jul 2024 - added roleEnums
  // Updated - 31 Jul 2024 - added navigate

  // 0 - Set Page Title
  useTitle("Users List")

  // 0a - Set navigate -- 31 Jul 2024
  const navigate = useNavigate()

  // 1 - Using RTK Query hook to fetch and subscribe to the users data
  const { data: usersData, isLoading, isError, error } = useGetUsersQuery()
  // console.log("user data", usersData)
  
  // 1a - Using RTK Query hook to fetch the org subscription data -- 16 May 2024 --
  const { data: orgSubscriptionsData } = useGetOrgSubscriptionsQuery()
  //console.log("org subscription data", orgSubscriptionsData)

  // 1b - Using RTK Mutation hook to create new User Org Subscription -- 18 May 2024 --
  const { data: userOrgSubscriptionsData } = useGetUserOrgSubscriptionsQuery()
  //console.log("user org subscriptions data", userOrgSubscriptionsData)

  // 1c - Using RTK Mutation hook to create new User Org Subscription -- 16 May 2024 --
  const [createNewUserOrgSubscription, { isSuccess }] =
    useCreateNewUserOrgSubscriptionMutation()

  // 1d - Using RTK Mutation hook to create new User Org Subscription -- 16 May 2024 --
  const [updateUserOrgSubscription] =
    useUpdateUserOrgSubscriptionMutation()

  // 1e - Using RTK Mutation hook to create new User Trail Session Plan -- 25 May 2024 --
  const [createNewUserTrailSessionPlan] = useCreateNewUserTrailSessionPlanMutation()

  // 1f - Using RTK Query hook to fetch the user trail session plans -- 25 May 2024 --
  const { data: userTrailSessionPlansData } = useGetUserTrailSessionPlansQuery();
  //console.log('user trail session plan:', userTrailSessionPlansData)

  // 1g - extract role enums -- 30 Jul 2024
  const rolesEnum = usersData?.rolesEnum
  //console.log('roles enum:', rolesEnum)

  // 2a - State for toggling organization details
  const [showOrg, setShowOrg] = useState({})
  const [selectedUser, setSelectedUser] = useState(null) // 16 May 2024
  const [selectedSubscriptions, setSelectedSubscriptions] = useState({}) // 16 May 2024
  const [showSubscriptions, setShowSubscriptions] = useState({}); // 20 May 2024
  const [showOrganizations, setShowOrganizations] = useState(false) // 21 May 2024
  const [showTrails, setShowTrails] = useState({}) // 23 May 2024
  const [showUserDetails, setShowUserDetails] = useState({}) // 23 May 2024

  // 2b - Extract Users Data

  const users = usersData?.ids?.map((id) => {
    const user = usersData?.entities[id]
    const { email, name, roles, userOrgId, username } = user
    return { email, name, roles, userOrgId, username }
  })

  //console.log("users", users)

  // 2c - Filter the org subscriptions for the user's organization -- 20 May 2024 --
  const getFilteredSubscriptions = (userId, userOrgId) => {
    // console.log('userId for filtering subscriptions:', userId)
    // console.log('userOrgId for filtering subscriptions:', userOrgId)

    if (!orgSubscriptionsData && !userOrgSubscriptionsData) return [];
    
    const orgSubsForUserOrg = orgSubscriptionsData?.ids.filter(id => orgSubscriptionsData?.entities[id].orgId?._id === userOrgId?._id);
    //console.log('orgSubsFor User Org:', orgSubsForUserOrg);
    
    // Proceed without early return for userOrgSubscriptionsData
    let assignedSubs = [];
    if (userOrgSubscriptionsData && userOrgSubscriptionsData?.entities) {
        const userSubs = Object.values(userOrgSubscriptionsData?.entities).filter(userSub => 
            userSub.userId._id.toString() === userId.toString()
        );
        //console.log('userSubs:', userSubs);

        assignedSubs = userSubs.flatMap(userSub => 
            userSub.userOrgSubscriptions.map(sub => sub.orgSubscription._id?.toString())
        );
        //console.log('assigned Subs:', assignedSubs);
    }

    const filteredSubs = orgSubsForUserOrg?.filter(id => !assignedSubs.includes(id.toString()));
    //console.log('filteredSubs:', filteredSubs);

    return filteredSubs;
  };

  // 2d - filter user subscriptions
  const filteredSubscriptions = usersData?.ids.reduce((acc, userId) => {
    const user = usersData?.entities[userId];
    // console.log('user', user)
    acc[userId] = getFilteredSubscriptions(userId, user.userOrgId);
    return acc;
  }, {});

  // 2e - Get user assigned subscriptions -- 22 May 2024 --
  const getAssignedSubscriptions = (userId) => {
    if (!userOrgSubscriptionsData) return [];
    const userSubs = Object.values(userOrgSubscriptionsData?.entities).filter(
      (userSub) => userSub.userId._id.toString() === userId.toString()
    );
    return userSubs.flatMap((userSub) => userSub.userOrgSubscriptions);
  };

  // 2f - Get user trail session plans -- 25 May 2024 --
  const getUserTrailSessionPlans = (userId) => {
    if (!userTrailSessionPlansData || !userTrailSessionPlansData?.entities) return [];
    return Object.values(userTrailSessionPlansData?.entities).filter(
      (plan) => plan.userId._id.toString() === userId.toString()
    );
  };

  // 3 - Toggle function for organization details
  const handleToggleOrg = (orgId) => {
    setShowOrg((prev) => ({ ...prev, [orgId]: !prev[orgId] }))
  }

  // 3a - Handle user subscriptions
  const handleSelectSubscription = (userId, subscriptionId) => {
    setSelectedSubscriptions((prev) => ({ ...prev, [userId]: subscriptionId }))
  }

  // 3b - Handle toggle show user subscriptions -- 20 May 2024 --
  const handleToggleSubscriptions = (userId) => {
    setShowSubscriptions(prev => ({ ...prev, [userId]: !prev[userId] }));
  };

  // 3c - Function to toggle the visibility of trail details -- 23 May 2024 --
  const handleToggleTrails = (subscriptionId) => {
    setShowTrails((prev) => ({
      ...prev,
      [subscriptionId]: !prev[subscriptionId],
    }));
  };

   // 3d - Handle toggle show user details -- 23 May 2024 --
   const handleToggleUserDetails = (userId) => {
    setShowUserDetails((prev) => ({ ...prev, [userId]: !prev[userId] }));
  };

  // 4 - Grouping users by organization
  const organizations = {}
  usersData?.ids.forEach((id) => {
    const user = usersData.entities[id]
    const orgId = user.userOrgId ? user.userOrgId?._id : "noOrg"
    if (!organizations[orgId]) {
      organizations[orgId] = {
        orgName: user.userOrgId ? user.userOrgId.organizationName : "No Org",
        orgEstablishDate: user.userOrgId
          ? user.userOrgId.organizationStartedDate
          : "Long time ago...",
        users: [],
      }
    }
    organizations[orgId].users.push(user)
  })
  //console.log('organizations', organizations)

  // 5 - Function to assign individual user org subscriptions -- 16 May 2024 --
  const handleAssignSubscription = async (userId) => {
    try {
      await createNewUserOrgSubscription({
        userId,
        userOrgSubscriptions: [
          { orgSubscription: selectedSubscriptions[userId] },
        ],
      }).unwrap()
      alert("Subscription assigned successfully")
      setSelectedUser(null)
      setSelectedSubscriptions((prev) => ({ ...prev, [userId]: "" }))
    } catch (err) {
      console.error("Failed to assign subscription", err)
      alert("Failed to assign subscription")
    }
  }

  // 6 - Function to assign all user org subscriptions -- 16 May 2024 --
  const handleAssignAllSubscriptions = async (userId) => {
    try {
      const remainingSubscriptions = orgSubscriptionsData?.ids
        .filter(
          (id) =>
            !(usersData.entities[userId].userOrgSubscriptions ?? []).some(
              (sub) => sub.orgSubscription === id
            ) &&
            // Null check for userOrgId and orgId
            orgSubscriptionsData.entities[id].orgId?._id ===
              usersData.entities[userId].userOrgId?._id
        )
        .map((id) => ({ orgSubscription: id }))

      await createNewUserOrgSubscription({
        userId,
        userOrgSubscriptions: remainingSubscriptions,
      }).unwrap()
      alert("All subscriptions assigned successfully")
      setSelectedUser(null)
    } catch (err) {
      console.error("Failed to assign all subscriptions", err)
      alert("Failed to assign all subscriptions")
    }
  }

  // 7 - Function to extract user org subscriptions data -- 20 May 2024 --
  const getUserSubscriptions = (userId) => {
    if (!userOrgSubscriptionsData) return [];
    const userSubs = Object.values(userOrgSubscriptionsData?.entities).filter(userSub =>
        userSub.userId._id.toString() === userId.toString()
    );
    const userOrgSubsData = userSubs.flatMap(userSub => userSub.userOrgSubscriptions);
    //console.log('User Org Subscription Details', userOrgSubsData)
    return userOrgSubsData
    };

  // 8 - Function to remove individual user org subscriptions -- 21 May 2024 --
const handleRemoveSubscription = async (userId, subscriptionId) => {
  try {
    // console.log('request for a subscription removal received...')
    // console.log('userId:', userId)
    // console.log('subscriptionId', subscriptionId)
      const userSub = Object.values(userOrgSubscriptionsData?.entities).find(
          userSub => userSub.userId._id.toString() === userId.toString()
      );
      // console.log('user Sub for Removal', userSub)

      if (!userSub) return;

      const updatedSubscriptions = userSub.userOrgSubscriptions.filter(sub => sub.orgSubscription._id.toString() !== subscriptionId);
      // console.log('updated subscriptions for removal', updatedSubscriptions)
      await updateUserOrgSubscription({
          id: userSub._id,
          userOrgSubscriptions: updatedSubscriptions
      }).unwrap();

      alert("Subscription removed successfully");
      setSelectedSubscriptions(prev => ({ ...prev, [userId]: "" }));
  } catch (err) {
      console.error("Failed to remove subscription", err);
      alert("Failed to remove subscription");
  }
};

// 9 - Function to remove all user org subscriptions -- 21 May 2024 --
const handleRemoveAllSubscriptions = async (userId) => {
  try {
      const userSub = Object.values(userOrgSubscriptionsData?.entities).find(
          userSub => userSub.userId._id.toString() === userId.toString()
      );

      if (!userSub) return;

      await updateUserOrgSubscription({
          id: userSub._id,
          userOrgSubscriptions: []
      }).unwrap();

      alert("All subscriptions removed successfully");
      setSelectedSubscriptions(prev => ({ ...prev, [userId]: "" }));
  } catch (err) {
      console.error("Failed to remove all subscriptions", err);
      alert("Failed to remove all subscriptions");
  }
};

// 10 - Functions to determine if all subscriptions have been assigned or removed -- 23 May 2024 --
const allSubscriptionsAssigned = (userId) => {
  const filteredSubs = filteredSubscriptions[userId] || [];
  return filteredSubs.length === 0;
};

const allSubscriptionsRemoved = (userId) => {
  const userSubs = getUserSubscriptions(userId);
  return userSubs.length === 0;
};

// 11 - Function to handle session assignments -- 25 May 2024 --
const handleAssignSessions = async (userId, trailId) => {
  try {
    await createNewUserTrailSessionPlan({ userId, trailId }).unwrap();
    alert("Sessions assigned successfully");
  } catch (err) {
    console.error("Failed to assign sessions", err);
    alert("Failed to assign sessions");
  }
};

// 12 - Function to determine if all sessions are assigned -- 25 May 2024 --
  const allSessionsAssigned = (userId, trailId) => {
    console.log('checking for all sessions assigned...')
    console.log('userId:', userId)
    console.log('trailId:', trailId)
    const userTrailPlans = getUserTrailSessionPlans(userId);
    console.log('user Trail Plans:', userTrailPlans)
    const trailSessions = userTrailPlans?.filter(
      (plan) => plan.trailId._id.toString() === trailId.toString()
    );
    console.log('trail Sessions:', trailSessions)
    return trailSessions.length > 0; // Adjust this condition based on your specific needs
  };
 
  
  if (isLoading) return <PulseLoader color={"#ea580c"} />
  if (isError) return <div>Error occurred: {error?.data?.message}</div>

  return (
    <div className="bg-gray-200 p-1 xl:w-5/6 mx-auto mt-5 rounded-lg shadow-md shadow-black font-poppins">
      {/* Header bar */}
      <div className="m-1 p-1 flex justify-start items-center">

          <div
            className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white"
            onClick={() => setShowOrganizations((prev) => !prev)}
          >
            {showOrganizations ? (
              <MinusIcon className="h-5 w-5 text-orange-600" />
            ) : (
              <PlusIcon className="h-5 w-5 text-orange-600" />
            )}
          </div>
          <div>
              <h1 className="p-1 m-1 font-bold font-poppins text-lg">
                Users By Organizations
              </h1>
          </div>
          {/* Stats Bar */}
          <div className="flex justify-start items-center m-1 p-1 ">
                <div><p className='text-[10px] text-gray-400 mx-2 px-2'>Organizations:</p></div>
                  <div>
                      <BuildingOffice2Icon className='h-5 w-5 text-orange-600 m-1'/>
                  </div>
                <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50'>
                  <p className='text-orange-600 text-[10px]'>{Object.entries(organizations).length}</p>
                </div>
            </div>
          <div className="flex justify-start items-center m-1 p-1 ">
                <div><p className='text-[10px] text-gray-400 mx-2 px-2'>Users:</p></div>
                  <div>
                      <UserGroupIcon className='h-5 w-5 text-orange-600 m-1'/>
                  </div>
                <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50'>
                  <p className='text-orange-600 text-[10px]'>{usersData?.ids?.length}</p>
                </div>
            </div>
      </div>
      
      {showOrganizations && (Object.entries(organizations).map(
        ([orgId, { orgName, orgEstablishDate, users }], index) => (
          <div key={orgId + index} className="bg-white m-1 p-1 rounded">
            <div className="bg-gray-300 m-1 p-1 rounded ">
              <div className="m-1 p-1 flex justify-between items-center">
                <div className="m-1 p-1 flex justify-start items-center">
                  <div
                    className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white"
                    onClick={() => handleToggleOrg(orgId)}
                  >
                    {showOrg[orgId] ? (
                      <MinusIcon className="h-5 w-5 text-orange-600" />
                    ) : (
                      <PlusIcon className="h-5 w-5 text-orange-600" />
                    )}
                  </div>
                  <div className="w-48">
                    <h2 className="font-bold mx-2">{index+1} - {orgName}</h2>
                  </div>
                  <div className="flex justify-start items-center">
                    <p className="text-gray-500 text-xs mx-2">Established: </p>
                    <div>
                      <FlagIcon className="h-5 w-5 text-orange-600" />
                    </div>
                    <p className="font-bold mx-2">
                      {formatDate(orgEstablishDate)}
                    </p>
                  </div>
                  {/* Stats Bar */}
                  <div className="flex justify-start items-center m-1 p-1 ">
                    <div><p className='text-[10px] text-gray-400 mx-2 px-2'>Users:</p></div>
                    <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50'>
                      <p className='text-orange-600 text-[10px]'>{users?.length}</p>
                    </div>
                </div>


                </div>
              </div>
              {showOrg[orgId] && (
                <div className="bg-white m-1 p-1 rounded">
                  {users.map((user, userIndex) => (
                    <div
                      key={user.id+userIndex}
                      className="bg-gray-100 rounded m-1 p-1"
                    >
                      {/* User Pic, Name and Email */}
                      <div className="flex justify-between items-center">
                        <div className="m-1 p-1 flex justify-start items-center">

                          {/* <div><p className="font-bold mx-1 px-1">{userIndex+1}.</p></div> */}

                          <div
                              className="flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white"
                              onClick={() => handleToggleUserDetails(user.id)}
                            >
                              {showUserDetails[user.id] ? (
                                <MinusIcon className="h-5 w-5 text-orange-600" />
                              ) : (
                                <PlusIcon className="h-5 w-5 text-orange-600" />
                              )}
                            </div>
                          <div className="flex justify-start items-center m-1 p-1 w-64">
                            <User
                              username={user.username}
                              defaultUserPic={userProfileIcon2}
                            />
                            {/* <p className="text-xs m-1 p-1 font-bold text-gray-400 ">
                              Name:{" "}
                            </p> */}
                            <p className="text-xs m-1 p-1">{user.name} </p>
                          </div>
                          <div className="flex justify-start items-center m-1 p-1">
                            <div>
                              <EnvelopeIcon className="h-5 w-5 text-orange-600" />
                            </div>
                            <p className="text-xs m-1 p-1">{user.email}</p>
                          </div>
                        </div>
                        <div className='flex justify-start items-center mx-2'>
                            <div className='rounded-full flex justify-center items-center h-6 w-6 hover:bg-white'>
                                <PencilSquareIcon onClick={() => navigate(`/users/update/${user?.id}`)} className="h-4 w-4 text-orange-600 cursor-pointer" />
                            </div>
                            <div className='rounded-full flex justify-center items-center h-6 w-6 hover:bg-white'>
                                <TrashIcon onClick={() => navigate(`/users/delete/${user?.id}`)} className="h-4 w-4 text-orange-600 cursor-pointer" />
                            </div>
                        </div>
                      </div>

                      {/* User Subscriptions */}
                      {showUserDetails[user.id] && (
                      <div className="m-1 p-1 rounded bg-gray-200">
                          <div className="flex justify-start items-center m-1 p-1">
                            <div className='flex justify-center items-center cursor-pointer rounded-full h-7 w-7 hover:bg-white' onClick={() => handleToggleSubscriptions(user.id)}>
                                {showSubscriptions[user.id] ? <MinusIcon className="h-5 w-5 text-orange-600" /> : <PlusIcon className="h-5 w-5 text-orange-600" />}
                            </div>
                            
                            <div><p className="text-xs font-bold mx-1 px-1">Subscriptions</p></div>
                          </div>

                          {/* Start of Subscription Details */}
                          {showSubscriptions[user.id] && (

                            <div className="m-1 p-1 rounded bg-white">
                              <div className="m-1 p-1 rounded bg-gray-100 flex justify-between">

                              {/* Available Subscriptions */}
                               <div className="w-1/3">
                                  <div className="flex justify-start items-center m-1 p-1">
                                    <div className="m-1 p-1 rounded-full h-7 w-7 bg-gray-50 flex justify-center items-center">
                                        <Squares2X2Icon className="h-5 w-5 text-orange-600" />
                                    </div>
                                    <div><p className="text-xs font-bold">Current Subscriptions</p></div>
                                  </div>

                                  { getUserSubscriptions(user.id).length > 0 ? (
                                  getUserSubscriptions(user.id).map(sub => (
                                      <div key={sub.orgSubscription._id} className="m-1 p-1 rounded bg-gray-200 flex justify-start items-center">
                                          <div className="bg-gray-50 rounded m-1 p-1 w-full">
                                            <div className="m-1 p-1 flex justify-start items-center">
                                                <div>
                                                  <img src={sub.orgSubscription.subsPackId[0].pic} alt="" className="h-5 w-5" />
                                                </div>
                                                <div><p className="text-xs m-1 p-1">{sub.orgSubscription.subsPackId[0].subscriptionPackName}</p></div>
                                                
                                            </div>
                                            {/* Package Trails - added -- 23 May 2024-- */}
                                            
                                            <div>
                                              <div className="flex justify-start items-center">
                                              <div
                                                  className="cursor-pointer mx-1 px-1 flex justify-center items-center w-5 h-5 rounded-full hover:bg-gray-100"
                                                  onClick={() => handleToggleTrails(sub.orgSubscription._id)}
                                                >
                                                  {showTrails[sub.orgSubscription._id] ? (
                                                    <MinusIcon className="h-3 w-3 text-orange-600" />
                                                  ) : (
                                                    <PlusIcon className="h-3 w-3 text-orange-600" />
                                                  )}
                                                </div>
                                                
                                                <div><p className="py-1 text-[10px] font-bold ">Trails</p></div>
                                              </div>  
                                            </div>
                                            {/* Trail Details - Pic and Name */}
                                            {showTrails[sub.orgSubscription._id] && sub.orgSubscription?.subsPackId[0].subscriptionPackTrails?.map((trail, trailIndex) => (
                                              <div key={trail.packTrailId._id+trailIndex} >
                                                <div className="flex justify-between items-center mx-1 px-1 bg-gray-100 rounded">
                                                  <div className="flex justify-start items-center">
                                                    <div>
                                                      <p className="text-[10px] mx-1 px-1 font-bold">{trailIndex+1}.</p>
                                                    </div>
                                                    <div className="flex justify-center items-center rounded-full h-7 w-7">
                                                      <img src={trail.packTrailId.pic} alt="" className="h-5 w-5" />
                                                    </div>
                                                    <div>
                                                      <p className="text-[10px] mx-1 px-1">{trail.packTrailId.trailName}</p>
                                                    </div>
                                                  </div> 

                                                  <div
                                                    className={`rounded-full border  ${
                                                      allSessionsAssigned(user._id,trail.packTrailId._id)
                                                        ? "border-gray-400 cursor-not-allowed text-gray-400"
                                                        : "border-orange-600 hover:bg-orange-600 cursor-pointer hover:text-white text-gray-600"
                                                    }`}
                                                    onClick={() => {
                                                      if (!allSessionsAssigned(user._id, trail.packTrailId._id)) {
                                                        handleAssignSessions(user._id, trail.packTrailId._id);
                                                      }
                                                    }}
                                                    disabled={allSessionsAssigned(user._id,trail.packTrailId._id)}
                                                  >
                                                    <p className="text-[10px] px-2"> Assign Sessions</p>
                                                  </div>

                                                </div>                                   

                                              </div>
                                            ))}

                                          </div>
                                      </div>
                                  ))
                                 ) : (
                                  <div className="m-1 p-1 rounded bg-gray-200 flex justify-center items-center">
                                    <div className="m-1 p-1 bg-gray-50 rounded">
                                      <p className="text-[10px] text-gray-800 p-2">No current subscriptions assigned. Please assign subscriptions from the list of available subscriptions.</p>
                                    </div>
                                    
                                  </div>

                                  )} 
                               </div>
                               
                                {/* Assign Subscriptions - Updated 21 May 2024*/}
                                <div className="w-1/3">
                                  <div className="flex justify-start items-center m-1 p-1">
                                      <div className="m-1 p-1 rounded-full h-7 w-7 bg-gray-50 flex justify-center items-center">
                                          <ArrowDownOnSquareIcon className="h-5 w-5 text-orange-600" />
                                      </div>
                                      <div><p className="text-xs font-bold">Assign Subscriptions</p></div>
                                    </div>

                                  <div className="bg-gray-200 rounded m-1 p-1">
                                    <div className="m-1 p-1">
                                      <select
                                      className="m-1 p-1 border border-gray-300 rounded text-[10px] w-full"
                                      value={selectedSubscriptions[user.id] || ""}
                                      onChange={(e) =>
                                        handleSelectSubscription(user.id, e.target.value)
                                      }
                                    >
                                      <option value="">Select a subscription</option>
                                      {filteredSubscriptions[user.id]?.map(id => (
                                      <option key={id} value={id}>
                                        {orgSubscriptionsData?.entities[id].orgSubscriptionName}
                                      </option>
                                    ))}
                                    {filteredSubscriptions[user.id]?.length === 0 && (
                                      <option value="">No subscriptions available</option>
                                    )}
                                    </select>
                                    </div>
                                    
                                    {/* {filteredSubscriptions[user.id]?.length > 0 && ( */}
                                    
                                    <div className="flex justify-between items-center m-1 p-1">
                                      
                                        <div className="flex justify-start items-center mx-1">
                                              <div className={`m-1 p-1 rounded-full bg-gray-50 flex justify-center items-center ${allSubscriptionsAssigned(user.id) ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer text-orange-600 hover:bg-orange-600 hover:text-white'} border ${allSubscriptionsAssigned(user.id) ? 'border-gray-400' : 'border-orange-600'}`}>
                                                  <ArrowDownRightIcon className={`h-5 w-5 p-1 ${allSubscriptionsAssigned(user.id) ? 'text-gray-400' : 'text-orange-60'}`} />
                                                  
                                                  <button
                                                  className="text-[10px] mx-2 px-3 py-1 "
                                                  onClick={() => handleAssignSubscription(user.id)}
                                                  disabled={allSubscriptionsAssigned(user.id) || !selectedSubscriptions[user.id]}
                                                  >
                                                  Assign
                                                </button>
                                              </div>
                                        </div>
                                        
                                        <div className="flex justify-start items-center mx-1">
                                          <div className={`m-1 p-1 rounded-full bg-gray-50 flex justify-center items-center ${allSubscriptionsAssigned(user.id) ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer text-orange-600 hover:bg-orange-600 hover:text-white'} border ${allSubscriptionsAssigned(user.id) ? 'border-gray-400' : 'border-orange-600'}`}>
                                                  <ArrowsPointingInIcon className={`h-5 w-5 p-[2px] ${allSubscriptionsAssigned(user.id) ? 'text-gray-400' : 'text-orange-60'}`} />

                                             <button
                                              className="text-[10px] mx-2 px-3 py-1"
                                              onClick={() => handleAssignAllSubscriptions(user.id)}
                                              disabled={allSubscriptionsAssigned(user.id)}
                                              >
                                                Assign All
                                              </button>
                                            </div>
                                        
                                        </div>
                                    </div>
                                  {/* // )} */}
                                  </div>
                                </div>

                                {/* Remove Subscriptions - First Created 21 May 2024*/}
                                <div className="w-1/3">
                                  <div className="flex justify-start items-center m-1 p-1">
                                      <div className="m-1 p-1 rounded-full h-7 w-7 bg-gray-50 flex justify-center items-center">
                                          <ArrowUpOnSquareIcon className="h-5 w-5 text-orange-600" />
                                      </div>
                                      <div><p className="text-xs font-bold">Remove Subscriptions</p></div>
                                  </div>
                                  <div className="bg-gray-200 rounded m-1 p-1">
                                    <div className="m-1 p-1">
                                      <select
                                        className="m-1 p-1 border border-gray-300 rounded text-[10px] w-full"
                                        value={selectedSubscriptions[user.id]}
                                        onChange={(e) =>
                                          handleSelectSubscription(user.id, e.target.value)
                                        }
                                      >
                                        <option value="">Select a subscription</option>
                                        {getAssignedSubscriptions(user.id).map((sub) => (
                                            <option key={sub.orgSubscription._id} value={sub.orgSubscription._id}>
                                              {sub.orgSubscription.orgSubscriptionName}
                                            </option>
                                        ))}
                                        {getAssignedSubscriptions(user.id).length === 0 && (
                                          <option value="">No subscriptions available</option>
                                        )}
                                      </select>
                                    </div>
                                    
                                    {/* {getAssignedSubscriptions(user.id).length > 0 && ( */}
                                    
                                    <div className="flex justify-between items-center m-1 p-1">
                                        <div className="flex justify-start items-center mx-1">
                                          <div className={`m-1 p-1 rounded-full bg-gray-50 flex justify-center items-center ${allSubscriptionsRemoved(user.id) ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer text-orange-600 hover:bg-orange-600 hover:text-white'} border ${allSubscriptionsRemoved(user.id) ? 'border-gray-400' : 'border-orange-600'}`}>
                                            <ArrowUpRightIcon className={`h-5 w-5 p-1 ${allSubscriptionsRemoved(user.id) ? 'text-gray-400' : ''}`} />
                                            <button
                                              className="text-[10px] mx-2 px-3 py-1"
                                              onClick={() => handleRemoveSubscription(user.id, selectedSubscriptions[user.id])}
                                              disabled={allSubscriptionsRemoved(user.id) || !selectedSubscriptions[user.id]}
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        </div>
                                        <div className="flex justify-start items-center mx-1">
                                          <div className={`m-1 p-1 rounded-full bg-gray-50 flex justify-center items-center ${allSubscriptionsRemoved(user.id) ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer text-orange-600 hover:bg-orange-600 hover:text-white'} border ${allSubscriptionsRemoved(user.id) ? 'border-gray-400' : 'border-orange-600'}`}>
                                            <ArrowsPointingOutIcon className={`h-5 w-5 p-[2px] ${allSubscriptionsRemoved(user.id) ? 'text-gray-400' : ''}`} />
                                            <button
                                              className="text-[10px] mx-2 px-3 py-1"
                                              onClick={() => handleRemoveAllSubscriptions(user.id)}
                                              disabled={allSubscriptionsRemoved(user.id)}
                                            >
                                              Remove All
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    {/* )} */} 
                                  </div>
                                </div>
                              </div>
                            </div>
                          
                          )}  {/* End of Subscription Details */}
                      
                          
                      
                      </div>
                      )}
                    
                    
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )
      ))}
    </div>
  )
}

// Function to format the date
const formatDate = (dateString) => {
  if (!dateString) return "Unknown" // Handle null or undefined date
  const date = new Date(dateString)
  if (isNaN(date)) return "Unknown" // Handle invalid date

  //const options = { month: 'long', year: 'numeric' };
  const options = { year: "numeric" }
  const day = date.getDate()

  // Determine the correct ordinal indicator
  const ordinal = (n) => {
    let s = ["th", "st", "nd", "rd"],
      v = n % 100
    return s[(v - 20) % 10] || s[v] || s[0] // Only returns the suffix
  }

  return (
    <>
      {/* {day}<sup>{ordinal(day)}</sup> {new Intl.DateTimeFormat('en-GB', options).format(date)} */}
      {new Intl.DateTimeFormat("en-GB", options).format(date)}
    </>
  )
}

const User = ({ username, defaultUserPic }) => {
  const { data: userPic, isFetching: isFetchingUserPic } =
    useGetUserPicQuery(username)

  return (
    <div className="flex justify-center items-center h-12 w-12 rounded-full border border-white bg-gradient-to-r from-orange-600 to-orange-400 shadow-lg shadow-black cursor-pointer">
      {/* Display user picture or loading indicator*/}
      {isFetchingUserPic ? (
        <div>Loading...</div>
      ) : userPic ? (
        <img src={userPic} alt="User" className="h-11 w-11  rounded-full" />
      ) : (
        <img src={defaultUserPic} alt="User" className="h-10 w-10 p-1" />
      )}
    </div>
  )
}

export default EtUsersList
