
    import React from 'react';
    
    const EtEtTrinityIcon6SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3335 3763" fill="currentColor">
          <defs><clipPath id="clip0"><rect x="490" y="107" width="3335" height="3763"/></clipPath></defs><g clip-path="url(#clip0)" transform="translate(-490 -107)"><path d="M2580 1626.95C2580 1679.42 2579.26 1731.26 2577.82 1782.36L2577.58 1788 2571.42 1642.73C2532.02 950.11 2361.67 429.097 2157.5 429.097 1953.33 429.097 1782.98 950.11 1743.58 1642.73L1737.42 1788 1737.18 1782.36C1735.74 1731.26 1735 1679.42 1735 1626.95 1735 787.505 1924.16 107 2157.5 107 2390.84 107 2580 787.505 2580 1626.95Z" fillRule="evenodd"/><path d="M2158 1559C2413.16 1559 2620 1765.84 2620 2021 2620 2276.16 2413.16 2483 2158 2483 1902.84 2483 1696 2276.16 1696 2021 1696 1765.84 1902.84 1559 2158 1559Z" fillRule="evenodd"/><path d="M1612.48 1842.7C1657.9 1816.47 1703.15 1791.2 1748.11 1766.91L1753.12 1764.29 1630.43 1842.24C1050.49 2222.55 684.576 2630.47 786.638 2807.24 888.701 2984.02 1424.92 2871.09 2044.24 2559L2173.1 2491.72 2168.33 2494.75C2124.82 2521.54 2080.3 2548.09 2034.88 2574.32 1308.13 2993.9 624.425 3170.27 507.783 2968.24 391.14 2766.21 885.73 2262.29 1612.48 1842.7Z" fillRule="evenodd"/><path d="M2280.73 2574.32C2235.31 2548.09 2190.8 2521.54 2147.28 2494.75L2142.52 2491.72 2271.37 2559C2890.69 2871.09 3426.91 2984.02 3528.97 2807.24 3631.04 2630.47 3265.12 2222.55 2685.19 1842.24L2562.5 1764.29 2567.5 1766.91C2612.46 1791.2 2657.71 1816.47 2703.13 1842.7 3429.88 2262.29 3924.47 2766.21 3807.83 2968.24 3691.19 3170.27 3007.48 2993.9 2280.73 2574.32Z" fillRule="evenodd"/><text fill="#E97132" font-family="Poppins,Poppins_MSFontService,sans-serif" font-weight="700" font-size="527" transform="matrix(1 0 0 1 1339.48 3405)">trinity</text></g>
        </svg>
    );
    
    export default EtEtTrinityIcon6SVGComp;
    