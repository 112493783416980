import React, { useState, useEffect, useMemo } from "react"
import { useNavigate } from "react-router"
import useTitle from "@/hooks/useTitle"
import EtBtnFormBasic from "@/components/common/buttons/EtBtnFormBasic"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLightWithPlace from "@/utils/EtContentEditorLightWithPlace"
import { useCreateNewCapabilitiesServiceFunctionAreaTypeMutation } from "./capabilitiesServiceFunctionAreaTypesApiSlice"
import { useGetCapabilitiesServiceTypesQuery } from '../capabilitiesServiceTypes/capabilitiesServiceTypesApiSlice' // 14 Nov 2024

const EtCreateCapabilitiesServiceFunctionAreaType = ({setActiveAdminView}) => {
  // First Created - 17 Nov 2024
  // Author - Atul Pandey
  // Inherited from  capabilitiesFunctionType
  // Updated - 28 Feb 2025 - updated the serviceFunctionArea with props - { setActiveAdminView }
  // Updated - 28 Feb 2025 - switched from navigate to setActiveAdminView

  // 0 - Set Page Title
  useTitle('Create CapabilitiesServiceFunctionArea Types')
  
  // 1 - set page navigations
  const navigate = useNavigate()

  // 1b - state variables for service types
  // const [selectedFocus, setSelectedFocus] = useState("");
  // const [filteredServiceTypes, setFilteredServiceTypes] = useState([]);

  // 2a - set the mutation to create the new section data
  const[createNewCapabilitiesServiceFunctionAreaType, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewCapabilitiesServiceFunctionAreaTypeMutation()

  // 2b - Get ServiceTypes Data
  const { data: serviceTypesData } = useGetCapabilitiesServiceTypesQuery();
  const serviceTypes = useMemo(() => {
    return serviceTypesData?.ids?.map(id => serviceTypesData?.entities[id]) || [];
  }, [serviceTypesData]);
  // console.log('serviceTypes', serviceTypes)

  // 2c - populate the filteredServiceTypes based on selected focus
  // useEffect(() => {
  //   if (selectedFocus) {
  //     setFilteredServiceTypes(
  //       serviceTypes.filter((service) => service.capabilitiesServiceFocus === selectedFocus)
  //     );
  //   } else {
  //     setFilteredServiceTypes([]); // Reset if no focus is selected
  //   }
  // }, [selectedFocus, serviceTypes]);

// 3 - create the form data object
const [formData, setFormData] = useState({
  capabilitiesServiceFunctionAreaTypeName: "",
  capabilitiesServiceFunctionAreaTypeDesc: "",
  capabilitiesServiceType:"",
  aboutMe: "",
  pic: "",
})

// 4 - Reset form upon successful submission
useEffect(() => {
  if(isSuccess) {
    setFormData({
      capabilitiesServiceFunctionAreaTypeName: "",
      capabilitiesServiceFunctionAreaTypeDesc: "",
      capabilitiesServiceType: "",
      aboutMe: "",
      pic: "",
    })

   // navigate('/capabilitiesServiceFunctionAreatypes')
   setActiveAdminView('capabilitiesServiceFunctionAreaTypes'); // 28 Feb 2025
  }

},[isSuccess])// ✅ 28 Feb 2025 - Removed `navigate` since it's no longer used.

// 5 - Form field updates / change handling

const handleChange = (e) => {
  const { name, value, files } = e.target
  if (name === "pic") {
    setFormData(prevState => ({ ...prevState, pic: files[0] }))
  } else {
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }
}

// 6 - serviceFunctionArea to strip html tags from sections
const stripHtml = (html) => {
  return html.replace(/<\/?[^>]+(>|$)/g, "");
}

// 7 - Form Submit Logic
const handleSubmit = async (e) => {
e.preventDefault()

// Initialize an object to collect the form data
const capabilitiesServiceFunctionAreaTypeData = {
  capabilitiesServiceFunctionAreaTypeName: formData.capabilitiesServiceFunctionAreaTypeName,
  capabilitiesServiceFunctionAreaTypeDesc: formData.capabilitiesServiceFunctionAreaTypeDesc,
  capabilitiesServiceType: formData.capabilitiesServiceType || null, // New field for service type selection
  aboutMe: formData.aboutMe,
}

console.log('capabilitiesServiceFunctionAreaType Name:',capabilitiesServiceFunctionAreaTypeData.capabilitiesServiceFunctionAreaTypeName)
console.log('capabilitiesServiceType:',capabilitiesServiceFunctionAreaTypeData.capabilitiesServiceType)

if (formData.pic) {
  // Convert the image to a base64 string
  const reader = new FileReader()
  reader.onloadend = async () => {
    // Once conversion is done, add the base64 string to your data object
    capabilitiesServiceFunctionAreaTypeData.pic = reader.result

    // Now, send the data object to the server
    try {
      console.log("Form submitted.")
      await createNewCapabilitiesServiceFunctionAreaType(capabilitiesServiceFunctionAreaTypeData).unwrap() // Dispatch the RTK Query mutation
    } catch (error) {
      console.error(error)
    }
  }
  reader.readAsDataURL(formData.pic)
} else {
  // No image, send other data as is
  try {
    console.log("Form submitted.")
    await createNewCapabilitiesServiceFunctionAreaType(capabilitiesServiceFunctionAreaTypeData).unwrap() // Dispatch the RTK Query mutation
  } catch (error) {
    console.error(error)
  }
}
}

// 8 - Handle Cancel

const handleCancel = () => {
 // navigate("/capabilitiesServiceFunctionAreatypes")
 setActiveAdminView('capabilitiesServiceFunctionAreaTypes'); // 28 Feb 2025
}

// 9 - Handle Clear
const handleClear = () => {
  setFormData({
    capabilitiesServiceFunctionAreaTypeName: "",
    capabilitiesServiceFunctionAreaTypeDesc: "",
    capabilitiesServiceType: "", // New field for service type selection
    aboutMe: "",
    pic: "",
    })
}
return (
  <div className="bg-gray-200 p-1 mx-auto mt-5 rounded-lg shadow-md shadow-black">
    {isLoading && <PulseLoader color={"#ea580c"} />}
    {isError && (
      <p className="text-orange-700 font-semi-bold py-2">
        {error?.data?.message}
      </p>
    )}
    <div className="bg-white p-1 m-1 rounded">
      <p className="p-1 m-1 font-bold font-poppins text-xl">Add an CapabilitiesServiceFunctionArea Type</p>

      <div className="bg-gray-200 rounded m-1 p-1">
        <form
          onSubmit={handleSubmit}
          className="m-1 p-1 text-xs font-poppins"
        >

          {/* <div id="focus">
            <select
              name="focus"
              value={selectedFocus}
              onChange={(e) => {
                setSelectedFocus(e.target.value);
                setFilteredServiceTypes(
                  serviceTypes?.filter(
                    (service) => service.capabilitiesServiceFocus === e.target.value
                  ) || []
                );
              }}
              className="w-full rounded p-2 m-1"
            >
              <option value="">Select Service Focus</option>
              <option value="Business">Business</option>
              <option value="Technology">Technology</option>
            </select>
          </div> */}

          <div id="serviceType">
            <select
              name="capabilitiesServiceType"
              value={formData.capabilitiesServiceType || ""}
              onChange={(e) => setFormData({ ...formData, capabilitiesServiceType: e.target.value })}
              className="w-full rounded p-2 m-1"
              // disabled={!selectedFocus} // Disable if no focus is selected
            >
              <option value="">Select Service Type</option>
              {serviceTypes?.map((service) => (
                <option key={service._id} value={service._id}>
                  {service.capabilitiesServiceTypeName}
                </option>
              ))}
              {/* {filteredServiceTypes?.map((service) => (
                <option key={service._id} value={service._id}>
                  {service.capabilitiesServiceTypeName}
                </option>
              ))} */}
            </select>
          </div>

          <div id="capabilitiesServiceFunctionAreaTypeName">
            <input
              type="text"
              name="capabilitiesServiceFunctionAreaTypeName"
              placeholder="Enter the capabilitiesServiceFunctionArea type name here"
              value={formData.capabilitiesServiceFunctionAreaTypeName}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div className="bg-white w-full rounded p-2 m-1">
            <EtContentEditorLightWithPlace
              value={formData.capabilitiesServiceFunctionAreaTypeDesc}
              handleChange={(content) =>
                setFormData({ ...formData, capabilitiesServiceFunctionAreaTypeDesc: content })
              }
              placeholderText={
                "Write this capabilitiesServiceFunctionArea type is about or will be used for"
              }
              // toolbarId={'sectionDesc'}
            />
          </div>

          <div id="aboutMe">
            <input
              type="text"
              name="aboutMe"
              placeholder="Add something about the personality about this capabilitiesServiceFunctionArea type"
              value={formData.aboutMe}
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

          <div id="pic">
            <input
              type="file"
              name="pic"
              onChange={handleChange}
              className="w-full rounded p-2 m-1"
            />
          </div>

         <div id="capabilitiesServiceFunctionAreas" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic
              name={"Create"}
              type={"create"}
              handleClick={handleSubmit}
            />
            <EtBtnFormBasic
              name={"Clear"}
              type={"clear"}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={"Cancel"}
              type={"cancel"}
              handleClick={handleCancel}
            />
          </div>
        </form>
      </div>
    </div>
  </div>
)
}

export default EtCreateCapabilitiesServiceFunctionAreaType