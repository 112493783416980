
    import React from 'react';
    
    const EtImprintOverviewEducationIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" fill="currentColor">
          <g transform="translate(-928 -100)"><path d="M1000 199.316C989.578 199.316 973.896 197.376 957.576 188.41L957.576 191.384C957.576 193.607 958.811 195.649 960.799 196.709 975.99 204.801 990.501 206.498 999.999 206.498 1017.22 206.498 1030.85 201.155 1039.26 196.675 1041.21 195.635 1042.42 193.607 1042.42 191.385L1042.42 188.426C1033 193.595 1018.42 199.316 999.998 199.316Z" fillRule="evenodd"/><path d="M1005.19 181.204C1003.53 181.862 1001.79 182.197 1000 182.197 998.219 182.197 996.472 181.862 994.814 181.205L957.579 166.421 957.579 184.989C973.857 194.349 989.628 196.319 1000 196.319 1018.77 196.319 1033.41 190.174 1042.43 184.99L1042.43 166.421 1005.19 181.204Z" fillRule="evenodd"/><path d="M1063.64 147.536 1004.08 123.892C1001.45 122.849 998.55 122.849 995.916 123.892L936.364 147.536C934.872 148.128 933.909 149.548 933.909 151.153 933.909 152.758 934.873 154.178 936.364 154.769L995.916 178.414C997.232 178.936 998.616 179.196 999.999 179.196 1001.38 179.196 1002.77 178.934 1004.08 178.414L1052.12 159.343 1001.26 154.288C1000.44 154.205 999.838 153.473 999.916 152.653L1053.42 157.973 1053.42 190.172C1050.83 190.844 1048.9 193.181 1048.9 195.98 1048.9 197.831 1049.76 199.469 1051.08 200.573 1050.55 201.191 1050.18 201.954 1050.05 202.815L1047.44 219.771C1047.38 220.205 1047.5 220.644 1047.78 220.977 1048.07 221.31 1048.49 221.501 1048.92 221.501L1060.92 221.501C1061.36 221.501 1061.78 221.31 1062.06 220.977 1062.35 220.644 1062.47 220.205 1062.41 219.771L1059.8 202.815C1059.66 201.954 1059.29 201.191 1058.77 200.573 1060.09 199.467 1060.94 197.829 1060.94 195.979 1060.94 193.179 1059.02 190.841 1056.42 190.171L1056.42 157.633 1063.63 154.77C1065.13 154.178 1066.09 152.759 1066.09 151.154 1066.09 149.549 1065.12 148.128 1063.63 147.537Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtImprintOverviewEducationIcon1SVGComp;
    