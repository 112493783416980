import React, { useState, useEffect } from 'react'
import useTitle from "../../../hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
    PencilSquareIcon,
  } from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "../../../utils/EtContentEditorLight2"
import EtBtnFormBasic from "../../../components/common/buttons/EtBtnFormBasic"
import { useNavigate } from "react-router"

import { useCreateNewUserImprintHiddenMagicMutation } from './userImprintHiddenMagicsApiSlice'

const EtCreateUserImprintHiddenMagicComp = ({
    userId, 
    userOrgId, 
    triggeringUrl, 
    onClose
  }) => {

  // First Created - 18 Dec 2024
  // Author - Atul Pandey
  // Inherited from the EtCreateUserImprintCreationComp component
  // Updated - 19 Dec 2024 - added the main logic
  // Updated - 23 Dec 2024 - added the dates to the arrays

  // 0 - Set Page title & variables
  useTitle('User Imprint HiddenMagic')

  // 1 - set page navigations
  const navigate = useNavigate()
  
  // 2a - set the mutation to create the new user imprint hiddenMagic data
  const [createNewUserImprintHiddenMagic, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewUserImprintHiddenMagicMutation()

  // 3 - Initialize Form Data State
    const [formData, setFormData] = useState({
        userImprintHiddenMagicName: "",
        userImprintHiddenMagicDesc: "",
        userAspirations:[],
        userInspirations:[],
        userMagicMoments:[],
        userSuperPowers:[],
        userHiddenMagicSelfNotes:[],
        pic: "",
        active: true, // Default active state
    });
  
    // 4 - State variables for the editor
    const [editorKey, setEditorKey] = useState('hiddenMagicDesc-' + Date.now());
    const [editorValue, setEditorValue] = useState('');
    const [collapsedSections, setCollapsedSections] = useState({});
  
    // 5a - Convert Image to Base64
    const convertPicToBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
  
    // 5b - Handle Form Changes
    const handleChange = (e) => {
      const { name, value, files } = e.target;
      if (name === "pic") {
        setFormData((prevState) => ({ ...prevState, pic: files[0] }));
      } else {
        setFormData((prevState) => ({ ...prevState, [name]: value }));
      }
    };
  
    // 5c - Toggle show / collapse each section 
    const toggleSection = (key) => {
      setCollapsedSections((prev) => ({
        ...prev,
        [key]: !prev[key],
      }));
    };  

    // 6 - Handle form submission
    const handleSubmit = async (e) => {
      e.preventDefault();

      try {
        // Step 1: Convert the pic field to Base64 if it exists
        const picBase64 = formData.pic ? await convertPicToBase64(formData.pic) : undefined;

        // Step 2: Process and filter arrays for valid entries
        const validAspirations = formData.userAspirations.filter((aspiration) => aspiration.aspirationName.trim());
        const validInspirations = formData.userInspirations.filter((inspiration) => inspiration.inspirationName.trim());
        const validMagicMoments = formData.userMagicMoments.filter((moment) => moment.magicMomentName.trim());
        const validSuperPowers = formData.userSuperPowers.filter((power) => power.superPowerName.trim());
        const validSelfNotes = formData.userHiddenMagicSelfNotes.filter((note) => note.hiddenMagicSelfNoteText.trim());

        // Process nested notes for each array
        const processNotes = (notes) =>
          notes
            ?.filter((note) => note.text && note.text.trim())
            .map((note) => ({
              ...note,
              text: note.text.trim(),
            })) || [];

        const processedAspirations = validAspirations.map((aspiration) => ({
          ...aspiration,
          aspirationNotes: processNotes(aspiration.aspirationNotes),
        }));

        const processedInspirations = validInspirations.map((inspiration) => ({
          ...inspiration,
          inspirationNotes: processNotes(inspiration.inspirationNotes),
        }));

        const processedMagicMoments = validMagicMoments.map((moment) => ({
          ...moment,
          magicMomentNotes: processNotes(moment.magicMomentNotes),
        }));

        const processedSuperPowers = validSuperPowers.map((power) => ({
          ...power,
          superPowerNotes: processNotes(power.superPowerNotes),
        }));

        // Step 3: Construct the payload
        const payload = {
          userId,
          userOrgId,
          userImprintHiddenMagicName: formData.userImprintHiddenMagicName.trim() || undefined,
          userImprintHiddenMagicDesc: formData.userImprintHiddenMagicDesc.trim() || undefined,
          userAspirations: processedAspirations,
          userInspirations: processedInspirations,
          userMagicMoments: processedMagicMoments,
          userSuperPowers: processedSuperPowers,
          userHiddenMagicSelfNotes: validSelfNotes.map((note) => ({
            ...note,
            hiddenMagicSelfNoteText: note.hiddenMagicSelfNoteText.trim(),
          })),
          pic: picBase64 || undefined,
          active: true,
        };

        // Remove undefined keys from the payload
        Object.keys(payload).forEach((key) => payload[key] === undefined && delete payload[key]);

        // Debugging output
        console.log("Final payload for submission:", payload);

        // Step 4: Submit the payload
        const response = await createNewUserImprintHiddenMagic(payload).unwrap();
        console.log("Submission successful:", response);

        // Step 5: Reset the form upon success
        setFormData({
          userImprintHiddenMagicName: "",
          userImprintHiddenMagicDesc: "",
          userAspirations: [],
          userInspirations: [],
          userMagicMoments: [],
          userSuperPowers: [],
          userHiddenMagicSelfNotes: [],
          pic: "",
          active: true,
        });

        // Step 6: Handle navigation/closure
        if (onClose) {
          onClose();
        } else {
          navigate(triggeringUrl || "/userMagicHome");
        }
      } catch (error) {
        // Step 7: Handle errors
        console.error("Error submitting form:", error);
        // You might want to set an error state here or show a notification
      }
    };

    // 7 - Reset Form on Successful Submission
      useEffect(() => {
        if (isSuccess) {
          setFormData({
            userImprintHiddenMagicName: "",
            userImprintHiddenMagicDesc: "",
            userAspirations:[],
            userInspirations:[],
            userMagicMoments:[],
            userSuperPowers:[],
            userHiddenMagicSelfNotes:[],
            pic: "",
            active: true, // Default active state
          });
    
          if (onClose) {
            onClose();
          } else {
            navigate(triggeringUrl || "/userMagicHome");
          }
        }
      }, [isSuccess, navigate]);
    
    // 8 - Handle Clear
    const handleClear = () => {
    setFormData({
      userImprintHiddenMagicName: "",
      userImprintHiddenMagicDesc: "",
      userAspirations:[],
      userInspirations:[],
      userMagicMoments:[],
      userSuperPowers:[],
      userHiddenMagicSelfNotes:[],
      pic: "",
      active: true, // Default active state
    });
    };

    // 9 - Handle Cancel
    const handleCancel = () => {
    if (onClose) {
        onClose();
    } else {
        navigate(triggeringUrl || "/userMagicHome");
    }
    };


  return (
    <div className="mx-auto w-full m-1 p-1 bg-white rounded shadow-md shadow-black">
      {isLoading && <PulseLoader color={'#ea580c'} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">{error?.data?.message}</p>
      )}

      <p className="p-1 m-1 font-bold font-poppins text-xl">
        Create User Imprint HiddenMagic
      </p>

      <form onSubmit={handleSubmit} className="m-1 p-1 text-xs font-poppins">
        {/* 1. HiddenMagic Name */}
        <div id="hiddenMagicName" className="bg-gray-100 rounded p-1 m-1">
          <label
            htmlFor="userImprintHiddenMagicName"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
            HiddenMagic Name
          </label>
          <input
            type="text"
            name="userImprintHiddenMagicName"
            placeholder="Enter a name or title"
            value={formData.userImprintHiddenMagicName}
            onChange={handleChange}
            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
          />
        </div>

        {/* 2. HiddenMagic Description */}
        <div id="hiddenMagicDesc" className="bg-gray-100 rounded p-1 m-1">
          <label
            htmlFor="userImprintHiddenMagicDesc"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
            HiddenMagic Description
          </label>
          <EtContentEditorLight2
            value={formData.userImprintHiddenMagicDesc || ""}
            handleChange={(content) => {
              setFormData((prevState) => ({
                ...prevState,
                userImprintHiddenMagicDesc: content,
              }));
            }}
            placeholderText="Write your hidden magic description here..."
            editorKey={editorKey}
          />
        </div>
        
        {/* 3. HiddenMagic - Aspirations */}
        <div id="aspirations" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <p className="font-bold text-sm">User Aspirations</p>
            <button
              type="button"
              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
              onClick={() => {
                setFormData((prevState) => ({
                  ...prevState,
                  userAspirations: [
                    ...prevState.userAspirations,
                    {
                      aspirationName: "",
                      aspirationDesc: "",
                      aspirationNotes: [],
                      aspirationDate: new Date().toISOString(), // Initial creation date
                      aspirationUpdateDate: new Date().toISOString(), // Initial update date
                    },
                  ],
                }));
              }}
            >
              <PlusIcon className="h-4 w-4 inline" />
            </button>
          </div>

          {formData.userAspirations.map((aspiration, aspirationIndex) => (
            <div
              key={aspirationIndex}
              className="p-2 border rounded mb-2 bg-white"
            >
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={() =>
                      toggleSection(`aspiration-${aspirationIndex}`)
                    }
                    className="text-orange-600 font-bold m-1 p-1 rounded"
                  >
                    {collapsedSections[`aspiration-${aspirationIndex}`] ? (
                      <PlusIcon className="h-4 w-4 text-orange-600" />
                    ) : (
                      <MinusIcon className="h-4 w-4 text-orange-600" />
                    )}
                  </button>
                  <p className="font-bold text-sm mx-1 px-1">
                    Aspiration {aspirationIndex + 1}
                  </p>
                </div>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) => ({
                      ...prevState,
                      userAspirations: prevState.userAspirations.filter(
                        (_, i) => i !== aspirationIndex
                      ),
                    }));
                  }}
                >
                  <MinusIcon className="h-4 w-4 inline" />
                </button>
              </div>

              {!collapsedSections[`aspiration-${aspirationIndex}`] && (
                <div>
                  {/* Aspiration Name */}
                  <div className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`aspirationName-${aspirationIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Aspiration Name
                    </label>
                    <input
                      type="text"
                      name={`aspirationName-${aspirationIndex}`}
                      placeholder="Enter the aspiration name"
                      value={aspiration.aspirationName}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData((prevState) => ({
                          ...prevState,
                          userAspirations: prevState.userAspirations.map(
                            (asp, i) =>
                              i === aspirationIndex
                                ? {
                                  ...asp,
                                  aspirationName: value,
                                  aspirationUpdateDate: new Date().toISOString(), // Update when name changes
                                }
                                : asp
                          ),
                        }));
                      }}
                      className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                    />
                  </div>

                  {/* Aspiration Description */}
                  <div className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`aspirationDesc-${aspirationIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Aspiration Description
                    </label>
                    <EtContentEditorLight2
                      value={aspiration.aspirationDesc || ""}
                      handleChange={(content) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          userAspirations: prevState.userAspirations.map(
                            (asp, i) =>
                              i === aspirationIndex
                                ? {
                                  ...asp,
                                  aspirationDesc: content,
                                  aspirationUpdateDate: new Date().toISOString(), // Update when description changes
                                }
                                : asp
                          ),
                        }));
                      }}
                      placeholderText="Write the aspiration description here..."
                      editorKey={`aspirationDesc-${aspirationIndex}`}
                    />
                  </div>

                  {/* Aspiration Notes */}
                  <div className="bg-gray-100 rounded p-2 m-1">
                    <div className="flex justify-between items-center mb-2">
                      <p className="font-bold text-sm">Aspiration Notes</p>
                      <button
                        type="button"
                        className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                        onClick={() => {
                          setFormData((prevState) => ({
                            ...prevState,
                            userAspirations: prevState.userAspirations.map(
                              (asp, i) =>
                                i === aspirationIndex
                                  ? {
                                    ...asp,
                                    aspirationNotes: [
                                      ...(asp.aspirationNotes || []),
                                      {
                                        aspirationNoteText: "",
                                        aspirationNoteDate: new Date().toISOString(),
                                        aspirationNoteUpdateDate: new Date().toISOString(),
                                      },
                                    ],
                                    aspirationUpdateDate: new Date().toISOString(), // Update aspiration date
                                  }
                                  : asp
                            ),
                          }));
                        }}
                      >
                        <PlusIcon className="h-4 w-4 inline" />
                      </button>
                    </div>

                    {aspiration.aspirationNotes?.map((note, noteIndex) => (
                      <div
                        key={noteIndex}
                        className="p-2 border rounded mb-2 bg-white"
                      >
                        <div className="flex justify-between items-center mb-2">
                          <p className="font-bold text-xs">Note {noteIndex + 1}</p>
                          <button
                            type="button"
                            className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                            onClick={() => {
                              setFormData((prevState) => ({
                                ...prevState,
                                userAspirations: prevState.userAspirations.map((asp, i) =>
                                  i === aspirationIndex
                                    ? {
                                        ...asp,
                                        aspirationNotes: asp.aspirationNotes.filter(
                                          (_, idx) => idx !== noteIndex
                                        ),
                                        aspirationUpdateDate: new Date().toISOString(), // Update aspiration date
                                      }
                                    : asp
                                ),
                              }));
                            }}
                          >
                            <MinusIcon className="h-4 w-4 inline" />
                          </button>

                        </div>

                        {/* Note Text */}
                        <div className="bg-gray-100 rounded p-1 m-1">
                          <label
                            htmlFor={`noteText-${aspirationIndex}-${noteIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            Note Text
                          </label>
                          <EtContentEditorLight2
                            value={note.aspirationNoteText || ""}
                            handleChange={(content) => {
                              setFormData((prevState) => ({
                                ...prevState,
                                userAspirations: prevState.userAspirations.map((asp, i) =>
                                  i === aspirationIndex
                                    ? {
                                        ...asp,
                                        aspirationNotes: asp.aspirationNotes.map((n, j) =>
                                          j === noteIndex
                                            ? {
                                                ...n,
                                                aspirationNoteText: content,
                                                aspirationNoteUpdateDate: new Date().toISOString(), // Update note date
                                              }
                                            : n
                                        ),
                                        aspirationUpdateDate: new Date().toISOString(), // Update aspiration date
                                      }
                                    : asp
                                ),
                              }));
                            }}
                            placeholderText="Write your note here..."
                            editorKey={`noteText-${aspirationIndex}-${noteIndex}`}
                          />

                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* 4. HiddenMagic - Inspirations */}
        <div id="inspirations" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <p className="font-bold text-sm">User Inspirations</p>
            <button
              type="button"
              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
              onClick={() => {
                setFormData((prevState) => ({
                  ...prevState,
                  userInspirations: [
                    ...prevState.userInspirations,
                    {
                      inspirationName: "",
                      inspirationDesc: "",
                      inspirationNotes: [],
                      inspirationDate: new Date().toISOString(), // Initial creation date
                      inspirationUpdateDate: new Date().toISOString(), // Initial update date
                    },
                  ],
                }));
              }}
            >
              <PlusIcon className="h-4 w-4 inline" />
            </button>
          </div>

          {formData.userInspirations.map((inspiration, inspirationIndex) => (
            <div
              key={inspirationIndex}
              className="p-2 border rounded mb-2 bg-white"
            >
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={() =>
                      toggleSection(`inspiration-${inspirationIndex}`)
                    }
                    className="text-orange-600 font-bold m-1 p-1 rounded"
                  >
                    {collapsedSections[`inspiration-${inspirationIndex}`] ? (
                      <PlusIcon className="h-4 w-4 text-orange-600" />
                    ) : (
                      <MinusIcon className="h-4 w-4 text-orange-600" />
                    )}
                  </button>
                  <p className="font-bold text-sm mx-1 px-1">
                    Inspiration {inspirationIndex + 1}
                  </p>
                </div>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) => ({
                      ...prevState,
                      userInspirations: prevState.userInspirations.filter(
                        (_, i) => i !== inspirationIndex
                      ),
                    }));
                  }}
                >
                  <MinusIcon className="h-4 w-4 inline" />
                </button>
              </div>

              {!collapsedSections[`inspiration-${inspirationIndex}`] && (
                <div>
                  {/* Inspiration Name */}
                  <div className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`inspirationName-${inspirationIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Inspiration Name
                    </label>
                    <input
                      type="text"
                      name={`inspirationName-${inspirationIndex}`}
                      placeholder="Enter the inspiration name"
                      value={inspiration.inspirationName}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData((prevState) => ({
                          ...prevState,
                          userInspirations: prevState.userInspirations.map(
                            (insp, i) =>
                              i === inspirationIndex
                                ? {
                                  ...insp,
                                  inspirationName: value,
                                  inspirationUpdateDate: new Date().toISOString(), // Update inspiration date
                                }
                                : insp
                          ),
                        }));
                      }}
                      className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                    />
                  </div>

                  {/* Inspiration Description */}
                  <div className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`inspirationDesc-${inspirationIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Inspiration Description
                    </label>
                    <EtContentEditorLight2
                      value={inspiration.inspirationDesc || ""}
                      handleChange={(content) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          userInspirations: prevState.userInspirations.map(
                            (insp, i) =>
                              i === inspirationIndex
                                ?{
                                  ...insp,
                                  inspirationDesc: content,
                                  inspirationUpdateDate: new Date().toISOString(), // Update when description changes
                                }
                                : insp
                          ),
                        }));
                      }}
                      placeholderText="Write the inspiration description here..."
                      editorKey={`inspirationDesc-${inspirationIndex}`}
                    />
                  </div>

                  {/* Inspiration Notes */}
                  <div className="bg-gray-100 rounded p-2 m-1">
                    <div className="flex justify-between items-center mb-2">
                      <p className="font-bold text-sm">Inspiration Notes</p>
                      <button
                        type="button"
                        className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                        onClick={() => {
                          setFormData((prevState) => ({
                            ...prevState,
                            userInspirations: prevState.userInspirations.map(
                              (insp, i) =>
                                i === inspirationIndex
                                  ? {
                                    ...insp,
                                    inspirationNotes: [
                                      ...(insp.inspirationNotes || []),
                                      {
                                        inspirationNoteText: "",
                                        inspirationNoteDate: new Date().toISOString(), // Creation date
                                        inspirationNoteUpdateDate: new Date().toISOString(), // Initial update date
                                      },
                                    ],
                                    inspirationUpdateDate: new Date().toISOString(), // Parent update
                                  }
                                  : insp
                            ),
                          }));
                        }}
                      >
                        <PlusIcon className="h-4 w-4 inline" />
                      </button>
                    </div>

                    {inspiration.inspirationNotes?.map((note, noteIndex) => (
                      <div
                        key={noteIndex}
                        className="p-2 border rounded mb-2 bg-white"
                      >
                        <div className="flex justify-between items-center mb-2">
                          <p className="font-bold text-xs">Note {noteIndex + 1}</p>
                          <button
                            type="button"
                            className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                            onClick={() => {
                              setFormData((prevState) => ({
                                ...prevState,
                                userInspirations: prevState.userInspirations.map(
                                  (insp, i) =>
                                    i === inspirationIndex
                                      ? {
                                        ...insp,
                                        inspirationNotes: insp.inspirationNotes.filter(
                                          (_, idx) => idx !== noteIndex
                                        ),
                                        inspirationUpdateDate: new Date().toISOString(), // Parent update
                                      }
                                      : insp
                                ),
                              }));
                            }}
                          >
                            <MinusIcon className="h-4 w-4 inline" />
                          </button>
                        </div>

                        {/* Note Text */}
                        <div className="bg-gray-100 rounded p-1 m-1">
                          <label
                            htmlFor={`noteText-${inspirationIndex}-${noteIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            Note Text
                          </label>
                          <EtContentEditorLight2
                            value={note.inspirationNoteText || ""}
                            handleChange={(content) => {
                              setFormData((prevState) => ({
                                ...prevState,
                                userInspirations: prevState.userInspirations.map((insp, i) =>
                                  i === inspirationIndex
                                    ? {
                                        ...insp,
                                        inspirationNotes: insp.inspirationNotes.map((n, j) =>
                                          j === noteIndex
                                            ? {
                                                ...n,
                                                inspirationNoteText: content,
                                                inspirationNoteUpdateDate: new Date().toISOString(), // Update note
                                              }
                                            : n
                                        ),
                                        inspirationUpdateDate: new Date().toISOString(), // Parent update
                                      }
                                    : insp
                                ),
                              }));
                            }}
                            placeholderText="Write your note here..."
                            editorKey={`noteText-${inspirationIndex}-${noteIndex}`}
                          />

                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* 5. HiddenMagic - Magic Moments */}
        <div id="magicMoments" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <p className="font-bold text-sm">User Magic Moments</p>
            <button
              type="button"
              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
              onClick={() => {
                setFormData((prevState) => ({
                  ...prevState,
                  userMagicMoments: [
                    ...prevState.userMagicMoments,
                    {
                      magicMomentName: "",
                      magicMomentDesc: "",
                      magicMomentNotes: [],
                      magicMomentDate: new Date().toISOString(), // Creation date
                      magicMomentUpdateDate: new Date().toISOString(), // Initial update date
                    },
                  ],
                }));
              }}
            >
              <PlusIcon className="h-4 w-4 inline" />
            </button>
          </div>

          {formData.userMagicMoments.map((moment, momentIndex) => (
            <div
              key={momentIndex}
              className="p-2 border rounded mb-2 bg-white"
            >
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={() =>
                      toggleSection(`magicMoment-${momentIndex}`)
                    }
                    className="text-orange-600 font-bold m-1 p-1 rounded"
                  >
                    {collapsedSections[`magicMoment-${momentIndex}`] ? (
                      <PlusIcon className="h-4 w-4 text-orange-600" />
                    ) : (
                      <MinusIcon className="h-4 w-4 text-orange-600" />
                    )}
                  </button>
                  <p className="font-bold text-sm mx-1 px-1">
                    Magic Moment {momentIndex + 1}
                  </p>
                </div>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) => ({
                      ...prevState,
                      userMagicMoments: prevState.userMagicMoments.filter(
                        (_, i) => i !== momentIndex
                      ),
                    }));
                  }}
                >
                  <MinusIcon className="h-4 w-4 inline" />
                </button>
              </div>

              {!collapsedSections[`magicMoment-${momentIndex}`] && (
                <div>
                  {/* Magic Moment Name */}
                  <div className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`magicMomentName-${momentIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Magic Moment Name
                    </label>
                    <input
                      type="text"
                      name={`magicMomentName-${momentIndex}`}
                      placeholder="Enter the magic moment name"
                      value={moment.magicMomentName}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData((prevState) => ({
                          ...prevState,
                          userMagicMoments: prevState.userMagicMoments.map(
                            (mm, i) =>
                              i === momentIndex
                                ? {
                                  ...mm,
                                  magicMomentName: value,
                                  magicMomentUpdateDate: new Date().toISOString(), // Update parent date
                                }
                                : mm
                          ),
                        }));
                      }}
                      className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                    />
                  </div>

                  {/* Magic Moment Description */}
                  <div className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`magicMomentDesc-${momentIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Magic Moment Description
                    </label>
                    <EtContentEditorLight2
                      value={moment.magicMomentDesc || ""}
                      handleChange={(content) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          userMagicMoments: prevState.userMagicMoments.map(
                            (mm, i) =>
                              i === momentIndex
                                ? {
                                  ...mm,
                                  magicMomentDesc: content,
                                  magicMomentUpdateDate: new Date().toISOString(), // Update parent date
                                }
                                : mm
                          ),
                        }));
                      }}
                      placeholderText="Write the magic moment description here..."
                      editorKey={`magicMomentDesc-${momentIndex}`}
                    />
                  </div>

                  {/* Magic Moment Notes */}
                  <div className="bg-gray-100 rounded p-2 m-1">
                    <div className="flex justify-between items-center mb-2">
                      <p className="font-bold text-sm">Magic Moment Notes</p>
                      <button
                        type="button"
                        className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                        onClick={() => {
                          setFormData((prevState) => ({
                            ...prevState,
                            userMagicMoments: prevState.userMagicMoments.map(
                              (mm, i) =>
                                i === momentIndex
                                  ? {
                                      ...mm,
                                      magicMomentNotes: [
                                        ...(mm.magicMomentNotes || []),
                                        {
                                          magicMomentNoteText: "",
                                          magicMomentNoteDate: new Date().toISOString(), // Creation date
                                          magicMomentNoteUpdateDate: new Date().toISOString(), // Initial update date
                                        },
                                      ],
                                    }
                                  : mm
                            ),
                          }));
                        }}
                      >
                        <PlusIcon className="h-4 w-4 inline" />
                      </button>
                    </div>

                    {moment.magicMomentNotes?.map((note, noteIndex) => (
                      <div
                        key={noteIndex}
                        className="p-2 border rounded mb-2 bg-white"
                      >
                        <div className="flex justify-between items-center mb-2">
                          <p className="font-bold text-xs">Note {noteIndex + 1}</p>
                          <button
                            type="button"
                            className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                            onClick={() => {
                              setFormData((prevState) => ({
                                ...prevState,
                                userMagicMoments: prevState.userMagicMoments.map(
                                  (mm, i) =>
                                    i === momentIndex
                                      ? {
                                        ...mm,
                                        magicMomentNotes: mm.magicMomentNotes.filter(
                                          (_, idx) => idx !== noteIndex
                                        ),
                                        magicMomentUpdateDate: new Date().toISOString(), // Update parent date
                                      }
                                      : mm
                                ),
                              }));
                            }}
                          >
                            <MinusIcon className="h-4 w-4 inline" />
                          </button>
                        </div>

                        {/* Note Text */}
                        <div className="bg-gray-100 rounded p-1 m-1">
                          <label
                            htmlFor={`noteText-${momentIndex}-${noteIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            Note Text
                          </label>
                          <EtContentEditorLight2
                            value={note.magicMomentNoteText || ""}
                            handleChange={(content) => {
                              setFormData((prevState) => ({
                                ...prevState,
                                userMagicMoments: prevState.userMagicMoments.map(
                                  (mm, i) =>
                                    i === momentIndex
                                      ? {
                                        ...mm,
                                        magicMomentNotes: mm.magicMomentNotes.map((n, j) =>
                                          j === noteIndex
                                            ? {
                                                ...n,
                                                magicMomentNoteText: content,
                                                magicMomentNoteUpdateDate: new Date().toISOString(), // Update note date
                                              }
                                            : n
                                        ),
                                        magicMomentUpdateDate: new Date().toISOString(), // Update parent date
                                      }
                                      : mm
                                ),
                              }));
                            }}
                            placeholderText="Write your note here..."
                            editorKey={`noteText-${momentIndex}-${noteIndex}`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* 6. HiddenMagic - Super Powers */}
        <div id="superPowers" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <p className="font-bold text-sm">User Super Powers</p>
            <button
              type="button"
              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
              onClick={() => {
                setFormData((prevState) => ({
                  ...prevState,
                  userSuperPowers: [
                    ...prevState.userSuperPowers,
                    {
                      superPowerName: "",
                      superPowerDesc: "",
                      superPowerNotes: [],
                      superPowerDate: new Date().toISOString(), // Set creation date
                      superPowerUpdateDate: new Date().toISOString(), // Set update date
                    },
                  ],
                }));
              }}
            >
              <PlusIcon className="h-4 w-4 inline" />
            </button>
          </div>

          {formData.userSuperPowers.map((power, powerIndex) => (
            <div
              key={powerIndex}
              className="p-2 border rounded mb-2 bg-white"
            >
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={() =>
                      toggleSection(`superPower-${powerIndex}`)
                    }
                    className="text-orange-600 font-bold m-1 p-1 rounded"
                  >
                    {collapsedSections[`superPower-${powerIndex}`] ? (
                      <PlusIcon className="h-4 w-4 text-orange-600" />
                    ) : (
                      <MinusIcon className="h-4 w-4 text-orange-600" />
                    )}
                  </button>
                  <p className="font-bold text-sm mx-1 px-1">
                    Super Power {powerIndex + 1}
                  </p>
                </div>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) => ({
                      ...prevState,
                      userSuperPowers: prevState.userSuperPowers.filter(
                        (_, i) => i !== powerIndex
                      ),
                    }));
                  }}
                >
                  <MinusIcon className="h-4 w-4 inline" />
                </button>
              </div>

              {!collapsedSections[`superPower-${powerIndex}`] && (
                <div>
                  {/* Super Power Name */}
                  <div className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`superPowerName-${powerIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Super Power Name
                    </label>
                    <input
                      type="text"
                      name={`superPowerName-${powerIndex}`}
                      placeholder="Enter the super power name"
                      value={power.superPowerName}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData((prevState) => ({
                          ...prevState,
                          userSuperPowers: prevState.userSuperPowers.map(
                            (sp, i) =>
                              i === powerIndex
                                ? {
                                  ...sp,
                                  superPowerName: value,
                                  superPowerUpdateDate: new Date().toISOString(), // Update the update date
                                }
                                : sp
                          ),
                        }));
                      }}
                      className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                    />
                  </div>

                  {/* Super Power Description */}
                  <div className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`superPowerDesc-${powerIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Super Power Description
                    </label>
                    <EtContentEditorLight2
                      value={power.superPowerDesc || ""}
                      handleChange={(content) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          userSuperPowers: prevState.userSuperPowers.map(
                            (sp, i) =>
                              i === powerIndex
                                ? {
                                  ...sp,
                                  superPowerDesc: content,
                                  superPowerUpdateDate: new Date().toISOString(), // Update the update date
                                }
                                : sp
                          ),
                        }));
                      }}
                      placeholderText="Write the super power description here..."
                      editorKey={`superPowerDesc-${powerIndex}`}
                    />
                  </div>

                  {/* Super Power Notes */}
                  <div className="bg-gray-100 rounded p-2 m-1">
                    <div className="flex justify-between items-center mb-2">
                      <p className="font-bold text-sm">Super Power Notes</p>
                      <button
                        type="button"
                        className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                        onClick={() => {
                          setFormData((prevState) => ({
                            ...prevState,
                            userSuperPowers: prevState.userSuperPowers.map(
                              (sp, i) =>
                                i === powerIndex
                                  ? {
                                    ...sp,
                                    superPowerNotes: [
                                      ...(sp.superPowerNotes || []),
                                      {
                                        superPowerNoteText: "",
                                        superPowerNoteDate: new Date().toISOString(),
                                        superPowerNoteUpdateDate: new Date().toISOString(),
                                      },
                                    ],
                                    superPowerUpdateDate: new Date().toISOString(), // Update parent element
                                  }
                                  : sp
                            ),
                          }));
                        }}
                      >
                        <PlusIcon className="h-4 w-4 inline" />
                      </button>
                    </div>

                    {power.superPowerNotes?.map((note, noteIndex) => (
                      <div
                        key={noteIndex}
                        className="p-2 border rounded mb-2 bg-white"
                      >
                        <div className="flex justify-between items-center mb-2">
                          <p className="font-bold text-xs">Note {noteIndex + 1}</p>
                          <button
                            type="button"
                            className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                            onClick={() => {
                              setFormData((prevState) => ({
                                ...prevState,
                                userSuperPowers: prevState.userSuperPowers.map(
                                  (sp, i) =>
                                    i === powerIndex
                                      ? {
                                        ...sp,
                                        superPowerNotes: sp.superPowerNotes.filter(
                                          (_, idx) => idx !== noteIndex
                                        ),
                                        superPowerUpdateDate: new Date().toISOString(), // Update parent element
                                      }
                                      : sp
                                ),
                              }));
                            }}
                          >
                            <MinusIcon className="h-4 w-4 inline" />
                          </button>
                        </div>

                        {/* Note Text */}
                        <div className="bg-gray-100 rounded p-1 m-1">
                          <label
                            htmlFor={`noteText-${powerIndex}-${noteIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            Note Text
                          </label>
                          <EtContentEditorLight2
                            value={note.superPowerNoteText || ""}
                            handleChange={(content) => {
                              setFormData((prevState) => ({
                                ...prevState,
                                userSuperPowers: prevState.userSuperPowers.map(
                                  (sp, i) =>
                                    i === powerIndex
                                      ? {
                                        ...sp,
                                        superPowerNotes: sp.superPowerNotes.map((n, j) =>
                                          j === noteIndex
                                            ? {
                                                ...n,
                                                superPowerNoteText: content,
                                                superPowerNoteUpdateDate: new Date().toISOString(), // Update note date
                                              }
                                            : n
                                        ),
                                        superPowerUpdateDate: new Date().toISOString(), // Update parent element
                                      }
                                      : sp
                                ),
                              }));
                            }}
                            placeholderText="Write your note here..."
                            editorKey={`noteText-${powerIndex}-${noteIndex}`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
   
        {/* 7. HiddenMagic - Self Notes */}
        <div id="selfNotes" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <p className="font-bold text-sm">Self Notes</p>
            <button
              type="button"
              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
              onClick={() => {
                setFormData((prevState) => ({
                  ...prevState,
                  userHiddenMagicSelfNotes: [
                    ...prevState.userHiddenMagicSelfNotes,
                    {
                      hiddenMagicSelfNoteText: "",
                      hiddenMagicSelfNoteDate: new Date().toISOString(),
                    },
                  ],
                }));
              }}
            >
              <PlusIcon className="h-4 w-4 inline" />
            </button>
          </div>

          {formData.userHiddenMagicSelfNotes.map((note, noteIndex) => (
            <div key={noteIndex} className="p-2 border rounded mb-2 bg-white">
              <div className="flex justify-between items-center mb-2">
                <p className="font-bold text-xs">Note {noteIndex + 1}</p>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) => ({
                      ...prevState,
                      userHiddenMagicSelfNotes: prevState.userHiddenMagicSelfNotes.filter(
                        (_, idx) => idx !== noteIndex
                      ),
                    }));
                  }}
                >
                  <MinusIcon className="h-4 w-4 inline" />
                </button>
              </div>

              {/* Note Text */}
              <div className="bg-gray-100 rounded p-1 m-1">
                <label
                  htmlFor={`selfNoteText-${noteIndex}`}
                  className="block text-gray-600 text-sm font-bold mb-1"
                >
                  Note Text
                </label>
                <EtContentEditorLight2
                  value={note.hiddenMagicSelfNoteText || ""}
                  handleChange={(content) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      userHiddenMagicSelfNotes: prevState.userHiddenMagicSelfNotes.map(
                        (n, idx) =>
                          idx === noteIndex
                            ? { ...n, hiddenMagicSelfNoteText: content }
                            : n
                      ),
                    }));
                  }}
                  placeholderText="Write your note here..."
                  editorKey={`selfNoteText-${noteIndex}`}
                />
              </div>

              {/* Note Date */}
              <p className="text-xs text-gray-500 mt-2">
                Created on: {new Date(note.hiddenMagicSelfNoteDate).toLocaleDateString()}
              </p>
            </div>
          ))}
        </div>

        {/* 8. Form Submissions Bar */}
        <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic 
              name={'Create'} 
              type={'create'} 
              handleClick={handleSubmit} 
              />
            <EtBtnFormBasic
              name={'Clear'}
              type={'clear'}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={'Cancel'}
              type={'cancel'}
              handleClick={handleCancel}
            />
        </div>

      </form>


    </div>
  )
}

export default EtCreateUserImprintHiddenMagicComp