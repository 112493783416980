import React, { useState, useEffect } from 'react'
import useTitle from "../../../hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
    PencilSquareIcon,
  } from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "../../../utils/EtContentEditorLight2"
import EtBtnFormBasic from "../../../components/common/buttons/EtBtnFormBasic"
import { useNavigate } from "react-router"

import { useCreateNewUserMissionSelfMutation } from '@/features/userMissions/userMissionSelfsApiSlice'
import { useGetUserVisionSelfsQuery } from '@/features/userVisions/userVisionSelfsApiSlice'
import { useGetMissionMetricUoMTypesQuery } from '@/features/missionMetricUoMTypes/missionMetricUoMTypesApiSlice'
import { useGetMissionMetricGradationTypesQuery } from '@/features/missionMetricGradationTypes/missionMetricGradationTypesApiSlice'

const EtCreateUserImprintMissionSelfComp = ({
    userId, 
    userOrgId, 
    triggeringUrl, 
    onClose
  }) => {

  // First Created - 10 Jan 2025
  // Author - Atul Pandey
  // Inherited from the EtCreateUserImprintCreationComp component
  

  // 0 - Set Page title & variables
  useTitle('User Imprint Legacy - MissionSelf - Create')

  // 1 - set page navigations
  const navigate = useNavigate()
  
  // 2a - set the mutation to create the new user imprint hiddenMagic data
  const [createNewUserMissionSelf, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewUserMissionSelfMutation()

  // 2b - get the data for mission value uom and gradations
  const { data: visionsData } = useGetUserVisionSelfsQuery()
  const { data: metricUoMData } = useGetMissionMetricUoMTypesQuery()
  const { data: metricGradationData } = useGetMissionMetricGradationTypesQuery()

  const extractEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id])
  };

  const extractFilteredEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id])
    ?.filter(
        (item) => item.userId?._id === userId && item.userOrgId._id === userOrgId
        );
  };

  const visions = extractFilteredEntitiesFromAdapter(visionsData)
  const metricUoMs = extractEntitiesFromAdapter(metricUoMData)
  const metricGradations = extractEntitiesFromAdapter(metricGradationData)

  // console.log('visions', visions)
  // console.log('uoms', metricUoMs)
  // console.log('gradations', metricGradations)

  // 3 - Initialize Form Data State
    const [formData, setFormData] = useState({
      visionId: null,
      missionName: "",
      missionDesc: "",
      missionDate: "",
      missionUoMType: null,
      missionGradationType: null,
      mission5YrTargetValue: 0,
      mission5YrTargetDate: "",
      mission3YrTargetValue: 0,
      mission3YrTargetDate: "",
      mission1YrTargetValue: 0,
      mission1YrTargetDate: "",
      missionNotes:[],
      pic: "",
      active: true, // Default active state
    });
  
    // 4 - State variables for the editor
    const [editorKey, setEditorKey] = useState('hiddenMagicDesc-' + Date.now());
    const [editorValue, setEditorValue] = useState('');
    const [collapsedSections, setCollapsedSections] = useState({});
  
    // 5a - Convert Image to Base64
    const convertPicToBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
  
    // 5b - Handle Form Changes
    // const handleChange = (e) => {
    //   const { name, value, files } = e.target;
    //   if (name === "pic") {
    //     setFormData((prevState) => ({ ...prevState, pic: files[0] }));
    //   } else {
    //     setFormData((prevState) => ({ ...prevState, [name]: value }));
    //   }
    // };
    const handleChange = (e) => {
      const { name, value, files } = e.target;
    
      // Get the mission year from the missionDate
      const missionYear = new Date(formData.missionDate).getFullYear();
      const max5YrDate = new Date(missionYear + 5, 11, 31); // 5th year's last day
      const max3YrDate = new Date(missionYear + 3, 11, 31); // 3rd year's last day
      const max1YrDate = new Date(missionYear, 11, 31); // Current year's last day
    
      // Validate mission target dates
      if (name === "mission5YrTargetDate" && new Date(value) > max5YrDate) {
        alert("5-Year Target Date cannot exceed " + max5YrDate.toISOString().split("T")[0]);
        return;
      }
    
      if (name === "mission3YrTargetDate" && new Date(value) > max3YrDate) {
        alert("3-Year Target Date cannot exceed " + max3YrDate.toISOString().split("T")[0]);
        return;
      }
    
      if (name === "mission1YrTargetDate" && new Date(value) > max1YrDate) {
        alert("1-Year Target Date cannot exceed " + max1YrDate.toISOString().split("T")[0]);
        return;
      }
    
      if (name === "pic") {
        setFormData((prevState) => ({ ...prevState, pic: files[0] }));
      } else {
        setFormData((prevState) => ({ ...prevState, [name]: value }));
      }
    };
  
    // 5c - Toggle show / collapse each section 
    const toggleSection = (key) => {
      setCollapsedSections((prev) => ({
        ...prev,
        [key]: !prev[key],
      }));
    };  

    // 6 - Handle form submission
    const handleSubmit = async (e) => {
      e.preventDefault();

      const missionYear = new Date(formData.missionDate).getFullYear();
      const max5YrDate = new Date(missionYear + 5, 11, 31);
      const max3YrDate = new Date(missionYear + 3, 11, 31);
      const max1YrDate = new Date(missionYear, 11, 31);

      if (new Date(formData.mission5YrTargetDate) > max5YrDate) {
        alert("5-Year Target Date cannot exceed " + max5YrDate.toISOString().split("T")[0]);
        return;
      }

      if (new Date(formData.mission3YrTargetDate) > max3YrDate) {
        alert("3-Year Target Date cannot exceed " + max3YrDate.toISOString().split("T")[0]);
        return;
      }

      if (new Date(formData.mission1YrTargetDate) > max1YrDate) {
        alert("1-Year Target Date cannot exceed " + max1YrDate.toISOString().split("T")[0]);
        return;
      }

      try {
        const picBase64 = formData.pic
          ? await convertPicToBase64(formData.pic)
          : undefined;

        const validNotes = formData.missionNotes.filter(
          (note) => note.missionNoteText.trim()
        );

        const payload = {
          userId,
          userOrgId,
          visionId: formData.visionId || null, // Use null for empty values
          missionName: formData.missionName.trim() || undefined,
          missionDesc: formData.missionDesc.trim() || undefined,
          missionDate: formData.missionDate || undefined,
          missionUoMType: formData.missionUoMType || null, // Use null for empty values
          missionGradationType: formData.missionGradationType || null, // Use null for empty values
          mission5YrTargetValue: formData.mission5YrTargetValue || undefined,
          mission5YrTargetDate: formData.mission5YrTargetDate || undefined,
          mission3YrTargetValue: formData.mission3YrTargetValue || undefined,
          mission3YrTargetDate: formData.mission3YrTargetDate || undefined,
          mission1YrTargetValue: formData.mission1YrTargetValue || undefined,
          mission1YrTargetDate: formData.mission1YrTargetDate || undefined,
          missionNotes: validNotes.map((note) => ({
            ...note,
            missionNoteText: note.missionNoteText.trim(),
          })),
          pic: picBase64 || undefined,
          active: formData.active,
        };
        
        await createNewUserMissionSelf(payload).unwrap();

        if (onClose) {
          onClose();
        } else {
          navigate(triggeringUrl || "/userMagicHome");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
     
    };

    // 7 - Reset Form on Successful Submission
      useEffect(() => {
        if (isSuccess) {
          setFormData({
            visionId: null,
            missionName: "",
            missionDesc: "",
            missionDate: "",
            missionUoMType: null,
            missionGradationType: null,
            mission5YrTargetValue: 0,
            mission5YrTargetDate: "",
            mission3YrTargetValue: 0,
            mission3YrTargetDate: "",
            mission1YrTargetValue: 0,
            mission1YrTargetDate: "",
            missionNotes:[],
            pic: "",
            active: true, // Default active state
          });
    
          if (onClose) {
            onClose();
          } else {
            navigate(triggeringUrl || "/userMagicHome");
          }
        }
      }, [isSuccess, navigate]);
    
    // 8 - Handle Clear
    const handleClear = () => {
    setFormData({
      visionId: null,
      missionName: "",
      missionDesc: "",
      missionDate: "",
      missionUoMType: null,
      missionGradationType: null,
      mission5YrTargetValue: 0,
      mission5YrTargetDate: "",
      mission3YrTargetValue: 0,
      mission3YrTargetDate: "",
      mission1YrTargetValue: 0,
      mission1YrTargetDate: "",
      missionNotes:[],
      pic: "",
      active: true, // Default active state
    });
    };

    // 9 - Handle Cancel
    const handleCancel = () => {
    if (onClose) {
        onClose();
    } else {
        navigate(triggeringUrl || "/userMagicHome");
    }
    };


  return (
    <div className="mx-auto w-full m-1 p-1 bg-white rounded shadow-md shadow-black">
      {isLoading && <PulseLoader color={'#ea580c'} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">{error?.data?.message}</p>
      )}

      <p className="p-1 m-1 font-bold font-poppins text-xl">
        Create User Imprint Mission - Self
      </p>

      <form onSubmit={handleSubmit} className="m-1 p-1 text-xs font-poppins">
        
        {/* 1. Self Mission -  Name */}
        <div id="missionName" className="bg-gray-100 rounded p-1 m-1">
          <label htmlFor="missionName" className="block text-gray-600 text-sm font-bold mb-1">
            Self Mission - Name
          </label>
          <input
            type="text"
            name="missionName"
            value={formData.missionName}
            onChange={handleChange}
            placeholder="Enter missionSelf name"
            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
          />
        </div>

        {/* 2. Self Mission -  Desc */}
        <div id="missionDesc" className="bg-gray-100 rounded p-1 m-1">
          <label
            htmlFor="missionDesc"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
            Self Mission -  Description
          </label>
          <EtContentEditorLight2
            value={formData.missionDesc || ""}
            handleChange={(content) => {
              setFormData((prevState) => ({
                ...prevState,
                missionDesc: content,
              }));
            }}
            placeholderText="Write your missionSelf description here..."
            editorKey={editorKey}
          />
        </div>

        {/* 3. Self Mission Date */}
        <div id="missionDate" className="bg-gray-100 rounded p-1 m-1 w-full">
          <label
            htmlFor="missionDate"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
              Mission Date
          </label>
          <input
            type="date"
            name="missionDate"
            value={formData.missionDate}
            onChange={handleChange}
            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
          />
        </div>

        {/* 4. Vision Selection */}
        <div id="visionId" className="bg-gray-100 rounded p-1 m-1">
          <label htmlFor="visionId" className="block text-gray-600 text-sm font-bold mb-1">
            Select Vision
          </label>
          <select
            name="visionId"
            value={formData.visionId || ""}
            onChange={handleChange}
            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
          >
            <option value="">-- Select Vision --</option>
            {visions?.map((vision) => (
              <option key={vision._id} value={vision._id}>
                {vision.visionName}
              </option>
            ))}
          </select>
        </div>

        <div className='flex justify-between items-center'>
          {/* 5. Mission UoM Type */}
          <div id="missionUoMType" className="bg-gray-100 rounded p-1 m-1 w-full">
            <label htmlFor="missionUoMType" className="block text-gray-600 text-sm font-bold mb-1">
              Select Mission UoM Type
            </label>
            <select
              name="missionUoMType"
              value={formData.missionUoMType || ""}
              onChange={handleChange}
              className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
            >
              <option value="">-- Select UoM Type --</option>
              {metricUoMs?.map((uom) => (
                <option key={uom._id} value={uom._id}>
                  {uom.missionMetricUoMTypeName
                  }
                </option>
              ))}
            </select>
          </div>

          {/* 6. Mission Gradation Type */}
          <div id="missionGradationType" className="bg-gray-100 rounded p-1 m-1 w-full">
            <label htmlFor="missionGradationType" className="block text-gray-600 text-sm font-bold mb-1">
              Select Mission Gradation Type
            </label>
            <select
              name="missionGradationType"
              value={formData.missionGradationType || ""}
              onChange={handleChange}
              className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
            >
              <option value="">-- Select Gradation Type --</option>
              {metricGradations?.map((gradation) => (
                <option key={gradation._id} value={gradation._id}>
                  {gradation.missionMetricGradationTypeName}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* 7. Mission Targets */}
        <div id="missionTargets" className="bg-gray-100 rounded p-1 m-1">
          <label className="block text-gray-600 text-sm font-bold m-1">
            Mission Targets
          </label>
          
          {/* 5-Year Target */}
          <div className="grid grid-cols-2 gap-2">
            <div>
              <label htmlFor="mission5YrTargetValue" className="block text-gray-600 text-xs font-bold m-1">
                5-Year Target Value
              </label>
              <input
                type="number"
                name="mission5YrTargetValue"
                value={formData.mission5YrTargetValue}
                onChange={handleChange}
                placeholder="5-Year Target"
                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
              />
            </div>
            <div>
              <label htmlFor="mission5YrTargetDate" className="block text-gray-600 text-xs font-bold m-1">
                5-Year Target Date
              </label>
              <input
                type="date"
                name="mission5YrTargetDate"
                value={formData.mission5YrTargetDate}
                onChange={handleChange}
                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
              />
            </div>
          </div>

          {/* 3-Year Target */}
          <div className="grid grid-cols-2 gap-2 mt-2">
            <div>
              <label htmlFor="mission3YrTargetValue" className="block text-gray-600 text-xs font-bold m-1">
                3-Year Target Value
              </label>
              <input
                type="number"
                name="mission3YrTargetValue"
                value={formData.mission3YrTargetValue}
                onChange={handleChange}
                placeholder="3-Year Target"
                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
              />
            </div>
            <div>
              <label htmlFor="mission3YrTargetDate" className="block text-gray-600 text-xs font-bold m-1">
                3-Year Target Date
              </label>
              <input
                type="date"
                name="mission3YrTargetDate"
                value={formData.mission3YrTargetDate}
                onChange={handleChange}
                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
              />
            </div>
          </div>

          {/* 1-Year Target */}
          <div className="grid grid-cols-2 gap-2 mt-2">
            <div>
              <label htmlFor="mission1YrTargetValue" className="block text-gray-600 text-xs font-bold m-1">
                1-Year Target Value
              </label>
              <input
                type="number"
                name="mission1YrTargetValue"
                value={formData.mission1YrTargetValue}
                onChange={handleChange}
                placeholder="1-Year Target"
                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
              />
            </div>
            <div>
              <label htmlFor="mission1YrTargetDate" className="block text-gray-600 text-xs font-bold m-1">
                1-Year Target Date
              </label>
              <input
                type="date"
                name="mission1YrTargetDate"
                value={formData.mission1YrTargetDate}
                onChange={handleChange}
                className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
              />
            </div>
          </div>
        </div>        
        
        {/* 8. Self Mission -  Notes */}
        <div id="missionNotes" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <p className="font-bold text-sm">Self Mission Notes</p>
            <button
              type="button"
              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
              onClick={() => {
                setFormData((prevState) => ({
                  ...prevState,
                  missionNotes: [
                    ...prevState.missionNotes,
                    {
                      missionNoteText: "",
                      missionNoteDate: new Date().toISOString(),
                    },
                  ],
                }));
              }}
            >
              <PlusIcon className="h-4 w-4 inline" />
            </button>
          </div>

          {formData.missionNotes.map((note, index) => (
            <div key={index} className="p-2 border rounded mb-2 bg-white">
              <div className="flex justify-between items-center mb-2">
                <p className="font-bold text-xs">Note {index + 1}</p>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) => ({
                      ...prevState,
                      missionNotes: prevState.missionNotes.filter(
                        (_, idx) => idx !== index
                      ),
                    }));
                  }}
                >
                  <MinusIcon className="h-4 w-4 inline" />
                </button>
              </div>

              {/* Note Text */}
              <div className="bg-gray-100 rounded p-1 m-1">
                <label
                  htmlFor={`missionSelfNoteText-${index}`}
                  className="block text-gray-600 text-sm font-bold mb-1"
                >
                  Note Text
                </label>
                <EtContentEditorLight2
                  value={note.missionNoteText || ""}
                  handleChange={(content) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      missionNotes: prevState.missionNotes.map(
                        (n, idx) =>
                          idx === index
                            ? { ...n, missionNoteText: content }
                            : n
                      ),
                    }));
                  }}
                  placeholderText="Write your note here..."
                  editorKey={`missionSelfNoteText-${index}`}
                />
              </div>
            </div>
          ))}
        </div>
        
        {/* 9. Form Submissions Bar */}
        <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic 
              name={'Create'} 
              type={'create'} 
              handleClick={handleSubmit} 
              />
            <EtBtnFormBasic
              name={'Clear'}
              type={'clear'}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={'Cancel'}
              type={'cancel'}
              handleClick={handleCancel}
            />
        </div>

      </form>


    </div>
  )
}

export default EtCreateUserImprintMissionSelfComp