
    import React from 'react';
    
    const EtImprintVisionSelfIcon1SVGComp = (props) => (
        <svg {...props} className={`fill-current ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" fill="currentColor">
          <g transform="translate(-669 -80)"><path d="M735.96 87.2C711.898 87.1527 688.932 99.3992 688.756 129.904 688.667 145.44 698.814 154.629 705.766 165.589 712.762 176.617 713.872 187.547 709.681 204.469 719.799 213.289 739.465 211.856 756.525 216.799 757.219 208.164 755.954 199.076 759.09 195.919 763.645 191.333 772.343 201.936 777.945 194.794 782.902 188.475 775.006 187.006 782.31 178.954 784.07 177.014 778.947 176.362 778.89 174.589 778.841 173.048 782.776 175.16 783.705 173.284 784.678 171.319 781.653 167.803 783.345 165.679 785.426 163.068 790.983 168.184 793.02 163.294 794.727 159.198 786.923 150.856 783.12 142.999 781.155 138.938 784.662 134.696 784.785 129.994 785.456 104.347 764.815 89.0342 740.777 87.3782 739.17 87.2676 737.567 87.2013 735.962 87.1982ZM729.84 102.095C730.545 102.097 731.249 102.137 731.955 102.185 742.522 102.912 751.6 109.632 751.305 120.905 751.251 122.972 749.721 124.835 750.585 126.62 752.257 130.074 755.701 133.73 754.95 135.53 754.055 137.68 751.59 135.418 750.675 136.565 749.931 137.499 751.283 139.077 750.855 139.94 750.447 140.765 748.718 139.803 748.74 140.48 748.765 141.26 750.998 141.563 750.225 142.415 747.014 145.955 750.513 146.613 748.335 149.391 745.873 152.53 742.012 147.87 740.01 149.886 738.632 151.273 739.19 155.27 738.885 159.066 731.386 156.893 722.722 157.497 718.275 153.621 720.118 146.183 719.64 141.413 716.565 136.566 713.51 131.748 709.056 127.689 709.095 120.861 709.172 107.452 719.264 102.075 729.84 102.096Z" fillRule="evenodd"/></g>
        </svg>
    );
    
    export default EtImprintVisionSelfIcon1SVGComp;
    