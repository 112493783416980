import React, { useState, useEffect } from "react"
import useTitle from "../../hooks/useTitle"
import { useNavigate } from "react-router"
import {
  PlusIcon,
  MinusIcon,
  TrashIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline"
import {
  PlusCircleIcon,
  CalendarDaysIcon,
  ArrowUpCircleIcon,
  ClockIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/solid"
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined"
import PulseLoader from "react-spinners/PulseLoader"
import { formatDate, formatDateTime } from "../../utils/formatDate"
import { getTimeSince } from "../../utils/EtGetTimeSince"
import { differenceInDays } from 'date-fns' // Added 29 Jun 2024
import {
  useGetUserActionsQuery,
  useUpdateUserActionMutation,
} from "./userActionsApiSlice" // 09 Jun 2024 - update mutation

const EtUserActionListByGroupWithTabsComp = ({ userId, trailId, sessionId }) => {
  // First Created - 11 Sep 2024
  // Author - Atul Pandey
  // Inherited from EtUserActionListByGroupComp 
  // Purpose - to separate actions into Tabs based on actionCreatedBy field
  // Updated - 11 Sep 2024 - Added state for managing active tab
  // Updated - 12 Sep 2024 - added triggeringUrl for navigating back to the originating url
  // Updated - 18 Sep 2024 - Set the actionProgress to the current userActionProgress if not already set when a new note is added without updating progress
  // Updated - 18 Feb 2025 - Refined the JSX for displaying actionDesc


  // 0 - Set Page Title
  useTitle("User Actions List By Group")

  // 1 - set page navigations
  const navigate = useNavigate()

  // 2 - Initialize showDetails state as an object where key is the session index
  const [showDetails, setShowDetails] = useState({})
  const [actionNotes, setActionNotes] = useState({}) // 09 Jun 2024
  const [actionProgress, setActionProgress] = useState({}) // 09 Jun 2024
  const [showGroup, setShowGroup] = useState({}); // 02 Sep 2024 To manage group toggling
  const [activeTab, setActiveTab] = useState('All'); // 11 Sep 2024 - Options: 'All', 'Self', 'Coach', 'Sponsor'


  // 3 - Extract the user actions data
  const {
    data: userActionsData,
    isError,
    error,
    isLoading,
  } = useGetUserActionsQuery()
  // console.log("userActionsData", userActionsData)

  // 3a - Mutation for updating user action -- 09 Jun 2024
  const [updateUserAction] = useUpdateUserActionMutation()

  // 3b - Load state from localStorage -- 11 Jun 2024
  useEffect(() => {
    const storedShowDetails = localStorage.getItem("showDetails")
    if (storedShowDetails) {
      setShowDetails(JSON.parse(storedShowDetails))
    }
  }, [])

  // 3c Save state to localStorage before page reload -- 11 Jun 2024
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("showDetails", JSON.stringify(showDetails))
    }

    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [showDetails])

  // 4 - Toggle Show function for userAction details -- updated -- 12 Jun 2024 --
  const handleToggleDetails = (actionId) => {
    setShowDetails((prev) => {
      const newShowDetails = { ...prev, [actionId]: !prev[actionId] }

      // Update the value attribute when details are shown
      if (!prev[actionId]) {
        const action = filteredUserActionsData?.find((a) => a.id === actionId)

        const slider = document.getElementById(`slider-${actionId}`)
        if (slider) {
          const initialValue =
            actionProgress[actionId] || action?.userActionProgress || 0
          slider.value = initialValue // Set the value attribute
        }
      }
      return newShowDetails
    })
  }

  // 5 - Create an object for setting inner HTML -- 18 Feb 2025
  const createMarkup = (htmlContent) => {
    const formattedContent = htmlContent.replace(/\n/g, '<br />'); // Convert \n to <br />
    // return { __html: htmlContent }  // 18 Feb 2025 - commented
    return { __html: formattedContent };
  }

  // 6a - Group user actions by actionType
  // const groupedUserActions = userActionsData?.ids
  // .map((id) => userActionsData.entities[id])
  // .filter(
  //   (action) =>
  //     action.userId._id === userId &&
  //     action.trailId._id === trailId &&
  //     action.sessionId._id === sessionId
  // )
  // .reduce((acc, action) => {
  //   const actionType = action.actionTypeId?.actionTypeName || 'Unknown';
  //   if (!acc[actionType]) acc[actionType] = [];
  //   acc[actionType].push(action);
  //   return acc;
  // }, {});

  // 6a - Group user actions by actionType and activeTab
const groupedUserActions = userActionsData?.ids
.map((id) => userActionsData.entities[id])
.filter((action) => {
  // Common conditions for all tabs
  const commonConditions =
    action.userId._id === userId &&
    action.trailId._id === trailId &&
    action.sessionId._id === sessionId;

  // Filter based on activeTab value
  if (activeTab === 'Self') {
    return commonConditions && action.actionCreatedBy === 'Self';
  } else if (activeTab === 'Coach') {
    return commonConditions && action.actionCreatedBy === 'Coach';
  } else if (activeTab === 'Sponsor') {
    return commonConditions && action.actionCreatedBy === 'Sponsor';
  } else if (activeTab === 'Session') {
    return commonConditions && action.actionCreatedBy === 'Session';
  }else {
    // Show all actions when the 'All' tab is active
    return commonConditions;
  }
})
.reduce((acc, action) => {
  const actionType = action.actionTypeId?.actionTypeName || 'Unknown';
  if (!acc[actionType]) acc[actionType] = [];
  acc[actionType].push(action);
  return acc;
}, {});

  


  // // 6b - Filter userActionsData based on props
  // const filteredUserActionsData = userActionsData?.ids
  //   .map((id) => userActionsData.entities[id])
  //   .filter(
  //     (action) =>
  //       action.userId._id === userId &&
  //       action.trailId._id === trailId &&
  //       action.sessionId._id === sessionId
  //   )

  // 6b - Filter userActionsData based on activeTab and user-specific conditions
  const filteredUserActionsData = userActionsData?.ids
  .map((id) => userActionsData.entities[id])
  .filter((action) => {
    // Common conditions for all tabs
    const commonConditions =
      action.userId._id === userId &&
      action.trailId._id === trailId &&
      action.sessionId._id === sessionId;

    // Filter based on activeTab value
    if (activeTab === 'Self') {
      return commonConditions && action.actionCreatedBy === 'Self';
    } else if (activeTab === 'Coach') {
      return commonConditions && action.actionCreatedBy === 'Coach';
    } else if (activeTab === 'Sponsor') {
      return commonConditions && action.actionCreatedBy === 'Sponsor';
    }else if (activeTab === 'Session') {
      return commonConditions && action.actionCreatedBy === 'Session';
    } else {
      // Show all actions when the 'All' tab is active
      return commonConditions;
    }
  });

  // console.log("filtered user action data:", filteredUserActionsData)
  // console.log("filtered user actions count: ", filteredUserActionsData?.length)

  // 7 - Handle Note Change -- 09 Jun 2024
  const handleNoteChange = (e, actionId) => {
    const { value } = e.target
    setActionNotes((prevNotes) => ({ ...prevNotes, [actionId]: value }))

    // 18 Sep 2024 - Set the actionProgress to the current userActionProgress if not already set
    setActionProgress((prevProgress) => {
      if (prevProgress[actionId] === undefined) {
          const action = filteredUserActionsData?.find((a) => a.id === actionId);
          return { ...prevProgress, [actionId]: action?.userActionProgress || 0 };
      }
      return prevProgress;
  });
  }

  // 8 - Handle Progress Change -- 09 Jun 2024
  const handleProgressChange = (e, actionId) => {
    const { value } = e.target
    setActionProgress((prevProgress) => ({
      ...prevProgress,
      [actionId]: value,
    }))
    e.target.style.setProperty("--value", value)
  }

  // 8a - UseEffect for slider -- 10 Jun 2024, updated -- 12 Jun 2024 --
  // useEffect(() => {
  //   // Adjust slider initialization to use grouped actions
  //   Object.values(groupedUserActions).flat().forEach((action) => {
  //     const slider = document.getElementById(`slider-${action._id}`);
  //     if (slider) {
  //       const initialValue =
  //         actionProgress[action._id] || action.userActionProgress || 0;
  //       slider.style.setProperty("--value", initialValue);
  //       slider.style.setProperty("--min", 0);
  //       slider.style.setProperty("--max", 100);
  //     }
  //   });
  // }, [groupedUserActions, actionProgress]);
  
  useEffect(() => {
    // Iterate through all actions to set initial styles
    filteredUserActionsData?.forEach((action) => {
      const slider = document.getElementById(`slider-${action.id}`)
      if (slider) {
        const initialValue =
          actionProgress[action.id] || action.userActionProgress || 0
          // console.log('actionProgress[action.id]', actionProgress[action.id], 'action.id', action.id)
        slider.style.setProperty("--value", initialValue)
        slider.style.setProperty("--min", 0)
        slider.style.setProperty("--max", 100)
      }
    })
  }, [filteredUserActionsData, actionProgress]) // Dependencies updated

  // 9 - Save function to update user action
  const handleSave = async (
    userId,
    trailId,
    sessionId,
    actionTypeId,
    actionId,
    userActionName,
    userActionDesc,
    completeBy
  ) => {
    const actionNoteText = actionNotes[actionId]
    const actionNoteDate = new Date()

    // // Use current overall progress if no progress input was provided
    // const userActionProgress = actionProgress[actionId] !== undefined 
    //     ? actionProgress[actionId] 
    //     : actionId?.userActionProgress || 0;

    const userActionProgress = actionProgress[actionId] // 18 Sep 2024 - previous code
    // console.log('userActionProgress:', userActionProgress) // 18 Sep 2024 - for testing

    // Check if the action note text is not empty
    if (!actionNoteText) {
      alert("Action note cannot be empty")
      return
    }

    try {
      const updatedAction = {
        id: actionId,
        userId: userId,
        trailId: trailId,
        sessionId: sessionId,
        actionTypeId: actionTypeId,
        userActionName: userActionName,
        userActionDesc: userActionDesc,
        completeBy: completeBy,
        userActionNotes: [
          {
            actionNoteText,
            actionNoteDate,
            actionNoteProgress: userActionProgress,
          },
        ],
        userActionProgress,
      }

      await updateUserAction(updatedAction).unwrap()
      alert("Action updated successfully")
      window.location.reload()
    } catch (error) {
      console.error("Failed to update action:", error)
      alert("Failed to update action")
    }
  }

  // 10a - bgColor for action progress status -- 29 Jun 2024
  const getBgColor = (action) => {
    const today = new Date() // Current date
    const completeByDate = new Date(action?.completeBy); // Assuming it's already a Date object
    
    const daysDifference = differenceInDays(completeByDate, today);

    let bgColor = 'bg-lime-400'; // default color

    if (daysDifference < 0) {
      bgColor = 'bg-red-600 text-white';
    } else if (daysDifference < 1) {
      bgColor = 'bg-orange-400 text-white';
    } else if (daysDifference < 3) {
      bgColor = 'bg-yellow-400';
    } // default bgColor 'bg-lime-400' will be used if daysDifference > 3
    return bgColor
  }

  // 10b - Icon color for action progress icon  -- 29 Jun 2024
  const getIconClr = (action) => {
    const today = new Date(); // Current date
    const completeByDate = new Date(action?.completeBy); // Convert the string to a Date object
  
    if (isNaN(completeByDate)) {
      console.error('Invalid date:', action?.completeBy);
      return '#84cc16'; // Return the default color if the date is invalid
    }
  
    const daysDifference = differenceInDays(completeByDate, today);
    //console.log('Difference in days', daysDifference);
  
    let color = '#84cc16'; // default color
  
    if (daysDifference < 0) {
      color = '#dc2626';
    } else if (daysDifference < 1) {
      color = '#f97316';
    } else if (daysDifference < 3) {
      color = '#facc15';
    } // default color '#84cc16' will be used if daysDifference > 3
  
    return color;
  };

  // 10c - get the difference in days -- 29 Jun 2024
  const getDaysDifference = (action) => {
    const today = new Date(); // Current date
    const completeByDate = new Date(action?.completeBy); // Convert the string to a Date object
    const daysDifference = differenceInDays(completeByDate, today);
    return daysDifference
  }
  

  // 11 - Optional: handle loading and error states
  if (isLoading) return <PulseLoader color={"#ea580c"} />
  if (isError) return <div>Error occurred: {error?.data?.message}</div>

  return (
    <div className="m-1 p-1 rounded font-poppins xl:mx-auto">
      <div className="bg-white m-1 p-1 rounded">
        {/* 11 Sep 2024 - Step 2: Add Tabs for filtering actions */}
          <div className="flex justify-start space-x-4 p-2 border-b font-poppins text-sm">
            <button
              className={`px-3 py-1 ${activeTab === 'All' ? 'text-orange-600 border-b-2 font-bold border-orange-600' : 'text-gray-500'}`}
              onClick={() => setActiveTab('All')}
            >
              All
            </button>
            <button
              className={`px-3 py-1 ${activeTab === 'Self' ? 'text-orange-600 border-b-2 font-bold border-orange-600' : 'text-gray-500'}`}
              onClick={() => setActiveTab('Self')}
            >
              Self
            </button>
            <button
              className={`px-3 py-1 ${activeTab === 'Coach' ? 'text-orange-600 border-b-2 font-bold border-orange-600' : 'text-gray-500'}`}
              onClick={() => setActiveTab('Coach')}
            >
              Coach
            </button>
            <button
              className={`px-3 py-1 ${activeTab === 'Sponsor' ? 'text-orange-600 border-b-2 font-bold border-orange-600' : 'text-gray-500'}`}
              onClick={() => setActiveTab('Sponsor')}
            >
              Sponsor
            </button>

            <button
              className={`px-3 py-1 ${activeTab === 'Session' ? 'text-orange-600 border-b-2 font-bold border-orange-600' : 'text-gray-500'}`}
              onClick={() => setActiveTab('Session')}
            >
              Session
            </button>
          </div>

        {/* 11 Jun 2024 - Added this section when there are no user actions in the trail*/}
        {filteredUserActionsData?.length === 0 ? (
          <div className="m-1 p-1"><p className="text-center text-orange-600 text-xs">
            There are no user actions added to this tab yet
          </p></div>
        ) : (       
          
          Object.keys(groupedUserActions)?.map((actionType, index) => (
            <div key={actionType + index} className="bg-gray-200 rounded m-1 p-1">
              {/* Group Header with Toggle */}
              <div
                className="flex justify-between items-center m-1 p-1 cursor-pointer"
                onClick={() =>
                  setShowGroup((prev) => ({ ...prev, [actionType]: !prev[actionType] }))
                }
              >
                <div className="flex items-center">
                  {showGroup[actionType] ? (
                    <MinusIcon className="h-5 w-5 text-orange-600" />
                  ) : (
                    <PlusIcon className="h-5 w-5 text-orange-600" />
                  )}
                  <p className="font-bold text-sm mx-2">{actionType}</p>
                </div>
              </div>
             
                  {/* Display actions inside the group if expanded */}
                  {showGroup[actionType] &&
                    groupedUserActions[actionType].map((action, actionIndex) => {
                      // 10 Sep 2024 - added console.log to check the action details
                      // console.log('action details:', action)
                      return(
                      <div key={action._id + actionIndex} className="bg-gray-100 rounded m-1 p-1">
                        {/* Action display code */}

                          <div className="grid grid-cols-12 justify-between items-center">
                              <div className="col-span-5 gap-2 flex justify-start items-center mx-2">
                                <div
                                    onClick={() => handleToggleDetails(action._id)}
                                    className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                    >
                                    {showDetails[action._id] ? (
                                      <MinusIcon className="h-4 w-4 text-orange-600" />
                                    ) : (
                                      <PlusIcon className="h-4 w-4 text-orange-600" />
                                    )}
                                </div>

                          <div className="m-1 p-1 ">
                            <div className="flex justify-start items-center">
                              <p className="text-xs font-bold mx-1">
                                Action {actionIndex + 1}
                              </p>
                              <p className="text-[10px] mx-1">
                                {action?.userActionName}
                              </p>
                            </div>

                            {/* <p className="text-[10px] m-1">{action?.userActionDesc}</p> */}
                          </div>
                              </div>

                              <div className="col-span-2 gap-2">
                                {/* 11 Jun 2024 - added code to display progress data based on threshold for better visibility */}
                                {action?.userActionProgress < 10 ? (
                                  <div className="rounded-full bg-gray-300 relative">
                                    <div
                                      className="rounded-full bg-[#552a9a]"
                                      style={{
                                        width: `${action?.userActionProgress}%`,
                                        height: "26%",
                                      }}
                                    ></div>
                                    <p className="text-[8px] text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                      {action?.userActionProgress}%
                                    </p>
                                  </div>
                                ) : (
                                  <div className="rounded-full bg-gray-300">
                                    <div className="flex justify-between items-center">
                                      <div
                                        className="rounded-full bg-[#552a9a] flex justify-center items-center"
                                        style={{ width: `${action?.userActionProgress}%` }}
                                      >
                                        <p className="text-[8px] text-white">
                                          {action?.userActionProgress}%
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className="col-span-2 flex justify-center items-center mx-2">
                                {action?.userActionStatus === "Completed" ? (
                                  <CheckCircleIcon className="h-4 w-4 text-lime-600" />
                                ) : (
                                  <ExploreOutlinedIcon
                                    fontSize="small"
                                    sx={{
                                      color:
                                        action?.userActionStatus !== "Planned"
                                          ? `${getIconClr(action)}`
                                          : (action?.userActionStatus ===
                                            "Planned" && getDaysDifference(action)<3 )? `${getIconClr(action)}` :"#d1d5db",
                                    }}
                                  />
                                )}
                                <p
                                  className={`text-[9px] mx-1 ${
                                    action?.userActionStatus === "Completed"
                                      ? "bg-lime-600 text-white rounded-full px-2"
                                      : action?.userActionStatus !== "Planned"
                                      ? `${getBgColor(action)} rounded-full px-2`
                                      : (action?.userActionStatus === "Planned" && getDaysDifference(action)<3 )? `${getBgColor(action)} rounded-full px-2` :"text-gray-600"
                                  }`}
                                >
                                  {action?.userActionStatus}
                                </p>
                              </div>

                              <div className="col-span-3 flex justify-between items-center ">
                                <div className="flex justify-start items-center">
                                  <p className="text-gray-600 text-[9px]">Complete by:</p>
                                <CalendarDaysIcon className="m-2 h-5 w-5 text-orange-600" />
                                <p className="flex justify-center items-center text-[9px] rounded-full px-2 py-[1px] bg-white border text-gray-600 border-orange-400">
                                  {formatDate(action?.completeBy)}
                                </p>
                                </div>
                                
                                {/* 13 Jun 2024 Added scchedule risk status */}
                                {/* <p className="text-[8px] px-2">On Track</p> */}
                              {/* </div> */}

                              {/* <div className="col-span-1 flex justify-center items-center mx-2"> */}
                              {/* 10 Sep 2024 - added action.actionCreatedBy filter to show the edit / delete option only for self created actions */}
                                {action.actionCreatedBy === "Self" && (
                                <div className="flex justify-center items-center">
                                  <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                    <PencilSquareIcon
                                      onClick={() =>
                                        navigate(`/userActions/update/${action?.id}`, { state: { triggeringUrl: window.location.pathname } }) // 12 Sep 2024 - added triggeringUrl for navigating back to the originating url 
                                      }
                                      className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                    />
                                  </div>
                                  <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                    <TrashIcon
                                      onClick={() =>
                                        navigate(`/userActions/delete/${action?.id}`)
                                      }
                                      className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                    />
                                  </div>
                                </div>)}
                              </div>

                          </div>
                        
                        {/* User Action Notes Section */}

                        {showDetails[action.id] && (
                          <div>
                            {/* 18 Feb 2025 - Action Desc */}
                            {action?.userActionDesc && (
                              <div className="bg-gray-50 m-1 p-1 rounded border">
                                <p dangerouslySetInnerHTML={createMarkup(action?.userActionDesc)} className="text-xs leading-4 m-1 p-1"></p>

                              </div>
                            )}

                            
                            {/* Show existing action notes */}
                            <div className="bg-gray-300 m-1 p-1 rounded">
                              <p className="font-bold m-1 p-1 text-xs">
                                Action Progress Trail:
                              </p>
                              {action?.userActionNotes &&
                                action?.userActionNotes?.map((userNote, noteIndex) => (
                                  <div
                                    key={userNote._id + noteIndex}
                                    className="bg-gray-50 rounded m-1 p-1"
                                  >
                                    <div className="grid grid-cols-10 justify-between items-center m-1 p-1 w-full">
                                      <div className="col-span-5 flex justify-start items-center">
                                        <div>
                                          <p className="font-bold text-[9px] mx-1 py-[1px]">
                                            {noteIndex + 1}.
                                          </p>
                                        </div>
                                        <div>
                                          <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-18">
                                            {getTimeSince(userNote?.actionNoteDate)}
                                          </p>
                                        </div>
                                        <div>
                                          <p className="text-[9px] mx-1 py-[1px]">
                                            {userNote?.actionNoteText}
                                          </p>
                                        </div>
                                      </div>

                                      <div className="col-span-1">
                                        <p className="text-[9px] text-gray-600">
                                          Action Progress:
                                        </p>
                                      </div>

                                      {/* 11 Jun 2024 - action progress bar for each note */}

                                      <div className="col-span-2 gap-4">
                                        {userNote?.actionNoteProgress < 10 ? (
                                          <div className="rounded-full bg-gray-300 relative">
                                            <div
                                              className="rounded-full bg-[#552a9a]"
                                              style={{
                                                width: `${userNote?.actionNoteProgress}%`,
                                                height: "56%",
                                              }}
                                            ></div>
                                            <p className="text-[8px] text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                              {userNote?.actionNoteProgress}%
                                            </p>
                                          </div>
                                        ) : (
                                          <div className="rounded-full bg-gray-300">
                                            <div className="flex justify-between items-center">
                                              <div
                                                className="rounded-full bg-[#552a9a] flex justify-center items-center"
                                                style={{
                                                  width: `${userNote?.actionNoteProgress}%`,
                                                }}
                                              >
                                                <p className="text-[8px] text-white">
                                                  {userNote?.actionNoteProgress}%
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>

                                      <div className="col-span-2 flex justify-start items-center">
                                        <div className="mx-2 px-2 w-full">
                                          <p className="text-[9px] text-gray-400 mx-1 rounded-full px-2">
                                            {formatDateTime(userNote?.actionNoteDate)}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}

                              {/* Area for new note */}
                              {/* 11 June - added logic for hiding action note addition for completed actions */}
                              {action?.userActionProgress < 100 && (
                                <div className="bg-white m-1 py-1 px-2 rounded">
                                  <div className="m-1 p-1 bg-gray-100 rounded">
                                    <div className="grid grid-cols-10 justify-between items-start">
                                      <div className="col-span-6 m-1 p-1">
                                        <div className="flex justify-start items-center">
                                          <PlusCircleIcon className="m-2 h-5 w-5 text-orange-600" />
                                          <p className="font-bold text-xs">
                                            Add Action Note
                                          </p>
                                        </div>
                                        <textarea
                                          className="w-full rounded p-2 m-1 text-[10px] border border-gray-200"
                                          placeholder="Add your action notes here"
                                          value={actionNotes[action.id] || ""}
                                          onChange={(e) => handleNoteChange(e, action.id)}
                                        />
                                      </div>

                                      <div className="col-span-4 m-1 p-1">
                                        <div className="flex justify-start items-center">
                                          <ArrowUpCircleIcon className="m-2 h-5 w-5 text-orange-600" />
                                          <p className="font-bold text-xs">
                                            Update Action Progress
                                          </p>
                                        </div>
                                        <div className="flex justify-start items-center">
                                          <input
                                            type="range"
                                            id={`slider-${action.id}`}
                                            min="0"
                                            max="100"
                                            className="w-full rounded m-1 h-1"
                                            value={
                                              actionProgress[action.id] ||
                                              action?.userActionProgress
                                            }
                                            onChange={(e) =>
                                              handleProgressChange(e, action.id)
                                            }
                                          />
                                          <input
                                            type="number"
                                            className="w-12 rounded p-2 m-1 text-center text-[10px] border border-gray-200"
                                            placeholder="Action Progress (%)"
                                            value={
                                              actionProgress[action.id] ||
                                              action?.userActionProgress
                                            }
                                            onChange={(e) =>
                                              handleProgressChange(e, action.id)
                                            }
                                          />
                                          <p className="text-xs">%</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <button
                                    className="bg-orange-600 text-white rounded-full px-4 py-[1px] m-1 text-[10px]"
                                    onClick={() =>
                                      handleSave(
                                        userId,
                                        trailId,
                                        sessionId,
                                        action.actionTypeId,
                                        action.id,
                                        action.userActionName,
                                        action.userActionDesc,
                                        action.completeBy
                                      )
                                    }
                                  >
                                    Save
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )})}
              </div>
            ))   
        )}
      </div>
    </div>
  )
}

export default EtUserActionListByGroupWithTabsComp
