import React, {useState} from 'react'
import { useNavigate } from 'react-router'
import { addIcon } from '@/assets/img/imgAssets'
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import PulseLoader from "react-spinners/PulseLoader"
import { useGetCapabilitiesSalesFunctionAreaTypesQuery } from './capabilitiesSalesFunctionAreaTypesApiSlice'

const EtCapabilitiesSalesFunctionAreaTypeList = ({ setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }) => {

  // First Created - 18 Nov 2024
  // Author - Atul Pandey
  // Inherited from functionType
   // Updated - 28 Feb 2025 - updated the salesFunctionArea with props - { setActiveAdminView, onAdd, onUpdate, onDelete, setSelectedEntityId }
  // Updated - 28 Feb 2025 - switched from navigate to onAdd, onUpdate, onDelete, setSelectedEntityId
    
  // 1 - Set navigation
  const navigate = useNavigate()
  
  // 2a - Initialize showDetails state as an object where key is the capabilitiesSalesFunctionAreaType index
  const [showDetails, setShowDetails] = useState({}); // Toggle sales type sections
  const [showSalesTypeSection, setShowSalesTypeSection] = useState({}); // Toggle sales type sections

  const { data: capabilitiesSalesFunctionAreaTypesData, isError, error, isLoading } =
  useGetCapabilitiesSalesFunctionAreaTypesQuery();


// 2b - Group sales function areas by sales type
const groupedSalesFunctionAreas = capabilitiesSalesFunctionAreaTypesData?.ids?.reduce((acc, id) => {
  const capability = capabilitiesSalesFunctionAreaTypesData.entities[id];
  const salesType = capability.capabilitiesSalesType?.capabilitiesSalesTypeName;
  const salesTypePic = capability.capabilitiesSalesType?.pic;

  if (!acc[salesType]) {
    acc[salesType] = { salesTypePic, salesFunctionAreas: [] };
  }
  acc[salesType].salesFunctionAreas.push(capability);
  return acc;
}, {});


  
  // 3a - Toggle the show/hide state for the specific capabilitiesSalesFunctionAreaType index
  const handleToggle = (index) => {
        setShowDetails(prev => prev === index ? null : index);
  }

  // 3b - Toggle Show salesFunctionArea for show creation notes -- 07 Oct 2024
//   const handleToggleDomainTypeNameSection = (sectionId) => {
//     setShowDomainTypeNameSection((prev) => ({
//       ...prev,
//       [sectionId]: !prev[sectionId],
//     }))
// }
    
  // 4 - Create an object for setting inner HTML
    const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
     }
  

  // 5 -  Optional: handle loading and error states
    if (isLoading) return <PulseLoader color={"#ea580c"} />
    if (isError) return <div>Error occurred: {error?.data?.message}</div>


  return (
    <div className='bg-gray-200 m-1 p-1 rounded mx-auto'>
        {/* Header */}
        <div className='flex justify-start items-center m-1 p-1'>

            <div><p className='font-poppins text-2xl m-1 p-1 font-bold'>Et Capabilities SalesFunctionArea Types</p></div>
            <div>
          <img
            src={addIcon}
            alt=""
            className="h-10 w-10 cursor-pointer"
            // onClick={() => navigate("/capabilitiesSalesFunctionAreatypes/create")}
            onClick={onAdd} // 28 Feb 2025
          />
        </div>

        </div>

        {/* CapabilitiesSalesFunctionAreaType Data */}
        <div id='capabilitiesSalesFunctionAreaType-data' className='bg-white rounded m-1 p-1 font-poppins'>
            {capabilitiesSalesFunctionAreaTypesData?.ids.length === 0 && (
                <div><p>No capabilities SalesFunctionArea type data exists. Click above to create one</p></div>
            )}

            <div className='bg-gray-50 rounded'>
              {Object.entries(groupedSalesFunctionAreas)?.map(([salesTypeName, { salesTypePic, salesFunctionAreas }], index) => (
                <div key={salesTypeName + index} className='bg-gray-100 rounded m-1 p-1 border'>
                  {/* Sales Type Header */}
                  <div className='flex justify-start items-center m-1 p-1'>
                    <div onClick={() => setShowSalesTypeSection((prev) => ({
                      ...prev,
                      [salesTypeName]: !prev[salesTypeName],
                    }))}
                      className="mx-2 cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white">
                      {showSalesTypeSection[salesTypeName] ? <MinusIcon className="h-3 w-3 text-orange-600 " /> : <PlusIcon className="h-3 w-3 text-orange-600 " />}
                    </div>
                    <div>
                      <p className='text-sm font-bold mx-1 px-1'>{index + 1} - </p>
                    </div>
                    {salesTypePic && (
                      <div className='flex justify-center items-center h-7 w-7 bg-gray-100 hover:bg-white rounded-full cursor-pointer mr-2'>
                        <img src={salesTypePic} alt="" className='h-5 w-5' />
                      </div>
                    )}
                    <p className='text-sm font-bold mx-2'>{salesTypeName}</p>
                  </div>

                  {/* Render Sales Function Areas */}
                  {showSalesTypeSection[salesTypeName] && (
                    <div>
                      {salesFunctionAreas.map((salesFunction, idx) => (
                        <div key={salesFunction._id} className='bg-gray-200 rounded m-1 p-1 border'>
                          <div className='flex justify-between items-center m-1 p-1'>
                            <div className='flex justify-start items-center mx-2'>
                              <div onClick={() => setShowDetails((prev) => ({ ...prev, [salesFunction._id]: !prev[salesFunction._id] }))}>
                                {showDetails[salesFunction._id] ? (
                                  <MinusIcon className="h-3 w-3 text-orange-600 " />
                                ) : (
                                  <PlusIcon className="h-3 w-3 text-orange-600 " />
                                )}
                              </div>

                              <p className='text-xs font-bold mx-1 px-1'>{idx + 1} - {salesFunction.capabilitiesSalesFunctionAreaTypeName}</p>
                            </div>
                            <div className='flex justify-start items-center'>
                              <div 
                              
                              // onClick={() => navigate(`/capabilitiesSalesFunctionAreaTypes/update/${salesFunction._id}`)} 
                              onClick={() => onUpdate(salesFunction._id)} // 28 Feb 2025
                              
                              className='cursor-pointer flex justify-center 
                              items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white'>
                                <PencilSquareIcon className="h-3 w-3 text-orange-600 " />
                              </div>
                              <div 
                              
                              // onClick={() => navigate(`/capabilitiesSalesFunctionAreaTypes/delete/${salesFunction._id}`)} 
                              onClick={() => onDelete(salesFunction._id)} // 28 Feb 2025
                              
                              
                              className='cursor-pointer flex justify-center items-center rounded-full h-5 w-5 hover:shadow-md hover:z-30 hover:bg-white'>
                                <TrashIcon className="h-3 w-3 text-orange-600 " />
                              </div>
                            </div>
                          </div>

                          {/* Sales Function Details */}
                          {showDetails[salesFunction._id] && (
                            <div className='bg-white rounded text-xs py-1'>
                              <p className='font-bold m-1 p-1 border-b-2 border-orange-600'>Description:</p>
                              <div dangerouslySetInnerHTML={createMarkup(salesFunction.capabilitiesSalesFunctionAreaTypeDesc)} className='m-1 p-1'></div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>


        </div>

    </div>
  )
}

export default EtCapabilitiesSalesFunctionAreaTypeList