import React, { useState, useEffect } from 'react'
import useTitle from "../../../hooks/useTitle"
import {
    PlusIcon,
    MinusIcon,
    PencilSquareIcon,
  } from "@heroicons/react/24/outline"
import PulseLoader from "react-spinners/PulseLoader"
import EtContentEditorLight2 from "../../../utils/EtContentEditorLight2"
import EtBtnFormBasic from "../../../components/common/buttons/EtBtnFormBasic"
import { useNavigate } from "react-router"

import { useUpdateUserImprintHiddenMagicMutation, useGetUserImprintHiddenMagicsQuery } from './userImprintHiddenMagicsApiSlice'

import { produce } from "immer";

const EtUpdateUserImprintHiddenMagicComp = ({
    id,
    userId, 
    userOrgId, 
    triggeringUrl, 
    onClose
  }) => {

  // First Created - 22 Dec 2024
  // Author - Atul Pandey
  // Inherited from the EtCreateUserImprintGratitudeComp component
  

  // 0 - Set Page title & variables
  useTitle('User Imprint Hidden Magic - Update')

  console.log('id is:', id)
  // console.log('userId is:', userId)
  // console.log('userOrgId is:', userOrgId)
  
  // 1a - set page navigations
  const navigate = useNavigate()

  // 1b - set form states
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState({
    aspirationsCollapsed: true,
    inspirationsCollapsed: true,
    magicMomentsCollapsed: true,
    superPowersCollapsed: true,
    selfNotesCollapsed:true,
  }); // 25 Dec 2024
  // const [editorKey, setEditorKey] = useState('HiddenMagicDesc-' + Date.now());
  // const [editorValue, setEditorValue] = useState('');

  // 2a - set the mutation to create the new user imprint gratitude data
    const [updateUserImprintHiddenMagic, {
      isLoading,
      isSuccess,
      isError,
      error,
    }] = useUpdateUserImprintHiddenMagicMutation()
  
  // 2b - Get existing user creations Data from the backend
  const { 
    data: userImprintHiddenMagicData,
    isLoading: isFetching,
    isError: isFetchError,
    error: fetchError,
  } = useGetUserImprintHiddenMagicsQuery()
  // console.log('userImprintHiddenMagicData', userImprintHiddenMagicData)
  const userImprintHiddenMagicRecords = userImprintHiddenMagicData?.ids
  ?.map(id => userImprintHiddenMagicData?.entities[id])
  ?.filter(item=> item._id === id && item.userId._id === userId && item.userOrgId._id === userOrgId) || []
  console.log('userImprintHiddenMagics', userImprintHiddenMagicRecords)

  // 3 - Initialize Form Data State
  const [formData, setFormData] = useState({
    userImprintHiddenMagicName: "",
    userImprintHiddenMagicDesc: "",
    userAspirations:[],
    userInspirations:[],
    userMagicMoments:[],
    userSuperPowers:[],
    userHiddenMagicSelfNotes:[],
    pic: "",
    active: true, // Default active state
  });

  // 4a - Set the FormData based on the incoming data
  useEffect(() => {
    if (userImprintHiddenMagicRecords?.length > 0 && !isFormInitialized) {
      const selectedRecord = userImprintHiddenMagicRecords[0];

      setFormData({
        userImprintHiddenMagicName: selectedRecord?.userImprintHiddenMagicName || "",
        userImprintHiddenMagicDesc: selectedRecord?.userImprintHiddenMagicDesc || "",
        userAspirations: selectedRecord?.userAspirations || [],
        userInspirations: selectedRecord?.userInspirations || [],
        userMagicMoments: selectedRecord?.userMagicMoments || [],
        userSuperPowers: selectedRecord?.userSuperPowers || [],
        userHiddenMagicSelfNotes: selectedRecord?.userHiddenMagicSelfNotes || [],
        aboutMe: selectedRecord?.aboutMe || '',
        pic: selectedRecord?.pic || '',
        active: selectedRecord?.active ?? true,
      });

      setIsFormInitialized(true); // Prevent future overwrites
    }
  }, [userImprintHiddenMagicRecords, isFormInitialized]);

  // 4b - Post formData initialization effect -- 25 Dec 2024
  // useEffect(() => {
  //   if (formData) {
  //     setCollapsedSections(prevState => ({
  //       ...prevState,
  //       ...formData.userAspirations?.reduce((acc, _, index) => ({
  //         ...acc,
  //         [`aspirationNotes-${index}`]: true
  //       }), {}),
  //       ...formData.userInspirations?.reduce((acc, _, index) => ({
  //         ...acc,
  //         [`inspirationNotes-${index}`]: true
  //       }), {}),
  //       ...formData.userMagicMoments?.reduce((acc, _, index) => ({
  //         ...acc,
  //         [`magicMomentNotes-${index}`]: true
  //       }), {}),
  //       ...formData.userSuperPowers?.reduce((acc, _, index) => ({
  //         ...acc,
  //         [`superPowerNotes-${index}`]: true
  //       }), {}),
  //       ...formData.userHiddenMagicSelfNotes?.reduce((acc, _, index) => ({
  //         ...acc,
  //         [`selfNotes-${index}`]: true
  //       }), {})
  //     }));
  //   }
  // }, [formData]); // Add formData as dependency
  
  // 5a - Convert Image to Base64
  const convertPicToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  // 5b - Handle Form Changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "pic") {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  // 5c - Toggle show / collapse each section 
  const toggleSection = (key) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // 6 - Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Step 1: Convert the pic field to Base64 if it exists
      const picBase64 = formData.pic ? await convertPicToBase64(formData.pic) : undefined;
  
      // Step 2: Compare current formData with initial data to determine changes
      const initialData = userImprintHiddenMagicRecords[0]; // Assume only one record is being updated
      const changes = {};
  
      // Compare top-level fields
      if (formData.userImprintHiddenMagicName !== initialData.userImprintHiddenMagicName) {
        changes.userImprintHiddenMagicName = formData.userImprintHiddenMagicName.trim() || undefined;
      }
      if (formData.userImprintHiddenMagicDesc !== initialData.userImprintHiddenMagicDesc) {
        changes.userImprintHiddenMagicDesc = formData.userImprintHiddenMagicDesc.trim() || undefined;
      }
      if (formData.active !== initialData.active) {
        changes.active = formData.active;
      }
      if (picBase64) {
        changes.pic = picBase64;
      }
  
      // Step 3: Process arrays to identify new, updated, and deleted items
      const processArrayChanges = (currentArray, initialArray = [], deletedArrayKey) => {
        const changesArray = [];
        const existingIds = initialArray.map((item) => item._id);
  
        // Identify new or updated items
        currentArray.forEach((item) => {
          if (!item._id || !existingIds.includes(item._id)) {
            // New item
            changesArray.push(item);
          } else {
            // Existing item, check for changes
            const initialItem = initialArray.find((initItem) => initItem._id === item._id);
            if (JSON.stringify(item) !== JSON.stringify(initialItem)) {
              changesArray.push(item);
            }
          }
        });
  
        // Include deleted items
        if (deletedArrayKey && formData[deletedArrayKey]) {
          changesArray.push(...formData[deletedArrayKey]);
        }
  
        return changesArray;
      };
  
      // Handle each array field
      changes.userAspirations = processArrayChanges(
        formData.userAspirations,
        initialData.userAspirations,
        "deletedAspirations"
      );
      changes.userInspirations = processArrayChanges(
        formData.userInspirations,
        initialData.userInspirations,
        "deletedInspirations"
      );
      changes.userMagicMoments = processArrayChanges(
        formData.userMagicMoments,
        initialData.userMagicMoments,
        "deletedMagicMoments"
      );
      changes.userSuperPowers = processArrayChanges(
        formData.userSuperPowers,
        initialData.userSuperPowers,
        "deletedSuperPowers"
      );
      changes.userHiddenMagicSelfNotes = processArrayChanges(
        formData.userHiddenMagicSelfNotes,
        initialData.userHiddenMagicSelfNotes,
        "deletedSelfNotes"
      );
  
      // Step 4: Construct the payload
      const payload = {
        imprintId: id, // Overall record ID
        userId,
        userOrgId,
        ...changes, // Include only the changes
      };
  
      // Step 5: Submit the payload
      console.log("Payload to be submitted:", payload); // For debugging
      await updateUserImprintHiddenMagic(payload).unwrap();
  
      // Step 6: Reset the form upon success
      setFormData({
        userImprintHiddenMagicName: "",
        userImprintHiddenMagicDesc: "",
        userAspirations: [],
        userInspirations: [],
        userMagicMoments: [],
        userSuperPowers: [],
        userHiddenMagicSelfNotes: [],
        pic: "",
        active: true,
      });
  
      // Step 7: Redirect or close the form
      if (onClose) {
        onClose();
      } else {
        navigate(triggeringUrl || "/userMagicHome");
      }
    } catch (error) {
      // Step 8: Handle errors
      console.error("Error submitting form:", error);
    }
  };
  
  
  // 7 - Reset Form on Successful Submission
        useEffect(() => {
          if (isSuccess) {
            setFormData({
              userImprintHiddenMagicName: "",
              userImprintHiddenMagicDesc: "",
              userAspirations:[],
              userInspirations:[],
              userMagicMoments:[],
              userSuperPowers:[],
              userHiddenMagicSelfNotes:[],
              pic: "",
              active: true, // Default active state
            });
      
            if (onClose) {
              onClose();
            } else {
              navigate(triggeringUrl || "/userMagicHome");
            }
          }
        }, [isSuccess, navigate]);
      
      // 8 - Handle Clear
      const handleClear = () => {
      setFormData({
        userImprintHiddenMagicName: "",
        userImprintHiddenMagicDesc: "",
        userAspirations:[],
        userInspirations:[],
        userMagicMoments:[],
        userSuperPowers:[],
        userHiddenMagicSelfNotes:[],
        pic: "",
        active: true, // Default active state
      });
      };
  
      // 9 - Handle Cancel
      const handleCancel = () => {
      if (onClose) {
          onClose();
      } else {
          navigate(triggeringUrl || "/userMagicHome");
      }
      };

  return (
    <div className="mx-auto w-full m-1 p-1 bg-white rounded shadow-md shadow-black">
      {isLoading && <PulseLoader color={'#ea580c'} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">{error?.data?.message}</p>
      )}

      <p className="p-1 m-1 font-bold font-poppins text-xl">
        Update User Hidden Magic
      </p>

      <form onSubmit={handleSubmit} className="m-1 p-1 text-xs font-poppins">
        {/* 1. HiddenMagic Name */}
        <div id="hiddenMagicName" className="bg-gray-100 rounded p-1 m-1">
          <label
            htmlFor="userImprintHiddenMagicName"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
            HiddenMagic Name
          </label>
          <input
            type="text"
            name="userImprintHiddenMagicName"
            placeholder="Enter a name or title"
            value={formData.userImprintHiddenMagicName}
            onChange={(e) => {
              const value = e.target.value;
              setFormData((prevState) =>
                produce(prevState, (draft) => {
                  draft.userImprintHiddenMagicName = value;
                })
              );
            }}
            className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
          />
        </div>

        {/* 2. HiddenMagic Description */}
        <div id="hiddenMagicDesc" className="bg-gray-100 rounded p-1 m-1">
          <label
            htmlFor="userImprintHiddenMagicDesc"
            className="block text-gray-600 text-sm font-bold mb-1"
          >
            HiddenMagic Description
          </label>
          <EtContentEditorLight2
            value={formData.userImprintHiddenMagicDesc || ""}
            handleChange={(content) => {
              setFormData((prevState) =>
                produce(prevState, (draft) => {
                  draft.userImprintHiddenMagicDesc = content;
                })
              );
            }}
            placeholderText="Write your hidden magic description here..."
            editorKey={`hiddenMagicDesc-main-${id}`}
          />
        </div>

        {/* 3. HiddenMagic - Aspirations */}
        <div id="aspirations" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center">
              <button
                type="button"
                onClick={() =>
                  setCollapsedSections((prevState) => ({
                    ...prevState,
                    aspirationsCollapsed: !prevState.aspirationsCollapsed,
                  }))
                }
                className="text-orange-600 font-bold m-1 p-1 rounded"
              >
                {collapsedSections.aspirationsCollapsed ? (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                )}
              </button>
              <p className="font-bold text-sm mx-1 px-1">My Aspirations</p>
            </div>
            
            {/* <p className="font-bold text-sm">User Aspirations</p> */}
            
            <button
              type="button"
              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
              onClick={() => {
                const timestamp = Date.now();
  
                setFormData((prevState) =>
                  produce(prevState, (draft) => {
                    draft.userAspirations.unshift({
                      aspirationName: "",
                      aspirationDesc: "",
                      aspirationNotes: [],
                      aspirationDate: new Date().toISOString(),
                      aspirationUpdateDate: new Date().toISOString(),
                      _tempId: timestamp,
                    });
                  })
                );
                setCollapsedSections((prevState) => ({
                  ...prevState,
                  aspirationsCollapsed: false,
                  [`aspiration-0`]: false,
                  [`aspirationNotes-0`]: true,  // Set new aspiration's notes to collapsed
                  ...formData.userAspirations.reduce((acc, _, index) => ({
                    ...acc,
                    [`aspiration-${index + 1}`]: true,
                    [`aspirationNotes-${index + 1}`]: true
                  }), {})
                }));
              }}
            >
              <PlusIcon className="h-4 w-4 inline" />
            </button>
          </div>

          {!collapsedSections.aspirationsCollapsed && formData.userAspirations?.map((aspiration, aspirationIndex) => (
            <div
              key={aspirationIndex}
              className="p-2 border rounded mb-2 bg-white"
            >
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={() =>
                      toggleSection(`aspiration-${aspirationIndex}`)
                    }
                    className="text-orange-600 font-bold m-1 p-1 rounded"
                  >
                    {collapsedSections[`aspiration-${aspirationIndex}`] ? (
                      <PlusIcon className="h-4 w-4 text-orange-600" />
                    ) : (
                      <MinusIcon className="h-4 w-4 text-orange-600" />
                    )}
                  </button>
                  <p className="font-bold text-sm mx-1 px-1">
                    Aspiration {aspirationIndex + 1}
                  </p>
                </div>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) =>
                      produce(prevState, (draft) => {
                        // draft.userAspirations.splice(aspirationIndex, 1);
                        const removedAspiration = draft.userAspirations[aspirationIndex];
                        if (removedAspiration._id) {
                          // Mark existing aspiration as deleted
                          removedAspiration.delete = true;
                          if (!draft.deletedAspirations) {
                            draft.deletedAspirations = [];
                          }
                          draft.deletedAspirations.push(removedAspiration);
                        }
                        // Remove it from the visible list
                        draft.userAspirations.splice(aspirationIndex, 1);
                      })
                    );
                  }}
                >
                  <MinusIcon className="h-4 w-4 inline" />
                </button>
              </div>

              {!collapsedSections[`aspiration-${aspirationIndex}`] && (
                <div>
                  {/* Aspiration Name */}
                  <div className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`aspirationName-${aspirationIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Aspiration Name
                    </label>
                    <input
                      type="text"
                      name={`aspirationName-${aspirationIndex}`}
                      placeholder="Enter the aspiration name"
                      value={aspiration.aspirationName}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData((prevState) =>
                          produce(prevState, (draft) => {
                            draft.userAspirations[aspirationIndex].aspirationName = value;
                            draft.userAspirations[aspirationIndex].aspirationUpdateDate = new Date().toISOString();
                          })
                        );
                      }}
                      className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                    />
                  </div>

                  {/* Aspiration Description */}
                  <div className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`aspirationDesc-${aspirationIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Aspiration Description
                    </label>
                    <EtContentEditorLight2
                      value={aspiration.aspirationDesc || ""}
                      handleChange={(content) => {
                        setFormData((prevState) =>
                          produce(prevState, (draft) => {
                            draft.userAspirations[aspirationIndex].aspirationDesc = content;
                            draft.userAspirations[aspirationIndex].aspirationUpdateDate = new Date().toISOString();
                          })
                        );
                      }}
                      placeholderText="Write the aspiration description here..."
                      editorKey={`aspirationDesc-${aspirationIndex}-${aspiration._tempId || aspiration._id || 'new'}`}
                    />
                  </div>

                  {/* Aspiration Notes */}
                  <div className="bg-gray-100 rounded p-2 m-1">
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex items-center">
                        <button
                          type="button"
                          onClick={() =>
                            toggleSection(`aspirationNotes-${aspirationIndex}`)
                          }
                          className="text-orange-600 font-bold m-1 p-1 rounded"
                        >
                          {collapsedSections[`aspirationNotes-${aspirationIndex}`] ? (
                            <PlusIcon className="h-4 w-4 text-orange-600" />
                          ) : (
                            <MinusIcon className="h-4 w-4 text-orange-600" />
                          )}
                        </button>
                        <p className="font-bold text-sm">Aspiration Notes</p>
                      </div>
                      
                      <button
                        type="button"
                        className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                        onClick={() => {
                          const timestamp = Date.now(); // New timestamp for the note
                          setFormData((prevState) =>
                            produce(prevState, (draft) => {
                              draft.userAspirations[aspirationIndex].aspirationNotes.push({
                                aspirationNoteText: "",
                                aspirationNoteDate: new Date().toISOString(),
                                aspirationNoteUpdateDate: new Date().toISOString(),
                                _tempId: timestamp,  // Add _tempId for the note
                              });
                              draft.userAspirations[aspirationIndex].aspirationUpdateDate = new Date().toISOString();
                            })
                          );
                        }}
                      >
                        <PlusIcon className="h-4 w-4 inline" />
                      </button>
                    </div>

                    {!collapsedSections[`aspirationNotes-${aspirationIndex}`] && aspiration.aspirationNotes?.map((note, noteIndex) => (
                      <div
                        key={noteIndex}
                        className="p-2 border rounded mb-2 bg-white"
                      >
                        <div className="flex justify-between items-center mb-2">
                          <p className="font-bold text-xs">Note {noteIndex + 1}</p>
                          <button
                            type="button"
                            className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                            onClick={() => {
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  const removedNote = draft.userAspirations[aspirationIndex].aspirationNotes[noteIndex];
                                  if (removedNote._id) {
                                    // Mark existing note as deleted
                                    removedNote.delete = true;
                                    if (!draft.deletedAspirationNotes) {
                                      draft.deletedAspirationNotes = [];
                                    }
                                    draft.deletedAspirationNotes.push(removedNote);
                                  }
                                  // Remove it from the visible list
                                  draft.userAspirations[aspirationIndex].aspirationNotes.splice(noteIndex, 1);
                                  draft.userAspirations[aspirationIndex].aspirationUpdateDate = new Date().toISOString();
                                })
                              );
                            }}
                          >
                            <MinusIcon className="h-4 w-4 inline" />
                          </button>
                        </div>

                        {/* Note Text */}
                        <div className="bg-gray-100 rounded p-1 m-1">
                          <label
                            htmlFor={`noteText-${aspirationIndex}-${noteIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            Note Text
                          </label>
                          <EtContentEditorLight2
                            value={note.aspirationNoteText || ""}
                            handleChange={(content) => {
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  draft.userAspirations[aspirationIndex].aspirationNotes[noteIndex].aspirationNoteText = content;
                                  draft.userAspirations[aspirationIndex].aspirationNotes[noteIndex].aspirationNoteUpdateDate = new Date().toISOString();
                                  draft.userAspirations[aspirationIndex].aspirationUpdateDate = new Date().toISOString();
                                })
                              );
                            }}
                            placeholderText="Write your note here..."
                            editorKey={`aspirationNote-${aspirationIndex}-${noteIndex}-${note._tempId || note._id || 'new'}`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* 4. HiddenMagic - Inspirations */}
        <div id="inspirations" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">

            <div className="flex items-center">
              <button
                type="button"
                onClick={() =>
                  setCollapsedSections((prevState) => ({
                    ...prevState,
                    inspirationsCollapsed: !prevState.inspirationsCollapsed,
                  }))
                }
                className="text-orange-600 font-bold m-1 p-1 rounded"
              >
                {collapsedSections.inspirationsCollapsed ? (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                )}
              </button>
              <p className="font-bold text-sm mx-1 px-1">My Inspirations</p>
            </div>

            {/* <p className="font-bold text-sm">User Inspirations</p> */}
            <button
              type="button"
              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
              onClick={() => {
                const timestamp = Date.now();

                setFormData((prevState) =>
                  produce(prevState, (draft) => {
                    draft.userInspirations.unshift({
                      inspirationName: "",
                      inspirationDesc: "",
                      inspirationNotes: [],
                      inspirationDate: new Date().toISOString(),
                      inspirationUpdateDate: new Date().toISOString(),
                      _tempId: timestamp,
                    });
                  })
                );
                setCollapsedSections((prevState) => ({
                  ...prevState,
                  inspirationsCollapsed: false,
                  [`inspiration-0`]: false,
                  [`inspirationNotes-0`]: true,  // Set new inspiration's notes to collapsed
                  ...formData.userInspirations.reduce((acc, _, index) => ({
                    ...acc,
                    [`inspiration-${index + 1}`]: true,
                    [`inspirationNotes-${index + 1}`]: true  // Set existing inspirations' notes to collapsed
                  }), {})
                }));
              }}
            >
              <PlusIcon className="h-4 w-4 inline" />
            </button>
          </div>

          {!collapsedSections.inspirationsCollapsed && formData.userInspirations?.map((inspiration, inspirationIndex) => (
            <div
              key={inspirationIndex}
              className="p-2 border rounded mb-2 bg-white"
            >
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={() =>
                      toggleSection(`inspiration-${inspirationIndex}`)
                    }
                    className="text-orange-600 font-bold m-1 p-1 rounded"
                  >
                    {collapsedSections[`inspiration-${inspirationIndex}`] ? (
                      <PlusIcon className="h-4 w-4 text-orange-600" />
                    ) : (
                      <MinusIcon className="h-4 w-4 text-orange-600" />
                    )}
                  </button>
                  <p className="font-bold text-sm mx-1 px-1">
                    Inspiration {inspirationIndex + 1}
                  </p>
                </div>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) =>
                      produce(prevState, (draft) => {
                        // draft.userInspirations.splice(inspirationIndex, 1);
                        const removedInspiration = draft.userInspirations[inspirationIndex];
                        if (removedInspiration._id) {
                          // Mark existing inspiration as deleted
                          removedInspiration.delete = true;
                          if (!draft.deletedInspirations) {
                            draft.deletedInspirations = [];
                          }
                          draft.deletedInspirations.push(removedInspiration);
                        }
                        // Remove it from the visible list
                        draft.userInspirations.splice(inspirationIndex, 1);
                      })
                    );
                  }}
                >
                  <MinusIcon className="h-4 w-4 inline" />
                </button>
              </div>

              {!collapsedSections[`inspiration-${inspirationIndex}`] && (
                <div>
                  {/* Inspiration Name */}
                  <div className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`inspirationName-${inspirationIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Inspiration Name
                    </label>
                    <input
                      type="text"
                      name={`inspirationName-${inspirationIndex}`}
                      placeholder="Enter the inspiration name"
                      value={inspiration.inspirationName}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData((prevState) =>
                          produce(prevState, (draft) => {
                            draft.userInspirations[inspirationIndex].inspirationName =
                              value;
                            draft.userInspirations[
                              inspirationIndex
                            ].inspirationUpdateDate = new Date().toISOString(); // Update the date
                          })
                        );
                      }}
                      className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                    />
                  </div>

                  {/* Inspiration Description */}
                  <div className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`inspirationDesc-${inspirationIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Inspiration Description
                    </label>
                    <EtContentEditorLight2
                      value={inspiration.inspirationDesc || ""}
                      handleChange={(content) => {
                        setFormData((prevState) =>
                          produce(prevState, (draft) => {
                            draft.userInspirations[
                              inspirationIndex
                            ].inspirationDesc = content;
                            draft.userInspirations[
                              inspirationIndex
                            ].inspirationUpdateDate = new Date().toISOString(); // Update the date
                          })
                        );
                      }}
                      placeholderText="Write the inspiration description here..."
                      editorKey={`inspirationDesc-${inspirationIndex}-${inspiration._tempId || inspiration._id || 'new'}`}
                    />
                  </div>

                  {/* Inspiration Notes */}
                  <div className="bg-gray-100 rounded p-2 m-1">
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex items-center">
                        <button
                          type="button"
                          onClick={() =>
                            toggleSection(`inspirationNotes-${inspirationIndex}`)
                          }
                          className="text-orange-600 font-bold m-1 p-1 rounded"
                        >
                          {collapsedSections[`inspirationNotes-${inspirationIndex}`] ? (
                            <PlusIcon className="h-4 w-4 text-orange-600" />
                          ) : (
                            <MinusIcon className="h-4 w-4 text-orange-600" />
                          )}
                        </button>
                        <p className="font-bold text-sm">Inspiration Notes</p>
                      </div>
                      
                      <button
                        type="button"
                        className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                        onClick={() => {
                          const timestamp = Date.now();

                          setFormData((prevState) =>
                            produce(prevState, (draft) => {
                              draft.userInspirations[
                                inspirationIndex
                              ].inspirationNotes.unshift({
                                inspirationNoteText: "",
                                inspirationNoteDate: new Date().toISOString(),
                                inspirationNoteUpdateDate: new Date().toISOString(),
                                _tempId: timestamp,
                              });
                              draft.userInspirations[
                                inspirationIndex
                              ].inspirationUpdateDate = new Date().toISOString(); // Update the parent date
                            })
                          );
                        }}
                      >
                        <PlusIcon className="h-4 w-4 inline" />
                      </button>
                    </div>

                    {!collapsedSections[`inspirationNotes-${inspirationIndex}`] && inspiration.inspirationNotes?.map((note, noteIndex) => (
                      <div
                        key={noteIndex}
                        className="p-2 border rounded mb-2 bg-white"
                      >
                        <div className="flex justify-between items-center mb-2">
                          <p className="font-bold text-xs">Note {noteIndex + 1}</p>
                          <button
                            type="button"
                            className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                            onClick={() => {
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  const removedNote = draft.userInspirations[inspirationIndex].inspirationNotes[noteIndex];
                                  if (removedNote._id) {
                                    // Mark existing note as deleted
                                    removedNote.delete = true;
                                    if (!draft.deletedInspirationNotes) {
                                      draft.deletedInspirationNotes = [];
                                    }
                                    draft.deletedInspirationNotes.push(removedNote);
                                  }
                                  // Remove it from the visible list
                                  draft.userInspirations[inspirationIndex].inspirationNotes.splice(noteIndex, 1);
                                  draft.userInspirations[inspirationIndex].inspirationUpdateDate = new Date().toISOString(); // Update the parent date
                                })
                              );
                            }}
                          >
                            <MinusIcon className="h-4 w-4 inline" />
                          </button>
                        </div>

                        {/* Note Text */}
                        <div className="bg-gray-100 rounded p-1 m-1">
                          <label
                            htmlFor={`noteText-${inspirationIndex}-${noteIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            Note Text
                          </label>
                          <EtContentEditorLight2
                            value={note.inspirationNoteText || ""}
                            handleChange={(content) => {
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  draft.userInspirations[
                                    inspirationIndex
                                  ].inspirationNotes[noteIndex].inspirationNoteText =
                                    content;
                                  draft.userInspirations[
                                    inspirationIndex
                                  ].inspirationNotes[
                                    noteIndex
                                  ].inspirationNoteUpdateDate = new Date().toISOString(); // Update note date
                                  draft.userInspirations[
                                    inspirationIndex
                                  ].inspirationUpdateDate = new Date().toISOString(); // Update parent date
                                })
                              );
                            }}
                            placeholderText="Write your note here..."
                            editorKey={`inspirationNote-${inspirationIndex}-${noteIndex}-${note._tempId || note._id || 'new'}`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* 5. HiddenMagic - Magic Moments */}
        <div id="magicMoments" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center">
              <button
                type="button"
                onClick={() =>
                  setCollapsedSections((prevState) => ({
                    ...prevState,
                    magicMomentsCollapsed: !prevState.magicMomentsCollapsed,
                  }))
                }
                className="text-orange-600 font-bold m-1 p-1 rounded"
              >
                {collapsedSections.magicMomentsCollapsed ? (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                )}
              </button>
              <p className="font-bold text-sm mx-1 px-1">My Magic Moments</p>
            </div>
            
            {/* <p className="font-bold text-sm">User Magic Moments</p> */}
            <button
              type="button"
              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
              onClick={() => {
                const timestamp = Date.now(); // Create timestamp first
                
                setFormData((prevState) =>
                  produce(prevState, (draft) => {
                    draft.userMagicMoments.unshift({
                      magicMomentName: "",
                      magicMomentDesc: "",
                      magicMomentNotes: [],
                      magicMomentDate: new Date().toISOString(),
                      magicMomentUpdateDate: new Date().toISOString(),
                      _tempId: timestamp, // Add this to ensure stable keys before _id exists
                    });
                  })
                );
                setCollapsedSections((prevState) => ({
                  ...prevState,
                  magicMomentsCollapsed: false,
                  [`magicMoment-0`]: false,
                  [`magicMomentNotes-0`]: false,

                  ...formData.userMagicMoments.reduce((acc, _, index) => ({
                    ...acc,
                    [`magicMoment-${index + 1}`]: true,
                    [`magicMomentNotes-${index + 1}`]: true  // Set existing magicMoments' notes to collapsed
                  }), {})
                }));
              }}
            >
              <PlusIcon className="h-4 w-4 inline" />
            </button>
          </div>

          {!collapsedSections.magicMomentsCollapsed && formData.userMagicMoments.map((moment, momentIndex) => (
            <div
              key={momentIndex}
              className="p-2 border rounded mb-2 bg-white"
            >
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={() =>
                      toggleSection(`magicMoment-${momentIndex}`)
                    }
                    className="text-orange-600 font-bold m-1 p-1 rounded"
                  >
                    {collapsedSections[`magicMoment-${momentIndex}`] ? (
                      <PlusIcon className="h-4 w-4 text-orange-600" />
                    ) : (
                      <MinusIcon className="h-4 w-4 text-orange-600" />
                    )}
                  </button>
                  <p className="font-bold text-sm mx-1 px-1">
                    Magic Moment {momentIndex + 1}
                  </p>
                </div>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) =>
                      produce(prevState, (draft) => {
                        // draft.userMagicMoments.splice(momentIndex, 1);
                        const removedMagicMoment = draft.userMagicMoments[momentIndex];
                        if (removedMagicMoment._id) {
                          // Mark existing magic moment as deleted
                          removedMagicMoment.delete = true;
                          if (!draft.deletedMagicMoments) {
                            draft.deletedMagicMoments = [];
                          }
                          draft.deletedMagicMoments.push(removedMagicMoment);
                        }
                        // Remove it from the visible list
                        draft.userMagicMoments.splice(momentIndex, 1);
                      })
                    );
                  }}
                >
                  <MinusIcon className="h-4 w-4 inline" />
                </button>
              </div>

              {!collapsedSections[`magicMoment-${momentIndex}`] && (
                <div>
                  {/* Magic Moment Name */}
                  <div className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`magicMomentName-${momentIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Magic Moment Name
                    </label>
                    <input
                      type="text"
                      name={`magicMomentName-${momentIndex}`}
                      placeholder="Enter the magic moment name"
                      value={moment.magicMomentName}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData((prevState) =>
                          produce(prevState, (draft) => {
                            draft.userMagicMoments[momentIndex].magicMomentName =
                              value;
                            draft.userMagicMoments[
                              momentIndex
                            ].magicMomentUpdateDate = new Date().toISOString(); // Update the date
                          })
                        );
                      }}
                      className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                    />
                  </div>

                  {/* Magic Moment Description */}
                  <div className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`magicMomentDesc-${momentIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Magic Moment Description
                    </label>
                    <EtContentEditorLight2
                      value={moment.magicMomentDesc || ""}
                      handleChange={(content) => {
                        setFormData((prevState) =>
                          produce(prevState, (draft) => {
                            draft.userMagicMoments[
                              momentIndex
                            ].magicMomentDesc = content;
                            draft.userMagicMoments[
                              momentIndex
                            ].magicMomentUpdateDate = new Date().toISOString(); // Update the date
                          })
                        );
                      }}
                      placeholderText="Write the magic moment description here..."
                      editorKey={`magicMomentDesc-${momentIndex}-${moment._tempId || moment._id || 'new'}`}
                    />
                  </div>

                  {/* Magic Moment Notes */}
                  <div className="bg-gray-100 rounded p-2 m-1">
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex items-center">
                          <button
                            type="button"
                            onClick={() =>
                              toggleSection(`magicMomentNotes-${momentIndex}`)
                            }
                            className="text-orange-600 font-bold m-1 p-1 rounded"
                          >
                            {collapsedSections[`magicMomentNotes-${momentIndex}`] ? (
                              <PlusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                              <MinusIcon className="h-4 w-4 text-orange-600" />
                            )}
                          </button>
                          <p className="font-bold text-sm">Magic Moment Notes</p>
                      </div>

                      <button
                        type="button"
                        className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                        onClick={() => {
                          const timestamp = Date.now();

                          setFormData((prevState) =>
                            produce(prevState, (draft) => {
                              draft.userMagicMoments[
                                momentIndex
                              ].magicMomentNotes.unshift({
                                magicMomentNoteText: "",
                                magicMomentNoteDate: new Date().toISOString(),
                                magicMomentNoteUpdateDate: new Date().toISOString(),
                                _tempId: timestamp,
                              });
                              draft.userMagicMoments[
                                momentIndex
                              ].magicMomentUpdateDate = new Date().toISOString(); // Update the parent date
                            })
                          );
                        }}
                      >
                        <PlusIcon className="h-4 w-4 inline" />
                      </button>
                    </div>

                    {!collapsedSections[`magicMomentNotes-${momentIndex}`] && moment.magicMomentNotes?.map((note, noteIndex) => (
                      <div
                        key={noteIndex}
                        className="p-2 border rounded mb-2 bg-white"
                      >
                        <div className="flex justify-between items-center mb-2">
                          <p className="font-bold text-xs">Note {noteIndex + 1}</p>
                          <button
                            type="button"
                            className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                            onClick={() => {
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  const removedNote = draft.userMagicMoments[momentIndex].magicMomentNotes[noteIndex];
                                  if (removedNote._id) {
                                    // Mark existing note as deleted
                                    removedNote.delete = true;
                                    if (!draft.deletedMagicMomentNotes) {
                                      draft.deletedMagicMomentNotes = [];
                                    }
                                    draft.deletedMagicMomentNotes.push(removedNote);
                                  }
                                  // Remove it from the visible list
                                  draft.userMagicMoments[momentIndex].magicMomentNotes.splice(noteIndex, 1);
                                  draft.userMagicMoments[momentIndex].magicMomentUpdateDate = new Date().toISOString(); // Update the parent date
                                })
                              );
                            }}
                          >
                            <MinusIcon className="h-4 w-4 inline" />
                          </button>
                        </div>

                        {/* Note Text */}
                        <div className="bg-gray-100 rounded p-1 m-1">
                          <label
                            htmlFor={`noteText-${momentIndex}-${noteIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            Note Text
                          </label>
                          <EtContentEditorLight2
                            value={note.magicMomentNoteText || ""}
                            handleChange={(content) => {
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  draft.userMagicMoments[
                                    momentIndex
                                  ].magicMomentNotes[noteIndex].magicMomentNoteText =
                                    content;
                                  draft.userMagicMoments[
                                    momentIndex
                                  ].magicMomentNotes[
                                    noteIndex
                                  ].magicMomentNoteUpdateDate = new Date().toISOString(); // Update note date
                                  draft.userMagicMoments[
                                    momentIndex
                                  ].magicMomentUpdateDate = new Date().toISOString(); // Update parent date
                                })
                              );
                            }}
                            placeholderText="Write your note here..."
                            editorKey={`magicMomentNote-${momentIndex}-${noteIndex}-${note._tempId || note._id || 'new'}`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* 6. HiddenMagic - Super Powers */}
        <div id="superPowers" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center">
              <button
                type="button"
                onClick={() =>
                  setCollapsedSections((prevState) => ({
                    ...prevState,
                    superPowersCollapsed: !prevState.superPowersCollapsed,
                  }))
                }
                className="text-orange-600 font-bold m-1 p-1 rounded"
              >
                {collapsedSections.superPowersCollapsed ? (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                )}
              </button>
              <p className="font-bold text-sm mx-1 px-1">My Super Powers</p>
            </div>
            {/* <p className="font-bold text-sm">User Super Powers</p> */}
            <button
              type="button"
              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
              onClick={() => {
                const timestamp = Date.now();

                setFormData((prevState) =>
                  produce(prevState, (draft) => {
                    draft.userSuperPowers.unshift({
                      superPowerName: "",
                      superPowerDesc: "",
                      superPowerNotes: [],
                      superPowerDate: new Date().toISOString(), // Initial creation date
                      superPowerUpdateDate: new Date().toISOString(), // Initial update date
                      _tempId: timestamp,
                    });
                  })
                );
                setCollapsedSections((prevState) => ({
                  ...prevState,
                  superPowersCollapsed: false,
                  [`superPower-0`]: false,
                  [`superPowerNotes-0`]: false,
                  ...formData.userSuperPowers.reduce((acc, _, index) => ({
                    ...acc,
                    [`superPower-${index + 1}`]: true,
                    [`superPowerNotes-${index + 1}`]: true,
                  }), {})
                }));
              }}
            >
              <PlusIcon className="h-4 w-4 inline" />
            </button>
          </div>

          {!collapsedSections.superPowersCollapsed && formData.userSuperPowers.map((power, powerIndex) => (
            <div
              key={powerIndex}
              className="p-2 border rounded mb-2 bg-white"
            >
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={() =>
                      toggleSection(`superPower-${powerIndex}`)
                    }
                    className="text-orange-600 font-bold m-1 p-1 rounded"
                  >
                    {collapsedSections[`superPower-${powerIndex}`] ? (
                      <PlusIcon className="h-4 w-4 text-orange-600" />
                    ) : (
                      <MinusIcon className="h-4 w-4 text-orange-600" />
                    )}
                  </button>
                  <p className="font-bold text-sm mx-1 px-1">
                    Super Power {powerIndex + 1}
                  </p>
                </div>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) =>
                      produce(prevState, (draft) => {
                        // draft.userSuperPowers.splice(powerIndex, 1);
                        const removedSuperPower = draft.userSuperPowers[powerIndex];
                        if (removedSuperPower._id) {
                          // Mark existing super power as deleted
                          removedSuperPower.delete = true;
                          if (!draft.deletedSuperPowers) {
                            draft.deletedSuperPowers = [];
                          }
                          draft.deletedSuperPowers.push(removedSuperPower);
                        }
                        // Remove it from the visible list
                        draft.userSuperPowers.splice(powerIndex, 1);
                      })
                    );
                  }}
                >
                  <MinusIcon className="h-4 w-4 inline" />
                </button>
              </div>

              {!collapsedSections[`superPower-${powerIndex}`] && (
                <div>
                  {/* Super Power Name */}
                  <div className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`superPowerName-${powerIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Super Power Name
                    </label>
                    <input
                      type="text"
                      name={`superPowerName-${powerIndex}`}
                      placeholder="Enter the super power name"
                      value={power.superPowerName}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData((prevState) =>
                          produce(prevState, (draft) => {
                            draft.userSuperPowers[powerIndex].superPowerName = value;
                            draft.userSuperPowers[
                              powerIndex
                            ].superPowerUpdateDate = new Date().toISOString(); // Update date
                          })
                        );
                      }}
                      className="w-full rounded p-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
                    />
                  </div>

                  {/* Super Power Description */}
                  <div className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`superPowerDesc-${powerIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Super Power Description
                    </label>
                    <EtContentEditorLight2
                      value={power.superPowerDesc || ""}
                      handleChange={(content) => {
                        setFormData((prevState) =>
                          produce(prevState, (draft) => {
                            draft.userSuperPowers[
                              powerIndex
                            ].superPowerDesc = content;
                            draft.userSuperPowers[
                              powerIndex
                            ].superPowerUpdateDate = new Date().toISOString(); // Update date
                          })
                        );
                      }}
                      placeholderText="Write the super power description here..."
                      editorKey={`superPowerDesc-${powerIndex}-${power._tempId || power._id || 'new'}`}
                    />
                  </div>

                  {/* Super Power Notes */}
                  <div className="bg-gray-100 rounded p-2 m-1">
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex items-center">
                            <button
                              type="button"
                              onClick={() =>
                                toggleSection(`superPowerNotes-${powerIndex}`)
                              }
                              className="text-orange-600 font-bold m-1 p-1 rounded"
                            >
                              {collapsedSections[`superPowerNotes-${powerIndex}`] ? (
                                <PlusIcon className="h-4 w-4 text-orange-600" />
                              ) : (
                                <MinusIcon className="h-4 w-4 text-orange-600" />
                              )}
                            </button>
                            <p className="font-bold text-sm">Super Power Notes</p>
                        </div>
                      
                      <button
                        type="button"
                        className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
                        onClick={() => {
                          const timestamp = Date.now();

                          setFormData((prevState) =>
                            produce(prevState, (draft) => {
                              draft.userSuperPowers[powerIndex].superPowerNotes.unshift({
                                superPowerNoteText: "",
                                superPowerNoteDate: new Date().toISOString(), // Creation date
                                superPowerNoteUpdateDate: new Date().toISOString(), // Update date
                                _tempId: timestamp,
                              });
                              draft.userSuperPowers[
                                powerIndex
                              ].superPowerUpdateDate = new Date().toISOString(); // Update parent date
                            })
                          );
                        }}
                      >
                        <PlusIcon className="h-4 w-4 inline" />
                      </button>
                    </div>

                    {!collapsedSections[`superPowerNotes-${powerIndex}`] && power.superPowerNotes?.map((note, noteIndex) => (
                      <div
                        key={noteIndex}
                        className="p-2 border rounded mb-2 bg-white"
                      >
                        <div className="flex justify-between items-center mb-2">
                          <p className="font-bold text-xs">Note {noteIndex + 1}</p>
                          <button
                            type="button"
                            className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                            onClick={() => {
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  const removedNote = draft.userSuperPowers[powerIndex].superPowerNotes[noteIndex];
                                  if (removedNote._id) {
                                    // Mark existing note as deleted
                                    removedNote.delete = true;
                                    if (!draft.deletedSuperPowerNotes) {
                                      draft.deletedSuperPowerNotes = [];
                                    }
                                    draft.deletedSuperPowerNotes.push(removedNote);
                                  }
                                  // Remove it from the visible list
                                  draft.userSuperPowers[powerIndex].superPowerNotes.splice(noteIndex, 1);
                                  draft.userSuperPowers[powerIndex].superPowerUpdateDate = new Date().toISOString(); // Update parent date
                                })
                              );
                            }}
                          >
                            <MinusIcon className="h-4 w-4 inline" />
                          </button>
                        </div>

                        {/* Note Text */}
                        <div className="bg-gray-100 rounded p-1 m-1">
                          <label
                            htmlFor={`noteText-${powerIndex}-${noteIndex}`}
                            className="block text-gray-600 text-sm font-bold mb-1"
                          >
                            Note Text
                          </label>
                          <EtContentEditorLight2
                            value={note.superPowerNoteText || ""}
                            handleChange={(content) => {
                              setFormData((prevState) =>
                                produce(prevState, (draft) => {
                                  draft.userSuperPowers[
                                    powerIndex
                                  ].superPowerNotes[noteIndex].superPowerNoteText =
                                    content;
                                  draft.userSuperPowers[
                                    powerIndex
                                  ].superPowerNotes[
                                    noteIndex
                                  ].superPowerNoteUpdateDate = new Date().toISOString(); // Update note date
                                  draft.userSuperPowers[
                                    powerIndex
                                  ].superPowerUpdateDate = new Date().toISOString(); // Update parent date
                                })
                              );
                            }}
                            placeholderText="Write your note here..."
                            editorKey={`superPowerNote-${powerIndex}-${noteIndex}-${note._tempId || note._id || 'new'}`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        
        {/* 7. HiddenMagic - Self Notes */}
        <div id="selfNotes" className="border rounded p-2 m-2 bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center">
              <button
                type="button"
                onClick={() =>
                  setCollapsedSections((prevState) => ({
                    ...prevState,
                    selfNotesCollapsed: !prevState.selfNotesCollapsed,
                  }))
                }
                className="text-orange-600 font-bold m-1 p-1 rounded"
              >
                {collapsedSections.selfNotesCollapsed ? (
                  <PlusIcon className="h-4 w-4 text-orange-600" />
                ) : (
                  <MinusIcon className="h-4 w-4 text-orange-600" />
                )}
              </button>
              <p className="font-bold text-sm mx-1 px-1">Self Notes</p>
            </div>

            {/* <p className="font-bold text-sm">Self Notes</p> */}
            <button
              type="button"
              className="bg-lime-600 hover:bg-lime-700 text-white text-xs font-bold m-1 p-1 rounded"
              onClick={() => {
                const timestamp = Date.now();

                setFormData((prevState) =>
                  produce(prevState, (draft) => {
                    draft.userHiddenMagicSelfNotes.unshift({
                      hiddenMagicSelfNoteText: "",
                      hiddenMagicSelfNoteDate: new Date().toISOString(), // Initial creation date
                      hiddenMagicSelfNoteUpdateDate: new Date().toISOString(), // Initial update date
                      _tempId: timestamp,
                    });
                  })
                );
                setCollapsedSections((prevState) => ({
                  ...prevState,
                  selfNotesCollapsed: false,
                  [`selfNote-0`]: false,
                  ...formData.userHiddenMagicSelfNotes.reduce((acc, _, index) => ({
                    ...acc,
                    [`selfNote-${index + 1}`]: true
                  }), {})
                }));
              }}
            >
              <PlusIcon className="h-4 w-4 inline" />
            </button>
          </div>

          {!collapsedSections.selfNotesCollapsed && formData.userHiddenMagicSelfNotes.map((note, noteIndex) => (
            <div
              key={noteIndex}
              className="p-2 border rounded mb-2 bg-white"
            >
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={() =>
                      toggleSection(`selfNote-${noteIndex}`)
                    }
                    className="text-orange-600 font-bold m-1 p-1 rounded"
                  >
                    {collapsedSections[`selfNote-${noteIndex}`] ? (
                      <PlusIcon className="h-4 w-4 text-orange-600" />
                    ) : (
                      <MinusIcon className="h-4 w-4 text-orange-600" />
                    )}
                  </button>
                  <p className="font-bold text-xs mx-1 px-1">
                    Self Note {noteIndex + 1}
                  </p>
                </div>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-bold m-1 p-1 rounded"
                  onClick={() => {
                    setFormData((prevState) =>
                      produce(prevState, (draft) => {
                        // draft.userHiddenMagicSelfNotes.splice(noteIndex, 1);
                        const removedNote = draft.userHiddenMagicSelfNotes[noteIndex];
                        if (removedNote._id) {
                          // Mark existing note as deleted
                          removedNote.delete = true;
                          if (!draft.deletedSelfNotes) {
                            draft.deletedSelfNotes = [];
                          }
                          draft.deletedSelfNotes.push(removedNote);
                        }
                        // Remove it from the visible list
                        draft.userHiddenMagicSelfNotes.splice(noteIndex, 1);
                      })
                    );
                  }}
                >
                  <MinusIcon className="h-4 w-4 inline" />
                </button>
              </div>

              {!collapsedSections[`selfNote-${noteIndex}`] && (
                <div>
                  {/* Self Note Text */}
                  <div className="bg-gray-100 rounded p-1 m-1">
                    <label
                      htmlFor={`selfNoteText-${noteIndex}`}
                      className="block text-gray-600 text-sm font-bold mb-1"
                    >
                      Note Text
                    </label>
                    <EtContentEditorLight2
                      value={note.hiddenMagicSelfNoteText || ""}
                      handleChange={(content) => {
                        setFormData((prevState) =>
                          produce(prevState, (draft) => {
                            draft.userHiddenMagicSelfNotes[
                              noteIndex
                            ].hiddenMagicSelfNoteText = content;
                            draft.userHiddenMagicSelfNotes[
                              noteIndex
                            ].hiddenMagicSelfNoteUpdateDate = new Date().toISOString(); // Update note date
                          })
                        );
                      }}
                      placeholderText="Write your note here..."
                      editorKey={`selfNote-${noteIndex}-${note._tempId || note._id || 'new'}`}
                    />
                  </div>

                  {/* Note Date */}
                  <p className="text-xs text-gray-500 mt-2">
                    Created on:{" "}
                    {new Date(note.hiddenMagicSelfNoteDate).toLocaleDateString()}
                  </p>
                  <p className="text-xs text-gray-500">
                    Last updated:{" "}
                    {new Date(note.hiddenMagicSelfNoteUpdateDate).toLocaleDateString()}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>

        
        {/* 8. Form Submissions Bar */}
        <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic 
              name={'Update'} 
              type={'create'} 
              handleClick={handleSubmit} 
              />
            <EtBtnFormBasic
              name={'Clear'}
              type={'clear'}
              handleClick={handleClear}
            />
            <EtBtnFormBasic
              name={'Cancel'}
              type={'cancel'}
              handleClick={handleCancel}
            />
        </div>

      </form>
      
    </div>
  )
}

export default EtUpdateUserImprintHiddenMagicComp