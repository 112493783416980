import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const coachOrgTrailSessionPadsAdapter = createEntityAdapter({})

const initialState = coachOrgTrailSessionPadsAdapter.getInitialState()

export const coachOrgTrailSessionPadsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCoachOrgTrailSessionPads: builder.query({
            query: () => ({
                url: '/coachOrgTrailSessionPads/getCoachOrgTrailSessionPads',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedCoachOrgTrailSessionPads = responseData.map(coachOrgTrailSessionPad => {
                    coachOrgTrailSessionPad.id = coachOrgTrailSessionPad._id
                    return coachOrgTrailSessionPad
                })
                return coachOrgTrailSessionPadsAdapter.setAll(initialState, loadedCoachOrgTrailSessionPads)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'CoachOrgTrailSessionPad', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'CoachOrgTrailSessionPad', id}))
                    ]
                } else {
                    return [ { type: 'CoachOrgTrailSessionPad', id: 'LIST'}]
                }
            },
        }),
        createNewCoachOrgTrailSessionPad: builder.mutation({
            query: initialCoachOrgTrailSessionPad => ({
                url: '/coachOrgTrailSessionPads/createNewCoachOrgTrailSessionPad',
                method: 'POST',
                body: {...initialCoachOrgTrailSessionPad,}
            }),
            invalidatesTags: [
                { type: 'CoachOrgTrailSessionPad', id: "LIST" }
            ] 
        }),
        updateCoachOrgTrailSessionPad: builder.mutation({
            query: initialCoachOrgTrailSessionPad => ({
                url: '/coachOrgTrailSessionPads/updateCoachOrgTrailSessionPad',
                method: 'PATCH',
                body: {...initialCoachOrgTrailSessionPad,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'CoachOrgTrailSessionPad', id: arg.id },
                { type: 'CoachOrgTrailSessionPad', id: 'LIST' }
            ],
        }),
        deleteCoachOrgTrailSessionPad: builder.mutation({
            query: ({id}) => ({
                url: '/coachOrgTrailSessionPads/deleteCoachOrgTrailSessionPad',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'CoachOrgTrailSessionPad', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetCoachOrgTrailSessionPadsQuery,
    useCreateNewCoachOrgTrailSessionPadMutation,
    useUpdateCoachOrgTrailSessionPadMutation,
    useDeleteCoachOrgTrailSessionPadMutation,
} = coachOrgTrailSessionPadsApiSlice

// returns the query result object
export const selectCoachOrgTrailSessionPadResult = coachOrgTrailSessionPadsApiSlice.endpoints.getCoachOrgTrailSessionPads.select()

// create memoized selector
const selectCoachOrgTrailSessionPadsData = createSelector(
    selectCoachOrgTrailSessionPadResult,
    coachOrgTrailSessionPadResult => coachOrgTrailSessionPadResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCoachOrgTrailSessionPads,
    selectById: selectCoachOrgTrailSessionPadById,
    selectIds: selectCoachOrgTrailSessionPadIds,
} = coachOrgTrailSessionPadsAdapter.getSelectors(state => selectCoachOrgTrailSessionPadsData(state) ?? initialState)