import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import {
  PlusIcon,
  MinusIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline"
import { ClockIcon, BuildingOffice2Icon,ArrowPathIcon, CalendarDaysIcon, Squares2X2Icon, ArrowTrendingUpIcon, InboxArrowDownIcon, UserIcon, ArrowDownCircleIcon, ArrowUpCircleIcon  } from "@heroicons/react/24/solid"
import { useGetUserCreationsQuery } from '@/features/userCreations/userCreationsApiSlice'
import { useGetCreationTypesQuery } from '@/features/creationTypes/creationTypesApiSlice'
import { useGetCreationAreaTypesQuery } from '@/features/creationAreaTypes/creationAreaTypesApiSlice'

import EtModalCreateUserImprintCreation from "@/components/common/modal/EtModalCreateUserImprintCreation"
import EtModalUpdateUserImprintCreation from "@/components/common/modal/EtModalUpdateUserImprintCreation"

// 10 Dec 2024
import EtWabCreationNotesIcon1SVGComp from '@/assets/img/icons/svgComp/EtWabCreationNotesIcon1SVGComp'
import EtCreationAreaIcon1SVGComp from '@/assets/img/icons/svgComp/EtCreationAreaIcon1SVGComp'
import EtCreationTypeIcon1SVGComp from '@/assets/img/icons/svgComp/EtCreationTypeIcon1SVGComp'

import { createSvgComponentFromBase64 } from '@/utils/createSvgComponentFromBase64'

import {
  formatDateToMonthYear,
  formatDateToYear,
  formatDateTime,
  formatShortDate,
  calculateYearsAndMonths,
} from "@/utils/formatDate"

import { getTimeSince } from "@/utils/EtGetTimeSince"

const EtImprintCreation = ({ userId, userInfo }) => {
  // First Created - 09 Dec 2024
  // Author - Atul Pandey
  // Updated - 10 Dec 2024 - Tabs - Summary and Details
  // Updated - 11 Dec 2024 - Tab Tiles - refined data extraction and scorecard components
  // Updated - 12 Dec 2024 - id for update modal
  // Updated - 14 Dec 2024 - refined the JSX to display creation description and notes
  // Updated - 15 Dec 2024 - refined the JSX to display creation area types

  // 1a - State for Modal -- 09 Dec 2024
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [createTriggeringUrl, setCreateTriggeringUrl] = useState(null)
  const [updateTriggeringUrl, setUpdateTriggeringUrl] = useState(null)

  // 1b - State for JSX Display - 10 Dec 2024
  const [showCreationDetails, setShowCreationDetails] = useState({})
  const [showCreationNotes, setShowCreationNotes] = useState({})
  const [showCreationAreaTypes, setShowCreationAreaTypes] = useState({}) // 15 Dec 2024
  const [showCreationNoteDetails, setShowCreationNoteDetails] = useState({}) // 14 Dec 2024
  const [activeTab, setActiveTab] = useState('Summary') // 10 Dec 2024
  const [selectedCreationId, setSelectedCreationId] = useState(null); // 12 Dec 2024

  // 2a - Extract needed data from the incoming props
  const userOrgId = userInfo[0]?.userOrgId._id || null
  // console.log('userOrgId', userOrgId)

  // 2b - Get UserImprintCreation Data - 09 Dec 2024
  const { data: creationTypesData} = useGetCreationTypesQuery()
  const { data: creationAreaTypesData} = useGetCreationAreaTypesQuery()
  const { data: userImprintCreationData } = useGetUserCreationsQuery()
  const userImprintCreations = userImprintCreationData?.ids
    ?.map((id) => userImprintCreationData?.entities[id])
    ?.filter(
      (item) => item.userId?._id === userId && item.userOrgId._id === userOrgId
    )
  console.log("userImprintCreations", userImprintCreations)

  const extractEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id]);
  };

  const creationTypes = extractEntitiesFromAdapter(creationTypesData);
  const creationAreaTypes = extractEntitiesFromAdapter(creationAreaTypesData);
  console.log('creationAreaTypes: ', creationAreaTypes)

  // 3a - Handle Create User Imprint Creation
  const handleCreateUserImprintCreation = () => {
    const currentUrl = window.location.pathname
    setIsCreateModalOpen(true)
    setCreateTriggeringUrl(currentUrl)
  }

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false)
    setCreateTriggeringUrl(null)
    // window.location.reload();
  }

  // 3b - Handle Update User Imprint Creation
  const handleUpdateUserImprintCreation = (id) => {
    const currentUrl = window.location.pathname
    setIsUpdateModalOpen(true)
    setUpdateTriggeringUrl(currentUrl)
    setSelectedCreationId(id)
  }

  const handleCloseUpdateModal = () => {
    setIsUpdateModalOpen(false)
    setUpdateTriggeringUrl(null)
    // window.location.reload();
  }

  // 3c - Toggle Show function for show creation details -- 10 Dec 2024
  const handleToggleCreationDetails = (noteId) => {
    setShowCreationDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
    }

  // 3d - Toggle Show function for show creation notes -- 10 Dec 2024
  const handleToggleCreationNotes = (noteId) => {
    setShowCreationNotes((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
    }

  // 3e - Toggle Show function for show creation note details -- 14 Dec 2024
  const handleToggleCreationNoteDetails = (noteId) => {
    setShowCreationNoteDetails((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }))
    }

  // 3f - Toggle Show function for show creation area tpes -- 15 Dec 2024
  const handleToggleCreationAreaTypes = (areaTypeId) => {
    setShowCreationAreaTypes((prev) => ({
      ...prev,
      [areaTypeId]: !prev[areaTypeId],
    }))
    }

  // 4 - Toggle Tabs -- 10 Dec 2024
  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
  };

 // 5 - Function to get the appropriate color class based on section score
 const getColorForScore = (score) => {
  if (score >= 0 && score < 0.9) return "bg-etpx-0"
  if (score >= 0.9 && score < 1.9) return "bg-etpx-1"
  if (score >= 1.9 && score < 2.9) return "bg-etpx-2"
  if (score >= 2.9 && score < 3.9) return "bg-etpx-3"
  if (score >= 3.9 && score < 4.9) return "bg-etpx-4"
  return "bg-etpx-5" // For score >= 4.9
}

// 6 - create Markup
const createMarkup = (htmlContent) => {
  return { __html: htmlContent }
}

  return (
    <div className="m-1 p-1 border rounded">
      {userImprintCreations?.length === 0 && (
        <div className="flex justify-between items-center">
          <div>
            <p className="m-1 p-1 text-xs">
              There is no creation record for you yet! Change that by
              clicking the Add button.
            </p>
          </div>
          <div className="flex justify-start items-center">
            <p className="text-xs font-bold mx-1 px-1">Add</p>
            <button
              type="button"
              onClick={handleCreateUserImprintCreation}
              className="flex items-center bg-lime-500 hover:bg-lime-600 text-white text-xs font-bold p-1 rounded shadow"
            >
              <PlusIcon className="h-3 w-3" />
            </button>
          </div>
          <EtModalCreateUserImprintCreation
            isOpen={isCreateModalOpen}
            userId={userId}
            userOrgId={userOrgId}
            triggeringUrl={createTriggeringUrl}
            onClose={handleCloseCreateModal}
          />
        </div>
      )}
      {userImprintCreations?.length > 0 && (
        <div>    
            {/* Tab Navigation */}
            <div className="grid grid-cols-10 justify-between items-center m-1 p-1">
                <div className="col-span-3 flex space-x-4 mb-4 m-1 p-1">
                    {/* Tab 1: Summary */}
                    <button
                        className={`m-1 p-1 text-sm font-bold ${activeTab === 'Summary' ? 'border-b-2 border-orange-600' : 'text-gray-400'}`}
                        onClick={() => handleTabSwitch('Summary')}
                    >
                        Summary
                    </button>

                    {/* Tab 2: Details */}
                    <button
                        className={`m-1 p-1 text-sm font-bold ${activeTab === 'Details' ? 'border-b-2 border-orange-600' : 'text-gray-400'}`}
                        onClick={() => handleTabSwitch('Details')}
                    >
                        Details
                    </button>
                </div>
                <div className="col-span-3 flex justify-center items-center m-1 p-1">
                    <p className="font-bold text-xs m-1 p-1">Creations:</p>
                    <div className='rounded-full flex justify-center items-center h-5 w-5 border border-orange-300 bg-gray-50 m-1'><p className='text-orange-600 text-[10px]'>{userImprintCreations?.length}</p></div>
                </div>
                <div className="col-span-4 flex justify-end items-center m-1 p-1">
                    <p className="font-bold text-xs m-1 p-1">Add a New Creation</p>
                    <button
                        type="button"
                        onClick={handleCreateUserImprintCreation}
                        className="flex justify-center items-center h-5 w-5 text-white m-1 p-1 rounded bg-lime-600 hover:bg-green-600"
                    >
                        <PlusIcon className="h-5 w-5" />
                    </button>
                </div>

                <EtModalCreateUserImprintCreation
                  isOpen={isCreateModalOpen}
                  userId={userId}
                  userOrgId={userOrgId}
                  triggeringUrl={createTriggeringUrl}
                  onClose={handleCloseCreateModal}
                />  
            </div>

            {/* TAB1 - Summary */}

            {activeTab === 'Summary' && (
              <div className="bg-gray-100 rounded m-1 p-1">
                {userImprintCreations?.length > 0 && (
                <p className="text-xs m-1 p-1 leading-4">Here's a bird's eye view of my creations imprint, marking my contributions to my clients, my partners, my team, and my organization in my tech services journey thus far. On the left, you would see these creations organized by <strong>Creation Areas</strong> like Industries, Domains, Functions, Tech, Sales, and Services, reflecting the breadth of my focus. On the right, you would see them categorized by <strong>Creation Types</strong> such as Templates, Checklists, Solutions, and Products. Together, they portray a rich <strong>tapestry</strong> of my creative endeavors and their impact.</p>      
                )}

                <div className='grid grid-cols-6 justify-between items-center w-5/6 mx-auto'>
                    <div className='col-span-3 m-1 rounded'>
                        <div className='flex justify-start items-center'>
                        <div><EtCreationAreaIcon1SVGComp className='h-5 w-5 text-etpx-5'/></div>
                        <div><p className='p-1 m-1 text-gray-600 font-bold'>Creation Areas</p></div>
                        </div>
                        <div><p className='mx-1 px-1 text-[10px] lg:text-xs italic'>who have I created for..</p></div>
                        {/* Left side - Put the CreationTypes grid based on all creation types with zero here */}
                        <EtCreationAreaTypesScorecard creationAreaTypes={creationAreaTypes} creations={userImprintCreations} />
                    </div>
                    <div className='col-span-3 m-1 rounded '>                       
                        <div className="flex justify-between items-center">
                            <div className='flex justify-start items-center'>
                                <div><EtCreationTypeIcon1SVGComp className='h-5 w-5 text-etpx-5'/></div>
                                <div><p className='p-1 m-1 text-gray-400 font-bold'>Creation Types</p></div>
                            </div>
                        </div>
                        <div><p className='mx-1 px-1 text-[10px] lg:text-xs italic'>what have I created...</p></div>
                        {/* Right Side grid with actual weekly creations data*/}
                        <EtCreationTypesScorecard creationTypes={creationTypes} creations={userImprintCreations} />
                    </div>
                </div>    


              </div>
            )}

            {/* TAB2 - Details */}

            {activeTab === 'Details' && (
              <div>
                {userImprintCreations
                  ?.slice()
                  .sort(
                      (a, b) =>
                          new Date(b.createdAt) - new Date(a.createdAt)
                  ) // Sort in descending order
                  ?.map((creation, creationIndex) => {
                  const creationName = creation?.userCreationName || ""
                  const creationDesc = creation?.userCreationDesc || ""
                  const creationTypeName = creation?.userCreationType?.creationTypeName || ""
                  const creationTypePic = creation?.userCreationType?.pic || ""
                  const creationFocusAreas = creation?.userCreationFocusAreas || []
                  const creationNotes = creation?.userCreationNotes || []
                  const createdAt = creation?.createdAt
                  const creationNotesLength = creation?.userCreationNotes?.length || 0

                  return (
                    <div key={creation._id + creationIndex} className="m-1 p-1 bg-gray-100 rounded">
                      <div className="grid grid-cols-12 justify-start gap-2 items-center">
                        <div className="col-span-4 flex justify-start items-center m-1 p-1">
                          <div
                            onClick={() => handleToggleCreationDetails(creation?._id)}
                            className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
                          >
                            {showCreationDetails[creation?._id] ? (
                              <MinusIcon className="h-4 w-4 text-orange-600" />
                            ) : (
                              <PlusIcon className="h-4 w-4 text-orange-600" />
                            )}
                          </div>
                          <div>
                            <p className="text-gray-600 font-bold text-xl mx-1 px-1">
                              {creationIndex + 1}.
                            </p>
                          </div>
                          <div>
                            <p className="text-gray-800 text-xs mx-1 px-1">
                              {creationName}
                            </p>
                          </div>
                        </div>

                        {/* Creation Area Types */}
                        <div className="col-span-3 m-1 p-1">

                          <div className="flex justify-start items-center">
                            <div
                              onClick={() => handleToggleCreationAreaTypes(creation?._id)}
                              className="mx-1 rounded-full h-6 w-6 hover:bg-white flex justify-center items-center cursor-pointer"
                            >
                              {showCreationAreaTypes[creation?._id] ? (
                                <MinusIcon className="h-3 w-3 text-orange-600" />
                              ) : (
                                <PlusIcon className="h-3 w-3 text-orange-600" />
                              )}
                            </div>
                            <div><EtCreationAreaIcon1SVGComp  className='h-4 w-4 text-orange-600'/></div>
                            <p className="mx-1 px-1 font-bold text-[10px] text-gray-600">Creation Areas:</p>

                          </div>
                        
                          {showCreationAreaTypes[creation?._id] && creationFocusAreas.length>0 && creationFocusAreas?.map((focusArea, focusAreaIndex)=>{
                            const creationAreaTypeName = focusArea?.creationAreaType?.creationAreaTypeName || ""
                            const creationAreaTypePic = focusArea?.creationAreaType?.pic || ""
                            const creationFocusName = (() => {
                              if (!creationAreaTypeName) return ""; // Ensure area type name exists

                              // Special case for "General"
                              if (creationAreaTypeName === "General") return "All Purpose";
                            
                              // Construct the dynamic field name
                              const focusFieldKey = `creation${creationAreaTypeName}Type`;
                              const focusTypeObj = focusArea?.[focusFieldKey]; // Access the focus type object
                            
                              // Construct the dynamic name key
                              const focusTypeNameKey = `capabilities${creationAreaTypeName}TypeName`;
                              return focusTypeObj?.[focusTypeNameKey] || "";
                            })();
                            
                            
                            return(
                            <div key={focusArea?._id+focusAreaIndex}>
                              <div className="flex justify-start items-center m-1 p-1 bg-white rounded">
                                {/* <div><p className="text-xs font-bold mx-1 px-1 text-gray-600">{focusAreaIndex+1}.</p></div> */}
                                <div className="mx-1 px-1"><img src={creationAreaTypePic} alt="" className="h-4 w-4"/></div>
                                <div className="flex justify-start items-center">
                                  <p className="text-[10px] font-bold text-gray-600">{creationAreaTypeName}:</p>
                                  <p className="text-[10px] mx-1 px-1">{creationFocusName}</p>
                                </div>

                              </div>

                            </div>
                            )
                            })}
                        </div>

                        <div className="col-span-2 flex justify-start items-center m-1 p-1">
                          {/* <div><p className="text-[10px] text-gray-400">Type:</p></div> */}
                          <div className="flex justify-center items-center h-7 w-7 rounded-full bg-white hover:bg-gray-50">
                            <img src={creationTypePic} alt="" className="h-5 w-5" />
                          </div>
                          <div>
                            <p className="mx-1 px-1 text-[10px]">{creationTypeName}</p>
                          </div>
                        </div>

                        <div className="col-span-3 flex justify-center items-center mx-2">
                            <div className="flex justify-start items-center">
                              <ClockIcon className="h-5 w-5 text-lime-600" />
                              <p className="text-[9px] mx-1">First Created:</p>
                              <p className="text-gray-400 text-[9px] mx-2">
                                {formatDateTime(createdAt)}
                              </p>
                            </div>
                            <div className="flex justify-end items-center mx-1">
                              <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                <PencilSquareIcon
                                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                  onClick={() => handleUpdateUserImprintCreation(creation._id)}
                                />
                              </div>
                              {/* <div className="rounded-full flex justify-center items-center h-6 w-6 hover:bg-white">
                                <TrashIcon
                                  className="h-4 w-4 p-[2px] text-orange-600 cursor-pointer"
                                  onClick={() => handleDeleteCreation(creation?._id)}
                                />
                              </div> */}
                            </div>
                        </div>

                        
                      </div>
                      {showCreationDetails[creation?._id] && (
                        <div className="m-1 p-1 border rounded bg-white">
                          <div>
                            <p className="m-1 p-1 border-b-2 border-orange-600 text-gray-800 font-bold text-sm inline-block">Description</p>
                            <p dangerouslySetInnerHTML={createMarkup(creationDesc)} className="text-xs leading-4 text-gray-600 m-1 p-1"></p>
                          </div>
                           {/* Creation Notes */}
                          <div className="m-1 p-1 bg-gray-200 rounded">

                            <div className="grid grid-cols-8 justify-start items-center">
                              <div
                                className={`col-span-3 flex justify-start items-center m-1 p-1`}
                              >
                                <div
                                  onClick={() => handleToggleCreationNotes(creation?._id)}
                                  className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                >
                                  {showCreationNotes[creation?._id] ? (
                                    <MinusIcon className="h-4 w-4 text-orange-600" />
                                  ) : (
                                    <PlusIcon className="h-4 w-4 text-orange-600" />
                                  )}
                                </div>
                                <div
                                  className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer"
                                  onClick={() => handleToggleCreationNotes(creation?._id)}
                                >
                                  <EtWabCreationNotesIcon1SVGComp className="h-6 w-6 text-orange-600" />
                                </div>
                                <div>
                                  <p className="mx-1 px-1 font-bold text-gray-600">
                                    Creation Notes Trail
                                  </p>
                                  <p className="mx-1 px-1 text-gray-400 text-xs italic">
                                    what perspectives have I gained from this creation?
                                  </p>
                                </div>
                              </div>
                            </div>

                            {showCreationNotes[creation?._id] && creationNotes.length>0 && creationNotes?.map((note, noteIndex) => {

                              const noteText = note?.userCreationNoteText || ""
                              const noteDate = note?.userCreationNoteDate || ""
                              const noteUpdateDate = note?.userCreationNoteUpdateDate || ""


                              return(
                                <div key={note._id+noteIndex} className="">
                                  <div className="grid grid-cols-6 justify-between items-center m-1 p-1 bg-gray-100 border rounded">
                                    <div className="col-span-1 flex justify-start items-center m-1 p-1">
                                      <div
                                        onClick={() =>
                                          handleToggleCreationNoteDetails(note?._id)
                                        }
                                        className="mx-2 cursor-pointer rounded-full h-5 w-5 flex justify-center items-center hover:bg-white"
                                      >
                                        {showCreationNoteDetails[note?._id] ? (
                                          <MinusIcon className="h-4 w-4 text-orange-600" />
                                        ) : (
                                          <PlusIcon className="h-4 w-4 text-orange-600" />
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xl font-bold mx-1 px-1 text-gray-600">
                                          {noteIndex + 1}.
                                        </p>
                                      </div>
                                      {/* <div className="flex justify-center items-center rounded-full h-8 w-8 bg-gray-50 hover:bg-white cursor-pointer">
                                        <EtShowcaseSelfNotesIcon1SVGComp className="h-4 w-4 text-orange-600" />
                                      </div> */}
                                      <div>
                                        <div className="flex flex-col justify-center items-center mx-1 px-1">
                                          <p className="text-[9px] mx-1 rounded-full px-2 py-[1px] bg-lime-200 w-full">
                                            {getTimeSince(noteUpdateDate)}
                                          </p>
                                          <p className="text-[9px] text-gray-600 italic">
                                            last updated
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-span-3">
                                      <div
                                        dangerouslySetInnerHTML={createMarkup(noteText)}
                                        className="text-[11px] text-gray-700 m-1 p-1 truncate max-w-full line-clamp-1"
                                      ></div>
                                    </div>
                                    <div className="col-span-2 flex justify-center items-center mx-2">
                                      <ClockIcon className="h-5 w-5 text-lime-600" />
                                      <p className="text-[9px] mx-1">First Created:</p>
                                      <p className="text-gray-400 text-[9px] mx-2">
                                        {formatDateTime(noteDate)}
                                      </p>
                                    </div>
                                  </div>
                                  {showCreationNoteDetails[note?._id] && (
                                    <div className="bg-white m-1 p-1 rounded">

                                      <div dangerouslySetInnerHTML={createMarkup(noteText)}
                                        className="m-1 p-1 text-xs leading-4">
                                      </div>

                                    </div>
                                  )}


                                </div>
                              )
                            })}



                          </div>

                        </div>
                      )}       
                    </div>
                  )
                })}
              </div>
            )}
            <EtModalUpdateUserImprintCreation 
              isOpen={isUpdateModalOpen}
              id={selectedCreationId}
              userId={userId}
              userOrgId={userOrgId}
              triggeringUrl={updateTriggeringUrl}
              onClose={handleCloseUpdateModal} 
              />     
        </div>
      )}
    </div>
  )
}

const EtCreationTypesScorecard = ({ creationTypes=[], creations = [] }) => {

  // 0. Get the weeklyCreationData
  const creationsData = extractCreationTypesData(creationTypes, creations)
  console.log('Weekly CreationData', creationsData)
  
  // 1. Calculate the grid size based on the number of creation types
  const calculateGridSize = (numberOfCreationTypes) => {
      if (numberOfCreationTypes <= 1) return 1;
      if (numberOfCreationTypes <= 4) return 2;
      if (numberOfCreationTypes <= 9) return 3;
      if (numberOfCreationTypes <= 16) return 4;
      return 4; // Default to 4 if more than 16 creation types
  };
  
  const gridSize = calculateGridSize(creationTypes.length);
  
  // 2. Function to get the appropriate color class based on creation count
  const getColorForCreationCount = (count) => {
      return count > 0 ? "bg-etpx-5" : "bg-black"; // Light up the pixel if count > 0, else keep it black
  };
  
  const getTextColorForScore = (count) => {
      return count >0 ? 'text-white' : 'text-gray-900'; // Default color if none of the conditions match
      };
  
  const getTextColorForTitle = (count) => {
      return count >0 ? 'text-black' : 'text-gray-700'; // Default color if none of the conditions match
      };
  
  // 3. Generate square divs for each creation type
  const squares = creationTypes?.map((type, index) => {
      const creationData = creationsData?.find(data => data.creationTypeName === type?.creationTypeName); // Find matching creation data
      const pic = type?.pic; // Icon for the creation type
      const MyIcon = createSvgComponentFromBase64(pic); // 07 Jul 2024
      const creationTypeName = type?.creationTypeName;
      const creationCount = creationData ? creationData.creationCount : 0; // Get creation count or default to 0
      const colorClass = getColorForCreationCount(creationCount);
      const textColorClass = getTextColorForScore(creationCount)
      const textTitleColorClass = getTextColorForTitle(creationCount)
  
      return (
      <div key={index} className={`aspect-square rounded border m-[1px] border-black ${colorClass}`}>
          <div className="flex flex-col justify-between items-center h-full p-2">
          
          <MyIcon className={`h-4 w-4 sm:h-6 sm:w-6 md:h-8 md:w-8 ${textColorClass}`}/>
          
          <div className={`rounded-full h-6 w-6 border flex justify-center items-center ${creationCount >0 ? 'border-white' : 'border-none'}`}><p className={`${textColorClass} text-center font-bold`}>{creationCount}</p></div>
          <p className={`text-[9px]  ${textTitleColorClass} text-center`}>{creationTypeName}</p>
          
          
          </div>
      </div>
      );
  });
  
  // 4. Return the grid of squares
  return (
      <div className={`grid grid-cols-${gridSize} aspect-square rounded bg-gradient-to-r from-black to-black via-etpx-bg mx-auto p-[2px]`}>
      {squares}
      </div>
  );
};

const extractCreationTypesData = (creationTypes=[], creations) => {
  // Create a map to store the count of creations per creation type
  const creationCountMap = {};

  // Populate the map with creation counts from the creations array
  creations?.forEach(creation => {
    const creationTypeId = creation.userCreationType?._id;
    if (creationTypeId) {
      creationCountMap[creationTypeId] = (creationCountMap[creationTypeId] || 0) + 1;
    }
  });

  // Build the weeklyCreationData array
  const weeklyCreationData = creationTypes?.map(type => ({
    creationTypeName: type.creationTypeName,
    pic: type.pic, // Assuming pic holds the icon data for each creation type
    creationCount: creationCountMap[type._id] || 0 // If no creations, set to 0
  }));

  return weeklyCreationData;
};

const EtCreationAreaTypesScorecard = ({ creationAreaTypes=[], creations = [] }) => {

  // 0. Get the creations Data
  const creationsData = extractCreationAreaTypesData(creationAreaTypes, creations)
  // console.log('Creations', creations)
  // console.log('CreationData', creationsData)
  console.log('CreationAreaTypes', creationAreaTypes)
  
  // 1. Calculate the grid size based on the number of creation types
  const calculateGridSize = (numberOfCreationTypes) => {
      if (numberOfCreationTypes <= 1) return 1;
      if (numberOfCreationTypes <= 4) return 2;
      if (numberOfCreationTypes <= 9) return 3;
      if (numberOfCreationTypes <= 16) return 4;
      return 4; // Default to 4 if more than 16 creation types
  };
  
  const gridSize = calculateGridSize(creationAreaTypes.length);
  console.log('gridSize', gridSize)
  
  // 2. Function to get the appropriate color class based on creation count
  const getColorForCreationCount = (count) => {
      return count > 0 ? "bg-etpx-5" : "bg-black"; // Light up the pixel if count > 0, else keep it black
  };
  
  const getTextColorForScore = (count) => {
      return count >0 ? 'text-white' : 'text-gray-900'; // Default color if none of the conditions match
      };
  
  const getTextColorForTitle = (count) => {
      return count >0 ? 'text-black' : 'text-gray-700'; // Default color if none of the conditions match
      };
  
  // 3. Generate square divs for each creation type
  const squares = creationAreaTypes?.map((type, index) => {
      const creationData = creationsData?.find(data => data.creationAreaTypeName === type?.creationAreaTypeName); // Find matching creation area type data
      const pic = type?.pic; // Icon for the creation type
      const MyIcon = createSvgComponentFromBase64(pic); // 07 Jul 2024
      const creationAreaTypeName = type?.creationAreaTypeName;
      const creationCount = creationData ? creationData.creationCount : 0; // Get creation count or default to 0
      const colorClass = getColorForCreationCount(creationCount);
      const textColorClass = getTextColorForScore(creationCount)
      const textTitleColorClass = getTextColorForTitle(creationCount)
  
      return (
      <div key={index} className={`aspect-square rounded border m-[1px] border-black ${colorClass}`}>
          <div className="flex flex-col justify-between items-center h-full p-2">
          
          <MyIcon className={`h-12 w-12 ${textColorClass}`}/>
          
          <div className={`rounded-full h-8 w-8 border flex justify-center items-center ${creationCount >0 ? 'border-white' : 'border-none'}`}><p className={`${textColorClass} text-center font-bold text-2xl`}>{creationCount}</p></div>
          <p className={`text-xs  ${textTitleColorClass} text-center`}>{creationAreaTypeName}</p>
          
          
          </div>
      </div>
      );
  });
  
  // 4. Return the grid of squares
  return (
      <div className={`grid grid-cols-${gridSize} aspect-square rounded bg-gradient-to-r from-black to-black via-etpx-bg mx-auto p-[2px]`}>
      {squares}
      </div>
  );
};
    
const extractCreationAreaTypesData = (creationAreaTypes=[], creations=[]) => {
  // Create a map to store the count of creations per creation type
  const creationCountMap = {};

  creations?.forEach(creation => {
    creation.userCreationFocusAreas?.forEach(focusArea => {
      const creationAreaTypeId = focusArea?.creationAreaType?._id || focusArea?.creationAreaType;
      if (creationAreaTypeId) {
        creationCountMap[creationAreaTypeId] = (creationCountMap[creationAreaTypeId] || 0) + 1;
      }
    });
  });
  

  // Build the creations Data array
  const creationsData = creationAreaTypes?.map(type => ({
    creationAreaTypeName: type.creationAreaTypeName,
    pic: type.pic, // Assuming pic holds the icon data for each creation type
    creationCount: creationCountMap[type._id] || 0 // If no creations, set to 0
  }));

  return creationsData;
};

export default EtImprintCreation